import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dialog } from '@unite-us/ui';
import CloseAssistanceRequestForm from './CloseAssistanceRequestForm';
import '../stylesheets/close-assistance-request.scss';

class CloseAssistanceRequestDialog extends Component {
  constructor(props) {
    super(props);
    this.closeDialog = this.closeDialog.bind(this);
  }

  closeDialog() {
    this.props.resetActionValue();
    this.dialog.closeDialog('CLOSE');
  }

  render() {
    const { assistanceRequest, groupId, requestId } = this.props;
    return (
      <Dialog
        id="close-assistance-modal"
        ref={(el) => { this.dialog = el; }}
        title="Close Assistance Request"
        dialogContentStyles={{ padding: 0 }}
      >
        <CloseAssistanceRequestForm
          groupId={groupId}
          requestId={requestId}
          assistanceRequest={assistanceRequest}
          closeDialog={this.closeDialog}
        />
      </Dialog>
    );
  }
}

CloseAssistanceRequestDialog.propTypes = {
  assistanceRequest: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
  resetActionValue: PropTypes.func.isRequired,
};

export default CloseAssistanceRequestDialog;
