import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'lodash';
import GroupDetailsHeaderTitle from 'src/components/Groups/GroupDetails/components/GroupDetailsHeaderTitle';
import GroupDetailsHeaderActions from 'src/components/Groups/GroupDetails/components/GroupDetailsHeaderActions';
import 'src/components/Groups/GroupDetails/stylesheets/group-details-header.scss';

const ReferralCreateGroupDetailsHeader = (props) => {
  const {
    group,
    selected,
    addOrRemoveGroup,
    serviceType,
  } = props;

  return (
    <div className="referral-create-group-details-header group-details-header">
      <GroupDetailsHeaderTitle
        group={group}
        serviceTypeName={get(serviceType, 'name')}
        baseClass="referral-create"
      />

      <GroupDetailsHeaderActions
        selectGroup={addOrRemoveGroup}
        group={group}
        selected={selected}
      />
    </div>
  );
};

ReferralCreateGroupDetailsHeader.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  addOrRemoveGroup: PropTypes.func.isRequired,
  serviceType: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default ReferralCreateGroupDetailsHeader;
