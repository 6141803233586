import { isEmpty, find } from 'lodash';

function findPrimaryNumber(phoneNumbers = []) {
  if (isEmpty(phoneNumbers)) {
    return null;
  }
  return find(phoneNumbers, 'is_primary') || phoneNumbers[0];
}

export default findPrimaryNumber;
