import { useSearchParam } from './useSearchParam';
import { useFeatureFlag } from './index';

const FILTER_SEARCH_PARAM_NAMESPACE = 'filter';

/**
 * React hook for storing and retrieving filter values from URL search params.
 *
 * @param {string} key - **user facing** key for search param in snake_case
 * @param {string} [defaultValue] - optional default, used when **no** filters are in searchParams on page load
 *
 * @returns {Array} useState-like array with additional reset callback - [value, setValue, reset]
 *
 * @example
 * const [requestType, setRequestType, resetRequestType] = useFilterSearchParam('request_type', 'enroll');
 *
 * <Select label="Request Type" value={requestType} onChange={(val) => setRequestType(val)} />
 *
 * @see https://uniteus.atlassian.net/wiki/x/FID5FwE Confluence Design Doc
 */
export const useFilterSearchParam = (key, defaultValue) => useSearchParam(
  key,
  defaultValue,
  FILTER_SEARCH_PARAM_NAMESPACE,
);

/**
 * React hook for storing and retrieving filter values from URL search params, controlled by Feature Flag.
 *
 * Should be used when controlling release of url search param functionality with a Feature Flag.
 * If not controlling this feature in particular with a FF, use `useFilterSearchParam` instead.
 *
 * @param {string} key - **user facing** key for search param in snake_case
 * @param {string} [defaultValue] - optional default, used when **no** filters are in searchParams on page load
 * @param {string} flagName - name of the featureFlag
 *
 * @returns {Array} useState-like array with additional reset callback - [value, setValue, reset]
 *
 * @example
 * const [requestType, setRequestType, resetRequestType] = useFilterSearchParamWithFF(
 *   'request_type',
 *   'enroll',
 *   'feature-flag',
 * );
 * // to deprecate a FF, just replace with the following (in addition to test changes)
 * const [requestType, setRequestType, resetRequestType] = useFilterSearchParam('request_type', 'enroll');
 *
 * @see https://uniteus.atlassian.net/wiki/x/FID5FwE Confluence Design Doc
 */
export const useFilterSearchParamWithFF = (key, defaultValue, flagName) => {
  const flag = !!useFeatureFlag(flagName);

  return useSearchParam(key, defaultValue, FILTER_SEARCH_PARAM_NAMESPACE, flag);
};
