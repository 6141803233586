import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon } from '@unite-us/ui';
import ColumnHeader from './ColumnHeader';

const SortableColumnHeader = ({
  children,
  className,
  colName,
  sortBy,
  sortDirection,
  setSort,
  label,
}) => {
  const isSorted = sortBy === colName;

  const onColClick = () => {
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSort({
      sortBy: colName,
      sortDirection: newDirection,
    });
  };

  return (
    <ColumnHeader
      className={className}
      aria-sort={sortDirection === 'asc' ? 'ascending' : 'descending'}
    >
      <button
        type="button"
        onClick={onColClick}
        className="p-2 -m-2 flex uppercase items-center"
        aria-label={`Sort by ${label} in ${sortDirection === 'asc' ? 'ascending' : 'descending'} order`}
      >
        <div className="flex-none">
          {children}
        </div>
        <div
          className={cx(
            'flex-none ml-2',
            {
              'opacity-50': !isSorted,
            },
          )}
          icon="V2CaretDown"
        >
          <div className="flex flex-col items-center">
            <Icon
              icon="V2CaretDown"
              size={10}
              className={cx(
                'transform flex-none text-text-blue fill-current',
                {
                  'rotate-180': !isSorted || (isSorted && sortDirection === 'desc'),
                },
              )}
            />
            {
              !isSorted && (
                <Icon
                  icon="V2CaretDown"
                  size={10}
                  className="-mt-3px text-text-blue fill-current"
                />
              )
            }
          </div>
        </div>
      </button>
    </ColumnHeader>
  );
};

SortableColumnHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  colName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  setSort: PropTypes.func.isRequired,
};

SortableColumnHeader.defaultProps = {
  className: '',
};

export default SortableColumnHeader;
