import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import _ from 'lodash';
import { reduxForm7 } from '@unite-us/client-utils';
import {
  Icon,
} from '@unite-us/ui';
import {
  includePathwaysServices,
  hasPaymentsUserAccess,
  hint716SearchNetworkHubSupportPremiumSelector,
  hint542SupersetUnlistedPrograms as hint542SupersetUnlistedProgramsflag,
} from 'common/utils/FeatureFlags/flags';

// Component Imports
import {
  NetworkGroupLogo,
  NetworkGroupsDrawer,
} from 'src/components/Network/Groups/components';
import NetworkActionsBar from 'src/components/Network/Groups/NetworkActionsBar';

// Action Imports
import {
  fetchCoreLocations,
  fetchCorePrograms,
  fetchCoreProvider,
  fetchCoreProviderServices,
  searchMyNetworkProviders,
} from 'actions/Group/Network';

import {
  clearNetworkGroup,
  clearNetworkGroups,
  uploadGroupFile,
} from 'actions/Group';

// Util imports
import callOrLog from 'src/common/utils/callOrLog';
import { MY_NETWORKS } from 'common/utils/EventTracker/utils/eventConstants';
import { requiredMapScript } from 'src/components/Browse/Map/utils';
import { isExternalProviderOrOONGroup } from 'src/components/Groups/utils';
import withScript from 'common/utils/withScript';
import { getPermittedNetworks } from 'src/components/Referrals/utils/scopes';
import { serviceTypesFilter } from 'src/components/Dashboard/utils/filter';
import { addressBookParams, setMyTimeout, setSearchParams } from 'src/components/Network/Groups/utils';
import { isOONCaseUser } from 'src/components/User/utils';
import findCurrentGroup from 'common/utils/findCurrentGroup';
import { getOurCoordinates } from 'src/components/Browse/utils/address';
import { getEmployee } from 'src/components/Employee/employeeGetters';

// Stylesheet Imports
import './NetworkGroups.scss';

export class Groups extends Component {
  constructor(props) {
    super(props);

    this.closeDrawer = this.closeDrawer.bind(this);
    this.fetchGroups = this.fetchGroups.bind(this);
    this.onGroupSelect = this.onGroupSelect.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.toggleGroupDetail = this.toggleGroupDetail.bind(this);
    this.updateNameFilter = this.updateNameFilter.bind(this);
    this.updateNetworkFilter = this.updateNetworkFilter.bind(this);
    this.updateNetworkScopeFilter = this.updateNetworkScopeFilter.bind(this);
    this.updateServiceTypesFilter = this.updateServiceTypesFilter.bind(this);
    this.uploadCSV = this.uploadCSV.bind(this);

    const searchParams = props.searchParams;
    const serviceTypeFilterOptions = serviceTypesFilter(props.networkServiceTypes).options ||
      [];
    this.state = {
      delayedFunc: undefined,
      filterText: {
        name: 'search',
        onChange: this.updateNameFilter,
        value: _.get(searchParams, 'q.name', ''),
      },
      networkFilter: [],
      networkScopeFilter: [],
      open: false,
      providerServices: [],
      selectedGroup: {},
      selectedProvider: {},
      serviceTypeFilterOptions,
      serviceTypesFilter: [],
      sord: _.get(searchParams, 'sord', 'asc'),
      providerLocations: [],
      programs: [],
    };
  }

  componentDidMount() {
    this.fetchGroups();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((nextProps.networkId !== this.props.networkId) || (nextProps.networks !== this.props.networks)) {
      this.setState({
        filterText: {
          ...this.state.filterText,
          value: '',
        },
        open: false,
        selectedGroup: null,
        serviceTypeFilterOptions: serviceTypesFilter(nextProps.serviceTypes).options || [],
        serviceTypesFilter: [],
      });
    }
  }

  componentWillUnmount() {
    this.setState({ delayedFunc: clearTimeout(this.state.delayedFunc) });
  }

  onGroupSelect(rowNumber) {
    const group = this.props.groups[rowNumber];
    this.toggleGroupDetail(group);
  }

  onNextClick() {
    const { paging } = this.props;

    this.fetchGroups({
      page: _.get(paging, 'current_page', 1) + 1,
      sord: this.state.sord,
    });
  }

  onPrevClick() {
    const { paging } = this.props;

    this.fetchGroups({
      page: Math.max(_.get(paging, 'current_page', 1) - 1, 1),
    });
  }

  closeDrawer() {
    this.setState({
      open: false,
    });
  }

  fetchGroups(options) {
    const {
      networks,
      networkId,
      serviceTypes,
      userCanViewOONGroups,
    } = this.props;

    const { networkScopeFilter } = this.state;

    const isOON = networkScopeFilter.length && networkScopeFilter.includes('out-of-network');
    const showInAndOON = networkScopeFilter.length === 0 || networkScopeFilter.length === 2;

    const filters = {
      sord: this.state.sord,
      q: {
        include_home_groups: true,
        ...addressBookParams({
          canViewOONGroups: userCanViewOONGroups,
          networkScopes: networkScopeFilter,
        }),
        name: this.state.filterText.value,
        permitted_networks: this.state.networkFilter,
        service_type_ids: this.state.serviceTypesFilter,
      },
    };

    callOrLog(() => this.context.eventTracker(MY_NETWORKS.providerSearched, {
      searched_term: _.get(filters, 'q.name'),
    }));

    this.props.searchMyNetworkProviders({
      referredByNetworkId: networkId || networks,
      options: { ...filters, ...options },
      shouldCancel: true,
      serviceTypes,
      isOON,
      showInAndOON,
      hint716SearchNetworkHubSupportPremium: this.props.searchShouldIncludeNetworkHubSupportPremium,
    });
  }

  toggleGroupDetail(group = {}) {
    const { open, selectedGroup = {} } = this.state;
    const sameGroupSelected = selectedGroup.id === group.id;
    const {
      employeeId,
      usePaymentsUserRole,
      includePathways,
    } = this.props;
    const selectedProviderId = group.id;
    const referable = { employee: employeeId };

    if (!sameGroupSelected) {
      this.props.resetForm('share');
    }

    fetchCorePrograms({
      provider: selectedProviderId,
      referable,
      usePaymentsUserRole,
      includePathways,
    }).then((programs) => {
      this.setState({ programs });
    });

    fetchCoreLocations(selectedProviderId).then(((providerLocations) => {
      this.setState({ providerLocations });
    }));

    fetchCoreProvider(selectedProviderId).then((provider) => {
      const selectedProvider = {
        ..._.get(provider, 'attributes', {}),
        id: selectedProviderId,
      };
      this.setState({ selectedProvider });
    });

    fetchCoreProviderServices(selectedProviderId, this.includePathways).then((providerServices) => {
      this.setState({ providerServices });
    });

    this.setState({
      open: !open || !sameGroupSelected,
      selectedGroup: group,
    });
  }

  updateNameFilter(e) {
    const value = e.target ? e.target.value : '';
    this.setState({
      delayedFunc: setMyTimeout({
        func: this.fetchGroups,
        timer: this.state.delayedFunc,
      }),
      filterText: {
        ...this.state.filterText,
        value,
      },
      open: false,
    });
  }

  updateNetworkFilter(networks) {
    this.setState({
      networkFilter: networks,
      open: false,
    }, () => {
      this.props.clearNetworkGroups();
      this.fetchGroups();
    });
  }

  updateNetworkScopeFilter(scopes) {
    this.setState({
      networkScopeFilter: scopes,
      open: false,
    }, () => {
      this.props.clearNetworkGroups();
      this.fetchGroups();
    });
  }

  updateServiceTypesFilter(serviceTypes) {
    this.setState({
      serviceTypesFilter: serviceTypes,
      open: false,
    }, () => {
      this.props.clearNetworkGroups();
      this.fetchGroups();
    });
  }

  uploadCSV(documents) {
    const { groupId } = this.props;
    return this.props.uploadGroupFile({ documents, groupId });
  }

  render() {
    const {
      allowUpload,
      groups,
      isFetching,
      isSuperNetwork,
      networks,
      network,
      networkId,
      paging,
      referralScopes,
      styles,
      userCanViewOONGroups,
      center,
      hint542SupersetUnlistedPrograms,
    } = this.props;

    const {
      selectedGroup,
      serviceTypeFilterOptions,
      providerLocations,
      programs,
      providerServices,
      selectedProvider,
    } = this.state;

    const listClass = classNames('ui-list', {
      'ui-list--clickable': true,
      'ui-list--hoverable': true,
    });

    const listItemClass = classNames('ui-list-item', {
      'ui-list-item--clickable': true,
      'ui-list-item--hoverable': true,
    });

    const oonToolTipText = hint542SupersetUnlistedPrograms ?
      'Off-Platform Organization' :
      'Out of Network Organization';

    const listItems = groups.map((group, index) => (
      <li
        key={`network-group-${group.id}`}
        className={`${listItemClass} network-groups__group-row`}
        id={`network-group-row-${index}`}
        data-testid={`list-item-${index}`}
        onClick={() => this.onGroupSelect(index)}
        tabIndex={0}
      >
        <div className="network-groups__group-row-icon" data-testid="list-item-column-1">
          {isExternalProviderOrOONGroup(group) ? (
            <span
              data-tooltip={oonToolTipText}
              data-tooltip-bottom-right
            >
              <Icon icon="IconElectronsArrow" />
            </span>
          ) : (
            <div style={{ width: '17px' }} />
          )}
        </div>
        <div className="network-groups__group-row-logo" data-testid="list-item-column-2">
          <NetworkGroupLogo group={group} />
        </div>
        <div className="group-row__name" data-testid="list-item-column-3">
          {_.get(group, 'name')}
        </div>
      </li>
    ));

    return (
      <div className="network-groups">
        <NetworkActionsBar
          allowUpload={allowUpload}
          disableServiceTypeFilter={false}
          filterText={this.state.filterText}
          isFetching={isFetching}
          isSuperNetwork={isSuperNetwork}
          network={network}
          networkId={networkId || networks}
          onNetworkFilterChange={this.updateNetworkFilter}
          onNetworkScopeFilterChange={this.updateNetworkScopeFilter}
          onNextClick={this.onNextClick}
          onPrevClick={this.onPrevClick}
          onServiceTypesFilterChange={this.updateServiceTypesFilter}
          paging={paging}
          referralScopes={referralScopes}
          serviceTypeOptions={serviceTypeFilterOptions}
          uploadCSV={this.uploadCSV}
          userCanViewOONGroups={userCanViewOONGroups}
        />

        <div className="network-groups__list" aria-live="polite" aria-atomic="true">
          <div className="network-groups__list-header">Name</div>
          <ul
            className={listClass}
            style={{ paddingLeft: '0' }}
            data-testid="ui-list-network-groups__list"
          >
            {listItems}
          </ul>
        </div>

        <NetworkGroupsDrawer
          className="network-group-detail"
          drawerStyle={styles.containerStyle}
          group={selectedGroup}
          groupPrograms={programs}
          onClose={this.closeDrawer}
          open={this.state.open}
          selectedServiceIds={this.state.serviceTypesFilter}
          toggleDrawer={this.toggleGroupDetail}
          network={network}
          center={center}
          providerLocations={providerLocations}
          providerServices={providerServices}
          selectedProvider={selectedProvider}
        />
      </div>
    );
  }
}

Groups.propTypes = {
  allowUpload: PropTypes.bool,
  clearNetworkGroups: PropTypes.func.isRequired,
  employeeId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  isFetching: PropTypes.bool,
  isSuperNetwork: PropTypes.bool.isRequired,
  network: PropTypes.object.isRequired,
  networks: PropTypes.string.isRequired,
  networkId: PropTypes.string.isRequired,
  networkServiceTypes: PropTypes.array.isRequired,
  paging: PropTypes.object.isRequired,
  referralScopes: PropTypes.array.isRequired,
  resetForm: PropTypes.func.isRequired,
  searchMyNetworkProviders: PropTypes.func.isRequired,
  searchParams: PropTypes.object,
  serviceTypes: PropTypes.array,
  styles: PropTypes.object,
  uploadGroupFile: PropTypes.func.isRequired,
  userCanViewOONGroups: PropTypes.bool.isRequired,
  usePaymentsUserRole: PropTypes.bool.isRequired,
  includePathways: PropTypes.bool,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  searchShouldIncludeNetworkHubSupportPremium: PropTypes.bool,
  hint542SupersetUnlistedPrograms: PropTypes.bool,
};

Groups.defaultProps = {
  allowUpload: false,
  isFetching: false,
  searchParams: { q: {} },
  serviceTypes: [],
  styles: {
    iconColumn: {
      width: '24px',
      paddingRight: '0',
    },
    logoColumn: {
      width: '36px',
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  includePathways: false,
  searchShouldIncludeNetworkHubSupportPremium: false,
  hint542SupersetUnlistedPrograms: false,
};

Groups.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const {
    network, networkId, referralScopes,
  } = ownProps;
  const { user } = state;
  const networks = state.globalState.activeNetworks.map((n) => n.id).join(',');
  const groupId = _.get(state, 'session.groupId');
  const employeeId = _.get(state, 'globalState.currentEmployee.id');

  const coordCenterId = _.get(network, 'coordination_center.id', null) ||
    _.get(network, 'admin_groups[0].id', null);
  const permittedNetworkIds = _.map(getPermittedNetworks(referralScopes, networkId), 'id');
  const paging = _.get(state, 'networks.networkGroupsByReferralScopes.groupsPaging', {});
  const currentPage = _.get(state, 'networks.networkGroupsByReferralScopes.groupsPaging.current_page', 1);
  const allGroups = _.get(state, `networks.networkGroupsByReferralScopes.groups.${currentPage}`, []);
  const groups = allGroups.filter((group) => group.state !== 'inactive');
  const isFetching = _.get(state, 'networks.isFetching');
  const searchParams = setSearchParams(state, network, ownProps);
  const serviceTypes = _.get(state, 'session.globals.service_types', []);
  const userCanViewOONGroups = isOONCaseUser(user, groupId);
  const geoCoordinates = _.wget(state, 'session.position.geoCoordinates', {});
  const ipCoordinates = _.wget(state, 'session.position.ipCoordinates', {});
  const employee = getEmployee({ state });
  const currentUserGroup = findCurrentGroup(user, state.session);
  const usePaymentsUserRole = hasPaymentsUserAccess(state);
  const includePathways = includePathwaysServices(state);
  const center = getOurCoordinates({
    geoCoordinates,
    ipCoordinates,
    employee,
    currentUserGroup,
  });

  return {
    coordCenterId,
    currentPage,
    employeeId,
    groupId,
    groups,
    isFetching,
    network,
    networks,
    paging,
    permittedNetworkIds,
    searchParams,
    serviceTypes,
    userCanViewOONGroups,
    center,
    usePaymentsUserRole,
    includePathways,
    searchShouldIncludeNetworkHubSupportPremium: hint716SearchNetworkHubSupportPremiumSelector(state),
    hint542SupersetUnlistedPrograms: hint542SupersetUnlistedProgramsflag(state),
  };
}

export default connect(mapStateToProps, {
  clearNetworkGroup,
  clearNetworkGroups,
  resetForm: (name) => (dispatch) => dispatch(reduxForm7.reset(name)),
  searchMyNetworkProviders,
  uploadGroupFile,
})(withScript(requiredMapScript)(Groups));
