import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Table,
  TableBody,
  TableRowColumn,
  TableHeader,
  TableRow,
} from '@unite-us/ui';
import { TableHeaderColumnSortable } from './components';
import { theme } from '../utils';

function getTotal(data, yKey, cols) {
  const totals = _.reduce(cols, (acc, col) => {
    const total = _.reduce(data, (a, d) => (
      _.get(d, col.key, 0) + a
    ), 0);
    return _.merge(acc, { [col.key]: total });
  }, {});
  totals[yKey] = _.reduce(data, (acc, datum) => (
    _.get(datum, yKey, 0) + acc
  ), 0);
  return totals;
}

const row = (data, cols, x, y, showPercent, colWidths) => {
  const xCol = (
    <TableRowColumn
      clickable={false}
      key="x"
      style={{ width: _.get(colWidths, 'x', 'auto') }}
    >
      {x(data)}
    </TableRowColumn>
  );

  const yCol = (
    <TableRowColumn
      clickable={false}
      key="y"
      style={{ width: _.get(colWidths, 'y', 'auto') }}
    >
      <strong>{y(data)}</strong>
    </TableRowColumn>
  );

  const customCols = _.map(cols, (val) => {
    let pct = null;
    if (showPercent) {
      // eslint-disable-next-line react/jsx-one-expression-per-line
      pct = <span className="percent">({(_.round(data[val.key] / y(data)) * 100)}%)</span>;
    }

    return (
      <TableRowColumn
        clickable={false}
        key={val.key}
        style={{ width: _.get(colWidths, val.key, 'auto') }}
      >
        {data[val.key]} {pct}
      </TableRowColumn>
    );
  });

  return _.concat(xCol, customCols, yCol);
};

class DataTable extends Component {
  constructor(props) {
    super(props);

    this.getCols = this.getCols.bind(this);
    this.getDir = this.getDir.bind(this);
    this.onSortColumn = this.onSortColumn.bind(this);
    this.sortData = this.sortData.bind(this);

    this.state = {
      sortedData: _.get(props, 'data.values', []),
      sortingCol: '',
      sortingDir: '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.sortData(nextProps.data.values);
    }
  }

  onSortColumn(col, dir) {
    this.setState({
      sortingCol: col,
      sortingDir: dir,
    }, this.sortData);
  }

  getCols() {
    if (this.props.data.meta && this.props.data.meta.partitions) {
      return _.map(this.props.data.meta.partition_keys, (part) => {
        const label = this.props.data.meta.partition_display_names[part];
        return {
          key: part,
          label,
        };
      });
    }
    return [];
  }

  getDir(col) {
    return (this.state.sortingCol === col) ? this.state.sortingDir : '';
  }

  sortData(values) {
    this.setState({
      sortedData: _.orderBy(values || this.props.data.values, this.state.sortingCol, this.state.sortingDir),
    });
  }

  render() {
    const {
      colWidths,
      showPercent,
      showTotal,
      styles,
      x,
      xKey,
      xLabel,
      y,
      yKey,
      yLabel,
    } = this.props;
    const cols = this.getCols();

    return (
      <Table
        noBorder
        selectable={false}
      >
        <TableHeader
          adjustForCheckbox={false}
          displaySelectAll={false}
          enableSelectAll={false}
        >
          <TableRow hoverable={false}>
            <TableHeaderColumnSortable
              onSortColumn={this.onSortColumn}
              sortColumn={xKey}
              sortDirection={this.getDir(xKey)}
              style={{ width: _.get(colWidths, 'x', 'auto') }}
            >
              {xLabel}
            </TableHeaderColumnSortable>
            {
              cols.map((col) => (
                <TableHeaderColumnSortable
                  key={col.key}
                  onSortColumn={this.onSortColumn}
                  sortColumn={col.key}
                  sortDirection={this.getDir(col.key)}
                  style={{ width: _.get(colWidths, col.key, 'auto') }}
                >
                  {col.label}
                </TableHeaderColumnSortable>
              ))
            }
            <TableHeaderColumnSortable
              onSortColumn={this.onSortColumn}
              sortColumn={yKey}
              sortDirection={this.getDir(yKey)}
              style={{ width: _.get(colWidths, 'y', 'auto') }}
            >
              {yLabel}
            </TableHeaderColumnSortable>
          </TableRow>
        </TableHeader>
        <TableBody
          displayRowCheckbox={false}
        >
          {
            this.state.sortedData.map((value, index) => (
              <TableRow
                key={`data-row-${index}`}
                clickable={false}
              >
                {row(value, cols, x, y, showPercent, colWidths)}
              </TableRow>
            ))
          }
          {
            showTotal ? (
              <TableRow
                clickable={false}
                key={'data-row-total'}
                style={styles.totalRow}
              >
                {row(getTotal(this.state.sortedData, yKey, cols), cols, () => 'Total', y, false, colWidths)}
              </TableRow>
            ) : null
          }
        </TableBody>
      </Table>
    );
  }
}

DataTable.propTypes = {
  colWidths: PropTypes.object,
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  showPercent: PropTypes.bool.isRequired,
  showTotal: PropTypes.bool.isRequired,
  styles: PropTypes.object.isRequired,
  x: PropTypes.func.isRequired,
  xKey: PropTypes.string.isRequired,
  xLabel: PropTypes.string.isRequired,
  y: PropTypes.func.isRequired,
  yKey: PropTypes.string.isRequired,
  yLabel: PropTypes.string.isRequired,
};

DataTable.defaultProps = {
  cardStyle: {
    marginBottom: theme.card.marginBottom,
  },
  showPercent: true,
  showTotal: true,
  styles: {
    totalRow: {
      fontWeight: 'bold',
      backgroundColor: '#F8F8F8',
    },
  },
  x: (d) => d.label,
  xKey: 'label',
  xLabel: 'Name',
  y: (d) => d.value,
  yKey: 'value',
  yLabel: 'Total',
};

export default DataTable;
