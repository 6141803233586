import _ from 'lodash';
function filterOptions(opts, term) {
  var options = _.cloneDeep(opts);
  return _.filter(options, function (opt) {
    if (_.has(opt, 'children') && !_.isEmpty(opt.children)) {
      var filteredChildren = filterOptions(opt.children, term);
      _.set(opt, 'children', filteredChildren);
      if (filteredChildren.length <= 0) {
        return false;
      }
      return true;
    }
    return _.includes(_.toLower(opt.label), _.toLower(term));
  });
}
export default filterOptions;