import { get, map, reduce, intersection } from 'lodash';

const getSharedNetworks = ({ user, group }) => {
  const userNetworks = get(user, 'networks', []);
  const groupNetworks = get(group, 'group.networks', []);
  const networkIds = intersection(map(groupNetworks, 'id'), map(userNetworks, 'id'));

  return reduce(userNetworks, (acc, curr) => (
    (networkIds.includes(curr.id) ? [...acc, curr] : acc)
  ), []);
};

export default getSharedNetworks;
