import { decodeHTML } from '@unite-us/ui';
import { has } from 'lodash';
import { getOONGroupNameFromField } from 'src/components/Referrals/ReferralFormFields/OONGroupsSelector/utils';

export default function structureOONProviders(groups = []) {
  return groups.map((provider = {}) => {
    const value = has(provider.group, 'value') ? provider.group.value : provider.group;
    const isCustom = value.isCustom || value.provider_type === 'CUSTOM' || typeof value === 'string';
    const programId = provider.program?.id ?? provider.program?.value?.id;
    return {
      provider_id: isCustom ? null : value.id,
      provider_type: isCustom ? 'CUSTOM' : value.provider_type,
      custom_name: isCustom ? decodeHTML(getOONGroupNameFromField(provider)) : null,
      ...(programId ? { program_id: programId } : null),
    };
  });
}
