function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { assign, get, has, isEmpty, reduce, set } from 'lodash';
import { stripHTML } from "./sanitize";
function buildChoices(options, valueKey, labelKey, multi, placeholder) {
  var choices = reduce(options, function (acc, option) {
    var choice = assign({}, {
      label: stripHTML(get(option, labelKey, '')),
      value: stripHTML(get(option, valueKey, '')),
      disabled: get(option, 'disabled'),
      customProperties: get(option, 'customProperties')
    });
    if (has(option, 'children') && !isEmpty(option.children)) {
      var children = buildChoices(option.children, valueKey, labelKey);
      set(choice, 'choices', children);
    }
    return [].concat(_toConsumableArray(acc), [choice]);
  }, []);
  if (!multi && placeholder) {
    return [{
      label: placeholder,
      value: '',
      placeholder: true
    }].concat(_toConsumableArray(choices));
  }
  return choices;
}
export default buildChoices;