import { get, isEmpty, keys } from 'lodash';
import { uuOmit, uuCompactArrayOrObject } from "../../utils/extendLodash";
function keyForInvalidAddressTypeOmission(type) {
  return ![type, get(type, 'value')].includes('ours') ? null : 'addressType';
}
function keyForInvalidDistanceOmission(distance) {
  return ['', 'any'].includes(distance) || isEmpty(distance) ? 'distance' : null;
}
var createFilterTrackingObject = function createFilterTrackingObject(filters) {
  if (isEmpty(filters)) {
    return [];
  }
  var trackingFilters = uuOmit(filters, ['address', keyForInvalidDistanceOmission(filters.distance), keyForInvalidAddressTypeOmission(filters.addressType), 'includeHomeGroups', 'networks', 'contextAction']);
  return keys(uuCompactArrayOrObject(trackingFilters));
};
export default createFilterTrackingObject;