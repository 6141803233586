import { isEmpty } from 'lodash';
import {
  isCurrentLocation,
  isCurrentSection,
} from './index';
import { updateElement } from '../updateElements';

const stateOrUpdatedState = (state, element, toUpdateId, currentSection, currentLocation) => {
  if (isEmpty(state)) {
    return undefined;
  }

  return (section) => {
    if (isCurrentLocation(currentLocation, section) && isCurrentSection(currentSection, section)) {
      return updateElement(state, toUpdateId, element, section);
    }

    return state;
  };
};

export { stateOrUpdatedState };
