import React from 'react';
import PropTypes from 'prop-types';
import { TableRowColumn } from '@unite-us/ui';
import { DynamicTableRowColumnData } from '.';
import getColumnStyles from '../styles/getColumnStyles';

const DynamicTableRowColumn = ({
  columnLabel,
  data,
  ...props
}) => (
  <TableRowColumn
    {...props}
    className="dynamic-table__row-column"
    style={getColumnStyles(columnLabel)}
  >
    <DynamicTableRowColumnData
      columnLabel={columnLabel}
      data={data}
      initClick={columnLabel.initClick}
      link={columnLabel.link}
    />
  </TableRowColumn>
);

DynamicTableRowColumn.propTypes = {
  columnLabel: PropTypes.shape({
    initClick: PropTypes.func,
    link: PropTypes.bool,
  }).isRequired,
  data: PropTypes.object.isRequired,
  style: PropTypes.object,
};

DynamicTableRowColumn.defaultProps = {
  style: {},
};

export default DynamicTableRowColumn;
