import { get } from 'lodash';

const dateDisputeResolved = (versions = []) => {
  const resolvedDisputedInvoiceVersion = (version) => {
    const changesIncludeSetInvoiceUnderDisputeResolved = get(version, 'object_changes.under_dispute[1]') === false;
    const changesIncludeSetInvoiceDisputeResolutionDate =
      get(version, 'object_changes.invoice_dispute_resolution_date[1]');

    return changesIncludeSetInvoiceUnderDisputeResolved && !!changesIncludeSetInvoiceDisputeResolutionDate;
  };

  const underDisputeResolvedInvoiceVersion = versions.find(resolvedDisputedInvoiceVersion);
  const invoiceDisputeResolutionDate =
    get(underDisputeResolvedInvoiceVersion, 'object_changes.invoice_dispute_resolution_date[1]');

  if (invoiceDisputeResolutionDate) {
    const date = new Date(Date.parse(invoiceDisputeResolutionDate));
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  }

  return null;
};

export default dateDisputeResolved;
