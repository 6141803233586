import { browserHistory } from 'common/utils/browserHistory';

export default function goToOpenCaseViaOpenCasesIndex({ serviceCase, contactId }) {
  // First redirect to the open cases index before going to the case route, so
  // a back click will take the user to the open cases index.
  if (serviceCase.state === 'managed') {
    browserHistory.push('/dashboard/cases/open');
    browserHistory.push(`/dashboard/cases/open/${serviceCase.id}/contact/${contactId}`);
  }
  if (serviceCase.state === 'off_platform') {
    browserHistory.push('/dashboard/oon-cases/open');
    browserHistory.push(`/dashboard/oon-cases/open/${serviceCase.id}/contact/${contactId}`);
  }
}
