import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchAssessment } from 'actions/Assessment/Case/Group';
import AssessmentDetailsView from 'src/components/Contacts/components/AssessmentDetailsView';
import { restrictToSubmissionContext } from 'common/utils/FeatureFlags/flags';

export class CasesAssessmentDetailsView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assessment: null,
      editMode: props.editMode,
    };

    this.fetchAssessment = this.fetchAssessment.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.fetchAssessment();
  }

  // eslint-disable-next-line class-methods-use-this
  onCancel() {
    window.history.back();
  }

  fetchAssessment() {
    const {
      groupId, params, id, restrictContext,
    } = this.props;

    this.props.fetchAssessment(groupId, params.id, id, restrictContext)
      .then((payload) => {
        this.setState({
          assessment: payload.data.data,
        });
      });
  }

  render() {
    const { assessment, editMode } = this.state;
    const { id: caseID, submission_id: submissionId } = this.props.params;
    if (!assessment) {
      return null;
    }

    return (
      <AssessmentDetailsView
        assessment={assessment}
        submissionId={submissionId}
        showEditButton={false}
        afterSubmit={this.onCancel}
        editMode={editMode}
        onCancel={this.onCancel}
        contextType="case"
        context={caseID}
      />
    );
  }
}

const {
  func, bool, string, object,
} = PropTypes;

CasesAssessmentDetailsView.propTypes = {
  id: string.isRequired,
  groupId: string.isRequired,
  params: object.isRequired,
  editMode: bool,
  fetchAssessment: func.isRequired,
  restrictContext: bool,
};

CasesAssessmentDetailsView.defaultProps = {
  editMode: false,
  restrictContext: false,
};

function mapStateToProps(state, ownProps) {
  const restrictContext = restrictToSubmissionContext(state);
  const editMode = !ownProps.params.submission_id;
  return {
    id: ownProps.params.assessment_id,
    groupId: state.session.groupId,
    restrictContext,
    editMode,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchAssessment,

  },
)(CasesAssessmentDetailsView);
