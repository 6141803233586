import React from 'react';

const displaySavedClaimCodes = (data = []) => {
  const buildCodes = data.map((code) => (
    <span key={code?.id} className="block mb-1.5">
      {`${code?.code} - ${code?.description}`}
    </span>
  ));

  return buildCodes;
};

export default displaySavedClaimCodes;
