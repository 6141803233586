import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { get } from 'lodash';
import UploadedDocumentModal from 'src/components/Facesheet/Uploads/components/UploadedDocumentModal';
import { FileThumbnail } from '@unite-us/client-utils';
import RemoveDocument from './RemoveDocument';
import RenameDocument from './RenameDocument';

class ListViewDocument extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentClicked: false,
      renameDocumentClicked: false,
      removing: false,
    };

    this.onRemoveDocument = this.onRemoveDocument.bind(this);
    this.openPdf = this.openPdf.bind(this);
  }

  onRemoveDocument() {
    this.setState({ removing: true });
    return Promise.resolve(this.props.onRemoveDocument(this.props.doc))
      .then(() => this.setState({ removing: false }));
  }

  openPdf() {
    this.setState({ documentClicked: true }, () => {
      this.uploadedDocumentModal.dialog.openDialog();
    });
  }

  render() {
    const {
      doc,
      editable,
      iconStyle,
      id,
    } = this.props;
    const { documentClicked, renameDocumentClicked, removing } = this.state;
    const documentTitle = doc.title || doc.name || 'Untitled';
    // Only use the doc.canRename and doc.canRemove properties as overrides if
    // the properties exist. Otherwise, fall back to the editable prop.
    const canRemove = 'canRemove' in doc ? get(doc, 'canRemove', false) : editable;
    const canRename = 'canRename' in doc ? get(doc, 'canRename', false) : editable;

    if (removing) {
      return null;
    }

    return (
      <li className="list-view-document detail-upload">
        <FileThumbnail
          contentType={doc.content_type}
          style={iconStyle}
        />
        <div className="list-view-document__title">
          {
            doc.pdf_url ? (
              <a
                id={`${id}-link`}
                role="button"
                onClick={this.openPdf}
              >
                {documentTitle}
              </a>
            ) : <span>{documentTitle}</span>
          }
          {
            editable && (
              <RenameDocument
                id={`rename-list-view-document-${id}`}
                canRename={canRename}
                doc={doc}
                onRenameDocument={this.props.onRenameDocument}
                showDialog={renameDocumentClicked}
              />
            )
          }
          {
            editable && (
              <RemoveDocument
                id={`remove-list-view-document-${id}`}
                canRemove={canRemove}
                onRemoveDocument={this.onRemoveDocument}
                removing={removing}
              />
            )
          }
        </div>
        <UploadedDocumentModal
          ref={(el) => { this.uploadedDocumentModal = el; }}
          doc={doc}
          documentClicked={documentClicked}
        />
      </li>
    );
  }
}

ListViewDocument.propTypes = {
  doc: PropTypes.shape({
    canRemove: PropTypes.bool,
    canRename: PropTypes.bool,
    content_type: PropTypes.string.isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    pdf_url: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  editable: PropTypes.bool,
  iconStyle: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onRemoveDocument: PropTypes.func,
  onRenameDocument: PropTypes.func,
};

ListViewDocument.defaultProps = {
  editable: false,
  iconStyle: {
    svg: {
      height: '16px',
      width: '16px',
      marginRight: '10px',
      fill: '#4A657F',
    },
  },
};

export default ListViewDocument;
