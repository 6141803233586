import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { Icon, Button } from '@unite-us/ui';
import AssistanceRequestContactCard from 'src/components/AssistanceRequests/components/AssistanceRequestDetail/AssistanceRequestContactCard';

function AssistanceRequestSelectClient(props) {
  const {
 className, clientMatches, onCreateNewClient, onUseThisRecord, creatingNewCase,
} = props;
  const SELECT_CLIENT_MESSAGE = `We found existing client records that might 
  match the assistance request. Compare the information in the sidebar to the right 
  with the existing records below. If they're not a match, click Create New Client.`;

  return (
    <div className={cx(className, 'p-6 border border-solid rounded border-green bg-light-green')}>
      <div className="flex items-center gap-2">
        <Icon icon="IconInfoCircle" className="w-3.5 h-3.5" color={'#182A44'} />
        <h3 className="text-base font-black text-text-blue">Select Client</h3>
      </div>
      <p className="py-2 leading-normal">{ SELECT_CLIENT_MESSAGE }</p>
      <ul className="flex flex-wrap justify-evenly content-start list-unstyled">
        {
          clientMatches.map((client) => (
            <AssistanceRequestContactCard
              key={client.id}
              contact={client}
              onSelectContact={onUseThisRecord}
            />
            ))
        }
      </ul>
      <div className="flex justify-center mt-4">
        <Button
          id="create-new-client"
          onClick={onCreateNewClient}
          label="Create New Client"
          primary
          disabled={creatingNewCase}
        />
      </div>
    </div>
  );
}

AssistanceRequestSelectClient.propTypes = {
  className: PropTypes.string,
  clientMatches: PropTypes.array,
  onCreateNewClient: PropTypes.func.isRequired,
  onUseThisRecord: PropTypes.func.isRequired,
  creatingNewCase: PropTypes.bool.isRequired,
};

AssistanceRequestSelectClient.defaultProps = {
  className: '',
  clientMatches: [],
};

export default AssistanceRequestSelectClient;
