import Notifier from 'common/helpers/Notifier';
import createNote from './createNote';

function createContactServiceCaseInteraction({
  subjectType = 'case',
  subject,
  interaction,
  opts = {},
}) {
  return async (dispatch) => {
    try {
      const response = await dispatch(createNote({
        interaction,
        opts,
        subjectType,
        subject,
      }));

      return response;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}

export default createContactServiceCaseInteraction;
