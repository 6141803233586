import _ from 'lodash';

export default function filterBrowseGroupIds(groups, selectedGroupFields) {
  const selectedFieldGroupIds = _.map(selectedGroupFields, 'group.value.id'); // Previously Selected Groups
  const returnedGroupIds = _.map(groups, 'id'); // All Group Results

  // Find the ids already selected before Browse
  const intersection = _.intersection(returnedGroupIds, selectedFieldGroupIds);
  // Find the ids that user has selected to remove
  const toRemoveIds = _.difference(selectedFieldGroupIds, intersection);
  const toAddIds = _.difference(returnedGroupIds, intersection);

  return {
    toRemoveIds,
    toAddIds,
    returnedGroupIds,
  };
}
