import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { map, get, filter } from 'lodash';
import { Field, useField, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { validations } from '@unite-us/app-components';
import { InputField, SelectField } from '@unite-us/ui';
import ButtonAddField from './ButtonAddField';
import ButtonRemoveField from './ButtonRemoveField';
import {
  PHONE_TYPES,
  USER_PHONE_TYPES,
} from '../constants';

const PhoneNumbersField = ({
  name,
  required,
  initializeWithBlankPhoneNumber,
  userPhoneTypes,
  withExtension,
}) => {
  const { input: { value: phoneFieldValues } } = useField(name);
  const { mutators: { push } } = useForm();
  const phoneTypes = userPhoneTypes ? [...USER_PHONE_TYPES] : [...PHONE_TYPES];

  useEffect(() => {
    if (!phoneFieldValues.length && initializeWithBlankPhoneNumber) {
      push(name, {});
    }
  }, [phoneFieldValues.length]);

return (
  <div className="relative">
    <ButtonAddField
      data-test-element="add_phone"
      isPositioned
      label="Add Phone"
      onClick={() => push(name, {})}
    />
    <FieldArray name="phone_numbers">
      {({ fields }) => (fields.length ? fields.map((fieldName, index) => (
        <div
          key={`org-fieldset-${fieldName}`}
          data-testid="phone_numbers_row"
          className="flex phone_numbers_row"
        >
          <div
            className="w-8/12 mr-2"
            title="Phone Number"
          >
            <Field
              name={`${fieldName}.phone_number`}
              validate={(value, { phone_numbers }) => {
                if (!value && phone_numbers[index]?.phone_type) {
                  return validations.isRequired();
                }
                if (value) {
                  const phones = map(phone_numbers, (p) => get(p, 'phone_number', '').replace(/[^0-9]/g, ''));
                  const hasDuplicatePhone = filter(phones, (p) => p === value.replace(/[^0-9]/g, '')).length > 1;
                  if (hasDuplicatePhone) {
                    return 'Phone already exists on this profile.';
                  }
                  return validations.isPhoneNumber(value.replace(/[^0-9]/g, ''));
                }
                if (required) {
                  return validations.isRequired(value);
                }
                return undefined;
              }}
            >
              {(params) => {
              const paramsToPass = !params.meta.valid ? {
                ...params,
                meta: {
                  ...params.meta,
                  touched: true,
                },
              } : params;
                return (
                  <InputField
                    required={required && index === 0}
                    label="Phone Number"
                    mask="(999) 999-9999"
                    placeholder="(xxx) xxx-xxxx"
                    hideLabel={!!index}
                    id={`phone-number-${index}`}
                    {...paramsToPass}
                  />
                );
              }}
            </Field>
          </div>

          { withExtension && (
            <div
              className="w-6/12 mr-2"
              title="Phone Number Extension"
            >
              <Field
                name={`${fieldName}.extension`}
                validate={(value) => {
                    if (value) {
                      return validations.isNumber(value);
                    }
                    return undefined;
                  }}
              >
                {(params) => {
                const paramsToPass = !params.meta.valid ? {
                  ...params,
                  meta: {
                    ...params.meta,
                    touched: true,
                  },
                } : params;
                  return (
                    <InputField
                      required={required && index === 0}
                      label="Phone Number Extension"
                      mask=""
                      placeholder="ext."
                      hideLabel={!!index}
                      id={`phone-ext-${index}`}
                      {...paramsToPass}
                    />
                  );
                }}
              </Field>
            </div>
          )}
          <div
            className="w-4/12 mr-2"
            title="Phone Type"
          >
            <Field
              name={`${fieldName}.phone_type`}
              validate={(value, { phone_numbers }) => {
                if (!value && phone_numbers[index]?.phone_number) {
                  return validations.isRequired();
                }
                return undefined;
              }}
            >
              {(params) => {
                const paramsToPass = !params.meta.valid ? {
                  ...params,
                  meta: {
                    ...params.meta,
                    touched: true,
                  },
                } : params;
              return (
                <SelectField
                  label="Phone Type"
                  hideLabel={!!index}
                  placeholder="Type"
                  id={`phone-type-${index}`}
                  options={phoneTypes.map((type) => ({ label: type, value: type.toLowerCase() }))}
                  {...paramsToPass}
                />
                );
              }}
            </Field>
          </div>
          <div className="w-6/12 flex" data-test-element={`phone_${index}`}>
            {(!required || fields.length > 1) && (
              <ButtonRemoveField
                onClick={() => fields.remove(index)}
                testId={`phone_trash_${index}`}
              />
            )}
            <div className="w-5/12 " />
          </div>
        </div>
      )) : <div className="h-16" />)}
    </FieldArray>
  </div>
  );
};

PhoneNumbersField.propTypes = {
  initializeWithBlankPhoneNumber: PropTypes.bool,
  name: PropTypes.string.isRequired,
  userPhoneTypes: PropTypes.bool,
  required: PropTypes.bool,
  withExtension: PropTypes.bool,
};

PhoneNumbersField.defaultProps = {
  initializeWithBlankPhoneNumber: false,
  userPhoneTypes: false,
  required: false,
  withExtension: true,
};

export default PhoneNumbersField;
