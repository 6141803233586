import { reduce } from 'lodash';

function getAttachedDocumentsFromField(attachableDocumentsField = []) {
  const attached = reduce(attachableDocumentsField, (acc, curr) => (
    curr.attached.value ? [...acc, curr.document.value] : acc
  ), []);

  return attached;
}

export default getAttachedDocumentsFromField;
