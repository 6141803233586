import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Dialog,
} from '@unite-us/ui';

export class LeavePageDialog extends React.Component {
  constructor(props) {
    super(props);

    this.closeDialog = this.closeDialog.bind(this);
  }

  closeDialog() {
    this.dialog.closeDialog();
  }

  render() {
    const dialog = (
      <Dialog
        id="leave-page-dialog"
        ref={(el) => { this.dialog = el; }}
        size="mini"
        actions={(
          <div>
            <Button
              id="leave-page-cancel-btn"
              label="Cancel"
              onClick={this.closeDialog}
            />
            <Button
              id="leave-page-leave-btn"
              onClick={this.props.onLeave}
              label="Leave"
              primary
              style={{ marginLeft: '10px' }}
            />
          </div>
        )}
      >
        <p>{this.props.text}</p>
      </Dialog>
    );

    return dialog;
  }
}

LeavePageDialog.propTypes = {
  text: PropTypes.string.isRequired,
  onLeave: PropTypes.func.isRequired,
};

LeavePageDialog.defaultProps = {
  text: 'You have unsaved changes. Are you sure you want to leave this page?',
};

export default LeavePageDialog;
