import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { get, isEmpty, noop } from 'lodash';
import {
  DateField,
  DateTimeField,
  InputField,
  SelectField,
  TextField,
} from '@unite-us/ui';
import { validations } from '@unite-us/app-components';
import { theme } from 'src/../tailwind.config';
import AddressFieldFinalForm from 'common/form/AddressField/AddressFieldFinalForm';
import CurrencyField from 'common/form/CurrencyField';
import { SHOW_STREET_CITY_STATE_POSTAL_CODE } from 'common/form/AddressField/constants';

const METAFIELD_COMPONENTS = {
  address: AddressFieldFinalForm,
  date: DateField,
  datetime: DateTimeField,
  dropdown: SelectField,
  multipledropdown: SelectField,
  money: CurrencyField,
  number: InputField,
  text: InputField,
  textarea: TextField,
  uuid: InputField,
};

const HelpText = ({ text }) => (
  /* eslint-disable react/no-danger */
  <div
    className="payments-track-service__help-text"
    dangerouslySetInnerHTML={{ __html: text }}
  />
  /* eslint-enable react/no-danger */
);
HelpText.propTypes = {
  text: PropTypes.string.isRequired,
};

const validateField = (required, value = {}, type) => {
  if (type === 'uuid' && !isEmpty(value)) {
    return validations.isValidUUID;
  }
  if (required) {
    return validations.isRequired;
  }
  return noop;
};

const MetaFields = ({ metafields, enforceRequiredFields }) => (
  metafields.map(({
    field: fieldKey,
    help_text,
    label,
    options,
    type,
    validations: metafieldValidations,
  }) => {
    const dropdownOptions = (options || []).map((option) => ({
      field: option,
      value: option,
    }));
    /*
      The metafield is required
        * if the validations required key is true AND
        * if enforceRequiredFields === true
          - the fee schedule program is non-billable or the user submits the contracted service
    */
    const required = get(metafieldValidations, 'required', false) && enforceRequiredFields;
    // eslint-disable-next-line react/no-danger
    const hint = isEmpty(help_text) ? '' : <HelpText text={help_text} />;
    const MetafieldComponent = METAFIELD_COMPONENTS[type];
    switch (type) {
      case 'file':
        return null;
      case 'address':
        return (
          <div key={fieldKey}>
            <label htmlFor={`${fieldKey}-address-field`} className="ui-form-field__label">{label}</label>
            <MetafieldComponent
              addressPath={`metafields.${fieldKey}`}
              displayFieldSet={SHOW_STREET_CITY_STATE_POSTAL_CODE}
              forceRequired={required}
              hideMailingField
              id={`${fieldKey}-address-field`}
              inline={false}
              labelStyle={{
                color: theme.extend.colors['text-blue'],
                fontFamily: 'Proxima Nova Medium',
                fontSize: '13px',
                textTransform: 'none',
              }}
              requireAddressLine1={required}
            />
          </div>
        );
      default:
        return (
          <Field
            name={`metafields.${fieldKey}`}
            key={fieldKey}
            validate={(value) => validateField(required, value, type)(value)}
          >
            {(fieldProps) => (
              <MetafieldComponent
                id={fieldKey}
                hint={hint}
                inline={false}
                label={label}
                labelKey="field"
                multiple={type === 'multipledropdown'}
                options={dropdownOptions}
                required={required}
                type={type}
                valueKey="value"
                {...fieldProps}
              />
            )}
          </Field>
        );
    }
  })
);

MetaFields.propTypes = {
  metafields: PropTypes.array,
  enforceRequiredFields: PropTypes.bool.isRequired,
};

MetaFields.defaultProps = {
  metafields: [],
};

export default MetaFields;
