import PropTypes from 'prop-types';
import React from 'react';
import { Button, Dialog } from '@unite-us/ui';

export class RemoveReferralConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.closeDialog = this.closeDialog.bind(this);
  }

  closeDialog(txt) {
    return () => {
      this.dialog.closeDialog(txt);
    };
  }

  render() {
    const { text } = this.props;

    return (
      <Dialog
        className="remove-referral-confirmation-dialog"
        id="remove-referral-dialog"
        ref={(el) => { this.dialog = el; }}
        size="mini"
        actions={(
          <div>
            <Button
              id="remove-referral-cancel-btn"
              label="Cancel"
              onClick={this.closeDialog()}
            />
            <Button
              id="remove-referral-remove-btn"
              onClick={this.closeDialog('delete')}
              label="Remove"
              primary
              style={{ marginLeft: '10px' }}
            />
          </div>
        )}
      >
        <p>{text}</p>
      </Dialog>
    );
  }
}

RemoveReferralConfirmationDialog.propTypes = {
  text: PropTypes.string,
};

RemoveReferralConfirmationDialog.defaultProps = {
  text: 'Are you sure you want to remove this referral?',
};

export default RemoveReferralConfirmationDialog;
