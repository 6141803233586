import { capitalize } from 'lodash/string';
import { useFilterSearchParamWithFF } from 'common/hooks/useFilterSearchParam';
import { useCurrentPayerId } from 'common/contexts/CurrentProviderContext';
import { useFind } from 'src/api/APIHooks';
import { REQUEST_TYPES } from '../constants';

export const useEnrollmentFilters = () => {
  const currentPayerId = useCurrentPayerId();

  const { data: planData } = useFind('plan', {
    payer: currentPayerId,
    plan_type: 'social',
    enrollment_required: true,
  }, { queryConfig: { placeholderData: undefined } });
  const plans = planData?.data?.data ?? [];
  const planOptions = plans.map((plan) => ({
    label: plan.name,
    value: plan.id,
  }));

  const [requestType, setRequestType] = useFilterSearchParamWithFF(
    'request_type',
    undefined,
    'pays-6096-update-ewq-design',
  );
  const [plan, setPlan] = useFilterSearchParamWithFF(
    'plan',
    undefined,
    'pays-6096-update-ewq-design',
  );
  const [daysSinceLastUpdated, setDaysSinceLastUpdated] = useFilterSearchParamWithFF(
    'days_since_last_updated',
    undefined,
    'pays-6096-update-ewq-design',
  );
  const [clientName, setClientName] = useFilterSearchParamWithFF(
    'client_name',
    undefined,
    'pays-6096-update-ewq-design',
  );

  const isFiltered = !!requestType || !!plan || !!daysSinceLastUpdated || !!clientName;

  const resetFilters = () => {
    setRequestType(undefined);
    setPlan(undefined);
    setDaysSinceLastUpdated(undefined);
    setClientName(undefined);
  };

  const daysSinceLastUpdatedOptions = [
    {
      label: 'Today',
      value: '1',
    },
    {
      label: 'Last 7 days',
      value: '7',
    },
    {
      label: 'Last 14 days',
      value: '14',
    },
    {
      label: 'Last 30 days',
      value: '30',
    },
    {
      label: 'Last 60 days',
      value: '60',
    },
  ];

  return {
    filterValues: {
      requestType,
      plan,
      daysSinceLastUpdated,
      clientName,
    },
    filterOptions: {
      requestType: Object.values(REQUEST_TYPES).map((type) => (
        { value: type, label: capitalize(type) }
      )),
      plan: planOptions,
      daysSinceLastUpdated: daysSinceLastUpdatedOptions,
    },
    isFiltered,
    resetFilters,
    setRequestTypeFilter: setRequestType,
    setPlanFilter: setPlan,
    setDaysSinceLastUpdatedFilter: setDaysSinceLastUpdated,
    setClientNameFilter: setClientName,
  };
};
