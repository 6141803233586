import { generateUUID } from 'common/utils/utils';

export default function defaultPaymentsInsurance() {
  return {
    form_id: generateUUID(),
    id: '',
    plan: '',
    plan_type: '',
  };
}
