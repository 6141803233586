import PropTypes from 'prop-types';
import React from 'react';
import featureFlag from 'common/utils/FeatureFlag/FeatureFlag';
import { CheckBoxField } from '@unite-us/ui';
import { noop } from 'lodash';
import canPoolReferrals from './utils/canPoolReferrals';

export const AutoRecallableCheckBoxField = ({
  field, flagEnabled, referralScope, suggestedGroups,
  selectedGroupCount, style, index, ...props
}) => (
  canPoolReferrals({
    flagEnabled, referralScope, suggestedGroups, selectedGroupCount,
  }) ? (
    <CheckBoxField
      id={`send-referral-auto-recallable-checkbox${index !== undefined ? `-${index}` : ''}`}
      field={field}
      style={style}
      label="Automatically recall outstanding referrals after the first is accepted"
      showError={false}
      showHint={false}
      {...props}
    />
    ) : null
);

AutoRecallableCheckBoxField.propTypes = {
  field: PropTypes.object.isRequired,
  flagEnabled: PropTypes.func.isRequired,
  index: PropTypes.number,
  onChange: PropTypes.func,
  suggestedGroups: PropTypes.array,
  referralScope: PropTypes.object,
  selectedGroupCount: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
};

AutoRecallableCheckBoxField.defaultProps = {
  index: undefined,
  onChange: noop,
  selectedGroupCount: 0,
  style: {},
};

export default featureFlag(AutoRecallableCheckBoxField);
