import PropTypes from 'prop-types';
import React from 'react';
import {
  get, isEmpty, map, noop, orderBy,
} from 'lodash';
import EmptyContactDocuments from './EmptyContactDocuments';
import ContactDocumentCard from './ContactDocumentCard';
import './ContactDocuments.scss';

function orderByTitle(documents = []) {
  return orderBy(
    documents,
    (doc) => (get(doc, 'title', '').toLowerCase()),
  );
}

function ContactDocuments(props) {
  const { documents, showOptions, styles } = props;

  const contactDocCards = map(orderByTitle(documents), (doc = {}, i) => (
    <ContactDocumentCard
      id={`uploaded-contact-document-${i}`}
      key={`${doc.id}-${i}`}
      doc={doc}
      onRemoveDocument={props.onRemove}
      onRenameClick={props.onRename}
      showOptions={showOptions}
    />
  ));

  return (
    <div className="contact-documents">
      <EmptyContactDocuments documents={documents} styles={styles} />
      {
        !isEmpty(contactDocCards) && (
          <div className="contact-documents__client-wrapper">
            <h2 className="mb-half">
              Client attachments
            </h2>
            {contactDocCards}
          </div>
        )
      }
    </div>
  );
}

ContactDocuments.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  onRemove: PropTypes.func,
  onRename: PropTypes.func,
  showOptions: PropTypes.bool,
  styles: PropTypes.shape({
    iconArrow: PropTypes.object,
  }),
};

ContactDocuments.defaultProps = {
  onRename: noop,
  onRemove: noop,
  showOptions: true,
  styles: {
    iconArrow: {
      svg: {
        width: '10em',
        height: '10em',
      },
    },
  },
};

export default ContactDocuments;
