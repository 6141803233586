import _ from 'lodash';
import today from 'common/utils/today';
import { formatDateFilterDate } from '.';

export default function dateFilterText(dateRange = {}, activeTab) {
  const compactedDateRange = _.uuCompactArrayOrObject(dateRange);
  const { start_date, end_date } = compactedDateRange;

  if (activeTab === 'network-performance' || _.isEmpty(compactedDateRange)) {
    return '';
  }
  if (!end_date) {
    return `Showing data from ${formatDateFilterDate(start_date)} to ${formatDateFilterDate(today())}`;
  }
  if (!start_date) {
    return `Showing data up to ${formatDateFilterDate(end_date)}`;
  }
  return `Showing data from ${formatDateFilterDate(start_date)} to ${formatDateFilterDate(end_date)}`;
}
