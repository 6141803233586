import {
  isEmpty,
  head,
  tail,
} from 'lodash';
import { addElement } from './addElements';
import handleAssistanceRequest from './handleAssistanceRequest';

export function addCase(state, element, currentSection) {
  const postRemoveState = handleAssistanceRequest(state, element, currentSection);
  switch (currentSection) {
    case 'allCases':
      return addElement(postRemoveState, element, 'allCases');
    case 'openCases':
      return addElement(postRemoveState, element, 'openCases');
    default:
      return postRemoveState;
  }
}

export default function addCases(state, payload = [], currentSection) {
  if (isEmpty(payload)) { return state; }
  return addCases(addCase(state, head(payload), currentSection), tail(payload), currentSection);
}
