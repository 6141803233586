import Notifier from 'common/helpers/Notifier';

const errorMessageForInsuranceDate = (planName) => (
  'This referral and authorization request cannot be sent because the service delivery' +
  ' dates requested fall outside the client\'s enrollment dates for the ' +
  `${planName} plan. Please edit the service delivery dates.`
);

const errorHandlers = {
  'must be after the insurance enrollment date': (planName) => (errorMessageForInsuranceDate(planName)),
  'must be before the insurance enrollment date': (planName) => (errorMessageForInsuranceDate(planName)),
  'request exceeds cap for given dates': () => (
    'This referral and authorization request cannot be sent because the amount requested is greater than ' +
    'the amount available for authorization. Please edit the amount requested.'
  ),
};

export default function coreErrorHandler(error, planName) {
  const errorsTitle = error.response
    ?.data
    ?.errors;
  if (errorsTitle?.length) {
    const formatedErrorsTitle = errorsTitle.reduce((prev, e) => {
      const message = errorHandlers[e.title] ? errorHandlers[e.title](planName) : e.title;
      return `${prev + message} `;
    }, '');
    Notifier.dispatch('error', formatedErrorsTitle, 10000);
  } else {
    Notifier.handleErrors(error);
  }
}
