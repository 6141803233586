import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'common/spinners';
import _ from 'lodash';
import { Icon } from '@unite-us/ui';
// import { pluralize } from 'humanize-plus';
import './PaginationLoader.scss';

function hasMorePages(pagingObject) {
  return pagingObject.next_page;
}

function morePagesText(/* pagingObject */) {
  // const remainingResults = //TODO: commented because CW-144
  //   pagingObject.total_count - pagingObject.current_page * pagingObject.per;
  // return remainingResults >= pagingObject.per ?
  //   `See ${pagingObject.per} more results` :
  //   `See ${remainingResults} more ${pluralize(remainingResults, 'result')}`;
  return 'See more results';
}

const PaginationLoader = ({
  appendData,
  emptyMessage,
  isFetching,
  paging,
  serviceAreaSupportForOrgsFlag,
}) => {
  if (isFetching) {
    return <Spinner />;
  }

  if (hasMorePages(paging) && !_.isEmpty(paging)) {
    return (
      <a
        className={serviceAreaSupportForOrgsFlag ? 'group_list_more_results' : 'detail-load'}
        onClick={appendData} role="button"
      >
        {serviceAreaSupportForOrgsFlag ?
          (
            <>
              {morePagesText()}
              <Icon
                style={
                  {
                    svg:
                    {
                      width: '11px',
                      transform: 'rotate(270deg)',
                      marginLeft: '6px',
                      fill: '#4467AB',
                    },
                  }
                }
                icon="IconChevronLeft"
              />
            </>
          ) : <small>Load More</small>}
      </a>
    );
  }

  return _.isString(emptyMessage) ? <p>{emptyMessage}</p> : emptyMessage;
};

PaginationLoader.propTypes = {
  appendData: PropTypes.func.isRequired,
  emptyMessage: PropTypes.node,
  isFetching: PropTypes.bool.isRequired,
  paging: PropTypes.object.isRequired,
  serviceAreaSupportForOrgsFlag: PropTypes.bool,
};

PaginationLoader.defaultProps = {
  emptyMessage: 'No More Results',
  isFetching: false,
  paging: {},
  serviceAreaSupportForOrgsFlag: false,
};

export default PaginationLoader;
