import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { ActionSelect } from '@unite-us/app-components';
import {
  Button,
} from '@unite-us/ui';

const SINGLE_ACTION = 1;

class ReferralActionButton extends Component {
  render() {
    const {
      actions,
      disabled,
      styles,
      onChange,
    } = this.props;

    const actionSelectContainer = _.first(document.getElementsByClassName('action-select-container'));
    if (actionSelectContainer !== undefined) {
      const referralActionContents = actionSelectContainer.getElementsByClassName('choices__item');
      _.each(referralActionContents, (referralAction) => {
        referralAction.setAttribute('aria-haspopup', 'dialog');
      });
    }

    if (_.isEmpty(actions.data)) {
      return null;
    }

    if (_.isEqual(actions.data.length, SINGLE_ACTION)) {
      const firstAction = _.head(actions.data);

      return (
        <Button
          id={`${_.get(firstAction, 'className', 'single-action')}-btn`}
          primary
          onClick={firstAction.action}
          label={firstAction.label}
          disabled={disabled}
        />
      );
    }

    return (
      <ActionSelect
        disabled={disabled}
        actions={actions.data}
        id="referral-action-select"
        onChange={onChange}
        styles={styles}
        label="Take Action"
        ref={(actionSelect) => {
          if (actionSelect) {
            this.actionSelect = actionSelect;
          }
        }}
      />
    );
  }
}

ReferralActionButton.propTypes = {
  actions: PropTypes.shape({
    data: PropTypes.array.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  styles: PropTypes.shape({
    selectStyle: PropTypes.object.isRequired,
  }),
};

ReferralActionButton.defaultProps = {
  disabled: false,
  onChange: _.noop,
  styles: {
    selectStyle: {
      minWidth: '265px',
    },
  },
};

export default ReferralActionButton;
