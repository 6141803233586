import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hasPayerInvoicesRole as getHasPayerInvoicesRole } from 'common/utils/FeatureFlags/flags';
import { NoInvoicesFoundCard } from '../components';
import { CLOSED_NL_STATUSES, CLOSED_PAYER_STATUSES } from '../constants';
import InvoiceWorkqueue from './InvoiceWorkqueue';

const ClosedInvoices = ({ children, hasPayerInvoicesRole }) => {
  const invoiceStatus = hasPayerInvoicesRole ? CLOSED_PAYER_STATUSES : CLOSED_NL_STATUSES;
  const statusOptions = hasPayerInvoicesRole ? 'CLOSED_PAYER_STATUS_OPTIONS' : 'CLOSED_STATUS_OPTIONS';

  const bulkActions = ['IconDownloadV2', 'IconArchive'];

  return (
    <InvoiceWorkqueue
      bulkActions={bulkActions}
      invoiceStatus={invoiceStatus}
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <NoInvoicesFoundCard isFiltered={isFiltered} status="open" resetFilters={resetFilters} />
      )}
      showStatusFilter
      statusOptionsConstant={statusOptions}
      path="closed/"
    >
      {children}
    </InvoiceWorkqueue>
  );
};

ClosedInvoices.propTypes = {
  children: PropTypes.node,
  hasPayerInvoicesRole: PropTypes.bool,
};

ClosedInvoices.defaultProps = {
  children: undefined,
  hasPayerInvoicesRole: false,
};

const mapStateToProps = (state) => ({
  hasPayerInvoicesRole: getHasPayerInvoicesRole(state),
});

export default connect(mapStateToProps)(ClosedInvoices);
