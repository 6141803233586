// Library Imports
import React from 'react';
import { InfoPanel } from '@unite-us/client-utils';
import 'src/components/ConsentReminder/stylesheets/consent-reminder.scss';

const CONSENT_REMINDER_MESSAGE = 'Ready to submit? Great! By clicking ' +
'CONFIRM and SUBMIT, you confirm that the client agrees to share their ' +
'information with the receiving organization(s).';

const ConsentReminder = () => <InfoPanel className="consent-reminder mb-4" message={CONSENT_REMINDER_MESSAGE} />;

export default ConsentReminder;
