import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { goToCase } from 'src/components/Cases/utils/routing';
import { panelPaddingStyles } from 'common/utils/Panels/Panels';
import {
  Button,
} from '@unite-us/ui';

export class AssistanceRequestNav extends Component {
  constructor(props) {
    super(props);

    this.navigateToCase = this.navigateToCase.bind(this);
  }

  navigateToCase() {
    const { serviceCase } = this.props;
    goToCase(serviceCase);
  }

  render() {
    const { style } = this.props;
    return (
      <div className="assistance-request-nav">
        <div
          className="detail-action-wrapper"
          style={style}
        >
          <Button
            id="view-case-btn"
            onClick={this.navigateToCase}
            label="View Case"
            primary
          />
        </div>
      </div>
    );
  }
}
AssistanceRequestNav.defaultProps = {
  style: panelPaddingStyles(),
  serviceCase: {},
};

AssistanceRequestNav.propTypes = {
  serviceCase: PropTypes.shape({
    id: PropTypes.string.isRequired,
    contact: PropTypes.object.isRequired,
  }).isRequired,
  style: PropTypes.object.isRequired,
};

export default AssistanceRequestNav;
