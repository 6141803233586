import {
  useFind,
} from 'src/api/APIHooks';
import { defaultQueryConfig } from '../apiHookOptions';

export const useFindProviders = (providerIds) => useFind(
  'providers',
  { id: providerIds },
  { queryConfig: defaultQueryConfig },
);
