import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { first, get, isEmpty, tail } from 'lodash';
import { useInvalidateQueries } from 'src/api/APIHooks';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import CareCoordinator from 'src/components/Dashboard/components/CareCoordinator';
import DialogV2 from 'common/modal/DialogV2';
import Notifier from 'common/helpers/Notifier';
import { Dialog } from '@unite-us/ui';
import { Spinner } from 'common/spinners';
import IconButton from 'common/buttons/IconButton/IconButton';
import {
  useDeletePersonalRelationship,
  useFetchPersonalRelationships,
} from '../Common/api';
import RelationshipCard from '../Common/RelationshipCard';
import RelationshipDeleteContent from '../Common/RelationshipDeleteContent';
import RelationshipListItem from '../Common/RelationshipListItem';
import RelationshipsList from '../Common/RelationshipsList';
import CareTeamForm from './forms/CareTeamForm/CareTeamForm';
import './CareTeamMember.scss';

const DISPLAY_NAME = 'care-team-members';

const getEmail = (
  personalRelationship,
) => get(personalRelationship, 'related_person.email_addresses[0].email_address', '');
const getPhoneNumber = (
  personalRelationship,
) => get(personalRelationship, 'related_person.phone_numbers[0]', '');

const CareTeamMember = ({ person, className, getClientRelationship }) => {
  if (isEmpty(person)) {
    return null;
  }
  const memberModal = useRef(null);
  const memberForm = useRef(null);
  const deleteDialogRef = useRef(null);
  const {
    data: personalRelationships,
    isFetching,
    isError,
  } = useFetchPersonalRelationships({
    personId: person.id,
    familyMember: false,
    careTeamMember: true,
  });
  useEffect(() => {
    if (isError) {
      Notifier.dispatch('Error fetching family members');
    }
  }, [isError]);
  const [currentPersonalRelationshipId, setCurrentPersonalRelationshipId] = useState(null);
  const { deleteRecord: deletePersonalRelationship } = useDeletePersonalRelationship();
  const confirmDelete = async () => {
    deleteDialogRef.current.closeDialog();
    const response = await deletePersonalRelationship(
      currentPersonalRelationshipId,
      {},
    );
    if (isHttpSuccess(response.status)) {
      Notifier.dispatch(response.status, 'Care Team Member successfully deleted.');
    }
  };
  const cancelDelete = () => {
    deleteDialogRef.current.closeDialog();
  };
  const deleteButtonOnClickHandler = (e, personalRelationshipId) => {
    e.stopPropagation();
    setCurrentPersonalRelationshipId(personalRelationshipId);
    deleteDialogRef.current.openDialog();
  };
  const [editMode, setEditMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPersonalRelationship, setCurrentPersonalRelationship] = useState(null);
  useEffect(() => {
    if (isModalOpen) {
      memberModal.current.openDialog().then(() => setIsModalOpen(false));
    }
  }, [isModalOpen]);
  const getCareTeamHeaderText = () => (
    editMode ? `Edit ${currentPersonalRelationship.related_person.full_name}` : 'Add New Care Team Member'
  );
  const closeModalHandler = () => {
    memberModal.current.closeDialog();
    setIsModalOpen(false);
    setEditMode(false);
    setCurrentPersonalRelationship(null);
    document.querySelector('#add-care-team-member-icon-button').focus();
  };
  const onEditClickHandler = (personalRelationship) => {
    setCurrentPersonalRelationship(personalRelationship);
    setIsModalOpen(true);
    setEditMode(true);
  };
  const onAddClickHandler = () => {
    setCurrentPersonalRelationship(null);
    setIsModalOpen(true);
    setEditMode(false);
  };
  const getInitData = () => {
    if (editMode) {
      const { related_person } = currentPersonalRelationship;
      const personCopy = {
        ...related_person,
        base_email_address: first(related_person.email_addresses),
        email_addresses: tail(related_person.email_addresses),
        relation_to_contact: currentPersonalRelationship.relationship,
      };
      return {
        member: personCopy,
      };
    }
    return {
      member: { base_email_address: {} },
    };
  };
  const getFormPersonalRelationship = () => (editMode ?
    currentPersonalRelationship :
    {
      id: null,
      person: {
        id: person.id,
      },
      related_person: null,
      relationship: null,
    });
  const invalidateQueries = useInvalidateQueries();
  return (
    <RelationshipCard
      title="Care Team"
      addButtonLabel="Add Care Team Member"
      className={classNames(DISPLAY_NAME, className)}
      onAddClick={onAddClickHandler}
    >
      <CareCoordinator
        contact={person}
        showCCDetails
        getClientRelationship={getClientRelationship}
        className={`
          p-4 hover:bg-medium-fill-blue
          border border-solid border-dark-border-blue
          mb-2 px-4 py-3 bg-white rounded
        `}
      />
      {isFetching && <Spinner scale={0.5} />}
      {
        !isFetching && (
          <RelationshipsList name="care team">
            {personalRelationships.map((pr) => (
              <RelationshipListItem
                key={pr.id}
                personalRelationshipId={pr.id}
                fullName={pr.related_person?.full_name}
                relationship={pr.relationship}
                email={getEmail(pr)}
                phoneNumber={getPhoneNumber(pr)}
                onClick={() => onEditClickHandler(pr)}
                maintainRelationship
              >
                <IconButton
                  className={classNames(`${DISPLAY_NAME}__delete-btn`, 'pr-2')}
                  labelText="Delete"
                  icon="IconTrash"
                  onClick={(e) => deleteButtonOnClickHandler(e, pr.id)}
                />
                <IconButton
                  className={`${DISPLAY_NAME}__edit-btn`}
                  labelText="Edit"
                  icon="IconPencil"
                  onClick={() => onEditClickHandler(pr)}
                />
              </RelationshipListItem>
            ))}
          </RelationshipsList>
        )
      }
      {
        isModalOpen && (
          <Dialog
            id="care-team-member-modal"
            modal
            ref={memberModal}
            size="large"
            title={getCareTeamHeaderText()}
            focusTrap
          >
            <CareTeamForm
              closeModal={closeModalHandler}
              personalRelationship={getFormPersonalRelationship()}
              editMode={editMode}
              initData={getInitData()}
              ref={memberForm}
              invalidateQueries={invalidateQueries}
              getClientRelationship={getClientRelationship}
            />
          </Dialog>
        )
      }
      <DialogV2
        ref={deleteDialogRef}
        title="Delete Care Team Member"
        confirmLabel="Delete"
        cancelHandler={cancelDelete}
        confirmationHandler={confirmDelete}
      >
        <RelationshipDeleteContent memberType="Care Team" />
      </DialogV2>
    </RelationshipCard>
  );
};

CareTeamMember.propTypes = {
  className: PropTypes.string,
  person: PropTypes.object.isRequired,
  getClientRelationship: PropTypes.func.isRequired,
};

CareTeamMember.defaultProps = {
  className: '',
};

export default CareTeamMember;
