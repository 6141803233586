import { dates } from '@unite-us/app-components';
import moment from 'moment';

export default function getDobData(dateOfBirth) {
  const birthData = dates.formatDate(dateOfBirth);
  const currentDate = moment(new Date());
  const age = Math.floor(currentDate.diff(birthData, 'years', true));

  return `${birthData} (Age ${age})`;
}
