import {
  SHOW_SYSTEM_NOTIFICATION,
  CLEAR_SYSTEM_NOTIFICATION,
  USER_LOGOUT,
} from 'actions';
import {
  generateUUID,
} from 'common/utils/utils';

export default function (state = [], action) {
  switch (action.type) {
    case SHOW_SYSTEM_NOTIFICATION:
      return [{
        status: action.payload.status,
        message: action.payload.message,
        dismissable: Object.prototype.hasOwnProperty.call(action.payload, 'dismissable') ?
          action.payload.dismissable : true,
        uuid: generateUUID(),
      }];

    case CLEAR_SYSTEM_NOTIFICATION:
    case USER_LOGOUT:
      return [];

    default:
      return state;
  }
}
