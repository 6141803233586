import { compact, filter, get, includes, map } from 'lodash';
import { SENSITIVE_SERVICE_OR_PROVIDER_MESSAGE } from 'src/components/Referrals/ReferralServicesForm/constants';

const sensitiveIsSelected = (groupId, sensitiveGroupIds) => includes(sensitiveGroupIds, groupId);

const sensitiveServiceOrProviderError = (
  sensitiveCount,
  isCurrentUserGroupSensitive,
) => (
  (sensitiveCount > 0 || isCurrentUserGroupSensitive) ? SENSITIVE_SERVICE_OR_PROVIDER_MESSAGE : ''
);

const getInvalidSensitiveErrors = ({
  caseReferrals = [],
  sensitiveGroupIds = [],
  isCurrentUserGroupSensitive,
  fields = [],
  groupId = '',
  referral,
}) => {
  if (!sensitiveIsSelected(groupId, sensitiveGroupIds) && !isCurrentUserGroupSensitive) {
    return [];
  }

  const currentRecipientId = get(referral, 'referred_to_group.id');
  const activeReferrals = filter(caseReferrals, (r) => get(r, 'state') !== 'accepted');
  const otherReferralsRecipientsIds = map(activeReferrals, (r) => get(r, 'receiving_provider.id'))
    .filter((recipientId) => recipientId !== currentRecipientId);
  const selectedGroupIds = compact(
    map(fields, (selected) => get(selected, 'group.value.id')),
  ).concat(otherReferralsRecipientsIds);

  const sensitiveCount = (
    filter(selectedGroupIds, (id) => includes(sensitiveGroupIds, id)) || []
  ).length;

  const providerCount = selectedGroupIds.length;

  if (providerCount < 2) { return []; }

  return [
    sensitiveServiceOrProviderError(
      sensitiveCount,
      isCurrentUserGroupSensitive,
    ),
  ];
};

export default getInvalidSensitiveErrors;
