import { useFind, usePopulate, usePopulateMemo } from 'src/api/APIHooks';
import { get, groupBy, map } from 'lodash';

const useManagedProviderOptions = (networkLeadId) => {
  const networkLeadRelationshipsQuery = useFind(
    'network_lead_relationships',
    {
      network_lead: networkLeadId,
    },
    {
      page: { size: 500 },
      queryConfig: {
        enabled: !!networkLeadId,
      },
    },
  );

  const networkLeadRelationship = get(networkLeadRelationshipsQuery, 'data.data.data', []);
  const providerQuery = usePopulate('provider', 'provider', networkLeadRelationship);

  usePopulateMemo(networkLeadRelationshipsQuery.data, [providerQuery]);

  const uniqueNetworkLeadRelationship = groupBy(networkLeadRelationship, 'provider.name');

  const networkLeadRelationshipOptions = map(uniqueNetworkLeadRelationship, (val) => ({
    label: get(val[0], 'provider.name', ''),
    value: get(val[0], 'provider.id'),
  }));

  networkLeadRelationshipOptions.sort((a, b) => {
     if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  return networkLeadRelationshipOptions;
};

export default useManagedProviderOptions;
