const findChild = (predicate, services) => {
  for (const service of Object.values(services)) {
      const foundChild = service.children.find(predicate);
      if (foundChild) {
          return foundChild;
      }
  }
  return null;
};

const getServiceId = (serviceName, services) => {
  const child = findChild((c) => c.name === serviceName, services);
  return child ? child.id : null;
};

const getServiceName = (serviceId, services) => {
  const child = findChild((c) => c.id === serviceId, services);
  return child ? child.name : null;
};

export { getServiceId, getServiceName };
