import Notifier from 'common/helpers/Notifier';
import { coreApi } from 'src/api/config';
import { FETCH_CURRENT_GROUP } from 'actions';

export default function fetchCurrentGroup(groupId) {
  return (dispatch) => {
    const request = coreApi.findRecord('provider', groupId);

    function onSuccess(payload) {
      return dispatch({
        currentProvider: payload.data.data,
        type: FETCH_CURRENT_GROUP,
      });
    }

    function onError(error) {
      Notifier.handleErrors(error);
      return error;
    }

    return request.then(onSuccess, onError);
  };
}
