import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import { BarLoader } from '@unite-us/ui';
import { feeSchedulePrograms as fspUtils } from '@unite-us/client-utils';
import { Card } from 'common/Card';
import { DollarAmount } from 'common/display/Money';
import { LineItem } from 'common/display/LineItem';
import { useFeeScheduleProgram } from 'common/hooks';

const ReferralAuthorizationRequestSummary = ({ authorizationRequest }) => {
  const {
    fee_schedule_program_id, service_start, service_end, amount_requested,
  } = authorizationRequest;
  const feeScheduleProgram =
    useFeeScheduleProgram(fee_schedule_program_id);

  const isCostBased = fspUtils.isCostPaymentType(feeScheduleProgram?.payment_type);
  const isSingleDateRequested = service_start && !service_end;
  const showUnits = !isCostBased && feeScheduleProgram?.unit;
  const showRate = showUnits && feeScheduleProgram?.unit_rate > 0;

  const formatDate = (date) => moment.unix(date).utc().format('M/D/YYYY');

  return (
    isEmpty(feeScheduleProgram) ? <BarLoader /> : (
      <Card className="space-y-4 py-4">
        <h5 className="ml-4">
          Authorization Request
        </h5>
        <hr />

        <dl className="grid grid-cols-line-items gap-x-24 gap-y-2 ml-4">
          <LineItem field="Contracted Program" dataTestElement="request-contracted-program">
            {feeScheduleProgram.name}
          </LineItem>
          <LineItem field="Payment Approach" dataTestElement="request-payment-approach">
            {fspUtils.PAYMENT_TYPES[feeScheduleProgram.payment_type]}
          </LineItem>
          { showUnits && (
            <LineItem field="Unit" dataTestElement="request-unit">
              <span className="capitalize">{feeScheduleProgram.unit}</span>
            </LineItem>
          )}
          { showRate && (
            <LineItem field="Rate" dataTestElement="request-rate">
              <DollarAmount value={feeScheduleProgram.unit_rate} convertCents />
              {' per '}{feeScheduleProgram.unit}
            </LineItem>
          )}
          { feeScheduleProgram.cap_information && (
            <LineItem field="Program Cap" dataTestElement="request-cap">
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: feeScheduleProgram.cap_information }} />
            </LineItem>
          )}
          <hr className="col-span-2 mr-4 p-0" />
          <LineItem field="Amount Requested" dataTestElement="amount-requested">
            {
              isCostBased ?
                <DollarAmount value={amount_requested} convertCents /> :
                `${amount_requested} ${pluralize(feeScheduleProgram.unit, amount_requested)}`
            }
          </LineItem>
          {
            isSingleDateRequested ? (
              <LineItem field="Service Delivery Date" dataTestElement="request-service-delivery-date">
                {formatDate(service_start)}
              </LineItem>
            ) : (
              <LineItem field="Service Delivery Dates" dataTestElement="request-service-delivery-dates">
                {formatDate(service_start)} - {formatDate(service_end)}
              </LineItem>
            )
          }
        </dl>
      </Card>
    ));
};

ReferralAuthorizationRequestSummary.propTypes = {
  authorizationRequest: PropTypes.object.isRequired,
};

export default ReferralAuthorizationRequestSummary;
