import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ExpandableContainer } from '@unite-us/client-utils';
import { removeClassName } from 'common/utils/domTools';
import _ from 'lodash';
import '../stylesheets/detail-view.scss';
import addLineBreaks from '../../../utils/addLineBreaks';

class DetailDescription extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { description } = nextProps;
    if (description) {
      const noDescriptionDivs = document.querySelectorAll('.no-description');
      _.forEach(noDescriptionDivs, (div) => removeClassName(div, 'field-invalid'));
    }
  }

  render() {
    const { description, subtitle, title } = this.props;

    return (
      <div className="detail-info__description">
        <p className="no-description">Please enter a description</p>
        <ExpandableContainer
          id="detail-description-expandable"
          header={title}
          collapsedHeight="70px"
        >
          {subtitle}
          <p className="no-margin">{addLineBreaks(description)}</p>
        </ExpandableContainer>
      </div>
    );
  }
}

DetailDescription.propTypes = {
  description: PropTypes.string,
  subtitle: PropTypes.node,
  title: PropTypes.string.isRequired,
};

DetailDescription.defaultProps = {
  description: '',
  subtitle: undefined,
};

export default DetailDescription;
