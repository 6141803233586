import PropTypes from 'prop-types';
import React from 'react';
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableHeaderColumn,
} from '@unite-us/ui';
import ReferralTableRow from './ReferralTableRow';

const ReferralTableView = (props) => {
  const {
    referrals, styles, groupId, isCC,
  } = props;

  return (
    <div className="referral-table-view">
      <Table id="referral-table-view" noBorder>
        <TableHeader
          adjustForCheckbox={false}
          displaySelectAll={false}
        >
          <TableRow>
            <TableHeaderColumn />
            <TableHeaderColumn style={{ minWidth: '65px' }}>
              Date Sent
            </TableHeaderColumn>

            <TableHeaderColumn style={{ minWidth: '65px' }}>
              Status
            </TableHeaderColumn>

            <TableHeaderColumn>
              Service Type
            </TableHeaderColumn>

            <TableHeaderColumn>
              Sender
            </TableHeaderColumn>

            <TableHeaderColumn>
              Recipient
            </TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody
          displayRowCheckbox={false}
          showRowHover
        >
          {
            referrals.map((referral, index) => (
              <ReferralTableRow
                key={referral.id}
                isCC={isCC}
                referral={referral}
                index={index}
                styles={styles}
                groupId={groupId}
              />
            ))
          }
        </TableBody>
      </Table>
    </div>
  );
};

ReferralTableView.propTypes = {
  referrals: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  isCC: PropTypes.bool.isRequired,
  styles: PropTypes.shape({
    status: PropTypes.object.isRequired,
  }).isRequired,
};

ReferralTableView.defaultProps = {
  styles: {
    status: { textTransform: 'capitalize', minWidth: '65px' },
  },
};

export default ReferralTableView;
