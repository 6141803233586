import { coreApi } from 'src/api/config';

const fetchProviderIsSensitive = async (providerId) => {
  try {
    const payload = await coreApi.findRecord('provider', providerId);

    return payload.data.data.sensitive;
  } catch (error) {
    if (window.Rollbar) {
      window.Rollbar.error(
        'Failed to fetch provider to determine if sensitive',
        { error, providerId },
      );
    }
    return error;
  }
};

export default fetchProviderIsSensitive;
