import _ from 'lodash';
import { enums } from '@unite-us/client-utils';
import { dates } from '@unite-us/app-components';
import { getItemResult } from '.';

function getRecipient(referral) {
  if (_.get(referral, 'referred_to_group.name')) {
    return _.get(referral, 'referred_to_group.name');
  }
  if (_.get(referral, 'recalled_from_group.name')) {
    return _.get(referral, 'recalled_from_group.name');
  }
  return '';
}

function formatReferralData({ result, columns, session }) {
  const referral = getItemResult(result);
  const full_name = _.get(referral, 'contact.full_name', '');
  const recipient = getRecipient(referral);
  const status = enums.generateEnumsObject(referral, 'referrals', session);
  const service_type = _.get(referral, 'service_type.name', '');

  const referralData = {
    recipient,
    full_name,
    name_service_type: `${full_name} - ${service_type}`,
    created_by: _.get(referral, 'created_by.full_name', ''),
    created_at: dates.formatDate(referral.created_at, 'MM/DD/YYYY'),
    service_type,
    status: _.get(status, 'display_name', ''),
    updated_at: dates.formatDate(referral.updated_at, 'MM/DD/YYYY'),
    received_at: dates.formatDate(referral.received_at, 'MM/DD/YYYY'),
  };

  return _.reduce(columns, (acc, value) => {
    if (_.has(referralData, value)) {
      return [
        ...acc,
        {
          label: value,
          value: referralData[value],
        },
      ];
    }
    return [...acc];
  }, []);
}

export default formatReferralData;
