function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import serviceTypeUtils from "../../utils/serviceTypes";
import createFilterTrackingObject from "./createFilterTrackingObject";
var defaultEntry = {
  prefix: null,
  transformers: {}
};
var SERIALIZER_DICTIONARY = {
  contact: _objectSpread(_objectSpread({}, defaultEntry), {}, {
    keys: ['id', 'full_name']
  }),
  browseFilters: {
    keys: ['accessibility', 'addressType', 'catersTo', 'cities', 'counties', 'distance', 'filters', 'languages', 'network_id', 'network_name', 'networks', 'networkScopes', 'serviceTypes', 'states', 'text'],
    prefix: 'browse_filters',
    transformers: {
      filters: function filters(values) {
        return createFilterTrackingObject(values);
      },
      addressType: function addressType(value) {
        return value.label;
      }
    }
  },
  browseGroupDetails: {
    keys: ['id', 'name', 'service_types', 'group_type', 'position'],
    prefix: 'browse_drawer_group',
    transformers: {
      service_types: function service_types(values) {
        return serviceTypeUtils.flattenServiceTypes(values, true).map(function (val) {
          return val.name;
        });
      }
    }
  },
  dropDownGroupDetails: {
    keys: ['id', 'name', 'position', 'network'],
    prefix: 'dropdown_group',
    transformers: {
      service_types: function service_types(values) {
        return serviceTypeUtils.flattenServiceTypes(values, true).map(function (val) {
          return val.name;
        });
      }
    }
  },
  "export": {
    keys: ['end_at', 'send_email', 'start_at', 'target_ids', 'target_type', 'time_field', 'time_period', 'type'],
    prefix: 'export',
    transformers: {
      target_ids: function target_ids(values) {
        return values.map(function (val) {
          return val.id;
        });
      }
    }
  },
  interaction: _objectSpread(_objectSpread({}, defaultEntry), {}, {
    keys: ['type', 'occurred_on', 'duration']
  }),
  network: _objectSpread(_objectSpread({}, defaultEntry), {}, {
    keys: ['id', 'name'],
    prefix: 'network'
  }),
  outcome: _objectSpread(_objectSpread({}, defaultEntry), {}, {
    keys: ['outcome_id', 'resolved']
  }),
  referral: _objectSpread(_objectSpread({}, defaultEntry), {}, {
    keys: ['id', 'contact', 'referred_by_group'],
    transformers: {
      contact: function contact(_ref) {
        var id = _ref.id,
          full_name = _ref.full_name;
        return {
          id: id,
          full_name: full_name
        };
      },
      referred_by_group: function referred_by_group(_ref2) {
        var id = _ref2.id,
          name = _ref2.name;
        return {
          id: id,
          name: name
        };
      }
    }
  }),
  sharedGroup: _objectSpread(_objectSpread({}, defaultEntry), {}, {
    keys: ['id', 'name'],
    prefix: 'shared_group'
  }),
  user: _objectSpread(_objectSpread({}, defaultEntry), {}, {
    keys: ['id', 'full_name', 'email'],
    prefix: 'user'
  })
};
export default SERIALIZER_DICTIONARY;