const LabelDefaults = {
  CARE_COORDINATOR: 'Care Coordinator',
};

// Extend this as required...
const defaults = {
  CareCoordinator: LabelDefaults.CARE_COORDINATOR,
};

export default defaults;
