import {
  CREATE_CONTACT_SCREENING,
  FETCH_CONTACT_SCREENING,
  FETCH_CONTACT_SCREENINGS,
  FETCH_GROUP_SCREENING,
  SET_CONTACT_SCREENINGS_FETCHING,
  UNSET_CONTACT_SCREENINGS_FETCHING,
  UPDATE_CONTACT_SCREENING,
} from 'actions';
import { get, reduce } from 'lodash';

export const defaultState = {
  screenings: [],
  isFetching: false,
  currentScreening: null,
};

function screeningsReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case SET_CONTACT_SCREENINGS_FETCHING: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case UNSET_CONTACT_SCREENINGS_FETCHING: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case FETCH_CONTACT_SCREENINGS: {
      return {
        ...state,
        screenings: action.payload.data.data,
      };
    }

    case CREATE_CONTACT_SCREENING: {
      const screening = get(action, 'payload.data.data');
      return {
        ...state,
        screenings: [
          ...state.screenings,
          screening,
        ],
        currentScreening: screening,
      };
    }

    case UPDATE_CONTACT_SCREENING: {
      const screening = get(action, 'payload.data.data');
      const screenings = reduce(state.screenings, (acc, current) => {
        if (current.id === screening.id) {
          return [...acc, screening];
        }
        return [...acc, current];
      }, []);
      return {
        ...state,
        screenings,
        currentScreening: screening,
      };
    }

    case FETCH_CONTACT_SCREENING:
    case FETCH_GROUP_SCREENING:
      return {
        ...state,
        currentScreening: get(action, 'payload.data.data'),
      };
    default:
      return state;
  }
}

export default screeningsReducer;
