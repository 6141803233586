import { CLEAN_RETURNED_ASSESSMENTS } from '..';

function cleanReturnedAssessments() {
  return {
    type: CLEAN_RETURNED_ASSESSMENTS,
    payload: [],
  };
}

export default cleanReturnedAssessments;
