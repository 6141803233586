import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
// (todo micah) make sure these transition groups are right
// https://github.com/reactjs/react-transition-group/blob/master/Migration.md
import { validateReduxForm } from 'common/form';
import { Button } from '@unite-us/ui';
import _ from 'lodash';

// Action imports
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';
import { createServiceCase } from 'actions/Case/Contact/Group';
import { fetchContactDocuments } from 'actions/Document/Contact/Group';
import { fetchCoreGroupForms } from 'actions/Group';
import { submitReferral } from 'actions/Referral/Contact/Group';
import {
  destroyDropzone,
  destroyAllDropzones,
} from 'common/form/FileUpload/actions/FileUpload';

// Component imports
import { OverlaySpinner } from 'common/spinners';
import featureFlag from 'src/common/utils/FeatureFlag/FeatureFlag';
import { generateUUID } from 'common/utils/utils';
import Header from 'common/display/ContactStepper/components/Header';
import ReferralServiceFormCard from 'src/components/Referrals/ReferralServicesForm/ReferralServiceFormCard';
import {
  RemoveReferralConfirmationDialog,
  ReferralServicesFormFooter,
} from 'src/components/Referrals/ReferralServicesForm/components';
import { hasValidCaseContext } from 'src/components/Referrals/utils';
import { newReferralFormFieldsAsServices } from 'src/components/Referrals/utils/form';

// Constant imports
import {
  CREATE_REFERRAL_FORM,
  REFERRAL_DOCUMENT_UPLOAD,
} from 'src/components/Referrals/constants';

// Util imports
import callOrLog from 'src/common/utils/callOrLog';
import { REFERRAL } from 'common/utils/EventTracker/utils/eventConstants';
import today from 'src/common/utils/today';
import {
  goToNextStep,
  indexToExpandAfterDelete,
  indexToExpandAfterToggle,
  isFromAssistanceRequest,
} from 'src/components/Referrals/ReferralServicesForm/utils';
import {
  validateInNetworkGroupFields,
} from 'src/components/Referrals/ReferralServicesForm/utils/errorHandling';
import {
  removeAllGroupFields,
} from 'src/components/Referrals/ReferralGroupsPrograms/utils';
import { getEmployeeNetworks } from 'src/components/Employee/employeeGetters';

// Stylesheets
import 'src/components/Referrals/stylesheets/referrals.scss';

// Feature Flags
import { hasCasesOONReferralUnlicensedOrgs, programBasedSearchSelector } from 'src/common/utils/FeatureFlags/flags';

export class ReferralServicesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeUsersInGroup: [],
      canShowOONCaseContext: false,
      // If we have more than 1 service to start, intially collapse all forms (set currentServiceTypeIndex to -1).
      currentServiceTypeIndex: props.initialServices && props.initialServices.length > 1 ? -1 : 0,
      showOONCaseContext: false,
      disableNextButton: false,
      expandedReferrals: new Set(), // referrals that have been expanded
    };

    this.addServiceType = this.addServiceType.bind(this);
    this.addField = this.addField.bind(this);
    this.fetchGroupsUsers = this.fetchGroupsUsers.bind(this);
    this.debouncedfetchGroupsUsers = _.debounce(this.fetchGroupsUsers, 400);
    this.onCancelCase = this.onCancelCase.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onForceShowOONCaseContext = this.onForceShowOONCaseContext.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSaveDraft = this.onSaveDraft.bind(this);
    this.onToggleExpanded = this.onToggleExpanded.bind(this);
    this.isOONCase = this.isOONCase.bind(this);
    this.submitInNetworkReferral = this.submitInNetworkReferral.bind(this);
    this.setHasSensitiveErrors = this.setHasSensitiveErrors.bind(this);
    this.setDisableNextButton = this.setDisableNextButton.bind(this);
  }

  componentDidMount() {
    const { fields, groupId, selectedContact } = this.props;

    if (fields.services.length < 1) {
      this.addField();
    }

    if (!selectedContact || selectedContact === '') {
      browserHistory.push('/referrals/new/search');
    }

    this.props.fetchContactDocuments({ groupId, contactId: selectedContact });

    this.props.fetchProvidersUserCore({ providers: groupId })
      .then((response) => {
        if (response) {
          const { employees: activeUsersInGroup } = response;
          this.setState({ activeUsersInGroup });
        }
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { fields: { services: nextServices } } = nextProps;
    const newState = {};
    if (!nextServices.length) {
      return;
    }

    const { canShowOONCaseContext, showOONCaseContext } = this.state;
    const nextPropsValidCaseContext = hasValidCaseContext(this.props, nextProps, false, true);

    if (this.props.fields.services.length !== nextServices.length) {
      newState.currentServiceTypeIndex = nextServices.length - 1;
    }
    if (nextPropsValidCaseContext !== canShowOONCaseContext) {
      newState.canShowOONCaseContext = nextPropsValidCaseContext;
      /*
        TODO: this piece of state is only used inside this method.
        Keeping for now because components below are calling
        onForceShowOONCaseContext and unwinding that is beyond
        the current task.
      */
      newState.showOONCaseContext = !nextPropsValidCaseContext && showOONCaseContext;
      if (!_.isEmpty(newState)) {
        this.setState(newState);
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { services } = nextProps.fields;
    if (services.length < 1) {
      this.addField();
    }
  }

  onCancelCase(index = 0) {
    const { fields } = this.props;
    this.setState({
      showOONCaseContext: false,
    });
    const service = fields.services[index];
    removeAllGroupFields(service.oonCase.selected);
    service.oonCase.primary_worker.onChange();
    service.oonCase.program_entry.onChange(today());
  }

  onDelete(e, index) {
    e.stopPropagation();
    /* eslint-disable no-unused-expressions */
    document.getElementById('remove-referral-dialog')?.focus();
    this.deleteModal.dialog.openDialog().then((response) => {
      if (response === 'delete') {
        const { services } = this.props.fields;
        services.removeField(index);
        this.props.destroyDropzone(`${REFERRAL_DOCUMENT_UPLOAD}-${index}`);
        const indexToExpand = indexToExpandAfterDelete({
          deletedIndex: index,
          expandedIndex: this.state.currentServiceTypeIndex,
          servicesCount: services.length,
        });
        this.setState({ currentServiceTypeIndex: indexToExpand }, () => {
          document.getElementById('0-header-button')?.focus();
        });
      }
      if (response === 'dismiss') {
        document.getElementsByClassName('service-type-action')[index]?.focus();
      }
    });
    /* eslint-enable no-unused-expressions */
  }

  onForceShowOONCaseContext() {
    this.setState({ showOONCaseContext: true });
  }

  onSubmit(values = {}) {
    this.setState({ disableNextButton: true });
    return this.submitInNetworkReferral(values);
  }

  onSaveDraft() {
    const {
      currentEmployee,
      fields,
      groupId,
      networks,
      selectedContact,
      params,
      screeningId,
    } = this.props;

    const assistance_request_id = _.get(params, 'assistance_request_id', null);

    return validateInNetworkGroupFields({
      allowEmptyGroups: false,
      networks,
      services: fields.services,
    }).then(() => (
      this.props.submitReferral(
        groupId,
        selectedContact,
        this.props.values.services,
        {
          assistance_request_id,
          screening_id: screeningId,
          draft: true,
          requester_id: currentEmployee.id,
        },
      ).then((response) => {
        if (!response) {
          return;
        }

        const referrals = _.get(response, 'data.data', []);
        callOrLog(() => this.context.eventTracker(REFERRAL.saveDraft, {
          referral_ids: _.map(referrals, 'id'),
        }));

        this.props.destroyAllDropzones();
        this.props.resetForm();
        this.props.destroyForm(CREATE_REFERRAL_FORM);
        browserHistory.push('/dashboard/referrals/sent/draft');
      })
    ));
  }

  onToggleExpanded(index) {
    const { currentServiceTypeIndex, expandedReferrals } = this.state;
    const newExpandedIndex = indexToExpandAfterToggle({
      expandedIndex: currentServiceTypeIndex,
      toggledIndex: index,
    });

    this.setState({
      currentServiceTypeIndex: newExpandedIndex,
      expandedReferrals: new Set([...expandedReferrals, currentServiceTypeIndex]),
    });
  }

  setHasSensitiveErrors(value, index) {
    const { fields: { services } } = this.props;
    services[index].hasSensitiveError.onChange(value);
  }

  setDisableNextButton(value) {
    this.setState({
      disableNextButton: value,
    });
  }

  hasSensitiveErrors() {
    const { fields: { services = [] } } = this.props;
    return services.some((service) => service.hasSensitiveError.value);
  }

  isOONCase(service) {
    const validOON = hasValidCaseContext(this.props, this.props, false, true);
    return validOON && (_.get(service, 'isOONCase.value', false) || false);
  }

  submitInNetworkReferral(values) {
    const {
      fields,
      groupId,
      networks,
      originPrefix,
      params,
      locationSrc,
    } = this.props;
    // Referral context
    const serviceTypeIds = _.uniq(_.map(values.services, (service) => (
      service.service_type.id
    )));

    const networkId = _.uniq(_.map(values.services, (service) => (
      service.referred_by_network.id
    ))).join(',');

    // remove empty fields, if any, to prevent submission errors
    _.each(fields.services, (service, key) => {
      // remove empty OON case fields
      _.each(service.oonCase.selected, (sel, index) => {
        if (sel.group.value === '') {
          fields.services[key].oonCase.selected.removeField(index);
        }
      });

      _.each(service.oonCase.custom, (sel, index) => {
        if (sel.group.value === '') {
          fields.services[key].oonCase.custom.removeField(index);
        }
      });

      // remove empty in-network case fields
      _.each(service.selected, (sel, index) => {
        if (sel.group.value === '') {
          fields.services[key].selected.removeField(index);
        }
      });
    });
    // const networkIds = _.uniq(_.map(values.services, 'referred_by_network.id'));
    const prefix = !_.isEmpty(_.get(params, 'assistance_request_id')) ?
      `/assistance-requests/${params.assistance_request_id}` :
      originPrefix;
    return validateInNetworkGroupFields({ networks, services: fields.services })
      .then(() => {
        this.props.fetchCoreGroupForms(groupId, serviceTypeIds, networkId)
          // We now need to pass a possible location scr to create the correct route
          .then((response) => goToNextStep(prefix, response, locationSrc))
          // @todo We should do something more here, otherwise spinner keeps spinning
          .catch(console.error); // eslint-disable-line no-console
      });
  }

  addServiceType() {
    const { fields, selectedContact } = this.props;
    const numOfServices = fields.services.length;

    this.addField();
    this.setState({ currentServiceTypeIndex: numOfServices });
    callOrLog(() => this.context.eventTracker(
      REFERRAL.addAnother,
      { contact_id: selectedContact },
    ));
  }

  addField() {
    const {
      fields: { services = [] },
      assistanceRequest,
      networks,
      selectedContactDocuments,
    } = this.props;

    services.addField({
      attachableDocuments: selectedContactDocuments,
      auto_recallable: true,
      notes: _.get(assistanceRequest, 'description', ''),
      referred_by_network: networks.length === 1 && {
        id: _.get(networks, '[0].id', ''),
      },
      oonCase: {
        program_entry: today(),
      },
      hasSensitiveError: false,
      itemID: generateUUID(),
    });
  }

  fetchGroupsUsers(search, callback) {
    const { groupId } = this.props;
    this.props.fetchProvidersUserCore({ providers: groupId, options: { text: search } })
      .then((response) => {
        if (response) {
          const { employees: activeUsersInGroup } = response;
          callback({ options: activeUsersInGroup });
        }
      });
  }

  render() {
    const {
      assistanceRequest,
      fields,
      fromAssistanceRequest,
      handleSubmit,
      isReferralSaving,
      isProgramBasedSearch,
      networks,
      registerField,
      selectedContact,
      submitting,
      touch,
      untouch,
      values,
      errors,
      unregisterField,
    } = this.props;
    const {
      activeUsersInGroup,
      canShowOONCaseContext,
      currentServiceTypeIndex,
      disableNextButton,
      expandedReferrals,
    } = this.state;

    const canSaveDraft = values.services
      .every((s) => s.service_type && s.referred_by_network && !s.isOONCase);

    const hasOONCases = values.services
      .some((s) => s.isOONCase);

    const referralTitle = (service) => {
      const isOONCase = _.get(service, 'isOONCase.checked', false);
      const serviceType = _.get(service, 'service_type.value.name', '');
      const title = isOONCase ? 'New Out of Network Case' : 'New Referral';
      if (!_.isEmpty(serviceType)) {
        return `${title}: ${serviceType}`;
      }
      return title;
    };

    const serviceTypeViewList = fields.services.map((service, index) => (
      <CSSTransition
        className="service"
        classNames="service"
        timeout={{ enter: 500, exit: 500 }}
        key={_.get(service, 'itemID.value')}
      >
        <ReferralServiceFormCard
          activeUsersInGroup={activeUsersInGroup}
          assistanceRequest={assistanceRequest}
          canDelete={fields.services.length > 1}
          canShowOONCaseContext={canShowOONCaseContext}
          contactId={selectedContact}
          currentServiceTypeIndex={currentServiceTypeIndex}
          fetchGroupsUsers={this.debouncedfetchGroupsUsers}
          fields={service}
          form={CREATE_REFERRAL_FORM}
          fromAssistanceRequest={fromAssistanceRequest}
          index={index}
          isExpanded={currentServiceTypeIndex === index}
          isProgramBasedSearch={isProgramBasedSearch}
          networks={networks}
          onDelete={this.onDelete}
          onForceShowOONCaseContext={this.onForceShowOONCaseContext}
          onToggleExpanded={this.onToggleExpanded}
          hasBeenExpanded={expandedReferrals.has(index)}
          referralTitle={referralTitle(service)}
          registerField={registerField}
          showOONCaseContext={this.isOONCase(service)}
          untouch={untouch}
          touch={touch}
          onCancel={this.onCancelCase}
          setDisableNextButton={this.setDisableNextButton}
          setHasSensitiveErrors={this.setHasSensitiveErrors}
          unregisterField={unregisterField}
        />
      </CSSTransition>
    ));

    return (
      <div className="referral-services-form">
        <OverlaySpinner
          text="Saving..."
          show={submitting || isReferralSaving}
        />

        <form>
          <div className="row">
            <div className="col-md-12">
              <Header
                header="Create Referral"
                subHeader="Please add one or more referrals for your client."
              />
            </div>
          </div>

          <TransitionGroup
            component="ul"
            className="service-type-list list-unstyled"
          >
            {serviceTypeViewList}
          </TransitionGroup>

          <div className="add-referral">
            <Button
              id="add-another-referral-btn"
              label="+ Add Another Referral"
              onClick={this.addServiceType}
              primary
            />
          </div>

          <ReferralServicesFormFooter
            formErrors={errors}
            canSaveDraft={canSaveDraft}
            onCancel={this.onCancelCase}
            onSaveDraft={handleSubmit(this.onSaveDraft)}
            onSubmitForm={handleSubmit(this.onSubmit)}
            submitting={submitting || this.hasSensitiveErrors()}
            contactId={selectedContact}
            hasOONCases={hasOONCases}
            disableNextButton={disableNextButton}
          />
        </form>
        <RemoveReferralConfirmationDialog
          ref={(el) => { this.deleteModal = el; }}
        />
      </div>
    );
  }
}

ReferralServicesForm.propTypes = {
  assistanceRequest: PropTypes.object,
  currentEmployee: PropTypes.object,
  destroyForm: PropTypes.func.isRequired,
  destroyDropzone: PropTypes.func.isRequired,
  destroyAllDropzones: PropTypes.func.isRequired,
  errors: PropTypes.object,
  fetchContactDocuments: PropTypes.func.isRequired,
  fetchCoreGroupForms: PropTypes.func.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  fromAssistanceRequest: PropTypes.bool,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialServices: PropTypes.array,
  isProgramBasedSearch: PropTypes.bool.isRequired,
  isReferralSaving: PropTypes.bool,
  networks: PropTypes.array.isRequired,
  originPrefix: PropTypes.string,
  params: PropTypes.object.isRequired,
  registerField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  submitReferral: PropTypes.func.isRequired,
  selectedContact: PropTypes.string.isRequired,
  selectedContactDocuments: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  untouch: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  locationSrc: PropTypes.string,
  screeningId: PropTypes.string,
  unregisterField: PropTypes.func,
};

ReferralServicesForm.defaultProps = {
  assistanceRequest: undefined,
  currentEmployee: {},
  errors: {},
  fromAssistanceRequest: false,
  initialServices: [],
  isReferralSaving: false,
  originPrefix: '',
  locationSrc: undefined,
  screeningId: undefined,
  unregisterField: _.noop,
};

ReferralServicesForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const {
    assistanceRequests: { requests },
    contactDocuments,
    selectedContact,
    session: { groupId },
    user,
  } = state;

  const networks = getEmployeeNetworks({ state });

  const groupForms = _.get(state, ['groupForms', groupId], []);

  const assistanceRequest = _.find(requests, {
    id: _.get(ownProps, 'params.assistance_request_id', ''),
  });

  // We need to check the location query for a src param and pass it along
  // this is used to determine the stepper config (nehii users for now)
  const srcQueryString = _.get(ownProps, 'location.query.src');
  const locationSrc = srcQueryString ? `?src=${srcQueryString}` : undefined;

  const selectedContactDocuments = _.get(contactDocuments, [selectedContact, 'data'], [])
    .map((doc) => ({
      attached: false,
      document: doc,
    }));

  const onEdit = _.get(ownProps, 'location.query.onEdit', false);
  const notes = assistanceRequest ? _.get(assistanceRequest, 'description', '') : '';

  // Use initialServices
  const initialServicesData = ownProps.initialServices || [{}];
  const itemIDs = ownProps.itemIDs || [];

  const initialValues = ({
    services: initialServicesData.map((service = {}, index) => ({
      auto_recallable: true,
      attachableDocuments: selectedContactDocuments,
      oonCase: {
        program_entry: today(),
      },
      referred_by_network: networks.length === 1 && {
        id: _.get(networks, '[0].id', ''),
      },
      hasSensitiveError: false,
      itemID: itemIDs[index],
      ...(notes ? { notes } : {}),
      ...service,
    })),
  });

  const isReferralSaving = _.get(state, 'contactReferrals.isSaving', false);
  const isCC = _.get(state, 'session.isCoordinationGroup', false);
  const casesOONReferralUnlicensedOrgs = hasCasesOONReferralUnlicensedOrgs(state);
  const isProgramBasedSearch = programBasedSearchSelector(state);

  return {
    assistanceRequest,
    casesOONReferralUnlicensedOrgs,
    currentEmployee: _.get(state, 'globalState.currentEmployee'),
    fromAssistanceRequest: isFromAssistanceRequest(ownProps.location),
    groupForms,
    groupId,
    ...(onEdit ? {} : { initialValues }),
    isCC,
    isProgramBasedSearch,
    isReferralSaving,
    networks,
    selectedContact,
    selectedContactDocuments,
    user,
    locationSrc,
  };
}

const fields = newReferralFormFieldsAsServices();
const ReferralServicesFormWithFlags = featureFlag(ReferralServicesForm);
const onSubmitFail = () => {
  setTimeout(() => {
    const errs = document.getElementsByClassName('has-error');
    const err = _.first(errs);
    const focusableErr = _.first(err.getElementsByClassName('choices')) ||
      _.first(err.getElementsByTagName('textarea'));
    return focusableErr?.focus();
  }, 300);
};

export default validateReduxForm({
  onSubmitFail,
  form: CREATE_REFERRAL_FORM,
  fields,
  destroyOnUnmount: false,
}, mapStateToProps, {
  createServiceCase,
  destroyAllDropzones,
  destroyDropzone,
  fetchContactDocuments,
  fetchCoreGroupForms,
  fetchProvidersUserCore,
  submitReferral,
})(ReferralServicesFormWithFlags);
