import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'common/utils/browserHistory';
import { dates } from '@unite-us/app-components';
import { PrimaryButton, SecondaryButton } from 'common/TailwindComponents';

export const ClientDetails = ({
  contact,
  contactId,
  plan,
  stepForward,
}) => {
  const onBackToFaceSheet = useCallback(() => {
    browserHistory.push(`/facesheet/${contactId}/profile`);
  }, [browserHistory, contactId]);

  return (
    <div className="p-6 pt-0">
      <p className="text-xs mb-4">
        Review the client and social care coverage plan details below.
      </p>
      <dl>
        <dt className="uppercase text-text-blue font-extrabold mb-2">
          Client name
        </dt>
        <dd className="text-black ml-0">
          {contact.full_name}
        </dd>
        <dt className="uppercase text-text-blue font-extrabold mb-2 mt-6">
          Date of birth
        </dt>
        <dd className="text-black ml-0">
          {dates.formatDate(contact.date_of_birth)}
        </dd>
        <p className="uppercase text-text-blue font-extrabold mb-2 mt-6">
          Social care coverage
        </p>
        <p className="text-black ml-0">
          {plan?.name}
        </p>
        <div className="flex">
          <div className="flex-none mr-12">
            <dt className="uppercase text-text-blue font-extrabold mb-2 mt-6">
              Start date
            </dt>
            <dd className="text-black ml-0">
              Social care coverage will start once the client is enrolled.
            </dd>
          </div>
          <div className="flex-none mr-8">
            <dt className="uppercase text-text-blue font-extrabold mb-2 mt-6">
              End date
            </dt>
            <dd className="text-black ml-0">
              Social care coverage will end when the client is disenrolled.
            </dd>
          </div>
        </div>
      </dl>

      <div className="bg-white px-6 py-4 flex items-center justify-end space-x-6">
        <SecondaryButton label="Cancel" onClick={onBackToFaceSheet} />
        <PrimaryButton label="Next" onClick={stepForward} />
      </div>
    </div>
  );
};

ClientDetails.propTypes = {
  contact: PropTypes.object,
  contactId: PropTypes.string.isRequired,
  plan: PropTypes.object.isRequired,
  stepForward: PropTypes.func.isRequired,
};

ClientDetails.defaultProps = {
  contact: {},
};

export default ClientDetails;
