// Assistance Requests
export const ASSISTANCE_REQUESTS_VIEW_INCOMING = 'assistance_requests_view_incoming';

// Group Settings
export const GROUP_SETTINGS_VIEW_SETTINGS = 'organization_settings_view_settings';

// Intakes
export const INTAKES_VIEW_INTAKES = 'intake_view_intakes';

// Referrals
export const REFERRALS = 'referrals';
export const REFERRALS_AUTO_RECALL_REFERRALS = 'referrals_auto_recall_referrals';
export const REFERRALS_CLOSE_REFERRALS = 'referrals_close_referrals';
export const REFERRALS_CREATE_REFERRALS = 'referrals_create_referrals';
export const REFERRALS_FORWARD_REFERRALS = 'referrals_forward_referrals';
export const REFERRALS_RECALL_REFERRALS = 'referrals_recall_referrals';
export const REFERRALS_VIEW_CLOSED_REFERRALS = 'referrals_view_closed_referrals';
export const REFERRALS_VIEW_INCOMING_REFERRALS = 'referrals_view_incoming_referrals';
export const REFERRALS_VIEW_OUTGOING_REFERRALS = 'referrals_view_outgoing_referrals';

// Reporting
export const REPORTS_VIEW_REPORTS = 'reports_view_reports';

// Insights
export const INSIGHTS_VIEW_INSIGHTS = 'insights_view_insights';

// Screeings
export const SCREENINGS = 'screenings';
export const SCREENINGS_VIEW_SCREENINGS = 'screenings_view_screenings';
export const SCREENINGS_VIEW_INDEX = 'screenings_view_index';

// Resource Lists
export const RESOURCE_LISTS = 'resource_lists';
