import { UPDATE_SERVICE_CASE } from 'actions';
import Notifier from 'common/helpers/Notifier';
import _ from 'lodash';
import { coreApi } from 'src/api/config';

const addDraftReferral = (caseObj, receiving_provider, serviceCase) => {
  const referralResponse = coreApi.createRecord('referral', {
    state: 'draft',
    case: caseObj.id,
    sending_provider: caseObj.provider.id,
    sending_network: caseObj.network.id,
    receiving_network: serviceCase.referral.referred_to_network_id,
    receiving_provider: receiving_provider.provider_id,
    ...(receiving_provider.program_id ?
      { receiving_program: receiving_provider.program_id } :
      {}),
  });

  return referralResponse;
};

const recreateReferralsPBS = async (
  formRecipients,
  currentReferrals,
  caseObj,
  serviceCase,
) => {
  const formRecipientsIds = _.map(formRecipients, (r) => ({
    provider_id: r.group_id,
    program_id: _.get(r, 'program_id', null),
  }));

  const deleteOperations = currentReferrals.map((r) => coreApi.deleteRecord('referral', r.id));
  if (deleteOperations.length) {
    await Promise.all(deleteOperations);
  }

  const createOperations = formRecipientsIds.map((r) => addDraftReferral(caseObj, r, serviceCase));
  if (createOperations.length) {
    await Promise.all(createOperations);
  }
};

const recreateReferrals = async (
  formRecipients,
  currentReferrals,
  caseObj,
  serviceCase,
) => {
  const currentRecipientsIds = _.map(currentReferrals, (r) => ({
    provider_id: r.receiving_provider.id,
    program_id: _.get(r, 'receiving_program.id', null),
  }));
  const formRecipientsIds = _.map(formRecipients, (r) => ({
    provider_id: r.group_id,
    program_id: _.get(r, 'program_id', null),
  }));

  const deleteOperations = [];

  _.map(currentRecipientsIds, (r) => {
    const referralToDelete = !formRecipientsIds.some(
      (recipient) => recipient.provider_id === r.provider_id,
    );
    if (referralToDelete) {
      const FilteredReferral = _.filter(
        currentReferrals,
        (referral) => referral.receiving_provider.id === r.provider_id,
      );
      deleteOperations.push(
        coreApi.deleteRecord('referral', FilteredReferral[0].id),
      );
    }
  });

  if (deleteOperations.length) {
    await Promise.all(deleteOperations);
  }

  const createOrUpdateOperations = [];

  _.map(formRecipientsIds, (r) => {
    const referralToUpdate = currentRecipientsIds.some(
      (recipient) => recipient.provider_id === r.provider_id &&
        recipient.program_id !== r.program_id,
    );
    if (referralToUpdate) {
      const FilteredReferral = _.filter(
        currentReferrals,
        (referral) => referral.receiving_provider.id === r.provider_id,
      );
      createOrUpdateOperations.push(
        coreApi.updateRecord('referral', FilteredReferral[0].id, {
          receiving_program: r.program_id,
        }),
      );
    }
    const referralToCreate = !currentRecipientsIds.some(
      (recipient) => recipient.provider_id === r.provider_id,
    );
    if (referralToCreate) {
      createOrUpdateOperations.push(addDraftReferral(caseObj, r, serviceCase));
    }
  });

  if (createOrUpdateOperations.length) {
    await Promise.all(createOrUpdateOperations);
  }
};

export default function updateDraftCase(
  groupId,
  contactId,
  caseId,
  serviceCase,
  isProgramBasedSearch = false,
) {
  return async (dispatch) => {
    try {
      const [currentCase, caseReferrals] = await Promise.all([
        coreApi.findRecord('case', caseId),
        coreApi.query('referral', { case: caseId }),
      ]);
      const caseObj = currentCase.data.data;
      const currentReferrals = caseReferrals.data.data;
      const { service_type, description, referred_to_groups_draft } =
        serviceCase.referral;

      const serviceTypeChanged =
        service_type && service_type !== caseObj.service_type.id;
      const descriptionChanged =
        description && description !== caseObj.description;

      const serviceCasePayload = {
        ...(serviceTypeChanged && { service: { id: service_type } }),
        ...(descriptionChanged && { description }),
      };

      const payload = _.isEmpty(serviceCasePayload) ?
        currentCase :
        await coreApi.updateRecord('case', caseId, serviceCasePayload);

      const recreateReferralsFn = isProgramBasedSearch ? recreateReferralsPBS : recreateReferrals;

      // If we have recipients in the form, use those
      if (referred_to_groups_draft) {
        await recreateReferralsFn(
          referred_to_groups_draft,
          currentReferrals,
          caseObj,
          serviceCase,
        );
        // if service type changed, we need to clear the recipients
      } else if (serviceTypeChanged) {
        await recreateReferralsFn([], currentReferrals, caseObj, serviceCase);
      }

      dispatch({
        type: UPDATE_SERVICE_CASE,
        payload,
        contactId,
      });

      Notifier.dispatch(payload.status, 'Referral Successfully Updated');
      return payload;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}
