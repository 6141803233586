import { AR_ROUTE_PREFIX, FROM_AR_QUERY_PARAM } from '../constants';

// Cannot call query.hasOwnProperty() because hasOwnProperty is undefined.
// It must get stripped at some point.
const fromARQueryParamExists = (query = {}) => (
  Object.prototype.hasOwnProperty.call(query, FROM_AR_QUERY_PARAM)
);

const pathnameIndicatesAR = (pathname = '') => (
  pathname.split('/').includes(AR_ROUTE_PREFIX)
);

const isFromAssistanceRequest = ({ pathname = '', query = {} } = {}) => (
  fromARQueryParamExists(query) || pathnameIndicatesAR(pathname)
);

export default isFromAssistanceRequest;
