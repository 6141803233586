import geoCode from 'src/components/Browse/Map/utils/geoCode';
import { isNaN, isEmpty, toString } from 'lodash';

async function isAddressValid(location) {
  const {
    line_1, city, state, postal_code,
  } = location;

  const lineOneArray = toString(line_1).split(' ');
  const streetNumber = lineOneArray[0];
  const route = lineOneArray.slice(1, lineOneArray.length).join(' ');

  const geoCodedAddress = await geoCode(location);
  const { address_components } = geoCodedAddress;

  const verifyAddressComponent = (value) => {
    const component = address_components.find((c) => c.long_name.toLowerCase() === value.trim().toLowerCase() ||
        c.short_name.toLowerCase() === value.trim().toLowerCase());
    return !!component;
  };
  const verifyStreetNumber = line_1 ? verifyAddressComponent(!isNaN(streetNumber) ? streetNumber : line_1) : true;
  const verifyRoute = line_1 ? verifyAddressComponent(!isNaN(streetNumber) ? route : line_1) : true;
  const verifyCity = city ? verifyAddressComponent(city) : false;
  const verifyState = state ? verifyAddressComponent(state) : false;
  const verifyPostalCode = !isEmpty(line_1) && postal_code ? verifyAddressComponent(postal_code) : true;
  const valid =
    verifyStreetNumber &&
    verifyRoute &&
    verifyCity &&
    verifyState &&
    verifyPostalCode;

  return valid;
}

export default isAddressValid;
