import {
  inNetworkMarker,
  inNetworkSelectedMarker,
  outOfNetworkMarker,
  outOfNetworkSelectedMarker,
} from './mapMarkers';

export default function getMarkerIcon (markerGroupType, useHoverIcon = false) {
  if (useHoverIcon) {
    switch (markerGroupType) {
      case 'in_network':
        return inNetworkSelectedMarker;
      case 'out_of_network':
        return outOfNetworkSelectedMarker;
      case 'external_provider':
        return outOfNetworkSelectedMarker;
      default:
        return inNetworkSelectedMarker;
    }
  } else {
    switch (markerGroupType) {
      case 'in_network':
        return inNetworkMarker;
      case 'out_of_network':
        return outOfNetworkMarker;
      case 'external_provider':
        return outOfNetworkMarker;
      default:
        return inNetworkMarker;
    }
  }
}
