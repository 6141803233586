import _ from 'lodash';
import Notifier from 'common/helpers/Notifier';
import {
  FETCH_REFERRAL_PROGRAMS,
} from 'actions';
import {
  // coreApi,
  apiDefault,
} from 'src/api/config';

export default function fetchReferralPrograms(referral, groupId, options = {}) {
  const serviceTypeId = _.wget(referral, 'service_type.id');

  // const req = coreApi.query('program', {
  //   services: serviceTypeId,
  // });

  return (dispatch) => {
    const params = {
      'filter[services]': serviceTypeId,
      'filter[provider]': groupId,
      'filter[id]': options.ids,
      'filter[fee_schedule_program.billable]': options.excludeBillable ? 'false' : null,
      'filter[fee_schedule_program.authorization_required]': options.excludeAuthorizationRequired ? 'false' : null,
      'filter[include_pathways]': options.includePathways,
    };

    const request = apiDefault.get('/programs', {
      params: _.uuPickBy(params, _.identity),
    });

    const onSuccess = (response) => {
      dispatch({
        type: FETCH_REFERRAL_PROGRAMS,
        payload: response,
        serviceTypeId,
        referralId: referral.id,
      });
      return response;
    };
    const onError = (error) => {
      Notifier.handleErrors(error);
      return error;
    };
    return request.then(onSuccess, onError);
  };
}
