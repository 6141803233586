import { browserHistory } from 'common/utils/browserHistory';
import STEP_DEFAULTS from './utils/defaults';

import {
  DRAFT_ORG_SETTINGS,
  DRAFT_ORG_USER_SETTINGS,
} from '../constants';

const draftOrgStepperConfig = {
  steps: [
    {
      ...STEP_DEFAULTS.first,
      title: 'Organization',
      onStepClick: () => {
        browserHistory.push(`/organization/${DRAFT_ORG_SETTINGS}`);
      },
      routeRegex: /^\/?organization\/settings(?:$|(\/edit)|(\/(edit|programs|locations)\/.*$))/,
    },
    {
      ...STEP_DEFAULTS.second,
      title: 'Users',
      onStepClick: () => {
        browserHistory.push(`/organization/${DRAFT_ORG_USER_SETTINGS}`);
      },
      routeRegex: /^\/?organization\/settings\/(users|users\/.*$)/,
    },
    {
      ...STEP_DEFAULTS.third,
      title: 'Submit',
    },
  ],
};

export default draftOrgStepperConfig;
