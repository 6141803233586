import Notifier from 'common/helpers/Notifier';
import {
  REFERRAL_ALREADY_ACCEPTED_MESSAGE,
} from 'src/components/Referrals/ReferralStatus/constants';

function notifyAutoRecalledCannotView() {
  return () => (
    Notifier.dispatch('error', REFERRAL_ALREADY_ACCEPTED_MESSAGE)
  );
}

export default notifyAutoRecalledCannotView;
