import React from 'react';
import { PropTypes } from 'prop-types';
import cx from 'classnames';
import { Icon } from '@unite-us/ui';

const AuditTrail = ({ className, versions }) => (
  <div className={className}>
    <ul className="pl-0 space-y-5">
      {versions.map(({ icon, message, date }, versionIdx) => (
        <li key={`${message}-${date}`}>
          <div className="relative">
            {versionIdx !== versions.length - 1 ? (
              <div className="absolute left-0 top-0 mt-6 ml-3 mr-px h-full w-0.5" aria-hidden="true">
                <div className="-ml-px bg-dark-fill-blue w-full h-full" />
              </div>
            ) : null}
            <div className="relative flex items-center space-x-3">
              <div
                aria-hidden
                className={cx(
                  'bg-dark-fill-blue',
                  'h-6 w-6 rounded-full flex items-center justify-center',
                )}
              >
                <Icon
                  color="currentColor"
                  className="text-dark-grey"
                  icon={icon}
                  size={14}
                />
              </div>
              <div className="min-w-0 flex-1 flex justify-between space-x-12">
                <div>
                  <p className="text-14px text-dark-grey">
                    {message}
                  </p>
                </div>
                <div className="flex-shrink-0 text-right text-sm whitespace-nowrap text-dark-grey">
                  {date}
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

AuditTrail.propTypes = {
  className: PropTypes.string,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

AuditTrail.defaultProps = {
  className: '',
};

export default AuditTrail;
