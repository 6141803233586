import _ from 'lodash';
import moment from 'moment';
import { coreApi } from 'src/api/config';
import { getUpdatedValues } from './utils/getPersonChanges';

const updateInvalidPhoneTypes = (phoneNumbers) => _.map(phoneNumbers, (phoneNumber) => {
  if (phoneNumber.phone_type === 'phone') {
      return _.assign({}, phoneNumber, { phone_type: 'unknown' });
  }
  return phoneNumber;
});

const distinctRecordLanguages = (recordLanguagesChanges) => {
  const [arr1, arr2] = recordLanguagesChanges;
  const isSameLanguage = (lang1, lang2) => lang1.record_language_type === lang2.record_language_type &&
    lang1.language_id === lang2.language_id;

  const onlyInArr2 = arr2.filter((item2) => !arr1.some((item1) => isSameLanguage(item1, item2)));

  return [...onlyInArr2];
};

const mergeChangesWithEmpiPerson = (personData, changes) => {
  const newValues = getUpdatedValues(changes.updates);
  const personDataPhoneNumbres = changes.additions.phone_numbers.length > 0 ?
                                  personData.phone_numbers.map((phone) => ({ ...phone, is_primary: false })) :
                                  personData.phone_numbers;
  const personDataEmailAddresses = changes.additions.email_addresses.length > 0 ?
                                  personData.email_addresses.map((email) => ({ ...email, is_primary: false })) :
                                  personData.email_addresses;

  return {
    ...personData,
    date_of_birth: moment.unix(personData.date_of_birth).toISOString(),
    ...newValues,
    phone_numbers: _.concat(updateInvalidPhoneTypes(personDataPhoneNumbres), changes.additions.phone_numbers),
    email_addresses: _.concat(personDataEmailAddresses, changes.additions.email_addresses),
    addresses: _.concat(personData.addresses, changes.additions.addresses),
  };
};

const mergeChangesWithExistingPerson = (personData, changes) => {
  const newValues = getUpdatedValues(changes.updates);
  const personDataPhoneNumbres = changes.additions.phone_numbers.length > 0 ?
                                  personData.phone_numbers.map((phone) => ({ ...phone, is_primary: false })) :
                                  personData.phone_numbers;
  const personDataEmailAddresses = changes.additions.email_addresses.length > 0 ?
                                  personData.email_addresses.map((email) => ({ ...email, is_primary: false })) :
                                  personData.email_addresses;
  return {
    ...newValues,
    phone_numbers: _.concat(personDataPhoneNumbres, changes.additions.phone_numbers),
    email_addresses: _.concat(personDataEmailAddresses, changes.additions.email_addresses),
    addresses: changes.additions.addresses,
    record_languages: changes.updates.record_languages ? distinctRecordLanguages(changes.updates.record_languages) : [],
  };
};

const hasChanges = (changes) => {
  const hasAdditions = ['phone_numbers', 'email_addresses', 'addresses'].some(
    (key) => !_.isEmpty(changes?.additions?.[key]),
  );

  return !_.isEmpty(changes.updates) || hasAdditions;
};

const createAssistanceRequestCaseRelationship = async (requestId, personData, personChanges, personId = null) => {
  let value = {};

  if (personId) {
    const mergedPersonData = hasChanges(personChanges) ? mergeChangesWithExistingPerson(personData, personChanges) : {};
    value = { id: personId, type: 'person', person_data: mergedPersonData };
  } else if (personData) {
    const mergedPersonData = mergeChangesWithEmpiPerson(personData, personChanges);
    value = { type: 'person', person_data: mergedPersonData };
  }

  return coreApi.setRelationship(
    'assistance_request',
    requestId,
    { relationshipModel: 'case', value },
  );
};

export default createAssistanceRequestCaseRelationship;
