import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from 'lodash';
import DetailLabelWrapper from
  'common/display/SingleItemDetails/DetailLabelWrapper';
import { referralBasicInformationTableData } from '../utils';

const ReferralBasicInformation = ({ groupId, isCC, referral }) => (
  !isEmpty(referral) ? (
    <DetailLabelWrapper
      data={referralBasicInformationTableData({ groupId, isCC, referral })}
    />
  ) : null
);

ReferralBasicInformation.propTypes = {
  groupId: PropTypes.string.isRequired,
  isCC: PropTypes.bool,
  referral: PropTypes.object.isRequired,
};

ReferralBasicInformation.defaultProps = {
  isCC: false,
};

export default ReferralBasicInformation;
