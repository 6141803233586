import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@unite-us/ui/dist/Display/Icon';
import ToolTip from 'common/ToolTip';

const CardDetail = ({
  label, tooltip, children, dataTestId,
}) => (
  <div className="flex">
    <div className="flex col-xs-5 text-sm font-extrabold">
      {`${label}:`}
      { tooltip && (
        <ToolTip
          tooltipElementAs="span"
          enableHover
          buttonComponentContent={() => (
            <Icon
              icon="IconInfoCircle"
              className="fill-current text-text-blue"
              ariaLabel={tooltip}
            />
          )}
          buttonClassName="px-2 focus:outline-none"
          panelComponentContent={() => (tooltip)}
          panelClassName="bg-white border border-gray-400 border-solid rounded
          shadow-md text-dark-grey leading-4
            w-80 px-5 py-3"
          placement="bottom-start"
        />
      )}
    </div>
    <div
      className="col-xs-7 text-sm"
      {...dataTestId && { 'data-testid': dataTestId }}
    >
      {children}
    </div>
  </div>
);

CardDetail.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  children: PropTypes.node,
  dataTestId: PropTypes.string,
};

CardDetail.defaultProps = {
  tooltip: '',
  children: '',
  dataTestId: '',
};

export default CardDetail;
