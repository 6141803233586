import { get as getCookie, set as setCookie } from 'es-cookie';
import { setAuthTokenClients } from 'src/api/config';
import axios from 'axios';
import { setAxiosHeaders, secureProtocol } from 'common/utils/utils';
import { AUTH_URL, CLIENT_ID } from 'src/config/env/env.config';
import { SET_AUTH_SESSION } from 'actions';
import { COOKIE_SESSION_KEY } from 'src/common/constants';

const refresh = async (dispatch) => {
  const tokenFromCookie = getCookie(COOKIE_SESSION_KEY);
  const parsedTokenCookie = JSON.parse(tokenFromCookie);
  const reqParams = {
    client_id: CLIENT_ID,
    refresh_token: parsedTokenCookie.refresh_token,
    grant_type: 'refresh_token',
  };

  try {
    const AUTH_TOKEN_URL = `${AUTH_URL}/oauth2/token`;
    const authResponse = await axios.post(AUTH_TOKEN_URL, reqParams);
    const { data } = authResponse;
    const expiresIn = data.expires_in;
    const expirationDate = new Date();
    expirationDate.setSeconds(expirationDate.getSeconds() + expiresIn);

    if (!data.refresh_token && window.Rollbar) {
      window.Rollbar.error(
        `Setting COOKIE_SESSION_KEY (2).
        Auth response: ${JSON.stringify(data, 0, 2)},
        Request params: ${JSON.stringify(reqParams, 0, 2)}`,
      );
    }

    setCookie(COOKIE_SESSION_KEY, JSON.stringify(data), { secure: secureProtocol, expires: expirationDate });
    setAxiosHeaders(data.access_token);
    setAuthTokenClients(data.access_token);
    const payload = {
      session: {
        access_token: data.access_token,
        token: data.refresh_token,
        expires_in: data.expires_in,
      },
    };

    dispatch({
      type: SET_AUTH_SESSION,
      payload,
    });
  } catch (error) {
    const message = 'Error refreshing token';
    if (window.Rollbar) {
      window.Rollbar.error(message, error);
    } else {
      // eslint-disable-next-line no-console
      console.error(message, error);
    }
  }
};

export default function refreshSession() {
  return (dispatch, getState) => refresh(dispatch, getState);
}
