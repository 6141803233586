import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { USER_LOGOUT } from 'actions';
import { reduxForm7 } from '@unite-us/client-utils';
import FeatureFlags from 'common/utils/FeatureFlags';
import accountConfirmationReducer from './accountConfirmationReducer';
import assessmentsReducer from './assessmentsReducer';
import assistanceRequestReducer from './assistanceRequestReducer';
import browseReducer from './browseReducer';
import caseAssessmentsReducer from './caseAssessmentsReducer';
import profileFormsReducer from './profileFormsReducer';
import checkProgramEnrollmentReducer from './checkProgramEnrollmentReducer';
import contactDocumentsReducer from './contactDocumentsReducer';
import contactReferralsReducer from './contactReferralsReducer';
import contactsReducer from './contactsReducer';
import dashboardReducer from './dashboardReducer';
import employeesReducer from './employeesReducer';
import exportReducer from './exportReducer';
import feeSchedulesReducer from './feeSchedulesReducer';
import feeScheduleProgramsReducer from './feeScheduleProgramsReducer';
import fileUploadReducer from './fileUploadReducer';
import formRendererReducer from './formRendererReducer';
import globalReducer from './globalReducer';
import globalServiceTypesReducer from './globalServiceTypesReducer';
import groupFormsReducer from './groupFormsReducer';
import groupsProgramsReducer from './groupsProgramsReducer';
import groupsReducer from './groupsReducer';
import groupLocationsReducer from './groupLocationsReducer';
import groupsRolesReducer from './groupsRolesReducer';
import groupsUsersReducer from './groupsUsersReducer';
import insurancesReducer from './insurancesReducer';
import intakesReducer from './intakesReducer';
import invitationsReducer from './invitationsReducer';
import isWorkingReducer from './isWorkingReducer';
import networkGroupsReducer from './networkGroupsReducer';
import networksReducer from './networksReducer';
import networkUsersReducer from './networkUsersReducer';
import notificationPreferencesContactsReducer from './notificationPreferencesContactsReducer';
import notificationPreferencesGroupsReducer from './notificationPreferencesGroupsReducer';
import notificationPreferencesNetworksReducer from './notificationPreferencesNetworksReducer';
import notificationSettingsGlobalReducer from './notificationSettingsGlobalReducer';
import notificationsReducer from './notificationsReducer';
import outcomesReducer from './outcomesReducer';
import referralAssessmentsReducer from './referralAssessmentsReducer';
import referralDocumentsReducer from './referralDocumentsReducer';
import referralProgramEnrollmentReducer from './referralProgramEnrollmentReducer';
import referralProgramsReducer from './referralProgramsReducer';
import referralsReducer from './referralsReducer';
import referralStatusReducer from './referralStatusReducer';
import roleReducer from './roleReducer';
import screeningsReducer from './screeningsReducer';
import searchContactReducer from './searchContactReducer';
import searchReducer from './searchReducer';
import selectedContactReducer from './selectedContactReducer';
import serviceCaseReducer from './serviceCaseReducer';
import serviceTypesReducer from './serviceTypesReducer';
import sessionReducer from './sessionReducer';
import socketsReducer from './socketsReducer';
import subscriptionReducer from './subscriptionReducer';
import systemNotificationsReducer from './systemNotificationsReducer';
import userReducer from './userReducer';
import userV2Reducer from './userV2Reducer';
import userAuthServiceReducer from './userAuthServiceReducer';
import accountRolesReducer from './accountRolesReducer';
import payersReducer from './payersReducer';
import plansReducer from './plansReducer';
import fileUploadsV2Reducer from './fileUploadsV2Reducer';
import invoicedSpendReducer from './invoicedSpendReducer';

const appReducer = combineReducers({
  accountConfirmation: accountConfirmationReducer,
  accountRoles: accountRolesReducer,
  assistanceRequests: assistanceRequestReducer,
  browse: browseReducer,
  caseAssessments: caseAssessmentsReducer,
  caseProgramEnrollment: checkProgramEnrollmentReducer,
  profileForms: profileFormsReducer,
  contactDocuments: contactDocumentsReducer,
  contactReferrals: contactReferralsReducer,
  contacts: contactsReducer,
  dashboard: dashboardReducer,
  employee: employeesReducer,
  export: exportReducer,
  feeSchedules: feeSchedulesReducer,
  feeSchedulePrograms: feeScheduleProgramsReducer,
  fileupload: fileUploadReducer,
  fileUploads: fileUploadsV2Reducer,
  form: formReducer,
  form7: reduxForm7.reducer,
  formStatus: formRendererReducer,
  flags: FeatureFlags.reducer,
  globalState: globalReducer,
  groupForms: groupFormsReducer,
  groups: groupsReducer,
  groupLocations: groupLocationsReducer,
  groupsPrograms: groupsProgramsReducer,
  groupsRoles: groupsRolesReducer,
  groupsUsers: groupsUsersReducer,
  insurances: insurancesReducer,
  intakes: intakesReducer,
  invitations: invitationsReducer,
  isWorking: isWorkingReducer,
  networkGroups: networkGroupsReducer,
  networks: networksReducer,
  networkUsers: networkUsersReducer,
  notificationPreferencesContacts: notificationPreferencesContactsReducer,
  notificationPreferencesGroups: notificationPreferencesGroupsReducer,
  notificationPreferencesNetworks: notificationPreferencesNetworksReducer,
  notifications: notificationsReducer,
  notificationSettingsGlobal: notificationSettingsGlobalReducer,
  outcomes: outcomesReducer,
  payers: payersReducer,
  plans: plansReducer,
  referralAssessments: referralAssessmentsReducer,
  referralDocuments: referralDocumentsReducer,
  referralProgramEnrollment: referralProgramEnrollmentReducer,
  referralPrograms: referralProgramsReducer,
  referrals: referralsReducer,
  referralStatus: referralStatusReducer,
  role: roleReducer,
  screenings: screeningsReducer,
  search: searchReducer,
  searchedContacts: searchContactReducer,
  selectedContact: selectedContactReducer,
  selectedServiceTypes: serviceTypesReducer,
  serviceCase: serviceCaseReducer,
  serviceTypes: globalServiceTypesReducer,
  session: sessionReducer,
  socket: socketsReducer,
  subscription: subscriptionReducer,
  systemNotifications: systemNotificationsReducer,
  user: userReducer,
  userV2: userV2Reducer,
  userAssessments: assessmentsReducer,
  userAuthService: userAuthServiceReducer,
  invoicedSpend: invoicedSpendReducer,
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return appReducer(undefined, action);
    default:
      return appReducer(state, action);
  }
};

export default rootReducer;
