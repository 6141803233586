import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, some } from 'lodash';
import { FieldErrorMessage } from 'common/form';
import './stylesheets/mimicReferralGroupValidations.scss';

const isRequiredValidation = (field = {}, groupCount = 1) => (
  groupCount === 1 && !field.value.id && field.touched
);

const topClasses = (showError) => classNames(
  'mimic-referral-group-validations',
  {
    'ui-select-field': showError,
    'ui-select-field--has-error': showError,
    hasError: showError,
  },
);

function MimicReferralGroupValidations(props) {
  const {
    allowEmptyGroups,
    children,
    field,
    fields,
    hidden,
    mimicValidation,
    groupErrors,
  } = props;

  const hasIsRequiredError = !allowEmptyGroups &&
    isRequiredValidation(field, fields.length);
  const hasGroupErrors = !isEmpty(groupErrors);

  const showError = mimicValidation && !hidden && some([
    hasIsRequiredError,
    hasGroupErrors,
  ]);

  return (
    <div className={topClasses(showError)}>
      {children}
      {
        showError && hasIsRequiredError &&
          <FieldErrorMessage message="Required" />
      }
      {
        showError && hasGroupErrors && groupErrors.map((error) => (
          <FieldErrorMessage
            key={error}
            message={error}
          />
        ))
      }
    </div>
  );
}

MimicReferralGroupValidations.propTypes = {
  allowEmptyGroups: PropTypes.bool,
  children: PropTypes.any.isRequired,
  field: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  groupErrors: PropTypes.array,
  hidden: PropTypes.bool,
  mimicValidation: PropTypes.bool,
};

MimicReferralGroupValidations.defaultProps = {
  allowEmptyGroups: false,
  groupErrors: [],
  hidden: false,
  invalidGroupMessages: [],
  mimicValidation: true,
};

export default MimicReferralGroupValidations;
