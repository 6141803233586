import {
  FETCH_NETWORK_USERS_STARTED,
  FETCH_NETWORK_USERS_SUCCESS,
  FETCH_NETWORK_USERS_ERROR,
} from 'actions';
import convertCoreUser from 'src/api/utils/convertCoreUser';
import convertCorePaging from 'src/api/utils/convertCorePaging';
import fetchEmployees from 'src/api/core/Employees/fetchEmployees';
import Notifier from 'common/helpers/Notifier';
import { uniq } from 'lodash';
import { coreApi } from 'src/api/config';

export const defaultSearchParams = {
  page: 1,
  per: 50,
  q: {
    text: '',
  },
};

const onError = (dispatch, error) => {
  Notifier.handleErrors(error);
  dispatch({ type: FETCH_NETWORK_USERS_ERROR, payload: { error } });
  return error;
};

const onSuccess = (dispatch, payload, searchParams, network) => {
  dispatch({
    type: FETCH_NETWORK_USERS_SUCCESS,
    id: network,
    payload,
    searchParams,
    replace: true,
  });

  return payload;
};

export const getUsers = (employees, providers) => employees.map((employee) => {
  const user = convertCoreUser(employee);
  const provider = providers.find((prov) => prov.id === user.employee.provider.id);
  return {
    ...user.user,
    employeeId: employee.id,
    groups: [{
      ...provider,
    }],
  };
});

export default function fetchNetworkUsers({ network, page = 1, query = undefined }) {
  return async (dispatch) => {
    try {
      const searchParams = {
        ...defaultSearchParams,
        page,
      };
      dispatch({
        replace: true,
        searchParams,
        type: FETCH_NETWORK_USERS_STARTED,
      });
      const { employees, response: employeesResponse } = await dispatch(fetchEmployees({
        networks: network,
        page,
        query,
        licensed: true,
      }));

      const paging = convertCorePaging(employeesResponse.data.meta.page);
      if (!employees || !employees.length) {
        const payload = {
          data: {
            data: [],
            paging,
          },
        };
        return onSuccess(dispatch, payload, searchParams, network);
      }
      const providerIds = uniq(employees.map((employee) => employee.provider.id));
      const providersResponse = await coreApi.query('provider', { ids: providerIds });
      const { data: { data: providers } } = providersResponse;
      const users = getUsers(employees, providers);
      const payload = {
        data: {
          data: users,
          paging,
        },
      };
      return onSuccess(dispatch, payload, searchParams, network);
    } catch (error) {
      return onError(dispatch, error);
    }
  };
}
