import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';

const defaultTooltipGenerator = (d) => {
  if (!d) {
    return '';
  }

  const percent = (data) => {
    if (_.has(data, 'percent')) {
      return (<td className="percent">({data.percent}%)</td>);
    }
    return null;
  };

  const html = (
    <table>
      <tbody>
        {
          d.value ? (<tr><th className="title" colSpan="4">{d.value}</th></tr>) : null
        }
        {
          d.series.map((serie, index) => (
            <tr key={index}>
              <td className="legend-color-guide">
                <div style={{ backgroundColor: serie.color }} />
              </td>
              <td className="key">{serie.key}</td>
              <td className="value">{serie.value}</td>
              {percent((d.series.length > 1) ? serie.data : d.data)}
            </tr>
          ))
        }
      </tbody>
    </table>
  );

  const container = document.createElement('div');
  ReactDOM.render(html, container);

  return container.outerHTML;
};

export default defaultTooltipGenerator;
