import PropTypes from 'prop-types';
import React from 'react';
import EditModal from 'common/modal/EditModal';
import EditAddressesGroup from 'common/form/Profile/EditAddress/EditAddressesGroup';
import Address from 'common/display/Address';
import { EDIT_ADDRESS_FIELDS } from 'common/display/Profile/constants/form';
import RowDisplay from 'common/display/Profile/RowDisplay';

const AddressesGroup = (props) => {
  const {
    addresses,
    addressTypes,
    groupId,
    onSave,
  } = props;

  const editSection = (
    <EditModal
      header="Edit Address"
      size="large"
      formName={EDIT_ADDRESS_FIELDS}
      buttonId="edit-address-btn"
      id="edit-address-modal"
    >
      <EditAddressesGroup
        addresses={addresses}
        groupId={groupId}
        onSave={onSave}
        addressTypes={addressTypes}
      />
    </EditModal>
  );

  return (
    <RowDisplay
      editSection={editSection}
      label="Address"
    >
      {
        addresses.map((address = {}) => (
          <div className="col-sm-6" key={address.id}>
            <Address item={address.attributes} />
          </div>
        ))
      }
    </RowDisplay>
  );
};

AddressesGroup.propTypes = {
  addresses: PropTypes.array,
  groupId: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  addressTypes: PropTypes.array.isRequired,
};

AddressesGroup.defaultProps = {
  addresses: [],
};

export default AddressesGroup;
