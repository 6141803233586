import React from 'react';
import PropTypes from 'prop-types';
import { useRoles } from 'src/components/Organization/api/hooks/v1/roleHooks';
import { Spinner } from 'src/common/spinners';

const PRIMARY_ROLES_TITLE = 'Primary Role';
const FEATURE_ROLES_TITLE = 'Feature Role';

const RolesList = ({ roleIds }) => {
  const { isFetching, data = [] } = useRoles({ roleIds });

  const displayRole = (role) => <div key={role.id} className="leading-5 font-regular-font">{role.name}</div>;
  const primaryRoles = data.filter((role) => role.role_type === 'primary').map(displayRole);
  const featureRoles = data.filter((role) => role.role_type === 'feature').map(displayRole);

  const primaryRolesTitle = primaryRoles.length > 1 ? `${PRIMARY_ROLES_TITLE}s` : PRIMARY_ROLES_TITLE;
  const featureRolesTitle = featureRoles.length > 1 ? `${FEATURE_ROLES_TITLE}s` : FEATURE_ROLES_TITLE;

  return (
    <>{isFetching ? <Spinner center /> : (
      <>
        <h4 className="mt-3 leading-6" data-test-element="primary_role">{ primaryRolesTitle }</h4>
        { primaryRoles }
        <h4 className="mt-3 leading-6" data-test-element="feature_roles">{ featureRolesTitle }</h4>
        { featureRoles }
      </>
    )}
    </>
  );
};

RolesList.propTypes = {
  roleIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RolesList;
