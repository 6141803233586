import {
  FETCH_CONTACT_REFERRALS,
  SUBMIT_REFERRAL,
  SET_REFERRAL_IS_SAVING,
  UNSET_REFERRAL_IS_SAVING,
} from 'actions';

function contactReferralsReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_CONTACT_REFERRALS:
      return {
        ...state,
        [action.id]: {
          data: [...action.payload.data.data],
        },
        paging: action.payload.data.paging,
      };

    case SUBMIT_REFERRAL: {
      const newState = { ...state };
      action.payload.data.data.forEach((x) => {
        const contactId = x.contact.id;
        const hash = newState[contactId];

        if (hash) {
          const hashData = [x, ...hash.data];
          newState[contactId].data = hashData;
        } else {
          newState[contactId] = {
            data: [x],
          };
        }
      });

      return newState;
    }

    case SET_REFERRAL_IS_SAVING:
      return { ...state, isSaving: true };

    case UNSET_REFERRAL_IS_SAVING:
      return { ...state, isSaving: false };

    default:
      return state;
  }
}

export default contactReferralsReducer;
