import React from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { CardDetail, CardSubHeader } from 'common/Card';
import getClientStatusText from '../../utils/getClientStatusText';

const SocialCareCoverageDetailsSection = ({ enrollmentRequest }) => {
  const insurance = enrollmentRequest.insurance;
  const insuranceStatus = insurance?.insurance_status;
  const maybeDateToString = (maybeDate) => (moment(maybeDate).utc().format('MMM D, YYYY'));

  return (
    <>
      <CardSubHeader>Social Care Coverage Details</CardSubHeader>
      {insurance.enrolled_at &&
        <CardDetail label="Start Date">{maybeDateToString(insurance.enrolled_at)}</CardDetail>}
      {insurance.expired_at &&
        <CardDetail label="End Date">{maybeDateToString(insurance.expired_at)}</CardDetail>}
      <CardDetail label="Social Care Coverage Plan">{enrollmentRequest.plan.name}</CardDetail>
      <CardDetail label="Social Care Coverage Status">
        {getClientStatusText(insuranceStatus)}
      </CardDetail>
    </>
  );
};

SocialCareCoverageDetailsSection.propTypes = {
  enrollmentRequest: PropTypes.object.isRequired,
};

export default SocialCareCoverageDetailsSection;
