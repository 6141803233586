import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Icon } from '@unite-us/ui';
import { noop } from 'lodash';

class ReferralServiceCardTitle extends Component {
  constructor(props) {
    super(props);
    this.onDelete = this.onDelete.bind(this);
  }

  onDelete(e) {
    e.stopPropagation();
    this.props.onDelete(e, this.props.index);
  }

  render() {
    const {
      canDelete,
      errorCount,
      iconStyles,
      isExpanded,
      title,
      hasBeenExpanded,
    } = this.props;

    const errorCountText = errorCount === 1 ? 'Error' : 'Errors';
    return (
      <span className="referral-service-card-title">
        {title}
        {
          !isExpanded && errorCount > 0 && (
          <>
            {!hasBeenExpanded ?
              (<span className="warn-display has-warn ml-two">Needs Review</span>) :
              (
                <span className="error-display has-error ml-two">
                  {`${errorCount} ${errorCountText}`}
                </span>
              )}
          </>
          )
        }
        {
          canDelete && (
            <button
              aria-label="delete referral"
              aria-haspopup="dialog"
              type="button"
              className="service-type-action pull-right"
              onClick={this.onDelete}
            >
              <Icon icon="IconTrash" style={iconStyles} />
            </button>
          )
        }
      </span>
    );
  }
}

ReferralServiceCardTitle.propTypes = {
  canDelete: PropTypes.bool,
  errorCount: PropTypes.number,
  iconStyles: PropTypes.object,
  index: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool,
  onDelete: PropTypes.func,
  title: PropTypes.string,
  hasBeenExpanded: PropTypes.bool,
};

ReferralServiceCardTitle.defaultProps = {
  canDelete: false,
  errorCount: 0,
  iconStyles: {
    svg: {
      width: '1.5em',
      height: '1.5em',
      color: '#C5C8CC',
    },
  },
  isExpanded: false,
  onDelete: noop,
  title: 'Referral',
  hasBeenExpanded: false,
};

export default ReferralServiceCardTitle;
