import { has } from 'lodash';

const getOONGroupNameFromField = ({ group = {} } = {}) => {
  let name;

  switch (true) {
    case has(group.value, 'name'):
      name = group.value.name;
      break;

    case has(group.value, 'displayName'):
      name = group.value.displayName;
      break;

    case has(group, 'name') && !has(group, 'value'):
      name = group.name;
      break;

    case has(group, 'displayName'):
      name = group.displayName;
      break;

    default:
      name = group.value;
      break;
  }

  return name ? name.trim() : '';
};

export default getOONGroupNameFromField;
