import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { colors } from '@unite-us/client-utils';
import { Icon, Menu, MenuItem } from '@unite-us/ui';
import { routeOnKeyDown } from 'src/components/Navigation/utils';

const VerticalDotsMenu = ({
  className,
  iconColor,
  iconSize,
  id,
  menuItemStyle,
  menuItems,
}) => (
  <Menu
    anchorElement={(
      <Icon
        ariaLabel="Vertical Dots Menu"
        canReceiveFocus={false}
        color={iconColor}
        icon="IconEllipsisV"
        size={iconSize}
      />
    )}
    className={classNames('vertical-dots-menu', className)}
    id={id}
    position="bottom-end"
  >
    {
      menuItems.map((option, index) => (
        <MenuItem
          className="vertical-dots-menu__menu-item"
          id={`${id}-item-${index}`}
          key={index}
          onKeyDown={routeOnKeyDown(option.onSelect)}
          onClick={option.onSelect}
          style={menuItemStyle}
        >
          {option.label}
        </MenuItem>
      ))
    }
  </Menu>
);

VerticalDotsMenu.propTypes = {
  className: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.number,
  id: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onSelect: PropTypes.func.isRequired,
    }).isRequired,
  ),
  menuItemStyle: PropTypes.object,
};

VerticalDotsMenu.defaultProps = {
  className: '',
  iconColor: colors.palettes.BLUE,
  iconSize: 18,
  menuItems: [],
  menuItemStyle: {},
};

export default VerticalDotsMenu;
