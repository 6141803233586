import {
  SET_CONTACT_INTAKES_FETCHING,
  UNSET_CONTACT_INTAKES_FETCHING,
  FETCH_CONTACT_INTAKES,
  RESET_CONTACT_INTAKES,
  FETCH_GROUP_INTAKE,
  SET_GROUP_INTAKE_FETCHING,
  UNSET_GROUP_INTAKE_FETCHING,
  DELETE_GROUP_INTAKE,
  FETCH_CONTACT_INTAKE,
  SET_CONTACT_INTAKE_FETCHING,
  UNSET_CONTACT_INTAKE_FETCHING,
  FETCH_GROUPS_USERS_WITH_CONTACTS,
  FETCH_GROUPS_USERS_WITH_CONTACTS_FAIL,
  RESET_INTAKES,
  USER_LOGOUT,
  ADD_INTAKE_DOCUMENTS,
  REMOVE_INTAKE_DOCUMENT,
  RENAME_INTAKE_DOCUMENT,
} from 'actions';
import _ from 'lodash';

export const defaultState = {
  isFetching: false,
  intakes: [],
  currentIntake: {},
  usersWithContacts: {},
};

function replaceIntakeDocumentsInState({ state, newDocuments }) {
  return {
    ...state,
    currentIntake: {
      ...state.currentIntake,
      documents: newDocuments,
    },
  };
}

function removeIntakefromList(intakes, intakeId) {
  return _.uuOmit(intakes, (intake) => intake.id === intakeId);
}

function renameDocument({ documents = [], documentId, title }) {
  return documents.reduce((acc, curr) => (
    curr.id === documentId ? [...acc, { ...curr, title }] : [...acc, curr]
  ), []);
}

function removeDocument({ documents = [], documentId }) {
  return _.reject(documents, { id: documentId });
}

export default function intakesReducer(state = defaultState, action = {}) {
  const {
    documentId, documents, intakes, title,
  } = action;

  switch (action.type) {
    case SET_CONTACT_INTAKES_FETCHING:
    case SET_CONTACT_INTAKE_FETCHING:
    case SET_GROUP_INTAKE_FETCHING:
      return _.assign({}, state, {
        isFetching: true,
        currentIntake: defaultState.currentIntake,
      });

    case UNSET_CONTACT_INTAKES_FETCHING:
    case UNSET_CONTACT_INTAKE_FETCHING:
    case UNSET_GROUP_INTAKE_FETCHING:
      return _.assign({}, state, { isFetching: false });

    case RESET_CONTACT_INTAKES:
      return _.assign({}, state, {
        intakes: [],
      });
    case RESET_INTAKES:
    case USER_LOGOUT:
      return { ...defaultState };
    case FETCH_CONTACT_INTAKES:
      return {
        ...state,
        intakes,
      };

    case FETCH_GROUP_INTAKE:
      return {
        ...state,
        currentIntake: action.payload.data.data,
      };

    case DELETE_GROUP_INTAKE:
      return {
        ...state,
        currentIntake: {},
        intakes: removeIntakefromList(state.intakes.intakes),
      };

    case FETCH_CONTACT_INTAKE:
      return {
        ...state,
        currentIntake: action.payload.data.data,
      };

    case FETCH_GROUPS_USERS_WITH_CONTACTS:
      return {
        ...state,
        usersWithContacts: {
          success: true,
          users: action.payload.data.data,
        },
      };

    case FETCH_GROUPS_USERS_WITH_CONTACTS_FAIL:
      return {
        ...state,
        usersWithContacts: {
          success: false,
          users: [],
        },
      };

    case ADD_INTAKE_DOCUMENTS:
      return replaceIntakeDocumentsInState({
        state,
        newDocuments: [..._.get(state, 'currentIntake.documents', []), ...documents],
      });

    case RENAME_INTAKE_DOCUMENT: {
      const newDocuments = renameDocument({
        documents: _.get(state, 'currentIntake.documents', []),
        documentId,
        title,
      });
      return replaceIntakeDocumentsInState({ state, newDocuments });
    }

    case REMOVE_INTAKE_DOCUMENT: {
      const newDocuments = removeDocument({
        documents: _.get(state, 'currentIntake.documents', []),
        documentId,
      });
      return replaceIntakeDocumentsInState({ state, newDocuments });
    }

    default:
      return state;
  }
}
