import Notifier from 'common/helpers/Notifier';

const INVALID_MESSAGE = 'Could not send referral. A description and recipient groups are required';

function notifyInvalidDraftReferral() {
  return () => (
    Notifier.dispatch('error', INVALID_MESSAGE)
  );
}

export default notifyInvalidDraftReferral;
