import React from 'react';
import PropTypes from 'prop-types';

export const UnsavedFormWarning = ({ saveSurvey }) => (
  <div className="h-screen bg-white mr-2">
    <div className="p-5">
      <h1>It looks like you haven&apos;t saved your work.</h1>
      <br />
      <h1>To save the form and open the Edit Logic tab, click [Save Form and Go to Edit Logic].</h1>
      <button
        className="mt-4 btn btn-primary sv-btn svd-toolbar-button svd_save_btn"
        type="submit"
        onClick={() => saveSurvey('editLogic')}
      >
        <div className="svd-toolbar-button__title">
          <span data-bind="text: item.title">Save Form and Go to Edit Logic</span>
        </div>
      </button>
    </div>
  </div>
);

UnsavedFormWarning.propTypes = {
  saveSurvey: PropTypes.func.isRequired,
};

export default UnsavedFormWarning;
