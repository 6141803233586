import PropTypes from 'prop-types';
import React, { Component } from 'react';
import EditModal from 'common/modal/EditModal';
import ServiceCaseReferredToForm from 'src/components/Cases/components/Detail/ServiceCaseReferredToForm';
import _ from 'lodash';
import { connect } from 'react-redux';
import { hasCasesOONReferralUnlicensedOrgs, uup459SupersetPhase2 } from 'src/common/utils/FeatureFlags/flags';

export class ServiceCaseReferredTo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceCaseOONProviders: [],
    };
  }

  render() {
    const {
      invalidateCaseReferrals,
      serviceCase,
      contact,
      editable,
      groupId,
      selectedProgramsContext,
      isSupersetEnabled,
    } = this.props;
    const { serviceCaseOONProviders } = this.state;

    const caseState = _.get(serviceCase, 'state', '');
    const isOutcomeEmpty = _.isEmpty(serviceCase.outcome);
    if (caseState !== 'off_platform') {
      return null;
    }

    const editSection = (
      <div className="service-case-details__edit-section">
        <EditModal
          id="service-case-details__edit-modal"
          buttonId="service-case-details__edit-button"
          header="Edit Recipients"
          formName="editServiceCaseReferredToForm"
          labelText="Edit Recipients"
          size="normal"
        >
          <ServiceCaseReferredToForm
            ref={(el) => { this.editServiceCaseReferredTo = el; }}
            contact={contact}
            contactId={contact.id}
            serviceCaseOONProviders={serviceCaseOONProviders}
            groupId={groupId}
            serviceCase={serviceCase}
            invalidateCaseReferrals={invalidateCaseReferrals}
            selectedProgramsContext={selectedProgramsContext}
          />
        </EditModal>
      </div>
    );

    return (
      <>
        {!(isOutcomeEmpty && isSupersetEnabled) && (
        <div className="service-case-referred-to service-case-node">
          {editable && editSection}
        </div>
      )}
      </>
    );
  }
}

ServiceCaseReferredTo.propTypes = {
  serviceCase: PropTypes.shape({
    out_of_network_providers: PropTypes.array.isRequired,
    program: PropTypes.shape({
      referred_to: PropTypes.string,
      enrolled_at: PropTypes.number.isRequired,
      program: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    service_type: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    state: PropTypes.string.isRequired,
    outcome: PropTypes.object,
  }),
  contact: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  invalidateCaseReferrals: PropTypes.func.isRequired,
  selectedProgramsContext: PropTypes.shape({
    selectedPrograms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })).isRequired,
    dispatchRemoveAllPrograms: PropTypes.func.isRequired,
    dispatchAddProgram: PropTypes.func.isRequired,
    dispatchRemoveProgram: PropTypes.func.isRequired,
  }).isRequired,
  isSupersetEnabled: PropTypes.bool,
};

ServiceCaseReferredTo.defaultProps = {
  serviceCase: {},
  isSupersetEnabled: false,
};

const mapStateToProps = (state) => (
  {
    casesOONReferralUnlicensedOrgs: hasCasesOONReferralUnlicensedOrgs(state),
    isSupersetEnabled: uup459SupersetPhase2(state),
  }
);

export default connect(mapStateToProps)(ServiceCaseReferredTo);
