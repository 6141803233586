import Notifier from 'common/helpers/Notifier';
import { DELETE_CONTACT_ADDRESS_FIELD } from 'actions';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import fetchGroupContact from 'src/actions/Contact/Group/fetchGroupContact';
import { coreApi } from 'src/api/config';

const onError = (error) => {
  Notifier.handleErrors(error);
  return error;
};

const deleteContactAddressField = (groupId, contactId, itemId) => (
  async (dispatch) => {
    try {
      const response = await coreApi.deleteRecord('address', itemId);
      if (!response || !isHttpSuccess(response.status)) {
        return onError(response);
      }

      dispatch({ type: DELETE_CONTACT_ADDRESS_FIELD });

      Notifier.dispatch(response.status, 'Address Field Deleted');
      dispatch(fetchGroupContact(groupId, contactId));
      return response;
    } catch (error) {
      Notifier.handleErrors(error);
      return false;
    }
  }
);

export default deleteContactAddressField;
