import { compact } from 'lodash';
import {
  accept,
  close,
  holdForReview,
  recall,
  reject,
  send,
  sendDraft,
  changeHoldReason,
} from './referralActions';

function createActionOptions({
  goToReferralSend,
  user,
  groupId,
  isCC,
  onSubmit,
  openModal,
  caseReferrals,
  referral,
  closeInboundReferralsEnabled,
  crtb1239AdaptDraftReferralFlowEnabled,
  crtb1060ChangeReasonInReviewReferralEnabled,
}) {
  const actions = [
    holdForReview({
      referral,
      caseReferrals,
      user,
      groupId,
      openModal,
    }),
    send({
      user,
      groupId,
      referral,
      caseReferrals,
      goToReferralSend,
    }),
    accept({
      user,
      isCC,
      groupId,
      openModal,
      referral,
    }),
    reject({
      user,
      isCC,
      groupId,
      openModal,
      referral,
    }),
    // viewCase({ goToCase, groupId, referral }), // deprecate this
    sendDraft({
      caseReferrals,
      user,
      referral,
      groupId,
      onSubmit,
      crtb1239AdaptDraftReferralFlowEnabled,
    }),
    recall({
      groupId,
      openModal,
      referral,
      user,
    }),
    // close or archive
    close({
      user,
      referral,
      groupId,
      openModal,
      isCC,
      closeInboundReferralsEnabled,
    }),

  ];

  if (crtb1060ChangeReasonInReviewReferralEnabled) {
    actions.push(
      changeHoldReason({
        referral,
        caseReferrals,
        user,
        groupId,
        openModal,
      }),
    );
  }
  return actions;
}

async function actionOptions(params) {
  const options = await Promise.all(createActionOptions(params));
  return compact(options);
}

export default actionOptions;
