import PropTypes from 'prop-types';
import React from 'react';
import {
  Table,
  TableHeader,
  TableBody,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from '@unite-us/ui';
import { connect } from 'react-redux';
import moment from 'moment';
import TableRowLoading from 'common/display/Table/TableRowLoading';
import { hint542SupersetUnlistedPrograms as hint542SupersetUnlistedProgramsflag, crtb1234ShowProgramNameReferralHistory as crtb1234ShowProgramNameReferralHistoryFlag } from 'common/utils/FeatureFlags/flags';
import ReferralRowIcon from 'src/components/Dashboard/components/Referrals/ReferralRowIcon';
import { get, isEmpty, map } from 'lodash';
import { referralStatuses } from '../utils/referralStatusConstants';
import '../stylesheets/ReferralHistoryTable.scss';

const ReferralHistoryTable = ({
  referrals,
  isFetchingReferrals,
  isClosedByCoordinationCenter,
  hint542SupersetUnlistedPrograms,
  crtb1234ShowProgramNameReferralHistory,
}) => {
  const NoReferralsView = () => (
    <TableRow
      hoverable={false}
      clickable={false}
    >
      <div className="no-referrals">
        <p className="no-content">
          No referrals
        </p>
      </div>
    </TableRow>
  );

  const data = isEmpty(referrals) ? NoReferralsView() : (
    map(referrals, (referralObj) => (
      <TableRow
        className="referral-table-row"
        key={`table-row-${referralObj.id}`}
        hoverable={false}
        clickable={false}
        onCellClick={null}
      >
        <TableRowColumn className="is-grouped">
          <ReferralRowIcon referral={referralObj} />
        </TableRowColumn>
        <TableRowColumn>
          {get(referralObj, 'sending_provider.name')}
        </TableRowColumn>
        <TableRowColumn>
          { (crtb1234ShowProgramNameReferralHistory &&
              get(referralObj, 'receiving_program.name') &&
              get(referralObj, 'state') === 'off_platform') ? (
          `${get(referralObj, 'receiving_program.name') } | ${ get(referralObj, 'receiving_provider.name')}`
        ) : (
          get(referralObj, 'receiving_provider.name')
        )}
        </TableRowColumn>
        <TableRowColumn>
          {referralObj.state !== 'draft' ? moment.unix(get(referralObj, 'created_at')).format('MMMM DD, YYYY') : ''}
        </TableRowColumn>
        <TableRowColumn>
          {isClosedByCoordinationCenter ?
            referralStatuses.reviewed({ showText: true }) :
            referralStatuses[referralObj.state]({ hint542SupersetUnlistedPrograms, showText: true })}
        </TableRowColumn>
      </TableRow>
    ))
  );
  return (
    <Table
      noBorder
      className="overflow-visible"
    >
      <TableHeader className="referral-history-header">
        <TableRow>
          <TableHeaderColumn />
          <TableHeaderColumn>
            Sender
          </TableHeaderColumn>
          <TableHeaderColumn>
            Recipient
          </TableHeaderColumn>
          <TableHeaderColumn>
            Sent Date
          </TableHeaderColumn>
          <TableHeaderColumn>
            Status
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isFetchingReferrals ? <TableRowLoading /> : data}
      </TableBody>
    </Table>
  );
};

ReferralHistoryTable.propTypes = {
  referrals: PropTypes.array,
  isFetchingReferrals: PropTypes.bool,
  isClosedByCoordinationCenter: PropTypes.bool.isRequired,
  hint542SupersetUnlistedPrograms: PropTypes.bool.isRequired,
  crtb1234ShowProgramNameReferralHistory: PropTypes.bool.isRequired,
};
ReferralHistoryTable.defaultProps = {
  referrals: [],
  isFetchingReferrals: false,
};

function mapStateToProps(state) {
  return {
    hint542SupersetUnlistedPrograms: hint542SupersetUnlistedProgramsflag(state),
    crtb1234ShowProgramNameReferralHistory: crtb1234ShowProgramNameReferralHistoryFlag(state),
  };
}

export default connect(mapStateToProps)(ReferralHistoryTable);
