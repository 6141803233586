import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@headlessui/react';
import cx from 'classnames';

export const Toggle = ({
  checked,
  value,
  onChange,
  label,
  children,
  labelClass,
  toggleClass,
}) => (
  <Switch.Group>
    {label && (
      <Switch.Label
        className={cx(
          'normal-case text-sm text-text-blue font-regular-font tracking-normal',
          labelClass,
        )}
      >
        {label}
      </Switch.Label>
    )}
    <div className={cx('flex items-center', toggleClass)}>
      <Switch
        checked={checked}
        value={value}
        onChange={onChange}
        className={`${value ? 'bg-action-blue' : 'bg-white border-dark-grey'}
          relative inline-flex h-4 w-6 items-center rounded-lg border-solid border-2 focus:outline-none`}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`${value ? 'translate-x-2 border-current-blue' : '-translate-x-1 border-grey'}
                inline-block h-4 w-4 transform rounded-full bg-white border-solid border-2`}
        />
      </Switch>
      {children}
    </div>
  </Switch.Group>
);

Toggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  children: PropTypes.node,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  toggleClass: PropTypes.string,
  value: PropTypes.bool.isRequired,
};

Toggle.defaultProps = {
  children: null,
  label: '',
  labelClass: '',
  toggleClass: '',
};

export default Toggle;
