import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AddressField from 'common/form/AddressField/AddressField';
import { validateReduxForm } from 'common/form';
import {
  Button,
} from '@unite-us/ui';
import _ from 'lodash';
import { generateUUID } from 'common/utils/utils';
import { OverlaySpinner } from 'common/spinners';
import callOrLog from 'src/common/utils/callOrLog';
import { FACESHEET } from 'common/utils/EventTracker/utils/eventConstants';
import { EDIT_ADDRESS_FIELDS } from 'common/display/Profile/constants/form';
import { isAddressValid } from 'common/form/Profile/utils';
import addressIsUnchanged from './utils/addressIsUnchanged';
import newAddressFromFields from './utils/newAddressFromFields';
import './stylesheets/editAddress.scss';

export class EditAddressFields extends Component {
  static removeField(addresses, idToRemove) {
    const index = _.findIndex(addresses, (a) => _.get(a, 'id.value') === idToRemove);
    addresses.removeField(index);
  }

  constructor(props) {
    super(props);

    this.state = {
      addressesValidation: {},
    };

    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.addField = this.addField.bind(this);
    this.removeOrDelete = this.removeOrDelete.bind(this);
    this.onPrimaryChange = this.onPrimaryChange.bind(this);
    this.removeUnsavedAddresses = this.removeUnsavedAddresses.bind(this);
    this.isValidAddress = this.isValidAddress.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.modalIsOpen && !nextProps.modalIsOpen) {
      this.removeUnsavedAddresses();
      this.setState({ addressesValidation: {} });
    }
  }

  onCancel() {
    this.resetAndCloseModal();
  }

  async onSubmit() {
    const addresses = _.get(this.props, 'fields.addresses', []);

    // Validations
    await Promise.all(addresses.map((a, i) => this.isValidAddress(newAddressFromFields(a), i)));
    if (this.hasInvalidAddress) throw new Error();

    const promises = addresses.map((addressField = {}) => {
      const id = _.get(addressField, 'id.value', '');

      if (addressIsUnchanged(addressField)) {
        return { data: { data: { id } } };
      }

      const newAddress = newAddressFromFields(addressField);
      const existingAddressIds = _.get(this.props, 'addresses', []).map((address) => address.id);

      const isNew = (addressId) => existingAddressIds.indexOf(addressId) < 0;

      return this.props.updateOrCreateAddress(newAddress, isNew(id));
    });

    return Promise.all(promises)
      .then(() => {
        callOrLog(() => this.context.eventTracker(FACESHEET.addressUpdated));

        this.props.closeModal();
        this.props.resetForm();
      });
  }

  onPrimaryChange(address) {
    _.each(this.props.fields.addresses, (field) => {
      if (field !== address) {
        field.is_primary.onChange(false);
      }
    });
    address.is_primary.onChange(!address.is_primary.value);
  }

  get hasInvalidAddress() {
    const { addressesValidation } = this.state;
    return Object.values(addressesValidation).some((a) => a === false);
  }

  addField() {
    const { fields } = this.props;
    const id = generateUUID();
    fields.addresses.addField({ id, is_primary: false });
  }

  removeOrDelete(idToRemove) {
    const { fields, addresses } = this.props;
    EditAddressFields.removeField(fields.addresses, idToRemove);

    const addressIds = addresses.map((a) => a.id);
    if (_.includes(addressIds, idToRemove)) {
      this.props.deleteAddress(idToRemove);
    }
  }

  removeUnsavedAddresses() {
    const { fields } = this.props;
    _.eachRight(fields.addresses, (address, i) => {
      if (!address.id.initialValue) {
        fields.addresses.removeField(i);
      }
    });
  }

  resetAndCloseModal() {
    this.removeUnsavedAddresses();
    this.props.resetForm();
    this.props.closeModal();
  }

  async isValidAddress(location, index) {
    const valid = await isAddressValid(location);
    this.setState({
      addressesValidation: {
        ...this.state.addressesValidation,
        [index]: valid,
      },
    });
  }

  clearValidation(index) {
    this.setState({
      addressesValidation: {
        ...this.state.addressesValidation,
        [index]: true,
      },
    });
  }

  render() {
    const {
      addressTypes,
      fields: { addresses },
      handleSubmit,
      registerField,
      styles,
      submitting,
      modalIsOpen,
    } = this.props;
    const { addressesValidation } = this.state;

    const addressFormFields = addresses.map((address, index) => (
      <div
        id={`address-${index}`}
        key={address.id.initialValue || index}
        style={styles.inlineAddressContainer}
      >
        <AddressField
          id={address.id.initialValue}
          field={address}
          forceRequired
          index={index}
          inline={false}
          remove={() => this.removeOrDelete(address.id.initialValue)}
          registerField={registerField}
          addressTypes={addressTypes}
          isValidAddress={addressesValidation[index]}
          modalIsOpen={modalIsOpen}
          clearValidation={() => this.clearValidation(index)}
          onPrimaryChange={this.onPrimaryChange}
        />
      </div>
    ));

    return (
      <form className="edit-address content-with-actions">
        <OverlaySpinner text="Saving Addresses..." show={submitting} />

        <div className="content-container">
          {addressFormFields}
        </div>

        <div className="actions">
          <div className="row">
            <div className="text-left col-sm-6 edit-address__add-address">
              <button
                className="text-sm"
                id="add-address-link"
                type="button"
                onClick={this.addField}
              >
                + Add Address
              </button>
            </div>

            <div className="col-sm-6">
              <span className="action-item">
                <Button
                  id="edit-address-cancel-btn"
                  onClick={this.onCancel}
                  disabled={submitting}
                  label="Cancel"
                />
              </span>
              <span className="action-item">
                <Button
                  id="edit-address-save-btn"
                  onClick={handleSubmit(this.onSubmit)}
                  disabled={submitting}
                  label="Save"
                  primary
                />
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

EditAddressFields.propTypes = {
  addresses: PropTypes.array.isRequired,
  addressTypes: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  deleteAddress: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  registerField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    inlineAddressContainer: PropTypes.object.isRequired,
  }),
  submitting: PropTypes.bool.isRequired,
  updateOrCreateAddress: PropTypes.func.isRequired,
};

EditAddressFields.defaultProps = {
  styles: {
    inlineAddressContainer: {
      borderBottom: '1px solid #EBECED',
      marginBottom: '20px',
      paddingBottom: '10px',
    },
  },
};

EditAddressFields.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { addresses } = ownProps;
  const groupId = _.get(state, 'session.groupId');
  return {
    groupId,
    initialValues: { addresses },
  };
}

const fields = [
  'addresses[].id',
  'addresses[].line_1',
  'addresses[].line_2',
  'addresses[].city',
  'addresses[].state',
  'addresses[].postal_code',
  'addresses[].address_type',
  'addresses[].country',
  'addresses[].is_mailing_address',
  'addresses[].is_primary',
];

// accessibility improvement: focus on first field with error or first field when invalid address
const onSubmitFail = () => {
  setTimeout(() => {
    let focusableErr;
    const invalidAddress = _.first(document.getElementsByClassName('address-field__error'));

    if (invalidAddress) {
      focusableErr = invalidAddress;
    } else {
      const errs = document.getElementsByClassName('ui-form-field--has-error');
      const err = _.first(errs);
      focusableErr = _.first(err?.getElementsByClassName('choices')) || _.first(err?.getElementsByTagName('input'));
    }

    return focusableErr?.focus();
  }, 300);
};

export default validateReduxForm(
  {
    onSubmitFail,
    form: EDIT_ADDRESS_FIELDS,
    fields,
  },
  mapStateToProps,
  null,
)(EditAddressFields);
