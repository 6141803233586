import { apiV4Default } from 'src/api/config';
import {
  DELETE_INSURANCE_ENTRY_ERROR,
  DELETE_INSURANCE_ENTRY_STARTED,
  DELETE_INSURANCE_ENTRY_SUCCESS,
} from 'actions';
import Notifier from 'common/helpers/Notifier';

const onSuccess = (dispatch, payload, showNotification = true) => {
  dispatch({
    type: DELETE_INSURANCE_ENTRY_SUCCESS,
    payload,
  });

  if (showNotification) {
    Notifier.dispatch('success', 'Insurance Entry Successfully Deleted');
  }

  return payload;
};

function onError(dispatch, error) {
  dispatch({ type: DELETE_INSURANCE_ENTRY_ERROR });
  Notifier.dispatch('error', 'Error deleting insurance for client. Please try again');
  return error;
}

export default function deleteInsuranceEntry(insuranceId, contactId, groupId, showNotification) {
  return (dispatch) => {
    dispatch({ type: DELETE_INSURANCE_ENTRY_STARTED });

    const params = {
      id: insuranceId,
      group_id: groupId,
      data: {
        attributes: {
          state: 'archived',
        },
        relationships: {
          person: {
            data: {
              id: contactId,
              type: 'person',
            },
          },
        },
      },
    };

    const request = apiV4Default.patch(`/insurances/${insuranceId}`, params);

    return request.then(
      () => onSuccess(dispatch, { contactId, insuranceId }, showNotification),
      (error) => (onError(dispatch, error)),
    );
  };
}
