import { useQuery, useQueryClient } from 'react-query';

export const usePendingNotificationCount = () => {
  const queryClient = useQueryClient();

  const { data: pendingNotifications } = useQuery(
    'pendingNotifications',
    () => ({ task: 0, export: 0 }),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      initialData: { task: 0, export: 0 },
    },
  );

  const pendingNotificationCount = pendingNotifications.task + pendingNotifications.export;

  const incrementPendingNotificationCount = (type, amount = 1) => {
    queryClient.setQueryData('pendingNotifications', (oldCounts) => ({
      ...oldCounts,
      [type]: oldCounts[type] + amount,
    }));
  };

  const decrementPendingNotificationCount = (type, amount = 1) => {
    queryClient.setQueryData('pendingNotifications', (oldCounts) => ({
      ...oldCounts,
      [type]: Math.max(0, oldCounts[type] - amount),
    }));
  };

  return { pendingNotificationCount, incrementPendingNotificationCount, decrementPendingNotificationCount };
};
