import { useState } from 'react';
import { get, isEmpty } from 'lodash';
import { relationships } from 'src/reducers/constants/defaultEnums';
import {
  useCreateRecord,
  useUpdateRecord,
  useDeleteRecord,
  useFind,
  useInvalidateQueries,
  usePopulate,
} from 'src/api/APIHooks';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { defaultMutationConfig } from './apiHookOptions';

const useCreatePerson = () => {
  const invalidateQueries = useInvalidateQueries();
  return useCreateRecord('person', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('person');
      },
      ...defaultMutationConfig,
    },
  });
};

const useCreatePersonalRelationship = () => {
  const invalidateQueries = useInvalidateQueries();
  return useCreateRecord('personal_relationship', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('personal_relationship');
      },
      ...defaultMutationConfig,
    },
  });
};

export const getDefaultFamilyMember = () => relationships.find((r) => r.default === true).value;

export const useCreateMember = ({
  personId,
  familyMember = false,
  careTeamMember = false,
}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const { createRecord: createPerson } = useCreatePerson();
  const { createRecord: createPersonalRelationship } =
    useCreatePersonalRelationship();
  const submit = async (values) => {
    setIsSuccess(false);
    setIsError(false);
    const responsePerson = await createPerson({
      ...values,
    });
    if (!responsePerson || !isHttpSuccess(responsePerson.status)) {
      setIsError(true);
      return responsePerson;
    }
    const familyMemberPerson = responsePerson.data.data;
    const responsePersonalRelationship = await createPersonalRelationship({
      person: personId,
      related_person: familyMemberPerson.id,
      relationship: values.relation_to_contact ?? getDefaultFamilyMember(),
      care_team_member: careTeamMember,
      family_member: familyMember,
    });
    if (
      !responsePersonalRelationship ||
      !isHttpSuccess(responsePersonalRelationship.status)
    ) {
      setIsError(true);
      return responsePersonalRelationship;
    }
    setIsSuccess(true);
    return responsePersonalRelationship;
  };
  return { submit, isSuccess, isError };
};

export const useUpdatePerson = () => {
  const invalidateQueries = useInvalidateQueries();
  return useUpdateRecord('person', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('person');
      },
      ...defaultMutationConfig,
    },
  });
};

export const useUpdatePersonalRelationship = () => {
  const invalidateQueries = useInvalidateQueries();
  return useUpdateRecord('personal_relationship', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('personal_relationship');
      },
      ...defaultMutationConfig,
    },
  });
};

export const useFetchPersonalRelationships = ({
  personId,
  familyMember = false,
  careTeamMember = false,
  params = { page: 1, per: 20 },
}) => {
  if (isEmpty(personId)) {
    return { data: [], isFetching: false };
  }
  const searchAttributes = {
    family_member: familyMember,
    care_team_member: careTeamMember,
    person: personId,
  };
  const {
    isFetching,
    data: rawResponse,
    isError,
  } = useFind('personal_relationship', searchAttributes, {
    page: {
      number: params.page,
      size: params.per,
    },
    include: 'related_person',
  });
  const rawData = get(rawResponse, 'data.data', []);
  const { isFetching: isFetchingAddresses, isError: isAddressError } =
    usePopulate('related_person.addresses', 'address', rawData, {
      oneByOne: true,
    });
  return {
    data: rawData,
    isFetching: isFetching || isFetchingAddresses,
    isError: isError || isAddressError,
  };
};

export const useDeletePersonalRelationship = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useDeleteRecord('personal_relationship', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('personal_relationship');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};
