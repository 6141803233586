import { validations } from '@unite-us/app-components';
import { CONSENT_OPTIONS } from 'src/components/InformedConsent/constants';
import { compact } from 'lodash';

function validateEmailAddress(value, message, args, values) {
  return values[CONSENT_OPTIONS.SELECTED_CONSENT_TYPE] === CONSENT_OPTIONS.EMAIL_ADDRESS ?
    compact([
      validations.isRequired(value),
      validations.isEmail(value),
    ]).join(', ') :
    null;
}

function validatePhoneNumber(value, message, args, values) {
  return values[CONSENT_OPTIONS.SELECTED_CONSENT_TYPE] === CONSENT_OPTIONS.PHONE_NUMBER ?
    compact([
      validations.isRequired(value),
      validations.isPhoneNumber(value),
    ]).join(', ') :
    null;
}

export {
  validateEmailAddress,
  validatePhoneNumber,
};
