import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'common/utils/browserHistory';
import {
  Tabs,
  Tab,
} from '@unite-us/ui';
import CustomDateRangeSelect from 'src/components/Reports/components/CustomDateRangeSelect';
import dateFilterText from 'src/components/Reports/utils/dateFilterText';
import findCurrentGroup from 'common/utils/findCurrentGroup';
import _ from 'lodash';
import { hasInsightsViewInsights } from 'common/utils/FeatureFlags/flags';
import { getEmployeeNetworks } from 'src/components/Employee/employeeGetters';
import '../Report.scss';

export class GroupReports extends Component {
  constructor(props) {
    super(props);
    this.onTabChange = this.onTabChange.bind(this);
    this.onDateRangeFilterChange = this.updateFilters.bind(this, 'dateRange');
    this.getActiveTab = this.getActiveTab.bind(this);
    this.getTabStyle = this.getTabStyle.bind(this);

    this.state = {
      filters: {
        dateRange: {
          start_date: null,
          end_date: null,
        },
      },
    };
  }

  onTabChange(value) {
    browserHistory.push(`/reports/group/${this.props.groupId}/${value}`);
  }

  getActiveTab() {
    return _.last(_.split(_.last(this.props.routes).path, '/'));
  }

  getTabStyle(value) {
    return value === this.getActiveTab() ?
      this.props.styles.activeTab :
      this.props.styles.tab;
  }

  updateFilters(filter, value) {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [filter]: value,
      },
    });
  }

  render() {
    const {
      children,
      groupId,
      hasInsights,
      insightsRedirectPath,
      styles,
      group,
      serviceTypes,
    } = this.props;

    if (hasInsights) {
      browserHistory.push(insightsRedirectPath);
      return null;
    }

    const { filters } = this.state;

    const childrenWithFunc = React.Children.map(children, (c) => React.cloneElement(c, {
      groupId,
      serviceTypes,
      filters: { ...filters.dateRange },
    }));

    return (
      <div className="reports">
        <div
          id="subheader-container"
          className="reports__subheader-container tab-header container-fluid"
          style={styles.header}
        >
          <div className="row">
            <div className="col-sm-7">
              <h2 style={styles.groupName}>{group.group.name}</h2>
            </div>
            <div className="col-sm-5" style={styles.dateFieldText}>
              {dateFilterText(filters.dateRange)}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8">
              <Tabs
                id="group-report-tabs"
                className="group-report-tabs"
                onChange={this.onTabChange}
                value={this.getActiveTab()}
                style={styles.tabs}
              >
                <Tab
                  label="Population"
                  value="population"
                  style={this.getTabStyle('population')}
                />
                <Tab
                  label="Services"
                  value="services"
                  style={this.getTabStyle('services')}
                />
                <Tab
                  label="Military"
                  value="military"
                  style={this.getTabStyle('military')}
                />
              </Tabs>
            </div>
            <div className="col-sm-4" style={styles.dateField}>
              <div className="pull-right">
                <CustomDateRangeSelect
                  onDateRangeChange={this.onDateRangeFilterChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid reports__content">
          {childrenWithFunc}
        </div>
      </div>
    );
  }
}

GroupReports.propTypes = {
  children: PropTypes.node.isRequired,
  group: PropTypes.shape({
    group: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  hasInsights: PropTypes.bool.isRequired,
  insightsRedirectPath: PropTypes.string,
  networks: PropTypes.array.isRequired,
  routes: PropTypes.array.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  styles: PropTypes.shape({
    activeTab: PropTypes.object,
    dateField: PropTypes.object,
    dateFieldText: PropTypes.object,
    header: PropTypes.object,
    inkBar: PropTypes.object,
    groupName: PropTypes.object,
    tab: PropTypes.object,
    tabs: PropTypes.object,
  }).isRequired,
};

GroupReports.defaultProps = {
  group: {},
  serviceTypes: [],
  styles: {
    activeTab: {
      height: '60px',
      textTransform: 'none',
      fontWeight: '600',
      color: '#58606E',
      fontSize: '16px',
    },
    header: {
      marginLeft: '-15px',
      marginRight: '-15px',
      paddingLeft: '30px',
      paddingRight: '30px',
    },
    groupName: { margin: '0px', padding: '16px 0px 10px' },
    tab: {
      height: '60px',
      textTransform: 'none',
      fontWeight: '400',
      color: '#58606E',
    },
    tabs: { maxWidth: '550px' },
    dateFieldText: {
      margin: '30px 0 5px 0',
      textAlign: 'right',
    },
    dateField: {
      margin: '10px 0 5px 0',
    },
  },
};

function mapStateToProps(state, ownProps) {
  const groupId = _.get(ownProps, 'params.id', '');
  const group = findCurrentGroup(state.user, state.session);
  const serviceTypes = _.get(state, 'session.globals.service_types', []);
  const hasInsights = hasInsightsViewInsights(state);
  const insightsRedirectPath = '/insights';

  return {
    groupId,
    group,
    hasInsights,
    insightsRedirectPath,
    networks: getEmployeeNetworks({ state }),
    serviceTypes,
  };
}

export default connect(mapStateToProps)(GroupReports);
