import React, { Component } from 'react';
import { ScreeningDetailHOC } from 'pages/components';

class FacesheetScreeningDetail extends Component {
  componentDidMount() {
    document.getElementById('facesheet-container').scrollIntoView();
  }

  render() {
    return (
      <div className="facesheet-screening-detail">
        <ScreeningDetailHOC {...this.props} />
      </div>
    );
  }
}

export default FacesheetScreeningDetail;
