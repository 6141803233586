import React, { useRef } from 'react';
import parse from 'html-react-parser';
import { PropTypes } from 'prop-types';
import { get } from 'lodash';
import { PrimaryButton } from 'common/TailwindComponents';
import DialogV2 from 'common/modal/DialogV2';
import { ENROLLMENT_STATUSES, REQUEST_TYPES } from '../constants';
import { useAcceptEnrollmentRequest } from '../hooks';

const AcceptEnrollmentRequestButton = ({
  enrollmentRequest,
  fullViewPage,
  setDrawerOpen,
}) => {
  const isEnrollType = enrollmentRequest.request_type === REQUEST_TYPES.enroll;
  const isOpenStatus = enrollmentRequest.enrollment_status === ENROLLMENT_STATUSES.open;
  const acceptModalRef = useRef();
  const acceptEnrollmentRequest = useAcceptEnrollmentRequest();

  const closeAcceptEnrollmentModal = () => {
    acceptModalRef.current.closeDialog();
  };

  const openAcceptEnrollmentModal = () => {
    acceptModalRef.current.openDialog();
  };

  const acceptEnrollment = async () => {
    await acceptEnrollmentRequest(enrollmentRequest);
    if (!fullViewPage) setDrawerOpen(false);
    closeAcceptEnrollmentModal();
  };

  return (
    <>
      <PrimaryButton
        disabled={!isOpenStatus}
        label="Accept"
        ariaLabel="accept enrollment request"
        onClick={openAcceptEnrollmentModal}
        primary
        icon="IconCheckCircle"
      />
      <DialogV2
        ref={acceptModalRef}
        title={isEnrollType ? (`Confirm Enrollment Request #${enrollmentRequest.short_id}`) :
          (`Confirm Disenrollment Request #${enrollmentRequest.short_id}`)}
        confirmLabel="Confirm"
        cancelHandler={closeAcceptEnrollmentModal}
        confirmationHandler={acceptEnrollment}
      >
        <p className="pb-4">
          Accepting this request will {isEnrollType ? parse('<b>enroll</b> the client to ') :
            parse('<b>disenroll</b> the client from ')}
          <b>{get(enrollmentRequest, 'plan.name')}</b>.
        </p>
        <p>&nbsp;</p>
      </DialogV2>
    </>
  );
};

AcceptEnrollmentRequestButton.propTypes = {
  enrollmentRequest: PropTypes.object.isRequired,
  fullViewPage: PropTypes.bool,
  setDrawerOpen: PropTypes.func,
};

AcceptEnrollmentRequestButton.defaultProps = {
  fullViewPage: false,
  setDrawerOpen: () => {},
};

export default AcceptEnrollmentRequestButton;
