import { useFindRecord } from 'src/api/APIHooks';
import { get } from 'lodash';

const useFeeScheduleProgram = (id) => {
  const { isFetching, data } = useFindRecord(
    'fee_schedule_program',
    id,
    {
      queryConfig: {
        enabled: !!id,
        staleTime: Infinity,
      },
    },
  );

  return isFetching ? {} : get(data, 'data.data', {});
};

export default useFeeScheduleProgram;
