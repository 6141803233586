import { orderBy } from 'lodash';

function primaryFirstSort({
  arr = [],
  sortByProperty,
  primaryProperty = 'is_primary',
}) {
  const ordered = orderBy(arr, sortByProperty);
  const primary = [];
  const nonPrimary = ordered.reduce((acc, curr = {}) => {
    if (curr[primaryProperty]) {
      primary.push(curr);
      return acc;
    }
    return [...acc, curr];
  }, []);

  return [
    ...primary,
    ...nonPrimary,
  ];
}

export default primaryFirstSort;
