import PropTypes from 'prop-types';
import React from 'react';
import { ServiceTypeSelectField } from '@unite-us/client-utils';
import { getServiceTypeOptions } from 'src/components/Cases/utils';

const CaseServiceTypeField = ({
  defaultProgramSelected,
  fields,
  programsServiceTypes,
  user,
  ...props
}) => {
  const disableServiceTypes = !fields.program.value ||
    (fields.case_type.value.type !== 'org_case' && !fields.network.value);
  const serviceTypes = getServiceTypeOptions({
    defaultProgramSelected, fields, user, programsServiceTypes,
  });

  return (
    <ServiceTypeSelectField
      disabled={disableServiceTypes}
      options={serviceTypes}
      {...props}
    />
  );
};

CaseServiceTypeField.propTypes = {
  defaultProgramSelected: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  programsServiceTypes: PropTypes.object,
  registerField: PropTypes.func.isRequired,
  user: PropTypes.shape({
    networks: PropTypes.array.isRequired,
  }).isRequired,
};

CaseServiceTypeField.defaultProps = {
  id: 'service-type',
  placeholder: 'Choose one...',
  required: true,
  searchChoices: false,
  searchPlaceholderValue: 'Search for the service your client needs',
};

export default CaseServiceTypeField;
