import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { noop } from 'lodash';
import EditModal from 'common/modal/EditModal';
import { EditEmailAddresses } from 'common/form/Profile';
import { primaryFirstEmailSort } from 'common/form/Profile/EditEmailAddresses/utils';
import Email from 'common/display/Email';
import { RowDisplay } from 'common/display/Profile';
import { EDIT_EMAIL_ADDRESSES } from 'common/display/Profile/constants/form';

export class EmailAddresses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailDisplay: [],
    };

    this.setEmailDisplay = this.setEmailDisplay.bind(this);
  }

  componentDidMount() {
    this.setEmailDisplay(this.props.emails);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.emails !== nextProps.emails) {
      this.setEmailDisplay(nextProps.emails);
    }
  }

  setEmailDisplay(emails = []) {
    const emailDisplay = primaryFirstEmailSort(emails).map((email, index) => (
      <div className="col-sm-6 mb-two" key={`${email.id}-${index}`}>
        <Email item={email} />
      </div>
    ));
    this.setState({ emailDisplay });
  }

  render() {
    const {
      updateGroup,
      emails,
      groupId,
      hideCommunicationPreferences,
      onSave,
    } = this.props;

    const editSection = (
      <EditModal
        header="Edit Email"
        formName={EDIT_EMAIL_ADDRESSES}
        buttonId="edit-email-btn"
        id="edit-email-modal"
      >
        <EditEmailAddresses
          updateGroup={updateGroup}
          emails={emails}
          groupId={groupId}
          hideCommunicationPreferences={hideCommunicationPreferences}
          onSave={onSave}
        />
      </EditModal>
    );

    return (
      <RowDisplay
        className="email-addresses"
        editSection={editSection}
        label="Email"
      >
        {this.state.emailDisplay}
      </RowDisplay>
    );
  }
}

EmailAddresses.propTypes = {
  updateGroup: PropTypes.func.isRequired,
  emails: PropTypes.array,
  groupId: PropTypes.string.isRequired,
  hideCommunicationPreferences: PropTypes.bool,
  onSave: PropTypes.func,
};

EmailAddresses.defaultProps = {
  emails: [],
  hideCommunicationPreferences: false,
  onSave: noop,
};

export default EmailAddresses;
