import { isOONCaseUser } from 'src/components/User/utils';
import {
  servicesValidForOONCaseContext,
} from 'src/components/Referrals/ReferralServicesForm/utils/oonCase';

const hasValidCaseContext = (props, nextProps, isForwarding = false, acceptSelectedGroup = false) => {
  const { groupId, user } = props;
  const userCanCreateOONCases = isOONCaseUser(user, groupId);

  return (nextProps.isCC || userCanCreateOONCases) &&
    servicesValidForOONCaseContext(props, nextProps, isForwarding, acceptSelectedGroup);
};

export default hasValidCaseContext;
