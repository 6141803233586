import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { Spinner } from 'src/common/spinners';
import { useUpdateRecord } from 'src/api/APIHooks';
import { theme } from 'src/../tailwind.config';
import { SelectField } from 'components/Backoffice/form/SelectField';
import Notifier from 'common/helpers/Notifier';
import { useFindProgramServiceAreas } from 'src/components/Organization/api/hooks/v1';
import { BaseCard, BaseCardBody, Button, Dialog, Icon } from '@unite-us/ui';
import { ProgramDetailsV2 } from '@unite-us/client-utils';
import addDisplayNameToServiceAreas from '../../../utils/addDisplayNameToServiceAreas';

export const ProgramDetail = ({
  program,
  readOnly,
  serviceAreaSupportForOrgsFlag,
  onRefreshPrograms,
  isDraft,
}) => {
  const deactivateProgramModal = useRef();
  const [reason, setReason] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const deactivationReasons = [
    { label: 'Permanently Closed', value: 'Permanently Closed' },
    { label: 'Duplicate', value: 'Duplicate' },
    { label: 'Other', value: 'Other' },
  ];

  const prettyErrorArray = [
    {
      error: 'is_active cannot be set to false with active employees',
      displayError: 'This program has active employees assigned to it and cannot be deactivated.',
    },
    {
      error: 'cannot be set to false with open cases',
      displayError: 'This program has open cases and cannot be deactivated.',
    },
  ];

  const deactivateModalHandlerOpen = useCallback((event) => {
    event.preventDefault();
    deactivateProgramModal.current.openDialog();
  }, []);

  const deactivateModalHandlerClose = useCallback(() => {
    setReason('');
    deactivateProgramModal.current.closeDialog();
  }, []);

  useEffect(() => {
    if (submitted) {
      setSpinner(false);
      setSubmitted(false);
      deactivateModalHandlerClose();
    }
  }, [submitted]);

  const { updateRecord: deactivateProgram } = useUpdateRecord('program', {
    mutationConfig: {
      onError: (error) => {
        const errors = error.response.data.errors;
        const prettyErrors = [];
        for (let i = 0; i < errors.length; i++) {
          const errLookupId = prettyErrorArray.findIndex((e) => e.error === errors[i].title);
          if (errLookupId === -1) {
            prettyErrors.push(errors[i].title);
          } else {
            prettyErrors.push(prettyErrorArray[errLookupId].displayError);
          }
        }
        Notifier.dispatch('error', prettyErrors.join(', '));
        setSubmitted(true);
      },
      onSuccess: () => {
        Notifier.dispatch(
          'success',
          `${program.name} was successfully deactivated.`,
          );
          setSubmitted(true);
          deactivateProgramModal.current.closeDialog();
          onRefreshPrograms(program.id);
        },
      },
    });

  const submitDeactivation = (() => {
    setSpinner(true);
    deactivateProgram(program.id, { is_active: false, status: reason });
  });

  const {
    data: apiProgramServiceAreas,
    isFetching: isFetchingProgramServiceAreas,
  } = useFindProgramServiceAreas({ programId: program.id });

  if (isFetchingProgramServiceAreas && serviceAreaSupportForOrgsFlag) {
    return (<Spinner center />);
  }

  const programServiceAreas = serviceAreaSupportForOrgsFlag ? addDisplayNameToServiceAreas(apiProgramServiceAreas) : [];
  const renderProgram = {
    ...program,
    service_areas: programServiceAreas,
  };

  const EditProgramLink = () => () => (
    <div className="float-right -mt-8">
      <Link
        aria-label={`Edit ${program.name}`}
        data-test-element="edit_program_link"
        to={`/organization/settings/programs/${program.id}/edit`}
        style={{ paddingRight: '15px' }}
      >
        <Icon className="-mt-1 pr-1" icon="IconPencil" color={theme.extend.colors['current-blue']} />Edit Program
      </Link>
      { (isDraft) ? ( // draft status must be present
        <Link
          aria-label={`Deactivate ${program.name}`}
          data-test-element="deactivate_program_button"
          onClick={deactivateModalHandlerOpen}
          role="button"
          to="/"
        >
          <Icon className="-mt-1 pr-1" icon="IconTrash" color={theme.extend.colors['current-blue']} />Deactivate Program
        </Link>
      ) : (<div />) }
    </div>
  );

  return (
    <BaseCard key={renderProgram.id} className="mb-4" style={{ margin: 0, padding: 0 }}>
      <BaseCardBody>
        <ProgramDetailsV2
          rightHeaderComponent={
            !readOnly && renderProgram.is_active ? EditProgramLink(program.id) : null
          }
          program={renderProgram}
          showProgramUsersInfo
        />
      </BaseCardBody>
      <Dialog
        ref={deactivateProgramModal}
        data-testid="deactivate-program-dialog"
        actions={(
          <div>
            <Button
              label="Cancel"
              onClick={deactivateModalHandlerClose}
              style={{ borderColor: theme.extend.colors['blue-border'] }}
            />
            <Button
              className="validation-red"
              label="Deactivate"
              primary
              onClick={submitDeactivation}
              style={{
                marginLeft: '12px',
                color: theme.extend.colors.white,
                background: theme.extend.colors['validation-red'],
              }}
              disabled={reason === ''}
            />
          </div>
        )}
        dialogContentStyles={{
          background: '#FAFBFC',
          padding: '0rem, 1.5rem, 0.5rem, 0rem',
        }}
        removeCloseIcon="true"
        showBorderRadius="true"
        spinnerShow={spinner}
        size="small"
        title="Deactivate Program"
        titleStyles={{
          padding: '1rem, 1.5rem, 0.5rem, 0rem',
          borderRadius: '4px',
          borderBottom: 'none',
          fontFamily: 'Proxima Nova',
          fontSize: '1.125rem',
          color: theme.extend.colors['dark-blue'],
          background: '#FAFBFC',
        }}
        footerStyles={{ borderTop: 'none' }}
      >
        <div className="bg-white-grey font-regular-font text-xs text-dark-blue">
          <div className="relative" style={{ paddingBottom: '1.5rem' }}>
            <SelectField
              listBoxUpOptionsClassName="outline-solid focus:outline-solid"
              label={
              (
                <span>Deactivation Reason
                  <sup
                    style={{ color: theme.extend.colors['validation-red'] }}
                  >*
                  </sup>
                </span>
              )
             }
              onChange={({ value }) => setReason(value)}
              options={deactivationReasons}
              placeholder="Select Reason..."
              value={reason ?? ''}
              labelKey="label"
              valueKey="value"
            />
          </div>
          <div>
            <div>
              If you deactivate this program:
              <ul className="list-disc">
                <li>It won&apos;t appear to you or any other Unite Us user </li>
                <li>You cannot restore it. Deactivation is permanent and cannot be undone.</li>
              </ul>
              <br />
            </div>
            <div>
              If this program isn&apos;t currently accepting referrals, consider updating the
              Program Status field instead of deactivating it.
            </div>
            <br />
            <div>
              Do you want to deactivate this program?
            </div>
          </div>
        </div>
      </Dialog>
    </BaseCard>
  );
};

ProgramDetail.propTypes = {
  program: PropTypes.object,
  isDraft: PropTypes.bool,
  readOnly: PropTypes.bool,
  serviceAreaSupportForOrgsFlag: PropTypes.bool,
  onRefreshPrograms: PropTypes.func,
};

ProgramDetail.defaultProps = {
  program: {},
  isDraft: false,
  readOnly: false,
  serviceAreaSupportForOrgsFlag: false,
  onRefreshPrograms: () => {},
};

export default ProgramDetail;
