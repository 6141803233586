import {
  useCreateRecord,
  useFind,
  useFindRecord,
  useUpdateRecord,
  useInvalidateQueries,
} from 'src/api/APIHooks';
import { defaultPagingQueryConfig, defaultQueryConfig, defaultMutationConfig } from '../apiHookOptions';

export const useFindDirectory = (id) => useFindRecord('directories', id, {
  queryConfig: defaultQueryConfig,
  api: 'prdAdminApi',
});

export const useFindDirectories = (options) => useFind(
  'directories',
  {},
  {
    queryConfig: {
      ...defaultPagingQueryConfig,
    },
    api: 'prdAdminApi',
    ...options,
  },
);

export const useCreateDirectory = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useCreateRecord('directories', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('directories');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
    api: 'prdAdminApi',
  });
};

export const useUpdateDirectory = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useUpdateRecord('directories', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('directories');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
    api: 'prdAdminApi',
  });
};
