import React from 'react';
import { ALL_AUTHORIZATION_PAGE_STATUSES } from 'common/Status/authorizationConstants';
import ServiceAuthorizationWorkqueue from './ServiceAuthorizationWorkqueue';

const AllServiceAuthorizations = () => (
  <ServiceAuthorizationWorkqueue
    authorizationStatus={ALL_AUTHORIZATION_PAGE_STATUSES}
    onlyConsentedPeople
  />
);

export default AllServiceAuthorizations;
