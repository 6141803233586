import React, { useState } from 'react';
import { useCreateRecord, useInvalidateQueries } from 'src/api/APIHooks';
import { browserHistory } from 'src/common/utils/browserHistory';
import {
  ColumnHeader,
  DataCell,
  DataRow,
  HeaderRow,
  Table,
  TableBody,
} from 'src/common/tables/V2';
import moment from 'moment';
import { Button, Icon } from '@unite-us/ui';
import { Spinner } from 'common/spinners';
import Pagination from 'src/common/Pagination/Pagination';
import ScrollToElement from 'src/common/utils/Form/ScrollToElement';
import useForms from 'src/common/hooks/useForms';
import Notifier from 'common/helpers/Notifier';
import SearchBar from 'src/common/SearchBar/SearchBar';
import { get } from 'lodash';
import { useFetchForm } from 'src/components/Backoffice/form/hooks/useFetchForm';
import { Menu } from '@headlessui/react';
import { DEFAULT_PAGE_SIZE } from 'src/components/Backoffice/backofficeUtils';

const Forms = () => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const { data: formsResponse, isFetching } = useForms(searchTerm, pageSize, pageNumber);
  const { data: forms, paging } = formsResponse.data;
  const [formId, setFormId] = useState('');
  const form_data = useFetchForm(formId);
  const invalidateQueries = useInvalidateQueries();
  const { createRecord } = useCreateRecord('form', {
    api: 'coreApi',
    mutationConfig: {
      onSuccess: (result) => {
        invalidateQueries('form');
        browserHistory.push(`/backoffice/forms/${result.data.data.id}/edit`);
      },
      onError: (error) => {
        const errorList = get(error, 'response.data.errors', []);
        const stringNotification = errorList.map((e) => e.title).join(', ');
        Notifier.dispatch(400, `Failed to create form: ${stringNotification}`);
      },
    },
  });

  const ScrollToElementEvent = () => {
    ScrollToElement(document.getElementById('TopOfForms'));
  };

  const SearchFormName = (data) => {
    setPageNumber(1);
    setSearchTerm(data?.search);
  };
  const handleCellClick = (formIdToDuplicate, e) => {
    e.stopPropagation();
    setFormId(formIdToDuplicate);
  };

  const duplicateForm = async () => {
    await createRecord({
      name: `Copy of ${form_data.name}`,
      state: 'published',
      description: form_data.description || 'Please change the description',
      configuration: { pages: form_data.configuration.pages },
    });
  };

  return (
    <>
      <div
        data-testid="forms-header"
        className="flex px-8 pt-8 pb-2 bg-medium-fill-blue"
        id="TopOfForms"
      >
        <div className="w-9/12 flex flex-row relative">
          <h2 className="pt-1 pb-2">Forms</h2>
          <SearchBar onSubmit={SearchFormName} dataTestId="forms-search-field" />
        </div>

        <div className="w-3/12 flex justify-end">
          <Button
            id="add-form-button"
            iconLeft={<Icon icon="V2Add" />}
            className="py-1 px-4 text-sm text-action-blue rounded border-none bg-transparent normal-case"
            label="Create New Form"
            onClick={() => {
              browserHistory.push('/backoffice/forms/new');
            }}
            data-testid="create-form-btn"
          />
        </div>
      </div>
      { isFetching ? <Spinner /> : (
        <div className="flex flex-col" data-testid="forms-table-container">
          <div className="overflow-x-auto">
            <div className="py-2 align-middle sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mb-5">
                <Table className="min-w-full divide-y divide-gray-200">
                  <HeaderRow className="bg-gray-100">
                    <ColumnHeader
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-50"
                    >
                      Name
                    </ColumnHeader>
                    <ColumnHeader
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-25"
                    >
                      Last Updated
                    </ColumnHeader>
                    <ColumnHeader
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-25"
                    />
                  </HeaderRow>
                  <TableBody>
                    {forms.map((form, i) => (
                      <DataRow
                        className={
                          `${i % 2 === 0 ? 'bg-white hover:bg-gray-300' : 'bg-gray-100 hover:bg-gray-300'} relative`
                        }
                        key={form.id}
                        onClick={() => {
                          browserHistory.push(`/backoffice/forms/${form.id}/edit`);
                        }}
                      >
                        <DataCell className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {form.name}
                        </DataCell>
                        <DataCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {moment(form.updated_at).format('LL')}
                        </DataCell>
                        <DataCell
                          className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right"
                        >
                          <Menu as="div">
                            <Menu.Button
                              className={'focus:outline-none text-black font-bold'}
                              onClick={(e) => handleCellClick(form.id, e)}
                            >
                              ...
                            </Menu.Button>
                            <Menu.Items
                              className={
                                `flex flex-col absolute border border-solid border-dark-border-blue bg-white
                                box-border w-32 z-modal right-4 focus:outline-none mt-2`
                              }
                            >
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    className={`${active && 'bg-dark-fill-blue'} w-full px-2 py-4`}
                                    type="button"
                                    onClick={duplicateForm}
                                  >
                                    Duplicate Form
                                  </button>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Menu>
                        </DataCell>
                      </DataRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <Pagination
                pageNumber={pageNumber}
                pageSize={pageSize}
                totalItemCount={paging.total_count}
                totalPageCount={paging.total_pages}
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                scrollPaginationElement={ScrollToElementEvent}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Forms.propTypes = {
};

Forms.defaultProps = {
};

export default Forms;
