import PropTypes from 'prop-types';
import React from 'react';
import { noop } from 'lodash';
import { BarLoader } from '@unite-us/ui';
import EditPhoneNumbers from 'common/form/Profile/EditPhoneNumbers/index';
import PhoneNumberDisplay from 'common/display/PhoneNumberDisplay';
import RowDisplay from 'common/display/Profile/RowDisplay';
import { EDIT_PHONE_NUMBERS } from 'common/display/Profile/constants/form';
import { primaryFirstPhoneSort } from 'common/form/Profile/EditPhoneNumbers/utils';
import EditModal from 'common/modal/EditModal';
import generatePhonesWithExtension from 'common/form/Profile/utils/generatePhonesWithExtension';
import './PhoneNumbers.scss';

const PhoneNumbers = ({
  groupId,
  hideCommunicationPreferences,
  phoneFor,
  phoneNumbers,
  phoneTypes,
  onSave,
  isFetching,
  withPhoneExtensionField,
}) => {
  const processedNumbers = withPhoneExtensionField ? generatePhonesWithExtension(phoneNumbers) : phoneNumbers;

  const phoneDisplay = () => primaryFirstPhoneSort(processedNumbers).map((phone) => (
    <div
      className="col-sm-6 phone-numbers__phone-number-display"
      key={`phoneDisplay-${phone.id}`}
    >
      <PhoneNumberDisplay item={phone} detailedExtension={withPhoneExtensionField} />
    </div>
  ));

  const editSection = () => (
    <EditModal
      header="Edit Phone Number"
      size="large"
      formName={EDIT_PHONE_NUMBERS}
      buttonId="edit-phone-number-btn"
      id="edit-phone-number-modal"
    >
      {
        isFetching ? (
          <div>
            <BarLoader className="mb-quarter" size="semi-full" />
            <BarLoader className="mb-quarter" size="half" />
            <BarLoader className="mb-quarter" size="semi-full" />
          </div>
        ) : (
          <EditPhoneNumbers
            groupId={groupId}
            hideCommunicationPreferences={hideCommunicationPreferences}
            numbers={processedNumbers}
            onSave={onSave}
            phoneFor={phoneFor}
            phoneTypes={phoneTypes}
            withPhoneExtensionField={withPhoneExtensionField}
          />
        )
      }
    </EditModal>
  );

  return (
    <RowDisplay
      className="phone-numbers"
      editSection={editSection()}
      label="Phone"
    >
      { phoneDisplay() }
    </RowDisplay>
  );
};

PhoneNumbers.propTypes = {
  groupId: PropTypes.string.isRequired,
  hideCommunicationPreferences: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  phoneFor: PropTypes.oneOf(['contact', 'group']),
  phoneNumbers: PropTypes.array,
  phoneTypes: PropTypes.array.isRequired,
  withPhoneExtensionField: PropTypes.bool,
};

PhoneNumbers.defaultProps = {
  phoneFor: 'contact',
  phoneNumbers: [],
  hideCommunicationPreferences: false,
  withPhoneExtensionField: false,
  onSave: noop,
};

export default PhoneNumbers;
