import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'src/common/Pagination/Pagination';
import scrollToElement from 'src/common/utils/Form/ScrollToElement';
import { useFind, usePopulate } from 'src/api/APIHooks';
import { Spinner } from 'common/spinners';
import EmptyTable from 'src/common/tables/EmptyTable/EmptyTable';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from '@unite-us/app-components';
import { tableWidth } from 'common/helpers/Tables';
import { DEFAULT_PAGE_SIZE } from 'src/components/Backoffice/backofficeUtils';

const FeeScheduleScreeningProgramsTableHeader = () => (
  <TableHeader>
    <TableRow>
      <TableHeaderColumn style={{ width: tableWidth('large') }}>
        PROGRAM NAME
      </TableHeaderColumn>
      <TableHeaderColumn style={{ width: tableWidth('small') }}>
        PROGRAM ID
      </TableHeaderColumn>
      <TableHeaderColumn style={{ width: tableWidth('small') }}>
        ORGANIZATION NAME
      </TableHeaderColumn>
    </TableRow>
  </TableHeader>
);

const FeeScheduleScreeningProgramsTable = ({ feeScheduleScreeningId }) => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const { data: programData, isLoading } = useFind(
    'program',
    { fee_schedule_screenings: feeScheduleScreeningId },
    {
      queryConfig: {
        placeholderData: undefined,
      },
      page: {
        size: pageSize,
        number: pageNumber,
      },
    },
  );

  const programs = programData?.data?.data || [];

  const { isLoading: isLoadingProviders } =
  usePopulate('provider', 'provider', programs, { queryConfig: { placeholderData: undefined } });

  if (isLoading || isLoadingProviders) return <Spinner />;

  const paging = programData?.data?.paging || {};
  const scrollToElementEvent = () => {
    scrollToElement(document.getElementById('TopOfForms'));
  };

  return (
    <>
      <Table id="fee-schedule-screenings-programs-table">
        <FeeScheduleScreeningProgramsTableHeader />
        <TableBody>
          {programs.map((program, idx) => (
            <TableRow
              dataTestId={`fee-schedule-screening-program-row-${idx}`}
              key={program.id}
            >
              <TableRowColumn style={{ width: tableWidth('large') }}>
                {program.name}
              </TableRowColumn>
              <TableRowColumn style={{ width: tableWidth('small') }}>
                {program.id}
              </TableRowColumn>
              <TableRowColumn style={{ width: tableWidth('small') }}>
                {program?.provider?.name}
              </TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {(paging.total_count === 0) && (
        <EmptyTable
          className="p-6 border border-t-0 border-solid border-light-border-blue"
          message="No programs linked to this Fee Schedule Screening
            Click “Add Program” to add a program to this Fee Schedule Screening."
        />
      )}
      <div className="p-6">
        <Pagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalItemCount={paging.total_count}
          totalPageCount={paging.total_pages}
          setPageSize={setPageSize}
          setPageNumber={setPageNumber}
          scrollPaginationElement={scrollToElementEvent}
        />
      </div>
    </>
  );
};

FeeScheduleScreeningProgramsTable.propTypes = {
  feeScheduleScreeningId: PropTypes.string.isRequired,
};

export default FeeScheduleScreeningProgramsTable;
