import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Button,
  Icon,
} from '@unite-us/ui';
import moment from 'moment';
import { useSetPlanFeeSchedule, useFindPlansByName } from 'src/components/Backoffice/api/hooks/v1/planHooks';
import { theme } from 'src/../tailwind.config';
import ScrollToElement from 'src/common/utils/Form/ScrollToElement';
import { Spinner } from 'common/spinners';
import { useInvalidateQueries } from 'src/api/APIHooks';
import Notifier from 'common/helpers/Notifier';
import { get } from 'lodash';
import { useFindFeeSchedule } from 'src/components/Backoffice/api/hooks/v1/feeScheduleHooks';
import AddRelationshipDialog from 'src/components/Backoffice/AddRelationshipDialog';
import 'src/pages/fee-schedules/_id/FeeSchedulePrograms/_fee_schedule_program_id/FeeScheduleProgramBaseCard.scss';
import './stylesheets/FeeScheduleProgramsTable.scss';

import FeeSchedulePlansTable, { EmptyFeeSchedulePlansTable } from './FeeSchedulePlansTable';

const FeeSchedulePlans = ({ feeScheduleId }) => {
  const [searchPageSize, setSearchPageSize] = useState(10);
  const [searchPageNumber, setSearchPageNumber] = useState(1);
  const [planName, setPlanName] = useState('');
  const [searchedPlansName, setSearchedPlansName] = useState(planName);
  const [selectedPlanID, setSelectedPlanID] = useState(null);
  const AddPlanModalRef = useRef(null);
  const invalidateQueries = useInvalidateQueries();
  const { data: feeSchedule, isFetching } = useFindFeeSchedule(feeScheduleId);
  const planIds = feeSchedule.plans;
  const { data: plans_searched, isFetchingSearch } = useFindPlansByName(
    searchedPlansName,
    {
      page: {
        size: searchPageSize,
        number: searchPageNumber,
      },
    },
  );
  const { data: plans, paging: planPaging } = plans_searched?.data || {};
  const closeModal = () => {
    setSelectedPlanID(null);
    setPlanName('');
    setSearchedPlansName('');
    AddPlanModalRef.current.closeDialog();
  };

  const openModal = () => {
    AddPlanModalRef.current.openDialog();
  };

  const ScrollToElementEvent = () => {
    ScrollToElement(document.getElementById('TopOfForms'));
  };

  const searchPlans = () => {
    setSelectedPlanID(null);
    setSearchedPlansName(planName || '');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      searchPlans();
    }
  };
  const checkboxDefault = (planId) => (
    planIds.map((plan) => plan.id).find((id) => (
      id === planId
    ))
  );

  const { setRelationship: setPlanFeeSchedule } = useSetPlanFeeSchedule(
    {
      onSuccess: () => {
        closeModal();
        invalidateQueries('plan');
        invalidateQueries('fee_schedule');
      },
      onError: (error) => {
        const errorList = get(error, 'response.data.errors', []);
        const stringNotification = errorList.map((e) => e.title).join(', ');
        Notifier.dispatch(400, `Failed to create program: ${stringNotification}`);
      },
    },
  );

  const addPlan = async () => {
    if (selectedPlanID) {
      await setPlanFeeSchedule(selectedPlanID, [feeScheduleId]);
      setSelectedPlanID(null);
    }
  };

  const selectPlan = (rowNumber, planID) => {
    if (!planIds.includes(planID)) {
      setSelectedPlanID(planID);
    }
  };
  if (isFetching) return <Spinner />;
  return (
    <div className="fee-schedule-base-card">
      <BaseCard className="p-6" noBorder>
        <BaseCardHeader title="Associated Plans" noBorder className="px-4 font-bold">
          <Button
            id="add-plan-button"
            iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="IconPlusCircle" />}
            className="flex"
            label="ADD PLAN"
            onClick={openModal}
            disabled={moment.utc().isAfter(moment(feeSchedule.ends_at).utc())}
          />
        </BaseCardHeader>
        <BaseCardBody withPadding>
          {
            feeSchedule.plans && feeSchedule.plans.length === 0 ?
              <EmptyFeeSchedulePlansTable /> : (
                <FeeSchedulePlansTable
                  planIds={planIds}
                  feeScheduleId={feeScheduleId}
                />
            )
          }
        </BaseCardBody>
      </BaseCard>
      <AddRelationshipDialog
        cancelHandler={closeModal}
        confirmationHandler={addPlan}
        confirmLabel="Confirm"
        dialogId="add-plan-modal"
        setRef={AddPlanModalRef}
        dialogTitle="Add New Plan"
        confirmationBtnDisabled={!selectedPlanID}
        isFetching={isFetchingSearch}
        onKeyPress={handleKeyPress}
        onClick={searchPlans}
        onChange={(value) => {
          setPlanName(value);
        }}
        paging={planPaging}
        tableElements={plans}
        selectedID={selectedPlanID}
        pageNumber={searchPageNumber}
        setPageNumber={setSearchPageNumber}
        pageSize={searchPageSize}
        setPageSize={setSearchPageSize}
        scrollPaginationElement={ScrollToElementEvent}
        selectElement={selectPlan}
        checkboxDefault={checkboxDefault}
        inputId="plan__form-input"
        inputPlaceholder="Enter the Plan Name"
        baseCardHeaderTestId="plan-search-result-base-card-header"
        searchTableId="feeScheduleSearchPlanTable"
        labelText="Plan name"
        tableHeaderColumns={['Plan Search Result', 'External ID', 'Included']}
        baseCardHeaderTitle="Plan Search Result"
      />
    </div>
  );
};

FeeSchedulePlans.propTypes = {
  feeScheduleId: PropTypes.string.isRequired,
};

export default FeeSchedulePlans;
