import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import ReferralRowIcon from 'src/components/Dashboard/components/Referrals/ReferralRowIcon';
import { DynamicTable } from 'common/tables';
import { shapes, Filter } from '@unite-us/client-utils';
import { Drawer } from '@unite-us/ui';
import { TOP_NAVIGATION_HEIGHT } from 'src/components/Navigation/constants';
import { Spinner } from 'common/spinners';
import FilterBar from '../../FilterBar';
import { setFilterSearchPlaceholder, setAsyncSearch } from '../../../utils/filter';
import './SentReferralsIndex.scss';

export default function SentReferralsIndex(props) {
  const {
    columnConfig,
    data,
    filters,
    isFetching,
    initialSort,
    onCellClick,
    onFiltersChange,
    onNextClick,
    onPrevClick,
    paging,
    fetchGroupsUsers,
    onClose,
    onToggle,
    open,
  } = props;
  const secondaryFilters = filters.filter((f) => _.get(f, 'secondary'));

  return (
    <div className="sent-referrals-index index-view">
      <FilterBar
        filters={filters}
        onFiltersChange={onFiltersChange}
        paging={paging}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
        isFetching={isFetching && !_.isEmpty(data)}
        asyncSearch={fetchGroupsUsers}
        onMoreFiltersClick={onToggle}
        showSecondaryFilters={!_.isEmpty(secondaryFilters)}
      />
      <div className="dashboard-inner-content">
        {
          (isFetching && _.isEmpty(data)) ?
            <Spinner /> : (
              <DynamicTable
                id="sent-referrals-table"
                dataSet={data}
                onCellClick={onCellClick}
                initialSort={initialSort}
                columnLabels={columnConfig}
                emptyMessage="There are no referrals."
                rowIcon={(referral) => <ReferralRowIcon referral={referral} />}
              />
            )
        }
      </div>
      <Drawer
        className="all-referrals__drawer"
        open={open}
        secondary
        onClose={onClose}
        topOffset={TOP_NAVIGATION_HEIGHT}
      >
        <div className="all-referrals__drawer-contents">
          <div className="all-referrals__drawer-header">
            <h2 className="all-referrals__drawer-header-label">
              More Filter(s)
            </h2>
          </div>
          <div className="all-referrals__drawer-body">
            {
                _.map(_.orderBy(secondaryFilters, ['name'], ['desc']), (f) => {
                  const {
                    asyncSearch: filterAsyncSearch,
                    searchPlaceholder,
                  } = f;

                  if (!f.secondary) {
                    return null;
                  }

                  return (
                    <Filter
                      hideSelectAll={f.hideSelectAll || false}
                      key={f.key}
                      name={f.name}
                      pluralName={f.pluralName}
                      options={f.options}
                      onFiltersChange={(activeFilters) => onFiltersChange(f.key, activeFilters)}
                      searchPlaceholder={searchPlaceholder || setFilterSearchPlaceholder(f)}
                      asyncSearch={filterAsyncSearch !== undefined ?
                        setAsyncSearch(f, filterAsyncSearch) :
                        setAsyncSearch(f, fetchGroupsUsers)}
                      uniqIdPrefix={f.key}
                    />
                  );
                })
              }
          </div>
        </div>
      </Drawer>
    </div>
  );
}

SentReferralsIndex.propTypes = {
  columnConfig: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  filters: PropTypes.array,
  initialSort: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  paging: shapes.paging,
  onCellClick: PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  fetchGroupsUsers: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
