import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  fetchAssessmentFromReferral,
} from 'actions/Assessment/Contact/Referral/Group';
import AssessmentDetailsView from 'src/components/Contacts/components/AssessmentDetailsView';
import { isHttpError } from 'common/utils/Error';
import ReferralAssessmentError from 'src/components/Referrals/components/ReferralAssessmentError';
import { restrictToSubmissionContext } from 'common/utils/FeatureFlags/flags';

export class ReferralAssessmentDetailsView extends Component {
  constructor(props) {
    super(props);

    this.fetchAssessmentFromReferral = this.fetchAssessmentFromReferral.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.state = {
      assessment: null,
      editMode: props.editMode,
      isFetching: true,
    };
  }

  UNSAFE_componentWillMount() {
    this.fetchAssessmentFromReferral();
  }

  // eslint-disable-next-line class-methods-use-this
  onCancel() {
    window.history.back();
  }

  fetchAssessmentFromReferral() {
    const { restrictContext } = this.props;
    this.setState({ isFetching: true }, () => {
      this.props.fetchAssessmentFromReferral(this.props.groupId, this.props.referralId, this.props.id, restrictContext)
        .then((payload) => {
          if (isHttpError(payload)) {
            this.setState({ isFetching: false });
          } else {
            this.setState({
              assessment: get(payload, 'data.data', {}),
              isFetching: false,
            });
          }
        });
    });
  }

  render() {
    const { assessment, editMode } = this.state;
    const { referralId, submissionId } = this.props;

    if (!assessment && !this.state.isFetching) {
      return <ReferralAssessmentError />;
    }

    return (
      <div className="referral-assessment-details">
        {
          assessment && (
            <AssessmentDetailsView
              assessment={assessment}
              submissionId={submissionId}
              showEditButton={false}
              afterSubmit={this.onCancel}
              editMode={editMode}
              onCancel={this.onCancel}
              contextType="referral"
              context={referralId}
            />
          )
        }
      </div>
    );
  }
}

const {
  func, bool, string,
} = PropTypes;

ReferralAssessmentDetailsView.propTypes = {
  id: string.isRequired,
  groupId: string.isRequired,
  editMode: bool,
  fetchAssessmentFromReferral: func.isRequired,
  referralId: string.isRequired,
  submissionId: string,
  restrictContext: bool,
};

ReferralAssessmentDetailsView.defaultProps = {
  editMode: false,
  restrictContext: false,
  submissionId: null,
};

function mapStateToProps(state, ownProps) {
  const restrictContext = restrictToSubmissionContext(state);
  const referralId = ownProps.params.id;
  return {
    id: ownProps.params.assessment_id,
    submissionId: ownProps.params.submission_id,
    editMode: !ownProps.params.submission_id,
    groupId: state.session.groupId,
    referralId,
    restrictContext,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchAssessmentFromReferral,
  },
)(ReferralAssessmentDetailsView);
