import _ from 'lodash';
import { noGroupsSelected } from 'src/components/Referrals/ReferralGroupsPrograms/utils';

function canSend(service) {
  const hasServiceType = !_.isEmpty(service.service_type.value);
  const hasValidNetworks = !_.isEmpty(service.referred_by_network.value) &&
    !service.refer_to_outside_network.value;

  return hasValidNetworks && hasServiceType;
}

function hasInvalidatingFieldChange(fields, nextFields) {
  const hasNetworkChanged = _.get(fields, 'referred_by_network.value.id') !==
    _.get(nextFields, 'referred_by_network.value.id');
  const hasServiceTypeChanged = _.get(fields, 'service_type.value.id') !== _.get(nextFields, 'service_type.value.id');
  return hasNetworkChanged || hasServiceTypeChanged;
}

function getThisAndNextService(props, nextProps, isSend) {
  return isSend ? {
    nextService: nextProps.fields || {},
    thisService: props.fields || {},
  } : {
    nextService: _.get(nextProps, 'fields.services[0]', {}),
    thisService: _.get(props, 'fields.services[0]', {}),
  };
}

function servicesValidForOONCaseContext(props = {}, nextProps = {}, isSend = false, acceptSelectedGroup = false) {
  const {
    nextService,
    thisService,
  } = getThisAndNextService(props, nextProps, isSend);

  // Check if valid sending/receiving network/checkbox combo.
  const hasValidNetworksAndServiceType = canSend(nextService);

  // Check that no receiving in-network groups are selected.
  const inNetworkGroupsEmptySelected = noGroupsSelected(nextService.selected);

  // Check that sending network and service type have not changed.
  const invalidatingFieldChange = hasInvalidatingFieldChange(thisService, nextService);

  return _.every([
    hasValidNetworksAndServiceType,
    inNetworkGroupsEmptySelected || acceptSelectedGroup,
    !invalidatingFieldChange,
  ]);
}

export default servicesValidForOONCaseContext;
