import { get, reverse } from 'lodash';
import { getPreferredProp } from '@unite-us/ui';
var findAddressComponent = function findAddressComponent(components, type) {
  var _short = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var component = components.find(function (_ref) {
    var types = _ref.types;
    return types.includes(type);
  });
  return get(component, "".concat(_short ? 'short_name' : 'long_name'));
};
var findAddressComponentForCity = function findAddressComponentForCity(components, terms) {
  var types = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var cityTerms = types.map(function (type) {
    return findAddressComponent(components, type);
  }).filter(function (term) {
    return term;
  });
  var suggestedCityTerms = cityTerms.filter(function (cityTerm) {
    return terms.includes(cityTerm);
  });
  return suggestedCityTerms[0] || cityTerms[0] || null;
};
var updateFieldsValues = function updateFieldsValues(result) {
  var _result$result, _result$latLng, _result$latLng2;
  var fields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var components = reverse(result === null || result === void 0 || (_result$result = result.result) === null || _result$result === void 0 ? void 0 : _result$result.address_components) || [];
  var terms = ((result === null || result === void 0 ? void 0 : result.terms) || []).map(function (term) {
    return term.value;
  });
  var streetNumber = findAddressComponent(components, 'street_number');
  var street = findAddressComponent(components, 'route');
  var establishment = findAddressComponent(components, 'establishment');
  var city = findAddressComponentForCity(components, terms, ['locality', 'sublocality', 'administrative_area_level_3', 'neighborhood']);
  var county = findAddressComponent(components, 'administrative_area_level_2');
  var state = findAddressComponent(components, 'administrative_area_level_1', true);
  var country = findAddressComponent(components, 'country', true);
  var postalCode = findAddressComponent(components, 'postal_code', true);
  var line1 = establishment;
  if (street) {
    line1 = streetNumber ? "".concat(streetNumber, " ").concat(street) : street;
  }
  var fieldOnChange = function fieldOnChange(fieldName, value) {
    var field = fields[fieldName];
    var onChange = getPreferredProp(field, 'onChange');
    onChange(value || '');

    // Touch each field
    if (result) {
      var onBlur = getPreferredProp(field, 'onBlur');
      onBlur();
    }
  };
  fieldOnChange('line_1', line1);
  fieldOnChange('city', city);
  fieldOnChange('county', county);
  fieldOnChange('state', state);
  fieldOnChange('country', country);
  fieldOnChange('postal_code', postalCode);
  fieldOnChange('latitude', result === null || result === void 0 || (_result$latLng = result.latLng) === null || _result$latLng === void 0 ? void 0 : _result$latLng.lat);
  fieldOnChange('longitude', result === null || result === void 0 || (_result$latLng2 = result.latLng) === null || _result$latLng2 === void 0 ? void 0 : _result$latLng2.lng);
};
export default updateFieldsValues;