import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Icon, InputField } from '@unite-us/ui';
import { validations } from '@unite-us/app-components';
import { phoneNumberNotEmptyValidation } from 'common/form/Phone/utils';
import { PhoneTypeField } from 'common/form/Phone';
import { RemovePhoneConfirmation } from 'common/form/Profile/ProfilePhoneFields/components';

import './stylesheets/programProfileFields.scss';

class ProgramProfilePhoneFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRemove: false,
    };

    this.onCancelRemove = this.onCancelRemove.bind(this);
    this.onConfirmRemove = this.onConfirmRemove.bind(this);
    this.showRemoveConfirmation = this.showRemoveConfirmation.bind(this);
  }

  onCancelRemove() {
    this.setState({ showRemove: false });
  }

  onConfirmRemove() {
    this.props.remove(this.props.index);
    this.setState({ showRemove: false });
  }

  showRemoveConfirmation() {
    this.setState({ showRemove: true });
  }

  render() {
    const {
      canRemoveField,
      fields,
      id,
      phoneFor,
      registerField,
      styles,
    } = this.props;

    const { showRemove } = this.state;

    return (
      <div className="program-profile-phone-fields">
        <div className="program-profile-phone-fields__program-phone-field-group">
          <div className="program-profile-phone-fields__program-phone-number">
            <InputField
              field={fields.phone_number}
              id={id}
              label="Phone Number"
              ref={registerField}
              validations={[
                { func: validations.isPhoneNumber },
                { func: validations.isRequired },
              ]}
            />
          </div>
          <div className="program-profile-phone-fields__program-phone-type">
            <PhoneTypeField
              field={fields.phone_type}
              id={id}
              phoneFor={phoneFor}
              registerField={registerField}
              validations={{
                func: phoneNumberNotEmptyValidation(fields),
                message: 'Required',
              }}
            />
          </div>
          <div
            className="program-profile-phone-fields__remove-phone"
            style={showRemove ? styles.fadeRemove : styles.showRemove}
          >
            {
              canRemoveField && (
                <Icon
                  icon="IconTrash"
                  onClick={this.showRemoveConfirmation}
                  size={21}
                />
              )
            }
          </div>
        </div>
        <div className="mb-two">
          <RemovePhoneConfirmation
            show={showRemove}
            onCancel={this.onCancelRemove}
            onConfirm={this.onConfirmRemove}
          />
        </div>
      </div>
    );
  }
}

ProgramProfilePhoneFields.propTypes = {
  canRemoveField: PropTypes.bool,
  fields: PropTypes.shape({
    value: PropTypes.object,
    phone_number: PropTypes.object.isRequired,
    phone_type: PropTypes.object.isRequired,
    extension: PropTypes.object,
  }).isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  phoneFor: PropTypes.oneOf(['contact', 'group', 'program']),
  registerField: PropTypes.func.isRequired,
  remove: PropTypes.func,
  styles: PropTypes.object,
};

ProgramProfilePhoneFields.defaultProps = {
  canRemoveField: true,
  phoneFor: 'program',
  remove: _.noop,
  styles: {
    fadeRemove: { opacity: '.3' },
    showRemove: { visibility: 'visible' },
    hideRemove: { visibility: 'hidden', display: 'none' },
    checkbox: { marginTop: '8px' },
  },
};

export default ProgramProfilePhoneFields;
