import { isEmpty, isEqual, includes } from 'lodash';

const getNetworkScopeFilterSearchParams = (networkScopes) => {
  const includeAddressBook = isEmpty(networkScopes) || isEqual(networkScopes.length, 2);
  const onlyAddressBook = includes(networkScopes, 'out-of-network') && isEqual(networkScopes.length, 1);

  if (includeAddressBook) {
    return {
      include_address_book: true,
    };
  }

  if (onlyAddressBook) {
    return {
      only_address_book: true,
    };
  }

  return {};
};

export default getNetworkScopeFilterSearchParams;
