import React from 'react';
import PropTypes from 'prop-types';
import './ContactDetailColumnItem.scss';

const ContactDetailColumnItem = ({
  children, hide, title, id,
}) => (hide ? null : (
  <div className="contact-detail-column-item">
    <h4 className="contact-detail-column-item__title">{title}</h4>
    <div
      className="contact-detail-column-item__data"
      {...(id && { id })}
    >
      {children}
    </div>
  </div>
));

ContactDetailColumnItem.propTypes = {
  children: PropTypes.node,
  hide: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string,
};

ContactDetailColumnItem.defaultProps = {
  hide: false,
};

export default ContactDetailColumnItem;
