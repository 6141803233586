/* eslint-disable max-len */
import { map, get } from 'lodash';

export default function buildNetwork({ network }) {
  /**
   * TODO -
   * id key - Add custom key from network.id
   * is_coordination_center key - Add custom key.  network.relationships.coordination_centers.data.length > 0 && coordination center id matches current provider ID
  */

  if (network.attributes) {
    return {};
  }

  const {
    id,
    is_coordination_center,
    name,
    network_type,
    focus_configuration = {},
    coordination_centers,
    service_types,
  } = network;

  return {
    id,
    is_coordination_center,
    name,
    network_type,
    // Matching CORE attributes
    focus: {
      military_focus: get(focus_configuration, 'military_focus', undefined),
      health_insurance_focus: get(focus_configuration, 'health_insurance_focus', undefined),
    },
    // Relationships
    ccIds: map(coordination_centers, 'id'),
    serviceTypeIds: [...map(service_types, 'id'), ...service_types.reduce((acc, curr) => {
      if (curr.children) {
        const childrenServiceTypeIds = map(curr.children, 'id');
        return [...acc, ...childrenServiceTypeIds];
      }

      return acc;
    }, [])],
  };
}

/**
 * TODO -
 * serviceTypeIds destructured from the nested parent and child service types provided by v3.  Adjust accordingly.
*/
