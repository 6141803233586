import React from 'react';
import {
  Icon,
} from '@unite-us/ui';
import PropTypes from 'prop-types';

function ErrorMessage({ children }) {
  return (
    <>
      <div>
        <Icon className="fill-current text-red mr-1" icon="IconExclamationCircle" size={16} />
      </div>
      <div>
        <p className="text-red text-13px">
          {children}
        </p>
      </div>
    </>
  );
}

ErrorMessage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorMessage;
