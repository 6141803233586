import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Tableau } from 'src/components/Insights/components';
import { fetchTicket } from 'actions/Bi/Auth/Api';
import { fetchUserInfo } from 'actions/User/Insights/Api';
import { fetchInsights } from 'actions/Downloads/Insights/Api';
import { notify } from 'actions/Notifier';
import { fetchViews } from 'actions/Views/Insights/Api';
import { hasUserRole } from 'src/components/User/utils';
import { browserHistory } from 'common/utils/browserHistory';
import { getEmployeeNetworks } from 'src/components/Employee/employeeGetters';
import { TABLEAU_BASE_URL } from 'src/config/env/env.config';
import './Insights.scss';

export class Insights extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apiToken: '',
      error: false,
      insights: [],
      loadingTableau: true,
      siteId: '',
      ticket: '',
      userId: '',
      views: [],
    };

    this.initializeTableau = this.initializeTableau.bind(this);
    this.updateInsights = this.updateInsights.bind(this);
  }

  componentDidMount() {
    const {
      hasInsights,
      redirectPath,
    } = this.props;

    if (!hasInsights) {
      return browserHistory.push(redirectPath);
    }

    // Phase II - Investigate dynamic importing
    // https://uniteus.atlassian.net/browse/UU3-28871
    if (!document.getElementById('tableau-script')) {
      const script = document.createElement('script');
      script.id = 'tableau-script';
      script.src = `${TABLEAU_BASE_URL}/javascripts/api/tableau-2.2.2.min.js`;
      script.async = true;

      document.body.appendChild(script);
    }

    return this.initializeTableau();
  }

  componentDidUpdate() {
    const {
      hasInsights,
      redirectPath,
    } = this.props;

    if (!hasInsights) {
      browserHistory.push(redirectPath);
    }
  }

  setError(error) {
    this.setState({
      error: true,
      insights: [],
      loadingTableau: false,
    }, () => {
      this.props.notify({
        status: 'error',
        message: error.message,
      });
    });
  }

  fetchInsights() {
    const { userId } = this.state;
    const params = { id: userId };

    return fetchInsights(params).then((res) => {
      const { data } = res;
      this.setState({
        insights: data.response,
      });
    }).catch((error) => {
      // Ideally would like to use status code to determine empty downlaods
      if (error.response.data.message !== 'no downloads found') {
        this.setState(() => {
          this.props.notify({
            status: 'error',
            message: error.message,
          });
        });
      }
    });
  }

  initializeTableau() {
    const promises = Promise.all([
      fetchTicket(),
      fetchUserInfo(),
    ]);

    return promises.then((response) => {
      const { ticket } = response[0].data;
      const {
        user_info: {
          api_token,
          site_id,
          user_id,
          workbooks,
        },
      } = response[1].data;

      const viewParams = {
        api_token,
        site_id,
        workbook_id: workbooks[0].id,
      };

      fetchViews(viewParams).then((res) => {
        const { views } = res.data.response;

        // Disable workbook due to performance issue per https://uniteus.atlassian.net/browse/UU3-29125
        // const workbookView = {
        //   id: workbooks[0].id,
        //   name: WORKBOOK_DROPDOWN_NAME,
        // };
        // Update view state when workbook is implemented via views: [...views, workbookView],

        this.setState({
          ticket,
          apiToken: api_token,
          siteId: site_id,
          userId: user_id,
          views,
          workbooks,
          loadingTableau: false,
        }, this.fetchInsights);
      }).catch((error) => {
        this.setError(error);
      });
    }).catch((error) => {
      this.setError(error);
    });
  }

  updateInsights(file) {
    const {
      insights,
    } = this.state;

    this.setState({
      insights: [file, ...insights],
    });
  }

  render() {
    const {
      apiToken,
      error,
      loadingTableau,
      siteId,
      ticket,
      userId,
      views,
      workbooks,
    } = this.state;

    return (
      <div className="insights">
        <div>
          {
            !loadingTableau && (
              <Tableau
                data={{
                  error,
                  ticket,
                  siteId,
                  userId,
                  apiToken,
                  views,
                  workbooks,
                }}
                updateInsights={this.updateInsights}
              />
            )
          }
        </div>
      </div>
    );
  }
}

Insights.propTypes = {
  hasInsights: PropTypes.bool.isRequired,
  notify: PropTypes.func.isRequired,
  redirectPath: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const hasInsights = hasUserRole({
    groupId: state.session.groupId,
    name: 'Insights',
    roleType: 'feature',
    user: state.user,
  });
  const session = state.session;
  const networks = getEmployeeNetworks({ state });
  const groups = get(state, 'user.groups', []);
  const group = groups.find((g) => g.group.id === session.groupId);
  const reportsNetworkRedirectPath = networks.length && `/reports/network/${networks[0].id}/population`;
  const reportsGroupRedirectPath = !reportsNetworkRedirectPath && `/reports/group/${group.id}/population`;
  const redirectPath = reportsNetworkRedirectPath || reportsGroupRedirectPath;

  return {
    hasInsights,
    redirectPath,
  };
}

export default connect(mapStateToProps, { notify })(Insights);
