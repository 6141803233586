import { get } from 'lodash';
import { useFind } from 'src/api/APIHooks';
import {
  DISPUTED_STATUSES,
  DRAFT_STATUSES,
  OPEN_NL_STATUSES,
  REJECTED_CBO_ADMIN_STATUSES_DWQ,
  OPEN_PAYER_STATUSES,
  READ_ONLY_OPEN_PAYER_STATUSES,
  PAYER_DISPUTED_STATUSES,
} from '../constants';
import usePayerWQInteractiveView from './usePayerWQInteractiveView';

const useInvoiceStatusCount = (
    providerId,
    status,
    isNetworkLead,
    payerId,
    showPayerInvoices,
    disputed = false,
    enabled = true,
  ) => {
  const defaultFilters = {
    invoice_status: status,
    has_user_archive: false,
    under_dispute: disputed,
  };

  const variableFilters = showPayerInvoices ? {
    payer: payerId,
  } :
  {
    ...(isNetworkLead ? { network_lead: providerId } : { provider: providerId }),
  };

  const { isFetching, data } = useFind(
    'invoice',
    {
      ...defaultFilters,
      ...variableFilters,
    },
    {
      page: {
        number: 1,
        size: 1,
      },
      queryConfig: {
        enabled: !!providerId && enabled,
        placeholderData: undefined,
      },
    },
  );

  return isFetching ? undefined : get(data, 'data.paging.total_count');
};

const useInvoiceStatusCounts = (providerId, isNetworkLead, payerId, showPayerInvoices, enabled) => {
  const { payerWQInteractiveView } = usePayerWQInteractiveView(payerId);
  const payerReadOnlyView = !payerWQInteractiveView;
  const submittedToCBOAdmin =
    useInvoiceStatusCount(
      providerId,
      'submitted_contracted_service_note',
      isNetworkLead,
      payerId,
      showPayerInvoices,
      undefined,
      enabled,
    );
  const submittedToNetworkLead = useInvoiceStatusCount(
    providerId,
    'submitted_to_network_lead',
    isNetworkLead,
    payerId,
    showPayerInvoices,
    undefined,
    enabled,
  );
  const draft = useInvoiceStatusCount(
    providerId,
    DRAFT_STATUSES,
    isNetworkLead,
    payerId,
    showPayerInvoices,
    undefined,
    enabled,
  );

  let variableOpenStatuses;
  if (showPayerInvoices) {
    if (payerReadOnlyView) {
      variableOpenStatuses = READ_ONLY_OPEN_PAYER_STATUSES;
    } else {
      variableOpenStatuses = OPEN_PAYER_STATUSES;
    }
  } else {
    variableOpenStatuses = OPEN_NL_STATUSES;
  }

  const variableDisputedStatuses = showPayerInvoices ? PAYER_DISPUTED_STATUSES : DISPUTED_STATUSES;

  const open = useInvoiceStatusCount(
    providerId,
    variableOpenStatuses,
    isNetworkLead,
    payerId,
    showPayerInvoices,
    undefined,
    enabled,
  );
  const rejected = useInvoiceStatusCount(
    providerId,
    ['rejected_by_cbo_admin', 'rejected_by_network_lead', 'rejected_by_payer'].join(','),
    isNetworkLead,
    payerId,
    showPayerInvoices,
    undefined,
    enabled,
  );
  const rejectedDWQ = useInvoiceStatusCount(
    providerId,
    REJECTED_CBO_ADMIN_STATUSES_DWQ,
    isNetworkLead,
    payerId,
    showPayerInvoices,
    undefined,
    enabled,
  );
  const disputed = useInvoiceStatusCount(
    providerId,
    variableDisputedStatuses,
    isNetworkLead,
    payerId,
    showPayerInvoices,
    true,
    enabled,
  );

  return {
    disputed,
    draft,
    open,
    submittedToCBOAdmin,
    submittedToNetworkLead,
    rejected,
    rejectedDWQ,
  };
};

export default useInvoiceStatusCounts;
