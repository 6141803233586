import moment from 'moment';
import _ from 'lodash';

function formatLocalTableDate(date, format) {
  const unixDate = moment.unix(date);
  const isValidDate = unixDate.isValid();

  if (!isValidDate || !_.isNumber(date)) {
    return '';
  }

  const defaultFormat = format || 'MMM D, YYYY';
  const sameDayFormat = 'h:mm a';

  if (format) {
    return unixDate.format(format);
  }

  const now = moment();

  if (unixDate.isSame(now, 'day')) {
    return unixDate.format(sameDayFormat);
  }

  return unixDate.format(defaultFormat);
}

export default formatLocalTableDate;
