import _ from 'lodash';

function getReasonsOptions(reasonsOptions = [], state) {
  return _.map(reasonsOptions, (reason) => (
    {
      label: _.get(reason, 'display_name', ''),
      value: _.get(reason, 'value', ''),
      initial: _.includes(state.filters.reasons, reason.value),
    }
  ));
}

export default getReasonsOptions;
