import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PhoneNumberDisplay from 'common/display/PhoneNumberDisplay';
import { orderedPrimary, generateUUID } from 'common/utils/utils';
import _ from 'lodash';
import EditModal from 'common/modal/EditModal';
import generatePhonesWithExtension from 'common/form/Profile/utils/generatePhonesWithExtension';
import EditPhoneNumbers from '../Forms/EditPhoneNumbers';

const EDIT_PHONE_NUMBERS = 'editPhoneNumbers';

class PhoneNumbers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
    };

    this.showHover = this.showHover.bind(this);
    this.hideHover = this.hideHover.bind(this);
  }

  showHover() {
    this.setState({
      editing: true,
    });
  }

  hideHover() {
    this.setState({
      editing: false,
    });
  }

  render() {
    const { employee } = this.props;
    employee.phone_numbers = generatePhonesWithExtension(employee.phone_numbers);

    const numbers = orderedPrimary(employee.phone_numbers, 'is_primary').map((number) => (
      <div
        className="col-sm-6 col-md-4"
        key={`phoneDisplay-${generateUUID()}`}
        style={{ minWidth: '170px' }}
      >
        <PhoneNumberDisplay
          item={number}
          detailedExtension
        />
      </div>
    ));
    const styles = this.state.editing ?
      _.assign({}, this.props.phoneRowStyles, { backgroundColor: '#ECF5FD' }) :
      this.props.phoneRowStyles;

    return (
      <div style={styles} className="user-phone-numbers editable-panel">
        <div
          className="row"
          onMouseEnter={this.showHover}
          onMouseLeave={this.hideHover}
        >
          <div className="col-sm-2">
            <h5 className="pull-right mt-quarter">Phone</h5>
          </div>
          <div className="col-sm-9">
            <div className="row">
              {numbers}
            </div>
          </div>
          <div className="col-sm-1">
            <span>
              <ul className="list-unstyled">
                <EditModal
                  id="edit-phone-modal"
                  header="Edit Phone"
                  size="large"
                  formName={EDIT_PHONE_NUMBERS}
                  buttonId="edit-phone-btn"
                >
                  <EditPhoneNumbers
                    employee={employee}
                  />
                </EditModal>
              </ul>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

PhoneNumbers.propTypes = {
  employee: PropTypes.object.isRequired,
  phoneRowStyles: PropTypes.object,
};

PhoneNumbers.defaultProps = {
  phoneRowStyles: {
    padding: '20px',
  },
};

export default PhoneNumbers;
