import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'src/../tailwind.config';
import { Icon } from '@unite-us/ui';
import { Link } from 'react-router';
import './EditLink.scss';

const EditLink = ({
  to, 'data-test-element': dataTestElement, label, ...props
}) => (
  <Link
    data-test-element={dataTestElement}
    to={to}
    className="edit-link"
    {...props}
  >
    <Icon
      className="mr-1.5"
      icon="IconPencil"
      style={{
        svg: {
          verticalAlign: 'top',
          // Color prop breaks when using style prop, so set fill color here.
          fill: theme.extend.colors['action-blue'],
        },
      }}
    />
    {label}
  </Link>
);

EditLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  'data-test-element': PropTypes.string.isRequired,
};

export default EditLink;
