import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { get } from 'lodash';
import { useFindRecord } from 'src/api/APIHooks';
import DEFAULT_ENUMS from 'src/reducers/constants/defaultEnums';

const { contact_data: { military: { affiliation: MILITARY_AFFILIATION } } } = DEFAULT_ENUMS;

/* eslint-disable no-param-reassign */
const MilitaryAffiliation = ({ personId, affiliationValue }) => {
  if (personId && !affiliationValue) {
    const { data, isFetching } = useFindRecord('person', personId);
    const person = get(data, 'data.data', null);
    if (!person || isFetching) { return null; }
    affiliationValue = get(person, 'military.affiliation');
  }
  const affiliation = affiliationValue && MILITARY_AFFILIATION.find((v) => v.value === affiliationValue);

  return (
    <div>
      <h3>Military Affiliation</h3>
      {
        !personId ? <p className="mt-2">{ affiliation?.display_name }</p> : (
          <Link
            id="military-information-link"
            className="block mt-2 text-action-blue"
            to={`/military-information/${personId}`}
          >
            { affiliation ? affiliation.display_name : 'Undisclosed' }
          </Link>
        )
      }
    </div>
  );
};
/* eslint-enable no-param-reassign */

MilitaryAffiliation.propTypes = {
  personId: PropTypes.string.isRequired,
  affiliationValue: PropTypes.string,
};

MilitaryAffiliation.defaultProps = {
  affiliationValue: null,
};

export default MilitaryAffiliation;
