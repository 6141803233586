/* eslint-disable max-len */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'common/utils/browserHistory';
import { Icon } from '@unite-us/ui';
import { PrimaryButton } from 'common/TailwindComponents';

export const EnrollmentConfirmation = (props) => {
  const {
    contact,
    contactId,
    plan,
  } = props;

  const onCheckEnrollmentRequest = useCallback(() => {
    browserHistory.push(`/facesheet/${contactId}/profile#social-insurance-list`);
  }, [browserHistory, contactId]);

  return (
    <div className="bg-white flex flex-col justify-center items-center text-center p-4">
      <Icon
        ariaLabel="verified"
        color="#4ECE3D"
        icon="IconCheckCircle"
        iconName="verified"
        size={48}
        className="p-4"
      />
      <h1 style={{ margin: '0px 0px 1em' }}>
        <strong>Enrollment Request Sent!</strong>
      </h1>
      <p className="max-w-lg text-left">
        Your enrollment request to the <strong>{plan.name}</strong> for <strong>{contact.full_name}</strong> has been sent! You&apos;ll receive a notification when the request has been approved.
      </p>
      <p className="max-w-lg text-left py-6">
        To check the status of the request, visit the client&apos;s face sheet and look under &quot;Social Care Coverage&quot; in their &quot;Profile&quot;. Forms completed in this enrollment request process will be added to &quot;Forms&quot; in the client face sheet.
      </p>

      <PrimaryButton label="Check Enrollment Request Status" onClick={onCheckEnrollmentRequest} />

    </div>
  );
};

EnrollmentConfirmation.propTypes = {
  contact: PropTypes.object,
  contactId: PropTypes.string.isRequired,
  plan: PropTypes.object.isRequired,
};

EnrollmentConfirmation.defaultProps = {
  contact: {},
};

export default EnrollmentConfirmation;
