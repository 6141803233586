import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, MenuItem } from '@unite-us/ui';
import { noop } from 'lodash';
import { Link } from 'react-router';
import { routeOnKeyDown } from 'src/components/Navigation/utils';
import {
  useFindExportAndDownload,
  useUpdateViewed,
} from 'src/components/Organization/api/hooks/v1/employeeNotificationHooks';
import { browserHistory } from 'common/utils/browserHistory';
import EmployeeNotification from './EmployeeNotification';

const handleNotificationAction = (notification, updateViewed, setSelectedExportId) => () => {
  if (!notification.viewed) {
    updateViewed(notification.id, { viewed: true });
  }
  if (notification.key === 'export_completed') {
    setSelectedExportId(notification.subject.id);
  }
  if (notification.key.startsWith('Task.')) {
    browserHistory.push({
      pathname: '/tasks',
      search: `?task_id=${notification.key.split('.')[1]}`,
    });
  }
  if (notification.key.includes('invoice')) {
    browserHistory.push({
      pathname: `/invoices/${notification.subject.id}`,
    });
  }
  if (notification.key.includes('service_authorization')) {
    browserHistory.push({
      // eslint-disable-next-line max-len
      pathname: `/dashboard/cases/open/${notification.key.split('.')[1]}/contact/${notification.message_data.client.id}`,
    });
  }
  if (notification.key.includes('social_care_coverage_ended')) {
    browserHistory.push({
      // eslint-disable-next-line max-len
      pathname: `/dashboard/cases/open/${notification.key.split('.')[1]}/contact/${notification.message_data.client.id}`,
    });
  }
};

export const EmployeeNotificationsContainer = ({
  position,
  setMenuItemLength,
  setPosition,
  handleMenuItemNavigation,
  togglePopover,
  notifications,
}) => {
  useEffect(() => {
    setMenuItemLength(notifications.length);
  }, [notifications]);

  const [selectedExportId, setSelectedExportId] = useState('');
  useFindExportAndDownload(selectedExportId);

  const { updateRecord: updateViewed } = useUpdateViewed();
  return (
    <div className="flex flex-col antialiased h-700px">
      <div className="flex flex-none items-center justify-between py-5 px-4">
        <h1 className="font-heavy-font font-bold">Notifications</h1>
        <Link
          to={'/user/settings/notifications'}
          onClick={togglePopover}
        >
          <div className="flex items-center">
            <Icon
              className="fill-current text-action-blue mr-1.5"
              color="text-action-blue"
              icon="IconSettings"
              size={14}
            />
            <p className="text-action-blue">Settings</p>
          </div>
        </Link>
      </div>

      <hr />

      <div className="flex flex-none items-center justify-between p-4">
        <h2 className="font-heavy-font font-bold">Latest</h2>
        <button
          className="text-action-blue"
          type="button"
          onClick={() => {
            notifications
              .forEach(async (notification) => {
                if (!notification.viewed) {
                  await updateViewed(notification.id, { viewed: true });
                }
              });
          }}
        >
          Mark all as read
        </button>
      </div>

      <div className="flex flex-col flex-auto min-w-400px overflow-y-auto">
        {

          notifications.map((notification, index) => (
            <MenuItem
              key={notification.id}
              handleMenuItemNavigation={handleMenuItemNavigation}
              id="right-nav-notifications"
              className="focus:outline-none"
              index={index}
              position={position}
              primaryNode={(
                <EmployeeNotification notification={notification} />
              )}
              setPosition={setPosition}
              togglePopover={togglePopover}
              onKeyDown={routeOnKeyDown(handleNotificationAction(notification, updateViewed, setSelectedExportId))}
              onClick={handleNotificationAction(notification, updateViewed, setSelectedExportId)}
            />
          ))
        }

        {
          notifications.length ? (
            <>
              <div className="flex flex-none flex-col items-center space-y-2 my-6 text-center">
                <Icon
                  className="fill-current text-brand-blue"
                  icon="LogoShield"
                  size={34}
                />
                <p>That&apos;s all your notifications</p>
              </div>
            </>
          ) : (
            <div className="flex flex-auto flex-col justify-between h-full">
              <div className="mt-24 text-center space-y-4">
                <Icon
                  icon="V2NoNotification"
                  size={138}
                />
                <h4 className="text-dark-grey text-lg">You don&apos;t have any notifications yet</h4>
                <p className="text-dark-grey">Notifications will appear here as they come in</p>
              </div>
            </div>
          )
        }
      </div>
      <div className="flex-none bg-medium-fill-grey h-10" />
    </div>
  );
};

EmployeeNotificationsContainer.propTypes = {
  position: PropTypes.number,
  setMenuItemLength: PropTypes.func,
  setPosition: PropTypes.func,
  handleMenuItemNavigation: PropTypes.func,
  togglePopover: PropTypes.func,
  notifications: PropTypes.array,
};

EmployeeNotificationsContainer.defaultProps = {
  position: 0,
  setMenuItemLength: noop,
  setPosition: noop,
  handleMenuItemNavigation: noop,
  togglePopover: noop,
  notifications: [],
};

export default EmployeeNotificationsContainer;
