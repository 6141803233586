import { get, isEmpty } from 'lodash';

function getStateEnumFullName(states = [], stateEnums) {
  if (!isEmpty(stateEnums)) {
    return states.reduce((acc, state) => {
      const stateValue = stateEnums.find((s) => (
        s.value === (state)
      ));

      if (stateValue) {
        acc.push(get(stateValue, 'display_name'));
      }
      return acc;
    }, []);
  }

  return [];
}

export default getStateEnumFullName;
