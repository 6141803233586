// Library Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@unite-us/ui';

// Component Imports
import { RejectDisplay } from '.';

class RejectModalDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rejectClicked: false,
      forwardClicked: false,
    };

    this.onRejectClicked = this.onRejectClicked.bind(this);
    this.onForwardClicked = this.onForwardClicked.bind(this);
    this.resetState = this.resetState.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  onRejectClicked() {
    this.setState({ rejectClicked: true });
  }

  onForwardClicked() {
    this.setState({ forwardClicked: true });
  }

  resetState() {
    this.setState({ rejectClicked: false, forwardClicked: false });
  }

  openDialog() {
    return this.rejectDialog.openDialog()
      .then(this.props.resetActionValue);
  }

  closeDialog() {
    this.resetState();
    this.rejectDialog.closeDialog();
  }

  render() {
    const {
      groupId,
      referral,
    } = this.props;

    return (
      <div className="reject-modal-dialog">
        <Dialog
          id="reject-referral-modal"
          data-testid="reject-referral-modal"
          ref={(el) => { this.rejectDialog = el; }}
          title="Reject Referral"
          size="large"
          onRequestClose={this.closeDialog}
          modal
        >
          <RejectDisplay
            referral={referral}
            groupId={groupId}
            closeDialog={this.closeDialog}
            onRejectClicked={this.onRejectClicked}
            onForwardClicked={this.onForwardClicked}
            rejectClicked={this.state.rejectClicked}
            forwardClicked={this.state.forwardClicked}
          />
        </Dialog>
      </div>
    );
  }
}

RejectModalDialog.propTypes = {
  referral: PropTypes.object.isRequired,
  resetActionValue: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
};

export default RejectModalDialog;
