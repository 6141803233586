import { get, isEmpty } from "lodash";

export const extractQuestionsRecursive = (n, questions) => {
  // page and panel have elements array
  // 'panel questions' wont be added to the results
  const childElements = get(n, 'elements')

  if (isEmpty(childElements)) return n;

  childElements.forEach(ce => {
    const question = extractQuestionsRecursive(ce, questions);
    if (question)
      questions.push(question)
  })
}
