import _ from 'lodash';

// This filter set currently has only two values -> 'none' (default) or 'true'
const allClientsFilter = 'none';
const reqActionFilter = 'true';
const ALLOWED_CLIENT_FILTERS = [allClientsFilter, reqActionFilter];

/**
 * Ensure the filter value is set properly and only accept the allowed values.
 *
 * @param  {String} desiredFilter The filter to use
 * @return {String} The normalized filter value
 */
const getNormalizedFilterValue = (desiredFilter) => {
  // We most typically receive a string
  if (_.isString(desiredFilter) && _.includes(ALLOWED_CLIENT_FILTERS, desiredFilter)) {
    return desiredFilter;
  }

  // We can receive a boolean too
  if (_.isBoolean(desiredFilter)) {
    if (desiredFilter) {
      // Return the only choice we're supporting here
      return reqActionFilter;
    }
  }

  // Check array contents (less typical)
  if (_.isArray(desiredFilter)) {
    if (_.includes(desiredFilter, reqActionFilter) || _.includes(desiredFilter, true)) {
      return reqActionFilter;
    }
  }

  // Give up and return the preferred default
  return allClientsFilter;
};

const clientsRequiringActionFilter = (initialFilters = [], secondary = false) => ({
  key: 'flagged',
  name: 'Clients Requiring Action',
  pluralName: 'Clients Requiring Action',
  single: true,
  value: getNormalizedFilterValue(initialFilters),
  options: [
    { label: 'All Clients', value: allClientsFilter, initial: _.includes(initialFilters, allClientsFilter) },
    { label: 'Clients Requiring Action', value: reqActionFilter, initial: _.includes(initialFilters, reqActionFilter) },
  ],
  secondary,
});

export default clientsRequiringActionFilter;
