import getStatusDisplayName from 'src/components/Dashboard/utils/getStatusDisplayName';
import formatConsentRequests from 'src/components/Dashboard/utils/formatConsentRequests';
import getReferralRecipientDisplayName from 'src/components/Dashboard/utils/getReferralRecipientDisplayName';

export const actionMenu = (actions = []) => ({
  label: '',
  actions,
  type: 'takeAction',
  onItemSelect: (i) => actions[i].action(),
});

export const consentRequests = () => ({
  label: 'Consent Requests',
  sortColumn: 'contact.last_consent_requested_at',
  formatData: formatConsentRequests,
});

export const dateClosed = () => ({
  label: 'Date Case Closed',
  sortColumn: 'case.closed_date',
  type: 'date',
});

export const dateReceived = () => ({
  label: 'Date Received',
  sortColumn: 'created_at',
  type: 'date',
});

export const dateSent = () => ({
  label: 'Date Sent',
  sortColumn: 'created_at',
  type: 'date',
});

export const lastUpdated = () => ({
  label: 'Last Updated',
  sortColumn: 'updated_at',
  type: 'date',
});

export const organization = () => ({
  label: 'Organization',
  sortColumn: 'referred_to_group.name',
});

export const outcome = () => ({
  label: 'Outcome',
  sortColumn: 'closing.outcome',
});

export const reasonInReview = () => ({
  label: 'Reason In Review',
  sortColumn: 'status_reason',
});

export const recipient = () => ({
  label: 'Recipient',
  formatData: getReferralRecipientDisplayName,
  sortColumn: 'referred_to_group.name',
});

export const status = (statuses = []) => ({
  label: 'Status',
  sortColumn: 'status',
  formatData: (data) => getStatusDisplayName(data, statuses),
  type: 'statusExtended',
});
