import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SystemNotification from './components/SystemNotification';

require('./stylesheets/systemNotifications.scss');

export class SystemNotifications extends Component {
  constructor(props) {
    super(props);

    this.renderSystemNotifications = this.renderSystemNotifications.bind(this);
  }

  renderSystemNotifications() {
    return this.props.notifications.map((notification) => (
      <SystemNotification
        notification={notification}
        key={notification.uuid}
      />
    ));
  }

  render() {
    return (
      <div style={{ zIndex: 1 }} id="systemNotifications">
        {this.renderSystemNotifications()}
      </div>
    );
  }
}

SystemNotifications.propTypes = {
  notifications: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return { notifications: state.systemNotifications };
}

export default connect(mapStateToProps)(SystemNotifications);
