import {
  FETCH_INVITATIONS,
} from 'actions';

const defaultState = {
  data: [],
  paging: {},
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case FETCH_INVITATIONS: {
      return action.payload.data;
    }
    default:
      return state;
  }
}
