import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';
import { theme } from 'src/../tailwind.config';

const AddFieldButton = ({
  'data-test-element': dataTestElement,
  isPositioned,
  label,
  onClick,
}) => (
  <div className={isPositioned ? 'absolute top-0 right-0' : ''}>
    <button
      className="flex itmes-center"
      type="button"
      onClick={onClick}
      data-test-element={dataTestElement}
    >
      <Icon icon="V2Add" color={theme.extend.colors['action-blue']} />
      <span className="text-action-blue pl-1 transform -translate-y-px">{label}</span>
    </button>
  </div>
);

AddFieldButton.defaultProps = {
  'data-test-element': null,
  isPositioned: false,
  onClick: () => null,
};

AddFieldButton.propTypes = {
  'data-test-element': PropTypes.string,
  isPositioned: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default AddFieldButton;
