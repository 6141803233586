import { get } from 'lodash';
import { dateToEpoch } from 'common/utils/utils';

export default function paymentsInsuranceInitialValues(currentInsurance) {
  let initialInsuranceValues;

  if (currentInsurance) {
    const enrollment = get(currentInsurance.attributes, 'enrolled_at');
    const expiration = get(currentInsurance.attributes, 'expired_at');
    const planId = get(currentInsurance.relationships.plan.data, 'id', '');
    const planType = get(currentInsurance.relationships.plan.data, 'planType', '');

    initialInsuranceValues = {
      form_id: currentInsurance.id,
      id: currentInsurance.id,
      plan_type: planType,
      plan: planId,
      member_id: currentInsurance.attributes.external_member_id || '',
      group_id: currentInsurance.attributes.external_group_id,
      enrollment: enrollment ? dateToEpoch(enrollment) : '',
      expiration: expiration ? dateToEpoch(expiration) : '',
    };
  }

  return initialInsuranceValues;
}
