import React, { useContext, useEffect } from 'react';
import { createSelector } from 'reselect';
import { find, pick, isNil } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import Notifier from 'common/helpers/Notifier';
import { SEARCH_CLIENTS, SEARCH_CONTEXTS, SEARCH_ROLES } from '@unite-us/app-search';
import { getAuthToken } from 'src/api/config';
import {
  hasPaymentsUserAccess,
  hasCaseManagerRole,
  hint716SearchNetworkHubSupportPremiumSelector,
  includePathwaysServices,
  uup459SupersetPhase2 as uup459SupersetPhase2Selector,
  hint1246HideLgbtqPlusFilter as hint1246HideLgbtqPlusFilterSelector,
  hint1426SharesShowNewPopulations as hint1426SharesShowNewPopulationsSelector,
  hint1980ShowSharesMessageBox as hint1980ShowSharesMessageBoxSelector,
  cerb1199IsSupersetMyNetwork as cerb1199IsSupersetMyNetworkSelector,
  cerb1367ResourceListDetailEnhancements as cerb1367ResourceListDetailEnhancementsSelector,
  cerb1455HasSuggestEdit as cerb1455HasSuggestEditSelector,
  cerb1485Ny1115Networks as cerb1485Ny1115NetworksSelector,
  cerbNy1115 as cerbNy1115Selector,
  cerb1519ScreeningRequiredFeeSchedules as cerb1519ScreeningRequiredFeeSchedulesSelector,
  cerb1486Ny1115ClientSocialCareCoverageIds as cerb1486Ny1115ClientSocialCareCoverageIdsSelector,
} from 'common/utils/FeatureFlags/flags';
import { SEARCH_API, CORE_API, GOOGLE_MAPS_API_KEY, SHARES_URL } from 'src/config/env/env.config';
import { TrackerContext } from '@unite-us/client-utils';
import { ComponentLibraryLoader } from '@unite-us/app-components';
import { updateGlobalState } from 'actions/Global/globalActions';

const SearchApp = ComponentLibraryLoader({
  loadFn: () => import('@unite-us/app-search'),
  component: 'SearchApp',
  loadingApp: 'app-client',
  source: 'app-search',
  datadogRum,
});

function getMyNetworkFilters({
  employeeId,
  networkId,
  providerId,
}) {
  return {
    'provider.networks': networkId,
    referable: {
      employee: employeeId,
      network: networkId,
      provider: providerId,
    },
  };
}

function getReferralFilters({
  referral,
  employeeId,
  contact,
}) {
  if (!referral) return {};
  return {
    services: referral.serviceId,
    'provider.licensed': referral.isLicensed,
    ...(!isNil(referral.providerSensitive) && { 'provider.sensitive': referral.providerSensitive }),
    ...(referral.isLicensed ? {
      receiving_referrals: true,
    } : { 'provider.networks': referral.toNetworkId }),
    referable: {
      employee: employeeId,
      network: referral.fromNetworkId,
      person: referral.personId,
      provider: referral.providerId,
      recipient_network: referral.toNetworkId,
    },
    contact: contact ?
      pick(
        contact,
        [
          'first_name',
          'last_name',
          'phone_numbers',
          'email_addresses',
          'date_of_birth',
          'gender',
          'addresses',
          'household',
        ],
      ) :
      undefined,
  };
}

function getInitialFilters({
  uup459SupersetPhase2,
  networkId,
  providerId,
  referral,
  employeeId,
  isPaymentsUserRole,
  contact,
}) {
  const baseFilters = {
    active: true,
  };
  const additionalFilters = networkId ?
    getMyNetworkFilters({
      employeeId,
      uup459SupersetPhase2,
      networkId,
      providerId,
      isPaymentsUserRole,
    }) :
    getReferralFilters({
      referral,
      employeeId,
      contact,
      isPaymentsUserRole,
    });

  return {
    ...baseFilters,
    ...additionalFilters,
  };
}

const SearchPrograms = ({
  activeNetworks,
  includePathways,
  uup459SupersetPhase2,
  serviceTypeOptions,
  employeeId,
  employeeNetworks,
  providerId,
  allAddresses,
  networkId,
  referral,
  userCoordinates,
  isPaymentsUserRole,
  isCaseManagerRole,
  contact,
  isShoppingCartOpen,
  setIsShoppingCartOpen,
  screeningRequiredFeeSchedulesIds,
  ny1115SocialCoverageIds,
  hint716SearchNetworkHubSupportPremium,
  hint1246HideLgbtqPlusFilter,
  hint1426SharesShowNewPopulations,
  hint1980ShowSharesMessageBox,
  crtb1127AuthPaymentProgramsInReferrals,
  cerb1199IsSupersetMyNetwork,
  cerb1367ResourceListDetailEnhancements,
  cerb1455HasSuggestEdit,
  cerbNy1115,
  cerb1485Ny1115Networks,
}) => {
  useEffect(() => {
    setIsShoppingCartOpen(false);
  }, []);

  const initialFilters = getInitialFilters({
    uup459SupersetPhase2,
    networkId,
    providerId,
    referral,
    employeeId,
    isPaymentsUserRole,
    contact,
  });
  const trackEvent = useContext(TrackerContext);

  serviceTypeOptions.sort((a, b) => {
    if (a.parent_code < b.parent_code) return -1;
    if (a.parent_code > b.parent_code) return 1;
    return a.name.localeCompare(b.name);
  });

  const networkName = uup459SupersetPhase2 ? activeNetworks.filter((n) => n.id === networkId).name : '';

  const userRoles = [
    ...isPaymentsUserRole ? [SEARCH_ROLES.PAYMENTS] : [],
    ...isCaseManagerRole ? [SEARCH_ROLES.CASE_MANAGER] : [],
  ];

  return (
    <SearchApp
      callbacks={{
        trackEvent,
        notify: {
          error: (message) => Notifier.dispatch('error', message),
          success: (message) => {
            Notifier.dispatch('success', message);
          },
          warn: (message) => Notifier.dispatch('warning', message),
        },
      }}
      serviceTypeOptions={serviceTypeOptions}
      allAddresses={allAddresses}
      // TODO: this prop can be remove if the service type is looked inside the serviceTypeOptions
      referralServiceType={referral && {
        id: referral.serviceId,
        name: referral.serviceName,
      }}
      networkName={networkName}
      userNetworks={employeeNetworks}
      cerb1485Ny1115Networks={cerb1485Ny1115Networks}
      appSettings={
        {
          client: SEARCH_CLIENTS.APP_CLIENT,
          context: referral ? SEARCH_CONTEXTS.REFERRAL : SEARCH_CONTEXTS.MY_NETWORK,
          env: {
            getAuthToken,
            employeeId,
            providerId,
            googleApiKey: GOOGLE_MAPS_API_KEY,
            SHARES_URL,
          },
          endpoints: {
            search: {
              url: SEARCH_API,
            },
            core: {
              url: CORE_API,
            },
          },
          flags: {
            hint716SearchNetworkHubSupportPremium,
            serviceTypesIncludePathways: includePathways,
            uup459SupersetPhase2,
            hint1246HideLgbtqPlusFilter,
            hint1426SharesShowNewPopulations,
            hint1980ShowSharesMessageBox,
            crtb1127AuthPaymentProgramsInReferrals,
            cerb1199IsSupersetMyNetwork,
            cerb1367ResourceListDetailEnhancements,
            cerb1455hasSuggestEdit: cerb1455HasSuggestEdit,
            cerbNy1115,
          },
          roles: userRoles,
          idsOfFeeSchedulesThatRequireSpecializedScreenings: screeningRequiredFeeSchedulesIds,
          ny1115SocialCoverageIds,
        }
      }
      initialFilters={initialFilters}
      userCoordinates={userCoordinates}
      isShoppingCartOpen={isShoppingCartOpen}
      setIsShoppingCartOpen={setIsShoppingCartOpen}
    />
  );
};

// Should match shape of address validator in Core
const addressShape = {
  address_type: PropTypes.string.isRequired,
  line_1: PropTypes.string,
  line_2: PropTypes.string,
  city: PropTypes.string.isRequired,
  county: PropTypes.string,
  state: PropTypes.string.isRequired,
  postal_code: PropTypes.string,
  country: PropTypes.string.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  is_primary: PropTypes.bool,
};

SearchPrograms.propTypes = {
  activeNetworks: PropTypes.arrayOf(PropTypes.object).isRequired,
  employeeId: PropTypes.string.isRequired,
  employeeNetworks: PropTypes.arrayOf(PropTypes.string).isRequired,
  providerId: PropTypes.string.isRequired,
  contact: PropTypes.object,
  allAddresses: PropTypes.shape({
    CLIENT: PropTypes.arrayOf(PropTypes.shape(addressShape)),
    USER: PropTypes.arrayOf(PropTypes.shape(addressShape)),
    GROUP: PropTypes.arrayOf(PropTypes.shape(addressShape)),
  }).isRequired,
  includePathways: PropTypes.bool.isRequired,
  networkId: PropTypes.string.isRequired,
  referral: PropTypes.shape({
    fromNetworkId: PropTypes.string,
    isLicensed: PropTypes.bool,
    personId: PropTypes.string,
    providerId: PropTypes.string,
    serviceId: PropTypes.string,
    serviceName: PropTypes.string,
    toNetworkId: PropTypes.string,
  }),
  serviceTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      parent_id: PropTypes.string,
      parent_code: PropTypes.string,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      taxonomy: PropTypes.string.isRequired,
      is_sensitive: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  userCoordinates: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  isShoppingCartOpen: PropTypes.bool.isRequired,
  setIsShoppingCartOpen: PropTypes.func.isRequired,
  screeningRequiredFeeSchedulesIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  ny1115SocialCoverageIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  isPaymentsUserRole: PropTypes.bool.isRequired,
  isCaseManagerRole: PropTypes.bool.isRequired,
  hint716SearchNetworkHubSupportPremium: PropTypes.bool.isRequired,
  uup459SupersetPhase2: PropTypes.bool.isRequired,
  hint1246HideLgbtqPlusFilter: PropTypes.bool.isRequired,
  hint1426SharesShowNewPopulations: PropTypes.bool.isRequired,
  hint1980ShowSharesMessageBox: PropTypes.bool.isRequired,
  crtb1127AuthPaymentProgramsInReferrals: PropTypes.bool.isRequired,
  cerb1199IsSupersetMyNetwork: PropTypes.bool.isRequired,
  cerb1367ResourceListDetailEnhancements: PropTypes.bool.isRequired,
  cerb1455HasSuggestEdit: PropTypes.bool.isRequired,
  cerbNy1115: PropTypes.bool.isRequired,
  cerb1485Ny1115Networks: PropTypes.arrayOf(PropTypes.string).isRequired,
};

SearchPrograms.defaultProps = {
  referral: undefined,
  contact: {},
};

function mapStateToProps(state, ownProps) {
  const activeNetworks = state.globalState.activeNetworks;
  const employeeNetworks = state.globalState.activeNetworks.map((network) => network.id);
  const employeeId = state.globalState.currentEmployee.id;
  const providerId = state.globalState.currentEmployee.provider.id;
  const includePathways = includePathwaysServices(state);
  const { referral } = ownProps;
  const contact = find(state.contacts.contacts, { id: referral?.personId });
  const selectServiceTypes = createSelector(
    (inState) => inState.session.globals.service_types,
    (serviceTypes) => {
      const ret = [];
      serviceTypes.forEach((parentType) => {
        parentType.children.forEach((child) => (
          ret.push({
            id: child.id,
            parent_id: parentType.id,
            parent_code: parentType.code,
            name: child.name,
            code: child.code,
            taxonomy: child.taxonomy,
            is_sensitive: child.sensitive,
          })
        ));

        ret.push({
          id: parentType.id,
          parent_id: null,
          parent_code: null,
          name: parentType.name,
          code: parentType.code,
          taxonomy: parentType.taxonomy,
          is_sensitive: parentType.sensitive,
        });
      });
      return ret;
    },
  );

  const {
    currentEmployee: {
      addresses: userAddresses,
      provider: {
        addresses: groupAddresses,
      },
    },
  } = state.globalState;
  const userCoordinates = Object.keys(state.session.position.geoCoordinates).length === 0 ?
    state.session.position.ipCoordinates : state.session.position.geoCoordinates;
  const isShoppingCartOpen = state.globalState.isShoppingCartOpen;

  return {
    activeNetworks,
    employeeNetworks,
    includePathways,
    serviceTypeOptions: selectServiceTypes(state),
    employeeId,
    providerId,
    isPaymentsUserRole: hasPaymentsUserAccess(state),
    isCaseManagerRole: hasCaseManagerRole(state),
    contact,
    allAddresses: {
      client: contact?.addresses,
      user: userAddresses,
      ours: groupAddresses,
    },
    userCoordinates,
    isShoppingCartOpen,
    screeningRequiredFeeSchedulesIds: cerb1519ScreeningRequiredFeeSchedulesSelector(state),
    ny1115SocialCoverageIds: cerb1486Ny1115ClientSocialCareCoverageIdsSelector(state),
    hint716SearchNetworkHubSupportPremium: hint716SearchNetworkHubSupportPremiumSelector(state),
    uup459SupersetPhase2: uup459SupersetPhase2Selector(state),
    hint1246HideLgbtqPlusFilter: hint1246HideLgbtqPlusFilterSelector(state),
    hint1426SharesShowNewPopulations: hint1426SharesShowNewPopulationsSelector(state),
    hint1980ShowSharesMessageBox: hint1980ShowSharesMessageBoxSelector(state),
    cerb1199IsSupersetMyNetwork: cerb1199IsSupersetMyNetworkSelector(state),
    cerb1367ResourceListDetailEnhancements: cerb1367ResourceListDetailEnhancementsSelector(state),
    cerb1455HasSuggestEdit: cerb1455HasSuggestEditSelector(state),
    cerbNy1115: cerbNy1115Selector(state),
    cerb1485Ny1115Networks: cerb1485Ny1115NetworksSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setIsShoppingCartOpen: (isOpen) => dispatch(updateGlobalState({ isShoppingCartOpen: isOpen })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPrograms);
