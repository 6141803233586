import React, { useEffect } from 'react';
import arrayMutators from 'final-form-arrays';
import { noop, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { validations } from '@unite-us/app-components';
import PaymentsSupportingDocuments from 'common/form/Interactions/components/PaymentsSupportingDocuments';

const EnrollmentsSupportingDocuments = ({
  onSubmit,
  requiredMetaFieldFiles,
  submitTriggered,
}) => {
  const initialMetadata = requiredMetaFieldFiles?.map(({ field }) => ({ field })) ?? [];
  return (
    <Form
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
      initialValues={{ metadata: initialMetadata }}
      initialValuesEqual={(oldFields, newFields) => isEqual(
        oldFields.metadata.map(({ field }) => ({ field })),
        newFields.metadata.map(({ field }) => ({ field })),
      )}
    >
      {({
        handleSubmit,
      }) => {
        useEffect(() => {
          if (submitTriggered) {
            handleSubmit();
          }
        }, [submitTriggered]);

        return (
          <form id="supporting-documents-form">
            <FieldArray name="metadata">
              {({ fields }) => fields.map((
                name,
                index,
              ) => {
                const requiredMetaFieldFile = requiredMetaFieldFiles.find(
                  (file) => fields.value[index].field === file.field,
                );
                if (!requiredMetaFieldFile) return null;
                const fieldName = requiredMetaFieldFile?.field;
                const isRequired = !!requiredMetaFieldFile?.validations?.required;
                return (
                  <div className="payments-supporting-documents-wrapper pb-6" key={fieldName}>
                    <Field name={`${name}.value`} validate={isRequired ? validations.isRequired : noop}>
                      {({ input, meta }) => (
                        <PaymentsSupportingDocuments
                          label={requiredMetaFieldFile?.label}
                          fieldName={fieldName}
                          field={input}
                          required={isRequired}
                          invalid={meta.invalid}
                          touched={meta.touched}
                        />
                      )}
                    </Field>
                  </div>
                );
              })}
            </FieldArray>
          </form>
        );
      }}
    </Form>
  );
};

EnrollmentsSupportingDocuments.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitTriggered: PropTypes.number.isRequired,
  requiredMetaFieldFiles: PropTypes.array.isRequired,
};

export default EnrollmentsSupportingDocuments;
