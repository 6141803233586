import React from 'react';

const NoRequestsExistLogo = () => (
  /* eslint-disable */
  <svg width="150px" viewBox="0 0 308.4 288" xmlns="http://www.w3.org/2000/svg">
    <circle cx="144" cy="144" r="144" fill="#EAF0F7" />
    <circle cx="257" cy="52.2" r="51.4" fill="#fff" />
    <circle cx="257" cy="52.2" r="45.4" fill="#2C405A" />
    <g transform="translate(-170.1 -179.5)">
      <path d="m427.4 208.2c12.7 0 17.8 12.6 17.8 24 0 11.3-5.1 24.1-17.8 24.1-12.8 0-17.8-12.8-17.8-24.1-0.1-11.3 5-24 17.8-24zm0 5.2c-8.9 0-11.9 9.6-11.9 18.9s3 18.9 11.9 18.9 11.9-9.6 11.9-18.9-3.1-18.9-11.9-18.9z" fill="#fff" />
    </g>
    <polygon points="262.3 173.2 128.4 250.5 25.5 191 159.3 113.7" fill="#D0DEEF" />
    <polygon points="268.1 134 121.9 218.4 9.5 153.5 9.5 147.3 155.7 69.1 268.1 127.8" fill="#2F71F4" />
    <polygon points="268.1 127.8 121.9 212.2 9.5 147.3 155.7 62.9" fill="#fff" />
    <line x1="200.9" x2="155.9" y1="103.5" y2="77.5" fill="none" stroke="#D0DEEF" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="5" />
    <line x1="188.9" x2="143.9" y1="109.6" y2="83.6" fill="none" stroke="#D0DEEF" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="5" />
    <line x1="218.5" x2="133.1" y1="140.3" y2="91" fill="none" stroke="#D0DEEF" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="5" />
    <line x1="194.3" x2="109.2" y1="154.9" y2="105.7" fill="none" stroke="#D0DEEF" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="5" />
    <line x1="127.7" x2="105" y1="191.6" y2="178.3" fill="none" stroke="#D0DEEF" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="5" />
    <line x1="138.5" x2="115.9" y1="185.5" y2="172.2" fill="none" stroke="#D0DEEF" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="5" />
    <line x1="181.6" x2="95.4" y1="161.9" y2="112.2" fill="none" stroke="#D0DEEF" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="5" />
    <line x1="168.3" x2="82.2" y1="169.5" y2="119.7" fill="none" stroke="#D0DEEF" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="5" />
  </svg>
  /* eslint-enable */
);

export default NoRequestsExistLogo;
