import _ from 'lodash';

function getSubNetworkOptions(superNetwork = {}) {
  const subNetworkOptions = _.chain(superNetwork)
    .get('sub_networks', [])
    .map((network = {}) => ({
      label: network.name,
      value: network.id,
    }))
    .value();

  return [{ label: superNetwork.name, value: superNetwork.id }, ...subNetworkOptions];
}

export default getSubNetworkOptions;
