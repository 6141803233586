import { isEmpty, uuCompactArrayOrObject, noop } from 'lodash';
import { isHttpError } from 'src/common/utils/Error';
import { anyFieldChanged } from 'src/common/display/NewItemStepper/utils';

export default function createUpdateDeletePaymentsInsurances ({
  initialPaymentsIds = [],
  values = {},
  contactId,
  groupId,
  createInsuranceEntry = noop,
  editInsuranceEntry = noop,
  deleteInsuranceEntry = noop,
  paymentsEventTracking = noop,
  showNotification = true,
  insuranceFields = [],
}) {
  const currentPaymentsIds = values.insurance.map((insurance) => insurance.id);
  const currentPaymentsIdSet = new Set(currentPaymentsIds);
  // creates payments insurance object for editing insurance ID lookup
  const currentPaymentsObject = values.insurance.reduce((idsMap, insurance) => (
    { ...idsMap, [insurance.id]: insurance }), {});

  // identifies insurances for delete, add, edit
  const deletedIds = initialPaymentsIds.filter(({ id }) => !currentPaymentsIdSet.has(id));
  const editedInsuranceIds = initialPaymentsIds.filter(({ id }) => {
    const insuranceField = insuranceFields.find((insurance) => insurance.id.value === id);

    return anyFieldChanged(insuranceField) && currentPaymentsIdSet.has(id);
  });
  const isNewInsurance = (insurance) => isEmpty(insurance.id) && !isEmpty(insurance.plan);
  const addedInsurances = values.insurance.filter((insurance) => isNewInsurance(insurance));

  // Delete Insurances
  if (!isEmpty(deletedIds)) {
    deletedIds.map(({ id, plan_type }) => deleteInsuranceEntry(id, contactId, groupId, showNotification).then((res) => {
      if (!isHttpError(res)) {
        paymentsEventTracking([{ id, plan_type }]);
      }
    }));
  }

  // Add New Insurances
  if (!isEmpty(addedInsurances)) {
    addedInsurances.map((insurance) => {
      const compactInsuranceValues = uuCompactArrayOrObject(insurance);
      return createInsuranceEntry({
        contactId,
        groupId,
        values: { insurance: compactInsuranceValues },
        type: 'new',
        showNotification,
      }).then((res) => {
        if (!isHttpError(res)) {
          paymentsEventTracking([insurance]);
        }
      });
    });
  }

  // Edit Current Insurances
  if (!isEmpty(editedInsuranceIds)) {
    editedInsuranceIds.map(({ id: insuranceId, plan_type }) => {
      const compactInsuranceValues = uuCompactArrayOrObject(currentPaymentsObject[insuranceId]);
      return editInsuranceEntry({
        contactId,
        groupId,
        values: { insurance: compactInsuranceValues },
        insuranceId,
        showNotification,
      }).then((res) => {
        if (!isHttpError(res)) {
          paymentsEventTracking([{ insuranceId, plan_type }]);
        }
      });
    });
  }
}
