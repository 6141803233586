import { useState } from 'react';

const useInvoiceSorting = ({ resetPagination }) => {
  const [{ sortBy, sortDirection }, setSort] = useState({ sortBy: 'updated_at', sortDirection: 'desc' });
  return {
    sortBy,
    sortDirection,
    setSort: (sort) => {
      setSort(sort);
      resetPagination();
    },
  };
};

export default useInvoiceSorting;
