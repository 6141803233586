import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/app-components';

const TableHeaderColumnSortableIcon = ({ sortDirection }) => {
  if (['asc', 'desc'].includes(sortDirection)) {
    return (
      <Icon
        className="table-header-column-sortable-icon"
        icon="ChevronArrow"
        direction={sortDirection === 'asc' ? 'up' : 'down'}
        size={18}
      />
    );
  }

  return (<span />);
};

TableHeaderColumnSortableIcon.propTypes = {
  sortDirection: PropTypes.string.isRequired,
};

export default TableHeaderColumnSortableIcon;
