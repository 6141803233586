import React from 'react';
import classNames from 'classnames';

const DISPLAY_NAME = 'note-delete-content';

const NoteDeleteContent = () => (
  <div className={classNames(DISPLAY_NAME)}>
    <p className="mb-4">
      {'If you delete this note it will no longer be visible on the client face sheet.'}
    </p>
    <p>
      Are you sure you want to delete?
    </p>
  </div>
);

export default NoteDeleteContent;
