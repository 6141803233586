import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class EnterLeaveViewport extends Component {
  constructor(props) {
    super(props);

    this.attachListener = this.attachListener.bind(this);
    this.checkVisibility = this.checkVisibility.bind(this);
    this.detachListener = this.detachListener.bind(this);
    this.onScroll = this.onScroll.bind(this);

    this.throttledOnScroll = _.throttle(this.onScroll, props.throttle, { leading: true });

    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    if (this.props.scrollElement && !this.props.disabled) {
      this.attachListener(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.scrollElement !== nextProps.scrollElement && !nextProps.disabled) {
      this.attachListener(nextProps);
    }
    if (this.props.disabled && !nextProps.disabled) {
      this.attachListener(nextProps);
    }
    if (!this.props.disabled && nextProps.disabled) {
      this.detachListener();
    }
  }

  componentWillUnmount() {
    this.detachListener();
  }

  onScroll() {
    this.checkVisibility();
  }

  checkVisibility() {
    const {
      childElement,
      offset,
      scrollElement,
    } = this.props;
    if (!childElement || !scrollElement) {
      return this.setState({ visible: false });
    }
    const visible = childElement.offsetTop < (scrollElement.clientHeight + scrollElement.scrollTop + offset);
    if (!this.state.visible && visible && _.isFunction(this.props.onEnter)) {
      this.props.onEnter();
    }
    if (this.state.visible && !visible && _.isFunction(this.props.onLeave)) {
      this.props.onLeave();
    }
    this.setState({ visible });
    return false;
  }

  attachListener(props) {
    if (props.scrollElement && !props.disabled) {
      const computedStyle = window.getComputedStyle(props.scrollElement);

      if (!_.includes(['relative', 'absolute', 'fixed'], _.get(computedStyle, 'position', ''))) {
        throw new TypeError('The scrollElement should be positioned. \'relative\', \'absolute\' or \'fixed\'');
      }

      this.checkVisibility();
      props.scrollElement.addEventListener('scroll', this.throttledOnScroll);
    }
  }

  detachListener() {
    this.throttledOnScroll.cancel();
    if (this.props.scrollElement) {
      this.props.scrollElement.removeEventListener('scroll', this.throttledOnScroll);
    }
  }

  render() {
    const {
      children,
      scrollElement,
    } = this.props;

    if (!scrollElement) {
      return null;
    }

    return (
      <div>
        {children}
      </div>
    );
  }
}

EnterLeaveViewport.propTypes = {
  childElement: PropTypes.object,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
  offset: PropTypes.number.isRequired,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
  scrollElement: PropTypes.object,
  throttle: PropTypes.number.isRequired,
};

EnterLeaveViewport.defaultProps = {
  disabled: false,
  offset: 100,
  throttle: 400,
};

export default EnterLeaveViewport;
