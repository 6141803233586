import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Table = ({ children, className }) => (
  <table className={cx('min-w-full divide-y divide-solid divide-dark-fill-blue', className)}>
    {children}
  </table>
);

Table.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Table.defaultProps = {
  className: '',
};

export default Table;
