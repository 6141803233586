import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SingleSelectWithCustomValue } from 'common/form';
import { oonGroupValidations } from 'src/components/Cases/utils';
import { getGroupOptions, getProgramAndProviderOptions } from 'src/components/Referrals/ReferralGroupsPrograms/utils';
import { disabledCustomOptions } from 'src/components/Referrals/ReferralFormFields/OONGroupsSelector/utils';
import { sortGroupsByDistance } from 'src/components/Groups/utils';
import { connect } from 'react-redux';
import { paginateNetworkGroups, programBasedSearchSelector } from 'src/common/utils/FeatureFlags/flags';

export class CaseOONGroupSelect extends Component {
  constructor(props) {
    super(props);

    this.removeGroupField = this.removeGroupField.bind(this);
  }

  removeGroupField(selected) {
    const { index } = this.props;
    this.props.removeGroupField(selected, index);
  }

  render() {
    const {
      allowEmpty,
      canPaginateNetworkGroups,
      useProgramBasedSearch,
      debouncedSearchNetworkGroups,
      groupField,
      index,
      isOONCase,
      oonGroupFields,
      registerField,
      selectedGroupIds,
      suggestedGroups,
      originCoordinates,
      shouldSearchOnChange,
    } = this.props;

    const options = useProgramBasedSearch ?
    [
      ...getProgramAndProviderOptions(suggestedGroups, selectedGroupIds, originCoordinates, canPaginateNetworkGroups),
      ...disabledCustomOptions(oonGroupFields, useProgramBasedSearch),
    ] :
    [
      ...getGroupOptions({
        canPaginateNetworkGroups,
        groups: suggestedGroups,
        originCoordinates,
        selectedGroupIds,
      }),
      ...disabledCustomOptions(oonGroupFields),
    ];

    let suggestedGroupsSorted;
    if (!canPaginateNetworkGroups) {
      suggestedGroupsSorted = sortGroupsByDistance(options, originCoordinates);
    }

    let placeholderText = '';
    if (canPaginateNetworkGroups) {
      placeholderText = useProgramBasedSearch ? 'Choose a program' : 'Choose an organization';
    }

    return (
      <div className="case-oon-group-select">
        {
          <SingleSelectWithCustomValue
            debouncedSearchNetworkGroups={debouncedSearchNetworkGroups}
            disableCustomValue={useProgramBasedSearch}
            canPaginateNetworkGroups={canPaginateNetworkGroups}
            id={`select-field-oon-group-${index}`}
            field={groupField}
            searchGroups={this.searchGroups}
            label="oon-group-select"
            hideLabel
            labelKey="displayName"
            valueKey="id"
            oonGroupFields={oonGroupFields}
            options={canPaginateNetworkGroups ? options : suggestedGroupsSorted}
            inline={false}
            onChange={this.removeGroupField}
            registerField={registerField}
            placeholder={placeholderText}
            resetOptions={false}
            searchPlaceholderValue={
              canPaginateNetworkGroups ?
                `Displaying closest 50 - Search for more${useProgramBasedSearch ? '' : ' or create custom...'}` :
                ''
            }
            shouldSearchOnChange={shouldSearchOnChange}
            validations={[
              { func: oonGroupValidations(allowEmpty, isOONCase) },
            ]}
          />
        }
      </div>
    );
  }
}

CaseOONGroupSelect.propTypes = {
  allowEmpty: PropTypes.bool,
  canPaginateNetworkGroups: PropTypes.bool.isRequired,
  useProgramBasedSearch: PropTypes.bool.isRequired,
  debouncedSearchNetworkGroups: PropTypes.func.isRequired,
  groupField: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  originCoordinates: PropTypes.array,
  isOONCase: PropTypes.bool,
  oonGroupFields: PropTypes.array.isRequired,
  registerField: PropTypes.func.isRequired,
  removeGroupField: PropTypes.func.isRequired,
  selectedGroupIds: PropTypes.array.isRequired,
  suggestedGroups: PropTypes.array.isRequired,
  shouldSearchOnChange: PropTypes.bool,
};

CaseOONGroupSelect.defaultProps = {
  allowEmpty: false,
  isOONCase: false,
  originCoordinates: [],
  shouldSearchOnChange: true,
};

const mapStateToProps = (state) => ({
  canPaginateNetworkGroups: paginateNetworkGroups(state),
  useProgramBasedSearch: programBasedSearchSelector(state),
});

export default connect(mapStateToProps)(CaseOONGroupSelect);
