import {
  ASSESSMENTS_SET_FETCHING,
  ASSESSMENTS_UNSET_FETCHING,
  FETCH_ASSESSMENTS,
} from 'actions';

const defaultState = {
  assessments: [],
  isFetching: false,
};

export default function assessmentsReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case ASSESSMENTS_SET_FETCHING: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case ASSESSMENTS_UNSET_FETCHING: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case FETCH_ASSESSMENTS: {
      return {
        ...state,
        assessments: action.payload.data.data,
      };
    }

    default:
      return state;
  }
}
