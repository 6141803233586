import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'common/utils/browserHistory';
import { validateReduxForm } from 'common/form';
import {
  BaseCard,
  BaseCardBody,
  Button,
  CheckBoxField,
} from '@unite-us/ui';
import { UULogoWithText } from '@unite-us/client-utils';
import acceptAgreement from 'src/actions/Agreement/acceptAgreement';
import ParticipationAgreement from './components/ParticipationAgreement';
import './stylesheets/eula.scss';

const fields = ['participationAccepted'];

class UniteUsParticipationAgreement extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    const { group: { employeeId, provider } } = this.props;
    return this.props.acceptAgreement(provider, employeeId)
      .then(() => this.props.resetForm)
      .then(() => {
        if (this.props.onRedirect) {
          this.props.onRedirect();
        } else {
          browserHistory.push('/');
        }
      });
  }

  render() {
    const {
      fields: {
        participationAccepted,
      },
      group,
      handleSubmit,
      submitting,
    } = this.props;

    const agreementIsAccepted = (participationAccepted.value);
    const checkBoxLabelText = `I hereby acknowledge that I have read, understand, and agree to the Network Participation Agreement above on behalf of ${group.name}.`; /* eslint-disable-line max-len */

    return (
      <div className="unite-us-participation-agreement container">
        <div className="unite-us-participation-agreement__logo">
          <UULogoWithText />
        </div>

        <div className="unite-us-participation-agreement__content">
          <BaseCard>
            <BaseCardBody withPadding>
              <ParticipationAgreement group={group} />

              <form
                className="unite-us-participation-agreement__content-eula-confirmation"
                onSubmit={handleSubmit(this.onSubmit)}
              >
                <CheckBoxField
                  field={participationAccepted}
                  id="eulaAccepted"
                  label={checkBoxLabelText}
                  showError={false}
                  showHint={false}
                />

                <div className="unite-us-participation-agreement__content-submit-button">
                  <Button
                    disabled={!agreementIsAccepted || submitting}
                    id="eula-agree-btn"
                    label="I Agree"
                    onClick={handleSubmit(this.onSubmit)}
                    primary
                  />
                </div>
              </form>
            </BaseCardBody>
          </BaseCard>
        </div>
      </div>
    );
  }
}

UniteUsParticipationAgreement.propTypes = {
  acceptAgreement: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  onRedirect: PropTypes.func,
};

const initialValues = {
  participationAccepted: false,
};

function mapStateToProps(state) {
  return {
    initialValues,
    user: state.user,
  };
}

export default validateReduxForm(
  {
    destroyOnUnmount: true,
    fields,
    form: 'unite-us-participation-agreement',
  },
  mapStateToProps,
  { acceptAgreement },
)(UniteUsParticipationAgreement);
