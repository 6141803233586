import Notifier from 'common/helpers/Notifier';
import { CLOSE_REFERRAL_SENT, CLOSE_REFERRAL_RECEIVED, CLOSE_SERVICE_CASE } from 'actions';
import { coreApi } from 'src/api/config';
import createNote from 'src/actions/Case/Contact/Group/createNote';
import moment from 'moment';
import { get } from 'lodash';
import { isHttpSuccess } from 'src/api/utils/httpStatus';

// if there are multiple referrals on a case, you are not supposed to close the case with an outcome
// instead, you flag the case
export const archiveReferral = async (referralId) => {
  const response = await coreApi.updateRecord('referral', referralId, {
    flagged_for_sender: false,
  });
  if (!response || !isHttpSuccess(response.status)) {
    Notifier.dispatch(
      400,
      'Referral cannot be closed',
    );
    return false;
  }

  Notifier.dispatch(201, 'Referral Closed');
  return true;
};

function closeReferral(groupId, referralId, closing, referral, isCoordinationCenter, closeInboundReferralsEnabled) {
  return async (dispatch) => {
    const case_state = get(referral, 'case.state');
    try {
      await dispatch(createNote({
        interaction: {
          note: closing.note,
          occurred_on: closing.exited_at,
          category: 'general',
          is_note_private: case_state === 'draft' ? 'private' : 'public',

        },
        subjectType: 'case',
        subject: get(referral, 'case.id'),
        opts: { sendSuccessNotification: false },
        context: 'closed',
      }));
      const isReferralReceiver = get(referral, 'receiving_provider.id') === groupId;
      const caseResponse = await coreApi.updateRecord('case', get(referral, 'case.id'), {
        outcome: closing.outcome_id,
        ...(get(referral, 'case.opened_date') && referral.state !== 'off_platform' ?
          { closed_date: moment.unix(closing.exited_at).utc().format('YYYY-MM-DD') } : {}),
        ...(isCoordinationCenter && closeInboundReferralsEnabled && isReferralReceiver ?
           { provider: { id: referral.receiving_provider.id } } : {}),
      });
      const payload = { data: { data: referral } };

      dispatch({
        type: get(payload, 'data.data.referred_by_group.id', '') === groupId ?
          CLOSE_REFERRAL_SENT :
          CLOSE_REFERRAL_RECEIVED,
        payload,
      });
      dispatch({
        type: CLOSE_SERVICE_CASE,
        payload: caseResponse,
        contactId: get(referral, 'person.id'),
      });
      Notifier.dispatch(201, 'Referral Successfully Closed');
      return payload;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}

export default closeReferral;
