import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Stepper } from 'common/TailwindComponents';

const EnrollmentStepperWrapper = ({
  step,
  goToStep,
}) => {
  const [steps, setSteps] = useState([
    {
      id: '01',
      name: 'Review client details',
      onClick: () => goToStep(0),
      status: 'upcoming',
    },
    {
      id: '02',
      name: 'Enrollment application',
      onClick: () => goToStep(1),
      status: 'upcoming',
    },
    {
      id: '03',
      name: 'Review request',
      status: 'upcoming',
    },
  ]);

  useEffect(() => {
    const newSteps = steps.map((s, index) => {
      let status = 'upcoming';
      if (index === step) {
        status = 'current';
      }
      if (index < step) {
        status = 'complete';
      }
      return (
        {
          ...s,
          status,
        }
      );
    });
    setSteps(newSteps);
  }, [step]);

  return (
    <Stepper steps={steps} />
  );
};

EnrollmentStepperWrapper.propTypes = {
  step: PropTypes.number.isRequired,
  goToStep: PropTypes.func.isRequired,
};

export default EnrollmentStepperWrapper;
