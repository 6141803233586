import {
  DASHBOARD_SET_ACTIONABLES_IS_FETCHING,
  DASHBOARD_FETCH_ACTIONABLES,
  DASHBOARD_FETCH_ASSISTANCE_REQUESTS,
  DASHBOARD_FETCH_CASES,
  DASHBOARD_FETCH_CONTACTS,
  DASHBOARD_FETCH_REFERRALS,
  DASHBOARD_UNSET_ACTIONABLES_IS_FETCHING,
  REMOVE_DELETED_DRAFT_REFERRAL,
  RESET_DASHBOARD,
  SET_DASHBOARD_FETCHING,
  SET_DASHBOARD_REFETCH,
  UNSET_DASHBOARD_FETCHING,
  UNSET_REFETCH_ACTIONABLES,
  USER_LOGOUT,
  WS_DECREMENT_COUNT,
  WS_HANDLE_MESSAGE,
  WS_INCREMENT_COUNT,
  REASSIGN_REFERRAL_PROGRAM,
  DASHBOARD_FETCH_SCREENINGS,
} from 'actions';
import socketHandler from 'src/components/Dashboard/utils/socketHandler';
import _ from 'lodash';
import moment from 'moment';

const defaultTarget = {
  data: [
    { data: [], paging: {}, time: 0 },
  ],
  currentPage: 1,
  filters: {},
  isFetching: false,
  cancel: null,
};

const defaultData = [
  {
    data: [],
    paging: {},
    time: 0,
  },
];

const defaultState = {
  actionables: {},
  allCases: defaultTarget,
  assistanceRequests: defaultTarget,
  clients: defaultTarget,
  closedAssistanceRequests: defaultTarget,
  closedCases: defaultTarget,
  closedReferrals: defaultTarget,
  closedInboundReferrals: defaultTarget,
  draftReferrals: defaultTarget,
  intakes: defaultTarget,
  isActionablesFetching: false,
  newReferrals: defaultTarget,
  openCases: defaultTarget,
  pendingConsentReferrals: defaultTarget,
  processedAssistanceRequests: defaultTarget,
  recalledReferrals: defaultTarget,
  referralsInReview: defaultTarget,
  refetch: false,
  refetchActionables: false,
  rejectedReferrals: defaultTarget,
  screeningsAll: defaultTarget,
  sentReferrals: defaultTarget,
  sentReferralsAcceptedClosed: defaultTarget,
  sentReferralsAcceptedOpen: defaultTarget,
  sentReferralsAll: defaultTarget,
  sentReferralsInReview: defaultTarget,
  sentReferralsPending: defaultTarget,
  sentReferralsPendingConsent: defaultTarget,
};

function cancelPreviousRequestIfExists(state, action) {
  const cancel = _.get(state, [action.target, 'cancel'], null);
  if (cancel !== null) {
    cancel();
  }
}

function updateState(state, action) {
  const currentData = _.get(state, action.target, {});
  const currentPage = _.get(action, 'payload.data.paging.current_page', 1);
  let newData = _.assign([], currentData.data);
  if (!_.isEqual(_.get(currentData, 'filters', {}), action.filters)) {
    newData = [{ data: [], paging: {} }];
  }
  newData[currentPage - 1] = {
    data: action.payload.data.data,
    paging: action.payload.data.paging,
    time: moment().unix(),
  };
  return _.assign({}, state, {
    [action.target]: {
      data: newData,
      filters: action.filters,
      isFetching: state[action.target].isFetching,
      currentPage: action.payload.data.paging.current_page,
    },
  });
}

function updateFetching(state, action, unset = false) {
  const currentData = _.get(state, action.target, {});
  const isFetching = !unset;
  const reset = isFetching && !_.isEqual(currentData.filters, action.filters);

  return {
    ...state,
    [action.target]: {
      data: reset ? defaultData : currentData.data,
      filters: currentData.filters,
      isFetching,
      cancel: unset ? null : action.cancel,
      currentPage: reset ? 1 : _.get(currentData, 'currentPage', 1),
    },
  };
}

function doRecursiveAssignAt(state, value, paths) {
  if (_.isEmpty(_.tail(paths))) {
    return _.assign({}, state, { [_.head(paths)]: value });
  }

  return _.assign({}, state, {
    [_.head(paths)]: doRecursiveAssignAt(
      _.get(state, _.head(paths), {}),
      value,
      _.tail(paths),
    ),
  });
}

function recursiveAssignAt(state, value, paths) {
  if (_.isString(paths)) {
    return doRecursiveAssignAt(state, value, _.split(paths, '.'));
  }

  return doRecursiveAssignAt(state, value, paths);
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case SET_DASHBOARD_FETCHING:
      cancelPreviousRequestIfExists(state, action);
      return updateFetching(state, action);
    case UNSET_DASHBOARD_FETCHING:
      return updateFetching(state, action, true);
    case DASHBOARD_FETCH_SCREENINGS:
    case DASHBOARD_FETCH_REFERRALS:
    case DASHBOARD_FETCH_ASSISTANCE_REQUESTS:
    case DASHBOARD_FETCH_CASES:
    case DASHBOARD_FETCH_CONTACTS:
      return {
        ...updateState(state, action),
        refetch: false,
      };
    case USER_LOGOUT:
    case RESET_DASHBOARD:
      return { ...defaultState };
    case DASHBOARD_FETCH_ACTIONABLES:
      if (action.updated < state.actionablesUpdated) {
        return state;
      }

      return {
        ...state,
        actionables: action.payload.data.data,
        actionablesUpdated: action.updated,
      };
    case UNSET_REFETCH_ACTIONABLES:
      return { ...state, refetchActionables: false };
    case SET_DASHBOARD_REFETCH:
      return { ...state, refetch: true, refetchActionables: true };
    case WS_INCREMENT_COUNT:
      return recursiveAssignAt(
        state,
        _.get(state, `actionables.${action.target}`, 0) + 1,
        `actionables.${action.target}`,
      );
    case WS_DECREMENT_COUNT:
      return recursiveAssignAt(
        state,
        _.get(state, `actionables.${action.target}`, 0) - 1,
        `actionables.${action.target}`,
      );
    case WS_HANDLE_MESSAGE:
      return socketHandler(state, action.payload, action.currentSection, action.currentGroup);
    case DASHBOARD_SET_ACTIONABLES_IS_FETCHING:
      return { ...state, isActionablesFetching: true };
    case DASHBOARD_UNSET_ACTIONABLES_IS_FETCHING:
      return { ...state, isActionablesFetching: false };
    case REMOVE_DELETED_DRAFT_REFERRAL: {
      const id = action.draftId;

      if (id) {
        const currentIndex = state.draftReferrals.currentPage - 1;
        const data = _.get(state.draftReferrals, `data[${currentIndex}].data`, []);
        const updatedData = _.reject(data, { id });

        return {
          ...state,
          draftReferrals: {
            ...state.draftReferrals,
            data: {
              ...state.draftReferrals.data,
              [currentIndex]: {
                ...state.draftReferrals.data[currentIndex],
                data: updatedData,
              },
            },
          },
        };
      }

      return state;
    }
    case REASSIGN_REFERRAL_PROGRAM: {
      const referral = action.payload.data.data;
      const currentIndex = state.newReferrals.currentPage - 1;
      const data = _.get(state.newReferrals, `data[${currentIndex}].data`, []);

      const updatedData = _.map(data, (dataReferral) => (
        _.isEqual(dataReferral.id, referral.id) ? referral : dataReferral
      ));

      const result = {
        ...state,
        newReferrals: {
          ...state.newReferrals,
          data: _.map(state.newReferrals.data, (currentReferrals, index) => {
            if (index === currentIndex) {
              return { ...currentReferrals, data: updatedData };
            }
            return currentReferrals;
          }),
        },
      };

      return result;
    }
    default:
      return state;
  }
}
