import React from 'react';
import PropTypes from 'prop-types';
import { SearchableSelectField } from 'components/Backoffice/form/SearchableSelectField';
import { SelectField } from 'components/Backoffice/form/SelectField';
import { SearchableField } from 'components/Backoffice/form/SearchableField';
import { AUTHORIZATION_STATUSES } from 'src/common/Status/authorizationConstants';
import {
  Button,
} from '@unite-us/ui';

const FilterSelectors = ({
  filterValues,
  filterOptions,
  resetFilters,
  setRequestIdFilter,
  setClientNameFilter,
  setStatusFilter,
  setContractedProgramFilter,
  setContractedProgramName,
  authorizationStatus,
}) => {
  const clearFilters = () => {
    resetFilters();
  };

  return (
    <div className="flex items-start items-center space-x-4">
      <SearchableField
        className="w-64"
        label="Request ID"
        onChange={({ value }) => {
          setRequestIdFilter(value);
        }}
        searchQuery={filterValues.requestId}
        onInputChange={setRequestIdFilter}
        placeholder="Search by ID"
        clearable
      />
      <SearchableField
        className="w-64"
        label="Client Name"
        onChange={({ value }) => {
          setClientNameFilter(value);
        }}
        searchQuery={filterValues.clientName}
        onInputChange={setClientNameFilter}
        placeholder="Search by client"
        clearable
      />
      {authorizationStatus !== AUTHORIZATION_STATUSES.open &&
        authorizationStatus !== AUTHORIZATION_STATUSES.rejected && (
        <SelectField
          className="w-64"
          label="Status"
          placeholder="Choose Status"
          value={filterValues.status ?? ''}
          onChange={({ value }) => {
            setStatusFilter(value);
          }}
          options={filterOptions.status}
          clearable
        />
      )}
      <SearchableSelectField
        className="w-80"
        label="Contracted Program"
        placeholder="Choose program"
        options={filterOptions.contractedProgram}
        onChange={({ value }) => {
          setContractedProgramName(filterOptions?.contractedProgram?.find((option) => option.value === value)?.label);
          setContractedProgramFilter(value || undefined);
        }}
        value={filterValues.contractedProgram}
        onInputChange={setContractedProgramName}
        inputValue={filterValues.contractedProgramName}
        truncateOptions
        clearable
      />
      <Button
        id="clear-all-filters-auths-btn"
        onClick={clearFilters}
        label="Clear all"
        className="mt-5"
        link
      />
    </div>
  );
};

FilterSelectors.propTypes = {
  filterValues: PropTypes.shape({
    requestId: PropTypes.string,
    clientName: PropTypes.string,
    status: PropTypes.string,
    contractedProgram: PropTypes.string,
    contractedProgramName: PropTypes.string,
  }).isRequired,
  filterOptions: PropTypes.shape({
    requestId: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    clientName: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    status: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    contractedProgram: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  resetFilters: PropTypes.func.isRequired,
  setRequestIdFilter: PropTypes.func.isRequired,
  setClientNameFilter: PropTypes.func.isRequired,
  setStatusFilter: PropTypes.func.isRequired,
  setContractedProgramFilter: PropTypes.func.isRequired,
  setContractedProgramName: PropTypes.func.isRequired,
  authorizationStatus: PropTypes.string.isRequired,
};

export default FilterSelectors;
