import React from 'react';
import PropTypes from 'prop-types';
import { TableRowColumn, TableRow } from '@unite-us/ui';
import { get, map } from 'lodash';
import { findPrimaryOrFirst } from 'src/components/Contacts/utils';
import PhoneNumberDisplay from 'common/display/PhoneNumberDisplay';

const UserRow = ({ user, userGroupNames }) => {
  const primaryPhone = findPrimaryOrFirst(get(user, 'phone_numbers'));
  const email = get(user, 'email');

  return (
    <TableRow className="user-row" clickable={false}>
      <TableRowColumn className="name">{get(user, 'full_name')}</TableRowColumn>
      <TableRowColumn className="organizations">
        <ul className="user-row__org-list list-unstyled">
          {
            map(userGroupNames, (name, index) => (
              <li
                className="user-row__org"
                key={`${name}-$${index}`}
              >
                {name}
              </li>
            ))
          }
        </ul>
      </TableRowColumn>
      <TableRowColumn className="phone">
        {primaryPhone && <PhoneNumberDisplay item={primaryPhone} shortDisplay />}
      </TableRowColumn>
      <TableRowColumn className="email">
        {
          email && (
            <a href={`mailto:${email}`} title={email}>
              {email}
            </a>
          )
        }
      </TableRowColumn>
    </TableRow>
  );
};

UserRow.propTypes = {
  user: PropTypes.shape({
    full_name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  userGroupNames: PropTypes.array,
};

export default UserRow;
