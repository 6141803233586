import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { validateReduxForm } from 'common/form';
import { Button } from '@unite-us/ui';
import { chain } from 'lodash';
import { destroyDropzone } from 'common/form/FileUpload/actions/FileUpload';
import UploadAndAttachDocumentsFormFields from './UploadAndAttachDocumentsFormFields';
import { getDropzoneDocuments } from '../utils';

class UploadAndAttachDocumentsForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  onSubmit() {
    const { dropzoneDocuments, fields } = this.props;
    const attachedDocuments = chain(fields.attachableDocuments)
      .filter((field) => field.attached.value === true)
      .map((field) => field.document.value)
      .value();

    return Promise.resolve(this.props.onAttach({
      attachedDocuments,
      uploadedDocuments: dropzoneDocuments,
    })).then(this.resetForm);
  }

  onCancel() {
    return Promise.resolve(this.props.onCancel())
      .then(this.resetForm);
  }

  resetForm() {
    const { dropzoneName } = this.props;
    this.props.destroyDropzone(dropzoneName);
    this.props.resetForm();
  }

  render() {
    const {
      dropzoneName,
      fields,
      handleSubmit,
      hideActions,
      registerField,
      submitting,
      warningText,
    } = this.props;

    return (
      <form
        id="upload-and-attach-documents-form"
        className="upload-and-attach-documents-form content-with-actions"
        onSubmit={handleSubmit(this.onSubmit)}
      >
        <div className="content-container">
          <UploadAndAttachDocumentsFormFields
            dropzoneName={dropzoneName}
            fields={fields}
            registerField={registerField}
            warningText={warningText}
          />
        </div>
        {
          !hideActions && (
            <div className="actions">
              <span className="action-item">
                <Button
                  id="upload-cancel-btn"
                  label="Cancel"
                  onClick={this.onCancel}
                />
              </span>
              <span className="action-item">
                <Button
                  id="upload-submit-btn"
                  disabled={submitting}
                  label="Attach"
                  primary
                  type="submit"
                />
              </span>
            </div>
          )
        }
      </form>
    );
  }
}

UploadAndAttachDocumentsForm.propTypes = {
  destroyDropzone: PropTypes.func.isRequired,
  dropzoneDocuments: PropTypes.array.isRequired,
  dropzoneName: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hideActions: PropTypes.bool,
  onAttach: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  warningText: PropTypes.string,
};

UploadAndAttachDocumentsForm.defaultProps = {
  documents: [],
  hideActions: false,
};

function mapStateToProps(state, ownProps) {
  const { documents, dropzoneName } = ownProps;

  return {
    dropzoneDocuments: getDropzoneDocuments(state.fileupload, dropzoneName),
    initialValues: {
      attachableDocuments: documents.map((doc) => ({
        attached: false,
        document: doc,
      })),
    },
  };
}

const fields = [
  'attachableDocuments[].attached',
  'attachableDocuments[].document',
  'uploadedDocuments',
];
export default validateReduxForm({
  form: 'upload-and-attach-documents-form',
  fields,
}, mapStateToProps, {
  destroyDropzone,
})(UploadAndAttachDocumentsForm);
