import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import FeatureFlagContainer from 'common/utils/FeatureFlags/FeatureFlagContainer';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Spinner } from 'common/spinners';
import { browserHistory } from 'common/utils/browserHistory';
import Stepper from 'common/Stepper/Stepper';
import EligibilityAssessment from 'src/pages/facesheet/_id/eligibility/EligibilityAssessment';
import EligibilityConsent from 'src/pages/facesheet/_id/eligibility/EligibilityConsent';
import EligibilityOutreach from 'src/pages/facesheet/_id/eligibility/EligibilityOutreach';
import {
  useInvalidateQueries,
} from 'src/api/APIHooks';
import {
  USE_MY_SCREENINGS_KEY,
  USE_TEMPLATE_V2,
  useElAssessmentTemplateId,
  updateAssessment,
} from 'src/pages/facesheet/_id/eligibility/hooks/eligibilityApi';
import {
  useElAssessment,
  // EL_ASSESSMENT_QUERY_KEYS
} from 'src/pages/facesheet/_id/eligibility/hooks/useEligibilityAssessments';

const NewAssessment = (props) => {
  const {
    contactId,
    employeeId,
  } = props;
  const mode = get(props, 'route.mode');
  const paramAssessmentId = get(props, 'params.assessment_id');
  const paramStep = get(props, 'params.step');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const invalidateQueries = useInvalidateQueries();
  const templateId = useElAssessmentTemplateId();
  const { data: assessment, isFetching } = useElAssessment(
    paramAssessmentId,
    { enabled: Boolean(paramAssessmentId) },
  );
  const outreach_status = get(assessment, 'data.screen.outreach_status');

  const steps = [
    {
      title: 'Outreach Attempt',
      content: null,
      onStepClick: () => {
        browserHistory.push(`/facesheet/${contactId}/eligibility/new/${paramAssessmentId}/outreach`);
        setStep(0);
      },
    },
    {
      title: 'Consent',
      content: null,
      stepDisabled: (currentStep) => currentStep < 1,
      onStepClick: () => {
        browserHistory.push(`/facesheet/${contactId}/eligibility/new/${paramAssessmentId}/consent`);
        setStep(1);
      },
    },
    {
      title: 'Eligibility Assessment',
      content: null,
      stepDisabled: (currentStep) => currentStep < 2,
      onStepClick: () => setStep(2),
    },
  ];
  let stepperRef = useRef();

  const goToStep = (s) => {
    if (!stepperRef?.setCurrentStep) { return; }
    stepperRef.setCurrentStep(s);
    setStep(s);
  };

  const answerConsent = async (acceptedConsent) => {
    if (acceptedConsent) {
      const response = await updateAssessment({
        personId: contactId,
        employeeId,
        status: 'draft',
        // answers,
        related_screen_id: get(assessment, 'data.screen.related_screen_id', null),
        outreach_status: 'success',
        templateId,
        consent: 'accepted',
        assessmentId: paramAssessmentId,
      });
      if (!get(response, 'data.screen.id')) { return; }
      goToStep(2);
      return;
    }
    browserHistory.push(`/facesheet/${contactId}/eligibility/all`);
    return;
  };

  useEffect(() => {
    if (mode !== 'edit') { return; }
  }, [mode]);

  useEffect(() => {
    if (!paramStep || !assessment) { return; }
    const hasAcceptedConsent = get(assessment, 'data.screen.consent') === 'accepted';
    if (!outreach_status || outreach_status === 'declined') {
      goToStep(0);
      return;
    }

    if (step !== 1 && paramStep === 'consent') {
      goToStep(1);
    }
    if (step !== 2 && paramStep === 'assessment') {
      if (hasAcceptedConsent) {
        goToStep(2);
      } else {
        goToStep(1);
      }
    }
  }, [paramStep, assessment]);

  useEffect(() => {
    invalidateQueries(USE_MY_SCREENINGS_KEY);
    invalidateQueries(USE_TEMPLATE_V2);
  }, []);

  if (loading || isFetching) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="mb-8">
        <Stepper
          ref={(el) => { stepperRef = el; }}
          steps={steps}
          initialStep={step}
          stepStyle={{ flex: '0 1 auto' }}
          stepperStyle={{ backgroundColor: '#ecf5fd' }}
        />
      </div>
      {
        step === 0 ? (
          <EligibilityOutreach
            employeeId={employeeId}
            personId={contactId}
            answerConsent={answerConsent}
            setLoading={setLoading}
            screenId={paramAssessmentId}
          />
        ) : null
      }
      {
        step === 1 ? (
          <EligibilityConsent
            employeeId={employeeId}
            personId={contactId}
            answerConsent={answerConsent} // to deprecate
            nextStep={() => goToStep(2)}
            screenId={paramAssessmentId}
            setLoading={setLoading}
          />
        ) : null
      }
      {
        step === 2 ? (
          <EligibilityAssessment
            contactId={contactId}
            employeeId={employeeId}
            setLoading={setLoading}
            mode={mode}
            assessmentId={paramAssessmentId}
          />
        ) : null
      }
    </div>
  );
};

NewAssessment.propTypes = {
  employeeId: PropTypes.string.isRequired,
  contactId: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  employeeId: get(state, 'globalState.currentEmployee.id'),
  contactId: get(ownProps, 'params.id'),
});

export default withRouter(connect(mapStateToProps)(FeatureFlagContainer(NewAssessment)));
