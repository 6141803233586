import PropTypes from 'prop-types';
import React, { Component } from 'react';
import EditModal from 'common/modal/EditModal';
import _ from 'lodash';
import PersonalInformationForm from '../Forms/PersonalInformationForm';

const EDIT_PERSONAL_INFORMATION = 'editPersonalInformation';

class PersonalInformationDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
    };

    this.showEdit = this.showEdit.bind(this);
    this.hideEdit = this.hideEdit.bind(this);
  }

  showEdit() {
    this.setState({
      editing: true,
    });
  }

  hideEdit() {
    this.setState({
      editing: false,
    });
  }

  render() {
    const { employee } = this.props;

    const styles = this.state.editing ?
      _.assign({}, this.props.styles.row, { backgroundColor: '#ECF5FD' }) :
      this.props.styles.row;
    return (
      <div style={styles}>
        <div
          className="row section-body"
          onMouseEnter={this.showEdit}
          onMouseLeave={this.hideEdit}
        >
          <div className="col-sm-2">
            <h5 className="text-right mt-quarter">Name</h5>
          </div>
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-4">
                <p className="subhead">First</p>
                <p>{employee.first_name}</p>
              </div>
              <div className="col-sm-4">
                <p className="subhead">Last</p>
                <p>{employee.last_name}</p>
              </div>
              <div className="col-sm-4">
                <p className="subhead">Work Title</p>
                <p>{employee.work_title}</p>
              </div>
            </div>
          </div>
          <div className="col-sm-1">
            <span>
              <ul className="list-unstyled">
                <EditModal
                  id="edit-personal-modal"
                  header="Edit Personal Information"
                  formName={EDIT_PERSONAL_INFORMATION}
                  buttonId="edit-personal-btn"
                >
                  <PersonalInformationForm
                    employee={employee}
                  />
                </EditModal>
              </ul>
            </span>
          </div>
          <div className="col-sm-2 mt-one">
            <h5 className="text-right mt-quarter">Timezone</h5>
          </div>
          <div className="col-sm-10 mt-one">
            <p>{employee.timezone}</p>
          </div>
        </div>
      </div>
    );
  }
}

PersonalInformationDisplay.propTypes = {
  employee: PropTypes.object.isRequired,
  styles: PropTypes.object,
};

PersonalInformationDisplay.defaultProps = {
  styles: {
    row: { padding: '0' },
  },
};

export default PersonalInformationDisplay;
