import _ from 'lodash';

function otherProgramsExist(referralPrograms, userPrograms) {
  const referralProgramsIds = _.map(referralPrograms, 'id');
  const userProgramsIds = _.map(userPrograms, 'id');

  return (
    referralPrograms.length > 1 &&
    _.difference(referralProgramsIds, userProgramsIds).length > 0
  );
}

export default otherProgramsExist;
