import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ScrollFieldIntoView extends Component {
  constructor(props) {
    super(props);

    this.createFieldNameRef = this.createFieldNameRef.bind(this);
    this.onFieldFocus = this.onFieldFocus.bind(this);
    this.passOnFocusToChild = this.passOnFocusToChild.bind(this);
  }

  onFieldFocus(event) {
    event.preventDefault();
    this.fieldName.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  createFieldNameRef(el) {
    this.fieldName = el;
  }

  passOnFocusToChild(child) {
    return React.cloneElement(child, {
      onFocus: this.onFieldFocus,
    });
  }

  render() {
    const { children } = this.props;

    const childrenWithFuncs = React.Children
      .map(children, this.passOnFocusToChild);

    return (
      <div
        className="scroll-field-into-view"
        ref={this.createFieldNameRef}
      >
        {childrenWithFuncs}
      </div>
    );
  }
}

ScrollFieldIntoView.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ScrollFieldIntoView;
