import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ServiceCasePrimaryWorkerForm from 'src/components/Cases/components/Detail/ServiceCasePrimaryWorkerForm';
import EditModal from 'common/modal/EditModal';
import _ from 'lodash';

export class ServiceCasePrimaryWorker extends Component {
  showEditableSection() {
    const currentGroupId = _.get(this.props, 'groupId', 'no current group id');
    const serviceCaseGroupId = _.get(this.props, 'serviceCase.provider.id', 'no service case group id');
    return _.wget(this.props, 'editable', false) && currentGroupId === serviceCaseGroupId;
  }

  render() {
    const {
      serviceCase,
      contactId,
    } = this.props;

    const showEditable = this.showEditableSection();
    const editSection = (
      <div className="service-case-details__edit-section">
        <EditModal
          id="edit-service-worker-modal"
          buttonId="edit-service-worker"
          header="Edit"
          size="large"
          formName="editServiceCasePrimaryWorkerForm"
          labelText="Edit"
        >
          <ServiceCasePrimaryWorkerForm
            ref={(el) => { this.editServiceCasePrimaryWorker = el; }}
            serviceCase={serviceCase}
            contactId={contactId}
          />
        </EditModal>
      </div>
    );

    return (
      <div className="service-case-status__primary-worker service-case-node">
        <p className="detail-status__description">{_.get(serviceCase, 'primary_worker.full_name', '')}</p>
        {showEditable ? editSection : null}
      </div>
    );
  }
}

ServiceCasePrimaryWorker.propTypes = {
  serviceCase: PropTypes.shape({
    primary_case_worker: PropTypes.shape({
      full_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  contactId: PropTypes.string.isRequired,
};

export default ServiceCasePrimaryWorker;
