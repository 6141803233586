import { get, isEmpty, first, last, isNil } from 'lodash';
import { addresses } from '@unite-us/client-utils';

const getClientCenter = (contact) => {
  const address = addresses.findMainAddress(get(contact, 'addresses', []));
  const latLng = get(address, 'lat_lng', []);
  const latOrLngIsNil = isNil(first(latLng)) || isNil(last(latLng));

  return (isEmpty(latLng) || latOrLngIsNil) ? undefined : {
    lat: first(latLng),
    lng: last(latLng),
  };
};

export default getClientCenter;
