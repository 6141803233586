import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { InputField } from '@unite-us/ui';
import { getGroupOptions } from 'src/components/Referrals/ReferralGroupsPrograms/utils';
import TabbedSelectField from 'src/common/form/TabbedSelectField';
import {
  atLeastOneGroupValue,
  disabledCustomOptions,
  getInNetworkGroupField,
} from '../utils';

function oonGroupValidations(allowEmpty) {
  return (value, _2, _3, formValues) => {
    const inNetworkGroupField = getInNetworkGroupField(formValues);

    return !allowEmpty &&
      _.isEmpty(value) &&
      !atLeastOneGroupValue(_.get(formValues, 'oonCase.selected', [])) &&
      inNetworkGroupField === '' ?
      'Required' :
      null;
  };
}
class ReferralOONGroupSelect extends Component {
  constructor(props) {
    super(props);

    this.onGroupSelect = this.onGroupSelect.bind(this);
  }

  onGroupSelect(selected) {
    const { index } = this.props;
    this.props.onGroupSelect(selected, index);
  }

  render() {
    const {
      allowEmpty,
      canPaginateNetworkGroups,
      customOnly,
      groupField,
      originCoordinates,
      index,
      oonGroupFields,
      registerField,
      selectedGroupIds,
      searchTermSelectable,
      suggestedGroups,
      nationalStateSuggestedGroups,
      query,
      paginateSearchNetworkGroups,
    } = this.props;

    const handleSearch = _.debounce((t) => { paginateSearchNetworkGroups(t); }, 2000);

    /*
      getGroupOptions normalizes the suggestedGroups in a couple ways.
        * only includes the id, name provider_type, and programs properties on each group object.
        * if the "canPaginateNetworkGroups" feature flag is ON,
          we also append distances from originCoords to the names.
    */
    const options = [
      ...getGroupOptions({
        canPaginateNetworkGroups,
        groups: suggestedGroups,
        originCoordinates,
        selectedGroupIds,
      }),
      ...disabledCustomOptions(oonGroupFields),
    ];

    const nationalStateOptions = [
      ...getGroupOptions({
        canPaginateNetworkGroups: false,
        groups: nationalStateSuggestedGroups,
        originCoordinates,
        selectedGroupIds,
      }),
      ...disabledCustomOptions(oonGroupFields),
    ];

    const groupFieldValue = groupField?.value?.id ? {
      ...nationalStateOptions.find((v) => v.id === groupField?.value?.id),
      ...options.find((v) => v.id === groupField?.value?.id),
    } : {};

    const origData = [
      {
        label: 'Nearby Organizations',
        id: 'nearby-organizations',
        options,
      },
      {
        label: 'Also serves this area',
        id: 'national-state-organizations',
        options: nationalStateOptions,
      },
    ];

    return (
      <div className="referral-oon-group-select">
        {customOnly && !query ? (
          <InputField
            autoComplete="off"
            className="referral-oon-group-input"
            id={`input-field-oon-group-${index}`}
            field={groupField}
            label="oon-group-input"
            hideLabel
            inline={false}
            onChange={this.onGroupSelect}
            ref={registerField}
            validations={[
              { func: oonGroupValidations(allowEmpty) },
            ]}
          />
        ) : (
          <TabbedSelectField
            name="oon-selector"
            placeholder="Choose an organization"
            searchPlaceholder="Search for an organization"
            data={origData}
            query={query}
            open={query}
            searchTermSelectable={searchTermSelectable}
            searchDescription={'Displaying top 50 results. Type to search for more.'}
            required
            registerField={registerField}
            groupField={groupField}
            clearable
            truncateOptions
            value={groupFieldValue}
            onChange={(val) => { groupField.onChange(val); this.onGroupSelect(val); }}
            searchEnabled
            onSearch={(e) => { handleSearch(e.target.value); }}
          />
        )}
      </div>
    );
  }
}

ReferralOONGroupSelect.propTypes = {
  allowEmpty: PropTypes.bool,
  canPaginateNetworkGroups: PropTypes.bool.isRequired,
  customOnly: PropTypes.bool,
  groupField: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  oonGroupFields: PropTypes.array.isRequired,
  originCoordinates: PropTypes.array.isRequired,
  onGroupSelect: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  selectedGroupIds: PropTypes.array,
  searchTermSelectable: PropTypes.bool,
  suggestedGroups: PropTypes.array.isRequired,
  nationalStateSuggestedGroups: PropTypes.array,
  query: PropTypes.string,
  paginateSearchNetworkGroups: PropTypes.func,
};

ReferralOONGroupSelect.defaultProps = {
  allowEmpty: false,
  customOnly: false,
  selectedGroups: [],
  searchTermSelectable: false,
  nationalStateSuggestedGroups: [],
  query: '',
  paginateSearchNetworkGroups () { },
};

export default ReferralOONGroupSelect;
