import { coreApi, setAuthTokenClients } from 'src/api/config';
import axios from 'axios';
import Notifier from 'common/helpers/Notifier';
import { UPDATE_USER } from 'actions';
import { AUTH_URL, CLIENT_ID } from 'src/config/env/env.config';
import { get as getCookie, set as setCookie } from 'es-cookie';
import { setAxiosHeaders, secureProtocol } from 'src/common/utils/utils';
import getCoreError from 'src/api/utils/getCoreError';
import { COOKIE_SESSION_KEY } from 'src/common/constants';

const onSuccess = async (dispatch, response, user) => {
  dispatch({
    type: UPDATE_USER,
    payload: response,
  });

  const tokenFromCookie = getCookie(COOKIE_SESSION_KEY);
  const { password, password_confirmation } = user;

  if (password && password_confirmation) {
    const parsedTokenCookie = JSON.parse(tokenFromCookie);
    const { data } = await axios.post(`${AUTH_URL}/oauth2/token`, {
      client_id: CLIENT_ID,
      refresh_token: parsedTokenCookie.refresh_token,
      grant_type: 'refresh_token',
    });
    const expiresIn = data.expires_in;
    const expirationDate = new Date();
    expirationDate.setSeconds(expirationDate.getSeconds() + expiresIn);

    if (!data.refresh_token && window.Rollbar) {
      window.Rollbar.error(
        `Setting COOKIE_SESSION_KEY (3).
        Auth response: ${JSON.stringify(data, 0, 2)},
        parsedTokenCookie: ${JSON.stringify(parsedTokenCookie, 0, 2)}`,
      );
    }

    setCookie(
      COOKIE_SESSION_KEY,
      JSON.stringify(data),
      {
        secure: secureProtocol,
        expires: expirationDate,
      },
    );
    setAxiosHeaders(data.access_token);
    setAuthTokenClients(data.access_token);
  }
  Notifier.dispatch(response.status, 'User Successfully Updated');
  return response;
};

const onError = (dispatch, error) => {
  Notifier.handleErrors(getCoreError(error));
  return error;
};

export default function updateEmployee(employeeId, user) {
  return async (dispatch) => {
    try {
      await coreApi.updateRecord('employee', employeeId, user);
      const employeeResponse = await coreApi.findRecord('employee', employeeId);
      return onSuccess(dispatch, employeeResponse, user);
    } catch (error) {
      return onError(dispatch, error);
    }
  };
}
