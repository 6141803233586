import _ from 'lodash';

function hashToParams(hash) {
  return _.map(
    _.toPairs(hash),
    (p) => [p[0], encodeURIComponent(p[1])].join('='),
  ).join('&');
}

export default hashToParams;
