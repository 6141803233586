import { useState } from 'react';

class StepOutOfRangeError extends Error {
  constructor() {
    super('Step must be in range 0..3');
  }
}

export const useEnrollmentSteps = () => {
  const [step, setStep] = useState(0);

  const stepForward = () => {
    if (step >= 3) throw new StepOutOfRangeError();
    setStep(step + 1);
  };

  const stepBackward = () => {
    if (step <= 0) throw new StepOutOfRangeError();
    setStep(step - 1);
  };

  const goToStep = (newStep = 0) => {
    if (newStep < 0 || newStep > 3) throw new StepOutOfRangeError();
    setStep(newStep);
  };

  return {
    step,
    stepForward,
    stepBackward,
    goToStep,
  };
};
