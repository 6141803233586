import React from 'react';
import { AUTH_URL } from 'src/config/env/env.config';
import { get as getCookie } from 'es-cookie';
import { COOKIE_SESSION_KEY } from 'src/common/constants';

const LogoutAuth = () => {
  const cookie = getCookie(COOKIE_SESSION_KEY);

  if (!cookie) {
    return null;
  }

  const { access_token } = JSON.parse(cookie);
  const encodedSession = window.btoa(JSON.stringify({ token: access_token }));

  return (
    <form
      action={`${AUTH_URL}/logout`}
      method="POST"
      id="logout-auth-form"
    >
      <input
        type="hidden"
        id="session"
        name="session"
        value={encodedSession}
      />
    </form>
  );
};

export default LogoutAuth;
