import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { get, isEmpty, noop } from 'lodash';
import { validateReduxForm } from 'common/form';
import { NoteDisclosure } from '@unite-us/client-utils';
import moment from 'moment';
import NoteVisibility from 'src/common/form/Interactions/components/NoteVisibility';
import SelectServiceCases from './SelectServiceCases';
import InteractionNoteField from './InteractionNoteField';
import AttachedToField from './AttachedToField';
import FormInteractionsButtons from './FormInteractionsButtons';
import { SENSITIVE_GENERAL_NOTE_HINT_TEXT } from './constants';

const NEW_NOTE_FORM = 'newNote';
const INTERACTION_TYPE = 'note';

export class NewNote extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onSubmit(values) {
    const formValues = {
      ...values,
      interaction: {
        ...values.interaction,
        type: INTERACTION_TYPE,
        occurred_on: moment().unix(),
      },
    };

    return this.props.submitFunction(formValues)
      .then(this.props.resetForm);
  }

  onCancel() {
    this.props.cancelFunction();
    this.props.resetForm();
  }

  render() {
    const {
      fields,
      handleSubmit,
      hasCases,
      isFromCaseOrReferral,
      isMyProviderSensitive,
      registerField,
      submitting,
      showNoteVisibility,
    } = this.props;

    const hintText = isMyProviderSensitive ? SENSITIVE_GENERAL_NOTE_HINT_TEXT : '';
    const isGeneralNote = fields.attached_to.value === 'general';

    const warningMessage = <NoteDisclosure />;

    return (
      <div className="new-note mt-3">
        <form
          onSubmit={handleSubmit(this.onSubmit)}
          data-role="log-interaction-form"
        >
          <AttachedToField
            className="mt-one ml-half"
            field={fields.attached_to}
            hidden={!hasCases}
          />

          {null}

          <InteractionNoteField
            afterLabelContent={warningMessage}
            field={fields.interaction.note}
            hintText={hintText}
            registerField={registerField}
            showHint={!isFromCaseOrReferral && isGeneralNote}
          />

          {
            showNoteVisibility ? (
              <NoteVisibility
                registerField={registerField}
                disabled={isMyProviderSensitive}
                field={fields.interaction.is_note_private}
              />
            ) : null
          }

          <FormInteractionsButtons
            primaryActionHandler={handleSubmit(this.onSubmit)}
            cancelActionHandler={this.onCancel}
            submitting={submitting}
            interaction="new-note"
          />
        </form>
      </div>
    );
  }
}

NewNote.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hasCases: PropTypes.bool,
  isMyProviderSensitive: PropTypes.bool.isRequired,
  isFromCaseOrReferral: PropTypes.bool.isRequired,
  registerField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  submitFunction: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  cancelFunction: PropTypes.func,
  showNoteVisibility: PropTypes.bool.isRequired,
};

NewNote.defaultProps = {
  hasCases: false,
  cancelFunction: noop,
};

function mapStateToProps(state, ownProps = {}) {
  const isMyProviderSensitive = get(state, 'globalState.currentProvider.group.sensitive', null);
  const showNoteVisibility = ownProps.detailType === 'profile';
  const is_note_private = Boolean(showNoteVisibility && isMyProviderSensitive);

  return {
    initialValues: {
      attached_to: 'general',
      interaction: {
        type: 'phone_call',
        is_note_private,
      },
    },
    showNoteVisibility,
    isMyProviderSensitive,
  };
}

const fields = [
  'interaction.is_note_private',
  'attached_to',
  'interaction.note',
];
export default validateReduxForm({
  form: NEW_NOTE_FORM,
  fields,
}, mapStateToProps)(NewNote);
