import React, { useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { get } from 'lodash';
import cx from 'classnames';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import moment from 'moment';
import { feeSchedulePrograms as fspUtils } from '@unite-us/client-utils';
import { validations } from '@unite-us/app-components';
import {
  DateField,
  RadioField,
  DurationField,
  InputField,
  TextField,
} from '@unite-us/ui';
import { usePopulate } from 'api/APIHooks';
import { useCurrentEmployeeId } from 'common/contexts/CurrentEmployeeContext';
import { LineItem } from 'common/display/LineItem';
import { dollarAmount } from 'common/display/Money/DollarAmount';
import { validateUnitAmount } from 'common/form/Interactions/utils';
import DialogV2 from 'common/modal/DialogV2';
import { PrimaryButton } from 'common/TailwindComponents';
import datesInRange from 'common/utils/Dates/datesInRange';
import nearestDay from 'common/utils/Dates/nearestDay';
import formatDate from 'common/utils/Dates/formatDate';
import unixToISO from 'common/utils/Dates/unixToISO';
import dollarsToCents from 'common/utils/Money/dollarsToCents';
import NoteDisclaimer from 'common/Disclaimer/NoteDisclaimer';
import ErrorMessage from 'common/utils/ErrorMessage';
import usePreventWheel from 'common/hooks/usePreventWheel';
import AuthorizationSpendSection from './AuthorizationSpendSection';
import { useAcceptServiceAuthorization } from '../hooks/useUpdateServiceAuthorization';

const DATE_TYPE_VALUES = {
  SINGLE_DATE: 'single',
  RANGE_DATE: 'range',
};
const DATE_TYPE_OPTIONS = [
  { label: 'Single Date', value: DATE_TYPE_VALUES.SINGLE_DATE },
  { label: 'Date Range', value: DATE_TYPE_VALUES.RANGE_DATE },
];

const FORM_ERRORS = {
  AMOUNT_EXCEEDED: 'amount_exceeded',
  DATES_OUT_OF_RANGE: 'dates_out_of_range',
};

const datesCoveredByInsurance = (formValues, authorization) => {
  const { insurance } = authorization;
  const startDate = moment.unix(formValues.range_date_start);
  const endDate = moment.unix(formValues.range_date_end);
  const singleDate = moment.unix(formValues.single_date);
  const enrolledAt = nearestDay(insurance.enrolled_at);
  const expiredAt = insurance.expired_at && nearestDay(insurance.expired_at);
  return formValues.date_type === DATE_TYPE_VALUES.RANGE_DATE ?
    datesInRange(enrolledAt, expiredAt, startDate, endDate) :
    datesInRange(enrolledAt, expiredAt, singleDate);
};

const isDateEntered = (values) => ((values.date_type === DATE_TYPE_VALUES.RANGE_DATE &&
  values.range_date_start && values.range_date_end) ||
  (values.date_type === DATE_TYPE_VALUES.SINGLE_DATE && values.single_date));

const AcceptServiceAuthorizationButton = ({ authorization }) => {
  const [isAmountExceeded, setIsAmountExceeded] = useState(false);
  const modalRef = useRef();
  usePopulate('insurance', 'insurance', authorization, { queryConfig: { placeholderData: undefined } });

  const closeModal = ({ reset }) => {
    if (modalRef.current) {
      reset();
      modalRef.current.closeDialog();
    }
  };

  const openModal = () => {
    modalRef.current.openDialog();
  };

  const currentEmployeeId = useCurrentEmployeeId();
  const feeScheduleProgram = get(authorization, 'fee_schedule_program', {});
  const isCostBased = fspUtils.isCostPaymentType(feeScheduleProgram.payment_type);
  const isUnitBased = !isCostBased;
  const isSingleDateRequested = authorization.requested_starts_at === authorization.requested_ends_at;

  const acceptAuthorization = useAcceptServiceAuthorization();

  const onSubmit = async (
    {
      payer_authorization_number,
      authorized_amount,
      date_type,
      single_date,
      range_date_start,
      range_date_end,
      reviewer_note,
    },
    form,
  ) => {
    await acceptAuthorization({
      authorization,
      reviewer: currentEmployeeId,
      payer_authorization_number,
      reviewer_note,
      ...(isUnitBased ?
        { approved_unit_amount: parseInt(authorized_amount, 10) } :
        { approved_cents: dollarsToCents(authorized_amount) }
      ),
      approved_starts_at: unixToISO(
        date_type === DATE_TYPE_VALUES.SINGLE_DATE ?
          single_date :
          range_date_start,
      ),
      approved_ends_at: unixToISO(
        date_type === DATE_TYPE_VALUES.SINGLE_DATE ?
          single_date :
          range_date_end,
      ),
    });
    closeModal(form);
  };

  usePreventWheel('authorized_amount');

  return (
    <>
      <PrimaryButton
        ariaLabel="accept authorization request"
        dataTestId="accept-authorization-button"
        icon="IconCheckCircle"
        label="Accept"
        onClick={openModal}
        primary
      />

      <Form
        initialValues={{
          authorized_amount: isUnitBased ? authorization.requested_unit_amount : authorization.requested_cents / 100,
          date_type: isSingleDateRequested ? DATE_TYPE_VALUES.SINGLE_DATE : DATE_TYPE_VALUES.RANGE_DATE,
          single_date: Date.parse(authorization.requested_ends_at) / 1000,
          range_date_start: Date.parse(authorization.requested_starts_at) / 1000,
          range_date_end: Date.parse(authorization.requested_ends_at) / 1000,
        }}
        onSubmit={onSubmit}
        validate={(values) => {
          const areDatesSelected = values.date_type === DATE_TYPE_VALUES.SINGLE_DATE ?
            !!values.single_date :
            !!values.range_date_start && values.range_date_end;

          if (isAmountExceeded && isDateEntered(values)) {
            return { [FORM_ERROR]: FORM_ERRORS.AMOUNT_EXCEEDED };
          }

          if (areDatesSelected && !datesCoveredByInsurance(values, authorization)) {
            return { [FORM_ERROR]: FORM_ERRORS.DATES_OUT_OF_RANGE };
          }

          return {};
        }}
        render={({
          error,
          form,
          handleSubmit,
          valid,
          values: {
            authorized_amount,
            date_type,
            range_date_start,
            range_date_end,
            single_date,
          },
        }) => (
          <DialogV2
            ref={modalRef}
            title={`Accept Authorization Request #${authorization.short_id}`}
            confirmLabel="Accept"
            cancelHandler={() => closeModal(form)}
            confirmationHandler={handleSubmit}
            confirmationBtnDisabled={!valid}
            dataTestId="accept-auth-request-dialog"
            confirmButtonTestId="accept-auth-request-dialog-confirm-button"
            width="3xl"
          >
            <p>
              Accepting this request will send the referral to the recipient
              organization. Any changes you make to the authorized amount
              will be reflected in the referral.
            </p>
            <div className="pt-6">
              <h4 className="leading-4 mb-3 uppercase">
                Request Details
              </h4>
              <dl className="grid grid-cols-2 gap-2 m-0">
                <LineItem field="Amount Requested" dataTestElement="amount-requested">
                  {authorization.requested_unit_amount ?
                      authorization.requested_unit_amount :
                      dollarAmount(authorization.requested_cents, true)}
                </LineItem>
                {isSingleDateRequested ? (
                  <LineItem field="Service Delivery Date" dataTestElement="service-delivery-date">
                    {formatDate(authorization.requested_ends_at)}
                  </LineItem>
                  ) : (
                    <LineItem field="Service Delivery Dates" dataTestElement="service-delivery-dates">
                      {formatDate(authorization.requested_starts_at)} -{' '}
                      {formatDate(authorization.requested_ends_at)}
                    </LineItem>
                  )}
                <LineItem field="Payment Approach" dataTestElement="payment-approach">
                  {fspUtils.PAYMENT_TYPES[feeScheduleProgram.payment_type]}
                </LineItem>
                {isUnitBased && (
                  <LineItem field="Rate" dataTestElement="request-rate">
                    {dollarAmount(feeScheduleProgram.unit_rate, true)} per{' '}
                    {feeScheduleProgram.unit}
                  </LineItem>
                  )}
                <LineItem field="Program Cap" dataTestElement="request-cap">
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: feeScheduleProgram.cap_information || 'N/A' }} />
                </LineItem>
              </dl>
            </div>
            <AuthorizationSpendSection
              feeScheduleProgram={feeScheduleProgram}
              person={authorization?.case?.person}
              isUnitBased={isUnitBased}
              requestedStartsAt={
                moment.unix(
                  date_type === DATE_TYPE_VALUES.RANGE_DATE ?
                    range_date_start :
                    single_date,
                )
              }
              requestedEndsAt={
                moment.unix(
                  date_type === DATE_TYPE_VALUES.RANGE_DATE ?
                    (range_date_end || range_date_start) :
                    single_date,
                )
              }
              authorizedAmount={authorized_amount}
              setIsAmountExceeded={setIsAmountExceeded}
            />
            <hr className="my-5 border-dark-fill-blue border-solid" />
            <h4 className="uppercase leading-4 mb-3">
              Authorization Details
            </h4>
            <div className="grid grid-cols-2 gap-2 m-0 gap-x-24">
              <Field
                name="date_type"
                type="select"
              >
                {(props) => (
                  <RadioField
                    className="mb-0"
                    label="PERIOD OF SERVICE"
                    options={DATE_TYPE_OPTIONS}
                    {...props}
                    required
                  />
                )}
              </Field>
              {date_type === DATE_TYPE_VALUES.SINGLE_DATE && (
                <Field
                  name="single_date"
                  validate={(value) => validations.isRequired(value)}
                >
                  {(fieldProps) => (
                    <DateField
                      {...fieldProps}
                      className="mb-0"
                      id="single_date"
                      label="SERVICE DELIVERY DATE"
                      required
                    />
                  )}
                </Field>
              )}
              {date_type === DATE_TYPE_VALUES.RANGE_DATE && (
                <Field name="range_date_start" validate={(value) => validations.isRequired(value)}>
                  {(startProps) => (
                    <Field name="range_date_end" validate={(value) => validations.isRequired(value)}>
                      {(endProps) => (
                        <DurationField
                          className="mb-0"
                          style={{ dropdown: { right: 0, left: 'unset' } }}
                          id="duration_field"
                          isUTC
                          label="SERVICE DELIVERY DATES"
                          startField={startProps}
                          endField={endProps}
                          required
                        />
                      )}
                    </Field>
                  )}
                </Field>
              )}
              {error === FORM_ERRORS.DATES_OUT_OF_RANGE && (
                <div className="flex col-span-2 space-x-2 px-0 mt-0 pb-4">
                  <ErrorMessage>
                    The authorized service delivery dates fall outside the
                    client&apos;s enrollment dates for the associated social
                    care coverage plan. Please change the authorized service
                    delivery dates.
                  </ErrorMessage>
                </div>
              )}
              {isUnitBased ? (
                <Field name="authorized_amount" validate={validateUnitAmount}>
                  {(fieldProps) => (
                    <InputField
                      {...fieldProps}
                      className={cx('mb-0', error === FORM_ERRORS.AMOUNT_EXCEEDED && 'ui-input-field--has-error')}
                      id="authorized_amount"
                      label="AUTHORIZED AMOUNT"
                      required
                      type="number"
                    />
                  )}
                </Field>
              ) : (
                <Field name="authorized_amount" validate={(value) => validations.isRequired(value)}>
                  {(fieldProps) => (
                    <InputField
                      {...fieldProps}
                      className={cx('mb-0', error === FORM_ERRORS.AMOUNT_EXCEEDED && 'ui-input-field--has-error')}
                      id="authorized_amount"
                      label="AUTHORIZED AMOUNT"
                      placeholder="0.00"
                      required
                      step="0.1"
                      type="number"
                    />
                  )}
                </Field>
              )}
              <div>
                <label className="block mb-4" htmlFor="accept-service-authorization-unit-info">Unit</label>
                <p className="capitalize" id="accept-service-authorization-unit-info">
                  {isCostBased ? 'Dollars' : feeScheduleProgram.unit}
                </p>
              </div>

              {error === FORM_ERRORS.AMOUNT_EXCEEDED && (
                <div className="flex col-span-2 space-x-2 px-0 mt-0 pb-4">
                  <ErrorMessage>
                    The authorized amount is more than the available amount.
                    Please adjust the authorized amount.
                  </ErrorMessage>
                </div>
              )}

              <Field name="payer_authorization_number">
                {(fieldProps) => (
                  <InputField
                    {...fieldProps}
                    className="mb-0"
                    id="payer_authorization_number"
                    label="PAYER AUTHORIZATION ID"
                  />
                )}
              </Field>
              <Field name="reviewer_note">
                {(props) => (
                  <TextField
                    {...props}
                    className="p-0 col-span-2"
                    id="reviewer_note"
                    label="NOTE"
                    afterLabelContent={<NoteDisclaimer />}
                  />
                )}
              </Field>
            </div>
          </DialogV2>
        )}
      />
    </>
  );
};

AcceptServiceAuthorizationButton.propTypes = {
  authorization: PropTypes.shape({
    fee_schedule_program: PropTypes.shape({
      fee_schedule: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
    requested_cents: PropTypes.number,
    requested_starts_at: PropTypes.string.isRequired,
    requested_ends_at: PropTypes.string.isRequired,
    requested_unit_amount: PropTypes.number,
    short_id: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    case: PropTypes.shape({
      person: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

export default AcceptServiceAuthorizationButton;
