import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { InputField } from '@unite-us/ui';
import { ShowHide } from '@unite-us/client-utils';
import { SingleSelectWithCustomValue } from 'common/form';
import { getGroupOptions } from 'src/components/Referrals/ReferralGroupsPrograms/utils';
import {
  atLeastOneGroupValue,
  disabledCustomOptions,
  getInNetworkGroupField,
} from '../utils';

function oonGroupValidations(allowEmpty) {
  return (value, _2, _3, formValues) => {
    const inNetworkGroupField = getInNetworkGroupField(formValues);

    return !allowEmpty &&
      _.isEmpty(value) &&
      !atLeastOneGroupValue(_.get(formValues, 'oonCase.selected', [])) &&
      inNetworkGroupField === '' ?
      'Required' :
      null;
  };
}

class ReferralOONGroupSelect extends Component {
  constructor(props) {
    super(props);

    this.onGroupSelect = this.onGroupSelect.bind(this);
  }

  onGroupSelect(selected) {
    const { index } = this.props;
    this.props.onGroupSelect(selected, index);
  }

  render() {
    const {
      allowEmpty,
      canPaginateNetworkGroups,
      debouncedSearchNetworkGroups,
      customOnly,
      groupField,
      originCoordinates,
      hidden,
      index,
      oonGroupFields,
      registerField,
      selectedGroupIds,
      suggestedGroups,
      touch,
    } = this.props;

    /*
      getGroupOptions normalizes the suggestedGroups in a couple ways.
        * only includes the id, name provider_type, and programs properties on each group object.
        * if the "canPaginateNetworkGroups" feature flag is ON,
          we also append distances from originCoords to the names.
    */
    const options = [
      ...getGroupOptions({
        canPaginateNetworkGroups,
        groups: suggestedGroups,
        originCoordinates,
        selectedGroupIds,
      }),
      ...disabledCustomOptions(oonGroupFields),
    ];

    return (
      <div className="referral-oon-group-select">
        <ShowHide hide={hidden || customOnly}>
          <SingleSelectWithCustomValue
            className="referral-oon-group-select"
            canPaginateNetworkGroups={canPaginateNetworkGroups}
            debouncedSearchNetworkGroups={debouncedSearchNetworkGroups}
            field={groupField}
            groupsOptionType={'out-of-network'}
            hidden={hidden}
            hideLabel
            id={`select-field-oon-group-${index}`}
            inline={false}
            label="out-of-network-group-select"
            labelKey="displayName"
            onChange={this.onGroupSelect}
            oonGroupFields={oonGroupFields}
            options={options}
            registerField={registerField}
            placeholder={canPaginateNetworkGroups ? 'Choose an organization' : ''}
            searchPlaceholderValue={
              canPaginateNetworkGroups ?
                'Displaying closest 50 - Search for more or create custom...' :
                ''
            }
            touch={touch}
            valueKey="id"
            validations={[
              { func: oonGroupValidations(allowEmpty) },
            ]}
          />
        </ShowHide>
        <ShowHide hide={hidden || !customOnly}>
          <InputField
            autoComplete="off"
            className="referral-oon-group-input"
            id={`input-field-oon-group-${index}`}
            field={groupField}
            label="oon-group-input"
            hidden={hidden}
            hideLabel
            inline={false}
            onChange={this.onGroupSelect}
            ref={registerField}
            validations={[
              { func: oonGroupValidations(allowEmpty) },
            ]}
          />
        </ShowHide>
      </div>
    );
  }
}

ReferralOONGroupSelect.propTypes = {
  allowEmpty: PropTypes.bool,
  canPaginateNetworkGroups: PropTypes.bool.isRequired,
  debouncedSearchNetworkGroups: PropTypes.func.isRequired,
  customOnly: PropTypes.bool,
  groupField: PropTypes.object.isRequired,
  hidden: PropTypes.bool,
  index: PropTypes.number.isRequired,
  oonGroupFields: PropTypes.array.isRequired,
  originCoordinates: PropTypes.array.isRequired,
  onGroupSelect: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  selectedGroupIds: PropTypes.array,
  suggestedGroups: PropTypes.array.isRequired,
  touch: PropTypes.func,
};

ReferralOONGroupSelect.defaultProps = {
  allowEmpty: false,
  customOnly: false,
  hidden: false,
  selectedGroups: [],
  touch: _.noop,
};

export default ReferralOONGroupSelect;
