import { isFunction } from 'lodash';

function createRecenterButton(map, recenterMap) {
  const maps = window.google.maps;
  const recenterDiv = document.createElement('div');
  const controlUI = document.createElement('div');

  controlUI.style.backgroundColor = '#fff';
  controlUI.style.border = '2px solid #fff';
  controlUI.style.height = '24px';
  controlUI.style.width = '24px';
  controlUI.style.borderRadius = '3px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.marginBottom = '22px';
  controlUI.style.textAlign = 'center';
  controlUI.title = 'Click to recenter the map';
  recenterDiv.appendChild(controlUI);

  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttributeNS(null, 'viewBox', '0 0 24 24');
  svg.setAttributeNS(null, 'height', '20');
  svg.setAttributeNS(null, 'width', '20');
  svg.style.display = 'block';
  svg.style.marginLeft = 'auto';
  svg.style.marginRight = 'auto';
  svg.style.marginTop = '2px';

  const path1 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  const path2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');

  path1.setAttributeNS(null, 'd', 'M0 0h24v24H0z');
  path1.setAttributeNS(null, 'fill', 'none');

  /* eslint-disable */
  path2.setAttributeNS(null, 'd', 'M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z');
  path2.setAttributeNS(null, 'fill', '#58606E');
  /* eslint-enable */
  svg.appendChild(path1);
  svg.appendChild(path2);

  controlUI.appendChild(svg);

  recenterDiv.addEventListener('click', () => {
    if (isFunction(recenterMap)) {
      recenterMap();
    }
  });

  recenterDiv.style.marginLeft = '10px';
  recenterDiv.index = 1;

  return map.controls[maps.ControlPosition.LEFT_TOP].push(recenterDiv);
}

export default createRecenterButton;
