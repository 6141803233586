import separatePhoneNumberFromExtension from './separatePhoneNumberFromExtension';

function generatePhonesWithExtension(phones = []) {
    for (const phone of phones) {
        const { phone_number, extension } = separatePhoneNumberFromExtension(phone);
        phone.phone_number = phone_number;
        phone.extension = extension;
    }

    return phones;
}

export default generatePhonesWithExtension;
