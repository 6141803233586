import React from 'react';
import PropTypes from 'prop-types';
import GroupDetailsHeaderTitle from 'src/components/Groups/GroupDetails/components/GroupDetailsHeaderTitle';

const InfoWindow = (props) => {
  return (
    <div className="infowindow">
      <div className="infowindow-title">{props.title}</div>
    </div>
  )
}

InfoWindow.propTypes = {
  title: PropTypes.string.isRequired,
}

export default InfoWindow;
