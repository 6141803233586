import { SENT_REFERRAL_BASE_ROUTE } from 'src/components/Referrals/constants';

function statusRoute(status) {
  switch (status) {
    case 'accepted/open':
    case 'accepted/closed':
    case 'accepted':
    case 'closed':
    case 'recalled':
      return `/${status}`;

    case 'pending':
      return '/awaiting-response';

    case 'declined':
      return '/rejected';

    case 'in_review':
      return '/in-review';

    case 'pending_consent':
      return '/pending-consent';

    case 'declined_consent':
      return '/declined-consent';

    case 'auto_recalled':
      return '/auto-recalled';

    case 'recalled_or_auto_recalled':
      return '/recalled-or-auto-recalled';

    default:
      return '';
  }
}

function getReferralSubRouteFromStatus(status, base = SENT_REFERRAL_BASE_ROUTE) {
  return `${base}${statusRoute(status)}`;
}

export default getReferralSubRouteFromStatus;
