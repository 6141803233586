import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Button,
  Dialog,
} from '@unite-us/ui';
import _ from 'lodash';
import ExportsTable from 'src/components/Exports/components/ExportsTable';
import ExportsCreateForm from 'src/components/Exports/components/ExportsCreateForm';
import FilterBar from 'src/components/Dashboard/components/FilterBar';
import { fetchExports } from 'actions/Export/Group';
import { setExportsRefetch } from 'actions/Export';
import fetchNetworksByProviderIds from 'src/api/core/Networks/fetchNetworksByProviderIds';
import 'src/common/stylesheets/tableView.scss';
import { getDataOfPage, isDataValid } from 'src/components/Dashboard/utils/dataHelpers';
import 'src/components/Exports/stylesheets/export.scss';
import { assistanceRequestsExportsAvailable } from 'common/utils/FeatureFlags/flags';

export class Exports extends Component {
  constructor(props) {
    super(props);
    this.closeDialog = this.closeDialog.bind(this);
    this.fetch = this.fetch.bind(this);
    this.fetchAllNetworks = this.fetchAllNetworks.bind(this);
    this.onFiltersChange = this.onFiltersChange.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.setIntervalFetch = this.setIntervalFetch.bind(this);
    this.shouldFetch = this.shouldFetch.bind(this);

    this.state = {
      filters: {
        export_types: _.get(props, 'exports.filters', null),
      },
      intervalFunc: null,
      loading: true,
      page: 1,
      sidx: 'created_at',
      sord: 'desc',
      allNetworks: [],
    };
  }

  componentDidMount() {
    this.fetch();
    this.setIntervalFetch();
    this.fetchAllNetworks();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.get(this.props, 'exports.currentPage', 1) !== _.get(nextProps, 'exports.currentPage', 1)) {
      this.setState({ page: nextProps.exports.currentPage });
    }

    if (!this.props.refetch && nextProps.refetch) {
      this.fetch();
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalFunc);
    this.setState({ intervalFunc: null });
  }

  onFiltersChange(key, activeFilters) {
    this.setState({
      page: 1,
      filters: { ...this.state.filters, [key]: activeFilters },
    }, () => this.fetch());
  }

  onNextClick() {
    const page = this.state.page + 1;
    clearInterval(this.state.intervalFunc);
    this.setState({ intervalFunc: null }, () => this.shouldFetch(page));
  }

  onPrevClick() {
    const page = this.state.page - 1;

    if (page === 1) {
      this.setIntervalFetch();
    }

    this.shouldFetch(page);
  }

  setIntervalFetch() {
    this.setState({ intervalFunc: setInterval(() => this.props.setExportsRefetch(), 60000) });
  }

  fetchAllNetworks() {
    const { groupId } = this.props;
    this.props.fetchNetworksByProviderIds({ ids: [groupId] })
      .then((allNetworks) => {
        this.setState({ allNetworks });
      });
  }

  openDialog() {
    return this.createExportDialog.openDialog();
  }

  closeDialog() {
    return this.createExportDialog.closeDialog();
  }

  fetch(page = 1) {
    this.setState({ loading: true });
    const { groupId } = this.props;
    const formattedFilters = _.reduce(
      this.state.filters,
      (acc, f, key) => {
        if (_.isEmpty(f)) {
          return acc;
        }
        return { ...acc, ...{ [key]: f } };
      },
      {},
    );

    const params = {
      filters: formattedFilters,
      page: {
       number: page,
       size: 100,
      },
      sidx: this.state.sidx,
      sord: this.state.sord,
    };

    this.props.fetchExports(groupId, params)
      .then(() => this.setState({ loading: false }));
  }

  shouldFetch(page) {
    if (isDataValid(this.props.exports, page)) {
      this.setState({ page });
    } else {
      this.fetch(page);
    }
  }

  render() {
    const {
      exports,
      exportEnums,
      refetch,
      isAssistanceRequestsExportsAvailable,
    } = this.props;
    const { loading, page } = this.state;
    const pagedExports = getDataOfPage(exports, this.state.page);

    const filteredExportEnums = {
      ...exportEnums,
      types: [...exportEnums.types],
    };

    if (!isAssistanceRequestsExportsAvailable) {
      filteredExportEnums.types = filteredExportEnums.types.filter(
        (type) => type.value !== 'assistance_requests' && type.value !== 'assistance_requests_supplemental_responses',
      );
    }

    const filters = [
      {
        key: 'export_types',
        name: 'Export Type',
        options: _.reduce(filteredExportEnums.types, (acc, s) => (
          _.concat(acc, {
            initial: _.includes(this.state.filters.type, 'contact'),
            label: s.display_name,
            value: s.value,
          })
        ), []),
      },
    ];
    return (
      <div className="exports table-view">
        <div className="container">
          <div className="table-view__header">
            <Button
              className="btn-raised btn-success"
              id="create-new-export-btn"
              label="New Export"
              onClick={this.openDialog}
              primary
            />
          </div>

          <BaseCard>
            <BaseCardHeader title="Exports">
              <FilterBar
                filters={filters}
                isFetching={exports.isFetching && !_.isEmpty(pagedExports.data)}
                onFiltersChange={this.onFiltersChange}
                onNextClick={this.onNextClick}
                onPrevClick={this.onPrevClick}
                paging={pagedExports.paging}
                topMargin={false}
              />
            </BaseCardHeader>
            <BaseCardBody>
              <ExportsTable
                exportEnums={filteredExportEnums}
                exports={pagedExports.data}
                loading={loading}
                page={page}
                refetch={refetch}
              />

              <Dialog
                id="create-export-dialog"
                modal
                ref={(c) => { this.createExportDialog = c; }}
                size="large"
                title="Create Export"
              >
                <ExportsCreateForm
                  closeDialog={this.closeDialog}
                  allNetworks={this.state.allNetworks}
                  exportEnums={filteredExportEnums}
                />
              </Dialog>
            </BaseCardBody>
          </BaseCard>
        </div>
      </div>
    );
  }
}

Exports.propTypes = {
  exportEnums: PropTypes.object.isRequired,
  exports: PropTypes.object.isRequired,
  fetchNetworksByProviderIds: PropTypes.func.isRequired,
  fetchExports: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  refetch: PropTypes.bool.isRequired,
  setExportsRefetch: PropTypes.func.isRequired,
  isAssistanceRequestsExportsAvailable: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    exportEnums: _.get(state, 'session.enums.exports', {}),
    exports: state.export.exports,
    groupId: _.get(state, 'session.groupId'),
    refetch: _.get(state, 'export.refetch', false),
    isAssistanceRequestsExportsAvailable: assistanceRequestsExportsAvailable(state),
  };
}

export default connect(mapStateToProps, {
  fetchNetworksByProviderIds,
  fetchExports,
  setExportsRefetch,
})(Exports);
