/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import { get, isEmpty } from 'lodash';
import { useFind, usePopulate, usePopulateMemo } from 'src/api/APIHooks';
import { replaceAndCapitalizeString } from 'common/utils/utils';

// const refetchInterval = { queryConfig: { refetchInterval: 30000 } };

const useFormSubmissions = ({
  contextType,
  context,
  size,
  page,
  providerId,
  formTypeFilter,
  formFilter,
  consolidate,
}) => {
  const filters = {
    'form.form_usages.provider': providerId,
    ...(formTypeFilter ? { 'form.form_usages.usage_type': formTypeFilter } : []),
    'context.type': contextType,
    context,
    ...(formFilter ? { form: formFilter } : []),
    ...(consolidate ? { consolidate } : []),
  };
  const queryOptions = {
    api: 'coreApi',
    page: {
      size,
      number: page,
    },
    queryConfig: {
      refetchInterval: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !isEmpty(providerId) && !isEmpty(context),
      placeholderData: undefined,
    },
  };
  const formSubmissionQuery = useFind('form_submission', filters, queryOptions);
  const formSubmissions = get(formSubmissionQuery, 'data.data.data', [], queryOptions);
  const paging = get(formSubmissionQuery, 'data.data.paging', {}, queryOptions);

  // const sqForm = usePopulate('form', 'form', formSubmissions);
  const sqProvider = usePopulate('provider', 'provider', formSubmissions, queryOptions);
  const submitterQuery = usePopulate('submitter', 'employee', formSubmissions, queryOptions);
  const fQuery = usePopulate('form', 'form', formSubmissions, {
    queryParams: {
      'form_usages.provider': providerId,
    },
  }, queryOptions);
  const formIds = formSubmissions.map((submission) => submission.form.id);
  const formUsagesQuery = useFind(
    'form_usages',
    {
      form: formIds.join(','),
      provider: providerId,
    },
    {
      queryConfig: {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        enabled: !isEmpty(providerId) && !isEmpty(context),
        placeholderData: undefined,
      },
    },
  );
  const formUsages = get(formUsagesQuery, 'data.data.data', [], queryOptions);

  const isFetching = usePopulateMemo(formSubmissionQuery.data, [
    formSubmissionQuery, submitterQuery, fQuery, sqProvider,
  ]);

  formSubmissions.forEach((submission) => {
    submission.usage_types = [];
    formUsages.map((usage) => {
      if (usage.form.id === submission.form.id) {
        const formattedUsageType = replaceAndCapitalizeString(usage.usage_type, '_');
        if (!submission.usage_types.includes(formattedUsageType)) {
          submission.usage_types.push(formattedUsageType);
        }
      }
    });
  });
  return {
    paging,
    formSubmissions,
    isFetching,
  };
};

export default useFormSubmissions;
