import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'common/utils/browserHistory';
import {
  useCreateFeeScheduleProgram,
} from 'src/components/Backoffice/api/hooks/v1/feeScheduleProgramHooks';
import {
  constructFeeScheduleProgramFormPayload,
 } from 'src/pages/fee-schedules/_id/utils/index';
 import { useUniteUsServices } from 'src/components/Backoffice/api/hooks/v1/serviceHooks';
import getServiceTypesWithParents from '../utils/getServiceTypesWithParents';
import FeeScheduleProgramForm from './components/FeeScheduleProgramForm';

const CreateFeeScheduleProgram = ({ params }) => {
  const feeScheduleId = params.id;
  const { createRecord: createFeeScheduleProgram } = useCreateFeeScheduleProgram();
  const [serviceId, setServiceId] = useState();
  const { data: parentServices } = useUniteUsServices();

  const onSubmit = async (feeScheduleProgram) => {
    const payload = constructFeeScheduleProgramFormPayload(feeScheduleProgram, feeScheduleId, serviceId);
    const servicesWithParents = getServiceTypesWithParents(feeScheduleProgram.services, Object.values(parentServices));
    payload.services = servicesWithParents;
    const response = await createFeeScheduleProgram(payload);
    if (response) {
      browserHistory.push({ pathname: `/backoffice/fee_schedules/${feeScheduleId}` });
    }
  };

  return (
    <FeeScheduleProgramForm onSubmit={onSubmit} feeScheduleId={feeScheduleId} setServiceId={setServiceId} />
  );
};

CreateFeeScheduleProgram.propTypes = {
  params: PropTypes.object.isRequired,
};

export default CreateFeeScheduleProgram;
