import _ from 'lodash';
import {
  removeAllGroupFields,
  removeSelectedGroupFields,
} from './removeGroupFields';

export default function filterBrowseGroups({
  groups, toRemoveIds, toAddIds, groupField,
}) {
  if (_.isEmpty(groups)) {
    removeAllGroupFields(groupField);
  } else {
    removeSelectedGroupFields(groupField, toRemoveIds);
  }

  const result = _.filter(groups, (group) => (
    _.includes(toAddIds, group.id)
  ));

  return result;
}
