import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { get } from 'lodash';
import { validateReduxForm } from 'common/form';
import { validations } from '@unite-us/app-components';
import {
  DateField,
} from '@unite-us/ui';
import today from 'common/utils/today';
import { Spinner } from 'common/spinners';
import FormFooter from 'common/form/FormButtons/components/FormFooter';
import { updateProgramInformation } from 'actions/Program/Case/Contact/Group';
import { fetchServiceCase, updateServiceCase } from 'actions/Case/Contact/Group';

export class ServiceCaseProgramEntryForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onSubmit(values) {
    const { groupId, serviceCase, contactId } = this.props;

    return this.props.updateServiceCase(groupId, contactId, serviceCase.id, {
      opened_date: values.opened_date,
    }).then(() => {
      this.props.fetchServiceCase(groupId, contactId, serviceCase.id);
      this.onClose();
    });
  }

  onClose() {
    this.props.closeModal();
  }

  render() {
    const {
      registerField, fields, handleSubmit, submitting,
    } = this.props;

    if (submitting) {
      return <Spinner text="Updating Program Entry Date..." />;
    }

    return (
      <form className="service-case-program-entry-form" onSubmit={handleSubmit(this.onSubmit)}>
        <DateField
          className="service-case-program-entry-form__date-field"
          id="service-case-program-entry-input__enrolled_at"
          ref={registerField}
          field={fields.opened_date}
          inline={false}
          label="Date Opened"
          validations={validations.isRequired}
          maxDate={today()}
          required
          style={{ container: { minHeight: '250px' } }}
        />
        <FormFooter
          onSubmitLabel="Save"
          handleSubmit={() => {
            handleSubmit(this.onSubmit)();
          }}
          submitDisabled={submitting}
          onCancel={this.onClose}
        />
      </form>
    );
  }
}

ServiceCaseProgramEntryForm.propTypes = {
  serviceCase: PropTypes.object.isRequired,
  contactId: PropTypes.string.isRequired,
  registerField: PropTypes.func.isRequired,
  fetchServiceCase: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateServiceCase: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {
      opened_date: get(ownProps, 'serviceCase.opened_date'),
    },
  };
}

const fields = [
  'opened_date',
];

export default validateReduxForm(
  {
    form: 'editServiceCaseProgramEntryForm',
    fields,
  },
  mapStateToProps,
  {
    fetchServiceCase,
    updateProgramInformation,
    updateServiceCase,
  },
)(ServiceCaseProgramEntryForm);
