// DEPRECATED COMPONENT, DO NOT USE. USE APP-COMPONENTS Spinner INSTEAD.
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Spinner.scss';

const Spinner = ({
  className,
  scale,
  style,
  text,
  textStyle,
  center,
}) => {
  const centerStyles = center ? ['flex', 'justify-center', 'h-full', 'items-center'] : [];
  return (
    <div
      aria-label="Loading"
      aria-live="polite"
      data-testid="spinner"
      className={classNames('spinner-container', centerStyles, className)}
      style={{ transform: `scale(${scale})` }}
    >
      <svg
        className="spinner"
        style={style}
        width="66px"
        height="66px"
        viewBox="0 0 66 66"
      >
        <circle
          className="path"
          fill="none"
          strokeWidth="6px"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
      {text && <h4 style={textStyle}>{text}</h4>}
    </div>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
  scale: PropTypes.number,
  style: PropTypes.object,
  text: PropTypes.string,
  textStyle: PropTypes.object,
  center: PropTypes.bool,
};

Spinner.defaultProps = {
  className: '',
  scale: 1,
  style: {},
  text: '',
  textStyle: {},
  center: false,
};

export default Spinner;
