import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { authClient } from 'src/api/config';
import onError from 'src/api/utils/onError';

const fetchEula = async () => {
  try {
    const response = await authClient.get('/users/accept_eula');
    if (!response || !isHttpSuccess(response.status)) {
      return onError(response);
    }
    return response;
  } catch (error) {
    return onError(error);
  }
};

export default {
  fetchEula,
};
