import { searchAPIClient as axios } from 'src/api/config';
import { merge, reduce, mapKeys, snakeCase } from 'lodash';
import { SEARCH_API } from 'src/config/env/env.config';
import { isHttpError } from 'common/utils/Error';

const createParams = (params = {}, page = {}) => {
  const referable = params.referable ?? {};

  const filters = {
    query: params.query,
    active: !(params.state && params.state === 'false'),
    services: params.services,
    genders: params.genders,
    age_in_months: params.age,
    'provider.type': params['provider.type'],
  };

  const providerNetworks = params.networks;
  const providerLicensed = params.licensed;
  const providerSensitive = typeof params.sensitive === 'boolean' ? params.sensitive : null;

  const locations = {
    latitude: params.locations?.radius.latitude || null,
    longitude: params.locations?.radius.longitude || null,
    distance: params.locations?.radius.distance || null,
  };

  const residency = {
    city: params.residency?.city || null,
    county: params.residency?.county || null,
    state: params.residency?.state || null,
    postal_code: params.residency?.postal_code || null,
  };

  const pageParsed = {
    number: page.page.number || null,
    size: page.page.size || null,
  };

  const include = 'provider';
  const sort = page.sort === 'name' ? 'provider-name-a-to-z' : 'distance';
  return merge(
    reduce(filters, (acc, f, key) => ({ ...acc, ...{ [`filter[${key}]`]: f } }), {}),
    reduce(locations, (acc, f, key) => ({ ...acc, ...{ [`filter[locations][radius][${key}]`]: f } }), {}),
    reduce(pageParsed, (acc, f, key) => ({ ...acc, ...{ [`page[${key}]`]: f } }), {}),
    reduce(referable, (acc, f, key) => ({ ...acc, ...{ [`filter[referable][${key}]`]: f } }), {}),
    reduce(residency, (acc, f, key) => ({ ...acc, ...{ [`filter[residency][${key}]`]: f } }), {}),
    providerNetworks && { 'filter[provider.networks]': providerNetworks },
    providerSensitive && { 'filter[provider.sensitive]': providerSensitive },
    { 'filter[provider.licensed]': providerLicensed },
    { 'filter[include_pathways]': params.include_pathways ?? false },
    { 'filter[include_payments]': params.include_payments ?? false },
    { include },
    { sort },
  );
};

export const responseTransformer = (data) => data.map((program) => {
  const document = program.document;

  const dataMap = {
    id: program.id,
    accessibiliy_options: document.accessibiliy_options ?? [],
    age_requirements: document.age_requirements ?? null,
    age_specializations: document.age_specializations ?? null,
    cities: document.cities ?? [],
    counties: document.counties ?? [],
    default: document.default,
    delivery_options: document.delivery_options ?? [],
    description: document.description,
    eligibility_text: document.eligibility_text,
    email_addresses: document.email_addresses,
    funds_allocation: document.funds_allocation ?? null,
    is_active: document.is_active,
    languages: document.languages,
    locations: document.locations,
    name: document.name,
    payment_options: document.payment_options,
    phone_numbers: document.phone_numbers,
    population_restrictions: document.population_restrictions,
    population_specializations: document.population_specializations,
    provider_id: program.relationships.provider.data.id,
    provider_name: program.relationships.provider.data.document.name,
    receiving_referrals: document.receiving_referrals,
    states: document.states ?? [],
    status: document.status,
    transportation_options: document.transportation_options,
    website_url: document.website_url,
  };
  const snakeCasedDataMap = mapKeys(dataMap, (value, key) => snakeCase(key));
  snakeCasedDataMap.focus = snakeCasedDataMap.focus ??
    mapKeys(snakeCasedDataMap.focus, (value, key) => snakeCase(key));
  return snakeCasedDataMap;
});

const fetchCaseProgramBrowseResultsFromSearch = async (params, page, additionalProgramIds = []) => {
  const searchProgramsUrl = `${SEARCH_API}/programs`;
  const parsedParams = createParams(params, page);
  const promises = [
    axios.get(searchProgramsUrl, {
      params: parsedParams,
      transformResponse: (res) => res, // prevent Axios running JSON.parse() on data
    }),
  ];
  if (additionalProgramIds.length) {
    promises.push(axios.get(searchProgramsUrl, {
      params: {
        'filter[id]': additionalProgramIds.join(','),
        include: 'provider',
      },
      transformResponse: (res) => res,
    }));
  }

  let results = {
    data: {
      data: [],
      meta: {
        page: 1,
      },
      paging: {
        current_page: 1,
        next_page: null,
        per: 50,
        prev_page: null,
        total_count: undefined,
        total_pages: undefined,
      },
    },
  };
  try {
    const [programsResponse, additionalProgramsResponse] = await Promise.all(promises);
    const resData = JSON.parse(programsResponse.data);
    const additionalProgramsData = additionalProgramsResponse ? JSON.parse(additionalProgramsResponse.data) : null;

    const currPage = page.page.number || 1;

    const paging = {
      current_page: currPage,
      next_page: resData.meta.page.total_pages > currPage ? currPage + 1 : null,
      per: page.page.size,
      prev_page: currPage > 1 ? currPage - 1 : null,
      total_count: resData.meta.page.total_count,
      total_pages: resData.meta.page.total_pages,
    };

    const transformedData = responseTransformer(resData.data);
    const additionalProgramsTransformedData = additionalProgramsData ?
      responseTransformer(additionalProgramsData.data) : [];

    results = {
      ...programsResponse,
      data: {
        paging,
        data: [...transformedData, ...additionalProgramsTransformedData],
        meta: resData.meta,
      },
    };
  } catch (e) {
    // if anything goes wrong with axios or parsing, log the error and return default result
    if (window.Rollbar) {
      if (isHttpError(e)) {
        window.Rollbar.warning('Error retrieving programs for OON case', e.response);
      } else {
        window.Rollbar.warning('Error parsing programs for OON case', e);
      }
    }
  }
  return results;
};

export default fetchCaseProgramBrowseResultsFromSearch;
