import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'common/utils/browserHistory';
import classNames from 'classnames';
import {
  Button,
  Dialog,
  Icon,
} from '@unite-us/ui';
import { getGroupReferralScopesFromState } from 'src/components/Groups/utils/referralScope';
import { searchNetworkGroupsByReferralScopes, fetchNetworkGroupsByReferralScope } from 'actions/Group/Network';
import { Spinner } from 'common/spinners';
import { goToReferralsIndex } from 'src/components/Referrals/utils/routing';
import _ from 'lodash';
import { REFERRAL } from 'common/utils/EventTracker/utils/eventConstants';

// Actions
import { attachReferralDocuments } from 'actions/Document/Contact/Referral/Group';
import {
  removeReferralDocument,
  renameReferralDocument,
} from 'actions/Document/Referral/Contact/Group';
import { deleteReferral, fetchGroupsReferral } from 'actions/Referral/Group';
import { notifyAutoRecalledCannotView } from 'actions/Notifier';
import { fetchGroupsPrograms } from 'actions/Program/Group';

// Components
import ReferralInteractions from 'src/components/Referrals/components/ReferralInteractions';
import ReferralDetailCaseHistory from
  'src/components/Referrals/ReferralDetail/components/ReferralDetailCaseHistory';
import ReferralDetailReferralHistory from
  'src/components/Referrals/ReferralDetail/components/ReferralDetailReferralHistory';
import AsideColumn from 'src/components/Dashboard/components/AsideColumn';
import Notes from 'src/components/Notes/Notes';

// Utils
import callOrLog from 'common/utils/callOrLog';
import { isEditableReferral } from 'src/components/Referrals/utils';
import { isReferredToGroup } from 'src/components/Referrals/ReferralStatus/utils';
import canView from 'src/common/utils/SecureLink/utils/canView';
import isReferralForwarded from 'src/components/Referrals/ReferralDetail/utils/isReferralForwarded';
import isReferralClosedByCoordinationCenter from 'src/components/Referrals/ReferralDetail/utils/isReferralClosedByCoordinationCenter';

// Constants
import {
  REFERRAL_STATUS_AUTO_RECALLED,
  REFERRAL_STATUS_DRAFT,
} from 'src/components/Referrals/constants';

import getContactFromState from 'src/components/Contacts/utils/getContactFromState';
import FormSubmissions from 'src/components/FormSubmissions/FormSubmissions';
import ReferralDetailTop from 'src/components/Referrals/ReferralDetail/components/ReferralDetailTop';

// Stylesheets
import './stylesheets/referralDetail.scss';

export class ReferralDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSendable: true,
      isSensitive: false,
      isReferralFetching: true,
      showActions: true,
      suggestedGroups: [],
      showInteractions: false,
      firstTimeFocus: false,
    };

    this.attachDocuments = this.attachDocuments.bind(this);
    this.falsifyActionBool = this.falsifyActionBool.bind(this);
    this.fetchReferral = this.fetchReferral.bind(this);
    this.onDeleteModalCancel = this.onDeleteModalCancel.bind(this);
    this.onDeleteModalConfirmation = this.onDeleteModalConfirmation.bind(this);
    this.removeDocument = this.removeDocument.bind(this);
    this.renameDocument = this.renameDocument.bind(this);
    this.showDeleteModal = this.showDeleteModal.bind(this);
  }

  componentDidMount() {
    this.fetchReferral();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.refetch !== nextProps.refetch) {
      if (nextProps.refetch && !nextProps.isAssessmentsFetching) {
        this.fetchReferral();
      }
    }
  }

  componentDidUpdate() {
    if (this.state.showInteractions && this.state.firstTimeFocus) {
      const focusElem = document.getElementById('interactions-interaction-tab');
      focusElem.focus();
      this.setState({ firstTimeFocus: false });
    }
  }

  onDeleteModalCancel() {
    this.deleteModal.closeDialog();
  }

  onDeleteModalConfirmation() {
    const {
      groupId,
      referral: {
        id: referralId,
      },
    } = this.props;

    this.props.deleteReferral(groupId, referralId, true)
      .then(() => {
        this.deleteModal.closeDialog();
        goToReferralsIndex(REFERRAL_STATUS_DRAFT);
      });
  }

  async confirmSendableStatus(referral) {
    const providerDrafts = referral.referred_to_groups_draft;

    if (!_.isEmpty(providerDrafts)) {
      const programIds = _.map(providerDrafts, 'program.id');
      const providerNoPrograms = programIds.filter((prog) => prog === undefined);

      // If lengths do not match, referral contains no programs -- no state change needed
      if (providerNoPrograms.length !== programIds.length) {
        try {
          const adjustedProgramIds = programIds.filter((prog) => prog !== undefined);
          const payload = await this.props.fetchGroupsPrograms(null, { ids: adjustedProgramIds.join(',') });
          const programStatus = _.map(payload.data.data, 'attributes.is_active');
          const receivingStatus = _.map(payload.data.data, 'attributes.receiving_referrals');

          if (_.includes(programStatus, false) || _.includes(receivingStatus, false)) {
            this.setState({ isSendable: false });
          }
        } catch (e) {
          this.setState({ isSendable: false });
        }
      }
    }
  }

  falsifyActionBool() {
    this.setState({ showActions: false });
  }

  showDeleteModal() {
    this.deleteModal.openDialog();
  }

  fetchReferral() {
    const {
      groupId, location, params,
    } = this.props;
    const referralId = _.get(params, 'id', '');

    this.props.fetchGroupsReferral(groupId, referralId)
      .then(async (response) => {
        if (response === 'sensitive') {
          this.setState({
            isSensitive: true,
            isReferralFetching: false,
          });
          return;
        }
        const referral = _.get(response, 'data.data', {});

        const fetchedReferralId = referral.id || '';
        const status = referral.status;

        if (status === REFERRAL_STATUS_AUTO_RECALLED && isReferredToGroup(referral, groupId)) {
          this.props.notifyAutoRecalledCannotView();
          browserHistory.replace('/dashboard/new/referrals');
          return;
        }

        if (fetchedReferralId !== referralId) {
          const newLocation = location.pathname.replace(referralId, fetchedReferralId);
          browserHistory.replace(newLocation);
          return;
        }

        await this.confirmSendableStatus(referral);

        this.setState({ isReferralFetching: false });
      });
  }

  fetchNetworkGroupsByReferralScope(referral, referralScopes, groupId) {
    const referralScope = _.find(
      referralScopes,
      (scope) => (
        scope.network.id === referral.referred_by_network.id &&
        scope.permitted_network.id === null
      ),
    ) || {};
    const isOON = undefined;
    return fetchNetworkGroupsByReferralScope({
      referredByNetworkId: _.get(referral, 'referred_by_network.id', null),
      referredToNetworkId: _.get(referral, 'referred_to_network.id', null),
      scope: referralScope,
      searchNetworkGroups: this.props.searchNetworkGroupsByReferralScopes,
      serviceTypeId: referral.service_type.id,
      serviceTypes: this.props.serviceTypes,
      groupId,
      isOON,
    });
  }

  goToNotes() {
    this.interactions.scrollIntoView();
  }

  attachDocuments(documents = {}) {
    const { attachedDocuments, uploadedDocuments } = documents;
    const { groupId, referral } = this.props;

    return this.props.attachReferralDocuments({
      attachedDocuments,
      uploadedDocuments,
      groupId,
      referral,
    }).then(() => {
      this.fetchReferral();
      callOrLog(() => this.context.eventTracker(REFERRAL.documentUploaded, null, {
        referral,
      }));
    });
  }

  removeDocument(doc = {}) {
    const { groupId, referral } = this.props;

    return this.props.removeReferralDocument({
      contactId: referral.contact.id,
      groupId,
      documentId: doc.id,
      caseId: referral.case.id,
    });
  }

  renameDocument(doc) {
    this.props.renameReferralDocument({
      contactId: _.get(this.props.referral, 'contact.id'),
      documentId: doc.id,
      title: doc.title,
    });
  }

  render() {
    const {
      canViewContact,
      contact,
      employeeProviderId,
      groupId,
      referral,
      referralDocuments,
      isReferralsAdminUser,
      isDraft,
      showContactColumn,
      type,
      location = {},
      currentProviderId,
      currentProviderType,
      isCCUser,
    } = this.props;

    const {
      isReferralFetching,
      isSendable,
      isSensitive,
      showInteractions,
    } = this.state;

    if (this.state.isReferralFetching) {
      return <Spinner />;
    }

    const editableReferral = isEditableReferral(referral, groupId, isCCUser);

    const title = () => {
      const fullName = _.get(contact, 'full_name');
      return fullName ? `${fullName}'s Referral` : 'Referral';
    };
    const gridClass = () => classNames({
      'col-md-9': showContactColumn,
      'col-md-12': !showContactColumn,
    });

    const buttonClass = () => classNames({
      'service-type-action': true,
      'referral-details-view__delete-btn': true,
      'service-type-action--hide': !isDraft,
    });

    const sensitiveClass = () => classNames({
      'mt-6': true,
      'color-grey': true,
      'text-center': true,
      'py-8': true,
      'px-6': true,
      'mb-6': true,
      rounded: true,
      'border-solid': true,
      border: true,
      'border-dark-border-blue': true,
      'bg-dark-fill-blue': true,
    });

    const contactId = _.get(referral, 'contact.id');
    const description = (referral && referral.description) || '';
    const serviceCase = _.get(referral, 'case', null);
    const forwardedReferral = isReferralForwarded({ serviceCase, referral });
    const showNotes = (
      employeeProviderId === referral.sending_provider.id ||
      employeeProviderId === referral.receiving_provider.id ||
      currentProviderType === 'coordination_center'
    );
    let showCase = referral.status && referral.status === 'accepted';
    // CRTB-886: Prevent users from seeing cases on forwarded referrals when they're not ready to be viewed
    if (forwardedReferral && !['managed', 'off_platform'].includes(referral.case.state)) {
      showCase = false;
    }
    const isClosedByCoordinationCenter = isReferralClosedByCoordinationCenter(referral);
    const TopSectionEl = () => (
      <ReferralDetailTop
        isCCUser={isCCUser}
        groupId={groupId}
        gridClass={gridClass}
        referral={referral}
        title={title}
        serviceCase={serviceCase}
        forwardedReferral={forwardedReferral}
        buttonClass={buttonClass}
        showDeleteModal={this.showDeleteModal} // eslint-disable-line
        isSensitive={isSensitive}
        description={description}
        isReferralFetching={isReferralFetching}
        referralDocuments={referralDocuments}
        contactId={contactId}
        editableReferral={editableReferral}
        attachDocuments={this.attachDocuments} // eslint-disable-line
        removeDocument={this.removeDocument} // eslint-disable-line
        renameDocument={this.renameDocument} // eslint-disable-line
        canViewContact={canViewContact}
        isDraft={isDraft}
        isReferralsAdminUser={isReferralsAdminUser}
        type={type}
        showActions={this.state.showActions} // eslint-disable-line
        suggestedGroups={this.state.suggestedGroups} // eslint-disable-line
        goToNotes={this.goToNotes} // eslint-disable-line
        falsifyActionBool={this.falsifyActionBool} // eslint-disable-line
        isSendable={isSendable}
        location={location}
        isClosedByCoordinationCenter={isClosedByCoordinationCenter}
      />
    );

    if (isSensitive) {
      return (
        <div className="referral-details-view row">
          <div className={gridClass()}>
            {TopSectionEl()}
            <div
              className={sensitiveClass()}
            >
              <Icon icon="IconLock" className="mr-2" />
              <span>
                This referral is for a sensitive service so you don’t have access to all Referral and Case information.
              </span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="referral-details-view row">
        <div className={gridClass()}>
          {TopSectionEl()}
          <div className="mt-12">
            <FormSubmissions
              context={referral.case.id}
              contextType="case"
              providerId={currentProviderId}
              serviceId={_.get(serviceCase, 'service.id')}
            />
          </div>
          <div>
            {
              showInteractions ? (
                <div className="mt-10">
                  <ReferralInteractions
                    referral={referral}
                    eventTracker={this.context.eventTracker}
                    ref={(el) => { this.interactions = el; }}
                    cancelFunction={() => this.setState({ showInteractions: false })}
                    styles={{ marginBottom: '20px' }}
                  />
                </div>
              ) : null
            }
            <div className="mt-10">
              <Notes
                editable={showNotes}
                providerId={serviceCase.provider.id}
                subject={referral.id}
                reason={_.get(referral, 'reason', null)}
                subjectType="referral"
                onAddNote={() => {
                  this.setState({
                    showInteractions: true,
                    firstTimeFocus: true,
                  });
                }}
              />
            </div>
          </div>
          {
            showCase ? (
              <ReferralDetailCaseHistory
                caseId={_.wget(referral, 'case.id', '')}
                groupId={groupId}
              />
            ) : null
          }
          <ReferralDetailReferralHistory
            referral={referral}
            groupId={groupId}
            isClosedByCoordinationCenter={isClosedByCoordinationCenter}
          />

          <Dialog
            id="delete-draft-modal"
            ref={(el) => { this.deleteModal = el; }}
            title="Delete"
            size="mini"
            actions={(
              <div>
                <Button
                  id="delete-draft-cancel-btn"
                  label="Cancel"
                  onClick={this.onDeleteModalCancel}
                  primary
                />
                <Button
                  id="delete-draft-btn"
                  onClick={this.onDeleteModalConfirmation}
                  label="Delete"
                  primary
                  style={{ marginLeft: '10px' }}
                />
              </div>
            )}
          >
            <p>Are you sure you want to delete this draft?</p>
          </Dialog>
        </div>

        {
          showContactColumn ? (
            <div className="pl-0 col-md-3">
              <AsideColumn
                contact={contact}
                detailObj={referral}
                type={type}
              />
            </div>
          ) : null
        }
      </div>
    );
  }
}

ReferralDetail.propTypes = {
  attachReferralDocuments: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired,
  canViewContact: PropTypes.bool,
  deleteReferral: PropTypes.func.isRequired,
  employeeProviderId: PropTypes.string.isRequired,
  fetchGroupsReferral: PropTypes.func.isRequired,
  fetchGroupsPrograms: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isAssessmentsFetching: PropTypes.bool.isRequired,
  isCCUser: PropTypes.bool.isRequired,
  isDraft: PropTypes.bool,
  isReferralsAdminUser: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  notifyAutoRecalledCannotView: PropTypes.func.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  referral: PropTypes.shape({
    status: PropTypes.string,
    case: PropTypes.object,
    description: PropTypes.string,
    id: PropTypes.string,
    service_type: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    receiving_provider: PropTypes.shape({
      id: PropTypes.string,
    }),
    referred_by_network: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    referred_to_network: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    contact: PropTypes.shape({
      full_name: PropTypes.string,
      id: PropTypes.string.isRequired,
      permissions: PropTypes.object,
    }),
    permissions: PropTypes.object,
    sending_provider: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  referralDocuments: PropTypes.array.isRequired,
  refetch: PropTypes.bool,
  removeReferralDocument: PropTypes.func.isRequired,
  renameReferralDocument: PropTypes.func.isRequired,
  searchNetworkGroupsByReferralScopes: PropTypes.func.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  showContactColumn: PropTypes.bool,
  type: PropTypes.string.isRequired,
  currentProviderId: PropTypes.string.isRequired,
  currentProviderType: PropTypes.string.isRequired,
};

ReferralDetail.defaultProps = {
  isDraft: false,
  isReferralsAdminUser: false,
  referral: {},
  refetch: false,
  showContactColumn: true,
  canViewContact: false,
};

ReferralDetail.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const {
    referrals,
    user,
    referralDocuments,
    globalState,
  } = state;

  const { location } = ownProps;
  const allReferrals = [...referrals.sent, ...referrals.received];
  const { groupId, globals } = state.session;
  const referralId = ownProps.params.id;
  const isDraft = _.get(location, 'pathname', '')
    .indexOf(REFERRAL_STATUS_DRAFT) !== -1;
  const referralObj = allReferrals.find((r) => r.id === referralId) || {};
  const referral = {
    ...referralObj,
    status: referralObj.state,
  };
  const contact = getContactFromState(state, _.get(referral, 'contact.id'));
  const userGroup = user.groups.find((g) => g.group.id === groupId);
  const userRoleKeys = userGroup ? userGroup.roles.map((r) => r.role_key) : [];
  const isReferralsAdminUser =
    userRoleKeys.includes('legacy_referrals_admin_user') ||
    userRoleKeys.includes('referrals_admin') ||
    userRoleKeys.includes('oversight') ||
    userRoleKeys.includes('org_admin');

  const referralScopes = getGroupReferralScopesFromState({ state, groupId });
  const serviceTypes = globals.service_types || [];
  const canViewContact = canView(contact);

  return {
    currentProviderId: globalState.currentProvider.group.id,
    currentProviderType: globalState.currentProvider.group.provider_type,
    canViewContact,
    contact,
    employeeProviderId: globalState.currentEmployee.provider.id,
    isReferralsAdminUser,
    groupId,
    isAssessmentsFetching: state.groupForms.isFetching,
    isCCUser: state.session.isCoordinationGroup,
    isDraft,
    referral,
    referralId,
    referralDocuments,
    refetch: referrals.refetch,
    referralScopes,
    serviceTypes,
  };
}

export default connect(mapStateToProps, {
  attachReferralDocuments,
  deleteReferral,
  fetchGroupsReferral,
  notifyAutoRecalledCannotView,
  removeReferralDocument,
  renameReferralDocument,
  fetchNetworkGroupsByReferralScope,
  searchNetworkGroupsByReferralScopes,
  fetchGroupsPrograms,
})(ReferralDetail);
