import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ChartMultiBar } from 'src/common/charts';
import { fetchContactsReports } from 'actions/Report/Contact';
import _ from 'lodash';
import { ethnicityPartitions } from '../../utils/partitions';

export default class ContactsRaceByEthnicity extends Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.state = {
      contactsRaceByEthnicity: [],
      title: '',
    };
  }

  componentDidMount() {
    this.fetchData(this.props.filters);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const filtersChanged = !_.isEqual(this.props.filters, nextProps.filters);
    const networkChanged = !_.isEqual(this.props.id, nextProps.id);

    if (filtersChanged || networkChanged) {
      this.fetchData(nextProps.filters, nextProps.id);
    }
  }

  fetchData(filters, id = this.props.id) {
    const { scope } = this.props;

    fetchContactsReports(scope, id, 'race/by-ethnicity', { partitions: ethnicityPartitions(), ...filters })
      .then((response) => {
        const sorted = _.orderBy(_.get(response, 'data.values', []), 'title', 'asc');
        this.setState({
          contactsRaceByEthnicity: _.assign({}, response.data, { values: sorted }),
          title: _.get(response, 'data.title', ''),
        });
        this.props.loaded('contactsRaceByEthnicity');
      });
  }

  render() {
    if (_.isEmpty(this.state.contactsRaceByEthnicity)) {
      return null;
    }
    return (
      <ChartMultiBar
        data={this.state.contactsRaceByEthnicity}
        x={(d) => d.title}
        y={(d) => d.total}
        title={this.state.title}
        margin={{ left: 60, top: 60, bottom: 60 }}
        showValues
        showYAxis
        showLegend
        stacked
        valueFormat="d"
        useInteractiveGuideline={false}
        showControls
        maxKeyLength={40}
        colors={[
          '#74B57D',
          '#FF9900',
          '#E8E879',
          '#7ED1A3',
          '#4A657F',
        ]}
        style={{ height: '400px' }}
        xLabel="Race"
        colWidths={{
          x: '270px',
        }}
      />
    );
  }
}

ContactsRaceByEthnicity.propTypes = {
  filters: PropTypes.shape({
    end_date: PropTypes.string,
    network_ids: PropTypes.array,
    start_date: PropTypes.string,
  }),
  loaded: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
};
