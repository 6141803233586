import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import { connect } from 'react-redux';
import { Spinner } from 'common/spinners';
import ConditionalTooltip from 'common/ConditionalTooltip/ConditionalTooltip';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Divider,
} from '@unite-us/ui';
import classNames from 'classnames';
import _ from 'lodash';
import { isHttpError } from 'common/utils/Error';
import AssistanceRequestSelectClient from 'src/components/AssistanceRequests/components/AssistanceRequestDetail/AssistanceRequestSelectClient';
import FormSubmissions from 'src/components/FormSubmissions/FormSubmissions';
import DetailLabelWrapper from 'common/display/SingleItemDetails/DetailLabelWrapper';
import DetailOutcomeWrapper from 'common/display/SingleItemDetails/DetailOutcomeWrapper';
import DetailStatusText from 'common/display/SingleItemDetails/DetailStatusText';
import { dividerStatusStyles } from 'common/display/SingleItemDetails/utils/common';
import clearContacts from 'common/display/ContactStepper/actions/ClearContacts';
import getPersonChanges from 'actions/AssistanceRequest/Group/utils/getPersonChanges';
import {
  createAssistanceRequestCaseRelationship,
  fetchAssistanceRequestDetails,
  fetchGroupAssistanceRequestsConfig,
} from 'actions/AssistanceRequest/Group';
import createContact from 'common/display/ContactStepper/actions/CreateContact';
import { destroyForm, resetForm } from 'actions/Form';
import { fetchGroupContact } from 'actions/Contact/Group';
import { searchContact } from 'actions/Search/Group';
import callOrLog from 'src/common/utils/callOrLog';
import { ASSISTANCE_REQUEST, DASHBOARD_EVENTS, DASHBOARD_VIEWS } from 'common/utils/EventTracker/utils/eventConstants';
import featureFlag from 'common/utils/FeatureFlag/FeatureFlag';
import generateDetailLabelData from 'common/display/SingleItemDetails/utils/AssistanceRequestLabel';
import retrieveContactFromAr from 'src/components/AssistanceRequests/utils/retrieveContactFromAr';
import {
  REFERRALS_CREATE_REFERRALS,
} from 'src/common/utils/FeatureFlag/utils/constants';
import { goToConfirmStep } from 'common/display/NewItemStepper/utils';
import { hasNewSearchAndMatch, uup459SupersetPhase2, uup459SupersetRelease3 } from 'common/utils/FeatureFlags/flags';
import { CREATE_REFERRAL_FORM } from 'src/components/Referrals/constants';
import AsideColumn from 'src/components/Dashboard/components/AsideColumn';
import ServiceCaseReferralHistory from 'src/components/Cases/components/Detail/ServiceCaseReferralHistory';
import { canViewCase } from 'src/components/Referrals/ReferralStatus/utils/referralActions';
import { referralStatuses } from 'src/components/ReferralHistory/utils/referralStatusConstants';
import AssistanceRequestDescription from './AssistanceRequestDetail/AssistanceRequestDescription';
import AssistanceRequestActions from './AssistanceRequestDetail/AssistanceRequestActions';
import CloseAssistanceRequestDialog from './CloseAssistanceRequestDialog';
import ConfirmUseThisRecordDialog from './AssistanceRequestDetail/ConfirmUseThisRecordDialog';

import '../stylesheets/assistance-request.scss';

const DISABLED_ACTIONS_MESSAGE = `Take action button is disabled until you select an
existing client record or create a new client.`;

function title(name) {
  return name ? `${name}'s Assistance Request` : 'Assistance Request';
}

export class AssistanceRequestDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actionLoading: false,
      confirmRecordModalData: {
        personChanges: {},
        personData: {},
        personId: null,
      },
    };

    this.createContact = this.createContact.bind(this);
    this.createCase = this.createCase.bind(this);
    this.createAssistanceRequestCase = this.createAssistanceRequestCase.bind(this);
    this.createIntake = this.createIntake.bind(this);
    this.createReferral = this.createReferral.bind(this);
    this.openCloseAssistanceRequestDialog = this.openCloseAssistanceRequestDialog.bind(this);
    this.searchContact = this.searchContact.bind(this);
    this.resetActionValue = this.resetActionValue.bind(this);
    this.openConfirmUseThisRecordDialog = this.openConfirmUseThisRecordDialog.bind(this);
    this.createNewClientFromAssistanceRequest = this.createNewClientFromAssistanceRequest.bind(this);
    this.closeConfirmUseThisRecordDialog = this.closeConfirmUseThisRecordDialog.bind(this);
  }

  componentDidMount() {
    const { groupId, requestId, needsActionStatus } = this.props;
    const requestModel = needsActionStatus ? 'assistance_request' : 'case';

    this.props.clearContacts();
    this.props.fetchAssistanceRequestDetails(groupId, requestId, requestModel);
    this.props.fetchGroupAssistanceRequestsConfig(groupId);
  }

  openConfirmUseThisRecordDialog = (personId = null, personData = {}) => {
    const { assistanceRequest } = this.props;

    const personChanges = getPersonChanges(personData, assistanceRequest.requestor);

    this.setState({
      confirmRecordModalData: {
        personChanges,
        personData,
        personId,
      },
    }, () => {
      const hasAdditions = personChanges?.additions &&
                            Object.values(personChanges?.additions).some((arr) => arr.length > 0);
      const hasUpdates = personChanges?.updates && Object.keys(personChanges?.updates).length > 0;

      if (hasAdditions || hasUpdates) {
        this.confirmUseThisRecordDialog.openDialog();
      } else {
        this.createAssistanceRequestCase();
      }
    });
  }

  searchContact() {
    const {
      groupId,
      assistanceRequest: {
        requestor: searchParams,
      },
    } = this.props;

    return this.props.searchContact(groupId, searchParams);
  }

  createContact() {
    const {
      groupId,
      assistanceRequest: {
        requestor: searchParams,
      },
      requestId,
    } = this.props;

    return this.props.createContact({
      assistanceRequestId: requestId,
      contact: searchParams,
      groupId,
      showNotification: false,
    }).then((payload) => {
      if (isHttpError(payload)) {
        return payload;
      }
      return payload;
    });
  }

  createCase() {
    return this.searchContact()
      .then((response) => {
        const {
          assistanceRequest,
          groupId,
          baseRequestUrl,
          useNewSearchAndMatch,
        } = this.props;

        const data = _.get(response, 'data.data', []);
        const contacts = _.map(data, (contact) => _.get(contact, 'item.result'));
        callOrLog(() => this.context.eventTracker(
          ASSISTANCE_REQUEST.clickedOutOfNetworkCase,
          null,
          { assistanceRequest },
        ));
        const assistanceRequestContactId = _.get(assistanceRequest, 'contact.id', '');

        if (assistanceRequestContactId) {
          return this.props.fetchGroupContact(groupId, assistanceRequestContactId)
            .then(() => {
              browserHistory.push(
                `${baseRequestUrl}/contacts/${assistanceRequestContactId}/cases/new/add-case-details`,
              );
            });
        }

        if (contacts.length > 0) {
          this.setState({ actionLoading: false });
          if (useNewSearchAndMatch) {
            return goToConfirmStep({
              context: `assistance-requests/${assistanceRequest.id}/contacts`,
              contactData: assistanceRequest.requestor,
            });
          }
          return browserHistory.push(
            `${baseRequestUrl}/contacts/new/confirm`,
          );
        }
        return this.createContact().then((contact) => {
          this.setState({ actionLoading: false });
          const contactId = _.wget(contact, 'data.data.id', '');
          browserHistory.push(
            `${baseRequestUrl}/contacts/${contactId}/cases/new/add-case-details`,
          );
        });
      });
  }

  createReferral() {
    return this.searchContact()
      .then((response) => {
        const {
          assistanceRequest,
          groupId,
          baseRequestUrl,
          useNewSearchAndMatch,
          isSupersetEnabled,
          uup459SupersetRelease3Flag,
          requestId,
        } = this.props;
        const data = _.get(response, 'data.data', []);
        const contacts = _.map(data, (contact) => contact.item.result);

        callOrLog(() => this.context.eventTracker(
          ASSISTANCE_REQUEST.clickedRefer,
          null,
          { assistanceRequest },
        ));
        this.props.destroyForm(CREATE_REFERRAL_FORM);
        window.localStorage.removeItem('referralsProgress');

        const assistanceRequestContactId = _.get(assistanceRequest, 'contact.id', '');
        const path = (isSupersetEnabled && uup459SupersetRelease3Flag) ?
          `/referrals/create/add-resources?person=${assistanceRequestContactId}&assistance_request=${requestId}` :
          `${baseRequestUrl}/referrals/new/add-service-types`;

        // Known contact id is associated with AR, redirect to new referral form.
        if (assistanceRequestContactId) {
          return this.props.fetchGroupContact(groupId, assistanceRequestContactId)
            .then(() => {
              this.resetActionValue();
              browserHistory.push(path);
            });
        }

        // AR requestor is not yet a known contact, but multiple existing
        // contacts with matching name/dob exist.  Redirect to contact
        // confirmation view.
        if (contacts.length > 0) {
          this.resetActionValue();
          if (useNewSearchAndMatch) {
            return goToConfirmStep({
              context: `assistance-requests/${assistanceRequest.id}/referrals`,
              contactData: assistanceRequest.requestor,
            });
          }
          return browserHistory.push(`${baseRequestUrl}/referrals/new/confirm`);
        }

        // AR requestor is not yet a known contact and no contacts with matching
        // name/dob exist. Create contact and redirect to new referral form.
        return this.createContact().then(() => {
          this.resetActionValue();
          browserHistory.push(path);
        });
      });
  }

  createIntake() {
    const {
      assistanceRequest,
      useNewSearchAndMatch,
    } = this.props;
    const { contact } = assistanceRequest;
    callOrLog(() => this.context.eventTracker(DASHBOARD_EVENTS.goToIntake, {
      view: DASHBOARD_VIEWS.assistanceRequestDetailView,
    }));

    // Scenario 1 - If AR is associated with contact, go directly to the intake
    if (contact) {
      return browserHistory.push(`/intakes/new?contactId=${contact.id}&arId=${assistanceRequest.id}`);
    }

    return this.searchContact()
      .then((response) => {
        // Scenario 2 - AR not associated with contact, contact match exists, user can select, navigate to intake
        this.resetActionValue();
        const data = _.get(response, 'data.data', []);
        const contacts = _.map(data, (c) => c.item.result);

        if (contacts.length > 0) {
          if (useNewSearchAndMatch) {
            // pull out requestor id so it will not be treated as a contact id.
            const { id, ...requestorNoId } = assistanceRequest.requestor;

            return goToConfirmStep({
              context: 'intakes/contacts',
              contactData: requestorNoId,
              arId: assistanceRequest.id,
            });
          }
          return browserHistory.push(`/intakes/confirm?arId=${assistanceRequest.id}`);
        }

        // Scenario 3 - AR not associated with contact, no contact exists, create contact then navigate to intake
        return this.createContact()
          .then((contactResponse) => {
            const contactId = contactResponse.data.data.id;
            browserHistory.push(`/intakes/new?contactId=${contactId}&arId=${assistanceRequest.id}`);
          });
      });
  }

  closeConfirmUseThisRecordDialog() {
    this.confirmUseThisRecordDialog.closeDialog();
  }

  openCloseAssistanceRequestDialog() {
    return this.arCloseDialog.dialog.openDialog()
      .then(this.resetActionValue);
  }

  resetActionValue() {
    this.assistanceRequestActions.actionSelect.resetValue();
  }

  async createAssistanceRequestCase() {
    const { requestId, groupId } = this.props;
    const { personId, personChanges, personData } = this.state.confirmRecordModalData;

    this.setState({ actionLoading: true }, async () => {
      this.closeConfirmUseThisRecordDialog();

      await createAssistanceRequestCaseRelationship(
        requestId,
        personData,
        personChanges,
        personId,
      );

      this.props.fetchAssistanceRequestDetails(groupId, requestId, 'assistance_request');

      this.setState({ actionLoading: false });
    });
  }

  async createNewClientFromAssistanceRequest() {
    const { requestId, groupId } = this.props;

    this.setState({ actionLoading: true }, async () => {
      await createAssistanceRequestCaseRelationship(requestId);

      this.props.fetchAssistanceRequestDetails(groupId, requestId, 'assistance_request');

      this.setState({ actionLoading: false });
    });
  }

  render() {
    const {
      assistanceRequest,
      flagEnabled,
      groupId,
      isCoordinationGroup,
      requestId,
      showContactColumn,
      type,
      currentProvider,
      clientMatches,
    } = this.props;
    const { actionLoading } = this.state;
    const requestor = _.get(assistanceRequest, 'requestor', {});
    const requestorFirstName = _.get(requestor, 'first_name', '');
    const requestorFullName = _.get(requestor, 'full_name');
    const caseState = _.get(assistanceRequest, 'state');
    const viewCase = canViewCase(assistanceRequest);

    const actions = _.compact([
      !isCoordinationGroup && {
        label: `Serve ${requestorFirstName}`,
        value: 'serve',
        className: 'take-action-serve',
        action: this.createCase,
      },
      flagEnabled(REFERRALS_CREATE_REFERRALS) && {
        label: `Refer ${requestorFirstName}`,
        value: 'refer',
        className: 'take-action-refer',
        action: this.createReferral,
      },
      {
        label: 'Close Request',
        value: 'closeRequest',
        className: 'take-action-close',
        action: this.openCloseAssistanceRequestDialog,
      },
    ]);

    const gridClass = () => classNames({
      'detail-info': true,
      'col-sm-9': showContactColumn,
      'col-sm-12': !showContactColumn,
    });

    const status = _.get(assistanceRequest, 'status');
    const isClosed = status === 'closed';
    const description = _.get(assistanceRequest, 'description');

    if (_.isEmpty(assistanceRequest)) {
      return (
        <div className="assistance-request--loading">
          <Spinner />
        </div>
      );
    }

    const showSelectClientMatchWorkflow = !assistanceRequest.id && !!clientMatches.length;

    const contact = retrieveContactFromAr(assistanceRequest, requestor);
    return (
      <div className="assistance-request-detail-view row">
        <div className={gridClass()}>
          <BaseCard className="assistance-request-summary">
            <BaseCardHeader noBorder title={title(requestorFullName)}>
              { status && referralStatuses[status] && referralStatuses[status]({ showText: false }) }
              <div className="detail-info__status-text">
                <DetailStatusText enumsKey="assistance_requests" detailObj={assistanceRequest} />
              </div>
            </BaseCardHeader>
            <Divider style={dividerStatusStyles(status)} />
            <BaseCardBody withPadding>
              {
                showSelectClientMatchWorkflow && (
                  <>
                    <AssistanceRequestSelectClient
                      className="mb-4"
                      onCreateNewClient={this.createNewClientFromAssistanceRequest}
                      onUseThisRecord={this.openConfirmUseThisRecordDialog}
                      creatingNewCase={this.state.actionLoading}
                      clientMatches={clientMatches}
                    />
                    <ConfirmUseThisRecordDialog
                      dialogRef={(el) => { this.confirmUseThisRecordDialog = el; }}
                      content={this.state.confirmRecordModalData.personChanges}
                      onClose={this.closeConfirmUseThisRecordDialog}
                      onSubmit={this.createAssistanceRequestCase}
                    />
                  </>
                )
              }
              {
                isClosed && (
                  <DetailOutcomeWrapper item={assistanceRequest} />
                )
              }
              <DetailLabelWrapper data={generateDetailLabelData(assistanceRequest)} />
              <Divider className="mt-4 mb-1 detail-info__horizontal-divider" />
              <AssistanceRequestDescription description={description} />
              { _.includes(['referred', 'deferred'], caseState) ? null : (
                <ConditionalTooltip
                  className="light"
                  condition={showSelectClientMatchWorkflow}
                  direction="bottom-right"
                  size="medium"
                  tag="div"
                  text={DISABLED_ACTIONS_MESSAGE}
                >
                  <AssistanceRequestActions
                    actionLoading={actionLoading}
                    actions={actions}
                    assistanceRequest={assistanceRequest}
                    disabled={showSelectClientMatchWorkflow}
                    disabledMessage={DISABLED_ACTIONS_MESSAGE}
                    isClosed={isClosed}
                    viewCase={viewCase}
                    ref={(assistanceRequestActions) => { this.assistanceRequestActions = assistanceRequestActions; }}
                  />
                </ConditionalTooltip>
              )}
            </BaseCardBody>
          </BaseCard>

          <div className="mt-12">
            <FormSubmissions
              context={showSelectClientMatchWorkflow ? requestId : _.get(assistanceRequest, 'id')}
              contextType={showSelectClientMatchWorkflow ? 'assistance_request' : 'case'}
              providerId={_.get(currentProvider, 'id')}
              serviceId={_.get(assistanceRequest, 'service_type.id')}
              editable={!showSelectClientMatchWorkflow}
            />
          </div>
          {
            showSelectClientMatchWorkflow ? null : (
              <div className="mt-10 case-referral-history">
                <ServiceCaseReferralHistory
                  serviceCase={assistanceRequest}
                  currentProvider={currentProvider}
                  type={type}
                />
              </div>
            )
          }
        </div>
        {
          showContactColumn ? (
            <div className="col-sm-3">
              <AsideColumn
                contact={contact}
                detailObj={assistanceRequest}
                type={type}
                isAssistanceRequest={showSelectClientMatchWorkflow}
              />
            </div>
          ) : null
        }

        <CloseAssistanceRequestDialog
          assistanceRequest={assistanceRequest}
          groupId={groupId}
          requestId={requestId}
          ref={(el) => { this.arCloseDialog = el; }}
          resetActionValue={this.resetActionValue}
        />
      </div>
    );
  }
}

AssistanceRequestDetail.propTypes = {
  assistanceRequest: PropTypes.shape({
    id: PropTypes.string,
    requestor: PropTypes.shape({
      first_name: PropTypes.string,
      full_name: PropTypes.string,
      id: PropTypes.string,
    }),
    contact: PropTypes.object,
    is_converted: PropTypes.bool,
  }),
  baseRequestUrl: PropTypes.string.isRequired,
  clearContacts: PropTypes.func.isRequired,
  createContact: PropTypes.func.isRequired,
  currentProvider: PropTypes.object.isRequired,
  destroyForm: PropTypes.func.isRequired,
  fetchAssistanceRequestDetails: PropTypes.func.isRequired,
  fetchGroupAssistanceRequestsConfig: PropTypes.func.isRequired,
  fetchGroupContact: PropTypes.func.isRequired,
  flagEnabled: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isCoordinationGroup: PropTypes.bool.isRequired,
  isSupersetEnabled: PropTypes.bool,
  uup459SupersetRelease3Flag: PropTypes.bool,
  needsActionStatus: PropTypes.bool.isRequired,
  requestId: PropTypes.string.isRequired,
  searchContact: PropTypes.func.isRequired,
  showContactColumn: PropTypes.bool,
  type: PropTypes.string,
  useNewSearchAndMatch: PropTypes.bool.isRequired,
  clientMatches: PropTypes.array.isRequired,
};

AssistanceRequestDetail.defaultProps = {
  assistanceRequest: {},
  isSupersetEnabled: false,
  uup459SupersetRelease3Flag: false,
  showContactColumn: true,
  type: 'assistanceRequests',
};

function mapStateToProps(state, ownProps) {
  const { globalState } = state;
  const requests = _.get(state, 'assistanceRequests.requests', {});
  const id = _.get(ownProps, 'params.assistance_request_id');
  const needsActionStatus = window.location.pathname.includes('new');
  const idParams = needsActionStatus ? { assistanceRequestId: id } : { id };
  const assistanceRequest = (id && _.find(requests, idParams)) || {};
  const assistanceRequestCase = assistanceRequest.case;
  const assistanceRequestCaseId = assistanceRequestCase ? assistanceRequestCase.id : '';
  const baseRequestUrl = `/assistance-requests/${assistanceRequestCaseId}`;
  const clientMatches = (assistanceRequest.clientMatches || []);
  const groupId = _.get(state, 'session.groupId');
  const isCoordinationGroup = _.get(state, 'session.isCoordinationGroup', false);
  const useNewSearchAndMatch = hasNewSearchAndMatch(state);

  return {
    assistanceRequest: assistanceRequestCase,
    baseRequestUrl,
    clientMatches,
    contacts: state.searchedContacts,
    currentProvider: _.get(globalState, 'currentProvider.group'),
    groupId,
    isCoordinationGroup,
    isSupersetEnabled: uup459SupersetPhase2(state),
    uup459SupersetRelease3Flag: uup459SupersetRelease3(state),
    needsActionStatus,
    requestId: id,
    useNewSearchAndMatch,
  };
}

AssistanceRequestDetail.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  clearContacts,
  createContact,
  destroyForm,
  fetchAssistanceRequestDetails,
  fetchGroupAssistanceRequestsConfig,
  fetchGroupContact,
  resetForm,
  searchContact,
})(featureFlag(AssistanceRequestDetail));
