import { intelClient } from 'src/api/config';

function fetchDownloadFile(params) {
  return intelClient.get('/api/insights/download', {
    responseType: 'arraybuffer',
    params,
  });
}

export default fetchDownloadFile;
