import getPendingReferralStatus from "./getPendingReferralStatus";
import getReferralName from "./getReferralName";
import getReferralStatus from "./getReferralStatus";
import isDraft from "./isDraft";
import isPendingReferral from "./isPendingReferral";
export default {
  getPendingReferralStatus: getPendingReferralStatus,
  getReferralName: getReferralName,
  getReferralStatus: getReferralStatus,
  isDraft: isDraft,
  isPendingReferral: isPendingReferral
};