import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import MilitaryForm from 'common/form/MilitaryForm';
import { BarLoader, Button, Dialog } from '@unite-us/ui';
import EditButton from 'common/display/Profile/components/EditButton';
import callOrLog from 'src/common/utils/callOrLog';
import { FACESHEET } from 'common/utils/EventTracker/utils/eventConstants';
import { cleanMilitaryFields } from 'src/components/MilitaryInformation/utils';
import {
  useInvalidateQueries,
} from 'src/api/APIHooks';
import MilitaryInformationDisplay from './components/MilitaryInformationDisplay';

const MilitaryInformation = (props) => {
  const invalidateQueries = useInvalidateQueries();
  let militaryForm = useRef();
  let editDialog = useRef();
  const [state, setState] = useState({
    verb: '',
    submitting: false,
  });

  const onSubmit = (data) => {
    setState({ ...state, submitting: true });
    const cleanedData = cleanMilitaryFields(data);
    props.updateMilitaryInformation(cleanedData);

    callOrLog(() => window.eventTracker(FACESHEET.militaryStatusUpdated));
    setState({ ...state, submitting: false });
    invalidateQueries('person');
  };

  const edit = (isAdd = false) => {
    setState({
      ...state,
      verb: isAdd ? 'Add' : 'Edit',
    });
    editDialog.openDialog();
  };

  const handleAddMilitaryInfoClick = (e) => {
    e.preventDefault();
    edit(true);
  };

  const submitMilitaryForm = () => {
    militaryForm.submit();
    editDialog.closeDialog('saved');
  };

  const {
    contact,
    enums,
    hideHeader,
    submitting,
  } = props;

  const militaryInfo = () => {
    if (!contact.has_military) {
      return (
        <span
          className="military-information-add-info"
          onClick={handleAddMilitaryInfoClick}
          role="button"
          tabIndex={0}
        >
          Add Military Information
        </span>
      );
    }

    return (
      <MilitaryInformationDisplay
        action={state.verb}
        contact={contact}
        edit={edit}
        enums={enums}
        submitting={submitting}
      />
    );
  };

  const editSection = () => (
    <div className="edit-modal">
      {
        contact.has_military &&
        <EditButton edit={edit} id="edit-military-btn" aria-label="edit military information" />
      }
      <Dialog
        modal
        ref={(el) => { editDialog = el; }}
        title={`${state.verb} Military Information`}
        id="military-info-modal"
        actions={[
          <Button
            id="edit-military-cancel-btn"
            label="Cancel"
            disabled={state.submitting}
            onClick={() => editDialog.closeDialog()}
          />,
          <Button
            id="edit-military-save-btn"
            label="Save"
            primary
            style={{ marginLeft: '10px' }}
            disabled={state.submitting}
            onClick={submitMilitaryForm}
          />,
        ]}
      >
        <MilitaryForm
          ref={(el) => { militaryForm = el; }}
          contact={contact}
          onSubmit={onSubmit}
        />
      </Dialog>
    </div>
  );

  return (
    <div className="profile-panel military-information editable-panel">
      <div>
        {!hideHeader && <h3>Military Information</h3>}

        <div className="section-body">
          {submitting ? (<BarLoader size="half" tall />) : militaryInfo()}

          {editSection()}
        </div>
      </div>
    </div>
  );
};

MilitaryInformation.propTypes = {
  contact: PropTypes.object.isRequired,
  enums: PropTypes.object.isRequired,
  hideHeader: PropTypes.bool,
  submitting: PropTypes.bool,
  updateMilitaryInformation: PropTypes.func.isRequired,
};

MilitaryInformation.defaultProps = {
  hideHeader: false,
  submitting: false,
};

export default MilitaryInformation;
