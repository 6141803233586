import React from 'react';
import PropTypes from 'prop-types';
import { BackButton } from 'common/buttons';
import { browserHistory } from 'common/utils/browserHistory';
import FeeScheduleScreeningForm from './FeeScheduleScreeningForm';

const FeeScheduleScreeningNew = ({ params }) => {
  const feeScheduleId = params.fee_schedule_id;

  const goBack = () => {
    browserHistory.push({
      pathname: `/backoffice/fee_schedules/${feeScheduleId}/fee-schedule-screenings`,
    });
  };

  return (
    <div>
      <div className="flex p-4 justify-start items-center bg-white border-b border-solid border-dark-border-blue">
        <BackButton navigateTo={goBack} />
        <h1 className="pl-6 font-bold">Add Fee Schedule Screening</h1>
      </div>
      <FeeScheduleScreeningForm goBack={goBack} feeScheduleId={feeScheduleId} />
    </div>
);
};

FeeScheduleScreeningNew.propTypes = {
  params: PropTypes.shape({
    fee_schedule_id: PropTypes.string.isRequired,
  }).isRequired,
};

export default FeeScheduleScreeningNew;
