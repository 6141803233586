import { connect } from 'react-redux';
import { get } from 'lodash';

// component to connect
import ScreeningDetail from 'src/components/Screenings/Detail/ScreeningDetail';

// actions
import { fetchGroupScreening } from 'actions/Screening/Group';
import { fetchContactScreening } from 'actions/Screening/Form/Contact/Group';
import { selectContact } from 'actions/Contact';

// utils
import { isCaseManager } from 'src/components/User/utils';

function mapStateToProps(state, ownProps) {
  const enums = get(state, 'session.enums');
  const submissionId = get(ownProps, 'params.screening_id');
  const groupId = get(state, 'session.groupId');
  const isCaseManagerUser = isCaseManager(get(state, 'user'), groupId);

  return {
    isCaseManager: isCaseManagerUser,
    enums,
    groupId,
    isMilitaryFocused: true,
    submissionId,
    type: ownProps.type,
  };
}

export default connect(mapStateToProps, {
  fetchGroupScreening,
  fetchContactScreening,
  selectContact,
})(ScreeningDetail);
