import _ from 'lodash';

export function currentCase(cases = [], currentCaseId) {
  return cases.find((c) => c.id === currentCaseId);
}

export function caseProgramId(serviceCase) {
  return _.get(serviceCase, 'program.program.id');
}

export function caseProgramEnrollmentId(serviceCase) {
  return _.get(serviceCase, 'program.id');
}

export function relatedCases(cases = [], currentCaseId) {
  const thisCase = currentCase(cases, currentCaseId);
  const currentCaseProgramEnrollmentId = caseProgramEnrollmentId(thisCase);

  return cases.filter((c) => (
    c.id !== currentCaseId &&
    caseProgramEnrollmentId(c) === currentCaseProgramEnrollmentId
  ));
}

export function nonClosedCases(cases = []) {
  return cases.filter((c) => c.closing === null);
}

export function showExitedAt(contactCases, caseId) {
  return _.isEmpty(nonClosedCases(relatedCases(contactCases, caseId)));
}
