// parentServices are grabbed upon app load
// network/provider specific service API response does not contain all the parent services
// This function maps incoming network/provider services with parent services
const assignChildToParentServices = (networkServices, parentServices) => {
  const payload = [];
  networkServices
    .filter((s) => s && s.parent)
    .forEach((s) => {
      const parent = parentServices.find((p) => p.id === s.parent.id);
      if (payload.find((p) => p.id === parent.id)) { return; }
      payload.push({ ...parent, children: [] });
    });
  networkServices.forEach((s) => {
    if (s && !s.parent) { return; }
    const parent = payload.find((p) => p.id === s.parent.id);
    if (!parent) { return; }
    // do not add duplicate child services
    if (parent.children && parent.children.find((c) => c.id === s.id)) { return; }
    parent.children = parent.children ? [...parent.children, s] : [s];
  });
  // adds parent only service types if any
  parentServices
    .filter((p) => p.children.length === 0)
    .forEach((p) => {
      if (networkServices.find((s) => s.id === p.id)) {
        payload.push(p);
      }
    });

  return payload.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
};

export default assignChildToParentServices;
