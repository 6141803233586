import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import _ from 'lodash';

const colors = {
  primaryColor: '#2C405A',
  accentColor: '#2C405A',
  textColor: '#58606E',
  background: '#F6F9FC',
  white: '#FFFFFF',
  primaryDarkColor: '#2C3D4D',
  primaryText: '#58606E',
  secondaryText: '#A5A9B1',
  disabledText: '#C5C8CC',
  dividerColor: '#D8D8D8',
  supportingGrey: '#E6E9ED',
  red: '#E53935',
  lightred: '#FBE1E1',
  darkred: '#a9201d',
  orange: '#FF9900',
  lightorange: '#FFF5E5',
  yellow: '#FFED18',
  green: '#47B04B',
  lightgreen: '#E4F3E4',
  blue: '#3E4EB8',
  lightblue: '#E2E5F4',
  darkblue: '#1f2e90',
  sidebarBackground: '#e0e7ee',
  cloudBlueColor: '#ECF5FD',
  unitedBlueColor: '#4571BA',
  displayColor: '#2C405A',
};

const UUmuiTheme = createTheme({
  typography: {
    fontFamily: [
      'Proxima Nova Medium',
      'Open Sans',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ],
  },
  overrides: {
    MuiStepper: {
      horizontal: {
        background: '#FBFCFD',
      },
    },
    MuiStepLabel: {
      label: {
        fontSize: '14px',
      },
    },
    MuiStepIcon: {
      root: {
        '&$active': {
          color: '#2C405A',
        },
      },
    },
    MuiListItemText: {
      primary: {
        color: '#2C405A',
        fontSize: '16px',
      },
    },
    MuiSwitch: {
      colorPrimary: {
        '&$checked + $track': {
          backgroundColor: '#D8E5EE',
        },
      },
    },
  },
  palette: {
    primary1Color: colors.primaryColor,
    accent1Color: colors.primaryColor,
    textColor: colors.textColor,
  },
  datePicker: {
    calendarTextColor: colors.textColor,
    selectColor: colors.primaryColor,
    selectTextColor: colors.white,
  },
  appBar: {
    color: colors.primaryDarkColor,
    height: 60,
  },
  navDrawer: {
    color: colors.sidebarBackground,
    width: 66,
  },
  checkbox: {
    checkedColor: colors.unitedBlueColor,
  },
  radioButton: {
    checkedColor: colors.unitedBlueColor,
  },
  toggle: {
    thumbOnColor: colors.accentColor,
    trackOnColor: '#E6E9ED',
  },
  tabs: {
    backgroundColor: 'transparent',
    textColor: colors.textColor,
    selectedTextColor: colors.primaryColor,
  },
  inkBar: {
    backgroundColor: colors.unitedBlueColor,
  },
  stepper: {
    iconColor: colors.displayColor,
  },
  tableRow: {
    hoverColor: colors.cloudBlueColor,
  },
});

function getTitle(routes) {
  const docTitle = 'Unite Us';
  const pageTitle = _.findLast(routes, (route) => _.has(route, 'title'));
  if (pageTitle) {
    return `${docTitle} - ${pageTitle.title}`;
  }
  return docTitle;
}

class MuiApp extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    document.title = getTitle(nextProps.routes);
  }

  render() {
    return (
      <MuiThemeProvider theme={UUmuiTheme}>
        <div className="container-fluid">
          {this.props.children}
        </div>
      </MuiThemeProvider>
    );
  }
}

MuiApp.propTypes = {
  routes: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
};

export default MuiApp;
