import Notifier from 'common/helpers/Notifier';
import isUserActive from 'src/api/utils/isUserActive';
import usersAPI from 'src/api/auth/users';
import fetchAgreements from 'src/api/core/Agreements/fetchAgreements';
import { updateGlobalState } from 'src/actions/Global/globalActions';
import { FETCH_EMPLOYEES_ERROR, FETCH_SUPPORT } from 'src/actions';
import convertCoreUser from 'src/api/utils/convertCoreUser';
import { coreApi } from 'src/api/config';
import { logoutUser } from 'actions/Login';
import { isEmpty } from 'lodash';

const onSuccess = ({
  dispatch,
  data,
  employees,
  agreements,
  eula,
  impersonation = false,
}) => {
  try {
    const filtered = data.map((provider) => ({
      group: provider,
      user: convertCoreUser(
        employees.find((employee) => employee.provider.id === provider.id),
      ),
      agreements: agreements.find((agreement) => agreement.provider === provider.id)?.agreements,
    }));

    dispatch({
      type: FETCH_SUPPORT,
      payload: eula,
      impersonation,
    });

    dispatch(updateGlobalState({
      providers: filtered,
    }));
    return filtered;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('fetchMyProviders.onSuccess error', error);
    return null;
  }
};

const onError = (dispatch, error) => {
  dispatch({ type: FETCH_EMPLOYEES_ERROR });
  Notifier.handleErrors(error);
  return error;
};

// fetches providers, eula and provider agreements
export default function fetchMyProviders({
  employees,
  impersonation = false,
}) {
  return async (dispatch) => {
    try {
      const activeEmployees = employees
        .filter((employee) => isUserActive(employee));
      if (isEmpty(activeEmployees)) {
        return dispatch(logoutUser());
      }
      const providerIds = activeEmployees
        .map((employee) => employee.provider.id);
      // get all provider agreements
      const fetchProviderAgreements = providerIds.map((id) => dispatch(fetchAgreements(id)));
      const [providers, eula, ...agreements] = await Promise.all([
        coreApi.query('provider', { ids: providerIds }),
        dispatch(usersAPI.fetchEula),
        ...fetchProviderAgreements,
      ]);
      if (!providers || !eula) { return false; }
      const { data: { data } } = providers;
      return onSuccess({
        dispatch,
        data,
        employees: activeEmployees,
        agreements,
        eula,
        impersonation,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('fetchMyProviders error', error);
      return onError(dispatch, error);
    }
  };
}
