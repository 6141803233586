import PropTypes from 'prop-types';
import React from 'react';
import { Drawer } from '@unite-us/ui';
import { noop } from 'lodash';
import NetworkGroupDetails from 'src/components/Groups/GroupDetails/NetworkGroupDetails';
import { TOP_NAVIGATION_HEIGHT } from 'src/components/Navigation/constants';
import '../stylesheets/networkGroupsDrawer.scss';

const NetworkGroupsDrawer = ({
  center,
  groupPrograms,
  network,
  onClose,
  open,
  providerLocations,
  providerServices,
  selectedProvider,
  selectedServiceIds,
}) => (
  <Drawer
    className="network-groups-drawer"
    onClose={onClose}
    open={open}
    secondary
    topOffset={TOP_NAVIGATION_HEIGHT}
    width="500px"
  >
    <NetworkGroupDetails
      center={center}
      selectedProvider={selectedProvider}
      programs={groupPrograms}
      selectedServiceIds={selectedServiceIds}
      network={network}
      providerLocations={providerLocations}
      providerServices={providerServices}
    />
  </Drawer>
);

NetworkGroupsDrawer.propTypes = {
  center: PropTypes.object.isRequired,
  groupPrograms: PropTypes.array,
  network: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  selectedServiceIds: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ).isRequired,
  providerLocations: PropTypes.array,
  providerServices: PropTypes.array,
  selectedProvider: PropTypes.object.isRequired,
};

NetworkGroupsDrawer.defaultProps = {
  groupPrograms: [],
  onClose: noop,
  open: false,
  network: {},
  providerLocations: [],
  providerServices: [],
};

export default NetworkGroupsDrawer;
