import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'src/common/Pagination/Pagination';
import ScrollToElement from 'src/common/utils/Form/ScrollToElement';
import { useFindAllPlan } from 'src/components/Backoffice/api/hooks/v1/planHooks';
import { usePopulate } from 'src/api/APIHooks';
import { Spinner } from 'common/spinners';
import { capitalize, isObject } from 'lodash';
import { Menu } from '@headlessui/react';
import EmptyTable from 'src/common/tables/EmptyTable/EmptyTable';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from '@unite-us/ui';
import { tableWidth } from 'common/helpers/Tables';
import { browserHistory } from 'common/utils/browserHistory';
import { DEFAULT_PAGE_SIZE } from 'src/components/Backoffice/backofficeUtils';
import './stylesheets/FeeScheduleProgramsTable.scss';

const namespace = 'fee-schedule-plans-table';

const FeeSchedulePlansTableHeader = () => (
  <TableHeader>
    <TableRow>
      <TableHeaderColumn style={{ width: tableWidth('large') }}>
        Plan name
      </TableHeaderColumn>
      <TableHeaderColumn style={{ width: tableWidth('medium') }}>
        Plan type
      </TableHeaderColumn>
      <TableHeaderColumn style={{ width: tableWidth('medium') }}>
        Payer name
      </TableHeaderColumn>
      <TableHeaderColumn style={{ width: tableWidth('medium') }}>
        External id
      </TableHeaderColumn>
      <TableHeaderColumn style={{ width: tableWidth('icon') }} />
    </TableRow>
  </TableHeader>
);

export const EmptyFeeSchedulePlansTable = () => (
  <>
    <Table className="rounded-b-none">
      <FeeSchedulePlansTableHeader />
    </Table>
    <EmptyTable
      className={'p-6 border border-t-0 border-solid border-light-border-blue'}
      message={'Click "Add Plan" to add Plans'}
    />
  </>
);

const FeeSchedulePlansTable = ({ feeScheduleId, planIds }) => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const { data: plansResponse, isFetching: isFetchingPlans } = useFindAllPlan(
    planIds.map((plan) => plan.id).toString(),
    {
      page: {
        size: pageSize,
        number: pageNumber,
      },
    },
  );
  const { data: plans, paging } = plansResponse?.data || {};

  const { isFetching: isFetchingPayers } = usePopulate(
    'payer',
    'payer',
    plans,
    { queryConfig: { placeholderData: undefined } },
  );

  if (isFetchingPlans || isFetchingPayers) return <Spinner />;

  const ScrollToElementEvent = () => {
    ScrollToElement(document.getElementById('TopOfForms'));
  };

  const handleCellClick = (plan, e) => {
    e.stopPropagation();
    setSelectedPlan(plan);
  };

  const editPlan = () => () => {
    browserHistory.push({ pathname: `/backoffice/payers/${selectedPlan.payer.id}/plans/${selectedPlan.id}/edit` });
  };

  const viewPlan = (plan) => {
    browserHistory.push({
      pathname: `/backoffice/fee_schedules/${feeScheduleId}/plans/${plan.id}`,
    });
  };

  return (
    <div data-testid="fee-schedule-plans-table">
      <Table id="feeSchedulePlansTable">
        <FeeSchedulePlansTableHeader />
        <TableBody>
          {plans.filter(isObject).map((plan) => (
            <TableRow
              className={`${namespace}__row`}
              key={`plans-row-${plan.id}`}
              onCellClick={() => viewPlan(plan)}
            >
              <TableRowColumn style={{ width: tableWidth('large') }}>
                { plan.name }
              </TableRowColumn>
              <TableRowColumn style={{ width: tableWidth('medium') }}>
                { capitalize(plan.plan_type) }
              </TableRowColumn>
              <TableRowColumn style={{ width: tableWidth('medium') }}>
                { plan.payer.name }
              </TableRowColumn>
              <TableRowColumn style={{ width: tableWidth('medium') }}>
                {plan.external_id}
              </TableRowColumn>
              <TableRowColumn style={{ width: tableWidth('icon') }}>
                <Menu as="div">
                  <Menu.Button
                    className={'focus:outline-none text-black font-bold'}
                    onClick={(e) => handleCellClick(plan, e)}
                  >
                    ...
                  </Menu.Button>
                  <Menu.Items
                    className={
                        `flex flex-col absolute border border-solid border-dark-border-blue bg-white
                        box-border w-32 z-modal right-4 focus:outline-none`
                      }
                  >
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${active && 'bg-dark-fill-blue'} w-full px-2 py-2`}
                          type="button"
                          disabled
                        >
                          Archive
                        </button>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${active && 'bg-dark-fill-blue'} w-full px-2 py-2`}
                          type="button"
                          onClick={editPlan}
                        >
                          Edit
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
              </TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="p-6">
        <Pagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalItemCount={paging.total_count}
          totalPageCount={paging.total_pages}
          setPageSize={setPageSize}
          setPageNumber={setPageNumber}
          scrollPaginationElement={ScrollToElementEvent}
        />
      </div>
    </div>
  );
};

FeeSchedulePlansTable.propTypes = {
  feeScheduleId: PropTypes.string.isRequired,
  planIds: PropTypes.array.isRequired,
};

export default FeeSchedulePlansTable;
