import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'common/utils/browserHistory';
import { isEmpty, get, find, wget, noop, includes } from 'lodash';
import {
  Button,
} from '@unite-us/ui';
import { validateReduxForm } from 'common/form';
import { OverlaySpinner, Spinner } from 'common/spinners';
import {
  createDocumentConsent,
  createEmailConsent,
  createSmsConsent,
  fetchConsentAppUrl,
} from 'actions/Consent/Contact/Group';
import { fetchGroupContact } from 'actions/Contact/Group';
import { notify } from 'actions/Notifier';
import Notifier from 'common/helpers/Notifier';
import { updateGlobalState } from 'actions/Global/globalActions';
import {
  CONSENT_COMPLETED,
  CONSENT_FORM_HEADER_MESSAGE,
  CONSENT_OPTIONS,
  CHOOSE_PREFERRED_LANGUAGE_MESSAGE,
  DIGITAL_CONSENT_TYPES,
} from 'src/components/InformedConsent/constants';
import {
  getSubmitConsentText,
  isUploadConsentType,
} from 'src/components/InformedConsent/utils';
import findPrimaryOrFirst from 'src/components/Contacts/utils/findPrimaryOrFirst';
import callOrLog from 'src/common/utils/callOrLog';
import { CONSENT } from 'common/utils/EventTracker/utils/eventConstants';
import { getAuthToken } from 'src/api/config';
import { showTranslationsNewLanguages } from 'common/utils/FeatureFlags/flags';
import { LanguageSelector } from '@unite-us/client-utils';

// Style Imports
import 'src/components/InformedConsent/stylesheets/informedConsent.scss';
import ConsentAppFrame from './ConsentAppFrame';
import ConsentTypeChoices from './ConsentTypeChoices';

const DEFAULT_SELECTED_CONSENT_CHOICE = CONSENT_OPTIONS.ON_SCREEN_ATTESTATION;

export class RequestOrUploadConsentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetchingConsentAppUrl: true,
      selectedConsentType: DEFAULT_SELECTED_CONSENT_CHOICE,
      currentLanguage: props.language,
    };

    this.iframeRef = null;

    this.consentActions = {
      audio: (params) => this.props.createDocumentConsent({ ...params, consentMethod: 'verbal' }),
      document: (params) => this.props.createDocumentConsent({ ...params, consentMethod: 'written' }),
      email_address: this.props.createEmailConsent,
      phone_number: this.props.createSmsConsent,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.navigateAway = this.navigateAway.bind(this);
    this.getSelectedConsentValue = this.getSelectedConsentValue.bind(this);
    this.selectConsentType = this.selectConsentType.bind(this);
    this.onConsentComplete = this.onConsentComplete.bind(this);
    this.showConsent = this.showConsent.bind(this);
    this.setConsentAttestation = this.setConsentAttestation.bind(this);
    this.onProvideAuthToken = this.onProvideAuthToken.bind(this);
    this.onLoadIframe = this.onLoadIframe.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.getConsentAppUrl = this.getConsentAppUrl.bind(this);
    this.updateGlobalLanguage = this.updateGlobalLanguage.bind(this);
    this.initializeForm = this.initializeForm.bind(this);
  }

  componentDidMount() {
    const { groupId, contactId } = this.props;

    if (!isEmpty(contactId)) {
      this.props.fetchGroupContact(groupId, contactId);
    }

    this.initializeForm();
    window.addEventListener('message', this.onConsentComplete);
    window.addEventListener('message', this.onProvideAuthToken);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.initializedValue !== this.props.initializedValue) {
      this.setState({ selectedConsentType: nextProps.initializedValue });
    }
  }

  componentDidUpdate(prevProps, nextProps) {
    if (this.state.selectedConsentType !== nextProps.selectedConsentType) {
      if (!includes(DIGITAL_CONSENT_TYPES, this.state.selectedConsentType)) {
        this.updateGlobalLanguage('en');
        this.handleOnChange('en');
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onConsentComplete);
    window.removeEventListener('message', this.onProvideAuthToken);
  }

  handleOnChange(val, field, registerField) {
    this.setState({
      currentLanguage: val,
    });

    this.props.handleOnChange(val, field, registerField);
  }

  onSubmit() {
    const {
      contactId,
    } = this.props;

    const { selectedConsentType } = this.state;
    const consentValue = this.getSelectedConsentValue();
    const language = this.state.currentLanguage;

    const consentParams = {
      contactId,
      ...(selectedConsentType === CONSENT_OPTIONS.EMAIL_ADDRESS ? { emailAddress: consentValue } : null),
      ...(selectedConsentType === CONSENT_OPTIONS.PHONE_NUMBER ? { phoneNumber: consentValue } : null),
      ...((selectedConsentType === CONSENT_OPTIONS.AUDIO) || (selectedConsentType === CONSENT_OPTIONS.DOCUMENT) ?
        { documentsFileList: consentValue } : null),
      language,
    };

    if (isUploadConsentType(selectedConsentType)) {
      if (isEmpty(consentValue)) {
        return this.props.notify({
          status: 'error',
          message: 'Upload document is required',
        });
      }

      callOrLog(() => this.context.eventTracker(CONSENT.upload, {
        consent_type: selectedConsentType,
        contact_id: contactId,
      }));
    }

    return this.consentActions[selectedConsentType](consentParams)
      .then(this.navigateAway);
  }

  onConsentComplete(event) {
    const { groupId, contactId, consentAppUrl } = this.props;
    const eventOrigin = get(event, 'origin');
    const originIsConsentApp = includes(consentAppUrl, eventOrigin);
    if (event.data.status === CONSENT_COMPLETED && originIsConsentApp) {
      this.props.fetchGroupContact(groupId, contactId).then((contact) => {
        const consentStatus = get(contact, 'data.data.consent.state', '');
        if (consentStatus === 'accepted') {
          Notifier.dispatch('success', 'Your consent has been recorded.');
        }
        return this.navigateAway();
      });
    }
  }

  onProvideAuthToken(event) {
    if (event.data === 'retrieve-auth-token') {
      this.iframeRef.contentWindow.postMessage({
        authToken: getAuthToken(),
      }, '*');
    }
  }

  onLoadIframe() {
    this.setState({
      isFetchingConsentAppUrl: false,
    });
  }

  getConsentAppUrl(groupId, contactId, contact, consentAttestation) {
    this.setState({
      isFetchingConsentAppUrl: true,
    });

    this.props.getConsentAppUrl(groupId, contactId, contact, consentAttestation);
  }

  getSelectedConsentValue() {
    const { selectedConsentType } = this.state;
    return isUploadConsentType(selectedConsentType) ?
      this.props.fileUpload :
      this.props.fields[selectedConsentType].value;
  }

  // TODO: Remove props related to attestation in the parent component since they are not needed anymore:
  // setConsentAttestation, showConsentAppAttestation, etc
  setConsentAttestation() {
    this.showConsent(true);
    // this.props.setConsentAttestation()
  }

  updateGlobalLanguage(language) {
    this.props.updateGlobalState({
      language,
    });
  }

  initializeForm() {
    const initialLanguage = !includes(DIGITAL_CONSENT_TYPES, this.state.selectedConsentType) ?
      'en' : this.state.currentLanguage;
    const { contact } = this.props;
    const email_address = contact && findPrimaryOrFirst(contact.email_addresses).email_address;
    const phone_number = contact && findPrimaryOrFirst(contact.phone_numbers).phone_number;

    this.props.initializeForm({
      language: initialLanguage,
      email_address,
      phone_number,
    });

    this.handleOnChange(initialLanguage);
  }

  showConsent(isConsentAttestation) {
    const {
      groupId, contactId, contact, fields, registerField,
    } = this.props;

    this.updateGlobalLanguage(this.state.currentLanguage);
    this.getConsentAppUrl(groupId, contactId, contact, isConsentAttestation);
    this.props.showConsent(fields[CONSENT_OPTIONS.LANGUAGE], registerField);
  }

  selectConsentType(selectedConsentType) {
    this.props.fields.selectedConsentType.onChange(selectedConsentType);
    this.setState({ selectedConsentType });
  }

  navigateAway() {
    if (this.props.onNavigateAway) {
      this.props.onNavigateAway();
    } else {
      browserHistory.push(`/facesheet/${this.props.contactId}`);
    }
  }

  render() {
    const {
      allowSkipConsent,
      fields,
      handleSubmit,
      isFetchingContact,
      registerField,
      showConsentApp,
      styles,
      submitting,
      shouldShowTranslationsNewLanguages,
      consentAppUrl,
    } = this.props;

    const {
      isFetchingConsentAppUrl,
      selectedConsentType,
    } = this.state;

    if (showConsentApp) {
      return (
        <div className="request-or-upload-consent-form consent-request-form-wrapper">
          <div className="consent-app-frame">
            <OverlaySpinner
              text="Loading Web Consent Form"
              show={isFetchingConsentAppUrl}
            />
            {
              consentAppUrl && (
                <ConsentAppFrame
                  consentAppUrl={consentAppUrl}
                  onBackClick={this.props.hideConsent}
                  onSetRef={(element) => {
                    this.iframeRef = element;
                  }}
                  onLoad={this.onLoadIframe}
                />
              )
            }
          </div>
        </div>
      );
    }

    return (
      <div className="request-or-upload-consent-form consent-request-form-wrapper">
        <div className="consent-request-form content-with-actions">
          <OverlaySpinner
            text={getSubmitConsentText(selectedConsentType)}
            show={submitting}
          />
          <p className="container-text-block mx-2">
            {CONSENT_FORM_HEADER_MESSAGE}
          </p>
          <div className="mx-2 mt-4">
            <p className="preferred-language-message pb-4">{CHOOSE_PREFERRED_LANGUAGE_MESSAGE}</p>
            <LanguageSelector
              onChange={this.handleOnChange}
              field={fields[CONSENT_OPTIONS.LANGUAGE]}
              registerField={registerField}
              disabled={!includes(DIGITAL_CONSENT_TYPES, selectedConsentType)}
              translationsNewLanguageFlag={shouldShowTranslationsNewLanguages}
            />
          </div>
          {
            isFetchingContact ?
              <Spinner /> : (
                <ConsentTypeChoices
                  fields={fields}
                  onSubmit={handleSubmit(this.onSubmit)}
                  registerField={registerField}
                  selectConsentType={this.selectConsentType}
                  selectedConsentType={selectedConsentType}
                  setConsentAttestation={this.setConsentAttestation}
                  showConsent={this.showConsent}
                />
              )
          }
          <div className="footer-actions actions text-right">
            <Button
              id="skip-consent-btn"
              className="skip-consent-btn normal-case text-action-blue border-dark-border-grey px-3"
              label="Skip for now"
              onClick={this.navigateAway}
              shouldRender={allowSkipConsent}
            />
            {
              isUploadConsentType(selectedConsentType) ? (
                <span className="action-item">
                  <Button
                    id="audio-document-consent-btn"
                    className="document-or-audio-consent-submit-btn"
                    disabled={submitting}
                    label="Submit"
                    onClick={handleSubmit(this.onSubmit)}
                    style={styles.submitButton}
                    primary
                  />
                </span>
              ) : null
            }
          </div>
        </div>
      </div>
    );
  }
}

RequestOrUploadConsentForm.propTypes = {
  allowSkipConsent: PropTypes.bool,
  contact: PropTypes.object,
  contactId: PropTypes.string,
  createDocumentConsent: PropTypes.func.isRequired,
  createEmailConsent: PropTypes.func.isRequired,
  createSmsConsent: PropTypes.func.isRequired,
  consentAppUrl: PropTypes.string.isRequired,
  fetchGroupContact: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  fileUpload: PropTypes.array,
  getConsentAppUrl: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  hideConsent: PropTypes.func.isRequired,
  initializedValue: PropTypes.string,
  initializeForm: PropTypes.func.isRequired,
  isFetchingContact: PropTypes.bool.isRequired,
  notify: PropTypes.func.isRequired,
  onNavigateAway: PropTypes.func,
  registerField: PropTypes.func.isRequired,
  showConsent: PropTypes.func.isRequired,
  showConsentApp: PropTypes.bool.isRequired,
  updateGlobalState: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  styles: PropTypes.shape({
    submitButton: PropTypes.object,
  }),
  submitting: PropTypes.bool.isRequired,
  shouldShowTranslationsNewLanguages: PropTypes.bool,
  selectedConsentType: PropTypes.string,
};

RequestOrUploadConsentForm.defaultProps = {
  allowSkipConsent: false,
  contact: {},
  contactId: '',
  fileUpload: [],
  initializedValue: '',
  onNavigateAway: noop,
  handleOnChange: noop,
  styles: {
    submitButton: {
      marginLeft: '20px',
    },
  },
  selectedConsentType: DEFAULT_SELECTED_CONSENT_CHOICE,
  shouldShowTranslationsNewLanguages: false,
};

RequestOrUploadConsentForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { contactId } = ownProps;
  const fileUpload = get(state, 'fileupload.Consent.files[0]');
  const contact = !isEmpty(ownProps.contact) ?
    ownProps.contact :
    find(state.contacts.contacts, { id: contactId });

  const email_address = contact && findPrimaryOrFirst(contact.email_addresses).email_address;
  const phone_number = contact && findPrimaryOrFirst(contact.phone_numbers).phone_number;
  const currentEmployee = state.globalState.currentEmployee;
  const language = state.globalState.language;
  return {
    contact,
    contactId,
    currentEmployee,
    fileUpload: fileUpload && [fileUpload],
    groupId: wget(state, 'session.groupId'),
    language,
    shouldShowTranslationsNewLanguages: showTranslationsNewLanguages(state),
    initialValues: {
      email_address,
      phone_number,
      selectedConsentType: ownProps.initializedValue || DEFAULT_SELECTED_CONSENT_CHOICE,
      language,
    },
    isFetchingContact: get(state, 'contacts.isFetching', false),
  };
}

const fields = [
  CONSENT_OPTIONS.AUDIO,
  CONSENT_OPTIONS.DOCUMENT,
  CONSENT_OPTIONS.EMAIL_ADDRESS,
  CONSENT_OPTIONS.PHONE_NUMBER,
  CONSENT_OPTIONS.SELECTED_CONSENT_TYPE,
  CONSENT_OPTIONS.LANGUAGE,
];

export default validateReduxForm({
  form: 'consentRequestOrUploadConsentForm',
  fields,
}, mapStateToProps, {
  createDocumentConsent,
  createEmailConsent,
  createSmsConsent,
  fetchConsentAppUrl,
  fetchGroupContact,
  updateGlobalState,
  notify,
})(RequestOrUploadConsentForm);
