import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import _ from 'lodash';
import {
  InputField,
  DateField,
  SelectField,
  FormLegend,
} from '@unite-us/ui';
import today from 'common/utils/today';
import { BINARY_GENDERS, canEditGender } from 'src/common/utils/gender';
import minBirthDate from 'common/utils/minBirthDate';

function BasicInformation(
  {
    registerField,
    fields,
    validations,
    enums,
    limitedGenderOptions,
    shouldShowSexualityFields,
    isExistingContact,
  },
) {
  const peopleEnums = _.wget(enums, 'people', {});
  const genderDisabled = isExistingContact &&
    !canEditGender({ gender: fields.gender.value, flag: limitedGenderOptions });
  const modifiedGenders = useMemo(() => {
    if (limitedGenderOptions) {
      return peopleEnums.genders.filter(
        (genderOption) => BINARY_GENDERS.includes(genderOption.value.toLowerCase()),
      );
    }

    return peopleEnums.genders;
  }, [peopleEnums, limitedGenderOptions, BINARY_GENDERS]);

  const sexualOrientationsSelected = _.get(fields, 'sexuality.value', []);
  const isSexualOrientationOtherSelected = _.includes(sexualOrientationsSelected, 'other');

  return (
    <div className="basic-information">
      <h2 className="basic-information__title">Personal Information</h2>
      <FormLegend />

      <hr className="mt-1 mb-4" />

      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <InputField
            ref={registerField}
            field={fields.first_name}
            label="First Name"
            id="first-name"
            required
            validations={validations.isRequired}
          />
        </div>

        <div className="col-xs-12 col-sm-6">
          <InputField
            ref={registerField}
            field={fields.last_name}
            label="Last Name"
            id="last-name"
            required
            validations={validations.isRequired}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <DateField
            ref={registerField}
            field={fields.date_of_birth}
            label="Date of Birth"
            id="date-of-birth"
            minDate={minBirthDate()}
            maxDate={today()}
            validations={validations.isRequired}
            required
          />
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <InputField
            id="social-security-number"
            className="basic-information__social-security-number"
            label="Social Security Number"
            field={fields.ssn}
            placeholder={`*** - ** - ${fields.ssn_last4.value || '***'}`}
            mask="999-99-9999"
            ref={registerField}
            validations={{
              func: validations.maskValidation,
              args: /\d{3}-\d{2}-\d{4}/,
              message: 'SSN should be 9 digits',
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          {genderDisabled ? (
            <SelectField
              className="basic-information-gender"
              id="gender"
              label="Gender"
              labelKey="display_name"
              valueKey="value"
              options={[{ display_name: 'Undisclosed', value: 'undisclosed' }]}
              value="undisclosed"
              disabled
            />
          ) : (
            <SelectField
              className="basic-information-gender"
              ref={registerField}
              id="gender"
              field={fields.gender}
              label="Gender"
              options={modifiedGenders}
              labelKey="display_name"
              valueKey="value"
              validations={validations.isRequired}
              required
              searchEnabled={false}
            />
          )}
          {shouldShowSexualityFields && (
            <SelectField
              className="basic-information-sexuality"
              clearable
              field={fields.sexuality}
              forceObjectValue={false}
              id="sexuality"
              inline={false}
              label="Sexual Orientation"
              labelKey="display_name"
              multiple
              shouldSort={false}
              options={peopleEnums.sexual_orientations}
              ref={registerField}
              required={false}
              valueKey="value"
            />
          )}
        </div>
      </div>
      {(shouldShowSexualityFields && isSexualOrientationOtherSelected) && (
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <InputField
              ref={registerField}
              field={fields.sexuality_other}
              maxLength={198}
              label="Other (Please Specify)"
              id="sexuality-other"
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-xs-12">
          <SelectField
            className="basic-information-marital-status"
            ref={registerField}
            id="marital-status"
            field={fields.marital_status}
            label="Marital Status"
            options={peopleEnums.marital_status}
            labelKey="display_name"
            valueKey="value"
            validations={validations.isRequired}
            required
            searchEnabled={false}
          />
          <SelectField
            className="basic-information-race"
            ref={registerField}
            id="race"
            field={fields.race}
            label="Race"
            options={peopleEnums.race}
            labelKey="display_name"
            valueKey="value"
            validations={validations.isRequired}
            required
            searchEnabled={false}
          />
          <SelectField
            className="basic-information-ethnicity"
            ref={registerField}
            id="ethnicity"
            field={fields.ethnicity}
            label="Ethnicity"
            options={peopleEnums.ethnicity}
            labelKey="display_name"
            valueKey="value"
            validations={validations.isRequired}
            required
            searchEnabled={false}
          />
        </div>
      </div>
    </div>
  );
}

BasicInformation.propTypes = {
  registerField: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  validations: PropTypes.object.isRequired,
  enums: PropTypes.object.isRequired,
  limitedGenderOptions: PropTypes.bool.isRequired,
  shouldShowSexualityFields: PropTypes.bool.isRequired,
  isExistingContact: PropTypes.bool.isRequired,
};

BasicInformation.fields = [
  'first_name',
  'last_name',
  'date_of_birth',
  'gender',
  'sexuality',
  'sexuality_other',
  'marital_status',
  'race',
  'ethnicity',
  'ssn',
  'ssn_last4',
];

export default BasicInformation;
