import React from 'react';
import { Link } from 'react-router';
import { usePopulate } from 'src/api/APIHooks';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import {
  DataCell,
  DataRow,
} from 'src/common/tables/V2';
import { DollarAmount } from 'src/common/display/Money';
import moment from 'moment';
import ServiceAuthorizationStatusIndicator from './ServiceAuthorizationStatusIndicator';

const ServiceAuthorizationTableRow = ({
  authorizationRequest,
  showTimeInQueue,
}) => {
  const lastUpdated = new Date(Date.parse(authorizationRequest.updated_at));
  usePopulate('requester', 'employee', authorizationRequest);
  usePopulate('fee_schedule_program', 'fee_schedule_program', authorizationRequest);
  usePopulate('person', 'person', authorizationRequest);

  return (
    <DataRow
      key={authorizationRequest.id}
      dataTestId="workqueue-table-row"
    >
      <DataCell className="text-action-blue">
        <Link to={`/authorizations/${authorizationRequest.id}`} className="underline">
          {authorizationRequest.short_id}
        </Link>
      </DataCell>
      <DataCell>
        {
          authorizationRequest.requested_cents ?
            (
              <DollarAmount
                value={authorizationRequest.requested_cents}
                convertCents
              />
            ) :
            `${authorizationRequest.requested_unit_amount} ${get(authorizationRequest, 'fee_schedule_program.unit') ?
              pluralize(
                get(authorizationRequest, 'fee_schedule_program.unit'),
                authorizationRequest.requested_unit_amount,
              ) : ''}`
        }
      </DataCell>
      <DataCell>
        {get(authorizationRequest, 'person.last_name')},&nbsp;
        {get(authorizationRequest, 'person.first_name')}
      </DataCell>
      <DataCell>
        <ServiceAuthorizationStatusIndicator
          status={authorizationRequest.state}
          autoApproved={authorizationRequest.auto_approved}
        />
      </DataCell>
      <DataCell>{get(authorizationRequest, 'fee_schedule_program.name')}</DataCell>
      <DataCell>
        {get(authorizationRequest, 'requester.last_name')},&nbsp;
        {get(authorizationRequest, 'requester.first_name')}
      </DataCell>
      <DataCell>
        {
          showTimeInQueue ?
            moment(lastUpdated).fromNow(true) :
            lastUpdated.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })
        }
      </DataCell>
    </DataRow>
  );
};

ServiceAuthorizationTableRow.propTypes = {
  authorizationRequest: PropTypes.object.isRequired,
  showTimeInQueue: PropTypes.bool,
};

ServiceAuthorizationTableRow.defaultProps = {
  showTimeInQueue: false,
};

export default (ServiceAuthorizationTableRow);
