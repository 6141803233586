import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { createId } from './utils';
import RouteNavigationItemGroup from './components/RouteNavigationItemGroup';
import RouteNavigationItem from './components/RouteNavigationItem';

const RouteNavigation = ({ routes, location, onClick }) => {
  const labels = _.map(routes, (route, index) => {
    if (_.isEmpty(route.children)) {
      return (
        <ul
          key={`item-${index}`}
          className="route-navigation-item-group__list list-unstyled"
        >
          <RouteNavigationItem
            id={createId(route.path)}
            item={route}
            location={location}
            onClick={onClick}
          />
        </ul>
      );
    }
    return (
      <RouteNavigationItemGroup
        id={createId(route.path)}
        route={route}
        location={location}
        onClick={onClick}
        key={index}
      />
    );
  });

  return (
    <div className="route-navigation">
      {labels}
    </div>
  );
};

RouteNavigation.propTypes = {
  routes: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RouteNavigation;
