import { pluralize } from 'humanize-plus';

export default (durationInSeconds) => {
  const minutes = Math.floor(durationInSeconds / 60);
  const hours = Math.floor(durationInSeconds / 3600);
  const days = Math.floor(durationInSeconds / 86400);

  if (minutes < 60) {
    return `${minutes} ${pluralize(minutes, 'minute')}`;
  }
  if (hours < 24) {
    return `${hours} ${pluralize(hours, 'hour')}`;
  }
  return `${days} ${pluralize(days, 'day')}`;
};
