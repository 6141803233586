import { get, some } from 'lodash';

const noNonEmptyGroups = (groupFields = []) => (
  !some(groupFields, (field) => get(field, 'group.value.id', '').length > 0)
);

const someEmptyGroups = (groupFields = []) => (
  some(groupFields, (field) => !get(field, 'group.value.id'))
);

const hasInvalidEmptyGroup = (groupFields = []) => (
  noNonEmptyGroups(groupFields) && someEmptyGroups(groupFields)
);

export default hasInvalidEmptyGroup;
