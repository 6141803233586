import _ from 'lodash';

function getPrimaryWorkersOptions(
  activeUsersInGroup = {},
  state = {},
  currentEmployee = {},
) {
  return _.uniqBy(_.map(_.concat(activeUsersInGroup, currentEmployee), (employee) => (
    {
      label: employee.full_name,
      value: `${employee.id}-primary_worker`,
      initial: _.includes(_.map(
        state.filters.primary_worker_users,
        (f) => f.split('-primary_worker')[0],
      ), employee.id),
    }
  )), 'value');
}

export default getPrimaryWorkersOptions;
