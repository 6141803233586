import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import DateOfBirth from 'common/display/DateOfBirth';
import PhoneNumber from 'common/display/PhoneNumberDisplay';
import SimpleList from 'common/display/SimpleList';
import Email from 'common/display/Email';
import DetailDefinitionList from 'common/display/SingleItemDetails/DetailDefinitionList';
import { isEmpty } from 'lodash';

class ContactDetails extends PureComponent {
  render() {
    const {
      date_of_birth,
      email_addresses = [],
      full_name = '',
      phone_numbers = [],
    } = this.props.contact;

    return (
      <div className="client-details">
        <h3 className="client-details__header">Contact Information</h3>
        <div className="row client-details__user-birth-info">
          <div className="col-md-6">
            <DetailDefinitionList
              id="client-detail-full-name-list"
              title="Full Name"
              value={full_name}
            />
          </div>
          <div className="col-md-6">
            <DetailDefinitionList
              id="client-detail-dob-list"
              title="Date of Birth"
              value={<DateOfBirth date={date_of_birth} />}
            />
          </div>
        </div>

        <div className="row client-details__user-contact-info">
          {
            !isEmpty(phone_numbers) && (
              <div className="col-md-6">
                <DetailDefinitionList
                  id="client-detail-phone-list"
                  title="Phone"
                  value={(
                    <SimpleList
                      list={phone_numbers}
                      ListElement={PhoneNumber}
                      className="client-details__phone-number"
                    />
                  )}
                />
              </div>
            )
          }
          {
            !isEmpty(email_addresses) && (
              <div className="col-md-6">
                <DetailDefinitionList
                  id="client-detail-email-list"
                  title="Email"
                  value={<SimpleList list={email_addresses} ListElement={Email} />}
                />
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

ContactDetails.propTypes = {
  contact: PropTypes.shape({
    date_of_birth: PropTypes.number.isRequired,
    email_addresses: PropTypes.array.isRequired,
    full_name: PropTypes.string.isRequired,
    phone_numbers: PropTypes.array.isRequired,
  }),
};

ContactDetails.defaultProps = {
  contact: {},
};

export default ContactDetails;
