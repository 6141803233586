import _ from 'lodash';

function getPermittedNetworkOptions(referralScopes, networkId) {
  return _.chain(referralScopes)
    .filter((scope) => scope.network.id === networkId)
    .map((scope) => ({
      label: scope.permitted_network.name,
      value: scope.permitted_network.id,
      initial: scope.permitted_network.id === networkId,
    }))
    .value();
}

export default getPermittedNetworkOptions;
