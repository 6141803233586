import { USER_LOAD_FROM_SESSIONSTORAGE } from 'actions';
import {
  fetchUserFromSessionId,
} from 'common/utils/Session/actions';

export default function userLoadFromSessionStorage() {
  return async (dispatch) => {
    const currentUserResponse = await fetchUserFromSessionId({ retries: 0 })(dispatch);
    dispatch({
      type: USER_LOAD_FROM_SESSIONSTORAGE,
      payload: {
        user: currentUserResponse.data.data,
      },
    });
  };
}
