import { isNil, isEmpty, isEqual } from 'lodash';

const programsFilter = (activeFilters, callbacks) => {
  let filters = activeFilters;
  const { setSelectedOptions, filterKey } = callbacks;
  const ccsSelected = activeFilters.filter((o) => o !== 'none');
  const noneAssignedSelected = activeFilters.includes('none');

  if ((isEqual(filterKey, 'none') && !isEmpty(ccsSelected)) ||
    (!isEqual(filterKey, 'none') && noneAssignedSelected)) {
    filters = [filterKey];
  }

  if (isNil(filterKey) && !isEmpty(ccsSelected)) {
    filters = ccsSelected;
  }

  setSelectedOptions(filters);
  return filters;
};

export default programsFilter;
