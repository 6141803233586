/* eslint-disable query-config/enforce-query-config */
import moment from 'moment';
import { useFind, useInvalidateQueries, usePopulate, usePopulateMemo } from 'src/api/APIHooks';
import isReferralForwarded from 'src/components/Referrals/ReferralDetail/utils/isReferralForwarded';
import { map } from 'lodash';

export const useInvalidateCaseReferrals = () => {
  const invalidateQueries = useInvalidateQueries();

  return () => {
    invalidateQueries('referral');
    invalidateQueries('provider');
    invalidateQueries('note');
  };
};

const useCaseReferrals = (serviceCase) => {
  const { data: referralResponse } = useFind('referral', { case: serviceCase.id }, {
    queryConfig: { staleTime: 10000 },
  });
  const referrals = referralResponse.data.data;
  const sending_provider = usePopulate('sending_provider', 'provider', referrals);
  const receiving_provider = usePopulate('receiving_provider', 'provider', referrals);
  const receiving_program = usePopulate('receiving_program', 'program', referrals);
  const isLoadingReferrals =
   usePopulateMemo(referralResponse.data, [sending_provider, receiving_provider, receiving_program]);

  const { data: notesResponse, isLoading: isLoadingNotes } = useFind('note', {
    subject: serviceCase.id,
    'subject.type': 'case',
  });
  const notesData = notesResponse.data.data;

  const notes = notesData.filter((n) => n.category === 'external_provider')
    .map((noteObj) => ({
      ...noteObj,
      state: 'off_platform',
      receiving_provider: { name: noteObj.text.split('platform to ')[1] },
      sending_provider: serviceCase.provider,
      created_at: moment(noteObj.created_at).unix(),
    }));

  const updatedReferrals = [];
  map(referrals, (r) => {
    const forwardedReferral = isReferralForwarded({ serviceCase, referral: r });
    if (forwardedReferral) {
      updatedReferrals.push({ ...r, state: 'forwarded' });
    } else { updatedReferrals.push(r); }
  });

  const isLoading = isLoadingReferrals || isLoadingNotes;
  const allReferrals = [...updatedReferrals, ...notes].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  return { isLoading, allReferrals };
};

export default useCaseReferrals;
