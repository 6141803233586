import { useFind, useCreateRecord, useUpdateRecord, useInvalidateQueries } from 'src/api/APIHooks';
import { defaultQueryConfig } from 'src/components/Backoffice/api/hooks/apiHookOptions';
import Notifier from 'common/helpers/Notifier';
import { get } from 'lodash';

export const useFindFormUsageByFormOrFormUtilizer = (
  formId = [],
  formUtilizerId = '',
  formUtilizerType = '',
) => {
  const utilizerType = formUtilizerId ? formUtilizerType : '';

  return (
    useFind(
      'form_usage',
      {
        form_utilizer: formUtilizerId,
        'form_utilizer.type': utilizerType,
        form: formId,
      },
      { queryConfig: defaultQueryConfig },
    )
  );
};

export const useCreateFormUsage = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return (
    useCreateRecord('form_usage', {
      mutationConfig: {
        onSuccess: () => {
          invalidateQueries('form_usages');
          invalidateQueries('forms');
        },
        onError: (error) => {
          const errorList = get(error, 'response.data.errors', []);
          const stringNotification = errorList.map((e) => e.title).join(', ');
          Notifier.dispatch(400, `Failed to create form usage: ${stringNotification}`);
        },
      },
      ...mutationConfig,
    })
  );
};

export const useUpdateFormUsage = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return (
    useUpdateRecord('form_usage', {
      mutationConfig: {
        onSuccess: () => {
          invalidateQueries('form_usages');
          invalidateQueries('forms');
        },
        onError: (error) => {
          const errorList = get(error, 'response.data.errors', []);
          const stringNotification = errorList.map((e) => e.title).join(', ');
          Notifier.dispatch(400, `Failed to update form usage: ${stringNotification}`);
        },
      },
      ...mutationConfig,
    })
  );
};
