import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { CaseFooterButtons, ReferralFooterButtons } from '.';
import '../stylesheets/referralServicesFormFooter.scss';

const ReferralServicesFormFooter = ({
  canSaveDraft,
  onCancel,
  onSaveDraft,
  onSubmitForm,
  showCaseButtons,
  submitting,
  contactId,
  hasOONCases,
  formErrors,
  disableNextButton,
}) => (
  <div className="referral-services-form-footer">
    <hr />
    <div className="row">
      <div className="col-md-12">
        {
          showCaseButtons ? (
            <CaseFooterButtons
              onCancel={onCancel}
              onSubmitForm={onSubmitForm}
              submitting={submitting}
              contactId={contactId}
            />
          ) : (
            <ReferralFooterButtons
              canSaveDraft={canSaveDraft}
              onSaveDraft={onSaveDraft}
              onSubmitForm={onSubmitForm}
              submitting={submitting}
              hasOONCases={hasOONCases}
              formErrors={formErrors}
              disableNextButton={disableNextButton}
            />
          )
        }
      </div>
    </div>
  </div>
);

ReferralServicesFormFooter.propTypes = {
  canSaveDraft: PropTypes.bool,
  onCancel: PropTypes.func,
  onSaveDraft: PropTypes.func,
  onSubmitForm: PropTypes.func,
  showCaseButtons: PropTypes.bool,
  submitting: PropTypes.bool,
  contactId: PropTypes.string.isRequired,
  hasOONCases: PropTypes.bool,
  formErrors: PropTypes.object,
  disableNextButton: PropTypes.bool,
};

ReferralServicesFormFooter.defaultProps = {
  canSaveDraft: false,
  onSubmitForm: _.noop,
  showCaseButtons: false,
  submitting: false,
  hasOONCases: false,
  formErrors: {},
  disableNextButton: false,
};

export default ReferralServicesFormFooter;
