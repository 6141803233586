export const BINARY_GENDERS = ['male', 'female', 'undisclosed'];
export const MASKED_GENDER = 'undisclosed';

export function getGender({ gender, flag }) {
  if (flag && !BINARY_GENDERS.includes(gender)) {
    return MASKED_GENDER;
  }

  return gender;
}

export function canEditGender({ gender, flag }) {
  if (flag && !BINARY_GENDERS.includes(gender)) {
    return false;
  }

  return true;
}
