import createXHRPromise from 'common/utils/createXHRPromise';
import { ROUTE_BASEPATH } from 'src/config/env/env.config';

export const versionUrl = () => {
  const baseUrl = ROUTE_BASEPATH === '/' ? '' : ROUTE_BASEPATH;
  return `${baseUrl}/public/version.json`;
};

export default function fetchCurrentAppVersion() {
  return () => {
    const request = createXHRPromise(versionUrl());

    function onSuccess(payload) {
      return payload;
    }

    function onError() {
      return {};
    }

    return request.then(onSuccess, onError);
  };
}
