import { useContext } from 'react';
import { TrackerContext } from '@unite-us/client-utils';

const useTrackEvent = () => {
    const trackEvent = useContext(TrackerContext);

    const trackEventData = (pageName, eventData) => {
      if (!pageName || !eventData) {
        if (window.Rollbar) {
          window.Rollbar.error(new Error('page name or event data is undefined when tracking event'));
          // eslint-disable-next-line no-console
          console.error('page name or event data is undefined');
        }
      }
      trackEvent(pageName, eventData);
    };
    return trackEventData;
};

export default useTrackEvent;
