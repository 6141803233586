import { isEmpty } from 'lodash';

export default function formatAddress(address) {
  if (isEmpty(address)) {
    return undefined;
  }

  let finalAdr = '';

  finalAdr += address.line_1 ? `${address.line_1}, ` : '';
  finalAdr += address.city ? `${address.city}, ` : '';
  finalAdr += address.state ? `${address.state} ` : '';
  finalAdr += address.postal_code ? `${address.postal_code}` : '';

  return finalAdr;
}
