import statesEnums from 'src/reducers/constants/serviceAreasStates';
import { SERVICE_AREA_TYPE_STATE } from 'components/Groups/constants';

const getServiceAreaDisplayName = (abbr) => statesEnums.find((stateEnum) => stateEnum.value === abbr)?.display_name;

const addDisplayNameToServiceAreas = (service_areas) => service_areas.reduce((prev, sA) => {
    if (sA.service_area_type === SERVICE_AREA_TYPE_STATE) {
      const serviceAreaName = getServiceAreaDisplayName(sA.state_abbreviation);
      if (serviceAreaName) {
        prev.push({ ...sA, name: serviceAreaName });
      }
    } else {
      prev.push(sA);
    }
    return prev;
  }, []);

export default addDisplayNameToServiceAreas;
