import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { SelectField, Button } from '@unite-us/ui';
import { validateReduxForm } from 'common/form';
import { validations } from '@unite-us/app-components';
import { fetchReferralPrograms } from 'actions/Program/Group';
import { reassignReferralProgram } from 'actions/Program/Referral/Group';
import { OverlaySpinner, Spinner } from 'common/spinners';
import { isDraft } from 'src/components/Referrals/ReferralStatus/utils/status';
import { isOptionUpdated } from 'src/components/Referrals/ReferralFormFields/utils/selectField';
import {
  hasPaymentsUserAccess,
  includePathwaysServices,
} from 'src/common/utils/FeatureFlags/flags';
import { buildContractedProgramOptions } from 'src/components/Referrals/ReferralStatus/utils';

export class ReferralProgramForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      programs: [],
      isFetchingPrograms: true,
    };

    this.fetchPrograms = this.fetchPrograms.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    const { referral } = this.props;
    if (!isDraft(referral)) {
      this.fetchPrograms(referral);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextServiceType = _.get(nextProps, 'referral.service_type', {});
    const currentServiceType = _.get(this.props, 'referral.service_type', {});

    if (isOptionUpdated(currentServiceType, nextServiceType)) {
      if (!isDraft(nextProps.referral)) {
        this.fetchPrograms(nextProps.referral);
      }
    }
  }

  onSubmit(values) {
    const { referral, groupId } = this.props;
    const referralId = referral.id;
    const programId = _.get(values, 'referred_to_program.id');

    const params = {
      referral: {
        referred_to_program_id: programId,
      },
    };

    return this.props
      .reassignReferralProgram(referralId, groupId, params)
      .then(() => {
        this.onCancel();
      });
  }

  onCancel() {
    this.props.closeModal();
  }

  fetchPrograms(referral) {
    const { groupId } = this.props;
    const programId = _.get(referral, 'referred_to_program.id');

    const fetchGroupsProgramsOptions = {
      excludeBillable: false,
      excludeAuthorizationRequired: true,
      includePathways: this.props.includePathways,
    };

    this.props.fetchReferralPrograms(referral, groupId, fetchGroupsProgramsOptions)
    .then((payload) => {
      const programs = _.get(payload, 'data.data', []);
      this.setState({
        programs,
      });
      if (_.findIndex(programs, { id: programId }) < 0) {
        this.props.fetchReferralPrograms(referral, groupId, {
          ids: programId,
        }).then((response) => {
          const program = _.get(response, 'data.data', []);
          this.setState({
            programs: [
              ...program,
              ...this.state.programs,
            ],
            isFetchingPrograms: false,
          });
        });
      } else {
        this.setState({ isFetchingPrograms: false });
      }
    });
  }

  render() {
    const {
      fields,
      registerField,
      handleSubmit,
      submitting,
      usePaymentsUserRole,
    } = this.props;

    if (this.state.isFetchingPrograms) {
      return <Spinner text="Retrieving Programs..." />;
    }

    const programs = this.state.programs;
    let programOptions;
    if (usePaymentsUserRole) {
      programOptions = programs.map((program) => buildContractedProgramOptions(program, 'name'));
    } else {
      programOptions = programs.map((p) => ({ name: p.attributes.name, id: p.id }));
    }

    return (
      <form
        className="referral-program-form content-with-actions"
        onSubmit={handleSubmit(this.onSubmit)}
      >
        <div className="content-container content-container--medium">
          <OverlaySpinner text="Updating Program..." show={submitting} />

          <SelectField
            className="referral-program-form__select-field"
            id="referral-program-select"
            field={fields.referred_to_program}
            label="Programs"
            inline={false}
            ref={registerField}
            options={programOptions}
            labelKey="name"
            valueKey="id"
            forceObjectValue
            validations={validations.isRequired}
          />
        </div>
        <div className="actions">
          <span className="action-item">
            <Button
              id="reject-referral-cancel-btn"
              onClick={this.onCancel}
              disabled={submitting}
              label="Cancel"
            />
          </span>
          <span className="action-item">
            <Button
              id="reject-referral-reject-btn"
              onClick={handleSubmit(this.onSubmit)}
              disabled={submitting}
              label="Save"
              primary
            />
          </span>
        </div>
      </form>
    );
  }
}

ReferralProgramForm.propTypes = {
  referral: PropTypes.object.isRequired,
  fetchReferralPrograms: PropTypes.func.isRequired,
  reassignReferralProgram: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  closeModal: PropTypes.func,
  /* redux form props */
  fields: PropTypes.shape({
    referred_to_program: PropTypes.object.isRequired,
  }),
  registerField: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  usePaymentsUserRole: PropTypes.bool.isRequired,
  includePathways: PropTypes.bool,
};

ReferralProgramForm.defaultProps = {
  closeModal: _.noop,
  fields: {
    referred_to_program: {},
  },
};

function mapStateToProps(state, ownProps) {
  const { referral } = ownProps;
  const usePaymentsUserRole = hasPaymentsUserAccess(state);
  const groupId = _.get(state, 'session.groupId', '');

  return {
    groupId,
    usePaymentsUserRole,
    includePathways: includePathwaysServices(state),
    initialValues: {
      referred_to_program: {
        id: _.get(referral, 'referred_to_program.id', ''),
      },
    },
  };
}

const fields = ['referred_to_program'];

export default validateReduxForm(
  {
    form: 'editProgramForm',
    fields,
  },
  mapStateToProps,
  {
    fetchReferralPrograms,
    reassignReferralProgram,
  },
)(ReferralProgramForm);
