import { isEmpty } from 'lodash';
import getGroupCenter from './getGroupCenter';
import getEmployeeCenter from './getEmployeeCenter';

const getGeoCoordinates = (geoCoordinates) => (
  isEmpty(geoCoordinates) ? undefined : geoCoordinates
);

const getIpCoordinates = (ipCoordinates) => (
  isEmpty(ipCoordinates) ? undefined : ipCoordinates
);

const getOurCoordinates = ({
  currentUserGroup,
  employee,
  geoCoordinates,
  ipCoordinates,
}) => {
  const employeeCenter = getEmployeeCenter(employee);
  const groupCenter = getGroupCenter(currentUserGroup);

  return (
    employeeCenter ||
    groupCenter ||
    getGeoCoordinates(geoCoordinates) ||
    getIpCoordinates(ipCoordinates)
  );
};

export default getOurCoordinates;
