import _ from 'lodash';
import { splitInAndOONGroups } from 'src/components/Groups/utils';
import { removeGroupsByIds } from 'src/components/Referrals/utils/scopes';
import { serviceTypes as serviceTypeUtils } from '@unite-us/client-utils';

function fetchNetworkGroupsByReferralScope({
  groupId,
  pageLimit,
  referralSenderId,
  referredByNetworkId,
  referredToNetworkId,
  scope,
  searchNetworkGroups,
  serviceTypeId,
  serviceTypes,
  isOON,
  feeScheduleProgramId,
}) {
  const service_type_ids = [serviceTypeId];
  const params = {
    groupId,
    options: {
      per: pageLimit,
      q: {
        service_type_ids,
        feeScheduleProgramId,
      },
    },
    referredByNetworkId,
    referredToNetworkId,
    isOON,
  };

  const removeSelfAndSender = (groups) => (
    removeGroupsByIds(groups, [groupId, referralSenderId])
  );

  const formatGroupResponseData = (response) => {
    const composedResponse = {
      ...response,
      data: {
        ...response.data,
        data: serviceTypeUtils.composeGroupsList(response.data.data, serviceTypes),
      },
    };

    return splitInAndOONGroups(
      removeSelfAndSender(_.get(composedResponse, 'data.data', [])),
    );
  };

  return new Promise((resolve) => {
    searchNetworkGroups(params).then((response) => {
      const groups = formatGroupResponseData(response);
      const { inNetwork } = groups;

      resolve({
        oonGroups: groups.outOfNetwork,
        suggestedGroups: scope === 'coordination_center_only' ? removeSelfAndSender(inNetwork) : inNetwork,
      });
    });
  });
}

export default fetchNetworkGroupsByReferralScope;
