import { compact, uuOmit } from 'lodash';
import { validations } from '@unite-us/app-components';
import checkPlanMemberIdFieldValues from './checkPlanMemberIdFieldValues';

function isRequiredPaymentsPlanType({
  values,
  value,
}) {
  const insurance = uuOmit(values, 'id', 'form_id');
  const anyValidValues = compact(Object.values(insurance)).length;

  if (anyValidValues) {
    return validations.isRequired(value);
  }

  return null;
}

function isRequiredPaymentsPlan({ values, value }) {
  if (checkPlanMemberIdFieldValues(values)) {
    return validations.isRequired(value);
  }

  return null;
}

function isRequiredPaymentsMemberId({ values, value }) {
  if (checkPlanMemberIdFieldValues(values)) {
    return validations.isRequired(value);
  }

  return null;
}

const insuranceFieldsValidations = {
  isRequiredPaymentsMemberId,
  isRequiredPaymentsPlan,
  isRequiredPaymentsPlanType,
};

export default insuranceFieldsValidations;
