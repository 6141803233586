import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@unite-us/client-utils';
import _ from 'lodash';
import './ContactColumnDetails.scss';

function ContactColumnDetails(props) {
  const { contact, linkLabel, linkColor } = props;

  return (
    <div className="contact-column-details">
      <a
        className="contact-column-details__link"
        style={{ color: linkColor }}
        role="link"
        onClick={() => props.onLinkClick(contact)}
      >
        {_.upperCase(linkLabel)}
      </a>
      <a
        className="triangle--right"
        onClick={() => props.onLinkClick(contact)}
        role="link"
      />
    </div>
  );
}

ContactColumnDetails.propTypes = {
  contact: PropTypes.object.isRequired,
  onLinkClick: PropTypes.func,
  linkLabel: PropTypes.string.isRequired,
  linkColor: PropTypes.string,
};

ContactColumnDetails.defaultProps = {
  linkColor: colors.palettes.BLUE,
  linkLabel: '',
};

export default ContactColumnDetails;
