import { addresses as addressesUtils } from '@unite-us/client-utils';
import getGoogleAddressDetails from './getGoogleAddressDetails';

const getClientAddress = async ({ addresses = [] } = {}) => {
  const address = addressesUtils.findMainAddress(addresses);
  try {
    return await getGoogleAddressDetails(address);
  } catch (error) {
    console.error('Could not find client address', error); /* eslint-disable-line no-console */
    return error;
  }
};

export default getClientAddress;
