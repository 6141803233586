import { massageAddressFields } from 'common/utils/addressUtils';
import { cleanMilitaryFields } from 'src/components/MilitaryInformation/utils';
import { mapKeys } from 'src/components/Group/Programs/Edit/utils';
import { uuCompactArrayOrObject, uuOmit } from 'lodash';

function formatEmails(email = {}) {
  return email.email_address && [{
    acceptable_communication_types: mapKeys(email.acceptable_communication_types),
    email_address: email.email_address,
    is_primary: true,
  }];
}

function formatPhones(phone = {}) {
  return phone.phone_number && [{
    acceptable_communication_types: mapKeys(phone.acceptable_communication_types),
    is_primary: true,
    phone_number: phone.phone_number,
    phone_type: phone.phone_type || 'unknown',
  }];
}

function formatContact(values) {
  return uuCompactArrayOrObject({
    ...values,
    email: undefined,
    email_addresses: formatEmails(values.email),
    military: cleanMilitaryFields(values.military),
    phone: undefined,
    phone_numbers: formatPhones(values.phone),
  });
}

export function formatNewContactDataWithNoInsurance({
  fields = {},
  values,
}) {
  const valuesWithoutInsurance = uuOmit(values, ['insurance', 'insurance_ids']);
  const cleanedValues = massageAddressFields(
    valuesWithoutInsurance,
    fields.addresses,
  );

  return formatContact(cleanedValues);
}
