import { useState, useCallback, useEffect } from 'react';

const useInvoiceDrawer = ({
  invoices,
  totalPageCount,
  pageNumber,
  setPageNumber,
  pageSize,
  open,
  setOpen,
}) => {
  const [clickedIdx, setClickedIdx] = useState();
  const [activeRowRef, setActiveRowRef] = useState(null);

  const clickedInvoice = invoices[clickedIdx];
  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === totalPageCount;
  const isFirstInvoice = clickedIdx === 0;
  const isLastInvoice = clickedIdx === invoices.length - 1;

  const openDrawer = useCallback((idx, rowRef) => () => {
    setClickedIdx(idx);
    setActiveRowRef(rowRef);
    setOpen(true);
  }, [setClickedIdx, setOpen, setActiveRowRef]);

  useEffect(() => {
    if (!open && activeRowRef && activeRowRef.current) {
      activeRowRef.current.focus();
    }
  }, [open, activeRowRef]);

  const getInvoice = useCallback((increment) => () => {
    if (
      (increment > 0 && isLastInvoice && !isLastPage) ||
      (increment < 0 && isFirstInvoice && !isFirstPage)
    ) {
      setPageNumber(pageNumber + increment);
      setClickedIdx(increment > 0 ? 0 : pageSize - 1);
    } else {
      setClickedIdx(clickedIdx + increment);
    }
  }, [
    setClickedIdx,
    clickedIdx,
    invoices,
    setPageNumber,
    pageNumber,
    pageSize,
  ]);

  useEffect(() => {
    if (clickedInvoice === undefined) {
      setOpen(false);
    }
  });

  const prevInvoiceShortId = isFirstInvoice ? undefined : invoices[clickedIdx - 1]?.short_id;
  const nextInvoiceShortId = isLastInvoice ? undefined : invoices[clickedIdx + 1]?.short_id;

  return {
    open,
    openDrawer,
    clickedInvoice,
    clickedIdx,
    getNextInvoice: isLastInvoice && isLastPage ? undefined : getInvoice(1),
    getPrevInvoice: isFirstInvoice && isFirstPage ? undefined : getInvoice(-1),
    setOpen,
    prevInvoiceShortId,
    nextInvoiceShortId,
  };
};

export default useInvoiceDrawer;
