import PropTypes from 'prop-types';
import React from 'react';
import { RadioField } from '@unite-us/ui';
import classNames from 'classnames';

const ATTACHED_TO_OPTIONS = [
  { label: 'Attached to Case', value: 'case' },
  { label: 'General Note', value: 'general' },
];

const AttachedToField = ({ className, field, hidden }) => (
  hidden ? null : (
    <div
      className={classNames(['attached-to-field', 'form-group', className])}
    >
      <h5 className="label-header">
        {'Is this note attached to a case or is this a general note?'}
      </h5>

      <RadioField
        id="attached-to"
        className="attached-to-field__radio-field"
        field={field}
        options={ATTACHED_TO_OPTIONS}
        inline
      />
    </div>
  )
);

AttachedToField.propTypes = {
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  hidden: PropTypes.bool,
};

AttachedToField.defaultProps = {
  className: '',
  hidden: false,
};

export default AttachedToField;
