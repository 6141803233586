import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import { Form, Field } from 'react-final-form';
import _ from 'lodash';

// unite-us
import { Button, Icon } from '@unite-us/ui';
import { phones as phonesUtils } from '@unite-us/client-utils';

// api
import { apiDefault } from 'api/config';
import { useFindRecord, useFind, usePopulate } from 'api/APIHooks';
import { isHttpSuccess } from 'api/utils/httpStatus';

// enums
import DEFAULT_ENUMS from 'reducers/constants/defaultEnums';

// common
import FeatureFlagContainer from 'common/utils/FeatureFlags/FeatureFlagContainer';

import { Spinner } from 'common/spinners';
import { BackButton } from 'common/buttons';
import { useFeatureFlag, useAuthorizeClientMerging } from 'common/hooks';
import DollarAmount from 'common/display/Money/DollarAmount';
import Notifier from 'common/helpers/Notifier';
import { browserHistory } from 'common/utils/browserHistory';

// components
import { isOrgAdmin } from 'components/User/utils';
import ConfirmationModal from 'components/People/MergeDetails/components/ConfirmationModal';
import Address from './components/Address';
import Nicknames from './components/Nicknames';
import Name from './components/Name';

// utils
import { mergeClientData, MULTIPLE_VALUES_ATTRIBUTES } from './utils/mergeClientData';

const required = (value) => (value ? undefined : 'Required');
const validateForm = (values) => {
  const errors = {};
  Object.keys(values).forEach((key) => {
    if (MULTIPLE_VALUES_ATTRIBUTES.includes(key)) {
      return;
    }
    const error = required(values[key]);
    if (error) {
      errors[key] = error;
    }
  });

  return errors;
};
const enumDisplayName = (value, enumsPath) => {
  const path = enumsPath === 'people.gender' ? 'people.genders' : enumsPath;
  const pathEnums = _.wget(DEFAULT_ENUMS, path, []);
  const foundValue = pathEnums.find((element) => element.value === value);
  return foundValue?.display_name || '';
};
const formatDate = (date) => moment(date).format('MM/DD/YY');

const FieldType = {
  gross_monthly_income: 'money',
  marital_status: 'enum',
  gender: 'enum',
  race: 'enum',
  ethnicity: 'enum',
  citizenship: 'enum',
  date_of_birth: 'date',
  household: 'household',
  sexual_orientation: 'sexuality',
  email_addresses: 'email_address',
  phone_numbers: 'phone_number',
  addresses: 'address',
  nicknames: 'nicknames',
  name: 'name',
};

const FieldKey = {
  email_addresses: 'email_address',
  phone_numbers: 'phone_number',
  addresses: 'id',
};

const SexualityDisplay = ({ value }) => {
  const sexuality =
    value.sexuality.map((sexualityOption) => enumDisplayName(sexualityOption, 'people.sexual_orientations')).join(', ');
  const sexualityOther = value.sexuality_other;

  return (
    <div>
      <div>{sexuality}&nbsp;</div>
      {sexualityOther && (<div>Other: {sexualityOther}</div>)}
    </div>
  );
};

const HouseholdValue = ({ value }) => (
  <div>
    {value.total ? <p>Household Size: {value.total}&nbsp;</p> : null}
    {
      value.adults || value.children ? (
        <p className="mt-1">
          {value.adults ? <span>Adults: {value.adults}&nbsp;</span> : null}
          {value.children ? <span>Children: {value.children}</span> : null}
        </p>
      ) :
        null
    }
  </div>
);

const FieldValue = ({ field, value }) => {
  switch (FieldType[field]) {
    case 'enum':
      return enumDisplayName(value, `people.${field}`);
    case 'money':
      return <DollarAmount value={value} />;
    case 'date':
      return formatDate(value);
    case 'email_address':
      return <EmailAddress email={value} />;
    case 'household':
      return <HouseholdValue value={value} />;
    case 'sexuality':
      return <SexualityDisplay value={value} />;
    case 'phone_number':
      return <PhoneNumber phone={value} />;
    case 'address':
      return <Address address={value.attributes} />;
    case 'nicknames':
      return <Nicknames nicknames={value} />;
    case 'name':
      return <Name {...value} />;
    default:
      return value;
  }
};

const PhoneNumber = ({ phone }) => (
  <div className="flex items-center">
    <div className="flex flex-col">
      {phone.phone_type && (
        <span className="italic">
          {enumDisplayName(phone.phone_type, 'contact_data.phone_types')}
        </span>
      )}
      <span className="max-w-xs">
        {phonesUtils.formatPhoneNumber(phone.phone_number)}
        {phone.extension && ` ext. ${phone.extension}`}
        {phone.is_primary && ' (Primary)'}
      </span>
      <div className="text-sm">
        {phone.acceptable_communication_types && (
          <span className="italic">
            {phone.acceptable_communication_types
              .map((type) => enumDisplayName(type, 'contact_data.communication_types'))
              .join(', ')}
          </span>
        )}
      </div>
    </div>
  </div>
);

const EmailAddress = ({ email }) => (
  <div className="flex items-center">
    <div className="flex flex-col">
      <span className="break-all max-w-xs">
        <span className="pr-1">{email.email_address}</span>
        {email.is_primary && (
          <span className="text-sm inline-block whitespace-nowrap">(Primary)</span>
        )}
      </span>

      {email.acceptable_communication_types && (
        <span className="text-sm italic">
          {email.acceptable_communication_types
            .map((type) => _.capitalize(type))
            .join(', ')}
        </span>
      )}
    </div>
  </div>
);

const MultipleFieldsRow = ({
  values, field, clientIds, selectedValues,
}) => {
  const fieldName = _.startCase((field.replace(/_/g, ' ')));

  return (
    <div className="w-full gap-2 grid grid-cols-custom" role="listitem" data-test-id="multiple-fields-row">
      <div className="text-brand-blue font-bold font-heavy-font p-2 flex col-span-1">
        {fieldName}
      </div>
      {values.map((fieldValues, index) => (
        <div
          key={`${clientIds[index]}-${field}`}
          className="flex flex-col space-y-2 w-full"
        >
          {fieldValues.map((value) => (
            <Field
              key={`client_${field}_${value[FieldKey[field]]}`}
              name={field}
              type="checkbox"
              value={value}
              id={value[FieldKey[field]]}
            >
              {({ input }) => (
                <label
                  className={cx(
                    'ui-checkbox-field flex items-center font-initial p-2',
                    {
                      'bg-light-fill-grey':
                        selectedValues
                          ?.some((selectedValue) => selectedValue[FieldKey[field]] === value[FieldKey[field]]),
                    },
                  )}
                >
                  <input
                    {...input}
                    id={`client_${field}_${value[FieldKey[field]]}`}
                    type="checkbox"
                  />
                  <div className="ui-form-field__label mb-0" />
                  <FieldValue field={field} value={value} />
                </label>
              )}
            </Field>
          ))}
        </div>
      ))}
    </div>
  );
};

const FieldRow = ({
  field, selectedValues, clientIds, values,
}) => {
  const fieldDisplayName = _.startCase(field);

  return (
    <div
      className="grid grid-cols-custom gap-2 w-full ui-radio-field ui-radio-field--inline ui-form-field mb-0"
      role="listitem"
      data-test-id="field-row"
    >
      <div className="text-brand-blue font-bold font-heavy-font p-2 flex items-center">
        {fieldDisplayName}
      </div>
      {values.map((value, index) => (
        <div
          key={`${clientIds[index]}-${field}`}
          className={cx(
            'p-2 flex items-center',
            { 'bg-light-fill-grey': selectedValues === index.toString() },
          )}
        >
          <div className="ui-radio-field__item">
            <label htmlFor={`client-${index}-${field}`}>
              <div className="flex items-center gap-1">
                <Field
                  id={`client-${index}-${field}`}
                  name={field}
                  component="input"
                  type="radio"
                  value={index.toString()}
                  validate={required}
                  aria-labelledby={`client-${index}-${field}`}
                />
                <span className="normal-case" />
                <FieldValue field={field} value={value} />
              </div>
            </label>
          </div>
        </div>
      ))}
    </div>
  );
};

export const MergeDetails = ({ location, userIsAdmin }) => {
  const isClientMergingEnabled = useFeatureFlag('cl-67-client-merging');
  const [mergedClientId, setMergedClientId] = useState(null);
  const DuplicatesModalRef = useRef();
  const [submitForm, setSubmitForm] = useState(null);
  const { query: { clients } } = location;

  useAuthorizeClientMerging(isClientMergingEnabled, userIsAdmin);

  const { data: duplicatesClientsResponse } = useFind(`people/${mergedClientId}/duplicates`, {}, {
    api: 'coreApi',
    queryConfig: {
      enabled: mergedClientId !== null,
      placeholderData: undefined,
    },
  });

  const { data: clientsComparison, isFetching: isFetchingClientComparison } = useFind(
    `people/comparison?ids[]=${clients[0]}&ids[]=${clients[1]}&`,
    {},
    {
      api: 'coreApi',
      queryConfig: {
        enabled: isClientMergingEnabled && userIsAdmin,
        placeholderData: undefined,
      },
    },
  );
  const { data: firstClientResponse, isFetching: isFetchingFirstClient } = useFindRecord(
    'person',
    clients[0],
    {
      include: 'addresses',
      queryConfig: { placeholderData: undefined },
      enabled: isClientMergingEnabled && userIsAdmin,
    },
  );
  const firstClientData = firstClientResponse?.data?.data;
  const { data: secondClientResponse, isFetching: isFetchingSecondClient } = useFindRecord(
    'person',
    clients[1],
    {
      include: 'addresses',
      queryConfig: { placeholderData: undefined },
      enabled: isClientMergingEnabled && userIsAdmin,
    },
  );
  const secondClientData = secondClientResponse?.data?.data;
  usePopulate(
    'contact_preference',
    'contact_preference',
    firstClientData,
    {
      queryConfig: { placeholderData: undefined },
    },
  );
  usePopulate(
    'contact_preference',
    'contact_preference',
    secondClientData,
    {
      queryConfig: { placeholderData: undefined },
    },
  );

  const isLoading = isFetchingFirstClient || isFetchingSecondClient || isFetchingClientComparison;
  const hasLoadingError = !isLoading && (!firstClientData || !secondClientData || !clientsComparison);
  const mergedClientHasDuplicates = duplicatesClientsResponse?.data?.length >= 2;
  const differentFields = Object.fromEntries(
    Object.entries(clientsComparison?.data?.data || {}).filter(([, value]) => value[2] === true),
  );
  const hasDifferences = Object.keys(differentFields).length > 0;

  const openConfirmationModal = (formSubmit) => {
    setSubmitForm(() => formSubmit);
    DuplicatesModalRef.current.openModal();
  };

  const confirmMergeClients = async (formValues) => {
    try {
      const { first_name, last_name } = firstClientData;
      const newestClientIndex = new Date(firstClientData.created_at) > new Date(secondClientData.created_at) ? 0 : 1;

      const mergeData = mergeClientData(
        formValues,
        clientsComparison,
        differentFields,
        { first_name, last_name },
        newestClientIndex,
      );

      const response = await apiDefault.post(
        `/people/merge?ids[]=${firstClientData.id}&ids[]=${secondClientData.id}`,
        mergeData,
      );

      if (!response || !isHttpSuccess(response.status)) {
        Notifier.dispatch('error', 'We could not complete the merge. Please try again.');
      } else {
        const clientId = response.data.data.id;
        setMergedClientId(clientId);
      }
    } catch {
      Notifier.dispatch('error', 'We could not complete the merge. Please try again.');
    } finally {
      DuplicatesModalRef.current.closeModal();
    }
  };

  return (
    <div className="flex justify-center py-12">
      {mergedClientId ? (
        <div
          className="bg-white border border-solid
            rounded border-filter-border-color w-771px
            h-409px flex flex-col items-center justify-center
            text-brand-blue space-y-6"
        >
          <h1 className="text-4xl">Merge Successful!</h1>
          <div className="text-sm">
            The client profiles are now merged.
            You can view the merge history in the note section of the client&rsquo;s face sheet
          </div>
          <div className="flex items-center justify-center space-x-4">
            <Button
              label="Go to Client's Face Sheet"
              className="w-56"
              onClick={() => browserHistory.push({ pathname: `/facesheet/${mergedClientId}` })}
            />
            {mergedClientHasDuplicates && (
              <Button
                primary
                label="Merge Additional Duplicates"
                className="w-56"
                onClick={() => browserHistory.push({ pathname: `/people/duplicates/${mergedClientId}` })}
              />
            )}
          </div>
        </div>
      ) : (
        <Form
          onSubmit={confirmMergeClients}
          validate={validateForm}
          render={({
            handleSubmit, submitting, pristine, values, errors,
          }) => (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                openConfirmationModal(() => handleSubmit(event));
              }}
            >
              <div className="flex flex-col items-center w-full max-w-5xl space-y-4">
                <BackButton className="self-start" />
                <div className="w-full">
                  <div className="px-8 py-4 bg-white border border-solid rounded-t-md border-filter-border-color">
                    <h2 className="text-xl font-semibold text-brand-blue">
                      Select Client Profile Information to Keep
                    </h2>
                    <p className="my-2 text-sm text-brand-blue">
                      Only information that differs between the two client records appears below.
                      Select which information you want to keep.
                      We&apos;ll also save all of the matching profile information from both records,
                      plus all of the referral history.
                    </p>
                    <div className="flex items-center gap-2">
                      <Icon
                        className="fill-current text-brand-blue"
                        icon={'V2Warning'}
                        size={14}
                      />
                      <span>
                        This action can&apos;t be undone.
                      </span>
                    </div>
                  </div>
                  <div
                    className="
                            flex flex-col items-center py-8 px-40
                            space-y-4 bg-white border-b border-l
                            border-r border-solid rounded-b-md
                            border-filter-border-color"
                    role="list"
                  >
                    {isLoading && <Spinner />}
                    {hasLoadingError && <div>Error loading client data</div>}
                    {!isLoading && !hasLoadingError && (
                      <div
                        className={cx('w-full flex flex-col items-center space-y-2', { 'space-y-10': !hasDifferences })}
                      >
                        <div
                          className={cx(
                            'w-full gap-2',
                            {
                              'grid grid-cols-custom': hasDifferences,
                              'flex justify-center': !hasDifferences,
                            },
                          )}
                        >
                          <div />
                          {[firstClientData, secondClientData].map((client) => (
                            <div
                              key={client.id}
                              className="
                              flex flex-col py-3 w-56
                              px-4 border border-solid rounded-md
                            border-filter-border-color
                            "
                            >
                              <h3 className="mb-1 text-base font-semibold text-action-blue">{client.full_name}</h3>
                              <div className="flex flex-col gap-1">
                                <span className="italic">Last Updated: {formatDate(client.updated_at)}</span>
                                <span className="italic">Date Created: {formatDate(client.created_at)}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                        {Object.keys(differentFields).map((field) => {
                          const isMultiValueField = MULTIPLE_VALUES_ATTRIBUTES.includes(field);
                          const FieldComponent = isMultiValueField ? MultipleFieldsRow : FieldRow;
                          return (
                            <FieldComponent
                              key={`field-row-${field}`}
                              field={field}
                              clientIds={[firstClientData.id, secondClientData.id]}
                              values={[differentFields[field][0], differentFields[field][1]]}
                              selectedValues={values[field]}
                            />
                          );
                        })}
                        {hasDifferences ? (
                          <div className="pt-6">
                            Showing only fields with different values.
                          </div>
                        ) : (
                          <div className="italic">
                            All of the client details match in these records. Select Merge Records to continue.
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full flex justify-end">
                  <Button
                    type="submit"
                    primary
                    label="Merge Records"
                    className="w-40"
                    disabled={hasDifferences && (submitting || pristine || Object.keys(errors).length > 0)}
                  />
                </div>
              </div>
            </form>
          )}
        />
      )}
      <ConfirmationModal modalRef={DuplicatesModalRef} onMergeRecordsClick={submitForm} />
    </div>
  );
};

PhoneNumber.propTypes = {
  phone: PropTypes.shape({
    phone_number: PropTypes.string.isRequired,
    is_primary: PropTypes.bool,
    acceptable_communication_types: PropTypes.arrayOf(PropTypes.string),
    country_code: PropTypes.string,
    phone_type: PropTypes.string,
    extension: PropTypes.string,
    can_sms: PropTypes.bool,
  }).isRequired,
};

EmailAddress.propTypes = {
  email: PropTypes.shape({
    email_address: PropTypes.string.isRequired,
    is_primary: PropTypes.bool,
    acceptable_communication_types: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

HouseholdValue.propTypes = {
  value: PropTypes.object.isRequired,
};

FieldValue.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};

FieldRow.defaultProps = {
  selectedValues: null,
};

SexualityDisplay.propTypes = {
  value: PropTypes.object.isRequired,
};

FieldRow.propTypes = {
  field: PropTypes.string.isRequired,
  clientIds: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  selectedValues: PropTypes.any,
};

MergeDetails.propTypes = {
  location: PropTypes.object.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
};

MultipleFieldsRow.defaultProps = {
  selectedValues: null,
};

MultipleFieldsRow.propTypes = {
  values: PropTypes.arrayOf(PropTypes.array).isRequired,
  field: PropTypes.string.isRequired,
  clientIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedValues: PropTypes.any,
};

const mapStateToProps = (state) => {
  const { session } = state;

  return {
    userIsAdmin: isOrgAdmin(state.user, session.groupId),
  };
};

export default connect(mapStateToProps)(FeatureFlagContainer(MergeDetails));
