export const PHONE_TYPES_THAT_CAN_RECEIVE_WRITTEN_COMMUNICATION = [
  'mobile',
];

export const PHONE_TYPE_OPTIONS = [
  { display_name: 'Phone', value: 'phone' },
  { display_name: 'Fax', value: 'fax' },
  { display_name: 'Other', value: 'other' },
];

export const PHONE_TYPE_FAX = 'fax';
