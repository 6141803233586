import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import _ from 'lodash';
import pickValidLinkProps from 'src/common/utils/SecureLink/utils/pickValidLinkProps';
import './stylesheets/secureLink.scss';

export default class SecureLink extends React.Component {
  constructor(props) {
    super(props);

    this.onValidLinkClick = this.onValidLinkClick.bind(this);
  }

  onValidLinkClick() {
    this.props.onLinkClick({ status: 'success' });
  }

  render() {
    const linkProps = pickValidLinkProps(this.props);

    return (
      <Link {...linkProps} onClick={this.onValidLinkClick} />
    );
  }
}

SecureLink.propTypes = {
  ...Link.propTypes,
  onLinkClick: PropTypes.func,
};

SecureLink.defaultProps = {
  onLinkClick: _.noop,
};
