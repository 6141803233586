import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Stepper from 'common/Stepper/Stepper';
import _ from 'lodash';
import { connect } from 'react-redux';
import { destroyDropzone } from 'common/form/FileUpload/actions/FileUpload';
import { CONTACT_CASE_FILE_UPLOAD_KEY } from 'src/components/Cases/constants';
import {
  setInitialStepperConfig,
  setDisabledStepperConfig,
  emptyGroupForms,
  atReviewStep,
  containGroupForms,
  groupFormsPropsChanged,
} from '../utils/stepper';

export class HorizontalLinearStepper extends Component {
  constructor(props) {
    super(props);

    const { assistanceRequestId } = this.props;
    const prefix = assistanceRequestId ? `/assistance-requests/${assistanceRequestId}` : '';

    this.state = {
      stepperConfig: setInitialStepperConfig(props, prefix),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.children, this.props.children)) {
      const newStepperConfig = [...this.state.stepperConfig];
      newStepperConfig[nextProps.currentStep].content = nextProps.children;

      this.setState({ stepperConfig: newStepperConfig });
    }

    if (nextProps.currentStep !== this.props.currentStep) {
      this.stepper.setCurrentStep(nextProps.currentStep);
    }

    if (containGroupForms(nextProps) && groupFormsPropsChanged(nextProps, this.props)) {
      const { assistanceRequestId } = nextProps;
      const prefix = assistanceRequestId ? `/assistance-requests/${assistanceRequestId}` : '';

      this.setState({ stepperConfig: setInitialStepperConfig(nextProps, prefix) });
    }

    if (emptyGroupForms(nextProps) && atReviewStep(nextProps)) {
      this.setState({ stepperConfig: setDisabledStepperConfig(this.state, nextProps) });
    }
  }

  componentWillUnmount() {
    this.props.destroyDropzone(CONTACT_CASE_FILE_UPLOAD_KEY);
  }

  render() {
    const { currentStep } = this.props;

    return (
      <div className="horizontal-stepper" style={{ width: '100%', margin: 'auto' }}>
        <h2 className="horizontal-stepper__title">Create Case</h2>
        <Stepper
          ref={(el) => { this.stepper = el; }}
          steps={this.state.stepperConfig}
          initialStep={currentStep}
          stepStyle={{ flex: '0 1 auto' }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    groupForms,
    session: { groupId },
  } = state;

  return {
    groupForms: _.get(groupForms, groupId, []),
  };
}

HorizontalLinearStepper.propTypes = {
  assistanceRequestId: PropTypes.string,
  currentStep: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  destroyDropzone: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  destroyDropzone,
})(HorizontalLinearStepper);
