import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { concat, get, isEmpty, flow } from 'lodash';
import { Card } from 'common/Card';
import { useCurrentEmployeeId } from 'common/contexts/CurrentEmployeeContext';
import { Drawer } from 'common/Drawer';
import { Spinner } from 'common/spinners';
import { useCurrentPayerId, useCurrentProviderId, useIsNetworkLead } from 'common/contexts/CurrentProviderContext';
import FeatureFlagContainer from 'common/utils/FeatureFlags/FeatureFlagContainer';
import { hasPayerInvoicesRole } from 'common/utils/FeatureFlags/flags';
import { useFind } from 'src/api/APIHooks';
import { useFeatureFlag } from 'src/common/hooks';
import { apiV1 } from 'src/api/config';
import {
  InvoicesTable,
  InvoicePagination,
  BulkActions,
  InvoiceDetails,
} from '../components';
import { FilterSelectors } from '../components/Filter';
import {
  useInvoiceDrawer,
  useInvoiceExports,
  useInvoiceFilters,
  useInvoicePagination,
  useInvoiceSorting,
  useProviderFeeSchedules,
} from '../hooks';
import getSort from '../utils/getSort';
import usePayerWQInteractiveView from '../hooks/usePayerWQInteractiveView';
import AllFiltersDrawer from '../components/Filter/AllFiltersDrawer';
import getInvoiceWQBucketName from '../utils/getInvoiceWQBucketName';
import getWQInvoiceStatus from '../utils/getWQInvoiceStatus';
import usePayerProviderPlans from '../hooks/usePayerProviderPlans';
import { INVOICE_TYPES } from '../constants';

export const InvoiceWorkqueue = ({
  archived,
  bulkActions,
  children,
  disputed,
  invoiceStatus,
  path,
  renderNoInvoicesFound,
  showInvoiceAging,
  showPayerInvoices,
  showStatusFilter,
  statusOptionsConstant,
}) => {
  const pays5214BillingForScreenings = useFeatureFlag('pays-5214-billing-for-screenings');
  const [openAllFiltersDrawer, setOpenAllFiltersDrawer] = useState(false);
  const [isDetailDrawerOpen, setIsDetailDrawerOpen] = useState(false);
  const isNetworkLead = useIsNetworkLead();
  const currentProviderId = useCurrentProviderId();
  const payerId = useCurrentPayerId();
  const paginationProps = useInvoicePagination();
  const currentEmployeeId = useCurrentEmployeeId();
  const filterProps = useInvoiceFilters();
  const sortProps = useInvoiceSorting({ resetPagination: paginationProps.resetPagination });
  const { isLoading: isPayerInteractiveLoading, payerWQInteractiveView } = usePayerWQInteractiveView(payerId);
  const allInvoicesFilter = invoiceStatus === '' ? null : disputed;
  const disputeFilter = filterProps.underDispute ? filterProps.underDispute : allInvoicesFilter;
  const variableInvoiceStatuses = getWQInvoiceStatus(invoiceStatus, showPayerInvoices, payerWQInteractiveView);
  const payerProviderFeeScheduleIds = usePayerProviderPlans(payerId, showPayerInvoices);
  const providerFeeScheduleIds = useProviderFeeSchedules(
    currentProviderId,
    currentEmployeeId,
    isNetworkLead,
    showPayerInvoices,
  );
  const uniqueFeeScheduleIds = showPayerInvoices ? payerProviderFeeScheduleIds : providerFeeScheduleIds;

  const userRoleVariableFilters = showPayerInvoices ? {
    payer: payerId,
    under_dispute: disputeFilter,
  } : {
    ...(isNetworkLead ? {
      network_lead: currentProviderId,
      under_dispute: disputeFilter,
      payer: filterProps.invoicePayer,
    } : {
      provider: currentProviderId,
      ...!disputed && { under_dispute: disputeFilter },
      ...disputed ? { has_disputed_resolved: true } : {},
      payer: filterProps.invoicePayer,
      }),
  };

  const defaultInvoiceFilters = {
    fee_schedule_program: filterProps.serviceProvided,
    ...filterProps.dateDuration,
    has_user_archive: archived,
    last_updated: filterProps.lastUpdated,
    invoice_status: concat([], filterProps.status || variableInvoiceStatuses).join(','),
    ...(filterProps.managedProvider && { provider: filterProps.managedProvider }),
    short_id_like: filterProps.invoiceShortId,
    client_name_like: filterProps.invoiceClientName,
    ...(filterProps.payerProvider && { provider: filterProps.payerProvider }),
    invoice_dispute_resolution_reason: filterProps.disputeResolution,
    invoice_dispute_reason: filterProps.disputeReason,
    invoice_type: filterProps.invoiceType,
    fee_schedule_screening: filterProps.feeScheduleScreeningName.value,
  };

  const invoiceFilters = { ...userRoleVariableFilters, ...defaultInvoiceFilters };
  const { isLoading: isInvoicesLoading, data } = useFind(
    'invoice',
    invoiceFilters,
    {
      include: 'submitter',
      page: {
        number: paginationProps.pageNumber,
        size: paginationProps.pageSize,
      },
      queryConfig: {
        enabled: !!currentProviderId && !isPayerInteractiveLoading && !isDetailDrawerOpen,
        placeholderData: undefined,
      },
      sort: getSort(sortProps),
    },
  );

  const fetchAllInvoices = useCallback(async () => (
    apiV1.query(
      'invoice',
      invoiceFilters,
      {
        page: {
          number: 1,
          size: paginationProps.totalItemCount,
        },
      },
    )
  ), [invoiceFilters, paginationProps]);
  const isLoading = isPayerInteractiveLoading || isInvoicesLoading;

  const exportProps = useInvoiceExports({ currentEmployeeId, fetchAllInvoices });
  const onUpdateFilter = useCallback(() => {
    paginationProps.resetPagination();
    exportProps.deselectAll();
  }, [paginationProps.resetPagination, exportProps.deselectAll]);

  const invoices = get(data, 'data.data', []);
  const totalItemCount = get(data, 'data.paging.total_count', 0);
  const totalPageCount = get(data, 'data.paging.total_pages', 0);

  const drawerProps = useInvoiceDrawer({
    invoices,
    pageNumber: paginationProps.pageNumber,
    pageSize: paginationProps.pageSize,
    setPageNumber: paginationProps.setPageNumber,
    totalPageCount,
    open: isDetailDrawerOpen,
    setOpen: setIsDetailDrawerOpen,
  });
  const { isFiltered, resetFilters } = filterProps;

  const closeAllFiltersDrawer = () => {
    drawerProps.setOpen(false);
    setOpenAllFiltersDrawer(false);
  };
  const bucketName = getInvoiceWQBucketName(statusOptionsConstant);

  const { data: fspData } = useFind(
    'fee_schedule_program',
    { fee_schedule: uniqueFeeScheduleIds.join() },
    {
      page: { number: 1, size: 1 },
      queryConfig: { enabled: !!invoices, placeholderData: undefined },
    },
  );

  const { data: fssData } = useFind(
    'fee_schedule_screening',
    { fee_schedule: uniqueFeeScheduleIds.join() },
    {
      page: { number: 1, size: 1 },
      queryConfig: { enabled: !!invoices, placeholderData: undefined },
    },
  );

  const hasFeeSchedulePrograms = !!(fspData?.data?.data?.length);
  const hasFeeScheduleScreenings = !!(fssData?.data?.data?.length);
  const showInvoiceType = pays5214BillingForScreenings && hasFeeSchedulePrograms && hasFeeScheduleScreenings;
  const invoiceTypes = [
    hasFeeSchedulePrograms && INVOICE_TYPES.SERVICE,
    hasFeeScheduleScreenings && INVOICE_TYPES.SCREENING,
  ].filter(Boolean);

  if (!isEmpty(children)) {
    return React.cloneElement(children, { showInvoiceType });
  }

  return (
    <div className="divide-y divide-solid divide-dark-border-blue">
      <div className="px-3 py-3">
        {/* eslint-disable no-nested-ternary */}
        {isLoading ? (
          <div role="alert"><Spinner /></div>
        ) : (
          !isEmpty(invoices) ? (
            <>
              <Card className="bg-white py-10 pt-30 overflow-x-scroll">
                <h1 className="text-text-blue font-extrabold font-heavy-font text-2xl mb-6 px-8">
                  {`${bucketName} Invoices`}
                </h1>
                <div className="flex px-9">
                  <BulkActions
                    className="mt-2 mr-5"
                    bulkActions={bulkActions}
                    {...exportProps}
                    onClose={() => drawerProps.setOpen(false)}
                    invoices={invoices}
                    currentEmployeeId={currentEmployeeId}
                    payerWQInteractiveView={payerWQInteractiveView}
                  />
                  <FilterSelectors
                    {...filterProps}
                    archived={archived}
                    disputed={disputed}
                    onUpdateFilter={onUpdateFilter}
                    showStatusFilter={showStatusFilter}
                    statusOptionsConstant={statusOptionsConstant}
                    setOpenAllFiltersDrawer={setOpenAllFiltersDrawer}
                    payerWQInteractiveView={payerWQInteractiveView}
                    showInvoiceType={showInvoiceType}
                    invoiceTypes={invoiceTypes}
                  />
                </div>
                <InvoicesTable
                  invoices={invoices}
                  openDrawer={drawerProps.openDrawer}
                  showInvoiceAging={showInvoiceAging}
                  showInvoiceType={showInvoiceType}
                  path={path}
                  {...sortProps}
                  {...exportProps}
                />
                <InvoicePagination
                  {...paginationProps}
                  totalItemCount={totalItemCount}
                  totalPageCount={totalPageCount}
                />
              </Card>
            </>
          ) : (
            renderNoInvoicesFound({
              isFiltered,
              resetFilters: () => {
                resetFilters();
                onUpdateFilter();
              },
            })
          )
        )}
        {/* eslint-enable no-nested-ternary */}
        <Drawer open={drawerProps.open} setOpen={drawerProps.setOpen}>
          {
            (isLoading || !drawerProps.clickedInvoice) ? (
              <div className="pt-8" role="alert" aria-live="polite"><Spinner /></div>
            ) : (
              <InvoiceDetails
                getNextInvoice={drawerProps.getNextInvoice}
                getPrevInvoice={drawerProps.getPrevInvoice}
                showInvoiceType={showInvoiceType}
                invoice={drawerProps.clickedInvoice}
                isNetworkLead={isNetworkLead}
                onClose={() => drawerProps.setOpen(false)}
                deselectAll={exportProps.deselectAll}
                payerWQInteractiveView={payerWQInteractiveView}
                path={path}
              />
            )
          }
        </Drawer>
        {
          openAllFiltersDrawer && (
            <Drawer open={openAllFiltersDrawer} setOpen={setOpenAllFiltersDrawer}>
              {
                <AllFiltersDrawer
                  {...filterProps}
                  onSuccess={closeAllFiltersDrawer}
                  onUpdateFilter={onUpdateFilter}
                  feeScheduleIds={uniqueFeeScheduleIds}
                  statusOptionsConstant={statusOptionsConstant}
                  underDispute={disputed}
                  archived={archived}
                  showPayerInvoices={showPayerInvoices}
                  path={path}
                  payerWQInteractiveView={payerWQInteractiveView}
                  showInvoiceType={showInvoiceType}
                  invoiceTypes={invoiceTypes}
                />
              }
            </Drawer>
          )
        }
      </div>
    </div>
  );
};

InvoiceWorkqueue.propTypes = {
  archived: PropTypes.bool,
  bulkActions: PropTypes.array,
  children: PropTypes.node,
  disputed: PropTypes.bool,
  invoiceStatus: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  renderNoInvoicesFound: PropTypes.func.isRequired,
  showInvoiceAging: PropTypes.bool,
  showPayerInvoices: PropTypes.bool,
  showStatusFilter: PropTypes.bool,
  statusOptionsConstant: PropTypes.string,
};

InvoiceWorkqueue.defaultProps = {
  archived: false,
  bulkActions: [],
  disputed: false,
  showStatusFilter: false,
  showInvoiceAging: false,
  statusOptionsConstant: null,
  showPayerInvoices: false,
  children: undefined,
};

function mapStateToProps(state) {
  return {
    showPayerInvoices: hasPayerInvoicesRole(state),
  };
}

export default flow(
  FeatureFlagContainer,
  connect(mapStateToProps),
)(InvoiceWorkqueue);
