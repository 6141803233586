import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { validations } from '@unite-us/app-components';
import {
  DateField,
  InputField,
  SelectField,
} from '@unite-us/ui';
import CurrencyField from 'common/form/CurrencyField';

const PaymentsTrackServiceFundsDistributed = ({
  distributionReasons,
  fields,
  paymentMethods,
  registerField,
}) => (
  <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8">
    <div className="lg:col-span-1 px-0">
      <CurrencyField
        field={fields.provided_service.unit_amount}
        id="provided-service-unit-amount"
        label="Amount Distributed ($)"
        required
        ref={registerField}
        validations={validations.isRequired}
      />
    </div>
    <div className="lg:col-span-1 mb-4 lg:mb-0 px-0">
      <div className="ui-input-field ui-form-field">
        <label htmlFor="provided-service-unit" className="ui-form-field__label">Unit</label>
        <div className="pointer-events-none opacity-75 pt-1 leading-8" id="provided-service-unit">
          {capitalize(fields.provided_service.unit.value)}
        </div>
      </div>
    </div>
    <div className="lg:col-span-1 px-0">
      <SelectField
        id="payment-method-select"
        label="Distribution Method"
        options={paymentMethods}
        valueKey="value"
        labelKey="field"
        ref={registerField}
        field={fields.provided_service.payment_method}
        required
        validations={validations.isRequired}
      />
    </div>
    <div className="lg:col-span-1 px-0">
      <SelectField
        id="distribution-reason-select"
        label="Reason for Distribution"
        options={distributionReasons}
        valueKey="value"
        labelKey="field"
        ref={registerField}
        field={fields.provided_service.distribution_reason}
        required
        validations={validations.isRequired}
      />
    </div>
    <div className="lg:col-span-1 px-0">
      <InputField
        field={fields.provided_service.distribution_id}
        id="provided-service-distribution-id"
        label="Payment Distribution ID"
        ref={registerField}
        type="text"
      />
    </div>
    <div className="lg:col-span-1 px-0">
      <DateField
        id="provided-service-date"
        label="Date of Distribution"
        field={fields.provided_service.service_start_date}
        ref={registerField}
        required
        validations={validations.isRequired}
      />
    </div>
    <div className="lg:col-span-1 px-0">
      <InputField
        field={fields.provided_service.payee}
        id="provided-service-payee"
        label="Payee Name"
        ref={registerField}
        type="text"
      />
    </div>
  </div>
);

PaymentsTrackServiceFundsDistributed.propTypes = {
  distributionReasons: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  registerField: PropTypes.func.isRequired,
};

export default PaymentsTrackServiceFundsDistributed;
