import {
  SEARCH_NETWORK_GROUPS,
  SEARCH_NETWORK_GROUPS_BY_REFERRAL_SCOPE,
  SET_NETWORK_GROUPS_FETCHING,
  UNSET_NETWORK_GROUPS_FETCHING,
} from 'actions';
import { serviceTypes as serviceTypeUtils } from '@unite-us/client-utils';
import Notifier from 'common/helpers/Notifier';
import { hasPaymentsUserAccess, includePathwaysServices } from 'common/utils/FeatureFlags/flags';
import { addAdditionalProviderAttributes } from 'src/actions/Group/Network/searchProviderUtils';
import fetchProviderBrowseResultsFromSearch from './fetchProviderBrowseResultsFromSearch';

const getPageFilter = (params) => ({
  page: {
    number: params.page || 1,
    size: 50 || params.per,
  },
  sort: 'name',
});

const onError = (error, errorMessage, dispatch) => {
  Notifier.handleErrors({
    ...error,
    response: {
      ...error.response,
      data: {
        ...error.response.data,
        errors: errorMessage || error.response.data.errors,
      },
    },
  }, dispatch);
  return error;
};

const searchMyNetworkProviders = ({
  referredByNetworkId,
  options = {},
  serviceTypes,
  errorMessage,
  isOON,
  showInAndOON = false,
  hint716SearchNetworkHubSupportPremium,
} = {}) => (
  async (dispatch, getState) => {
    try {
      const state = getState();
      const usePaymentsUserRole = hasPaymentsUserAccess(state);
      const includePathways = includePathwaysServices(state);
      const page = getPageFilter(options);
      const licensed = !isOON;
      const query = options.q.name ? options.q.name : null;
      const networksFilter = options.q.permitted_networks.length ?
        options.q.permitted_networks.join(',') : referredByNetworkId;

      const params = {
        ...(networksFilter ? { networks: networksFilter } : null),
        ...(options.q.service_type_ids ? { 'programs.services': options.q.service_type_ids.join(',') } : null),
        ...(
          showInAndOON ? {} : {
            licensed,
          }
        ),
        ...(includePathways && { include_pathways: true }),
        ...(usePaymentsUserRole && { include_payments: true }),
        query,
      };

      dispatch({
        type: SET_NETWORK_GROUPS_FETCHING,
      });

      const payload = await fetchProviderBrowseResultsFromSearch(
        {
          ...params,
          ...(hint716SearchNetworkHubSupportPremium && { type: 'all' }),
        },
        page,
        hint716SearchNetworkHubSupportPremium ? { 'filter[provider.type]': 'all' } : {},
      );

      const composedPayload = JSON.parse(JSON.stringify(payload));
      addAdditionalProviderAttributes(composedPayload.data.data);

      composedPayload.data = {
        ...composedPayload.data,
        data: serviceTypeUtils.composeGroupsList(composedPayload.data.data, serviceTypes),
      };

      dispatch({
        type: SEARCH_NETWORK_GROUPS,
        payload: composedPayload,
        searchParams: params,
      });

      dispatch({
        type: SEARCH_NETWORK_GROUPS_BY_REFERRAL_SCOPE,
        payload: composedPayload,
        searchParams: params,
      });

      dispatch({ type: UNSET_NETWORK_GROUPS_FETCHING });

      return composedPayload;
    } catch (error) {
      return onError(error, errorMessage, dispatch);
    }
  }
);

export default searchMyNetworkProviders;
