import React from 'react';
import { PropTypes } from 'prop-types';
import BackButton from './BackButton';
import RejectEnrollmentRequestButton from '../RejectEnrollmentRequestButton';
import AcceptEnrollmentRequestButton from '../AcceptEnrollmentRequestButton';

const ActionBar = ({ enrollmentRequest }) => (
  <div className="sticky top-0 z-50 flex px-4 py-4 bg-white border-b border-solid border-dark-border-blue">
    <div className="flex-grow">
      <BackButton />
    </div>
    <div className="flex flex-row space-x-2">
      <RejectEnrollmentRequestButton enrollmentRequest={enrollmentRequest} />
      <AcceptEnrollmentRequestButton
        enrollmentRequest={enrollmentRequest}
        fullViewPage
      />
    </div>
  </div>
);

ActionBar.propTypes = {
  enrollmentRequest: PropTypes.object.isRequired,
};

export default ActionBar;
