import { find, some } from 'lodash';

export default function getActiveStep(path = '', steps = []) {
  const activeStep = find(steps, (step) => {
    if (step.routeRegex) {
      return step.routeRegex.test(path);
    }
    return some(step.routes, ['route', path]);
  });
  return activeStep;
}
