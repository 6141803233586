import { useContext, useEffect } from 'react';
import { TrackerContext } from '@unite-us/client-utils';

const useTrackPageView = (pageName) => {
  if (!pageName) {
    if (window.Rollbar) {
      window.Rollbar.error(new Error('page name is undefined when tracking page view'));
    }
    // eslint-disable-next-line no-console
    console.error('page name is undefined');
  }
  const eventTracker = useContext(TrackerContext);
  useEffect(() => eventTracker(pageName), []);
};

export default useTrackPageView;
