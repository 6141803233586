import React, { useState } from 'react';
import $scripts from 'scriptjs';
import { LocationAddressField } from '@unite-us/client-utils';
import {
  Button,
} from '@unite-us/ui';
import { Form } from 'react-final-form';
import { requiredMapScript } from 'src/components/Browse/Map/utils';
import Fields from 'src/components/Organization/components/Fields';
import { Spinner } from 'src/common/spinners';
import PropTypes from 'prop-types';
import './LatitudeLongitudeField.css';

/**
 * Latitude and longitude field
 *
 */
const LatitudeLongitudeField = ({ input, meta, changeValue }) => {
  const { value } = input;
  const { error, touched } = meta;
  const [latitude, setLatitude] = useState(value.lat);
  const [longitude, setLongitude] = useState(value.lng);
  const hasError = error && touched;
  const hasLatAndLng = latitude && longitude;
  const displayText = hasLatAndLng ? `${latitude}, ${longitude} ` : 'No location set.';
  const googleMapsPreview = hasLatAndLng && `https://www.google.com/maps?q=${latitude},${longitude}&z=15`;

  const [isEditing, setIsEditing] = useState(false);
  const [scriptsLoaded, setScriptsLoaded] = useState(window.google && window.google.maps);
  // Let's check to make sure that the google maps script is loaded
  if (!(window.google && window.google.maps)) {
    $scripts(requiredMapScript.scripts, () => {
      setScriptsLoaded(true);
    });
  }

  if (!scriptsLoaded) {
    return (
      <div>
        <label
          htmlFor="directory-detail__latitude_and_longitude"
          className={`ui-form-field__label ui-form-field__label--required ${hasError ? 'text-red' : ''}`}
        >
          Default Location Coordinates
        </label>
        <Spinner center />
      </div>
    );
  }

  const onSubmit = (values) => {
    if ((values && values.latitude)) {
      changeValue('latitude_and_longitude', {
        lat: values.latitude,
        lng: values.longitude,
      });
      setLatitude(values.latitude);
      setLongitude(values.longitude);
      setIsEditing(false);
    }
  };

  return (
    <div>
      <div>
        <label
          htmlFor="directory-detail__latitude_and_longitude"
          className={`ui-form-field__label ui-form-field__label--required${hasError ? ' text-red' : ''}`}
        >
          Default Location Coordinates
        </label>
        <div>
          <div className={`pt-2 pb-1 px-1${hasError ? ' text-red' : ''}`}>
            {displayText}
            {hasLatAndLng && <a href={googleMapsPreview} target="_blank" rel="noopener noreferrer">Preview</a>}
          </div>
        </div>
      </div>
      <div>
        {!isEditing ? (
          <Button
            data-test-element={'edit-location-coordinates-button'}
            label="Edit Location"
            className={`${hasError ? 'border-red text-red' : ''}`}
            onClick={() => setIsEditing(true)}
            type="button"
          />
        ) : (
          <div>
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form
                  onSubmit={handleSubmit}
                  className="grid grid-cols-1 gap-x-6 md:grid-cols-12"
                >
                  <div className="md:col-span-8 xl:col-span-9 px-0">
                    <Fields
                      names={[
                        'line_1',
                        'city',
                        'county',
                        'state',
                        'postal_code',
                        'country',
                        'latitude',
                        'longitude',
                      ]}
                      data-test-element="address"
                    >
                      {(props) => (
                        <LocationAddressField
                          id="directory-detail__latitude_and_longitude"
                          data-test-element="prd-location-address-field"
                          cityRequiredMessage={null}
                          className={`px-0${hasError ? ' location_error' : ''}`}
                          label=""
                          placeholder="Enter location address"
                          required={false}
                          google={window.google}
                          fieldNamePath={null}
                          {...props}
                        />
                      )}
                    </Fields>
                  </div>
                  <Button
                    data-test-element="set-coord-btn"
                    className={
                      'ui-button ui-button md:col-span-4 xl:col-span-3 px-0 mb-6 mt-1' +
                      `${hasError ? ' border-red text-red' : ''}`
                    }
                    label="Set Coordinates"
                    type="submit"
                  />
                </form>
                )}
            />
          </div>
        )}
      </div>
      {hasError && (
        <span className={`ui-form-field__error visible row ml-1${isEditing ? ' -mt-6' : ' mt-1'}`}>
          {error}
        </span>
      )}
    </div>
  );
};

  LatitudeLongitudeField.propTypes = {
    input: PropTypes.object.isRequired,
    changeValue: PropTypes.func.isRequired,
    meta: PropTypes.object.isRequired,
};

export default LatitudeLongitudeField;
