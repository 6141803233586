import { compact, filter, find, get, includes, map } from 'lodash';
import { getDeterminantNetworkId } from 'src/components/Referrals/utils/form';
import {
  MIX_CC_PROVIDER_ERROR_MESSAGE,
  MULTIPLE_CC_RECIPIENT_ERROR_MESSAGE,
} from '../../constants';

function getInvalidSelectedGroupsErrorMessages({
  networkId,
  networks,
  selectedGroupIds = [],
}) {
  const network = find(networks, { id: networkId }) || {};
  const { ccIds } = network;

  const selectedCCsCount = (
    filter([...selectedGroupIds], (id) => includes(ccIds, id)) || []
  ).length;

  const selectedProvidersCount = selectedGroupIds.length - selectedCCsCount;
  return compact([
    selectedCCsCount > 0 && selectedProvidersCount > 0 &&
    MIX_CC_PROVIDER_ERROR_MESSAGE,
    selectedCCsCount > 1 &&
    MULTIPLE_CC_RECIPIENT_ERROR_MESSAGE,
  ]);
}

function getInvalidSelectedGroupsErrors({ networks = [], service = {} }) {
  const isOONCase = get(service, 'isOONCase.checked', false);
  if (isOONCase) {
    return [];
  }
  return getInvalidSelectedGroupsErrorMessages({
    networkId: getDeterminantNetworkId(service),
    networks,
    selectedGroupIds: compact(map(service.selected, (selected) => get(selected, 'group.value.id', ''))),
  });
}

export default getInvalidSelectedGroupsErrors;
