import { get } from 'lodash';
import { useState, useEffect } from 'react';
import * as env from 'src/config/env/env.config';
import {
  useFind,
  useFindRecord,
  useInvalidateQueries,
  usePopulate,
  usePopulateMemo,
  useUpdateRecord,
} from 'src/api/APIHooks';
import { usePendingNotificationCount } from 'src/components/Organization/utils/usePendingNotifications';
import { defaultQueryConfig, defaultMutationConfig } from './apiHookOptions';

export const useFindEmployeeNotifications = (employeeId, taskUser) => {
  const { pendingNotificationCount, decrementPendingNotificationCount } = usePendingNotificationCount();
  const [notifications, setNotifications] = useState([]);
  const query = useFind(
    'employee_notifications',
    {
      employee: employeeId,
    },
    {
      queryConfig: {
        refetchInterval: !taskUser && pendingNotificationCount === 0 ? 300000 : 7000,
        placeholderData: undefined,
        ...defaultQueryConfig,
      },
      enabled: !!employeeId,
    },
  );

  useEffect(() => {
    if (query.data) {
      const newExportNotifications = query.data.filter((newNotif) => (
        newNotif.key.includes('export') &&
        !notifications.some((oldNotif) => oldNotif.id === newNotif.id)
      ));

      const newTaskNotifications = query.data.filter((newNotif) => (
        newNotif.key.includes('Task') &&
        !notifications.some((oldNotif) => oldNotif.id === newNotif.id)
      ));
      setNotifications(query.data);
      decrementPendingNotificationCount('task', newTaskNotifications.length);
      decrementPendingNotificationCount('export', newExportNotifications.length);
    }
  }, [query.data]);

  return query;
};

export const useFindExportAndDownload = (exportId) => {
  const exportsQuery = useFindRecord(
    'invoice_export',
    exportId,
    {
      queryConfig: {
        placeholderData: undefined,
        enabled: !!exportId,
        staleTime: Infinity,
      },
    },
  );
  const notificationExports = get(exportsQuery, 'data.data.data', []);
  const fileUploadQuery = usePopulate(
    'file_uploads',
    'file_upload',
    notificationExports,
    {
      queryConfig: {
        placeholderData: undefined,
        staleTime: Infinity,
      },
    },
  );
  const fileUploadPath = get(notificationExports, 'file_uploads[0].path');

  usePopulateMemo(exportsQuery, [fileUploadQuery]);

  useEffect(() => {
    if (fileUploadPath) {
      const a = document.createElement('a');

      a.href = `${env.CORE_BASE_URL}${notificationExports.file_uploads[0].path}`;
      a.click();
    }
  }, [fileUploadPath]);

  return exportsQuery;
};

export const useUpdateViewed = () => {
  const invalidateQueries = useInvalidateQueries();

  return useUpdateRecord('employee_notification', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('employee_notifications');
      },
      ...defaultMutationConfig,
    },
  });
};
