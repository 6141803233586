import { intelClient } from 'src/api/config';

function fetchDownloadType({ params, type }) {
  return intelClient.post('/api/insights/download', params, {
    responseType: 'arraybuffer',
    params: {
      type,
    },
  });
}

export default fetchDownloadType;
