/* eslint-disable max-len */
import React from 'react';

const NotEligible = () => (
  <div>
    <div className="my-5 align-middle inline-block mr-2">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 8C16 3.6129 12.3871 0 8 0C3.58065 0 0 3.6129 0 8C0 12.4194 3.58065 16 8 16C12.3871 16 16 12.4194 16 8ZM8 9.6129C8.80645 9.6129 9.48387 10.2903 9.48387 11.0968C9.48387 11.9355 8.80645 12.5806 8 12.5806C7.16129 12.5806 6.51613 11.9355 6.51613 11.0968C6.51613 10.2903 7.16129 9.6129 8 9.6129ZM6.58065 4.29032C6.54839 4.06452 6.74194 3.87097 6.96774 3.87097H9C9.22581 3.87097 9.41936 4.06452 9.3871 4.29032L9.16129 8.67742C9.12903 8.90323 8.96774 9.03226 8.77419 9.03226H7.19355C7 9.03226 6.83871 8.90323 6.80645 8.67742L6.58065 4.29032Z"
          fill="#182A44"
        />
      </svg>
    </div>
    <div className="font-bold italic align-middle inline-block">
      <p>The client is not eligible for enhanced services per NY1115 Waiver guidelines.</p>
      <p>Submit the assessment and direct the client to existing programs in your network.</p>
    </div>
  </div>
);

export default NotEligible;
