export const PERMISSION_CATEGORIES = {
    UU_MEDICAL_HEALTH: 'UU-MEDICAL-HEALTH',
    UU_MENTAL_BEHAVIORAL_HEALTH: 'UU-MENTAL-BEHAVIORAL-HEALTH',
    UU_PAYMENTS: 'UU-PAYMENTS',
    UU_SUBSTANCE_USE: 'UU-SUBSTANCE-USE',
    UU_LEGAL: 'UU-LEGAL',
    UU_HEALTH: 'UU-HEALTH',
    REGULAR: 'REGULAR',
};

export const getProgramPermissions = ({ serviceTypes, selectedServices = [] }) => {
    // iterate over all services and map ids to Codes and Codes to ids, for efficient processing later
    //
    // idToCode: {
    //  0a73f953-8467-47dd-a395-9dfcf0b2287d: "UU-SUBSTANCE-USE"
    //  0bb07cf2-64b1-4993-8382-b20d496aeefc: "REGULAR"
    //  0ce91da6-2447-4a09-9ed0-299cb2c1e0ba: "UU-MENTAL-BEHAVIORAL-HEALTH"
    //  0ead417f-13b4-438d-a433-8c88648895e8: "UU-LEGAL"
    //  0f14e6a9-51bf-4d10-8916-4419e4b33120: "REGULAR"
    //  0ffd2454-2925-4795-834a-13b227d92028: "REGULAR"
    // ... }
    //
    // codeToId: {
    //  REGULAR: ['50126fcb-a3e9-45d5-9a8e-c32b7fd810b4', '2ac1bb8f-89a1-44b9-9e24-9af3cec8a2de', …]
    //  UU-LEGAL: ['40ee374f-2915-46f6-b8ba-ca73d19525ae', '0ead417f-13b4-438d-a433-8c88648895e8', ...]
    //  UU-MEDICAL-HEALTH:  ['ddb3759d-0829-454f-98dc-b131c956b369', 'e6c0b729-0515-4fe9-98e5-8b28b851d6bb', ...]
    //  UU-MENTAL-BEHAVIORAL-HEALTH: ['5c1ab598-0402-4fc1-8550-aa5d3cc48ea0', ...]
    //  UU-SUBSTANCE-USE: ['044d5f79-ea1e-4d4c-930c-b34072f3039a', ...]
    // }
    const serviceCodeMappings = Object.keys(serviceTypes).reduce((currentMapping, nextKey) => {
        const codeToId = { ...currentMapping.codeToId };
        const idToCode = { ...currentMapping.idToCode };
        const nextService = serviceTypes[nextKey];

        // if the code for service does not exist in codeToId, then it is considered Regular unless it is sensitive
        let serviceCategory = 'NO-CATEGORY';
        if (nextService.code in codeToId) {
            serviceCategory = nextService.code;
        } else if (!nextService.sensitive) {
            serviceCategory = PERMISSION_CATEGORIES.REGULAR;
        }

        // push parent level service in relevant codeToId mapping
        if (serviceCategory in codeToId) {
            codeToId[serviceCategory].push(nextKey);
        } else {
            codeToId[serviceCategory] = [nextKey];
        }

        // push parent level service in relevant idToCode mapping
        idToCode[nextKey] = serviceCategory;

        // now we push any child servcies to both mappings
        for (const child of nextService.children) {
            codeToId[serviceCategory].push(child.id);
            idToCode[child.id] = serviceCategory;
        }
        return { codeToId, idToCode };
    }, {
        //   initialize codeToId to
        //     {
        //         'UU-MEDICAL-HEALTH': [],
        //         'UU-MENTAL-BEHAVIORAL-HEALTH': [],
        //         'UU-SUBSTANCE-USE': [],
        //         'UU-LEGAL': [],
        //         'REGULAR' : []
        //     }
        codeToId: Object.values(PERMISSION_CATEGORIES).reduce((curr, category) => ({ ...curr, [category]: [] }), {}),
        idToCode: {},
    });

    const { codeToId, idToCode } = serviceCodeMappings;

    let selectedPermissions = [];
    const currentPermissionSelections = Object.values(PERMISSION_CATEGORIES)
        .reduce((curr, category) => ({ ...curr, [category]: false }), {});

    const updatePermissions = ({ serviceCode }) => {
        selectedPermissions = [...selectedPermissions, ...codeToId[serviceCode]];
        currentPermissionSelections[serviceCode] = true;
    };

    selectedServices.forEach((serviceId) => {
        if (serviceId) {
            const serviceCode = idToCode[serviceId];
             // If user selects Legal, program gets viewing permissions for Legal services.
            if (serviceCode === PERMISSION_CATEGORIES.UU_LEGAL &&
                !currentPermissionSelections[PERMISSION_CATEGORIES.UU_LEGAL]) {
                updatePermissions({ serviceCode });
            }

            // If user selects Substance Use, program gets viewing permissions for all Substance Use services
            if (serviceCode === PERMISSION_CATEGORIES.UU_SUBSTANCE_USE &&
                !currentPermissionSelections[PERMISSION_CATEGORIES.UU_SUBSTANCE_USE]) {
                updatePermissions({ serviceCode });
            }

            // If user selects Mental Health, program gets viewing permissions for all Mental Health services
            if (serviceCode === PERMISSION_CATEGORIES.UU_MENTAL_BEHAVIORAL_HEALTH &&
                !currentPermissionSelections[PERMISSION_CATEGORIES.UU_MENTAL_BEHAVIORAL_HEALTH]) {
                updatePermissions({ serviceCode });
            }

             // If legacy program has deprecated UU-HEALTH, program gets viewing permissions for HEALTH
             if (serviceCode === PERMISSION_CATEGORIES.UU_HEALTH &&
                !currentPermissionSelections[PERMISSION_CATEGORIES.UU_HEALTH]) {
                updatePermissions({ serviceCode });
            }

             // If a payments service type is selected, program gets viewing permissions
             if (serviceCode === PERMISSION_CATEGORIES.UU_PAYMENTS &&
                !currentPermissionSelections[PERMISSION_CATEGORIES.UU_PAYMENTS]) {
                updatePermissions({ serviceCode });
            }

            // If user selects Physical Health, Mental Health or Substance Use, program gets viewing permissions
            // for all Physical Health and all regular services
            if ((serviceCode === PERMISSION_CATEGORIES.UU_MEDICAL_HEALTH ||
                currentPermissionSelections[PERMISSION_CATEGORIES.UU_MENTAL_BEHAVIORAL_HEALTH] ||
                currentPermissionSelections[PERMISSION_CATEGORIES.UU_SUBSTANCE_USE]) &&
                !currentPermissionSelections[PERMISSION_CATEGORIES.UU_MEDICAL_HEALTH]) {
                updatePermissions({ serviceCode: PERMISSION_CATEGORIES.UU_MEDICAL_HEALTH });
            }

            // When a user selects a regular service type for a program, or any other service type
            // they get permissions for all regular services
            if ((serviceCode === PERMISSION_CATEGORIES.REGULAR || selectedPermissions.length) &&
                !currentPermissionSelections[PERMISSION_CATEGORIES.REGULAR]) {
                updatePermissions({ serviceCode: PERMISSION_CATEGORIES.REGULAR });
            }
        }
    });

    return selectedPermissions;
};
