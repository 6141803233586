import { every, get } from 'lodash';
import { isReferralsUserAndAbove } from 'src/components/User/utils';

export const canArchive = ({
  user,
  referral,
  groupId,
}) => {
  const {
    case: {
      state: caseState,
    },
    state: referralState,
  } = referral;

  const checks = [
    isReferralsUserAndAbove(user, groupId),
    get(referral, 'case.provider.id') === groupId,
    ['recalled', 'declined'].includes(referralState),
    ['referred'].includes(caseState),
    referral.flagged_for_sender === true,
  ];

  return every(checks);
};

export default function canClose({
  user,
  referral,
  groupId,
  isCC,
  closeInboundReferralsEnabled,
}) {
  const {
    case: {
      state: caseState,
      outcome: caseOutcome,
    },
    state: referralState,
  } = referral;

  let checks;

  const isReferralReceiver = get(referral, 'receiving_provider.id') === groupId;

  if (isCC && closeInboundReferralsEnabled && isReferralReceiver) {
    checks = [
      isReferralsUserAndAbove(user, groupId),
      ['sent', 'in_review'].includes(referralState),
      ['referred'].includes(caseState),
    ];
  } else {
    checks = [
      isReferralsUserAndAbove(user, groupId),
      caseOutcome === null,
      get(referral, 'case.provider.id') === groupId,
      ['recalled', 'declined', 'draft', 'denied_authorization'].includes(referralState),
      ['deferred', 'draft'].includes(caseState),
    ];
  }

  return every(checks);
}
