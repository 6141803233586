import { FETCH_ASSESSMENT } from 'actions';
import Notifier from 'common/helpers/Notifier';
import { coreApi } from 'src/api/config';

export default function fetchAssessment(groupsId, caseId, id) {
  return async (dispatch) => {
    try {
      const payload = await coreApi.findRecord('form', id);
      dispatch({
        type: FETCH_ASSESSMENT,
        payload,
      });
      return payload;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}
