import Notifier from 'common/helpers/Notifier';
import convertCoreUser from 'src/api/utils/convertCoreUser';
import { FETCH_EMPLOYEES_ERROR, FETCH_GROUPS_USER } from 'src/actions';
import { coreApi } from 'src/api/config';
import { isEmpty } from 'lodash';
import { getRoles } from './utils';

const onError = (dispatch, error) => {
  dispatch({ type: FETCH_EMPLOYEES_ERROR });
  Notifier.handleErrors(error);
  return error;
};

const onSuccessV2 = async (dispatch, employee, roles) => {
  const userObject = convertCoreUser(employee);
  if (!isEmpty(employee.programs)) {
    const response = await coreApi.query('program', { ids: employee.programs.map((p) => p.id) });
    userObject.programs = response.data.data;
  }
  if (!isEmpty(employee.roles)) {
    userObject.roles = getRoles(roles, employee.roles);
  }
  dispatch({
    type: FETCH_GROUPS_USER,
    payload: {
      data: {
        data: userObject,
      },
    },
  });
  return userObject;
};

export default function fetchUser({ employeeId }) {
  return async (dispatch, getState) => {
    try {
      const response = await coreApi.findRecord('employee', employeeId);
      const employee = response.data.data;
      const { globalState: { roles } } = getState();
      return onSuccessV2(dispatch, employee, roles);
    } catch (error) {
      return onError(dispatch, error);
    }
  };
}
