import { hasUserRole } from '.';

export function isOversightAdmin(user, groupId) {
  return hasUserRole({
    groupId,
    name: 'Oversight',
    roleType: 'primary',
    user,
  });
}

export default isOversightAdmin;
