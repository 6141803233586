import { isEmpty, get } from 'lodash';
import { getFieldValue } from '.';

function hasValidSelectedNetworks(fields) {
  const referToOutsideNetwork = getFieldValue(fields.refer_to_outside_network);
  const referredByNetwork = getFieldValue(fields.referred_by_network);
  const referredToNetwork = getFieldValue(fields.referred_to_network);

  const referredByNetworkId = get(referredByNetwork, 'id', '');
  const referredToNetworkId = get(referredToNetwork, 'id', '');

  return referToOutsideNetwork ?
    !isEmpty(referredByNetworkId) && !isEmpty(referredToNetworkId) :
    !isEmpty(referredByNetworkId);
}

export default hasValidSelectedNetworks;
