/* eslint-disable no-irregular-whitespace */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const ParticipationAgreement = ({ group }) => (
  <div className="participation-agreement">
    <header>
      <h2 className="participation-agreement__header ui-h2-heavy">NETWORK PARTICIPATION TERMS AND CONDITIONS</h2>
      <h3 className="participation-agreement__header">{group.name}</h3>
    </header>

    <div className="participation-agreement__content">
      <section className="participation-agreement__content-section">
        <p>
          By agreeing to these Network Participation Terms and Conditions (<span className="defined-term">&ldquo;​​Network Terms​&rdquo;​</span>), you agree to participate in a network (the <span className="defined-term">&ldquo;​​Network​&rdquo;​</span>) of health and social service organizations (<span className="defined-term">&ldquo;​​Network Participants​&rdquo;</span>​) that connects clients with services using Unite USA Inc.&apos;​s (<span className="defined-term">&ldquo;​​Unite Us​&rdquo;​</span>) proprietary care coordination and case management software platform (the <span className="defined-term">&ldquo;​​Unite Us Platform​&rdquo;​</span>). By scrolling to the bottom and clicking the &ldquo;​I Accept&rdquo;​ button, you agree to these Network Terms.
        </p>
        <p>
          If you are accepting the Network Terms on behalf of another person or company or other legal entity, you represent and warrant that you have full authority to bind that person, company or legal entity to these Network Terms.
        </p>
        <p>
          These Network Terms may be modified by Unite Us at any time with or without notice. Any such modification will be effective immediately upon public posting. Your continued use of the Unite Us Platform following any such modification constitutes your acceptance of the modified terms.
        </p>
      </section>

      <section className="participation-agreement__content-section">
        <h3 className="participation-agreement__content-section-header">
          NETWORK PARTICIPANT RESPONSIBILITIES
        </h3>

        <section className="participation-agreement__content-section-sub">
          <h4>Network Participant Profile​.&nbsp;</h4>
          <span>
            ​In order for the Network to function optimally, Network Participant should make a reasonable effort to keep an up-to-date profile within the Unite Us Platform. Network Participant shall regularly update the available programs hosted by such Network Participant, eligibility for such programs and appropriate contact information for processing of assistance requests and referrals. Network Participant shall immediately terminate access to the Unite Us Platform of any Authorized User who is no longer associated with or employed by such Network Participant or shall contact Unite Us to terminate such access.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Assistance Requests and Referrals​.&nbsp;</h4>
          <span>
            ​Network Participant has no obligation to service a request for assistance or referral, but if such assistance request or referral is unserviceable by Network Participant, Network Participant must make a commercially reasonable effort to refer such assistance request or referral to an alternate Network Participant in a timely manner. Network Participant agrees to review and process all assistance requests and referrals within a commercially reasonable time appropriate for such assistance request or referral. Following acceptance of a referral or assistance request, Network Participant agrees to contact and begin working with the client within a commercially reasonable time appropriate for such assistance request or referral. Upon processing an assistance request or referral, a Network Participant will document the service outcome of the associated case within the Unite Us Platform. In the event that Network Participant creates an out-of-Network case, Network Participant shall use commercially reasonable efforts to provide status updates, outcomes and other relevant information regarding such out-of-Network case on the Unite Us Platform.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Offline Interactions​.&nbsp;</h4>
          <span>
            Network Participants should make whatever investigation necessary or appropriate before proceeding with any offline interaction with any other Network Participant. Network Participant agrees that Unite Us shall not be responsible or liable for any loss or damage of any sort incurred as a result of or in connection with any such dealings.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>HIPAA Compliance​.&nbsp;</h4>
          <span>
            Depending on the services it provides, a Network Participant may be considered a &ldquo;​business associate&rdquo;​ that creates, receives, maintains or transmits protected health information in the course of performing services and may be subject to HIPAA. It is the Network Participant&apos;​s responsibility to understand its obligations with respect to HIPAA (if applicable) and maintain the security and privacy of protected health information. Depending on the services provided by the Network Participant, it may be required to enter into a Business Associate Agreement with Unite Us.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Hardware and Connectivity​.&nbsp;</h4>
          <span>
            Network Participant shall be solely responsible for all hardware and Internet connectivity required to access the Network and shall use supported Internet browsers to access the Unite Us Platform.
          </span>
        </section>
      </section>

      <section className="participation-agreement__content-section">
        <h3 className="participation-agreement__content-section-header">
          UNITE US RESPONSIBILITIES
        </h3>

        <section className="participation-agreement__content-section-sub">
          <h4>Training and Support​.&nbsp;</h4>
          <span>
            Unite Us shall maintain the Unite Us Platform and provide technical and advisory training and support to Network Participant in connection with its use of the Unite Us Platform. Unite Us shall determine the scope and frequency of such training and support and will communicate to each Network Participant the support available during onboarding and thereafter.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Compliance with Laws and Regulations​.&nbsp;</h4>
          <span>
            Without limiting any other provision of these Network Terms relating to the parties&apos;​ compliance with applicable laws and regulations, Unite Us shall perform in all respects as contemplated by these Network Terms in compliance with applicable federal, state, and local laws, ordinances and regulations and shall maintain the privacy of all of the data on the Unite Us Platform in accordance with its privacy notice.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Malicious Software, Viruses, and Other Threats.&nbsp;</h4>
          <span>
            Unite Us shall use reasonable efforts to ensure that its provision of the Unite Us Platform does not include, and that any method of transmitting data will not introduce, any program, routine, subroutine, or data (including without limitation malicious software or &ldquo;​malware,&rdquo;​ viruses, worms, and &ldquo;​Trojan Horses&rdquo;​) which will disrupt the proper operation of Unite Us or any Network Participant or which, upon the occurrence of a certain event, the passage of time, or the taking of or failure to take any action will cause the Unite Us Platform or any hardware, software or data used by Unite Us or any other Network Participant to be destroyed, damaged, or rendered inoperable.
          </span>
        </section>
      </section>

      <section className="participation-agreement__content-section">
        <h3 className="participation-agreement__content-section-header">
          LICENSE TO THE UNITE US PLATFORM
        </h3>

        <section className="participation-agreement__content-section-sub">
          <h4>Unite Us Intellectual Property Rights​.&nbsp;</h4>
          <span>
            As between Unite Us and Network Participant or any individual authorized by Network Participant to access the Unite Us Platform (each such individual, an &ldquo;​​Authorized User​&rdquo;​), Unite Us and its licensors own and shall retain all intellectual property rights in and to the Unite Us Platform and any services, materials, documentation and infrastructure used to provide the Unite Us Platform to Network Participant. If Network Participant provides any feedback to Unite Us concerning the functionality and performance of the Unite Us Platform (including identifying potential errors or improvements), Network Participant hereby assigns to Unite Us all right, title and interest in and to the feedback and Unite Us is free to use such feedback without payment or restriction.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>License to Network Participant​.&nbsp;</h4>
          <span>
            Unite Us hereby grants to Network Participant a non-exclusive, non-transferable license to (a) access and use the Unite Us Platform for the benefit of Network Participant; (b) reproduce, distribute and display the documentation provided by Unite Us solely to its Authorized Users; and (c) use and access any Network Participant Data as necessary for the care and treatment of individuals seeking treatment or services from Network Participant in compliance with HIPAA and other applicable privacy laws. For purposes of these Network Terms, <span className="defined-term">&ldquo;​​Network Participant Data​&rdquo;</span>​ means information (including, without limitation, PII (as defined below)) provided to Network Participant by or at the direction of a client or information Network Participant requires to provide and document services to such client within the Unite Us Platform in the course of Network Participant&apos;​s use of the Network.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Authorized Users​.&nbsp;</h4>
          <span>
            Network Participant shall be responsible for the acts or omissions of any person who accesses the Unite Us Platform using passwords or access procedures provided to or created by Network Participant or its Authorized Users. Unite Us reserves the right to refuse registration of, or to cancel, login IDs that violate these Network Terms. Network Participant agrees to notify Unite Us immediately upon learning of any unauthorized use of Network Participant&apos;​s or any of its Authorized Users&apos;​ accounts. Each Authorized User accessing the Unite Us Platform will enter electronically into an end-user license agreement governing the access to, use of, and all rights and obligations of the end-user relating to the Unite Us Platform.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Restrictions​.&nbsp;</h4>
          <span>
            Network Participant may not and may not permit third parties to (a) sell, assign, sublicense or otherwise transfer the Unite Us Platform to third parties; (b) resell the Unite Us Platform to any third party; (c) use the Unite Us Platform to provide or perform service bureau processing, or hosting services for any third party; (d) otherwise use the Unite Us Platform for the benefit of any third party; (e) disassemble, decompile, reverse engineer or use any other means to attempt to discover any source code of the Unite Us Platform, or the underlying ideas, algorithms or trade secrets therein; (f) use the Unite Us Platform to knowingly transmit malware, spam or other unsolicited emails in violation of applicable law, or to post or send any unlawful, threatening, harassing, racist, abusive, libelous, pornographic, defamatory, obscene, or other similarly inappropriate content; (g) remove any copyright notice, trademark notice or other proprietary legend set forth on or contained within any of the documentation or other materials provided by Unite Us; or (h) otherwise use the Unite Us Platform or Network Participant Data in violation of any applicable law.
          </span>
        </section>
      </section>

      <section className="participation-agreement__content-section">
        <h3 className="participation-agreement__content-section-header">
          DATA
        </h3>

        <section className="participation-agreement__content-section-sub">
          <h4>Data Ownership​.&nbsp;</h4>
          <span>
            Each Network Participant shall remain the owner of any Network Participant Data inputted by such Network Participant of all individuals registered with a Network Participant. Nothing in this section or elsewhere in these Network Terms is intended or will be deemed in any way to limit a Network Participant&apos;​s use of its own Network Participant Data outside of the Unite Us Platform.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Data Restrictions​.&nbsp;</h4>
          <span>
            Network Participant may include personally identifiable data (including protected health information) (collectively, <span className="defined-term">&ldquo;​​PII​&rdquo;​</span>) in Network Participant Data and provide PII to Unite Us in the course of using the Unite Us Platform only if (a) disclosure of such PII is necessary for Network Participant&apos;​s exploitation of the Unite Us Platform and services provided by Unite Us; (b) Network Participant has all consents, rights and authorizations under applicable law necessary to provide Unite Us with the Network Participant Data hereunder; (c) such PII is collected by Network Participant and disclosed to Unite Us pursuant to and in accordance with Network Participant&apos;​s applicable privacy policies and (d) Network Participant&apos;​s provision of such PII to Unite Us and Unite Us&apos;​ retention and use of such PII as contemplated under these Network Terms does not and will not violate any applicable Network Participant privacy policy or any applicable laws.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Data License​.&nbsp;</h4>
          <span>
            Each Network Participant hereby grants (a) all other Network Participants and their Authorized Users an irrevocable, worldwide, non-exclusive, royalty-free, fully paid-up license to access the Network Participant Data, and to use and exercise all rights in it under applicable laws, as permitted by the functionality of the Unite Us Platform and services provided by Unite Us, provided that Network Participants and their Authorized Users may not use the Network Participant Data in violation of applicable law; and (b) Unite Us an irrevocable, worldwide, non-exclusive, royalty-free, fully paid-up license to use, reproduce, modify, distribute and display Network Participant Data (i) on the Unite Us Platform, (ii) for Network evaluation and reporting purposes and (iii) in connection with providing services to Network Participants.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Subject Access Requests; Government Requests​.&nbsp;</h4>
          <span>
            Network Participant acknowledges and agrees that, pursuant to a subject access request or at the request of a government or regulatory agency, any content or data, including Network Participant Data, may be deleted, updated, corrected or otherwise changed or made unavailable with no notice or liability to Network Participant or Unite Us.
          </span>
        </section>
      </section>

      <section className="participation-agreement__content-section">
        <h3 className="participation-agreement__content-section-header">
          CONFIDENTIALITY; PRIVACY AND NETWORK SECURITY
        </h3>

        <section className="participation-agreement__content-section-sub">
          <h4>Confidentiality​.&nbsp;</h4>
          <span>
            In performance of their respective responsibilities pursuant to these Network Terms, Unite Us and each Network Participant may come into the possession of certain Confidential Information of the other party. For the purposes hereof, &ldquo;​Confidential Information&rdquo;​ means all trade secrets, business plans, marketing plans, know-how, data, contracts, documents, scientific and medical concepts, member and customer lists, costs, financial information, profits and billings, and referral sources, existing or future services, products, operations, management, pricing, financial status, goals, strategies, objectives, and agreements of Unite Us and the Network Participant, whether written or verbal, that are confidential in nature; provided, however, that Confidential Information shall not include any information that: (a) is in the public domain; (b) is already known or obtained by any other party other than in the course of the other party&apos;​s performance pursuant to the Network Terms; (c) is independently developed by any other party; and/or (d) becomes known from an independent source having the right to disclose such information and without similar restrictions as to disclosure and use and without breach of these Network Terms, or any other confidentiality or nondisclosure agreement by such other party
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Protection of Confidential Information.​&nbsp;</h4>
          <span>
            Each party agrees (a) to protect the disclosing party&apos;​s Confidential Information from unauthorized dissemination and use; (b) to use the disclosing party&apos;​s Confidential Information only for the performance of the receiving party&apos;​s obligations and in connection with the exercise of the receiving party&apos;​s rights hereunder; (c) to disclose any confidential information only to those of its employees, agents, or contractors who have a need to know for the performance of their duties and who are bound to comply with confidentiality obligations no less restrictive than the requirements set forth in this section; (d) not to disclose or otherwise provide to any third party, without the prior written consent of the disclosing party, any Confidential Information or any part or parts thereof; (e) to undertake whatever action is necessary to prevent or remedy (or authorize the disclosing party to do so in the name of the receiving party) any breach of the receiving party&apos;​s confidentiality obligations set forth herein or any other unauthorized disclosure of any Confidential Information by its current or former employees, agents, or contractors; and (f) not to remove or destroy any proprietary or confidential legends or markings placed upon or contained within any Confidential Information.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Legally Compelled Disclosures​.&nbsp;</h4>
          <span>
            Notwithstanding the restrictions on the use and disclosure of confidential information set forth herein, the receiving party may use or disclose confidential information to the extent the receiving party is legally compelled to disclose such confidential information; provided, however, prior to any such compelled disclosure the receiving party shall (to the extent allowed under applicable law) notify the disclosing party and cooperate fully with the disclosing party in protecting against any such disclosure, and if applicable, obtaining a protective order narrowing the scope of such disclosure and use of the confidential information.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Equitable Relief​.&nbsp;</h4>
          <span>
            Each party acknowledges and agrees that, due to the unique nature of such confidential information, there may be no adequate remedy at law to compensate the disclosing party for the breach of the confidentiality obligations set forth herein; that any such breach may result in irreparable harm to the disclosing party that would be difficult to measure; and, therefore, that upon any such breach or threat thereof, the disclosing party shall be entitled to seek injunctive and other appropriate equitable relief (without the necessity of posting a bond), in addition to whatever remedies it may have at law, under these Network Terms, or otherwise.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Privacy Compliance​.&nbsp;</h4>
          <span>
            Network Participant represents and warrants that its collection, access, use, storage, disposal and disclosure of Network Participant Data does and will comply with applicable law.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Security Incident Reporting​.&nbsp;</h4>
          <span>
            In the event that Network Participant (a) discovers or is notified of any act or omission that materially compromises the security, confidentiality or integrity of any Network Participant Data or any physical, technical, administrative or organizational safeguards that relate to the security, confidentiality or integrity of Network Participant Data or (b) receives a complaint in relation to Network Participant&apos;​s privacy practices or notice of a breach or alleged breach of these Network Terms in connection with such privacy practices (collectively, a <span className="defined-term">&ldquo;​​Security Incident​&rdquo;​</span>), Network Participant shall notify Unite Us of such Security Incident by sending an email to ​<a className="eula-content__link" href="mailto:​compliance@uniteus.com" title="​compliance@uniteus.com">​compliance@uniteus.com</a> with detailed information regarding the Security Incident as soon as possible, but in any event no later than twenty four (24) hours from the discovery of facts giving rise to such knowledge or belief and cooperate with Unite Us in the remediation of such Security Incident. In the event of any Security Incident or potential Security Incident involving or relating to Network Participant Data, Network Participant shall cooperate to resolve any such Security Incident or potential Security Incident as securely and efficiently as possible. In the event of a Security Incident attributable to an act or omission of Network Participant, as part of such remediation, Network Participant shall (a) take all actions at its sole cost and expense required to comply with applicable law relating to the notification of and credit monitoring for individuals or entities whose information may have been disclosed in connection with the breach and (b) provide assurances satisfactory to Unite Us that such breach shall not recur.
          </span>
        </section>
      </section>

      <section className="participation-agreement__content-section">
        <h3 className="participation-agreement__content-section-header">
          REPRESENTATIONS AND WARRANTIES
        </h3>

        <section className="participation-agreement__content-section-sub">
          <h4>Network Participant Representations​.&nbsp;</h4>
          <span>
            Network Participant represents, warrants and covenants to Unite Us that Network Participant owns all rights, title and interest in and to the Network Participant Data provided by such Network Participant, or that Network Participant has otherwise secured all necessary rights in the Network Participant Data as may be necessary to permit the access, use and distribution thereof as contemplated by these Network Terms. Network Participant further represents and warrants to Unite Us that (a) Network Participant will not, or allow an Authorized User or third party to, take any action, or upload, download, post, submit or otherwise distribute or facilitate distribution of any content on or through the Unite Us Platform that infringes any patent, trademark, trade secret, copyright, right of publicity or any other proprietary right of any other person or entity, or, that violates any applicable law or contract; (b) Network Participant will not, or allow an Authorized User or third party to, use the Unite Us Platform in violation of any applicable law, including HIPAA and any laws regarding data privacy, marketing or unsolicited messaging, such as the &ldquo;​CAN-SPAM&rdquo;​ Act of 2003, 15 U.S.C. §§ 7701-7713 or the Telephone Consumer Protection Act, and any similar laws of any applicable jurisdiction; (c) the Network Participant Data will not contain any obscene, defamatory, infringing, illegal, deceptive, or hateful content; (d) the Network Participant Data will be free of any malware, viruses, worms, Trojan horses, spyware and other computer instructions or devices that were designed to, in each case in any material respect, threaten, infect, assault, vandalize, defraud, disrupt, damage, disable, alter, inhibit or shut down the Unite Us Platform; and (e) Network Participant has obtained, and is deemed to have hereby granted to Unite Us, all rights and/or licenses necessary to grant the rights granted by it in these Network Terms.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Disclaimer of Warranty​.&nbsp;</h4>
          <span>
            NETWORK PARTICIPANT EXPRESSLY ACKNOWLEDGES AND AGREES THAT USE OF THE UNITE US PLATFORM AND SERVICES PROVIDED BY UNITE US ARE AT ITS SOLE RISK AND THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH NETWORK PARTICIPANT. THE UNITE US PLATFORM AND SERVICES ARE PROVIDED &ldquo;​AS IS&rdquo;​ AND, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, EXCEPT AS OTHERWISE PROVIDED HEREIN, UNITE US EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, WITH RESPECT TO THE UNITE US PLATFORM (INCLUDING ALL THIRD PARTY AND OPEN-SOURCE COMPONENTS), DOCUMENTATION, DATA, MEDIA AND ANY OTHER SERVICES AND MATERIALS PROVIDED TO NETWORK PARTICIPANT UNDER THESE NETWORK TERMS, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, QUALITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND WARRANTIES ARISING FROM A COURSE OF DEALING, USAGE OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING, UNITE US PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, THAT THE UNITE US PLATFORM OR SERVICES PROVIDED BY UNITE US WILL MEET NETWORK PARTICIPANT&apos;​S REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS OR BE ERROR FREE. NETWORK PARTICIPANT ACKNOWLEDGES AND AGREES THAT THE UNITE US PLATFORM IS NOT BEING PROVIDED TO INDUCE REFERRALS TO ANY PARTICULAR ENTITY OR INDIVIDUAL.
          </span>
        </section>
      </section>

      <section className="participation-agreement__content-section">
        <h3 className="participation-agreement__content-section-header">
          INDEMNITY; LIMITATION OF LIABILITY
        </h3>

        <section className="participation-agreement__content-section-sub">
          <h4>Indemnity​.&nbsp;</h4>
          <span>
            Each Network Participant shall defend, indemnify and hold harmless Unite Us and each other Network Participant, and their respective subsidiaries, affiliates, officers, directors, employees, agents, successors and permitted assigns (each, an <span className="defined-term">&ldquo;​​Indemnitee​&rdquo;​</span>) from and against all losses, damages, liabilities, deficiencies, actions, judgments, interest, awards, penalties, fines, costs or expenses of whatever kind, including reasonable attorneys&apos;​ fees, the cost of enforcing any right to indemnification hereunder and the cost of pursuing any insurance providers, in connection with any third-party claim against any Indemnitee arising out of or resulting from such Network Participant&apos;​s failure to comply with any of its obligations under these Network Terms.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Limitation of Liability​.&nbsp;</h4>
          <span>
            IN NO EVENT WILL UNITE US OR ANY OF ITS LICENSORS, PARTNERS OR REPRESENTATIVES BE LIABLE UNDER THESE NETWORK TERMS TO NETWORK PARTICIPANT, ANY AUTHORIZED USER OR ANY THIRD PARTY FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO ANY DAMAGES FOR BUSINESS INTERRUPTION, INTERRUPTIONS IN THE UNITE US PLATFORM, UNITE US WEBSITE, OR UNITE US SERVICES, LOSS OF USE, DATA (INCLUDING NETWORK PARTICIPANT DATA), REVENUE OR PROFIT, WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, OR ACCURACY OR COMPLETENESS OF ANY DATA CONTAINED IN OR ACCESSIBLE VIA THE UNITE US PLATFORM OR UNITE US WEBSITE, REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND WHETHER UNITE US WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL UNITE US&apos;​ COLLECTIVE AGGREGATE LIABILITY UNDER OR IN CONNECTION WITH THESE NETWORK TERMS OR ITS SUBJECT MATTER, UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE, EXCEED $1,000. THE FOREGOING LIMITATIONS SHALL APPLY EVEN IF A NETWORK PARTICIPANT&apos;​S REMEDIES UNDER THESE NETWORK TERMS FAIL OF THEIR ESSENTIAL PURPOSE.
          </span>
        </section>
      </section>

      <section className="participation-agreement__content-section">
        <h3 className="participation-agreement__content-section-header">
          TERMINATION
        </h3>

        <section className="participation-agreement__content-section-sub">
          <p>
            Unite Us may immediately terminate Network Participant&apos;​s access to the Unite Us Platform in the event that Network Participant materially breaches any term or condition of these Network Terms. In the event of any such termination or the termination of these Network Terms, Network Participant shall immediately cease use of the Unite Us Platform and return or destroy all materials and documentation provided by Unite Us. Unite Us further reserves the right to block, terminate, or otherwise deny Network Participant access to the Unite Us Platform, or remove any material from the Unite Us Platform, in the event that Unite Us believes in good faith that such access or material violates the copyright, privacy, trademark, or publicity rights of any person or party, or otherwise violates applicable law.
          </p>
        </section>
      </section>

      <section className="participation-agreement__content-section">
        <h3 className="participation-agreement__content-section-header">
          GENERAL TERMS
        </h3>

        <section className="participation-agreement__content-section-sub">
          <h4>Nondiscrimination Policy​.&nbsp;</h4>
          <span>
            Network Participant and its Authorized Users shall not discriminate as to race, color, age, religion, sex, national origin, socioeconomic status, sexual orientation, gender identity or expression, disability, veteran status, or source of payment in connection with its and their use of the Unite Us Platform.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Force Majeure​.&nbsp;</h4>
          <span>
            In the event that a party is prevented from performing, or is unable to perform, any of its obligations under these Network Terms due to any cause beyond its reasonable control, the affected party shall give written notice thereof to the other party and its performance shall be extended for the period of delay or inability to perform due to such occurrence.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Assignment​.&nbsp;</h4>
          <span>
            Network Participant may not assign or transfer any of its rights under these Network Terms, either voluntarily or by operation of law, without Unite Us&apos;​ prior written consent.
          </span>
        </section>

        <section className="participation-agreement__content-section-sub">
          <h4>Severability​.&nbsp;</h4>
          <span>
            Any provision of these Network Terms that is deemed invalid, void or illegal shall in no way affect, impair, or invalidate any other provisions set forth herein, and such other provisions shall remain in full force and effect.
          </span>
        </section>
      </section>
    </div>
  </div>
);

ParticipationAgreement.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default ParticipationAgreement;
