import _ from 'lodash';
import {
  FETCH_EXPORTS,
  SET_EXPORTS_REFETCH,
} from 'actions';
import moment from 'moment';

const defaultTarget = {
  data: [
    { data: [], paging: {}, time: 0 },
  ],
  currentPage: 1,
  filters: {},
  isFetching: false,
};

const defaultState = {
  exports: defaultTarget,
};

function updateState(state, action) {
  const currentData = _.get(state, 'exports', {});
  const currentPage = _.get(action, 'payload.data.paging.current_page', 1);
  let newData = _.assign([], currentData.data);

  if (!_.isEqual(_.get(currentData, 'filters', {}), action.filters)) {
    newData = [{ data: [], paging: {} }];
  }

  newData[currentPage - 1] = {
    data: action.payload.data.data,
    paging: action.payload.data.paging,
    time: moment().unix(),
  };

  return _.assign({}, state, {
    exports: {
      data: newData,
      filters: action.filters,
      isFetching: state.exports.isFetching,
      currentPage: action.payload.data.paging.current_page,
    },
  });
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case FETCH_EXPORTS: {
      return {
        ...updateState(state, action),
        refetch: false,
      };
    }
    case SET_EXPORTS_REFETCH: {
      return { ...state, refetch: true };
    }
    default:
      return state;
  }
}
