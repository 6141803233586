import { get } from 'lodash';

function getActiveFacesheetTab(pathname = '') {
  const matches = pathname.match(
    /facesheet\/[A-Z0-9a-z-]*\/([A-za-z]*)/,
  );

  return get(matches, '[1]') || 'overview';
}

export default getActiveFacesheetTab;
