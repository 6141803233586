import React from 'react';
import PropTypes from 'prop-types';
import { useIsNetworkLead } from 'common/contexts/CurrentProviderContext';
import { NoInvoicesFoundCard } from '../components';
import { REJECTED_CBO_ADMIN_STATUSES, REJECTED_NETWORK_LEAD_STATUSES } from '../constants';
import InvoiceWorkqueue from './InvoiceWorkqueue';

const RejectedInvoices = ({ children }) => {
  const isNetworkLead = useIsNetworkLead();
  const bulkActions = ['IconDownloadV2', 'IconArchive'];

  const invoiceStatus = isNetworkLead ? REJECTED_NETWORK_LEAD_STATUSES : REJECTED_CBO_ADMIN_STATUSES;
  return (
    <InvoiceWorkqueue
      invoiceStatus={invoiceStatus}
      bulkActions={bulkActions}
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <NoInvoicesFoundCard isFiltered={isFiltered} status="rejected" resetFilters={resetFilters} />
      )}
      path="closed/rejected/"
    >
      {children}
    </InvoiceWorkqueue>
  );
};

RejectedInvoices.propTypes = {
  children: PropTypes.node,
};

RejectedInvoices.defaultProps = {
  children: undefined,
};
export default RejectedInvoices;
