import axios from 'axios';
import { SET_EULA_ACCEPTANCE } from 'actions';
import Notifier from 'common/helpers/Notifier';
import { AUTH_URL } from 'src/config/env/env.config';
import submitLogoutForm from 'src/common/utils/Auth/submitLogoutForm';
import { get } from 'lodash';

function onSuccess(response, dispatch) {
  dispatch({ type: SET_EULA_ACCEPTANCE, payload: response });
  return response;
}

function onError(error) {
  if (get(error, 'response.status') === 403) {
    submitLogoutForm();
    return error;
  }
  Notifier.handleErrors(error);
  return error;
}

export default function getEulaAgreementStatus() {
  const request = axios.get('/users/accept_eula', { baseURL: AUTH_URL });

  return (dispatch) => request
    .then((data) => onSuccess(data, dispatch))
    .catch((error) => onError(error, dispatch));
}
