import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { get, isEmpty, recursiveGet } from 'lodash';
import ClientSummary from 'src/components/ClientSummary';
import { browserHistory } from 'common/utils/browserHistory';

class RequestorHeader extends Component {
  constructor(props) {
    super(props);
    this.goToFacesheet = this.goToFacesheet.bind(this);
  }

  goToFacesheet() {
    const { screening = {} } = this.props;

    if (get(screening, 'contact.id')) {
      browserHistory.push(`/facesheet/${get(screening, 'contact.id')}`);
    }
  }

  render() {
    const {
      contact,
      enums,
      isMilitaryFocused,
      screening,
    } = this.props;
    const requestorOrContact = contact || recursiveGet(
      screening,
      ['contact', 'requestor'],
      (cont) => !isEmpty(cont),
    );

    const goToFacesheetLink = get(screening, 'contact.id') ? (
      <a
        className="client-name__link"
        onClick={this.goToFacesheet}
        role="link"
      >
        GO TO FACESHEET
      </a>
    ) : null;

    return (
      <div className="requestor-header">
        <ClientSummary
          contact={requestorOrContact}
          enums={enums}
          isMilitaryFocused={isMilitaryFocused}
        />
        <div className="pull-right">
          {goToFacesheetLink}
        </div>
      </div>
    );
  }
}

RequestorHeader.propTypes = {
  contact: PropTypes.object,
  enums: PropTypes.object,
  isMilitaryFocused: PropTypes.bool,
  screening: PropTypes.shape({
    contact: PropTypes.object,
    requestor: PropTypes.object,
  }).isRequired,
};

export default RequestorHeader;
