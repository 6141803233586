import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { ExpandableContainer } from '@unite-us/client-utils';
import '../stylesheets/detail-view.scss';

function DetailDefinitionList(props) {
  const {
    icon,
    id,
    styles,
    title,
    value,
  } = props;

  const iconValueClass = () => classNames({
    'detail-definition-list__icon-value': true,
    'detail-definition-list__icon-value--none': _.isEmpty(styles.icon),
  });

  return (
    <div className="detail-definition-list" style={styles.icon}>
      <div className="detail-definition-list__icon">
        {icon || null}
      </div>

      <div className={iconValueClass()}>
        <ExpandableContainer
          collapsedHeight="70px"
          id={id}
          header={title}
        >
          {value}
        </ExpandableContainer>
      </div>
    </div>
  );
}

DetailDefinitionList.propTypes = {
  icon: PropTypes.node,
  id: PropTypes.string.isRequired,
  styles: PropTypes.shape({
    icon: PropTypes.object.isRequired,
  }),
  title: PropTypes.string.isRequired,
  value: PropTypes.any,
};

DetailDefinitionList.defaultProps = {
  styles: {
    icon: { paddingLeft: '40px' },
  },
};

export default DetailDefinitionList;
