import { get } from 'lodash';
import { useFind, useFindRecord, usePopulate, usePopulateMemo } from 'src/api/APIHooks';

const useFormSubmission = ({ submissionId, providerId, contextType }) => {
  const formSubmissionQuery = useFindRecord(
    'form_submission',
    submissionId,
    {
      page: {
        number: 1,
        size: 1,
      },
      api: 'coreApi',
      queryConfig: {
        refetchInterval: false,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
      },
    },
  );

  const formSubmission = get(
    formSubmissionQuery,
    'data.data.data',
    {
      queryConfig: {
        refetchOnWindowFocus: false,
        refetchInterval: false,
        staleTime: Infinity,
      },
    },
  );
  const sqProvider = usePopulate(
    'provider',
    'provider',
    formSubmission,
    {
      queryConfig: {
        refetchOnWindowFocus: false,
        refetchInterval: false,
        staleTime: Infinity,
      },
    },
  );
  const submitterQuery = usePopulate(
    'submitter',
    'employee',
    formSubmission,
    {
      queryConfig: {
        refetchOnWindowFocus: false,
        refetchInterval: false,
        staleTime: Infinity,
      },
    },
  );
  const fQuery = usePopulate(
    'form',
    'form',
    formSubmission,
    {
      queryParams: {
        'form_usages.provider': providerId,
      },
      queryConfig: {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
      },
    },
  );

  const identifiedNeeds = get(formSubmission, 'identified_needs', []);
  const serviceIds = identifiedNeeds.map((n) => n.id).join(',');
  const servicesQuery = useFind(
    'service',
    { id: serviceIds },
    {
      queryConfig: {
        refetchOnWindowFocus: false,
        refetchInterval: false,
        staleTime: Infinity,
      },
    },
  );
  const services = get(servicesQuery, 'data.data.data', []);

  let pQuery;
  let cQuery;

  if (contextType === 'person') {
    pQuery = usePopulate(
      'context',
      'person',
      formSubmission,
      {
        queryConfig: {
          refetchOnWindowFocus: false,
          refetchInterval: false,
          staleTime: Infinity,
        },
      },
    );
    cQuery = usePopulate(
      'context.consent',
      'consent',
      formSubmission,
      {
        queryConfig: {
          refetchOnWindowFocus: false,
          refetchInterval: false,
          staleTime: Infinity,
        },
      },
    );
    formSubmission.contact = formSubmission.context;
  }

  formSubmission.needs = [];
  services.forEach((service) => {
    if (identifiedNeeds.map((n) => n.id).includes(service.id)) {
      formSubmission.needs.push({ service_type: service });
    }
  });

  const isFetching = usePopulateMemo(formSubmissionQuery.data, [
    formSubmissionQuery, submitterQuery, fQuery, sqProvider, servicesQuery, pQuery, cQuery,
  ]);

  return {
    formSubmission,
    isFetching,
  };
};

export default useFormSubmission;
