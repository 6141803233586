import { editable, viewable } from 'common/utils/Permission/Permission';
import { isAutoRecallable } from 'src/components/Referrals/utils';
import { isReferredToGroup } from 'src/components/Referrals/ReferralStatus/utils';

function showEdit(referral, referralContact, groupId) {
  const { status } = referral;
  if (isAutoRecallable(referral) && isReferredToGroup(referral, groupId)) {
    return false;
  }

  switch (status) {
    case 'in_review':
    case 'sent':
    case 'recalled':
    case 'declined':
      return editable(referral) && viewable(referralContact);
    case 'draft':
      return true;
    default:
      return false;
  }
}

export default showEdit;
