import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ChartPie } from 'common/charts';
import { fetchContactsReports } from 'actions/Report/Contact';
import _ from 'lodash';

export default class ContactsByAge extends Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.state = {
      contactsByAge: [],
      title: '',
    };
  }

  componentDidMount() {
    this.fetchData(this.props.filters);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const filtersChanged = !_.isEqual(this.props.filters, nextProps.filters);
    const networkChanged = !_.isEqual(this.props.id, nextProps.id);

    if (filtersChanged || networkChanged) {
      this.fetchData(nextProps.filters, nextProps.id);
    }
  }

  fetchData(filters, id = this.props.id) {
    const { scope } = this.props;
    fetchContactsReports(scope, id, 'by-age', filters)
      .then((response) => {
        this.setState({
          contactsByAge: _.get(response, 'data.values', []),
          title: _.get(response, 'data.title', ''),
        });
        this.props.loaded('contactsByAge');
      });
  }

  render() {
    if (_.isEmpty(this.state.contactsByAge)) {
      return null;
    }
    return (
      <ChartPie
        data={this.state.contactsByAge}
        title={this.state.title}
        x={(d) => d.title}
        y={(d) => d.total}
        style={{ height: '350px' }}
        valueFormat="d"
        twoColsLegend={false}
      />
    );
  }
}

ContactsByAge.propTypes = {
  filters: PropTypes.shape({
    end_date: PropTypes.string,
    network_ids: PropTypes.array,
    start_date: PropTypes.string,
  }),
  loaded: PropTypes.func.isRequired,
  scope: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
