import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import { Icon, SelectField } from '@unite-us/ui';
import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { browserHistory } from 'src/common/utils/browserHistory';
import { ExpandableChoiceList } from 'src/components/Backoffice/form/ExpandableChoiceList';
import { UnsavedFormWarning } from 'src/pages/backoffice/forms/components/UnsavedFormWarning';
import cx from 'classnames';
import { Spinner } from 'common/spinners';
import {
  createServiceOptions,
  convertQuestionsForList,
  createAnswerServicePairing,
  updateAnswerServiceTypeCount,
  createServiceInputPairing,
} from 'src/pages/backoffice/forms/components/editLogicDataTransformers';
import { makeRequests } from 'src/pages/backoffice/forms/components/editLogicRequestHandlers';
import DialogV2 from 'common/modal/DialogV2';
import FileUpload from 'common/form/FileUploadV2/FileUpload';
import './EditLogic.scss';
import { extractQuestionsRecursive } from '../extractQuestionsRecursive';
import { getServiceName, sortByRelevance, getChildrenArray } from './utils';

export const EditLogic = ({
  formConfig,
  services,
  formId,
  formName,
  surveyCreatorState,
  saveSurvey,
  formNeedConfigurations,
  createConfig,
  updateConfig,
  deleteConfig,
  invalidateQueries,
  onDownloadLogicAnswers,
  onUploadLogicAnswers,
}) => {
  if (surveyCreatorState === 'modified' || isEmpty(formConfig)) {
    return (<UnsavedFormWarning saveSurvey={saveSurvey} />);
  }
  const serviceOptions = createServiceOptions(services);
  const options = serviceOptions;
  const serviceOptionsChildren = getChildrenArray(serviceOptions);
  const questions = [];
  const [selectedQuestionChoice, setSelectedQuestionChoice] = useState({});
  extractQuestionsRecursive(get(formConfig, 'pages[0]', []), questions); // populates question array
  const questionList = convertQuestionsForList(questions);
  const [answerServicePairing, setAnswerServicePairing] =
   useState(createAnswerServicePairing(questionList, formNeedConfigurations));
  const questionListWithCount = updateAnswerServiceTypeCount(questionList, answerServicePairing);
  const [isPerformingRequests, setIsPerformingRequests] = useState(false);
  const dialogV2Ref = useRef(null);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState({
    filesTooBig: [],
    filesWrongType: [],
    filesTooBigAndWrongType: [],
  });

  const openDialog = useCallback(() => {
    dialogV2Ref.current.openDialog();
  }, []);

  const cancelAttachFiles = useCallback(() => {
    setUploadedFiles([]);
    setRejectedFiles({ filesTooBig: [], filesWrongType: [], filesTooBigAndWrongType: [] });
    dialogV2Ref.current.closeDialog();
  }, []);

  if (isPerformingRequests) {
    return (<Spinner />);
  }

  const onChoiceClick = (choice, question, questionIndex) => {
    setSelectedQuestionChoice({
      questionTitle: `Question: ${questionIndex + 1}. ${question.title}`,
      answerText: `Answer: ${choice.text}`,
      inputOptionId: choice.id,
    });
  };

  const onServiceChange = (serviceTypes) => {
    const answerServicePairingTemp = answerServicePairing;
    answerServicePairingTemp[selectedQuestionChoice.inputOptionId] = serviceTypes;
    setAnswerServicePairing({ ...answerServicePairingTemp });
  };

  const sortServiceOptionsByRelevance = (search) => new Promise((resolve) => {
    if (isEmpty(search)) {
      resolve({ options: serviceOptions });
    }
    resolve({
      options: sortByRelevance(serviceOptionsChildren, search),
    });
  });

  const handlePromiseResults = (promises) => {
    Promise.allSettled(promises)
    .then((results) => {
      const isSuccessful = results.filter((result) => isHttpSuccess(result.value)).length === promises.length;
      browserHistory.push(`/backoffice/forms/${formId}/edit/editLogic`);
      invalidateQueries('form');
      if (isSuccessful) {
        Notifier.dispatch(200, 'The form is saved.');
      } else {
        Notifier.dispatch(400, 'Error saving form.');
      }
    });
  };

  const onSaveConfigurations = () => {
    const serviceInputPairing = createServiceInputPairing(answerServicePairing);
    const promises =
     makeRequests(formId, serviceInputPairing, formNeedConfigurations, createConfig, updateConfig, deleteConfig);

     if (isEmpty(promises)) {
      return;
    }
    setIsPerformingRequests(true);
    handlePromiseResults(promises);
  };

  return (
    <div id="edit-logic-tab-container">
      <div className="grid w-full grid-flow-row auto-rows-max">
        <div className="mb-2">
          <button
            className="mr-1 btn btn-primary sv-btn svd-toolbar-button svd_save_btn float-right"
            type="button"
            onClick={openDialog}
          >
            <Icon
              icon={'IconCloudUpload'}
              className={cx('fill-current text-white')}
            />
            <div className="svd-toolbar-button__title">
              <span data-bind="text: item.title">Upload Answers</span>
            </div>
          </button>
          <button
            className="mr-1 btn btn-primary sv-btn svd-toolbar-button svd_save_btn float-right"
            type="button"
            onClick={() => onDownloadLogicAnswers(formNeedConfigurations, getServiceName, formName, services)}
            disabled={!formNeedConfigurations?.length}
          >
            <Icon
              icon={'IconDownloadV2'}
              className={cx('fill-current text-white', !formNeedConfigurations?.length && 'opacity-50')}
            />
            <div className="svd-toolbar-button__title">
              <span data-bind="text: item.title">Download Answers</span>
            </div>

          </button>
          <button
            className="mr-1 btn btn-primary sv-btn svd-toolbar-button svd_save_btn float-right"
            type="button"
            onClick={onSaveConfigurations}
          >
            <div className="svd-toolbar-button__title">
              <span data-bind="text: item.title">Save Form</span>
            </div>
          </button>
        </div>
      </div>
      <div id="edit-logic-container" className="flex pb-2">
        <div id="form_questions_container" className="w-1/3 bg-white m-2 pr-2 overflow-auto">
          <ExpandableChoiceList
            optionsList={questionListWithCount}
            onClick={(choice, option, optionIndex) => { onChoiceClick(choice, option, optionIndex); }}
            numberedList
            displayChoiceCount
          />
        </div>
        <div id="services-container" className="w-2/3 my-2 mr-1 ml-4 bg-white">
          {
          selectedQuestionChoice.inputOptionId ?
            (
              <div className="grid w-full px-4 py-2 text-left">
                <h1 className="ml-2 mt-4">{selectedQuestionChoice.questionTitle}</h1>
                <h1 className="ml-2 mt-2 mb-6">{selectedQuestionChoice.answerText}</h1>
                <span className="ml-2 text-base">If it&apos;s selected, then suggest</span>
                <div className="browse-filters-service-types -m-3 mx-1">
                  <SelectField
                    id="service-types"
                    multiple
                    label=""
                    options={options}
                    value={answerServicePairing[selectedQuestionChoice.inputOptionId]}
                    placeholder="Select..."
                    onChange={onServiceChange}
                    forceObjectValue
                    shouldSort={false}
                    labelKey="name"
                    valueKey="id"
                    loadOptions={sortServiceOptionsByRelevance}
                  />
                </div>
              </div>
            ) :
            (
              <div className="flex pb-9 items-center justify-center h-full">
                <h1 className="mx-4 pb-9 mb-9">In the left section, choose a question and answer to add/edit logic</h1>
              </div>
            )
          }
        </div>
      </div>
      <DialogV2
        cancelHandler={cancelAttachFiles}
        confirmationHandler={
          () => onUploadLogicAnswers(
            uploadedFiles,
            services,
            formId,
            dialogV2Ref,
            createConfig,
            setUploadedFiles,
            setRejectedFiles,
            invalidateQueries,
            formConfig,
            formNeedConfigurations,
          )
        }
        confirmationButtonDisabled={!uploadedFiles.length}
        confirmLabel={'Upload'}
        ref={dialogV2Ref}
        title={'Drop Answers File Here'}
        isWarning
        confirmButtonTestId={'EditLogicDialogConfirmButtomTestId'}
      >
        <FileUpload
          rejectedFiles={rejectedFiles}
          setRejectedFiles={setRejectedFiles}
          setUploadedFiles={setUploadedFiles}
          uploadedFiles={uploadedFiles}
          acceptedFileTypes={'application/json'}
        />
      </DialogV2>
    </div>
  );
};

EditLogic.propTypes = {
  formConfig: PropTypes.object.isRequired,
  services: PropTypes.object.isRequired,
  formId: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  surveyCreatorState: PropTypes.string.isRequired,
  saveSurvey: PropTypes.func.isRequired,
  formNeedConfigurations: PropTypes.array.isRequired,
  createConfig: PropTypes.func.isRequired,
  updateConfig: PropTypes.func.isRequired,
  deleteConfig: PropTypes.func.isRequired,
  invalidateQueries: PropTypes.func.isRequired,
  onDownloadLogicAnswers: PropTypes.func.isRequired,
  onUploadLogicAnswers: PropTypes.func.isRequired,
};

export default EditLogic;
