import React from 'react';
import PropTypes from 'prop-types';
import BrowseMapButton from 'src/components/Browse/BrowseMapButton';
import { isDraft } from 'src/components/Referrals/ReferralStatus/utils/status';
import { oonGroupHeaderMessage } from '../utils';

const ReferralOONGroupHeader = ({
  inNetworkGroupsEmpty,
  oonGroupsEmpty,
  referral,
  toggleBrowse,
  isProgramBasedSearch,
}) => (
  <header className="referral-oon-group-header mb-one">
    <h5>{`Suggested Out of Network ${isProgramBasedSearch ? 'Programs' : 'Organizations'}`}</h5>
    <p className="referral-group-hint">
      {oonGroupHeaderMessage({ inNetworkGroupsEmpty, oonGroupsEmpty, isProgramBasedSearch })}
      {
        !isDraft(referral) && !oonGroupsEmpty && (
          <span className="referral-oon-group-header__browse-link">
            Click
            <BrowseMapButton
              id="browse-map-button"
              onClick={() => toggleBrowse('out-of-network')}
            />
            for more options.
          </span>
        )
      }
    </p>
  </header>
);

ReferralOONGroupHeader.propTypes = {
  inNetworkGroupsEmpty: PropTypes.bool,
  oonGroupsEmpty: PropTypes.bool,
  referral: PropTypes.object.isRequired,
  toggleBrowse: PropTypes.func.isRequired,
  isProgramBasedSearch: PropTypes.bool,
};

ReferralOONGroupHeader.defaultProps = {
  inNetworkGroupsEmpty: false,
  oonGroupsEmpty: false,
  isProgramBasedSearch: false,
};

export default ReferralOONGroupHeader;
