// Library Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { dates } from '@unite-us/app-components';
import { validateReduxForm } from 'common/form';
import {
  Button,
  Divider,
  decodeHTML,
} from '@unite-us/ui';
import { browserHistory } from 'common/utils/browserHistory';

// Util Imports
import addNotification from 'common/utils/Notifications/actions/AddNotification';
import { caseDetailFields } from 'src/components/Cases/utils/fields';
import checkFields, { removeOptionalFields } from 'src/components/Cases/utils/checkFields';
import enforcedConsentRoute from 'common/utils/Navigation/enforcedConsentRoute';
import { CASE_DETAILS_FORM, CONTACT_CASE_FILE_UPLOAD_KEY } from 'src/components/Cases/constants';
import { getDropzoneDocuments } from 'common/form/DocumentUploader/utils';
import callOrLog from 'src/common/utils/callOrLog';
import { getAttachedDocumentsFromField } from 'src/components/Referrals/utils/form';
import { SERVICE_CASE } from 'common/utils/EventTracker/utils/eventConstants';
import {
  getOONGroupNameFromField,
  validateOONGroupFields,
} from 'src/components/Referrals/ReferralFormFields/OONGroupsSelector/utils';
import { structureOONProviders } from 'src/components/Referrals/ReferralGroupsPrograms/utils';
import { getEmployeeNetworks } from 'src/components/Employee/employeeGetters';

// Action Imports
import { attachCaseDocuments } from 'actions/Document/Contact/Group';
import { createServiceCase, createServiceCaseFromAR } from 'actions/Case/Contact/Group';
import { patchAssessment as patchCaseAssessment } from 'actions/Assessment/Case/Group';

// Component Imports
import DetailUnorderedList from 'common/display/SingleItemDetails/DetailUnorderedList';
import DocumentsListView from 'common/form/DocumentUploader/components/DocumentsListView';
import ConsentReminder from 'src/components/ConsentReminder';
import ReferralAuthorizationRequestSummary from 'src/components/Referrals/components/ReferralServices/ReferralAuthorizationRequestSummary';

// Stylesheets imports
import 'src/components/Cases/stylesheets/caseCreation.scss';

// Feature flag import;
import { hasCasesOONReferralUnlicensedOrgs } from 'src/common/utils/FeatureFlags/flags';

import { DOMAIN_CONSTANTS } from 'src/common/constants';

export function buildParams(values, selected, featureFlag = undefined, feeScheduleProgramId = null) {
  const { service_case = {} } = values;
  const selectedGroups = validateOONGroupFields(selected);
  const selectedGroupNames = selectedGroups.map(getOONGroupNameFromField).join(', ');

  let serviceCase = {
    description: service_case.description || null,
    primary_worker_id: service_case.primary_worker_id || null,
  };

  if (featureFlag) {
    serviceCase = {
      ...serviceCase,
      out_of_network_providers: structureOONProviders(selectedGroups),
    };
  }

  return {
    // Handle formData.network as ''. When empty, should always result in null.
    authorization_request: _.get(values, 'authorizationRequest', null),
    network_id: _.get(values, 'network.id') || null,
    program: {
      entry_date: values.program_entry || null,
      program_id: _.get(values, 'program.id', null),
      program_name: _.get(values, 'program.attributes.name', null) || _.get(values, 'program.name', null),
      referred_to: decodeHTML(selectedGroupNames) || null,
      fee_schedule_program_id: feeScheduleProgramId,
    },
    service_case: serviceCase,
    service_type_id: _.wget(service_case, 'service_type.id') || null,
  };
}

export class ReviewCaseDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshed: false,
    };

    this.onEdit = this.onEdit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.submitAssessments = this.submitAssessments.bind(this);
  }

  UNSAFE_componentWillMount() {
    const {
      assistanceRequestId,
      contactId,
      fields: {
        service_case,
        program,
        program_entry,
      },
    } = this.props;

    const caseFields = removeOptionalFields({
      options: {
        serviceCase: service_case,
        programId: program,
        programEntry: program_entry,
      },
    });

    const containEmptyFields = checkFields(caseFields);

    if (containEmptyFields) {
      const prefix = assistanceRequestId ?
        `/assistance-request/${assistanceRequestId}` :
        '';
      browserHistory.push(`${prefix}/contacts/${contactId}/cases/new/add-case-details`);
      this.setState({ refreshed: true });
    }
  }

  onEdit() {
    const {
      assistanceRequestId,
      contactId,
    } = this.props;

    const prefix = assistanceRequestId ?
      `/assistance-requests/${assistanceRequestId}` :
      '';
    browserHistory.push(`${prefix}/contacts/${contactId}/cases/new/add-case-details`);
  }

  onSubmit(values) {
    const {
      assistanceRequestId,
      contactId,
      dropzoneDocuments,
      fields: { service_case: { oonCase: { selected } } },
      groupId,
      casesOONReferralUnlicensedOrgs,
      groupsPrograms,
    } = this.props;

    const attachedDocuments = _.reduce(values.service_case.attachableDocuments, (acc, curr) => (
      curr.attached ? [...acc, {
        title: _.wget(curr, 'document.title'),
        document_id: _.wget(curr, 'document.id'),
      }] : acc
    ), []);
    const feeScheduleProgramId = _.get(
      _.find(groupsPrograms.data, { id: values.program.id }),
      'relationships.fee_schedule_program.data.id',
      null,
    );

    return assistanceRequestId ? this.props.createServiceCaseFromAR({
      assistanceRequestId,
      attachedDocuments,
      contactId,
      dropzoneDocuments,
      groupId,
      params: buildParams(values, selected, casesOONReferralUnlicensedOrgs),
    }).then((response) => {
      // Use contact from response to make sure we have a contact id (because
      // we could be coming from an AR that doesn't have a pre-existing contact).
      const serviceCase = _.get(response, 'data.data', {});
      callOrLog(() => this.context.eventTracker(SERVICE_CASE.createFromAr, {
        assistance_request_id: assistanceRequestId,
      }, { serviceCase }));

      this.submitAssessments(serviceCase, () => {
        const caseContact = _.get(response, 'data.data.person');
        enforcedConsentRoute({
          contact: caseContact,
          itemType: DOMAIN_CONSTANTS.CASE,
          to: '/dashboard/cases/open',
        });
        this.resetForm();
      });
    }) :
      this.props.createServiceCase({
        assistance_request_id: assistanceRequestId,
        attachedDocuments,
        contactId,
        dropzoneDocuments,
        groupId,
        params: buildParams(values, selected, casesOONReferralUnlicensedOrgs, feeScheduleProgramId),
      }).then((response) => {
        const serviceCase = _.get(response, 'data.data', {});
        callOrLog(() => this.context.eventTracker(SERVICE_CASE.createFromFacesheet, null, {
          serviceCase,
        }));

        this.submitAssessments(serviceCase, () => {
          const caseContact = _.get(response, 'data.data.person');
          enforcedConsentRoute({
            contact: caseContact,
            itemType: DOMAIN_CONSTANTS.CASE,
            to: '/dashboard/cases/open',
          });
          this.resetForm();
        });
      });
  }

  submitAssessments(serviceCase, onComplete) {
    const {
      groupId,
      assessmentResponses,
      currentEmployee,
    } = this.props;

    Promise.all(_.map(assessmentResponses, (assessment) => (
      this.props.patchCaseAssessment({
        groupId,
        caseId: serviceCase.id,
        id: assessment.id,
        values: assessment.data,
        submitter: currentEmployee,
      })
    ))).catch(() => (
      this.props.addNotification({
        payload: {
          status: 'warning',
          statusText: 'There was a problem saving the assessment with your case.',
        },
      })
    )).finally(onComplete);
  }

  resetForm() {
    this.props.resetForm();
  }

  render() {
    const {
      fields,
      dropzoneDocuments,
      groupsPrograms,
      groupsUsers,
      handleSubmit,
      networks,
      returnedAssessment,
      styles,
      submitting,
    } = this.props;

    if (this.state.refreshed) {
      return null;
    }

    const networkTeam = !_.isEmpty(networks) ?
      networks.find((network) => network.id === fields.network.value.id) :
      null;

    const selectedProgram = _.get(groupsPrograms, 'data', [])
      .find((groupProgram) => groupProgram.id === fields.program.value.id);

    const primaryWorker = _.get(groupsUsers, 'data', [])
      .find((groupUser) => (
        groupUser.employee.id === fields.service_case.primary_worker_id.value
      ));

    const selectedServiceType = fields.service_case.service_type.value || {};

    const attachedDocuments = getAttachedDocumentsFromField(fields.service_case.attachableDocuments);

    const docs = [
      ...(dropzoneDocuments || []),
      ...attachedDocuments,
    ];

    const referredToGroups = validateOONGroupFields(fields.service_case.oonCase.selected)
      .map(getOONGroupNameFromField).join(', ');
    const isDefaultProgram = _.get(selectedProgram, 'attributes.default');

    const feeScheduleProgramId = _.get(
      _.find(groupsPrograms.data, { id: fields.program.value.id }),
      'relationships.fee_schedule_program.data.id',
      null,
    );

    return (
      <div className="detail-review">
        <form
          className="case-review"
          onSubmit={handleSubmit(this.onSubmit)}
        >
          <div className="row detail-review__row">
            <div className="col-xs-6 col-md-6 detail-review__cols">
              <div className="detail-review__content">
                <h5 className="detail-review__label">Service Type</h5>
                <p className="detail-review__text--short">{_.get(selectedServiceType, 'name')}</p>
              </div>

              <Divider style={styles.verticalDesignStyle} />
            </div>

            <div className="col-xs-6 col-md-6 detail-review__cols">
              <div className="detail-review__content">
                <h5 className="detail-review__label">Network</h5>
                <p>{networkTeam ? networkTeam.name : 'No Network selected'}</p>
              </div>
            </div>
          </div>

          <Divider />

          <div className="row detail-review__row">
            <div className="col-xs-6 col-md-6 detail-review__cols">
              <div className="detail-review__content">
                <h5 className="detail-review__label">Program</h5>
                <p className="detail-review__text--short">{_.get(selectedProgram, 'attributes.name')}
                  <span className="detail-review__text--italicize">
                    {
                      isDefaultProgram ?
                        ` (Referred on ${dates.formatDate(parseInt(fields.program_entry.value, 10))})` :
                        ` (Enrolled on ${dates.formatDate(parseInt(fields.program_entry.value, 10))})`
                    }
                  </span>
                </p>
              </div>

              <Divider style={styles.verticalDesignStyle} />
            </div>

            <div className="col-xs-6 col-md-6">
              <div className="detail-review__content">
                <h5 className="detail-review__label">Primary Worker</h5>
                <p className="detail-review__text--short">
                  {primaryWorker ? primaryWorker.user.full_name : 'No Primary Worker selected'}
                </p>
              </div>
            </div>
          </div>

          <Divider />
          {
            isDefaultProgram ? (
              <div className="row detail-review__row">
                <div className="col-xs-12 col-md-12">
                  <h5 className="detail-review__label">Referred To</h5>
                  <p className="detail-review__text--short">
                    {decodeHTML(referredToGroups)}
                  </p>
                </div>
              </div>
            ) : null
          }
          {fields?.authorizationRequest?.service_start?.value && (
            <>
              <div className="row detail-review__row">
                <div className="col-xs-12 col-md-12">
                  <ReferralAuthorizationRequestSummary
                    authorizationRequest={{
                      service_start: fields?.authorizationRequest.service_start.value,
                      service_end: fields?.authorizationRequest.service_end.value,
                      amount_requested: fields?.authorizationRequest.amount_requested.value,
                      fee_schedule_program_id: feeScheduleProgramId,
                    }}
                  />
                </div>
              </div>
              <Divider />
            </>
          )}
          <div className="row detail-review__row">
            <div className="col-xs-12 col-md-12">
              <h5 className="detail-review__label">
                {isDefaultProgram ? 'Notes' : 'Case Description'}
              </h5>
              <p className="detail-review__text--short">
                {fields.service_case.description.value}
              </p>
            </div>
          </div>

          <Divider />

          <div className="row detail-review__row">
            {
              returnedAssessment.length ? (
                <div className="col-xs-12 col-md-12">
                  <DetailUnorderedList
                    list={returnedAssessment}
                    title="Assessments"
                  />
                </div>
              ) : null
            }
          </div>

          <div className="row detail-review__row">
            <div className="col-xs-12 col-md-12">
              <DocumentsListView
                documents={docs}
                editable={false}
                label="Attached Documents"
              />
            </div>
          </div>

          <div className="row detail-review__row">
            <div className="col-xs-12 col-md-12">
              <ConsentReminder />
            </div>
          </div>

          <footer className="detail-review__footer">
            <Button
              id="edit-case-btn"
              label="Edit Case"
              onClick={this.onEdit}
              style={{ marginRight: '10px' }}
              disabled={submitting}
            />

            <Button
              id="submit-case-btn"
              onClick={handleSubmit(this.onSubmit)}
              label="Confirm and Submit"
              primary
              disabled={submitting}
            />
          </footer>
        </form>
      </div>
    );
  }
}

ReviewCaseDetails.propTypes = {
  addNotification: PropTypes.func.isRequired,
  assistanceRequestId: PropTypes.string,
  assessmentResponses: PropTypes.array,
  casesOONReferralUnlicensedOrgs: PropTypes.bool,
  contactId: PropTypes.string.isRequired,
  createServiceCase: PropTypes.func.isRequired,
  createServiceCaseFromAR: PropTypes.func.isRequired,
  currentEmployee: PropTypes.object.isRequired,
  dropzoneDocuments: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  groupsPrograms: PropTypes.shape({
    data: PropTypes.array,
  }).isRequired,
  groupsUsers: PropTypes.shape({
    data: PropTypes.array,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  networks: PropTypes.array.isRequired,
  params: PropTypes.shape({
    assistance_request_id: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  patchCaseAssessment: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  returnedAssessment: PropTypes.array.isRequired,
  styles: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
};

ReviewCaseDetails.defaultProps = {
  casesOONReferralUnlicensedOrgs: false,
  contact: {},
  dropzoneDocuments: [],
  groups: [],
  groupsUsers: {},
  networks: [],
  oonGroups: [],
  returnedAssessment: [],
  styles: {
    verticalDesignStyle: { height: '35px', width: '0.5px' },
    cardStyleSplit: { boxShadow: '0 2px 2px -2px #c5c8cc', marginBottom: '10px', position: 'relative' },
    cardStyleFull: { boxShadow: '0 2px 2px -2px #c5c8cc', marginBottom: '10px', position: 'relative' },
    cardStyleFullNoBorder: { boxShadow: 'none', marginBottom: '10px', position: 'relative' },
    icon: { width: '20px', height: '20px' },
  },
};

ReviewCaseDetails.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const {
    groupsUsers,
    groupsPrograms,
    fileupload,
    serviceCase,
  } = state;

  const assistanceRequestId = _.get(ownProps, 'params.assistance_request_id');
  const contacts = _.get(state, 'contacts.contacts', []);
  const contactId = _.get(ownProps, 'params.id');
  const groupId = _.get(state, 'session.groupId');
  const returnedAssessment = _.get(state, 'groupForms.returnedAssessment');
  const assessmentResponses = _.get(state, 'groupForms.responses', []);
  const user = _.get(state, 'user', {});
  const casesOONReferralUnlicensedOrgs = hasCasesOONReferralUnlicensedOrgs(state);
  const currentEmployee = _.get(state, 'globalState.currentEmployee');

  return {
    assistanceRequestId,
    dropzoneDocuments: getDropzoneDocuments(fileupload, CONTACT_CASE_FILE_UPLOAD_KEY),
    contactId,
    contact: _.find(contacts, { id: contactId }) || {},
    groupId,
    groups: user.groups,
    networks: getEmployeeNetworks({ state }),
    groupsUsers,
    groupsPrograms,
    submitting: _.get(serviceCase, 'isCreating', false),
    returnedAssessment,
    assessmentResponses,
    casesOONReferralUnlicensedOrgs,
    currentEmployee,
  };
}

export default validateReduxForm({
  form: CASE_DETAILS_FORM,
  fields: caseDetailFields,
  destroyOnUnmount: false,
}, mapStateToProps, {
  addNotification,
  attachCaseDocuments,
  createServiceCase,
  createServiceCaseFromAR,
  patchCaseAssessment,
})(ReviewCaseDetails);
