import PropTypes from 'prop-types';
import React from 'react';
import { get, find, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { useNotes } from 'src/components/Notes/queries';
import Note from 'src/components/Notes/Note';
import NotesHeader from 'src/components/Notes/NotesHeader';
import { hasDeleteNoteIcon } from 'common/utils/FeatureFlags/flags';
import Pagination from 'src/common/Pagination/Pagination';
import { Spinner } from 'common/spinners';
import NoNotes from 'src/components/Notes/NoNotes';

const Notes = ({
  currentEmployeeId,
  onAddNote,
  subject,
  subjectType,
  providerId,
  reason,
  editable,
  durations,
  showDeleteNoteIcon,
  reworkedAddNotes,
  notesTitle,
  notesContainerId,
}) => {
  const {
    response,
    notes,
    page,
    pageSize,
    setPageNumber,
    setPageSize,
    isLoading,
  } = useNotes({
    subject,
    subjectType,
    providerId,
  });

  const paging = get(response, 'data.paging', {});

  return (
    <div>
      {reworkedAddNotes ? (
        <div>
          <div id={notesContainerId} data-test-element={notesContainerId}>
            {
              isLoading ? (
                <div className="my-10">
                  <Spinner text="" show={isLoading} />
                </div>
              ) : null
            }
            {
              isEmpty(notes) && (<NoNotes subjectType={subjectType} />)
            }
            {
              notes.map((n) => (
                <div className="mb-4" data-test-element={`note-${n.id}`}>
                  <Note
                    currentEmployeeId={currentEmployeeId}
                    Title={n.Title}
                    duration={get(find(durations, (d) => Number(d.value) === n.duration), 'display_name')}
                    interactionDate={n.interactionDate}
                    interactionType={n.interactionType}
                    category={n.category}
                    text={n.text}
                    details={n.details}
                    note={n}
                    reason={reason}
                    visibility={n.visibility}
                    showDeleteNoteIcon={showDeleteNoteIcon}
                  />
                </div>
              ))
            }
          </div>
          <div className="mb-16">
            <Pagination
              pageNumber={page}
              pageSize={pageSize}
              totalItemCount={paging.total_count}
              totalPageCount={paging.total_pages}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
            />
          </div>
          <div className="connector" />
        </div>
      ) : (
        <div>
          <NotesHeader
            editable={editable}
            onAddNote={onAddNote}
            notesTitle={notesTitle}
          />
          <div id={notesContainerId} data-test-element={notesContainerId}>
            {
              isLoading ? (
                <div className="my-10">
                  <Spinner text="" show={isLoading} />
                </div>
              ) : null
            }
            {
              isEmpty(notes) && (<NoNotes subjectType={subjectType} />)
            }
            {
              notes.map((n) => (
                <div className="mb-4" data-test-element={`note-${n.id}`}>
                  <Note
                    currentEmployeeId={currentEmployeeId}
                    Title={n.Title}
                    duration={get(find(durations, (d) => Number(d.value) === n.duration), 'display_name')}
                    interactionDate={n.interactionDate}
                    interactionType={n.interactionType}
                    category={n.category}
                    text={n.text}
                    details={n.details}
                    note={n}
                    reason={reason}
                    visibility={n.visibility}
                    showDeleteNoteIcon={showDeleteNoteIcon}
                  />
                </div>
              ))
            }
          </div>
          <div className="mb-16">
            <Pagination
              pageNumber={page}
              pageSize={pageSize}
              totalItemCount={paging.total_count}
              totalPageCount={paging.total_pages}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
            />
          </div>
          <div className="connector" />
        </div>
      )}
    </div>
  );
};

Notes.propTypes = {
  currentEmployeeId: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  subjectType: PropTypes.string.isRequired,
  providerId: PropTypes.string.isRequired,
  onAddNote: PropTypes.func.isRequired,
  reason: PropTypes.string,
  editable: PropTypes.bool,
  durations: PropTypes.array.isRequired,
  showDeleteNoteIcon: PropTypes.bool.isRequired,
  reworkedAddNotes: PropTypes.bool,
  notesTitle: PropTypes.string,
  notesContainerId: PropTypes.string,
};

Notes.defaultProps = {
  reason: null,
  editable: true,
  reworkedAddNotes: false,
  notesTitle: 'Notes',
  notesContainerId: 'notes-container',
};

const mapStateToProps = (state, ownProps) => ({
  onAddNote: ownProps.onAddNote,
  providerId: ownProps.providerId,
  subject: ownProps.subject,
  subjectType: ownProps.subjectType,
  durations: get(state, 'session.enums.time.duration', []),
  currentEmployeeId: get(state, 'globalState.currentEmployee.id', ''),
  showDeleteNoteIcon: hasDeleteNoteIcon(state),
});

export default connect(mapStateToProps)(Notes);
