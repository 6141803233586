import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Stepper from 'common/Stepper/Stepper';
import {
  BaseCard,
  BaseCardBody,
} from '@unite-us/ui';
import _ from 'lodash';
import { browserHistory } from 'common/utils/browserHistory';

function getStepIndex(path) {
  const viewFromPath = path.replace('/contacts/new/', '');
  let index;

  switch (viewFromPath) {
    case 'search':
    case 'confirm':
      index = 0;
      break;

    case 'review':
    case 'add-contact-information':
      index = 1;
      break;

    default:
      index = 0;
  }

  return index;
}

class NewContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepperConfig: [
        {
          content: props.children,
          onStepClick: () => browserHistory.push('/contacts/new/search'),
          stepDisabled: false,
          title: 'Search Records',
        },
        {
          content: props.children,
          onStepClick: () => browserHistory.push('/contacts/new/review'),
          stepDisabled: (currentStep) => currentStep < 1,
          title: 'Contact Information',
        },
      ],
      currentStep: getStepIndex(props.location.pathname),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.children, this.props.children)) {
      const newStepperConfig = [...this.state.stepperConfig];
      const currentStep = getStepIndex(nextProps.location.pathname);
      newStepperConfig[currentStep].content = nextProps.children;

      this.setState({ stepperConfig: newStepperConfig });
    }

    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.setState(
        { currentStep: getStepIndex(nextProps.location.pathname) },
        () => this.stepper.setCurrentStep(this.state.currentStep),
      );
    }
  }

  render() {
    return (
      <div className="contacts-new container">
        <BaseCard className="card-container">
          <BaseCardBody>
            <Stepper
              initialStep={this.state.currentStep}
              ref={(el) => { this.stepper = el; }}
              steps={this.state.stepperConfig}
            />
          </BaseCardBody>
        </BaseCard>
      </div>
    );
  }
}

NewContact.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
};


export default NewContact;
