// Library imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Spinner } from 'common/spinners';

// action imports
import { fetchGroup } from 'actions/Group';
import { fetchNetworkServiceTypes } from 'actions/ServiceType/Network';
import {
  searchNetworkGroupsByReferralScopes,
  fetchNetworkGroupsByReferralScope,
  searchNetworkBrowseGroups,
} from 'actions/Group/Network';
import { sendReferral } from 'actions/Referral/Contact/Group';
import { setDashboardRefetch } from 'actions/Dashboard';

// util imports
import {
  canReferOutOfNetwork,
} from 'src/components/Referrals/utils/scopes';
import {
  filterBrowseGroups,
  filterBrowseGroupIds,
  addGroupFields,
  getProgramOptions,
} from 'src/components/Referrals/ReferralGroupsPrograms/utils';
import {
  canSearchGroups,
  getFieldValue,
  getDeterminantNetworkId,
} from 'src/components/Referrals/utils/form';
import { getGroup } from 'common/utils/stateHelpers';
import {
  hasPaymentsUserAccess,
  includePathwaysServices,
  paginateNetworkGroups,
  showProgramStatusToggle,
  programBasedSearchSelector,
  hint716SearchNetworkHubSupportPremiumSelector,
} from 'src/common/utils/FeatureFlags/flags';
import callOrLog from 'src/common/utils/callOrLog';
import { Serializer } from '@unite-us/client-utils';
import { getOurCoordinates, getClientAddress, getClientCenter } from 'src/components/Browse/utils/address';
import $scripts from 'scriptjs';
import { getEmployee } from 'src/components/Employee/employeeGetters';
import { getNetworkScopeFilterSearchParams } from 'src/components/Network/Groups/utils';
import { fetchPosition, fetchIpPosition } from 'src/common/utils/Session/actions';
import { filterGroupsReceivingReferrals } from 'src/components/Referrals/ReferralFormFields/EditReferralDetails/utils';
import { requiredMapScript } from 'src/components/Browse/Map/utils';
import { removeAllGroupFields } from 'src/components/Referrals/ReferralGroupsPrograms/utils/removeGroupFields';

// constants
import { REFERRAL } from 'common/utils/EventTracker/utils/eventConstants';
import { CREATE_REFERRAL_FORM } from '../../constants';

export default function (ComposedComponent) {
  class ReferralFormBaseTemplate extends Component {
    constructor(props) {
      super(props);

      this.state = {
        query: '',
        isFetchingNetworkGroups: false,
        isFetchingGroup: true,
        isFetchingCoordinates: true,
        // Default to true to prevent immediate render -> line:549.
        showBrowse: false,
        canSearch: false,
        groupsOptionType: '',
        feeScheduleProgramId: null,
        referralScope: {},
        suggestedGroups: [],
        browseMapGroups: [],
        browseMapPrograms: [],
        browseMapOONPrograms: [],
        isFetchingOONGroups: false,
        isFetchingBrowsePrograms: false,
        originCoordinates: [],
        oonGroups: [],
        showOONCaseContext: false,
      };

      this.buildParams = this.buildParams.bind(this);
      this.fetchSingleGroup = this.fetchSingleGroup.bind(this);
      this.onChangeReferredByNetwork = this.onChangeReferredByNetwork.bind(this);
      this.removeSelectedBrowseGroup = this.removeSelectedBrowseGroup.bind(this);
      this.triggerBrowse = this.triggerBrowse.bind(this);
      this.addSelectedBrowseGroups = this.addSelectedBrowseGroups.bind(this);
      this.setCanSearch = this.setCanSearch.bind(this);
      this.debouncedSearchNetworkGroups = _.debounce(this.debouncedSearchNetworkGroups.bind(this), 400);
      this.setFeeScheduleProgramId = this.setFeeScheduleProgramId.bind(this);
      this.setGroups = this.setGroups.bind(this);
      this.setGroupsPagination = this.setGroupsPagination.bind(this);
      this.paginateSearchNetworkGroups = this.paginateSearchNetworkGroups.bind(this);
      this.returnAllGroups = this.returnAllGroups.bind(this);
      this.searchNetworkGroups = this.searchNetworkGroups.bind(this);
      this.getCenterCoordinates = this.getCenterCoordinates.bind(this);
      this.formatCoordinatesForFetch = this.formatCoordinatesForFetch.bind(this);
      this.updateSelectedPrograms = this.updateSelectedPrograms.bind(this);
      this.updateGroupsOptionType = this.updateGroupsOptionType.bind(this);
    }

    componentDidMount() {
      this.fetchSingleGroup();
    }

    componentDidUpdate(_prevProps, prevState) {
      const { fields } = this.props;
      const { feeScheduleProgramId } = this.state;

      if (feeScheduleProgramId !== prevState.feeScheduleProgramId) {
        const referredToNetworkId = getDeterminantNetworkId(fields);
        const referredByNetworkId = getFieldValue(fields.referred_by_network).id;

        this.paginateSearchNetworkGroups({
          showOONCaseContext: false,
          referredByNetworkId,
          referredToNetworkId,
          type: 'create',
        });
      }
    }

    onChangeReferredByNetwork(selected) {
      const { fields, referralScopes } = this.props;

      if (!canReferOutOfNetwork(referralScopes, _.get(selected, 'value.id', ''))) {
        fields.referred_to_network.onChange();
      }
    }

    setCanSearch(value) {
      this.setState({ canSearch: value });
    }

    setFeeScheduleProgramId(feeScheduleProgramId) {
      if (feeScheduleProgramId !== this.state.feeScheduleProgramId) {
        this.setState({ feeScheduleProgramId });
        if (this.props.fields?.authorizationRequest) {
          Object.values(this.props.fields.authorizationRequest)
          .forEach((field) => {
            field.onChange(null);
            this.props.untouch(field.name);
          });
        }
      }
    }

    setGroupsPagination({
      suggestedGroups = this.state.suggestedGroups,
      oonGroups = this.state.oonGroups,
      nationalStateSuggestedGroups = this.state.nationalStateSuggestedGroups,
      nationalOONGroups = this.state.nationalOONGroups,
      groupsOptionType,
      filterByServiceArea,
    }) {
      const { fields, useReferralToggle, isProgramBasedSearch } = this.props;
      const serviceType = getFieldValue(fields.service_type);

      let filteredSuggestedGroups = suggestedGroups;

      if (useReferralToggle) {
        filteredSuggestedGroups = filterGroupsReceivingReferrals(
          suggestedGroups,
          serviceType,
          this.props.usePaymentsUserRole,
        );
      }

      const isInNetwork = groupsOptionType === 'in-network';

      const onGroupsSet = (groupOptions) => {
        this.setState({
          isFetchingNetworkGroups: false,
          isFetchingBrowsePrograms: false,
          ...(!isInNetwork && { isFetchingOONGroups: false }),
        });
        if (isProgramBasedSearch) this.updateSelectedPrograms(groupOptions);
      };

      if (filterByServiceArea) {
        if (isInNetwork) {
          this.setState({ nationalStateSuggestedGroups }, () => onGroupsSet(nationalStateSuggestedGroups));
        } else {
          this.setState({ nationalOONGroups }, () => onGroupsSet(nationalOONGroups));
        }
      } else if (isInNetwork) {
        this.setState({ suggestedGroups: filteredSuggestedGroups }, () => onGroupsSet(filteredSuggestedGroups));
      } else {
        this.setState({ oonGroups }, () => onGroupsSet(oonGroups));
      }
    }

    setGroups(suggestedGroups, oonGroups) {
      this.setState({ suggestedGroups, oonGroups }, () => {
        this.setState({ isFetchingNetworkGroups: false });
      });
    }

    getCenterCoordinates() {
      const {
        contact = {},
        employee,
        group,
        geoCoordinates,
        ipCoordinates,
      } = this.props;

      const scriptsEmpty = () => _.isEmpty(requiredMapScript);

      // If the scripts are empty, we still want to set state with coordinates.
      if (scriptsEmpty()) {
        const clientCenter = getClientCenter(contact);
        const ourCoordinates = getOurCoordinates({
          employee,
          currentUserGroup: group,
          geoCoordinates,
          ipCoordinates,
        });
        const center = clientCenter || ourCoordinates || {};
        this.setState({
          originCoordinates: _.isEmpty(center) ? [] : [center.lat, center.lng],
          isFetchingCoordinates: false,
        });
        return;
      }
      // fetch client coordinates from Google.
      // if no client coordiantes exist, we set state with "our Coordinates".

      $scripts(requiredMapScript.scripts, () => {
        this.setState({ ...this.state, scriptsLoaded: true }, async () => {
          // try getting a valid contact address if there is a contact in props
          const clientAddress = await (!_.isEmpty(contact.addresses) && getClientAddress(contact));

          // get the coordinates of the current user, current group;
          const ourCoordinates = getOurCoordinates({
            employee,
            currentUserGroup: group,
            geoCoordinates,
            ipCoordinates,
          });

          // check to see if there is a valid contact address that has latitude and longitude
          const shouldUseContactAddress = !_.isEmpty(contact.addresses) &&
            !_.isError(clientAddress) &&
            clientAddress !== 'ZERO_RESULTS';

          const clientCoordinates = shouldUseContactAddress ?
            _.get(clientAddress, 'latLng', undefined) :
            undefined;

          const center = clientCoordinates || ourCoordinates || {};

          this.setState({
            originCoordinates: _.isEmpty(center) ? [] : [center.lat, center.lng],
            isFetchingCoordinates: false,
          });
        });
      });
    }

    formatCoordinatesForFetch() {
      const { originCoordinates } = this.state;
      const coordinatesForFetch = !_.isEmpty(originCoordinates) ?
        {
          lat: _.first(originCoordinates),
          lon: _.last(originCoordinates),
        } :
        [];

      return coordinatesForFetch;
    }

    buildParams({
      groupsOptionType, text, filterByServiceArea, state, query,
    }) {
      const {
        contact: { id: contactId },
        employeeId,
        fields,
        group,
        groupId,
        includePathways,
        pageLimit,
        serviceTypes,
        usePaymentsUserRole,
        isProgramBasedSearch,
        hint716SearchNetworkHubSupportPremium,
      } = this.props;
      const { feeScheduleProgramId } = this.state;
      const filterByFspId = feeScheduleProgramId ? { feeScheduleProgramId } : {};

      const referredByNetworkId = _.get(fields, 'referred_by_network.value.id', '');
      const referredToNetworkId = _.get(fields, 'referred_to_network.value.id');
      const permitted_networks = referredToNetworkId ? [referredToNetworkId] : [referredByNetworkId];
      const serviceType = getFieldValue(fields.service_type);
      const coordinatesForFetch = this.formatCoordinatesForFetch();
      const referable = { referable: { person: contactId, employee: employeeId } };

      const reqParams = {
        q: {
          coordinates: coordinatesForFetch,
          force_sort_by_name: false,
          include_home_groups: false,
          permitted_networks,
          service_type_ids: [serviceType.id],
          ...referable,
          ...(this.props.referral.id && { sensitive: false }),
          ...getNetworkScopeFilterSearchParams([groupsOptionType]),
          ...(text && { text }), // Text param will only exist in debounced searches
          ...(usePaymentsUserRole && groupsOptionType === 'in-network' && { person: contactId }),
          ...(usePaymentsUserRole && { include_payments: true }),
          ...(includePathways && { include_pathways: true }),
          ...(filterByServiceArea && { filterByServiceArea }),
          ...(state && { state }),
          ...(query && { query }),
          ...filterByFspId,
        },
        page: 1,
        per: pageLimit,
      };

      return {
        browseMapView: false,
        currentUserGroup: group,
        groupId,
        networkId: referredByNetworkId,
        reqParams,
        serviceTypes,
        usePaymentsUserRole,
        isProgramBasedSearch,
        hint716SearchNetworkHubSupportPremium,
      };
    }

    // A debounced call for searched groups.
    debouncedSearchNetworkGroups(search, groupsOptionType) {
      const { fields } = this.props;
      const { browseMapGroups, browseMapPrograms, browseMapOONPrograms } = this.state;
      const isOON = groupsOptionType === 'out-of-network';
      const browseMapProgramIds = (
        isOON ? browseMapOONPrograms : browseMapPrograms
      ).map((program) => program.id);
      const canSearch = canSearchGroups(fields);

      this.setState({ canSearch });
      if (canSearch) {
        const params = this.buildParams({ groupsOptionType, text: search });
        this.props.searchNetworkBrowseGroups({ ...params, isOON, browseMapProgramIds })
          .then((networkGroups) => {
            /* filteredGroups
              * Only include groups that includes the search text in its name.
            */

           // add groups for programs selected from custom search
            const selectedGroups = [];
            fields.selected.forEach((sel) => {
              const selectedGroup = sel.program?.value?.relationships?.provider?.data;
              if (!selectedGroup) return;

              const matchingGroup = networkGroups.some((group) => (
                group.programs?.some((program) => (
                  program.id === sel.program.value.id
                ))
              ));
              if (!matchingGroup) {
                selectedGroups.push(selectedGroup);
              }
            });

            const filteredGroups = _.filter(networkGroups, ({ name }) => (
              _.includes(_.toLower(name), _.toLower(search))
            ));

            /* browseMapGroupsToAdd
              * If we search for specific groups, only show those groups.
              * Otherwise, onBlur, append the selected browse map groups to the dropdown list.
            */
            const browseMapGroupsToAdd = search.length ? [] : browseMapGroups;

            const allGroups = [...filteredGroups, ...browseMapGroupsToAdd, ...selectedGroups];

            if (filteredGroups.length) {
              if (groupsOptionType === 'in-network') {
                this.setGroupsPagination({ suggestedGroups: allGroups, groupsOptionType });
              } else {
                this.setGroupsPagination({ oonGroups: allGroups, groupsOptionType });
              }
            }
          });
      }
    }

    // This is the initial request for 50 closest groups.
    paginateSearchNetworkGroups(options, cb) {
      const { fields, referralScopes, isProgramBasedSearch } = this.props;
      const {
        showOONCaseContext,
        referredByNetworkId,
        referredToNetworkId,
        filterByServiceArea,
        state,
        query,
      } = options;
      const { browseMapPrograms, browseMapOONPrograms } = this.state;
      const canSearch = canSearchGroups(fields);
      const groupsOptionType = showOONCaseContext ? 'out-of-network' : 'in-network';

      const browseMapProgramIds = (
        showOONCaseContext ? browseMapOONPrograms : browseMapPrograms
      ).map((program) => program.id);

      this.setState({ canSearch, groupsOptionType });

      if (canSearch) {
        const params = this.buildParams({
          groupsOptionType, filterByServiceArea, state, query,
        });

        const referralScope = _.find(
          referralScopes,
          (scope) => (
            scope.network.id === referredByNetworkId &&
            scope.permitted_network.id === referredToNetworkId
          ),
        ) || {};

        this.setState({
          query,
          isFetchingNetworkGroups: true,
          isFetchingOONGroups: groupsOptionType === 'out-of-network',
          referralScope,
        }, () => {
          const isOON = groupsOptionType === 'out-of-network';

          return this.props.searchNetworkBrowseGroups({ ...params, isOON, browseMapProgramIds })
            .then((networkGroups) => {
              if (isProgramBasedSearch) {
                // eslint-disable-next-line no-param-reassign
                networkGroups = _.uniqBy(networkGroups, 'programs[0].id');
              }
              if (filterByServiceArea) {
                if (groupsOptionType === 'in-network') {
                  if (cb) cb(networkGroups);

                  this.setGroupsPagination({
                    nationalStateSuggestedGroups: networkGroups, groupsOptionType, filterByServiceArea,
                  });
                } else {
                  this.setGroupsPagination({ nationalOONGroups: networkGroups, groupsOptionType, filterByServiceArea });
                }
              } else if (groupsOptionType === 'in-network') {
                if (cb) cb(networkGroups);

                this.setGroupsPagination({ suggestedGroups: networkGroups, groupsOptionType });
              } else {
                this.setGroupsPagination({ oonGroups: networkGroups, groupsOptionType });
              }
            });
        });
      }
    }

    fetchSingleGroup() {
      const { groupId } = this.props;

      this.props.fetchGroup(groupId).then(() => {
        this.setState({ isFetchingGroup: false }, () => {
          this.getCenterCoordinates();
        });
      });
    }

    searchNetworkGroups(options, cb) {
      this.returnAllGroups(options, cb).then(({ inNetworkGroups, oonGroups }) => {
        this.setGroups(inNetworkGroups, oonGroups);
      });
    }

    returnAllGroups(options = {}, cb) {
      const {
        fields,
        referral,
        referralScopes,
        serviceTypes,
        useReferralToggle,
      } = this.props;
      const { referredByNetworkId, type } = options;
      const canSearch = canSearchGroups(fields);
      this.setState({ canSearch });

      return new Promise((resolve) => {
        if (canSearch) {
          const referredToNetworkId = getDeterminantNetworkId(fields);

          const referralScope = _.find(
            referralScopes,
            (scope) => (
              scope.network.id === referredByNetworkId &&
              scope.permitted_network.id === referredToNetworkId
            ),
          ) || {};

          const serviceType = getFieldValue(fields.service_type);

          const isOON = (this.state.showOONCaseContext || options.showOONCaseContext) === true;
          this.setState({ isFetchingNetworkGroups: true, referralScope }, () => {
            const params = type === 'create' ?
              this.createParams({
                referredByNetworkId,
                referredToNetworkId,
                referralScope,
                serviceType,
                serviceTypes,
                isOON,
              }) :
              this.sendParams({
                referralScope,
                referral,
                referredByNetworkId,
                referredToNetworkId,
                serviceType,
                serviceTypes,
                isOON,
              });

            fetchNetworkGroupsByReferralScope(params).then(({ oonGroups, suggestedGroups }) => {
              let inNetworkGroups = suggestedGroups;

              if (useReferralToggle) {
                inNetworkGroups = filterGroupsReceivingReferrals(suggestedGroups, serviceType);
              }

              cb(inNetworkGroups);
              resolve({ oonGroups, inNetworkGroups });
            });
          });
        } else {
          resolve({ inNetworkGroups: this.state.suggestedGroups });
        }
      });
    }

    createParams({
      referredByNetworkId, referredToNetworkId, referralScope, serviceType, isOON,
    }) {
      const {
        groupId, pageLimit, serviceTypes,
      } = this.props;

      const { feeScheduleProgramId } = this.state;
      const filterByFspId = feeScheduleProgramId ? { feeScheduleProgramId } : {};

      return {
        addressBookStatuses: ['published'],
        groupId,
        referredByNetworkId,
        referredToNetworkId,
        pageLimit,
        scope: referralScope.referral_scope,
        searchNetworkGroups: this.props.searchNetworkGroupsByReferralScopes,
        serviceTypeId: serviceType.id,
        serviceTypes,
        isOON,
        ...filterByFspId,
      };
    }

    sendParams({
      referralScope, referral, referredByNetworkId, referredToNetworkId, serviceType, isOON,
    }) {
      const { groupId, pageLimit } = this.props;
      const { feeScheduleProgramId } = this.state;
      const filterByFspId = feeScheduleProgramId ? { feeScheduleProgramId } : {};

      return {
        addressBookStatuses: ['published'],
        groupId,
        pageLimit,
        referralSenderId: _.wget(referral, 'referred_by_group.id'),
        referredByNetworkId,
        referredToNetworkId,
        scope: referralScope.referral_scope,
        searchNetworkGroups: this.props.searchNetworkGroupsByReferralScopes,
        serviceTypeId: serviceType.id,
        isOON,
        ...filterByFspId,
      };
    }

    addSelectedBrowseGroups(groups) {
      const { canPaginateNetworkGroups, fields } = this.props;
      const { groupsOptionType, suggestedGroups, oonGroups } = this.state;
      const scopedField = _.isEqual(groupsOptionType, 'in-network') ? fields.selected : fields.oonCase.selected;
      const { toRemoveIds, toAddIds } = filterBrowseGroupIds(groups, scopedField);
      const newGroups = filterBrowseGroups({
        groups,
        toRemoveIds,
        toAddIds,
        groupField: scopedField,
      });

      if (canPaginateNetworkGroups) {
        // Sort the groups added from browse map, so they are in the list in the correct order.
        const groupsWithDistanceSorted = newGroups.sort((a, b) => a.distance - b.distance);
        const existingGroups = groupsOptionType === 'in-network' ? suggestedGroups : oonGroups;

        // Filter out the selected browse map groups that already exist in the original "closest 50" groups.
        // This filter is needed so that we dont add duplicate groups to the dropdown.
        const filteredBrowseMapGroups = _.filter(groupsWithDistanceSorted, (group) => (
          !_.some(existingGroups, ({ id }) => group.id === id)));

        if (filteredBrowseMapGroups.length) {
          if (groupsOptionType === 'in-network') {
            this.setState({
              suggestedGroups: [...this.state.suggestedGroups, ...filteredBrowseMapGroups],
              browseMapGroups: filteredBrowseMapGroups,
            });
          } else {
            this.setState({
              oonGroups: [...this.state.oonGroups, ...filteredBrowseMapGroups],
              browseMapGroups: filteredBrowseMapGroups,
            });
          }
        }
        addGroupFields(groupsWithDistanceSorted, scopedField);
      } else {
        addGroupFields(newGroups, scopedField);
      }
    }

    addSelectedBrowsePrograms(programs, isOON) {
      const groupsOptionType = isOON ? 'out-of-network' : 'in-network';
      this.setState({
        ...(
          isOON ? { browseMapOONPrograms: programs } : { browseMapPrograms: programs }
        ),
        isFetchingBrowsePrograms: true,
      }, () => {
        this.debouncedSearchNetworkGroups('', groupsOptionType);
      });
    }

    removeSelectedBrowseGroup(groupsOptionType) {
      const { browseMapGroups } = this.state;
      const { fields } = this.props;

      /*
        When the user removes a selected browse map group, we want to make sure it doesnt
        render in along with the closest 50 groups in dropdowns.
      */
      if (browseMapGroups.length) {
        const scopedNetworkGroups = groupsOptionType === 'in-network' ? 'suggestedGroups' : 'oonGroups';
        const scopedField = groupsOptionType === 'in-network' ? fields.selected : fields.oonCase.selected;

        // Filtering the browseMapGroups by those that are in the selected fields so if a group is deselected,
        // it is also removed from the list of dropdown groups.
        const filteredBrowseMapGroups = browseMapGroups.filter((bmg) => (
          _.some(scopedField, { group: { value: { id: bmg.id } } })
        ));

        this.setState({
          [scopedNetworkGroups]: [
            ...this.state[scopedNetworkGroups],
            ...filteredBrowseMapGroups,
          ],
          browseMapGroups: filteredBrowseMapGroups,
        });
      }
    }

    triggerBrowse(groupsOptionType) {
      const {
        fields: {
          selected,
          oonCase: {
            selected: oonSelected,
          },
        },
        contact,
        isProgramBasedSearch,
      } = this.props;

      if (isProgramBasedSearch) {
        const {
          selectedProgramsContext: {
            selectedPrograms,
            dispatchAddProgram,
            dispatchRemoveAllPrograms,
          },
        } = this.props;

        const isOON = groupsOptionType === 'out-of-network';
        const selectedProgramsFields = isOON ? oonSelected : selected;

        if (this.state.showBrowse) {
          // close browse map
          removeAllGroupFields(selectedProgramsFields);

          this.addSelectedBrowsePrograms(selectedPrograms, isOON);
        } else {
          // open browse map
          dispatchRemoveAllPrograms();

          selectedProgramsFields.forEach(({
            program: { value: { id, name } },
            group: { value: { id: providerId } },
          }) => {
            if (id) {
              dispatchAddProgram({ id, name, providerId });
            }
          });
        }
        this.setState({
          showBrowse: !this.state.showBrowse,
          showOONCaseContext: isOON,
        });
      } else {
        this.setState({
          showBrowse: !this.state.showBrowse,
          groupsOptionType,
        });
      }

      const eventPayload = Serializer.build({ contact });
      callOrLog(() => this.context.eventTracker(REFERRAL.browseMapClicked, { contact: eventPayload }));
    }

    updateSelectedPrograms(groupOptions) {
      const {
        selectedProgramsContext: {
          selectedPrograms,
        },
      } = this.props;
      const { originCoordinates, showOONCaseContext } = this.state;

      selectedPrograms.forEach((program, index) => {
        const provider = _.find(groupOptions, { programs: [{ id: program.id }] });
        const selectedProgramIds = _.map(selectedPrograms, (p) => p.id);

        if (provider && program) {
          const parsedProgram = getProgramOptions({ groups: [provider], selectedProgramIds, originCoordinates });
          const matchedProgram = _.head(parsedProgram);
          this.props.updateSelectedField({
            provider, program: matchedProgram, index, isOON: showOONCaseContext,
          });
        }
      });
    }

    updateGroupsOptionType(OONContext) {
      const groupsOptionType = OONContext ? 'out-of-network' : 'in-network';
      this.setState({ groupsOptionType });
    }

    render() {
      const { canPaginateNetworkGroups, group } = this.props;
      const {
        canSearch,
        groupsOptionType,
        isFetchingGroup,
        isFetchingNetworkGroups,
        isFetchingCoordinates,
        isFetchingOONGroups,
        isFetchingBrowsePrograms,
        oonGroups,
        nationalOONGroups,
        referralScope,
        suggestedGroups,
        nationalStateSuggestedGroups,
        originCoordinates,
        query,
        feeScheduleProgramId,
      } = this.state;

      if (_.isEmpty(group) || isFetchingCoordinates) {
        return <Spinner />;
      }

      return (
        <ComposedComponent
          isFetchingBrowsePrograms={isFetchingBrowsePrograms}
          addSelectedBrowseGroups={this.addSelectedBrowseGroups}
          canSearch={canSearch}
          debouncedSearchNetworkGroups={this.debouncedSearchNetworkGroups}
          fetchNetworkServiceTypes={this.props.fetchNetworkServiceTypes}
          fetchSingleGroup={this.fetchSingleGroup}
          isFetchingGroup={isFetchingGroup}
          isFetchingOONGroups={isFetchingOONGroups}
          query={query}
          feeScheduleProgramId={feeScheduleProgramId}
          setFeeScheduleProgramId={this.setFeeScheduleProgramId}
          isFetchingNetworkGroups={isFetchingNetworkGroups}
          groupsOptionType={groupsOptionType}
          removeSelectedBrowseGroup={this.removeSelectedBrowseGroup}
          nationalOONGroups={nationalOONGroups}
          nationalStateSuggestedGroups={nationalStateSuggestedGroups}
          onChangeReferredByNetwork={this.onChangeReferredByNetwork}
          oonGroups={oonGroups}
          originCoordinates={originCoordinates}
          referralScope={referralScope}
          returnAllGroups={this.returnAllGroups}
          paginateSearchNetworkGroups={this.paginateSearchNetworkGroups}
          searchNetworkGroups={
            canPaginateNetworkGroups ? this.paginateSearchNetworkGroups : this.searchNetworkGroups
          }
          searchNetworkGroupsByReferralScopes={this.props.searchNetworkGroupsByReferralScopes}
          setCanSearch={this.setCanSearch}
          setGroups={this.setGroups}
          showBrowse={this.state.showBrowse}
          suggestedGroups={suggestedGroups}
          triggerBrowse={this.triggerBrowse}
          updateSelectedPrograms={this.updateSelectedPrograms}
          updateGroupsOptionType={this.updateGroupsOptionType}
          {...this.props}
        />
      );
    }
  }

  ReferralFormBaseTemplate.contextTypes = {
    eventTracker: PropTypes.func.isRequired,
  };

  ReferralFormBaseTemplate.propTypes = {
    canPaginateNetworkGroups: PropTypes.bool.isRequired,
    contact: PropTypes.object.isRequired,
    currentUserGroup: PropTypes.object,
    employee: PropTypes.object.isRequired,
    employeeId: PropTypes.string.isRequired,
    fields: PropTypes.shape({
      authorizationRequest: PropTypes.object,
      auto_recallable: PropTypes.object.isRequired,
      documents: PropTypes.object.isRequired,
      notes: PropTypes.object.isRequired,
      oonCase: PropTypes.shape({
        selected: PropTypes.array.isRequired,
      }).isRequired,
      referred_by_network: PropTypes.object.isRequired,
      referred_to_network: PropTypes.object.isRequired,
      service_type: PropTypes.object.isRequired,
      selected: PropTypes.arrayOf(PropTypes.shape({
        group: PropTypes.object.isRequired,
        program: PropTypes.object.isRequired,
      }).isRequired),
    }).isRequired,
    fetchGroup: PropTypes.func.isRequired,
    fetchPosition: PropTypes.func.isRequired,
    fetchIpPosition: PropTypes.func.isRequired,
    geoCoordinates: PropTypes.object.isRequired,
    includePathways: PropTypes.bool.isRequired,
    ipCoordinates: PropTypes.object.isRequired,
    fetchNetworkServiceTypes: PropTypes.func.isRequired,
    group: PropTypes.object.isRequired,
    groupId: PropTypes.string.isRequired,
    pageLimit: PropTypes.number.isRequired,
    referral: PropTypes.object,
    referralScopes: PropTypes.array.isRequired,
    searchNetworkGroupsByReferralScopes: PropTypes.func.isRequired,
    searchNetworkBrowseGroups: PropTypes.func.isRequired,
    sendReferral: PropTypes.func.isRequired,
    serviceTypes: PropTypes.array.isRequired,
    setDashboardRefetch: PropTypes.func.isRequired,
    untouch: PropTypes.func,
    updateSelectedField: PropTypes.func.isRequired,
    useReferralToggle: PropTypes.bool.isRequired,
    usePaymentsUserRole: PropTypes.bool.isRequired,
    isProgramBasedSearch: PropTypes.bool.isRequired,
    selectedProgramsContext: PropTypes.shape({
      selectedPrograms: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })).isRequired,
      dispatchRemoveAllPrograms: PropTypes.func.isRequired,
      dispatchAddProgram: PropTypes.func.isRequired,
      dispatchRemoveProgram: PropTypes.func.isRequired,
    }).isRequired,
    showOONCaseContext: PropTypes.bool,
    hint716SearchNetworkHubSupportPremium: PropTypes.bool.isRequired,
  };

  ReferralFormBaseTemplate.defaultProps = {
    referral: {},
    currentUserGroup: {},
    showOONCaseContext: false,
    untouch: () => {},
  };

  function mapStateToProps(state) {
    const groupId = _.get(state, 'session.groupId');
    const group = getGroup(state, groupId) || {};
    const employeeId = _.get(state, 'globalState.currentEmployee.id');
    const serviceTypes = _.get(state, 'session.globals.service_types', []);
    const pageLimit = 50;
    const useReferralToggle = showProgramStatusToggle(state);
    const employee = getEmployee({ state });
    const canPaginateNetworkGroups = paginateNetworkGroups(state);
    const geoCoordinates = _.wget(state, 'session.position.geoCoordinates', {});
    const ipCoordinates = _.wget(state, 'session.position.ipCoordinates', {});
    const showOONCaseContext = _.get(state, 'showOONCaseContext');

    const isProgramBasedSearch = programBasedSearchSelector(state);
    return {
      canPaginateNetworkGroups,
      employee,
      employeeId,
      groupId,
      group,
      geoCoordinates,
      includePathways: includePathwaysServices(state),
      ipCoordinates,
      pageLimit,
      serviceTypes,
      showOONCaseContext,
      useReferralToggle,
      usePaymentsUserRole: hasPaymentsUserAccess(state),
      isProgramBasedSearch,
      hint716SearchNetworkHubSupportPremium: hint716SearchNetworkHubSupportPremiumSelector(state),
    };
  }

  function mapDispatchToProps(dispatch, ownProps) {
    const updateSelectedField = ({
      provider, program, index, isOON,
    }) => {
      const formName = ownProps.form ?? ownProps.formName;
      const isOONCase = isOON ? '.oonCase' : '';
      let prefix = '';
      if (formName === CREATE_REFERRAL_FORM) {
        prefix = `services[${ownProps.currentServiceTypeIndex}]`;
      }

      dispatch(
        change(
          formName,
          `${prefix}${isOONCase}.selected[${index}].program`,
          program,
        ),
      );
      dispatch(
        change(
          formName,
          `${prefix}${isOONCase}.selected[${index}].group`,
          provider,
        ),
      );
    };

    return {
      ...bindActionCreators({
        fetchGroup,
        fetchNetworkServiceTypes,
        fetchPosition,
        fetchIpPosition,
        searchNetworkGroupsByReferralScopes,
        searchNetworkBrowseGroups,
        sendReferral,
        setDashboardRefetch,
        fetchNetworkGroupsByReferralScope,
      }, dispatch),
      updateSelectedField,
      dispatch,
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(ReferralFormBaseTemplate);
}
