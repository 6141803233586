import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { validateReduxForm } from 'common/form';
import { Serializer } from '@unite-us/client-utils';
import { Spinner } from 'common/spinners';
import findCurrentGroup from 'common/utils/findCurrentGroup';
import FormFooter from 'common/form/FormButtons/components/FormFooter';
import { coordinationCenterNetwork, isCoordinationCenter } from 'common/utils/Group';
import { setExportsRefetch } from 'actions/Export';
import { createExport } from 'actions/Export/Group';
import callOrLog from 'src/common/utils/callOrLog';
import { REPORTS } from 'common/utils/EventTracker/utils/eventConstants';
import moment from 'moment';
import {
  EXPORTS_CREATE_FORM,
} from '../constants';
import ExportCreateFields from './ExportCreateFields';
import {
  filterExportTypes,
  targetSelectionOptions,
} from '../utils';

export class ExportsCreateForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onSubmit(values) {
    const { currentEmployee } = this.props;
    const params = {
      export_type: values.type,
      state: 'requested',
      details: {
        start_date: values.start_at,
        end_date: values.end_at || moment().format('YYYY-MM-DD'),
      },
      requester: currentEmployee.id,
    };
    callOrLog(() => this.context.eventTracker(
      REPORTS.createdExport,
      Serializer.build({ export: values }),
    ));

    return this.props.createExport(params).then(() => {
      this.props.setExportsRefetch();
      this.onClose();
    });
  }

  onClose() {
    this.props.resetForm();
    this.props.closeDialog();
  }

  render() {
    const {
      exportEnums,
      exportTargets,
      exportTypes,
      fields,
      groupId,
      handleSubmit,
      isCC,
      registerField,
      submitting,
    } = this.props;

    if (submitting) {
      return <Spinner text="Creating Export..." />;
    }

    return (
      <form className="exports-create-form content-with-actions" onSubmit={handleSubmit(this.onSubmit)}>
        <div className="content-container">
          <ExportCreateFields
            exportEnums={exportEnums}
            exportTargets={exportTargets}
            exportTypes={exportTypes}
            fields={fields}
            groupId={groupId}
            isCC={isCC}
            registerField={registerField}
          />
        </div>
        <div className="actions">
          <FormFooter
            onSubmitLabel="Save"
            handleSubmit={() => {
              handleSubmit(this.onSubmit)();
            }}
            submitDisabled={submitting}
            onCancel={this.onClose}
          />
        </div>
      </form>
    );
  }
}

ExportsCreateForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

ExportsCreateForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  createExport: PropTypes.func.isRequired,
  currentEmployee: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  exportEnums: PropTypes.object.isRequired,
  exportTargets: PropTypes.array.isRequired,
  exportTypes: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isCC: PropTypes.bool.isRequired,
  registerField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  setExportsRefetch: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  // CORE-WORK - Need to resolve network.is_super_network
  const { allNetworks } = ownProps;
  const networks = _.get(state, 'user.networks', null);
  const groupId = _.get(state, 'session.groupId', null);
  const isCC = isCoordinationCenter(allNetworks, groupId);
  const ccNetwork = coordinationCenterNetwork(allNetworks, groupId);
  const networkId = _.get(ccNetwork, 'id', null);
  const targetType = _.get(state, `form.${EXPORTS_CREATE_FORM}.target_type.value`);
  const currentGroup = _.get(findCurrentGroup(_.get(state, 'user'), _.get(state, 'session')), 'group', {});
  const currentEmployee = _.get(state, 'globalState.currentEmployee', null);

  const exportTargets = targetSelectionOptions({
    targetType, currentGroup, ccNetwork, isCC,
  });

  const exportTypes = _.get(ownProps, 'exportEnums.types', []);
  const exportFormTypes = _.get(ownProps, 'exportEnums.form_types', []);
  const filteredExportTypes = filterExportTypes({ targetType, exportTypes });

  return {
    currentEmployee,
    exportTargets,
    exportTypes: filteredExportTypes,
    groupId,
    initialValues: {
      formTypes: exportFormTypes.map((type) => ({
        checked: false,
        form_type: type,
      })),
    },
    isCC,
    networkId,
    networks,
  };
}

export default validateReduxForm(
  {
    form: EXPORTS_CREATE_FORM,
    fields: [
      ...ExportCreateFields.fields,
    ],
  },
  mapStateToProps,
  {
    setExportsRefetch,
    createExport,
  },
)(ExportsCreateForm);
