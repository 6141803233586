import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  DateField,
  InputField,
  SelectField,
} from '@unite-us/ui';
import { debounce, isEmpty } from 'lodash';
import today from 'common/utils/today';
import featureFlag from 'common/utils/FeatureFlag/FeatureFlag';
import { getInsuranceValuesForValidation, insuranceFieldsValidations } from 'src/components/Insurance/utils';
import { PLAN_TYPES } from 'src/components/Insurance/constants';
import './PaymentsInsuranceFields.scss';

export class PaymentsInsuranceFields extends PureComponent {
  constructor(props) {
    super(props);

    this.onChangeType = this.onChangeType.bind(this);
    this.searchInsurancePlanOptions = this.searchInsurancePlanOptions.bind(this);
    this.debouncedSearchInsurancePlanOptions = debounce(this.searchInsurancePlanOptions, 400);
  }

  onChangeType(planType) {
    this.props.onChangeType(planType);
  }

  searchInsurancePlanOptions(search) {
    this.props.searchInsurancePlanOptions(search);
  }

  render() {
    const {
      requiredFields,
      fields,
      index,
      insurancePlanOptions,
      registerField,
    } = this.props;
    const insurancePlanTypes = [];
    Object.values(PLAN_TYPES).map((plan) => {
      if (typeof plan === 'string') {
        insurancePlanTypes.push({
          display_name: plan.charAt(0).toUpperCase() + plan.slice(1),
          value: plan,
        });
      }

      return true;
    });
    return (
      <div className="payments-insurance-fields">
        <SelectField
          className="payments-insurance-fields__plan-type"
          field={fields.plan_type}
          disabled={!isEmpty(fields.plan_type.initialValue)}
          id="insurance-plan-type"
          label="Plan Type"
          labelKey="display_name"
          onChange={this.onChangeType}
          options={insurancePlanTypes}
          placeholder="Select..."
          ref={registerField}
          required={requiredFields}
          validations={{
            func: getInsuranceValuesForValidation(insuranceFieldsValidations.isRequiredPaymentsPlanType, index),
          }}
        />
        <SelectField
          className="payments-insurance-fields__plan"
          field={fields.plan}
          disabled={!isEmpty(fields.plan.initialValue)}
          id="insurance-plan"
          label="Insurance Plan"
          loadOptions={this.debouncedSearchInsurancePlanOptions}
          options={
            insurancePlanOptions.map(({ id, attributes }) => ({ value: id, label: attributes.name }))
          }
          placeholder="Select..."
          ref={registerField}
          required={requiredFields}
          searchResultLimit={250}
          shouldSort={false}
          validations={{
            func: getInsuranceValuesForValidation(insuranceFieldsValidations.isRequiredPaymentsPlan, index),
          }}
          searchPlaceholderValue={
            insurancePlanOptions.length === 0 ? 'Select Plan Type first' : 'Search'
          }
        />
        <div className="payments-insurance-info-item">
          <InputField
            className="payments-insurance-fields__member-id"
            field={fields.member_id}
            id="insurance-member-id"
            label="Member Id"
            labelKey="display_name"
            ref={registerField}
            required={requiredFields}
            validations={{
              func: getInsuranceValuesForValidation(insuranceFieldsValidations.isRequiredPaymentsMemberId, index),
            }}
          />
        </div>
        <InputField
          className="payments-insurance-fields__group-id"
          field={fields.group_id}
          id="insurance-group-id"
          label="Insurance Group Id"
        />
        <div className="payments-insurance-date-item">
          <DateField
            className="payments-insurance-fields__coverage-start"
            field={fields.enrollment}
            id="insurance-coverage-start"
            label="Coverage Start"
            maxDate={
              fields.expiration.value ?
                (Number(fields.expiration.value) - 86400).toString() : null
            }
          />
          <DateField
            className="payments-insurance-fields__coverage-end"
            field={fields.expiration}
            id="insurance-coverage-end"
            label="Coverage End"
            minDate={
              this.props.fields.enrollment.value ?
                (Number(this.props.fields.enrollment.value) + 86400).toString() : today()
            }
            isUtc={false}
            shouldDisableDate={(date) => date.isSame(moment.utc(this.props.fields.enrollment.value), 'day')}
            ref={registerField}
          />
        </div>
      </div>
    );
  }
}

PaymentsInsuranceFields.propTypes = {
  fields: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  insurancePlanOptions: PropTypes.array,
  onChangeType: PropTypes.func.isRequired,
  requiredFields: PropTypes.bool.isRequired,
  registerField: PropTypes.func.isRequired,
  searchInsurancePlanOptions: PropTypes.func.isRequired,
};

PaymentsInsuranceFields.defaultProps = {
  insurancePlanOptions: [],
};

export default featureFlag(PaymentsInsuranceFields);
