import Notifier from 'common/helpers/Notifier';
import { UPDATE_CONTACT_ADDRESS_FIELD } from 'actions';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import fetchGroupContact from 'src/actions/Contact/Group/fetchGroupContact';
import { coreApi } from 'src/api/config';
import { getGeocodedAddress } from 'src/components/Browse/Map/utils/geoCode';

const onError = (error) => {
  Notifier.handleErrors(error);
  return error;
};

const updateContactAddressField = (groupId, contactId, itemId, address) => (
  async (dispatch) => {
    try {
      const geocodedAddress = await getGeocodedAddress({ ...address, person: contactId });
      const response = await coreApi.updateRecord('address', itemId, geocodedAddress);
      if (!response || !isHttpSuccess(response.status)) {
        return onError(response);
      }
      dispatch({ type: UPDATE_CONTACT_ADDRESS_FIELD });
      Notifier.dispatch(response.status, 'Address Field Successfully Updated');
      dispatch(fetchGroupContact(groupId, contactId));
      return response;
    } catch (error) {
      return onError(error);
    }
  }
);

export default updateContactAddressField;
