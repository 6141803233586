import {
  FETCH_NETWORK_USERS_ERROR,
  FETCH_NETWORK_USERS_STARTED,
  FETCH_NETWORK_USERS_SUCCESS,
} from 'actions';

import { isEqual } from 'lodash';

export const defaultState = {
  users: {},
  networkUsers: {},
  searchParams: {},
  status: 'initial',
  paging: {},
  error: null,
};

// used to determine if the reducer needs to be reset due to a change in search params
// paging and per is not accounted for
const isSameSearchContext = (newSearchParams = {}, existingSearchParams = {}) => {
  const { permitted_networks: newNetworks, text: newText, group_id: newGroupId } = newSearchParams;
  const { permitted_networks: networks, text, group_id: groupId } = existingSearchParams;

  const sameText = isEqual(newText, text);
  const sameGroupId = isEqual(newGroupId, groupId);
  const sameNetworks = isEqual(newNetworks, networks);

  if (sameText && sameGroupId && sameNetworks) {
    return true;
  }

  return false;
};

const useDefaultState = ({ replace, searchParams, state }) => {
  // determine if we are resetting the list of users and paged list of network users
  const sameContext = isSameSearchContext(searchParams.q, state.searchParams.q);
  const shouldUseDefaultState = (replace && !sameContext);

  const existingUsers = shouldUseDefaultState ? defaultState.users : state.users;
  const existingNetworkUsers = shouldUseDefaultState ? defaultState.networkUsers : state.networkUsers;
  const existingPaging = shouldUseDefaultState ? defaultState.paging : state.paging;

  // return the correct users, networkUsersList, and paging object
  return [existingUsers, existingNetworkUsers, existingPaging];
};

function networkUsersReducer(state = defaultState, action = {}) {
  const {
    payload,
    searchParams,
    type,
    replace = false,
  } = action;

  switch (type) {
    case FETCH_NETWORK_USERS_SUCCESS: {
      const { data, paging } = payload.data;
      const { current_page = 1 } = paging;

      // determine if we are resetting the list of users and paged list of network users
      const [existingNetworkUsers] = useDefaultState({ replace, searchParams, state });

      const users = {};
      data.forEach((user) => {
        users[user.id] = user;
      });

      // create the set of paged network users that will be put in state
      const newNetworkUsers = {
        ...existingNetworkUsers,
        [current_page]: data.map((user) => user.id),
      };

      return {
        ...state,
        error: null,
        networkUsers: newNetworkUsers,
        paging,
        searchParams,
        status: 'success',
        users,
      };
    }

    case FETCH_NETWORK_USERS_STARTED: {
      // determine if we are resetting the list of users and paged list of network users
      const [existingUsers, existingNetworkUsers, existingPaging] = useDefaultState({ replace, searchParams, state });

      return {
        ...state,
        networkUsers: existingNetworkUsers,
        paging: existingPaging,
        status: 'pending',
        users: existingUsers,
      };
    }

    case FETCH_NETWORK_USERS_ERROR: {
      const { error } = payload;
      return {
        ...state,
        status: 'error',
        error,
      };
    }

    default:
      return state;
  }
}

export default networkUsersReducer;
