import PropTypes from 'prop-types';
import React from 'react';
import {
  EnterLeaveViewport,
} from 'src/common/charts';
import { OverlaySpinner } from 'common/spinners';
import Affiliation from 'src/components/Reports/components/MilitaryReports/Affiliation';
import ServiceEra from 'src/components/Reports/components/MilitaryReports/ServiceEra';
import CurrentStatus from 'src/components/Reports/components/MilitaryReports/CurrentStatus';
import AffiliationByGender from 'src/components/Reports/components/MilitaryReports/AffiliationByGender';
import StatusByGender from 'src/components/Reports/components/MilitaryReports/StatusByGender';
import BranchOfService from 'src/components/Reports/components/MilitaryReports/BranchOfService';
import Transitioning from 'src/components/Reports/components/MilitaryReports/Transitioning';
import BranchByGender from 'src/components/Reports/components/MilitaryReports/BranchByGender';
import TransitioningByGender from 'src/components/Reports/components/MilitaryReports/TransitioningByGender';
import TransitioningByBranch from 'src/components/Reports/components/MilitaryReports/TransitioningByBranch';
import EraByBranch from 'src/components/Reports/components/MilitaryReports/EraByBranch';
import EraByGender from 'src/components/Reports/components/MilitaryReports/EraByGender';
import MilitaryDischarge from 'src/components/Reports/components/MilitaryReports/MilitaryDischarge';
import Deployed from 'src/components/Reports/components/MilitaryReports/Deployed';
import { DOMAIN_CONSTANTS } from 'src/common/constants';
import _ from 'lodash';
import 'src/pages/reports/MilitaryReport.scss';

export default class MilitaryReport extends React.Component {
  constructor(props) {
    super(props);
    this.onBlock1Enter = this.onBlock1Enter.bind(this);
    this.onBlock2Enter = this.onBlock2Enter.bind(this);
    this.getDomElements = this.getDomElements.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.chartLoaded = this.chartLoaded.bind(this);

    this.state = {
      scrollElement: null,
      block1Element: null,
      block1Active: false,
      block2Element: null,
      block2Active: false,
      loaded: [
        {
          affiliation: false,
          currentStatus: false,
          branch: false,
          serviceEra: false,
          discharge: false,
          deployed: false,
          transitioning: false,
        },
        {
          affiliationByGender: false,
          statusByGender: false,
          branchByGender: false,
          eraByGender: false,
        },
        {
          transitioningByGender: false,
          eraByBranch: false,
          transitioningByBranch: false,
        },
      ],
    };
  }

  componentDidMount() {
    this.getDomElements();
  }

  onBlock1Enter() {
    this.setState({ block1Active: true });
  }

  onBlock2Enter() {
    this.setState({ block2Active: true });
  }

  getDomElements() {
    this.setState({
      scrollElement: document.getElementById('military-main').parentElement,
      block1Element: document.getElementById('block-1'),
      block2Element: document.getElementById('block-2'),
    });
  }

  isLoading(block = 0) {
    return _.some(_.values(this.state.loaded[block]), (v) => !v);
  }

  chartLoaded(key, block) {
    this.setState({ loaded: _.set(this.state.loaded, `[${block}].${key}`, true) });
  }

  render() {
    const { groupId, filters } = this.props;

    return (
      <div
        className="military-report"
        id="military-main"
      >
        <OverlaySpinner
          show={this.isLoading(0)}
          text="Loading"
          animationTimeout={1}
        />
        <div className="row">
          <div className="col-sm-6">
            <Affiliation
              id={groupId}
              scope={DOMAIN_CONSTANTS.GROUPS}
              loaded={(key) => this.chartLoaded(key, 0)}
              filters={filters}
            />
          </div>
          <div className="col-sm-6">
            <CurrentStatus
              id={groupId}
              scope={DOMAIN_CONSTANTS.GROUPS}
              loaded={(key) => this.chartLoaded(key, 0)}
              filters={filters}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <BranchOfService
              id={groupId}
              scope={DOMAIN_CONSTANTS.GROUPS}
              loaded={(key) => this.chartLoaded(key, 0)}
              filters={filters}
            />
          </div>
          <div className="col-sm-6">
            <ServiceEra
              id={groupId}
              scope={DOMAIN_CONSTANTS.GROUPS}
              loaded={(key) => this.chartLoaded(key, 0)}
              filters={filters}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <MilitaryDischarge
              id={groupId}
              scope={DOMAIN_CONSTANTS.GROUPS}
              loaded={(key) => this.chartLoaded(key, 0)}
              filters={filters}
            />
          </div>
          <div className="col-sm-6">
            <Deployed
              id={groupId}
              scope={DOMAIN_CONSTANTS.GROUPS}
              loaded={(key) => this.chartLoaded(key, 0)}
              filters={filters}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <Transitioning
              id={groupId}
              scope={DOMAIN_CONSTANTS.GROUPS}
              loaded={(key) => this.chartLoaded(key, 0)}
              filters={filters}
            />
          </div>
        </div>

        <div id="block-1" className="military-report__report-block">
          <EnterLeaveViewport
            scrollElement={this.state.scrollElement}
            childElement={this.state.block1Element}
            onEnter={this.onBlock1Enter}
            disabled={this.isLoading(0) || !this.isLoading(1)}
          >
            {
              this.state.block1Active ? (
                <div className="military-report__pos-relative">
                  <OverlaySpinner
                    show={this.isLoading(1)}
                    text="Loading"
                    animationTimeout={1}
                  />
                  <AffiliationByGender
                    id={groupId}
                    scope={DOMAIN_CONSTANTS.GROUPS}
                    loaded={(key) => this.chartLoaded(key, 1)}
                    filters={filters}
                  />
                  <StatusByGender
                    id={groupId}
                    scope={DOMAIN_CONSTANTS.GROUPS}
                    loaded={(key) => this.chartLoaded(key, 1)}
                    filters={filters}
                  />
                  <BranchByGender
                    id={groupId}
                    scope={DOMAIN_CONSTANTS.GROUPS}
                    loaded={(key) => this.chartLoaded(key, 1)}
                    filters={filters}
                  />
                  <EraByGender
                    id={groupId}
                    scope={DOMAIN_CONSTANTS.GROUPS}
                    loaded={(key) => this.chartLoaded(key, 1)}
                    filters={filters}
                  />
                </div>
              ) : <div />
            }
          </EnterLeaveViewport>
        </div>
        <div id="block-2" className="military-report__report-block">
          <EnterLeaveViewport
            scrollElement={this.state.scrollElement}
            childElement={this.state.block2Element}
            onEnter={this.onBlock2Enter}
            disabled={this.isLoading(1) || !this.isLoading(2)}
          >
            {
              this.state.block2Active ? (
                <div className="military-report__pos-relative">
                  <OverlaySpinner
                    show={this.isLoading(2)}
                    text="Loading"
                    animationTimeout={1}
                  />
                  <TransitioningByGender
                    id={groupId}
                    scope={DOMAIN_CONSTANTS.GROUPS}
                    loaded={(key) => this.chartLoaded(key, 2)}
                    filters={filters}
                  />
                  <EraByBranch
                    id={groupId}
                    scope={DOMAIN_CONSTANTS.GROUPS}
                    loaded={(key) => this.chartLoaded(key, 2)}
                    filters={filters}
                  />
                  <TransitioningByBranch
                    id={groupId}
                    scope={DOMAIN_CONSTANTS.GROUPS}
                    loaded={(key) => this.chartLoaded(key, 2)}
                    filters={filters}
                  />
                </div>
              ) : <div />
            }
          </EnterLeaveViewport>
        </div>
      </div>
    );
  }
}

MilitaryReport.propTypes = {
  filters: PropTypes.shape({
    end_date: PropTypes.string,
    start_date: PropTypes.string,
  }),
  groupId: PropTypes.string,
};
