import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@unite-us/ui';
import { isEmpty } from 'lodash';
import { theme } from 'src/../tailwind.config';
import MetafieldForm from './MetafieldForm';

const EditMetafields = ({
 metafields, setMetafieldsToSave, addButtonLabel, formsTitle, ...props
}) => {
  const [newMetafields, setNewMetafields] = useState([...metafields]);
  const [newComponents, setNewComponents] = useState([]);
  const [lastIndex, setLastIndex] = useState(metafields.length);

  const setMetafield = (metafield, index) => {
    const metafieldsToSave = [...newMetafields];
    metafieldsToSave[index] = metafield;
    setNewMetafields(metafieldsToSave);
    setMetafieldsToSave(metafieldsToSave);
  };

  const addMetafield = () => {
    setNewComponents(
      [
        ...newComponents,
        <MetafieldForm
          title={`${formsTitle} ${lastIndex + 1}`}
          setMetafield={setMetafield}
          index={lastIndex}
          {...props}
        />,
      ],
    );
    setLastIndex(lastIndex + 1);
  };

  return (
    <div>
      {!isEmpty(metafields) &&
        metafields
          .map((metafield, index) => (
            <div className={'mb-2'}>
              <MetafieldForm
                title={`${formsTitle} ${index + 1}`}
                metafield={metafield}
                setMetafield={setMetafield}
                index={index}
                {...props}
              />
            </div>
          ))}
      {newComponents.map((component) => (
        <div className={'mb-2'}>
          {component}
        </div>
      ))}
      <Button
        id="add-custom-field-button"
        iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="V2Add" />}
        className="py-1 px-4 text-sm text-action-blue rounded border-none bg-transparent normal-case add_button"
        label={addButtonLabel}
        onClick={() => addMetafield()}
      />
    </div>
  );
};

EditMetafields.propTypes = {
  metafields: PropTypes.array.isRequired,
  setMetafieldsToSave: PropTypes.func.isRequired,
  addButtonLabel: PropTypes.string,
  formsTitle: PropTypes.string,
};

EditMetafields.defaultProps = {
  addButtonLabel: 'ADD METAFIELD',
  formsTitle: 'Metafield',
};

export default EditMetafields;
