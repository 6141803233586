import Notifier from 'common/helpers/Notifier';
import { ADD_CONTACT_DOCUMENTS } from 'actions';
import { checkUploads } from 'common/form/DocumentUploader/utils';
import uploadAndAttachDocumentsToResources from
  'common/form/DocumentUploader/actions/uploadAndAttachDocumentsToResources';
import { get } from 'lodash';

function uploadAndAttachContactDocuments({
  attach_to_contact = true,
  attachedDocuments,
  contactId,
  groupId,
  notifySuccess = true,
  resourceIds = [],
  uploadedDocuments,
}) {
  return (dispatch) => {
    function onSuccess([attachedDocsPayload, uploadedDocsPayload]) {
      const attachedDocs = get(attachedDocsPayload, 'data.data', []);
      const uploadedDocs = get(uploadedDocsPayload, 'data.data', []);
      const combinedDocuments = [...attachedDocs, ...uploadedDocs];

      dispatch({
        type: ADD_CONTACT_DOCUMENTS,
        contactId,
        documents: combinedDocuments,
      });

      if (notifySuccess) {
        Notifier.dispatch('success', 'Documents Saved');
      }

      return [attachedDocsPayload, uploadedDocsPayload];
    }

    function onError(error) {
      Notifier.handleErrors(error);
      return error;
    }

    return uploadAndAttachDocumentsToResources({
      attach_to_contact,
      attachedDocuments,
      contactId,
      groupId,
      resourceIds,
      uploadedDocuments,
    })
  };
}

export default uploadAndAttachContactDocuments;
