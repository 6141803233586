import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import UploadAndAttachDocumentsFormFields from './UploadAndAttachDocumentsFormFields';

class DocumentUploaderFormOnly extends PureComponent {
  render() {
    const {
      dropzoneName, fields, formIndex, registerField,
    } = this.props;
    return (
      <div className="document-uploader-form-only">
        <UploadAndAttachDocumentsFormFields
          dropzoneName={dropzoneName}
          fields={fields}
          formIndex={formIndex}
          persistOnUnmount
          registerField={registerField}
        />
      </div>
    );
  }
}

DocumentUploaderFormOnly.propTypes = {
  dropzoneName: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  formIndex: PropTypes.number,
  registerField: PropTypes.func.isRequired,
};

DocumentUploaderFormOnly.defaultProps = {
  formIndex: undefined,
};

export default DocumentUploaderFormOnly;
