import React from 'react';
import PropTypes from 'prop-types';

const PageHeading = ({ text, classes }) => (
  <h2 data-test-element="heading" className={`text-xl leading-7 font-black font-heavy-font ${classes}`}>
    {text}
  </h2>
);

PageHeading.defaultProps = {
  text: '',
  classes: '',
};

PageHeading.propTypes = {
  text: PropTypes.string,
  classes: PropTypes.string,
};

export default PageHeading;
