import {
  useFind,
  useFindRecord,
  useCreateRecord,
  useInvalidateQueries,
  useUpdateRecord,
} from 'src/api/APIHooks';
import { defaultQueryConfig, defaultMutationConfig } from '../apiHookOptions';

export const useFindFeeScheduleProgram = (id) => useFindRecord('fee_schedule_program', id, {
    queryConfig: defaultQueryConfig,
});

export const useFindFeeSchedulePrograms =
  (feeSchedule, state = '', name = '', queryConfig = { queryConfig: defaultQueryConfig }) => useFind(
    'fee_schedule_program',
    {
      fee_schedule: feeSchedule,
      state,
      name,
    },
    queryConfig,
  );

export const useFindProviderByFeeScheduleProgram = (id, params = { queryConfig: defaultQueryConfig }) => useFind(
  'provider',
  {
    fee_schedule_program: id,
  },
  params,
);

export const useCreateFeeScheduleProgram = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useCreateRecord('fee_schedule_program', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('fee_schedule_program');
        invalidateQueries('fee_schedule');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useUpdateFeeScheduleProgram = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useUpdateRecord('fee_schedule_program', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('fee_schedule_program');
        invalidateQueries('fee_schedule');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};
