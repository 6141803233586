import axios from 'axios';
import { DOMAIN_CONSTANTS } from 'src/common/constants';
import _ from 'lodash';

export default function fetchCaseReportsByOutcomeForServiceType(scope, id, serviceTypeId, options = {}) {
  const params = _.uuCompactArrayOrObject(options);
  const closed = scope === DOMAIN_CONSTANTS.GROUPS ? 'closed/' : '';
  const request = axios.get(
    `/reports/${scope}/${id}/cases/${closed}service-types/${serviceTypeId}/by-outcome`,
    { params },
  );
  return request
    .then((response) => _.wget(response, 'data', { data: { values: [] } }), () => ({ data: { values: [] } }));
}
