import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ContactDisplay.scss';

export default function ContactDisplay(props) {
  const { icon, display, className } = props;

  const displayClassName = classNames({
    'contact-display': true,
  }, className);

  return (
    <div className={displayClassName}>
      <div className="contact-display__icon">
        {icon}
      </div>

      <div className="contact-display__display-label">
        {display}
      </div>
    </div>
  );
}

ContactDisplay.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  display: PropTypes.node.isRequired,
};

ContactDisplay.defaultProps = {
  className: '',
};
