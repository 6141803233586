import { get } from 'lodash';
import { DEFAULT_SEARCH_PARAMS } from '../constants';

export default function setSearchParams(state, network) {
  const isNewNetwork = get(state, 'networks.networkId') !== network?.id;

  return isNewNetwork ?
    DEFAULT_SEARCH_PARAMS :
    get(state, 'networks.networkGroupsByReferralScopes.groupSearchParams', DEFAULT_SEARCH_PARAMS);
}
