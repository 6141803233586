import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { get, isEmpty } from 'lodash';
import $scripts from 'scriptjs';

const WithScriptHOC = (opts) => (ComposedComponent) => {
  const scripts = get(opts, 'scripts', []);
  const scriptsEmpty = () => isEmpty(scripts);

  class WithScript extends Component {
    constructor(props) {
      super(props);

      this.state = {
        scriptsLoaded: scriptsEmpty(),
      };
    }

    UNSAFE_componentWillMount() {
      if (scriptsEmpty()) {
        return;
      }

      $scripts(scripts, () => {
        this.setState({ ...this.state, scriptsLoaded: true });
      });
    }

    render() {
      return <ComposedComponent {...this.props} scriptsLoaded={this.state.scriptsLoaded} />;
    }
  }

  WithScript.propTypes = {
    scripts: PropTypes.arrayOf(PropTypes.string),
  };

  return WithScript;
};

export default WithScriptHOC;
