import { coreApi } from 'src/api/config';
import { get } from 'lodash';
import Notifier from 'common/helpers/Notifier';
import { canFetchActionables, isReferralUser } from 'common/utils/Roles/isOrgAdmin';
import {
  DASHBOARD_FETCH_ACTIONABLES,
  DASHBOARD_SET_ACTIONABLES_IS_FETCHING,
  DASHBOARD_UNSET_ACTIONABLES_IS_FETCHING,
} from 'actions';
import { oneYearAgoDateString } from 'src/common/utils/today';

function onSuccess(response, dispatch, updated) {
  dispatch({
    type: DASHBOARD_FETCH_ACTIONABLES,
    payload: response,
    updated,
  });

  dispatch({ type: DASHBOARD_UNSET_ACTIONABLES_IS_FETCHING });
  return response;
}

function onError(dispatch, error) {
  dispatch({ type: DASHBOARD_UNSET_ACTIONABLES_IS_FETCHING });
  Notifier.handleErrors(error);
  return error;
}

const fetchActionables = (groupId) => (
  async (dispatch, getState) => {
    try {
      const state = getState();
      const userRoles = get(state, 'globalState.currentEmployee.roles', []);
      const allRoles = get(state, 'globalState.roles');
      const canFetchAllActionables = canFetchActionables(userRoles, allRoles);
      const canFetchOutboundActionables = isReferralUser(userRoles, allRoles);

      if (!canFetchAllActionables && !canFetchOutboundActionables) { return false; }

      dispatch({ type: DASHBOARD_SET_ACTIONABLES_IS_FETCHING });

      const pageOptions = { page: { number: 1, size: 1 } };

      let countsPayload = {};
      const outbboundReferralsQueries = [
        coreApi.query('referral', {
          state: 'recalled',
          sending_provider: groupId,
          'case.has_outcome': false,
          flagged_for_sender: true,
          updated_after: oneYearAgoDateString,
        }, pageOptions),

        coreApi.query('referral', {
          state: 'declined',
          sending_provider: groupId,
          'case.has_outcome': false,
          flagged_for_sender: true,
          updated_after: oneYearAgoDateString,
        }, pageOptions),

        coreApi.query('referral', {
          state: 'sent',
          sending_provider: groupId,
          'case.person.has_consent': false,
          updated_after: oneYearAgoDateString,
        }, pageOptions),

        coreApi.query('referral', {
          state: 'sent',
          sending_provider: groupId,
          'case.person.consent.state': 'accepted',
          updated_after: oneYearAgoDateString,
        }, pageOptions),
      ];

      if (canFetchAllActionables) {
        const [
          assistanceRequests,
          referralsReceived,
          receivedReferralsPendingConsent,
          clientsRequiringAction,
          referralsInReview,
          referralsRecalled,
          referralsRejected,
          sentReferralsPendingConsent,
          awaitingResponseReferrals,
          assistanceRequestForms,
        ] = await Promise.all([
          coreApi.query('assistance_request', {
            originating_provider: groupId,
          }, pageOptions),

          coreApi.query('referral', {
            state: 'sent',
            'case.has_outcome': false,
            receiving_provider: groupId,
            updated_after: oneYearAgoDateString,
          }, pageOptions),

          coreApi.query('referral', {
            state: 'sent',
            receiving_provider: groupId,
            'case.person.has_consent': false,
            updated_after: oneYearAgoDateString,
          }, pageOptions),

          coreApi.query('person', {
            'client_relationships.flagged': true,
            'client_relationships.provider': groupId,
          }, pageOptions),

          coreApi.query('referral', {
            state: 'in_review',
            'case.has_outcome': false,
            receiving_provider: groupId,
            'case.person.consent.state': 'accepted',
            updated_after: oneYearAgoDateString,
          }, pageOptions),

          ...outbboundReferralsQueries,

          coreApi.query('assistance_request_config', {
            provider: groupId,
          }, pageOptions),
          ]);

        countsPayload = {
          data: {
            data: {
              referrals: {
                received: {
                  pending_consent: get(receivedReferralsPendingConsent, 'data.meta.page.total_count', 0),
                  pending: get(referralsReceived, 'data.meta.page.total_count', 0),
                  in_review: get(referralsInReview, 'data.meta.page.total_count', 0),
                },
                sent: {
                  pending_consent: get(sentReferralsPendingConsent, 'data.meta.page.total_count', 0),
                  awaiting_response: get(awaitingResponseReferrals, 'data.meta.page.total_count', 0),
                  rejected: get(referralsRejected, 'data.meta.page.total_count', 0),
                  recalled: get(referralsRecalled, 'data.meta.page.total_count', 0),
                },
              },
              assistance_requests: {
                pending: get(assistanceRequests, 'data.meta.page.total_count', 0),
              },
              contacts: { flagged: get(clientsRequiringAction, 'data.meta.page.total_count', 0) },
              assistanceRequestForms: get(assistanceRequestForms, 'data.data.length', 0),
            },
          },
        };
      } else if (canFetchOutboundActionables) {
        const [
          referralsRecalled,
          referralsRejected,
          sentReferralsPendingConsent,
          awaitingResponseReferrals,
        ] = await Promise.all(outbboundReferralsQueries);

        countsPayload = {
          data: {
            data: {
              referrals: {
                sent: {
                  pending_consent: get(sentReferralsPendingConsent, 'data.meta.page.total_count', 0),
                  awaiting_response: get(awaitingResponseReferrals, 'data.meta.page.total_count', 0),
                  rejected: get(referralsRejected, 'data.meta.page.total_count', 0),
                  recalled: get(referralsRecalled, 'data.meta.page.total_count', 0),
                },
              },
            },
          },
        };
      }

      const updated = Date.now();
      return onSuccess(countsPayload, dispatch, updated);
    } catch (error) {
      return onError(dispatch, error);
    }
  }
);

export default fetchActionables;
