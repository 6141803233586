import _ from 'lodash';

function getGroupFromState(state, groupId) {
  return _.chain(state)
    .get('groups.data', [])
    .find({ id: groupId })
    .value() || {};
}

function getGroupReferralScopesFromState({ state, groupId }) {
  return _.get(getGroupFromState(state, groupId), 'referral_scopes', []);
}

export default getGroupReferralScopesFromState;
