import { isEmpty, wget } from 'lodash';
import { ccNetworkHasP2P } from 'src/components/Groups/utils/referralScope';
import { hasNoUserRole, isCaseManager, isNetworkUser, isReferralsAdminAndAbove } from 'src/components/User/utils';

function canViewNavItem({
  route, childRoute, currentNetwork, isCCUser, groupId, user, actionables,
}) {
  if (hasNoUserRole({ user, groupId }) || isEmpty(user)) {
    return false;
  }
  const routeLabel = wget(route, 'label');
  const childRouteLabel = wget(childRoute, 'label');
  const hasARForms = Boolean(wget(actionables, 'assistanceRequestForms', 0));

  switch (routeLabel) {
    case 'INBOUND REFERRALS':
      switch (childRouteLabel) {
        case 'Needs Action':
          return isReferralsAdminAndAbove(user, groupId);
        case 'In Review':
          return isReferralsAdminAndAbove(user, groupId);
        case 'Closed':
          return isReferralsAdminAndAbove(user, groupId) && isCCUser;
        default:
          return true;
      }
    case 'ASSISTANCE REQUESTS':
      if (!hasARForms) {
        return false;
      }
      switch (childRouteLabel) {
        case 'Needs Action':
          return isReferralsAdminAndAbove(user, groupId);
        case 'Closed':
          return isReferralsAdminAndAbove(user, groupId);
        case 'Processed':
          return isReferralsAdminAndAbove(user, groupId);
        default:
          return true;
      }
    case 'INTERNAL CASES':
      switch (childRouteLabel) {
        case 'Open':
          return !isNetworkUser(user, groupId) && !isCCUser;
        case 'Closed':
          return !isNetworkUser(user, groupId) && !isCCUser;
        case 'All':
          return !isNetworkUser(user, groupId) && !isCCUser;
        default:
          return true;
      }
    case 'OUTBOUND REFERRALS':
      switch (childRouteLabel) {
        case 'Drafts':
          return !isCaseManager(user, groupId) && !isNetworkUser(user, groupId);
        case 'Pending Consent':
          return !isCaseManager(user, groupId) && !isNetworkUser(user, groupId);
        case 'Rejected':
          return !isCaseManager(user, groupId) && !isNetworkUser(user, groupId);
        case 'Recalled':
          return !isCaseManager(user, groupId) && !isNetworkUser(user, groupId);
        case 'Needs Action':
          return !isCaseManager(user, groupId) && !isNetworkUser(user, groupId);
        case 'In Review':
          return !isCaseManager(user, groupId) && !isNetworkUser(user, groupId);
        case 'Closed':
          return !isCaseManager(user, groupId) && !isNetworkUser(user, groupId);
        case 'All':
          return !isCaseManager(user, groupId) && !isNetworkUser(user, groupId);
        default:
          return true;
      }
    case 'EXTERNAL CASES':
      switch (childRouteLabel) {
        case 'Open':
          return !isCaseManager(user, groupId) && !isNetworkUser(user, groupId);
        case 'Closed':
          return !isCaseManager(user, groupId) && !isNetworkUser(user, groupId);
        case 'All':
          return !isCaseManager(user, groupId) && !isNetworkUser(user, groupId);
        default:
          return true;
      }
    case 'OUT OF NETWORK CASES':
      switch (childRouteLabel) {
        case 'Open':
          return !isNetworkUser(user, groupId);
        case 'Closed':
          return !isNetworkUser(user, groupId);
        case 'All':
          return !isNetworkUser(user, groupId);
        default:
          return true;
      }
    case 'ALL NETWORK':
      switch (childRouteLabel) {
        case 'Referrals':
          return isCCUser && ccNetworkHasP2P(currentNetwork) && isReferralsAdminAndAbove(user, groupId);
        case 'Cases':
          return isCCUser && ccNetworkHasP2P(currentNetwork) && isReferralsAdminAndAbove(user, groupId);
        default:
          return true;
      }
    default:
      return true;
  }
}

export default canViewNavItem;
