import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LineItem } from 'common/display/LineItem';
import ExpandableButton from 'common/buttons/ExpandableButton/ExpandableButton';

function InformationText({
 name, value, isLoaded, section,
}) {
  const [sectionOpen, setSectionOpen] = useState(false);

  return (
    <>
      <LineItem
        field={name}
        fieldClassName="font-heavy-font text-dark-grey"
        valueClassName="flex"
        isLoaded={isLoaded}
      >
        {value}
        {section && (
          <span className="ml-3">
            <ExpandableButton
              isOpen={sectionOpen}
              onClick={() => setSectionOpen(!sectionOpen)}
              dataTestId={'expandable-button-caps-info'}
            >
              {sectionOpen ? 'Hide details' : 'Show details'}
            </ExpandableButton>
          </span>
        )}
      </LineItem>
      <div className="col-span-3 px-0">
        {sectionOpen && section}
      </div>
    </>
  );
}

InformationText.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.number]).isRequired,
  isLoaded: PropTypes.bool.isRequired,
  section: PropTypes.node,
};

InformationText.defaultProps = {
  section: null,
};

function CapsInfo({
  totalApprovedSpend,
  totalRequestedSpend,
  capInfo,
  amountAvailable,
  approvedAuthorizationsSection,
  requestedAuthorizationsSection,
  showMoreInformationButton,
  isLoaded,
}) {
  return (
    <div className="grid grid-cols-2 gap-2" data-testid="caps-info-section">
      { capInfo.hasCap && (
        <InformationText
          name={<span className="font-bold">AMOUNT AVAILABLE FOR AUTHORIZATION</span>}
          value={(
            <span className="font-bold">
              {amountAvailable}
            </span>
          )}
          isLoaded={isLoaded}
        />
      )}
      <InformationText
        name="Amount Currently Authorized"
        value={totalApprovedSpend}
        section={approvedAuthorizationsSection}
        isLoaded={isLoaded}
      />
      { capInfo.hasCap && (
        <>
          <InformationText name="Cap" value={capInfo.formattedCap} isLoaded={isLoaded} />
          {capInfo.startDate?.isValid() &&
           (<InformationText name="Cap Period" value={capInfo.formattedCapPeriod} isLoaded={isLoaded} />)}
        </>
      )}
      <InformationText
        name="Amount Pending Authorization"
        value={totalRequestedSpend}
        section={requestedAuthorizationsSection}
        isLoaded={isLoaded}
      />
      {showMoreInformationButton && (
        <div>
          {showMoreInformationButton}
        </div>
      )}
    </div>
  );
}

CapsInfo.propTypes = {
  totalApprovedSpend: PropTypes.string,
  totalRequestedSpend: PropTypes.string,
  requestedAuthorizationsSection: PropTypes.node,
  approvedAuthorizationsSection: PropTypes.node,
  capInfo: PropTypes.shape({
    hasCap: PropTypes.bool,
    formattedCap: PropTypes.string,
    formattedCapPeriod: PropTypes.string,
    startDate: PropTypes.object,
  }),
  amountAvailable: PropTypes.string,
  showMoreInformationButton: PropTypes.node,
  isLoaded: PropTypes.bool.isRequired,
};

CapsInfo.defaultProps = {
  totalApprovedSpend: '0',
  totalRequestedSpend: '0',
  requestedAuthorizationsSection: null,
  approvedAuthorizationsSection: null,
  capInfo: {
    hasCap: false,
    startDate: null,
  },
  amountAvailable: null,
  showMoreInformationButton: null,
};

export default CapsInfo;
