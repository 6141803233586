import { get, find, includes, isEmpty, map } from 'lodash';

export default function coordinationCenterNetwork(networks = [], groupId = '') {
  if (!groupId || isEmpty(networks)) {
    return undefined;
  }

  return find(networks, (network) => {
    /**
     * CORE-WORK - Need to resolve network.coordination_centers
    */
    const coordinationCenters = get(network, 'coordination_centers');

    if (coordinationCenters) {
      const ccIds = map(coordinationCenters, 'id');
      return includes(ccIds, groupId);
    }

    // This will be default
    const { ccIds } = network;
    return includes(ccIds, groupId);
  });
}
