import { searchAPIClient as axios } from 'src/api/config';
import { SEARCH_API } from 'src/config/env/env.config';
import { responseTransformer, createParams } from './fetchProviderBrowseResultsFromSearch';

const buildProgramParams = (params, parsedParams) => {
  const paramCopy = parsedParams;

  delete paramCopy['filter[licensed]'];
  delete paramCopy['filter[networks]'];
  delete paramCopy['filter[programs.receiving_referrals]'];
  delete paramCopy['filter[programs.services]'];
  delete paramCopy['filter[programs.service_areas][region][state]'];
  delete paramCopy['filter[programs.service_areas][region][national]'];
  delete paramCopy['filter[referable][employee]'];
  delete paramCopy['filter[referable][person]'];
  delete paramCopy['filter[sensitive]'];
  delete paramCopy['filter[provider.type]'];

  return {
    ...paramCopy,
    'filter[provider.networks]': params?.referable?.network ?? params?.networks,
    'filter[provider.licensed]': params?.licensed,
    ...(typeof params.sensitive === 'boolean' && {
      'filter[provider.sensitive]': params.sensitive,
    }),
    'filter[receiving_referrals]': params['programs.receiving_referrals'],
    'filter[services]': params['programs.services'],
    'filter[provider.type]': params['provider.type'],
    include: 'provider',
    sort: 'distance',
  };
};

const fetchProgramBrowseResultsFromSearch = async (params = {}, page = {}, browseMapProgramIds = []) => {
    const searchProgramsUrl = `${SEARCH_API}/programs`;
    const parsedParams = createParams(params, page);

    delete parsedParams['filter[licensed]'];
    delete parsedParams['filter[networks]'];
    delete parsedParams['filter[programs.receiving_referrals]'];
    delete parsedParams['filter[programs.services]'];

    const nearbyProgramParams = buildProgramParams(params, parsedParams);

    const promises = [
      axios.get(searchProgramsUrl, {
          params: nearbyProgramParams,
      }),
    ];

    if (browseMapProgramIds.length) {
      promises.push(axios.get(searchProgramsUrl, {
        params: {
          'filter[id]': browseMapProgramIds.join(','),
          include: 'provider',
        },
      }));
    }

    const [programsResponse, browseMapProgramsResponse] = await Promise.all(promises);

    const resData = programsResponse.data ?? {};
    const nearbyPrograms = programsResponse?.data?.data ?? [];
    const browseMapPrograms = browseMapProgramsResponse?.data?.data ?? [];
    const allPrograms = nearbyPrograms.concat(browseMapPrograms);

    resData.data = allPrograms.map((program) => ({
      ...program.relationships.provider.data,
      relationships: {
        programs: {
          data: [{
            ...program,
            provider: program.relationships.provider.data,
            provided_service_type_ids: program.relationships.services.data,
          }],
        },
        networks: program.relationships.networks,
        locations: {
          data: (program.relationships.provider.data.document.locations || []).map((loc) => ({
            document: { ...loc },
            email_addresses: loc.email_addresses || [],
            location_type: loc.location_type || '',
          })),
        },
      },
    }));

    const currPage = page.page.number || 1;

    const paging = {
      current_page: currPage,
      next_page: resData?.meta?.page?.total_pages > currPage ? currPage + 1 : null,
      per: page?.page?.size,
      prev_page: currPage > 1 ? currPage - 1 : null,
      total_count: resData?.meta?.page?.total_count,
      total_pages: resData?.meta?.page?.total_pages,
    };

    const transformedData = responseTransformer(resData.data);

    return {
      ...programsResponse,
      data: {
        paging,
        data: transformedData,
        meta: resData.meta,
      },
    };
};

export default fetchProgramBrowseResultsFromSearch;
