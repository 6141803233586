import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Icon } from '@unite-us/ui';
import { Card, CardHeader } from 'common/Card';
import { useCurrentProviderId } from 'common/contexts/CurrentProviderContext';
import useFormSubmissions from 'src/components/FormSubmissions/useFormSubmissions';

const FormSubmissionItem = ({ formSubmission, authorizationId }) => (
  <div
    className="flex"
    data-testid={`formSubmission:${formSubmission.id}`}
  >
    <Icon
      className="ml-2 py-1"
      color="text-brand-blue"
      icon="IconCircle"
      size={16}
    />
    <Link
      id={`formUsage-${formSubmission.id}`}
      // eslint-disable-next-line max-len
      to={`/authorizations/${authorizationId}/person/${formSubmission.context.id}/forms/${formSubmission.form.id}/submissions/${formSubmission.id}`}
      className="item-center px-2 text-action-blue"
    >
      {formSubmission?.form?.name}
    </Link>
  </div>
);

FormSubmissionItem.propTypes = {
  formSubmission: PropTypes.object.isRequired,
  authorizationId: PropTypes.string.isRequired,
};

const AssessmentFormsCard = ({ serviceAuthorization }) => {
  const personId = serviceAuthorization?.person?.id;
  const providerId = useCurrentProviderId();
  const { formSubmissions } = useFormSubmissions({
    context: personId,
    contextType: 'person',
    providerId,
  });

  // List most recent forms only
  const reducedFormSubmissions = formSubmissions.reduce((result, obj) => {
    const row = result.find((x) => x.form.id === obj.form.id);
    if (!row) { result.push({ ...obj }); } else if (row.created_at < obj.created_at) { Object.assign(row, obj); }
    return result;
  }, []);

  return (
    <Card className="bg-white">
      <CardHeader
        className="pt-2 space-y-2"
        title="Assessment Forms"
      />
      <div className="py-4 pl-4 space-y-2">
        {
          reducedFormSubmissions.length > 0 ? (
            reducedFormSubmissions.map((formSubmission) => (
              <FormSubmissionItem
                key={`formSubmission:${formSubmission.id}`}
                formSubmission={formSubmission}
                authorizationId={serviceAuthorization.id}
              />
            ))
          ) : <div className="pt-1" data-testid="no-assessments">No assessment forms available.</div>
        }
      </div>
    </Card>
  );
};

AssessmentFormsCard.propTypes = {
  serviceAuthorization: PropTypes.object.isRequired,
};

export default AssessmentFormsCard;
