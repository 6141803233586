import { noop } from 'lodash';
import { browserHistory } from 'common/utils/browserHistory';
import callOrLog from 'src/common/utils/callOrLog';
import { NEW_CLIENT } from 'common/utils/EventTracker/utils/eventConstants';
import STEP_DEFAULTS from './utils/defaults';
import {
  SEARCH_PATH,
  CONFIRM_PATH,
  ADD_INFORMATION_PATH,
} from '../constants';

const contactsStepperConfig = {
  steps: [
    {
      ...STEP_DEFAULTS.first,
      title: 'Search Records',
      onStepClick: () => {
        browserHistory.push(`/contacts/${SEARCH_PATH}`);
      },
      routes: [
        {
          route: `/contacts/${SEARCH_PATH}`,
        },
        {
          route: `/contacts/${CONFIRM_PATH}`,
        },
      ],
    },
    {
      ...STEP_DEFAULTS.second,
      title: 'Contact Information',
      onStepClick: noop,
      onStepComplete: ({
        contact = {},
        contactId = null,
        eventTracker = noop,
        removeSelectedContact = noop,
      }) => {
        const event = contactId ? NEW_CLIENT.updateFromNav : NEW_CLIENT.createFromNav;
        callOrLog(() => eventTracker(event, null, {
          contact,
        }));

        removeSelectedContact();
      },
      routes: [
        {
          route: `/contacts/${ADD_INFORMATION_PATH}`,
        },
      ],
    },
  ],
};

export default contactsStepperConfig;
