function generateFeeScheduleOptionsRollbar({
  response,
  employeeId,
  networkId,
  contactId,
}) {
  const errorData = {
    response,
    employeeId,
    networkId,
    contactId,
  };

  window.Rollbar.error('Unable to reach fee schedules endpoint', errorData);
}

export default generateFeeScheduleOptionsRollbar;
