import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { tracker } from '@unite-us/client-utils';
import IndexDetailView from 'src/components/Dashboard/components/IndexDetailView';
import ProviderToProviderReferralsIndex from
  'src/components/Dashboard/components/Referrals/ProviderToProviderReferralsIndex';
import { fetchDashboardReferrals } from 'actions/Referral/Group';
import _ from 'lodash';
import DetailView from 'src/components/Dashboard/components/DetailView';
import ReferralDetail from 'src/components/Referrals/ReferralDetail/index';
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';
import { noneAssignedOption, networkGroupsOptions } from 'src/components/Dashboard/utils/filterDefaults';
import { getDataOfPage, isDataValid } from 'src/components/Dashboard/utils/dataHelpers';
import appendReferralIdToLocation from 'src/components/Dashboard/utils/appendReferralIdToLocation';
import callOrLog from 'common/utils/callOrLog';
import { goToReferral } from 'src/components/Referrals/utils/routing';
import { getReferralStatusEnums } from 'src/components/Referrals/ReferralStatus/utils';
import {
  getReferralSubRouteFromStatus,
  getStatus,
  sortStatuses,
} from 'src/components/Dashboard/components/Referrals/SentReferrals/utils';
import { searchNetworkGroups } from 'actions/Group/Network';
import { careCoordinatorsFilter, serviceTypesFilter, validateFilterValue } from 'src/components/Dashboard/utils/filter';
import subNetworksFilter from 'src/components/Dashboard/utils/subNetworksFilter';
import { DASHBOARD_EVENTS, DASHBOARD_VIEWS } from 'common/utils/EventTracker/utils/eventConstants';
import {
  REFERRAL_STATUS_PROVIDER_TO_PROVIDER,
  PROVIDER_TO_PROVIDER_REFERRAL_BASE_ROUTE,
} from 'src/components/Referrals/constants';
import {
  REFERRED_TO_GROUPS,
  REFERRED_BY_GROUPS,
} from 'src/components/Dashboard/constants';
import { getGroupUsersOptions } from 'src/components/Groups/Users/utils';
import { hideMoreFiltersOption, crtb1290SortReferralsByOldest } from 'src/common/utils/FeatureFlags/flags';
import labelCustomization from 'src/common/utils/Customization/labels';
import defaultLabels from 'src/constants/labels';

export class ProviderToProviderReferrals extends Component {
  constructor(props) {
    super(props);
    this.onCellClick = this.onCellClick.bind(this);
    this.onFiltersChange = this.onFiltersChange.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.shouldFetch = this.shouldFetch.bind(this);
    this.fetch = this.fetch.bind(this);
    this.fetchGroupsUsers = this.fetchGroupsUsers.bind(this);
    this.fetchAllNetworkGroups = this.fetchAllNetworkGroups.bind(this);
    this.state = {
      activeUsersInGroup: [],
      page: 1,
      sidx: 'updated_at',
      sord: 'desc',
      filters: {
        service_types: _.get(props, 'filters.service_types', []),
        status: validateFilterValue(props.statuses, getStatus(props), 'all'),
        care_coordinator_users: _.get(
          props,
          'filters.care_coordinator_users',
          props.isCaseManager ? [props.userId] : [],
        ),
        referral_sender_users: this.props.sendingProviders,
        networks: _.get(props, 'filters,networks', []),
        referred_to_groups: [],
        referred_by_groups: [],
        referralStates: 'sent,accepted,auto_recalled,in_review,declined,recalled',
        networkId: props.networkId,
      },
      referred_to_groups: [],
      referred_by_groups: [],
    };
  }

  componentDidMount() {
    this.fetch();
    this.props.fetchProvidersUserCore({
      providers: this.props.groupId,
    }).then((response) => {
      if (response) {
        const { employees: activeUsersInGroup } = response;
        this.setState({ activeUsersInGroup });
      }
    });
    this.fetchAllNetworkGroups();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.get(this.props, 'providerToProviderReferral.currentPage', 1) !==
      _.get(nextProps, 'providerToProviderReferral.currentPage', 1)) {
      this.setState({ page: nextProps.providerToProviderReferral.currentPage });
    }

    if (!this.props.refetch && nextProps.refetch) {
      this.fetch();
    }
  }

  onCellClick(row) {
    const pagedData = getDataOfPage(this.props.providerToProviderReferral, this.state.page, this.props.sortAscending);
    const referral = pagedData.data[row];

    callOrLog(() => this.context.eventTracker(DASHBOARD_EVENTS.providerToProviderReferralRow, {
      view: DASHBOARD_VIEWS.providerToProviderReferral,
    }, { referral }));

    goToReferral({ ...referral, status: REFERRAL_STATUS_PROVIDER_TO_PROVIDER });
  }

  onFiltersChange(key, activeFilters, callbacks) {
    let newFilters = activeFilters;
    if (_.isEqual(key, 'care_coordinator_users')) {
      newFilters = careCoordinatorsFilter(activeFilters, callbacks);
    }
    const myFilters = { ...this.state.filters, [key]: newFilters };
    this.setState({
      page: 1,
      filters: myFilters,
    }, () => {
      callOrLog(() => {
        const filters = tracker.buildDashboardFilterPayload(this.state, this.props);
        this.context.eventTracker(DASHBOARD_EVENTS.providerToProviderReferralFilter, filters);
      });
      this.fetch();
    });
  }

  onNextClick() {
    const page = this.state.page + 1;

    this.shouldFetch(page);
  }

  onPrevClick() {
    const page = this.state.page - 1;

    this.shouldFetch(page);
  }

  shouldFetch(page) {
    if (isDataValid(this.props.providerToProviderReferral, page)) {
      this.setState({ page });
    } else {
      this.fetch(page);
    }
  }

  fetch(page = 1) {
    this.props.fetchDashboardReferrals(
      this.props.groupId,
      getReferralSubRouteFromStatus(
        this.state.filters.status,
        PROVIDER_TO_PROVIDER_REFERRAL_BASE_ROUTE,
      ),
      {
        target: 'providerToProviderReferrals',
        sidx: this.state.sidx,
        sord: this.state.sord,
        filters: this.state.filters,
        page,
        noPendingConsent: true,
        allStatuses: this.state.filters.referralStates,
      },
      this.state.filters.status === 'all' ? this.state.filters.referralStates : this.state.filters.status,
    );
  }

  fetchGroupsUsers(search = '') {
    const { groupId } = this.props;
    return this.props.fetchProvidersUserCore({
      providers: groupId,
      options: {
        text: search,
        licensed: false,
      },
    }).then((response) => {
      if (response) {
        const { employees: activeUsersInGroup } = response;
        if (search === '') {
          return _.concat(
            [noneAssignedOption(this.state)],
            getGroupUsersOptions(activeUsersInGroup, this.state),
          );
        }

        return getGroupUsersOptions(activeUsersInGroup, this.state);
      }

      return [];
    });
  }

  fetchAllNetworkGroups(page = 1, search = undefined, type = 'initial') {
    const { networkId } = this.props;
    return this.props.searchNetworkGroups(this.props.networkId, {}, { page, search }, true).then((response) => {
      const dropdownResponse = response.map((provider) => ({
        label: provider.name,
        value: provider.id,
        initial: false,
      }));
      if (type === 'initial') {
        this.setState({
          referred_by_groups: {
            [networkId]: response,
          },
          referred_to_groups: {
            [networkId]: response,
          },
        });
      }
      if (type === REFERRED_TO_GROUPS) {
        this.setState({
          referred_to_groups: {
            [networkId]: response,
          },
        });
      } else if (type === REFERRED_BY_GROUPS) {
        this.setState({
          referred_by_groups: {
            [networkId]: response,
          },
        });
      }

      return dropdownResponse;
    });
  }

  render() {
    const {
      baseLocation,
      currentGroup,
      isFetching,
      params,
      providerToProviderReferral,
      serviceTypes,
      statuses,
      showMoreFilters,
      labels,
    } = this.props;
    const { activeUsersInGroup } = this.state;
    const location = appendReferralIdToLocation(baseLocation, params);

    const filters = [
      serviceTypesFilter(serviceTypes, this.state.filters.service_types),
      {
        key: 'care_coordinator_users',
        name: labels.CareCoordinator,
        pluralName: `${labels.CareCoordinator}s`,
        options: _.concat(
          [noneAssignedOption(this.state.filters.care_coordinator_users)],
          getGroupUsersOptions(activeUsersInGroup, this.state),
        ),
      },
      {
        name: 'Status',
        key: 'status',
        single: true,
        options: sortStatuses(statuses),
        value: this.state.filters.status,
      },
      subNetworksFilter(currentGroup, this.state.filters.networks),
      {
        key: REFERRED_TO_GROUPS,
        name: 'Receiving Organization',
        pluralName: 'Receiving Organizations',
        hideSelectAll: true,
        isSingleOptionSelect: true,
        options: networkGroupsOptions(this.state.referred_to_groups, this.state.filters.referred_to_groups),
        asyncSearch: (search) => (
          this.fetchAllNetworkGroups(undefined, search, REFERRED_TO_GROUPS)
        ),
        searchPlaceholder: 'Search',
        secondary: !showMoreFilters,
      },
      {
        key: REFERRED_BY_GROUPS,
        name: 'Sending Organization',
        pluralName: 'Sending Organizations',
        hideSelectAll: true,
        isSingleOptionSelect: true,
        options: networkGroupsOptions(this.state.referred_by_groups, this.state.filters.referred_by_groups),
        asyncSearch: (search) => (
          this.fetchAllNetworkGroups(undefined, search, REFERRED_BY_GROUPS)
        ),
        searchPlaceholder: 'Search',
        secondary: !showMoreFilters,
      },
    ];

    const innerContentView = (
      <ReferralDetail
        subjectType="referral"
        key={params.id}
        location={location}
        params={params}
        type="providerToProviderReferral"
        showContactColumn
      />
    );

    const pagedData = getDataOfPage(providerToProviderReferral, this.state.page, this.props.sortAscending);

    return (
      <IndexDetailView
        params={this.props.params}
        DetailView={(
          <DetailView
            innerContentView={innerContentView}
          />
        )}
        IndexView={ProviderToProviderReferralsIndex}
        data={pagedData.data}
        paging={pagedData.paging}
        isFetching={isFetching}
        onNextClick={this.onNextClick}
        onPrevClick={this.onPrevClick}
        onCellClick={this.onCellClick}
        initialSort={{ [this.state.sidx]: this.state.sord }}
        onFiltersChange={this.onFiltersChange}
        filters={_.sortBy(filters, 'name')}
        statuses={statuses}
        fetchGroupsUsers={this.fetchGroupsUsers}
      />
    );
  }
}

ProviderToProviderReferrals.propTypes = {
  baseLocation: PropTypes.string.isRequired,
  currentGroup: PropTypes.object.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  fetchDashboardReferrals: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isCaseManager: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  networkId: PropTypes.string,
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  providerToProviderReferral: PropTypes.shape({
    data: PropTypes.array,
    currentPage: PropTypes.number,
  }).isRequired,
  refetch: PropTypes.bool.isRequired,
  searchNetworkGroups: PropTypes.func.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  sendingProviders: PropTypes.array,
  statuses: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  showMoreFilters: PropTypes.bool,
  labels: PropTypes.object,
  sortAscending: PropTypes.bool,
};

ProviderToProviderReferrals.defaultProps = {
  networkId: '',
  sendingProviders: [],
  labels: defaultLabels,
  sortAscending: false,
};

function mapStateToProps(state, ownProps) {
  const groupId = _.get(state, 'session.groupId');
  const userGroups = _.get(state, 'groups.data', []);
  const currentGroup = _.find(userGroups, { id: groupId }) || {};

  return {
    baseLocation: ownProps.location.pathname,
    userId: _.wget(state, 'user.id'),
    groupId,
    currentGroup,
    providerToProviderReferral: _.wget(state, 'dashboard.providerToProviderReferrals', {}),
    filters: _.wget(state, 'dashboard.providerToProviderReferrals.filters', {}),
    isFetching: _.wget(state, 'dashboard.providerToProviderReferrals.isFetching', false),
    networkId: _.get(state, 'session.networkId', null),
    serviceTypes: _.wget(state, 'serviceTypes.allNetworks', []),
    refetch: _.get(state, 'dashboard.refetch'),
    statuses: _.reject(
      getReferralStatusEnums(state),
      (status) => _.includes(['recalled_or_auto_recalled', 'pending_consent', 'declined_consent'], status.value),
    ),
    isCC: _.wget(state, 'session.isCoordinationGroup', false),
    token: _.get(state, 'session.token', ''),
    sendingProviders: _.get(state, 'globalState.networkProviders', []).map((provider) => provider.id),
    showMoreFilters: hideMoreFiltersOption(state),
    labels: labelCustomization(state),
    sortAscending: crtb1290SortReferralsByOldest(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({
      fetchDashboardReferrals,
      fetchProvidersUserCore,
      searchNetworkGroups,
    }, dispatch),
    dispatch,
  };
}

ProviderToProviderReferrals.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProviderToProviderReferrals);
