import Notifier from 'common/helpers/Notifier';
import createNote from 'src/actions/Case/Contact/Group/createNote';

function createContactInteraction({
  contactId,
  interaction,
}) {
  return async (dispatch) => {
    try {
      const response = await dispatch(createNote({
        interaction,
        subjectType: 'person',
        subject: contactId,
      }));

      return response;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}

export default createContactInteraction;
