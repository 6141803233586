import {
  compact,
  get,
  isEmpty,
} from 'lodash';

// getMarkerObjects creates an array of objects that will be
// passed down to the Map component and used to create Markers
// We need to grab all of the addresses from the groups and make these structures
const getMarkerObjects = (groups = []) => groups
  .reduce((markers, currentGroup) => ([
    ...markers,
    ...compact(get(currentGroup, 'addresses', [])
      .map(({ lat_lng }, index) => {
        if (isEmpty(lat_lng)) {
          return null;
        }

        return {
          key: currentGroup.name,
          // addresses no longer have ids on them, so we need to somehow create unique ids for the marker objects
          id: `${currentGroup.id}::${lat_lng[0]}::${index}`,
          position: { lat: lat_lng[0], lng: lat_lng[1] },
          groupType: get(currentGroup, 'group_type'),

          // new key for the group id, previously the id was being used to trigger the selected group for the drawer
          // let's pass this along instead of trying to parse out the group from the new id construction.
          groupId: currentGroup.id,
        };
      })),
  ]), []);

export default getMarkerObjects;
