import { useEffect } from 'react';
import { browserHistory } from 'common/utils/browserHistory';

const useAuthorizeClientMerging = (isClientMergingEnabled, userIsAdmin) => {
  useEffect(() => {
    if (!isClientMergingEnabled || !userIsAdmin) {
      browserHistory.push('/');
      return;
    }
  }, []);
};

export default useAuthorizeClientMerging;
