import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  TableRow,
  TableRowColumn,
  Popover,
} from '@unite-us/ui';
import { wget } from 'lodash';
import { epochToDate } from 'common/utils/utils';
import callOrLog from 'common/utils/callOrLog';
import { FACESHEET } from 'common/utils/EventTracker/utils/eventConstants';
import { viewable } from 'common/utils/Permission/Permission';
import { browserHistory } from 'common/utils/browserHistory';
import { referralRoute } from 'src/components/Referrals/utils/routing';
import getReferralRecipientDisplayName from 'src/components/Dashboard/utils/getReferralRecipientDisplayName';
import { iconColStyle } from 'src/common/tables/DynamicTable/styles/dynamicTableStyles';
import { tableWidth } from 'common/helpers/Tables';
import ReferralRowIcon from 'src/components/Dashboard/components/Referrals/ReferralRowIcon';
import returnReferralStatus from 'src/components/Referrals/ReferralDetail/utils/returnReferralStatus';

class ReferralTableRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popoverOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const {
      referral,
      groupId,
      isCC,
    } = this.props;

    callOrLog(() => this.context.eventTracker(FACESHEET.referralClicked, null, {
      referral,
    }));

    if (viewable(referral)) {
      browserHistory.push(referralRoute(referral, groupId, isCC));
    } else {
      this.setState({
        popoverOpen: !this.state.popoverOpen,
      });
    }
  }

  render() {
    const {
      referral,
      index,
      styles,
      parent,
      rowIndex,
      secondaryDataList,
    } = this.props;

    return (
      <TableRow
        className="referral-row"
        key={`referral-row-${referral.id}-${index}`}
        id={`referral-row-${referral.id}`}
        onCellClick={this.toggle}
        parent={parent}
        rowIndex={rowIndex}
        secondaryDataList={secondaryDataList}
      >
        <TableRowColumn
          className="table-row__row-column"
          style={{ overflow: 'visible', ...iconColStyle, width: tableWidth('icon') }}
        >
          <ReferralRowIcon referral={referral} />
        </TableRowColumn>
        <TableRowColumn style={{ width: tableWidth('small') }}>{epochToDate(referral.created_at)}</TableRowColumn>
        <TableRowColumn style={{ ...styles.status, width: tableWidth('small') }}>
          {returnReferralStatus({ serviceCase: wget(referral, 'case_summary'), referral })}
        </TableRowColumn>
        <TableRowColumn
          style={{ width: tableWidth('medium') }}
        >
          {wget(referral, 'service_type.name', '')}
        </TableRowColumn>
        <TableRowColumn
          style={{ width: tableWidth('large') }}
        >
          {wget(referral, 'referred_by_group.name', '')}
        </TableRowColumn>
        <TableRowColumn
          style={{ width: tableWidth('large') }}
        >
          {getReferralRecipientDisplayName(referral)}
        </TableRowColumn>
        <Popover
          placement="bottom"
          isOpen={this.state.popoverOpen}
          target={`referral-row-${referral.id}`}
          body="You do not have permission to access this referral"
          toggle={this.toggle}
        />
      </TableRow>
    );
  }
}

ReferralTableRow.propTypes = {
  referral: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  styles: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  isCC: PropTypes.bool.isRequired,
  /** Inherited props from the TableBody Component * */
  parent: PropTypes.string,
  /** Inherited props from the TableBody Component * */
  rowIndex: PropTypes.number,
  /** Inherited props from the TableBody Component * */
  secondaryDataList: PropTypes.array,
};

ReferralTableRow.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default ReferralTableRow;
