import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';

const OrganizationSubheader = ({ providerState, orgAdminDeactivatedPrograms }) => {
  let headerHtml;
  if (providerState === 'active') {
    headerHtml = (
      <p data-testid="org-active-text">
        Edit your organization using the
        <a
          href="http://support.uniteus.io/en/articles/6078997-organization-administration-setup-guide"
          target="_blank"
          rel="noreferrer"
        >
          &nbsp;Organization Administration Setup Guide&nbsp;
        </a>
        available on Unite Us Docs.
      </p>
    );
  } else if (orgAdminDeactivatedPrograms && providerState === 'draft') {
    headerHtml = (
      <p data-testid="org-draft-text">
        Set up your organization using the
        <a
          href="http://support.uniteus.io/en/articles/6078997-organization-administration-setup-guide"
          target="_blank"
          rel="noreferrer"
        >
          &nbsp;Organization Administration Setup Guide&nbsp;
        </a>
        (available on Unite Us Docs) or the Organization Administrator Onboarding course (available from the
        <span className="sr-only">Learn button</span>
        <Icon
          id="org-subheader-learn-btn"
          className="learn-btn mx-1"
          color="black"
          icon="IconGraduationCap"
          size={20}
        />
        in the toolbar).
      </p>
    );
  } else {
    headerHtml = (
      <p data-testid="org-inactive-text">
        Set up your organization using the
        <a
          href="http://support.uniteus.io/en/articles/6078997-organization-administration-setup-guide"
          target="_blank"
          rel="noreferrer"
        >
          &nbsp;Organization Administration Setup Guide&nbsp;
        </a>
        (available on Unite Us Docs) or the CBO Onboarding Course (available on Unite Us Learn.
        To register for a Unite Us Learn account, check your email from
        <a
          href="mailto:education@uniteus.com"
          target="_blank"
          rel="noreferrer"
        >
          &nbsp;education@uniteus.com
        </a>
        ).
      </p>
    );
  }
  return headerHtml;
};

OrganizationSubheader.propTypes = {
  providerState: PropTypes.string.isRequired,
  orgAdminDeactivatedPrograms: PropTypes.bool,
};

OrganizationSubheader.defaultProps = {
  orgAdminDeactivatedPrograms: false,
};

export default OrganizationSubheader;
