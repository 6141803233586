export const DISPLAY_NPS = {
  OFF: 'off',
  LIVE: 'live',
  TEST: 'test',
};

export const DISPLAY_PENDO = {
  OFF: 'off',
  LIVE: 'live',
};

export const PRODUCT_NAME = 'appclient';
