import _ from 'lodash';

const ALLOWED_CONSENT_FILTERS = ['none', 'accepted', 'declined', 'pending', 'revoked'];

/**
 * Ensure the filter value is set properly and only accept the allowed values.
 *
 * @param  {String} desiredFilter The filter to use
 * @return {String} The normalized filter value
 */
const getNormalizedFilterValue = (desiredFilter) => {
  // We most typically receive a string
  if (_.isString(desiredFilter) && _.includes(ALLOWED_CONSENT_FILTERS, desiredFilter)) {
    return desiredFilter;
  }

  // Check array contents (less typical)
  if (_.isArray(desiredFilter)) {
    const filterChoice = _.first(desiredFilter);
    if (_.includes(ALLOWED_CONSENT_FILTERS, filterChoice)) {
      return filterChoice;
    }
  }

  // Return the default
  return 'none';
};

const consentStatusFilter = (initialFilters = [], secondary = false) => ({
  key: 'consent_status',
  name: 'Client Consent',
  pluralName: 'Client Consents',
  single: true,
  value: getNormalizedFilterValue(initialFilters),
  options: [
    { value: 'none', label: 'Client Consent (All)', initial: _.includes(initialFilters, 'none') },
    { value: 'accepted', label: 'Consented', initial: _.includes(initialFilters, 'accepted') },
    { value: 'declined', label: 'Declined Consent', initial: _.includes(initialFilters, 'declined') },
    { value: 'pending', label: 'Pending Consent', initial: _.includes(initialFilters, 'pending') },
    { value: 'revoked', label: 'Revoked Consent', initial: _.includes(initialFilters, 'revoked') },
  ],
  secondary,
});

export default consentStatusFilter;
