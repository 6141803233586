import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { fetchServiceCase, updateServiceCase } from 'actions/Case/Contact/Group';
import callOrLog from 'src/common/utils/callOrLog';
import { SERVICE_CASE } from 'common/utils/EventTracker/utils/eventConstants';
import { Serializer } from '@unite-us/client-utils';

function makeRequestData(formInputs) {
  return {
    service_case: {
      description: _.wget(formInputs, 'description', ''),
    },
  };
}

export class CaseDetailSummaryWrapper extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  submit(formInputs) {
    const {
      contact,
      groupId,
      serviceCase,
    } = this.props;
    const { interaction = {} } = formInputs;

    const contactId = contact.id;
    const caseId = serviceCase.id;
    const requestData = makeRequestData(formInputs);
    const eventPayload = Serializer.build({ interaction });

    return this.props.updateServiceCase(groupId, contactId, caseId, requestData).then(async () => {
      await this.props.fetchServiceCase(groupId, contactId, serviceCase.id);
      callOrLog(() => this.context.eventTracker(SERVICE_CASE.noteAdded, eventPayload, {
        serviceCase,
      }));
    });
  }

  render() {
    const childrenWithFuncs = React.Children.map(this.props.children, (child) => React.cloneElement(child, {
      submit: this.submit,
    }));

    return <div>{childrenWithFuncs}</div>;
  }
}

CaseDetailSummaryWrapper.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
  groupId: PropTypes.string.isRequired,
  serviceCase: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  fetchServiceCase: PropTypes.func.isRequired,
  updateServiceCase: PropTypes.func.isRequired,
};

CaseDetailSummaryWrapper.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    session: {
      groupId,
    },
  } = state;

  return {
    groupId,
  };
}

export default connect(mapStateToProps, {
  fetchServiceCase,
  updateServiceCase,
})(CaseDetailSummaryWrapper);
