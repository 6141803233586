import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

const SimpleList = (props) => {
  const {
    list, className, shortDisplay, ListElement,
  } = props;

  const fieldClass = () => classNames({
    [`${className}`]: className,
  });

  const listElements = list.map((item, index) => {
    if (isEmpty(item)) {
      return null;
    }
    return (
      <li className={fieldClass()} key={index}>
        <ListElement item={item} shortDisplay={shortDisplay} />
      </li>
    );
  });

  return (
    <ul className="list-unstyled">
      {listElements}
    </ul>
  );
};

SimpleList.propTypes = {
  className: PropTypes.string,
  ListElement: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  shortDisplay: PropTypes.bool,
};

SimpleList.defaultProps = {
  className: 'simple-list',
  list: [],
};

export default SimpleList;
