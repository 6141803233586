import {
  FETCH_GROUP_LOCATIONS,
  CREATE_GROUP_LOCATION,
  UPDATE_GROUP_LOCATION,
  DELETE_GROUP_LOCATION,
} from 'actions';

export default function groupLocationsReducer(state = [], action) {
  switch (action.type) {
    case FETCH_GROUP_LOCATIONS:
      return action.payload.data.data;
    case CREATE_GROUP_LOCATION:
      return [
        ...state,
        action.payload.data.data,
      ];
    case UPDATE_GROUP_LOCATION: {
      const response = action.payload.data.data;
      return state.map((location) => {
        if (location.id === response.id) {
          return response;
        }

        return location;
      });
    }
    case DELETE_GROUP_LOCATION: {
      return state.filter((location) => location.id !== action.locationId);
    }
    default:
      return state;
  }
}
