import constants from 'src/components/Referrals/ReferralStatus/constants';
import {
  FETCH_NETWORK_GROUPS,
  UNSET_NETWORK_GROUP_FETCH,
  SET_NETWORK_GROUP_FETCH,
  FETCH_GROUPS_PROGRAMS,
  GROUPS_PROGRAM_UNSET_FETCHING,
  GROUPS_PROGRAM_SET_FETCHING,
  GROUPS_USER_UNSET_FETCHING,
  GROUPS_USER_SET_FETCHING,
  FETCH_GROUPS_USERS_FROM_PROGRAM,
  FETCH_GROUPS_USERS,
} from 'actions';
import { assign, get, reduce } from 'lodash';

export const defaultState = {
  networkGroups: {
    data: [],
    isFetching: false,
  },
  programs: {
    data: [],
    isFetching: false,
  },
  employees: reduce(constants, (acc, curr) => assign({}, acc, {
    [curr]: {
      data: [],
    },
  }), {
    isFetching: false,
  }),
};

function programsReducer(state = defaultState.programs, action = {}) {
  switch (action.type) {
    case FETCH_GROUPS_PROGRAMS:
      if (get(action, 'options.statusOrigin', false)) {
        return {
          ...state,
          data: action.payload.data.data,
        };
      }

      return state;
    case GROUPS_PROGRAM_UNSET_FETCHING:
      return { ...state, isFetching: false };
    case GROUPS_PROGRAM_SET_FETCHING:
      return { ...state, isFetching: true };
    default:
      return state;
  }
}

function networkGroupReducer(state = defaultState.networkGroups, action) {
  switch (action.type) {
    case UNSET_NETWORK_GROUP_FETCH:
      return { ...state, isFetching: false };
    case SET_NETWORK_GROUP_FETCH:
      return { ...state, isFetching: true };
    case FETCH_NETWORK_GROUPS: {
      if (get(action, 'options.statusOrigin', false)) {
        return {
          ...state,
          data: action.payload.data.data,
        };
      }

      return state;
    }
    default:
      return state;
  }
}

function employeesReducer(state = defaultState.employees, action) {
  switch (action.type) {
    case GROUPS_USER_SET_FETCHING:
      return { ...state, isFetching: true };
    case GROUPS_USER_UNSET_FETCHING:
      return { ...state, isFetching: false };
    case FETCH_GROUPS_USERS:
    case FETCH_GROUPS_USERS_FROM_PROGRAM:
      if (get(action, 'options.statusOrigin', false) && get(action, 'options.target', false)) {
        return assign({}, state, {
          [action.options.target]: assign({}, state[action.options.target], {
            data: action.payload.data.data,
          }),
        });
      }

      return state;
    default:
      return state;
  }
}

export default function referralStatusReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_NETWORK_GROUPS:
    case UNSET_NETWORK_GROUP_FETCH:
    case SET_NETWORK_GROUP_FETCH:
      return {
        ...state,
        networkGroups: networkGroupReducer(state.networkGroups, action),
      };

    case FETCH_GROUPS_PROGRAMS:
    case GROUPS_PROGRAM_UNSET_FETCHING:
    case GROUPS_PROGRAM_SET_FETCHING:
      return {
        ...state,
        programs: programsReducer(state.programs, action),
      };

    case GROUPS_USER_SET_FETCHING:
    case GROUPS_USER_UNSET_FETCHING:
    case FETCH_GROUPS_USERS:
    case FETCH_GROUPS_USERS_FROM_PROGRAM:
      return {
        ...state,
        employees: employeesReducer(state.employees, action),
      };
    default:
      return state;
  }
}
