import { coreApi } from 'src/api/config';
import { PATCH_ASSESSMENT, FORM_SAVING, FORM_COMPLETE } from 'actions';
import Notifier from 'common/helpers/Notifier';

export default function patchAssessment ({
  groupId,
  caseId,
  id,
  values,
  submitter,
}) {
  return async (dispatch) => {
    try {
      const payload = await coreApi.createRecord('form_submission', {
        responses: values,
        form: id,
        provider: groupId,
        contextType: 'case',
        context: caseId,
        submitter: submitter.id,
      });

      dispatch({ type: FORM_SAVING, form: id });
      dispatch({ type: FORM_COMPLETE, form: id });
      dispatch({
        type: PATCH_ASSESSMENT,
        payload,
      });

      return payload;
    } catch (error) {
      Notifier.handleErrors(error);
    }
    return null;
  };
}
