import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EDIT_DESCRIPTION } from 'common/display/Profile/constants/form';
import { OverlaySpinner } from 'common/spinners';
import { validateReduxForm } from 'common/form';
import { DraftEditorField } from '@unite-us/client-utils';
import { isFunction, noop } from 'lodash';
import {
  Button,
} from '@unite-us/ui';

class EditDescription extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  onSubmit(formData) {
    const { groupId } = this.props;
    return this.props.updateGroup(groupId, formData).then(this.closeModal);
  }

  closeModal() {
    if (isFunction(this.props.closeModal)) {
      this.props.closeModal();
    }
  }

  render() {
    const {
      fields,
      invalid,
      submitting,
      handleSubmit,
    } = this.props;

    return (
      <form className="content-with-actions">
        <OverlaySpinner text="Saving..." show={submitting} />
        <div className="content-container">
          <DraftEditorField
            id="org-settings-description"
            label="Description"
            field={fields.description}
            inline={false}
          />
        </div>
        <div className="actions">
          <span className="action-item">
            <Button
              id="edit-description-cancel-btn"
              onClick={this.closeModal}
              disabled={submitting}
              label="Cancel"
            />
          </span>
          <span className="action-item">
            <Button
              id="edit-description-save-btn"
              onClick={handleSubmit(this.onSubmit)}
              disabled={invalid || submitting}
              label="Save"
              primary
            />
          </span>
        </div>
      </form>
    );
  }
}

EditDescription.propTypes = {
  closeModal: PropTypes.func,
  fields: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  updateGroup: PropTypes.func,
};

EditDescription.defaultProps = {
  updateGroup: noop,
};

function mapStateToProps(state, ownProps) {
  const { description } = ownProps;
  return {
    initialValues: { description },
    groupId: state.session.groupId,
  };
}

const fields = ['description'];
export default validateReduxForm(
  {
    form: EDIT_DESCRIPTION,
    fields,
  },
  mapStateToProps,
)(EditDescription);
