import _ from 'lodash';
import theme from './theme';
import sortWithNoResponseLast from './sortWithNoResponseLast';

function formatData(data, x = 'title', y = 'total', colors = [], customDataFormat) {
  if (_.isArray(data) || _.isEmpty(data)) {
    return data;
  }

  if (_.isFunction(customDataFormat)) {
    return customDataFormat(data, x, y, colors);
  }

  if (!data.meta || !data.meta.partitions) {
    return [
      {
        key: data.title,
        color: _.first(colors) || _.last(theme.colors),
        values: data.values,
      },
    ];
  }

  if (data.meta && data.meta.partitions) {
    const orderedPartitionKeys = sortWithNoResponseLast(data.meta.partition_keys);
    const formatted = _.reduce(orderedPartitionKeys, (acc, key, index) => {
      const name = data.meta.partition_display_names[key];
      const color = colors[index] || null;

      return _.concat(acc, {
        key: name,
        color,
        values: _.reduce(data.values, (a, value) => {
          const pct = _.round((value[key] / value[y]) * 100, 2);
          return _.concat(a, {
            [x]: value[x],
            [y]: value[key],
            percent: pct,
          });
        }, []),
      });
    }, []);

    return formatted;
  }

  return null;
}

export default formatData;
