import Notifier from 'common/helpers/Notifier';
import moment from 'moment';
import createNote from './createNote';

function createContactServiceCaseProvidedService({
  serviceCaseId,
  provided_service,
  is_note_private = false,
}) {
  return async (dispatch) => {
    try {
      const {
        amount,
        note,
        type,
        unit,
        date: unformatedDate,
      } = provided_service;
      const dateFormated = moment
        .unix(unformatedDate)
        .utc()
        .format('YYYY-MM-DD');
      const response = await dispatch(
        createNote({
          interaction: {
            note,
            category: 'service_provided',
            is_note_private,
          },
          service_provided: {
            amount,
            type,
            unit,
            date: dateFormated,
          },
          subjectType: 'case',
          subject: serviceCaseId,
          opts: {
            sendSuccessMessage: 'Service Successfully Logged',
          },
        }),
      );

      return response;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}

export default createContactServiceCaseProvidedService;
