import {
  RESET_GROUP_CONTACTS_TO_DEFAULT_STATE,
  RESET_DASHBOARD_ACTIVITY_STREAM,
  RESET_DASHBOARD,
  RESET_INTAKES,
} from 'actions';

export default function resetStoreConfigs() {
  return (dispatch) => {
    dispatch({ type: RESET_GROUP_CONTACTS_TO_DEFAULT_STATE });
    dispatch({ type: RESET_DASHBOARD_ACTIVITY_STREAM });
    dispatch({ type: RESET_DASHBOARD });
    dispatch({ type: RESET_INTAKES });
  };
}
