import React from 'react';
import { AUTHORIZATION_STATUSES } from 'src/common/Status/authorizationConstants';
import ServiceAuthorizationWorkqueue from './ServiceAuthorizationWorkqueue';

const OpenServiceAuthorizations = () => (
  <ServiceAuthorizationWorkqueue
    authorizationStatus={AUTHORIZATION_STATUSES.open}
    onlyConsentedPeople
    showTimeInQueue
  />
);

export default OpenServiceAuthorizations;
