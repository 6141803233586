import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@unite-us/ui';

function TableauErrorPage(props) {
  return (
    <div className="tableau__error-page">
      <h4>Oops.  Something went wrong!</h4>
      <Button
        className="mt-one"
        label="Reload Page"
        primary
        onClick={props.onClick}
      />
    </div>
  );
}

TableauErrorPage.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default TableauErrorPage;
