function getGroupUsersOptions(activeUsersInGroup = [], state = {}, currentUser = {}, isCaseManager = false) {
  const hasCurrentUser = activeUsersInGroup.some((u) => u.id === currentUser.id);
  const allUsers = isCaseManager && !hasCurrentUser ? [...activeUsersInGroup, currentUser] : [...activeUsersInGroup];

  return allUsers
    .sort((u1, u2) => {
      const n1 = `${u1.last_name} ${u1.first_name}`.trim();
      const n2 = `${u2.last_name} ${u2.first_name}`.trim();
      return n1.localeCompare(n2);
    })
    .map((u) => ({
      label: u.full_name,
      value: u.id,
      initial: state.filters.care_coordinator_users?.includes(u.id),
    }));
}

export default getGroupUsersOptions;
