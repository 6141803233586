import _ from 'lodash';
import { ASYNC_DASHBOARD_FILTERS_KEYS } from 'src/components/Dashboard/constants';
import FILTER_CONSTANTS from 'src/components/Dashboard/utils/filter/constants';

function setFilterSearchPlaceholder(filter) {
  if (_.includes(ASYNC_DASHBOARD_FILTERS_KEYS, filter.key)) {
    return FILTER_CONSTANTS.SEARCH_PLACEHOLDER_ASYNC;
  }
  return FILTER_CONSTANTS.SEARCH_PLACEHOLDER;
}

export default setFilterSearchPlaceholder;
