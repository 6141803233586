import { filter, reject } from 'lodash';
import { isExternalProviderOrOONGroup } from '.';

function splitInAndOONGroups(groups = []) {
  const inNetwork = reject(groups, isExternalProviderOrOONGroup) || [];
  const outOfNetwork = filter(groups, isExternalProviderOrOONGroup) || [];

  return {
    inNetwork,
    outOfNetwork,
  };
}

export default splitInAndOONGroups;
