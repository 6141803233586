import { noop } from 'lodash';
import { useCreateRecord } from 'api/APIHooks';
import Notifier from 'common/helpers/Notifier';

const defaultOnError = () => {
  Notifier.dispatch('error', 'Could not archive record.');
};

const useArchiveRecord = (recordType, options = {}) => {
  const { createRecord } = useCreateRecord(
    'user_archive',
    {
      mutationConfig: {
        onSuccess: options.onSuccess || noop,
        onError: options.onError || defaultOnError,
      },
      api: 'coreApi',
    },
  );

  return async ({ recordId, employeeId }) => {
    try {
      return await createRecord({
        subject: recordId,
        subjectType: recordType,
        employee: employeeId,
      });
    } catch (error) {
      if (window.Rollbar) {
        window.Rollbar.error('Failed to archive record', { error });
      }
      return error;
    }
  };
};

export default useArchiveRecord;
