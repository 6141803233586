import _ from 'lodash';

//
// Reference http://youmightnotneedjquery.com/
// william@uniteus.com / 2018-02-01
//

export function removeClassName(elem, className) {
  if (Array.isArray(className)) {
    _.forEach(className, (cName) => removeClassName(elem, cName));
    return;
  }

  if (typeof (elem.classList) === 'object' && typeof (elem.classList.add) === 'function') {
    elem.classList.remove(className);
  } else {
    const newClassNames = elem.className.length > 0 ? elem.className.split(' ') : [];
    elem.className = newClassNames // eslint-disable-line
      .filter((cName) => cName !== className)
      .join(' ');
  }
}

export function addClassName(elem, className) {
  if (Array.isArray(className)) {
    _.forEach(className, (cName) => addClassName(elem, cName));
    return;
  }

  const newClassNames = elem.className.length > 0 ? elem.className.split(' ') : [];
  if (newClassNames.indexOf(className) >= 0) {
    return;
  }

  if (typeof (elem.classList) === 'object' && typeof (elem.classList.add) === 'function') {
    elem.classList.add(className);
  } else {
    elem.className = [...newClassNames, className].join(' '); // eslint-disable-line
  }
}

export function outerHeight(elem) {
  let height = elem.offsetHeight;
  const style = getComputedStyle(elem);

  height += parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
  return height;
}

export function outerWidth(elem) {
  return elem.offsetWidth;
}

export function offset(elem) {
  const rect = elem.getBoundingClientRect();

  return {
    top: rect.top + document.body.scrollTop,
    left: rect.left + document.body.scrollLeft,
  };
}
