import {
  SET_FETCHING_INVOICED_SPENDS,
  UNSET_FETCHING_INVOICED_SPENDS,
  FETCH_INVOICED_SPENDS,
} from 'actions';
import _ from 'lodash';

export const defaultState = {
  isFetching: false,
  invoicedSpends: {},
};

export default function invoicedSpendReducer(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case SET_FETCHING_INVOICED_SPENDS:
      return _.assign({}, state, { isFetching: true });

    case UNSET_FETCHING_INVOICED_SPENDS:
      return _.assign({}, state, { isFetching: false });

    case FETCH_INVOICED_SPENDS: {
      return _.assign({}, state, { invoicedSpends: payload });
    }
    default:
      return state;
  }
}
