import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import { Icon } from '@unite-us/ui';
import ReduxFormControlWrapper from './ReduxFormControlWrapper';

export const InputField = (props) => {
  const {
    className,
    disabled,
    invalid,
    label,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required,
    type,
    value,
    dataTestId,
  } = props;

  return (
    <div
      className={classNames(className, 'antialiased')}
    >
      <label
        htmlFor={`${name}__form-input`}
        className={classNames([
          'block mb-1 leading-snug',
          'font-extrabold font-medium-font normal-case',
          'text-13px text-text-blue',
          required && 'ui-form-field__label--required', // red asterisk after content
        ])}
      >
        {label}
      </label>
      <div className="relative">
        <input
          aria-describedby={`${name}__form-error`}
          aria-invalid={invalid}
          aria-required={required}
          className={classNames(
            'h-38px px-4 w-full',
            'outline-none border border-solid rounded-md shadow-sm',
            invalid ?
              'border-light-red focus:border-red focus:shadow-input-error-ring' :
              'border-dark-border-blue hover:border-action-blue focus:border-action-blue focus:shadow-input-ring',
          )}
          disabled={disabled}
          id={`${name}__form-input`}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          type={type}
          value={value}
          data-testid={dataTestId}
        />
        {invalid && (
          <div
            data-testid="input-field_error-icon_container"
            className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
          >
            <Icon icon="IconExclamationCircle" className="h-5 w-5 text-red fill-current" aria-hidden="true" />
          </div>
        )}
      </div>
    </div>
  );
};

InputField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  type: PropTypes.string,
  dataTestId: PropTypes.string,
};

InputField.defaultProps = {
  className: '',
  disabled: false,
  invalid: false,
  onBlur: noop,
  onFocus: noop,
  placeholder: '',
  required: false,
  type: 'text',
  dataTestId: '',
};

export default ReduxFormControlWrapper(InputField);
