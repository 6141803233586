import PropTypes from 'prop-types';
import React from 'react';
import { hasAtLeastOneResponse } from '../utils';
import './AssessmentStatus.scss';

const AssessmentStatus = ({ assessment }) => (
  <div className="assessment-status">
    {
      hasAtLeastOneResponse(assessment) ? (
        <div className="assessment-status__text assessment-status__text--started">
          Started
        </div>
      ) : (
        <div className="assessment-status__text assessment-status__text--not-started">
          Not Started
        </div>
      )
    }
  </div>
);

AssessmentStatus.propTypes = {
  assessment: PropTypes.shape({
    progress: PropTypes.shape({
      at_least_one_response: PropTypes.bool,
    }),
  }),
};

export default AssessmentStatus;
