import _ from 'lodash';
import moment from 'moment';
import { dates } from '@unite-us/app-components';

const emptyData = { data: [], paging: {}, time: 0 };
const validity = 300;

export function getDataOfPage(data, page, sortAscending) {
  if (sortAscending && data?.data?.[0]?.data) {
    data.data[0].data.sort((a, b) => a.created_at - b.created_at);
  }
  return _.get(data, `data[${page - 1}]`, emptyData);
}

export function isDataValid(data, page) {
  const datum = getDataOfPage(data, page);
  if (_.isEmpty(datum)) {
    return false;
  }
  const now = moment().unix();
  return (now - datum.time) < validity;
}

export function childrenExist(parent) {
  return !_.isEqual(_.get(parent, 'children.length', 0), 0);
}

/**
 * Filter's navConfig object and removes ones without children
 */
export function filterIndexTabs(navConfig) {
  return _.filter(navConfig, (parent) => childrenExist(parent));
}

export function returnFirstIndexTab(config) {
  if (!config[0].children) {
    return config[0];
  }
  return returnFirstIndexTab(config[0].children) || {};
}

function findMatchingPath(config, path) {
  return _.reduce(config, (match, curr) => {
    if (!curr.children) {
      return _.startsWith(path, curr.path) ? curr.path : match;
    }
    return findMatchingPath(curr.children, path) || match;
  }, '');
}

function getFirstAvailablePath(navConfig) {
  return returnFirstIndexTab(filterIndexTabs(navConfig)).path;
}

export function getRedirectRoute(navConfig, prevPath) {
  const matchingPath = prevPath && findMatchingPath(navConfig, prevPath);
  return matchingPath || getFirstAvailablePath(navConfig);
}

export function formatInteraction(data = {}) {
  if (!data.interacted_at || _.get(data, 'interaction_count', '').length === 0) {
    return '';
  }
  const interactionDate = moment.unix(data.interacted_at).utc();
  const today = moment(interactionDate).isSame(moment().utc(), 'day');

  const interactionsCount = data.interaction_count;
  const when = today ? 'last at' : 'last on';

  return `${interactionsCount}, ${when} ${dates.formatTableDate(data.interacted_at)}`;
}
