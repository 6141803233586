import PropTypes from 'prop-types';
import React from 'react';
import DashboardRowIcon from 'src/components/Dashboard/components/DashboardRowIcon';
import { getCaseIconAndTooltip } from 'src/components/Cases/utils';
import { connect } from 'react-redux';
import { hint542SupersetUnlistedPrograms as hint542SupersetUnlistedProgramsflag } from 'common/utils/FeatureFlags/flags';

const CaseRowIcon = ({
  iconStyle,
  isCaseMgmtProvider,
  serviceCase,
  hint542SupersetUnlistedPrograms,
}) => {
  const { icon, iconTooltip } = getCaseIconAndTooltip({
    iconStyle,
    isCaseMgmtProvider,
    serviceCase,
    hint542SupersetUnlistedPrograms,
  });

  return (
    <DashboardRowIcon
      iconTooltip={iconTooltip}
      permissions={serviceCase.permissions}
    >
      {icon}
    </DashboardRowIcon>
  );
};

CaseRowIcon.propTypes = {
  iconStyle: PropTypes.object,
  isCaseMgmtProvider: PropTypes.bool.isRequired,
  hint542SupersetUnlistedPrograms: PropTypes.bool.isRequired,
  serviceCase: PropTypes.shape({
    permissions: PropTypes.object.isRequired,
  }).isRequired,
};

CaseRowIcon.defaultProps = {
  iconStyle: {
    width: '14px',
  },
};

function mapStateToProps(state) {
  return {
    hint542SupersetUnlistedPrograms: hint542SupersetUnlistedProgramsflag(state),
  };
}

export default connect(mapStateToProps)(CaseRowIcon);
