import React from 'react';
import { TextButton } from 'src/common/TailwindComponents';
import PropTypes from 'prop-types';
import SpinIcon from 'src/common/spinners/SpinIcon';

const SelectAllRowsButton =
 ({
   selectedRows, deselectAll, allRowsLoading,
}) => (
  <div className="flex items-end divide-x divide-solid divide-dark-border-blue mr-5 mb-3">
    <span className="pr-2">
      {allRowsLoading ? (
        <SpinIcon />
          ) : (
            <div className="text-text-blue font-regular">
              {selectedRows.length > 0 ? selectedRows.length : 'None'}
              &nbsp;selected
            </div>
          )}
    </span>
    <TextButton onClick={deselectAll} className="pl-2" ariaLabel="Deselect all rows">None</TextButton>
  </div>
);

SelectAllRowsButton.propTypes = {
  selectedRows: PropTypes.array.isRequired,
  deselectAll: PropTypes.func.isRequired,
  allRowsLoading: PropTypes.bool,
};

SelectAllRowsButton.defaultProps = {
  allRowsLoading: false,
};

export default SelectAllRowsButton;
