import React from 'react';
import { Icon } from '@unite-us/ui';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

const BackButton = ({ router }) => (
  <button
    className="flex p-2 border border-solid border-dark-border-blue rounded hover:bg-light-fill-blue"
    type="button"
    onClick={() => {
      router.goBack();
    }}
  >
    <div className="h-4 w-4">
      <Icon
        className="h-4 w-4 pb-1 stroke-current stroke-1 fill-current text-text-blue"
        icon="IconChevronLeft"
      />
    </div>
    <div className="pl-2 text-text-blue">
      Back
    </div>
  </button>
);

BackButton.propTypes = {
  router: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(BackButton);
