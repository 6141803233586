import _ from 'lodash';
import createMergedMilitaryObject from 'src/components/Contacts/utils/createMergedMilitaryObject';
import mergeAddresses from 'src/components/Contacts/utils/mergeAddresses';

const OMITTED_PROPERTIES = [
  'created_by',
  'updated_by',
  'created_at',
  'updated_at',
  '_meta',
  'permissions',
  'id',
  'consent',
];

function mergeBy(values1 = [], values2 = [], property) {
  const newValues = [..._.differenceBy(values2, values1, (v) => _.get(v, property))];
  const formattedValues = _.map(newValues, (v) => _.uuOmit(v, OMITTED_PROPERTIES));

  if (_.isEmpty(formattedValues)) {
    return null;
  }

  return formattedValues;
}

function handleArrayMerge(key, cValue, rValue) {
  switch (key) {
    case 'email_addresses':
      return mergeBy(cValue, rValue, 'email_address');

    case 'phone_numbers':
      return mergeBy(cValue, rValue, 'phone_number');

    case 'addresses':
      return mergeAddresses(cValue, rValue);

    default:
      return _.uniq([...(cValue || []), ...(rValue || [])]);
  }
}

function mergeProperty(key, cValue, rValue) {
  const isRequestorValueValid = !_.isNull(rValue) && !_.isEmpty(rValue);
  const canReplaceContactValue = _.isNull(cValue) || _.isEmpty(cValue) || cValue === 'undisclosed';

  if (isRequestorValueValid) {
    if (_.isArray(rValue)) {
      return handleArrayMerge(key, cValue, rValue);
    }

    if (key === 'military') {
      return createMergedMilitaryObject(cValue, rValue);
    }

    if (canReplaceContactValue) {
      return rValue;
    }
  }
  return null;
}

function mergeContactWithRequestorDetails(contact = {}, requestor = {}) {
  const newContact = {};

  _(contact)
    .keys()
    .each((key) => {
      const value = mergeProperty(key, _.get(contact, key), _.get(requestor, key));

      if (!_.isNil(value) || !_.isEmpty(value)) {
        newContact[key] = value;
      }
    });

  return _.uuOmit(newContact, OMITTED_PROPERTIES);
}

export default mergeContactWithRequestorDetails;
