import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InputField } from '@unite-us/ui';
import { noop } from 'lodash';
import './stylesheets/formFieldWrapper.scss';

class FormFieldWrapper extends Component {
  render() {
    const {
      children,
      field,
      hint,
      id,
      label,
      registerField,
      required,
      validations,
    } = this.props;

    const labelClasses = () => classNames({
      'ui-form-field__label': true,
      'ui-form-field__label--required': required,
    });
    return (
      <div className="form-field-wrapper ui-form-field">
        <label htmlFor="field-validation" className={labelClasses()}>
          {label}
        </label>
        {children}
        <div className="form-field-wrapper__hidden-field">
          <InputField
            id={id}
            hideLabel
            hint={hint}
            label={`${label}-hidden-input`}
            field={field}
            ref={registerField}
            required
            validations={validations}
          />
        </div>
      </div>
    );
  }
}

FormFieldWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  field: PropTypes.object.isRequired,
  hint: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  registerField: PropTypes.func,
  required: PropTypes.bool,
  validations: PropTypes.any,
};

FormFieldWrapper.defaultProps = {
  hint: '',
  registerField: noop,
  required: false,
  validation: null,
};

export default FormFieldWrapper;
