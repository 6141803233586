import _ from 'lodash';

function getAuthorizationStatusesOptions(
  state = {},
) {
  return [
    {
      label: 'Accepted',
      value: 'approved',
      initial: _.includes(state.filters.authorization_status_filter, 'approved'),
    },
    {
      label: 'Pending Authorization',
      value: 'requested',
      initial: _.includes(state.filters.authorization_status_filter, 'requested'),
    },
    {
      label: 'Rejected',
      value: 'denied',
      initial: _.includes(state.filters.authorization_status_filter, 'denied'),
    },
  ];
}

export default getAuthorizationStatusesOptions;
