import { get, sortBy, isNil } from 'lodash';
import { useFind, usePopulateMemo } from 'src/api/APIHooks';

export default function useAvailableForms({ providerId, usageTypes, relevantServices }) {
  const formsQuery = useFind('form', {
    'form_usages.form_utilizer.state': 'active',
    'form_usages.provider': providerId,
    ...(!isNil(usageTypes) && { 'form_usages.usage_type': usageTypes }),
    ...(!isNil(relevantServices) && { 'form_usages.relevant_services': relevantServices }),
  }, {
    api: 'coreApi',
    queryConfig: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      staleTime: Infinity,
    },
  });

  const formsData = get(formsQuery, 'data.data.data', []);
  const forms = sortBy(formsData, 'name');

  const isFetching = usePopulateMemo(formsQuery.data, [formsQuery]);

  return {
    isFetching,
    forms,
  };
}
