import Notifier from 'common/helpers/Notifier';
import { ADD_NOTE_INTERACTION } from 'actions';
import { coreApi } from 'src/api/config';
import { get } from 'lodash';
import { delKeys } from 'src/api/utils/delKeys';

const DEFAULT_OPTIONS = {
  sendSuccessNotification: true,
  sendSuccessMessage: 'Note Successfully Added',
};

function createNote({
  interaction,
  service_provided,
  opts = {},
  subjectType,
  subject,
  context,
}) {
  return async (dispatch, getState) => {
    try {
      const currentEmployee = get(getState(), 'globalState.currentEmployee');
      const options = { ...DEFAULT_OPTIONS, ...opts };

      const interactionTypeMappings = {
        phone_call: 'phone',
        note: null,
        email: 'email',
        meeting: 'meeting',
      };

      const category = interaction.category || interaction.attached_to;
      const note = delKeys({
        subjectType,
        subject,
        employee: currentEmployee.id,
        visibility: interaction.is_note_private ? 'private' : 'public',
        category,
        text: interaction.note,
        details: {
          interaction: {
            date: interaction.occurred_on,
            duration: interaction.duration ? parseInt(interaction.duration, 10) : null,
            type: interactionTypeMappings[interaction.type],
          },
          service_provided,
          context,
        },
      });

      const response = await coreApi.createRecord('note', note);

      dispatch({ type: ADD_NOTE_INTERACTION });
      if (options.sendSuccessNotification) {
        Notifier.dispatch(response.status, options.sendSuccessMessage);
      }
      return response;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}

export default createNote;
