import { get } from 'lodash';
import { addContactIdToScreening } from './index';

const formatScreeningPayload = (payload) => ({
  data: {
    paging: get(payload, 'data.paging'),
    data: addContactIdToScreening(get(payload, 'data.data', [])),
  },
});

export default formatScreeningPayload;
