import { Icon } from '@unite-us/ui';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

export const ExpiryMessage = ({ expiredDateString }) => {
  const date = moment(expiredDateString);
  const formattedDate = date.format('MM/DD/YY[ at ]h[:]mm a');
  return (
    <div className="flex items-center my-2 px-3 py-2">
      <Icon
        icon="IconExclamationCircle"
        className="fill-current text-red mr-1"
      />
      <p>Per an NCTracks query performed on {formattedDate}, the member’s enrollment in this plan has expired.</p>
    </div>
  );
};

ExpiryMessage.propTypes = {
  expiredDateString: PropTypes.string.isRequired,
};
