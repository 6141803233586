import React from 'react';
import {
  VerbalConsentScript,
} from '@unite-us/client-utils';
import './VerbalConsentScript.scss';

const VerbalConsentScriptPage = () => (
  <div className="verbal-consent-script container">
    <VerbalConsentScript showBaseCard />
  </div>
);

export default VerbalConsentScriptPage;
