import {
  acceptedClosedConfig,
  acceptedOpenConfig,
  allConfig,
  inReviewConfig,
  needsActionConfig,
  pendingConsentConfig,
} from './sentReferralsConfigs';

function getReferralColumnConfig(routeStatus, statuses = [], actions = [], isSuperNetwork) {
  switch (routeStatus) {
    case 'accepted/closed':
      return acceptedClosedConfig(isSuperNetwork);

    case 'accepted/open':
      return acceptedOpenConfig(isSuperNetwork);

    case 'in-review':
      return inReviewConfig(isSuperNetwork);

    case 'needs-action':
      return needsActionConfig(isSuperNetwork);

    case 'pending-consent':
      return pendingConsentConfig(actions, isSuperNetwork);

    case 'all':
    default:
      return allConfig(statuses, isSuperNetwork);
  }
}

export default getReferralColumnConfig;
