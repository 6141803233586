import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Serializer } from '@unite-us/client-utils';
import { ShareDrawer } from '@unite-us/shares-utils';
import { shareGroupsDetails } from 'actions/Group';
import { SHARES_URL } from 'src/config/env/env.config';
import callOrLog from 'src/common/utils/callOrLog';
import { MY_NETWORKS } from 'common/utils/EventTracker/utils/eventConstants';
import { get } from 'lodash';
import { coreApi } from 'src/api/config';
import '../stylesheets/group-details-send-message.scss';

export class GroupDetailsSendMessage extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.trackSharedGroup = this.trackSharedGroup.bind(this);
  }

  onSubmit({ shareMethod, language }) {
    this.trackSharedGroup({ messageType: shareMethod, language });
  }

  trackSharedGroup({ messageType, language }) {
    const { group, network } = this.props;

    callOrLog(() => this.context.eventTracker(MY_NETWORKS.shareClicked, {
      ...Serializer.build({ sharedGroup: group }),
      browse_network_id: get(network, 'id'),
      browse_network_name: get(network, 'name'),
      shared_group_count: 1,
      shared_message_type: messageType,
      shared_language_code: language,
      share_type: 'providers',
    }));
  }

  render() {
    const {
      currentProviderId, group, sendProviderOpen, employeeId,
    } = this.props;

    return (
      <div className="group-details-send-message">
        <ShareDrawer
          employeeId={employeeId}
          coreApi={coreApi}
          SHARES_URL={SHARES_URL}
          show={sendProviderOpen}
          onClose={this.props.onToggle}
          resourceType="provider"
          resources={[group]}
          onShare={
            ({ shareMethod, language }) => this.onSubmit({ shareMethod, language })
          }
          providerId={currentProviderId}
        />
      </div>
    );
  }
}

GroupDetailsSendMessage.propTypes = {
  currentProviderId: PropTypes.string.isRequired,
  group: PropTypes.object.isRequired,
  employeeId: PropTypes.string.isRequired,
  sendProviderOpen: PropTypes.bool,
  network: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onToggle: PropTypes.func.isRequired,
};

GroupDetailsSendMessage.defaultProps = {
  sendProviderOpen: false,
};

GroupDetailsSendMessage.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentProviderId: state.session.groupId,
    employeeId: state.globalState?.currentEmployee?.id,
  };
}

export default connect(mapStateToProps, {
  shareGroupsDetails,
})(GroupDetailsSendMessage);
