import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  tracker,
} from '@unite-us/client-utils';
import IndexDetailView from 'src/components/Dashboard/components/IndexDetailView';
import ClosedReferralsIndex from 'src/components/Dashboard/components/Referrals/ClosedReferralsIndex';
import { fetchDashboardReferrals } from 'actions/Referral/Group';
import _ from 'lodash';
import DetailView from 'src/components/Dashboard/components/DetailView';
import ReferralDetail from 'src/components/Referrals/ReferralDetail/index';
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';
import {
  noneAssignedOption,
  careCoordinatorFilterDefault,
} from 'src/components/Dashboard/utils/filterDefaults';
import { getDataOfPage, isDataValid } from 'src/components/Dashboard/utils/dataHelpers';
import appendReferralIdToLocation from 'src/components/Dashboard/utils/appendReferralIdToLocation';
import callOrLog from 'src/common/utils/callOrLog';
import { goToReferral } from 'src/components/Referrals/utils/routing';
import { careCoordinatorsFilter, serviceTypesFilter } from 'src/components/Dashboard/utils/filter';
import { DASHBOARD_EVENTS, DASHBOARD_VIEWS } from 'common/utils/EventTracker/utils/eventConstants';
import { hideMoreFiltersOption, crtb1290SortReferralsByOldest } from 'src/common/utils/FeatureFlags/flags';
import { getGroupUsersOptions, getSentByOptions } from 'src/components/Groups/Users/utils';
import { getStatus, sortStatuses } from 'src/components/Dashboard/components/Referrals/SentReferrals/utils';
import labelCustomization from 'src/common/utils/Customization/labels';
import defaultLabels from 'src/constants/labels';

export class ClosedReferrals extends Component {
  constructor(props) {
    super(props);
    this.onCellClick = this.onCellClick.bind(this);
    this.onFiltersChange = this.onFiltersChange.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.shouldFetch = this.shouldFetch.bind(this);
    this.fetch = this.fetch.bind(this);
    this.fetchGroupsUsers = this.fetchGroupsUsers.bind(this);
    this.fetchSentByUsers = this.fetchSentByUsers.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onToggle = this.onToggle.bind(this);

    this.state = {
      activeUsersInGroup: [],
      page: 1,
      sidx: 'updated_at',
      sord: 'desc',
      filters: {
        service_types: _.get(props, 'filters.service_types', []),
        care_coordinator_users: _.get(
          props,
          'filters.care_coordinator_users',
          careCoordinatorFilterDefault(props.userId, props.isCaseManager && props.isCC),
        ),
        sent_by: _.get(
          props,
          'filters.sent_by',
         [props.currentEmployee.id],
        ),
        resolved: _.get(props, 'filters.resolved', 'all'),
        sent: _.get(props, 'filters.sent', null),
        status: getStatus(props),
      },
      employees: [],
      open: false,
    };
  }

  componentDidMount() {
    this.fetch();
    this.props.fetchProvidersUserCore({
      providers: this.props.groupId,
    }).then((response) => {
      if (response) {
        const { employees: activeUsersInGroup } = response;
        this.setState({ activeUsersInGroup, employees: response.employees });
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.get(this.props, 'closedReferrals.currentPage', 1) !== _.get(nextProps, 'closedReferrals.currentPage', 1)) {
      this.setState({ page: nextProps.closedReferrals.currentPage });
    }

    if (!this.props.refetch && nextProps.refetch) {
      this.fetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.fetch();
    }
  }

  onClose() {
    this.setState({ open: false });
  }

  onToggle() {
    const { open } = this.state;
    this.setState({ open: !open });
  }

  onCellClick(row) {
    const pagedData = getDataOfPage(this.props.closedReferrals, this.state.page, this.props.sortAscending);
    const referral = pagedData.data[row];

    callOrLog(() => this.context.eventTracker(DASHBOARD_EVENTS.closedReferralsRow, {
      view: DASHBOARD_VIEWS.closedReferrals,
    }, { referral }));

    goToReferral({ ...referral, subRoute: 'closedReferrals' });
  }

  onFiltersChange(key, activeFilters, callbacks) {
    let newFilters = activeFilters;
    if (_.isEqual(key, 'care_coordinator_users')) {
      newFilters = careCoordinatorsFilter(activeFilters, callbacks);
    }
    this.setState({
      page: 1,
      filters: _.assign({}, this.state.filters, { [key]: newFilters }),
    }, () => {
      callOrLog(() => {
        const filters = tracker.buildDashboardFilterPayload(this.state, this.props);
        this.context.eventTracker(DASHBOARD_EVENTS.closedReferralsFilter, filters);
      });
      this.fetch();
    });
  }

  onNextClick() {
    const page = this.state.page + 1;

    this.shouldFetch(page);
  }

  onPrevClick() {
    const page = this.state.page - 1;

    this.shouldFetch(page);
  }

  shouldFetch(page) {
    if (isDataValid(this.props.closedReferrals, page)) {
      this.setState({ page });
    } else {
      this.fetch(page);
    }
  }

  fetch(page = 1) {
    let formattedFilters = this.state.filters;
    formattedFilters.sent_by = _.uniq(
      _.map(formattedFilters.sent_by, (f) => f.split('-employee')[0]),
    );
    if (this.state.filters.status === 'all') {
      formattedFilters = _.omit(formattedFilters, ['status']);
    }
    this.props.fetchDashboardReferrals(
      this.props.groupId,
      'closed',
      {
        target: 'closedReferrals',
        sidx: this.state.sidx,
        sord: this.state.sord,
        filters: formattedFilters,
        page,
      },
      'closed',
      null,
    );
  }

  fetchGroupsUsers(search = '') {
    const { groupId } = this.props;
    return this.props.fetchProvidersUserCore({
      providers: groupId,
      options: {
        text: search,
        licensed: false,
      },
    }).then((response) => {
      if (response) {
        const { employees: activeUsersInGroup } = response;
        if (search === '') {
          return _.concat(
            [noneAssignedOption(this.state)],
            getGroupUsersOptions(activeUsersInGroup, this.state),
          );
        }

        return getGroupUsersOptions(activeUsersInGroup, this.state);
      }

      return [];
    });
  }

  fetchSentByUsers(search = '') {
    const { groupId, currentEmployee } = this.props;
    return this.props.fetchProvidersUserCore({
      providers: groupId,
      options: {
        licensed: false,
        text: search,
      },
    })
      .then((response) => {
        if (response) {
          return getSentByOptions(
            response.employees,
            this.state,
            currentEmployee,
          );
        }

        return [];
      });
  }

  render() {
    const {
      currentEmployee,
      closedReferrals,
      isFetching,
      isCC,
      baseLocation,
      params,
      serviceTypes,
      showMoreFilters,
      labels,
    } = this.props;
    const { filters, open } = this.state;
    const { activeUsersInGroup, employees } = this.state;
    const location = appendReferralIdToLocation(baseLocation, params);

    const statuses = [
      { display_name: 'Rejected', value: 'declined' },
      { display_name: 'Recalled', value: 'recalled' },
      { display_name: 'Draft', value: 'draft' },
    ];
    const columnFilters = _.compact([
      serviceTypesFilter(serviceTypes, this.state.filters.service_types),
      {
        key: 'care_coordinator_users',
        name: labels.CareCoordinator,
        pluralName: `${labels.CareCoordinator}s`,
        options: _.concat(
          [noneAssignedOption(this.state.filters.care_coordinator_users)],
          getGroupUsersOptions(activeUsersInGroup, this.state),
        ),
      },
      {
        key: 'sent_by',
        name: 'Sent By',
        pluralName: 'Sent By',
        options: getSentByOptions(employees, this.state, currentEmployee),
        asyncSearch: this.fetchSentByUsers,
        secondary: !showMoreFilters,
      },
      {
        key: 'resolved',
        name: 'Resolution',
        single: true,
        options: [
          { label: 'Resolution (All)', value: 'all' },
          { label: 'Resolved', value: 'true' },
          { label: 'Unresolved', value: 'false' },
        ],
        value: filters.resolved,
      },
      {
        key: 'status',
        name: 'Status',
        pluralName: 'Statuses',
        single: true,
        options: sortStatuses(statuses),
        value: filters.status,
      },
    ]);

    const innerContentView = (
      <ReferralDetail
        subjectType="referral"
        key={params.id}
        location={location}
        params={params}
        type="closedReferrals"
        showContactColumn
      />
    );

    const closedReferralsDetailView = (
      <DetailView
        innerContentView={innerContentView}
      />
    );
    const pagedData = getDataOfPage(closedReferrals, this.state.page, this.props.sortAscending);

    return (
      <IndexDetailView
        params={this.props.params}
        DetailView={closedReferralsDetailView}
        IndexView={ClosedReferralsIndex}
        data={pagedData.data}
        paging={pagedData.paging}
        isFetching={isFetching}
        onNextClick={this.onNextClick}
        onPrevClick={this.onPrevClick}
        onCellClick={this.onCellClick}
        initialSort={{ [this.state.sidx]: this.state.sord }}
        isCC={isCC}
        onFiltersChange={this.onFiltersChange}
        filters={_.sortBy(columnFilters, 'name')}
        fetchGroupsUsers={this.fetchGroupsUsers}
        onClose={this.onClose}
        onToggle={this.onToggle}
        open={open}
        showMoreFilters={showMoreFilters}
      />
    );
  }
}

ClosedReferrals.propTypes = {
  location: PropTypes.object,
  currentEmployee: PropTypes.object.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  fetchDashboardReferrals: PropTypes.func.isRequired,
  closedReferrals: PropTypes.shape({
    data: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
  }).isRequired,
  userId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isCC: PropTypes.bool.isRequired,
  baseLocation: PropTypes.string.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  refetch: PropTypes.bool.isRequired,
  isCaseManager: PropTypes.bool.isRequired,
  showMoreFilters: PropTypes.bool,
  labels: PropTypes.object,
  sortAscending: PropTypes.bool,
};

ClosedReferrals.defaultProps = {
  labels: defaultLabels,
  sortAscending: false,
};

function mapStateToProps(state, ownProps) {
  const groupId = _.wget(state, 'session.groupId');
  return {
    currentEmployee: state.globalState.currentEmployee,
    baseLocation: ownProps.location.pathname,
    groupId,
    closedReferrals: _.get(state, 'dashboard.closedReferrals', {}),
    filters: _.get(state, 'dashboard.closedReferrals.filters', {}),
    isFetching: _.get(state, 'dashboard.closedReferrals.isFetching', false),
    isCC: _.wget(state, 'session.isCoordinationGroup', false),
    serviceTypes: _.wget(state, 'serviceTypes.allNetworks', []),
    refetch: _.get(state, 'dashboard.refetch'),
    userId: _.wget(state, 'user.id', null),
    token: _.get(state, 'session.token', ''),
    showMoreFilters: hideMoreFiltersOption(state),
    labels: labelCustomization(state),
    sortAscending: crtb1290SortReferralsByOldest(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ fetchDashboardReferrals, fetchProvidersUserCore }, dispatch),
    dispatch,
  };
}

ClosedReferrals.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClosedReferrals);
