import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import ListViewDocument from './ListViewDocument';

const EMPTY_DOCUMENTS = (
  <p className="empty-documents-text">No Attached Documents</p>
);

class DocumentsListView extends PureComponent {
  render() {
    const {
      documents, editable, hideTitle, label,
    } = this.props;

    const uploads = isEmpty(documents) ?
      EMPTY_DOCUMENTS :
      documents.map((doc, i) => (
        <ListViewDocument
          id={`document-${i}`}
          key={i}
          doc={doc}
          editable={editable}
          onRemoveDocument={this.props.onRemoveDocument}
          onRenameDocument={this.props.onRenameDocument}
        />
      ));

    return (
      <div className="documents-list-view detail-info__relationship">
        {
          !hideTitle && (
            <h5 className="documents-list-view__title detail-info__relationship-label">
              {`${label} (${documents.length})`}
            </h5>
          )
        }
        <ul className="detail-info__relationship-files list-unstyled">
          {uploads}
        </ul>
      </div>
    );
  }
}

DocumentsListView.propTypes = {
  documents: PropTypes.array,
  editable: PropTypes.bool,
  label: PropTypes.node,
  onRemoveDocument: PropTypes.func,
  onRenameDocument: PropTypes.func,
  hideTitle: PropTypes.bool,
};

DocumentsListView.defaultProps = {
  documents: [],
  hideTitle: false,
  label: 'Uploads',
};

export default DocumentsListView;
