import _ from 'lodash';

function atLeastOneGroupValue(fieldValues = []) {
  return _.some(fieldValues, (field = {}) => {
    const hasStringValue = _.isString(field.group) && !_.isEmpty(field.group);
    const hasObjectValue = _.isObject(field.group) && !_.isEmpty(_.get(field, 'group.id'));
    return hasStringValue || hasObjectValue;
  });
}

export default atLeastOneGroupValue;
