import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import moment from 'moment';
import cx from 'classnames';
import { isEmpty, sortBy, last, get } from 'lodash';
import { Icon } from '@unite-us/ui';
import IconButton from 'src/common/display/Profile/components/IconButton';
import {
  useFind,
  useDeleteRecord,
} from 'src/api/APIHooks';
import { useFeatureFlag } from 'common/hooks';
import { defaultMutationConfig } from 'src/components/Backoffice/api/hooks/apiHookOptions';
import DeleteProvidedServiceConfirmationDialog from 'src/components/Cases/components/Detail/DeleteProvidedServiceConfirmationDialog';
import './FeeScheduleProvidedServiceCardData.scss';

const FeeScheduleProvidedServiceCardHeader = ({
  className,
  isBillable,
  isNetworkLead,
  metafields,
  onEdit,
  onSubmit,
  providedService,
  programName,
  showInteractions,
  onDelete,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const isEditingDisabled = providedService.state !== 'active';
  const isDeleteDisabled = isEditingDisabled || !!providedService.invoices?.length;

  const metadata = providedService.metadata || [];

  const requiredMetaDataMissing = metafields.some(({ field, validations }) => {
    if (validations && validations.required) {
      const metaDataMatch = metadata.find((md) => md.field === field);

      if (!metaDataMatch) return true;

      const value = metaDataMatch.value || {};

      const metaDataMatchEmpty = field === 'address_field' ? (
        !value.line_1 || !value.city || !value.state || !value.postal_code
      ) : isEmpty(metaDataMatch.value);

      return metaDataMatchEmpty;
    }

    return false;
  });

  const { data: invoiceApiData } = useFind(
    'invoice',
    {
      provided_service: providedService.id,
    },
    {
      queryConfig: {
        placeholderData: undefined,
      },
    },
  );
  const invoicesData = get(invoiceApiData, 'data.data', []);

  const invoices = sortBy(invoicesData, 'created_at');

  const isSubmitDisabled = requiredMetaDataMissing ||
    !providedService.unit_amount ||
    !providedService.starts_at;

  const showSubmitButton = showInteractions && isBillable && providedService.state === 'active';

  const deleteProvidedServiceConfirmationDialogRef = useRef(null);

  const deleteProvidedService = useDeleteRecord('provided_service', {
    mutationConfig: {
      onSuccess: () => {
        onDelete();
      },
      ...defaultMutationConfig,
    },
  });

  const allowDeletion = useFeatureFlag('pays-2012-allow-deletion-contracted-service-notes');

  const handleSubmit = async () => {
    setSubmitting(true);
    await onSubmit();
    setSubmitting(false);
  };

  return (
    <div className={className}>
      <div className="flex justify-between space-x-4">
        <h3 className="flex-grow text-lg text-text-blue leading-snug">
          {programName} Provided
        </h3>
        {showInteractions && (
          <div className="flex flex-shrink items-start space-x-6 mt-1">
            <IconButton
              className={cx(!showSubmitButton && 'hidden')}
              disabled={isSubmitDisabled || submitting}
              icon="IconSubmit"
              id="submit-provided-service"
              labelText="Submit"
              onClick={() => handleSubmit()}
            />
            {(allowDeletion && isBillable) && (
              <>
                <IconButton
                  disabled={isDeleteDisabled}
                  icon="IconTrash"
                  labelText="Delete"
                  id="delete-provided-service"
                  onClick={() => {
                  deleteProvidedServiceConfirmationDialogRef.current.openDialog();
                }}
                />
                <DeleteProvidedServiceConfirmationDialog
                  ref={deleteProvidedServiceConfirmationDialogRef}
                  onConfirmation={() => deleteProvidedService.deleteRecord(providedService.id, {})}
                />
              </>
            )}
            <IconButton
              disabled={isEditingDisabled}
              icon="IconPencil"
              labelText="Edit"
              id="edit-provided-service"
              onClick={onEdit}
            />
          </div>
        )}
      </div>
      {isBillable && (
        <div className="mt-2 flex space-x-2">
          <span className="text-dark-grey whitespace-no-wrap">
            {moment(providedService.updated_at).utc().format('LL')}
          </span>
          <div className="flex space-x-1 -ml-3 lg:ml-0">
            {providedService.state !== 'active' ? (
              <>
                <Icon
                  className="text-light-green fill-current pt-px"
                  icon="IconCircle"
                  size={24}
                />
                <span className="text-text-blue whitespace-no-wrap">Submitted</span>
              </>
            ) : (
              <>
                <Icon
                  className="text-yellow fill-current pt-px"
                  icon="IconCircle"
                  size={24}
                />
                <span className="text-text-blue whitespace-no-wrap">Pending</span>
              </>
            )}
          </div>
          {
            !isEmpty(invoices) && !isNetworkLead && (
              <Link
                className="whitespace-no-wrap"
                to={`/invoices/${last(invoices).id}`}
              >
                Go to Invoice
              </Link>
            )
          }
        </div>
      )}
    </div>
  );
};

FeeScheduleProvidedServiceCardHeader.propTypes = {
  className: PropTypes.string,
  isBillable: PropTypes.bool.isRequired,
  isNetworkLead: PropTypes.bool,
  metafields: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  programName: PropTypes.string.isRequired,
  providedService: PropTypes.object.isRequired,
  showInteractions: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
};

FeeScheduleProvidedServiceCardHeader.defaultProps = {
  className: '',
  isNetworkLead: false,
};

export default FeeScheduleProvidedServiceCardHeader;
