import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import callOrLog from 'src/common/utils/callOrLog';
import { SEARCH } from 'common/utils/EventTracker/utils/eventConstants';
import { goToSearchIndex } from '../utils';
import SearchBarTableRow from './SearchBarTableRow';
import SearchBarTableHeader from './SearchBarTableHeader';

const SearchBarTable = ({ model, numResults, sectionResults }, context) => {
  const {
    closeSearchResults,
    eventTracker,
    query,
  } = context;

  const onViewAllClick = () => {
    closeSearchResults();
    callOrLog(() => eventTracker(SEARCH.viewAllResults, { model }));
    goToSearchIndex({ query, model });
  };

  return (
    <div className="search-results-section-table">
      <table className="search-results-section-table__table">
        <thead>
          <SearchBarTableHeader
            model={model}
          />
        </thead>
        <tbody>
          {
            _.take(sectionResults, numResults).map((result, key) => (
              <SearchBarTableRow
                index={key}
                key={`${model}-${key}`}
                model={model}
                result={result}
              />
            ))
          }
        </tbody>
      </table>

      <div className="search-results-section-table__view-all">
        <a id={`search-results-section-table__view-all-${model}`} onClick={onViewAllClick}>
          View All
        </a>
      </div>
    </div>
  );
};

SearchBarTable.propTypes = {
  numResults: PropTypes.number.isRequired,
  model: PropTypes.string.isRequired,
  sectionResults: PropTypes.array.isRequired,
};

SearchBarTable.contextTypes = {
  closeSearchResults: PropTypes.func.isRequired,
  eventTracker: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
};

export default SearchBarTable;
