import {
  useGetBase,
  usePostBase,
} from '@unite-us/json-api-resources';
import { useQuery as useQueryRQ, useMutation } from 'react-query';
import { isString } from 'lodash';
import { getAdapter } from './config';

const defaultApi = 'coreApiClientBase';

const getApi = (api) => (
  isString(api) ?
    getAdapter(api) :
    api
  );

export const usePostGeneric = (url, options = {}) => {
  const callback = usePostBase({
    useMutation,
    mutationConfig: options.mutationConfig,
  });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.doExecute = (data, overrideOptions = {}) => {
    const api = getAdapter(options.api ?? defaultApi);
    const newData = {
      url,
      data,
      params: { method: 'POST', api, ...overrideOptions },
    };

    return mutateAsync(newData, overrideOptions.mutationConfig);
  };

  return callback;
};

export const useGet = (url, query, params = {}) => {
  const { api = defaultApi, queryConfig = {}, ...rest } = params;
  return useGetBase(
    url,
    query,
    {
      api: getApi(api),
      useQuery: useQueryRQ,
      queryConfig: { enabled: true, ...queryConfig },
      ...rest,
    },
  );
};

export const usePost = (url, options = {}) => {
  const callback = usePostGeneric(url, options);

  callback.postFn = callback.doExecute;
  callback.doExecute = null;

  return callback;
};

export const usePatch = (url, options = {}) => {
  const callback = usePostGeneric(url, options);

  const doExecute = callback.doExecute;
  callback.patchFn = (data, overrideOptions = {}) => (
    doExecute(data, {
      ...overrideOptions,
      method: 'PATCH',
    })
  );
  callback.execute = null;

  return callback;
};

export const useDelete = (url, options = {}) => {
  const callback = usePostGeneric(url, options);

  const doExecute = callback.doExecute;
  callback.deleteFn = (data, overrideOptions = {}) => doExecute(data, {
    ...overrideOptions,
    method: 'DELETE',
  });
  callback.doExecute = null;

  return callback;
};

export const usePut = (url, options = {}) => {
  const callback = usePostGeneric(url, options);

  const doExecute = callback.doExecute;
  callback.putFn = (data, overrideOptions = {}) => doExecute(data, {
    ...overrideOptions,
    method: 'PUT',
  });
  callback.doExecute = null;

  return callback;
};
