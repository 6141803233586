import {
  useDeleteRecord,
  useInvalidateQueries,
} from 'src/api/APIHooks';
import { defaultMutationConfig } from 'src/pages/facesheet/_id/Relationships/Common/api/apiHookOptions';

export const useDeleteNote = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useDeleteRecord('note', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('notes');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export default useDeleteNote;
