import React from 'react';
import PropTypes from 'prop-types';
import './stylesheets/fieldErrorMessage.scss';

const FieldErrorMessage = ({ message }) => (
  <div className="field-error-message ui-form-field">
    <p className="ui-form-field__error">
      {message}
    </p>
  </div>
);

FieldErrorMessage.propTypes = {
  message: PropTypes.string,
};

FieldErrorMessage.defaultProps = {
  message: '',
};

export default FieldErrorMessage;
