import {
  get,
  map,
  includes,
} from 'lodash';

export default function updateInteractables(handlerFn, state, request, payload, currentSection, currentGroup) {
  const interactablePayload = map(payload, (element) => get(element, 'interactable', false));

  if (includes(interactablePayload, false)) { return state; }

  return handlerFn(state, request, interactablePayload, currentSection, currentGroup);
}
