import { isArray, get, isEmpty, map, reject, sortBy } from 'lodash';
var coreServicesBanner = function coreServicesBanner(providerServices) {
  if (!isArray(providerServices)) {
    return providerServices.name;
  }
  var slimServices = reject(providerServices, function (service) {
    return isEmpty(get(service, 'relationships.parent', {}));
  }); // remove parent service types

  var sortedServicesNames = map(sortBy(slimServices, 'attributes.name'), 'attributes.name'); // create an array of service names (strings)

  return sortedServicesNames.length > 1 ? "\n    ".concat(sortedServicesNames[0], " and ").concat(sortedServicesNames.length - 1, " more\n  ") : sortedServicesNames[0];
};
export default coreServicesBanner;