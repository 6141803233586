import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { validations } from '@unite-us/app-components';
import { UUTextField } from '@unite-us/ui';
import './InteractionNoteField.scss';

const InteractionNoteField = ({
  afterLabelContent,
  field,
  hintText,
  label,
  registerField,
  required,
  showHint,
}) => (
  <div className="interaction-note-field form-group">
    <UUTextField
      afterLabelContent={afterLabelContent}
      field={field}
      hint={showHint ? hintText : undefined}
      id="interactionNote"
      label={label}
      placeholder="Enter your note here..."
      required={required}
      rows={5}
      ref={registerField}
      validations={required ? validations.isRequired : noop}
    />
  </div>
);

InteractionNoteField.propTypes = {
  afterLabelContent: PropTypes.node,
  field: PropTypes.object.isRequired,
  hintText: PropTypes.string,
  label: PropTypes.node,
  registerField: PropTypes.func,
  required: PropTypes.bool,
  showHint: PropTypes.bool,
};

InteractionNoteField.defaultProps = {
  afterLabelContent: undefined,
  hintText: '',
  label: 'Note',
  registerField: noop,
  required: true,
  showHint: false,
};

export default InteractionNoteField;
