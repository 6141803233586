import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@unite-us/ui';

const FontSelectField = ({
  id, options, title, value, dataTestId, ...props
}) => {
  const getFontClassName = (fontFamily) => {
    let fontClassName = '';
    if (fontFamily) {
      fontClassName = `font-${
        (typeof fontFamily === 'string') ?
        fontFamily :
        fontFamily?.name
      }`.toLowerCase().replace(/\s+/g, '-');
    }
    return fontClassName;
  };

  const getFontText = (fontFamily) => {
    let result = '';
    result =
      (typeof fontFamily === 'string') ?
      fontFamily :
      fontFamily?.name;
    return result;
  };

  return (
    <>
      <SelectField
        className={`md:col-span-4 xl:col-span-4 px-0 ${id}-select_field`}
        id={`directory-detail__${id}`}
        label={title}
        placeholder="Select Font Family"
        options={options}
        forceObjectValue
        labelKey="name"
        valueKey="name"
        value={value}
        {...props}
      />
      <div className="md:col-span-2 xl:col-span-2 pt-4">
        <div className="relative">
          <input
            className={
              `h-38px px-4 w-full outline-none border border-solid rounded-md shadow-sm ${getFontClassName(value)}`
            }
            readOnly
            value={getFontText(value) || ''}
            data-testid={dataTestId}
            {...props}
          />
        </div>
      </div>
    </>
  );
};

FontSelectField.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  dataTestId: PropTypes.string,
};

FontSelectField.defaultProps = {
  value: '',
  options: [],
  dataTestId: null,
};

export default FontSelectField;
