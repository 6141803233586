import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { VelocityTransitionGroup } from 'velocity-react';
import _ from 'lodash';
import Notification from './components/Notification';
import { isSuccess, isError, isWarning, isInformation } from './utils';

require('./stylesheets/notifications.scss');

export function Notifications(props) {
  const { notifications } = props;
  const successNotifications = _.filter(notifications, (n) => isSuccess(n.status));
  const errorNotifications = _.filter(notifications, (n) => isError(n.status));
  const warningNotifications = _.filter(notifications, (n) => isWarning(n.status));
  const informationNotifications = _.filter(notifications, (n) => isInformation(n.status));

  return (
    <div id="notifications">
      <VelocityTransitionGroup
        enter={{ animation: 'transition.slideDownIn' }}
        leave={{ animation: 'transition.slideUpOut' }}
      >
        {
          !_.isEmpty(successNotifications) && (
            <Notification
              notifications={successNotifications}
              type="success"
            />
          )
        }
      </VelocityTransitionGroup>

      <VelocityTransitionGroup
        enter={{ animation: 'transition.slideDownIn' }}
        leave={{ animation: 'transition.slideUpOut' }}
      >
        {
          !_.isEmpty(errorNotifications) && (
            <Notification
              notifications={errorNotifications}
              type="error"
            />
          )
        }
      </VelocityTransitionGroup>

      <VelocityTransitionGroup
        enter={{ animation: 'transition.slideDownIn' }}
        leave={{ animation: 'transition.slideUpOut' }}
      >
        {
          !_.isEmpty(warningNotifications) && (
            <Notification
              notifications={warningNotifications}
              type="warning"
            />
          )
        }
      </VelocityTransitionGroup>

      <VelocityTransitionGroup
        enter={{ animation: 'transition.slideDownIn' }}
        leave={{ animation: 'transition.slideUpOut' }}
      >
        {
           !_.isEmpty(informationNotifications) && (
           <Notification
             notifications={informationNotifications}
             type="information"
           />
         )
        }
      </VelocityTransitionGroup>
    </div>
  );
}

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return { notifications: state.notifications };
}

export default connect(mapStateToProps)(Notifications);
