import { INSURANCE_STATUSES } from 'components/Insurance/constants';

const clientStatusText = {
  [INSURANCE_STATUSES.pending_enrollment]: 'Pending enrollment',
  [INSURANCE_STATUSES.enrolled]: 'Enrolled',
  [INSURANCE_STATUSES.not_enrolled]: 'Not enrolled',
  [INSURANCE_STATUSES.rejected]: 'Not enrolled',
  [INSURANCE_STATUSES.pending_disenrollment]: 'Pending disenrollment',
};

const getClientStatusText = (insuranceStatus) => (
  Object.keys(clientStatusText).includes(insuranceStatus) && clientStatusText[insuranceStatus]
);

export default getClientStatusText;
