import _ from 'lodash';
import createXHRPromise from 'common/utils/createXHRPromise';
import { UNITEUS_GEOGRAPHY_URL } from 'src/config/env/env.config';
import Notifier from 'common/helpers/Notifier';
import { SET_BROWSE_GEOGRAPHY } from 'actions';

const statesToParams = (st) => _.join(st, ',');

export default function fetchStatesGeography(selectedStates, placePath) {
  return (dispatch) => {
    const states = statesToParams(selectedStates);
    const url = `${UNITEUS_GEOGRAPHY_URL}/${placePath}?states=${states}`;
    const request = createXHRPromise(url); // use exports to allow mocking in test

    function onSuccess(payload) {
      dispatch({
        type: SET_BROWSE_GEOGRAPHY,
        payload,
      });

      return payload;
    }

    function onError(error) {
      Notifier.handleErrors(error);
      return error;
    }

    return request.then(onSuccess, onError);
  };
}
