import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Icon,
} from '@unite-us/ui';
import _ from 'lodash';
import classNames from 'classnames';
import clearSystemNotification from '../actions/clearSystemNotification';

require('../stylesheets/systemNotifications.scss');

export class SystemNotification extends Component {
  constructor(props) {
    super(props);
    this.clearSystemNotification = this.clearSystemNotification.bind(this);
  }

  clearSystemNotification() {
    this.props.clearSystemNotification();
  }

  isError() {
    return this.props.notification.status === 'error' || this.props.notification.status >= 400;
  }

  isSuccess() {
    const status = this.props.notification.status;
    return status === 'success' || (status >= 200 && status < 400);
  }

  isWarning() {
    return this.props.notification.status === 'warning';
  }

  notifClass() {
    return classNames({
      notification: true,
      error: this.isError(),
      success: this.isSuccess(),
      warning: this.isWarning(),
    });
  }

  iconName() {
    const { styles } = this.props;
    if (this.isError()) {
      return (<Icon icon="IconTimesCircle" style={_.merge(styles.icon, styles.error)} />);
    }
    if (this.isSuccess()) {
      return (<Icon icon="IconCheckCircle" style={_.merge(styles.icon, styles.success)} />);
    }
    if (this.isWarning()) {
      return (<Icon icon="IconExclamationCircle" style={_.merge(styles.icon, styles.warning)} />);
    }
    return (<Icon icon="IconInfoCircle" style={styles.icon} />);
  }

  render() {
    const message = this.props.notification.message;
    const dismissable = this.props.notification.dismissable;

    return (
      <div className="notifications-wrapper">
        <div className={this.notifClass()}>
          {this.iconName()}
          {message}
          <a
            role="button"
            className="close"
            onClick={this.clearSystemNotification}
          >
            {dismissable ? <Icon icon="IconTimes" style={this.props.styles.close} /> : null}
          </a>
        </div>
        <div className="spacer" />
      </div>
    );
  }
}

SystemNotification.propTypes = {
  clearSystemNotification: PropTypes.func,
  notification: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
};

SystemNotification.defaultProps = {
  styles: {
    icon: {
      svg: {
        flexBasis: '70px',
        height: '26px',
        width: '26px',
        fill: '#3E4EB8',
      },
    },
    error: {
      svg: {
        fill: '#E53935',
      },
    },
    success: {
      svg: {
        fill: '#47B04B',
      },
    },
    warning: {
      svg: {
        fill: '#FF9900',
      },
    },
    close: {
      svg: {
        height: '20px',
        width: '20px',
      },
    },
  },
};

export default connect(null, { clearSystemNotification })(SystemNotification);
