import {
  useCreateRecord,
  useFind,
  useFindRecord,
  useFindAll,
  useInvalidateQueries,
  useUpdateRecord,
  useSetRelationship,
} from 'src/api/APIHooks';
import Notifier from 'common/helpers/Notifier';
import { defaultQueryConfig, defaultMutationConfig } from '../apiHookOptions';

export const useFindAllFeeSchedules = () => useFindAll('fee_schedule', {
  queryConfig: defaultQueryConfig,
});

export const useFindFeeSchedule = (id) => useFindRecord('fee_schedule', id, {
  queryConfig: defaultQueryConfig,
});

export const useFindFeeScheduleByName = (
  name = '',
  state = '',
  queryConfig = { queryConfig: defaultQueryConfig },
) => useFind(
  'fee_schedule',
  {
    name,
    state,
  },
  queryConfig,
);

export const useSetCareManager = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useSetRelationship('fee_schedule', 'employee', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('fee_schedule');
        invalidateQueries('employee');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useCreateFeeSchedule = () => {
  const invalidateQueries = useInvalidateQueries();
  return useCreateRecord('fee_schedule', {
    mutationConfig: {
      onError: () => Notifier.dispatch('error', 'Something went wrong...'),
      onSuccess: () => {
        invalidateQueries('fee_schedule');
      },
    },
  });
};

export const useUpdateFeeSchedule = () => {
  const invalidateQueries = useInvalidateQueries();
  return useUpdateRecord('fee_schedule', {
    mutationConfig: {
      onError: () => Notifier.dispatch('error', 'Something went wrong...'),
      onSuccess: (response) => {
        invalidateQueries('fee_schedule');
        Notifier.dispatch(response.status, 'Fee Schedule Successfully Updated');
      },
    },
  });
};

export default useFindAllFeeSchedules;
