import React from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import {
  useCreateFeeSchedule,
} from 'src/components/Backoffice/api/hooks/v1/feeScheduleHooks';
import {
  constructFeeScheduleFormPayload,
} from 'src/pages/fee-schedules/_id/utils/index';
import FeeScheduleForm from './components/FeeScheduleForm';

const CreateFeeSchedule = () => {
  const { createRecord: createFeeSchedule } = useCreateFeeSchedule();

  const onSubmit = async (feeSchedule) => {
    const payload = constructFeeScheduleFormPayload(feeSchedule);
    const response = await createFeeSchedule(payload);
    if (response) {
      browserHistory.push({ pathname: '/backoffice/fee_schedules' });
    }
  };

  return (
    <FeeScheduleForm onSubmit={onSubmit} />
  );
};

export default CreateFeeSchedule;
