import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isObject } from 'lodash';
import { Spinner } from 'common/spinners';
import {
  useFindAuthorizationRejectionReasonsByFeeScheduleId,
} from 'src/components/Backoffice/api/hooks/v1/authorizationRejectionReasonHooks';
import { useFindFeeSchedule } from 'src/components/Backoffice/api/hooks/v1/feeScheduleHooks';
import moment from 'moment';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Button,
  Icon,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from '@unite-us/ui';
import { theme } from 'src/../tailwind.config';
import EmptyTable from 'src/common/tables/EmptyTable/EmptyTable';
import 'src/pages/fee-schedules/_id/FeeSchedulePrograms/_fee_schedule_program_id/FeeScheduleProgramBaseCard.scss';
import FeeScheduleEditAuthorizationRejectionReasons from './FeeScheduleEditAuthorizationRejectionReasons';

const FeeScheduleAuthorizationRejectionReasonsTable = ({ feeScheduleId }) => {
  const editRejectionReasonModalRef = useRef(null);
  const { data: reasons, isFetching } = useFindAuthorizationRejectionReasonsByFeeScheduleId(feeScheduleId);

  const openModal = () => {
    editRejectionReasonModalRef.current.openDialog();
  };

  const [addStandardRejectionReasons, setAddStandardRejectionReasons] = useState(false);

  const addStandardRejectionReasonsOnClick = () => {
    setAddStandardRejectionReasons(true);
    openModal();
  };

  const { data: feeSchedule } = useFindFeeSchedule(feeScheduleId);

  return (
    <div className="fee-schedule-base-card">
      <BaseCard className="p-6" noBorder>
        <BaseCardHeader title="Authorization Rejection Reasons" className="px-4 font-bold">
          <Button
            id="edit-authorization-rejections-button"
            iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="V2Edit" />}
            onClick={openModal}
            className="flex"
            label="EDIT REJECTION REASONS"
            disabled={moment.utc().isAfter(moment(feeSchedule.ends_at).utc())}
          />
        </BaseCardHeader>
        <BaseCardBody>
          {isFetching ? <Spinner /> : (
            <>
              {!isEmpty(reasons) ? (
                <Table id="AuthorizationRejectionReasonsTable" noBorder>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderColumn className="border-r border-gray-300 border-solid" style={{ width: '5%' }}>
                        Code
                      </TableHeaderColumn>
                      <TableHeaderColumn className="border-r border-gray-300" style={{ width: '45%' }}>
                        Rejection Reason
                      </TableHeaderColumn>
                      <TableHeaderColumn className="border-r border-gray-300" style={{ width: '10%' }}>
                        State
                      </TableHeaderColumn>
                      <TableHeaderColumn className="border-r border-gray-300" style={{ width: '35%' }}>
                        Description
                      </TableHeaderColumn>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {reasons.filter(isObject).map((reason) => (
                      <TableRow hoverable={false} key={reason.id}>
                        <TableRowColumn className="border-r border-gray-300 border-solid" style={{ width: '5%' }}>
                          {reason.code}
                        </TableRowColumn>
                        <TableRowColumn className="border-r border-gray-300" style={{ width: '45%' }}>
                          {reason.display_name}
                        </TableRowColumn>
                        <TableRowColumn className="border-r border-gray-300 capitalize" style={{ width: '10%' }}>
                          {reason.state}
                        </TableRowColumn>
                        <TableRowColumn className="border-r border-gray-300" style={{ width: '35%' }}>
                          {reason.description}
                        </TableRowColumn>
                      </TableRow>
                  ))}
                  </TableBody>
                </Table>
              ) : (
                <EmptyTable
                  className={'p-6'}
                  message={(
                    <>
                      <div>
                        No Authorization Rejection Reasons added.
                        <br />
                        Add all standard rejection codes or manually add reasons.
                      </div>
                      <div className="flex items-center justify-center p-4">
                        <Button
                          id="edit-authorization-rejections-button"
                          className="text-sm capitalize mr-4"
                          onClick={addStandardRejectionReasonsOnClick}
                          secondary
                          label="ADD ALL STANDARD REJECTION REASONS"
                        />
                        <Button
                          id="edit-authorization-rejections-button"
                          className="text-sm capitalize mr-4"
                          onClick={openModal}
                          secondary
                          label="ADD REJECTION REASON"
                        />
                      </div>
                    </>
                  )}
                />
              )}
              <FeeScheduleEditAuthorizationRejectionReasons
                rejectionReasons={reasons}
                modalRef={editRejectionReasonModalRef}
                feeScheduleId={feeScheduleId}
                addStandardRejectionReasons={addStandardRejectionReasons}
                setAddStandardRejectionReasons={setAddStandardRejectionReasons}
              />
            </>
          )}
        </BaseCardBody>
      </BaseCard>
    </div>
  );
};

FeeScheduleAuthorizationRejectionReasonsTable.propTypes = {
  feeScheduleId: PropTypes.string.isRequired,
};

export default FeeScheduleAuthorizationRejectionReasonsTable;
