import React from 'react';

const NoRequestsFoundLogo = () => (
  /* eslint-disable */
  <svg width="150px" viewBox="0 0 308.4 288" xmlns="http://www.w3.org/2000/svg">
    <circle cx="144" cy="144" r="144" fill="#EAF0F7" />
    <path d="m205.5 181.9h3.8c0.6 0 0.9 0.9 0.3 1.2l-123.7 72.2c-0.7 0.4-1.4 0.6-2.2 0.6h-6.4l29.3-43.7 98.9-30.3z" fill="#D0DEEF" />
    <circle cx="257" cy="52.2" r="51.4" fill="#fff" />
    <circle cx="257" cy="52.2" r="45.4" fill="#2C405A" />
    <g transform="translate(-170.1 -179.5)">
      <path d="m427.4 208.2c12.7 0 17.8 12.6 17.8 24 0 11.3-5.1 24.1-17.8 24.1-12.8 0-17.8-12.8-17.8-24.1-0.1-11.3 5-24 17.8-24zm0 5.2c-8.9 0-11.9 9.6-11.9 18.9s3 18.9 11.9 18.9 11.9-9.6 11.9-18.9-3.1-18.9-11.9-18.9z" fill="#fff" />
    </g>
    <path d="m189.6 57.9-35.7 20.5c-1.7 1-2.7 2.7-2.7 4.7v4.9l-79.2 45.7c-2.8 1.6-4.6 4.7-4.6 8v108.5l128.2-74v-114.9c0-3.1-3.3-5-6-3.4z" fill="#2F71F4" />
    <path d="m67.5 250.2 9.9 5.7v-108.5l118.3-73.6v-5.7l-123.3 71.3c-2.8 1.7-4.5 4.7-4.5 8l-0.4 102.8z" fill="#0D59C9" />
    <polygon points="176.3 24.8 176.3 93.1 87.6 144.3 87.6 76" fill="#fff" />
    <path d="m199.4 71.6-117.5 67.8c-2.8 1.6-4.6 4.7-4.6 8v108.5l128.2-74v-106.8c0-3.1-3.4-5.1-6.1-3.5z" fill="#71B2FF" />
    <line x1="97.2" x2="168.8" y1="104.5" y2="63.1" fill="none" stroke="#D0DEEF" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="4.384" />
    <line x1="97.2" x2="168.8" y1="119.9" y2="78.5" fill="none" stroke="#D0DEEF" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="4.384" />
    <line x1="97.2" x2="135.7" y1="89.8" y2="67.6" fill="none" stroke="#D0DEEF" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="4.384" />
  </svg>
  /* eslint-enable */
);

export default NoRequestsFoundLogo;
