import { get, uuPick } from 'lodash';

function createRollbarPerson(user) {
  const groups = get(user, 'groups', []).map((groupObj) => (
    {
      id: get(groupObj, 'group.id'),
      name: get(groupObj, 'group.name'),
    }));
  const networks = get(user, 'networks', []).map((network) => (
    {
      id: network.id,
      name: network.name,
    }));

  return {
    ...uuPick(user, ['email', 'id']),
    groups,
    networks,
  };
}

export default createRollbarPerson;
