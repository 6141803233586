import React, { useRef, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { Dialog, Icon } from '@unite-us/ui';
import { LineItem } from 'common/display/LineItem';
import ReferralAuthSpendExpandable from './ReferralAuthSpendExpandable';

const filterByState = (items) => {
  const approvedItems = items.filter((item) => item.state === 'approved');
  const requestedItems = items.filter((item) => item.state === 'requested');
  return { approvedItems, requestedItems };
};

const PreviousAuthorizationRequestsDetails = (
  {
    authorizationSpends,
    totalApprovedSpend,
    totalRequestedSpend,
    unit,
    isCostBased,
    capInfo,
    amountAvailable,
  },
) => {
  const modalRef = useRef();

  const closeModal = useCallback(() => {
    modalRef.current.closeDialog();
  }, []);

  const openModal = useCallback(() => {
    modalRef.current.openDialog();
  }, []);

  const { approvedItems, requestedItems } = filterByState(authorizationSpends);

  return (
    <>
      <button
        data-testid="auth-requests-details-view-details-button"
        type="button"
        onClick={openModal}
        className="focus:outline-none inline-block"
      >
        <Icon icon="IconExternalLink" className="fill-current text-action-blue" />
        <span className="text-action-blue ml-2">
          View details
        </span>
      </button>
      <Dialog
        ref={modalRef}
        title="Previous Authorization Requests"
        close={closeModal}
        dataTestId="auth-requests-details-dialog"
        width="3xl"
      >
        { capInfo.hasCap && (
          <>
            <dl className="m-0 flex justify-between gap-2">
              <LineItem
                field="Amount Available for Authorization"
                dataTestElement="auth-requests-details-amount-available"
                valueClassName="text-text-blue text-sm font-heavy-font leading-5"
              >
                {amountAvailable}
              </LineItem>
            </dl>

            <hr className="my-3" />
          </>
        )}

        <ReferralAuthSpendExpandable
          title="Amount Currently Authorized:"
          items={approvedItems}
          totalSpend={totalApprovedSpend}
          unit={unit}
          isAuthorized
          isCostBased={isCostBased}
        />

        <hr className="my-3" />

        { capInfo.hasCap && (
          <>
            <dl className="m-0 flex flex-col gap-y-3 gap-x-2">
              <div className="flex justify-between">
                <LineItem
                  field="Cap"
                  dataTestElement="auth-requests-details-cap"
                >
                  {capInfo.formattedCap}
                </LineItem>
              </div>
              {capInfo.startDate?.isValid() && (
                <>
                  <hr className="col-span-2" />
                  <div className="flex justify-between">
                    <LineItem
                      field="Cap Period"
                      dataTestElement="auth-requests-details-cap-period"
                    >
                      {capInfo.formattedCapPeriod}
                    </LineItem>
                  </div>
                </>
              )}
            </dl>

            <hr className="my-3 col-span-2" />
          </>
        )}

        <ReferralAuthSpendExpandable
          title="Amount Pending Authorization:"
          items={requestedItems}
          totalSpend={totalRequestedSpend}
          unit={unit}
          isAuthorized={false}
          isCostBased={isCostBased}
        />

        <hr className="my-3" />

        { capInfo.hasCap && (
          <p className="my-3">
            If accepted, pending requests will count towards the cap and reduce the amount available for authorization.
          </p>
        )}

      </Dialog>
    </>
  );
};

PreviousAuthorizationRequestsDetails.propTypes = {
  authorizationSpends: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      short_id: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      spend: PropTypes.number.isRequired,
      service_starts_at: PropTypes.string.isRequired,
      service_ends_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
    }),
  ).isRequired,
  totalApprovedSpend: PropTypes.string.isRequired,
  totalRequestedSpend: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  capInfo: PropTypes.shape({
    hasCap: PropTypes.bool.isRequired,
    formattedCap: PropTypes.string,
    formattedCapPeriod: PropTypes.string,
    startDate: PropTypes.object,
  }).isRequired,
  amountAvailable: PropTypes.string,
  isCostBased: PropTypes.bool.isRequired,
};

PreviousAuthorizationRequestsDetails.defaultProps = {
  amountAvailable: null,
};

export default PreviousAuthorizationRequestsDetails;
