import React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton } from 'src/common/TailwindComponents';
import NoFilteredResultsLogo from './NoFilteredResultsLogo';

const NoFilteredEnrollmentsFound = ({ resetFilters }) => (
  <>
    <NoFilteredResultsLogo />
    <h4 className="font-heavy-font text-base text-dark-grey">No results found.</h4>
    <p className="max-w-xs text-dark-grey">Try adjusting your search or filters to find what you are looking for.</p>
    <PrimaryButton label="Remove all filters" onClick={resetFilters} />
  </>
);

NoFilteredEnrollmentsFound.propTypes = {
  resetFilters: PropTypes.func.isRequired,
};

export default NoFilteredEnrollmentsFound;
