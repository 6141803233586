import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
} from '@unite-us/ui';
import { fetchGroupsReferral } from 'actions/Referral/Group';
import { Spinner } from 'common/spinners';
import { BackButton } from 'common/buttons';
import SendReferralForm from 'src/components/Referrals/ReferralStatus/components/SendReferral/SendReferralForm';
import { useSelectedPrograms } from '@unite-us/app-search';

const SendReferralCard = (props) => {
  const {
    caseReferrals,
    groupId,
    isCC,
    referralId,
    referral,
  } = props;

  useEffect(() => {
    if (isEmpty(referral)) {
      props.fetchGroupsReferral(groupId, referralId);
    }
  }, []);

  const selectedProgramsContext = useSelectedPrograms();
  const preventChangingServiceType = referral.state === 'declined' || referral.state === 'recalled';

  if (isEmpty(referral)) {
    return <Spinner />;
  }

  return (
    <div className="send-referral-card container card-container">
      <BackButton />
      <BaseCard>
        <BaseCardHeader title="Send Referral" />
        <BaseCardBody withPadding>
          <SendReferralForm
            isCC={isCC}
            referral={referral}
            caseReferrals={caseReferrals}
            selectedProgramsContext={selectedProgramsContext}
            preventChangingServiceType={preventChangingServiceType}
          />
        </BaseCardBody>
      </BaseCard>
    </div>
  );
};

SendReferralCard.propTypes = {
  caseReferrals: PropTypes.array.isRequired,
  fetchGroupsReferral: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isCC: PropTypes.bool.isRequired,
  referral: PropTypes.object.isRequired,
  referralId: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { referrals } = state;
  const { groupId } = state.session;
  const allReferrals = [...referrals.sent, ...referrals.received];
  const referralId = ownProps.params.id;
  const isCC = get(state, 'session.isCoordinationGroup', false);
  const caseReferrals = get(ownProps, 'location.state.caseReferrals', []);

  return {
    caseReferrals,
    referral: allReferrals.find((referral) => referral.id === referralId) || {},
    groupId,
    referralId,
    isCC,
  };
}

export default connect(mapStateToProps, {
  fetchGroupsReferral,
})(SendReferralCard);
