import { SET_CURRENT_NETWORK } from 'actions';
import { saveCurrentNetworkIdToSessionStorage } from '../../utils';

export default function setCurrentNetwork(networkId) {
  const network = networkId === 'undefined' ? undefined : networkId;
  saveCurrentNetworkIdToSessionStorage(networkId);
  return (dispatch) => dispatch({
    type: SET_CURRENT_NETWORK,
    networkId: network,
  });
}
