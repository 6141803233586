import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router';
import { Icon } from '@unite-us/ui';

const Breadcrumbs = ({ crumbs, className }) => (
  <div className={classnames('flex flex-wrap', className)}>
    {crumbs.map(({ label, href }, index) => {
      const isLast = index === (crumbs.length - 1);
      const crumbLabel = (
        <span className={classnames('text-15px', href && 'text-action-blue')}>
          {label}
        </span>
      );
      const renderedCrumb = href ? <Link className="text-action-blue" to={href}>{crumbLabel}</Link> : crumbLabel;
      return (
        <div className="flex items-center" key={label}>
          {renderedCrumb}
          {!isLast && (
            <Icon className="mx-6 opacity-50 fill-current text-dark-grey" icon="IconChevronRight" size={12} />
          )}
        </div>
      );
    })}
  </div>
);

Breadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string,
    }).isRequired,
  ).isRequired,
  className: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  className: '',
};

export default Breadcrumbs;
