import getNetworkScopeFilterSearchParams from './getNetworkScopeFilterSearchParams';
/**
 * Configure address book params, max length of networkScopes is 2 [in-network, out-of-network]
 * @return { Object }
 */
function addressBookParams({ canViewOONGroups, networkScopes = [] }) {
  if (!canViewOONGroups) { return {}; }

  return getNetworkScopeFilterSearchParams(networkScopes);
}

export default addressBookParams;
