import React, { useState } from 'react';
import { snakeCase } from 'lodash';
import PropTypes from 'prop-types';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  InputField,
  RadioField,
  SelectField,
  TextField,
} from '@unite-us/ui';
import MetafieldsOptions from './MetafieldsOptions';

const MetafieldForm = ({
  title, metafield, setMetafield, index, hideSelectField, defaultFields,
}) => {
  const [tempMetafield, setTempMetafield] = useState({ ...metafield, ...defaultFields });
  const initialOptions = () => (
    tempMetafield?.type === 'dropdown' || tempMetafield?.type === 'multipledropdown'
  );

  const [showOptions, setShowOptions] = useState(initialOptions());
  const [options, setOptions] = useState(tempMetafield.options ? tempMetafield.options : ['', '', '']);
  const typeOptions = [
    { label: 'Text', value: 'text' },
    { label: 'Number', value: 'number' },
    { label: 'Money', value: 'money' },
    { label: 'File', value: 'file' },
    { label: 'Address', value: 'address' },
    { label: 'Date', value: 'date' },
    { label: 'Date and Time', value: 'datetime' },
    { label: 'Single-select Dropdown', value: 'dropdown' },
    { label: 'Multi-select Dropdown', value: 'multipledropdown' },
  ];
  const requirementOptions = [
    { label: 'Required', value: true },
    { label: 'Optional', value: false },
  ];
  const namespace = title.toLowerCase().split(' ').join('-');

  const addOptionsToMetaFields = (newOptions) => {
    const validOptions = newOptions.filter((o) => o !== '');
    return { ...tempMetafield, ...{ options: validOptions } };
  };

  const removeOptionsFromMetaFields = (newField) => (
    newField.type && !['dropdown', 'multipledropdown'].includes(newField.type) ?
      { ...tempMetafield, options: null } : { ...tempMetafield }
  );

  const onFieldChange = ((newField) => {
    const newMetafield = { ...removeOptionsFromMetaFields(newField), ...newField };
    if (newMetafield.help_text === '') {
      delete newMetafield.help_text;
    }
    setTempMetafield(newMetafield);
    setMetafield(newMetafield, index);
  });

  const onOptionFieldChange = (i, newValue) => {
    const newOptions = [...options];
    newOptions.splice(i, 1, newValue);
    setOptions(newOptions);
    const newMetafield = addOptionsToMetaFields(newOptions);
    setTempMetafield(newMetafield);
    setMetafield(newMetafield, index);
  };

  const onDeleteOption = (newOptions) => {
    const newMetafield = addOptionsToMetaFields(newOptions);
    setTempMetafield(newMetafield);
    setMetafield(newMetafield, index);
  };

  const shouldShowOptions = (value) => {
    setShowOptions(value === 'multipledropdown' || value === 'dropdown');
  };

  return (
    <div className="fee-schedule-program-base-card">
      <BaseCard noBorder>
        <BaseCardHeader title={title} noBorder className="px-4 font-bold" />
        <BaseCardBody withPadding>
          <InputField
            id={`${namespace}__field-name`}
            label="Field Name"
            placeholder="Enter the name of the field"
            type="text"
            value={tempMetafield.label}
            required
            onChange={(e) => {
              onFieldChange({ field: snakeCase(e.target.value), label: e.target.value });
            }}
          />
          <div className={'flex justify-between'}>
            { !hideSelectField && (
              <SelectField
                className={'pr-4'}
                id={`${namespace}__type`}
                data-testid="custom-field-form__select-field"
                label="Field Type"
                options={typeOptions}
                valueKey="value"
                labelKey="label"
                value={tempMetafield.type}
                required
                onChange={(value) => {
                  onFieldChange({ type: value });
                  shouldShowOptions(value);
                }}
              />
            )}
            <RadioField
              id={`${namespace}__requirement`}
              inline
              label="Field Requirement"
              options={requirementOptions}
              value={(tempMetafield.validations) ? tempMetafield.validations.required : false}
              onChange={(e) => {
                onFieldChange({
                  validations: {
                    ...tempMetafield.validations,
                    required: e.target.value === 'true',
                  },
                });
              }}
            />
          </div>
          {
            showOptions && (
              <MetafieldsOptions
                onDeleteOption={onDeleteOption}
                onOptionFieldChange={onOptionFieldChange}
                options={options}
                setOptions={setOptions}
              />
            )
          }
          <TextField
            id={`${namespace}__description`}
            label="Description"
            placeholder="Type Description here."
            value={tempMetafield.help_text ? tempMetafield.help_text : ''}
            onChange={(e) => onFieldChange({ help_text: e.target.value })}
          />
        </BaseCardBody>
      </BaseCard>
    </div>
  );
};

MetafieldForm.propTypes = {
  title: PropTypes.string,
  metafield: PropTypes.object,
  setMetafield: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  hideSelectField: PropTypes.bool,
  defaultFields: PropTypes.object,
};

MetafieldForm.defaultProps = {
  title: 'Custom Field',
  metafield: {
    field: '',
    label: '',
    type: '',
  },
  hideSelectField: false,
  defaultFields: {},
};

export default MetafieldForm;
