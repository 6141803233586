import _ from 'lodash';

export default function flattenServiceTypes(serviceTypes, keepParent = false) {
  const result = _.reduce(serviceTypes, (acc, st) => {
    if (_.has(st, 'children') && !_.isEmpty(st.children)) {
      if (keepParent) {
        return [...acc, st, ...st.children];
      }
      return [...acc, ...st.children];
    }
    return [...acc, st];
  }, []);
  return _.sortBy(result, 'name', 'asc');
}
