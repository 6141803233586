import moment from 'moment';

const getFeeScheduleEndDateMinDate = (startsAt, isEditing) => {
  // min date isn't inclusive so min date should be yesterday's date to be able to select present day
  const minDate = moment().subtract(1, 'day').startOf('day');

  // If the user is creating a fee schedule, the date can't be in the past
  if (!isEditing) {
    return startsAt && moment(startsAt, 'MM/DD/YYYY').isAfter(minDate) ?
      moment.max(moment(startsAt, 'MM/DD/YYYY'), minDate).format('MM/DD/YYYY') :
      minDate.format('MM/DD/YYYY');
  }

  // If the fee schedule is being edited, the min date should be startsAt if it's set, otherwise no minDate
  return startsAt ? moment(startsAt, 'MM/DD/YYYY').format('MM/DD/YYYY') : null;
};

export default getFeeScheduleEndDateMinDate;
