function getTarget(route = {}) {
  switch (route.status) {
    case 'all':
      return 'sentReferralsAll';

    case 'accepted/open':
      return 'sentReferralsAcceptedOpen';

    case 'accepted/closed':
      return 'sentReferralsAcceptedClosed';

    case 'declined_consent':
      return 'sentReferralsDeclinedConsent';

    case 'in_review':
      return 'sentReferralsInReview';

    case 'pending_consent':
      return 'sentReferralsPendingConsent';

    case 'pending':
      return 'sentReferralsPending';

    default:
      return 'sentReferrals';
  }
}

export default getTarget;
