import moment from 'moment';
import { useFindRecord, usePopulate, usePopulateMemo } from 'src/api/APIHooks';
import { isNull } from 'lodash';

const refetchInterval = { queryConfig: { refetchInterval: 30000 } };

const useCase = (caseId) => {
  const { data: caseResponse } = useFindRecord('case', caseId, refetchInterval);
  const caseObj = caseResponse.data.data;
  const provider = usePopulate('provider', 'provider', caseObj, refetchInterval);
  usePopulateMemo(caseResponse.data, [provider]);

  return {
    ...caseObj,
    opened_date: isNull(caseObj.opened_date) ? '-' : moment.unix(caseObj.opened_date).format('LL'),
    closed_date: isNull(caseObj.closed_date) ? '-' : moment.unix(caseObj.closed_date).format('LL'),
  };
};

export default useCase;
