function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import classNames from 'classnames';
import { isEmpty } from 'lodash';
var containerClass = function containerClass(props, state) {
  return classNames({
    'ui-date-field': true,
    'ui-form-field': true,
    'ui-form-field--inline': props.inline,
    'ui-form-field--has-error': props.hasError,
    'ui-form-field--disabled': props.disabled,
    'ui-date-field--has-error': props.hasError,
    'ui-date-field--droptdown-open': state.isOpen
  }, props.className);
};
var labelClass = function labelClass(props) {
  return classNames(_defineProperty(_defineProperty({
    'ui-form-field__label': true,
    'ui-form-field__label--required': props.required
  }, props.labelClassName, !isEmpty(props.labelClassName)), 'sr-only', props.hideLabel));
};
var dropDownClass = function dropDownClass(state) {
  return classNames({
    'ui-date-field__dropdown': true,
    'ui-date-field__dropdown--open': state.isOpen
  });
};
export { containerClass, labelClass, dropDownClass };