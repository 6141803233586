import React from 'react';
import {
  displayDateWithTime,
  displayNode,
  displayText,
  generateColumn,
} from 'common/display/SingleItemDetails/utils/common';
import ReferralServiceType from
'src/components/Referrals/ReferralStatus/components/ReferralServiceType';
import ReferralProgramDisplay from
'src/components/Referrals/ReferralPrograms/ReferralProgramDisplay';
import { isReferredWithinNetwork } from 'src/components/Referrals/utils';
import { REFERRAL_STATUS_DRAFT } from 'src/components/Referrals/constants';
import { get } from 'lodash';
import showDetailProgram from './showDetailProgram';

function firstDraftColumnInfo(referral) {
  return [
    displayText(referral, {
      label: 'Sender',
      key: 'referred_by_group.name',
    }),
  ];
}

function secondDraftColumnInfo(referral, groupId, isCC) {
  return [
    displayText(referral, {
      label: 'Sending Network',
      key: 'network.name',
    }),
    !isReferredWithinNetwork(referral) ?
      displayText(referral, {
        label: 'Recipient Network',
        key: 'referred_to_network.name',
      }) : null,
    displayNode({
      label: 'Service Type',
      node: (
        <ReferralServiceType
          referral={referral}
          groupId={groupId}
          status={referral.status}
          isCCUser={isCC}
          editable={isCC}
        />
      ),
    }),
  ];
}

function firstColumnInfo(referral, groupId, isCC) {
  const sendingEmployee = get(referral, 'sending_employee', null);
  return [
    displayText(referral, {
      label: sendingEmployee ? 'Sending Organization' : 'Sender',
      key: 'referred_by_group.name',
    }),
    sendingEmployee ?
      displayText(referral, {
        label: 'Sending User',
        key: 'sending_employee.full_name',
      }) : null,
    displayText(referral, {
      label: 'Sending Network',
      key: 'referred_by_network.name',
    }),
    displayNode({
      label: 'Service Type',
      node: (
        <ReferralServiceType
          referral={referral}
          groupId={groupId}
          status={referral.status}
          isCCUser={isCC}
          editable={isCC}
        />
      ),
    }),
  ];
}

function caseResolutionDisplayText(referral = {}) {
  const resolutionText = referral.case?.resolution ? displayText(referral, {
    label: 'Case Resolution',
    key: 'case.resolution',
  }) :
    null;
  if (resolutionText) resolutionText.value = resolutionText.value[0].toUpperCase() + resolutionText.value.slice(1);
  return resolutionText;
}

function getReferralRecipientKey(referral = {}) {
  if (referral.recalled_from_group) {
    return 'recalled_from_group.name';
  }

  if (referral.referred_to_group) {
    return 'referred_to_group.name';
  }

  return 'referred_by_group.name';
}

function getReferralNetworkKey(referral = {}) {
  if (referral.recalled_from_network) {
    return 'recalled_from_network.name';
  }

  if (referral.referred_to_network) {
    return 'referred_to_network.name';
  }

  return 'referred_by_network.name';
}

function secondColumnInfo(referral, groupId) {
  return [
    caseResolutionDisplayText(referral),
    displayText(referral, {
      label: 'Recipient',
      key: getReferralRecipientKey(referral),
    }),
    !isReferredWithinNetwork(referral) ?
      displayText(referral, {
        label: 'Recipient Network',
        key: getReferralNetworkKey(referral),
      }) :
      null,
    displayDateWithTime(referral, {
      label: 'Date Sent',
      key: 'created_at',
    }),
    showDetailProgram(referral) ? displayNode({
      label: 'Program',
      node: (
        <ReferralProgramDisplay
          referral={referral}
          groupId={groupId}
          hideEditBtn
        />
      ),
      clickable: true,
    }) : null,
  ];
}

export default function referralBasicInformationTableData({ groupId, isCC, referral = {} }) {
  if (referral.status === REFERRAL_STATUS_DRAFT) {
    return [
      generateColumn(firstDraftColumnInfo(referral)),
      generateColumn(secondDraftColumnInfo(referral, groupId, isCC)),
    ];
  }

  return [
    generateColumn(firstColumnInfo(referral, groupId, isCC)),
    generateColumn(secondColumnInfo(referral, groupId)),
  ];
}
