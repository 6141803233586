import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import {
  Button,
  Dialog,
  Icon,
} from '@unite-us/ui';
import { useInvalidateQueries } from 'src/api/APIHooks';
import FeatureFlagContainer from 'src/common/utils/FeatureFlags/FeatureFlagContainer';
import CaseCloseForm from './CaseCloseForm';

const ReopenWarning = () => (
  <div className="mb-4 mt-2 font-regular-font" data-testid="reopen-warning">
    <div className="flex items-start p-3 color-background--cloud-blue-color">
      <Icon
        icon="IconExclamationCircle"
        size={17}
        className="mr-2 fill-current text-text-blue"
        ariaLabel="note"
      />
      <p className="italic">
        After you close this case, you will not be able to reopen the case
        or submit contracted service notes for the remaining unutilized funds/units.
      </p>
    </div>
  </div>
);

const CaseCloseWrapper = (props) => {
  let closeCaseRef = useRef(null);
  const invalidateQueries = useInvalidateQueries();

  const openDialog = () => {
    closeCaseRef.openDialog();
  };

  const closeDialog = () => {
    closeCaseRef.closeDialog();
  };

  const { contact, serviceCase, programHasCap } = props;

  return (
    <div className="detail-service-type" data-testid="case-close-wrapper">
      <Button
        id="close-case-btn"
        primary
        onClick={openDialog}
        label="Close Case"
      />
      <Dialog
        id="close-case-modal"
        ref={(el) => { closeCaseRef = el; }}
        title="Close Case"
        size="large"
      >
        {programHasCap && <ReopenWarning />}
        <CaseCloseForm
          serviceCase={serviceCase}
          contact={contact}
          closeDialog={closeDialog}
          invalidateQueries={invalidateQueries}
        />
      </Dialog>
    </div>
  );
};

CaseCloseWrapper.propTypes = {
  serviceCase: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  programHasCap: PropTypes.bool.isRequired,
};

export default FeatureFlagContainer(CaseCloseWrapper);
