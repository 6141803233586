import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

export default function DemographicInfoDisplay(props) {
  const { fieldType, label, enumsPeopleArray } = props;

  if (fieldType.value && label) {
    const enumsPeopleType = _.find(enumsPeopleArray, { value: fieldType.value });
    return (
      <div className="demographic-info-display">
        <p><span className="strong">{label}:</span> {enumsPeopleType.display_name}</p>
      </div>
    );
  }
  return null;
}

DemographicInfoDisplay.propTypes = {
  fieldType: PropTypes.object.isRequired,
  label: PropTypes.string,
  enumsPeopleArray: PropTypes.array.isRequired,
};
