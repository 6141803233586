import { coreApi } from 'src/api/config';
import { convertCoreDocuments } from 'src/api/utils/fileUploadUtils';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { FETCH_CONTACT_DOCUMENTS } from 'actions';
import Notifier from 'common/helpers/Notifier';

const fetchContactDocuments = ({
  contactId,
  notifyError = false,
}) => (
  async (dispatch) => {
    const response = await coreApi.query(
      'file_uploads',
      {
        record: contactId,
        'record.type': 'person',
      },
    );

    let documents = response.data.data;
    documents = convertCoreDocuments(documents);
    const payload = { data: { data: documents } };

    if (!isHttpSuccess(response.status)) {
      if (notifyError) {
        Notifier.handleErrors(response);
      }
      return response;
    }

    dispatch({
      type: FETCH_CONTACT_DOCUMENTS,
      payload,
      contactId,
    });

    return payload;
  }
);

export default fetchContactDocuments;
