import { compact, reduce, sortBy } from 'lodash';

function sortStatuses(statusOptions, hideAll = false) {
  const statuses = reduce(statusOptions, (acc, s) => [
    ...acc, {
      label: s.display_name,
      value: s.value,
    }], []);

  return compact([
    hideAll ? null : { label: 'Status (All)', value: 'all' },
    ...sortBy(statuses, (s) => s.label),
  ]);
}

export default sortStatuses;
