import { RETRIEVE_SELECTED_CONTACT } from 'actions';

export default function retrieveSearchedContacts(id) {
  return (dispatch) => {
    dispatch({
      type: RETRIEVE_SELECTED_CONTACT,
      id,
    });
  };
}
