import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Spinner } from 'common/spinners';
import { filterSearchResults } from 'src/components/Search/utils';
import { NO_RESULTS_FOUND_TEXT } from 'src/components/Search/constants';
import SearchBarTable from './SearchBarTable';

const SearchBarSection = ({ displayName, model, numResults }, context) => {
  const { isFetching, searchResults } = context;
  const sectionResults = _.get(searchResults, `${model}.data`, []);
  const hasResults = !_.isEmpty(sectionResults);

  const results = (hasResults ? (
    <SearchBarTable
      model={model}
      numResults={numResults}
      sectionResults={filterSearchResults(sectionResults)}
    />
  ) : (
    <span className="search-results-section__no-results">
      {NO_RESULTS_FOUND_TEXT}
    </span>
  ));

  return (
    <div className="search-results-section">
      <h5
        className="search-results-section__label"
        id={`${model}-section-label`}
      >
        {displayName}
      </h5>
      {isFetching ? <Spinner scale={0.5} /> : results}
    </div>
  );
};

SearchBarSection.propTypes = {
  displayName: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  numResults: PropTypes.number.isRequired,
};

SearchBarSection.contextTypes = {
  isFetching: PropTypes.bool.isRequired,
  searchResults: PropTypes.object.isRequired,
};

export default SearchBarSection;
