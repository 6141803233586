import { get, map, uuCompactArrayOrObject } from 'lodash';

const getGroupAndProgramInitialValues = (selectedGroups = []) => (
  map(selectedGroups, ({ id, receiving_program = {} } = {}) => (
    uuCompactArrayOrObject({
      group: { id },
      program: get(receiving_program, 'id') && { id: receiving_program.id },
    })
  ))
);

export default getGroupAndProgramInitialValues;
