import { scopeAllowsMultipleGroups } from 'src/components/Groups/utils/referralScope';
import { REFERRALS_AUTO_RECALL_REFERRALS } from 'common/utils/FeatureFlag/utils/constants';
import { every } from 'lodash';

function canPoolReferrals({
  flagEnabled, referralScope, suggestedGroups, selectedGroupCount,
}) {
  return every([
    scopeAllowsMultipleGroups(referralScope, suggestedGroups),
    flagEnabled(REFERRALS_AUTO_RECALL_REFERRALS),
    selectedGroupCount > 1,
  ]);
}

export default canPoolReferrals;
