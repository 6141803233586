import { createContext, useContext } from 'react';

export const CurrentEmployeeContext = createContext();

/**
 *
 * @returns {string | undefined}
 */
export const useCurrentEmployeeId = () => {
  const currentEmployee = useContext(CurrentEmployeeContext);

  return currentEmployee?.id;
};
