export const ALL_TIME = { value: 'all-time', label: 'All Time' };

export const CUSTOM_DATES = { value: 'custom-date-range', label: 'Custom Date Range' };

export const DATE_FILTER_OPTIONS = [
  ALL_TIME,
  { label: 'Last 7 Days', value: '7' },
  { label: 'Last 30 Days', value: '30' },
  { label: 'Last 60 Days', value: '60' },
  { label: 'Last 90 Days', value: '90' },
  CUSTOM_DATES,
];
