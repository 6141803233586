import { theme } from 'src/../tailwind.config';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Icon } from '@unite-us/ui';
import { capitalize, isEmpty } from 'lodash';
import moment from 'moment';
import { browserHistory } from 'common/utils/browserHistory';
import { useUpdateFeeSchedule } from 'src/components/Backoffice/api/hooks/v1/feeScheduleHooks';
import stateBgColor from 'common/utils/stateBgColor';
import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import ArchiveDialog from './ArchiveDialog';

const editFeeSchedule = (id) => {
  browserHistory.push({ pathname: `/backoffice/fee_schedules/${id}/edit` });
};
const FeeScheduleSummary = ({ feeSchedule }) => {
  const feeScheduleId = feeSchedule.id;
  const feeScheduleName = feeSchedule.name;
  const starts_at = moment(feeSchedule.starts_at).utc().format('MM/DD/YYYY');
  const ends_at = moment(feeSchedule.ends_at).utc().format('MM/DD/YYYY');
  const state = isEmpty(feeSchedule.state) ? 'Loading...' : feeSchedule.state;
  const ArchiveFeeScheduleModalRef = useRef(null);
  const { updateRecord: updateFeeSchedule } = useUpdateFeeSchedule();

  const openArchiveModal = () => {
    ArchiveFeeScheduleModalRef.current.openDialog();
  };
  const closeModal = () => {
    ArchiveFeeScheduleModalRef.current.closeDialog();
  };

  const archiveFeeSchedule = async () => {
    const response = await updateFeeSchedule(feeScheduleId, {
      state: 'archived',
    });
    if (isHttpSuccess(response.status)) {
      Notifier.dispatch(200, 'Fee Schedule Successfully Archived');
    }
  };

  return (
    <div className="FeeScheduleSummary">
      <div className="flex flex-row justify-between">
        <div className="flex bg-white justify-start items-center flex-nowrap">
          <h1 className="pr-4 font-bold text-2xl leading-8">{feeScheduleName}</h1>
          <Badge
            className="flex flex-row justify-center px-10 py-2 flex-nowrap items-center"
            text={capitalize(state)}
            color={stateBgColor(state)}
            dataTestId="fs-badge"
          />
        </div>
        <div className="flex flex-nowrap">
          {
            feeSchedule.state.toLowerCase() === 'active' &&
              (
                <Button
                  id="archive-fee-schedule-button"
                  className="mx-2"
                  iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="IconArchive" />}
                  label="ARCHIVE"
                  onClick={() => openArchiveModal()}
                  data-testid="archive-fs-btn"
                />
              )
          }
          <Button
            id="edit-fee-schedule-button"
            className="mx-2"
            iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="V2Edit" />}
            label="EDIT"
            onClick={() => editFeeSchedule(feeSchedule.id)}
            disabled={moment().utc().isAfter(feeSchedule.ends_at)}
            data-testid="edit-fs-btn"
          />
        </div>
      </div>
      <div className="mt-1 w-full">
        <p className="pr-3 font-bold inline-block">START DATE: </p>
        <p className="pr-4 inline-block">{starts_at}</p>
        <p className="pr-4 inline-block">|</p>
        <p className="pr-3 font-bold inline-block">END DATE: </p>
        <p className="inline-block">{ends_at}</p>
      </div>
      <ArchiveDialog
        cancelHandler={closeModal}
        confirmationHandler={archiveFeeSchedule}
        confirmationBtnDisabled={feeSchedule.state !== 'active'}
        title="Archive Fee Schedule"
        dialogId="archive-fee-schedule-modal"
        ref={ArchiveFeeScheduleModalRef}
        itemName={feeScheduleName}
        itemType="fee schedule"
      />
    </div>
  );
};
FeeScheduleSummary.propTypes = {
    feeSchedule: PropTypes.object.isRequired,
};
export default FeeScheduleSummary;
