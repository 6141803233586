import { useFind } from 'src/api/APIHooks';
import { sortBy } from 'lodash';

const useInvoicePayerOptions = (
  providerId,
  isNetworkLead,
) => {
  const filterOptions = {
      ...(isNetworkLead ? { network_lead: providerId } : { provider: providerId }),
  };

  const { isLoading, data } = useFind(
    'payers',
    filterOptions,
    {
      queryConfig: {
        enabled: !!providerId,
        placeholderData: undefined,
      },
    },
  );

  const payerData = data?.data?.data ?? [];

  const payerOptions = payerData.map((payer) => ({
    label: payer.name,
    value: payer.id,
  }));
  return isLoading ? [] : sortBy(payerOptions, 'label');
};

export default useInvoicePayerOptions;
