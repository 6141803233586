/**
 * Sort a list of addresses putting primary and recent addresses first.
 *
 * This criteria is:
 * - Addresses are sorted by creation time, newer first.
 * - If an address marked as primary is present, it will go first independently
 *   of the creation time.
 *
 * > Note: You can think that the first address in the list will be the "main"
 * (most used) address, even if no primary address is set.
 *
 * @param {any[]} addresses
 * @returns {any[]} A new array with all addresses sorted
 */
export default function sortAddresses(addresses) {
  if (!addresses) return [];
  return Array.from(addresses).sort(function (a1, a2) {
    if (a1.is_primary) return -1;
    if (a2.is_primary) return 1;
    var t1 = new Date(a1.created_at).getTime();
    var t2 = new Date(a2.created_at).getTime();

    // Descending
    return t2 - t1;
  });
}