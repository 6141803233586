import {
  SELECT_CONTACT,
  RETRIEVE_SELECTED_CONTACT,
  REMOVE_SELECTED_CONTACT,
} from 'actions';

export const defaultState = '';

export default function selectContactReducer(state = defaultState, action) {
  switch (action.type) {
    case SELECT_CONTACT:
      return action.id;
    case RETRIEVE_SELECTED_CONTACT:
      return action.id;
    case REMOVE_SELECTED_CONTACT:
      return null;
    default:
      return state;
  }
}
