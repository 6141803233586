import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import {
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_ENVIRONMENT,
  DATADOG_PROXY_URL,
  DATADOG_LOGS_ENABLED,
 } from 'src/config/env/env.config';

export const initiateDataDogSession = (userId) => {
  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'ddog-gov.com',
    service: 'app-client',
    env: DATADOG_ENVIRONMENT,
    version: process.env.REACT_APP_CI_COMMIT_ID,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    proxy: (options) => `${DATADOG_PROXY_URL}/${options.path}?${options.parameters}`,
    // https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum
    // TODO: We may want to move these tracing urls to environment config, but they are
    //       very unlikely to change.
    allowedTracingUrls: [
      /https:\/\/core\.uniteus.*\.com\/.*/,
      /https:\/\/core\.uniteus\.io\/.*/,
      /https:\/\/datadog\.uniteus\.com\/.*/,
      /https:\/\/datadog\.uniteus\.io\/.*/,
    ],
  });
  datadogRum.startSessionReplayRecording();
  datadogRum.setUser({ id: userId });
};

export const updateDataDogSessionUser = ({
  employee,
  provider,
  email,
  name,
}) => {
  datadogRum.setUserProperty('employee', employee);
  datadogRum.setUserProperty('provider', provider);
  datadogRum.setUserProperty('email', email);
  datadogRum.setUserProperty('name', name);
};

export const initLogs = () => {
  datadogLogs.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'ddog-gov.com',
    service: 'app-client',
    env: DATADOG_ENVIRONMENT,
    version: process.env.REACT_APP_CI_COMMIT_ID || 'local',
    defaultPrivacyLevel: 'mask-user-input',
    proxy: (options) => `${DATADOG_PROXY_URL}/${options.path}?${options.parameters}`,
    // https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum
    // TODO: We may want to move these tracing urls to environment config, but they are
    //       very unlikely to change.
    allowedTracingUrls: [
      /https:\/\/core\.uniteus.*\.com\/.*/,
      /https:\/\/core\.uniteus\.io\/.*/,
      /https:\/\/datadog\.uniteus\.com\/.*/,
      /https:\/\/datadog\.uniteus\.io\/.*/,
    ],
  });

  const consoleErrorInstance = console.error;
  console.error = (error, ...rest) => {
    if (DATADOG_LOGS_ENABLED) {
      datadogLogs.logger.error(error, ...rest);
    }
    consoleErrorInstance(error, ...rest);
  };

  const consoleLogInstance = console.log;
  console.log = (log, ...rest) => {
    if (DATADOG_LOGS_ENABLED) {
      datadogLogs.logger.log(log, ...rest);
    }
    consoleLogInstance(log, ...rest);
  };
};
