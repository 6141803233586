const SENT_BY_FILTER_KEY = 'referral_sender_users';
const PRIMARY_WORKER_FILTER_KEY = 'primary_worker_users';
const CARE_COORDINATOR_FILTER_KEY = 'care_coordinator_users';
const GROUPS = 'groups';
const PROGRAMS_FILTER_KEY = 'programs_filter';
const SENT_BY = 'sent_by';
export const REFERRED_TO_GROUPS = 'referred_to_groups';
export const REFERRED_BY_GROUPS = 'referred_by_groups';

export const ASYNC_DASHBOARD_FILTERS_KEYS = [
  SENT_BY_FILTER_KEY,
  PRIMARY_WORKER_FILTER_KEY,
  CARE_COORDINATOR_FILTER_KEY,
  GROUPS,
  PROGRAMS_FILTER_KEY,
  REFERRED_BY_GROUPS,
  REFERRED_TO_GROUPS,
  SENT_BY,
];
