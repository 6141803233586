import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const TableBody = ({ children, className, 'data-testid': dataTestId }) => (
  <tbody className={cx('divide-y divide-solid divide-dark-fill-blue', className)} data-testid={dataTestId}>
    {children}
  </tbody>
);

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
};

TableBody.defaultProps = {
  className: '',
  'data-testid': undefined,
};

export default TableBody;
