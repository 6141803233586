import { hasUserRole } from '.';

export function isReferralsUser(user, groupId) {
  return hasUserRole({
    groupId,
    name: 'Referrals User',
    roleType: 'primary',
    user,
  });
}

export default isReferralsUser;
