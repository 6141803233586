import {
  useFind,
  useCreateRecord,
  useUpdateRecord,
  useInvalidateQueries,
} from 'src/api/APIHooks';
import { API_ADAPTERS } from 'src/api/config';
import { defaultQueryConfig, defaultMutationConfig } from './apiHookOptions';

export const useUsers = ({ ids }, queryConfig) => useFind(
  'user',
  { id: ids },
  {
    api: API_ADAPTERS.AUTH_API,
    queryConfig: { ...defaultQueryConfig, ...queryConfig },
  },
);

export const useUsersByEmail = ({ email }, queryConfig) => useFind(
  'user',
  {
    email,
  },
  {
    api: API_ADAPTERS.AUTH_API,
    queryConfig: { ...defaultQueryConfig, ...queryConfig },
  },
);

export const useCreateUser = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useCreateRecord(
    'user',
    {
      api: API_ADAPTERS.AUTH_API,
      mutationConfig: {
        onSuccess: () => {
          invalidateQueries('user');
        },
        ...defaultMutationConfig,
        ...mutationConfig,
      },
    },
  );
};

export const useUpdateUser = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useUpdateRecord('user', {
    api: API_ADAPTERS.AUTH_API,
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('user');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};
