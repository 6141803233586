var convertStoredAges = function convertStoredAges(months) {
  return months > 24 ? months / 12 : months;
};
var convertStoredAgeUnits = function convertStoredAgeUnits(months) {
  return months > 24 ? 'years' : 'months';
};
var upperAgeLimit = function upperAgeLimit(age_units) {
  return (age_units === null || age_units === void 0 ? void 0 : age_units.toLowerCase()) === 'months' ? 24 : 100;
};
var aboveApiAgeTopLimit = function aboveApiAgeTopLimit(age_count, age_unit) {
  return age_count > upperAgeLimit(age_unit);
};
var convertApiAgeRangeToUnits = function convertApiAgeRangeToUnits(apiAge) {
  var min_age = parseInt(apiAge.split('...')[0], 10);
  var max_age = parseInt(apiAge.split('...')[1], 10) - 1;
  return [min_age ? "".concat(convertStoredAges(min_age)) : '', min_age ? convertStoredAgeUnits(min_age) : '', max_age ? "".concat(convertStoredAges(max_age)) : '', max_age ? convertStoredAgeUnits(max_age) : ''];
};
var convertAgeAndUnitsToNumOfMonths = function convertAgeAndUnitsToNumOfMonths(age_count, age_units) {
  if ((age_units === null || age_units === void 0 ? void 0 : age_units.toLowerCase()) === 'years') {
    return parseInt(age_count, 10) * 12;
  }
  if ((age_units === null || age_units === void 0 ? void 0 : age_units.toLowerCase()) === 'months') {
    return parseInt(age_count, 10);
  }
  return null;
};
var convertAgeToApiAgeRange = function convertAgeToApiAgeRange(age_req_min, age_req_min_units, age_req_max, age_req_max_units) {
  var parsedAgeReqMin = convertAgeAndUnitsToNumOfMonths(age_req_min, age_req_min_units);
  var parsedAgeReqMax = convertAgeAndUnitsToNumOfMonths(age_req_max, age_req_max_units);
  if (!parsedAgeReqMin && !parsedAgeReqMax) return null;
  if (!parsedAgeReqMin) return "...".concat(parsedAgeReqMax + 1);
  if (!parsedAgeReqMax) return "".concat(parsedAgeReqMin, "...");
  return "".concat(parsedAgeReqMin, "...").concat(parsedAgeReqMax + 1);
};
var convertAgesForDisplay = function convertAgesForDisplay(count, units) {
  if (units === 'years' && count < 2) {
    return count * 12;
  }
  if (units === 'months' && count >= 24) {
    return count / 12;
  }
  return count;
};
var convertAgeUnitsForDisplay = function convertAgeUnitsForDisplay(count, units) {
  if ((units === null || units === void 0 ? void 0 : units.toLowerCase()) === 'years' && count < 2) {
    return 'months';
  }
  if ((units === null || units === void 0 ? void 0 : units.toLowerCase()) === 'months' && count >= 24) {
    return 'years';
  }
  return units;
};
var processAgeText = function processAgeText(age_min, age_min_units, age_max, age_max_units) {
  if (aboveApiAgeTopLimit(age_min, age_min_units) || aboveApiAgeTopLimit(age_max, age_max_units)) {
    return '';
  }
  var age_min_converted = age_min ? convertAgesForDisplay(age_min, age_min_units) : '';
  var age_min_converted_units = age_min ? convertAgeUnitsForDisplay(age_min, age_min_units) : '';
  var age_max_converted = age_max ? convertAgesForDisplay(age_max, age_max_units) : '';
  var age_max_converted_units = age_max ? convertAgeUnitsForDisplay(age_max, age_max_units) : '';
  if (age_min_converted && age_max_converted && age_min_converted_units && age_max_converted_units) {
    return "".concat(age_min_converted, " ").concat(age_min_converted_units, " to ").concat(age_max_converted, " ").concat(age_max_converted_units);
  }
  if (age_min_converted && age_min_converted_units) {
    return "".concat(age_min_converted, " ").concat(age_min_converted_units, " and over");
  }
  if (age_max_converted && age_max_converted_units) {
    return "".concat(age_max_converted, " ").concat(age_max_converted_units, " and under");
  }
  return '';
};
var agePreview = function agePreview(age_min, age_min_units, age_max, age_max_units) {
  var ageText = processAgeText(age_min, age_min_units, age_max, age_max_units);
  if (ageText) {
    return ageText;
  }
  return 'Age range will appear here';
};
var getMaximumAgeForMinRange = function getMaximumAgeForMinRange(_, age_min_units, age_max_count, age_max_units) {
  var maxAge = upperAgeLimit(age_min_units);
  if (!age_max_count || !age_max_units) return maxAge;
  if (age_max_count && age_min_units.toLowerCase() === 'years') {
    maxAge = Math.min(age_max_units.toLowerCase() === 'years' ? age_max_count : Math.floor(age_max_count / 12), maxAge);
  } else if (age_max_count && age_min_units.toLowerCase() === 'months') {
    maxAge = Math.min(age_max_units.toLowerCase() === 'years' ? age_max_count * 12 : age_max_count, maxAge);
  }
  return maxAge;
};
var getMinimumAgeForMaxRange = function getMinimumAgeForMaxRange(age_min_count, age_min_units, _, age_max_units) {
  var minAge = 0;
  if (!age_min_count || !age_min_units) return minAge;
  if (age_min_count && age_max_units.toLowerCase() === 'years') {
    minAge = Math.max(age_min_units.toLowerCase() === 'years' ? age_min_count : Math.floor(age_min_count / 12), minAge);
  } else if (age_min_count && age_max_units === 'months') {
    minAge = Math.max(age_min_units.toLowerCase() === 'years' ? age_min_count * 12 : age_min_count, minAge);
  }
  return minAge;
};
var basicChecks = function basicChecks(age_count, age_units) {
  if (!age_count && !age_units) return '';
  if (!age_count && age_units) return 'Please enter an age';
  if (!age_units) return 'Units required';
  if (aboveApiAgeTopLimit(age_count, age_units)) return 'Cannot exceed 24 months or 100 years';
  if (age_count < 0) return 'Must be above 0';
  return '';
};
var validateMinRange = function validateMinRange(age_min_count, age_min_units, age_max_count, age_max_units) {
  var outOfRangeOrNoUnits = basicChecks(age_min_count, age_min_units);
  if (outOfRangeOrNoUnits) return outOfRangeOrNoUnits;
  if (!age_min_count) return '';
  var maxValidAge = getMaximumAgeForMinRange(age_min_count, age_min_units, age_max_count, age_max_units);
  if (age_min_count > maxValidAge) return 'Age minimum must be lower than max value';
  return '';
};
var validateMaxRange = function validateMaxRange(age_min_count, age_min_units, age_max_count, age_max_units) {
  var outOfRangeOrNoUnits = basicChecks(age_max_count, age_max_units);
  if (outOfRangeOrNoUnits) return outOfRangeOrNoUnits;
  if (!age_max_count) return '';
  var minValidAge = getMinimumAgeForMaxRange(age_min_count, age_min_units, age_max_count, age_max_units);
  if (age_max_count < minValidAge) return 'Age maximum must be larger than min value';
  return '';
};
export default {
  upperAgeLimit: upperAgeLimit,
  convertApiAgeRangeToUnits: convertApiAgeRangeToUnits,
  convertAgeToApiAgeRange: convertAgeToApiAgeRange,
  agePreview: agePreview,
  processAgeText: processAgeText,
  validateMinRange: validateMinRange,
  validateMaxRange: validateMaxRange
};