import { pluckSelectedGroupIds } from 'src/components/Referrals/ReferralGroupsPrograms/utils'

function canAddMoreOONGroups({ selectedFields, suggestedGroups }) {
  const selectedGroupIds = pluckSelectedGroupIds(selectedFields, 'group');

  /**
   * 1st check - all groups submitted: hide button
   * 2nd check - selectedFields are greater than 0: show button
   * 3rd check - default to false: hide button
   */

  if (suggestedGroups.length > 0 && selectedFields.length === suggestedGroups.length) {
    return false;
  }
  if (selectedGroupIds.length > 0) {
    return true;
  }
  return false;
}

export default canAddMoreOONGroups;
