import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  get,
  noop,
  isEmpty,
  compact,
  map,
  difference,
  isError,
  omitBy,
} from 'lodash';
import {
  Form,
  Field,
} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {
  BaseCard,
  BaseCardHeader,
  BaseCardBody,
  SelectField,
  decodeHTML,
} from '@unite-us/ui';
import { NoteDisclosure, TrackerContext } from '@unite-us/client-utils';
import { validations } from '@unite-us/app-components';
import callOrLog from 'src/common/utils/callOrLog';
import { SERVICES_PROVIDED } from 'common/utils/EventTracker/utils/eventConstants';
import {
  feeScheduleProvidedServiceInitialValues,
  validateUnitAmount,
} from 'src/common/form/Interactions/utils';
import { Spinner } from 'common/spinners';
import ErrorMessage from 'common/utils/ErrorMessage';
import WarningMessage from 'common/utils/WarningMessage';
import {
  forbidContractedServiceCreationForUnconsentedClient,
} from 'src/common/utils/FeatureFlags/flags';
import {
  createFeeScheduleProvidedService,
  updateFeeScheduleProvidedService,
} from 'actions/Case/Contact/Group';
import Notifier from 'common/helpers/Notifier';
import { createFileUpload, deleteFileUpload, fetchFileUploads as fetchPsFileUploads } from 'src/actions/FileUploads';
import PaymentsTrackServiceFundsDistributed from './PaymentsTrackServiceFundsDistributed';
import PaymentsTrackServiceDetailsForm from './PaymentsTrackServiceDetailsForm';
import InteractionNoteField from './InteractionNoteField';
import PaymentsSupportingDocuments from './PaymentsSupportingDocuments';
import FormInteractionsButtons from '../FormInteractionsButtons';
import ContractedServiceAuthorizationDetails from '../ContractedServiceAuthorizationDetails';
import MetaFields from './MetaFields';
import ProcedureCodeSelectField from './ProcedureCodeSelectField';
import useInsuranceValidations from './useInsuranceValidations';

const FUNDS_DISTRIBUTED = 'funds_distributed';
const CLIENT_HAS_NOT_CONSENTED_ERROR = 'Client must be consented to submit for review.';
const INSURANCE_ID_ERROR =
  'You must enter insurance information on the Client\'s Profile before you can submit for review.';

const validateInsuranceId = (value) => (
  validations.isRequired(value, INSURANCE_ID_ERROR)
);
const DATE_SELECTOR_OPTIONS = [
  { label: 'Single Date', value: 'Single Date' },
  { label: 'Date Range', value: 'Date Range' },
];

const SectionSeparator = ({ className }) => (
  <div className={cx(className, 'border-t border-solid border-dark-fill-blue -mx-2 lg:-mx-8 xl:-mx-16')} />
);
SectionSeparator.propTypes = {
  className: PropTypes.string,
};
SectionSeparator.defaultProps = {
  className: '',
};

function requestDateOutOfRange(requestDate, startDate, endDate) {
  return (requestDate) &&
    (
      (startDate && moment.unix(requestDate) < moment(startDate)) ||
      (endDate && moment.unix(requestDate) > moment(endDate))
    );
}

const unitAmountValidations = (enforceRequiredFields) => (value) => (
  validateUnitAmount(value, enforceRequiredFields)
);

export const PaymentsTrackService = (props) => {
  const [enforceRequiredFields, setEnforceRequiredFields] = useState(true);

  const eventTracker = useContext(TrackerContext);

  const {
    contactId,
    groupId,
    programId,
    serviceCaseId,
    distributionReasons,
    feeScheduleProgramName,
    ignoreSocialCareExpiredDates,
    isEditing,
    insuranceRequired,
    paymentMethods,
    paymentType,
    isCostType,
    socialInsurances,
    zcodeOptions,
    metafields,
    placeOfServiceOptions,
    procedureCodesWithModifiers,
    cancelFunction,
    isBillable,
    disallowContractedServiceForUnconsentedClient,
    isPersonConsented,
    canInvoiceAboveRemainingAuthorizedAmount,
    serviceAuthorization,
    initialValues,
    serviceAuthorizationInvoiceInformation,
    fetchFileUploads,
    planId,
    setInferredPlanId,
  } = props;

  const {
    hasSelectedDates,
    hasValidInsurance,
    isInsuranceExpired,
    validateInsuranceCoverage,
  } = useInsuranceValidations({
    socialInsurances,
    setInferredPlanId,
    ignoreSocialCareExpiredDates,
    serviceAuthorization,
  });

  const requiredMetaFieldFiles = metafields.filter(({ type }) => type === 'file');
  const hasAdditionalInfo = !isEmpty(metafields) ||
                            !isEmpty(placeOfServiceOptions) ||
                            zcodeOptions.length > 1 ||
                            procedureCodesWithModifiers.length > 1;
  const canForbidContractedServiceCreationWhenNoConsent = disallowContractedServiceForUnconsentedClient &&
    !isPersonConsented;

  const requestedValueNonValid = (formProps) => (
    serviceAuthorizationInvoiceInformation?.remainingAuthorizedAmount < 0 ||
    formProps.values?.provided_service?.unit_amount >
    serviceAuthorizationInvoiceInformation?.remainingAuthorizedAmount
  );

  const requestedStartDate = (formProps) => formProps.values?.provided_service?.service_start_date;
  const requestedEndDate = (formProps) => formProps.values?.provided_service?.service_end_date;
  const serviceAuthorizationStartDate = serviceAuthorizationInvoiceInformation?.approvedStartsAt;
  const serviceAuthorizationEndDate = serviceAuthorizationInvoiceInformation?.approvedEndsAt;
  const requestedDatesNonValid = (formProps) => (
    requestDateOutOfRange(requestedStartDate(formProps), serviceAuthorizationStartDate, serviceAuthorizationEndDate) ||
    requestDateOutOfRange(requestedEndDate(formProps), serviceAuthorizationStartDate, serviceAuthorizationEndDate)
  );

  const formatMetadataValues = (metaValues) => {
    const formatedMetadata = metafields.map((metafield) => {
      const value = get(metaValues, metafield.field, null);
      switch (metafield.type) {
        case 'address':
          return {
            field: metafield.field,
            value: omitBy(value, isEmpty),
          };
        case 'datetime':
        case 'date':
          return {
            field: metafield.field,
            value: value ? new Date(value * 1000).toISOString() : value,
          };
        case 'file':
          return null;
        case 'dropdown':
          return {
            field: metafield.field,
            value: value ? decodeHTML(value) : value,
          };
        default:
          return { field: metafield.field, value };
      }
    });
    return compact(formatedMetadata);
  };

  const updateFiles = async (filesValues, providedServiceId) => {
    const fileResponses = await Promise.all(
      map(filesValues, (async (value = [], field) => {
        const filesToCreate = value.filter((file) => file.tempId);
        const filesToMaintain = value.filter((file) => !file.tempId).map((file) => file.id);
        const previousMetadata = (props.providedService.metadata || []).find((md) => md.field === field);
        const previousMetadataValue = previousMetadata && previousMetadata.value;
        const filesToDelete = difference(previousMetadataValue, filesToMaintain);
        const createResponses = filesToCreate.map(async ({ file }) => {
          const response = await props.createFileUpload({
            file,
            recordType: 'provided_services',
            recordId: providedServiceId,
          });

          return isError(response) ? { file, response } : response;
        });
        const deleteResponses = filesToDelete.map(async (fileId) => {
          const response = await props.deleteFileUpload({
            fileId,
            recordType: 'provided_services',
            recordId: providedServiceId,
          });

          return isError(response) ? { file: props.fileUploads[fileId], response } : null;
        });

        const allResponses = await Promise.all([
          ...createResponses,
          ...deleteResponses,
          ...filesToMaintain,
        ]);

        return { field, value: compact(allResponses) };
      })),
    );

    let failedFileNames = '';
    let failedDeletedFileNames = '';

    const successFileResponses = fileResponses.map(({ field, value }) => {
      const failedDeletedFileIds = [];
      const fileIds = value.filter((fileValue) => {
        if (fileValue.file) {
          if (fileValue.file.name) {
            failedFileNames += failedFileNames ? `, ${fileValue.file.name}` : fileValue.file.name;
          }
          if (fileValue.file.filename) {
            failedDeletedFileNames += failedDeletedFileNames ? `, ${fileValue.file.filename}` : fileValue.file.filename;
            failedDeletedFileIds.push(fileValue.file.id);
          }
        }
        return !fileValue.file;
      });

      return { field, value: [...fileIds, ...failedDeletedFileIds] };
    });

    return { successFileResponses, failedFileNames, failedDeletedFileNames };
  };

  const formatProvidedServiceRequestParams = (formValues) => {
    const {
      distribution_reason,
      payment_method,
      period_of_service,
      service_end_date,
      procedure_code_rows,
      unit_amount,
      actual_unit_amount,
    } = formValues.provided_service;

    let procedure_codes = [];
    let procedure_code_modifiers = [];
    if (procedure_code_rows) {
      procedure_codes = procedure_code_rows.map((row) => row?.procedure_code);
      procedure_code_modifiers = procedure_code_rows.map((row) => row?.procedure_code_modifier);
    }

    // ** NOTE ** If form value comes from a SelectField, make sure to decodeHTML

    const transformedEndDate = period_of_service === 'Single Date' ? null : service_end_date;
    const decodedDistributionReason = distribution_reason ? decodeHTML(distribution_reason) : null;
    const decodedPaymentMethod = payment_method ? decodeHTML(payment_method) : null;

    return {
      providedService: {
        ...formValues.provided_service,
        unit_amount: parseInt(unit_amount, 10),
        actual_unit_amount: parseInt(actual_unit_amount, 10),
        distribution_reason: decodedDistributionReason,
        metadata: formatMetadataValues(formValues?.metafields),
        payment_method: decodedPaymentMethod,
        service_end_date: transformedEndDate,
        procedure_codes: compact(procedure_codes),
        procedure_code_modifiers: compact(procedure_code_modifiers),
      },
      caseId: serviceCaseId,
      contactId,
      groupId,
      programId,
      planId,
    };
  };

  const draftOrSubmitProvidedService = async ({
    filesValues,
    params,
    providedServiceId,
  }) => {
    const {
      successFileResponses,
      failedFileNames,
      failedDeletedFileNames,
    } = await updateFiles(filesValues, providedServiceId);

    const providedService = {
      ...params.providedService,
      metadata: params.providedService.metadata ?
        [...params.providedService.metadata, ...successFileResponses] :
        successFileResponses,
    };

    if (failedFileNames.length || failedDeletedFileNames.length) {
      const successfulFileIdsExist = successFileResponses.find(({ value }) => value && value.length);

      if (successfulFileIdsExist) {
        await props.updateFeeScheduleProvidedService({
          ...params,
          providedService: { ...providedService, state: 'active' },
          id: providedServiceId,
          showNotification: false,
        });
      }

      if (!isEmpty(failedFileNames) && !isEmpty(failedDeletedFileNames)) {
        Notifier.dispatch(
          'error',
          `There was a problem uploading and deleting some of your file(s). Your contracted service has been saved.
          Try uploading and deleting your file(s) again: ${failedFileNames}, ${failedDeletedFileNames}`,
        );
      } else if (!isEmpty(failedDeletedFileNames)) {
        Notifier.dispatch(
          'error',
          `There was a problem deleting some of your file(s). Your contracted service has been saved.
          Try deleting your file(s) again: ${failedDeletedFileNames}`,
        );
      } else if (!isEmpty(failedFileNames)) {
        Notifier.dispatch(
          'error',
          `There was a problem uploading some of your file(s). Your contracted service has been saved.
          Try uploading your file(s) again: ${failedFileNames}`,
        );
      }

      cancelFunction();
      return null;
    }
    await props.updateFeeScheduleProvidedService({ ...params, providedService, id: providedServiceId });
    cancelFunction();
    return null;
  };

  const onSubmit = (values) => {
    const formatedParams = formatProvidedServiceRequestParams(values);
    const filesValues = get(values, 'files', {});
    callOrLog(() => eventTracker(SERVICES_PROVIDED.clickedSaveNote));
    // Edit a provided service
    if (props.isEditing) {
      return draftOrSubmitProvidedService({
        filesValues,
        params: formatedParams,
        providedServiceId: props.providedServiceId,
      });
    }

    // Create a new provided service
    return props.createFeeScheduleProvidedService(formatedParams)
      .then((payload) => {
        if (payload) {
          draftOrSubmitProvidedService({
            filesValues,
            params: formatedParams,
            providedServiceId: get(payload, 'data.data.id', ''),
          });
        }
      });
  };

  const onSaveDraft = (formProps) => () => {
    formProps.form.change('provided_service.state', 'active');
    setEnforceRequiredFields(false);
    return onSubmit(formProps.values);
  };

  useEffect(() => {
    const fileMetadata = initialValues.provided_service.metadata?.filter((d) => d.type === 'file');

    // Fetch only if provided service has attached files that are not present in initial values
    const fetchFles = isEmpty(initialValues.files) && !isEmpty(fileMetadata);

    if (fetchFles) {
      fetchFileUploads({ recordId: initialValues.provided_service.id, recordType: 'provided_service' });
    }
  }, [fetchFileUploads, initialValues.provided_service.id]);

  return (
    <BaseCard className={isEditing ? 'payments-track-service-card--edit' : 'payments-track-service-card--new'}>
      <BaseCardHeader title={feeScheduleProgramName} />
      <BaseCardBody className="pt-4 pb-9 px-4">
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          keepDirtyOnReinitialize
        >
          {(formProps) => (
            <form className="payments-track-service px-4 lg:px-16 xl:px-32" data-role="provided-service-form">
              {serviceAuthorization && (
                <>
                  <h3 className="text-lg text-text-blue mb-4 leading-7">Authorization Details</h3>
                  <ContractedServiceAuthorizationDetails {...serviceAuthorizationInvoiceInformation} />
                  <SectionSeparator className="my-10" />
                </>
              )}
              <h3 className="text-lg text-text-blue mb-4 leading-7">Service Details</h3>
              {paymentType === FUNDS_DISTRIBUTED ? (
                <PaymentsTrackServiceFundsDistributed
                  distributionReasons={distributionReasons}
                  paymentMethods={paymentMethods}
                  providedServiceUnit={formProps.values.provided_service.unit}
                  validateInsuranceCoverage={validateInsuranceCoverage}
                />
              ) : (
                <PaymentsTrackServiceDetailsForm
                  canInvoiceAboveRemainingAuthorizedAmount={canInvoiceAboveRemainingAuthorizedAmount}
                  enforceRequiredFields={enforceRequiredFields}
                  ignoreSocialCareExpiredDates={ignoreSocialCareExpiredDates}
                  isBillable={isBillable}
                  isCostType={isCostType}
                  options={DATE_SELECTOR_OPTIONS}
                  requestedValueNonValid={requestedValueNonValid(formProps)}
                  socialInsurances={socialInsurances}
                  unitAmountValidations={unitAmountValidations(enforceRequiredFields)}
                  validateInsuranceCoverage={validateInsuranceCoverage}
                />
              )}
              {requestedDatesNonValid(formProps) && (
                <div className="col-span-5 px-0 flex space-x-2 -mt-6 mb-8">
                  <ErrorMessage>
                    This contracted service cannot be submitted
                    because the selected service delivery dates fall outside the authorized service delivery dates.
                  </ErrorMessage>
                </div>
              )}
              {hasSelectedDates && !ignoreSocialCareExpiredDates && !hasValidInsurance && (
                <div className="col-span-5 px-0 flex space-x-2 -mt-6 mb-8">
                  <ErrorMessage>
                    Client must be enrolled in a social insurance plan
                    with the {feeScheduleProgramName} contracted program during the selected service dates.
                  </ErrorMessage>
                </div>
              )}
              {hasSelectedDates && ignoreSocialCareExpiredDates && !hasValidInsurance && (
                <div className="col-span-5 px-0 flex space-x-2 -mt-6 mb-8">
                  <ErrorMessage>
                    The selected service dates cannot be prior to the client&apos;s
                    social care coverage enrollment start date.
                  </ErrorMessage>
                </div>
              )}
              {hasSelectedDates && ignoreSocialCareExpiredDates && isInsuranceExpired && (
                <div className="col-span-5 px-0 flex space-x-2 -mt-6 mb-8">
                  <WarningMessage>
                    The client&apos;s social care coverage ended prior to or during
                    the selected service delivery date(s).
                    Please verify the service date(s) before submitting.
                  </WarningMessage>
                </div>
              )}
              <div id="additional-information" className={cx((!hasAdditionalInfo) && 'hidden')}>
                <SectionSeparator className="mt-1 mb-10" />
                <h3 className="text-lg text-text-blue normal-case tracking-normal leading-7 mb-4">
                  Additional Information
                </h3>
                { zcodeOptions.length > 0 && (
                  <Field
                    name="provided_service.zcodes"
                    validate={enforceRequiredFields ? (value) => validations.isRequired(value) : noop}
                  >
                    {(fieldProps) => (
                      <SelectField
                        id="provided-service-zcodes"
                        label="Diagnosis Code (Z-Code)"
                        multiple
                        options={zcodeOptions}
                        placeholder="Z-codes"
                        autoSelectValue
                        className="mt-5 mb-10"
                        required
                        {...fieldProps}
                      />
                    )}
                  </Field>
                )}
                { placeOfServiceOptions.length > 0 && (
                  <Field
                    name="provided_service.place_of_service"
                    validate={(val) => validations.isRequired(val)}
                  >
                    {(fieldProps) => (
                      <SelectField
                        id="provided-service-place_of_service"
                        label="Place of Service"
                        options={placeOfServiceOptions}
                        placeholder="Place of Service"
                        className="mt-5 mb-10"
                        required
                        {...fieldProps}
                      />
                    )}
                  </Field>
                )}
                {!isEmpty(procedureCodesWithModifiers) && (
                  <ProcedureCodeSelectField
                    procedureCodesWithModifiers={procedureCodesWithModifiers}
                    formProps={formProps}
                  />
                )}
                <MetaFields
                  metafields={metafields}
                  enforceRequiredFields={enforceRequiredFields}
                />
              </div>
              <SectionSeparator className="mt-5 mb-10" />
              <div className="payments-track-service__notes">
                <InteractionNoteField
                  afterLabelContent={<NoteDisclosure />}
                  name="provided_service.note"
                  label={(
                    <h3 className="text-lg text-text-blue normal-case tracking-normal leading-7">
                      Note
                    </h3>
                  )}
                  required={false}
                />
              </div>
              <div className={cx(formProps.submitting && 'relative z-0')}>
                {!isEmpty(requiredMetaFieldFiles) && (
                  <>
                    <SectionSeparator className="mt-4 mb-10" />
                    <h3 className="text-lg text-text-blue mb-4 leading-7">
                      Supporting Documents
                    </h3>
                    <p className="mb-10 text-13px">
                      Upload your documents from your device. Acceptable formats are .CSV, .DOCX, .PDF, .JPG, or .PNG
                    </p>
                    <div className="space-y-8">
                      {
                        requiredMetaFieldFiles.map(({
                          field: fieldName,
                          label,
                          validations: metafieldValidations,
                        }) => {
                          const requiredMetaField = get(metafieldValidations, 'required', false);

                          return (
                            <Field
                              name={`files.${fieldName}`}
                              key={fieldName}
                              validate={requiredMetaField && enforceRequiredFields ? validations.isRequired : noop}
                            >
                              {(fieldProps) => (
                                <PaymentsSupportingDocuments
                                  label={label}
                                  fieldName={fieldName}
                                  field={fieldProps.input}
                                  required={requiredMetaField && enforceRequiredFields}
                                  {...fieldProps}
                                />
                                )}
                            </Field>
                          );
                        })
                      }
                    </div>
                  </>
                )}
                {formProps.submitting && !isEmpty(requiredMetaFieldFiles) && (
                  <div className="absolute inset-0 flex justify-center items-center z-10">
                    <Spinner />
                  </div>
                )}
              </div>
              {enforceRequiredFields && insuranceRequired && (
                <div
                  id="insurance-error"
                  className="text-red mb-4"
                >
                  {validateInsuranceId(formProps.values.insuranceExternalMemberId)}
                </div>
              )}
              {canForbidContractedServiceCreationWhenNoConsent && (
                <div
                  id="client-not-consented-error"
                  className="text-red mb-4"
                >
                  {CLIENT_HAS_NOT_CONSENTED_ERROR}
                </div>
              )}
              <SectionSeparator className="p-0 mb-6 -mx-4 lg:-mx-16 xl:-mx-32" />
              {isBillable ? (
                <FormInteractionsButtons
                  secondaryActionHandler={onSaveDraft(formProps)}
                  primaryActionHandler={formProps.handleSubmit}
                  cancelActionHandler={cancelFunction}
                  submitting={formProps.submitting}
                  interaction="fee-schedule-provided-service"
                  primaryLabel={'SUBMIT FOR REVIEW'}
                  secondaryLabel={'SAVE AS DRAFT'}
                  conditionToDisableButton={
                    canForbidContractedServiceCreationWhenNoConsent ||
                    !hasValidInsurance || (
                      !canInvoiceAboveRemainingAuthorizedAmount && (
                        requestedValueNonValid ||
                        requestedDatesNonValid
                      )
                    )
                  }
                  conditionToDisableSecondaryButton={
                    !hasValidInsurance || (
                      !canInvoiceAboveRemainingAuthorizedAmount && (
                        requestedValueNonValid ||
                        requestedDatesNonValid
                      )
                    )
                  }
                />
              ) : (
                <FormInteractionsButtons
                  primaryActionHandler={formProps.handleSubmit}
                  cancelActionHandler={cancelFunction}
                  submitting={formProps.submitting}
                  interaction="fee-schedule-provided-service"
                  primaryLabel={'SAVE'}
                  conditionToDisableButton={!hasValidInsurance}
                />
              )}
            </form>
          )}
        </Form>
      </BaseCardBody>
    </BaseCard>
  );
};

PaymentsTrackService.propTypes = {
  cancelFunction: PropTypes.func,
  contactId: PropTypes.string,
  disallowContractedServiceForUnconsentedClient: PropTypes.bool,
  distributionReasons: PropTypes.array,
  feeScheduleProgramName: PropTypes.string,
  fileUploads: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  ignoreSocialCareExpiredDates: PropTypes.bool,
  canInvoiceAboveRemainingAuthorizedAmount: PropTypes.bool,
  initialValues: PropTypes.object,
  insuranceRequired: PropTypes.bool.isRequired,
  isBillable: PropTypes.bool.isRequired,
  isCostType: PropTypes.bool,
  isEditing: PropTypes.bool,
  isPersonConsented: PropTypes.bool,
  metafields: PropTypes.array.isRequired,
  paymentMethods: PropTypes.array,
  paymentType: PropTypes.string,
  placeOfServiceOptions: PropTypes.array,
  procedureCodesWithModifiers: PropTypes.array,
  programId: PropTypes.string.isRequired,
  providedServiceId: PropTypes.string,
  serviceAuthorization: PropTypes.object,
  serviceAuthorizationInvoiceInformation: PropTypes.object,
  serviceCaseId: PropTypes.string.isRequired,
  socialInsurances: PropTypes.array.isRequired,
  zcodeOptions: PropTypes.array,
  createFeeScheduleProvidedService: PropTypes.func.isRequired,
  updateFeeScheduleProvidedService: PropTypes.func.isRequired,
  createFileUpload: PropTypes.func.isRequired,
  deleteFileUpload: PropTypes.func.isRequired,
  providedService: PropTypes.object,
  fetchFileUploads: PropTypes.func.isRequired,
  planId: PropTypes.string,
  setInferredPlanId: PropTypes.func.isRequired,
};

PaymentsTrackService.defaultProps = {
  cancelFunction: noop,
  contactId: '',
  distributionReasons: [],
  feeScheduleProgramName: '',
  ignoreSocialCareExpiredDates: false,
  isCostType: false,
  isEditing: false,
  paymentMethods: [],
  paymentType: '',
  zcodeOptions: [],
  procedureCodesWithModifiers: [],
  providedServiceId: '',
  placeOfServiceOptions: [],
  disallowContractedServiceForUnconsentedClient: false,
  isPersonConsented: false,
  canInvoiceAboveRemainingAuthorizedAmount: false,
  serviceAuthorization: null,
  initialValues: {},
  serviceAuthorizationInvoiceInformation: null,
  providedService: {},
  planId: null,
};

function mapStateToProps(state, ownProps) {
  const { serviceCase, insuranceExternalMemberId } = ownProps;
  const isPersonConsented = get(serviceCase, 'person.consent.state') === 'accepted';
  const serviceCaseId = get(serviceCase, 'id');
  const programId = get(serviceCase, 'program.id');
  const program = get(state, 'groupsPrograms.data').find(({ id }) => id === programId);
  const feeScheduleProgramName = get(program, 'fee_schedule_program.attributes.name');
  const metafields = get(program, 'fee_schedule_program.attributes.metafields', []);
  const paymentType = get(program, 'fee_schedule_program.attributes.payment_type');
  const unitInformation = get(program, 'fee_schedule_program.attributes.unit', '');
  const capInformation = get(program, 'fee_schedule_program.attributes.cap_information');
  const capOrUnitInitialValue = paymentType === 'cost_based_reimbursement' ? capInformation : unitInformation;
  const canInvoiceAboveRemainingAuthorizedAmount =
    get(program, 'fee_schedule_program.attributes.can_invoice_above_remaining_authorized_amount', false);
  const feeScheduleId = get(program, 'fee_schedule_program.relationships.fee_schedule.data.id', '');
  const feeSchedule = get(state, `feeSchedules.data.${feeScheduleId}`, {});
  const ignoreSocialCareExpiredDates = get(feeSchedule, 'ignore_social_care_expired_dates', false);
  const groupId = state.session.groupId;
  const distReasons = get(program, 'fee_schedule_program.attributes.distribution_reasons', []);
  const distributionReasons = (distReasons).map((option) => ({
    field: option,
    value: option,
  }));
  const payMethods = get(program, 'fee_schedule_program.attributes.payment_methods', []);
  const paymentMethods = (payMethods).map((option) => ({
    field: option,
    value: option,
  }));
  const fileUploads = get(state, 'fileUploads.fileUploads', {});

  const initialValues = feeScheduleProvidedServiceInitialValues({
    providedService: ownProps.providedService,
    capOrUnitInitialValue,
    metafields,
    fileUploads,
    insuranceExternalMemberId,
    procedureCodeRows: ownProps.procedureCodesWithModifiers,
    isBillable: ownProps.isBillable,
  });

  return {
    groupId,
    programId,
    distributionReasons,
    feeScheduleProgramName,
    ignoreSocialCareExpiredDates,
    paymentMethods,
    metafields,
    isPersonConsented,
    disallowContractedServiceForUnconsentedClient: forbidContractedServiceCreationForUnconsentedClient(state),
    canInvoiceAboveRemainingAuthorizedAmount,
    initialValues,
    serviceCaseId,
  };
}

export default connect(
  mapStateToProps,
  {
    createFeeScheduleProvidedService,
    updateFeeScheduleProvidedService,
    createFileUpload,
    deleteFileUpload,
    fetchFileUploads: fetchPsFileUploads,
  },
)(PaymentsTrackService);
