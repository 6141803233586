import { THUNK_START, THUNK_END, THUNK_FAILED } from 'actions';

const trackThunks = (store) => (next) => (action) => {
  if (typeof action !== 'function') {
    return next(action);
  }

  return Promise.resolve()
    .then(() => store.dispatch({ type: THUNK_START }))
    .then(() => next(action))
    .then((nextReturnValue) => {
      const delayForNicerUserUI = 1000;
      setTimeout(
        () => store.dispatch({ type: THUNK_END }),
        delayForNicerUserUI,
      );

      return nextReturnValue;
    })
    .catch((error) => {
      store.dispatch({ type: THUNK_FAILED });
      return error;
    });
};

export default trackThunks;
