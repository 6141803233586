import { validations } from '@unite-us/app-components';
import { compact, uuOmit } from 'lodash';
import {
  checkCityStateFieldValues,
  checkPostalFieldValues,
  requireAddressType,
} from './index';

function isRequiredAddressType({
  forceRequired, values, displayFieldSet, value,
}) {
  const currentValuesToValidate = uuOmit(values, ['id', 'is_primary']);
  const anyValidValues = compact(Object.values(currentValuesToValidate)).length;

  if (forceRequired || (anyValidValues && requireAddressType(displayFieldSet) && !value)) {
    return validations.isRequired(value);
  }
  return null;
}

function isRequiredCity({ forceRequired, values, value }) {
  if (forceRequired || (checkPostalFieldValues(values) && !value)) {
    return validations.isRequired(value);
  }

  return null;
}

function isRequiredState({ forceRequired, values, value }) {
  if (forceRequired || (checkPostalFieldValues(values) && !value)) {
    return validations.isRequired(value);
  }
  return null;
}

function isRequiredZipCode({ forceRequired, values, value }) {
  if (forceRequired || (checkCityStateFieldValues(values) && !value)) {
    return validations.isRequired(value);
  }

  return null;
}

function zipCodeNumberLength({ value, message }) {
  if (value && !/\d{5}/.test(value)) {
    return message;
  }

  return null;
}

const afValidations = {
  isRequiredAddressType,
  isRequiredCity,
  isRequiredState,
  isRequiredZipCode,
  zipCodeNumberLength,
};

export default afValidations;
