import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@unite-us/ui';

function EditButton({
  disabled,
  edit,
  id,
  labelText,
  'aria-label': ariaLabel,
}) {
  return (
    <button
      className={disabled ? 'edit-modal__icon edit-modal__disabled' : 'edit-modal__icon'}
      id={id}
      aria-label={ariaLabel}
      onClick={edit}
      type="button"
      tabIndex={0}
    >
      <Icon icon="IconPencil" className="mt-quarter" />
      <p className="edit-modal__text edit-modal__text_ex">{labelText}</p>
    </button>
  );
}

EditButton.propTypes = {
  disabled: PropTypes.bool,
  edit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  'aria-label': PropTypes.string,
};

EditButton.defaultProps = {
  disabled: false,
  labelText: 'Edit',
  'aria-label': undefined,
};

export default EditButton;
