import { get } from 'lodash';
import { addElement } from './addElements';
import removeElement from './removeElement';

function handleAssistanceRequest(state, element, currentSection) {
  const contact = get(element, 'contact', false);

  const assistanceRequest = {
    ...get(element, 'assistance_request', {}),
    requestor: contact,
  };

  const assistanceRequestId = get(assistanceRequest, 'id', false);

  if (!assistanceRequestId || !contact) {
    return state;
  }

  switch (currentSection) {
    case 'assistanceRequests':
      return removeElement(state, assistanceRequestId, 'assistanceRequests');

    case 'processedAssistanceRequests':
      return addElement(state, assistanceRequest, 'processedAssistanceRequests');

    default:
      return state;
  }
}

export default handleAssistanceRequest;
