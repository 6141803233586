import { browserHistory } from 'common/utils/browserHistory';

function goToClosedCasesIndex(caseStatus, caseProviderId, currentProviderId) {
  if (caseProviderId === currentProviderId) {
    switch (caseStatus) {
      case 'off_platform':
        return browserHistory.push('/dashboard/oon-cases/closed');
      default:
        return browserHistory.push('/dashboard/cases/closed');
    }
  }
  return browserHistory.push('/dashboard/external-cases/closed');
}
export default goToClosedCasesIndex;
