import React from 'react';
import NoRequestsExistLogo from 'common/TailwindComponents/NoRequestsExistLogo';

export const NoServiceAuthorizationsExist = () => (
  <>
    <NoRequestsExistLogo />
    <h4 className="font-heavy-font text-base text-dark-grey">
      You don&apos;t have any authorization requests yet.
    </h4>
    <p className="tracking-tight max-w-xs text-dark-grey">
      Requests for authorization are added to this list whenever a
      care manager sends a referral to a fee schedule program.
    </p>
  </>
);
