import React, { PureComponent } from 'react';
import { Button } from '@unite-us/ui';
import PropTypes from 'prop-types';

class RejectConfirmation extends PureComponent {
  render() {
    return (
      <div className="reject-confirmation content-with-actions">
        <div className="content-container">
          <div className="reject-confirmation__text-container">
            <p className="reject-confirmation__text">
              Rejecting a referral will remove it from the inboxes of all the other
              Referral Admins within your organization. Instead, you could Forward
              the referral to another program in your organization that might be
              able to provide the requested service by selecting the Forward option
              and selecting this program&apos;s name in the Referral screen
            </p>
          </div>
        </div>

        <div className="reject-confirmation__button-container actions">
          <span className="action-item">
            <Button
              className="reject-confirmation__cancel mr-half"
              label="Cancel"
              onClick={this.props.onCancelClick}
            />
          </span>
          <span className="action-item">
            <Button
              className="reject-confirmation__forward mr-half"
              label="Forward"
              primary
              onClick={this.props.onForwardClicked}
            />
          </span>
          <span className="action-item">
            <Button
              className="reject-confirmation__reject"
              label="Reject"
              primary
              onClick={this.props.onRejectClicked}
            />
          </span>
        </div>
      </div>
    );
  }
}

RejectConfirmation.propTypes = {
  onRejectClicked: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onForwardClicked: PropTypes.func.isRequired,
};

export default RejectConfirmation;
