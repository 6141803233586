import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NetworkSubHeader from 'src/components/Network/components/NetworkSubHeader';
import SearchPrograms from 'src/components/Browse/SearchPrograms';
import { useSelectedPrograms } from '@unite-us/app-search';
import {
  uup459SupersetPhase2,
  crtb1127AuthPaymentProgramsInReferrals as crtb1127AuthPaymentProgramsInReferralsSelector,
  cerb1199IsSupersetMyNetwork as cerb1199IsSupersetMyNetworkSelector,
} from 'common/utils/FeatureFlags/flags';

function MyNetworkPrograms({
  params: { networkId },
  location: { pathname },
  networks,
  isSupersetEnabled,
  crtb1127AuthPaymentProgramsInReferrals,
  cerb1199IsSupersetMyNetwork,
  ...otherProps
}) {
  const { dispatchRemoveAllPrograms } = useSelectedPrograms();
  const [pageNetworkId, setPageNetworkId] = useState(networkId);

  useEffect(() => dispatchRemoveAllPrograms(), []);

  useEffect(() => {
    if (isSupersetEnabled) {
      setPageNetworkId(networks);
    } else {
      setPageNetworkId(networkId);
    }
  }, [networks, networkId, isSupersetEnabled]);

  if (!pageNetworkId || (!isSupersetEnabled && pageNetworkId !== networkId)) return null;

  return (
    <div className={cerb1199IsSupersetMyNetwork ? 'bg-medium-fill-blue' : 'bg-white'}>
      {!isSupersetEnabled && (
        <NetworkSubHeader
          networkId={pageNetworkId}
          pathname={pathname}
        />
      )}
      <SearchPrograms
        {...otherProps}
        networkId={pageNetworkId}
        crtb1127AuthPaymentProgramsInReferrals
      />
    </div>
  );
}

MyNetworkPrograms.propTypes = {
  params: PropTypes.shape({
    networkId: PropTypes.string,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  networks: PropTypes.arrayOf(PropTypes.string).isRequired,
  isSupersetEnabled: PropTypes.bool.isRequired,
  crtb1127AuthPaymentProgramsInReferrals: PropTypes.bool.isRequired,
  cerb1199IsSupersetMyNetwork: PropTypes.bool.isRequired,
};

MyNetworkPrograms.defaultProps = {
  params: {
    networkId: '',
  },
  location: {
    pathname: '',
  },
};

function mapStateToProps(state) {
  const networks = state.globalState.activeNetworks.map((n) => n.id).join(',');
  const isSupersetEnabled = uup459SupersetPhase2(state);
  const crtb1127AuthPaymentProgramsInReferrals = crtb1127AuthPaymentProgramsInReferralsSelector(state);
  const cerb1199IsSupersetMyNetwork = cerb1199IsSupersetMyNetworkSelector(state);

  return {
    networks,
    isSupersetEnabled,
    crtb1127AuthPaymentProgramsInReferrals,
    cerb1199IsSupersetMyNetwork,
  };
}

export default connect(mapStateToProps)(MyNetworkPrograms);
