import _ from 'lodash';
import {
  WS_CONNECT,
  WS_DISCONNECT,
} from 'actions';

const defaultState = {
  connected: false,
  channel: null,
  messages: [],
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case WS_CONNECT:
      return _.assign({}, state, {
        connected: true,
        channel: action.channel,
      });
    case WS_DISCONNECT:
      return _.assign({}, state, {
        connected: false,
        channel: null,
      });
    default:
      return state;
  }
}
