import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';
import cx from 'classnames';
import { theme } from 'src/../tailwind.config';
import DollarAmount from 'src/common/display/Money/DollarAmount';
import ToolTip from '../../../common/ToolTip/ToolTip';

const InvoiceDifferenceIndicator = ({
  invoiceStatus,
  invoiceAmountPaid,
  invoiceAmountInvoiced,
  invoicedResolved,
}) => {
  const isLower = invoiceAmountInvoiced - invoiceAmountPaid > 0;

  const ButtonContent = () => (
    <Icon
      className={cx(isLower && 'transform rotate-180')}
      icon="IconFatArrowUp"
      color={theme.extend.colors.red}
      size={12}
    />
  );

  const PanelContent = () => (
    <div className="whitespace-normal p-6 max-w-xs bg-white rounded shadow-md
      border border-solid border-dark-border-blue"
    >
      <p>{`This arrow indicates the amount paid was ${isLower ? 'less' : 'greater'} than the invoice amount.`}</p>
      <div className="flex flex-row mt-6">
        <p className="flex-none w-32 font-semibold">Invoice Total</p>
        <DollarAmount
          className="flex-auto"
          value={invoiceAmountInvoiced}
          convertCents
          dataLabel="Invoice Total"
        />
      </div>
      <div className="flex flex-row mt-2">
        <p className="flex-none w-32 font-semibold">Paid Amount</p>
        <DollarAmount
          className="flex-auto"
          value={invoiceAmountPaid}
          convertCents
          dataLabel="Paid Amount"
        />
      </div>
    </div>
  );

  const amountsEqual = invoiceAmountInvoiced === invoiceAmountPaid;
  const hideIndicator = amountsEqual ||
    !invoiceAmountPaid ||
    invoicedResolved ||
    invoiceStatus !== 'paid';

  if (hideIndicator) {
    return null;
  }

  return (
    <ToolTip
      buttonComponentContent={ButtonContent}
      buttonClassName="ml-2"
      panelComponentContent={PanelContent}
      enableHover
      placement="bottom"
      offsetOptions={{ offset: [0, 20] }}
    />
  );
};

InvoiceDifferenceIndicator.propTypes = {
  invoiceStatus: PropTypes.string.isRequired,
  invoiceAmountPaid: PropTypes.number.isRequired,
  invoiceAmountInvoiced: PropTypes.number.isRequired,
  invoicedResolved: PropTypes.bool.isRequired,
};

export default InvoiceDifferenceIndicator;
