import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { oxford, pluralize } from 'humanize-plus';
import { getFilterSummaryServiceNames } from 'src/components/Browse/utils/filters';
import './FilterSummary.scss';

// const getResultsText = (resultsCount = 0) => (
//   `${resultsCount} ${pluralize(resultsCount, 'result')}`
// );

const getLabelText = ({ distance, address = {} }) => {
  const distanceText = isEmpty(distance) ?
    ' any distance' :
    `${distance} ${pluralize(distance, 'mile')}`;

  const addressString = address.address || 'your location';

  return `${distanceText} of ${addressString}.`;
};

const getServiceText = (selectedServiceType = {}, services) => {
  if (!isEmpty(selectedServiceType)) {
    return (
      <span className="filter-summary__services">
        {selectedServiceType.name}
      </span>
    );
  }

  if (!isEmpty(services)) {
    return (
      <span className="filter-summary__services" title={oxford(services)}>
        {oxford(services, 1)}
      </span>
    );
  }

  return '';
};

const FilterSummary = (props) => {
  const {
    className,
    filters,
    // resultsCount,
    selectedServiceType,
    serviceOptions,
    toggleFiltersDrawer,
    message,
  } = props;

  const services = getFilterSummaryServiceNames(filters.serviceTypes, serviceOptions);
  const forText = isEmpty(services) ? '' : 'for';
  const serviceText = getServiceText(selectedServiceType, services);
  // const resultsText = getResultsText(resultsCount); //TODO: commented because CW-144
  const withinText = <>{forText} {serviceText} within{' '}</>;
  // const messageText = message && `. ${message}`;//TODO: commented because CW-144
  const messageText = message; // TODO: delete because CW-144

  return (
    <div className={classnames('filter-summary', className)}>
      <div className="filter-summary__results-text">
        {/* {resultsText} */}{messageText ||
          (
            <> {withinText}
              <a
                className="filter-summary__drawer-toggle filter-drawer-toggle"
                id="filter-summary-anchor"
                onClick={toggleFiltersDrawer}
                role="button"
              >
                {getLabelText(filters)}
              </a>
            </>
          )}
      </div>
    </div>
  );
};

FilterSummary.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.shape({
    serviceTypes: PropTypes.array.isRequired,
  }).isRequired,
  // resultsCount: PropTypes.number.isRequired,
  selectedServiceType: PropTypes.shape({
    name: PropTypes.string,
  }),
  serviceOptions: PropTypes.array.isRequired,
  toggleFiltersDrawer: PropTypes.func.isRequired,
  message: PropTypes.string,
};

FilterSummary.defaultProps = {
  className: '',
  selectedServiceType: {},
};

export default FilterSummary;
