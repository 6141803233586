import React from 'react';
import cx from 'classnames';
import { PropTypes } from 'prop-types';

const TextButton = ({
  ariaCurrent,
  ariaLabel,
  children,
  className,
  dataTestId,
  disabled,
  onClick,
  role,
}) => (
  <button
    aria-current={ariaCurrent}
    aria-label={ariaLabel || children}
    className={cx(
      className,
      'text-action-blue',
      'hover:border-dark-border-blue hover:text-text-blue',
      'focus:border-dark-border-blue focus:outline-none focus:underline',
      disabled && 'pointer-events-none opacity-50',
    )}
    data-testid={dataTestId}
    disabled={disabled}
    onClick={onClick}
    role={role}
    type="button"
  >
    {children}
  </button>
);

TextButton.propTypes = {
  ariaCurrent: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  role: PropTypes.string,
};

TextButton.defaultProps = {
  ariaCurrent: '',
  ariaLabel: '',
  className: '',
  dataTestId: '',
  disabled: false,
  role: undefined,
};

export default TextButton;
