import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { BackButton } from 'common/buttons';
import { isEmpty } from 'lodash';
import '../../stylesheets/consentAppFrame.scss';

const HEIGHT_DIFFERENTIAL = 130;
const DEFAULT_HEIGHT = 467;

// Set height to avoid double scrollbar (Tom's idea).
function iframeHeight() {
  const dialog = document.getElementById('consent-dialog');

  if (isEmpty(dialog)) {
    return 1200;
  }

  const paper = dialog.getElementsByClassName('dialog-paper');
  const calculatedHeight = paper[0].clientHeight - HEIGHT_DIFFERENTIAL;

  // Set a default height of 467.  On the initial load of the iframe
  // the height is not set properly causing issues on the dialog Component
  // dialog component was very small thus adjusting height only if the calculated height is larger than the default
  return calculatedHeight < DEFAULT_HEIGHT ? DEFAULT_HEIGHT : calculatedHeight;
}
// this is a pure component due to iframeHeight being called multiple times
// https://uniteus.atlassian.net/browse/UU3-9722
class ConsentAppFrame extends PureComponent {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  render() {
    const {
      onBackClick,
      onSetRef,
      onLoad,
      consentAppUrl,
    } = this.props;

    return (
      <div className="consent-app-frame">
        <div className="consent-app-frame__back-button">
          <BackButton navigateTo={onBackClick} />
        </div>
        <div className="consent-app-frame__iframe-wrapper" style={{ height: iframeHeight() + 20 }}>
          <iframe
            title="consent-app"
            ref={(element) => onSetRef(element)}
            frameBorder="0"
            height={iframeHeight()}
            id="consent-app-frame-iframe"
            scrolling="yes"
            src={consentAppUrl}
            width="100%"
            onLoad={onLoad}
          />
        </div>
      </div>
    );
  }
}

ConsentAppFrame.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  onSetRef: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  consentAppUrl: PropTypes.string.isRequired,
};

export default ConsentAppFrame;
