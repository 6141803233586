import {
  SET_PROFILE_FORMS_FETCHING,
  UNSET_PROFILE_FORMS_FETCHING,
  FETCH_PROFILE_FORMS,
} from 'actions';

export const defaultState = {
  profileForms: [],
  isFetching: false,
};

export default function profileFormsReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case SET_PROFILE_FORMS_FETCHING: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case UNSET_PROFILE_FORMS_FETCHING: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case FETCH_PROFILE_FORMS: {
      return {
        ...state,
        profileForms: action.profileForms,
        isFetching: false,
      };
    }

    default:
      return state;
  }
}
