import { noop } from 'lodash';
import {
  formatCaseData,
  formatClientData,
  formatReferralData,
} from 'src/components/Search/utils';
import { MODELS } from 'src/components/Search/constants';

const { contact, referral, servicecase } = MODELS;

const defaultHeaders = [
  { label: 'Name - Service Type', value: 'name_service_type' },
  { label: 'Created', value: 'created_at' },
  { label: 'Status', value: 'status' },
];

function getSearchBarColumnHeaders(model, clientIdStandardizationEnabled = false) {
  switch (model) {
    case contact: {
      const headers = [
        { label: 'Name', value: 'full_name' },
        { label: 'Phone', value: 'phone' },
        { label: 'DOB', value: 'dob' },
        { label: 'Location', value: 'location' },
      ];
      if (clientIdStandardizationEnabled) headers.push({ label: 'External ID', value: 'external_id' });
      return {
        getRowValues: formatClientData,
        headers,
      };
    }

    case referral: {
      return {
        getRowValues: formatReferralData,
        headers: defaultHeaders,
      };
    }

    case servicecase: {
      return {
        getRowValues: formatCaseData,
        headers: defaultHeaders,
      };
    }

    default:
      return {
        getRowValues: noop,
        headers: [],
      };
  }
}

export default getSearchBarColumnHeaders;
