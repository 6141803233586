import moment from 'moment';
import { maxBy } from 'lodash';

const findInsuranceByDateRange = (
  insurances,
  startDate,
  endDate,
  ignoreSocialCareExpiredDates,
) => {
  let latestStartingValidInsurance;
  let isExpired = false;
  // tier one: check insurance start and end dates:
  const validInsurancesForDateRange = insurances.filter((insurance) => {
    if (!insurance?.attributes?.enrolled_at) return false;
    const enrolledAt = moment(insurance.attributes.enrolled_at);
    const expiredAt = insurance.attributes.expired_at && moment(insurance.attributes.expired_at);
    return (startDate >= enrolledAt && (!expiredAt || endDate <= expiredAt));
  });
  latestStartingValidInsurance = maxBy(validInsurancesForDateRange, (ins) => ins?.attributes?.enrolled_at);

  // tier two: check only insurance start dates:
  if (ignoreSocialCareExpiredDates && !latestStartingValidInsurance) {
    const validInsurancesIncludingExpired = insurances.filter((insurance) => {
      if (!insurance?.attributes?.enrolled_at) return false;
      const enrolledAt = moment(insurance.attributes.enrolled_at);
      return (startDate >= enrolledAt);
    });
    latestStartingValidInsurance = maxBy(validInsurancesIncludingExpired, (ins) => ins?.attributes?.enrolled_at);
    isExpired = !!latestStartingValidInsurance;
  }
  return { validInsurance: latestStartingValidInsurance || null, isExpired };
};

const findValidInsurance = (
    socialInsurances,
    startDate,
    endDate,
    ignoreSocialCareExpiredDates,
    serviceAuthorization = null,
  ) => {
  let candidateInsurances = socialInsurances;

  // if there is a service authorization, its insurance is the only one we can use
  if (serviceAuthorization) {
    candidateInsurances = [socialInsurances.find((ins) => ins.id === serviceAuthorization.insurance.id)];
  }

  if (!startDate) {
    return { validInsurance: null, isExpired: false };
  }
  const serviceStart = moment.unix(startDate);
  const serviceEnd = endDate ? moment.unix(endDate) : serviceStart;

  return findInsuranceByDateRange(
    candidateInsurances,
    serviceStart,
    serviceEnd,
    ignoreSocialCareExpiredDates,
  );
};

export default findValidInsurance;
