import { isHttpSuccess } from 'src/api/utils/httpStatus';
import Notifier from 'common/helpers/Notifier';
import { coreApi } from 'src/api/config';
import {
  SET_FETCHING_INVOICED_SPENDS,
  UNSET_FETCHING_INVOICED_SPENDS,
  FETCH_INVOICED_SPENDS,
} from 'actions';
import {
  PENDING_AND_APPROVED_STATUSES,
} from 'pages/invoices/constants';

const getRemainingAuthorizedAmountPerInvoice = (invoices) => (
  async (dispatch) => {
    try {
      dispatch({ type: SET_FETCHING_INVOICED_SPENDS });
      const elementsToFetch = invoices.map((invoice) => ({
        fee_schedule_program: invoice.fee_schedule_program_id,
        person: invoice.client_id,
        service_authorization: invoice.service_authorization?.id,
        state: PENDING_AND_APPROVED_STATUSES.join(','),
      }));

      const calls = elementsToFetch.map((obj) => coreApi.query('invoiced_spend', obj, {
          page: {
            number: 1,
            size: 1,
          },
        }));

      let responses = await Promise.all(calls);
      responses = responses.reduce((acc, response, i) => {
        if (!response || !isHttpSuccess(response.status)) {
          throw (response);
        }

        acc[invoices[i].service_authorization?.id] =
          response.data.meta.remaining_authorized_amount;
        return acc;
      }, {});
      dispatch({
        type: FETCH_INVOICED_SPENDS,
        payload: responses,
      });
      dispatch({ type: UNSET_FETCHING_INVOICED_SPENDS });

      return responses;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  }
);

export default getRemainingAuthorizedAmountPerInvoice;
