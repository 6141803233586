import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '@unite-us/ui';

// components
import NetworkSearchBar from 'src/components/Network/Directory/components/NetworkSearchBar';
import TableRowLoading from 'common/display/Table/TableRowLoading';
import UserRow from 'src/components/Network/Directory/components/UserRow';

// actions
import fetchNetworkUsers from 'src/actions/Networks/fetchNetworkUsers';

// utilities
import callOrLog from 'src/common/utils/callOrLog';
import { MY_NETWORKS } from 'common/utils/EventTracker/utils/eventConstants';

import './NetworkUsers.scss';

const TIMEOUT_DELAY = 500;

// @todo Replace this with a debounce function
function setMyTimeout(timer, func, value, delay) {
  if (timer) {
    clearTimeout(timer);
  }

  return setTimeout(() => func(value), delay);
}

class NetworkUsers extends Component {
  static renderUsers(userRows, showLoading) {
    if (showLoading) {
      return <TableRowLoading />;
    }

    if (!showLoading && _.isEmpty(userRows)) {
      return null;
    }
    return userRows;
  }

  constructor(props) {
    super(props);

    this.clearTextFilter = this.clearTextFilter.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
    this.handleFetchUsers = this.handleFetchUsers.bind(this);
    this.handleFilterTextUpdate = this.handleFilterTextUpdate.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);

    const value = props.urlSearchTerm ?
      props.urlSearchTerm :
      _.get(props, 'searchParams.text', '');

    const networkFilter = props.params.networkId ? [props.params.networkId] : props.networks.split(',');

    this.state = {
      delayedFunc: undefined,
      filterText: {
        value,
        name: 'search',
        onChange: this.handleFilterTextUpdate,
      },
      networkFilter,
    };
  }

  componentDidMount() {
    const {
      networks,
      params: { networkId },
    } = this.props;

    this.props.fetchNetworkUsers({
      page: 1,
      network: networkId || networks,
    });
  }

  handleFetchUsers(page) {
    const { networks } = this.props;
    const permittedNetworkIds =
      this.state.networkFilter.filter(Boolean).length ? this.state.networkFilter : networks.split(',');

    const options = {
      permittedNetworkIds,
      text: _.get(this.state, 'filterText.value', ''),
    };

    this.fetchUsers(options, page);
  }

  handleFilterTextUpdate(event) {
    const text = _.get(event, 'target.value', '');
    const { delayedFunc, filterText } = this.state;
    const timeout = _.isEmpty(text) ? 0 : TIMEOUT_DELAY;

    const options = {
      text,
      permittedNetworkIds: this.state.networkFilter,
    };

    this.setState({
      filterText: { ...filterText, value: text },
      delayedFunc: setMyTimeout(delayedFunc, this.fetchUsers, options, timeout),
    });
  }

  onFilterChange(networks) {
    this.setState({
      networkFilter: networks,
    }, () => {
      this.fetchUsers({
        permittedNetworkIds: this.state.networkFilter,
        text: _.get(this.state, 'filterText.value', ''),
      });
    });
  }

  fetchUsers(options = {}, page = 1) {
    callOrLog(() => this.context.eventTracker(MY_NETWORKS.directorySearched, {
      searched_term: options.text,
    }));

    this.props.fetchNetworkUsers({
      page,
      network: options.permittedNetworkIds.join(','),
      query: options.text,
    });
  }

  clearTextFilter() {
    const filterText = { ...this.state.filterText, value: '' };
    this.setState({ filterText }, () => {
      this.handleFilterTextUpdate();
    });
  }

  render() {
    const {
      networks,
      params: {
        networkId,
      },
      paging,
      referralScopes,
      status,
      users,
    } = this.props;

    const userRows = users.map((user, idx) => {
      const userGroupNames = [user.groups[0].name];
      return (
        <UserRow
          // Data isn't intended to be dynamically reordered so using idx should be okay
          // eslint-disable-next-line react/no-array-index-key
          key={`${user.employeeId}-${idx}`}
          user={user}
          userGroupNames={userGroupNames}
        />
      );
    });

    const showLoading = (status === 'pending' && users.length === 0);
    const isFetching = (status === 'pending');

    return (
      <div className="network-directory">
        <NetworkSearchBar
          fetchUsers={this.handleFetchUsers}
          filterText={this.state.filterText}
          isFetching={isFetching}
          networkId={networkId || networks}
          onFilterChange={this.onFilterChange}
          paging={paging}
          referralScopes={referralScopes}
        />
        <div className="network-directory__table">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderColumn className="network-directory__table-name">
                  Name
                </TableHeaderColumn>
                <TableHeaderColumn className="network-directory__table-organizations">
                  Organization
                </TableHeaderColumn>
                <TableHeaderColumn className="network-directory__table-phone">Phone</TableHeaderColumn>
                <TableHeaderColumn className="network-directory__table-email">Email</TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {NetworkUsers.renderUsers(userRows, showLoading)}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

NetworkUsers.defaultProps = {
  urlSearchTerm: undefined,
};

NetworkUsers.propTypes = {
  fetchNetworkUsers: PropTypes.func.isRequired,
  params: PropTypes.shape({
    networkId: PropTypes.string.isRequired,
  }).isRequired,
  paging: PropTypes.object.isRequired,
  referralScopes: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  urlSearchTerm: PropTypes.string,
  users: PropTypes.array.isRequired,
  networks: PropTypes.string.isRequired,
};

NetworkUsers.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const {
    paging,
    status,
  } = state.networkUsers;

  const { networks: { users } } = state;
  const networks = state.globalState.activeNetworks.map((n) => n.id).join(',');
  const initialSearchParams = _.get(state, 'networks.userSearchParams');
  const groupId = _.get(state, 'session.groupId');
  const searchParam = _.get(ownProps, 'location.search', '');
  const urlSearchTerm = _.last(searchParam.split('='));
  const searchParams = {
    ...initialSearchParams,
    text: urlSearchTerm,
    groupId,
    permittedNetworkIds: [ownProps.params.id],
  };

  return {
    currentUser: state.user,
    groupId,
    networks,
    paging,
    searchParams,
    status,
    urlSearchTerm,
    users,
  };
}

export default connect(mapStateToProps, {
  fetchNetworkUsers,
})(NetworkUsers);
