import {
  differenceBy,
  filter,
  indexOf,
  intersectionBy,
  map,
} from 'lodash';
import anyFieldChanged from './anyFieldChanged';

export default function getItemsToDeleteUpateOrAdd({
  current = [],
  values = [],
  fields = [],
} = {}) {
  const toDelete = differenceBy(current, values, 'id');
  const changedIds = map(filter(fields, anyFieldChanged), 'id.value');
  const changed = filter(values, (item) => indexOf(changedIds, item.id) >= 0);
  const toUpdate = intersectionBy(changed, current, 'id');
  const toAdd = differenceBy(changed, current, 'id');

  return {
    toDelete,
    toUpdate,
    toAdd,
  };
}
