import PropTypes from 'prop-types';
import React from 'react';
import DetailLabelContent from 'common/display/SingleItemDetails/DetailLabelContent';
import _ from 'lodash';

const GRID_CONSTANT = 12;

const DetailLabelWrapper = ({ data }) => {
  const gridValue = GRID_CONSTANT / data.length;

  const wrapper = _.times(data.length, (value) => (
    <div
      key={`wrapper-grid-${value}`}
      className={`col-lg-${gridValue} ${_.isEqual(value, 0) ? 'list-item' : 'list-divider'}`}
    >
      <DetailLabelContent content={data[value]} />
    </div>
  ));

  return (
    <div className="detail-label-wrapper row">
      {wrapper}
    </div>
  );
};

DetailLabelWrapper.propTypes = {
  data: PropTypes.array,
};

DetailLabelWrapper.defaultProps = {
  data: [],
};

export default DetailLabelWrapper;
