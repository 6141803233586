import React from 'react';
import PropTypes from 'prop-types';
import DialogV2 from 'common/modal/DialogV2';

const DeleteProvidedServiceConfirmationDialog = React.forwardRef(({ onConfirmation }, refParam) => (
  <DialogV2
    cancelHandler={() => {
        refParam.current.closeDialog();
      }}
    confirmationHandler={() => {
      onConfirmation();
        refParam.current.closeDialog();
      }}
    cancelLabel="Cancel"
    confirmLabel="Delete"
    ref={refParam}
    title="Delete Contracted Service Note"
    isWarning
    width="2xl"
  >
    <div>
      <p>
        You are about to delete this contracted service note.
        Once the note is deleted, you will not be able to submit
        it for reimbursement of any services provided to the client.
      </p>
      <br />
      <p>
        Are you sure you want to delete the note?
      </p>
    </div>
  </DialogV2>
  ));

DeleteProvidedServiceConfirmationDialog.propTypes = {
  onConfirmation: PropTypes.func.isRequired,
};

export default DeleteProvidedServiceConfirmationDialog;
