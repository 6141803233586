import { noop, isEmpty, get } from 'lodash';
import { browserHistory } from 'common/utils/browserHistory';
import STEP_DEFAULTS from './utils/defaults';
import {
  SEARCH_PATH,
  CONFIRM_PATH,
} from '../constants';
import goToConfirmStep from '../utils/goToConfirmStep';
import encodeContactData from '../utils/encodeContactData';

const intakesStepperConfig = {
  steps: [
    {
      ...STEP_DEFAULTS.first,
      title: 'Search Records',
      onStepClick: () => {
        const location = browserHistory.getCurrentLocation();
        const arId = get(location, 'query.arId');
        const arParam = arId ? `?arId=${arId}` : '';

        browserHistory.push(`/intakes/contacts/${SEARCH_PATH}${arParam}`);
      },
      onSearchComplete: ({
        contacts,
        formData,
      }) => {
        const context = 'intakes/contacts';
        const location = browserHistory.getCurrentLocation();
        const arId = get(location, 'query.arId');
        const arParam = arId ? `&arId=${arId}` : '';

        // if there contacts (matches), we need to go to the confirm step
        if (!isEmpty(contacts)) {
          return goToConfirmStep({ context, contactData: formData, arId });
        }

        // if there are no contacts, we need to go to intake with the formData
        return browserHistory.push(`/intakes/new?contact=${encodeContactData(formData)}${arParam}`);
      },
      onConfirmationComplete: ({
        contactId,
        formData,
      }) => {
        const location = browserHistory.getCurrentLocation();
        const arId = get(location, 'query.arId');
        const arParam = arId ? `&arId=${arId}` : '';
        // we have a confirmed contact, go to intake with the id
        if (!isEmpty(contactId)) {
          return browserHistory.push(`/intakes/new?contactId=${contactId}${arParam}`);
        }

        // we have a new contact, go to intake with serialized contact
        return browserHistory.push(`/intakes/new?contact=${encodeContactData(formData)}${arParam}`);
      },
      onStepComplete: () => {},
      routes: [
        {
          route: `/intakes/contacts/${SEARCH_PATH}`,
        },
        {
          route: `/intakes/contacts/${CONFIRM_PATH}`,
        },
      ],
    },
    {
      ...STEP_DEFAULTS.second,
      title: 'Intake',
      onStepClick: noop,
      onStepComplete: () => {},
      routes: [],
    },
  ],
};

export default intakesStepperConfig;
