import PropTypes from 'prop-types';
import React from 'react';
import { formatNumber } from 'humanize-plus';
import { isNull, isNumber } from 'lodash';

function getWholeNumber(value = '') {
  const stringValue = isNumber(value) ? value.toString() : value;
  return parseInt(stringValue.split('.')[0], 10);
}

export function dollarAmount(value = '', convertCents) {
  if (isNull(value)) {
    return '';
  }
  if (value === 'Required') {
    return 'Required';
  }
  if (convertCents) {
    const floatNum = (parseFloat(value) / 100).toFixed(2);
    return Number.isNaN(floatNum) ? '' : `$${formatNumber(floatNum, 2)}`;
  }
  const number = getWholeNumber(value);
  return Number.isNaN(number) ? '' : `$${formatNumber(number)}`;
}

const DollarAmount = ({
  value, convertCents, className, dataLabel,
}) => (
  <span
    className={className}
    data-test-element={dataLabel}
  >
    {dollarAmount(value, convertCents)}
  </span>
);

DollarAmount.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  convertCents: PropTypes.bool,
  className: PropTypes.string,
  dataLabel: PropTypes.string,
};

DollarAmount.defaultProps = {
  value: '',
  convertCents: false,
  className: 'dollar-amount',
  dataLabel: '',
};

export default DollarAmount;
