import { Button } from '@unite-us/ui';
import PropTypes from 'prop-types';
import React from 'react';
import CareTeamFooterConfirm from './CareTeamFooterConfirm';

const CareTeamFooter = (props) => {
  const { formCancelPressed } = props;

  return (
    <div className="care-team-footer">
      <CareTeamFooterConfirm
        formCancelPressed={props.formCancelPressed}
        handleFinalClose={props.handleFinalClose}
      />
      {
        !formCancelPressed && (
          <div>
            <Button
              disabled={props.submitting}
              id="care-team-cancel-btn"
              label="cancel"
              secondary
              onClick={props.handleInitialClose}
            />
            <Button
              disabled={props.submitting}
              id="care-team-save-btn"
              label={props.editMode ? 'Submit' : 'Save'}
              primary
              style={{ marginLeft: '10px' }}
              type="submit"
            />
          </div>
        )
      }
    </div>
  );
};

CareTeamFooter.propTypes = {
  editMode: PropTypes.bool,
  formCancelPressed: PropTypes.bool,
  handleFinalClose: PropTypes.func,
  handleInitialClose: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
};

export default CareTeamFooter;
