import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  BaseCard,
  BaseCardBody,
} from '@unite-us/ui';
import CaseHistoryTable from 'src/components/CaseHistory/components/CaseHistoryTable';
import useCase from 'src/components/Cases/hooks/useCase';
import './ReferralDetailCaseHistory.scss';

const ReferralDetailCaseHistory = ({ caseId, groupId }) => {
  const caseObj = useCase(caseId);

  return (
    <div className="case-history-title mb-16">
      <h2>Case</h2>
      <div className="case-history-table">
        <BaseCard className="case-history">
          <BaseCardBody>
            <CaseHistoryTable
              caseObj={caseObj}
              groupId={groupId}
            />
          </BaseCardBody>
        </BaseCard>
      </div>
    </div>
  );
};

ReferralDetailCaseHistory.propTypes = {
  caseId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    caseId: _.wget(ownProps, 'caseId'),
  };
}

export default connect(mapStateToProps)(ReferralDetailCaseHistory);
