import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import AsideColumn from 'src/components/Dashboard/components/AsideColumn';
import DraftCaseOptions from 'src/components/Cases/components/Detail/DraftCaseOptions';
import ServiceCaseFeeScheduleServicesProvided from
  'src/components/Cases/components/Detail/ServiceCaseFeeScheduleServicesProvided';
import { isFeeScheduleProgram } from 'src/components/Cases/utils';
import { hasPaymentsUserAccess, restrictToSubmissionContext } from 'src/common/utils/FeatureFlags/flags';
import { fetchServiceCase } from 'actions/Case/Contact/Group';
import { attachCaseDocuments } from 'actions/Document/Contact/Group';
import { renameCaseDocument, removeCaseDocument } from 'actions/Document/Case/Contact/Group';
import fetchProviderIsSensitive from 'actions/Group/fetchProviderIsSensitive';
import { fetchGroupsPrograms } from 'actions/Program/Group';
import FormSubmissions from 'src/components/FormSubmissions/FormSubmissions';
import Notes from 'src/components/Notes/Notes';
import ServiceCaseInteractions from 'src/components/Cases/components/Detail/ServiceCaseInteractions';
import ServiceCaseReferralHistory from 'src/components/Cases/components/Detail/ServiceCaseReferralHistory';
import callOrLog from 'common/utils/callOrLog';
import { SERVICE_CASE } from 'common/utils/EventTracker/utils/eventConstants';
import { Spinner } from 'common/spinners';
import { TrackerContext } from '@unite-us/client-utils';
import classNames from 'classnames';
import { getEnumsFromState } from 'common/utils/Enums';
import _ from 'lodash';
import { useFeatureFlag } from 'common/hooks';
import FeatureFlagContainer from 'src/common/utils/FeatureFlags/FeatureFlagContainer';

export const DraftDetailsView = (props) => {
  const {
    location = {},
    caseAssessments,
    caseId,
    contactId,
    currentProvider,
    enums,
    groupId,
    isCoordinationGroup,
    refetch,
    isFetching,
    isFSP,
    serviceCase,
    showContactColumn,
    type,
    usePaymentsUserRole,
    currentProviderId,
  } = props;

  const [isSensitive, setIsSensitive] = useState(false);
  const [showActions, setShowActions] = useState(true);
  const [isSendable, setIsSendable] = useState(true);
  const [showInteractions, setShowInteractions] = useState(false);
  const eventTracker = useContext(TrackerContext);
  const hasNotesSection = useFeatureFlag('crtb-593-show-notes-on-draft-referrals');

  const getCaseIsSensitive = async (kase = {}) => {
    // Check that the group associated with the case is sensitive.
    const caseGroupId = _.get(kase, 'provider.id', '');
    const caseGroupIsSensitive = caseGroupId ?
      await fetchProviderIsSensitive(caseGroupId) :
      false;

    // Find the referral that was sent to the group associated with the case
    // and check if its referred_by_group.id is sensitive.
    const caseReferral = _.find(_.get(kase, 'referrals', []), ((referral = {}) => (
      _.get(referral, 'referred_to_group.id', '') === caseGroupId
    )));
    const referredByGroupId = _.get(caseReferral, 'referred_by_group.id', '');
    const referralGroupIsSensitive = referredByGroupId ?
      await fetchProviderIsSensitive(referredByGroupId) :
      false;

    const sensitive = caseGroupIsSensitive || referralGroupIsSensitive;
    setIsSensitive(sensitive);
  };

  const confirmSendableStatus = async (kase) => {
    const providerDrafts = kase.referrals;

    if (!_.isEmpty(providerDrafts)) {
      const allPrograms = _.map(providerDrafts, 'receiving_program');
      const providerNoPrograms = allPrograms.filter((prog) => prog === undefined);
      // If all draft referrals don't have a receiving_program -- no state change needed
      if (providerNoPrograms.length !== allPrograms.length) {
        try {
          const programs = _.map(providerDrafts, 'receiving_program').filter((prog) => prog !== undefined);
          const programStatus = _.map(programs, 'is_active');
          const receivingStatus = _.map(programs, 'receiving_referrals');
          if (_.includes(programStatus, false) || _.includes(receivingStatus, false)) {
            setIsSendable(false);
          }
        } catch (e) {
          setIsSendable(false);
        }
      }
    }
  };

  const falsifyActionBool = () => {
    setShowActions(false);
  };

  const fetchProvidersPrograms = (kase) => {
    const providerId = _.get(kase, 'provider.id');
    const programId = _.get(kase, 'program.id');
    props.fetchGroupsPrograms(providerId, {
      ids: programId,
      include: 'fee_schedule_program',
    });
  };

  const attachDocuments = (documents = {}) => {
    const { attachedDocuments, uploadedDocuments } = documents;
    return props.attachCaseDocuments({
      attachedDocuments,
      caseId,
      contactId,
      groupId,
      uploadedDocuments,
    }).then(() => {
      callOrLog(() => eventTracker(
        SERVICE_CASE.documentUploaded,
        null,
        {
          serviceCase,
        },
      ));
    });
  };

  const removeDocument = (doc = {}) => (
    props.removeCaseDocument({
      caseId: serviceCase.id,
      contactId: _.get(serviceCase, 'person.id'),
      groupId,
      documentId: doc.id,
    })
  );

  const renameDocument = (doc = {}) => (
    props.renameCaseDocument({
      caseId: serviceCase.id,
      contactId: _.get(serviceCase, 'person.id'),
      documentId: doc.id,
      groupId,
      title: doc.title,
    })
  );

  useEffect(() => {
    props.fetchServiceCase(groupId, contactId, caseId)
    .then((payload) => {
      // Check if the group associated with the case is sensitive.
      const kase = _.get(payload, 'data.data', {});
      getCaseIsSensitive(kase);

      fetchProvidersPrograms(kase);
      confirmSendableStatus(kase);
    });
  }, []);

  useEffect(() => {
    if (refetch && !isFetching) {
      props.fetchServiceCase(groupId, contactId, caseId);
    }
  }, [refetch, isFetching]);

  const gridClass = () => classNames({
    'col-md-9': showContactColumn,
    'col-md-12': !showContactColumn,
  });

  if (_.isEmpty(serviceCase) || isFetching) {
    return (
      <div className="service-case--loading">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="case-detail-view row">
      <div className={gridClass()}>
        <DraftCaseOptions
          location={location}
          caseAssessments={caseAssessments}
          falsifyActionBool={falsifyActionBool}
          groupId={groupId}
          groupIsSensitive={isSensitive}
          isCoordinationGroup={isCoordinationGroup}
          isSendable={isSendable}
          serviceCase={serviceCase}
          onAttachDocuments={attachDocuments}
          onRemoveDocument={removeDocument}
          onRenameDocument={renameDocument}
          showActions={showActions}
          type="draftReferrals"
        />
        {
          usePaymentsUserRole && isFSP ? (
            <ServiceCaseFeeScheduleServicesProvided
              caseId={caseId}
              className="mt-6"
              contactId={contactId}
              isCoordinationGroup={isCoordinationGroup}
              groupId={groupId}
              serviceCase={serviceCase}
            />
          ) : null
        }
        <div className="mt-12">
          <FormSubmissions
            context={serviceCase.id}
            contextType="case"
            providerId={currentProviderId}
            serviceId={_.get(serviceCase, 'service.id')}
          />
        </div>

        {hasNotesSection && (
          <div className="mt-10">
            <Notes
              providerId={serviceCase.provider.id}
              subject={serviceCase.id}
              subjectType="case"
              onAddNote={() => { setShowInteractions(true); }}
            />
          </div>
        )}

        {
          showInteractions ? (
            <ServiceCaseInteractions
              caseAssessments={caseAssessments}
              enums={enums}
              contact={serviceCase.person}
              serviceCase={serviceCase}
              isCoordinationGroup={isCoordinationGroup}
              groupId={groupId}
              cancelFunction={() => { setShowInteractions(false); }}
              styles={{ position: { marginTop: '50px' } }}
              eventTracker={eventTracker}
            />
          ) : null
        }

        <div className="case-referral-history mt-10">
          <ServiceCaseReferralHistory
            serviceCase={serviceCase}
            currentProvider={currentProvider}
            type={type}
          />
        </div>
      </div>
      {
        showContactColumn && (
          <div className="col-md-3">
            <AsideColumn
              contact={serviceCase.person}
              detailObj={serviceCase}
              type={type}
            />
          </div>
        )
      }
    </div>
  );
};

DraftDetailsView.propTypes = {
  attachCaseDocuments: PropTypes.func.isRequired,
  caseAssessments: PropTypes.array,
  caseId: PropTypes.string,
  person: PropTypes.object.isRequired,
  contactId: PropTypes.string.isRequired,
  currentProvider: PropTypes.object.isRequired,
  enums: PropTypes.object,
  fetchGroupsPrograms: PropTypes.func.isRequired,
  fetchServiceCase: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isCoordinationGroup: PropTypes.bool.isRequired,
  isFSP: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  refetch: PropTypes.bool,
  removeCaseDocument: PropTypes.func.isRequired,
  renameCaseDocument: PropTypes.func.isRequired,
  serviceCase: PropTypes.shape({
    id: PropTypes.string,
    contact: PropTypes.object,
    person: PropTypes.object,
    provider: PropTypes.object,
    service_type: PropTypes.object,
  }).isRequired,
  showContactColumn: PropTypes.bool,
  type: PropTypes.string.isRequired,
  usePaymentsUserRole: PropTypes.bool,
  currentProviderId: PropTypes.string.isRequired,
};

DraftDetailsView.defaultProps = {
  caseAssessments: [],
  caseId: '',
  enums: {},
  refetch: false,
  showContactColumn: true,
  usePaymentsUserRole: false,
};

DraftDetailsView.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const {
    serviceCase,
    session,
    caseAssessments,
    globalState,
  } = state;
  const caseId = _.wget(ownProps, 'params.id');
  const contactId = _.wget(ownProps, 'params.contact_id');
  const currentProvider = _.wget(session, 'currentProvider', {});
  const feeScheduleProvidedServices = _.get(state, `serviceCase.${contactId}[0].feeScheduleProvidedServices`);
  const serviceCaseForContact = _.find(_.get(serviceCase, contactId, []), (sCase) => sCase.id === caseId) || {};
  const restrictContext = restrictToSubmissionContext(state);

  return {
    currentProviderId: globalState.currentProvider.group.id,
    location: ownProps.location,
    caseId,
    caseAssessments,
    contactId,
    currentProvider,
    enums: getEnumsFromState(state),
    feeScheduleProvidedServices,
    groupId: session.groupId,
    isCoordinationGroup: session.isCoordinationGroup,
    isFetching: _.get(serviceCase, 'isFetching', false),
    isFSP: isFeeScheduleProgram(state, serviceCaseForContact),
    refetch: _.get(serviceCase, 'refetch', false),
    restrictContext,
    serviceCase: serviceCaseForContact,
    usePaymentsUserRole: hasPaymentsUserAccess(state),
  };
}

export default _.flow(
  FeatureFlagContainer,
  connect(mapStateToProps, {
    attachCaseDocuments,
    fetchGroupsPrograms,
    fetchServiceCase,
    removeCaseDocument,
    renameCaseDocument,
  }),
)(DraftDetailsView);
