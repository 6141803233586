import React from 'react';
import PropTypes from 'prop-types';
import PlanDetail from './PlanDetail';

const PlanOverview = ({ planId }) => (
  <>
    <PlanDetail planId={planId} />
  </>
);

PlanOverview.propTypes = {
  planId: PropTypes.string.isRequired,
};

export default PlanOverview;
