import axios from 'axios';
import { CORE_API, AUTH_URL, CLIENT_ID, ROUTE_BASEPATH } from 'src/config/env/env.config';
import moment from 'moment';
import Humanize from 'humanize-plus';
import _ from 'lodash';
import { validations } from '@unite-us/app-components';
import hashToParams from 'src/common/utils/Login/utils/hashToParams';
import { COOKIE_SESSION_KEY } from 'src/common/constants';
import { get as getCookie, remove as removeCookie } from 'es-cookie';

const { location } = window;

export const appRootPath = ROUTE_BASEPATH !== '/' ? ROUTE_BASEPATH : '';
export const secureProtocol = location.protocol === 'https:';
export const authRedirectUrl = `${location.protocol}//${location.host}${appRootPath}/callback/`;

export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(
      /[xy]/g,
      (c) => {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line no-mixed-operators
        return v.toString(16);
      },
    );
}

export function redirectUserToAuth() {
  const userState = generateUUID();
  const paramsHash = {
    client_id: CLIENT_ID,
    redirect_uri: authRedirectUrl,
    response_type: 'code',
    scope: 'app:read app:write',
    state: userState,
  };

  window.location = `${AUTH_URL}/oauth2/auth?${hashToParams(paramsHash)}`;
  return;
}

export function redirectUserToLearn() {
  const session_cookie = getCookie(COOKIE_SESSION_KEY);
  const token = JSON.parse(session_cookie);

  const newWindow = window.open('about:blank', '_blank');
  newWindow.location = `${AUTH_URL}/oauth2/learn?access_token=${token.access_token}`;
  return;
}

export function passwordValidations() {
  return [{
    func: validations.isLongerThan,
    args: 8,
  }];
}

export function sort(arr, sortParams) {
  const filtered = _.uuOmitBy(sortParams, (val) => val === '');
  return _.orderBy(arr, _.keys(filtered), _.values(filtered));
}

export function clearEmptyValues(object) {
  if (_.isObject(object)) {
    return _.uuOmitBy(object, (v) => (!v || v.length === 0));
  }

  return object;
}

/**
 *
 * @param string
 * @param replaceThis defaults to '-', is used in new RegExp with the global flag
 * @param withThis  defaults to ' ', is used to replace the passed in value
 * @returns {*} Returns the formatted string
 */
export function replaceAndCapitalizeString(string, replaceThis = '-', withThis = ' ') {
  return Humanize.capitalizeAll(string.replace(new RegExp(replaceThis, 'g'), withThis));
}

/**
 *
 * @param {Object} objects pass in
 * @returns {Array}
 */
export function orderedPrimary(objects, key, sortOrder = 'desc') {
  return _.orderBy(objects, [key], [sortOrder]);
}

export function dateToEpoch(date) {
  return new Date(date).getTime() / 1000;
}

export function epochToDate(epoch, format = 'M/D/YYYY', offset = 0) {
  if (!_.isNil(epoch)) {
    return moment.unix(epoch).utcOffset(offset).format(format);
  }
  return '';
}

export function loadKeyFromSessionStorage(key) {
  if (key && sessionStorage && sessionStorage.getItem(key)) {
    return JSON.parse(sessionStorage.getItem(key));
  }
  return undefined;
}

export function saveKeyToSessionStorage(key, obj) {
  sessionStorage.setItem(key, JSON.stringify(obj));
}

export function saveSessionToSessionStorage(obj) {
  sessionStorage.setItem('uniteusApiToken', JSON.stringify(obj));
}

export function saveCurrentGroupIdToSessionStorage(groupId) {
  sessionStorage.setItem('uniteusApiCurrentGroup', groupId);
}

export function saveCurrentNetworkIdToSessionStorage(networkId) {
  sessionStorage.setItem('uniteusApiCurrentNetwork', networkId);
}

export function saveCurrentUserToSessionStorage(user) { /* eslint-disable-line */
  // const { email, email_addresses, ...sanitizedUser } = user;
  // if (sanitizedUser?.groups?.length) {
  //   sanitizedUser.groups.forEach((g) => {
  //     /* eslint-disable-next-line */
  //     delete g.programs;
  //   });
  // }
  // sessionStorage.setItem(SESSION_USER_KEY, JSON.stringify(sanitizedUser));
}

export function clearSession() {
  removeCookie(COOKIE_SESSION_KEY);
  sessionStorage.clear();
}

export function isLoggedIn() {
  const token = getCookie(COOKIE_SESSION_KEY);

  if (token) {
    return true;
  }

  return false;
}

export function userIsLoaded(user) {
  // @todo Do a better check!
  return !!user.id;
}

export function setAxiosHeaders(token) {
  axios.defaults.baseURL = CORE_API;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common.Accept = 'application/json';
  axios.defaults.headers.Authorization = `Bearer ${token}`;
}

export function replaceEmptyStringToNull(result) {
  return (typeof (result) === 'string' && _.isEmpty(result)) ? null : result;
}

export function sortTableColumn(object, sorting) {
  if (_.isEmpty(sorting)) {
    return object;
  }
  const filtered = _.uuOmitBy(sorting, (val) => _.isString(val) && val.length <= 0);
  return _.orderBy(object, _.keys(filtered), _.values(filtered));
}

export function findPrimaryOrFirst(array) {
  return _.find(array, { is_primary: true }) || array[0] || '';
}

export function isCoordinationGroup(group = {}) {
  const networks = _.get(group, 'networks', []);
  return _.some(networks, (n) => (
    _.get(n, 'is_coordination_center', false)
  ));
}

export function coordinatedNetwork(networks = [], providerId = '') {
  return _.filter(networks, (n) => (
    _.some(n.coordination_centers, (cc) => (
      _.get(cc, 'id') === providerId
    ))));
}

/**
 * Update nested state by one level
 * @param { object} state - state of the object
 * @param { string } data - name of the first level object return in the state examples - groups, contacts, data, etc
 * @param { string } firstId - first level Id
 * @param { string } secondId - second level Id
 * @param { object } payload - returned payload data
 * @param { string } collType - name of nested object desired to update
 * @return { object } - returns state with updated nested object.
 */
export function updateNestedStateByOneLevel(state, data, firstId, secondId, payload, collType) {
  const updatedCollection = state[data].reduce((acc, current) => {
    if (firstId === current.id) {
      let updatedColl = current[collType].reduce((items, curr) => {
        if (curr.id === secondId) {
          return [
            ...items,
            payload,
          ];
        }
        return [
          ...items,
          curr,
        ];
      }, []);

      if (!secondId) {
        updatedColl = [
          ...payload,
        ];
      }

      return [
        ...acc,
        _.assign({}, current, {
          [collType]: updatedColl,
        }),
      ];
    }
    return [
      ...acc,
      current,
    ];
  }, []);
  return _.assign({}, state, { [data]: updatedCollection });
}

/**
 * Creates an object within a nested state
 * @param { object} state - state of the object
 * @param { string } data - name of the first level object return in the state examples - groups, contacts, data, etc
 * @param { string } firstId - first level Id
 * @param { object } payload - returned payload data
 * @param { string } collType - name of nested object desired to update
 * @return { object } - returns state with updated nested object. (create)
 */
export function createNestedStateByOneLevel(state, data, firstId, payload, collType) {
  const updatedCollection = state[data].reduce((acc, current) => {
    if (firstId === current.id) {
      return [
        ...acc,
        _.assign({}, current, {
          [collType]: [
            ...current[collType],
            ...(Array.isArray(payload) ? payload : [payload]),
          ],
        }),
      ];
    }
    return [
      ...acc,
      current,
    ];
  }, []);
  return _.assign({}, state, { [data]: updatedCollection });
}

/**
 * Update nested state by one level
 * @param { object} state - state of the object
 * @param { string } data - name of the first level object return in the state examples - groups, contacts, data, etc
 * @param { string } firstId - first level Id
 * @param { string } secondId - second level Id
 * @param { string } collType - name of nested object desired to update
 * @return { object } - returns state with updated nested object. (delete)
 */
export function deleteNestedStateByOneLevel(state, data, firstId, secondId, collType) {
  const updatedCollection = state[data].reduce((acc, current) => {
    if (firstId === current.id) {
      const deletedColl = current[collType].reduce((items, curr) => {
        if (curr.id === secondId) {
          return items;
        }
        return [
          ...items,
          curr,
        ];
      }, []);

      return [
        ...acc,
        _.assign({}, current, {
          [collType]: deletedColl,
        }),
      ];
    }
    return [
      ...acc,
      current,
    ];
  }, []);
  return _.assign({}, state, { [data]: updatedCollection });
}
