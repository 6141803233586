export const CLIENT_MUST_PROVIDE_CONSENT_TEXT = [
  'Your client must provide their consent to receive messages via Unite Us.',
  'Request consent from them via the button next to their name above.',
].join(' ');

export const MESSAGE_CHAR_LIMIT = 670;

export const MESSAGE_FIELD_HINT = [
  'Messages will be visible to your organization in the client\'s timeline.',
  'Please avoid including sensitive information (including PHI) in your message.',
].join(' ');

export const MESSAGE_FIELD_INFO_POPOVER_TEXT = [
  'Send your client a message of up to 670 characters if they have agreed to be',
  'messaged on their mobile number and/or email address. To update a client\'s',
  'communication preferences, please visit their Profile.',
].join(' ');

export const NO_MESSAGE_ENABLED_COMMUNICATION_TEXT = [
  'This client does not have messaging enabled. ',
  'Please update messaging preferences by editing the client\'s phone number and/or email address in the Profile tab.',
].join(' ');
