import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { getFilterOptions } from 'src/components/Browse/utils/filters';
import { Filter } from '@unite-us/client-utils';

const SpecializedSupport = (props) => {
  const {
    accessibility,
    label,
    languages,
    populations,
  } = props;

  return (
    <div className="specialized-support-section">
      <h5>{label}</h5>
      <Filter
        filterKey="catersTo"
        name="Populations Specialized in Serving"
        onFiltersChange={props.onFiltersChange}
        options={populations}
        pluralName="Populations Specialized in Serving"
      />

      <Filter
        filterKey="accessibility"
        name="Accessibility"
        onFiltersChange={props.onFiltersChange}
        options={accessibility}
        pluralName="Accessibility"
      />

      <Filter
        filterKey="languages"
        name="Language"
        onFiltersChange={props.onFiltersChange}
        options={languages}
      />
    </div>
  );
};

SpecializedSupport.propTypes = {
  accessibility: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  languages: PropTypes.array.isRequired,
  onFiltersChange: PropTypes.func,
  populations: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
  const programs = get(state, 'session.enums.groups.programs', []);
  const accessibility = getFilterOptions(programs.accessibility_options, get(ownProps, 'filters.accessibility', []));
  const languages = getFilterOptions(
    get(state, 'session.enums.base.languages'),
    get(ownProps, 'filters.languages', []),
  );
  const populations = getFilterOptions(programs.population, get(ownProps, 'filters.catersTo', []));

  return {
    accessibility,
    languages,
    populations,
  };
}

export default connect(
  mapStateToProps,
)(SpecializedSupport);
