import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';
import moment from 'moment';
import { get, isObject } from 'lodash';
import ScrollToElement from 'src/common/utils/Form/ScrollToElement';
import { theme } from 'src/../tailwind.config';
import { Spinner } from 'common/spinners';
import { useFindProviderByFeeScheduleProgram, useFindFeeScheduleProgram } from 'src/components/Backoffice/api/hooks/v1/feeScheduleProgramHooks';
import { useFindLicensedProvidersByName } from 'src/components/Organization/api/hooks/v1/providerHooks';
import { useCreateProgram } from 'src/components/Organization/api/hooks/v1/programHooks';
import Notifier from 'common/helpers/Notifier';
import { useInvalidateQueries } from 'src/api/APIHooks';
import AddRelationshipDialog from 'src/components/Backoffice/AddRelationshipDialog';
import { DEFAULT_PAGE_SIZE } from 'src/components/Backoffice/backofficeUtils';
import DynamicDataTable from 'src/components/Backoffice/dataTables/DynamicDataTable';
import './FeeScheduleProgramBaseCard.scss';

const FeeScheduleProgramCboTable = ({ feeScheduleProgramId }) => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchPageSize, setSearchPageSize] = useState(10);
  const [searchPageNumber, setSearchPageNumber] = useState(1);
  const [cboName, setCboName] = useState('');
  const [searchedCbosName, setSearchedCbosName] = useState(cboName);
  const AddCboModalRef = useRef(null);
  const { data: providersResponse, isFetching } = useFindProviderByFeeScheduleProgram(
    feeScheduleProgramId,
    {
      page: {
        size: pageSize,
        number: pageNumber,
      },
    },
  );
  const { data: providers_searched, isFetchingSearch } = useFindLicensedProvidersByName(
    searchedCbosName,
    {
      page: {
        size: searchPageSize,
        number: searchPageNumber,
      },
    },
  );

  const { data: feeScheduleProgramProviders, paging: feeScheduleProgramPaging } = providersResponse.data;
  const [selectedProviderID, setSelectedProviderID] = useState(null);
  const { data: providers, paging: providerPaging } = providers_searched.data;
  const {
    data: feeScheduleProgram,
    isFetching: isFetchingFeeScheduleProgram,
    } = useFindFeeScheduleProgram(feeScheduleProgramId);

  const searchCBOs = () => {
    setSelectedProviderID(null);
    setSearchedCbosName(cboName || '');
  };

  const closeModal = () => {
    setSelectedProviderID(null);
    setCboName('');
    setSearchedCbosName('');
    AddCboModalRef.current.closeDialog();
  };

  const invalidateQueries = useInvalidateQueries();
  const { createRecord: createProgram } = useCreateProgram(
    {
      onSuccess: () => {
        closeModal();
        invalidateQueries('program');
        invalidateQueries('provider');
      },
      onError: (error) => {
        const errorList = get(error, 'response.data.errors', []);
        const stringNotification = errorList.map((e) => e.title).join(', ');
        Notifier.dispatch(400, `Failed to create program: ${stringNotification}`);
      },
    },
  );

  if (isFetching || isFetchingFeeScheduleProgram) return <Spinner />;

  const ScrollToElementEvent = () => {
    ScrollToElement(document.getElementById('TopOfForms'));
  };

  const openModal = () => {
    AddCboModalRef.current.openDialog();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      searchCBOs();
    }
  };

  const checkboxDefault = (providerId) => (
    feeScheduleProgramProviders.find((provider) => (
      provider.id === providerId
    ))
  );

  const addCbo = async () => {
    if (selectedProviderID) {
      await createProgram({
        name: feeScheduleProgram.name || 'Please insert a new program name',
        description: feeScheduleProgram.description || 'Please insert a new program description',
        provider: { id: selectedProviderID },
        fee_schedule_program: { id: feeScheduleProgramId },
        eligibility_text: feeScheduleProgram.eligibility_text || 'Please insert a new eligibility text',
      });
      setSelectedProviderID(null);
    }
  };

  const selectCbo = (rowNumber, providerID) => {
    const fspCboIds = feeScheduleProgramProviders.map((provider) => provider.id);
    if (!fspCboIds.includes(providerID)) {
      setSelectedProviderID(providerID);
    }
  };

  return (
    <div className="fee-schedule-base-card">
      <DynamicDataTable
        title="Community Based Organization"
        withPadding
        table={{
          headers: ['Name'],
          data: feeScheduleProgramProviders.filter(isObject)
            .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1))
            .map((provider) => ({
              rowId: provider.id,
              data: {
                name: provider.name,
              },
            })),
          emptyTableMessage: 'Click "Add CBO" to add CBOs',
          withBorder: true,
        }}
        button={{
          label: 'ADD CBO',
          icon: <Icon color={theme.extend.colors['current-blue']} icon="IconPlusCircle" />,
          onClick: openModal,
          disable: moment.utc().isAfter(moment(feeScheduleProgram.ends_at).utc()),
        }}
        withPagination
        pagination={{
          pageNumber,
          pageSize,
          totalItemCount: feeScheduleProgramPaging.total_count,
          totalPageCount: feeScheduleProgramPaging.total_pages,
          setPageSize,
          setPageNumber,
          scrollPaginationElement: ScrollToElementEvent,
        }}
      />
      <AddRelationshipDialog
        cancelHandler={closeModal}
        confirmationHandler={addCbo}
        confirmLabel="Confirm"
        dialogId="add-cbo-modal"
        setRef={AddCboModalRef}
        dialogTitle="Add New Community Based Organization"
        confirmationBtnDisabled={!selectedProviderID}
        isFetching={isFetchingSearch}
        onKeyPress={handleKeyPress}
        onClick={searchCBOs}
        onChange={(value) => {
          setCboName(value);
        }}
        paging={providerPaging}
        tableElements={providers}
        selectedID={selectedProviderID}
        pageNumber={searchPageNumber}
        setPageNumber={setSearchPageNumber}
        pageSize={searchPageSize}
        setPageSize={setSearchPageSize}
        scrollPaginationElement={ScrollToElementEvent}
        selectElement={selectCbo}
        checkboxDefault={checkboxDefault}
        tableHeaderColumns={['Name', 'Organization ID', 'Included']}
        baseCardHeaderTitle="CBO Search Result"
        labelText="Organization name"
        searchTableId="feeScheduleProgramSearchTable"
        inputId="cbo__form-input"
      />
    </div>
  );
};

FeeScheduleProgramCboTable.propTypes = {
  feeScheduleProgramId: PropTypes.string.isRequired,
};

export default FeeScheduleProgramCboTable;
