import React from 'react';
import PropTypes from 'prop-types';
import { useIsNetworkLead } from 'common/contexts/CurrentProviderContext';
import { NoInvoicesFoundCard } from '../components';
import { SUBMITTED_NETWORK_LEAD_STATUSES, SUBMITTED_CBO_ADMIN_STATUSES } from '../constants';
import InvoiceWorkqueue from './InvoiceWorkqueue';

const SubmittedInvoices = ({ children }) => {
  const isNetworkLead = useIsNetworkLead();
  const invoiceStatus = isNetworkLead ? SUBMITTED_NETWORK_LEAD_STATUSES : SUBMITTED_CBO_ADMIN_STATUSES;
  const bulkActions = ['IconDownloadV2', 'IconArchive'];

  return (
    <InvoiceWorkqueue
      invoiceStatus={invoiceStatus}
      bulkActions={bulkActions}
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <NoInvoicesFoundCard isFiltered={isFiltered} status="submitted" resetFilters={resetFilters} />
      )}
      path="open/submitted/"
    >
      {children}
    </InvoiceWorkqueue>
  );
};

SubmittedInvoices.propTypes = {
  children: PropTypes.node,
};

SubmittedInvoices.defaultProps = {
  children: undefined,
};
export default SubmittedInvoices;
