import React from 'react';
import PropTypes from 'prop-types';
import { feeSchedulePrograms as fspUtils } from '@unite-us/client-utils';
import { BarLoader } from '@unite-us/ui';
import formatSpend from 'common/utils/Money/formatSpend';

const AuthorizationDetailsLabel = ({ children }) => (
  <h4 className="font-medium-font text-13px leading-4 text-dark-grey uppercase">
    {children}
  </h4>
);
AuthorizationDetailsLabel.propTypes = {
  children: PropTypes.node.isRequired,
};

const LoadableText = ({ children, isLoaded }) => {
  if (!isLoaded) {
    return <BarLoader />;
  }
  return (
    <p className="text-sm leading-5 text-brand-blue">
      {children}
    </p>
  );
};
LoadableText.propTypes = {
  children: PropTypes.node,
  isLoaded: PropTypes.bool.isRequired,
};
LoadableText.defaultProps = {
  children: '',
};

const ContractedServiceAuthorizationDetails = ({
 isFetching, remainingAuthorizedAmount, unit, isCostBased, authorizedServiceDeliveryDates, paymentApproach,
}) => {
 const isLoaded = !isFetching;
  return (
    <div className="flex space-x-10">
      <div className="flex flex-col space-y-2">
        <AuthorizationDetailsLabel>Remaining Authorized Amount</AuthorizationDetailsLabel>
        <LoadableText isLoaded={isLoaded}>
          {formatSpend(remainingAuthorizedAmount, unit, isCostBased)}
        </LoadableText>
      </div>
      <div className="flex flex-col space-y-2">
        <AuthorizationDetailsLabel>Authorized Service Delivery Date(s)</AuthorizationDetailsLabel>
        <LoadableText isLoaded={isLoaded}>
          {authorizedServiceDeliveryDates}
        </LoadableText>
      </div>
      <div className="flex flex-col space-y-2">
        <AuthorizationDetailsLabel>Payment Approach</AuthorizationDetailsLabel>
        <LoadableText isLoaded={isLoaded}>
          {fspUtils.PAYMENT_TYPES[paymentApproach]}
        </LoadableText>
      </div>
    </div>
  );
};

ContractedServiceAuthorizationDetails.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  remainingAuthorizedAmount: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  isCostBased: PropTypes.bool.isRequired,
  authorizedServiceDeliveryDates: PropTypes.string.isRequired,
  paymentApproach: PropTypes.string.isRequired,
};

export default ContractedServiceAuthorizationDetails;
