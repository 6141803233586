import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@unite-us/ui';

const CareTeamFooterConfirm = ({ formCancelPressed, handleFinalClose }) => (
  !formCancelPressed ? null : (
    <div className="care-team-footer-confirm">
      <p className="care-team-footer-confirm__text">You have unsaved changes. Are you sure you want to cancel?</p>

      <Button
        className="care-team-footer-confirm__keep-form"
        id="care-team-cancel-btn"
        label="No"
        onClick={() => handleFinalClose({ confirmClose: false })}
      />
      <Button
        id="care-team-save-btn"
        label="Yes"
        primary
        style={{ marginLeft: '10px' }}
        onClick={() => handleFinalClose({ confirmClose: true })}
        className="care-team-footer-confirm__close-form"
      />
    </div>
  ));

CareTeamFooterConfirm.propTypes = {
  formCancelPressed: PropTypes.bool,
  handleFinalClose: PropTypes.func.isRequired,
};


export default CareTeamFooterConfirm;
