import { get, some } from 'lodash';
import isRouteActive from './isRouteActive';

function isChildRouteActive(item, currentPath) {
  const children = get(item, 'children', []);

  return some(children, (child) => (
    isRouteActive(get(child, 'path'), currentPath)
  ));
}

export default isChildRouteActive;
