import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'common/utils/browserHistory';
import _ from 'lodash';
import querystring from 'query-string';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Pager,
  Table,
} from '@unite-us/ui';
import { FilterSingle, shapes } from '@unite-us/client-utils';
import { Spinner } from 'common/spinners';
import callOrLog from 'common/utils/callOrLog';
import { SEARCH } from 'common/utils/EventTracker/utils/eventConstants';
import { hasClientIdStandardization } from 'common/utils/FeatureFlags/flags';
import SearchTableBody from 'src/components/Search/components/SearchTableBody';
import SearchTableHeader from 'src/components/Search/components/SearchTableHeader';
import SearchTableSingleRow from 'src/components/Search/components/SearchTableSingleRow';
import { fetchSearchResults } from 'actions/Search/Group';
import { filterSearchResults } from 'src/components/Search/utils';
import {
  DEFAULT_INDEX_COUNT,
  DEFAULT_VIEW_OPTION,
  NO_RESULTS_FOUND_TEXT,
  VIEW_OPTIONS,
} from 'src/components/Search/constants';
import 'src/components/Search/stylesheets/search.scss';

export class Search extends Component {
  constructor(props) {
    super(props);
    this.buildResultsText = this.buildResultsText.bind(this);
    this.fetchResults = this.fetchResults.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
  }

  getChildContext() {
    const { clientIdStandardizationEnabled, session } = this.props;
    return { clientIdStandardizationEnabled, session };
  }

  componentDidMount() {
    const { query, model, page } = this.props;
    this.fetchResults(query, model, page);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentQuery = querystring.parse(this.props.location.search);
    const nextQuery = querystring.parse(nextProps.location.search);
    const { q, model, page } = nextQuery;

    if (!_.isEqual(currentQuery, nextQuery)) {
      this.fetchResults(q, model, page);
    }
  }

  onFilterChange(activeFilter, model) {
    const { query } = this.props;
    const page = 1;

    callOrLog(() => this.context.eventTracker(SEARCH.changeIndexView));
    this.fetchResults(query, model, page);
  }

  onNextClick() {
    const { query, model, page } = this.props;
    const nextPage = page + 1;
    this.fetchResults(query, model, nextPage);
  }

  onPrevClick() {
    const { query, model, page } = this.props;
    const prevPage = page > 1 ? page - 1 : 1;
    this.fetchResults(query, model, prevPage);
  }

  fetchResults(query, model, page) {
    const { groupId } = this.context;
    const per = DEFAULT_INDEX_COUNT;

    browserHistory.push({
      pathname: '/search',
      search: `?${querystring.stringify({ q: query, model, page })}`,
    });

    this.props.fetchSearchResults({
      groupId, query, model, per, page, isIndexFetch: true,
    });
  }

  buildResultsText() {
    const { model, query } = this.props;
    const titleObject = _.find(VIEW_OPTIONS, { value: model });
    const pageTitle = _.get(titleObject, 'label', '');
    const queryText = !_.isEmpty(query) ? `for "${query}"` : '';

    return (
      <span className="search__page-title" id={`search__page-title--${model}`}>{pageTitle}
        <span className="search__result-count">
          {` - Search results ${queryText}`}
        </span>
      </span>
    );
  }

  render() {
    const {
      isIndexFetching, model, paging, sectionResults,
    } = this.props;
    const hasResults = !_.isEmpty(sectionResults);

    const results = (hasResults ? (
      <SearchTableBody
        model={model}
        sectionResults={sectionResults}
      />
    ) :
      <SearchTableSingleRow rowContent={<span>{NO_RESULTS_FOUND_TEXT}</span>} />
    );

    return (
      <BaseCard className="search">
        <BaseCardHeader title={this.buildResultsText()}>
          <FilterSingle
            className="search__select-view--dropdown hidden"
            id="search__select-view-dropdown"
            name="search-results"
            onFilterChange={this.onFilterChange}
            options={VIEW_OPTIONS}
            value={this.props.model}
          />
          <Pager
            disabled={isIndexFetching}
            hideCount
            onNextClick={this.onNextClick}
            onPrevClick={this.onPrevClick}
            paging={paging}
          />
        </BaseCardHeader>
        <BaseCardBody>
          {
            isIndexFetching ? (
              <Table noBorder>
                <SearchTableSingleRow rowContent={<Spinner scale={0.5} />} />
              </Table>
            ) : (
              <Table noBorder>
                <SearchTableHeader model={model} />
                {results}
              </Table>
            )
          }
        </BaseCardBody>
      </BaseCard>
    );
  }
}

Search.propTypes = {
  clientIdStandardizationEnabled: PropTypes.bool.isRequired,
  fetchSearchResults: PropTypes.func.isRequired,
  isIndexFetching: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  model: PropTypes.string,
  page: PropTypes.number,
  paging: shapes.paging,
  query: PropTypes.string,
  sectionResults: PropTypes.array.isRequired,
  session: PropTypes.object.isRequired,
};

Search.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
};

Search.childContextTypes = {
  clientIdStandardizationEnabled: PropTypes.bool.isRequired,
  session: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { q, model, page } = querystring.parse(ownProps.location.search);
  const searchState = _.get(state, 'search', {});
  const paging = _.get(searchState, `searchResults.${model}.paging`) || {};
  const propsPage = _.parseInt(page);
  const sectionResults = _.get(searchState, `searchResults.${model}.data`) || [];
  const session = _.get(state, 'session', {});
  const filteredSectionResults = filterSearchResults(sectionResults);
  const clientIdStandardizationEnabled = hasClientIdStandardization(state);

  return {
    clientIdStandardizationEnabled,
    isIndexFetching: _.get(searchState, 'isIndexFetching', false),
    model: model || DEFAULT_VIEW_OPTION,
    page: propsPage || 1,
    paging,
    query: q || '',
    sectionResults: filteredSectionResults,
    session,
  };
}

export default connect(
  mapStateToProps,
  { fetchSearchResults },
)(Search);
