import Notifier from 'common/helpers/Notifier';
import { ADD_REFERRAL_INTERACTION } from 'actions';
import createNote from 'src/actions/Case/Contact/Group/createNote';

function createReferralInteraction({
  referralId,
  interaction,
}) {
  return async (dispatch) => {
    try {
      const response = await dispatch(createNote({
        interaction,
        subjectType: 'referral',
        subject: referralId,
      }));

      dispatch({
        type: ADD_REFERRAL_INTERACTION,
        payload: response,
      });

      return response;
    } catch (error) {
      console.log(error);
      Notifier.handleErrors(error);
      return error;
    }
  };
}

export default createReferralInteraction;
