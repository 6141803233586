import React from 'react';
import {
  TableRow,
  TableRowColumn,
} from '@unite-us/ui';

function TableRowLoading() {
  return (
    <TableRow>
      <TableRowColumn>
        Loading...
      </TableRowColumn>
    </TableRow>
  );
}

export default TableRowLoading;
