import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { get, isEmpty } from 'lodash';
import moment from 'moment';

const dateFormatter = (value) => {
  if (value) {
    return moment(value).utc().format('LL');
  }
  return null;
};

const dateTimeFormatter = (value) => {
  if (value) {
    return moment(value).utc().format('LLL');
  }
  return null;
};

const formatters = {
  money: (value) => {
    if (value) {
      const formattedMoneyValue = (value / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return `$ ${formattedMoneyValue}`;
    }
    return null;
  },
  date: dateFormatter,
  datetime: dateTimeFormatter,
  dropdown: (value) => value,
  multipledropdown: (value) => value && value.join(', '),
  file: (value) => value, // TODO real file upload
  number: (value) => value,
  text: (value) => value,
  textarea: (value) => value,
  uuid: (value) => value,
};

const AddressField = ({ label, value, index }) => (
  <div className="flex">
    <div className="pl-0 col-sm-3">
      <span className="font-heavy-font text-text-blue">{label}: </span>
    </div>
    <div className="col-sm-9" data-test-element={`metafield-address-value-${index}`}>
      <div className="text-text-blue" data-test-element={`metafield-address-line-1-value-${index}`}>{value.line_1}</div>
      {value.line_2 && (
        <div className="text-text-blue" data-test-element={`metafield-address-line-2-value-${index}`}>
          {value.line_2}
        </div>
      )}
      <div>
        <span className="text-text-blue" data-test-element={`metafield-city-value-${index}`}>{value.city}</span>,
        <span className="text-text-blue" data-test-element={`metafield-state-value-${index}`}> {value.state}</span>
        <span className="text-text-blue" data-test-element={`metafield-zip-value-${index}`}> {value.postal_code}</span>
      </div>
    </div>
  </div>
);

const MetafieldDetailViewCardData = ({ metafields = [], metadata = [] }) => {
  const metafieldValueObj = {};

  metadata.forEach((data) => {
    metafieldValueObj[data.field] = { value: data.value };
  });

  metafields.forEach((metafield) => {
    if (get(metafield, 'validations.required')) {
      metafieldValueObj[metafield.field] ||= { value: null };
    }
    metafieldValueObj[metafield.field] = {
      ...metafieldValueObj[metafield.field],
      label: metafield.label,
      type: metafield.type,
      required: get(metafield, 'validations.required'),
};
  });

  return (
    <div className="space-y-2">
      {
        Object.entries(metafieldValueObj).map(([fieldName, fieldData], index) => {
          if (fieldData.type === 'address' && !isEmpty(fieldData.value)) {
            return (
              <AddressField
                index={index}
                key={fieldName}
                label={fieldData.label}
                value={fieldData.value}
              />
            );
          }
          const formattedValue = formatters[fieldData.type] ? formatters[fieldData.type](fieldData.value) : '';
          return !isEmpty(fieldData.value) || fieldData.required || (typeof fieldData.value === 'number') ? (
            <div className="flex" key={fieldName}>
              <div className="pl-0 col-sm-3">
                <span className={cx(
                fieldData.required && 'ui-form-field__label--required',
                'font-heavy-font text-text-blue ',
)}
                >
                  {fieldData.label}:
                </span>
              </div>
              <div className="col-sm-9">
                { fieldData.required && (isEmpty(fieldData.value) && typeof fieldData.value !== 'number') ? (
                  <span className="text-red" data-test-element={`metafield-value-${index}`}>Required</span>
                ) : (
                  <span
                    className="text-text-blue"
                    data-test-element={`metafield-value-${index}`}
                  >{formattedValue}
                  </span>
)}

              </div>
            </div>
          ) : null;
        })
      }
    </div>
  );
};

MetafieldDetailViewCardData.propTypes = {
  metafields: PropTypes.array.isRequired,
  metadata: PropTypes.array.isRequired,
};

AddressField.propTypes = {
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
};

export default MetafieldDetailViewCardData;
