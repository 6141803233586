import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DISPLAY_NAME = 'family-member-delete-content';

const RelationshipDeleteContent = ({
  memberType,
}) => (
  <div className={classNames(DISPLAY_NAME)}>
    <p className="mb-4">
      {`If you delete this ${memberType} member, they will no longer appear on your client’s face sheet.`}
    </p>
    <p>
      Are you sure you want to delete?
    </p>
  </div>
);

RelationshipDeleteContent.propTypes = {
  memberType: PropTypes.string.isRequired,
};

export default RelationshipDeleteContent;
