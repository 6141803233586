import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dialog } from '@unite-us/ui';
import HoldModalForm from './HoldModalForm';

class HoldModalDialog extends Component {
  constructor(props) {
    super(props);

    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  openDialog() {
    return this.holdDialog.openDialog()
      .then(this.props.resetActionValue);
  }

  closeDialog() {
    this.holdDialog.closeDialog();
  }

  render() {
    return (
      <div>
        <Dialog
          id={this.props.dataTestId}
          data-testid={this.props.dataTestId}
          ref={(el) => { this.holdDialog = el; }}
          title={this.props.title}
          size="large"
          modal
        >
          <HoldModalForm referral={this.props.referral} closeDialog={this.closeDialog} />
        </Dialog>
      </div>
    );
  }
}

HoldModalDialog.propTypes = {
  referral: PropTypes.object.isRequired,
  resetActionValue: PropTypes.func.isRequired,
  dataTestId: PropTypes.func.isRequired,
  title: PropTypes.string,
};

HoldModalDialog.defaultProps = {
  title: 'Hold Referral',
};

export default HoldModalDialog;
