import PropTypes from 'prop-types';
import React from 'react';

const ClientEnrolledNotification = ({
  checkingEnrollment,
  programEnrollment,
  styles: { warningStyle, notificationStyle },
}) => {
  if (checkingEnrollment ||
    (programEnrollment && programEnrollment.is_active_enrollment && !programEnrollment.out_of_network)) {
    const pStyle = checkingEnrollment ? notificationStyle : warningStyle;
    const text = checkingEnrollment ?
      'Checking Program for Active Enrollment' :
      'Client is Already Enrolled in This Program';
    return (
      <div className="row">
        <div className="col-sm-12">
          <p style={pStyle}><em>{text}</em></p>
        </div>
      </div>
    );
  }
  return null;
};

ClientEnrolledNotification.propTypes = {
  programEnrollment: PropTypes.object,
  checkingEnrollment: PropTypes.bool,
  styles: PropTypes.object.isRequired,
};

ClientEnrolledNotification.defaultProps = {
  styles: {
    warningStyle: { margin: '0px', color: '#E53935' },
    notificationStyle: { margin: '0px', color: '#58606E' },
  },
};

export default ClientEnrolledNotification;
