import {
  FETCH_GROUP_FORMS,
  FETCH_CORE_GROUP_FORMS,
  CREATE_GROUP_CONTACT,
  FETCH_GROUP_CONTACT,
  RETURNED_ASSESSMENT,
  CLEAN_RETURNED_ASSESSMENTS,
  UNSET_GROUP_FORMS_FETCHING,
  SET_GROUP_FORMS_FETCHING,
  SET_ASSESSMENTS_RESPONSES,
} from 'actions';
import _ from 'lodash';

export const defaultState = {
  returnedAssessment: [],
  isFetching: false,
  responses: [],
};

export default function GroupFormsReducer(state = defaultState, action) {
  const returnedAssessment = new Set(state.returnedAssessment);

  switch (action.type) {
    case SET_GROUP_FORMS_FETCHING:
      return { ...state, isFetching: true };
    case UNSET_GROUP_FORMS_FETCHING:
      return { ...state, isFetching: false };
    case FETCH_GROUP_FORMS: {
      const forms = action.payload.data.data.map((x) => x.form);
      return { ...state, ...{ [action.groupId]: forms } };
    }
    case FETCH_CORE_GROUP_FORMS: {
      return { ...state, ...{ [action.groupId]: action.payload.data.data } };
    }
    case FETCH_GROUP_CONTACT:
    case CREATE_GROUP_CONTACT:
      return { ...state, contactId: action.payload.data.data.id };
    case RETURNED_ASSESSMENT:
      returnedAssessment.add(action.payload.name);

      return {
        ...state,
        returnedAssessment: Array.from(returnedAssessment),
      };
    case CLEAN_RETURNED_ASSESSMENTS: {
      const filteredAssessments = _.filter(Array.from(returnedAssessment), (a) => (
        _.findIndex(state[action.groupId], { name: a }) >= 0
      ));
      return {
        ...state,
        returnedAssessment: filteredAssessments,
      };
    }
    case SET_ASSESSMENTS_RESPONSES: {
      return {
        ...state,
        responses: action.payload.responses,
        responsesContactId: action.payload.responsesContactId,
      };
    }
    default:
      return state;
  }
}
