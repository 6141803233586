import _ from 'lodash';
import {
  isReferredByGroup,
  isReferredToGroup,
} from 'src/components/Referrals/ReferralStatus/utils';
import {
  REFERRAL_STATUS_ACCEPTED,
  REFERRAL_STATUS_PENDING,
  REFERRAL_STATUS_DECLINED,
  REFERRAL_STATUS_IN_REVIEW,
} from '../constants';

export default function isEditableReferral(referral = {}, groupId, isCCUser = false) {
  const { status } = referral;

  if (isReferredToGroup(referral, groupId)) {
    if (!isCCUser) {
      return _.every([
        status !== REFERRAL_STATUS_DECLINED,
        status !== REFERRAL_STATUS_PENDING,
      ]);
    }
    return true;
  }

  if (isReferredByGroup(referral, groupId)) {
    if (!isCCUser) {
      return _.every([
        status !== REFERRAL_STATUS_ACCEPTED,
        status !== REFERRAL_STATUS_IN_REVIEW,
      ]);
    }
    return true;
  }

  return false;
}
