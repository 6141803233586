function combinePhoneNumberAndExtension(phoneFields = {}) {
  const { phone_number, extension } = phoneFields;
  const newPhoneNumber = phone_number;

  if (extension && newPhoneNumber.value.length < 11) {
    newPhoneNumber.value = phone_number.value + extension.value;
    newPhoneNumber.initialValue = phone_number.initialValue + extension.initialValue;
  }

  return newPhoneNumber;
}

export default combinePhoneNumberAndExtension;
