import { map, startCase } from 'lodash';
import { DAYS_OF_THE_WEEK } from '../constants';

function getDaysOfTheWeekOptions(days = DAYS_OF_THE_WEEK) {
  return map(days, (day) => (
    {
      label: startCase(day),
      value: day,
    }
  ));
}

export default getDaysOfTheWeekOptions;
