import Notifier from 'common/helpers/Notifier';
import {
  CREATE_PAYER_STARTED,
  CREATE_PAYER_SUCCESS,
  CREATE_PAYER_ERROR,
} from 'actions';
import { apiV1 } from 'src/api/config';
import { isHttpError } from 'common/utils/Error';
import { isHttpSuccess } from 'src/api/utils/httpStatus';

const buildPayer = (params) => ({
  external_id: params.external_id,
  name: params.name,
  state: params.state,
});

export default function createPayer(params) {
  return async (dispatch) => {
    dispatch({ type: CREATE_PAYER_STARTED });

    const payer = buildPayer(params);
    const response = await apiV1.createRecord('payer', payer);

    if (!(response && isHttpSuccess(response.status))) {
      dispatch({ type: CREATE_PAYER_ERROR });
      if (isHttpError(response)) {
        Notifier.dispatch(
          'error',
          'Error creating payer.',
        );
      } else {
        Notifier.handleErrors(response);
      }
      return response;
    }

    Notifier.dispatch(response.status, 'Payer created.');

    dispatch({
      type: CREATE_PAYER_SUCCESS,
      payload: response.data.data,
    });

    return response;
  };
}
