import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReferralDetailSummaryWrapper from 'src/components/Referrals/components/ReferralDetailSummaryWrapper';

const ReferralServiceType = ({
  groupId, referral,
}) => {
  const contact = _.wget(referral, 'contact', {});

  return (
    <div className="referral-service-type">
      <span>{_.wget(referral, 'service_type.name', '')}</span>
      <ReferralDetailSummaryWrapper
        contact={contact}
        groupId={groupId}
        referral={referral}
      />
    </div>
  );
};

ReferralServiceType.propTypes = {
  groupId: PropTypes.string.isRequired,
  referral: PropTypes.shape({
    contact: PropTypes.object.isRequired,
  }).isRequired,
};

ReferralServiceType.defaultProps = {
};

export default ReferralServiceType;
