import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import cx from 'classnames';
import { hasFeatureRole } from 'common/utils/Roles/hasFeatureRole';
import { CurrentEmployeeContext } from 'src/common/contexts/CurrentEmployeeContext';

const LeftNavigationBar = ({ children, className }) => {
  const currentEmployee = useContext(CurrentEmployeeContext);
  const hasPayerInvoicesRole = hasFeatureRole({
    employeeRoles: currentEmployee?.roles,
    targetRoleKey: 'payer_invoices',
  });

  return (
    <div
      className={`${cx(
        'workqueue-left-nav py-8 px-2 box-border flex-shrink-0',
        'sticky top-0 space-y-1',
        'bg-brand-blue text-white text-base',
        className,
      )} ${hasPayerInvoicesRole ? 'w-48' : 'w-56'}`}
    >
      {children}
    </div>
  );
};

LeftNavigationBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

LeftNavigationBar.defaultProps = {
  children: null,
  className: '',
};

export default LeftNavigationBar;
