import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import {
  hasPaymentsUserAccess,
  showProgramStatusToggle,
} from 'src/common/utils/FeatureFlags/flags';
import 'src/pages/org/_id/programs/new/GroupProgramNew.scss';
import ProgramDetailsV2Wrapper from './ProgramDetailsV2Wrapper';

export class GroupDetailsProgram extends Component {
  constructor(props) {
    super(props);

    this.toggleExpandChange = this.toggleExpandChange.bind(this);

    this.state = {
      expanded: props.initiallyCollapsed,
    };
  }

  toggleExpandChange() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  render() {
    const {
      center,
      languageEnums,
      program,
      programEnums,
      programLocations,
      serviceTypesEnums,
      stateEnums,
      usePaymentsUserRole,
    } = this.props;

    return (
      <ProgramDetailsV2Wrapper
        center={center}
        languageEnums={languageEnums}
        program={program}
        programEnums={programEnums}
        programLocations={programLocations}
        serviceTypesEnums={serviceTypesEnums}
        stateEnums={stateEnums}
        usePaymentsUserRole={usePaymentsUserRole} // feature flag
      />
    );
  }
}

GroupDetailsProgram.propTypes = {
  center: PropTypes.object,
  initiallyCollapsed: PropTypes.bool.isRequired,
  languageEnums: PropTypes.array.isRequired,
  program: PropTypes.shape({
    accessibility_options: PropTypes.array,
    cities: PropTypes.array,
    counties: PropTypes.array,
    delivery_options: PropTypes.array,
    description: PropTypes.string,
    eligibility_text: PropTypes.string,
    id: PropTypes.string,
    languages: PropTypes.array,
    locations: PropTypes.array,
    name: PropTypes.string,
    payment_options: PropTypes.array,
    population_restrictions: PropTypes.array,
    population_specializations: PropTypes.array,
    receiving_referrals: PropTypes.bool,
    service_types: PropTypes.array,
    states: PropTypes.array,
    status: PropTypes.string,
    transportation_options: PropTypes.array,
  }).isRequired,
  programEnums: PropTypes.object.isRequired,
  programLocations: PropTypes.array.isRequired,
  serviceTypesEnums: PropTypes.object.isRequired,
  stateEnums: PropTypes.array,
  usePaymentsUserRole: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  languageEnums: get(state, 'session.enums.base.languages', []),
  programEnums: get(state, 'session.enums.groups.programs', []),
  serviceTypesEnums: get(state, 'session.globals.service_types', []),
  stateEnums: get(state, 'session.enums.geography.us_states', []),
  usePaymentsUserRole: hasPaymentsUserAccess(state),
  useReferralToggle: showProgramStatusToggle(state),
});

GroupDetailsProgram.defaultProps = {
  center: {},
  stateEnums: [],
  usePaymentsUserRole: false,
};

export default connect(mapStateToProps)(GroupDetailsProgram);
