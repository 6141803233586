import { GOOGLE_MAPS_API_KEY } from 'src/config/env/env.config';

export const GOOGLE_STATIC_URL = 'https://maps.googleapis.com/maps/api';
export const KEY = `&key=${GOOGLE_MAPS_API_KEY}`;

const ASSET_URL = 'https://uniteus-io-assets.s3.amazonaws.com/app-client';
const MAP_MARKER_URL = `${ASSET_URL}/location-markers/map-marker.svg`;
const STATIC_API_URL = `${GOOGLE_STATIC_URL}/staticmap?key=${GOOGLE_MAPS_API_KEY}`;
const LEGACY_STATIC_MARKER_URL = `${ASSET_URL}/location-marker-2x.png`;
const STATIC_MARKER_URL = `${ASSET_URL}/location-markers/IconMapPin@2x.png`;
const STATIC_OON_MARKER_URL = `${ASSET_URL}/location-markers/IconMapPin000@2x.png`;

export {
  ASSET_URL,
  LEGACY_STATIC_MARKER_URL,
  MAP_MARKER_URL,
  STATIC_API_URL,
  STATIC_MARKER_URL,
  STATIC_OON_MARKER_URL,
};
