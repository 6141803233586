import { useFind } from 'src/api/APIHooks';
import { get, isEmpty } from 'lodash';

const useProcedureCodeModifiers = (procedureCodeModifierIds = [], showSavedClaimCodes = false) => {
  const { isFetching, data } = useFind(
    'procedure_code_modifiers',
    {
      id: procedureCodeModifierIds.join(','),
    },
    {
      queryConfig: {
        enabled: showSavedClaimCodes && !isEmpty(procedureCodeModifierIds),
      },
    },
  );
  const procedureCodeModifiers = get(data, 'data.data', []);

  return isFetching ? [] : procedureCodeModifiers;
};

export default useProcedureCodeModifiers;
