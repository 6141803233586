import React from 'react';
import PropTypes from 'prop-types';
import {
  BaseCard,
  BaseCardBody,
} from '@unite-us/ui';

import {
  ChartTitle,
  DataTable,
} from 'src/common/charts';

import { theme } from '../utils';

const ReportTable = ({
  title, subTitle, cardStyle, ...rest
}) => (
  <BaseCard className="chart" style={cardStyle}>
    <ChartTitle
      subTitle={subTitle}
      title={title}
    />
    <BaseCardBody>
      <DataTable {...rest} />
    </BaseCardBody>
  </BaseCard>
);

ReportTable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  cardStyle: PropTypes.object,
  colWidths: PropTypes.object,
  showPercent: PropTypes.bool.isRequired,
  styles: PropTypes.object.isRequired,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  x: PropTypes.func.isRequired,
  xKey: PropTypes.string.isRequired,
  xLabel: PropTypes.string.isRequired,
  y: PropTypes.func.isRequired,
  yKey: PropTypes.string.isRequired,
  yLabel: PropTypes.string.isRequired,
};

ReportTable.defaultProps = {
  cardStyle: {
    marginBottom: theme.card.marginBottom,
  },
  showPercent: true,
  styles: {
    totalRow: {
      backgroundColor: '#F8F8F8',
      fontWeight: 'bold',
    },
  },
  x: (d) => d.label,
  xKey: 'label',
  xLabel: 'Name',
  y: (d) => d.value,
  yKey: 'value',
  yLabel: 'Total',
};

export default ReportTable;
