import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  get,
  find,
  isEmpty,
  noop,
} from 'lodash';
import callOrLog from 'common/utils/callOrLog';
import findCurrentGroup from 'common/utils/findCurrentGroup';
import { isFocused, INSURANCE } from 'common/utils/isFocused';
import { NEW_CLIENT } from 'common/utils/EventTracker/utils/eventConstants';
import {
  createUpdateDeletePaymentsInsurances,
} from 'src/components/Insurance/utils/';
import {
  createInsuranceEntry,
  fetchPaymentsInsurance,
  fetchInsurancePlanInformation,
  deleteInsuranceEntry,
  editInsuranceEntry,
} from 'src/components/Insurance/actions';
import {
  createGroupContact,
  createContactAddress,
  deleteContactAddressField,
  updateContactAddressField,
  updateGroupContact,
} from 'actions/Contact/Group';
/* eslint-disable-next-line max-len */
import { loadGoogleMapsAPI } from 'src/components/Browse/Map/utils/requiredMapScript';
import featureFlag from 'common/utils/FeatureFlag/FeatureFlag';
import AddContactInformationForm from './AddContactInformationForm';
import {
  decodeContactData,
  getItemsToDeleteUpateOrAdd,
} from '../utils';

export class AddContactInformation extends Component {
  constructor(props) {
    super(props);
    this.createOrUpdateContact = this.createOrUpdateContact.bind(this);
    this.initiatePaymentsEventTracking = this.initiatePaymentsEventTracking.bind(this);
  }

  componentDidMount() {
    const { currentGroup } = this.props;
    const insuranceFocused = isFocused(currentGroup, INSURANCE);

    if (insuranceFocused) {
      this.fetchPaymentsData();
    }
  }

  async createOrUpdateContact(
    values = {},
    fields = {},
    insurances = [],
    initialPaymentsIds = [],
  ) {
    loadGoogleMapsAPI();
    const {
      groupId,
      selectedContact,
      location: { query: { contactId } },
    } = this.props;

    /**
     * Determines if contact is a POST or PATCH to the people table
     * @param {object} contactId - The person id, if null POST else PATCH
     */
    if (contactId) {
      const addresses = getItemsToDeleteUpateOrAdd({
        current: isEmpty(selectedContact) ? [] : selectedContact.addresses, // Null check to prevent breaking front-end
        values: values.addresses,
        fields: fields.addresses,
      });

      if (addresses.toDelete.length || addresses.toAdd.length || addresses.toUpdate.length) {
        const promises = [
          ...addresses.toDelete.map((address) => this.props.deleteContactAddressField(groupId, contactId, address.id)),
          ...addresses.toUpdate.map((address) => this.props.updateContactAddressField(
            groupId,
            contactId,
            address.id,
            address,
          )),
          ...addresses.toAdd.map((address) => this.props.createContactAddress(
            groupId,
            contactId,
            address,
          )),
        ];
        await Promise.all(promises);
      }

      const showNotification = false;

      return this.props.updateGroupContact(
        groupId,
        contactId,
        { ...values, addresses: undefined },
      ).then((payload) => this.createUpdateDeletePaysInsurances(
        payload,
        insurances,
        initialPaymentsIds,
        fields,
        showNotification,
      ));
    }

    return this.props.createGroupContact({
      groupId,
      contact: values,
      options: {},
    }).then((payload) => this.createUpdateDeletePaysInsurances(
      payload,
      insurances,
      initialPaymentsIds,
      fields,
    ));
  }

  createUpdateDeletePaysInsurances(
    payload,
    insurances = [],
    initialPaymentsIds = [],
    fields = {},
    showNotification,
  ) {
    const { groupId } = this.props;
    const contactId = get(payload, 'data.data.id', {});

    const paymentsIds = initialPaymentsIds.filter((insurance) => insurance.plan_type !== 'social');

    createUpdateDeletePaymentsInsurances({
      initialPaymentsIds: paymentsIds,
      values: { insurance: insurances },
      contactId,
      groupId,
      createInsuranceEntry: this.props.createInsuranceEntry,
      editInsuranceEntry: this.props.editInsuranceEntry,
      deleteInsuranceEntry: this.props.deleteInsuranceEntry,
      paymentsEventTracking: this.initiatePaymentsEventTracking,
      showNotification,
      insuranceFields: fields.insurance,
    });

    return payload;
  }

  fetchPaymentsData() {
    const { location: { query: { contactId } }, groupId } = this.props;
    if (contactId) {
      this.props.fetchPaymentsInsurance(contactId, groupId)
        .then(({ data: { data = [] } }) => data.forEach(
          (insurance) => this.props.fetchInsurancePlanInformation(insurance.relationships.plan.data.id, contactId),
        ));
    }
  }

  initiatePaymentsEventTracking(insurancesArr = []) {
    const updatedTypes = insurancesArr.reduce((insurances, currentInsurance) => {
      if (isEmpty(currentInsurance.plan_type)) {
        return insurances;
      }
      return [...insurances, currentInsurance.plan_type.toLowerCase()];
    }, []);

    const eventTracker = this.context.eventTracker;
    if (!isEmpty(updatedTypes)) {
      callOrLog(() => eventTracker(
        NEW_CLIENT.changedInsurance,
        { insurance_types: updatedTypes },
      ));
    }
  }

  render() {
    const {
      activeStep,
      context,
      contactData,
      insurances,
      integrationContact,
      location: { query: { contactId } },
      selectedContact,
      route,
    } = this.props;

    return (
      <div className="contact-search">
        <AddContactInformationForm
          activeStep={activeStep}
          context={context}
          contactId={contactId}
          contactData={contactData}
          insurances={insurances}
          integrationContact={integrationContact}
          selectedContact={selectedContact}
          createOrUpdateContact={this.createOrUpdateContact}
          checkForAttachedForms={route.from === 'nav' || route.from === 'screening' || route.from === 'referral'}
          isClientRoute={route.from === 'nav'}
          addressTypeahead
        />
      </div>
    );
  }
}

AddContactInformation.propTypes = {
  activeStep: PropTypes.object.isRequired,
  contactData: PropTypes.object.isRequired,
  context: PropTypes.string,
  createGroupContact: PropTypes.func.isRequired,
  createInsuranceEntry: PropTypes.func,
  currentGroup: PropTypes.object.isRequired,
  deleteContactAddressField: PropTypes.func.isRequired,
  deleteInsuranceEntry: PropTypes.func,
  editInsuranceEntry: PropTypes.func,
  fetchInsurancePlanInformation: PropTypes.func,
  fetchPaymentsInsurance: PropTypes.func,
  groupId: PropTypes.string.isRequired,
  integrationContact: PropTypes.bool,
  insurances: PropTypes.array,
  selectedContact: PropTypes.object,
  createContactAddress: PropTypes.func.isRequired,
  updateContactAddressField: PropTypes.func.isRequired,
  updateGroupContact: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  location: PropTypes.shape({
    query: PropTypes.object,
  }).isRequired,
};

AddContactInformation.defaultProps = {
  context: '',
  createInsuranceEntry: noop,
  deleteInsuranceEntry: noop,
  editInsuranceEntry: noop,
  fetchInsurancePlanInformation: noop,
  fetchPaymentsInsurance: noop,
  insurances: [],
  integrationContact: false,
  selectedContact: {},
};

AddContactInformation.contextTypes = {
  eventTracker: PropTypes.func,
};

export function mapStateToProps(state, ownProps) {
  const {
    session: { groupId },
  } = state;
  const currentGroup = findCurrentGroup(state.user, state.session);
  const {
    location:
    {
      query: {
        contact,
        contactId,
        fromIntegration: integrationContact,
      },
    },
  } = ownProps;

  const contactData = decodeContactData(contact);
  const selectedContact = contactId && find(state.searchedContacts, { id: contactId });
  const fetchedContact = contactId && find(state.contacts.contacts, { id: contactId });

  return {
    contactData,
    currentGroup,
    groupId,
    integrationContact,
    insurances: state.insurances.data[contactId] || [],
    selectedContact: selectedContact || fetchedContact,
  };
}

export default connect(
  mapStateToProps,
  {
    createGroupContact,
    createInsuranceEntry,
    deleteContactAddressField,
    deleteInsuranceEntry,
    editInsuranceEntry,
    fetchInsurancePlanInformation,
    fetchPaymentsInsurance,
    updateContactAddressField,
    createContactAddress,
    updateGroupContact,
  },
)(featureFlag(AddContactInformation));
