import PropTypes from 'prop-types';
import React from 'react';
import { SelectField } from '@unite-us/ui';
import { validations } from '@unite-us/app-components';
import { primaryWorkerOptions } from 'src/components/Cases/utils';

const CasePrimaryWorkerField = ({
  fetchGroupsUsers,
  groupsUsers,
  registerField,
  ...props
}) => {
  const allPrimaryWorkerOptions = primaryWorkerOptions({ groupsUsers });
  return (
    <SelectField
      loadOptions={fetchGroupsUsers}
      ref={registerField}
      options={allPrimaryWorkerOptions}
      searchPlaceholderValue="Search - Displaying 50 Max"
      validations={validations.isRequired}
      {...props}
    />
  );
};

CasePrimaryWorkerField.propTypes = {
  fetchGroupsUsers: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  field: PropTypes.object.isRequired,
  groupsUsers: PropTypes.object.isRequired,
  registerField: PropTypes.func.isRequired,
};

CasePrimaryWorkerField.defaultProps = {
  id: 'primary-worker',
  inline: false,
  label: 'Primary Worker',
  placeholder: 'Choose one...',
  required: true,
};

export default CasePrimaryWorkerField;
