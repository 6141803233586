const filterMimeType = (type) => {
  switch (type) {
    case 'png':
    case 'image':
      return 'image/png';
    case 'csv':
    case 'data':
      return 'text/csv';
    default:
      return 'application/pdf';
  }
};

export default filterMimeType;
