import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, noop } from 'lodash';
import {
  Editor,
  EditorState,
  RichUtils,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { Icon } from '@unite-us/ui';
import 'draft-js/dist/Draft.css';
import ReduxFormControlWrapper from './ReduxFormControlWrapper';

/**
 * Draft.js Docs - https://facebook.github.io/draft-js/docs/api-reference-editor.html#content
 * stateFromHTML - https://github.com/sstur/draft-js-import-html
 * stateToHTML   - https://github.com/sstur/draft-js-export-html
 */

export const RichTextInputField = (props) => {
  const {
    className,
    hint,
    invalid,
    label,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required,
    value,
    labelClassName,
    dataTestId,
  } = props;

  const contentState = stateFromHTML(value);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [valueIsPresent, setValueIsPresent] = useState(value !== '');
  useEffect(() => {
    // if value is loaded after component mounts, set the editor state
    // but only the first time, otherwise it will infinite loop,
    // and only if it's different from what's in the editor already
    const derivedValue = stateToHTML(editorState.getCurrentContent());
    if (!valueIsPresent && value !== '') {
      setValueIsPresent(true);
      if (value !== derivedValue) {
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  }, [value, valueIsPresent]);
  const onEdit = useCallback((newEditorState) => {
    if (!newEditorState) return;
    const newValue = stateToHTML(newEditorState.getCurrentContent());
    onChange(newValue);
    setEditorState(newEditorState);
  });
  const onToggleStyle = useCallback((inlineStyle) => onEdit(RichUtils.toggleInlineStyle(editorState, inlineStyle)));
  const onToggleBlockStyle = useCallback((blockType) => onEdit(RichUtils.toggleBlockType(editorState, blockType)));
  const handleKeyCommand = useCallback((command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onEdit(newState);
      return 'handled';
    }
    return 'not-handled';
  }, [editorState, onEdit, RichUtils]);

  return (
    <div
      className={classNames(className, 'antialiased')}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        className={labelClassName ?
        classNames(labelClassName, required && 'ui-form-field__label--required') :
        classNames(
          'block leading-snug',
          'font-extrabold font-medium-font normal-case',
          'text-13px text-text-blue',
          required && 'ui-form-field__label--required', // red asterisk after content
        )}
        id={`${name}__form-label`}
      >
        {label}
      </label>
      {!isEmpty(hint) && (
        <p className="mt-1 text-13px text-dark-grey">
          {hint}
        </p>
      )}
      <div className="flex items-center space-x-4 mt-4 mb-4">
        <Icon
          className="h-3 w-3 text-text-blue fill-current"
          icon="IconBoldText"
          onClick={() => onToggleStyle('BOLD')}
        />
        <Icon
          className="h-3 w-3 text-text-blue fill-current"
          icon="IconItalicText"
          onClick={() => onToggleStyle('ITALIC')}
        />
        <div className="w-0 h-6 border border-l-0 border-solid border-dark-fill-blue" />
        <Icon
          className="h-3 w-3 text-text-blue fill-current"
          icon="IconUnorderedList"
          onClick={() => onToggleBlockStyle('unordered-list-item')}
        />
        <Icon
          className="h-3 w-3 text-text-blue fill-current"
          icon="IconOrderedList"
          onClick={() => onToggleBlockStyle('ordered-list-item')}
        />
      </div>
      <div
        className={classNames(
          'w-auto h-20 pl-4 pr-8 py-2 overflow-y-auto relative resize',
          'outline-none border border-solid rounded-md shadow-sm bg-white',
          invalid ?
            'border-light-red focus-within:border-red focus-within:shadow-input-error-ring' :
            `border-dark-border-blue hover:border-action-blue
             focus-within:border-action-blue focus-within:shadow-input-ring`,
        )}
        onBlur={onBlur}
        onFocus={onFocus}
        data-testid={dataTestId}
      >
        <Editor
          ariaLabel={label}
          ariaDescribedBy={`${name}__form-error`}
          ariaInvalid={invalid}
          ariaLabelledBy={`${name}__form-label`}
          ariaRequired={required}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={onEdit}
          placeholder={placeholder}
        />
        {invalid && (
          <div className="absolute right-0 top-0 pt-2 pr-3 flex items-center pointer-events-none">
            <Icon icon="IconExclamationCircle" className="h-5 w-5 text-red fill-current" aria-hidden="true" />
          </div>
        )}
      </div>
    </div>
  );
};

RichTextInputField.propTypes = {
  className: PropTypes.string,
  hint: PropTypes.string,
  invalid: PropTypes.bool,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  labelClassName: PropTypes.string,
  dataTestId: PropTypes.string,
};

RichTextInputField.defaultProps = {
  className: '',
  hint: '',
  invalid: false,
  onBlur: noop,
  onFocus: noop,
  placeholder: '',
  required: false,
  labelClassName: '',
  dataTestId: '',
};

export default ReduxFormControlWrapper(RichTextInputField);
