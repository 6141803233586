import React from 'react';
import { PropTypes } from 'prop-types';
import * as env from 'src/config/env/env.config';
import { FileThumbnail } from '@unite-us/client-utils';
import { get } from 'lodash';
import {
  ColumnHeader,
  DataCell,
  DataRow,
  HeaderRow,
  Table,
  TableBody,
} from 'src/common/tables/V2';
import { Card, CardSubHeader } from 'src/common/Card';
import { Spinner } from 'src/common/spinners';
import { useFind } from 'src/api/APIHooks';
import formatFileType from '../../utils/formatFileType';
import usePlanMetafields from '../../hooks/usePlanMetafields';

const SupportingDocumentsCard = ({ enrollmentRequest }) => {
  const { data: fileResponse, isFetching, isLoading } = useFind(
    'file_upload',
    {
      'record.type': 'enrollment_request',
      record: enrollmentRequest.id,
    },
  );
  const files = get(fileResponse, 'data.data', []);
  const metafields = usePlanMetafields(enrollmentRequest);
  const metadata = enrollmentRequest.metadata || [];
  return (
    <Card className="mt-3 px-4 py-4 bg-white">
      <CardSubHeader>Supporting Documents</CardSubHeader>
      <hr className="pt-2 -mx-4 border-dark-border-blue" />
      {isFetching || isLoading ? <Spinner /> : (
        <div className="py-4 pl-4 space-y-2">
          {
            files.length ? (
              <Table className="bg-white border-collapse border-solid border rounded border-dark-border-blue">
                <HeaderRow>
                  <ColumnHeader>File Name</ColumnHeader>
                  <ColumnHeader>Type</ColumnHeader>
                  <ColumnHeader>Date Modified</ColumnHeader>
                </HeaderRow>
                <TableBody>
                  {
                    files.map((fileObject) => (
                      <DataRow key={fileObject.id}>
                        <DataCell>
                          <a
                            href={`${env.CORE_BASE_URL}${fileObject.path}`} rel="noreferrer" target="_blank"
                            className="flex text-action-blue pt-2 pl-2"
                            key={fileObject.id}
                          >
                            <div className="pr-1">
                              <FileThumbnail size={20} color="#4467AB" contentType={fileObject.content_type} />
                            </div>
                            <div>{fileObject.filename}</div>
                          </a>
                        </DataCell>
                        <DataCell>{formatFileType(fileObject.id, metadata, metafields)}</DataCell>
                        <DataCell>{
                          new Intl
                            .DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
                            .format(fileObject.created_at ? new Date(fileObject.created_at) : new Date())
                        }
                        </DataCell>
                      </DataRow>
                    ))
                  }
                </TableBody>
              </Table>
            ) : <div className="pt-1" data-testid="no-documents">No supporting documents available.</div>
          }
        </div>
      )}
    </Card>
  );
};

SupportingDocumentsCard.propTypes = {
  enrollmentRequest: PropTypes.object.isRequired,
};

export default SupportingDocumentsCard;
