import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

class RemovePhoneConfirmation extends Component {
  constructor(props) {
    super(props);

    this.onCancel = this.onCancel.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  onCancel() {
    this.props.onCancel();
  }

  onConfirm() {
    this.props.onConfirm();
  }

  render() {
    const {
      confirmationMessage,
      show,
      styles,
    } = this.props;

    return (
      <div className="remove-phone-confirmation row">
        <div className="col-md-12 text-right">
          <div
            className="remove-button"
            style={show ? styles.showRemove : styles.hideRemove}
          >
            {confirmationMessage}
            <a
              className="confirm-confirmation"
              onClick={this.onConfirm}
              role="button"
              tabIndex="0"
            >
              YES
            </a>
            <a
              className="cancel-confirmation"
              onClick={this.onCancel}
              role="button"
              tabIndex="0"
            >
              NO
            </a>
          </div>
        </div>
      </div>
    );
  }
}

RemovePhoneConfirmation.propTypes = {
  confirmationMessage: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  show: PropTypes.bool,
  styles: PropTypes.object.isRequired,
};

RemovePhoneConfirmation.defaultProps = {
  confirmationMessage: 'Are you sure you want to remove this phone number?',
  onCancel: noop,
  onConfirm: noop,
  show: false,
  styles: {
    fadeRemove: { opacity: '.3' },
    hideRemove: { visibility: 'hidden', display: 'none' },
    showRemove: { visibility: 'visible' },
  },
};

export default RemovePhoneConfirmation;
