import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Spinner } from 'common/spinners';
import {
  hasPaymentsUserAccess,
  serviceAreaSupportForOrgs,
  includePathwaysServices,
  hint716SearchNetworkHubSupportPremiumSelector,
} from 'common/utils/FeatureFlags/flags';
import { Serializer } from '@unite-us/client-utils';

// actions
import { fetchPosition, fetchIpPosition } from 'src/common/utils/Session/actions';
import {
  clearNetworkBrowseGroup,
  clearNetworkBrowseGroupPrograms,
  clearNetworkBrowseGroups,
  resetFetchStatus,
} from 'actions/Browse';
import {
  fetchCorePrograms,
  searchNetworkBrowseGroups,
} from 'actions/Group/Network';
import { searchMyNetworkBrowse } from 'src/actions/Group/Network/searchNetworkBrowseGroups';

// utils
import { getEmployee } from 'src/components/Employee/employeeGetters';
import callOrLog from 'common/utils/callOrLog';
import { BROWSE } from 'common/utils/EventTracker/utils/eventConstants';
import { flattenServiceTypes } from 'common/utils/ServiceTypes';
import $scripts from 'scriptjs';
import {
  getOurCoordinates,
  getClientAddress,
} from './utils/address';
import {
  buildBrowseFilters,
  searchParams,
} from './utils/filters';
import { shouldSetNewCenter } from './Map/utils';

// styles
import './stylesheets/browse.scss';

const browseHOC = (opts) => (ComposedComponent) => {
  const scripts = _.get(opts, 'scripts', []);
  const scriptsEmpty = () => _.isEmpty(scripts);

  class Browse extends Component {
    constructor(props) {
      super(props);

      this.state = {
        scriptsLoaded: scriptsEmpty(),
        sortBy: 'distance',
        clientAddress: {},
        uiCenter: null,
        uiCenterForDistance: null,
        uiCurrentSelectedGroupId: null,
        uiFetching: false,
        uiFilters: {},
        uiOpen: false,
        uiSelectedGroupsFromMarker: null,
        uiShowSearchButton: false,
      };

      this.buildBrowseFiltersParams = this.buildBrowseFiltersParams.bind(this);
      this.childCall = this.childCall.bind(this);
      this.setParentState = this.setParentState.bind(this);
      this.fetchGroups = this.fetchGroups.bind(this);
      this.onDrawerClose = this.onDrawerClose.bind(this);
      this.onFiltersChange = this.onFiltersChange.bind(this);
      this.onSortByChange = this.onSortByChange.bind(this);
      this.debouncedFetchGroups = _.debounce(this.fetchGroups, 500);
      this.setGeoFilters = this.setGeoFilters.bind(this);
      this.handleCenterChange = this.handleCenterChange.bind(this);
      this.handleSearchAreaChange = this.handleSearchAreaChange.bind(this);
      this.handleSearchAreaClick = this.handleSearchAreaClick.bind(this);
      this.appendGroups = this.appendGroups.bind(this);
      this.trackBrowseFilters = this.trackBrowseFilters.bind(this);
      this.debouncedTrackBrowseFilters = _.debounce(this.trackBrowseFilters, 800);
      this.fetchGeoOrIpPosition = this.fetchGeoOrIpPosition.bind(this);
      this.setInitialCenterAndFilters = this.setInitialCenterAndFilters.bind(this);
    }

    UNSAFE_componentWillMount() {
      if (scriptsEmpty()) {
        return;
      }

      $scripts(scripts, () => {
        this.setState({ ...this.state, scriptsLoaded: true }, async () => {
          const { contact = {} } = this.props;

          // get the coordinates of the current user, current group, browser or ip
          const ourCoordinates = getOurCoordinates(this.props);

          // try getting a valid contact address if there is a contact in props
          const clientAddress = await (!_.isEmpty(contact.addresses) && getClientAddress(contact));
          // check to see if there is a valid contact address that has latitude and longitude
          const shouldUseContactAddress = !_.isEmpty(contact.addresses) &&
            !_.isError(clientAddress) &&
            clientAddress !== 'ZERO_RESULTS';

          if (shouldUseContactAddress) {
            this.setState({
              clientAddress,
            }, () => {
              if (!_.isEmpty(clientAddress)) {
                this.setInitialCenterAndFilters();
              }
            });
          } else if (ourCoordinates) {
            this.setInitialCenterAndFilters();
          } else {
            this.fetchGeoOrIpPosition();
          }
        });
      });
    }

    componentDidUpdate(prevProps) {
      if (this.props.networkId !== prevProps.networkId) {
        this.setInitialCenterAndFilters();
      }
    }

    componentWillUnmount() {
      this.props.resetFetchStatus();
    }

    onDrawerClose() {
      this.setState({ uiOpen: false });
    }

    // NOTE: if key is undefined, then it is assumed that activeFilters
    // is an object with key/value pairs for multiple filters
    onFiltersChange(activeFilters, key) {
      this.setState({
        uiFilters: {
          ...this.state.uiFilters,
          ...(key ? { [key]: activeFilters } : { ...activeFilters }),
        },
      }, () => {
        const q = searchParams({
          ...this.state.uiFilters,
          sortBy:
            _.isEmpty(this.state.uiFilters.address) ? 'atoz' : this.state.sortBy,
        });
        this.debouncedFetchGroups({ reqParams: { q } });
      });
    }

    onSortByChange(sortBy) {
      this.setState({
        sortBy,
      }, () => {
        const q = searchParams({ ...this.state.uiFilters, sortBy });
        this.debouncedFetchGroups({ reqParams: { q } });
      });
    }

    setParentState(args, cb) {
      return this.setState(args, cb);
    }

    setInitialCenterAndFilters() {
      const { clientAddress } = this.state;
      const ourCoordinates = getOurCoordinates(this.props);
      const clientCoordinates = clientAddress.latLng;
      const centerCoordinates = clientCoordinates || ourCoordinates;

      const browseFilterParams = this.buildBrowseFiltersParams(this.props);
      const filters = buildBrowseFilters({ ...browseFilterParams, distance: '50', centerCoordinates });
      const q = searchParams(filters);

      this.setState({
        uiFilters: filters,
        uiCenter: centerCoordinates,
        uiCenterForDistance: centerCoordinates,
        sortBy: 'distance',
      }, () => {
        this.fetchGroups({ reqParams: { q }, initialRequest: true });
      });
    }

    setGeoFilters(filters) {
      const center = _.get(filters, 'address.latLng');

      if (center) {
        this.setState({
          uiCenter: center,
          uiCenterForDistance: center,
        });
      }

      this.setState({
        uiFilters: {
          ...this.state.uiFilters,
          address: filters.address,
          distance: filters.distance === 'any' ? '' : filters.distance,
          addressType: filters.addressType,
        },
      });
    }

    fetchGeoOrIpPosition() {
      this.props.fetchPosition().then(({ coords }) => {
        if (coords) {
          this.setInitialCenterAndFilters();
        } else {
          this.props.fetchIpPosition().then(() => {
            this.setInitialCenterAndFilters();
          });
        }
      });
    }

    buildBrowseFiltersParams(props = this.props) {
      const browseFilterParams = {
        contextAction: props.contextAction,
        groupAddress: _.get(props.currentUserGroup, 'addresses[0]', {}),
        groupsOptionType: props.groupsOptionType,
        networks: _.uuCompactArrayOrObject([props.permittedNetworkId ? props.permittedNetworkId : props.networkId]),
        clientAddress: this.state.clientAddress,
        serviceTypes: !_.isNil(props.serviceType) ?
          [_.get(props.serviceType, 'id')] :
          _.get(props.filters, 'serviceTypes', []),
        networkScopes: [],
        employeeAddress: _.get(props, 'employee.addresses[0]', {}),
      };

      return browseFilterParams;
    }

    fetchGroups({
      reqParams,
      initialRequest = false,
      type, // location or serviceArea
    }) {
      const {
        contact = {},
        currentUserGroup,
        employeeId,
        includePathways,
        networkId,
        serviceTypes,
        usePaymentsUserRole,
        feeScheduleProgramId,
      } = this.props;
      const groupId = _.get(currentUserGroup, 'id', '');

      const getOON = (networkScopes) => !networkScopes || !networkScopes.length || networkScopes.length === 2;
      this.setState({
        uiOpen: false,
      }, () => {
        if (!initialRequest) {
          this.debouncedTrackBrowseFilters();
        }
        this.props.clearNetworkBrowseGroup();

        const { uiFilters } = this.state;
        let isOON = null;
        if (reqParams.q.include_home_groups) {
          isOON = getOON(uiFilters.networkScopes);
        } else {
          isOON = uiFilters.groupsOptionType === 'out-of-network';
        }

        const augmentedReqParams = reqParams;

        augmentedReqParams.q.referable = {
          ...(augmentedReqParams.q.referable ? augmentedReqParams.q.referable : {}),
          ...(contact.id ? { person: contact.id } : {}),
          employee: employeeId,
        };
        if (feeScheduleProgramId) {
          augmentedReqParams.q.feeScheduleProgramId = feeScheduleProgramId;
        }

        if (includePathways) {
          augmentedReqParams.q.include_pathways = true;
        }

        if (usePaymentsUserRole) {
          augmentedReqParams.q.include_payments = true;
        }

        const paths = window.location.pathname?.split('/');
        const isOnForwardReferralFlow = paths && paths[1] === 'referrals' && paths[3] === 'send';
        const referralId = isOnForwardReferralFlow ? paths[2] : null;
        if (referralId) {
          augmentedReqParams.q.sensitive = false;
        }

        // used on My Network page
        if (_.get(this.state, 'uiFilters.includeHomeGroups')) {
          return this.props.searchMyNetworkBrowse({
            currentUserGroup,
            filters: this.state.uiFilters,
            groupId,
            networkId,
            reqParams: augmentedReqParams,
            serviceTypes,
            type,
            serviceAreaSupportForOrgsFlag: this.props.serviceAreaSupportForOrgsFlag,
            hint716SearchNetworkHubSupportPremium: this.props.hint716SearchNetworkHubSupportPremium,
          })
            .then(() => this.setState({ uiFetching: false }))
            .catch(() => this.setState({ uiFetching: false }));
        }

        // used on Create Referral page
        return this.props.searchNetworkBrowseGroups({
          browseMapView: true,
          currentUserGroup,
          filters: this.state.uiFilters,
          groupId,
          networkId,
          reqParams: augmentedReqParams,
          serviceTypes,
          isOON,
          type,
          serviceAreaSupportForOrgsFlag: this.props.serviceAreaSupportForOrgsFlag,
          hint716SearchNetworkHubSupportPremium: this.props.hint716SearchNetworkHubSupportPremium,
        })
          .then(() => this.setState({ uiFetching: false }))
          .catch(() => this.setState({ uiFetching: false }));
      });
    }

    trackBrowseFilters() {
      const {
        serviceTypes, network, geography, languages,
      } = this.props;

      callOrLog(() => {
        const browseFilters = Serializer.buildFilters({
          filters: this.state.uiFilters,
          geography,
          langs: languages,
          network,
          services: serviceTypes,
        });

        this.context.eventTracker(BROWSE.browseFilterApplied, browseFilters);
      });
    }

    appendGroups({ type = 'location' }) {
      if (this.state.uiFetching) {
        return true;
      }
      let paging;

      if (type === 'serviceArea') {
        paging = this.props.pagingServiceArea;
      } else {
        paging = this.props.paging;
      }
      const { uiFilters, sortBy } = this.state;
      const page = _.get(paging, 'next_page', 1);
      const params = {
        page,
        q: searchParams({ ...uiFilters, sortBy }),
      };

      return this.setState({
        uiFetching: true,
      }, () => {
        this.fetchGroups({ reqParams: params, type });
      });
    }

    // eslint-disable-next-line react/sort-comp
    handleCenterChange(newCenter) {
      const { uiCenter } = this.state;

      if (shouldSetNewCenter(uiCenter, newCenter)) {
        this.setState({
          uiCenter: { lat: newCenter.lat(), lng: newCenter.lng() },
        });
      }
    }

    handleSearchAreaChange() {
      this.setState({
        uiShowSearchButton: true,
      });
    }

    handleSearchAreaClick(newCenter, formattedAddress) {
      const center = newCenter.toJSON();
      const filters = {
        ...this.state.uiFilters,
        address: {
          address: formattedAddress,
          id: 'custom-search',
          latLng: center,
        },
        addressType: { label: 'Other', value: 'other' },
        distance: '',
      };

      this.setState({
        uiFilters: filters,
        uiCenter: center,
        uiCenterForDistance: center,
        uiShowSearchButton: false,
      }, () => {
        const { uiFilters, sortBy } = this.state;
        const q = searchParams({ ...uiFilters, sortBy });

        this.fetchGroups({ reqParams: { q } });
      });
    }

    childCall(type, ...payload) {
      return this[type](...payload);
    }

    render() {
      if (_.isEmpty(this.state.uiFilters)) {
        return <Spinner />;
      }

      const flattenedServiceTypes = flattenServiceTypes(this.props.serviceTypes);
      const selectedServices = _.reduce(_.get(this.state, 'uiFilters.serviceTypes', []), (acc, stId) => {
        const st = _.find(flattenedServiceTypes, { id: stId });
        return st ? [...acc, st] : acc;
      }, []);

      return (
        <ComposedComponent
          buildBrowseFiltersParams={this.buildBrowseFiltersParams}
          callParent={this.childCall}
          parentState={this.state}
          selectedServices={selectedServices}
          setParentState={this.setParentState}
          {...this.props}
        />
      );
    }
  }

  Browse.propTypes = {
    clearNetworkBrowseGroup: PropTypes.func.isRequired,
    contact: PropTypes.object,
    contextAction: PropTypes.oneOf(['network', 'create', 'send']).isRequired,
    currentUserGroup: PropTypes.object,
    employeeId: PropTypes.string.isRequired,
    feeScheduleProgramId: PropTypes.string,
    fetchCorePrograms: PropTypes.func.isRequired,
    fetchPosition: PropTypes.func.isRequired,
    fetchIpPosition: PropTypes.func.isRequired,
    geography: PropTypes.object,
    geoCoordinates: PropTypes.object.isRequired,
    ipCoordinates: PropTypes.object.isRequired,
    languages: PropTypes.array,
    network: PropTypes.object,
    networkId: PropTypes.string.isRequired,
    permittedNetworkId: PropTypes.string,
    paging: PropTypes.object,
    pagingServiceArea: PropTypes.object,
    scripts: PropTypes.arrayOf(PropTypes.string),
    searchMyNetworkBrowse: PropTypes.func.isRequired,
    searchNetworkBrowseGroups: PropTypes.func.isRequired,
    selectedGroup: PropTypes.object,
    serviceTypes: PropTypes.array.isRequired,
    resetFetchStatus: PropTypes.func.isRequired,
    serviceAreaSupportForOrgsFlag: PropTypes.bool,
    hint716SearchNetworkHubSupportPremium: PropTypes.bool,
    stateDisplayName: PropTypes.string.isRequired,
    usePaymentsUserRole: PropTypes.bool.isRequired,
    includePathways: PropTypes.bool,
  };

  Browse.defaultProps = {
    selectedGroup: {},
    feeScheduleProgramId: null,
    permittedNetworkId: '',
    serviceAreaSupportForOrgsFlag: false,
    includePathways: false,
    hint716SearchNetworkHubSupportPremium: false,
    contact: {},
    currentUserGroup: {},
    geography: {},
    languages: [],
    network: {},
    paging: {},
    pagingServiceArea: {},
    scripts: [],
  };

  Browse.contextTypes = {
    eventTracker: PropTypes.func.isRequired,
  };

  function mapStateToProps(state, ownProps) {
    const geoCoordinates = _.wget(state, 'session.position.geoCoordinates', {});
    const ipCoordinates = _.wget(state, 'session.position.ipCoordinates', {});
    const selectedGroup = _.get(state, 'browse.currentGroup');
    const isFetching = _.get(state, 'browse.isFetching', false);
    const isFetchingServiceArea = _.get(state, 'browse.isFetchingServiceArea', false);
    const networkId = ownProps.networkId || _.get(state, 'session.networkId');
    // CORE-WORK - Need to resolve for Browse work
    const network = _.find(_.get(state, 'user.networks', []), { id: networkId }) || {};
    const paging = _.get(state, 'browse.paging', {});
    const pagingServiceArea = _.get(state, 'browse.pagingServiceArea', {});
    const geography = _.get(state, 'browse.geography', {});
    const languages = _.get(state, 'session.enums.base.languages');
    const serviceTypes = _.get(state, 'session.globals.service_types');
    const stateDisplayName = _.get(state, 'browse.stateDisplayName');
    const employee = getEmployee({ state });
    const employeeId = _.get(state, 'globalState.currentEmployee.id');
    const usePaymentsUserRole = hasPaymentsUserAccess(state);
    const includePathways = includePathwaysServices(state);

    return {
      employee,
      employeeId,
      geoCoordinates,
      geography,
      ipCoordinates,
      isFetching,
      isFetchingServiceArea,
      languages,
      network,
      paging,
      pagingServiceArea,
      selectedGroup,
      serviceAreaSupportForOrgsFlag: serviceAreaSupportForOrgs(state),
      hint716SearchNetworkHubSupportPremium: hint716SearchNetworkHubSupportPremiumSelector(state),
      serviceTypes,
      stateDisplayName,
      usePaymentsUserRole,
      includePathways,
    };
  }

  return connect(mapStateToProps, {
    clearNetworkBrowseGroup,
    clearNetworkBrowseGroupPrograms,
    clearNetworkBrowseGroups,
    fetchPosition,
    fetchIpPosition,
    searchMyNetworkBrowse,
    searchNetworkBrowseGroups,
    resetFetchStatus,
    fetchCorePrograms,
  })(Browse);
};

export default browseHOC;
