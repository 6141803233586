import React from 'react';
import { PropTypes } from 'prop-types';
import {
  ACCEPTED,
  IN_DISPUTE,
  NEEDS_ATTENTION,
  IN_QUEUE_TO_PAYER,
  REJECTED,
  REJECTED_BY_NETWORK_LEAD,
  REJECTED_BY_PAYER,
  SUBMITTED_TO_NETWORK_LEAD,
  TRANSMITTED_TO_PAYER,
  PAID,
 AWAITING_TRANSMISSION,
} from 'src/pages/invoices/constants';
import { StatusBadge } from 'src/common/badges';

const commonBadgeProps = {
  submitted_to_payer: {
    className: 'bg-medium-yellow text-text-blue',
    label: IN_QUEUE_TO_PAYER,
  },
  transmitted_to_payer: {
    className: 'bg-dark-border-blue text-text-blue',
    label: TRANSMITTED_TO_PAYER,
  },
  accepted_by_payer: {
    className: 'bg-dark-green text-white',
    label: ACCEPTED,
  },
  rejected_by_payer: {
    className: 'bg-red text-white',
    label: REJECTED_BY_PAYER,
  },
  paid: {
    className: 'bg-light-green text-dark-green',
    label: PAID,
  },
  under_dispute: {
    className: 'bg-purple text-white',
    label: IN_DISPUTE,
  },
};

const cboAdminBadgeProps = {
  ...commonBadgeProps,
  submitted_contracted_service_note: {
    className: 'bg-action-blue text-white',
    label: NEEDS_ATTENTION,
  },
  rejected_by_cbo_admin: {
    className: 'bg-red text-white',
    label: REJECTED,
  },
  submitted_to_network_lead: {
    className: 'bg-dark-border-blue text-text-blue',
    label: SUBMITTED_TO_NETWORK_LEAD,
  },
  rejected_by_network_lead: {
    className: 'bg-red text-white',
    label: REJECTED_BY_NETWORK_LEAD,
  },
};

const networkLeadBadgeProps = {
  ...commonBadgeProps,
  submitted_to_network_lead: {
    className: 'bg-action-blue text-white',
    label: NEEDS_ATTENTION,
  },
  rejected_by_network_lead: {
    className: 'bg-red text-white',
    label: REJECTED,
  },
};

const payerBadgeProps = {
  ...commonBadgeProps,
  submitted_to_payer: {
    className: 'bg-medium-yellow text-text-blue',
    label: AWAITING_TRANSMISSION,
  },
  transmitted_to_payer: {
    className: 'bg-action-blue text-white',
    label: NEEDS_ATTENTION,
  },
  rejected_by_payer: {
    className: 'bg-red text-white',
    label: REJECTED,
  },
};

const InvoiceStatusIndicator = ({ isNetworkLead, invoiceStatus, isPayer }) => {
  const badgeProps = isNetworkLead ? networkLeadBadgeProps : cboAdminBadgeProps;
  const validStatuses = isPayer ? Object.keys(payerBadgeProps) : Object.keys(badgeProps);
  if (!validStatuses.includes(invoiceStatus)) return '';
  const { className, label } = isPayer ? payerBadgeProps[invoiceStatus] : badgeProps[invoiceStatus];
  return <StatusBadge className={className} label={label} />;
};

InvoiceStatusIndicator.propTypes = {
  isNetworkLead: PropTypes.bool.isRequired,
  invoiceStatus: PropTypes.string.isRequired,
  isPayer: PropTypes.bool,
};

InvoiceStatusIndicator.defaultProps = {
  isPayer: false,
};

export default InvoiceStatusIndicator;
