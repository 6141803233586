import React from 'react';

function NoReferralsView() {
  return (
    <div className="no-referrals">
      <p className="no-referrals-content">
        No referrals yet
      </p>
    </div>
  );
}

export default NoReferralsView;
