import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty, orderBy } from 'lodash';
import DetailDescription from 'common/display/SingleItemDetails/DetailDescription';
import DetailUnorderedList from 'common/display/SingleItemDetails/DetailUnorderedList';
import DetailDefinitionList from 'common/display/SingleItemDetails/DetailDefinitionList';
import ReferralAuthorizationRequestSummary from './ReferralAuthorizationRequestSummary';

const ReferralServiceDetails = ({ documents, referral, isProgramBasedSearch }) => (
  <div className="referral-service-details">
    <div className="service-type-item">
      <div className="row service-type-section mb-one">
        <div className="col-md-12">
          <DetailDescription
            title="Referral Description"
            description={referral.notes}
          />
        </div>
      </div>

      <div className="row service-type-section">
        <div className="col-md-6">
          <DetailUnorderedList
            displayProp="name"
            list={referral.groups}
            title={isProgramBasedSearch ? 'Recipient Programs/Organizations' : 'Recipient Organizations/Programs'}
          />
        </div>

        <div className="col-md-6">
          <DetailDefinitionList
            id="recipient-network-detail-list"
            title="Recipient Network"
            value={referral.referredToNetwork.name}
          />
        </div>
      </div>

      { !isEmpty(referral.authorizationRequest) && (
        <div className="row authorization-request-section">
          <div className="col-md-12">
            <ReferralAuthorizationRequestSummary
              authorizationRequest={referral.authorizationRequest}
            />
          </div>
        </div>
      )}

      <div className="row service-type-section">
        <div className="col-md-12">
          <DetailUnorderedList
            displayProp="title"
            list={orderBy(documents, 'title')}
            title="Attached Documents"
          />
        </div>
      </div>
    </div>
  </div>
);

ReferralServiceDetails.propTypes = {
  referral: PropTypes.shape({
    groups: PropTypes.array.isRequired,
    referredByNetwork: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    referredToNetwork: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    notes: PropTypes.string.isRequired,
    authorizationRequest: PropTypes.object.isRequired,
  }).isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  isProgramBasedSearch: PropTypes.bool.isRequired,
};

export default ReferralServiceDetails;
