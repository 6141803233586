import React from 'react';
import PropTypes from 'prop-types';

function Header(props) {
  const {
    header, mainHeader, subHeader,
  } = props;
  return (
    <header className="contact-header">
      <h2>{header}</h2>
      {
        (mainHeader) ? (<h3>{mainHeader}</h3>) : null
      }
      <span className="help-block">{subHeader}</span>
    </header>
  );
}

Header.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  mainHeader: PropTypes.string,
};

Header.defaultProps = {
  mainHeader: null,
};

export default Header;
