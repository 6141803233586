import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  Popover,
} from '@unite-us/ui';
import { EmptyTable } from 'common/tables';
import { DynamicTableRow, UnauthorizedMessage } from './components';
import getColumnStyles from './styles/getColumnStyles';
import './DynamicTable.scss';

class DynamicTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popoverOpen: false,
    };

    this.onCellClick = this.onCellClick.bind(this);
    this.closePopover = this.closePopover.bind(this);
    this.openPopover = this.openPopover.bind(this);
  }

  onCellClick(row, column, event) {
    const { columnLabels, dataSet } = this.props;
    const colIndex = column - 1;
    const type = _.get(columnLabels, `[${colIndex}].type`);

    // If column is an action column, don't register this cell click.
    if (!['takeAction', 'download'].includes(type)) {
      event.preventDefault();
      const clickedDataObj = dataSet[row];

      if (!_.wget(clickedDataObj, 'permissions.can_view.authorization', false)) {
        if (this.state.popoverOpen) {
          this.closePopover();
        } else {
          this.openPopover(clickedDataObj, row);
        }
      } else if (_.isFunction(this.props.onCellClick)) {
        this.props.onCellClick(row, column);
      }
    }
  }

  closePopover() {
    this.setState({
      popoverOpen: false,
      popoverMessage: '',
      row: null,
    });
  }

  openPopover(clickedDataObj, row) {
    this.setState({
      popoverOpen: true,
      popoverMessage: <UnauthorizedMessage clickedDataObj={clickedDataObj} />,
      row,
    });
  }

  render() {
    const {
      columnLabels,
      dataSet,
      emptyMessage,
      hideRowIcon,
      id,
      rowIcon,
    } = this.props;

    const { popoverMessage } = this.state;

    if (_.isEmpty(dataSet)) {
      return <EmptyTable message={emptyMessage} />;
    }

    return (
      <div className="dynamic-table" id={id}>
        <Table onCellClick={this.onCellClick}>
          <TableHeader
            adjustForCheckbox={false}
            displaySelectAll={false}
          >
            <TableRow id={`${id}-header-row`}>
              {!hideRowIcon && <TableHeaderColumn />}
              {
                _.map(columnLabels, (column = {}, i) => (
                  <TableHeaderColumn
                    key={`${column.label}-${i}`}
                    style={getColumnStyles(column)}
                  >
                    {column.label}
                  </TableHeaderColumn>
                ))
              }
            </TableRow>
          </TableHeader>

          <TableBody
            displayRowCheckbox={false}
            showRowHover
          >
            {
              _.map(dataSet, (data, i) => (
                <DynamicTableRow
                  columnLabels={columnLabels}
                  data={data}
                  hideRowIcon={hideRowIcon}
                  id={`${id}-row-${i}`}
                  key={`dynamic-table-row-${i}`}
                  rowIcon={rowIcon}
                />
              ))
            }
          </TableBody>
        </Table>
        {
          _.isNumber(this.state.row) && (
            <Popover
              className="dynamic-table-popover"
              hideArrow
              target={`${id}-row-${this.state.row}`}
              placement="bottom-start"
              isOpen={this.state.popoverOpen}
              toggle={this.closePopover}
              body={popoverMessage}
            />
          )
        }
      </div>
    );
  }
}

DynamicTable.propTypes = {
  columnLabels: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })),
  dataSet: PropTypes.array,
  emptyMessage: PropTypes.string,
  hideRowIcon: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onCellClick: PropTypes.func,
  rowIcon: PropTypes.func,
};

DynamicTable.defaultProps = {
  columnLabels: [],
  dataSet: [],
  emptyMessage: '',
  hideRowIcon: false,
  onCellClick: _.noop,
  rowIcon: _.noop,
};

export default DynamicTable;
