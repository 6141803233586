import { FETCH_CONFIRMATION_DETAILS } from 'actions';

export default function accountConfirmationReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_CONFIRMATION_DETAILS: {
      return action.payload.data.data;
    }

    default:
      return state;
  }
}
