import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { FileThumbnail } from '@unite-us/client-utils';
import UploadedDocumentModal from './UploadedDocumentModal';
import UploadedDocumentPopover from './UploadedDocumentPopover';
import ContactDocumentCardMenu from './ContactDocumentCardMenu';
import './ContactDocumentCard.scss';

class ContactDocumentCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false,
      documentClicked: false,
    };

    this.onRenameClick = this.onRenameClick.bind(this);
    this.openPdf = this.openPdf.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  handleMouseOver() {
    this.setState({ hover: true });
  }

  handleMouseOut() {
    this.setState({ hover: false });
  }

  onRenameClick() {
    this.props.onRenameClick(this.props.doc);
  }

  openPdf() {
    this.setState({ documentClicked: true }, () => {
      this.uploadedDocumentModal.dialog.openDialog();
    });
  }

  render() {
    const { doc, showOptions, id } = this.props;
    const { documentClicked, hover } = this.state;
    const contentType = _.get(doc, 'content_type', '');

    const thumbnail = _.get(doc, 'url', '');
    const isImage = contentType.search('image') > -1;
    const isPdf = contentType.search('pdf') > -1;

    return (
      <div
        className="contact-document-card"
        onMouseLeave={this.handleMouseLeave}
      >
        <div
          className="contact-document-card__uploaded-document"
          id={id}
        >
          <div className="contact-document-card__image">
            {
              isImage && !_.isEmpty(thumbnail) && (
                <div className="contact-document-card__thumbnail">
                  <img
                    className="contact-document-card__image-src"
                    src={thumbnail}
                    role="presentation"
                    onClick={this.openPdf}
                    onMouseOver={this.handleMouseOver}
                    onFocus={this.handleMouseOver}
                    onMouseOut={this.handleMouseOut}
                    onBlur={this.handleMouseOut}
                    alt="No Preview Available"
                  />
                </div>
              )
            }
            {
              isPdf && !_.isEmpty(doc.url) && (
              <FileThumbnail
                color="#ccc"
                contentType={doc.content_type}
                onThumbnailClick={this.openPdf}
                size={145}
              />
              )
            }
            {
              !isImage && !isPdf && (
                <div className="uploaded-document__thumbnail">
                  <FileThumbnail
                    color="#ccc"
                    contentType={doc.content_type}
                    onThumbnailClick={this.openPdf}
                    size={145}
                  />
                </div>
              )
            }
            <UploadedDocumentPopover
              doc={doc}
              hover={hover}
              openPdf={this.openPdf}
            />
          </div>

          <ContactDocumentCardMenu
            doc={doc}
            onRemoveDocument={this.props.onRemoveDocument}
            onRenameClick={this.onRenameClick}
            openWindow={this.openWindow}
            showOptions={showOptions}
          />

          <UploadedDocumentModal
            ref={(el) => { this.uploadedDocumentModal = el; }}
            doc={doc}
            documentClicked={documentClicked}
          />
        </div>
      </div>
    );
  }
}

ContactDocumentCard.propTypes = {
  doc: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onRemoveDocument: PropTypes.func,
  onRenameClick: PropTypes.func.isRequired,
  showOptions: PropTypes.bool.isRequired,
};

ContactDocumentCard.defaultProps = {
  styles: {
    backgroundImage: 'url(http://placehold.it/150x250)',
  },
  showOptions: true,
};

export default ContactDocumentCard;
