import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Expandable, Icon } from '@unite-us/ui';
import { get, noop, isEmpty } from 'lodash';

class ReferralServiceMinimized extends Component {
  constructor(props) {
    super(props);

    this.onEdit = this.onEdit.bind(this);
    this.onEditKeyPress = this.onEditKeyPress.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDeleteKeyPress = this.onDeleteKeyPress.bind(this);
  }

  onEdit(event) {
    this.props.onEdit(event, this.props.index);
  }

  onEditKeyPress(event = {}) {
    if ('stopPropagation' in event) event.stopPropagation();
    if (event.key === 'Enter') {
      this.onEdit(event);
    }
  }

  onDelete(event = {}) {
    if ('stopPropagation' in event) event.stopPropagation();
    this.props.onDelete(event, this.props.index);
  }

  onDeleteKeyPress(event = {}) {
    if (event.key === 'Enter') {
      this.onDelete(event);
    }
  }

  render() {
    const {
      hideEdit,
      index,
      initiallyCollapsed,
      errorCount,
      referral,
    } = this.props;

    const isOONCase = get(referral, 'isOONCase', false);

    const referralTitle = (values) => {
      const serviceType = get(values, 'serviceType.name', '');
      const title = isOONCase ? 'New Out of Network Case' : 'New Referral';
      if (!isEmpty(serviceType)) {
        return `${title}: ${serviceType}`;
      }
      return title;
    };

    return (
      <div className="referral-service-minimized">
        <Expandable
          className="referral-service-minimized__expandable"
          header={(
            <span className="referral-service-minimized__header">
              <span className="referral-service-minimized__header-text">
                {referralTitle(referral)}
                {errorCount > 0 ? (
                  <span className="referral-service-minimized__header-action-list--error-display ml-two">
                    {`${errorCount} ${errorCount === 1 ? 'Error' : 'Errors'}`}
                  </span>
                ) : null}
              </span>
              <span className="referral-service-minimized__header-action-list">
                {hideEdit ? null : (
                  <span
                    role="button"
                    className="referral-service-minimized__header-action-list--service-type-action"
                    onClick={this.onEdit}
                    onKeyPress={this.onEditKeyPress}
                    tabIndex={0}
                  >
                    <Icon icon="IconPencil" size={16} />
                  </span>
                )}
                <span
                  role="button"
                  className="referral-service-minimized__header-action-list--service-type-action"
                  onClick={this.onDelete}
                  onKeyPress={this.onDeleteKeyPress}
                  tabIndex={0}
                >
                  <Icon icon="IconTrash" size={16} />
                </span>
              </span>
            </span>
          )}
          id={`expandable-referrel-${index}`}
          initiallyCollapsed={initiallyCollapsed}
        >
          {this.props.children}
        </Expandable>
      </div>
    );
  }
}

ReferralServiceMinimized.propTypes = {
  children: PropTypes.node,
  errorCount: PropTypes.number,
  hideEdit: PropTypes.bool,
  index: PropTypes.number.isRequired,
  initiallyCollapsed: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  referral: PropTypes.shape({
    serviceType: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

ReferralServiceMinimized.defaultProps = {
  children: undefined,
  errorCount: 0,
  hideEdit: false,
  onDelete: noop,
  onEdit: noop,
};

export default ReferralServiceMinimized;
