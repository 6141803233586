import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const feeScheduleStatusContext = createContext();

export const FeeScheduleStatusContextProvider = ({ children, isNavigatingFeeSchedules, fspId }) => {
  const [feeScheduleStatus, setFeeScheduleStatus] = useState('active');
  const [feeScheduleProgramStatus, setFeeScheduleProgramStatus] = useState('active');

  useEffect(() => {
    if (!isNavigatingFeeSchedules) {
      setFeeScheduleStatus('active');
    }
  }, [isNavigatingFeeSchedules]);

  useEffect(() => {
    setFeeScheduleProgramStatus('active');
  }, [fspId]);

  return (
    <feeScheduleStatusContext.Provider
      value={{
        feeScheduleStatus,
        setFeeScheduleStatus,
        feeScheduleProgramStatus,
        setFeeScheduleProgramStatus,
      }}
    >
      {children}
    </feeScheduleStatusContext.Provider>
  );
};

FeeScheduleStatusContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isNavigatingFeeSchedules: PropTypes.bool.isRequired,
  fspId: PropTypes.string.isRequired,
};

export const useFeeScheduleStatusContext = () => useContext(feeScheduleStatusContext);
