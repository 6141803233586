import React, { useContext } from 'react';
import { TrackerContext } from '@unite-us/client-utils';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import ButtonFormSubmit from 'src/components/Organization/components/ButtonFormSubmit';
import { ORG_SETTINGS } from 'src/common/utils/EventTracker/utils/eventConstants';
import generatePhonesWithExtension from 'src/common/form/Profile/utils/generatePhonesWithExtension';
import UserSettingsForm from './UserSettingsForm';
import UserInfoForm from './UserInfoForm';

export const UserForm = (props) => {
  const eventTracker = useContext(TrackerContext);

  const { initialValues, onSubmit } = props;
  initialValues.phone_numbers = generatePhonesWithExtension(initialValues.phone_numbers);

  const onSubmitForm = (values) => {
    eventTracker(ORG_SETTINGS.savedUser);
    onSubmit({ ...values });
  };

  const isPaymentsUser = initialValues.user_feature_roles?.filter((role) => role.key === 'payments').length > 0;

  return (
    <Form
      onSubmit={onSubmitForm}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={{
        ...initialValues,
      }}
      validate={(values) => {
        const errors = {};

        if (!values.user_programs?.length) {
          errors.user_programs = 'Required';
        }
        if (!values.user_primary_role) {
          errors.user_primary_role = 'Required';
        }
        if (values.line_2 && !values.city) {
          errors.line_2 = 'Address/location required';
        }
        if ((values.state && !values.city)) {
          // We need an error state outside the LocationAddressField component.
          errors.city = 'hidden error message';
        }
        return errors;
      }}
      render={({
        handleSubmit, submitting, valid, values,
      }) => {
        // NOTE: calculating pristine here instead of relying on react-final-form pristine value
        // because the later one seems to have a limitation when fields are removed from the form,
        // it does not seem to account for removed fields in its pristine calculation
        const pristine = isEqual(initialValues, values);
        return (
          <form onSubmit={handleSubmit}>
            <UserInfoForm withPhoneExtensionField />
            <UserSettingsForm isPaymentsUser={isPaymentsUser} />
            <ButtonFormSubmit
              isSaveDisabled={submitting || pristine || !valid}
            />
          </form>
        );
      }}
    />
  );
};

UserForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

UserForm.defaultProps = {
  initialValues: {},
};

export default UserForm;
