import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  Icon,
} from '@unite-us/ui';
import _ from 'lodash';
import removeNotification from '../actions/RemoveNotification';

export class Notification extends Component {
  constructor(props) {
    super(props);
    this.iconName = this.iconName.bind(this);
    this.removeNotification = this.removeNotification.bind(this);
  }

  componentDidMount() {
    this.addNotificationTimeout();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.notifications !== this.props.notifications) {
      this.addNotificationTimeout();
    }
  }

  addNotificationTimeout() {
    // eslint-disable-next-line no-unused-expressions
    this.props?.notifications?.forEach((notification) => {
      const timeout = notification.timeOut || 5000;
      setTimeout(() => this.props.removeNotification(notification), timeout);
    });
  }

  removeNotification() {
    _.each(this.props.notifications, (notification) => {
     this.props.removeNotification(notification);
    });
  }

  notifClass() {
    const { type } = this.props;
    const notificationsExist = !_.isEmpty(this.props.notifications);

    return classNames({
      notification: true,
      success: notificationsExist && type === 'success',
      error: notificationsExist && type === 'error',
      warning: notificationsExist && type === 'warning',
      information: notificationsExist && type === 'information',
    });
  }

  iconName() {
    const { styles, type, notifications } = this.props;

    const newStyles = notifications.length <= 1 ? styles : {
      ...styles,
      icon: {
        ...styles.icon,
        svg: {
          ...styles.icon.svg, marginTop: '-20px',
        },
      },
    };

    const newStylesIconSvg = _.uuPick(newStyles.icon, ['svg']);
    if (type === 'error') {
      return (<Icon icon="IconTimesCircle" style={_.merge(newStylesIconSvg, newStyles.error)} />);
    }
    if (type === 'success') {
      return (<Icon icon="IconCheckCircle" style={_.merge(newStylesIconSvg, newStyles.success)} />);
    }
    if (type === 'warning') {
      return (<Icon icon="IconExclamationCircle" style={_.merge(newStylesIconSvg, newStyles.warning)} />);
    }
    if (type === 'information') {
      return (<Icon icon="IconInfoCircle" style={_.merge(newStylesIconSvg, newStyles.information)} />);
    }

    return (<Icon icon="IconInfoCircle" style={styles.icon} />);
  }

  render() {
    const { notifications } = this.props;

    if (_.isEmpty(notifications)) {
      return null;
    }

    const notificationListClass = () => classNames({
      'list-unstyled': notifications.length <= 1,
    });

    return (
      <div className={this.notifClass()}>
        {this.iconName()}
        <ul className={`${notificationListClass()} list-unstyled`}>
          {
            _.map(notifications, (notification) => (
              <li key={notification.uuid}>{notification.text}</li>
            ))
          }
        </ul>
        <a
          role="button"
          className="close"
          onClick={this.removeNotification}
        >
          <Icon icon="IconTimes" style={this.props.styles.close} />
        </a>
      </div>
    );
  }
}

Notification.propTypes = {
  removeNotification: PropTypes.func.isRequired,
  styles: PropTypes.object,
  notifications: PropTypes.array,
  type: PropTypes.string.isRequired,
};

Notification.defaultProps = {
  styles: {
    icon: {
      svg: {
        position: 'absolute',
        height: '26px',
        width: '26px',
        top: '50%',
        marginTop: '-13px',
        left: '15px',
        fill: '#3E4EB8',
      },
    },
    error: {
      svg: {
        fill: '#E53935',
      },
    },
    success: {
      svg: {
        fill: '#47B04B',
      },
    },
    warning: {
      svg: {
        fill: '#FF9900',
      },
    },
    information: {
      svg: {
        fill: '#4571BA',
      },
    },
    close: {
      svg: {
        height: '20px',
        width: '20px',
      },
    },
  },
};

export default connect(null, { removeNotification })(Notification);
