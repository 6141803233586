import React from 'react';
import pluralize from 'pluralize';
import { DollarAmount } from 'common/display/Money';

const formatSpend = (spend = 0, unit, isCostBased) => (
  isCostBased ? (
    <DollarAmount convertCents value={spend} />
  ) : (
    `${spend} ${pluralize(unit || '', spend)}`
  )
);

export default formatSpend;
