import { useFind } from 'src/api/APIHooks';
import { get } from 'lodash';

const usePlaceOfService = (placeOfServiceId = '', showSavedClaimCodes = false) => {
  const { isFetching, data } = useFind(
    'place_of_service',
    {
      id: placeOfServiceId,
    },
    {
      queryConfig: {
        enabled: showSavedClaimCodes && !!placeOfServiceId,
      },
    },
  );
  const placeOfService = get(data, 'data.data', []);

  return isFetching ? [] : placeOfService;
};

export default usePlaceOfService;
