import { reduce } from 'lodash';

/**
 * @param {array} forms - list of objects w/ id and name
 * @param {object} reduxForm7 - reduxform7 state
 * @param {object} state - redux state
*/
const returnInvalidFormIds = (forms, reduxForm7, state) => (
  reduce(forms, (acc, form) => {
    const invalid = reduxForm7.isInvalid(form.id, (s) => s.form7)(state);

    const submitFailed = reduxForm7.hasSubmitFailed(form.id, (s) => s.form7)(state);

    if (invalid && submitFailed) {
      return [...acc, form.id];
    }

    return acc;
  }, [])
);

export default returnInvalidFormIds;
