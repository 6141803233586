import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from '@unite-us/ui';
import { validateReduxForm } from 'common/form';
import _ from 'lodash';
import { OverlaySpinner } from 'common/spinners';
import ProfileEmailFields from 'common/form/Profile/ProfileEmailFields';
import { EDIT_EMAIL_ADDRESSES } from 'common/display/Profile/constants/form';
import { generateUUID } from 'common/utils/utils';
import callOrLog from 'common/utils/callOrLog';
import { FACESHEET } from 'common/utils/EventTracker/utils/eventConstants';
import {
  cleanEmailValues,
} from 'common/form/Profile/utils/cleanContactValues';
import { emailsInitialValues } from './utils';
import './stylesheets/editEmailAddresses.scss';

export class EditEmailAddresses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deletingEmail: false,
      newIds: [],
    };

    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.addField = this.addField.bind(this);
    this.removeField = this.removeField.bind(this);
    this.resetAndCloseModal = this.resetAndCloseModal.bind(this);
    this.onPrimaryChange = this.onPrimaryChange.bind(this);
    this.removeUnsavedEmails = this.removeUnsavedEmails.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.modalIsOpen && !nextProps.modalIsOpen) {
      this.removeUnsavedEmails();
    }
  }

  onCancel() {
    this.resetAndCloseModal();
  }

  onSubmit(values) {
    const emails = values.emails.map(cleanEmailValues);
    callOrLog(() => this.context.eventTracker(FACESHEET.emailUpdated));
    return this.props.onSave(emails)
      .then(this.resetAndCloseModal);
  }

  onPrimaryChange(email) {
    _.each(this.props.fields.emails, (field) => {
      if (field !== email) {
        field.is_primary.onChange(false);
      }
    });
    email.is_primary.onChange(true);
  }

  addField() {
    const { emails } = this.props.fields;
    const id = generateUUID();
    emails.addField({ id });
    this.setState({
      newIds: [...this.state.newIds, id],
    }, () => window.dispatchEvent(new Event('resize')));
  }

  removeField(idToRemove) {
    const { fields: { emails } } = this.props;
    const index = _.findIndex(emails, (e) => e.id.value === idToRemove);
    emails.removeField(index);
    this.setState({
      newIds: _.filter(this.state.newIds, (id) => id !== idToRemove),
    });
  }

  removeUnsavedEmails() {
    const { emails } = this.props.fields;
    _.eachRight(emails, (email, i) => {
      if (!email.email_address.initialValue) {
        emails.removeField(i);
      }
    });
  }

  resetAndCloseModal() {
    this.removeUnsavedEmails();
    this.props.resetForm();
    this.props.closeModal();
  }

  render() {
    const {
      fields: { emails },
      handleSubmit,
      hideCommunicationPreferences,
      registerField,
      styles,
      submitting,
    } = this.props;
    const { deletingEmail } = this.state;

    const emailFormFields = emails.map((email, index) => (
      <div
        id={`email-${index}`}
        key={`${email.id.initialValue}-${index}`}
        style={index !== emails.length - 1 ? styles.inlineEmailContainer : {}}
      >
        <ProfileEmailFields
          fields={email}
          hideCommunicationPreferences={hideCommunicationPreferences}
          onPrimaryChange={this.onPrimaryChange}
          registerField={registerField}
          onRemove={this.removeField}
        />
      </div>
    ));

    return (
      <form
        className="edit-email-addresses content-with-actions"
        onSubmit={handleSubmit(this.onSubmit)}
      >
        <OverlaySpinner text="Saving..." show={submitting || deletingEmail} />
        <div className="content-container">
          {emailFormFields}
        </div>
        <div className="actions">
          <div className="row">
            <div className="col-sm-6 text-left edit-email-addresses__add-email">
              <button
                className="text-sm"
                id="add-email-link"
                type="button"
                onClick={this.addField}
              >
                + Add Email
              </button>
            </div>
            <div className="col-sm-6">
              <span className="action-item">
                <Button
                  id="edit-email-cancel-btn"
                  onClick={this.onCancel}
                  disabled={submitting}
                  label="Cancel"
                  secondary
                />
              </span>
              <span className="action-item">
                <Button
                  id="edit-email-save-btn"
                  onClick={handleSubmit(this.onSubmit)}
                  disabled={submitting}
                  label="Save"
                  primary
                />
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

EditEmailAddresses.propTypes = {
  closeModal: PropTypes.func,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hideCommunicationPreferences: PropTypes.bool,
  modalIsOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  registerField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    inlineEmailContainer: PropTypes.object,
  }),
  submitting: PropTypes.bool.isRequired,
};

EditEmailAddresses.defaultProps = {
  closeModal: _.noop,
  hideCommunicationPreferences: false,
  onSave: _.noop,
  styles: {
    inlineEmailContainer: {
      borderBottom: '1px solid #EBECED',
      marginBottom: '20px',
      paddingBottom: '10px',
    },
  },
};

EditEmailAddresses.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { emails } = ownProps;
  return {
    groupId: state.session.groupId,
    initialValues: { emails: emailsInitialValues(emails) },
  };
}

const fields = [
  'emails[].acceptable_communication_types.message',
  'emails[].acceptable_communication_types.notification',
  'emails[].email_address',
  'emails[].id',
  'emails[].is_primary',
  'emails[].message',
  'emails[].notifications',
];

// accessibility improvement: focus on first field with error
const onSubmitFail = () => {
  setTimeout(() => {
    const errs = document.getElementsByClassName('ui-form-field--has-error');
    const err = _.first(errs);
    const focusableErr = _.first(err?.getElementsByTagName('input'));
    return focusableErr?.focus();
  }, 300);
};

export default validateReduxForm({
  onSubmitFail,
  form: EDIT_EMAIL_ADDRESSES,
  fields,
}, mapStateToProps)(EditEmailAddresses);
