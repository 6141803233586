import {
  useFind, useFindRecord,
  useUpdateRecord, useCreateRecord,
  useInvalidateQueries, useDeleteRecord,
} from 'src/api/APIHooks';
import { defaultQueryConfig, defaultMutationConfig } from './apiHookOptions';

export const useLocations = ({ providerId }) => useFind(
  'location',
  {
    provider: providerId,
  },
  {
    queryConfig: defaultQueryConfig,
  },
);

export const useFindLocation = ({ locationId }) => useFindRecord('location', locationId, {
  queryConfig: defaultQueryConfig,
});

export const useCreateLocation = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useCreateRecord(
    'location',
    {
      mutationConfig: {
        onSuccess: () => {
          invalidateQueries('location');
          invalidateQueries('provider');
        },
        ...defaultMutationConfig,
        ...mutationConfig,
      },
    },
  );
};

export const useUpdateLocation = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useUpdateRecord('location', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('location');
        invalidateQueries('provider');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useDeleteLocation = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useDeleteRecord('location', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('location');
        invalidateQueries('provider');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};
