import { collectServiceGroupErrors } from '.';

const collectReduxFormServiceErrors = (service = {}) => {
  const errors = [];
  for (const key of Object.keys(service)) {
    const value = service[key];
    if (value.error) {
      errors.push(key);
    }
  }
  return errors;
};

const getServiceErrorCount = ({
  allowEmptyGroups = false,
  networks = [],
  service = {},
}) => (
  collectReduxFormServiceErrors(service).length +
  collectServiceGroupErrors({ allowEmptyGroups, networks, service }).length
);

export default getServiceErrorCount;
