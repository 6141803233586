import _ from 'lodash';

function filterVisibility(filters, partialPath) {
  switch (partialPath) {
    case 'all':
      return _.chain(filters)
        .reject({ key: 'resolved' })
        .reject({ key: 'reasons' })
        .value();

    case 'accepted/closed':
      return _.chain(filters)
        .reject({ key: 'status' })
        .reject({ key: 'reasons' })
        .value();

    case 'needs-action':
      return _.chain(filters)
        .reject({ key: 'reasons' })
        .reject({ key: 'status' })
        .reject({ key: 'resolved' })
        .value();

    case 'in-review':
      return _.chain(filters)
        .reject({ key: 'consent_status' })
        .reject({ key: 'status' })
        .reject({ key: 'resolved' })
        .value();

    default:
      return _.chain(filters)
        .reject({ key: 'resolved' })
        .reject({ key: 'status' })
        .value();
  }
}

export default filterVisibility;
