const updateThemeColor = (theme, key, value) => {
  if (!value) return theme;

  const updatedColor = value.startsWith('#') ? value : `#${value}`;
  return {
    ...theme,
    colors: {
      ...theme.colors,
      [key]: updatedColor,
    },
  };
};

const getTheme = (directoryConfiguration) => {
  let theme = { colors: {}, font: {} };
  theme = updateThemeColor(theme, 'primary', directoryConfiguration.primary_color);
  theme = updateThemeColor(theme, 'accent', directoryConfiguration.accent_color);
  theme = updateThemeColor(theme, 'background', directoryConfiguration.background_color);
  theme = updateThemeColor(theme, 'link', directoryConfiguration.link_color);
  theme = updateThemeColor(theme, 'mapMarker', directoryConfiguration.map_marker_color);

  if (directoryConfiguration.primary_font_family?.name) {
    theme.font.primary = {
      family: directoryConfiguration.primary_font_family?.name,
    };
  } else if (directoryConfiguration.primary_font_family) {
    theme.font.primary = {
      family: directoryConfiguration.primary_font_family,
    };
  }
  if (directoryConfiguration.secondary_font_family?.name) {
    theme.font.secondary = {
      family: directoryConfiguration.secondary_font_family?.name,
    };
  } else if (directoryConfiguration.secondary_font_family) {
    theme.font.secondary = {
      family: directoryConfiguration.secondary_font_family,
    };
  }
  if (directoryConfiguration.body_font_family?.name) {
    theme.font.body = {
      family: directoryConfiguration.body_font_family?.name,
    };
  } else if (directoryConfiguration.body_font_family) {
    theme.font.body = {
      family: directoryConfiguration.body_font_family,
    };
  }
  return JSON.stringify(theme, null, 2);
};

const constructDirectoryConfigurationPayload = (directoryConfiguration, initialLogo, contactData) => {
  const data = {};
  data.theme = getTheme(directoryConfiguration);
  if (directoryConfiguration.default_location) {
    data.location = JSON.stringify({ name: directoryConfiguration.location }, null, 2);
  }
  const default_location = directoryConfiguration.default_location || '';
  const latAndLng = directoryConfiguration.latitude_and_longitude;
  const lat = parseFloat(latAndLng.lat);
  const lng = parseFloat(latAndLng.lng);
  data.location = JSON.stringify({ name: default_location, lat, lng }, null, 2);
  if (directoryConfiguration.external_api_key) {
    data.keys = JSON.stringify({ externalApiKey: directoryConfiguration?.external_api_key }, null, 2);
  }
  const footerText = (directoryConfiguration.copyright_info || '');
  data.contact = JSON.stringify({ ...contactData, footerText }, null, 2);
  if (directoryConfiguration?.logo && directoryConfiguration?.logo !== initialLogo) {
    data.logo = directoryConfiguration?.logo;
  }

  const { counties, cities, states } = directoryConfiguration;

  data.counties = counties?.map(({ display_name }) => {
    const [name, state] = display_name.split(',');
    return ({ name, state: state.trim() });
  });

  data.cities = cities?.map(({ display_name }) => {
    const [name, state] = display_name.split(',');
    return ({ name, state: state.trim() });
  });

  data.states = states;
  data.include_payments = directoryConfiguration?.include_payments;

  if (directoryConfiguration?.include_payments && directoryConfiguration?.fee_schedules) {
    data.fee_schedule_ids = directoryConfiguration.fee_schedules;
  } else {
    data.fee_schedule_ids = [];
  }

  return data;
};

export default constructDirectoryConfigurationPayload;
