import { BUILD_SERVICE_TYPES } from 'actions';
import buildServiceTypes from 'common/utils/ServiceTypes/buildServiceTypes';

export const defaultState = {
  allNetworks: [],
  allPrograms: [],
  allNetworksAndPrograms: [],
  network: [],
  programs: {},
};

export default function globalServiceTypesReducer(state = defaultState, action) {
  switch (action.type) {
    case BUILD_SERVICE_TYPES:
      return { ...state, ...buildServiceTypes(action.globalState) };
    default:
      return state;
  }
}
