import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@unite-us/ui';
import LeavePageDialog from 'src/common/utils/Dialog/LeavePageDialog';
import classNames from 'classnames';
import './BackButton.scss';

export default class BackButton extends Component {
  static redirectToPrevious() {
    window.history.back();
  }

  constructor(props) {
    super(props);

    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.navigateAway = this.navigateAway.bind(this);
  }

  handleButtonClick() {
    if (this.props.confirm) {
      this.deleteModal.dialog.openDialog().then((response) => {
        if (response === 'leave') {
          this.navigateAway();
        }
      });
    } else {
      this.navigateAway();
    }
  }

  navigateAway() {
    if (this.props.navigateTo) {
      this.props.navigateTo();
    } else {
      BackButton.redirectToPrevious();
    }
  }

  render() {
    const {
      className,
      confirm,
      confirmationText,
      label,
      dataTestId,
    } = this.props;

    return (
      <div className={classNames('back-button', className)}>
        <Button
          className="back-button__button"
          iconLeft={
            <Icon icon="IconChevronLeft" />
          }
          label={label}
          onClick={this.handleButtonClick}
          data-testid={dataTestId}
          secondary
        />
        {
          confirm && (
            <LeavePageDialog
              ref={(el) => { this.deleteModal = el; }}
              text={confirmationText}
              onLeave={BackButton.redirectToPrevious}
            />
          )
        }
      </div>
    );
  }
}

BackButton.propTypes = {
  className: PropTypes.string,
  confirm: PropTypes.bool,
  confirmationText: PropTypes.string,
  label: PropTypes.string,
  navigateTo: PropTypes.func,
  dataTestId: PropTypes.string,
};

BackButton.defaultProps = {
  confirm: false,
  confirmationText: 'Are you sure you want to leave?',
  label: 'Back',
  navigateTo: null,
  dataTestId: '',
};
