import { get, isEmpty, uuCompactArrayOrObject, isEqual } from 'lodash';
import { getNetworkScopeFilterSearchParams } from 'src/components/Network/Groups/utils';

const getCoordinates = (address) => (
  uuCompactArrayOrObject({
    lat: get(address, 'latLng.lat'),
    lon: get(address, 'latLng.lng'),
  })
);

const getFormattedDistance = (distance) => {
  if (isEmpty(distance)) {
    return undefined;
  }
  return `${distance}mi`;
};

const getNetworkScopesParams = (networkScopes, contextAction, groupsOptionType) => {
  if (isEqual(contextAction, 'network')) {
    return getNetworkScopeFilterSearchParams(networkScopes);
  }

  return getNetworkScopeFilterSearchParams([groupsOptionType]);
};

const searchParams = (filters) => {
  const {
    accessibility,
    address = {},
    catersTo,
    distance,
    feeScheduleIds,
    languages,
    includeHomeGroups,
    networks,
    serviceTypes,
    sortBy = 'distance',
    text,
    states,
    counties,
    cities,
    networkScopes,
    contextAction,
    page,
    groupsOptionType,
  } = isEmpty(filters) ? {} : filters;

  return uuCompactArrayOrObject({
    accessibility_options: accessibility,
    caters_to: catersTo,
    coordinates: getCoordinates(address),
    counties,
    distance: getFormattedDistance(distance),
    fee_schedule_ids: feeScheduleIds,
    force_sort_by_name: sortBy === 'atoz',
    include_home_groups: includeHomeGroups,
    languages,
    permitted_networks: networks,
    places: cities,
    service_type_ids: serviceTypes,
    states,
    page,
    text,
    ...getNetworkScopesParams(networkScopes, contextAction, groupsOptionType),
  });
};

export default searchParams;
