import moment from 'moment';

const constructFeeScheduleFormPayload = (feeSchedule) => {
  const data = JSON.parse(JSON.stringify(feeSchedule));
  data.starts_at = moment(data.starts_at, 'MM/DD/YYYY').utc().startOf('day').toISOString();
  data.ends_at = moment(data.ends_at, 'MM/DD/YYYY').utc().startOf('day').toISOString();
  data.ignore_social_care_expired_dates = typeof data.ignore_social_care_expired_dates === 'string' ?
   data.ignore_social_care_expired_dates === 'true' :
    !!data.ignore_social_care_expired_dates;
  return data;
};

export default constructFeeScheduleFormPayload;
