import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import { ProgramDetailsV2 } from '@unite-us/client-utils';
import {
  getProgramAttributesFullNames,
  getProgramServiceTypes,
  getStateEnumFullName,
  mapGeography,
} from 'src/components/Group/Programs/utils';
import { getLanguageFullNames } from 'src/components/Groups/GroupDetails/utils';
import { FEE_SCHEDULE_PROGRAM_TOOLTIP } from 'src/components/Groups/constants';

function ProgramDetailsV2Wrapper(props) {
  const {
    center,
    languageEnums,
    program,
    programEnums,
    programLocations,
    serviceTypesEnums,
    stateEnums,
    usePaymentsUserRole, // feature flag
  } = props;

  const programAttributes = program.attributes;
  const programRelationships = program.relationships;

  const {
    accessibility_options,
    cities,
    counties,
    delivery_options,
    languages,
    payment_options,
    population_restrictions,
    population_specializations,
    states,
    transportation_options,
  } = programAttributes;

  // adjusted program params
  const accessibilityOptions =
    getProgramAttributesFullNames(accessibility_options, programEnums, 'accessibility_options');
  const deliveryOptions = getProgramAttributesFullNames(delivery_options, programEnums, 'delivery_options');
  const languagesFullNames = getLanguageFullNames(languages, languageEnums);
  const paymentOptions = getProgramAttributesFullNames(payment_options, programEnums, 'payment_options');
  const populationRestrictions = getProgramAttributesFullNames(population_restrictions, programEnums, 'population');
  const populationSpecializations =
    getProgramAttributesFullNames(population_specializations, programEnums, 'population');
  const programServiceTypes = getProgramServiceTypes(program, serviceTypesEnums);
  const stateRestrictions = getStateEnumFullName(states, stateEnums);
  const transportationOptions =
    getProgramAttributesFullNames(transportation_options, programEnums, 'transportation_options');

  const programParams = {
    ...programAttributes,
    ...programRelationships,
    accessibility_options: accessibilityOptions,
    cities: mapGeography(cities),
    counties: mapGeography(counties),
    delivery_options: deliveryOptions,
    languages: languagesFullNames,
    locations: programLocations,
    payment_options: paymentOptions,
    population_restrictions: populationRestrictions,
    population_specializations: populationSpecializations,
    service_types: programServiceTypes,
    states: stateRestrictions,
    transportation_options: transportationOptions,
  };

  const originLatLng = !isEmpty(center) ? [
    get(center, 'lat'),
    get(center, 'lng'),
  ] : [];

  if (usePaymentsUserRole) {
    const isFeeScheduleProgram = !!get(programParams, 'fee_schedule_program.data');
    const feeScheduleProgramIconDetails = {
      color: 'white',
      icon: 'IconStarWhite',
      toolTipText: FEE_SCHEDULE_PROGRAM_TOOLTIP,
    };

    return (
      <div className="program-card-v2-wrapper">
        <ProgramDetailsV2
          className="mb-one"
          feeScheduleProgramIconDetails={feeScheduleProgramIconDetails}
          isFeeScheduleProgram={isFeeScheduleProgram}
          originLatLng={originLatLng}
          program={programParams}
        />
      </div>
    );
  }

  return (
    <div className="program-card-v2-wrapper">
      <ProgramDetailsV2
        className="mb-one"
        originLatLng={originLatLng}
        program={programParams}
      />
    </div>
  );
}

ProgramDetailsV2Wrapper.propTypes = {
  center: PropTypes.object.isRequired,
  languageEnums: PropTypes.array.isRequired,
  program: PropTypes.object.isRequired,
  programEnums: PropTypes.object.isRequired,
  programLocations: PropTypes.array.isRequired,
  serviceTypesEnums: PropTypes.object.isRequired,
  stateEnums: PropTypes.array,
  usePaymentsUserRole: PropTypes.bool,
};

ProgramDetailsV2Wrapper.defaultProps = {
  stateEnums: [],
  usePaymentsUserRole: false,
};

export default ProgramDetailsV2Wrapper;
