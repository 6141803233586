import React from 'react';
import PropTypes from 'prop-types';
import { useIsNetworkLead } from 'common/contexts/CurrentProviderContext';
import { NoInvoicesFoundCard } from '../components';
import InvoiceWorkqueue from './InvoiceWorkqueue';

const NeedsAttentionInvoices = ({ children }) => {
  const isNetworkLead = useIsNetworkLead();
  const invoiceStatus = isNetworkLead ? 'submitted_to_network_lead' : 'submitted_contracted_service_note';
  const noneFoundStatus = isNetworkLead ? '' : 'draft';
  const bulkActions = ['IconDownloadV2', 'IconArchive'];

  return (
    <InvoiceWorkqueue
      invoiceStatus={invoiceStatus}
      bulkActions={bulkActions}
      showInvoiceAging
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <NoInvoicesFoundCard isFiltered={isFiltered} status={noneFoundStatus} resetFilters={resetFilters} />
      )}
      path="open/needs-attention/"
    >
      {children}
    </InvoiceWorkqueue>
  );
};

NeedsAttentionInvoices.propTypes = {
  children: PropTypes.node,
};

NeedsAttentionInvoices.defaultProps = {
  children: undefined,
};
export default NeedsAttentionInvoices;
