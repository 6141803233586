import { findIndex } from 'lodash';

function replaceObjectInArrayById(obj = {}, arr = []) {
  const objIndex = findIndex(arr, { id: obj.id });
  if (objIndex < 0) {
    return arr;
  }
  const before = arr.slice(0, objIndex);
  const after = arr.slice(objIndex + 1);
  return [...before, obj, ...after];
}

export default replaceObjectInArrayById;
