import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import {
  CheckBoxField,
  SelectField,
} from '@unite-us/ui';
import { AddRemoveButtons } from '@unite-us/client-utils';
import { ScrollFieldIntoView } from 'common/form';
import {
  daysOfTheWeekOptions,
  isClosed,
  timeOfDayOptions,
} from '../utils';
import '../stylesheets/hoursOfOperationDayFields.scss';

class HoursOfOperationDayFields extends Component {
  constructor(props) {
    super(props);

    this.onChangeOpensAt = this.onChangeOpensAt.bind(this);
    this.onCheckIsClosed = this.onCheckIsClosed.bind(this);
    this.handleRemoveHoursOfOperation = this.handleRemoveHoursOfOperation.bind(this);
    this.handleAddHoursOfOperation = this.handleAddHoursOfOperation.bind(this);
  }

  onChangeOpensAt(time = {}) {
    const { fields } = this.props;
    const { value } = time;

    // If opens at_time values changes to a time later than closes_at value,
    // clear the closes_at value.
    const closesAtValue = get(fields, 'closes_at.value');
    if (value && closesAtValue && parseInt(closesAtValue, 10) <= parseInt(value, 10)) {
      fields.closes_at.onChange();
    }
  }

  onCheckIsClosed(e, value) {
    const { fields } = this.props;

    if (value) {
      fields.opens_at.onChange();
      fields.closes_at.onChange();
    }
  }

  handleRemoveHoursOfOperation() {
    this.props.onRemove(this.props.index);
  }

  handleAddHoursOfOperation() {
    this.props.onAdd();
  }

  render() {
    const {
      fields,
      hideAddRemoveButtons,
      id,
      index,
      length,
    } = this.props;

    return (
      <div className="hours-of-operation-day-fields">
        <div className="hours-of-operation-day-fields__row">
          <div className="hours-of-operation-day-fields__day">
            <ScrollFieldIntoView>
              <SelectField
                className="hours-of-operation-day-fields__day-field"
                clearable={false}
                field={fields.day_of_week}
                hideLabel
                id={`${id}-day`}
                label="day"
                options={daysOfTheWeekOptions()}
                placeholder="Select day..."
                shouldSort={false}
              />
            </ScrollFieldIntoView>
          </div>

          <div className="hours-of-operation-day-fields__opens-at">
            <ScrollFieldIntoView>
              <SelectField
                className="hours-of-operation-day-fields__opens-at-field"
                clearable={false}
                disabled={isClosed(fields)}
                field={fields.opens_at}
                hideLabel
                id={`${id}-opens-at`}
                label="opens"
                onChange={this.onChangeOpensAt}
                options={timeOfDayOptions()}
                placeholder="Open"
                shouldSort={false}
              />
            </ScrollFieldIntoView>
          </div>

          <div className="hours-of-operation-day-fields__closes-at">
            <ScrollFieldIntoView>
              <SelectField
                className="hours-of-operation-day-fields__closes-at-field"
                clearable={false}
                disabled={isClosed(fields)}
                field={fields.closes_at}
                hideLabel
                id={`${id}-closes-at`}
                label="closes"
                options={timeOfDayOptions({
                  before: get(fields, 'opens_at.value'),
                  midnightEndsDay: true,
                })}
                placeholder="Close"
                shouldSort={false}
              />
            </ScrollFieldIntoView>
          </div>

          <div className="hours-of-operation-day-fields__is-closed">
            <CheckBoxField
              className="hours-of-operation-day-fields__is-closed-field"
              field={fields.is_closed}
              iconStyle={{ marginRight: '5px' }}
              id={`${id}-closed`}
              inputStyle={{ marginRight: '0' }}
              label="Closed"
              onChange={this.onCheckIsClosed}
            />
          </div>
          {
            !hideAddRemoveButtons && (
              <div className="hours-of-operation-day-fields__add-remove">
                <AddRemoveButtons
                  index={index}
                  length={length}
                  onAddClick={this.handleAddHoursOfOperation}
                  onRemoveClick={this.handleRemoveHoursOfOperation}
                />
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

HoursOfOperationDayFields.propTypes = {
  fields: PropTypes.shape({
    closes_at: PropTypes.object.isRequired,
    day_of_week: PropTypes.object.isRequired,
    is_closed: PropTypes.object.isRequired,
    opens_at: PropTypes.object.isRequired,
  }).isRequired,
  hideAddRemoveButtons: PropTypes.bool,
  id: PropTypes.string.isRequired,
  index: PropTypes.number,
  length: PropTypes.number,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
};

HoursOfOperationDayFields.defaultProps = {
  hideAddRemoveButtons: false,
  index: 0,
  length: 1,
  onAdd: noop,
  onRemove: noop,
};

export default HoursOfOperationDayFields;
