import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@unite-us/ui';
import { isEmpty } from 'lodash';
import { isLockedRow } from 'common/tables/DynamicTable/utils';
import './DashboardRowIcon.scss';

const DashboardRowIcon = ({ children, iconTooltip, permissions }) => {
  if (!isEmpty(permissions) && isLockedRow(permissions)) {
    return (
      <Icon
        className="dashboard-row-icon--locked"
        icon="IconLock"
      />
    );
  }

  if (!isEmpty(children)) {
    const tooltipProps = iconTooltip && {
      'data-tooltip-bottom-right': true,
      'data-tooltip': iconTooltip,
    };

    return (
      <div className="dashboard-row-icon" {...tooltipProps}>
        {children}
      </div>
    );
  }

  return null;
};

DashboardRowIcon.propTypes = {
  children: PropTypes.node,
  iconTooltip: PropTypes.node,
  permissions: PropTypes.shape({
    can_view: PropTypes.shape({
      authorization: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

DashboardRowIcon.defaultProps = {
  children: null,
  iconTooltip: null,
};

export default DashboardRowIcon;
