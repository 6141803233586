import { Icon } from '@unite-us/ui';
import PropTypes from 'prop-types';
import React from 'react';
import CardDetail from '../../../../common/Card/CardDetail';

// eslint-disable-next-line react/prop-types
const Success = ({ message }) => (
  <div className="flex items-center">
    <Icon
      icon="IconCheckCircle"
      className="fill-current text-dark-green mr-1"
    />
    <p>{message}</p>
  </div>
);

// eslint-disable-next-line react/prop-types
const Failure = ({ message }) => (
  <div className="flex items-center">
    <Icon
      icon="IconTimesCircle"
      className="fill-current text-red mr-1"
    />
    <p>{message}</p>
  </div>
);

// need to verify if pending is a state for some of these statuses
export const MatchVerificationMessage = ({
  label,
  match,
  successMessage,
  failureMessage,
  pending,
}) => {
  const renderMessage = () => {
    if (pending) return <p>Checking records...</p>;
    if (match) return <Success message={successMessage} />;
    return <Failure message={failureMessage} />;
  };

  return (
    <CardDetail label={label}>
      {renderMessage()}
    </CardDetail>
  );
};

MatchVerificationMessage.propTypes = {
  label: PropTypes.string.isRequired,
  match: PropTypes.bool,
  successMessage: PropTypes.string.isRequired,
  failureMessage: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
};

MatchVerificationMessage.defaultProps = {
  match: null,
};
