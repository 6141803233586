import React from 'react';
import { DashboardNavigationLink, LeftNavigationBar } from 'common/Navigation';
import { useCurrentPayerId } from 'common/contexts/CurrentProviderContext';
import { ENROLLMENT_STATUSES } from '../constants';
import { useEnrollmentStatusCount } from '../hooks/useEnrollmentStatusCount';

export const StatusSelectBar = () => {
  const payerId = useCurrentPayerId();
  const openCount = useEnrollmentStatusCount(ENROLLMENT_STATUSES.open, payerId);
  return (
    <LeftNavigationBar className="space-y-4 -mb-20">
      <DashboardNavigationLink
        className="text-base"
        label="Open"
        count={openCount || undefined}
        to="/enrollments/open"
        data-testid="open-enrollments-link"
      />
      <DashboardNavigationLink
        className="text-base"
        label="Accepted"
        to="/enrollments/accepted"
      />
      <DashboardNavigationLink
        className="text-base"
        label="Rejected"
        to="/enrollments/rejected"
      />
      <DashboardNavigationLink
        className="text-base"
        label="Canceled"
        to="/enrollments/canceled"
      />
      <DashboardNavigationLink
        className="text-base"
        label="All Requests"
        to="/enrollments/all"
      />
    </LeftNavigationBar>
  );
};
