import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

const validateReduxForm = (
  options,
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
  connectOpt,
) => (WrappedComponent) => {
  function mapStateToPropswithInitial(state, ownProps) {
    if (mapStateToProps) {
      return mapStateToProps(state, ownProps);
    }
    return {
      initialValues: ownProps.initialValues,
    };
  }

  function scrollToFirstError() {
    const errs = document.getElementsByClassName('has-error');
    const err = _.first(errs);
    let scrollElement = err;
    while (scrollElement && scrollElement.scrollTop <= 0) {
      if (scrollElement.scrollHeight > scrollElement.clientHeight) {
        break;
      }
      scrollElement = scrollElement.parentElement;
    }
    if (!scrollElement) {
      return;
    }
    let offset = err.offsetTop;
    let parent = err.parentElement;
    let offsetParent = err.offsetParent;
    while (parent && parent !== scrollElement) {
      if (parent === offsetParent) {
        offset += parent.offsetTop;
        offsetParent = parent.offsetParent;
      }
      parent = parent.parentElement;
    }
    scrollElement.scrollTop = offset - scrollElement.offsetTop - 60;
  }

  function onSubmitFail(errors) {
    setTimeout(scrollToFirstError, 200);
    if (_.isFunction(options.onSubmitFail)) {
      options.onSubmitFail(errors);
    }
  }

  class ValidateReduxForm extends Component {
    constructor(props) {
      super(props);
      this.instance = {
        validations: [],
        changeCallback: null,
      };

      const optionsParams = options.form ? options : { ...options, form: props.formName };

      this.ReduxFormCpt = reduxForm(
        _.merge(
          {
            destroyOnUnmount: !(process.env.NODE_ENV === 'development' && module.hot), // https://github.com/redux-form/redux-form/issues/623
            touchOnBlur: true,
          },
          optionsParams,
          { onSubmitFail },
        ),
        mapStateToPropswithInitial,
        mapDispatchToProps,
        mergeProps,
        connectOpt,
      )(WrappedComponent);
      this.validate = this.validate.bind(this);
      this.register = this.register.bind(this);
      this.unregister = this.unregister.bind(this);
      this.registerCallback = this.registerCallback.bind(this);
    }

    componentWillUnmount() {
      _.remove(this.instance.validations, () => true);
      this.instance.changeCallback = null;
    }

    validate(values) {
      if (_.isFunction(this.instance.changeCallback)) {
        this.instance.changeCallback(values);
      }

      const filteredValidations = _.filter(this.instance.validations, (item) => (
        _.hasIn(values, item.props.field && item.props.field.name) && !item.props.hidden
      ));
      const errorMessages = _.reduce(filteredValidations, (errors, validation) => {
        const value = _.get(values, validation.props.field.name);
        const rules = validation.props.validations;
        let messages = [];
        if (_.isFunction(rules)) {
          messages.push(rules(value));
        } else if (_.isObject(rules) && !_.isArray(rules)) {
          messages.push(rules.func(value, rules.message, rules.args, values));
        } else if (_.isArray(rules)) {
          _.forEach(rules, (rule) => (
            messages.push(rule.func(value, rule.message, rule.args, values))
          ));
        }
        if (_.isFunction(validation.validate)) {
          messages = messages.concat(validation.validate(value));
        }
        messages = _.compact(messages);
        _.update(errors, validation.props.field.name, () => messages.join(', '));
        return errors;
      }, {});
      return errorMessages;
    }

    submit() {
      return this.form.submit();
    }

    register(c) {
      if (c) {
        this.instance.validations = _.concat(this.instance.validations, c);
      }
    }

    registerCallback(f) {
      this.instance.changeCallback = f;
    }

    unregister(c) {
      if (c) {
        this.instance.validations = _.remove(this.instance.validations, c);
      }
    }

    render() {
      return (
        <this.ReduxFormCpt
          ref={(el) => { this.form = el; }}
          {...this.props}
          {...this.state}
          formName={options.form || this.props.formName}
          registerField={this.register}
          unregisterField={this.unregister}
          registerOnChange={this.registerCallback}
          validate={this.validate}
        />
      );
    }
  }

  ValidateReduxForm.propTypes = {
    formName: PropTypes.string,
  };

  ValidateReduxForm.defaultProps = {
    formName: '',
  };

  ValidateReduxForm.displayName =
    `ValidateReduxForm(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
  ValidateReduxForm.WrappedComponent = WrappedComponent;

  return ValidateReduxForm;
};

export default validateReduxForm;
