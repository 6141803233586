import { find, get, isEmpty } from 'lodash';

export default function getServiceTypeOptions({
  defaultProgramSelected, fields, programsServiceTypes, user,
}) {
  // CORE-WORK - Need to resolve network.service_types
  const selectedNetwork = find(user.networks, { id: fields.network.value.id }) || {};

  if (defaultProgramSelected) {
    return selectedNetwork.service_types || [];
  }

  return !isEmpty(fields.program.value) ? get(programsServiceTypes, fields.program.value.id, []) : [];
}
