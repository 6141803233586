import { coreApi } from 'src/api/config';
import Notifier from 'common/helpers/Notifier';
import { setDashboardRefetch } from 'actions/Dashboard';
import { HOLD_REFERRAL_SENT, HOLD_REFERRAL_RECEIVED } from 'actions';
import { get } from 'lodash';

const populateRelationships = async (referral) => {
  await Promise.all([
    coreApi.populateRelationship('case', 'case', referral),
    coreApi.populateRelationship('receiving_network', 'network', referral),
    coreApi.populateRelationship('receiving_provider', 'provider', referral),
    coreApi.populateRelationship('sending_network', 'network', referral),
    coreApi.populateRelationship('sending_provider', 'provider', referral),
  ]);

  await Promise.all([
    coreApi.populateRelationship('case.person', 'person', referral),
    coreApi.populateRelationship('case.service', 'service', referral),
  ]);
};

const createReferralNote = (referralId, employeeId, note) => coreApi.createRecord('note', {
    subjectType: 'referral',
    subject: referralId,
    employee: employeeId,
    visibility: 'public',
    category: 'general',
    text: note,
    details: { context: 'held_in_review' },
  });

const buildPayload = (responseData, referral) => ({
    ...responseData,
    data: {
      ...responseData.data,
      data: {
        ...referral,
        contact: get(referral, 'case.person', null),
        service_type: get(referral, 'case.service', null),
        referred_by_network: get(referral, 'sending_network', null),
        referred_to_network: get(referral, 'receiving_network', null),
        referred_by_group: get(referral, 'sending_provider', null),
        referred_to_group: get(referral, 'receiving_provider', null),
        rejection: {
          reason: get(referral, 'reason', null),
        },
      },
    },
  });

const changeHoldReason = (groupId, referralId, referral_hold, refetch = false) => (
  async (dispatch, getState) => {
    try {
      const { globalState: { currentEmployee } } = getState();
      const { data: responseData } = await coreApi.updateRecord('referral', referralId, {
        reason: referral_hold.reason,
      });

      const referral = responseData.data;

      await populateRelationships(referral);
      await createReferralNote(referralId, currentEmployee.id, referral_hold.note);

      const payload = buildPayload(responseData, referral);

      dispatch({
        type: get(payload, 'data.data.referred_by_group.id') === groupId ?
          HOLD_REFERRAL_SENT :
          HOLD_REFERRAL_RECEIVED,
        payload,
      });

      Notifier.dispatch(payload.status, 'Referral Held Successfully');

      if (refetch) {
        dispatch(setDashboardRefetch());
      }

      return payload;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  }
);

export default changeHoldReason;
