/* eslint max-len: 0 */
import React from 'react';

const NoFilteredResultsLogo = () => (
  <svg
    width=""
    height="187"
    viewBox="0 0 204 187"
    fill="none"
  >
    <g clipPath="url(#clip0_1875_68)">
      <path d="M93.7308 186.993C145.497 186.993 187.462 145.134 187.462 93.4967C187.462 41.8599 145.497 0 93.7308 0C41.9647 0 0 41.8599 0 93.4967C0 145.134 41.9647 186.993 93.7308 186.993Z" fill="#EAF0F7" />
      <path d="M92.4486 141.599C127.439 141.599 155.804 125.263 155.804 105.112C155.804 84.9603 127.439 68.6245 92.4486 68.6245C57.4586 68.6245 29.0936 84.9603 29.0936 105.112C29.0936 125.263 57.4586 141.599 92.4486 141.599Z" fill="#CADEEE" />
      <path d="M151.417 100.21C151.417 118.83 125.207 133.918 92.8782 133.918C60.5498 133.918 34.3395 118.824 34.3395 100.21V81.9468H151.423V100.21H151.417Z" fill="#2F71F4" />
      <path d="M92.8782 116.778C125.208 116.778 151.417 101.687 151.417 83.07C151.417 64.4533 125.208 49.3616 92.8782 49.3616C60.5482 49.3616 34.3395 64.4533 34.3395 83.07C34.3395 101.687 60.5482 116.778 92.8782 116.778Z" fill="#75B6F2" />
      <path d="M138.029 119.051L197.036 153.603L186.31 159.784L127.296 125.225L138.029 119.051Z" fill="#2F71F4" />
      <path d="M138.029 106.67L197.036 141.229L186.31 147.409L127.296 112.851L138.029 106.67Z" fill="#75B6F2" />
      <path d="M127.296 125.225V112.851L186.31 147.409V159.784L127.296 125.225Z" fill="#2F71F4" />
      <path d="M197.036 141.229V153.603L186.31 159.784V147.409L197.036 141.229Z" fill="#0349F9" />
      <path d="M92.8782 110.059C118.763 110.059 139.747 97.9755 139.747 83.07C139.747 68.1645 118.763 56.0812 92.8782 56.0812C66.9933 56.0812 46.0095 68.1645 46.0095 83.07C46.0095 97.9755 66.9933 110.059 92.8782 110.059Z" fill="#EAF0F7" />
      <path d="M139.207 83.07C139.155 80.7003 138.621 78.3306 137.553 76.2011C136.499 74.0586 135.035 72.1303 133.323 70.4553C129.88 67.0922 125.623 64.6576 121.171 62.8462C118.939 61.9373 116.648 61.1907 114.324 60.5609C112.001 59.9117 109.638 59.4377 107.262 59.0352C102.511 58.2366 97.6945 57.8601 92.8716 57.8536C88.0553 57.8341 83.2324 58.1912 78.468 58.9573C73.7037 59.7039 68.998 60.9115 64.5005 62.6904C60.0226 64.4823 55.7269 66.9105 52.2448 70.2865C50.52 71.9745 49.023 73.9222 47.9556 76.0907C46.8752 78.2526 46.3284 80.6613 46.2764 83.07C46.3349 85.4787 46.8817 87.8873 47.9621 90.0493C49.036 92.2178 50.5265 94.159 52.2578 95.847C55.7399 99.2165 60.0356 101.638 64.5135 103.424C66.759 104.32 69.0631 105.047 71.3932 105.663C73.7233 106.293 76.0924 106.754 78.468 107.137C83.2259 107.897 88.0422 108.254 92.8586 108.234C97.675 108.234 102.491 107.851 107.236 107.059C109.612 106.663 111.974 106.183 114.292 105.54C116.615 104.91 118.906 104.17 121.139 103.261C125.591 101.463 129.854 99.0283 133.297 95.6782C135.009 94.0032 136.479 92.0749 137.527 89.9389C138.595 87.8094 139.128 85.4397 139.187 83.07H139.207ZM140.281 83.07C140.261 85.6085 139.721 88.1535 138.621 90.4388C137.547 92.7307 136.102 94.8537 134.371 96.6845C130.928 100.392 126.664 103.229 122.16 105.462C113.094 109.909 102.934 111.883 92.8847 111.89C82.8353 111.844 72.6884 109.799 63.6804 105.306C59.2025 103.06 54.9719 100.203 51.5679 96.5157C49.8496 94.6914 48.4307 92.5943 47.3763 90.3285C46.2894 88.0756 45.7622 85.5631 45.7427 83.0635C45.7622 80.5704 46.2959 78.0579 47.3828 75.805C48.4437 73.5392 49.8626 71.4421 51.5809 69.6243C54.9849 65.9431 59.2155 63.0994 63.6935 60.8596C72.7014 56.3863 82.8353 54.3477 92.8847 54.3023C102.927 54.3023 113.081 56.2695 122.147 60.7037C126.651 62.9241 130.915 65.7613 134.364 69.4555C136.102 71.2863 137.547 73.3963 138.621 75.6946C139.727 77.98 140.274 80.525 140.294 83.0635L140.281 83.07Z" fill="white" />
      <path d="M170.578 67.2091C189.04 67.2091 204.007 52.2801 204.007 33.8642C204.007 15.4484 189.04 0.51939 170.578 0.51939C152.116 0.51939 137.15 15.4484 137.15 33.8642C137.15 52.2801 152.116 67.2091 170.578 67.2091Z" fill="white" />
      <path d="M170.578 63.3591C186.909 63.3591 200.147 50.1538 200.147 33.8643C200.147 17.5747 186.909 4.36937 170.578 4.36937C154.248 4.36937 141.009 17.5747 141.009 33.8643C141.009 50.1538 154.248 63.3591 170.578 63.3591Z" fill="#2C405A" />
      <path d="M170.721 18.6461C179 18.6461 182.32 26.859 182.32 34.2538C182.32 41.6486 179 49.9004 170.721 49.9004C162.442 49.9004 159.123 41.5967 159.123 34.2538C159.123 26.9109 162.397 18.6461 170.721 18.6461ZM170.721 22.0027C164.948 22.0027 162.989 28.2159 162.989 34.2538C162.989 40.2917 164.942 46.5439 170.721 46.5439C176.501 46.5439 178.454 40.2852 178.454 34.2538C178.454 28.2224 176.501 22.0027 170.721 22.0027Z" fill="white" />
      <path d="M78.5462 86.6473C86.0337 86.6473 92.1036 83.1505 92.1036 78.837C92.1036 74.5234 86.0337 71.0266 78.5462 71.0266C71.0586 71.0266 64.9887 74.5234 64.9887 78.837C64.9887 83.1505 71.0586 86.6473 78.5462 86.6473Z" fill="white" />
      <path d="M101.391 75.805C103.372 75.805 104.978 74.8807 104.978 73.7404C104.978 72.6002 103.372 71.6759 101.391 71.6759C99.4108 71.6759 97.8052 72.6002 97.8052 73.7404C97.8052 74.8807 99.4108 75.805 101.391 75.805Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_1875_68">
        <rect width="204" height="187" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default NoFilteredResultsLogo;
