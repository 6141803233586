import PropTypes from 'prop-types';
import React from 'react';
import { ConsentTypeChoice } from '@unite-us/client-utils';
import {
  Button,
  InputField,
} from '@unite-us/ui';
import {
  validateEmailAddress,
  validatePhoneNumber,
} from 'src/components/InformedConsent/utils/validations';
import { CONSENT_OPTIONS } from 'src/components/InformedConsent/constants';
import ConsentFileUpload from './ConsentFileUpload';
import DownloadConsentFormLink from './DownloadConsentFormLink';
import ViewVerbalConsentScriptLink from './ViewVerbalConsentScriptLink';

const ConsentTypeChoices = ({
  fields,
  registerField,
  onSubmit,
  selectConsentType,
  selectedConsentType,
  showConsent,
  setConsentAttestation,
}) => (
  <div className="consent-type-choices">
    <div className="consent-type-choices-group attestation-consent">
      <h3 className="consent-type-choices-group__title">
        User Attestation
      </h3>
      <ConsentTypeChoice
        type={CONSENT_OPTIONS.ON_SCREEN_ATTESTATION}
        label="On-Screen Consent by Attestation (Client Consented Verbally)"
        onSelect={selectConsentType}
        selectedType={selectedConsentType}
      >
        <Button
          id="go-to-form-btn"
          className="consent-go-to-form-btn"
          label="Go To Form"
          primary
          onClick={() => setConsentAttestation(true)}
        />
      </ConsentTypeChoice>
    </div>
    <div className="consent-type-choices-group">
      <h3 className="consent-type-choices-group__title">
        Digital Consent
      </h3>
      <div className="consent-type-choices-group__choices">
        <ConsentTypeChoice
          type={CONSENT_OPTIONS.PHONE_NUMBER}
          label="Send request for signature by text message (SMS)"
          onSelect={selectConsentType}
          selectedType={selectedConsentType}
        >
          <div className="row">
            <div className="col-sm-8">
              <InputField
                ref={registerField}
                id="phone-number"
                label="Phone Number"
                field={fields[CONSENT_OPTIONS.PHONE_NUMBER]}
                hideLabel
                disabled={selectedConsentType !== CONSENT_OPTIONS.PHONE_NUMBER}
                mask="(999) 999-9999"
                validations={{ func: validatePhoneNumber }}
              />
            </div>
            <div className="col-sm-4">
              <Button
                id="consent-submit-phone-btn"
                className="consent-submit-request-by-phone-btn"
                label="Submit"
                onClick={onSubmit}
                primary
              />
            </div>
          </div>
        </ConsentTypeChoice>

        <ConsentTypeChoice
          type={CONSENT_OPTIONS.EMAIL_ADDRESS}
          label="Send request for signature by Email"
          onSelect={selectConsentType}
          selectedType={selectedConsentType}
        >
          <div className="row">
            <div className="col-sm-8">
              <InputField
                ref={registerField}
                id="email-address"
                label="Email Address"
                field={fields[CONSENT_OPTIONS.EMAIL_ADDRESS]}
                hideLabel
                disabled={selectedConsentType !== CONSENT_OPTIONS.EMAIL_ADDRESS}
                validations={{ func: validateEmailAddress }}
              />
            </div>
            <div className="col-sm-4">
              <Button
                id="consent-submit-email-btn"
                className="consent-submit-request-by-email-btn"
                label="Submit"
                onClick={onSubmit}
                primary
              />
            </div>
          </div>
        </ConsentTypeChoice>

        <ConsentTypeChoice
          type={CONSENT_OPTIONS.ON_SCREEN}
          label="On-Screen Consent"
          onSelect={selectConsentType}
          selectedType={selectedConsentType}
        >
          <Button
            id="go-to-form-btn"
            className="consent-go-to-form-btn"
            label="Go To Form"
            primary
            onClick={showConsent}
          />
        </ConsentTypeChoice>
      </div>
    </div>

    <div className="consent-type-choices-group paper-and-verbal-consent">
      <h3 className="consent-type-choices-group__title">
        Paper And Verbal Consent
      </h3>
      <div className="consent-type-choices-group__choices">
        <ConsentTypeChoice
          type={CONSENT_OPTIONS.DOCUMENT}
          label="Upload signed paper form"
          onSelect={selectConsentType}
          selectedType={selectedConsentType}
          dropzone={(
            <ConsentFileUpload type={CONSENT_OPTIONS.DOCUMENT} />
          )}
        >
          <DownloadConsentFormLink />
        </ConsentTypeChoice>

        <ConsentTypeChoice
          type={CONSENT_OPTIONS.AUDIO}
          label="Upload audio file (client consented verbally)"
          onSelect={selectConsentType}
          selectedType={selectedConsentType}
          dropzone={(
            <ConsentFileUpload type={CONSENT_OPTIONS.AUDIO} />
          )}
        >
          <ViewVerbalConsentScriptLink />
        </ConsentTypeChoice>
      </div>
    </div>
  </div>
);

ConsentTypeChoices.propTypes = {
  fields: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  setConsentAttestation: PropTypes.func.isRequired,
  selectConsentType: PropTypes.func.isRequired,
  selectedConsentType: PropTypes.string.isRequired,
  showConsent: PropTypes.func.isRequired,
};

export default ConsentTypeChoices;
