import _ from 'lodash';

export function addElement(state, element, target) {
  const currentPage = _.get(state, `${target}.current_page`, 1);
  return _.assign({}, state, {
    [target]: {
      ...state[target],
      data: _.map(state[target].data, (page) => {
        // page looks like this:
        // {
        //  data: [ elements ],
        //  paging: {},
        //  time: 0,
        // }
        if (page.paging.current_page === currentPage) {
          return _.assign({}, page, {
            ...page,
            data: [
              element,
              ...page.data,
            ],
            paging: {
              ...page.paging,
              total_count: page.paging.total_count + 1,
            },
          });
        }

        return page;
      }),
    },
  });
}

export default function addElements(state, payload = [], target, currentSection) {
  if (_.isNil(state)) {
    // eslint-disable-next-line no-console
    console.warn('You are calling addElements with an undefined state passed in.');
  }
  if (target !== currentSection) { return state; }
  if (!_.has(state, target)) { return state; }
  if (_.isEmpty(payload)) { return state; }

  return addElements(addElement(state, _.head(payload), target), _.tail(payload), target);
}
