import { useFind } from 'src/api/APIHooks';
import { get, isEmpty } from 'lodash';

const useProcedureCodes = (procedureCodeIds = [], showSavedClaimCodes) => {
  const { isFetching, data } = useFind(
    'procedure_codes',
    {
      id: procedureCodeIds.join(','),
    },
    {
      queryConfig: {
        enabled: showSavedClaimCodes && !isEmpty(procedureCodeIds),
      },
    },
  );
  const procedureCodes = get(data, 'data.data', []);

  return isFetching ? [] : procedureCodes;
};

export default useProcedureCodes;
