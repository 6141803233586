import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import IconButton from 'common/buttons/IconButton/IconButton';
import DialogV2 from 'common/modal/DialogV2';
import Notifier from 'common/helpers/Notifier';
import { Spinner } from 'common/spinners';
import { FACESHEET } from 'common/utils/EventTracker/utils/eventConstants';
import {
  useDeletePersonalRelationship,
  useFetchPersonalRelationships,
} from '../Common/api';
import RelationshipCard from '../Common/RelationshipCard';
import RelationshipDeleteContent from '../Common/RelationshipDeleteContent';
import RelationshipListItem from '../Common/RelationshipListItem';
import RelationshipsList from '../Common/RelationshipsList';
import useFamilyMemberForm from './forms/useFamilyMemberForm';
import './FamilyMember.scss';

const DISPLAY_NAME = 'family-members';

const FamilyMember = ({ person, className }) => {
  if (isEmpty(person)) {
    return null;
  }
  const dialogRef = useRef(null);
  const {
    data: personalRelationships,
    isFetching: isFetchingRelationships,
    isError: isErrorFetchingRelationships,
  } = useFetchPersonalRelationships({
    personId: person.id,
    familyMember: true,
    careTeamMember: false,
  });
  React.useEffect(() => {
    if (isErrorFetchingRelationships) {
      Notifier.dispatch('Error fetching family members');
    }
  }, [isErrorFetchingRelationships]);
  const [currentPersonalRelationship, setCurrentPersonalRelationship] =
    useState(null);
  const [FamilyMemberFormDialog, openFamilyMemberForm] = useFamilyMemberForm({
    personId: person.id,
  });

  const { deleteRecord: deletePersonalRelationship } =
    useDeletePersonalRelationship();
  const confirmDelete = async () => {
    dialogRef.current.closeDialog();
    const response = await deletePersonalRelationship(
      currentPersonalRelationship.id,
      {},
    );
    if (isHttpSuccess(response.status)) {
      Notifier.dispatch(response.status, 'Family member successfully deleted.');
      window.eventTracker(FACESHEET.familyMemberRemoved, {
        relation_to_contact: currentPersonalRelationship.relationship,
      });
    }
  };

  const deleteButtonOnClickHandler = (e, pr) => {
    e.stopPropagation();
    setCurrentPersonalRelationship(pr);
    dialogRef.current.openDialog();
  };
  const editButtonClickHandler = (e, pr) => {
    e.stopPropagation();
    window.eventTracker(FACESHEET.familyMemberEditClicked, {
      relation_to_contact: pr.relationship,
    });
    openFamilyMemberForm(pr, personalRelationships.length);
  };

  return (
    <RelationshipCard
      title="Family Members"
      addButtonLabel="Add Family Member"
      className={classNames(DISPLAY_NAME, className)}
      onAddClick={() => openFamilyMemberForm(null, personalRelationships.length)}
    >
      {isFetchingRelationships ? (
        <Spinner scale={0.5} />
      ) : (
        <RelationshipsList name="family">
          {personalRelationships.map((pr) => (
            <RelationshipListItem
              key={pr.id}
              personalRelationshipId={pr.id}
              fullName={pr.related_person?.full_name}
              relationship={pr.relationship}
              onClick={() => openFamilyMemberForm(pr, personalRelationships.length)}
              linkedFamilyMemberId={
                pr.shouldDisplayLink ? pr.related_person.id : ''
              }
            >
              <IconButton
                className={classNames(`${DISPLAY_NAME}__delete-btn`, 'pr-2')}
                labelText="Delete"
                icon="IconTrash"
                onClick={(e) => deleteButtonOnClickHandler(e, pr)}
                removeMargin
              />
              <IconButton
                className={`${DISPLAY_NAME}__edit-btn`}
                labelText="Edit"
                icon="IconPencil"
                onClick={(e) => editButtonClickHandler(e, pr)}
                removeMargin
              />
            </RelationshipListItem>
          ))}
        </RelationshipsList>
      )}
      {FamilyMemberFormDialog}
      <DialogV2
        ref={dialogRef}
        title="Delete Family Member"
        confirmLabel="Delete"
        cancelHandler={dialogRef?.current?.closeDialog || (() => {})}
        confirmationHandler={confirmDelete}
      >
        <RelationshipDeleteContent memberType="Family" />
      </DialogV2>
    </RelationshipCard>
  );
};

FamilyMember.propTypes = {
  className: PropTypes.string,
  person: PropTypes.object.isRequired,
};

FamilyMember.defaultProps = {
  className: '',
};

export default FamilyMember;
