import _ from 'lodash';

const TYPE_MAP = {
  facades_networks_participatinggroup: 'provider',
  group: 'provider',
  network: 'network',
  networks_supernetwork: 'network',
};

const disableOptionsByTargetType = ({ targetType, options }) => {
  if (_.isEmpty(targetType)) {
    return options;
  }

  return _.reduce(options, (acc, option) => {
    const metaType = _.get(option, '_meta._type');
    if (TYPE_MAP[metaType] !== targetType) {
      return [...acc, { ...option, disabled: true }];
    }
    return [...acc, { ...option }];
  }, []);
};

const targetSelectionOptions = ({
  targetType, currentGroup, ccNetwork, isCC,
}) => {
  if (!isCC) {
    return [currentGroup];
  }
  const processedGroupOptions = disableOptionsByTargetType({ targetType, options: [currentGroup] });
  const processedNetworkOptions = disableOptionsByTargetType({ targetType, options: [ccNetwork] });

  const options = [
    {
      name: 'Networks',
      children: processedNetworkOptions,
    },
    {
      name: 'Organizations',
      children: processedGroupOptions,
    },
  ];

  return options;
};

export default targetSelectionOptions;
