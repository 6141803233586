import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import FeeScheduleProgramCustomFieldTable from './FeeScheduleProgramCustomFieldTable';
import FeeScheduleProgramAttachmentTable from './FeeScheduleProgramAttachmentTable';

const FeeScheduleProgramCustomField = ({ feeScheduleProgram }) => {
  const customFields = isEmpty(feeScheduleProgram) ?
    [] :
    feeScheduleProgram.metafields.filter((metafield) => !isEmpty(metafield) && metafield.type !== 'file');

  const attachments = isEmpty(feeScheduleProgram) ?
    [] :
    feeScheduleProgram.metafields.filter((metafield) => !isEmpty(metafield) && metafield.type === 'file');

  return (
    <>
      <FeeScheduleProgramCustomFieldTable
        customFields={customFields}
        feeScheduleProgram={feeScheduleProgram}
      />
      <div className="pb-5">
        <FeeScheduleProgramAttachmentTable
          attachments={attachments}
          feeScheduleProgram={feeScheduleProgram}
        />
      </div>
    </>
  );
};

FeeScheduleProgramCustomField.propTypes = {
  feeScheduleProgram: PropTypes.object.isRequired,
};

export default FeeScheduleProgramCustomField;
