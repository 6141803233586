// Library Imports
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Divider,
} from '@unite-us/ui';
import { InfoPanel } from '@unite-us/client-utils';
import { PHI_INFO_MESSAGE, CFR_PART_2_CASE_MESSAGE } from 'common/messageConstants';

// Util Imports
import { usePopulate } from 'src/api/APIHooks';
import { dividerStatusStyles } from 'common/display/SingleItemDetails/utils/common';
import { showEdit } from 'src/components/Referrals/ReferralDetail/utils';
import _ from 'lodash';

// Component Imports
import DocumentUploader from 'common/form/DocumentUploader';
import DetailSummary from 'common/display/SingleItemDetails/dashboard/DetailSummary';
import ReferralBasicInformation from 'src/components/Referrals/ReferralDetail/components/ReferralBasicInformation';
import ReferralSummaryWrapper from 'src/components/Referrals/components/ReferralDetailSummaryWrapper';
import ReferralServiceAuthorizationInformation from
'src/components/Referrals/ReferralDetail/components/ReferralServiceAuthorizationInformation';
import ReferralDetailActionWrapper from
'src/components/Referrals/ReferralDetail/components/ReferralDetailActionWrapper';
import ConsentReminder from 'src/components/ConsentReminder';
import ServiceCaseAssessmentsWrapper from 'src/components/Cases/components/Detail/ServiceCaseAssessmentsWrapper';
import DetailStatusText from 'common/display/SingleItemDetails/DetailStatusText';
import { referralStatuses } from 'src/components/ReferralHistory/utils/referralStatusConstants';
import './DraftCaseOptions.scss';

function DraftCaseOptions(props) {
  const {
    location,
    caseAssessments,
    falsifyActionBool,
    goToNotes,
    groupId,
    isCoordinationGroup,
    isReferralsAdminUser,
    isSendable,
    serviceCase = {},
    showActions,
    suggestedGroups,
    type,
  } = props;

  const referral = {
    ...serviceCase,
    status: 'draft',
    case: serviceCase,
    contact: serviceCase.person,
    service_type: serviceCase.service,
    referred_by_group: serviceCase.provider,
    sending_network: _.get(serviceCase, 'referrals[0].sending_network', ''),
    referred_by_network: _.get(serviceCase, 'referrals[0].sending_network', ''),
    referred_to_network: _.get(serviceCase, 'referrals[0].receiving_network', ''),
    referred_to_groups_draft: _.get(serviceCase, 'referrals', [])
      .map((r) => ({ ...r.receiving_provider, program: r.receiving_program })),
    type: 'referrals_referral',
    permissions: {
      ...serviceCase.permissions,
      can_close: {
        authorization: true,
        message: [],
      },
    },
    _meta: { _type: 'cases_servicecase' },
  };
  usePopulate(
'service_authorization',
'service_authorization',
serviceCase,
  { queryConfig: { placeholderData: undefined } },
);
  usePopulate(
'insurance',
'insurance',
_.get(serviceCase, 'service_authorization'),
  { queryConfig: { placeholderData: undefined } },
);
  usePopulate(
'plan',
'plan',
_.get(serviceCase, 'service_authorization.insurance'),
  { queryConfig: { placeholderData: undefined } },
);
  const isSensitive = _.get(referral, 'receiving_provider.sensitive', false);
  const contact = serviceCase.person;
  const description = (serviceCase && serviceCase.description) || '';
  const status = 'draft';

  return (
    <BaseCard className="service-case-options detail-info">
      <BaseCardHeader noBorder title={`${contact.full_name}'s Referral`}>
        {referralStatuses.draft({ showText: false }) }
        <div className="detail-info__status-text">
          <DetailStatusText enumsKey="referrals" detailObj={{ ...serviceCase, status }} />
        </div>
      </BaseCardHeader>
      <Divider style={dividerStatusStyles('draft')} />
      <BaseCardBody withPadding>
        <ReferralBasicInformation
          referral={referral}
          groupId={groupId}
          isCC={isCoordinationGroup}
        />
        <Divider className="detail-info__horizontal-divider mb-2 mt-4" />
        <ReferralSummaryWrapper
          contact={serviceCase.person}
          groupId={groupId}
          referral={referral}
        >
          <DetailSummary
            className={`detail-summary ${isSensitive ? 'detail-summary--sensitive' : ''}`}
            editable={showEdit(referral, serviceCase.person, groupId)}
            description={description}
            item={referral}
            descriptionTitle="Referral Description"
            descriptionEditSubtitle={(
              <InfoPanel
                className="detail-summary__info-panel"
                message={
                  isSensitive ?
                    [PHI_INFO_MESSAGE, CFR_PART_2_CASE_MESSAGE].join(' ') :
                    PHI_INFO_MESSAGE
                }
              />
            )}
            descriptionSubtitle={isSensitive ? (
              <InfoPanel
                className="detail-summary__info-panel"
                message={`Reminder: ${CFR_PART_2_CASE_MESSAGE}`}
              />
            ) : undefined}
            status={status}
          />
        </ReferralSummaryWrapper>
        {!_.isEmpty(serviceCase.service_authorization) && (
          <>
            <Divider className="detail-info__horizontal-divider mb-4 mt-4" />
            <ReferralServiceAuthorizationInformation
              serviceAuthorization={serviceCase.service_authorization}
              showRequestedValues
            />
          </>
        )}
        <Divider className="detail-info__horizontal-divider mb-4 mt-4" />
        <div className="row">
          <div className="col-md-6">
            <ServiceCaseAssessmentsWrapper
              serviceCase={serviceCase}
              contact={contact}
              assessments={caseAssessments}
              singleItemId={serviceCase.id}
            />
          </div>
          <div className="col-md-6 list-divider">
            <h5 className="detail-info__icon-label">Documents</h5>
            <DocumentUploader
              attachedDocuments={serviceCase.documents}
              contactId={contact.id}
              dropzoneName="case-detail-view"
              editable
              onAttachDocuments={props.onAttachDocuments}
              onRemoveDocument={props.onRemoveDocument}
              onRenameDocument={props.onRenameDocument}
            />
          </div>
        </div>
        <Divider className="detail-info__horizontal-divider mb-4 mt-4" />
        <div>
          <ConsentReminder />
          <ReferralDetailActionWrapper
            disableActions={!isReferralsAdminUser && type === 'newReferrals'}
            showActions={showActions}
            location={location}
            suggestedGroups={suggestedGroups}
            referral={referral}
            goToNotes={goToNotes}
            falsifyActionBool={falsifyActionBool}
            isSendable={isSendable}
          />
        </div>
      </BaseCardBody>
    </BaseCard>
  );
}

DraftCaseOptions.propTypes = {
  isReferralsAdminUser: PropTypes.bool,
  isSendable: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  caseAssessments: PropTypes.array.isRequired,
  falsifyActionBool: PropTypes.func.isRequired,
  goToNotes: PropTypes.func.isRequired,
  serviceCase: PropTypes.shape({
    contact: PropTypes.shape({
      id: PropTypes.string.isRequired,
      full_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  isCoordinationGroup: PropTypes.bool.isRequired,
  onAttachDocuments: PropTypes.func.isRequired,
  onRemoveDocument: PropTypes.func.isRequired,
  onRenameDocument: PropTypes.func.isRequired,
  showActions: PropTypes.bool.isRequired,
  suggestedGroups: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

DraftCaseOptions.defaultProps = {
  isReferralsAdminUser: false,
};

DraftCaseOptions.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const userGroup = state.user.groups.find((g) => g.group.id === state.session.groupId);
  const isReferralsAdminUser = userGroup && userGroup.roles.map((r) => r.name).includes('Referrals Admin User');

  return {
    isReferralsAdminUser,
  };
}

export default connect(mapStateToProps)(DraftCaseOptions);
