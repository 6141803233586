import { isEmpty } from 'lodash';

function communicationTypesInitialValues(communicationTypes = {}) {
  if (isEmpty(communicationTypes)) {
    return {};
  }
  return communicationTypes.reduce((acc, curr) => ({
    ...acc,
    [curr]: true,
  }), {});
}

export default communicationTypesInitialValues;
