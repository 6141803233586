import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@unite-us/ui';

const SubTitle = ({ subTitle, showBackButton, onBackButtonClick }) => {
  function backButton() {
    if (showBackButton) {
      return (
        <Button
          secondary
          onClick={onBackButtonClick}
          style={{ marginLeft: '10px', display: 'inline-block' }}
        >
          Back
        </Button>
      );
    }
    return null;
  }
  if (!subTitle && !showBackButton) {
    return null;
  }
  return (
    <h5>
      {subTitle}
      {backButton()}
    </h5>
  );
};

SubTitle.propTypes = {
  onBackButtonClick: PropTypes.func,
  showBackButton: PropTypes.bool.isRequired,
  subTitle: PropTypes.string,
};

export default SubTitle;
