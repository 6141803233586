import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

function DetailActionWrapper(props) {
  const { buttonComp } = props;

  if (_.isEmpty(buttonComp)) {
    return null;
  }

  return (
    <div
      className="detail-action-wrapper"
    >
      {buttonComp}
    </div>
  );
}

DetailActionWrapper.propTypes = {
  buttonComp: PropTypes.node.isRequired,
};

export default DetailActionWrapper;
