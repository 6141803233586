import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { isEmpty } from 'lodash';
import { BaseCard, BaseCardHeader, BaseCardBody, Icon, Button } from '@unite-us/ui';
import {
  EmailAddress,
  HoursOfOperationV2,
  Locations,
  PhoneNumbers,
} from '@unite-us/client-utils';
import mapProviderIdToProps from 'src/components/Organization/utils/mapProviderIdToProps';
import { useFindProvider, useLocations } from 'src/components/Organization//api/hooks/v1';
import OrganizationHeader from 'src/components/Organization/components/OrganizationHeader';
import { ORG_TABS } from 'src/components/Organization/constants';
import { theme } from 'src/../tailwind.config';
import DivReset from 'src/components/Organization/components/DivLineHeightReset';
import EditLink from 'src/components/Organization/components/EditLink';
import PageHeading from 'src/components/Organization/components/PageHeading';
import { primaryFirstEmailSort } from 'src/common/form/Profile/EditEmailAddresses/utils';
import useTrackPageView from 'src/components/Organization/utils/useTrackPageView';
import { ORG_SETTINGS } from 'src/common/utils/EventTracker/utils/eventConstants';
import { Spinner } from 'src/common/spinners';
import { browserHistory } from 'common/utils/browserHistory';
import { orgAdminDeactivatedPrograms, orgAdminEnhancements } from 'src/common/utils/FeatureFlags/flags';
import Programs from './programs';
import OrganizationSubheader from '../components/OrganizationSubheader';

const EditLocationLink = ({ location = null }) => (
  <EditLink
    className="-mt-8"
    data-test-element="edit_location_link"
    to={`/organization/settings/locations/${location?.id}/edit`}
    label="Edit Location"
  />
);

EditLocationLink.propTypes = {
  location: PropTypes.object,
};

EditLocationLink.defaultProps = {
  location: null,
};

const Organization = (
  {
    currentProviderId,
    location,
    orgAdminDeactivatedPrograms: orgAdminDeactivatedProgramsFlag,
    orgAdminEnhancements: orgAdminEnhancementsFlag,
  },
) => {
  useTrackPageView(ORG_SETTINGS.orgViewed);

  const { isFetching: isFetchingProvider, data: provider } = useFindProvider({ providerId: currentProviderId });
  const { isFetching: isFetchingLocations, data: locations } = useLocations({ providerId: currentProviderId });
  const sortedEmails = useMemo(() => primaryFirstEmailSort(provider?.email_addresses), [provider?.email_addresses]);
  const isEditable = provider?.state === 'draft' || provider?.state === 'active';
  const showDraftStepper = orgAdminEnhancementsFlag && provider?.state === 'draft';
  const pageHeadingClasses = showDraftStepper ? 'text-center' : null;
  const pageHeadingContent = showDraftStepper ? 'Organization' : provider?.name;

  if (isFetchingProvider || isFetchingLocations) {
    return (<Spinner center />);
  }

  if (!provider || !locations) {
    return null;
  }
  return (
    <>
      <OrganizationHeader currentTab={ORG_TABS.organization} showOrgStatus location={location} />
      <DivReset className="container max-w-screen-lg space-y-4 pt-8 pb-4">
        <header>
          <PageHeading text={pageHeadingContent} classes={pageHeadingClasses} />
        </header>
        <OrganizationSubheader
          providerState={provider?.state}
          orgAdminDeactivatedPrograms={orgAdminDeactivatedProgramsFlag}
        />
        <BaseCard>
          <BaseCardHeader data-test-element="org_about_section" title="About">
            {isEditable && (
              <EditLink
                to="/organization/settings/edit"
                data-test-element="edit_org_link"
                label="Edit Organization Info"
              />
            )}
          </BaseCardHeader>
          <BaseCardBody className="p-4">
            <DivReset
              className={'org-details'}
              data-test-element="org_description"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: provider.description }}
            />
            <h4 className="mt-2 leading-5">Organization Contact Info</h4>
            <div className="org-info pb-3">
              <DivReset data-test-element="org_website" className="flex items-center leading-6">
                <Icon icon="V2Website" className="mr-0.75em" color={theme.extend.colors['action-blue']} />
                <p>
                  {provider.website_url || 'Website'}
                </p>
              </DivReset>
              {provider?.phone_numbers?.length > 0 ? (
                <PhoneNumbers phone_numbers={provider.phone_numbers} />
              ) : (
                <DivReset data-test-element="org_phone_label" className="leading-6 flex items-center">
                  <Icon icon="V2Phone" className="mr-0.75em" color={theme.extend.colors['action-blue']} />
                  Phone
                </DivReset>
              )}

              {sortedEmails.map(({ email_address }, index) => (
                <DivReset
                  data-test-element={`org_email_address_${index}`}
                  className="leading-6 flex items-center" key={email_address}
                >
                  <Icon icon="V2Email" className="mr-0.75em" color={theme.extend.colors['action-blue']} />
                  <EmailAddress emailAddress={email_address} disableLink />
                </DivReset>
              ))}
              {!sortedEmails.length && (
                <DivReset className="leading-6 flex items-center">
                  <Icon
                    data-test-element="org_email_label" icon="V2Email" className="mr-0.75em"
                    color={theme.extend.colors['action-blue']}
                  />
                  Email
                </DivReset>
              )}

              <DivReset data-test-element="org_hours" className="flex">
                {/* API returns null for hours, but wary an empty object my be
                returned under some circumstances - using isEmpty to handle either. */}
                {!isEmpty(provider.hours) && (
                  <DivReset className="mt-0.5">
                    <HoursOfOperationV2
                      hours={provider.hours}
                      showAll
                      showToggle
                      showHoursIcon
                    />
                  </DivReset>
                )}
                {isEmpty(provider.hours) && (
                  <DivReset className="flex items-center leading-6">
                    <Icon icon="IconClock" className="mr-0.75em" color={theme.extend.colors['action-blue']} />
                    Hours
                  </DivReset>
                )}
              </DivReset>
            </div>
            <DivReset
              data-test-element="org_locations_section"
              className="-mx-4 pt-4 px-4 border-t border-solid border-dark-fill-blue"
            >
              <DivReset className="flex mb-3">
                <p className="text-dark-grey">
                  Add all organization and program locations here.
                </p>
                {isEditable && (
                  <Link
                    data-test-element="add_location_link"
                    className="flex items-center ml-auto"
                    to="/organization/settings/locations/new"
                  >
                    <Icon icon="V2Add" color={theme.extend.colors['action-blue']} />
                    <p className="ml-1 text-action-blue">Add Location</p>
                  </Link>
                )}
              </DivReset>
              <Locations
                locations={locations}
                clickableAddress
                showMore
                locationAdditionalContentComponent={isEditable ? EditLocationLink : null}
              />
            </DivReset>
          </BaseCardBody>
        </BaseCard>

        {provider?.state === 'draft' && (
          <p className="text-red bold" data-testid="add-location-warning">
            Make sure to add a location if your program operates out of a physical location.
          </p>
        )}

        <Programs
          readOnly={!isEditable}
          providerId={provider.id}
          providerLocations={locations}
          providerState={provider?.state}
        />

        {provider?.state === 'draft' && (
          <BaseCard>
            <BaseCardBody withPadding>
              <div
                className="flex flex-col items-center p-3"
                data-testid="org-user-section"
              >
                <div>
                  {orgAdminEnhancementsFlag ? 'Have you finished editing your organization, locations, and programs?' :
                  'Have you finished editing your organization, locations and programs?'}
                </div>
                <div className="font-bold pt-1 pb-4">
                  Next, add users to your organization.
                </div>
                <Button
                  label="Users"
                  className="button_link"
                  iconRight={
                    (
                      <Icon
                        icon="IconChevronRight"
                        color={theme.extend.colors['action-blue']}
                      />
                    )
                  }
                  onClick={() => browserHistory.push('/organization/settings/users')}
                />
              </div>
            </BaseCardBody>
          </BaseCard>
        )}

        {provider?.state === 'active' && (
          <div
            className="flex"
            data-testid="org-user-button"
          >
            <Button
              label="Users"
              className="ml-auto"
              iconRight={
                (
                  <Icon
                    icon="IconChevronRight"
                    className="fill-current"
                  />
                )
              }
              onClick={() => browserHistory.push('/organization/settings/users')}
              outline
            />
          </div>
        )}
      </DivReset>
    </>
  );
};

Organization.propTypes = {
  currentProviderId: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  orgAdminDeactivatedPrograms: PropTypes.bool,
  orgAdminEnhancements: PropTypes.bool,
};

Organization.defaultProps = {
  orgAdminDeactivatedPrograms: false,
  orgAdminEnhancements: false,
};

function mapStateToProps(state) {
  return {
    orgAdminDeactivatedPrograms: orgAdminDeactivatedPrograms(state),
    orgAdminEnhancements: orgAdminEnhancements(state),
    currentProviderId: mapProviderIdToProps(state).currentProviderId,
  };
}

// once orgAdminDeactivatedPrograms is decommissioned, replace with mapProviderIdToProps
export default connect(mapStateToProps)(Organization);
