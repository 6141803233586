import React, { useState } from 'react';
import {
  Badge,
  Icon,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  Button,
} from '@unite-us/ui';
import { tableWidth } from 'common/helpers/Tables';
import { Spinner } from 'common/spinners';
import { browserHistory } from 'common/utils/browserHistory';
import { useFindPayers } from 'src/components/Organization/api/hooks/v1/payerHooks';
import Pagination from 'src/common/Pagination/Pagination';
import ScrollToElement from 'src/common/utils/Form/ScrollToElement';
import { capitalize, isObject } from 'lodash';
import stateBgColor from 'common/utils/stateBgColor';
import EmptyTable from 'src/common/tables/EmptyTable/EmptyTable';
import SearchBar from 'src/common/SearchBar/SearchBar';
import { theme } from 'src/../tailwind.config';
import { DEFAULT_PAGE_SIZE } from 'src/components/Backoffice/backofficeUtils';

const namespace = 'payers';

const Payers = () => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const { data: payersResponse, isFetching } = useFindPayers(
    searchTerm,
    {
      page: {
        size: pageSize,
        number: pageNumber,
      },
    },
  );
  const { data: payers, paging } = payersResponse.data;

  const ScrollToElementEvent = () => {
    ScrollToElement(document.getElementById('TopOfPayers'));
  };

  const addPayer = () => {
    browserHistory.push({ pathname: '/backoffice/payers/new' });
  };

  const viewPayer = (id) => {
    browserHistory.push({ pathname: `/backoffice/payers/${id}` });
  };

  const SearchPayerName = (data) => {
    setPageNumber(1);
    setSearchTerm(data?.search);
  };

  return (
    <>
      <div
        data-testid="payers-header"
        className="flex px-8 pt-8 pb-2 bg-medium-fill-blue"
        id="TopOfPayers"
      >
        <div className="w-9/12 flex flex-row relative">
          <h1 className="pt-1 pb-2 font-bold">Payers</h1>
          <SearchBar onSubmit={SearchPayerName} dataTestId="payers-search-bar" />
        </div>
        <div className="w-3/12 flex justify-end">
          <Button
            id="add-payer-button"
            iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="V2Add" />}
            className="py-1 px-4 text-action-blue rounded normal-case add_button"
            label="Create New Payer"
            onClick={() => addPayer()}
            data-testid="create-payer-btn"
          />
        </div>
      </div>
      <div data-testid="payers-table-container" style={{ padding: '30px' }}>
        {isFetching ? <Spinner /> : (
          <>
            <Table id="payersTable">
              <TableHeader>
                <TableRow>
                  <TableHeaderColumn style={{ width: tableWidth('half') }}>
                    Name
                  </TableHeaderColumn>
                  <TableHeaderColumn style={{ width: tableWidth('medium') }}>
                    Status
                  </TableHeaderColumn>
                  <TableHeaderColumn style={{ width: tableWidth('medium') }}>
                    External ID
                  </TableHeaderColumn>
                  <TableHeaderColumn style={{ width: tableWidth('medium') }}>
                    Payer WQ Interactive
                  </TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                {payers.filter(isObject).map((payer) => (
                  <TableRow
                    className={`${namespace}__row`}
                    key={`payer-row-${payer.id}`}
                    onCellClick={() => viewPayer(payer.id)}
                    dataTestId={`${payer.name}-selectable-row`}
                  >
                    <TableRowColumn style={{ width: tableWidth('half') }}>
                      {payer.name}
                    </TableRowColumn>
                    <TableRowColumn style={{ width: tableWidth('medium') }}>
                      <Badge
                        className="px-4"
                        text={capitalize(payer.state)}
                        color={stateBgColor(payer.state)}
                      />
                    </TableRowColumn>
                    <TableRowColumn style={{ width: tableWidth('medium') }}>
                      {payer.external_id}
                    </TableRowColumn>
                    <TableRowColumn style={{ width: tableWidth('medium') }}>
                      {payer.payer_wq_interactive ? 'Yes' : 'No'}
                    </TableRowColumn>
                  </TableRow>
              ))}
              </TableBody>
            </Table>
            {
              (paging.total_count === 0) &&
              (
                <EmptyTable
                  className={'p-6 border border-t-0 border-solid border-light-border-blue'}
                  message={'No Results Found'}
                />
              )
            }
            <div className="p-6">
              <Pagination
                pageNumber={pageNumber}
                pageSize={pageSize}
                totalItemCount={paging.total_count}
                totalPageCount={paging.total_pages}
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                scrollPaginationElement={ScrollToElementEvent}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Payers;
