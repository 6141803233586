import { SET_REDIRECT_PATH } from 'actions';
import querystring from 'query-string';
import { get } from 'lodash';

export function groupIdFromSearchParams(search = '') {
  return get(querystring.parse(search), 'groupId');
}

export default function setRedirectPath(location = {}) {
  if (typeof location !== 'object') {
    throw new Error('wrong param type for setRedirectPath action');
  }

  let redirectPath = null;
  if (location.pathname) {
    redirectPath = location.pathname;
  }

  if (location.search) {
    redirectPath += location.search;
  }

  return (dispatch) => {
    const groupId = groupIdFromSearchParams(location.search);
    return dispatch({ type: SET_REDIRECT_PATH, path: redirectPath, groupId });
  };
}
