import axios from 'axios';
import Notifier from 'common/helpers/Notifier';
import { setDashboardRefetch } from 'actions/Dashboard';
import removeDeletedDraftReferral from 'actions/Referral/removeDeletedDraftReferral';

export default function deleteReferral(groupId, referralId, refetch = false) {
  const request = axios.delete(`/groups/${groupId}/referrals/drafts/${referralId}`);

  return (dispatch) => {
    function onSuccess(response) {
      Notifier.dispatch(response.status, 'Referral Successfully Deleted');
      if (refetch) {
        dispatch(removeDeletedDraftReferral(referralId));
        dispatch(setDashboardRefetch());
      }
      return response;
    }

    return request.then(onSuccess);
  };
}
