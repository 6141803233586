import {
  BUILD_SERVICE_TYPES,
  SET_CURRENT_GROUP,
} from 'actions';
import { saveCurrentGroupIdToSessionStorage } from 'common/utils/utils';

export default function setCurrentGroup(groupId) {
  const group = groupId === 'undefined' ? undefined : groupId;
  saveCurrentGroupIdToSessionStorage(group);

  return (dispatch, getState) => {
    dispatch({ type: BUILD_SERVICE_TYPES, globalState: getState() });
    return dispatch({
      groupId: group,
      type: SET_CURRENT_GROUP,
    });
  };
}
