import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  isRouteActive,
  isRouteDisabled,
} from '../utils';
import { selectionKeyCodes } from '../constants';

class RouteNavigationSubItem extends Component {
  constructor(props) {
    super(props);

    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
  }

  handleOnClick() {
    const { subItem } = this.props;
    this.props.onClick(subItem.path, subItem);
    this.props.handleSubItemClick();
  }

  handleOnKeyDown(e) {
    const {
      onClick,
      subItem,
    } = this.props;

    if (selectionKeyCodes.has(e.keyCode)) {
      e.preventDefault();
      return onClick(subItem.path, subItem);
    }
    return false;
  }

  render() {
    const { id, location, subItem } = this.props;

    const tertiaryListClass = () => classNames({
      'route-navigation-subitem': true,
      'route-navigation-subitem--active': isRouteActive(subItem.path, location.pathname),
      'route-navigation-subitem--disabled': isRouteDisabled(subItem),
    });

    return (
      <li
        id={id}
        className={tertiaryListClass()}
        onClick={this.handleOnClick}
        onKeyDown={this.handleOnKeyDown}
        role="button"
        tabIndex={0}
      >
        {subItem.label}
      </li>
    );
  }
}

RouteNavigationSubItem.propTypes = {
  handleSubItemClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  subItem: PropTypes.object.isRequired,
};

export default RouteNavigationSubItem;
