import Notifier from 'common/helpers/Notifier';
import { useInvalidateQueries, useUpdateRecord } from 'src/api/APIHooks';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { ENROLLMENT_STATUSES, REQUEST_TYPES } from '../constants';

export const useUpdateEnrollmentRequest = () => {
  const invalidateQueries = useInvalidateQueries();
  const { updateRecord } = useUpdateRecord('enrollment_request', {
    mutationConfig: {
      onError: () => Notifier.dispatch('error', 'Something went wrong...'),
      onSettled: () => invalidateQueries(),
    },
  });

  return async (id, updatedFields, successMessage) => {
    const updateResponse = await updateRecord(id, updatedFields);
    if (isHttpSuccess(updateResponse.status)) {
      Notifier.dispatch(updateResponse.status, successMessage);
    }
  };
};

export const useRejectEnrollmentRequest = () => {
  const update = useUpdateEnrollmentRequest();

  return async (request, reason, note) => update(
    request.id,
    {
      enrollment_status: ENROLLMENT_STATUSES.rejected,
      enrollment_rejection_reason: reason,
      rejection_note: note,
    },
    `${request.request_type === REQUEST_TYPES.disenroll ? 'Disenrollment' : 'Enrollment'} request rejected.`,
  );
};

export const useAcceptEnrollmentRequest = () => {
  const update = useUpdateEnrollmentRequest();

  return async (request) => update(
    request.id,
    {
      enrollment_status: ENROLLMENT_STATUSES.accepted,
    },
    `${request.request_type === REQUEST_TYPES.disenroll ? 'Disenrollment' : 'Enrollment'} request accepted.`,
  );
};
