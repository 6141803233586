import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@unite-us/ui';

const PostNoteButton = (props) => (
  <div className="post-note-button">
    <Button {...props} />
  </div>
);

PostNoteButton.propTypes = {
  label: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  primary: PropTypes.bool.isRequired,
};

PostNoteButton.defaultProps = {
  label: 'Post Note',
  primary: true,
};

export default PostNoteButton;
