import _ from 'lodash';
import defaultLabels from 'src/constants/labels';

function labelCustomization(state) {
  // Confirm the flag state is fully initialized and available (not done in flags.js)
  if (_.isObject(state) && !_.isEmpty(state) && _.isObject(state.flags) && !_.isEmpty(state.flags)) {
    const labelValue = ({ key, defaultValue }) => {
      // Our LaunchDarkly flag names are stored using camel case in the redux store :-/
      const flag = _.camelCase(key);
      // Use our default in case this flag does not exist (we should log this ideally)
      return state.flags?.[flag] ?? defaultValue;
    };

    const customizedLabels = {
      CareCoordinator: labelValue({
        key: 'crtb-1125-rename-care-coordinator',
        defaultValue: defaultLabels.CareCoordinator,
      }),
    };

    const allLabels = { ...defaultLabels, ...customizedLabels };
    return allLabels;
  }

  return defaultLabels;
}

export default labelCustomization;
