import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog } from '@unite-us/ui';

class AutoLogoutDialog extends Component {
  constructor(props) {
    super(props);
    this.closeDialog = this.closeDialog.bind(this);
    this.state = {
      counter: null,
    };
  }

  componentDidMount() {
    this.modal.openDialog();
    this.setState({ counter: this.props.timeRemaining });

    this.counterInterval = setInterval(() => {
      this.setState((prevState) => ({
        ...prevState,
        counter: prevState.counter - 1,
      }));
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.counterInterval);
  }

  closeDialog() {
    this.modal.closeDialog();
    this.props.onClickContinue();
  }

  render() {
    const {
      onClickContinue,
      onClickLogout,
    } = this.props;

    const {
      counter,
    } = this.state;

    return (
      <div style={{ height: 'auto' }}>
        <Dialog
          ref={(el) => { this.modal = el; }}
          onRequestClose={this.closeDialog}
          size="normal"
          title="You have been idle for a while "
          modal
          actions={(
            <div>
              <Button
                onClick={onClickLogout}
                label="Logout"
              />
              <Button
                label="Continue"
                onClick={onClickContinue}
                primary
                style={{ marginLeft: '20px' }}
              />
            </div>
          )}
        >
          <p>
            To maintain your client&apos;s privacy, Unite Us will automatically log off in {counter} seconds.
            Press Continue to stay on the page.
          </p>
        </Dialog>
      </div>
    );
  }
}

AutoLogoutDialog.propTypes = {
  onClickContinue: PropTypes.func.isRequired,
  onClickLogout: PropTypes.func.isRequired,
  timeRemaining: PropTypes.number.isRequired,
};

export default AutoLogoutDialog;
