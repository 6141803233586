import { get } from 'lodash';
import { browserHistory } from 'common/utils/browserHistory';
import { goToCase } from 'src/components/Cases/utils/routing';
import { goToReferral } from 'src/components/Referrals/utils/routing';
import { getItemResult } from '.';
import { MODELS } from '../constants';

const { contact, referral, servicecase } = MODELS;

function goToSearchResult(model, result) {
  const resultItem = getItemResult(result);
  const contactId = `${get(resultItem, 'id', '')}`;
  const facesheetPath = `/facesheet/${contactId}`;

  switch (model) {
    case contact: {
      return browserHistory.push(facesheetPath);
    }

    case referral:
      return goToReferral(resultItem);

    case servicecase: {
      return goToCase(resultItem);
    }

    default:
      return browserHistory.push('/search');
  }
}

export default goToSearchResult;
