import {
  get,
  last,
  split,
} from 'lodash';
import addElements from './addElements';
import parsePutUrl from './parsePutUrl';
import addReferrals from './addReferrals';
import updateReferrals from './updateReferrals';
import updateCases from './updateCases';
import updateContacts from './updateContacts';
import addCases from './addCases';
import updateInteractables from './updateInteractables';
import updateAssistanceRequests from './updateAssistanceRequests';

function handlePut(state, request, payload = [], currentSection, currentGroup) {
  const url = get(request, 'url', false);

  if (!url) { return state; }

  const urlData = parsePutUrl(url);
  const collectionType = get(urlData, 'collection');

  if (!get(urlData, 'matched', false)) { return state; }

  switch (collectionType) {
    case 'referrals':
      return updateReferrals(state, payload, urlData, currentSection, currentGroup);
    case 'contacts':
      return updateContacts(state, payload, urlData, currentSection);
    case 'cases':
      return updateCases(state, payload, urlData, currentSection);
    case 'assistance-requests':
      return updateAssistanceRequests(state, payload, urlData, currentSection);
    default:
      return state;
  }
}

function handlePost(state, request, payload = [], currentSection, currentGroup) {
  const url = get(request, 'url', false);

  if (!url) { return state; }

  const collectionType = last(split(url, '/'));

  switch (collectionType) {
    case 'referrals':
      return addReferrals(state, payload, currentSection, currentGroup);
    case 'contacts':
      return addElements(state, payload, 'clients', currentSection);
    case 'cases':
      return addCases(state, payload, currentSection);
    case 'assistance-requests':
      return addElements(state, payload, 'assistanceRequests', currentSection);
    case 'draft':
      return addElements(state, payload, 'draftReferrals', currentSection);
    case 'interactions':
      return updateInteractables(handlePut, state, request, payload, currentSection, currentGroup);
    default:
      return state;
  }
}

export default function socketHandler(state, payload, currentSection, currentGroup) {
  const request = get(payload, 'request', false);
  const verb = get(request, 'verb', false);
  const records = get(payload, 'payload', []);

  if (!request || !verb) { return state; }

  switch (verb) {
    case 'POST':
      return handlePost(state, request, records, currentSection, currentGroup);
    case 'PATCH':
    case 'PUT':
      return handlePut(state, request, records, currentSection, currentGroup);
    default:
      return state;
  }
}
