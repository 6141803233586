import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

const Fields = ({
    names,
    subscription,
    fieldsState,
    children,
    originalRender,
  }) => {
    if (!names.length) {
      return (originalRender || children)(fieldsState);
    }
    const [name, ...rest] = names;
    return (
      <Field name={name} subscription={subscription}>
        {(fieldState) => (
          <Fields
            names={rest}
            subscription={subscription}
            originalRender={originalRender || children}
            fieldsState={{ ...fieldsState, [name]: fieldState }}
          />
        )}
      </Field>
    );
  };

  Fields.defaultProps = {
    children: null,
    fieldsState: null,
    originalRender: null,
    subscription: undefined,
  };

  Fields.propTypes = {
    names: PropTypes.array.isRequired,
    subscription: PropTypes.object,
    fieldsState: PropTypes.object,
    children: PropTypes.func,
    originalRender: PropTypes.func,
  };

  export default Fields;
