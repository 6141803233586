import moment from 'moment';
import Notifier from 'common/helpers/Notifier';
import _ from 'lodash';
import { setDashboardRefetch } from 'actions/Dashboard';
import { CLOSE_ASSISTANCE_REQUEST } from 'actions';
import { coreApi } from 'src/api/config';
import createNote from 'src/actions/Case/Contact/Group/createNote';

const SUCCESS_MESSAGE = 'Assistance Request Successfully Closed';

const onError = (error) => Notifier.handleErrors(error);

const closeAssistanceRequest = (groupId, networkId, assistanceRequest, closing = {}, refetch = false) => (
  async (dispatch) => {
    const caseId = _.get(assistanceRequest, 'id');
    const opened_date = _.get(assistanceRequest, 'opened_date');
    try {
      const payload = await coreApi.updateRecord('case', caseId, {
        outcome: closing.outcome_id,
        ...(opened_date ?
          { closed_date: moment.unix(closing.close_date).utc().format('YYYY-MM-DD') } : {}),
        network: closing.network_id ? closing.network_id : networkId,
      });
      await dispatch(createNote({
        interaction: {
          note: closing.note,
          occurred_on: closing.exited_at,
          category: 'general',
        },
        subjectType: 'case',
        subject: caseId,
        opts: { sendSuccessNotification: false },
        context: 'closed',
      }));

      Notifier.dispatch(payload.status, SUCCESS_MESSAGE);
      dispatch({
        type: CLOSE_ASSISTANCE_REQUEST,
        payload,
        requestId: caseId,
      });

      if (refetch) {
        dispatch(setDashboardRefetch());
      }

      return payload;
    } catch (error) {
      return onError(error);
    }
  }
);

export default closeAssistanceRequest;
