import _ from 'lodash';

/*
  user object sample:
  {
    groups: [
      {
        group: {
          id: 'group_id_123'
        },
        roles: [
          {
            role_type: 'primary',
            name: 'Case Manager',
          }
        ]
      }
    ],
  }
*/
function hasUserRole({
  groupId, name, roleType, user,
}) {
  return _.chain(user)
    .get('groups', [])
    .find((group) => group.group.id === groupId)
    .get('roles', [])
    .some({
      role_type: roleType,
      name,
    })
    .value();
}

export default hasUserRole;
