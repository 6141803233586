import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';
import {
  ENTER_KEY_CODE,
  SPACEBAR_KEY_CODE,
} from '../constants';
import '../stylesheets/homeLink.scss';

class HomeLink extends Component {
  constructor() {
    super();
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onKeyDown({ keyCode }) {
    const {
      onClick,
    } = this.props;

    if (keyCode === ENTER_KEY_CODE || keyCode === SPACEBAR_KEY_CODE) {
      return onClick();
    }
    return false;
  }

  render() {
    const {
      onClick,
    } = this.props;

    return (
      <a
        id="nav-home"
        aria-label="Unite Us"
        className="home-link"
        onClick={onClick}
        onKeyDown={this.onKeyDown}
        role="button"
        tabIndex={0}
      >
        <Icon
          className="home-link__home-logo"
          color="#FFFFFF"
          icon="LogoShieldText"
          size={123}
        />
      </a>
    );
  }
}

HomeLink.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default HomeLink;
