import {
  useFind,
} from 'src/api/APIHooks';
import { defaultQueryConfig } from '../apiHookOptions';

export const useFindNetworksByName = (name = '', state = '', params = { queryConfig: defaultQueryConfig }) => useFind(
  'network',
  {
    name,
    state,
  },
  {
    queryConfig: {
      enabled: !!name,
      placeholderData: undefined,
    },
    ...params,
  },
);

export const useFindNetworksById = (networkIds = '') => useFind(
  'network',
  { id: networkIds },
  {
    queryConfig: {
      enabled: !!networkIds,
      placeholderData: undefined,
      ...defaultQueryConfig,
    },
  },
);
