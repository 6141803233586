import { isEmpty } from 'lodash';

const isCurrentSection = (currentSection, section) => {
  if (isEmpty(currentSection)) {
    return false;
  }
  return currentSection === section;
};

export { isCurrentSection };
