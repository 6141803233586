import { get } from 'lodash';

const getCurrentScreening = (state, id) => {
  const currentScreening = get(state, 'screenings.currentScreening', {});
  if (id && get(currentScreening, 'id') === id) {
    return currentScreening;
  }
  return {};
};

export default getCurrentScreening;
