import { get } from 'lodash';

/**
 * Merge included key response into the top level with attributes and relationships.
 * Ex: mergeIncludedById({ data, included, includedFields: ['subscriber', 'package']})
 * Returns { attributes: {}, relationships: {}, subscriber: {}, package: {} }
 * subscriber and package is manually added via function below.
 * @param { array } data - payload data response from the API
 * @param { array } included - payload included response from the API,
 * @param { array } includedFields - Array of strings used to generate a object to merge to the original object
 */
const mergeIncludedById = ({ data, included, includedFields }) => {
  const mergeable = {};

  const result = data.map((responseObj) => {
    includedFields.forEach((field) => {
      const includedValue = included.find((include) => (
        include.id === get(responseObj, `relationships[${field}].data.id`)
      ));

      if (includedValue) {
        mergeable[field] = includedValue;
      }
    });

    return {
      ...responseObj,
      ...mergeable,
    };
  });

  return result;
};

export default mergeIncludedById;
