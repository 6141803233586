import { get, map, isEmpty } from 'lodash';

/*
This function is used to gather all service types from a
program. Some duplication is possible due to overlapping parent service types,
but not necessarily the children service types. We need to make sure we
collect all parent service types and all children service types associated
with the program without duplication
 */

function getProgramServiceTypes(program, services) {
  const servicesOffered = get(program, 'relationships.services.data');
  const servicesOfferedIds = map(servicesOffered, 'id');
  const allServices = !isEmpty(services) ? services : [];
  const programServices = allServices
    .filter((item) => servicesOfferedIds.includes(item.id))
    .map((item) => {
      const children = item.children.filter((c) => servicesOfferedIds.includes(c.id));
      return { ...item, children };
    });

  return programServices;
}

export default getProgramServiceTypes;
