import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BaseCard, BaseCardBody, BaseCardHeader, Button, Icon } from '@unite-us/ui';
import { useFindDirectoryConfiguration } from 'src/components/Backoffice/api/hooks/prdApi/DirectoryConfigurationHooks';
import { browserHistory } from 'common/utils/browserHistory';
import { theme } from 'src/../tailwind.config';
import { directoryConfigurationParser } from 'src/pages/backoffice/prds/utils/directoryConfigurationParser';
import { Spinner } from 'common/spinners';
import { fetchFeeSchedules } from 'src/common/utils/FeeSchedules/fetchFeeSchedules';
import PrdDetailsColorElement from './PrdDetailsColorElement';
import './PrdDetails.css';

const PrdDetails = (props) => {
  const {
    data: directory_configuration,
    isFetching: isFetchingDirectoryConfiguration,
  } = useFindDirectoryConfiguration(props.directory_configuration_id);

  const {
    font_families, colors, location_name, latitude, longitude, copyright_info, logo_url,
    external_api_key, counties, cities, states,
  } = directoryConfigurationParser(directory_configuration);

  const [feeScheduleObjects, setFeeScheduleObjects] = useState([]);

  const fetchSchedules = async (feeSchedules) => {
    const tempFeeScheduleObjects = await fetchFeeSchedules(feeSchedules);
    setFeeScheduleObjects(tempFeeScheduleObjects);
  };

  useEffect(() => {
    if (!isFetchingDirectoryConfiguration && directory_configuration?.fee_schedule_ids) {
        const feeSchedules = JSON.parse(directory_configuration.fee_schedule_ids);

        if (feeSchedules.length > 0) {
          fetchSchedules(feeSchedules);
        } else {
          setFeeScheduleObjects([]);
        }
    }
  }, [directory_configuration, isFetchingDirectoryConfiguration]);

  const EditDirectoryConfiguration = () => {
    browserHistory.push({
      pathname:
        `/backoffice/prds/${props.directory_id}/directory_configurations/${props.directory_configuration_id}/edit`,
    });
  };

  return (
    <div className="prd-base-card">
      <BaseCard className="prd-details p-8" noBorder>
        <BaseCardHeader title="PRD Details" noBorder className="px-4 font-bold">
          <Button
            id="edit-directory-details-button"
            iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="V2Edit" />}
            className="flex"
            label="Edit"
            onClick={EditDirectoryConfiguration}
            data-testid="edit-directory-details-button"
          />
        </BaseCardHeader>
        <BaseCardBody className="px-8 bg-white">
          {
            isFetchingDirectoryConfiguration && (
              <div className={
                'bg-white flex flex-col justify-center items-center text-center pt-40 pb-48 space-y-4'
              }
              >
                <Spinner />
              </div>
            )
          }
          {
            !isFetchingDirectoryConfiguration &&
            (
              <div className="grid grid-cols-5">
                <div className="col-start-1 pl-0 font-bold pb-3">
                  Font Family:
                </div>
                <div className="col-span-4 pb-3">
                  {font_families?.primary && (
                    <p data-testid="prd-primary-font">
                      {`${font_families.primary} (Header 1)`}
                    </p>
                  )}
                  {font_families?.secondary && (
                    <p data-testid="prd-secondary-font">
                      {`${font_families.secondary} (Header 2)`}
                    </p>
                  )}
                  {font_families?.body && (
                    <p data-testid="prd-body-font">
                      {`${font_families.body} (Body)`}
                    </p>
                  )}
                </div>
                <div className="col-start-1 pl-0 font-bold pb-3">
                  Color:
                </div>
                <div className="col-span-4 pb-3">
                  {colors?.primary && (
                    <PrdDetailsColorElement color={colors.primary} label="Primary" />
                  )}
                  {colors?.accent && (
                    <PrdDetailsColorElement color={colors.accent} label="Secondary" />
                  )}
                  {colors?.background && (
                    <PrdDetailsColorElement color={colors.background} label="Landing Page Background" />
                  )}
                  {colors?.link && (
                    <PrdDetailsColorElement color={colors.link} label="Link" />
                  )}
                  {colors?.mapMarker && (
                    <PrdDetailsColorElement color={colors.mapMarker} label="Map Marker" />
                  )}
                </div>
                <div className="col-start-1 pl-0 font-bold pb-3">
                  Default Location Name:
                </div>
                <div className="col-span-4 pb-3" data-testid="prd-location-name">
                  {location_name}
                </div>
                <div className="col-start-1 pl-0 font-bold pb-3">
                  Latitude:
                </div>
                <div className="col-span-4 pb-3" data-testid="prd-latitude">
                  {latitude}
                </div>
                <div className="col-start-1 pl-0 font-bold pb-3">
                  Longitude
                </div>
                <div className="col-span-4 pb-3" data-testid="prd-longitude">
                  {longitude}
                </div>
                <div className="col-start-1 pl-0 font-bold pb-5">
                  Searches Include Resources In:
                </div>
                <div className="col-span-4 pb-5">
                  States:  {states}
                  <br />
                  Counties:  {counties}
                  <br />
                  Cities:  {cities}
                </div>
                <div className="col-start-1 pl-0 font-bold pb-3">
                  Footer Text:
                </div>
                <div className="col-span-4 pb-3" data-testid="prd-copyright-info">
                  {copyright_info}
                </div>
                <div className="col-start-1 pl-0 font-bold pb-5">
                  Associated Fee Schedules:
                </div>
                <div className="col-span-4 pb-5">
                  {feeScheduleObjects.map((feeScheduleObject) => (
                    <div key={feeScheduleObject.value}>
                      {feeScheduleObject.display_name}
                    </div>
                  ))}
                </div>
                <div className="col-start-1 pl-0 font-bold pb-3">
                  Logo:
                </div>
                <div className="col-span-4 pb-3">
                  {logo_url && (
                    <img
                      data-testid="directory-detail__logo"
                      className="logo"
                      alt="PRD Logo"
                      src={logo_url}
                    />
                  )}
                </div>
                <div className="col-start-1 pl-0 font-bold pb-3 ">
                  External API Key:
                </div>
                <div className="col-span-4 pb-3 break-words">
                  {external_api_key}
                </div>
              </div>
            )
          }
        </BaseCardBody>
      </BaseCard>
    </div>
  );
};

PrdDetails.propTypes = {
  directory_id: PropTypes.string.isRequired,
  directory_configuration_id: PropTypes.string.isRequired,
};

export default PrdDetails;
