import React from 'react';
import PropTypes from 'prop-types';
import BrowseMapButton from 'src/components/Browse/BrowseMapButton';
import { caseOONGroupHeaderMessage } from '../../utils';

const CaseOONGroupHeader = ({
  oonGroupsEmpty,
  toggleBrowse,
}) => (
  <header className="case-oon-groups-selector__header mb-one">
    <h5>Suggested Out of Network Organizations</h5>
    <p className="case-groups-selector__hint">
      {
        caseOONGroupHeaderMessage(oonGroupsEmpty)
      }
      {
        !oonGroupsEmpty && (
          <span className="case-oon-group-header__browse-link">
            Click
            <BrowseMapButton
              id="browse-map-button"
              onClick={toggleBrowse}
            />
            for more options.
          </span>
        )
      }
    </p>
  </header>
);

CaseOONGroupHeader.propTypes = {
  oonGroupsEmpty: PropTypes.bool,
  toggleBrowse: PropTypes.func.isRequired,
};

CaseOONGroupHeader.defaultProps = {
  oonGroupsEmpty: false,
};

export default CaseOONGroupHeader;
