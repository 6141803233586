import { isHttpError } from 'common/utils/Error';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { apiV4 } from 'src/api/config';
import {
  SUBMIT_PAYMENT_PROVIDED_SERVICE_STARTED,
  SUBMIT_PAYMENT_PROVIDED_SERVICE_SUCCESS,
  SUBMIT_PAYMENT_PROVIDED_SERVICE_ERROR,

} from 'actions';
import Notifier from 'common/helpers/Notifier';
import _ from 'lodash';

const buildService = ({ providedService, caseId, programId }) => ({
  state: 'submitted',
  case: caseId,
  program: programId,
  procedure_codes: !_.isEmpty(providedService.procedure_codes) ?
    [providedService.procedure_codes[0]] : undefined,
  procedure_code_modifiers: !_.isEmpty(providedService.procedure_codes) ?
    [providedService.procedure_code_modifiers[0]] : undefined,
});

// This changes the state of the fee schedule provided service to submitted.
// This then creates an invoice and locks the fee schedule provided service.
function submitFeeScheduleProvidedService({
  id,
  caseId,
  contactId,
  groupId,
  programId,
  showNotification = true,
  providedService,
}) {
  return async (dispatch) => {
    dispatch({ type: SUBMIT_PAYMENT_PROVIDED_SERVICE_STARTED });
    const service = buildService({ providedService, caseId, programId });
    const payload = await apiV4.updateRecord('provided_service', id, service, { groupId });
    if (!payload || !isHttpSuccess(payload.status)) {
      dispatch({ type: SUBMIT_PAYMENT_PROVIDED_SERVICE_ERROR });
      if (isHttpError(payload)) {
        Notifier.dispatch(
          'error',
          'We\'ve encountered an unexpected problem submitting the contracted service. Please try again.',
        );
      } else {
        Notifier.handleErrors(payload);
      }
      return false;
    }
    dispatch({
      type: SUBMIT_PAYMENT_PROVIDED_SERVICE_SUCCESS,
      contactId,
      caseId,
      payload,
    });

    if (showNotification) {
      Notifier.dispatch(
        payload.status,
        'Your submission request is in queue and processing. View the progress in Invoices.',
      );
    }

    return payload;
  };
}

export default submitFeeScheduleProvidedService;
