import { useFind } from 'src/api/APIHooks';

export const useEnrollmentStatusCount = (status, payerId) => {
  const { data } = useFind(
    'enrollment_request',
    {
      'plan.payer': payerId,
      enrollment_status: status,
    },
    {
      page: {
        number: 1,
        size: 1,
      },
      queryConfig: {
        enabled: !!payerId,
        placeholderData: undefined,
      },
    },
  );

  return data?.data?.meta?.page?.total_count;
};
