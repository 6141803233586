import React, { useState } from 'react';
import moment from 'moment/moment';
import {
  Badge,
  Icon,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  Button,
} from '@unite-us/ui';
import stateBgColor from 'common/utils/stateBgColor';
import { Spinner } from 'common/spinners';
import Pagination from 'src/common/Pagination/Pagination';
import EmptyTable from 'src/common/tables/EmptyTable/EmptyTable';
import ScrollToElement from 'src/common/utils/Form/ScrollToElement';
import {
  useFindDirectories,
} from 'src/components/Backoffice/api/hooks/prdApi/DirectoryHooks';
import { browserHistory } from 'common/utils/browserHistory';
import { tableWidth } from 'common/helpers/Tables';
import { theme } from 'src/../tailwind.config';
import { DEFAULT_PAGE_SIZE } from 'src/components/Backoffice/backofficeUtils';

const prdDirectoryStatus = (prdDirectory) => {
  if (prdDirectory.current_configuration && !prdDirectory.has_draft_changes) {
    return 'published';
  } if (!prdDirectory.current_configuration) {
    return 'unpublished';
  }
    return 'draft changes';
};

const addPrdDirectory = () => {
  browserHistory.push({ pathname: '/backoffice/prds/new' });
};

const viewPrdDirectory = (prd) => () => {
  browserHistory.push({ pathname: `/backoffice/prds/${prd.id}` });
};

const ScrollToElementEvent = () => {
  ScrollToElement(document.getElementById('top-of-prd-table'));
};

const PrdTable = () => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const { data: directoriesResponse, isFetching } = useFindDirectories({
    page: {
      number: pageNumber,
      size: pageSize,
    },
  });

  const { data: directories, paging } = directoriesResponse;

  return (
    <>
      <div data-testid="prd-directories-header" id="top-of-prd-table" className="flex px-8 pt-12">
        <div className="w-9/12 flex flex-row relative">
          <h1 className="pt-1 pb-2 font-bold text-brand-blue">PRD Editor</h1>
        </div>
        <div className="w-3/12 flex justify-end">
          <Button
            id="add-form-button"
            iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="V2Add" />}
            className={
              'py-0 px-4 h-12 text-sm text-action-blue rounded border solid border-dark-fill-blue bg-white normal-case'
            }
            label="Create a new PRD"
            onClick={() => addPrdDirectory()}
            data-testid="prd-create-button"
          />
        </div>
      </div>
      { isFetching ? <Spinner /> : (
        <div data-testid="prd-directories-table-container" style={{ padding: '30px' }}>
          <Table id="prdDirectoriesTable">
            <TableHeader />
            <TableHeader>
              <TableRow>
                <TableHeaderColumn style={{ width: tableWidth('medium') }}>
                  Customer Name
                </TableHeaderColumn>
                <TableHeaderColumn style={{ width: tableWidth('small') }}>
                  Status
                </TableHeaderColumn>
                <TableHeaderColumn style={{ width: tableWidth('small') }}>
                  Last Updated Date
                </TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {directories.map((prdDirectory) => {
                  const status = prdDirectoryStatus(prdDirectory);
                  const dataFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
                  const tableFormat = 'MMMM D, YYYY';
                  return (
                    <TableRow
                      key={`prd-directory-row-${prdDirectory.id}`}
                      onCellClick={viewPrdDirectory(prdDirectory)}
                    >
                      <TableRowColumn style={{ width: tableWidth('medium') }}>
                        {prdDirectory.customer_name}
                      </TableRowColumn>
                      <TableRowColumn style={{ width: tableWidth('small') }}>
                        <div>
                          <Badge
                            text={status}
                            style={{ textTransform: 'capitalize' }}
                            color={stateBgColor(status)}
                          />
                        </div>
                      </TableRowColumn>
                      <TableRowColumn style={{ width: tableWidth('small') }}>
                        {(prdDirectory.updated_at && moment(prdDirectory.updated_at, dataFormat).format(tableFormat))}
                      </TableRowColumn>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {
            (paging.total_count === 0) &&
            (
              <EmptyTable
                className={'p-6 border border-t-0 border-solid border-light-border-blue'}
                message={'No Results Found'}
              />
            )
          }
          <div data-testid="prd-pagination-container" className="p-6">
            <Pagination
              pageNumber={pageNumber}
              pageSize={pageSize}
              totalItemCount={paging.total_count}
              totalPageCount={paging.total_pages}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
              scrollPaginationElement={ScrollToElementEvent}
            />
          </div>
        </div>
      ) }
    </>
  );
};

export default PrdTable;
