const phoneNumberRegex = /\(\d{3}\) \d{3}-\d{4}/; // matches (xxx) xxx-xxxx format, where x = numeral digits

function focusExtensionIfPhoneNumberValid(phoneValue, extensionFieldElement) {
    const isValidPhoneNumber = phoneValue.match(phoneNumberRegex);

    if (isValidPhoneNumber) {
      extensionFieldElement.focus();
    }
}

export default focusExtensionIfPhoneNumberValid;
