function indexToExpandAfterDelete({
  deletedIndex,
  expandedIndex,
  servicesCount,
}) {
  const nextServicesCount = servicesCount - 1;
  if (nextServicesCount <= 1 || deletedIndex === expandedIndex) {
    return 0;
  }
  if (deletedIndex < expandedIndex) {
    return expandedIndex - 1;
  }
  return expandedIndex;
}

export default indexToExpandAfterDelete;
