import { useQuery } from 'react-query';
import { get } from 'lodash';
import { useFeatureFlag } from 'common/hooks';
import { screeningsApiClient } from 'src/api/config';
import {
  ELIGIBILITY_ASSESSMENT_STATUS,
  DECLINED_REASON,
  formatDateToScreeningFormat,
} from 'src/pages/facesheet/_id/eligibility/utils';
import { createElAssessSurveyJSTemplate } from 'src/pages/facesheet/_id/eligibility/surveyjsTemplates/createElAssessTemplate';

export const USE_MY_SCREENINGS_KEY = 'EligibilityAssessmentMyScreenings';
export const USE_TEMPLATE_V2 = 'EligibilityAssessmentTemplateV2';

// possible values for consent attribute are accepted or null
export const CONSENT_VALUE = {
  ACCEPTED: 'accepted',
  NULL: null,
};

export const useElAssessmentTemplateId = () => {
  const templateIdFlag = useFeatureFlag('scrn340-el-assessment-template');
  const templateId = (!templateIdFlag || templateIdFlag === 'null') ? false : templateIdFlag;
  return templateId;
};

export const useMyScreenings = (person_id) => (
  useQuery(
    [USE_MY_SCREENINGS_KEY],
    async () => {
      const queryParams = {
        person_id,
        type: 'screening',
        status: 'complete',
      };
      const response = await screeningsApiClient.get('/screenings', { params: queryParams });
      const screensList = get(response, 'data.screens', []);
      const templateIds = [];
      const screens = screensList.map((i) => {
        const screen = { ...i };
        screen.name = `Screening ${formatDateToScreeningFormat(screen.updated_at)}`;
        if (!templateIds.includes(screen.templateId)) {
          templateIds.push(screen.templateId);
        }
        return screen;
      });

      return screens;
    },
    {
      staleTime: Infinity,
      refetchInterval: false,
      retry: false,
    },
  )
);

// assessmentId is null if its a new el assessment; not null if it's an existing one
export const useTemplateV2 = ({ templateId }) => (
  useQuery(
    [USE_TEMPLATE_V2],
    async () => {
      const response = await screeningsApiClient.get(`/templates/${templateId}`);
      const {
        template: surveyjsTemplate,
        questionElementMap,
      } = createElAssessSurveyJSTemplate(response.data.template);
      return {
        // response,
        surveyjsTemplate,
        questionElementMap,
      };
    },
    {
      staleTime: Infinity,
      refetchInterval: false,
      retry: false,
      enabled: Boolean(templateId),
    },
  )
);

export const updateAssessment = async ({
  personId,
  employeeId,
  status,
  answers,
  related_screen_id,
  outreach_status,
  templateId,
  consent,
  assessmentId,
  eligible_services,
}) => {
  const payload = {
    source: 'web_app',
    template_id: templateId,
    status,
    subject_id: personId,
    related_screen_id,
    consent,
    facilitator: employeeId,
    ...(outreach_status ? { outreach_status } : {}),
    ...(eligible_services ? { eligible_services } : {}),
  };

  if (status === ELIGIBILITY_ASSESSMENT_STATUS.DECLINED) {
    payload.decline_reason = {
      key: 'other',
      note: DECLINED_REASON,
    };
  }
  if (
    status === ELIGIBILITY_ASSESSMENT_STATUS.COMPLETE ||
    status === ELIGIBILITY_ASSESSMENT_STATUS.DRAFT
  ) {
    if (answers) {
      payload.answers = answers;
    }
  }

  const response = await screeningsApiClient.put(`/screenings/${assessmentId}`, payload);
  return response;
};

// subject_id is the client/person (id)
// facilitator is the employee
// new assessments are created with consent defaulting to null
// assessmentId is not available/will be created by this call
export const createNewAssessment = async ({
  personId,
  employeeId,
  status,
  answers,
  related_screen_id,
  outreach_status, // success or declined or null
  templateId,
}) => {
  const payload = {
    source: 'web_app',
    template_id: templateId,
    status,
    subject_id: personId,
    related_screen_id,
    answers,
    facilitator: employeeId,
    ...(outreach_status ? { outreach_status } : {}),
  };

  const response = await screeningsApiClient.post('/screenings', payload);
  return response;
};
