import React from 'react';
import PropTypes from 'prop-types';
import OrganizationHeader from 'src/components/Organization/components/OrganizationHeader';
import { ORG_TABS } from 'src/components/Organization/constants';
import FormHeader from 'src/components/Organization/components/FormHeader';
import ProgramsList from 'src/components/Organization/pages/users/detail/ProgramsList';
import RolesList from 'src/components/Organization/pages/users/detail/RolesList';
import FormContainer from 'src/components/Organization/components/FormContainer';

import {
  useFindEmployee,
} from 'src/components/Organization/api/hooks/v1/employeeHooks';
import {
  Icon,
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
} from '@unite-us/ui';
import {
  EmailAddress,
  Locations,
  PhoneNumbers,
} from '@unite-us/client-utils';
import DivReset from 'src/components/Organization/components/DivLineHeightReset';
import { Spinner } from 'src/common/spinners';
import { theme } from 'src/../tailwind.config';
import EditLink from 'src/components/Organization/components/EditLink';
import useTrackPageView from 'src/components/Organization/utils/useTrackPageView';
import { ORG_SETTINGS } from 'src/common/utils/EventTracker/utils/eventConstants';

const PHONE_TYPE_SORT_ORDER = ['work', 'mobile', 'fax', 'unknown'];

const UserDetail = (props) => {
  useTrackPageView(ORG_SETTINGS.userViewed);
  const {
    params: { user_id },
    location,
  } = props;

  const employee = useFindEmployee(user_id);
  const {
    isFetching, data: {
      email = '', full_name = '', work_title, addresses = [], phone_numbers = [], state = '', programs = [], roles = [],
    },
  } = employee;

  return isFetching ? (<Spinner center />) : (
    <>
      <OrganizationHeader currentTab={ORG_TABS.users} location={location} />
      <FormContainer>
        <FormHeader headingText={full_name} cancelLink="/organization/settings/users" cancelText="Back" />
        <BaseCard className="mb-4">
          <BaseCardHeader title="User Info">
            <EditLink
              data-test-element="edit_user_info_link"
              to={`/organization/settings/users/${user_id}/edit`}
              label={'Edit User Info'}
            />
          </BaseCardHeader>
          <BaseCardBody className="p-4">
            <h4 className="leading-6">Name</h4>
            <div className="leading-5 font-regular-font">{full_name}</div>
            <h4 className="mt-3 leading-5">Contact Info</h4>
            <DivReset data-test-element="user_email_label" className="leading-6 flex items-center">
              <Icon icon="V2Email" className="mr-0.75em" color={theme.extend.colors['action-blue']} />
              <EmailAddress emailAddress={email || 'Email'} disableLink />
            </DivReset>
            {phone_numbers.length > 0 ? (
              <PhoneNumbers phone_numbers={phone_numbers} sortOrder={PHONE_TYPE_SORT_ORDER} detailedExtension />
            ) : (
              <DivReset data-test-element="user_phone_label" className="leading-6 flex items-center">
                <Icon icon="V2Phone" className="mr-0.75em" color={theme.extend.colors['action-blue']} />
                <span className="font-regular-font">Phone</span>
              </DivReset>
            )}
            {addresses.length > 0 ? (
              <Locations
                locations={addresses}
                showCount={false}
              />
            ) : (
              <DivReset data-test-element="user_location_label" className="leading-6 flex items-center">
                <Icon icon="IconMapMarkV2" className="mr-0.75em" color={theme.extend.colors['action-blue']} />
                <span className="font-regular-font">Location</span>
              </DivReset>
            )}
            <h4 className="mt-3 leading-6" data-test-element="user_job_title">Job Title</h4>
            <div className="leading-5 font-regular-font">{work_title}</div>
          </BaseCardBody>
        </BaseCard>
        <BaseCard className={'mb-8'}>
          <BaseCardHeader title="User Settings">
            <EditLink
              data-test-element="edit_user_settings_link"
              to={`/organization/settings/users/${user_id}/edit`}
              label="Edit User Settings"
            />
          </BaseCardHeader>
          <BaseCardBody className="p-4">
            <h4 className="leading-6" data-test-element="user_status">User Status</h4>
            <div className="capitalize font-regular-font">{state}</div>
            <ProgramsList programIds={programs.map((program) => program.id)} />
            <RolesList roleIds={roles.map((role) => role.id)} />
          </BaseCardBody>
        </BaseCard>
      </FormContainer>
    </>
  );
};

UserDetail.propTypes = {
  params: PropTypes.shape({ user_id: PropTypes.string }).isRequired,
  location: PropTypes.object.isRequired,
};

export default UserDetail;
