const getInvoiceWQBucketName = (name) => {
  let bucketName;
  switch (name) {
    case ('OPEN_PAYER_STATUS_OPTIONS'):
    case ('OPEN_STATUS_OPTIONS'):
      bucketName = 'Open';
      break;
    case ('CLOSED_PAYER_STATUS_OPTIONS'):
    case ('CLOSED_STATUS_OPTIONS'):
      bucketName = 'Closed';
      break;
    case ('DISPUTED_STATUS_OPTIONS'):
      bucketName = 'Disputed';
      break;
    case ('PAYER_ARCHIVED_STATUS_OPTIONS'):
    case ('ARCHIVED_STATUS_OPTIONS'):
    case ('NL_ARCHIVED_STATUS_OPTIONS'):
      bucketName = 'Archived';
      break;
    case ('SUBMITTED_STATUS_OPTIONS'):
      bucketName = 'Submitted';
      break;
    case ('DRAFT_STATUS_OPTIONS'):
      bucketName = 'Draft';
      break;
    case ('REJECTED_STATUS_OPTIONS'):
      bucketName = 'Rejected';
      break;
    case ('ACCEPTED_STATUS_OPTIONS'):
      bucketName = 'Accepted';
      break;
    default:
      bucketName = 'All';
  }

  return bucketName;
};
export default getInvoiceWQBucketName;
