export const selectCurrentBulkActions = (invoices = [], selectedInvoiceIds = []) => {
  const selectInvoices = selectedInvoiceIds.map((invoiceId) => invoices.find((invoice) => invoice.id === invoiceId));
  const selectedInvoiceStatuses = selectInvoices.map((invoice) => invoice?.invoice_status);

  const showBulkAnything = selectedInvoiceIds.length > 0 &&
    selectedInvoiceStatuses.every((invoiceStatus) => !!invoiceStatus);

  const showBulkAccept = showBulkAnything &&
    selectedInvoiceStatuses.every((invoiceStatus) => (
      invoiceStatus === 'transmitted_to_payer' || invoiceStatus === 'submitted_to_payer'
    ));

  const showBulkPay = showBulkAnything &&
    selectedInvoiceStatuses.every((invoiceStatus) => (
      invoiceStatus === 'accepted_by_payer'
    ));

  const showBulkReject = (selectedInvoiceStatuses[0] === 'transmitted_to_payer' ||
    selectedInvoiceStatuses[0] === 'submitted_to_payer' || selectedInvoiceStatuses[0] === 'accepted_by_payer'
    ) && (selectedInvoiceIds.length === 1);

  const disputedInvoice = selectInvoices.some((invoice) => invoice?.under_dispute);
  const enableArchiveBulkAction = !selectedInvoiceStatuses.some(
    (invoiceStatus) => (invoiceStatus === 'transmitted_to_payer' || invoiceStatus === 'submitted_to_payer'),
  );

  const showBulkArchive = selectedInvoiceIds.length > 0 && enableArchiveBulkAction && !disputedInvoice;

  return {
      Accept: showBulkAccept,
      Pay: showBulkPay,
      Reject: showBulkReject,
      Download: selectedInvoiceIds.length > 0,
      Archive: showBulkArchive,
      Unarchive: selectedInvoiceIds.length > 0,
  };
};
