import moment from 'moment';

// (todo) check to see if this needs to be moved to uu-cl-utils
function today() {
  return moment().startOf('day').unix();
}

export const oneYearAgoDateString = new Date(
  new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
).toISOString();

export default today;
