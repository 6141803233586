import { compact, get } from 'lodash';
import {
  getInvalidSelectedGroupsErrors,
  hasInvalidEmptyGroup,
} from '.';

const collectServiceGroupErrors = ({
  allowEmptyGroups = false,
  networks = [],
  service = {},
}) => {
  const isOONCase = get(service, 'isOONCase.checked', false);
  const hasSensitiveError = get(service, 'hasSensitiveError.value', false);

  return (
    compact([
      hasSensitiveError && 'Sensitivity Error',
      !allowEmptyGroups && !isOONCase && hasInvalidEmptyGroup(service.selected) && 'Required',
      ...getInvalidSelectedGroupsErrors({ networks, service }),
    ])
  );
};

export default collectServiceGroupErrors;
