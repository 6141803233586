import Notifier from 'common/helpers/Notifier';
import getCoreError from 'src/api/utils/getCoreError';
import { coreApi } from 'src/api/config';

export const ROLE_TYPES = {
  SYSTEM: 'system',
  RESTRICTED: 'restricted',
  PROGRAM: 'program',
  FEATURE: 'feature',
  PRIMARY: 'primary',
};

const onError = (dispatch, error) => {
  Notifier.handleErrors(getCoreError(error));
  return error;
};

const filterRoles = (roles) => {
  const systemRoles = []; // legacy
  const restrictedRoles = [];
  const programRoles = []; // legacy

  const primaryRoles = [];
  const featureRoles = [];

  roles.forEach((role) => {
    if (role.role_type === ROLE_TYPES.SYSTEM) { // legacy
      systemRoles.push(role);
    }
    if (role.role_type === ROLE_TYPES.RESTRICTED) {
      restrictedRoles.push(role);
    }
    if (role.role_type === ROLE_TYPES.PROGRAM) { // legacy
      programRoles.push(role);
    }
    if (role.role_type === ROLE_TYPES.PRIMARY) {
      primaryRoles.push(role);
    }
    if (role.role_type === ROLE_TYPES.FEATURE) {
      featureRoles.push(role);
    }
  });

  return {
    systemRoles,
    restrictedRoles,
    programRoles,
    primaryRoles,
    featureRoles,
  };
};

export default function fetchRoles() {
  return async (dispatch) => {
    try {
      const response = await coreApi.findAll('role');
      const roles = response.data.data;

      return filterRoles(roles);
    } catch (error) {
      return onError(dispatch, error);
    }
  };
}
