import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';

const Checkbox = ({
  ariaLabel,
  className,
  disabled,
  id,
  label,
  onChange,
  onClick,
  checked,
}) => (
  <div className={cx('flex', className)}>
    <input
      id={id}
      aria-label={ariaLabel}
      type="checkbox"
      onClick={onClick}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
    />
    <label htmlFor={id} className="ml-2 text-transform: capitalize text-sm font-medium-font">{label}</label>
  </div>
);

Checkbox.propTypes = {
  ariaLabel: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

Checkbox.defaultProps = {
  ariaLabel: '',
  checked: false,
  className: '',
  disabled: false,
  label: '',
  onClick: noop,
};

export default Checkbox;
