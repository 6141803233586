import PropTypes from 'prop-types';
import React from 'react';
import {
  Table,
  TableHeader,
  TableBody,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from '@unite-us/ui';
import TableRowLoading from 'common/display/Table/TableRowLoading';
import { goToCase } from 'src/components/Cases/utils/routing';
import { get, isEmpty } from 'lodash';
import { caseStatuses } from '../utils/caseStatusConstants';
import '../stylesheets/CaseHistoryTable.scss';

const caseState = (caseObj) => {
  if (!isEmpty(caseObj.outcome)) {
    return 'closed';
  }

  switch (caseObj.state) {
    case 'draft':
      return 'draft';
    case 'off_platform':
      return 'off_platform';
    default:
      return 'open';
  }
};

const navigateToCase = (caseObj, groupId) => {
  if (caseObj.provider && caseObj.provider.id === groupId) {
    if (caseState(caseObj) === 'off_platform' && isEmpty(caseObj.outcome)) {
      return goToCase({ ...caseObj, pathname: '/dashboard/oon-cases/open' });
    }
    if (caseState(caseObj) === 'off_platform' && !isEmpty(caseObj.outcome)) {
      return goToCase({ ...caseObj, pathname: '/dashboard/oon-cases/closed' });
    }
    if (caseState(caseObj) === 'open' && isEmpty(caseObj.outcome)) {
      return goToCase({ ...caseObj, pathname: '/dashboard/cases/open' });
    }
    if (caseState(caseObj) === 'open' && !isEmpty(caseObj.outcome)) {
      return goToCase({ ...caseObj, pathname: '/dashboard/cases/closed' });
    }
  }
  if (isEmpty(caseObj.outcome)) {
    return goToCase({ ...caseObj, pathname: '/dashboard/external-cases/open' });
  }
  return goToCase({ ...caseObj, pathname: '/dashboard/external-cases/closed' });
};

const CaseHistoryTable = ({ caseObj, groupId, isFetchingReferrals }) => {
  const NoCasesView = () => (
    <TableRow
      hoverable={false}
      clickable={false}
    >
      <div className="no-cases">
        <p className="no-cases-content">
          No cases
        </p>
      </div>
    </TableRow>
  );
  const data = isEmpty(caseObj) ? NoCasesView() : (
    <TableRow
      className="case-table-row"
      key={`table-row-${caseObj.id}`}
      hoverable={1}
      clickable={1}
      onCellClick={() => navigateToCase(caseObj, groupId)}
    >
      <TableRowColumn>
        {get(caseObj, 'provider.name')}
      </TableRowColumn>
      <TableRowColumn>
        {get(caseObj, 'opened_date')}
      </TableRowColumn>
      <TableRowColumn>
        {get(caseObj, 'closed_date')}
      </TableRowColumn>
      <TableRowColumn>
        {caseStatuses[caseState(caseObj)]()}
      </TableRowColumn>
    </TableRow>
  );
  return (
    <Table
      noBorder
    >
      <TableHeader className="case-history-header">
        <TableRow>
          <TableHeaderColumn>
            Organization
          </TableHeaderColumn>
          <TableHeaderColumn>
            Open Date
          </TableHeaderColumn>
          <TableHeaderColumn>
            Close Date
          </TableHeaderColumn>
          <TableHeaderColumn>
            Status
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isFetchingReferrals ? <TableRowLoading /> : data}
      </TableBody>
    </Table>
  );
};

CaseHistoryTable.propTypes = {
  caseObj: PropTypes.object,
  groupId: PropTypes.string,
  isFetchingReferrals: PropTypes.bool,
};
CaseHistoryTable.defaultProps = {
  caseObj: {},
  groupId: '',
  isFetchingReferrals: false,
};

export default CaseHistoryTable;
