import {
  useFind,
  useCreateRecord,
  useInvalidateQueries,
  useUpdateRecord,
  useDeleteRecord,
} from 'src/api/APIHooks';
import { defaultQueryConfig, defaultMutationConfig } from '../apiHookOptions';

export const useFindFormNeedConfigurations = (formId) => useFind('form_need_configuration', { form: formId }, {
  queryConfig: defaultQueryConfig,
});

export const useCreateFormNeedConfiguration = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useCreateRecord('form_need_configuration', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('form_need_configuration');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useUpdateFormNeedConfiguration = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useUpdateRecord('form_need_configuration', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('form_need_configuration');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useDeleteFormNeedConfiguration = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useDeleteRecord('form_need_configuration', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('form_need_configuration');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};
