import {
  ADD_ASSISTANCE_REQUEST_INTERACTION,
  ASSISTANCE_REQUESTS_START_FETCHING,
  ASSISTANCE_REQUESTS_END_FETCHING,
  CLOSE_ASSISTANCE_REQUEST,
  FETCH_ASSISTANCE_REQUESTS_CONFIG,
  FETCH_ASSISTANCE_REQUESTS,
  FETCH_ASSISTANCE_REQUEST,
  FETCH_ASSISTANCE_REQUEST_FORM,
  FETCH_ASSISTANCE_REQUEST_FORMS,
} from 'actions';
import { find, get } from 'lodash';

export const defaultState = {
  isFetching: false,
  config: {},
  requests: [],
  forms: {},
};

function mergeRequest(requests = [], request = {}, id = 'id') {
  if (!find(requests, { [id]: request[id] })) {
    return [...requests, request];
  }
  return requests.reduce((acc, curr) => (
    curr[id] === request[id] ? [...acc, request] : [...acc, curr]
  ), []);
}

export default function assistanceRequestReducer(state = defaultState, action) {
  const { requestId, payload, type } = action;

  switch (type) {
    case ASSISTANCE_REQUESTS_START_FETCHING:
      return { ...state, isFetching: true };

    case ASSISTANCE_REQUESTS_END_FETCHING:
      return { ...state, isFetching: false };

    case FETCH_ASSISTANCE_REQUESTS_CONFIG:
      return {
        ...state,
        config: payload.data.data,
      };

    case FETCH_ASSISTANCE_REQUESTS:
      return {
        ...state,
        requests: payload.data.data,
      };

    case FETCH_ASSISTANCE_REQUEST:
    case CLOSE_ASSISTANCE_REQUEST:
      return {
        ...state,
        requests: mergeRequest(state.requests, payload.data.data, payload.findRequestBy),
      };

    case ADD_ASSISTANCE_REQUEST_INTERACTION:
      return state;

    case FETCH_ASSISTANCE_REQUEST_FORM:
      return {
        ...state,
        forms: {
          ...state.forms,
          [requestId]: {
            forms: get(state, `forms[${requestId}].forms`, []),
            selectedForm: get(payload, 'data.data', {}),
          },
        },
      };
    case FETCH_ASSISTANCE_REQUEST_FORMS:
      return {
        ...state,
        forms: {
          ...state.forms,
          [requestId]: {
            forms: get(payload, 'data.data', []),
            selectedForm: get(state, `forms[${requestId}].selectedForm`, {}),
          },
        },
      };

    default:
      return state;
  }
}
