import { browserHistory } from 'common/utils/browserHistory';
import querystring from 'query-string';
import { DEFAULT_RECORD_TYPE } from 'src/components/Search/constants';

function goToSearchIndex({ query, model = DEFAULT_RECORD_TYPE, page = 1 }) {
  const searchObj = {
    q: query,
    model,
    page,
  };

  browserHistory.push({
    pathname: '/search',
    search: `?${querystring.stringify(searchObj)}`,
  });
}

export default goToSearchIndex;
