import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setDashboardRefetch } from 'actions/Dashboard';
import { fetchGroupsReferral, updateReferral } from 'actions/Referral/Group';
import { fetchServiceCase, updateDraftCase } from 'actions/Case/Contact/Group';
import { isDraft } from 'src/components/Referrals/ReferralStatus/utils/status';
import { isHttpError } from 'src/common/utils/Error';
import { viewable } from 'common/utils/Permission/Permission';
import { goToReferralsIndex } from 'src/components/Referrals/utils/routing';
import { get } from 'lodash';
import { crtb1239AdaptDraftReferralFlow } from 'common/utils/FeatureFlags/flags';

export class ReferralDetailSummaryWrapper extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  submit(formInputs) {
    const { groupId, referral = {} } = this.props;
    const requestData = {
      referral: { ...formInputs },
    };

    return !isDraft(referral) ? this.props.updateReferral(referral, requestData)
      .then((response) => {
        this.props.fetchGroupsReferral(groupId, referral.id, { isDraft });
        this.props.setDashboardRefetch();

        const updatedReferral = get(response, 'data.data', {});
        // Go to referrals index if updated referral is no longer viewable.
        if (!isHttpError(response) && !viewable(updatedReferral)) {
          goToReferralsIndex(updatedReferral.status);
        }
      }) :
      this.props.updateDraftCase(groupId, referral.contact.id, referral.case.id, requestData)
        .then(async () => {
          await this.props.fetchServiceCase(groupId, referral.contact.id, referral.case.id);
          this.props.setDashboardRefetch();
        });
  }

  render() {
    const { children, crtb1239AdaptDraftReferralFlowEnabled } = this.props;
    const referralState = children?.props?.item?.state === 'draft';
    const hasAdaptDraftReferralFlowEnabled = crtb1239AdaptDraftReferralFlowEnabled && referralState;
    const childrenWithFuncs = React.Children
      .map(children, (child) => React.cloneElement(child, {
          submit: this.submit,
          editable: !hasAdaptDraftReferralFlowEnabled,
          showServiceTypeEditField: !hasAdaptDraftReferralFlowEnabled,
      }));

    return (
      <div className="referral-detail-summary-wrapper">
        {childrenWithFuncs}
      </div>
    );
  }
}

ReferralDetailSummaryWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  fetchGroupsReferral: PropTypes.func.isRequired,
  fetchServiceCase: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  referral: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  setDashboardRefetch: PropTypes.func.isRequired,
  updateDraftCase: PropTypes.func.isRequired,
  updateReferral: PropTypes.func.isRequired,
  crtb1239AdaptDraftReferralFlowEnabled: PropTypes.bool,
};

ReferralDetailSummaryWrapper.defaultProps = {
  crtb1239AdaptDraftReferralFlowEnabled: false,
};

function mapStateToProps(state) {
  return {
    crtb1239AdaptDraftReferralFlowEnabled: crtb1239AdaptDraftReferralFlow(state),
  };
}

export default connect(mapStateToProps, {
  fetchGroupsReferral,
  fetchServiceCase,
  setDashboardRefetch,
  updateDraftCase,
  updateReferral,
})(ReferralDetailSummaryWrapper);
