import {
  useFind,
  useFindRecord,
  useCreateRecord,
  useInvalidateQueries,
  useUpdateRecord,
  usePopulate,
} from 'src/api/APIHooks';
import { defaultQueryConfig, defaultMutationConfig } from './apiHookOptions';

export const useEmployees = (
  {
 providerId, query, pageSize, pageNumber, sort,
},
  queryConfig,
) => useFind(
    'employee',
    {
      provider: providerId,
      query: query || '',
    },
    {
      queryConfig: { ...defaultQueryConfig, ...queryConfig },
      page: { number: pageNumber || 1, size: pageSize || 15 },
      sort: sort || '',
    },
  );

export const useFindEmployee = (id) => useFindRecord('employee', id, {
    queryConfig: defaultQueryConfig,
  });

export const useEmployeeWithRoles = (id) => {
  const findEmployee = useFindEmployee(id);
  usePopulate('roles', 'roles', findEmployee.data, {
    queryConfig: {
      enabled: findEmployee.isSuccess,
      staleTime: 1000 * 60 * 60,
      refetchOnWindowFocus: false,
    },
  });
  return findEmployee;
};

export const useCreateEmployee = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useCreateRecord('employee', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('employee');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useUpdateEmployee = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useUpdateRecord('employee', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('employee');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};
