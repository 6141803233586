import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { usePrograms } from 'src/components/Organization/api/hooks/v1/programHooks';
import mapProviderIdToProps from 'src/components/Organization/utils/mapProviderIdToProps';
import { Spinner } from 'src/common/spinners';

const ProgramsList = (props) => {
  const {
    programIds,
    currentProviderId: providerId,
  } = props;

  const { isFetching, data = [] } = usePrograms(providerId, { excludeDeactivated: true });

  if (isFetching) {
    return (<Spinner />);
  }

  const programData = data.filter((program) => programIds.includes(program.id));

  return (
    <>
      {isFetching ? <Spinner center /> : (
        <>
          <h4 className="mt-3 leading-6" data-test-element="program_assignments">Program Assignments</h4>
          {programData.map(({ id, name }) => <div key={id} className="leading-5 font-regular-font">{name}</div>)}
        </>
      )}
    </>
  );
};

ProgramsList.propTypes = {
  programIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentProviderId: PropTypes.string.isRequired,
};

export default connect(mapProviderIdToProps)(ProgramsList);
