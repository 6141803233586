import { map, isEmpty, isNull } from 'lodash';

export function isLastPage(paging = {}) {
  if (isEmpty(paging) || isNull(paging.total)) {
    return true;
  }
  return !(paging.current_page < paging.total_pages);
}

export function hasNextPage(paging = {}) {
  return !isNull(paging.next_page);
}

// ------------------------------------//
// Pagination logic with v4 endpoints //
// ----------------------------------//

export function buildNextPaging(paging = {}) {
  if (!paging.next_page) {
    return paging;
  }

  return {
    ...paging,
    current_page: paging.current_page + 1,
    prev_page: paging.prev_page + 1,
    next_page: paging.next_page + 1 > paging.total_pages ? null : paging.next_page + 1,
  };
}

export function buildPrevPaging(paging = {}) {
  if (!paging.prev_page) {
    return paging;
  }

  return {
    ...paging,
    current_page: paging.current_page - 1 === 0 ? null : paging.current_page - 1,
    prev_page: paging.prev_page - 1 === 0 ? null : paging.prev_page - 1,
    next_page: paging.next_page === null ? paging.total_pages : paging.next_page - 1,
  };
}

export function createPageCollection({
  page,
  pageName,
  prevData,
  data,
  cached,
  query,
}) {
  const ids = map(data, 'id');

  let cachedData = { ...prevData.cached };

  const pagingObj = {
    [page.number]: ids,
    paging: {
      current_page: page.number,
      next_page: page.number < page.total_pages ?
        page.number + 1 : null,
      prev_page: page.number !== 1 ? page.number - 1 : null,
      per: page.size,
      total_count: page.total_count,
      total_pages: page.total_pages,
    },
  };

  // cached key will save a record of page.number and paging object for first page
  if (cached) {
    if (page.number === 1) {
      cachedData = { ...cachedData, ...pagingObj };
    } else {
      cachedData = { ...cachedData, [page.number]: ids };
    }
  }

  // If query key exists, reset pageCollection with filtered text results
  if (!isEmpty(query)) {
    return {
      [pageName]: {
        ...pagingObj,
        cached: cachedData,
      },
    };
  }

  // If next page or prev page clicked with fetch, normalized data with old data (pagination)
  // Replace cache key
  return {
    [pageName]: {
      ...prevData,
      ...pagingObj,
      cached: cachedData,
    },
  };
}
