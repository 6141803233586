import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { InputField, Button, SelectField, DateField } from '@unite-us/ui';
import { validations } from '@unite-us/app-components';
import today from 'common/utils/today';
import { relationships } from 'src/reducers/constants/defaultEnums';

const DISPLAY_NAME = 'family-member-form';

const FamilyMemberForm = ({ onCloseDialog, valid }) => (
  <div className={DISPLAY_NAME}>
    <div data-testid="family-modal-first-name">
      <Field
        name="first_name"
        validate={(value) => validations.isRequired(value)}
      >
        {(params) => (
          <InputField
            id="first-name" label="First Name" required
            {...params}
          />
        )}
      </Field>
    </div>
    <div data-test-element="family-modal-last-name">
      <Field
        name="last_name"
        validate={(value) => validations.isRequired(value)}
      >
        {(params) => (
          <InputField
            id="last-name" label="Last Name" required
            {...params}
          />
        )}
      </Field>
    </div>
    <div data-test-element="family-modal-dob">
      <Field name="date_of_birth">
        {(params) => (
          <DateField
            className="w-full"
            id="date-of-birth"
            inline={false}
            label="Date of Birth"
            maxDate={today().toString()}
            data-test-element="family-modal-dob"
            {...params}
          />
        )}
      </Field>
    </div>
    <Field name="relation_to_contact">
      {(params) => (
        <SelectField
          dataTestElement="family-modal-relationship"
          label="Relationship to your client"
          placeholder="Select..."
          id="relation-to-contact"
          labelKey="display_name"
          options={relationships}
          valueKey="value"
          {...params}
        />
      )}
    </Field>
    <div className="flex items-center justify-end">
      <Button
        data-test-element="family-modal-cancel"
        label="cancel"
        className="text-sm capitalize mr-4"
        onClick={onCloseDialog}
        secondary
      />
      <Button
        data-test-element="family-modal-save"
        label="save"
        className="text-sm capitalize"
        disabled={!valid}
        type="submit"
        primary
      />
    </div>
  </div>
);

FamilyMemberForm.propTypes = {
  onCloseDialog: PropTypes.func,
  onDeleteClick: PropTypes.func,
  valid: PropTypes.bool.isRequired,
};

FamilyMemberForm.defaultProps = {
  onCloseDialog: () => { },
  onDeleteClick: null,
};

export default FamilyMemberForm;
