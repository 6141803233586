import React, { useCallback, useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { TrackerContext } from '@unite-us/client-utils';
import { INVOICE } from 'common/utils/EventTracker/utils/eventConstants';
import DialogV2 from 'common/modal/DialogV2';
import CurrencyField from 'common/form/CurrencyField';
import { theme } from 'src/../tailwind.config';
import callOrLog from 'src/common/utils/callOrLog';
import { dollarAmount } from 'src/common/display/Money/DollarAmount';
import { usePayerFixResponse } from '../hooks/useUpdateInvoice';

const FixInvoiceResponseForm = ({
  close, invoice, networkId, setRef, onSuccess,
}) => {
  const hasInvoiceRejectionStatus = invoice.invoice_status === 'rejected_by_payer';
  const hasInvoicePaidStatus = invoice.invoice_status === 'paid';

  const [value, setvalue] = useState(null);
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const acceptFixResponse = usePayerFixResponse(
    invoice.id,
    {
      invoice_status: 'accepted_by_payer',
    },
    'Invoice has been accepted',
  );
  const paidFixResponse = usePayerFixResponse(
    invoice.id,
    {
      invoice_status: 'paid',
      amount_paid: value,
    },
    `Invoice paid with ${dollarAmount(value, true)}`,
  );

  const totalAmountInvoiced = invoice.total_amount_invoiced ?
    dollarAmount(invoice.total_amount_invoiced, true) : '';

  const cancelFixReponse = useCallback(() => {
    setvalue(null);
    setValid(false);
    setSubmitted(false);
    close();
  }, [setvalue, setValid, setSubmitted, close]);

  const eventTracker = useContext(TrackerContext);

  const submitAcceptFixResponse = async () => {
    setSubmitted(true);
    await acceptFixResponse(invoice.id, { invoice_status: 'accepted_by_payer' });
    onSuccess();
    callOrLog(() => eventTracker(
      INVOICE.nlClickedDisputeResolution,
      { current_network: networkId },
    ));
  };

  const submitPaidFixResponse = async () => {
    setSubmitted(true);

    if (valid) {
      await paidFixResponse(invoice.id, { invoice_status: 'paid', amount_paid: value });
      onSuccess();
      callOrLog(() => eventTracker(
        INVOICE.nlClickedDisputeResolution,
        { current_network: networkId },
      ));
    }
  };

  return (
    <DialogV2
      ref={setRef}
      modal
      title={`Fix invoice response #${invoice.short_id}`}
      titleStyles={{ color: theme.extend.colors['text-blue'] }}
      id="payer-fix-response-invoice-modal"
      onRequestClose={cancelFixReponse}
      cancelHandler={cancelFixReponse}
      confirmButtonIcon={hasInvoicePaidStatus ? 'LogoShield' : 'IconCheckCircle'}
      confirmLabel={hasInvoicePaidStatus ? 'Pay' : 'Accept'}
      confirmationHandler={hasInvoicePaidStatus ? submitPaidFixResponse : submitAcceptFixResponse}
      width="2xl"
    >
      <div className="flex flex-col text-left">
        { hasInvoiceRejectionStatus && (
          <>
            <div className="text-sm pb-2">
              This invoice has a resolved dispute with a request to accept the rejected invoice.
              Please confirm whether you would like to accept this invoice.
            </div>
            <div>
              <label className="ui-form-field__label text-brand-blue text-13px pt-2" htmlFor="original_amount">
                INVOICE AMOUNT
              </label>
              <span id="reject-total-amount-invoiced" className="mt-2">
                {totalAmountInvoiced}
              </span>
            </div>
          </>
        )}
        { hasInvoicePaidStatus && (
          <>
            <div className="text-sm pb-2">
              This invoice has been resolved with a request to fix the paid amount.
              Please confirm whether you would like to pay the resolved amount.
            </div>
            <div>
              <label className="ui-form-field__label text-brand-blue text-13px pt-2" htmlFor="original_amount">
                ORIGINAL INVOICE AMOUNT
              </label>
              <span id="paid-total-amount-invoiced" className="mt-2">
                {totalAmountInvoiced}
              </span>
            </div>
          </>
        )}
        <div>
          <label className="ui-form-field__label text-brand-blue text-13px pt-4" htmlFor="dispute_reason">
            DISPUTE REASON
          </label>
          <span id="dispute-reason" className="mt-2">
            {invoice.invoice_dispute_reason.display_name}
          </span>
        </div>
        <div>
          <label className="ui-form-field__label text-brand-blue text-13px pt-4" htmlFor="resolution_reason">
            RESOLUTION REASON
          </label>
          <span id="resolution-reason" className="mt-2">
            {invoice.invoice_dispute_resolution_reason.display_name}
          </span>
        </div>
        <div>
          <label className="ui-form-field__label text-brand-blue text-13px pt-4" htmlFor="resolution_reason">
            RESOLUTION NOTES
          </label>
          <span id="resolution-notes" className="mt-2">
            {invoice.invoice_dispute_resolution_note}
          </span>
        </div>
        { hasInvoicePaidStatus && (
          <div>
            <hr className="pt-2 border-dark-border-blue my-3" />
            <label className="ui-form-field__label text-brand-blue text-13px" htmlFor="resolution_reason">
              UPDATED AMOUNT
            </label>
            <CurrencyField
              className="col-span-5 lg:col-span-2 pl-0 mt-3 w-5/12"
              id="fix-response-amount-paid"
              field={invoice.amount_paid}
              onChange={(val) => {
                setValid(true);
                setvalue(val);
              }}
              value={value}
            />
            { submitted && !valid && (
              <span id="fix-response-error" className="text-red mt-1">
                * Updated amount is required.
              </span>
            )}
          </div>
        )}
      </div>
    </DialogV2>
  );
};

FixInvoiceResponseForm.propTypes = {
  close: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
  networkId: PropTypes.string.isRequired,
  setRef: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
};

FixInvoiceResponseForm.defaultProps = {
  onSuccess: () => {},
};

FixInvoiceResponseForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const networkId = state.networks.networkId;
  return {
    networkId,
  };
}

export { FixInvoiceResponseForm };
export default connect(mapStateToProps)(FixInvoiceResponseForm);
