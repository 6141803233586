import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'common/utils/browserHistory';
import { caseRoute } from 'src/components/Cases/utils/routing';
import { fetchServiceCase } from 'actions/Case/Contact/Group';
import { Spinner } from 'common/spinners';
import _ from 'lodash';

class CaseLegacyDetailRedirect extends Component {
  componentDidMount() {
    const {
      groupId,
      params: { id, case_id },
    } = this.props;

    this.props.fetchServiceCase(groupId, id, case_id)
      .then((response) => {
        const serviceCase = _.get(response, 'data.data', {});
        browserHistory.replace(caseRoute(serviceCase));
      });
  }

  render() {
    return <Spinner />;
  }
}

CaseLegacyDetailRedirect.propTypes = {
  groupId: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  fetchServiceCase: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { session } = state;
  return {
    params: ownProps.params,
    groupId: session.groupId,
  };
}


export default connect(mapStateToProps, {
  fetchServiceCase,
})(CaseLegacyDetailRedirect);
