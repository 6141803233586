import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { isFunction } from 'lodash';
import { BarLoader } from '@unite-us/ui';

// Produces unique but stable-between-renders ids to correlate dt and dd
// elements in combination with `useMemo`
let nextLineItemId = 1;
const lineItemIdFactory = () => `line-item-${nextLineItemId++}`;

const LineItem = ({
  children,
  dataTestElement,
  isLoaded,
  field,
  fieldClassName,
  valueClassName,
  colon,
}) => {
  const dtId = useMemo(lineItemIdFactory);
  const renderChildren = () => (isFunction(children) ? children() : children);
  return (
    <>
      <dt
        className={fieldClassName}
        data-testid={dataTestElement}
        data-test-element={dataTestElement}
        id={dtId}
      >
        {field}{colon && ':'}
      </dt>
      <dd aria-describedby={dtId} className={valueClassName}>
        {isLoaded ? renderChildren() : <BarLoader />}
      </dd>
    </>
  );
};

LineItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  field: PropTypes.node.isRequired,
  isLoaded: PropTypes.bool,
  dataTestElement: PropTypes.string,
  fieldClassName: PropTypes.string,
  valueClassName: PropTypes.string,
  colon: PropTypes.bool,
};

LineItem.defaultProps = {
  children: '',
  isLoaded: true,
  dataTestElement: null,
  fieldClassName: 'text-text-blue text-sm font-bold leading-5 break-word',
  valueClassName: 'text-text-blue text-sm leading-5 break-word',
  colon: true,
};

export default LineItem;
