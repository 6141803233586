import { get } from 'lodash';

const getInNetworkGroupField = (formValues) => {
  // Creation Referral - formField is prefixed with services
  // Send Referral - formField is not prefixed

  /**
   * Hardcoded index of 0 intentional for services[0]
   * groupField is an array but nested under a single service in OON case.
   */
  /**
   * Hardcoded index of 0 intentional for selected[0]
   * User will need the first option field available.
   */
  const newFormValues = formValues.services ? formValues.services[0].selected[0] : formValues.selected[0];
  return get(newFormValues, 'group', '');
};

export default getInNetworkGroupField;
