import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { UUModal } from '@unite-us/ui';
import Notifier from 'common/helpers/Notifier';
import { Form } from 'react-final-form';
import { FACESHEET } from 'common/utils/EventTracker/utils/eventConstants';
import moment from 'moment';
import FamilyMemberForm from './FamilyMemberForm';
import { useCreateMember, getDefaultFamilyMember } from '../../Common/api';
import { useUpdateFamilyMember } from '../api';

const useFamilyMemberForm = ({
  personId,
} = {}) => {
  const dialogRef = useRef();
  const [editMode, setEditMode] = useState(false);
  const [personalRelationship, setPersonalRelationship] = useState('');
  const [initialValues, setInitialValues] = useState(null);

  const openDialog = (_personalRelationship, numberOfFamilyMembers) => {
    const isEdit = !!(_personalRelationship && _personalRelationship.id);
    setEditMode(isEdit);
    if (isEdit) {
      const { related_person } = _personalRelationship;
      setPersonalRelationship(_personalRelationship);
      setInitialValues({
        id: _personalRelationship.id,
        first_name: related_person.first_name,
        last_name: related_person.last_name,
        full_name: related_person.full_name,
        relation_to_contact: _personalRelationship.relationship,
        date_of_birth: Number.isInteger(related_person.date_of_birth) ?
          related_person.date_of_birth :
          null,
      });
    } else {
      window.eventTracker(FACESHEET.familyMemberAddClicked, { number_of_family_members: numberOfFamilyMembers });
    }
    dialogRef.current.openModal();
  };

  const {
    submit: createMember,
    isSuccess: isSuccessCreate,
    isError: isErrorCreate,
  } = useCreateMember({
    personId,
    familyMember: true,
  });

  const [updateMember, isSuccessEdit, isErrorEdit] = useUpdateFamilyMember();

  useEffect(() => {
    if (isSuccessCreate || isSuccessEdit) {
      Notifier.dispatch(
        'success',
        `${editMode ? 'Updated' : 'Created'} Family Member`,
      );
    } else if (isErrorCreate || isErrorEdit) {
      Notifier.dispatch(
        'error',
        `Error ${editMode ? 'editing' : 'adding'} Family Member`,
      );
    }
  }, [isSuccessCreate, isErrorCreate, isSuccessEdit, isErrorEdit]);

  const onSubmitEventTrackerHandler = ({ relation_to_contact, date_of_birth }) => {
    let relationshipType = 'Family Member';
    if (relation_to_contact && relation_to_contact.length > 0) {
      relationshipType = relation_to_contact;
    }
    const age = moment().diff(moment.unix(date_of_birth), 'years');

    window.eventTracker(
      editMode ? FACESHEET.familyMemberUpdated : FACESHEET.familyMemberAdded,
      {
        relation_to_contact: relationshipType,
        age,
      },
    );
  };

  const updateHandler = (values) => {
    const relationship = values.relation_to_contact ?? getDefaultFamilyMember();
    // TODO: update name values from personal relationship to make resulting modal accurate
    setPersonalRelationship({
      ...personalRelationship,
      relationship,
    });
    updateMember({
      personId: personalRelationship.related_person.id,
      relationshipId: personalRelationship.id,
      values: {
        ...values,
        relation_to_contact: relationship,
      },
    });
  };

  const DialogComponent = (
    <Form
      onSubmit={async (values) => (editMode ? updateHandler(values) : createMember(values))}
      initialValues={editMode ? initialValues : {}}
      render={({
 form, handleSubmit, valid, values,
}) => {
        const closeDialog = () => {
          form.restart();
          dialogRef.current.closeModal();
        };
        return (
          <UUModal
            id="family-consent-dialog"
            ref={dialogRef}
            title={editMode ? 'Edit Family Member' : 'Add Family Member'}
            onRequestClose={closeDialog}
            size="large"
            dataTestElementClose="family-modal-close"
            dialogContentStyles={{ overflow: 'visible' }}
          >
            <form
              onSubmit={async (event) => {
                onSubmitEventTrackerHandler(values);
                await handleSubmit(event);
                closeDialog();
              }}
            >
              <FamilyMemberForm
                onCloseDialog={closeDialog}
                valid={valid}
              />
            </form>
          </UUModal>
        );
      }}
    />
  );
  return [DialogComponent, openDialog];
};

useFamilyMemberForm.propTypes = {
  personId: PropTypes.string.isRequired,
};

export default useFamilyMemberForm;
