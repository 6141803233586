import Notifier from 'common/helpers/Notifier';
import { FETCH_GEO_POSITION } from 'actions';

const PERMISSION_DENIED = 1;
const POSITION_UNAVAILABLE = 2;

export default function fetchPosition(options) {
  return (dispatch) => {
    function onSuccess(response) {
      const geolocation = {
        lat: response.coords.latitude,
        long: response.coords.longitude,
      };

      dispatch({
        type: FETCH_GEO_POSITION,
        payload: geolocation,
      });

      return response;
    }

    // For the Microsoft Edge & Windows 10 & system settings location settings issue
    const win10EdgeLocationError = (error) => (error.message.indexOf('https://inference.location.live.net/') !== -1 &&
    window.navigator.userAgent.indexOf('Edg/') !== -1);

    // And for the Chromium Position Unavailable known bug on Google Chrome browser
    // https://bugs.chromium.org/p/chromium/issues/detail?id=154096
    const chromiumPositionUnavailableError = (error) => (error.code === POSITION_UNAVAILABLE &&
      error.message.indexOf('Failed to query location from network service.') !== -1);

    function onError(error) {
      // Don't notify when error is a result of the user denying location services.
      // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
      if (error.code !== PERMISSION_DENIED &&
        !(win10EdgeLocationError(error)) &&
        !(chromiumPositionUnavailableError(error))) {
        // We don't want to notify the user of these errors
        Notifier.handleErrors(error);
      }
      return error;
    }
    return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject, options))
      .then(onSuccess, onError);
  };
}
