// planType Constants
export const PLAN_TYPES = {
  COMMERCIAL: 'commercial',
  MEDICARE: 'medicare',
  MEDICAID: 'medicaid',
  UNKNOWN: 'unknown',
  TRICARE: 'tricare',
};

export const INSURANCE_STATUSES = Object.freeze({
  pending_enrollment: 'pending_enrollment',
  enrolled: 'enrolled',
  not_enrolled: 'not_enrolled',
  rejected: 'rejected',
  pending_disenrollment: 'pending_disenrollment',
});
