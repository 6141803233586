import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import SurveyJSFormRenderer from '@unite-us/surveyjs/dist/components/Renderer/SurveyJSFormRenderer';
import buildResponses from '@unite-us/surveyjs/dist/components/Renderer/buildResponses';
import { PrimaryButton, SecondaryButton } from 'common/TailwindComponents';
import { Spinner } from 'common/spinners';
import useFormSubmissions from 'src/components/FormSubmissions/useFormSubmissions';

import EnrollmentsSupportingDocuments from './EnrollmentsSupportingDocuments';
import './EnrollmentApplication.scss';

export const EnrollmentApplication = (props) => {
  const {
    groupId,
    currentEmployee,
    plan,
    form,
    formResponse,
    contactId,
    stepBackward,
    stepForward,
  } = props;
  const surveyRef = useRef();
  const [submitTriggered, setSubmitTriggered] = useState(0);
  const metafields = plan?.metafields ?? [];
  const requiredMetaFieldFiles = metafields.filter(({ type, validations }) => (
    type === 'file' && get(validations, 'required', false)
  ));
  const metaFieldFiles = metafields.filter(({ type }) => (
    type === 'file'
  ));

  const handleSetSurvey = (survey) => {
    surveyRef.current = survey;
  };

  const submitDocuments = (data) => {
    props.handleAttachDocuments(get(data, 'metadata'));
    stepForward();
  };

  const handleOnSubmit = () => {
    const survey = surveyRef.current;
    if (survey.completeLastPage() && !survey.hasErrors()) {
      props.handleFormResponseChange(buildResponses(survey));
      if (!isEmpty(requiredMetaFieldFiles)) {
        setSubmitTriggered(submitTriggered + 1);
      } else {
        stepForward();
      }
    }
  };

  const contextType = 'person';
  const context = contactId;
  const formSubmissionsData = useFormSubmissions({
    contextType,
    context,
    providerId: groupId,
    formFilter: form.id,
  });

  let formSubmission;

  if (isEmpty(formResponse)) {
    formSubmission = get(formSubmissionsData, 'formSubmissions[0]');
  } else {
    formSubmission = {
      responses: formResponse,
      form: form.id,
      provider: groupId,
      contextType: 'person',
      context: contactId,
      submitter: currentEmployee,
    };
  }

  if (isEmpty(form)) {
    return (
      <div className="form--loading">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="p-4 pt-0">
      <div className="bg-white px-6 py-4 flex items-center justify-start space-x-6">
        <div>
          <SurveyJSFormRenderer
            formData={{ configuration: form?.configuration }}
            formSubmission={formSubmission}
            editMode
            handleSetSurveyModel={handleSetSurvey}
          />
          <div className="relative z-0">
            {!isEmpty(metaFieldFiles) && (
              <>
                <div className="border-t border-solid border-dark-fill-blue -mx-2 lg:-mx-8 xl:-mx-16 mt-4 mb-10" />
                <h3 className="text-lg text-text-blue font-medium-font mb-4 leading-7">Supporting Documents</h3>
                <p className="mb-10 text-13px">
                  Upload your documents from your device. Acceptable formats are .CSV, .DOCX, .PDF, .JPG, or .PNG
                </p>
                <div className="space-y-8">
                  <EnrollmentsSupportingDocuments
                    requiredMetaFieldFiles={metaFieldFiles}
                    submitTriggered={submitTriggered}
                    onSubmit={(data) => submitDocuments(data)}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end px-6 py-4 space-x-6">
        <SecondaryButton label="Previous" onClick={stepBackward} />
        <PrimaryButton
          label="Next"
          type="submit"
          onClick={handleOnSubmit}
        />
      </div>
    </div>
  );
};

EnrollmentApplication.propTypes = {
  plan: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  formResponse: PropTypes.array.isRequired,
  currentEmployee: PropTypes.string.isRequired,
  contactId: PropTypes.string.isRequired,
  handleFormResponseChange: PropTypes.func.isRequired,
  handleAttachDocuments: PropTypes.func.isRequired,
  stepForward: PropTypes.func.isRequired,
  stepBackward: PropTypes.func.isRequired,
};

export default EnrollmentApplication;
