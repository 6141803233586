import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ContactPreferences from 'common/display/ContactPreferences/ContactPreferences';
import MessageEnabledCommunicationText from './MessageEnabledCommunicationText';
import {
  CLIENT_MUST_PROVIDE_CONSENT_TEXT,
  NO_MESSAGE_ENABLED_COMMUNICATION_TEXT,
} from '../constants';

const MessageEnabledCommunicationContent = ({
  consentAccepted,
  enabledEmails,
  enabledPhones,
  fields,
  contact_preference,
}) => {
  if (!consentAccepted) {
    return <p>{CLIENT_MUST_PROVIDE_CONSENT_TEXT}</p>;
  }
  if (enabledEmails.length > 0 || enabledPhones.length > 0) {
    return (
      <>
        <MessageEnabledCommunicationText
          fields={fields}
          enabledPhones={enabledPhones}
          enabledEmails={enabledEmails}
        />
        { contact_preference && !_.isEmpty(contact_preference.preferred_contact_methods) && (
          <div className="mb-8">
            <ContactPreferences contact_preference={contact_preference} abbreviateDays={false} />
          </div>
        )}
      </>
    );
  }
  return <p>{NO_MESSAGE_ENABLED_COMMUNICATION_TEXT}</p>;
};

MessageEnabledCommunicationContent.propTypes = {
  consentAccepted: PropTypes.bool,
  enabledEmails: PropTypes.array,
  enabledPhones: PropTypes.array,
  fields: PropTypes.object.isRequired,
  contact_preference: PropTypes.object,
};

MessageEnabledCommunicationContent.defaultProps = {
  consentAccepted: false,
  contact_preference: {},
  enabledEmails: [],
  enabledPhones: [],
};

export default MessageEnabledCommunicationContent;
