import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CityState from './CityState';

function includesCounty(name) {
  return !_.isEmpty(name.match(/county/ig));
}

function formatCountyName(name) {
  return includesCounty(name) ? name : `${name} County`;
}

const addressDisplay = ({ address }) => {
  if (_.isEmpty(address)) {
    return null;
  }

  const county = address.county ? (
    <p className="county">{formatCountyName(address.county)}</p>
  ) : null;

  const primaryIndicator = address.is_primary ? (
    <p className="italic">Primary</p>
  ) : null;

  return (
    <div className="address__details">
      {primaryIndicator}
      <p>{address.line_1} {address.line_2}</p>
      <p><CityState address={address} /> {address.postal_code}</p>
      {county}
    </div>
  );
};

addressDisplay.propTypes = {
  address: PropTypes.object,
};

addressDisplay.defaultProps = {
  address: {},
};

export default addressDisplay;
