import PropTypes from 'prop-types';
import React, { Component } from 'react';
import RouteNavigation from 'src/components/RouteNavigation';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { connect } from 'react-redux';
import { fetchActionables } from 'actions/Actionable/Group';
import { fetchGroup } from 'actions/Group';
import { fetchCurrentNetwork } from 'actions/Networks';
import { getGroupReferralScopesFromState } from 'src/components/Groups/utils/referralScope';
import featureFlag from 'src/common/utils/FeatureFlag/FeatureFlag';
import { getRedirectRoute } from 'src/components/Dashboard/utils/dataHelpers';
import { searchNetworkGroups } from 'actions/Group/Network';
import { unsetFetchActionables } from 'actions/Actionable';
import callOrLog from 'src/common/utils/callOrLog';
import isNetworkUser from 'src/components/User/utils/isNetworkUser';
import findKeyDifference from 'src/common/utils/findKeyDifference';
import { isCaseManager } from 'src/components/User/utils';
import { routesToNavConfig } from './utils/navigation';
import './stylesheets/dashboard.scss';

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.onNavClick = this.onNavClick.bind(this);
    this.redirectOnNav = this.redirectOnNav.bind(this);
    this.state = {
      navConfig: routesToNavConfig({ ...props }),
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.networkId) {
      Promise.resolve(this.props.fetchCurrentNetwork(this.props.networkId)).then(() => {
        this.props.searchNetworkGroups(this.props.networkId, {}, {}, true, true);
      });
    }
    this.redirectOnNav(this.props, null);
  }

  componentDidMount() {
    const { groupId, referralScopes } = this.props;

    this.props.fetchActionables(this.props.groupId);
    if (_.isEmpty(referralScopes)) {
      this.props.fetchGroup(groupId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.redirectOnNav(nextProps, this.props);
    this.setState({ navConfig: routesToNavConfig({ ...nextProps }) });

    let refetch = false;
    if (!this.props.data.isActionablesFetching && !nextProps.data.isActionablesFetching) {
      const diffKey = findKeyDifference(this.props.data, nextProps.data);
      if (diffKey && !_.isUndefined(nextProps.data[diffKey]) && !nextProps.data[diffKey]?.isFetching) {
        refetch = true;
      }
    }

    if (!this.props.refetchActionables && nextProps.refetchActionables) {
      this.props.unsetFetchActionables();
      refetch = true;
    }

    // If we already have an existing WebSocket
    // connection, kill the WebSocket to avoid it overwriting the new
    // actionables counts on the next message.
    if (this.socket) {
      this.socket.close();
      this.socket = undefined;
      refetch = true;
    }

    if (refetch) {
      this.props.fetchActionables(nextProps.groupId, true);
    }
  }

  componentWillUnmount() {
    if (this.socket) {
      this.socket.close();
    }
  }

  onNavClick(path, item) {
    callOrLog(() => {
      this.context.eventTracker(item.eventConstant, { path });
    });
    this.props.router.push(path);
  }

  redirectOnNav(props, prevProps) {
    if (!props.children) {
      const prevPath = _.get(prevProps, 'location.pathname', null);

      const { user, groupId, networkId } = this.props;
      if (isNetworkUser(user, groupId)) {
        this.props.router.push(`/network/${networkId}/browse`);
        return;
      }

      // A goal of this method is to prevent Dashboard tab navigation if we're already on the Dashboard tab.
      // Problem (CPR-87): The Clients tab is actually a shortcut to a Dashboard route, so this method kills the
      // click on the Dashboard tab when the Clients tab is active.
      // Fix: If prev path matches the route of the Clients tab, allow Dashboard tab navigation.
      const clientsTabRoute = '/dashboard/clients/all';
      const redirectPath = getRedirectRoute(this.state.navConfig, prevPath === clientsTabRoute ? null : prevPath);
      this.props.router.push(redirectPath);
    }
  }

  render() {
    const { children, location } = this.props;
    const childrenWithFuncs = React.Children.map(children, (child) => React.cloneElement(child, {
      isCaseManager: this.props.isCaseManager,
    }));
    return (
      <div className="dashboard-container">
        <div className="dashboard-nav">
          <RouteNavigation
            routes={this.state.navConfig}
            location={location}
            onClick={this.onNavClick}
          />
        </div>
        <div className="dashboard-content">
          {childrenWithFuncs}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  actionables: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
  children: PropTypes.node,
  data: PropTypes.object.isRequired,
  fetchActionables: PropTypes.func.isRequired,
  fetchGroup: PropTypes.func.isRequired,
  fetchCurrentNetwork: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  networkId: PropTypes.string.isRequired,
  isCaseManager: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  referralScopes: PropTypes.array.isRequired,
  refetchActionables: PropTypes.bool.isRequired,
  router: PropTypes.object.isRequired,
  searchNetworkGroups: PropTypes.func.isRequired,
  unsetFetchActionables: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const groupId = _.wget(state, 'session.groupId');
  const networkId = _.wget(state, 'session.networkId', state.networkId);
  const userId = _.wget(state, 'user.id');
  const token = _.wget(state, 'session.token');
  const userGroups = _.get(state, 'user.groups', []);

  const allNetworks = _.get(state, 'globalState.allNetworks', []).map((network) => ({
    id: network.id,
    name: network.name,
    coordination_centers: network.coordination_centers.data,
    network_type: network.network_type,
  }));

  // CORE-WORK - Need to resolve network.is_super_network
  const networks = state.user.networks;
  return {
    currentNetwork: _.find(allNetworks, { id: networkId }),
    groupId,
    networkId,
    userId,
    token,
    actionables: _.get(state, 'dashboard.actionables', {}),
    networks,
    allNetworks,
    data: _.get(state, 'dashboard', {}),
    isCaseManager: isCaseManager(state.user, groupId),
    isCCUser: _.get(state, 'session.isCoordinationGroup', false),
    referralScopes: getGroupReferralScopesFromState({ state, groupId }),
    refetchActionables: _.get(state, 'dashboard.refetchActionables'),
    roles: _.get(userGroups.find((userGroup) => (userGroup.group.id === groupId)), 'roles', []),
    user: _.get(state, 'user', {}),
  };
}

Dashboard.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

Dashboard.defaultProps = {
  children: '',
};

export default featureFlag(withRouter(
  connect(mapStateToProps, {
    fetchCurrentNetwork,
    fetchGroup,
    fetchActionables,
    searchNetworkGroups,
    unsetFetchActionables,
  })(Dashboard),
));
