import { isEmpty } from 'lodash';

const isCurrentLocation = (currentLocation = {}, location) => {
  if (isEmpty(currentLocation)) {
    return false;
  }

  return currentLocation.location === location;
};

export { isCurrentLocation };
