import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { removeClassName } from 'common/utils/domTools';
import _ from 'lodash';
import './stylesheets/detail-view.scss';

class DetailDescription extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { description } = nextProps;
    if (description) {
      const noDescriptionDivs = document.querySelectorAll('.no-description');
      _.forEach(noDescriptionDivs, (div) => removeClassName(div, 'field-invalid'));
    }
  }

  render() {
    const { description, title } = this.props;
    return (
      <div className="detail-info__description">
        <h5 className="detail-info__description-label">{title}</h5>
        <p className="no-description">Please enter a description</p>
        <p className="detail-info__description-text">{description}</p>
      </div>
    );
  }
}

DetailDescription.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
};

DetailDescription.defaultProps = {
  description: '',
};

export default DetailDescription;
