import { isEmpty, get, reduce, some } from 'lodash';

const getContactsFromScreenings = (screenings) => (
  reduce(screenings, (acc, curr) => {
    if (isEmpty(curr.contact)) {
      return acc;
    }
    const currentContactId = get(curr, 'contact.id');
    const isDuplicateContact = some(acc, { id: currentContactId });

    if (!isDuplicateContact && currentContactId) {
      return [...acc, curr.contact];
    }

    return acc;
  }, [])
);

export default getContactsFromScreenings;
