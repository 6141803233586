import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { CONSENT_DOWNLOAD_URL } from 'src/config/env/env.config';
import { getConsentDownloadUrl } from 'src/common/utils/Session/utils';

const DownloadConsentFormLink = ({ style }) => (
  <a
    id="download-consent-form-link"
    role="link"
    style={style}
    target="_blank" href={CONSENT_DOWNLOAD_URL}
    rel="noreferrer noopener"
    download
  >
    Download Consent Form
  </a>
);

DownloadConsentFormLink.propTypes = {
  style: PropTypes.object.isRequired,
};

DownloadConsentFormLink.defaultProps = {
  style: {
    color: 'dark-grey',
    textDecoration: 'underline',
    fontSize: '14px',
  },
};

function mapStateToProps(state) {
  return {
    downloadUrl: getConsentDownloadUrl(state),
  };
}

export default connect(mapStateToProps)(DownloadConsentFormLink);
