import React from 'react';
import PropTypes from 'prop-types';

const DataRow = React.forwardRef(({
  children, className, dataTestId, onClick, ariaLabel,
}, ref) => (
  <tr
    ref={ref}
    className={className}
    data-testid={dataTestId}
    onClick={onClick}
    tabIndex="0"
    role="button"
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick();
      }
    }}
    aria-label={ariaLabel}
  >
    {children}
  </tr>
));

DataRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string,
};

DataRow.defaultProps = {
  className: '',
  dataTestId: undefined,
  onClick: () => {},
  ariaLabel: '',
};

export default DataRow;
