import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';
import { Divider } from '@unite-us/ui';
import { CompactPhoneNumbers } from '@unite-us/client-utils';
import { findDisplayName } from 'src/components/Dashboard/utils/getStatusDisplayName';
import DateOfBirth from 'common/display/DateOfBirth';
import ContactPreferences from 'common/display/ContactPreferences/ContactPreferences';
import { hasLimitedGenderOptions, cl1018PreferredLanguagesFields } from 'common/utils/FeatureFlags/flags';
import { getGender } from 'src/common/utils/gender';
import ContactPreferredLanguages from 'src/common/display/ContactPreferredLanguages/ContactPreferredLanguages';
import {
  ContactAddressDisplay,
  ContactColumnDetails,
  ContactEmailDisplay,
  SecondaryDetail,
} from './components';
import './ContactColumn.scss';

export function ContactColumn(props) {
  const {
    contact,
    labelClickable,
    linkLabel,
    name,
    peopleEnums,
    showSecondaryDetails,
    limitedGenderOptions,
    preferredLanguagesEnabled,
  } = props;

  const nameClass = () => classNames({
    'contact-column__name': true,
    'contact-column__name--short-display': !labelClickable,
  });

  const containerClass = () => classNames({
    'contact-column': true,
    'contact-column--short-display': !labelClickable,
  });

  const dateOfBirth = _.get(contact, 'date_of_birth');
  const { preferred_contact_methods, contact_notes, timeslots } = _.get(contact, 'contact_preference', {});
  const contactPreferences = [preferred_contact_methods, contact_notes, timeslots];
  const showContactPreferences = contactPreferences.some((pref) => !_.isEmpty(pref));
  const showPreferredLanguages = preferredLanguagesEnabled && !_.isEmpty(contact.preferred_languages);

  return (
    <div className={containerClass()}>
      <h3 className={nameClass()}>{name}</h3>
      {
        labelClickable && (
          <ContactColumnDetails
            contact={contact}
            clickable={labelClickable}
            linkLabel={linkLabel}
            onLinkClick={props.onLinkClick}
          />
        )
      }

      <CompactPhoneNumbers phone_numbers={contact.phone_numbers} />

      <ContactEmailDisplay emails={contact.email_addresses} />

      <ContactAddressDisplay addresses={contact.addresses} />

      { showPreferredLanguages && (
        <SecondaryDetail id="languages" label="Preferred Languages">
          <ContactPreferredLanguages languages={contact.preferred_languages} />
        </SecondaryDetail>
      )}

      { showContactPreferences && (
        <>
          <Divider className="my-5" />
          <ContactPreferences
            contact_preference={contact.contact_preference}
            showVoiceMailOk
            hideLabel
          />
          <Divider className="my-5" />
        </>
      )}

      { showSecondaryDetails && (
        <div className="secondary-details">
          <SecondaryDetail id="dob" label="Date of Birth">
            {
              !_.isNil(contact.date_of_birth) ?
                <DateOfBirth date={dateOfBirth} oneline /> :
                null
            }
          </SecondaryDetail>

          <SecondaryDetail id="ssn" label="SSN">
            {
              contact.ssn_last4 ?
                `*** - ** - ${contact.ssn_last4}` :
                null
            }
          </SecondaryDetail>

          <SecondaryDetail id="race" label="Race">
            {findDisplayName(contact.race, peopleEnums.race)}
          </SecondaryDetail>

          <SecondaryDetail id="ethnicity" label="Ethnicity">
            {findDisplayName(contact.ethnicity, peopleEnums.ethnicity)}
          </SecondaryDetail>

          <SecondaryDetail id="marital_status" label="Marital Status">
            {findDisplayName(contact.marital_status, peopleEnums.marital_status)}
          </SecondaryDetail>

          <SecondaryDetail id="gender" label="Gender">
            {findDisplayName(getGender({ gender: contact.gender, flag: limitedGenderOptions }), peopleEnums.genders)}
          </SecondaryDetail>
        </div>
      )}
    </div>
  );
}

ContactColumn.propTypes = {
  contact: PropTypes.shape({
    addresses: PropTypes.array,
    date_of_birth: PropTypes.number,
    email_addresses: PropTypes.array,
    ethnicity: PropTypes.string.isRequired,
    full_name: PropTypes.string,
    gender: PropTypes.string.isRequired,
    marital_status: PropTypes.string.isRequired,
    phone_numbers: PropTypes.array,
    race: PropTypes.string.isRequired,
    ssn_last4: PropTypes.string,
    preferred_languages: PropTypes.array,
    contact_preference: PropTypes.shape({
      preferred_contact_methods: PropTypes.array,
      voice_mail_ok: PropTypes.bool,
      contact_notes: PropTypes.string,
      timeslots: PropTypes.shape({
        monday: PropTypes.array,
        tuesday: PropTypes.array,
        wednesday: PropTypes.array,
        thursday: PropTypes.array,
        friday: PropTypes.array,
        saturday: PropTypes.array,
        sunday: PropTypes.array,
      }),
    }),
  }).isRequired,
  labelClickable: PropTypes.bool,
  linkLabel: PropTypes.string.isRequired,
  name: PropTypes.string,
  onLinkClick: PropTypes.func,
  peopleEnums: PropTypes.shape({
    ethnicity: PropTypes.array,
    genders: PropTypes.array,
    marital_status: PropTypes.array,
    race: PropTypes.array,
  }),
  showSecondaryDetails: PropTypes.bool,
  limitedGenderOptions: PropTypes.bool.isRequired,
  preferredLanguagesEnabled: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    peopleEnums: _.get(state, 'session.enums.people', {}),
    limitedGenderOptions: hasLimitedGenderOptions(state),
    preferredLanguagesEnabled: cl1018PreferredLanguagesFields(state),
  };
}

ContactColumn.defaultProps = {
  labelClickable: true,
  name: '',
  onLinkClick: _.noop,
  peopleEnums: {},
  showSecondaryDetails: true,
};

export default connect(mapStateToProps, {})(ContactColumn);
