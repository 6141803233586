import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { get } from 'lodash';
import { reduxForm } from 'redux-form';
import {
  InputField,
  Button,
  SelectField,
} from '@unite-us/ui';
import { updateEmployee } from 'actions/User';
import updateCurrentEmployeeState from 'src/api/core/Employees/updateCurrentEmployeeState';

const EDIT_PERSONAL_INFORMATION = 'editPersonalInformation';

class PersonalInformationForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(form) {
    const {
      currentEmployee,
    } = this.props;
    const updatedEmployee = form.employee;

    if (form.employee.timezone) {
      updatedEmployee.timezone = updatedEmployee.timezone.name;
    }

    return this.props.updateEmployee(
      currentEmployee.id,
      updatedEmployee,
    ).then(
      (response) => {
        this.props.updateCurrentEmployeeState(response);
        this.props.closeModal();
      },
    );
  }

  render() {
    const {
      fields: { employee }, handleSubmit, submitting, timezones,
    } = this.props;

    const timezoneOptions = [
      {
        name: 'United States',
        disabled: true,
      },
      ...timezones.usa,
      {
        name: 'Global',
        disabled: true,
      },
      ...timezones.non_usa,
    ];

    return (
      <form onSubmit={handleSubmit(this.onSubmit)} className="content-with-actions">
        <div className="content-container">
          <InputField
            id="first-name"
            field={employee.first_name}
            type="text"
            label="First Name"
            inline={false}
          />
          <InputField
            id="last-name"
            field={employee.last_name}
            type="text"
            label="Last Name"
            inline={false}
          />
          <InputField
            id="work-title"
            field={employee.work_title}
            type="text"
            label="Work Title"
            inline={false}
          />
          <SelectField
            id="timezone"
            label="Timezone"
            field={employee.timezone}
            options={timezoneOptions}
            inline={false}
            valueKey="name"
            labelKey="name"
          />
        </div>
        <div className="actions">
          <span className="action-item">
            <Button
              id="cancel-personal-information-btn"
              label="Cancel"
              onClick={this.props.closeModal}
              secondary
            />
          </span>
          <span className="action-item">
            <Button
              id="save-personal-information-btn"
              onClick={handleSubmit(this.onSubmit)}
              primary
              label="Save"
              disabled={submitting}
            />
          </span>
        </div>
      </form>
    );
  }
}

PersonalInformationForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  timezones: PropTypes.object.isRequired,
  updateEmployee: PropTypes.func.isRequired,
  employee: PropTypes.object.isRequired,
  currentEmployee: PropTypes.object.isRequired,
  updateCurrentEmployeeState: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const {
    employee: {
      first_name,
      last_name,
      work_title,
      timezone,
    },
  } = ownProps;

  const { currentEmployee } = state.globalState;

  return {
    groupId: state.session.groupId,
    initialValues: {
      employee: {
        first_name,
        last_name,
        work_title,
        timezone,
      },
    },
    timezones: get(state, 'session.enums.timezones', {}),
    currentEmployee,
  };
}

const fields = [
  'employee.first_name',
  'employee.last_name',
  'employee.work_title',
  'employee.timezone',
];

export default reduxForm({
  form: EDIT_PERSONAL_INFORMATION,
  fields,
}, mapStateToProps, {
  updateEmployee,
  updateCurrentEmployeeState,
})(PersonalInformationForm);
