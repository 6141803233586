import { get } from 'lodash';

export default function buildOptionalInsuranceEntryParams(values) {
  const optionalParams = {
    data: {
      attributes: {},
    },
  };
  const external_group_id = get(values, 'insurance.group_id', '');

  optionalParams.data.attributes.external_group_id = external_group_id;

  if (get(values, 'insurance.enrollment')) {
    const coverageStartDate = new Date(values.insurance.enrollment * 1000);
    optionalParams.data.attributes.enrolled_at = coverageStartDate.toISOString();
  } else {
    optionalParams.data.attributes.enrolled_at = null;
  }

  if (get(values, 'insurance.expiration')) {
    const coverageEndDate = new Date(values.insurance.expiration * 1000);
    optionalParams.data.attributes.expired_at = coverageEndDate.toISOString();
  } else {
    optionalParams.data.attributes.expired_at = null;
  }

  return optionalParams;
}
