import { apiDefault } from 'src/api/config';
import { DELETE_GROUP_LOCATION } from 'actions';
import Notifier from 'common/helpers/Notifier';

function onSuccess(status, locationId, dispatch) {
  const dispatchObj = {
    type: DELETE_GROUP_LOCATION,
    locationId,
  };

  dispatch(dispatchObj);

  Notifier.dispatch(status, 'Address Deleted');
}

export default function deleteGroupLocation(locationId) {
  const request = apiDefault.delete(`/locations/${locationId}`);

  return (dispatch) => request.then(
    (success) => onSuccess(success.status, locationId, dispatch),
    (error) => Notifier.handleErrors(error),
  );
}
