import { DELETE_FILE_UPLOAD_SUCCESS } from 'actions';
import { apiV1 } from 'src/api/config';
import { isHttpSuccess } from 'src/api/utils/httpStatus';

export default function deleteFileUpload({ recordType, recordId, fileId }) {
  return async (dispatch) => {
    const response = await apiV1.deleteFile(recordType, recordId, fileId);

    if (!response || !isHttpSuccess(response.status)) return response;

    dispatch({
      type: DELETE_FILE_UPLOAD_SUCCESS,
      fileId,
    });

    return fileId;
  };
}
