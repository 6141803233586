import { every } from 'lodash';
import { isReferralsAdminAndAbove } from 'src/components/User/utils';

export default function canChangeHoldReason({ user, referral, groupId }) {
  const {
    state: referralState,
    case: {
      outcome: caseOutcome,
      state: caseState,
    },
  } = referral;
  if (caseState === 'draft') {
    return null;
  }

  const isUserAdminAndAbove = isReferralsAdminAndAbove(user, groupId);
  const isInReviewState = ['in_review'].includes(referralState);
  const isReceivingProvider = referral.receiving_provider.id === groupId;
  const isCaseOutcomeNull = caseOutcome === null;

  return every([isUserAdminAndAbove, isInReviewState, isReceivingProvider, isCaseOutcomeNull]);
}
