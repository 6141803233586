import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ChartMultiBar } from 'src/common/charts';
import { fetchCasesReports } from 'actions/Report/Case';
import { fetchCaseReportsByOutcomeForServiceType } from 'actions/Report/ServiceType/Case';
import { DOMAIN_CONSTANTS } from 'src/common/constants';
import _ from 'lodash';
import { resolutionPartitions } from '../../utils/partitions';
import { resolutionColors } from '../../utils/colors';

export default class CasesByServiceTypeAndResolution extends Component {
  static formatOutcomesData(data, x, y, colors) {
    const newData = [
      {
        key: 'Resolved',
        colors: colors[0],
        values: _.reduce(
          _.orderBy(data.values, 'title'),
          (acc, v) => (
            _.concat(acc, {
              [x]: v[x],
              [y]: v.resolution_type === 'resolved' ? v[y] : 0,
            })),
          [],
        ),
      },
      {
        key: 'Unresolved',
        colors: colors[1],
        values: _.reduce(
          _.orderBy(data.values, 'title'),
          (acc, v) => (
            _.concat(acc, {
              [x]: v[x],
              [y]: v.resolution_type === 'not_resolved' ? v[y] : 0,
            })),
          [],
        ),
      },
    ];
    return newData;
  }

  constructor(props) {
    super(props);
    this.onDrillDown = this.onDrillDown.bind(this);
    this.onServiceTypeBack = this.onServiceTypeBack.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.state = {
      casesByServiceTypeAndResolution: [],
      casesByOutcomeForServiceType: [],
      drilledDown: false,
      drilledServiceType: null,
      title: '',
    };
  }

  componentDidMount() {
    this.fetchData(this.props.filters);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.filters, nextProps.filters)) {
      this.fetchData(nextProps.filters);
    }
  }

  onDrillDown(col) {
    if (this.state.drilledDown) {
      this.setState({
        drilledDown: false,
        casesByOutcomeForServiceType: [],
        drilledServiceType: null,
      });
      return;
    }
    const serviceType = _.find(this.props.serviceTypes, { name: _.get(col, 'data.title', '') });
    const { scope, id } = this.props;
    fetchCaseReportsByOutcomeForServiceType(scope, id, _.wget(serviceType, 'code', ''), this.props.filters)
      .then((response) => (
        this.setState({
          drilledDown: true,
          casesByOutcomeForServiceType: _.get(response, 'data', {}),
          drilledServiceType: _.wget(col, 'data.title', ''),
        })
      ));
  }

  onServiceTypeBack() {
    this.setState({
      drilledDown: false,
      casesByOutcomeForServiceType: [],
      drilledServiceType: null,
    });
  }

  fetchData(filters) {
    const { scope, id } = this.props;
    const closed = scope === DOMAIN_CONSTANTS.GROUPS ? 'closed/' : '';

    fetchCasesReports(scope, id, `${closed}by-service-type`, { partitions: resolutionPartitions(), ...filters })
      .then((response) => {
        const sorted = _.orderBy(_.get(response, 'data.values', []), 'title', 'asc');
        this.setState({
          casesByServiceTypeAndResolution: _.assign({}, response.data, { values: sorted }),
          title: _.get(response, 'data.title', ''),
        });
        this.props.loaded('casesByServiceTypeAndResolution');
      });
  }

  render() {
    if (_.isEmpty(this.state.casesByServiceTypeAndResolution)) {
      return null;
    }
    const crstTitle = this.state.drilledDown ?
      `Closed Cases by Outcome for ${this.state.drilledServiceType}` :
      this.state.title;
    const crstSubTitle = this.state.drilledDown ?
      'Click any column to view by service type' :
      'Click any column to drilldown by outcome';
    return (
      <ChartMultiBar
        data={
          this.state.drilledDown ?
            this.state.casesByOutcomeForServiceType :
            this.state.casesByServiceTypeAndResolution
        }
        x={(d) => d.title}
        y={(d) => d.total}
        title={crstTitle}
        subTitle={crstSubTitle}
        showLegend
        showControls={!this.state.drilledDown}
        valueFormat="d"
        useInteractiveGuideline={false}
        rotateLabels={-45}
        margin={{ bottom: 160 }}
        onElementClick={this.onDrillDown}
        onBackButtonClick={this.onServiceTypeBack}
        showBackButton={this.state.drilledDown}
        maxKeyLength={100}
        colors={resolutionColors}
        customDataFormat={this.state.drilledDown ? CasesByServiceTypeAndResolution.formatOutcomesData : null}
        xLabel={this.state.drilledDown ? 'Outcome' : 'Service Type'}
      />
    );
  }
}

CasesByServiceTypeAndResolution.propTypes = {
  filters: PropTypes.shape({
    end_date: PropTypes.string,
    network_ids: PropTypes.array,
    start_date: PropTypes.string,
  }),
  loaded: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
  serviceTypes: PropTypes.array,
};
