import { useFind } from 'src/api/APIHooks';

const useInvoiceRejectionReasons = (feeScheduleId) => {
  const { isLoading, data: response } = useFind(
    'invoice_rejection_reasons',
    { fee_schedule: feeScheduleId },
    {
      queryConfig: {
        enabled: !!feeScheduleId,
        placeholderData: undefined,
      },
    },
  );

  return isLoading ? [] : response.data.data;
};

export default useInvoiceRejectionReasons;
