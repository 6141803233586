import { useQuery } from 'react-query';
import { get } from 'lodash';
import { screeningsApiClient, coreApi } from 'src/api/config';
import {
  SELECT_SCREENING_QUESTION,
} from 'src/pages/facesheet/_id/eligibility/utils';

const SCREENING_TYPES = {
  SCREENING: 'screening',
  ASSESSMENT: 'assessment',
};

export const EL_ASSESSMENT_QUERY_KEYS = {
  ASSESSMENTS: 'eligibility-assessments',
  ASSESSMENT: 'eligibility-assessment',
};

export const useEligibilityAssessments = (contactId) => (
  useQuery(
    [EL_ASSESSMENT_QUERY_KEYS.ASSESSMENTS, contactId],
    async () => {
      const queryParams = {
        person_id: contactId,
        type: SCREENING_TYPES.ASSESSMENT,
      };
      const response = await screeningsApiClient.get('/screenings', { params: queryParams });
      const screens = get(response, 'data.screens', []);
      const facilitators = [];
      screens.forEach((screen) => {
        if (!facilitators.includes(screen.facilitator_id)) {
          facilitators.push(screen.facilitator_id);
        }
      });
      const subjects = [];
      screens.forEach((screen) => {
        if (!subjects.includes(screen.subject.id)) {
          subjects.push(screen.subject.id);
        }
      });
      const organizations = screens.map((s) => s.organization_id);
      const [peopleResponse, providersResponse, facilitatorResponse] = await Promise.all([
        coreApi.query('employee', { ids: subjects }),
        coreApi.query('provider', { ids: organizations }),
        coreApi.query('employee', { ids: facilitators }),
      ]);

      const newScreens = screens.map((screen) => {
        const person = peopleResponse.data.data.find((p) => p.id === screen.subject.id);
        const provider = providersResponse.data.data.find((p) => p.id === screen.organization_id);
        const facilitator = facilitatorResponse.data.data.find((f) => f.id === screen.facilitator_id);
        return {
          ...screen,
          person,
          provider,
          facilitator,
        };
      });

      return newScreens;
    },
    {
      staleTime: 10 * 60 * 1000,
      refetchInterval: 10 * 60 * 1000,
      retry: false,
    },
  )
);

export const useElAssessment = (assessmentId, { enabled }) => (
  useQuery(
    [EL_ASSESSMENT_QUERY_KEYS.ASSESSMENT, assessmentId],
    async () => {
      const response = await screeningsApiClient.get(`/screenings/${assessmentId}`);
      if (get(response, 'data.screen.questions')) {
        const questions = response.data.screen.questions;
        response.data.screen.questions = questions.sort((a, b) => a.order - b.order);
        response.renderQuestions = questions.filter((q) => {
          if (q.primaryText === SELECT_SCREENING_QUESTION) {
            return false;
          }
          return true;
        });
      }
      return {
        ...response,
      };
    },
    {
      enabled,
      staleTime: Infinity,
      retry: false,
      refetchInterval: false,
    },
  )
);
