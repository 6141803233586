import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  InputField,
} from '@unite-us/ui';
import { theme } from 'src/../tailwind.config';

const MetafieldsOptions = ({
  onDeleteOption,
  onOptionFieldChange,
  options,
  setOptions,
}) => {
  const [index, setIndex] = useState(options.length);

  const addOption = () => {
    setOptions([
      ...options,
      '',
    ]);
    setIndex(index + 1);
  };

  const removeOption = (i) => {
    const newOptions = [...options];
    newOptions.splice(i, 1);
    setOptions(newOptions);
    onDeleteOption(newOptions);
  };

  return (
    <div className="mb-3 metaFields options">
      { options.map((option, i) => (
        <div>
          <div className={'flex justify-between'}>
            <div className="flex items-center mt-3 w-5/6">
              <InputField
                id={`option_${i + 1}`}
                label={`Option ${i + 1}`}
                placeholder="Enter the option."
                value={option}
                onChange={(e) => onOptionFieldChange(i, e.target.value)}
                required={options.length === 1}
              />
            </div>
            { options.length > 1 && (
              <div className="flex items-center">
                <Icon
                  color={theme.extend.colors['action-blue']}
                  icon="V2Delete"
                  onClick={() => {
                    removeOption(i);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      ))}
      <Button
        id="add-custom-field-button"
        iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="V2Add" />}
        className="mb-5 pl-1 text-sm text-action-blue rounded border-none bg-transparent normal-case add_button"
        label={'ADD OPTION'}
        onClick={() => addOption()}
      />
    </div>
  );
};

MetafieldsOptions.propTypes = {
  onDeleteOption: PropTypes.func.isRequired,
  onOptionFieldChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  setOptions: PropTypes.func.isRequired,
};

export default MetafieldsOptions;
