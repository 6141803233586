import axios from 'axios';
import { SELECT_CONTACT, CREATE_GROUP_CONTACT } from 'actions';
import Notifier from 'common/helpers/Notifier';
import { uuCompactArrayOrObject } from 'lodash';

function createContact({
  assistanceRequestId,
  contact,
  groupId,
  showNotification = true,
}) {
  return (dispatch) => {
    const request = axios.post(`/groups/${groupId}/contacts`, uuCompactArrayOrObject({
      assistance_request_id: assistanceRequestId,
      contact,
    }));

    function onSuccess(payload) {
      dispatch({
        type: CREATE_GROUP_CONTACT,
        payload,
      });
      dispatch({
        type: SELECT_CONTACT,
        id: payload.data.data.id,
      });

      if (showNotification) {
        Notifier.dispatch(payload.status, 'Client Successfully Created');
      }
      return payload;
    }

    function onError(error) {
      Notifier.handleErrors(error);
      return error;
    }

    return request.then(onSuccess, onError);
  };
}

export default createContact;
