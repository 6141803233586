import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { CheckBoxField } from '@unite-us/ui';

const NoteVisibility = ({ registerField, field, disabled }) => (
  <div>
    <div>
      <h5 className="label-header">
        Note Visibility
      </h5>
    </div>

    <CheckBoxField
      id="note-visibility-checkbox"
      field={field}
      hidden={false}
      label="Private to my organization"
      ref={registerField}
      disabled={disabled}
      showError={false}
      showHint={false}
    />
  </div>
);

NoteVisibility.propTypes = {
  registerField: PropTypes.func,
  disabled: PropTypes.bool,
  field: PropTypes.object.isRequired,
};

NoteVisibility.defaultProps = {
  hidden: false,
  disabled: false,
  registerField: noop,
};

export default NoteVisibility;
