import React, { useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { omit, get } from 'lodash';
import { InputField, getPreferredProp } from '@unite-us/ui';
import dollarsToCents from 'common/utils/Money/dollarsToCents';

const CurrencyField = React.forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({ props }));

  const reduxOnChange = getPreferredProp(props, 'onChange');
  const reduxOnBlur = getPreferredProp(props, 'onBlur');

  const toDollarValue = (centValue) => {
    const floatNum = parseFloat(centValue) / 100;
    return Number.isNaN(floatNum) ? '' : floatNum;
  };

  const onChange = (event) => {
    const value = get(event, 'target.value', '');
    reduxOnChange(dollarsToCents(value));
  };

  const onBlur = (event) => {
    const value = get(event, 'target.value', '');
    reduxOnBlur(dollarsToCents(value));
  };

  const value = getPreferredProp(props, 'value');
  const inputProps = omit(props, [
    'field.onBlur',
    'field.onChange',
    'field.initialValue',
    'field.value',
    'input.onBlur',
    'input.onChange',
    'input.value',
    'meta.initial',
  ]);
  const dollarValue = toDollarValue(value);

  return (
    <InputField
      {...inputProps}
      onChange={onChange}
      onBlur={onBlur}
      type="number"
      placeholder="0.00"
      step="0.1"
      value={dollarValue}
    />
  );
});

CurrencyField.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  field: PropTypes.object,
};

CurrencyField.defaultProps = {
  onChange: null,
  onBlur: null,
  value: '',
  field: null,
};

export default CurrencyField;
