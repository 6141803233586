import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  BaseCard,
  BaseCardBody,
  Icon,
} from '@unite-us/ui';
import { theme, hexToRgba } from '../utils';

import './NumberStatistic.scss';

class NumberStatistic extends PureComponent {
  render() {
    const {
      color,
      data,
      icon,
      size,
      style,
      title,
    } = this.props;
    const iconStyle = {
      backgroundColor: hexToRgba(color, 0.15),
      borderColor: color,
    };
    return (
      <BaseCard className="chart">
        <BaseCardBody>
          <div className="number-stat" style={style}>
            <div
              className="stat-icon"
              style={iconStyle}
            >
              <Icon
                color={color}
                icon={icon}
                size={size}
              />
            </div>
            <div className="stat-data">{data || '-'}</div>
            <div className="stat-title">{title}</div>
          </div>
        </BaseCardBody>
      </BaseCard>
    );
  }
}

NumberStatistic.propTypes = {
  color: PropTypes.string.isRequired,
  data: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  title: PropTypes.string,
};

NumberStatistic.defaultProps = {
  color: theme.colors[0],
  size: 50,
  style: {},
};


export default NumberStatistic;
