// import axios from 'axios';
import Notifier from 'common/helpers/Notifier';
// import { createDocumentFormData } from 'src/components/InformedConsent/utils';
// import { CONTACT_CONSENT_ACCEPTED } from 'actions';
import { coreApi } from 'src/api/config';
import { get } from 'lodash';

export default function createDocumentConsent({
  contactId, documentsFileList, consentMethod,
}) {
  return async () => {
    try {
      const response = await coreApi.createRecord('consent', {
        state: 'accepted',
        consent_method: consentMethod,
        submission_method: 'file_upload',
        person: contactId,
      });
      const id = get(response, 'data.data.id');
      await coreApi.uploadFile('consent', id, documentsFileList[0]);

      Notifier.dispatch(response.status, 'Your consent has been recorded.');
      return response;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}
