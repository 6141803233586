import axios from 'axios';
import {
  FETCH_CONTACT_SCREENING,
} from 'actions';
import Notifier from 'common/helpers/Notifier';

function fetchContactScreening({
  groupId, contactId, screeningId, formId,
} = {}) {
  const request = axios.get(
    `/groups/${groupId}/contacts/${contactId}/forms/${formId}/screenings/${screeningId}`,
  );

  return (dispatch) => {
    function onSuccess(payload) {
      dispatch({
        type: FETCH_CONTACT_SCREENING,
        payload,
      });
      return payload;
    }

    function onError(error) {
      Notifier.handleErrors(error);

      return error;
    }

    return request.then(onSuccess, onError);
  };
}

export default fetchContactScreening;
