import { FETCH_NETWORK_NOTIFICATION_PREFERENCES, UPDATE_NETWORK_NOTIFICATION_PREFERENCES_SUCCESS } from 'actions';

export const defaultState = {};

export default function (state = defaultState, action) {
  switch (action.type) {
    case FETCH_NETWORK_NOTIFICATION_PREFERENCES: {
      return { ...state, [action.payload.data.data.id]: action.payload.data.data };
    }
    case UPDATE_NETWORK_NOTIFICATION_PREFERENCES_SUCCESS: {
      return { ...state, [action.payload.data.data.id]: action.payload.data.data };
    }
    case 'redux-form/DESTROY': {
      if (action.form === 'NetworkNotificationPreferencesForm') {
        return defaultState;
      }
      return state;
    }
    default:
      return state;
  }
}
