import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'common/utils/browserHistory';
import {
  useFindFeeSchedule,
  useUpdateFeeSchedule,
} from 'src/components/Backoffice/api/hooks/v1/feeScheduleHooks';
import {
  constructFeeScheduleInitialFormValues,
  constructFeeScheduleFormPayload,
} from 'src/pages/fee-schedules/_id/utils/index';
import FeeScheduleForm from '../components/FeeScheduleForm';

const EditFeeScheduleProgram = ({ params }) => {
  const feeScheduleId = params.id;
  const { updateRecord: updateFeeSchedule } = useUpdateFeeSchedule();
  const { data, isFetching } = useFindFeeSchedule(feeScheduleId);
  const initialValues = constructFeeScheduleInitialFormValues({ ...data });

  const onSubmit = async (feeSchedule) => {
    const payload = constructFeeScheduleFormPayload(feeSchedule);
    const response = await updateFeeSchedule(feeScheduleId, payload);
    if (response) {
      browserHistory.push({
        pathname: `/backoffice/fee_schedules/${feeScheduleId}`,
      });
    }
  };

  return (
    <FeeScheduleForm
      feeScheduleId={feeScheduleId} onSubmit={onSubmit} initialValues={initialValues}
      showPending={isFetching}
    />
  );
};

EditFeeScheduleProgram.propTypes = {
  params: PropTypes.object.isRequired,
};

export default EditFeeScheduleProgram;
