import React from 'react';
import PropTypes from 'prop-types';
import { NoInvoicesFoundCard } from '../components';
import InvoiceWorkqueue from './InvoiceWorkqueue';

const PaidInvoices = ({ children }) => {
  const bulkActions = ['IconDownloadV2', 'IconArchive'];

  return (
    <InvoiceWorkqueue
      invoiceStatus="paid"
      bulkActions={bulkActions}
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <NoInvoicesFoundCard isFiltered={isFiltered} status="paid" resetFilters={resetFilters} />
      )}
      path="closed/paid/"
    >
      {children}
    </InvoiceWorkqueue>
  );
};

PaidInvoices.propTypes = {
  children: PropTypes.node,
};

PaidInvoices.defaultProps = {
  children: undefined,
};
export default PaidInvoices;
