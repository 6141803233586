import { coreApi } from 'src/api/config';
import Notifier from 'common/helpers/Notifier';
import { setDashboardRefetch } from 'actions/Dashboard';
import {
  REFERRAL_ALREADY_ACCEPTED_MESSAGE,
} from 'src/components/Referrals/ReferralStatus/constants';
import { isHttpError } from 'common/utils/Error';

const rejectReferral = (groupId, referralId, rejection, refetch = false) => (
  async (dispatch, getState) => {
    let createNoteError = null;
    let updateReferralPayload = null;
    let updateReferralError = null;

    try {
      await coreApi.createRecord('note', {
        subjectType: 'referral',
        subject: referralId,
        employee: getState().globalState.currentEmployee.id,
        visibility: 'public',
        category: 'general',
        text: rejection.note,
        details: { context: 'declined' },
      });
    } catch (error) {
      createNoteError = error;
    }

    // Always update the referral even if note create failed.
    try {
      updateReferralPayload = await coreApi.updateRecord('referral', referralId, {
        state: 'declined',
        reason: rejection.reason,
      });
    } catch (error) {
      updateReferralError = error;
    }

    const firstError = createNoteError || updateReferralError;

    if (firstError) {
      if (isHttpError(firstError, 409)) {
        Notifier.dispatch('error', REFERRAL_ALREADY_ACCEPTED_MESSAGE, dispatch);
      } else {
        Notifier.handleErrors(firstError);
      }
    } else {
      Notifier.dispatch(updateReferralPayload.status, 'Referral Rejected Successfully');

      if (refetch) {
        dispatch(setDashboardRefetch());
      }
    }

    return updateReferralPayload || firstError;
  }
);

export default rejectReferral;
