import { get, isEmpty, join, trim, compact } from 'lodash';
var getAddressString = function getAddressString(address) {
  if (isEmpty(address)) {
    return '';
  }
  var street = trim("".concat(get(address, 'line_1') || '', " ").concat(get(address, 'line_2') || ''));
  var city = trim(get(address, 'city') || '');
  var stateAndZip = trim("".concat(get(address, 'state') || '', " ").concat(get(address, 'postal_code') || ''));
  var addressParts = compact([street, city, stateAndZip]);
  return isEmpty(addressParts) ? '' : join(addressParts, ', ');
};
export default getAddressString;