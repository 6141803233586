export function stripHTML() {
  var html = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  if (!html) {
    return '';
  }
  return html.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&apos;');
}
export function decodeHTML(html) {
  if (typeof document === 'undefined') return '';
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  // eslint-disable-next-line consistent-return
  return txt.textContent;
}