import { browserHistory } from 'common/utils/browserHistory';
import STEP_DEFAULTS from './utils/defaults';
import {
  NEW_REFERRAL_PATH,
  NEW_REFERRAL_SUPPORTING_PATH,
  NEW_REFERRAL_REVIEW_PATH,
} from '../constants';

const QUERY_STRING = '?src=facesheet-referral';

const facesheetReferralStepperConfig = {
  steps: [
    {
      ...STEP_DEFAULTS.first,
      title: 'Add Referral',
      onStepClick: () => {
        browserHistory.push(`${NEW_REFERRAL_PATH}${QUERY_STRING}`);
      },
      routes: [
        {
          route: NEW_REFERRAL_PATH,
        },
        {
          route: NEW_REFERRAL_SUPPORTING_PATH,
        },
      ],
    },
    {
      ...STEP_DEFAULTS.second,
      title: 'Review Referral',
      onStepClick: () => {
        browserHistory.push(`${NEW_REFERRAL_REVIEW_PATH}${QUERY_STRING}`);
      },
      routes: [
        {
          route: NEW_REFERRAL_REVIEW_PATH,
        },
      ],
    },
  ],
};

export default facesheetReferralStepperConfig;
