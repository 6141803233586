import Notifier from 'common/helpers/Notifier';

export default function sessionExpiredNotification(error) {
  const modifiedError = {
    ...error,
    response: {
      ...error.response,
      statusText: 'Your session has timed out.  Please sign in again.',
    },
  };
  return () => Notifier.handleErrors(modifiedError);
}
