import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DynamicDataTable from 'src/components/Backoffice/dataTables/DynamicDataTable';
import FormSearchTable from 'src/components/Backoffice/searchTable/FormSearchTable';
import { useFindFormUsageByFormOrFormUtilizer, useCreateFormUsage, useUpdateFormUsage } from 'src/components/Backoffice/api/hooks/v1/formUsageHooks';
import { isEmpty } from 'lodash';
import DialogV2 from 'common/modal/DialogV2';
import { Icon } from '@unite-us/ui';
import { theme } from 'src/../tailwind.config';

const PlanEnrollmentForms = ({ planId, enrollmentForm }) => {
  const AddReplaceFormModal = useRef(null);

  const { data: formUsages } = useFindFormUsageByFormOrFormUtilizer(
    enrollmentForm?.id,
    planId,
    'plan',
  );
  const formUsage = formUsages[0];

  const [selectedFormId, setSelectedFormId] = useState(isEmpty(enrollmentForm) ? '' : enrollmentForm?.id);
  useEffect(() => {
    if (!isEmpty(enrollmentForm)) {
      setSelectedFormId(enrollmentForm.id);
    }
  }, [enrollmentForm]);

  const openModal = () => {
    AddReplaceFormModal.current.openDialog();
  };

  const closeModal = () => {
    AddReplaceFormModal.current.closeDialog();
    setSelectedFormId(isEmpty(enrollmentForm) ? '' : enrollmentForm?.id);
  };

  const { createRecord: createFormUsage } = useCreateFormUsage();
  const { updateRecord: updateFormUsage } = useUpdateFormUsage();

  const addFormHandler = async (formId, plan_id) => {
    await createFormUsage({
      usage_type: 'enrollment',
      form: {
        id: formId,
        type: 'form',
      },
      form_utilizer: {
        id: plan_id,
        type: 'plan',
      },
    });
    closeModal();
  };

  const replaceFormHandler = async (formId, formUsageId) => {
    await updateFormUsage(formUsageId, {
      form: {
        id: formId,
        type: 'form',
      },
    });
    closeModal();
  };

  return (
    <>
      <DynamicDataTable
        title="Enrollment Form"
        button={{
          label: 'Add/Replace Form',
          icon: <Icon color={theme.extend.colors['current-blue']} icon="V2Edit" />,
          onClick: openModal,
        }}
        table={{
          headers: ['Name', 'Last Updated', 'Form ID'],
          data: isEmpty(enrollmentForm) ? [] : [{
            rowId: enrollmentForm.id,
            data: {
              name: enrollmentForm.name,
              lastUpdated: new Date(enrollmentForm.updated_at).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
              }),
              formId: enrollmentForm.id,
            },
          }],
          emptyTableMessage: 'Click "Add Form" to add form.',
        }}
      />
      <DialogV2
        cancelHandler={closeModal}
        confirmationHandler={
          isEmpty(enrollmentForm) ?
           () => addFormHandler(selectedFormId, planId) :
           () => replaceFormHandler(selectedFormId, formUsage.id)
        }
        confirmLabel="Select"
        id={'add-replace-form-modal'}
        ref={AddReplaceFormModal}
        title={'Add/Replace Form'}
        confirmationBtnDisabled={selectedFormId === ''}
        width="6xl"
        confirmButtonTestId="add-replace-form-modal-confirm-button"
        dialogDescriptionClass="flex flex-col h-screen overflow-hidden bg-light-border-grey"
        scrollable={false}
        dialogWrapperPadding="p-12"
      >
        <FormSearchTable
          selectedFormId={selectedFormId}
          setSelectedFormId={setSelectedFormId}
        />
      </DialogV2>
    </>
  );
};

PlanEnrollmentForms.propTypes = {
  planId: PropTypes.string.isRequired,
  enrollmentForm: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    updated_at: PropTypes.string,
  }),
};

PlanEnrollmentForms.defaultProps = {
  enrollmentForm: {},
};

export default PlanEnrollmentForms;
