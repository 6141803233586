import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { PrimaryButton, TextButton } from 'src/common/TailwindComponents';
import SpinIcon from 'src/common/spinners/SpinIcon';

const InvoiceExportsToolbar = ({
  allInvoicesLoading,
  className,
  deselectAll,
  downloadInvoices,
  selectAll,
  selectedInvoices,
}) => (
  <div className={cx(className, 'flex justify-between')}>
    <PrimaryButton
      disabled={selectedInvoices.length === 0}
      icon="IconDownloadV2"
      label="Download"
      onClick={downloadInvoices}
    />
    <div className="flex items-end divide-x divide-solid divide-dark-border-blue">
      <span className="pr-2">
        {allInvoicesLoading ? (
          <SpinIcon />
        ) : (
          <>
            {selectedInvoices.length > 0 ? selectedInvoices.length : 'None'}
            &nbsp;selected
          </>
        )}
      </span>
      <TextButton onClick={selectAll} className="pr-2 pl-2">Select All</TextButton>
      <TextButton onClick={deselectAll} className="pl-2">Deselect All</TextButton>
    </div>
  </div>
);

InvoiceExportsToolbar.propTypes = {
  allInvoicesLoading: PropTypes.bool.isRequired,
  className: PropTypes.string,
  deselectAll: PropTypes.func.isRequired,
  downloadInvoices: PropTypes.func.isRequired,
  selectAll: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.array.isRequired,
};

InvoiceExportsToolbar.defaultProps = {
  className: '',
};

export default InvoiceExportsToolbar;
