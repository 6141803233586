import { find } from 'lodash';
import { getDeterminantNetworkId } from 'src/components/Referrals/utils/form';

const getCCGroupIds = (networks, fields) => {
  const networkId = getDeterminantNetworkId(fields);
  const network = find(networks, { id: networkId }) || {};
  const { ccIds } = network;

  return ccIds;
};

export default getCCGroupIds;
