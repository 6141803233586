import {
  displayText,
  generateColumn,
  displayDateWithTime,
} from 'common/display/SingleItemDetails/utils/common';

function firstColumnInfo(assistanceRequest) {
  return [
    displayText(assistanceRequest, { label: 'Service Type', key: 'service_type.name' }),
  ];
}

function secondColumnInfo(assistanceRequest) {
  const key = assistanceRequest?.ar_submitted_on ? 'ar_submitted_on' : 'created_at';

  return [
    displayDateWithTime(assistanceRequest, { label: 'Date Submitted', key }),
  ];
}

export default function generateDetailLabelData(assistanceRequest) {
  return [
    generateColumn(firstColumnInfo(assistanceRequest)),
    generateColumn(secondColumnInfo(assistanceRequest)),
  ];
}
