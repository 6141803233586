import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@unite-us/ui';
import { isEmpty } from 'lodash';
import { theme } from 'src/../tailwind.config';
import DialogV2 from 'src/common/modal/DialogV2';
import { generateUUID } from 'common/utils/utils';
import {
  useCreateInvoiceRejectionReason,
  useUpdateInvoiceRejectionReason,
} from 'src/components/Backoffice/api/hooks/v1/invoiceRejectionReasonHooks';
import { useInvalidateQueries } from 'src/api/APIHooks';
import FeeScheduleRejectionReasonForm from './FeeScheduleRejectionReasonForm';

const FeeScheduleEditInvoiceRejectionReasons = ({ rejectionReasons, modalRef, feeScheduleId }) => {
  const formTitle = 'Invoice Rejection Reason';
  const [rejectionReasonsData, setRejectionReasonsData] = useState(rejectionReasons);
  const { createRecord: createInvoiceRejectionReason } = useCreateInvoiceRejectionReason();
  const { updateRecord: updateInvoiceRejectionReason } = useUpdateInvoiceRejectionReason();
  const invalidateQueries = useInvalidateQueries();

  const onChangeHandler = ((updatedReason, index) => {
    const newRejectionReasonsData = [...rejectionReasonsData];
    newRejectionReasonsData[index] = updatedReason;
    setRejectionReasonsData(newRejectionReasonsData);
  });

  const addNewReason = () => {
    setRejectionReasonsData(
      [
        ...rejectionReasonsData,
        {
          code: '',
          display_name: '',
          description: '',
          fee_schedule: { id: feeScheduleId },
          temp_id: generateUUID(),
        },
      ],
    );
  };

  const closeModal = () => {
    modalRef.current.closeDialog();
  };

  const onCancelHandler = () => {
    setRejectionReasonsData(rejectionReasons);
    closeModal();
  };

  const onSaveHandler = async () => {
    const rejectedReasonsWithoutIds = rejectionReasonsData.filter((reason) => !reason.id);
    const editedReasons = rejectionReasonsData.filter((reason) => reason.id);
    let hasErrors = false;

    const createPromises = rejectedReasonsWithoutIds.map(async (reason) => {
      try {
        await createInvoiceRejectionReason(reason);
      } catch (error) {
        hasErrors = true;
      }
    });

    const updatePromises = editedReasons.map(async (reason) => {
      try {
        await updateInvoiceRejectionReason(reason.id, reason);
      } catch (error) {
        hasErrors = true;
      }
    });

    await Promise.all([...createPromises, ...updatePromises]);

    if (!hasErrors) {
      invalidateQueries('invoice_rejection_reason');
      closeModal();
    }
  };

  const cancelNewReasonHandler = (index) => {
    const newRejectionReasonsData = [...rejectionReasonsData];
    newRejectionReasonsData.splice(index, 1);
    setRejectionReasonsData(newRejectionReasonsData);
  };

  return (
    <DialogV2
      cancelHandler={onCancelHandler}
      confirmationHandler={onSaveHandler}
      confirmLabel="Save"
      id="edit-invoice-rejection-reasons-modal"
      ref={modalRef}
      title="Add/Edit Invoice Rejection Reasons"
      vertical
      dialogDescriptionClass={'flex-auto overflow-y-auto bg-medium-fill-blue'}
    >
      <div>
        {!isEmpty(rejectionReasonsData) &&
          rejectionReasonsData.map((reason, index) => (
            <div className={'mb-2'} key={reason.id || reason.temp_id}>
              <FeeScheduleRejectionReasonForm
                title={`${formTitle} ${index + 1}`}
                rejectionReason={reason}
                onChangeHandler={onChangeHandler}
                index={index}
                showCrossOnForm={index > (rejectionReasons.length - 1)}
                onCrossClickHandler={cancelNewReasonHandler}
                hasState={false}
              />
            </div>
          ))}
        <Button
          id="add-rejection-reason-button"
          iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="V2Add" />}
          className="py-1 px-4 text-sm text-action-blue rounded border-none bg-transparent normal-case add_button"
          label="ADD REJECTION REASON"
          onClick={() => addNewReason()}
        />
      </div>
    </DialogV2>
  );
};

FeeScheduleEditInvoiceRejectionReasons.propTypes = {
  rejectionReasons: PropTypes.array.isRequired,
  modalRef: PropTypes.func.isRequired,
  feeScheduleId: PropTypes.string.isRequired,
};

export default FeeScheduleEditInvoiceRejectionReasons;
