import sortAddresses from "./sortAddresses";
/**
 * Finds the main address from a list of addresses.
 *
 * The main address is the primary address, or, the
 * most recently created address (fallback).
 *
 * @param {any[]} addresses
 * @returns {any | null} The address found or null if not found.
 */
export default function findMainAddress() {
  var addresses = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var sortedAddresses = sortAddresses(addresses);
  return (sortedAddresses === null || sortedAddresses === void 0 ? void 0 : sortedAddresses[0]) || null;
}