import _ from 'lodash';

function filterUserLicensedNetworks(user, networks) {
  const userNetworks = _.get(user, 'networks', []);
  return _.filter(networks, (network = {}) => (
    _.some(userNetworks, { id: network.id })
  ));
}

export default filterUserLicensedNetworks;
