// Libary Imports
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

// Style Imports
import 'src/components/Referrals/stylesheets/referral-program-display.scss';

// Component Imports
import ReferralProgramDialog from './ReferralProgramDialog';

const ReferralProgramDisplay = ({
  referral,
  groupId,
  hideEditBtn = false,
}) => {
  const contact = _.wget(referral, 'contact', {});

  return (
    <div className="referral-program-display">
      <span className="referral-program-display__program-text">
        {_.wget(referral, 'referred_to_program.name', '')}
      </span>

      <ReferralProgramDialog
        contact={contact}
        groupId={groupId}
        referral={referral}
        hideEditBtn={hideEditBtn}
      />
    </div>
  );
};

ReferralProgramDisplay.propTypes = {
  referral: PropTypes.shape({
    contact: PropTypes.object.isRequired,
    referred_to_program: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  hideEditBtn: PropTypes.bool,
};

ReferralProgramDisplay.defaultProps = {
  hideEditBtn: false,
};

export default ReferralProgramDisplay;
