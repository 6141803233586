import React from 'react';
import PropTypes from 'prop-types';

const ServiceTypesInfo = ({ orgIsDraft }) => (
  <>
    <div className="my-2 text-dark-grey" data-testid="service-type-info">
      Select the services that this program <em>directly</em> provides.&nbsp;
      These include things such as food, clothing, housing, legal services, etc.
      Select the option(s) that most closely match your services.&nbsp;
      {!orgIsDraft ? (
        <>
          Some service types may be disabled because they are sensitive. Contact
          Unite Us to add sensitive service types to this program.&nbsp;
        </>
      ) : (
        ''
        )}
      <a
        data-test-element="about_service_types_link"
        target="_blank"
        rel="noreferrer"
        href="https://support.uniteus.io/en/articles/2812619-service-types-glossary"
      >
        Learn more.
      </a>
    </div>
    <div className="my-4 text-dark-grey">
      Asterisks (*) denote sensitive service types.
    </div>
  </>
);

ServiceTypesInfo.propTypes = {
  orgIsDraft: PropTypes.bool.isRequired,
};

export default ServiceTypesInfo;
