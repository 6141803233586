const createXHRPromise = (url) => new Promise((resolve, reject) => {
  const xhr = new XMLHttpRequest();

  xhr.open('GET', url);
  xhr.onreadystatechange = () => {
    const DONE = 4; // readyState 4 means the request is done.
    const OK = 200; // status 200 is a successful return.
    if (xhr.readyState === DONE) {
      if (xhr.status === OK) {
        resolve(JSON.parse(xhr.response));
      } else {
        reject(xhr);
      }
    }
  };

  xhr.send(null);
});

export default createXHRPromise;
