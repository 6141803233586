import { first, split, compact } from 'lodash';

export default function getContext(path = '') {
  const context = first(
    compact(
      split(path, '/'),
    ),
  );

  if (context === 'assistance-requests') {
    const reg = /^\/?assistance-requests\/(.*)\/(contacts|referrals)\/(.*)/;
    const matches = path.match(reg);

    const secondaryContext = matches[2];
    return `${context}/${secondaryContext}`;
  }

  if (context === 'screenings') {
    const reg = /^\/?screenings\/(.*)\/referrals\/new\/(.*)/;
    const screeningToReferral = reg.test(path);

    return `${context}${screeningToReferral ? '/referral' : ''}`;
  }

  return context;
}
