import { useState, useCallback, useEffect } from 'react';
import { AUTHORIZATION_STATUSES_TEXT, AUTHORIZATION_STATUSES } from 'src/common/Status/authorizationConstants';
import { useFind } from 'src/api/APIHooks';
import { get, map, startCase, debounce } from 'lodash';

const useFeeScheduleProgramOptions = (payerId, programName) => {
  const [contractedProgramNameSearch, setContractedProgramNameSearch] = useState(undefined);

  const debounceSetContractedProgramName = useCallback(debounce(setContractedProgramNameSearch, 1500), []);
  useEffect(() => {
    debounceSetContractedProgramName(programName);
  }, [programName]);

  const { isLoading, data } = useFind(
    'fee_schedule_programs',
    {
      'fee_schedule.payers': payerId,
      name: contractedProgramNameSearch,
    },
    {
      queryConfig: {
        enabled: !!payerId,
        placeholderData: undefined,
      },
    },
  );

  const groupByFSPName = get(data, 'data.data');
  const feeScheduleProgramOptions = map(groupByFSPName, (val) => ({
    label: get(val, 'name'),
    value: get(val, 'id'),
  }));

  return isLoading ? [] : feeScheduleProgramOptions;
};

const useServiceAuthorizationFilters = ({ payerId, authorizationStatus }) => {
  const [requestId, setRequestId] = useState(undefined);
  const [clientName, setClientName] = useState(undefined);
  const [statusValue, setStatus] = useState(undefined);
  const [contractedProgram, setContractedProgram] = useState(undefined);
  const [contractedProgramName, setContractedProgramName] = useState(undefined);

  const feeScheduleProgramOptions = useFeeScheduleProgramOptions(payerId, contractedProgramName);

  let statusOptions = [
    {
      label: startCase(AUTHORIZATION_STATUSES_TEXT.accepted),
      value: AUTHORIZATION_STATUSES.accepted,
    },
    {
      label: startCase(AUTHORIZATION_STATUSES_TEXT.autoAccepted),
      value: AUTHORIZATION_STATUSES.autoAccepted,
    },
  ];

  if (authorizationStatus !== AUTHORIZATION_STATUSES.accepted) {
    statusOptions = statusOptions.concat([{
      label: startCase(AUTHORIZATION_STATUSES_TEXT.open),
      value: AUTHORIZATION_STATUSES.open,
    },
    {
      label: startCase(AUTHORIZATION_STATUSES_TEXT.rejected),
      value: AUTHORIZATION_STATUSES.rejected,
    }]);
  }

  const resetFilters = () => {
    setRequestId(undefined);
    setClientName(undefined);
    setStatus(undefined);
    setContractedProgram(undefined);
    setContractedProgramName(undefined);
  };

  const isFiltered = !!requestId || !!clientName || !!statusValue || !!contractedProgram;

  return ({
    filterValues: {
      requestId,
      clientName,
      status: statusValue,
      contractedProgram,
      contractedProgramName,
    },
    filterOptions: {
      status: statusOptions,
      contractedProgram: feeScheduleProgramOptions,
    },
    resetFilters,
    isFiltered,
    setRequestIdFilter: setRequestId,
    setClientNameFilter: setClientName,
    setStatusFilter: setStatus,
    setContractedProgramFilter: setContractedProgram,
    setContractedProgramName,
  });
};

export default useServiceAuthorizationFilters;
