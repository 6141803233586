import { endsWith } from 'lodash';

export default function appendReferralIdToLocation(baseLocation, params) {
  const location = { pathname: baseLocation };
  if (!endsWith(baseLocation, params.id)) {
    location.pathname = `${baseLocation}/${params.id}`;
  }

  return location;
}
