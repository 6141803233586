import React from 'react';
import PropTypes from 'prop-types';

const SearchTableSingleRow = ({ rowContent }) => (
  <caption
    className="search__single-row"
  >
    {rowContent}
  </caption>
);

SearchTableSingleRow.propTypes = {
  rowContent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};

export default SearchTableSingleRow;
