import Notifier from 'common/helpers/Notifier';
import fetchMyProviders from 'src/actions/Login/fetchMyProviders';
import acceptProviderAgreement from 'src/api/core/Agreements/acceptAgreement';
import setCurrentProvider from 'src/components/App/actions/setCurrentProvider';
import openProvider from 'src/actions/Agreement/openProvider';

const onError = (dispatch, error) => {
  Notifier.handleErrors(error);
  return error;
};

export default function acceptAgreement(provider, employeeId) {
  return async (dispatch, getState) => {
    try {
      const response = await acceptProviderAgreement(provider, employeeId)(dispatch);
      const { globalState: { employees } } = getState();
      await fetchMyProviders({ employees })(dispatch);
      setCurrentProvider(provider)(dispatch, getState);
      openProvider(provider)(dispatch, getState);
      return response;
    } catch (error) {
      return onError(dispatch, error);
    }
  };
}
