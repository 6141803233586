const TWO_OR_MORE_FORWARD_SLASHES = /(\/{2,})/g;

function appendPath(basePath, pathToAppend) {
  return !pathToAppend ?
    basePath :
    `${basePath}/${pathToAppend}`
      .replace(TWO_OR_MORE_FORWARD_SLASHES, '/');
}

export default appendPath;
