import { every, get } from 'lodash';

function isProviderToProviderReferral({ groupId, isCC, referral } = {}) {
  const referredByGroupId = get(referral, 'referred_by_group.id');
  const referredToGroupId = get(referral, 'referred_to_group.id');

  return every([
    isCC,
    groupId,
    referredByGroupId,
    referredToGroupId,
    referredByGroupId !== groupId,
    referredToGroupId !== groupId,
  ]);
}

export default isProviderToProviderReferral;
