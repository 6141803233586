const sortByRelevance = (options, searchTerm) => {
  const searchTerms = searchTerm.toLowerCase().split(/\s+/);
  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  return options.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    if (nameA === lowerCaseSearchTerm) return -1;
    if (nameB === lowerCaseSearchTerm) return 1;

    const startsWithSearchTermA = nameA.startsWith(lowerCaseSearchTerm);
    const startsWithSearchTermB = nameB.startsWith(lowerCaseSearchTerm);
    if (startsWithSearchTermA && !startsWithSearchTermB) return -1;
    if (startsWithSearchTermB && !startsWithSearchTermA) return 1;

    const startsWithA = searchTerms.some((term) => nameA.startsWith(term));
    const startsWithB = searchTerms.some((term) => nameB.startsWith(term));
    if (startsWithA && !startsWithB) return -1;
    if (startsWithB && !startsWithA) return 1;

    const containsA = searchTerms.some((term) => nameA.includes(term));
    const containsB = searchTerms.some((term) => nameB.includes(term));
    if (containsA && !containsB) return -1;
    if (containsB && !containsA) return 1;

    const firstMatchIndexA = Math.min(...searchTerms.map((term) => nameA.indexOf(term))
      .filter((index) => index !== -1));
    const firstMatchIndexB = Math.min(...searchTerms.map((term) => nameB.indexOf(term))
      .filter((index) => index !== -1));
    if (firstMatchIndexA !== firstMatchIndexB) return firstMatchIndexA - firstMatchIndexB;

    return nameA.localeCompare(nameB);
  });
};

const getChildrenArray = (array) => {
  const childrenArray = [];
  array.forEach((item) => {
    if (item.children) {
      childrenArray.push(...item.children);
    }
  });
  return childrenArray;
};

export { sortByRelevance, getChildrenArray };
