import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { BaseCard, BaseCardBody, Button } from '@unite-us/ui';
import { Spinner } from 'common/spinners';
import { connect } from 'react-redux';
import { browserHistory } from 'common/utils/browserHistory';
import Notifier from 'common/helpers/Notifier';
import enforcedConsentRoute from 'common/utils/Navigation/enforcedConsentRoute';
import { DOMAIN_CONSTANTS } from 'src/common/constants';
import findCurrentGroup from 'common/utils/findCurrentGroup';
import SurveyJSFormRenderer from '@unite-us/surveyjs/dist/components/Renderer/SurveyJSFormRenderer';
import buildResponses from '@unite-us/surveyjs/dist/components/Renderer/buildResponses';
import { useCreateRecord, useInvalidateQueries } from 'src/api/APIHooks';
import ScreeningFormSelect from 'src/components/Screenings/ScreeningForm/components/ScreeningFormSelect';
import useAvailableForms from 'src/components/FormSubmissions/useAvailableForms';
import { getContactFromState } from 'src/components/Contacts/utils';
import './ScreeningNewFormWrapper.scss';

export function ScreeningNewFormWrapper({
  groupId,
  contact,
  contactId,
  currentEmployee,
}) {
  const surveyRef = useRef();
  const [form, setForm] = useState({});
  const [submission, setSubmission] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [surveyJSON, setSurveyJSON] = useState({});

  const { forms, isFetching } = useAvailableForms({
    providerId: groupId,
    usageTypes: 'screening',
    relevantServices: null,
  });

  const invalidateQueries = useInvalidateQueries();

  if (!contactId) {
    browserHistory.push('/screenings/new/search');
  }

  const onFormSelect = (formData) => {
    setForm(formData);
  };

  useEffect(() => {
    setForm((formData) => formData);
    setSurveyJSON(!isEmpty(form) ? {
      configuration: {
        title: form.name,
        description: form.description,
        ...form.configuration,
      },
    } : {});
    setSubmission({}); // necessary to trigger useEffect rerender in SurveyJSFormRenderer
  }, [form]);

  useEffect(() => {
    invalidateQueries('form');
  }, []);

  const handleSetSurvey = (survey) => {
    surveyRef.current = survey;
  };

  const { createRecord: createFormSubmission } = useCreateRecord('form_submission', {
    api: 'coreApi',
  });

  const submit = async () => {
    const survey = surveyRef.current;
    if (survey.completeLastPage() && !survey.hasErrors()) {
      const responses = buildResponses(surveyRef.current);
      setIsSaving(true);
      await createFormSubmission({
        responses,
        form: form.id,
        provider: groupId,
        contextType: 'person',
        context: contactId,
        submitter: currentEmployee.id,
      }, {
        mutationConfig: {
          onSuccess: () => {
            invalidateQueries('form_submission');
          },
        },
      }).then((response) => {
        const formSubmission = get(response, 'data.data', {});
        if (formSubmission.id) {
          setIsSaving(false);
          Notifier.dispatch(response.status, 'Screening Successfully Created');

          const screeningPath = `/screenings/all/${formSubmission.id}`;
          enforcedConsentRoute({
            contact,
            itemType: DOMAIN_CONSTANTS.SCREENING,
            to: screeningPath,
          });
        }
      });
      setIsSaving(false);
    }
  };

  if ((isEmpty(forms) && !isEmpty(contact)) && !isFetching) {
    return (
      <div className="no-screening-forms-warning">
        <h3>There are no current screening forms available to choose from.</h3>
      </div>
    );
  }

  if (isEmpty(forms) || !contact) {
    return (
      <div style={{ padding: '44px 0px' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="screening-new-form container">
      <BaseCard>
        <BaseCardBody withPadding>
          <ScreeningFormSelect
            forms={forms}
            onFormSelect={onFormSelect}
          />
          <div>
            {(!isFetching && !isSaving) && (
              <SurveyJSFormRenderer
                formData={surveyJSON}
                formSubmission={submission}
                editMode
                handleSetSurveyModel={handleSetSurvey}
              />
            )}
            <div className="screening-new-form__actions text-right">
              <Button
                disabled={isSaving}
                label="Submit Screening"
                onClick={submit}
                primary
              />
            </div>
          </div>
          {
            isFetching && (
              <div style={{ padding: '44px 0px' }}>
                <Spinner />
              </div>
            )
          }
        </BaseCardBody>
      </BaseCard>
    </div>
  );
}

ScreeningNewFormWrapper.propTypes = {
  contact: PropTypes.object,
  contactId: PropTypes.string,
  currentEmployee: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
};

ScreeningNewFormWrapper.defaultProps = {
  contact: {},
  contactId: '',
};

function mapStateToProps(state) {
  const group = findCurrentGroup(state.user, state.session);
  const groupId = get(group, 'group.id', '');
  const selectedContactId = state.selectedContact;

  return {
    contact: getContactFromState(state, selectedContactId),
    contactId: selectedContactId,
    currentEmployee: state.globalState.currentEmployee,
    groupId,
  };
}

export default connect(mapStateToProps)(ScreeningNewFormWrapper);
