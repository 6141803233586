import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Card from '../../../../../common/Card/Card';

const DISPLAY_NAME = 'relationships-list-empty';

const RelationshipsListEmpty = ({ text }) => (
  <Card
    borderColor="border-dark-border-blue"
    className={classNames(DISPLAY_NAME, 'p-6 bg-light-fill-blue text-center text-sm')}
  >
    {text}
  </Card>
  );

RelationshipsListEmpty.defaultProps = {
  text: '',
};

RelationshipsListEmpty.propTypes = {
  text: PropTypes.string,
};

export default RelationshipsListEmpty;
