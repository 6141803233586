import { coreApi } from 'src/api/config';
import { get } from 'lodash';

export const updateClientRelationship = async(clientRelationship, checked) => {
  const crResponse = await coreApi.updateRecord('client_relationship', clientRelationship.id, {
    flagged: checked,
  });

  const relationship = get(crResponse, 'data.data', {});
  return relationship;
};
