/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import {
  useInvalidateQueries,
} from 'src/api/APIHooks';
import { useTemplateV2 } from 'src/pages/facesheet/_id/eligibility/hooks/eligibilityApi';
import Notifier from 'common/helpers/Notifier';
import { browserHistory } from 'common/utils/browserHistory';
import SurveyJSFormRenderer from '@unite-us/surveyjs/dist/components/Renderer/SurveyJSFormRenderer';
import buildResponses from '@unite-us/surveyjs/dist/components/Renderer/buildResponses';
import {
  BaseCard,
  BaseCardBody,
} from '@unite-us/ui';
import * as env from 'src/config/env/env.config';
import { screeningsApiClient } from 'src/api/config';
import {
  useElAssessment,
  EL_ASSESSMENT_QUERY_KEYS
} from 'src/pages/facesheet/_id/eligibility/hooks/useEligibilityAssessments';
import {
  getIdentifiedNeedsHTML,
  formatDateToScreeningFormat,
  ELIGIBILITY_ASSESSMENT_STATUS,
  // buildAnswersPayload,
} from 'src/pages/facesheet/_id/eligibility/utils';
import NotEligible from 'src/pages/facesheet/_id/eligibility/components/NotEligible';
import './EligibilityAssessment.scss';
import EligibilityAssessmentJSON from 'src/pages/facesheet/_id/eligibility/surveyjsTemplates/EligibilityAssessmentJSON';
import {
  updateAssessment,
  createNewAssessment,
  useElAssessmentTemplateId,
} from 'src/pages/facesheet/_id/eligibility/hooks/eligibilityApi';

const SURVEY_PAGE_NUMBER = 0; // set to 0 for page1, 1 for page2, etc.

export const buildAnswersPayload = (surveyRef, surveyJSON) => {
  const survey = surveyRef.current;

  const responses = buildResponses(surveyRef.current);
  const answers = responses
    .map((response) => {
      let option_id = null;
      const elementName = response.name;
      if (['qDateAttempt1', 'qDateAttempt2', 'qDateAttempt3'].includes(elementName)) {
        return {
          question_id: response.id,
          value: response.value ? moment(response.value).format('YYYY-MM-DDTHH:mm:ss[Z]') : null,
        };
      }

      const optionsSelected = response.custom.map((c) => c.option.uuid || c.option.id);
      const questionType = get(response, 'custom[0].question.type', '');
      option_id = optionsSelected;
      if (response.value) {
        option_id = response.value;
      } else if (questionType === 'select_multiple') {
        option_id = Array.isArray(optionsSelected) ? optionsSelected : [optionsSelected];
      } else if (optionsSelected.length > 1) {
        option_id = optionsSelected;
      } else {
        option_id = optionsSelected[0];
      }
      return {
        question_id: response.id,
        option_id: response.valueId[0],
      }
    });

  return answers;
};

const getOutreachValues = (surveyRef) => {
  const ASSESSED_ANSWER = 'Success';
  const DECLINED_ANSWER = 'No response';
  const WRONG_INFO = 'Wrong info';
  const survey = surveyRef.current.pages[0];
  const qDateAttempt1Value = survey.getQuestionByName('qDateAttempt1').value;
  const qDateAttempt2Value = survey.getQuestionByName('qDateAttempt2').value;
  const qDateAttempt3Value = survey.getQuestionByName('qDateAttempt3').value;

  const qOutcomeAttempt1Value = survey.getQuestionByName('qOutcomeAttempt1').value;
  const qModalityAttempt2Value = survey.getQuestionByName('qModalityAttempt2').value;
  const qModalityAttempt3Value = survey.getQuestionByName('qModalityAttempt3').value;

  const qModalityAttempt1Value = survey.getQuestionByName('qModalityAttempt1').value;
  const qOutcomeAttempt2Value = survey.getQuestionByName('qOutcomeAttempt2').value;
  const qOutcomeAttempt3Value = survey.getQuestionByName('qOutcomeAttempt3').value;

  const patientHasAssessed = (
    qDateAttempt1Value && qModalityAttempt1Value && qOutcomeAttempt1Value === ASSESSED_ANSWER
  ) || (
    qDateAttempt2Value && qModalityAttempt2Value && qOutcomeAttempt2Value === ASSESSED_ANSWER
  ) || (
    qDateAttempt3Value && qModalityAttempt3Value && qOutcomeAttempt3Value === ASSESSED_ANSWER
  );
  const patientHasDeclined = (
    qDateAttempt1Value && qModalityAttempt1Value &&
      (qOutcomeAttempt1Value === DECLINED_ANSWER || qOutcomeAttempt1Value === WRONG_INFO)
  ) && (
    qDateAttempt2Value && qModalityAttempt2Value &&
      (qOutcomeAttempt2Value === DECLINED_ANSWER || qOutcomeAttempt2Value === WRONG_INFO)
  ) && (
    qDateAttempt3Value && qModalityAttempt3Value &&
    (qOutcomeAttempt3Value === DECLINED_ANSWER || qOutcomeAttempt3Value === WRONG_INFO)
  );

  return {
    patientHasAssessed: Boolean(patientHasAssessed),
    patientHasDeclined: Boolean(patientHasDeclined),
  }
};

const populateQuestion = (surveyRef, screenQuestions, questionName, questionType) => {
  const survey = surveyRef.current;
  const surveyPage = surveyRef.current.pages[0];
  let element = surveyPage.getQuestionByName(questionName);
  let screenQuestion;
  let elementAnswer;
  if (questionType === 'dropdown') {
    screenQuestion = screenQuestions.find(
      (q) => q.primary_text.toLowerCase() === element.title.toLowerCase()
    );
    if (get(screenQuestion, 'answer.value')) {
      element.readOnly = true;
      element.value = screenQuestion.answer.value;
      elementAnswer = element.value;
    }

    element.visible = true;
  }

  if (questionType === 'dropdown-multiselect') {
    screenQuestion = screenQuestions.find(
      (q) => q.primary_text.toLowerCase() === element.title.toLowerCase()
    );
    if (get(screenQuestion, 'answers', []).length > 0) {
      const selectedValues = screenQuestion.answers.map((a) => a.value);
      element.readOnly = true;
      elementAnswer = selectedValues;
      element.value = selectedValues;
    }
    element.visible = true;
  }

  if (questionType === 'date') {
    screenQuestion = screenQuestions.find(
      (q) => q.primary_text.toLowerCase() === element.title.toLowerCase()
    );
    if (get(screenQuestion, 'answer.value')) {
      element.readOnly = true;
      element.value = moment(screenQuestion.answer.value).format('YYYY-MM-DD');
      elementAnswer = element.value;
      // element.visible = false;
    }
    element.visible = true;
  }

  return {
    element,
    screenQuestion,
    elementAnswer,
  };
};

const populateForm = (surveyRef, assessment) => {
  const screenQuestions = get(assessment, 'data.screen.questions', []);
  populateQuestion(surveyRef, screenQuestions, 'qDateAttempt1', 'date');
  populateQuestion(surveyRef, screenQuestions, 'qModalityAttempt1', 'dropdown');
  populateQuestion(surveyRef, screenQuestions, 'qOutcomeAttempt1', 'dropdown');
  populateQuestion(surveyRef, screenQuestions, 'qDateAttempt2', 'date');
  populateQuestion(surveyRef, screenQuestions, 'qModalityAttempt2', 'dropdown');
  populateQuestion(surveyRef, screenQuestions, 'qOutcomeAttempt2', 'dropdown');
  populateQuestion(surveyRef, screenQuestions, 'qDateAttempt3', 'date');
  populateQuestion(surveyRef, screenQuestions, 'qModalityAttempt3', 'dropdown');
  populateQuestion(surveyRef, screenQuestions, 'qOutcomeAttempt3', 'dropdown');
};

const EligibilityOutreach = (props) => {
  const {
    employeeId,
    personId,
    setLoading,
  } = props;
  const [outreachesVisible, setOutreachesVisible] = useState(1);
  const templateId = useElAssessmentTemplateId();
  const paramAssessmentId = props.screenId;
  const inEditMode = Boolean(paramAssessmentId);
  const { data: assessment } = useElAssessment(
    paramAssessmentId,
    { enabled: Boolean(paramAssessmentId) }
  );
  const [refReady, setRefReady] = useState(null);
  const invalidateQueries = useInvalidateQueries();

  const { data: templateData } = useTemplateV2({ templateId });
  const formJSON = get(templateData, 'surveyjsTemplate');
  
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isOutreachButtonDisabled, setIsOutreachButtonDisabled] = useState(true);

  const surveyRef = useRef(null);
  const handleSetSurvey = (survey) => {
    surveyRef.current = survey;
    surveyRef.current.currentPageNo = 0;
    setRefReady(surveyRef);
  };

  const invalidateCommonQueries = () => {
    invalidateQueries(EL_ASSESSMENT_QUERY_KEYS.ASSESSMENTS);
    invalidateQueries(EL_ASSESSMENT_QUERY_KEYS.ASSESSMENT);
  };

  const saveAsDraft = async () => {
    setLoading(true);
    const answers = buildAnswersPayload(surveyRef);

    if (inEditMode) {
      const response = await updateAssessment({
        personId,
        employeeId,
        status: 'draft',
        answers,
        related_screen_id: get(assessment, 'data.screen.related_screen_id', null),
        outreach_status: null,
        templateId,
        assessmentId: paramAssessmentId,
      });

      Notifier.dispatch(200, 'Draft saved');
      invalidateCommonQueries();
      setLoading(false);
      return;
    }
    const response = await createNewAssessment({
      personId,
      employeeId,
      status: 'draft',
      answers,
      related_screen_id: null,
      outreach_status: null,
      templateId,
    });

    const newAssessmentId = get(response, 'data.screen.id');
    if (!newAssessmentId) {
      setLoading(false);
      return Notifier.dispatch(400, 'Failed to create new assessment');
    }
    Notifier.dispatch(200, 'Draft saved');
    invalidateCommonQueries();
    browserHistory.push(`/facesheet/${personId}/eligibility`);
    setLoading(false);
  };

  const failOutreach = async () => {
    setLoading(true);
    const answers = buildAnswersPayload(surveyRef);
    if (inEditMode) {
      invalidateQueries(EL_ASSESSMENT_QUERY_KEYS.ASSESSMENTS);
      const response = await updateAssessment({
        personId,
        employeeId,
        status: 'draft',
        answers,
        related_screen_id: null,
        outreach_status: 'declined',
        templateId,
        assessmentId: paramAssessmentId,
      });
      invalidateCommonQueries();
      setLoading(false);
      return browserHistory.push(`/facesheet/${personId}/eligibility/all`);
    }

    const response = await createNewAssessment({
      personId,
      employeeId,
      status: 'draft',
      answers,
      related_screen_id: null,
      outreach_status: 'declined',
      templateId,
    });
    invalidateCommonQueries();
    browserHistory.push(`/facesheet/${personId}/eligibility/all`);
    setLoading(false);
  };

  const submit = async () => {
    setLoading(true);

    const goToNextStep = (assessId) => {
      browserHistory.push(`/facesheet/${personId}/eligibility/new/${assessId}/consent`);
    };

    const hasOutreachSuccess = get(assessment, 'data.screen.outreach_status') === 'success';
    if (hasOutreachSuccess) {
      goToNextStep(paramAssessmentId);
      return setLoading(false);
    }

    const answers = buildAnswersPayload(surveyRef);
    if (inEditMode) {
      const response = await updateAssessment({
        personId,
        employeeId,
        status: 'draft',
        answers,
        related_screen_id: get(assessment, 'data.screen.related_screen_id', null),
        outreach_status: 'success',
        templateId,
        assessmentId: paramAssessmentId,
      });
      invalidateCommonQueries();
      goToNextStep(paramAssessmentId);
      return setLoading(false);
    }

    const response = await createNewAssessment({
      personId,
      employeeId,
      status: 'draft',
      answers,
      related_screen_id: null,
      outreach_status: 'success',
      templateId,
    });

    invalidateCommonQueries();
    const newAssessmentId = get(response, 'data.screen.id');
    setLoading(false);
    if (!newAssessmentId) {
      return Notifier.dispatch(400, 'Failed to create new assessment');
    }
    goToNextStep(newAssessmentId);
  };

  const addOutreachAttempt = () => {
    const survey = surveyRef.current.pages[SURVEY_PAGE_NUMBER];
    if (outreachesVisible === 1) {
      const qDateAttempt2 = survey.getQuestionByName('qDateAttempt2');
      const qModalityAttempt2 = survey.getQuestionByName('qModalityAttempt2');
      const qOutcomeAttempt2 = survey.getQuestionByName('qOutcomeAttempt2');
      qDateAttempt2.visible = true;
      qModalityAttempt2.visible = true;
      qOutcomeAttempt2.visible = true;
      return setOutreachesVisible(2);
    }

    if (outreachesVisible === 2) {
      const qDateAttempt3 = survey.getQuestionByName('qDateAttempt3');
      const qModalityAttempt3 = survey.getQuestionByName('qModalityAttempt3');
      const qOutcomeAttempt3 = survey.getQuestionByName('qOutcomeAttempt3');
      qDateAttempt3.visible = true;
      qModalityAttempt3.visible = true;
      qOutcomeAttempt3.visible = true;
      return setOutreachesVisible(3);
    }
  }

  const evaluateFormVisibilities = () => {
    const survey = surveyRef.current.pages[0];
    const { patientHasAssessed, patientHasDeclined } = getOutreachValues(surveyRef);
    setIsSubmitDisabled(!patientHasAssessed);
    setIsOutreachButtonDisabled(!patientHasDeclined);
  };

  const onValueChanged = (sender, options) => {
    evaluateFormVisibilities();
  }

  useEffect(() => {
    if (!inEditMode) { return; }
  }, [inEditMode]);

  useEffect(() => {
    if (!assessment || !surveyRef?.current) { return; }
    populateForm(surveyRef, assessment);
  }, [assessment, refReady]);

  if (!formJSON) { return null; }

  return (
    <div className="eligibility-assessment">
      <BaseCard className="bg-white">
        <div
          className='flex items-center justify-between border border-solid border-medium-border-blue border-l-0 border-r-0 border-t-0'
        >
          <div className="px-5 py-8 inline-block">
            <h2 className="text-2xl font-bold mb-2">Eligibility Assessment</h2>
            <p className="text-md">This form is to determine eligibility for HRSN services, if the client is not eligible, you can proceed to send referrals for regular client services.</p>
          </div>

          {
            !inEditMode && outreachesVisible < 3 ? (
              <div className="inline-block text-right mr-6">
                <button
                  onClick={addOutreachAttempt}
                  className={`${outreachesVisible === 3 ? 'bg-grey-disabled' : 'bg-white text-black'} h-12 px-16 py-3 border border-solid border-medium-border-blue rounded`}
                >
                  Add Outreach
                </button>
              </div>
            ) : null
          }
        </div>

        <div className="px-6">
          <BaseCardBody>
            <SurveyJSFormRenderer
              formData={formJSON}
              formSubmission={null}
              editMode={true}
              handleSetSurveyModel={handleSetSurvey}
              onValueChanged={onValueChanged}
            />

            {/* {
              showNotEligible ? (
                <NotEligible />
              ) : null
            } */}

            {/* {
              eligibleForServices && eligibleForServices.length ? (
                <div>
                  <h4 className="text-lg font-bold mt-8 mb-4">Client is eligible for services:</h4>
                  {
                    eligibleForServices.map((service, index) => (
                      <p className="ml-4">{ service }</p>
                    ))
                  }
                </div>
              ) : null
            } */}

            <div
              className="flex justify-between items-center mb-6 mt-3"
            >
              <button
                onClick={saveAsDraft}
                className="h-12 mr-4 px-16 py-3 border border-solid border-light-border-blue rounded text-action-darker-blue">
                <span className="inline-block align-middle">Save as Draft</span>
              </button>

              <div className="flex space-x-4">
                <button
                  onClick={failOutreach}
                  disabled={isOutreachButtonDisabled}
                  className={`${isOutreachButtonDisabled ? 'bg-grey-disabled' : 'bg-red'} h-12 px-16 py-3 border border-solid border-medium-border-blue rounded`}
                >
                  {/* <span className="inline-block align-middle mr-2">
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.9643 3.5L13 0.535714C12.7143 0.25 12.1786 0 11.7857 0H2.21429C1.25 0 0.5 0.785714 0.5 1.71429V14.2857C0.5 15.25 1.25 16 2.21429 16H14.7857C15.7143 16 16.5 15.25 16.5 14.2857V4.71429C16.5 4.32143 16.25 3.78571 15.9643 3.5ZM8.5 13.7143C7.21429 13.7143 6.21429 12.7143 6.21429 11.4286C6.21429 10.1786 7.21429 9.14286 8.5 9.14286C9.75 9.14286 10.7857 10.1786 10.7857 11.4286C10.7857 12.7143 9.75 13.7143 8.5 13.7143ZM11.9286 2.85714V6.42857C11.9286 6.67857 11.7143 6.85714 11.5 6.85714H3.21429C2.96429 6.85714 2.78571 6.67857 2.78571 6.42857V2.71429C2.78571 2.5 2.96429 2.28571 3.21429 2.28571H11.3571C11.4643 2.28571 11.5714 2.35714 11.6786 2.42857L11.7857 2.53571C11.8571 2.60714 11.9286 2.75 11.9286 2.85714Z" fill="#4467AB"/>
                    </svg>
                  </span> */}
                  <span className="inline-block align-middle text-white">Outreach Failed (3 Attempts)</span>
                </button>
                
                <button
                  onClick={submit}
                  disabled={isSubmitDisabled}
                  className={`${isSubmitDisabled ? 'bg-grey-disabled' : 'bg-action-blue'} h-12 px-16 py-3 rounded text-white`}
                >
                  <span className="inline-block align-middle text-white">
                    Continue
                  </span>
                </button>
              </div>

            </div>
          </BaseCardBody>
        </div>
      </BaseCard>
      <div style={{ display: "none" }}></div>
    </div>
  );
};

export default EligibilityOutreach;
