import {
  SEARCH_CONTACT,
  CLEAR_SEARCHED_CONTACTS,
  RETRIEVE_STORED_SEARCHED_CONTACTS,
  REPLACE_SEARCHED_CONTACT,
} from 'actions';
import _ from 'lodash';

export const defaultState = [];

export default function searchContactReducer(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_SEARCHED_CONTACTS: {
      return [];
    }

    case SEARCH_CONTACT: {
      return action
        .payload
        .data
        .data
        .map((contact) => contact.item.result);
    }

    case REPLACE_SEARCHED_CONTACT: {
      return state.map((contact) => {
        if (contact.id === action.currentContactId) {
          return _.get(action, 'payload.data.data');
        }

        return contact;
      });
    }

    case RETRIEVE_STORED_SEARCHED_CONTACTS: {
      return action.contacts;
    }

    default:
      return state;
  }
}
