import {
  useFind,
  useFindRecord,
  useUpdateRecord,
  useInvalidateQueries,
} from 'src/api/APIHooks';
import Notifier from 'common/helpers/Notifier';
import { defaultQueryConfig, defaultMutationConfig } from './apiHookOptions';

export const useFindProviderRequired = ({ providerId }) => useFindRecord('provider', providerId, {
  queryConfig: { ...defaultQueryConfig, enabled: !!providerId },
});

export const useFindProvider = ({ providerId }) => useFindRecord('provider', providerId, {
  queryConfig: { ...defaultQueryConfig },
});

export const useFindLicensedProvidersByName = (providerName, params = { queryConfig: defaultQueryConfig }) => useFind(
  'providers',
  {
    name: providerName,
    licensed: true,
  },
  {
    queryConfig: {
      enabled: !!providerName,
    },
    ...params,
  },
);

export const useUpdateProvider = (mutationConfig, successMessage, time) => {
  const invalidateQueries = useInvalidateQueries();
  return useUpdateRecord(
    'provider',
    {
      mutationConfig: {
        onSuccess: () => {
          Notifier.dispatch('success', successMessage || 'Organization Successfully Updated', time);
          invalidateQueries('provider');
        },
        ...defaultMutationConfig,
        ...mutationConfig,
      },
    },
  );
};

//   Notifier.dispatch(
//       'success',
//       'Organization submitted. Our team will review your submission and send an email with next steps.',
//   );
