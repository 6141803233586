import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get, find } from 'lodash';
import { useFindRecord, useFind } from 'api/APIHooks';
import { useFeatureFlag } from 'common/hooks';
import { browserHistory } from 'common/utils/browserHistory';
import FeatureFlagContainer from 'common/utils/FeatureFlags/FeatureFlagContainer';
import { Card } from 'common/Card';
import { Spinner } from 'common/spinners';
import { SecondaryButton } from 'common/TailwindComponents';
import {
  EnrollmentStepperWrapper,
  ClientDetails,
  EnrollmentApplication,
  EnrollmentReview,
  EnrollmentConfirmation,
} from './components';
import { useEnrollmentSteps } from './hooks/useEnrollmentSteps';

export const FacesheetEnrollmentFormsContainer = (props) => {
  const {
    contact,
    contactId,
    groupId,
    currentEmployee,
  } = props;
  const hasEnrollmentWQ = useFeatureFlag('enrollment-wq');
  const [formResponse, setFormResponse] = useState([]);
  const [attachedDocuments, setAttachedDocuments] = useState([]);

  const {
    step,
    goToStep,
    stepForward,
    stepBackward,
  } = useEnrollmentSteps();

  useEffect(() => {
    if (!hasEnrollmentWQ) {
      browserHistory.push('/');
    }
  }, [browserHistory, hasEnrollmentWQ]);

  const planId = get(props, 'location.query.planId');
  const { isLoading, isFetching, data } = useFindRecord(
    'plan',
    planId,
    { queryConfig: { enabled: !!planId, refetchOnWindowFocus: false } },
  );

  const formsQuery = useFind('form', {
    'form_usages.form_utilizer.type': 'plan',
    'form_usages.form_utilizer': planId,
  }, {
    api: 'coreApi',
    queryConfig: { refetchOnWindowFocus: false },
  });

  const plan = get(data, 'data.data', {});
  const planName = get(plan, 'name', '');
  const personId = get(props, 'params.id');
  const form = get(formsQuery, 'data.data.data[0]', {});

  const handleFormResponseChange = useCallback((responses) => {
    setFormResponse(responses);
  }, [setFormResponse]);

  const handleAttachDocuments = useCallback((documents) => {
    setAttachedDocuments(documents);
  }, [setAttachedDocuments]);

  const onBackToFaceSheet = useCallback(() => {
    browserHistory.push(`/facesheet/${personId}/profile`);
  }, [browserHistory, personId]);

  return (
    <>
      <SecondaryButton
        className="px-4 py-2 bg-white font-regular-font flex items-center text-15px mb-4"
        icon="IconChevronLeft"
        label="Back to Client Face Sheet"
        onClick={onBackToFaceSheet}
      />
      {(isLoading || isFetching) && <Spinner />}
      {!(isLoading || isFetching) && (
        <Card className="divide-y divide-solid divide-dark-fill-blue">
          <div className="bg-white px-6 py-4">
            <h2 className="text-text-blue ">Apply for {planName} Enrollment</h2>
          </div>
          <div className="bg-white">
            { step < 3 && (
            <div className="p-4">
              <EnrollmentStepperWrapper
                step={step}
                goToStep={goToStep}
              />
            </div>
            )}
            {
              (() => {
                switch (step) {
                  case 0:
                    return (
                      <ClientDetails
                        plan={plan}
                        stepForward={stepForward}
                        contact={contact}
                        contactId={contactId}
                      />
                    );
                  case 1:
                    return (
                      <EnrollmentApplication
                        plan={plan}
                        form={form}
                        formResponse={formResponse}
                        handleFormResponseChange={handleFormResponseChange}
                        handleAttachDocuments={handleAttachDocuments}
                        contactId={contactId}
                        stepBackward={stepBackward}
                        stepForward={stepForward}
                        groupId={groupId}
                        currentEmployee={currentEmployee}
                      />
                    );
                  case 2:
                    return (
                      <EnrollmentReview
                        plan={plan}
                        form={form}
                        formResponse={formResponse}
                        attachedDocuments={attachedDocuments}
                        contactId={contactId}
                        stepBackward={stepBackward}
                        stepForward={stepForward}
                        groupId={groupId}
                        currentEmployee={currentEmployee}
                      />
                    );
                  case 3:
                    return <EnrollmentConfirmation plan={plan} contactId={contactId} contact={contact} />;
                  default:
                    goToStep(0);
                    return null;
                }
              })()
            }
          </div>
        </Card>
      )}
    </>
  );
};

FacesheetEnrollmentFormsContainer.propTypes = {
  contact: PropTypes.object,
  contactId: PropTypes.string,
  location: PropTypes.shape({
    query: PropTypes.shape({
      planId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  currentEmployee: PropTypes.string.isRequired,
};

FacesheetEnrollmentFormsContainer.defaultProps = {
  contact: {},
  contactId: undefined,
};

const mapStateToProps = (state, ownProps) => {
  const contactId = get(ownProps, 'params.id');
  const contact = find(state.contacts.contacts, { id: contactId });
  const groupId = get(state, 'session.groupId', '');
  const currentEmployee = get(state, 'globalState.currentEmployee.id', '');

  return {
    contact,
    contactId,
    groupId,
    currentEmployee,
  };
};

export default connect(mapStateToProps)(FeatureFlagContainer(FacesheetEnrollmentFormsContainer));
