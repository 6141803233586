export const colorScheme = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#E9E9E9',
      },
      {
        lightness: '17',
      },
    ],
  }, {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#F5F5F5',
      },
      {
        lightness: '20',
      },
    ],
  }, {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      { color: '#FFFFFF' },
      { lightness: '17' },
    ],
  }, {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      { color: '#FFFFFF' },
      { lightness: '29' },
      { weight: '0.2' },
    ],
  }, {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [{ color: '#FFFFFF' }, { lightness: '18' }],
  }, {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      { color: '#FFFFFF' },
      { lightness: '16' },
    ],
  }, {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      { color: '#F5F5F5' },
      { lightness: '21' },
    ],
  }, {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      { color: '#DEDEDE' },
      { lightness: '21' },
    ],
  }, {
    elementType: 'labels.text.stroke',
    stylers: [
      { visibility: 'on' },
      { color: '#FFFFFF' },
      { lightness: '16' },
    ],
  }, {
    elementType: 'labels.text.fill',
    stylers: [
      { saturation: '-100' },
      { color: '0x515151' },
      { lightness: '30' },
    ],
  }, {
    elementType: 'labels.icon',
    stylers: [
      { visibility: 'off' },
    ],
  }, {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      { color: '#F2F2F2' },
      { lightness: '19' },
    ],
  }, {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      { color: '#FEFEFE' },
      { lightness: '20' },
    ],
  }, {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      { color: '#FEFEFE' },
      { lightness: '17' },
      { weight: '1.2' },
    ],
  }];

const getMapStyles = (styles) => {
  const result = [];
  styles.forEach((v) => {
    let style = '';
    if (v.stylers.length > 0) { // Needs to have a style rule to be valid.
      style += `${(Object.prototype.hasOwnProperty.call(v, 'featureType') ?
        `feature:${v.featureType}` : 'feature:all')}%7C`;
      style += `${(Object.prototype.hasOwnProperty.call(v, 'elementType') ?
        `element:${v.elementType}` : 'element:all')}%7C`;
      v.stylers.forEach((val) => {
        const propertyname = Object.keys(val)[0];
        const propertyval = val[propertyname].toString().replace('#', '0x');
        style += `${propertyname}:${propertyval}%7C`;
      });
    }

    result.push(`style=${style}`);
  });

  return result.join('&');
};

const mapScheme = getMapStyles(colorScheme);

export default mapScheme;
