import { isEqual } from 'lodash';

const createFormNeedConfigurationPayload = (serviceId, inputOptions, formId) => (
  {
    form: { id: formId },
    service: { id: serviceId },
    threshold: 0,
    input_option_scores: inputOptions,
  }
);

// creates, edits, and deletes configurations based on existing configurations
export const makeRequests = (
  formId,
  serviceInputPairing,
  formNeedConfigurations,
  createConfig,
  updateConfig,
  deleteConfig,
) => {
  const deleteFormNeedConfigurations =
    formNeedConfigurations.filter((config) => !serviceInputPairing[config.service.id]);
  const promises = [];
  for (const [serviceId, inputOptions] of Object.entries(serviceInputPairing)) {
    const existingConfiguration = formNeedConfigurations.find((config) => (config.service.id === serviceId));
    if (!existingConfiguration) {
      const formNeedConfiguration = createFormNeedConfigurationPayload(serviceId, inputOptions, formId);
      const promise = createConfig(formNeedConfiguration);
      promises.push(promise);
    } else if (!isEqual(
       existingConfiguration.input_option_scores.sort((a, b) => a.input_option_id.localeCompare(b.input_option_id)),
       inputOptions.sort((a, b) => a.input_option_id.localeCompare(b.input_option_id)),
      )) {
      existingConfiguration.input_option_scores = inputOptions;
      const promise = updateConfig(existingConfiguration.id, existingConfiguration);
      promises.push(promise);
    }
  }
  deleteFormNeedConfigurations.forEach((config) => {
    const promise = deleteConfig(config.id, {});
    promises.push(promise);
  });
  return promises;
};
