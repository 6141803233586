import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { defaultOnDownloadLogicAnswers, defaultOnUploadLogicAnswers } from './utils';
import EditLogic from './EditLogic';

export const useEditLogicComponent = (
  formResponse,
  services,
  formId,
  formName,
  formNeedConfigurations,
  createConfig,
  updateConfig,
  deleteConfig,
  invalidateQueries,
) => {
  const formConfig = isEmpty(formResponse) ? {} : formResponse.data.data.configuration;
  const EditLogicComponent = ({ surveyCreatorState, saveSurvey }) => (
    <EditLogic
      formConfig={formConfig}
      services={services}
      formId={formId}
      formName={formName}
      surveyCreatorState={surveyCreatorState}
      saveSurvey={saveSurvey}
      formNeedConfigurations={formNeedConfigurations}
      createConfig={createConfig}
      updateConfig={updateConfig}
      deleteConfig={deleteConfig}
      invalidateQueries={invalidateQueries}
      onDownloadLogicAnswers={defaultOnDownloadLogicAnswers}
      onUploadLogicAnswers={defaultOnUploadLogicAnswers}
    />
  );

  EditLogicComponent.propTypes = {
    surveyCreatorState: PropTypes.string.isRequired,
    saveSurvey: PropTypes.func.isRequired,
  };

  return [EditLogicComponent];
};
