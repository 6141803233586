import React from 'react';
import PropTypes from 'prop-types';
import { TableBody } from '@unite-us/ui';
import SearchTableRow from './SearchTableRow';

const SearchTableBody = ({ model, sectionResults }) => (
  <TableBody>
    {
      sectionResults.map((result, i) => (
        <SearchTableRow
          key={`${model}-${i}`}
          index={i}
          model={model}
          result={result}
        />
      ))
    }
  </TableBody>
);

SearchTableBody.propTypes = {
  model: PropTypes.string.isRequired,
  sectionResults: PropTypes.array.isRequired,
};

export default SearchTableBody;
