import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'common/utils/browserHistory';
import ContactDetailReview from 'common/display/ContactStepper/components/ContactDetailReview';

class ReviewContactDetailsView extends Component {
  constructor(props) {
    super(props);

    this.navigateToFacesheet = this.navigateToFacesheet.bind(this);
  }

  navigateToFacesheet() {
    const { selectedContactId } = this.props;
    browserHistory.push(`facesheet/${selectedContactId}`);
  }

  render() {
    return (
      <ContactDetailReview
        header="Create Client"
        mainHeader="Review Contact Information"
        subHeader="Please ensure that the contact information we have is accurate."
        lastPath={'/contacts/new/add-demographic-information'}
        onComplete={this.navigateToFacesheet}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedContactId: state.selectedContact,
  };
}

ReviewContactDetailsView.propTypes = {
  selectedContactId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(ReviewContactDetailsView);
