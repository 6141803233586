import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Dialog } from '@unite-us/ui';
import { noop } from 'lodash';
import { showTranslationsNewLanguages } from 'common/utils/FeatureFlags/flags';
import { updateGlobalState } from 'actions/Global/globalActions';
import { CONSENT_APP_URL } from 'src/config/env/env.config';
import { LanguageSelector } from '@unite-us/client-utils';
import RequestOrUploadConsentForm from './RequestOrUploadConsentForm';
import '../stylesheets/informedConsent.scss';

export class ConsentDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showConsentApp: false,
      showConsentAppAttestation: false,
      consentAppUrl: '',
      currentLanguage: props.language,
      field: {},
      registerField: null,
    };

    this.closeDialog = this.closeDialog.bind(this);
    this.hideConsent = this.hideConsent.bind(this);
    this.showConsent = this.showConsent.bind(this);
    this.setConsentAttestation = this.setConsentAttestation.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.getConsentAppUrl = this.getConsentAppUrl.bind(this);
  }

  handleOnChange(val, field, registerField) {
    const indexToReplace = this.state.consentAppUrl.lastIndexOf('language=') + 'language'.length;
    if (indexToReplace !== -1) {
      const trimURL = this.state.consentAppUrl.substring(0, indexToReplace);
      const newURL = trimURL.concat(`=${val}`);
      this.props.updateGlobalState({
        language: val,
      });
      this.setState({
        consentAppUrl: newURL,
        currentLanguage: val,
        field,
        registerField,
      });
    }
  }

  getConsentAppUrl(groupId, contactId, contact, consentAttestation) {
    const { currentEmployee } = this.props;
    if (!contactId) {
      return;
    }
    const submission_method = consentAttestation === true ? 'attestation' : '';
    this.setState({
      // eslint-disable-next-line
      consentAppUrl: `${CONSENT_APP_URL}?person_id=${contactId}&first_name=${contact.first_name}&last_name=${contact.last_name}&employee_id=${currentEmployee.id}&employee_fullname=${currentEmployee.full_name}&submission_method=${submission_method}&form-only&language=${this.state.currentLanguage}`,
      showConsentAppAttestation: consentAttestation === true,
    });
  }

  setConsentAttestation(value) {
    this.setState({ showConsentAppAttestation: value });
  }

  closeDialog() {
    this.dialog.closeDialog();

    if (this.state.showConsentAppAttestation) {
      this.setConsentAttestation(false);
    }

    if (this.state.showConsentApp) {
      this.hideConsent();
    }
  }

  hideConsent() {
    this.setState({ showConsentApp: false });
  }

  showConsent(field, registerField) {
    this.setState({
      showConsentApp: true,
      field,
      registerField,
    });
  }

  render() {
    const {
      allowSkipConsent,
      contact,
      initializedValue,
      shouldShowTranslationsNewLanguages,
    } = this.props;
    const {
      showConsentApp,
      consentAppUrl,
      field,
      registerField,
      showConsentAppAttestation,
    } = this.state;

    const Title = (showConsentApp && !showConsentAppAttestation) ? (
      <div className="consent-title_language-selector flex justify-between w-full pr-4 pt-2">
        <div className="pl-2">Informed Consent</div>
        <LanguageSelector
          onChange={this.handleOnChange}
          field={field}
          registerField={registerField}
          translationsNewLanguageFlag={shouldShowTranslationsNewLanguages}
        />
      </div>
    ) : (
      <div className="h-10">
        <div className="pt-2 pl-2">Informed Consent</div>
      </div>
    );

    return (
      <Dialog
        id="consent-dialog"
        ref={(el) => { this.dialog = el; }}
        title={Title}
        onRequestClose={() => this.closeDialog()}
        size="jumbo"
        dialogContentStyles={showConsentApp && { 'overflow-y': 'hidden' }}
      >
        <RequestOrUploadConsentForm
          allowSkipConsent={allowSkipConsent}
          closeDialog={this.closeDialog}
          contact={contact}
          contactId={contact.id}
          hideConsent={this.hideConsent}
          initializedValue={initializedValue}
          onNavigateAway={this.props.onNavigateAway}
          setConsentAttestation={this.setConsentAttestation}
          showConsent={this.showConsent}
          showConsentApp={this.state.showConsentApp}
          showConsentAppAttestation={this.state.showConsentAppAttestation}
          handleOnChange={this.handleOnChange}
          consentAppUrl={consentAppUrl}
          getConsentAppUrl={this.getConsentAppUrl}
        />
      </Dialog>
    );
  }
}

ConsentDialog.propTypes = {
  allowSkipConsent: PropTypes.bool,
  contact: PropTypes.shape({
    id: PropTypes.string,
  }),
  currentEmployee: PropTypes.object.isRequired,
  initializedValue: PropTypes.string,
  onNavigateAway: PropTypes.func,
  shouldShowTranslationsNewLanguages: PropTypes.bool,
  language: PropTypes.string,
  updateGlobalState: PropTypes.func.isRequired,
};

ConsentDialog.defaultProps = {
  allowSkipConsent: false,
  contact: {},
  initializedValue: '',
  onNavigateAway: noop,
  language: 'en',
  shouldShowTranslationsNewLanguages: false,
};

function mapStateToProps(state) {
  const language = state.globalState.language;
  const currentEmployee = state.globalState.currentEmployee;

  return {
    language,
    shouldShowTranslationsNewLanguages: showTranslationsNewLanguages(state),
    currentEmployee,
  };
}

export default connect(mapStateToProps, {
  updateGlobalState,
}, null, { forwardRef: true })(ConsentDialog);
