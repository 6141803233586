import { addresses } from '@unite-us/client-utils';
import { formatNumber } from 'humanize-plus';
import getGroupType from './getGroupType';

export const appendDistanceToName = ({ groupAddresses, name, originCoordinates }) => {
  const getDistance = (destinationLatLng) => addresses.getDistanceInMiles(originCoordinates, destinationLatLng, {
    format: 'float',
  });
  const distances = groupAddresses
    .map((address) => getDistance(address.lat_lng))
    .filter((distance) => distance !== '');
  const distance = Math.min(...distances);
  const miles = distance !== Infinity ? formatNumber(distance, 2) : 'no distance';
  const newName = miles !== 'no distance' ? `${name} (${miles} mi)` : `${name}`;

  return newName;
};

function getGroupOptions({
  canPaginateNetworkGroups,
  groups = [],
  selectedGroupIds = [],
  originCoordinates,
}) {
  return groups.reduce((groupOptions, currentGroup) => {
    if (currentGroup.name === undefined) {
      return groupOptions;
    }
    const {
      id,
      programs,
      addresses: groupAddresses = [],
      name,
      position,
    } = currentGroup;

    const groupName = canPaginateNetworkGroups ?
      appendDistanceToName({ groupAddresses, name, originCoordinates }) :
      name;

    return [
      ...groupOptions,
      {
        addresses: groupAddresses,
        disabled: selectedGroupIds.includes(id),
        displayName: groupName,
        id,
        name,
        programs,
        provider_type: currentGroup.provider_type || getGroupType(currentGroup),
        position,
      },
    ];
  }, []);
}

export default getGroupOptions;
