import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const StatusBadge = ({ className, label, 'data-testid': dataId }) => (
  <div className={cx(className, 'inline items-center px-2 py-1 rounded-full text-xs')} data-testid={dataId}>
    {label}
  </div>
);

StatusBadge.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  label: PropTypes.string.isRequired,
};

StatusBadge.defaultProps = {
  className: '',
  'data-testid': undefined,
};

export default StatusBadge;
