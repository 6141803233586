import { RISK_LEVELS } from '../../constants';

const CONFIG_DISPLAYS = {
  always: 'always',
  never: 'never',
  high_risk_only: 'high_risk_only',
};

const showRiskDisplayOption = (configKey, riskLevel) => {
  if (configKey === CONFIG_DISPLAYS.never) {
    return false;
  }

  if (configKey === CONFIG_DISPLAYS.always) {
    return true;
  }

  return CONFIG_DISPLAYS[configKey] && riskLevel === RISK_LEVELS.highRisk;
};

export default showRiskDisplayOption;
