import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import findPrimaryOrFirst from '@unite-us/client-utils/dist/utils/arrays/findPrimaryOrFirst';
import { dates } from '@unite-us/app-components';
import { CheckBoxField } from '@unite-us/ui';
import moment from 'moment';
import { phones } from '@unite-us/client-utils';
import { formatAddress } from 'src/components/ClientSummary/utils';
import ToolTip from 'common/ToolTip';

const PersonToMergeCard = (props) => {
  const {
    person, isSelected, handleSelect, disabled,
  } = props;

  const { formatDate } = dates;
  const dob = person.date_of_birth;
  const tel = findPrimaryOrFirst(person.phone_numbers || [])?.phone_number;
  const email = findPrimaryOrFirst(person.email_addresses || [])?.email_address;

  const age = moment().diff(moment.unix(dob), 'years');

  const demographics = {
    DOB: `${formatDate(dob)} (Age ${age})`,
    TEL: phones.formatPhoneNumber(tel),
    EMAIL: email,
    ADDRESS: person.addresses?.length ? formatAddress(findPrimaryOrFirst(person.addresses)) : '',
  };

  return (
    <div
      data-testid="people-to-merge"
      className={classNames('flex items-center space-x-6', {
        'pointer-events-none opacity-50': disabled,
      })}
      aria-disabled={disabled}
    >
      <div className="flex items-center w-5 h-5 mt-10">
        <CheckBoxField
          id={`${person.id}-checkbox`}
          checked={isSelected} onChange={() => handleSelect(person)}
          label=""
        />
      </div>
      <div className="
        flex flex-col min-w-0 w-full
        px-4 py-3 space-y-2 border border-solid
        rounded-md border-filter-border-color"
      >
        <div className="flex justify-between">
          <h3 className="text-base font-semibold text-action-blue">
            {person.full_name}
          </h3>
          <div className="flex gap-2">
            <p className="italic">Last Activity: {formatDate(person.last_activity)}</p>
            <p className="italic">Created: {formatDate(person.created_at)}</p>
          </div>
        </div>
        <div className="flex gap-2">
          <p>Open Cases: {person.open_case_count}</p>
          <p>Referrals: {person.referral_count}</p>
          <p>Notes: {person.notes_count}</p>
        </div>
        <ul className="list-unstyled flex">
          {Object.entries(demographics).map(([key, value], index) => {
            const liClasses = classNames(
              'inline-block text-sm whitespace-no-wrap text-brand-blue',
              {
                'border-l border-solid border-filter-border-color pl-2 ml-2': index !== 0,
                truncate: index === 3,
              },
            );

            return (
              <li
                key={key}
                className={liClasses}
              >
                <span className="inline-block mr-2 font-bold">{key}</span>
                <span>{value}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default function PersonToMerge(props) {
  const { disabled } = props;
  return (
    <li>
      { disabled ? (
        <ToolTip
          tooltipElementAs="span"
          enableHover
          buttonClassName="focus:outline-none cursor-not-allowed w-full"
          buttonComponentContent={() => (
            <PersonToMergeCard {...props} />
          )}
          offsetOptions={{
            offset: [20, -20],
          }}
          panelComponentContent={() => (
            'This record has at least one set of data that is not compatible to merge. Select another record.'
          )}
          panelClassName="bg-white border border-gray-400 border-solid rounded text-grey-blue
          shadow-md leading-4
            w-48 px-5 py-3"
          placement="top-start"
        />
        ) : (
          <PersonToMergeCard {...props} />
        )}
    </li>
  );
}

PersonToMergeCard.defaultProps = {
  disabled: false,
};

PersonToMergeCard.propTypes = {
  person: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

PersonToMerge.defaultProps = {
  disabled: false,
};

PersonToMerge.propTypes = {
  person: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
