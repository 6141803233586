import { useFind } from 'src/api/APIHooks';
import { get, sortBy } from 'lodash';

const useProvidedServiceToFindInvoices = (providedServiceId) => {
  const { isFetching, data: invoiceApiData } = useFind(
    'invoice',
    {
      provided_service: providedServiceId,
    },
    {
      queryConfig: {
        enabled: !!providedServiceId,
        placeholderData: undefined,
      },
    },
  );

  const invoicesData = get(invoiceApiData, 'data.data', []);
  const invoices = sortBy(invoicesData, 'created_at');

  return isFetching ? [] : invoices;
};

export default useProvidedServiceToFindInvoices;
