import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty, noop } from 'lodash';
import { SelectField } from '@unite-us/ui';
import { replaceAndCapitalizeString } from 'common/utils/utils';

const EnumSelect = (props) => {
  const { enums, inline } = props;

  if (isEmpty(enums)) {
    return null;
  }

  const options = enums.map((option = {}) => ({
    value: option.value,
    label: replaceAndCapitalizeString(option.display_name),
  }));

  return (
    <SelectField
      ref={props.registerField}
      {...props}
      options={options}
      inline={inline}
    />
  );
};

EnumSelect.propTypes = {
  enums: PropTypes.array,
  field: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  label: PropTypes.string,
  registerField: PropTypes.func,
};

EnumSelect.defaultProps = {
  enums: [],
  inline: false,
  label: '',
  registerField: noop,
};

export default EnumSelect;
