import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { noop } from 'lodash';
import { BarLoader } from '@unite-us/ui';
import EditPhoneNumbersGroup from 'common/form/Profile/EditPhoneNumbers/EditPhoneNumbersGroup';
import PhoneNumberDisplay from 'common/display/PhoneNumberDisplay';
import RowDisplay from 'common/display/Profile/RowDisplay';
import { EDIT_PHONE_NUMBERS } from 'common/display/Profile/constants/form';
import { primaryFirstPhoneSort } from 'common/form/Profile/EditPhoneNumbers/utils';
import EditModal from 'common/modal/EditModal';
import './PhoneNumbers.scss';

export class PhoneNumbersGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneDisplay: [],
    };

    this.setPhoneDisplay = this.setPhoneDisplay.bind(this);
  }

  componentDidMount() {
    this.setPhoneDisplay(this.props.phoneNumbers);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.phoneNumbers !== nextProps.phoneNumbers) {
      this.setPhoneDisplay(nextProps.phoneNumbers);
    }
  }

  setPhoneDisplay(phones = []) {
    const phoneDisplay = primaryFirstPhoneSort(phones).map((phone, index) => (
      <div
        className="col-sm-6 phone-numbers__phone-number-display"
        key={index}
      >
        <PhoneNumberDisplay item={phone} />
      </div>
    ));
    this.setState({ phoneDisplay });
  }

  render() {
    const {
      groupId,
      hideCommunicationPreferences,
      isFetching,
      onSave,
      phoneFor,
      phoneNumbers,
      phoneTypes,
    } = this.props;

    const editSection = (
      <EditModal
        header="Edit Phone Number"
        size="large"
        formName={EDIT_PHONE_NUMBERS}
        buttonId="edit-phone-number-btn"
        id="edit-phone-number-modal"
      >
        {
          isFetching ? (
            <div>
              <BarLoader className="mb-quarter" size="semi-full" />
              <BarLoader className="mb-quarter" size="half" />
              <BarLoader className="mb-quarter" size="semi-full" />
            </div>
          ) : (
            <EditPhoneNumbersGroup
              groupId={groupId}
              hideCommunicationPreferences={hideCommunicationPreferences}
              numbers={phoneNumbers}
              onSave={onSave}
              phoneFor={phoneFor}
              phoneTypes={phoneTypes}
            />
          )
        }
      </EditModal>
    );

    return (
      <RowDisplay
        className="phone-numbers"
        editSection={editSection}
        label="Phone"
      >
        {this.state.phoneDisplay}
      </RowDisplay>
    );
  }
}

PhoneNumbersGroup.propTypes = {
  groupId: PropTypes.string.isRequired,
  hideCommunicationPreferences: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  phoneFor: PropTypes.oneOf(['contact', 'group']),
  phoneNumbers: PropTypes.array,
  phoneTypes: PropTypes.array.isRequired,
};

PhoneNumbersGroup.defaultProps = {
  phoneFor: 'contact',
  phoneNumbers: [],
  hideCommunicationPreferences: false,
  onSave: noop,
};

export default PhoneNumbersGroup;
