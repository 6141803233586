import React from 'react';
import { AUTHORIZATION_STATUSES } from 'src/common/Status/authorizationConstants';
import ServiceAuthorizationWorkqueue from './ServiceAuthorizationWorkqueue';

const RejectedServiceAuthorizations = () => (
  <ServiceAuthorizationWorkqueue
    authorizationStatus={AUTHORIZATION_STATUSES.rejected}
  />
);

export default RejectedServiceAuthorizations;
