import PropTypes from 'prop-types';
import React from 'react';
import IconButton from './IconButton';

function AddCirclePlusButton(props) {
  return (
    <IconButton
      {...props}
      icon="IconPlusCircle"
      onClick={props.addHandler}
    />
  );
}

AddCirclePlusButton.propTypes = {
  ariaLabel: PropTypes.string,
  addHandler: PropTypes.func.isRequired,
  labelText: PropTypes.string,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  toolTip: PropTypes.string,
};

AddCirclePlusButton.defaultProps = {
  labelText: 'add',
  ariaLabel: 'add',
  disabled: false,
  toolTip: '',
};

export default AddCirclePlusButton;
