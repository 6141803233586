import Notifier from 'common/helpers/Notifier';
import { apiV4Default } from 'src/api/config';
import {
  FETCH_PAYMENTS_INSURANCE_STARTED,
  FETCH_PAYMENTS_INSURANCE_SUCCESS,
  FETCH_PAYMENTS_INSURANCE_ERROR,
} from 'src/actions/index';

export default function fetchPaymentsInsurance(contactId, groupId) {
  const params = {
    'filter[person]': contactId,
    'filter[state]': 'active,pending,inactive',
    group_id: groupId,
  };
  return (dispatch) => {
    const request = apiV4Default.get('/insurances', { params });
    dispatch({ type: FETCH_PAYMENTS_INSURANCE_STARTED });

    function onSuccess(payload) {
      dispatch({
        type: FETCH_PAYMENTS_INSURANCE_SUCCESS,
        contactId,
        payload,
      });
      return payload;
    }

    function onError(error) {
      dispatch({ type: FETCH_PAYMENTS_INSURANCE_ERROR });
      Notifier.dispatch('error', 'Insurance information cannot be displayed');
      return error;
    }

    return request.then(onSuccess, onError);
  };
}
