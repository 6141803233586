import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* to be used with src/common/hooks/useFeatureFlag.js
 * the provider/container here place flags from redux state
 * into context, and make them queryable via the hook.
 */

export const FeatureFlagContext = createContext();

const FeatureFlagContainer = (Component) => {
  const InnerFFContainer = ({ featureFlags, ...props }) => (
    <FeatureFlagContext.Provider value={featureFlags}>
      <Component {...props} />
    </FeatureFlagContext.Provider>
  );

  InnerFFContainer.propTypes = {
    featureFlags: PropTypes.object.isRequired,
  };

  const mapStateToProps = (state) => ({
    featureFlags: state.flags,
  });

  return connect(mapStateToProps)(InnerFFContainer);
};

/* usage:
 * const MyComponentThatNeedsFeatureFlags = (props) => {
 *   const hasFF = useFeatureFlag('my-flag');
 * };
 *
 * <FeatureFlagProvider>
 *   <MyComponentThatNeedsFeatureFlags {...} />
 * </FeatureFlagProvider>
 */
export const FeatureFlagProvider = FeatureFlagContainer(({ children }) => children);

/* usage:
 * const MyComponentThatNeedsFeatureFlags = (props) => {
 *   const hasFF = useFeatureFlag('my-flag');
 * };
 * const UseThisComponent = FeatureFlagContainer(MyComponentThatNeedsFFs);
 * <UseThisComponent />
 */
export default FeatureFlagContainer;
