import {
  useFind,
  useFindRecord,
} from 'src/api/APIHooks';
import { defaultQueryConfig } from '../apiHookOptions';

export const useUniteUsServices = () => {
  const { isFetching, data } = useFind(
    'service',
    {
      taxonomy: 'uniteus',
    },
    {
      queryConfig: {
        staleTime: Infinity,
        ...defaultQueryConfig,
      },
      page: { number: 1, size: 300 },
    },
  );

  const childServices = data.filter((st) => st.parent);
  const flattenedServices = data.sort((d) => d.name).reduce((map, parent) => {
    if (!(Object.prototype.hasOwnProperty.call(parent, 'parent'))) {
      // eslint-disable-next-line no-param-reassign
      map[parent.id] = {
        ...parent,
        children: childServices.filter((st) => st.parent.id === parent.id),
      };
    }
    return map;
  }, {});
  return { isFetching, data: flattenedServices };
};

export const useFindService = (id) => useFindRecord('service', id, {
  queryConfig: defaultQueryConfig,
});

export const useFindServices = (ids, params = { queryConfig: defaultQueryConfig }) => useFind(
  'service',
  {
    id: ids,
  },
  params,
);
