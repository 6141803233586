import { isEmpty } from 'lodash';
import { coreApi } from 'src/api/config';

function uploadAndAttachDocumentsToResources({
  // attach_to_contact = false,
  // resourceIds = [],
  contactId,
  attachedDocuments,
  uploadedDocuments,
  caseId,
}) {
  const attachDocumentsToResource = () => (
    coreApi.setRelationship(
      'case',
      caseId,
      { relationshipModel: 'file_upload', value: attachedDocuments.map((a) => a.id) },
    )
  );

  const uploadDocumentsToResource = async () => {
    if (caseId) {
      return Promise.all(
        uploadedDocuments.map((doc) => coreApi.uploadFile('case', caseId, doc)),
      );
    }

    return Promise.all(
      uploadedDocuments.map((doc) => coreApi.uploadFile('person', contactId, doc)),
    );
  };

  return Promise.all([
    !isEmpty(attachedDocuments) && attachDocumentsToResource(),
    !isEmpty(uploadedDocuments) && uploadDocumentsToResource(),
  ]);
}

export default uploadAndAttachDocumentsToResources;
