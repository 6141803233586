import { apiDefault } from 'src/api/config';
import coreParamsSerializer from 'src/api/utils/coreParamsSerializer';

export default function fetchCoreProviderServices(providerId, includePathways) {
  const request = apiDefault.get('/services', {
    params: {
      filter: {
        'programs.provider': providerId,
        include_pathways: includePathways,
      },
    },
    paramsSerializer: (params) => coreParamsSerializer(params),
  });

  function onSuccess(payload) {
    return payload.data.data;
  }

  function onError(error) {
    return error;
  }

  return request.then(onSuccess, onError);
}
