import {
  FETCH_FILE_UPLOADS_ERROR,
  FETCH_FILE_UPLOADS_SUCCESS,
  FETCH_FILE_UPLOADS_STARTED,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import { isHttpError } from 'common/utils/Error';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { apiV1 } from 'src/api/config';

export default function fetchFileUploads(options = {}) {
  return async (dispatch) => {
    dispatch({ type: FETCH_FILE_UPLOADS_STARTED });
    const payload = await apiV1.query(
      'file_uploads',
      {
        record: options.recordId,
        'record.type': options.recordType,
      },
    );

    if (!payload || !isHttpSuccess(payload.status)) {
      dispatch({ type: FETCH_FILE_UPLOADS_ERROR });

      if (isHttpError(payload)) {
        Notifier.dispatch('error', 'Failed to load files');
      } else {
        Notifier.handleErrors(payload);
      }

      return payload;
    }

    dispatch({
      type: FETCH_FILE_UPLOADS_SUCCESS,
      payload,
    });

    return payload;
  };
}
