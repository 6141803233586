import qs from 'qs';

/**
 * Returns query string that can be passed to axios request
 * @param { object } params - json object to pass as params in the request header
*    - const params = { filter: { provider: groupId, active: true } };
 * @return { string } - query string paramaters for request headers
   - i.e.: "filter[provider]=abc1234-5678-44a5-bf91-b2a4b7f5be6c&filter[state]=active"
 */

export default function coreParamsSerializer(params) {
  return qs.stringify(params, { encode: false, arrayFormat: 'comma' });
}
