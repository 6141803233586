import {
  useFind,
} from 'src/api/APIHooks';
import { defaultQueryConfig } from '../apiHookOptions';

export const useFindPlanEnrollmentForms = (planId, queryConfig = { queryConfig: defaultQueryConfig }) => useFind(
  'forms',
  {
    'form_usages.usage_type': 'enrollment',
    'form_usages.form_utilizer': planId,
    'form_usages.form_utilizer.type': 'plan',
  },
  queryConfig,
);
