import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  CLIENT_RESULTS_COUNT,
  MODELS,
} from 'src/components/Search/constants';
import SearchBarSection from './SearchBarSection';

const { contact } = MODELS;

const SearchBarResults = (props, context) => {
  const { clientIdStandardizationEnabled } = context;

  return (
    <div className={classNames(
      'search-results',
      {
        'max-w-3xl': !clientIdStandardizationEnabled,
        'max-w-4xl': clientIdStandardizationEnabled,
      },
    )}
    >
      <SearchBarSection
        displayName="Clients"
        model={contact}
        numResults={CLIENT_RESULTS_COUNT}
      />
    </div>
  );
};

SearchBarResults.contextTypes = {
  clientIdStandardizationEnabled: PropTypes.bool.isRequired,
};

export default SearchBarResults;
