import { get, isEmpty } from 'lodash';

export function requiresEula(session) {
  if (isEmpty(session) || isEmpty(session.user_support)) {
    return false;
  }

  return !get(session, 'user_support.has_accepted_eula_terms');
}

export function isUser(user) {
  if (isEmpty(user) || user.fetchingUser) {
    return false;
  }

  return !!user.id;
}

export function requiresUniteUsParticipationAgreement(session) {
  if (isEmpty(session) || isEmpty(session.user_support)) {
    return false;
  }

  return get(session, 'user_support.participation_agreements_required', []).length > 0;
}
