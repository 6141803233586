import { omit } from 'lodash';
import {
  DELETE_FILE_UPLOAD_SUCCESS,
  FETCH_FILE_UPLOADS_STARTED,
  FETCH_FILE_UPLOADS_SUCCESS,
  FETCH_FILE_UPLOADS_ERROR,
} from 'actions';
import {
  STATUS_INITIAL,
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_ERROR,
} from 'common/Status/constants';
import arrayToObject from 'common/utils/arrayToObject';

export const defaultState = {
  fileUploads: {},
  status: STATUS_INITIAL,
};

export default function fileUploadsV2Reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case FETCH_FILE_UPLOADS_STARTED: {
      return {
        ...state,
        status: STATUS_PENDING,
      };
    }

    case FETCH_FILE_UPLOADS_ERROR: {
      return {
        ...state,
        status: STATUS_ERROR,
      };
    }

    case FETCH_FILE_UPLOADS_SUCCESS: {
      return {
        ...state,
        status: STATUS_SUCCESS,
        fileUploads: { ...state.fileUploads, ...arrayToObject(action.payload.data.data) },
      };
    }

    case DELETE_FILE_UPLOAD_SUCCESS: {
      return {
        ...state,
        fileUploads: { ...omit(state.fileUploads, action.fileId) },
      };
    }

    default:
      return state;
  }
}
