import fetchEmployees from 'src/api/core/Employees/fetchEmployees';
import {
  FETCH_GROUPS_USERS_FROM_PROGRAM,
  GROUPS_USER_SET_FETCHING,
  GROUPS_USER_UNSET_FETCHING,
} from 'actions';
import Notifier from 'common/helpers/Notifier';

const defaultOptions = {
  per: 50,
  page: 1,
};

const convertCorePayload = (payload) => {
  try {
    const newPayload = JSON.parse(JSON.stringify(payload));
    const newData = newPayload.data.data.map((employee) => ({
      updated_at: undefined,
      employee: { ...employee },
      user: {
        ...employee,
        id: employee.user.id,
      },
    }));
    const paging = {
      current_page: newPayload.data.meta.page.number,
      total_count: newPayload.data.meta.page.total_count,
      per: newPayload.data.meta.page.size,
      total_pages: newPayload.data.meta.page.total_pages,
    };
    newPayload.data.data = newData;
    newPayload.data.paging = paging;
    delete newPayload.data.meta;
    return newPayload;
  } catch (err) {
    return null;
  }
};

const onSuccess = (payload, options, dispatch) => {
  dispatch({ type: GROUPS_USER_UNSET_FETCHING });
  dispatch({
    type: FETCH_GROUPS_USERS_FROM_PROGRAM,
    payload,
    options,
  });
  return payload;
};

const onError = (error, dispatch) => {
  dispatch({ type: GROUPS_USER_UNSET_FETCHING });
  Notifier.handleErrors(error);
  return null;
};

export default function fetchGroupsUsersFromProgram(groupId, programId, options = defaultOptions, networkId = null) {
  return async (dispatch) => {
    try {
      const { response } = await dispatch(fetchEmployees({
        providers: groupId,
        programs: programId,
        licensed: true,
        networks: networkId,
        query: options.text,
      }));
      const payload = convertCorePayload(response);
      dispatch({ type: GROUPS_USER_SET_FETCHING });
      return onSuccess(payload, options, dispatch);
    } catch (error) {
      dispatch({ type: GROUPS_USER_SET_FETCHING });
      return onError(error, dispatch);
    }
  };
}
