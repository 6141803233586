import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { coreApi } from 'src/api/config';
import {
  RENAME_CONTACT_DOCUMENT,
  RENAME_CASE_DOCUMENT,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import { get } from 'lodash';

const renameCaseDocument = ({
  caseId,
  contactId,
  documentId,
  notifySuccess = false,
  title,
}) => (
  async (dispatch) => {
    // Rename the document before updating so the UI change happens immediately.
    dispatch({
      type: RENAME_CASE_DOCUMENT,
      caseId,
      contactId,
      documentId,
      title,
    });

    const payload = await coreApi.updateRecord('file_upload', documentId, {
      filename: title,
    });

    if (!isHttpSuccess(payload.status)) {
      Notifier.handleErrors(payload);
      return payload;
    }

    dispatch({
      type: RENAME_CASE_DOCUMENT,
      caseId,
      contactId,
      documentId: get(payload, 'data.data.id'),
      title: get(payload, 'data.data.filename'),
    });

    dispatch({
      type: RENAME_CONTACT_DOCUMENT,
      contactId,
      documentId: get(payload, 'data.data.id'),
      title: get(payload, 'data.data.filename'),
    });

    if (notifySuccess) {
      Notifier.dispatch(payload.status, 'Document Renamed');
    }

    return payload;
  }
);

export default renameCaseDocument;
