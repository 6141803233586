import { FETCH_CASE_ASSESSMENTS } from 'actions';

export default function caseAssessmentsReducer(state = [], action) {
  const { payload, type } = action;

  switch (type) {
    case FETCH_CASE_ASSESSMENTS:
      return payload.data.data;
    default:
      return state;
  }
}
