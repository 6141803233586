import { isEmpty } from 'lodash';

const invalidServiceTypeValidation = (serviceType = {}) => ({
  func: (value = '') => {
    if (!value && !isEmpty(serviceType)) {
      return [
        `The identified service type, ${serviceType.name},`,
        `needs to be updated before you move forward. Choose which ${serviceType.name} subtype best represents your`,
        'client\'s need.',
      ].join(' ');
    }
    return '';
  },
});

export default invalidServiceTypeValidation;
