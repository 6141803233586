/**
 * @param {string} date ISO 8601 format (e.g., 2023-02-01T00:00:00.000Z)
 * @returns {string} Formatted date (e.g., Feb 1, 2023)
 */
export default function formatDate(date, useUTC = true) {
  return new Date(Date.parse(date)).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    ...(useUTC ? { timeZone: 'UTC' } : {}),
  });
}
