import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { wget } from 'lodash';
import { getAdditionalInfoDisplay } from './utils';
import './stylesheets/communicationAdditionalInfo.scss';

const CommunicationAdditionalInfo = ({
  communicationTypes,
  contactCommunicationTypesEnums,
  isPrimary,
}) => (
  <div className="communication-additional-info">
    {
      getAdditionalInfoDisplay({
        communicationTypes,
        enums: contactCommunicationTypesEnums,
        isPrimary,
      })
    }
  </div>
);

CommunicationAdditionalInfo.propTypes = {
  communicationTypes: PropTypes.array,
  contactCommunicationTypesEnums: PropTypes.array.isRequired,
  isPrimary: PropTypes.bool,
};

CommunicationAdditionalInfo.defaultProps = {
  communicationTypes: [],
  isPrimary: false,
};

function mapStateToProps(state) {
  const contactCommunicationTypesEnums = wget(
    state,
    'session.enums.contact_data.communication_types',
    [],
  );
  return {
    contactCommunicationTypesEnums,
  };
}

export default connect(mapStateToProps)(CommunicationAdditionalInfo);
