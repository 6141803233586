export function genderPartitions() {
  return [
    'male',
    'female',
    'non_binary',
    'undisclosed',
    'other',
  ].join(',');
}

export function ethnicityPartitions() {
  return [
    'hispanic_or_latino',
    'not_hispanic_or_latino',
    'undisclosed',
  ].join(',');
}

export function serviceBranchesPartitions() {
  return [
    'air_force',
    'army',
    'coast_guard',
    'marines',
    'navy',
    'prefer_not_to_disclose',
    'no_response',
  ].join(',');
}

export function networkServiceBranchesPartitions() {
  return [
    'air_force',
    'army',
    'coast_guard',
    'marines',
    'navy',
    'prefer_not_to_disclose',
  ].join(',');
}

export function resolutionPartitions() {
  return [
    'resolved',
    'unresolved',
  ].join(',');
}
