import React from 'react';
import cx from 'classnames';
import { PropTypes } from 'prop-types';
import { Icon } from '@unite-us/ui';

const SecondaryButton = ({
  ariaLabel,
  className,
  dataTestId,
  disabled,
  icon,
  label,
  onClick,
}) => (
  <button
    className={cx(
      className,
      'flex p-2 border border-solid border-dark-border-blue rounded space-x-2',
      disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-light-fill-blue',
    )}
    data-testid={dataTestId}
    type="button"
    aria-label={ariaLabel || label}
    onClick={onClick}
    disabled={disabled}
  >
    { icon ? <Icon icon={icon} className={cx('fill-current text-action-blue', disabled && 'opacity-50')} /> : null }
    <span className={cx('text-action-blue', disabled && 'opacity-50')}>
      {label}
    </span>
  </button>
);

SecondaryButton.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

SecondaryButton.defaultProps = {
  ariaLabel: '',
  className: '',
  disabled: false,
  icon: '',
};

export default SecondaryButton;
