import { apiV4Default } from 'src/api/config';
import {
  EDIT_INSURANCE_ENTRY_ERROR,
  EDIT_INSURANCE_ENTRY_STARTED,
  EDIT_INSURANCE_ENTRY_SUCCESS,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import { isHttpError } from 'common/utils/Error';
import { buildEditMedicalInsuranceParams } from '../utils';

function editInsuranceEntry({
  groupId,
  contactId,
  values,
  insuranceId,
  showNotification = true,
}) {
  return (dispatch) => {
    dispatch({ type: EDIT_INSURANCE_ENTRY_STARTED });

    const paymentsFormParams = buildEditMedicalInsuranceParams({
      groupId,
      values,
      contactId,
      insuranceId,
    });
    const request = apiV4Default.patch(`/insurances/${insuranceId}`, paymentsFormParams);

    function onSuccess(payload) {
      dispatch({
        type: EDIT_INSURANCE_ENTRY_SUCCESS,
        contactId,
        insuranceId,
        payload,
      });

      if (showNotification) {
        Notifier.dispatch(payload.status, 'Insurance Information Successfully Updated');
      }

      return payload;
    }

    function onError(error) {
      dispatch({ type: EDIT_INSURANCE_ENTRY_ERROR });
      if (isHttpError(error)) {
        Notifier.dispatch(
          'error',
          'Error updating insurance for client. Please try again.',
        );
      } else {
        Notifier.handleErrors(error);
      }
    }

    return request.then(onSuccess, onError);
  };
}

export default editInsuranceEntry;
