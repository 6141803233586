function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import _ from 'lodash';
function buildOptions() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$options = _ref.options,
    options = _ref$options === void 0 ? [] : _ref$options,
    _ref$selected = _ref.selected,
    selected = _ref$selected === void 0 ? [] : _ref$selected,
    _ref$disableParent = _ref.disableParent,
    disableParent = _ref$disableParent === void 0 ? true : _ref$disableParent,
    _ref$level = _ref.level,
    level = _ref$level === void 0 ? 0 : _ref$level;
  return _.reduce(options, function (acc, opt) {
    if (_.has(opt, 'children') && !_.isEmpty(opt.children)) {
      var allChildrenSelected = _.every(_.reduce(opt.children, function (arr, child) {
        if (selected.includes(child.value)) {
          return [].concat(_toConsumableArray(arr), [true]);
        }
        return [].concat(_toConsumableArray(arr), [false]);
      }, []));
      var newOpt = _.merge(opt, {
        hasChildren: true,
        level: level,
        disabled: disableParent,
        allChildrenSelected: allChildrenSelected
      });
      return _.concat(acc, newOpt, buildOptions({
        options: opt.children,
        selected: selected,
        disableParent: disableParent,
        level: level + 1
      }));
    }
    return _.concat(acc, _.merge(opt, {
      hasChildren: false,
      level: level
    }));
  }, []);
}
export default buildOptions;