import { useFind, useFindRecord } from 'src/api/APIHooks';
import { defaultQueryConfig } from './apiHookOptions';

export const useFindPayer = (id) => useFindRecord('payer', id, {
  queryConfig: defaultQueryConfig,
});

export const useFindPayers = (searchTerm, queryConfig = { queryConfig: defaultQueryConfig }) => useFind(
  'payer',
  { name: searchTerm || '' },
  queryConfig,
);
