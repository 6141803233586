import React from 'react';
import { PropTypes } from 'prop-types';
import OrganizationHeader from 'src/components/Organization/components/OrganizationHeader';
import OrganizationEditForm from 'src/components/Organization/pages/OrganizationEditForm';
import FormHeader from 'src/components/Organization/components/FormHeader';
import { ORG_TABS } from 'src/components/Organization/constants';
import useTrackPageView from 'src/components/Organization/utils/useTrackPageView';
import { ORG_SETTINGS } from 'src/common/utils/EventTracker/utils/eventConstants';
import FormContainer from 'src/components/Organization/components/FormContainer';

const Organization = ({ location }) => {
  useTrackPageView(ORG_SETTINGS.editOrgViewed);
  return (
    <>
      <OrganizationHeader currentTab={ORG_TABS.organization} location={location} />
      <FormContainer>
        <FormHeader
          cancelLink="/organization/settings"
          headingText="Edit Organization Info"
        />
        <p className="pb-4">
          Edit your organization information using the
          <a
            href="https://support.uniteus.io/en/articles/5808325-update-your-organization-s-profile-information"
            target="_blank"
            rel="noreferrer"
          >
          &nbsp;Update Your Organization’s Profile Information&nbsp;
          </a>
          article.
        </p>
        <OrganizationEditForm />
      </FormContainer>
    </>
  );
};

Organization.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Organization;
