import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Icon, Button } from '@unite-us/ui';
import { theme } from 'src/../tailwind.config';
import PageHeading from './PageHeading';

const FormHeader = ({ headingText, cancelLink, cancelText }) => (
  <header className="py-5 flex items-center">
    <Link
      data-test-element="cancel"
      role="link"
      to={cancelLink}
    >
      <Button
        iconLeft={<Icon icon="IconChevronLeft" className="fill-current"/>}
        outline
        label={cancelText}
        className="mr-4"
      />
    </Link>
    <PageHeading text={headingText} />
  </header>
);

FormHeader.propTypes = {
  headingText: PropTypes.string.isRequired,
  cancelLink: PropTypes.string.isRequired,
  cancelText: PropTypes.string,
};

FormHeader.defaultProps = {
  cancelText: 'Cancel',
};

export default FormHeader;
