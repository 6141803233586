import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Interactions from 'common/form/Interactions';
import {
  createContactServiceCaseProvidedService,
  createContactServiceCaseInteraction,
} from 'actions/Case/Contact/Group';
import { INTERACTION_TYPES } from 'src/components/Notes/constants';
import { isFeeScheduleProgram } from 'src/components/Cases/utils';
import callOrLog from 'src/common/utils/callOrLog';
import { SERVICE_CASE } from 'common/utils/EventTracker/utils/eventConstants';
import { Serializer } from '@unite-us/client-utils';
import { compact, noop } from 'lodash';
import {
  useInvalidateQueries,
} from 'src/api/APIHooks';

export const submitInteractionFn = ({
  formValues,
  category,
  props,
  invalidateQueries,
  close,
}) => {
  const { interaction = {} } = formValues;
  const {
    eventTracker,
    groupId,
    contact,
    serviceCase,
  } = props;

  const eventPayload = Serializer.build({ interaction });
  const is_note_private = formValues.is_note_private || serviceCase.state === 'draft';
  return props.createContactServiceCaseInteraction({
    groupId,
    contactId: contact.id,
    subject: serviceCase.id,
    subjectType: 'case',
    interactionType: formValues.interaction.type,
    interaction: {
      ...formValues.interaction,
      category,
      is_note_private,
    },
  }).then(() => {
    invalidateQueries('notes');
    close();
    callOrLog(() => eventTracker(SERVICE_CASE.noteAdded, eventPayload, {
      serviceCase,
    }));
  });
};

export const ServiceCaseInteractions = (props) => {
  const {
    cancelFunction,
    isFSP,
    serviceCase,
    contact,
    groupId,
  } = props;
  const showServiceProvided = !serviceCase.closed_date && !isFSP;
  const invalidateQueries = useInvalidateQueries();

  const submitInteraction = (formValues, category) => (
    submitInteractionFn({
      formValues,
      category,
      props,
      invalidateQueries,
      close: cancelFunction,
    })
  );

  const submitProvidedService = async (formValues) => (
    props.createContactServiceCaseProvidedService({
      groupId,
      contactId: contact.id,
      serviceCaseId: serviceCase.id,
      provided_service: formValues.provided_service,
      is_note_private: formValues.interaction.is_note_private,
    })
      .then(() => {
        invalidateQueries('notes');
        cancelFunction();
      })
  );
  const interactionList = compact([
    {
      value: INTERACTION_TYPES.INTERACTION,
      submitFunction: (formValues) => submitInteraction(formValues, 'interaction'),
    },
    showServiceProvided &&
      {
        value: INTERACTION_TYPES.SERVICE_PROVIDED,
        submitFunction: (formValues) => submitProvidedService(formValues, 'service_provided'),
      },
    {
      value: INTERACTION_TYPES.GENERAL,
      submitFunction: (formValues) => submitInteraction(formValues, 'general'),
    },
  ]);

  return (
    <div style={props.styles.position}>
      <Interactions
        detailType="case"
        interactionList={interactionList}
        isFromCaseOrReferral
        isFSP={isFSP}
        cancelFunction={cancelFunction}
      />
    </div>
  );
};

ServiceCaseInteractions.propTypes = {
  contact: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  isFSP: PropTypes.bool.isRequired,
  serviceCase: PropTypes.object.isRequired,
  createContactServiceCaseProvidedService: PropTypes.func.isRequired,
  styles: PropTypes.object,
  cancelFunction: PropTypes.func,
};

ServiceCaseInteractions.defaultProps = {
  styles: {
    position: { marginTop: '20px' },
  },
  cancelFunction: noop,
};

function mapStateToProps(state, ownProps) {
  return {
    groupId: state.session.groupId,
    isFSP: isFeeScheduleProgram(state, ownProps.serviceCase),
  };
}

export default connect(mapStateToProps, {
  createContactServiceCaseProvidedService,
  createContactServiceCaseInteraction,
})(ServiceCaseInteractions);
