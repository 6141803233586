import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { fetchGroup } from 'actions/Group';
import { browserHistory } from 'common/utils/browserHistory';
import goToInvoicesIndex from 'src/pages/invoices/utils/goToInvoicesIndex';
import _ from 'lodash';
import {
  hasWorkflowViewIndex,
  hasContactsViewContacts,
  hasReportsViewReports,
  hasInsightsViewInsights,
  hasNetworkDirectoryAccess,
  hasPayerInvoicesRole,
} from 'src/common/utils/FeatureFlags/flags';
import { getEmployeeNetworks } from 'src/components/Employee/employeeGetters';

class Home extends Component {
  UNSAFE_componentWillMount() {
    this.props.fetchGroup(this.props.groupId);
  }

  componentDidMount() {
    this.isRedirectedOnOrderedListOfPermissions();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.groupId !== this.props.groupId) {
      this.props.fetchGroup(nextProps.groupId);
    }
  }

  isRedirectedOnOrderedListOfPermissions = () => {
   const {
    currentNetworkId,
    firstNetworkId,
    hasContacts,
    hasDashboard,
    hasInsights,
    hasNetworkDirectory,
    hasReports,
    isNetworkLead,
  } = this.props;

    if (!hasDashboard) {
      if (hasContacts) {
        browserHistory.push('/dashboard/clients/all');
        return null;
      }
      if (hasInsights) {
        browserHistory.push('/insights');
        return null;
      }
      if (hasReports) {
        browserHistory.push(`/reports/${firstNetworkId}`);
        return null;
      }
      if (isNetworkLead || this.props.hasPayerInvoices) {
        // This is kinda hacky, but setting the timeout gives a buffer
        // so the hasInvoicesAccess feature flag updates to correct value when landing on invoices page.
        // There may be a better way to do this.
        setTimeout(
          () => goToInvoicesIndex(),
          100,
        );
        return null;
      }
      if (hasNetworkDirectory) {
        browserHistory.push(`/network/${currentNetworkId}/browse`);
        return null;
      }

      // force dashboard if feature flags are not defined properly
      return browserHistory.push('/dashboard');
    }
    return browserHistory.push('/dashboard');
  }

  render() {
    return null;
  }
}

Home.propTypes = {
  isNetworkLead: PropTypes.bool.isRequired,
  fetchGroup: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  firstNetworkId: PropTypes.string.isRequired,
  hasDashboard: PropTypes.bool.isRequired,
  hasContacts: PropTypes.bool.isRequired,
  hasReports: PropTypes.bool.isRequired,
  hasInsights: PropTypes.bool.isRequired,
  hasNetworkDirectory: PropTypes.bool.isRequired,
  hasPayerInvoices: PropTypes.bool.isRequired,
  currentNetworkId: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const networks = getEmployeeNetworks({ state });
  const firstNetworkId = _.get(networks, '[0].id');
  const isNetworkLead = _.get(state, 'globalState.currentProvider.group.provider_type', {}) === 'network_lead';

  return {
    isNetworkLead,
    groupId: state.session.groupId,
    group: state.groups.data.find((group) => group.id === state.session.groupId),
    clients: state.contacts && state.contacts.myContacts,
    firstNetworkId,
    hasDashboard: hasWorkflowViewIndex(state) && !isNetworkLead,
    hasContacts: hasContactsViewContacts(state) && !isNetworkLead,
    hasReports: hasReportsViewReports(state) && !isNetworkLead,
    hasInsights: hasInsightsViewInsights(state),
    hasNetworkDirectory: hasNetworkDirectoryAccess(state),
    hasPayerInvoices: hasPayerInvoicesRole(state),
    currentNetworkId: state.session.networkId || firstNetworkId,
  };
}

export default connect(mapStateToProps, {
  fetchGroup,
})(Home);
