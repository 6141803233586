import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';

export default class IndexDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = { scroll: null };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextId = _.get(nextProps, 'params.id', null);
    const prevId = _.get(this.props, 'params.id', null);
    const propsPage = _.get(this.props, 'paging.current_page');
    const nextPropsPage = _.get(nextProps, 'paging.current_page');

    if ((propsPage !== nextPropsPage) || (prevId && !nextId)) {
      this.scrollToPreviousPosition();
    }

    if (nextId && nextId !== prevId) {
      this.setState({ scroll: _.get(document.querySelector('.dashboard-inner-content'), 'scrollTop', null) });
    }
  }

  scrollToPreviousPosition() {
    const scrollTop = this.state.scroll || 0;
    this.setState({ scroll: null });
    setTimeout(() => {
      const element = document.querySelector('.dashboard-inner-content');
      if (element) {
        element.scrollTop = scrollTop;
      }
      return;
    }, 100);
  }

  render() {
    const {
      DetailView,
      IndexView,
      columnConfig,
      data,
      fetchGroupsUsers,
      filters,
      initialSort,
      isCaseMgmtProvider,
      isCC,
      isFetching,
      idPath,
      onCellClick,
      onFiltersChange,
      onNextClick,
      onPrevClick,
      paging,
      params,
      route,
      statuses,
      onLetterClick,
      isSuperNetwork,
      onClose,
      onToggle,
      open,
      showMoreFilters,
      columnLabels,
    } = this.props;

    const id = _.get(params, idPath, null);
    if (id) {
      return (
        DetailView
      );
    }
    return (
      <IndexView
        columnConfig={columnConfig}
        isFetching={isFetching}
        data={data}
        paging={paging}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
        onCellClick={onCellClick}
        initialSort={initialSort}
        isCC={isCC}
        isSuperNetwork={isSuperNetwork}
        onFiltersChange={onFiltersChange}
        filters={filters}
        statuses={statuses}
        isCaseMgmtProvider={isCaseMgmtProvider}
        route={route}
        onLetterClick={onLetterClick}
        fetchGroupsUsers={fetchGroupsUsers}
        onClose={onClose}
        onToggle={onToggle}
        open={open}
        showMoreFilters={showMoreFilters}
        columnLabels={columnLabels}
      />
    );
  }
}

IndexDetailView.propTypes = {
  DetailView: PropTypes.node.isRequired,
  IndexView: PropTypes.func.isRequired,
  columnConfig: PropTypes.array,
  data: PropTypes.array.isRequired,
  filters: PropTypes.array,
  idPath: PropTypes.string,
  isCaseMgmtProvider: PropTypes.bool,
  isCC: PropTypes.bool.isRequired,
  isSuperNetwork: PropTypes.bool,
  initialSort: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  onCellClick: PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  paging: PropTypes.shape({
    current_page: PropTypes.number,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  route: PropTypes.shape({
    status: PropTypes.string,
  }),
  statuses: PropTypes.array,
  onLetterClick: PropTypes.func,
  fetchGroupsUsers: PropTypes.func,
  onClose: PropTypes.func,
  onToggle: PropTypes.func,
  open: PropTypes.bool,
  showMoreFilters: PropTypes.bool,
  columnLabels: PropTypes.array,
};

IndexDetailView.defaultProps = {
  columnConfig: undefined,
  filters: undefined,
  isCaseMgmtProvider: undefined,
  isCC: false,
  isSuperNetwork: false,
  idPath: 'id',
  initialSort: undefined,
  onFiltersChange: undefined,
  route: undefined,
  statuses: undefined,
  onLetterClick: undefined,
  fetchGroupsUsers: undefined,
  onClose: undefined,
  onToggle: undefined,
  open: false,
  columnLabels: undefined,
  showMoreFilters: undefined,
};
