import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'common/utils/browserHistory';
import { tracker } from '@unite-us/client-utils';
import _ from 'lodash';

import { noneAssignedOption } from 'src/components/Dashboard/utils/filterDefaults';

// components
import { ScreeningDetailHOC } from 'pages/components';
import IndexDetailView from 'src/components/Dashboard/components/IndexDetailView';
import AllScreeningsIndex from 'src/components/Dashboard/components/Screenings/AllScreeningsIndex';
import DetailView from 'src/components/Dashboard/components/DetailView';

// actions
import { fetchDashboardScreenings } from 'actions/Screening/Group';
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';

// utils
import { isSuperNetwork } from 'src/components/Network/utils';
import { getDataOfPage, isDataValid } from 'src/components/Dashboard/utils/dataHelpers';
import { isCoordinationCenter } from 'common/utils/Group';
import { sortStatuses } from 'src/components/Dashboard/components/Referrals/SentReferrals/utils';
import { getGroupUsersOptions } from 'src/components/Groups/Users/utils';
import {
  getDashboardScreeningsFromState,
} from 'src/components/Screenings/utils';
import { DASHBOARD_VIEWS, DASHBOARD_EVENTS } from 'common/utils/EventTracker/utils/eventConstants';
import callOrLog from 'src/common/utils/callOrLog';
import labelCustomization from 'src/common/utils/Customization/labels';
import defaultLabels from 'src/constants/labels';

export class AllScreenings extends Component {
  constructor(props) {
    super(props);

    this.onCellClick = this.onCellClick.bind(this);
    this.onFiltersChange = this.onFiltersChange.bind(this);
    this.onLetterClick = this.onLetterClick.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.shouldFetch = this.shouldFetch.bind(this);
    this.fetchGroupsUsers = this.fetchGroupsUsers.bind(this);
    this.onBackClick = this.onBackClick.bind(this);

    this.state = {
      activeUsersInGroup: [],
      filters: {
        care_coordinator_users: _.get(props, 'filters.care_coordinator_users', []),
        statuses: _.get(props, 'filters.statuses', []),
        last_name_starts_with_letter: null,
      },
      page: 1,
      sidx: 'created_at',
      sord: 'desc',
    };
  }

  componentDidMount() {
    this.props.fetchProvidersUserCore({
      providers: this.props.groupId,
    }).then((response) => {
      if (response) {
        const { users: activeUsersInGroup } = response;
        this.setState({ activeUsersInGroup });
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const differentPage = _.get(this.props, 'screenings.currentPage', 1) !==
      _.get(nextProps, 'screenings.currentPage', 1);

    if (differentPage) {
      this.setState({ page: nextProps.screenings.currentPage });
    }

    if (!this.props.isFetching) {
      const needToRefetch = !this.props.refetch && nextProps.refetch;

      if (needToRefetch) {
        this.fetch();
      }
    }
  }

  onCellClick(row) {
    const { pathname } = this.props;
    const pagedData = getDataOfPage(this.props.screenings, this.state.page);
    const screening = pagedData.data[row];
    const id = _.get(screening, 'id');

    browserHistory.push(`${pathname}/${id}`);
  }

  onFiltersChange(key, activeFilters) {
    this.setState({
      page: 1,
      filters: {
        ...this.state.filters,
        [key]: activeFilters,
      },
    }, () => {
      callOrLog(() => {
        const filters = tracker.buildDashboardFilterPayload(this.state, this.props);
        this.context.eventTracker(DASHBOARD_EVENTS.screeningsFilter, filters);
      });
      this.fetch();
    });
  }

  onLetterClick(letter) {
    const newFilters = _.isEqual(letter, 'ALL') ?
      _.uuOmit(this.state.filters, 'last_name_starts_with_letter') :
      { ...this.state.filters, last_name_starts_with_letter: letter };

    this.setState({
      filters: newFilters,
    }, () => {
      callOrLog(() => this.context.eventTracker(DASHBOARD_EVENTS.letterFilter, {
        view: DASHBOARD_VIEWS.screenings,
        filters: {
          ...this.state.filters,
          ...newFilters,
        },
      }));

      this.fetch();
    });
  }

  onNextClick() {
    const page = this.state.page + 1;

    this.shouldFetch(page);
  }

  onPrevClick() {
    const page = this.state.page - 1;

    this.shouldFetch(page);
  }

  onBackClick() {
    const { params, selectedContactId } = this.props;
    const screening_id = _.get(params, 'screening_id');

    if (screening_id && selectedContactId) {
      browserHistory.push(`/facesheet/${selectedContactId}/forms`);
    } else {
      browserHistory.goBack();
    }
  }

  shouldFetch(page) {
    if (isDataValid(this.props.screenings, page)) {
      this.setState({ page });
    } else {
      this.fetch(page);
    }
  }

  fetch(page = 1) {
    const { groupId } = this.props;
    const { sidx, sord } = this.state;
    const filters = _.reduce(
      this.state.filters,
      (acc, f, key) => {
        if (f === 'all') {
          return acc;
        }
        return _.merge(acc, { [key]: f });
      },
      {},
    );

    if (filters.care_coordinator_users.includes('all')) {
      filters.care_coordinator_users = null;
    }
    if (filters.statuses.includes('all')) {
      filters.statuses = null;
    }

    const options = {
      filters: _.uuCompactArrayOrObject(filters),
      page,
      sidx,
      sord,
    };

    this.props.fetchDashboardScreenings({ groupId, options });
  }

  fetchGroupsUsers(search = '') {
    const { groupId } = this.props;
    return this.props.fetchProvidersUserCore({
      providers: groupId,
      options: { text: search },
    }).then((response) => {
      if (response) {
        const { users: activeUsersInGroup } = response;
        if (search === '') {
          return _.concat(
            [noneAssignedOption(this.state)],
            getGroupUsersOptions(activeUsersInGroup, this.state),
          );
        }

        return getGroupUsersOptions(activeUsersInGroup, this.state);
      }

      return [];
    });
  }

  render() {
    const {
      screenings,
      currentGroup,
      groupId,
      isCC,
      isFetching,
      params,
      statuses,
      labels,
    } = this.props;
    const pagedData = getDataOfPage(screenings, this.state.page);
    const { activeUsersInGroup } = this.state;

    const options = _.filter(sortStatuses(statuses), (s) => (
      !['Status (All)', 'Exempted', 'In Progress'].includes(s.label)
    ));
    const filters = [
      {
        key: 'care_coordinator_users',
        name: labels.CareCoordinator,
        options: getGroupUsersOptions(activeUsersInGroup, this.state),
        pluralName: `${labels.CareCoordinator}s`,
      },
      {
        key: 'statuses',
        name: 'Status',
        options,
        single: false,
        value: this.state.filters.status,
      },
    ];

    const innerContentView = (
      <ScreeningDetailHOC
        params={params}
        showContactColumn
        type="allScreenings"
      />
    );

    const allScreeningsDetailView = (
      <div className="mt-4">
        <DetailView
          innerContentView={innerContentView}
          hideMessageCenterButton
          onBackClick={this.onBackClick}
        />
      </div>
    );

    return (
      <IndexDetailView
        data={pagedData.data}
        DetailView={allScreeningsDetailView}
        filters={filters}
        IndexView={AllScreeningsIndex}
        initialSort={{ [this.state.sidx]: this.state.sord }}
        isCC={isCC}
        isFetching={isFetching}
        isSuperNetwork={isSuperNetwork(currentGroup.networks, groupId)}
        onCellClick={this.onCellClick}
        onFiltersChange={this.onFiltersChange}
        onLetterClick={this.onLetterClick}
        onNextClick={this.onNextClick}
        onPrevClick={this.onPrevClick}
        paging={pagedData.paging}
        params={params}
        statuses={statuses}
        idPath="screening_id"
        fetchGroupsUsers={this.fetchGroupsUsers}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const pathname = _.get(ownProps, 'location.pathname');
  const groupId = _.get(state, 'session.groupId');
  // CORE-WORK - Need to resolve user.groups migration work
  const userGroups = _.get(state, 'groups.data', []);
  const currentGroup = _.find(userGroups, { id: groupId }) || {};
  const screenings = getDashboardScreeningsFromState(state);
  const filters = _.get(screenings, 'filters', {});
  const isCC = isCoordinationCenter(_.get(currentGroup, 'networks', []), groupId);
  const isFetching = _.get(screenings, 'isFetching', false);
  const refetch = _.get(state, 'dashboard.refetch');
  const token = _.get(state, 'session.token', '');
  const userId = _.get(state, 'user.id', null);
  const selectedContactId = _.get(state, 'selectedContact', '');

  const statuses = _.get(state, 'session.enums.screenings.statuses', []);

  return {
    screenings,
    currentGroup,
    filters,
    groupId,
    isCC,
    isFetching,
    pathname,
    refetch,
    statuses,
    token,
    userId,
    selectedContactId,
    labels: labelCustomization(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({
      fetchDashboardScreenings,
      fetchProvidersUserCore,
    }, dispatch),
    dispatch,
  };
}

AllScreenings.propTypes = {
  screenings: PropTypes.object.isRequired,
  currentGroup: PropTypes.object.isRequired,
  fetchDashboardScreenings: PropTypes.func.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isCC: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  refetch: PropTypes.bool.isRequired,
  statuses: PropTypes.array.isRequired,
  selectedContactId: PropTypes.string.isRequired,
  labels: PropTypes.object,
};

AllScreenings.defaultProps = {
  labels: defaultLabels,
};

AllScreenings.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllScreenings);
