import { every } from 'lodash';
import { isReferralsAdminAndAbove } from 'src/components/User/utils';

// get network with id referral.sending_network
// this will have relationships coordination_centers
// check if coordination_centers includes my provider (groupId)
const doesCCcoordinateNetwork = (referral, groupId) => {
  const { sending_network: { coordination_centers } } = referral;
  return coordination_centers.find((c) => c.id === groupId);
};

export default function canReject({
  user,
  referral,
  groupId,
  isCC,
}) {
  const {
    case: {
      outcome: caseOutcome,
      state: caseState,
    },
    state: referralState,
  } = referral;

  if (caseState === 'draft') {
    return null;
  }

  if (isCC && referral.sending_provider.id === groupId) {
    return false;
  }

  const checks = [
    isReferralsAdminAndAbove(user, groupId),
    ['sent', 'in_review'].includes(referralState),
    caseOutcome === null,
    (referral.receiving_provider.id === groupId) ||
    (isCC && doesCCcoordinateNetwork(referral, groupId)),
    // TODO: can CCs reject a referral? Product question
  ];

  return every(checks);
}
