import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DialogV2 from 'src/common/modal/DialogV2';
import parse from 'html-react-parser';
import constructPlanFormPayload from 'src/pages/backoffice/payers/_id/utils/constructPlanFormPayload';
import { useUpdatePlan } from 'src/components/Backoffice/api/hooks/v1/planHooks';
import EditMetafields from 'src/components/Backoffice/metafields/EditMetafields';
import DynamicDataTable from 'src/components/Backoffice/dataTables/DynamicDataTable';
import { Icon } from '@unite-us/ui';
import { theme } from 'src/../tailwind.config';
import './PlanDetail.scss';

const PlanAttachments = ({ attachments, plan }) => {
  const EditAttachmentModelRef = useRef(null);
  const [newAttachments, setNewAttachments] = useState([...attachments]);
  const { updateRecord: updatePlan } = useUpdatePlan();
  const closeModal = () => {
    setNewAttachments(attachments);
    EditAttachmentModelRef.current.closeDialog();
  };
  const payerId = plan.payer?.id;
  const openModal = () => {
    EditAttachmentModelRef.current.openDialog();
  };

  const addAttachments = async () => {
    const customFieldsForPlans =
    plan.metafields.filter((metafield) => metafield.type !== 'file');
    const newPlan = {
      ...plan,
      metafields: [...customFieldsForPlans, ...newAttachments],
    };

    const payload = constructPlanFormPayload(newPlan, payerId);
    const response = await updatePlan(newPlan.id, payload);
    if (response) {
      closeModal();
    }
  };

  return (
    <>
      <DynamicDataTable
        title="Attachments"
        button={{
          label: 'EDIT ATTACHMENTS',
          icon: <Icon color={theme.extend.colors['current-blue']} icon="V2Edit" />,
          onClick: openModal,
        }}
        table={{
          headers: ['Name', 'Requirement', 'Description'],
          data: attachments.map((attachment) => ({
            rowId: attachment.id,
            data: {
              name: attachment.label,
              requirement: (attachment.validations && attachment.validations.required) ? 'Required' : 'Optional',
              description: (attachment.help_text) ? parse(attachment.help_text) : '',
            },
          })),
          emptyTableMessage: 'Click "Edit Attachments" to add attachments',
        }}
      />
      <DialogV2
        cancelHandler={closeModal}
        confirmationHandler={addAttachments}
        confirmLabel="Save"
        ref={EditAttachmentModelRef}
        title="Add/Edit Attachments"
        vertical
        dialogDescriptionClass={'flex-auto overflow-y-auto bg-medium-fill-blue'}
      >
        <EditMetafields
          metafields={attachments}
          setMetafieldsToSave={setNewAttachments}
          addButtonLabel={'ADD ATTACHMENT'}
          formsTitle={'Attachment'}
          hideSelectField
          defaultFields={{ type: 'file' }}
        />
      </DialogV2>
    </>
  );
};

PlanAttachments.propTypes = {
  attachments: PropTypes.array.isRequired,
  plan: PropTypes.object.isRequired,
};

export default PlanAttachments;
