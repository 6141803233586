// converts Core paging response to app's structure
const convertCorePaging = (page) => ({
  current_page: page.number,
  next_page: parseInt(page.number, 10) === parseInt(page.total_pages, 10) ? null : parseInt(page.number, 10) + 1,
  prev_page: parseInt(page.number, 10) === 1 ? null : parseInt(page.number, 10) - 1,
  per: page.size,
  total_count: page.total_count,
  total_pages: page.total_pages,
});

export default convertCorePaging;
