import { ADD_NOTIFICATION } from '../../../../actions';

export default function addNotification(notification) {
  return (dispatch) => {
    dispatch({
      type: ADD_NOTIFICATION,
      payload: notification.payload,
    });
  };
}
