import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import './stylesheets/detail-view.scss';

class DetailUnorderedList extends PureComponent {
  render() {
    const { title, list = [], displayProp } = this.props;
    const itemList = list.length === 0 ?
      <em>No items</em> :
      list.map((item, index) => (
        <li key={index}>
          {item[displayProp] || item}
        </li>
      ));

    return (
      <div className="detail-unordered-list detail-info__groups">
        <h5 className="detail-unordered-list__title detail-info__icon-label detail-review__label">
          {title}
        </h5>
        <ul className="detail-unordered-list__list detail-info__groups-list list-unstyled">
          {itemList}
        </ul>
      </div>
    );
  }
}

DetailUnorderedList.propTypes = {
  displayProp: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default DetailUnorderedList;
