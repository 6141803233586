import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import {
  Button,
  BaseCard,
  BaseCardHeader,
  BaseCardBody,
  Icon,
} from '@unite-us/ui';
import { get, isFunction } from 'lodash';
import { connect } from 'react-redux';
import { BackButton } from 'common/buttons';
import addNotification from 'common/utils/Notifications/actions/AddNotification';
import callOrLog from 'src/common/utils/callOrLog';
import Notifier from 'common/helpers/Notifier';
import { useCreateRecord, useFind, useFindRecord, useInvalidateQueries } from 'src/api/APIHooks';
import { ASSESSMENTS } from 'common/utils/EventTracker/utils/eventConstants';
import SurveyJSFormRenderer from '@unite-us/surveyjs/dist/components/Renderer/SurveyJSFormRenderer';
import buildResponses from '@unite-us/surveyjs/dist/components/Renderer/buildResponses';
import { crtb1121HideFieldInForm } from 'common/utils/FeatureFlags/flags';
import '@unite-us/surveyjs/src/components/Renderer/surveyjs-styles.scss';
import '../stylesheets/contacts.scss';
import './AssessmentDetailsView.scss';

const contextTypesModel = {
  referral: 'referral',
  case: 'case',
  assistance_request: 'assistance_request',
  person: 'person',
  'Screenings::Screening': null,
};

const SUCCESS_MESSAGE = 'Form Response Saved Successfully';

export function AssessmentDetailsView({
  assistanceRequest,
  assessment,
  editMode,
  showEditButton,
  onEditClick,
  onCancel,
  afterSubmit,
  contactId,
  contextType,
  context: contextModelId,
  providerId,
  currentEmployee,
  submissionId,
  hideFieldInForm,
}, context) {
  const surveyRef = useRef();
  const [isSaving, setIsSaving] = useState(false);

  const formId = assessment.id;

  const viewMode = !!submissionId;

  const assessmentConfiguration = assessment.configuration;

  if (hideFieldInForm) {
    const featureFlagQuestion = assessmentConfiguration.pages[0].elements.find((e) => (
      e.name === 'crtb-1121-hide-field-in-form'
    ));

    if (featureFlagQuestion) {
      const questionIdToHide = featureFlagQuestion.html;
      assessmentConfiguration.pages = assessmentConfiguration.pages.map((page) => ({
        ...page,
        elements: page.elements.map((element) => {
          if (element.id === questionIdToHide) {
            return { ...element, visible: false };
          }
          return element;
        }),
      }));
    }
  }

  // SurveyJs needs title and description at the same level as pages
  const surveyJSON = {
    configuration: {
      title: assessment.name,
      description: assessment.description,
      ...assessmentConfiguration,
    },
  };

  const invalidateQueries = useInvalidateQueries();

  const { createRecord: createFormSubmission } = useCreateRecord('form_submission', {
    api: 'coreApi',
  });

  // Load all the submissions to get the latest (Create new submission flow)
  const { data: formSubmissionsData, isFetching, refetch: refetchFormSubmission } = useFind('form_submission', {
    'context.type': contextType,
    context: contextModelId,
    form: formId,
    'form.form_usages.provider': providerId,
  }, {
    page: {
      number: 1,
      size: 1,
    },
    api: 'coreApi',
    queryConfig: {
      refetchOnWindowFocus: false,
      enabled: !viewMode,
      refetchInterval: false,
      staleTime: Infinity,
      placeholderData: undefined,
    },
  });

  // load the form submission by id (View Submission Flow)
  const { data: formSubmissionData, isFetching: isFetchingSubmission } = useFindRecord(
    'form_submission',
    submissionId,
    {
      page: {
        number: 1,
        size: 1,
      },
      api: 'coreApi',
      queryConfig: {
        refetchOnWindowFocus: false,
        enabled: viewMode,
        refetchInterval: false,
        staleTime: Infinity,
        placeholderData: undefined,
      },
    },
  );

  const { data: formUsageData, isFetching: isFetchingUsage } = useFind('form_usage', {
    provider: providerId,
    form: formId,
  }, {
    page: {
      number: 1,
      size: 1,
    },
    api: 'coreApi',
    queryConfig: {
      refetchOnWindowFocus: false,
      enabled: !viewMode,
      refetchInterval: false,
      staleTime: Infinity,
      placeholderData: undefined,
    },
  });

  let formSubmission = null;
  if (viewMode) {
    formSubmission = get(formSubmissionData, 'data.data');
  } else {
    formSubmission = get(formSubmissionsData, 'data.data[0]');
  }

  const handleSetSurvey = (survey) => {
    surveyRef.current = survey;
  };

  const handleOnCancel = () => {
    if (isFunction(onCancel)) {
      onCancel();
    }
  };

  const handleOnEditClick = () => {
    if (isFunction(onEditClick)) {
      onEditClick();
    }
  };

  const handleOnSubmit = async () => {
    const survey = surveyRef.current;
    if (survey.completeLastPage() && !survey.hasErrors()) {
      callOrLog(() => context.eventTracker(ASSESSMENTS.updatedFromDetailView, {
        context: contextModelId,
        contact_id: contactId,
        contextType,
      }, { assistanceRequest, assessment }));

      // Default save the submission, if want to change it you can pass the onSubmit to override
      const responses = buildResponses(surveyRef.current);
      setIsSaving(true);
      handleOnEditClick();
      let formSubmissionId;
      const formUsage = get(formUsageData, 'data.data', [])[0];
      await createFormSubmission({
        responses,
        form: formId,
        provider: providerId,
        contextType: contextTypesModel[contextType],
        context: contextModelId,
        submitter: currentEmployee.id,
      }, {
        mutationConfig: {
          onSuccess: (result) => {
            invalidateQueries('form_submission');
            Notifier.dispatch('success', SUCCESS_MESSAGE);
            formSubmissionId = result.data.data.id;
          },
        },
      });

      refetchFormSubmission();
      setIsSaving(false);

      if (isFunction(afterSubmit)) afterSubmit(formSubmissionId, formUsage.usage_type);
    }
  };

  const iconDiv = (
    <div
      id="assessment-edit-btn"
      onClick={handleOnEditClick}
      role="button"
      onKeyDown={handleOnEditClick}
      tabIndex={0}
    >
      <Icon icon="IconPencil" style={{ svg: { width: '1em', height: '1em' } }} />
      <p className="assessment-edit-btn__text">Edit</p>
    </div>
  );

  const subheaderText = get(assessment, 'notes_text');

  const title = (
    <span className="assessment-details-view__header">
      {get(assessment, 'name', '')}
      {
        subheaderText && (
          <small className="assessment-details-view__subheader">
            {`(${subheaderText})`}
          </small>
        )
      }

    </span>
  );

  const isLoading = isFetching || isFetchingSubmission || isFetchingUsage;

  return (
    <div className="container-fluid assessment-details-view">
      <div className="row">
        <div className="col-sm-10 offset-sm-1 assessments-show">
          <BackButton />
          <BaseCard>
            <BaseCardHeader
              title={title}
            >
              {(!editMode && showEditButton) && iconDiv}
            </BaseCardHeader>
            <BaseCardBody withPadding>
              {isLoading && 'Loading...'}
              {isSaving && 'Saving...'}
              {(!isFetching && !isSaving) && (
                <SurveyJSFormRenderer
                  formData={surveyJSON}
                  formSubmission={formSubmission}
                  editMode={editMode}
                  handleSetSurveyModel={handleSetSurvey}
                />
              )}
              <div className="assessment-details-view__actions">
                {editMode && !isFetching && !isSaving && (
                  <>
                    <Button
                      id="cancel-btn"
                      className="form-cancel-button"
                      label="Cancel"
                      onClick={handleOnCancel}
                    />
                    <Button
                      id="save-btn"
                      className="form-submit-button ml-one"
                      label="Save"
                      onClick={handleOnSubmit}
                      primary
                    />
                  </>
                )}
              </div>
            </BaseCardBody>
          </BaseCard>
        </div>
      </div>
    </div>
  );
}

AssessmentDetailsView.propTypes = {
  assessment: PropTypes.object.isRequired,
  assistanceRequest: PropTypes.object,
  contactId: PropTypes.string,
  editMode: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onEditClick: PropTypes.func,
  showEditButton: PropTypes.bool,
  context: PropTypes.string,
  contextType: PropTypes.string,
  providerId: PropTypes.string.isRequired,
  currentEmployee: PropTypes.object.isRequired,
  afterSubmit: PropTypes.func,
  submissionId: PropTypes.string,
  hideFieldInForm: PropTypes.bool,
};

AssessmentDetailsView.defaultProps = {
  assistanceRequest: undefined,
  contactId: undefined,
  showEditButton: true,
  onCancel: null,
  onEditClick: null,
  afterSubmit: null,
  submissionId: null,
  context: null,
  contextType: null,
  hideFieldInForm: false,
};

AssessmentDetailsView.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  providerId: state.session.groupId,
  currentEmployee: state.globalState.currentEmployee,
  hideFieldInForm: crtb1121HideFieldInForm(state),
});

export default connect(mapStateToProps, {
  addNotification,
})(AssessmentDetailsView);
