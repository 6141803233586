import React from 'react';
import PropTypes from 'prop-types';
import { LogoCard } from 'src/common/TailwindComponents';
import InvoiceWorkqueue from './InvoiceWorkqueue';
import { NoFilteredInvoicesFound } from '../components';

const NoInvoicesExist = () => (
  <>
    <h4 className="font-heavy-font text-base text-dark-grey">
      You don&apos;t have any invoices yet.
    </h4>
    <p className="max-w-xs text-dark-grey">
      Invoices are added to this list whenever a contracted service has been submitted for a client.
    </p>
  </>
);

const ArchivedInvoices = ({ children }) => {
  const bulkActions = ['IconDownloadV2', 'IconUndo'];

  return (
    <InvoiceWorkqueue
      archived
      bulkActions={bulkActions}
      invoiceStatus=""
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <LogoCard>
          {isFiltered ? (
            <NoFilteredInvoicesFound resetFilters={resetFilters} />
          ) : (
            <NoInvoicesExist />
          )}
        </LogoCard>
      )}
      showStatusFilter
      path="archived/"
    >
      {children}
    </InvoiceWorkqueue>
  );
};

ArchivedInvoices.propTypes = {
  children: PropTypes.node,
};

ArchivedInvoices.defaultProps = {
  children: undefined,
};
export default ArchivedInvoices;
