import {
  updateDataDogSessionUser,
} from 'src/components/App/actions/dataDogHelper';
import { updateGlobalState } from 'src/actions/Global/globalActions';

export default function setCurrentProvider(providerId) {
  return (dispatch, getState) => {
    const state = getState();
    const { globalState: { employees, providers } } = state;
    const currentProvider = providers.find(
      (provider) => provider.group.id === providerId,
    );
    const currentEmployee = employees.find(
      (employee) => employee.provider.id === providerId,
    );
    updateDataDogSessionUser({
      employee: currentEmployee.id,
      email: currentEmployee.email,
      provider: currentEmployee.provider.id,
      name: currentEmployee.full_name,
    });

    return dispatch(updateGlobalState({
      currentProvider,
      currentEmployee,
    }));
  };
}
