import { ADD_CASE_DOCUMENTS } from 'actions';
import Notifier from 'common/helpers/Notifier';
import uploadAndAttachDocumentsToResources from
  'common/form/DocumentUploader/actions/uploadAndAttachDocumentsToResources';
import { convertCoreDocuments } from 'src/api/utils/fileUploadUtils';
import { get, map } from 'lodash';

function attachCaseDocuments({
  caseId,
  contactId,
  groupId,
  attachedDocuments = [],
  uploadedDocuments = [],
}) {
  return (dispatch) => {
    function onSuccess([attachDocsPayload, uploadDocsPayload]) {
      const docsToUpload = map(uploadDocsPayload, (doc) => get(doc, 'data.data', {}));
      const uploadedDocs = convertCoreDocuments(docsToUpload);

      dispatch({
        type: ADD_CASE_DOCUMENTS,
        caseId,
        contactId,
        documents: [
          ...attachedDocuments,
          ...uploadedDocs,
        ],
      });
      return [attachDocsPayload, uploadDocsPayload];
    }

    function onError(error) {
      Notifier.handleErrors(error);
      return error;
    }

    return uploadAndAttachDocumentsToResources({
      contactId,
      groupId,
      attachedDocuments,
      uploadedDocuments,
      caseId,
    })
      .then(onSuccess, onError);
  };
}

export default attachCaseDocuments;
