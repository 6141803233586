import { compact } from 'lodash';

function removeParentheses(path = '') {
  const regex = / *\([^)]*\) */g;
  return path.replace(regex, '');
}

function createId(path) {
  const regex = /[^a-zA-Z\d]/g;
  const pathParts = removeParentheses(path).split(regex);
  return compact(pathParts).join('-');
}

export default createId;
