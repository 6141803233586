import {
  FETCH_GROUPS_ROLES,
  CREATE_GROUPS_ROLE,
  UPDATE_GROUPS_ROLE,
  DELETE_GROUPS_ROLE,
} from 'actions';

export default function groupsRolesReducer(state = [], action) {
  switch (action.type) {
    case FETCH_GROUPS_ROLES:
      return action.payload.data.data;
    case CREATE_GROUPS_ROLE:
      return [
        ...state,
        action.payload.data.data,
      ];
    case UPDATE_GROUPS_ROLE: {
      const response = action.payload.data.data;
      return state.map((role) => {
        if (role.id === response.id) {
          return response;
        }

        return role;
      });
    }
    case DELETE_GROUPS_ROLE: {
      return state.filter((role) => role.id !== action.roleId);
    }
    default:
      return state;
  }
}
