import {
  ARtoCaseStepperConfig,
  ARtoReferralStepperConfig,
  contactsStepperConfig,
  intakesStepperConfig,
  referralsStepperConfig,
  screeningsStepperConfig,
  screeningToreferralStepperConfig,
  facesheetReferralStepperConfig,
  referralsSuperSetStepperConfig,
  draftOrgStepperConfig,
} from '../config';

const configDictionary = {
  'assistance-requests/contacts': ARtoCaseStepperConfig,
  'assistance-requests/referrals': ARtoReferralStepperConfig,
  contacts: contactsStepperConfig,
  intakes: intakesStepperConfig,
  referrals: referralsStepperConfig,
  referralsSuperset: referralsSuperSetStepperConfig,
  screenings: screeningsStepperConfig,
  'screenings/referral': screeningToreferralStepperConfig,
  'facesheet-referral': facesheetReferralStepperConfig,
  organization: draftOrgStepperConfig,
};

function getStepperConfig(context, isSupersetEnabled) {
  if (context === 'referrals' && isSupersetEnabled) {
    return configDictionary.referralsSuperset;
  }

  return configDictionary[context];
}

export default getStepperConfig;
