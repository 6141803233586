import PropTypes from 'prop-types';
import React from 'react';
import { shapes } from '@unite-us/client-utils';
import { get, isEmpty } from 'lodash';
import { getScreeningStatus } from 'src/components/Screenings/utils';
import { Spinner } from 'common/spinners';
import { DynamicTable } from 'common/tables';
import defaultLabels from 'src/constants/labels';
import FilterBar from '../FilterBar';
import DashboardRowIcon from '../DashboardRowIcon';
import { createAlphabet } from '../../utils/filter';

function getColumns(statuses) {
  return [
    {
      label: 'Client Name',
      sortColumn: 'contact.last_name_first_name',
    },

    {
      label: 'Client DoB',
      sortColumn: 'contact.date_of_birth',
      type: 'dateOfBirth',
      columnWidth: 'auto',
      align: 'left',
    },

    {
      label: defaultLabels.CareCoordinator, /* Customization net yet required */
      sortColumn: 'contact.care_coordinator.user.last_name_first_name',
    },
    {
      label: 'Status',
      sortColumn: 'status',
      type: 'statusExtended',
      formatData: (data) => getScreeningStatus({ value: get(data, 'status'), statusEnums: statuses }),
      columnWidth: 'auto',
      align: 'left',
    },
    {
      label: 'Date Created',
      sortColumn: 'created_at',
      type: 'date',
    },
  ];
}

function AllScreeningsIndex(props) {
  const {
    data,
    filters,
    initialSort,
    isFetching,
    onCellClick,
    onFiltersChange,
    onLetterClick,
    onNextClick,
    onPrevClick,
    paging,
    statuses,
    fetchGroupsUsers,
  } = props;

  return (
    <div className="all-screenings-index">
      <FilterBar
        filters={filters}
        isFetching={isFetching && !isEmpty(data)}
        itemLabel="Filter by Last Name:"
        items={createAlphabet()}
        onFiltersChange={onFiltersChange}
        onItemClick={onLetterClick}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
        paging={paging}
        showItemFilter
        asyncSearch={fetchGroupsUsers}
      />
      <div className="dashboard-inner-content">
        {
          (isFetching && isEmpty(data)) ?
            <Spinner /> : (
              <DynamicTable
                columnLabels={getColumns(statuses)}
                dataSet={data}
                emptyMessage="There are no screenings."
                id="all-screenings-table"
                initialSort={initialSort}
                onCellClick={onCellClick}
                rowIcon={DashboardRowIcon}
              />
            )
        }
      </div>
    </div>
  );
}

AllScreeningsIndex.propTypes = {
  data: PropTypes.array.isRequired,
  filters: PropTypes.array,
  initialSort: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  onCellClick: PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  onLetterClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  paging: shapes.paging,
  statuses: PropTypes.array.isRequired,
  fetchGroupsUsers: PropTypes.func.isRequired,
};

export default AllScreeningsIndex;
