import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PersonalInformationDisplay from './components/Displays/PersonalInformationDisplay';

class PersonalInformation extends Component {
  render() {
    const { employee } = this.props;
    return (
      <div className="user-personal-information editable-panel">
        <h3 className="section-header">Personal Information</h3>
        <div
          data-role="personal-information"
        >
          <PersonalInformationDisplay
            employee={employee}
            closeModal={this.closeModal}
          />
        </div>
      </div>
    );
  }
}

PersonalInformation.propTypes = {
  employee: PropTypes.object.isRequired,
};

export default PersonalInformation;
