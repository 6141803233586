function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { flattenDeep, reduce, sortBy, uniqBy } from 'lodash';
import { uuOmit } from "../../utils/extendLodash";
var flattenServiceTypes = function flattenServiceTypes() {
  var structuredServiceTypes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var keepParent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var flatArrayServiceTypes = flattenDeep(structuredServiceTypes);
  var flatServiceTypes = reduce(flatArrayServiceTypes, function (acc, st) {
    if (st && st.children && st.children.length) {
      if (keepParent) {
        var stNoChildren = uuOmit(st, 'children');
        return [].concat(_toConsumableArray(acc), [stNoChildren], _toConsumableArray(st.children));
      }
      return [].concat(_toConsumableArray(acc), _toConsumableArray(st.children));
    }
    return [].concat(_toConsumableArray(acc), [st]);
  }, []);
  var uniqueServiceTypes = uniqBy(flatServiceTypes, 'id');
  return sortBy(uniqueServiceTypes, 'name', 'asc');
};
export default flattenServiceTypes;