import { uuOmit, values, remove, isUndefined } from 'lodash';

const isReallyEmpty = (obj) => {
  const newObj = uuOmit(obj, ['id']);
  const newObjValues = values(newObj);
  const removedEmptyValues = remove(
    newObjValues,
    (value) => value && !isUndefined(value) && value !== '',
  );

  return removedEmptyValues.length <= 0;
};

export default isReallyEmpty;
