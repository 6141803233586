import hasConsent from 'src/components/Contacts/utils/hasConsent';
import { browserHistory } from 'common/utils/browserHistory';
import querystring from 'query-string';

function consentUrl({ itemType = 'contact', contactId }) {
  const baseUrl = `/consent/${contactId}/${itemType}`;

  return function handleAllowSkipConsent(queryObject = {}) {
    return `${baseUrl}?${querystring.stringify(queryObject)}`;
  };
}

function enforcedConsentRoute({
  allowSkipConsent = true,
  contact,
  itemType,
  to,
}) {
  if (hasConsent(contact)) {
    return browserHistory.push(to);
  }
  const redirectUrl = consentUrl({
    itemType,
    contactId: contact?.id,
  })({
    fullName: contact?.full_name,
    allowSkipConsent,
    to,
  });
  return browserHistory.push(redirectUrl);
}

export default enforcedConsentRoute;
