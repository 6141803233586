import PropTypes from 'prop-types';
import React from 'react';
import EditModal from 'common/modal/EditModal';
import EditAddress from 'common/form/Profile/EditAddress/index';
import Address from 'common/display/Address';
import { EDIT_ADDRESS_FIELDS } from 'common/display/Profile/constants/form';
import RowDisplay from 'common/display/Profile/RowDisplay';
import { addresses as addressesUtils } from '@unite-us/client-utils';

const Addresses = (props) => {
  const {
    addresses,
    addressTypes,
    deleteAddress,
    groupId,
    updateOrCreateAddress,
    lastUpdate,
    shouldShowLastUpdate,
  } = props;

  const editSection = (
    <EditModal
      header="Edit Address"
      size="large"
      formName={EDIT_ADDRESS_FIELDS}
      buttonId="edit-address-btn"
      id="edit-address-modal"
    >
      <EditAddress
        addresses={addresses}
        groupId={groupId}
        updateOrCreateAddress={updateOrCreateAddress}
        deleteAddress={deleteAddress}
        addressTypes={addressTypes}
      />
    </EditModal>
  );

  return (
    <RowDisplay
      editSection={editSection}
      label="Address"
      lastUpdate={lastUpdate}
      showLastUpdate={shouldShowLastUpdate}
    >
      {
        addressesUtils.sortAddresses(addresses).map((address = {}, i) => (
          <div className="col-sm-6" key={i}>
            <Address item={address} />
          </div>
        ))
      }
    </RowDisplay>
  );
};

Addresses.propTypes = {
  addresses: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  deleteAddress: PropTypes.func.isRequired,
  updateOrCreateAddress: PropTypes.func.isRequired,
  addressTypes: PropTypes.array.isRequired,
  lastUpdate: PropTypes.object,
  shouldShowLastUpdate: PropTypes.bool,
};

Addresses.defaultProps = {
  addresses: [],
  lastUpdate: {
    recently_updated_by_name: null,
    recently_updated_at: null,
  },
  shouldShowLastUpdate: false,
};

export default Addresses;
