import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Expandable,
} from '@unite-us/ui';
import { debounce, noop } from 'lodash';
import {
  getServiceErrorCount,
} from 'src/components/Referrals/ReferralServicesForm/utils/errorHandling';
import {
  ReferralServiceCardTitle,
  ReferralServiceFormExpanded,
} from './components';
import { targetIsIcon } from './utils';
import './stylesheets/referralServiceFormCard.scss';

class ReferralServiceFormCard extends Component {
  constructor(props) {
    super(props);
    this.onHeaderClick = this.onHeaderClick.bind(this);
    this.toggleExpanded = debounce(this.toggleExpanded.bind(this), 300);
  }

  onHeaderClick(e) {
    e.stopPropagation();
    // Do not toggle when clicking trash icon.
    if (!targetIsIcon(e)) {
      this.toggleExpanded();
    }
  }

  toggleExpanded() {
    this.props.onToggleExpanded(this.props.index);
  }

  render() {
    const {
      activeUsersInGroup,
      assistanceRequest,
      canDelete,
      canShowOONCaseContext,
      currentServiceTypeIndex,
      fetchGroupsUsers,
      fields,
      form,
      fromAssistanceRequest,
      index,
      isExpanded,
      isProgramBasedSearch,
      networks,
      onCancel,
      onForceShowOONCaseContext,
      referralTitle,
      registerField,
      showOONCaseContext,
      touch,
      untouch,
      hasBeenExpanded,
      setDisableNextButton,
      setHasSensitiveErrors,
      unregisterField,
    } = this.props;

    return (
      <Expandable
        className="referral-service-form-card"
        expanded={isExpanded}
        header={(
          <ReferralServiceCardTitle
            canDelete={canDelete}
            errorCount={getServiceErrorCount({ networks, service: fields })}
            index={index}
            isExpanded={isExpanded}
            onDelete={this.props.onDelete}
            title={referralTitle}
            hasBeenExpanded={hasBeenExpanded}
          />
        )}
        id={`${index}-header`}
        onExpandChange={this.toggleExpanded}
      >
        <ReferralServiceFormExpanded
          activeUsersInGroup={activeUsersInGroup}
          assistanceRequest={assistanceRequest}
          canDelete={canDelete}
          canShowOONCaseContext={canShowOONCaseContext}
          fetchGroupsUsers={fetchGroupsUsers}
          fields={fields}
          fromAssistanceRequest={fromAssistanceRequest}
          isProgramBasedSearch={isProgramBasedSearch}
          index={index}
          networks={networks}
          onCancel={onCancel}
          onForceShowOONCaseContext={onForceShowOONCaseContext}
          registerField={registerField}
          showOONCaseContext={showOONCaseContext}
          touch={touch}
          untouch={untouch}
          setDisableNextButton={setDisableNextButton}
          setHasSensitiveErrors={setHasSensitiveErrors}
          form={form}
          currentServiceTypeIndex={currentServiceTypeIndex}
          unregisterField={unregisterField}
        />
      </Expandable>
    );
  }
}

ReferralServiceFormCard.propTypes = {
  activeUsersInGroup: PropTypes.array,
  assistanceRequest: PropTypes.object,
  canDelete: PropTypes.bool,
  canShowOONCaseContext: PropTypes.bool,
  currentServiceTypeIndex: PropTypes.number.isRequired,
  fetchGroupsUsers: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  fromAssistanceRequest: PropTypes.bool,
  index: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool,
  isProgramBasedSearch: PropTypes.bool.isRequired,
  networks: PropTypes.array,
  onDelete: PropTypes.func,
  onForceShowOONCaseContext: PropTypes.func,
  onToggleExpanded: PropTypes.func,
  referralTitle: PropTypes.string,
  registerField: PropTypes.func.isRequired,
  showOONCaseContext: PropTypes.bool,
  untouch: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  hasBeenExpanded: PropTypes.bool,
  setDisableNextButton: PropTypes.func.isRequired,
  setHasSensitiveErrors: PropTypes.func.isRequired,
  unregisterField: PropTypes.func,
};

ReferralServiceFormCard.defaultProps = {
  activeUsersInGroup: [],
  canDelete: false,
  canShowOONCaseContext: false,
  fromAssistanceRequest: false,
  isExpanded: false,
  networks: [],
  onForceShowOONCaseContext: noop,
  onToggleExpanded: noop,
  referralTitle: 'Referral',
  showOONCaseContext: false,
  validOONCaseContext: false,
  hasBeenExpanded: false,
  unregisterField: noop,
};

export default ReferralServiceFormCard;
