import {
  CREATE_SERVICE_CASE,
  CREATE_SERVICE_CASE_SET_SUBMITTING,
  CREATE_SERVICE_CASE_UNSET_SUBMITTING,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import { coreApi } from 'src/api/config';
import { pays5590RefactorInsuranceFilters } from 'src/common/utils/FeatureFlags/flags';
import { feeSchedulePrograms as fspUtils } from '@unite-us/client-utils';
import fetchInsurances from 'src/api/core/Insurances/fetchInsurances';

const iso8601Date = (date) => moment.unix(date).toISOString();

const parseAuthorizationParams = (params) => {
  const authorizationRequest = get(params, 'authorization_request', {});
  const serviceStart = get(authorizationRequest, 'service_start', null);
  const amountRequested = get(authorizationRequest, 'amount_requested', null);

  if (serviceStart == null || amountRequested == null) {
    return {};
  }

  return authorizationRequest;
};

const createCase = async (params, groupId, contactId, isOONCase) => {
  const isOON = isOONCase || params.program.program_name === 'Referred Out of Network';

  let caseState = isOON ? 'off_platform' : 'managed';
  if (!isEmpty(parseAuthorizationParams(params))) {
    caseState = 'pending_authorization';
  }

  return coreApi.createRecord('case', {
    program_enrollment_date: params.program.entry_date,
    description: params.service_case.description,
    state: caseState,
    ...(isOON ? {} : { opened_date: moment.unix(params.program.entry_date).utc().format('YYYY-MM-DD') }),
    ...(isOON ? {} : { program: params.program.program_id }),
    provider: groupId,
    network: params.network_id,
    primary_worker: params.service_case.primary_worker_id,
    person: contactId,
    service: params.service_type_id,
  });
};

const handleAuthorizationRequest = async (params, contactId, caseId, state) => {
  const authorizationRequest = parseAuthorizationParams(params);
  const feeScheduleProgramId = get(params, 'program.fee_schedule_program_id', null);

  if (feeScheduleProgramId && !isEmpty(authorizationRequest)) {
    const {
      service_start: serviceStart,
      service_end: serviceEnd,
      amount_requested: amountRequested,
    } = authorizationRequest;
    const fspResponse = await coreApi.findRecord('fee_schedule_program', feeScheduleProgramId);
    const feeScheduleProgram = get(fspResponse, 'data.data');
    if (!feeScheduleProgram) {
      throw new Error('Contracted Program required');
    }
    const feeScheduleId = get(feeScheduleProgram, 'fee_schedule.id');
    const isCostBased = fspUtils.isCostPaymentType(feeScheduleProgram.payment_type);
    const insurances = await fetchInsurances({
      personId: contactId,
      feeScheduleIds: feeScheduleId,
      requestStartDate: iso8601Date(serviceStart),
      requestEndDate: iso8601Date(serviceEnd),
      isRefactorInsuranceFiltersEnabled: pays5590RefactorInsuranceFilters(state),
      sort: '-enrolled_at',
    });
    const insurance = insurances[0];
    await coreApi.createRecord('service_authorization', {
      state: 'requested',
      requested_starts_at: iso8601Date(serviceStart),
      requested_ends_at: iso8601Date(serviceEnd || serviceStart),
      ...(isCostBased ? { requested_cents: amountRequested } : { requested_unit_amount: amountRequested }),
      case: caseId,
      fee_schedule_program: feeScheduleProgramId,
      person: contactId,
      requester: get(state, 'globalState.currentEmployee.id'),
      insurance: insurance.id,
    });
  }
};

const uploadDocuments = async (caseId, dropzoneDocuments, attachedDocuments) => {
  await Promise.all([
    ...(dropzoneDocuments.length ? dropzoneDocuments.map((doc) => coreApi.uploadFile('case', caseId, doc)) : []),
    ...(attachedDocuments.length ? [
      coreApi.setRelationship(
        'case',
        caseId,
        { relationshipModel: 'file_upload', value: attachedDocuments.map((a) => a.document_id) },
      ),
    ] : []),
  ]);
};

const handleOONProviders = async (oonProviders, createdCase, caseId) => {
  await Promise.all(oonProviders
    .filter((oonProvider) => oonProvider.provider_type !== 'CUSTOM')
    .map((oonProvider) => coreApi.createRecord('referral', {
      state: 'off_platform',
      case: caseId,
      sending_provider: createdCase.provider.id,
      sending_network: createdCase.network.id,
      receiving_provider: oonProvider.provider_id,
      receiving_network: createdCase.network.id,
      ...(oonProvider.program_id ? { receiving_program: oonProvider.program_id } : null),
    })));
};

const createCustomProviderNotes = async (oonProviders, caseId, currentEmployee) => {
  await Promise.all(oonProviders
    .filter((oonProvider) => oonProvider.provider_type === 'CUSTOM' && !isEmpty(oonProvider.custom_name))
    .map((oonProvider) => coreApi.createRecord('note', {
      subjectType: 'case',
      subject: caseId,
      employee: currentEmployee.id,
      visibility: 'public',
      category: 'external_provider',
      text: `Referred off platform to ${oonProvider.custom_name}`,
    })));
};

export default function createServiceCase({
  attachedDocuments = [],
  contactId,
  dropzoneDocuments = [],
  groupId,
  isOONCase = false,
  params = {},
}) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_SERVICE_CASE_SET_SUBMITTING });

      const payload = await createCase(params, groupId, contactId, isOONCase);
      const contact = await coreApi.populateRelationship('person', 'person', payload.data.data);
      await coreApi.populateRelationship('consent', 'consent', contact.person);

      dispatch({ type: CREATE_SERVICE_CASE_UNSET_SUBMITTING });
      dispatch({ type: CREATE_SERVICE_CASE, payload });
      Notifier.dispatch(payload.status, 'Case Created');

      const createdCase = payload.data.data;
      const { id: caseId } = createdCase;
      const state = getState();

      await handleAuthorizationRequest(params, contactId, caseId, state);
      await uploadDocuments(caseId, dropzoneDocuments, attachedDocuments);
      const oonProviders = get(params, 'service_case.out_of_network_providers', []);
      await handleOONProviders(oonProviders, createdCase, caseId);
      await createCustomProviderNotes(oonProviders, caseId, get(state, 'globalState.currentEmployee'));

      return payload;
    } catch (error) {
      dispatch({ type: CREATE_SERVICE_CASE_UNSET_SUBMITTING });
      Notifier.handleErrors(error);
      return error;
    }
  };
}
