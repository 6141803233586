import React from 'react';
import LoadingDots from 'common/display/LoadingDots';

export default function StillWorking() {
  return (
    <div id="stillWorking">
      <div className="message">
        <div className="strong-text">There was a problem completing your request.</div>
        <span className="text">
          &nbsp;Still Working
        </span>
        <LoadingDots />
      </div>
    </div>
  );
}
