import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { locationAddressFieldNames as basicAddressFieldNames } from '@unite-us/client-utils';
import AddressField from 'common/form/AddressField/AddressField';
import AddDeleteButtons from 'src/common/form/AddDeleteButtons/AddDeleteButtons';
import { PhoneFields } from 'common/form/Phone';
import { EmailFields } from 'common/form/Email';
import {
  defaultPhoneNumber,
  defaultEmailAddress,
  defaultAddress,
} from '../utils';

function ContactInformation(props) {
  const {
    addressTypeahead,
    fields,
    registerField,
  } = props;

  const onAddPhone = () => {
    const newPhone = defaultPhoneNumber();
    fields.phone_numbers.addField(newPhone);
  };
  const onRemovePhone = (index) => () => {
    fields.phone_numbers.removeField(index);

    setTimeout(() => {
      const phonesContainer = _.first(document.getElementsByClassName('contact-information__phones'));
      const addphoneButtonContainer = _.first(phonesContainer?.getElementsByClassName('ui-add-remove-buttons'));
      const phoneButton = _.first(addphoneButtonContainer?.getElementsByTagName('button'));
      return phoneButton?.focus();
    }, 200);
  };
  const onPhonePrimaryChange = (field) => {
    _.each(
      fields.phone_numbers,
      (phone) => phone.is_primary.onChange(false),
    );
    field.is_primary.onChange(true);
  };
  const onAddressPrimaryChange = (field) => {
    _.each(
      fields.addresses,
      (address) => {
        if (field !== address) {
          address.is_primary.onChange(false);
        }
      },
    );
    field.is_primary.onChange(!field.is_primary.value);
  };
  const onAddEmail = () => {
    const newEmail = defaultEmailAddress();
    fields.email_addresses.addField(newEmail);
  };
  const onRemoveEmail = (index) => () => {
    fields.email_addresses.removeField(index);

    setTimeout(() => {
      const emailsContainer = _.first(document.getElementsByClassName('contact-information__emails'));
      const addEmailButtonContainer = _.first(emailsContainer?.getElementsByClassName('ui-add-remove-buttons'));
      const emailButton = _.first(addEmailButtonContainer?.getElementsByTagName('button'));
      return emailButton?.focus();
    }, 200);
  };
  const onAddAddress = () => {
    const newAddress = defaultAddress();
    fields.addresses.addField(newAddress);

    setTimeout(() => {
      const newAddressElement = document.getElementById(`address-${fields.addresses.length}`);
      const newAddressTypeSelectField = _.first(newAddressElement?.getElementsByClassName('choices'));
      return newAddressTypeSelectField?.focus();
    }, 200);
  };
  const onRemoveAddress = (index) => () => {
    if (fields.addresses.length <= 1) {
      onAddAddress();
    }
    fields.addresses.removeField(index);
  };

  return (
    <div className="mt-4 new-contact-information">
      <h2 className="basic-information__title">Contact Information</h2>

      <hr className="mt-1 mb-4" />

      <div className="contact-information__emails">
        <div className="contact-information__emails__label">Emails</div>
        {
          fields.email_addresses.map((email, index) => (
            <EmailFields
              key={`${email.id.value}`}
              id={`email-${index}`}
              fields={email}
              index={index}
              registerField={registerField}
              length={fields.email_addresses.length}
              add={onAddEmail}
              remove={onRemoveEmail(index)}
            />
          ))
        }
      </div>

      <div className="contact-information__phones">
        <div className="contact-information__phones__label">Phone Numbers</div>
        {
          fields.phone_numbers.map((phone, index, allPhones) => {
            const allPhoneNumbers = allPhones.map((phoneField) => phoneField.phone_number.value);

            return (
              <PhoneFields
                key={`${phone.id.value}`}
                id={`phone-${index}`}
                allPhoneNumbers={allPhoneNumbers}
                fields={phone}
                index={index}
                length={fields.phone_numbers.length}
                registerField={registerField}
                hideLabel
                add={onAddPhone}
                remove={onRemovePhone(index)}
                onPrimaryChange={onPhonePrimaryChange}
              />
            );
          })
        }
      </div>
      <div>
        <div className="w-full contact-information__addresses" data-testid="addresses">
          <div className="contact-information__addresses__label">Addresses</div>
          {_.get(fields, 'addresses', []).map((address, index) => (
            <div
              id={`address-${index}`}
              key={address.id.value}
            >
              <AddressField
                id={`address-${index}`}
                field={address}
                inClientCreation
                registerField={props.registerField}
                inline={false}
                index={index}
                remove={onRemoveAddress(index)}
                onPrimaryChange={onAddressPrimaryChange}
                addressTypeahead={addressTypeahead}
              />
            </div>
          ))}
        </div>
        <div className="h-10 mt-1 address-form-footer">
          <AddDeleteButtons
            displayText="Add Address"
            onAddClick={onAddAddress}
          />
        </div>
      </div>
    </div>
  );
}

ContactInformation.fields = [
  ...basicAddressFieldNames.map((fieldName) => `addresses[].${fieldName}`),
  'addresses[].id',
  'addresses[].line_2',
  'addresses[].address_type',
  'addresses[].is_primary',
  'email_addresses[].id',
  'email_addresses[].email_address',
  'email_addresses[].acceptable_communication_types.message',
  'email_addresses[].acceptable_communication_types.notification',
  'email_addresses[].is_primary',
  'phone_numbers[].id',
  'phone_numbers[].phone_number',
  'phone_numbers[].phone_type',
  'phone_numbers[].is_primary',
  'phone_numbers[].acceptable_communication_types.message',
  'phone_numbers[].acceptable_communication_types.notification',
  'phone_numbers[].acceptable_communication_types.phone_call',
];

ContactInformation.propTypes = {
  fields: PropTypes.object.isRequired,
  registerField: PropTypes.func.isRequired,
  addressTypeahead: PropTypes.bool,
};

ContactInformation.defaultProps = {
  addressTypeahead: false,
};

export default ContactInformation;
