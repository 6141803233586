import PropTypes from 'prop-types';
import { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import { connect } from 'react-redux';
import { fetchGroupsReferral } from 'actions/Referral/Group';
import { referralRoute } from 'src/components/Referrals/utils/routing';
import { isHttpError } from 'common/utils/Error';
import { get, wget } from 'lodash';

const REFERRAL_STATUS_DRAFT = 'draft';

class ReferralLegacyDetailRedirect extends Component {
  componentDidMount() {
    const { groupId, location, params } = this.props;
    const referralId = wget(params, 'id', '');
    const isDraft = get(location, 'pathname', '')
      .indexOf(REFERRAL_STATUS_DRAFT) !== -1;

    this.props.fetchGroupsReferral(groupId, referralId, { isDraft })
      .then((response) => {
        if (isHttpError(response, 409)) {
          browserHistory.replace('/dashboard/new/referrals');
        } else {
          const referral = get(response, 'data.data', {});
          browserHistory.replace(referralRoute(referral, groupId));
        }
      });
  }

  render() {
    return null;
  }
}

ReferralLegacyDetailRedirect.propTypes = {
  groupId: PropTypes.string.isRequired,
  fetchGroupsReferral: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  params: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    groupId: state.session.groupId,
    params: ownProps.params,
  };
}


export default connect(mapStateToProps, {
  fetchGroupsReferral,
})(ReferralLegacyDetailRedirect);
