import { apiDefault } from 'src/api/config';
import coreParamsSerializer from 'src/api/utils/coreParamsSerializer';

export default function fetchCoreServices(servicesIds) {
  const request = apiDefault.get('/services', {
    params: {
      filter: {
        id: servicesIds,
      },
      page: { number: 1, size: 300 },
    },
    paramsSerializer: (params) => coreParamsSerializer(params),
  });

  function onSuccess(payload) {
    return payload.data.data;
  }

  function onError(error) {
    return error;
  }

  return request.then(onSuccess, onError);
}
