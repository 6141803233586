import { get } from 'lodash';

function disabledCustomOptions(oonGroupFields = [], shouldDisplayUnspecifiedProgram = false) {
  const customFields = oonGroupFields.filter(({ group }) => (
    get(group, 'value.isCustom') || (get(group, 'value.provider_type') === 'CUSTOM')
  ));

  const disabledCustomFields = customFields.map((customField) => {
    const disabledCustomField = {
    ...get(customField, 'group.value', {}),
    disabled: true,
    };
    if (shouldDisplayUnspecifiedProgram && disabledCustomField.name) {
      disabledCustomField.displayName = `Unspecified program | ${disabledCustomField.name}`;
    }
    return disabledCustomField;
  });

  return disabledCustomFields.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    return 1;
  });
}

export default disabledCustomOptions;
