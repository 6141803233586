export const STATUS_OPTIONS = [
  {
    value: 'submitted_contracted_service_note',
    label: 'Needs Attention',
  },
  {
    value: 'submitted_to_network_lead',
    label: 'Submitted to Network Lead',
  },
  {
    value: 'submitted_to_payer',
    label: 'In Queue to Payer',
  },
  {
    value: 'transmitted_to_payer',
    label: 'Submitted to Payer',
  },
  {
    value: 'under_dispute',
    label: 'In Dispute',
  },
  {
    value: 'accepted_by_payer',
    label: 'Accepted',
  },
  {
    value: 'paid',
    label: 'Paid',
  },
  {
    value: 'rejected_by_cbo_admin',
    label: 'Rejected',
  },
  {
    value: 'rejected_by_network_lead',
    label: 'Rejected by Network Lead',
  },
  {
    value: 'rejected_by_payer',
    label: 'Rejected by Payer',
  },
];

export const NL_STATUS_OPTIONS = [
  {
    value: 'submitted_to_network_lead',
    label: 'Needs Attention',
  },
  {
    value: 'submitted_to_payer',
    label: 'In Queue to Payer',
  },
  {
    value: 'transmitted_to_payer',
    label: 'Submitted to Payer',
  },
  {
    value: 'under_dispute',
    label: 'In Dispute',
  },
  {
    value: 'accepted_by_payer',
    label: 'Accepted',
  },
  {
    value: 'paid',
    label: 'Paid',
  },
  {
    value: 'rejected_by_network_lead',
    label: 'Rejected',
  },
  {
    value: 'rejected_by_payer',
    label: 'Rejected by Payer',
  },
];

export const PAYER_STATUS_OPTIONS = [
  {
    value: 'transmitted_to_payer',
    label: 'Needs Attention',
  },
  {
    value: 'under_dispute',
    label: 'In Dispute',
  },
  {
    value: 'accepted_by_payer',
    label: 'Accepted',
  },
  {
    value: 'paid',
    label: 'Paid',
  },
  {
    value: 'rejected_by_payer',
    label: 'Rejected',
  },
];

export const DRAFT_STATUS_OPTIONS = [
  {
    value: 'submitted_contracted_service_note',
    label: 'Needs Attention',
  },
  {
    value: 'rejected_by_cbo_admin',
    label: 'Rejected',
  },
];

export const REJECTED_STATUS_OPTIONS = [
  {
    value: 'rejected_by_network_lead',
    label: 'Rejected by Network Lead',
  },
  {
    value: 'rejected_by_payer',
    label: 'Rejected by Payer',
  },
];

export const DISPUTED_STATUS_OPTIONS = [
  {
    value: 'rejected_by_payer',
    label: 'Rejected by Payer',
  },
  {
    value: 'accepted_by_payer',
    label: 'Accepted',
  },
  {
    value: 'paid',
    label: 'Paid',
  },
];

export const SUBMITTED_STATUS_OPTIONS = [
  {
    value: 'submitted_to_network_lead',
    label: 'Submitted to Network Lead',
  },
  {
    value: 'submitted_to_payer',
    label: 'In Queue to Payer',
  },
  {
    value: 'transmitted_to_payer',
    label: 'Submitted to Payer',
  },
];

export const ACCEPTED_STATUS_OPTIONS = [
  {
    value: 'accepted_by_payer',
    label: 'Accepted',
  },
  {
    value: 'paid',
    label: 'Paid',
  },
];

export const ARCHIVED_STATUS_OPTIONS = [
  {
    value: 'submitted_contracted_service_note',
    label: 'Needs Attention',
  },
  {
    value: 'rejected_by_cbo_admin',
    label: 'Rejected',
  },
  {
    value: 'rejected_by_network_lead',
    label: 'Rejected by Network Lead',
  },
  {
    value: 'rejected_by_payer',
    label: 'Rejected by Payer',
  },
  {
    value: 'accepted_by_payer',
    label: 'Accepted',
  },
  {
    value: 'paid',
    label: 'Paid',
  },
];

export const NL_ARCHIVED_STATUS_OPTIONS = [
  {
    value: 'submitted_to_network_lead',
    label: 'Needs Attention',
  },
  {
    value: 'rejected_by_network_lead',
    label: 'Rejected',
  },
  {
    value: 'rejected_by_payer',
    label: 'Rejected by Payer',
  },
  {
    value: 'accepted_by_payer',
    label: 'Accepted',
  },
  {
    value: 'paid',
    label: 'Paid',
  },
];

export const OPEN_STATUS_OPTIONS = [
  {
    value: 'submitted_to_network_lead',
    label: 'Needs Attention',
  },
  {
    value: 'submitted_to_payer',
    label: 'In Queue to Payer',
  },
  {
    value: 'transmitted_to_payer',
    label: 'Submitted to Payer',
  },
];

export const OPEN_PAYER_STATUS_OPTIONS = [
  {
    value: 'transmitted_to_payer',
    label: 'Needs Attention',
  },
  {
    value: 'accepted_by_payer',
    label: 'Accepted',
  },
];

export const READ_ONLY_OPEN_PAYER_STATUS_OPTIONS = [
  {
    value: 'submitted_to_payer',
    label: 'Awaiting Transmission',
  },
  ...OPEN_PAYER_STATUS_OPTIONS,
];

export const CLOSED_STATUS_OPTIONS = [
  {
    value: 'accepted_by_payer',
    label: 'Accepted',
  },
  {
    value: 'paid',
    label: 'Paid',
  },
  {
    value: 'rejected_by_payer',
    label: 'Rejected by Payer',
  },
  {
    value: 'rejected_by_network_lead',
    label: 'Rejected',
  },
];

export const CLOSED_PAYER_STATUS_OPTIONS = [
  {
    value: 'paid',
    label: 'Paid',
  },
  {
    value: 'rejected_by_payer',
    label: 'Rejected',
  },
];

export const ALL_PAYER_STATUS_OPTIONS = [
  {
    value: 'transmitted_to_payer',
    label: 'Needs Attention',
  },
  {
    value: 'under_dispute',
    label: 'In Dispute',
  },
  {
    value: 'accepted_by_payer',
    label: 'Accepted',
  },
  {
    value: 'paid',
    label: 'Paid',
  },
  {
    value: 'rejected_by_payer',
    label: 'Rejected',
  },
];

export const READ_ONLY_ALL_PAYER_STATUS_OPTIONS = [
  {
    value: 'submitted_to_payer',
    label: 'Awaiting Transmission',
  },
  ...ALL_PAYER_STATUS_OPTIONS,
];

export const PAYER_ARCHIVED_STATUS_OPTIONS = [
  {
    value: 'rejected_by_payer',
    label: 'Rejected',
  },
  {
    value: 'paid',
    label: 'Paid',
  },
];

export const LAST_UPDATED_OPTIONS = [1, 7, 14, 30, 60];
