import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import './UploadedDocumentPopover.scss';

export default class UploadedDocumentPopover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popOverHover: false,
    };

    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.popoverClass = this.popoverClass.bind(this);
  }

  handleMouseOver() {
    this.setState({ popOverHover: true });
  }

  handleMouseOut() {
    this.setState({ popOverHover: false });
  }

  popoverClass() {
    return classNames({
      'uploaded-document-popover': true,
      'uploaded-document-popover--show': this.props.hover || this.state.popOverHover,
    });
  }

  render() {
    const { openPdf } = this.props;

    return (
      <div
        role="button"
        className={this.popoverClass()}
        onClick={openPdf}
        onMouseOver={this.handleMouseOver}
        onFocus={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onBlur={this.handleMouseOut}
      />
    );
  }
}

const { bool, func } = PropTypes;

UploadedDocumentPopover.propTypes = {
  hover: bool,
  openPdf: func,
};
