import React from 'react';
import PropTypes from 'prop-types';
import { NoInvoicesFoundCard } from '../components';
import InvoiceWorkqueue from './InvoiceWorkqueue';
import { ACCEPTED_STATUSES } from '../constants';

const AcceptedInvoicesDWQ = ({ children }) => {
  const invoiceStatus = ACCEPTED_STATUSES;
  const bulkActions = ['IconDownloadV2', 'IconArchive'];
  return (
    <InvoiceWorkqueue
      invoiceStatus={invoiceStatus}
      bulkActions={bulkActions}
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <NoInvoicesFoundCard isFiltered={isFiltered} status="accepted" resetFilters={resetFilters} />
      )}
      showStatusFilter
      statusOptionsConstant="ACCEPTED_STATUS_OPTIONS"
      path="accepted/"
    >
      {children}
    </InvoiceWorkqueue>
  );
};

AcceptedInvoicesDWQ.propTypes = {
  children: PropTypes.node,
};

AcceptedInvoicesDWQ.defaultProps = {
  children: undefined,
};
export default AcceptedInvoicesDWQ;
