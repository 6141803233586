import { stripHTML, decodeHTML } from '@unite-us/ui';
import { isEmpty } from 'lodash';

function getOONProgramsAndProviders(OONProgramsAndProviders, oonGroupsExist) {
  return OONProgramsAndProviders.map((programOrProvider) => {
    if (programOrProvider && oonGroupsExist) {
      if (!programOrProvider.isProvider) {
        const { name } = programOrProvider;
        return {
          group: {
            name: stripHTML(name),
            id: isEmpty(programOrProvider.id) ? stripHTML(name) : programOrProvider.id,
            provider_id: programOrProvider.provider.id,
          },
        };
      }
      const { name, provider_type = 'CUSTOM' } = programOrProvider;
      return {
        group: {
          name: stripHTML(name),
          id: isEmpty(programOrProvider.id) ? stripHTML(name) : programOrProvider.id,
          provider_type,
        },
      };
    }

    return {
      // Encoding HTML into entities and then decoding them by reading the textContent.
      // See sanitize.js in packages/uniteus-ui for these utils
      group: programOrProvider ? decodeHTML(stripHTML(programOrProvider.name)) : '',
    };
  });
}

export default getOONProgramsAndProviders;
