import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isString } from 'lodash';

const DataCell = ({ children, className }) => (
  <td
    className={cx('px-4 py-3 truncate text-14px text-text-blue', className)}
    title={isString(children) ? children : ''}
  >
    {children}
  </td>
);

DataCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

DataCell.defaultProps = {
  children: '',
  className: '',
};

export default DataCell;
