import { isEmpty, map } from 'lodash';
import paymentsInsuranceInitialValues from './paymentsInsuranceInitialValues';

export default function getInitialPaymentsFormData(insurances) {
  const filteredInsurance = insurances.filter((insurance) => insurance.relationships.plan.data.planType !== 'social');
  return isEmpty(filteredInsurance) ?
    { insurance: [{}] } :
    {
      insurance: map(filteredInsurance, (insurance = {}) => (
        paymentsInsuranceInitialValues(insurance, insurance.planType))),
    };
}
