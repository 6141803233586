// DEPRECATED COMPONENT, DO NOT USE. USE APP-COMPONENTS OverlaySpinner INSTEAD.
import React from 'react';
import PropTypes from 'prop-types';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

import Spinner from 'common/spinners/Spinner';
import './OverlaySpinner.scss';

const OverlaySpinner = ({
  animationTimeout, description, show, text,
}) => (
  <TransitionGroup className="overlay">
    {
      show && (
        <CSSTransition
          classNames="overlay"
          className="overlay-spinner"
          timeout={{ enter: animationTimeout, exit: animationTimeout }}
        >
          <div>
            <div className="overlay-spinner__text-container">
              <p className="overlay-spinner__text">{text}</p>
              {
                description &&
                  <p className="overlay-spinner__description">{description}</p>
              }
            </div>

            <div className="overlay-spinner__options">
              <Spinner />
            </div>
          </div>
        </CSSTransition>
      )
    }
  </TransitionGroup>
);

OverlaySpinner.propTypes = {
  animationTimeout: PropTypes.number,
  description: PropTypes.string,
  show: PropTypes.bool,
  text: PropTypes.string,
};

OverlaySpinner.defaultProps = {
  animationTimeout: 500,
  description: '',
  show: false,
  text: 'Submitting...',
};

export default OverlaySpinner;
