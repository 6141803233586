import React from 'react';
import Icon from '@unite-us/ui/dist/Display/Icon';
import { PHI_INFO_MESSAGE } from 'common/messageConstants';

const NoteDisclaimer = () => (
  <div className="flex items-start" data-testid="note-disclaimer">
    <Icon
      icon="IconExclamationCircle"
      size={25}
      className="-mt-1 mr-2 fill-current text-text-blue"
      ariaLabel="note"
    />
    <p>
      {PHI_INFO_MESSAGE}
    </p>
  </div>
);

export default NoteDisclaimer;
