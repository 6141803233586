import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Button,
} from '@unite-us/ui';
import { RISK_LEVELS } from '../../constants';

class ServiceReferralButton extends Component {
  constructor(props) {
    super(props);

    this.createReferral = this.createReferral.bind(this);
  }

  createReferral() {
    const {
      screening,
      index,
    } = this.props;

    const path = `/screenings/${screening.id}/need/${index}/referrals/new/add-service-types`;

    browserHistory.push(path);
  }

  render() {
    const {
      need,
      screening,
    } = this.props;
    const text = (
      <p>
        This client has been determined to have a <b>{need.service_type.name}</b> need.
      </p>
    );
    const isHighRisk = screening.risk_level === RISK_LEVELS.highRisk;
    const title = `${need.service_type.name}${isHighRisk ? ' High Risk' : ''}`;

    return (
      <BaseCard className="service-referral-button">
        <BaseCardHeader title={title} className="service-referral-button__header" />
        <BaseCardBody withPadding className="service-referral-button__container">
          {text}
          <Button
            id={`${need.service_type.id}-referral-btn`}
            label="Create Referral"
            onClick={this.createReferral}
            primary
            fullWidth
          />
        </BaseCardBody>
      </BaseCard>
    );
  }
}

ServiceReferralButton.propTypes = {
  index: PropTypes.number.isRequired,
  screening: PropTypes.object.isRequired,
  need: PropTypes.shape({
    service_type: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default ServiceReferralButton;
