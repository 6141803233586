import { FETCH_NETWORK_OUTCOMES, SET_NETWORK_OUTCOMES_FETCHING } from 'actions';
import Notifier from 'common/helpers/Notifier';
import { coreApi } from 'src/api/config';

const defaultParams = {
  applicable_states: ['cases', 'referrals', 'assistance_requests'],
  // sord: 'asc',
  // sidx: 'name',
};

const onError = (error, dispatch) => {
  Notifier.handleErrors(error);
  dispatch({ type: SET_NETWORK_OUTCOMES_FETCHING, isFetching: false });
};

const fetchNetworkOutcomes = (networkId, serviceTypeId, options = {}) => (
  async (dispatch) => {
    try {
      const params = { ...defaultParams, ...options };
      dispatch({
        type: SET_NETWORK_OUTCOMES_FETCHING,
        isFetching: true,
      });
      const [serviceResponse, outcomesResponse] = await Promise.all([
        coreApi.findRecord('service', serviceTypeId),
        coreApi.query('outcome', {
          network: networkId,
          service: serviceTypeId,
          applicable_states: params.applicable_states.join(','),
        }),
      ]);
      const service = serviceResponse.data.data;
      const outcomes = outcomesResponse.data.data;

      outcomesResponse.data.data = [
        {
          ...service,
          outcomes,
          service_type_id: serviceTypeId,
        },
      ];

      dispatch({
        type: FETCH_NETWORK_OUTCOMES,
        payload: outcomesResponse,
      });

      return outcomesResponse;
    } catch (error) {
      return onError(error, dispatch);
    }
  }
);

export default fetchNetworkOutcomes;
