import React from 'react';
import { PropTypes } from 'prop-types';
import { isEmpty, startCase } from 'lodash';
import { feeSchedulePrograms as fspUtils } from '@unite-us/client-utils';
import { useFeatureFlag } from 'common/hooks';
import { CardDetail } from 'src/common/Card';
import formatShortDate from 'src/common/utils/Dates/formatShortDate';
import { dollarAmount } from 'src/common/display/Money/DollarAmount';
import displaySavedClaimCodes from 'src/pages/invoices/utils/displaySavedClaimCodes';

const ServiceDetails = ({
  invoice,
  placeOfService,
  procedureCodes,
  procedureCodeModifiers,
  serviceDescription,
  showClaimsCodes,
  zCodes,
}) => {
  const hasAmountProvidedFlag = useFeatureFlag('pays-4037-contracted-service-amount-provided');

  const isUnitBased = !fspUtils.isCostPaymentType(invoice.fee_schedule_program_payment_type);
  const unitAmountParens = dollarAmount(invoice.provided_service_unit_amount *
    invoice.fee_schedule_program_unit_rate, true);
  const unitAmount = isUnitBased ?
    `${invoice.provided_service_unit_amount} (${unitAmountParens})` :
    dollarAmount(invoice.provided_service_unit_amount, true);
  let actualUnitAmount;
  const actualUnitAmountParens = dollarAmount(invoice.provided_service_actual_unit_amount *
    invoice.fee_schedule_program_unit_rate, true);
  if (!invoice.provided_service_actual_unit_amount) {
    actualUnitAmount = '';
  } else if (isUnitBased) {
    actualUnitAmount = `${invoice.provided_service_actual_unit_amount} (${actualUnitAmountParens})`;
  } else {
    actualUnitAmount = dollarAmount(invoice.provided_service_actual_unit_amount, true);
  }
  const unitRate = invoice.fee_schedule_program_unit_rate ?
    dollarAmount(invoice.fee_schedule_program_unit_rate, true) : '';

  const datesOfService = invoice.provided_service_ends_at ?
    `${formatShortDate(invoice.provided_service_starts_at)} - ${formatShortDate(invoice.provided_service_ends_at)}` :
    formatShortDate(invoice.provided_service_starts_at);

  return (
    <>
      { unitRate ? (
        <>
          <CardDetail label="Invoice Amount">
            {unitAmount}
          </CardDetail>
          { hasAmountProvidedFlag && actualUnitAmount && (
            <CardDetail
              label="Amount Provided"
              tooltip="This is the actual amount of the service provided by the organization."
            >
              {actualUnitAmount}
            </CardDetail>
          )}
          <CardDetail label="Unit">
            {invoice.fee_schedule_program_unit}
          </CardDetail>
          <CardDetail label="Rate">
            {unitRate}
          </CardDetail>
        </>
        ) : (
          <>
            <CardDetail label="Amount Invoiced">
              {unitAmount}
            </CardDetail>
            { hasAmountProvidedFlag && actualUnitAmount && (
              <CardDetail
                label="Cost of Service"
                tooltip="This is the actual cost to the organization of providing this service."
              >
                {actualUnitAmount}
              </CardDetail>
            )}
          </>
        )}
      <CardDetail label="Date(s) of Service">{datesOfService}</CardDetail>
      <CardDetail label="Service Provided">
        {invoice.fee_schedule_program_name}
      </CardDetail>
      {
        serviceDescription ? (
          <CardDetail label={'Service Description'}>{serviceDescription}</CardDetail>
        ) : null
      }
      <CardDetail label="Service Code">
        {invoice.fee_schedule_program_external_code}
      </CardDetail>
      <CardDetail label="Payment Approach">
        {startCase(invoice.fee_schedule_program_payment_type)}
      </CardDetail>
      <CardDetail label="Fee Schedule Name">{invoice.fee_schedule_name}</CardDetail>
      {
        showClaimsCodes && (
          <>
            {
              !isEmpty(zCodes) ? (
                <CardDetail label={'Zcodes'}>{displaySavedClaimCodes(zCodes)}
                </CardDetail>
              ) : null
            }
            {
              !isEmpty(placeOfService) ? (
                <CardDetail label={'Place of Service'}>{displaySavedClaimCodes(placeOfService)}
                </CardDetail>
              ) : null
            }
            {
              !isEmpty(procedureCodes) ? (
                <CardDetail label={'Procedure Codes'}>{displaySavedClaimCodes(procedureCodes)}</CardDetail>
              ) : null
            }
            {
              !isEmpty(procedureCodeModifiers) ? (
                <CardDetail label={'Procedure Code Modifier'}>
                  {displaySavedClaimCodes(procedureCodeModifiers)}
                </CardDetail>
              ) : null
            }
          </>
        )
      }
    </>
  );
};

ServiceDetails.propTypes = {
  invoice: PropTypes.object.isRequired,
  placeOfService: PropTypes.array,
  procedureCodes: PropTypes.array,
  procedureCodeModifiers: PropTypes.array,
  serviceDescription: PropTypes.string,
  showClaimsCodes: PropTypes.bool.isRequired,
  zCodes: PropTypes.array,
};

ServiceDetails.defaultProps = {
  placeOfService: [],
  procedureCodes: [],
  procedureCodeModifiers: [],
  serviceDescription: '',
  zCodes: [],
};

export default ServiceDetails;
