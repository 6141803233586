import { isEmpty, isObject, some } from 'lodash';

const isEmptyField = (field = {}) => (
  isObject(field.value) ? isEmpty(field.value) : field.value === ''
);

const anyEmptyRequiredCaseFormFields = (fields = {}) => (
  some([
    isEmptyField(fields.network) && fields.case_type.value === 'network_case',
    isEmptyField(fields.program),
    isEmptyField(fields.program_entry),
    isEmptyField(fields.service_case.description),
    isEmptyField(fields.service_case.primary_worker_id),
    isEmptyField(fields.service_case.service_type),
  ])
);

const shouldInitializeCaseDetailsForm = (formFields) => (
  !formFields ||
  !formFields.service_case ||
  anyEmptyRequiredCaseFormFields(formFields)
);

export default shouldInitializeCaseDetailsForm;
