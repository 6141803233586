import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';
import classNames from 'classnames';

export default function SpinIcon(props) {
  const {
    children,
    size,
    style,
    spin,
  } = props;

  const mainClass = () => classNames({
    'spin-icon': true,
    spinning: spin,
  });

  const childWithProps = React.cloneElement(children, { size });

  return (
    <span className={mainClass()} style={style}>
      {childWithProps}
    </span>
  );
}

SpinIcon.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  spin: PropTypes.bool.isRequired,
};

SpinIcon.defaultProps = {
  children: (<Icon icon="IconRefresh" />),
  size: 18,
  style: {
    marginTop: '5px',
  },
  spin: true,
};
