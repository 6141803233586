import _ from 'lodash';
import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  USER_LOGOUT,
} from 'actions';
import { generateUUID } from 'common/utils/utils';
import { statusInQueue } from 'common/utils/Notifications/utils';

export default function (state = [], action) {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      const stateStatuses = _.uniq(_.map(state, 'status'));

      if (statusInQueue(stateStatuses, action.payload.status)) {
        const notifications = [
          ...state,
          {
            uuid: generateUUID(),
            status: action.payload.status,
            text: action.payload.statusText,
            ...(action.payload?.timeOut && { timeOut: action.payload.timeOut }),
          },
        ];
        return notifications;
      }

      return state;
    }
    case REMOVE_NOTIFICATION: {
      return _.filter(state, (o) => o.uuid !== action.payload.uuid);
    }

    case USER_LOGOUT:
      return [];

    default:
      return state;
  }
}
