import { get, has } from 'lodash';
import formatDate from 'src/common/utils/Dates/formatDate';
import { HISTORY_ICONS, HISTORY_MESSAGES } from '../constants';

const historyMessage = (event, employeeName) => (
  HISTORY_MESSAGES[event]({ employeeName })
);

const prepareVersion = (version) => {
  const newStatus = get(version, 'object_changes.invoice_status[1]');
  const becamePaid = get(version, 'object_changes.payment_received[1]', false);
  let event;
  switch (true) {
    case becamePaid:
      event = 'payment_received';
      break;
    case has(HISTORY_MESSAGES, newStatus):
      event = newStatus;
      break;
    default:
      event = 'edited';
      break;
  }
  const employeeName = get(version, 'employee.full_name', 'System');

  return {
    icon: HISTORY_ICONS[event],
    message: historyMessage(event, employeeName),
    date: formatDate(version.created_at, false),
  };
};

export default prepareVersion;
