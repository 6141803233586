import React from 'react';
import PropTypes from 'prop-types';
import { InputField } from '@unite-us/ui';
import { validations } from '@unite-us/app-components';
import { noop } from 'lodash';
import './stylesheets/phoneNumberField.scss';

const PhoneNumberField = (props) => (
  <InputField
    className="phone-number-field"
    ref={props.registerField}
    {...props}
  />
);

PhoneNumberField.propTypes = {
  hideLabel: PropTypes.bool,
  inline: PropTypes.bool,
  label: PropTypes.string,
  mask: PropTypes.string,
  placeholder: PropTypes.string,
  registerField: PropTypes.func,
  validations: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func,
  ]),
};

PhoneNumberField.defaultProps = {
  hideLabel: true,
  inline: false,
  label: 'Phone Number',
  mask: '(999) 999-9999 x 99999',
  placeholder: '(999) 999-9999',
  registerField: noop,
  validations: validations.isPhoneNumber,
};

export default PhoneNumberField;
