import { isEmpty } from 'lodash';
import { getCurrentAddress } from '../address';

function buildBrowseFilters({
  centerCoordinates,
  clientAddress,
  clientCenter,
  contextAction = '',
  distance = '',
  employeeAddress,
  groupAddress,
  groupsOptionType,
  networks = [],
  networkScopes,
  serviceTypes = [],
}) {
  const addressType = !isEmpty(clientAddress) ? 'client' : 'ours';

  return {
    accessibility: [],
    addressType,
    address: getCurrentAddress({
      addressType,
      clientAddress,
      clientCenter,
      employeeAddress,
      groupAddress,
      latLng: centerCoordinates,
    }),
    catersTo: [],
    cities: [],
    contextAction,
    counties: [],
    distance,
    feeScheduleIds: [],
    groupsOptionType,
    includeHomeGroups: contextAction === 'network',
    languages: [],
    networks,
    networkScopes,
    serviceTypes,
    states: [],
    text: '',
  };
}

export default buildBrowseFilters;
