import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Button,
  Icon,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from '@unite-us/ui';
import EmptyTable from 'src/common/tables/EmptyTable/EmptyTable';
import { tableWidth } from 'common/helpers/Tables';
import DialogV2 from 'src/common/modal/DialogV2';
import { isEmpty } from 'lodash';
import { theme } from 'src/../tailwind.config';
import parse from 'html-react-parser';
import { constructFeeScheduleProgramFormPayload } from 'src/pages/fee-schedules/_id/utils/index';
import { useUpdateFeeScheduleProgram } from 'src/components/Backoffice/api/hooks/v1/feeScheduleProgramHooks';
import EditMetafields from 'src/components/Backoffice/metafields/EditMetafields';
import moment from 'moment';

const namespace = 'fee-schedule-program-custom-field-table';

const FeeScheduleProgramCustomFieldTable = ({ customFields, feeScheduleProgram }) => {
  const EditCustomFieldModalRef = useRef(null);
  const [newCustomFields, setNewCustomFields] = useState([...customFields]);
  const { updateRecord: updateFeeScheduleProgram } = useUpdateFeeScheduleProgram();
  const closeModal = () => {
    setNewCustomFields(customFields);
    EditCustomFieldModalRef.current.closeDialog();
  };

  const openModal = () => {
    EditCustomFieldModalRef.current.openDialog();
  };

  const addCustomField = async () => {
    const attachmentsForFeeScheduleProgram =
      feeScheduleProgram.metafields.filter((metafield) => metafield.type === 'file');
    const newFeeScheduleProgram = {
      ...feeScheduleProgram,
      metafields: [...newCustomFields, ...attachmentsForFeeScheduleProgram],
    };
    const payload = constructFeeScheduleProgramFormPayload(newFeeScheduleProgram, newFeeScheduleProgram.id);
    const response = await updateFeeScheduleProgram(newFeeScheduleProgram.id, payload);
    if (response) {
      closeModal();
    }
  };

  return (
    <>
      <div className="fee-schedule-program-base-card">
        <BaseCard className="p-6" noBorder>
          <BaseCardHeader title="Custom Fields" noBorder className="px-4 font-bold">
            <Button
              id="edit-custom-field-button"
              iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="V2Edit" />}
              className="flex"
              label="EDIT CUSTOM FIELD"
              onClick={openModal}
              disabled={moment().utc().isAfter(moment(feeScheduleProgram.ends_at).utc())}
            />
          </BaseCardHeader>
          <BaseCardBody>
            <Table id="feeScheduleProgramCustomFieldTable" className="border-0 border-t rounded-none">
              <TableHeader>
                <TableRow>
                  <TableHeaderColumn style={{ width: tableWidth('quarter') }}>
                    Name
                  </TableHeaderColumn>
                  <TableHeaderColumn style={{ width: tableWidth('small') }}>
                    Type
                  </TableHeaderColumn>
                  <TableHeaderColumn style={{ width: tableWidth('small') }}>
                    Requirement
                  </TableHeaderColumn>
                  <TableHeaderColumn style={{ width: tableWidth('half') }}>
                    Description
                  </TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                {!isEmpty(customFields) &&
                  customFields
                    .map((customField) => (
                      <TableRow
                        className={`${namespace}__row`}
                        key={`fee-schedule-custom-field-row-${customField.id}`}
                        hoverable={false}
                        clickable={false}
                      >
                        <TableRowColumn style={{ width: tableWidth('quarter') }}>
                          {customField.label}
                        </TableRowColumn>
                        <TableRowColumn style={{ width: tableWidth('small') }}>
                          {customField.type}
                        </TableRowColumn>
                        <TableRowColumn style={{ width: tableWidth('small') }}>
                          { (customField.validations && customField.validations.required) ? 'Required' : 'Optional' }
                        </TableRowColumn>
                        <TableRowColumn style={{ width: tableWidth('half') }}>
                          { (customField.help_text) ? parse(customField.help_text) : '' }
                        </TableRowColumn>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {isEmpty(customFields) && (
              <EmptyTable
                className={'p-6'}
                message={'Click "Edit Custom Fields" to add custom fields'}
              />
            )}
          </BaseCardBody>
        </BaseCard>
      </div>
      <DialogV2
        cancelHandler={closeModal}
        confirmationHandler={addCustomField}
        confirmLabel="Save"
        id="add-cbo-modal"
        ref={EditCustomFieldModalRef}
        title="Add/Edit Custom Fields"
        vertical
        dialogDescriptionClass={'flex-auto overflow-y-auto bg-medium-fill-blue'}
      >
        <EditMetafields
          metafields={customFields}
          setMetafieldsToSave={setNewCustomFields}
          addButtonLabel={'ADD CUSTOM FIELD'}
          formsTitle={'Custom Field'}
        />
      </DialogV2>
    </>
  );
};

FeeScheduleProgramCustomFieldTable.propTypes = {
  customFields: PropTypes.array.isRequired,
  feeScheduleProgram: PropTypes.object.isRequired,
};

export default FeeScheduleProgramCustomFieldTable;
