import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';

const RemoveEmailButton = ({
  styles,
  onClick,
}) => (
  <div className="remove-email-button">
    <button
      onClick={onClick}
      type="button"
      style={styles.linkStyle}
      title="Remove email"
      className="text-sm"
    >
      <Icon
        icon="IconTrash"
        style={styles.iconStyle}
      />
    </button>
  </div>
);

RemoveEmailButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    linkStyle: PropTypes.object,
    iconStyle: PropTypes.object,
  }),
};

RemoveEmailButton.defaultProps = {
  styles: {
    linkStyle: {
      marginTop: '24px',
      display: 'block',
    },
    iconStyle: {
      svg: {
        width: '1.5em',
        height: '1.5em',
      },
    },
  },
};

export default RemoveEmailButton;
