import { theme } from 'src/../tailwind.config';

const statusColors = {
  pending_authorization: theme.extend.colors.yellow,
  sent: theme.extend.colors.yellow,
  pending_consent: theme.extend.colors.yellow,
  pending: theme.extend.colors.yellow,
  in_review: theme.extend.colors.orange,
  recalled: theme.extend.colors.orange,
  rejection: theme.extend.colors.red,
  denied_authorization: theme.extend.colors.red,
  declined: theme.extend.colors.red,
  closed: theme.extend.colors['action-blue'],
  declined_consent: theme.extend.colors.red,
  open: theme.extend.colors['dark-green'],
  processed: theme.extend.colors['medium-grey'],
  accepted: theme.extend.colors['medium-grey'],
};

export default function getStatusColor(status) {
  return statusColors[status] || theme.extend.colors['medium-grey'];
}
