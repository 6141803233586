import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  BaseCard,
  BaseCardBody,
} from '@unite-us/ui';
import ReferralHistoryTable from 'src/components/ReferralHistory/components/ReferralHistoryTable';
import DetailNetworkForDraftReferral from
  'common/display/SingleItemDetails/DetailNetworkForDraftReferral';
import ServiceCaseReferredTo from 'src/components/Cases/components/Detail/ServiceCaseReferredTo';
import { editable } from 'common/utils/Permission/Permission';
import { useSelectedPrograms } from '@unite-us/app-search';
import useCaseReferrals, { useInvalidateCaseReferrals } from '../../hooks/useCaseReferrals';
import './ServiceCaseReferralHistory.scss';

const ServiceCaseReferralHistory = ({
  provider, serviceCase, type, isFetchingCase,
}) => {
  const selectedProgramsContext = useSelectedPrograms();
  const {
    selectedPrograms,
    dispatchAddProgram,
    dispatchRemoveAllPrograms,
  } = selectedProgramsContext;

  const { isLoading: isLoadingReferrals, allReferrals: referrals } = useCaseReferrals(serviceCase);

  const updateSelectedPrograms = () => {
    const referralProgramIds = referrals.map((referral) => referral.receiving_program?.id);
    const selectedProgramIds = selectedPrograms.map((p) => p.id);
    // if selectedProgramIds differs from referralProgramIds, update selectedProgramIds
    if (!(referralProgramIds.every((rp) => selectedProgramIds.some((sp) => rp === sp)) &&
      referralProgramIds.length === selectedProgramIds.length)) {
      dispatchRemoveAllPrograms();
      referrals.forEach((referral) => {
        if (!['draft', 'off_platform'].includes(referral.state)) return;
        const { receiving_program } = referral;
        if (!receiving_program) return;
        // if receiving_program is falsy, we're dealing with an old provider-based referral
        const { id: programId, name: programName, provider: programProvider = {} } = receiving_program;
        const { id: providerId } = programProvider;
        if (selectedPrograms.findIndex((p) => p.id === programId) === -1) {
          dispatchAddProgram({ id: programId, name: programName, providerId });
        }
      });
    }
  };

  const invalidateCaseReferrals = useInvalidateCaseReferrals();
  const allowEdit = editable(serviceCase, type) &&
    ['off_platform', 'draft'].includes(serviceCase.state) &&
    _.isEmpty(serviceCase.outcome);

  const referredToGroupsDraft = _.map(_.get(serviceCase, 'referrals', []), (r) => ({
    ...r.receiving_provider,
    ...(r.receiving_program ? { receiving_program: r.receiving_program } : null),
  }));

  const recipientsForm = serviceCase.state === 'draft' ? (
    <DetailNetworkForDraftReferral
      referral={{
        ...serviceCase,
        referred_to_groups_draft: referredToGroupsDraft,
      }}
      groupId={_.get(provider, 'id')}
      invalidateCaseReferrals={invalidateCaseReferrals}
      modalOpenCallback={updateSelectedPrograms}
    />
  ) : (
    <ServiceCaseReferredTo
      serviceCase={{ ...serviceCase, referrals }}
      groupId={serviceCase.provider.id}
      contactId={serviceCase.person.id}
      contact={serviceCase.person}
      editable={allowEdit}
      invalidateCaseReferrals={invalidateCaseReferrals}
      selectedProgramsContext={selectedProgramsContext}
    />
  );

  return (
    <div className="case-referral-history">
      <div className="flex-referral flex justify-end mb-4">
        <h2 className="w-9/12">Referral History</h2>
        <div className="w-3/12 flex justify-end">
          {(isFetchingCase || isLoadingReferrals) ? null : recipientsForm}
        </div>
      </div>
      <div>
        <BaseCard className="referral-history">
          <BaseCardBody>
            {(isFetchingCase || isLoadingReferrals) ? (
              <div className="flex justify-center p-6">
                <p>
                  Loading...
                </p>
              </div>
            ) : (
              <ReferralHistoryTable
                referrals={referrals}
                groupId={_.get(provider, 'id')}
              />
            )}
          </BaseCardBody>
        </BaseCard>
      </div>
    </div>
  );
};

ServiceCaseReferralHistory.propTypes = {
  isFetchingCase: PropTypes.bool.isRequired,
  provider: PropTypes.object.isRequired,
  serviceCase: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    provider: _.wget(ownProps, 'currentProvider'),
    serviceCase: _.wget(ownProps, 'serviceCase'),
    isFetchingCase: _.wget(state, 'serviceCase.isFetching', false),
  };
}

export default connect(mapStateToProps)(ServiceCaseReferralHistory);
