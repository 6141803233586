import calculateDistance from "./calculateDistance";
import findClosestDistanceAddress from "./findClosestDistanceAddress";
import findCurrentHours from "./findCurrentHours";
import findHomeOrFirstAddress from "./findHomeOrFirstAddress";
import findMainAddress from "./findMainAddress";
import getAddressString from "./getAddressString";
import getDistanceInMiles from "./getDistanceInMiles";
import getFormattedAddress from "./getFormattedAddress";
import getFormattedLocations from "./getFormattedLocations";
import getLatLng from "./getLatLng";
import makeGoogleMapLink from "./makeGoogleMapLink";
import sortAddresses from "./sortAddresses";
export default {
  calculateDistance: calculateDistance,
  findCurrentHours: findCurrentHours,
  findClosestDistanceAddress: findClosestDistanceAddress,
  findHomeOrFirstAddress: findHomeOrFirstAddress,
  findMainAddress: findMainAddress,
  getAddressString: getAddressString,
  getDistanceInMiles: getDistanceInMiles,
  getFormattedAddress: getFormattedAddress,
  getFormattedLocations: getFormattedLocations,
  getLatLng: getLatLng,
  makeGoogleMapLink: makeGoogleMapLink,
  sortAddresses: sortAddresses
};