import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Button } from '@unite-us/ui';

class GroupSelectorListItem extends Component {
  constructor(props) {
    super(props);

    this.selectGroup = this.selectGroup.bind(this);
  }

  selectGroup() {
    this.props.setGroupId(get(this.props, 'group.id'));
  }

  render() {
    const { group } = this.props;

    return (
      <Button
        className="group-selector-item"
        id={`group-${get(group, 'id')}-select-btn`}
        label={get(group, 'name', '')}
        onClick={this.selectGroup}
        primary
      />
    );
  }
}

GroupSelectorListItem.propTypes = {
  group: PropTypes.object.isRequired,
  setGroupId: PropTypes.func.isRequired,
};

export default GroupSelectorListItem;
