import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function ContactPreferredLanguages(props) {
  const { languages, className } = props;

  const spokenLangs = languages.filter((l) => l.record_language_type === 'spoken');
  const writtenLangs = languages.filter((l) => l.record_language_type === 'written');
  const spokenLangsDisplay = spokenLangs.length > 0 ? spokenLangs.map((l) => l.language_name).join(', ') : '--';
  const writtenLangsDisplay = writtenLangs.length > 0 ? writtenLangs.map((l) => l.language_name).join(', ') : '--';

  return (
    <div className={cx('flex flex-col space-y-2', className)}>
      <div>Spoken: {spokenLangsDisplay}</div>
      <div>Written: {writtenLangsDisplay}</div>
    </div>
  );
}

ContactPreferredLanguages.propTypes = {
  className: PropTypes.string,
  languages: PropTypes.array.isRequired,
};

ContactPreferredLanguages.defaultProps = {
  className: '',
};

export default ContactPreferredLanguages;
