import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isObject } from 'lodash';
import { Spinner } from 'common/spinners';
import {
  useFindInvoiceRejectionReasonsByFeeScheduleId,
} from 'src/components/Backoffice/api/hooks/v1/invoiceRejectionReasonHooks';
import { useFindFeeSchedule } from 'src/components/Backoffice/api/hooks/v1/feeScheduleHooks';
import moment from 'moment';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Button,
  Icon,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from '@unite-us/ui';
import { theme } from 'src/../tailwind.config';
import EmptyTable from 'src/common/tables/EmptyTable/EmptyTable';
import 'src/pages/fee-schedules/_id/FeeSchedulePrograms/_fee_schedule_program_id/FeeScheduleProgramBaseCard.scss';
import FeeScheduleEditInvoiceRejectionReasons from './FeeScheduleEditInvoiceRejectionReasons';

const FeeScheduleInvoiceRejectionReasonsTable = ({ feeScheduleId }) => {
  const EditRejectionReasonModalRef = useRef(null);
  const { data: reasons, isFetching } = useFindInvoiceRejectionReasonsByFeeScheduleId(feeScheduleId);

  const openModal = () => {
    EditRejectionReasonModalRef.current.openDialog();
  };

  const { data: feeSchedule } = useFindFeeSchedule(feeScheduleId);

  return (
    <>
      <div className="fee-schedule-base-card">
        <BaseCard className="p-6" noBorder>
          <BaseCardHeader title="Invoice Rejection Reasons" className="px-4 font-bold">
            <Button
              id="edit-rejections-button"
              iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="V2Edit" />}
              onClick={openModal}
              className="flex"
              label="EDIT REJECTION REASONS"
              disabled={moment.utc().isAfter(moment(feeSchedule.ends_at).utc())}
            />
          </BaseCardHeader>
          <BaseCardBody>
            {isFetching ? <Spinner /> : (
              <>
                {!isEmpty(reasons) ? (
                  <Table id="InvoiceRejectionReasonsTable" noBorder>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderColumn className="border-r border-gray-300 border-solid" style={{ width: '5%' }}>
                          Code
                        </TableHeaderColumn>
                        <TableHeaderColumn className="border-r border-gray-300" style={{ width: '45%' }}>
                          Rejection Reason
                        </TableHeaderColumn>
                        <TableHeaderColumn className="border-r border-gray-300" style={{ width: '45%' }}>
                          Description
                        </TableHeaderColumn>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {reasons.filter(isObject).map((reason) => (
                        <TableRow hoverable={false} key={reason.id}>
                          <TableRowColumn className="border-r border-gray-300 border-solid" style={{ width: '5%' }}>
                            {reason.code}
                          </TableRowColumn>
                          <TableRowColumn className="border-r border-gray-300" style={{ width: '45%' }}>
                            {reason.display_name}
                          </TableRowColumn>
                          <TableRowColumn className="border-r border-gray-300" style={{ width: '45%' }}>
                            {reason.description}
                          </TableRowColumn>
                        </TableRow>
                    ))}
                    </TableBody>
                  </Table>
                ) : (
                  <EmptyTable
                    className={'p-6'}
                    message={(
                      <>
                        No rejection reasons have been configured for this Fee Schedule.
                        <br />
                        Click &quot;Edit Rejections&quot; to add Invoice Rejection Reasons.
                      </>
                      )}
                  />
                )}
                <FeeScheduleEditInvoiceRejectionReasons
                  rejectionReasons={reasons}
                  modalRef={EditRejectionReasonModalRef}
                  feeScheduleId={feeScheduleId}
                />
              </>
          )}
          </BaseCardBody>
        </BaseCard>
      </div>
    </>
  );
};

FeeScheduleInvoiceRejectionReasonsTable.propTypes = {
  feeScheduleId: PropTypes.string.isRequired,
};

export default FeeScheduleInvoiceRejectionReasonsTable;
