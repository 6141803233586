import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { truncate } from 'lodash';
import EditModal from 'common/modal/EditModal';
import EditEmailAddress from '../Forms/EditEmailAddress';

const EDIT_EMAIL_ADDRESS = 'editEmailAddress';
class EmailAddress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
    };

    this.showEdit = this.showEdit.bind(this);
    this.hideEdit = this.hideEdit.bind(this);
  }

  showEdit() {
    this.setState({ editing: true });
  }

  hideEdit() {
    this.setState({ editing: false });
  }

  render() {
    const {
      emailRowStyles,
      employee,
      employee: { email },
    } = this.props;

    const styles = this.state.editing ?
      { ...emailRowStyles, backgroundColor: 'ECF5FD' } :
      emailRowStyles;

    return (
      <div style={styles} className="user-email-address editable-panel">
        <div
          className="row"
          onMouseEnter={this.showEdit}
          onMouseLeave={this.hideEdit}
        >
          <div className="col-sm-2">
            <h5 className="pull-right mt-quarter">Email</h5>
          </div>
          <div className="col-sm-9">
            <div>
              <p>
                <a href={`mailto:${email}`} title={email}>
                  {truncate(email, { length: 40 })}
                </a>
              </p>
            </div>
          </div>
          <div className="col-sm-1">
            <span>
              <ul className="list-unstyled">
                <EditModal
                  id="edit-email-modal"
                  header="Edit Email"
                  formName={EDIT_EMAIL_ADDRESS}
                  buttonId="edit-email-btn"
                  showEdit={Boolean(email)}
                >
                  <EditEmailAddress
                    employee={employee}
                  />
                </EditModal>
              </ul>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

EmailAddress.propTypes = {
  emailRowStyles: PropTypes.object,
  employee: PropTypes.object.isRequired,
};

EmailAddress.defaultProps = {
  emailRowStyles: {
    padding: '20px',
  },
};

export default EmailAddress;
