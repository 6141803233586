import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';

const LIGHT_GREY = '#C5C8CC';

const TitleIcon = ({ showTable, enableTableView, onViewToggle }) => {
  if (!enableTableView) {
    return null;
  }

  return (
    <div className="title-icon">
      <span
        className="title-icon__view-toggle"
        data-tooltip={showTable ? 'Switch to Chart View' : 'Switch to Table View'}
        data-tooltip-bottom
      >
        <Icon
          className="title-icon__icon"
          icon={showTable ? 'IconBarChart' : 'IconTable'}
          onClick={onViewToggle}
          color={LIGHT_GREY}
          size={24}
        />
      </span>
    </div>
  );
};

TitleIcon.propTypes = {
  enableTableView: PropTypes.bool.isRequired,
  onViewToggle: PropTypes.func,
  showTable: PropTypes.bool,
};

export default TitleIcon;
