import {
  CREATE_INSURANCE_ENTRY_STARTED,
  CREATE_INSURANCE_ENTRY_SUCCESS,
  CREATE_INSURANCE_ENTRY_ERROR,
  DELETE_INSURANCE_ENTRY_ERROR,
  DELETE_INSURANCE_ENTRY_STARTED,
  DELETE_INSURANCE_ENTRY_SUCCESS,
  EDIT_INSURANCE_ENTRY_ERROR,
  EDIT_INSURANCE_ENTRY_STARTED,
  EDIT_INSURANCE_ENTRY_SUCCESS,
  FETCH_PAYMENTS_INSURANCE_STARTED,
  FETCH_PAYMENTS_INSURANCE_SUCCESS,
  FETCH_PAYMENTS_INSURANCE_ERROR,
  FETCH_PLAN_INFORMATION_STARTED,
  FETCH_PLAN_INFORMATION_SUCCESS,
  FETCH_PLAN_INFORMATION_ERROR,
} from 'src/actions';
import { get } from 'lodash';
import {
  STATUS_INITIAL,
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_ERROR,
} from 'common/Status/constants';

export const defaultState = {
  status: STATUS_INITIAL,
  data: {},
};

function insurancesReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case CREATE_INSURANCE_ENTRY_STARTED: {
      return { ...state, status: STATUS_PENDING };
    }

    case CREATE_INSURANCE_ENTRY_SUCCESS: {
      const insuranceEntry = get(action, 'payload.data.data');
      const existingInsurances = state.data[action.contactId] === undefined ? [] : state.data[action.contactId];

      return {
        ...state,
        status: STATUS_SUCCESS,
        data: { ...state.data, [action.contactId]: [...existingInsurances, insuranceEntry] },
      };
    }

    case CREATE_INSURANCE_ENTRY_ERROR: {
      return { ...state, status: STATUS_ERROR };
    }

    case FETCH_PAYMENTS_INSURANCE_STARTED: {
      return { ...state, status: STATUS_PENDING };
    }

    case FETCH_PAYMENTS_INSURANCE_SUCCESS: {
      const insuranceEntries = get(action, 'payload.data.data');
      return {
        ...state,
        status: STATUS_SUCCESS,
        data: { ...state.data, [action.contactId]: insuranceEntries },
      };
    }

    case FETCH_PAYMENTS_INSURANCE_ERROR: {
      return { ...state, status: STATUS_ERROR };
    }

    case DELETE_INSURANCE_ENTRY_ERROR: {
      return { ...state, status: STATUS_ERROR };
    }

    case DELETE_INSURANCE_ENTRY_STARTED: {
      return { ...state, status: STATUS_PENDING };
    }

    case DELETE_INSURANCE_ENTRY_SUCCESS: {
      const { contactId, insuranceId } = action.payload;
      const currentInsuranceEntries = state.data[contactId] || [];
      const filteredInsuranceEntries = currentInsuranceEntries.filter((insurance) => (
        insurance.id !== insuranceId
      ));
      return {
        ...state,
        status: STATUS_SUCCESS,
        data: { ...state.data, [contactId]: filteredInsuranceEntries },
      };
    }

    case EDIT_INSURANCE_ENTRY_ERROR: {
      return { ...state, status: STATUS_ERROR };
    }

    case EDIT_INSURANCE_ENTRY_STARTED: {
      return { ...state, status: STATUS_PENDING };
    }

    case EDIT_INSURANCE_ENTRY_SUCCESS: {
      const editedEntry = get(action, 'payload.data.data');
      const { contactId, insuranceId } = action;
      const currentInsuranceEntries = state.data[contactId] || [];
      const updatedEntries = currentInsuranceEntries.reduce((insuranceEntries, currentEntry) => {
        if (currentEntry.id === insuranceId) {
          return [...insuranceEntries, editedEntry];
        }
        return [...insuranceEntries, currentEntry];
      }, []);
      return {
        ...state,
        status: STATUS_SUCCESS,
        data: { ...state.data, [contactId]: updatedEntries },
      };
    }

    case FETCH_PLAN_INFORMATION_STARTED: {
      return { ...state, status: STATUS_PENDING };
    }

    case FETCH_PLAN_INFORMATION_SUCCESS: {
      const planInformation = get(action, 'payload.data');
      const { planId, contactId } = action;
      const currentInsuranceEntries = state.data[contactId] || [];
      const planFeeSchedules = planInformation.data.relationships.fee_schedules;

      const insuranceEntriesWithPlanInfo = currentInsuranceEntries.reduce((insuranceEntries, currentEntry) => {
        if (get(currentEntry, 'relationships.plan.data.id') === planId) {
          const updatedCurrentEntry = {
            ...currentEntry,
            relationships: {
              ...currentEntry.relationships,
              plan: {
                ...currentEntry.relationships.plan,
                data: {
                  ...currentEntry.relationships.plan.data,
                  planName: planInformation.data.attributes.name,
                  planType: planInformation.data.attributes.plan_type,
                },
                relationships: {
                  fee_schedules: planFeeSchedules,
                },
              },
            },
          };
          return [...insuranceEntries, updatedCurrentEntry];
        }
        return [...insuranceEntries, currentEntry];
      }, []);

      return {
        ...state,
        status: STATUS_SUCCESS,
        data: { ...state.data, [contactId]: insuranceEntriesWithPlanInfo },
      };
    }

    case FETCH_PLAN_INFORMATION_ERROR: {
      return { ...state, status: STATUS_ERROR };
    }

    default:
      return state;
  }
}

export default insurancesReducer;
