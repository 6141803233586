import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ChartMultiBar } from 'src/common/charts';
import { fetchMilitaryReports } from 'actions/Report/Contact/Military';
import _ from 'lodash';
import { genderPartitions } from '../../utils/partitions';
import { genderColors } from '../../utils/colors';
import sortByTitleNoResponseLast from '../../utils/sortByTitleNoResponseLast';

export default class BranchByGender extends Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.state = {
      branchByGender: [],
      title: '',
    };
  }

  componentDidMount() {
    this.fetchData(this.props.filters);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.filters, nextProps.filters)) {
      this.fetchData(nextProps.filters);
    }
  }

  fetchData(filters) {
    const { scope, id } = this.props;
    fetchMilitaryReports(scope, id, 'branch-of-service/by-gender', { partitions: genderPartitions(), ...filters })
      .then((response) => {
        const data = _.get(response, 'data', {});
        data.values = sortByTitleNoResponseLast(data.values);
        this.setState({
          branchByGender: data,
          title: _.get(response, 'data.title', ''),
        });
        this.props.loaded('branchByGender');
      });
  }

  render() {
    if (_.isEmpty(this.state.branchByGender)) {
      return null;
    }
    return (
      <ChartMultiBar
        data={this.state.branchByGender}
        x={(d) => d.title}
        y={(d) => d.total}
        title={this.state.title}
        margin={{ left: 60, top: 60, bottom: 60 }}
        showValues
        showYAxis
        showLegend
        stacked
        valueFormat="d"
        useInteractiveGuideline={false}
        colors={genderColors}
        xLabel="Branch of Service"
        colWidths={{
          x: '270px',
        }}
      />
    );
  }
}

BranchByGender.propTypes = {
  filters: PropTypes.shape({
    end_date: PropTypes.string,
    network_ids: PropTypes.array,
    start_date: PropTypes.string,
  }),
  loaded: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
};
