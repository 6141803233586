import React, { useContext, useEffect } from 'react';
import { createSelector } from 'reselect';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import Notifier from 'common/helpers/Notifier';
import { useSelectedPrograms, SEARCH_CLIENTS, SEARCH_CONTEXTS, SEARCH_ROLES } from '@unite-us/app-search';
import { ClientHeader } from '@unite-us/app-client-profile';
import { getAuthToken, coreApi } from 'src/api/config';
import {
  crtb1127AuthPaymentProgramsInReferrals as crtb1127AuthPaymentProgramsInReferralsSelector,
  crtb1239AdaptDraftReferralFlow as crtb1239AdaptDraftReferralFlowSelector,
  hasPaymentsUserAccess,
  hasCaseManagerRole,
  includePathwaysServices,
  hint542SupersetUnlistedPrograms as hint542SupersetUnlistedProgramsSelector,
  hint716SearchNetworkHubSupportPremiumSelector,
  hint1246HideLgbtqPlusFilter as hint1246HideLgbtqPlusFilterSelector,
  uup459SupersetPhase2 as uup459SupersetPhase2Selector,
  hint1066PaymentsLiteModal as hint1066PaymentsLiteModalSelector,
  hint1426SharesShowNewPopulations as hint1426SharesShowNewPopulationsSelector,
  hint1980ShowSharesMessageBox as hint1980ShowSharesMessageBoxSelector,
  cerb1367ResourceListDetailEnhancements as cerb1367ResourceListDetailEnhancementsSelector,
  cerb1582StayOnPageAfterShareOrSave as cerb1582StayOnPageAfterShareOrSaveSelector,
  cerb1455HasSuggestEdit as cerb1455HasSuggestEditSelector,
  cerbNy1115 as cerbNy1115Selector,
  cerb1519ScreeningRequiredFeeSchedules as cerb1519ScreeningRequiredFeeSchedulesSelector,
  cerb1486Ny1115ClientSocialCareCoverageIds as cerb1486Ny1115ClientSocialCareCoverageIdsSelector,
  cerb1485Ny1115Networks as cerb1485Ny1115NetworksSelector,
} from 'common/utils/FeatureFlags/flags';
import { SEARCH_API, CORE_API, GOOGLE_MAPS_API_KEY, SHARES_URL } from 'src/config/env/env.config';
import { TrackerContext } from '@unite-us/client-utils';
import { ComponentLibraryLoader } from '@unite-us/app-components';
import { init, useAppCreateReferralContext } from '@unite-us/app-create-referral';
import { browserHistory } from 'src/common/utils/browserHistory';
import { updateGlobalState } from 'actions/Global/globalActions';

const SupersetSearchApp = ComponentLibraryLoader({
  loadFn: () => import('@unite-us/app-search'),
  component: 'SupersetSearchApp',
  loadingApp: 'app-client',
  source: 'app-search',
  datadogRum,
});

const ReferralStepper = ComponentLibraryLoader({
  loadFn: () => import('@unite-us/app-create-referral'),
  component: 'ReferralStepper',
  loadingApp: 'app-client',
  source: 'app-create-referral',
  datadogRum,
});

function getInitialFilters({
  employeeId,
  employeeNetworks,
  personId,
  providerId,
  serviceIds,
}) {
  return {
    active: true,
    ...(serviceIds?.length && { services: serviceIds }),
    'provider.networks': employeeNetworks.toString(),
    referable: {
      employee: employeeId,
      networks: employeeNetworks.toString(),
      person: personId,
      provider: providerId,
      person_screening: personId,
    },
  };
}

const SupersetSearch = ({
  cerbNy1115,
  cerb1455HasSuggestEdit,
  cerb1367ResourceListDetailEnhancements,
  cerb1582StayOnPageAfterShareOrSave,
  crtb1127AuthPaymentProgramsInReferrals,
  crtb1239AdaptDraftReferralFlow,
  hint542SupersetUnlistedPrograms,
  hint716SearchNetworkHubSupportPremium,
  hint1246HideLgbtqPlusFilter,
  hint1066PaymentsLiteModal,
  hint1426SharesShowNewPopulations,
  hint1980ShowSharesMessageBox,
  uup459SupersetPhase2,
  assistanceRequestId,
  serviceTypeOptions,
  employeeId,
  employeeNetworks,
  enums,
  formSubmissionId,
  providerId,
  includePathways,
  initialAddresses,
  userCoordinates,
  isPaymentsUserRole,
  isCaseManagerRole,
  location,
  networkName,
  personId,
  screeningId,
  resourceListId,
  serviceIds,
  shouldPreserveCart,
  isShoppingCartOpen,
  setIsShoppingCartOpen,
  screeningRequiredFeeSchedulesIds,
  ny1115SocialCoverageIds,
  cerb1485Ny1115Networks,
}) => {
  useEffect(() => {
    setIsShoppingCartOpen(false);
  }, []);

  const initialFilters = getInitialFilters({
    employeeId,
    employeeNetworks,
    personId,
    providerId,
    serviceIds,
  });

  const trackEvent = useContext(TrackerContext);
  const { selectedPrograms } = useSelectedPrograms();
  const {
    dispatch,
    state: {
      draftState,
    },
  } = useAppCreateReferralContext();
  const addSelectClient = browserHistory.getCurrentLocation().state?.source === 'client' || false;
  const serviceType = browserHistory.getCurrentLocation().state?.serviceType;
  const referralType = browserHistory.getCurrentLocation().state?.referralType;
  const referralId = browserHistory.getCurrentLocation().state?.referralId;
  const sensitive = serviceType?.sensitive;
  const referralDescription = browserHistory.getCurrentLocation().state?.referralDescription;
  const caseId = browserHistory.getCurrentLocation().state?.caseId;

  const onCreateReferralsClickHandler = () => {
    const programToServiceTypesDict = {};
    const unlistedPrograms = selectedPrograms.filter((p) => !p.id);
    const listedPrograms = selectedPrograms.filter((p) => p.id);

    listedPrograms.forEach((program) => {
      programToServiceTypesDict[program.id] = programToServiceTypesDict[program.id] ?
        [...programToServiceTypesDict[program.id], program.service] :
        [program.service];
    });

    const programsToUse = Object.keys(programToServiceTypesDict)
      .map((id) => ({
        id,
        services: programToServiceTypesDict[id],
        name: selectedPrograms.find((p) => p.id === id).name,
        isSensitive: selectedPrograms.find((p) => p.id === id).isSensitive,
        // unlisted-programs are not aggregated, id is always null, and the names are distinct
        // this validation happens in search-app during creation
      })).concat(unlistedPrograms.map((p) => ({
        id: null,
        services: [p.service],
        name: p.name,
      })));

    dispatch(
      init({
        selectedPrograms: programsToUse,
        person: personId,
        resourceListId,
        formSubmissionId,
        serviceIds,
        workflow: 'search',
        addSelectClient,
        assistanceRequestId,
        draftState,
        screeningId,
        serviceType,
        referralType,
        sensitive,
        referralId,
        referralDescription,
        caseId,
      }),
    );

    browserHistory.push({
      pathname: '/referrals/2/create/builder',
    });
  };

  const navigate = (path) => {
    const url = `/referrals/2${path}`;
    browserHistory.push({
      pathname: url,
    });
  };

  const onNavigateBackHandler = browserHistory.push;

  const onNavigateToResourceListHandler = (id) => {
    const newUrl = `/facesheet/${personId}/resource-lists/${id}`;
    browserHistory.push(newUrl);
  };

  const userRoles = [
    ...isPaymentsUserRole ? [SEARCH_ROLES.PAYMENTS] : [],
    ...isCaseManagerRole ? [SEARCH_ROLES.CASE_MANAGER] : [],
  ];

  return (
    <>
      <div className="bg-white -mx-container-padding">
        <ClientHeader
          adapters={{ coreApi }}
          appState={{
            providerId,
            personId,
            enums,
          }}
          mode="lite"
        />
      </div>
      <div className={classNames('-mx-4 bg-white', cerb1367ResourceListDetailEnhancements ? 'pt-4' : 'py-4')}>
        <div className="max-w-screen-xl mx-auto">
          <ReferralStepper
            navigate={navigate}
            location={location}
            searchParams={{
              person: personId,
              resource_list: resourceListId,
              form_submission: formSubmissionId,
              services: serviceIds,
            }}
            selectedPrograms={selectedPrograms}
            addSelectClient={addSelectClient}
          />
        </div>
      </div>
      <SupersetSearchApp
        callbacks={{
          trackEvent,
          notify: {
            error: (message) => Notifier.dispatch('error', message),
            success: (message) => Notifier.dispatch('success', message),
            warn: (message) => Notifier.dispatch('warning', message),
          },
          createReferrals: onCreateReferralsClickHandler,
          navigateBack: onNavigateBackHandler,
          navigateToResourceList: onNavigateToResourceListHandler,
        }}
        userNetworks={employeeNetworks}
        serviceTypeOptions={serviceTypeOptions}
        initialAddresses={initialAddresses}
        networkName={networkName}
        cerb1485Ny1115Networks={cerb1485Ny1115Networks}
        appSettings={{
          client: SEARCH_CLIENTS.APP_CLIENT,
          context: SEARCH_CONTEXTS.SUPERSET_REFERRAL,
          env: {
            getAuthToken,
            employeeId,
            providerId,
            googleApiKey: GOOGLE_MAPS_API_KEY,
            SHARES_URL,
            isReferralContext: true,
          },
          endpoints: {
            search: {
              url: SEARCH_API,
            },
            core: {
              url: CORE_API,
            },
          },
          flags: {
            serviceTypesIncludePathways: includePathways,
            crtb1127AuthPaymentProgramsInReferrals,
            crtb1239AdaptDraftReferralFlow,
            hint542SupersetUnlistedPrograms,
            hint1246HideLgbtqPlusFilter,
            hint716SearchNetworkHubSupportPremium,
            hint1066PaymentsLiteModal,
            hint1426SharesShowNewPopulations,
            hint1980ShowSharesMessageBox,
            uup459SupersetPhase2,
            cerb1367ResourceListDetailEnhancements,
            cerb1582StayOnPageAfterShareOrSave,
            cerb1455hasSuggestEdit: cerb1455HasSuggestEdit,
            cerbNy1115,
          },
          roles: userRoles,
          shouldPreserveCart,
          idsOfFeeSchedulesThatRequireSpecializedScreenings: screeningRequiredFeeSchedulesIds,
          ny1115SocialCoverageIds,
        }}
        formSubmissionId={formSubmissionId}
        initialFilters={initialFilters}
        personId={personId}
        userCoordinates={userCoordinates}
        resourceListId={resourceListId}
        assistanceRequestId={assistanceRequestId}
        isShoppingCartOpen={isShoppingCartOpen}
        setIsShoppingCartOpen={setIsShoppingCartOpen}
        serviceType={serviceType}
        referralType={referralType}
        sensitive={sensitive}
        referralId={referralId}
      />
    </>
  );
};

// Should match shape of address validator in Core
const addressShape = {
  address_type: PropTypes.string.isRequired,
  line_1: PropTypes.string,
  line_2: PropTypes.string,
  city: PropTypes.string.isRequired,
  county: PropTypes.string,
  state: PropTypes.string.isRequired,
  postal_code: PropTypes.string,
  country: PropTypes.string.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  is_primary: PropTypes.bool,
};

SupersetSearch.propTypes = {
  // FLAGS
  cerbNy1115: PropTypes.bool.isRequired,
  cerb1455HasSuggestEdit: PropTypes.bool.isRequired,
  includePathways: PropTypes.bool.isRequired,
  cerb1367ResourceListDetailEnhancements: PropTypes.bool.isRequired,
  cerb1582StayOnPageAfterShareOrSave: PropTypes.bool.isRequired,
  crtb1127AuthPaymentProgramsInReferrals: PropTypes.bool.isRequired,
  crtb1239AdaptDraftReferralFlow: PropTypes.bool.isRequired,
  hint716SearchNetworkHubSupportPremium: PropTypes.bool.isRequired,
  hint542SupersetUnlistedPrograms: PropTypes.bool.isRequired,
  hint1246HideLgbtqPlusFilter: PropTypes.bool.isRequired,
  hint1066PaymentsLiteModal: PropTypes.bool.isRequired,
  hint1426SharesShowNewPopulations: PropTypes.bool.isRequired,
  hint1980ShowSharesMessageBox: PropTypes.bool.isRequired,
  uup459SupersetPhase2: PropTypes.bool.isRequired,
  // FLAGS END
  employeeId: PropTypes.string.isRequired,
  employeeNetworks: PropTypes.arrayOf(PropTypes.string).isRequired,
  formSubmissionId: PropTypes.string,
  providerId: PropTypes.string.isRequired,
  initialAddresses: PropTypes.shape({
    CLIENT: PropTypes.arrayOf(PropTypes.shape(addressShape)),
    USER: PropTypes.arrayOf(PropTypes.shape(addressShape)),
    GROUP: PropTypes.arrayOf(PropTypes.shape(addressShape)),
  }).isRequired,
  serviceTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      parent_id: PropTypes.string,
      parent_code: PropTypes.string,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      taxonomy: PropTypes.string.isRequired,
      is_sensitive: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  userCoordinates: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  isPaymentsUserRole: PropTypes.bool.isRequired,
  isCaseManagerRole: PropTypes.bool.isRequired,
  networkName: PropTypes.string.isRequired,
  personId: PropTypes.string,
  screeningId: PropTypes.string,
  assistanceRequestId: PropTypes.string,
  resourceListId: PropTypes.string,
  serviceIds: PropTypes.string,
  location: PropTypes.object.isRequired,
  enums: PropTypes.object.isRequired,
  shouldPreserveCart: PropTypes.bool.isRequired,
  isShoppingCartOpen: PropTypes.bool.isRequired,
  setIsShoppingCartOpen: PropTypes.func.isRequired,
  screeningRequiredFeeSchedulesIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  ny1115SocialCoverageIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  cerb1485Ny1115Networks: PropTypes.arrayOf(PropTypes.string).isRequired,
};

SupersetSearch.defaultProps = {
  assistanceRequestId: null,
  formSubmissionId: null,
  personId: null,
  screeningId: null,
  resourceListId: null,
  serviceIds: null,
};

function mapStateToProps(state, ownProps) {
  const networkId = state.session.networkId || state.networkId;
  const allNetworks = (state.globalState.allNetworks || []).map((network) => ({
    id: network.id,
    name: network.name,
    coordination_centers: network.coordination_centers.data,
    network_type: network.network_type,
  }));
  const employeeNetworks = state.globalState.activeNetworks.map((network) => network.id);
  const currentNetwork = find(allNetworks, { id: networkId });

  const employeeId = state.globalState.currentEmployee.id;
  const providerId = state.globalState.currentEmployee.provider.id;
  const includePathways = includePathwaysServices(state);
  const isPaymentsUserRole = hasPaymentsUserAccess(state);
  const isCaseManagerRole = hasCaseManagerRole(state);
  const {
    location: {
      query: {
        assistance_request: assistanceRequestId,
        form_submission: formSubmissionId,
        person: personId,
        resource_list: resourceListId,
        services: serviceIds,
        preserve_cart: preserveCart,
        screening: screeningId,
      },
    },
  } = ownProps;

  const contact = find(state.contacts.contacts, { id: personId });

  const selectServiceTypes = createSelector(
    (inState) => inState.session.globals.service_types,
    (serviceTypes) => {
      const ret = [];
      serviceTypes.forEach((parentType) => {
        parentType.children.forEach((child) => ret.push({
          id: child.id,
          parent_id: parentType.id,
          parent_code: parentType.code,
          name: child.name,
          code: child.code,
          taxonomy: child.taxonomy,
          is_sensitive: child.sensitive,
        }));

        ret.push({
          id: parentType.id,
          parent_id: null,
          parent_code: null,
          name: parentType.name,
          code: parentType.code,
          taxonomy: parentType.taxonomy,
          is_sensitive: parentType.sensitive,
        });
      });
      return ret;
    },
  );

  const {
    currentEmployee: {
      addresses: userAddresses,
      provider: { addresses: groupAddresses },
    },
  } = state.globalState;
  const userCoordinates =
    Object.keys(state.session.position.geoCoordinates).length === 0 ?
      state.session.position.ipCoordinates :
      state.session.position.geoCoordinates;

  const enums = state.session.enums || {};

  const isShoppingCartOpen = state.globalState.isShoppingCartOpen;

  return {
    serviceTypeOptions: selectServiceTypes(state),
    employeeId,
    employeeNetworks,
    assistanceRequestId,
    formSubmissionId,
    includePathways,
    providerId,
    isPaymentsUserRole,
    isCaseManagerRole,
    contact,
    initialAddresses: {
      client: contact?.addresses,
      user: userAddresses,
      ours: groupAddresses,
    },
    userCoordinates,
    networkName: currentNetwork?.name || '',
    personId,
    screeningId,
    shouldPreserveCart: preserveCart === 'true',
    resourceListId,
    serviceIds: serviceIds?.split(',') ?? null,
    enums,
    isShoppingCartOpen,
    screeningRequiredFeeSchedulesIds: cerb1519ScreeningRequiredFeeSchedulesSelector(state),
    ny1115SocialCoverageIds: cerb1486Ny1115ClientSocialCareCoverageIdsSelector(state),
    hint716SearchNetworkHubSupportPremium: hint716SearchNetworkHubSupportPremiumSelector(state),
    crtb1127AuthPaymentProgramsInReferrals: crtb1127AuthPaymentProgramsInReferralsSelector(state),
    crtb1239AdaptDraftReferralFlow: crtb1239AdaptDraftReferralFlowSelector(state),
    hint542SupersetUnlistedPrograms: hint542SupersetUnlistedProgramsSelector(state),
    hint1246HideLgbtqPlusFilter: hint1246HideLgbtqPlusFilterSelector(state),
    hint1066PaymentsLiteModal: hint1066PaymentsLiteModalSelector(state),
    hint1426SharesShowNewPopulations: hint1426SharesShowNewPopulationsSelector(state),
    hint1980ShowSharesMessageBox: hint1980ShowSharesMessageBoxSelector(state),
    uup459SupersetPhase2: uup459SupersetPhase2Selector(state),
    cerb1367ResourceListDetailEnhancements: cerb1367ResourceListDetailEnhancementsSelector(state),
    cerb1582StayOnPageAfterShareOrSave: cerb1582StayOnPageAfterShareOrSaveSelector(state),
    cerb1455HasSuggestEdit: cerb1455HasSuggestEditSelector(state),
    cerbNy1115: cerbNy1115Selector(state),
    cerb1485Ny1115Networks: cerb1485Ny1115NetworksSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setIsShoppingCartOpen: (isOpen) => dispatch(updateGlobalState({ isShoppingCartOpen: isOpen })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SupersetSearch);
