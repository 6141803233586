import {
  NO_OON_GROUPS_MESSAGE,
  HAS_IN_AND_OON_GROUPS_MESSAGE,
} from 'src/components/Referrals/ReferralFormFields/OONGroupsSelector/constants';

const caseOONGroupHeaderMessage = (oonGroupsEmpty) => (
  oonGroupsEmpty ? NO_OON_GROUPS_MESSAGE : HAS_IN_AND_OON_GROUPS_MESSAGE
);

export default caseOONGroupHeaderMessage;
