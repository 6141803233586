import React from 'react';
import PropTypes from 'prop-types';
import { InfoPopover } from '@unite-us/client-utils';
import '../stylesheets/communicationTypeInfoIcon.scss';

const CommunicationTypeInfoIcon = ({
  className,
  id,
  popoverContent,
}) => {
  if (!popoverContent) {
    return null;
  }
  return (
    <InfoPopover
      className={`communication-type-info-icon ${className}`}
      id={id}
    >
      <div className="communication-type-info-icon__popover-content">
        {popoverContent}
      </div>
    </InfoPopover>
  );
};

CommunicationTypeInfoIcon.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  popoverContent: PropTypes.node,
};

CommunicationTypeInfoIcon.defaultProps = {
  className: '',
  popoverContent: '',
};

export default CommunicationTypeInfoIcon;
