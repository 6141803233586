import React from 'react';
import Notifier from 'common/helpers/Notifier';

export default function systemUpdateRequiredNotification() {
  const message = (
    <span>
      A new version of Unite Us is available. Please refresh your browser at your earliest convenience.
      <a role="button" className="ml-one" onClick={() => window.location.reload(true)}>Refresh now</a>
    </span>
  );
  const status = 'warning';
  const dismissable = false;

  return () => Notifier.handleSystemNotification(status, message, dismissable);
}
