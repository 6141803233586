import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { FilterSingle } from '@unite-us/client-utils';
import {
  Button,
  Dialog,
  DurationField,
} from '@unite-us/ui';
import { SERVER_DATE_FORMAT } from 'src/components/Exports/constants';
import { getDateRange } from '../utils';
import { ALL_TIME, CUSTOM_DATES, DATE_FILTER_OPTIONS } from '../constants';

export class CustomDateRangeSelect extends Component {
  constructor(props) {
    super(props);
    this.onDateRangeChange = this.onDateRangeChange.bind(this);
    this.startDateSelect = this.startDateSelect.bind(this);
    this.endDateSelect = this.endDateSelect.bind(this);
    this.state = {
      dateRangeValue: ALL_TIME,
      start_date: null,
      end_date: null,
    };
  }

  onDateRangeChange(dateOption) {
    if (_.isEmpty(dateOption)) {
      return;
    }
    if (dateOption === CUSTOM_DATES) {
      this.customDateRange.openDialog().then((response) => {
        if (response === 'submit') {
          if (!_.isString(this.state.start_date) && !_.isString(this.state.end_date)) {
            this.onDateRangeChange(ALL_TIME);
          } else {
            this.props.onDateRangeChange({ start_date: this.state.start_date, end_date: this.state.end_date });
          }
        }
      });
    } else {
      this.props.onDateRangeChange(getDateRange(dateOption));
    }
    this.setState({
      dateRangeValue: dateOption,
    });
  }

  startDateSelect(dateInt) {
    if (dateInt) {
      this.setState({ start_date: moment.unix(dateInt).utc().format(SERVER_DATE_FORMAT) });
    } else {
      this.setState({ start_date: null });
    }
  }

  endDateSelect(dateInt) {
    if (dateInt) {
      this.setState({ end_date: moment.unix(dateInt).utc().format(SERVER_DATE_FORMAT) });
    } else {
      this.setState({ end_date: null });
    }
  }

  render() {
    const actions = [
      <Button
        id="custom-date-cancel-btn"
        label="Cancel"
        onClick={() => this.customDateRange.closeDialog()}
      />,
      <Button
        id="custom-date-submit-btn"
        label="Submit"
        onClick={() => this.customDateRange.closeDialog('submit')}
        primary
      />,
    ];

    return (
      <div>
        <Dialog
          ref={(el) => { this.customDateRange = el; }}
          title="Custom Date Range"
          actions={actions}
          size="normal"
          modal
          id="custom-date-range"
        >
          <DurationField
            id={CUSTOM_DATES.value}
            startField={{
              name: 'custom_start_field',
              value: this.state.start_date,
              onChange: this.startDateSelect,
              onBlur: _.noop,
            }}
            endField={{
              name: 'custom_end_field',
              value: this.state.end_date,
              onChange: this.endDateSelect,
              onBlur: _.noop,
            }}
            dateFormat={SERVER_DATE_FORMAT}
            label="Custom Date Range"
            hideLabel
            inline={false}
            maxDate={new Date()}
            style={{ container: { minHeight: '250px' } }}
          />
        </Dialog>
        <FilterSingle
          id="basic"
          name="Date Range"
          onFilterChange={this.onDateRangeChange}
          value={this.state.dateRangeValue.value}
          options={DATE_FILTER_OPTIONS}
          placeholder="Filter by Date Range"
        />
      </div>
    );
  }
}

CustomDateRangeSelect.propTypes = {
  onDateRangeChange: PropTypes.func.isRequired,
};

export default CustomDateRangeSelect;
