import {
  CREATE_SERVICE_CASE,
  CREATE_SERVICE_CASE_SET_SUBMITTING,
  CREATE_SERVICE_CASE_UNSET_SUBMITTING,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import { get } from 'lodash';
import moment from 'moment';
import { coreApi } from 'src/api/config';

export default function createServiceCaseFromAR({
  assistanceRequestId,
  attachedDocuments = [],
  contactId,
  dropzoneDocuments = [],
  isOONCase = false,
  params = {},
}) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_SERVICE_CASE_SET_SUBMITTING });

      const isOON = isOONCase || params.program.program_name === 'Referred Out of Network';
      // Update from AR to Case
      const payload = await coreApi.updateRecord('case', assistanceRequestId, {
        description: params.service_case.description,
        state: isOON ? 'off_platform' : 'managed',
        ...(isOON ? {} : { opened_date: moment.unix(params.program.entry_date).utc().format('YYYY-MM-DD') }),
        ...(
          isOON ? {} : {
            program: params.program.program_id,
          }
        ),
        network: params.network_id,
        primary_worker: params.service_case.primary_worker_id,
        person: contactId,
        service: params.service_type_id,
      });
      const contact = await coreApi.populateRelationship('person', 'person', payload.data.data);
      await coreApi.populateRelationship('consent', 'consent', contact.person);

      dispatch({ type: CREATE_SERVICE_CASE_UNSET_SUBMITTING });
      dispatch({
        type: CREATE_SERVICE_CASE,
        payload,
      });

      // Attaching documents
      await Promise.all([
        ...(
          dropzoneDocuments.length ?
            dropzoneDocuments.map((doc) => coreApi.uploadFile('case', assistanceRequestId, doc)) : []
        ),
        ...(
          attachedDocuments.length ? [
            coreApi.setRelationship(
              'case',
              assistanceRequestId,
              { relationshipModel: 'file_upload', value: attachedDocuments.map((a) => a.document_id) },
            ),
          ] : []
        ),
      ]);

      const oonProviders = get(params, 'service_case.out_of_network_providers', []);

      const currentEmployee = get(getState(), 'globalState.currentEmployee');

      // creating notes for the free text providers
      await Promise.all(oonProviders
        .filter((oonProvider) => oonProvider.provider_type === 'CUSTOM')
        .map((oonProvider) => coreApi.createRecord('note', {
          subjectType: 'case',
          subject: assistanceRequestId,
          employee: currentEmployee.id,
          visibility: 'public',
          category: 'external_provider',
          text: `Referred off platform to ${oonProvider.custom_name}`,
        })));

      Notifier.dispatch(payload.status, 'Case Created');

      return payload;
    } catch (error) {
      dispatch({ type: CREATE_SERVICE_CASE_UNSET_SUBMITTING });
      Notifier.handleErrors(error);
      return error;
    }
  };
}
