import {
  useFind,
} from 'src/api/APIHooks';
import { defaultQueryConfig } from '../apiHookOptions';

export const useFindEmployeesByFeeSchedule = (ids, params = { queryConfig: defaultQueryConfig }) => useFind(
  'employee',
  { fee_schedule_id: ids },
  params,
);

export const useFindPaymentsEmployees = (
  providerId,
  searchText,
  feeScheduleId,
  params = { queryConfig: defaultQueryConfig },
) => useFind(
  'employee',
  {
    provider: providerId,
    query: searchText,
    exclude_fee_schedule_id: feeScheduleId,
    'roles.key': 'payments',
  },
  params,
);
