import { forEach } from 'lodash';

export default function clearFields({ fields, untouch, keys }) {
  const removalMethods = {
    primary_worker: () => {
      fields.service_case.primary_worker_id.onChange('');
      untouch(fields.service_case.primary_worker_id.name);
    },
    network: () => {
      fields.network.onChange('');
      untouch(fields.network.name);
    },
    program: () => {
      fields.program.onChange('');
      untouch(fields.program.name);
    },
    service_type: () => {
      fields.service_case.service_type.onChange('');
      untouch(fields.service_case.service_type.name);
    },
  };

  forEach(keys, (keyName) => {
    removalMethods[keyName]();
  });
}
