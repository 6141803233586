import PropTypes from 'prop-types';
import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { useMessages } from 'src/components/Notes/queries';
import Note from 'src/components/Notes/Note';
import AddCirclePlusButton from 'common/display/Profile/components/AddCirclePlusButton';
import Pagination from 'src/common/Pagination/Pagination';
import { Spinner } from 'common/spinners';

const Messages = ({
  onAddNote,
  subject,
  providerId,
}) => {
  const {
    messages,
    response,
    page,
    pageSize,
    setPageSize,
    setPageNumber,
    isLoading,
  } = useMessages({
    subject,
    providerId,
  });

  const paging = get(response, 'data.paging');

  return (
    <div>
      <div>
        <div className="mb-4 flex justify-end">
          <h2 className="w-9/12">
            Messages
          </h2>
          <div className="w-3/12 flex justify-end">
            <AddCirclePlusButton
              className="text-right"
              id="add-case-message-button"
              labelText="Message Client"
              addHandler={onAddNote}
            />
          </div>
        </div>
        <div id="messages-container" data-test-element="messages-container">
          {
            isLoading ? (
              <div className="my-10">
                <Spinner text="" show={isLoading} />
              </div>
            ) : null
          }
          {
            !messages.length ? (
              <div className={
                'bg-dark-fill-blue border border-solid ' +
                'border-dark-border-blue color-grey text-center py-8 px-6 mb-6 rounded'
              }
              >
                No messages with this client.
              </div>
            ) : null
          }
          {
            messages.map((n) => (
              <div className="mb-4" data-test-element={`message-${n.id}`}>
                <Note
                  {...n}
                  Title={n.Title}
                  duration={n.duration}
                  interactionDate={n.interactionDate}
                  category={n.category}
                  text={get(n, 'details.message_body', '')}
                  noteType="Message"
                />
              </div>
            ))
          }
          <div className="mb-16">
            <Pagination
              pageNumber={page}
              pageSize={pageSize}
              totalItemCount={paging.total_count}
              totalPageCount={paging.total_pages}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
            />
          </div>
        </div>
        <div className="connector" />
      </div>
    </div>
  );
};

Messages.propTypes = {
  subject: PropTypes.string.isRequired,
  onAddNote: PropTypes.func.isRequired,
  providerId: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  onAddNote: ownProps.onAddNote,
  providerId: ownProps.providerId,
  subject: ownProps.subject,
  subjectType: ownProps.subjectType,
});

export default connect(mapStateToProps)(Messages);
