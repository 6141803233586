import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  Menu,
  Badge,
} from '@unite-us/ui';
import { browserHistory } from 'common/utils/browserHistory';
import { useUnreadConversationsCount } from '@unite-us/app-message-center';
import { datadogLogs } from '@datadog/browser-logs';
import { useTwilioClient } from '../../../api/twilioHooks';

const loggerId = 'app-message-center-unread-count';

export const MessageCenterNotification = ({ currentEmployee }) => {
  const { data: twilioClient } = useTwilioClient(currentEmployee?.id);

  datadogLogs.createLogger(loggerId, {
    level: 'error',
    handler: 'http',
    context: {
      package: 'app-message-center',
    },
  });

  const messageCenterLogger = datadogLogs.getLogger(loggerId);

  const notifications = useUnreadConversationsCount(twilioClient, messageCenterLogger);

  return (
    <div className="relative mr-1 mt-px">
      <Menu
        id="right-nav-message-notification-menu-btn"
        className="right-nav ml-half mr-half"
        anchorElement={(
          <Icon
            ariaLabel="Notifications"
            id="right-nav-message-notification-btn"
            className="help-btn"
            color="white"
            icon="IconEnvelope"
            onClick={() => { browserHistory.push('/message-center'); }}
            size={24}
          />
        )}
        position="notification"
      />
      {
        notifications > 0 ? (
          <div className="absolute flex-1 -mt-8 ml-5">
            <Badge
              id="message-notification-count-badge"
              text={notifications}
              className="float-right"
              style={{
                paddingTop: 4,
                wordBreak: 'normal',
                height: 20,
                minWidth: 20,
                fontSize: 11,
                backgroundColor: '#D0021B',
              }}
            />
          </div>
        ) : null
      }
    </div>
  );
};

MessageCenterNotification.propTypes = {
  currentEmployee: PropTypes.object.isRequired,
};

export default MessageCenterNotification;
