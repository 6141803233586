import PropTypes from 'prop-types';
import React from 'react';
import { EDIT_DESCRIPTION } from 'common/display/Profile/constants/form';
import { DraftEditorField } from '@unite-us/client-utils';
import EditModal from 'common/modal/EditModal';
import EditDescription from 'common/form/Profile/EditDescription';
import RowDisplay from 'common/display/Profile/RowDisplay';
import './OrgProfileDescription.scss';

export default function Description(props) {
  const {
    description,
    groupId,
    readOnly,
  } = props;

  const editSection = (
    <EditModal
      header="Edit Description"
      formName={EDIT_DESCRIPTION}
      buttonId="edit-description-btn"
      id="edit-description-modal"
    >
      <EditDescription
        groupId={groupId}
        description={description}
        updateGroup={props.updateGroup}
      />
    </EditModal>
  );

  return (
    <RowDisplay
      editSection={readOnly ? null : editSection}
      label="Description"
    >
      <div className="org-profile-description col-lg-12">
        <DraftEditorField
          id="description"
          value={description}
          label="Description"
          hideLabel
          readOnly
        />
      </div>
    </RowDisplay>
  );
}

Description.propTypes = {
  description: PropTypes.string,
  groupId: PropTypes.string,
  updateGroup: PropTypes.func,
  readOnly: PropTypes.bool,
};

Description.defaultProps = {
  description: '',
  readOnly: false,
};
