import React from 'react';
import { BaseCard, BaseCardBody } from '@unite-us/ui';
import './NoFeeScheduleProvidedServiceCard.scss';

const NoFeeScheduleProvidedServiceCard = () => (
  <BaseCard>
    <BaseCardBody withPadding>
      <div className="no-fee-schedule-services-provided">No Contracted Services</div>
    </BaseCardBody>
  </BaseCard>
);

export default NoFeeScheduleProvidedServiceCard;
