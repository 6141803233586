import _ from 'lodash';

export default function sortByTitleNoResponseLast(values) {
  return values.sort((a, b) => {
    if (_.toLower(a.title) === 'no response') {
      return 1;
    }
    if (_.toLower(b.title) === 'no response') {
      return -1;
    }
    if (_.toLower(a.title) === 'prefer not to disclose') {
      return 1;
    }
    if (_.toLower(b.title) === 'prefer not to disclose') {
      return -1;
    }
    return (_.toUpper(a.title) < _.toUpper(b.title)) ? -1 : 1;
  });
}
