import { isEmpty } from 'lodash';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { useUpdateRecord, useInvalidateQueries } from 'src/api/APIHooks';
import Notifier from 'common/helpers/Notifier';

const useUpdateProvidedService = (id, params, successMessage = '', groupId) => {
  const { updateRecord } = useUpdateRecord('provided_service', {
    api: 'apiV4',
    mutationConfig: {
      onError: () => {
        Notifier.dispatch('error', 'Something went wrong...');
      },
    },
  });

  const invalidateQueries = useInvalidateQueries();

  return async () => {
    const invoiceUpdated = await updateRecord(id, params, { jsonApiParams: { groupId } });
    if (!isEmpty(successMessage) && isHttpSuccess(invoiceUpdated.status)) {
      Notifier.dispatch(
        invoiceUpdated.status,
        successMessage,
      );
    }

    invalidateQueries('provided_service');
  };
};

export const usePSActive = (id, groupId, caseId) => {
  const params = {
    state: 'active',
    case: caseId,
  };

  return useUpdateProvidedService(
    id,
    params,
    '',
    groupId,
  );
};
