// import axios from 'axios';
import { coreApi } from 'src/api/config';
import { UPDATE_REFERRAL } from 'actions';
import Notifier from 'common/helpers/Notifier';
import _ from 'lodash';

const updateReferral = (referral, params) => (
  async (dispatch) => {
    try {
      const serviceCaseId = referral.case.id;
      const description = _.trim(_.get(params, 'referral.description', ''));
      const serviceCasePayload = _.isEmpty(description) ?
        { description: _.get(referral, 'case.description') } : { description };

      const payload = await coreApi.updateRecord('case', serviceCaseId, serviceCasePayload);

      dispatch({
        type: UPDATE_REFERRAL,
        payload,
      });

      Notifier.dispatch(payload.status, 'Referral Successfully Updated');
      return payload;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  }
);

export default updateReferral;
