import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const DISPLAY_NAME = 'care-coordinator-assign-button';

const Assign = ({ showModal }) => (
  <button className={classNames(DISPLAY_NAME, 'text-action-blue')} onClick={showModal} type="button">
    Assign
  </button>
);

Assign.propTypes = {
  showModal: PropTypes.func.isRequired,
};

export default Assign;
