import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Icon } from '@unite-us/ui';
import {
  serviceNamesForDisplay,
  getSelectedServices,
} from 'src/components/Browse/utils/display';
import { isExternalProviderOrOONGroup } from 'src/components/Groups/utils';

const GroupListItemHeader = ({ group, serviceType, selectedServiceIds }) => {
  const groupName = get(group, 'name');
  const serviceTypes = get(group, 'service_types', []);
  const selectedServiceName = get(serviceType, 'name');
  const selectedServiceNamesFromGroup = getSelectedServices(serviceTypes, selectedServiceIds);
  const firstGroupServiceName = serviceNamesForDisplay(serviceTypes, 1);

  return (
    <div className="group-list-item-header">
      <div className="group-list-item-header__group-container">
        <h3 className="group-list-item-header__group-name">
          {groupName}
        </h3>
        {
          isExternalProviderOrOONGroup(group) && (
            <span
              className="group-list-item-header__group-icon"
              data-tooltip="Out of Network Organization"
              data-tooltip-left
            >
              <Icon icon="IconElectronsArrow" />
            </span>
          )
        }
      </div>

      <h4 className="group-list-item-header__service-type">
        {
          selectedServiceName ||
          selectedServiceNamesFromGroup ||
          firstGroupServiceName
        }
      </h4>
    </div>
  );
};

GroupListItemHeader.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  serviceType: PropTypes.shape({
    name: PropTypes.string,
  }),
  selectedServiceIds: PropTypes.array,
};

GroupListItemHeader.defaultProps = {
  serviceType: {},
  selectedServiceIds: [],
};

export default GroupListItemHeader;
