import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

const ContactDataPoint = ({ label, value }) => (
  !value ? null : (
    <p>
      <span className="field-type strong">{label}: </span>
      <span>{value}</span>
    </p>
  )
);

ContactDataPoint.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

const ContactDataAsDisplay = ({ fields }) => (
  <div className="contact-data-as-display">
    <ContactDataPoint
      label="Email"
      value={fields.email.value}
    />
    <ContactDataPoint
      label="Phone"
      value={fields.phone.value}
    />
    <ContactDataPoint
      label="Phone Type"
      value={fields.phone_type.value && _.capitalize(fields.phone_type.value)}
    />
  </div>
);

ContactDataAsDisplay.propTypes = {
  fields: PropTypes.object.isRequired,
};

export default ContactDataAsDisplay;
