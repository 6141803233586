import { get, isNil } from 'lodash';

function buildContractedProgramOptions(program, labelType) {
  if (!isNil(get(program, 'relationships.fee_schedule_program.data'))) {
    return {
      ...program,
      [labelType]: `${program.attributes.name} (Contracted Program)`,
      value: program.id,
    };
  }
  return {
    ...program,
    [labelType]: program.attributes.name,
    value: program.id,
  };
}

export default buildContractedProgramOptions;
