/* eslint-disable */
import { get, isEmpty } from 'lodash';
import { fetchCurrentAppVersion } from 'actions/SystemVersion';
import sessionUpdateRequiredNotification from
  'src/common/utils/SystemNotifications/actions/systemUpdateRequiredNotification';

const checkVersionCommon = (dispatch) => {
  dispatch(fetchCurrentAppVersion()).then((payload) => {
    if (isEmpty(payload)) {
      console.log('error getting app version');
    } else if (get(payload, 'version') !== window.runningAppVersion) {
      const currVersionCheck = `${window.runningAppVersion}-banner-shown`;
      if (localStorage.getItem(currVersionCheck) === null) {
        //show update banner
        dispatch(sessionUpdateRequiredNotification());
        localStorage.setItem(currVersionCheck, "true");
      } else {
        //banner already shown once. Don't show again
        console.log('using outdated application version. application needs update');
      }
    }
  })
};

export default checkVersionCommon;
