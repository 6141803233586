import _ from 'lodash';
import { Link } from 'react-router';

export default function pickValidLinkProps(props) {
  const validLinkPropKeys = [
    ..._.keys(Link.propTypes),
    'children',
  ];
  return _.uuPick(props, validLinkPropKeys);
}
