import moment from 'moment';

function formatStartDate(date) {
  if (date === 'last_6_months') {
    return moment().subtract(6, 'months').format('YYYY-MM-DD');
  }
  if (date === 'last_1_year') {
    return moment().subtract(12, 'months').format('YYYY-MM-DD');
  }
  return '';
}

export default formatStartDate;
