import canSendDraft from './canSendDraft';

function sendDraft({
  caseReferrals,
  groupId: providerId,
  onSubmit,
  referral,
  user,
  crtb1239AdaptDraftReferralFlowEnabled,
}) {
  return canSendDraft({
    caseReferrals, providerId, referral, user,
  }) ? {
    label: crtb1239AdaptDraftReferralFlowEnabled ? 'Edit and Send' : 'Send',
    description: crtb1239AdaptDraftReferralFlowEnabled ?
      'Update the referral before sending it to another program.' :
      'Send the referral to another program.',
    icon: 'Forward',
    value: 'sendDraft',
    className: 'take-action-send-draft',
    action: onSubmit,
  } : null;
}

export default sendDraft;
