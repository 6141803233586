import { forIn, get, find, compact } from 'lodash';
import flattenServiceTypes from "../serviceTypes/flattenServiceTypes";
function findServiceType(serviceTypes, id) {
  return find(serviceTypes, {
    id: id
  });
}
function buildServiceTypeNames(serviceTypes, ids) {
  var serviceTypeNames = ids.map(function (id) {
    var serviceType = findServiceType(serviceTypes, id);
    return get(serviceType, 'name');
  });
  return compact(serviceTypeNames);
}
function findCareCoordinator(coordinators, id) {
  return find(coordinators, {
    user: {
      id: id
    }
  });
}
function buildCoordinatorObjects(coordinators, ids) {
  var coordinatorsObjects = ids.map(function (filterId) {
    var coordinator = findCareCoordinator(coordinators, filterId);
    if (coordinator) {
      return {
        id: get(coordinator, 'user.id'),
        full_name: get(coordinator, 'user.full_name')
      };
    }
    return null;
  });
  return compact(coordinatorsObjects);
}
export default function buildDashboardFilterPayload(state, props) {
  var filters = {};
  forIn(get(state, 'filters', []), function (value, key) {
    switch (key) {
      case 'service_types':
        {
          var serviceTypes = flattenServiceTypes(get(props, 'serviceTypes', []), true);
          filters[key] = buildServiceTypeNames(serviceTypes, value);
          break;
        }
      case 'referral_sender_users':
      case 'care_coordinator_users':
        {
          var coordinators = get(props, 'careCoordinators', []);
          filters[key] = buildCoordinatorObjects(coordinators, value);
          break;
        }
      default:
        filters[key] = value;
    }
  });
  return filters;
}