import {
  UPDATE_GLOBAL_STATE,
} from 'actions';
import { saveKeyToSessionStorage } from 'common/utils/utils';

export const GLOBAL_STATE_SESSION_STORAGE_KEY = 'uniteusGlobalState';

export const defaultState = {
  providers: [],
  employees: [],
  currentEmployee: undefined,
  currentProvider: undefined,
  impersonation: null,
  roles: [],
  intercom: {
    companies: undefined,
    userHash: undefined,
  },
  activeNetworks: [],
  allNetworks: [],
  language: 'en',
  isShoppingCartOpen: false,
};

export default function globalReducer(state = defaultState, action) {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case UPDATE_GLOBAL_STATE: {
      const newState = {
        ...state,
        ...payload,
        intercom: {
          ...state.intercom,
          ...payload.intercom,
        },
      };
      saveKeyToSessionStorage(GLOBAL_STATE_SESSION_STORAGE_KEY, newState);
      return newState;
    }
    default:
      return state;
  }
}
