import { coreApi } from 'src/api/config';

export default async function fetchServiceCaseReferrals(caseId) {
  const referrals = await coreApi.query('referral', { case: caseId });
  if (referrals) {
    const promises = referrals.data.data.map(
      (r) => coreApi.populateRelationship('receiving_provider', 'provider', r)
    );
    await Promise.all(promises);
  }
  return referrals;
}
