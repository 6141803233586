import canReject from './canReject';
import { REJECT_MODAL_DIALOG } from '../../constants';

function reject({
  user,
  isCC,
  groupId,
  openModal,
  referral,
}) {
  if (
    !canReject({
      isCC,
      user,
      groupId,
      referral,
    })
  ) {
    return null;
  }

  const label = isCC && groupId !== referral.receiving_provider.id ? 'Return to sender' : 'Reject';

  return {
    label,
    value: 'reject',
    className: 'take-action-reject',
    icon: 'CrossCircleO',
    description: 'Let the sender know you can’t help the client.',
    action: () => openModal(REJECT_MODAL_DIALOG),
  };
}

export default reject;
