import { get } from 'lodash';
import { useFindRecord } from 'src/api/APIHooks';

const usePlanMetafields = (enrollmentRequest) => {
  const { data } = useFindRecord(
    'plan',
    get(enrollmentRequest, 'plan.id'),
  );
  return get(data, 'data.data.metafields', []);
};

export default usePlanMetafields;
