import { Workflows } from '@unite-us/workflows';
import Notifier from 'common/helpers/Notifier';
import { Spinner } from 'common/spinners';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as apiHooks from 'src/api/APIHooks';
import { coreApi, getAuthToken } from 'src/api/config';
import * as standardHooks from 'src/api/standardHooks';
import { isTasksMenuEnabled, isTasksListV2Enabled } from 'src/common/utils/FeatureFlags/flags';
import * as useFormSubmissions from 'src/components/FormSubmissions/useFormSubmissions';
import * as useEmployeeHooks from 'src/components/Organization/api/hooks/v1/employeeHooks';
import ScreeningDetail from 'src/components/Screenings/Detail/ScreeningDetail';
import { hasUserRole } from 'src/components/User/utils';
import { CORE_API } from 'src/config/env/env.config';
import './Tasks.scss';

const Tasks = (props) => {
  const {
    employeeId, providerId, canCreateTasks, canSeePage, router, location,
  } = props;
  const authToken = getAuthToken();

  if (!canSeePage) {
    router.push('/');
  }

  const queryModal = 'showTaskInputModal';
  const queryParams = new URLSearchParams(location.search);
  const isShowModal = queryParams.get(queryModal) === 'true';
  const [showTaskInputModal, setShowTaskInputModal] = useState(isShowModal);
  const currentLocation = location.state?.pathname;

  return (
    <Workflows
      {...props}
      appSettings={
        {
          env: {
            authToken,
            currentEmployeeId: employeeId,
            currentProviderId: providerId,
          },
          endpoints: {
            core: CORE_API,
          },
          flags: {
            isTasksListV2Enabled: canCreateTasks,
          },
          manualTaskModal: {
            showTaskInputModal,
            setShowTaskInputModal,
            currentLocation,
          },
        }
      }
      hooks={{
        ...useFormSubmissions,
        ...apiHooks,
        ...standardHooks,
        ...useEmployeeHooks,
      }}
      components={{
        AssessmentDetailsView: ScreeningDetail,
        Spinner,
      }}
      callbacks={{
        toast: {
          success: (message) => Notifier.dispatch(200, message),
          error: (message) => Notifier.dispatch(400, message),
          warn: (message) => Notifier.dispatch(100, message),
        },
      }}
      apis={{
        core: coreApi,
      }}
    />
  );
};

Tasks.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    state: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  employeeId: PropTypes.string.isRequired,
  providerId: PropTypes.string.isRequired,
  canCreateTasks: PropTypes.bool.isRequired,
  canSeePage: PropTypes.bool.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  const employeeId = _.get(state, 'globalState.currentEmployee.id', '');
  const providerId = _.get(state, 'globalState.currentProvider.group.id', '');
  const canCreateTasks = isTasksListV2Enabled(state);
  const canSeePage =
    hasUserRole({
      groupId: state.session.groupId,
      name: 'Tasks',
      roleType: 'feature',
      user: state.user,
    }) && isTasksMenuEnabled(state);

  return {
    employeeId,
    providerId,
    canCreateTasks,
    canSeePage,
  };
}

export default connect(mapStateToProps)(withRouter(Tasks));
