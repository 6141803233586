/**
 * Compare dates, in descending order.
 *
 * Params can be anything the Date constructor accepts, (e.g. timestamp, dateString, dateObject) or undefined.
 * Missing(undefined) dates will be sorted last.
 *
 * @param {number|string|Date|undefined} a
 * @param {number|string|Date|undefined} b
 * @returns {number}
 */
export function comparePossiblyMissingDatesDescending(a, b) {
  const aDate = new Date(a).valueOf();
  const bDate = new Date(b).valueOf();
  if (Number.isNaN(bDate)) {
    return Number.isNaN(aDate) ? 1 : aDate;
  }
    return Number.isNaN(aDate) ? -1 : bDate - aDate;
}
