import _ from 'lodash';

export function getFieldValue(fields) {
  if (typeof fields === 'object') {
    if (fields && _.has(fields, 'value')) {
      return fields.value;
    }
    return _.flattenDeep(_.map(fields, (field) => getFieldValue(field)));
  }
  return [];
}

function filterCaseFields(serviceCase) {
  return _.uuOmit(serviceCase, ['documents', 'uploadedDocuments', 'oonCase']);
}

export function removeOptionalFields(fields) {
  const { serviceCase, programId, programEntry } = fields.options;

  return [filterCaseFields(serviceCase), programId, programEntry];
}

export default function checkFields(fieldsArray) {
  const result = getFieldValue(fieldsArray);
  return result.includes('');
}
