import {
  dateStyles,
  phoneStyles,
  serviceTypeStyles,
  statusExtendedStyles,
  statusStyles,
  takeActionStyles,
} from './dynamicTableStyles';

function baseColumnStyles(type) {
  switch (type) {
    case 'date':
      return dateStyles;
    case 'phoneNumber':
      return phoneStyles;
    case 'serviceType':
      return serviceTypeStyles;
    case 'status':
      return statusStyles;
    case 'statusExtended':
      return statusExtendedStyles;
    case 'takeAction':
      return takeActionStyles;
    default:
      return {};
  }
}

export default baseColumnStyles;
