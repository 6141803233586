import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'common/utils/browserHistory';
import {
  createContactAddress,
  updateGroupContact,
} from 'actions/Contact/Group';
import SearchConfirmation from 'common/display/ContactStepper/components/SearchConfirmation';
import mergeContactWithRequestorDetails from 'src/components/Contacts/utils/mergeContactWithRequestorDetails';
import _ from 'lodash';

export function goToAddContactInformation(id) {
  const prefix = id ?
    `/assistance-requests/${id}` :
    '';

  browserHistory.push(`${prefix}/referrals/new/add-contact-information?not_found=true`);
}

export class SearchContactConfirmationStep extends Component {
  constructor(props) {
    super(props);

    this.saveContact = this.saveContact.bind(this);
    this.navigateToAddServiceTypes = this.navigateToAddServiceTypes.bind(this);
  }

  saveContact(contact) {
    const { groupId, requestor } = this.props;

    if (!_.isEmpty(requestor)) {
      const contactId = _.get(contact, 'id');
      const mergedContact = mergeContactWithRequestorDetails(contact, requestor);

      this.props.updateGroupContact(
        groupId,
        contactId,
        mergedContact,
        { showNotification: false },
      ).then(() => this.navigateToAddServiceTypes());
    }
    this.navigateToAddServiceTypes();
  }

  navigateToAddServiceTypes() {
    const { requestId } = this.props;

    if (requestId) {
      browserHistory.push({
        pathname: `/assistance-requests/${requestId}/referrals/new/add-service-types`,
      });
    } else {
      browserHistory.push({
        pathname: '/referrals/new/add-service-types',
      });
    }
  }

  render() {
    const { requestId, assistanceRequest } = this.props;

    return (
      <SearchConfirmation
        className="new-referrals-confirmation"
        header="Create Referral"
        mainHeader="We Found Matching Records"
        onNotFound={() => goToAddContactInformation(requestId)}
        subHeader="The information you provided matched our existing records."
        onContactConfirmation={this.saveContact}
        assistanceRequest={assistanceRequest}
        searchContext="referral"
      />
    );
  }
}

SearchContactConfirmationStep.propTypes = {
  groupId: PropTypes.string.isRequired,
  params: PropTypes.shape({
    assistance_request_id: PropTypes.string,
  }).isRequired,
  requestId: PropTypes.string,
  requestor: PropTypes.object.isRequired,
  updateGroupContact: PropTypes.func.isRequired,
  assistanceRequest: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  const requestId = _.get(ownProps, 'params.assistance_request_id');
  const requests = _.get(state, 'assistanceRequests.requests', []);
  const assistanceRequest = _.find(requests, { id: requestId }) || {};
  const requestor = _.get(assistanceRequest, 'requestor', {});
  const groupId = _.wget(state, 'session.groupId', '');

  return {
    groupId,
    requestId,
    requestor,
    assistanceRequest,
  };
}

export default connect(mapStateToProps, {
  createContactAddress,
  updateGroupContact,
})(SearchContactConfirmationStep);
