import PropTypes from 'prop-types';
import React from 'react';
import AddCirclePlusButton from 'common/display/Profile/components/AddCirclePlusButton';

export const NotesHeader = (props) => {
    const {
        editable,
        styles,
        onAddNote,
        notesTitle,
    } = props;

    return (
      <div className="mb-4 flex justify-end" style={styles}>
        <h2 className="w-9/12">
          {notesTitle}
        </h2>
        <div className="w-3/12 flex justify-end">
          {editable && (
            <AddCirclePlusButton
              className="text-right"
              id="add-case-note-button"
              labelText="Add Note"
              addHandler={onAddNote}
            />
          )}
        </div>
      </div>
    );
};

NotesHeader.propTypes = {
    editable: PropTypes.bool,
    styles: PropTypes.object,
    onAddNote: PropTypes.func.isRequired,
    notesTitle: PropTypes.string,
};

NotesHeader.defaultProps = {
    editable: true,
    styles: {
      position: { marginTop: '0px' },
    },
    notesTitle: 'Notes',
};

export default NotesHeader;
