var getTelLink = function getTelLink(phoneNumber) {
  var includeExtension = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (!phoneNumber) return '#';
  var cCode = phoneNumber.country_code;
  var country = cCode === '01' ? '1' : cCode;
  var fullNumber = phoneNumber.phone_number ? phoneNumber.phone_number : '';
  var city = fullNumber.slice(0, 3);
  var number = fullNumber.slice(3, 10);
  var extension = phoneNumber.extension ? phoneNumber.extension : '';
  if (includeExtension && !!extension) {
    return "tel:+".concat(country).concat(city).concat(number, ",").concat(extension);
  }
  if (country && city && number) {
    return "tel:+".concat(country).concat(city).concat(number);
  }
  return '#';
};
export default getTelLink;