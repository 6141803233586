export default function hexToRgba(color, alpha = 1) {
  if (alpha > 1 || alpha < 0) {
    throw new Error('Alpha should be 0 < alpha < 1');
  }
  const hex = color.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
