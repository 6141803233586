import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog } from '@unite-us/ui';
import './confirmationDialog.scss';

class ConfirmationDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.onCancel = this.onCancel.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  onCancel() {
    this.onClose('cancel');
  }

  onConfirm() {
    this.onClose('confirm');
  }

  onClose(result) {
    const closeFn = this.props.onCloseDialog ?
      this.props.onCloseDialog(result) :
      null;

    Promise.resolve(closeFn).then(() => {
      this.closeDialog(result);
    });
  }

  closeDialog(result) {
    this.confirmationDialog.closeDialog(result);
  }

  render() {
    const {
      cancelLabel, children, confirmLabel, size,
    } = this.props;

    return (
      <Dialog
        className="confirmation-dialog"
        ref={(el) => { this.confirmationDialog = el; }}
        size={size}
        actions={(
          <div className="confirmation-dialog__actions">
            <Button
              className="confirmation-dialog__actions--cancel"
              label={cancelLabel}
              onClick={this.onCancel}
            />
            <Button
              className="confirmation-dialog__actions--confirm"
              onClick={this.onConfirm}
              label={confirmLabel}
              primary
            />
          </div>
        )}
      >
        <div className="confirmation-dialog__contents">
          {children}
        </div>
      </Dialog>
    );
  }
}

ConfirmationDialog.propTypes = {
  cancelLabel: PropTypes.string,
  children: PropTypes.node,
  confirmLabel: PropTypes.string,
  onCloseDialog: PropTypes.func,
  size: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  cancelLabel: 'Cancel',
  children: <p>Are you sure?</p>,
  confirmLabel: 'Confirm',
  size: 'mini',
};

export default ConfirmationDialog;
