import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dialog } from '@unite-us/ui';
import { noop } from 'lodash';
import {
  CLOSE_REFERRAL_MODAL_DIALOG,
} from 'src/components/Referrals/ReferralStatus/constants';
import CloseReferralForm from './CloseReferralForm';

class CloseReferralModalDialog extends Component {
  constructor(props) {
    super(props);

    this.onChangeSubmit = this.onChangeSubmit.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);

    this.state = {
      submitting: false,
    };
  }

  onChangeSubmit(submitting) {
    this.setState({ submitting });
  }

  openDialog() {
    return this[CLOSE_REFERRAL_MODAL_DIALOG].openDialog()
      .then(this.props.resetActionValue);
  }

  closeDialog() {
    this[CLOSE_REFERRAL_MODAL_DIALOG].closeDialog();
  }

  render() {
    const {
      modal,
      outcomes,
      outcomesIsFetching,
      referral,
      referralId,
      isCC,
    } = this.props;
    const { submitting } = this.state;

    return (
      <div className="close-referral-modal-dialog">
        <Dialog
          ref={(el) => { this[CLOSE_REFERRAL_MODAL_DIALOG] = el; }}
          title="Close Referral"
          size="large"
          modal={modal}
          spinnerShow={submitting}
          spinnerText="Closing Referral..."
          data-testid="close-referral-modal"
        >
          <CloseReferralForm
            closeDialog={this.closeDialog}
            onChangeSubmit={this.onChangeSubmit}
            outcomes={outcomes}
            outcomesIsFetching={outcomesIsFetching}
            referralId={referralId}
            referral={referral}
            isCC={isCC}
          />
        </Dialog>
      </div>
    );
  }
}

CloseReferralModalDialog.propTypes = {
  modal: PropTypes.bool.isRequired,
  outcomes: PropTypes.array.isRequired,
  outcomesIsFetching: PropTypes.bool.isRequired,
  referralId: PropTypes.string.isRequired,
  resetActionValue: PropTypes.func,
  referral: PropTypes.object,
  isCC: PropTypes.bool.isRequired,
};

CloseReferralModalDialog.defaultProps = {
  modal: true,
  outcomesIsFetching: false,
  resetActionValue: noop,
  referral: {},
};

export default CloseReferralModalDialog;
