import { isEmpty } from 'lodash';
import getAddressString from './getAddressString';

const getAddressObject = ({ address, id }, latLng) => ({ address, latLng, id });

const getCurrentAddress = ({
  latLng, clientAddress, groupAddress, employeeAddress, addressType,
}) => {
  const { lat, lng } = latLng || {};
  let currentAddress = {};

  if (lat && lng) {
    if (!isEmpty(clientAddress) && addressType === 'client') {
      currentAddress = { address: clientAddress.address || '', id: clientAddress.id };
    } else if (!isEmpty(employeeAddress)) {
      currentAddress = { address: getAddressString(employeeAddress), id: employeeAddress.id };
    } else if (!isEmpty(groupAddress)) {
      currentAddress = { address: getAddressString(groupAddress), id: groupAddress.id };
    } else {
      currentAddress = { address: undefined, id: undefined };
    }
    currentAddress = getAddressObject(currentAddress, latLng);
  }

  return currentAddress;
};

export default getCurrentAddress;
