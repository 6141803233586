import { get } from 'lodash';
import { coreApi } from 'src/api/config';

export const fetchClientRelationship = async (contactId, groupId) => {
  const crResponse = await coreApi.query('client_relationship', {
    person: contactId,
    provider: groupId,
  });
  const clientRelationship = get(crResponse, 'data.data[0]', {});
  await coreApi.populateRelationship('care_coordinator', 'employee', clientRelationship);
  await coreApi.populateRelationship('care_coordinator.provider', 'provider', clientRelationship);

  return clientRelationship;
};
