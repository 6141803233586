import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { validations } from '@unite-us/app-components';
import { validateReduxForm } from 'common/form';
import { isHttpError } from 'common/utils/Error';
import {
  DateField,
  InputField,
  Button,
  FormLegend,
} from '@unite-us/ui';
import { searchContact } from 'actions/Search/Group';
import today from 'common/utils/today';
import minBirthDate from 'common/utils/minBirthDate';
import { Spinner } from 'common/spinners';
import Header from './Header';
import { CONTACT_SEARCH_FORM } from '../constants';

export const fields = ['first_name', 'last_name', 'date_of_birth'];

export class SearchForm extends Component {
  static handleEnter(e) {
    if (e.key === 'Enter') {
      const searchRecordsButton = document.getElementById('search-records-btn');
      if (searchRecordsButton) {
        searchRecordsButton.focus();
      }
    }
  }

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', SearchForm.handleEnter);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', SearchForm.handleEnter);
  }

  onSubmit(formData) {
    this.setState({ loading: true });
    const { groupId } = this.props;
    const searchAction = this.props.searchContact;
    const showNotification = true;
    return searchAction(groupId, formData, showNotification)
      .then((response) => {
        if (!isHttpError(response)) {
          const contacts = get(response, 'data.data', []);
          return this.props.onComplete(contacts, formData);
        }
        return response;
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const {
      className,
      fields: { first_name, last_name, date_of_birth },
      handleSubmit,
      header,
      mainHeader,
      registerField,
      subHeader,
    } = this.props;

    const { loading } = this.state;

    return loading ? (
      <Spinner />
    ) : (
      <div {...(className ? { className } : {})}>
        <Header
          header={header}
          mainHeader={mainHeader}
          subHeader={subHeader}
        />
        <FormLegend />
        <form className="row" onSubmit={handleSubmit(this.onSubmit)}>
          <div className="col-md-6">
            <InputField
              ref={registerField}
              field={first_name}
              label="First Name"
              id="first-name"
              inline={false}
              validations={validations.isRequired}
              required
            />
          </div>
          <div className="col-md-6">
            <InputField
              ref={registerField}
              field={last_name}
              label="Last Name"
              id="last-name"
              inline={false}
              validations={validations.isRequired}
              required
            />
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4">
                <DateField
                  ref={registerField}
                  field={date_of_birth}
                  id="date-of-birth"
                  inline={false}
                  label="Date of Birth"
                  validations={validations.isRequired}
                  maxDate={today()}
                  minDate={minBirthDate()}
                  required
                />
              </div>
            </div>
          </div>
          <footer className="col-md-12 referral-footer">
            <Button
              id="search-records-btn"
              disabled={loading}
              className="search-records-btn"
              onClick={handleSubmit(this.onSubmit)}
              label="Search Our Records"
              primary
              style={{ float: 'right' }}
              type="submit"
            />
          </footer>
        </form>
      </div>
    );
  }
}

SearchForm.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  mainHeader: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  registerField: PropTypes.func.isRequired,
  searchContact: PropTypes.func.isRequired,
  subHeader: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
};

SearchForm.defaultProps = {
  className: '',
  mainHeader: null,
  submitting: false,
};

function mapStateToProps(state) {
  const { session: { groupId } } = state;
  return {
    groupId,
  };
}

export default validateReduxForm(
  {
    form: CONTACT_SEARCH_FORM,
    fields,
    destroyOnUnmount: true,
  },
  mapStateToProps,
  {
    searchContact,
  },
)(SearchForm);
