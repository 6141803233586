import { noop } from 'lodash';
import { browserHistory } from 'common/utils/browserHistory';
import callOrLog from 'src/common/utils/callOrLog';
import { NEW_CLIENT } from 'common/utils/EventTracker/utils/eventConstants';
import { generateUUID } from 'common/utils/utils';
import STEP_DEFAULTS from './utils/defaults';
import {
  ADD_INFORMATION_PATH,
  CONFIRM_PATH,
  SEARCH_PATH,
} from '../constants';

const referralsStepperConfig = {
  steps: [
    {
      ...STEP_DEFAULTS.first,
      title: 'Select Client',
      onStepClick: () => {
        browserHistory.push(`/referrals/${SEARCH_PATH}`);
      },
      onStepComplete: ({
        contact = {},
        contactId = null,
        eventTracker = noop,
      }) => {
        const event = contactId ? NEW_CLIENT.createFromReferral : NEW_CLIENT.updateFromReferral;
        const newClientContactId = contact.id;
        callOrLog(() => eventTracker(event, null, {
          contact,
        }));
        browserHistory.push({
          pathname: '/referrals/create/add-resources',
          search: `?person=${contactId || newClientContactId}`,
          state: { source: 'client' },
        });
      },
      routes: [
        {
          route: `/referrals/${SEARCH_PATH}`,
        },
        {
          route: `/referrals/${CONFIRM_PATH}`,
        },
        {
          route: `/referrals/${ADD_INFORMATION_PATH}`,
        },
      ],
    },
    {
      ...STEP_DEFAULTS.second,
      title: 'Add Resources',
    },
    {
      ...STEP_DEFAULTS.third,
      title: 'Refer',
    },
    {
      ...STEP_DEFAULTS.fourth,
      title: 'Add Assessments',
    },
    {
      id: generateUUID(),
      isStepDisabled: ({
        activeStepIndex = -1,
      }) => activeStepIndex < 4,
      isStepCompleted: () => false,
      title: 'Review',
    },
  ],
};

export default referralsStepperConfig;
