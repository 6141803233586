const styles = [
  {
    featureType: 'landscape.man_made',
    stylers: [
      { color: '#b2d9fb' },
      { visibility: 'off' },
    ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [
      { visibility: 'off' },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      { visibility: 'off' },
    ],
  },
  {
    featureType: 'poi.business',
    stylers: [
      { visibility: 'off' },
    ],
  },
  {
    featureType: 'poi.government',
    stylers: [
      { visibility: 'on' },
    ],
  },
  {
    featureType: 'poi.medical',
    stylers: [
      { visibility: 'on' },
    ],
  },
  {
    featureType: 'poi.park',
    stylers: [
      { visibility: 'on' },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text',
    stylers: [
      { visibility: 'off' },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels',
    stylers: [
      { visibility: 'off' },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels',
    stylers: [
      { visibility: 'off' },
    ],
  },
  {
    featureType: 'road.local',
    stylers: [
      { visibility: 'off' },
    ],
  },
  {
    featureType: 'transit.station',
    stylers: [
      { visibility: 'off' },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      { color: '#d1eafe' },
    ],
  },
];

export default styles;
