import { useCallback, useState } from 'react';
import { useCreateRecord } from 'src/api/APIHooks';
import Notifier from 'common/helpers/Notifier';
import { get, uniq, without } from 'lodash';
import { usePendingNotificationCount } from 'src/components/Organization/utils/usePendingNotifications';

const useInvoiceExports = ({ currentEmployeeId, fetchAllInvoices }) => {
  const { incrementPendingNotificationCount } = usePendingNotificationCount();
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [allInvoicesLoading, setAllInvoicesLoading] = useState(false);
  const deselectAll = useCallback(() => setSelectedInvoices([]));
  const selectInvoices = useCallback((...invoiceIds) => {
    setSelectedInvoices(uniq([...selectedInvoices, ...invoiceIds]));
  });
  const deselectInvoices = useCallback((...invoiceIds) => {
    setSelectedInvoices(without(selectedInvoices, ...invoiceIds));
  });
  const selectAll = useCallback(async () => {
    setAllInvoicesLoading(true);
    const allInvoices = await fetchAllInvoices();
    const allInvoiceIds = get(allInvoices, 'data.data', []).map((data) => data.id);
    selectInvoices(...allInvoiceIds);
    setAllInvoicesLoading(false);
  }, [fetchAllInvoices]);
  const { createRecord: createExport } = useCreateRecord('invoice_export');
  const downloadInvoices = useCallback(async () => {
    try {
      await createExport({
        export_type: 'invoices',
        requester: currentEmployeeId,
        state: 'requested',
        details: {
          invoice_ids: selectedInvoices,
        },
      });
      incrementPendingNotificationCount('export');
      Notifier.dispatch('information', "Export file is processing. You'll receive a notification when it's ready.");
    } catch (error) {
      if (window.Rollbar) {
        window.Rollbar.error('Failed to request export', { error });
      }
      Notifier.dispatch('error', 'There was a problem requesting your export.');
    }
  }, [createExport, currentEmployeeId, selectedInvoices]);
  return {
    allInvoicesLoading,
    deselectAll,
    deselectInvoices,
    downloadInvoices,
    selectAll,
    selectInvoices,
    selectedInvoices,
  };
};

export default useInvoiceExports;
