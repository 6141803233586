import { referralsAdminAndAboveIds } from 'src/components/User/utils/isReferralsAdminAndAbove';

export const isOrganizationAdministrator = ({ userRoles = [], allRoles = {} }) => {
  if (
    !allRoles.primaryRoles.length ||
    !userRoles.length
  ) {
    return false;
  }

  const orgAdminRole = allRoles.primaryRoles.find((role) => (role.key === 'org_admin'));
  const { id: orgAdminId } = orgAdminRole;
  return Boolean(userRoles.find((role) => role.id === orgAdminId));
};

export const isReferralUser = (userRoles = [], allRoles = {}) => {
  if (
    !allRoles.primaryRoles.length ||
    !userRoles.length
  ) {
    return false;
  }

  const referralUserRole = allRoles.primaryRoles.find((role) => (role.key === 'referrals_user'));
  const { id: referralUserId } = referralUserRole;
  return userRoles.some((role) => role.id === referralUserId);
};

export const canFetchActionables = (userRoles = [], allRoles = {}) => {
  if (
    !allRoles.primaryRoles.length ||
    !userRoles.length
  ) {
    return false;
  }

  const validRoleIds = referralsAdminAndAboveIds(allRoles);

  const userRoleIds = userRoles.map((r) => r.id);
  const hasRoles = [];
  userRoleIds.forEach((rid) => {
    if (validRoleIds.includes(rid)) {
      hasRoles.push(rid);
    }
  });

  return Boolean(hasRoles.length);
};
