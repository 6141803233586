import { isEmpty } from 'lodash';
import {
  isCurrentSection,
} from './index';

import { addElement } from '../addElements';

const addedState = (state, element, currentSection, postRemoveState) => {
  if (isEmpty(state)) {
    return undefined;
  }

  return (section) => {
    if (isCurrentSection(currentSection, section)) {
      return addElement(postRemoveState, element, section);
    }

    return postRemoveState || state;
  };
};

export { addedState };
