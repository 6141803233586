import _ from 'lodash';
import { addElement } from './addElements';
import { updateElement } from './updateElements';
import removeElement from './removeElement';

function updateCase(state, element, urlData, currentSection) {
  const toUpdateId = _.get(urlData, 'uuid', '');
  const modifier = _.get(urlData, 'modifier', '');

  switch (modifier) {
    case 'close': {
      switch (currentSection) {
        case 'closedCases':
          return addElement(state, element, 'closedCases');
        case 'openCases':
          return removeElement(state, toUpdateId, 'openCases');
        case 'allCases':
          return updateElement(state, toUpdateId, element, 'allCases');
        default:
          return state;
      }
    }
    default: {
      switch (currentSection) {
        case 'closedCases':
          return updateElement(state, toUpdateId, element, 'closedCases');
        case 'openCases':
          return updateElement(state, toUpdateId, element, 'openCases');
        case 'allCases':
          return updateElement(state, toUpdateId, element, 'allCases');
        default:
          return state;
      }
    }
  }
}

export default function updateCases(state, payload = [], urlData, currentSection) {
  if (_.isEmpty(payload)) { return state; }

  return updateCases(
    updateCase(state, _.head(payload), urlData, currentSection),
    _.tail(payload),
    urlData,
    currentSection,
  );
}
