import { coreApi } from 'src/api/config';
import {
  FETCH_REFERRAL_CONTACT,
  SET_CONTACTS_IS_FETCHING,
  UNSET_CONTACTS_IS_FETCHING,
} from 'actions';
import Notifier from 'common/helpers/Notifier';

const fetchReferralContact = (groupId, referralId) => (
  async (dispatch) => {
    try {
      const response = await coreApi.findRecord('referral', referralId);
      const referral = response.data.data;

      dispatch({ type: SET_CONTACTS_IS_FETCHING });

      await Promise.all([
        coreApi.populateRelationship('case', 'case', referral),
      ]);
      await Promise.all([
        coreApi.populateRelationship('case.person', 'person', referral),
      ]);

      const contact = referral.case.person;

      const payload = {
        ...response,
        data: {
          ...response.data,
          data: contact,
        },
      };

      dispatch({ type: FETCH_REFERRAL_CONTACT, payload });
      dispatch({ type: UNSET_CONTACTS_IS_FETCHING });

      return payload;
    } catch (error) {
      Notifier.handleErrors(error);
      dispatch({ type: UNSET_CONTACTS_IS_FETCHING });
      return error;
    }
  }
);

export default fetchReferralContact;
