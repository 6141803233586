import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReferralRowIcon from 'src/components/Dashboard/components/Referrals/ReferralRowIcon';
import getStatusDisplayName from 'src/components/Dashboard/utils/getStatusDisplayName';
import { TOP_NAVIGATION_HEIGHT } from 'src/components/Navigation/constants';
import _ from 'lodash';
import { DynamicTable } from 'common/tables';
import { Drawer } from '@unite-us/ui';
import { Spinner } from 'common/spinners';
import {
  Filter,
  shapes,
} from '@unite-us/client-utils';
import { setFilterSearchPlaceholder, setAsyncSearch } from '../../utils/filter';
import FilterBar from '../FilterBar';
import './ProviderToProviderReferralsIndex.scss';

function getColumns(statuses = []) {
  return [
    {
      label: 'Sender',
      sortColumn: 'referred_by_group.name',
    },
    {
      label: 'Recipient',
      sortColumn: 'referred_to_group.name',
    },
    {
      label: 'Client Name',
      sortColumn: 'contact.full_name',
    },
    {
      label: 'Service Type',
      sortColumn: 'service_type.name',
    },
    {
      label: 'Status',
      sortColumn: 'status',
      formatData: (referral) => getStatusDisplayName(referral, statuses),
      type: 'statusExtended',
    },
    {
      label: 'Last Updated',
      sortColumn: 'updated_at',
      type: 'date',
    },
  ];
}

class ProviderToProviderReferralsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.onClose = this.onClose.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  onClose() {
    this.setState({ open: false });
  }

  onToggle() {
    const { open } = this.state;

    this.setState({ open: !open });
  }

  render() {
    const {
      data,
      filters,
      paging,
      initialSort,
      isFetching,
      onCellClick,
      onFiltersChange,
      onNextClick,
      onPrevClick,
      statuses,
      fetchGroupsUsers,
    } = this.props;

    const {
      open,
    } = this.state;

    const secondaryFilters = filters.filter((f) => _.get(f, 'secondary'));

    return (
      <div className="provider-to-provider">
        <FilterBar
          filters={filters}
          onFiltersChange={onFiltersChange}
          onMoreFiltersClick={this.onToggle}
          paging={paging}
          onNextClick={onNextClick}
          onPrevClick={onPrevClick}
          isFetching={isFetching && !_.isEmpty(data)}
          asyncSearch={fetchGroupsUsers}
          showSecondaryFilters={!_.isEmpty(secondaryFilters)}
        />
        <div className="dashboard-inner-content">
          {
            (isFetching && _.isEmpty(data)) ?
              <Spinner /> : (
                <DynamicTable
                  id="open-channel-referrals-table"
                  dataSet={data}
                  onCellClick={onCellClick}
                  initialSort={initialSort}
                  columnLabels={getColumns(statuses)}
                  emptyMessage="There are no referrals."
                  rowIcon={(referral) => <ReferralRowIcon referral={referral} />}
                />
              )
          }
        </div>
        <Drawer
          className="provider-to-provider__drawer"
          open={open}
          secondary
          onClose={this.onClose}
          topOffset={TOP_NAVIGATION_HEIGHT}
        >
          <div className="provider-to-provider__drawer-contents">
            <div className="provider-to-provider__drawer-header">
              <h2 className="provider-to-provider__drawer-header-label">
                More Filters
              </h2>
            </div>
            <div className="provider-to-provider__drawer-body">
              {
                _.map(_.orderBy(secondaryFilters, ['name'], ['desc']), (f) => {
                  const {
                    asyncSearch: filterAsyncSearch,
                    searchPlaceholder,
                  } = f;

                  if (!f.secondary) {
                    return null;
                  }

                  return (
                    <Filter
                      hideSelectAll={f.hideSelectAll || false}
                      isSingleOptionSelect={f.isSingleOptionSelect || false}
                      key={f.key}
                      name={f.name}
                      pluralName={f.pluralName}
                      options={f.options}
                      onFiltersChange={(activeFilters) => onFiltersChange(f.key, activeFilters)}
                      searchPlaceholder={searchPlaceholder || setFilterSearchPlaceholder(f)}
                      asyncSearch={filterAsyncSearch !== undefined ?
                        setAsyncSearch(f, filterAsyncSearch) :
                        setAsyncSearch(f, fetchGroupsUsers)}
                      uniqIdPrefix={f.key}
                    />
                  );
                })
              }
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

ProviderToProviderReferralsIndex.propTypes = {
  data: PropTypes.array.isRequired,
  filters: PropTypes.array,
  initialSort: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  onCellClick: PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  paging: shapes.paging,
  statuses: PropTypes.array.isRequired,
  fetchGroupsUsers: PropTypes.func.isRequired,
};

export default connect()(ProviderToProviderReferralsIndex);
