function getGroupType({ group_type } = {}) {
  let providerType;
  if (group_type === 'out_of_network') {
    providerType = 'ADDRESS_BOOK_GROUP';
  } else if (group_type === 'external_provider') {
    providerType = 'UNLICENSED_PROVIDER';
  }

  return providerType;
}

export default getGroupType;
