export const REQUEST_TYPES = {
  disenroll: 'disenroll',
  enroll: 'enroll',
};

export const ENROLLMENT_STATUSES = {
  open: 'open',
  canceled: 'canceled',
  accepted: 'accepted',
  rejected: 'rejected',
};

export const NON_SOCIAL_PLAN_TYPES = 'medicare,medicaid,commercial,tricare';

export const ENROLLMENT_BULK_ACTIONS = {
  open: ['Accept'],
};
