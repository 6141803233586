import PropTypes from 'prop-types';
import React from 'react';
import { Button, Icon } from '@unite-us/ui';
import './PreviewUnavailableCard.scss';

const PreviewUnavailableCard = ({ doc }) => (
  <div className="uploaded-document-pdf content-with-actions">
    <div className="content-container container-styled">
      <div className="preview-unavailable-card-icon">
        <Icon
          icon="IconFileTextO"
          className="absolute fill-current pt-2 pl-3"
          size={27}
        />
      </div>
      <div className="preview-unavailable-card-text">Preview Unavailable</div>
    </div>
    <div
      className="actions uploaded-document-pdf__open-button"
      style={{ textAlign: 'center' }}
    >
      <a
        className="uploaded-document-pdf__link"
        style={{ textDecoration: 'none' }}
        role="link"
        href={doc.url}
        download={doc.title}
      >
        <Button id="download-document-btn" label="Download" />
      </a>
    </div>
  </div>
);

PreviewUnavailableCard.propTypes = {
    doc: PropTypes.shape({
        content_type: PropTypes.string,
        title: PropTypes.string.isRequired,
        url: PropTypes.string,
      }).isRequired,
};

export default PreviewUnavailableCard;
