import { FETCH_GROUP_OUTCOMES, SET_GROUP_OUTCOMES_FETCHING } from 'actions';
import Notifier from 'common/helpers/Notifier';
import { coreApi } from 'src/api/config';

const defaultParams = {
  applicable_states: ['cases', 'referral', 'assistance_requested'],
  sord: 'asc',
  sidx: 'name',
};

const onError = (error, dispatch) => {
  Notifier.handleErrors(error);
  return dispatch({ type: SET_GROUP_OUTCOMES_FETCHING, isFetching: false });
};

const fetchGroupOutcomes = (provider, serviceTypeId, options = {}) => (
  async (dispatch) => {
    try {
      const params = { ...defaultParams, ...options };
      dispatch({ type: SET_GROUP_OUTCOMES_FETCHING, isFetching: true });
      const [serviceResponse, outcomesResponse] = await Promise.all([
        coreApi.findRecord('service', serviceTypeId),
        coreApi.query('outcome', {
          provider,
          service: serviceTypeId,
          // applicable_states: assistance_requested / off_platform / accepted / referred
          applicable_states: params.applicable_states.join(','),
        }),
      ]);

      const service = serviceResponse.data.data;
      const outcomes = outcomesResponse.data.data;
      outcomesResponse.data.data = [
        {
          ...service,
          outcomes,
          service_type_id: serviceTypeId,
        },
      ];

      dispatch({
        type: FETCH_GROUP_OUTCOMES,
        payload: outcomesResponse,
      });
      return outcomesResponse;
    } catch (error) {
      return onError(error, dispatch);
    }
  }
);

export default fetchGroupOutcomes;
