import { massageAddressFields } from 'common/utils/addressUtils';
import { cleanMilitaryFields } from 'src/components/MilitaryInformation/utils';
import { mapKeys } from 'src/components/Group/Programs/Edit/utils';
import { uuCompactArrayOrObject, compact, get } from 'lodash';

export function formatEmail(email = {}) {
  return email.email_address && {
    id: email.id,
    acceptable_communication_types: mapKeys(email.acceptable_communication_types),
    email_address: email.email_address,
    is_primary: email.is_primary || false,
  };
}

export function formatPhone(phone = {}) {
  return phone.phone_number && {
    id: phone.id,
    acceptable_communication_types: mapKeys(phone.acceptable_communication_types),
    is_primary: phone.is_primary || false,
    phone_number: phone.phone_number.replace(/\D/g, ''),
    phone_type: phone.phone_type || 'unknown',
  };
}

export function formatContact(values) {
  const emails = get(values, 'email_addresses', []);
  const phones = get(values, 'phone_numbers', []);
  const ssn = get(values, 'ssn', '');

  return uuCompactArrayOrObject({
    ...values,
    email_addresses: compact(emails.map((email) => formatEmail(email))),
    military: cleanMilitaryFields(values.military),
    phone_numbers: compact(phones.map((phone) => formatPhone(phone))),
    ssn: ssn.replace(/\D+/ig, ''),
  });
}

export function formatNewContactDataWithNoInsurance({
  fields = {},
  values = {},
}) {
  const cleanedValues = massageAddressFields(
    values,
    fields.addresses,
  );
  return formatContact(cleanedValues);
}
