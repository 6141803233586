import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VerticalDotsMenu from 'common/VerticalDotsMenu';
import { map } from 'lodash';
import './RowActionsMenu.scss';

class RowActionsMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuItems: map(props.actions, (menuItem = {}) => ({
        label: menuItem.label,
        onSelect: () => menuItem.action(props.data),
      })),
    };
  }

  render() {
    const { menuItems } = this.state;
    return (
      <VerticalDotsMenu
        className="row-actions-menu"
        id="vertical-dots-menu"
        menuItems={menuItems}
      />
    );
  }
}

RowActionsMenu.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    action: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired),
  data: PropTypes.object,
};

RowActionsMenu.defaultProps = {
  actions: [],
  data: {},
};

export default RowActionsMenu;
