import {
  useFind,
} from 'src/api/APIHooks';
import { defaultQueryConfig } from './apiHookOptions';

export const useUniteUsServices = () => useFind(
  'service',
  {
    taxonomy: 'uniteus',
  },
  {
    queryConfig: {
      staleTime: Infinity,
      ...defaultQueryConfig,
    },
    page: { number: 1, size: 300 },
  },
);
