import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OrganizationHeader from 'src/components/Organization/components/OrganizationHeader';
import { ORG_TABS } from 'src/components/Organization/constants';
import FormHeader from 'src/components/Organization/components/FormHeader';
import { browserHistory } from 'common/utils/browserHistory';
import mapProviderIdToProps from 'src/components/Organization/utils/mapProviderIdToProps';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import {
  useEmployeeWithRoles,
  useUpdateUser,
  useUpdateEmployee,
} from 'src/components/Organization/api/hooks/v1/';
import { Spinner } from 'src/common/spinners';
import { getEmployeePayload } from 'src/components/Organization/utils/getEmployeePayload';
import Notifier from 'common/helpers/Notifier';
import { ROLE_TYPES } from 'src/api/core/Roles/fetchRoles';
import useTrackPageView from 'src/components/Organization/utils/useTrackPageView';
import { ORG_SETTINGS } from 'src/common/utils/EventTracker/utils/eventConstants';
import FormContainer from 'src/components/Organization/components/FormContainer';
import UserForm from '../userForm';

const UserEdit = (props) => {
  useTrackPageView(ORG_SETTINGS.editUserViewed);
  const {
    params: { user_id: userId },
    currentProviderId,
    location,
  } = props;

  const { data: existingEmployee } = useEmployeeWithRoles(userId);
  const { isLoading: updateUserLoading, updateRecord: updateUser } =
    useUpdateUser();
  const { isLoading: updateEmployeeLoading, updateRecord: updateEmployee } =
    useUpdateEmployee();

  const initialValues = {
    first_name: existingEmployee?.first_name,
    last_name: existingEmployee?.last_name,
    email: existingEmployee?.email,
    work_title: existingEmployee?.work_title,
    userId: existingEmployee?.user?.id,
    phone_numbers: existingEmployee?.phone_numbers,
    line_1: existingEmployee?.addresses?.[0]?.line_1,
    line_2: existingEmployee?.addresses?.[0]?.line_2,
    city: existingEmployee?.addresses?.[0]?.city,
    state: existingEmployee?.addresses?.[0]?.state,
    county: existingEmployee?.addresses?.[0]?.county,
    postal_code: existingEmployee?.addresses?.[0]?.postal_code,
    country: existingEmployee?.addresses?.[0]?.country,
    latitude: existingEmployee?.addresses?.[0]?.latitude,
    longitude: existingEmployee?.addresses?.[0]?.longitude,
    user_status: existingEmployee?.state,
    user_primary_role: existingEmployee?.roles?.find(
      (role) => role.role_type === ROLE_TYPES.PRIMARY,
    ),
    user_feature_roles: existingEmployee?.roles?.filter(
      (role) => role.role_type === ROLE_TYPES.FEATURE,
    ),
    user_programs: existingEmployee?.programs,
  };

  const onSubmit = async (values) => {
    let updateUserResponse;
    // check if active inactive changed and update user
    if (existingEmployee?.state !== values.user_status || existingEmployee.email !== values.email) {
      updateUserResponse = await updateUser(existingEmployee?.user?.id, {
        active: values.user_status === 'active',
        email: values.email,
      });
    }

    // update employee
    const updateEmployeeResponse = await updateEmployee(
      existingEmployee?.id,
      getEmployeePayload(currentProviderId, userId, values),
    );

    if (
      ((!updateUserResponse || isHttpSuccess(updateUserResponse.status))) &&
      isHttpSuccess(updateEmployeeResponse.status)
    ) {
      Notifier.dispatch(
        updateEmployeeResponse.status,
        'User Successfully Updated',
      );
      browserHistory.push('/organization/settings/users');
    }
  };

  return (
    <>
      <OrganizationHeader currentTab={ORG_TABS.users} location={location} />
      <FormContainer>
        <FormHeader
          headingText="Edit User"
          cancelLink={`/organization/settings/users/${userId}`}
        />
        {updateEmployeeLoading || updateUserLoading ? (
          <Spinner center />
        ) : (
          <UserForm initialValues={initialValues} onSubmit={onSubmit} />
        )}
      </FormContainer>
    </>
  );
};

UserEdit.propTypes = {
  params: PropTypes.shape({
    user_id: PropTypes.string.isRequired,
  }).isRequired,
  currentProviderId: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapProviderIdToProps)(UserEdit);
