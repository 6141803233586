import { get, isEmpty } from 'lodash';

function checkPlanMemberIdFieldValues(values) {
  return (
    !isEmpty(get(values, 'plan_type')) &&
      (isEmpty(get(values, 'plan')) ||
      isEmpty(get(values, 'member_id')))
  );
}

export default checkPlanMemberIdFieldValues;
