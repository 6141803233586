const ScrollToElement = (ref) => {
  if (ref) {
    ref.scrollIntoView(
      {
        block: 'start',
      },
      );
  }
};

export default ScrollToElement;
