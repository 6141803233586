import { useEffect, useState } from 'react';
import { browserHistory } from '../utils/browserHistory';
import { ROUTE_BASEPATH } from '../../config/env/env.config';

const getUrlSearchParams = () => new URLSearchParams(window.location.search);
const getExistingParam = (params, key) => params.get(key) ?? undefined;
const getPathname = () => {
  const pathname = window.location.pathname;
  // Remove the basepath to ensure the basepath is not appended twice, e.g. devqa branches
  return ROUTE_BASEPATH === '/' ? pathname : pathname.replace(ROUTE_BASEPATH, '');
};
const getSearch = (params) => {
  const paramString = params.toString();
  return (paramString ? `?${paramString}` : undefined);
};

/**
 * React hook for storing and retrieving strings from URL search params.
 *
 * @param {string} key - **user facing** key for search param, typically snake_case
 * @param {string} [defaultValue] - optional default, used when key (or namespace) is not in searchParams on page load
 * @param {string} [namespace] - optional **user facing** "namespace" prepended to key -
 * when provided, having **any** search param within the namespace causes useSearchParam to ignore defaultValue
 * @param {boolean} enableParams=true - when false, this hook acts as useState, with no search param functionality -
 * used with featureFlags to allow separating release of code and deployment of features
 * *without violating the Rules of React Hooks*
 *
 * @returns {Array} useState-like array with additional reset callback - [value, setValue, reset]
 *
 * @example
 * const [lastName, setLastName, resetLastName] = useSearchParam('last_name', 'Doe');
 *
 * <Field label="Last Name" value={lastName} onChange={(val) => setLastName(val)} />
 */
export const useSearchParam = (key, defaultValue, namespace, enableParams = true) => {
  if (typeof key !== 'string' || key.length < 1) {
    throw new TypeError('key *must* be a non-empty string');
  }
  if (defaultValue !== undefined && typeof defaultValue !== 'string') {
    throw new TypeError('defaultValue must be a string, if defined');
  }
  if (namespace !== undefined && typeof namespace !== 'string') {
    throw new TypeError('namespace must be a string, if defined');
  }

  const namespacedKey = namespace ? `${namespace}.${key}` : key;

  const [valueInState, setValueInState] = useState(() => {
    if (!enableParams) return defaultValue;
    const params = getUrlSearchParams();
    const existingValue = getExistingParam(params, namespacedKey);
    let isParamInNamespace = typeof existingValue === 'string'; // empty string is both valid and falsy
    if (!isParamInNamespace && namespace) {
      for (const existingKey of params.keys()) {
        if (existingKey.startsWith(`${namespace}.`)) {
          isParamInNamespace = true;
          break;
        }
      }
    }
    return isParamInNamespace ? existingValue : defaultValue;
  });

  const setParam = (newValue) => {
    const params = getUrlSearchParams();
    const existingValue = getExistingParam(params, namespacedKey);

    if (newValue !== existingValue) {
      if (typeof newValue === 'string') {
        params.set(namespacedKey, newValue);
      } else if (newValue === null || newValue === undefined) {
        params.delete(namespacedKey);
      }
      params.sort();
      browserHistory.replace({
        pathname: getPathname(),
        search: getSearch(params),
      });
    }
  };

  const setValue = (newValue) => {
    if (typeof newValue === 'string' || newValue === undefined) {
      setValueInState(newValue);
      if (enableParams) setParam(newValue);
    } else {
      // eslint-disable-next-line no-console
      console.error(
        'useSearchParam can only be used with strings or undefined. Skipping setValue.',
      );
    }
  };

  const reset = () => {
    setValue(defaultValue);
  };

  useEffect(() => {
    if (enableParams && defaultValue !== undefined && valueInState === defaultValue) {
      setParam(defaultValue);
    }
  }, []);

  return [valueInState, setValue, reset];
};
