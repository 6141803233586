import React from 'react';
import PropTypes from 'prop-types';
import InvoiceWorkqueue from './InvoiceWorkqueue';
import { DISPUTED_STATUSES } from '../constants';
import { NoInvoicesFoundCard } from '../components';

const DisputedInvoices = ({ children }) => {
  const invoiceStatus = DISPUTED_STATUSES;

  const bulkActions = ['IconDownloadV2'];

  return (
    <InvoiceWorkqueue
      bulkActions={bulkActions}
      disputed
      invoiceStatus={invoiceStatus}
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <NoInvoicesFoundCard isFiltered={isFiltered} status="disputed" resetFilters={resetFilters} />
      )}
      showStatusFilter
      statusOptionsConstant="DISPUTED_STATUS_OPTIONS"
      path="disputed/"
    >
      {children}
    </InvoiceWorkqueue>
  );
};

DisputedInvoices.propTypes = {
  children: PropTypes.node,
};

DisputedInvoices.defaultProps = {
  children: undefined,
};
export default DisputedInvoices;
