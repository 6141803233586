import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { enums } from '@unite-us/client-utils';
import { isEmpty } from 'lodash';
import DetailStatusTextIcon from './DetailStatusTextIcon';

const UNKNOWN_DISPLAY_NAME = 'Unknown';

function getStatusText(display_name, isReferralForwarded, isClosedByCoordinationCenter) {
  if (isReferralForwarded) return 'Forwarded';
  if (isClosedByCoordinationCenter) return 'Reviewed';
  return (display_name || UNKNOWN_DISPLAY_NAME);
}

const DetailStatusText = ({
  detailObj,
  enumsKey,
  groupId,
  icon,
  iconTooltip,
  isCoordinationGroup,
  isReferralForwarded,
  session,
  isClosedByCoordinationCenter,
}) => {
  const {
    display_name,
  } = enums.generateEnumsObject(detailObj, enumsKey, session, { groupId, isCoordinationGroup });

  return (
    <h4 className={'detail-status-text uppercase text-text-blue'}>
      {
        !isEmpty(icon) ?
          <DetailStatusTextIcon icon={icon} iconTooltip={iconTooltip} /> :
          null
      }
      { getStatusText(display_name, isReferralForwarded, isClosedByCoordinationCenter) }
    </h4>
  );
};

DetailStatusText.propTypes = {
  groupId: PropTypes.string,
  detailObj: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  enumsKey: PropTypes.string.isRequired,
  isReferralForwarded: PropTypes.bool,
  session: PropTypes.shape({
    enums: PropTypes.object.isRequired,
  }).isRequired,
  icon: PropTypes.node,
  iconTooltip: PropTypes.node,
  isCoordinationGroup: PropTypes.bool,
  isClosedByCoordinationCenter: PropTypes.bool.isRequired,
};

DetailStatusText.defaultProps = {
  iconTooltip: '',
  isReferralForwarded: false,
};

function mapStateToProps(state, ownProps) {
  return {
    isCoordinationGroup: state.session.isCoordinationGroup,
    enumsKey: ownProps.enumsKey,
    session: state.session,
    detailObj: ownProps.detailObj,
  };
}

export default connect(mapStateToProps)(DetailStatusText);
