import React from 'react';
import PropTypes from 'prop-types';

export const NoNotes = ({ subjectType }) => {
  const styles = 'bg-dark-fill-blue border border-solid ' +
    'border-dark-border-blue color-grey text-center py-8 px-6 mb-6 rounded';

    return (
      <div className={styles}>
        No notes on this {subjectType}.
      </div>
    );
};

NoNotes.propTypes = {
  subjectType: PropTypes.string,
};

NoNotes.defaultProps = {
  subjectType: 'referral',
};

export default NoNotes;
