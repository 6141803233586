import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ColumnHeader,
  DataCell,
  DataRow,
  HeaderRow,
  Table,
  TableBody,
} from 'src/common/tables/V2';
import SearchBar from 'src/common/SearchBar/SearchBar';
import { Checkbox } from 'src/common/TailwindComponents';
import EmptyTable from 'src/common/tables/EmptyTable/EmptyTable';
import Pagination from 'src/common/Pagination/Pagination';
import ScrollToElement from 'src/common/utils/Form/ScrollToElement';
import { intersection } from 'lodash';
import './DynamicSearchTable.scss';

const DynamicSearchTable = ({
  searchResults,
  rowHeaders,
  selectedRowIds,
  setSelectedRowIds,
  setSearch,
  emptyTableMessage,
  searchBarPlaceHolder,
  searchTitle,
  tableTitle,
  dataTestId,
  isMultiSelect,
  pageSize,
  setPageSize,
  pageNumber,
  setPageNumber,
  paging,
  showSelectedAmount,
}) => {
  const [selectedRows, setSelectedRows] = useState(Array.isArray(selectedRowIds) ? selectedRowIds : [selectedRowIds]);
  const searchResultsIds = searchResults?.map((searchResult) => searchResult.id);

  useEffect(() => {
    setSelectedRows(Array.isArray(selectedRowIds) ? selectedRowIds : [selectedRowIds]);
  }, [selectedRowIds]);

  const searchProviderByName = (data) => {
    setSearch(data.search);
  };

  const selectRows = (...ids) => {
    const newSelected = isMultiSelect ? [...selectedRows, ...ids] : ids[0];
    setSelectedRowIds(newSelected);
  };

  const deselectRows = (...ids) => {
    const newSelected = selectedRows.filter((id) => !ids.includes(id));
    setSelectedRowIds(newSelected);
  };

  const onCheckColumn = () => {
    if (
      selectedRows.length !== 0 &&
      intersection(selectedRows, searchResultsIds).length ===
      searchResultsIds.length
    ) {
      deselectRows(...searchResultsIds);
    } else {
      selectRows(...searchResultsIds);
    }
  };

  const toggleRowSelected = (searchResultId) => {
    if (selectedRows.includes(searchResultId)) {
      deselectRows(searchResultId);
    } else {
      selectRows(searchResultId);
    }
  };

  const ScrollToElementEvent = () => {
    ScrollToElement(document.getElementById('TopOfForms'));
  };

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="flex flex-col flex-grow-0 relative">
        { searchTitle && (<h1 className="pt-1 pb-2 font-bold text-brand-blue">{searchTitle}</h1>) }
        <SearchBar
          className="flex-grow p-0"
          onSubmit={searchProviderByName}
          dataTestId={`${dataTestId}-search-bar`}
          inputClassName="w-full"
          placeholder={searchBarPlaceHolder}
          autoSubmit={false}
        />
      </div>
      { (tableTitle || showSelectedAmount) && (
        <div
          className="
            flex flex-row
            justify-between items-center
            rounded-t-lg border border-solid border-light-border-blue
            bg-white
            p-2"
        >
          <h1 className="pt-1 pb-2 font-bold text-brand-blue">{tableTitle}</h1>
          { showSelectedAmount && (<span>{selectedRowIds.length} selected</span>) }
        </div>
      )}
      {
        (searchResults?.length > 0) ? (
          <div
            data-testid={`${dataTestId}-container`}
            className={
              `h-full
              overflow-y-auto
              border  ${tableTitle || showSelectedAmount ? 'border-t-0' : 'rounded-t-lg'}
              border-solid border-light-border-blue
              mb-2`
            }
          >
            <Table id="searchTable" className="overflow-y-auto bg-white">
              <HeaderRow>
                {isMultiSelect && (
                <ColumnHeader className="w-6">
                  <Checkbox
                    ariaLabel="Select all rows on page"
                    checked={
                      intersection(selectedRows, searchResultsIds).length ===
                      searchResultsIds.length
                    }
                    id="dynamic-search-table-header-row-checkbox"
                    onChange={onCheckColumn}
                  />
                </ColumnHeader>
              )}
                {rowHeaders.map((header) => (
                  <ColumnHeader
                    className="w-6"
                    key={`search-table-header-${header}`}
                  >
                    {header}
                  </ColumnHeader>
                ))}
              </HeaderRow>
              <TableBody>
                {searchResults.map((searchResult, index) => (
                  <DataRow
                    className={
                    !isMultiSelect && selectedRows.includes(searchResult.id) ?
                      'selectedSearchTableRow' :
                      'searchTableRow'
                  }
                    dataTestId={`${dataTestId}-table-row-${index}`}
                    key={`search-organization-table-row-${searchResult.id}`}
                    onClick={() => toggleRowSelected(searchResult.id)}
                  >
                    {isMultiSelect && (
                    <DataCell className="w-6">
                      <Checkbox
                        ariaLabel={'Select row'}
                        checked={selectedRows.includes(searchResult.id)}
                        id={`dynamic-table-data-row-${searchResult.id}`}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => toggleRowSelected(searchResult.id)}
                      />
                    </DataCell>
                  )}
                    {searchResult.data.map((data, i) => (
                      <DataCell key={`search-table-data-cell-${searchResult.id}-${rowHeaders[i]}`}>
                        { data }
                      </DataCell>
                  ))}
                  </DataRow>
              ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <EmptyTable
            className={`
              border
              border-solid
              border-light-border-blue
              ${tableTitle || showSelectedAmount ? 'border-t-0' : 'rounded-t-lg'}
              mb-2
            `}
            message={emptyTableMessage}
          />
        )
      }
      <Pagination
        pageNumber={pageNumber}
        pageSize={pageSize}
        totalItemCount={paging ? paging.total_count : 0}
        totalPageCount={paging ? paging.total_pages : 0}
        setPageSize={setPageSize}
        setPageNumber={setPageNumber}
        scrollPaginationElement={ScrollToElementEvent}
      />
    </div>
  );
};

DynamicSearchTable.propTypes = {
  searchResults: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      data: PropTypes.array,
    }),
  ).isRequired,
  rowHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedRowIds: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  setSelectedRowIds: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  emptyTableMessage: PropTypes.string,
  searchBarPlaceHolder: PropTypes.string,
  searchTitle: PropTypes.string,
  tableTitle: PropTypes.string,
  dataTestId: PropTypes.string,
  isMultiSelect: PropTypes.bool,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  paging: PropTypes.shape({
    total_count: PropTypes.number,
    total_pages: PropTypes.number,
  }).isRequired,
  showSelectedAmount: PropTypes.bool,
};

DynamicSearchTable.defaultProps = {
  emptyTableMessage: 'No Results Found.',
  searchBarPlaceHolder: 'Search...',
  searchTitle: null,
  tableTitle: null,
  dataTestId: 'search-table',
  isMultiSelect: false,
  showSelectedAmount: false,
};

export default DynamicSearchTable;
