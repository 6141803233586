import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import BrowseMapButton from 'src/components/Browse/BrowseMapButton';
import { isDraft } from 'src/components/Referrals/ReferralStatus/utils/status';

class ReferralGroupHeader extends PureComponent {
  render() {
    const {
      browseLinkDisabled,
      referral,
      serviceTypeField,
      children,
      isProgramBasedSearch,
    } = this.props;

    if (_.isEmpty(serviceTypeField.value)) {
      return null;
    }

    const showBrowseLink = !isDraft(referral);
    const hintClass = 'referral-group-hint';

    return (
      <header className="referral-group-header">
        <label htmlFor="select-field-group-0" className="ui-form-field__label ui-form-field__label--required">
          {`Suggested In Network ${isProgramBasedSearch ? 'Programs' : 'Organizations'}`}
        </label>
        {children && children}
        <p className={hintClass}>
          {
            `We've compiled this list based on the service type you selected.
            Select one or more recipients for this
            referral${showBrowseLink ? ', ' : '.'}`
          }
          {
            showBrowseLink && (
              <span>
                or click
                <BrowseMapButton
                  disabled={browseLinkDisabled}
                  id="browse-map-button"
                  onClick={() => this.props.toggleBrowse('in-network')}
                />
                for more options.
              </span>
            )
          }
        </p>
      </header>
    );
  }
}

ReferralGroupHeader.propTypes = {
  browseLinkDisabled: PropTypes.bool,
  referral: PropTypes.object.isRequired,
  serviceTypeField: PropTypes.object.isRequired,
  toggleBrowse: PropTypes.func.isRequired,
  children: PropTypes.node,
  isProgramBasedSearch: PropTypes.bool,
};

ReferralGroupHeader.defaultProps = {
  browseLinkDisabled: false,
  children: null,
  isProgramBasedSearch: false,
};

export default ReferralGroupHeader;
