import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { coreApi } from 'src/api/config';
import { FileThumbnail } from '@unite-us/client-utils';
import Notifier from 'common/helpers/Notifier';
import { get, compact } from 'lodash';
import { PrimaryButton, SecondaryButton } from 'common/TailwindComponents';
import SurveyJSFormRenderer from '@unite-us/surveyjs/dist/components/Renderer/SurveyJSFormRenderer';
import { useCreateRecord, useUpdateRecord } from 'src/api/APIHooks';

export const EnrollmentReview = ({
  contactId,
  groupId,
  currentEmployee,
  form,
  formResponse,
  plan,
  attachedDocuments,
  stepBackward,
  stepForward,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const surveyRef = useRef();

  const metafields = plan?.metafields ?? [];
  const submissionJson = {
    responses: formResponse,
    form: form?.id,
    provider: groupId,
    contextType: 'person',
    context: contactId,
    submitter: currentEmployee,
  };

  const handleSetSurvey = (survey) => {
    surveyRef.current = survey;
  };

  const { createRecord: createFormSubmission } = useCreateRecord('form_submission', {
    api: 'coreApi',
  });
  const { createRecord: createEnrollmentRequest } = useCreateRecord('enrollment_request');
  const { updateRecord: updateEnrollmentRequest } = useUpdateRecord('enrollment_request');

  const addFileUploadsToEnrollmentRequest = async (enrollmentRequest, files) => {
    const metadata = files.map((file) => ({
      field: file.field,
      value: file.value.map((fileEntry) => fileEntry.id),
    }));
    await updateEnrollmentRequest(
      enrollmentRequest,
      { metadata },
      {
        mutationConfig: {
          onSuccess: () => {
            Notifier.dispatch('success', 'Enrollment Request Sent.');
          },
        },
      },
    );
  };

  const uploadFiles = async (enrollmentRequestId) => (
    Promise.all(attachedDocuments.map(async ({ field, value = [] }) => {
      const uploadFileResponses = value.map(async ({ file }) => {
        const response = await coreApi.uploadFile('enrollment_requests', enrollmentRequestId, file);
        return response?.data?.data;
      });
      return { field, value: compact(await Promise.all(uploadFileResponses)) };
    }))
  );

  const submit = async () => {
    const survey = surveyRef.current;
    if (survey.completeLastPage() && !survey.hasErrors()) {
      setSubmitting(true);
      await createEnrollmentRequest({
        person: contactId,
        plan: plan.id,
        request_type: 'enroll',
      }, {
        mutationConfig: {
          onError: (error) => {
            Notifier.handleErrors(error);
          },
        },
      }).then(async (response) => {
        const enrollmentRequest = get(response, 'data.data', {});
        if (enrollmentRequest.id) {
          const uploadFilesResponse = await uploadFiles(enrollmentRequest.id);
          await addFileUploadsToEnrollmentRequest(
            enrollmentRequest.id,
              uploadFilesResponse,
          );
        }
      });
      await createFormSubmission(submissionJson, {
        mutationConfig: {
          onError: (error) => {
            Notifier.handleErrors(error);
          },
        },
      });
      setSubmitting(false);
      stepForward();
    }
  };

  return (
    <div className="p-4 pt-0">
      <div className="p-4 pt-0">
        <p className="text-md mb-4">
          Review the information to make sure it is correct before requesting enrollment.
        </p>
        <div className="bg-white py-4 flex items-center justify-start space-x-6">
          <div>
            <SurveyJSFormRenderer
              formData={{ configuration: form.configuration }}
              formSubmission={submissionJson}
              editMode={false}
              handleSetSurveyModel={handleSetSurvey}
            />
            <div className="pt-2">
              <h1 className="pb-4">Supporting Documents</h1>
              {
              (attachedDocuments).map((documents) => (
                <div className="row" key={documents.field}>
                  <div className="col-sm-6 flex justify-start">
                    <h5 className="text-left">
                      {
                        metafields.map((meta) => (
                          Object.values(meta).includes(documents.field) && documents.value ? (meta.label) : null
                        ))
                      }
                    </h5>
                  </div>
                  {
                    documents.value ? documents.value.map((fileEntry, index) => (
                      <div className="col-sm-6 flex" key={fileEntry.tempId}>
                        <div className="preview-item flex" key={'filepreview-'.concat(index)}>
                          <FileThumbnail size={20} color="#4467AB" contentType={fileEntry.file.type} />
                          <div className="file-name pl-1 pb-4">
                            <a
                              href={`${fileEntry.preview}`} rel="noreferrer" target="_blank"
                              className="text-action-blue"
                            >
                              {fileEntry.file.name}
                            </a>
                          </div>
                        </div>
                      </div>
                    )) : null
                  }
                </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white px-6 py-4 flex items-center justify-end space-x-6">
        <SecondaryButton label="Previous" onClick={stepBackward} disabled={submitting} />
        <PrimaryButton
          id="submitButton"
          label="Submit enrollment request"
          disabled={submitting}
          onClick={submit}
        />
      </div>
    </div>
  );
};

EnrollmentReview.propTypes = {
  form: PropTypes.object.isRequired,
  formResponse: PropTypes.array.isRequired,
  contactId: PropTypes.string.isRequired,
  currentEmployee: PropTypes.string.isRequired,
  attachedDocuments: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  plan: PropTypes.object.isRequired,
  stepForward: PropTypes.func.isRequired,
  stepBackward: PropTypes.func.isRequired,
};

export default EnrollmentReview;
