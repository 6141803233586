import { USER_LOGOUT } from 'actions';
import { AUTH_URL } from 'src/config/env/env.config';
import { clearSession } from 'common/utils/utils';
import { remove as removeCookie, get as getCookie } from 'es-cookie';
import { COOKIE_SESSION_KEY } from 'src/common/constants';

export default function logoutUser() {
  const cookie = getCookie(COOKIE_SESSION_KEY);
  if (!cookie) {
    window.location = AUTH_URL;
    return false;
  }

  const { access_token } = JSON.parse(cookie);
  const encodedSession = window.btoa(JSON.stringify({ token: access_token }));

  return (dispatch) => {
    const p = dispatch({ type: USER_LOGOUT });
    Promise.resolve(p).then(() => {
      clearSession();
      removeCookie(COOKIE_SESSION_KEY);
      window.location = `${AUTH_URL}/logout?session=${encodedSession}`;
    });
  };
}
