import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { HoursOfOperationDayFields } from './components';

const HoursOfOperationFields = ({
  hideAddRemoveButtons,
  hideLabel,
  hoursFields = [],
  id,
  label,
}) => {
  const lineCount = hoursFields.length;

  const week = map(hoursFields, (day, i) => (
    <div key={`${id}-day-field-${i}`}>
      <HoursOfOperationDayFields
        fields={day}
        hideAddRemoveButtons={hideAddRemoveButtons}
        id={`${id}-day-field-${i}`}
        index={i}
        length={lineCount}
        onAdd={hoursFields.addField}
        onRemove={hoursFields.removeField}
      />
    </div>
  ));

  return (
    <div className="hours-of-operation-fields">
      {
        !hideLabel && (
          <div className="mb-one">
            <label
              className="hours-of-operation-fields__label"
              htmlFor="hours-of-operation"
            >
              {label}
            </label>
          </div>
        )
      }
      {week}
    </div>
  );
};

HoursOfOperationFields.propTypes = {
  hideAddRemoveButtons: PropTypes.bool,
  hideLabel: PropTypes.bool,
  hoursFields: PropTypes.arrayOf(PropTypes.shape({
    closes_at: PropTypes.object.isRequired,
    day_of_week: PropTypes.object.isRequired,
    is_closed: PropTypes.object.isRequired,
    opens_at: PropTypes.object.isRequired,
  }).isRequired),
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
};

HoursOfOperationFields.defaultProps = {
  hideAddRemoveButtons: false,
  hideLabel: false,
  hoursFields: [],
  label: 'Hours Of Operation',
};

HoursOfOperationFields.fields = [
  'hours[].closes_at',
  'hours[].day_of_week',
  'hours[].is_closed',
  'hours[].opens_at',
];

export default HoursOfOperationFields;
