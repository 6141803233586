import { compact } from 'lodash';
import {
  clientNameColumn,
  serviceTypeColumn,
  networkColumn,
} from '../baseColumns';
import {
  actionMenu,
  dateSent,
  lastUpdated,
  organization,
  recipient,
  status,
} from './columns';

export function acceptedClosedConfig(isSuperNetwork) {
  return compact([
    organization(),
    networkColumn(isSuperNetwork),
    clientNameColumn(),
    serviceTypeColumn(),
    lastUpdated(),
  ]);
}

export function acceptedOpenConfig(isSuperNetwork) {
  return compact([
    organization(),
    networkColumn(isSuperNetwork),
    clientNameColumn(),
    serviceTypeColumn(),
    lastUpdated(),
  ]);
}

export function allConfig(statuses = [], isSuperNetwork) {
  return compact([
    networkColumn(isSuperNetwork),
    recipient(),
    clientNameColumn(),
    serviceTypeColumn(),
    status(statuses),
    lastUpdated(),
  ]);
}

export function inReviewConfig(isSuperNetwork) {
  return compact([
    networkColumn(isSuperNetwork),
    recipient(),
    clientNameColumn(),
    serviceTypeColumn(),
    lastUpdated(),
  ]);
}

export function needsActionConfig(isSuperNetwork) {
  return compact([
    networkColumn(isSuperNetwork),
    recipient(),
    clientNameColumn(),
    serviceTypeColumn(),
    lastUpdated(),
  ]);
}

export function pendingConsentConfig(actions = [], isSuperNetwork) {
  return compact([
    networkColumn(isSuperNetwork),
    recipient(),
    clientNameColumn(),
    serviceTypeColumn(),
    dateSent(),
    actionMenu(actions),
  ]);
}
