import { get } from 'lodash';

function isReferredWithinNetwork(referral) {
  const referredByNetworkId = get(referral, 'referred_by_network.id');
  const referredToNetworkId = get(referral, 'referred_to_network.id');
  const recalledFromNetworkId = get(referral, 'recalled_from_network.id');

  return referredByNetworkId === (referredToNetworkId || recalledFromNetworkId);
}

export default isReferredWithinNetwork;
