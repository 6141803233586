import React from 'react';
import PropTypes from 'prop-types';

const LegendRowValue = ({ value, disabled, showValue }) => {
  if (!showValue) {
    return null;
  }
  return (
    <td className="legend-row-value">
      {!disabled && <strong>{value}</strong>}
    </td>
  );
};

LegendRowValue.propTypes = {
  disabled: PropTypes.bool.isRequired,
  showValue: PropTypes.bool.isRequired,
  value: PropTypes.number,
};

export default LegendRowValue;
