import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'lodash';
import ServiceReferralButton from './ServiceReferralButton';

const ServiceReferrals = ({ screening }) => {
  const serviceReferralButtons = get(screening, 'needs', [])
    .map((need, index) => (
      <ServiceReferralButton
        index={index}
        key={get(need, 'service_type.id')}
        need={need}
        screening={screening}
      />
    ));

  return (
    <div className="service-referrals">
      {serviceReferralButtons}
    </div>
  );
};

ServiceReferrals.propTypes = {
  screening: PropTypes.shape({
    needs: PropTypes.array,
  }),
};

ServiceReferrals.defaultProps = {
  screening: { needs: [] },
};

export default ServiceReferrals;
