import { compact, some } from 'lodash';

const RESOLVED_OPTION = {
  label: 'Resolved',
  value: 'resolved',
};

const NOT_RESOLVED_OPTION = {
  label: 'Unresolved',
  value: 'not_resolved',
};

function resolutionOptions(outcomes) {
  const hasOutcome = (resolved) => {
    resolved = resolved === 'resolved';
    return some(outcomes, { resolved })
  };

  return compact([
    hasOutcome('resolved') ? RESOLVED_OPTION : null,
    hasOutcome('not_resolved') ? NOT_RESOLVED_OPTION : null,
  ]);
}

export default resolutionOptions;
