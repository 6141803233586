// Library Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@unite-us/ui';

// Component Imports
import AcceptModalForm from './AcceptModalForm';

class AcceptModalDialog extends Component {
  constructor(props) {
    super(props);

    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  openDialog() {
    return this.acceptDialog.openDialog()
      .then(this.props.resetActionValue);
  }

  closeDialog() {
    this.acceptDialog.closeDialog();
  }

  render() {
    return (
      <div className="accept-modal-dialog">
        <Dialog
          id="accept-referral-modal"
          data-testid="accept-referral-modal"
          ref={(el) => { this.acceptDialog = el; }}
          title="Accept Referral"
          size="large"
          modal
        >
          <AcceptModalForm
            referral={this.props.referral}
            closeDialog={this.closeDialog}
          />
        </Dialog>
      </div>
    );
  }
}

AcceptModalDialog.propTypes = {
  referral: PropTypes.object.isRequired,
  resetActionValue: PropTypes.func.isRequired,
};

export default AcceptModalDialog;
