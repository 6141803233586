import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  RadioField,
  DurationField,
  SelectField,
} from '@unite-us/ui';
import formatOptions from 'common/utils/formatOptions';

export const MILITARY_FORM_NAME = 'contact_military';

export class MilitaryInformation extends React.Component {
  static onAffiliationChange() {
    setTimeout(() => window.dispatchEvent(new Event('resize')), 10);
  }

  render() {
    const {
      fields,
      enums,
    } = this.props;

    const militaryEnums = _.wget(enums, 'military', {});

    return (
      <div className="contact-military-information">
        <h3 className="contact-military-information__header mb-3">Military Information</h3>
        <div className="contact-military-information__content">
          <div className="military-information">
            <SelectField
              id="affiliation"
              label="Affiliation"
              field={fields.military.affiliation}
              options={formatOptions(militaryEnums.affiliation)}
              inline={false}
              onChange={MilitaryInformation.onAffiliationChange}
            />
            <div className={(fields.military.affiliation.value === 'military_member_or_veteran') ? '' : 'hidden'}>
              <SelectField
                id="current_status"
                label="Current Status"
                field={fields.military.current_status}
                options={formatOptions(militaryEnums.current_status)}
                inline={false}
              />
              <div className="form-group">
                <div className="control-label">Currently Transitioning?</div>
                <RadioField
                  id="transitioning"
                  field={fields.military.currently_transitioning}
                  options={formatOptions(militaryEnums.transitioning)}
                />
              </div>
              <div className="form-group">
                <div className="control-label">Has at least one day of active duty outside of basic training?</div>
                <RadioField
                  id="active-duty"
                  field={fields.military.at_least_one_day_active_duty}
                  options={formatOptions(militaryEnums.active_duty)}
                />
              </div>
              <SelectField
                id="branch"
                label="Branch Of Service"
                field={fields.military.branch}
                options={formatOptions(militaryEnums.branch)}
                inline={false}
              />
              <SelectField
                id="era"
                label="Service Era"
                field={fields.military.service_era}
                options={formatOptions(militaryEnums.service_era)}
                inline={false}
                shouldSort={false}
              />
              <DurationField
                id="service-dates"
                label="Service Dates"
                startField={fields.military.entry_date}
                endField={fields.military.exit_date}
                inline={false}
              />
              <div className="form-group">
                <div className="control-label">Has client ever been deployed?</div>
                <RadioField
                  id="deployed"
                  field={fields.military.deployed}
                  options={formatOptions(militaryEnums.deployed)}
                />
              </div>
              <div className={(fields.military.deployed.value === 'yes') ? '' : 'hidden'}>
                <DurationField
                  id="deployment-dates"
                  label="Deployment Dates"
                  startField={fields.military.deployment_starts_at}
                  endField={fields.military.deployment_ends_at}
                  inline={false}
                />
              </div>
              <SelectField
                id="discharge"
                label="Military Discharge"
                field={fields.military.discharge_type}
                options={formatOptions(militaryEnums.discharge_type)}
                inline={false}
              />
              <div className="form-group">
                <div className="control-label">
                  Discharged or retired from military service for a disability incurred in the line of duty?
                </div>
                <RadioField
                  id="discharge-disability"
                  field={fields.military.discharged_due_to_disability}
                  options={formatOptions(militaryEnums.discharge_disability)}
                />
              </div>
              <div className="form-group">
                <div className="control-label">Has VA-rated Service-Connected Disability?</div>
                <RadioField
                  id="connected-disability"
                  field={fields.military.service_connected_disability}
                  options={formatOptions(militaryEnums.connected_disability)}
                />
              </div>
              <div className={(fields.military.service_connected_disability.value === 'yes') ? '' : 'hidden'}>
                <SelectField
                  id="disablility-rating"
                  label="Service-Connected Disability Rating"
                  field={fields.military.service_connected_disability_rating}
                  options={formatOptions(militaryEnums.disability_rating)}
                  inline={false}
                  shouldSort={false}
                />
              </div>
              <div className="form-group">
                <div className="control-label">Proof of veteran status?</div>
                <RadioField
                  id="proof"
                  field={fields.military.proof_of_veteran_status}
                  options={formatOptions(militaryEnums.proof_of_veteran_status)}
                />
              </div>
              <div className={(fields.military.proof_of_veteran_status.value === 'yes') ? '' : 'hidden'}>
                <SelectField
                  id="proof-type"
                  label="Proof Type"
                  field={fields.military.proof_type}
                  options={formatOptions(militaryEnums.proof_type)}
                  inline={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MilitaryInformation.propTypes = {
  fields: PropTypes.object.isRequired,
  enums: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    enums: _.wget(state, 'session.enums.contact_data', {}),
  };
}

MilitaryInformation.fields = [
  'military.affiliation',
  'military.current_status',
  'military.currently_transitioning',
  'military.at_least_one_day_active_duty',
  'military.branch',
  'military.service_era',
  'military.entry_date',
  'military.exit_date',
  'military.deployed',
  'military.deployment_starts_at',
  'military.deployment_ends_at',
  'military.discharge_type',
  'military.discharged_due_to_disability',
  'military.service_connected_disability',
  'military.service_connected_disability_rating',
  'military.proof_of_veteran_status',
  'military.proof_type',
];

export default connect(mapStateToProps)(MilitaryInformation);
