import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getParentServiceType } from 'common/utils/ServiceTypes';
import { Icon } from '@unite-us/ui';

function getIcon(serviceType, serviceTypes) {
  let firstLevelServiceType = _.find(serviceTypes, { id: serviceType.id });

  if (!firstLevelServiceType) {
    firstLevelServiceType = getParentServiceType(serviceType.id, serviceTypes);
  }
  if (!firstLevelServiceType) {
    return null;
  }

  switch (firstLevelServiceType.code) {
    case 'UU-BENEFITS':
      return 'Benefits';
    case 'UU-CLOTHING':
      return 'ClothingHouseholdGoods';
    case 'UU-EDUCATION':
      return 'Education';
    case 'UU-EMPLOYMENT':
      return 'Employment';
    case 'UU-ENTREPRENEURSHIP':
      return 'Entrepreneurship';
    case 'UU-FOOD':
      return 'Food';
    case 'UU-HEALTH':
      return 'MedicalHealth';
    case 'UU-INCOME-SUPPORT':
      return 'IncomeSupport';
    case 'UU-HOUSING':
      return 'HousingShelter';
    case 'UU-SUPPORT-SERVICES':
      return 'IndvFamSupport';
    case 'UU-LEGAL':
      return 'Legal';
    case 'UU-MEDICAL-HEALTH':
      return 'MedicalHealth';
    case 'UU-MENTAL-BEHAVIORAL-HEALTH':
      return 'MentalHealth';
    case 'UU-MONEY-MANAGEMENT':
      return 'MoneyManagement';
    case 'UU-SOCIAL-ENRICHMENT':
      return 'PersonalEnrichment';
    case 'UU-SPIRITUAL':
      return 'SpiritualEnrichment';
    case 'UU-SPORTS':
      return 'SportsRecreation';
    case 'UU-SUBSTANCE-USE':
      return 'Substance';
    case 'UU-TRANSPORTATION':
      return 'Transportation';
    case 'UU-UTILITIES':
      return 'Utilities';
    case 'UU-WELLNESS':
      return 'Wellness';
    default:
      return null;
  }
}

const serviceTypeText = (iconOnly, service) => (
  iconOnly ? null : (
    <div className="service-type__text">
      <span>
        {service.name}
      </span>
    </div>
  ));

function ServiceType(props) {
  const {
    service,
    iconOnly,
    iconHeight,
    iconWidth,
    size,
    svgStyle,
    serviceTypes,
  } = props;

  const ServiceIcon = getIcon(service, serviceTypes);

  return (
    <div className="service-type">
      <div className="service-type__item">
        <div className="service-type__icon">
          {
            ServiceIcon ? (
              <Icon
                style={svgStyle || { height: iconHeight, width: iconWidth }}
                color="67859E"
                icon={ServiceIcon}
                size={size}
              />
            ) : null
          }
        </div>

        {serviceTypeText(iconOnly, service)}
      </div>
    </div>
  );
}

ServiceType.propTypes = {
  service: PropTypes.object,
  iconOnly: PropTypes.bool.isRequired,
  iconHeight: PropTypes.string.isRequired,
  iconWidth: PropTypes.string.isRequired,
  svgStyle: PropTypes.object,
  serviceTypes: PropTypes.array.isRequired,
  size: PropTypes.number,
};

ServiceType.defaultProps = {
  iconOnly: false,
  iconHeight: '1em',
  iconWidth: '1em',
  size: 26,
};

function mapStateToProps(state) {
  return {
    serviceTypes: _.get(state, 'session.globals.service_types', []),
  };
}

export default connect(
  mapStateToProps,
)(ServiceType);
