import { apiDefault } from 'src/api/config';

export default function fetchCoreProvider(groupId) {
  const request = apiDefault.get(`/providers/${groupId}`);

  function onSuccess(payload) {
    return payload.data.data;
  }

  function onError(error) {
    return error;
  }
  return request.then(onSuccess, onError);
}
