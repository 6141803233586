import { get } from 'lodash';

export const MILITARY = 'military_focus';
export const INSURANCE = 'health_insurance_focus';

export function isFocused(currentGroup = {}, focus = '') {
  return (
    get(currentGroup, `group.focus.${focus}`, false) ||
    get(currentGroup, `group.focus_configuration.${focus}`, false) // legacy/current_user compatibility
  );
}
