import React from 'react';
import PropTypes from 'prop-types';
import RelationshipsListEmpty from './RelationshipsListEmpty';
import './RelationshipList.scss';

const DISPLAY_NAME = 'relationships-member-list';

const RelationshipsList = ({
  children,
  name,
}) => {
  if (!children.length) {
    return <RelationshipsListEmpty text={`No ${name} members.`} />;
  }
  return (
    <div className={DISPLAY_NAME}>
      {children}
    </div>
  );
};

RelationshipsList.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
};

RelationshipsList.defaultProps = {
  name: '',
};

export default RelationshipsList;
