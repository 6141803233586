export const CONTACT_CASE_FILE_UPLOAD_KEY = 'contact-case-documents';
export const CASE_DETAILS_FORM = 'createCaseDetails';
export const CLOSE_CASE_FORM = 'CLOSE_CASE_FORM';

export const CASE_STATUS_OPEN = 'open';
export const CASE_STATUS_CLOSED = 'closed';

export const NETWORK_CASE = 'network_case';
export const ORG_CASE = 'org_case';

// Fee Schedule Provided Services form
export const CREATE_FEE_SCHEDULE_PROVIDED_SERVICE = 'create-fee-schedule-provided-service';
export const UPDATE_FEE_SCHEDULE_PROVIDED_SERVICE = 'update-fee-schedule-provided-service';
export const INTERNAL_CASES_STATUSES = ['managed', 'pending_authorization'];
