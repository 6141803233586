import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { dates } from '@unite-us/app-components';

function DateOfBirth(props) {
  const { oneline, date } = props;

  if (_.isNull(date)) {
    return null;
  }

  const dob = moment.unix(date).utc();
  const years = moment().diff(dob, 'years');

  if (oneline) {
    return (
      <div>
        <p>{dates.formatDate(date)} <em> (Age {years})</em></p>
      </div>
    );
  }
  return (
    <div>
      <p>
        {dates.formatDate(date)} <br />
        <em>Age: {years}</em>
      </p>
    </div>
  );
}

DateOfBirth.propTypes = {
  date: PropTypes.number.isRequired,
  oneline: PropTypes.bool,
};

DateOfBirth.defaultProps = {
  oneline: false,
};

export default DateOfBirth;
