export const updateFormNeedConfigurations = async (updatedFormNeedConfigurations, updateConfig, deleteConfig) => {
  const promises = [];
  updatedFormNeedConfigurations.forEach((updatedFormNeedConfig) => {
    if (updatedFormNeedConfig.input_option_scores.length > 0) {
      promises.push(updateConfig(updatedFormNeedConfig.id, updatedFormNeedConfig));
    } else {
      promises.push(deleteConfig(updatedFormNeedConfig.id, {}));
    }
  });

  const results = await Promise.allSettled(promises);
  return results;
};
