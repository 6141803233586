import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { debounce, isEmpty } from 'lodash';
import {
  SelectField,
  BaseCard,
  BaseCardHeader,
  BaseCardBody,
  CheckBoxField,
  Expandable,
  InputField,
} from '@unite-us/ui';
import { DraftEditorField, ageRangeConverters } from '@unite-us/client-utils';
import { Field, useForm, useField } from 'react-final-form';
import DEFAULT_ENUMS from 'src/reducers/constants/defaultEnums';
import DivReset from 'src/components/Organization/components/DivLineHeightReset';
import { useFindProgramRequirementOptions } from 'src/components/Organization/api/hooks/v1';
import {
  retrieveTypeAheadCities,
  retrieveTypeAheadCounties,
  retrieveTypeAheadPostalCodes,
} from 'common/utils/Geographic/geographicalsTypeAhead';
import addLabelsToEnums from 'common/utils/addLabelsToEnums';
import './EligibilityFormV2.scss';

const {
 upperAgeLimit, validateMaxRange, validateMinRange, agePreview,
} = ageRangeConverters;

const statesEnums = DEFAULT_ENUMS.geography.us_states;
const stateOptions = addLabelsToEnums(statesEnums);

const populationEnums = DEFAULT_ENUMS.groups.programs.population;
const populationOptions = addLabelsToEnums(populationEnums);
const prd_only_populations = [
  {
    display_name: 'Blind or low vision',
    value: 'blind_or_low_vision',
    label: 'Blind or low vision',
  },
  {
    display_name: 'Deaf or hard of hearing',
    value: 'deaf_or_hard_of_hearing',
    label: 'Deaf or hard of hearing',
  },
  {
    display_name: 'People with learning disabilities ',
    value: 'people_with_learning_disabilities',
    label: 'People with learning disabilities ',
  },
  {
    display_name: 'People with chronic health conditions',
    value: 'people_with_chronic_conditions',
    label: 'People with chronic health conditions',
  },
];

const ageUnitEnums = DEFAULT_ENUMS.groups.programs.eligibility.age_units;
const ageUnitOptions = addLabelsToEnums(ageUnitEnums);

const closedResidencyHeaderText = (
  <>
    <h1>Residency Requirements</h1>
    <p>States, ZIP codes, counties, cities</p>
  </>
);

const openDemographicHeaderText = <h1>Demographic Requirements</h1>;
const closedDemographicHeaderText = (
  <>
    <h1>Demographic Requirements</h1>
    <p>Ages, genders, populations</p>
  </>
);

let countyOpts = [];
let cityOpts = [];
let postalCodeOpts = [];
let residencyExpandableOpen = false;
let demographicExpandableOpen = false;

const EligibilityFormV2Deprecated = ({
  values: {
    eligibility_text,
    age_req_min,
    age_req_min_units,
    age_req_max,
    age_req_max_units,
    state_reqs,
    city_reqs,
    city_requirements,
    county_reqs,
    county_requirements,
    postal_code_reqs,
    postal_code_requirements,
  },
  orgAdminDeactivatedPrograms,
  orgAdminEnhancements,
}) => {
  if (!countyOpts?.length) countyOpts = county_requirements ? [...county_requirements] : [];
  if (!cityOpts?.length) cityOpts = city_requirements ? [...city_requirements] : [];
  if (!postalCodeOpts?.length) {
    postalCodeOpts = postal_code_requirements ? [...postal_code_requirements] : postal_code_requirements;
  }

  const openResidencyHeaderText = (
    <>
      <h1>Residency Requirements</h1>
      <p>
        {orgAdminDeactivatedPrograms ?
          'This program requires proof of residency. Select where the client must ' +
            "live using the most specific drop-down menus below. For example, there's " +
            'no need to select both a city and the entire state.' :
          'This program requires proof of residency'}
      </p>
    </>
  );

  const { data: requirementOptions } = useFindProgramRequirementOptions();
  const genderOptions = requirementOptions
    ?.filter((opt) => opt.category === 'gender')
    .map((opt) => ({
      value: opt.id,
      display_name: opt.display_name,
      label: opt.display_name,
    }));

  const [residencyHeaderText, setResidencyHeaderText] = useState(closedResidencyHeaderText);
  const [demographicHeaderText, setDemographicHeaderText] = useState(closedDemographicHeaderText);

  // eslint-disable-next-line no-shadow, max-len
  const ageRangeMinValidation = (value, { age_req_min_units, age_req_max, age_req_max_units }) => validateMinRange(value, age_req_min_units, age_req_max, age_req_max_units);

  // eslint-disable-next-line no-shadow, max-len
  const ageRangeMaxValidation = (value, { age_req_min, age_req_min_units, age_req_max_units }) => validateMaxRange(age_req_min, age_req_min_units, value, age_req_max_units);

  const hasFeeScheduleProgram = !isEmpty(useField('fee_schedule_program').input.value);
  const form = useForm();
  const getCities = async (value, callback) => {
    if (value.length > 2) {
      cityOpts = cityOpts?.filter((opts) => city_reqs?.includes(opts.value));
      cityOpts = await retrieveTypeAheadCities(value, cityOpts);
      cityOpts = [...new Map(cityOpts.map((opts) => [opts.value, opts])).values()];
    }

    return callback({ options: cityOpts });
  };

  const getPostalCodes = async (value, callback) => {
    if (value.length > 2) {
      postalCodeOpts = postalCodeOpts?.filter((opts) => postal_code_reqs?.includes(opts.value));
      postalCodeOpts = await retrieveTypeAheadPostalCodes(value, postalCodeOpts);
      postalCodeOpts = [...new Map(postalCodeOpts.map((opts) => [opts.value, opts])).values()];
    }

    return callback({ options: postalCodeOpts });
  };

  const getCounties = async (value, callback) => {
    if (value.length > 2) {
      countyOpts = countyOpts?.filter((opts) => county_reqs?.includes(opts.value));
      countyOpts = await retrieveTypeAheadCounties(value, countyOpts);
      countyOpts = [...new Map(countyOpts.map((opts) => [opts.value, opts])).values()];
    }

    return callback({ options: countyOpts });
  };

  const eligibilityEnabled = !form.getFieldState('no_eligibility_requirements')?.value;

  const updateResidencyHeader = () => {
    residencyExpandableOpen = !residencyExpandableOpen;
    setResidencyHeaderText(residencyExpandableOpen ? openResidencyHeaderText : closedResidencyHeaderText);
  };

  const updateDemographicHeader = () => {
    demographicExpandableOpen = !demographicExpandableOpen;
    setDemographicHeaderText(demographicExpandableOpen ? openDemographicHeaderText : closedDemographicHeaderText);
  };

  return (
    <BaseCard className="mt-6">
      <BaseCardHeader
        title={(
          <>
            Eligibility<span className="text-red pl-2">*</span>
            <div className="font-proxima-nova text-sm my-1.5 text-dark-grey">
              {orgAdminDeactivatedPrograms ?
                'Enter requirements below or select No Client Eligibility Requirements.' :
                "Enter requirements below or check 'No Client Eligibility Requirements.'"}
            </div>
          </>
        )}
        className={!eligibilityEnabled ? 'no-borders' : ''}
      >
        <Field data-test-element="eligibility-no-requirements" name="no_eligibility_requirements" type="checkbox">
          {(params) => (
            <CheckBoxField
              id="toggle-eligibility"
              className="flex"
              label="No Client Eligibility Requirements"
              {...params}
            />
          )}
        </Field>
      </BaseCardHeader>
      {eligibilityEnabled && (
        <BaseCardBody className="px-8 py-10">
          <span>
            <Expandable
              header={residencyHeaderText}
              onExpandChange={updateResidencyHeader}
              headerTag="h1"
              expanded={residencyExpandableOpen}
            >
              <div>
                <div className="preview-label">PREVIEW</div>
                {!state_reqs?.length && !city_reqs?.length && !county_reqs?.length && !postal_code_reqs?.length && (
                  <div className="flex align-center">
                    <span className="my-2">Selected requirements will appear here</span>
                  </div>
                )}
                <div className="pb-2">
                  {state_reqs?.length > 0 && (
                    <div className="flex">
                      <span className="region-preview-label">States:</span>
                      <span>{state_reqs.join(', ')}</span>
                    </div>
                  )}
                  {city_reqs?.length > 0 && (
                    <div className="flex">
                      <span className="region-preview-label">Cities:</span>
                      <span>
                        {cityOpts
                          .filter((opt) => city_reqs.includes(opt.value))
                          .map((opt) => opt.display_name)
                          .join(', ')}
                      </span>
                    </div>
                  )}
                  {county_reqs?.length > 0 && (
                    <div className="flex">
                      <span className="region-preview-label">Counties:</span>
                      <span>
                        {countyOpts
                          .filter((opt) => county_reqs.includes(opt.value))
                          .map((opt) => opt.display_name)
                          .join(', ')}
                      </span>
                    </div>
                  )}
                  {postal_code_reqs?.length > 0 && (
                    <div className="flex">
                      <span className="region-preview-label">Postal Codes:</span>
                      <span>
                        {postalCodeOpts
                          .filter((opt) => postal_code_reqs.includes(opt.value))
                          .map((opt) => opt.display_name)
                          .join(', ')}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <DivReset className="my-2">
                <Field name="state_reqs">
                  {(params) => (
                    <SelectField
                      dataTestElement="resident_states_dropdown"
                      label={
                        orgAdminDeactivatedPrograms ?
                          'Client must live anywhere in these states:' :
                          'Client must live in the following states:'
                      }
                      placeholder="Select states..."
                      id="state_reqs"
                      multiple
                      options={stateOptions}
                      valueKey="value"
                      {...params}
                    />
                  )}
                </Field>
              </DivReset>
              <DivReset className="my-2">
                <Field name="postal_code_reqs">
                  {(params) => (
                    <SelectField
                      dataTestElement="resident_postal_code_dropdown"
                      label={
                        orgAdminDeactivatedPrograms ?
                          'Client must live anywhere in these ZIP codes:' :
                          'Client must live in the following ZIP codes:'
                      }
                      placeholder="Type to search for ZIP codes..."
                      id="postal_code_reqs"
                      testid="postal_code_reqs"
                      multiple
                      loadingText="Searching...."
                      loadOnMount
                      loadOptions={debounce(getPostalCodes, 400)}
                      valueKey="value"
                      {...params}
                    />
                  )}
                </Field>
              </DivReset>
              <DivReset className="my-2">
                <Field name="county_reqs">
                  {(params) => (
                    <SelectField
                      dataTestElement="resident_counties_dropdown"
                      label={
                        orgAdminDeactivatedPrograms ?
                          'Client must live anywhere in these counties:' :
                          'Client must live in the following counties:'
                      }
                      placeholder="Type to search for counties..."
                      id="county_reqs"
                      multiple
                      loadingText="Searching...."
                      loadOnMount
                      loadOptions={debounce(getCounties, 400)}
                      valueKey="value"
                      labelKey="display_name"
                      {...params}
                    />
                  )}
                </Field>
              </DivReset>
              <DivReset className="mt-2">
                <Field name="city_reqs">
                  {(params) => (
                    <SelectField
                      dataTestElement="resident_cities_dropdown"
                      label={
                        orgAdminDeactivatedPrograms ?
                          'Client must live anywhere in these cities:' :
                          'Client must live in the following cities:'
                      }
                      placeholder="Type to search for cities..."
                      id="city_reqs"
                      multiple
                      loadingText="Searching...."
                      loadOnMount
                      loadOptions={debounce(getCities, 400)}
                      valueKey="value"
                      labelKey="display_name"
                      {...params}
                    />
                  )}
                </Field>
              </DivReset>
            </Expandable>
            <Expandable
              header={demographicHeaderText}
              onExpandChange={updateDemographicHeader}
              headerTag="h1"
              expanded={demographicExpandableOpen}
            >
              <label htmlFor="age" className="ui-form-field__label">
                Client&apos;s age must be:
              </label>
              <div className="flex">
                <div className="flex ml-2 gap-2 age-range-box">
                  <Field name="age_req_min" validate={ageRangeMinValidation}>
                    {(params) => (
                      <InputField
                        autoComplete="on"
                        label="Min"
                        id="min-age"
                        type="number"
                        min={0}
                        max={upperAgeLimit(age_req_min_units)}
                        step="1"
                        {...params}
                      />
                    )}
                  </Field>
                  <Field name="age_req_min_units">
                    {(params) => (
                      <SelectField
                        dataTestElement="age_req_min_units_dropdown"
                        label="Unit"
                        id="age_req_min_units"
                        options={ageUnitOptions}
                        required={!!age_req_min}
                        {...params}
                      />
                    )}
                  </Field>
                </div>
                <div className="flex ml-2 gap-2 age-range-box">
                  <Field name="age_req_max" validate={ageRangeMaxValidation}>
                    {(params) => (
                      <InputField
                        autoComplete="on"
                        label="Max"
                        id="max-age"
                        type="number"
                        min={0}
                        max={upperAgeLimit(age_req_max_units)}
                        step="1"
                        {...params}
                      />
                    )}
                  </Field>
                  <Field name="age_req_max_units">
                    {(params) => (
                      <SelectField
                        dataTestElement="age_req_max_units_dropdown"
                        label="Unit"
                        id="age_req_max_units"
                        options={ageUnitOptions}
                        required={!!age_req_max}
                        {...params}
                      />
                    )}
                  </Field>
                </div>

                <div className="ml-4">
                  <div className="preview-label">PREVIEW</div>
                  <div className="flex align-center">
                    <span className="my-2">
                      {agePreview(age_req_min, age_req_min_units, age_req_max, age_req_max_units)}
                    </span>
                  </div>
                </div>
              </div>

              <DivReset className="my-2">
                <Field name="genders">
                  {(params) => (
                    <SelectField
                      dataTestElement="gender_dropdown"
                      label="Client's gender must be:"
                      placeholder="Select genders..."
                      id="genders"
                      multiple
                      options={genderOptions}
                      {...params}
                    />
                  )}
                </Field>
              </DivReset>

              <DivReset className="my-2">
                <Field name="population_restrictions">
                  {(params) => (
                    <SelectField
                      dataTestElement="restricted_populations_dropdown"
                      label="Client must be part of this population:"
                      multiple
                      placeholder="Select populations..."
                      id="population_restrictions"
                      options={
                        orgAdminEnhancements ?
                          populationOptions :
                          populationOptions.filter(
                              (prd) => !prd_only_populations.find((rmPrd) => rmPrd.display_name === prd.display_name),
                            )
                      }
                      {...params}
                      {...params}
                    />
                  )}
                </Field>
              </DivReset>
            </Expandable>
            <Expandable
              header={(
                <>
                  <h1>Other Requirements</h1>
                  <p>Any other requirements</p>
                </>
              )}
              headerTag="h1"
              initiallyCollapsed
            >
              <DivReset className="font-regular-font">
                <div className="font-heavy-font uppercase text-xs title">Client Eligibility Requirements</div>
                <p className="my-2">
                  Please describe any of the following criteria clients must meet to be eligible for this program:
                </p>
                <div className="flex mb-4">
                  <div className="w-4/12">
                    <p className="underline mb-2">General Requirements</p>
                    <ul data-test-element="general-requirements" className="list-disc list-inside pl-2">
                      <li>Income</li>
                      <li>Citizenship/Immigration Status</li>
                      <li>Employment</li>
                      <li>Disability</li>
                      <li>Eligible Household Members</li>
                      <li>Required Documentation</li>
                      <li>Additional Eligibility Requirements</li>
                    </ul>
                  </div>
                  <div className="w-4/12">
                    <p className="underline mb-2">Veteran-Specific Requirements</p>
                    <ul data-test-element="veteran-specific-requirements" className="list-disc list-inside pl-2 mb-4">
                      <li>Eligible Service Statuses</li>
                      <li>Eligibile Service Eras</li>
                      <li>Eligible Discharge Statuses</li>
                      <li>Deployment Required?</li>
                      <li>Minimum Length of Duty</li>
                    </ul>
                    <p>
                      <a
                        href="https://support.uniteus.io/en/articles/2812578-add-and-update-your-organization-s-programs"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Learn more about eligibility requirements.
                      </a>
                    </p>
                  </div>
                </div>
              </DivReset>
              <DivReset className="pt-2">
                <Field data-test-element="eligibility-text" name="eligibility_text">
                  {(params) => (
                    <DraftEditorField
                      dataTestId="eligibility-description"
                      hideLabel
                      id="eligibility-description"
                      label="eligibility description"
                      readOnly={hasFeeScheduleProgram}
                      required
                      value={eligibility_text}
                      {...params}
                    />
                  )}
                </Field>
              </DivReset>
            </Expandable>
          </span>
        </BaseCardBody>
      )}
    </BaseCard>
  );
};

EligibilityFormV2Deprecated.propTypes = {
  form: PropTypes.shape({ change: PropTypes.func.isRequired }).isRequired,
  values: PropTypes.shape({
    states: PropTypes.arrayOf(PropTypes.string),
    eligibility_text: PropTypes.string,
    age_req_min: PropTypes.string,
    age_req_min_units: PropTypes.string,
    age_req_max: PropTypes.string,
    age_req_max_units: PropTypes.string,
    state_reqs: PropTypes.array,
    city_reqs: PropTypes.array,
    city_requirements: PropTypes.array,
    county_reqs: PropTypes.array,
    county_requirements: PropTypes.array,
    postal_code_reqs: PropTypes.array,
    postal_code_requirements: PropTypes.array,
  }),
  orgAdminDeactivatedPrograms: PropTypes.bool,
  orgAdminEnhancements: PropTypes.bool,
};

EligibilityFormV2Deprecated.defaultProps = {
  values: {
    city_requirements: [],
    county_requirements: [],
    postal_code_requirements: [],
  },
  orgAdminDeactivatedPrograms: false,
  orgAdminEnhancements: false,
};

export default EligibilityFormV2Deprecated;
