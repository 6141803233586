import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OrganizationHeader from 'src/components/Organization/components/OrganizationHeader';
import { ORG_TABS } from 'src/components/Organization/constants';
import FormHeader from 'src/components/Organization/components/FormHeader';
import { browserHistory } from 'common/utils/browserHistory';
import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import {
  useCreateEmployee,
  useCreateUser,
} from 'src/components/Organization/api/hooks/v1';
import { Spinner } from 'src/common/spinners';
import useTrackPageView from 'src/components/Organization/utils/useTrackPageView';
import { ORG_SETTINGS } from 'src/common/utils/EventTracker/utils/eventConstants';
import { getEmployeePayload } from 'src/components/Organization/utils/getEmployeePayload';
import FormContainer from 'src/components/Organization/components/FormContainer';
import useTrackEvent from 'src/components/Organization/utils/useTrackEvent';
import hasBackOfficeAccess from 'src/common/utils/BackOffice/backOffice';
import UserForm from '../userForm';
import UserExistsCheck from '../userExistsCheck';

const UserNew = ({
 currentProviderId,
 currentProviderName,
 adminUserId,
 hasBackOffice,
 location,
}) => {
  useTrackPageView(ORG_SETTINGS.newUserViewed);
  const trackEventData = useTrackEvent();

  const [initialValues, setInitialValues] = useState({
    user_status: 'inactive',
  });
  const { isLoading: createEmployeeLoading, createRecord: createEmployee } =
    useCreateEmployee();
  const { isLoading: createUserLoading, createRecord: createUser } =
    useCreateUser();
  const [checkedForExistingUser, setCheckedForExistingUser] = useState(false);

  const onCheckComplete = (result) => {
    setInitialValues({ ...initialValues, ...result });
    setCheckedForExistingUser(true);
  };

  const onSubmit = async (values) => {
    // see if user exists
    let userId;
    let createUserResponse;
    let createEmployeeResponse;
    if (values.userId) {
      userId = values.userId;
    } else {
      // create user
      createUserResponse = await createUser({
        email: values.email,
        active: values.user_status.id === 'active',
      });
      userId = createUserResponse.data.data.id;
    }
    // create employee
   if (userId) {
    createEmployeeResponse = await createEmployee(
    getEmployeePayload(currentProviderId, userId, values),
    );
    }
    if (userId && isHttpSuccess(createEmployeeResponse.status)) {
    if (!hasBackOffice) {
    trackEventData(
        ORG_SETTINGS.newUserCreated,
        {
           admin_user_id: adminUserId,
           created_user_id: userId,
           orgName: currentProviderName,
           created_employee_id: createEmployeeResponse?.data?.data?.id,
        },
        );
        }
         Notifier.dispatch(
        createEmployeeResponse.status,
        'User successfully created.',
      );
    }
    browserHistory.push('/organization/settings/users');
  };

  const displayUserForm = () => {
    if (checkedForExistingUser) {
      return <UserForm initialValues={initialValues} onSubmit={onSubmit} />;
    }
      return <UserExistsCheck onCheckComplete={onCheckComplete} currentProviderId={currentProviderId} />;
  };

      return (
        <>
          <OrganizationHeader currentTab={ORG_TABS.users} location={location} />
          <FormContainer>
            <FormHeader
              headingText="New User"
              cancelLink="/organization/settings/users"
            />
            {createEmployeeLoading || createUserLoading ?
             (<Spinner center />) : displayUserForm()}
          </FormContainer>
        </>
  );
};

UserNew.propTypes = {
  currentProviderId: PropTypes.string.isRequired,
  currentProviderName: PropTypes.string.isRequired,
  adminUserId: PropTypes.string.isRequired,
  hasBackOffice: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  currentProviderId: state.session.groupId,
  currentProviderName: state.session.currentProvider.name,
  adminUserId: state.user.id,
  hasBackOffice: hasBackOfficeAccess(state),
});

export default connect(mapStateToProps)(UserNew);
