import { coreApi } from 'src/api/config';
import Notifier from 'common/helpers/Notifier';
import { UPDATE_REFERRAL_CONTACT } from 'actions';

const updateReferralContact = (groupId, referralId, contact, showNotification = true) => (
  async (dispatch) => {
    try {
      const response = await coreApi.findRecord('referral', referralId);
      const referral = response.data.data;

      await Promise.all([
        coreApi.populateRelationship('case', 'case', referral),
      ]);
      await Promise.all([
        coreApi.populateRelationship('case.person', 'person', referral),
      ]);

      const referralContact = referral.case.person;

      const payload = await coreApi.updateRecord('person', referralContact.id, { ...contact });

      dispatch({
        type: UPDATE_REFERRAL_CONTACT,
        payload,
      });

      if (showNotification) {
        Notifier.dispatch(payload.status, 'Client Successfully Updated');
      }

      return payload;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  }
);

export default updateReferralContact;
