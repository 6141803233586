import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { ScreeningNeedSelector, ServiceProviders } from '@unite-us/screening';
import { BaseCard, BaseCardBody } from '@unite-us/ui';
import { browserHistory } from 'common/utils/browserHistory';
import { uup459SupersetPhase2 } from 'common/utils/FeatureFlags/flags';
import featureFlag from 'common/utils/FeatureFlag/FeatureFlag';
import { showRiskDisplayOption } from '../utils';
import { RISK_LEVELS } from '../../constants';

class RiskDisplay extends Component {
  constructor(props) {
    super(props);
    this.onCreateReferrals = this.onCreateReferrals.bind(this);
  }

  onCreateReferrals(selectedNeeds = []) {
    const { screening, isSupersetEnabled } = this.props;
    // Grab indices (rather than IDs because they're smaller) and stick them in
    // the url as a query param.
    const needIndices = selectedNeeds.map((need) => {
      const { service_type } = need;
      return screening.needs.findIndex((screeningNeed) => screeningNeed.service_type.id === service_type.id);
    });

    const needIds = selectedNeeds.map((need) => need.service_type.id);
    const path = !isSupersetEnabled ?
      `/screenings/${screening.id}/need/-1/referrals/new/add-service-types?needIds=${needIndices}` :
      // eslint-disable-next-line max-len
      `/referrals/create/add-resources?person=${screening.contact.id}&form_submission=${screening.id}&services=${needIds}`;

    browserHistory.push(path);
  }

  render() {
    const { screening, isCaseManager, isSupersetEnabled } = this.props;

    if (isEmpty(screening)) {
      return null;
    }

    const { needs = [] } = screening;
    if (isEmpty(needs)) {
      return (
        <div className="risk-display">
          <BaseCard className="risk-display__no-needs">
            <BaseCardBody withPadding>
              <h3>No needs were identified for your client.</h3>
            </BaseCardBody>
          </BaseCard>
        </div>
      );
    }

    // Determine display based on risk levels.
    const riskLevel = get(screening, 'risk_level');
    const showProvidersList = showRiskDisplayOption(
      get(screening, 'configuration.resource_listing_option', {}),
      riskLevel,
    );

    // Choose component based on the flag.
    const needsDisplay = (
      <ScreeningNeedSelector
        initiallyChecked
        needs={needs}
        onSubmit={this.onCreateReferrals}
        isHighRisk={riskLevel === RISK_LEVELS.highRisk}
        isSupersetEnabled={isSupersetEnabled}
      />
    );

    return (
      <div className="risk-display">
        { !isCaseManager && needsDisplay }
        {showProvidersList && <ServiceProviders needs={needs} />}
      </div>
    );
  }
}

RiskDisplay.propTypes = {
  screening: PropTypes.shape({
    contact: PropTypes.object,
    id: PropTypes.string,
    needs: PropTypes.array,
    risk_level: PropTypes.string,
  }),
  isSupersetEnabled: PropTypes.bool,
  isCaseManager: PropTypes.bool.isRequired,
};

RiskDisplay.defaultProps = {
  screening: {},
  isSupersetEnabled: false,
};

function mapStateToProps(state) {
  return {
    isSupersetEnabled: uup459SupersetPhase2(state),
  };
}

export default connect(mapStateToProps)(featureFlag(RiskDisplay));
