/* eslint-disable max-len */
export const NO_OON_GROUPS_MESSAGE = 'No Out of Network organization found. Please enter the name of the organization where you are referring the client.';

export const HAS_IN_AND_OON_GROUPS_MESSAGE = `Please select an organization from the following list.
  If you do not find one, enter the name of the organization where you are referring the client. `;

export const NO_IN_OR_OON_GROUPS_MESSAGE = 'No In Network or Out of Network organization found. Please enter the name of the Out of Network organization where you are referring the client.';

export const NO_IN_HAS_OON_GROUPS_MESSAGE = `No In Network organizations provide the selected service. ${HAS_IN_AND_OON_GROUPS_MESSAGE}`;

export const HAS_IN_AND_OON_PROGRAMS_MESSAGE = 'Please select a program from the following list.';
