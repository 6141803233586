import { find, get } from 'lodash';

const getContactFromState = (state, id) => {
  if (!id) {
    return null;
  }
  const contacts = get(state, 'contacts.contacts', []);
  return find(contacts, { id }) || {};
};

export default getContactFromState;
