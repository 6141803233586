import { isString } from 'lodash';
import { MINUTES_IN_A_DAY, NOON } from '../constants';

function formatTimeOfDay(time = 0) {
  const nTime = isString(time) ? Math.parse(time) : time;
  const relativeTime = (nTime % MINUTES_IN_A_DAY) < NOON ? nTime : nTime - NOON;
  const hour = Math.floor(relativeTime / 60);
  const minute = relativeTime - (hour * 60);

  const displayHour = (hour % 24) === 0 ? '12' : hour;
  const displayMinute = minute < 10 ? `0${minute}` : minute;
  const label = (nTime % MINUTES_IN_A_DAY) < NOON ? 'AM' : 'PM';

  return `${displayHour}:${displayMinute} ${label}`;
}

export default formatTimeOfDay;
