import React from 'react';
import PropTypes from 'prop-types';
import { BaseCardHeader } from '@unite-us/ui';
import {
  SubTitle,
  TitleIcon,
} from './components';

const ChartTitle = ({
  enableTableView,
  onBackButtonClick,
  onViewToggle,
  showBackButton,
  showTable,
  subTitle,
  title,
}) => (
  <BaseCardHeader title={title}>
    <SubTitle
      onBackButtonClick={onBackButtonClick}
      showBackButton={showBackButton}
      subTitle={subTitle}
    />
    <TitleIcon
      enableTableView={enableTableView}
      onViewToggle={onViewToggle}
      showTable={showTable}
    />
  </BaseCardHeader>
);

ChartTitle.propTypes = {
  enableTableView: PropTypes.bool.isRequired,
  onBackButtonClick: PropTypes.func,
  onViewToggle: PropTypes.func,
  showBackButton: PropTypes.bool.isRequired,
  showTable: PropTypes.bool.isRequired,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

ChartTitle.defaultProps = {
  enableTableView: false,
  showBackButton: false,
  showTable: false,
};

export default ChartTitle;
