import _ from 'lodash';

export default function removeElement(state, toRemoveId, target) {
  if (_.isNil(state) || _.isNil(state[target])) {
    return state;
  }
  return _.assign({}, state, {
    [target]: {
      ...state[target],
      data: _.map(state[target].data, (page) => {
        const pageData = _.get(page, 'data', []);
        if (_.some(pageData, { id: toRemoveId })) {
          return _.assign({}, page, {
            ...page,
            data: _.reject(pageData, (o) => toRemoveId === o.id),
            paging: {
              ...page.paging,
              total_count: page.paging.total_count - 1,
            },
          });
        }

        return page;
      }),
    },
  });
}
