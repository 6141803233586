import PropTypes from 'prop-types';
import React from 'react';
import { Expandable } from '@unite-us/ui';
import { isFocused, INSURANCE } from 'common/utils/isFocused';
import PaymentsInformation from 'src/components/Insurance/PaymentsInformation';
import featureFlag from 'common/utils/FeatureFlag/FeatureFlag';
import MilitaryInformation from './MilitaryInformation';

export const SupplementalInformation = ({
  contactId,
  fields,
  currentGroup,
  registerField,
  untouch,
}) => {
  const showHealthInsuranceSection = isFocused(currentGroup, INSURANCE);

  return (
    <Expandable
      className="supplemental-information"
      header="Supplemental Information"
    >
      {
         showHealthInsuranceSection && (
         <div className="payments-insurance payments-insurance-form">
           <h3 className="payments-insurance__header mb-3">Insurance Information</h3>
           <div className="health-insurance__content">
             <PaymentsInformation
               contactId={contactId || ''}
               fields={fields}
               registerField={registerField}
               untouch={untouch}
             />
           </div>
         </div>
         )
      }
      <MilitaryInformation
        fields={fields}
      />
    </Expandable>
  );
};

SupplementalInformation.propTypes = {
  contactId: PropTypes.string,
  currentGroup: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  registerField: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
};

SupplementalInformation.defaultProps = {
  contactId: '',
};

export default featureFlag(SupplementalInformation);
