import { FETCH_REFERRAL_ASSESSMENTS } from 'actions';

const defaultState = {};

export default function referralAssessmentsReducer(state = defaultState, action) {
  const { id, payload, type } = action;
  switch (type) {
    case FETCH_REFERRAL_ASSESSMENTS:
      return { ...state, [id]: payload };

    default:
      return state;
  }
}
