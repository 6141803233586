import { Icon } from '@unite-us/ui';
import React from 'react';

export const MissingNpiErrorMessage = () => (
  <div className="flex flex-row ml-2">
    <Icon
      icon="IconExclamationCircle"
      className="fill-current text-red mr-1 ml-2 mt-2"
      size={28}
    />
    <p className="pl-2 mt-3">
      NCTracks could not verify this record because the organization associated with
      the user that added it is not a Healthy Opportunities Pilot entity.
    </p>
  </div>
);
