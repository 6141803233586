import axios from 'axios';
import _ from 'lodash';
import Notifier from 'common/helpers/Notifier';
import {
  UPDATE_PROGRAM_INFORMATION,
} from 'actions';

// TODO: TBD
// This enpoint is being used to update the program dates, which is in discusion.
// https://uniteus.atlassian.net/browse/CORE-736
// https://uniteus.slack.com/archives/C01A7M28KKP/p1628100109013200

// Also used to update the program.referred_to which is in discusion too.
// https://uniteus.atlassian.net/browse/CORE-960
// https://uniteus.slack.com/archives/C01A7M28KKP/p1624305463020700

function updateProgramInformation(
  groupId,
  contactId,
  caseId,
  options = { enrolledAt: null, exitedAt: null, referredTo: null },
) {
  const params = {
    enrollment: _.uuCompactArrayOrObject({
      enrolled_at: options.enrolledAt,
      exited_at: options.exitedAt,
      referred_to: options.referredTo,
    }),
  };

  const request = axios.put(`/groups/${groupId}/contacts/${contactId}/cases/${caseId}/enrollment`, params);

  return (dispatch) => {
    function onSuccess(payload) {
      dispatch({
        type: UPDATE_PROGRAM_INFORMATION,
        payload,
        contactId,
        caseId,
      });

      Notifier.dispatch(payload.status, 'Enrollment information updated');

      return payload;
    }

    function onError(error) {
      Notifier.handleErrors(error);
      return error;
    }

    return request
      .then(onSuccess, onError);
  };
}

export default updateProgramInformation;
