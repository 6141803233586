import {
  ADD_PAYMENTS_PROVIDED_SERVICE_ERROR,
  ADD_PAYMENTS_PROVIDED_SERVICE_SUCCESS,
  ADD_PAYMENTS_PROVIDED_SERVICE_STARTED,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import { isHttpError } from 'common/utils/Error';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { apiV4 } from 'src/api/config';

const buildService = ({
  providedService, caseId, programId, planId,
}) => ({
  ...(providedService.actual_unit_amount && { actual_unit_amount: providedService.actual_unit_amount }),
  ...(providedService.unit_amount && { unit_amount: providedService.unit_amount }),
  ...(providedService.service_start_date && {
    starts_at: new Date(providedService.service_start_date * 1000).toISOString(),
  }),
  ...(providedService.service_end_date && {
    ends_at: new Date(providedService.service_end_date * 1000).toISOString(),
  }),
  ...(providedService.distribution_reason && { distribution_reason: providedService.distribution_reason }),
  ...(providedService.payee && { payee: providedService.payee }),
  ...(providedService.payment_method && { payment_method: providedService.payment_method }),
  ...(providedService.distribution_id && { distribution_id: providedService.distribution_id }),
  ...(providedService.note && { notes: providedService.note }),
  ...(providedService.metadata && { metadata: providedService.metadata }),
  case: caseId,
  program: programId,
  ...(planId && { plan: planId }),
});

function createFeeScheduleProvidedService({
  caseId,
  contactId,
  groupId,
  programId,
  providedService,
  planId,
}) {
  return async (dispatch) => {
    dispatch({ type: ADD_PAYMENTS_PROVIDED_SERVICE_STARTED });

    const service = buildService({
      providedService, caseId, programId, planId,
    });
    const payload = await apiV4.createRecord('provided_service', service, { groupId });

    if (!payload || !isHttpSuccess(payload.status)) {
      dispatch({ type: ADD_PAYMENTS_PROVIDED_SERVICE_ERROR });
      if (isHttpError(payload)) {
        const errorMessage = providedService.state === 'active' ?
          'We\'ve encountered an unexpected problem saving the contracted service. Please try again.' :
          'We\'ve encountered an unexpected problem submitting the contracted service. Please try again.';

        Notifier.dispatch('error', errorMessage);
      } else {
        Notifier.handleErrors(payload, dispatch);
      }
      return false;
    }

    dispatch({
      type: ADD_PAYMENTS_PROVIDED_SERVICE_SUCCESS,
      contactId,
      caseId,
      payload,
    });

    /*
      * We are handling the provided service creation success banner in updateFeeSchedulePrivdedService.js.
      * This is because the provided service will only be finished saving as a draft or
      * being submitted once files are created and attached to the provided service.
    */

    return payload;
  };
}

export default createFeeScheduleProvidedService;
