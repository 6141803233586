import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { INTERACTION_TYPES } from 'src/components/Notes/constants';
import _ from 'lodash';
import {
  BaseCard,
  BaseCardHeader,
  BaseCardBody,
} from '@unite-us/ui';
import {
  InteractionTabs,
  LogInteraction,
  NewNote,
  TrackService,
} from './components';
import Message from './Message';
import './stylesheets/interactions.scss';

const allTabs = [
  { value: INTERACTION_TYPES.INTERACTION, component: LogInteraction },
  { value: INTERACTION_TYPES.MESSAGE, component: Message },
  { value: INTERACTION_TYPES.SERVICE_PROVIDED, component: TrackService },
  { value: INTERACTION_TYPES.GENERAL, component: NewNote },
];

class Interactions extends Component {
  constructor(props) {
    super(props);

    const { interactionList } = this.props;
    const selectedTab = interactionList.find((t) => t.value === INTERACTION_TYPES.INTERACTION) || interactionList[0];
    const currentTabSelection = allTabs.find((t) => t.value === selectedTab.value);
    this.state = {
      currentTabSelection,
      tabs: allTabs,
    };

    this.selectTab = this.selectTab.bind(this);
  }

  selectTab(selection) {
    const { tabs } = this.state;
    this.setState({
      currentTabSelection: _.find(tabs, ['value', selection]),
    });
  }

  render() {
    const {
      cardHeaderTitle,
      caseOptions,
      contact,
      detailType,
      interactionList,
      isFromCaseOrReferral,
      cancelFunction,
      showTabs,
      showAttachedToField,
      showAttachedToCases,
    } = this.props;

    const { currentTabSelection, tabs } = this.state;

    const mapInteractionList = _.map(interactionList, 'value');

    const interactionTabs = _.filter(
      tabs,
      (item) => _.includes(mapInteractionList, item.value),
    );

    const submitFunction = (
      _.find(interactionList, ['value', currentTabSelection.value]) || {}
    ).submitFunction;

    return (
      <div className="interactions new-activity" data-testid="add-note-container" data-role="new-activity">
        <BaseCard>
          <BaseCardHeader title={cardHeaderTitle} />
          <BaseCardBody withPadding>
            {
              showTabs ? (
                <InteractionTabs
                  detailType={detailType}
                  selection={this.state.currentTabSelection}
                  selectTab={this.selectTab}
                  tabs={interactionTabs}
                />
              ) : null
            }

            <currentTabSelection.component
              caseOptions={caseOptions || []}
              contact={contact}
              submitFunction={submitFunction}
              isFromCaseOrReferral={isFromCaseOrReferral}
              detailType={detailType}
              cancelFunction={cancelFunction}
              showAttachedToField={showAttachedToField}
              showAttachedToCases={showAttachedToCases}
            />
          </BaseCardBody>
        </BaseCard>
      </div>
    );
  }
}

Interactions.propTypes = {
  cardHeaderTitle: PropTypes.string,
  caseOptions: PropTypes.array,
  contact: PropTypes.object,
  interactionList: PropTypes.arrayOf(PropTypes.shape({
    submitFunction: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  isFromCaseOrReferral: PropTypes.bool,
  detailType: PropTypes.string,
  cancelFunction: PropTypes.func,
  showTabs: PropTypes.bool,
  showAttachedToField: PropTypes.bool,
  showAttachedToCases: PropTypes.bool,
};

Interactions.defaultProps = {
  cardHeaderTitle: 'Add New Note',
  caseOptions: [],
  contact: {},
  isFromCaseOrReferral: false,
  detailType: '',
  cancelFunction: _.noop,
  showTabs: true,
  showAttachedToField: true,
  showAttachToCases: true,
};

export default Interactions;
