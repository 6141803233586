import { find, get, isEmpty, sortBy } from 'lodash';

const mergeBatchedContacts = ({ state, contacts, sort = 'created_at' } = {}) => {
  if (isEmpty(state) && isEmpty(contacts)) {
    return [];
  }

  if (isEmpty(contacts)) {
    return get(state, 'contacts') || [];
  }
  const updatedContacts = (get(state, 'contacts') || [])
    .reduce((acc, curr) => {
      const contactFromPayload = find(contacts, { id: curr.id });

      if (contactFromPayload) {
        return acc;
      }
      return [
        ...acc,
        curr,
      ];
    }, contacts);

  return sortBy(updatedContacts, [sort]);
};

export default mergeBatchedContacts;
