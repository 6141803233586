export const NOON = 720;

export const MINUTES_IN_A_DAY = 60 * 24;

export const DAYS_OF_THE_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];
