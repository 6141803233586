import { browserHistory } from 'common/utils/browserHistory';
import { ADD_INFORMATION_PATH } from '../constants';
import encodeContactData from './encodeContactData';

export default function goToAddInformationStep({
  context,
  contactId,
  contactData = {},
  fromIntegration = false,
}) {
  const dataParam = `contact=${encodeContactData(contactData)}`;
  const idParam = contactId ? `&contactId=${contactId}` : '';
  const integrationContactParam = fromIntegration ?
    '&fromIntegration=true' :
    '';
  browserHistory.push(
    `/${context}/${ADD_INFORMATION_PATH}?${dataParam}${idParam}${integrationContactParam}`,
  );
}
