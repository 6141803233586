import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, noop } from 'lodash';
import { RadioGroup } from '@headlessui/react';
import ReduxFormControlWrapper from './ReduxFormControlWrapper';

export const RadioField = ({
  className,
  hint,
  disabled,
  label,
  onBlur,
  onFocus,
  onChange,
  options,
  required,
  value,
  dataTestId,
}) => (
  <RadioGroup
    className={classNames(
      className,
      'antialiased',
      disabled ? 'opacity-50 cursor-not-allowed' : '',
    )}
    value={value}
    onChange={onChange}
    disabled={disabled}
    data-testid={dataTestId}
  >
    <RadioGroup.Label className={classNames(
      'block mb-1 leading-snug',
      'font-extrabold font-medium-font normal-case',
      'text-13px text-text-blue',
      required && 'ui-form-field__label--required', // red asterisk after content
    )}
    >
      {label}
    </RadioGroup.Label>
    {!isEmpty(hint) && (
    <p className="mt-1 text-13px text-grey-500 mb-2">
      {hint}
    </p>
    )}
    <div onFocus={onFocus} onBlur={onBlur} className="space-y-2">
      {options.map(({ label: optionLabel, value: optionValue }) => (
        <RadioGroup.Option
          className={classNames(
            disabled ? 'cursor-not-allowed' : '',
            'flex cursor-pointer outline-none',
          )}
          onBlur={onBlur}
          onFocus={onFocus}
          key={optionValue}
          value={optionValue}
        >
          {({ active, checked }) => (
            <>
              <span
                aria-hidden
                data-testid={optionLabel ? `${optionLabel.toLowerCase()}-radio-btn` : 'radio-btn'}
                className={classNames(
                  active && 'shadow-radio-ring',
                  'h-4 w-4 mr-2 border border-solid rounded-full',
                  'flex flex-shrink-0 items-center justify-center',
                  'bg-white border-action-blue',
                  !disabled ? 'hover:border-action-blue cursor-pointer' : 'cursor-not-allowed',
                )}
              >
                <span className={classNames(
                  checked ? 'bg-action-blue' : 'bg-white',
                  'rounded-full w-1.5 h-1.5',
                  )}
                />
              </span>
              <span>{optionLabel}</span>
            </>
          )}
        </RadioGroup.Option>
      ))}
    </div>
  </RadioGroup>
);

RadioField.propTypes = {
  className: PropTypes.string,
  hint: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.array,
        PropTypes.number,
      ]).isRequired,
    }),
  ),
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
  ]).isRequired,
  dataTestId: PropTypes.string,
};

RadioField.defaultProps = {
  className: '',
  hint: '',
  disabled: false,
  onBlur: noop,
  onFocus: noop,
  options: [],
  label: '',
  required: false,
  dataTestId: '',
};

export default ReduxFormControlWrapper(RadioField);
