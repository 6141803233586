import PropTypes from 'prop-types';
import React from 'react';
import { isNumber } from 'lodash';
import { Link, routerShape, withRouter } from 'react-router';
import cx from 'classnames';
import { Badge } from '@unite-us/app-components';

const LinkDisabledWhenActive = ({
  children,
  className,
  activeClassName,
  'data-testid': dataTestId,
  router,
  to,
}) => {
  const pathMatch = router?.isActive({ pathname: to });
  return pathMatch ? (
    <span className={cx(className, activeClassName, 'cursor-default')} data-testid={dataTestId}>
      {children}
    </span>
  ) : (
    <Link
      activeClassName={activeClassName}
      className={className}
      data-testid={dataTestId}
      onlyActiveOnIndex={false}
      to={to}
    >
      {children}
    </Link>
  );
};

LinkDisabledWhenActive.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  'data-testid': PropTypes.string,
  router: routerShape,
  to: PropTypes.string.isRequired,
};

LinkDisabledWhenActive.defaultProps = {
  className: undefined,
  activeClassName: undefined,
  'data-testid': undefined,
  router: undefined,
};

const WrappedLinkDisabledWhenActive = withRouter(LinkDisabledWhenActive);

const DashboardNavigationLink = ({
  to,
  label,
  className,
  count,
  'data-testid': dataTestId,
}) => (
  <WrappedLinkDisabledWhenActive
    activeClassName="bg-dark-grey"
    className="flex items-center py-2 px-3 rounded-md hover:bg-dark-grey focus:bg-dark-grey"
    data-testid={dataTestId}
    to={to}
  >
    <div
      className={cx(
        'w-full px-3 flex items-center',
        className,
      )}
    >
      <div className="flex-1 font-medium-font text-white">{label}</div>
      {isNumber(count) &&
        (
          <Badge
            color="#D0021B"
            className="flex-none content-center"
            text={count.toString()}
            style={{
              height: 18,
              fontSize: 11,
            }}
          />
        )}
    </div>
  </WrappedLinkDisabledWhenActive>
  );

DashboardNavigationLink.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  'data-testid': PropTypes.string,
};

DashboardNavigationLink.defaultProps = {
  className: undefined,
  count: null,
  'data-testid': undefined,
};

export default DashboardNavigationLink;
