import {
  CHECK_REFERRAL_PROGRAM_ENROLLMENT,
  CLEAR_REFERRAL_PROGRAM_ENROLLMENT,
  SET_FETCHING_REFERRAL_PROGRAM_ENROLLMENT,
  UNSET_FETCHING_REFERRAL_PROGRAM_ENROLLMENT,
} from 'actions';
import _ from 'lodash';

export const defaultState = {
  data: null,
  isFetching: false,
};

export default function referralProgramEnrollmentReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_FETCHING_REFERRAL_PROGRAM_ENROLLMENT:
      return _.assign({}, state, { isFetching: true });
    case UNSET_FETCHING_REFERRAL_PROGRAM_ENROLLMENT:
      return _.assign({}, state, { isFetching: false });
    case CHECK_REFERRAL_PROGRAM_ENROLLMENT: {
      return _.assign({}, state, { data: action.payload.data.data });
    }
    case CLEAR_REFERRAL_PROGRAM_ENROLLMENT: {
      return _.assign({}, state, { data: null });
    }
    default:
      return state;
  }
}
