import React from 'react';

const ProgramFormSubheader = () => (
  <p className="pb-4">
    Add programs to your organization using the
    <a
      href="http://support.uniteus.io/en/articles/2812578-add-and-update-your-organization-s-programs"
      target="_blank"
      rel="noreferrer"
    >
      &nbsp;Add and Update Your Organization’s Programs&nbsp;
    </a>
    article.
  </p>
);

export default ProgramFormSubheader;
