import React from 'react';
import PropTypes from 'prop-types';
import { LEFT_NAVIGATION } from 'common/utils/EventTracker/utils/eventConstants';
import { connect } from 'react-redux';
import NetworkSelectMenuItem from './NetworkSelectMenuItem';
import HomeLink from './HomeLink';
import LeftNavLink from './LeftNavLink';
import { getInvoicesPath } from '../utils';
import '../stylesheets/leftNav.scss';
import {
  uup459SupersetPhase2,
  cerb1199IsSupersetMyNetwork as cerb1199IsSupersetMyNetworkSelector,
} from '../../../common/utils/FeatureFlags/flags';

// eslint-disable-next-line consistent-return
const isActivePath = (currentPath, targetPath) => {
  // eslint-disable-next-line default-case
  switch (targetPath) {
    case '/dashboard':
      return currentPath.startsWith(targetPath) && !currentPath.includes('/clients/all');
    case '/dashboard/clients/all':
    case '/exports':
    case '/insights':
    case '/network':
    case '/my-network':
    case '/tasks':
    case '/invoices':
    case '/enrollments':
    case '/authorizations':
    case '/backoffice':
      return currentPath.startsWith(targetPath);
  }
};

const LeftNav = ({
  group,
  isNetworkGroupsFetching,
  isNetworkLead,
  isPayer,
  linkToNetwork,
  linkToPath,
  onLeftNavLinkClick,
  onNetworkLinkClick,
  showAuthorizations,
  showInsights,
  showTasks,
  showExports,
  showNetworkDirectory,
  showDashboard,
  showContacts,
  showBackOffice,
  showInvoices,
  showEnrollments,
  showInvoiceDisputeWorkflow,
  showPayerInvoices,
  user,
  isOrgActive,
  isSupersetEnabled,
  currentPath,
  cerb1199IsSupersetMyNetwork,
}) => (
  <div className="left-nav">
    <HomeLink onClick={() => linkToPath(isOrgActive ? '/' : '/organization/settings', LEFT_NAVIGATION.homeLink)} />
    <ul className="left-nav-align list-unstyled">
      {
        isOrgActive && (
          <>
            {
              showDashboard && !isNetworkLead && (
                <LeftNavLink
                  id="nav-workflow-dashboard"
                  eventType={LEFT_NAVIGATION.dashboardLink}
                  onLeftNavLinkClick={onLeftNavLinkClick}
                  path="/dashboard"
                  active={isActivePath(currentPath, '/dashboard')}
                >
                  Dashboard
                </LeftNavLink>
              )
            }
            {
              showContacts && !isNetworkLead && (
                <LeftNavLink
                  id="nav-clients"
                  eventType={LEFT_NAVIGATION.clientLink}
                  onLeftNavLinkClick={onLeftNavLinkClick}
                  path="/dashboard/clients/all"
                  active={isActivePath(currentPath, '/dashboard/clients/all')}
                >
                  Clients
                </LeftNavLink>
              )
            }
            {
              showExports && !isNetworkLead && (
                <LeftNavLink
                  id="nav-exports"
                  eventType={LEFT_NAVIGATION.exportsLink}
                  onLeftNavLinkClick={onLeftNavLinkClick}
                  path="/exports"
                  active={isActivePath(currentPath, '/exports')}
                >
                  Exports
                </LeftNavLink>
              )
            }
            {
              showInsights && (
                <LeftNavLink
                  id="nav-insights"
                  eventType={LEFT_NAVIGATION.insightsLink}
                  onLeftNavLinkClick={onLeftNavLinkClick}
                  path="/insights"
                  active={isActivePath(currentPath, '/insights')}
                >
                  Insights
                </LeftNavLink>
              )
            }
          </>
        )
      }

      {
        showNetworkDirectory && !isSupersetEnabled && (
          <li>
            <NetworkSelectMenuItem
              linkToNetwork={linkToNetwork}
              user={user}
              active={isActivePath(currentPath, '/network')}
              disabled={isNetworkGroupsFetching}
              onNetworkLinkClick={onNetworkLinkClick}
              group={group}
              label={cerb1199IsSupersetMyNetwork ? 'Browse Resources' : 'My Network'}
            />
          </li>
        )
      }
      {
        showNetworkDirectory && isSupersetEnabled && (
          <LeftNavLink
            id="nav-my-networks"
            eventType={LEFT_NAVIGATION.networkLink}
            onLeftNavLinkClick={onLeftNavLinkClick}
            path="/my-network"
            active={isActivePath(currentPath, '/my-network')}
          >
            {cerb1199IsSupersetMyNetwork ? 'Browse Resources' : 'My Network'}
          </LeftNavLink>
        )
      }
      {
        showTasks && (
          <LeftNavLink
            id="nav-tasks"
            eventType={LEFT_NAVIGATION.tasksListLink}
            onLeftNavLinkClick={onLeftNavLinkClick}
            path="/tasks"
            active={isActivePath(currentPath, '/tasks')}
          >
            Tasks
          </LeftNavLink>
        )
      }

      {
        isOrgActive && (
          <>
            {
              showInvoices && (
                <LeftNavLink
                  id="nav-invoices"
                  eventType={LEFT_NAVIGATION.invoicesLink}
                  onLeftNavLinkClick={onLeftNavLinkClick}
                  path={getInvoicesPath({ showInvoiceDisputeWorkflow, isNetworkLead, showPayerInvoices })}
                  active={isActivePath(currentPath, '/invoices')}
                >
                  Invoices
                </LeftNavLink>
              )
            }
            {
              showEnrollments && isPayer && (
                <LeftNavLink
                  id="nav-enrollments"
                  eventType={LEFT_NAVIGATION.enrollmentsLink}
                  onLeftNavLinkClick={onLeftNavLinkClick}
                  path="/enrollments"
                  active={isActivePath(currentPath, '/enrollments')}
                >
                  Enrollments
                </LeftNavLink>
              )
            }
            {
              showAuthorizations && isPayer && (
                <LeftNavLink
                  id="nav-authorizations"
                  eventType={LEFT_NAVIGATION.authorizationsLink}
                  onLeftNavLinkClick={onLeftNavLinkClick}
                  path="/authorizations"
                  active={isActivePath(currentPath, '/authorizations')}
                >
                  Authorizations
                </LeftNavLink>
              )
            }
            {
              showBackOffice && (
                <LeftNavLink
                  id="nav-backoffice"
                  eventType={LEFT_NAVIGATION.backOfficeLink}
                  onLeftNavLinkClick={onLeftNavLinkClick}
                  path="/backoffice"
                  active={isActivePath(currentPath, '/backoffice')}
                >
                  Back Office
                </LeftNavLink>
              )
            }
          </>
        )
      }

    </ul>
  </div>
);

LeftNav.propTypes = {
  group: PropTypes.shape({
    group: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  isNetworkGroupsFetching: PropTypes.bool.isRequired,
  isNetworkLead: PropTypes.bool,
  isPayer: PropTypes.bool,
  linkToNetwork: PropTypes.func.isRequired,
  linkToPath: PropTypes.func.isRequired,
  onNetworkLinkClick: PropTypes.func.isRequired,
  onLeftNavLinkClick: PropTypes.func.isRequired,
  showBackOffice: PropTypes.bool.isRequired,
  showInsights: PropTypes.bool.isRequired,
  showTasks: PropTypes.bool.isRequired,
  showInvoices: PropTypes.bool.isRequired,
  showEnrollments: PropTypes.bool.isRequired,
  showAuthorizations: PropTypes.bool.isRequired,
  showExports: PropTypes.bool.isRequired,
  showNetworkDirectory: PropTypes.bool.isRequired,
  showDashboard: PropTypes.bool.isRequired,
  showContacts: PropTypes.bool.isRequired,
  showInvoiceDisputeWorkflow: PropTypes.bool.isRequired,
  showPayerInvoices: PropTypes.bool.isRequired,
  isOrgActive: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    groups: PropTypes.array,
    networks: PropTypes.array,
  }).isRequired,
  isSupersetEnabled: PropTypes.bool.isRequired,
  currentPath: PropTypes.string.isRequired,
  cerb1199IsSupersetMyNetwork: PropTypes.bool.isRequired,
};

LeftNav.defaultProps = {
  isNetworkLead: false,
  isPayer: false,
};

function mapStateToProps(state) {
  return {
    isSupersetEnabled: uup459SupersetPhase2(state),
    cerb1199IsSupersetMyNetwork: cerb1199IsSupersetMyNetworkSelector(state),
  };
}

export default connect(mapStateToProps)(LeftNav);
