import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Spinner } from 'common/spinners';
import NetworkGroupDetailsHeader from './components/NetworkGroupDetailsHeader';
import GroupDetailsContentV2 from '../components/GroupDetailsContentV2';
import '../stylesheets/groupDetails.scss';

class NetworkGroupDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sendProviderOpen: false,
    };

    this.toggleSendProvider = this.toggleSendProvider.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.get(nextProps, 'group.id') !== _.get(this.props, 'group.id')) {
      this.setState({
        sendProviderOpen: false,
      });
    }
  }

  toggleSendProvider() {
    this.setState({ sendProviderOpen: !this.state.sendProviderOpen });
  }

  render() {
    const {
      center,
      network,
      onAddProvider,
      onRemoveProvider,
      programs,
      providerIsSelectable,
      providerIsSelected,
      providerLocations,
      providerServiceAreas,
      providerServices,
      selectedProvider: provider,
      selectedServiceIds,
      selectedServices,
      serve,
      stateDisplayName,
    } = this.props;

    if (_.isEmpty(provider)) {
      return <Spinner />;
    }

    return (
      <div className="network-group-details group-details">
        <NetworkGroupDetailsHeader
          group={provider}
          network={network}
          onAddProvider={onAddProvider}
          onRemoveProvider={onRemoveProvider}
          providerIsSelectable={providerIsSelectable}
          providerIsSelected={providerIsSelected}
          providerServices={providerServices}
          selectedServiceIds={selectedServiceIds}
          selectedServices={selectedServices}
          sendProviderOpen={this.state.sendProviderOpen}
          toggleSendProvider={this.toggleSendProvider}
        />
        {
          <GroupDetailsContentV2
            center={center}
            programs={programs}
            provider={provider}
            providerLocations={providerLocations}
            providerServiceAreas={providerServiceAreas}
            serve={serve}
            stateDisplayName={stateDisplayName}
          />
        }
      </div>
    );
  }
}

NetworkGroupDetails.propTypes = {
  center: PropTypes.object,
  network: PropTypes.object,
  onAddProvider: PropTypes.func,
  onRemoveProvider: PropTypes.func,
  programs: PropTypes.array,
  providerIsSelectable: PropTypes.bool,
  providerIsSelected: PropTypes.bool,
  providerLocations: PropTypes.array.isRequired,
  providerServiceAreas: PropTypes.string.isRequired,
  providerServices: PropTypes.array,
  selectedProvider: PropTypes.object,
  selectedServiceIds: PropTypes.array,
  selectedServices: PropTypes.array,
  serve: PropTypes.bool,
  stateDisplayName: PropTypes.string,
};

NetworkGroupDetails.defaultProps = {
  center: {},
  network: {},
  onAddProvider: _.noop,
  onRemoveProvider: _.noop,
  programs: [],
  providerIsSelectable: false,
  providerIsSelected: false,
  providerServices: [],
  selectedProvider: {},
  selectedServiceIds: [],
  selectedServices: [],
  serve: false,
  stateDisplayName: '',
};

export default NetworkGroupDetails;
