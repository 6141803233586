function createCenterMapMarker(map) {
  const maps = window.google.maps;
  const blue = '#517EC0';
  const white = '#FFFFFF';

  const circleObject = {
    position: map.getCenter(),
    icon: {
      path: maps.SymbolPath.CIRCLE,
      fillColor: blue,
      fillOpacity: 0.25,
      strokeOpacity: 0.25,
      strokeColor: blue,
      strokeWeight: 1,
      scale: 13,
      zIndex: 1,
    },
    map,
  };

  const marker = {
    outer: new maps.Marker({
      ...circleObject,
    }),
    middle: new maps.Marker({
      ...circleObject,
      icon: {
        ...circleObject.icon,
        fillOpacity: 0.4,
        strokeOpacity: 0.4,
        scale: 9,
      },
    }),
    inner: new maps.Marker({
      ...circleObject,
      icon: {
        ...circleObject.icon,
        fillOpacity: 1,
        strokeOpacity: 1,
        strokeColor: white,
        scale: 5,
      },
    }),
  };

  return marker;
}

export default createCenterMapMarker;
