import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { capitalize } from 'lodash';
import { Badge, Button, Icon } from '@unite-us/ui';
import { theme } from 'src/../tailwind.config';
import { browserHistory } from 'common/utils/browserHistory';
import stateBgColor from 'src/common/utils/stateBgColor';

const FeeScheduleScreeningSummary = ({ feeScheduleScreening, feeScheduleId }) => {
  const feeScheduleScreeningName = feeScheduleScreening.name;
  const starts_at = moment(feeScheduleScreening.starts_at).utc().format('MM/DD/YYYY');
  const ends_at = moment(feeScheduleScreening.ends_at).utc().format('MM/DD/YYYY');
  const state = feeScheduleScreening.state;
  const id = feeScheduleScreening?.id;

  const goEdit = () => browserHistory.push({
    pathname: `/backoffice/fee_schedules/${feeScheduleId}/fee-schedule-screenings/${id}/edit`,
  });

  return (
    <div>
      <div className="flex flex-row justify-between">
        <div className="flex bg-white justify-start items-center flex-nowrap">
          <h1 className="pr-4 font-bold text-2xl leading-8">{feeScheduleScreeningName}</h1>
          <Badge
            className="flex flex-row justify-center px-10 py-2 flex-nowrap items-center"
            text={capitalize(state)}
            color={stateBgColor(state)}
          />
        </div>
      </div>
      <div className="flex justify-end items-center">
        <Button
          id="edit-fee-schedule-screening-button"
          className="mx-2"
          iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="V2Edit" />}
          label="EDIT"
          onClick={goEdit}
          data-testid="edit-fss-btn"
        />
      </div>
      <div className="mt-1 w-full">
        <p className="pr-3 font-bold inline-block">START DATE: </p>
        <p className="pr-4 inline-block">{starts_at}</p>
        <p className="pr-4 inline-block">|</p>
        <p className="pr-3 font-bold inline-block">END DATE: </p>
        <p className="inline-block">{ends_at}</p>
      </div>
    </div>
  );
};

FeeScheduleScreeningSummary.propTypes = {
  feeScheduleScreening: PropTypes.shape({
    name: PropTypes.string.isRequired,
    starts_at: PropTypes.string.isRequired,
    ends_at: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  feeScheduleId: PropTypes.string.isRequired,
};

export default FeeScheduleScreeningSummary;
