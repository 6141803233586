import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@unite-us/ui';
import callOrLog from 'src/common/utils/callOrLog';
import { SERVICE_CASE } from 'common/utils/EventTracker/utils/eventConstants';

class CaseFooterButtons extends Component {
  constructor(props) {
    super(props);

    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onSubmitForm() {
    const { contactId } = this.props;

    callOrLog(() => this.context.eventTracker(SERVICE_CASE.OONCaseCreated, {
      contact_id: contactId,
    }));
    this.props.onSubmitForm();
  }

  render() {
    const {
      onCancel,
      submitting,
    } = this.props;

    return (
      <div className="case-footer-buttons">
        <Button
          id="cancel-btn"
          onClick={onCancel}
          label="Cancel"
          disabled={submitting}
        />
        <Button
          id="create-case-btn"
          onClick={this.onSubmitForm}
          className="create-case-btn ml-one"
          label="Create Case"
          disabled={submitting}
          primary
        />
      </div>
    );
  }
}

CaseFooterButtons.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  contactId: PropTypes.string.isRequired,
};

CaseFooterButtons.defaultProps = {
  submitting: false,
};

CaseFooterButtons.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default CaseFooterButtons;
