import { get } from 'lodash';

const JUST_DIGITS_REGEX = /\d+\.?\d*/g;
function justDigits(phoneNumber = '') {
  const digitsArray = phoneNumber.match(JUST_DIGITS_REGEX);
  return !digitsArray ? '' : digitsArray.join('');
}

function phoneNumberNotEmptyValidation(phoneFields = {}) {
  const phoneNumberValue = get(phoneFields, 'phone_number.value') || '';
  const phoneNumberDigits = justDigits(phoneNumberValue);
  return (value, message) => (
    !value && phoneNumberDigits.length > 0 ? message : null
  );
}

export default phoneNumberNotEmptyValidation;
