import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import callOrLog from 'common/utils/callOrLog';
import { LOGIN } from 'common/utils/EventTracker/utils/eventConstants';
import ListItem from './components/ListItem';
import './group-selector.scss';

class GroupSelector extends Component {
  constructor(props) {
    super(props);
    this.setGroupId = this.setGroupId.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { groups } = this.props;
    if (groups.length === 1) {
      this.setGroupId(groups[0].group.id);
    }
  }

  setGroupId(id = '') {
    const group = _.find(this.props.groups, { group: { id } }) || {};

    // Only create a log if we have a group ID.
    if (id) {
      callOrLog(() => this.context.eventTracker(LOGIN.session, {
        session_group_id: id,
        session_group_name: _.get(group, 'group.name', ''),
      }));
    }
    this.props.setGroupId(id);
  }

  render() {
    const { groups } = this.props;
    const groupListItems = groups.map((group) => (
      <ListItem
        group={group.group}
        key={group.group.id}
        setGroupId={this.setGroupId}
      />
    ));

    return (
      <div className="group-selector">
        <div className="group-selector__header">
          <h1>
            Please Select a Group
          </h1>
        </div>
        <div className="group-selector__choices">
          {groupListItems}
        </div>
      </div>
    );
  }
}

GroupSelector.propTypes = {
  groups: PropTypes.array.isRequired,
  setGroupId: PropTypes.func.isRequired,
};

GroupSelector.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default GroupSelector;
