import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import classNames from 'classnames';
import { isFunction, isEqual } from 'lodash';
import { fieldProps, getPreferredProp } from '@unite-us/ui';

const ReduxFormControlWrapper = (FormControl) => {
  const WrappedFormControl = forwardRef(({ className, ...props }, ref) => {
    useImperativeHandle(ref, () => ({ props }));

    const { onChange: explicitOnChange } = props;
    const preferredOnChange = getPreferredProp(props, 'onChange');
    const onChange = useCallback((event) => {
      if (isFunction(preferredOnChange)) {
        preferredOnChange(event);
      }
      if (isFunction(explicitOnChange) && !isEqual(explicitOnChange, preferredOnChange)) {
        explicitOnChange(event);
      }
    }, [preferredOnChange, explicitOnChange]);

    const invalid = getPreferredProp(props, 'invalid') && getPreferredProp(props, 'touched');

    const formProps = {
      invalid,
      name: getPreferredProp(props, 'name'),
      onBlur: getPreferredProp(props, 'onBlur'),
      onChange,
      onFocus: getPreferredProp(props, 'onFocus'),
      value: getPreferredProp(props, 'value') || '',
    };

    return (
      <div className={className}>
        <FormControl {...props} {...formProps} />
        <div className={classNames('mt-2 h-4 text-red text-13px', invalid ? 'visible' : 'invisible')}>
          {getPreferredProp(props, 'error')}
        </div>
      </div>
    );
  });

  WrappedFormControl.propTypes = fieldProps;

  return WrappedFormControl;
};

export default ReduxFormControlWrapper;
