import { get } from 'lodash';

export default function getReferralRecipientDisplayName(referral = {}) {
  if (referral.recalled) {
    return get(referral, 'recalled_from_group.name', '');
  }

  if (!referral.referred_to_group) {
    return get(referral, 'referred_by_group.name', '');
  }

  return get(referral, 'referred_to_group.name', '');
}
