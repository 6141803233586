import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CheckBoxField, Icon } from '@unite-us/ui';
import moment from 'moment';
import { isEmpty } from 'lodash';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Button,
  Modal,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from '@unite-us/app-components';
import Pagination from 'common/Pagination/Pagination';
import EmptyTable from 'common/tables/EmptyTable/EmptyTable';
import { Spinner } from 'common/spinners';
import ScrollToElement from 'common/utils/Form/ScrollToElement';
import {
  useFindProvidersByFeeScheduleScreening,
  useFindProvidersByName,
  useUpdateFeeScheduleScreening,
} from 'components/Backoffice/api/hooks/v1/feeScheduleScreeningHooks';
import { DEFAULT_PAGE_SIZE } from 'components/Backoffice/backofficeUtils';
import AddRelationshipDialog from 'components/Backoffice/AddRelationshipDialog';
import { browserHistory } from 'common/utils/browserHistory';

const FeeScheduleScreeningCboTable = ({ feeScheduleScreening }) => {
  const feeScheduleScreeningProviders = feeScheduleScreening.providers || [];
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const { data: providersData, isLoading } = useFindProvidersByFeeScheduleScreening(
    feeScheduleScreening.id,
    {
      page: {
        size: pageSize,
        number: pageNumber,
      },
    },
  );

  const addCboModalRef = useRef(null);
  const removeCboErrorModalRef = useRef(null);
  const [searchCboNamePlaceholder, setSearchCboNamePlaceholder] = useState('');
  const [searchCboName, setSearchCboName] = useState('');
  const [searchPageSize, setSearchPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [searchPageNumber, setSearchPageNumber] = useState(1);
  const { data: searchedProvidersData, isLoading: isSearchProvidersLoading } = useFindProvidersByName(
    searchCboName,
    {
      page: {
        size: searchPageSize,
        number: searchPageNumber,
      },
    },
  );
  const [selectedProviderId, setSelectedProviderId] = useState(null);
  const { updateRecord: updateFeeScheduleScreening } = useUpdateFeeScheduleScreening();
  const [selectedProviders, setSelectedProviders] = useState([]);

  const searchedProviders = searchedProvidersData?.data?.data || [];
  const searchedPaging = searchedProvidersData?.data?.paging || null;

  const providers = providersData?.data?.data || [];
  const paging = providersData?.data?.paging || {};

  const scrollToElementEvent = () => {
    ScrollToElement(document.getElementById('TopOfForms'));
  };

  const openAddCboModal = () => {
    addCboModalRef.current.openDialog();
  };

  const closeAddCboModal = () => {
    setSelectedProviderId(null);
    setSearchCboNamePlaceholder('');
    setSearchCboName('');
    setSearchPageNumber(1);
    setSearchPageSize(DEFAULT_PAGE_SIZE);
    addCboModalRef.current.closeDialog();
  };

  const onSearchCboNameChange = (value) => {
    setSearchCboNamePlaceholder(value);
  };

  const onSearchProviders = () => {
    setSelectedProviderId(null);
    setSearchCboName(searchCboNamePlaceholder);
  };

  const onSearchCboKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSearchProviders();
    }
  };

  const checkboxAssociatedProviders = (providerId) => providers.find((provider) => provider.id === providerId);

  const selectProvider = (_rowNumber, providerId) => {
    const isAssociated = checkboxAssociatedProviders(providerId);
    if (!isAssociated) {
      setSelectedProviderId(providerId);
    }
  };

  const addProviderToFeeScheduleScreening = async () => {
    if (!selectedProviderId) return;
    const updatedProviders = [...feeScheduleScreeningProviders, selectedProviderId];
    await updateFeeScheduleScreening(
      feeScheduleScreening.id,
      { providers: updatedProviders },
    );
  };

  const onCheckAllProviders = () => {
    setSelectedProviders(selectedProviders.length === providers.length ? [] : providers.map((provider) => provider.id));
  };

  const onCheckProvider = (providerId) => () => {
    setSelectedProviders((prevSelectedProviders) => {
      if (prevSelectedProviders.includes(providerId)) {
        return prevSelectedProviders.filter((id) => id !== providerId);
      }
      return [...prevSelectedProviders, providerId];
    });
  };

  const canRemoveProvider = (providerId) => {
    // NOTE: a provider can only be removed if their programs are not attached to the screening.
    const provider = providers.find((p) => p.id === providerId);
    const providerPrograms = provider?.programs || [];
    const screeningPrograms = feeScheduleScreening.programs || [];

    const hasProgramAttached = providerPrograms.some(
      (program) => screeningPrograms.some((screeningProgram) => screeningProgram.id === program.id),
    );

    return !hasProgramAttached;
  };

  const onRemoveProviders = async () => {
    const canAllBeRemoved = selectedProviders.every(canRemoveProvider);

    if (!canAllBeRemoved) {
      removeCboErrorModalRef.current.openModal();
      return;
    }

    const updatedProviders = feeScheduleScreeningProviders
      .filter((provider) => !selectedProviders.includes(provider.id));

    await updateFeeScheduleScreening(
      feeScheduleScreening.id,
      { providers: updatedProviders },
    );
    setSelectedProviders([]);
  };

  const onCloseRemoveCboErrorModal = () => {
    removeCboErrorModalRef.current.closeModal();
  };

  const onGoToProgramsTab = () => {
    removeCboErrorModalRef.current.closeModal();
    browserHistory.push({
      pathname: `/backoffice/fee_schedules/${feeScheduleScreening.fee_schedule.id}` +
                `/fee-schedule-screenings/${feeScheduleScreening.id}/programs`,
    });
  };

  if (isLoading) return <Spinner />;

  return (
    <>
      <BaseCard className="p-6" noBorder>
        <BaseCardHeader title="Community Based Organizations" noBorder className="font-bold">
          <Button
            id="edit-attachment-button"
            icon={{
              name: 'Plus', // TODO: update app-components to add PlusCircle icon
              position: 'left',
              color: 'current-blue',
            }}
            className="flex"
            label="Add CBO"
            onClick={openAddCboModal}
            disabled={moment.utc().isAfter(moment(feeScheduleScreening.ends_at).utc())}
          />
          <Button
            id="edit-attachment-button-secondary"
            iconLeft={<Icon className="fill-current text-current-blue" icon="IconMinusCircle" />}
            icon={{
              name: 'MinusCircle',
              color: 'current-blue',
              position: 'left',
            }}
            className="flex"
            label="Remove CBO"
            onClick={onRemoveProviders}
            disabled={moment.utc().isAfter(moment(feeScheduleScreening.ends_at).utc()) || !selectedProviders.length}
          />
        </BaseCardHeader>
        <BaseCardBody withPadding>
          <Table className="">
            <TableHeader>
              {providers.length > 0 && (
                <TableHeaderColumn className="w-6 h-6 py-3 px-4 flex justify-center items-center">
                  <CheckBoxField
                    id="select-all-checkbox"
                    className="mb-0 h-6"
                    checked={selectedProviders.length === providers.length && !isEmpty(providers)}
                    onChange={onCheckAllProviders}
                  />
                </TableHeaderColumn>
              )}
              <TableHeaderColumn>Name</TableHeaderColumn>
            </TableHeader>
            <TableBody>
              {providers.map((provider) => (
                <TableRow key={provider.id}>
                  <TableRowColumn className="w-6 h-6 flex">
                    <CheckBoxField
                      className="mb-0 h-6"
                      id={`select-checkbox-${provider.id}`}
                      checked={selectedProviders.includes(provider.id)}
                      onChange={onCheckProvider(provider.id)}
                    />
                  </TableRowColumn>
                  <TableRowColumn className="w-full">{provider.name}</TableRowColumn>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {isEmpty(providers) && (
            <EmptyTable
              className="p-6 border border-t-0 border-solid border-pale-grey"
              message="Click 'Add CBO' to add CBOs"
            />
          )}
          <div className="mt-6 mb-6">
            <Pagination
              pageNumber={pageNumber}
              pageSize={pageSize}
              totalItemCount={paging.total_count}
              totalPageCount={paging.total_pages}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
              scrollPaginationElement={scrollToElementEvent}
            />
          </div>
        </BaseCardBody>
      </BaseCard>
      <AddRelationshipDialog
        baseCardHeaderTitle="CBO Search Result"
        cancelHandler={closeAddCboModal}
        checkboxDefault={checkboxAssociatedProviders}
        confirmLabel="Confirm"
        confirmationBtnDisabled={!selectedProviderId}
        confirmationHandler={addProviderToFeeScheduleScreening}
        dialogId="add-cbo-modal"
        dialogTitle="Add Community Based Organization"
        inputId="cbo-form-input"
        inputValue={searchCboNamePlaceholder}
        isFetching={isSearchProvidersLoading}
        labelText="Organization name"
        onKeyPress={onSearchCboKeyPress}
        onClick={onSearchProviders}
        onChange={onSearchCboNameChange}
        paging={searchedPaging}
        pageNumber={searchPageNumber}
        pageSize={searchPageSize}
        scrollPaginationElement={scrollToElementEvent}
        searchTableId="search-cbo-table"
        selectElement={selectProvider}
        selectedID={selectedProviderId}
        setPageNumber={setSearchPageNumber}
        setPageSize={setSearchPageSize}
        setRef={addCboModalRef}
        tableElements={searchedProviders}
        tableHeaderColumns={['Name', 'Organization ID', 'Included']}
      />
      <Modal
        ref={removeCboErrorModalRef}
        title="Organization cannot be removed"
        size="small"
        modal
        showBorderRadius
        actions={(
          <div className="confirmation-dialog__actions">
            <span className="mr-4 inline-block">
              <Button
                label="Cancel"
                onClick={onCloseRemoveCboErrorModal}
              />
            </span>
            <span className="mr-4 inline-block">
              <Button
                label="Go to programs tab"
                primary
                onClick={onGoToProgramsTab}
              />
            </span>
          </div>
        )}
      >
        <div className="break-words">
          <p className="mb-4">
            This organization cannot be removed from the screening
            because one or more of its programs are attached to the screening.
          </p>
          <p>
            To remove this organization, first remove all of its programs on the Programs tab.
          </p>
        </div>
      </Modal>
    </>
  );
};

FeeScheduleScreeningCboTable.propTypes = {
  feeScheduleScreening: PropTypes.object.isRequired,
};

export default FeeScheduleScreeningCboTable;
