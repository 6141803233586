import { coreApi } from 'src/api/config';
import Notifier from 'common/helpers/Notifier';
import moment from 'moment';
import {
  REFERRAL_ALREADY_ACCEPTED_MESSAGE,
} from 'src/components/Referrals/ReferralStatus/constants';
import { ACCEPT_REFERRAL_SENT, ACCEPT_REFERRAL_RECEIVED } from 'actions';
import { isHttpError } from 'common/utils/Error';
import { wget } from 'lodash';

const updateCase = (caseId, referral_acceptance) => {
  const caseResponse = coreApi.updateRecord('case', caseId, {
    state: 'managed',
    opened_date: moment.unix(referral_acceptance.entry_date).utc().format('YYYY-MM-DD'),
    program: referral_acceptance.program_id,
    primary_worker: referral_acceptance.primary_case_worker_id,
    ...(referral_acceptance.service_id ? { service: referral_acceptance.service_id } : {}),
  });

  return caseResponse;
};

function acceptReferral(
  providerId,
  referralId,
  caseId,
  referral_acceptance,
  successMessage = 'Referral Acceptance Successful',
) {
  return async (dispatch) => {
    try {
      const referral = await coreApi.updateRecord('referral', referralId, { state: 'accepted' });
      await updateCase(
        caseId,
        referral_acceptance,
      );
      await Promise.all([
        coreApi.populateRelationship('case', 'case', referral.data.data),
        coreApi.populateRelationship('person', 'person', referral.data.data.case),
      ]);

      const payloadObj = {
        ...referral,
        data: {
          data: {
            ...referral.data.data,
            contact: referral.data.data.case.person,
          },
        },
      };

      dispatch({
        type: wget(referral, 'data.data.sending_provider.id') === providerId ?
          ACCEPT_REFERRAL_SENT :
          ACCEPT_REFERRAL_RECEIVED,
        payload: payloadObj,
      });

      Notifier.dispatch(referral.status, successMessage);
      return referral;
    } catch (error) {
      if (isHttpError(error, 409)) {
        Notifier.dispatch('error', REFERRAL_ALREADY_ACCEPTED_MESSAGE);
      } else {
        Notifier.handleErrors(error);
      }
      return error;
    }
  };
}

export default acceptReferral;
