import React from 'react';
import cx from 'classnames';
import { PropTypes } from 'prop-types';
import { Icon } from '@unite-us/ui';

const CaseStatus = ({
  state,
  openedDate,
  closedDate,
  showStatusDot,
}) => {
  let text = state;
  let colorClass = 'text-dark-green';

  // Closed is not a case state; make sure to display Closed text if there is a closed date present
  if (closedDate) {
    text = `Closed on ${closedDate}`;
    colorClass = 'text-red';
  } else { // non-closed cases:
    switch (state) {
      case 'referred':
        text = 'Referral Sent';
        break;
      case 'accepted':
      case 'managed':
      case 'off_platform':
        text = openedDate ? `Opened on ${openedDate}` : 'Opened';
        break;
      case 'deferred':
        text = 'Referral Not Accepted';
        colorClass = 'text-red';
        break;
      case 'pending_authorization':
        text = 'Pending Authorization';
        colorClass = 'text-yellow';
        break;
      default:
        break;
    }
  }

  return (
    <div className="flex">
      { showStatusDot &&
        (
          <Icon
            className={cx('w-8 -ml-4 fill-current', colorClass)}
            size={26}
            icon="IconCircle"
          />
        )}
      {text}
    </div>
  );
};

CaseStatus.propTypes = {
  state: PropTypes.string.isRequired,
  openedDate: PropTypes.string,
  closedDate: PropTypes.string,
  showStatusDot: PropTypes.bool,
};

CaseStatus.defaultProps = {
  openedDate: '',
  closedDate: '',
  showStatusDot: true,
};

export default CaseStatus;
