import React, { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { validateReduxForm } from 'common/form';
import { Button, SelectField } from '@unite-us/ui';
import classNames from 'classnames';
import { OverlaySpinner, Spinner } from 'common/spinners';

// action imports
import { acceptReferral } from 'actions/Referral/Group';
import { archiveReferral } from 'src/actions/Referral/Group/closeReferral';
import { fetchGroupsPrograms } from 'actions/Program/Group';
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';
import { fetchCoreServices } from 'src/actions/Group/Network';

// Util imports
import { getEmployeeNetworks } from 'src/components/Employee/employeeGetters';
import today from 'src/common/utils/today';
import callOrLog from 'common/utils/callOrLog';
import { REFERRAL, SERVICE_CASE } from 'common/utils/EventTracker/utils/eventConstants';
import Notifier from 'common/helpers/Notifier';
import {
  getReferredToNetwork,
  hasValidCaseContext,
} from 'src/components/Referrals/utils';
import { getGroup } from 'common/utils/stateHelpers';
import {
  getDeterminantNetworkId,
  getFieldValue,
  newReferralFormFields,
} from 'src/components/Referrals/utils/form';
import { goToReferralsIndex } from 'src/components/Referrals/utils/routing';
import {
  formatGroupsAndPrograms,
  removeAllGroupFields,
  structureOONProviders,
} from 'src/components/Referrals/ReferralGroupsPrograms/utils';
import { formatOverride, getServiceTypeOptionList } from 'src/components/Referrals/ReferralStatus/utils';
import {
} from 'src/components/Groups/Users/utils';
import {
  sortGroupsByDistance,
} from 'src/components/Groups/utils';
import {
  validateOONGroupFields,
} from 'src/components/Referrals/ReferralFormFields/OONGroupsSelector/utils';
import {
  getCCGroupIds,
  getSelectedGroups,
} from 'src/components/Referrals/ReferralFormFields/EditReferralDetails/utils';
import {
  validateInNetworkGroupFields,
} from 'src/components/Referrals/ReferralServicesForm/utils/errorHandling';
import { validations } from '@unite-us/app-components';

// Component Imports
import {
  OONGroupsSelector,
  ReferralFormBaseTemplate,
  ReferralOONCaseFields,
} from 'src/components/Referrals/ReferralFormFields';
import featureFlag from 'src/common/utils/FeatureFlag/FeatureFlag';
import ReferralGroupsPrograms from 'src/components/Referrals/ReferralGroupsPrograms';
import ReferralServiceProviderBrowse from
  'src/components/Referrals/components/ReferralServices/ReferralServiceProviderBrowse';
import ReferralsSearchPrograms from 'src/components/Referrals/ReferralsSearchPrograms';
import { CaseFooterButtons } from 'src/components/Referrals/ReferralServicesForm/components';
import ConsentReminder from 'src/components/ConsentReminder';

// Style Imports
import 'src/components/Referrals/ReferralFormFields/EditReferralDetails/stylesheets/editReferralDetails.scss';
import 'src/components/Referrals/stylesheets/sendReferralForm.scss';

// Feature Flags
import {
  paginateNetworkGroups,
  hasCasesOONReferralUnlicensedOrgs,
  programBasedSearchSelector,
  serviceAreaSupportForOrgs,
  crtb219ChangeServiceTypeOnReferral,
} from 'src/common/utils/FeatureFlags/flags';

import { REFERRAL_STATUS_DECLINED } from 'src/components/Referrals/constants';
import { SEND_REFERRAL_FORM } from '../../constants';

export class SendReferralForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeUsersInGroup: [],
      canShowOONCaseContext: false,
      showOONCaseContext: props.groupsOptionType === 'out-of-network',
      showReferInNetworkButton: false,
      serviceTypesUpdated: false,
      hasSensitiveErrors: false,
      serviceTypes: [],
      serviceType: {},
      isFetchingServiceType: false,
      showOONComponents: false,
    };

    this.fetchGroupsUsers = this.fetchGroupsUsers.bind(this);
    this.debouncedfetchGroupsUsers = _.debounce(this.fetchGroupsUsers, 400);
    this.onCancelCase = this.onCancelCase.bind(this);
    this.onOpenOONCase = this.onOpenOONCase.bind(this);
    this.onServiceTypeChange = this.onServiceTypeChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.createOONCase = this.createOONCase.bind(this);
    this.searchNetworkGroups = this.searchNetworkGroups.bind(this);
    this.searchNetworkGroupsPromise = this.searchNetworkGroupsPromise.bind(this);
    this.setOONCaseContext = this.setOONCaseContext.bind(this);
    this.setOONCaseContextPaginate = this.setOONCaseContextPaginate.bind(this);
    this.sendReferral = this.sendReferral.bind(this);
    this.fetchServiceType = this.fetchServiceType.bind(this);
    this.fillServiceTypeData = this.fillServiceTypeData.bind(this);
    this.updateOONCaseContext = this.updateOONCaseContext.bind(this);
    this.updateSuggestedGroups = this.updateSuggestedGroups.bind(this);
    this.updateServiceType = this.updateServiceType.bind(this);
  }

  componentDidMount() {
    const {
      forwardByNetwork,
      groupId,
      oonGroups,
      suggestedGroups,
    } = this.props;

    const referredByNetworkId = forwardByNetwork.id;
    if (referredByNetworkId) {
      const initialFieldValue = formatOverride(referredByNetworkId);
      this.props.onChangeReferredByNetwork(initialFieldValue);
    }

    this.props.fetchProvidersUserCore({
      providers: groupId,
    }).then((response) => {
      if (response) {
        const { employees: activeUsersInGroup } = response;
        this.setState({ activeUsersInGroup });
      }
    });

    const oonContext = this.props.groupsOptionType === 'out-of-network';

    this.setState({
      canShowOONCaseContext: oonContext,
      showOONCaseContext: oonContext,
      showReferInNetworkButton: oonContext,
    }, () => {
      this.searchNetworkGroups();

      this.props.selectedProgramsContext.dispatchRemoveAllPrograms();
      const groupOptions = oonContext ? oonGroups : suggestedGroups;
      this.props.updateSelectedPrograms(groupOptions);
    });
    this.fetchServiceType();
    this.updateOONCaseContext(true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextPropsValidCaseContext = hasValidCaseContext(this.props, nextProps, true);
    if (nextPropsValidCaseContext !== this.state.canShowOONCaseContext) {
      this.setState({
        canShowOONCaseContext: nextPropsValidCaseContext,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.serviceTypesUpdated) {
      this.searchNetworkGroups();
    }
    if (prevProps.suggestedGroups !== this.props.suggestedGroups) {
      this.updateOONCaseContext();
    }
    if (prevState.showOONCaseContext !== this.state.showOONCaseContext) {
      this.setState({ showOONComponents: this.state.showOONCaseContext });
    }
  }

  componentWillUnmount() {
    this.props.resetForm();
  }

  onCancelCase() {
    const { fields } = this.props;

    this.setState({
      showOONCaseContext: false,
      showReferInNetworkButton: false,
    }, () => {
      this.searchNetworkGroups();
    });

    removeAllGroupFields(fields.oonCase.selected);
    fields.oonCase.primary_worker.onChange();
    fields.oonCase.program_entry.onChange();
  }

  onOpenOONCase() {
    const { referral: { contact } } = this.props;

    callOrLog(() => (
      this.context.eventTracker(SERVICE_CASE.createOONCaseFromReferral, null, { contact })
    ));
    this.setState({
      showOONCaseContext: true,
      showReferInNetworkButton: true,
    }, () => {
      this.searchNetworkGroups();
    });
  }

  onServiceTypeChange() {
    this.setState({ serviceTypesUpdated: true });
    const { canPaginateNetworkGroups, fields } = this.props;

    removeAllGroupFields(fields.selected);

    if (canPaginateNetworkGroups) {
      removeAllGroupFields(fields.oonCase.selected);
    }
  }

  onSubmit(values = {}) {
    if (this.state.showOONCaseContext) {
      return this.createOONCase(values);
    }
    return this.sendReferral(values);
  }

  setOONCaseContext(networkGroups, serviceAreaGroups) {
    const forceOONCaseContext = this.state.canShowOONCaseContext &&
      _.get(networkGroups, 'length', 0) === 0 &&
      _.get(serviceAreaGroups, 'length', 0) === 0;

    if (forceOONCaseContext && !this.state.showOONCaseContext) {
      this.setState({ showOONCaseContext: true });
    }
  }

  setOONCaseContextPaginate(networkGroups, serviceAreaGroups) {
    const forceOONCaseContext = this.state.canShowOONCaseContext &&
      _.get(networkGroups, 'length', 0) === 0 &&
      _.get(serviceAreaGroups, 'length', 0) === 0;

    if (forceOONCaseContext && !this.state.showOONCaseContext) {
      this.setState({ showOONCaseContext: true, showReferInNetworkButton: false }, () => {
        this.searchNetworkGroups();
      });
    }
  }

  createOONCase(service) {
    const {
      fields,
      groupId,
      referral,
      referredByNetwork,
    } = this.props;
    const referralId = _.wget(referral, 'id');
    let selectedGroups = validateOONGroupFields(fields.oonCase.selected || []);
    const customGroups = validateOONGroupFields(fields.oonCase.custom || []);
    const serviceId = fields.service_type.value.id;

    if (!_.isEmpty(customGroups)) {
      const formattedCustomGroups = _.map(customGroups, (custom) => ({
        group: {
          displayName: custom?.group.value,
          id: custom?.group.value,
          isCustom: true,
        },
      }));

      selectedGroups = selectedGroups.concat(formattedCustomGroups);
    }

    const newReferral = {
      id: referralId,
      description: _.get(referral, 'description', ''),
      referred_by_network_id: referredByNetwork.id,
      referred_by_group_id: groupId,
      referred_to_groups: service.referred_to || selectedGroups.map((provider) => provider.group.value) || null,
      service_type_id: serviceId,
      case_id: _.get(referral, 'case.id', ''),
      primary_case_worker_id: _.get(service, 'oonCase.primary_worker.id') || null,
      state: _.get(referral, 'state', ''),
      out_of_network_providers: structureOONProviders(selectedGroups),
    };

    const promise = this.props.sendReferral(newReferral, true);
    return Promise.resolve(promise).then(async () => {
      const referralState = _.wget(referral, 'state');
      if (['recalled', 'declined'].includes(referralState)) {
        await archiveReferral(referralId);
      }
      browserHistory.push('/dashboard/oon-cases/open');
    });
  }

  fetchGroupsUsers(search, callback) {
    const { groupId } = this.props;
    return this.props.fetchProvidersUserCore({
      providers: groupId,
      options: { text: search },
    }).then((response) => {
      if (response) {
        const { employees: activeUsersInGroup } = response;
        return callback({ options: activeUsersInGroup });
      }

      return [];
    });
  }

  async fetchServiceType() {
    const { referredByNetwork, referral, fields } = this.props;
    const servicesIds = referredByNetwork.services.map((service) => service.id);
    const options = await getServiceTypeOptionList(servicesIds, referral);
    this.props.untouch(fields.service_type.name);
    this.setState({ serviceTypes: options });
    this.fillServiceTypeData();
  }

  async updateServiceType(referredByNetworkId, referredToNetworkId) {
    this.setState({ isFetchingServiceType: true });
    this.fillServiceTypeData();
    await this.updateSuggestedGroups(referredByNetworkId, referredToNetworkId);
  }

  async fillServiceTypeData() {
    const { fields } = this.props;
    const response = await fetchCoreServices([fields.service_type.value.id]);
    const parentId = Object.keys(response[0].relationships).length === 0 ?
      response[0].id :
      response[0].relationships.parent.data.id;
    const serviceType = {
      id: response[0].id,
      parent: {
        id: parentId,
      },
      ...response[0].attributes,
    };
    this.setState({ serviceType });
  }

  async updateOONCaseContext(showOONComponents = false) {
    const {
      suggestedGroups,
      updateGroupsOptionType,
      referredByNetworkId,
      referredToNetworkId,
      } = this.props;
    const context = _.isEmpty(suggestedGroups);
    this.setState({
      canShowOONCaseContext: context,
      showOONCaseContext: context,
      showReferInNetworkButton: context,
    });
    updateGroupsOptionType(context);
    if (showOONComponents) {
      this.setState({ showOONComponents: context });
      this.updateServiceType(referredByNetworkId, referredToNetworkId);
    }
    this.setState({ isFetchingServiceType: false });
  }

  async updateSuggestedGroups(referredByNetworkId, referredToNetworkId) {
    const { paginateSearchNetworkGroups } = this.props;
    paginateSearchNetworkGroups({
      showOONCaseContext: false,
      referredByNetworkId,
      referredToNetworkId,
      type: 'send',
    });
  }

  sendReferral(values = {}) {
    const {
      fields,
      groupId,
      networks,
      referral,
      referredByNetwork,
    } = this.props;
    const referralId = _.wget(referral, 'id');
    const referralState = _.wget(referral, 'state');
    const referredToGroups = formatGroupsAndPrograms(values.selected);
    const serviceId = fields.service_type.value.id;

    const newReferral = {
      id: referralId,
      auto_recallable: referredToGroups.length > 1 && values.auto_recallable ?
        'true' :
        'false',
      description: _.get(referral, 'description', ''),
      referred_by_network_id: referredByNetwork.id,
      referred_by_group_id: groupId,
      referred_to_groups: referredToGroups,
      referred_to_network_id: referredByNetwork.id,
      service_type_id: serviceId,
      case_id: _.get(referral, 'case.id', ''),
      state: _.get(referral, 'state', ''),
    };

    return validateInNetworkGroupFields({ networks, services: [fields] })
      .then(() => {
        const promise = this.props.sendReferral(newReferral);
        return Promise.resolve(promise).then(async (response) => {
          const createdReferrals = _.map(response, 'data.data', []);
          const referralIds = _.map(createdReferrals, 'id');
          if (['recalled', 'declined'].includes(referralState)) {
            await archiveReferral(referralId);
          }

          callOrLog(() => this.context.eventTracker(REFERRAL.sent, {
            referral_ids: referralIds,
          }));
          this.props.setDashboardRefetch();
          goToReferralsIndex('sent');
          this.props.resetForm();
          this.props.destroyForm(SEND_REFERRAL_FORM);
        });
      })
      .catch((err) => {
        const message = err.message || err[0];
        Notifier.dispatch(err.status || 'error', message);
      });
  }

  searchNetworkGroupsPromise(params) {
    return new Promise((resolve) => {
      this.props.searchNetworkGroups(params, (data) => {
        resolve(data);
      });
    });
  }

  searchNetworkGroups() {
    const { canPaginateNetworkGroups, referredByNetwork, fields } = this.props;

    this.setState({ serviceTypesUpdated: false }, () => {
      const referredByNetworkId = referredByNetwork.id;
      const referredToNetworkId = getDeterminantNetworkId(fields);

      const callbackSetOONCaseContext = canPaginateNetworkGroups ?
        this.setOONCaseContextPaginate :
        this.setOONCaseContext;

      Promise.all([
        this.searchNetworkGroupsPromise({
          showOONCaseContext: this.state.showOONCaseContext,
          referredByNetworkId,
          referredToNetworkId,
          type: 'send',
        }),
        this.searchNetworkGroupsPromise({
          showOONCaseContext: this.state.showOONCaseContext,
          referredByNetworkId,
          referredToNetworkId,
          type: 'send',
          filterByServiceArea: true,
        }),
      ]).then((groupValues) => callbackSetOONCaseContext(groupValues[0], groupValues[1]));
    });
  }

  render() {
    const {
      caseReferrals,
      canPaginateNetworkGroups,
      canSearch,
      fields,
      group,
      handleSubmit,
      debouncedSearchNetworkGroups,
      isFetchingGroup,
      isFetchingOONGroups,
      isFetchingNetworkGroups,
      isProgramBasedSearch,
      nationalOONGroups,
      nationalStateSuggestedGroups,
      networks,
      oonGroups,
      paginateSearchNetworkGroups,
      query,
      referral,
      referredByNetwork,
      registerField,
      unregisterField,
      searchNetworkGroups,
      submitting,
      suggestedGroups,
      removeSelectedBrowseGroup,
      originCoordinates,
      groupsOptionType,
      serviceAreaSupportForOrgsFlag,
      selectedProgramsContext,
      changeServiceTypeOnReferral,
      preventChangingServiceType,
    } = this.props;
    const {
      activeUsersInGroup,
      canShowOONCaseContext,
      showOONCaseContext,
      showReferInNetworkButton,
      hasSensitiveErrors,
      serviceTypes,
      serviceType,
      isFetchingServiceType,
      showOONComponents,
    } = this.state;

    const isFetching = isFetchingGroup || isFetchingNetworkGroups;

    const selectedGroups = getSelectedGroups(fields, groupsOptionType);

    const referredByNetworkId = _.get(getFieldValue(fields.referred_by_network), 'id');
    const referredToNetworkId = getDeterminantNetworkId(fields);

    const oonCaseFieldsClass = classNames({
      'oon-case-field': true,
      hidden: !showOONCaseContext,
    });

    const selectedOONFields = _.get(fields, 'oonCase.selected', []);
    const customFields = _.get(fields, 'oonCase.custom', []);

    let suggestedGroupsSorted;
    let oonGroupsSorted;
    const openReferralsRecipientsIds = _.filter(caseReferrals, (r) => r.state === 'sent')
      .map((r) => r.receiving_provider.id);
    const filteredSuggestedGroups = suggestedGroups.filter((el) => openReferralsRecipientsIds.indexOf(el.id) < 0);

    if (!canPaginateNetworkGroups) {
      // When this `canPaginateNetworkGroups` feature flag is OFF, we no longer have the API to sort our groups.
      // So we need to do it manually here, with the originCoordinates fetched from ReferralFormBaseTemplate.
      suggestedGroupsSorted = sortGroupsByDistance(filteredSuggestedGroups, originCoordinates);
      oonGroupsSorted = sortGroupsByDistance(oonGroups, originCoordinates);
    }

    const noProvidersSelected = !selectedGroups.length;

    return (
      <div className="send-referral-form-container">
        <form
          onSubmit={handleSubmit(this.onSubmit)}
          className="send-referral-form"
        >
          <div className="send-referral-form__container">
            <div className="send-referral-form__inputs">
              <OverlaySpinner
                text={showOONCaseContext ? 'Creating Case...' : 'Sending Referral...'}
                show={submitting}
              />

              <div className="row">
                <div className="col-xs-6">
                  <div className="display-field">
                    <div className="display-field__label">Sending Network</div>
                    {referredByNetwork.name}
                  </div>
                </div>
              </div>

              <div className="service-type-section row">
                <div className="col-xs-6">
                  <div className="display-field">
                    {changeServiceTypeOnReferral && !preventChangingServiceType ? (
                      <SelectField
                        className="accept-referral-service-type-select"
                        label="Service Type"
                        ref={registerField}
                        field={fields.service_type}
                        id="service_type"
                        options={serviceTypes}
                        onChange={() => {
                          this.updateServiceType(referredByNetworkId, referredToNetworkId);
                        }}
                        valueKey="id"
                        labelKey="name"
                        forceObjectValue
                        clearable={false}
                        searchPlaceholderValue="Search"
                        inline={false}
                        validations={validations.isRequired}
                        required
                      />
                    ) :
                      (
                        <>
                          <div className="display-field__label">Service Type</div>
                          {serviceType.name}
                        </>
                      )}
                  </div>
                </div>
              </div>
              {isFetchingServiceType && <Spinner />}
              {!isFetchingServiceType && (
                !showOONComponents ? (
                  <div className="groups-selector">
                    <ReferralGroupsPrograms
                      caseReferrals={caseReferrals}
                      canPaginateNetworkGroups={canPaginateNetworkGroups}
                      ccGroupIds={getCCGroupIds(networks, fields)}
                      contact={referral.contact}
                      currentUserGroup={group}
                      hide={!canSearch || isFetching}
                      isFetchingOONGroups={isFetchingOONGroups}
                      isProgramBasedSearch={isProgramBasedSearch}
                      loading={canSearch && isFetching}
                      ref={(value) => { this.referralGroupPrograms = value; }}
                      referral={referral}
                      searchNetworkGroups={searchNetworkGroups}
                      debouncedSearchNetworkGroups={debouncedSearchNetworkGroups}
                      selectedFields={fields.selected}
                      paginateSearchNetworkGroups={paginateSearchNetworkGroups}
                      query={query}
                      nationalStateSuggestedGroups={nationalStateSuggestedGroups}
                      removeSelectedBrowseGroup={removeSelectedBrowseGroup}
                      serviceTypeField={fields.service_type}
                      serviceTypeObj={serviceType}
                      shouldSort={false}
                      suggestedGroups={canPaginateNetworkGroups ? filteredSuggestedGroups : suggestedGroupsSorted}
                      originCoordinates={originCoordinates}
                      toggleBrowse={this.props.triggerBrowse}
                      serviceAreaSupportForOrgsFlag={serviceAreaSupportForOrgsFlag}
                      setHasSensitiveErrors={
                        (val) => {
                          if (val !== hasSensitiveErrors) {
                            this.setState({ hasSensitiveErrors: val });
                          }
                        }
                      }
                      selectedProgramsContext={selectedProgramsContext}
                    />
                  </div>
                ) :
                  (
                    <div>
                      <>
                        <OONGroupsSelector
                          canPaginateNetworkGroups={canPaginateNetworkGroups}
                          contact={referral.contact}
                          debouncedSearchNetworkGroups={debouncedSearchNetworkGroups}
                          paginateSearchNetworkGroups={paginateSearchNetworkGroups}
                          fields={fields}
                          customFields={customFields}
                          query={query}
                          groupsOptionType={groupsOptionType}
                          removeSelectedBrowseGroup={removeSelectedBrowseGroup}
                          isFetchingOONGroups={isFetchingOONGroups}
                          isProgramBasedSearch={isProgramBasedSearch}
                          inNetworkGroupsEmpty={
                            canPaginateNetworkGroups ?
                              filteredSuggestedGroups.length === 0 :
                              suggestedGroupsSorted.length === 0
                          }
                          network={referredByNetwork}
                          oonGroups={canPaginateNetworkGroups ? oonGroups : oonGroupsSorted}
                          nationalOONGroups={nationalOONGroups}
                          originCoordinates={originCoordinates}
                          referral={referral}
                          registerField={registerField}
                          selectedFields={selectedOONFields}
                          toggleBrowse={this.props.triggerBrowse}
                          serviceAreaSupportForOrgsFlag={serviceAreaSupportForOrgsFlag}
                          isFetchingNetworkGroups={isFetchingNetworkGroups}
                        />
                        <div className={oonCaseFieldsClass}>
                          <ReferralOONCaseFields
                            activeUsersInGroup={activeUsersInGroup}
                            fetchGroupsUsers={this.debouncedfetchGroupsUsers}
                            fields={fields}
                            hidden={!showOONCaseContext}
                            registerField={registerField}
                            unregisterField={unregisterField}
                          />
                        </div>
                      </>
                    </div>
                  ))}
              {
                showReferInNetworkButton && showOONCaseContext && (
                  <Button
                    id="open-in-network-case-btn"
                    className="open-in-network-case-button mb-one"
                    label="Refer In Network"
                    onClick={this.onCancelCase}
                    primary
                  />
                )
              }
              {
                canSearch && !isFetching && canShowOONCaseContext && !showOONCaseContext && (
                  <Button
                    id="open-out-of-network-case-btn"
                    className="open-out-of-network-case-button mt-one mb-one"
                    label="Create Out Of Network Case"
                    onClick={this.onOpenOONCase}
                    primary
                  />
                )
              }
            </div>
          </div>

          <ConsentReminder />

          {
            showOONCaseContext ? (
              <div className="send-referral-form__actions">
                <CaseFooterButtons
                  onCancel={this.onCancelCase}
                  onSubmitForm={handleSubmit(this.onSubmit)}
                  submitting={submitting}
                  contactId={referral.contact.id}
                />
              </div>
            ) : (
              <div className="send-referral-form__actions">
                <span className="send-referral-form__action-item">
                  <Button
                    id="send-referral-send-btn"
                    onClick={handleSubmit(this.onSubmit)}
                    disabled={submitting || hasSensitiveErrors || noProvidersSelected}
                    label="Confirm and Submit"
                    primary
                  />
                </span>
              </div>
            )
          }
        </form>

        {
          this.props.showBrowse && (isProgramBasedSearch ? (
            <ReferralsSearchPrograms
              toggleBrowse={() => this.props.triggerBrowse(groupsOptionType)}
              referral={{
                fromNetworkId: referredByNetworkId,
                isLicensed: !showOONCaseContext,
                personId: _.wget(referral, 'contact.id'),
                providerId: this.props.groupId,
                providerSensitive: false,
                serviceId: serviceType.id,
                serviceName: serviceType.name,
                toNetworkId: referredToNetworkId,
              }}
            />
          ) : (
            <ReferralServiceProviderBrowse
              addGroups={this.props.addSelectedBrowseGroups}
              contact={_.wget(referral, 'contact')}
              contextAction="send"
              currentUserGroup={group}
              description={_.get(referral, 'description', '')}
              groupsOptionType={groupsOptionType}
              referral={referral}
              groups={canPaginateNetworkGroups ? filteredSuggestedGroups : suggestedGroupsSorted}
              oonGroups={canPaginateNetworkGroups ? oonGroups : oonGroupsSorted}
              referredByNetworkId={referredByNetworkId}
              referredToNetworkId={referredToNetworkId}
              selectedGroups={selectedGroups}
              sender={_.wget(referral, 'referred_by_group.name', '')}
              serviceType={serviceType}
              toggleBrowse={this.props.triggerBrowse}
            />
          ))
        }
      </div>
    );
  }
}

SendReferralForm.propTypes = {
  caseReferrals: PropTypes.array,
  addSelectedBrowseGroups: PropTypes.func.isRequired,
  canPaginateNetworkGroups: PropTypes.bool.isRequired,
  canSearch: PropTypes.bool.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  forwardByNetwork: PropTypes.object.isRequired,
  group: PropTypes.shape({
    referral_scopes: PropTypes.array,
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  groupsOptionType: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  isFetchingGroup: PropTypes.bool.isRequired,
  isFetchingNetworkGroups: PropTypes.bool.isRequired,
  isFetchingOONGroups: PropTypes.bool.isRequired,
  isProgramBasedSearch: PropTypes.bool.isRequired,
  nationalOONGroups: PropTypes.array,
  nationalStateSuggestedGroups: PropTypes.array,
  networks: PropTypes.array.isRequired,
  debouncedSearchNetworkGroups: PropTypes.func.isRequired,
  destroyForm: PropTypes.func.isRequired,
  searchNetworkGroups: PropTypes.func.isRequired,
  onChangeReferredByNetwork: PropTypes.func.isRequired,
  oonGroups: PropTypes.array,
  originCoordinates: PropTypes.array,
  paginateSearchNetworkGroups: PropTypes.func.isRequired,
  query: PropTypes.string,
  referredByNetwork: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    services: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  referral: PropTypes.shape({
    contact: PropTypes.object.isRequired,
    referred_by_network: PropTypes.shape({
      id: PropTypes.string,
    }),
    referred_to_network: PropTypes.shape({
      id: PropTypes.string,
    }),
    service_type: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  registerField: PropTypes.func.isRequired,
  unregisterField: PropTypes.func.isRequired,
  removeSelectedBrowseGroup: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  selectedProgramsContext: PropTypes.shape({
    selectedPrograms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })).isRequired,
    dispatchRemoveAllPrograms: PropTypes.func.isRequired,
    dispatchAddProgram: PropTypes.func.isRequired,
    dispatchRemoveProgram: PropTypes.func.isRequired,
  }).isRequired,
  sendReferral: PropTypes.func.isRequired,
  serviceAreaSupportForOrgsFlag: PropTypes.bool,
  serviceType: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  setDashboardRefetch: PropTypes.func.isRequired,
  suggestedGroups: PropTypes.array,
  showBrowse: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  triggerBrowse: PropTypes.func.isRequired,
  updateSelectedPrograms: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  changeServiceTypeOnReferral: PropTypes.bool.isRequired,
  updateGroupsOptionType: PropTypes.func.isRequired,
  referredByNetworkId: PropTypes.string.isRequired,
  referredToNetworkId: PropTypes.string.isRequired,
  preventChangingServiceType: PropTypes.bool,
};

SendReferralForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

SendReferralForm.defaultProps = {
  caseReferrals: [],
  groupsOptionType: '',
  suggestedGroups: [],
  nationalOONGroups: [],
  nationalStateSuggestedGroups: [],
  oonGroups: [],
  originCoordinates: [],
  query: '',
  serviceAreaSupportForOrgsFlag: false,
  preventChangingServiceType: false,
};

function mapStateToProps(state, ownProps) {
  const { referral = {} } = ownProps;
  const canPaginateNetworkGroups = paginateNetworkGroups(state);
  const groupId = state.session.groupId;
  const group = getGroup(state, groupId) || {};
  const referralScopes = _.get(group, 'referral_scopes', []);
  const serviceType = _.wget(referral, 'service_type', {});

  const forwardByNetwork = referral.status === REFERRAL_STATUS_DECLINED ?
    referral.referred_by_network :
    getReferredToNetwork(referral);

  const forwardingScopes = _.filter(referralScopes, (scope) => (
    scope.network.id === forwardByNetwork.id
  ));

  const casesOONReferralUnlicensedOrgs = hasCasesOONReferralUnlicensedOrgs(state);
  const networks = getEmployeeNetworks({ state });
  const contact = _.get(referral, 'contact', {});
  const serviceAreaSupportForOrgsFlag = serviceAreaSupportForOrgs(state);

  return {
    contact,
    canPaginateNetworkGroups,
    casesOONReferralUnlicensedOrgs,
    forwardByNetwork,
    initialValues: {
      oonCase: {
        program_entry: today(),
      },
      referred_by_network: forwardByNetwork,
      service_type: serviceType,
      selected: [
        {
          group: '',
          program: '',
        },
      ],
    },
    isProgramBasedSearch: programBasedSearchSelector(state),
    changeServiceTypeOnReferral: crtb219ChangeServiceTypeOnReferral(state),
    networks,
    referredByNetwork: forwardByNetwork,
    referralScopes: forwardingScopes,
    serviceType,
    serviceAreaSupportForOrgsFlag,
  };
}

const fields = newReferralFormFields();
const SendReferralFormWithFlags = featureFlag(SendReferralForm);

export default validateReduxForm({
  form: SEND_REFERRAL_FORM,
  fields,
}, mapStateToProps, {
  acceptReferral,
  fetchGroupsPrograms,
  fetchProvidersUserCore,
})(ReferralFormBaseTemplate(SendReferralFormWithFlags));
