import React, { useRef, useContext, useCallback } from 'react';
import { Link } from 'react-router';
import { PropTypes } from 'prop-types';
import { get, isEmpty, startCase, toLower } from 'lodash';
import { Icon, Button, Dialog } from '@unite-us/ui';
import * as env from 'src/config/env/env.config';
import { FileThumbnail, TrackerContext } from '@unite-us/client-utils';
import { Spinner } from 'src/common/spinners';
import { Card, CardDetail, CardHeader } from 'src/common/Card';
import cx from 'classnames';
import Notifier from 'src/common/helpers/Notifier';
import InvoiceStatusIndicator from 'src/pages/invoices/utils/InvoiceStatusIndicator';
import { browserHistory } from 'common/utils/browserHistory';
import { useUnarchiveRecord, useArchiveRecord, useFeatureFlag } from 'common/hooks';
import { usePSActive } from 'src/pages/invoices/hooks/updateProvidedService';
import { SecondaryButton, PrimaryButton } from 'src/common/TailwindComponents';
import { theme } from 'src/../tailwind.config';
import callOrLog from 'src/common/utils/callOrLog';
import formatDate from 'src/common/utils/Dates/formatDate';
import formatShortDate from 'src/common/utils/Dates/formatShortDate';
import { INVOICE } from 'common/utils/EventTracker/utils/eventConstants';
import InsuranceStatusIndicator from 'components/Referrals/ReferralDetail/components/InsuranceStatusIndicator';
import { INSURANCE_STATUSES } from 'src/components/Insurance/constants';
import { useInvalidateQueries } from 'src/api/APIHooks';
import AuthorizationDetails from './AuthorizationDetails';
import InvoiceRejectionForm from './InvoiceRejectionForm';
import InvoiceDisputeForm from './InvoiceDisputeForm';
import ServiceDetails from './ServiceDetails';
import dateDisputed from '../utils/dateDisputed';
import dateDisputeResolved from '../utils/dateDisputeResolved';
import formatInvoiceType from '../utils/formatInvoiceType';
import DisputeResolutionForm from './DisputeResolutionForm';
import SubmitInvoiceButton from './SubmitInvoiceButton';
import FixInvoiceResponseForm from './FixInvoiceResponseForm';
import InvoiceDifferenceIndicator from './InvoiceDifferenceIndicator';
import usePayerWQInteractiveView from '../hooks/usePayerWQInteractiveView';
import useInvoiceFixVersion from '../hooks/useInvoiceFixVersion';
import {
  NETWORK_LEAD_ARCHIVABLE_STATUSES,
  CBO_ADMIN_ARCHIVABLE_STATUSES,
  PAYER_DISPUTED_STATUSES,
  DISPUTABLE_STATUSES,
  REJECTED_OPTIONS,
} from '../constants';
import { useSubmitInvoice, useSubmitInvoiceToPayer, usePayerUpdateInvoiceStatus } from '../hooks/useUpdateInvoice';

export const InvoiceDrawer = ({
  deselectAll,
  getNextInvoice,
  getPrevInvoice,
  invoice,
  isNetworkLead,
  onClose,
  path,
  showClaimsCodes,
  showPayerInvoices,
  useInvoiceUserRole,
  disableSubmitButton,
  files,
  isFetching,
  isLoading,
  isCBOProvider,
  providerAddress,
  placeOfService,
  procedureCodes,
  procedureCodeModifiers,
  serviceDescription,
  showFixAndMakeNewInvoice,
  showInvoiceType,
  totalAmountInvoiced,
  totalAmountPaid,
  versions,
  zCodes,
  networkId,
  payerWQInteractiveView,
  currentEmployee,
  groupId,
  handleDisableSubmitButton,
  insurance,
  feeSchedule,
  isMostRecent,
  insuranceLoaded,
}) => {
  const pays3693AddLinkToClientFacesheetInIwq = useFeatureFlag('pays-3693-add-link-to-client-facesheet-in-iwq');
  const pays5214BillingForScreenings = useFeatureFlag('pays-5214-billing-for-screenings');

  const { payerWQInteractiveView: payerOnInvoiceHasWQInteractiveView } = usePayerWQInteractiveView(invoice.payer_id);
  const isArchived = !!get(invoice, 'user_archives.0.id');

  const transmitToPayerWrapper =
    useSubmitInvoiceToPayer(invoice.id, 'transmitted_to_payer', 'Your invoice was transmitted to the payer.');

  const eventTracker = useContext(TrackerContext);

  const transmitToPayer = () => {
    transmitToPayerWrapper();
    callOrLog(() => eventTracker(
      INVOICE.nlClickedSubmit,
      { current_network: networkId },
    ));
  };

  const submitToPayerWrapper =
    useSubmitInvoiceToPayer(invoice.id, 'submitted_to_payer', 'Your invoice was submitted to the payer.');
  const submitToPayer = () => {
    submitToPayerWrapper();
    onClose();
    callOrLog(() => eventTracker(
      INVOICE.nlClickedSubmit,
      { current_network: networkId },
    ));
  };

  const submitToNetworkLeadWrapper = useSubmitInvoice(invoice.id);

  const submitToNetworkLead = () => {
    submitToNetworkLeadWrapper();
    onClose();
    callOrLog(() => eventTracker(
      INVOICE.cboClickedApprove,
      { current_network: networkId },
    ));
  };

  const fullPayerWQView = payerWQInteractiveView && showPayerInvoices;

  const payerAcceptedInvoice = invoice.invoice_status === 'accepted_by_payer';

  const payerCompleteInvoicePaymentWrapper = usePayerUpdateInvoiceStatus(invoice.id, 'paid');
  const payerCompleteInvoicePayment = () => {
    payerCompleteInvoicePaymentWrapper();
    callOrLog(() => eventTracker(
      INVOICE.payerRejectInvoice,
      { current_network: networkId },
    ));
  };

  const payerAcceptInvoiceWrapper = usePayerUpdateInvoiceStatus(invoice.id, 'accepted_by_payer');
  const payerAcceptInvoice = () => {
    payerAcceptInvoiceWrapper();
    callOrLog(() => eventTracker(
      INVOICE.payerAcceptInvoice,
      { current_network: networkId },
    ));
  };

  const payerClosedInvoice = PAYER_DISPUTED_STATUSES.includes(invoice.invoice_status);

  const payerNeedsAttentionInvoice = !payerAcceptedInvoice && !payerClosedInvoice;

  const iconClass = 'fill-current text-dark-grey h-18';

  const resolved = get(invoice, 'invoice_dispute_resolution_reason.id');

  const fixVersionInvoices = useInvoiceFixVersion(invoice, showPayerInvoices);

  const canFixInvoice = payerClosedInvoice && !!resolved && isEmpty(fixVersionInvoices);

  const fixResponseModalRef = useRef();

  const openFixResponseModal = () => {
    fixResponseModalRef.current.openDialog();
  };
  const closeFixResponseModal = () => {
    fixResponseModalRef.current.closeDialog();
  };

  const invalidateQueries = useInvalidateQueries();

  const unarchiveInvoice = useUnarchiveRecord('invoice', {
    onSuccess: () => {
      onClose();
      invalidateQueries('invoice');
      Notifier.dispatch('success', 'Invoice unarchived.');
    },
  });
  const handleUnarchive = useCallback(
    () => unarchiveInvoice({ recordId: invoice.id }),
    [unarchiveInvoice, invoice.id],
  );

  const archivableStatuses = isNetworkLead ? NETWORK_LEAD_ARCHIVABLE_STATUSES : CBO_ADMIN_ARCHIVABLE_STATUSES;

  const currentlyInDispute = get(invoice, 'under_dispute');
  const disputed = !isEmpty(get(invoice, 'invoice_dispute_reason.id'));

  const canArchiveInvoice = showPayerInvoices ? (
    archivableStatuses.includes(invoice.invoice_status) && !currentlyInDispute && !canFixInvoice
  ) : (
    archivableStatuses.includes(invoice.invoice_status) && !disputed
  );

  const archiveInvoice = useArchiveRecord('invoice', {
    onSuccess: () => {
      onClose();
      invalidateQueries('invoice');
      Notifier.dispatch('success', 'Invoice archived.');
    },
  });
  const handleArchive = useCallback(
    () => archiveInvoice({ recordId: invoice.id, employeeId: currentEmployee.id }),
    [archiveInvoice, invoice.id, currentEmployee.id],
  );

  const rejectionModalRef = useRef();

  const openRejectionModal = () => {
    rejectionModalRef.current.openDialog();
  };

  const closeRejectionModal = () => {
    rejectionModalRef.current.closeDialog();
  };

  const canReject = !isArchived && (
    (isNetworkLead && invoice.invoice_status === 'submitted_to_network_lead') ||
    (!isNetworkLead && invoice.invoice_status === 'submitted_contracted_service_note')
  );

  const canDispute = !isArchived && DISPUTABLE_STATUSES.includes(invoice.invoice_status);

  const previouslyDisputedInvoice = !isEmpty(get(invoice, 'invoice_dispute_resolution_reason'));

  const disputeModalRef = useRef();

  const openDisputeModal = () => {
    disputeModalRef.current.openDialog();
  };

  const closeDisputeModal = () => {
    disputeModalRef.current.closeDialog();
  };

  const showArchive = useFeatureFlag('pays-1837-temp-invoices-archive');

  const fixAndMakeNewModalRef = useRef();

  const openFixAndMakeNewInvoiceModal = () => {
    fixAndMakeNewModalRef.current.openDialog();
  };

  const closeFixAndMakeNewInvoiceModal = () => {
    fixAndMakeNewModalRef.current.closeDialog();
  };

  const setProvidedServiceActive = usePSActive(invoice.provided_service?.id, groupId, invoice.case_id);

  const routeToCase = async (fixInvoiceDispute) => {
    const caseId = invoice.case_id;
    const contactId = invoice.client_id;
    const provided_service_id = invoice.provided_service.id;
    await setProvidedServiceActive();

    if (fixInvoiceDispute) {
      callOrLog(() => eventTracker(INVOICE.cboClickedFixInvoiceFromDispute, { current_network: networkId }));
    }

    browserHistory.push({
      pathname: `/dashboard/cases/open/${caseId}/contact/${contactId}`,
      state: { editProvidedServiceId: provided_service_id, isEditing: true },
    });
  };

  const disputeResolutionModalRef = useRef();

  const openDisputeResolutionModal = () => {
    disputeResolutionModalRef.current.openDialog();
  };

  const closeDisputeResolutionModal = () => {
    disputeResolutionModalRef.current.closeDialog();
  };

  const rejected = REJECTED_OPTIONS.includes(invoice.invoice_status);

  const canShowFixAndMakeNewInvoiceButton = isCBOProvider && isMostRecent;

  return (
    <div className="pt-6" data-testid="invoice-drawer">
      {/* Invoice # Row */}
      <div className="flex">
        <div className="col-sm-9">
          <div className="flex pl-4 pt-2 text-xl font-extrabold text-text-blue space-x-2 items-center">
            <div>
              {showInvoiceType &&
                `${formatInvoiceType(invoice.invoice_type)} `}Invoice
            </div>
            <Link
              to={`/invoices/${path}${invoice.id}`}
              className="item-center underline"
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              #{invoice.short_id}
            </Link>
          </div>
        </div>
        <div className="col-xs-3">
          {useInvoiceUserRole && !showPayerInvoices && (
          <SubmitInvoiceButton
            currentEmployee={currentEmployee}
            disableSubmitButton={disableSubmitButton}
            hasPayerWQInteractiveView={payerOnInvoiceHasWQInteractiveView}
            id="submit-btn"
            invoice={invoice}
            isArchived={isArchived}
            isNetworkLead={isNetworkLead}
            submitToPayer={submitToPayer}
            submitToNetworkLead={submitToNetworkLead}
            transmitToPayer={transmitToPayer}
          />
          )}
          {fullPayerWQView && payerAcceptedInvoice && (
          <PrimaryButton
            ariaLabel="pay invoice"
            onClick={payerCompleteInvoicePayment}
            label="Pay"
            icon="LogoShield"
            id="pay-btn"
            className="px-4"
          />
          )}
          {
            fullPayerWQView && !payerAcceptedInvoice && (
              <PrimaryButton
                ariaLabel="accept invoice"
                onClick={payerAcceptInvoice}
                label="Accept"
                icon="V2CheckCircle"
                id="accept-btn"
                disabled={!payerNeedsAttentionInvoice || disableSubmitButton}
              />
            )
          }
        </div>
      </div>

      {/* Invoice Navigation Row */}
      <div className="flex py-2 -ml-2">
        <div className="col-xs-1">
          <button
            disabled={getPrevInvoice === undefined}
            onClick={getPrevInvoice}
            type="button"
            aria-label="go to previous invoice"
          >
            <Icon
              className={cx(
                iconClass,
                getPrevInvoice ? 'hover:text-dark-grey' : 'opacity-50 cursor-not-allowed',
              )}
              icon="V2ChevronLeft"
            />
          </button>
        </div>
        <div className="flex col-xs-10">
          <div className="-ml-5 pr-4 text-sm">
            Last updated: {formatDate(invoice.updated_at)}
          </div>
          <div className="pl-2 flex flex-row items-center">
            <InvoiceStatusIndicator
              isNetworkLead={isNetworkLead}
              invoiceStatus={invoice.invoice_status}
              isPayer={showPayerInvoices}
            />
            <InvoiceDifferenceIndicator
              invoiceStatus={invoice.invoice_status}
              invoiceAmountPaid={invoice.amount_paid}
              invoiceAmountInvoiced={invoice.total_amount_invoiced}
              invoicedResolved={invoice.invoice_dispute_resolution_date !== null}
            />
          </div>
        </div>
        <div className="col-xs-1 ml-2">
          <button
            disabled={getNextInvoice === undefined}
            onClick={getNextInvoice}
            type="button"
            aria-label="go to next invoice"
          >
            <Icon
              className={cx(
                iconClass,
                getNextInvoice ? 'hover:text-dark-grey' : 'opacity-50 cursor-not-allowed',
              )}
              icon="V2ChevronRight"
            />
          </button>
        </div>
      </div>
      {/* Invoice Buttons Row */}
      {
        showPayerInvoices && payerClosedInvoice && (
          <div className="flex flex-row px-8 py-2 space-x-3 mb-4">
            {!!resolved && fullPayerWQView && (
              <div>
                <PrimaryButton
                  className="items-center"
                  disabled={!canFixInvoice}
                  onClick={openFixResponseModal}
                  label="Fix response"
                  icon="IconFix"
                  id="fixresponse-btn"
                />
                <FixInvoiceResponseForm
                  invoice={invoice}
                  isNetworkLead={false}
                  isPayerProvider
                  close={closeFixResponseModal}
                  setRef={fixResponseModalRef}
                  onSuccess={onClose}
                />
              </div>
            )}
            {isArchived ? (
              <SecondaryButton
                ariaLabel="unarchive invoice"
                className="items-center"
                onClick={handleUnarchive}
                label="Unarchive"
                icon="IconUndo"
                id="unarchive-btn"
              />
            ) : (
              <SecondaryButton
                ariaLabel="archive invoice"
                className="items-center"
                disabled={!canArchiveInvoice}
                onClick={handleArchive}
                label="Archive"
                icon="IconArchive"
                id="archive-btn"
              />
            )}
          </div>
        )
      }
      {
        /* Payer Accepted Invoice Buttons */
        fullPayerWQView && !payerClosedInvoice && (
          <div className="flex flex-row px-8 py-2 space-x-3 mb-4">
            <SecondaryButton
              ariaLabel="reject invoice"
              onClick={openRejectionModal}
              label="Reject"
              icon="IconCrossCircle"
              id="reject-btn"
            />
            <InvoiceRejectionForm
              invoice={invoice}
              isNetworkLead={isNetworkLead}
              close={closeRejectionModal}
              setRef={rejectionModalRef}
              onSuccess={() => {
                onClose();
                deselectAll();
              }}
              isPayerProvider={showPayerInvoices}
            />
          </div>
        )
      }
      {
        !showPayerInvoices && (
          <div className="flex px-8 py-2 space-x-4">
            <div>
              <SecondaryButton
                ariaLabel="reject invoice"
                disabled={!canReject}
                onClick={openRejectionModal}
                label="Reject"
                icon="IconCrossCircle"
                id="reject-btn"
              />
              <InvoiceRejectionForm
                invoice={invoice}
                isNetworkLead={isNetworkLead}
                close={closeRejectionModal}
                setRef={rejectionModalRef}
                onSuccess={onClose}
              />
            </div>

            {isNetworkLead && (
              <div>
                <SecondaryButton
                  ariaLabel="dispute invoice"
                  disabled={!canDispute || previouslyDisputedInvoice || currentlyInDispute}
                  onClick={openDisputeModal}
                  label="Dispute"
                  icon="V2Warning"
                  id="dispute-btn"
                />
                <InvoiceDisputeForm
                  invoice={invoice}
                  close={closeDisputeModal}
                  setRef={disputeModalRef}
                  onSuccess={onClose}
                />
              </div>
            )}
            {showArchive && (
            <div>
              {isArchived ? (
                <SecondaryButton
                  ariaLabel="unarchive invoice"
                  className="items-center"
                  onClick={handleUnarchive}
                  label="Unarchive"
                  icon="IconUndo"
                  id="unarchive-btn"
                />
            ) : (
              <SecondaryButton
                ariaLabel="archive invoice"
                className="items-center"
                disabled={!canArchiveInvoice}
                onClick={handleArchive}
                label="Archive"
                icon="IconArchive"
                id="archive-btn"
              />
            )}
            </div>
        )}
          </div>
        )
      }
      <div className="mt-4 bg-gray-100 flex-1 px-3 py-2 border border-solid border-dark-border-blue">
        {/* Dispute Details */}
        {
          disputed && (
            <Card borderColor="border-purple" className="mt-2 px-4 py-2 bg-white">
              <CardHeader iconColor="purple" titleIcon="V2Warning" title="Dispute Details" />
              <div className="py-4 space-y-2">
                <CardDetail label="Date Disputed">{!isEmpty(versions) && dateDisputed(versions)}</CardDetail>
                <CardDetail
                  label="Reason for Dispute"
                >
                  {get(invoice, 'invoice_dispute_reason.display_name')}
                </CardDetail>
                {
                  !isEmpty(invoice.dispute_reason_note) && (
                    <CardDetail label="Note">{invoice.dispute_reason_note}</CardDetail>
                  )
                }

                {
                  resolved && (
                    <>
                      <hr className="pt-2 -mx-8 border-dark-border-blue" />
                      <div className="py-4 space-y-2">
                        <CardDetail label="Date Resolved">
                          {!isEmpty(versions) && dateDisputeResolved(versions)}
                        </CardDetail>
                        <CardDetail label="Resolution">
                          {get(invoice, 'invoice_dispute_resolution_reason.display_name')}
                        </CardDetail>
                        {!isEmpty(invoice.invoice_dispute_resolution_note) && (
                          <CardDetail label="Note">{invoice.invoice_dispute_resolution_note}</CardDetail>
                        )}
                      </div>
                    </>
                  )
                }
              </div>

              {
                showFixAndMakeNewInvoice ? (
                  <>
                    <div className="flex justify-center">
                      <Button
                        className="rounded my-2 py-3 px-6"
                        disabled={!isMostRecent}
                        label="Fix And Make New Invoice"
                        onClick={openFixAndMakeNewInvoiceModal}
                        primary
                      />
                    </div>
                    <Dialog
                      modal
                      title="Fix and make new invoice"
                      ref={fixAndMakeNewModalRef}
                      titleStyles={{ color: theme.extend.colors['text-blue'] }}
                      id="dispute-resolution-invoice-modal"
                      onRequestClose={closeFixAndMakeNewInvoiceModal}
                    >
                      <>
                        <div className="text-sm pb-4 space-y-6">
                          <p>
                            This will create a new version of the invoice and
                            move the old version to the Closed Invoices folder.
                          </p>
                          <p>
                            Are you sure you want to create a new version of this invoice?
                          </p>
                        </div>
                        <div className="flex justify-end mt-6 space-x-4">
                          <Button
                            className="capitalize"
                            onClick={closeFixAndMakeNewInvoiceModal}
                            label="cancel"
                          />
                          <Button
                            className="text-white capitalize"
                            onClick={() => routeToCase(true)}
                            label="Yes"
                            primary
                          />
                        </div>
                      </>
                    </Dialog>
                  </>
                ) : null
              }

              {
                isNetworkLead && (
                  <>
                    <hr className="pt-2 -mx-8 border-dark-border-blue" />
                    <div className="mt-4 mb-2">
                      <Button
                        className="ml-4 bg-text-blue text-white capitalize"
                        onClick={openDisputeResolutionModal}
                        label={resolved ? 'Resolved' : 'Resolve Invoice'}
                        disabled={resolved}
                      />
                      <DisputeResolutionForm
                        invoice={invoice}
                        close={closeDisputeResolutionModal}
                        setRef={disputeResolutionModalRef}
                        onSuccess={onClose}
                      />
                    </div>
                  </>
                )
              }
            </Card>
          )
        }

        {/* Rejection Details */}
        {
          rejected && (
            <Card className="mt-2 px-2 py-2 bg-white">
              <CardHeader titleIcon="V2Warning" title="Rejection Details" />
              <div className="py-4 space-y-2">
                <CardDetail label="Rejection Reason">
                  { get(invoice, 'invoice_rejection_reason.display_name') }
                </CardDetail>
                <CardDetail label="Note">
                  { invoice.rejection_note }
                </CardDetail>
              </div>
              {
                canShowFixAndMakeNewInvoiceButton && (
                  <div className="flex justify-center">
                    <Button
                      className="rounded mt-4 mb-2 py-3 px-6"
                      label="Fix And Make New Invoice"
                      onClick={() => routeToCase(false)}
                      primary
                    />
                  </div>
                )
              }
            </Card>
          )
        }

        {/* Invoice Details */}
        <Card className="mt-2 px-2 py-2 bg-white">
          <CardHeader title="Invoice Details" />
          <div className="py-4 space-y-2">
            <CardDetail label="Invoice Total">{totalAmountInvoiced}</CardDetail>
            {
              totalAmountPaid && (
                <CardDetail label="Paid Amount">{totalAmountPaid}</CardDetail>
              )
            }
            <CardDetail label="Invoice ID#">{invoice.short_id}</CardDetail>
            {!isEmpty(invoice.client_address_postal_code) && (
            <CardDetail
              label="Zip Code (County)"
            >{`${invoice.client_address_postal_code } (${ invoice.client_address_county || '' })`}
            </CardDetail>
            )}
            {invoice?.payment_date && (
            <CardDetail label="Payment Date">
              {formatDate(invoice.payment_date)}
            </CardDetail>
            )}
            {invoice?.response_date && (
            <CardDetail label="Response Date">
              {formatDate(invoice.response_date, false)}
            </CardDetail>
              )}
            <CardDetail label="Date Created">
              {formatDate(invoice.created_at, false)}
            </CardDetail>
            { isNetworkLead && (
            <CardDetail label="Provider">
              { invoice.provider_name }
            </CardDetail>
            )}
            <CardDetail label="Submitted By">
              { get(invoice, 'submitter.full_name') }
            </CardDetail>
          </div>
        </Card>

        {/* Client Details */}
        <Card className="mt-3 px-2 py-2 bg-white">
          <CardHeader title="Client Details" />
          <div className="py-4 space-y-2">
            {
              isCBOProvider && pays3693AddLinkToClientFacesheetInIwq ? (
                <CardDetail label="Client Name">
                  <Link
                    className="text-action-blue"
                    to={`/facesheet/${invoice.client_id}`}
                  >
                    {startCase(toLower(invoice.client_name))}
                  </Link>
                </CardDetail>
              ) : (
                <CardDetail label="Client Name">{startCase(toLower(invoice.client_name))}</CardDetail>
              )
            }
            <CardDetail label="Client ID#">{invoice.insurance_external_member_id}</CardDetail>
            <CardDetail label="Health Plan">{startCase(invoice.plan_name)}</CardDetail>
            <CardDetail label="Social Care Coverage Plan" dataTestId="social_care_coverage_insurance">
              {startCase(invoice.social_plan_name)}
              {isMostRecent && insurance && insuranceLoaded && (
              <>
                <br />
                {formatShortDate(insurance.enrolled_at)}
                {insurance.expired_at ?
                    ` - ${formatShortDate(insurance.expired_at)}` :
                    ' - present'}
              </>
              )}
            </CardDetail>
            {isMostRecent && insuranceLoaded && (
            <CardDetail label="Social Care Coverage Status">
              <InsuranceStatusIndicator
                status={insurance?.insurance_status}
                ignoreExpiration={feeSchedule.ignore_social_care_expired_dates}
                showTooltip={false}
                showInsuranceServiceDateWarning={
                    !feeSchedule.ignore_social_care_expired_dates &&
                    !!(insurance?.expired_at && invoice.service_authorization_approved_ends_at > insurance.expired_at)
                  }
              />
              {insurance?.insurance_status === INSURANCE_STATUSES.not_enrolled && (
              <div className="mt-2"> The client’s
                social care coverage ended prior to or during the selected service delivery date(s).
                Please verify the service date(s) before submitting.`
              </div>
              )}
            </CardDetail>
            )}
          </div>
        </Card>

        {/* Screening Details */}
        { pays5214BillingForScreenings && invoice.invoice_type === 'screening' && (
          <Card className="mt-3 px-2 py-2 bg-white">
            <CardHeader title="Screening Details" />
            <div className="py-4 space-y-2">
              <CardDetail label="Screening Name">
                {startCase(toLower(invoice.fee_schedule_screening_name))}
              </CardDetail>
              <CardDetail label="Date of Screening">
                {formatShortDate(invoice.procedure_date)}
              </CardDetail>
              <CardDetail label="Screened By">
                {invoice.provider_employee_name}
              </CardDetail>
            </div>
          </Card>
        )}

        {/* Service Authorization Details */}
        { invoice.service_authorization_short_id && (
        <Card className="mt-3 px-2 py-2 bg-white">
          <CardHeader title="Authorization Details" />
          <div className="py-4 space-y-2">
            <AuthorizationDetails
              invoice={invoice}
              onDisableSubmitButtonChange={handleDisableSubmitButton}
            />
          </div>
        </Card>
        )}

        {/* Service Details */}
        { invoice.invoice_type === 'provided_service' && (
          <Card className="mt-3 px-2 py-2 bg-white">
            <CardHeader title="Service Details" />
            <div className="py-4 space-y-2">
              <ServiceDetails
                invoice={invoice}
                placeOfService={placeOfService}
                procedureCodes={procedureCodes}
                procedureCodeModifiers={procedureCodeModifiers}
                serviceDescription={serviceDescription}
                showClaimsCodes={showClaimsCodes}
                zCodes={zCodes}
              />
            </div>
          </Card>
        )}

        {/* Provider Information */}
        <Card className="mt-3 px-2 py-2 bg-white">
          <CardHeader title="Provider Information" />
          <div className="py-4 space-y-2">
            <CardDetail label="Name">{invoice.provider_name}</CardDetail>
            <CardDetail label="Address">
              {!isEmpty(providerAddress) && (
              <>
                <div>{providerAddress.line_1}</div>
                <div>{providerAddress.line_2}</div>
                <div>{providerAddress.city}, {providerAddress.state} {providerAddress.postal_code}</div>
              </>
              )}
            </CardDetail>
          </div>
        </Card>

        {/* Supporting Documents */}
        <Card className="mt-3 px-2 py-2 bg-white">
          <CardHeader title="Supporting Documents" />
          { isFetching || isLoading ? <Spinner /> : (
            <div className="py-4 space-y-2">
              {files.map((fileObject) => (
                <a
                  href={`${env.CORE_BASE_URL}${fileObject.path}`} rel="noreferrer" target="_blank"
                  className="flex text-action-blue pt-2 pl-2"
                  key={fileObject.id}
                >
                  <div className="pr-1">
                    <FileThumbnail size={20} color="#4467AB" contentType={fileObject.content_type} />
                  </div>
                  <div>{fileObject.filename}</div>
                </a>
              ))}
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

InvoiceDrawer.propTypes = {
  disableSubmitButton: PropTypes.bool.isRequired,
  files: PropTypes.array,
  invoice: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  isLoading: PropTypes.bool,
  isNetworkLead: PropTypes.bool,
  isCBOProvider: PropTypes.bool.isRequired,
  procedureCodes: PropTypes.array,
  procedureCodeModifiers: PropTypes.array,
  providerAddress: PropTypes.object,
  placeOfService: PropTypes.array,
  serviceDescription: PropTypes.string,
  showClaimsCodes: PropTypes.bool,
  showFixAndMakeNewInvoice: PropTypes.bool,
  showInvoiceType: PropTypes.bool.isRequired,
  showPayerInvoices: PropTypes.bool,
  totalAmountInvoiced: PropTypes.string,
  totalAmountPaid: PropTypes.string,
  useInvoiceUserRole: PropTypes.bool.isRequired,
  versions: PropTypes.array.isRequired,
  zCodes: PropTypes.array,
  deselectAll: PropTypes.func.isRequired,
  getNextInvoice: PropTypes.func.isRequired,
  getPrevInvoice: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  networkId: PropTypes.string.isRequired,
  payerWQInteractiveView: PropTypes.object.isRequired,
  currentEmployee: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  handleDisableSubmitButton: PropTypes.func.isRequired,
  insurance: PropTypes.object.isRequired,
  feeSchedule: PropTypes.object.isRequired,
  isMostRecent: PropTypes.bool.isRequired,
  insuranceLoaded: PropTypes.bool.isRequired,
};

InvoiceDrawer.defaultProps = {
  files: [],
  isFetching: false,
  isLoading: false,
  placeOfService: [],
  procedureCodes: [],
  procedureCodeModifiers: [],
  providerAddress: {},
  serviceDescription: '',
  showClaimsCodes: false,
  showFixAndMakeNewInvoice: false,
  showPayerInvoices: false,
  totalAmountInvoiced: '',
  totalAmountPaid: '',
  zCodes: [],
  isNetworkLead: false,
};

export default InvoiceDrawer;
