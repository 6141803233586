import _ from 'lodash';
import { dates } from '@unite-us/app-components';
import { getItemResult } from '.';

function formatCaseData({ result, columns }) {
  const serviceCase = getItemResult(result);
  const full_name = _.get(serviceCase, 'person.full_name', '');

  const serviceCaseData = {
    full_name,
    name_service_type: `${full_name} - ${_.get(serviceCase, 'service.name', '')}`,
    program_name: _.get(serviceCase, 'program.name', ''),
    created_at: dates.formatDate(serviceCase.created_at, 'MM/DD/YYYY'),
    service_type: _.get(serviceCase, 'service.name', ''),
    status: serviceCase.closed_at ? 'Closed' : 'Open',
    primary_worker: _.get(serviceCase, 'primary_worker.full_name', ''),
    updated_at: dates.formatDate(serviceCase.updated_at, 'MM/DD/YYYY'),
  };

  return _.reduce(columns, (acc, value) => {
    if (_.has(serviceCaseData, value)) {
      return [
        ...acc,
        {
          label: value,
          value: serviceCaseData[value],
        },
      ];
    }
    return [...acc];
  }, []);
}

export default formatCaseData;
