import PropTypes from 'prop-types';
import React, { Component } from 'react';
import callOrLog from 'src/common/utils/callOrLog';
import { MY_NETWORKS } from 'common/utils/EventTracker/utils/eventConstants';
import { Filter } from '@unite-us/client-utils';
import { InputField, Pager } from '@unite-us/ui';
import { getPermittedNetworkOptions } from 'src/components/Network/utils';
import SpinIcon from 'common/spinners/SpinIcon';

import './NetworkSearchBar.scss';

function addRequiredProps(inputField) {
  return {
    onFocus: () => (true),
    ...inputField,
  };
}

class NetworkSearchBar extends Component {
  constructor(props) {
    super(props);

    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.onNetworkFilterChange = this.onNetworkFilterChange.bind(this);
  }

  onNetworkFilterChange(selected) {
    callOrLog(() => this.context.eventTracker(MY_NETWORKS.selectServiceType));
    this.props.onFilterChange(selected);
  }

  onNextClick() {
    const { paging } = this.props;

    this.props.fetchUsers(paging.next_page);
  }

  onPrevClick() {
    const { paging } = this.props;

    this.props.fetchUsers(paging.prev_page);
  }

  render() {
    const {
      filterText,
      isFetching,
      networkId,
      paging,
      referralScopes,
      styles,
    } = this.props;

    const networkOptions = getPermittedNetworkOptions(referralScopes, networkId);
    const showNetworkFilter = networkOptions.length > 1;

    return (
      <div className="network-search-bar">
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <InputField
              clearable
              field={addRequiredProps(filterText)}
              hideLabel
              id="search-text"
              label="search"
              placeholder="Search by Email or Name..."
            />
          </div>
          <div className="col-sm-12 col-md-4">
            {
              showNetworkFilter && (
                <Filter
                  id="networks"
                  mustHaveOneSelected
                  name="Network"
                  onFiltersChange={this.onNetworkFilterChange}
                  options={networkOptions}
                  style={{ display: 'inline-block' }}
                />
              )
            }
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="network-search-bar__pager-container">
              <div className="network-search-bar__pager-container-spinner">
                {
                  isFetching && (
                    <SpinIcon
                      iconStyle={styles.spinIconIconStyles}
                    />
                  )
                }
              </div>
              <div className="network-search-bar__pager-container-pager">
                <Pager
                  disabled={isFetching}
                  onNextClick={this.onNextClick}
                  onPrevClick={this.onPrevClick}
                  paging={paging}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NetworkSearchBar.propTypes = {
  fetchUsers: PropTypes.func.isRequired,
  filterText: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  networkId: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  paging: PropTypes.object.isRequired,
  referralScopes: PropTypes.array.isRequired,
  styles: PropTypes.object,
};

NetworkSearchBar.defaultProps = {
  styles: {
    spinIconIconStyles: {
      height: '20px',
      width: '20px',
    },
  },
};

NetworkSearchBar.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default NetworkSearchBar;
