import React, { Component } from 'react';
import { Button } from '@unite-us/ui';
import PropTypes from 'prop-types';
import { validateReduxForm } from 'common/form';
import { isEmpty, get } from 'lodash';
import { PostNoteButton } from 'common/form/Interactions/components';
import { isHttpError } from 'common/utils/Error';
import { hasConsent } from 'src/components/Contacts/utils';
import { MESSAGE_CHAR_LIMIT } from './constants';
import {
  MessageEnabledCommunicationContainer,
  MessageField,
} from './components';
import {
  someMessageEnabledPhoneOrEmail,
} from './utils';

export class Message extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    const { values } = this.props;
    return this.props.submitFunction(values)
      .then((response) => {
        if (!isHttpError(response)) {
          this.props.resetForm();
        }
        this.props.cancelFunction();
      });
  }

  render() {
    const {
      contact,
      fields,
      handleSubmit,
      registerField,
      submitting,
      cancelFunction,
    } = this.props;

    return (
      <div className="message mt-3">
        {
          !isEmpty(contact) && (
            <MessageEnabledCommunicationContainer
              fields={fields}
              contact={contact}
            />
          )
        }
        <form
          onSubmit={handleSubmit(this.onSubmit)}
          data-role="log-interaction-form"
        >
          <MessageField
            field={fields.message}
            id="message-field"
            registerField={registerField}
          />

          <div className="flex justify-between">
            <Button
              disabled={submitting}
              id="close-post-message-btn"
              onClick={cancelFunction}
              label="Close"
              secondary
            />
            <div className="inline-block text-right">
              <PostNoteButton
                disabled={
                  submitting ||
                  !hasConsent(contact) ||
                  isEmpty(get(fields, 'message.value')) ||
                  (get(fields, 'message.value', '').length > MESSAGE_CHAR_LIMIT) ||
                  !someMessageEnabledPhoneOrEmail(contact)
                }
                id="new-note-post-note-btn"
                label="Send"
                onClick={this.onSubmit}
                primary
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

Message.propTypes = {
  contact: PropTypes.object,
  values: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  submitFunction: PropTypes.func.isRequired,
  cancelFunction: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

Message.defaultProps = {
  contact: {},
};

const fields = [
  'message',
  'messageTo',
];
export default validateReduxForm({
  form: 'messageForm',
  fields,
})(Message);
