import React from 'react';
import { isAutoRecallable, isInternetwork } from 'src/components/Referrals/utils';
import {
  Icon,
} from '@unite-us/ui';
import {
  AUTO_RECALLABLE_REFERRAL_TOOLTIP_TEXT,
  INTERNETWORK_REFERRAL_TOOLTIP_TEXT,
} from 'src/components/Referrals/constants';

function getReferralIconAndTooltip({ referral, style }) {
  if (isAutoRecallable(referral) || (referral.is_grouped)) {
    return {
      icon: <Icon icon="IconGroup" style={style} />,
      iconTooltip: AUTO_RECALLABLE_REFERRAL_TOOLTIP_TEXT,
    };
  }

  if (isInternetwork(referral)) {
    return {
      icon: <Icon icon="IconElectrons" style={style} />,
      iconTooltip: INTERNETWORK_REFERRAL_TOOLTIP_TEXT,
    };
  }

  return {
    icon: null,
    iconTooltip: null,
  };
}

export default getReferralIconAndTooltip;
