import { formatNumber } from 'humanize-plus';
var RADIUS_OF_EARTH_IN_MILES = 3956;
function toRadians(x) {
  return x / 180 * Math.PI;
}
function getDistanceInMiles() {
  var originLatLng = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var destinationLatLng = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  if (!originLatLng || !destinationLatLng) return '';
  if (originLatLng.length && destinationLatLng.length) {
    var origin = {
      lat: originLatLng[0],
      lng: originLatLng[1]
    };
    var destination = {
      lat: destinationLatLng[0],
      lng: destinationLatLng[1]
    };
    var lat1 = toRadians(origin.lat);
    var lat2 = toRadians(destination.lat);
    var lng1 = toRadians(origin.lng);
    var lng2 = toRadians(destination.lng);
    var latDifference = lat2 - lat1;
    var lngDifference = lng2 - lng1;
    var chord = Math.pow(Math.sin(latDifference / 2), 2) + Math.pow(Math.sin(lngDifference / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
    var angularDistance = 2 * Math.atan2(Math.sqrt(chord), Math.sqrt(1 - chord));
    var convertedMiles = RADIUS_OF_EARTH_IN_MILES * angularDistance;
    if (options.format === 'float') {
      return convertedMiles;
    }
    var miles = formatNumber(convertedMiles, 2);
    var mileText = "".concat(miles, " mi");
    return miles === '0.00' ? '0 mi' : mileText;
  }
  return '';
}
export default getDistanceInMiles;