import { useFind } from 'src/api/APIHooks';
import { comparePossiblyMissingDatesDescending } from '../utils/comparePossiblyMissingDatesDescending';

const getEligibility = (data) => {
  const eligibilities = data?.data?.data ?? [];
  const activeEligibilities = eligibilities.filter((elig) => elig.state === 'active');
  return activeEligibilities.sort(
    (a, b) => comparePossiblyMissingDatesDescending(a.verified_at, b.verified_at),
  )[0];
};

export const useFindEligibilityForInsurance = (insurance, enabled) => {
  const { data } = useFind(
    'eligibilities',
    { id: insurance?.eligibilities?.map((el) => (el.id)).join() },
    {
      queryConfig: {
        placeholderData: undefined,
        enabled: enabled && insurance?.eligibilities?.length > 0,
      },
    },
  );

  const eligibility = getEligibility(data);
  return eligibility;
};
