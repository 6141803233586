import { join, compact } from 'lodash';
import { getPreferredProp } from '@unite-us/ui';
function stringifyAddress() {
  var address = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var getValue = function getValue(fieldName) {
    var field = address[fieldName] || {};
    return getPreferredProp(field, 'value') || '';
  };
  var parts = [getValue('line_1'), getValue('city'), "".concat(getValue('state'), " ").concat(getValue('postal_code')).trim()];
  return join(compact(parts), ', ');
}
export default stringifyAddress;