import { REFERRAL_STATUS_DRAFT } from 'src/components/Referrals/constants';
import { wget } from 'lodash';

export default function isReferralSent(referral, currentGroupId = '') {
  if (!currentGroupId) {
    return false;
  }

  if (wget(referral, 'status') === REFERRAL_STATUS_DRAFT) {
    return false;
  }

  return wget(referral, 'referred_by_group.id') === currentGroupId;
}
