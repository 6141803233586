import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { RadioField } from '@unite-us/ui';
import formatOptions from 'common/utils/formatOptions';
import '../stylesheets/messageEnabledCommunicationText.scss';

const getMessageToOptions = (enabledEmails, enabledPhones) => {
  const options = [];
  enabledEmails.forEach((e) => {
    const { email_address, acceptable_communication_types } = e;
    if (acceptable_communication_types.includes('message')) {
      options.push({
        display_name: `Email: ${email_address}`,
        value: email_address,
      });
    }
  });

  enabledPhones.forEach((p) => {
    const { phone_number, acceptable_communication_types } = p;
    if (acceptable_communication_types.includes('message')) {
      options.push({
        display_name: `Text message: ${phone_number}`,
        value: phone_number,
      });
    }
  });

  return formatOptions(options);
};

const MessageEnabledCommunicationText = ({ fields, enabledEmails, enabledPhones }) => {
  const formattedOptions = getMessageToOptions(enabledEmails, enabledPhones);

  useEffect(() => {
    if (!formattedOptions.length || fields.messageTo.value !== '') { return; }
    fields.messageTo.onChange(formattedOptions[0]);
  }, [formattedOptions]);

  return (
    <p className="message-enabled-communication-text">
      <RadioField
        id="message-enabled-communication-text"
        field={fields.messageTo}
        label="Message Type"
        options={formattedOptions}
      />
    </p>
  );
};

MessageEnabledCommunicationText.propTypes = {
  enabledEmails: PropTypes.array,
  enabledPhones: PropTypes.array,
  fields: PropTypes.object.isRequired,
};

MessageEnabledCommunicationText.defaultProps = {
  enabledEmails: [],
  enabledPhones: [],
};

export default MessageEnabledCommunicationText;
