import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import './stylesheets/detail-view.scss';

function DetailDefinitionList(props) {
  const {
    icon, styles, title, value,
  } = props;

  const iconValueClass = () => classNames({
    'detail-definition-list__icon-value': true,
    'detail-definition-list__icon-value--none': _.isEmpty(styles.icon),
  });

  return (
    <div className="detail-definition-list">
      {icon && (
        <div className="detail-definition-list__icon" style={styles.icon}>
          {icon}
        </div>
      )}
      <div className={iconValueClass()}>
        <h5 className="detail-definition-list__title">
          {title}
        </h5>
        {
          typeof (value) === 'string' ?
            <p className="detail-definition-list__value">{value}</p> :
            <div className="detail-definition-list__value">{value}</div>
        }
      </div>
    </div>
  );
}

DetailDefinitionList.propTypes = {
  icon: PropTypes.node,
  styles: PropTypes.shape({
    icon: PropTypes.object.isRequired,
  }),
  title: PropTypes.string.isRequired,
  value: PropTypes.any,
};

DetailDefinitionList.defaultProps = {
  styles: {
    icon: { paddingLeft: '40px' },
  },
};

export default DetailDefinitionList;
