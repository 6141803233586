import { Icon } from '@unite-us/ui';
import React from 'react';

export const NoResponseReceived = () => (
  <div className="flex items-center my-2 pl-4">
    <Icon
      icon="IconExclamationCircle"
      className="fill-current text-red mr-1"
    />
    <p>No response received</p>
  </div>
);
