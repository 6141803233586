import { theme } from 'src/../tailwind.config';

function stateBgColor(state) {
  switch (state.toLowerCase()) {
    case 'active':
      return theme.extend.colors.green;
    case 'published':
      return theme.extend.colors.green;
    case 'inactive':
      return theme.extend.colors.red;
    case 'archived':
      return theme.extend.colors.yellow;
    case 'unpublished':
      return theme.extend.colors.yellow;
    case 'draft changes':
      return theme.extend.colors['medium-fill-blue'];
    default:
      return '';
  }
}
export default stateBgColor;
