import PropTypes from 'prop-types';
import React from 'react';
import { get, isEmpty } from 'lodash';

const County = ({ address, inline }) => {
  const county = get(address, 'county', '');
  const hasCounty = !isEmpty(county);

  if (!hasCounty) {
    return null;
  }

  // @todo this isn't great that I'm using a div here and a p tag for the
  // not-inline county.  Didn't want to break existing instances of this component
  if (inline) {
    return (
      <div className="county">{county}</div>
    );
  }

  return (
    <p className="county">
      <span className="county__subhead">county</span>
      <span>{county}</span>
    </p>
  );
};

County.propTypes = {
  address: PropTypes.shape({
    county: PropTypes.string,
  }).isRequired,
  inline: PropTypes.bool,
};

County.defaultProps = {
  inline: false,
};

export default County;
