import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { PrimaryButton } from 'common/TailwindComponents';
import useNetworkLeadRelationship from '../hooks/useNetworkLeadRelationship';

const SubmitInvoiceButton = ({
  currentEmployee,
  disableSubmitButton,
  hasPayerWQInteractiveView,
  invoice,
  isArchived,
  isNetworkLead,
  submitToPayer,
  submitToNetworkLead,
  transmitToPayer,
}) => {
  const [onClickFunction, setOnClickFunction] = useState(() => () => {});

  const networkLeadRelationships = useNetworkLeadRelationship(invoice);

  const isCurrentEmployeeProviderAtCBO = currentEmployee?.provider?.provider_type === 'provider';

  const isInvoiceProvidedByCurrentEmployeesProvider = currentEmployee?.provider?.id === invoice?.provider_id;

  const isCBOSubmitingInvoice = isCurrentEmployeeProviderAtCBO &&
    isInvoiceProvidedByCurrentEmployeesProvider &&
    invoice?.invoice_status === 'submitted_contracted_service_note';

  const isCurrentEmployeeNetworkLeadForInvoice = isNetworkLead && networkLeadRelationships?.some(
    (nlr) => nlr.network_lead?.id === currentEmployee?.provider?.id,
  );

  const isNetworkLeadSubmitingInvoice = isCurrentEmployeeNetworkLeadForInvoice &&
    invoice?.invoice_status === 'submitted_to_network_lead';

  const canCurrentEmployeeSubmit = isCBOSubmitingInvoice || isNetworkLeadSubmitingInvoice;

  const disableButton = !currentEmployee || !invoice || networkLeadRelationships === undefined ||
    isArchived || disableSubmitButton || !canCurrentEmployeeSubmit;

  const invoiceHasNetworkLead = networkLeadRelationships?.length > 0;

  useEffect(() => {
    if (invoiceHasNetworkLead && isCBOSubmitingInvoice) {
      setOnClickFunction(() => submitToNetworkLead);
    } else if (isCBOSubmitingInvoice || isNetworkLeadSubmitingInvoice) {
      setOnClickFunction(() => (hasPayerWQInteractiveView ? transmitToPayer : submitToPayer));
    }
  }, [
    invoiceHasNetworkLead,
    hasPayerWQInteractiveView,
    isCBOSubmitingInvoice,
    isNetworkLeadSubmitingInvoice,
    submitToNetworkLead,
    submitToPayer,
    transmitToPayer,
  ]);

  return (
    <PrimaryButton
      disabled={disableButton}
      label="Submit"
      onClick={onClickFunction}
      icon="IconSubmit"
    />
  );
};

SubmitInvoiceButton.propTypes = {
  currentEmployee: PropTypes.object.isRequired,
  disableSubmitButton: PropTypes.bool.isRequired,
  hasPayerWQInteractiveView: PropTypes.bool.isRequired,
  invoice: PropTypes.object.isRequired,
  isArchived: PropTypes.bool.isRequired,
  isNetworkLead: PropTypes.bool.isRequired,
  submitToPayer: PropTypes.func.isRequired,
  submitToNetworkLead: PropTypes.func.isRequired,
  transmitToPayer: PropTypes.func.isRequired,
};

export default SubmitInvoiceButton;
