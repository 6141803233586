export const DOMAIN_CONSTANTS = {
  CASE: 'case',
  CONSENT_REQUEST_TYPES: {
    DOCUMENT: 'document',
    EMAIL_ADDRESS: 'email_address',
    PHONE_NUMBER: 'phone_number',
  },
  CONSENT_STATUSES: {
    ACCEPTED: 'accepted',
  },
  CONTACT: 'contact',
  GROUPS: 'groups',
  INTAKE: 'intake',
  NETWORKS: 'networks',
  REFERRAL: 'referral',
  ROLES: {
    PROGRAM: 'program',
    PROGRAM_ROLES: {
      CASE_MANAGER: 'Case Manager',
      SUPERVISOR: 'Supervisor',
      GENERAL_STAFF: 'General Staff',
    },
    SYSTEM: 'system',
  },
  SCREENING: 'screening',
};

export const COOKIE_SESSION_KEY = 'uniteusApiToken';
export const EXPORTS_CREATE_LOCAL_STORAGE = 'exportsCreateLocalStorage';
export const HOME_ROUTE = '/';
export const SESSION_USER_KEY = 'uniteusApiCurrentUser';
export const SHOW_LIMIT_AMOUNT = 5;

// colors
export const BODY_GREY_COLOR = '#506784';
export const SERVICE_ICON_GREY = '#A5A9B1';

// hidden roles
export const HIDDEN_ROLES = [
  { role_key: 'insights' },
  { role_key: 'network_access_user' },
  { role_key: 'invoice_admin_user' },
  { role_key: 'payments_user' },
];

export const PAYMENTS_ROLE = { role_key: 'payments_user' };
