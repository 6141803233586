import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import DetailAssessments from 'common/display/SingleItemDetails/DetailAssessments';

function ServiceCaseAssessmentsWrapper(props) {
  const serviceCaseId = _.wget(props, 'serviceCase.id', null);
  const assessmentsWithUrl = props.assessments.map((assessment) => (
    { ...assessment, url: `/cases/${serviceCaseId}/assessments/${_.wget(assessment, 'form.id', null)}` }
  ));

  return (
    <DetailAssessments
      detailObj={props.serviceCase}
      assessments={assessmentsWithUrl}
      contact={props.contact}
      singleItemId={props.singleItemId}
      militaryUrl={`/military-information/${props.contact.id}`}
    />
  );
}

ServiceCaseAssessmentsWrapper.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  assessments: PropTypes.arrayOf(
    PropTypes.shape({
      form: PropTypes.shape({
        id: PropTypes.string,
      }),
      progress: PropTypes.object,
    }),
  ).isRequired,
  singleItemId: PropTypes.string.isRequired,
  serviceCase: PropTypes.object.isRequired,
};

export default ServiceCaseAssessmentsWrapper;
