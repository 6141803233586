import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader } from 'common/Card';
import { usePopulate } from '../../../api/APIHooks';

const ServiceAuthorizationRejectionCard = ({ authorization }) => {
  usePopulate('service_authorization_denial_reason', 'service_authorization_denial_reason', authorization);

  const {
    service_authorization_denial_reason: { display_name: reason },
    reviewer_note,
  } = authorization;

  return (
    <Card borderColor="border-red" className="p-2 bg-white">
      <CardHeader
        className="pt-4 space-y-4"
        title="Rejection Details"
        titleIcon="V2Warning"
        iconColor="#CF2A2A"
      />
      <dl className="m-0 px-4 py-6 grid grid-cols-4 gap-y-2 gap-x-8">
        <dt id="denial_reason" className="text-text-blue text-sm font-bold leading-5 break-word">
          Rejection Reason:
        </dt>
        <dd aria-describedby="denial_reason" className="col-span-3 ml-2">
          {reason}
        </dd>
        <dt
          id="reviewer_note"
          className="text-text-blue text-sm font-bold leading-5 break-word"
        >
          Note:
        </dt>
        <dd
          aria-describedby="reviewer_note"
          className="col-span-3 ml-2"
        >
          {reviewer_note}
        </dd>
      </dl>
    </Card>
  );
};

ServiceAuthorizationRejectionCard.propTypes = {
  authorization: PropTypes.shape({
    service_authorization_denial_reason: PropTypes.shape({
      id: PropTypes.string.isRequired,
      display_name: PropTypes.string,
    }).isRequired,
    reviewer_note: PropTypes.string,
  }).isRequired,
};

export default ServiceAuthorizationRejectionCard;
