import { communicationTypesInitialValues } from 'common/form/Profile/utils';
import { primaryFirstEmailSort } from '.';

function emailsInitialValues(emails = []) {
  const withCommunicationTypes = emails.reduce((acc, curr) => ([
    ...acc,
    {
      ...curr,
      acceptable_communication_types:
        communicationTypesInitialValues(curr.acceptable_communication_types),
    },
  ]), []);
  return primaryFirstEmailSort(withCommunicationTypes);
}

export default emailsInitialValues;
