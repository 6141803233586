import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import moment from 'moment';
import './RowDisplay.scss';

const labelClass = (labelPosition) => classNames({
  'text-right': labelPosition === 'right',
  'text-left': labelPosition === 'left',
});

const mainClass = (noHover) => classNames({
  'common-display-profile': true,
  'editable-panel': true,
  'common-display-profile--no-hover': noHover,
});

const columnClass = (renderVerifiedCheck) => classNames({
  'col-sm-3': true,
  'verify-check': renderVerifiedCheck,
});

const lastUpdateVersionsText = (lastUpdate, itemId) => {
  if (!isEmpty(lastUpdate.versions)) {
    const version = lastUpdate.versions.find((obj) => obj.item_id === itemId);
    if (!isEmpty(version)) {
      if (version.created_at && version.first_name) {
        const name = <strong className="text-gray-600">{version.first_name} {version.last_name}</strong>;
        return <text>Last update by {name} on {moment(version.created_at).utc().format('MMMM D, YYYY')}</text>;
      }
      if (version.created_at && !version.first_name) {
        return `Last update on ${moment(version.created_at).utc().format('MMMM D, YYYY')}`;
      }
    }
  }
  return 'This field has not been updated in the last 180 days.';
};

const lastUpdateText = (lastUpdate) => {
  const { recently_updated_by_name, recently_updated_at } = lastUpdate;
  if (recently_updated_at && recently_updated_by_name) {
    const name = <strong className="text-gray-600">{recently_updated_by_name}</strong>;
    return <text>Last update by {name} on {moment(recently_updated_at).utc().format('MMMM D, YYYY')}</text>;
  }
  if (recently_updated_at && !recently_updated_by_name) {
    return `Last update on ${moment(recently_updated_at).utc().format('MMMM D, YYYY')}`;
  }
  return 'This field has not been updated in the last 180 days.';
};

const RowDisplay = ({
  children,
  deleteSection,
  editSection,
  hideDelete,
  hideEdit,
  label,
  labelPosition,
  noHover,
  renderVerifiedCheck,
  lastUpdate,
  showLastUpdate,
  itemId,
}) => (
  <div className={mainClass(noHover)}>
    <div className="row">
      <div className={columnClass(renderVerifiedCheck)}>
        {
          renderVerifiedCheck && (
            <div className="flex-static verify-check__container">
              {renderVerifiedCheck}
            </div>
          )
        }
        <h5 className={labelClass(labelPosition)}>
          {label}
        </h5>
      </div>
      <div className="col-sm-9 flex">
        <div className="flex-auto">
          <div className="row">
            {children}
          </div>
        </div>
        {
          !hideDelete && deleteSection && (
            <div className="flex-none">
              {deleteSection}
            </div>
          )
        }
        {
          editSection && (
            <div className="flex-none w-12">
              {!hideEdit && editSection}
            </div>
          )
        }

      </div>
      <div className="w-1/4 pr-4 inline-block" />
      <div className="w-2/4 inline-block">
        {
          showLastUpdate && (
            <p className="last_updated text-xs italic text-gray-600 mt-2">
              {itemId === null ? lastUpdateText(lastUpdate) : lastUpdateVersionsText(lastUpdate, itemId)}
            </p>
          )
        }
      </div>
    </div>
  </div>
);

RowDisplay.propTypes = {
  children: PropTypes.node.isRequired,
  deleteSection: PropTypes.node,
  editSection: PropTypes.node,
  hideDelete: PropTypes.bool,
  hideEdit: PropTypes.bool,
  label: PropTypes.node,
  labelPosition: PropTypes.string,
  noHover: PropTypes.bool,
  renderVerifiedCheck: PropTypes.node,
  lastUpdate: PropTypes.object,
  showLastUpdate: PropTypes.bool,
  itemId: PropTypes.string,
};

RowDisplay.defaultProps = {
  hideEdit: false,
  hideDelete: false,
  label: '',
  labelPosition: 'right',
  noHover: false,
  lastUpdate: {
    recently_updated_by_name: null,
    recently_updated_at: null,
    versions: null,
  },
  showLastUpdate: false,
  itemId: null,
};

export default RowDisplay;
