import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UUModal, Button } from '@unite-us/ui';

const ConfirmationModal = ({ modalRef, onMergeRecordsClick }) => {
  const [isMergingClients, setIsMergingClients] = useState(false);
  const handleClose = () => {
    modalRef.current.closeModal();
  };

  const handleMergeRecords = async () => {
    setIsMergingClients(true);
    try {
      if (onMergeRecordsClick) {
        await onMergeRecordsClick();
      }
    } finally {
      setIsMergingClients(false);
    }
  };

  return (
    <UUModal
      ref={modalRef} title="Confirm Merge" size="normal"
      onRequestClose={handleClose}
      modal
      actions={(
        <div className="flex justify-end gap-6">
          <Button
            label="Close"
            secondary
            onClick={handleClose}
          />
          <Button
            label="Merge Records"
            primary
            disabled={isMergingClients}
            data-testid="merge-records-button"
            onClick={handleMergeRecords}
          />
        </div>
      )}
    >
      <div className="flex flex-col space-y-4 pb-6">
        <div className="text-sm">
          You are about to merge these records.
        </div>
        <div className="text-sm text-red">
          This action can&apos;t be undone.
        </div>
      </div>
    </UUModal>
  );
};

ConfirmationModal.propTypes = {
  modalRef: PropTypes.object.isRequired,
  onMergeRecordsClick: PropTypes.func,
};

ConfirmationModal.defaultProps = {
  onMergeRecordsClick: null,
};

export default ConfirmationModal;
