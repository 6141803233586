import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import ReferralCreateBrowse from 'src/components/Browse/ReferralCreateBrowse';
import BrowseWrapper from 'src/components/Browse/components/BrowseWrapper';
import {
  clearNetworkBrowseFilters,
  clearNetworkBrowseGroups,
} from 'actions/Browse';
import callOrLog from 'src/common/utils/callOrLog';
import { BROWSE } from 'common/utils/EventTracker/utils/eventConstants';
import { Serializer } from '@unite-us/client-utils';
import ContactReferralSummary from './ContactReferralSummary';

class ReferralServiceProviderBrowse extends Component {
  constructor(props) {
    super(props);

    this.removeGroup = this.removeGroup.bind(this);
    this.toggleSelected = this.toggleSelected.bind(this);
    this.addGroups = this.addGroups.bind(this);
    this.restrictGroupsBy = this.restrictGroupsBy.bind(this);

    this.state = {
      selectedGroups: _.get(props, 'selectedGroups', []),
    };
  }

  toggleSelected(group) {
    const { contact } = this.props;
    const selectedAlready = _.some(this.state.selectedGroups, { id: group.id });

    const eventPayload = Serializer.build({ contact });

    if (selectedAlready) {
      this.removeGroup(group);
      callOrLog(() => this.context.eventTracker(BROWSE.removeReferralRecipient, {
        contact: eventPayload,
      }));
    } else {
      this.setState({ selectedGroups: [...this.state.selectedGroups, group] });
      callOrLog(() => this.context.eventTracker(BROWSE.addReferralRecipient, {
        contact: eventPayload,
      }));
    }
  }

  removeGroup(group) {
    const selectedGroups = _.reject(this.state.selectedGroups, { id: group.id });
    this.setState({ selectedGroups }, () => {
      if (_.isEmpty(this.state.selectedGroups)) {
        this.addGroups([]);
      }
    });
  }

  addGroups(groups) {
    if (!_.isEmpty(groups)) {
      this.props.clearNetworkBrowseFilters();
      this.props.clearNetworkBrowseGroups();
    }

    this.props.addGroups(groups);
  }

  restrictGroupsBy(browseGroups) {
    const { groups, groupsOptionType, oonGroups } = this.props;

    const scopedGroups = groupsOptionType === 'in-network' ? groups : oonGroups;

    return _.filter(browseGroups, (group) => (
      _.some(scopedGroups, { id: group.id })
    ));
  }

  render() {
    const {
      contact,
      contextAction,
      currentUserGroup,
      description,
      groupsOptionType,
      referral,
      referredByNetworkId,
      referredToNetworkId,
      sender,
      serviceType,
      toggleBrowse,
      feeScheduleProgramId,
    } = this.props;

    return (
      <BrowseWrapper toggleBrowse={toggleBrowse}>
        <div className="referral-service-provider-browse">
          <div className="referral-service-provider-browse__referral-summary">
            <ContactReferralSummary
              contact={contact}
              contextAction={contextAction}
              description={description}
              referral={referral}
              sender={sender}
              serviceType={serviceType}
              toggleBrowse={toggleBrowse}
            />
          </div>
          <div className="referral-service-provider-browse__browse">
            <ReferralCreateBrowse
              addGroups={this.addGroups}
              addOrRemoveGroup={this.toggleSelected}
              contact={contact}
              contextAction={contextAction}
              currentUserGroup={currentUserGroup}
              feeScheduleProgramId={feeScheduleProgramId}
              groupsOptionType={groupsOptionType}
              networkId={referredByNetworkId}
              permittedNetworkId={referredToNetworkId}
              removeGroup={this.removeGroup}
              restrictGroupsBy={this.restrictGroupsBy}
              selectedGroups={this.state.selectedGroups}
              serviceType={serviceType}
              toggleBrowse={toggleBrowse}
            />
          </div>
        </div>
      </BrowseWrapper>
    );
  }
}

ReferralServiceProviderBrowse.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

ReferralServiceProviderBrowse.propTypes = {
  addGroups: PropTypes.func.isRequired,
  clearNetworkBrowseFilters: PropTypes.func.isRequired,
  clearNetworkBrowseGroups: PropTypes.func.isRequired,
  contact: PropTypes.object,
  contextAction: PropTypes.oneOf(['create', 'send']).isRequired,
  currentUserGroup: PropTypes.object,
  description: PropTypes.string,
  feeScheduleProgramId: PropTypes.string,
  groups: PropTypes.array.isRequired,
  groupsOptionType: PropTypes.string,
  oonGroups: PropTypes.array.isRequired,
  referral: PropTypes.object,
  referredByNetworkId: PropTypes.string,
  referredToNetworkId: PropTypes.string,
  sender: PropTypes.string,
  serviceType: PropTypes.object,
  toggleBrowse: PropTypes.func.isRequired,
};

ReferralServiceProviderBrowse.defaultProps = {
  feeScheduleProgramId: null,
  groupsOptionType: '',
};

export default connect(null, {
  clearNetworkBrowseFilters,
  clearNetworkBrowseGroups,
})(ReferralServiceProviderBrowse);
