import {
  ADD_REFERRAL_DOCUMENTS,
  FETCH_REFERRAL_DOCUMENTS,
  REMOVE_REFERRAL_DOCUMENT,
  RENAME_REFERRAL_DOCUMENT,
} from 'actions';
import { replaceObjectInArrayById } from 'common/utils/stateHelpers';
import { find, reject } from 'lodash';

const defaultState = [];

function referralDocumentsReducer(state = defaultState, action = {}) {
  const { documents, documentId, title } = action;

  switch (action.type) {
    case ADD_REFERRAL_DOCUMENTS:
      return [...state, ...documents];

    case FETCH_REFERRAL_DOCUMENTS:
      return documents;

    case REMOVE_REFERRAL_DOCUMENT:
      return reject(state, { id: documentId });

    case RENAME_REFERRAL_DOCUMENT:
      return replaceObjectInArrayById({ ...find(state, { id: documentId }), title }, state);

    default:
      return state;
  }
}

export default referralDocumentsReducer;
