import {
  useFindRecord,
  useUpdateMultipartRecord,
  useInvalidateQueries,
} from 'src/api/APIHooks';
import { useMutation } from 'react-query';
import { prdAdminApiClient } from 'src/api/config';
import Notifier from 'common/helpers/Notifier';
import { defaultQueryConfig, defaultMutationConfig } from '../apiHookOptions';

export const useFindDirectoryConfiguration = (id) => useFindRecord(
  'directory_configuration',
  id,
  {
    queryConfig: defaultQueryConfig,
    api: 'prdAdminApi',
  },
);

export const usePublishDirectoryConfiguration = (id) => {
  const invalidateQueries = useInvalidateQueries();
  return useMutation(
    async () => {
      const response = await prdAdminApiClient.post(`directory_configurations/${id}/publish`, { id });
      return response;
    },
    {
      onSuccess: () => {
        invalidateQueries('directory_configuration');
        invalidateQueries('directories');
        Notifier.dispatch(200, 'PRD - Changes have been successfully published.');
      },
      onError: () => Notifier.dispatch('error', 'Failed to publish directory'),
    },
    ['directory_configuration', id],
  );
};

export const useUpdateDirectoryConfiguration = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useUpdateMultipartRecord('directory_configurations', {
    api: 'prdAdminApi',
    mutationConfig: {
      onError: () => Notifier.dispatch('error', 'Something went wrong...'),
      onSuccess: (response) => {
        invalidateQueries('directory_configuration');
        invalidateQueries('directories');
        Notifier.dispatch(response.status, 'Directory Configuration Successfully Updated');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};
