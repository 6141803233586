const defaultOnDownloadLogicAnswers = (formNeedConfigurations, getServiceName, formName, services) => {
  const editLogicAnswers = formNeedConfigurations.map((answer) => ({
    ...answer,
    service: { name: getServiceName(answer.service.id, services) },
  }));
  const blob = new Blob([JSON.stringify(editLogicAnswers, null, 2)], { type: 'application/json' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  const normalizedFormName = formName.replace(/[^a-z0-9]+/ig, '-');
  a.download = `${normalizedFormName}-edit-logic-answers.json`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export default defaultOnDownloadLogicAnswers;
