import {
  UNSET_DASHBOARD_FETCHING,
} from 'actions';
import isCancelledRequest from 'src/components/Dashboard/utils/isCancelledRequest';

function unsetDashboardFetching(response, target) {
  return (dispatch) => {
    if (!isCancelledRequest(response)) {
      dispatch({
        type: UNSET_DASHBOARD_FETCHING,
        target,
      });
    }
    return response;
  };
}

export default unsetDashboardFetching;
