import React from 'react';
import { PropTypes } from 'prop-types';
import { Icon } from '@unite-us/ui';
import { browserHistory } from 'common/utils/browserHistory';
import { AUTHORIZATION_STATUSES } from 'src/common/Status/authorizationConstants';
import AcceptServiceAuthorizationButton from './AcceptServiceAuthorizationButton';
import RejectServiceAuthorizationButton from './RejectServiceAuthorizationButton';

const ServiceAuthorizationDetailsActionBar = ({ authorization }) => {
  const isOpen = authorization.state === AUTHORIZATION_STATUSES.open;

  return (
    <div className="sticky top-0 z-50 flex px-6 py-4 bg-white border-b border-solid border-dark-border-blue">
      <div className="flex-grow">
        <button
          className="flex p-2 border border-solid border-dark-border-blue rounded hover:bg-light-fill-blue"
          type="button"
          onClick={browserHistory.goBack}
        >
          <div className="h-4 w-4">
            <Icon
              className="h-4 w-4 pb-1 stroke-current stroke-1 fill-current text-text-blue"
              icon="IconChevronLeft"
            />
          </div>
          <div className="pl-2 text-text-blue">
            Back
          </div>
        </button>
      </div>
      { isOpen && (
        <div className="flex flex-row space-x-6">
          <RejectServiceAuthorizationButton authorization={authorization} />
          <AcceptServiceAuthorizationButton
            authorization={authorization}
          />
        </div>
      )}
    </div>
  );
};

ServiceAuthorizationDetailsActionBar.propTypes = {
  authorization: PropTypes.object.isRequired,
};

export default ServiceAuthorizationDetailsActionBar;
