import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';

const Stepper = ({ steps }) => (
  <nav aria-label="Progress">
    <ol className="pl-0 border border-solid border-dark-fill-blue rounded-md md:flex md:divide-y-0">
      {steps.map((step, stepIdx) => (
        <li key={step.name} className="relative md:flex-1 md:flex">
          {/* eslint-disable-next-line no-nested-ternary */}
          {step.status === 'complete' ? (
            <span
              onClick={step.onClick}
              className={`group flex items-center w-full${step.onClick ? ' cursor-pointer' : ''}`}
            >
              <span className="px-6 py-4 flex items-center text-sm font-bold">
                <span
                  className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-purple rounded-full"
                >
                  <Icon
                    className="w-4 h-4 text-white fill-current"
                    aria-hidden="true"
                    icon="IconCheck"
                  />
                </span>
                <span className="ml-4 text-sm font-bold text-purple">{step.name}</span>
              </span>
            </span>
          ) : step.status === 'current' ? (
            <span
              onClick={step.onClick}
              className={`px-6 py-4 flex items-center text-sm font-bold${step.onClick ? ' cursor-pointer' : ''}`}
              aria-current="step"
            >
              <span
                // eslint-disable-next-line max-len
                className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-solid border-purple rounded-full"
              >
                <span className="text-purple">{step.id}</span>
              </span>
              <span className="ml-4 text-sm font-bold text-purple">{step.name}</span>
            </span>
          ) : (
            <span
              onClick={step.onClick}
              className={`group flex items-center${step.onClick ? ' cursor-pointer' : ''}`}
            >
              <span className="px-6 py-4 flex items-center text-sm font-bold">
                <span
                  // eslint-disable-next-line max-len
                  className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-solid border-dark-grey rounded-full"
                >
                  <span className="text-dark-grey">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-bold text-dark-grey">{step.name}</span>
              </span>
            </span>
          )}

          {stepIdx !== steps.length - 1 ? (
            <>
              {/* Arrow separator for lg screens and up */}
              <div className="sm:hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                <svg
                  className="h-full w-full text-dark-fill-blue"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </>
          ) : null}
        </li>
      ))}
    </ol>
  </nav>
);

Stepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    status: PropTypes.oneOf(['complete', 'current', 'upcoming']).isRequired,
  })).isRequired,
};

export default Stepper;
