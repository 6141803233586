import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useCurrentPayerId, useIsNetworkLead } from 'common/contexts/CurrentProviderContext';
import InvoiceDetails from 'src/pages/invoices/components/InvoiceDetails';
import { useFindRecord, usePopulate } from 'src/api/APIHooks';
import { Spinner } from 'src/common/spinners';
import usePayerWQInteractiveView from '../hooks/usePayerWQInteractiveView';

export const InvoiceDetailPage = ({ params, showInvoiceType }) => {
  const isNetworkLead = useIsNetworkLead();
  const { isLoading, data } = useFindRecord(
    'invoice',
    params.id,
    { include: 'submitter', queryConfig: { placeholderData: undefined } },
  );

  const payerId = useCurrentPayerId();
  const { isLoading: isPayerInteractiveLoading, payerWQInteractiveView } = usePayerWQInteractiveView(payerId);

  const invoice = get(data, 'data.data', {});
  usePopulate('original_version', 'invoice', invoice, { queryConfig: { placeholderData: undefined } });

  if (isLoading || isPayerInteractiveLoading) {
    return <Spinner />;
  }

  return (
    <InvoiceDetails
      invoice={invoice}
      isNetworkLead={isNetworkLead}
      fullInvoiceView
      payerWQInteractiveView={payerWQInteractiveView}
      showInvoiceType={showInvoiceType}
    />
  );
};

InvoiceDetailPage.propTypes = {
  params: PropTypes.object.isRequired,
  showInvoiceType: PropTypes.bool,
};

InvoiceDetailPage.defaultProps = {
  showInvoiceType: false,
};

export default InvoiceDetailPage;
