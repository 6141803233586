import { useFind } from 'api/APIHooks';
import { useVersions } from 'common/hooks';
import { get, isEmpty, merge } from 'lodash';

const useInvoiceVersions = (id) => {
  const {
    isFetching: versionsAreFetching,
    isLoading: versionsAreLoading,
    data: versionData,
  } = useVersions('invoice', id);
  const versions = get(versionData, 'data.data', []);

  const { isFetching: employeesAreFetching, isLoading: employeesAreLoading, data: employeeData } = useFind(
    'employee',
    {
      user: versions.map((version) => version.user.id).join(','),
    },
    {
      queryConfig: {
        enabled: !isEmpty(versions),
      },
    },
  );
  const employees = get(employeeData, 'data.data', []);

  const versionsWithEmployees = versions.map((version) => (
    merge({}, version, { employee: employees.find((e) => e.user.id === version.user.id) })
  ));

  return {
    isFetching: versionsAreFetching || employeesAreFetching,
    isLoading: versionsAreLoading || employeesAreLoading,
    data: versionsWithEmployees,
  };
};

export default useInvoiceVersions;
