import _ from 'lodash';

const didFieldChangeById = (props, nextProps, fieldType) => {
  const value = _.get(props, `fields.${fieldType}.value.id`, '');
  const nextValue = _.get(nextProps, `fields.${fieldType}.value.id`, '');

  return value !== nextValue;
};

export default didFieldChangeById;
