import PropTypes from 'prop-types';
import React from 'react';
import SpinIcon from 'common/spinners/SpinIcon';
import { Pager } from '@unite-us/ui';

const NetworkActionsBarPagination = (props) => (
  <div className="network-actions-bar-pagination">
    <div className="network-actions-bar-pagination__pager-container">
      {
        props.isFetching && (
          <SpinIcon
            iconStyle={props.styles.spinIconIconStyles}
            style={props.styles.spinIconStyles}
          />
        )
      }
      <Pager
        disabled={props.isFetching}
        onNextClick={props.onNextClick}
        onPrevClick={props.onPrevClick}
        paging={props.paging}
      />
    </div>
  </div>
);


NetworkActionsBarPagination.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  paging: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
};

NetworkActionsBarPagination.defaultProps = {
  styles: {
    spinIconIconStyles: {
      height: '20px',
      width: '20px',
    },
    spinIconStyles: {
      marginTop: '7px',
      marginRight: '10px',
    },
  },
};

export default NetworkActionsBarPagination;
