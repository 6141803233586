import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'common/utils/browserHistory';
import {
  Button,
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
} from '@unite-us/app-components';
import FeeScheduleScreeningsTable from './FeeScheduleScreeningsTable';

const FeeScheduleScreenings = ({ feeScheduleId }) => {
  const navigateToNewScreening = () => browserHistory.push({
    pathname: `/backoffice/fee_schedules/${feeScheduleId}/fee_schedule_screenings/new`,
  });
  return (
    <div className="fee-schedule-base-card">
      <BaseCard className="p-6" noBorder>
        <BaseCardHeader title="Fee Schedule Screenings" noBorder className="px-6 font-bold">
          <Button
            id="add-program-button"
            icon={{
            name: 'Plus',
            position: 'left',
            color: 'current-blue',
          }}
            onClick={
            navigateToNewScreening
          }
            className="flex"
            label="ADD SCREENING"
            data-testid="add-fs-program-btn"
          />
        </BaseCardHeader>
        <BaseCardBody className="px-6">
          <FeeScheduleScreeningsTable feeScheduleId={feeScheduleId} />
        </BaseCardBody>
      </BaseCard>
    </div>
);
};

FeeScheduleScreenings.propTypes = {
  feeScheduleId: PropTypes.string.isRequired,
};

export default FeeScheduleScreenings;
