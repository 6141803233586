import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Dialog } from '@unite-us/ui';
import { reset, initialize } from 'redux-form';
import { connect } from 'react-redux';
import { generateUUID } from 'common/utils/utils';
import EditButton from 'common/display/Profile/components/EditButton';
import './EditModal.scss';

export class EditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: generateUUID(),
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true }, () => {
      this[`editModal-${this.state.id}`].openDialog().then(() => {
        this.setState({ modalIsOpen: false });
      });
      this.props.modalOpenCallback();
    });
  }

  closeModal() {
    const { formName } = this.props;
    this.props.clearCurrentSelectedId();
    const modal = this[`editModal-${this.state.id}`];
    if (modal) {
      modal.closeDialog();
    }
    if (formName) {
      this.props.reset(formName);
    }
  }

  render() {
    const {
      actions,
      dialogContentStyles,
      buttonId,
      children,
      disabled,
      header,
      id,
      labelText,
      removeCloseIcon,
      showEdit,
      size,
    } = this.props;

    const childrenWithFuncs = React.Children.map(children, (child) => (
      React.cloneElement(child, {
        closeModal: this.closeModal,
        modalIsOpen: this.state.modalIsOpen,
      })
    ));

    return (
      <div className="edit-modal">
        {
          showEdit && (
            <EditButton
              disabled={disabled}
              edit={this.openModal}
              id={buttonId}
              labelText={labelText}
              aria-label={buttonId.replace(/-/g, ' ').replace('btn', '').trim()}
            />
          )
        }
        <Dialog
          actions={actions}
          dialogContentStyles={dialogContentStyles}
          id={id}
          onCloseModal={this.closeModal}
          onRequestClose={this.closeModal}
          ref={(el) => { this[`editModal-${this.state.id}`] = el; }}
          removeCloseIcon={removeCloseIcon}
          size={size}
          title={header}
          type="form"
        >
          {childrenWithFuncs}
        </Dialog>
      </div>
    );
  }
}

EditModal.propTypes = {
  actions: PropTypes.node,
  dialogContentStyles: PropTypes.object,
  buttonId: PropTypes.string.isRequired,
  children: PropTypes.node,
  clearCurrentSelectedId: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  formName: PropTypes.string,
  header: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  modalOpenCallback: PropTypes.func,
  removeCloseIcon: PropTypes.bool,
  reset: PropTypes.func,
  showEdit: PropTypes.bool.isRequired,
  size: PropTypes.string,
};

EditModal.defaultProps = {
  clearCurrentSelectedId: noop,
  dialogContentStyles: {},
  disabled: false,
  fields: [],
  initialValues: {},
  labelText: 'Edit',
  modalOpenCallback: noop,
  showEdit: true,
  removeCloseIcon: false,
};

export default connect(undefined, { reset, initialize })(EditModal);
