/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';

const AddressRemoveButton = ({
  confirmRemove,
  displayText,
  iconSize,
  multi,
  remove,
  showRemove,
  toggleRemove,
  trashColor,
}) => {
  if (multi && remove) {
    return (
      <div className="address-remove-buttons">
        <div className={!showRemove ? 'address-remove-buttons__remove-text' : 'hidden'}>
          <button
            className={displayText === null ? '' : 'address-flex-end'}
            onClick={toggleRemove}
            type="button"
            title="Remove address"
            tabIndex="0"
          >
            <Icon icon="IconTrash" size={iconSize} color={trashColor} />
            { displayText !== null && (
              <div>{displayText}</div>
            )}
          </button>
        </div>

        <div className={showRemove ? 'address-remove-buttons__remove-confirmation text-right' : 'hidden'}>
          <p className="address-remove-buttons__question-text">Are you sure you want to remove this address?</p>
          <div className="address-remove-buttons__selects">
            <button
              className="address-remove-buttons__confirm-confirmation"
              onClick={confirmRemove}
              type="button"
              tabIndex="0"
            >
              YES
            </button>
            <button
              className="address-remove-buttons__cancel-confirmation"
              onClick={toggleRemove}
              type="button"
              tabIndex="0"
            >
              NO
            </button>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

AddressRemoveButton.propTypes = {
  confirmRemove: PropTypes.func.isRequired,
  displayText: PropTypes.string,
  iconSize: PropTypes.number,
  multi: PropTypes.bool.isRequired,
  remove: PropTypes.func,
  showRemove: PropTypes.bool.isRequired,
  trashColor: PropTypes.string,
  toggleRemove: PropTypes.func.isRequired,
};

AddressRemoveButton.defaultProps = {
  displayText: null,
  iconSize: 20,
  trashColor: '#C5C8CC',
};

export default AddressRemoveButton;
