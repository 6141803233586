import {
  CREATE_PLAN_STARTED,
  CREATE_PLAN_SUCCESS,
  CREATE_PLAN_ERROR,
} from 'actions';
import {
  STATUS_INITIAL,
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_ERROR,
} from 'common/Status/constants';

export const defaultState = {
  plans: [],
  status: STATUS_INITIAL,
};

export default function plansReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case CREATE_PLAN_STARTED:
      return { ...state, status: STATUS_PENDING };
    case CREATE_PLAN_ERROR:
      return { ...state, status: STATUS_ERROR };
    case CREATE_PLAN_SUCCESS: {
      return {
        ...state,
        plans: [...state.plans, { [action.payload.id]: action.payload }],
        status: STATUS_SUCCESS,
      };
    }

    default:
      return state;
  }
}
