import { useFind } from 'src/api/APIHooks';

const useProviderFeeSchedules = (providerId, employeeId, isNetworkLead, isPayer) => {
  const filter = isNetworkLead ? ({ network_lead: providerId }) : ({ provider: providerId, employee: employeeId });

  const networkLeadRelationshipsQuery = useFind(
    'network_lead_relationships',
    filter,
    {
      page: { size: Infinity },
      queryConfig: {
        enabled: !!providerId && !isPayer,
      },
    },
  );

  const networkLeadRelationships = networkLeadRelationshipsQuery?.data?.data?.data ?? [];
  const feeScheduleIds = networkLeadRelationships.map((nlr) => nlr.fee_schedule?.id);
  const feeScheduleIdSet = new Set(feeScheduleIds);
  const uniqFeeScheduleIds = Array.from(feeScheduleIdSet);
  return uniqFeeScheduleIds;
};

export default useProviderFeeSchedules;
