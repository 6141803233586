import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import pluralize from 'pluralize';
import { Card, CardHeader } from 'common/Card';
import formatShortDate from 'common/utils/Dates/formatShortDate';
import { LineItem } from 'common/display/LineItem';
import { usePopulate } from 'api/APIHooks';
import DollarAmount from 'common/display/Money/DollarAmount';

const ServiceAuthorizationAcceptedCard = ({ authorization }) => {
  usePopulate('fee_schedule_program', 'fee_schedule_program', authorization);
  const isSingleDateRequested = authorization.approved_starts_at === authorization.approved_ends_at;
  const isCostBased = !!authorization.approved_cents;
  const unit = get(authorization, 'fee_schedule_program.unit', '');

  return (
    <Card borderColor="border-green" className="px-2 py-2 bg-white">
      <CardHeader
        className="pt-2 space-y-2"
        title="Authorization Details"
        titleIcon="V2CheckCircle"
        iconColor="#4ECE3D"
      />
      <dl className="m-0 px-4 py-6 grid grid-cols-4 gap-y-2 gap-x-12">
        {isCostBased ? (
          <LineItem field="Authorized Amount" valueClassName="col-span-3 ml-2">
            <DollarAmount convertCents className="font-medium-font" value={authorization.approved_cents} />
          </LineItem>
        ) : (
          <LineItem field="Authorized Amount" valueClassName="col-span-3 ml-2" isLoaded={!!unit}>
            {authorization.approved_unit_amount}
            {' '}
            {pluralize(unit, authorization.approved_unit_amount)}
          </LineItem>
        )}
        <LineItem field="Authorized Service Delivery Date(s)" valueClassName="col-span-3 ml-2">
          {isSingleDateRequested ? (
            formatShortDate(authorization.approved_ends_at)
          ) : (
            `${formatShortDate(authorization.approved_starts_at)} - ${formatShortDate(authorization.approved_ends_at)}`
          )}
        </LineItem>
        <LineItem field="Payer Authorization ID" valueClassName="col-span-3 ml-2">
          {authorization.payer_authorization_number || 'N/A'}
        </LineItem>
        {authorization.reviewer_note && (
          <LineItem field="Note" valueClassName="col-span-3 ml-2">
            <div className="whitespace-pre-line">{authorization.reviewer_note}</div>
          </LineItem>
        )}
      </dl>
    </Card>
  );
};

ServiceAuthorizationAcceptedCard.propTypes = {
  authorization: PropTypes.object.isRequired,
};

export default ServiceAuthorizationAcceptedCard;
