import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ExpandableContainer } from '@unite-us/client-utils';
import { Divider } from '@unite-us/ui';
import {
  outcomeColumnOne,
  outcomeColumnTwo,
} from 'common/display/SingleItemDetails/utils/DetailOutcomeLabels';
import { BasicTable } from 'common/tables';
import { useOutcomeNotes } from 'src/components/Notes/queries';

function DetailOutcome(props) {
  const { item } = props;
  const updatedAt = _.get(item, 'updated_at');
  const notes = useOutcomeNotes(item);

  return (
    <div className="detail-outcome">
      <div className="row detail-outcome__summary">
        <div className="col-lg-6 list-item">
          <BasicTable
            data={outcomeColumnOne(item)}
            id="outcome-column-one-table"
            type="detail-page"
          />
        </div>
        <div className="col-lg-6 list-divider">
          <BasicTable
            data={outcomeColumnTwo(item, updatedAt)}
            id="outcome-column-two-table"
            type="detail-page"
          />
        </div>
      </div>

      <Divider className="mt-4" />

      <div className="row">
        <div className="col-lg-12">
          <ExpandableContainer
            id="outcome-notes-expandable"
            header="Outcome Notes"
            collapsedHeight="70px"
            style={{ backgroundColor: '#ECF5FD' }}
          >
            <p>
              {_.get(notes, '[0].text', '')}
            </p>
          </ExpandableContainer>
        </div>
      </div>
    </div>
  );
}

DetailOutcome.propTypes = {
  item: PropTypes.shape({
    closing: PropTypes.shape({
      note: PropTypes.string,
      resolved: PropTypes.bool,
      created_by: PropTypes.shape({
        full_name: PropTypes.string,
      }).isRequired,
      outcome: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default DetailOutcome;
