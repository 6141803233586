import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';

import {
  differenceBy,
  each,
  filter,
  find,
  intersectionBy,
  isEmpty,
  isEqual,
  minBy,
  noop,
  trimEnd,
} from 'lodash';
import {
  createMapSearchButton,
  createRecenterButton,
  createCenterMapMarker,
  mapStyles,
} from './utils';
import getMarkerIcon from './utils/mapElements/getMarkerIcon';
import InfoWindow from './utils/mapElements/InfoWindow';
import '../stylesheets/map.scss';

class Map extends Component {
  constructor(props) {
    super(props);

    this.map = null;
    this.centerChangeTimeout = null;
    this.dragging = false;
    this.centerMarker = null;
    this.markers = [];
    this.infoWindow = null;

    this.cleanUpMap = this.cleanUpMap.bind(this);
    this.fitBounds = this.fitBounds.bind(this);
    this.geoCode = this.geoCode.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleHoverChanged = this.handleHoverChanged.bind(this);
    this.handleInfoWindowClose = this.handleInfoWindowClose.bind(this);
    this.handleInfoWindowOpen = this.handleInfoWindowOpen.bind(this);
    this.handleMapSearch = this.handleMapSearch.bind(this);
    this.handleMarkerClick = this.handleMarkerClick.bind(this);
    this.handleSearchButton = this.handleSearchButton.bind(this);
    this.loadMap = this.loadMap.bind(this);
    this.recenterMap = this.recenterMap.bind(this);
    this.removePreviousCenterMarker = this.removePreviousCenterMarker.bind(this);
    this.removeSearchButton = this.removeSearchButton.bind(this);
    this.setMarkers = this.setMarkers.bind(this);
    this.tilesLoaded = this.tilesLoaded.bind(this);
    this.toggleProviderHover = this.toggleProviderHover.bind(this);
    this.updateMapCenter = this.updateMapCenter.bind(this);
    this.updateSearchRadiusCircle = this.updateSearchRadiusCircle.bind(this);

    this.state = {
      currentHoverGroupId: null,
    };
  }

  componentDidMount() {
    this.loadMap();

    if (this.props.searchRadius) {
      this.updateSearchRadiusCircle();
    }
  }

  componentDidUpdate(prevProps) {
    const centerChanged = () => prevProps.center !== this.props.center;
    const googleChanged = () => prevProps.google !== this.props.google;
    const hoverChanged = () => this.props.hoverGroupId !== prevProps.hoverGroupId;
    const markersChanged = () => !isEqual(prevProps.markers, this.props.markers);
    const searchButtonChanged = () => prevProps.showSearchButton !== this.props.showSearchButton;
    const searchRadiusChanged = () => prevProps.searchRadius !== this.props.searchRadius;

    if (googleChanged()) {
      this.loadMap();
    }

    if (hoverChanged()) {
      this.handleHoverChanged(prevProps.hoverGroupId);
    }

    if (markersChanged()) {
      this.setMarkers(prevProps.markers);
    }

    if (searchButtonChanged()) {
      this.handleSearchButton();
    }

    if (centerChanged()) {
      this.updateMapCenter();
      this.updateSearchRadiusCircle();
    }

    if (searchRadiusChanged()) {
      this.updateSearchRadiusCircle();
    }
  }

  componentWillUnmount() {
    this.cleanUpMap();
  }

  handleInfoWindowClose ({ marker }) {
    return () => this.infowindow.close(this.map, marker);
  }

  handleInfoWindowOpen ({ marker }) {
    return () => {
      const contentString = ReactDOMServer.renderToString(
        <InfoWindow title={marker.key} />,
      );

      this.infowindow.setContent(contentString);
      this.infowindow.open(this.map, marker);
    };
  }

  handleMarkerClick(marker) {
    return () => {
      this.props.setSelectedGroupFromMarker({ marker });
    };
  }

  handleHoverChanged(previousHoverGroupId) {
    const { center, google, hoverGroupId } = this.props;
    const googleMapEvent = google.maps.event;

    if (!isEmpty(previousHoverGroupId) && (previousHoverGroupId === this.state.currentHoverGroupId)) {
      const marker = find(this.markers, ['groupId', previousHoverGroupId]);
      googleMapEvent.trigger(marker, 'mouseout', { isHovering: false, marker });
    }

    if (!isEmpty(hoverGroupId)) {
      const mapCenter = new google.maps.LatLng(center.lat, center.lng);
      const hoverMarkers = filter(this.markers, ['groupId', hoverGroupId]);

      filter(hoverMarkers, (hov) => {
        const distanceFromCenter = google.maps.geometry.spherical.computeDistanceBetween(mapCenter, hov.position);
        // eslint-disable-next-line no-param-reassign
        hov.distanceFromCenter = distanceFromCenter;
      });

      const marker = minBy(hoverMarkers, 'distanceFromCenter');

      googleMapEvent.trigger(marker, 'mouseover', { isHovering: true, marker });
    }
  }

  handleDragStart() {
    this.props.handleSearchAreaChange();
  }

  handleSearchButton() {
    const { showSearchButton } = this.props;

    return showSearchButton ? this.createSearchButton() : this.removeSearchButton();
  }

  handleMapSearch() {
    const newCenter = this.map.getCenter();

    if (this.searchRadiusCircle) {
      this.searchRadiusCircle.setMap(null);
      this.searchRadiusCircle = null;
    }

    this.geoCode(newCenter).then((address) => {
      const formattedAddress = trimEnd(address, ', USA');
      this.props.handleSearchAreaClick(newCenter, formattedAddress);
    });
  }

  setMarkers(previousMarkers = []) {
    const {
      center,
      google,
      markers,
    } = this.props;

    if (isEmpty(previousMarkers) && !isEmpty(this.markers)) {
      return;
    }

    const mapCenter = new google.maps.LatLng(center.lat, center.lng);
    const markersObjToDelete = differenceBy(previousMarkers, markers, 'id');
    const markersObjToAdd = differenceBy(markers, previousMarkers, 'id');

    // Remove markers that need to
    const markersToDelete = intersectionBy(this.markers, markersObjToDelete, 'id');

    each(markersToDelete, (marker) => {
      google.maps.event.clearInstanceListeners(marker);
      marker.setMap(null);
    });

    this.markers = differenceBy(this.markers, markersToDelete, 'id');

    // Set the markers on the current map
    each(markersObjToAdd, (marker) => {
      const position = new google.maps.LatLng(marker.position);
      const {
        groupId, groupType, id, key,
      } = marker;

      const distanceFromCenter = google.maps.geometry.spherical.computeDistanceBetween(mapCenter, position);

      const markerInstance = new google.maps.Marker({
        id,
        distanceFromCenter,
        groupId,
        groupType,
        icon: { url: getMarkerIcon(groupType, false) },
        position,
        key,
        zIndex: 1,
      });

      this.markers.push(markerInstance);
      markerInstance.setMap(this.map);
    });

    each(this.markers, (marker) => {
      marker.addListener('click', this.handleMarkerClick(marker));

      marker.addListener('mouseout', this.toggleProviderHover({ isHovering: false, marker }));
      marker.addListener('mouseover', this.toggleProviderHover({ isHovering: true, marker }));
      marker.addListener('mouseout', this.handleInfoWindowClose({ marker }));
      marker.addListener('mouseover', this.handleInfoWindowOpen({ marker }));
    });
  }

  setListeners() {
    this.map.addListener('dragstart', () => this.handleDragStart());
  }

  toggleProviderHover({ isHovering, marker }) {
    return () => {
      const markerGroupId = marker.groupId;
      const currentHoverGroupId = isHovering ? markerGroupId : '';

      this.setState({ currentHoverGroupId });

      each(this.markers, (groupMarker) => {
        if (groupMarker.groupId === markerGroupId) {
          const markerIcon = getMarkerIcon(groupMarker.groupType, isHovering);

          groupMarker.setZIndex(isHovering ? 2 : 1);
          groupMarker.setIcon(markerIcon);
        }
      });
    };
  }

  tilesLoaded() {
    this.setMarkers();
  }

  fitBounds() {
    const { google: { maps } } = this.props;

    const positions = new maps.LatLngBounds();
    each(this.markers, (marker) => positions.extend(marker.position));

    this.map.fitBounds(positions);
  }

  geoCode(location) {
    const { google } = this.props;
    const geoCoder = new google.maps.Geocoder();

    return new Promise((res) => {
      geoCoder.geocode({ location }, (results, status) => {
        if (status === 'OK') {
          const address = find(results, { types: ['postal_code'] });
          return address ?
            res(address.formatted_address) :
            res('Custom Search Area');
        }
        return res('Custom Search Area');
      });
    });
  }

  removePreviousCenterMarker() {
    if (this.centerMarker) {
      this.centerMarker.inner.setMap(null);
      this.centerMarker.middle.setMap(null);
      this.centerMarker.outer.setMap(null);

      this.centerMarker = null;
    }
  }

  createSearchButton() {
    this.removeSearchButton();

    createMapSearchButton(this.map, this.handleMapSearch);
  }

  recenterMap() {
    const center = this.props.centerForDistance;

    this.map.setCenter(center);
  }

  updateMapCenter() {
    const center = this.props.center;

    this.removePreviousCenterMarker();
    this.map.panTo(center);
    this.setMarkers();
    this.centerMarker = createCenterMapMarker(this.map);
  }

  updateSearchRadiusCircle() {
    if (this.searchRadiusCircle) {
      this.searchRadiusCircle.setMap(null);
      this.searchRadiusCircle = null;
    }

    if (!isEmpty(this.props.searchRadius)) {
      this.searchRadiusCircle = new this.props.google.maps.Circle({
        strokeColor: '#333333',
        strokeOpacity: 0.1,
        strokeWeight: 2,
        fillColor: '#333333',
        fillOpacity: 0.08,
        map: this.map,
        center: this.props.centerForDistance,
        radius: this.props.searchRadius * 1609.34, // meters to miles
        zIndex: 1,
      });

      this.map.fitBounds(this.searchRadiusCircle.getBounds());
    }
  }

  removeSearchButton() {
    const { google: { maps } } = this.props;

    this.map.controls[maps.ControlPosition.TOP_RIGHT].clear();
  }

  cleanUpMap() {
    const { google } = this.props;
    if (google) {
      google.maps.event.clearInstanceListeners(this.map);
    }
    this.map = null;
  }

  loadMap() {
    if (this.props && this.props.google) {
      const { google: { maps }, center: { lat, lng } } = this.props;

      if (lat && lng) {
        const center = new maps.LatLng(lat, lng);
        const mapConfig = {
          center,
          zoomControl: true,
          zoomControlOptions: {
            position: maps.ControlPosition.TOP_LEFT,
          },
          disableDefaultUI: true,
          mapTypeControl: false,
          styles: mapStyles,
        };

        this.map = new maps.Map(this.mapArea, mapConfig);
        this.infowindow = new maps.InfoWindow({});
        createRecenterButton(this.map, this.recenterMap);
        this.centerMarker = createCenterMapMarker(this.map);

        maps.event.addListenerOnce(this.map, 'tilesloaded', () => this.tilesLoaded());
        this.setListeners();
      }
    }
  }

  render() {
    return (
      <div
        className="map"
        ref={(div) => {
          this.mapArea = div;
          return div;
        }}
      />
    );
  }
}

Map.propTypes = {
  google: PropTypes.object.isRequired,
  center: PropTypes.object,
  showSearchButton: PropTypes.bool.isRequired,
  markers: PropTypes.arrayOf(PropTypes.object),
  handleSearchAreaChange: PropTypes.func,
  handleSearchAreaClick: PropTypes.func,
  centerForDistance: PropTypes.object,
  searchRadius: PropTypes.string,
  setSelectedGroupFromMarker: PropTypes.func.isRequired,
  hoverGroupId: PropTypes.string,
};

Map.defaultProps = {
  hoverGroupId: '',
  handleSearchAreaChange: noop,
  handleSearchAreaClick: noop,
};

export default Map;
