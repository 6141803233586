import {
  RENAME_CONTACT_DOCUMENT,
  RENAME_REFERRAL_DOCUMENT,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import { get } from 'lodash';
import { coreApi } from 'src/api/config';
import { isHttpSuccess } from 'src/api/utils/httpStatus';

const renameReferralDocument = ({
  contactId,
  documentId,
  title,
}) => (
  async (dispatch) => {
    try {
      const response = await coreApi.updateRecord('file_upload', documentId, { filename: title });

      if (!response || !isHttpSuccess(response.status)) {
        throw response;
      }

      Notifier.dispatch(response.status, 'Document Renamed');
      dispatch({
        type: RENAME_REFERRAL_DOCUMENT,
        documentId: get(response, 'data.data.id'),
        title: get(response, 'data.data.filename'),
      });

      dispatch({
        type: RENAME_CONTACT_DOCUMENT,
        contactId,
        documentId: get(response, 'data.data.id'),
        title: get(response, 'data.data.filename'),
      });

      return response;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  }
);

export default renameReferralDocument;
