import React from 'react';
import { PropTypes } from 'prop-types';

const CardSubHeader = ({ className, children }) => (
  <div className={className || 'pt-1 pl-4 text-text-blue font-extrabold text-lg'}>{children}</div>
);

CardSubHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

CardSubHeader.defaultProps = {
  children: '',
  className: '',
};

export default CardSubHeader;
