import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty, get, map, reject } from 'lodash';
import { fetchGroupScreening } from 'actions/Screening/Group';
import { getCurrentScreening } from 'src/components/Screenings/Detail/utils';
import ReferralServicesForm from 'pages/components/ReferralServicesForm';
import { isExternalProviderOrOONGroup } from 'src/components/Groups/utils';
import { destroyForm } from 'actions/Form';
import { CREATE_REFERRAL_FORM } from 'src/components/Referrals/constants';
import { generateUUID } from 'common/utils/utils';

export class ScreeningToReferral extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadForm: false,
      itemIDs: this.props.needIndices.map(() => generateUUID()),
    };

    this.setLoadForm = this.setLoadForm.bind(this);
  }

  componentDidMount() {
    const {
      screening,
      screeningId,
    } = this.props;

    if (!screening || isEmpty(screening)) {
      this.props.fetchGroupScreening({ screeningId });
    }

    this.props.destroyForm(CREATE_REFERRAL_FORM).then(this.setLoadForm);
  }

  setLoadForm() {
    this.setState({ loadForm: true });
  }

  render() {
    const {
      needIndices,
      screening,
      screeningId,
    } = this.props;

    const { loadForm, itemIDs } = this.state;

    if (!screening || isEmpty(screening) || !loadForm) {
      return null;
    }

    // Create services data to pass ot referral form.
    const shouldPreselectProviders = get(screening, 'preselect_providers', false);
    const needs = needIndices.map((i) => get(screening, `needs[${i}]`, {}));

    const initialServices = needs.map(({ groups, service_type } = {}) => {
      const inNetworkGroups = reject(groups, isExternalProviderOrOONGroup);
      const selected = map(inNetworkGroups, (group) => ({ group }));
      return {
        ...(shouldPreselectProviders ? { selected } : {}),
        service_type,
      };
    });

    return (
      <div className="screening-to-referral">
        <ReferralServicesForm
          initialServices={initialServices}
          itemIDs={itemIDs}
          origin="screenings"
          originPrefix={`/screenings/${screening.id}/need/${needIndices[0]}`}
          screeningId={screeningId}
        />
      </div>
    );
  }
}

ScreeningToReferral.propTypes = {
  destroyForm: PropTypes.func.isRequired,
  fetchGroupScreening: PropTypes.func.isRequired,
  needIndices: PropTypes.array,
  screening: PropTypes.shape({
    id: PropTypes.string,
    needs: PropTypes.array,
  }),
  screeningId: PropTypes.string.isRequired,
};

ScreeningToReferral.defaultProps = {
  needIndices: [],
  screening: {
    id: '',
    needs: [],
  },
};

function mapStateToProps(state, ownProps) {
  const screeningId = get(ownProps, 'params.screening_id');
  const screening = getCurrentScreening(state, screeningId);

  // Grab the need indices from the url (either from needs/:need_id or from the
  // needIndices query param).
  const needIndices = get(ownProps, 'location.query.needIds', '').split(',');

  return {
    needIndices,
    screening,
    screeningId,
  };
}

export default connect(
  mapStateToProps,
  {
    destroyForm,
    fetchGroupScreening,
  },
)(ScreeningToReferral);
