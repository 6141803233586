import { communicationTypesInitialValues } from 'common/form/Profile/utils';
import { primaryFirstPhoneSort } from '.';

function phonesInitialValues(phones = []) {
  const withCommunicationTypes = phones.reduce((acc, curr) => ([
    ...acc,
    {
      ...curr,
      acceptable_communication_types:
        communicationTypesInitialValues(curr.acceptable_communication_types),
    },
  ]), []);
  return primaryFirstPhoneSort(withCommunicationTypes);
}

export default phonesInitialValues;
