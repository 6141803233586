import { get } from 'lodash';

const dateDisputed = (versions) => {
  const versionUnderDispute = (version) => {
    const changesIncludeSetInvoiceUnderDispute = get(version, 'object_changes.under_dispute[1]') === true;

    return changesIncludeSetInvoiceUnderDispute;
  };

  const underDisputeInvoiceVersion = versions.find(versionUnderDispute);

  if (underDisputeInvoiceVersion) {
    const date = new Date(Date.parse(underDisputeInvoiceVersion.created_at));
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  }

  return null;
};

export default dateDisputed;
