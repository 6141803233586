import moment from 'moment';

// given a date, round it to the nearest midnight
const nearestDay = (timestamp) => {
  const timeMoment = moment.utc(timestamp);
  return timeMoment.hour() < 12 ?
    timeMoment.startOf('day') :
    timeMoment.add(1, 'day').startOf('day');
};

export default nearestDay;
