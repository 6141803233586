import Notifier from 'common/helpers/Notifier';
import {
  REASSIGN_REFERRAL_PROGRAM,
} from 'actions';
import { coreApi } from 'src/api/config';
import { get } from 'lodash';

export default function reassignReferralProgram(referralId, groupId, params) {
  const request = coreApi.updateRecord('referral', referralId, {
    receiving_program: get(params, 'referral.referred_to_program_id'),
    receiving_provider: groupId,
  });

  return (dispatch) => {
    function onSuccess(response) {
      dispatch({
        type: REASSIGN_REFERRAL_PROGRAM,
        payload: response,
      });

      Notifier.dispatch(response.status, 'Program Successfully Updated');

      return response;
    }

    function onError(error) {
      Notifier.handleErrors(error);
      return error;
    }

    return request
      .then(onSuccess, onError);
  };
}
