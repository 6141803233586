import React from 'react';
import Notifier from 'common/helpers/Notifier';
import './ClientProfileLink.scss';

const ClientProfileLink = () => {
  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      Notifier.dispatch('success', 'Client profile has been copied to clipboard.');
    });
  };

  return (
    <div className="client-profile-div">
      <h3 className="client-profile-link"><a onClick={handleCopy}>Copy Profile Link</a></h3>
    </div>
  );
};

export default ClientProfileLink;
