import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { Spinner } from 'common/spinners';
import ReferralCreateGroupDetailsHeader from './components/ReferralCreateGroupDetailsHeader';
import GroupDetailsContentV2 from '../components/GroupDetailsContentV2';
import '../stylesheets/groupDetails.scss';

const ReferralCreateGroupDetails = ({
  center,
  group,
  programs,
  selectedGroups,
  addOrRemoveGroup,
  serviceType = [],
  providerLocations,
  providerServiceAreas,
  selectedProvider: provider,
  serve,
  stateDisplayName,
}) => {
  if (_.isEmpty(group)) {
    return <Spinner />;
  }

  const selected = _.some(selectedGroups, { id: group.id });

  return (
    <div className="referral-create-group-details group-details">
      <ReferralCreateGroupDetailsHeader
        center={center}
        group={group}
        selected={selected}
        addOrRemoveGroup={addOrRemoveGroup}
        serviceType={serviceType}
      />
      {
        <GroupDetailsContentV2
          center={center}
          programs={programs}
          provider={provider}
          providerLocations={providerLocations}
          providerServiceAreas={providerServiceAreas}
          serve={serve}
          stateDisplayName={stateDisplayName}
        />
      }
    </div>
  );
};

ReferralCreateGroupDetails.propTypes = {
  center: PropTypes.object,
  group: PropTypes.object,
  programs: PropTypes.array,
  selectedGroups: PropTypes.array,
  addOrRemoveGroup: PropTypes.func.isRequired,
  selectedProvider: PropTypes.object.isRequired,
  serviceType: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  providerLocations: PropTypes.array.isRequired,
  providerServiceAreas: PropTypes.array.isRequired,
  serve: PropTypes.bool,
  stateDisplayName: PropTypes.string,
};

ReferralCreateGroupDetails.defaultProps = {
  center: {},
  group: {},
  programs: [],
  selectedGroups: [],
  serve: false,
  stateDisplayName: '',
};

export default ReferralCreateGroupDetails;
