import { some, has } from 'lodash';

export default function anyFieldChanged(fields = {}) {
  return some(fields, (field) => {
    if (!has(field, 'dirty')) {
      return anyFieldChanged(field);
    }
    return field.dirty;
  });
}
