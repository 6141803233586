import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OrganizationHeader from 'src/components/Organization/components/OrganizationHeader';
import { ORG_TABS } from 'src/components/Organization/constants';
import FormHeader from 'src/components/Organization/components/FormHeader';
import ProgramForm from 'src/components/Organization/components/ProgramForm';
import ProgramFormSubheader from 'src/components/Organization/components/ProgramForm/ProgramFormSubheader';
import {
  useFindProgram,
  useUniteUsServices,
  useLocations,
  useSubmitProgramAndLocations,
  useFindProgramServiceAreas,
  useFindProgramRequirementOptions,
} from 'src/components/Organization/api/hooks/v1';
import { Spinner } from 'src/common/spinners';
import { SERVICE_AREA_TYPE_NATIONAL, SERVICE_AREA_TYPE_STATE } from 'components/Groups/constants';
import { browserHistory } from 'common/utils/browserHistory';
import Notifier from 'common/helpers/Notifier';
import { getProgramServices } from 'src/components/Organization/services/apiDataTransform';
import { notifySomethingWentWrong } from 'src/components/Organization/api/hooks/v1/apiHookOptions';
import someEligibilityExists from 'src/components/Organization/utils/someProgramEligibilityExists';
import useTrackPageView from 'src/components/Organization/utils/useTrackPageView';
import { ORG_SETTINGS } from 'src/common/utils/EventTracker/utils/eventConstants';
import FormContainer from 'src/components/Organization/components/FormContainer';
import hasBackOfficeAccess from 'src/common/utils/BackOffice/backOffice';

const arrayToObject = (array) => array.reduce((acc, option) => (
  {
    ...acc,
    [option]: true,
  }
), {});

const ProgramEdit = (props) => {
  useTrackPageView(ORG_SETTINGS.editProgViewed);
  const {
    params: { program_id: programId },
    currentProviderId,
    userHasBackOffice,
    currentProviderName,
    location: windowLocation,
  } = props;

  const {
    data: apiProgram,
    isFetching: isFetchingProgram,
  } = useFindProgram({ programId });

  const {
    data: apiProgramLocations,
    isFetching: isFetchingProgramLocations,
  } = useLocations({ providerId: currentProviderId });

  const {
    data: apiProgramServiceAreas,
    isFetching: isFetchingProgramServiceAreas,
  } = useFindProgramServiceAreas({ programId });

  const {
    submit: updateProgram,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useSubmitProgramAndLocations();

  const {
    data: requirementOptions,
    isFetching: isFetchingRequirementOptions,
  } = useFindProgramRequirementOptions();

  const { data: services, isFetching: isFetchingServices } = useUniteUsServices();

  useEffect(() => {
    if (isUpdateSuccess) {
      Notifier.dispatch(
        200,
        'Program Successfully Updated',
      );
      browserHistory.push('/organization/settings/');
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isUpdateError) {
      notifySomethingWentWrong();
    }
  }, [isUpdateError]);

  if (isFetchingProgram ||
    isFetchingServices ||
    isFetchingProgramLocations ||
    isFetchingProgramServiceAreas ||
    isFetchingRequirementOptions) {
    return (<Spinner center />);
  }

  const serviceTypes = getProgramServices({
    allServices: services,
    programServiceIds: apiProgram?.services || [],
  });

  const initialServiceTypes = serviceTypes.reduce((acc, service) => {
    if (service.children.length) {
      return [
        ...acc,
        ...service.children,
      ];
    }

    return [
      ...acc,
      service,
    ];
  }, []);

  const filteredGenderOptions = requirementOptions
    ?.filter((opt) => opt.category === 'gender')
    ?.map((opt) => ({
      value: opt.id,
      display_name: opt.display_name,
      label: opt.display_name,
    }));

  const programRequirementIds = apiProgram.requirements?.map((reqs) => reqs.id);

  const initialGenders = filteredGenderOptions?.filter((opt) => programRequirementIds.includes(opt.value));

  const locations = apiProgramLocations.map((location) => ({
    location,
    selected: !!location?.programs.find((program) => program.id === programId),
    submitChange: false,
  }));

  const nationalServiceArea = apiProgramServiceAreas.find((sA) => sA.service_area_type === SERVICE_AREA_TYPE_NATIONAL);

  const program = {
    ...apiProgram,
    locations,
    service_areas_states: apiProgramServiceAreas.filter((sA) => sA.service_area_type === SERVICE_AREA_TYPE_STATE).map(
      (sA) => ({ ...sA, selected: true, submitChange: false }),
    ),
    service_areas_national: {
      selected: !!nationalServiceArea, submitChange: false, ...nationalServiceArea,
    },
    genders: initialGenders,
    services: initialServiceTypes,
    accessibility_options: apiProgram.accessibility_options,
    delivery_options: arrayToObject(apiProgram.delivery_options),
    payment_options: apiProgram.payment_options,
    transportation_options: apiProgram.transportation_options,
    status: apiProgram.receiving_referrals ? 'Accepting Referrals' : apiProgram.status,
  };

  const initialValues = {
    ...program,
    no_eligibility_requirements: !someEligibilityExists(program),
    email_addresses: (program.email_addresses || []).map((email) => ({ email_address: email })),
    phone_numbers: program.phone_numbers || [],
  };
  delete initialValues.permissions;
  delete initialValues.is_active;

  const onSubmit = (values) => {
    updateProgram(values, programId, userHasBackOffice, currentProviderName);
  };

  return (
    <>
      <OrganizationHeader currentTab={ORG_TABS.organization} location={windowLocation} />
      <FormContainer>
        <FormHeader
          headingText="Edit Program"
          cancelLink="/organization/settings"
        />
        <ProgramFormSubheader />
        <ProgramForm
          initialValues={initialValues}
          onSubmit={onSubmit}
        />
      </FormContainer>
    </>
  );
};

ProgramEdit.propTypes = {
  params: PropTypes.shape({
    program_id: PropTypes.string.isRequired,
  }).isRequired,
  currentProviderId: PropTypes.string.isRequired,
  currentProviderName: PropTypes.string.isRequired,
  userHasBackOffice: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currentProviderId: state.session.groupId,
  currentProviderName: state.session.currentProvider.name,
  userHasBackOffice: hasBackOfficeAccess(state),
});

export default connect(mapStateToProps)(ProgramEdit);
