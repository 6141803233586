import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import { PropTypes } from 'prop-types';
import { Icon } from '@unite-us/ui';
import { get, startCase, isEmpty } from 'lodash';
import moment from 'moment';
import { Card, CardDetail, CardHeader } from 'common/Card';
import { Spinner } from 'common/spinners';
import formatDate from 'common/utils/Dates/formatDate';
import getTimeDifference from 'common/utils/Dates/getTimeDifference';
import cx from 'classnames';
import { epochToDate } from 'common/utils/utils';
import { usePopulate } from 'src/api/APIHooks';
import { useFeatureFlag } from 'common/hooks';
import { ENROLLMENT_STATUSES, REQUEST_TYPES } from '../constants';
import getClientStatusText from '../utils/getClientStatusText';
import { useInsuranceExternalMemberID, useSccRequiredInsurancePlan } from '../hooks';
import AcceptEnrollmentRequestButton from './AcceptEnrollmentRequestButton';
import EnrollmentStatusIndicator from './EnrollmentStatusIndicator';
import RejectEnrollmentRequestButton from './RejectEnrollmentRequestButton';
import { NcTracksVerification } from './NcTracks/NcTracksVerification';
import { useFindEligibilityForInsurance } from '../hooks/useFindEligibilityForInsurance';

export const EnrollmentDrawerDetails = ({
  enrollmentRequest,
  setDrawerOpen,
  getNextRequest,
  getPrevRequest,
}) => {
  const linkSccToHealthInsuranceFlag = useFeatureFlag('pays-2199-temp-link-scc-to-health-insurance');
  const iconClass = 'fill-current text-grey h-18';
  const [isLoading, setLoading] = useState(true);

  const isDisenrollType = enrollmentRequest.request_type === REQUEST_TYPES.disenroll;
  const isOpenStatus = enrollmentRequest.enrollment_status === ENROLLMENT_STATUSES.open;

  usePopulate('insurance', 'insurance', enrollmentRequest, { queryConfig: { placeholderData: undefined } });
  usePopulate('person', 'person', enrollmentRequest, { queryConfig: { placeholderData: undefined } });
  usePopulate('plan', 'plan', enrollmentRequest, { queryConfig: { placeholderData: undefined } });
  usePopulate(
    'enrollment_disenroll_reason',
    'enrollment_disenroll_reason',
    enrollmentRequest,
    { queryConfig: { placeholderData: undefined } },
  );
  usePopulate(
    'enrollment_rejection_reason',
    'enrollment_rejection_reason',
    enrollmentRequest,
    { queryConfig: { placeholderData: undefined } },
  );
  usePopulate('requester', 'employee', enrollmentRequest, { queryConfig: { placeholderData: undefined } });
  usePopulate('provider', 'provider', enrollmentRequest.requester, { queryConfig: { placeholderData: undefined } });

  const isInsurancePopulated = !!enrollmentRequest.insurance?.state;
  const isPersonPopulated = !!enrollmentRequest.person?.last_name;
  const isPlanPopulated = !!enrollmentRequest.plan?.state;
  const isDisenrollReasonPopulated = (
    !enrollmentRequest.enrollment_disenroll_reason?.id || !!enrollmentRequest.enrollment_disenroll_reason?.code
  );
  const isRejectionReasonPopulated = (
    !enrollmentRequest.enrollment_rejection_reason?.id || !!enrollmentRequest.enrollment_rejection_reason?.code
  );

  const { externalMemberID: payerExternalMemberID } = useInsuranceExternalMemberID(
    enrollmentRequest?.person?.id,
    enrollmentRequest?.plan?.payer?.id,
    linkSccToHealthInsuranceFlag,
  );

  const { isLoading: isPrimaryInsuranceLoading, primaryInsurance } = useSccRequiredInsurancePlan(
    enrollmentRequest?.insurance?.primary_health_insurance?.id,
    enrollmentRequest?.person?.id,
    enrollmentRequest?.plan?.required_health_plans,
  );
  usePopulate('plan', 'plan', primaryInsurance, {
    queryConfig: {
      enabled: !isPrimaryInsuranceLoading,
      placeholderData: undefined,
    },
  });

  const externalMemberID = linkSccToHealthInsuranceFlag ? primaryInsurance?.external_member_id : payerExternalMemberID;

  const pays4252NcTracksVerification = useFeatureFlag(
    'pays-4252-temp-display-nctracks-verification-result-on-enrollment-request',
  );

  const fetchEligibilityRecord = !!primaryInsurance?.id && pays4252NcTracksVerification;
  const eligibility = useFindEligibilityForInsurance(primaryInsurance, fetchEligibilityRecord);

  const showNcTracksVerification = !!eligibility?.id;

  useEffect(() => {
    if (
      isInsurancePopulated &&
      isPersonPopulated &&
      isPlanPopulated &&
      isDisenrollReasonPopulated &&
      isRejectionReasonPopulated &&
      !isPrimaryInsuranceLoading
    ) {
      setLoading(false);
    }
  }, [
    isInsurancePopulated,
    isPersonPopulated,
    isPlanPopulated,
    isDisenrollReasonPopulated,
    isRejectionReasonPopulated,
    isPrimaryInsuranceLoading,
  ]);

  const rejectionReason = enrollmentRequest.enrollment_rejection_reason;
  const insuranceStatus = enrollmentRequest?.insurance?.insurance_status;

  const maybeDateToString = (maybeDate) => (maybeDate ? moment(maybeDate).utc().format('MM/DD/YYYY') : '');

  return (
    <>
      {isLoading ? (<Spinner />) : (
        <div className="pt-4" data-testid="enrollment-request-drawer">
          {/* Enrollment # Row */}
          <div className="flex">
            <div className="col-sm-9">
              <div className="flex pl-4 pt-2 text-xl font-extrabold text-text-blue space-x-2 items-center">
                {isDisenrollType ? (<div>Disenrollment Request</div>) : (<div>Enrollment Request</div>)}
                <Link
                  to={`/enrollments/${enrollmentRequest.id}`}
                  className="item-center underline"
                >
                  #{enrollmentRequest.short_id}
                </Link>
              </div>
            </div>
            <div className="col-xs-3">
              <AcceptEnrollmentRequestButton
                enrollmentRequest={enrollmentRequest}
                setDrawerOpen={setDrawerOpen}
              />
            </div>
          </div>

          {/* Enrollment Navigation Row */}
          <div className="flex py-2 -ml-2">
            <div className="col-xs-1">
              <button
                disabled={getPrevRequest === undefined}
                onClick={getPrevRequest}
                type="button"
                aria-label="go to previous request"
              >
                <Icon
                  className={cx(
                    iconClass,
                    getPrevRequest ? 'hover:text-dark-grey' : 'opacity-50 cursor-not-allowed',
                  )}
                  icon="V2ChevronLeft"
                />
              </button>
            </div>
            <div className="flex col-xs-10">
              <div className="-ml-5 pr-4 text-sm">
                Last updated: {formatDate(enrollmentRequest.updated_at)}
              </div>
              <div className="pl-2">
                <EnrollmentStatusIndicator
                  status={enrollmentRequest.enrollment_status}
                />
              </div>
            </div>
            <div className="col-xs-1 ml-2">
              <button
                disabled={getNextRequest === undefined}
                onClick={getNextRequest}
                type="button"
                aria-label="go to next request"
              >
                <Icon
                  className={cx(
                    iconClass,
                    getNextRequest ? 'hover:text-dark-grey' : 'opacity-50 cursor-not-allowed',
                  )}
                  icon="V2ChevronRight"
                />
              </button>
            </div>
          </div>

          <div className="flex px-8 py-2 space-x-4">
            <RejectEnrollmentRequestButton enrollmentRequest={enrollmentRequest} setDrawerOpen={setDrawerOpen} />
          </div>

          <div className="bg-gray-100 flex-1 px-3 py-3 border border-solid border-dark-border-blue">
            {enrollmentRequest?.enrollment_status === ENROLLMENT_STATUSES.rejected && (
              <Card borderColor="border-red" className="mb-3 px-4 py-2 bg-white">
                <CardHeader title="Rejection Details" titleIcon="V2Warning" iconColor="#CF2A2A" />
                <div className="py-4 space-y-2">
                  <CardDetail label="Rejection Reason">
                    {rejectionReason.display_name}
                  </CardDetail>
                  <CardDetail label="Note">
                    {enrollmentRequest.rejection_note}
                  </CardDetail>
                </div>
              </Card>
            )}

            {/* Client Details */}
            <Card className="px-4 py-2 bg-white">
              <CardHeader title="Client Details" />
              <div className="py-4 space-y-2">
                <CardDetail label="Client Name">
                  {get(enrollmentRequest, 'person.last_name')}, {get(enrollmentRequest, 'person.first_name')}
                </CardDetail>
                {(!linkSccToHealthInsuranceFlag || externalMemberID) && (
                  <CardDetail label="Client ID#">
                    {isEmpty(externalMemberID) ? 'No Client ID' : externalMemberID}
                  </CardDetail>
                )}
                {(linkSccToHealthInsuranceFlag && primaryInsurance) && (
                  <CardDetail label="Insurance Plan">
                    {primaryInsurance.plan?.name}
                  </CardDetail>
                )}
                <CardDetail label="Date of Birth">
                  {epochToDate(get(enrollmentRequest, 'person.date_of_birth'))}
                </CardDetail>
              </div>
            </Card>

            {/* Request Details */}
            <Card className="mt-3 px-4 py-2 bg-white">
              <CardHeader title="Request Details" />
              <div className="py-4 space-y-2">
                <CardDetail label="Request Type">{startCase(enrollmentRequest.request_type)}</CardDetail>
                <CardDetail label="Date of Request">{maybeDateToString(enrollmentRequest.created_at)}</CardDetail>
                {isOpenStatus &&
                  <CardDetail label="Time in Queue">{getTimeDifference(enrollmentRequest.created_at)}</CardDetail>}
                <CardDetail label="Submitted By">
                  {enrollmentRequest.requester?.full_name}
                  <br />
                  ({enrollmentRequest.requester?.provider?.name})
                </CardDetail>
                <CardDetail label="Request ID#">{enrollmentRequest.short_id}</CardDetail>
                {isDisenrollType && (
                  <>
                    <CardDetail label="Disenrollment Reason">
                      {get(enrollmentRequest, 'enrollment_disenroll_reason.display_name')}
                    </CardDetail>
                    <CardDetail label="Note">{enrollmentRequest.disenroll_note}</CardDetail>
                  </>
                )}
              </div>
            </Card>
            {
              showNcTracksVerification && (
                <Card className="mt-3 px-4 py-2 bg-white">
                  <CardHeader title="Insurance Verification" />
                  <NcTracksVerification eligibility={eligibility} />
                </Card>
              )
            }

            {/* Social Care Coverage Details */}
            <Card className="mt-3 px-4 py-2 bg-white">
              <CardHeader title="Social Care Coverage Details" />
              <div className="py-4 space-y-2">
                <CardDetail label="Social Care Coverage Plan">{get(enrollmentRequest, 'plan.name')}</CardDetail>
                { enrollmentRequest.insurance.enrolled_at && (
                  <CardDetail label="Start Date">
                    {maybeDateToString(enrollmentRequest.insurance?.enrolled_at)}
                  </CardDetail>
                )}
                { enrollmentRequest.insurance.expired_at && (
                  <CardDetail label="End Date">
                    {maybeDateToString(enrollmentRequest.insurance?.expired_at)}
                  </CardDetail>
                )}
                <CardDetail label="Social Care Coverage Status">
                  {getClientStatusText(insuranceStatus)}
                </CardDetail>
              </div>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

EnrollmentDrawerDetails.propTypes = {
  enrollmentRequest: PropTypes.object.isRequired,
  setDrawerOpen: PropTypes.func.isRequired,
  getNextRequest: PropTypes.func,
  getPrevRequest: PropTypes.func,
};

EnrollmentDrawerDetails.defaultProps = {
  getNextRequest: undefined,
  getPrevRequest: undefined,
};

export default EnrollmentDrawerDetails;
