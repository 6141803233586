import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Pager } from '@unite-us/ui';
import FeatureFlagContainer from 'src/common/utils/FeatureFlags/FeatureFlagContainer';
import { useFeatureFlag, useAuthorizeClientMerging } from 'common/hooks';
import { BackButton } from 'common/buttons';
import { Spinner } from 'common/spinners';
import { browserHistory } from 'common/utils/browserHistory';
import { useFind } from 'src/api/APIHooks';
import { DEFAULT_PAGE_SIZE } from 'src/components/Backoffice/backofficeUtils';
import { isOrgAdmin } from 'src/components/User/utils';

export const DuplicateClients = ({ userIsAdmin }) => {
  const isClientMergingEnabled = useFeatureFlag('cl-67-client-merging');
  const [pageNumber, setPageNumber] = useState(1);
  const { data, isFetching } = useFind('people/duplicates', {}, {
    page: {
      size: DEFAULT_PAGE_SIZE,
      number: pageNumber,
    },
    api: 'coreApi',
    queryConfig: {
      placeholderData: undefined,
      enabled: isClientMergingEnabled && userIsAdmin,
    },
  });

  const duplicatesClients = data?.data?.data;
  const paging = data?.data?.paging;

  useAuthorizeClientMerging(isClientMergingEnabled, userIsAdmin);

  const onBackClick = () => {
    browserHistory.push({
      pathname: '/dashboard/clients/all',
    });
  };

  const onViewDuplicatesClick = (person_id) => {
    browserHistory.push({
      pathname: `/people/duplicates/${person_id}`,
    });
  };

  const onNextClick = () => {
    setPageNumber(paging?.current_page + 1);
  };

  const onPrevClick = () => {
    setPageNumber(paging?.current_page - 1);
  };

  return (
    <div className="flex justify-center py-12">
      {isFetching ? <Spinner /> : (
        <div className="flex flex-col items-center w-full max-w-5xl space-y-4">
          <BackButton className="self-start" navigateTo={onBackClick} />
          <div className="w-full">
            {/* eslint-disable-next-line max-len */}
            <div className="flex items-center justify-between px-8 py-4 bg-white border border-solid rounded-t-md border-filter-border-color">
              <div>
                <h2 className="text-xl font-semibold text-brand-blue">Select Client to Review</h2>
                <p className="mt-1 text-sm text-brand-blue">
                  {paging.total_count} potential duplicate records found. Click View Duplicates
                  to review and merge records.
                </p>
              </div>
              <Pager
                paging={paging}
                disabled={isFetching}
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}
              />
            </div>
            <div className="bg-white border-b border-l border-r border-solid rounded-b-md border-filter-border-color">
              <ul className="p-8 space-y-4">
                {duplicatesClients.map((person) => (
                  <li key={person.id} className="flex items-center space-x-6">
                    {/* eslint-disable-next-line max-len */}
                    <div className="flex flex-col flex-1 max-w-lg px-4 py-3 space-y-2 border border-solid rounded-md border-filter-border-color">
                      <h3 className="text-lg font-semibold text-brand-blue">
                        {person.first_name} {person.last_name}
                      </h3>
                      <p>{person.quantity} records found</p>
                    </div>
                    <Button
                      className="primary-button"
                      label="View Duplicates"
                      primary
                      onClick={() => onViewDuplicatesClick(person.id)}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

DuplicateClients.propTypes = {
  userIsAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { session } = state;

  return {
    userIsAdmin: isOrgAdmin(state.user, session.groupId),
  };
};

export default connect(mapStateToProps)(FeatureFlagContainer(DuplicateClients));
