import { useFind } from 'src/api/APIHooks';

export const useServiceAuthorizationStatusCount = (state, payerId) => {
    const { data } = useFind(
      'service_authorization',
      {
        state,
        'insurance.plan.payer': payerId,
        'person.consent.state': 'accepted',
      },
      {
        page: {
          number: 1,
          size: 1,
        },
        queryConfig: {
          enabled: !!payerId,
          placeholderData: undefined,
        },
      },
    );

    const count = data?.data?.meta?.page?.total_count;
    return count === 0 ? null : count;
};
