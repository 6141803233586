import React from 'react';
import { PropTypes } from 'prop-types';
import { get } from 'lodash';
import { useCurrentProviderId } from 'common/contexts/CurrentProviderContext';
import { Card, CardSubHeader } from 'src/common/Card';
import { Spinner } from 'src/common/spinners';
import { Icon } from '@unite-us/ui';
import { Link } from 'react-router';
import useFormSubmissions from 'src/components/FormSubmissions/useFormSubmissions';

export function AssessmentFormsSection({
  enrollmentRequest,
}) {
  const context = enrollmentRequest.person.id;
  const contextType = 'person';
  const providerId = useCurrentProviderId();
  const { formSubmissions, isFetching } = useFormSubmissions({
    context,
    contextType,
    providerId,
  });

  // List most recent forms only
  const reducedFormSubmissions = formSubmissions.reduce((result, obj) => {
    const row = result.find((x) => x.form.id === obj.form.id);
    if (!row) { result.push({ ...obj }); } else if (row.created_at < obj.created_at) { Object.assign(row, obj); }
    return result;
  }, []);

  return (
    <Card className="mt-3 px-4 py-4 bg-white">
      <CardSubHeader>Assessment Forms</CardSubHeader>
      <hr className="pt-2 -mx-4 border-dark-border-blue" />
      {isFetching ? <Spinner /> : (
        <div className="py-4 pl-4 space-y-2">
          {
            reducedFormSubmissions.length ? (
              <div>
                {
                  reducedFormSubmissions.map((formSubmission) => (
                    <div className="flex" data-testid={`formSubmission:${formSubmission.id}`}>
                      <Icon
                        className="ml-2 py-1"
                        color="text-brand-blue"
                        icon="IconCircle"
                        size={18}
                      />
                      <Link
                        // eslint-disable-next-line max-len
                        to={`/enrollments/${enrollmentRequest.id}/person/${context}/forms/${formSubmission.form.id}/submissions/${formSubmission.id}`}
                        className="item-center px-2 text-base text-light-blue"
                      >
                        {get(formSubmission, 'form.name')}
                      </Link>

                    </div>
                  ))
                }
              </div>
            ) : <div className="pt-1" data-testid="no-assessments">No assessment forms available.</div>
          }
        </div>
      )}
    </Card>
  );
}

AssessmentFormsSection.propTypes = {
  enrollmentRequest: PropTypes.object.isRequired,
};

export default AssessmentFormsSection;
