import Notifier from 'common/helpers/Notifier';
import {
  FETCH_GROUP,
  SET_IS_FETCHING,
  UNSET_IS_FETCHING,
} from 'actions';
import { coreApi } from 'src/api/config';

export default function fetchGroup(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_IS_FETCHING });
      const response = await coreApi.findRecord('provider', id);
      const provider = response.data.data;
      await coreApi.populateRelationship('networks', 'network', provider);

      dispatch({
        type: FETCH_GROUP,
        payload: response,
      });

      // if this is NOT commented out, then fetchDashboardAssistanceRequests + all other dashboard
      // will have NO SERVICE TYPES in the service types filter, for some reason:
      // dispatch({ type: BUILD_SERVICE_TYPES, globalState: getState() });
      dispatch({ type: UNSET_IS_FETCHING });
      return response;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}
