import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import ReferralRowIcon from 'src/components/Dashboard/components/Referrals/ReferralRowIcon';
import { DynamicTable } from 'common/tables';
import { shapes } from '@unite-us/client-utils';
import { Spinner } from 'common/spinners';
import FilterBar from '../FilterBar';

function getColumns() {
  return [
    {
      label: 'Sender',
      sortColumn: 'referred_by_group.name',
    },
    {
      label: 'Client Name',
      sortColumn: 'contact.full_name',
    },
    {
      label: 'Service Type',
      sortColumn: 'service_type.name',
    },
    {
      label: 'Date Created',
      sortColumn: 'created_at',
      type: 'date',
      align: 'left',
    },
    {
      label: 'Last Updated',
      sortColumn: 'updated_at',
      type: 'date',
    },
  ];
}

export default function ReferralsInReviewIndex(props) {
  const {
    paging,
    data,
    onNextClick,
    onPrevClick,
    isFetching,
    onCellClick,
    initialSort,
    filters,
    onFiltersChange,
    fetchGroupsUsers,
  } = props;

  return (
    <div>
      <FilterBar
        filters={filters}
        onFiltersChange={onFiltersChange}
        paging={paging}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
        isFetching={isFetching && !_.isEmpty(data)}
        asyncSearch={fetchGroupsUsers}
      />
      <div className="dashboard-inner-content">
        {
          (isFetching && _.isEmpty(data)) ?
            <Spinner /> : (
              <DynamicTable
                id="referrals-in-review-table"
                dataSet={data}
                onCellClick={onCellClick}
                initialSort={initialSort}
                columnLabels={getColumns()}
                emptyMessage="There are no referrals in review."
                rowIcon={(referral) => <ReferralRowIcon referral={referral} />}
              />
            )
        }
      </div>
    </div>
  );
}

ReferralsInReviewIndex.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  paging: shapes.paging,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  onCellClick: PropTypes.func.isRequired,
  initialSort: PropTypes.object,
  filters: PropTypes.array,
  onFiltersChange: PropTypes.func,
  fetchGroupsUsers: PropTypes.func.isRequired,
};
