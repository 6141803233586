import React from 'react';
import PropTypes from 'prop-types';
import { OverlaySpinner } from 'common/spinners';
import TotalUniqueCases from 'src/components/Reports/components/ServiceReports/TotalUniqueCases';
import CasesBySource from 'src/components/Reports/components/ServiceReports/CasesBySource';
import CasesByServiceType from 'src/components/Reports/components/ServiceReports/CasesByServiceType';
import CasesByStatus from 'src/components/Reports/components/ServiceReports/CasesByStatus';
import CasesByServiceTypeAndResolution from 'src/components/Reports/components/ServiceReports/CasesByServiceTypeAndResolution';
import { DOMAIN_CONSTANTS } from 'src/common/constants';
import _ from 'lodash';

export default class ServicesReport extends React.Component {
  constructor(props) {
    super(props);
    this.isLoading = this.isLoading.bind(this);
    this.chartLoaded = this.chartLoaded.bind(this);

    this.state = {
      loaded: {
        totalUniqueCases: false,
        casesBySource: false,
        casesByServiceType: false,
        casesByStatus: false,
        casesByServiceTypeAndResolution: false,
      },
    };
  }

  isLoading() {
    return _.some(_.values(this.state.loaded), (v) => !v);
  }

  chartLoaded(key) {
    const loaded = _.merge(this.state.loaded, { [key]: true });
    this.setState({ loaded });
  }


  render() {
    const { groupId, serviceTypes, filters } = this.props;

    return (
      <div>
        <OverlaySpinner
          show={this.isLoading()}
          text="Loading"
          animationTimeout={1}
        />
        <div className="row">
          <div className="col-sm-6">
            <TotalUniqueCases
              loaded={this.chartLoaded}
              id={groupId}
              scope={DOMAIN_CONSTANTS.GROUPS}
              filters={filters}
            />
          </div>
          <div className="col-sm-6">
            <CasesBySource
              loaded={this.chartLoaded}
              id={groupId}
              scope={DOMAIN_CONSTANTS.GROUPS}
              filters={filters}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <CasesByServiceType
              loaded={this.chartLoaded}
              id={groupId}
              scope={DOMAIN_CONSTANTS.GROUPS}
              filters={filters}
            />
          </div>
          <div className="col-sm-6">
            <CasesByStatus
              loaded={this.chartLoaded}
              id={groupId}
              scope={DOMAIN_CONSTANTS.GROUPS}
              filters={filters}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <CasesByServiceTypeAndResolution
              loaded={this.chartLoaded}
              id={groupId}
              scope={DOMAIN_CONSTANTS.GROUPS}
              serviceTypes={serviceTypes}
              filters={filters}
            />
          </div>
        </div>
      </div>
    );
  }
}

ServicesReport.propTypes = {
  filters: PropTypes.shape({
    end_date: PropTypes.string,
    start_date: PropTypes.string,
  }),
  groupId: PropTypes.string,
  serviceTypes: PropTypes.array,
};
