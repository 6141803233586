import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useReasonNote } from 'src/components/Notes/queries';
import './ReasonNote.scss';

const ReasonNote = ({
  referral, providerId,
}) => {
  const note = useReasonNote(referral, providerId);
  const truncatedNote = _.truncate(_.get(note, 'text', ''), { length: 150 });

  return (
    <div className="reason-container">
      <p className="reason-paragraph">
        Reason: {truncatedNote}
      </p>
    </div>
  );
};

ReasonNote.propTypes = {
  referral: PropTypes.object.isRequired,
  providerId: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  referral: ownProps.referral,
  providerId: ownProps.providerId,
});

export default connect(mapStateToProps)(ReasonNote);
