import PropTypes from 'prop-types';
import React from 'react';
import DashboardRowIcon from 'src/components/Dashboard/components/DashboardRowIcon';
import { getReferralIconAndTooltip } from 'src/components/Referrals/utils';

const ReferralRowIcon = ({ referral, style }) => {
  const { icon, iconTooltip } = getReferralIconAndTooltip({ referral, style });
  return (
    <DashboardRowIcon
      iconTooltip={iconTooltip}
      permissions={referral.permissions}
    >
      {icon}
    </DashboardRowIcon>
  );
};

ReferralRowIcon.propTypes = {
  referral: PropTypes.shape({
    permissions: PropTypes.object.isRequired,
  }).isRequired,
  style: PropTypes.object.isRequired,
};

ReferralRowIcon.defaultProps = {
  style: {
    svg: {
      width: '14px',
    },
  },
};

export default ReferralRowIcon;
