import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import DateOfBirth from 'common/display/DateOfBirth';
import { validateReduxForm } from 'common/form';
import {
  Button,
} from '@unite-us/ui';
import { getEnumsFromState } from 'common/utils/Enums';
import { hasLimitedGenderOptions } from 'common/utils/FeatureFlags/flags';
import Header from './Header';
import ContactDetailReviewDemographic from './ContactDetailReviewDemographic';
import ContactDataAsDisplay from './ContactDataAsDisplay';
import '../stylesheets/contactStepper.scss';

class ContactDetailReview extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.complete = this.complete.bind(this);
    this.restartContactForm = this.restartContactForm.bind(this);
  }

  onSubmit() {
    this.complete();
  }

  complete() {
    this.props.onComplete();
  }

  restartContactForm() {
    if (this.props.onGoBack) {
      return this.props.onGoBack();
    }
    return browserHistory.push('/contacts/new/search');
  }

  render() {
    const {
      handleSubmit,
      submitting,
      contact,
      header,
      mainHeader,
      subHeader,
      enums,
      limitedGenderOptions,
    } = this.props;

    return (
      <form
        className="contact-details-review"
        onSubmit={handleSubmit(this.onSubmit)}
      >
        <Header
          header={header}
          mainHeader={mainHeader}
          subHeader={subHeader}
        />

        <div className="confirmed-details">
          <div className="confirmed-details__wrapper">
            <div className="confirmed-details__name confirmed-details__row">
              <span className="strong">Name: </span>
              <span> {contact.full_name}</span>
            </div>

            {
              contact.date_of_birth && (
                <div className="confirmed-details__dob confirmed-details__row">
                  <span className="strong">Date of Birth</span>
                  <div>
                    <DateOfBirth date={contact.date_of_birth} />
                  </div>
                </div>
              )
            }

            <div className="confirmed-details__contact-data confirmed-details__row">
              <ContactDataAsDisplay fields={this.props.fields} />
            </div>

            <ContactDetailReviewDemographic
              enums={enums}
              fields={this.props.fields}
              limitedGenderOptions={limitedGenderOptions}
            />
          </div>
        </div>

        <footer className="referral-footer">
          <Button
            id="go-back-btn"
            onClick={this.restartContactForm}
            label="Go Back"
            style={{ marginRight: '10px' }}
          />
          <Button
            id="save-and-continue-btn"
            onClick={handleSubmit(this.onSubmit)}
            label="Save and Continue"
            disabled={submitting}
            style={{ float: 'right' }}
            primary
          />
        </footer>
      </form>
    );
  }
}

ContactDetailReview.propTypes = {
  contact: PropTypes.object.isRequired,
  enums: PropTypes.object,
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  header: PropTypes.string.isRequired,
  mainHeader: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
  onGoBack: PropTypes.func,
  subHeader: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
  limitedGenderOptions: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const { selectedContact, session: { groupId } } = state;
  const allContacts = state.searchedContacts.concat(state.contacts.contacts);
  const contact = allContacts
    .find((person) => person.id === selectedContact);
  return {
    groupId,
    selectedContact,
    contact,
    enums: getEnumsFromState(state),
    initialValues: {
      email: (contact.email_addresses.find((email) => email.is_primary) || {}).email_address,
      phone: (contact.phone_numbers.find((phone) => phone.is_primary) || {}).phone_number,
      phone_type: (contact.phone_numbers.find((phone) => phone.is_primary) || {}).phone_type,
      gender: contact.gender,
      marital_status: contact.marital_status,
      race: contact.race,
      ethnicity: contact.ethnicity,
    },
    limitedGenderOptions: hasLimitedGenderOptions(state),
  };
}

export const fields = [
  'email',
  'phone',
  'phone_type',
  'gender',
  'marital_status',
  'race',
  'ethnicity',
];
export default validateReduxForm(
  {
    form: 'contact',
    fields,
    destroyOnUnmount: true,
  },
  mapStateToProps,
  null,
)(ContactDetailReview);
