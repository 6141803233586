import _ from 'lodash';

function sortWithNoResponseLast(values) {
  return values.sort((a, b) => {
    if (_.toLower(a) === 'no_response') {
      return 1;
    }
    if (_.toLower(b) === 'no_response') {
      return -1;
    }
    return (_.toUpper(a) < _.toUpper(b)) ? -1 : 1;
  });
}

export default sortWithNoResponseLast;
