import PropTypes from 'prop-types';

export function EligibilityAssessmentsRoute({
  children,
}) {
  return children;
}

EligibilityAssessmentsRoute.propTypes = {
  children: PropTypes.node,
};

EligibilityAssessmentsRoute.defaultProps = {
  children: null,
};

export default EligibilityAssessmentsRoute;
