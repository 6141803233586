import { FETCH_BOOTSTRAP_ENUMS, BUILD_SERVICE_TYPES } from 'actions';
import { coreApi } from 'src/api/config';
import mapServiceResponse from 'src/common/utils/ServiceTypes/mapServiceResponse';
import FeatureFlags from 'src/common/utils/FeatureFlags';
import { includePathwaysServices } from 'src/common/utils/FeatureFlags/flags';
import Notifier from '../../../helpers/Notifier';

export default function fetchBootstrapEnums() {
  return async (dispatch, getState) => {
    try {
      const state = getState();

      const response = await coreApi.query('service', {
        taxonomy: 'uniteus',
        include_pathways: includePathwaysServices(state),
      }, {
        page: {
          number: 1,
          size: 300,
        },
      });

      // building service_types with children
      const services = mapServiceResponse(response);

      const payload = {
        global: {
          service_types: services,
        },
      };
      response.data.data = payload;

      dispatch({ type: FETCH_BOOTSTRAP_ENUMS, payload: response });
      dispatch({ type: BUILD_SERVICE_TYPES, globalState: state });

      return response;
    } catch (err) {
      Notifier.handleErrors(err);
      return err;
    }
  };
}

FeatureFlags.onChange('service-types-include-pathways', (dispatch) => dispatch(fetchBootstrapEnums()));
