import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { FETCH_EMPLOYEES_ERROR } from 'src/actions';
import getCoreError from 'src/api/utils/getCoreError';
import { coreApi } from 'src/api/config';

const onError = (dispatch, error) => {
  dispatch({ type: FETCH_EMPLOYEES_ERROR });
  Notifier.handleErrors(getCoreError(error));
};

export default function fetchEmployees({
  userId,
  providers,
  programs,
  networks = undefined,
  query = '',
  page = 1,
  size = 50,
  licensed = false,
  sort = undefined,
}) {
  return async (dispatch) => {
    try {
      const response = await coreApi.query('employee', {
        user: userId,
        provider: providers,
        programs,
        query,
        ...(networks ? { 'provider.networks': networks } : {}),
        ...(licensed ? { state: 'active' } : {}),
      }, {
        page: {
          number: page,
          size,
        },
        sort,
      });
      if (!response || !isHttpSuccess(response.status)) {
        return onError(dispatch, 'Failed to get employees');
      }
      const { data: { data: employees } } = response;
      coreApi.populateRelationship('provider', 'provider', employees);
      coreApi.populateRelationship('roles', 'roles', employees);
      return { employees, response };
    } catch (error) {
      onError(dispatch, error);
      throw error;
    }
  };
}
