import { every } from 'lodash';
import { isReferralsAdminAndAbove } from 'src/components/User/utils';

export default function canHoldForReview({ user, referral, groupId }) {
  const {
    state: referralState,
    case: {
      outcome: caseOutcome,
      state: caseState,
    },
  } = referral;

  if (caseState === 'draft') {
    return null;
  }

  const checks = [
    isReferralsAdminAndAbove(user, groupId),
    ['sent'].includes(referralState),
    referral.receiving_provider.id === groupId,
    caseOutcome === null,
  ];

  return every(checks);
}
