import { isFunction } from 'lodash';

function createMapSearchButton(map, handleMapSearch) {
  const maps = window.google.maps;
  const mainDiv = document.createElement('div');
  const controlUI = document.createElement('div');

  controlUI.style.backgroundColor = '#2C405A';
  controlUI.style.border = '2px solid #2C405A';
  controlUI.style.borderRadius = '4px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.marginBottom = '22px';
  controlUI.style.marginTop = '6px';
  controlUI.style.textAlign = 'center';
  controlUI.style.textAlign = 'center';
  controlUI.title = 'Click to search the current area';
  mainDiv.appendChild(controlUI);

  const controlText = document.createElement('div');
  controlText.style.color = '#fff';
  controlText.style.fontSize = '13px';
  controlText.style.fontWeight = '400';
  controlText.style.lineHeight = '30px';
  controlText.style.paddingLeft = '15px';
  controlText.style.paddingRight = '15px';
  controlText.innerHTML = 'SEARCH THIS AREA';
  controlUI.appendChild(controlText);

  mainDiv.addEventListener('click', () => {
    if (isFunction(handleMapSearch)) {
      handleMapSearch();
    }
  });

  mainDiv.style.marginTop = '10px';
  mainDiv.style.marginRight = '20px';
  mainDiv.index = 1;

  map.controls[maps.ControlPosition.TOP_RIGHT].push(mainDiv);
}

export default createMapSearchButton;
