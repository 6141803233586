import { isEmpty, some } from 'lodash';
import { getMessageEnabled } from '.';

function someMessageEnabledPhoneOrEmail(contact = {}) {
  if (isEmpty(contact)) {
    return false;
  }
  return some([
    getMessageEnabled(contact.email_addresses).length > 0,
    getMessageEnabled(contact.phone_numbers).length > 0,
  ]);
}

export default someMessageEnabledPhoneOrEmail;
