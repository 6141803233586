import React from 'react';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
} from '@unite-us/app-components';
import PropTypes from 'prop-types';
import FeeScheduleScreeningProgramsTable from './FeeScheduleScreeningProgramsTable';

const FeeScheduleScreeningPrograms = ({ feeScheduleScreening }) => (
  <div className="fee-schedule-base-card">
    <BaseCard className="p-6" noBorder>
      <BaseCardHeader title="Programs" noBorder className="px-6 font-bold" />
      <BaseCardBody className="px-6">
        <FeeScheduleScreeningProgramsTable feeScheduleScreeningId={feeScheduleScreening.id} />
      </BaseCardBody>
    </BaseCard>
  </div>
);

FeeScheduleScreeningPrograms.propTypes = {
  feeScheduleScreening: PropTypes.object.isRequired,
};

export default FeeScheduleScreeningPrograms;
