import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { Icon } from '@unite-us/ui';

const IconNavigationLink = ({ path, icon, label }) => (
  <Link
    activeClassName="bg-dark-grey"
    to={path}
    className="flex items-center py-2 px-3 rounded-md space-x-4 hover:bg-dark-grey focus:bg-dark-grey"
  >
    <Icon color="white" icon={icon} />
    <span className="leading-6 font-medium-font text-white text-base">{label}</span>
  </Link>
);

IconNavigationLink.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default IconNavigationLink;
