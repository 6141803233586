import { get, isEmpty } from 'lodash';
import {
  CLEAR_NETWORK_BROWSE_GROUP,
  SET_NETWORK_BROWSE_GROUPS_FETCHING,
  UNSET_NETWORK_BROWSE_GROUPS_FETCHING,
  UNSET_NETWORK_BROWSE_GROUPS_FETCHING_CASES,
  SEARCH_NETWORK_BROWSE_GROUPS,
  CLEAR_NETWORK_BROWSE_FILTERS,
  CLEAR_NETWORK_BROWSE_GROUPS,
  CLEAR_NETWORK_BROWSE_GROUP_PROGRAMS,
  FETCH_NETWORK_BROWSE_GROUP_PROGRAMS,
  SET_BROWSE_GEOGRAPHY,
} from 'actions';

export const defaultFilters = {
  accessibility: [],
  states: [],
  counties: [],
  cities: [],
  catersTo: [],
  distance: '',
  feeScheduleIds: [],
  languages: [],
  networks: [],
  serviceTypes: [],
  text: '',
};

export const defaultState = {
  currentGroup: null,
  currentGroupId: '',
  isFetching: true,
  currentGroupPrograms: [],
  groups: [],
  paging: {},
  filters: defaultFilters,
  cancel: null,
  geography: {},
  groupsServiceArea: [],
  pagingServiceArea: {},
  isFetchingServiceArea: false,
  stateDisplayName: '',
};

function appendOrReplaceGroups(page, payload, currentGroups) {
  const group = get(payload, 'data.data', []);

  return page === 1 ?
    group : [
      ...currentGroups,
      ...group,
    ];
}

function cancelPreviousRequestIfExists(state) {
  const cancel = get(state, 'cancel', null);

  if (cancel) {
    cancel();
  }
}

function setGeography(payload, state) {
  const geography = payload.reduce((acc, curr) => ({
    ...acc,
    [curr.state.name]: {
      counties: isEmpty(curr.counties) ? get(state.geography, `${curr.state.name}.counties`) : curr.counties,
      places: isEmpty(curr.places) ? get(state.geography, `${curr.state.name}.places`) : curr.places,
    },
  }), {});

  return {
    ...state,
    geography,
  };
}

export default function browseReducer(state = defaultState, action) {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case SET_BROWSE_GEOGRAPHY: {
      return setGeography(payload, state);
    }
    case CLEAR_NETWORK_BROWSE_GROUPS:
      return {
        ...state,
        groups: [],
        paging: {},
        groupsServiceArea: [],
        pagingServiceArea: {},
      };
    case CLEAR_NETWORK_BROWSE_FILTERS: {
      return {
        ...state,
        filters: defaultFilters,
      };
    }
    case CLEAR_NETWORK_BROWSE_GROUP:
      return {
        ...state,
        currentGroup: null,
        currentGroupId: '',
        currentGroupPrograms: [],
      };
    case SET_NETWORK_BROWSE_GROUPS_FETCHING: {
      cancelPreviousRequestIfExists(state);

      if (action.list === 'serviceArea') {
        return {
          ...state,
          isFetchingServiceArea: true,
        };
      }

      return {
        ...state,
        isFetching: true,
        cancel: action.cancel,
      };
    }

    case UNSET_NETWORK_BROWSE_GROUPS_FETCHING_CASES: {
      cancelPreviousRequestIfExists(state);

      return {
        ...state,
        cancel: null,
        groups: payload.data.data, // dropdown options
        isFetching: false,
      };
    }

    case UNSET_NETWORK_BROWSE_GROUPS_FETCHING: {
      if (action.list === 'serviceArea') {
        return {
          ...state,
          isFetchingServiceArea: false,
        };
      }

      return {
        ...state,
        cancel: null,
        isFetching: false,
      };
    }

    case CLEAR_NETWORK_BROWSE_GROUP_PROGRAMS:
      return {
        ...state,
        currentGroupPrograms: [],
      };

    case FETCH_NETWORK_BROWSE_GROUP_PROGRAMS:
      return {
        ...state,
        currentGroupPrograms: payload,
      };

    case SEARCH_NETWORK_BROWSE_GROUPS: {
      const paging = get(payload, 'data.paging', {});
      const page = paging.current_page;

      if (action.list === 'serviceArea') {
        return {
          ...state,
          groupsServiceArea: appendOrReplaceGroups(page, payload, state.groupsServiceArea),
          pagingServiceArea: paging,
          stateDisplayName: action?.stateDisplayName,
        };
      }
      return {
        ...state,
        groups: appendOrReplaceGroups(page, payload, state.groups),
        filters: get(action, 'filters', {}),
        paging,
      };
    }

    default:
      return state;
  }
}
