export const SEND_REFERRAL_FORM = 'sendReferralForm';
export const OUT_OF_NETWORK_REFERRAL_FORM = 'outOfNetworkReferralForm';
export const PRIMARY_WORKER_REFERRAL_FORM = 'primaryWorkerReferralForm';
export const CLOSE_REFERRAL_FORM = 'closeReferralForm';

export const ACCEPT_MODAL_DIALOG = 'acceptModalDialog';
export const REJECT_MODAL_DIALOG = 'rejectModalDialog';
export const SEND_MODAL_DIALOG = 'sendModalDialog';
export const HOLD_MODAL_DIALOG = 'holdModalDialog';
export const OUT_OF_NETWORK_MODAL_DIALOG = 'outOfNetworkModalDialog';
export const RECALL_MODAL_DIALOG = 'recallModalDialog';
export const CLOSE_REFERRAL_MODAL_DIALOG = 'closeModalDialog';
export const INACTIVE_PROGRAM_DIALOG = 'inactiveProgramDialog';
export const INACTIVE_REFERRAL_DIALOG = 'inactiveReferralDialog';

export const REFERRAL_ALREADY_ACCEPTED_MESSAGE =
  'Sorry, this referral was accepted by another recipient. No further action is needed from you at this time.';

export default [
  SEND_REFERRAL_FORM,
  OUT_OF_NETWORK_REFERRAL_FORM,
  PRIMARY_WORKER_REFERRAL_FORM,
];
