function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { findIndex, isArray, reduce } from 'lodash';
import flattenServiceTypes from "./flattenServiceTypes";
var matchingServiceTypes = function matchingServiceTypes(selectedServiceTypes, providerServiceTypes) {
  if (!isArray(selectedServiceTypes)) {
    return [selectedServiceTypes];
  }
  if (selectedServiceTypes.length === 1) {
    return selectedServiceTypes;
  }
  var flattenedProviderServiceTypes = flattenServiceTypes(providerServiceTypes, true);
  return reduce(selectedServiceTypes, function (acc, st) {
    if (findIndex(flattenedProviderServiceTypes, {
      id: st.id
    }) >= 0) {
      return [].concat(_toConsumableArray(acc), [st]);
    }
    return acc;
  }, []);
};
export default matchingServiceTypes;