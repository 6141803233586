import { filter } from 'lodash';
import { isOONCaseUser } from 'src/components/User/utils';
import { programOptions } from 'src/components/Cases/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { SelectField } from '@unite-us/ui';
import { validations } from '@unite-us/app-components';
import featureFlag from 'src/common/utils/FeatureFlag/FeatureFlag';

export const CaseProgramField = ({
  fields,
  groupId,
  isCoordinationGroup,
  programmingOptionsArray,
  registerField,
  user,
  ...props
}) => {
  const userCanReferOutOfNetwork = isCoordinationGroup || isOONCaseUser(user, groupId);

  const filteredProgrammingOptionsArray = userCanReferOutOfNetwork && fields.case_type.value.type !== 'org_case' ?
    programmingOptionsArray :
    filter(programmingOptionsArray, (program) => program.attributes.default === false);
  return (
    <SelectField
      ref={registerField}
      options={programOptions(filteredProgrammingOptionsArray)}
      validations={validations.isRequired}
      {...props}
    />
  );
};

CaseProgramField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  field: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  forceObjectValue: PropTypes.bool,
  groupId: PropTypes.string.isRequired,
  id: PropTypes.string,
  inline: PropTypes.bool,
  isCoordinationGroup: PropTypes.bool.isRequired,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  programmingOptionsArray: PropTypes.array.isRequired,
  registerField: PropTypes.func.isRequired,
  required: PropTypes.bool,
  user: PropTypes.shape({
    networks: PropTypes.array.isRequired,
  }).isRequired,
  valueKey: PropTypes.string,
};

CaseProgramField.defaultProps = {
  id: 'program',
  label: 'Program',
  labelKey: 'name',
  valueKey: 'id',
  forceObjectValue: true,
  inline: false,
  placeholder: 'Choose one...',
  required: true,
};

export default featureFlag(CaseProgramField);
