export default function findStatusConfigs(status) {
  switch (status) {
    case 'declined':
      return {
        icon: 'IconTimesCircle',
        color: 'black',
      };
    case 'pending':
      return {
        icon: 'IconQuestionCircle',
        color: 'tangie',
      };
    case 'accepted':
      return {
        icon: 'IconCheckCircle',
        color: 'avocado',
      };
    default:
      return {
        icon: 'IconExclamationCircle',
        color: 'rosso',
      };
  }
}
