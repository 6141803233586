import { useFind } from 'src/api/APIHooks';
import { get } from 'lodash';

const useInvoiceFixVersion = (invoice = {}, showPayerInvoices) => {
  const { isFetching, data: fixVersionInvoicesData } = useFind(
    'invoices',
    {
      payer: invoice.payer_id,
      original_version: invoice.id,
    },
    {
      queryConfig: { enabled: (!!invoice && showPayerInvoices) },
    },
  );
  const fixVersionInvoices = get(fixVersionInvoicesData, 'data.data', []);

  return isFetching ? [] : fixVersionInvoices;
};

export default useInvoiceFixVersion;
