import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import { useFindRecord } from '../../api/APIHooks';
import { ErrorPage } from '../ErrorPage/ErrorPage';

const CurrentProviderContext = createContext();

/**
 *
 * @returns {string | undefined}
 */
export const useCurrentProviderId = () => {
  const currentProvider = useContext(CurrentProviderContext);

  return currentProvider?.id;
};

/**
 *
 * @returns {boolean}
 */
export const useIsPayer = () => {
  const currentProvider = useContext(CurrentProviderContext);

  return currentProvider?.provider_type === 'payer';
};

/**
 *
 * @returns {boolean}
 */
export const useIsNetworkLead = () => {
  const currentProvider = useContext(CurrentProviderContext);

  return currentProvider?.provider_type === 'network_lead';
};

/**
 *
 * @returns {string | undefined}
 */
export const useIsCBOProvider = () => {
  const currentProvider = useContext(CurrentProviderContext);

  return currentProvider?.provider_type === 'provider';
};

/**
 *
 * @returns {string | undefined}
 */
export const useCurrentPayerId = () => {
  const currentProvider = useContext(CurrentProviderContext);

  return currentProvider?.payer?.id;
};

/**
 *
 * @returns {string | undefined}
 */
export const useCurrentNetworkId = () => {
  const currentProvider = useContext(CurrentProviderContext);

  return currentProvider?.networks?.[0]?.id;
};

export const CurrentProviderContextProvider = ({ providerId, children }) => {
  const {
    data, isSuccess, isError, error,
  } = useFindRecord(
    'provider',
    providerId,
    { queryConfig: { enabled: !!providerId, placeholderData: undefined } },
  );

  if (isError) {
    // eslint-disable-next-line no-console
    console.error('Failed to fetch current provider.\n', error);
    return <ErrorPage />;
  }

  return isSuccess && (
    <CurrentProviderContext.Provider value={data?.data?.data}>
      {children}
    </CurrentProviderContext.Provider>
  );
};

CurrentProviderContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  providerId: PropTypes.string.isRequired,
};

export default CurrentProviderContext;
