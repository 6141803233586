import _ from 'lodash';
import findCurrentGroup from '../findCurrentGroup';

export function hasGroupLicense(group) {
  if (_.isEmpty(group)) {
    return false;
  }
  const groupLicense = _.find(group.licensing, { subscription_type: 'group' });
  return !_.isEmpty(groupLicense);
}

export function hasNetworkLicense(group) {
  if (_.isEmpty(group)) {
    return false;
  }
  const networkLicense = _.find(group.licensing, { subscription_type: 'network' });
  return !_.isEmpty(networkLicense);
}

export function isCaseManagementProvider(state) {
  const userState = _.get(state, 'user', {});
  const sessionState = _.get(state, 'session', {});
  const group = findCurrentGroup(userState, sessionState);

  return hasGroupLicense(group) && hasNetworkLicense(group);
}
