import moment from 'moment';
import { UPDATE_SERVICE_CASE } from 'actions';
import Notifier from 'common/helpers/Notifier';
import _ from 'lodash';
import { coreApi } from 'src/api/config';

export default function updateServiceCase(groupId, contactId, caseId, serviceCase) {
  return async (dispatch) => {
    try {
      // leaving these comments here -
      // this file is used in other places and would like to keep track of the attributes it originally changed
      // working on it right now
      // const serviceCasePayload = _.uuCompactArrayOrObject({
      //   opened_date: _.get(serviceCase, 'opened_date'),
      //   description: _.get(serviceCase, 'service_case.description'),
      //   state: _.get(serviceCase, 'service_case.state'),
      //   primary_worker: _.get(serviceCase, 'service_case.primary_worker_id'),
      // });
      const opened_date = _.get(serviceCase, 'opened_date', null);
      const closed_date = _.get(serviceCase, 'closed_date', null);
      const description = _.get(serviceCase, 'service_case.description', null);
      const primary_worker = _.get(serviceCase, 'service_case.primary_worker_id');
      const serviceCasePayload = {
        ...(opened_date ? { opened_date: moment.unix(opened_date).utc().format('YYYY-MM-DD') } : {}),
        ...(closed_date ? { closed_date: moment.unix(closed_date).utc().format('YYYY-MM-DD') } : {}),
        ...(description ? { description } : null),
        ...(primary_worker ? { primary_worker } : null),
      };

      const payload = await coreApi.updateRecord('case', caseId, serviceCasePayload);

      dispatch({
        type: UPDATE_SERVICE_CASE,
        payload,
        contactId,
      });

      Notifier.dispatch(payload.status, 'Case Successfully Updated');
      return payload;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}
