import PropTypes from 'prop-types';
import React from 'react';
import ReferralRowIcon from 'src/components/Dashboard/components/Referrals/ReferralRowIcon';
import getReferralRecipientDisplayName from 'src/components/Dashboard/utils/getReferralRecipientDisplayName';
import { DynamicTable } from 'common/tables';
import { shapes, Filter } from '@unite-us/client-utils';
import { Drawer } from '@unite-us/ui';
import { Spinner } from 'common/spinners';
import { TOP_NAVIGATION_HEIGHT } from 'src/components/Navigation/constants';
import _ from 'lodash';
import FilterBar from '../FilterBar';
import { setFilterSearchPlaceholder, setAsyncSearch } from '../../utils/filter';
import './ClosedReferralsIndex.scss';

function getColumns() {
  return _.compact([
    {
      label: 'Recipient',
      formatData: getReferralRecipientDisplayName,
      sortColumn: 'referred_to_group.name',
    },
    {
      label: 'Client Name',
      sortColumn: 'contact.full_name',
    },
    {
      label: 'Service Type',
      sortColumn: 'service_type.name',
    },
    {
      label: 'Last Updated',
      sortColumn: 'updated_at',
      type: 'date',
    },
  ]);
}

export default function ClosedReferralsIndex(props) {
  const {
    paging,
    data,
    onNextClick,
    onPrevClick,
    isFetching,
    onCellClick,
    initialSort,
    filters,
    onFiltersChange,
    fetchGroupsUsers,
    onClose,
    onToggle,
    open,
    showMoreFilters,
    columnLabels,
  } = props;
  const secondaryFilters = filters.filter((f) => _.get(f, 'secondary'));

  return (
    <div>
      <FilterBar
        filters={filters}
        onFiltersChange={onFiltersChange}
        paging={paging}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
        isFetching={isFetching && !_.isEmpty(data)}
        asyncSearch={fetchGroupsUsers}
        onMoreFiltersClick={onToggle}
        showSecondaryFilters={!showMoreFilters}
      />
      <div className="dashboard-inner-content">
        {
          (isFetching && _.isEmpty(data)) ?
            <Spinner /> : (
              <DynamicTable
                id="closed-referrals-table"
                dataSet={data}
                onCellClick={onCellClick}
                initialSort={initialSort}
                columnLabels={columnLabels || getColumns()}
                emptyMessage="There are no closed referrals."
                rowIcon={(referral) => <ReferralRowIcon referral={referral} />}
              />
            )
        }
      </div>
      <Drawer
        className="closed-referrals__drawer"
        open={open}
        secondary
        onClose={onClose}
        topOffset={TOP_NAVIGATION_HEIGHT}
      >
        <div className="closed-referrals__drawer-contents">
          <div className="closed-referrals__drawer-header">
            <h2 className="closed-referrals__drawer-header-label">
              More Filter(s)
            </h2>
          </div>
          <div className="closed-referrals__drawer-body">
            {
                _.map(_.orderBy(secondaryFilters, ['name'], ['desc']), (f) => {
                  const {
                    asyncSearch: filterAsyncSearch,
                    searchPlaceholder,
                  } = f;

                  if (!f.secondary) {
                    return null;
                  }

                  return (
                    <Filter
                      hideSelectAll={f.hideSelectAll || false}
                      key={f.key}
                      name={f.name}
                      pluralName={f.pluralName}
                      options={f.options}
                      onFiltersChange={(activeFilters) => onFiltersChange(f.key, activeFilters)}
                      searchPlaceholder={searchPlaceholder || setFilterSearchPlaceholder(f)}
                      asyncSearch={filterAsyncSearch !== undefined ?
                        setAsyncSearch(f, filterAsyncSearch) :
                        setAsyncSearch(f, fetchGroupsUsers)}
                      uniqIdPrefix={f.key}
                    />
                  );
                })
              }
          </div>
        </div>
      </Drawer>
    </div>
  );
}

ClosedReferralsIndex.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  paging: shapes.paging,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  onCellClick: PropTypes.func.isRequired,
  initialSort: PropTypes.object,
  filters: PropTypes.array,
  onFiltersChange: PropTypes.func,
  fetchGroupsUsers: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  showMoreFilters: PropTypes.bool,
  columnLabels: PropTypes.array,
};

ClosedReferralsIndex.defaultProps = {
  paging: undefined,
  initialSort: undefined,
  filters: undefined,
  onFiltersChange: undefined,
  showMoreFilters: undefined,
  columnLabels: undefined,
};
