import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import ContactColumn from 'src/components/ContactColumn';
import MilitaryAffiliation from 'src/components/Dashboard/components/MilitaryAffiliation/MilitaryAffiliation';
import {
  UUBaseCard,
  UUBaseCardBody,
  Divider,
} from '@unite-us/ui';
import { connect } from 'react-redux';
import { find, get, isEmpty } from 'lodash';
import canView from 'src/common/utils/SecureLink/utils/canView';
import callOrLog from 'src/common/utils/callOrLog';
import { DASHBOARD_EVENTS } from 'common/utils/EventTracker/utils/eventConstants';
import { fetchGroupContact } from 'actions/Contact/Group';
import { fetchClientRelationship } from 'src/actions/CareCoordinator/Contact/Group/fetchClientRelationship';
import CareCoordinator from './CareCoordinator';

class AsideColumn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientRelationship: {},
    };

    this.goToFacesheet = this.goToFacesheet.bind(this);
    this.trackClick = this.trackClick.bind(this);
    this.getClientRelationship = this.getClientRelationship.bind(this);
  }

  componentDidMount() {
    const { contact, groupId } = this.props;
    if (canView(contact)) {
      //  Hack: Refetch contact using fetchGroupContact(groupId, contactId)
      //        When users are loading the FaceSheet after viewiing list of Referrals, the underlying contact data
      //        has the incorrect consent info (default version that presents an error)
      //        The  fetchGroupContact has been identified containing the correct consent info.
      const contactId = get(contact, 'id');
      this.props.fetchGroupContact(groupId, contactId);
      this.getClientRelationship();
    }
  }

  componentDidUpdate(prevProps) {
    const { contact } = this.props;

    if (prevProps.contact.id !== contact.id) {
      this.getClientRelationship();
    }
  }

  async getClientRelationship() {
    const {
      groupId,
      contact: { id: contactId },
    } = this.props;

    const clientRelationship = await fetchClientRelationship(contactId, groupId);
    this.setState({ clientRelationship });
  }

  goToFacesheet() {
    const { contact = {} } = this.props;
    this.trackClick(contact);
    browserHistory.push(`/facesheet/${contact.id}`);
  }

  trackClick() {
    const contact_id = get(this.props, 'contact.id');

    callOrLog(() => this.context.eventTracker(DASHBOARD_EVENTS.goToFacesheet, {
      contact_id,
    }));
  }

  render() {
    const {
      contact,
      detailObj,
      isFetching,
      styles: { divider },
      isAssistanceRequest,
      isCoordinationGroup,
    } = this.props;

    const { clientRelationship } = this.state;

    const canViewContact = canView(contact);
    const canViewLabel = !isEmpty(clientRelationship) || isCoordinationGroup;
    const canViewCareCoordinator = canViewContact && canViewLabel;

    const militaryAffiliation = contact.military?.affiliation;
    const hasMilitaryAffiliation = militaryAffiliation && militaryAffiliation !== 'prefer_not_to_disclose';
    const canViewMilitaryAffiliation = canViewCareCoordinator || (hasMilitaryAffiliation && isAssistanceRequest);

    return (
      <UUBaseCard className="aside-column">
        <UUBaseCardBody withPadding>
          {
            isFetching && (
              <div>Loading...</div>
            )
          }
          <ContactColumn
            contact={contact}
            labelClickable={canViewContact && canViewLabel}
            linkLabel="Go To Face Sheet"
            name={get(contact, 'full_name', '')}
            onLinkClick={this.goToFacesheet}
            showSecondaryDetails
          />
          {
            canViewMilitaryAffiliation && (
              <MilitaryAffiliation
                personId={contact.id}
                affiliationValue={contact.military?.affiliation}
              />
            )
          }
          {
            canViewCareCoordinator && (
              <>
                <Divider style={divider} />
                <CareCoordinator
                  contact={contact}
                  detailObj={detailObj}
                />
              </>
            )
          }
        </UUBaseCardBody>
      </UUBaseCard>
    );
  }
}

AsideColumn.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.string,
    military: PropTypes.shape({
      affiliation: PropTypes.string,
    }),
  }),
  detailObj: PropTypes.object.isRequired,
  fetchGroupContact: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  styles: PropTypes.shape({
    divider: PropTypes.object,
  }),
  isAssistanceRequest: PropTypes.bool,
  isCoordinationGroup: PropTypes.bool.isRequired,
};

AsideColumn.defaultProps = {
  styles: {
    divider: { marginTop: '20px' },
  },
  contact: {},
  isAssistanceRequest: false,
};

function mapStateToProps(state) {
  const { user, session: { isCoordinationGroup, groupId } } = state;
  const group = user.groups.find((g) => g.id === groupId) || {};
  const license = find(group.licensing, { subscription_type: 'group' });
  const isFetching = get(state, 'contacts.isFetching', false);

  return {
    groupId,
    isCoordinationGroup,
    isFetching,
    license,
    user,
  };
}

AsideColumn.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  fetchGroupContact,
})(AsideColumn);
