import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ChartMultiBar } from 'src/common/charts';
import { fetchContactsReports } from 'actions/Report/Contact';
import _ from 'lodash';
import { genderPartitions } from '../../utils/partitions';
import { genderColors } from '../../utils/colors';

export default class ContactsByGenderByAge extends Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.state = {
      contactsByGenderByAge: [],
      title: '',
    };
  }

  componentDidMount() {
    this.fetchData(this.props.filters);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const filtersChanged = !_.isEqual(this.props.filters, nextProps.filters);
    const networkChanged = !_.isEqual(this.props.id, nextProps.id);

    if (filtersChanged || networkChanged) {
      this.fetchData(nextProps.filters, nextProps.id);
    }
  }

  fetchData(filters, id = this.props.id) {
    const { scope } = this.props;
    fetchContactsReports(scope, id, 'gender/by-age', { partitions: genderPartitions(), ...filters })
      .then((response) => {
        this.setState({
          contactsByGenderByAge: _.assign({}, response.data, { values: _.get(response, 'data.values', []) }),
          title: _.get(response, 'data.title', ''),
        });
        this.props.loaded('contactsByGenderByAge');
      });
  }

  render() {
    if (_.isEmpty(this.state.contactsByGenderByAge)) {
      return null;
    }
    return (
      <ChartMultiBar
        data={this.state.contactsByGenderByAge}
        x={(d) => _.truncate(d.title, { length: 42 })}
        y={(d) => d.total}
        title={this.state.title}
        margin={{ left: 60, top: 60, bottom: 60 }}
        showYAxis
        showLegend
        stacked
        showControls
        useInteractiveGuideline={false}
        valueFormat="d"
        colors={genderColors}
        style={{ height: '400px' }}
        xLabel="Age"
      />
    );
  }
}

ContactsByGenderByAge.propTypes = {
  filters: PropTypes.shape({
    end_date: PropTypes.string,
    network_ids: PropTypes.array,
    start_date: PropTypes.string,
  }),
  loaded: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
};
