import _ from 'lodash';

function geoCode(address) {
  const geoCoder = new window.google.maps.Geocoder();

  return new Promise((res, rej) => {
    geoCoder.geocode({
      address: [address.line_1, address.city, address.state].join(','),
      componentRestrictions: { country: 'US' },
    }, (results, status) => {
      if (status === 'OK') {
        const { location } = results[0].geometry;
        return res({
          latitude: location.lat(),
          longitude: location.lng(),
          address_components: results[0].address_components,
        });
      }
      return rej(results);
    });
  });
}

export const getStateData = (latLng) => {
  const geoCoder = new window.google.maps.Geocoder();

  return new Promise((res, rej) => {
    geoCoder.geocode({
      latLng: new window.google.maps.LatLng(latLng.lat, latLng.lng),
    }, (results, status) => {
      if (status === 'OK') {
        const addressComponents = _.get(results[0], 'address_components', []);
        const stateAbbreviation = _.get(
          addressComponents.find(
            (aC) => aC.types.includes('administrative_area_level_1') && aC.types.includes('political'),
          ),
          'short_name',
          '',
          );
        const stateDisplayName = _.get(
          addressComponents.find(
            (aC) => aC.types.includes('administrative_area_level_1') && aC.types.includes('political'),
          ),
          'long_name',
          '',
          );
        return res({ stateAbbreviation, stateDisplayName });
      }
      return rej(results);
    });
  });
};

/** @typedef {*} Address */

/**
 * Returns the address with geocoding fields filled.
 *
 * If the address already contains geocoded fields, returns the
 * same object.
 *
 * @param {Address} address
 * @returns {Address}
 */
export async function getGeocodedAddress(address) {
  const isGeocoded = ['latitude', 'longitude'].every((attr) => address[attr]);

  if (isGeocoded) {
    return address;
  }

  const { latitude, longitude, address_components } = await geoCode(address);

  const county = address_components
    .filter((c) => c.types.includes('administrative_area_level_2'))[0]?.long_name ?? null;

  return {
    ...address,
    latitude,
    longitude,
    county,
  };
}

export default geoCode;
