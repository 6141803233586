import { isEmpty } from 'lodash';
import {
  SET_IMPERSONATION,
  USER_LOGIN_SUCCESS,
} from 'actions';

export default function loginWithSession(session) {
  return (dispatch) => {
    const { impersonationType } = session;

    dispatch({ type: USER_LOGIN_SUCCESS, payload: session });

    if (!isEmpty(impersonationType)) {
      dispatch({ type: SET_IMPERSONATION, value: impersonationType });
    }
  };
}
