import Notifier from 'common/helpers/Notifier';
import { CREATE_GROUPS_INVITATION } from 'actions';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { authApi } from 'src/api/config';

const onSuccess = (dispatch, payload) => {
  dispatch({
    type: CREATE_GROUPS_INVITATION,
    payload: { // same success response structure as v3:
      data: {
        ok: true,
      },
    },
  });
  Notifier.dispatch(payload.status, 'Invitation Sent');
  return payload;
};

export default function sendUserInvite({
  provider,
  user,
}) {
  return async (dispatch) => {
    try {
      const response = await authApi.createRecord('invitation', { provider, user });
      if (!response || !isHttpSuccess(response.status)) { return false; }

      return onSuccess(dispatch, response);
    } catch (error) {
      return false;
    }
  };
}
