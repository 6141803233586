import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { kebabCase, join, map } from 'lodash';
import './BasicTable.scss';

const tableClass = (type) => classNames(
  'basic-table',
  { 'basic-table--detail-page': type === 'detail-page' },
);

const valueClass = (item) => classNames(
  'basic-table__text',
  { 'basic-table__text--italicize': item.italicize },
);

const BasicTable = ({ data, id, type }) => (
  <table className={tableClass(type)} id={id}>
    <tbody>
      {
        map(data, (item = {}, rowIndex) => (
          <tr
            className="basic-table__row"
            key={`basic-table-${rowIndex}`}
          >
            <td className="basic-table__text basic-table__text--label">
              {item.label}
            </td>
            <td
              id={kebabCase(join([id, item.label, 'value']))}
              className={valueClass(item)}
            >
              {item.value}
            </td>
          </tr>
        ))
      }
    </tbody>
  </table>
);

BasicTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    italicize: PropTypes.bool,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    value: PropTypes.node,
  }).isRequired),
  id: PropTypes.string,
  type: PropTypes.string,
};

BasicTable.defaultProps = {
  data: [],
  id: 'basic-table',
  type: '',
};

export default BasicTable;
