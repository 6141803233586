import { THUNK_START, THUNK_END, THUNK_FAILED } from 'actions';

function isWorking(state = 0, { type } = {}) {
  switch (type) {
    case THUNK_START:
      return state + 1;
    case THUNK_END:
      return state - 1;
    case THUNK_FAILED:
      return state - 1;
    default:
      return state;
  }
}

export const getIsWorking = (state) => state.isWorking;
export default isWorking;
