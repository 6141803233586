import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { InfoPanel } from '@unite-us/client-utils';
import { validations } from '@unite-us/app-components';
import { TextField } from '@unite-us/ui';
import { CFR_PART_2_REFERRAL_MESSAGE, PHI_INFO_MESSAGE } from 'common/messageConstants';
import EditReferralDetails from 'src/components/Referrals/ReferralFormFields/EditReferralDetails';
import { ReferralOONCaseFields } from 'src/components/Referrals/ReferralFormFields';
import DocumentUploader from 'common/form/DocumentUploader';
import { REFERRAL_DOCUMENT_UPLOAD } from 'src/components/Referrals/constants';
import { useSelectedPrograms } from '@unite-us/app-search';

const ReferralServiceFormExpanded = (props) => {
  const {
    activeUsersInGroup,
    assistanceRequest,
    canShowOONCaseContext,
    currentGroupIsSensitive,
    currentServiceTypeIndex,
    fetchGroupsUsers,
    fields,
    form,
    fromAssistanceRequest,
    index,
    isProgramBasedSearch,
    networks,
    onCancel,
    onForceShowOONCaseContext,
    registerField,
    showOONCaseContext,
    touch,
    untouch,
    setDisableNextButton,
    setHasSensitiveErrors,
    unregisterField,
  } = props;

  // Remove the value so that it's not programmically set for file input
  delete fields.documents.value;

  // Determine if referral is sensitive based on the "sending" group, which
  // is also the current user group.
  const isSensitive = currentGroupIsSensitive;
  const selectedProgramsContext = useSelectedPrograms();

  // clear selected programs when creating a new referral
  useEffect(() => {
    selectedProgramsContext.dispatchRemoveAllPrograms();
  }, []);

  return (
    <div className="referral-service-form-expanded referral-service-expanded">
      <EditReferralDetails
        assistanceRequest={assistanceRequest}
        canShowOONCaseContext={canShowOONCaseContext}
        currentServiceTypeIndex={currentServiceTypeIndex}
        fields={fields}
        form={form}
        fromAssistanceRequest={fromAssistanceRequest}
        networks={networks}
        onForceShowOONCaseContext={onForceShowOONCaseContext}
        registerField={registerField}
        showOONCaseContext={showOONCaseContext}
        untouch={untouch}
        touch={touch}
        onCancel={onCancel}
        index={index}
        setHasSensitiveErrors={setHasSensitiveErrors}
        selectedProgramsContext={selectedProgramsContext}
        setDisableNextButton={setDisableNextButton}
        unregisterField={unregisterField}
      />
      <div className="service-type-section row">
        <div className="col-md-12">
          {isProgramBasedSearch && (
            <>
              <hr className="mb-5" />
              <h2 className="my-3 font-bold">
                Additional Information
              </h2>
            </>
          )}
          <TextField
            afterLabelContent={(
              <InfoPanel
                className="referral-service-expanded__info-panel"
                message={
                  isSensitive ?
                    [PHI_INFO_MESSAGE, CFR_PART_2_REFERRAL_MESSAGE].join(' ') :
                    PHI_INFO_MESSAGE
                }
              />
            )}
            id="referral-notes"
            className="referral-service-expanded__notes"
            field={fields.notes}
            hint="Please describe your client's current situation and reason for referral."
            label="Referral Description"
            rows={3}
            inline={false}
            ref={registerField}
            validations={validations.isRequired}
            required
          />
        </div>
      </div>

      <ReferralOONCaseFields
        activeUsersInGroup={activeUsersInGroup}
        fields={fields}
        fetchGroupsUsers={fetchGroupsUsers}
        hidden={!showOONCaseContext}
        registerField={registerField}
      />
      <div className="upload-documents">
        <h5>Documents</h5>
        <DocumentUploader
          formIndex={index}
          dropzoneName={`${REFERRAL_DOCUMENT_UPLOAD}-${index}`}
          fields={fields}
          formOnly
          registerField={registerField}
        />
      </div>
    </div>
  );
};

ReferralServiceFormExpanded.propTypes = {
  activeUsersInGroup: PropTypes.array,
  assistanceRequest: PropTypes.object,
  canShowOONCaseContext: PropTypes.bool,
  currentGroupIsSensitive: PropTypes.bool.isRequired,
  currentServiceTypeIndex: PropTypes.number.isRequired,
  fetchGroupsUsers: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  fromAssistanceRequest: PropTypes.bool,
  index: PropTypes.number.isRequired,
  isProgramBasedSearch: PropTypes.bool.isRequired,
  networks: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onForceShowOONCaseContext: PropTypes.func,
  registerField: PropTypes.func.isRequired,
  showOONCaseContext: PropTypes.bool,
  touch: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  setDisableNextButton: PropTypes.func.isRequired,
  setHasSensitiveErrors: PropTypes.func.isRequired,
  unregisterField: PropTypes.func,
};

ReferralServiceFormExpanded.defaultProps = {
  activeUsersInGroup: [],
  assistanceRequest: undefined,
  canShowOONCaseContext: false,
  fromAssistanceRequest: false,
  onForceShowOONCaseContext: false,
  showOONCaseContext: false,
  unregisterField: _.noop,
};

function mapStateToProps(state) {
  const currentGroupIsSensitive = _.get(state, 'session.currentProvider.sensitive', false);
  return {
    currentGroupIsSensitive,
  };
}

export default connect(mapStateToProps)(ReferralServiceFormExpanded);
