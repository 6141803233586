import { compact, isEmpty, map, reduce } from 'lodash';
import serviceNamesForDisplay from './serviceNamesForDisplay';

const getSelectedChildren = (service, ids) => compact(
  map(service.children, (child) => (
    ids.includes(child.id) ? { name: child.name } : null
  )),
);

const getSelectedServices = (services, ids) => {
  if (!isEmpty(ids)) {
    const service_types = compact(reduce(services, (acc, service) => [
      ...acc,
      (ids.includes(service.id) ? { name: service.name } : null),
      ...(getSelectedChildren(service, ids)),
    ], []));

    return serviceNamesForDisplay(service_types, 1);
  }
  return null;
  // @todo Update this function to return the same default value.  Right now
  // it's returning null if no ids exist, but if ids exist (even an []), the
  // function will return an empty string is no services match.  It should
  // either always return '' or [].
};

export default getSelectedServices;
