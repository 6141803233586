// Code-readable status names to status strings from backend
export const AUTHORIZATION_STATUSES = {
  draft: 'draft',
  open: 'requested',
  accepted: 'approved',
  rejected: 'denied',
  autoAccepted: 'auto_approved',
};

export const ALL_AUTHORIZATION_PAGE_STATUSES = 'requested,approved,denied';

// Code-readable (for programmers use) status names to human-readable status names
export const AUTHORIZATION_STATUSES_TEXT = {
  draft: 'draft',
  open: 'open',
  accepted: 'accepted',
  rejected: 'rejected',
  autoAccepted: 'auto_accepted',
};

// Status strings from backend to human-readable status text
export const AUTHORIZATION_STATUSES_TO_TEXT = {
  draft: 'Draft',
  requested: 'Requested',
  approved: 'Accepted',
  denied: 'Rejected',
  deferred: 'Deferred',
};

export const AUTHORIZATION_STATUSES_TO_COLOR = {
  draft: '#525F73',
  requested: '#F4D316',
  approved: '#4ECE3D',
  denied: '#CF2A2A',
  deferred: '#CF2A2A',
};
