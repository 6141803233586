import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dialog } from '@unite-us/ui';
import RecallModalForm from './RecallModalForm';

class RecallModalDialog extends Component {
  constructor(props) {
    super(props);

    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  openDialog() {
    return this.recallDialog.openDialog()
      .then(this.props.resetActionValue);
  }

  closeDialog() {
    this.recallDialog.closeDialog();
  }

  render() {
    return (
      <div>
        <Dialog
          id="recall-referral-modal"
          data-testid="recall-referral-modal"
          ref={(el) => { this.recallDialog = el; }}
          title="Recall Referral"
          size="large"
          modal
        >
          <RecallModalForm
            referral={this.props.referral}
            closeDialog={this.closeDialog}
          />
        </Dialog>
      </div>
    );
  }
}

RecallModalDialog.propTypes = {
  referral: PropTypes.object.isRequired,
  resetActionValue: PropTypes.func.isRequired,
};

export default RecallModalDialog;
