import * as LDClient from 'launchdarkly-js-client-sdk';
import _ from 'lodash';

import {
  FETCH_USER_FOR_SESSION_SUCCESS,
  USER_LOAD_FROM_SESSIONSTORAGE,
  USER_LOGOUT,
  SET_CURRENT_GROUP,
  SET_CURRENT_NETWORK,
  SET_IS_COORDINATION_GROUP,
  FEATURE_FLAG_UPDATE,
} from 'src/actions';

require('@babel/polyfill');

export class FeatureFlags {
  constructor() {
    this.user = { key: 'unknown', anonymous: true };
    this.groups = {};
    this.thunks = {};

    this.reducer = this.reducer.bind(this);
  }

  onChange(key, thunk) {
    this.thunks[key] ||= [];
    this.thunks[key].push(thunk);
  }

  init({ clientId }) {
    this.client = LDClient.initialize(clientId, this.user, {
      bootstrap: 'localStorage',
      useReport: true,
    });

    return (dispatch, getState) => new Promise((resolve) => {
      this.client.on('ready', () => {
        this.identify().then(() => {
          const flags = this.client.allFlags();
          dispatch({
            type: FEATURE_FLAG_UPDATE,
            payload: {
              flags,
            },
          });

          _.forOwn(flags, (value, key) => {
            const thunks = this.thunks[key] || [];

            _.forEach(thunks, (thunk) => {
              thunk(dispatch, getState, value);
            });
          });

          resolve(flags);
        });
      });

      this.client.on('change', (settings) => {
        const flags = _.mapValues(settings, (flag) => flag.current);

        dispatch({
          type: FEATURE_FLAG_UPDATE,
          payload: {
            flags,
          },
        });

        _.forOwn(flags, (value, key) => {
          const thunks = this.thunks[key] || [];

          _.forEach(thunks, (thunk) => {
            thunk(dispatch, getState, value);
          });
        });
      });
    });
  }

  load(user) {
    this.groups = _.reduce(user.groups, (groups, group) => {
      const groupId = _.get(group, 'group.id');

      const packageIds = _.reduce(group.licensing, (pids, license) => {
        const packageId = _.get(license, 'package.id');
        if (!packageId) return pids;

        return [...pids, packageId];
      }, []);

      const features = _.keys(group.feature_flags).sort();
      const role_keys = _.values(group.roles).map((role) => role.role_key).sort();

      return {
        ...groups,
        [groupId]: {
          packageIds,
          features,
          role_keys,
        },
      };
    }, {});

    this.user = {
      key: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      name: user.full_name,
      email: user.email,
      avatar: user.avatar_url,
      anonymous: false,
      custom: {
        ...this.user.custom,
        created: user.created_at,
      },
    };
  }

  mapCurrentGroup() {
    const id = this.user.custom.groupId;
    if (!id) return;

    this.user = {
      ...this.user,
      custom: {
        ...this.user.custom,
        packageIds: _.get(this.groups[id], 'packageIds', []),
        features: _.get(this.groups[id], 'features', []),
        role_keys: _.get(this.groups[id], 'role_keys', []),
      },
    };
  }

  async identify() {
    try {
      await this.client.identify(this.user); // eslint-disable-line
    } catch (e) {
      // ignore error
    }
  }

  reducer(state = {}, action) {
    switch (action.type) {
      case USER_LOAD_FROM_SESSIONSTORAGE:
        this.load(action.payload.user);
        this.mapCurrentGroup();

        this.identify();
        break;
      case FETCH_USER_FOR_SESSION_SUCCESS:
        this.load(action.payload.data.data);
        this.mapCurrentGroup();

        this.identify();
        break;
      case SET_CURRENT_GROUP:
        this.user.custom = {
          ...this.user.custom,
          groupId: action.groupId,
        };

        this.mapCurrentGroup();

        this.identify();
        break;
      case SET_CURRENT_NETWORK:
        this.user.custom = {
          ...this.user.custom,
          networkId: action.networkId,
        };

        this.identify();
        break;
      case SET_IS_COORDINATION_GROUP:
        this.user.custom = {
          ...this.user.custom,
          isCoordinationGroup: action.value,
        };

        this.identify();
        break;
      case USER_LOGOUT:
        this.user = { anonymous: true };
        this.identify();
        break;
      case FEATURE_FLAG_UPDATE: {
        const flags = _.mapKeys(action.payload.flags, (value, key) => _.camelCase(key));

        return {
          ...state,
          ...flags,
        };
      }
      default:
        return state;
    }

    return state;
  }
}

export default new FeatureFlags();
