import { apiDefault } from 'src/api/config';
import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import getCoreError from 'src/api/utils/getCoreError';

const onError = (dispatch, error) => {
  Notifier.handleErrors(getCoreError(error));
  return error;
};

const makeRequestData = (provider, employeeId) => ({
  data: {
    relationships: {
      provider: {
        data: {
          id: provider,
          type: 'provider',
        },
      },
      employee: {
        data: {
          id: employeeId,
          type: 'employee',
        },
      },
    },
  },
});

export default function acceptAgreement(provider, employeeId) {
  return async (dispatch) => {
    try {
      const response = await apiDefault.post(
        '/provider_agreements',
        makeRequestData(provider, employeeId),
      );
      if (!response || !isHttpSuccess(response.status)) {
        return onError(dispatch, 'Failed to accept provider agreement');
      }
      return response;
    } catch (error) {
      return onError(dispatch, error);
    }
  };
}
