// given a target date range, is the query date range a subset of that range?
// if query end date is falsy, it is ignored.
// if target end date is falsy, it is treated as infinitely far in the future.
const datesInRange = (targetRangeStart, targetRangeEnd, queryDateStart, queryDateEnd) => {
  if (queryDateEnd) {
    return queryDateStart >= targetRangeStart && (!targetRangeEnd || queryDateEnd <= targetRangeEnd);
  }
  return queryDateStart >= targetRangeStart && (!targetRangeEnd || queryDateStart <= targetRangeEnd);
};

export default datesInRange;
