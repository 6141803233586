import {
  FETCH_USERS_V2_STARTED,
  FETCH_USERS_V2_SUCCESS,
  FETCH_USERS_V2_ERROR,
} from 'actions';
import {
  STATUS_INITIAL,
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_ERROR,
} from 'common/Status/constants';
import arrayToObject from 'common/utils/arrayToObject';

export const defaultState = {
  data: {},
  status: STATUS_INITIAL,
};

export default function userV2Reducer(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case FETCH_USERS_V2_STARTED:
      return { ...state, status: STATUS_PENDING };
    case FETCH_USERS_V2_ERROR:
      return { ...state, status: STATUS_ERROR };
    case FETCH_USERS_V2_SUCCESS: {
      const updatedData = { ...state.data, ...arrayToObject(payload.data.data) };
      return {
        ...state,
        data: updatedData,
        status: STATUS_SUCCESS,
      };
    }
    default:
      return state;
  }
}
