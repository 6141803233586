import { every } from 'lodash';
import { getFieldValue } from '.';

function canSearchGroups(fields) {
  const referredByNetwork = getFieldValue(fields.referred_by_network);
  const referredToNetwork = getFieldValue(fields.referred_to_network);
  const referToOutsideNetwork = getFieldValue(fields.refer_to_outside_network);
  const serviceType = getFieldValue(fields.service_type);

  return every([
    referredByNetwork.id,
    !referToOutsideNetwork || (referToOutsideNetwork && referredToNetwork.id),
    serviceType.id,
  ]);
}

export default canSearchGroups;
