// Library Imports
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import {
  CheckBoxField,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from '@unite-us/ui';

import { dates } from '@unite-us/app-components';

class ContactDocumentsSelector extends PureComponent {
  static getCheckBoxId(formIndex, i) {
    return formIndex !== undefined ? `document-${formIndex}-${i}-checkbox` : `document-${i}-checkbox`;
  }

  static openInNewWindow(url) {
    window.open(url);
  }

  render() {
    const { documentFields = {}, formIndex } = this.props;

    if (isEmpty(documentFields)) {
      return null;
    }

    const rows = documentFields.map((field, i) => {
      const documentTitle = get(field, 'document.value.title', '');
      const documentUrl = get(field, 'document.value.url', '');
      return (
        <TableRow key={i} clickable={false}>
          <TableRowColumn className="contact-documents-selector__checkboxes">
            <div data-test-element={ContactDocumentsSelector.getCheckBoxId(formIndex, i)}>
              <CheckBoxField
                id={ContactDocumentsSelector.getCheckBoxId(formIndex, i)}
                field={field.attached}
                label=""
                hideLabel
                showError={false}
                showHint={false}
              />
            </div>
          </TableRowColumn>
          <TableRowColumn>
            {
              documentUrl ? (
                <a onClick={() => ContactDocumentsSelector.openInNewWindow(documentUrl)}>
                  {documentTitle}
                </a>
              ) : documentTitle
            }
          </TableRowColumn>
          <TableRowColumn>
            {get(field, 'document.value.created_by.full_name', '')}
          </TableRowColumn>
          <TableRowColumn>
            {dates.formatDate(get(field, 'document.value.updated_at'))}
          </TableRowColumn>
        </TableRow>
      );
    });

    return (
      <Table
        id="contact-documents-selector-table"
        className="contact-documents-selector"
      >
        <TableHeader>
          <TableRow>
            <TableHeaderColumn />
            <TableHeaderColumn>File Name</TableHeaderColumn>
            <TableHeaderColumn>Uploaded By</TableHeaderColumn>
            <TableHeaderColumn>Date Modified</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {rows}
        </TableBody>
      </Table>
    );
  }
}

ContactDocumentsSelector.propTypes = {
  documentFields: PropTypes.array,
  formIndex: PropTypes.number,
};

ContactDocumentsSelector.defaultProps = {
  documentFields: [],
  formIndex: undefined,
};

export default ContactDocumentsSelector;
