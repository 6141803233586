import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  addresses,
  AddressCardText,
  ProviderDetailsV2,
} from '@unite-us/client-utils';
import { Card, CardText } from '@unite-us/ui';
import StaticMap from 'src/components/Browse/Map/StaticMap/StaticMap';
import { getStateData } from 'src/components/Browse/Map/utils/geoCode';
import { SERVICE_AREA_TYPE_NATIONAL } from 'components/Groups/constants';
import GroupDetailsPrograms from './GroupDetailsPrograms';
import './GroupDetailsContentV2.scss';

const GroupDetailsContentV2 = (props) => {
  const {
    center,
    provider,
    programs, // core programs via groupPrograms
    providerLocations,
    providerServiceAreas,
    serve,
    stateDisplayName,
  } = props;
  const [stateAbbr, setStateAbbr] = useState('');

  const getStateAbbr = async () => {
    const { stateAbbreviation } = await getStateData({
      lat: center.lat,
      lng: center.lng,
    });

    setStateAbbr(stateAbbreviation);
  };

  useEffect(() => {
    getStateAbbr();
  }, [center]);

  const { licensed } = provider;

  const originLatLng = [center.lat, center.lng];

  const calculatedDistances = _.map(
    providerLocations,
    (address) => addresses.calculateDistance(address, originLatLng),
  );
  const locationsByDistance = _.sortBy(calculatedDistances, (add) => add.distanceVal);

  const firstAddressIndex = _.indexOf(calculatedDistances, _.first(locationsByDistance));
  const groupFirstAddress = _.get(providerLocations, `[${firstAddressIndex}]`, {});
  const latLng = groupFirstAddress.attributes ?
    [groupFirstAddress.attributes.latitude, groupFirstAddress.attributes.longitude] : null;

  const groupFirstAddressDisplay = _.first(locationsByDistance);

  const isNational = providerServiceAreas === SERVICE_AREA_TYPE_NATIONAL;
  const serviceAreaText = isNational ?
    'Serves the entire country.' :
    `Serves the state of ${stateDisplayName}.`;
  const serviceAreaCenter = isNational ? 'USA' : `${stateAbbr} state, USA`;
  const serviceAreaZoom = isNational ? '3' : '5';

  return (
    <div className="group-details-content group-details-content--v2">
      {
        latLng && !serve && (
          <Card className="group-detail-content__map-container mb-one">
            <CardText>
              <div className="group-details-content__map">
                <StaticMap
                  isLicensedProvider={licensed}
                  latLng={latLng}
                />
              </div>

              <AddressCardText
                iconColor={licensed ? '#4571BA' : '#2C405A'}
                location={groupFirstAddressDisplay}
              />
            </CardText>
          </Card>
        )
      }

      {
        providerServiceAreas && serve && (
          <Card className="group-detail-content__map-container mb-one">
            <CardText>
              <div className="group-details-content__map">
                <StaticMap
                  isLicensedProvider={licensed}
                  latLng={latLng}
                  serviceArea={serviceAreaCenter}
                  zoom={serviceAreaZoom}
                />
              </div>

              <AddressCardText
                iconColor={licensed ? '#4571BA' : '#2C405A'}
                serviceAreaText={serviceAreaText}
              />
            </CardText>
          </Card>
        )
      }

      <ProviderDetailsV2
        center={center}
        provider={provider}
        providerLocations={providerLocations}
      />

      {
        !_.isEmpty(programs) && (
          <GroupDetailsPrograms
            center={center}
            programs={programs}
            provider={provider}
            providerLocations={providerLocations}
          />
        )
      }
    </div>
  );
};

GroupDetailsContentV2.propTypes = {
  center: PropTypes.object,
  programs: PropTypes.array,
  provider: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    licensed: PropTypes.bool,
  }),
  providerLocations: PropTypes.array.isRequired,
  providerServiceAreas: PropTypes.array.isRequired,
  serve: PropTypes.bool,
  stateDisplayName: PropTypes.string,
};

GroupDetailsContentV2.defaultProps = {
  center: {},
  provider: {},
  programs: [],
  serve: false,
  stateDisplayName: '',
};

export default GroupDetailsContentV2;
