import { coreApi } from 'src/api/config';

const removeDocumentsFromResource = async ({
  documentId,
  resourceId,
  resourceType,
}) => {
  if (resourceType === 'referrals' || resourceType === 'cases') {
    const response = await coreApi.deleteRelationship('case', resourceId, 'file_upload', [documentId]);
    return response;
  }
  if (resourceType === 'person') {
    const response = await coreApi.deleteRelationship('person', resourceId, 'file_upload', [documentId]);
    return response;
  }
  return null;
};

export default removeDocumentsFromResource;
