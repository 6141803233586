import { coreApi } from 'src/api/config';
import {
  CREATE_EXPORT,
} from 'actions';
import Notifier from 'common/helpers/Notifier';

function onSuccess(dispatch, payload) {
  dispatch({
    type: CREATE_EXPORT,
    payload,
  });
}

function onError(error) {
  Notifier.handleErrors(error);
  return error;
}

export default function createExport(params) {
  return async (dispatch) => {
    try {
      const payload = await coreApi.createRecord('export', params);

      Notifier.dispatch(
        payload.status,
        'Your export will be ready for download here. It will be available within 48 hours.',
      );
      return onSuccess(dispatch, payload);
    } catch (error) {
      return onError(error);
    }
  };
}
