import { FETCH_NETWORK_SERVICE_TYPES } from 'actions';
import Notifier from 'common/helpers/Notifier';
import { coreApi } from 'src/api/config';
import assignChildToParentServices from 'src/common/utils/ServiceTypes/assignChildToParentServices';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { includePathwaysServices } from 'common/utils/FeatureFlags/flags';

const onError = (error) => {
  Notifier.handleErrors(error);
  return error;
};

const fetchNetworkServiceTypes = (networkId) => (
  async (dispatch, getState) => {
    const state = getState();

    const response = await coreApi.query('service', {
        networks: networkId,
        taxonomy: 'uniteus',
        include_pathways: includePathwaysServices(state),
      }, { page: { number: 1, size: 300 } },
    );

    if (!response || !isHttpSuccess(response.status)) {
      return onError(dispatch, response);
    }

    const { service_types } = state.session.globals;
    const payload = assignChildToParentServices(response.data.data, service_types);
    response.data.data = payload;
    dispatch({
      type: FETCH_NETWORK_SERVICE_TYPES,
      payload: response,
    });

    return response;
  }
);

export default fetchNetworkServiceTypes;
