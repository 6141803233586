import { SELECT_CONTACT, REPLACE_SEARCHED_CONTACT } from 'actions';
import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { coreApi } from 'src/api/config';

function onError(error) {
  Notifier.handleErrors(error);
  return error;
}

const addContactToGroup = (contactId) => (
  async (dispatch) => {
    try {
      const response = await coreApi.findRecord('person', contactId, { include: 'addresses' });
      if (!response || !isHttpSuccess(response.status)) {
        return onError(response);
      }

      await coreApi.populateRelationship('consent', 'consent', response.data.data);

      dispatch({ type: SELECT_CONTACT, id: response.data.data.id });
      dispatch({ type: REPLACE_SEARCHED_CONTACT, payload: response, currentContactId: contactId });
      return response;
    } catch (error) {
      return onError(error);
    }
  }
);

export default addContactToGroup;
