import React, { useRef, useState } from 'react';
import { useInvalidateQueries } from 'src/api/APIHooks';
import {
  ColumnHeader,
  DataCell,
  DataRow,
  HeaderRow,
  Table,
  TableBody,
} from 'src/common/tables/V2';
import _ from 'lodash';
import DialogV2 from 'common/modal/DialogV2';
import { Button, DateField } from '@unite-us/ui';
import { validations } from '@unite-us/app-components';
import { Form, Field } from 'react-final-form';
import { Spinner } from 'common/spinners';
import today from 'src/common/utils/today';
import { useContactHqUserOnly, useRevokeConsent } from 'src/common/hooks';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import Notifier from 'common/helpers/Notifier';
import moment from 'moment';
import SearchBar from 'src/common/SearchBar/SearchBar';
import { consentStatuses } from './utils/consentStatusConstants';

const RevokeConsent = () => {
  const [selectedContact, setSelectedContact] = useState({});
  const [dialogContent, setDialogContent] = useState(null);
  const [personId, setPersonId] = useState('');
  const revokeConsentDialogRef = useRef(null);
  const invalidateQueries = useInvalidateQueries();
  const { updateRecord: revokeConsent } = useRevokeConsent();

  const { data: contactResponse, isFetching } = useContactHqUserOnly(personId);

  const confirmRevoke = async (contact) => {
    const date = _.get(document.getElementById('revoked-date-field'), 'value');

    if (!date || date === 'Invalid date') {
      validations.isRequired();
    } else {
      revokeConsentDialogRef.current.closeDialog();
      const response = await revokeConsent(_.get(contact, 'consent.id'), {
        state: 'revoked',
        revoked_on: moment(date).utc().format('YYYY-MM-DD'),
      });

      if (isHttpSuccess(response.status)) {
        Notifier.dispatch(
          response.status,
          `${contact.first_name} ${contact.last_name} has had their consent revoked`,
        );
        invalidateQueries('person');
        invalidateQueries('consent');
      }
    }
  };

  const revokeConsentContent = (contact) => setDialogContent(
    <div className="revoke-consent-content">
      <p className="mb-4">
        {'The following client will have their consent revoked:'}
      </p>
      <p className="font-bold">
        {`${_.get(contact, 'first_name')} ${_.get(contact, 'last_name')}
        (Client ID: ${_.get(contact, 'id')})`}
      </p>
      <p className="mt-10">
        {'Enter the date that the Revoke Consent request was received:'}
      </p>
      <div className="mt-6">
        <Form
          onSubmit={() => {}}
        >
          {() => (
            <form>
              <div>
                <Field
                  className="ui-interaction-type-field__revoked-date-field w-5/12"
                  id="revoked-date-field"
                  label="Request Received Date"
                  name="revoked_on"
                  required
                  validate={(v) => validations.isRequired(v)}
                  customErrorMessage="Request Received Date cannot be later than today"
                  initialValue={moment(new Date()).format('MM/DD/YYYY')}
                  maxDate={today()}
                >
                  {(params) => (
                    <DateField
                      {...params}
                    />
                  )}
                </Field>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>,
  );

  const deleteButtonOnClickHandler = (e, contact) => {
    e.stopPropagation();
    revokeConsentContent(contact);
    setSelectedContact(contact);
    revokeConsentDialogRef.current.openDialog();
  };

  const cancelRevoke = () => {
    revokeConsentDialogRef.current.closeDialog();
  };

  const handleOnSubmitSearchBar = (data) => {
    setPersonId(data?.search);
  };

  return (
    <>
      <div
        className="flex px-8 pt-8 pb-2"
        id="TopOfContacts"
        data-testid="revoke-consent-header"
        style={{ background: '#ECF5FD' }}
      >
        <div className="w-9/12 flex flex-row relative">
          <h1 className="pt-1 font-bold text-center">Revoke Consent</h1>
          <SearchBar
            onSubmit={handleOnSubmitSearchBar}
            dataTestId="revoke-consent-client-id-field"
            placeholder="Enter Client ID"
          />
        </div>
      </div>
      {personId && (
      <div className="contacts-table flex flex-col" data-testid="contacts-table-container">
        <div className="overflow-x-auto">
          <div className="py-2 align-middle sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mb-5">
              {isFetching ? <Spinner /> : (
                <Table className="min-w-full divide-y divide-gray-200">
                  <HeaderRow className="bg-gray-100">
                    <ColumnHeader
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-50"
                    >
                      Client Name
                    </ColumnHeader>
                    <ColumnHeader
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-25"
                    >
                      Client ID
                    </ColumnHeader>
                    <ColumnHeader
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-25"
                    >
                      Consent Status
                    </ColumnHeader>
                    <ColumnHeader
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-25"
                    >
                      Action
                    </ColumnHeader>
                  </HeaderRow>
                  {contactResponse === undefined ? (
                    <TableBody>
                      <tr className="text-center">
                        <td colSpan="4">{'No Client Found'}</td>
                      </tr>
                    </TableBody>
                  ) : (
                    <TableBody>
                      <DataRow
                        className={
                        'bg-white hover:bg-gray-300'
                      }
                        key={contactResponse.id}
                      >
                        <DataCell className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {`${contactResponse.first_name} ${contactResponse.last_name}`}
                        </DataCell>
                        <DataCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {contactResponse.id}
                        </DataCell>
                        <DataCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {contactResponse.consent ?
                            consentStatuses[_.get(contactResponse, 'consent.state')] :
                            consentStatuses[['needs-consent']]}
                        </DataCell>
                        <DataCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          { _.get(contactResponse, 'consent.state') === 'accepted' ? (
                            <Button
                              id={`revoke-consent-${contactResponse.consent.id}-button`}
                              className="py-1 px-4 text-xs text-current-black rounded
                            border-action-blue border-opacity-25 revoke-consent-btn"
                              label="Revoke Consent"
                              onClick={(e) => deleteButtonOnClickHandler(e, contactResponse)}
                            />
                          ) : '---'}
                        </DataCell>
                      </DataRow>
                      <DialogV2
                        cancelHandler={cancelRevoke}
                        confirmLabel="Revoke Consent"
                        confirmationHandler={() => confirmRevoke(selectedContact)}
                        ref={revokeConsentDialogRef}
                        scrollable={false}
                        title="Revoke Consent for Selected Client"
                      >
                        {dialogContent}
                      </DialogV2>
                    </TableBody>
                  )}
                </Table>
              )}
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default RevokeConsent;
