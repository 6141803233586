import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'common/utils/browserHistory';
import { isOrganizationAdministrator } from 'common/utils/Roles/isOrgAdmin';
import { NewItemStepper } from 'common/display/NewItemStepper';
import { orgAdminEnhancements } from 'common/utils/FeatureFlags/flags';
import { useFindProvider } from 'src/components/Organization/api/hooks/v1';
import './Organization.scss';

const OrganizationContainer = ({
  children,
  location,
  userRoles,
  allRoles,
  providerId,
  orgAdminEnhancements: orgAdminEnhancementsFlag,
}) => {
  useEffect(() => {
    if (!isOrganizationAdministrator({ userRoles, allRoles })) {
      browserHistory.push('/');
    }
  }, [userRoles, allRoles]);
  const { data: provider } = useFindProvider({ providerId });
  const showDraftStepper = orgAdminEnhancementsFlag && provider?.state === 'draft';

  return (
    <div>
      {showDraftStepper ? (
        <NewItemStepper location={location}>{children}</NewItemStepper>
        ) :
        (
          <div className="organization-settings">
            {children}
          </div>
        )}
    </div>
  );
};

OrganizationContainer.propTypes = {
  children: PropTypes.element.isRequired,
  userRoles: PropTypes.array.isRequired,
  allRoles: PropTypes.shape({
    programRoles: PropTypes.array.isRequired,
    restrictedRoles: PropTypes.array.isRequired,
    systemRoles: PropTypes.array.isRequired,
  }).isRequired,
  providerId: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  orgAdminEnhancements: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const userRoles = state.globalState.currentEmployee.roles;
  const allRoles = state.globalState.roles;
  const providerId = state.globalState.currentProvider.group.id;
  return {
    userRoles,
    allRoles,
    providerId,
    orgAdminEnhancements: orgAdminEnhancements(state),
  };
}

export default connect(mapStateToProps)(OrganizationContainer);
