import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { Icon } from '@unite-us/app-components';
import useOutsideAlerter from './useOutsideAlerter';
import './TabbedSelectField.scss';

const TabbedSelectField = ({
  className,
  clearable,
  data,
  forceOpen,
  label,
  listBoxUpOptionsClassName,
  name,
  onChange,
  onSearch,
  placeholder,
  query,
  searchDescription,
  searchEnabled,
  searchPlaceholder,
  searchTermSelectable,
  truncateOptions,
  value,
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState(query);
  const selectRef = useRef();
  const searchRef = useRef();

  const noResultsOption = {
    disabled: true,
    displayName: 'No results found',
  };

  const searchTermOption = useMemo(() => (query ? {
    addresses: [],
    disabled: false,
    displayName: query,
    id: query.replace(/ /g, '-'),
    name: query,
    position: undefined,
    programs: [],
    provider_type: 'provider',
  } : null), [query]);

  useOutsideAlerter(selectRef, () => setIsOptionsOpen(false));

  useEffect(() => {
    if (isOptionsOpen) {
      searchRef.current.focus();
    }
  }, [isOptionsOpen]);

  const onClearClick = (event) => {
    event.stopPropagation();
    if (onChange) onChange('');
  };

  const handleKeyDown = (option) => (e) => {
    switch (e.key) {
      case ' ':
      case 'SpaceBar':
      case 'Enter':
        e.preventDefault();
        if (onChange) onChange(option);
        setIsOptionsOpen(false);
        break;
      default:
        break;
    }
  };

  const toggleOptions = (event = {}) => {
    event.preventDefault();
    setIsOptionsOpen(() => !isOptionsOpen);
  };

  return (
    <div
      ref={selectRef}
      className={classNames(className, 'antialiased ui-select-field ui-form-field choices no-focus-outline')}
    >
      <label
        className={classNames(
          'block mb-1 leading-snug uppercase',
          'font-extrabold font-medium-font',
          'text-13px ui-form-field__label',
        )}
        htmlFor={`tabbed-select-${name}`}
      >
        {label}
      </label>
      <div className="relative choices-inner">
        <button
          id={`tabbed-select-${name}`}
          className={classNames(
            'choices-inner cursor-pointer relative w-full bg-white cursor-default text-left',
            'pl-3 py-2 border border-solid border-dark-border-blue rounded',
            'border-dark-border-blue hover:border-action-blue focus:border-action-blue',
            isOptionsOpen && 'rounded-b-none border-action-blue',
            !isOptionsOpen && 'shadow-sm',
            clearable && value ? 'pr-12' : 'pr-10',
          )}
          onClick={toggleOptions}
          aria-haspopup="listbox"
          aria-expanded={isOptionsOpen}
          type="button"
        >
          <span className="block h-5 leading-5 truncate font-medium-font">
            {(!_.isEmpty(value) && value.displayName)}
            {_.isEmpty(value) && <span aria-hidden className="placeholder">{placeholder}</span>}
          </span>
          {
            clearable && !_.isEmpty(value) && (
              <span
                className="absolute opacity-50 inset-y-0 right-4 flex items-center pr-4"
              >
                <Icon
                  color={'#000000'}
                  icon="Cross"
                  size={8}
                  onClick={onClearClick}
                  ariaLabel={`Clear ${label}`}
                />
              </span>
            )
          }
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <Icon
              aria-hidden
              className="text-gray"
              icon="ChevronArrow"
              direction={isOptionsOpen ? 'up' : 'down'}
              size={15}
            />
          </span>
        </button>
        {(isOptionsOpen || forceOpen) && (
          <div
            data-test-element="tabbed-select-options"
            className={classNames(
              `absolute z-dropdown border border-solid border-dark-border-blue 
                  border-action-blue box-content pl-0 py-1 z-10 w-full max-h-80 overflow-auto 
                  bg-white shadow-lg rounded overflow-hidden border-t-0 rounded-t-none option-list-container`,
              listBoxUpOptionsClassName,
            )}
            // eslint-disable-next-line react/no-unknown-property
            static
          >
            {searchEnabled && (
              <>
                <input
                  data-test-element="search"
                  placeholder={searchPlaceholder}
                  className="w-full px-3 border-b-2 border-solid border-medium-fill-blue search-input"
                  onChange={(e) => { onSearch(e); setSearchTerm(e.target.value); }}
                  tabIndex={0}
                  value={searchTerm}
                  ref={searchRef}
                />
                <div className="p-3 font-medium-font text-sm">
                  {searchDescription}
                </div>
              </>
            )}
            <Tab.Group onChange={setSelectedTab} selectedIndex={selectedTab}>
              <Tab.List className="w-full border-b-2 border-solid border-medium-fill-blue">
                {data.map((tab) => (
                  <Tab key={tab.id} className="ml-2.5 tab-header">
                    {({ selected }) => (
                      <div
                        className={`font-extrabold text-text-blue p-3 
                          ${selected && 'tab-header-selected border-solid border-action-blue'}`}
                      >
                        {tab.label}
                      </div>
                    )}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels>
                {data.map((tab) => {
                  const tabOptions = searchTermSelectable && searchTermOption ?
                    [searchTermOption, ...tab.options] :
                    tab.options;

                  if (!tabOptions.length) tabOptions.push(noResultsOption);

                  return (
                    <Tab.Panel>
                      <ul className="pl-0 light-border-grey h-48 overflow-y-scroll option-list">
                        {tabOptions.map((option) => {
                          const selected = value &&
                            option.id === value.id;
                          return (
                            <li
                              className={
                                `relative py-2 pl-3 pr-9 select-none 
                              ${!option.disabled && 'cursor-pointer hover:bg-medium-fill-blue hover:border-l-2'}`
                              }
                              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                              role="button"
                              value={option}
                              key={`${option.id}`}
                              onKeyDown={() => {
                                if (option.disabled) return;
                                handleKeyDown(option);
                              }}
                              onClick={() => {
                                if (option.disabled) return;
                                if (onChange) { onChange(option); }
                                setIsOptionsOpen(false);
                              }}
                              tabIndex={0}
                            >
                              <>
                                <span
                                  className={classNames(
                                    'block leading-4 font-normal result-item',
                                    truncateOptions && 'truncate',
                                    selected ? 'font-semibold mr-8' : 'font-normal',
                                  )}
                                  aria-selected={selected}
                                >
                                  {option.displayName}
                                </span>
                                {selected && (
                                  <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                    <Icon
                                      aria-hidden
                                      className={classNames(
                                        'h-5 w-5 fill-current text-action-blue',
                                      )}
                                      icon="Check"
                                    />
                                  </span>
                                )}
                              </>
                            </li>
                          );
                        })}
                      </ul>
                    </Tab.Panel>
                  );
                })}
              </Tab.Panels>
            </Tab.Group>
          </div>
        )}
      </div>
    </div>
  );
};

TabbedSelectField.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
      displayName: PropTypes.string,
    })),
  })).isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  clearable: PropTypes.bool,
  forceOpen: PropTypes.bool,
  listBoxUpOptionsClassName: PropTypes.string,
  truncateOptions: PropTypes.bool,
  searchDescription: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    id: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
    displayName: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  searchEnabled: PropTypes.bool,
  searchTermSelectable: PropTypes.bool,
  onSearch: PropTypes.func,
  name: PropTypes.string.isRequired,
  query: PropTypes.string,
};

TabbedSelectField.defaultProps = {
  className: '',
  label: '',
  clearable: false,
  forceOpen: false,
  listBoxUpOptionsClassName: '',
  truncateOptions: false,
  searchDescription: '',
  searchPlaceholder: '',
  searchTermSelectable: false,
  placeholder: '',
  value: null,
  searchEnabled: false,
  onSearch() { },
  query: '',
};

export default TabbedSelectField;
