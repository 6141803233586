import {
  FETCH_NETWORK_GROUPS,
  SET_NETWORK_GROUP_FETCH,
  UNSET_NETWORK_GROUP_FETCH,
} from 'actions';
import { get, isArray } from 'lodash';

const defaultState = {
  isFetching: false,
  data: [],
  paging: {},
};

function networkGroupsToArray(groups = []) {
  return isArray(groups) ? groups : [groups];
}

export default function networkGroupsReducer(state = defaultState, action = {}) {
  const { payload } = action;

  switch (action.type) {
    case SET_NETWORK_GROUP_FETCH:
      return { ...state, isFetching: true };

    case UNSET_NETWORK_GROUP_FETCH:
      return { ...state, isFetching: false };

    case FETCH_NETWORK_GROUPS:
      return {
        ...state,
        data: networkGroupsToArray(get(payload, 'data.data', [])),
        paging: get(payload, 'data.paging', {}),
      };

    default:
      return state;
  }
}
