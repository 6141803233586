import moment from 'moment';
import formatDuration from './formatDuration';

const getTimeDifference = (pastTime) => {
  const past = moment(pastTime).unix();
  const now = moment().unix();

  const diff = now - past;
  return formatDuration(diff);
};

export default getTimeDifference;
