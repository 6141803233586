import PropTypes from 'prop-types';
import React from 'react';
import PhoneNumbers from './components/Displays/PhoneNumbers';
import EmailAddress from './components/Displays/EmailAddress';
import './stylesheets/contactInformation.scss';

function ContactInformation(props) {
  const { employee } = props;

  return (
    <div className="row settings-contact-information">
      <div className="col-sm-12">
        <h3>Contact Information</h3>
        <br />
        <div className="settings-contact-information__phone-numbers">
          <PhoneNumbers
            employee={employee}
          />
        </div>
        <div className="settings-contact-information__emails">
          <EmailAddress
            employee={employee}
          />
        </div>
      </div>
    </div>
  );
}

ContactInformation.propTypes = {
  employee: PropTypes.object.isRequired,
};

export default ContactInformation;
