import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import { Tabs, Tab } from '@unite-us/ui';

class UserSettings extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = { value: '' };
  }

  onChange(value = '') {
    this.setState({ value });
    const path = value ? `/user/settings/${value}` : '/user/settings';
    browserHistory.push(path);
  }

  render() {
    const { children } = this.props;

    return (
      <div className="user-settings">
        <div className="row">
          <div className="col-sm-10 offset-sm-1 pt-5">
            <Tabs
              id="user-settings-tabs"
              className="user-settings-tabs"
              value={this.state.value}
              onChange={this.onChange}
            >
              <Tab
                id="user-profile-tab"
                value=""
                label="Account Information"
              />
              <Tab
                id="user-notifications-tab"
                value="notifications"
                label="Notification Settings"
              />
            </Tabs>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

UserSettings.propTypes = {
  children: PropTypes.any.isRequired,
};

export default UserSettings;
