import { each } from 'lodash';
import classNames from 'classnames';

const createClassNames = (classes, baseClass) => {
  const classObj = {};

  each(classes, (className) => {
    classObj[className] = classNames({
      [className]: true,
      [`${baseClass}-${className}`]: baseClass,
    });
  });

  return classObj;
};

export default createClassNames;
