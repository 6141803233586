export const createServiceOptions = (services) => {
  let serviceOptions = Object.values(services);
  serviceOptions = serviceOptions.map((service) => ({
    id: service.id,
    name: service.name,
    children: service.children.map((child) => ({
      id: child.id,
      name: child.name,
      parent: service.id,
    })),
  }));
  return serviceOptions;
};

// takes form questions and transforms them for expandable choice list
export const convertQuestionsForList = (questions) => (
  questions
    .filter((q) => (q.type !== 'html' && q.type !== 'panel'))
    .map((question) => ({
      title: question.title ?? question.name,
      id: question.id ?? question.name,
      choices: question.choices ?
        question.choices.map((answer) => ({
          text: answer.text ?? answer.value,
          id: answer.id ?? answer.value,
        })) :
        undefined,
    }))
);

// creates answerId key, SelectField value pairing
export const createAnswerServicePairing = ((questionList, formNeedConfigurations) => {
  const answerServicePairing = {};
  for (let i = 0; i < questionList.length; i++) {
    for (let j = 0; j < questionList[i]?.choices?.length; j++) {
      const inputOptionId = questionList[i].choices[j].id;
      answerServicePairing[inputOptionId] = [];
    }
  }
  formNeedConfigurations.forEach((configuration) => (
    configuration.input_option_scores.forEach((answer) => {
      if (answerServicePairing[answer.input_option_id]) {
        answerServicePairing[answer.input_option_id].push({ id: configuration.service.id });
      } else {
        answerServicePairing[answer.input_option_id] = [{ id: configuration.service.id }];
      }
    })
  ));
  return answerServicePairing;
});

// updates the serviceType count for question list
export const updateAnswerServiceTypeCount = (questionList, answerServicePairing) => {
  const cleanQuestionList = [...questionList];
  return cleanQuestionList.map((question) => ({
    ...question,
    choices: question.choices ?
      question.choices.map((answer) => ({
        ...answer,
        count: answerServicePairing[answer.id].length,
      })) :
      undefined,
  }));
};

// creates serviceId key, input_option_scores pairing
export const createServiceInputPairing = (answerServicePairing) => {
  const serviceInputPairing = {};
  for (const [answerId, selectedServices] of Object.entries(answerServicePairing)) {
    selectedServices.forEach((service) => {
      if (serviceInputPairing[service.id]) {
        serviceInputPairing[service.id].push({ value: 1, input_option_id: answerId });
      } else {
        serviceInputPairing[service.id] = [{ value: 1, input_option_id: answerId }];
      }
    });
  }
  return serviceInputPairing;
};
