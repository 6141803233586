import React, { useContext, useState } from 'react';
import { useCurrentPayerId, useCurrentProviderId, useIsNetworkLead } from 'common/contexts/CurrentProviderContext';
import { INVOICE } from 'common/utils/EventTracker/utils/eventConstants';
import callOrLog from 'src/common/utils/callOrLog';
import { connect } from 'react-redux';
import { TrackerContext } from '@unite-us/client-utils';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { SelectField } from 'components/Backoffice/form/SelectField';
import { SearchableField } from 'components/Backoffice/form/SearchableField';
import { SearchableSelectField } from 'components/Backoffice/form/SearchableSelectField';
import {
  useFeeScheduleProgramOptions,
  useManagedProviderOptions,
  usePayerProviderOptions,
  useInvoiceShortIdOptions,
} from 'src/pages/invoices/hooks';
import { hasPayerInvoicesRole, useInvoiceDisputeWorkflow } from 'common/utils/FeatureFlags/flags';
import { Icon } from '@unite-us/ui';
import * as options from './constants';

const FilterSelectors = ({
  managedProvider,
  payerProvider,
  networkId,
  onUpdateFilter,
  serviceProvided,
  invoiceShortId,
  archived,
  disputed,
  setManagedProvider,
  setPayerProvider,
  setServiceProvided,
  setInvoiceShortId,
  setStatus,
  setUnderDispute,
  showStatusFilter,
  status,
  underDispute,
  statusOptionsConstant,
  showInvoiceDisputeWorkflow,
  showPayerInvoices,
  setOpenAllFiltersDrawer,
  payerWQInteractiveView,
  showInvoiceType,
  invoiceTypes,
  setInvoiceType,
  invoiceType,
}) => {
  const [shortIdSearchQuery, setShortIdSearchQuery] = useState(invoiceShortId);
  const isNetworkLead = useIsNetworkLead();
  const providerId = useCurrentProviderId();
  const payerId = useCurrentPayerId();
  const servicesProvided = useFeeScheduleProgramOptions(providerId);
  const nlProviders = useManagedProviderOptions(providerId);
  const payerProviders = usePayerProviderOptions(payerId);

  const eventTracker = useContext(TrackerContext);

  const setNewServicesProvided = (value) => {
    setServiceProvided(value);
    onUpdateFilter();
    callOrLog(() => eventTracker(
      INVOICE.invoiceFilterServiceProvidedClicked,
      { current_network: networkId },
    ));
  };

  const setNewManagedProvider = (value) => {
    setManagedProvider(value);
    onUpdateFilter();
  };

  const setNewPayerProvider = (value) => {
    setPayerProvider(value);
    onUpdateFilter();
  };

  let statusOptions;

  let conditionalSelectField;

  if (showPayerInvoices) {
    conditionalSelectField = (
      <SelectField
        className="w-48"
        label="Provider"
        onChange={({ value }) => setNewPayerProvider(value)}
        options={payerProviders}
        value={payerProvider}
        placeholder="Choose a Provider"
        clearable
      />
    );
  } else if (isNetworkLead) {
    conditionalSelectField = (
      <SearchableSelectField
        className="w-72"
        label="Provider"
        onChange={({ value }) => setNewManagedProvider(value)}
        options={nlProviders}
        placeholder="Choose Provider"
        value={managedProvider}
        truncateOptions={false}
        clearable
      />
    );
  } else {
    conditionalSelectField = (
      <SearchableSelectField
        className="w-80"
        label="Service Provided"
        onChange={({ value }) => setNewServicesProvided(value)}
        options={servicesProvided}
        placeholder="Choose Service Provided"
        value={serviceProvided}
        truncateOptions={false}
        clearable
      />
    );
  }

  if (showPayerInvoices) {
    statusOptions = options.PAYER_STATUS_OPTIONS;
    if (!payerWQInteractiveView) {
      statusOptions = options.READ_ONLY_ALL_PAYER_STATUS_OPTIONS;
    }
  } else if (isNetworkLead) {
    statusOptions = options.NL_STATUS_OPTIONS;
  } else {
    statusOptions = options.STATUS_OPTIONS;
  }

  if (statusOptionsConstant) {
    statusOptions = options[statusOptionsConstant];

    if (disputed && showPayerInvoices) {
      statusOptions = options.CLOSED_PAYER_STATUS_OPTIONS;
    }
  }

  if (statusOptionsConstant === 'OPEN_PAYER_STATUS_OPTIONS' && !payerWQInteractiveView) {
    statusOptions = options.READ_ONLY_OPEN_PAYER_STATUS_OPTIONS;
  }

  statusOptions = statusOptions.map((option) => (
    { label: option.label, value: option.value, id: option.value }));

  if (!showInvoiceDisputeWorkflow) {
    statusOptions = statusOptions.filter((option) => (option.label !== 'In Dispute'));
  }

  const shortIdFilters = {
    invoice_status: statusOptions.map((i) => i.value).join(','),
    has_user_archive: archived,
    under_dispute: disputed,
  };

  const setNewStatus = (value) => {
    if (showInvoiceDisputeWorkflow && value === 'under_dispute') {
      setUnderDispute(true);
    } else {
      setStatus(value);
      setUnderDispute(false);
    }
    onUpdateFilter();
    callOrLog(() => eventTracker(
      INVOICE.invoiceFilterStatusClicked,
      { current_network: networkId },
    ));
  };

  const shortIdOptions = useInvoiceShortIdOptions(
    providerId,
    payerId,
    isNetworkLead,
    shortIdSearchQuery,
    shortIdFilters,
    statusOptionsConstant,
  );

  const setNewInvoicesSearch = (value) => {
    setInvoiceShortId(value);
    onUpdateFilter();
    callOrLog(() => eventTracker(
      INVOICE.invoiceFilterInvoiceShortIdClicked,
      { current_network: networkId },
    ));
  };

  return (
    <div
      className="flex items-start space-x-4 mb-5 py-5 align-bottom"
    >
      {
        showStatusFilter && (
          <SelectField
            className="w-48"
            label="Status"
            onChange={({ value }) => setNewStatus(value)}
            options={statusOptions}
            value={underDispute ? 'under_dispute' : status}
            placeholder="Choose Status"
            clearable
          />
        )
      }
      {showInvoiceType && (
        <SelectField
          className="w-56"
          label="Invoice Type"
          onChange={({ value }) => setInvoiceType(value || '')}
          options={invoiceTypes}
          value={invoiceType}
          placeholder="Choose an Invoice Type"
          clearable
        />
      )}
      <SearchableField
        className="w-52"
        label="Invoice ID"
        onChange={({ value }) => setNewInvoicesSearch(value)}
        searchQuery={shortIdSearchQuery}
        options={shortIdOptions}
        onInputChange={setShortIdSearchQuery}
        placeholder={'Search for an invoice'}
        truncateOptions={false}
        clearable
      />
      <div data-testid="provider-filter-container">
        {conditionalSelectField}
      </div>
      <button
        type="button"
        className="min-w-max pt-8 md:pt-6 lg:pt-6 lg:max-xlg:6 xl:pt-8 "
        onClick={() => setOpenAllFiltersDrawer(true)}
      >
        <Icon
          icon="IconFilter"
          color="#2C405A"
        />
        <span className="ml-1">All Filters</span>
      </button>
    </div>
  );
};

FilterSelectors.propTypes = {
  managedProvider: PropTypes.string.isRequired,
  payerProvider: PropTypes.string.isRequired,
  networkId: PropTypes.string.isRequired,
  onUpdateFilter: PropTypes.func.isRequired,
  serviceProvided: PropTypes.string.isRequired,
  invoiceShortId: PropTypes.string.isRequired,
  archived: PropTypes.bool,
  disputed: PropTypes.bool,
  setManagedProvider: PropTypes.func.isRequired,
  setPayerProvider: PropTypes.func.isRequired,
  setServiceProvided: PropTypes.func.isRequired,
  setStatus: PropTypes.func,
  setUnderDispute: PropTypes.func,
  setInvoiceShortId: PropTypes.func,
  showStatusFilter: PropTypes.bool,
  status: PropTypes.string,
  underDispute: PropTypes.bool,
  statusOptionsConstant: PropTypes.string,
  showInvoiceDisputeWorkflow: PropTypes.bool,
  showPayerInvoices: PropTypes.bool,
  setOpenAllFiltersDrawer: PropTypes.func,
  payerWQInteractiveView: PropTypes.bool,
  showInvoiceType: PropTypes.bool.isRequired,
  invoiceTypes: PropTypes.array,
  setInvoiceType: PropTypes.func.isRequired,
  invoiceType: PropTypes.string.isRequired,
};

FilterSelectors.defaultProps = {
  status: '',
  underDispute: false,
  setStatus: noop,
  setUnderDispute: noop,
  setInvoiceShortId: noop,
  showStatusFilter: false,
  statusOptionsConstant: null,
  showInvoiceDisputeWorkflow: false,
  showPayerInvoices: false,
  setOpenAllFiltersDrawer: noop,
  payerWQInteractiveView: false,
  archived: undefined,
  disputed: undefined,
  invoiceTypes: null,
};

FilterSelectors.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const networkId = state.networks.networkId;
  return {
    networkId,
    showPayerInvoices: hasPayerInvoicesRole(state),
    showInvoiceDisputeWorkflow: useInvoiceDisputeWorkflow(state),
  };
}

export default connect(mapStateToProps)(FilterSelectors);
