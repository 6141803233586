import {
  FETCH_GROUPS_USERS,
  GROUPS_USER_UNSET_FETCHING,
} from 'actions';
import fetchEmployees from 'src/api/core/Employees/fetchEmployees';
import fetchAuthUsers from 'src/actions/User/User/Provider/fetchAuthUsers';
import convertCoreUser from 'src/api/utils/convertCoreUser';
import convertCorePaging from 'src/api/utils/convertCorePaging';

const onError = (dispatch) => {
  dispatch({ type: GROUPS_USER_UNSET_FETCHING });
  return false;
};

const onSuccess = (dispatch, employees, meta) => {
  try {
    const newData = employees.map((employee) => convertCoreUser(employee));
    const paging = convertCorePaging(meta.page);

    if (employees && employees.length) {
      const userIds = employees
        .filter((employee) => employee.user)
        .map((employee) => employee.user);
      dispatch(fetchAuthUsers(userIds));
    }

    const payload = {
      data: {
        data: newData,
        paging,
      },
    };
    dispatch({
      type: FETCH_GROUPS_USERS,
      payload,
    });
    dispatch({ type: GROUPS_USER_UNSET_FETCHING });
    const users = payload.data.data.map((user) => user.user);
    const employeesOnly = payload.data.data.map((user) => user.employee);
    return {
      response: payload,
      users,
      employees: employeesOnly,
    };
  } catch (error) {
    return onError(dispatch);
  }
};

export default function fetchProvidersUser({
  providers,
  options = {
    text: undefined,
    page: 1,
    size: 50,
    licensed: true, // by default, get only 'active' users
  },
}) {
  return async (dispatch) => {
    try {
      let licensed = options.licensed;
      if (options.licensed === undefined || options.licensed === null) {
        licensed = true;
      }
      const employeeResponse = await fetchEmployees({
        providers,
        query: options.text,
        page: options.page,
        size: options.size,
        licensed,
        sort: 'last_name,first_name',
      })(dispatch);
      const { employees, response: { data: { meta } } } = employeeResponse;
      if (!employees) {
        return onError(dispatch);
      }
      return onSuccess(dispatch, employees, meta);
    } catch (error) {
      return onError(dispatch, error);
    }
  };
}
