import React from 'react';
import PropTypes from 'prop-types';
import {
  ColumnHeader,
  HeaderRow,
  Table,
  TableBody,
} from 'src/common/tables/V2';
import ServiceAuthorizationTableRow from './ServiceAuthorizationTableRow';

const ServiceAuthorizationsTable = ({
  authorizationRequests,
  showTimeInQueue,
}) => (
  <Table className="bg-white w-full table-fixed">
    <HeaderRow>
      <ColumnHeader className="w-2" dataTestId="request-id-column-header">Request ID</ColumnHeader>
      <ColumnHeader className="w-2">Amount</ColumnHeader>
      <ColumnHeader className="w-2">Client Name</ColumnHeader>
      <ColumnHeader className="w-2" dataTestId="status-column-header">Status</ColumnHeader>
      <ColumnHeader className="w-10" dataTestId="contracted-program-column-header">Contracted Program</ColumnHeader>
      <ColumnHeader className="w-3">Submitted By</ColumnHeader>
      <ColumnHeader className="w-2">
        {showTimeInQueue ? 'Time In Queue' : 'Last Updated'}
      </ColumnHeader>
    </HeaderRow>
    <TableBody data-testid="authorizations-table-body">
      {authorizationRequests.map((request) => (
        <ServiceAuthorizationTableRow
          key={request.id}
          authorizationRequest={request}
          showTimeInQueue={showTimeInQueue}
        />
      ))}
    </TableBody>
  </Table>
);

ServiceAuthorizationsTable.propTypes = {
  authorizationRequests: PropTypes.array.isRequired,
  showTimeInQueue: PropTypes.bool,
};

ServiceAuthorizationsTable.defaultProps = {
  showTimeInQueue: false,
};

export default ServiceAuthorizationsTable;
