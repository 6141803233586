import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@unite-us/ui';
import { SORT_BY_OPTIONS } from 'src/components/Browse/constants';

const SortBySelect = (props) => <SelectField {...props} />;

SortBySelect.propTypes = {
  clearable: PropTypes.bool,
  component: PropTypes.func,
  hideError: PropTypes.bool,
  hideLabel: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  searchEnabled: PropTypes.bool,
  value: PropTypes.string,
  valueKey: PropTypes.string,
};

SortBySelect.defaultProps = {
  clearable: false,
  component: SelectField,
  hideError: true,
  hideLabel: true,
  id: 'sort-by-select',
  label: 'Sort By',
  labelKey: 'label',
  name: 'sortBy',
  options: SORT_BY_OPTIONS,
  searchEnabled: false,
  value: 'distance',
  valueKey: 'value',
};

export default SortBySelect;
