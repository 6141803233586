import { get } from 'lodash';
import { useFind } from '../../../api/APIHooks';

const usePayerProviderPlans = (payerId, isPayer) => {
  const { isFetching, data } = useFind(
    'plan',
    { payer: payerId },
    {
      queryConfig: {
        enabled: !!payerId && isPayer,
      },
    },
  );

  const planData = get(data, 'data.data') || [];

  const feeScheduleIds = planData.reduce((ids, plan) => {
    const feeSchedules = plan.fee_schedules;
    feeSchedules.map((fs) => ids.push(fs.id));
    return ids;
  }, []);

  return isFetching ? [] : feeScheduleIds;
};

export default usePayerProviderPlans;
