import {
  FETCH_GROUPS_USERS,
  FETCH_GROUPS_USER,
  FETCH_GROUPS_USERS_FROM_PROGRAM,
  UPDATE_GROUPS_USER,
  ADD_USER_TO_GROUPS_PROGRAM,
  REMOVE_USER_FROM_GROUPS_PROGRAM,
  GROUPS_USER_SET_FETCHING,
  GROUPS_USER_UNSET_FETCHING,
} from 'actions';
import * as _ from 'lodash';

const defaultState = {
  data: [],
  isFetching: false,
  paging: {},
};

function fetchUsers(state, data, paging) {
  return _.assign({}, state, { data, paging });
}

function fetchUser(state, data) {
  return _.assign({}, state, { data: [data] });
}

function updateUser(state, data) {
  return _.assign({}, state, {
    data: state.data.map((datum) => {
      if (datum.user.id === data.user.id) {
        return _.assign({}, datum, {
          user: data.user,
          roles: data.roles,
          programs: data.programs,
          licensing: data.licensing,
        });
      }
      return datum;
    }),
  });
}

function removeUserFromProgram(state, action) {
  return _.assign({}, state, {
    data: state.data.map((datum) => {
      if (datum.user.id === action.userId) {
        return _.assign({}, datum, {
          programs: _.without(datum.programs, _.find(datum.programs, { id: action.programId })),
        });
      }
      return datum;
    }),
  });
}

function fetchUsersFromProgram(state, data, paging) {
  return { ...state, data, paging };
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case GROUPS_USER_SET_FETCHING:
      return { ...state, isFetching: true };
    case GROUPS_USER_UNSET_FETCHING:
      return { ...state, isFetching: false };
    case FETCH_GROUPS_USERS:
      return fetchUsers(state, action.payload.data.data, action.payload.data.paging);
    case FETCH_GROUPS_USER:
      return fetchUser(state, action.payload.data.data);
    case ADD_USER_TO_GROUPS_PROGRAM:
    case UPDATE_GROUPS_USER:
      return updateUser(state, action.payload.data.data);
    case REMOVE_USER_FROM_GROUPS_PROGRAM:
      return removeUserFromProgram(state, action);
    case FETCH_GROUPS_USERS_FROM_PROGRAM:
      return fetchUsersFromProgram(state, action.payload.data.data, action.payload.data.paging);
    default:
      return state;
  }
}
