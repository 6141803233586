import React, { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import ContactDetailReview from 'common/display/ContactStepper/components/ContactDetailReview';

class NewScreeningReviewContactDetails extends Component {
  static onGoBack() {
    browserHistory.push('/screenings/new/search');
  }

  static goToNextStep() {
    browserHistory.push('/screenings/new/screening');
  }

  render() {
    return (
      <ContactDetailReview
        header="Create Client Screening"
        mainHeader="Review Contact Information"
        subHeader="Please ensure that the contact information we have is accurate."
        onComplete={NewScreeningReviewContactDetails.goToNextStep}
        onGoBack={NewScreeningReviewContactDetails.onGoBack}
      />
    );
  }
}

export default NewScreeningReviewContactDetails;
