import {
  head,
  tail,
  get,
  isEmpty,
} from 'lodash';
import { addElement } from './addElements';
import handleAssistanceRequest from './handleAssistanceRequest';

export function addReferral(state, element, currentSection, currentGroup) {
  const postRemoveState = handleAssistanceRequest(state, element, currentSection);
  const referredById = get(element, 'referred_by_group.id', '');
  const referredToId = get(element, 'referred_to_group.id', '');
  const isConsentStatus = (status) => get(element, 'contact.consent.status') === status;

  if (isConsentStatus('pending') && currentSection === 'pendingConsentReferrals') {
    return addElement(postRemoveState, element, 'pendingConsentReferrals');
  }

  if (isConsentStatus('accepted')) {
    if (referredById === currentGroup && currentSection === 'sentReferrals') {
      return addElement(postRemoveState, element, 'sentReferrals');
    }

    if (referredToId === currentGroup && currentSection === 'newReferrals') {
      return addElement(postRemoveState, element, 'newReferrals');
    }
  }

  return postRemoveState;
}

export default function addReferrals(state, payload = [], currentSection, currentGroup) {
  if (isEmpty(payload)) { return state; }
  return addReferrals(
    addReferral(state, head(payload), currentSection, currentGroup),
    tail(payload),
    currentSection,
    currentGroup,
  );
}
