import React from 'react';
import {
  Icon,
} from '@unite-us/ui';
import { get, isEmpty } from 'lodash';

function getCaseIconAndTooltip({
  iconStyle,
  isCaseMgmtProvider,
  serviceCase,
  hint542SupersetUnlistedPrograms,
}) {
  if (get(serviceCase, 'state') === 'off_platform') {
    return {
      icon: <Icon
        icon="IconElectronsArrow"
        style={{
          svg: {
            marginLeft: '1px',
            ...iconStyle,
          },
        }}
      />,
      iconTooltip: hint542SupersetUnlistedPrograms ? 'Off-Platform Case' : 'Out of Network',
    };
  }

  if (isCaseMgmtProvider && !isEmpty(serviceCase.network)) {
    return {
      icon: <Icon
        icon="IconNetwork"
        style={{
          svg: {
            iconStyle,
          },
        }}
      />,
      iconTooltip: get(serviceCase, 'network.name', ''),
    };
  }

  return {
    icon: null,
    iconTooltip: null,
  };
}

export default getCaseIconAndTooltip;
