import {
  FETCH_EMPLOYEES_STARTED,
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_EMPLOYEES_ERROR,
  SET_EMPLOYEES_PAGING,
  GET_CACHED_EMPLOYEES,
  UPDATE_EMPLOYEE_STARTED,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_ERROR,
} from 'actions';
import { isEmpty } from 'lodash';
import {
  STATUS_INITIAL,
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_ERROR,
} from 'common/Status/constants';
import {
  buildNextPaging,
  buildPrevPaging,
  createPageCollection,
} from 'common/utils/pagination';
import arrayToObject from 'common/utils/arrayToObject';

export const employeePage = {
  userSettings: 'user-settings-page',
};

export const defaultState = {
  status: STATUS_INITIAL,
  updateStatus: STATUS_INITIAL,
  data: {},
  [employeePage.userSettings]: {
    1: [],
    cached: {},
    paging: {
      current_page: 1,
    },
  },
};

export default function employeesReducer(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case FETCH_EMPLOYEES_STARTED:
      return { ...state, status: STATUS_PENDING };
    case FETCH_EMPLOYEES_ERROR:
      return { ...state, status: STATUS_ERROR };
    case UPDATE_EMPLOYEE_STARTED:
      return { ...state, updateStatus: STATUS_PENDING };
    case UPDATE_EMPLOYEE_ERROR:
      return { ...state, updateStatus: STATUS_ERROR };
    case FETCH_EMPLOYEES_SUCCESS: {
      const updatedData = { ...state.data, ...arrayToObject(payload.data) };

      // If pagination isn't needed return just data
      if (isEmpty(payload.pageName)) {
        return {
          ...state,
          data: updatedData,
          status: STATUS_SUCCESS,
        };
      }

      const paging = createPageCollection({
        page: payload.meta.page,
        pageName: payload.pageName,
        prevData: state[payload.pageName],
        data: payload.data,
        cached: payload.cached,
        query: payload.query,
      });

      return {
        ...paging,
        data: updatedData,
        status: STATUS_SUCCESS,
      };
    }

    case SET_EMPLOYEES_PAGING: {
      const { trigger } = payload;

      return {
        ...state,
        [payload.pageName]: {
          ...state[payload.pageName],
          paging: trigger === 'next' ? buildNextPaging(payload.paging) : buildPrevPaging(payload.paging),
        },
      };
    }

    case GET_CACHED_EMPLOYEES: {
      return {
        ...state,
        [payload.pageName]: {
          ...state[payload.pageName].cached,
          cached: state[payload.pageName].cached,
        },
      };
    }

    case UPDATE_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.data.id]: payload.data.data,
        },
        updateStatus: STATUS_SUCCESS,
      };
    }

    default:
      return state;
  }
}
