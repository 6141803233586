export const dateStyles = {
  width: '100px',
  textAlign: 'right',
};

export const phoneStyles = {
  width: '200px',
};

export const serviceTypeStyles = {
  width: '235px',
};

export const statusStyles = {
  width: '80px',
};

export const statusExtendedStyles = {
  width: '110px',
};

export const takeActionStyles = {
  overflow: 'visible',
  width: '70px',
  textAlign: 'right',
};

export const iconStyle = {
  height: '16px',
  width: '16px',
  verticalAlign: 'bottom',
};

export const iconColStyle = {
  width: '16px',
};
