import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@unite-us/ui';
import _ from 'lodash';
import './Avatar.scss';

class DetailAvatar extends Component {
  static groupInitials(group) {
    const name = group.name || '';
    const initials = name.substr(0, 1);
    return initials.toUpperCase();
  }

  static userInitials(user) {
    const firstName = user.first_name || '';
    const lastName = user.last_name || '';
    const initials = firstName.substr(0, 1) + lastName.substr(0, 1);
    return initials.toUpperCase();
  }

  constructor(props) {
    super(props);
    this.state = {
      initials: null,
      source: null,
      url: null,
    };
  }

  componentDidMount() {
    this.setSource(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.user !== nextProps.user) {
      this.setSource(nextProps);
    } else if (this.props.group !== nextProps.group) {
      this.setSource(nextProps);
    }
  }

  setSource(props) {
    if (props.user) {
      if (!_.isEmpty(props.user.avatar_url)) {
        this.setState({ source: 'avatar', url: props.user.avatar_url });
      } else if (props.user.first_name || props.user.last_name) {
        this.setState({ source: 'initials', initials: DetailAvatar.userInitials(props.user) });
      }
    } else if (props.group) {
      if (!_.isEmpty(props.group.logo_url)) {
        this.setState({ source: 'avatar', url: props.group.logo_url });
      } else if (props.group.name) {
        this.setState({ source: 'initials', initials: DetailAvatar.groupInitials(props.group) });
      }
    }
  }

  render() {
    const { style, size } = this.props;
    const { url, source, initials } = this.state;

    if (source === 'avatar') {
      return (
        <Avatar alt="User avatar" image={url} size={size} />
      );
    }

    return (
      <figure data-role="avatar" style={style} className={`detail-avatar ${size}`}>
        <span className="initials">
          {initials}
        </span>
      </figure>
    );
  }
}

DetailAvatar.propTypes = {
  user: PropTypes.object,
  group: PropTypes.object,
  size: PropTypes.string,
  style: PropTypes.object,
};

export default DetailAvatar;
