import _ from 'lodash';

export function removeAllGroupFields(selectedFields) {
  _.each(selectedFields, () => {
    selectedFields.removeField(0);
  });
}

export function removeSelectedGroupFields(selectedFields, toRemoveIds) {
  _.forEachRight(selectedFields, (selected, index) => {
    if (toRemoveIds.includes(selected.group.value.id)) {
      selectedFields.removeField(index);
    }
  });
}
