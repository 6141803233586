import { EMPTY_DRAFT_FIELD } from 'src/components/Group/Programs/constants';

export default ({
  eligibility_text = '',
  population_restrictions = [],
  states = [],
  counties = [],
  cities = [],
  age_requirements = '',
  age_req_min = '',
  age_req_max = '',
  genders = [],
  state_reqs = [],
  postal_code_reqs = [],
  county_reqs = [],
  city_reqs = [],
}) => {
  if (eligibility_text && eligibility_text !== EMPTY_DRAFT_FIELD) return true;
  if (population_restrictions.length) return true;
  if (states.length) return true;
  if (counties.length) return true;
  if (cities.length) return true;
  if (age_requirements) return true;
  if (age_req_min) return true;
  if (age_req_max) return true;
  if (genders.length) return true;
  if (state_reqs?.length) return true;
  if (postal_code_reqs?.length) return true;
  if (county_reqs?.length) return true;
  if (city_reqs?.length) return true;
  return false;
};
