import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from 'lodash';

const DetailStatusTextIcon = ({ icon, iconTooltip }) => (
  !isEmpty(icon) ? (
    <div
      className="detail-status-text-icon"
      data-tooltip={iconTooltip}
      data-tooltip-left
    >
      {icon}
    </div>
  ) : null
);

DetailStatusTextIcon.propTypes = {
  icon: PropTypes.node,
  iconTooltip: PropTypes.node,
};

export default DetailStatusTextIcon;
