import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
} from '@unite-us/ui';
import '../stylesheets/formButtons.scss';

const FormFooter = (props) => (
  <footer className="form-footer">
    {props.children}
    {
      props.hideCancel ?
        null : (
          <Button
            id="form-footer-cancel-btn"
            className="form-footer-cancel-btn"
            label={props.onCancelLabel}
            onClick={props.onCancel}
            style={{ marginRight: '10px' }}
          />
        )
    }
    <Button
      id="form-footer-submit-btn"
      className="form-footer-submit-btn"
      label={props.onSubmitLabel}
      onClick={props.handleSubmit}
      primary
      disabled={props.submitDisabled}
    />
  </footer>
);

FormFooter.propTypes = {
  children: PropTypes.node,
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  onSubmitLabel: PropTypes.string.isRequired,
  onCancelLabel: PropTypes.string,
  hideCancel: PropTypes.bool,
  submitDisabled: PropTypes.bool,
};

FormFooter.defaultProps = {
  onSubmitLabel: 'Save and Continue',
  onCancelLabel: 'Cancel',
  submitDisabled: false,
};

export default FormFooter;
