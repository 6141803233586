import { useFind } from 'src/api/APIHooks';

const useForms = (searchTerm, pageSize, pageNumber) => {
const { data, isFetching } = useFind('form', {
    state: 'published',
    name: searchTerm || '',
  }, {
    page: {
      size: pageSize,
      number: pageNumber,
    },
    api: 'coreApi',
  });
  return {
    data,
    isFetching,
  };
};

export default useForms;
