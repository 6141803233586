import moment from 'moment';

const constructFeeScheduleInitialFormValues = (feeSchedule) => {
  const initialValues = feeSchedule;
  if (initialValues && initialValues.starts_at) {
    initialValues.starts_at = moment(initialValues.starts_at, 'YYYY/MM/DD').format('MM/DD/YYYY');
  }
  if (initialValues && initialValues.ends_at) {
    initialValues.ends_at = moment(initialValues.ends_at, 'YYYY/MM/DD').format('MM/DD/YYYY');
  }
  const ignore_scc = initialValues.ignore_social_care_expired_dates;
  initialValues.ignore_social_care_expired_dates = ignore_scc ? ignore_scc.toString() : 'false';
  return initialValues;
};

export default constructFeeScheduleInitialFormValues;
