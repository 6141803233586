import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classNames from 'classnames';
import { isExternalProviderOrOONGroup } from 'src/components/Groups/utils';
import { Icon } from '@unite-us/ui';
import { createClassNames } from 'common/utils/Styles';
import 'src/components/Groups/GroupDetails/stylesheets/group-details-header-title.scss';

const CLASS_LIST = [
  'group-details-header-title',
  'group-details-header-title__group-icon',
  'group-details-header-title__org',
  'group-details-header-title__service-type',
];

const GroupDetailsHeaderTitle = ({
  baseClass,
  group,
  serviceTypeName,
}) => {
  const classes = createClassNames(CLASS_LIST, baseClass);

  const iconClassName = classNames({
    [classes['group-details-header-title__group-icon']]: true,
    'mr-half': isExternalProviderOrOONGroup(group),
  });

  const groupDetailHeaderTitle = classNames({
    [classes['group-details-header-title']]: true,
  });

  return (
    <div className={groupDetailHeaderTitle}>
      <div className="group-details-header-title__org-container mb-quarter">
        {
          isExternalProviderOrOONGroup(group) && (
            <span
              className={iconClassName}
              data-tooltip="Out of Network Organization"
              data-tooltip-bottom-right
            >
              <Icon icon="IconElectronsArrowV2" color="#2C405A" />
            </span>
          )
        }
        <h2 className={classes['group-details-header-title__org']}>
          {get(group, 'name', 'Loading Org...')}
        </h2>
      </div>

      <p className={classes['group-details-header-title__service-type']}>
        {serviceTypeName}
      </p>
    </div>
  );
};

GroupDetailsHeaderTitle.propTypes = {
  baseClass: PropTypes.string,
  group: PropTypes.shape({
    name: PropTypes.string,
  }),
  serviceTypeName: PropTypes.string,
};

GroupDetailsHeaderTitle.defaultProps = {
  baseClass: '',
  group: {},
  serviceTypeName: '',
};

export default GroupDetailsHeaderTitle;
