import React from 'react';
import {
  Icon,
} from '@unite-us/ui';
import { BODY_GREY_COLOR } from 'src/common/constants';

const ICON_SIZE = 64;

const ReferralAssessmentError = () => (
  <div className="referral-assessment-error">
    <div className="referral-assessment-error__icon">
      <Icon
        icon="ExclamationTriangle"
        color={BODY_GREY_COLOR}
        size={ICON_SIZE}
      />
    </div>
    <div className="referral-assessment-error__header">
      oops! something went wrong
    </div>
    <div className="referral-assessment-error__sub-header">
      Please go back and try again
    </div>
  </div>
);

export default ReferralAssessmentError;
