import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  wget,
  get,
  find,
  isFunction,
  compact,
} from 'lodash';
import { Button } from '@unite-us/ui';
import { SEARCH } from 'common/utils/EventTracker/utils/eventConstants';
import callOrLog from 'common/utils/callOrLog';
import {
  updateGroupContact,
  addContactToGroup,
} from 'actions/Contact/Group';
import mergeContactWithRequestorDetails from 'src/components/Contacts/utils/mergeContactWithRequestorDetails';
import Header from './Header';
import ContactCard from './ContactCard';
import {
  goToAddInformationStep,
  decodeContactData,
} from '../utils';
import {
  COPY_CREATE_CLIENT,
  COPY_MATCHING_RECORDS,
  COPY_MATCHING_RECORDS_SUB_HEADER,
} from '../constants';

export class ContactConfirmation extends Component {
  constructor(props) {
    super(props);

    this.onCreateNewContact = this.onCreateNewContact.bind(this);
    this.onSelectContact = this.onSelectContact.bind(this);
  }

  onCreateNewContact() {
    const {
      activeStep,
      contacts,
      context,
      location: { query: { contact } },
    } = this.props;
    const contactData = decodeContactData(contact);

    // this is usually called when a user ignores the existing records and proceeds to create new client
    callOrLog(() => this.context.eventTracker(SEARCH.ignoredRecords, {
      contacts: contacts.length,
    }));

    if (isFunction(activeStep.onConfirmationComplete)) {
      return activeStep.onConfirmationComplete({ formData: contactData });
    }
    return goToAddInformationStep({ context, contactData });
  }

  onSelectContact(contact) {
    return () => {
      const {
        activeStep,
        assistanceRequest,
        context,
        groupId,
        requestor,
        location: { query },
      } = this.props;
      const arId = get(assistanceRequest, 'id');
      // if there is a contact and a contact id, proceed
      if (contact.id && contact?.person !== null) {
        callOrLog(() => this.context.eventTracker(SEARCH.useRecord[context], {
          contact_id: contact.id,
        }));

        return this.props.addContactToGroup(contact.id)
          .then(() => {
            const mergedContactFomAR = mergeContactWithRequestorDetails(contact, requestor);
            this.props.updateGroupContact(groupId, contact.id, mergedContactFomAR, {
              showNotification: false,
              assistance_request_id: arId,
              forceClientRelationship: true,
            }).then(() => {
              if (isFunction(activeStep.onConfirmationComplete)) {
                return activeStep.onConfirmationComplete({ contactId: contact.id });
              }
              return goToAddInformationStep({
                contactData: decodeContactData(query.contact),
                contactId: contact.id,
                context,
              });
            });
          });
      }
      // let's track that we are selecting an external contact that is not linked to a uu contact
      callOrLog(() => this.context.eventTracker(SEARCH.useRecordNoId[context]));
      const mergedContactFomAR = mergeContactWithRequestorDetails(contact, requestor);
      // Because the addresses of mergedContactFomAR are only the new ones, we need to concatemate the existing ones
      const mergedAddresses = compact([
        ...contact.addresses || [],
        ...mergedContactFomAR.addresses || [],
      ]);
      const contactData = {
        ...contact,
        ...mergedContactFomAR,
        addresses: mergedAddresses,
      };
      if (isFunction(activeStep.onConfirmationComplete)) {
        return activeStep.onConfirmationComplete({ formData: contactData, fromIntegration: true });
      }
      // in this case, the contact is coming from nextgate.
      return goToAddInformationStep({ context, contactData, fromIntegration: true });
    };
  }

  render() {
    const { contacts } = this.props;
    return (
      <div className="contact-confirmation">
        <Header
          header={COPY_CREATE_CLIENT}
          mainHeader={COPY_MATCHING_RECORDS}
          subHeader={COPY_MATCHING_RECORDS_SUB_HEADER}
        />
        <div>
          <ul className="contact-list list-unstyled">
            {
              contacts.map((contact, index) => {
                // Hopefully encode something that is unique enough for a key
                const cardKey = contact.id || `${contact.first_name}-${contact.last_name}-${index}`;
                const cardId = `contact-card-${contact.id || index}`;

                return (
                  <ContactCard
                    key={cardKey}
                    id={cardId}
                    contact={contact}
                    onSelectContact={this.onSelectContact}
                  />
                );
              })
            }
          </ul>
        </div>
        <div className="text-center">
          <Button
            id="create-new-client"
            className="create-new-client-btn"
            onClick={this.onCreateNewContact}
            label="Create New Client"
            primary
          />
        </div>
      </div>
    );
  }
}

ContactConfirmation.propTypes = {
  activeStep: PropTypes.object,
  addContactToGroup: PropTypes.func.isRequired,
  assistanceRequest: PropTypes.object.isRequired,
  contacts: PropTypes.array.isRequired,
  context: PropTypes.string,
  groupId: PropTypes.string.isRequired,
  location: PropTypes.shape({
    query: PropTypes.object.isRequired,
  }).isRequired,
  requestor: PropTypes.object.isRequired,
  updateGroupContact: PropTypes.func.isRequired,
};

ContactConfirmation.defaultProps = {
  activeStep: {},
  context: null,
};

ContactConfirmation.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const requestId = get(ownProps, 'params.assistance_request_id');
  const requests = get(state, 'assistanceRequests.requests', []);
  const assistanceRequest = find(requests, { id: requestId }) || {};
  const requestor = get(assistanceRequest, 'requestor', {});
  const groupId = wget(state, 'session.groupId', '');

  return {
    assistanceRequest,
    contacts: wget(state, 'searchedContacts', []),
    groupId,
    requestor,
  };
}

export default connect(
  mapStateToProps,
  {
    addContactToGroup,
    updateGroupContact,
  },
)(ContactConfirmation);
