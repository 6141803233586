import {
  FETCH_ACCOUNT_ROLES_STARTED,
  FETCH_ACCOUNT_ROLES_SUCCESS,
  FETCH_ACCOUNT_ROLES_ERROR,
} from 'actions';
import {
  STATUS_INITIAL,
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_ERROR,
} from 'common/Status/constants';

export const defaultState = {
  accountRoles: [],
  status: STATUS_INITIAL,
};

export default function accountRolesReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case FETCH_ACCOUNT_ROLES_STARTED: {
      return {
        ...state,
        status: STATUS_PENDING,
      };
    }

    case FETCH_ACCOUNT_ROLES_ERROR: {
      return {
        ...state,
        status: STATUS_ERROR,
      };
    }

    case FETCH_ACCOUNT_ROLES_SUCCESS: {
      return {
        ...state,
        status: STATUS_SUCCESS,
        accountRoles: action.payload.data.data,
      };
    }

    default:
      return state;
  }
}
