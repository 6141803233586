import React from 'react';
import PropTypes from 'prop-types';
import { get, isNil } from 'lodash';
import { findPrimaryNumber } from 'common/form/Phone/utils';
import PhoneNumberDisplay from 'common/display/PhoneNumberDisplay';
import DetailAvatar from 'common/avatar/DetailAvatar';
import DateOfBirth from 'common/display/DateOfBirth';
import ContactAddress from 'common/display/Address/components/ContactAddress';
import { addresses } from '@unite-us/client-utils';
import { ContactDetailColumnItem } from './components';
import './ContactDetailColumn.scss';

const ContactDetailColumn = ({ contact, id }) => {
  const phoneNumber = findPrimaryNumber(get(contact, 'phone_numbers', []));
  const email = get(contact, 'email_addresses[0]');
  const dob = get(contact, 'date_of_birth');
  const householdCount = get(contact, 'household_count.total');
  const address = addresses.findMainAddress(contact.addresses ?? []) || null;

  return (
    <div className="contact-detail-column">
      <div>
        <DetailAvatar user={contact} size="lg" />
      </div>

      <h1 {...(id && { id: `${id}-name` })}>
        {contact.full_name}
      </h1>

      <ContactDetailColumnItem
        {...(id && { id: `${id}-email` })}
        title="Email"
        hide={isNil(email)}
      >
        {email && email.email_address}
      </ContactDetailColumnItem>

      <ContactDetailColumnItem
        {...(id && { id: `${id}-phone` })}
        title="Phone Number"
        hide={isNil(phoneNumber)}
      >
        {
          phoneNumber && (
          <PhoneNumberDisplay
            item={phoneNumber}
            hidePrimaryDisplay
            noLink
            shortDisplay
          />
          )
        }
      </ContactDetailColumnItem>
      <ContactDetailColumnItem
        {...(id && { id: `${id}-dob` })}
        title="Date of Birth"
        hide={isNil(dob)}
      >
        {
          dob && <DateOfBirth date={dob} oneline />
        }
      </ContactDetailColumnItem>
      <ContactDetailColumnItem
        {...(id && { id: `${id}-household-count` })}
        hide={isNil(householdCount)}
        title="Household Size"
      >
        {householdCount}
      </ContactDetailColumnItem>
      <ContactDetailColumnItem
        {...(id && { id: `${id}-address` })}
        hide={isNil(address)}
        title="Address"
      >
        {
          address && <ContactAddress address={address} />
        }
      </ContactDetailColumnItem>
    </div>
  );
};

ContactDetailColumn.propTypes = {
  contact: PropTypes.shape({
    first_name: PropTypes.string,
    full_name: PropTypes.string,
    last_name: PropTypes.string,
    addresses: PropTypes.array,
  }).isRequired,
  id: PropTypes.string,
};

ContactDetailColumn.defaultProps = {
  id: '',
};

export default ContactDetailColumn;
