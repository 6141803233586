import moment from 'moment';
import { SERVER_DATE_FORMAT } from 'src/components/Exports/constants';
import { ALL_TIME, CUSTOM_DATES } from '../constants';

export default function getDateRange(dateObj) {
  if (dateObj.value === ALL_TIME.value) {
    return {};
  }
  if (dateObj.value === CUSTOM_DATES.value) {
    return CUSTOM_DATES;
  }
  const daysAgo = parseInt(dateObj.value, 10);
  return {
    start_date: moment().subtract(daysAgo, 'd').format(SERVER_DATE_FORMAT),
    end_date: null,
  };
}
