import { browserHistory } from 'common/utils/browserHistory';
import { get, isEmpty } from 'lodash';

export function goToNextStep(prefix, response, queryString = '') {
  const groupFormResponse = get(response, 'data.data', []);

  return isEmpty(groupFormResponse) ?
    browserHistory.push(`${prefix}/referrals/new/final-review${queryString}`) :
    browserHistory.push(`${prefix}/referrals/new/add-supporting-details${queryString}`);
}

export default goToNextStep;
