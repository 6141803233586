import { CONSENT_OPTIONS } from 'src/components/InformedConsent/constants';

function getSubmitConsentText(consentType) {
  switch (consentType) {
    case CONSENT_OPTIONS.DOCUMENT:
      return 'Uploading Signed Consent...';

    case CONSENT_OPTIONS.AUDIO:
      return 'Uploading Audio Consent...';

    case CONSENT_OPTIONS.PHONE_NUMBER:
    case CONSENT_OPTIONS.EMAIL_ADDRESS:
    default:
      return 'Sending Consent Request...';
  }
}

export default getSubmitConsentText;
