import _ from 'lodash';
import {
  FETCH_REFERRAL_PROGRAMS,
} from 'actions';

const defaultState = {};

function referralProgramsReducer(state = defaultState, action = {}) {
  const {
    type, payload, serviceTypeId, referralId,
  } = action;

  switch (type) {
    case FETCH_REFERRAL_PROGRAMS: {
      const programs = _.get(payload, 'data.data');

      return {
        ...state,
        [referralId]: {
          serviceTypeId,
          programs,
        },
      };
    }

    default:
      return state;
  }
}

export default referralProgramsReducer;
