import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Profile from 'src/components/User/containers/Profile';
import convertCoreUser from 'src/api/utils/convertCoreUser';

export function UserAccountInformation(props) {
  return <Profile employee={props.employee} />;
}

UserAccountInformation.propTypes = {
  employee: PropTypes.object,
};

UserAccountInformation.defaultProps = {
  employee: {},
};

function mapStateToProps(state) {
  const employeeObject = convertCoreUser(state.globalState.currentEmployee);
  return {
    employee: employeeObject.employee,
  };
}

export default connect(mapStateToProps)(UserAccountInformation);
