import React, { Component } from 'react';
import PropTypes from 'prop-types';
import fetchInsurancePlanOptions from 'src/components/Insurance/utils/fetchInsurancePlanOptions';
import { isHttpError } from 'common/utils/Error';
import PaymentsInsuranceFields from './PaymentsInsuranceFields';
import generatePlanOptionsRollbar from './utils/generatePlanOptionsRollbar';

class PaymentsInsuranceFieldsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      insurancePlanOptions: [],
      requiredFields: false,
    };

    this.onChangeType = this.onChangeType.bind(this);
    this.setRequiredFields = this.setRequiredFields.bind(this);
    this.searchInsurancePlanOptions = this.searchInsurancePlanOptions.bind(this);
  }

  componentDidMount() {
    const { contactId } = this.props;
    if (this.props.fields.plan_type.value) {
      fetchInsurancePlanOptions({ planType: this.props.fields.plan_type.value })
        .then((response) => this.setState({ insurancePlanOptions: response.data.data }))
        .catch((response) => {
          if (isHttpError(response) && window.Rollbar) {
            generatePlanOptionsRollbar({ response, contactId });
          }
          this.setState({ insurancePlanOptions: [] });
        });
    }
  }

  componentDidUpdate(prevProps) {
    const { contactId } = this.props;
    const {
      enrollment, expiration, group_id, member_id, plan_type, plan,
    } = this.props.fields;

    /* If there are any changes made in the insurance form */
    if (prevProps.fields.member_id.value !== member_id.value ||
      prevProps.fields.enrollment.value !== enrollment.value ||
      prevProps.fields.expiration.value !== expiration.value ||
      prevProps.fields.group_id.value !== group_id.value ||
      prevProps.fields.plan_type.value !== plan_type.value ||
      prevProps.fields.plan.value !== plan.value) {
      this.setRequiredFields(prevProps);
    }

    if (prevProps.fields.plan_type.value !==
      this.props.fields.plan_type.value &&
      this.props.fields.plan_type.value) {
      fetchInsurancePlanOptions({ planType: this.props.fields.plan_type.value })
        .then((response) => this.setState({ insurancePlanOptions: response.data.data }))
        .catch((response) => {
          if (isHttpError(response) && window.Rollbar) {
            generatePlanOptionsRollbar({ response, contactId });
          }
          this.setState({ insurancePlanOptions: [] });
        });
    }
  }

  onChangeType(planType) {
    const { contactId } = this.props;

    this.clearInsuranceDetails();

    if (planType) {
      fetchInsurancePlanOptions({ planType })
        .then((response) => this.setState({ insurancePlanOptions: response.data.data }))
        .catch((response) => {
          if (isHttpError(response) && window.Rollbar) {
            generatePlanOptionsRollbar({ response, contactId });
          }
          this.setState({ insurancePlanOptions: [] });
        });
    }
  }

  setRequiredFields() {
    const {
      enrollment, expiration, group_id, member_id, plan_type, plan,
    } = this.props.fields;
    /*  If values in insurance form are empty then display asterisk */
    if (expiration.value === '' &&
      enrollment.value === '' &&
      group_id.value === '' &&
      member_id.value === '' &&
      plan_type.value === '' &&
      plan.value === '') {
      this.setState({ requiredFields: false });
    } else {
      this.setState({ requiredFields: true });
    }
  }

  clearInsuranceDetails() {
    this.setState({ insurancePlanOptions: [] });
    /* PlanType is filter for Payments Form. Triggering PlanType clears all fields except plan_type & id */
    Object.values(this.props.fields)
      .filter((field) => field.name !== this.props.fields.plan_type.name && field.name !== this.props.fields.id.name)
      .forEach((field) => {
        if (!field.touched || (field.name === this.props.fields.plan.name)) {
          field.onChange();
          this.props.untouch(field.name);
        }
      });
  }

  searchInsurancePlanOptions(search) {
    const { contactId } = this.props;

    if (this.props.fields.plan_type.value) {
      fetchInsurancePlanOptions({ planType: this.props.fields.plan_type.value, planName: search })
        .then((response) => this.setState({ insurancePlanOptions: response.data.data }))
        .catch((response) => {
          if (isHttpError(response) && window.Rollbar) {
            generatePlanOptionsRollbar({ response, contactId });
          }
          this.setState({ insurancePlanOptions: [] });
        });
    }
  }

  render() {
    const {
      fields, index, registerField,
    } = this.props;
    return (
      <div className="payments-insurance__information">
        <PaymentsInsuranceFields
          fields={fields}
          index={index}
          insurancePlanOptions={this.state.insurancePlanOptions}
          onChangeType={this.onChangeType}
          registerField={registerField}
          requiredFields={this.state.requiredFields}
          searchInsurancePlanOptions={this.searchInsurancePlanOptions}
        />
      </div>
    );
  }
}

PaymentsInsuranceFieldsContainer.propTypes = {
  contactId: PropTypes.string,
  fields: PropTypes.object.isRequired,
  registerField: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

PaymentsInsuranceFieldsContainer.defaultProps = {
  contactId: '',
};

export default PaymentsInsuranceFieldsContainer;
