import { SET_AUTH_SESSION } from 'actions';

export default function setAuthSession(access_token, refreshToken, expires_in) {
  return (dispatch) => {
    const payload = {
      session: {
        access_token,
        token: refreshToken,
        expires_in,
      },
    };

    dispatch({ type: SET_AUTH_SESSION, payload });
  };
}
