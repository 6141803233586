import { isEmpty } from 'lodash';
import { PRD_API_URL } from 'src/config/env/env.config';
import DEFAULT_ENUMS from 'src/reducers/constants/defaultEnums';

const statesMap = new Map(DEFAULT_ENUMS.geography.us_states.map(({ value, display_name }) => [value, display_name]));
const colorKeys = ['accent', 'background', 'button', 'icon', 'link', 'mapMarker', 'primary'];

export function directoryConfigurationParser(directory_configuration = {}) {
  const colors = {};
  let cities = '';
  let copyright_info = '';
  let counties = '';
  let external_api_key = '';
  let font_families = { primary: '', secondary: '', body: '' };
  let latitude = '';
  let location_name = '';
  let logo_url = '';
  let longitude = '';
  let states = '';

  if (!isEmpty(directory_configuration)) {
    const {
      theme: jsonTheme, location: jsonLocation, keys: jsonKeys,
      contact: jsonContact, logo_url: jsonLogoUrl, counties: jsonCounties,
      cities: jsonCities, states: jsonStates,
    } = directory_configuration;

    const theme = (jsonTheme) ? JSON.parse(jsonTheme) : {};

    if (!isEmpty(theme)) {
      const { primary, secondary, body } = theme.font || {};
      font_families = {
        primary: primary?.family || '',
        secondary: secondary?.family || '',
        body: body?.family || '',
      };
      colorKeys.forEach((colorKey) => {
        if (theme?.colors?.[colorKey]) {
          colors[colorKey] = theme.colors[colorKey];
        }
      });
    }

    const location = (jsonLocation) ? JSON.parse(jsonLocation) : {};
    location_name = location.name || '';
    latitude = location.lat || '';
    longitude = location.lng || '';

    const keys = (jsonKeys) ? JSON.parse(jsonKeys) : {};
    external_api_key = keys.externalApiKey || '';

    const contact = (jsonContact) ? JSON.parse(jsonContact) : {};
    copyright_info = contact.footerText || '';

    if (jsonLogoUrl) logo_url = `${PRD_API_URL}${jsonLogoUrl}`;

    if (jsonCounties) counties = JSON?.parse(jsonCounties)?.map(({ name, state }) => `${name}, ${state}`).join('; ');
    if (jsonCities) cities = JSON?.parse(jsonCities)?.map(({ name, state }) => `${name}, ${state}`).join('; ') || '';
    if (jsonStates) states = JSON?.parse(jsonStates)?.map((state) => statesMap.get(state)).join('; ') || '';
  }

  return {
    cities,
    colors,
    copyright_info,
    counties,
    external_api_key,
    font_families,
    latitude,
    location_name,
    logo_url,
    longitude,
    states,
  };
}
