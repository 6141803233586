import { includes, isArray, isNumber, reduce, isNaN } from 'lodash';

const MILITARY_DATE_FIELD_KEYS = [
  'entry_date',
  'exit_date',
  'deployment_starts_at',
  'deployment_ends_at',
];

function cleanMilitaryFields(military = {}) {
  return reduce(military, (acc, curr, key) => {
    if (includes(MILITARY_DATE_FIELD_KEYS, key)) {
      const epoch = parseInt(curr, 10);
      if (!isNumber(epoch) || isNaN(epoch)) {
        return { ...acc, [key]: null };
      }
    }
    if (key === 'proof_type') {
      if (isArray(curr)) {
        return { ...acc, [key]: '' };
      }
    }
    return { ...acc, [key]: curr };
  }, {});
}

export default cleanMilitaryFields;
