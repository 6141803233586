import defaultLabels from 'src/constants/labels';

export const careCoordinatorColumn = () => ({
  label: defaultLabels.CareCoordinator,
  sortColumn: 'care_coordinator.user.full_name',
});

export const clientNameColumn = () => ({
  label: 'Client Name',
  sortColumn: 'person.full_name',
});

export const lastUpdatedColumn = () => ({
  label: 'Last Updated',
  sortColumn: 'updated_at',
  type: 'date',
});

export const networkColumn = (isSuperNetwork, path = 'referred_to_network.name') => {
  if (!isSuperNetwork) {
    return null;
  }
  return {
    label: 'Network',
    sortColumn: path,
  };
};

export const serviceTypeColumn = () => ({
  label: 'Service Type',
  sortColumn: 'service.name',
});
