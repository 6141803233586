import {
  FETCH_GROUP,
  SET_IS_FETCHING,
  UNSET_IS_FETCHING,
  PROVIDER_REFERRAL_SCOPES,
} from 'actions';

export const defaultState = {
  data: [],
  paging: {},
  isFetching: true,
};

export default function (state = defaultState, action = {}) {
  switch (action.type) {
    case SET_IS_FETCHING:
      return { ...state, isFetching: true };

    case UNSET_IS_FETCHING:
      return { ...state, isFetching: false };

    case FETCH_GROUP: {
      const { payload = {} } = action;
      const group = (payload.data && payload.data.data) || {};

      if (state.data.map((g) => g.id).includes(group.id)) {
        return {
          ...state,
          data: state.data.reduce((acc, curr) => {
            if (curr.id === group.id) {
              return [...acc, {
                ...curr,
                ...group,
              }];
            }

            return [...acc, curr];
          }, []),
        };
      }

      return { ...state, data: [...state.data, group] };
    }

    case PROVIDER_REFERRAL_SCOPES: {
      const { payload = [], providerId = '' } = action;
      return {
        ...state,
        data: state.data.map((p) => (
          p.id === providerId ? { ...p, referral_scopes: payload } : { ...p }
        )),
      };
    }

    default:
      return state;
  }
}
