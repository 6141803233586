import moment from 'moment';
import { isNumber, isString } from 'lodash';

const DATE_FORMAT = 'M/D/YYYY';

function formatDateFilterDate(date) {
  if (isNumber(date)) {
    return moment.unix(date).utc().format(DATE_FORMAT);
  }
  if (isString(date)) {
    return moment(date).format(DATE_FORMAT);
  }
  return '';
}

export default formatDateFilterDate;
