import axios from 'axios';
import Notifier from 'common/helpers/Notifier';
import {
  CHECK_PROGRAM_ENROLLMENT,
  SET_FETCHING_PROGRAM_ENROLLMENT,
  UNSET_FETCHING_PROGRAM_ENROLLMENT,
} from 'actions';

function onSuccess(response, dispatch) {
  const dispatchObj = {
    type: CHECK_PROGRAM_ENROLLMENT,
    payload: response,
  };
  dispatch(dispatchObj);
}

// TODO: TBD
// This entity Enrollment was removed, replaced by Case
// This is being used while creating Case; AddCaseDetails, AddCaseDetailsForm to
// set the default date for enrolled_at
// https://uniteus.atlassian.net/browse/CORE-1091
// https://uniteus.atlassian.net/browse/CORE-736
export default function checkProgramEnrollment(groupId, contactId, programId) {
  const request = axios.get(`/groups/${groupId}/contacts/${contactId}/programs/${programId}/enrollment`);

  return (dispatch) => {
    dispatch({ type: SET_FETCHING_PROGRAM_ENROLLMENT });
    return request
      .then((success) => {
        onSuccess(success, dispatch);
        dispatch({ type: UNSET_FETCHING_PROGRAM_ENROLLMENT });
        return success;
      })
      .catch((error) => {
        dispatch({ type: UNSET_FETCHING_PROGRAM_ENROLLMENT });
        Notifier.handleErrors(error);
        return error;
      });
  };
}
