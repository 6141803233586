// eslint-disable-next-line max-len
export const PHI_INFO_MESSAGE = 'Include only the minimum information necessary to provide the requested services. Do not include sensitive information (e.g., interpersonal violence, substance use, or legal services information).';
// eslint-disable-next-line max-len
export const CFR_PART_2_CASE_MESSAGE = 'If this case is for a 42 CFR Part 2 covered service, Part 2 prohibits the unauthorized re-disclosure of these records.';
// eslint-disable-next-line max-len
export const CFR_PART_2_REFERRAL_MESSAGE = 'If this referral is for a 42 CFR Part 2 covered service, Part 2 prohibits the unauthorized re-disclosure of these records.';

// error messages from service_authorization_cap_rules
// eslint-disable-next-line max-len
export const UTILIZATION_ERROR = 'The client is already receiving this service through either a passthrough or non-passthrough service within this program group.';
// eslint-disable-next-line max-len
export const DATES_ERROR = 'Clients can only receive services from this program for 30 days or until their social coverage ends, whichever comes first.';
