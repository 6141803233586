import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Serializer } from '@unite-us/client-utils';
import { ShareDrawer } from '@unite-us/shares-utils';
import { Button } from '@unite-us/ui';
import { pluralize } from 'humanize-plus';
import { SHARES_URL } from 'src/config/env/env.config';
import { coreApi } from 'src/api/config';
import callOrLog from 'common/utils/callOrLog';
import { BROWSE, MY_NETWORKS } from 'common/utils/EventTracker/utils/eventConstants';
import { getPermittedNetworkOptions } from 'src/components/Network/utils';
import FilterNetwork from 'src/components/Browse/BrowseFilters/components/FilterNetwork';
import FilterToggle from 'src/components/Browse/BrowseFilters/components/FilterToggle';
import { NETWORK_SCOPE_OPTIONS } from 'src/components/Browse/utils/filters/constants';
import SelectedProviders from 'src/components/Browse/SelectedProviders';
import FiltersDrawer from '../components/FiltersDrawer';
import FilterNetworkScope from '../components/FilterNetworkScope';
import FilterSearch from '../components/FilterSearch';
import FilterServiceType from '../components/FilterServiceType';
import SortBySelect from '../components/SortBySelect';
import './NetworkBrowseFilters.scss';

function getPermittedNetworkOptionsWithFilter(referralScopes, networkId, activeFilters = []) {
  const permittedNetworks = _.chain(referralScopes)
    .filter((scope) => scope.network.id === networkId)
    .map((scope) => ({
      label: scope.permitted_network.name,
      value: scope.permitted_network.id,
      initial: _.indexOf(activeFilters, scope.permitted_network.id) >= 0,
    }))
    .value();

  return _.uniqBy(permittedNetworks, 'value');
}

function getNetworkScopes(activeFilters) {
  return NETWORK_SCOPE_OPTIONS.map((option) => (
    {
      value: option.value,
      label: option.label,
      initial: _.includes(activeFilters, option.value),
    }
  ));
}

export class NetworkBrowseFilters extends Component {
  constructor(props) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleFiltersDrawer = this.toggleFiltersDrawer.bind(this);
    this.toggleShareDrawer = this.toggleShareDrawer.bind(this);
    this.trackShareProviders = this.trackShareProviders.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const { filtersDrawerOpen } = this.props;
    const isTargetToggleBtn = event.target.classList.contains('filter-drawer-toggle');

    if (
      filtersDrawerOpen &&
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      !isTargetToggleBtn
    ) {
      this.toggleFiltersDrawer();
    }
  }

  onShareEvent({ shareMethod, language }) {
    this.trackShareProviders({ shareMethod, language });
    if (shareMethod === 'print') this.toggleShareDrawer();
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  toggleFiltersDrawer() {
    if (!this.props.filtersDrawerOpen) {
      callOrLog(() => this.context.eventTracker(BROWSE.filtersDrawerOpened));
    }
    this.props.onToggleFiltersDrawer();
  }

  toggleShareDrawer() {
    this.props.onToggleShareDrawer();
  }

  trackShareProviders({ shareMethod, language }) {
    const { network, selectedProviders } = this.props;
    const groupCount = selectedProviders.length;

    _.each(selectedProviders, (provider) => {
      const sharedGroup = Serializer.build({ sharedGroup: provider });
      const browse_network_id = provider.uiFilters ? provider.uiFilters.networkId : network.id;
      const browse_network_name = provider.uiFilters ? provider.uiFilters.networkName : network.name;

      const payload = {
        ...sharedGroup,
        shared_message_type: shareMethod,
        browse_network_id,
        browse_network_name,
        shared_group_count: groupCount,
        shared_language_code: language,
        share_type: 'providers',
      };

      callOrLog(() => this.context.eventTracker(MY_NETWORKS.shareClicked, payload));
    });
  }

  render() {
    const {
      employeeId,
      currentUserGroup,
      currentProviderId,
      filters,
      filtersDrawerOpen,
      isCCGroup,
      network,
      onSortByChange,
      referralScopes,
      selectedProviders,
      serviceOptions,
      shareDrawerOpen,
      sortBy,
      serviceAreaSupportForOrgsFlag,
    } = this.props;

    const displayNetworkFilter = getPermittedNetworkOptions(referralScopes, network.id).length > 1;

    const networkOptions = getPermittedNetworkOptionsWithFilter(
      referralScopes,
      network.id,
      filters.networks,
    );
    const networkScopeOptions = getNetworkScopes(filters.networkScopes);

    return (
      <div className="network-browse-filters browse-filters">
        <div className="browse-filters__main">
          <div className="network-browse-filters__filter-fields">
            <FilterSearch
              filters={filters}
              onFiltersChange={this.props.onFiltersChange}
              label="network search"
            />

            <FilterServiceType
              onFiltersChange={this.props.onFiltersChange}
              options={serviceOptions}
            />

            {
              displayNetworkFilter && isCCGroup && (
                <FilterNetwork
                  onFiltersChange={this.props.onFiltersChange}
                  options={networkOptions}
                />
              )
            }

            {
              <FilterNetworkScope
                onFiltersChange={this.props.onFiltersChange}
                options={networkScopeOptions}
              />
            }
            <div className="browse-filters-sort-by">
              <SortBySelect value={sortBy} onChange={onSortByChange} />
            </div>
          </div>
          <div
            className={`network-browse-filters__filter-toggle ${serviceAreaSupportForOrgsFlag ? 'w-64' : ''}`}
            style={{ justifyContent: serviceAreaSupportForOrgsFlag ? 'flex-start' : 'flex-end' }}
          >
            <FilterToggle
              leftIcon={serviceAreaSupportForOrgsFlag && 'IconFilter'}
              rightIcon={serviceAreaSupportForOrgsFlag ? false : undefined}
              name={serviceAreaSupportForOrgsFlag && 'More filters'}
              onClick={this.toggleFiltersDrawer}
            />
          </div>
        </div>

        <div className="network-browse-filters__secondary-row">
          <SelectedProviders
            className="network-browse-filters__selected-providers"
            providers={selectedProviders}
          />

          <Button
            className="network-browse-filters__clear-all-button"
            disabled={selectedProviders.length === 0}
            label="Clear All"
            link
            onClick={this.props.onClearAllProviders}
          />

          <Button
            className="share-button network-browse-filters__share-button"
            disabled={selectedProviders.length === 0}
            id="network-browse-multi-provider-share-button"
            label={`Share ${pluralize(selectedProviders.length, 'Organization')}`}
            onClick={this.toggleShareDrawer}
            primary
          />
        </div>

        <FiltersDrawer
          clearNetworkBrowseFilters={this.props.clearNetworkBrowseFilters}
          closeFiltersDrawer={this.toggleFiltersDrawer}
          currentUserGroup={currentUserGroup}
          filters={filters}
          groupsOptionType={filters.networkScopes}
          inputRef={this.setWrapperRef}
          networkId={network.id}
          onFiltersChange={this.props.onFiltersChange}
          setGeoFilters={this.props.setGeoFilters}
          open={filtersDrawerOpen}
        />

        <ShareDrawer
          employeeId={employeeId}
          coreApi={coreApi}
          SHARES_URL={SHARES_URL}
          show={shareDrawerOpen}
          onClose={this.toggleShareDrawer}
          resourceType="provider"
          resources={selectedProviders}
          onShare={
            ({ shareMethod, language }) => this.onShareEvent({ shareMethod, language })
          }
          providerId={currentProviderId}
        />
      </div>
    );
  }
}

NetworkBrowseFilters.propTypes = {
  clearNetworkBrowseFilters: PropTypes.func.isRequired,
  currentUserGroup: PropTypes.shape({
    id: PropTypes.string,
  }),
  currentProviderId: PropTypes.string.isRequired,
  employeeId: PropTypes.string,
  filters: PropTypes.object.isRequired,
  filtersDrawerOpen: PropTypes.bool,
  isCCGroup: PropTypes.bool.isRequired,
  network: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onClearAllProviders: PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  onSortByChange: PropTypes.func.isRequired,
  onToggleFiltersDrawer: PropTypes.func,
  onToggleShareDrawer: PropTypes.func,
  referralScopes: PropTypes.array.isRequired,
  selectedProviders: PropTypes.array,
  serviceOptions: PropTypes.array,
  setGeoFilters: PropTypes.func.isRequired,
  shareDrawerOpen: PropTypes.bool,
  sortBy: PropTypes.string.isRequired,
  serviceAreaSupportForOrgsFlag: PropTypes.bool.isRequired,
};

NetworkBrowseFilters.defaultProps = {
  currentUserGroup: {},
  employeeId: '',
  filtersDrawerOpen: false,
  onToggleFiltersDrawer: _.noop,
  onToggleShareDrawer: _.noop,
  selectedProviders: [],
  serviceOptions: [],
  shareDrawerOpen: false,
};

NetworkBrowseFilters.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentProviderId: state.session.groupId,
    employeeId: state.globalState.currentEmployee.id,
  };
}

export default connect(mapStateToProps)(NetworkBrowseFilters);
