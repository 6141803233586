import { map } from 'lodash';

function addNameToOONPrograms({ group = {}, programs = [] }) {
  const programName = `${group.name} Program`;
  return map(programs, (program) => ({
    ...program,
    name: programName,
  }));
}
export default addNameToOONPrograms;
