import _ from 'lodash';

function hasNoUserRole({
  groupId, user,
}) {
  return _.isEmpty(_.chain(user)
    .get('groups', [])
    .find((group) => group.group.id === groupId)
    .get('roles', []));
}

export default hasNoUserRole;
