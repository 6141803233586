import React from 'react';
import { PropTypes } from 'prop-types';

const FormContainer = ({ children }) => (
  <div className="container max-w-screen-lg min-h-screen pb-12">{children}</div>
);
FormContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
export default FormContainer;
