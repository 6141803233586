import _ from 'lodash';
import { notReferredToOrByGroup } from 'src/components/Referrals/ReferralStatus/utils';
import { updateElement } from './updateElements';
import findCurrentElementLocation from './findCurrentElementLocation';
import {
  handleReceivedReferral,
  handleSentReferral,
} from './webSocketHelpers';

function updateReferral(state, element, urlData, currentSection, currentGroupId) {
  const referredById = _.get(element, 'referred_by_group.id', '');
  const referredToId = _.get(element, 'referred_to_group.id', '');
  const toUpdateId = _.get(urlData, 'uuid', '');
  const currentLocation = findCurrentElementLocation(state, toUpdateId);

  if (notReferredToOrByGroup(currentGroupId, referredToId, referredById)) {
    if (currentLocation.location === 'sentReferrals' && currentSection === 'sentReferrals') {
      return updateElement(state, toUpdateId, element, 'sentReferrals');
    }
  }

  if (referredById === currentGroupId) {
    return handleSentReferral(state, element, urlData, currentSection, currentLocation);
  }

  if (referredToId === currentGroupId) {
    return handleReceivedReferral(state, element, urlData, currentSection);
  }

  return state;
}

export default function updateReferrals(state, payload = [], urlData, currentSection, currentGroupId) {
  if (_.isEmpty(payload)) { return state; }

  return updateReferrals(
    updateReferral(state, _.head(payload), urlData, currentSection, currentGroupId),
    _.tail(payload),
    urlData,
    currentSection,
    currentGroupId,
  );
}
