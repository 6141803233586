// Library Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, FormLegend } from '@unite-us/ui';
import { Spinner } from 'common/spinners';

// Component Imports
import {
  OONGroupsSelector,
  ReferralNetworksSelector,
  ReferralServiceTypeSelect,
  ReferralFormBaseTemplate,
} from 'src/components/Referrals/ReferralFormFields';
import ReferralGroupsPrograms from 'src/components/Referrals/ReferralGroupsPrograms';
import ReferralServiceProviderBrowse from
  'src/components/Referrals/components/ReferralServices/ReferralServiceProviderBrowse';
import ReferralsSearchPrograms from 'src/components/Referrals/ReferralsSearchPrograms';

// Util Imports
import {
  sortGroupsByDistance,
} from 'src/components/Groups/utils';
import {
  flattenServiceTypes,
  includesServiceType,
} from 'common/utils/ServiceTypes';
import {
  invalidServiceTypeValidation,
  serviceTypeRequiredValidation,
} from 'common/utils/ServiceTypes/validations';
import { getGroup } from 'src/common/utils/stateHelpers';
import {
  getDeterminantNetworkId,
  getFieldValue,
  hasValidSelectedNetworks,
} from 'src/components/Referrals/utils/form';
import { formatOverride } from 'src/components/Referrals/ReferralStatus/utils';
import callOrLog from 'src/common/utils/callOrLog';
import { SERVICE_CASE, REFERRAL } from 'common/utils/EventTracker/utils/eventConstants';
import { Serializer } from '@unite-us/client-utils';
import { removeAllGroupFields } from 'src/components/Referrals/ReferralGroupsPrograms/utils/removeGroupFields';
import addSelfToChildrenOptions from 'src/common/utils/ServiceTypes/addSelfToChildrenOptions';

// Feature Flags
import {
  paginateNetworkGroups,
  serviceAreaSupportForOrgs,
  programBasedSearchSelector,
  pays5590RefactorInsuranceFilters,
} from 'src/common/utils/FeatureFlags/flags';

// Action Imports
import { getCCGroupIds, getSelectedGroups } from './utils';

import AuthorizationRequestForm from './AuthorizationRequestForm';

// Style Imports
import './stylesheets/editReferralDetails.scss';

export class EditReferralDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invalidServiceType: null,
      serviceTypes: [],
      showReferInNetworkButton: true,
      oonCaseView: props.groupsOptionType === 'out-of-network',
      isFetchingServiceTypes: false,
    };

    this.onServiceTypeChange = this.onServiceTypeChange.bind(this);
    this.searchNetworkGroups = this.searchNetworkGroups.bind(this);
    this.updateServiceTypes = this.updateServiceTypes.bind(this);
    this.onOpenOONCase = this.onOpenOONCase.bind(this);
    this.onOpenInNetworkReferral = this.onOpenInNetworkReferral.bind(this);
    this.onTrackAutoRecallableCheckbox = this.onTrackAutoRecallableCheckbox.bind(this);
    this.setOONCaseContext = this.setOONCaseContext.bind(this);
    this.setOONCaseContextPaginate = this.setOONCaseContextPaginate.bind(this);
  }

  componentDidMount() {
    const {
      fields,
      networks = [],
      referral,
      showOONCaseContext,
      suggestedGroups,
      oonGroups,
    } = this.props;

    this.props.fetchPosition();

    const referredByNetworkId =
      _.get(fields, 'referred_by_network.value.id', null) ||
      (networks.length === 1 && _.get(networks, '[0].id')) ||
      _.get(referral, 'referred_by_network.id');

    if (referredByNetworkId) {
      const initialFieldValue = formatOverride(referredByNetworkId);
      this.props.onChangeReferredByNetwork(initialFieldValue);
    }

    const groupOptions = showOONCaseContext ? oonGroups : suggestedGroups;
    this.props.updateSelectedPrograms(groupOptions);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.get(this.props, 'fields.referred_by_network.value.id') !==
      _.get(nextProps, 'fields.referred_by_network.value.id')) {
      this.props.onChangeReferredByNetwork(nextProps.fields.referred_by_network);
    }
  }

  onServiceTypeChange(serviceType) {
    const {
      canPaginateNetworkGroups,
      contact,
      feeScheduleProgramId,
      fields,
      setFeeScheduleProgramId,
    } = this.props;

    // when service type changes, we should revert back to the in-network view
    // and fetch groups accordingly.
    this.setState({ oonCaseView: false });

    if (serviceType) {
      const eventPayload = Serializer.build({ contact });
      callOrLog(() => this.context.eventTracker(REFERRAL.serviceTypeSelected, {
        contact: eventPayload,
      }));
    } else {
      this.onOpenInNetworkReferral();
    }

    removeAllGroupFields(fields.selected);

    if (canPaginateNetworkGroups) {
      removeAllGroupFields(fields.oonCase.selected);
    }
    // when service type changes, we should force reload provider options when
    // feeScheduleProgramId is not null, which means auth required flow is activated
    if (feeScheduleProgramId) {
      setFeeScheduleProgramId(null);
    }

    this.searchNetworkGroups();
  }

  onOpenOONCase() {
    const { contact, fields, initialPrimaryWorker } = this.props;

    if (initialPrimaryWorker) {
      this.props.fields.oonCase.primary_worker.onChange(initialPrimaryWorker);
    }

    callOrLog(() => (
      this.context.eventTracker(SERVICE_CASE.createOONCaseFromReferral, null, { contact })
    ));
    fields.isOONCase.onChange(true);

    this.setState({
      showReferInNetworkButton: true,
      oonCaseView: true, // Using this local boolean value to determine context for fetch call - line: 197.
    }, () => {
      this.searchNetworkGroups();
      this.props.onForceShowOONCaseContext();
      removeAllGroupFields(fields.selected);
    });
  }

  onOpenInNetworkReferral() {
    const { fields, index } = this.props;
    fields.isOONCase.onChange(false);

    this.setState({
      showReferInNetworkButton: false,
      oonCaseView: false,
    }, () => {
      this.props.onCancel(index);
      this.searchNetworkGroups();
    });
  }

  onTrackAutoRecallableCheckbox(checked) {
    const { contact } = this.props;
    if (checked) {
      const eventPayload = Serializer.build({ contact });
      callOrLog(() => this.context.eventTracker(REFERRAL.autoRecallReferrals, {
        contact: eventPayload,
      }));
    }
  }

  /*
    * This is the callback function passed on line 213.
    * It is called when suggested groups come back empty (see line:422 of ReferralFormBaseTemplate)
  */
  setOONCaseContext(suggestedGroups) {
    const forceOONCaseContext = this.props.canShowOONCaseContext &&
      _.get(suggestedGroups, 'length', 0) === 0;

    if (forceOONCaseContext && !this.props.showOONCaseContext) {
      this.props.onForceShowOONCaseContext();
    }
  }

  /*
    * This is the feature flagged version of the callback function passed on line::213.
    * It is called when suggested groups come back empty (see line::338 of ReferralFormBaseTemplate)
  */
  setOONCaseContextPaginate(suggestedGroups) {
    const forceOONCaseContext = this.props.canShowOONCaseContext &&
      _.get(suggestedGroups, 'length', 0) === 0;

    if (forceOONCaseContext && !this.props.showOONCaseContext) {
      // setting showReferINNetworkButton to false, so that the refer in-network button
      // does not render when this callback is invoked, and we know there are no in-network groups available.
      this.setState({ oonCaseView: true, showReferInNetworkButton: false }, () => {
        this.props.onForceShowOONCaseContext();
        // after we've found no in-network groups, we should try for out-of-network groups
        this.searchNetworkGroups();
      });
    }
  }

  searchNetworkGroups() {
    const { canPaginateNetworkGroups, fields } = this.props;
    const referredToNetworkId = getDeterminantNetworkId(fields);
    const referredByNetworkId = getFieldValue(fields.referred_by_network).id;
    const callbackSetOONCaseContext = canPaginateNetworkGroups ?
      this.setOONCaseContextPaginate :
      this.setOONCaseContext;

    this.props.searchNetworkGroups({
      // showOONCaseContext is used to determine the scope by which to make a groups fetch.
      showOONCaseContext: this.state.oonCaseView,
      referredByNetworkId,
      referredToNetworkId,
      type: 'create',
    }, callbackSetOONCaseContext);
  }

  updateServiceTypes() {
    const { assistanceRequest, fields, fromAssistanceRequest } = this.props;

    const referredToNetworkId = getDeterminantNetworkId(fields);
    if (_.isEmpty(referredToNetworkId)) {
      this.props.setCanSearch(false);
      return this.setState({ serviceTypes: [] });
    }
    this.setState({ isFetchingServiceTypes: true }, () => {
      this.props.fetchNetworkServiceTypes(referredToNetworkId)
        .then((response) => {
          const serviceTypeResponse = _.get(response, 'data.data', []);
          const serviceTypes = addSelfToChildrenOptions(serviceTypeResponse);
          this.setState({ serviceTypes, isFetchingServiceTypes: false });

          // Set service type from AR as long as the service type field is
          // pristine (unchanged by the user).
          if (fromAssistanceRequest) {
            const arServiceType = _.get(assistanceRequest, 'service_type');
            const validARServiceType = includesServiceType(
              arServiceType,
              serviceTypes,
            );

            if (validARServiceType && fields.service_type.pristine) {
              fields.service_type.onChange(arServiceType);
              this.setState({
                invalidServiceType: null,
              });
              this.searchNetworkGroups();
              return;
            }

            if (!validARServiceType) {
              this.setState({ invalidServiceType: arServiceType }, () => {
                // We want to activate the field validations immediately,
                // so that the message will show immediately.
                fields.service_type.onBlur('');
              });
              return;
            }
          }

          const selectedServiceType = getFieldValue(fields.service_type) ||
            fields.service_type.initialValue ||
            '';
          // @todo We need to have selectedServiceType always be an object,
          // rather than sometimes be a string, sometimes be an object (kevin)
          const serviceType = _.find(flattenServiceTypes(serviceTypes), {
            id: _.isString(selectedServiceType) ? selectedServiceType : selectedServiceType.id,
          }) || '';
          fields.service_type.onChange(serviceType);
          this.searchNetworkGroups();
          return;
        });
    });

    return null;
  }

  render() {
    const {
      allowEmptyGroups,
      canPaginateNetworkGroups,
      canSearch,
      canShowOONCaseContext,
      contact,
      currentUserGroup,
      feeScheduleProgramId,
      fields,
      groupsOptionType,
      index,
      isFetchingGroup,
      isFetchingNetworkGroups,
      isFetchingOONGroups,
      isProgramBasedSearch,
      networks,
      debouncedSearchNetworkGroups,
      paginateSearchNetworkGroups,
      oonGroups,
      nationalOONGroups,
      originCoordinates,
      referral,
      removeSelectedBrowseGroup,
      registerField,
      setFeeScheduleProgramId,
      suggestedGroups,
      nationalStateSuggestedGroups,
      touch,
      serviceAreaSupportForOrgsFlag,
      isRefactorInsuranceFiltersEnabled,
      query,
      width,
      setDisableNextButton,
      setHasSensitiveErrors,
      selectedProgramsContext,
      isFetchingBrowsePrograms,
      unregisterField,
    } = this.props;

    const {
      invalidServiceType,
      isFetchingServiceTypes,
      serviceTypes,
      showReferInNetworkButton,
      oonCaseView,
    } = this.state;

    const isFetching = isFetchingGroup || isFetchingNetworkGroups || isFetchingServiceTypes;

    const referredByNetworkId = _.get(getFieldValue(fields.referred_by_network), 'id');
    const referredToNetworkId = getDeterminantNetworkId(fields);

    const selectedGroups = getSelectedGroups(fields, groupsOptionType);

    const referredByNetwork = _.find(networks, { id: referredByNetworkId });
    const serviceType = fields.service_type.value;
    const serviceTypeSelected = serviceType !== '';
    const description = fields.notes.value;
    const sender = _.get(currentUserGroup, 'name', '');

    const hasServiceTypes = !_.isEmpty(serviceTypes) ||
      (fields.service_type.touched && fields.service_type.value);

    const selectedOONFields = _.get(fields, 'oonCase.selected', []);
    const customFields = _.get(fields, 'oonCase.custom', []);

    let suggestedGroupsSorted;
    let oonGroupsSorted;

    if (!canPaginateNetworkGroups) {
      // When this `canPaginateNetworkGroups` feature flag is OFF, we no longer have the API to sort our groups.
      // So we need to do it manually here, with the originCoordinates fetched from ReferralFormBaseTemplate.

      suggestedGroupsSorted = sortGroupsByDistance(suggestedGroups, originCoordinates);
      oonGroupsSorted = sortGroupsByDistance(oonGroups, originCoordinates);
    }

    const selectedNetwork = fields?.referred_by_network?.value || {};

    return (
      <div className="edit-referral-details">
        <FormLegend />
        {isProgramBasedSearch && <h2 className="my-3 font-bold">Select Sending Network and Service Type</h2>}
        {_.get(referral, 'state') !== 'draft' ? (
          <ReferralNetworksSelector
            fields={fields}
            isFetchingNetworkGroups={isFetchingNetworkGroups}
            networks={networks}
            registerField={registerField}
          />
        ) : (
          <div className="row">
            <div className="col-xs-6">
              <div className="display-field">
                <h5 className="text-secondary display-field__label">SENDING NETWORK</h5>
                {_.get(referral, 'network.name')}
              </div>
            </div>
          </div>
        )}
        <div className={`service-type-section row
          ${isProgramBasedSearch && _.get(referral, 'state') !== 'draft' ? '-mt-4' : ''}`}
        >
          <div className={`service-type-dropdown col-md-6 col-xs-${width || 12}`}>
            <ReferralServiceTypeSelect
              options={serviceTypes}
              updateServiceTypes={this.updateServiceTypes}
              disabled={_.some([
                !hasValidSelectedNetworks(fields),
                // @todo If we have a valid network and it provides no services,
                // we need to notify the user, not just disable this dropdown.
                // BTW I think this is a scenario that shouldn't happen because
                // a network should always provide at least one service.
                !hasServiceTypes,
                isFetching,
              ])}
              registerField={registerField}
              onChange={this.onServiceTypeChange}
              required
              fields={fields}
              touch={touch}
              validations={[
                invalidServiceTypeValidation(invalidServiceType),
                serviceTypeRequiredValidation(invalidServiceType),
                {
                  func: (value) => {
                    // Hack to prevent submitting outer form while fetching.
                    if (value) { return undefined; }
                    if (isFetchingNetworkGroups) {
                      return ' ';
                    }
                    return undefined;
                  },
                },
              ]}
              setCanSearch={this.props.setCanSearch}
              canSearch={canSearch}
            />
          </div>
        </div>
        {isFetchingBrowsePrograms && <Spinner />}
        {!isFetchingBrowsePrograms && !oonCaseView && serviceTypeSelected && (
          <ReferralGroupsPrograms
            allowEmptyGroups={allowEmptyGroups}
            registerField={registerField}
            canPaginateNetworkGroups={canPaginateNetworkGroups}
            ccGroupIds={getCCGroupIds(networks, fields)}
            contact={contact}
            feeScheduleProgramId={feeScheduleProgramId}
            hide={!serviceTypeSelected || !canSearch}
            loading={canSearch && isFetching}
            index={index}
            isFetchingOONGroups={isFetchingOONGroups}
            isProgramBasedSearch={isProgramBasedSearch}
            ref={(value) => { this.referralGroupPrograms = value; }}
            referral={referral}
            selectedFields={fields.selected}
            debouncedSearchNetworkGroups={debouncedSearchNetworkGroups}
            paginateSearchNetworkGroups={paginateSearchNetworkGroups}
            referredByNetworkId={referredByNetworkId}
            referredToNetworkId={referredToNetworkId}
            removeSelectedBrowseGroup={removeSelectedBrowseGroup}
            serviceTypeField={fields.service_type}
            showErrors={!oonCaseView}
            originCoordinates={originCoordinates}
            query={query}
            suggestedGroups={canPaginateNetworkGroups ? suggestedGroups : suggestedGroupsSorted}
            nationalStateSuggestedGroups={nationalStateSuggestedGroups}
            toggleBrowse={this.props.triggerBrowse}
            shouldSort={false}
            network={selectedNetwork}
            serviceAreaSupportForOrgsFlag={serviceAreaSupportForOrgsFlag}
            width={width}
            currentUserGroup={currentUserGroup}
            setHasSensitiveErrors={setHasSensitiveErrors}
            setFeeScheduleProgramId={setFeeScheduleProgramId}
            selectedProgramsContext={selectedProgramsContext}
          />
        )}
        {!isFetchingBrowsePrograms && oonCaseView && serviceTypeSelected && (
          <OONGroupsSelector
            touch={touch}
            contact={contact}
            canPaginateNetworkGroups={canPaginateNetworkGroups}
            debouncedSearchNetworkGroups={debouncedSearchNetworkGroups}
            paginateSearchNetworkGroups={paginateSearchNetworkGroups}
            fields={fields}
            customFields={customFields}
            query={query}
            groupsOptionType={groupsOptionType}
            inNetworkGroupsEmpty={
              canPaginateNetworkGroups ?
                suggestedGroups.length === 0 :
                suggestedGroupsSorted.length === 0
            }
            isFetchingOONGroups={isFetchingOONGroups}
            isProgramBasedSearch={isProgramBasedSearch}
            network={referredByNetwork}
            oonGroups={canPaginateNetworkGroups ? oonGroups : oonGroupsSorted}
            nationalOONGroups={nationalOONGroups}
            removeSelectedBrowseGroup={removeSelectedBrowseGroup}
            originCoordinates={originCoordinates}
            referral={referral}
            registerField={registerField}
            selectedFields={selectedOONFields}
            toggleBrowse={this.props.triggerBrowse}
            serviceAreaSupportForOrgsFlag={serviceAreaSupportForOrgsFlag}
            unregisterField={unregisterField}
            isFetchingNetworkGroups={isFetchingNetworkGroups}
            setHasSensitiveErrors={setHasSensitiveErrors}
            index={index}
          />
        )}
        <AuthorizationRequestForm
          feeScheduleProgramId={feeScheduleProgramId}
          contact={contact}
          registerField={registerField}
          fields={fields}
          isRefactorInsuranceFiltersEnabled={isRefactorInsuranceFiltersEnabled}
          setDisableNextButton={setDisableNextButton}
        />
        {
          canSearch && !isFetching && canShowOONCaseContext && !oonCaseView && (
            <Button
              id="open-out-of-network-case-btn"
              className="open-out-of-network-case-button mt-one mb-one"
              label="Create Out Of Network Case"
              onClick={this.onOpenOONCase}
              primary
            />
          )
        }

        {
          showReferInNetworkButton && oonCaseView && !isFetchingOONGroups && (
            <Button
              id="open-in-network-case-btn"
              className="open-in-network-case-button mt-one mb-one"
              label="Refer In Network"
              onClick={this.onOpenInNetworkReferral}
              primary
            />
          )
        }

        {this.props.showBrowse && (isProgramBasedSearch ? (
          <ReferralsSearchPrograms
            toggleBrowse={() => this.props.triggerBrowse(groupsOptionType)}
            referral={{
              funderProgramId: feeScheduleProgramId,
              fromNetworkId: referredByNetwork.id,
              isLicensed: !this.state.oonCaseView,
              personId: contact.id,
              providerId: currentUserGroup.id,
              serviceId: serviceType.id,
              serviceName: serviceType.name,
              toNetworkId: referredToNetworkId,
            }}
          />
        ) : (
          <ReferralServiceProviderBrowse
            addGroups={this.props.addSelectedBrowseGroups}
            contact={contact}
            contextAction="create"
            currentUserGroup={currentUserGroup}
            description={description}
            feeScheduleProgramId={feeScheduleProgramId}
            groups={suggestedGroups}
            groupsOptionType={groupsOptionType}
            oonGroups={oonGroups}
            referredByNetworkId={referredByNetworkId}
            referredToNetworkId={referredToNetworkId}
            selectedGroups={selectedGroups}
            sender={sender}
            serviceType={serviceType}
            toggleBrowse={this.props.triggerBrowse}
          />
        ))}
      </div>
    );
  }
}

EditReferralDetails.propTypes = {
  isFetchingBrowsePrograms: PropTypes.bool,
  addSelectedBrowseGroups: PropTypes.func.isRequired,
  allowEmptyGroups: PropTypes.bool,
  assistanceRequest: PropTypes.object,
  canSearch: PropTypes.bool.isRequired,
  canPaginateNetworkGroups: PropTypes.bool.isRequired,
  canShowOONCaseContext: PropTypes.bool,
  contact: PropTypes.object,
  currentUserGroup: PropTypes.object,
  debouncedSearchNetworkGroups: PropTypes.func.isRequired,
  feeScheduleProgramId: PropTypes.string,
  fetchNetworkServiceTypes: PropTypes.func.isRequired,
  fetchPosition: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    auto_recallable: PropTypes.object.isRequired,
    documents: PropTypes.object.isRequired,
    notes: PropTypes.object.isRequired,
    authorizationRequest: PropTypes.object,
    isOONCase: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    oonCase: PropTypes.shape({
      selected: PropTypes.array.isRequired,
      primary_worker: PropTypes.object.isRequired,
    }).isRequired,
    referred_by_network: PropTypes.object.isRequired,
    referred_to_network: PropTypes.object.isRequired,
    service_type: PropTypes.object.isRequired,
    selected: PropTypes.arrayOf(PropTypes.shape({
      group: PropTypes.object.isRequired,
      program: PropTypes.object.isRequired,
    }).isRequired),
  }).isRequired,
  fromAssistanceRequest: PropTypes.bool,
  groupsOptionType: PropTypes.string,
  index: PropTypes.number,
  initialPrimaryWorker: PropTypes.object.isRequired,
  isFetchingGroup: PropTypes.bool.isRequired,
  isFetchingNetworkGroups: PropTypes.bool.isRequired,
  isFetchingOONGroups: PropTypes.bool.isRequired,
  isProgramBasedSearch: PropTypes.bool.isRequired,
  networks: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChangeReferredByNetwork: PropTypes.func.isRequired,
  onForceShowOONCaseContext: PropTypes.func,
  oonGroups: PropTypes.array,
  originCoordinates: PropTypes.array,
  referral: PropTypes.object.isRequired,
  registerField: PropTypes.func.isRequired,
  removeSelectedBrowseGroup: PropTypes.func.isRequired,
  searchNetworkGroups: PropTypes.func.isRequired,
  setCanSearch: PropTypes.func.isRequired,
  setFeeScheduleProgramId: PropTypes.func.isRequired,
  showBrowse: PropTypes.bool.isRequired,
  showOONCaseContext: PropTypes.bool,
  suggestedGroups: PropTypes.array,
  touch: PropTypes.func.isRequired,
  triggerBrowse: PropTypes.func.isRequired,
  serviceAreaSupportForOrgsFlag: PropTypes.bool,
  isRefactorInsuranceFiltersEnabled: PropTypes.bool,
  setDisableNextButton: PropTypes.func,
  paginateSearchNetworkGroups: PropTypes.func,
  nationalOONGroups: PropTypes.array,
  nationalStateSuggestedGroups: PropTypes.array,
  query: PropTypes.string,
  selectedProgramsContext: PropTypes.shape({
    selectedPrograms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })).isRequired,
    dispatchRemoveAllPrograms: PropTypes.func.isRequired,
    dispatchAddProgram: PropTypes.func.isRequired,
    dispatchRemoveProgram: PropTypes.func.isRequired,
  }).isRequired,
  updateSelectedPrograms: PropTypes.func.isRequired,
  width: PropTypes.number,
  setHasSensitiveErrors: PropTypes.func.isRequired,
  unregisterField: PropTypes.func,
};

EditReferralDetails.defaultProps = {
  allowEmptyGroups: false,
  assistanceRequest: {},
  canShowOONCaseContext: false,
  contact: {},
  currentUserGroup: {},
  feeScheduleProgramId: null,
  fromAssistanceRequest: false,
  groupsOptionType: '',
  index: undefined,
  onForceShowOONCaseContext: _.noop,
  oonGroups: [],
  showOONCaseContext: false,
  originCoordinates: [],
  suggestedGroups: [],
  serviceAreaSupportForOrgsFlag: false,
  isRefactorInsuranceFiltersEnabled: false,
  setDisableNextButton: _.noop,
  paginateSearchNetworkGroups() { },
  nationalOONGroups: [],
  nationalStateSuggestedGroups: [],
  query: '',
  width: undefined,
  unregisterField: _.noop,
};

EditReferralDetails.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const groupId = _.get(state, 'session.groupId');
  const { groupsUsers, globalState } = state;
  const currentUserGroup = getGroup(state, groupId);
  const activeUsersInGroup = groupsUsers.data.map((p) => p.employee);
  const initialPrimaryWorker = activeUsersInGroup.find((e) => e.id === globalState.currentEmployee.id);
  const referralScopes = _.get(currentUserGroup, 'referral_scopes', []);

  const allContacts = [...state.searchedContacts, ...state.contacts.contacts];
  const selectedContactId = _.get(state, 'selectedContact', '');
  const contact = _.find(allContacts, { id: selectedContactId }) || {};
  const canPaginateNetworkGroups = paginateNetworkGroups(state);
  const serviceAreaSupportForOrgsFlag = serviceAreaSupportForOrgs(state);
  const isRefactorInsuranceFiltersEnabled = pays5590RefactorInsuranceFilters(state);

  return {
    canPaginateNetworkGroups,
    referralScopes,
    currentUserGroup,
    initialPrimaryWorker,
    contact,
    serviceAreaSupportForOrgsFlag,
    isRefactorInsuranceFiltersEnabled,
    isProgramBasedSearch: programBasedSearchSelector(state),
  };
}

  export default connect(mapStateToProps)(
  ReferralFormBaseTemplate(EditReferralDetails),
);
