import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Dialog } from '@unite-us/ui';
import './InactiveProgramDialog.scss';

class InactiveProgramDialog extends Component {
  constructor(props) {
    super(props);

    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  openDialog() {
    return this.inactiveProgram.openDialog()
      .then(this.props.resetActionValue);
  }

  closeDialog() {
    this.inactiveProgram.closeDialog();
  }

  render() {
    return (
      <div className="inactive-program-dialog">
        <Dialog
          id="inactive-program-modal"
          data-testid="inactive-program-modal"
          ref={(el) => { this.inactiveProgram = el; }}
          title="The program selected for this referral is inactive"
          size="large"
          modal
        >
          <div>
            <div className="content-with-actions">
              <div className="content-container inactive-program-dialog__content-container">
                <h2>
                  This program is currently not receiving platform referrals.
                  Please create a new referral for this client.
                </h2>
              </div>
              <div className="actions">
                <span className="action-item">
                  <Button
                    id="close-case-cancel-btn"
                    label="Back to Referral"
                    primary
                    onClick={this.closeDialog}
                  />
                </span>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

InactiveProgramDialog.propTypes = {
  resetActionValue: PropTypes.func.isRequired,
};

export default InactiveProgramDialog;
