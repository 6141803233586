import { compact, reduce } from 'lodash';

const getSelectedChildren = (serviceTypes, service) => compact(reduce(service.children, (a, c) => [
  ...a,
  (serviceTypes.includes(c.value) ? c.label : ''),
], []));

const getFilterSummaryServiceNames = (serviceTypes, serviceOptions) => compact(reduce(serviceOptions, (acc, curr) => [
  ...acc,
  (serviceTypes.includes(curr.value) ? curr.label : ''),
  ...(getSelectedChildren(serviceTypes, curr)),
], []));

export default getFilterSummaryServiceNames;
