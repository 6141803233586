import React from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { TableRow, TableRowColumn } from '@unite-us/ui';
import { DynamicTableRowColumn } from '.';
import { isLockedRow } from '../utils';
import { iconColStyle } from '../styles/dynamicTableStyles';

const DynamicTableRow = ({
  columnLabels, data, hideRowIcon, id, rowIcon, ...props
}) => (
  <TableRow
    {...props}
    className={isLockedRow(data.permissions) ? 'unauthorized' : ''}
    id={id}
  >
    {
      !hideRowIcon && (
        <TableRowColumn
          className="dynamic-table__row-column"
          style={{ overflow: 'visible', ...iconColStyle }}
          {...props}
        >
          {rowIcon(data)}
        </TableRowColumn>
      )
    }
    {
      map(columnLabels, (columnLabel, i) => (
        <DynamicTableRowColumn
          {...props}
          key={`table-row-column-${i}`}
          columnLabel={columnLabel}
          data={data}
        />
      ))
    }
  </TableRow>
);

DynamicTableRow.propTypes = {
  columnLabels: PropTypes.array.isRequired,
  data: PropTypes.shape({
    permissions: PropTypes.object.isRequired,
  }).isRequired,
  hideRowIcon: PropTypes.bool,
  id: PropTypes.string.isRequired,
  rowIcon: PropTypes.func,
};

DynamicTableRow.defaultProps = {
  hideRowIcon: false,
  rowIcon: noop,
};

export default DynamicTableRow;
