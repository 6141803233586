import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'common/utils/browserHistory';
import { fetchServiceCases } from 'actions/Case/Contact/Group';
import CasesTable from 'src/components/Facesheet/Cases/components/CasesTable';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Button,
  Pager,
} from '@unite-us/ui';
import _ from 'lodash';
import { Spinner } from 'common/spinners';
import './FacesheetCases.scss';

class FacesheetCases extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
    };

    this.fetch = this.fetch.bind(this);
    this.initCaseCreation = this.initCaseCreation.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
  }

  componentDidMount() {
    const { contactCases, isFetching } = this.props;

    // @todo This may be unnecessary but I'm curious if this is why cases are
    // not loading when they exist in the overview timeline.
    if (_.isEmpty(contactCases) && !isFetching) {
      this.fetch();
    }
  }

  onNextClick() {
    const page = this.state.page + 1;

    this.setState({ page }, () => {
      this.fetch(page);
    });
  }

  onPrevClick() {
    const page = this.state.page - 1;
    this.setState({ page }, () => {
      this.fetch(page);
    });
  }

  fetch(page = 1) {
    const { groupId, contactId } = this.props;

    this.props.fetchServiceCases(
      groupId,
      contactId,
      {
        page,
      },
    );
  }

  initCaseCreation() {
    const { contactId } = this.props;

    browserHistory.push(`/contacts/${contactId}/cases/new/add-case-details`);
  }

  render() {
    const {
      contact,
      contactCases,
      isCC,
      isFetching,
      groupId,
      paging,
    } = this.props;

    if (!contact) {
      return null;
    }

    return (
      <BaseCard className="facesheet-cases">
        <BaseCardHeader title="Cases">
          {
            contactCases.length > 0 && (
              <div className="facesheet-cases__pagination">
                <Pager
                  paging={paging}
                  disabled={isFetching}
                  onNextClick={this.onNextClick}
                  onPrevClick={this.onPrevClick}
                />
              </div>
            )
          }
          {!isCC && (
          <Button
            id="create-new-case-btn"
            className="facesheet-cases__upload btn-raised btn-success"
            label="Create New Case"
            onClick={this.initCaseCreation}
            primary
          />
          )}
        </BaseCardHeader>
        <BaseCardBody>
          {
            isFetching ?
              <Spinner /> :
              <CasesTable cases={contactCases} contact={contact} groupId={groupId} />
          }
        </BaseCardBody>
      </BaseCard>
    );
  }
}

FacesheetCases.propTypes = {
  contact: PropTypes.object.isRequired,
  contactCases: PropTypes.array.isRequired,
  contactId: PropTypes.string.isRequired,
  fetchServiceCases: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isCC: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  paging: PropTypes.object.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  const contacts = _.get(state, 'contacts.contacts', []);
  const contactId = _.get(ownProps, 'params.id');
  const contactCaseObj = _.get(state.serviceCase, ownProps.params.id);
  const contactCases = _.get(contactCaseObj, 'data', []);
  const isCC = _.wget(state, 'session.isCoordinationGroup', false);
  const paging = _.get(contactCaseObj, 'paging', {});

  return {
    contact: _.find(contacts, { id: contactId }) || {},
    contactCases,
    contactId,
    groupId: _.wget(state, 'session.groupId', ''),
    isCC,
    isFetching: _.get(state, 'serviceCase.isFetching', false),
    paging,
  };
}

export default connect(mapStateToProps, {
  fetchServiceCases,
})(FacesheetCases);
