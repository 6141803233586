import React, { useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import pluralize from 'pluralize';
import { LineItem } from 'common/display/LineItem';
import ExpandableButton from 'common/buttons/ExpandableButton';
import formatSpend from 'common/utils/Money/formatSpend';
import AuthorizationSpendCard from './AuthorizationSpendCard';

const AuthorizationSpendExpandable = ({
  title,
  items,
  totalSpend,
  unit,
  isAuthorized,
  isCostBased,
  currentAuthorizationId,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const formattedTotalSpend = formatSpend(totalSpend, unit, isCostBased);
  const requestCount = `${items.length} ${pluralize('request', items.length)}`;

  if (items.length === 0) {
    return (
      <LineItem field={title}>
        No Requests
      </LineItem>
    );
  }

  return (
    <>
      <LineItem field={title}>
        <div className="flex">
          <span>{formattedTotalSpend} ({requestCount})</span>
          <ExpandableButton
            isOpen={isOpen}
            onClick={onClick}
            dataTestId={`expandable-button-${isAuthorized ? 'authorized' : 'not-yet-authorized'}`}
            className="ml-3"
          />
        </div>
      </LineItem>
      {isOpen && (
        <div className="py-4 space-y-4 col-span-2">
          {items.map((item) => (
            <AuthorizationSpendCard
              key={item.id}
              item={item}
              unit={unit}
              isAuthorized={isAuthorized}
              isCostBased={isCostBased}
              isCurrentItem={currentAuthorizationId === item.id}
              enabledLink
            />
          ))}
        </div>
      )}
    </>
  );
};

AuthorizationSpendExpandable.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      short_id: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      spend: PropTypes.number.isRequired,
      service_starts_at: PropTypes.string.isRequired,
      service_ends_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      requester_name: PropTypes.string.isRequired,
      case_state: PropTypes.string.isRequired,
      case_opened_date: PropTypes.string,
    }).isRequired,
  ).isRequired,
  totalSpend: PropTypes.number.isRequired,
  unit: PropTypes.string,
  isAuthorized: PropTypes.bool,
  isCostBased: PropTypes.bool.isRequired,
  currentAuthorizationId: PropTypes.string.isRequired,
};

AuthorizationSpendExpandable.defaultProps = {
  isAuthorized: false,
  unit: '',
};

export default AuthorizationSpendExpandable;
