import { find } from 'lodash';
import { PropTypes } from 'prop-types';

const AddressFieldConditionalDisplay = ({
  children,
  selectedFieldSet,
  validDisplayTypes,
}) => {
  if (find(validDisplayTypes, (o) => (o === selectedFieldSet))) {
    return children;
  }

  return null;
};

AddressFieldConditionalDisplay.propTypes = {
  children: PropTypes.node,
  selectedFieldSet: PropTypes.string.isRequired,
  validDisplayTypes: PropTypes.array.isRequired,
};

export default AddressFieldConditionalDisplay;
