import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@unite-us/ui';
import classNames from 'classnames';
import './RemoveButton.scss';

const RemoveButton = ({ className, ...props }) => (
  <Button
    className={classNames('remove-button', className)}
    {...props}
  />
);

RemoveButton.propTypes = {
  className: PropTypes.string,
  iconRight: PropTypes.node,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
};

RemoveButton.defaultProps = {
  className: '',
  iconRight: <Icon icon="IconMinusCircle" />,
  label: 'Remove',
};

export default RemoveButton;
