import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { validations } from '@unite-us/app-components';
import { SelectField } from '@unite-us/ui';
import './stylesheets/referralNetworkSelector.scss';

class ReferralNetworksSelector extends Component {
  render() {
    const {
      fields,
      isFetchingNetworkGroups,
      networks,
      registerField,
    } = this.props;

    return (
      <div className="referral-networks-selector">
        <div className="service-type-section row">
          <div className="col-md-6 col-xs-12">
            <SelectField
              autoSelectValue
              className="service-type-network-select"
              id="service-type-network"
              field={fields.referred_by_network}
              label="Sending Network"
              options={networks}
              inline={false}
              ref={registerField}
              validations={validations.isRequired}
              disabled={isFetchingNetworkGroups}
              labelKey="name"
              valueKey="id"
              required
            />
          </div>
        </div>
      </div>
    );
  }
}

ReferralNetworksSelector.propTypes = {
  fields: PropTypes.object.isRequired,
  isFetchingNetworkGroups: PropTypes.bool.isRequired,
  networks: PropTypes.array.isRequired,
  registerField: PropTypes.func.isRequired,
};

export default ReferralNetworksSelector;
