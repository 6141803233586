import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { Icon } from '@unite-us/ui';
import { isEmpty } from 'lodash';
import ToolTip from 'src/common/ToolTip';

function IconButton(props) {
  const {
    className,
    disabled,
    icon,
    id,
    labelText,
    onClick,
    toolTip,
  } = props;

  const Content = () => (
    <button
      aria-label={labelText}
      className={cx(
        className,
        disabled && 'cursor-default opacity-50 focus:outline-none',
        'flex justify-center items-center space-x-2',
        !disabled && 'cursor-pointer',
      )}
      id={id}
      onClick={!disabled ? onClick : undefined}
      type="button"
      tabIndex={0}
    >
      <Icon icon={icon} className="fill-current text-action-blue" size={16} />
      <p className="normal-case text-action-blue whitespace-no-wrap">{labelText}</p>
    </button>
  );

  return (
    !isEmpty(toolTip) ? (
      <ToolTip
        tooltipElementAs="span"
        enableHover
        buttonComponentContent={Content}
        panelClassName="bg-white border border-gray-400 border-solid rounded
        shadow-md text-dark-grey leading-4 w-80 px-5 py-3"
        panelComponentContent={() => (toolTip)}
        placement="bottom-start"
      />
    ) : (
      <Content />
    )
  );
}

IconButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  toolTip: PropTypes.string,
};

IconButton.defaultProps = {
  className: '',
  disabled: false,
  toolTip: '',
};

export default IconButton;
