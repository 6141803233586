import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import OrganizationHeader from 'src/components/Organization/components/OrganizationHeader';
import { ORG_TABS } from 'src/components/Organization/constants';
import FormHeader from 'src/components/Organization/components/FormHeader';
import ProgramForm from 'src/components/Organization/components/ProgramForm';
import ProgramFormSubheader from 'src/components/Organization/components/ProgramForm/ProgramFormSubheader';
import { notifySomethingWentWrong } from 'src/components/Organization/api/hooks/v1/apiHookOptions';
import {
  useLocations,
  useSubmitProgramAndLocations,
} from 'src/components/Organization/api/hooks/v1';
import { browserHistory } from 'common/utils/browserHistory';
import Notifier from 'common/helpers/Notifier';
import { Spinner } from 'src/common/spinners';
import useTrackPageView from 'src/components/Organization/utils/useTrackPageView';
import { ORG_SETTINGS } from 'src/common/utils/EventTracker/utils/eventConstants';
import FormContainer from 'src/components/Organization/components/FormContainer';
import hasBackOfficeAccess from 'src/common/utils/BackOffice/backOffice';

const ProgramNew = ({
  currentProviderId,
  currentProviderName,
  userHasBackOffice,
  location: windowLocation,
}) => {
  useTrackPageView(ORG_SETTINGS.newProgViewed);

  const {
    data: apiProgramLocations,
    isFetching: isFetchingProgramLocations,
  } = useLocations({ providerId: currentProviderId });

  const {
    submit: createProgram,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useSubmitProgramAndLocations();

  useEffect(() => {
    if (isUpdateSuccess) {
      Notifier.dispatch(
        200,
        'Program Successfully Updated',
      );
      browserHistory.push('/organization/settings/');
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isUpdateError) {
      notifySomethingWentWrong();
    }
  }, [isUpdateError]);

  if (isFetchingProgramLocations) {
    return (<Spinner center />);
  }

  const locations = apiProgramLocations.map((location) => ({
    location,
    selected: false,
    submitChange: false,
  }));

  const onSubmit = (values) => {
    createProgram({
      ...values,
      provider: currentProviderId,
    }, undefined, userHasBackOffice, currentProviderName);
  };

  return (
    <>
      <OrganizationHeader currentTab={ORG_TABS.organization} location={windowLocation} />
      <FormContainer>
        <FormHeader
          headingText="Add Program"
          cancelLink="/organization/settings"
        />
        <ProgramFormSubheader />
        <ProgramForm
          onSubmit={onSubmit}
          initialValues={{
            accessibility_options: {},
            delivery_options: {},
            email_addresses: [],
            phone_numbers: [],
            payment_options: {},
            transportation_options: {},
            service_areas_national: { selected: false, submitChange: false },
            service_areas_states: [],
            locations,
            status: 'Accepting Referrals',
          }}
        />
      </FormContainer>
    </>
  );
};
ProgramNew.propTypes = {
  currentProviderId: PropTypes.string.isRequired,
  currentProviderName: PropTypes.string.isRequired,
  userHasBackOffice: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currentProviderId: state.session.groupId,
  currentProviderName: state.session.currentProvider.name,
  userHasBackOffice: hasBackOfficeAccess(state),

});

export default connect(mapStateToProps)(ProgramNew);
