import { FETCH_SERVICE_TYPES } from 'actions';

export default function serviceTypesReducer(state = [], action) {
  switch (action.type) {
    case FETCH_SERVICE_TYPES: {
      return action.payload.data.data;
    }

    default:
      return state;
  }
}
