import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  ActionSelect,
  colors,
} from '@unite-us/client-utils';
import {
  Badge,
  Divider,
} from '@unite-us/ui';
import DetailActionWrapper from 'common/display/SingleItemDetails/DetailActionWrapper';
import AssistanceRequestNav from './AssistanceRequestNav';

const badge = (
  <Badge
    text="REQUEST CLOSED"
    color={colors.palettes.LIGHT_GREY}
    shape="square"
    style={{ color: 'white' }}
  />
);

class AssistanceRequestActions extends Component {
  render() {
    const {
      actions,
      assistanceRequest,
      disabled,
      disabledMessage,
      isClosed,
      viewCase,
    } = this.props;

    return (
      <div className="assistance-request-actions">
        {
          viewCase ? (
            <div>
              <Divider className="detail-info__horizontal-divider mb-4 mt-4" />
              <AssistanceRequestNav serviceCase={assistanceRequest} />
            </div>
          ) : null
        }
        {
          !viewCase || isClosed ? (
            <div>
              <Divider className="detail-info__horizontal-divider mb-4 mt-4" />
              <DetailActionWrapper
                buttonComp={
                  isClosed ? badge : (
                    <div className="action-container">
                      <ActionSelect
                        id="assistance-request-action-select"
                        actions={actions}
                        disabled={disabled}
                        label={disabled ? disabledMessage : null}
                        ref={(actionSelect) => {
                          if (actionSelect) {
                            this.actionSelect = actionSelect;
                          }
                        }}
                      />
                    </div>
                  )
                }
              />
            </div>
          ) : null
        }
      </div>
    );
  }
}

AssistanceRequestActions.propTypes = {
  actions: PropTypes.array.isRequired,
  assistanceRequest: PropTypes.shape({
    converted_to: PropTypes.array,
  }).isRequired,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  isClosed: PropTypes.bool.isRequired,
  viewCase: PropTypes.bool.isRequired,
};

AssistanceRequestActions.defaultProps = {
  disabled: false,
  disabledMessage: '',
};

export default AssistanceRequestActions;
