import React from 'react';
import AssessmentDetailsView from 'src/components/Contacts/components/AssessmentDetailsView';
import { useFindRecord } from 'src/api/APIHooks';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { browserHistory } from 'src/common/utils/browserHistory';

function ContactAssessmentDetailsView(props) {
  const { params } = props;
  const submissionId = params.submission_id;
  const editMode = !submissionId;

  const { data: formResponse } = useFindRecord(
    'form',
    params.assessment_id,
    {
      api: 'coreApi',
      queryConfig: {
        refetchOnWindowFocus: false,
        refetchInterval: false,
        staleTime: Infinity,
      },
    },
  );

  const assessment = formResponse.data.data;

  const onCancel = () => {
    browserHistory.goBack();
  };

  const onSubmit = (id, formUsageType) => {
    if (id && formUsageType) {
      const path = (formUsageType === 'screening' ?
      `/screenings/all/${id}` :
      `/facesheet/${params.id}/forms/${params.assessment_id}/submissions/${id}`);
      browserHistory.replace(path);
    } else {
      browserHistory.goBack();
    }
  };

  return (
    <div className="contact-assessment-details">
      {
        !isEmpty(assessment) && (
          <AssessmentDetailsView
            assessment={assessment}
            submissionId={submissionId}
            editMode={editMode}
            showEditButton={false}
            afterSubmit={onSubmit}
            onCancel={onCancel}
            contextType="person"
            context={params.id}
          />
        )
      }
    </div>
  );
}

ContactAssessmentDetailsView.propTypes = {
  params: PropTypes.object.isRequired,
};

ContactAssessmentDetailsView.defaultProps = {
};

export default React.memo(ContactAssessmentDetailsView);
