import {
useCreateRecord,
useFind,
useUpdateRecord,
} from 'src/api/APIHooks';
import Notifier from 'common/helpers/Notifier';
import { defaultQueryConfig } from '../apiHookOptions';

export const useFindAuthorizationRejectionReasonsByFeeScheduleId = (feeScheduleId) => useFind(
'service_authorization_denial_reason',
  {
    fee_schedule: feeScheduleId,
  },
  {
    queryConfig: {
      placeholderData: undefined,
      ...defaultQueryConfig,
    },
  },
);

export const useCreateAuthorizationRejectionReason = () => useCreateRecord('service_authorization_denial_reason', {
  mutationConfig: {
    onError: (error) => {
      Notifier.dispatch(error.response.status, `Error: there was a problem: ${error.response.data.errors[0].title}`);
    },
    onSuccess: (response) => {
      Notifier.dispatch(response.status, 'Authorization Rejection Reason Successfully Created');
    },
  },
});

export const useUpdateAuthorizationRejectionReason = () => useUpdateRecord('service_authorization_denial_reason', {
  mutationConfig: {
    onError: (error) => {
      Notifier.dispatch(error.response.status, `Error: there was a problem: ${error.response.data.errors[0].title}`);
    },
    onSuccess: (response) => {
      Notifier.dispatch(response.status, 'Authorization Rejection Reason Successfully Updated');
    },
  },
});

export default useFindAuthorizationRejectionReasonsByFeeScheduleId;
