import { DOMAIN_CONSTANTS } from 'src/common/constants';
import { referralRoute } from 'src/components/Referrals/utils/routing';
import enforcedConsentRoute from 'common/utils/Navigation/enforcedConsentRoute';
import { forEach, get, isEmpty } from 'lodash';
import { addClassName, removeClassName } from 'common/utils/domTools';
import { createReferralFromDraft } from 'actions/Referral/Contact/Group';
import { notifyInvalidDraftReferral } from 'actions/Notifier';
import { isHttpError } from 'common/utils/Error';

// TODO - this currently relies heavily on dispatched functions
// but with some more effort can be converted to look like the other referral actions
const handleDraftSubmission = ({
  networkGroups,
  referral,
  resetActionValue,
}) => (
  async (dispatch) => {
    const { description } = referral;
    const referredToGroups = get(referral, 'referred_to_groups_draft', []);

    // TODO this class manipulation probably dead code
    const fieldInvalidDivs = document.querySelectorAll('.field-invalid');
    forEach(fieldInvalidDivs, (div) => removeClassName(div, 'field-invalid'));

    if (!description) {
      const noDescriptionDivs = document.querySelectorAll('.no-description');
      forEach(noDescriptionDivs, (div) => addClassName(div, 'field-invalid'));
    }

    if (!referredToGroups) {
      const noGroupSelectionDivs = document.querySelectorAll('.no-group-selection');
      forEach(noGroupSelectionDivs, (div) => addClassName(div, 'field-invalid'));
    }

    let groups = [];

    if (!isEmpty(referredToGroups)) {
      groups = referredToGroups;
    } else if (get(networkGroups, 'length', 0) === 1) {
      groups = networkGroups;
    }

    if (description && !isEmpty(groups)) {
      return dispatch(createReferralFromDraft(referral, true))
        .then((response) => {
          if (!isHttpError(response)) {
            const contact = get(response, 'data.data.contact', referral.contact);
            enforcedConsentRoute({
              allowSkipConsent: false,
              to: referralRoute({ status: 'sent' }),
              itemType: DOMAIN_CONSTANTS.REFERRAL,
              contact,
            });
          }
        });
    }

    return dispatch(notifyInvalidDraftReferral())
      .then(resetActionValue);
  }
);

export default handleDraftSubmission;
