import { coreApi } from 'src/api/config';
import moment from 'moment';
import { fetchContactDocuments } from 'actions/Document/Contact/Group';
import { convertCoreDocuments } from 'src/api/utils/fileUploadUtils';
import {
  SET_FETCH_SERVICE_CASES,
  UNSET_FETCH_SERVICE_CASES,
  UNSET_SERVICE_CASES_REFETCH,
  FETCH_SERVICE_CASE,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import { get, isEmpty } from 'lodash';
import { cl1018PreferredLanguagesFields } from 'common/utils/FeatureFlags/flags';

function onSuccess(payload, dispatch, contactId) {
  const dispatchObj = {
    type: FETCH_SERVICE_CASE,
    contactId,
    payload,
  };

  dispatch(dispatchObj);
  return payload;
}

export default function fetchServiceCase(groupId, contactId, caseId, provider = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_FETCH_SERVICE_CASES });
      dispatch({ type: UNSET_SERVICE_CASES_REFETCH });

      // fetch this person's documents in case any of them need to be attached to the case
      dispatch(fetchContactDocuments({ groupId, contactId }));

      let allReferrals = [];
      let caseReferrals = [];
      let recordLanguages = [];
      let addresses = [];

      const [response, referralsResponse, documentsResponse, notesResponse] = await Promise.all([
        coreApi.findRecord('case', caseId),
        coreApi.query('referral', { case: caseId, 'case.state': 'off_platform' }),
        coreApi.query('file_upload', {
          record: caseId,
          'record.type': 'case',
        }),
        coreApi.query('notes', {
          subject: caseId,
          'subject.type': 'case',
        }),
      ]);
      const caseObj = response.data.data;
      const referrals = referralsResponse.data.data;
      const notesData = get(notesResponse, 'data.data', []);

      const notes = notesData.filter((n) => n.category === 'external_provider')
        .map((noteObj) => ({
          ...noteObj,
          state: 'off_platform',
          receiving_provider: { name: noteObj.text.split('platform to ')[1] },
          sending_provider: !isEmpty(provider) ? provider :
            get(referrals.filter((r) => r.state === 'accepted')[0], 'receiving_provider', ''),
          created_at: moment(noteObj.created_at).unix(),
        }));

      await Promise.all([
        coreApi.populateRelationship('service', 'service', caseObj),
        coreApi.populateRelationship('outcome', 'outcome', caseObj),
        coreApi.populateRelationship('person', 'person', caseObj),
        coreApi.populateRelationship('primary_worker', 'employee', caseObj),
        coreApi.populateRelationship('program', 'program', caseObj),
        coreApi.populateRelationship('provider', 'provider', caseObj),
        coreApi.populateRelationship('network', 'network', caseObj),
        coreApi.populateRelationship('receiving_provider', 'provider', referrals),
      ]);

      if (caseObj.state === 'draft' || caseObj.state === 'off_platform') {
        allReferrals = await coreApi.query('referral', { case: caseId });
        caseReferrals = allReferrals.data.data;

        await Promise.all([
          coreApi.populateRelationship('receiving_provider', 'provider', caseReferrals),
          coreApi.populateRelationship('sending_provider', 'provider', caseReferrals),
          coreApi.populateRelationship('sending_network', 'network', caseReferrals),
          coreApi.populateRelationship('receiving_network', 'network', caseReferrals),
          coreApi.populateRelationship('receiving_program', 'program', caseReferrals),
        ]);
      }

      await Promise.all([
        coreApi.populateRelationship('person.consent', 'consent', caseObj),
        coreApi.populateRelationship('person.contact_preference', 'contact_preference', caseObj),
      ]);

      const state = getState();
      const preferredLanguagesEnabled = cl1018PreferredLanguagesFields(state);

      ([{ data: recordLanguages }, { data: addresses }] = await Promise.all([
        preferredLanguagesEnabled ?
          coreApi.query('record_languages', { record_id: caseObj.person.id, record_type: 'Person' }) :
          Promise.resolve({ data: [] }),
        coreApi.query('address', { person: caseObj.person.id }),
      ]));

      if (get(caseObj, 'state', '') === 'off_platform') {
        caseObj.program = {
          program: {
            name: 'Referred Out of Network',
          },
        };
        caseObj.out_of_network_providers = referrals.map((r) => ({
          name: get(r, 'receiving_provider.name', ''),
          provider_id: get(r, 'receiving_provider.id', ''),
          provider_type: 'UNLICENSED_PROVIDER',
        }));
      }

      caseObj.referrals = [...caseReferrals, ...notes];
      response.data.data = caseObj;

      let documents = documentsResponse.data.data;
      documents = convertCoreDocuments(documents);
      response.data.data.documents = documents;
      response.data.data.person.preferred_languages = recordLanguages.data;
      response.data.data.person.addresses = addresses.data;

      dispatch({ type: UNSET_FETCH_SERVICE_CASES });
      onSuccess(response, dispatch, contactId);
      return response;
    } catch (error) {
      dispatch({ type: UNSET_FETCH_SERVICE_CASES });
      Notifier.handleErrors(error);
      return error;
    }
  };
}
