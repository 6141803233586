import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { flow } from 'lodash';
import { connect } from 'react-redux';
import {
  BaseCard,
  BaseCardBody,
} from '@unite-us/ui';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { uup459SupersetPhase2 } from 'common/utils/FeatureFlags/flags';
import {
  getActiveStep,
  getActiveStepIndex,
  getContext,
  getStepperConfig,
} from './utils';
import './stylesheets/newItemStepper.scss';

export const NewItemStepper = ({
  children,
  location,
  isSupersetEnabled,
}) => {
  const contextString = location.query.src;
  const [context, setContext] = useState(getContext(location.pathname));
  const [stepperConfig, setStepperConfig] = useState(getStepperConfig(contextString || context));
  const theme = useTheme();

  useEffect(() => {
    const newContext = getContext(location.pathname);
    setContext(newContext);
    setStepperConfig(getStepperConfig(contextString || newContext, isSupersetEnabled));
  }, [location.pathname, location.query.src]);

  const activeStep = getActiveStep(location.pathname, stepperConfig.steps);
  const activeStepIndex = getActiveStepIndex(location.pathname, stepperConfig.steps);

  const childrenWithFuncs = React.Children.map(children, (child) => (
    React.cloneElement(child, {
      context,
      activeStep,
    })
  ));

  return (
    <div className="new-item-stepper container">
      <div className="flex mt-5">
        <div className="font-regular-font font-extrabold text-2xl flex items-center">{stepperConfig.title}</div>
      </div>
      <BaseCard className="card-container">
        <BaseCardBody>
          <div className="new-item-stepper__stepper">
            <Stepper
              activeStep={activeStepIndex}
              orientation={useMediaQuery(theme.breakpoints.down('xs')) ? 'vertical' : 'horizontal'}
            >
              {
                stepperConfig.steps.map((step, index) => (
                  <Step
                    key={step.id}
                    active={activeStepIndex === index}
                    aria-current={activeStepIndex === index ? 'step' : false}
                    completed={step.isStepCompleted({ activeStepIndex })}
                    disabled={step.isStepDisabled({ activeStepIndex })}
                  >
                    <StepButton
                      onClick={step.onStepClick}
                      icon={index + 1}
                    >
                      {step.title}
                    </StepButton>
                  </Step>
                ))
              }
            </Stepper>
          </div>
          <div className="new-item-stepper__content" key={context}>
            {childrenWithFuncs}
          </div>
        </BaseCardBody>
      </BaseCard>
    </div>
  );
};

NewItemStepper.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  isSupersetEnabled: PropTypes.bool,
};

NewItemStepper.defaultProps = {
  isSupersetEnabled: false,
};

function mapStateToProps(state) {
  return { isSupersetEnabled: uup459SupersetPhase2(state) };
}

export default flow(connect(mapStateToProps))(NewItemStepper);
