import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BaseCard, BaseCardBody } from '@unite-us/ui';
import classNames from 'classnames';
import './GroupLogo.scss';

function learnRegExp(url) {
  const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
  return regexp.test(url);
}

function groupInitials(group) {
  let initials = '';
  const { name = '' } = group;
  initials = name.substr(0, 2);
  return initials.toUpperCase();
}

export const GroupLogoContainer = (props) => (
  <BaseCard className="group-logo-container">
    <BaseCardBody>
      <GroupLogo group={props.group} size={props.size} />
    </BaseCardBody>
  </BaseCard>
);

GroupLogoContainer.propTypes = {
  group: PropTypes.object,
  size: PropTypes.string,
};

class GroupLogo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initials: null,
      source: null,
      url: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.setSource(this.props.group);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setSource(nextProps.group);
  }

  setSource(group) {
    const { attributes = {}, logo_url: logoUrl = '', name: groupName = '' } = group;
    const logo_url = attributes.logo_url || logoUrl;
    const name = attributes.name || groupName;

    if (group) {
      if (logo_url && learnRegExp(logo_url)) {
        this.setState({ source: 'avatar', url: logo_url });
      } else if (name) {
        this.setState({ source: 'initials', initials: groupInitials(group) });
      }
    }
  }

  render() {
    const { url, source, initials } = this.state;
    const { className } = this.props;

    const groupLogoClass = classNames({
      'group-logo': true,
      [this.props.size]: this.props.size,
    }, className);

    return (
      <figure className={groupLogoClass} style={this.props.style}>
        {
          source === 'avatar' ?
            <img alt="Group avatar" src={url} /> :
            <span className="initials">{initials}</span>
        }
      </figure>
    );
  }
}

GroupLogo.propTypes = {
  className: PropTypes.string,
  group: PropTypes.object,
  size: PropTypes.string,
  style: PropTypes.object,
};

GroupLogo.defaultProps = {};

export default GroupLogo;
