import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { SelectField } from '@unite-us/ui';
import {
  getAddressString,
  getCurrentAddress,
  getFormattedAddress,
  getFormattedLocations,
  getOurCoordinates,
} from 'src/components/Browse/utils/address';
import { fetchCoreLocations } from 'src/actions/Group/Network';
import { generateUUID } from 'common/utils/utils';
import { addresses } from '@unite-us/client-utils';

const DISTANCE_OPTIONS = [
  { label: '1 Mile', value: '1' },
  { label: '5 Miles', value: '5' },
  { label: '10 Miles', value: '10' },
  { label: '25 Miles', value: '25' },
  { label: '50 Miles', value: '50' },
  { label: '100 Miles', value: '100' },
  { label: 'Any Distance', value: 'any' },
];

const ADDRESS_TYPES = {
  client: 'client',
  ours: 'ours',
  other: 'other',
};

const addressOptions = (contact) => [
  ...(!_.isEmpty(contact) ? [{ label: 'Client Address', value: 'client' }] : []),
  { label: 'Our Address', value: 'ours' },
  { label: 'Other', value: 'other' },
];

const getAddressFormatOptions =
    (results) => results.map(({ description, place_id }) => ({ address: description, id: place_id }));

const addressId = generateUUID();

class GeoFilter extends Component {
  constructor(props) {
    super(props);

    this.applyFilters = this.applyFilters.bind(this);
    this.getOurAddress = this.getOurAddress.bind(this);
    this.getGeoCodeOptions = this.getGeoCodeOptions.bind(this);
    this.getDebouncedGeoCodeOptions = _.debounce(this.getGeoCodeOptions, 100);
    this.setDebouncedAddressSelect = _.debounce(this.setAddressSelect, 200);
    this.setAddress = this.setAddress.bind(this);
    this.setAddressType = this.setAddressType.bind(this);
    this.setDistance = this.setDistance.bind(this);
    this.setOptions = this.setOptions.bind(this);

    this.state = {
      address: props.filters.address || '',
      addressType: props.filters.addressType || '',
      distance: props.filters.distance || 'any',
      locationOptions: [],
      contactAddresses: [],
    };
  }

  componentDidMount() {
    const { currentUserGroup, contact } = this.props;

    const ourAddress = this.getOurAddress();
    const addressID = _.get(ourAddress, 'id', '');
    const addressWithId = _.isEmpty(addressID) ? { ...ourAddress, id: addressId } : { ...ourAddress };
    const clientAddress = getFormattedAddress(addresses.findMainAddress(contact.addresses ?? []));

    fetchCoreLocations(currentUserGroup.id).then((currentProviderLocations) => {
      const formattedLocations = getFormattedLocations(currentProviderLocations);

      // If filters.address is included in an organization's locations, filter out that address to avoid duplicates
      const addressIncludedInGroupLocations = (location) => location.address === addressWithId.address;
      const hasDuplicateLocations = formattedLocations.some(addressIncludedInGroupLocations);
      const options = hasDuplicateLocations ? formattedLocations : [addressWithId, ...formattedLocations];

      this.setState({
        locationOptions: _.filter(options, (option) => !_.isUndefined(option.address)),
      }, () => {
        this.setAddressSelect(!_.isEmpty(clientAddress) ? clientAddress : _.head(this.state.locationOptions));
      });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const incomingFilters = {
      address: nextProps.filters.address,
      addressType: nextProps.filters.addressType,
      distance: nextProps.filters.distance || 'any',
    };

    if (!_.isEqual(this.state.address, nextProps.filters.address)) {
      const newAddress = nextProps.filters.address;

      this.setAddressSelect(newAddress);
    }

    if (!_.isEqual(this.props, nextProps) &&
      !_.isEqual(incomingFilters, this.state) &&
      !_.isEmpty(incomingFilters)) {
      this.setState({
        ...incomingFilters,
      });
    }
  }

  getGeoCodeOptions(address, callback) {
    let options;
    if (address.length > 0) {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions({
        input: address,
        componentRestrictions: { country: 'us' },
      }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const { locationOptions } = this.state;
          const locationAddresses = _.map(locationOptions, (option) => option.address);
          options = _.some(locationAddresses, (location) => _.includes(location, address)) ?
          locationOptions : getAddressFormatOptions(predictions);
          callback({ options });
        } else {
          console.warn(`PlacesService was not successful for the following reason: ${status}`);
          callback({ options: [] });
        }
      });
    } else {
      callback({ options: [] });
    }
  }

  setAddressSelect(address = {}) {
    const { contact } = this.props;
    const { addressType, locationOptions } = this.state;

    const addressWithId = !_.isEmpty(address) && _.isEmpty(address.id) ? { ...address, id: addressId } : address;

    if (this.addressSelectField) {
      const clientAddress = getFormattedAddress(addresses.findMainAddress(contact.addresses));
      const type = addressType.value ? addressType.value : addressType;
      let options;
      if (type === ADDRESS_TYPES.client) {
        options = [clientAddress];
      } else if (type === ADDRESS_TYPES.ours) {
        options = locationOptions;
      } else {
        options = [];
      }

      this.addressSelectField.setOptions({ options, replace: true });
      // eslint-disable-next-line no-unused-expressions
      !_.isUndefined(addressWithId.address) ? this.addressSelectField.setValue(addressWithId) : null;
    }
  }

  setDistanceSelect() {
    if (this.distanceSelectField) {
      this.distanceSelectField.setOptions({ options: DISTANCE_OPTIONS, replace: true });
      this.distanceSelectField.setValue(this.state.distance);
    }
  }

  setDistance(value) {
    this.setState({
      distance: _.isEmpty(value) ? 'any' : value,
    }, () => {
      this.setDistanceSelect();
      this.applyFilters();
    });
  }

  getOurAddress() {
    const {
      currentUserGroup,
      employee,
      geoCoordinates,
      ipCoordinates,
    } = this.props;

    const latLng = getOurCoordinates({
      currentUserGroup,
      employee,
      geoCoordinates,
      ipCoordinates,
    });

    const groupAddress = _.get(currentUserGroup, 'addresses[0]', {});
    const employeeAddress = _.get(employee, 'addresses[0]', {});
    const address = getCurrentAddress({
      latLng,
      groupAddress,
      employeeAddress,
    });

    return address;
  }

  setAddressType(addressType) {
    const {
      contact,
    } = this.props;

    const type = addressType.value;
    const clientAddressData = addresses.findMainAddress(_.get(contact, 'addresses', []));
    const clientAddress = {
      address: getAddressString(clientAddressData),
      id: clientAddressData.id,
    };
    const ourAddress = this.getOurAddress();

    if (this.addressSelectField) {
      let address;
      if (type === ADDRESS_TYPES.client) {
        address = clientAddress;
      } else if (type === ADDRESS_TYPES.other) {
        address = this.state.address;
      } else {
        address = ourAddress;
      }

      this.setDebouncedAddressSelect(address);
    }

    if (type === ADDRESS_TYPES.client && !_.isEmpty(clientAddress)) {
      const geoCoder = new window.google.maps.Geocoder();

      geoCoder.geocode({ address: clientAddress.address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK && !_.isEmpty(results)) {
          const option = {
            address: results[0].formatted_address,
            id: results[0].place_id,
            latLng: {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            },
          };
          this.setState({
            address: option,
            addressType,
          }, () => this.applyFilters());
        } else {
          this.setState({
            address: '',
            addressType,
          }, () => this.applyFilters());
          /* eslint-disable no-console */
          console.warn(`Geocode was not successful for the following reason: ${status}`);
          /* eslint-enable no-console */
        }
      });
    } else if (type === undefined) {
      this.setState({
        address: {},
        addressType: '',
      }, () => {
        this.setAddressSelect(this.state.address);
        this.applyFilters();
      });
    } else {
      this.setState({
        address: type === ADDRESS_TYPES.ours ? ourAddress : this.state.address,
        addressType,
      }, () => {
        if (type === ADDRESS_TYPES.ours) {
          this.applyFilters();
        }
      });
    }
  }

  async setAddress(location = {}) {
    const clientAddress = addresses.findMainAddress(_.get(this.props, 'contact.addresses', []));
    const groupClientLocations = _.without([getFormattedAddress(clientAddress), ...this.state.locationOptions], '');
    const notOurAddress = (!groupClientLocations.map((l) => (l.address)).includes(location.address));
    if (!location.latLng) {
      const service = new window.google.maps.places.PlacesService(document.createElement('div'));
      service.getDetails({
        placeId: location.id,
        fields: ['geometry.location'],
      }, (PlaceResult, PlacesServiceStatus) => {
        if (PlacesServiceStatus === window.google.maps.places.PlacesServiceStatus.OK) {
          const latLng = {
            lat: PlaceResult.geometry.location.lat(),
            lng: PlaceResult.geometry.location.lng(),
          };
          const address = {
            ...location,
            latLng,
          };
          this.setState({
            address,
            addressType: notOurAddress ? { label: 'Other', value: 'other' } : this.state.addressType,
           }, () => this.applyFilters());
          }
        });
      } else {
        const address = location;
        this.setState({
          address,
          addressType: notOurAddress ? { label: 'Other', value: 'other' } : this.state.addressType,
        }, () => this.applyFilters());
    }
  }

  setOptions() {
    const { contact } = this.props;
    const { addressType, locationOptions } = this.state;
    const type = addressType.value ? addressType.value : addressType;
    const clientAddress = getFormattedAddress(addresses.findMainAddress(contact.addresses));

    switch (type) {
      case ADDRESS_TYPES.ours:
        return locationOptions;
      case ADDRESS_TYPES.client:
        return [clientAddress];
      default:
        return '';
    }
  }

  applyFilters() {
    this.props.onFiltersChange(this.state);
    this.props.setGeoFilters(this.state);
  }

  render() {
    const {
      contact,
      label,
    } = this.props;

    const { distance, addressType, locationOptions } = this.state;
    const type = addressType.value ? addressType.value : addressType;
    return (
      <div className="geo-filter section">
        <h5>{label}</h5>
        <SelectField
          id="distance-filter"
          className="geo-filter__distance-select geo-filter__filter"
          label="Distance Select"
          field={{
            onBlur: _.noop,
            onChange: this.setDistance,
            value: distance,
          }}
          options={DISTANCE_OPTIONS}
          placeholder="Distance"
          ref={(div) => { this.distanceSelectField = div; }}
          searchEnabled={false}
          shouldSort={false}
          hideLabel
        />

        <SelectField
          className="geo-filter__address-type-select geo-filter__filter"
          field={{
            onBlur: _.noop,
            onChange: this.setAddressType,
            value: type,
          }}
          forceObjectValue
          hideLabel
          id="address-type-filter"
          label="Address Type Select"
          placeholder="Address Type"
          options={addressOptions(contact)}
          searchEnabled={false}
          shouldSort={false}
        />

        <SelectField
          className="geo-filter__address-select geo-filter__filter"
          field={{
            onBlur: _.noop,
            onChange: this.setAddress,
          }}
          filterOptions={(options) => options}
          options={type === ADDRESS_TYPES.ours ? locationOptions : this.setOptions}
          hideLabel
          id="searched-addresses-filter"
          label="Address"
          labelKey="address"
          loadOptions={type === ADDRESS_TYPES.other ? this.getDebouncedGeoCodeOptions : null}
          name="search-addresses"
          placeholder="Search for an Address"
          ref={(div) => { this.addressSelectField = div; }}
          valueKey="id"
          shouldSearchOnChange={false}
          shouldSearchOnHide={false}
        />

      </div>
    );
  }
}

GeoFilter.propTypes = {
  contact: PropTypes.object.isRequired,
  currentUserGroup: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  setGeoFilters: PropTypes.func.isRequired,
  employee: PropTypes.object.isRequired,
  geoCoordinates: PropTypes.object.isRequired,
  ipCoordinates: PropTypes.object.isRequired,
};

export default GeoFilter;
