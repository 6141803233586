import axios from 'axios';
import Notifier from 'common/helpers/Notifier';
import { checkUploads } from 'common/form/DocumentUploader/utils';
import { first, wget } from 'lodash';

const SUCCESS_MESSAGE = 'You will receive an email when the upload is complete';

export default function uploadGroupFile({ documents, groupId, sendEmail = true }) {
  return () => {
    function onSuccess(payload) {
      if (sendEmail) {
        Notifier.dispatch('success', SUCCESS_MESSAGE);
      }
      return payload;
    }
    function onError(error) {
      Notifier.handleErrors(error);
      return error;
    }
    return checkUploads({ documents }).then((validDocs = {}) => {
      const document_data_uri = wget(first(validDocs.documents), 'document_data_uri');
      const options = {
        import: {
          document_data_uri,
          send_email: sendEmail,
        },
      };
      const request = axios.post(`/groups/${groupId}/imports`, options);
      return request.then(onSuccess, onError);
    });
  };
}
