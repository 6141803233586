import { browserHistory } from 'common/utils/browserHistory';
import _ from 'lodash';

export function setInitialStepperConfig(props, prefix) {
  return [
    {
      title: 'Case Information',
      content: props.children,
      onStepClick: () => browserHistory.push(`${prefix}/contacts/${props.contactId}/cases/new/add-case-details`),
    },
    {
      title: 'Supporting Information',
      content: props.children,
      stepDisabled: (currentStep) => currentStep < 1,
      onStepClick: () => browserHistory.push(`${prefix}/contacts/${props.contactId}/cases/new/add-case-assessments`),
    },
    {
      title: 'Case Review',
      content: props.children,
      stepDisabled: (currentStep) => currentStep < 2,
    },
  ];
}

export function setDisabledStepperConfig(state, nextProps) {
  return state.stepperConfig.reduce((acc, curr) => {
    if (curr.title === 'Supporting Information') {
      const { assistanceRequestId } = nextProps;
      const prefix = assistanceRequestId ? `/assistance-requests/${assistanceRequestId}` : '';
      return [...acc, {
        title: 'Supporting Information',
        content: nextProps.children,
        stepDisabled: (currentStep) => currentStep < 1 || _.isEmpty(nextProps.groupForms),
        onStepClick: () => browserHistory.push(
          `${prefix}/contacts/${nextProps.contactId}/cases/new/add-case-assessments`,
        ),
      }];
    }

    return [...acc, curr];
  }, []);
}

export function emptyGroupForms(nextProps) {
  return _.isEmpty(nextProps.groupForms);
}

export function containGroupForms(nextProps) {
  return !_.isEmpty(nextProps.groupForms);
}

export function atReviewStep(nextProps) {
  return nextProps.currentStep === 2;
}

export function groupFormsPropsChanged(nextProps, props) {
  return !_.isEqual(nextProps.groupForms, props.groupForms);
}
