import React from 'react';
import { Icon } from '@unite-us/ui';

export const ErrorPage = () => (
  <div className="h-full w-full flex items-center justify-center space-x-2">
    <Icon className="fill-current text-red" icon="IconExclamationCircle" size={40} />
    <p className="text-20px">
      Error fetching required data. Please refresh.
    </p>
  </div>
);
