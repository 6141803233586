import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

function MethodsOfContact({ contactMethods, enums }) {
  const contactEnums = _.get(enums, 'contact_data.communication_preferences.methods_of_contact', []);

  return (
    <div className="methods-of-contact">
      {
        _.map(
          _.filter(contactEnums, (method) => _.includes(contactMethods, method.value)),
          'display_name',
        ).join(', ')
      }
    </div>
  );
}

MethodsOfContact.propTypes = {
  contactMethods: PropTypes.array.isRequired,
  enums: PropTypes.shape({
    contact_data: PropTypes.shape({
      communication_preferences: PropTypes.shape({
        methods_of_contact: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default MethodsOfContact;
