import Notifier from 'common/helpers/Notifier';
import {
  FETCH_FEE_SCHEDULES_STARTED,
  FETCH_FEE_SCHEDULES_SUCCESS,
  FETCH_FEE_SCHEDULES_ERROR,
} from 'actions';
import { apiV1 } from 'src/api/config';
import { isHttpError } from 'common/utils/Error';
import { isHttpSuccess } from 'src/api/utils/httpStatus';

export default function fetchFeeSchedules(options = {}) {
  return async (dispatch) => {
    dispatch({ type: FETCH_FEE_SCHEDULES_STARTED });
    const payload = await apiV1.query('fee_schedule', { id: options.id }, { include: options.include });

    if (!payload || !isHttpSuccess(payload.status)) {
      dispatch({ type: FETCH_FEE_SCHEDULES_ERROR });

      if (isHttpError(payload)) {
        Notifier.dispatch('error', 'Failed to load fee schedules');
      } else {
        Notifier.handleErrors(payload);
      }

      return payload;
    }

    dispatch({
      type: FETCH_FEE_SCHEDULES_SUCCESS,
      payload: payload.data.data,
    });

    return payload;
  };
}
