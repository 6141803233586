import { FAUX_MIDNIGHT, NOON } from "../constants";
function getRelativeTime(time) {
  if (time === FAUX_MIDNIGHT) {
    return 0;
  }
  return time < NOON ? time : time - NOON;
}
var formatMilitaryHours = function formatMilitaryHours(time) {
  return time > 12 ? time - 12 : time;
};
function formatTimeOfDay() {
  var time = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var minute;
  var displayHour;
  var label;
  var timeVal = parseInt(time, 10);
  if (typeof time === 'number') {
    var relativeTime = getRelativeTime(timeVal);
    var hour = Math.floor(relativeTime / 60);
    minute = relativeTime - hour * 60;
    displayHour = hour === 0 ? '12' : hour;
    label = timeVal < NOON || timeVal === FAUX_MIDNIGHT ? 'AM' : 'PM';
  }
  if (typeof time === 'string') {
    displayHour = timeVal === 0 ? 12 : formatMilitaryHours(timeVal);
    minute = timeVal ? parseInt(time.split(':')[1], 10) : 0;
    label = timeVal < 12 ? 'AM' : 'PM';
  }
  var displayMinute = minute < 10 ? "0".concat(minute) : minute;
  return "".concat(displayHour, ":").concat(displayMinute, " ").concat(label);
}
export default formatTimeOfDay;