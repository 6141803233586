import axios from 'axios';
import { wget } from 'lodash';
import { UPDATE_CONSENT_CONTACT } from 'actions';

export default function fetchConsentAppUrl(groupId, contactId) {
  const request = axios.post(`/groups/${groupId}/contacts/${contactId}/consents/web`);

  return (dispatch) => {
    function onSuccess(payload) {
      dispatch({
        type: UPDATE_CONSENT_CONTACT,
        payload,
      });

      return wget(payload, 'data.data.response_url');
    }

    function onError(error) {
      // return 'https://consent.uniteusdev.com?d6c5c59cb465ed7218df544454aaec12a6d82b1f1c3601ab939eab0593ad&form-only'
      return error;
    }

    return request.then(onSuccess, onError);
  };
}
