import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  get,
  includes,
  map,
  reduce,
  filter,
  isEmpty,
  toLower,
  debounce,
} from 'lodash';
import moment from 'moment';
import { validateReduxForm } from 'common/form';
import { Spinner } from 'common/spinners';
import FormFooter from 'common/form/FormButtons/components/FormFooter';
import { fetchServiceCase, updateOONCase, updateOONCaseProgramAndProvider } from 'actions/Case/Contact/Group';
import { getGroup } from 'common/utils/stateHelpers';
import { getClientCenter, getOurCoordinates } from 'src/components/Browse/utils/address';
import { setDashboardRefetch } from 'actions/Dashboard';
import {
  searchNetworkGroupsByReferralScopes,
  searchNetworkBrowseGroupsForCases as searchNetworkBrowseGroups,
} from 'actions/Group/Network';
import {
  hasPaymentsUserAccess,
  includePathwaysServices,
  organizationPaginationLimit,
  paginateNetworkGroups,
  hasCasesOONReferralUnlicensedOrgs,
  programBasedSearchSelector,
  hint716SearchNetworkHubSupportPremiumSelector,
} from 'src/common/utils/FeatureFlags/flags';
import { fetchPosition, fetchIpPosition } from 'src/common/utils/Session/actions';
import { removeAllGroupFields, structureOONProviders } from 'src/components/Referrals/ReferralGroupsPrograms/utils';
import { getOONGroupNameFromField } from 'src/components/Referrals/ReferralFormFields/OONGroupsSelector/utils';
import { getOONProviders, getOONProgramsAndProviders } from 'src/components/Cases/utils';
import { isHttpError } from 'common/utils/Error';
import ServiceCaseReferredToField from './ServiceCaseReferredToField';
import './ServiceCaseReferredToForm.scss';

export class ServiceCaseReferredToForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oonGroups: [],
      initializingForm: true,
      submitting: false,
    };

    this.addGroupField = this.addGroupField.bind(this);
    this.addCustomField = this.addCustomField.bind(this);
    this.debouncedSearchOONGroups = debounce(this.debouncedSearchOONGroups, 250).bind(this);
    this.buildParams = this.buildParams.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.initializeForm = this.initializeForm.bind(this);
    this.searchOONGroups = this.searchOONGroups.bind(this);
    this.submitReferredToField = this.submitReferredToField.bind(this);
    this.paginateSearchOONGroups = this.paginateSearchOONGroups.bind(this);
  }

  componentDidMount() {
    // "Using the method isMounted() is an anti-pattern, but using a property, like _isMounted is recommended."
    // eslint-disable-next-line no-underscore-dangle
    this._isMounted = true;
    if (this.props.canPaginateNetworkGroups) {
      this.paginateSearchOONGroups();
    } else {
      this.searchOONGroups();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.fields.service_case.oonCase.selected.length === 0) {
      this.addGroupField();
    }

    if (nextProps.fields.service_case.oonCase.custom.length === 0) {
      this.addCustomField();
    }

    // Only call this function if canPaginateNetworkGroups feature flag is off
    if (!this.props.modalIsOpen && nextProps.modalIsOpen && !nextProps.canPaginateNetworkGroups) {
      this.searchOONGroups();
    }

    if (this.props.modalIsOpen && !nextProps.modalIsOpen) {
      this.onClose();
    }
    if (!this.props.modalIsOpen && nextProps.modalIsOpen) {
      this.initializeForm();
    }
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-underscore-dangle
    this._isMounted = false;
  }

  onSubmit() {
    const {
      fields: {
        service_case: {
          oonCase: { selected, custom = [] },
        },
      },
      employee,
      groupId,
      invalidateCaseReferrals,
      serviceCase,
      contactId,
      casesOONReferralUnlicensedOrgs,
      useProgramBasedSearch,
    } = this.props;

    this.setState({ submitting: true });

    if (useProgramBasedSearch) {
      let selectedProgramsAndProviders = selected.filter(getOONGroupNameFromField);

      if (!isEmpty(custom)) {
        const formattedCustomGroups = map(custom, (customGroup) => ({
          group: {
            ...customGroup,
            value: {
              name: customGroup?.group.value,
              provider_type: 'CUSTOM',
            },
          },
        }));

        selectedProgramsAndProviders = selectedProgramsAndProviders.concat(formattedCustomGroups);
      }
      const programsAndProviders = selectedProgramsAndProviders.map((p) => p.group.value);
      this.props.updateOONCaseProgramAndProvider(serviceCase, programsAndProviders, employee).then((response) => {
        invalidateCaseReferrals();
        if (isHttpError(response)) {
          if (window.Rollbar) {
            window.Rollbar.warning('Error updating the service case', response);
          }
          this.setState({ submitting: false });
          this.onClose();
        } else {
          this.props.fetchServiceCase(groupId, contactId, serviceCase.id);
          this.submitReferredToField();
        }
      });
    } else if (casesOONReferralUnlicensedOrgs) {
      const selectedGroups = selected.filter(getOONGroupNameFromField);
      const oonProviders = {
        service_case: {
          out_of_network_providers: structureOONProviders(selectedGroups),
        },
      };

      this.props.updateOONCase(contactId, employee, groupId, serviceCase, oonProviders)
        .then((response) => {
          invalidateCaseReferrals();
          if (isHttpError(response)) {
            if (window.Rollbar) {
              window.Rollbar.warning('Error updating the service case', response);
            }
            this.setState({ submitting: false });
            this.onClose();
          } else {
            this.props.fetchServiceCase(groupId, contactId, serviceCase.id);
            this.submitReferredToField();
          }
        });
    } else {
      this.submitReferredToField();
    }

    if (this.props.canPaginateNetworkGroups) {
      this.paginateSearchOONGroups();
    } else {
      this.searchOONGroups();
    }
  }

  onClose() {
    const { fields } = this.props;
    const selectedProviders = fields.service_case.oonCase.selected;

    removeAllGroupFields(selectedProviders);

    this.initializeForm();

    this.props.closeModal();
  }

  submitReferredToField() {
    this.props.setDashboardRefetch();
    this.setState({ submitting: false });
    this.onClose();
  }

  initializeForm() {
    const { serviceCase, useProgramBasedSearch } = this.props;

    // This boolean is needed to determine how we want to normalize the selected groups.
    const oonGroupsExist = this.state.oonGroups.length > 0;

    const initialValues = useProgramBasedSearch ?
    {
      referred_to: get(serviceCase, 'provider.name', {}),
      service_case: {
        oonCase: {
          selected: getOONProgramsAndProviders(
            get(serviceCase, 'referrals', [])
              .filter((r) => r.state === 'off_platform' && r.category !== 'external_provider')
              .map((r) => (
                !isEmpty(r.receiving_program) ? r.receiving_program : { ...r.receiving_provider, isProvider: true }
              )),
            oonGroupsExist,
          ),
          custom: getOONProgramsAndProviders(
            get(serviceCase, 'referrals', [])
              .filter((r) => r.state === 'off_platform' && r.category === 'external_provider')
              .map((r) => (
                !isEmpty(r.receiving_program) ? r.receiving_program : { ...r.receiving_provider, isProvider: true }
              )),
          ),
        },
      },
    } : {
      referred_to: get(serviceCase, 'provider.name', {}),
      service_case: {
        oonCase: {
          selected: getOONProviders(
            get(serviceCase, 'referrals', [])
              .filter((r) => r.state === 'off_platform')
              .map((r) => r.receiving_provider),
            oonGroupsExist,
          ),
        },
      },
    };

    this.props.initializeForm(initialValues);

    // Set state with { initializingForm: false } to remove the Spinner.
    this.setState({ initializingForm: false });
  }

  buildParams(text) {
    const {
      contact,
      currentUserGroup,
      includePathways,
      usePaymentsUserRole,
      serviceCase,
      serviceTypes,
      originLatLng,
      useProgramBasedSearch,
    } = this.props;

    const programBasedParams = useProgramBasedSearch ?
      {
        age: null,
        genders: [],
        residency: {
          city: null,
          county: null,
          state: null,
          postal_code: null,
        },
      } :
      null;

    if (useProgramBasedSearch) {
      const ourContact = contact;
      if (ourContact.date_of_birth) {
        const birthDate = moment.unix(ourContact.date_of_birth);
        const currentDate = moment();
        programBasedParams.age = currentDate.diff(birthDate, 'months');
      }

      if (
          ourContact.gender &&
          ourContact.gender.toLowerCase() !== 'undisclosed' &&
          ourContact.gender.toLowerCase() !== 'other'
        ) {
          programBasedParams.genders = [ourContact.gender.toLowerCase()];
      }

      if (ourContact.addresses?.length > 0) {
        const addressObject = ourContact.addresses.filter((a) => a.is_primary)[0] || ourContact.addresses[0];
        programBasedParams.residency.city = addressObject?.city;
        programBasedParams.residency.county = addressObject?.county;
        programBasedParams.residency.state = addressObject?.state;
        programBasedParams.residency.postal_code = addressObject?.postal_code;
      }
    }
    const referredByNetworkId = get(serviceCase, 'network.id', '');
    const service_type_ids = get(serviceCase, 'service_type.id', '');
    const locationInfo = {
      locations: {
        radius: {
          latitude: originLatLng[0],
          longitude: originLatLng[1],
          distance: 2500,
        },
      },
    };

    const reqParams = useProgramBasedSearch ?
      {
        q: {
          locations: {
            radius: {
              latitude: originLatLng[0],
              longitude: originLatLng[1],
              distance: 2500,
            },
          },
          ...programBasedParams,
          licensed: false,
          networks: referredByNetworkId,
          services: service_type_ids,
          ...(text && { query: text }),
          ...(includePathways && { include_pathways: true }),
          ...(usePaymentsUserRole && { include_payments: true }),
        },
        page: 1,
        size: 50,
        sort: 'distance',
      } :
      {
        q: {
          ...locationInfo,
          licensed: false,
          networks: referredByNetworkId,
          'programs.services': service_type_ids,
          ...(text && { query: text }),
          ...(includePathways && { include_pathways: true }),
          ...(usePaymentsUserRole && { include_payments: true }),
        },
        page: 1,
        size: 50,
        sort: 'distance',
      };

    return {
      browseMapView: false,
      currentUserGroup,
      groupId: get(currentUserGroup, 'id', ''),
      networkId: referredByNetworkId,
      reqParams,
      serviceTypes,
    };
  }

  // This is called when the user searches for a specific Group in the Dropdown.
  debouncedSearchOONGroups(search = '') {
    const { serviceCaseOONProviders, useProgramBasedSearch } = this.props;

    const params = this.buildParams(search);

    this.props.searchNetworkBrowseGroups({
      reqParams: params.reqParams,
      useProgramBasedSearch,
      hint716SearchNetworkHubSupportPremium: this.props.hint716SearchNetworkHubSupportPremium,
    })
      .then((response) => {
        if (Array.isArray(response)) {
          if (isHttpError(response)) {
            if (window.Rollbar) {
              window.Rollbar.warning('Error searching network groups by Referral scopes', response);
            }
            setTimeout(() => {
              this.setState({ fetchingOONGroups: false });
              this.props.closeModal();
            }, 1000);
          } else {
            const filteredGroups = filter(
              response,
              ({ name }) => includes(toLower(name), toLower(search)),
            );

            const filteredNames = map(filteredGroups, 'name');

            // Used to determine if current selected provider should be included in the drop down list
            // condition 1 - search conditional check
            // condition 2 - current list does not contain selected referred to
            // condition 3 - selected referred to is not custom provider
            const oonCurrentProviders = reduce(serviceCaseOONProviders, (acc, curr) => {
              if (includes(toLower(curr.name), toLower(search)) &&
                !includes(filteredNames, curr.name) &&
                (curr.provider_id || curr.id)
              ) {
                return [...acc, {
                  id: curr.provider_id || curr.id,
                  name: curr.name,
                  addresses: curr.addresses,
                }];
              }

              return acc;
            }, []);

            if (filteredGroups.length) {
              this.setState({ oonGroups: [...filteredGroups, ...oonCurrentProviders] });
            }
          }
        }
      });
  }

  // This is called on Mount to fetch the closest 50 OON Groups to populate the dropdown.
  paginateSearchOONGroups() {
    const {
      selectedProgramsContext: {
        selectedPrograms,
      },
    } = this.props;
    const selectedProgramIds = selectedPrograms.map((p) => p.id);
    const params = this.buildParams();

    this.setState({ fetchingOONGroups: true }, () => {
      this.props.searchNetworkBrowseGroups({
        reqParams: params.reqParams,
        useProgramBasedSearch: this.props.useProgramBasedSearch,
        additionalProgramIds: selectedProgramIds,
        hint716SearchNetworkHubSupportPremium: this.props.hint716SearchNetworkHubSupportPremium,
      })
        .then((response) => {
          if (Array.isArray(response)) {
            if (isHttpError(response)) {
              if (window.Rollbar) {
                window.Rollbar.warning('Error searching network groups by Referral scopes', response);
              }

              setTimeout(() => {
                this.setState({ fetchingOONGroups: false });
                this.props.closeModal();
              }, 1000);
            } else {
              const filteredNames = map(response, 'name');

              // Used to determine if current selected provider should be included in the drop down list
              // condition 1 - current list does not contain selected referred to
              // condition 2 - selected referred to is not custom provider
              const caseReferrals = get(this.props.serviceCase, 'referrals', []);
              // filter out referrals that have a receiving_program
              // while using programBasedSearch, we only want receiving providers if there's no receiving_program
              const caseRecipients = this.props.useProgramBasedSearch ?
               caseReferrals.filter((r) => isEmpty(r.receiving_program)).map((r) => r.receiving_provider) :
               caseReferrals.map((referral) => referral.receiving_provider);
              const oonCurrentProviders = reduce(caseRecipients, (acc, curr) => {
                if (!includes(filteredNames, curr.name) &&
                  (curr.provider_id || curr.id)
                ) {
                  return [...acc, {
                    id: curr.provider_id || curr.id,
                    name: curr.name,
                    addresses: curr.addresses,
                  }];
                }

                return acc;
              }, []);

              const allOONGroups = response.length ? [...response, ...oonCurrentProviders] : [];

              this.setState({
                oonGroups: allOONGroups,
                fetchingOONGroups: false,
              });
              this.initializeForm(allOONGroups);
            }
          }
        });
    });
  }

  searchOONGroups() {
    const { serviceCase, groupId, pageLimit } = this.props;
    const referredByNetworkId = get(serviceCase, 'network.id', '');
    const referredToNetworkId = referredByNetworkId;
    const service_type_ids = [serviceCase.service_type.id];
    const errorMessage = ['Sorry, this field is not editable at this time. The requested resource could not be found.'];

    const params = {
      errorMessage,
      referredByNetworkId,
      referredToNetworkId,
      serviceCase,
      groupId,
      options: {
        per: pageLimit,
        q: {
          service_type_ids,
          permitted_networks: [referredByNetworkId],
        },
      },
      isOON: true,
    };

    this.setState({ fetchingOONGroups: true }, () => {
      this.props.searchNetworkGroupsByReferralScopes(params)
        .then((response) => {
          if (isHttpError(response)) {
            if (window.Rollbar) {
              window.Rollbar.warning('Error searching network groups by Referral scopes', response);
            }
            setTimeout(() => {
              this.setState({ fetchingOONGroups: false });
              this.props.closeModal();
            }, 1000);
          } else {
            const oonGroups = response.data.data;
            this.setState({ oonGroups, fetchingOONGroups: false });
            this.initializeForm(oonGroups);
          }
        });
    });
  }

  addGroupField() {
    this.props.fields.service_case.oonCase.selected.addField();
  }

  addCustomField() {
    this.props.fields.service_case.oonCase.custom.addField();
  }

  render() {
    const {
      canPaginateNetworkGroups,
      casesOONReferralUnlicensedOrgs,
      fields,
      handleSubmit,
      originLatLng,
      registerField,
      submitting,
      serviceCase,
      useProgramBasedSearch,
    } = this.props;

    if (submitting) {
      return <Spinner text="Updating Referred To..." />;
    }

    const customGroups = fields.service_case.oonCase.custom;
    const selectedGroups = fields.service_case.oonCase.selected;
    const showError = get(serviceCase, 'state') === 'off_platform' &&
      get(serviceCase, 'service.sensitive') &&
      selectedGroups.length > 1;

    return (
      <form className="service-case-referred-to-form" onSubmit={handleSubmit(this.onSubmit)}>
        {
          this.state.initializingForm ||
            this.state.submitting ||
            this.state.fetchingOONGroups ?
              <Spinner scale={0.7} /> :
            (
              <div>
                <div className="service-case-referred-to-form__field-container">
                  <ServiceCaseReferredToField
                    canPaginateNetworkGroups={canPaginateNetworkGroups}
                    casesOONReferralUnlicensedOrgs={casesOONReferralUnlicensedOrgs}
                    customGroups={customGroups}
                    useProgramBasedSearch={useProgramBasedSearch}
                    debouncedSearchNetworkGroups={this.debouncedSearchOONGroups}
                    fetchingOONGroups={this.state.fetchingOONGroups}
                    oonGroups={this.state.oonGroups}
                    originCoordinates={originLatLng}
                    referredTo={fields.referred_to}
                    registerField={registerField}
                    selectedGroups={selectedGroups}
                    submitting={this.state.submitting}
                    originLatLng={originLatLng}
                    showError={showError}
                  />
                </div>

                <FormFooter
                  onSubmitLabel="Save"
                  handleSubmit={() => {
                    handleSubmit(this.onSubmit)();
                  }}
                  submitDisabled={submitting}
                  onCancel={this.onClose}
                />
              </div>
            )
        }
      </form>
    );
  }
}

ServiceCaseReferredToForm.propTypes = {
  canPaginateNetworkGroups: PropTypes.bool.isRequired,
  contact: PropTypes.object.isRequired,
  currentUserGroup: PropTypes.object,
  casesOONReferralUnlicensedOrgs: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  contactId: PropTypes.string.isRequired,
  employee: PropTypes.object.isRequired,
  fetchServiceCase: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  includePathways: PropTypes.bool.isRequired,
  initializeForm: PropTypes.func.isRequired,
  invalidateCaseReferrals: PropTypes.func.isRequired,
  useProgramBasedSearch: PropTypes.bool.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  pageLimit: PropTypes.number,
  registerField: PropTypes.func.isRequired,
  searchNetworkBrowseGroups: PropTypes.func.isRequired,
  searchNetworkGroupsByReferralScopes: PropTypes.func.isRequired,
  serviceCase: PropTypes.object.isRequired,
  setDashboardRefetch: PropTypes.func.isRequired,
  serviceCaseOONProviders: PropTypes.array.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  updateOONCase: PropTypes.func.isRequired,
  updateOONCaseProgramAndProvider: PropTypes.func.isRequired,
  usePaymentsUserRole: PropTypes.bool.isRequired,
  originLatLng: PropTypes.array,
  selectedProgramsContext: PropTypes.shape({
    selectedPrograms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })).isRequired,
    dispatchRemoveAllPrograms: PropTypes.func.isRequired,
    dispatchAddProgram: PropTypes.func.isRequired,
    dispatchRemoveProgram: PropTypes.func.isRequired,
  }).isRequired,
  hint716SearchNetworkHubSupportPremium: PropTypes.bool,
};

ServiceCaseReferredToForm.defaultProps = {
  casesOONReferralUnlicensedOrgs: false,
  currentUserGroup: {},
  originLatLng: [],
  pageLimit: 50,
  hint716SearchNetworkHubSupportPremium: false,
};

function mapStateToProps(state, ownProps) {
  const casesOONReferralUnlicensedOrgs = hasCasesOONReferralUnlicensedOrgs(state);
  const contact = get(ownProps, 'contact', {});
  const groupId = state.session.groupId;
  const currentUserGroup = getGroup(state, groupId) || {};

  // Get coordinates
  const geoCoordinates = get(state, 'session.position.geoCoordinates', {});
  const ipCoordinates = get(state, 'session.position.ipCoordinates', {});

  const employee = get(state, 'globalState.currentEmployee');
  const ourCenter = getOurCoordinates({
    currentUserGroup,
    employee,
    geoCoordinates,
    ipCoordinates,
  });
  const clientCenter = getClientCenter(contact);
  const coordinates = clientCenter || ourCenter || {};
  const originLatLng = [coordinates.lat, coordinates.lng];

  // Props needed for paginated Groups fetch.
  const canPaginateNetworkGroups = paginateNetworkGroups(state); // feature flag.
  const pageLimit = organizationPaginationLimit(state);
  const serviceTypes = get(state, 'session.globals.service_types', []);

  return {
    canPaginateNetworkGroups,
    casesOONReferralUnlicensedOrgs,
    currentUserGroup,
    clientCenter,
    employee,
    includePathways: includePathwaysServices(state),
    hint716SearchNetworkHubSupportPremium: hint716SearchNetworkHubSupportPremiumSelector(state),
    usePaymentsUserRole: hasPaymentsUserAccess(state),
    useProgramBasedSearch: programBasedSearchSelector(state),
    geoCoordinates,
    ipCoordinates,
    pageLimit,
    originLatLng,
    serviceTypes,
  };
}

const fields = [
  'referred_to',
  'service_case.oonCase.selected[].group',
  'service_case.oonCase.custom[].group',
];

export default validateReduxForm(
  {
    form: 'editServiceCaseReferredToForm',
    fields,
  },
  mapStateToProps,
  {
    fetchServiceCase,
    searchNetworkGroupsByReferralScopes,
    updateOONCase,
    updateOONCaseProgramAndProvider,
    setDashboardRefetch,
    searchNetworkBrowseGroups,
    fetchPosition,
    fetchIpPosition,
  },
)(ServiceCaseReferredToForm);
