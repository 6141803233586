import {
  GROUPS_PROGRAM_SET_FETCHING,
  GROUPS_PROGRAM_UNSET_FETCHING,
  FETCH_GROUPS_PROGRAMS,
  CREATE_GROUPS_PROGRAM,
  UPDATE_GROUPS_PROGRAM,
} from 'actions';
import _ from 'lodash';
import { serviceTypes } from '@unite-us/client-utils';
import { mergeIncludedById } from './utils';

const defaultState = {
  isFetching: true,
  data: [],
  paging: {},
  programsServiceTypes: [],
};

export default function groupsProgramsReducer(state = defaultState, action) {
  const { payload, globalState } = action;

  switch (action.type) {
    case GROUPS_PROGRAM_SET_FETCHING:
      return { ...state, isFetching: true };

    case GROUPS_PROGRAM_UNSET_FETCHING:
      return { ...state, isFetching: false };

    case FETCH_GROUPS_PROGRAMS: {
      const programmingOptionsArray =
        serviceTypes.composeProgramServiceTypes(
          payload.data.data,
          globalState.session.globals.service_types,
        );

      const programsServiceTypes = {};
      if (programmingOptionsArray) {
        programmingOptionsArray.forEach((program) => {
          programsServiceTypes[program.id] = program.service_types;
        });
      }

      const programmingOptionsArrayWithIncluded = mergeIncludedById({
        data: programmingOptionsArray,
        included: payload.data.included || [],
        includedFields: ['provider', 'fee_schedule_program'],
      });

      const composedPayload = {
        ...payload.data,
        data: programmingOptionsArrayWithIncluded,
        programsServiceTypes,
      };

      return { ...state, ...composedPayload };
    }
    case CREATE_GROUPS_PROGRAM: {
      const responseData = action.payload.data.data;

      const updatedProgramsData = [responseData, ...state.data];

      // Update Programs /w Service Types
      const composedProgramsData = serviceTypes.composeProgramServiceTypes(
        updatedProgramsData,
        globalState.session.globals.service_types,
      );

      return { ...state, data: _.sortBy(composedProgramsData, _.property('attributes.name')) };
    }
    case UPDATE_GROUPS_PROGRAM: {
      const responseData = action.payload.data.data;

      // Map and replace old program
      const updatedProgramsData = _.map(state.data, (program) => {
        if (program.id === responseData.id) {
          return responseData;
        }

        return program;
      });

      // Update Programs /w Service Types
      const composedProgramsData = serviceTypes.composeProgramServiceTypes(
        updatedProgramsData,
        globalState.session.globals.service_types,
      );

      return { ...state, data: _.sortBy(composedProgramsData, _.property('attributes.name')) };
    }
    default:
      return state;
  }
}
