import { browserHistory } from 'common/utils/browserHistory';
import {
  CONFIRM_PATH,
} from '../constants';
import encodeContactData from './encodeContactData';

export default function goToConfirmStep({ context, contactData = {}, arId }) {
  const dataParam = `contact=${encodeContactData(contactData)}`;
  const arParam = arId ? `&arId=${arId}` : '';
  const path = context === 'contacts-link' ? 'confirm' : CONFIRM_PATH;
  browserHistory.push(`/${context}/${path}?${dataParam}${arParam}`);
}
