// Library Imports
import React from 'react';
import PropTypes from 'prop-types';

// Util Imports
import isSendingGroup from 'src/components/Referrals/utils/isSendingGroup';

// Component Imports
import EditModal from 'common/modal/EditModal';
import ReferralProgramForm from './ReferralProgramForm';

const ReferralProgramDialog = ({
  referral,
  contact,
  groupId,
  hideEditBtn = false,
}) => {
  if (hideEditBtn || isSendingGroup(referral, groupId)) {
    return null;
  }

  const editSection = (
    <div className="referral-program-dialog__edit ml-two">
      <EditModal
        header="Edit Program"
        id="edit-program-modal"
        size="large"
        formName="editProgramForm"
        labelText="Edit"
        buttonId="edit-program-btn"
      >
        <ReferralProgramForm
          referral={referral}
          contact={contact}
          groupId={groupId}
        />
      </EditModal>
    </div>
  );

  return (
    <div className="referral-program-dialog">
      {editSection}
    </div>
  );
};

ReferralProgramDialog.propTypes = {
  hideEditBtn: PropTypes.bool,
  referral: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
};

ReferralProgramDialog.defaultProps = {
  hideEditBtn: false,
};

export default ReferralProgramDialog;
