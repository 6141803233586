import _ from 'lodash';
import moment from 'moment';
import { feeSchedulePrograms as fspUtils } from '@unite-us/client-utils';
import {
  SET_REFERRAL_IS_SAVING,
  UNSET_REFERRAL_IS_SAVING,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import uploadAndAttachDocumentsToReferral from
  'src/actions/Document/Contact/Referral/Group/uploadAndAttachDocumentsToReferral';
import fetchInsurances from 'src/api/core/Insurances/fetchInsurances';
import { pays5590RefactorInsuranceFilters } from 'src/common/utils/FeatureFlags/flags';
import { coreApi } from 'src/api/config';

const iso8601Date = (date) => moment.unix(date).toISOString();

const serviceAuthorizationStrategy = (personId, referral, isRefactorInsuranceFiltersEnabled) => {
  const { authorizationRequest } = referral;

  const feeScheduleProgramId = referral.selected
    .find((r) => r.program?.relationships?.fee_schedule_program)
    ?.program?.relationships?.fee_schedule_program?.data?.id;

  const needsAuthorization = _.values(authorizationRequest).some((e) => e);

  // Cache fetched values so they can be used by the service authorization
  const cache = {};

  const fetchFeeScheduleInfo = async () => {
    // Fetch the fee schedule program
    const fspResponse = await coreApi.findRecord(
      'fee_schedule_program',
      feeScheduleProgramId,
    );
    const fsp = _.get(fspResponse, 'data.data');
    if (!fsp) {
      throw new Error('Contracted Program required');
    }

    const feeScheduleId = _.get(fsp, 'fee_schedule.id');

    if (!fsp.authorization_required) {
      throw new Error('Contracted Program does not need authorization');
    }

    const requestStartDate = authorizationRequest?.service_start &&
      moment.unix(authorizationRequest.service_start).toISOString();
    const requestEndDate = authorizationRequest?.service_end &&
      moment.unix(authorizationRequest.service_end).toISOString();

    const insurances = await fetchInsurances({
      personId,
      feeScheduleIds: feeScheduleId,
      requestStartDate,
      requestEndDate,
      isRefactorInsuranceFiltersEnabled,
      sort: '-enrolled_at',
    });

    if (!insurances?.length) {
      throw new Error('No insurance for program found');
    }

    cache.feeScheduleProgram = fsp;
    cache.insurance = insurances[0];
  };

  const createServiceAuthorization = async (caseId, { draft, requester_id }) => {
    const {
      service_start, service_end, amount_requested,
    } = authorizationRequest;

    const { feeScheduleProgram: { payment_type }, insurance } = cache;

    const result = await coreApi.createRecord('service_authorization', {
      state: draft ? 'draft' : 'requested',
      requested_starts_at: iso8601Date(service_start),
      requested_ends_at: iso8601Date(service_end || service_start),
      ...(fspUtils.isCostPaymentType(payment_type) ?
          { requested_cents: amount_requested } :
          { requested_unit_amount: amount_requested }
      ),
      case: caseId,
      fee_schedule_program: feeScheduleProgramId,
      person: personId,
      requester: requester_id,
      insurance: insurance.id,
    });

    if (result.status === 201) {
      console.log(`[PAYS-5178] Created Service Authorization: ${result.data.data.id}`);
    } else {
      console.error(`[PAYS-5178] Error creating service authorization: ${JSON.stringify(result)}`);
    }

    return result;
  };

  return needsAuthorization ?
    {
      fetchFeeScheduleInfo,
      createServiceAuthorization,
      needsAuthorization,
    } : {
      fetchFeeScheduleInfo: _.noop,
      createServiceAuthorization: _.noop,
      needsAuthorization,
    };
};

const checkProgramIsNotReceivingReferrals = async (referrals) => {
  const programIds = _.uniq(referrals.flatMap((r) => r.selected?.flatMap((s) => s.program?.id || [])));

  if (programIds.length === 0) {
    return false;
  }

  try {
    const programResponses = await Promise.all(
      programIds.map((id) => coreApi.findRecord('program', id)),
    );

    const programsNotReceivingReferrals = programResponses.some((r) => r?.data?.data?.receiving_referrals === false);

    return programsNotReceivingReferrals;
  } catch (error) {
    Notifier.handleErrors(error);
    return true;
  }
};

const submitOneReferral = async (
  groupId,
  contactId,
  referral,
  referralIndex,
  isRefactorInsuranceFiltersEnabled,
  options = {},
) => {
  try {
    let caseResponse;

    const assistanceRequestId = _.get(options, 'assistance_request_id', null);

    const serviceAuthStrategy = serviceAuthorizationStrategy(contactId, referral, isRefactorInsuranceFiltersEnabled);
    await serviceAuthStrategy.fetchFeeScheduleInfo();

    if (assistanceRequestId && referralIndex === 0) {
      caseResponse = await coreApi.findRecord('case', assistanceRequestId);

      if (referral.service_type.id &&
        (_.get(caseResponse, 'service.id') !== referral.service_type.id)) {
        caseResponse = await coreApi.updateRecord('case', assistanceRequestId, {
          description: referral.notes,
          service: referral.service_type.id,
        });
      }
    } else {
      const screeningId = _.get(options, 'screening_id', null);
      caseResponse = await coreApi.createRecord('case', {
        description: referral.notes,
        state: 'draft',
        provider: groupId,
        network: referral.referred_by_network.id,
        person: contactId,
        service: referral.service_type.id,
        ...(screeningId ? { originating_form_submission: screeningId } : {}),
      });

      if (caseResponse.status === 201) {
        console.log(`[PAYS-5178] Created Case: ${caseResponse.data.data.id}`);
      } else {
        console.error(`[PAYS-5178] Error creating case: ${JSON.stringify(caseResponse)}`);
      }
    }
    const createdCaseOrExistingAR = caseResponse.data.data;

    await serviceAuthStrategy.createServiceAuthorization(createdCaseOrExistingAR.id, options);

    const { uploadedDocuments, attachableDocuments } = referral;
    const filteredDocuments = _.filter(attachableDocuments, (doc) => doc.attached === true);
    const attachedDocuments = _.map(filteredDocuments, (ad) => ({
      title: _.get(ad, 'document.title'),
      id: _.get(ad, 'document.id'),
    }));

    const uploadAndAttachResults = await uploadAndAttachDocumentsToReferral({
      caseId: createdCaseOrExistingAR.id,
      attachedDocuments,
      uploadedDocuments,
    });

    (uploadAndAttachResults || []).slice(1).forEach((result) => {
      if (result.status === 201) {
        console.log(`[PAYS-5178] Uploaded document: ${result.data.data.id}`);
      } else {
        console.error(`[PAYS-5178] Error uploading document: ${JSON.stringify(result)}`);
      }
    });

    let referralState;
    if (options.draft) {
      referralState = 'draft';
    } else if (serviceAuthStrategy.needsAuthorization) {
      referralState = 'pending_authorization';
    } else {
      referralState = 'sent';
    }

    const isGroupedReferrals = referral.selected.length > 1;

    const referralResponse = await Promise.all(
      referral.selected.map((r) => coreApi.createRecord('referral', {
        state: referralState,
        case: createdCaseOrExistingAR.id,
        sending_provider: createdCaseOrExistingAR.provider.id,
        sending_network: referral.referred_by_network.id,
        receiving_network: referral.referred_by_network.id,
        receiving_provider: r.group.id,
        ...(r.program?.id ? { receiving_program: r.program.id } : {}),
        is_grouped: isGroupedReferrals,
      })),
    );
    await Promise.all(
      referralResponse.map(
        (r) => coreApi.populateRelationship('case', 'case', r.data.data),
      ),
    );

    referralResponse.forEach((response) => {
      if (response.status === 201) {
        console.log(`[PAYS-5178] Created Referral: ${response.data.data.id}`);
      } else {
        console.error(`[PAYS-5178] Error creating referral: ${JSON.stringify(response)}`);
      }
    });

    return referralResponse;
  } catch (error) {
    Notifier.handleErrors(error);
    return false;
  }
};

const submitReferral = (groupId, contactId, referrals, options) => (
  async (dispatch, getState) => {
    try {
      const isProgramNotReceivingReferrals = await checkProgramIsNotReceivingReferrals(referrals);
      if (isProgramNotReceivingReferrals) {
        Notifier.dispatch('error', 'One or more programs are not receiving referrals');
        return false;
      }

      dispatch({ type: SET_REFERRAL_IS_SAVING });

      const state = getState();
      const isRefactorInsuranceFiltersEnabled = pays5590RefactorInsuranceFilters(state);

      const newReferrals = await Promise.all(
        referrals.map(
          (r, idx) => submitOneReferral(groupId, contactId, r, idx, isRefactorInsuranceFiltersEnabled, options),
        ),
      );

      let allReferrals = [];
      newReferrals.filter((r) => r).forEach((r) => { allReferrals = [...allReferrals, ...r]; });

      if (allReferrals && allReferrals.length >= 1) {
        Notifier.dispatch(
          201,
          `Referral${allReferrals.length > 1 ? 's' : ''} Successfully Created`,
        );
      }

      dispatch({ type: UNSET_REFERRAL_IS_SAVING });

      return allReferrals;
    } catch (error) {
      Notifier.handleErrors(error);
      return false;
    }
  }
);

export default submitReferral;
