import Notifier from 'common/helpers/Notifier';

import {
  FETCH_FEE_SCHEDULES_STARTED,
  FETCH_FEE_SCHEDULES_SUCCESS,
  FETCH_FEE_SCHEDULES_ERROR,
} from 'actions';
import { apiV1 } from 'src/api/config';
import { isHttpError } from 'common/utils/Error';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { generateFeeScheduleOptionsRollbar } from 'src/components/Browse/utils/display';

export default function fetchNetworkFeeSchedules({ networkId, employeeId, contactId }) {
  return async (dispatch) => {
    dispatch({ type: FETCH_FEE_SCHEDULES_STARTED });
    const payload = await apiV1.query('fee_schedule', { network: networkId, person: contactId, state: 'active' });

    if (!payload || !isHttpSuccess(payload.status)) {
      dispatch({ type: FETCH_FEE_SCHEDULES_ERROR });

      if (isHttpError(payload)) {
        if (window.Rollbar) {
          generateFeeScheduleOptionsRollbar({
            response: payload,
            employeeId,
            networkId,
            contactId,
          });
        }

        Notifier.dispatch(
          'error',
          'Failed to load fee schedules, please contact Unite Us using the chat bubble in the lower right corner',
        );
      } else {
        Notifier.handleErrors(payload);
      }

      return payload;
    }

    dispatch({
      type: FETCH_FEE_SCHEDULES_SUCCESS,
      payload: payload.data.data,
    });

    return payload;
  };
}
