import axios from 'axios';
import Notifier from 'common/helpers/Notifier';
import { CORE_API } from 'src/config/env/env.config';
import getCoreError from 'src/api/utils/getCoreError';
import { isHttpSuccess } from 'src/api/utils/httpStatus';

const onSuccess = (data) => data;

const onError = (dispatch, error) => {
  Notifier.handleErrors(getCoreError(error));
  return error;
};

export default function fetchNetworksByProviderIds({
  ids,
}) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/networks?filter[providers]=${ids.join(',')}`, { baseURL: CORE_API });
      if (!response || !isHttpSuccess(response.status)) {
        return onError(dispatch, response);
      }
      const { data: { data } } = response;

      const networksData = data.map((network) => ({
        id: network.id,
        name: network.attributes.name,
        _meta: { _type: network.type },
        coordination_centers: network.relationships.coordination_centers.data,
      }));

      return onSuccess(networksData);
    } catch (error) {
      return onError(dispatch, error);
    }
  };
}
