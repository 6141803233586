import axios from 'axios';
import Notifier from 'common/helpers/Notifier';

export default function fetchZendeskURL() {
  return () => {
    const request = axios.get('/users/me/support/zendesk');

    function onSuccess(response) {
      return response;
    }

    function onError(error) {
      Notifier.handleErrors(error);

      return error;
    }

    return request.then(onSuccess, onError);
  };
}
