import { RECALL_MODAL_DIALOG } from '../../constants';
import canRecall from './canRecall';

function recall({
  groupId,
  openModal,
  referral,
  user,
}) {
  if (
    !canRecall({
      user,
      referral,
      groupId,
    })
  ) {
    return null;
  }

  return {
    label: 'Recall',
    value: 'recall',
    className: 'take-action-recall',
    action: () => openModal(RECALL_MODAL_DIALOG),
  };
}

export default recall;
