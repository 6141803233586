import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button, Icon } from '@unite-us/ui';
import {
  ExpandableContainer,
  serviceTypes as serviceTypeUtils,
} from '@unite-us/client-utils';
import GroupDetailsHeaderTitle from 'src/components/Groups/GroupDetails/components/GroupDetailsHeaderTitle';
import GroupDetailsSendMessage from 'src/components/Groups/GroupDetails/components/GroupDetailsSendMessage';
import 'src/components/Groups/GroupDetails/stylesheets/group-details-header.scss';
import './NetworkGroupDetailsHeader.scss';

class NetworkGroupDetailsHeader extends Component {
  constructor(props) {
    super(props);

    this.onRemoveProvider = this.onRemoveProvider.bind(this);
    this.onAddProvider = this.onAddProvider.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.sendProviderOpen && this.props.sendProviderOpen !== prevProps.sendProviderOpen) {
      this.sendProviderElement.focus();
    }
  }

  onRemoveProvider() {
    const { group } = this.props;
    this.props.onRemoveProvider(group);
  }

  onAddProvider() {
    const { group } = this.props;
    this.props.onAddProvider(group);
  }

  render() {
    const {
      group,
      providerIsSelected,
      providerIsSelectable,
      toggleSendProvider,
      hideSendProviderOption,
      network,
      providerServices,
    } = this.props;

    const svcTypeBanner = serviceTypeUtils.coreServicesBanner(providerServices);

    return (
      <div className="group-details-header network-group-details-header">
        <div className="group-details-header__wrapper">
          <GroupDetailsHeaderTitle
            group={group}
            serviceTypeName={svcTypeBanner}
          />

          <div className="network-group-details-header__provider group-details-header__provider">
            <Button
              className="share-button network-group-details-header__button network-group-details-header__button--share"
              id="network-groups-drawer-share-btn"
              onClick={toggleSendProvider}
              iconLeft={<Icon icon="IconMailForward" color="#4571BA" />}
              link
              upperCase
              label="Share"
            />
            {
              providerIsSelectable && providerIsSelected && (
                <Button
                  className="network-group-details-header__button network-group-details-header__button--remove"
                  iconRight={<Icon icon="IconMinusCircle" />}
                  id="network-group-details-header__remove-btn"
                  label="Remove"
                  onClick={this.onRemoveProvider}
                />
              )
            }
            {
              providerIsSelectable && !providerIsSelected && (
                <Button
                  iconLeft={<Icon icon="IconPlusCircle" color="white" />}
                  id="network-group-details-header__add-btn"
                  label="Add"
                  onClick={this.onAddProvider}
                  primary
                />
              )
            }
          </div>
        </div>
        {
          !hideSendProviderOption && (
            <div
              className="group-details-header__send-provider"
              tabIndex="-1"
              ref={(div) => { this.sendProviderElement = div; }}
            >
              <ExpandableContainer
                collapsedHeight="0px"
                id="group-details-send-provider-expandable"
                isOpen={this.props.sendProviderOpen}
                onToggle={this.props.toggleSendProvider}
                hideToggle
                showGradient={false}
                style={{ visibility: (this.props.sendProviderOpen ? 'visible' : 'hidden') }}
              >
                <GroupDetailsSendMessage
                  group={group}
                  onToggle={this.props.toggleSendProvider}
                  network={network}
                  sendProviderOpen={this.props.sendProviderOpen}
                />
              </ExpandableContainer>
            </div>
          )
        }
      </div>
    );
  }
}

NetworkGroupDetailsHeader.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    service_types: PropTypes.array,
  }),
  network: PropTypes.object,
  onAddProvider: PropTypes.func,
  onRemoveProvider: PropTypes.func,
  providerIsSelectable: PropTypes.bool,
  providerIsSelected: PropTypes.bool,
  providerServices: PropTypes.array,
  toggleSendProvider: PropTypes.func.isRequired,
  hideSendProviderOption: PropTypes.bool,
  sendProviderOpen: PropTypes.bool,
};

NetworkGroupDetailsHeader.defaultProps = {
  group: {},
  hideSendProviderOption: false,
  network: {},
  onAddProvider: noop,
  onRemoveProvider: noop,
  providerIsSelectable: false,
  providerIsSelected: false,
  providerServices: [],
  sendProviderOpen: false,
};

export default NetworkGroupDetailsHeader;
