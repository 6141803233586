import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { getGender } from 'src/common/utils/gender';
import DemographicInfoDisplay from './DemographicInfoDisplay';

function ContactDetailReviewDemographic(props) {
  const people = _.get(props.enums, 'people', {});
  const {
    fields: {
      gender, marital_status, race, ethnicity,
    },
    limitedGenderOptions,
  } = props;
  const modifiedGender = { ...gender, value: getGender({ gender: gender.value, flag: limitedGenderOptions }) };

  if (_.isEmpty(people)) { return null; }

  return (
    <div className="contact-details-review-demographic confirmed-details__row">
      <DemographicInfoDisplay fieldType={modifiedGender} label="Gender" enumsPeopleArray={people.genders} />
      <DemographicInfoDisplay
        fieldType={marital_status}
        label="Marital Status"
        enumsPeopleArray={people.marital_status}
      />
      <DemographicInfoDisplay fieldType={race} label="Race" enumsPeopleArray={people.race} />
      <DemographicInfoDisplay fieldType={ethnicity} label="Ethnicity" enumsPeopleArray={people.ethnicity} />
    </div>
  );
}

ContactDetailReviewDemographic.propTypes = {
  enums: PropTypes.object,
  fields: PropTypes.object,
  limitedGenderOptions: PropTypes.bool,
};

export default ContactDetailReviewDemographic;
