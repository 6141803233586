import isUserActive from './isUserActive';

// converts Core employee response to app's structure
const convertCoreUser = (employee) => ({
  updated_at: undefined,
  programs: [],
  roles: [],
  employee,
  user: {
    active_in_current_group: isUserActive(employee),
    ...employee,
    id: employee.user.id,
    full_name: `${employee.first_name} ${employee.last_name}`,
    last_name_first_name: `${employee.last_name} ${employee.first_name}`,
  },
});

export default convertCoreUser;
