import PropTypes from 'prop-types';
import React from 'react';
import DetailDefinitionList from 'common/display/SingleItemDetails/dashboard/DetailDefinitionList';
import addLineBreaks from 'common/utils/addLineBreaks';

function AssistanceRequestDescription(props) {
  const { description } = props;

  return (
    <div className="assistance-request-summary mb-5">
      <DetailDefinitionList
        id="assistance-request-description-list"
        title="Description"
        value={addLineBreaks(description)}
        styles={{ icon: {} }}
      />
    </div>
  );
}

AssistanceRequestDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

AssistanceRequestDescription.defaultProps = {
  description: '',
};

export default AssistanceRequestDescription;
