import React from 'react';
import PropTypes from 'prop-types';
import NoNotes from 'src/components/Notes/NoNotes';
import Notes from 'src/components/Notes/Notes';
import NotesHeader from 'src/components/Notes/NotesHeader';
import Pagination from 'src/common/Pagination/Pagination';
import { isEmpty, first } from 'lodash';

export const ReferralNotes = ({ referrals, providerId }) => {
  const acceptedReferrals = referrals.filter((referral) => referral.state === 'accepted')
                                     .sort((referral1, referral2) => referral2.created_at - referral1.created_at);
  const emptyAcceptedReferrals = isEmpty(acceptedReferrals);
  const acceptedReferral = first(acceptedReferrals);

    return (
      <div>
        {
          emptyAcceptedReferrals ? (
            <div className="mt-2">
              <NotesHeader
                editable={false}
                notesTitle={'Referral Notes'}
              />
              <NoNotes />
              <div className="mb-16">
                <Pagination
                  pageNumber={0}
                  pageSize={0}
                  totalItemCount={0}
                  totalPageCount={0}
                  setPageSize={0}
                  setPageNumber={0}
                />
              </div>
            </div>
          ) : (
            <div className="mt-10">
              <Notes
                editable={false}
                providerId={providerId}
                subject={acceptedReferral.id}
                subjectType="referral"
                notesTitle="Referral Notes"
                notesContainerId="referral-notes-container"
              />
            </div>
          )
        }
      </div>
    );
};

ReferralNotes.propTypes = {
  referrals: PropTypes.array.isRequired,
  providerId: PropTypes.string.isRequired,
};

export default ReferralNotes;
