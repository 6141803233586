import _ from 'lodash';

export default function getPermittedNetworkOptions(referralScopes, networkId) {
  const filteredScopes = _.filter(referralScopes, (scope) => scope.network.id === networkId);
  const mappedScopes = _.map(filteredScopes, (scope) => ({
    label: scope.permitted_network.name,
    value: scope.permitted_network.id,
    initial: scope.permitted_network.id === networkId,
  }));

  return _.uniqBy(mappedScopes, 'value');
}
