import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './ProviderBadge.scss';

const ProviderBadge = ({ className, providerName }) => (
  <div className={classnames('provider-badge', className)}>
    {providerName}
  </div>
);

ProviderBadge.propTypes = {
  className: PropTypes.string,
  providerName: PropTypes.string.isRequired,
};

ProviderBadge.defaultProps = {
  className: '',
};

export default ProviderBadge;
