import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, isNumber, isNaN, compact } from 'lodash';
import pluralize from 'pluralize';
import { feeSchedulePrograms as fspUtils } from '@unite-us/client-utils';
import { validations } from '@unite-us/app-components';
import moment from 'moment';
import { DollarAmount } from 'common/display/Money';
import { Card, InputField, DurationField } from '@unite-us/ui';
import formatSpend from 'common/utils/Money/formatSpend';
import CurrencyField from 'common/form/CurrencyField';
import { useFind } from 'src/api/APIHooks';
import { validateUnitAmount } from 'src/common/form/Interactions/utils';
import CapsInfo from 'components/Referrals/components/CapsInfo';
import { useFeeScheduleProgram } from 'src/common/hooks';
import { UTILIZATION_ERROR, DATES_ERROR } from 'src/common/messageConstants';
import { getCapInformation } from 'components/ServiceAuthorization/utils';
import ErrorMessage from 'common/utils/ErrorMessage';
import usePreventWheel from 'common/hooks/usePreventWheel';
import PreviousAuthorizationRequestsDetails from './PreviousAuthorizationRequestsDetails';

const AuthorizationRequestForm = (props) => {
  const {
    contact,
    fields,
    registerField,
    isRefactorInsuranceFiltersEnabled,
    setDisableNextButton,
    feeScheduleProgramId,
  } = props;

  const feeScheduleProgram = useFeeScheduleProgram(feeScheduleProgramId);
  const isCostBased = !isEmpty(feeScheduleProgram) && fspUtils.isCostPaymentType(feeScheduleProgram.payment_type);
  const amountRequestedFieldName = get(fields, 'authorizationRequest.amount_requested.name', '');
  const validationRef = useRef(amountRequestedFieldName);
  useEffect(() => {
    validationRef.current = amountRequestedFieldName;
  }, [amountRequestedFieldName]);
  const isValidationValid = () => validationRef.current === amountRequestedFieldName;

  const hasDatesSelected = !!(
    fields.authorizationRequest.service_start?.value &&
    fields.authorizationRequest.service_end?.value
  );
  const invalidDatesSelected = (
    isNaN(Number(fields.authorizationRequest.service_start?.value)) ||
    isNaN(Number(fields.authorizationRequest.service_end?.value))
  );

  const requestStartDate = fields?.authorizationRequest?.service_start?.value &&
    moment.unix(fields.authorizationRequest.service_start.value).toISOString();
  const requestEndDate = fields?.authorizationRequest?.service_end?.value &&
    moment.unix(fields.authorizationRequest.service_end.value).toISOString();

  const feeScheduleId = get(feeScheduleProgram, 'fee_schedule.id');

  let enrolledBefore = requestStartDate;
  let expiredAfter = requestEndDate;

  if (isRefactorInsuranceFiltersEnabled) {
    enrolledBefore = requestStartDate && moment(requestStartDate).add(1, 'day').toISOString();
    expiredAfter = requestEndDate && moment(requestEndDate).subtract(1, 'day').toISOString();
  }

  const insuranceFilters = {
    person: contact.id,
    state: 'active',
    'plan.fee_schedules': feeScheduleId,
    ...(
      hasDatesSelected ? {
        enrolled_before: enrolledBefore,
        expired_after: expiredAfter,
      } : {}
    ),
  };

  const { data: insurancesData, isLoading: isInsuranceLoading } = useFind(
    'insurance',
    insuranceFilters,
    {
      queryConfig: {
        enabled: hasDatesSelected && !invalidDatesSelected && !!feeScheduleId,
        placeholderData: undefined,
      },
    },
  );

  const insurances = get(insurancesData, 'data.data', []);

  const requestedAmount = fields?.authorizationRequest?.amount_requested?.value;
  const isOONCase = fields.isOONCase?.value || false;

  const nonValidInsuranceDateAuthorizationDates = hasDatesSelected && !insurances?.length && !isInsuranceLoading;

  const filters = {
    fee_schedule_program: feeScheduleProgramId,
    person: contact.id,
    ...(
      hasDatesSelected ?
        {
          requested_starts_at: requestStartDate,
          requested_ends_at: requestEndDate,
        } : {}
    ),
  };

  const {
    isFetching,
    isLoading,
    data: authorizedSpendsData,
  } = useFind(
    'authorized_spend',
    filters,
    {
      queryConfig: {
        enabled: hasDatesSelected && !invalidDatesSelected,
        placeholderData: undefined,
      },
    },
  );

  const capInfo = hasDatesSelected && getCapInformation(feeScheduleProgram, get(authorizedSpendsData, 'data.meta'));
  const isLoaded = !isFetching && !isLoading;

  const authorizationSpends = get(authorizedSpendsData, 'data.data', []);
  const totalApprovedSpend = get(authorizedSpendsData, 'data.meta.total_approved_spend', 0);
  const totalRequestedSpend = get(authorizedSpendsData, 'data.meta.total_requested_spend', 0);
  const authorizationSpendsErrors = get(authorizedSpendsData, 'data.meta.errors', []);
  const availableCap = get(authorizedSpendsData, 'data.meta.available_cap', 0);

  const showAuthorization = !isEmpty(feeScheduleProgram) && !isOONCase && feeScheduleProgram.authorization_required;
  const showUnits = !isCostBased && feeScheduleProgram?.unit;
  const showRate = showUnits && feeScheduleProgram?.unit_rate > 0;

  const isAmountExceeded = !!requestedAmount && isNumber(requestedAmount) &&
    capInfo.hasCap && (requestedAmount > availableCap);

  const showAmountExceededError = hasDatesSelected && isAmountExceeded;
  // PAYS-5235 TODO use error types from backend
  const showClientUtilizationError = authorizationSpendsErrors.some((e) => e.title === UTILIZATION_ERROR);
  const showPassthroughDatesError = hasDatesSelected &&
    authorizationSpendsErrors.some((e) => e.title === DATES_ERROR);

  useEffect(() => {
    setDisableNextButton(
      invalidDatesSelected ||
      showAmountExceededError ||
      nonValidInsuranceDateAuthorizationDates ||
      showPassthroughDatesError ||
      showClientUtilizationError,
    );
    return () => { setDisableNextButton(false); };
  }, [
    invalidDatesSelected,
    showAmountExceededError,
    nonValidInsuranceDateAuthorizationDates,
    showPassthroughDatesError,
    showClientUtilizationError,
  ]);

  const formattedTotalApprovedSpend = isNumber(totalApprovedSpend) &&
    formatSpend(totalApprovedSpend, feeScheduleProgram?.unit, isCostBased);
  const formattedTotalRequestedSpend = isNumber(totalRequestedSpend) &&
    formatSpend(totalRequestedSpend, feeScheduleProgram?.unit, isCostBased);
  const formattedAmountAvailable = capInfo.hasCap && capInfo.formattedAvailableAmount;

  usePreventWheel('authorization-request-amount-requested');

  return (
    showAuthorization && (
      <Card
        id="referral-authorization-form"
        className="flex flex-col justify-start my-6 pb-6 rounded-lg"
      >
        <h4 className="text-base text-dark-grey border-b border-solid border-dark-fill-blue pt-4 pl-5 pb-2">
          Authorization Request
        </h4>

        <div className="grid grid-cols-2 gap-x-6 gap-y-3 mt-5 mb-2 mx-5">
          <div className="font-heavy-font text-dark-grey">
            Contracted Program:
          </div>
          <div className="col-span-2">
            {feeScheduleProgram.name}
          </div>
          <div className="font-heavy-font text-dark-grey">
            Payment Approach:
          </div>
          <div className="col-span-2">
            {fspUtils.PAYMENT_TYPES[feeScheduleProgram.payment_type] || 'N/A'}
          </div>
          {
            showUnits && (
              <>
                <div className="font-heavy-font text-dark-grey">
                  Unit:
                </div>
                <div className="col-span-2 capitalize">
                  {feeScheduleProgram.unit}
                </div>
              </>
            )
          }
          {
            showRate && (
              <>
                <div className="font-heavy-font text-dark-grey">
                  Rate:
                </div>
                <div className="col-span-2">
                  <DollarAmount value={feeScheduleProgram.unit_rate} convertCents />
                  {' per '}{feeScheduleProgram.unit}
                </div>
              </>
            )
          }
          <div className="font-heavy-font text-dark-grey">
            Program Cap:
          </div>
          {feeScheduleProgram.cap_information ? (
            <>
              {/* eslint-disable-next-line react/no-danger, max-len */}
              <div className="col-span-2" dangerouslySetInnerHTML={{ __html: feeScheduleProgram.cap_information }} />
            </>
          ) : (
            <div className="col-span-2">No cap</div>
          )}

          <hr className="col-span-3 my-3 p-0" />

          {showClientUtilizationError ? (
            <div className="col-span-3 px-0 flex space-x-2 -mt-0.5 mb-0">
              <ErrorMessage>
                This passthrough referral cannot be sent because the client has already used this service.
                Please select a different program.
              </ErrorMessage>
            </div>
          ) : (
            <>
              <div className="h-14">
                {isCostBased ? (
                  <CurrencyField
                    className={showAmountExceededError ? 'ui-input-field--has-error' : ''}
                    field={fields.authorizationRequest.amount_requested}
                    id="authorization-request-amount-requested"
                    label="Amount Requested"
                    ref={registerField}
                    required
                    validations={[
                      { func: (...args) => (isValidationValid() ? validations.isRequired(...args) : null) },
                      {
                        func: validations.isGreaterThan,
                        args: 0,
                      },
                    ]}
                  />
                ) : (
                  <InputField
                    className={showAmountExceededError ? 'ui-input-field--has-error' : ''}
                    id="authorization-request-amount-requested"
                    label="Amount Requested"
                    field={fields.authorizationRequest.amount_requested}
                    type="number"
                    ref={registerField}
                    validations={
                      (...args) => (isValidationValid() ? validateUnitAmount(...args) : null)
                    }
                    required
                  />
                )}
              </div>
              <div className="col-span-2 space-y-2">
                <div className="font-heavy-font text-dark-grey">Unit</div>
                <div className="capitalize">
                  {isCostBased ? 'Dollars' :
                    pluralize(
                      feeScheduleProgram.unit || '',
                      fields.authorizationRequest?.amount_requested?.value || 0,
                    )}
                </div>
              </div>
              <div className="p-0 m-0 h-14">
                <DurationField
                  id="authorization-request-service-dates"
                  label="Service Delivery Date(s)"
                  startField={fields.authorizationRequest.service_start}
                  endField={fields.authorizationRequest.service_end}
                  ref={registerField}
                  validations={compact([
                    { func: (...args) => (isValidationValid() ? validations.isRequired(...args) : null) },
                  ])}
                  required
                  // This makes service end a required field for validations
                  field={fields.authorizationRequest.service_end}
                />
              </div>

              {hasDatesSelected && (
                <div className="col-span-full p-0">
                  <hr className="my-3 p-0" />
                  <CapsInfo
                    totalApprovedSpend={formattedTotalApprovedSpend}
                    totalRequestedSpend={formattedTotalRequestedSpend}
                    capInfo={capInfo}
                    amountAvailable={formattedAmountAvailable}
                    showMoreInformationButton={(
                      <PreviousAuthorizationRequestsDetails
                        authorizationSpends={authorizationSpends}
                        totalApprovedSpend={formattedTotalApprovedSpend}
                        totalRequestedSpend={formattedTotalRequestedSpend}
                        unit={feeScheduleProgram.unit}
                        amountAvailable={formattedAmountAvailable}
                        capInfo={capInfo}
                        isCostBased={isCostBased}
                      />
                    )}
                    isLoaded={isLoaded}
                  />
                </div>
              )}

              {showAmountExceededError && (
                <div className="col-span-3 px-0 flex space-x-2 -mt-0.5 mb-0">
                  <ErrorMessage>
                    The amount requested is more than the amount available for authorization.
                    Please adjust the amount requested, or search for another program
                    to meet the client&apos;s needs.
                  </ErrorMessage>
                </div>
              )}
              {showPassthroughDatesError && (
                <div className="col-span-3 px-0 flex space-x-2 -mt-0.5 mb-0">
                  <ErrorMessage>
                    Clients can only receive services from this program for 30 days or until their social coverage ends,
                    whichever comes first.
                  </ErrorMessage>
                </div>
              )}
              {nonValidInsuranceDateAuthorizationDates && (
                <div className="col-span-3 px-0 flex space-x-2 -mt-0.5 mb-0">
                  <ErrorMessage>
                    The service delivery dates requested fall outside the client&apos;s
                    enrollment dates for all enrolled plans. Please edit the service delivery dates.
                  </ErrorMessage>
                </div>
              )}
            </>
          )}
        </div>
      </Card>
    )
  );
};

AuthorizationRequestForm.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  feeScheduleProgramId: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  registerField: PropTypes.func.isRequired,
  isRefactorInsuranceFiltersEnabled: PropTypes.bool.isRequired,
  setDisableNextButton: PropTypes.func,
};

AuthorizationRequestForm.defaultProps = {
  setDisableNextButton: () => {},
};

export default AuthorizationRequestForm;
