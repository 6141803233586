import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';

const LegendShowDetail = ({
  data,
  detailText,
  detailWidth,
  disabled,
  hasChildren,
  index,
  onDetailClick,
}) => {
  const onClick = (e) => {
    e.stopPropagation();
    const response = { data, index };
    onDetailClick(response);
  };

  return (
    <td
      className="legend-show-detail"
      onClick={onClick}
      role="button"
      style={{ width: hasChildren ? detailWidth : 'Opx' }}
    >
      {
        hasChildren && !disabled && isFunction(onDetailClick) &&
          <span className="legend-show-detail__child">{detailText}</span>
      }
    </td>
  );
};

LegendShowDetail.propTypes = {
  data: PropTypes.object.isRequired,
  detailText: PropTypes.string.isRequired,
  detailWidth: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  hasChildren: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onDetailClick: PropTypes.func,
};

export default LegendShowDetail;
