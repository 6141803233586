import _ from 'lodash';

export default function getParentServiceType(serviceTypeId, allServiceTypes) {
  const parent = _.find(allServiceTypes, (st) => {
    if (_.has(st, 'children') && !_.isEmpty(st.children)) {
      const result = _.find(st.children, (c) => c.id === serviceTypeId);
      return result !== undefined;
    }
    return false;
  });

  return parent;
}
