import { isEmpty } from 'lodash';

function ccNetworkHasP2P(currentNetwork) {
  if (isEmpty(currentNetwork)) {
    return false;
  }

  return currentNetwork.network_type === 'coordinated_with_p2p';
}

export default ccNetworkHasP2P;
