import PropTypes from 'prop-types';
import React from 'react';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Button,
} from '@unite-us/ui';
import { epochToDate } from 'common/utils/utils';
import Address from 'common/display/Address';
import PhoneNumber from 'common/display/PhoneNumberDisplay';
import SimpleList from 'common/display/SimpleList';

const ContactCard = ({
  id,
  contact,
  onSelectContact,
}) => (
  <li className="contact-card">
    <BaseCard>
      <BaseCardHeader title={contact.full_name} />
      <BaseCardBody withPadding>
        <dl>
          <dt>Date of Birth</dt>
          <dd>{epochToDate(contact.date_of_birth)}</dd>
        </dl>
        <dl>
          <dt>Phone</dt>
          <dd>
            <SimpleList list={contact.phone_numbers} ListElement={PhoneNumber} />
          </dd>
        </dl>
        <dl>
          <dt>Address</dt>
          <dd>
            <SimpleList list={contact.addresses} ListElement={Address} shortDisplay />
          </dd>
        </dl>
        <Button
          id={`${id}-use-btn`}
          className="contact-card__confirmation"
          label="Use This Record"
          onClick={onSelectContact(contact)}
          primary
        />
      </BaseCardBody>
    </BaseCard>
  </li>
);

ContactCard.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.string,
    full_name: PropTypes.string,
    date_of_birth: PropTypes.number,
    phone_numbers: PropTypes.array,
    addresses: PropTypes.array,
  }).isRequired,
  id: PropTypes.string.isRequired,
  onSelectContact: PropTypes.func.isRequired,
};

export default ContactCard;
