import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import './FilterList.scss';

class FilterList extends Component {
  static buttonClasses(label, selectedItem) {
    return classNames({
      'filter-list__item-button--selected': _.isEqual(label, selectedItem),
    }, 'filter-list__item-button');
  }

  constructor(props) {
    super(props);

    this.onItemClick = this.onItemClick.bind(this);

    this.state = {
      selectedItem: null,
    };
  }

  onItemClick(selectedItem) {
    this.setState({ selectedItem }, () => {
      this.props.onItemClick(selectedItem);
    });
  }

  render() {
    const { className, items, itemLabel } = this.props;
    const { selectedItem } = this.state;

    return (
      <div className={classNames('filter-list', className)}>
        <label htmlFor="filter-list-items" className="filter-list__label">
          {itemLabel}
        </label>
        <ul id="filter-list-items" className="list-unstyled filter-list__options">
          {
            _.map(items, (label, key) => (
              <li
                className="filter-list__item"
                key={`item-${key}`}
              >
                <button
                  className={FilterList.buttonClasses(label, selectedItem)}
                  id={`filter-item-${label}`}
                  name={label}
                  onClick={() => this.onItemClick(label)}
                  type="button"
                >
                  {label}
                </button>
              </li>
            ))
          }
        </ul>
      </div>
    );
  }
}

FilterList.propTypes = {
  className: PropTypes.string,
  itemLabel: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onItemClick: PropTypes.func.isRequired,
};

FilterList.defaultProps = {
  className: '',
};

export default FilterList;
