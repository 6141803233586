import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@unite-us/ui';
import { isEmpty } from 'lodash';

const ReferralFooterButtons = ({
  canSaveDraft,
  onSaveDraft,
  onSubmitForm,
  submitting,
  hasOONCases,
  formErrors,
  disableNextButton,
}) => {
  const toolTip = hasOONCases && {
    'data-tooltip-left': true,
    'data-tooltip-large': 'Out of Network Cases cannot be saved as drafts',
  };

  const { services: errors = [] } = formErrors;
  const hasErrors = !isEmpty(errors[0]);

  let ariaErrorFields;
  let lastErrorField;
  let ariaErrorMessage;

  if (hasErrors) {
    ariaErrorFields = Object.keys(errors[0]).map((key) => {
      switch (key) {
        case 'notes':
          return 'referral description';
        case 'referred_by_network':
          return 'sending network';
        case 'selected':
          return 'selected organization';
        case 'oonCase':
          return 'primary worker';
        default:
          return key.replace('_', ' ');
      }
    });
    if (ariaErrorFields.length > 1) {
      lastErrorField = ariaErrorFields.pop();
      ariaErrorMessage = `invalid entry for ${ariaErrorFields.join(', ')} and ${lastErrorField}`;
    } else {
      ariaErrorMessage = `invalid entry for ${ariaErrorFields[0]}`;
    }
  }

  return (
    <div className="referral-footer-buttons">
      <span
        {...toolTip}
      >
        <Button
          id="save-draft-btn"
          onClick={onSaveDraft}
          label="Save Draft"
          disabled={!canSaveDraft || submitting}
          secondary
        />
      </span>
      <span aria-label={ariaErrorMessage}>
        <Button
          id="next-btn"
          onClick={onSubmitForm}
          className="ml-one"
          label="Next"
          disabled={disableNextButton || submitting}
          primary
        />
      </span>
    </div>
  );
};

ReferralFooterButtons.propTypes = {
  canSaveDraft: PropTypes.bool,
  disableNextButton: PropTypes.bool,
  onSaveDraft: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  hasOONCases: PropTypes.bool,
  formErrors: PropTypes.object,
};

ReferralFooterButtons.defaultProps = {
  canSaveDraft: false,
  disableNextButton: false,
  submitting: false,
  hasOONCases: false,
  formErrors: {},
};

export default ReferralFooterButtons;
