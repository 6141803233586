import { get } from 'lodash';

function getProgramAttributesFullNames(attributes = {}, programEnums = [], attributeType = '') {
  const attributeEnums = programEnums[attributeType];

  if (attributeEnums) {
    return attributes.reduce((acc, attribute) => {
      const enumValue = attributeEnums.find((a) => a.value === attribute);

      if (enumValue) {
        acc.push(get(enumValue, 'display_name'));
      }
      return acc;
    }, []);
  }
  return [];
}

export default getProgramAttributesFullNames;
