import _ from 'lodash';

function oonGroupSelected(selectedFields = []) {
  return _.some(selectedFields, (field) => {
    const value = _.get(field, 'group.value');
    return !_.isEmpty(value) && !_.get(value, 'isCustom', false);
  });
}

export default oonGroupSelected;
