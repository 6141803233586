import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import StepButton from '@material-ui/core/StepButton';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import _ from 'lodash';

function renderStep(label, icon, index, linear, onClick, labelStyle, buttonStyle) {
  const renderedLabel = () => {
    if (_.isFunction(label)) {
      return label();
    }
    return label;
  };
  if (linear) {
    return (
      <StepLabel
        style={labelStyle}
        icon={icon || index + 1}
      >
        {renderedLabel() || ''}
      </StepLabel>
    );
  }
  return (
    <StepButton
      onClick={() => onClick(index)}
      style={buttonStyle}
      icon={icon || index + 1}
    >
      {renderedLabel() || ''}
    </StepButton>
  );
}

function getContent(step, currentStep) {
  if (_.isFunction(step.content)) {
    return step.content(currentStep);
  }
  return step.content;
}

function isStepCompleted(step, currentStep, index, linear) {
  if (_.isFunction(step.stepCompleted)) {
    return step.stepCompleted(currentStep);
  }
  if (_.isBoolean(step.stepCompleted)) {
    return step.stepCompleted;
  }
  return (linear) ? currentStep > index : false;
}

function isStepDisabled(step, currentStep) {
  if (_.isFunction(step.stepDisabled)) {
    return step.stepDisabled(currentStep);
  }
  if (_.isBoolean(step.stepDisabled)) {
    return step.stepDisabled;
  }
  return false;
}

export default class UIStepper extends React.Component {
  constructor(props) {
    super(props);
    this.getCurrentStep = this.getCurrentStep.bind(this);
    this.setCurrentStep = this.setCurrentStep.bind(this);
    this.onStepClick = this.onStepClick.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onBackClick = this.onBackClick.bind(this);

    this.state = {
      currentStep: props.initialStep,
    };
  }

  onStepClick(index) {
    if (_.isFunction(this.props.onStepChanged)) {
      this.props.onStepChanged(this.state.currentStep, index);
    }

    if (_.isFunction(this.props.steps[index].onStepClick)) {
      this.props.steps[index].onStepClick();
    }

    this.setCurrentStep(index);
  }

  onNextClick() {
    if (_.isFunction(this.props.onNextClicked)) {
      this.props.onNextClicked(this.state.currentStep);
    }
    if (_.isFunction(this.props.steps[this.state.currentStep].onNextClicked)) {
      this.props.steps[this.state.currentStep].onNextClicked();
    }
    if (this.state.currentStep < this.props.steps.length - 1) {
      this.setCurrentStep(this.state.currentStep + 1);
    }
  }

  onBackClick() {
    if (_.isFunction(this.props.onBackClicked)) {
      this.props.onBackClicked(this.state.currentStep);
    }
    if (_.isFunction(this.props.steps[this.state.currentStep].onBackClicked)) {
      this.props.steps[this.state.currentStep].onBackClicked();
    }
    if (this.state.currentStep > 0) {
      this.setCurrentStep(this.state.currentStep - 1);
    }
  }

  getCurrentStep() {
    return this.state.currentStep;
  }

  setCurrentStep(index) {
    this.setState({
      currentStep: index,
    });
  }

  render() {
    const {
      steps,
      linear,
      style,
      stepperStyle,
      stepStyle,
      stepLabelStyle,
      stepButtonStyle,
    } = this.props;

    const { currentStep } = this.state;

    return (
      <div
        className="stepper"
        style={style}
      >
        <Stepper
          activeStep={currentStep}
          style={stepperStyle}
        >
          {
            steps.map((step, index) => (
              <Step
                key={`step-${index}`}
                active={index === currentStep}
                completed={isStepCompleted(steps[index], currentStep, index, linear)}
                disabled={isStepDisabled(steps[index], currentStep, linear)}
                style={stepStyle}
              >
                {renderStep(
                  step.title,
                  step.titleIcon,
                  index,
                  linear,
                  this.onStepClick,
                  stepLabelStyle,
                  stepButtonStyle,
                )}
              </Step>
            ))
          }
        </Stepper>
        <div className="step-content">
          {getContent(steps[currentStep], currentStep)}
        </div>
      </div>
    );
  }
}

UIStepper.propTypes = {
  steps: PropTypes.array.isRequired,
  linear: PropTypes.bool.isRequired,
  initialStep: PropTypes.number.isRequired,
  onBackClicked: PropTypes.func,
  onNextClicked: PropTypes.func,
  onStepChanged: PropTypes.func,
  style: PropTypes.object,
  stepperStyle: PropTypes.object,
  stepStyle: PropTypes.object,
  stepLabelStyle: PropTypes.object,
  stepButtonStyle: PropTypes.object,
};

UIStepper.defaultProps = {
  linear: false,
  initialStep: 0,
};
