export default function callOrLog(func) {
  try {
    func();
  } catch (error) {
    // this process variable is evaluated at build
    if (process.env.NODE_ENV !== 'production') {
      throw error;
    }
  }
}
