import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useFind } from 'src/api/APIHooks';
import { Icon, InputField, Button } from '@unite-us/ui';
import { Form, Field } from 'react-final-form';
import { validations } from '@unite-us/app-components';
import isEmployeeOfOrg from 'src/components/Organization/utils/isEmployeeOfOrg';
import composeValidators from 'src/components/Organization/utils/composeValidators';
import { isEmpty } from 'lodash';
import {
  useUsersByEmail,
  useAllRoles,
} from 'src/components/Organization/api/hooks/v1';

const notUniqueMessage = `A user with this email address already exists in your organization. 
Please provide a different email address or update the existing user.`;

const initialState = {
    emailAddressToSearch: '',
    showNotUniqueMessage: false,
    currentInvalidEmail: '',
    user: null,
    employees: [],
};

function reducer(state, action) {
  switch (action.type) {
    case 'emailAddressToSearch':
      return { ...state, emailAddressToSearch: action.payload };
    case 'showNotUniqueMessage':
      return { ...state, showNotUniqueMessage: action.payload };
    case 'updateCurrentInvalidEmail':
      return { ...state, currentInvalidEmail: state.emailAddressToSearch };
    case 'user':
      return { ...state, user: action.payload };
    case 'employees':
      return { ...state, employees: action.payload };
    default:
      throw new Error();
  }
}

const UserExistsCheck = ({ onCheckComplete, currentProviderId }) => {
  const { isEmail, isRequired } = validations;
  const { programRoles, orgRoles } = useAllRoles();
  const [state, dispatch] = useReducer(reducer, initialState);

  const getMostRecentlyUpdatedEmployeeRecord = () => {
    let retIdx = 0;
    for (let i = 1; i < state.employees.length; i++) {
      if (Date.parse(state.employees[i].updated_at) > Date.parse(state.employees[retIdx].updated_at)) {
        retIdx = i;
      }
    }
    return state.employees[retIdx];
  };

  const prepareInitialValuesForUserUpdate = () => {
    const refEmployee = getMostRecentlyUpdatedEmployeeRecord();
    const addr = refEmployee.addresses[0] ?? {};

    const empAllRoles = refEmployee.roles.map((r) => r.id);
    const empProgRole = programRoles.find((role) => empAllRoles.includes(role.id));
    const empOrgRoles = orgRoles.filter((role) => empAllRoles.includes(role.id));

    const activeUserObj = {
      id: refEmployee.active_in_current_group ? 'active' : 'inactive',
      name: refEmployee.active_in_current_group ? 'Active' : 'Inactive',
    };

    const retValues = {
      city: addr.city ?? '',
      country: addr.country ?? '',
      county: addr.county ?? '',
      email: refEmployee.email ?? '',
      first_name: refEmployee.first_name ?? '',
      last_name: refEmployee.last_name ?? '',
      latitude: addr.latitude ?? '',
      line_1: addr.line_1 ?? '', // address line 1
      line_2: addr.line_2 ?? '', // address line 2
      longitude: addr.longitude ?? '',
      phone_numbers: refEmployee.phone_numbers,
      postal_code: addr.postal_code ?? '',
      state: addr.state ?? '',
      work_title: refEmployee.work_title ?? '',
      userId: state.user.id,
      user_status: activeUserObj,
      user_org_roles: empOrgRoles,
      user_program_role: empProgRole,
      user_programs: [],
    };

    onCheckComplete(retValues);
  };

  // Runs once fetchEmployeesHook updates employees state
  useEffect(() => {
    if (state.employees.length) {
      const isAlreadyEmployeeOfOrg = isEmployeeOfOrg(state.employees, currentProviderId);
      if (isAlreadyEmployeeOfOrg) {
        dispatch({ type: 'updateCurrentInvalidEmail' });
        dispatch({ type: 'showNotUniqueMessage', payload: true });
      } else {
        prepareInitialValuesForUserUpdate();
      }
    } else if (state.user) {
      onCheckComplete({ email: state.emailAddressToSearch });
    }
  }, [state.employees]);

  const fetchEmployeesHook = useFind(
    'employee',
    { email: state.emailAddressToSearch },
    {
      queryConfig: {
        enabled: false,
        placeholderData: undefined,
        onSuccess: (result) => {
          dispatch({ type: 'employees', payload: result.data.data });
        },
      },
    },
  );

  // Runs once fetchUserHook updates user state
  useEffect(() => {
    if (state.user && isEmpty(state.user)) {
      onCheckComplete({ email: state.emailAddressToSearch });
    } else if (state.user) {
      fetchEmployeesHook.refetch();
    }
  }, [state.user]);

  const fetchUserHook = useUsersByEmail(
    { email: state.emailAddressToSearch },
    {
      enabled: false,
      onSuccess: (result) => {
        const newUser = result[0] ?? {};
        dispatch({ type: 'user', payload: newUser });
      },
    },
  );

  const onChangeHandler = ({ target: { value } }) => {
    dispatch({ type: 'emailAddressToSearch', payload: value });
    if ((!!state.currentInvalidEmail) && (value === state.currentInvalidEmail)) {
      dispatch({ type: 'showNotUniqueMessage', payload: true });
    } else {
      dispatch({ type: 'showNotUniqueMessage', payload: false });
    }
  };

  return (
    (
      <div className="relative userExistsCheck-parentDiv">
        <Icon
          className="pt-2 pl-3 absolute"
          icon="IconSearch"
          size={20}
        />
        <Form
          onSubmit={() => {
            fetchUserHook.refetch();
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="searchField"
                validate={composeValidators(isRequired, isEmail)}
                className={state.showNotUniqueMessage && 'ui-input-field--has-error'}
                role="region"
              >
                {(params) => (
                  <>
                    <InputField
                      hideError={state.showNotUniqueMessage}
                      hideHint={!(state.showNotUniqueMessage)}
                      hint={state.showNotUniqueMessage && (<span className={'text-red'}> {notUniqueMessage} </span>)}
                      placeholder="User email address"
                      label="search" id="search" hideLabel
                      {...params}
                      onChange={onChangeHandler}
                    />
                    <Button
                      label="Check for user"
                      className="button_link"
                      type="submit"
                    />
                  </>
                )}
              </Field>
            </form>
          )}
        />
      </div>
    )
  );
};

UserExistsCheck.propTypes = {
  onCheckComplete: PropTypes.func.isRequired,
  currentProviderId: PropTypes.string.isRequired,
};

export default UserExistsCheck;
