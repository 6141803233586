import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CheckBoxField } from '@unite-us/ui';
import { validations } from '@unite-us/app-components';
import { noop, replace, map, get } from 'lodash';
import { generateUUID } from 'common/utils/utils';
import {
  phoneCanReceiveOralCommunication,
  phoneCanReceiveWrittenCommunication,
  phoneNumberNotEmptyValidation,
} from 'common/form/Phone/utils';
import { CommunicationTypesCheckBoxes } from 'common/form';
import { PhoneNumberField, PhoneTypeField } from 'common/form/Phone';
import { RemovePhoneButton, RemovePhoneConfirmation } from './components';
import combinePhoneNumberAndExtension from '../utils/combinePhoneNumberAndExtension';
import focusExtensionIfPhoneNumberValid from '../utils/focusExtensionIfPhoneNumberValid';
import './stylesheets/profilePhoneFields.scss';

function enforcePrimaryField(primariesExist, i, primaryField) {
  if (!primariesExist && i === 0) {
    primaryField.onChange(true);
  }
}

const ProfilePhoneFields = ({
  fields,
  hideCommunicationPreferences,
  phoneFor,
  registerField,
  styles,
  onRemove,
  onPrimaryChange,
  phonePrimariesExist,
  index,
  withPhoneExtensionField,
}) => {
  const [showRemove, setShowRemove] = useState(false);

  const [uuid, setUuid] = useState('');

  useEffect(() => {
    enforcePrimaryField(phonePrimariesExist, index, fields.is_primary);
  });

  useEffect(() => {
    setUuid(generateUUID());
  }, []);

  const onCancelRemove = () => {
    setShowRemove(false);
  };

  const onConfirmRemove = () => {
    onRemove(fields.id.value);
  };

  const onPrimaryChanged = () => {
    onPrimaryChange(fields);
  };

  const handleAutoTab = (event) => {
    const extensionInputBox = document.getElementById(`${event.target.id}-extension`);
    focusExtensionIfPhoneNumberValid(event.target.value, extensionInputBox);
  };

  const onTypeChange = () => {
    // Clear all communication check boxes when changing types.
    // @todo Maybe we can be a bit smarter about this.
    for (const typeField of fields.acceptable_communication_types) {
      typeField.onChange();
    }
  };

  const getId = (name) => name.concat('-').concat(uuid);

  const showRemoveConfirmation = () => {
    setShowRemove(true);
  };

  return (
    <div className="profile-phone-fields">
      <div className="row">
        <div className="col-xs-2 text-right pt-quarter">
          <h5 className="inline-phone-label">Phone</h5>
        </div>
        <div className="col-xs-4 profile-phone-fields__phone-number">
          { withPhoneExtensionField ?
            (
              <div className="row phone-number-row">
                <div
                  className="col-xs-8 pr-0"
                  data-test-element="phone_number_input"
                >
                  <PhoneNumberField
                    autoFocus
                    id={getId('phoneNumber')}
                    field={fields.phone_number}
                    registerField={registerField}
                    placeholder="(xxx) xxx-xxxx"
                    mask="(999) 999-9999"
                    onChange={handleAutoTab}
                    validations={[
                      { func: validations.isPhoneNumber },
                      { func: validations.isRequired },
                    ]}
                  />
                </div>
                <div
                  className="col-xs-4 pr-0"
                  title="Phone Number Extension"
                  data-test-element="phone_extension_input"
                >
                  <PhoneNumberField
                    id={`${getId('phoneNumber')}-extension`}
                    field={fields.extension}
                    label="Phone Number Extension"
                    registerField={registerField}
                    placeholder="ext."
                    mask=""
                    validations={[
                      { func: validations.isNumber },
                    ]}
                  />
                </div>
              </div>
          ) :
          (
            <PhoneNumberField
              id={getId('phoneNumber')}
              field={combinePhoneNumberAndExtension(fields)}
              registerField={registerField}
              validations={[
                { func: validations.isPhoneNumber },
                { func: validations.isRequired },
                {
                  func: (value, message, args, formValues) => {
                    const formatPhone = (phone) => replace(phone, /\D/g, '');
                    const { phones } = formValues;
                    const phoneNumbers = map(phones, (p) => formatPhone(get(p, 'phone_number', '')));
                    const hasDuplicatePhone = phoneNumbers.filter((p) => p === formatPhone(value)).length > 1;
                    return hasDuplicatePhone ? message : null;
                  },
                  message: 'Phone already exists on this profile',
                },
              ]}
            />
          )}
        </div>
        <div className="col-xs-3 profile-phone-fields__phone-type">
          <PhoneTypeField
            field={fields.phone_type}
            id={getId('phoneType')}
            onChange={onTypeChange}
            phoneFor={phoneFor}
            registerField={registerField}
            validations={{
              func: phoneNumberNotEmptyValidation(fields),
              message: 'Required',
            }}
          />
        </div>
        <div className="col-xs-2 profile-phone-fields__is-primary">
          <CheckBoxField
            field={fields.is_primary}
            id={getId('isPrimary')}
            label="Primary"
            onChange={onPrimaryChanged}
            style={{ marginTop: '38px' }}
          />
        </div>
        <div
          className="col-xs-1"
          style={showRemove ? styles.fadeRemove : styles.showRemove}
        >
          {
            /* @todo we may want to add an explicit prop to show/hide this button */
            onRemove &&
              <RemovePhoneButton onClick={showRemoveConfirmation} />
          }
        </div>
      </div>
      {
        !hideCommunicationPreferences && (
          <div className="row">
            <div className="col-xs-2" />
            <div className="col-xs-10">
              <CommunicationTypesCheckBoxes
                hideOralTypes={!phoneCanReceiveOralCommunication(fields.phone_type.value)}
                hideWrittenTypes={!phoneCanReceiveWrittenCommunication(fields.phone_type.value)}
                id={uuid}
                fields={fields.acceptable_communication_types}
                phone
              />
            </div>
          </div>
        )
      }
      <RemovePhoneConfirmation
        show={showRemove}
        onCancel={onCancelRemove}
        onConfirm={onConfirmRemove}
      />
    </div>
  );
};

ProfilePhoneFields.propTypes = {
  fields: PropTypes.shape({
    acceptable_communication_types: PropTypes.shape({
      message: PropTypes.object,
      notification: PropTypes.object,
      phone_call: PropTypes.object,
    }),
    id: PropTypes.object.isRequired,
    is_primary: PropTypes.object.isRequired,
    phone_number: PropTypes.object.isRequired,
    phone_type: PropTypes.object.isRequired,
    extension: PropTypes.object,
  }).isRequired,
  hideCommunicationPreferences: PropTypes.bool,
  onPrimaryChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  phoneFor: PropTypes.oneOf(['contact', 'group']),
  registerField: PropTypes.func.isRequired,
  styles: PropTypes.object,
  phonePrimariesExist: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  withPhoneExtensionField: PropTypes.bool,
};

ProfilePhoneFields.defaultProps = {
  hideCommunicationPreferences: false,
  onRemove: noop,
  phoneFor: 'contact',
  styles: {
    fadeRemove: { opacity: '.3' },
    showRemove: { visibility: 'visible' },
    hideRemove: { visibility: 'hidden', display: 'none' },
    checkbox: { marginTop: '8px' },
  },
  withPhoneExtensionField: false,
};

export default ProfilePhoneFields;
