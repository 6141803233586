import _ from 'lodash';

const ADDRESS_VALUES = [
  'line_1',
  'line_2',
  'city',
  'state',
  'postal_code',
  'country',
  'address_type',
  'is_mailing_address',
];

function mergeAddresses(contactAddresses, requestorAddresses) {
  const cAddresses = _.map(contactAddresses, (address) => _.uuPick(address, ADDRESS_VALUES));
  const rAddresses = _.map(requestorAddresses, (address) => _.uuPick(address, ADDRESS_VALUES));

  if (_.isEmpty(rAddresses) || _.isEqual(cAddresses, rAddresses)) {
    return null;
  }

  if (_.isEmpty(cAddresses)) {
    return rAddresses;
  }

  return _.reduce(rAddresses, (acc, curr) => {
    if (_.some(cAddresses, curr)) {
      return acc;
    }

    return [
      ...acc,
      curr,
    ];
  }, []);
}

export default mergeAddresses;
