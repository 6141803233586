import axios from 'axios';
import { CREATE_GROUPS_INVITATION } from 'actions';
import Notifier from 'common/helpers/Notifier';

export default function createGroupsInvitation(groupId, invitation) {
  return (dispatch) => {
    const request = axios.post(`/groups/${groupId}/invitations`, {
      invitation,
    });

    function onSuccess(payload) {
      dispatch({
        type: CREATE_GROUPS_INVITATION,
        payload,
      });
      Notifier.dispatch(payload.status, 'Invitation Sent');

      return payload;
    }

    function onError(error) {
      Notifier.handleErrors(error);

      return error;
    }

    return request.then(onSuccess, onError);
  };
}
