import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from 'lodash';
import { providerDefaultLogo } from '@unite-us/client-utils';
import './NetworkGroupLogo.scss';

function isMasterRegExp(url) {
  const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
  return regexp.test(url);
}

function getSource(group) {
  if (group) {
    if (!isEmpty(group.logo_url) && isMasterRegExp(group.logo_url)) {
      return (
        <img alt="Group avatar" src={group.logo_url} />
      );
    }
    return (
      <img alt="Group avatar" src={providerDefaultLogo} />
    );
  }
  return null;
}

const NetworkGroupLogo = ({ group }) => (
  <div className="network-group-logo">
    <figure className="network-group-logo__figure">
      {getSource(group)}
    </figure>
  </div>
);

NetworkGroupLogo.propTypes = {
  group: PropTypes.object,
};

export default NetworkGroupLogo;
