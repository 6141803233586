import _ from 'lodash';
function buildOptions() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$options = _ref.options,
    options = _ref$options === void 0 ? [] : _ref$options,
    _ref$disableParent = _ref.disableParent,
    disableParent = _ref$disableParent === void 0 ? true : _ref$disableParent,
    _ref$level = _ref.level,
    level = _ref$level === void 0 ? 0 : _ref$level;
  return _.reduce(options, function (acc, opt) {
    if (_.has(opt, 'children') && !_.isEmpty(opt.children)) {
      var newOpt = _.merge(opt, {
        hasChildren: true,
        level: level,
        disabled: disableParent
      });
      return _.concat(acc, newOpt, buildOptions({
        options: opt.children,
        disableParent: disableParent,
        level: level + 1
      }));
    }
    return _.concat(acc, _.merge(opt, {
      hasChildren: false,
      level: level
    }));
  }, []);
}
export default buildOptions;