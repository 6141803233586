export const FEATURE_FLAGS = {
  robustOrgAdmin: 'uup-187-robust-org-admin-interface',
};

export const DAYS_OF_THE_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const PHONE_TYPES = ['Phone', 'Fax', 'Other'];
export const USER_PHONE_TYPES = ['Work', 'Mobile', 'Fax', 'Unknown'];
export const TIME_FORMAT_SERVER = 'h:mm A';
export const TIME_FORMAT_DISPLAY = 'HH:mm';

export const ORG_TABS = {
  organization: 'organization',
  users: 'users',
};

export const ORG_STATES = {
  draft: 'draft',
  draftSubmitted: 'draft_submitted',
  draftInReview: 'draft_in_review',
  draftNeedsAction: 'draft_needs_action',
  draftPreLaunchConfigured: 'draft_pre_launch_configured',
  active: 'active',
};
