import { find, get } from 'lodash';

function convertToDisplayName(enumParent, enumChild, value) {
  const targetedEnum = enumParent[`${enumChild}`];
  const enumObject = find(targetedEnum, (enumResult) => enumResult.value === value);

  return get(enumObject, 'display_name', 'Enum does not exist');
}

export default convertToDisplayName;
