import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from 'common/form/ConfirmationDialog';

class RemoveDocument extends PureComponent {
  constructor(props) {
    super(props);

    this.onRemoveDocument = this.onRemoveDocument.bind(this);
  }

  onRemoveDocument() {
    this.removeDocumentConfirmationDialog.confirmationDialog.openDialog()
      .then((result) => {
        if (result === 'confirm') {
          this.props.onRemoveDocument();
        }
      });
  }

  render() {
    const { canRemove, id } = this.props;
    return (
      <div className="remove-document">
        {
          canRemove && (
            <a
              id={`${id}-remove-link`}
              className="icon-delete"
              onClick={this.onRemoveDocument}
            >
              Remove
            </a>
          )
        }
        <ConfirmationDialog
          ref={(el) => { this.removeDocumentConfirmationDialog = el; }}
        >
          <p>Are you sure you want to remove this document?</p>
        </ConfirmationDialog>
      </div>
    );
  }
}

RemoveDocument.propTypes = {
  canRemove: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onRemoveDocument: PropTypes.func.isRequired,
};

RemoveDocument.defaultProps = {
  canRemove: false,
};

export default RemoveDocument;
