import React from 'react';
import PropTypes from 'prop-types';
import {
  STATIC_API_URL,
  STATIC_OON_MARKER_URL,
  STATIC_MARKER_URL,
} from './utils/constants';
import { colorScheme } from '../utils/mapScheme';
import getMarkerScheme from '../utils/getMarkerScheme';
import getStaticMapScheme from './utils/getStaticMapScheme';

const StaticMap = ({
  isLicensedProvider,
  latLng,
  serviceArea,
  zoom,
}) => {
  const width = '470';
  const height = '200';
  const scale = '2';
  const icon = isLicensedProvider ? STATIC_MARKER_URL : STATIC_OON_MARKER_URL;

  const markers = `&markers=${getMarkerScheme({
    latLng,
    icon,
    scale,
  })}`;
  const mapSize = `&size=${width}x${height}`;
  const mapScale = `&scale=${scale}`;
  const mapScheme = `&${getStaticMapScheme(colorScheme)}`;
  const mapZoom = `&zoom=${zoom}`;
  const mapCenter = `&center=${serviceArea}`;
  const mapStyle = '&style=feature:administrative.locality|element:labels|visibility:off';

  let mapSrc;
  if (serviceArea) {
    mapSrc = `${STATIC_API_URL}${mapCenter}${mapSize}${mapScale}${mapZoom}${mapStyle}`;
  } else {
    mapSrc = `${STATIC_API_URL}${markers}${mapScheme}${mapSize}${mapScale}${mapZoom}`;
  }

  return (
    <img
      alt="map"
      className="static-map"
      height={height}
      src={mapSrc}
    />
  );
};

StaticMap.propTypes = {
  isLicensedProvider: PropTypes.bool.isRequired,
  latLng: PropTypes.array.isRequired,
  zoom: PropTypes.string,
  serviceArea: PropTypes.string,
};

StaticMap.defaultProps = {
  zoom: '15',
  serviceArea: null,
};

export default StaticMap;
