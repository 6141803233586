const getServiceTypesWithParents = (childrenServices, parentServices) => {
  const servicesWithParents = [];
  if (!childrenServices) return servicesWithParents;
  childrenServices.forEach((service) => {
    servicesWithParents.push(service);
    const parentService = parentServices.find((ps) => ps.id === service.parent);
    if (
      parentService &&
      servicesWithParents.every((serviceWithParent) => serviceWithParent.id !== service.parent)
    ) {
      servicesWithParents.push(parentService);
    }
  });
  return servicesWithParents;
};
export default getServiceTypesWithParents;
