import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';
import { theme } from 'src/../tailwind.config';

const RemoveFieldRowButton = ({ onClick, testId }) => (
  <div className="w-1/12 self-end pb-10 pl-3">
    <button
      className="transform translate-y-1"
      data-test-element={testId}
      data-testid={testId}
      onClick={onClick}
      type="button"
      aria-label="remove row"
    >
      <Icon icon="V2Delete" color={theme.extend.colors['action-blue']} />
    </button>
  </div>
);

RemoveFieldRowButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
};

export default RemoveFieldRowButton;
