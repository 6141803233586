import { theme } from 'src/../tailwind.config';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Icon } from '@unite-us/ui';
import { capitalize, isEmpty } from 'lodash';
import moment from 'moment';
import { browserHistory } from 'common/utils/browserHistory';
import stateBgColor from 'src/common/utils/stateBgColor';
import { useUpdateFeeScheduleProgram } from 'src/components/Backoffice/api/hooks/v1/feeScheduleProgramHooks';
import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import ArchiveDialog from '../../ArchiveDialog';

const FeeScheduleProgramSummary = ({ feeScheduleProgram }) => {
  const feeScheduleId = feeScheduleProgram?.fee_schedule?.id;
  const feeScheduleProgramName = feeScheduleProgram.name;
  const starts_at = moment(feeScheduleProgram.starts_at).utc().format('MM/DD/YYYY');
  const ends_at = moment(feeScheduleProgram.ends_at).utc().format('MM/DD/YYYY');
  const state = isEmpty(feeScheduleProgram.state) ? 'Loading...' : feeScheduleProgram.state;
  const ArchiveFeeScheduleProgramModalRef = useRef(null);
  const openArchiveModal = () => {
    ArchiveFeeScheduleProgramModalRef.current.openDialog();
  };
  const closeModal = () => {
    ArchiveFeeScheduleProgramModalRef.current.closeDialog();
  };
  const EditFeeScheduleProgram = () => {
    browserHistory.push({
      pathname: `/backoffice/fee_schedules/${feeScheduleId}/fee_schedule_programs/${feeScheduleProgram.id}/edit`,
    });
  };

  const { updateRecord: updateFeeScheduleProgram } = useUpdateFeeScheduleProgram();

  const archiveFeeScheduleProgram = async () => {
    const response = await updateFeeScheduleProgram(feeScheduleProgram.id, {
      state: 'archived',
    });
    closeModal();
    if (isHttpSuccess(response.status)) {
      Notifier.dispatch(200, 'Fee Schedule Program Successfully Archived');
    }
  };
  return (
    <div className="FeeScheduleProgramSummary">
      <div className="flex flex-row justify-between">
        <div className="flex bg-white justify-start items-center flex-nowrap">
          <h1 className="pr-4 font-bold text-2xl leading-8">{feeScheduleProgramName}</h1>
          <Badge
            className="flex flex-row justify-center px-10 py-2 flex-nowrap items-center"
            text={capitalize(state)}
            color={stateBgColor(state)}
          />
        </div>
        <div className="flex flex-nowrap">
          <Button
            id="archive-fee-schedule-program-button"
            className="mx-2"
            iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="IconArchive" />}
            label="ARCHIVE"
            onClick={() => openArchiveModal()}
            disabled={state.toLowerCase() !== 'active'}
          />
          <Button
            id="edit-fee-schedule-program-button"
            className="mx-2"
            iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="V2Edit" />}
            label="Edit"
            onClick={EditFeeScheduleProgram}
            disabled={moment().utc().isAfter(ends_at)}
          />
        </div>
      </div>
      <div className="mt-1 w-full">
        <p className="pr-3 font-bold inline-block">START DATE: </p>
        <p className="pr-4 inline-block">{starts_at}</p>
        <p className="pr-4 inline-block">|</p>
        <p className="pr-3 font-bold inline-block">END DATE: </p>
        <p className="inline-block">{ends_at}</p>
      </div>
      <ArchiveDialog
        cancelHandler={closeModal}
        confirmationHandler={archiveFeeScheduleProgram}
        confirmationBtnDisabled={state.toLowerCase() !== 'active'}
        title="Archive Fee Schedule Program"
        dialogId="archive-fee-schedule-program-modal"
        ref={ArchiveFeeScheduleProgramModalRef}
        itemName={feeScheduleProgramName}
        itemType="fee schedule program"
      />
    </div>
  );
};

FeeScheduleProgramSummary.propTypes = {
  feeScheduleProgram: PropTypes.object.isRequired,
};

export default FeeScheduleProgramSummary;
