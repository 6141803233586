import { defaultTooltipGenerator } from './index';

const colors = [
  '#E53935', // 0
  '#E05F5F',
  '#FF9900', // 2
  '#FCC53A',
  '#EFDC54', // 4
  '#E8E879',
  '#DADE53', // 6
  '#AFC147',
  '#BCD64D', // 8
  '#47B04B',
  '#91D570', // 10
  '#74B57D',
  '#7ED1A3', // 12
  '#778B9F',
  '#4A657F', // 14
];

const theme = {
  animation: 1000,
  axis: {
    tickPadding: 15,
  },
  bar: {
    rotateLabels: -40,
    showValues: false,
    showXAxis: true,
    showYAxis: true,
    staggerLabels: false,
    wrapLabels: true,
  },
  card: {
    marginBottom: '30px',
  },
  colors,
  horizontalBar: {
    controlLabels: {
      expanded: 'Expanded',
      stacked: 'Stacked',
      stream: 'Streamed',
    },
    groupSpacing: 0.15,
    margin: {
      top: 50, right: 20, bottom: 50, left: 60,
    },
    showControls: false,
    showLegend: false,
    stacked: false,
  },
  legend: {
    align: true,
    margin: { top: 0 },
    maxKeyLength: 20,
    padding: 40,
    position: 'right',
  },
  multiBar: {
    controlLabels: {
      expanded: 'Expanded',
      stacked: 'Stacked',
      stream: 'Streamed',
    },
    groupSpacing: 0.15,
    margin: {
      top: 50, right: 20, bottom: 50, left: 60,
    },
    reduceXTicks: false,
    rotateLabels: 0,
    showControls: false,
    showLegend: false,
    stacked: true,
    staggerLabels: false,
    useInteractiveGuideline: true,
    wrapLabels: true,
  },
  noData: 'There is no Data to display',
  pie: {
    donut: true,
    donutRatio: 0.5,
    growOnHover: true,
    labelsOutside: true,
    margin: {
      top: 0, right: 0, bottom: 0, left: 0,
    },
    padAngle: 0,
    showLabels: false,
  },
  table: {
    border: '1px solid lightgray',
  },
  tooltip: {
    contentGenerator: defaultTooltipGenerator,
    enabled: true,
    showTooltipPercent: true,
  },
  valueFormat: ',.2f',
};

export default theme;
