import { get, has, isArray, isEmpty, map, reduce } from 'lodash';
function recursiveSearch(value, valueKey, options) {
  var selectedOption = reduce(options, function (acc, option) {
    var result;
    if (has(option, 'children') && !isEmpty(option.children)) {
      result = recursiveSearch(value, valueKey, option.children);
    }
    if (result === undefined && get(option, valueKey, null) === value.value) {
      result = option;
    }
    return result === undefined ? acc : result;
  }, undefined);
  return selectedOption;
}
function getSelectValue(value) {
  var valueKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'value';
  var options = arguments.length > 2 ? arguments[2] : undefined;
  var forceObjectValue = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  if (!value) {
    return '';
  }
  if (valueKey === 'value' && !forceObjectValue) {
    if (isArray(value)) {
      return map(value, 'value');
    }
    return value.value;
  }
  if (isArray(value)) {
    return map(value, function (val) {
      return recursiveSearch(val, valueKey, options);
    });
  }
  return recursiveSearch(value, valueKey, options);
}
export default getSelectValue;