import PropTypes from 'prop-types';
import React from 'react';
import { FileThumbnail } from '@unite-us/client-utils';

const FilePreview = ({ contentType, preview }) => {
  if (/\bimage\b/.test(contentType)) {
    return <img src={preview} alt="preview" />;
  }

  return <FileThumbnail contentType={contentType} />;
};

FilePreview.propTypes = {
  contentType: PropTypes.string.isRequired,
  preview: PropTypes.string,
};

export default FilePreview;
