import { useFind } from 'src/api/APIHooks';

const useServiceAuthorizationDenialReasons = (feeScheduleId, state = 'active') => {
  const { isLoading, data: response } = useFind(
    'service_authorization_denial_reasons',
    { fee_schedule: feeScheduleId, state },
    {
      queryConfig: {
        enabled: !!feeScheduleId,
        placeholderData: undefined,
      },
    },
  );

  return isLoading ? [] : (response?.data?.data || []);
};

export default useServiceAuthorizationDenialReasons;
