import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { wget } from 'lodash';
import { CheckBoxField } from '@unite-us/ui';
import { CommunicationTypeInfoIcon } from './components';
import { getCommunicationTypeInfo } from './utils';
import './stylesheets/communicationTypesCheckBoxes.scss';

function CommunicationTypesCheckBoxes(props) {
  const {
    communicationTypes, email, fields, id, phone,
  } = props;

  const checkBoxes = communicationTypes.map((type = {}, index) => (
    <div
      className="communication-types-checkboxes__checkbox-container"
      key={`${id}-${index}-${type.value}`}
    >
      <CheckBoxField
        className="communication-types-checkboxes__checkbox"
        field={fields[type.value]}
        id={`${id}-${index}-${type.value}`}
        label={type.display_name}
        labelKey="display_name"
        valueKey="value"
      />
      <CommunicationTypeInfoIcon
        className="communication-types-checkboxes__info-icon"
        communicationType={type.value}
        id={`${id}-${index}-${type.value}-info-icon`}
        popoverContent={
          getCommunicationTypeInfo({
            communicationType: type.value,
            email,
            phone,
          })
        }
      />
    </div>
  ));

  return (
    <div className="communication-types-checkboxes">
      {checkBoxes}
    </div>
  );
}

CommunicationTypesCheckBoxes.propTypes = {
  communicationTypes: PropTypes.array.isRequired,
  email: PropTypes.bool,
  fields: PropTypes.shape({
    message: PropTypes.object,
    notification: PropTypes.object,
    phone_call: PropTypes.object,
  }).isRequired,
  id: PropTypes.string.isRequired,
  phone: PropTypes.bool,
};

CommunicationTypesCheckBoxes.defaultProps = {
  email: false,
  phone: false,
};

function mapStateToProps(state, ownProps) {
  const {
    hideOralTypes,
    hideWrittenTypes,
  } = ownProps;
  const contactDataEnums = wget(state, 'session.enums.contact_data', {});
  const oralCommunicationTypes = hideOralTypes ? [] :
    wget(contactDataEnums, 'oral_communication_types', []);
  const writtenCommunicationTypes = hideWrittenTypes ? [] :
    wget(contactDataEnums, 'written_communication_types', []);
  const communicationTypes = [
    ...oralCommunicationTypes,
    ...writtenCommunicationTypes,
  ];
  return {
    communicationTypes,
  };
}

export default connect(mapStateToProps)(CommunicationTypesCheckBoxes);
