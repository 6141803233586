export const selectUniqueDisplayValues = (values = []) => values.reduce((acc, currentValue) => {
  const displayName = currentValue.display_name;

  const previouslySelectedValues = acc.map((r) => r.value);
  const addDisplayName = !previouslySelectedValues.includes(displayName);

  let newAcc;
  if (addDisplayName) {
    newAcc = [
      ...acc,
      {
        label: currentValue.display_name,
        value: currentValue.display_name,
      },
    ];
  }
  return addDisplayName ? newAcc : acc;
}, []);
