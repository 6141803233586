import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isFunction } from 'lodash';
import Icon from '@unite-us/ui/dist/Display/Icon';
import ToolTip from 'src/common/ToolTip';
import './ColorField.css';
/**
 * Color Component
 *
 */
const ColorField = (props) => {
  const {
    autoComplete,
    className,
    dataTestId,
    disabled,
    hideError,
    hideHint,
    hideLabel,
    hint,
    id,
    label,
    labelClassName,
    max,
    maxLength,
    min,
    placeholder,
    required,
    step,
    style,
    type,
    input,
    meta,
    toolTipText,
  } = props;

  const {
    value, name, onChange, onBlur, onFocus,
  } = input;
  const { touched, invalid, error } = meta;

  const inputRef = useRef(null);

  const handleSpanClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  function handleSpanKeyDown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      handleSpanClick();
      event.preventDefault();
    }
  }

  const handleChange = (event) => {
    const color = event.target.value.replace('#', '').toUpperCase();
    if (isFunction(onChange)) {
      onChange(color);
    }
  };

  const hasError = invalid && touched;

  const containerClass = classNames({
    'ui-form-field--has-error': hasError,
    'ui-form-field--disabled': disabled,
    'ui-input-field--has-error': hasError,
  }, className);

  const labelClass = classNames(
{
    'ui-form-field__label': true,
    'ui-form-field__label--required': required,
    'sr-only': hideLabel,
    },
    labelClassName.split(','),
);

  return (
    <div
      className={containerClass}
      style={style.container}
    >
      <div className="flex items-baseline">
        <label
          htmlFor={id}
          className={labelClass}
          style={style.label}
        >
          {label}

        </label>
        {toolTipText && (
        <ToolTip
          enableHover
          buttonComponentContent={() => (
            <div data-testid="tool-tip-button" className="w-4 h2"><Icon
              icon="IconInfoCircle"
              className="fill-current"
              ariaLabel="Color field tool tip"
            />
            </div>
        )}
          panelClassName="border bg-white border-solid border-gray-400
      rounded p-4 shadow-md normal-case"
          buttonClassName="px-2 focus:outline-none"
          panelComponentContent={
          () => <div>{toolTipText}</div>
        }
        />

    )}
      </div>
      <div className="flex items-center py-2">
        <input
          ref={inputRef}
          className="w-6 h-6 border-solid border border-black rounded-full overflow-hidden"
          autoComplete={autoComplete}
          disabled={disabled}
          id={id}
          max={max}
          maxLength={maxLength}
          min={min}
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          onFocus={onFocus}
          placeholder={placeholder}
          step={step}
          style={style.input}
          type={type}
          value={`#${value}`}
          data-testid={dataTestId}
        />
        <span
          className="pl-2" onClick={handleSpanClick} onKeyDown={handleSpanKeyDown}
          tabIndex="0"
          role="button"
        >
          {value || 'Pick a color'}
        </span>
        {
          !hideHint &&
          (
            <div className="ui-form-field__hint" style={style.hint}>
              {hint}
            </div>
          )
        }
        {
          !hideError &&
          (
          <div
            className="ui-form-field__error"
            style={style.error} data-test-visible={hasError}
            data-testid={`${name || id || 'unnamed'}-error`}
          >
            {error}
          </div>
          )
        }
      </div>
    </div>
  );
  };

ColorField.propTypes = {
  /** can be on or off or a specific type ie. 'name', 'address-line1' */
  autoComplete: PropTypes.string,
  /** className for reference */
  className: PropTypes.string,
  /** Is the input disabled? */
  disabled: PropTypes.bool,
  /** Should the label be hidden? */
  hideLabel: PropTypes.bool,
  /** Help text displayed below the input */
  hint: PropTypes.node,
  /** id for reference */
  id: PropTypes.string.isRequired,
  /** label text for button */
  label: PropTypes.node.isRequired,
  labelClassName: PropTypes.string,
  /** Placeholder text */
  placeholder: PropTypes.string,
  /** Remove Hint element */
  hideHint: PropTypes.bool,
  /** Remove Error element */
  hideError: PropTypes.bool,
  /** Is the field required */
  required: PropTypes.bool,
  /** granularity that is expected (and required) of the value */
  step: PropTypes.string,
  /** Override the inline-styles of the elements || **Not Recommended** */
  style: PropTypes.shape({
    container: PropTypes.object,
    error: PropTypes.object,
    hint: PropTypes.object,
    input: PropTypes.object,
    label: PropTypes.object,
  }),
  /** Input type */
  type: PropTypes.string,
  /** Min allowed value when `type="number"` */
  min: PropTypes.number,
  /** Max allowed value when `type="number"` */
  max: PropTypes.number,
  /** Max allowed value when `type="text"` */
  maxLength: PropTypes.number,
  /** Immutable identifier for testing */
  dataTestId: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
  toolTipText: PropTypes.string,
};

ColorField.defaultProps = {
  autoComplete: '',
  className: '',
  disabled: false,
  hideError: false,
  hideHint: false,
  hideLabel: false,
  hint: '',
  labelClassName: '',
  max: null,
  min: null,
  maxLength: 99,
  placeholder: '',
  required: false,
  step: 'any',
  style: {
    container: {},
    label: {},
    input: {},
    hint: {},
    error: {},
  },
  type: 'color',
  dataTestId: '',
  input: {
    name: '',
    value: '',
    onChange: () => {},
    onBlur: () => {},
    onFocus: () => {},
  },
  meta: {
    touched: false,
    invalid: false,
    error: '',
  },
  toolTipText: '',
};

export default ColorField;
