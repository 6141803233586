import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addContactToGroup } from 'actions/Contact/Group';
import { epochToDate } from 'common/utils/utils';
import Address from 'common/display/Address';
import PhoneNumber from 'common/display/PhoneNumberDisplay';
import SimpleList from 'common/display/SimpleList';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Button,
} from '@unite-us/ui';
import callOrLog from 'src/common/utils/callOrLog';
import { SEARCH } from 'common/utils/EventTracker/utils/eventConstants';

export class ContactCard extends Component {
  constructor(props) {
    super(props);

    this.onClientConfirmation = this.onClientConfirmation.bind(this);
  }

  onClientConfirmation() {
    const { contact = {}, searchContext } = this.props;

    const {
      assistanceRequest,
      groupId,
    } = this.props;

    callOrLog(() => this.context.eventTracker(SEARCH.useRecord[searchContext], {
      contact_id: contact.id,
    }));

    return this.props.addContactToGroup(contact.id, groupId, { assistance_request_id: assistanceRequest?.id })
      .then(() => (
        // Merging contact details with `onContactConfirmation` must happen sequentially
        // in order to avoid a race condition on the server. It does not rely
        // on the successful completion of `addContactToGroup`.
        this.props.onContactConfirmation(this.props.contact)
      ));
  }

  render() {
    const { id, contact = {} } = this.props;

    return (
      <li className="contact-card">
        <BaseCard>
          <BaseCardHeader title={contact.full_name} />
          <BaseCardBody withPadding>
            <dl>
              <dt>Date of Birth</dt>
              <dd>{epochToDate(contact.date_of_birth)}</dd>
            </dl>
            <dl>
              <dt>Phone</dt>
              <dd>
                <SimpleList list={contact.phone_numbers} ListElement={PhoneNumber} />
              </dd>
            </dl>
            <dl>
              <dt>Address</dt>
              <dd>
                <SimpleList list={contact.addresses} ListElement={Address} shortDisplay />
              </dd>
            </dl>
            <Button
              id={`${id}-use-btn`}
              className="contact-card__confirmation"
              onClick={this.onClientConfirmation}
              label="Use This Record"
              style={{ display: 'block', margin: '0 auto' }}
            />
          </BaseCardBody>
        </BaseCard>
      </li>
    );
  }
}

function mapStateToProps({ session }) {
  return {
    groupId: session?.groupId,
  };
}

ContactCard.propTypes = {
  addContactToGroup: PropTypes.func.isRequired,
  assistanceRequest: PropTypes.shape({
    id: PropTypes.string,
  }),
  contact: PropTypes.shape({
    id: PropTypes.string.isRequired,
    full_name: PropTypes.string,
    date_of_birth: PropTypes.number,
    phone_numbers: PropTypes.array,
    addresses: PropTypes.array,
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onContactConfirmation: PropTypes.func,
  searchContext: PropTypes.string.isRequired,
};

ContactCard.defaultProps = {
  assistanceRequest: null,
  onContactConfirmation: () => {},
};

ContactCard.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  addContactToGroup,
})(ContactCard);
