import { apiV4Default } from 'src/api/config';
import { PLAN_TYPES } from 'src/components/Insurance/constants';

const MAX_PAGE_SIZE = 250;

export default function fetchInsurancePlanOptions({
  networkId = '',
  providerId = '',
  planName = '',
  planType = undefined,
  planIds = undefined,
  showEnrollmentRequired = false,
}) {
  const casedPlanType = planType !== undefined ? planType.toLowerCase() : planType;

  let plan_types = '';
  Object.values(PLAN_TYPES).forEach((plan_type) => {
    if (plan_type !== 'unknown') {
      plan_types = plan_types !== '' ? `${plan_types},${plan_type}` : plan_type;
    }
  });

  const getPlanName = (value) => (planName ? { 'filter[name]': value } : {});
  const getNetwork = (value) => (value ? { 'filter[network]': value } : {});
  const getProvider = (value) => (value ? { 'filter[provider]': value } : {});
  const getIds = (value) => (value ? { 'filter[id]': value.join(',') } : {});
  const getEnrollmentRequired = (value) => (value ? {} : { 'filter[enrollment_required]': 'false' });
  const params = {
    'filter[plan_type]': casedPlanType,
    'filter[state]': 'active',
    'page[size]': MAX_PAGE_SIZE,
    ...getPlanName(planName),
    ...getNetwork(networkId),
    ...getProvider(providerId),
    ...getIds(planIds),
    ...getEnrollmentRequired(showEnrollmentRequired),
  };

  if (!planType || planType === PLAN_TYPES.UNKNOWN) {
    return apiV4Default.get('/plans', {
      params: {
        'filter[plan_type]': plan_types,
        'filter[state]': 'active',
        'page[size]': MAX_PAGE_SIZE,
        ...getPlanName(planName),
        ...getNetwork(networkId),
        ...getProvider(providerId),
        ...getIds(planIds),
        ...getEnrollmentRequired(showEnrollmentRequired),
      },
    });
  }
  return apiV4Default.get('/plans', { params });
}
