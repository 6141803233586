import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import d3 from 'd3';
import nv from 'nvd3';
import {
  BaseCard,
  BaseCardBody,
} from '@unite-us/ui';

import {
  ChartTitle,
  DataTable,
} from 'src/common/charts';

import { theme, formatData } from '../utils';

class ChartMultiBar extends Component {
  constructor(props) {
    super(props);

    this.buildMultiBarChart = this.buildMultiBarChart.bind(this);
    this.compileChart = this.compileChart.bind(this);
    this.hideZeros = this.hideZeros.bind(this);
    this.onViewToggle = this.onViewToggle.bind(this);
    this.refreshChart = this.refreshChart.bind(this);

    this.state = {
      chart: null,
      chartData: null,
      id: _.uniqueId('chart-multi-'),
      showTable: props.defaultShowTable,
    };
  }

  componentDidMount() {
    this.buildMultiBarChart();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.state.chartData.datum(
        formatData(nextProps.data, undefined, undefined, nextProps.colors, nextProps.customDataFormat),
      );
      this.refreshChart();
    }
  }

  onViewToggle() {
    this.setState({ showTable: !this.state.showTable }, () => {
      if (!this.state.showTable && _.isFunction(this.props.onBackButtonClick)) {
        this.props.onBackButtonClick();
      }
      if (!this.state.showTable) {
        this.refreshChart();
      }
    });
  }

  hideZeros() {
    setTimeout(() => {
      const graph = document.getElementById(this.state.id);
      if (!graph) {
        return;
      }
      const bars = graph.getElementsByClassName('nv-bar');
      _.each(bars, (bar) => {
        if (bar.getAttribute('height') === '0') {
          return bar.classList && bar.classList.add('hidden');
        }
        return bar.classList && bar.classList.remove('hidden');
      });
    }, theme.animation + 100);
  }

  compileChart() {
    const {
      props,
    } = this;
    const chart = nv.models.multiBarChart()
      .x(props.x)
      .y(props.y)
      .color(props.colors)
      .noData(theme.noData)
      .duration(theme.animation)
      .controlLabels(props.controlLabels)
      .groupSpacing(props.groupSpacing)
      .rotateLabels(props.rotateLabels)
      .showControls(props.showControls)
      .showLegend(props.showLegend)
      .showXAxis(props.showXAxis)
      .showYAxis(props.showYAxis)
      .stacked(props.stacked)
      .staggerLabels(props.staggerLabels)
      .wrapLabels(props.wrapLabels)
      .reduceXTicks(props.reduceXTicks)
      .useInteractiveGuideline(props.useInteractiveGuideline)
      .margin(_.merge(theme.multiBar.margin, props.margin));
    chart.yAxis
      .tickPadding(theme.axis.tickPadding)
      .tickFormat(d3.format(props.valueFormat));
    chart.xAxis.tickPadding(theme.axis.tickPadding);

    chart.legend.maxKeyLength(props.maxKeyLength);

    chart.tooltip.enabled(props.enableTooltip);
    if (props.useInteractiveGuideline) {
      chart.interactiveLayer.tooltip.contentGenerator(props.tooltipGenerator);
    } else {
      chart.tooltip.contentGenerator(props.tooltipGenerator);
    }

    chart.multibar.dispatch.on('elementClick', props.onElementClick);
    chart.multibar.dispatch.on('elementDblClick', props.onElementDoubleClick);

    const chartData = d3.select(`#${this.state.id} svg`)
      .datum(formatData(props.data, undefined, undefined, props.colors, props.customDataFormat));

    chartData.call(chart);

    nv.utils.windowResize(chart.update);

    return { chart, chartData };
  }

  buildMultiBarChart() {
    nv.addGraph(this.compileChart, (result) => {
      this.setState(result);
      this.hideZeros();
    });
  }

  refreshChart() {
    this.state.chartData.call(this.state.chart);
    this.hideZeros();
  }

  render() {
    const {
      colWidths,
      data,
      enableTableView,
      onBackButtonClick,
      showBackButton,
      showControls,
      showLegend,
      style,
      subTitle,
      title,
      x,
      xLabel,
      y,
      yLabel,
    } = this.props;

    const charClass = () => classNames({
      'chart-multi': true,
      'hide-controls': !showControls,
      'hide-legend': !showLegend,
      hidden: this.state.showTable,
    });

    return (
      <BaseCard className="chart">
        <ChartTitle
          enableTableView={enableTableView}
          onBackButtonClick={onBackButtonClick}
          onViewToggle={this.onViewToggle}
          showBackButton={showBackButton && !this.state.showTable}
          showTable={this.state.showTable}
          subTitle={subTitle}
          title={title}
        />
        <BaseCardBody>
          <div
            className={charClass()}
            id={this.state.id}
            style={style}
          >
            <svg />
          </div>
          {
            this.state.showTable ? (
              <DataTable
                colWidths={colWidths}
                data={data}
                showPercent={false}
                x={x}
                xKey="title"
                xLabel={xLabel}
                y={y}
                yKey="total"
                yLabel={yLabel}
              />
            ) : null
          }
        </BaseCardBody>
      </BaseCard>
    );
  }
}

ChartMultiBar.propTypes = {
  colors: PropTypes.array,
  colWidths: PropTypes.object,
  controlLabels: PropTypes.object.isRequired,
  customDataFormat: PropTypes.func,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  defaultShowTable: PropTypes.bool.isRequired,
  enableTableView: PropTypes.bool.isRequired,
  enableTooltip: PropTypes.bool.isRequired,
  groupSpacing: PropTypes.number.isRequired,
  margin: PropTypes.object.isRequired,
  maxKeyLength: PropTypes.number.isRequired,
  onBackButtonClick: PropTypes.func,
  onElementClick: PropTypes.func,
  onElementDoubleClick: PropTypes.func,
  reduceXTicks: PropTypes.bool.isRequired,
  rotateLabels: PropTypes.number.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  showControls: PropTypes.bool.isRequired,
  showLegend: PropTypes.bool.isRequired,
  showXAxis: PropTypes.bool.isRequired,
  showYAxis: PropTypes.bool.isRequired,
  stacked: PropTypes.bool.isRequired,
  staggerLabels: PropTypes.bool.isRequired,
  style: PropTypes.object.isRequired,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  tooltipGenerator: PropTypes.func.isRequired,
  useInteractiveGuideline: PropTypes.bool.isRequired,
  valueFormat: PropTypes.string.isRequired,
  wrapLabels: PropTypes.bool.isRequired,
  x: PropTypes.func.isRequired,
  xLabel: PropTypes.string,
  y: PropTypes.func.isRequired,
  yLabel: PropTypes.string,
};

ChartMultiBar.defaultProps = {
  colors: theme.colors,
  controlLabels: theme.multiBar.controlLabels,
  defaultShowTable: false,
  enableTableView: true,
  enableTooltip: theme.tooltip.enabled,
  groupSpacing: theme.multiBar.groupSpacing,
  margin: {},
  maxKeyLength: theme.legend.maxKeyLength,
  reduceXTicks: theme.multiBar.reduceXTicks,
  rotateLabels: theme.multiBar.rotateLabels,
  showBackButton: false,
  showControls: theme.multiBar.showControls,
  showLegend: theme.multiBar.showLegend,
  showXAxis: theme.bar.showXAxis,
  showYAxis: theme.bar.showYAxis,
  stacked: theme.multiBar.stacked,
  staggerLabels: theme.multiBar.staggerLabels,
  style: {
    height: '400px',
  },
  tooltipGenerator: theme.tooltip.contentGenerator,
  useInteractiveGuideline: theme.multiBar.useInteractiveGuideline,
  valueFormat: theme.valueFormat,
  wrapLabels: theme.multiBar.wrapLabels,
  x: (d) => d.label,
  xLabel: 'Name',
  y: (d) => d.value,
  yLabel: 'Total',
};

export default ChartMultiBar;
