import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Icon } from '@unite-us/ui';
import DialogV2 from 'common/modal/DialogV2';
import useDeleteNote from 'src/actions/Case/Contact/Group/useDeleteNote';
import IconButton from 'common/buttons/IconButton/IconButton';
import { useInvalidateQueries } from 'src/api/APIHooks';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import Notifier from 'common/helpers/Notifier';
import NoteDeleteContent from './NoteDeleteContent';
import { NOTE_VISIBILITY } from './constants';
import './Note.scss';

const SideDate = ({ day, month, year }) => (
  <div className="inline-block flex-none w-24 align-middle text-center">
    <p className="pb-1">{day ? day.toString() : null}</p>
    <p className="pb-1">{month}</p>
    <p>{year}</p>
  </div>
);

SideDate.propTypes = {
  day: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
};

SideDate.defaultProps = {
  day: null,
  month: null,
  year: null,
};

const Message = (props) => {
  const {
    interactionDate,
    person,
    sender,
    text,
  } = props;
  const day = get(interactionDate, 'day', '');
  const month = get(interactionDate, 'month', '');
  const year = get(interactionDate, 'year', '');
  const time = get(interactionDate, 'time', '');

  return (
    <div className="flex p-3 py-4 bg-white border border-solid border-medium-border-blue rounded">
      <SideDate day={day} month={month} year={year} />
      <div className="inline-block flex-1 w-64 align-middle">
        <b>Message</b> sent to {person.full_name} by {sender.full_name} on
        {` ${month} ${day}, ${year}`} at {time}
        <p className="mt-2 whitespace-pre-wrap">{text}</p>
      </div>
    </div>
  );
};

Message.propTypes = {
  interactionDate: PropTypes.string.isRequired,
  person: PropTypes.object.isRequired,
  sender: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
};

const Note = (props) => {
  const {
    currentEmployeeId,
    interactionDate,
    interactionType,
    text,
    duration,
    Title,
    note,
    noteType,
    category,
    details,
    reason,
    visibility,
    showDeleteNoteIcon,
  } = props;

  const showInteractionType = interactionType !== '';
  const showDate = category !== 'general';
  const serviceProvided = get(details, 'service_provided', null);
  const serviceProvidedType = get(serviceProvided, 'type', '');
  const serviceProvidedAmount = get(serviceProvided, 'amount', '');
  const unit = get(serviceProvided, 'unit', '');
  const serviceProvidedUnit =
    unit === 'dollar' && serviceProvidedAmount > 1 ? `${unit}s` : unit;
  const showReason = reason && get(details, 'context', null);
  const deletable = get(details, 'deletable', true);
  const showDeleteButton =
    showDeleteNoteIcon && currentEmployeeId === get(note, 'employee.id');

  if (noteType === 'Message') {
    return <Message {...props} />;
  }

  const day = get(interactionDate, 'day', '');
  const month = get(interactionDate, 'month', '');
  const year = get(interactionDate, 'year', '');
  const className = showDeleteButton ? 'employee_note_item' : 'note_item';
  const deleteNoteDialogRef = useRef(null);
  const invalidateQueries = useInvalidateQueries();
  const { deleteRecord: deleteNote } = useDeleteNote();

  const deleteButtonOnClickHandler = (e) => {
    e.stopPropagation();
    deleteNoteDialogRef.current.openDialog();
  };
  const confirmDelete = async () => {
    deleteNoteDialogRef.current.closeDialog();
    const response = await deleteNote(get(note, 'id'), {});
    if (isHttpSuccess(response.status)) {
      Notifier.dispatch(response.status, 'Note deleted');
      invalidateQueries('notes');
    }
  };
  const cancelDelete = () => {
    deleteNoteDialogRef.current.closeDialog();
  };

  return (
    <div
      className={`flex ${className} p-3 py-4 bg-white border border-solid border-medium-border-blue rounded`}
    >
      <SideDate day={day} month={month} year={year} />
      <div className="inline-block flex-1 w-64 align-middle">
        {Title ? <Title /> : null}
        {showInteractionType && (
          <p className="pb-2">Interaction Type: {interactionType}</p>
        )}
        {showDate ? (
          <p className="pb-2">
            Date:{' '}
            {get(
              details,
              category === 'service_provided' ?
                'service_provided.date' :
                'interaction.date',
              '',
            )}
          </p>
        ) : null}

        {duration ? <p className="pb-2">Duration: {duration}</p> : null}
        {serviceProvided ? (
          <div>
            <p className="pb-2">Service: {serviceProvidedType}</p>
            <p className="pb-2">
              Amount: {`${serviceProvidedAmount} ${serviceProvidedUnit}`}
            </p>
          </div>
        ) : null}
        {showReason ? <p className="pb-2">Reason: {reason} </p> : null}
        <strong className="font-bold pb-2">{noteType}:</strong>
        <p className="whitespace-pre-wrap">{text}</p>
      </div>
      {visibility === NOTE_VISIBILITY.PRIVATE && (
        <div className="inline-block flex-1 w-64 align-middle text-right text-medium-gray">
          <Icon icon="IconEyeDisabled" /> Private to my organization
        </div>
      )}
      {showDeleteButton && deletable && (
        <div className="deleteNoteButton">
          <IconButton
            className={`${className}_delete-btn`}
            labelText="Delete Note"
            icon="IconTrash"
            onClick={(e) => deleteButtonOnClickHandler(e)}
          />
        </div>
      )}
      <DialogV2
        ref={deleteNoteDialogRef}
        title="Delete Note"
        confirmLabel="Delete"
        cancelHandler={cancelDelete}
        confirmationHandler={confirmDelete}
      >
        <NoteDeleteContent />
      </DialogV2>
    </div>
  );
};

Note.defaultProps = {
  currentEmployeeId: '',
  noteType: 'Note',
  note: {},
  Title: null,
  details: {},
  reason: '',
  visibility: '',
};

Note.propTypes = {
  currentEmployeeId: PropTypes.string,
  interactionDate: PropTypes.string.isRequired,
  interactionType: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  details: PropTypes.string,
  reason: PropTypes.string,
  Title: PropTypes.string,
  note: PropTypes.object,
  noteType: PropTypes.string,
  visibility: PropTypes.string,
  showDeleteNoteIcon: PropTypes.bool.isRequired,
};

export default Note;
