import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'common/utils/browserHistory';
import { fetchSupport } from 'common/utils/Session/actions';
import { LogoutAuth } from 'common/form';
import { loginWithSession } from 'actions/Login';

export class TokenLoginView extends Component {
  componentDidMount() {
    const {
      location: {
        query: {
          session: encodedSession,
        },
      },
    } = this.props;

    const decodedSession = JSON.parse(window.atob(encodedSession));
    const { auth, support, impersonationType } = decodedSession;
    const authInfo = { auth, support, impersonationType };

    this.props.loginWithSession(authInfo)
      .then(() => {
        browserHistory.push('/');
      });
  }

  render() {
    return (
      <div>
        <h2>Logging in</h2>

        {
          <LogoutAuth />
        }
      </div>
    );
  }
}

TokenLoginView.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      session: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  loginWithSession: PropTypes.func.isRequired,
};

export default connect(null, {
  fetchSupport,
  loginWithSession,
})(TokenLoginView);
