import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BrowseActions from 'src/components/Browse/components/BrowseActions';

const GroupDetailsHeaderActions = ({ selected, group, selectGroup }) => {
  const actionsClass = () => classNames({
    'group-details-header-actions': true,
    'group-details-header-actions--add': !selected,
    'group-details-header-actions--remove': selected,
  });

  return (
    <div className={actionsClass()}>
      <BrowseActions
        id="group-details-header-actions"
        selectGroup={selectGroup}
        group={group}
        selected={selected}
      />
    </div>
  );
};

GroupDetailsHeaderActions.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  selectGroup: PropTypes.func.isRequired,
};

export default GroupDetailsHeaderActions;
