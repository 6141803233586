import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  clearNetworkBrowseFilters,
  clearNetworkBrowseGroups,
} from 'actions/Browse';
import '../stylesheets/browseWrapper.scss';

class BrowseWrapper extends Component {
  constructor(props) {
    super(props);

    this.toggleBrowse = this.toggleBrowse.bind(this);
  }

  toggleBrowse() {
    this.props.clearNetworkBrowseFilters();
    this.props.clearNetworkBrowseGroups();
    this.props.toggleBrowse();
  }

  render() {
    const { children } = this.props;
    return (
      <div className="browse-wrapper">
        <div className="browse-wrapper__navigation">
          <div className="browse-wrapper__navigation--header">
            <h1>Browse Referral Recipients</h1>
          </div>
          <div className="browse-wrapper__navigation--cancel">
            <a
              id="exit-browse-btn"
              role="button"
              onClick={this.toggleBrowse}
            >
              Cancel Browse
            </a>
          </div>
        </div>
        <div className="browse-wrapper__child-container">
          {children}
        </div>
      </div>
    );
  }
}


BrowseWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  clearNetworkBrowseGroups: PropTypes.func.isRequired,
  clearNetworkBrowseFilters: PropTypes.func.isRequired,
  toggleBrowse: PropTypes.func.isRequired,
};

export default connect(null, {
  clearNetworkBrowseFilters,
  clearNetworkBrowseGroups,
})(BrowseWrapper);
