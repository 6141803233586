import React, { useRef, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
} from '@unite-us/ui';
import DialogV2 from 'common/modal/DialogV2';
import NetworkSearchTable from 'components/Backoffice/searchTable/NetworkSearchTable';

/**
 * Network Selector
 *
 */
const NetworkSelector = ({
  input,
  meta,
  changeValue,
  className,
  required,
}) => {
  const { value } = input;
  const { error, touched } = meta;
  const hasError = error && touched;

  const [selectedNetwork, setSelectedNetwork] = useState('');

  const AddNetworkModal = useRef(null);

  const closeModal = () => {
    AddNetworkModal.current.closeDialog();
  };

  const addNetwork = () => {
    const isNetworkInList = value && value.some((network) => network.id === selectedNetwork.id);
    if (!isNetworkInList) {
      const updatedNetworks = [...value, selectedNetwork];
      changeValue('networks', updatedNetworks);
    }
    closeModal();
  };

  const removeNetwork = (networkId) => {
    const updatedNetworks = value.filter((network) => network.id !== networkId);
    changeValue('networks', updatedNetworks);
  };

  return (
    <div className={className}>
      <div>
        <label
          htmlFor="social-plan__networks"
          className={cx(
            'block mb-1 leading-snug',
            'font-extrabold font-medium-font normal-case',
            'text-13px text-dark-blue',
            hasError && 'text-validation-red', // red text on error
            required && 'ui-form-field__label--required', // red asterisk after content)}
          )}
        >
          Network(s)
        </label>
        <div>
          {value && value.length ?
          (
            <div>
              {value.map((network) => (
                <div key={`${network.name}-${network.id}`} className="flex items-center justify-between">
                  <p className="mr-2">{network.name}</p>
                  <Button
                    data-test-element={`remove-${network.id}-button`}
                    label="Remove"
                    disabled={required && value.length === 1}
                    className="bg-light-grey border-none text-current-blue pr-0"
                    onClick={() => removeNetwork(network.id)}
                    type="button"
                  />
                </div>
              ))}
            </div>
          ) :
          (
            <div className={cx(
                'pt-2 pb-1',
                hasError && 'text-validation-red',
              )}
            >
              No networks added.
            </div>
          )}
        </div>
      </div>
      <div>
        <Button
          id="social-plan__networks"
          data-test-element={'add-network-button'}
          label="Add Network"
          className={cx(hasError && 'border-validation-red text-validation-red')}
          onClick={() => AddNetworkModal.current.openDialog()}
          type="button"
        />
      </div>
      <span className={cx(
          'ui-form-field__error visible row ml-0 mt-2 h-4',
          'text-13px',
          !hasError && 'invisible',
        )}
      >
        {error}
      </span>
      <DialogV2
        cancelHandler={closeModal}
        confirmationHandler={() => addNetwork()}
        confirmLabel="Add"
        id={'add-network-modal'}
        ref={AddNetworkModal}
        title={'Add Networks'}
        confirmationBtnDisabled={!selectedNetwork}
        width="6xl"
        confirmButtonTestId="add-network-modal-confirm-button"
        dialogDescriptionClass="flex flex-col h-screen overflow-hidden bg-white-grey"
        scrollable={false}
        dialogWrapperPadding="p-12"
      >
        <NetworkSearchTable selectedNetwork={selectedNetwork} setSelectedNetwork={setSelectedNetwork} />
      </DialogV2>
    </div>
  );
};

NetworkSelector.propTypes = {
  input: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  className: PropTypes.string,
  required: PropTypes.bool,
};

NetworkSelector.defaultProps = {
  className: '',
  required: false,
};

export default NetworkSelector;
