import { searchAPIClient as axios } from 'src/api/config';
import { CORE_API } from 'src/config/env/env.config';

export async function fetchFeeSchedulesPage(pageNumber = 1, pageSize = 500) {
    const params = {
        'page[number]': pageNumber,
        'page[size]': pageSize,
        'filter[state]': 'active',
    };
    const response = await axios.get('/fee_schedules', {
        baseURL: CORE_API,
        params,
    });
    return response.data;
}

export async function fetchAllFeeSchedules() {
    const firstPageData = await fetchFeeSchedulesPage();
    const totalPages = firstPageData.meta.page.total_pages;

    const promises = [];
    // Here we've chosen 10 as the arbitrary max number of pages to fetch in order to avoid making too many requests
    for (let i = 2; i <= totalPages && i < 11; i++) {
        promises.push(fetchFeeSchedulesPage(i));
    }

    const results = await Promise.all(promises);

    const allFeeSchedules = [firstPageData, ...results].flatMap((data) => data.data);

    return allFeeSchedules.map((feeSchedule) => ({
        value: feeSchedule.id,
        label: feeSchedule.attributes.name,
        display_name: feeSchedule.attributes.name,
    }));
}

export async function fetchFeeSchedules(ids) {
    const params = {};
    if (ids) {
        params['filter[id]'] = Array.isArray(ids) ? ids.join(',') : ids;
    }
    const response = await axios.get('/fee_schedules', {
        baseURL: CORE_API,
        params,
    });
    return response.data.data.map((feeSchedule) => ({
        value: feeSchedule.id,
        label: feeSchedule.attributes.name,
        display_name: feeSchedule.attributes.name,
    }));
}
