import { useState } from 'react';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import {
  useUpdatePerson,
  useUpdatePersonalRelationship,
} from '../../Common/api';

export const useUpdateFamilyMember = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const { updateRecord: updatePerson } = useUpdatePerson();
  const { updateRecord: updatePersonalRelationship } =
    useUpdatePersonalRelationship();
  const submit = async ({
    personId,
    relationshipId,
    values: {
      first_name, last_name, date_of_birth, relation_to_contact,
    },
  }) => {
    setIsSuccess(false);
    setIsError(false);
    const responsePerson = await updatePerson(personId, {
      first_name,
      last_name,
      ...(date_of_birth && { date_of_birth }),
    });
    if (!responsePerson || !isHttpSuccess(responsePerson.status)) {
      setIsError(true);
      return responsePerson;
    }
    const responsePersonalRelationship = await updatePersonalRelationship(
      relationshipId,
      {
        relationship: relation_to_contact || 'Family Member',
      },
    );
    if (
      !responsePersonalRelationship ||
      !isHttpSuccess(responsePersonalRelationship.status)
    ) {
      setIsError(true);
      return responsePersonalRelationship;
    }
    setIsSuccess(true);
    return responsePersonalRelationship;
  };
  return [submit, isSuccess, isError];
};
