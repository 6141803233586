export const MODELS = {
  contact: 'contact',
  referral: 'referrals_referral',
  servicecase: 'cases_servicecase',
};

export const CLIENT_RESULTS_COUNT = 12;
export const DEFAULT_INDEX_COUNT = 20;
export const DEFAULT_RECORD_TYPE = MODELS.contact;
export const DEFAULT_RESULTS_COUNT = 3;
export const DEFAULT_VIEW_OPTION = MODELS.contact;
export const NO_RESULTS_FOUND_TEXT = 'No results found';
export const VIEW_OPTIONS = [
  { label: 'Clients', value: MODELS.contact },
  { label: 'Referrals', value: MODELS.referral },
  { label: 'Cases', value: MODELS.servicecase },
];
