import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';
import { isEmpty } from 'lodash';
import './EmptyContactDocuments.scss';

const EmptyContactDocuments = ({ documents, styles }) => (
  isEmpty(documents) && (
    <div className="empty-documents col-xs-12">
      <div className="empty-documents__notification">
        <p className="empty-documents__notification-text">NO DOCUMENTS UPLOADS YET</p>
      </div>
      <div className="empty-documents__helper">
        <Icon icon="IconArrow" style={styles.iconArrow} />
        <p className="empty-documents__helper-text">UPLOAD HERE</p>
      </div>
    </div>
  )
);

EmptyContactDocuments.propTypes = {
  documents: PropTypes.array.isRequired,
  styles: PropTypes.shape({
    iconArrow: PropTypes.object,
  }),
};

EmptyContactDocuments.defaultProps = {
  styles: {},
};

export default EmptyContactDocuments;
