const getIntercomParams = (currentEmployee) => {
  if (!currentEmployee) {
    return { userEmail: null, userId: null, userName: null };
  }
  const userId = currentEmployee.user.id;
  const userName = `${currentEmployee.first_name} ${currentEmployee.last_name}`;
  const userEmail = currentEmployee.email;
  return { userId, userName, userEmail };
};

export default getIntercomParams;
