import { coreApi } from 'src/api/config';
import {
  SEARCH_NETWORK_GROUPS,
  SET_NETWORK_GROUPS_FETCHING,
  UNSET_NETWORK_GROUPS_FETCHING,
  SET_NETWORK_GROUPS,
} from 'actions';
import { get, isEmpty } from 'lodash';
import Notifier from 'common/helpers/Notifier';
import { updateGlobalState } from 'src/actions/Global/globalActions';

export const DEFAULT_SEARCH_PARAMS = {
  q: {
    name: '',
    service_type_ids: [],
  },
};

const handleError = (error, dispatch) => {
  dispatch({ type: UNSET_NETWORK_GROUPS_FETCHING });
  Notifier.handleErrors(error);
  return error;
};

const onSuccess = (payload, id, params, dispatch, all) => {
  dispatch({
    type: SEARCH_NETWORK_GROUPS,
    payload,
    id,
    searchParams: params,
  });

  if (all) {
    dispatch({
      type: SET_NETWORK_GROUPS,
      groups: payload.data.data,
    });
    dispatch(updateGlobalState({
      networkProviders: payload.data.data,
    }));
  }

  dispatch({ type: UNSET_NETWORK_GROUPS_FETCHING });

  return payload.data.data;
};

const searchNetworkGroups = (
  id,
  searchParameters,
  options,
  all = false,
) => (async (dispatch) => {
  try {
    const searchParams = isEmpty(searchParameters) ? DEFAULT_SEARCH_PARAMS : searchParameters;
    dispatch({ type: SET_NETWORK_GROUPS_FETCHING });
    const page = {
      page: (all ? {} : {
        number: get(options, 'page', 1),
        size: get(options, 'per', 50),
      }),
      sort: 'name',
    };
    const params = {
      networks: id,
      ...(((!isEmpty(options) && options.search) || searchParams.q.name) !== '' ?
        { name: options.search || searchParams.q.name } : {}),
      ...(searchParams.q.service_type_ids.length > 0 ?
        { 'programs.services': searchParams.q.service_type_ids.join(',') } : {}),
    };

    const payload = await coreApi.query('provider', params, page);

    return onSuccess(payload, id, searchParams, dispatch, all);
  } catch (error) {
    return handleError(error, dispatch);
  }
}
);

export default searchNetworkGroups;
