import Notifier from 'common/helpers/Notifier';
import {
  FETCH_CURRENT_NETWORK,
  SET_IS_FETCHING,
  UNSET_IS_FETCHING,
} from 'actions';
import { coreApi } from 'src/api/config';

export default function fetchCurrentNetwork(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_IS_FETCHING });
      const response = await coreApi.findRecord('network', id);

      dispatch({
        type: FETCH_CURRENT_NETWORK,
        currentNetwork: response.data.data,
      });
      dispatch({ type: UNSET_IS_FETCHING });
      return response;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}
