import Notifier from 'common/helpers/Notifier';
import { get } from 'lodash';
import { coreApi } from 'src/api/config';

/**
 * This scenario will create a new Communication as per talk in
 * https://uniteus.slack.com/archives/C01A7M28KKP/p1629739969133700
 */
export default function createSmsConsent({
  contactId, phoneNumber, language,
}) {
  return async (dispatch, getState) => {
    try {
      const currentEmployee = get(getState(), 'globalState.currentEmployee');
      const phoneNumberDigits = phoneNumber.match(/\d+/g).join('');
      const response = await coreApi.createRecord('communication', {
        medium: 'sms',
        category: 'consent_request',
        to: phoneNumberDigits,
        person: contactId,
        sender: currentEmployee.id,
        details: { language },
      });

      Notifier.dispatch(response.status, 'Consent Request Sent');
      return response;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}
