import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckBoxField,
} from '@unite-us/ui';
import { Field } from 'react-final-form';

const Checkboxes = ({
  fieldKey,
  headingText,
  options,
  hideHeadingVisually,
  postLabelContent,
}) => (
  <fieldset id={fieldKey}>
    <legend className={`ui-form-field__label mb-4${hideHeadingVisually && ' sr-only'}`}>
      {headingText}
    </legend>
    {postLabelContent}
    {options.map(({ display_name, name, value }) => {
      if (!display_name && !name) {
        throw new Error('Checkbox options must have either a name or display_name property.');
      }

      const id = `${fieldKey}.${value}`;
      return (
        <Field
          key={id}
          name={id}
          data-test-element={id}
          type="checkbox"
          isEqual={(a, b) => a === b}
        >
          {(params) => (
            <CheckBoxField
              id={id}
              label={name || display_name}
              showHint={false}
              showError={false}
              {...params}
            />
          )}
        </Field>
      );
    })}
  </fieldset>
);

Checkboxes.defaultProps = {
  hideHeadingVisually: false,
  postLabelContent: null,
};

Checkboxes.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  headingText: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      name: PropTypes.string,
      display_name: PropTypes.string,
    }),
  ).isRequired,
  hideHeadingVisually: PropTypes.bool,
  postLabelContent: PropTypes.node,
};

export default Checkboxes;
