import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Button,
  Icon,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from '@unite-us/ui';
import DialogV2 from 'src/common/modal/DialogV2';
import EmptyTable from 'src/common/tables/EmptyTable/EmptyTable';
import { tableWidth } from 'common/helpers/Tables';
import { isEmpty } from 'lodash';
import { theme } from 'src/../tailwind.config';
import parse from 'html-react-parser';
import { constructFeeScheduleProgramFormPayload } from 'src/pages/fee-schedules/_id/utils/index';
import { useUpdateFeeScheduleProgram } from 'src/components/Backoffice/api/hooks/v1/feeScheduleProgramHooks';
import EditMetafields from 'src/components/Backoffice/metafields/EditMetafields';
import moment from 'moment';

const namespace = 'fee-schedule-program-attachment-table';

const FeeScheduleProgramAttachmentTable = ({ attachments, feeScheduleProgram }) => {
  const EditAttachmentModelRef = useRef(null);
  const [newAttachments, setNewAttachments] = useState([...attachments]);
  const { updateRecord: updateFeeScheduleProgram } = useUpdateFeeScheduleProgram();
  const closeModal = () => {
    setNewAttachments(attachments);
    EditAttachmentModelRef.current.closeDialog();
  };

  const openModal = () => {
    EditAttachmentModelRef.current.openDialog();
  };

  const addAttachments = async () => {
    const customFieldsForFeeScheduleProgram =
      feeScheduleProgram.metafields.filter((metafield) => metafield.type !== 'file');
    const newFeeScheduleProgram = {
      ...feeScheduleProgram,
      metafields: [...customFieldsForFeeScheduleProgram, ...newAttachments],
    };
    const payload = constructFeeScheduleProgramFormPayload(newFeeScheduleProgram, newFeeScheduleProgram.id);
    const response = await updateFeeScheduleProgram(newFeeScheduleProgram.id, payload);
    if (response) {
      closeModal();
    }
  };

  return (
    <>
      <div className="fee-schedule-program-base-card">
        <BaseCard className="px-6" noBorder>
          <BaseCardHeader title="Attachments" noBorder className="px-4 font-bold">
            <Button
              id="edit-attachment-button"
              iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="V2Edit" />}
              className="flex"
              label="EDIT ATTACHMENTS"
              onClick={openModal}
              disabled={moment().utc().isAfter(moment(feeScheduleProgram.ends_at).utc())}
            />
          </BaseCardHeader>
          <BaseCardBody>
            <Table id="feeScheduleProgramAttachmentTable" className="border-0 border-t rounded-none">
              <TableHeader>
                <TableRow>
                  <TableHeaderColumn style={{ width: tableWidth('quarter') }}>
                    Name
                  </TableHeaderColumn>
                  <TableHeaderColumn style={{ width: tableWidth('small') }}>
                    Requirement
                  </TableHeaderColumn>
                  <TableHeaderColumn style={{ width: tableWidth('half') }}>
                    Description
                  </TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                {!isEmpty(attachments) &&
                  attachments
                    .map((attachment) => (
                      <TableRow
                        className={`${namespace}__row`}
                        key={`fee-schedule-attachment-row-${attachment.id}`}
                        hoverable={false}
                        clickable={false}
                      >
                        <TableRowColumn style={{ width: tableWidth('quarter') }}>
                          {attachment.label}
                        </TableRowColumn>
                        <TableRowColumn style={{ width: tableWidth('small') }}>
                          {(attachment.validations && attachment.validations.required) ? 'Required' : 'Optional'}
                        </TableRowColumn>
                        <TableRowColumn style={{ width: tableWidth('half') }}>
                          {(attachment.help_text) ? parse(attachment.help_text) : ''}
                        </TableRowColumn>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {isEmpty(attachments) && (
              <EmptyTable
                className={'p-6'}
                message={'Click "Edit Attachments" to add attachments'}
              />
            )}
          </BaseCardBody>
        </BaseCard>
      </div>
      <DialogV2
        cancelHandler={closeModal}
        confirmationHandler={addAttachments}
        confirmLabel="Save"
        ref={EditAttachmentModelRef}
        title="Add/Edit Attachments"
        vertical
        dialogDescriptionClass={'flex-auto overflow-y-auto bg-medium-fill-blue'}
      >
        <EditMetafields
          metafields={attachments}
          setMetafieldsToSave={setNewAttachments}
          addButtonLabel={'ADD ATTACHMENT'}
          formsTitle={'Attachment'}
          hideSelectField
          defaultFields={{ type: 'file' }}
        />
      </DialogV2>
    </>
  );
};

FeeScheduleProgramAttachmentTable.propTypes = {
  attachments: PropTypes.array.isRequired,
  feeScheduleProgram: PropTypes.object.isRequired,
};

export default FeeScheduleProgramAttachmentTable;
