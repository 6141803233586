import Notifier from 'common/helpers/Notifier';
import { filter, isEmpty, forEach, find } from 'lodash';
import { coreApi } from 'src/api/config';

const addOONReferral = (caseObj, receiving_program) => {
  const referral = {
    state: 'off_platform',
    case: caseObj.id,
    sending_provider: caseObj.provider.id,
    receiving_provider: receiving_program.provider_id,
    receiving_program: receiving_program.program_id,
    receiving_network: caseObj.network.id,
    sending_network: caseObj.network.id,
  };
  const referralResponse = coreApi.createRecord('referral', referral);
  return referralResponse;
};

const addNote = (caseObj, currentEmployee, oonProvider) => {
  const noteResponse = coreApi.createRecord('note', {
    subjectType: 'case',
    subject: caseObj.id,
    employee: currentEmployee.id,
    visibility: 'public',
    category: 'external_provider',
    text: `Referred off platform to ${oonProvider.name}`,
  });

  return noteResponse;
};

export default function updateOONCaseProgramAndProvider(serviceCase, oonProgramsAndProviders, employee) {
  return async () => {
    try {
      const currentUnlicensedRecipientsIds = serviceCase.referrals
        .filter((r) => !isEmpty(r.receiving_provider.id) && r.state === 'off_platform')
        .map((r) => ({
          program_id: r.receiving_program?.id ?? null,
          provider_id: r.receiving_provider.id,
        }));
      const currentCustomRecipientsNames = serviceCase.referrals
        .filter((n) => !isEmpty(n.category) && n.category === 'external_provider')
        .map((n) => ({
          name: n.receiving_provider.name,
        }));

      const selectedCustomRecipientsNames = oonProgramsAndProviders
        .filter((n) => n.provider_type === 'CUSTOM' && !isEmpty(n.name))
        .map((n) => ({
          name: n.name,
        }));

      const selectedProgramsAndProviders = oonProgramsAndProviders
      .filter((r) => (
        !isEmpty(r.id) && (!isEmpty(r.provider_id) ||
        (!isEmpty(r.provider_type) && r.provider_type !== 'CUSTOM'))
      ))
      .map((r) => ({
        program_id: !r.provider_type ? r.id : null,
        provider_id: r.provider_type ? r.id : r.provider_id,
      }));

      const promisesList = [];
      // r is the referral to be deleted
      // if r has a program id, we need to delete the referral that has the matching program id
      // if r doesnt have a program id, we need to delete the referral that has the matching provider id
      forEach(currentUnlicensedRecipientsIds, (r) => {
        const referralToDelete = !selectedProgramsAndProviders
          .some((recipient) => (
            (r.program_id && recipient.program_id && recipient.program_id === r.program_id) ||
            (!r.program_id && !recipient.program_id && recipient.provider_id === r.provider_id)
          ));
        if (referralToDelete) {
          const FilteredReferral =
          filter(serviceCase.referrals, (referral) => (
            (r.program_id && referral.receiving_program?.id && referral.receiving_program?.id === r.program_id) ||
            (!r.program_id && !referral.receiving_program?.id && referral.receiving_provider.id === r.provider_id)
          ));
          promisesList.push(coreApi.deleteRecord('referral', FilteredReferral[0].id));
        }
      });
      forEach(currentCustomRecipientsNames, (r) => {
        const noteToDelete = !selectedCustomRecipientsNames
          .some((recipient) => (recipient.name === r.name));
        if (noteToDelete) {
          const FilteredNote =
              filter(serviceCase.referrals, (referral) => referral.receiving_provider.name === r.name);
          promisesList.push(coreApi.deleteRecord('note', FilteredNote[0].id));
        }
      });
      forEach(selectedCustomRecipientsNames, (r) => {
        const noteAlreadyExists = find(currentCustomRecipientsNames, { name: r?.name });
        if (!noteAlreadyExists) {
          promisesList.push(addNote(serviceCase, employee, r));
        }
      });
      forEach(selectedProgramsAndProviders, (r) => {
        const referralToCreate = !currentUnlicensedRecipientsIds
          .some((recipient) => (recipient.program_id === r.program_id));
        if (referralToCreate) {
          promisesList.push(addOONReferral(serviceCase, r));
        }
      });

      let payload = [];
      if (promisesList.length) {
        payload = await Promise.all(promisesList);
      }

      Notifier.dispatch(201, 'Case Successfully Updated');
      return payload;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}
