import { get, isEmpty, reduce, uuCompactArrayOrObject } from 'lodash';

const formatGroupsAndPrograms = (groupValues = []) => (
  reduce(groupValues, (acc, group) => {
    const formattedValues = uuCompactArrayOrObject({
      group_id: get(group, 'group.id'),
      program_id: get(group, 'program.id'),
    });
    return isEmpty(formattedValues) ? acc : [...acc, formattedValues];
  }, [])
);

export default formatGroupsAndPrograms;
