import PropTypes from 'prop-types';
import { get } from 'lodash';
import React from 'react';
import { Icon } from '@unite-us/ui';
import EditModal from 'common/modal/EditModal';
import ToolTip from 'common/ToolTip';
import { useFeatureFlag } from 'common/hooks';
import DetailNetworkForDraftReferralForm from './DetailNetworkForDraftReferralForm';

const DetailNetworkForDraftReferral = ({
  invalidateCaseReferrals,
  referral,
  submit,
  modalOpenCallback,
}) => {
  const hasAdaptDraftReferralFlowEnabled = useFeatureFlag('crtb-1239-adapt-draft-referral-flow');
  const ButtonComponent = () => (
    <Icon
      icon="IconInfoCircle"
      className="fill-current text-text-blue"
      ariaLabel="Rejection details"
    />
  );

  const ToolTipContent = () => (
    <p className="bg-white mb-2 w-100 p-4 border border-text-blue border-solid rounded">
      {
        `${get(referral, 'referrals[0].receiving_program.name')} requires authorization
        so the recipient cannot be changed. Please delete this draft and create a
        new referral if you would like to send a referral to a different recipient.`
      }
    </p>
  );

  return (
    <div className="flex items-center space-x-1 edit-draft-recipients">
      { !hasAdaptDraftReferralFlowEnabled && (
      <EditModal
        id="edit-referral-network-modal"
        buttonId="edit-referral-network-btn"
        disabled={referral.service_authorization}
        header="Edit Draft"
        size="large"
        formName="editDetailNetworkField"
        labelText="Edit Recipients"
        removeCloseIcon
        modalOpenCallback={modalOpenCallback}
        dialogContentStyles={{ 'overflow-y': 'visible' }}
      >
        <DetailNetworkForDraftReferralForm
          referral={referral}
          submit={submit}
          invalidateCaseReferrals={invalidateCaseReferrals}
          width={9}
        />
      </EditModal>
      )}
      {
        get(referral, 'referrals[0].receiving_program') && referral.service_authorization && (
          <ToolTip
            buttonClassName="mr-2"
            buttonComponentContent={ButtonComponent}
            enableHover
            panelComponentContent={ToolTipContent}
            panelClassName="z-modal"
            placement="top"
            offsetOptions={{
              offset: [-170, 0],
            }}
            flipOptions={{
              fallbackPlacements: [],
            }}
          />
        )
      }
    </div>
  );
};

DetailNetworkForDraftReferral.propTypes = {
  invalidateCaseReferrals: PropTypes.func.isRequired,
  referral: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  modalOpenCallback: PropTypes.func.isRequired,
};

export default DetailNetworkForDraftReferral;
