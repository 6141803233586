import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { pluralize } from 'humanize-plus';
import { get, isEmpty, isFunction } from 'lodash';
import {
  Button,
  Popover,
} from '@unite-us/ui';
import './ShoppingCart.scss';

class ShoppingCart extends Component {
  constructor(props) {
    super(props);

    this.removeItem = this.removeItem.bind(this);
    this.sendItems = this.sendItems.bind(this);
    this.toggle = this.toggle.bind(this);

    this.state = {
      open: false,
    };
  }

  removeItem(item) {
    this.props.removeItem(item);

    if (this.props.selectedGroups.length === 1) {
      this.toggle();
    }
  }

  sendItems(groupsOptionType) {
    if (isFunction(this.props.addGroups)) {
      this.props.addGroups(this.props.selectedGroups);
    }

    this.props.toggleBrowse(groupsOptionType);
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const {
      buttonStyle,
      selectedGroups,
      serviceType,
      existingSelectedGroups,
      groupsOptionType,
    } = this.props;

    const recipientsLabel = pluralize(selectedGroups.length, 'referral recipient', 'referral recipients');
    const label = `${selectedGroups.length} ${recipientsLabel}`;

    if (isEmpty(selectedGroups)) {
      return null;
    }

    const shoppingCartBtnLabel = existingSelectedGroups ? 'Update' :
      `Add Recipient${selectedGroups.length !== 1 ? 's' : ''}`;

    return (
      <div className="shopping-cart">
        <div className="shopping-cart__anchor" style={{ marginRight: '10px' }}>
          <h4
            id="shopping-cart-anchor"
            onClick={this.toggle}
            role="button"
          >
            {label}
          </h4>
        </div>
        <div className="shopping-cart__add-btn" style={{ display: 'inline' }}>
          <Button
            id="shopping-cart-add-providers-btn"
            style={buttonStyle}
            label={shoppingCartBtnLabel}
            onClick={() => this.sendItems(groupsOptionType)}
            primary
          />
          <Popover
            className="shopping-cart__body"
            placement="bottom-end"
            isOpen={this.state.open}
            target="shopping-cart-anchor"
            toggle={this.toggle}
            body={(
              <div>
                {
                  selectedGroups.map((group, i) => {
                    const primaryText = (
                      <div className="shopping-cart__list-item-primary-text">
                        {get(group, 'name')}
                      </div>
                    );

                    const secondaryText = (
                      <div
                        className="shopping-cart__list-item-secondary-text"
                      >
                        {get(serviceType, 'name', '')}
                      </div>
                    );

                    const rightIconButton = (
                      <Button
                        className="shopping-cart__remove"
                        id={`shopping-cart-item-${i}-remove-btn`}
                        onClick={() => this.removeItem(group)}
                        label="Remove"
                      />
                    );

                    return (
                      <div
                        className="shopping-cart__list-item"
                        id={`shopping-cart-item-${i}`}
                        key={`${i}-${get(group, 'id')}`}
                      >
                        <div>
                          {primaryText}
                          {secondaryText}
                        </div>
                        {rightIconButton}
                      </div>
                    );
                  })
                }
              </div>
            )}
          />
        </div>
      </div>
    );
  }
}

ShoppingCart.propTypes = {
  addGroups: PropTypes.func,
  buttonStyle: PropTypes.object,
  groupsOptionType: PropTypes.bool.isRequired,
  removeItem: PropTypes.func.isRequired,
  selectedGroups: PropTypes.array.isRequired,
  toggleBrowse: PropTypes.func.isRequired,
  serviceType: PropTypes.object,
  existingSelectedGroups: PropTypes.bool.isRequired,
};

ShoppingCart.defaultProps = {
  buttonStyle: {
    backgroundColor: '#0EC400',
    height: '40px',
    fontSize: '14px',
    minWidth: '158px',
    padding: '6px 20px',
  },
  listItemHoverColor: '#FFFFFF',
};

export default ShoppingCart;
