// DEPRECATED UTIL, DO NOT USE. USE APP-COMPONENTS getPreferredProp INSTEAD.
import { get } from 'lodash';
/**
 * Returns a prop by key prefering Redux-form 7 props, redux-form 5 props
 * or manual props in this order.
 * @param {object} props - all PropTypes
 * @param {string} key - the property to look for
 *
 */
export default function getPreferredProp(props, key) {
  var forcedPath = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var propsCopy = props;
  if (forcedPath) {
    propsCopy = get(props, forcedPath, {});
  }
  return get(propsCopy, "input.".concat(key), get(propsCopy, "meta.".concat(key), get(propsCopy, "field.".concat(key), get(propsCopy, key, undefined))));
}