import axios from 'axios';
import { INTEL_URL } from 'src/config/env/env.config';

function fetchViews(params) {
  const instance = axios.create({
    baseURL: INTEL_URL,
  });

  return instance.get('/api/insights/views', {
    params,
  });
}

export default fetchViews;
