import { get, noop } from 'lodash';
import { coreApi } from 'api/config';
import { useDeleteRecord } from 'api/APIHooks';
import Notifier from 'common/helpers/Notifier';

const defaultOnError = () => {
  Notifier.dispatch('error', 'Could not unarchive record.');
};

const useUnarchiveRecord = (recordType, options = {}) => {
  const { deleteRecord } = useDeleteRecord(
    'user_archive',
    {
      mutationConfig: {
        onSuccess: options.onSuccess || noop,
        onError: options.onError || defaultOnError,
      },
      api: 'coreApi',
    },
  );

  return async ({ recordId }) => {
    try {
      const record = await coreApi.findRecord(recordType, recordId);
      const archiveId = get(record, 'data.data.user_archives.0.id');
      return await deleteRecord(archiveId, {});
    } catch (error) {
      if (window.Rollbar) {
        window.Rollbar.error('Failed to unarchive record', { error });
      }
      return error;
    }
  };
};

export default useUnarchiveRecord;
