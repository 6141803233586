import { FIND_CONTACT_DOCUMENTS } from 'actions';

export default function findContactDocuments(contactId, documentSearch) {
  return (dispatch) => {
    dispatch({
      type: FIND_CONTACT_DOCUMENTS,
      documentSearch,
      contactId,
    });
    return Promise.resolve(contactId);
  };
}
