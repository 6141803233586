import { createHashedFingerprint } from 'common/utils/Rollbar';
import BLACKLISTED_HASHES from './blacklistedHashes';

const rollbarCheckIgnore = (isUncaught, args, payload) => {
  try {
    const fingerprint = createHashedFingerprint(payload);

    return BLACKLISTED_HASHES.includes(fingerprint);
  } catch (e) {
    // If here most likely there was an error from createHashedFingerprint
    // Catch it and move on, let Rollbar do it's thing
  }
  return false;
};

export default rollbarCheckIgnore;
