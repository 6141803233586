import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { MuiApp } from 'common/mui';
import { browserHistory } from 'common/utils/browserHistory';
import Notifications from 'common/utils/Notifications';
import { isCoordinationGroup } from 'common/utils/utils';
import SystemNotifications from 'common/utils/SystemNotifications';
import NavBar from 'src/components/Navigation';
import { AppCreateReferralProvider } from '@unite-us/app-create-referral';
import {
  clearZendeskReturnToUrl,
  setIsCoordinationGroup,
} from 'common/utils/Session/actions';
import { fetchZendeskURL } from 'actions/Zendesk';
// import { fetchProviderTags } from 'actions/Tags';
import fetchAccountRoles from 'src/actions/AccountRoles/fetchAccountRoles';
import { SelectedProgramsProvider } from '@unite-us/app-search';

class GroupWrapper extends Component {
  constructor(props) {
    super(props);
    this.redirectToZendesk = this.redirectToZendesk.bind(this);
    this.setCurrentGroup = this.setCurrentGroup.bind(this);
  }

  componentDidMount() {
    const { currentGroup, zendeskReturnToUrl, currentUser } = this.props;
    const group = _.get(currentGroup, 'group', {});
    const userId = _.get(currentUser, 'id', '');

    this.props.fetchAccountRoles(userId);

    this.setCurrentGroup(group);
    if (zendeskReturnToUrl) {
      this.redirectToZendesk(zendeskReturnToUrl);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.session.groupId !== this.props.session.groupId) {
      this.setCurrentGroup(nextProps.currentGroup.group);
      browserHistory.push('/');
    }
    if (nextProps.zendeskReturnToUrl && !this.props.zendeskReturnToUrl) {
      this.redirectToZendesk(nextProps.zendeskReturnToUrl);
    }
  }

  setCurrentGroup(group) {
    const value = isCoordinationGroup(group);
    this.props.setIsCoordinationGroup(value);
    // this.props.fetchProviderTags(group.id);
  }

  redirectToZendesk(zendeskReturnToUrl) {
    this.props.fetchZendeskURL()
      .then((data) => {
        const { zendesk_url } = data.data;
        window.location = `${zendesk_url}&return_to=${zendeskReturnToUrl}`;
      });
    this.props.clearZendeskReturnToUrl();
  }

  render() {
    const { children } = this.props;
    return (
      <MuiApp {...this.props}>
        <Notifications />
        <NavBar
          routes={this.props.routes}
          setGroupId={this.props.setGroupId}
          selectedGroupId={this.props.session.groupId}
          urlPath={this.props.urlPath}
        />
        <SystemNotifications />
        <main>
          <SelectedProgramsProvider>
            <AppCreateReferralProvider>
              {children}
            </AppCreateReferralProvider>
          </SelectedProgramsProvider>
        </main>
      </MuiApp>
    );
  }
}

GroupWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  session: PropTypes.object.isRequired,
  setGroupId: PropTypes.func.isRequired,
  setIsCoordinationGroup: PropTypes.func.isRequired,
  currentGroup: PropTypes.object,
  urlPath: PropTypes.string,
  routes: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  zendeskReturnToUrl: PropTypes.string,
  clearZendeskReturnToUrl: PropTypes.func.isRequired,
  // fetchProviderTags: PropTypes.func.isRequired,
  fetchZendeskURL: PropTypes.func.isRequired,
  fetchAccountRoles: PropTypes.func.isRequired,
};

GroupWrapper.defaultProps = {
  zendeskReturnToUrl: '',
  currentGroup: null,
  urlPath: '',
};

function mapStateToProps(state, ownProps) {
  const urlPath = ownProps.location.pathname;
  return {
    urlPath,
    session: state.session,
    currentUser: state.user,
    currentGroup: state.user.groups.find((data) => data.group.id === state.session.groupId),
    featureFlags: state.flags,
    zendeskReturnToUrl: state.session.zendeskReturnToUrl,
  };
}

export default connect(mapStateToProps, {
  setIsCoordinationGroup,
  clearZendeskReturnToUrl,
  // fetchProviderTags,
  fetchZendeskURL,
  fetchAccountRoles,
})(GroupWrapper);
