import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import ReferralServiceDetails from './ReferralServiceDetails';
import ReferralServiceMinimized from './ReferralServiceMinimized';
import { getReferralDetailsFromForm } from '../../utils';
import { getPermittedOutsideNetworks } from '../../utils/scopes';

const ReferralServicesReview = ({
  expandAllReferralSections,
  fields: { services },
  globalServiceTypes,
  networks,
  onDelete,
  onEdit,
  referralScopes,
  isProgramBasedSearch,
}) => {
  const referrals = services.map((service) => getReferralDetailsFromForm({
    fields: service,
    networks: [
      ...networks,
      ...getPermittedOutsideNetworks(referralScopes, service.referred_by_network.value.id),
    ],
    serviceTypes: globalServiceTypes,
    isProgramBasedSearch,
  }));

  return (
    <ul className="referral-services-review service-type-list list-unstyled">
      {referrals.map((referral, index) => (
        <li key={`service-type-${index}`}>
          <ReferralServiceMinimized
            initiallyCollapsed={!expandAllReferralSections && index !== 0}
            index={index}
            onDelete={onDelete}
            onEdit={onEdit}
            referral={referral}
          >
            <ReferralServiceDetails
              referral={referral}
              documents={[
                ...map(referral.documents, (doc) => ({ title: doc.name })),
                ...(referral.attachedDocuments || []),
              ]}
              isProgramBasedSearch={isProgramBasedSearch}
            />
          </ReferralServiceMinimized>
        </li>
      ))}
    </ul>
  );
};

ReferralServicesReview.propTypes = {
  expandAllReferralSections: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    services: PropTypes.arrayOf(
      PropTypes.shape({
        attachedDocuments: PropTypes.array,
        documents: PropTypes.object, // FileList
      }).isRequired,
    ),
  }).isRequired,
  globalServiceTypes: PropTypes.array.isRequired,
  isProgramBasedSearch: PropTypes.bool.isRequired,
  networks: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  referralScopes: PropTypes.array.isRequired,
};

export default ReferralServicesReview;
