import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './SecondaryDetail.scss';

function SecondaryDetail(props) {
  const { id, label, children } = props;

  if (_.isEmpty(children)) {
    return null;
  }

  return (
    <div className="secondary-details">
      <label htmlFor={id}>{label}</label>
      {
        _.isString(children) ? (
          <p id={id}>
            {children}
          </p>
        ) : (
          <div id={id} className="content">
            {children}
          </div>
        )
      }
    </div>
  );
}

SecondaryDetail.propTypes = {
  children: PropTypes.any,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default SecondaryDetail;
