import {
  CREATE_OON_GROUP,
  SEARCH_NETWORK_GROUPS,
  SEARCH_NETWORK_GROUPS_BY_REFERRAL_SCOPE,
  FETCH_NETWORK_GROUP,
  FETCH_NETWORK_SERVICE_TYPES,
  SET_NETWORK_GROUPS_FETCHING,
  UNSET_NETWORK_GROUPS_FETCHING,
  CLEAR_NETWORK_GROUP_SEARCH,
  SET_NETWORK_GROUP_FETCHING,
  UNSET_NETWORK_GROUP_FETCHING,
  CLEAR_NETWORK_GROUP,
  CLEAR_NETWORK_GROUPS,
  FETCH_NETWORK_USERS_STARTED,
  FETCH_NETWORK_USERS_ERROR,
  FETCH_NETWORK_USERS_SUCCESS,
  SET_CURRENT_NETWORK,
  FETCH_NETWORK_GROUP_PROGRAMS,
  UPDATE_OON_GROUP,
} from 'actions';
import _ from 'lodash';
import { replaceObjectInArrayById } from 'common/utils/stateHelpers';
import { OON_GROUP_TYPE } from 'src/components/Groups/constants';

export const defaultState = {
  cancel: null,
  currentGroupPrograms: [],
  groups: {},
  groupsPaging: {},
  groupSearchParams: {
    q: {
      name: '',
      service_type_ids: [],
    },
    sord: 'asc',
  },
  isGroupFetching: false,
  isFetching: false,
  isUserFetching: false,
  networkId: '',
  networkGroupsByReferralScopes: {
    groups: {},
    groupsPaging: {},
    groupSearchParams: {
      q: {
        name: '',
        service_type_ids: [],
      },
      sord: 'asc',
    },
  },
  users: [],
  usersPaging: {},
  userSearchParams: {},
  selectedGroup: {},
  serviceTypes: [],
};

function buildNewUsersArray(action) {
  const newUsers = _.get(action, 'payload.data.data', []);
  return [...newUsers];
}

export default function networksReducer(state = defaultState, action = {}) {
  const {
    cancel,
    networkId,
    payload,
    searchParams,
    shouldCancel,
    type,
  } = action;

  switch (type) {
    case SET_CURRENT_NETWORK:
      if (state.networkId !== networkId) {
        return {
          ...defaultState,
          networkId,
        };
      }
      return state;

    case CLEAR_NETWORK_GROUP:
      return {
        ...state,
        selectedGroup: defaultState.selectedGroup,
        currentGroupPrograms: defaultState.currentGroupPrograms,
      };

    case CLEAR_NETWORK_GROUPS:
      return {
        ...state,
        groups: defaultState.groups,
        groupsPaging: defaultState.groupsPaging,
        groupSearchParams: defaultState.groupSearchParams,
        selectedGroup: defaultState.selectedGroup,
      };

    case CLEAR_NETWORK_GROUP_SEARCH:
      return {
        ...state,
        groupSearchParams: defaultState.groupSearchParams,
      };

    case SET_NETWORK_GROUP_FETCHING:
      return { ...state, isGroupFetching: true };
    case UNSET_NETWORK_GROUP_FETCHING:
      return { ...state, isGroupFetching: false };

    case FETCH_NETWORK_USERS_STARTED:
      return { ...state, isUserFetching: true };

    case FETCH_NETWORK_USERS_ERROR:
      return { ...state, isUserFetching: false };

    case SET_NETWORK_GROUPS_FETCHING:
      if (shouldCancel && state.cancel) {
        state.cancel();
      }
      return { ...state, cancel, isFetching: true };
    case UNSET_NETWORK_GROUPS_FETCHING:
      return { ...state, cancel: null, isFetching: false };

    case SEARCH_NETWORK_GROUPS: {
      const page = _.get(payload, 'data.paging.current_page');
      return {
        ...state,
        groups: {
          ...state.groups,
          [page]: _.get(payload, 'data.data', []),
        },
        groupsPaging: _.get(payload, 'data.paging'),
        groupSearchParams: {
          ...searchParams,
          q: {
            ..._.get(state, 'groupSearchParams.q', {}),
            ..._.get(searchParams, 'q', {}),
          },
        },
      };
    }

    case SEARCH_NETWORK_GROUPS_BY_REFERRAL_SCOPE: {
      const page = _.get(payload, 'data.paging.current_page');

      return {
        ...state,
        networkGroupsByReferralScopes: {
          groups: {
            ...state.networkGroupsByReferralScopes.groups,
            [page]: _.get(payload, 'data.data', []),
          },
          groupsPaging: _.get(payload, 'data.paging'),
          groupSearchParams: {
            ...searchParams,
            q: {
              ..._.get(state, 'networkGroupsByReferralScopes.groupSearchParams.q', {}),
              ..._.get(searchParams, 'q', {}),
            },
          },
        },
      };
    }

    case FETCH_NETWORK_USERS_SUCCESS:
      return {
        ...state,
        isUserFetching: false,
        users: buildNewUsersArray(action, state),
        userSearchParams: searchParams,
        usersPaging: _.get(payload, 'data.paging'),
      };

    case FETCH_NETWORK_GROUP:
      return {
        ...state,
        selectedGroup: _.get(payload, 'data.data', {}),
      };

    case FETCH_NETWORK_GROUP_PROGRAMS:
      return {
        ...state,
        currentGroupPrograms: _.get(payload, 'data.data', []),
      };

    case FETCH_NETWORK_SERVICE_TYPES:
      return {
        ...state,
        serviceTypes: _.get(payload, 'data.data', []),
      };

    case CREATE_OON_GROUP: {
      const newGroup = {
        ..._.get(payload, 'data', {}),
        group_type: OON_GROUP_TYPE,
      };
      const currentPage = _.get(state, 'networkGroupsByReferralScopes.groupsPaging.current_page', 1);
      const currentPageOfGroups = _.wget(state, `networkGroupsByReferralScopes.groups.${currentPage}`);
      return {
        ...state,
        selectedGroup: newGroup,
        networkGroupsByReferralScopes: {
          groups: {
            ...state.networkGroupsByReferralScopes.groups,
            // Add the new group to the top of the current page of groups.
            [currentPage]: [newGroup, ...currentPageOfGroups],
          },
        },
      };
    }

    case UPDATE_OON_GROUP: {
      const updatedGroup = {
        ..._.get(payload, 'data', {}),
        group_type: OON_GROUP_TYPE,
      };
      const currentPage = _.get(state, 'networkGroupsByReferralScopes.groupsPaging.current_page', 1);
      const currentPageOfGroups = _.wget(state, `networkGroupsByReferralScopes.groups.${currentPage}`);
      return {
        ...state,
        selectedGroup: updatedGroup,
        networkGroupsByReferralScopes: {
          groups: {
            ...state.networkGroupsByReferralScopes.groups,
            [currentPage]: replaceObjectInArrayById(updatedGroup, currentPageOfGroups),
          },
        },
      };
    }

    default:
      return state;
  }
}
