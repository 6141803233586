import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Icon,
} from '@unite-us/ui';
import { Location } from '@unite-us/client-utils';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { theme } from 'src/../tailwind.config';

const getWarningText = (requireLocation, requireLocationOrServiceArea) => {
  if (requireLocation) {
    return 'Please select at least one location for this program';
  }
  if (requireLocationOrServiceArea) {
    return 'Please select at least one location or service area for this program';
  }
  return '';
};

const ProgramLocationsForm = (props) => {
  const {
    values,
    serviceAreaSupportForOrgsFlag,
  } = props;

  const delivery_options = values?.delivery_options || {};
  const requireLocationOrServiceArea =
    serviceAreaSupportForOrgsFlag &&
    Object.keys(delivery_options).some((option) => option !== 'in_office' && delivery_options[option]) &&
    !(values?.locations?.some((s) => s.selected) ||
      values?.service_areas_national?.selected ||
      values?.service_areas_states?.some((s) => s.selected));
  const requireLocation =
    serviceAreaSupportForOrgsFlag &&
    Object.keys(delivery_options).some((option) => option === 'in_office' && delivery_options[option]) &&
    !values?.locations?.some((s) => s.selected);

  const locationTitleColor = (requireLocation || requireLocationOrServiceArea) ? 'text-red' : 'title';
  const warningText = getWarningText(requireLocation, requireLocationOrServiceArea);

  return (
    <Card className="mt-6 rounded border-dark-border-blue">
      <div className="p-4 border-b border-solid border-gray-400 bg-white-important">
        <h2 className="pb-2">Program Locations</h2>
        <span className="text-dark-grey">Locations are physical addresses where
          your clients can access this program.
        </span>
      </div>
      <FieldArray name="locations">
        {({ fields, meta: { touched, error } }) => {
          const numSelected = (fields.value || [])
            .filter((field) => Boolean(field.selected)).length;

          return (
            <fieldset>
              <div className="px-8 py-10 bg-light-border-grey">
                <legend className="ui-form-field__label" data-testid="select-locations-title">
                  <h4 className={locationTitleColor}>Select Locations<span className="text-red pl-2">*</span></h4>
                </legend>
                <span className="text-dark-grey">You can only select locations
                  that have already been added to your organization. To add a new
                  location, go to your organization’s About section.
                </span>
                <a
                  href="https://support.uniteus.io/en/articles/5970542-create-a-program-without-a-physical-location"
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}What if this program does not have a physical location?
                </a>
                {
                  (requireLocation || requireLocationOrServiceArea) &&
                  <p className="text-red mt-2">{warningText}</p>
                }
                {touched && error ? <div className="text-red pt-2 px-4">{error}</div> : null}
                <div className="pt-4 pb-10 bg-light-border-grey rounded">
                  <div className="pb-2" data-test-element="num-selected">{numSelected} Locations Selected</div>
                  <div
                    style={{ maxHeight: '400px' }}
                    className="overflow-y-auto w-full
            border border-solid border-gray-400 divide-y divide-solid divide-gray-400 rounded"
                  >
                    {fields.map((name) => (
                      <Field
                        key={name}
                        name={name}
                        data-test-element={name}
                      >
                        {(params) => {
                          const { input: { value, onChange: onInputChange } } = params;

                          const handleToggleLocation = useCallback((event) => {
                            event.preventDefault();
                            const toggledValue =
                              { ...value, selected: !value.selected, submitChange: !value.submitChange };
                            if (!value.selected || (value.selected && numSelected > 1)) onInputChange(toggledValue);
                          }, [value, numSelected, onInputChange]);

                          return (
                            <div
                              key={name}
                              className={`p-4 flex justify-between cursor-pointer 
                                ${value.selected ? 'bg-light-fill-blue' : 'bg-white'}`}
                              onClick={handleToggleLocation}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  handleToggleLocation(event);
                                }
                              }}
                              name={value.selected ? 'Remove Location' : 'Add Location'}
                              role="button"
                              tabIndex={0}
                              {...params}
                            >
                              <Location
                                stopEventPropagation
                                clickableAddress
                                location={value.location}
                                showMore
                              />
                              <div className="flex items-center">
                                {value.selected ?
                                  (
                                    <Icon
                                      style={{
                                        svg:
                                          {
                                            height: '20px',
                                            width: '20px',
                                            fill: theme.extend.colors['action-blue'],
                                          },
                                      }}
                                      icon="IconCheckCircle"
                                    />
                                  ) :
                                  (
                                    <Icon
                                      style={{
                                        svg:
                                          {
                                            height: '18px',
                                            width: '18px',
                                            fill: theme.extend.colors['action-blue'],
                                          },
                                      }}
                                      className={'text-action-blue'}
                                      icon="IconPlusCircle"
                                    />
                                  )}

                              </div>
                            </div>
                        );
                    }}
                      </Field>
                  ))}
                  </div>
                </div>
              </div>
            </fieldset>
          );
        }}
      </FieldArray>
    </Card>
  );
};

ProgramLocationsForm.propTypes = {
  values: PropTypes.shape({
    delivery_options: PropTypes.array,
    locations: PropTypes.array,
    service_areas_national: PropTypes.array,
    service_areas_states: PropTypes.array,
  }),
  serviceAreaSupportForOrgsFlag: PropTypes.bool,
};

ProgramLocationsForm.defaultProps = {
  values: {
    delivery_options: [],
    locations: [],
  },
  serviceAreaSupportForOrgsFlag: false,
};

export default ProgramLocationsForm;
