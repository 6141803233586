import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Icon } from '@unite-us/app-components';
import PaginationLoader from 'common/PaginationLoader';
import { SERVICE_AREA_TYPE_NATIONAL, SERVICE_AREA_TYPE_STATE } from 'components/Groups/constants';
import './GroupList.scss';
import { GroupListItem } from './components';

function createGroupListItemList(
  list,
  center,
  selectedGroupFromMarker,
  selectedGroups,
  selectedServices,
  selectGroup,
  serviceType,
  toggleGroupDetail,
  serve,
  stateDisplayName,
  toggleProviderHover,
) {
  const bothServiceAreaServedIds = {};
  return _.map(list, (group, i) => {
    const selected = _.some(selectedGroups, { id: group.id });
    let serviceAreaServed = group?.service_area_served;
    if (group?.service_area_served === 'both') {
      serviceAreaServed = bothServiceAreaServedIds[group.id] ?
        SERVICE_AREA_TYPE_NATIONAL :
        SERVICE_AREA_TYPE_STATE;
      bothServiceAreaServedIds[group.id] = true;
    }
    return (
      <GroupListItem
        center={center}
        key={group.id}
        id={`browse-item-${i}${serve ? '-serve' : ''}`}
        group={group}
        serviceType={serviceType}
        toggleGroupDetail={(selectedGroup) => {
          toggleGroupDetail(selectedGroup, serviceAreaServed);
        }}
        toggleProviderHover={toggleProviderHover}
        selected={selected}
        selectedGroupFromMarker={selectedGroupFromMarker}
        selectedServices={selectedServices}
        selectGroup={selectGroup}
        serve={serve}
        stateDisplayName={stateDisplayName}
        serviceAreaServed={serviceAreaServed}
      />
    );
  });
}

export const GroupList = (props) => {
  const {
    center,
    list,
    loading,
    selectedGroupFromMarker,
    selectedGroups,
    selectedServices,
    selectGroup,
    serviceType,
    toggleGroupDetail,
    title,
    appendData,
    emptyMessage,
    paging,
    serviceAreaSupportForOrgsFlag,
    serve,
    stateDisplayName,
    toggleProviderHover,
  } = props;

  let notValues;
  if (!loading && _.isEmpty(list)) {
    notValues = (
      <div className="group-list group-list--empty">
        <Icon icon="ExclamationCircle" size={60} />
        <h4 className="group-list__empty-text h4-light">
          There are no organizations that match your search criteria. Please try
          adjusting the filters and search again.
        </h4>
      </div>
    );
  }

  const [open, setOpen] = useState(true);

  return (
    <div>
      {title && (
        <div
          className="group_list_title"
          onClick={() => setOpen(!open)}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.key === 'Enter' && setOpen(!open)}
        >
          {title}
          <Icon
            className="ml-2"
            icon="ChevronArrow"
            direction={open ? 'up' : 'down'}
            style={{ svg: { fill: 'white' } }}
          />
        </div>
      )}
      {open && (
        <>
          {notValues}
          <ul className="group-list list-unstyled">
            {createGroupListItemList(
              list,
              center,
              selectedGroupFromMarker,
              selectedGroups,
              selectedServices,
              selectGroup,
              serviceType,
              toggleGroupDetail,
              serve,
              stateDisplayName,
              toggleProviderHover,
            )}
          </ul>
          <div className="browse__content-list-pagination">
            <PaginationLoader
              appendData={() => appendData()}
              emptyMessage={emptyMessage}
              isFetching={loading}
              paging={paging}
              serviceAreaSupportForOrgsFlag={serviceAreaSupportForOrgsFlag}
            />
          </div>
        </>
      )}
    </div>
  );
};

GroupList.propTypes = {
  center: PropTypes.object,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
  loading: PropTypes.bool,
  selectedGroupFromMarker: PropTypes.string,
  selectedGroups: PropTypes.array,
  selectedServices: PropTypes.array,
  selectGroup: PropTypes.func,
  serviceType: PropTypes.object,
  toggleGroupDetail: PropTypes.func,
  toggleProviderHover: PropTypes.func.isRequired,
  title: PropTypes.string,
  serviceAreaSupportForOrgsFlag: PropTypes.bool,
  appendData: PropTypes.func,
  emptyMessage: PropTypes.string,
  paging: PropTypes.object.isRequired,
  serve: PropTypes.bool,
  stateDisplayName: PropTypes.string,
};

GroupList.defaultProps = {
  center: {},
  list: [],
  loading: false,
  selectedGroupFromMarker: '',
  selectedGroups: [],
  selectedServices: [],
  selectGroup: _.noop,
  appendData: _.noop,
  emptyMessage: '',
  serviceType: {},
  toggleGroupDetail: _.noop,
  serve: false,
  serviceAreaSupportForOrgsFlag: false,
  stateDisplayName: '',
};

export default GroupList;
