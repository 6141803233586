import PropTypes from 'prop-types';
import React from 'react';
import { Filter } from '@unite-us/client-utils';
import { connect } from 'react-redux';
import { NETWORK_SCOPE_OPTIONS } from 'src/components/Browse/utils/filters/constants';
import {
  uup459SupersetPhase2,
} from 'common/utils/FeatureFlags/flags';

const NetworkActionsBarFilters = ({
  hideNetworkFilter,
  hideServiceTypeFilter,
  networkOptions,
  onNetworkFilterChange,
  onNetworkScopeFilterChange,
  onServiceTypeFilterChange,
  serviceTypeOptions,
  isSupersetEnabled,
}) => (
  <div className="network-actions-bar-filters">
    <div className="network-actions-bar-filters__dropdown-filters">
      {
        !hideServiceTypeFilter && (
          <Filter
            hideSelectAll
            id="service-types"
            name="Service Type"
            onFiltersChange={onServiceTypeFilterChange}
            options={serviceTypeOptions}
            printRender
          />
        )
      }
      {!isSupersetEnabled && !hideNetworkFilter && (
        <Filter
          hideSelectAll
          isSingleOptionSelect
          id="networks"
          name="Network"
          onFiltersChange={onNetworkFilterChange}
          options={networkOptions}
        />
        )}
      {
        <Filter
          id="network-scopes"
          name="Network Scope"
          onFiltersChange={onNetworkScopeFilterChange}
          options={NETWORK_SCOPE_OPTIONS}
        />
      }
    </div>
  </div>
);

NetworkActionsBarFilters.propTypes = {
  hideServiceTypeFilter: PropTypes.bool,
  hideNetworkFilter: PropTypes.bool,
  networkOptions: PropTypes.array.isRequired,
  onNetworkFilterChange: PropTypes.func.isRequired,
  onNetworkScopeFilterChange: PropTypes.func.isRequired,
  onServiceTypeFilterChange: PropTypes.func.isRequired,
  serviceTypeOptions: PropTypes.array.isRequired,
  isSupersetEnabled: PropTypes.bool.isRequired,
};

NetworkActionsBarFilters.defaultProps = {
  hideServiceTypeFilter: false,
  hideNetworkFilter: false,
};

function mapStateToProps(state) {
  return {
    isSupersetEnabled: uup459SupersetPhase2(state),
  };
}

export default connect(mapStateToProps)(NetworkActionsBarFilters);
