import { merge } from 'lodash';
import buildOptionalInsuranceEntryParams from 'src/components/Insurance/utils/buildOptionalInsuranceEntryParams';

export default function buildEditMedicalInsuranceParams({
  values,
  contactId,
  groupId,
}) {
  const medicalInsuranceParams = {
    group_id: groupId,
    data: {
      attributes: {
        external_member_id: values.insurance.member_id,
      },
      relationships: {
        person: {
          data: {
            id: contactId,
            type: 'person',
          },
        },
      },
    },
  };

  const optionalParams = buildOptionalInsuranceEntryParams(values);
  const paymentsFormParams = merge(medicalInsuranceParams, optionalParams);
  return paymentsFormParams;
}
