import { hasUserRole } from '.';

export function isCaseManager(user, groupId) {
  return hasUserRole({
    groupId,
    name: 'Case Manager',
    roleType: 'primary',
    user,
  });
}

export default isCaseManager;
