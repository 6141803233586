import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { browserHistory } from 'common/utils/browserHistory';
import DateOfBirth from 'common/display/DateOfBirth';
import { validateReduxForm } from 'common/form';
import {
  Button,
} from '@unite-us/ui';
import { getEnumsFromState } from 'common/utils/Enums';
import '../stylesheets/contactStepper.scss';
import Header from './Header';
import ContactDetailReviewDemographic from './ContactDetailReviewDemographic';
import ContactDataAsDisplay from './ContactDataAsDisplay';

class ContactDetailReviewFromReferral extends Component {
  static restartReferralProcess() {
    browserHistory.push('/referrals/new/search');
  }

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.complete = this.complete.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { contact } = this.props;
    if (_.isEmpty(contact)) {
      browserHistory.push('/referrals/new/search');
    }
  }

  onSubmit() {
    this.complete();
  }

  complete() {
    this.props.onComplete();
  }

  render() {
    const {
      handleSubmit,
      submitting,
      contact,
      header,
      mainHeader,
      subHeader,
      enums,
    } = this.props;

    if (_.isEmpty(contact)) {
      return null;
    }

    return (
      <form
        className="contact-details-review"
        onSubmit={handleSubmit(this.onSubmit)}
      >
        <Header
          header={header}
          mainHeader={mainHeader}
          subHeader={subHeader}
        />

        <div className="confirmed-details">
          <div className="confirmed-details__wrapper">
            <div className="confirmed-details__name confirmed-details__row">
              <span className="strong">Name: </span>
              <span> {contact.full_name}</span>
            </div>

            <div className="confirmed-details__dob confirmed-details__row">
              <span className="strong">Date of Birth</span>
              <div>
                <DateOfBirth date={contact.date_of_birth} />
              </div>
            </div>

            <div className="confirmed-details__contact-data confirmed-details__row">
              <ContactDataAsDisplay fields={this.props.fields} />
            </div>

            <ContactDetailReviewDemographic enums={enums} fields={this.props.fields} />
          </div>
        </div>

        <footer className="referral-footer">
          <Button
            id="go-back-btn"
            onClick={ContactDetailReviewFromReferral.restartReferralProcess}
            label="Go Back"
            style={{ marginRight: '10px' }}
          />
          <Button
            id="next-btn"
            onClick={handleSubmit(this.onSubmit)}
            label="Next"
            disabled={submitting}
            style={{ float: 'right' }}
            primary
          />
        </footer>
      </form>
    );
  }
}

ContactDetailReviewFromReferral.propTypes = {
  contact: PropTypes.object.isRequired,
  enums: PropTypes.object,
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  header: PropTypes.string.isRequired,
  mainHeader: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
  subHeader: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
};

function mapStateToProps(state) {
  const { selectedContact, session: { groupId } } = state;
  const allContacts = [...state.searchedContacts, ...state.contacts.contacts];
  const contact = _.find(allContacts, { id: selectedContact }) || {};

  const findPrimary = (items = []) => (
    _.find(items, { is_primary: true }) || {}
  );

  return {
    groupId,
    selectedContact,
    contact,
    enums: getEnumsFromState(state),
    initialValues: {
      email: findPrimary(contact.email_addresses).email_address,
      phone: findPrimary(contact.phone_numbers).phone_number,
      phone_type: findPrimary(contact.phone_numbers).phone_type,
      gender: contact.gender,
      marital_status: contact.marital_status,
      race: contact.race,
      ethnicity: contact.ethnicity,
    },
  };
}

export const fields = [
  'email',
  'ethnicity',
  'gender',
  'marital_status',
  'phone',
  'phone_type',
  'race',
];
export default validateReduxForm(
  {
    form: 'contact',
    fields,
    destroyOnUnmount: true,
  },
  mapStateToProps,
  null,
)(ContactDetailReviewFromReferral);
