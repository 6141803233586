// DEPRECATED COMPONENT, DO NOT USE. USE APP-COMPONENTS PageSizeSelector INSTEAD.
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { SelectField } from 'components/Backoffice/form/SelectField';
import { PAGE_SIZE_OPTIONS } from './constants';

const PageSizeSelector = ({
  className, pageSize, setPageSize, setPageNumber,
}) => {
  const options = PAGE_SIZE_OPTIONS.map((val) => ({ label: val.toString(), value: val }));
  const setNewPageSize = (value) => {
    setPageSize(value);
    setPageNumber(1);
  };
  return (
    <SelectField
      className={cx('flex items-center space-x-2', className)}
      listBoxUpOptionsClassName="absolute bottom-46 outline-solid focus:outline-solid"
      label={(
        <span>
          Show
          <span className="inset-0 sr-only">items per page</span>
        </span>
      )}
      onChange={({ value }) => setNewPageSize(value)}
      options={options}
      value={pageSize}
      truncateOptions={false}
    />
  );
};

PageSizeSelector.propTypes = {
  className: PropTypes.string,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func.isRequired,
};

PageSizeSelector.defaultProps = {
  className: '',
};

export default PageSizeSelector;
