import PropTypes from 'prop-types';
import React from 'react';
import { BasicTable } from 'common/tables';

const DetailLabelContent = ({ content }) => (
  <div className="detail-label-content">
    <BasicTable data={content} type="detail-page" />
  </div>
);

DetailLabelContent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.node,
    value: PropTypes.node,
    italicize: PropTypes.bool,
  }).isRequired),
};

export default DetailLabelContent;
