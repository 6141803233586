const formatFileType = (fileId, metadata, metafields) => {
  const metadataMatch = metadata.find((item) => {
    const value = item.value;
    // array of fileIds is expected for metadata field
    return Array.isArray(value) && value.includes(fileId);
  });
  const metafieldMatch = metadataMatch && metafields && metafields.find((mf) => mf.field === metadataMatch.field);

  return metafieldMatch !== undefined ? metafieldMatch.label : '';
};

export default formatFileType;
