import React from 'react';
import PropTypes from 'prop-types';
import { round } from 'lodash';

const LegendRowPct = ({
  value, total, disabled, showValue,
}) => {
  if (!showValue) {
    return null;
  }
  const pct = `(${round((value / total) * 100)}%)`;
  return (
    <td className="legend-row-pct">
      {disabled ? null : pct}
    </td>
  );
};

LegendRowPct.propTypes = {
  disabled: PropTypes.bool.isRequired,
  showValue: PropTypes.bool.isRequired,
  total: PropTypes.number,
  value: PropTypes.number,
};

export default LegendRowPct;
