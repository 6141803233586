import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Disclosure } from '@headlessui/react';
import { Icon } from '@unite-us/ui';
import { theme } from 'src/../tailwind.config';
import './ExpandableChoiceList.scss';

export const ExpandableChoiceList = ({
  optionsList,
  onClick,
  numberedList,
  displayChoiceCount,
}) => {
  const [selectedChoice, setSelectedChoice] = useState('');
  return (
    <div id="expandable-choice-list" className="w-full px-2 pt-2">
      <div className="mx-1 w-full p-2">
        {
          optionsList.map((option, optionIndex) => (
            <Disclosure key={option.id}>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className="flex w-full py-2 text-left"
                  >
                    <span
                      role="img"
                      className={`flex justify-content-center mt-1 h-7 w-7 mr-2 ${!option.choices && 'invisible'}`}
                    >
                      <Icon
                        color={theme.extend.colors['action-blue']}
                        icon={open ? 'IconChevronDown' : 'IconChevronRight'}
                      />
                    </span>
                    <h1 className="pl-1">
                      {numberedList ? `${optionIndex + 1}. ${option.title}` : option.title}
                    </h1>
                  </Disclosure.Button>
                  {
                    option.choices && option.choices.map((choice) => (
                      <Disclosure.Panel key={choice.id} className="pl-3 ml-4 w-full">
                        <button
                          id={selectedChoice === choice.id ? 'active' : undefined}
                          type="button"
                          className="p-1 my-1 ml-6"
                          onClick={() => {
                          setSelectedChoice(choice.id);
                          onClick(choice, option, optionIndex);
                          }}
                        >
                          <h2 className={`text-left ${selectedChoice === choice.id && 'text-white'}`}>
                            {(choice.text) + (displayChoiceCount ? ` (${choice.count})` : '')}
                          </h2>
                        </button>
                      </Disclosure.Panel>
                    ))
                  }
                </>
              )}
            </Disclosure>
          ))
          }
      </div>
    </div>
  );
};

ExpandableChoiceList.propTypes = {
  optionsList: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  numberedList: PropTypes.bool,
  displayChoiceCount: PropTypes.bool,
};

ExpandableChoiceList.defaultProps = {
  numberedList: false,
  displayChoiceCount: false,
};

export default ExpandableChoiceList;
