import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  Menu,
  Badge,
} from '@unite-us/ui';
import { useUpdateRecord } from 'src/api/APIHooks';
import { useFindEmployee } from 'src/components/Organization/api/hooks/v1/employeeHooks';
import { useFindEmployeeNotifications } from 'src/components/Organization/api/hooks/v1/employeeNotificationHooks';
import { hasFeatureRole } from 'common/utils/Roles/hasFeatureRole';
import EmployeeNotificationsContainer from './EmployeeNotificationsContainer';

export const EmployeeNotificationMenu = ({ currentEmployee, showTasks }) => {
  const { updateRecord } = useUpdateRecord('employee');
  const { refetch: refetchCurrentEmployee, data: employee } = useFindEmployee(currentEmployee.id);

  const hasPayerInvoicesRole = hasFeatureRole({
    employeeRoles: currentEmployee?.roles,
    targetRoleKey: 'payer_invoices',
  });
  const { data: allNotifications } = useFindEmployeeNotifications(currentEmployee.id, showTasks);

  const userNotifications = !hasPayerInvoicesRole && Array.isArray(allNotifications) ? allNotifications
    .filter((notification) => notification.subject?.id && !notification.key.includes('invoice'))
    .sort((a, b) => ((a.created_at < b.created_at) ? 1 : -1)) : allNotifications;

  const [notificationsCount, setNotificationsCount] = useState(0);
  const [lastCheckedDate, setLastCheckedDate] = useState(new Date(null).toISOString());

  useEffect(() => {
    setNotificationsCount(
      userNotifications?.filter((notification) => notification.created_at > lastCheckedDate).length,
    );
  }, [lastCheckedDate, allNotifications]);

  useEffect(() => {
    if (employee?.last_checked_notifications_at) {
      setLastCheckedDate(employee.last_checked_notifications_at);
    }
  }, [employee]);

  const handleUpdateRecord = async () => {
    setNotificationsCount(0);
    await updateRecord(currentEmployee.id, { last_checked_notifications_at: new Date() });
    refetchCurrentEmployee();
  };

  return (
    <div className="relative mr-1 mt-px">
      <Menu
        id="right-nav-notification-menu-btn"
        className="right-nav ml-half mr-half"
        anchorElement={(
          <Icon
            ariaLabel="Notifications"
            id="right-nav-notification-btn"
            className="help-btn"
            color="white"
            icon="IconNotificationBell"
            onClick={handleUpdateRecord}
            size={24}
          />
        )}
        position="notification"
      >
        <EmployeeNotificationsContainer
          employeeId={currentEmployee.id}
          hasPayerInvoicesRole={hasPayerInvoicesRole}
          notifications={userNotifications}
        />
      </Menu>
      {
        notificationsCount > 0 ? (
          <div className="absolute flex-1 -mt-8 ml-4">
            <Badge
              id="employee-message-notification-count-badge"
              text={notificationsCount}
              className="float-right"
              style={{
                paddingTop: 3,
                wordBreak: 'normal',
                height: 18,
                minWidth: 25,
                fontSize: 11,
                backgroundColor: '#D0021B',
              }}
            />
          </div>
        ) : null
      }
    </div>
  );
};

EmployeeNotificationMenu.propTypes = {
  currentEmployee: PropTypes.object.isRequired,
  showTasks: PropTypes.bool.isRequired,
};

export default EmployeeNotificationMenu;
