import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { validateReduxForm } from 'common/form';
import {
  Button,
} from '@unite-us/ui';
import _ from 'lodash';
import { OverlaySpinner } from 'common/spinners';
import callOrLog from 'src/common/utils/callOrLog';
import { FACESHEET } from 'common/utils/EventTracker/utils/eventConstants';
import { generateUUID } from 'common/utils/utils';
import { EDIT_ADDRESS_FIELDS_GROUP } from 'common/display/Profile/constants/form';
import AddressFieldGroup from 'common/form/AddressField/AddressFieldGroup';
import { deleteGroupLocation } from 'actions/Group';
import { isAddressValid } from 'common/form/Profile/utils';

import './stylesheets/editAddress.scss';

export class EditAddressesGroup extends Component {
  static initializeIds(list) {
    return _.map(list, (email) => ({ ...email, id: generateUUID() }));
  }

  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
      newIds: _.map(props.addresses, (address) => address.id),
      hasInvalidAddress: false,
    };

    this.addField = this.addField.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.initializeForm = this.initializeForm.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.removeOrDelete = this.removeOrDelete.bind(this);
    this.removeUnsavedAddresses = this.removeUnsavedAddresses.bind(this);
    this.isValidAddress = this.isValidAddress.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.modalIsOpen && !nextProps.modalIsOpen) {
      this.removeUnsavedAddresses();
    }

    if (!this.props.modalIsOpen && nextProps.modalIsOpen) {
      this.initializeForm();
    }
  }

  onCancel() {
    this.resetAndCloseModal();
  }

  onDelete(addresses, idToRemove) {
    if (!_.isEmpty(addresses)) {
      callOrLog(() => this.context.eventTracker(FACESHEET.addressUpdated));
    }

    return (
      this.props.deleteGroupLocation(idToRemove)
        .then(() => {
          const updatedAddresses = _.filter(this.props.fields.addresses, (item) => item.id.value !== idToRemove);
          const updatedStateIds = _.map(updatedAddresses, (address) => address.id.value);
          const index = _.findIndex(this.props.fields.addresses, (e) => e.id.value === idToRemove);

          this.props.fields.addresses.removeField(index);
          this.setState({
            newIds: updatedStateIds,
            deleting: false,
          });
        })
    );
  }

  onSubmit(values) {
    const addresses = values.addresses;

    if (!_.isEmpty(addresses)) {
      callOrLog(() => this.context.eventTracker(FACESHEET.addressUpdated));
    }

    return this.props.onSave(addresses)
      .then(() => {
        this.resetAndCloseModal();
      });
  }

  async isValidAddress(location) {
    const valid = await isAddressValid(location);

    this.setState({ hasInvalidAddress: !valid });

    return this.state.hasInvalidAddress;
  }

  initializeForm() {
    const { initialAddresses } = this.props;

    this.props.initializeForm({
      addresses: initialAddresses,
    });
  }

  addField() {
    const { addresses } = this.props.fields;

    addresses.addField();
  }

  removeField(addresses, idToRemove) {
    const index = _.findIndex(addresses, (e) => e.id.value === idToRemove);
    addresses.removeField(index);
    this.setState({
      newIds: _.filter(this.state.newIds, (id) => id !== idToRemove),
    });
  }

  removeOrDelete(idToRemove) {
    const { fields: { addresses } } = this.props;

    if (_.includes(this.state.newIds, idToRemove)) {
      this.removeField(addresses, idToRemove);
    } else {
      this.setState({ deleting: true }, () => {
        const filteredAddresses = _.filter(addresses, (address) => address.id.value !==
          idToRemove && !_.includes(this.state.newIds, address.id.value));

        const addressValues = _.map(filteredAddresses, (address) => ({
          id: address.id.value,
          line_1: address.attributes.line_1.value,
          line_2: address.attributes.line_2.value,
          city: address.attributes.city.value,
          county: address.attributes.county.value,
          state: address.attributes.state.value,
          postal_code: address.attributes.postal_code.value,
          country: address.attributes.country.value,
        }));

        this.onDelete(addressValues, idToRemove);
      });
    }
  }

  removeUnsavedAddresses() {
    const { addresses } = this.props.fields;

    _.each(this.state.newIds, () => {
      addresses.removeField();
    });

    this.setState({ newIds: [] });
  }

  resetAndCloseModal() {
    this.initializeForm();
    this.setState({ newIds: [] });
    this.props.closeModal();
  }

  render() {
    const {
      fields,
      handleSubmit,
      registerField,
      styles,
      submitting,
    } = this.props;

    const { deleting, hasInvalidAddress } = this.state;

    return (
      <form
        className="edit-address content-with-actions"
        onSubmit={handleSubmit(this.onSubmit)}
      >
        <OverlaySpinner text="Saving Addresses..." show={submitting || deleting} />

        <div className="content-container">
          {
            fields.addresses.map((address, index) => (
              <div
                id={`address-${index}`}
                key={index}
                style={styles.inlineAddressContainer}
              >
                <AddressFieldGroup
                  id={address.id.value}
                  field={address}
                  forceRequired
                  index={index}
                  inline={false}
                  remove={() => this.removeOrDelete(address.id.value)}
                  registerField={registerField}
                  isValidAddress={this.isValidAddress}
                />
              </div>
            ))
          }
        </div>

        <div className="actions">
          <div className="row">
            <div className="col-sm-6 text-left edit-address__add-address">
              <a
                id="add-address-link"
                role="button"
                onClick={this.addField}
              >
                + Add Address
              </a>
            </div>

            <div className="col-sm-6">
              <span className="action-item">
                <Button
                  id="edit-address-cancel-btn"
                  onClick={this.onCancel}
                  disabled={submitting}
                  label="Cancel"
                />
              </span>
              <span className="action-item">
                <Button
                  id="edit-address-save-btn"
                  onClick={handleSubmit(this.onSubmit)}
                  disabled={submitting || hasInvalidAddress}
                  label="Save"
                  primary
                />
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

EditAddressesGroup.propTypes = {
  addresses: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  deleteGroupLocation: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialAddresses: PropTypes.array.isRequired,
  initializeForm: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    inlineAddressContainer: PropTypes.object.isRequired,
  }).isRequired,
  submitting: PropTypes.bool.isRequired,
};

EditAddressesGroup.defaultProps = {
  styles: {
    inlineAddressContainer: {
      borderBottom: '1px solid #EBECED',
      marginBottom: '20px',
      paddingBottom: '10px',
    },
  },
};

EditAddressesGroup.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { addresses } = ownProps;
  const groupId = _.get(state, 'session.groupId');

  return {
    initialAddresses: addresses,
    groupId,
  };
}

const fields = [
  'addresses[].id',
  'addresses[].attributes.line_1',
  'addresses[].attributes.line_2',
  'addresses[].attributes.city',
  'addresses[].attributes.county',
  'addresses[].attributes.state',
  'addresses[].attributes.postal_code',
  'addresses[].attributes.country',
];
export default validateReduxForm(
  {
    form: EDIT_ADDRESS_FIELDS_GROUP,
    fields,
  },
  mapStateToProps,
  { deleteGroupLocation },
)(EditAddressesGroup);
