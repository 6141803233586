import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Interactions from 'common/form/Interactions';
import { INTERACTION_TYPES } from 'src/components/Notes/constants';
import {
  createContactServiceCaseProvidedService,
  createContactServiceCaseInteraction,
} from 'actions/Case/Contact/Group';
import { createContactInteraction, createContactCommunications } from 'actions/Contact/Group';
import { hasPaymentsUserAccess } from 'src/common/utils/FeatureFlags/flags';
import Notes from 'src/components/Notes/Notes';
import NotesHeader from 'src/components/Notes/NotesHeader';
import Messages from 'src/components/Notes/Messages';
import useFacesheetInteractions from './actions/useFacesheetInteractions';

export const FacesheetInteractions = (props) => {
  const {
    groupId,
    contact,
    usePaymentsUserRole,
  } = props;

  const {
    interactionList,
    caseOptions,
    setShowMessageInteraction,
    showMessageInteraction,
    setShowInteractions,
    showInteractions,
  } = useFacesheetInteractions(props);

  const noteInteractionList = interactionList
    .filter((o) => [INTERACTION_TYPES.INTERACTION, INTERACTION_TYPES.GENERAL].includes(o.value));
  const messageInteractionList = interactionList.filter((o) => o.value === 'Message');

  const [firstTimeFocus, setFirstTimeFocus] = useState(false);

  useEffect(() => {
    if (showInteractions && firstTimeFocus) {
      const focusElem = document.getElementById('interactions-interaction-tab');
      focusElem.focus();
      setFirstTimeFocus(false);
    }
  }, [showInteractions, firstTimeFocus]);

  return (
    <div className="facesheet-interactions panel-wrapper">
      {
        showMessageInteraction ? (
          <div className="mb-4">
            <Interactions
              detailType="profile"
              cardHeaderTitle="Message Client"
              caseOptions={caseOptions}
              contact={contact}
              interactionList={messageInteractionList}
              usePaymentsUserRole={usePaymentsUserRole}
              cancelFunction={() => setShowMessageInteraction(false)}
              showTabs={false}
            />
          </div>
        ) : null
      }
      <Messages
        subject={contact.id}
        providerId={groupId}
        onAddNote={() => { setShowMessageInteraction(true); }}
      />
      <div>
        <NotesHeader
          styles={{ marginTop: '20px' }}
          onAddNote={() => {
            setFirstTimeFocus(true);
            setShowInteractions(true);
          }}
        />
        {
          showInteractions ? (
            <div className="mb-10">
              <Interactions
                detailType="profile"
                cardHeaderTitle="Add Note"
                caseOptions={caseOptions}
                contact={contact}
                interactionList={noteInteractionList}
                usePaymentsUserRole={usePaymentsUserRole}
                cancelFunction={() => setShowInteractions(false)}
                showAttachedToField={false}
                showAttachedToCases={false}
              />
            </div>
          ) : null
        }
        <Notes
          providerId={groupId}
          subject={contact.id}
          subjectType="person"
          onAddNote={() => { setShowInteractions(true); }}
          reworkedAddNotes
        />
      </div>
    </div>
  );
};

FacesheetInteractions.propTypes = {
  contact: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  usePaymentsUserRole: PropTypes.bool,
};

FacesheetInteractions.defaultProps = {
  usePaymentsUserRole: false,
};

function mapStateToProps(state) {
  return {
    groupId: state.session.groupId,
    usePaymentsUserRole: hasPaymentsUserAccess(state),
  };
}

export default connect(mapStateToProps, {
  createContactInteraction,
  createContactServiceCaseProvidedService,
  createContactServiceCaseInteraction,
  createContactCommunications,
})(FacesheetInteractions);
