import _ from 'lodash';

function getOutcomesFromResponse(outcomesResponse, service_type_id) {
  return [outcomesResponse]
    .map((response) => _.get(response, 'data.data', []))
    .map((allOutcomesData) => _.find(allOutcomesData, { service_type_id }))
    .map((dataByServiceType) => _.get(dataByServiceType, 'outcomes', []))[0];
}

export default getOutcomesFromResponse;
