import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@unite-us/ui';
import { reject, some } from 'lodash';
import UploadAndAttachDocumentsForm from './UploadAndAttachDocumentsForm';
import '../stylesheets/documentUploader.scss';

class UploadAndAttachDocuments extends Component {
  constructor(props) {
    super(props);

    this.onAttach = this.onAttach.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.openDialog = this.openDialog.bind(this);
  }

  onAttach(documents) {
    this.documentDialog.closeDialog();
    this.props.onAttachDocuments(documents);
  }

  onCancel() {
    this.documentDialog.closeDialog('cancel');
  }

  openDialog() {
    this.documentDialog.openDialog();
  }

  render() {
    const {
      anchorEl,
      attachedDocuments,
      contactDocuments,
      dropzoneName,
      warningText,
    } = this.props;

    const attachableDocuments = reject(contactDocuments, (document) => (
      some(attachedDocuments, { id: document.id })
    ));

    return (
      <div className="upload-and-attach-documents">
        <a
          id="upload-document-link"
          className="upload-and-attach-documents__upload-link"
          role="button"
          onClick={this.openDialog}
        >
          {anchorEl}
        </a>

        <Dialog
          id="upload-document-modal"
          className="upload-and-attach-documents__dialog"
          ref={(el) => { this.documentDialog = el; }}
          title="Attach Documents"
          size="large"
        >
          <UploadAndAttachDocumentsForm
            documents={attachableDocuments}
            dropzoneName={dropzoneName}
            onAttach={this.onAttach}
            onCancel={this.onCancel}
            warningText={warningText}
          />
        </Dialog>
      </div>
    );
  }
}

UploadAndAttachDocuments.propTypes = {
  anchorEl: PropTypes.node,
  attachedDocuments: PropTypes.array,
  contactDocuments: PropTypes.array,
  dropzoneName: PropTypes.string.isRequired,
  onAttachDocuments: PropTypes.func.isRequired,
  warningText: PropTypes.string,
};

UploadAndAttachDocuments.defaultProps = {
  anchorEl: 'Attach or Upload a Document',
  attachedDocuments: [],
  contactDocuments: [],
};

export default UploadAndAttachDocuments;
