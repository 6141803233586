import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { shapes } from '@unite-us/client-utils';
import { Spinner } from 'common/spinners';
import { DynamicTable } from 'common/tables';
import firstLine from 'common/utils/firstLine';
import CaseRowIcon from 'src/components/Dashboard/components/Cases/CaseRowIcon';
import _ from 'lodash';
import { showFacesheetCasesTableDescription } from 'common/utils/FeatureFlags/flags';
import {
  clientNameColumn,
  serviceTypeColumn,
} from '../../utils/columnConfigs/baseColumns';
import FilterBar from '../FilterBar';

function getColumns(showDescription) {
  const descColumn = {
    label: 'Description',
    formatData: (data) => firstLine(data.description),
    className: 'truncate',
  };

  return _.compact([
    clientNameColumn(),
    showDescription ? descColumn : null,
    serviceTypeColumn(),
    {
      label: 'Last Updated',
      sortColumn: 'updated_at',
      type: 'date',
    },
  ]);
}

export function OpenCasesIndex(props) {
  const {
    paging,
    data,
    onNextClick,
    onPrevClick,
    isFetching,
    onCellClick,
    initialSort,
    isCC,
    filters,
    onFiltersChange,
    isCaseMgmtProvider,
    fetchGroupsUsers,
    showDescription,
  } = props;
  return (
    <div>
      <FilterBar
        filters={filters}
        onFiltersChange={onFiltersChange}
        paging={paging}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
        isFetching={isFetching && !_.isEmpty(data)}
        asyncSearch={fetchGroupsUsers}
      />
      <div className="dashboard-inner-content">
        {
          (isFetching && _.isEmpty(data)) ?
            <Spinner /> : (
              <DynamicTable
                id="open-cases-table"
                dataSet={data}
                onCellClick={onCellClick}
                initialSort={initialSort}
                columnLabels={getColumns(showDescription)}
                rowIcon={(serviceCase) => (
                  <CaseRowIcon
                    isCC={isCC}
                    isCaseMgmtProvider={isCaseMgmtProvider}
                    serviceCase={serviceCase}
                  />
                )}
                emptyMessage="There are no open cases."
              />
            )
        }
      </div>
    </div>
  );
}

OpenCasesIndex.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  paging: shapes.paging,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  onCellClick: PropTypes.func.isRequired,
  initialSort: PropTypes.object,
  isCC: PropTypes.bool.isRequired,
  filters: PropTypes.array,
  onFiltersChange: PropTypes.func,
  isCaseMgmtProvider: PropTypes.bool,
  fetchGroupsUsers: PropTypes.func.isRequired,
  showDescription: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    showDescription: showFacesheetCasesTableDescription(state),
  };
}

export default connect(mapStateToProps)(OpenCasesIndex);
