import { ADD_NOTIFICATION } from 'actions';
import _ from 'lodash';
import showSystemNotification from '../utils/SystemNotifications/actions/showSystemNotification';

let dispatch = null;

const errorMessage = (message = '') => (
  message ?
    `Something went wrong: ${message}` :
    'Something went wrong.'
);

export function dispatchNotification(notification) {
  dispatch({
    type: ADD_NOTIFICATION,
    payload: notification,
  });
}

const Notifier = {
  setDispatch: (newDispatch) => {
    dispatch = newDispatch;
  },

  dispatch(status, statusText, timeOut) {
    dispatchNotification({ statusText, status, ...(!_.isNil(timeOut) && { timeOut }) });
  },

  handleErrors(error = {}) {
    const responseError = _.get(error, 'response');
    if (_.isUndefined(responseError)) {
      if (error && error.message) {
        dispatchNotification({
          statusText: errorMessage(error.message),
          status: 'error',
        });
        console.error(error.message);
        console.error(error.stack);
      }
      return { ok: false };
    }

    const responseData = _.get(responseError, 'data');
    const status = _.get(responseError, 'status');

    if (Array.isArray(responseData)) {
      // When the response data is an array of errors, we don't want to
      // swallow them and we don't need to dispatch a bunch of notifications
      // that aren't user friendly, so just dispatch one notification w/ the
      // base error message.

      dispatchNotification({
        status: 'error',
        statusText: errorMessage(error.message),
      });
    } else if (typeof responseData === 'object') {
      _.get(responseData, 'errors', []).forEach((statusText) => {
        if (!statusText) { return; }
        const notificationObj = {
          status,
          statusText: statusText.title || statusText,
        };

        dispatchNotification(notificationObj);
      });
    } else {
      dispatchNotification({
        status: responseError.status,
        statusText: responseError.statusText,
      });
    }

    // @todo This logic should eventually be updated.  Right now we don't do
    // anything with the return value of this function and it doesn't
    // necessarily make sense to get responseData.ok if the above conditional
    // is false.
    return { ok: _.get(responseData, 'ok', false) };
  },

  handleSystemNotification(status, message, dismissable) {
    const notificationObj = {
      status,
      message,
      dismissable,
    };

    dispatch(showSystemNotification(notificationObj));
  },
};

export default Notifier;
