const getInitialArrayFromJSON = (json) => (JSON.parse(json)?.map(
  (item) => ({
    display_name: `${item.name}, ${item.state}`, state: item.state, label: item.name,
  }),
) || []);

const constructDirectoryConfigurationInitialFormValues = (directoryConfiguration) => {
  const initialValues = {};
  const { counties, cities, states } = directoryConfiguration;

  if (directoryConfiguration.theme && directoryConfiguration.theme !== '{}') {
    const font = JSON.parse(directoryConfiguration.theme).font;
    if (font) {
      initialValues.primary_font_family = font?.primary?.family;
      initialValues.secondary_font_family = font?.secondary?.family;
      initialValues.body_font_family = font?.body?.family;
    }
    const colors = JSON.parse(directoryConfiguration.theme).colors;
    if (colors) {
      initialValues.primary_color = colors?.primary?.replace('#', '');
      initialValues.accent_color = colors?.accent?.replace('#', '');
      initialValues.background_color = colors?.background?.replace('#', '');
      initialValues.link_color = colors?.link?.replace('#', '');
      initialValues.map_marker_color = colors?.mapMarker?.replace('#', '');
    }
  }
  if (directoryConfiguration.location) {
    const location = JSON.parse(directoryConfiguration.location);
    initialValues.default_location = location.name;
    initialValues.latitude_and_longitude = { lat: location.lat, lng: location.lng };
  }
  if (directoryConfiguration.keys) {
    const keys = JSON.parse(directoryConfiguration.keys);
    initialValues.external_api_key = keys.externalApiKey;
  }
  if (directoryConfiguration.contact) {
    const contact = JSON.parse(directoryConfiguration.contact);
    initialValues.copyright_info = contact.footerText;
  }
  if (directoryConfiguration?.logo_url) {
    initialValues.logo = { name: directoryConfiguration?.logo_url };
  }

  if (counties) {
    initialValues.counties = getInitialArrayFromJSON(counties);
  }

  if (cities) {
    initialValues.cities = getInitialArrayFromJSON(cities);
  }

  if (states) {
    initialValues.states = JSON.parse(states);
  }

  if (directoryConfiguration.include_payments) {
    initialValues.include_payments = directoryConfiguration.include_payments;
  }

  if (directoryConfiguration.fee_schedule_ids) {
    initialValues.fee_schedules = JSON.parse(directoryConfiguration.fee_schedule_ids);
  }

  return initialValues;
};

export default constructDirectoryConfigurationInitialFormValues;
