import {
  useFindRecord,
} from 'src/api/APIHooks';
import { defaultQueryConfig } from 'src/components/Backoffice/api/hooks/apiHookOptions';

export const useFetchForm = (id) => {
  const form = useFindRecord('form', id, { queryConfig: defaultQueryConfig });
  const form_data = form.data;
  return form_data;
};
