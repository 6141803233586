import { compact, filter, get, includes, isEmpty, map } from 'lodash';
import {
  MIX_CC_PROVIDER_ERROR_MESSAGE,
  MULTIPLE_CC_RECIPIENT_ERROR_MESSAGE,
} from 'src/components/Referrals/ReferralServicesForm/constants';

const isCCSelected = (groupId, ccGroupIds) => includes(ccGroupIds, groupId);

const mixCCAndProviderError = (ccCount, providerCount) => (
  ccCount > 0 && providerCount > 0 ? MIX_CC_PROVIDER_ERROR_MESSAGE : ''
);

const multipleCCError = (ccCount) => (
  ccCount > 1 ? MULTIPLE_CC_RECIPIENT_ERROR_MESSAGE : ''
);

const getInvalidGroupErrors = ({
  caseReferrals = [],
  ccGroupIds = [],
  fields = [],
  groupId = '',
  referral,
}) => {
  if (!isCCSelected(groupId, ccGroupIds) && isEmpty(caseReferrals)) {
    return [];
  }

  const currentRecipientId = get(referral, 'referred_to_group.id');
  const activeReferrals = filter(caseReferrals, (r) => get(r, 'state') !== 'accepted');
  const otherReferralsRecipientsIds = map(activeReferrals, (r) => get(r, 'receiving_provider.id'))
    .filter((recipientId) => recipientId !== currentRecipientId);
  const selectedGroupIds = compact(
    map(fields, (selected) => get(selected, 'group.value.id')),
  ).concat(otherReferralsRecipientsIds);

  const ccCount = (
    filter(selectedGroupIds, (id) => includes(ccGroupIds, id)) || []
  ).length;

  const providerCount = selectedGroupIds.length - ccCount;

  return compact([
    mixCCAndProviderError(ccCount, providerCount),
    multipleCCError(ccCount),
  ]);
};

export default getInvalidGroupErrors;
