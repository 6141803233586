import Notifier from 'common/helpers/Notifier';
import { isEmpty } from 'lodash';
import {
  SET_NETWORK_BROWSE_GROUPS_FETCHING,
  UNSET_NETWORK_BROWSE_GROUPS_FETCHING_CASES,
} from 'actions';
import fetchProviderBrowseResultsFromSearch from './fetchProviderBrowseResultsFromSearch';
import fetchCaseProgramBrowseResultsFromSearch from './fetchCaseProgramBrowseResultsFromSearch';

const onError = (error, dispatch) => {
  if (!isEmpty(error.message)) {
    dispatch({
      type: UNSET_NETWORK_BROWSE_GROUPS_FETCHING_CASES,
    });
  }
  Notifier.handleErrors(error);

  return error;
};

const searchNetworkBrowseGroupsForCases = ({
  reqParams,
  useProgramBasedSearch,
  additionalProgramIds,
  hint716SearchNetworkHubSupportPremium,
}) => (
  async (dispatch) => {
    try {
      dispatch({
        type: SET_NETWORK_BROWSE_GROUPS_FETCHING,
      });
      const page = {
        page: {
          number: reqParams.page || 1,
          size: reqParams.size || 50,
        },
        sort: reqParams.sort,
      };

    const payload = useProgramBasedSearch ?
      await fetchCaseProgramBrowseResultsFromSearch({
        ...reqParams.q,
        ...(hint716SearchNetworkHubSupportPremium && { 'provider.type': 'all' }),
      }, page, additionalProgramIds) :
      await fetchProviderBrowseResultsFromSearch(
        {
          ...reqParams.q,
          ...(hint716SearchNetworkHubSupportPremium && { type: 'all' }),
        },
        page,
        hint716SearchNetworkHubSupportPremium ? { 'filter[provider.type]': 'all' } : {},
      );

      dispatch({
        type: UNSET_NETWORK_BROWSE_GROUPS_FETCHING_CASES,
        payload,
      });

      return payload.data.data;
    } catch (error) {
      return onError(error, dispatch);
    }
  }
);

export default searchNetworkBrowseGroupsForCases;
