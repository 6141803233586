import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ExpandableContainer, HoursOfOperationV2 } from '@unite-us/client-utils';
import displayContactMethods from './utils/displayContactMethods';

const ContactPreferences = ({
  contact_preference,
  showVoiceMailOk,
  hideLabel,
  abbreviateDays,
}) => {
  const isPhoneCallSelected =
    _.includes(contact_preference.preferred_contact_methods, 'phone') && contact_preference.voice_mail_ok != null;

  return (
    <div id="contact_preferences">
      { !_.isEmpty(contact_preference.preferred_contact_methods) && (
      <>
        <label htmlFor="method_of_contact">Method of Contact</label>
        <div id="method_of_contact" className="mb-4 mt-1 text-sm">
          { displayContactMethods(contact_preference.preferred_contact_methods) }
        </div>
      </>
    )}

      { showVoiceMailOk && isPhoneCallSelected ? (
        <>
          <label htmlFor="voice_mail_ok">Okay to Leave a Voicemail?</label>
          <div id="voice_mail_ok" className="mb-4 mt-1 text-sm">
            { contact_preference.voice_mail_ok ? 'Yes' : 'No' }
          </div>
        </>
    ) : null }

      { !_.isEmpty(contact_preference.timeslots) && (
      <>
        <label htmlFor="timeslots">Times to Contact</label>
        <HoursOfOperationV2
          hours={contact_preference.timeslots}
          showAll
          showToggle
          abbreviateDays={abbreviateDays}
          closedText="Do not contact"
          startTimeKey="start_time"
          endTimeKey="end_time"
          className="mt-1 mb-4"
          hideLabel={hideLabel}
        />
      </>
    )}

      { contact_preference.contact_notes && (
      <>
        <label htmlFor="expandable_contact_notes" id="contact_notes">Contact Notes</label>
        <ExpandableContainer id="expandable_contact_notes" collapsedHeight="60px">
          <div className="mt-1 text-sm">
            <div className="whitespace-pre-wrap">{contact_preference.contact_notes}</div>
          </div>
        </ExpandableContainer>
      </>
    )}
    </div>
  );
};

ContactPreferences.propTypes = {
  contact_preference: PropTypes.shape({
    preferred_contact_methods: PropTypes.array.isRequired,
    voice_mail_ok: PropTypes.bool,
    contact_notes: PropTypes.string,
    timeslots: PropTypes.shape({
      monday: PropTypes.array,
      tuesday: PropTypes.array,
      wednesday: PropTypes.array,
      thursday: PropTypes.array,
      friday: PropTypes.array,
      saturday: PropTypes.array,
      sunday: PropTypes.array,
    }),
  }).isRequired,
  showVoiceMailOk: PropTypes.bool,
  hideLabel: PropTypes.bool,
  abbreviateDays: PropTypes.bool,
};

ContactPreferences.defaultProps = {
  showVoiceMailOk: false,
  abbreviateDays: false,
  hideLabel: false,
};

export default ContactPreferences;
