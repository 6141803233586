import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { TrackerContext } from '@unite-us/client-utils';
import OrganizationHeader from 'src/components/Organization/components/OrganizationHeader';
import { ORG_TABS } from 'src/components/Organization/constants';
import $scripts from 'scriptjs';
import { requiredMapScript } from 'src/components/Browse/Map/utils';
import { Spinner } from 'src/common/spinners';
import LocationForm from 'src/components/Organization/pages/locations/LocationForm';
import FormHeader from 'src/components/Organization/components/FormHeader';
import normalizeHours from 'src/components/Organization/utils/normalizeHours';
import { useFindLocation, useUpdateLocation, useDeleteLocation } from 'src/components/Organization/api/hooks/v1';
import { browserHistory } from 'common/utils/browserHistory';
import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import DialogV2 from 'common/modal/DialogV2';
import useTrackPageView from 'src/components/Organization/utils/useTrackPageView';
import { ORG_SETTINGS } from 'src/common/utils/EventTracker/utils/eventConstants';
import FormContainer from 'src/components/Organization/components/FormContainer';
import LocationFormSubheader from '../../../components/LocationForm/LocationFormSubheader';

const LocationEdit = (props) => {
  useTrackPageView(ORG_SETTINGS.editLocViewed);

  const {
    params: { location_id: locationId },
    location: windowLocation,
  } = props;

  const eventTracker = useContext(TrackerContext);
  const [scriptsLoaded, setScriptsLoaded] = useState(window.google && window.google.maps);
  // Let's check to make sure that the google maps script is loaded
  if (!(window.google && window.google.maps)) {
    $scripts(requiredMapScript.scripts, () => {
      setScriptsLoaded(true);
    });
  }

  const deleteDialog = useRef(null);
  const {
    data: location,
    isFetching: isFetchingLocation,
  } = useFindLocation({ locationId });
  const { updateRecord: updateLocation } = useUpdateLocation();
  const { deleteRecord: deleteLocation } = useDeleteLocation();

  const onSubmit = async (values) => {
    eventTracker(ORG_SETTINGS.locSaved);
    const response = await updateLocation(locationId, {
      ...values,
    });

    if (isHttpSuccess(response.status)) {
      Notifier.dispatch(
        response.status,
        'Location Successfully Updated',
      );
    }
    browserHistory.push('/organization/settings/');
  };

  const onDelete = () => {
    deleteDialog.current.openDialog();
  };

  const confirmDelete = async () => {
    const response = await deleteLocation(locationId, {});
    if (isHttpSuccess(response.status)) {
      Notifier.dispatch(
        response.status,
        'Location successfully deleted.',
      );
    }
    browserHistory.push('/organization/settings/');
  };

  const cancelDelete = () => {
    deleteDialog.current.closeDialog();
  };

  if (isFetchingLocation || !scriptsLoaded) {
    return (<Spinner center />);
  }

  const initialValues = {
    ...location,
    email_addresses: map(location.email_addresses, (email) => ({ email_address: email })),
    hours: normalizeHours.groupByOpenHours(location.hours),
  };

  return (
    <>
      <OrganizationHeader currentTab={ORG_TABS.organization} location={windowLocation} />
      <FormContainer>
        <FormHeader
          headingText="Edit Location"
          cancelLink="/organization/settings"
        />
        <LocationFormSubheader />
        <LocationForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          onDelete={onDelete}
        />
        <DialogV2
          ref={deleteDialog}
          title="Delete Location"
          confirmLabel="Delete"
          cancelHandler={cancelDelete}
          confirmationHandler={confirmDelete}
          className="location-delete-dialog"
          isWarning
        >
          <p className="pb-4">
            Deleting this location will remove it from the organization and any
            associated programs. The organization and its programs will no
            longer appear on the My Network map at this location.
          </p>
          <p>
            Are you sure you want to delete this location?
          </p>
        </DialogV2>
      </FormContainer>
    </>
  );
};

LocationEdit.propTypes = {
  params: PropTypes.shape({
    location_id: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

export default LocationEdit;
