import React from 'react';
import {
  Icon,
} from '@unite-us/ui';
import PropTypes from 'prop-types';

function WarningMessage({ children }) {
  return (
    <>
      <div>
        <Icon className="fill-current mr-1" icon="IconExclamationTriangle" size={16} />
      </div>
      <div>
        <p className="text-13px">
          {children}
        </p>
      </div>
    </>
  );
}

WarningMessage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WarningMessage;
