import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ClientItem extends PureComponent {
  render() {
    const { label, value } = this.props;

    return (
      <li className={`client-item client-item--${label}`}>
        <h3 className="client-item__label ui-h3-heavy">{label} </h3>
        <h3 className="client-item__value">{value}</h3>
      </li>
    );
  }
}

ClientItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default ClientItem;
