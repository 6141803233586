import axios from 'axios';
import {
  PATCH_ASSESSMENT,
  FORM_SAVING,
  FORM_COMPLETE,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import _ from 'lodash';

export default function patchAssessment({
  groupId,
  contactId,
  assessmentId,
  serviceTypeIds = [],
  draft = false,
  values,
}) {
  // the API doesn't accept empty data, checking it here and
  // returning a dummy response.
  if (_.isEmpty(values)) {
    const p = new Promise((resolve, reject) => reject('empty data'));
    return (dispatch) => p.then(() => dispatch);
  }

  return (dispatch) => {
    function onSuccess(payload) {
      dispatch({
        type: PATCH_ASSESSMENT,
        payload,
      });
      return payload;
    }

    function onError(error) {
      Notifier.handleErrors(error);
      return error;
    }

    dispatch({ type: FORM_SAVING, form: assessmentId });

    const request = axios.patch(
      `/groups/${groupId}/contacts/${contactId}/forms/${assessmentId}/response`,
      { data: values, service_type_ids: serviceTypeIds, draft },
    );

    return request.then(onSuccess, onError)
      .then((response) => {
        dispatch({ type: FORM_COMPLETE, form: assessmentId });
        return response;
      });
  };
}
