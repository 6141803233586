import _ from 'lodash';
import getGroupType from 'src/components/Referrals/ReferralGroupsPrograms/utils/getGroupType';

export default function addGroupFields(groups, groupField, disabled = true) {
  _.each(groups, (group) => {
    groupField.addField({
      group: {
        disabled,
        id: group.id,
        name: group.name,
        provider_type: getGroupType(group),
        programs: group.programs,
      },
    });
  });
}
