import { object, bool } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  REFERRALS_CREATE_REFERRALS,
  REFERRALS_CLOSE_REFERRALS,
  REFERRALS_FORWARD_REFERRALS,
  RESOURCE_LISTS,
} from 'src/common/utils/FeatureFlag/utils/constants';
import { showResourceLists } from 'src/common/utils/FeatureFlags/flags';
import { isReferralsUserAndAbove, isReferralsAdminAndAbove } from 'src/components/User/utils';

export default function (ComposedComponent) {
  class FeatureFlag extends Component {
    constructor(props) {
      super(props);

      this.flagEnabled = this.flagEnabled.bind(this);
    }

    flagEnabled(flagType) {
      const {
        globalState,
        isResourceListEnabled,
      } = this.props;
      const providerId = get(globalState, 'currentProvider.group.id', null);
      // const isCC = get(
      //   globalState,
      //   'currentProvider.group.provider_type',
      //   null,
      // ) === 'coordination_center';

      // const allRoles = get(
      //   globalState,
      //   'roles',
      //   [],
      // );
      // const myRoleKeys = getUserRoleKeys(user, allRoles, providerId);
      const {
        user,
      } = this.props;

      if (flagType === REFERRALS_CREATE_REFERRALS) {
        // checks if there is a client relationship:
        // API call to client_relationship with 2 filters: person and provider
        // person = person who I am creating a referral for
        // provider = logged in user's provider

        // we might have to check (product question):
        // if there is a client relationship
        // AND if the logged in user's primary role is referrals user and above
        return isReferralsUserAndAbove(user, providerId);
      }

      // Facesheet display
      if (flagType === 'referrals_view_referral_details') {
        return true;
      }

      if (flagType === REFERRALS_FORWARD_REFERRALS) {
        return isReferralsAdminAndAbove(user, providerId);
      }

      if (flagType === REFERRALS_CLOSE_REFERRALS) {
        return isReferralsUserAndAbove(user, providerId);
      }

      if (flagType === RESOURCE_LISTS) {
        return isResourceListEnabled;
      }

      return get(this.props, `currentGroup.feature_flags.${flagType}`, false);
    }

    render() {
      return <ComposedComponent {...this.props} flagEnabled={this.flagEnabled} />;
    }
  }

  FeatureFlag.propTypes = {
    user: object.isRequired,
    currentGroup: object.isRequired,
    globalState: object.isRequired,
    isResourceListEnabled: bool.isRequired,
  };

  function mapStateToProps(state) {
    return {
      globalState: state.globalState,
      user: state.user,
      currentGroup: state.user.groups?.find((data) => data.group.id === state.session.groupId),
      isResourceListEnabled: showResourceLists(state),
    };
  }

  return connect(mapStateToProps)(FeatureFlag);
}
