import { hasUserRole } from '.';

function isSupervisor(user, groupId) {
  const isCaseManager = hasUserRole({
    groupId,
    name: 'Case Manager',
    roleType: 'primary',
    user,
  });
  const isReferralsAdmin = hasUserRole({
    groupId,
    name: 'Referrals Admin',
    roleType: 'primary',
    user,
  });
  const isReferralsUser = hasUserRole({
    groupId,
    name: 'Referrals User',
    roleType: 'primary',
    user,
  });
  return isCaseManager || isReferralsAdmin || isReferralsUser;
}

export default isSupervisor;
