import {
  ADD_CONTACT_DOCUMENTS,
  CREATE_CONTACT_CASE_DOCUMENTS,
  CREATE_CONTACT_DOCUMENTS,
  FETCH_CONTACT_DOCUMENTS,
  FIND_CONTACT_DOCUMENTS,
  REMOVE_CONTACT_DOCUMENT_FROM_RESOURCES,
  RENAME_CONTACT_DOCUMENT,
  REPLACE_CONTACT_DOCUMENTS,
  UPDATE_CONTACT_DOCUMENT,
} from 'actions';
import { replaceObjectInArrayById } from 'common/utils/stateHelpers';
import _ from 'lodash';

export const defaultState = {};

const blankStateWithPaging = {
  data: [],
  paging: {},
};

function mergeDocuments(currentDocuments = [], documents = []) {
  const newDocuments = _.differenceBy(documents, currentDocuments, (doc = {}) => doc.id);

  return [
    ..._.reduce(currentDocuments, (acc, curr) => {
      const updatedDoc = _.find(documents, { id: curr.id });
      return updatedDoc ? [...acc, updatedDoc] : [...acc, curr];
    }, []),
    ...newDocuments,
  ];
}

function updateDoc(doc, action) {
  const { data } = action.payload.data;
  return doc.id === data.id ? data : doc;
}

function updateContactDocument(state, action) {
  const { contactId } = action;

  let newState = _.merge({}, state);
  const currentDocuments = state[contactId];
  const updatedDocuments = [...currentDocuments.data].map((doc) => (
    updateDoc(doc, action)
  ));

  newState = _.uuOmit(newState, state[action.contactId]);
  newState[contactId].data = updatedDocuments;

  return newState;
}

export default function contactDocumentsReducer(state = defaultState, action) {
  const {
    contactId,
    documentId,
    documents = [],
    payload,
    resourceIds,
    title,
    type,
  } = action;

  switch (type) {
    case FETCH_CONTACT_DOCUMENTS:
      return {
        ...state,
        [contactId]: {
          data: payload.data.data,
          paging: payload.data.paging,
        },
      };

    case CREATE_CONTACT_CASE_DOCUMENTS:
    case CREATE_CONTACT_DOCUMENTS: {
      const newState = _.merge({}, state);
      newState[contactId] = newState[contactId] || blankStateWithPaging;
      return {
        ...newState,
        [contactId]: {
          data: [...newState[contactId].data, ...payload.data.data],
          paging: payload.data.paging,
        },
      };
    }

    case ADD_CONTACT_DOCUMENTS:
      return {
        ...state,
        [contactId]: {
          ...(state[contactId] || blankStateWithPaging),
          data: mergeDocuments(state[contactId].data, documents),
        },
      };

    case REPLACE_CONTACT_DOCUMENTS:
      return {
        ...state,
        [contactId]: {
          ...(state[contactId] || blankStateWithPaging),
          data: documents,
        },
      };

    case UPDATE_CONTACT_DOCUMENT:
      return updateContactDocument(state, action);

    case FIND_CONTACT_DOCUMENTS:
      return {
        ...state,
        [contactId]: {
          ...(state[contactId] || blankStateWithPaging),
          searchedWord: action.documentSearch,
        },
      };

    case REMOVE_CONTACT_DOCUMENT_FROM_RESOURCES: {
      const contactDocData = _.find(
        _.get(state, [contactId, 'data'], []),
        { id: documentId },
      );

      if (!contactDocData) {
        return state;
      }

      const newAttachedTo = _.reject(contactDocData.attached_to, (resource = {}) => (
        _.includes(resourceIds, resource.id)
      ));

      const newContactDocData = {
        ...contactDocData,
        attached_to: newAttachedTo,
      };

      return {
        ...state,
        [contactId]: {
          ...state[contactId],
          // If the document is attached to nothing, remove it from the document list,
          // else remove the resource from the document's attached_to field
          data: _.isEmpty(newAttachedTo) ?
            _.reject(state[contactId].data, { id: documentId }) :
            replaceObjectInArrayById(newContactDocData, state[contactId].data),
        },
      };
    }

    case RENAME_CONTACT_DOCUMENT: {
      if (!state[contactId]) {
        return state;
      }

      const data = replaceObjectInArrayById({
        ..._.find(state[contactId].data, { id: documentId }),
        title,
      }, state[contactId].data);

      return {
        ...state,
        [contactId]: {
          ...state[contactId],
          data,
        },
      };
    }

    default:
      return state;
  }
}
