import isOrgAdmin from './isOrgAdmin';
import isOversightAdmin from './isOversightAdmin';
import isReferralsAdmin from './isReferralsAdmin';

export const referralsAdminAndAboveIds = (allRoles) => (
  allRoles.primaryRoles.filter(
    (role) => (
      role.key === 'referrals_admin' ||
      role.key === 'oversight' ||
      role.key === 'org_admin'
    ),
  ).map((r) => r.id)
);

export const isReferralsAdminAndAbove = (user, groupId) => (
  isReferralsAdmin(user, groupId) ||
  isOversightAdmin(user, groupId) ||
  isOrgAdmin(user, groupId)
);

export default isReferralsAdminAndAbove;
