import { filterProgramsReceivingReferralsWithST } from 'src/components/Referrals/ReferralGroupsPrograms/utils';

/**
 * Only return groups that have at least one program that is receiving referrals and has the correct service type
 * @param {array} suggestedGroups - The suggested groups returned from the API after service type selection
 * @param {object} serviceType - The selected service type in referral creation
 * @param {boolean} usePaymentsUserRole - whether or not the user has the payments user role
*/

function filterGroupsReceivingReferrals(suggestedGroups = [], serviceType = {}, usePaymentsUserRole = false) {
  return suggestedGroups.filter(({ programs = [] }) => (
    filterProgramsReceivingReferralsWithST(programs, serviceType, usePaymentsUserRole).length >= 1
  ));
}

export default filterGroupsReceivingReferrals;
