import { useFind } from 'src/api/APIHooks';
import { get, map } from 'lodash';

const usePayerProviderOptions = (payerId) => {
  const providersQuery = useFind(
    'providers',
    {
      'programs.fee_schedule_program.fee_schedule.plans.payer': payerId,
    },
    {
      page: { size: Infinity },
      queryConfig: {
        enabled: !!payerId,
      },
    },
  );

  const providers = get(providersQuery, 'data.data.data', []);

  const providerOptions = map(providers, (val) => ({
    label: get(val, 'name', ''),
    value: get(val, 'id'),
  }));

  providerOptions.sort((a, b) => {
     if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  return providerOptions;
};

export default usePayerProviderOptions;
