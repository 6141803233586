// NormalizeHours:
//  Check each function for documentation, but for readability, the format of hours is summarized
//  here:
//
//  hoursInApiFormat: The format the API serves and receives:
//    ie:
//      { monday: [{ closes: "22:15", opens: "10:00" }, { closes: "14:15", opens: "7:00" }]}
//
//  hoursSingleLineFormat: An ordered array where each item is a day and range
//    ie:
//        [
//          { day: "monday", opens: "10:00", closes: "22:15" },
//          { day: "monday", closes: "14:15", opens: "7:00" },
//        ]
//
//  hoursMultiLineFormat: An ordered array where each item has multiple days for each range
//    ie:
//        [
//          { day: ["monday", "tuesday"], opens: "10:00", closes: "22:15" },
//          { day: ["monday"], closes: "14:15", opens: "7:00" },
//        ]
//
import { isEmpty } from 'lodash';
import { DAYS_OF_THE_WEEK } from '../constants';

// Transforms hoursApiFormat into hoursSingleLineFormat
const transformApiToSingleLineFormat = (hoursInApiFormat) => {
  const hoursSingleLineFormat = [];
  const daysOfWeek = DAYS_OF_THE_WEEK.map((x) => x.toLowerCase());
  if (hoursInApiFormat && typeof hoursInApiFormat === 'object') {
    Object.keys(hoursInApiFormat).forEach((day) => {
      hoursInApiFormat[day].forEach(({ opens, closes }) => {
        hoursSingleLineFormat.push({
          opens,
          closes,
          day,
        });
      });
    });
    hoursSingleLineFormat.sort((a, b) => daysOfWeek.indexOf(a.day) - daysOfWeek.indexOf(b.day));
  }
  return hoursSingleLineFormat;
};

// Transforms hoursApiFormat into hoursMultiLineFormat
const groupByOpenHours = (hoursInApiFormat) => {
  const hoursMultiLineFormat = {};
  const daysOfWeek = DAYS_OF_THE_WEEK.map((day) => day.toLowerCase());
  if (hoursInApiFormat && typeof hoursInApiFormat === 'object') {
    for (const [day, hours] of Object.entries(hoursInApiFormat)) {
      for (const { opens, closes } of hours) {
        const arbitraryHourString = `o${opens}c${closes}`;
        if (hoursMultiLineFormat[arbitraryHourString]) {
          hoursMultiLineFormat[arbitraryHourString].day = [
            ...hoursMultiLineFormat[arbitraryHourString].day,
            day,
          ];
        } else {
          hoursMultiLineFormat[arbitraryHourString] = {
            opens,
            closes,
            day: [day],
          };
        }
      }
    }
  }

  const getEarliestDayIndex = (days) => {
    for (let i = 0; i < daysOfWeek.length; i++) {
      if (days.includes(daysOfWeek[i])) return i;
    }
    return daysOfWeek.length;
  };

  return Object.values(hoursMultiLineFormat)
    .map((times) => ({ earliestDayIndex: getEarliestDayIndex(times.day), ...times }))
    .sort((a, b) => a.earliestDayIndex - b.earliestDayIndex)
    .map((days) => ({ ...days, earliestDayIndex: undefined }));
};

// Transforms hoursMultiLineFormat format to hoursInApiFormat
const transformToApiFormat = (hoursMultiLineFormat) => {
  if (!hoursMultiLineFormat?.length) return null;

  return hoursMultiLineFormat
    .filter((grouping) => !isEmpty(grouping))
    .reduce((hoursList, { opens, closes, day }) => {
      const newList = hoursList;

      for (const eachDay of day) {
        if (!newList[eachDay]) newList[eachDay] = [];
        newList[eachDay].push({
          opens,
          closes,
        });
      }

      return newList;
    }, {});
};

// Checks hoursMultiLineFormat for overlapping time ranges.  Return array of dates with overlaps
const hoursOverlap = (hoursMultiLineFormat) => {
  const daysWithOverlap = [];

  for (const [day, singleDaysHours] of Object.entries(transformToApiFormat(hoursMultiLineFormat))) {
    const sortedOpenTimes = singleDaysHours?.sort((a, b) => ((a.opens < b.opens) ? -1 : 1));
    let end = '00:00';
    for (const timeRange of sortedOpenTimes) {
      if (timeRange.opens < end) {
        daysWithOverlap.push(day);
        break;
      }
      end = timeRange.closes;
    }
  }

  return daysWithOverlap;
};

export default {
  transformApiToSingleLineFormat,
  groupByOpenHours,
  transformToApiFormat,
  hoursOverlap,
};
