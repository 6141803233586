import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  tracker,
} from '@unite-us/client-utils';
import IndexDetailView from 'src/components/Dashboard/components/IndexDetailView';
import RecalledReferralsIndex from 'src/components/Dashboard/components/Referrals/RecalledReferralsIndex';
import { fetchDashboardReferrals } from 'actions/Referral/Group';
import _ from 'lodash';
import DetailView from 'src/components/Dashboard/components/DetailView';
import {
  careCoordinatorsFilter,
  serviceTypesFilter,
  validateFilterValue,
} from 'src/components/Dashboard/utils/filter';
import ReferralDetail from 'src/components/Referrals/ReferralDetail/index';
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';
import {
  noneAssignedOption,
  careCoordinatorFilterDefault,
} from 'src/components/Dashboard/utils/filterDefaults';
import { getDataOfPage, isDataValid } from 'src/components/Dashboard/utils/dataHelpers';
import appendReferralIdToLocation from 'src/components/Dashboard/utils/appendReferralIdToLocation';
import callOrLog from 'src/common/utils/callOrLog';
import { goToReferral } from 'src/components/Referrals/utils/routing';
import {
  getReferralSubRouteFromStatus,
  getStatus,
} from 'src/components/Dashboard/components/Referrals/SentReferrals/utils';
import { DASHBOARD_EVENTS, DASHBOARD_VIEWS } from 'common/utils/EventTracker/utils/eventConstants';
import { getGroupUsersOptions, getSentByOptions } from 'src/components/Groups/Users/utils';
import labelCustomization from 'src/common/utils/Customization/labels';
import defaultLabels from 'src/constants/labels';
import { crtb1290SortReferralsByOldest } from 'src/common/utils/FeatureFlags/flags';

export class RecalledReferrals extends Component {
  constructor(props) {
    super(props);
    this.onCellClick = this.onCellClick.bind(this);
    this.onFiltersChange = this.onFiltersChange.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.shouldFetch = this.shouldFetch.bind(this);
    this.fetch = this.fetch.bind(this);
    this.fetchGroupsUsers = this.fetchGroupsUsers.bind(this);
    this.fetchSentByUsers = this.fetchSentByUsers.bind(this);

    this.state = {
      activeUsersInGroup: [],
      page: 1,
      sidx: 'created_at',
      sord: 'desc',
      filters: {
        service_types: _.get(props, 'filters.service_types', []),
        status: validateFilterValue(props.statuses, getStatus(props), 'recalled'),
        care_coordinator_users: _.get(
          props,
          'filters.care_coordinator_users',
          careCoordinatorFilterDefault(props.userId, props.isCaseManager && props.isCC),
        ),
        sent_by: _.get(
          props,
          'filters.sent_by',
          [props.currentEmployee.id],
        ),
      },
      employees: [],
    };
  }

  componentDidMount() {
    this.fetch();
    this.props.fetchProvidersUserCore({
      providers: this.props.groupId,
    }).then((response) => {
      if (response) {
        const { employees: activeUsersInGroup } = response;
        this.setState({ activeUsersInGroup, employees: response.employees });
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.get(this.props, 'recalledReferrals.currentPage', 1) !==
      _.get(nextProps, 'recalledReferrals.currentPage', 1)) {
      this.setState({ page: nextProps.recalledReferrals.currentPage });
    }

    if (!this.props.refetch && nextProps.refetch) {
      this.fetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.fetch();
    }
  }

  onCellClick(row) {
    const pagedData = getDataOfPage(this.props.recalledReferrals, this.state.page, this.props.sortAscending);
    const referral = pagedData.data[row];

    callOrLog(() => this.context.eventTracker(DASHBOARD_EVENTS.recalledReferralsRow, {
      view: DASHBOARD_VIEWS.recalledReferrals,
    }, { referral }));

    goToReferral(referral);
  }

  onFiltersChange(key, activeFilters, callbacks) {
    let newFilters = activeFilters;
    if (_.isEqual(key, 'care_coordinator_users')) {
      newFilters = careCoordinatorsFilter(activeFilters, callbacks);
    }

    this.setState({
      page: 1,
      filters: { ...this.state.filters, [key]: newFilters },
    }, () => {
      callOrLog(() => {
        const filters = tracker.buildDashboardFilterPayload(this.state, this.props);
        this.context.eventTracker(DASHBOARD_EVENTS.recalledReferralsFilter, filters);
      });
      this.fetch();
    });
  }

  onNextClick() {
    const page = this.state.page + 1;

    this.shouldFetch(page);
  }

  onPrevClick() {
    const page = this.state.page - 1;

    this.shouldFetch(page);
  }

  shouldFetch(page) {
    if (isDataValid(this.props.recalledReferrals, page)) {
      this.setState({ page });
    } else {
      this.fetch(page);
    }
  }

  fetch(page = 1) {
    const formattedFilters = this.state.filters;
    formattedFilters.sent_by = _.uniq(
      _.map(formattedFilters.sent_by, (f) => f.split('-employee')[0]),
    );
    this.props.fetchDashboardReferrals(
      this.props.groupId,
      getReferralSubRouteFromStatus(this.state.filters.status),
      {
        target: 'recalledReferrals',
        sidx: this.state.sidx,
        sord: this.state.sord,
        filters: formattedFilters,
        page,
      },
      this.state.filters.status,
      null,
    );
  }

  fetchGroupsUsers(search = '') {
    const { groupId } = this.props;
    return this.props.fetchProvidersUserCore({
      providers: groupId,
      options: {
        text: search,
        licensed: false,
      },
    }).then((response) => {
      if (response) {
        const { employees: activeUsersInGroup } = response;
        if (search === '') {
          return _.concat(
            [noneAssignedOption(this.state)],
            getGroupUsersOptions(activeUsersInGroup, this.state),
          );
        }

        return getGroupUsersOptions(activeUsersInGroup, this.state);
      }

      return [];
    });
  }

  fetchSentByUsers(search = '') {
    const { groupId, currentEmployee } = this.props;
    return this.props.fetchProvidersUserCore({
      providers: groupId,
      options: {
        licensed: false,
        text: search,
      },
    })
      .then((response) => {
        if (response) {
          return getSentByOptions(
            response.employees,
            this.state,
            currentEmployee,
          );
        }

        return [];
      });
  }

  render() {
    const {
      currentEmployee,
      baseLocation,
      isFetching,
      params,
      recalledReferrals,
      serviceTypes,
      labels,
    } = this.props;
    const { activeUsersInGroup, employees } = this.state;
    const location = appendReferralIdToLocation(baseLocation, params);

    const filters = [
      serviceTypesFilter(serviceTypes, this.state.filters.service_types),
      {
        key: 'care_coordinator_users',
        name: labels.CareCoordinator,
        pluralName: `${labels.CareCoordinator}s`,
        options: _.concat(
          [noneAssignedOption(this.state.filters.care_coordinator_users)],
          getGroupUsersOptions(activeUsersInGroup, this.state),
        ),
      },
      {
        key: 'sent_by',
        name: 'Sent By',
        pluralName: 'Sent By',
        options: getSentByOptions(employees, this.state, currentEmployee),
        asyncSearch: this.fetchSentByUsers,
      },
    ];

    const innerContentView = (
      <ReferralDetail
        subjectType="referral"
        key={params.id}
        location={location}
        params={params}
        type="recalledReferrals"
        showContactColumn
      />
    );

    const recalledReferralDetailView = (
      <DetailView
        innerContentView={innerContentView}
      />
    );
    const pagedData = getDataOfPage(recalledReferrals, this.state.page, this.props.sortAscending);

    return (
      <IndexDetailView
        params={this.props.params}
        DetailView={recalledReferralDetailView}
        IndexView={RecalledReferralsIndex}
        data={pagedData.data}
        paging={pagedData.paging}
        isFetching={isFetching}
        onNextClick={this.onNextClick}
        onPrevClick={this.onPrevClick}
        onCellClick={this.onCellClick}
        initialSort={{ [this.state.sidx]: this.state.sord }}
        onFiltersChange={this.onFiltersChange}
        filters={_.sortBy(filters, 'name')}
        fetchGroupsUsers={this.fetchGroupsUsers}
      />
    );
  }
}

RecalledReferrals.propTypes = {
  location: PropTypes.object,
  currentEmployee: PropTypes.object.isRequired,
  baseLocation: PropTypes.string.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  fetchDashboardReferrals: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isCaseManager: PropTypes.bool.isRequired,
  isCC: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  recalledReferrals: PropTypes.shape({
    data: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
  }).isRequired,
  refetch: PropTypes.bool.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  labels: PropTypes.object,
  sortAscending: PropTypes.bool,
};

RecalledReferrals.defaultProps = {
  labels: defaultLabels,
  sortAscending: false,
};

function mapStateToProps(state, ownProps) {
  const groupId = _.wget(state, 'session.groupId');
  return {
    currentEmployee: state.globalState.currentEmployee,
    baseLocation: ownProps.location.pathname,
    userId: _.wget(state, 'user.id'),
    groupId,
    recalledReferrals: _.wget(state, 'dashboard.recalledReferrals', {}),
    filters: _.wget(state, 'dashboard.recalledReferrals.filters', {}),
    isFetching: _.wget(state, 'dashboard.recalledReferrals.isFetching', false),
    serviceTypes: _.wget(state, 'serviceTypes.allNetworks', []),
    refetch: _.get(state, 'dashboard.refetch'),
    statuses: _.chain(state)
      .wget('session.enums.referrals.dashboard_statuses', [])
      .filter((status) => (
        _.includes(['auto_recalled', 'recalled'], status.value)
      ))
      .value(),
    isCC: _.wget(state, 'session.isCoordinationGroup', false),
    token: _.get(state, 'session.token', ''),
    labels: labelCustomization(state),
    sortAscending: crtb1290SortReferralsByOldest(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ fetchDashboardReferrals, fetchProvidersUserCore }, dispatch),
    dispatch,
  };
}

RecalledReferrals.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecalledReferrals);
