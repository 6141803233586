import React from 'react';
import { PropTypes } from 'prop-types';
import { isEligibilityPending } from '../../utils/isEligibilityPending';
import { NoResponseReceived } from './NoResponseReceived';
import { MissingNpiErrorMessage } from './MissingNpiErrorMessage';
import { MatchVerificationMessage } from './MatchVerificationMessage';
import { ExpiryMessage } from './ExpiryMessage';

const VerificationMessage = ({
  eligibility,
  isPending,
  isExpired,
}) => (
  <div className="py-4 space-y-2">
    <MatchVerificationMessage
      label="Member ID"
      pending={isPending}
      match={eligibility.member_id_match}
      successMessage="Match found for Medicaid ID"
      failureMessage="No match found for Medicaid ID"
    />
    <MatchVerificationMessage
      label="Managed Care Enrollment"
      pending={isPending}
      match={eligibility.managed_care_member}
      successMessage="Active in managed care or PIHP"
      failureMessage="Not active in managed care or PIHP"
    />
    <MatchVerificationMessage
      label="Medicaid Plan"
      pending={isPending}
      match={eligibility.payer_name_match}
      successMessage="Match found for plan"
      failureMessage="No match found for plan"
    />
    {isExpired && (
      <ExpiryMessage expiredDateString={eligibility.verified_at} />
    )}
  </div>
);

export const NcTracksVerification = ({
  eligibility,
}) => {
  const isPending = isEligibilityPending(eligibility);
  const isExpired = eligibility?.member_id_match && eligibility?.medicaid_member === false &&
    eligibility?.managed_care_member === false;
  const isNotVerified = eligibility?.status === 'not_verified';
  const isSkippedVerifyMissingNPI = eligibility?.status === 'skipped_verify_missing_npi';

  if (isNotVerified) return <NoResponseReceived />;
  if (isSkippedVerifyMissingNPI) return <MissingNpiErrorMessage />;

  return <VerificationMessage eligibility={eligibility} isPending={isPending} isExpired={isExpired} />;
};

NcTracksVerification.propTypes = {
  eligibility: PropTypes.object.isRequired,
};

VerificationMessage.propTypes = {
  eligibility: PropTypes.object.isRequired,
  isPending: PropTypes.bool.isRequired,
  isExpired: PropTypes.bool.isRequired,
};
