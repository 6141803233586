import { get } from 'lodash';
import { getFieldValue } from '.';

function getDeterminantNetworkId(fields) {
  const networkFieldValue = getFieldValue(fields.refer_to_outside_network) ?
    getFieldValue(fields.referred_to_network) :
    getFieldValue(fields.referred_by_network);
  return get(networkFieldValue, 'id');
}

export default getDeterminantNetworkId;
