import { useFindRecord, usePopulate } from 'src/api/APIHooks';
import { defaultQueryConfig } from 'src/components/Backoffice/api/hooks/apiHookOptions';

const httpConfig = {
  headers:
  { 'X-EMPLOYEE-ID': '' },
};

const useContactHqUserOnly = (query) => {
  const id = query;
  const { data, isFetching } = useFindRecord('person', id, {
    queryConfig: {
      ...defaultQueryConfig,
      enabled: !!query,
      onError: () => {},
    },
    httpConfig,
  });

  usePopulate('consent', 'consent', data, { httpConfig });

  return {
    data,
    isFetching,
  };
};

export default useContactHqUserOnly;
