import { uuCompactArrayOrObject } from 'lodash';
import { phonesInitialValues } from 'src/common/form/Profile/EditPhoneNumbers/utils';
import { emailsInitialValues } from 'src/common/form/Profile/EditEmailAddresses/utils';

export default function getInitialValuesFromContact(contact = {}) {
  const phones = phonesInitialValues(contact.phone_numbers);
  const emails = emailsInitialValues(contact.email_addresses);

  const initialValues = uuCompactArrayOrObject({
    addresses: contact.addresses,
    date_of_birth: contact.date_of_birth,
    email_addresses: emails,
    ethnicity: contact.ethnicity,
    first_name: contact.first_name,
    gender: contact.gender,
    last_name: contact.last_name,
    marital_status: contact.marital_status,
    military: contact.military,
    phone_numbers: phones,
    preferred_communication_method: contact.preferred_communication_method,
    preferred_communication_time_of_day: contact.preferred_communication_time_of_day,
    race: contact.race,
    ssn_last4: contact.ssn_last4,
    ssn: contact.ssn,
  });

  return initialValues;
}
