import _ from 'lodash';

const displayContactMethods = (methods) => (
  methods.map((method) => {
    switch (method) {
      case 'phone':
        return 'Phone Call';
      case 'sms':
        return 'Text';
      default:
        return _.startCase(_.toLower(method));
    }
  }).join(', ')
);

export default displayContactMethods;
