import React from 'react';

export default function addLineBreaks(text) {
  return (text && text.length > 0) ? (
    <span className="add-line-breaks">
      {
        text.split('\n').map((item, index) => (
          <span key={index}>
            {item}
            <br />
          </span>
        ))
      }
    </span>
  ) : '';
}
