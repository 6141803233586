import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { validateReduxForm } from 'common/form';
import {
  DurationField,
  RadioField,
  SelectField,
} from '@unite-us/ui';
import formatOptions from 'common/utils/formatOptions';

export const MILITARY_FORM_NAME = 'contact_military';

const fieldSet = [
  'affiliation',
  'current_status',
  'currently_transitioning',
  'at_least_one_day_active_duty',
  'branch',
  'service_era',
  'entry_date',
  'exit_date',
  'deployed',
  'deployment_starts_at',
  'deployment_ends_at',
  'discharge_type',
  'discharged_due_to_disability',
  'service_connected_disability',
  'service_connected_disability_rating',
  'proof_of_veteran_status',
  'proof_type',
];


class MilitaryForm extends Component {
  static onAffiliationChange() {
    setTimeout(() => window.dispatchEvent(new Event('resize')), 10);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.dirty && _.isFunction(this.props.militaryOnChange)) {
      this.props.militaryOnChange(nextProps.values);
    }
  }

  render() {
    const {
      fields,
      enums,
    } = this.props;

    return (
      <form className="military-form content-with-actions">
        <div className="content-container content-container--medium">
          <SelectField
            id="affiliation"
            label="Affiliation"
            field={fields.affiliation}
            options={formatOptions(enums.military.affiliation)}
            inline={false}
            onChange={MilitaryForm.onAffiliationChange}
          />
          <div className={(fields.affiliation.value === 'military_member_or_veteran') ? '' : 'hidden'}>
            <SelectField
              id="current_status"
              label="Current Status"
              field={fields.current_status}
              options={formatOptions(enums.military.current_status)}
              inline={false}
            />
            <div className="form-group">
              <div className="control-label">Currently Transitioning?</div>
              <RadioField
                id="transitioning"
                field={fields.currently_transitioning}
                options={formatOptions(enums.military.transitioning)}
              />
            </div>
            <div className="form-group">
              <div className="control-label">Has at least one day of active duty outside of basic training?</div>
              <RadioField
                id="active-duty"
                field={fields.at_least_one_day_active_duty}
                options={formatOptions(enums.military.active_duty)}
              />
            </div>
            <SelectField
              id="branch"
              label="Branch Of Service"
              field={fields.branch}
              options={formatOptions(enums.military.branch)}
              inline={false}
            />
            <SelectField
              id="era"
              label="Service Era"
              field={fields.service_era}
              options={formatOptions(enums.military.service_era)}
              inline={false}
              shouldSort={false}
            />
            <DurationField
              id="service-dates"
              label="Service Dates"
              startField={fields.entry_date}
              endField={fields.exit_date}
              inline={false}
            />
            <div className="form-group">
              <div className="control-label">Has client ever been deployed?</div>
              <RadioField
                id="deployed"
                field={fields.deployed}
                options={formatOptions(enums.military.deployed)}
              />
            </div>
            <div className={(fields.deployed.value === 'yes') ? '' : 'hidden'}>
              <DurationField
                id="deployment-dates"
                label="Deployment Dates"
                startField={fields.deployment_starts_at}
                endField={fields.deployment_ends_at}
                inline={false}
              />
            </div>
            <SelectField
              id="discharge"
              label="Military Discharge"
              field={fields.discharge_type}
              options={formatOptions(enums.military.discharge_type)}
              inline={false}
            />
            <div className="form-group">
              <div className="control-label">
                Discharged or retired from military service for a disability incurred in the line of duty?
              </div>
              <RadioField
                id="discharge-disability"
                field={fields.discharged_due_to_disability}
                options={formatOptions(enums.military.discharge_disability)}
              />
            </div>
            <div className="form-group">
              <div className="control-label">Has VA-rated Service-Connected Disability?</div>
              <RadioField
                id="connected-disability"
                field={fields.service_connected_disability}
                options={formatOptions(enums.military.connected_disability)}
              />
            </div>
            <div className={(fields.service_connected_disability.value === 'yes') ? '' : 'hidden'}>
              <SelectField
                id="disablility-rating"
                label="Service-Connected Disability Rating"
                field={fields.service_connected_disability_rating}
                options={formatOptions(enums.military.disability_rating)}
                inline={false}
                shouldSort={false}
              />
            </div>
            <div className="form-group">
              <div className="control-label">Proof of veteran status?</div>
              <RadioField
                id="proof"
                field={fields.proof_of_veteran_status}
                options={formatOptions(enums.military.proof_of_veteran_status)}
              />
            </div>
            <div className={(fields.proof_of_veteran_status.value === 'yes') ? '' : 'hidden'}>
              <SelectField
                id="proof-type"
                label="Proof Type"
                field={fields.proof_type}
                options={formatOptions(enums.military.proof_type)}
                inline={false}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

MilitaryForm.propTypes = {
  dirty: PropTypes.bool.isRequired,
  enums: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  militaryOnChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    initialValues: ownProps.contact.military,
    enums: _.wget(state, 'session.enums.contact_data', {}),
  };
}

export default validateReduxForm(
  {
    destroyOnUnmount: true,
    fields: fieldSet,
    form: MILITARY_FORM_NAME,
  },
  mapStateToProps,
)(MilitaryForm);
