import {
  INIT_FILE_UPLOAD,
  DESTROY_FILE_UPLOAD,
  DESTROY_ALL_FILES_UPLOADS,
  FILE_UPLOAD_ADD,
  CLEAR_FILE_UPLOAD,
} from 'actions';

export function initDropzone(config) {
  return {
    type: INIT_FILE_UPLOAD,
    payload: config,
  };
}

export function destroyDropzone(name) {
  return {
    type: DESTROY_FILE_UPLOAD,
    payload: name,
  };
}

export function destroyAllDropzones() {
  return {
    type: DESTROY_ALL_FILES_UPLOADS,
  };
}

export function clearFileUpload(name) {
  return {
    type: CLEAR_FILE_UPLOAD,
    name,
  };
}

export function addFileForSubmit(name, file, multipleDocForm, docIndex) {
  return (dispatch) => {
    dispatch({
      type: FILE_UPLOAD_ADD,
      name,
      payload: file,
      multipleDocForm,
      docIndex,
    });
  };
}
