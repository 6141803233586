export const INTERACTION_TYPES = {
  INTERACTION: 'Interaction',
  MESSAGE: 'Message',
  SERVICE_PROVIDED: 'Service Provided',
  GENERAL: 'General',
};

export const NOTE_VISIBILITY = {
  PUBLIC: 'public',
  PRIVATE: 'private',
};
