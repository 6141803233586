import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import Pagination from 'src/common/Pagination/Pagination';
import scrollToElement from 'src/common/utils/Form/ScrollToElement';
import { useFind } from 'src/api/APIHooks';
import { Spinner } from 'common/spinners';
import { browserHistory } from 'common/utils/browserHistory';
import formatDate from 'common/utils/Dates/formatDate';
import stateBgColor from 'common/utils/stateBgColor';
import EmptyTable from 'src/common/tables/EmptyTable/EmptyTable';
import {
  Badge,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from '@unite-us/ui';
import { tableWidth } from 'common/helpers/Tables';
import { DEFAULT_PAGE_SIZE } from 'src/components/Backoffice/backofficeUtils';

const FeeScheduleScreeningsTableHeader = () => (
  <TableHeader>
    <TableRow>
      <TableHeaderColumn style={{ width: tableWidth('large') }}>
        Name
      </TableHeaderColumn>
      <TableHeaderColumn style={{ width: tableWidth('small') }}>
        State
      </TableHeaderColumn>
      <TableHeaderColumn style={{ width: tableWidth('medium') }}>
        Start Date
      </TableHeaderColumn>
      <TableHeaderColumn style={{ width: tableWidth('medium') }}>
        End Date
      </TableHeaderColumn>
    </TableRow>
  </TableHeader>
);

const FeeScheduleScreeningsTable = ({ feeScheduleId }) => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const { data, isLoading } = useFind(
    'fee_schedule_screening',
    { fee_schedule: feeScheduleId },
    {
      queryConfig: {
        placeholderData: undefined,
      },
      page: {
        size: pageSize,
        number: pageNumber,
      },
    },
  );

  if (isLoading) return <Spinner />;

  const feeScheduleScreenings = data?.data?.data || [];
  const paging = data?.data?.paging || {};
  const scrollToElementEvent = () => {
    scrollToElement(document.getElementById('TopOfForms'));
  };

  const viewFeeScheduleScreening = (feeScheduleScreening) => {
    browserHistory.push({
      pathname: `/backoffice/fee_schedules/${feeScheduleId}/fee-schedule-screenings/${feeScheduleScreening.id}`,
    });
  };

  return (
    <>
      <Table id="fee-schedule-screenings-table">
        <FeeScheduleScreeningsTableHeader />
        <TableBody>
          {feeScheduleScreenings.map((feeScheduleScreening, idx) => (
            <TableRow
              dataTestId={`fee-schedule-screening-row-${idx}`}
              key={feeScheduleScreening.id}
              onCellClick={() => viewFeeScheduleScreening(feeScheduleScreening)}
            >
              <TableRowColumn style={{ width: tableWidth('large') }}>
                {feeScheduleScreening.name }
              </TableRowColumn>
              <TableRowColumn style={{ width: tableWidth('small') }}>
                <Badge
                  className="px-4"
                  text={capitalize(feeScheduleScreening.state)}
                  color={stateBgColor(feeScheduleScreening.state)}
                />
              </TableRowColumn>
              <TableRowColumn style={{ width: tableWidth('medium') }}>
                {formatDate(feeScheduleScreening.starts_at)}
              </TableRowColumn>
              <TableRowColumn style={{ width: tableWidth('medium') }}>
                {formatDate(feeScheduleScreening.ends_at)}
              </TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {(paging.total_count === 0) && (
        <EmptyTable
          className="p-6 border border-t-0 border-solid border-light-border-blue"
          message="No results found."
        />
      )}
      <div className="p-6">
        <Pagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalItemCount={paging.total_count}
          totalPageCount={paging.total_pages}
          setPageSize={setPageSize}
          setPageNumber={setPageNumber}
          scrollPaginationElement={scrollToElementEvent}
        />
      </div>
    </>
  );
};

FeeScheduleScreeningsTable.propTypes = {
  feeScheduleId: PropTypes.string.isRequired,
};

export default FeeScheduleScreeningsTable;
