import React from 'react';
import PropTypes from 'prop-types';

const Badge = ({ text, color, showText = true }) => (
  <span>
    <span className={`bg-${color} inline-block w-3 h-3 rounded-full mr-2`} />
    {showText && text}
  </span>
);

export const referralStatuses = {
  draft: ({ showText }) => <Badge text="Draft" color="medium-grey" showText={showText} />,
  sent: ({ showText }) => <Badge text="Needs Action" color="yellow" showText={showText} />,
  in_review: ({ showText }) => <Badge text="In Review" color="orange" showText={showText} />,
  accepted: ({ showText }) => <Badge text="Accepted" color="dark-green" showText={showText} />,
  declined: ({ showText }) => <Badge text="Rejected" color="red" showText={showText} />,
  recalled: ({ showText }) => <Badge text="Recalled" color="orange" showText={showText} />,
  closed: ({ showText }) => <Badge text="Closed" color="blue-700" showText={showText} />,
  off_platform: ({ hint542SupersetUnlistedPrograms, showText }) => (
    <Badge
      text={hint542SupersetUnlistedPrograms ? 'Off Platform' : 'Out of Network'}
      color="blue-700"
      showText={showText}
    />
  ),
  auto_recalled: ({ showText }) => <Badge text="Auto Recalled" color="yellow" showText={showText} />,
  forwarded: ({ showText }) => <Badge text="Forwarded" color="purple" showText={showText} />,
  pending_authorization: ({ showText }) => <Badge text="Needs Action" color="yellow" showText={showText} />,
  denied_authorization: ({ showText }) => <Badge text="Denied Authorization" color="orange" showText={showText} />,
  reviewed: ({ showText }) => <Badge text="Reviewed" color="blue-700" showText={showText} />,
  pending: ({ showText }) => <Badge text="Needs Action" color="yellow" showText={showText} />,
  open: ({ showText }) => <Badge text="Open" color="dark-green" showText={showText} />,
  processed: ({ showText }) => <Badge text="Processed" color="medium-grey" showText={showText} />,
};

Badge.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string.isRequired,
  showText: PropTypes.bool,
};
