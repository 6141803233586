import _ from 'lodash';

// Network-only exports are not available to providers
const PROVIDER_OMITTED_VALUES = [];

const NETWORK_OMITTED_VALUES = [
  'interaction',
];

const filterTypes = (types, typesToFilter) => (
  _.reduce(types, (acc, curr) => {
    if (_.includes(typesToFilter, curr.value)) {
      return acc;
    }
    return [...acc, curr];
  }, [])
);

const filterExportTypes = ({ targetType, exportTypes }) => {
  if (targetType === 'provider') {
    return filterTypes(exportTypes, PROVIDER_OMITTED_VALUES);
  }

  return filterTypes(exportTypes, NETWORK_OMITTED_VALUES);
};

export default filterExportTypes;
