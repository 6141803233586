function newReferralFormFields(base = '') {
  const baseAsNamespace = base ? `${base}.` : '';
  return [
    `${baseAsNamespace}auto_recallable`,
    `${baseAsNamespace}documents`,
    `${baseAsNamespace}notes`,
    `${baseAsNamespace}referred_by_network`,
    `${baseAsNamespace}referred_to_network`,
    `${baseAsNamespace}refer_to_outside_network`,
    `${baseAsNamespace}service_type`,
    // document uploads/attachments fields
    `${baseAsNamespace}attachableDocuments[].attached`,
    `${baseAsNamespace}attachableDocuments[].document`,
    `${baseAsNamespace}uploadedDocuments`,
    // referral group/program fields
    `${baseAsNamespace}selected[].group`,
    `${baseAsNamespace}selected[].program`,
    // oon case fields
    `${baseAsNamespace}isOONCase`,
    `${baseAsNamespace}oonCase.primary_worker`,
    `${baseAsNamespace}oonCase.program_entry`,
    `${baseAsNamespace}oonCase.selected[].group`,
    `${baseAsNamespace}oonCase.selected[].program`,
    `${baseAsNamespace}oonCase.custom[].group`,
    // authorization
    `${baseAsNamespace}authorizationRequest.amount_requested`,
    `${baseAsNamespace}authorizationRequest.service_start`,
    `${baseAsNamespace}authorizationRequest.service_end`,
    // misc
    `${baseAsNamespace}hasSensitiveError`,
    `${baseAsNamespace}itemID`,
  ];
}

export default newReferralFormFields;
