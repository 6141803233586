import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@unite-us/ui';
import { theme } from 'src/../tailwind.config';

const FormSubmitButtons = ({ isSaveDisabled, onDelete }) => (
  <div className="flex justify-end mt-8 mb-8">
    {onDelete && (
      <Button
        data-test-element="delete"
        label="Delete"
        className="text-md bg-transparent capitalize text-action-blue mr-4"
        type="button"
        onClick={onDelete}
        iconLeft={<Icon icon="V2Delete" color={theme.extend.colors['action-blue']} />}
        secondary
      />
    )}
    <Button
      data-test-element="save"
      label="Save"
      disabled={isSaveDisabled}
      type="submit"
      iconLeft={<Icon icon="V2Save" className="fill-current" />}
      primary
    />
  </div>
);

FormSubmitButtons.defaultProps = {
  isSaveDisabled: false,
  onDelete: null,
};

FormSubmitButtons.propTypes = {
  isSaveDisabled: PropTypes.bool,
  onDelete: PropTypes.func,
};

export default FormSubmitButtons;
