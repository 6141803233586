import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';

const RemovePhoneButton = ({
  styles,
  onClick,
}) => (
  <div className="remove-phone-button">
    <button
      className="remove-phone-button__link mt-half text-sm"
      onClick={onClick}
      type="button"
      style={styles.linkStyle}
      title="Remove phone number"
    >
      <Icon
        icon="IconTrash"
        style={styles.iconStyle}
      />
    </button>
  </div>
);

RemovePhoneButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    linkStyle: PropTypes.object,
    iconStyle: PropTypes.object,
  }),
};

RemovePhoneButton.defaultProps = {
  styles: {
    linkStyle: {
      display: 'block',
    },
    iconStyle: {
      svg: {
        width: '1.5em',
        height: '1.5em',
      },
    },
  },
};

export default RemovePhoneButton;
