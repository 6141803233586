import { browserHistory } from 'common/utils/browserHistory';
import {
  resetStoreConfigs,
  setCurrentGroup,
} from 'common/utils/Session/actions';

export default function openProvider(provider) {
  return (dispatch, getState) => {
    resetStoreConfigs()(dispatch);
    setCurrentGroup(provider)(dispatch, getState);
    browserHistory.push('/');
  };
}
