import _ from 'lodash';

export function careCoordinatorFilterDefault(userId, isCaseManager) {
  return isCaseManager ? [userId] : [];
}

export function noneAssignedOption(state) {
  return {
    label: 'None Assigned',
    value: 'none',
    initial: _.includes(state, 'none'),
  };
}

function validCaseLicenses(licensing, isCC) {
  const filteredLicenses = _.compact(_.map(licensing, 'subscription_type'));
  if (isCC) {
    return _.includes(filteredLicenses, 'network');
  }
  return _.includes(filteredLicenses, 'network') && _.includes(filteredLicenses, 'group');
}

export const NETWORK_SCOPE_OPTIONS = [
  { label: 'Network Scope (All)', value: 'all' },
  { label: 'In Network', value: 'in' },
  { label: 'Out of Network', value: 'out' },
];

export const CASE_TYPE_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Network Cases', value: 'network' },
  { label: 'Organization Cases', value: 'group' },
];

export function returnScopeFilter(licensing, isCC, options) {
  if (validCaseLicenses(licensing, isCC)) {
    return options;
  }

  return null;
}

export function networkGroupsOptions(networkGroups, activeFilters) {
  return _.reduce(networkGroups, (a, curr) => (
    _.reduce(curr, (acc, c) => (
      _.concat(acc, {
        label: c.name,
        value: c.id,
        initial: _.includes(activeFilters, c.id),
      })
    ), a)
  ), []);
}
