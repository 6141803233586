const formatInvoiceType = (type) => {
    if (type === 'provided_service') {
      return 'Service';
    } if (type === 'screening') {
      return 'Screening';
    }
    return type;
  };

export default formatInvoiceType;
