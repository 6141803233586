import { stripHTML, decodeHTML } from '@unite-us/ui';
import _ from 'lodash';

function getOONProviders(OONProviders, oonGroupsExist) {
  return OONProviders.map((provider) => {
    if (oonGroupsExist && provider !== undefined) {
      const { name, provider_type = 'CUSTOM' } = provider;
      return {
        group: {
          name: stripHTML(name),
          id: _.isEmpty(provider.id) ? stripHTML(name) : provider.id,
          provider_type,
        },
      };
    }

    return {
      // Encoding HTML into entities and then decoding them by reading the textContent.
      // See sanitize.js in packages/uniteus-ui for these utils
      group: provider ? decodeHTML(stripHTML(provider.name)) : '',
    };
  });
}

export default getOONProviders;
