import React from 'react';
import PropTypes from 'prop-types';
import { hasConsent } from 'src/components/Contacts/utils';
import { MessageEnabledCommunicationContent } from '.';
import { getMessageEnabled } from '../utils';

const MessageEnabledCommunicationContainer = ({ fields, contact }) => (
  <div className="message-enabled-communication-container mb-one mt-quarter">
    <MessageEnabledCommunicationContent
      fields={fields}
      contact_preference={contact.contact_preference}
      consentAccepted={hasConsent(contact)}
      enabledEmails={getMessageEnabled(contact.email_addresses)}
      enabledPhones={getMessageEnabled(contact.phone_numbers)}
    />
  </div>
);

MessageEnabledCommunicationContainer.propTypes = {
  fields: PropTypes.object.isRequired,
  contact: PropTypes.shape({
    consent: PropTypes.shape({
      status: PropTypes.string,
    }),
    phone_numbers: PropTypes.array,
    email_addresses: PropTypes.array,
    contact_preference: PropTypes.object,
  }).isRequired,
};

export default MessageEnabledCommunicationContainer;
