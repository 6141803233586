import React from 'react';
import PropTypes from 'prop-types';
import {
  BaseCard,
  BaseCardHeader,
  BaseCardBody,
} from '@unite-us/ui';
import _ from 'lodash';
import { isCaseOpen } from 'src/components/Cases/utils';
import CaseSummaryItem from './CaseSummaryItem';

/**
 *
 * @param cases
 * @returns object with group name as key and array of cases => {"group-name" : [caseObject, caseObject]}
 */
function createCaseGroup(cases) {
  return cases.reduce((acc, currentItem) => {
    if (acc[currentItem.provider.name]) {
      return _.assign({}, acc, { [currentItem.provider.name]: [...acc[currentItem.provider.name], currentItem] });
    }
    return _.assign({}, acc, { [currentItem.provider.name]: [currentItem] });
  }, {});
}

const CaseGroup = ({ cases }) => (
  <div>
    {_.map(cases, (groupCases, groupName) => (
      <div key={groupName}>
        {groupCases.map((serviceCase) => (<CaseSummaryItem key={serviceCase.id} serviceCase={serviceCase} />))}
      </div>
    ))}
  </div>
);

CaseGroup.propTypes = {
  cases: PropTypes.object.isRequired,
};

function CaseSummary(props) {
  const { contactCases } = props;
  const activeCases = _.filter(contactCases, (x) => isCaseOpen(x));

  return (
    <BaseCard className="case-summary" id="facesheet-case-summary">
      <BaseCardHeader title="Open Cases" />
      <BaseCardBody withPadding>
        <div className="row">
          {
            _.isEmpty(activeCases) ? null : (
              <div className="col-sm-12">
                <CaseGroup cases={createCaseGroup(activeCases)} />
              </div>
            )
          }
        </div>
      </BaseCardBody>
    </BaseCard>
  );
}

CaseSummary.propTypes = {
  contactCases: PropTypes.array,
};

export default CaseSummary;
