import _ from 'lodash';
import * as env from 'src/config/env/env.config';
import {
  APPLICATION_LOAD_FROM_SESSIONSTORAGE,
  CLEAR_REDIRECT_PATH,
  CLEAR_ZENDESK_RETURN_TO_URL,
  EULA_AGREEMENT_ACCEPTED,
  FETCH_BOOTSTRAP_ENUMS,
  FETCH_CURRENT_GROUP,
  FETCH_CURRENT_NETWORK,
  FETCH_GEO_POSITION,
  FETCH_IP_POSITION,
  FETCH_PROVIDER_TAGS_ERROR,
  FETCH_PROVIDER_TAGS_STARTED,
  FETCH_PROVIDER_TAGS_SUCCESS,
  FETCH_SUPPORT,
  FETCH_SERVICE_TYPES_FLATTENED,
  SET_AUTH_SESSION,
  SET_CURRENT_GROUP,
  SET_CURRENT_NETWORK,
  SET_EULA_ACCEPTANCE,
  SET_IMPERSONATION,
  SET_IS_COORDINATION_GROUP,
  SET_NETWORK_GROUPS,
  SET_REDIRECT_PATH,
  SET_ZENDESK_RETURN_TO_URL,
  UNSET_REFRESH_STATUS,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from 'actions';
import {
  STATUS_INITIAL,
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_ERROR,
} from 'common/Status/constants';
import { saveSessionToSessionStorage } from 'common/utils/utils';
import DEFAULT_ENUMS from 'src/reducers/constants/defaultEnums';

export const defaultSession = {
  access_token: null,
  app_support: {
    app: {
      analytics_proxy_url: env.TRACKING_URL,
    },
  },
  currentProvider: {},
  enums: DEFAULT_ENUMS,
  expires_at: null,
  groupId: null,
  hasFetchedSupport: false,
  impersonationType: null,
  intercomBootRequired: true,
  isCoordinationGroup: false,
  networkId: null,
  position: {
    geoCoordinates: {},
    ipCoordinates: {},
  },
  redirectPath: null,
  tags: {
    data: [],
    status: STATUS_INITIAL,
  },
  token: null,
  user_support: {},
  zendeskReturnToUrl: null,
  globals: {
    service_types_flattened: {},
  },
};

function setToSessionStorage(state) {
  const attributesToPersist = _.uuPick(state, [
    'app_support',
    'enums',
    'expires_at',
    'features',
    'globals',
    'hasFetchedSupport',
    'redirectPath',
    'user_support',
    'zendeskReturnToUrl',
    'impersonationType',
  ]);

  saveSessionToSessionStorage(attributesToPersist);
}

function duplicateCodeAsId(globals) {
  return {
    ...globals,
    service_types: _.reduce(globals.service_types, (acc, curr) => {
      if (_.isUndefined(curr.code)) {
        return [
          ...acc,
          { ...curr, code: curr.id },
        ];
      }

      return [
        ...acc,
        curr,
      ];
    }, []),
  };
}

export default function sessionReducer(state = defaultSession, action) {
  switch (action.type) {
    case APPLICATION_LOAD_FROM_SESSIONSTORAGE:
      return { ...state, ...action.payload.session };

    case CLEAR_REDIRECT_PATH: {
      const newState = {
        ...state,
        redirectPath: null,
      };
      setToSessionStorage(newState);
      return newState;
    }

    case CLEAR_ZENDESK_RETURN_TO_URL: {
      const newState = {
        ...state,
        zendeskReturnToUrl: null,
      };
      setToSessionStorage(newState);
      return newState;
    }

    case EULA_AGREEMENT_ACCEPTED: {
      const newState = {
        ...state,
        user_support: {
          ...state.user_support,
          has_accepted_eula_terms: true,
        },
      };

      setToSessionStorage(newState);
      return newState;
    }

    case SET_AUTH_SESSION: {
      const { access_token, token, expires_in } = action.payload.session;
      return {
        ...state,
        access_token,
        token,
        expires_in,
      };
    }

    case SET_EULA_ACCEPTANCE: {
      const payloadEulaStatus = action.payload.data.data.has_accepted_eula_terms;
      const stateEulaStatus = state.user_support.has_accepted_eula_terms;
      const has_accepted_eula_terms = _.isNil(stateEulaStatus) ? payloadEulaStatus : stateEulaStatus;

      return {
        ...state,
        user_support: {
          ...state.user_support,
          has_accepted_eula_terms,
        },
      };
    }

    case FETCH_BOOTSTRAP_ENUMS: {
      const {
        enums,
        features,
        global,
        support,
      } = action.payload.data.data;

      const newState = {
        ...state,
        enums: { ...state.enums, ...enums },
        features: { ...state.features, ...features },
        globals: { ...state.globals, ...duplicateCodeAsId(global) },
        app_support: {
          ...state.app_support,
          ...support,
        },
      };

      setToSessionStorage(newState);
      return newState;
    }

    case FETCH_SERVICE_TYPES_FLATTENED: {
      const newState = {
        ...state,
        globals: {
          ...state.globals,
          service_types_flattened: action.payload,
        },
      };

      setToSessionStorage(newState);
      return newState;
    }

    case FETCH_GEO_POSITION: {
      const geoCoordinates = {
        ...(_.get(action, 'payload.lat') && { lat: _.get(action, 'payload.lat') }),
        ...(_.get(action, 'payload.long') && { lng: _.get(action, 'payload.long') }),
      };

      return { ...state, position: { ...state.position, geoCoordinates } };
    }

    case FETCH_IP_POSITION: {
      const ipCoordinates = {
        ...(_.get(action, 'payload.lat') && { lat: _.get(action, 'payload.lat') }),
        ...(_.get(action, 'payload.long') && { lng: _.get(action, 'payload.long') }),
      };

      return { ...state, position: { ...state.position, ipCoordinates } };
    }

    case FETCH_SUPPORT: {
      const support = action.payload.data.data;
      const isImpersonation = _.get(action, 'impersonation', false);
      const stateEulaTerms = state.user_support.has_accepted_eula_terms;
      const eulaTermsStatus = _.isNil(stateEulaTerms) ? support.has_accepted_eula_terms : stateEulaTerms;

      const participationAgreementInformation = {
        has_accepted_eula_terms: isImpersonation ? true : eulaTermsStatus,
      };

      const newState = {
        ...state,
        hasFetchedSupport: true,
        user_support: {
          ...state.user_support,
          ...support,
          ...participationAgreementInformation,
        },
      };

      setToSessionStorage(newState);
      return newState;
    }

    case SET_CURRENT_GROUP:
      return {
        ...state,
        groupId: action.groupId,
      };

    case FETCH_CURRENT_GROUP:
      return {
        ...state,
        currentProvider: action.currentProvider,
      };

    case FETCH_CURRENT_NETWORK:
      return {
        ...state,
        currentNetwork: action.currentNetwork,
      };

    case SET_NETWORK_GROUPS:
      return {
        ...state,
        currentNetwork: {
          ...state.currentNetwork,
          providers: action.groups,
        },
      };

    case SET_CURRENT_NETWORK: {
      return { ...state, networkId: action.networkId };
    }

    case SET_IMPERSONATION: {
      return {
        ...state,
        impersonationType: action.value,
      };
    }

    case SET_IS_COORDINATION_GROUP:
      return { ...state, isCoordinationGroup: action.value };

    case SET_REDIRECT_PATH: {
      const newState = {
        ...state,
        redirectPath: {
          groupId: action.groupId,
          path: action.path,
        },
      };
      setToSessionStorage(newState);
      return newState;
    }

    case SET_ZENDESK_RETURN_TO_URL: {
      const newState = {
        ...state,
        zendeskReturnToUrl: action.payload,
      };
      setToSessionStorage(newState);
      return newState;
    }

    case FETCH_PROVIDER_TAGS_STARTED: {
      return {
        ...state,
        tags: {
          data: state.tags.data,
          status: STATUS_PENDING,
        },
      };
    }
    case FETCH_PROVIDER_TAGS_ERROR: {
      return {
        ...state,
        tags: {
          data: [],
          status: STATUS_ERROR,
        },
      };
    }
    case FETCH_PROVIDER_TAGS_SUCCESS: {
      const newState = {
        ...state,
        tags: {
          data: _.get(action, 'payload.data.data', []),
          status: STATUS_SUCCESS,
        },
      };
      setToSessionStorage(newState);
      return newState;
    }

    case UNSET_REFRESH_STATUS: {
      return { ...state, intercomBootRequired: false };
    }

    case USER_LOGIN_SUCCESS: {
      const newState = {
        ...state,
        ...action.payload.auth,
        user_support: {
          ...state.user_support,
          ...action.payload.support,
        },
      };

      setToSessionStorage(newState);
      return newState;
    }

    case USER_LOGOUT:
      return defaultSession;

    default:
      return state;
  }
}
