import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Tabs,
  Tab,
} from '@unite-us/ui';
import { get, kebabCase } from 'lodash';
import { INTERACTION_TYPES } from 'src/components/Notes/constants';

function getIdFromTabValue(tabValue) {
  return tabValue ? `interactions-${kebabCase(tabValue)}-tab` : null;
}

function createTab(tab) {
  const tabValue = get(tab, 'value');

  return (
    <Tab
      id={getIdFromTabValue(tabValue)}
      key={getIdFromTabValue(tabValue)}
      value={tabValue}
      label={tabValue}
    />
  );
}

class InteractionTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: get(this, 'props.selection.value', INTERACTION_TYPES.INTERACTION),
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    this.props.selectTab(value);
    this.setState({ value });
  }

  render() {
    const { tabs } = this.props;
    const createdTabs = tabs.map(createTab);

    return (
      <div className="interactions-tabs-container">
        <Tabs
          value={this.state.value}
          onChange={this.onChange}
          className="interactions-tabs"
        >
          {createdTabs}
        </Tabs>
      </div>
    );
  }
}

InteractionTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
  })).isRequired,
  selectTab: PropTypes.func.isRequired,
};

export default InteractionTabs;
