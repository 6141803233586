import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import callOrLog from 'common/utils/callOrLog';
import { SEARCH } from 'common/utils/EventTracker/utils/eventConstants';
import { viewable } from 'common/utils/Permission/Permission';
import {
  getItemResult,
  goToSearchResult,
} from '../../utils';
import {
  goToSearchIndex,
  getSearchBarColumnHeaders,
} from '../utils';

const SearchBarTableRow = ({ index, model, result }, context) => {
  const {
    closeSearchResults, eventTracker, query, session, clientIdStandardizationEnabled,
  } = context;
  const keyId = `${model}-${index}`;
  const isOSMac = ['MacIntel', 'MacPPC', 'Mac68k'].includes(navigator.platform);

  const onRowClick = () => {
    const resultItem = getItemResult(result);

    closeSearchResults();
    if (viewable(resultItem)) {
      goToSearchResult(model, result);
    } else {
      goToSearchIndex({ query });
    }

    callOrLog(() => eventTracker(SEARCH.clickResult, { model }));
  };

  const handleSpace = (e) => {
    if (e.keyCode === 32) {
      onRowClick();
    }
  };

  const tableConfig = getSearchBarColumnHeaders(model, clientIdStandardizationEnabled);
  const { headers, getRowValues } = tableConfig;
  const columns = map(headers, 'value');
  const rows = getRowValues(
    {
      result, columns, session, clientIdStandardizationEnabled,
    },
  )
    .map((column, i) => {
      const ariaLiveValue = (i === 0 && !isOSMac) ? 'polite' : undefined;
      return (
        <td
          className="search-result__column"
          key={`${keyId}-${column.label}`}
          aria-live={ariaLiveValue}
        >
          {column.value}
        </td>
      );
    });

  const r0Name = `name ${rows[0].props.children}`;
  const r1Phone = (rows[1] && rows[1].props.children) ? `, phone ${rows[1].props.children}` : '';
  const r2DOB = (rows[2] && rows[2].props.children) ? `, date of birth ${rows[2].props.children}` : '';
  const r3Loc = (rows[3] && rows[3].props.children) ? `, location ${rows[3].props.children}` : '';
  const r4ExtID = (rows[4] && rows[4].props.children) ? `, external ID ${rows[4].props.children}` : '';
  const accessibilityLabel = r0Name + r1Phone + r2DOB + r3Loc + r4ExtID;

  return (
    <tr
      className="search-result"
      id={keyId}
      onClick={onRowClick}
      onKeyDown={handleSpace}
      aria-label={accessibilityLabel}
      tabIndex={0}
    >
      {rows}
    </tr>
  );
};

SearchBarTableRow.propTypes = {
  index: PropTypes.number.isRequired,
  model: PropTypes.string.isRequired,
  result: PropTypes.object.isRequired,
};

SearchBarTableRow.contextTypes = {
  clientIdStandardizationEnabled: PropTypes.bool.isRequired,
  closeSearchResults: PropTypes.func.isRequired,
  eventTracker: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  session: PropTypes.object,
};

export default SearchBarTableRow;
