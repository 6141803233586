import { get, find } from 'lodash';
import isReferralForwarded from 'src/components/Referrals/ReferralDetail/utils/isReferralForwarded';
import isReferralClosedByCoordinationCenter from 'src/components/Referrals/ReferralDetail/utils/isReferralClosedByCoordinationCenter';

export function getDisplayName(status) {
  return get(status, 'display_name', '');
}

export function findDisplayName(value, statuses) {
  const status = find(statuses, { value });
  return getDisplayName(status);
}

export default function getStatusDisplayName(referral, statuses) {
  const forwardedReferral = isReferralForwarded({ serviceCase: get(referral, 'case_summary'), referral });
  const isClosedByCoordinationCenter = isReferralClosedByCoordinationCenter(referral);
  const status = get(referral, 'status');
  if (forwardedReferral) return 'Forwarded';
  if (isClosedByCoordinationCenter) return 'Reviewed';
  return findDisplayName(status, statuses);
}
