import { coreApi } from 'src/api/config';
import { PROVIDER_REFERRAL_SCOPES } from 'actions';

const getProviderReferralScopes = (providerId) => (
  async (dispatch) => {
    try {
      const response = await coreApi.query('referral_permissions', { provider: providerId });
      const referral_permissions = response.data.data;

      await Promise.all([
        coreApi.populateRelationship('recipient_network', 'network', referral_permissions),
      ]);

      const referralScopes = referral_permissions.map((scope) => ({
        id: scope.id,
        network: scope.network,
        permitted_groups: scope.recipient_providers,
        permitted_network: scope.recipient_network,
        referral_scope: scope.scope,
      }));

      dispatch({
        type: PROVIDER_REFERRAL_SCOPES,
        payload: referralScopes,
        providerId,
      });

      return referralScopes;
    } catch (error) {
      return error;
    }
  }
);

export default getProviderReferralScopes;
