import _ from 'lodash';

export function addParentToOptions(serviceTypes) {
  const result = _.reduce(serviceTypes, (acc, st) => {
    if (_.has(st, 'children') && !_.isEmpty(st.children)) {
      return [
        ...acc,
        _.assign({}, st, {
          children: [st, ...st.children],
        }),
      ];
    }
    return [...acc, st];
  }, []);
  return result;
}

export function parentsOnlyServiceTypesFilter(serviceTypes = [], initialFilters = []) {
  return {
    hideSelectAll: true,
    key: 'service_types',
    name: 'Service Type',
    pluralName: 'Service Types',
    options: serviceTypes.map((s = {}) => ({
      label: s.name,
      value: s.id === 'disabled' ? s.serviceTypeId : s.id,
      initial: _.includes(initialFilters, s.id),
    })),
  };
}

export default function serviceTypesFilter(serviceTypes, initialFilters = []) {
  return {
    hideSelectAll: true,
    key: 'service_types',
    name: 'Service Type',
    pluralName: 'Service Types',
    options: _.reduce(serviceTypes, (acc, s) => [...acc, {
      label: s.name,
      value: _.isEmpty(s.children) ? s.id : _.kebabCase(s.name),
      initial: _.includes(initialFilters, s.id),
      children: _.reduce(s.children, (a, st) => [...a, {
        label: st.name,
        value: st.id,
        initial: _.includes(initialFilters, st.id),
      }], []),
    }], []),
  };
}
