import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckBoxField } from '@unite-us/ui';
import { validations } from '@unite-us/app-components';
import AddDeleteButtons from 'src/common/form/AddDeleteButtons/AddDeleteButtons';
import { noop } from 'lodash';
import { CommunicationTypesCheckBoxes } from 'common/form';
import PhoneNumberField from 'common/form/Phone/PhoneNumberField';
import PhoneTypeField from 'common/form/Phone/PhoneTypeField';
import { anyCheckedValidation } from 'common/utils/Form';
import {
  phoneCanReceiveOralCommunication,
  phoneCanReceiveWrittenCommunication,
  phoneNumberNotEmptyValidation,
} from '../utils';
import './stylesheets/phoneFields.scss';

class PhoneFields extends Component {
  constructor(props) {
    super(props);

    this.handleRemovePhoneNumber = this.handleRemovePhoneNumber.bind(this);
    this.handleAddPhoneNumber = this.handleAddPhoneNumber.bind(this);
    this.onPrimaryChange = this.onPrimaryChange.bind(this);
  }

  handleRemovePhoneNumber() {
    this.props.remove();
  }

  handleAddPhoneNumber() {
    this.props.add();
  }

  onPrimaryChange() {
    this.props.onPrimaryChange(this.props.fields);
  }

  getId(name) {
    return `${name}-${this.state.uuid}`;
  }

  render() {
    const {
      fields,
      groupUser,
      hideLabel,
      id,
      index,
      hideCommunicationPreferences,
      length,
      multi,
      registerField,
      onAddBlur,
      allPhoneNumbers,
    } = this.props;

    return (
      <div className={`phone-field ${hideLabel && 'hide-label'}`}>
        <div className="row">
          <div className="col-xs-9">
            <div className="phone-field__wrapper">
              {
                !hideLabel && (
                  <label htmlFor={`${id}-number`} className="phone-field__label">
                    Phone Number
                  </label>
                )
              }
              <PhoneNumberField
                className="phone-field__digits"
                field={fields.phone_number}
                id={`${id}-number`}
                inline
                registerField={registerField}
                validations={[
                  { func: validations.isPhoneNumber },
                  {
                    func: () => validations.hasNoDuplicates(
                      index,
                      allPhoneNumbers,
                      "You can't add duplicate phone numbers.",
                      (phone) => phone?.replaceAll(/[^\d]/g, '')?.slice(0, 10),
                      { minLength: 10 },
                    ),
                  },
                  {
                    func: anyCheckedValidation(fields.acceptable_communication_types),
                    message: 'Required',
                  },
                ]}
              />
              <PhoneTypeField
                className={`${id}-type-select phone-field__phone-types`}
                field={fields.phone_type}
                groupUser={groupUser}
                id={`${id}-type`}
                inline
                registerField={registerField}
                validations={{
                  func: phoneNumberNotEmptyValidation(fields),
                  message: 'Required',
                }}
              />
              <CheckBoxField
                className="phone-field__is-primary"
                field={fields.is_primary}
                id={`${id}-primary`}
                inline
                inputStyle={{ marginRight: '0' }}
                label="Primary"
                onChange={this.onPrimaryChange}
                style={{
                  fontSize: '13px',
                }}
              />
            </div>

            {
              !hideCommunicationPreferences && (
                <div className="communication_preferences">
                  <div className="communication_preferences__label-spacer" />
                  <CommunicationTypesCheckBoxes
                    hideOralTypes={!phoneCanReceiveOralCommunication(fields.phone_type.value)}
                    hideWrittenTypes={!phoneCanReceiveWrittenCommunication(fields.phone_type.value)}
                    id={id}
                    fields={fields.acceptable_communication_types}
                    phone
                  />
                </div>
              )
            }
          </div>

          {
            multi &&
              (index === 0) ? (
                <div className="col-xs-3 phone-field__add-remove">
                  <AddDeleteButtons
                    index={index}
                    displayText={'Add Phone'}
                    length={length}
                    onAddClick={this.handleAddPhoneNumber}
                    onAddBlur={onAddBlur}
                  />
                </div>
              ) : (
                <div className="col-xs-3 phone-field__add-remove">
                  <AddDeleteButtons
                    index={index}
                    displayText={'Delete Phone'}
                    length={length}
                    onRemoveClick={this.handleRemovePhoneNumber}
                  />
                </div>
              )
          }
        </div>
      </div>
    );
  }
}

PhoneFields.propTypes = {
  add: PropTypes.func,
  fields: PropTypes.shape({
    acceptable_communication_types: PropTypes.object,
    is_primary: PropTypes.object.isRequired,
    phone_number: PropTypes.object.isRequired,
    phone_type: PropTypes.object.isRequired,
  }).isRequired,
  groupUser: PropTypes.bool,
  hideLabel: PropTypes.bool,
  hideCommunicationPreferences: PropTypes.bool,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  multi: PropTypes.bool,
  onPrimaryChange: PropTypes.func,
  registerField: PropTypes.func,
  remove: PropTypes.func,
  onAddBlur: PropTypes.func,
  allPhoneNumbers: PropTypes.array,
};

PhoneFields.defaultProps = {
  add: noop,
  groupUser: false,
  hideLabel: false,
  hideCommunicationPreferences: false,
  multi: true,
  onPrimaryChange: noop,
  registerField: noop,
  remove: noop,
  onAddBlur: noop,
  allPhoneNumbers: [],
};

export default PhoneFields;
