import { logoutUser } from 'actions/Login';
import { updateGlobalState } from 'src/actions/Global/globalActions';
import Notifier from 'common/helpers/Notifier';
import { isEmpty } from 'lodash';
import { coreApi } from 'src/api/config';

const onError = (dispatch, error) => {
  Notifier.handleErrors(error);
  return error;
};

const onSuccess = (dispatch, payload, networks) => {
  const updated = payload.map((network) => ({
    ...network,
    name: network.name,
  }));

  dispatch(updateGlobalState({
    activeNetworks: updated,
    allNetworks: networks,
  }));

  return payload;
};

export default function fetchActiveNetworks(providerId) {
  return async (dispatch) => {
    try {
      const response = await coreApi.findRecord('provider', providerId);
      const networkIds = response.data.data.networks.map((n) => n.id);
      if (isEmpty(networkIds)) {
        return dispatch(logoutUser());
      }
      const networksResponse = await coreApi.query('network', { ids: networkIds });
      const networks = networksResponse.data.data;

      const payload = networks.filter((network) => network.state === 'active');
      return onSuccess(dispatch, payload, networks);
    } catch (error) {
      return onError(dispatch, error);
    }
  };
}
