import { reject } from 'lodash';
import { getPermittedNetworks } from '.';

function getPermittedOutsideNetworks(scopes, networkId) {
  return reject(
    getPermittedNetworks(scopes, networkId),
    (network = {}) => network.id === networkId,
  );
}

export default getPermittedOutsideNetworks;
