import React from 'react';
import { PropTypes } from 'prop-types';
import { Icon } from '@unite-us/ui';
import { Card, CardDetail } from 'common/Card';
import { usePopulate } from '../../../../api/APIHooks';

const RejectionDetailsCard = ({ enrollmentRequest }) => {
  usePopulate('enrollment_rejection_reason', 'enrollment_rejection_reason', enrollmentRequest, {
    queryConfig: { placeholderData: undefined },
  });
  const reason = enrollmentRequest.enrollment_rejection_reason;
  return (
    <Card borderColor="border-red" className="mt-2 bg-white">
      <span className="flex px-6 py-4 text-xl font-extrabold text-text-blue">
        <Icon className="fill-current bg-white text-red h-18 mt-1 pr-3" icon="V2Warning" />
        Rejection Details
      </span>
      <hr className="border-dark-border-blue" />
      <div className="px-2 py-4 space-y-2">
        <CardDetail label="Rejection Reason">{reason.display_name}</CardDetail>
        <CardDetail label="Note">{enrollmentRequest.rejection_note}</CardDetail>
      </div>
    </Card>
  );
};

RejectionDetailsCard.propTypes = {
  enrollmentRequest: PropTypes.object.isRequired,
};

export default RejectionDetailsCard;
