import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Pager,
} from '@unite-us/ui';
import { browserHistory } from 'common/utils/browserHistory';
import { get, isEmpty, wget } from 'lodash';
import { fetchContactReferrals } from 'actions/Referral/Contact/Group';
import { Spinner } from 'common/spinners';
import ReferralTableView from 'src/components/Facesheet/Referrals/ReferralTableView';
import NoReferralsView from 'src/components/Facesheet/Referrals/NoReferralsView';
import 'src/components/Facesheet/Referrals/stylesheets/referrals.scss';

export class FacesheetReferrals extends Component {
  constructor(props) {
    super(props);
    this.fetchReferrals = this.fetchReferrals.bind(this);
    this.selectReferral = this.selectReferral.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.state = {
      fetching: false,
      page: 1,
    };
  }

  componentDidMount() {
    this.fetchReferrals();
  }

  onNextClick() {
    const page = this.state.page + 1;
    this.setState({ page }, () => {
      this.fetchReferrals(page);
    });
  }

  onPrevClick() {
    const page = this.state.page - 1;
    this.setState({ page }, () => {
      this.fetchReferrals(page);
    });
  }

  fetchReferrals(page = 1) {
    const { contactId } = this.props;
    this.setState({ fetching: true }, () => (
      this.props.fetchContactReferrals(
        null,
        contactId,
        { page },
      )
        .then(() => this.setState({ fetching: false }))
    ));
  }

  selectReferral(rowNumber) {
    const { referrals } = this.props;
    const selectedReferral = referrals[rowNumber];
    browserHistory.push(`/referrals/${selectedReferral.id}`);
  }

  render() {
    const {
      referrals,
      groupId,
      isCC,
      paging,
    } = this.props;
    const { fetching } = this.state;

    let content;

    if (fetching) {
      content = <Spinner />;
    } else if (!isEmpty(referrals)) {
      content = (
        <ReferralTableView
          referrals={referrals}
          groupId={groupId}
          isCC={isCC}
        />
      );
    } else {
      content = <NoReferralsView />;
    }

    return (
      <BaseCard>
        <BaseCardHeader title="Referrals">
          {
            referrals.length > 0 && (
              <div className="facesheet-cases__pagination">
                <Pager
                  paging={paging}
                  disabled={fetching}
                  onNextClick={this.onNextClick}
                  onPrevClick={this.onPrevClick}
                />
              </div>
            )
          }
        </BaseCardHeader>
        <BaseCardBody>
          {content}
        </BaseCardBody>
      </BaseCard>
    );
  }
}

FacesheetReferrals.propTypes = {
  contactId: PropTypes.string.isRequired,
  fetchContactReferrals: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isCC: PropTypes.bool.isRequired,
  referrals: PropTypes.array,
  paging: PropTypes.object.isRequired,
};

FacesheetReferrals.defaultProps = {
  referrals: [],
};

function mapStateToProps(state, ownProps) {
  const {
    contactReferrals,
    session: { groupId },
  } = state;
  const contactId = ownProps.params.id;
  const isCC = wget(state, 'session.isCoordinationGroup');
  const paging = get(contactReferrals, 'paging', {});

  return {
    contactId,
    groupId,
    isCC,
    referrals: get(contactReferrals, [contactId, 'data'], []),
    paging,
  };
}

export default connect(mapStateToProps, {
  fetchContactReferrals,
})(FacesheetReferrals);
