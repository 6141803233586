import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SelectField,
  BaseCard,
  BaseCardHeader,
  BaseCardBody,
} from '@unite-us/ui';
import { Field } from 'react-final-form';
import { usePrograms } from 'src/components/Organization/api/hooks/v1';
import { Spinner } from 'common/spinners';
import { get } from 'lodash';
import { referralsAdminAndAboveIds } from '../../../../User/utils/isReferralsAdminAndAbove';

export const UserSettingsForm = ({
  currentProviderId: providerId,
  isCC,
  allRoles,
  isPaymentsUser,
}) => {
  const userStateOptions = [
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ];
  const { data: programs, isLoading } = usePrograms(
    providerId,
    {
      excludeDeactivated: true,
      excludeProgramsWithFsps: !isPaymentsUser,
    },
  );

  const [selectableUserRoles, setSelectableUserRoles] = useState(allRoles.primaryRoles);

  const setUserRoleOptions = () => {
    if (isCC) {
      const ccUserRoles = referralsAdminAndAboveIds(allRoles);
      const userRoles = allRoles.primaryRoles.map((role) => {
        if (!ccUserRoles.includes(role.id)) {
          return {
            ...role,
            disabled: true,
          };
        }
        return role;
      });
      setSelectableUserRoles(userRoles);
    }
  };

  useEffect(() => {
    setUserRoleOptions();
  }, []);

  return (
    <BaseCard>
      <BaseCardHeader title="User Settings" />
      <BaseCardBody className="px-8 py-10">
        <div className="mb-8">
          <div className="font-heavy-font uppercase text-xs title" data-testid="user-status-title">
            User Status
          </div>
          <div className="text-dark-grey pb-2">
            A user&#39;s status controls their access to the Unite Us Platform.
            An inactive user cannot access the platform.
          </div>
          <Field name="user_status">
            {(params) => (
              <SelectField
                label="User Status"
                hideLabel
                dataTestElement="user_status"
                id="user-status"
                options={userStateOptions}
                valueKey="id"
                labelKey="name"
                {...params}
              />
            )}
          </Field>
          <div className="font-heavy-font uppercase text-xs title" data-testid="program-assignments-title">
            Program Assignments<span className="text-red pl-2">*</span>
          </div>
          <div className="text-dark-grey pb-2">
            A user should be assigned to those program(s) in which they work. A
            user must be assigned to a program in order to view and work on
            referrals/cases in that program.
          </div>
          {
            isLoading ? <Spinner /> : (
              <Field name="user_programs">
                {(params) => (
                  <SelectField
                    label="Program Assignments"
                    hideLabel
                    dataTestElement="user_programs"
                    placeholder="Select..."
                    id="user-programs"
                    multiple
                    options={programs}
                    valueKey="id"
                    labelKey="name"
                    {...params}
                  />
                  )}
              </Field>
            )
          }
          <div className="font-heavy-font uppercase text-xs title" data-testid="primary-role-title">
            User Role<span className="text-red pl-2">*</span>
          </div>
          <div className="text-dark-grey pb-2">
            A user&#39;s role controls their core experience on the platform and which features they can access.&nbsp;
            <a
              href="https://support.uniteus.io/en/articles/2812577-user-roles-and-permissions-overview"
              target="_blank"
              rel="noreferrer"
            >
              Learn more.
            </a>
          </div>
          <Field name="user_primary_role">
            {(params) => (
              <SelectField
                label="Primary Role"
                hideLabel
                dataTestElement="user_primary_roles"
                placeholder="Select..."
                id="user-primary-roles"
                options={selectableUserRoles}
                valueKey="id"
                labelKey="name"
                {...params}
              />
            )}
          </Field>
        </div>
      </BaseCardBody>
    </BaseCard>
  );
};
UserSettingsForm.propTypes = {
  currentProviderId: PropTypes.string.isRequired,
  isCC: PropTypes.string.isRequired,
  allRoles: PropTypes.shape({
    primaryRoles: PropTypes.array.isRequired,
  }).isRequired,
  isPaymentsUser: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const currentProviderId = get(state, 'session.groupId');
  const isCC = get(state, 'session.currentProvider.provider_type') === 'coordination_center';
  const allRoles = get(state, 'globalState.roles');

  return {
    currentProviderId,
    isCC,
    allRoles,
  };
}

export default connect(mapStateToProps)(UserSettingsForm);
