import PropTypes from 'prop-types';
import React, { useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { shapes, Banner } from '@unite-us/client-utils';
import { Link } from 'react-router';
import FeatureFlagContainer from 'src/common/utils/FeatureFlags/FeatureFlagContainer';
import { loadKeyFromSessionStorage, saveKeyToSessionStorage } from 'src/common/utils/utils';
import { useFeatureFlag } from 'common/hooks';
import { useFind } from 'src/api/APIHooks';
import { Spinner } from 'common/spinners';
import { DynamicTable } from 'common/tables';
import shouldHideData from 'src/components/Dashboard/utils/shouldHideData';
import defaultLabels from 'src/constants/labels';
import { isOrgAdmin } from 'src/components/User/utils';
import FilterBar from '../FilterBar';
import DashboardRowIcon from '../DashboardRowIcon';
import { createAlphabet } from '../../utils/filter';

function getPrimaryEmail(data) {
  const primaryEmail = _.find(data.email_addresses, { is_primary: true });
  if (!_.isEmpty(primaryEmail)) {
    return _.get(primaryEmail, 'email_address', '');
  }
  return _.get(data, 'email_addresses[0].email_address', '');
}

function getColumns(isCC) {
  if (isCC) {
    return [
      {
        label: 'Client Name',
        sortColumn: 'last_name_first_name',
      },
      {
        label: 'Phone',
        sortColumn: 'phone_numbers[0]',
        type: 'phoneNumber',
        cannotView: shouldHideData,
      },
      {
        label: 'Email',
        sortColumn: 'email_addresses',
        cannotView: shouldHideData,
        formatData: getPrimaryEmail,
      },
      {
        label: defaultLabels.CareCoordinator, /* Customization net yet required */
        sortColumn: 'primary_worker.full_name',
      },
    ];
  }
  return [
    {
      label: 'Client Name',
      sortColumn: 'last_name_first_name',
    },
    {
      label: 'Phone',
      sortColumn: 'phone_numbers[0]',
      type: 'phoneNumber',
    },
    {
      label: 'Email',
      sortColumn: 'email_addresses',
      formatData: (data) => {
        const primaryEmail = _.find(data.email_addresses, { is_primary: true });
        if (!_.isEmpty(primaryEmail)) {
          return _.get(primaryEmail, 'email_address', '');
        }
        return _.get(data, 'email_addresses[0].email_address', '');
      },
    },
  ];
}

export const AllClientsIndex = (props) => {
  const {
    paging,
    data,
    onNextClick,
    onPrevClick,
    isFetching,
    onCellClick,
    initialSort,
    isCC,
    filters,
    onFiltersChange,
    onLetterClick,
    fetchGroupsUsers,
    userIsAdmin,
  } = props;
  const isClientMergingEnabled = useFeatureFlag('cl-67-client-merging');
  const HIDE_DUPLICATES_KEY = 'hide-duplicates-warning';
  const [hideDuplicatesWarning, setHideDuplicatesWarning] = useState(!!loadKeyFromSessionStorage(HIDE_DUPLICATES_KEY));
  const { data: duplicatesClientsResponse } = useFind('people/duplicates', {}, {
    page: { size: 1, number: 1 },
    api: 'coreApi',
    queryConfig: {
      enabled: isClientMergingEnabled && userIsAdmin,
      placeholderData: undefined,
    },
  });

  const hideDuplicatesWarningHandler = () => {
    saveKeyToSessionStorage(HIDE_DUPLICATES_KEY, true);
    setHideDuplicatesWarning(true);
  };

  const totalDuplicates = duplicatesClientsResponse?.data?.meta?.page?.total_count || 0;
  const showDuplicatesWarning = totalDuplicates > 0 && !hideDuplicatesWarning;

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <div>
          <FilterBar
            filters={filters}
            onFiltersChange={onFiltersChange}
            paging={paging}
            onNextClick={onNextClick}
            onPrevClick={onPrevClick}
            isFetching={isFetching && !_.isEmpty(data)}
            onItemClick={onLetterClick}
            showItemFilter
            items={createAlphabet()}
            itemLabel="Filter by Last Name:"
            asyncSearch={fetchGroupsUsers}
          />
          <div className="dashboard-inner-content">
            {
              (isFetching && _.isEmpty(data)) ?
                <Spinner /> : (
                  <div>
                    {showDuplicatesWarning && (
                      <Banner
                        content={(
                          <>We found {totalDuplicates} client records with possible duplicates.&nbsp;
                            <Link
                              className="font-semibold text-action-blue"
                              to={{
                                pathname: '/people/duplicates',
                              }}
                            >
                              View duplicates.
                            </Link>
                          </>
                        )}
                        onDismiss={hideDuplicatesWarningHandler}
                        variant="warning"
                      />
                    )}
                    {totalDuplicates > 0 && (
                      <div className="flex justify-end py-2">
                        <Link
                          className="font-bold text-action-blue"
                          to={{
                            pathname: '/people/duplicates',
                          }}
                        >
                          View Duplicates
                        </Link>
                      </div>
                    )}
                    <DynamicTable
                      id="all-clients-table"
                      dataSet={data}
                      onCellClick={onCellClick}
                      initialSort={initialSort}
                      columnLabels={getColumns(isCC)}
                      emptyMessage="There are no clients."
                      rowIcon={DashboardRowIcon}
                    />
                  </div>
                )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { session } = state;

  return {
    userIsAdmin: isOrgAdmin(state.user, session.groupId),
  };
};

AllClientsIndex.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  paging: shapes.paging,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  onCellClick: PropTypes.func.isRequired,
  initialSort: PropTypes.object,
  isCC: PropTypes.bool.isRequired,
  filters: PropTypes.array,
  onFiltersChange: PropTypes.func,
  onLetterClick: PropTypes.func,
  fetchGroupsUsers: PropTypes.func.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(FeatureFlagContainer(AllClientsIndex));
