import React from 'react';
import PropTypes from 'prop-types';
import DialogV2 from 'common/modal/DialogV2';

const ArchiveDialog = React.forwardRef(
  ({
    cancelHandler, confirmationHandler, confirmationBtnDisabled, title, dialogId, itemName, itemType,
  }, setRef) => (
    <DialogV2
      cancelHandler={cancelHandler}
      confirmationHandler={confirmationHandler}
      confirmLabel="Archive"
      id={dialogId}
      ref={setRef}
      title={title}
      confirmationBtnDisabled={confirmationBtnDisabled}
      width="6xl"
    >
      <div>
        <p className="pb-2">You are about to archive {itemType} below.</p>
        <ul className="list-disc">
          <li className="text-text-blue font-extrabold">{itemName}</li>
        </ul>
        <p className="pt-8">Would you like to archive the {itemType}?</p>
      </div>
    </DialogV2>
  ),
  );

ArchiveDialog.propTypes = {
  cancelHandler: PropTypes.func.isRequired,
  confirmationHandler: PropTypes.func.isRequired,
  confirmationBtnDisabled: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  dialogId: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
};

export default ArchiveDialog;
