import {
  REFERRALS_SET_FETCHING,
  REFERRALS_UNSET_FETCHING,
  FETCH_RECEIVED_REFERRALS,
  FETCH_SENT_REFERRALS,
  FETCH_GROUPS_REFERRAL_SENT,
  FETCH_GROUPS_REFERRAL_RECEIVED,
  ACCEPT_REFERRAL_SENT,
  ACCEPT_REFERRAL_RECEIVED,
  HOLD_REFERRAL_SENT,
  HOLD_REFERRAL_RECEIVED,
  RECALL_REFERRAL_SENT,
  RECALL_REFERRAL_RECEIVED,
  REJECT_REFERRAL_SENT,
  REJECT_REFERRAL_RECEIVED,
  UPDATE_REFERRAL,
  UPDATE_PRIMARY_REFERRAL_WORKER_SENT,
  UPDATE_PRIMARY_REFERRAL_WORKER_RECEIVED,
  REFERRALS_SET_REFETCH,
  REFERRALS_UNSET_REFETCH,
  ADD_REFERRAL_INTERACTION,
  CLOSE_REFERRAL_SENT,
  CLOSE_REFERRAL_RECEIVED,
  REASSIGN_REFERRAL_PROGRAM,
} from 'actions';
import _ from 'lodash';

export const defaultState = {
  isFetching: false,
  sent: [],
  received: [],
  refetch: false,
};

function findAndReplaceReferral(state, key, data) {
  return {
    ...state,
    [key]: state[key].reduce((acc, curr) => {
      if (curr.id === data.id) {
        return [
          ...acc,
          data,
        ];
      }

      return [
        ...acc,
        curr,
      ];
    }, []),
  };
}

function insertOrReplaceReferral(state, key, data) {
  if (_.some(state[key], { id: data.id })) {
    return findAndReplaceReferral(state, key, data);
  }

  return {
    ...state,
    [key]: [
      ...state[key],
      data,
    ],
  };
}

export default function referralsReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_REFERRAL_INTERACTION:
    case REFERRALS_SET_REFETCH: {
      return { ...state, refetch: true };
    }

    case REFERRALS_UNSET_REFETCH: {
      return { ...state, refetch: false };
    }

    case REFERRALS_SET_FETCHING: {
      return { ...state, isFetching: true };
    }

    case REFERRALS_UNSET_FETCHING: {
      return { ...state, isFetching: false };
    }

    case FETCH_SENT_REFERRALS: {
      const sent = [...action.payload.data.data];
      return { ...state, sent };
    }

    case FETCH_RECEIVED_REFERRALS: {
      const received = [...action.payload.data.data];
      return { ...state, received };
    }
    case FETCH_GROUPS_REFERRAL_SENT: {
      return insertOrReplaceReferral(state, 'sent', action.payload.data.data);
    }

    case FETCH_GROUPS_REFERRAL_RECEIVED: {
      return insertOrReplaceReferral(state, 'received', action.payload.data.data);
    }

    case REJECT_REFERRAL_SENT:
    case HOLD_REFERRAL_SENT:
    case RECALL_REFERRAL_SENT:
    case UPDATE_PRIMARY_REFERRAL_WORKER_SENT:
    case ACCEPT_REFERRAL_SENT:
    case CLOSE_REFERRAL_SENT:
      return findAndReplaceReferral(state, 'sent', action.payload.data.data);

    case REJECT_REFERRAL_RECEIVED:
    case HOLD_REFERRAL_RECEIVED:
    case RECALL_REFERRAL_RECEIVED:
    case UPDATE_PRIMARY_REFERRAL_WORKER_RECEIVED:
    case ACCEPT_REFERRAL_RECEIVED:
    case CLOSE_REFERRAL_RECEIVED:
      return findAndReplaceReferral(state, 'received', action.payload.data.data);

    case UPDATE_REFERRAL: {
      const updatedReferral = action.payload.data.data;
      const sent = [...state.sent].reduce((prev, current) => {
        const shouldUpdateReferral = current.id !== updatedReferral.id;
        return shouldUpdateReferral ? [...prev, current] : [...prev, updatedReferral];
      }, []);

      const received = state.received.reduce((prev, current) => {
        const shouldUpdateReferral = current.id !== updatedReferral.id;
        return shouldUpdateReferral ? [...prev, current] : [...prev, updatedReferral];
      }, []);

      return {
        ...state,
        sent,
        received,
      };
    }

    case REASSIGN_REFERRAL_PROGRAM: {
      const referral = action.payload.data.data;
      const receivedReferrals = state.received;

      const updatedData = _.map(receivedReferrals, (receivedReferral) => (
        _.isEqual(receivedReferral.id, referral.id) ? referral : receivedReferral
      ));

      return { ...state, received: updatedData };
    }

    default:
      return state;
  }
}
