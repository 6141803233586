import {
  FETCH_SUBSCRIPTIONS_STARTED,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_ERROR,
} from 'actions';
import {
  STATUS_INITIAL,
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_ERROR,
} from 'common/Status/constants';
import { mergeIncludedById } from './utils';

export const defaultState = {
  data: [],
  status: STATUS_INITIAL,
};

export default function subscriptionReducer(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case FETCH_SUBSCRIPTIONS_STARTED:
      return { ...state, status: STATUS_PENDING };
    case FETCH_SUBSCRIPTIONS_ERROR:
      return { ...state, status: STATUS_ERROR };
    case FETCH_SUBSCRIPTIONS_SUCCESS: {
      // Removes non-licensed subscriptions via allotment filter
      const subscriptions = payload.data.data.filter((subscription) => {
        const groupAllotment = subscription.attributes.allotments.find(
          (allot) => allot.provider_id === payload.groupId,
        );

        return groupAllotment && groupAllotment.allotment > 0;
      });

      const subscriptionsMerged = mergeIncludedById({
        data: subscriptions,
        included: payload.data.included,
        includedFields: ['subscriber', 'package'],
      });

      return {
        ...state,
        data: {
          group: subscriptionsMerged.filter((subscription) => (
            subscription.subscriber.type === 'provider'
          )),
          network: subscriptionsMerged.filter((subscription) => (
            subscription.subscriber.type === 'network'
          )),
        },
        status: STATUS_SUCCESS,
      };
    }
    default:
      return state;
  }
}
