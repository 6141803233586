import Notifier from 'common/helpers/Notifier';
import { coreApi } from 'src/api/config';
import { isHttpSuccess } from 'src/api/utils/httpStatus';

const onError = (error) => {
  Notifier.handleErrors(error);
  return error;
};

export const updateCareTeamMember = async (personalRelationship) => {
  try {
    const { related_person: member } = personalRelationship;
    const responseAddresses = await coreApi.query('address', {
      person: member.id,
    });
    const existingAddresses = responseAddresses.data.data;
    const [responsePersonUpdate, responseRelationUpdate] = await Promise.all([
      coreApi.updateRecord('person', member.id, {
        ...member,
        addresses: undefined,
      }),
      coreApi.updateRecord('personal_relationship', personalRelationship.id, {
        relationship: personalRelationship.relationship,
      }),
    ]);
    if (!responsePersonUpdate || !isHttpSuccess(responsePersonUpdate.status)) {
      return onError(responsePersonUpdate);
    }
    if (
      !responseRelationUpdate ||
      !isHttpSuccess(responseRelationUpdate.status)
    ) {
      return onError(responseRelationUpdate);
    }
    const addressToDelete = existingAddresses.filter(
      (existing) => !member.addresses.find((address) => address.id === existing.id),
    );
    const addressToAdd = member.addresses.filter((address) => !address.id);
    const addressToUpdate = member.addresses.filter(
      (address) => existingAddresses.find((existing) => address.id === existing.id),
    );
    let promises = [];
    if (addressToDelete && addressToDelete.length) {
      promises = [
        ...promises,
        ...addressToDelete.map((address) => coreApi.deleteRecord('address', address.id)),
      ];
    }

    if (addressToAdd && addressToAdd.length) {
      promises = [
        ...promises,
        ...addressToAdd.map((address) => coreApi.createRecord('address', { ...address, person: member.id })),
      ];
    }

    if (addressToUpdate && addressToUpdate.length) {
      promises = [
        ...promises,
        ...addressToUpdate.map((address) => coreApi.updateRecord('address', address.id, { ...address })),
      ];
    }
    if (promises.length) {
      await Promise.all(promises);
    }
    return true;
  } catch (error) {
    return onError(error);
  }
};

export const createCareTeamMember = async (personalRelationship) => {
  try {
    const { related_person, person, relationship } = personalRelationship;
    const responsePerson = await coreApi.createRecord('person', {
      ...related_person,
      date_of_birth: 1023333555, // temporary placeholder until DOB is removed as a requirement of POST /people
    });
    if (!responsePerson || !isHttpSuccess(responsePerson.status)) {
      return onError(responsePerson);
    }
    const careTeamPerson = responsePerson.data.data;
    const responsePersonalRelationship = await coreApi.createRecord('personal_relationship', {
      person: person.id,
      related_person: careTeamPerson.id,
      relationship,
      care_team_member: true,
      family_member: false,
    });
    if (!responsePersonalRelationship || !isHttpSuccess(responsePersonalRelationship.status)) {
      return onError(responsePersonalRelationship);
    }

    if (related_person.addresses && related_person.addresses.length) {
      const addressPromises = related_person.addresses.map(
        (address) => coreApi.createRecord('address', { ...address, person: careTeamPerson.id }),
      );
      await Promise.all(addressPromises);
    }

    Notifier.dispatch(
      responsePersonalRelationship.status,
      `${careTeamPerson.full_name} has been added to the Care Team`,
    );

    return true;
  } catch (error) {
    return onError(error);
  }
};
