import moment from 'moment';
import Notifier from 'common/helpers/Notifier';
import getCoreError from 'src/api/utils/getCoreError';
import { coreApi } from 'src/api/config';
import { isHttpSuccess } from 'src/api/utils/httpStatus';

const onError = (error) => {
  Notifier.handleErrors(getCoreError(error));
  return error;
};

/**
 * @param {string} personId - ID of the person whose insurances are being requested.
 * @param {string} [feeScheduleIds] - Optional. Fee Schedule IDs separated by ',' to filter the insurances.
 * @param {string} [requestStartDate] - Optional. Date to filter insurances that were enrolled before this date.
 * @param {string} [requestEndDate] - Optional. Date to filter insurances that expire after this date.
 * @param {string} [sort] - Optional. Specifies the sorting order of the results.
 * @param {number} [page=1] - Optional. The page number of the results to fetch. Defaults to 1.
 * @param {number} [size=50] - Optional. The number of records per page. Defaults to 50.
 * @returns {Promise<Object>} API response containing the insurance data.
 * @throws {Error} Throws an error if the API call fails.
 */
export default async function fetchInsurances({
  personId,
  feeScheduleIds,
  requestStartDate,
  requestEndDate,
  isRefactorInsuranceFiltersEnabled,
  sort,
  page = 1,
  size = 50,
}) {
  try {
    let enrolledBefore = requestStartDate;
    let expiredAfter = requestEndDate;

    if (isRefactorInsuranceFiltersEnabled) {
      enrolledBefore = requestStartDate && moment(requestStartDate).add(1, 'day').toISOString();
      expiredAfter = requestEndDate && moment(requestEndDate).subtract(1, 'day').toISOString();
    }

    const params = {
      person: personId,
      state: 'active',
      ...(feeScheduleIds && { 'plan.fee_schedules': feeScheduleIds }),
      ...(requestStartDate && requestEndDate && {
        enrolled_before: enrolledBefore,
        expired_after: expiredAfter,
      }),
    };

    const response = await coreApi.query('insurance', params, {
      page: {
        number: page,
        size,
      },
      ...(sort && { sort }),
    });

    if (!response || !isHttpSuccess(response.status)) {
      return onError('Failed to get insurances');
    }
    const { data: { data: insurances } } = response;
    return insurances;
  } catch (error) {
    return onError(error);
  }
}
