import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIsNetworkLead } from 'common/contexts/CurrentProviderContext';
import { hasPayerInvoicesRole as getHasPayerInvoicesRole } from 'common/utils/FeatureFlags/flags';
import { LogoCard } from 'src/common/TailwindComponents';
import InvoiceWorkqueue from './InvoiceWorkqueue';
import { CBO_ADMIN_ARCHIVABLE_STATUSES, NETWORK_LEAD_ARCHIVABLE_STATUSES, PAYER_ARCHIVED_STATUS_OPTIONS } from '../constants';
import { NoFilteredInvoicesFound } from '../components';

const NoInvoicesExist = () => (
  <>
    <h4 className="font-heavy-font text-base text-dark-grey">
      You don&apos;t have any invoices yet.
    </h4>
    <p className="max-w-xs text-dark-grey">
      Invoices are added to this list whenever a contracted service has been submitted for a client.
    </p>
  </>
);

const ArchivedInvoicesDWQ = ({ children, hasPayerInvoicesRole }) => {
  const isNetworkLead = useIsNetworkLead();
  const invoiceStatus = isNetworkLead ?
    NETWORK_LEAD_ARCHIVABLE_STATUSES.join(',') : CBO_ADMIN_ARCHIVABLE_STATUSES.join(',');

  const selectStatusOptionsConstant = isNetworkLead ? 'NL_ARCHIVED_STATUS_OPTIONS' : 'ARCHIVED_STATUS_OPTIONS';

  const bulkActions = ['IconDownloadV2', 'IconUndo'];

  return (
    <InvoiceWorkqueue
      bulkActions={bulkActions}
      invoiceStatus={hasPayerInvoicesRole ? PAYER_ARCHIVED_STATUS_OPTIONS : invoiceStatus}
      archived
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <LogoCard>
          {isFiltered ? (
            <NoFilteredInvoicesFound resetFilters={resetFilters} />
          ) : (
            <NoInvoicesExist />
          )}
        </LogoCard>
      )}
      showStatusFilter
      statusOptionsConstant={hasPayerInvoicesRole ? 'PAYER_ARCHIVED_STATUS_OPTIONS' : selectStatusOptionsConstant}
      path="archive/"
    >
      {children}
    </InvoiceWorkqueue>
  );
};

ArchivedInvoicesDWQ.propTypes = {
  children: PropTypes.node,
  hasPayerInvoicesRole: PropTypes.bool,
};

ArchivedInvoicesDWQ.defaultProps = {
  children: undefined,
  hasPayerInvoicesRole: false,
};

const mapStateToProps = (state) => ({
  hasPayerInvoicesRole: getHasPayerInvoicesRole(state),
});

export default connect(mapStateToProps)(ArchivedInvoicesDWQ);
