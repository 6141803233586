import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { Drawer } from '@unite-us/ui';
import { TOP_NAVIGATION_HEIGHT } from 'src/components/Navigation/constants';
import { showFeeScheduleFilter as displayFeeScheduleFilter } from 'src/components/Browse/utils/filters';
import { getEmployee } from 'src/components/Employee/employeeGetters';
import { fetchStatesGeography } from 'actions/Geography';
import { hasPaymentsUserAccess } from 'common/utils/FeatureFlags/flags';
import FeeScheduleFilter from './FeeScheduleFilter';
import GeoFilter from './GeoFilter';
import SpecializedSupport from './SpecializedSupport';

export class FiltersDrawer extends Component {
  constructor(props) {
    super(props);

    this.clearNetworkBrowseFilters = this.clearNetworkBrowseFilters.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    if (
      !isEqual(this.props.filters, nextProps.filters) ||
      this.props.open !== nextProps.open
    ) {
      return true;
    }
    return false;
  }

  clearNetworkBrowseFilters() {
    this.props.clearNetworkBrowseFilters();
  }

  render() {
    const {
      contact,
      currentUserGroup,
      employee,
      geoCoordinates,
      ipCoordinates,
      filters,
      groupsOptionType,
      networkId,
      showFeeScheduleFilter,
    } = this.props;

    return (
      <div ref={this.props.inputRef} className="browse-filters-drawer" hidden={!this.props.open}>
        <Drawer
          onClose={this.props.closeFiltersDrawer}
          open={this.props.open}
          secondary
          topOffset={TOP_NAVIGATION_HEIGHT}
          width="380px"
        >
          <div className="browse-filters-drawer__contents">
            <div className="browse-filters-drawer__header">
              <h2 className="browse-filters-drawer__header-label">Filters</h2>
              <div className="browse-filters-drawer__header-clear-all text-right pull-right hidden">
                <a onClick={this.clearNetworkBrowseFilters}>Clear All</a>
              </div>
            </div>

            <div className="browse-filters-drawer__body">
              <SpecializedSupport
                label="Specialized Support"
                onFiltersChange={this.props.onFiltersChange}
                filters={filters}
              />

              <GeoFilter
                contact={contact}
                geoCoordinates={geoCoordinates}
                ipCoordinates={ipCoordinates}
                currentUserGroup={currentUserGroup}
                filters={filters}
                label="Distance"
                onFiltersChange={this.props.onFiltersChange}
                setGeoFilters={this.props.setGeoFilters}
                employee={employee}
                hidden={!this.props.open}
              />

              {showFeeScheduleFilter && displayFeeScheduleFilter(groupsOptionType) && (
                <FeeScheduleFilter
                  contact={contact}
                  employee={employee}
                  filters={filters}
                  networkId={networkId}
                  onFiltersChange={this.props.onFiltersChange}
                />
              )}

              <div className="browse-filters-drawer__header-clear-all">
                <a onClick={this.clearNetworkBrowseFilters}>Clear All</a>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

FiltersDrawer.propTypes = {
  clearNetworkBrowseFilters: PropTypes.func.isRequired,
  closeFiltersDrawer: PropTypes.func.isRequired,
  contact: PropTypes.object,
  currentUserGroup: PropTypes.object.isRequired,
  employee: PropTypes.object.isRequired,
  filters: PropTypes.shape({
    serviceTypes: PropTypes.array.isRequired,
  }).isRequired,
  geoCoordinates: PropTypes.object.isRequired,
  groupsOptionType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  inputRef: PropTypes.func.isRequired,
  ipCoordinates: PropTypes.object.isRequired,
  networkId: PropTypes.string.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setGeoFilters: PropTypes.func.isRequired,
  showFeeScheduleFilter: PropTypes.bool.isRequired,
};

FiltersDrawer.defaultProps = {
  contact: {},
  groupsOptionType: ['', []],
};

function mapStateToProps(state) {
  const { position } = state.session || {};
  const { geoCoordinates = {}, ipCoordinates = {} } = position;

  const employee = getEmployee({ state });
  const showFeeScheduleFilter = hasPaymentsUserAccess(state);

  return {
    employee,
    geoCoordinates,
    ipCoordinates,
    showFeeScheduleFilter,
  };
}

export default connect(
  mapStateToProps,
  { fetchStatesGeography },
)(FiltersDrawer);
