import {
  FETCH_FEE_SCHEDULE_PROGRAM_STARTED,
  FETCH_FEE_SCHEDULE_PROGRAM_SUCCESS,
  FETCH_FEE_SCHEDULE_PROGRAM_ERROR,
  CREATE_FEE_SCHEDULE_PROGRAM_STARTED,
  CREATE_FEE_SCHEDULE_PROGRAM_SUCCESS,
  CREATE_FEE_SCHEDULE_PROGRAM_ERROR,
  UPDATE_FEE_SCHEDULE_PROGRAM_STARTED,
  UPDATE_FEE_SCHEDULE_PROGRAM_SUCCESS,
  UPDATE_FEE_SCHEDULE_PROGRAM_ERROR,
} from 'actions';
import {
  STATUS_INITIAL,
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_ERROR,
} from 'common/Status/constants';

export const defaultState = {
  status: STATUS_INITIAL,
  data: {},
};

export default function feeScheduleProgramsReducer(state = defaultState, action) {
  const { payload, type } = action;

  switch (type) {
    case CREATE_FEE_SCHEDULE_PROGRAM_STARTED:
    case UPDATE_FEE_SCHEDULE_PROGRAM_STARTED:
    case FETCH_FEE_SCHEDULE_PROGRAM_STARTED:
      return { ...state, status: STATUS_PENDING };
    case CREATE_FEE_SCHEDULE_PROGRAM_ERROR:
    case UPDATE_FEE_SCHEDULE_PROGRAM_ERROR:
    case FETCH_FEE_SCHEDULE_PROGRAM_ERROR:
      return { ...state, status: STATUS_ERROR };
    case CREATE_FEE_SCHEDULE_PROGRAM_SUCCESS:
    case UPDATE_FEE_SCHEDULE_PROGRAM_SUCCESS:
    case FETCH_FEE_SCHEDULE_PROGRAM_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.id]: payload,
        },
        status: STATUS_SUCCESS,
      };
    default:
      return state;
  }
}
