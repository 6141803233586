import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import {
  InputField,
  DateField,
  SelectField,
  FormLegend,
} from '@unite-us/ui';
import today from 'common/utils/today';

class BasicInformation extends Component {
  render() {
    const {
      registerField,
      fields,
      validations,
      enums,
    } = this.props;

    const peopleEnums = _.wget(enums, 'people', {});

    return (
      <div className="basic-information">
        <h3 className="basic-information__title">Personal Information</h3>
        <FormLegend />

        <hr />

        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <InputField
              ref={registerField}
              field={fields.first_name}
              label="First Name"
              id="first-name"
              required
              validations={validations.isRequired}
            />
          </div>

          <div className="col-xs-12 col-sm-6">
            <InputField
              ref={registerField}
              field={fields.last_name}
              label="Last Name"
              id="last-name"
              required
              validations={validations.isRequired}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <DateField
              ref={registerField}
              field={fields.date_of_birth}
              label="Date of Birth"
              id="date-of-birth"
              maxDate={today()}
              required
              validations={validations.isRequired}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <InputField
              id="social-security-number"
              className="basic-information__social-security-number"
              label="Social Security Number"
              field={fields.ssn}
              placeholder={`*** - ** - ${fields.ssn_last4.value || '***'}`}
              mask="999-99-9999"
              ref={registerField}
              validations={{
                func: (value, message) => {
                  const reg = /\d{3}-\d{2}-\d{4}/;
                  return (value && (!reg.test(value))) ? message : null;
                },
                message: 'SSN should be 9 digits',
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <SelectField
              className="basic-information-gender"
              ref={registerField}
              id="gender"
              field={fields.gender}
              label="Gender"
              options={peopleEnums.genders}
              labelKey="display_name"
              valueKey="value"
              validations={validations.isRequired}
              required
              searchEnabled={false}
              inline={false}
            />
            <SelectField
              className="basic-information-marital-status"
              ref={registerField}
              id="marital-status"
              field={fields.marital_status}
              label="Marital Status"
              options={peopleEnums.marital_status}
              labelKey="display_name"
              valueKey="value"
              validations={validations.isRequired}
              required
              searchEnabled={false}
              inline={false}
            />
            <SelectField
              className="basic-information-race"
              ref={registerField}
              id="race"
              field={fields.race}
              label="Race"
              options={peopleEnums.race}
              labelKey="display_name"
              valueKey="value"
              validations={validations.isRequired}
              required
              searchEnabled={false}
              inline={false}
            />
            <SelectField
              className="basic-information-ethnicity"
              ref={registerField}
              id="ethnicity"
              field={fields.ethnicity}
              label="Ethnicity"
              options={peopleEnums.ethnicity}
              labelKey="display_name"
              valueKey="value"
              validations={validations.isRequired}
              required
              searchEnabled={false}
              inline={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

BasicInformation.propTypes = {
  registerField: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  validations: PropTypes.object.isRequired,
  enums: PropTypes.object.isRequired,
};

BasicInformation.fields = [
  'first_name',
  'last_name',
  'date_of_birth',
  'gender',
  'marital_status',
  'race',
  'ethnicity',
  'ssn',
  'ssn_last4',
];

export default BasicInformation;
