function scopeAllowsMultipleGroups(referralScope = {}, suggestedGroups = []) {
  switch (referralScope.referral_scope) {
    case 'all_groups':
      return true;

    case 'some_groups':
    case 'coordination_center_only':
      return suggestedGroups.length > 1;
    default:
      return false;
  }
}

export default scopeAllowsMultipleGroups;
