import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Interactions from 'common/form/Interactions';
import { createReferralInteraction } from 'actions/Referral/Group';
import { INTERACTION_TYPES } from 'src/components/Notes/constants';
import callOrLog from 'src/common/utils/callOrLog';
import { REFERRAL } from 'common/utils/EventTracker/utils/eventConstants';
import { Serializer } from '@unite-us/client-utils';
import {
  useInvalidateQueries,
} from 'src/api/APIHooks';

export const ReferralInteractions = (props) => {
  const {
    styles,
    referral,
    eventTracker,
    cancelFunction,
  } = props;
  const invalidateQueries = useInvalidateQueries();

  const submitAction = (formValues, category) => {
    const { interaction = {} } = formValues;
    const eventPayload = Serializer.build({ interaction });
    return props.createReferralInteraction({
      referralId: referral.id,
      interaction: {
        ...formValues.interaction,
        category,
      },
    }).then(() => {
      invalidateQueries('notes');
      cancelFunction();
      callOrLog(() => eventTracker(REFERRAL.noteAdded, eventPayload, {
        referral,
      }));
    });
  };

  const submitOther = (formValues) => (
    submitAction(formValues, 'general')
  );

  const submitInteraction = (formValues) => (
    submitAction(formValues, 'interaction')
  );

  const interactionList = [
    { value: INTERACTION_TYPES.INTERACTION, submitFunction: submitInteraction },
    { value: INTERACTION_TYPES.GENERAL, submitFunction: submitOther },
  ];

  return (
    <div style={styles.position}>
      <Interactions
        detailType="referral"
        interactionList={interactionList}
        isFromCaseOrReferral
        cancelFunction={cancelFunction}
      />
    </div>
  );
};

ReferralInteractions.propTypes = {
  referral: PropTypes.object.isRequired,
  createReferralInteraction: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired,
  cancelFunction: PropTypes.func.isRequired,
  eventTracker: PropTypes.func.isRequired,
};

ReferralInteractions.defaultProps = {
  styles: {
    position: { marginTop: '20px' },
  },
};

export default connect(null, { createReferralInteraction })(ReferralInteractions);
