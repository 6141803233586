import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ProfilePhoneFields } from 'common/form/Profile';
import { generateUUID } from 'common/utils/utils';
import { updateEmployee } from 'actions/User';
import _ from 'lodash';
import { validateReduxForm } from 'common/form';
import { Button } from '@unite-us/ui';
import {
  phonesInitialValues,
} from 'common/form/Profile/EditPhoneNumbers/utils';
import updateCurrentEmployeeState from 'src/api/core/Employees/updateCurrentEmployeeState';
import '../../stylesheets/editPhoneNumbers.scss';

const EDIT_PHONE_NUMBERS = 'editPhoneNumbers';

class EditPhoneNumbers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newIds: [],
    };

    this.onPrimaryChange = this.onPrimaryChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.addField = this.addField.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deletePhoneNumber = this.deletePhoneNumber.bind(this);
    this.removeField = this.removeField.bind(this);
    this.removeOrDelete = this.removeOrDelete.bind(this);
  }

  onSubmit() {
    const {
      fields,
      currentEmployee,
    } = this.props;

    const phone_numbers = fields.phones.map((phone = {}) => ({
      country_code: '1',
      is_primary: phone.is_primary.value || false,
      phone_number: phone.phone_number.value.replace(/\D/g, ''),
      extension: phone.extension.value,
      phone_type: phone.phone_type.value,
    }));

    return this.props.updateEmployee(currentEmployee.id, { phone_numbers })
      .then((response) => {
        this.props.updateCurrentEmployeeState(response);
        this.closeModal();
      });
  }

  onPrimaryChange(phone) {
    _.each(this.props.fields.phones, (field) => {
      if (field !== phone) {
        field.is_primary.onChange(false);
      }
    });
    phone.is_primary.onChange(true);
  }

  deletePhoneNumber(id) {
    this.removeField(id);
  }

  addField() {
    const { fields } = this.props;
    const id = generateUUID();
    fields.phones.addField({ id });
    this.setState({
      newIds: [...this.state.newIds, id],
    });
  }

  closeModal() {
    this.props.resetForm();
    this.props.closeModal();
  }

  removeField(idToRemove) {
    const { fields: { phones } } = this.props;
    const index = _.findIndex(phones, (p) => p.id.value === idToRemove);
    phones.removeField(index);
    this.setState({
      newIds: _.filter(this.state.newIds, (id) => id !== idToRemove),
    });
  }

  removeOrDelete(idToRemove, phones) {
    if (_.includes(this.state.newIds, idToRemove)) {
      this.removeField(phones, idToRemove);
    } else {
      this.deletePhoneNumber(idToRemove);
    }
  }

  render() {
    const {
      fields: { phones },
      handleSubmit,
      invalid,
      registerField,
      styles,
      submitting,
    } = this.props;

    const phonePrimariesExist = _.includes(_.map(phones, 'is_primary.value', []), true);

    const phoneForms = phones.map((phone, index) => (
      <div
        id={`inline-phone-${index}`}
        key={`phoneFields-${phone.id.name}`}
        style={index !== phones.length - 1 ? styles.inlinePhoneContainer : {}}
        className="profile-phone"
      >
        <ProfilePhoneFields
          fields={phone}
          hideCommunicationPreferences
          onPrimaryChange={this.onPrimaryChange}
          onRemove={this.removeOrDelete}
          registerField={registerField}
          phonePrimariesExist={phonePrimariesExist}
          index={index}
          withPhoneExtensionField
        />
      </div>
    ));

    return (
      <form
        onSubmit={handleSubmit(this.onSubmit)}
        className="edit-phone-numbers content-with-actions"
      >
        <div className="content-container">
          {phoneForms}
        </div>
        <div className="actions">
          <div className="row">
            <div className="col-sm-6 text-left edit-phone-numbers__add-phone">
              <Button
                type="button"
                onClick={this.addField}
                id="add-phone-button"
                label="+ Add Phone"
                className="border-none normal-case p-0"
              />
            </div>
            <div className="col-sm-6">
              <span className="action-item">
                <Button
                  id="edit-phone-cancel-btn"
                  label="Cancel"
                  onClick={this.props.closeModal}
                  secondary
                />
              </span>
              <span className="action-item">
                <Button
                  id="edit-phone-submit-btn"
                  onClick={handleSubmit(this.onSubmit)}
                  primary
                  label="Save"
                  disabled={submitting || invalid}
                />
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

EditPhoneNumbers.propTypes = {
  closeModal: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  registerField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  updateCurrentEmployeeState: PropTypes.func.isRequired,
  styles: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
  updateEmployee: PropTypes.func.isRequired,
  currentEmployee: PropTypes.object.isRequired,
};

EditPhoneNumbers.defaultProps = {
  styles: {
    inlinePhoneContainer: { borderBottom: '1px solid #EBECED', marginBottom: '20px', paddingBottom: '10px' },
  },
};

function mapStateToProps(state, ownProps) {
  const employeePhones = _.get(ownProps, 'employee.phone_numbers', []);
  const { currentEmployee } = state.globalState;

  return {
    initialValues: {
      phones: phonesInitialValues(employeePhones),
    },
    groupId: state.session.groupId,
    currentEmployee,
  };
}

const fields = [
  'phones[].id',
  'phones[].phone_number',
  'phones[].extension',
  'phones[].phone_type',
  'phones[].is_primary',
  'phones[].country_code',
];
export default validateReduxForm(
  {
    form: EDIT_PHONE_NUMBERS,
    fields,
  },
  mapStateToProps,
  { updateEmployee, updateCurrentEmployeeState },
)(EditPhoneNumbers);
