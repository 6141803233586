import sjcl from 'sjcl';
import { get } from 'lodash';

function createHashedFingerprint(payload) {
  const values = JSON.stringify(get(payload, 'body.trace', {}));
  const hash = sjcl.hash.sha256.hash(values);

  return sjcl.codec.hex.fromBits(hash);
}

export default createHashedFingerprint;
