import { get, isEmpty } from 'lodash';
import {
  DASHBOARD_FETCH_REFERRALS,
  UNSET_DASHBOARD_FETCHING,
  SET_DASHBOARD_FETCHING,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import { coreApi } from 'src/api/config';
import { oneYearAgoDateString } from 'src/common/utils/today';

const defaultOptions = {
  sidx: 'created_at',
  sord: 'asc',
  filters: {},
  page: 1,
};

const unsetFetching = (dispatch, target) => (
  dispatch({
    type: UNSET_DASHBOARD_FETCHING,
    target,
  })
);

const setFetching = (dispatch, options) => (
  dispatch({
    type: SET_DASHBOARD_FETCHING,
    target: options.target,
    filters: options.filters,
    reset: options.reset,
  })
);

const onError = (error) => {
  Notifier.handleErrors(error);
  return error;
};

const fetchDashboardReferrals = (
  groupId,
  referralType,
  options = defaultOptions,
  caseState,
  providerSource,
  ) => (async (dispatch) => {
    try {
      const careCoordinatorUsers = get(options, 'filters.care_coordinator_users', []);
      const careCoordinators = careCoordinatorUsers.filter((c) => c !== 'none');
      const noneAssigned = careCoordinatorUsers.includes('none');
      const serviceTypes = get(options, 'filters.service_types', null);
      const reasons = get(options, 'filters.reasons', null);

      let caseResolution = null;
      let sending_network = null;
      let receiving_network = null;
      let sending_provider = null;
      let receiving_provider = null;
      let case_has_outcome = null;
      let person_has_consent = null;
      let consent_state = null;
      let flagged_for_sender = null;
      let caseStateFilter = null;
      let receiving_program = null;
      let sent_by = null;

      if (providerSource === 'received') {
        sending_provider = null;
        receiving_provider = groupId;
      } else {
        sending_provider = groupId;
        receiving_provider = null;
        consent_state = 'accepted';
      }

      let state = caseState;
      if (caseState === 'closed') {
        state = null;
        if (options.filters.sent === 'true') {
          sending_provider = groupId;
          receiving_provider = null;
        }
        if (options.filters.sent === 'false') {
          sending_provider = null;
          receiving_provider = groupId;
        }
      }

      const { referral_sender_users, referred_by_groups, referred_to_groups } = options.filters;
      if (referral_sender_users && referral_sender_users.length) {
        sending_provider = referral_sender_users.join(',');
        receiving_provider = null;
      }
      if (referred_by_groups && referred_by_groups.length) {
        sending_provider = referred_by_groups.join(',');
      }
      if (referred_to_groups && referred_to_groups.length) {
        receiving_provider = referred_to_groups.join(',');
      }

      switch (options.target) {
        case 'referralsInReview':
          case_has_outcome = false;
          break;
        case 'recalledReferrals':
          consent_state = null;
          flagged_for_sender = true;
          case_has_outcome = false;
          break;
        case 'rejectedReferrals':
          consent_state = null;
          flagged_for_sender = true;
          case_has_outcome = false;
          break;
        case 'sentReferralsAcceptedOpen':
          case_has_outcome = false;
          break;
        case 'sentReferralsAcceptedClosed':
          case_has_outcome = true;
          break;
        case 'sentReferralsAll':
          consent_state = null;
          break;
        case 'sentReferralsInReview':
          consent_state = null;
          break;
        case 'sentReferralsPending':
          consent_state = null;
          break;
        case 'providerToProviderReferrals':
          consent_state = 'accepted';
          sending_provider = null;
          sending_network = options.filters.networkId;
          receiving_network = options.filters.networkId;
          break;
        case 'sentReferralsPendingConsent':
          state = 'sent';
          person_has_consent = false;
          consent_state = null;
          break;
        case 'pendingConsentReferrals':
          state = 'sent';
          person_has_consent = false;
          consent_state = null;
          break;
        case 'closedReferrals':
          state = 'sent,draft,declined,recalled';
          case_has_outcome = true;
          caseStateFilter = 'draft,deferred,referred';
          consent_state = null;
          break;
        case 'closedInboundReferrals':
          state = 'sent,in_review';
          case_has_outcome = true;
          consent_state = null;
          break;
        default: break;
      }

      switch (options.filters.status) {
        case 'accepted':
          state = 'accepted';
          case_has_outcome = options.target === 'sentReferralsAcceptedClosed';
          break;
        case 'declined_consent':
          state = 'sent';
          consent_state = 'declined';
          break;
        case 'pending_consent':
          state = 'sent';
          person_has_consent = false;
          consent_state = null;
          break;
        case 'closed':
          state = 'accepted';
          case_has_outcome = true;
          break;
        case 'sent':
          consent_state = 'accepted';
          case_has_outcome = false;
          break;
        // reviewed state happens when an inbound referral is closed
        case 'reviewed':
          state = 'sent,in_review';
          case_has_outcome = true;
          break;
        case 'in_review':
          case_has_outcome = false;
          break;
        default: break;
      }

      const consentStatus = get(options, 'filters.consent_status', null);
      if (consentStatus === 'none' || !consentStatus) {
        // do nothing
      } else if (consentStatus === 'pending') {
        person_has_consent = false;
      } else if (consentStatus === 'revoked') {
        consent_state = 'revoked';
      } else {
        consent_state = consentStatus;
        person_has_consent = null;
      }

      if (!isEmpty(options.filters.sent_by)) {
        sent_by = options.filters.sent_by.join(',');
      }
      if (!isEmpty(options.filters.resolved)) {
        const targets = ['closedInboundReferrals', 'closedReferrals'];
        if (targets.includes(options.target) && options.filters.resolved === 'all') {
          caseResolution = null;
        } else {
          caseResolution = options.filters.resolved;
        }
      }
      if (!isEmpty(options.filters.referred_by_groups)) {
        sending_provider = options.filters.referred_by_groups.join(',');
      }
      if (!isEmpty(options.filters.referred_to_groups)) {
        receiving_provider = options.filters.referred_to_groups.join(',');
      }
      if (!isEmpty(options.filters.programs_filter)) {
        receiving_program = options.filters.programs_filter.join(',');
      }
      if (!isEmpty(options.filters.status) && !['all', 'reviewed'].includes(options.filters.status)) {
        if (options.filters.status !== 'closed') {
          state = options.filters.status;
        }
        if (options.filters.status === 'closed') {
          state = options.allStatuses;
          case_has_outcome = true;
        }
      }

      // add pending_authorization referrals to Needs Action dashboard tab
      if (caseState === 'pending') {
        state = 'sent,pending_authorization';
        case_has_outcome = false;
      }
      // add denied_authorization referrals to Rejected dashboard tab
      if (caseState === 'declined') {
        state = 'declined,denied_authorization';
      }
      // add denied_authorization referrals to Closed dashboard tab
      if (caseState === 'closed') {
        state = `${state},denied_authorization`;
      }

      setFetching(dispatch, options);
      const response = await coreApi.query('referral', {
        state,
        sending_network,
        receiving_network,
        receiving_program,
        sending_provider,
        receiving_provider,
        ...(!isEmpty(careCoordinators) ?
          { 'case.person.client_relationships.care_coordinator': careCoordinators.join(',') } : null),
        ...(noneAssigned ? {
          'case.person.client_relationships.has_care_coordinator': false,
          'case.person.client_relationships.provider': groupId,
        } : null),
        ...(serviceTypes ? { 'case.service': serviceTypes.join(',') } : null),
        ...(reasons ? { reason: reasons.join(',') } : null),
        'case.has_outcome': case_has_outcome,
        ...(caseResolution ? { 'case.outcome.resolution': caseResolution } : null),
        'case.person.has_consent': person_has_consent,
        'case.person.consent.state': consent_state,
        'case.state': caseStateFilter,
        flagged_for_sender,
        ...(!isEmpty(sent_by) ? { sent_by } : null),
        updated_after: oneYearAgoDateString,
      }, {
        page: {
          number: options.page,
          size: 50,
        },
        include: 'case_summary,case',
        sort: get(options, 'sidx', []),
      });
      const referrals = response.data.data;
      await Promise.all([
        coreApi.populateRelationship('sending_provider', 'provider', referrals),
        coreApi.populateRelationship('receiving_provider', 'provider', referrals),
        coreApi.populateRelationship('case_summary.person', 'person', referrals),
        coreApi.populateRelationship('case_summary.service', 'service', referrals),
        coreApi.populateRelationship('receiving_program', 'program', referrals),
        coreApi.populateRelationship('rejector', 'employee', referrals),
        // this should be conditional - i think it's only used by `Sent` section of the dashboard referrals:
        coreApi.populateRelationship('case.primary_worker', 'employee', referrals),
      ]);

      if (options.target === 'providerToProviderReferrals') {
        await Promise.all([
          referrals.map((r) => (coreApi.populateRelationship('case', 'case', r))),
        ]);
      }

      await Promise.all([
        coreApi.populateRelationship('case.outcome', 'outcome', referrals),
        coreApi.populateRelationship('rejector.provider', 'provider', referrals),
      ]);
      const payload = {
        ...response,
        data: {
          ...response.data,
          data: referrals.map((r) => ({
            ...r,
            id: r.id,
            status_reason: r.reason,
            contact: r.case_summary.person,
            status: r.state,
            service_type: r.case_summary.service,
            referred_by_group: {
              ...r.sending_provider,
            },
            referred_to_group: {
              ...r.receiving_provider,
            },
            referred_to_program: {
              ...r.receiving_program,
            },
            closing: {
              outcome: get(r, 'case.outcome.description', null),
            },
            person: r.case_summary.person,
            service: r.case_summary.service,
            provider: {
              ...r.sending_provider,
            },
          })),
        },
      };

      dispatch({
        type: DASHBOARD_FETCH_REFERRALS,
        payload,
        target: options.target,
        filters: options.filters,
      });
      unsetFetching(dispatch, options.target);

      return payload;
    } catch (error) {
      return onError(error);
    }
  }
);

export default fetchDashboardReferrals;
