import { formatTimeOfDay } from '.';
function formatDuration() {
  var duration = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var opens_at = duration.opens_at,
    closes_at = duration.closes_at,
    opens = duration.opens,
    closes = duration.closes;
  if (duration.opens_at !== undefined) {
    return "".concat(formatTimeOfDay(opens_at), " - ").concat(formatTimeOfDay(closes_at));
  }
  return "".concat(formatTimeOfDay(opens), " - ").concat(formatTimeOfDay(closes));
}
export default formatDuration;