import { get } from 'lodash';

const isFeeScheduleProgram = (state, serviceCase) => {
  const programId = get(serviceCase, 'program.id');
  const program = get(state, 'groupsPrograms.data').find(({ id }) => id === programId);
  const hasFeeScheduleProgram = get(program, 'relationships.fee_schedule_program.data');
  return !!program && hasFeeScheduleProgram;
};

export default isFeeScheduleProgram;
