var PHONE_FORMATS = [
// "+15556667777" or with extension ("+15556667777 1234" or "+155566677771234")
/^\+[0-9]([0-9]{3})([0-9]{3})([0-9]{4})\s?([0-9]*)/,
// "5556667777" or with extension
/^([0-9]{3})([0-9]{3})([0-9]{4})\s?([0-9]*)/,
//
// "6667777" minimum 7 digits
/^([0-9]{3})([0-9]{4})/];
function isValidMinimum(match) {
  return match && match[0].length === 7;
}
function formatPhoneNumber(phoneNumber) {
  var detailedExtension = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (phoneNumber) {
    for (var i = 0; i < PHONE_FORMATS.length; i++) {
      // eslint-disable-line no-plusplus
      var match = phoneNumber.match(PHONE_FORMATS[i]);
      if (isValidMinimum(match)) {
        return "(---) ".concat(match[1], "-").concat(match[2]).trim();
      }
      if (match) {
        if (match[4] && detailedExtension) {
          return "(".concat(match[1], ") ").concat(match[2], "-").concat(match[3], " ext. ").concat(match[4]).trim();
        }
        return "(".concat(match[1], ") ").concat(match[2], "-").concat(match[3], " ").concat(match[4]).trim();
      }
    }
  }
  return '';
}
export default formatPhoneNumber;