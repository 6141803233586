var NOON = 720;
var FAUX_MIDNIGHT = 1439;
function getRelativeTime(time) {
  if (time === FAUX_MIDNIGHT) {
    return 0;
  }
  return time < NOON ? time : time - NOON;
}
function formatTimeOfDay() {
  var time = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var relativeTime = getRelativeTime(time);
  var hour = Math.floor(relativeTime / 60);
  var minute = relativeTime - hour * 60;
  var displayHour = hour === 0 ? '12' : hour;
  var displayMinute = minute < 10 ? "0".concat(minute) : minute;
  var label = time < NOON || time === FAUX_MIDNIGHT ? 'AM' : 'PM';
  return "".concat(displayHour, ":").concat(displayMinute, " ").concat(label);
}
export default formatTimeOfDay;