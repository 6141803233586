import { concat, isFunction, join } from 'lodash';
import momentizeValue from "./momentizeValue";
function validateDate(date, maxDate, minDate, shouldDisableDate, dateFormat) {
  var customErrorMessage = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : '';
  var maxiDate = momentizeValue(maxDate);
  var miniDate = momentizeValue(minDate);
  var errors = [];
  if (!date) {
    return undefined;
  }
  if (maxiDate && date.isAfter(maxiDate)) {
    var errorMessage = customErrorMessage || "Enter a date before ".concat(maxiDate.format(dateFormat));
    errors = concat(errors, errorMessage);
  }
  if (miniDate && date.isSameOrBefore(miniDate)) {
    var _errorMessage = customErrorMessage || "Enter a date after ".concat(miniDate.format(dateFormat));
    errors = concat(errors, _errorMessage);
  }
  if (isFunction(shouldDisableDate) && shouldDisableDate(date) || !date.isValid() || date.year() === 0) {
    errors = concat(errors, 'Invalid Date');
  }
  return join(errors, ',');
}
export default validateDate;