import { noop } from 'lodash';
import { browserHistory } from 'common/utils/browserHistory';
import callOrLog from 'src/common/utils/callOrLog';
import { NEW_CLIENT } from 'common/utils/EventTracker/utils/eventConstants';
import STEP_DEFAULTS from './utils/defaults';
import {
  ADD_INFORMATION_PATH,
  CONFIRM_PATH,
  SEARCH_PATH,
  NEW_REFERRAL_PATH,
  NEW_REFERRAL_SUPPORTING_PATH,
  NEW_REFERRAL_REVIEW_PATH,
} from '../constants';

const referralsStepperConfig = {
  steps: [
    {
      ...STEP_DEFAULTS.first,
      title: 'Search Records',
      onStepClick: () => {
        browserHistory.push(`/referrals/${SEARCH_PATH}`);
      },
      routes: [
        {
          route: `/referrals/${SEARCH_PATH}`,
        },
        {
          route: `/referrals/${CONFIRM_PATH}`,
        },
      ],
    },
    {
      ...STEP_DEFAULTS.second,
      title: 'Contact Information',
      onStepClick: noop,
      onStepComplete: ({
        contact = {},
        contactId = null,
        eventTracker = noop,
      }) => {
        const event = contactId ? NEW_CLIENT.createFromReferral : NEW_CLIENT.updateFromReferral;
        callOrLog(() => eventTracker(event, null, {
          contact,
        }));
        browserHistory.push(NEW_REFERRAL_PATH);
      },
      routes: [
        {
          route: `/referrals/${ADD_INFORMATION_PATH}`,
        },
      ],
    },
    {
      ...STEP_DEFAULTS.third,
      title: 'Add Referral',
      onStepClick: () => {
        browserHistory.push(NEW_REFERRAL_PATH);
      },
      routes: [
        {
          route: NEW_REFERRAL_PATH,
        },
        {
          route: NEW_REFERRAL_SUPPORTING_PATH,
        },
      ],
    },
    {
      ...STEP_DEFAULTS.fourth,
      title: 'Review Referral',
      onStepClick: () => {
        browserHistory.push(NEW_REFERRAL_REVIEW_PATH);
      },
      routes: [
        {
          route: NEW_REFERRAL_REVIEW_PATH,
        },
      ],
    },
  ],
};

export default referralsStepperConfig;
