import { useFind } from 'src/api/APIHooks';
import { get, isEmpty } from 'lodash';

const useZcodes = (zCodeIds = [], showSavedClaimCodes = false) => {
  const { isFetching, data } = useFind(
    'zcodes',
    {
      id: zCodeIds.join(','),
    },
    {
      queryConfig: {
        enabled: showSavedClaimCodes && !isEmpty(zCodeIds),
      },
    },
  );
  const zCodes = get(data, 'data.data', []);

  return isFetching ? [] : zCodes;
};

export default useZcodes;
