import { get, find, includes } from 'lodash';

const getInsuranceExternalId = ({
  insurances = [],
  fsPlans = [],
  insurancePlans = [],
}) => {
  const fsPayers = fsPlans.map((plan) => (get(plan, 'relationships.payer.data.id')));
  const insurancePlan = find(insurancePlans, (plan) => {
    const payer = get(plan, 'relationships.payer.data.id');
    return includes(fsPayers, payer);
  });
  const insurance = find(insurances, (ins) => (
    get(ins, 'relationships.plan.data.id') === insurancePlan?.id
  ));

  return get(insurance, 'attributes.external_member_id', null);
};

export default getInsuranceExternalId;
