import React from 'react';
import PropTypes from 'prop-types';

const PrdDetailsColorElement = ({ color, label }) => (
  <p>
    <span
      className="color-box w-3 h-3 inline-block mx-1 border-2 border-black border-solid"
      data-testid={`color-box-${label}`}
      style={{ backgroundColor: color }}
    />
    {color ? color.replace('#', '') : ''} ({label})
  </p>
);

PrdDetailsColorElement.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default PrdDetailsColorElement;
