import { get, isEmpty } from 'lodash';

import { coreApi } from 'src/api/config';
import { isOversightAdmin, isOrgAdmin } from 'src/components/User/utils';

export default async function canManageFeeSchedule({
  employee,
  referral: {
     case: serviceCase,
     sending_provider: sendingProvider,
  },
  user,
}) {
  // No service auth, no check
  if (isEmpty(serviceCase.service_authorization)) {
    return true;
  }

  const providerId = get(employee, 'provider.id');

  // Only sending providers are checked here
  if (sendingProvider && providerId && sendingProvider.id !== providerId) {
    return true;
  }

  // Oversight and Org Admin skip fee schedule check
  if (isOversightAdmin(user, providerId) || isOrgAdmin(user, providerId)) {
    return true;
  }

  if (!serviceCase.service_authorization.fee_schedule_program?.id) {
    await coreApi.populateRelationship('service_authorization', 'service_authorization', serviceCase);
  }

  const fspId = get(serviceCase, 'service_authorization.fee_schedule_program.id');
  const requesterId = get(serviceCase, 'service_authorization.requester.id');

  // The requester can manage their own referrals
  if (requesterId && employee?.id && requesterId === employee.id) {
    return true;
  }

  // Referrals User and Admin must be on the fee schedule if there's a service auth
  const fsp = get(await coreApi.findRecord('fee_schedule_program', fspId), 'data.data');

  return get(employee, 'fee_schedules', []).map((fs) => fs.id).includes(get(fsp, 'fee_schedule.id'));
}
