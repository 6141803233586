import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { BackButton } from 'common/buttons';
import Breadcrumbs from 'common/Breadcrumbs';
import { fetchFeeSchedules as fetchFS } from 'actions/FeeSchedules';
import { Spinner } from 'common/spinners';
import { browserHistory } from 'common/utils/browserHistory';
import {
  STATUS_INITIAL,
  STATUS_PENDING,
} from 'common/Status/constants';
import { useFindFeeSchedule } from 'src/components/Backoffice/api/hooks/v1/feeScheduleHooks';
import './stylesheets/FeeSchedule.scss';
import FeeScheduleSubHeader from './FeeScheduleSubHeader';
import FeeSchedulePlans from './FeeSchedulePlans';
import FeeScheduleScreenings from './FeeScheduleScreenings';
import FeeScheduleProgramsTable from './FeeScheduleProgramsTable';
import FeeScheduleInvoiceRejectionReasonsTable from './FeeScheduleInvoiceRejectionReasonsTable';
import FeeScheduleAuthorizationRejectionReasonsTable from './FeeScheduleAuthorizationRejectionReasonsTable';
import CareManagersTable from './CareManagers/CareManagersTable';

export const FeeSchedule = ({
  fetchFeeSchedules,
  feeScheduleId,
  status,
}) => {
  useEffect(() => {
    fetchFeeSchedules({ id: feeScheduleId, include: 'fee_schedule_programs' });
  }, [feeScheduleId, fetchFeeSchedules]);
  const { data: feeSchedule, isFetching } = useFindFeeSchedule(feeScheduleId);
  const childTabComponent = () => {
    const tabUrl = window.location.pathname.split('/').pop();
    switch (tabUrl) {
      case 'plans':
        return <FeeSchedulePlans feeScheduleId={feeScheduleId} />;
      case 'fee-schedule-screenings':
        return <FeeScheduleScreenings feeScheduleId={feeScheduleId} />;
      case 'invoice-rejection-reasons':
        return <FeeScheduleInvoiceRejectionReasonsTable feeScheduleId={feeScheduleId} />;
      case 'authorization-rejection-reasons':
        return <FeeScheduleAuthorizationRejectionReasonsTable feeScheduleId={feeScheduleId} />;
      case 'care-managers':
        return <CareManagersTable feeScheduleId={feeScheduleId} />;
      default:
        return <FeeScheduleProgramsTable feeScheduleId={feeScheduleId} />;
    }
  };
  const feeScheduleLabel = isEmpty(feeSchedule.name) ? 'Loading...' : feeSchedule.name;
  const crumbs = [
    { label: 'Fee Schedules', href: '/backoffice/fee_schedules' },
    { label: feeScheduleLabel, href: `/backoffice/fee_schedules/${feeSchedule.id}` },
  ];

  const BackToFeeSchedules = () => {
    browserHistory.push({
      pathname: '/backoffice/fee_schedules',
    });
  };

  const isLoading = ((status === STATUS_INITIAL || status === STATUS_PENDING) && isEmpty(feeSchedule)) || isFetching;
  return (
    <>
      {(isLoading) && <Spinner />}
      {!isLoading && (
        <div className="detail-view">
          <div className="flex p-4 justify-start items-center bg-white border-b border-solid border-dark-border-blue">
            <div>
              <BackButton navigateTo={BackToFeeSchedules} dataTestId="fs-back-btn" />
            </div>
            <div className="px-5">
              <Breadcrumbs className="bread-crumbs" crumbs={crumbs} />
            </div>
          </div>
          <div className="bg-white border-b border-solid border-dark-border-blue">
            <FeeScheduleSubHeader feeSchedule={feeSchedule} />
          </div>
          { childTabComponent() }
        </div>
      )}
    </>
  );
};

FeeSchedule.propTypes = {
  feeScheduleId: PropTypes.string.isRequired,
  fetchFeeSchedules: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const feeScheduleId = get(ownProps, 'params.id', '');
  const status = get(state, 'feeSchedules.status');
  return {
    feeScheduleId,
    status,
  };
};

export default connect(mapStateToProps, { fetchFeeSchedules: fetchFS })(FeeSchedule);
