import { noop, isEmpty } from 'lodash';
import { browserHistory } from 'common/utils/browserHistory';
import {
  SEARCH_PATH,
} from '../constants';
import goToConfirmStep from '../utils/goToConfirmStep';
import goToAddInformationStep from '../utils/goToAddInformationStep';
import getARIdFromPathname from '../utils/getARIdFromPathname';
import STEP_DEFAULTS from './utils/defaults';

const ARtoCaseStepperConfig = {
  steps: [
    {
      ...STEP_DEFAULTS.first,
      title: 'Search Records',
      onStepClick: () => {
        const location = browserHistory.getCurrentLocation();
        const arId = getARIdFromPathname(location.pathname);
        browserHistory.push(`/assistance-requests/${arId}/contacts/${SEARCH_PATH}`);
      },
      onSearchComplete: ({
        contacts,
        formData,
      }) => {
        const location = browserHistory.getCurrentLocation();
        const arId = getARIdFromPathname(location.pathname);
        const context = `assistance-requests/${arId}/contacts`;

        if (!isEmpty(contacts)) {
          return goToConfirmStep({ context, contactData: formData });
        }
        return goToAddInformationStep({ context, contactData: formData });
      },
      onConfirmationComplete: ({
        contactId,
        formData,
        fromIntegration = false,
      }) => {
        const location = browserHistory.getCurrentLocation();
        const arId = getARIdFromPathname(location.pathname);
        const context = `assistance-requests/${arId}/contacts`;

        if (!isEmpty(contactId)) {
          return goToAddInformationStep({ context, contactId });
        }
        return goToAddInformationStep({ context, contactData: formData, fromIntegration });
      },
      routeRegex: /^\/?assistance-requests\/(.*)\/contacts\/new\/(search|confirm)$/,
    },
    {
      ...STEP_DEFAULTS.second,
      title: 'Contact Information',
      onStepClick: noop,
      onStepComplete: ({
        contact = {},
        contactId = null,
      }) => {
        const location = browserHistory.getCurrentLocation();
        const arId = getARIdFromPathname(location.pathname);
        const savedContactId = contactId || contact.id;

        browserHistory.push(
          `assistance-requests/${arId}/contacts/${savedContactId}/cases/new/add-case-details`,
        );
      },
      onBackClick: () => {
        const location = browserHistory.getCurrentLocation();
        const arId = getARIdFromPathname(location.pathname);
        browserHistory.push(`/assistance-requests/${arId}/contacts/${SEARCH_PATH}`);
      },
      routeRegex: /^\/?assistance-requests\/(.*)\/contacts\/new\/(add-contact-information)$/,
    },
  ],
};

export default ARtoCaseStepperConfig;
