import {
  ADD_PAYMENTS_PROVIDED_SERVICE_ERROR,
  ADD_PAYMENTS_PROVIDED_SERVICE_SUCCESS,
  ADD_PAYMENTS_PROVIDED_SERVICE_STARTED,
  CLOSE_SERVICE_CASE,
  FETCH_PROVIDED_SERVICES_STARTED,
  FETCH_PROVIDED_SERVICES_SUCCESS,
  FETCH_PROVIDED_SERVICES_ERROR,
  FETCH_SERVICE_CASE,
  FETCH_SERVICE_CASES,
  UPDATE_SERVICE_CASE,
  UNSET_FETCH_SERVICE_CASES,
  SET_FETCH_SERVICE_CASES,
  ADD_CONTACT_PROVIDED_SERVICE,
  UNSET_SERVICE_CASES_REFETCH,
  ADD_NOTE_INTERACTION,
  CREATE_SERVICE_CASE_SET_SUBMITTING,
  CREATE_SERVICE_CASE_UNSET_SUBMITTING,
  UPDATE_PROGRAM_INFORMATION,
  ADD_CASE_DOCUMENTS,
  REMOVE_CASE_DOCUMENT,
  RENAME_CASE_DOCUMENT,
  SUBMIT_PAYMENT_PROVIDED_SERVICE_STARTED,
  SUBMIT_PAYMENT_PROVIDED_SERVICE_SUCCESS,
  SUBMIT_PAYMENT_PROVIDED_SERVICE_ERROR,
  UPDATE_PAYMENTS_PROVIDED_SERVICE_ERROR,
  UPDATE_PAYMENTS_PROVIDED_SERVICE_SUCCESS,
  UPDATE_PAYMENTS_PROVIDED_SERVICE_STARTED,
} from 'src/actions';
import {
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_ERROR,
} from 'common/Status/constants';
import { replaceObjectInArrayById } from 'common/utils/stateHelpers';
import _ from 'lodash';

function getCaseInState({ caseId, contactId, state }) {
  return _.find(state[contactId], { id: caseId });
}

function findAndReplaceServiceCase(currentState, newData) {
  return _.reduce(currentState, (acc, curr) => {
    if (curr.id === newData.id) {
      return [...acc, newData];
    }
    return [...acc, curr];
  }, []);
}

const getCurrentContactState = (state, contactId) => {
  if (state[contactId] && state[contactId].data) {
    return state[contactId].data;
  }

  return state[contactId] || [];
};

export const defaultState = {
  isFetching: false,
  isCreating: false,
  refetch: false,
};

export default function serviceCaseReducer(state = defaultState, action) {
  const {
    caseId,
    contactId,
    documents,
    documentId,
    payload,
    title,
    type,
  } = action;
  const caseInState = getCaseInState({ caseId, contactId, state }) || {};

  switch (type) {
    case ADD_NOTE_INTERACTION:
    case ADD_CONTACT_PROVIDED_SERVICE:
      return { ...state, refetch: true };

    case UNSET_SERVICE_CASES_REFETCH:
      return { ...state, refetch: false };

    case FETCH_PROVIDED_SERVICES_STARTED: {
      return { ...state, status: STATUS_PENDING };
    }

    case FETCH_PROVIDED_SERVICES_ERROR: {
      return { ...state, status: STATUS_ERROR };
    }

    case FETCH_PROVIDED_SERVICES_SUCCESS: {
      const feeScheduleProvidedService = _.get(action, 'payload.data.data');
      const currentContactState = getCurrentContactState(state, contactId);
      const updatedCases = findAndReplaceServiceCase(currentContactState, {
        ...caseInState,
        feeScheduleProvidedServices: feeScheduleProvidedService,
      });

      return { ...state, status: STATUS_SUCCESS, [contactId]: updatedCases };
    }

    case FETCH_SERVICE_CASES:
      return _.assign({}, state, {
        [contactId]: {
          data: payload.data.data,
          paging: payload.data.paging,
        },
      });

    case FETCH_SERVICE_CASE: {
      const currentContactState = getCurrentContactState(state, contactId);
      const existingServiceCase = _.find(currentContactState, (sc) => sc.id === payload.data.data.id);

      if (existingServiceCase) {
        if (payload.data.data.feeScheduleProvidedServices === undefined) {
          payload.data.data.feeScheduleProvidedServices = existingServiceCase.feeScheduleProvidedServices;
        }
        return _.assign({}, state, {
          [contactId]: findAndReplaceServiceCase(currentContactState, payload.data.data),
        });
      }

      return _.assign({}, state, {
        [contactId]: [
          ...currentContactState,
          payload.data.data,
        ],
      });
    }

    case UPDATE_PROGRAM_INFORMATION: {
      const currentContactState = getCurrentContactState(state, contactId);

      const newContactState = _.reduce(currentContactState, (acc, curr) => {
        if (curr.id === caseId) {
          return [...acc, { ...curr, program: payload.data.data }];
        }
        return [...acc, curr];
      }, []);

      return { ...state, [contactId]: newContactState };
    }

    case CLOSE_SERVICE_CASE:
    case UPDATE_SERVICE_CASE: {
      const currentContactState = getCurrentContactState(state, contactId);
      return _.assign({}, state, {
        [contactId]: findAndReplaceServiceCase(currentContactState, payload.data.data),
      });
    }

    case UNSET_FETCH_SERVICE_CASES:
      return { ...state, isFetching: false };

    case SET_FETCH_SERVICE_CASES:
      return { ...state, isFetching: true };

    case CREATE_SERVICE_CASE_SET_SUBMITTING:
      return { ...state, isCreating: true };

    case CREATE_SERVICE_CASE_UNSET_SUBMITTING:
      return { ...state, isCreating: false };

    // Case Documents
    case ADD_CASE_DOCUMENTS: {
      const currentContactState = getCurrentContactState(state, contactId);
      const updatedCases = findAndReplaceServiceCase(currentContactState, {
        ...caseInState,
        documents: [...caseInState.documents, ...documents],
      });

      return { ...state, [contactId]: updatedCases };
    }

    case REMOVE_CASE_DOCUMENT: {
      const currentContactState = getCurrentContactState(state, contactId);
      const updatedCases = findAndReplaceServiceCase(currentContactState, {
        ...caseInState,
        documents: _.reject(caseInState.documents, { id: documentId }),
      });

      return { ...state, [contactId]: updatedCases };
    }

    case RENAME_CASE_DOCUMENT: {
      const currentContactState = getCurrentContactState(state, contactId);
      const updatedCases = findAndReplaceServiceCase(currentContactState, {
        ...caseInState,
        documents: replaceObjectInArrayById({
          ..._.find(caseInState.documents, { id: documentId }),
          title,
        }, caseInState.documents),
      });

      return { ...state, [contactId]: updatedCases };
    }

    // Payments Fee Schedule Provided Service
    case ADD_PAYMENTS_PROVIDED_SERVICE_STARTED: {
      return { ...state, status: STATUS_PENDING };
    }

    case ADD_PAYMENTS_PROVIDED_SERVICE_ERROR: {
      return { ...state, status: STATUS_ERROR };
    }

    case ADD_PAYMENTS_PROVIDED_SERVICE_SUCCESS: {
      const feeScheduleProvidedService = _.get(action, 'payload.data.data');
      const currentContactState = getCurrentContactState(state, contactId);
      const existingFeeScheduleProvidedServices = caseInState.feeScheduleProvidedServices ?
        caseInState.feeScheduleProvidedServices : [];
      const updatedCases = findAndReplaceServiceCase(currentContactState, {
        ...caseInState,
        feeScheduleProvidedServices: [...existingFeeScheduleProvidedServices, feeScheduleProvidedService],
      });

      return { ...state, status: STATUS_SUCCESS, [contactId]: updatedCases };
    }

    case SUBMIT_PAYMENT_PROVIDED_SERVICE_STARTED:
    case UPDATE_PAYMENTS_PROVIDED_SERVICE_STARTED: {
      return { ...state, status: STATUS_PENDING };
    }

    case SUBMIT_PAYMENT_PROVIDED_SERVICE_ERROR:
    case UPDATE_PAYMENTS_PROVIDED_SERVICE_ERROR: {
      return { ...state, status: STATUS_ERROR };
    }
    case SUBMIT_PAYMENT_PROVIDED_SERVICE_SUCCESS:
    case UPDATE_PAYMENTS_PROVIDED_SERVICE_SUCCESS: {
      const updatedFeeScheduleProvidedService = _.get(action, 'payload.data.data');
      const currentContactState = getCurrentContactState(state, contactId);
      const updatedCases = findAndReplaceServiceCase(currentContactState, {
        ...caseInState,
        feeScheduleProvidedServices: replaceObjectInArrayById({
          ..._.find(caseInState.feeScheduleProvidedServices, { id: updatedFeeScheduleProvidedService.id }),
          ...updatedFeeScheduleProvidedService,
        }, caseInState.feeScheduleProvidedServices),
      });
      return { ...state, status: STATUS_SUCCESS, [contactId]: updatedCases };
    }

    default:
      return state;
  }
}
