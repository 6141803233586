import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { get, some, filter } from 'lodash';
import { connect } from 'react-redux';
import {
  addGroupFields,
  filterBrowseGroups,
  filterBrowseGroupIds,
} from 'src/components/Referrals/ReferralGroupsPrograms/utils';
import ReferralServiceProviderBrowse from 'src/components/Referrals/components/ReferralServices/ReferralServiceProviderBrowse';
import { getSelectedGroups } from 'src/components/Referrals/ReferralFormFields/EditReferralDetails/utils';
import { paginateNetworkGroups } from 'src/common/utils/FeatureFlags/flags';

export class CaseOONBrowse extends Component {
  constructor(props) {
    super(props);

    this.addSelectedBrowseGroups = this.addSelectedBrowseGroups.bind(this);
  }

  addSelectedBrowseGroups(groups) {
    const {
      fields,
      canPaginateNetworkGroups,
      setBrowseMapGroups,
      oonGroups,
    } = this.props;
    const selectedOONCases = fields.service_case.oonCase.selected;
    const { toRemoveIds, toAddIds } = filterBrowseGroupIds(groups, selectedOONCases);
    const newGroups = filterBrowseGroups({
      groups,
      toRemoveIds,
      toAddIds,
      groupField: selectedOONCases,
    });

    if (canPaginateNetworkGroups) {
      // Sort the groups added from browse map, so they are in the list in the correct order.
      const groupsWithDistanceSorted = newGroups.sort((a, b) => a.distance - b.distance);

      // Filter out the selected browse map groups that already exist in the original "closest 50" groups.
      // This filter is needed so that we dont add duplicate groups to the dropdown.
      const filteredBrowseMapGroups = filter(groupsWithDistanceSorted, (group) => (
        !some(oonGroups, ({ id }) => group.id === id)));

      if (filteredBrowseMapGroups.length) {
        setBrowseMapGroups(
          // This is the list that will populate the oonGroups dropdown.
          [...oonGroups, ...filteredBrowseMapGroups],
          // This is a separate storage of browse map groups that will be appended to each new dropdown field.
          filteredBrowseMapGroups,
        );
      }
      // We should still add all browse map groups selected (groupswithDistanceSorted) to ReduxForm.
      addGroupFields(groupsWithDistanceSorted, selectedOONCases);
    } else {
      addGroupFields(newGroups, selectedOONCases);
    }
  }

  render() {
    const {
      fields,
      oonGroups,
    } = this.props;

    const referralNetworkId = get(fields, 'network.value.id');

    const selectedGroups = getSelectedGroups(fields, 'out-of-network');

    return (
      <ReferralServiceProviderBrowse
        addGroups={this.addSelectedBrowseGroups}
        groups={oonGroups}
        oonGroups={oonGroups}
        referredByNetworkId={referralNetworkId}
        referredToNetworkId={referralNetworkId}
        selectedGroups={selectedGroups}
        {...this.props}
      />
    );
  }
}

CaseOONBrowse.propTypes = {
  canPaginateNetworkGroups: PropTypes.bool.isRequired,
  currentUserGroup: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  contextAction: PropTypes.string,
  description: PropTypes.string,
  fields: PropTypes.object.isRequired,
  groupsOptionType: PropTypes.string,
  oonGroups: PropTypes.array.isRequired,
  setBrowseMapGroups: PropTypes.func.isRequired,
  toggleBrowse: PropTypes.func.isRequired,
};

CaseOONBrowse.defaultProps = {
  contextAction: 'create',
  description: '',
  groupsOptionType: 'out-of-network',
};

const mapStateToProps = (state) => ({
  canPaginateNetworkGroups: paginateNetworkGroups(state), // Feature flag
});

export default connect(mapStateToProps)(CaseOONBrowse);
