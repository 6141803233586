import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'common/utils/browserHistory';
import { Tabs, Tab } from '@unite-us/ui';
import FeeScheduleProgramSummary from './FeeScheduleProgramSummary';

const urlMappings = (feeScheduleId, feeScheduleProgramId) => {
  const baseUrl = `/backoffice/fee_schedules/${feeScheduleId}/fee_schedule_programs/${feeScheduleProgramId}`;

  return {
    overview: `${baseUrl}`,
    'cbo-table': `${baseUrl}/cbo-table`,
    'custom-fields': `${baseUrl}/custom-fields`,
  };
};

const FeeScheduleProgramSubHeader = ({ feeSchedule, feeScheduleProgram }) => {
  const availableTabs = ['overview', 'cbo-table', 'custom-fields'];
  const initialLocation = () => {
    const path = window.location.pathname.split('/').pop();
    return availableTabs.includes(path) ? path : 'overview';
  };
  const [location, setLocation] = useState(initialLocation);
  const handleTabChange = (value) => {
    setLocation(value);
    browserHistory.push(urlMappings(feeSchedule.id, feeScheduleProgram.id)[value]);
  };

  return (
    <div className="px-6 pt-5 fee-schedule-program-sub-header-items">
      <FeeScheduleProgramSummary feeScheduleId={feeSchedule.id} feeScheduleProgram={feeScheduleProgram} />
      <Tabs
        id="fee-schedule-program-tabs"
        className="fee-schedule-program-tabs mt-5"
        onChange={handleTabChange}
        value={location}
      >
        <Tab id="fee-schedule-program-overview-tab" label="Overview" value="overview" />
        <Tab id="fee-schedule-program-cboTable-tab" label="CBO Table" value="cbo-table" />
        <Tab id="fee-schedule-program-customField-tab" label="Custom Fields & Attachments" value="custom-fields" />
      </Tabs>
    </div>
  );
};

FeeScheduleProgramSubHeader.propTypes = {
  feeSchedule: PropTypes.object.isRequired,
  feeScheduleProgram: PropTypes.object.isRequired,
};

export default FeeScheduleProgramSubHeader;
