import { reduce, isEmpty } from 'lodash';

/**
 * Shape the array of assessment section and questions for API submission
 * @param {array} formInstances - array of objects representing forms.  { formId: _, promise: _ }
 * @param {array} assessmentResults - array of objects (sections) containing questions.
*/
const filterAssessmentsData = (formInstances, assessmentsResults) => (
  reduce(formInstances, (acc, instance, index) => {
    if (!isEmpty(assessmentsResults[index])) {
      return [
        ...acc,
        {
          id: instance.formId,
          data: assessmentsResults[index],
        },
      ];
    }

    return acc;
  }, [])
);

export default filterAssessmentsData;
