import { useState } from 'react';
import _ from 'lodash';
import {
  useInvalidateQueries,
} from 'src/api/APIHooks';
import callOrLog from 'src/common/utils/callOrLog';
import { FACESHEET } from 'src/common/utils/EventTracker/utils/eventConstants';
import { INTERACTION_TYPES } from 'src/components/Notes/constants';

export const submitMessage = ({
  values,
  contact,
  invalidateQueries,
  createContactCommunications,
}) => {
  const { message } = values;

  callOrLog(() => window.eventTracker(FACESHEET.sentClientMessage, {
    contact_id: contact.id,
  }));

  return createContactCommunications({
    contact,
    message,
    to: values.messageTo,
  }).then(() => {
    invalidateQueries('communication');
  });
};

export const submitProvidedService = ({
  formValues,
  groupId,
  contact,
  createContactServiceCaseProvidedService,
}) => {
  const casesToSubmit = formValues.caseOptions.filter((c) => c.selected);
  const promises = casesToSubmit.reduce((acc, curr, index, cases) => {
    const options = {
      sendSuccessNotification: index === (cases.length - 1),
    };

    return [
      ...acc,
      createContactServiceCaseProvidedService({
        groupId,
        contactId: contact.id,
        serviceCaseId: curr.id,
        provided_service: formValues.provided_service,
        opts: options,
      }),
    ];
  }, []);

  return Promise.all(promises);
};

export const submitInteraction = ({
  values,
  groupId,
  personId,
  createContactInteraction,
  createContactServiceCaseInteraction,
  invalidateQueries,
  setShowInteractions,
}) => {
  const { interaction } = values;
  const interactionType = interaction.type;

  const promises = [];

  promises.push(
    createContactInteraction({
      contactId: personId,
      groupId,
      interaction,
      interactionType,
    }),
  );

  if (values.caseOptions && values.caseOptions.length) {
    const casesToSubmit = values.caseOptions.filter((c) => c.selected);
    casesToSubmit.forEach((kase) => {
      promises.push(
        createContactServiceCaseInteraction({
          groupId,
          contactId: personId,
          subject: kase.id,
          subjectType: 'case',
          interactionType,
          interaction,
          opts: {
            sendSuccessNotification: false,
          },
        }),
      );
    }, []);
  }

  return Promise.all(promises).then(() => {
    setShowInteractions(false);
    invalidateQueries('notes');
  });
};

const useFacesheetInteractions = ({
  contact,
  contactCases,
  usePaymentsUserRole,
  groupId,
  createContactInteraction,
  createContactServiceCaseProvidedService,
  createContactServiceCaseInteraction,
  createContactCommunications,
}) => {
  const [state, setState] = useState({
    showInteractions: false,
    showMessageInteraction: false,
  });
  const setShowInteractions = (show) => setState({ ...state, showInteractions: show });
  const setShowMessageInteraction = (show) => setState({ ...state, showMessageInteraction: show });

  const invalidateQueries = useInvalidateQueries();
  const openCases = contactCases.filter((c) => c.closed_date === null);
  const caseOptions = openCases.map((c) => ({
    id: c.id,
    label: c.provider.name,
  }));
  const showServiceProvidedTab = Boolean(!usePaymentsUserRole && openCases.length);

  const interactionList = _.compact([
    {
      value: INTERACTION_TYPES.INTERACTION,
      submitFunction: (values) => (
        submitInteraction({
          values: {
            ...values,
            interaction: {
              ...values.interaction,
              category: 'interaction',
            },
          },
          groupId,
          personId: contact.id,
          createContactInteraction,
          createContactServiceCaseInteraction,
          invalidateQueries,
          setShowInteractions,
        })
      ),
    },
    showServiceProvidedTab && {
      value: INTERACTION_TYPES.SERVICE_PROVIDED,
      submitFunction: (formValues) => (
        submitProvidedService({
          formValues,
          groupId,
          contact,
          createContactServiceCaseProvidedService,
        })
      ),
    },
    {
      value: 'Message',
      submitFunction: (values) => (
        submitMessage({
          values,
          contact,
          invalidateQueries,
          createContactCommunications,
        })
      ),
    },
    {
      value: INTERACTION_TYPES.GENERAL,
      submitFunction: (values) => (
        submitInteraction({
          values,
          groupId,
          personId: contact.id,
          createContactInteraction,
          createContactServiceCaseInteraction,
          invalidateQueries,
          setShowInteractions,
        })
      ),
    },
  ]);

  return {
    interactionList,
    caseOptions,
    setShowInteractions,
    showInteractions: state.showInteractions,
    setShowMessageInteraction,
    showMessageInteraction: state.showMessageInteraction,
  };
};

export default useFacesheetInteractions;
