import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { validations } from '@unite-us/app-components';
import {
  DateField,
  SelectField,
} from '@unite-us/ui';
import today from 'common/utils/today';
import ExportTargetSelect from './ExportTargetSelect';
import { preDefinedTimePeriodToDate, validate1Year, formatStartDate } from '../utils';
import {
  SERVER_DATE_FORMAT,
  TIME_PERIOD_ALL_TIME,
  TIME_PERIOD_CUSTOM,
  TIME_PERIOD_PRE_DEFINED_REG_EXP,
} from '../constants';

class ExportCreateFields extends Component {
  static filterTimePeriods(timePeriods) {
    return timePeriods.filter(({ value }) => value !== TIME_PERIOD_ALL_TIME);
  }

  constructor(props) {
    super(props);

    this.onTimePeriodChange = this.onTimePeriodChange.bind(this);
  }

  onTimePeriodChange(timePeriod) {
    this.props.fields.end_at.onChange(null);

    if (TIME_PERIOD_PRE_DEFINED_REG_EXP.test(timePeriod)) {
      const timePeriodDays = parseInt(timePeriod.replace('last_', ''), 10);
      this.props.fields.start_at.onChange(preDefinedTimePeriodToDate(timePeriodDays));
    } else if (TIME_PERIOD_CUSTOM === timePeriod) {
      this.props.fields.start_at.onChange(null);
    } else {
      this.props.fields.start_at.onChange(formatStartDate(timePeriod));
    }
  }

  render() {
    const {
      exportEnums,
      exportTargets,
      exportTypes,
      fields,
      groupId,
      isCC,
      registerField,
    } = this.props;

    const startEndDateClass = () => classNames({
      row: true,
      hidden: fields.time_period.value !== TIME_PERIOD_CUSTOM,
    });

    return (
      <div className="export-create-fields">
        <div className="row">
          <div className="col-xs-8">
            <ExportTargetSelect
              exportTargets={exportTargets}
              fields={fields}
              groupId={groupId}
              isCC={isCC}
              registerField={registerField}
            />
            <SelectField
              className="export-create-fields__select"
              ref={registerField}
              id="export-create-input__type"
              field={fields.type}
              label="Export Type"
              options={exportTypes}
              labelKey="display_name"
              valueKey="value"
              validations={validations.isRequired}
              inline={false}
              disabled={_.isEmpty(fields.target_ids.value)}
              required
            />
            <SelectField
              className="export-create-fields__select"
              ref={registerField}
              id="export-create-input__period"
              field={fields.time_period}
              label="Date Range"
              options={ExportCreateFields.filterTimePeriods(exportEnums.time_periods)}
              labelKey="display_name"
              valueKey="value"
              validations={validations.isRequired}
              inline={false}
              onChange={this.onTimePeriodChange}
              required
              searchEnabled={false}
            />
          </div>
        </div>

        <div className={startEndDateClass()}>
          <div className="col-xs-4">
            <DateField
              className="export-create-fields__date"
              ref={registerField}
              field={fields.start_at}
              id="export-create-input__start-at"
              inline={false}
              label="Start Date"
              valueFormat={SERVER_DATE_FORMAT}
              dateFormat={SERVER_DATE_FORMAT}
              validations={[
                {
                  func: (value) => {
                    if (fields.time_period.value === TIME_PERIOD_CUSTOM) {
                      return validations.isRequired(value);
                    }
                    return null;
                  },
                },
              ]}
              maxDate={today()}
              required
              isUtc={false}
            />
          </div>
          <div className="col-xs-4">
            <DateField
              className="export-create-fields__date"
              ref={registerField}
              field={fields.end_at}
              id="export-create-input__end-at"
              inline={false}
              label="End Date"
              valueFormat={SERVER_DATE_FORMAT}
              dateFormat={SERVER_DATE_FORMAT}
              validations={[
                {
                  func: (value, _ruleMessage, _ruleArgs, newFields) => {
                    if (fields.time_period.value === TIME_PERIOD_CUSTOM) {
                      return _.compact([
                        validations.isRequired(value),
                        validate1Year(newFields.start_at, value),
                      ]);
                    }
                    return null;
                  },
                },
              ]}
              maxDate={today()}
              required
              isUtc={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

ExportCreateFields.fields = [
  'target_ids',
  'target_type',
  'type',
  'time_period',
  'start_at',
  'end_at',
];

ExportCreateFields.propTypes = {
  exportEnums: PropTypes.shape({
    time_periods: PropTypes.array,
    form_types: PropTypes.array,
  }).isRequired,
  exportTargets: PropTypes.array.isRequired,
  exportTypes: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  isCC: PropTypes.bool.isRequired,
  registerField: PropTypes.func.isRequired,
};

export default ExportCreateFields;
