export default ({ flags, session }) => {
  const { autoLogoutConfig = {} } = flags;
  const configExpire = autoLogoutConfig.logout;
  const tokenExpire = session.expires_in;

  if (configExpire === 'session') {
    return tokenExpire;
  }

  return configExpire;
};
