import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'common/spinners';
import DynamicSearchTable from 'src/components/Backoffice/searchTable/DynamicSearchTable';
import { useFindPaymentsEmployees } from 'src/components/Backoffice/api/hooks/v1/employeeHooks';
import { useFindProvider } from 'src/components/Organization/api/hooks/v1/providerHooks';
import { DEFAULT_PAGE_SIZE } from 'src/components/Backoffice/backofficeUtils';

const AddCareManagersTable = ({
  selectedEmployeeIds, setSelectedEmployeeIds, organizationId, feeScheduleId,
}) => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState('');

  const { data: employeesResponse, isFetching: isFetchingEmployees } = useFindPaymentsEmployees(
    organizationId,
    searchText,
    feeScheduleId,
    {
      page: {
        size: pageSize,
        number: pageNumber,
      },
    },
  );
  const { data: employees, paging } = employeesResponse.data;

  const { data: provider, isFetching: isFetchingProvider } = useFindProvider({ providerId: organizationId });

  useEffect(() => {
    setPageNumber(1);
  }, [searchText]);

  const generateDataForTable = (employeesList) => {
    const data = [];
    employeesList.forEach((employee) => {
      data.push({
        id: employee.id,
        data: [`${employee.last_name}, ${employee.first_name}`, employee.email, employee.id],
      });
    });
    return data;
  };

  if (isFetchingEmployees || isFetchingProvider) return <Spinner />;

  return (
    <>
      <DynamicSearchTable
        searchResults={generateDataForTable(employees)}
        rowHeaders={['User Name', 'User Email', 'User ID']}
        selectedRowIds={selectedEmployeeIds}
        setSelectedRowIds={setSelectedEmployeeIds}
        setSearch={setSearchText}
        emptyTableMessage={'No users found.'}
        searchBarPlaceHolder={'Enter user\'s name or email...'}
        searchTitle={'User Name'}
        tableTitle={`Users under ${provider?.name}`}
        dataTestId={'add-care-managers'}
        isMultiSelect
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        paging={paging}
        showSelectedAmount
      />
    </>
  );
};

AddCareManagersTable.propTypes = {
  selectedEmployeeIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedEmployeeIds: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  feeScheduleId: PropTypes.string.isRequired,
};

export default AddCareManagersTable;
