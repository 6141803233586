import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import _, { noop, compact } from 'lodash';
import { CheckBoxField, InputField, SelectField } from '@unite-us/ui';
import { validations } from '@unite-us/app-components';
import {
  AddressRemoveButton,
  AddressFieldConditionalDisplay,
} from './components';

import {
  afValidations,
} from './utils';

import {
  DISPLAY_CITY_STATE,
  DISPLAY_POSTAL_CODE,
  DISPLAY_STREET_CITY_STATE_POSTAL_CODE,
  DISPLAY_ADDRESS_TYPE,
  DISPLAY_MAILING_ADDRESS,
} from './constants';

import './AddressField.scss';

export const AddressFieldFinalForm = (props) => {
  const {
    addressPath,
    addressTypes,
    displayFieldSet,
    forceRequired,
    id,
    inClientCreation,
    index,
    inline,
    inputStyle,
    isValidAddress,
    label,
    labelStyle,
    multi,
    remove,
    requireAddressLine1,
    usStates,
  } = props;

  const [showRemove, setShowRemove] = useState(false);

  const toggleRemove = () => {
    setShowRemove(!showRemove);
  };

  const confirmRemove = () => {
    remove();
    toggleRemove();
  };

  const validateAddressType = (value, allValues) => {
    const currentValues = _.get(allValues, addressPath, {});
    return afValidations.isRequiredAddressType({
        forceRequired,
        values: currentValues,
        displayFieldSet,
        value,
      });
  };

  const validateCity = (value, allValues) => {
    const currentValues = _.get(allValues, addressPath, {});
    return afValidations.isRequiredCity({ forceRequired, values: currentValues, value });
  };

  const validateState = (value, allValues) => {
    const currentValues = _.get(allValues, addressPath, {});
    return afValidations.isRequiredState({ forceRequired, values: currentValues, value });
  };

  const groupLabelClass = () => classNames('group-label', {
    hidden: label === undefined,
    inline,
  });

  const style = {
    label: labelStyle,
    input: inputStyle,
  };

  return (
    <div className="address-field">
      { inClientCreation && index !== 0 && (<div className="payments-insurance-client-divider" />) }

      <div className="address-field__title row">
        <div className="col-xs-12">
          <strong className={groupLabelClass()} style={labelStyle}>
            {label}
          </strong>
        </div>
      </div>
      {(isValidAddress === false) && (
        <div className="col-xs-9 address-field__error">
          <p>*Invalid Address. Please make sure you enter a valid address.</p>
        </div>
      )}
      <div className="row">
        <AddressFieldConditionalDisplay selectedFieldSet={displayFieldSet} validDisplayTypes={DISPLAY_ADDRESS_TYPE}>
          <div className="col-xs-12 col-sm-6">
            <Field
              name={`${addressPath}.address_type`}
              validate={validateAddressType}
            >
              {(fieldProps) => (
                <SelectField
                  searchEnabled={false}
                  className="address-field-type"
                  id={`${id}-type`}
                  inline={inline}
                  label="Address Type"
                  labelKey="display_name"
                  options={addressTypes}
                  placeholder="Select type"
                  style={style}
                  valueKey="value"
                  {...fieldProps}
                />
              )}
            </Field>
          </div>
        </AddressFieldConditionalDisplay>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <AddressFieldConditionalDisplay
            selectedFieldSet={displayFieldSet}
            validDisplayTypes={DISPLAY_STREET_CITY_STATE_POSTAL_CODE}
          >
            <Field
              name={`${addressPath}.line_1`}
              validate={requireAddressLine1 ? (value) => validations.isRequired(value) : _.noop}
            >
              {(fieldProps) => (
                <InputField
                  autoComplete="no"
                  id={`${id}-line1`}
                  inline={inline}
                  label="Address Line 1"
                  style={style}
                  {...fieldProps}
                />
              )}
            </Field>
          </AddressFieldConditionalDisplay>

          <AddressFieldConditionalDisplay
            selectedFieldSet={displayFieldSet}
            validDisplayTypes={DISPLAY_STREET_CITY_STATE_POSTAL_CODE}
          >
            <Field name={`${addressPath}.line_2`}>
              {(fieldProps) => (
                <InputField
                  autoComplete="no"
                  id={`${id}-line2`}
                  inline={inline}
                  label="Address Line 2"
                  style={style}
                  {...fieldProps}
                />
              )}
            </Field>
          </AddressFieldConditionalDisplay>

          <AddressFieldConditionalDisplay
            selectedFieldSet={displayFieldSet}
            validDisplayTypes={DISPLAY_CITY_STATE}
          >
            <Field
              name={`${addressPath}.city`}
              validate={validateCity}
            >
              {(fieldProps) => (
                <InputField
                  autoComplete="no"
                  id={`${id}-city`}
                  inline={inline}
                  label="City"
                  placeholder="City"
                  style={style}
                  {...fieldProps}
                />
              )}
            </Field>
          </AddressFieldConditionalDisplay>
        </div>
      </div>

      <div className="row">
        <AddressFieldConditionalDisplay
          selectedFieldSet={displayFieldSet}
          validDisplayTypes={DISPLAY_CITY_STATE}
        >
          <div className="col-xs-12 col-sm-6">
            <Field
              name={`${addressPath}.state`}
              validate={validateState}
            >
              {(fieldProps) => (
                <SelectField
                  className="address-field-state"
                  hideLabel={inline}
                  id={`${id}-state`}
                  inline={inline}
                  label="State"
                  labelKey="display_name"
                  options={usStates}
                  placeholder="Select state"
                  style={style}
                  valueKey="value"
                  {...fieldProps}
                />
              )}
            </Field>
          </div>
        </AddressFieldConditionalDisplay>

        <AddressFieldConditionalDisplay
          selectedFieldSet={displayFieldSet}
          validDisplayTypes={DISPLAY_POSTAL_CODE}
        >
          <div className="col-xs-12 col-sm-6">
            <Field
              name={`${addressPath}.postal_code`}
              validate={(value, allValues) => {
                const currentValues = _.get(allValues, addressPath, {});
                const requiredError = afValidations.isRequiredZipCode({ forceRequired, values: currentValues, value });
                const formatError = afValidations.zipCodeNumberLength({ value, message: 'Zip code must be 5 digits' });
                const errors = compact([requiredError, formatError]);
                return errors.length > 0 ? errors : null;
              }}
            >
              {(fieldProps) => (
                <InputField
                  autoComplete="no"
                  hideLabel={inline}
                  id={`${id}-postal-code`}
                  inline={inline}
                  label="Zip Code"
                  maxLength={5}
                  placeholder="Zip Code"
                  style={style}
                  {...fieldProps}
                />
              )}
            </Field>
          </div>
        </AddressFieldConditionalDisplay>
      </div>

      <div className="row">
        <AddressFieldConditionalDisplay
          selectedFieldSet={displayFieldSet}
          validDisplayTypes={DISPLAY_MAILING_ADDRESS}
        >
          <div className="col-xs-6">
            <Field name={`${addressPath}.is_mailing_address`}>
              {(fieldProps) => (
                <CheckBoxField
                  id={`${id}-is-mailing`}
                  label="Mailing address"
                  style={{ marginTop: '6px' }}
                  {...fieldProps}
                />
              )}
            </Field>
          </div>
        </AddressFieldConditionalDisplay>
      </div>

      <div className="row justify-end mx-0">
        {((index !== 0 && inClientCreation) || (!inClientCreation)) && (
          <div className="mb-4">
            <AddressRemoveButton
              confirmRemove={confirmRemove}
              displayText={'Delete Address'}
              iconSize={18}
              multi={multi}
              remove={remove}
              showRemove={showRemove}
              toggleRemove={toggleRemove}
              trashColor="#4571BA"
            />
          </div>
        )}
      </div>
    </div>
  );
};

AddressFieldFinalForm.propTypes = {
  addressPath: PropTypes.string,
  addressTypes: PropTypes.array.isRequired,
  displayFieldSet: PropTypes.string,
  forceRequired: PropTypes.bool,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  inClientCreation: PropTypes.bool,
  inline: PropTypes.bool,
  inputStyle: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  labelStyle: PropTypes.object,
  multi: PropTypes.bool,
  requireAddressLine1: PropTypes.bool,
  remove: PropTypes.func,
  usStates: PropTypes.array.isRequired,
  isValidAddress: PropTypes.bool,
  clearValidation: PropTypes.func,
  onPrimaryChange: PropTypes.func,
};

AddressFieldFinalForm.defaultProps = {
  addressPath: 'addresses',
  displayFieldSet: 'full',
  forceRequired: false,
  inline: true,
  inClientCreation: false,
  inputStyle: {},
  label: '',
  labelStyle: {},
  multi: true,
  remove: noop,
  isValidAddress: true,
  requireAddressLine1: false,
  clearValidation: noop,
  onPrimaryChange: noop,
};

function mapStateToProps(state, ownProps) {
  if (!state.session) {
    return {
      addressTypes: _.get(ownProps, 'addressTypes', ownProps.enums.contact_data.address_types),
      usStates: ownProps.enums.geography.us_states,
    };
  }
  return {
    addressTypes: _.get(ownProps, 'addressTypes', state.session.enums.contact_data.address_types),
    usStates: state.session.enums.geography.us_states,
  };
}

export default connect(mapStateToProps)(AddressFieldFinalForm);
