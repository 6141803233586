import _ from 'lodash';
import { formatGroupsAndPrograms } from 'src/components/Referrals/ReferralGroupsPrograms/utils';

function getDocuments(service = {}) {
  return [
    ...(service.documents || []),
    ..._.reduce(service.attachableDocuments, (acc, curr = {}) => (
      curr.attached ? [...acc, {
        document_id: _.get(curr, 'document.id'),
        title: _.get(curr, 'document.title'),
      }] : acc
    ), []),
  ];
}

function formatReferral(service) {
  const selectedGroups = _.get(service, 'selected', []);

  return {
    auto_recallable: selectedGroups.length > 1 && service.auto_recallable ?
      'true' :
      'false',
    description: service.notes,
    documents: getDocuments(service),
    referred_by_network_id: service.referred_by_network.id,
    referred_to_groups: formatGroupsAndPrograms(service.selected),
    referred_to_network_id: _.wget(service, 'referred_to_network.id', null) ||
      _.wget(service, 'referred_by_network.id', null),
    service_type_id: _.wget(service, 'service_type.id'),
  };
}

function getDocumentCount(documents = []) {
  // Sometimes documents is a FileList (and has a length),
  // other times it is an object with Files (count the keys).
  return documents.length || _.keys(documents).length;
}

function checkUploads(service) {
  const uploads = _.get(service, 'uploadedDocuments', []);
  const docCount = getDocumentCount(uploads);

  return new Promise((resolve, reject) => {
    const documents = [];
    if (_.isEmpty(uploads)) {
      resolve(service);
    }

    _.each(uploads, (doc) => {
      const reader = new FileReader();

      reader.onload = () => {
        documents.push({
          document_data_uri: reader.result,
          title: doc.name,
        });

        if (docCount === documents.length) {
          resolve({ ...service, documents });
        }
      };

      reader.onerror = () => {
        reject({
          status: 'File load failed',
        });
      };

      reader.readAsDataURL(doc);
    });
  });
}

function verifyServices(services) {
  const promises = _.map(services, checkUploads);
  return Promise.all(promises);
}

export default function referralParams(services, options = {}) {
  return new Promise((resolve) => (
    verifyServices(services)
      .then((verifiedServices) => {
        const referrals = _.map(
          verifiedServices,
          (service) => formatReferral(service),
        );
        const { assistance_request_id, screening_id } = options;
        resolve(_.uuCompactArrayOrObject({
          assistance_request_id,
          screening_id,
          referrals,
        }));
      })
      .catch((error) => {
        throw error;
      })
  ));
}
