import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';
import './FilterToggle.scss';

const FilterToggle = (
  {
    iconSize,
    onClick,
    name,
    leftIcon,
    rightIcon,
  },
  ) => (
    <a
      className="filter-toggle"
      id="filters-drawer-anchor"
      role="button"
      onClick={onClick}
    >
      {leftIcon && (
        <Icon
          className="filter-toggle__icon__left"
          icon={leftIcon}
          size={iconSize}
        />
      )}
      <div className="filter-toggle__text">
        {name || 'Filters'}
      </div>
      {rightIcon && (
        <Icon
          className="filter-toggle__icon"
          icon={rightIcon}
          size={iconSize}
        />
      )}
    </a>
);

FilterToggle.propTypes = {
  iconSize: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string,
  leftIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  rightIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

FilterToggle.defaultProps = {
  iconSize: 18,
  rightIcon: 'IconSliders',
};

export default FilterToggle;
