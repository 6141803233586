import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@unite-us/ui';
import cx from 'classnames';

const DISPLAY_NAME = 'uu-icon-button';

const IconButton = ({
  className,
  disabled,
  icon,
  id,
  labelText,
  onClick,
  dataTestId,
  removeMargin,
}) => (
  <button
    className={cx(
      DISPLAY_NAME,
      className,
      disabled && 'pointer-events-none opacity-50',
      'flex justify-center items-center space-x-2 cursor-pointer ml-1',
    )}
    id={id}
    onClick={onClick}
    tabIndex={0}
    type="button"
    data-testid={dataTestId}
    aria-haspopup="dialog"
  >
    <Icon icon={icon} className="fill-current text-action-blue" size={14} />
    <span className="normal-case text-action-blue whitespace-no-wrap block" style={removeMargin ? { margin: 0 } : {}}>
      {labelText}
    </span>
  </button>
);

IconButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
  removeMargin: PropTypes.bool,
};

IconButton.defaultProps = {
  className: '',
  disabled: false,
  dataTestId: '',
  id: '',
  removeMargin: false,
};

export default IconButton;
