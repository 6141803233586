import {
  generateUUID,
} from 'common/utils/utils';

// THESE ARE USED IN ALL CONFIGS
// DON'T UPDATE UNLESS ALL CONFIGS NEED YOUR UPDATE
// YOU CAN OVERRIDE ANY OF THESE IN THE CONFIG YOU ARE WORKING IN
const first = {
  id: generateUUID(),
  isStepDisabled: () => false,
  isStepCompleted: ({
    activeStepIndex = -1,
  }) => activeStepIndex > 0,
};

const second = {
  id: generateUUID(),
  isStepDisabled: ({
    activeStepIndex = -1,
  }) => activeStepIndex !== 1,
  isStepCompleted: ({
    activeStepIndex = -1,
  }) => activeStepIndex > 1,
};

const third = {
  id: generateUUID(),
  isStepDisabled: ({
    activeStepIndex = -1,
  }) => activeStepIndex < 2,
  isStepCompleted: ({
    activeStepIndex = -1,
  }) => activeStepIndex > 2,
};

const fourth = {
  id: generateUUID(),
  isStepDisabled: ({
    activeStepIndex = -1,
  }) => activeStepIndex < 3,
  isStepCompleted: () => false,
};

const STEP_DEFAULTS = {
  first,
  second,
  third,
  fourth,
};

export default STEP_DEFAULTS;
