import { TIMEOUT_DELAY } from '../constants';

export default function setMyTimeout({
  timer, func, value, delay = TIMEOUT_DELAY,
}) {
  if (timer) {
    clearTimeout(timer);
  }
  return setTimeout(() => func(value), delay);
}
