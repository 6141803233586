import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { noop, wget } from 'lodash';
import { SelectField } from '@unite-us/ui';
import { PHONE_TYPE_OPTIONS } from '../constants';
import './stylesheets/phoneTypeField.scss';

const PhoneTypeField = (props) => (
  <SelectField
    className="phone-type-field"
    ref={props.registerField}
    {...props}
  />
);

PhoneTypeField.propTypes = {
  forceObjectValue: PropTypes.bool,
  groupUser: PropTypes.bool,
  hideLabel: PropTypes.bool,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  options: PropTypes.array.isRequired,
  phoneFor: PropTypes.oneOf(['contact', 'group', 'program', 'location']),
  placeholder: PropTypes.string,
  registerField: PropTypes.func,
  shouldSort: PropTypes.bool,
  valueKey: PropTypes.string,
};

PhoneTypeField.defaultProps = {
  forceObjectValue: true,
  groupUser: false,
  hideLabel: true,
  label: 'Phone Type',
  labelKey: 'display_name',
  phoneFor: 'contact',
  placeholder: 'Phone Type',
  registerField: noop,
  shouldSort: false,
  valueKey: 'value',
};

function mapStateToProps(state, ownProps) {
  const { phoneFor = 'contact', groupUser } = ownProps;
  let options;

  if (phoneFor === 'program' || phoneFor === 'group' || phoneFor === 'location') {
    options = PHONE_TYPE_OPTIONS;
  } else {
    options = groupUser ?
      wget(state, `session.enums.${phoneFor}_data.phone_types`, []).filter((phoneType) => phoneType.value !== 'home') :
      wget(state, `session.enums.${phoneFor}_data.phone_types`, []);
  }

  return {
    options,
  };
}

export default connect(mapStateToProps)(PhoneTypeField);
