import { get } from 'lodash';
import { useVersions } from 'common/hooks';

const useDateRequested = (serviceAuthorizationId) => {
  const { data } = useVersions('service_authorization', serviceAuthorizationId);
  const versions = get(data, 'data.data', []);

  const requestVersion = versions.find((version) => get(version, 'object_changes.state[1]') === 'requested');
  return requestVersion?.created_at;
};

export default useDateRequested;
