import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NumberStatistic } from 'src/common/charts';
import { fetchCasesReports } from 'actions/Report/Case';
import _ from 'lodash';

export default class TotalUniqueCases extends Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.state = {
      totalUniqueCases: -1,
      title: '',
    };
  }

  componentDidMount() {
    this.fetchData(this.props.filters);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.filters, nextProps.filters)) {
      this.fetchData(nextProps.filters);
    }
  }

  fetchData(filters) {
    const { scope, id } = this.props;
    fetchCasesReports(scope, id, 'count', filters)
      .then((response) => {
        this.setState({
          totalUniqueCases: _.get(response, 'data.total', 0),
          title: _.get(response, 'data.title'),
        });
        this.props.loaded('totalUniqueCases');
      });
  }

  render() {
    if (this.state.totalUniqueCases < 0) {
      return null;
    }
    return (
      <NumberStatistic
        data={this.state.totalUniqueCases}
        title={this.state.title}
        icon="IconFileO"
      />
    );
  }
}

TotalUniqueCases.propTypes = {
  filters: PropTypes.shape({
    end_date: PropTypes.string,
    network_ids: PropTypes.array,
    start_date: PropTypes.string,
  }),
  loaded: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
};
