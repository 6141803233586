import { useFind } from '../../../api/APIHooks';

const useNetworkLeadRelationship = (invoice) => {
  const { data: networkLeadRelationshipData } = useFind(
    'network_lead_relationships',
    {
      provider: invoice?.provider_id,
      fee_schedule: invoice?.fee_schedule_id,
    },
    {
      queryConfig: {
        placeholderData: undefined,
        enabled: !!invoice,
      },
    },
  );

  return networkLeadRelationshipData?.data?.data;
};

export default useNetworkLeadRelationship;
