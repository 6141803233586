import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { noop } from 'lodash';
import { BarLoader } from '@unite-us/ui';
import EditModal from 'common/modal/EditModal';
import EditEmailAddressesGroup from 'common/form/Profile/EditEmailAddresses/EditEmailAddressesGroup';
import { primaryFirstEmailSort } from 'common/form/Profile/EditEmailAddresses/utils';
import Email from 'common/display/Email';
import { RowDisplay } from 'common/display/Profile';
import { EDIT_EMAIL_ADDRESSES } from 'common/display/Profile/constants/form';

export class EmailAddressesGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailDisplay: [],
    };

    this.setEmailDisplay = this.setEmailDisplay.bind(this);
  }

  componentDidMount() {
    this.setEmailDisplay(this.props.emails);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.emails !== nextProps.emails) {
      this.setEmailDisplay(nextProps.emails);
    }
  }

  setEmailDisplay(emails = []) {
    const emailDisplay = primaryFirstEmailSort(emails).map((email, index) => (
      <div className="col-sm-6 mb-two" key={`${email.id}-${index}`}>
        <Email item={email} />
      </div>
    ));
    this.setState({ emailDisplay });
  }

  render() {
    const {
      emails,
      groupId,
      hideCommunicationPreferences,
      isFetching,
      onSave,
    } = this.props;

    const editSection = (
      <EditModal
        header="Edit Email"
        formName={EDIT_EMAIL_ADDRESSES}
        buttonId="edit-email-btn"
        id="edit-email-modal"
      >
        {
          isFetching ? (
            <div>
              <BarLoader className="mb-quarter" size="semi-full" />
              <BarLoader className="mb-quarter" size="half" />
              <BarLoader className="mb-quarter" size="semi-full" />
            </div>
          ) : (
            <EditEmailAddressesGroup
              emails={emails}
              groupId={groupId}
              hideCommunicationPreferences={hideCommunicationPreferences}
              onSave={onSave}
            />
          )
        }
      </EditModal>
    );

    return (
      <RowDisplay
        className="email-addresses"
        editSection={editSection}
        label="Email"
      >
        {this.state.emailDisplay}
      </RowDisplay>
    );
  }
}

EmailAddressesGroup.propTypes = {
  emails: PropTypes.array,
  groupId: PropTypes.string.isRequired,
  hideCommunicationPreferences: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
};

EmailAddressesGroup.defaultProps = {
  emails: [],
  hideCommunicationPreferences: false,
  onSave: noop,
};

export default EmailAddressesGroup;
