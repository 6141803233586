import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import _ from 'lodash';

// Action Creators
import { addContactToGroup } from 'actions/Contact/Group';
import { assignCareCoordinator } from 'actions/CareCoordinator/Contact/Group';
import { setDashboardRefetch } from 'actions/Dashboard';
import { fetchClientRelationship } from 'src/actions/CareCoordinator/Contact/Group/fetchClientRelationship';

// Utilities
import labelCustomization from 'src/common/utils/Customization/labels';
import defaultLabels from 'src/constants/labels';

// Components
import IconButton from 'common/buttons/IconButton/IconButton';
import { Spinner } from 'common/spinners';
import { Dialog } from '@unite-us/ui';
import Assign from './components/Assign';
import CareCoordinatorInfo from './components/CareCoordinatorInfo';
import EditCareCoordinatorForm from './components/EditCareCoordinatorForm';
import './stylesheets/care-coordinator.scss';

class CareCoordinator extends Component {
  constructor(props) {
    super(props);

    this.showModal = this.showModal.bind(this);
    this.onModalCancel = this.onModalCancel.bind(this);
    this.onModalConfirmation = this.onModalConfirmation.bind(this);
    this.getClientRelationship = this.getClientRelationship.bind(this);

    this.state = {
      clientRelationship: {},
      isFetching: false,
    };
  }

  componentDidMount() {
    this.getClientRelationship();
  }

  onModalCancel() {
    this.careCoordinatorModal.closeDialog();
  }

  onModalConfirmation(formData) {
    const { care_coordinator: careCoordinator } = formData;
    const { clientRelationship } = this.state;
    const clientRelationshipId = _.get(clientRelationship, 'id', null);
    const { isCC, groupId, contact: { id: contactId } } = this.props;
    const msg = `${this.props.labels.CareCoordinator} assigned.`;

    return this.props.assignCareCoordinator(clientRelationshipId, careCoordinator, isCC, contactId, groupId, msg)
      .then((response) => {
        this.getClientRelationship();
        this.props.setDashboardRefetch();
        this.careCoordinatorModal.closeDialog();
        return response;
      });
  }

  async getClientRelationship() {
    const {
      groupId,
      contact: { id: contactId },
    } = this.props;
    this.setState({
      isFetching: true,
    });
    const clientRelationship = await fetchClientRelationship(contactId, groupId);
    this.setState({
      clientRelationship,
      isFetching: false,
    });
  }

  showModal() {
    this.careCoordinatorModal.openDialog();
  }

  render() {
    const { clientRelationship, isFetching } = this.state;
    const {
            detailObj, showCCDetails, className, getClientRelationship, labels,
          } = this.props;
    const hasCareCoordinator = !!_.get(clientRelationship, 'care_coordinator');
    return (
      <div className={classNames(className, 'care-coordinator')}>
        <div className="flex items-center justify-between mb-1">
          <h3>{labels.CareCoordinator}</h3>
          {!isFetching && hasCareCoordinator && (
            <IconButton
              className="care-coordinator__edit-btn"
              labelText="Edit"
              icon="IconPencil"
              onClick={this.showModal}
            />
          )}
        </div>
        {isFetching && <Spinner scale={0.5} />}
        {!isFetching && hasCareCoordinator ? (
          <CareCoordinatorInfo
            careCoordinator={clientRelationship?.care_coordinator}
            showDetails={showCCDetails}
          />
        ) : <Assign showModal={this.showModal} />}
        <Dialog
          id="care-coordinator-modal"
          ref={(el) => { this.careCoordinatorModal = el; }}
          title={`Assign ${labels.CareCoordinator}`}
          size="normal"
        >
          <EditCareCoordinatorForm
            detailObj={detailObj}
            coordinator={clientRelationship?.care_coordinator}
            onCancel={this.onModalCancel}
            onFormSubmit={this.onModalConfirmation}
            getClientRelationship={getClientRelationship}
          />
        </Dialog>
      </div>
    );
  }
}

CareCoordinator.propTypes = {
  className: PropTypes.string,
  assignCareCoordinator: PropTypes.func.isRequired,
  contact: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  setDashboardRefetch: PropTypes.func.isRequired,
  detailObj: PropTypes.object.isRequired,
  showCCDetails: PropTypes.bool,
  isCC: PropTypes.bool.isRequired,
  getClientRelationship: PropTypes.func.isRequired,
  labels: PropTypes.object,
};

CareCoordinator.defaultProps = {
  className: '',
  showCCDetails: false,
  labels: defaultLabels,
};

function mapStateToProps(state) {
  const { groupId } = state.session;
  const currentGroup = _.get(state, 'globalState.currentProvider.group') || {};

  return {
    isCC: _.get(currentGroup, 'provider_type') === 'coordination_center',
    groupId,
    labels: labelCustomization(state),
  };
}

export default connect(mapStateToProps, {
  addContactToGroup,
  assignCareCoordinator,
  setDashboardRefetch,
})(CareCoordinator);
