import React from 'react';
import PropTypes from 'prop-types';
import { StatusBadge } from 'src/common/badges';
import { AUTHORIZATION_STATUSES } from 'src/common/Status/authorizationConstants';

const badgeProps = {
  [AUTHORIZATION_STATUSES.open]: {
    className: 'bg-dark-border-blue text-text-blue',
    label: 'Open',
  },
  [AUTHORIZATION_STATUSES.accepted]: {
    className: 'bg-dark-green text-white',
    label: 'Accepted',
  },
  [AUTHORIZATION_STATUSES.rejected]: {
    className: 'bg-red text-white',
    label: 'Rejected',
  },
};

const validStatuses = Object.keys(badgeProps);

const ServiceAuthorizationStatusIndicator = ({ status, autoApproved }) => {
  if (!validStatuses.includes(status)) return '';
  const { className, label } = badgeProps[status];
  return <StatusBadge className={className} label={autoApproved ? `Auto ${label}` : label} />;
};

ServiceAuthorizationStatusIndicator.propTypes = {
  status: PropTypes.oneOf(validStatuses).isRequired,
  autoApproved: PropTypes.bool,
};

ServiceAuthorizationStatusIndicator.defaultProps = {
  autoApproved: false,
};

export default ServiceAuthorizationStatusIndicator;
