import _ from 'lodash';

function getStatusesOptions(
  state = {},
) {
  return [
    {
      label: 'Open',
      value: 'open',
      initial: _.includes(state.filters.status_filter, 'open'),
    },
    {
      label: 'Closed',
      value: 'closed',
      initial: _.includes(state.filters.status_filter, 'closed'),
    },
  ];
}

export default getStatusesOptions;
