import _ from 'lodash';

export const statuses = {
  initial: 'initial',
  changing: 'changing',
  blured: 'blured',
  changed: 'changed',
  submitting: 'submitting',
};

function setState(state, form, status) {
  return { ...state, [form]: status };
}

export default function formRendererReducer(state = {}, action) {
  switch (action.type) {
    case '@@redux-form/INITIALIZE':
      return setState(state, action.meta.form, statuses.initial);
    case '@@redux-form/DESTROY':
      return _.assign({}, _.filter(state, (value, key) => key !== action.form));
    case '@@redux-form/CHANGE':
      return setState(state, action.meta.form, statuses.changing);
    case '@@redux-form/BLUR':
      if (state[action.meta.form] === statuses.changing) {
        return setState(state, action.meta.form, statuses.changed);
      }
      return setState(state, action.form, statuses.blured);
    case 'FORM_SAVING':
      return setState(state, action.form, statuses.submitting);
    case 'FORM_COMPLETE':
      return setState(state, action.form, statuses.initial);
    default:
      return state;
  }
}
