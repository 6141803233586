import { isEmpty } from 'lodash';

const showFeeScheduleFilter = (groupsOptionType = []) => {
  if (Array.isArray(groupsOptionType)) {
    return groupsOptionType.includes('in-network') || isEmpty(groupsOptionType);
  }

  return groupsOptionType === 'in-network';
};

export default showFeeScheduleFilter;
