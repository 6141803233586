import React from 'react';
import { AUTHORIZATION_STATUSES } from 'src/common/Status/authorizationConstants';
import ServiceAuthorizationWorkqueue from './ServiceAuthorizationWorkqueue';

const AcceptedServiceAuthorizations = () => (
  <ServiceAuthorizationWorkqueue
    authorizationStatus={AUTHORIZATION_STATUSES.accepted}
  />
);

export default AcceptedServiceAuthorizations;
