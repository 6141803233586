import { READ_ONLY_OPEN_PAYER_STATUSES, OPEN_PAYER_STATUSES, CLOSED_PAYER_STATUSES, ALL_PAYER_STATUSES, READ_ONLY_ALL_PAYER_STATUSES } from '../constants';

const getWQInvoiceStatus = (invoiceStatus, isPayer, payerWQInteractiveView) => {
  if (!isPayer) return invoiceStatus;
  if (isPayer && payerWQInteractiveView) return invoiceStatus;
  let selectReadOnlyInvoiceStatus;

  switch (invoiceStatus) {
    case (ALL_PAYER_STATUSES):
      selectReadOnlyInvoiceStatus = READ_ONLY_ALL_PAYER_STATUSES;
      break;
    case (OPEN_PAYER_STATUSES):
      selectReadOnlyInvoiceStatus = READ_ONLY_OPEN_PAYER_STATUSES;
      break;
    case '':
      selectReadOnlyInvoiceStatus = READ_ONLY_ALL_PAYER_STATUSES;
      break;
    default:
      selectReadOnlyInvoiceStatus = CLOSED_PAYER_STATUSES;
  }

  return selectReadOnlyInvoiceStatus;
};

export default getWQInvoiceStatus;
