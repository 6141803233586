import React from 'react';
import PropTypes from 'prop-types';
import { Filter } from '@unite-us/client-utils';

const FilterNetworkScope = (props) => (
  <div className="browse-filters-network-scope">
    <Filter
      name="Network Scope"
      filterKey="networkScopes"
      onFiltersChange={props.onFiltersChange}
      options={props.options}
    />
  </div>
);

FilterNetworkScope.propTypes = {
  onFiltersChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default FilterNetworkScope;
