import PropTypes from 'prop-types';
import React from 'react';
import NetworkBrowseDirectory from 'src/components/Browse/NetworkBrowseDirectory';

const NetworkBrowse = ({
  currentUserGroup,
  networkId,
  referralScopes,
  isCCGroup,
}) => (
  currentUserGroup && networkId ? (
    <NetworkBrowseDirectory
      currentUserGroup={currentUserGroup}
      networkId={networkId}
      contextAction="network"
      referralScopes={referralScopes}
      isCCGroup={isCCGroup}
    />
  ) : null
);

NetworkBrowse.propTypes = {
  currentUserGroup: PropTypes.object,
  networkId: PropTypes.string,
  referralScopes: PropTypes.array,
  isCCGroup: PropTypes.bool,
};

NetworkBrowse.defaultProps = {
  referralScopes: [],
  isCCGroup: false,
};

export default NetworkBrowse;
