import React, { useState } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import mapProviderIdToProps from 'src/components/Organization/utils/mapProviderIdToProps';
import OrganizationHeader from 'src/components/Organization/components/OrganizationHeader';
import LocationForm from 'src/components/Organization/pages/locations/LocationForm';
import FormHeader from 'src/components/Organization/components/FormHeader';
import { Spinner } from 'src/common/spinners';
import $scripts from 'scriptjs';
import { requiredMapScript } from 'src/components/Browse/Map/utils';
import { ORG_TABS } from 'src/components/Organization/constants';
import { useCreateLocation } from 'src/components/Organization/api/hooks/v1';
import { browserHistory } from 'common/utils/browserHistory';
import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import useTrackPageView from 'src/components/Organization/utils/useTrackPageView';
import { ORG_SETTINGS } from 'src/common/utils/EventTracker/utils/eventConstants';
import FormContainer from 'src/components/Organization/components/FormContainer';
import LocationFormSubheader from '../../../components/LocationForm/LocationFormSubheader';

const LocationNew = ({ currentProviderId, location }) => {
  useTrackPageView(ORG_SETTINGS.newLocViewed);

  const [scriptsLoaded, setScriptsLoaded] = useState(window.google && window.google.maps);
  // Let's check to make sure that the google maps script is loaded
  if (!(window.google && window.google.maps)) {
    $scripts(requiredMapScript.scripts, () => {
      setScriptsLoaded(true);
    });
  }
  const { createRecord: createLocation } = useCreateLocation();

  const onSubmit = async (values) => {
    const response = await createLocation({
      ...values,
      provider: currentProviderId,
    });

    if (isHttpSuccess(response.status)) {
      Notifier.dispatch(
        response.status,
        'Location successfully created.',
      );
    }
    browserHistory.push('/organization/settings/');
  };

  if (!scriptsLoaded) {
    return (<Spinner center />);
  }
  return (
    <>
      <OrganizationHeader currentTab={ORG_TABS.organization} location={location} />
      <FormContainer>
        <FormHeader
          headingText="Add Location"
          cancelLink="/organization/settings"
        />
        <LocationFormSubheader />
        <LocationForm
          onSubmit={onSubmit}
        />
      </FormContainer>
    </>
  );
};

LocationNew.propTypes = {
  currentProviderId: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapProviderIdToProps)(LocationNew);
