import {
  SEARCH_NETWORK_GROUPS,
  SEARCH_NETWORK_GROUPS_BY_REFERRAL_SCOPE,
  SET_NETWORK_GROUPS_FETCHING,
  UNSET_NETWORK_GROUPS_FETCHING,
} from 'actions';
import { serviceTypes as serviceTypeUtils } from '@unite-us/client-utils';
import Notifier from 'common/helpers/Notifier';
import { coreApi } from 'src/api/config';

import { addAdditionalProviderAttributes } from 'src/actions/Group/Network/searchProviderUtils';

const getPageFilter = (params) => ({
  page: {
    number: params.page || 1,
    size: 50 || params.per,
  },
  sort: 'name',
});

const onError = (error, errorMessage, dispatch) => {
  Notifier.handleErrors({
    ...error,
    response: {
      ...error.response,
      data: {
        ...error.response.data,
        errors: errorMessage || error.response.data.errors,
      },
    },
  }, dispatch);
  return error;
};

const searchNetworkGroupsByReferralScopes = ({
  referredByNetworkId,
  referredToNetworkId,
  groupId, // sending group id
  options = {},
  serviceTypes,
  errorMessage,
  isOON,
  showInAndOON = false,
} = {}) => (
  async (dispatch) => {
    try {
      const page = getPageFilter(options);

      const licensed = !isOON;
      const referable = licensed ? {
        provider: groupId,
        network: referredByNetworkId,
        recipient_network: referredToNetworkId, // it should only be one network id
      } : null;

      const networksFilter = referredByNetworkId;

      const params = {
        ...(networksFilter ? { networks: networksFilter } : null),
        ...(options.q.service_type_ids ? { 'programs.services': options.q.service_type_ids.join(',') } : null),
        ...(
          showInAndOON ? {} : {
            ...(referable ? { referable } : null),
            licensed,
          }
        ),
        ...(
          options.q.feeScheduleProgramId ? { 'programs.fee_schedule_program': options.q.feeScheduleProgramId } : null
        ),
      };

      dispatch({
        type: SET_NETWORK_GROUPS_FETCHING,
      });

      const payload = await coreApi.query('provider', params, page);
      const networkGroups = payload.data.data;
      await Promise.all([
        coreApi.populateRelationship('programs', 'program', networkGroups),
      ]);

      const composedPayload = JSON.parse(JSON.stringify(payload));
      addAdditionalProviderAttributes(composedPayload.data.data);

      composedPayload.data = {
        ...composedPayload.data,
        data: serviceTypeUtils.composeGroupsList(composedPayload.data.data, serviceTypes),
      };

      dispatch({
        type: SEARCH_NETWORK_GROUPS,
        payload: composedPayload,
        searchParams: params,
      });

      dispatch({
        type: SEARCH_NETWORK_GROUPS_BY_REFERRAL_SCOPE,
        payload: composedPayload,
        searchParams: params,
      });

      dispatch({ type: UNSET_NETWORK_GROUPS_FETCHING });

      return composedPayload;
    } catch (error) {
      return onError(error, errorMessage, dispatch);
    }
  }
);

export default searchNetworkGroupsByReferralScopes;
