import { useFind } from 'src/api/APIHooks';
import { get, groupBy, map } from 'lodash';

const useFeeScheduleProgramOptions = (providerId) => {
  const { isFetching, data } = useFind(
    'fee_schedule_programs',
    {
      'programs.provider': providerId,
      billable: true,
    },
    {
      queryConfig: {
        enabled: !!providerId,
      },
    },
  );

  const groupByFSPName = groupBy(get(data, 'data.data', []), 'name');
  const feeScheduleProgramOptions = map(groupByFSPName, (val, key) => ({
    label: key,
    value: map(val, 'id').join(','),
  }));

  return isFetching ? [] : feeScheduleProgramOptions;
};

export default useFeeScheduleProgramOptions;
