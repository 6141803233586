import React from 'react';
import cx from 'classnames';
import { usePopulate } from 'api/APIHooks';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router';
import { Card } from 'common/Card';
import { LineItem } from 'common/display/LineItem';
import formatShortDate from 'common/utils/Dates/formatShortDate';
import formatSpend from 'common/utils/Money/formatSpend';
import CaseStatus from './CaseStatus';

const AuthorizationSpendCard = (
  {
    item,
    unit,
    isAuthorized,
    isCostBased,
    isCurrentItem,
    fieldClassName,
    cardClassName,
    enabledLink,
    valueClassName,
  },
) => {
  usePopulate('fee_schedule_program', 'fee_schedule_program', item);
  const isAutoApproved = item.auto_approved;
  const programName = item.fee_schedule_program.name;
  const requestText = `Request #${item.short_id}`;
  const requestLink = <Link to={`/authorizations/${item.id}`}>{requestText}</Link>;
  const createdDate = formatShortDate(item.created_at);
  const updatedDate = formatShortDate(item.updated_at);
  const startDate = formatShortDate(item.service_starts_at);
  const endDate = formatShortDate(item.service_ends_at);
  const caseOpenedDate = item?.case_opened_date ? formatShortDate(item.case_opened_date) : '';
  const caseClosedDate = item?.case_closed_date ? formatShortDate(item.case_closed_date) : '';
  const requestId = !isCurrentItem && enabledLink ? requestLink : requestText;

  return (
    <Card className={cx(cardClassName, isCurrentItem && 'bg-light-border-grey')}>
      {isCurrentItem && (
        <div className="italic pt-4 px-4">
          This is the request you are currently viewing.
        </div>
      )}
      { programName && !isCurrentItem && (
        <div className="font-bold pt-4 px-4">
          Referral for {programName}
        </div>
      )}
      <dl className="grid grid-cols-2 gap-y-1 gap-x-2 py-3 px-4 m-0">
        <LineItem
          fieldClassName={fieldClassName}
          field={requestId}
          dataTestElement="request-short-id"
          valueClassName={valueClassName}
        >
          {isAuthorized ?
            `${isAutoApproved ? 'Auto ' : ''}Accepted on ${updatedDate}` :
            `Open since ${createdDate}`}
        </LineItem>
        <LineItem
          fieldClassName={fieldClassName}
          field={isAuthorized ? 'Authorized Amount' : 'Amount Requested'}
          data-test-element="request-amount"
          valueClassName={valueClassName}
        >
          {formatSpend(item.spend, unit, isCostBased)}
        </LineItem>
        <LineItem
          fieldClassName={fieldClassName}
          field={isAuthorized ? 'Authorized Service Period' : 'Requested Service Dates'}
          dataTestElement="service-period"
          valueClassName={valueClassName}
        >
          {startDate} - {endDate}
        </LineItem>
        <LineItem
          fieldClassName={fieldClassName}
          field={isAuthorized ? 'Case Status' : 'Requester'}
          dataTestElement="case-status"
          valueClassName={valueClassName}
        >
          { isAuthorized ? (
            <CaseStatus
              state={item.case_state}
              openedDate={caseOpenedDate}
              closedDate={caseClosedDate}
            />
          ) : item.requester_name}
        </LineItem>
      </dl>
    </Card>
  );
};

AuthorizationSpendCard.propTypes = {
  item: PropTypes.shape({
    auto_approved: PropTypes.bool.isRequired,
    case_opened_date: PropTypes.string.isRequired,
    case_closed_date: PropTypes.string.isRequired,
    case_state: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    fee_schedule_program: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    requester_name: PropTypes.string.isRequired,
    service_starts_at: PropTypes.string.isRequired,
    service_ends_at: PropTypes.string.isRequired,
    short_id: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    spend: PropTypes.number.isRequired,
    updated_at: PropTypes.string.isRequired,
  }).isRequired,
  isCostBased: PropTypes.bool.isRequired,
  unit: PropTypes.string.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  isCurrentItem: PropTypes.bool,
  fieldClassName: PropTypes.string,
  cardClassName: PropTypes.string,
  valueClassName: PropTypes.string,
  enabledLink: PropTypes.bool,
};

AuthorizationSpendCard.defaultProps = {
  isCurrentItem: false,
  fieldClassName: 'text-brand-blue text-sm leading-5 ml-0',
  cardClassName: '',
  valueClassName: 'text-brand-blue text-sm leading-5 ml-0',
  enabledLink: false,
};

export default AuthorizationSpendCard;
