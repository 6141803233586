import { isUndefined, isEqual } from 'lodash';

const shouldSetNewCenter = (center, newCenter) => {
  const centerLatHasChanged = !isEqual(center.lat, newCenter.lat());
  const centerLngHasChanged = !isEqual(center.lng, newCenter.lng());

  return isUndefined(center) || centerLatHasChanged || centerLngHasChanged;
};

export default shouldSetNewCenter;
