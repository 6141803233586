import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { shapes } from '@unite-us/client-utils';
import { Spinner } from 'common/spinners';
import { DynamicTable } from 'common/tables';
import firstLine from 'common/utils/firstLine';
import CaseRowIcon from 'src/components/Dashboard/components/Cases/CaseRowIcon';
import _ from 'lodash';
import { showFacesheetCasesTableDescription } from 'common/utils/FeatureFlags/flags';
import {
  networkColumn,
  clientNameColumn,
  serviceTypeColumn,
} from '../../utils/columnConfigs/baseColumns';
import FilterBar from '../FilterBar';

function getColumns(isCC, isSuperNetwork, showDescription) {
  const descColumn = {
    label: 'Description',
    formatData: (data) => firstLine(data.description),
    className: 'truncate',
  };

  if (isCC) {
    return _.compact([
      networkColumn(isSuperNetwork, 'network.name'),
      clientNameColumn(),
      {
        label: 'Organization',
        sortColumn: 'provider.name',
      },
      showDescription ? descColumn : null,
      serviceTypeColumn(),
      {
        label: 'Status',
        sortColumn: 'status',
        formatData: (data) => (_.isEmpty(data.outcome) ? 'Open' : 'Closed'),
        type: 'status',
      },
      {
        label: 'Last Updated',
        sortColumn: 'updated_at',
        type: 'date',
      },
    ]);
  }
  return _.compact([
    clientNameColumn(),
    {
      label: 'Organization',
      sortColumn: 'provider.name',
    },
    showDescription ? descColumn : null,
    serviceTypeColumn(),
    {
      label: 'Status',
      sortColumn: 'status',
      formatData: (data) => (_.isEmpty(data.outcome) ? 'Open' : 'Closed'),
      type: 'status',
    },
    {
      label: 'Last Updated',
      sortColumn: 'updated_at',
      type: 'date',
    },
  ]);
}

export function AllExternalCasesIndex(props) {
  const {
    data,
    filters,
    initialSort,
    isCaseMgmtProvider,
    isCC,
    isFetching,
    isSuperNetwork,
    onCellClick,
    onFiltersChange,
    onNextClick,
    onPrevClick,
    paging,
    fetchGroupsUsers,
    showDescription,
  } = props;
  return (
    <div>
      <FilterBar
        filters={filters}
        onFiltersChange={onFiltersChange}
        paging={paging}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
        isFetching={isFetching && !_.isEmpty(data)}
        asyncSearch={fetchGroupsUsers}
      />
      <div className="dashboard-inner-content">
        {
          (isFetching && _.isEmpty(data)) ?
            <Spinner /> : (
              <DynamicTable
                id="all-network-cases-table"
                dataSet={data}
                onCellClick={onCellClick}
                initialSort={initialSort}
                columnLabels={getColumns(isCC, isSuperNetwork, showDescription)}
                rowIcon={(serviceCase) => (
                  <CaseRowIcon
                    isCaseMgmtProvider={isCaseMgmtProvider}
                    serviceCase={serviceCase}
                  />
                )}
                emptyMessage="There are no cases."
              />
            )
        }
      </div>
    </div>
  );
}

AllExternalCasesIndex.propTypes = {
  data: PropTypes.array.isRequired,
  filters: PropTypes.array,
  initialSort: PropTypes.object,
  isCaseMgmtProvider: PropTypes.bool,
  isCC: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isSuperNetwork: PropTypes.bool,
  onCellClick: PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  paging: shapes.paging,
  fetchGroupsUsers: PropTypes.func.isRequired,
  showDescription: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    showDescription: showFacesheetCasesTableDescription(state),
  };
}

export default connect(mapStateToProps)(AllExternalCasesIndex);
