import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { Icon } from '@unite-us/ui';

function LinkButton(props) {
  const {
    className,
    disabled,
    icon,
    id,
    labelText,
    onClick,
  } = props;

  return (
    <button
      type="button"
      aria-label={labelText}
      className={cx(
        className,
        disabled && 'pointer-events-none opacity-50',
        'flex justify-center items-center space-x-2 cursor-pointermt-2',
      )}
      id={id}
      onClick={onClick}
      tabIndex={0}
    >
      <p className="normal-case text-action-blue whitespace-no-wrap">{labelText}</p>
      <Icon icon={icon} className="fill-current text-action-blue" size={16} />
    </button>
  );
}

LinkButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

LinkButton.defaultProps = {
  className: '',
  disabled: false,
};

export default LinkButton;
