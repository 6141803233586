import {
  split,
  findLastIndex,
  nth,
} from 'lodash';

export default function parsePutUrl(url) {
  const uuidRegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const splitUrl = split(url, '/');
  const lastUUIDIndex = findLastIndex(splitUrl, (segment) => uuidRegExp.test(segment));

  if (lastUUIDIndex === -1) {
    return {
      matched: false,
      collection: undefined,
      uuid: undefined,
      modifier: undefined,
    };
  }

  return {
    matched: true,
    collection: nth(splitUrl, lastUUIDIndex - 1),
    uuid: nth(splitUrl, lastUUIDIndex),
    modifier: nth(splitUrl, lastUUIDIndex + 1),
  };
}
