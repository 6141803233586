import { apiDefault } from 'src/api/config';
import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import objectToQueryString from 'src/api/utils/objectToQueryString';
import getCoreError from 'src/api/utils/getCoreError';

const onError = (dispatch, error) => {
  Notifier.handleErrors(getCoreError(error));
  return error;
};

export default function fetchAgreements(provider) {
  return async (dispatch) => {
    try {
      const object = {
        filter: {
          provider,
        },
      };
      const params = objectToQueryString(object);
      const response = await apiDefault.get(`/provider_agreements?${params}`);
      if (!response || !isHttpSuccess(response.status)) {
        return onError(dispatch, 'Failed to fetch provider agreements');
      }
      return {
        provider,
        agreements: response.data.data,
      };
    } catch (error) {
      return onError(dispatch, error);
    }
  };
}
