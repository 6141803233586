import { hasUserRole } from '.';

export const isReferralsAdmin = (user, groupId) => (
  hasUserRole({
    groupId,
    name: 'Referrals Admin',
    roleType: 'primary',
    user,
  })
);

export default isReferralsAdmin;
