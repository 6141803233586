import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Card } from 'src/common/Card';
import { Icon } from '@unite-us/ui';

const LogoCard = ({ children, className }) => (
  <Card
    className={cx(
      'bg-white flex flex-col justify-center items-center text-center',
      'pt-40 pb-48 space-y-4',
      className,
    )}
  >
    <Icon className="fill-current text-brand-blue" icon="LogoShield" size={48} />
    {children}
  </Card>
);

LogoCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

LogoCard.defaultProps = {
  className: '',
};

export default LogoCard;
