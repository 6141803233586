import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'src/common/Card';
import NoRequestsFoundLogo from 'src/common/TailwindComponents/NoRequestsFoundLogo';
import NoEnrollmentsExist from './NoEnrollmentsExist';
import NoFilteredEnrollmentsFound from './NoFilteredEnrollmentsFound';

const NoEnrollmentsContent = ({ isFiltered, resetFilters, status }) => {
  if (isFiltered) return <NoFilteredEnrollmentsFound resetFilters={resetFilters} />;
  if (!status) return <NoEnrollmentsExist />;
  return (
    <>
      <NoRequestsFoundLogo />
      <h4 className="font-heavy-font text-base text-dark-grey">Nothing to see here!</h4>
      <p className="max-w-xs text-dark-grey">You don&apos;t have any {status} enrollment requests to review.</p>
    </>
  );
};

const NoEnrollmentsFoundCard = (props) => (
  <Card className="bg-white flex flex-col justify-center items-center text-center pt-40 pb-48 space-y-4">
    <NoEnrollmentsContent {...props} />
  </Card>
);

NoEnrollmentsContent.propTypes = {
  isFiltered: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

NoEnrollmentsFoundCard.propTypes = {
  isFiltered: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

export default NoEnrollmentsFoundCard;
