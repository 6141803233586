import {
  EMAIL_COMMUNICATION_TYPE_INFO_TEXT_MAP,
  PHONE_COMMUNICATION_TYPE_INFO_TEXT_MAP,
} from '../constants';

function getCommunicationTypeInfo({
  communicationType = '',
  email = false,
  phone = false,
}) {
  if (!communicationType) {
    return '';
  }
  if (email) {
    return EMAIL_COMMUNICATION_TYPE_INFO_TEXT_MAP[
      communicationType.toUpperCase()
    ];
  }
  if (phone) {
    return PHONE_COMMUNICATION_TYPE_INFO_TEXT_MAP[
      communicationType.toUpperCase()
    ];
  }
  return '';
}

export default getCommunicationTypeInfo;
