import Notifier from 'common/helpers/Notifier';
import {
  ASSISTANCE_REQUESTS_START_FETCHING,
  ASSISTANCE_REQUESTS_END_FETCHING,
  FETCH_ASSISTANCE_REQUESTS_CONFIG,
} from 'actions';
import { coreApi } from 'src/api/config';


function onSuccess(response, dispatch) {
  dispatch({ type: FETCH_ASSISTANCE_REQUESTS_CONFIG, payload: response });
  return response;
}

const fetchGroupAssistanceRequestsConfig = (groupId) => (
  async (dispatch) => {
    try {
      dispatch({ type: ASSISTANCE_REQUESTS_START_FETCHING });
      const payload = await coreApi.query('assistance_request_config', {
        provider: groupId,
      });
      const config = payload.data.data[0];
      await coreApi.populateRelationship('provider', 'provider', config);
      payload.data.data = {
        ...config,
        group: config.provider,
      };
      dispatch({ type: ASSISTANCE_REQUESTS_END_FETCHING });
      return onSuccess(payload, dispatch);
    } catch (error) {
      dispatch({ type: ASSISTANCE_REQUESTS_END_FETCHING });
      Notifier.handleErrors(error);
      return error;
    }
  }
);

export default fetchGroupAssistanceRequestsConfig;
