import PropTypes from 'prop-types';
import React from 'react';

const ViewVerbalConsentScriptLink = ({ style }) => (
  <div className="consent-document">
    <a
      id="view-verbal-consent-link"
      role="link"
      style={style}
      href="/consent/verbal-consent-script"
      target="_blank"
    >
      View Verbal Consent Script
    </a>
  </div>
);

ViewVerbalConsentScriptLink.propTypes = {
  style: PropTypes.object.isRequired,
};

ViewVerbalConsentScriptLink.defaultProps = {
  style: {
    color: 'dark-grey',
    textDecoration: 'underline',
    fontSize: '14px',
  },
};

export default ViewVerbalConsentScriptLink;
