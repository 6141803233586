import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { SelectField } from '@unite-us/ui';
import { validations } from '@unite-us/app-components';
import { didFieldChange } from 'common/utils/Form';

const targetTypeFromTargetIds = (targetIds) => {
  if (_.isEmpty(targetIds)) {
    return undefined;
  }
  switch (_.get(targetIds, '[0]._meta._type')) {
    case 'group':
    case 'facades_networks_participatinggroup':
      return 'provider';
    case 'network':
    case 'networks_supernetwork':
    default:
      return 'network';
  }
};

class ExportTargetSelect extends Component {
  static setTargetInformationForNonCC(props) {
    if (props.isCC) {
      return;
    }

    props.fields.target_ids.onChange([{
      id: props.groupId,
      _meta: { _type: 'group' },
    }]);
  }

  componentDidMount() {
    // If the exporter is neither a cc nor a super network,
    // the exportTarget should be automatically selected as the current group,
    // and the targetType should be automatically set to 'provider'.
    if (!this.props.isCC) {
      ExportTargetSelect.setTargetInformationForNonCC(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const targetIds = _.get(nextProps, 'fields.target_ids.value') || [];
    const targetType = _.get(nextProps, 'fields.target_type.value');

    // If the dialog is closed and re-opened, we need to repeat this process
    // for the default targetIds and targetType.
    if (!nextProps.isCC) {
      const shouldResetTargets = _.isEmpty(targetIds) && _.isEmpty(targetType);

      if (shouldResetTargets) {
        ExportTargetSelect.setTargetInformationForNonCC(nextProps);
        return;
      }
    }

    const targetIdsChanged = didFieldChange(this.props, nextProps, 'target_ids');
    // Set the target_type based on the first target selected, or null.
    if (targetIdsChanged && targetIds.length < 2) {
      const suggestedTargetType = targetTypeFromTargetIds(targetIds);
      if (suggestedTargetType !== targetType) {
        nextProps.fields.target_type.onChange(suggestedTargetType);
      }
    }
  }

  render() {
    const {
      exportTargets,
      fields,
      isCC,
      registerField,
    } = this.props;

    if (!isCC) {
      return null;
    }

    return (
      <SelectField
        className="export-create-fields__select"
        ref={registerField}
        id="export-create-input__type"
        field={fields.target_ids}
        label="Export Source(s)"
        options={exportTargets}
        labelKey="name"
        valueKey="id"
        validations={validations.isRequired}
        inline={false}
        backspaceRemoves
        clearable
        forceObjectValue
        multiple
      />
    );
  }
}

ExportTargetSelect.propTypes = {
  exportTargets: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  isCC: PropTypes.bool.isRequired,
  registerField: PropTypes.func.isRequired,
};

export default ExportTargetSelect;
