import { get, reduce, uuOmit } from 'lodash';

const addContactIdToScreening = (screenings) => (
  reduce(screenings, (acc, curr) => [
    ...acc,
    {
      ...uuOmit(curr, 'contact'),
      contactId: get(curr, 'contact.id', null),
    },
  ], [])
);

export default addContactIdToScreening;
