import _ from 'lodash';
import { apiDefault } from 'src/api/config';
import Notifier from 'common/helpers/Notifier';
import {
  FETCH_GROUPS_PROGRAMS,
  GROUPS_PROGRAM_SET_FETCHING,
  GROUPS_PROGRAM_UNSET_FETCHING,
  BUILD_SERVICE_TYPES,
} from 'actions';
import { includePathwaysServices } from 'common/utils/FeatureFlags/flags';

// TODO JSONAPI: This was migrated but it's using raw JSONAPI responses
/**
 * Returns query string that can be passed to axios request
 * @param { string } contactId - passed as a param when we want to filter programs by what the client is eligible for.
 */

export default function fetchGroupsPrograms(groupId, options = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const includePathways = includePathwaysServices(state);

    const params = {
      'filter[provider]': groupId,
      'filter[active]': options.active,
      'filter[id]': options.ids,
      'filter[fee_schedule_program.billable]': options.excludeBillable ? 'false' : null,
      'filter[fee_schedule_program.authorization_required]': options.excludeAuthorizationRequired ? 'false' : null,
      'filter[include_pathways]': includePathways,
      'context[person]': options.contactId,
      ...(options.serviceId ? { 'filter[services]': options.serviceId } : {}),
      ...(options.query ? { 'filter[query]': options.query } : {}),
      ...(options.referablePerson ? { 'filter[referable][person]': options.referablePerson } : {}),
      ...(options.referableEmployee ? { 'filter[referable][employee]': options.referableEmployee } : {}),
      'page[size]': includePathways ? '500' : 200,
      include: options.include,
    };

    const request = apiDefault.get('/programs', {
      params: _.uuPickBy(params, _.identity),
    });

    dispatch({ type: GROUPS_PROGRAM_SET_FETCHING });

    const onSuccess = (payload) => {
      dispatch({ type: GROUPS_PROGRAM_UNSET_FETCHING });
      dispatch({
        type: FETCH_GROUPS_PROGRAMS,
        payload,
        options,
        globalState: state,
      });
      dispatch({ type: BUILD_SERVICE_TYPES, globalState: state });
      return payload;
    };

    const onError = (error) => {
      Notifier.handleErrors(error);
      return error;
    };

    return request.then(onSuccess, onError);
  };
}
