import React from 'react';
import PropTypes from 'prop-types';
import Address from 'common/display/Address';
import { findPrimaryOrFirst } from 'common/utils/utils';
import PhoneNumberDisplay from 'common/display/PhoneNumberDisplay';
import { findWorkAddressOrFirstAddress } from 'src/components/Groups/utils';
import '../stylesheets/oonGroupDetails.scss';

const OONGroupDetails = ({ oonGroup = {} }) => {
  const workOrFirstAddress = findWorkAddressOrFirstAddress(oonGroup.addresses);
  const oonGroupPhoneNumbers = oonGroup.phone_numbers || [];
  const primaryOrFirstPhoneNumber = findPrimaryOrFirst(oonGroupPhoneNumbers);
  const primaryContactName = oonGroup.primary_contact_name;

  return (
    <div className="oon-group-details">
      <h2>{oonGroup.name}</h2>
      {
        workOrFirstAddress && (
          <div className="oon-group-details__address">
            <h5 className="mt-one mb-half">Address</h5>
            <Address item={workOrFirstAddress} inlineCounty shortDisplay />
          </div>
        )
      }
      {
        primaryOrFirstPhoneNumber && (
          <div className="oon-group-details__phone-number">
            <h5 className="mt-one mb-half">Telephone</h5>
            <PhoneNumberDisplay item={primaryOrFirstPhoneNumber} noLink shortDisplay />
          </div>
        )
      }
      {
        primaryContactName && (
          <div className="oon-group-details__primary-contact">
            <h5 className="mt-one mb-half">Primary Contact</h5>
            <p>{primaryContactName}</p>
          </div>
        )
      }
    </div>
  );
};

OONGroupDetails.propTypes = {
  oonGroup: PropTypes.shape({
    addresses: PropTypes.array,
    phone_numbers: PropTypes.array,
    name: PropTypes.string.isRequired,
    primary_contact_name: PropTypes.string,
  }).isRequired,
};

export default OONGroupDetails;
