import React, { useRef } from 'react';
import { Icon } from '@unite-us/ui';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import WorkqueueBulkAction from '../../../common/utils/Workqueue/WorkqueueBulkAction';
import { useBulkUpdate } from '../../../common/hooks/useBulkUpdate';

export const BulkAcceptEnrollments = ({
  deselectAll,
  deselectRows,
  selectedRows,
}) => {
  const ewqBulkAcceptFormRef = useRef();

  const closeBulkAcceptForm = () => {
    ewqBulkAcceptFormRef.current.closeDialog();
  };

  const openBulkAcceptForm = () => {
    ewqBulkAcceptFormRef.current.openDialog();
  };

  const onBulkAccept = useBulkUpdate(
    'enrollment_request',
    selectedRows.map((row) => row.id),
    { enrollment_status: 'accepted' },
    'Successfully Accepted Enrollment Request(s)',
  );

  const submitBulkAccceptedEnrollments = async () => {
    await onBulkAccept();
    closeBulkAcceptForm();
    deselectAll();
  };

  return (
    <WorkqueueBulkAction
      action={'Accept'}
      open={openBulkAcceptForm}
      close={closeBulkAcceptForm}
      onSubmit={submitBulkAccceptedEnrollments}
      bulkActionIcon="IconCheckCircle"
      dialogTitle="Accept Enrollment Requests"
      confirmLabel="Accept"
      dialogRef={ewqBulkAcceptFormRef}
      disableActionButton={selectedRows.length === 0}
      selectedRows={selectedRows}
      deselectAll={deselectAll}
    >
      <>
        <p className="mx-3 my-4">
          You are about to mark these enrollment requests as <b>accepted</b>. Would you like to continue?
        </p>
        {
          selectedRows.map((enrollment) => (
            <div
              className="flex items-center pl-4 mt-3 space-x-4"
              key={`bulk-action-enrollment-request-row-${enrollment.short_id}`}
              aria-label={`bulk-accept-preview-row-${enrollment.short_id}`}
            >
              <div className="flex-shrink-0 w-24">
                <Link
                  to={`/enrollments/${enrollment.id}`}
                  className="underline"
                >
                  {enrollment.short_id}
                </Link>
              </div>
              <p className="flex-grow truncate">
                {enrollment.plan.name}
              </p>
              <div className="flex-shrink-0 w-48">
                <p>
                  {`${enrollment.person.first_name} ${enrollment.person.last_name}`}
                </p>
              </div>
              <div className="flex-shrink-0 w-24">
                <p>{enrollment.request_type === 'enroll' ? 'Enroll' : 'Disenroll'}</p>
              </div>
              <div className="flex-shrink-0 w-8">
                <Icon
                  icon="IconCross"
                  size={10}
                  onClick={() => deselectRows([enrollment])}
                  ariaLabel={`deselect-enrollment-request-${enrollment.short_id}`}
                />
              </div>
            </div>
          ))
        }

      </>
    </WorkqueueBulkAction>
  );
};

BulkAcceptEnrollments.propTypes = {
  deselectAll: PropTypes.func.isRequired,
  deselectRows: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
};
