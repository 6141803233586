import {
  FETCH_PAYERS_STARTED,
  FETCH_PAYERS_SUCCESS,
  FETCH_PAYERS_ERROR,
  CREATE_PAYER_STARTED,
  CREATE_PAYER_SUCCESS,
  CREATE_PAYER_ERROR,
  UPDATE_PAYER_STARTED,
  UPDATE_PAYER_SUCCESS,
  UPDATE_PAYER_ERROR,
} from 'actions';
import {
  STATUS_INITIAL,
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_ERROR,
} from 'common/Status/constants';

export const defaultState = {
  payers: [],
  status: STATUS_INITIAL,
};

export default function payersReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case FETCH_PAYERS_STARTED: {
      return {
        ...state,
        status: STATUS_PENDING,
      };
    }

    case FETCH_PAYERS_ERROR: {
      return {
        ...state,
        status: STATUS_ERROR,
      };
    }

    case FETCH_PAYERS_SUCCESS: {
      return {
        ...state,
        status: STATUS_SUCCESS,
        payers: action.payload.data.data,
      };
    }

    case UPDATE_PAYER_STARTED:
      return { ...state, status: STATUS_PENDING };
    case UPDATE_PAYER_ERROR:
      return { ...state, status: STATUS_ERROR };
    case UPDATE_PAYER_SUCCESS: {
      return {
        ...state,
        payers: [...state.payers, { [action.payload.id]: action.payload }],
        status: STATUS_SUCCESS,
      };
    }
    case CREATE_PAYER_STARTED:
      return { ...state, status: STATUS_PENDING };
    case CREATE_PAYER_ERROR:
      return { ...state, status: STATUS_ERROR };
    case CREATE_PAYER_SUCCESS: {
      return {
        ...state,
        payers: [...state.payers, { [action.payload.id]: action.payload }],
        status: STATUS_SUCCESS,
      };
    }

    default:
      return state;
  }
}
