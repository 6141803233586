import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ProviderBadge from './ProviderBadge';
import './SelectedProviders.scss';

const SelectedProviders = ({ className, providers }) => (
  <div className={classnames('selected-providers', className)}>
    <h5 className="selected-providers__label">
      Organizations selected:
    </h5>

    <div className="selected-providers__providers">
      {
        providers.length > 0 ?
          providers.map((provider = {}) => (
            <ProviderBadge
              key={provider.id}
              providerName={provider.name}
            />
          )) :
          <span className="selected-providers__none">None</span>
      }
    </div>
  </div>
);

SelectedProviders.propTypes = {
  className: PropTypes.string,
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),
};

SelectedProviders.defaultProps = {
  className: '',
  providers: [],
};

export default SelectedProviders;
