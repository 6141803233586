import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BackButton } from 'common/buttons';
import { browserHistory } from 'common/utils/browserHistory';
import { Icon } from '@unite-us/ui';
import Button from '@unite-us/ui/dist/Display/Button';
import { isMessagingEnabledFlag } from 'common/utils/FeatureFlags/flags';

const DetailView = ({
  innerContentView,
  onBackClick,
  isMessagingEnabled,
  hideMessageCenterButton,
  subjectId,
  subjectType,
}) => {
  const onNavigateToMessaging = () => {
    browserHistory.push(`/message-center?subject=${subjectId}&subjectType=${subjectType}`);
  };
  return (
    <div className="detail-view">
      <div className="dashboard-filter-bar dashboard-filter-bar--detail-page">
        <BackButton navigateTo={onBackClick} />
        {
          isMessagingEnabled && !hideMessageCenterButton ? (
            <Button
              id="sendMessage"
              iconLeft={<Icon icon="IconEnvelope" className="fill-current text-white" />}
              primary
              label="message care team"
              onClick={onNavigateToMessaging}
            />
          ) : null
        }
      </div>

      <div className="dashboard-inner-content dashboard-inner-content--detail-page">
        <div className="row">
          <div className="col-md-12 dashboard-inner-content__left-column">
            {innerContentView}
          </div>
        </div>
      </div>
    </div>
  );
};

DetailView.propTypes = {
  innerContentView: PropTypes.node.isRequired,
  isMessagingEnabled: PropTypes.bool.isRequired,
  hideMessageCenterButton: PropTypes.bool,
  onBackClick: PropTypes.func,
  subjectId: PropTypes.string,
  subjectType: PropTypes.string,
};

DetailView.defaultProps = {
  onBackClick: null,
  hideMessageCenterButton: false,
};

function mapStateToProps(state, ownProps) {
  const { params, subjectType } = ownProps.innerContentView.props;
  return {
    subjectId: params?.id,
    subjectType,
    isMessagingEnabled: isMessagingEnabledFlag(state),
  };
}

export default connect(mapStateToProps)(DetailView);
