import { get, find, reduce, some } from 'lodash';
import buildNetwork from 'src/components/Network/buildNetwork';
import buildEmployee from './buildEmployee';

export function getEmployee({ state }) {
  const currentEmployee = state.user;
  return buildEmployee({ employee: currentEmployee, state });
}

export function getEmployeeNetworks({ state }) {
  const groupId = get(state, 'session.groupId', null);
  const currentUserGroup = find(state.user.groups, (group) => group.group.id === groupId);
  const groupNetworks = get(currentUserGroup, 'group.networks', []);
  const networksArray = get(state, 'user.networks', []);

  const groupUserNetworks = reduce(networksArray, (acc, current) => (
    some(groupNetworks, { id: current.id }) ?
      [...acc, current] :
      acc
  ), []);

  return groupUserNetworks.map((network) => buildNetwork({ network }));
}
