import React, { useCallback, useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { TrackerContext } from '@unite-us/client-utils';
import { INVOICE } from 'common/utils/EventTracker/utils/eventConstants';
import { SelectField } from 'src/components/Backoffice/form/SelectField';
import { TextField, Button, Dialog } from '@unite-us/ui';
import { get } from 'lodash';
import { theme } from 'src/../tailwind.config';
import callOrLog from 'src/common/utils/callOrLog';
import NoteDisclaimer from 'common/Disclaimer/NoteDisclaimer';
import { Spinner } from 'common/spinners';
import { useFind } from 'src/api/APIHooks';
import { useDisputeResolveByNetworkLead } from '../hooks/useUpdateInvoice';

const DisputeResolutionForm = ({
  close, invoice, networkId, setRef, onSuccess,
}) => {
  const [values, setValues] = useState({
    invoice_dispute_resolution_reason: '',
    dispute_resolution_reason_note: '',
  });
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const resolveDispute = useDisputeResolveByNetworkLead(invoice.id, values);

  const { isLoading, data } = useFind(
    'invoice_dispute_resolution_reasons',
    { fee_schedule: invoice.fee_schedule_id },
    { queryConfig: { placeholderData: undefined } },
  );

  const invoice_dispute_resolution_reasons = get(data, 'data.data', []);
  const disputeResolutionReasons = invoice_dispute_resolution_reasons
    .map((reason) => ({ label: reason.display_name, value: reason.id }));

  const handleDisputeResolutionReason = useCallback((event) => {
    setValues({ ...values, invoice_dispute_resolution_reason: event.value });
    setValid(true);
    setSubmitted(false);
  }, [values, setValues, setValid, setSubmitted]);

  const handleDisputeResolutionNote = useCallback((event) => {
    setValues({ ...values, dispute_resolution_reason_note: event.target.value });
    setSubmitted(false);
  }, [values, setValues, setSubmitted]);

  const cancelDisputeResolution = useCallback(() => {
    setValues({
      invoice_dispute_resolution_reason: '',
      dispute_resolution_reason_note: '',
    });
    setValid(false);
    setSubmitted(false);
    close();
  }, [setValues, setValid, setSubmitted, close]);

  const eventTracker = useContext(TrackerContext);

  const submitDispute = async () => {
    setSubmitted(true);
    if (valid) {
      await resolveDispute();
      onSuccess();
      callOrLog(() => eventTracker(
        INVOICE.nlClickedDisputeResolution,
        { current_network: networkId },
      ));
      close();
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <Dialog
      ref={setRef}
      modal
      title="Resolve Invoice"
      titleStyles={{ color: theme.extend.colors['text-blue'] }}
      id="dispute-resolution-invoice-modal"
      onRequestClose={cancelDisputeResolution}
    >
      <div className="flex flex-col text-left">
        <div className="text-sm pb-4 space-y-6">
          <p>
            Select a resolution to resolve this invoice. Provide clear instruction on next steps in the note section.
          </p>
        </div>
        <SelectField
          className="md:col-span-8 xl:col-span-4 px-0"
          value={values.invoice_dispute_resolution_reason}
          label="RESOLUTION"
          onChange={handleDisputeResolutionReason}
          options={disputeResolutionReasons}
          placeholder="Select..."
          required
        />
        {
          submitted && !valid && (
            <span id="dispute-resolution-reason-error" className="text-red mt-2">
              * Dispute resolution reason code is required.
            </span>
          )
        }
        <label
          className="ui-form-field__label text-text-blue font-medium-font text-13px pt-4 font-extrabold"
          htmlFor="dispute_resolution note"
        >
          NOTE
        </label>
        <TextField
          id="dispute_resolution_note"
          afterLabelContent={<NoteDisclaimer />}
          placeholder="Additional details and next steps..."
          value={values.dispute_resolution_reason_note}
          onChange={handleDisputeResolutionNote}
        />
      </div>
      <div className="flex justify-end space-x-4">
        <Button
          className="capitalize"
          onClick={cancelDisputeResolution}
          label="cancel"
        />
        <Button
          className="text-white capitalize"
          disabled={submitted}
          onClick={submitDispute}
          label="Resolve Invoice"
          primary
        />
      </div>
    </Dialog>
  );
};

DisputeResolutionForm.propTypes = {
  close: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
  networkId: PropTypes.string.isRequired,
  setRef: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
};

DisputeResolutionForm.defaultProps = {
  onSuccess: () => {},
};

DisputeResolutionForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const networkId = state.networks.networkId;
  return {
    networkId,
  };
}

export { DisputeResolutionForm };
export default connect(mapStateToProps)(DisputeResolutionForm);
