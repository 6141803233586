import { networkIdExists } from 'src/components/Referrals/utils/form';
import {
  didFieldChange,
  didFieldChangeById,
} from 'common/utils/Form';

const shouldRefetchServiceTypes = (props, nextProps) => {
  const conditions = networkIdExists(nextProps.fields) && (
    didFieldChangeById(props, nextProps, 'referred_by_network') ||
    didFieldChange(props, nextProps, 'refer_to_outside_network') ||
    didFieldChangeById(props, nextProps, 'referred_to_network')
  );

  return conditions;
};

export default shouldRefetchServiceTypes;
