import { hasUserRole } from '.';

export function isNetworkUser(user, groupId) {
  return hasUserRole({
    groupId,
    name: 'Network User',
    roleType: 'primary',
    user,
  });
}

export default isNetworkUser;
