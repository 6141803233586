import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { noop } from 'lodash';
import { fetchContactDocuments } from 'actions/Document/Contact/Group';
import DocumentsListView from './components/DocumentsListView';
import UploadAndAttachDocuments from './components/UploadAndAttachDocuments';
import DocumentUploaderFormOnly from './components/DocumentUploaderFormOnly';
import './stylesheets/documentUploader.scss';

export class DocumentUploader extends PureComponent {
  componentDidMount() {
    const {
      contactId, formOnly, groupId, canViewContact,
    } = this.props;
    if (!formOnly && canViewContact) {
      this.props.fetchContactDocuments({ groupId, contactId });
    }
  }

  render() {
    const {
      attachedDocuments,
      contactDocuments,
      dropzoneName,
      editable,
      fields,
      formIndex,
      formOnly,
      registerField,
    } = this.props;

    if (formOnly) {
      return (
        <div className="document-uploader">
          <DocumentUploaderFormOnly
            dropzoneName={dropzoneName}
            fields={fields}
            formIndex={formIndex}
            registerField={registerField}
          />
        </div>
      );
    }

    return (
      <div className="document-uploader">
        <DocumentsListView
          documents={attachedDocuments}
          editable={editable}
          hideTitle
          onRemoveDocument={this.props.onRemoveDocument}
          onRenameDocument={this.props.onRenameDocument}
        />
        {
          editable && (
            <UploadAndAttachDocuments
              attachedDocuments={attachedDocuments}
              contactDocuments={contactDocuments}
              dropzoneName={dropzoneName}
              onAttachDocuments={this.props.onAttachDocuments}
            />
          )
        }
      </div>
    );
  }
}

DocumentUploader.propTypes = {
  attachedDocuments: PropTypes.array,
  canViewContact: PropTypes.bool,
  contactDocuments: PropTypes.array,
  contactId: PropTypes.string,
  dropzoneName: PropTypes.string,
  editable: PropTypes.bool,
  fetchContactDocuments: PropTypes.func.isRequired,
  fields: PropTypes.object,
  formIndex: PropTypes.number,
  formOnly: PropTypes.bool,
  groupId: PropTypes.string.isRequired,
  onAttachDocuments: PropTypes.func,
  onRemoveDocument: PropTypes.func,
  onRenameDocument: PropTypes.func,
  registerField: PropTypes.func,
};

DocumentUploader.defaultProps = {
  canViewContact: false,
  attachedDocuments: [],
  contactDocuments: [],
  contactId: '',
  dropzoneName: 'Uploads',
  editable: false,
  fields: {},
  formIndex: undefined,
  formOnly: false,
  onAttachDocuments: noop,
  onRemoveDocument: noop,
  onRenameDocument: noop,
  registerField: noop,
};

function mapStateToProps(state, ownProps) {
  const { contactDocuments, session: { groupId } } = state;
  const { contactId } = ownProps;
  return {
    contactDocuments: (contactDocuments[contactId] || {}).data || [],
    groupId,
  };
}

export default connect(mapStateToProps, {
  fetchContactDocuments,
})(DocumentUploader);
