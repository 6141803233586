import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { CheckBoxField, InputField } from '@unite-us/ui';
import { validations } from '@unite-us/app-components';
import { CommunicationTypesCheckBoxes } from 'common/form';
import { generateUUID } from 'common/utils/utils';
import { RemoveEmailButton, RemoveEmailConfirmation } from './components';

import './stylesheets/inlineEmail.scss';

class ProfileEmailFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: generateUUID(),
      showRemove: false,
    };

    this.onCancelRemove = this.onCancelRemove.bind(this);
    this.onConfirmRemove = this.onConfirmRemove.bind(this);
    this.onPrimaryChange = this.onPrimaryChange.bind(this);
    this.showRemoveConfirmation = this.showRemoveConfirmation.bind(this);
  }

  onCancelRemove() {
    this.setState({ showRemove: false });
  }

  onConfirmRemove() {
    this.props.onRemove(this.props.fields.id.value);
  }

  onPrimaryChange() {
    this.props.onPrimaryChange(this.props.fields);
  }

  showRemoveConfirmation() {
    this.setState({ showRemove: true });
  }

  render() {
    const {
      fields,
      hideCommunicationPreferences,
      registerField,
      styles,
    } = this.props;

    const {
      showRemove,
      uuid,
    } = this.state;

    return (
      <div className="inline-email-address">
        <div className="row">
          <div className="col-md-7 inline-email-address__email-address">
            <InputField
              autoFocus
              field={fields.email_address}
              id={uuid}
              inline={false}
              label="Email"
              ref={registerField}
              validations={[
                { func: validations.isRequired },
                { func: validations.isEmail },
                {
                  func: (value, message, args, formValues) => {
                    const { emails } = formValues;
                    const emailAddresses = _.map(emails, (e) => e.email_address);
                    const hasDuplicateEmail = emailAddresses.filter((e) => e === value).length > 1;
                    return hasDuplicateEmail ? message : null;
                  },
                  message: 'Email already exists on this profile',
                },
              ]}
            />
          </div>
          <div className="col-md-4 inline-email-address__is-primary">
            <CheckBoxField
              className="check-box-field"
              field={fields.is_primary}
              id={`chk-${uuid}`}
              label="Primary"
              onChange={this.onPrimaryChange}
            />
          </div>
          <div className="col-md-1">
            <div style={showRemove ? styles.fadeRemove : styles.showRemove}>
              {
                /* @todo we may want to add an explicit prop to show/hide this button */
                this.props.onRemove &&
                  <RemoveEmailButton onClick={this.showRemoveConfirmation} />
              }
            </div>
          </div>
        </div>
        {
          !hideCommunicationPreferences && (
            <div className="row">
              <div className="col-xs-10">
                <CommunicationTypesCheckBoxes
                  email
                  fields={fields.acceptable_communication_types}
                  hideOralTypes
                  id={uuid}
                />
              </div>
            </div>
          )
        }
        <RemoveEmailConfirmation
          show={showRemove}
          onCancel={this.onCancelRemove}
          onConfirm={this.onConfirmRemove}
        />
      </div>
    );
  }
}

ProfileEmailFields.propTypes = {
  fields: PropTypes.shape({
    acceptable_communication_types: PropTypes.shape({
      message: PropTypes.object.isRequired,
      notification: PropTypes.object.isRequired,
    }).isRequired,
    email_address: PropTypes.object.isRequired,
    id: PropTypes.object.isRequired,
    is_primary: PropTypes.object.isRequired,
    message: PropTypes.object,
    notifications: PropTypes.object,
  }).isRequired,
  hideCommunicationPreferences: PropTypes.bool,
  onPrimaryChange: PropTypes.func,
  onRemove: PropTypes.func,
  registerField: PropTypes.func,
  styles: PropTypes.object.isRequired,
};

ProfileEmailFields.defaultProps = {
  hideCommunicationPreferences: false,
  onRemove: _.noop,
  registerField: _.noop,
  styles: {
    fadeRemove: { opacity: '.3' },
    showRemove: { visibility: 'visible' },
    hideRemove: { visibility: 'hidden', display: 'none' },
    checkbox: { marginTop: '26px' },
  },
};

export default ProfileEmailFields;
