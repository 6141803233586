import _ from 'lodash';
import { dates } from '@unite-us/app-components';

export function outcomeColumnOne(item) {
  return [
    {
      label: 'Outcome',
      value: !item.outcome ?
        _.get(item, 'case.outcome.description', '') : _.get(item, 'outcome.description', ''),
    },
  ];
}

export function outcomeColumnTwo(item, closingTime) {
  const outcomeResolution = !item.outcome ?
    _.get(item, 'case.outcome.resolved', false) : _.get(item, 'outcome.resolved', false);
  return [
    {
      label: 'Resolution',
      value: outcomeResolution ? 'Resolved' : 'Unresolved',
    },
    {
      label: 'Updated At',
      value: closingTime && dates.formatLocalDateTime(closingTime),
    },
  ];
}
