import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { browserHistory } from 'common/utils/browserHistory';
import Notifier from 'common/helpers/Notifier';
import {
  useUpdateDirectory,
  useFindDirectory,
} from 'src/components/Backoffice/api/hooks/prdApi/DirectoryHooks';
import { useInvalidateQueries } from 'src/api/APIHooks';
import PrdForm from '../components/PrdForm';

const EditPrd = ({ params }) => {
  const invalidateQueries = useInvalidateQueries();

  const { updateRecord: updatePrd } = useUpdateDirectory({
    onError: (error) => {
      const errorList = get(error, 'response.data.errors', []);
      const stringNotification = errorList.map((e) => e.title).join(', ');
      Notifier.dispatch(400, `Failed to edit directory: ${stringNotification}`);
    },
    onSuccess: () => {
      invalidateQueries('directories');
      Notifier.dispatch(200, 'PRD successfully updated');
    },
  });
  const { id: prdId } = params;
  const { data: prd, isFetching } = useFindDirectory(prdId);
  const initialData = { ...prd };
  const onSubmit = async (prdPayload) => {
    const response = await updatePrd(prdId, prdPayload);
    if (response) {
      browserHistory.push({ pathname: `/backoffice/prds/${prdId}` });
    }
  };

  return (
    <PrdForm
      prdId={prdId}
      onSubmit={onSubmit}
      showPending={isFetching}
      initialValues={initialData}
    />
  );
};

EditPrd.propTypes = {
  params: PropTypes.object.isRequired,
};

EditPrd.defaultProps = {
};

export default EditPrd;
