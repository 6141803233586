/* eslint-disable max-len */
import { get } from 'lodash';

export default function buildEmployee({ employee, state }) {
  /**
   * CORE-WORK-Todo
   * id key - Add custom key from employee.id
   * full_name key - Add custom key from `${employee.first_name} ${employee.last_name}`
   * addresses key - Replace latitude and longitude keys under each addresses key as lat_lng (array) lat_lng[0] = latitude, lat_long[1] = longitude
   * phone_numbers key - Combine employee.phone_number and employee.extension together. `${employee.phone_number}${employee.extension}`
   * two_factor_auth_confirmed key - Does not exist in CORE but back-end team is investigating
  */
  if (employee.attributes) {
    return {};
  }

  const {
    email,
    id,
    first_name,
    last_name,
    full_name,
    work_title,
    timezone,
    addresses,
    phone_numbers,
    two_factor_authentication,
  } = employee;

  return {
    email,
    id,
    first_name,
    last_name,
    full_name,
    work_title,
    two_factor_auth_confirmed: get(two_factor_authentication, 'confirmed', false),
    timezone,
    addresses: addresses.map(({ latitude, longitude, ...addressDetails }) => ({ // normalizing the V3 lat_lng address format to CORE's explicit latitiude, longitude keys.
      ...addressDetails,
      latitude,
      longitude,
      // is_primary key does not exist in V3 but address is not updatable for a user so we can ignore this
    })),
    phone_numbers,
    providerId: state.session.groupId,
    userId: null, // Null. userId is defined in the id key
    roleIds: [], // Empty. Role Ids not needed in V3
    subscriptionIds: [], // Empty. subscriptionIds not needed in V3.
  };
}
