import { searchAPIClient as axios } from 'src/api/config';
import { merge, reduce, mapKeys, snakeCase } from 'lodash';
import { SEARCH_API } from 'src/config/env/env.config';

export const createParams = (params = {}, page = {}) => {
  // Determine if querying for service areas
  const serviceAreaState = params['programs.service_areas']?.region?.state ?? null;
  const serviceAreaNational = serviceAreaState ? true : null;
  const referable = params.referable ?? {};

  if (serviceAreaState) {
    // eslint-disable-next-line no-param-reassign
    delete params['programs.service_areas'];
  }

  const filters = {
    licensed: params.licensed,
    query: params.query,
    networks: params.networks,
    // only query for active providers unless explicitly stated otherwise
    active: !(params.state && params.state === 'false'),
    type: params.type,
  };

  if (typeof params.sensitive === 'boolean') {
    filters.sensitive = params.sensitive;
  }

  const locations = {
    latitude: params.locations?.radius.latitude || null,
    longitude: params.locations?.radius.longitude || null,
    distance: params.locations?.radius.distance || null,
  };

  const pageParsed = {
    number: page.page.number || null,
    size: page.page.size || null,
  };

  // matches for 'programs.' and at least one more char to pull filters relating to programs
  const programsParams = Object.keys(params)
    .filter((paramKey) => paramKey.match(/programs\..+/))
    .reduce((obj, key) => {
      const value = params[key];
      if (!value) {
        return obj;
      }
      const splitKey = key.split('programs.')[1];
      // eslint-disable-next-line no-param-reassign
      obj[splitKey] = params[key];
      return obj;
    }, {});

  const programs = {
    ...programsParams,
  };

  const include = 'locations';
  const sort = page.sort === 'name' ? 'provider-name-a-to-z' : 'distance';
  return merge(
    reduce(filters, (acc, f, key) => ({ ...acc, ...{ [`filter[${key}]`]: f } }), {}),
    reduce(programs, (acc, f, key) => ({ ...acc, ...{ [`filter[programs.${key}]`]: f } }), {}),
    reduce(locations, (acc, f, key) => ({ ...acc, ...{ [`filter[locations][radius][${key}]`]: f } }), {}),
    reduce(pageParsed, (acc, f, key) => ({ ...acc, ...{ [`page[${key}]`]: f } }), {}),
    reduce(referable, (acc, f, key) => ({ ...acc, ...{ [`filter[referable][${key}]`]: f } }), {}),
    serviceAreaState && { 'filter[programs.service_areas][region][state]': serviceAreaState },
    serviceAreaNational && { 'filter[programs.service_areas][region][national]': serviceAreaNational },
    { 'filter[include_pathways]': params.include_pathways ?? false },
    { 'filter[include_payments]': params.include_payments ?? false },
    { include },
    !serviceAreaState && { sort },
  );
};

export const responseTransformer = (data) => data.map((provider) => {
  const document = provider.document;
  const networks = provider.relationships.networks.data || [];
  const programs = provider.relationships.programs.data || [];
  const locations = provider.relationships.locations.data || [];

  const dataMap = {
    id: provider.id,
    licensed: document.licensed,
    name: document.name,
    description: document.description,
    websiteUrl: document.website_url,
    emailAddresses: document.email_addresses,
    phoneNumbers: document.phone_numbers,
    hours: document.hours,
    state: document.state,
    primaryContact_name: document.primary_contact_name,
    providerType: provider.document_type,
    networks,
    service_area_served: document.service_area_served ?? '',
    programs: programs.map((p) => ({
      ...p.document,
      cities: p.restricted_cities || [],
      counties: p.restricted_counties || [],
      states: p.restricted_states || [],
      id: p.id,
      type: p.document_type,
      provider: p.provider,
      provided_service_type_ids: p.provided_service_type_ids,
      fee_schedule_program: p.relationships.fee_schedule_program.data,
      services: p.relationships.services.data || [],
    })),
    addresses: locations.map((loc) => ({
      ...loc.document,
      email_addresses: loc.email_addresses || [],
      address_type: loc.location_type,
      latitude: loc.document.coordinates ? loc.document.coordinates.lat : null,
      longitude: loc.document.coordinates ? loc.document.coordinates.lon : null,
    })),
    // The following properties seem to be inconsequentially not indexed by Search API
    // In case of funky behavior, these default values may be a good place to begin investigating
    status: null,
    sensitive: document.sensitive,
    logoUrl: null,
    focus: null,
    nationalProviderIdentifier: null,
    taxIdentificationNumber: null,
    managed: false,
    provider_type: '',
    payer: null,
  };

  const snakeCasedDataMap = mapKeys(dataMap, (value, key) => snakeCase(key));
  snakeCasedDataMap.focus = snakeCasedDataMap.focus ??
    mapKeys(snakeCasedDataMap.focus, (value, key) => snakeCase(key));
  snakeCasedDataMap.addresses = snakeCasedDataMap.addresses ?
    snakeCasedDataMap.addresses.map((a) => mapKeys(a, (value, key) => snakeCase(key))) : [];

  return snakeCasedDataMap;
});

const fetchProviderBrowseResultsFromSearch = async (params, page, searchProgramsParams = {}) => {
  const providersUrl = `${SEARCH_API}/providers`;
  const searchProgramsUrl = `${SEARCH_API}/programs`;

  const parsedParams = createParams(params, page);

  const response = await axios.get(providersUrl, {
    params: parsedParams,
    transformResponse: (res) => res, // prevent Axios running JSON.parse() on data
  });

  const resData = JSON.parse(response.data);

  // Populate programs objects with data
  const programsList = resData.data.map(
    (provider) => provider.relationships.programs.data.map((program) => program.id),
  ).flat();

  const promises = [];
  while (programsList.length > 0) { // Request up to 100 programs' data per call
    promises.push(
      axios.get(searchProgramsUrl, {
        params: {
          'filter[id]': programsList.slice(0, 100).join(),
          'filter[include_pathways]': params.include_pathways ?? false,
          'filter[include_payments]': params.include_payments ?? false,
          ...searchProgramsParams,
        },
      }),
    );
    programsList.splice(0, 100);
  }
  const allResponses = await Promise.all(promises);

  const allPrograms = allResponses.flatMap((resp) => resp.data.data ?? []);
  resData.data = resData.data.map((provider) => ({
    ...provider,
    relationships: {
      ...provider.relationships,
      programs: {
        data: provider.relationships.programs.data.map((program) => {
          const matchingProgram = allPrograms.find((fullProg) => fullProg.id === program.id);
          return {
            id: matchingProgram.id,
            document_type: matchingProgram.document_type,
            document: matchingProgram.document,
            provider: matchingProgram.relationships.provider.data,
            provided_service_type_ids: matchingProgram.relationships.services.data ?? [],
            relationships: matchingProgram.relationships,
          };
        }),
      },
    },
  }));

  const currPage = page.page.number || 1;

  const paging = {
    current_page: currPage,
    next_page: resData.meta.page.total_pages > currPage ? currPage + 1 : null,
    per: page.page.size,
    prev_page: currPage > 1 ? currPage - 1 : null,
    total_count: resData.meta.page.total_count,
    total_pages: resData.meta.page.total_pages,
  };

  const transformedData = responseTransformer(resData.data);
  return {
    ...response,
    data: {
      paging,
      data: transformedData,
      meta: resData.meta,
    },
  };
};

export default fetchProviderBrowseResultsFromSearch;
