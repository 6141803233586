function capitalize(str) {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}

const getCoreError = (coreError, generic = 'Oops, something went wrong.') => {
  try {
    const errors = coreError.response.data.errors;
    const messages = errors.map((error) => {
      const arr = error.source.pointer.split('/');
      let keyProperty = arr[arr.length - 1];
      keyProperty = keyProperty.replace('_', ' ');
      keyProperty = capitalize(keyProperty);
      return `${keyProperty} is invalid.`;
    });

    return {
      response: {
        data: {
          errors: messages,
        },
        status: coreError.response.status,
      },
    };
  } catch (error) {
    // return generic error
    return {
      response: {
        data: {
          errors: [!coreError.response ? coreError.message : generic],
        },
        status: 500,
      },
    };
  }
};

export default getCoreError;
