import React from 'react';
import PropTypes from 'prop-types';
import SelectAllRowsButton from '../../../common/Workqueue/SelectAllRowsButton';
import { BulkAcceptEnrollments } from './BulkAcceptEnrollments';

export const EnrollmentBulkActions = ({
 bulkActions, deselectAll, deselectRows, selectedRows,
}) => (
  <>
    <SelectAllRowsButton
      selectedRows={selectedRows}
      deselectAll={deselectAll}
    />
    {
        bulkActions.map((action) => {
          if (action === 'Accept') {
            return (
              <BulkAcceptEnrollments
                deselectAll={deselectAll}
                deselectRows={deselectRows}
                selectedRows={selectedRows}
              />
            );
          }
          return <></>;
        })
      }
  </>
  );

EnrollmentBulkActions.propTypes = {
  bulkActions: PropTypes.array.isRequired,
  deselectAll: PropTypes.func.isRequired,
  deselectRows: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
};
