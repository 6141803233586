import React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton } from 'src/common/TailwindComponents';

const NoFilteredServiceAuthorizationsFound = ({ resetFilters }) => (
  <>
    <h4 className="font-heavy-font text-base text-dark-grey">No results found.</h4>
    <p className="max-w-xs text-dark-grey">
      Try adjusting your search or filters to find what you&apos;re looking for.
    </p>
    <PrimaryButton label="Remove all filters" onClick={resetFilters} />
  </>
);

NoFilteredServiceAuthorizationsFound.propTypes = {
  resetFilters: PropTypes.func.isRequired,
};

export default NoFilteredServiceAuthorizationsFound;
