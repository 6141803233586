import React, { useState, useEffect, useRef } from 'react';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// hover modifications inspired by github issue:
// https://github.com/tailwindlabs/headlessui/issues/239

const ToolTip = ({
  buttonClassName,
  buttonComponentContent,
  buttonElementAs,
  buttonTextContent,
  enableHover,
  flipOptions,
  hoverDelay,
  offsetOptions,
  panelClassName,
  panelComponentContent,
  panelTextContent,
  placement,
  'data-testid': dataTestId,
}) => {
  const domRef = useRef(null);
  const [popperElement, setPopperElement] = useState();
  let timeout;
  // offset modifier necessary for hover functionality in order to
  // prevent mouseLeave immediatly fireing because panel overlaps with button
  const { styles, attributes } = usePopper(domRef?.current, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
          ...offsetOptions,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['left-start', 'top', 'right'],
          ...flipOptions,
        },
      },
    ],
  });

  const onHover = (open, action) => {
    // clear the old timeout, if any
    clearTimeout(timeout);
    if (
      (!open && action === 'onMouseEnter') ||
        (open && action === 'onMouseLeave')
    ) {
      // open the modal after a timeout
      timeout = setTimeout(() => {
        domRef?.current?.click(); // eslint-disable-line
      }, hoverDelay);
    }
  };

  const handleClick = (event) => {
    if (enableHover) {
      if (!domRef?.current?.contains(event.target)) {
        event.stopPropagation();
      }
      clearTimeout(timeout); // stop the hover timer if it's running
    }
  };

  useEffect(() => {
    if (enableHover) {
      document.addEventListener('mousedown', handleClick);
    }
    return () => {
      if (enableHover) {
        document.removeEventListener('mousedown', handleClick);
      }
    };
  });

  const PanelComponentContent = panelComponentContent;
  const ButtonComponentContent = buttonComponentContent;

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            as={buttonElementAs}
            ref={domRef}
            onMouseEnter={enableHover ? () => onHover(open, 'onMouseEnter') : undefined}
            onMouseLeave={enableHover ? () => onHover(open, 'onMouseLeave') : undefined}
            className={classNames(buttonClassName)}
            data-testid={dataTestId}
          >
            {buttonTextContent && buttonTextContent}
            {ButtonComponentContent && <ButtonComponentContent />}
          </Popover.Button>
          {open && (
            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className={classNames('z-10', panelClassName)}
              static
            >
              {panelTextContent && panelTextContent}
              {PanelComponentContent && <PanelComponentContent />}
            </Popover.Panel>
          )}
        </>
      )}
    </Popover>
  );
};

ToolTip.propTypes = {
  buttonClassName: PropTypes.string,
  buttonComponentContent: PropTypes.func,
  buttonElementAs: PropTypes.string,
  buttonTextContent: PropTypes.string,
  enableHover: PropTypes.bool,
  flipOptions: PropTypes.object,
  hoverDelay: PropTypes.number,
  offsetOptions: PropTypes.object,
  panelClassName: PropTypes.string,
  panelComponentContent: PropTypes.func,
  panelTextContent: PropTypes.string,
  placement: PropTypes.string,
  'data-testid': PropTypes.string,
};

ToolTip.defaultProps = {
  buttonClassName: '',
  buttonComponentContent: undefined,
  buttonElementAs: 'button',
  buttonTextContent: undefined,
  enableHover: false,
  flipOptions: {},
  hoverDelay: 400,
  offsetOptions: {},
  panelClassName: '',
  panelComponentContent: undefined,
  panelTextContent: undefined,
  placement: 'right-start',
  'data-testid': undefined,
};

export default ToolTip;
