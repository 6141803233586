import { get, isEmpty } from 'lodash';

const getEmployeeCenter = (employee) => {
  const address = get(employee, 'addresses[0]', {});
  if (!isEmpty(address.lat_lng)) {
    return {
      lat: address.lat_lng[0],
      lng: address.lat_lng[1],
    };
  }

  return !address.latitude || !address.longitude ? undefined : {
    lat: address.latitude,
    lng: address.longitude,
  };
};

export default getEmployeeCenter;
