import _ from 'lodash';
import buildOptions from "./buildOptions";
import isMultiLevel from "./isMultiLevel";
function countOptions(options) {
  var availableOptions = [];
  if (isMultiLevel(options)) {
    availableOptions = buildOptions({
      options: options,
      selected: []
    });
    return _.filter(_.flattenDeep(availableOptions), function (o) {
      return !o.hasChildren;
    }).length;
  }
  return options.length;
}
export default countOptions;