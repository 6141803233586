import _ from 'lodash';

function getProgramsOptions(activePrograms = [], state) {
  const programsOptions = _.filter(
    activePrograms,
    (prog) => _.get(prog, 'attributes.name', '') !== 'Referred Out of Network',
  );
  return _.map(programsOptions, (program) => (
    {
      label: _.get(program, 'attributes.name', ''),
      value: _.get(program, 'id', ''),
      initial: _.includes(state.filters.programs_filter, program.id),
    }
  ));
}

export default getProgramsOptions;
