import { find } from 'lodash';
import { allChildrenInValue, anyChildInValue, getParent, hasChildren, isSelected } from "../utils";
export function isAutoAdded() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    opt = _ref.opt,
    value = _ref.value,
    allOptions = _ref.allOptions,
    valueKey = _ref.valueKey,
    autoSelectParent = _ref.autoSelectParent,
    autoSelectChildren = _ref.autoSelectChildren;
  var selected = isSelected(opt, value, valueKey);
  if (!selected) {
    return false;
  }
  if (autoSelectParent && !autoSelectChildren) {
    if (hasChildren(opt)) {
      return anyChildInValue(opt, value, valueKey);
    }
    return false;
  }
  if (autoSelectParent && autoSelectChildren) {
    if (hasChildren(opt)) {
      var serviceType = find(allOptions, function (o) {
        return o[valueKey] === opt[valueKey];
      });
      return anyChildInValue(serviceType, value, valueKey) && !allChildrenInValue(serviceType, value, valueKey);
    }
    var parent = getParent(opt, allOptions, valueKey);
    return allChildrenInValue(parent, value, valueKey);
  }
  return false;
}
export default isAutoAdded;