import _ from 'lodash';
import { makeCustomOption } from '.';

function createOptions({
  options,
  labelKey,
  search,
  valueKey,
}, disableCustomValue = false) {
  const customOption = makeCustomOption({
    labelKey,
    options,
    search,
    valueKey,
  });

  const allOptions = disableCustomValue ? _.compact([...options]) : _.compact([customOption, ...options]);

  const filteredOptions = _.filter(
    allOptions,
    (o) => _.includes(_.toLower(o[labelKey]), _.toLower(search)),
  );

  return filteredOptions;
}

export default createOptions;
