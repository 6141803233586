import Notifier from 'common/helpers/Notifier';
import {
  ASSISTANCE_REQUESTS_START_FETCHING,
  ASSISTANCE_REQUESTS_END_FETCHING,
  FETCH_ASSISTANCE_REQUEST,
} from 'actions';
import { searchContact } from 'actions/Search/Group';
import { coreApi } from 'src/api/config';
import { dateToEpoch } from 'common/utils/utils';
import { cl1018PreferredLanguagesFields } from 'common/utils/FeatureFlags/flags';
import { populateAssistanceRequestCase } from './utils/populateAssistanceRequestCase';

const onError = (error) => Notifier.handleErrors(error);

// requestModel is 'assistance_request' for Needs Action status,
// and 'case' for Processed/Closed status.
const fetchAssistanceRequest = async(requestId, requestModel) => {
  const isCase = requestModel === 'case';
  const params = isCase ? {} : { include: 'case' };
  const response = await coreApi.findRecord(requestModel, requestId, params);
  const data = response.data.data;

  if (isCase) {
    const { id } = data;
    return { id, case: { ...data } };
  }

  if (data.case) {
    const { id: assistanceRequestId, case: { id } } = data;
    return { ...data, assistanceRequestId, id };
  }

  return { assistanceRequestId: data.id, ...data };
};

const fetchAssistanceRequestDetails = (groupId, requestId, requestModel) => (
  async (dispatch, getState) => {
    try {
      dispatch({ type: ASSISTANCE_REQUESTS_START_FETCHING });
      const assistanceRequest = await fetchAssistanceRequest(requestId, requestModel);
      let kase = assistanceRequest.case;
      let clientMatches = [];

      if (!kase) {
        const contact = {
          first_name: assistanceRequest.person_first_name,
          last_name: assistanceRequest.person_last_name,
          date_of_birth: dateToEpoch(assistanceRequest.person_date_of_birth),
        };
        const searchAndMatchResponse = await dispatch(searchContact(
          groupId,
          contact,
          true,
        ));
        const results = searchAndMatchResponse.data.data;

        if (results.length) {
          clientMatches = results.map((client) => client.item.result);
          kase = {};
        } else {
          const createCaseResponse = await coreApi.setRelationship(
            'assistance_request',
            requestId,
            { relationshipModel: 'case', value: null },
          );

          kase = createCaseResponse.data.data.case;
          assistanceRequest.id = kase.id;
        }
      }

      const state = getState();
      const preferredLanguagesEnabled = cl1018PreferredLanguagesFields(state);
      assistanceRequest.case = await populateAssistanceRequestCase(kase, assistanceRequest, preferredLanguagesEnabled);
      const findRequestBy = assistanceRequest.case.status === 'pending' ? 'assistanceRequestId' : 'id';
      const payload = { findRequestBy, data: { data: { clientMatches, ...assistanceRequest } } };

      dispatch({ type: FETCH_ASSISTANCE_REQUEST, payload });
      dispatch({ type: ASSISTANCE_REQUESTS_END_FETCHING });

      return payload;
    } catch (error) {
      return onError(error);
    }
  }
);

export default fetchAssistanceRequestDetails;
