import { get, isEmpty } from 'lodash';

function getLanguageFullNames(languages = [], languageEnums = []) {
  if (!isEmpty(languageEnums)) {
    return languages.reduce((acc, language) => {
      const languageValue = languageEnums.find((l) => (
        l.value === (language)
      ));

      if (languageValue) {
        acc.push(get(languageValue, 'display_name'));
      }
      return acc;
    }, []);
  }
  return [];
}

export default getLanguageFullNames;
