import _ from 'lodash';

function makeCustomOption({
  search, options = [], labelKey, valueKey,
}) {
  const exactMatch = _.some(options, (o) => (_.toLower(o[labelKey]) === _.toLower(search)));
  const emptySearch = !search;
  if (exactMatch || emptySearch) {
    return { };
  }
  return {
    isCustom: true,
    [labelKey]: search,
    [valueKey]: search,
  };
}

export default makeCustomOption;
