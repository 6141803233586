import { isEmpty } from 'lodash';

const addSelfToChildrenOptions = (serviceTypes = []) => (
  serviceTypes.map((sT) => {
    if (isEmpty(sT.children)) {
      return { ...sT, children: [sT] };
    }
    return sT;
  })
);

export default addSelfToChildrenOptions;
