import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { browserHistory } from 'src/common/utils/browserHistory';
import { getServiceId } from './serviceUtils';

const readFileAsync = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    resolve(event.target.result);
  };
  reader.onerror = (event) => {
    reject(event.target.error);
  };
  reader.readAsText(file);
});

const createConfigFromFile = async (file, services, formId, createConfig, formConfigIds, formNeedConfigurations) => {
  const fileContent = await readFileAsync(file);

  const parsedContent = JSON.parse(fileContent);
  const inputOptionIds = parsedContent.flatMap(
    (item) => item.input_option_scores.map((score) => score.input_option_id),
  );
  const promises = [];
  const data = parsedContent.map((answer) => {
    const serviceId = getServiceId(answer.service.name, services);
    return {
      ...answer,
      id: answer.id,
      service: { id: serviceId },
      form: { id: formId },
    };
  });

  let filteredData = data;

  if (formNeedConfigurations) {
    filteredData = data.filter((answer) => !formNeedConfigurations
      .some((config) => config.service.id === answer.service.id && config.input_option_scores
        .some((configScore) => answer.input_option_scores
          .some((answerScore) => answerScore.input_option_id === configScore.input_option_id))));
  }

  const validIds = inputOptionIds.every((id) => formConfigIds.includes(id));
  if (!validIds) {
    throw new Error('The form questions and responses must be exactly the same in order to upload screening logic.');
  }

  filteredData.forEach((answer) => {
    const promise = createConfig(answer);
    promises.push(promise);
  });

  return promises;
};

const handlePromiseResults = (promises, formId, invalidateQueries) => {
  Promise.allSettled(promises)
    .then((results) => {
      const isSuccessful = results.filter((result) => isHttpSuccess(result.value)).length === promises.length;
      browserHistory.push(`/backoffice/forms/${formId}/edit/editLogic`);
      invalidateQueries('form');
      if (isSuccessful) {
        Notifier.dispatch(200, 'The form is saved.');
      } else {
        Notifier.dispatch(400, 'Error saving form.');
      }
    });
};

const resetFileUpload = (setUploadedFiles, setRejectedFiles, dialogV2Ref) => {
  setUploadedFiles([]);
  setRejectedFiles({ filesTooBig: [], filesWrongType: [], filesTooBigAndWrongType: [] });
  dialogV2Ref.current.closeDialog();
};

export const defaultOnUploadLogicAnswers = async (
  uploadedFiles,
  services,
  formId,
  dialogV2Ref,
  createConfig,
  setUploadedFiles,
  setRejectedFiles,
  invalidateQueries,
  formConfig,
  formNeedConfigurations,
) => {
  try {
    const choiceIds = formConfig.pages[0].elements
      .filter((item) => item.choices)
      .flatMap((item) => item.choices.map((choice) => choice.id));

    const formConfigIds = [...choiceIds];

    if (uploadedFiles.length === 1) {
      const promises = await createConfigFromFile(
        uploadedFiles[0].file,
        services,
        formId,
        createConfig,
        formConfigIds,
        formNeedConfigurations,
      );
      handlePromiseResults(promises, formId, invalidateQueries);
    }
    resetFileUpload(setUploadedFiles, setRejectedFiles, dialogV2Ref);
  } catch (error) {
    resetFileUpload(setUploadedFiles, setRejectedFiles, dialogV2Ref);
    Notifier.dispatch(400, error.message);
  }
};

export default defaultOnUploadLogicAnswers;
