import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import findPrimaryOrFirst from 'src/components/Contacts/utils/findPrimaryOrFirst';
import removeSelectedContact from 'common/display/ContactStepper/actions/RemoveSelectedContact';
import { getEnumsFromState } from 'common/utils/Enums';
import findCurrentGroup from 'common/utils/findCurrentGroup';
import { getGroup } from 'common/utils/stateHelpers';
import { fetchGroup } from 'actions/Group';
import _ from 'lodash';
import { validateReduxForm } from 'common/form';
import { trackEvent } from '@unite-us/client-utils';
import { validations } from '@unite-us/app-components';
import {
  Button,
} from '@unite-us/ui';
import { Spinner } from 'common/spinners';
import callOrLog from 'src/common/utils/callOrLog';
import { NEW_CLIENT } from 'common/utils/EventTracker/utils/eventConstants';
import { destroyForm } from 'actions/Form';
import { formatNewContactDataWithNoInsurance } from 'common/display/ContactStepper/utils';
import { REFERRALS_CREATE_REFERRALS } from 'common/utils/FeatureFlag/utils/constants';
import { createUpdateDeletePaymentsInsurances } from 'src/components/Insurance/utils/';
import createInsuranceEntry from 'src/components/Insurance/actions/createInsuranceEntry';
import PaymentsInformation from 'src/components/Insurance/PaymentsInformation';
import '../stylesheets/addContactForm.scss';
import retrieveSearchedContacts from '../actions/RetrieveSelectedContact';
import SupplementalInformation from './SupplementalInformation';
import createContact from '../actions/CreateContact';
import BasicInformation from './BasicInformation';
import ContactInformation from './ContactInformation';
import Header from './Header';
import MilitaryInformation from './MilitaryInformation';
import { NOT_FOUND_HEADER_COPY, REGULAR_HEADER_COPY } from '../constants';

class AddContactInformationFromAssistanceRequest extends Component {
  static restartAssistanceRequestProcess() {
    browserHistory.goBack();
  }

  constructor(props) {
    super(props);

    this.onCreateContact = this.onCreateContact.bind(this);
    this.initiatePaymentsEventTracking = this.initiatePaymentsEventTracking.bind(this);
  }

  componentDidMount() {
    if (!this.props.completeGroup) {
      this.props.fetchGroup(this.props.groupId);
    }
  }

  onCreateContact(values) {
    const {
      assistanceRequestId,
      fields,
      groupId,
    } = this.props;

    return this.props.createContact({
      assistanceRequestId,
      contact: formatNewContactDataWithNoInsurance({ fields, values }),
      groupId,
      showNotification: false,
    }).then((contact) => {
      const { id: contactId } = _.get(contact, 'data.data', {});

      createUpdateDeletePaymentsInsurances({
        values,
        contactId,
        groupId,
        createInsuranceEntry: this.props.createInsuranceEntry,
        paymentsEventTracking: this.initiatePaymentsEventTracking,
        showNotification: false,
      });
      this.props.destroyForm(REFERRALS_CREATE_REFERRALS);

      this.setSelectedContact(contact);
    });
  }

  setSelectedContact(returnedContact) {
    const { assistanceRequestId } = this.props;
    const createdContact = _.get(returnedContact, 'data.data');
    const contactId = createdContact.id;

    this.props.retrieveSearchedContacts(contactId).then(() => {
      switch (this.props.from) {
        case 'nav':
          return browserHistory.push(`/contacts/${contactId}/cases/new/add-case-details`);

        case 'case-ar':
          callOrLog(() => trackEvent(NEW_CLIENT.createFromArServiceCase, {
            assistance_request_id: assistanceRequestId,
          }, {
            contact: createdContact,
          }));

          return browserHistory.push(
            `/assistance-requests/${assistanceRequestId}/contacts/${contactId}/cases/new/add-case-details`,
          );
        case 'intake-ar':
          return browserHistory.push(`/intakes/new?contactId=${contactId}&arId=${assistanceRequestId}`);
        default:
          callOrLog(() => trackEvent(NEW_CLIENT.createFromArReferral, {
            assistance_request_id: assistanceRequestId,
          }, {
            contact: createdContact,
          }));

          return browserHistory.push(`/assistance-requests/${assistanceRequestId}/referrals/new/add-service-types`);
      }
    });
  }

  initiatePaymentsEventTracking(insurancesArr = []) {
    const updatedTypes = insurancesArr.reduce((insurances, currentInsurance) => {
      if (_.isEmpty(currentInsurance.plan_type)) {
        return insurances;
      }
      return [...insurances, currentInsurance.plan_type.toLowerCase()];
    }, []);

    const eventTracker = this.context.eventTracker;
    if (!_.isEmpty(updatedTypes)) {
      callOrLog(() => eventTracker(
        NEW_CLIENT.changedInsurance,
        { insurance_types: updatedTypes },
      ));
    }
  }

  render() {
    const {
      completeGroup,
      currentGroup,
      enums,
      fields,
      handleSubmit,
      header,
      location,
      mainHeader,
      registerField,
      submitting,
      untouch,
      values,
    } = this.props;
    const { query: { not_found } } = location;
    const subHeader = not_found ? `${NOT_FOUND_HEADER_COPY} ${REGULAR_HEADER_COPY}` : REGULAR_HEADER_COPY;

    return (
      <form
        onSubmit={handleSubmit(this.onCreateContact)}
        className="add-contact-from-ar"
      >
        <div className="row">
          <div className="referral-header col-md-12">
            <Header
              header={header}
              mainHeader={mainHeader}
              subHeader={subHeader}
            />
          </div>
        </div>

        <BasicInformation
          registerField={registerField}
          fields={fields}
          validations={validations}
          enums={enums}
        />

        <ContactInformation
          registerField={registerField}
          fields={fields}
          enums={enums}
          validations={validations}
        />

        {
          completeGroup ? (
            <SupplementalInformation
              completeGroup={completeGroup}
              currentGroup={currentGroup}
              enums={enums}
              fields={fields}
              registerField={registerField}
              untouch={untouch}
              values={values}
            />
          ) : <Spinner />
        }

        <div className="row">
          <div className="col-md-12">
            <footer className="referral-footer">
              <Button
                id="go-back-btn"
                onClick={AddContactInformationFromAssistanceRequest.restartAssistanceRequestProcess}
                label="Go Back"
                style={{ marginRight: '10px' }}
              />
              <Button
                id="save-client-btn"
                onClick={handleSubmit(this.onCreateContact)}
                label="Save Client"
                primary
                disabled={submitting}
              />
            </footer>
          </div>
        </div>
      </form>
    );
  }
}

AddContactInformationFromAssistanceRequest.propTypes = {
  assistanceRequestId: PropTypes.string.isRequired,
  completeGroup: PropTypes.object,
  createContact: PropTypes.func.isRequired,
  createInsuranceEntry: PropTypes.func.isRequired,
  currentGroup: PropTypes.object.isRequired,
  destroyForm: PropTypes.func.isRequired,
  enums: PropTypes.object,
  fetchGroup: PropTypes.func.isRequired,
  fields: PropTypes.object,
  from: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  header: PropTypes.string.isRequired,
  location: PropTypes.object,
  mainHeader: PropTypes.string,
  registerField: PropTypes.func.isRequired,
  retrieveSearchedContacts: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  untouch: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

AddContactInformationFromAssistanceRequest.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const {
    assistanceRequests: { requests },
    contacts,
    searchedContacts,
    selectedContact,
    session,
    session: { groupId },
    user,
  } = state;

  const assistanceRequestId = ownProps.assistanceRequestId || _.get(ownProps, 'location.query.arId', null);
  const allContacts = [...contacts.contacts, ...searchedContacts];
  const contact = _.find(allContacts, { id: selectedContact });
  const assistanceRequest = _.find(requests, { id: assistanceRequestId });
  const requestor = _.get(assistanceRequest, 'requestor', {});

  const email = findPrimaryOrFirst(requestor.email_addresses);
  const phone = findPrimaryOrFirst(requestor.phone_numbers);

  const initialValues = {
    ...requestor,
    email_address: email.email_address,
    phone: phone.phone_number,
    phone_type: phone.phone_type,
    insurance: [{}],
  };

  return {
    assistanceRequestId,
    completeGroup: getGroup(state, groupId),
    contact,
    currentGroup: findCurrentGroup(user, session),
    enums: getEnumsFromState(state),
    groupId,
    initialValues,
    selectedContact,
  };
}

export default validateReduxForm(
  {
    form: 'contact',
    fields: [
      ...BasicInformation.fields,
      ...ContactInformation.fields,
      ...MilitaryInformation.fields,
      ...PaymentsInformation.fields,
    ],
    destroyOnUnmount: true,
  },
  mapStateToProps,
  {
    createContact,
    createInsuranceEntry,
    destroyForm,
    fetchGroup,
    removeSelectedContact,
    retrieveSearchedContacts,
  },
)(AddContactInformationFromAssistanceRequest);
