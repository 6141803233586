import Notifier from 'src/common/helpers/Notifier';
import {
  FETCH_PROVIDED_SERVICES_STARTED,
  FETCH_PROVIDED_SERVICES_SUCCESS,
  FETCH_PROVIDED_SERVICES_ERROR,
} from 'src/actions/index';
import { isHttpError } from 'common/utils/Error';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { apiV4 } from 'src/api/config';

function fetchProvidedServices({ caseId, groupId, contactId }) {
  return async (dispatch) => {
    dispatch({ type: FETCH_PROVIDED_SERVICES_STARTED });

    const payload = await apiV4.query('provided_service', { case: caseId }, { groupId });

    if (!payload || !isHttpSuccess(payload.status)) {
      dispatch({ type: FETCH_PROVIDED_SERVICES_ERROR });
      if (isHttpError(payload)) {
        Notifier.dispatch(
          'error',
          'Provided Services information cannot be displayed',
        );
      } else {
        Notifier.handleErrors(payload, dispatch);
      }
      return false;
    }

    dispatch({
      type: FETCH_PROVIDED_SERVICES_SUCCESS,
      contactId,
      caseId,
      payload,
    });

    return payload;
  };
}

export default fetchProvidedServices;
