import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Button,
} from '@unite-us/ui';
import { AUTH_URL } from 'src/config/env/env.config';
import ContactInformation from '../ContactInformation';
import PersonalInformation from '../PersonalInformation';
import './profile.scss';

export function Profile(props) {
  const { accessToken, employee = {} } = props;
  const { full_name } = employee;

  if (!full_name || !accessToken) {
    return <div>Loading</div>;
  }

  return (
    <BaseCard>
      <BaseCardHeader title={`${full_name}'s Profile`} />
      <BaseCardBody withPadding>
        <PersonalInformation employee={employee} />
        <ContactInformation employee={employee} />
        <h5 className="mb-one">Security Settings</h5>
        <p>Edit two-factor authentication and change password in Security Settings</p>
        <br />
        <form action={`${AUTH_URL}/oauth2/redeem`} method="POST">
          <input
            type="hidden"
            id="access_token"
            name="access_token"
            value={accessToken}
          />
          <input
            type="hidden"
            id="redirect_url"
            name="redirect_url"
            value="/users/security-settings"
          />
          <Button
            label="Edit Security Settings"
            primary
            type="submit"
            id="edit-security-settings-btn"
            data-test-element="edit-security-settings-btn"
          />
        </form>
      </BaseCardBody>
    </BaseCard>
  );
}

function mapStateToProps(state) {
  return { accessToken: state.session.access_token };
}

Profile.propTypes = {
  accessToken: PropTypes.string.isRequired,
  employee: PropTypes.shape({
    full_name: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Profile);
