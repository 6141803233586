import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ConfirmationDialog from 'common/form/ConfirmationDialog';
import { Menu, MenuItem, Icon } from '@unite-us/ui';
import { get } from 'lodash';
import { routeOnKeyDown } from 'src/components/Navigation/utils';
import { ENTER_KEY_CODE, SPACEBAR_KEY_CODE } from 'src/components/Navigation/constants';
import './ContactDocumentCardMenu.scss';

class ContactDocumentCardMenu extends Component {
  constructor(props) {
    super(props);
    this.confirmRemove = this.confirmRemove.bind(this);
    this.downloadKeyDownHandler = this.downloadKeyDownHandler.bind(this);
  }

  confirmRemove() {
    this.confirmDialog.confirmationDialog.openDialog()
      .then((action) => {
        if (action === 'confirm' && this.props.onRemoveDocument) {
          this.props.onRemoveDocument(this.props.doc);
        }
      });
  }

  downloadKeyDownHandler({ keyCode, target, srcElement }) {
    const { doc } = this.props;
    if ([ENTER_KEY_CODE, SPACEBAR_KEY_CODE].includes(keyCode)) {
      if (target || srcElement) {
        window.open(doc.url, doc.title);
      }
    }
  }

  render() {
    const {
      doc, onRemoveDocument, showOptions, onRenameClick,
    } = this.props;
    const title = get(doc, 'title', '');
    const url = get(doc, 'url', '');

    return (
      <div className="contact-document-card-menu">
        <p className="contact-document-card-menu__title">
          {title}
        </p>
        {
          showOptions && (
            <Menu
              id="document-menu"
              className="contact-document-card-menu__items"
              anchorElement={(
                <Icon
                  icon="IconEllipsisV"
                  color="#58606e"
                  size={20}
                />
              )}
              position="right-start"
              target="left-right"
            >
              <MenuItem
                id="document-menu-item-download"
                primaryNode={(
                  <a
                    className="contact-document-card-menu__link"
                    style={{ textDecoration: 'none' }}
                    role="link"
                    href={url}
                    download={title}
                  >
                    Download
                  </a>
                )}
                onKeyDown={this.downloadKeyDownHandler}
              />
              {
                onRemoveDocument && (
                  <MenuItem
                    id="document-menu-item-remove"
                    primaryNode="Remove"
                    onClick={this.confirmRemove}
                    onKeyDown={routeOnKeyDown(this.confirmRemove)}
                  />
                )
              }
              <MenuItem
                id="document-menu-item-rename"
                primaryNode="Rename"
                onClick={onRenameClick}
                onKeyDown={routeOnKeyDown(onRenameClick)}
              />
            </Menu>
          )
        }
        <ConfirmationDialog
          id="document-remove-confirmation-dialog"
          ref={(dialog) => { this.confirmDialog = dialog; }}
          confirmLabel="Remove"
          size="small"
        >
          <h2 className="mb-one">Are you sure you would like to remove this attachment?</h2>
          <p>
            Please note that this will not affect any records that are
            linked to the same attachment.
          </p>
        </ConfirmationDialog>
      </div>
    );
  }
}

ContactDocumentCardMenu.propTypes = {
  doc: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
  }).isRequired,
  onRemoveDocument: PropTypes.func,
  onRenameClick: PropTypes.func.isRequired,
  showOptions: PropTypes.bool,
};

ContactDocumentCardMenu.defaultProps = {
  showOptions: true,
};

export default ContactDocumentCardMenu;
