import React, { useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { pluralize } from 'humanize-plus';
import ExpandableButton from 'common/buttons/ExpandableButton';
import AuthorizationSpendCard from 'pages/service-authorizations/components/AuthorizationSpendCard';

const ReferralAuthSpendExpandable = ({
  title,
  items,
  totalSpend,
  unit,
  isAuthorized,
  isCostBased,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const requestCount = `${items.length} ${pluralize(items.length, 'request')}`;

  return (
    <div>
      <div className="flex justify-between my-2">
        <h4>{title}</h4>
        <span>{totalSpend}</span>
      </div>
      <div className="flex items-center">
        <span>{requestCount}</span>
        {items.length > 0 && (
          <ExpandableButton
            isOpen={isOpen}
            onClick={onClick}
            dataTestId={`expandable-button-${isAuthorized ? 'authorized' : 'not-yet-authorized'}`}
            className="ml-3"
          />
        )}
      </div>
      {isOpen && (
        <>
          {items.map((item) => (
            <AuthorizationSpendCard
              key={item.short_id}
              item={item}
              unit={unit}
              isAuthorized={isAuthorized}
              isCostBased={isCostBased}
              cardClassName="my-3 w-3/4"
            />
          ))}
        </>
      )}
    </div>
  );
};

ReferralAuthSpendExpandable.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      short_id: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      spend: PropTypes.number.isRequired,
      service_starts_at: PropTypes.string.isRequired,
      service_ends_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      requester_name: PropTypes.string.isRequired,
      case_state: PropTypes.string.isRequired,
      case_opened_date: PropTypes.string.isRequired,
    }),
  ).isRequired,
  totalSpend: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  isCostBased: PropTypes.bool.isRequired,
};

export default ReferralAuthSpendExpandable;
