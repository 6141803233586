import { removeDocumentsFromResource } from 'common/form/DocumentUploader/actions';
import { REMOVE_CONTACT_DOCUMENT_FROM_RESOURCES } from 'actions';
import Notifier from 'common/helpers/Notifier';

function removeDocumentFromContactResources({
  contactId,
  documentId,
}) {
  const request = removeDocumentsFromResource({
    documentId,
    resourceId: contactId,
    resourceType: 'person',
  });
  return (dispatch) => {
    function onSuccess(payload) {
      dispatch({
        type: REMOVE_CONTACT_DOCUMENT_FROM_RESOURCES,
        contactId,
        documentId,
        payload,
        resourceIds: documentId,
      });

      Notifier.dispatch(payload.status, 'Saved Document');

      return payload;
    }
    function onError(error) {
      Notifier.handleErrors(error);
      return error;
    }
    return request.then(onSuccess, onError);
  };
}

export default removeDocumentFromContactResources;
