import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { validateReduxForm } from 'common/form';
import {
  Button,
  SelectField,
} from '@unite-us/ui';
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';
import { fetchGroupsUsersFromProgram } from 'actions/User/Program/Group';
import { fetchServiceCase, updateServiceCase } from 'actions/Case/Contact/Group';
import * as _ from 'lodash';
import { setDashboardRefetch } from 'actions/Dashboard';
import callOrLog from 'src/common/utils/callOrLog';
import { SERVICE_CASE } from 'common/utils/EventTracker/utils/eventConstants';
import { primaryWorkerOptions } from 'src/components/Cases/utils/selectOptions';
import isCaseOON from 'src/components/Cases/utils/isCaseOON';

const fields = [
  'service_case.primary_worker_id',
];

export class ServiceCasePrimaryWorkerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
    };
    this.fetchGroupsUsers = this.fetchGroupsUsers.bind(this);
    this.debouncedFetchGroupsUsers = _.debounce(this.fetchGroupsUsers, 400);
    this.onSubmit = this.onSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { groupId, serviceCase } = this.props;
    const programId = _.get(serviceCase, 'program.id', null);
    const referOON = isCaseOON(serviceCase);
    if (referOON) {
      this.props.fetchProvidersUserCore({ providers: groupId, options: { licensed: false } });
    } else {
      this.props.fetchGroupsUsersFromProgram(groupId, programId);
    }
  }

  componentDidMount() {
    const {
      initializeForm,
      serviceCase,
    } = this.props;

    const primary_worker_id = _.get(serviceCase, 'primary_worker.id');
    initializeForm({
      service_case: {
        primary_worker_id,
      },
    });
  }

  onSubmit(formInputs) {
    const { groupId, contactId, serviceCase } = this.props;
    this.setState({ submitting: true });
    this.props.updateServiceCase(groupId, contactId, serviceCase.id, formInputs)
      .then((response) => {
        this.props.fetchServiceCase(groupId, contactId, serviceCase.id);
        const primaryWorker = _.get(response, 'data.data.primary_case_worker', {});

        this.props.setDashboardRefetch();
        this.setState({ submitting: false });
        callOrLog(() => this.context.eventTracker(
          SERVICE_CASE.primaryWorkerAssigned,
          { primary_worker_id: primaryWorker.id },
          { serviceCase },
        ));
        this.props.closeModal();
      });
  }

  fetchGroupsUsers(search) {
    const { groupId, serviceCase } = this.props;
    const programId = _.get(serviceCase, 'program.id', null);
    const referOON = isCaseOON(serviceCase);
    if (referOON) {
      this.props.fetchProvidersUserCore({ providers: groupId, options: { text: search, page: 1, licensed: false } });
    } else {
      this.props.fetchGroupsUsersFromProgram(groupId, programId, { text: search, per: 50, page: 1 });
    }
  }

  render() {
    const { fields: { service_case }, handleSubmit } = this.props;
    const { submitting } = this.state;
    const options = primaryWorkerOptions(this.props);

    return (
      <form className="service-case-detail-form content-with-actions" onSubmit={handleSubmit(this.onSubmit)}>
        <div className="content-container content-container--medium">
          <SelectField
            className="primary-worker-select"
            id="primary-worker"
            label="Primary Worker"
            loadOptions={this.debouncedFetchGroupsUsers}
            field={service_case.primary_worker_id}
            options={options}
            searchPlaceholderValue="Search - Displaying 50 max"
            clearable={false}
          />
        </div>
        <div className="actions">
          <span className="action-item">
            <Button
              id="edit-primary-worker-cancel-btn"
              label="Cancel"
              onClick={this.props.closeModal}
              disabled={submitting}
            />
          </span>
          <span className="action-item">
            <Button
              id="edit-primary-worker-submit-btn"
              onClick={handleSubmit(this.onSubmit)}
              primary
              disabled={submitting}
              label="Submit"
            />
          </span>
        </div>
      </form>
    );
  }
}

ServiceCasePrimaryWorkerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  closeModal: PropTypes.func,
  serviceCase: PropTypes.shape({
    id: PropTypes.string.isRequired,
    program: PropTypes.object.isRequired,
    network: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  fetchServiceCase: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  initializeForm: PropTypes.func.isRequired,
  updateServiceCase: PropTypes.func.isRequired,
  contactId: PropTypes.string.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  fetchGroupsUsersFromProgram: PropTypes.func.isRequired,
  setDashboardRefetch: PropTypes.func.isRequired,
};

ServiceCasePrimaryWorkerForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    groupId: state.session.groupId,
    groupsUsers: state.groupsUsers,
    initialValues: {
      service_case: {
        primary_worker_id: _.get(ownProps, 'serviceCase.primary_worker.id'),
      },
    },
  };
}

export default validateReduxForm(
  {
    form: 'editServiceCasePrimaryWorkerForm',
    fields,
  },
  mapStateToProps,
  {
    updateServiceCase,
    fetchProvidersUserCore,
    fetchGroupsUsersFromProgram,
    fetchServiceCase,
    setDashboardRefetch,
  },
)(ServiceCasePrimaryWorkerForm);
