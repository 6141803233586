import { coreApi } from 'src/api/config';
import { unionBy } from 'lodash';
import {
  FETCH_CORE_GROUP_FORMS,
  CLEAN_RETURNED_ASSESSMENTS,
  SET_GROUP_FORMS_FETCHING,
  UNSET_GROUP_FORMS_FETCHING,
} from 'actions';

export default function fetchCoreGroupForms(groupId, serviceIds, networkId) {
  return async(dispatch) => {
    try {
      const payloads = await Promise.all(
        serviceIds.map((serviceId) => coreApi.query('form', {
          'form_usages.form_utilizer.state': 'active',
          'form_usages.usage_type': 'referral_assessment',
          'form_usages.relevant_services': serviceId,
          'form_usages.provider': groupId,
          ...(networkId ? { 'form_usages.network': networkId } : {}),
        })),
      );

      const payload = {
        data: {
          data: unionBy(...payloads.map((pay) => [...pay.data.data]), 'id'),
        },
      };

      const formIds = payload.data.data.map((form) => form.id);

      const formUsagesResponse = await coreApi.query('form_usage', {
        provider: groupId,
        usage_type: 'referral_assessment',
        'form_utilizer.state': 'active',
        form: formIds.join(','),
      });

      formUsagesResponse.data.data.forEach((usage) => {
        payload.data.data.forEach((form) => {
          if (usage.form.id === form.id) {
            // eslint-disable-next-line no-param-reassign
            form.service_type_ids = usage.relevant_services.map((s) => s.id);
            // eslint-disable-next-line no-param-reassign
            form.usage_id = usage.id;
          }
        });
      });

      dispatch({ type: SET_GROUP_FORMS_FETCHING });

      dispatch({ type: FETCH_CORE_GROUP_FORMS, groupId, payload });
      dispatch({ type: CLEAN_RETURNED_ASSESSMENTS, groupId });
      dispatch({ type: UNSET_GROUP_FORMS_FETCHING });

      return payload;
    } catch (error) {
      dispatch({ type: UNSET_GROUP_FORMS_FETCHING });
      return error;
    }
  };
}
