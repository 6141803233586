import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'common/spinners';
import useForms from 'src/common/hooks/useForms';
import { DEFAULT_PAGE_SIZE } from 'src/components/Backoffice/backofficeUtils';
import DynamicSearchTable from './DynamicSearchTable';

const FormSearchTable = ({ selectedFormId, setSelectedFormId }) => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const [formName, setFormName] = useState('');

  const { data: formsResponse, isFetching } = useForms(
    formName,
    pageSize,
    pageNumber,
  );
  const { data: forms, paging = { total_count: 0, total_pages: 0 } } = formsResponse.data;

  useEffect(() => {
    setPageNumber(1);
  }, [formName]);

  const generateDataForTable = (formList) => {
    const data = [];
    formList.forEach((form) => {
      data.push({
        id: form.id,
        data: [
          form.name,
          new Date(form.updated_at).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }),
          form.id,
        ],
      });
    });
    return data;
  };

  if (isFetching) return <Spinner />;

  return (
    <>
      <DynamicSearchTable
        searchResults={generateDataForTable(forms)}
        rowHeaders={['Name', 'Last Updated', 'Form ID']}
        selectedRowIds={selectedFormId}
        setSelectedRowIds={setSelectedFormId}
        setSearch={setFormName}
        emptyTableMessage={'No Results Found.'}
        searchBarPlaceHolder={'Enter Form Name...'}
        searchTitle={'Form Name'}
        dataTestId={'form-search'}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        paging={paging}
      />
    </>
  );
};

FormSearchTable.propTypes = {
  selectedFormId: PropTypes.string.isRequired,
  setSelectedFormId: PropTypes.func.isRequired,
};

export default FormSearchTable;
