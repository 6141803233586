import { REOPEN_SERVICE_CASE } from 'actions';
import Notifier from 'common/helpers/Notifier';
import { coreApi } from 'src/api/config';

export default function reopenCase(serviceCase) {
  return async (dispatch) => {
    try {
      const payload = await coreApi.updateRecord('case', serviceCase.id, {
        outcome: null,
      });
      dispatch({
        type: REOPEN_SERVICE_CASE,
        payload,
      });
      Notifier.dispatch(payload.status, 'Case Successfully Reopened');

      return payload;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}
