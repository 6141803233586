import { get } from 'lodash';
import isAcceptedOpenOrClosed from './isAcceptedOpenOrClosed';

function getStatus({ filters, location, route }, defaultStatus) {
  const statusFromFilters = get(filters, 'status');
  const statusFromQuery = get(location, 'query.status');
  const statusFromRoute = isAcceptedOpenOrClosed(get(route, 'status', '')) ? 'accepted' : get(route, 'status');

  return statusFromFilters || statusFromQuery || statusFromRoute || defaultStatus || 'all';
}

export default getStatus;
