import _ from 'lodash';
import removeElement from '../removeElement';
import { updateElement } from '../updateElements';

import {
  stateOrUpdatedState,
  addedState,
} from './index';

const handleSentReferral = (state, element, urlData, currentSection, currentLocation) => {
  if (_.isEmpty(state)) {
    return undefined;
  }

  if (_.isNil(state[currentSection])) {
    return state;
  }

  const toUpdateId = _.get(urlData, 'uuid', '');
  const modifier = _.get(urlData, 'modifier', '');
  const postRemoveState = removeElement(state, toUpdateId, currentSection);

  const returnedStateOrUpdatedState = stateOrUpdatedState(
    state,
    element,
    toUpdateId,
    currentSection,
    currentLocation,
  );

  const returnAddedState = addedState(state, element, currentSection, postRemoveState);

  switch (modifier) {
    case 'hold':
      if (element.recalled || element.rejection) {
        return returnAddedState('referralsInReview');
      }
      return returnedStateOrUpdatedState('sentReferrals');

    case 'accept':
      return returnedStateOrUpdatedState('sentReferrals');

    case 'reject':
      return returnAddedState('rejectedReferrals');

    case 'recall':
      return returnAddedState('recalledReferrals');

    case 'interactions':
    default:
      return updateElement(state, toUpdateId, element, currentSection);
  }
};

export { handleSentReferral };
