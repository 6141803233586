import { map } from 'lodash';

function createReasonOptions(reasons = []) {
  return map(reasons, (reason = {}) => ({
    value: reason.value,
    label: reason.value,
  }));
}

export default createReasonOptions;
