import React, { useRef, useState, useImperativeHandle, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classNames from 'classnames';
import {
  Button,
  Icon,
  Table,
  TableHeader,
  TableRow,
  TableHeaderColumn,
  TableBody,
  TableRowColumn,
  getPreferredProp,
  Dialog,
} from '@unite-us/ui';
import AddCirclePlusButton from 'src/common/display/Profile/components/AddCirclePlusButton';
import FileUpload from 'common/form/FileUploadV2/FileUpload';
import './PaymentsSupportingDocuments.scss';

const PaymentsSupportingDocuments = React.forwardRef((props, ref) => {
  const {
    fieldName,
    field,
    label,
    required,
  } = props;
  useImperativeHandle(ref, () => ({ props }));
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState({
    filesTooBig: [],
    filesWrongType: [],
    filesTooBigAndWrongType: [],
  });
  const [removedFile, setRemovedFile] = useState({});
  const fileUploadRef = useRef();
  const deleteAttachmentModal = useRef();
  const fieldValue = field.value || [];

  // Attach file(s) modal action handlers
  const attachDocumentModalHandler = useCallback((event) => {
    event.preventDefault();
    fileUploadRef.current.openDialog();
  }, []);

  const confirmAttachFiles = useCallback(() => {
    field.onChange([...fieldValue, ...uploadedFiles]);
    setUploadedFiles([]);
    setRejectedFiles({ filesTooBig: [], filesWrongType: [], filesTooBigAndWrongType: [] });
    fileUploadRef.current.closeDialog();
  }, [uploadedFiles, fieldValue]);

  const cancelAttachFiles = useCallback(() => {
    setUploadedFiles([]);
    setRejectedFiles({ filesTooBig: [], filesWrongType: [], filesTooBigAndWrongType: [] });
    fileUploadRef.current.closeDialog();
  }, []);

  // Delete file modal action handlers
  const trashModalHandler = useCallback((file) => {
    setRemovedFile(file);
    deleteAttachmentModal.current.openDialog();
  }, [removedFile]);

  const confirmRemoveFile = useCallback(() => {
    const removedFileId = removedFile.tempId || removedFile.id;
    const updatedFiles = fieldValue.filter((file) => (file.id || file.tempId) !== removedFileId);
    field.onChange(updatedFiles);

    deleteAttachmentModal.current.closeDialog();
  }, [removedFile]);

  const cancelRemoveFile = useCallback(() => {
    setRemovedFile({});
    deleteAttachmentModal.current.closeDialog();
  }, []);

  const invalid = getPreferredProp(props, 'invalid') && getPreferredProp(props, 'touched');

  const headerClass = () => classNames([
    'payments-attachments-metafield-header',
    'uppercase font-heavy-font text-xs tracking-wider',
    required && 'payments-attachments-metafield-header--required',
    invalid && 'text-red',
  ]);

  const emptyAttachmentsClass = () => classNames([
    'text-text-blue opacity-50',
    invalid && 'border-solid border border-red rounded-md',
  ]);

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div><p className={headerClass()}>{label}</p></div>
        <AddCirclePlusButton
          addHandler={attachDocumentModalHandler}
          ariaLabel="Attach Document"
          className="payments-attachment-button"
          id={`payments-attachment-button-${fieldName}`}
          labelText="Attach Document"
        />
      </div>
      <Dialog
        id="upload-payments-documents"
        modal
        onRequestClose={cancelAttachFiles}
        ref={fileUploadRef}
        title="Attach Documents"
        actions={(
          <div>
            <Button
              className="attach-document-dialog__actions--cancel"
              label="Cancel"
              onClick={cancelAttachFiles}
            />
            <Button
              className="attach-document-dialog__actions--save"
              label="Attach"
              primary
              disabled={!uploadedFiles.length}
              onClick={confirmAttachFiles}
              style={{ marginLeft: '12px' }}
            />
          </div>
        )}
      >
        <FileUpload
          rejectedFiles={rejectedFiles}
          setRejectedFiles={setRejectedFiles}
          setUploadedFiles={setUploadedFiles}
          uploadedFiles={uploadedFiles}
          multiple
        />
      </Dialog>
      {
        fieldValue.length ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderColumn>File Name</TableHeaderColumn>
                <TableHeaderColumn>Date Modified</TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {
                fieldValue.map((fileData) => {
                  const file = fileData.file || fileData;

                  return (
                    <TableRow
                      className="file-row"
                      clickable={false}
                      hoverable={false}
                      key={fileData.id || fileData.tempId}
                    >
                      <TableRowColumn>
                        <a
                          href={fileData.preview}
                          rel="noreferrer"
                          target="_blank"
                          className="text-action-blue"
                        >
                          { file.name }
                        </a>
                      </TableRowColumn>
                      <TableRowColumn>
                        {
                          new Intl
                            .DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
                            .format(file.created_at ? new Date(file.created_at) : new Date())
                        }
                      </TableRowColumn>
                      <TableRowColumn>
                        <Icon
                          className={'text-dark-grey fill-current'}
                          icon="IconTrash"
                          onClick={() => trashModalHandler(fileData)}
                          size={18}
                          ariaLabel="delete attachment"
                        />
                      </TableRowColumn>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        ) : (
          <>
            <p className={emptyAttachmentsClass()}>No documents have been added to this note.</p>
            { invalid ? <div className="text-red text-xs mt-1">Required</div> : null }
          </>
        )
      }
      <Dialog
        id="delete-attachment-dialog"
        ref={deleteAttachmentModal}
        title={'Delete attachment'}
        modal
        onRequestClose={cancelRemoveFile}
        data-testid="delete-attachment-dialog"
        actions={(
          <div>
            <Button
              className=""
              label="Cancel"
              onClick={cancelRemoveFile}
            />
            <Button
              className=""
              label="Delete Attachment"
              primary
              onClick={confirmRemoveFile}
              style={{ marginLeft: '12px' }}
            />
          </div>
        )}
      >
        <p>
          You are about to remove
          <strong className="text-action-light-blue"> {removedFile.name || get(removedFile, 'file.name', '')} </strong>
          as an attachment from this service.
        </p>
      </Dialog>
    </div>
  );
});

PaymentsSupportingDocuments.propTypes = {
  field: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

PaymentsSupportingDocuments.defaultProps = {
  required: false,
};

export default PaymentsSupportingDocuments;
