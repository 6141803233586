import Humanize from 'humanize-plus';
import { map, reduce } from 'lodash';

const getServices = (services) => reduce(services, (acc, service) => [
  ...acc,
  service.name,
  ...(map(service.children, 'name')),
], []);

const serviceNamesForDisplay = (services, amount) => (
  Humanize.oxford(getServices(services), amount)
);

export default serviceNamesForDisplay;
