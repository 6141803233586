import { dates } from '@unite-us/app-components';
import getStatusColor from 'common/utils/Status/StatusColor';
import { isAutoRecallable } from 'src/components/Referrals/utils';
import { isReferredToGroup, isReferredByGroup } from 'src/components/Referrals/ReferralStatus/utils';
import {
  REFERRAL_STATUS_DRAFT,
  REFERRAL_STATUS_PENDING,
  // REFERRAL_STATUS_IN_REVIEW,
  REFERRAL_STATUS_DECLINED,
  REFERRAL_STATUS_RECALLED,
} from 'src/components/Referrals/constants';
import _ from 'lodash';

export function displayDate(object, options = {}) {
  const currentValue = dates.formatDate(_.wget(object, options.key, null));

  return {
    label: _.wget(options, 'label', null),
    value: currentValue,
    clickable: _.get(options, 'clickable', false),
  };
}

export function displayDateWithTime(object, options = {}) {
  const currentValue = dates.formatLocalDateTime(_.wget(object, options.key, null));

  return {
    label: _.wget(options, 'label', null),
    value: currentValue,
    clickable: _.get(options, 'clickable', false),
  };
}

export function displayInteractionDate(object, options = {}) {
  const currentValue = dates.formatLocalDateTime(_.wget(object, options.key, null));

  const value = _.isEmpty(currentValue) ? 'No Interactions Yet' : currentValue;

  return {
    label: _.wget(options, 'label', null),
    value,
    clickable: _.get(options, 'clickable', false),
    italicize: _.isEqual(value, 'No Interactions Yet'),
  };
}

export function displayText(object, options = {}) {
  return {
    label: _.wget(options, 'label', null),
    value: _.wget(object, options.key, ''),
    clickable: _.get(options, 'clickable', false),
  };
}

export function hideOrDisplayText(object, options = {}) {
  const currentValue = _.wget(object, options.key, null);

  if (currentValue) {
    return {
      label: _.wget(options, 'label', null),
      value: currentValue,
      clickable: _.get(options, 'clickable', false),
    };
  }

  return null;
}

export function displayNode(options = {}) {
  const currentValue = _.wget(options, 'node', null);

  return {
    label: _.wget(options, 'label', null),
    value: currentValue,
    clickable: _.get(options, 'clickable', false),
  };
}

export function generateColumn(content) {
  return _.reject(content, _.isNull);
}

export function dividerStatusStyles(status, isClosedByCoordinationCenter, verticalHeight = '5px') {
  return {
    height: verticalHeight,
    backgroundColor: (isClosedByCoordinationCenter ? getStatusColor('closed') : getStatusColor(status)),
    border: 'none',
  };
}

export function isReferralEditable(referralStatus, ...editableStates) {
  return editableStates.indexOf(referralStatus) !== -1;
}

export function isRecalledAndCCUser(isCCUser, status) {
  return isCCUser && status === 'recalled';
}

export function isServiceTypeEditable(item, status, groupId, isCCUser) {
  const type = _.get(item, 'type') || _.get(item, '_meta._type');
  if (type === 'referrals_referral') {
    if ((isAutoRecallable(item) && isReferredToGroup(item, groupId)) || isRecalledAndCCUser(isCCUser, status)) {
      return false;
    }

    if (isReferralEditable(
      status,
      REFERRAL_STATUS_DRAFT,
    )) {
      return true;
    }
  }

  return false;
}

export function isDescriptionEditable(item, status, groupId, isCCUser) {
  const type = _.get(item, 'type') || _.get(item, '_meta._type');

  if (type === 'referrals_referral') {
    if (isCCUser && isReferredByGroup(item, groupId) && isReferralEditable(
      status,
      REFERRAL_STATUS_DRAFT,
      REFERRAL_STATUS_PENDING,
      REFERRAL_STATUS_DECLINED,
      REFERRAL_STATUS_RECALLED,
    )) {
      return true;
    }
  }

  return false;
}

export function isNetworkEditable(item, status) {
  const type = _.get(item, '_meta._type');
  if (type === 'referrals_referral') {
    return status === REFERRAL_STATUS_DRAFT;
  }
  return false;
}
