import _ from 'lodash';
import moment from 'moment';

const FIELDS = [
  'title',
  'suffix',
  'gross_monthly_income',
  'race',
  'gender',
  'ethnicity',
  'citizenship',
  'marital_status',
];

const MILITARY_FIELDS = [
  'current_status',
  'currently_transitioning',
  'at_least_one_day_active_duty',
  'branch',
  'service_era',
  'entry_date',
  'exit_date',
  'deployed',
  'deployment_starts_at',
  'deployment_ends_at',
  'discharge_type',
  'discharged_due_to_disability',
  'service_connected_disability',
  'service_connected_disability_rating',
  'proof_of_veteran_status',
  'proof_type',
];

const isBlank = (value) => _.isNil(value) || value === 'undisclosed' || value === 'prefer_not_to_disclose';
const isEqual = (value1, value2) => {
  if (typeof value1 === 'string' && typeof value2 === 'string') {
    return value1.toLowerCase() === value2.toLowerCase();
  }
  if (Array.isArray(value1) && Array.isArray(value2)) {
    return _.isEqual(value1.sort(), value2.sort());
  }

  return _.isEqual(value1, value2);
};
const getDifferences = (array1, array2, keys) => _.differenceWith(array1, array2, (a, b) => keys.every((key) => {
      const valueA = _.get(a, key);
      const valueB = _.get(b, key);

      return isEqual(valueA, valueB);
    }));

const getUniqueItems = (arr, uniqueKeys) => {
  const comparisonFunction = (a, b) => uniqueKeys.every((key) => _.isEqual(_.get(a, key), _.get(b, key)));

  return _.uniqWith(arr, comparisonFunction);
};

const sortByKeyWithPriority = (arr, key, orderPriority) => _.sortBy(arr, (item) => orderPriority[item[key]]);

const compareFields = (fields, sourceObj, targetObj) => {
  const result = {};

  fields.forEach((field) => {
    const fieldChanged = (isBlank(sourceObj?.[field]) && !isBlank(targetObj?.[field])) ||
                          (!isBlank(targetObj?.[field]) && !isEqual(sourceObj?.[field], targetObj?.[field]));
    if (fieldChanged) {
      result[field] = [sourceObj?.[field], targetObj?.[field]];
    }
  });

  return result;
};

const getDifferencesWithoutDuplicates = (sourceArray, targetArray, keyArray) => getDifferences(
    getUniqueItems(sourceArray, keyArray),
    targetArray,
    keyArray,
  );

const setIsPrimaryFlag = (array) => array.map((item, index) => {
      if (index === 0) {
        return {
          ...item,
          is_primary: true,
        };
      }
      return item;
    });

export const getUpdatedValues = (changes) => Object.fromEntries(
  Object.entries(changes).map(([key, value]) => [key, value[1]]),
);

const getMilitaryChanges = (existingPerson = {}, assistanceRequestPerson = {}) => {
  let militaryChanges = {};

  const changes = compareFields(MILITARY_FIELDS, existingPerson.military, assistanceRequestPerson.military);
  const existingAffiliation = existingPerson.military?.affiliation;
  const newAffiliation = assistanceRequestPerson.military?.affiliation;
  const affiliationChanged = !_.isNil(existingAffiliation) &&
                              !_.isNil(newAffiliation) &&
                              !isEqual(existingAffiliation, newAffiliation);
  const addedAffiliation = _.isNil(existingAffiliation) && !_.isNil(newAffiliation);

  if (affiliationChanged || addedAffiliation) {
    changes.affiliation = [existingPerson.military?.affiliation, assistanceRequestPerson.military?.affiliation];
  }

  if (!_.isEmpty(changes)) {
    const newValues = getUpdatedValues(changes);

    militaryChanges = {
      military: [
        existingPerson.military,
        {
          ...existingPerson.military,
          ...newValues,
        },
      ],
    };
  }

  return militaryChanges;
};

const getSexualityChanges = (existingPerson = {}, assistanceRequestPerson = {}) => {
  if (assistanceRequestPerson.sexuality?.length === 0) {
    return {};
  }

  const changes = compareFields(['sexuality', 'sexuality_other'], existingPerson, assistanceRequestPerson);
  if ((!assistanceRequestPerson.sexuality?.includes('other')) && !_.isEmpty(existingPerson.sexuality_other)) {
    changes.sexuality_other = [existingPerson.sexuality_other, null];
  } else if (!assistanceRequestPerson.sexuality?.includes('other') && _.isEmpty(existingPerson.sexuality_other)) {
    delete changes.sexuality_other;
  }

  return changes;
};

const getRecordLanguagesChanges = (existingPerson = {}, assistanceRequestPerson = {}) => {
  const sameLanguage = (a, b) => a.record_language_type === b.record_language_type &&
    a.language_id === b.language_id;

  const existingLanguages = existingPerson.preferred_languages || [];
  const arLanguages = assistanceRequestPerson.preferred_languages || [];

  if (existingLanguages.length === 0 && arLanguages.length === 0) {
    return {};
  }

  const onlyInARLanguages = arLanguages
    .filter((arLanguage) => !existingLanguages.some((existingLanguage) => sameLanguage(existingLanguage, arLanguage)));

  if (onlyInARLanguages.length > 0) {
    return {
      record_languages: [existingLanguages, arLanguages],
    };
  }

  return {};
};

const normalizeMilitary = (existingPerson) => {
  if (!existingPerson.military) { return undefined; }
  const { military } = existingPerson;

  return {
    ...military,
    ...(military.deployment_ends_at ?
      { deployment_ends_at: moment.unix(military.deployment_ends_at).utc().format('YYYY-MM-DD') } : {}
    ),
    ...(military.deployment_starts_at ?
      { deployment_starts_at: moment.unix(military.deployment_starts_at).utc().format('YYYY-MM-DD') } : {}
    ),
    ...(military.entry_date ? { entry_date: moment.unix(military.entry_date).utc().format('YYYY-MM-DD') } : {}),
    ...(military.exit_date ? { exit_date: moment.unix(military.exit_date).utc().format('YYYY-MM-DD') } : {}),
  };
};

const normalizeExistingPerson = (existingPerson) => {
  const military = normalizeMilitary(existingPerson);

  return {
    ...existingPerson,
    ...(military ? { military } : {}),
  };
};

const getPersonChanges = (existingPerson = {}, assistanceRequestPerson = {}) => {
  const normalizedExistingPerson = normalizeExistingPerson(existingPerson);
  const sexualityChanges = getSexualityChanges(normalizedExistingPerson, assistanceRequestPerson);
  const recordLanguagesChanges = getRecordLanguagesChanges(normalizedExistingPerson, assistanceRequestPerson);
  const militaryChanges = getMilitaryChanges(normalizedExistingPerson, assistanceRequestPerson);

  const personChanges = {
    updates: {
      ...compareFields(FIELDS, normalizedExistingPerson, assistanceRequestPerson),
      ...sexualityChanges,
      ...recordLanguagesChanges,
      ...militaryChanges,
    },
    additions: {
      phone_numbers: getDifferencesWithoutDuplicates(
        sortByKeyWithPriority(
          assistanceRequestPerson.phone_numbers,
          'phone_type',
          {
            mobile: 1, home: 2, unknown: 3, work: 4, fax: 5,
          },
        ),
        normalizedExistingPerson.phone_numbers,
        ['phone_number', 'country_code'],
      ),
      email_addresses: getDifferencesWithoutDuplicates(
        assistanceRequestPerson.email_addresses,
        normalizedExistingPerson.email_addresses,
        ['email_address'],
      ),
      addresses: getDifferencesWithoutDuplicates(
        assistanceRequestPerson.addresses,
        normalizedExistingPerson.addresses,
        ['line_1', 'line_2', 'city', 'state', 'postal_code', 'country'],
      ),
    },
  };

  // Set is_primary to true for additions
  personChanges.additions.phone_numbers = setIsPrimaryFlag(personChanges.additions.phone_numbers);
  personChanges.additions.email_addresses = setIsPrimaryFlag(personChanges.additions.email_addresses);
  personChanges.additions.addresses = setIsPrimaryFlag(personChanges.additions.addresses);

  return personChanges;
};

export default getPersonChanges;
