// Library Imports
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { dates } from '@unite-us/app-components';
import { getMilitaryEnumDisplayName } from 'src/components/MilitaryInformation/utils';
import DisplayLine from './DisplayLine';

function getMilitaryData(contact = {}) {
  return isEmpty(contact.military) ? {} : contact.military;
}

const getMilitaryClassname = (military = {}) => {
  const affiliation = military.affiliation || {};

  return classNames({
    hidden: affiliation !== '' && affiliation !== 'military_member_or_veteran',
  });
};

const MilitaryInformationDisplay = ({ contact, enums }) => {
  const military = getMilitaryData(contact);
  const militaryInfoClasses = getMilitaryClassname(military);

  const duration = (start, end) => (
    <span>
      {start ? `From ${dates.formatDate(start)}` : ''}
      {start && end ? ` to ${dates.formatDate(end)}` : ''}
    </span>
  );

  return (
    <div className="military-information-display">
      <DisplayLine
        title="Affiliation"
        value={getMilitaryEnumDisplayName(military.affiliation, enums, 'affiliation')}
      />
      <DisplayLine
        className={militaryInfoClasses}
        title="Current Status"
        value={getMilitaryEnumDisplayName(military.current_status, enums, 'current_status')}
      />
      <DisplayLine
        className={militaryInfoClasses}
        title="Currently Transitioning?"
        value={getMilitaryEnumDisplayName(military.currently_transitioning, enums, 'transitioning')}
      />
      <DisplayLine
        className={militaryInfoClasses}
        title="Branch Of Service"
        value={getMilitaryEnumDisplayName(military.branch, enums, 'branch')}
      />
      <DisplayLine
        className={militaryInfoClasses}
        title="Service Era"
        value={getMilitaryEnumDisplayName(military.service_era, enums, 'service_era')}
      />
      <DisplayLine
        className={militaryInfoClasses}
        title="Service Dates"
        value={duration(military.entry_date, military.exit_date)}
      />
      <DisplayLine
        className={militaryInfoClasses}
        title="Has at least one day of active duty outside of basic training?"
        value={getMilitaryEnumDisplayName(military.at_least_one_day_active_duty, enums, 'active_duty')}
      />
      <DisplayLine
        className={militaryInfoClasses}
        title="Military Discharge"
        value={getMilitaryEnumDisplayName(military.discharge_type, enums, 'discharge_type')}
      />
      <DisplayLine
        className={militaryInfoClasses}
        title="Discharged or retired from military service for a disability incurred in the line of duty?"
        value={
          getMilitaryEnumDisplayName(military.discharged_due_to_disability, enums, 'discharge_disability')
        }
      />
      <DisplayLine
        className={militaryInfoClasses}
        title="Has VA-rated Service-Connected Disability?"
        value={
          getMilitaryEnumDisplayName(military.service_connected_disability, enums, 'connected_disability')
        }
      />
      { military.service_connected_disability === 'yes' && (
        <DisplayLine
          className={militaryInfoClasses}
          title="Service-Connected Disability Rating"
          value={
            getMilitaryEnumDisplayName(
              military.service_connected_disability_rating,
              enums,
              'disability_rating',
            )
          }
        />
      )}
      <DisplayLine
        className={militaryInfoClasses}
        title="Has client ever been deployed?"
        value={getMilitaryEnumDisplayName(military.deployed, enums, 'deployed')}
      />
      { military.deployed === 'yes' && (
        <DisplayLine
          className={militaryInfoClasses}
          title="Deployment Dates"
          value={duration(military.deployment_starts_at, military.deployment_ends_at)}
        />
      )}
      <DisplayLine
        className={militaryInfoClasses}
        title="Proof of veteran status?"
        value={
          getMilitaryEnumDisplayName(military.proof_of_veteran_status, enums, 'proof_of_veteran_status')
        }
      />
      { military.proof_of_veteran_status === 'yes' && (
        <DisplayLine
          title="Proof Type"
          value={getMilitaryEnumDisplayName(military.proof_type, enums, 'proof_type')}
          className={militaryInfoClasses}
        />
      )}
    </div>
  );
};

MilitaryInformationDisplay.propTypes = {
  contact: PropTypes.shape({
    military: PropTypes.object,
  }).isRequired,
  enums: PropTypes.shape({
    contact_data: PropTypes.shape({
      military: PropTypes.object,
    }),
  }).isRequired,
};

export default MilitaryInformationDisplay;
