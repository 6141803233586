import { useEffect } from 'react';

const usePreventWheel = (elementId) => {
  useEffect(() => {
    let element = document.getElementById(elementId);

    const handleWheel = (event) => {
      if (event.target.type === 'number') {
        event.preventDefault();
      }
    };

    const applyListener = () => {
      if (element) {
        element.addEventListener('wheel', handleWheel);
      }
    };

    const removeListener = () => {
      if (element) {
        element.removeEventListener('wheel', handleWheel);
      }
    };

    applyListener();

    const observer = new MutationObserver(() => {
      const newElement = document.getElementById(elementId);
      if (element !== newElement) {
        removeListener();
        element = newElement;
        applyListener();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      removeListener();
      observer.disconnect();
    };
  }, [elementId]);
};

export default usePreventWheel;
