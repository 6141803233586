import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dialog } from '@unite-us/ui';
import UploadedDocumentPdf from './UploadedDocumentPdf';
import PreviewUnavailableCard from './PreviewUnavailableCard';
import getFileExtension from './utils/getFileExtension';
import supportedFilesExtensions from './utils/supportedFilesExtensions';

class UploadedDocumentModal extends Component {
  render() {
    const {
      doc,
      documentClicked,
      styles,
    } = this.props;

    const isDocumentSupported = supportedFilesExtensions.includes(getFileExtension(doc?.name || doc?.filename));

    return !documentClicked ? null : (
      <Dialog
        id="upload-document-modal"
        ref={(el) => { this.dialog = el; }}
        title={doc.title}
        size="large"
        style={styles.dialogRoot}
        repositionOnUpdate={false}
      >
        {isDocumentSupported ?
          (
            <UploadedDocumentPdf
              doc={doc}
              pdfDocUrl={doc.pdf_url}
              UploadedDocumentPdf
            />
          ) : (
            <PreviewUnavailableCard
              doc={doc}
            />
        )}
      </Dialog>
    );
  }
}

UploadedDocumentModal.propTypes = {
  documentClicked: PropTypes.bool.isRequired,
  doc: PropTypes.shape({
    pdf_url: PropTypes.string,
    title: PropTypes.string,
    filename: PropTypes.string,
    name: PropTypes.string,
  }),
  styles: PropTypes.object.isRequired,
};

UploadedDocumentModal.defaultProps = {
  styles: {
    dialogRoot: {
      paddingTop: '10px',
    },
  },
};

export default UploadedDocumentModal;
