import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@unite-us/ui';
import { validateReduxForm } from 'common/form';
import _ from 'lodash';
import { OverlaySpinner } from 'common/spinners';
import { EDIT_PHONE_NUMBERS } from 'common/display/Profile/constants/form';
import { ProfilePhoneFields } from 'common/form/Profile';
import { generateUUID } from 'common/utils/utils';
import { FACESHEET } from 'common/utils/EventTracker/utils/eventConstants';
import callOrLog from 'src/common/utils/callOrLog';
import {
  cleanPhoneValues,
} from 'common/form/Profile/utils/cleanContactValues';
import { theme } from 'src/../tailwind.config';
import { phonesInitialValues } from './utils';

export class EditPhoneNumbers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deletingPhone: false,
      newIds: [],
    };

    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.addField = this.addField.bind(this);
    this.removeField = this.removeField.bind(this);
    this.resetAndCloseModal = this.resetAndCloseModal.bind(this);
    this.onPrimaryChange = this.onPrimaryChange.bind(this);
    this.removeUnsavedPhones = this.removeUnsavedPhones.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.modalIsOpen && !nextProps.modalIsOpen) {
      this.removeUnsavedPhones();
    }
  }

  onCancel() {
    this.resetAndCloseModal();
  }

  onSubmit(values) {
    const phones = values.phones.map(cleanPhoneValues);
    callOrLog(() => this.context.eventTracker(FACESHEET.phoneUpdated));
    return this.props.onSave(phones)
      .then(this.resetAndCloseModal);
  }

  onPrimaryChange(phone) {
    _.each(this.props.fields.phones, (field) => {
      if (field !== phone) {
        field.is_primary.onChange(false);
      }
    });
    phone.is_primary.onChange(true);
  }

  addField() {
    const { phones } = this.props.fields;
    const id = generateUUID();
    phones.addField({ id });
    this.setState({
      newIds: [...this.state.newIds, id],
    }, () => window.dispatchEvent(new Event('resize')));
  }

  removeField(idToRemove) {
    const { fields: { phones } } = this.props;
    const index = _.findIndex(phones, (p) => p.id.value === idToRemove);
    phones.removeField(index);
    this.setState({
      newIds: _.filter(this.state.newIds, (id) => id !== idToRemove),
    });
  }

  removeUnsavedPhones() {
    const { phones } = this.props.fields;
    _.eachRight(phones, (phone, i) => {
      if (!phone.phone_number.initialValue) {
        phones.removeField(i);
      }
    });
  }

  resetAndCloseModal() {
    this.removeUnsavedPhones();
    this.props.resetForm();
    this.props.closeModal();
  }

  render() {
    const {
      fields: { phones },
      handleSubmit,
      hideCommunicationPreferences,
      phoneFor,
      phoneTypes,
      registerField,
      styles,
      submitting,
      withPhoneExtensionField,
    } = this.props;
    const { deletingPhone } = this.state;
    const phoneForms = phones.map((phone, index) => (
      <div
        id={`inline-phone-${index}`}
        key={phone.id.initialValue}
        style={index !== phones.length - 1 ? styles.inlinePhoneContainer : {}}
      >
        <ProfilePhoneFields
          fields={phone}
          hideCommunicationPreferences={hideCommunicationPreferences}
          inline
          onPrimaryChange={this.onPrimaryChange}
          onRemove={this.removeField}
          phoneFor={phoneFor}
          phoneTypes={phoneTypes}
          registerField={registerField}
          withPhoneExtensionField={withPhoneExtensionField}
        />
      </div>
    ));

    return (
      <form
        className="edit-phone-numbers content-with-actions"
        onSubmit={handleSubmit(this.onSubmit)}
      >
        <OverlaySpinner text="Saving..." show={submitting || deletingPhone} />
        <div className="content-container">
          {phoneForms}
        </div>
        <div className="actions">
          <div className="row">
            <div className="col-sm-6 text-left edit-phone-numbers__add-phone">
              <Button
                type="button"
                onClick={this.addField}
                id="add-phone-button"
                label="+ Add Phone Number"
                className="border-none normal-case p-0 font-black text-sm"
                style={{ color: theme.extend.colors['action-darker-blue'] }}
              />
            </div>
            <div className="col-sm-6">
              <span className="action-item">
                <Button
                  id="edit-phone-number-cancel-btn"
                  onClick={this.onCancel}
                  disabled={submitting}
                  label="Cancel"
                />
              </span>
              <span className="action-item">
                <Button
                  id="edit-phone-number-save-btn"
                  onClick={handleSubmit(this.onSubmit)}
                  disabled={submitting}
                  label="Save"
                  primary
                />
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

EditPhoneNumbers.propTypes = {
  closeModal: PropTypes.func,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hideCommunicationPreferences: PropTypes.bool,
  modalIsOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  phoneFor: PropTypes.oneOf(['contact', 'group']),
  phoneTypes: PropTypes.array.isRequired,
  registerField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    inlinePhoneContainer: PropTypes.object.isRequired,
  }),
  submitting: PropTypes.bool.isRequired,
  withPhoneExtensionField: PropTypes.bool,
};

EditPhoneNumbers.defaultProps = {
  closeModal: _.noop,
  hideCommunicationPreferences: false,
  onSave: _.noop,
  phoneFor: 'contact',
  styles: {
    inlinePhoneContainer: {
      borderBottom: '1px solid #EBECED',
      marginBottom: '20px',
      paddingBottom: '10px',
    },
  },
  withPhoneExtensionField: false,
};

EditPhoneNumbers.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { numbers } = ownProps;
  return {
    groupId: state.session.groupId,
    initialValues: {
      phones: phonesInitialValues(numbers),
    },
  };
}

// accessibility improvement: focus on first field with error
const onSubmitFail = () => {
  setTimeout(() => {
    const errs = document.getElementsByClassName('ui-form-field--has-error');
    const err = _.first(errs);
    const focusableErr = _.first(err?.getElementsByClassName('choices')) || _.first(err?.getElementsByTagName('input'));
    return focusableErr?.focus();
  }, 300);
};

const fields = [
  'phones[].acceptable_communication_types.message',
  'phones[].acceptable_communication_types.notification',
  'phones[].acceptable_communication_types.phone_call',
  'phones[].country_code',
  'phones[].id',
  'phones[].is_primary',
  'phones[].phone_number',
  'phones[].phone_type',
  'phones[].extension',
];
export default validateReduxForm(
  {
    onSubmitFail,
    form: EDIT_PHONE_NUMBERS,
    fields,
  },
  mapStateToProps,
)(EditPhoneNumbers);
