import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const ColumnHeader = ({ children, className, dataTestId }) => (
  <th
    className={cx(
      'px-4 py-3 font-heavy-font text-text-blue text-xs text-left tracking-wider uppercase truncate',
      className,
    )}
    scope="col"
    data-testid={dataTestId}
  >
    {children}
  </th>
);

ColumnHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

ColumnHeader.defaultProps = {
  className: '',
  dataTestId: undefined,
};

export default ColumnHeader;
