import React from 'react';
import PropTypes from 'prop-types';
import LogoCard from 'src/common/TailwindComponents/LogoCard';
import NoFilteredInvoicesFound from './NoFilteredInvoicesFound';

const NoInvoicesFoundCard = ({ isFiltered, resetFilters, status }) => (
  <LogoCard>
    {isFiltered ? (
      <NoFilteredInvoicesFound resetFilters={resetFilters} />
    ) : (
      <>
        <h4>Nothing to see here!</h4>
        <p>You don&apos;t have any {status} invoices to review.</p>
      </>
    )}
  </LogoCard>
);

NoInvoicesFoundCard.propTypes = {
  isFiltered: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

export default NoInvoicesFoundCard;
