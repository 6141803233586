import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, flow } from 'lodash';
import FeatureFlagContainer from 'src/common/utils/FeatureFlags/FeatureFlagContainer';
import { hasPayerAuthorizationAccess } from 'src/common/utils/FeatureFlags/flags';
import { browserHistory } from 'src/common/utils/browserHistory';
import { CurrentProviderContextProvider, useIsPayer } from 'common/contexts/CurrentProviderContext';
import { CurrentEmployeeContext } from 'src/common/contexts/CurrentEmployeeContext';
import { StatusSelectBar } from './components/StatusSelectBar';

const ContainerWithCurrentProviderContext = ({ children, showServiceAuthorizations }) => {
  const isPayer = useIsPayer();
  useEffect(() => {
    if (!showServiceAuthorizations || !isPayer) {
      browserHistory.push('/');
    }
  }, [showServiceAuthorizations, isPayer]);
  return showServiceAuthorizations && isPayer && (
    <div className="flex antialiased min-h-full -mx-container-padding">
      <StatusSelectBar />
      <div className="mb-20 break-normal flex-grow">
        {children}
      </div>
    </div>
  );
};

ContainerWithCurrentProviderContext.propTypes = {
  children: PropTypes.node.isRequired,
  showServiceAuthorizations: PropTypes.bool.isRequired,
};

const ServiceAuthorizationsContainer = ({
  children,
  providerId,
  showServiceAuthorizations,
  currentEmployee,
}) => (
  <CurrentEmployeeContext.Provider value={currentEmployee}>
    <CurrentProviderContextProvider providerId={providerId}>
      <ContainerWithCurrentProviderContext showServiceAuthorizations={showServiceAuthorizations}>
        {children}
      </ContainerWithCurrentProviderContext>
    </CurrentProviderContextProvider>
  </CurrentEmployeeContext.Provider>
);

ServiceAuthorizationsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  providerId: PropTypes.string,
  showServiceAuthorizations: PropTypes.bool,
  currentEmployee: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

ServiceAuthorizationsContainer.defaultProps = {
  providerId: '',
  showServiceAuthorizations: false,
};

function mapStateToProps(state) {
  return {
    providerId: get(state, 'session.groupId', ''),
    showServiceAuthorizations: hasPayerAuthorizationAccess(state),
    currentEmployee: get(state, 'globalState.currentEmployee'),
  };
}

export default flow(
  FeatureFlagContainer,
  connect(mapStateToProps),
)(ServiceAuthorizationsContainer);
