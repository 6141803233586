import { browserHistory } from 'common/utils/browserHistory';
import caseRoute from './caseRoute';

function goToCase(serviceCase, query = {}, groupId = '') {
  const pathname = caseRoute(serviceCase, groupId);

  browserHistory.push({
    pathname,
    query,
  });
}

export default goToCase;
