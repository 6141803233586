import React from 'react';
import PropTypes from 'prop-types';
import { OverlaySpinner } from 'common/spinners';
import TotalUniqueContacts from 'src/components/Reports/components/PopulationReports/TotalUniqueContacts';
import ContactsByGender from 'src/components/Reports/components/PopulationReports/ContactsByGender';
import ContactsByAge from 'src/components/Reports/components/PopulationReports/ContactsByAge';
import ContactsByRace from 'src/components/Reports/components/PopulationReports/ContactsByRace';
import ContactsByEthnicity from 'src/components/Reports/components/PopulationReports/ContactsByEthnicity';
import ContactsByGenderByAge from 'src/components/Reports/components/PopulationReports/ContactsByGenderByAge';
import ContactsRaceByEthnicity from 'src/components/Reports/components/PopulationReports/ContactsRaceByEthnicity';
import { DOMAIN_CONSTANTS } from 'src/common/constants';
import _ from 'lodash';

export default class PopulationReport extends React.Component {
  constructor(props) {
    super(props);
    this.isLoading = this.isLoading.bind(this);
    this.chartLoaded = this.chartLoaded.bind(this);

    this.state = {
      loaded: {
        totalUniqueContacts: false,
        contactsByGender: false,
        contactsByAge: false,
        contactsByRace: false,
        contactsByEthnicity: false,
        contactsByGenderByAge: false,
        contactsRaceByEthnicity: false,
      },
    };
  }

  isLoading() {
    return _.some(_.values(this.state.loaded), (v) => !v);
  }

  chartLoaded(key) {
    const loaded = _.merge(this.state.loaded, { [key]: true });
    this.setState({ loaded });
  }

  render() {
    const { groupId, filters } = this.props;

    return (
      <div>
        <OverlaySpinner
          show={this.isLoading()}
          text="Loading"
          animationTimeout={1}
        />
        <div className="row">
          <div className="col-sm-6">
            <TotalUniqueContacts
              scope={DOMAIN_CONSTANTS.GROUPS}
              id={groupId}
              loaded={this.chartLoaded}
              filters={filters}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <ContactsByGender
              scope={DOMAIN_CONSTANTS.GROUPS}
              id={groupId}
              loaded={this.chartLoaded}
              filters={filters}
            />
          </div>
          <div className="col-sm-6">
            <ContactsByAge
              scope={DOMAIN_CONSTANTS.GROUPS}
              id={groupId}
              loaded={this.chartLoaded}
              filters={filters}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <ContactsByRace
              scope={DOMAIN_CONSTANTS.GROUPS}
              id={groupId}
              loaded={this.chartLoaded}
              filters={filters}
            />
          </div>
          <div className="col-sm-6">
            <ContactsByEthnicity
              scope={DOMAIN_CONSTANTS.GROUPS}
              id={groupId}
              loaded={this.chartLoaded}
              filters={filters}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <ContactsByGenderByAge
              scope={DOMAIN_CONSTANTS.GROUPS}
              id={groupId}
              loaded={this.chartLoaded}
              filters={filters}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <ContactsRaceByEthnicity
              scope={DOMAIN_CONSTANTS.GROUPS}
              id={groupId}
              loaded={this.chartLoaded}
              filters={filters}
            />
          </div>
        </div>
      </div>
    );
  }
}

PopulationReport.propTypes = {
  filters: PropTypes.shape({
    end_date: PropTypes.string,
    start_date: PropTypes.string,
  }),
  groupId: PropTypes.string,
};
