import { SEND_REFERRAL } from 'actions';
import { get } from 'lodash';
import Notifier from 'common/helpers/Notifier';
import { coreApi } from 'src/api/config';

const updateCaseState = async (referral, state) => {
  try {
    const updatedCase = await coreApi.updateRecord('case', referral.case_id, {
      state,
      primary_worker: state !== 'draft' ? referral.primary_case_worker_id : null,
    });

    return updatedCase;
  } catch (error) {
    return false;
  }
};

const updateCaseServiceType = async (referral) => {
  try {
    const updatedCase = await coreApi.updateRecord('case', referral.case_id, {
      service: referral.service_type_id,
    });

    return updatedCase;
  } catch (error) {
    return false;
  }
};

const acceptReferral = async (referral = {}) => {
  try {
    const acceptedReferral = await coreApi.updateRecord('referral', referral.id, {
      state: 'accepted',
    });

    return acceptedReferral;
  } catch (error) {
    return false;
  }
};

const sendReferral = (referral, isOON = false) => (
  async (dispatch, getState) => {
    try {
      if (referral.state === 'sent' || referral.state === 'in_review') {
        await Promise.all([
          acceptReferral(referral),
          updateCaseServiceType(referral),
        ]);
      }
      if (isOON) {
        await updateCaseState(referral, 'off_platform');
      }

      const currentEmployee = get(getState(), 'globalState.currentEmployee');
      const isGroupedReferrals = referral.referred_to_groups.length > 1;

      const payload = !isOON ?
        await Promise.all(
          referral.referred_to_groups.map((r) => coreApi.createRecord('referral', {
            state: 'sent',
            case: referral.case_id,
            sending_provider: referral.referred_by_group_id,
            sending_network: referral.referred_by_network_id,
            receiving_network: referral.referred_to_network_id,
            receiving_provider: r.group_id,
            ...(r.program_id ? { receiving_program: r.program_id } : {}),
            is_grouped: isGroupedReferrals,
          })),
        ) :
        await Promise.all(
          referral.out_of_network_providers.filter((oonProvider) => oonProvider.provider_id)
            .map((oonProvider) => coreApi.createRecord('referral', {
              state: 'off_platform',
              case: referral.case_id,
              sending_provider: referral.referred_by_group_id,
              sending_network: referral.referred_by_network_id,
              receiving_network: referral.referred_by_network_id,
              receiving_provider: oonProvider.provider_id,
              ...(oonProvider.program_id ? { receiving_program: oonProvider.program_id } : null),
            })),
          referral.out_of_network_providers.filter((oonProvider) => oonProvider.custom_name)
            .map((oonProvider) => coreApi.createRecord('note', {
              subjectType: 'case',
              subject: referral.case_id,
              employee: currentEmployee.id,
              visibility: 'public',
              category: 'external_provider',
              text: `Referred off platform to ${oonProvider.custom_name}`,
            })),
        );

      dispatch({
        type: SEND_REFERRAL,
        payload,
      });

      Notifier.dispatch(
        201,
        isOON ? 'Case Created' :
          `Referral${payload && payload.length > 1 ? 's' : ''} Successfully Created`,
      );

      return payload;
    } catch (error) {
      return false;
    }
  }
);

export default sendReferral;
