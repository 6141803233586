export const addAdditionalProviderAttributes = (providers) => providers.forEach((d) => {
    // eslint-disable-next-line no-param-reassign
    d.group_type = d.licensed ? 'in_network' : 'out_of_network';
    // `lat_lnd`, `provider_id`, `provided_service_type_ids` are used
    // in a specific format before being rendered in components
    d.addresses.forEach((a) => {
      // eslint-disable-next-line no-param-reassign
      a.lat_lng = [a.latitude, a.longitude];
    });

    d.programs
      .filter(Boolean)
      .forEach((p) => {
        // eslint-disable-next-line no-param-reassign
        p.provider_id = p.provider.id;
        // eslint-disable-next-line no-param-reassign
        p.provided_service_type_ids = p.provided_service_type_ids.map((o) => o.id);
      });
  });
