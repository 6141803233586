import React from 'react';
import PropTypes from 'prop-types';
import { StatusBadge } from 'src/common/badges';

const badgeProps = {
  open: {
    className: 'bg-dark-border-blue text-text-blue',
    label: 'Open',
  },
  canceled: {
    className: 'bg-action-blue text-white',
    label: 'Canceled',
  },
  accepted: {
    className: 'bg-dark-green text-white',
    label: 'Accepted',
  },
  rejected: {
    className: 'bg-red text-white',
    label: 'Rejected',
  },
};

const validStatuses = Object.keys(badgeProps);

const EnrollmentStatusIndicator = ({ status }) => {
  if (!validStatuses.includes(status)) return '';
  const { className, label } = badgeProps[status];
  return <StatusBadge className={className} label={label} />;
};

EnrollmentStatusIndicator.propTypes = {
  status: PropTypes.oneOf(validStatuses).isRequired,
};

export default EnrollmentStatusIndicator;
