import { CORE_BASE_URL } from 'src/config/env/env.config';

export const convertCoreDocuments = (documents) => (
  documents.map((d) => {
    const url = `${CORE_BASE_URL}${d.path}`;
    d.url = url;            // eslint-disable-line
    d.pdf_url = url;        // eslint-disable-line
    d.thumbnail_url = url;  // eslint-disable-line
    d.title = d.filename;   // eslint-disable-line
    return d;
  })
);
