import {
  useCreateRecord,
  useFind,
  useUpdateRecord,
} from 'src/api/APIHooks';
import Notifier from 'common/helpers/Notifier';
import { defaultQueryConfig } from '../apiHookOptions';

export const useFindInvoiceRejectionReasonsByFeeScheduleId = (feeScheduleId) => useFind(
  'invoice_rejection_reason',
  {
    fee_schedule: feeScheduleId,
  },
  { queryConfig: defaultQueryConfig },
);

export const useCreateInvoiceRejectionReason = () => useCreateRecord('invoice_rejection_reason', {
    mutationConfig: {
      onError: (error) => {
        Notifier.dispatch('error', `There was a problem: ${error.response.data.errors[0].title}`);
      },
      onSuccess: (response) => {
        Notifier.dispatch(response.status, 'Invoice Rejection Reason Successfully Created');
      },
    },
  });

export const useUpdateInvoiceRejectionReason = () => useUpdateRecord('invoice_rejection_reason', {
    mutationConfig: {
      onError: (error) => {
        Notifier.dispatch('error', `There was a problem: ${error.response.data.errors[0].title}`);
      },
      onSuccess: (response) => {
        Notifier.dispatch(response.status, 'Invoice Rejection Reason Successfully Updated');
      },
    },
  });

export default useFindInvoiceRejectionReasonsByFeeScheduleId;
