import { useFind, useFindRecord } from 'src/api/APIHooks';

const useSccRequiredInsurancePlan = (
  requiredInsurancePlanId,
  personId,
  requiredHealthPlans,
) => {
    const { data, isLoading } = useFindRecord(
      'insurance',
      requiredInsurancePlanId,
      {
        queryConfig: {
          enabled: !!requiredInsurancePlanId,
          placeholderData: undefined,
        },
      },
    );

  const { data: useFindData, isLoading: isUseFindLoading } = useFind(
    'insurance',
    {
      person: personId,
      state: 'active',
      plan: requiredHealthPlans?.map((el) => el.id).join(),
    },
    {
      queryConfig: {
        enabled: !!personId && !requiredInsurancePlanId && !!requiredHealthPlans?.length,
        placeholderData: undefined,
      },
    },
  );

  const insurances = useFindData?.data?.data ?? [];
  // insurances sorted based on descending order of their updated_at date
  const sortedInsurancePlanArray =
    !isUseFindLoading &&
    insurances.sort((a, b) => (new Date(b.updated_at).valueOf() - (new Date(a.updated_at).valueOf())));

  if (requiredInsurancePlanId) {
    return {
      isLoading,
      primaryInsurance: data?.data?.data ?? {},
    };
  }
  return {
    isLoading: isUseFindLoading,
    primaryInsurance: sortedInsurancePlanArray?.[0],
  };
};

export default useSccRequiredInsurancePlan;
