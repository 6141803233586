import {
  useUpdateRecord,
  useInvalidateQueries,
} from 'src/api/APIHooks';
import { defaultMutationConfig } from 'src/pages/facesheet/_id/Relationships/Common/api/apiHookOptions';

export const useRevokeConsent = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useUpdateRecord('consent', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('consents');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export default useRevokeConsent;
