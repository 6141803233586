// Library Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button } from '@unite-us/ui';
import { validateReduxForm } from 'common/form';

import {
  programBasedSearchSelector,
} from 'src/common/utils/FeatureFlags/flags';

// Component Imports
import { OverlaySpinner } from 'common/spinners';
import EditReferralDetails from 'src/components/Referrals/ReferralFormFields/EditReferralDetails';

// Constant imports
import { DRAFT_REFERRAL_EDIT_FORM } from 'src/components/Referrals/constants';

// Util Imports
import { isReferredWithinNetwork } from 'src/components/Referrals/utils';
import { newReferralFormFields } from 'src/components/Referrals/utils/form';
import {
  formatGroupsAndPrograms,
  getGroupAndProgramInitialValues,
} from 'src/components/Referrals/ReferralGroupsPrograms/utils';
import { fetchServiceCase, updateDraftCase } from 'actions/Case/Contact/Group';
import {
  validateInNetworkGroupFields,
} from 'src/components/Referrals/ReferralServicesForm/utils/errorHandling';
import { getEmployeeNetworks } from 'src/components/Employee/employeeGetters';
import { useSelectedPrograms } from '@unite-us/app-search';

const SearchSelectedProgramsWrapper = ({ children }) => {
  const selectedProgramsContext = useSelectedPrograms();
  return React.cloneElement(children, { selectedProgramsContext });
};

export class DetailNetworkForDraftReferralForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitDisabled: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    const { referral, selectedInitialValues } = this.props;
    const referredByNetworkId = _.get(referral, 'network.id');
    const referredToNetworkId = _.get(referral, 'referred_to_network.id');

    const initialReferredToNetwork = _.isEqual(referredByNetworkId, referredToNetworkId) ? null : referredToNetworkId;

    const initialValues = _.uuCompactArrayOrObject({
      auto_recallable: false,
      notes: _.get(referral, 'description'),
      refer_to_outside_network: !isReferredWithinNetwork(referral),
      referred_by_network: {
        id: referredByNetworkId,
      },
      referred_to_network: {
        id: initialReferredToNetwork,
      },
      service_type: _.get(referral, 'service_type'),
      selected: selectedInitialValues,
    });

    this.props.initializeForm(initialValues);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.modalIsOpen && !nextProps.modalIsOpen) {
      this.onClose();
    }
  }

  onSubmit(values) {
    const {
      groupId, fields, invalidateCaseReferrals, networks, referral, isProgramBasedSearch,
    } = this.props;
    const selectedGroups = _.get(values, 'selected', []);
    const contactId = _.get(referral, 'person.id');

    const formValues = {
      auto_recallable: selectedGroups.length > 1 && values.auto_recallable ?
        'true' :
        'false',
      referred_by_network_id: _.wget(values, 'network.id'),
      referred_to_groups_draft: formatGroupsAndPrograms(values.selected),
      referred_to_network_id: values.refer_to_outside_network ?
        _.wget(values, 'referred_to_network.id') :
        _.wget(values, 'referred_by_network.id'),
      service_type: _.get(values, 'service_type.id', ''),
    };
    const requestData = {
      referral: { ...formValues },
    };

    return validateInNetworkGroupFields({
      allowEmptyGroups: true,
      networks,
      services: [fields],
    }).then(() => {
      this.props.updateDraftCase(groupId, contactId, referral.id, requestData, isProgramBasedSearch)
        .then(async () => {
          invalidateCaseReferrals();
          await this.props.fetchServiceCase(groupId, contactId, referral.id);
          this.props.closeModal();
        });
    });
  }

  // Instead of resetting the form, we are initializing the form with the initialValue Parameters
  // This is due to groups/programs being removed from the field when interaction
  onCancel() {
    const { referral, selectedInitialValues } = this.props;
    const referredByNetworkId = _.get(referral, 'network.id');
    const referredToNetworkId = _.get(referral, 'referred_to_network.id');

    const initialReferredToNetwork = _.isEqual(referredByNetworkId, referredToNetworkId) ? null : referredToNetworkId;

    const initialValues = _.uuCompactArrayOrObject({
      auto_recallable: false,
      notes: _.get(referral, 'description'),
      refer_to_outside_network: !isReferredWithinNetwork(referral),
      referred_by_network: {
        id: referredByNetworkId,
      },
      referred_to_network: {
        id: initialReferredToNetwork,
      },
      service_type: _.get(referral, 'service_type'),
      selected: selectedInitialValues,
    });

    this.props.initializeForm(initialValues);

    this.props.closeModal();
  }

  onClose() {
    const { referral, selectedInitialValues } = this.props;

    const referredByNetworkId = _.get(referral, 'network.id');
    const initialValues = _.uuCompactArrayOrObject({
      auto_recallable: false,
      notes: _.get(referral, 'description'),
      refer_to_outside_network: !isReferredWithinNetwork(referral),
      referred_by_network: {
        id: referredByNetworkId,
      },
      service_type: _.get(referral, 'service_type'),
      selected: selectedInitialValues,
    });

    this.props.initializeForm(initialValues);

    this.props.closeModal();
  }

  render() {
    const {
      fields,
      groupId,
      handleSubmit,
      networks,
      registerField,
      submit,
      submitting,
      untouch,
      touch,
      referral,
      modalIsOpen,
      width,
    } = this.props;
    const { submitDisabled } = this.state;

    return (
      <form className="detail-network-for-draft-referral-form content-with-actions">
        <OverlaySpinner text="Saving..." show={submitting} />

        <div className="content-container">
          {
            modalIsOpen && (
              <SearchSelectedProgramsWrapper>
                <EditReferralDetails
                  allowEmptyGroups
                  fields={fields}
                  form={DRAFT_REFERRAL_EDIT_FORM}
                  groupId={groupId}
                  networks={networks}
                  referral={referral}
                  registerField={registerField}
                  submit={submit}
                  untouch={untouch}
                  width={width}
                  touch={touch}
                  setHasSensitiveErrors={
                    (disabled) => {
                      if (submitDisabled !== disabled) {
                        this.setState({ submitDisabled: disabled });
                      }
                    }
                  }
                />
              </SearchSelectedProgramsWrapper>
            )
          }
        </div>

        <div className="actions">
          <span className="action-item">
            <Button
              id="edit-draft-referral-cancel-btn"
              label="Cancel"
              onClick={this.onCancel}
              disabled={submitting}
            />
          </span>
          <span className="action-item">
            <Button
              id="edit-draft-referral-submit-btn"
              onClick={handleSubmit(this.onSubmit)}
              primary
              disabled={submitting || submitDisabled}
              label="Save"
            />
          </span>
        </div>
      </form>
    );
  }
}

DetailNetworkForDraftReferralForm.propTypes = {
  isProgramBasedSearch: PropTypes.bool,
  closeModal: PropTypes.func,
  fetchServiceCase: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalidateCaseReferrals: PropTypes.func.isRequired,
  networks: PropTypes.array.isRequired,
  referral: PropTypes.shape({
    service_type: PropTypes.object.isRequired,
    referred_by_network: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    referred_to_network: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  registerField: PropTypes.func.isRequired,
  submit: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  untouch: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  initializeForm: PropTypes.func.isRequired,
  selectedInitialValues: PropTypes.arrayOf(PropTypes.shape({
    group: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    program: PropTypes.shape({
      id: PropTypes.string,
    }),
  })),
  updateDraftCase: PropTypes.func.isRequired,
  width: PropTypes.number,
};

DetailNetworkForDraftReferralForm.defaultProps = {
  isProgramBasedSearch: false,
  closeModal: () => {},
  submit: () => {},
  width: undefined,
  selectedInitialValues: [],
};

function mapStateToProps(state, ownProps) {
  const groupId = state.session.groupId;
  const { referral } = ownProps;
  const networks = getEmployeeNetworks({ state });
  const selectedGroups = _.get(referral, 'referred_to_groups_draft', []);
  const referredByNetworkId = _.get(referral, 'network.id');
  const referredToNetworkId = _.get(referral, 'referred_to_network.id');
  const selectedInitialValues = getGroupAndProgramInitialValues(selectedGroups);

  const initialValues = _.uuCompactArrayOrObject({
    auto_recallable: false,
    notes: _.get(referral, 'description'),
    refer_to_outside_network: !isReferredWithinNetwork(referral),
    referred_by_network: {
      id: referredByNetworkId,
    },
    referred_to_network: {
      id: _.isEqual(referredByNetworkId, referredToNetworkId) ?
        null :
        referredToNetworkId,
    },
    service_type: _.get(referral, 'service_type'),
    selected: selectedInitialValues,
  });

  const isProgramBasedSearch = programBasedSearchSelector(state);

  return {
    groupId,
    initialValues,
    networks,
    selectedInitialValues,
    isProgramBasedSearch,
  };
}

const fields = newReferralFormFields();
export default validateReduxForm({
  form: DRAFT_REFERRAL_EDIT_FORM,
  fields,
}, mapStateToProps, {
  fetchServiceCase,
  updateDraftCase,
})(DetailNetworkForDraftReferralForm);
