import { isNil, isString } from 'lodash';
import moment from 'moment';
var momentizeValue = function momentizeValue(value, format, isUtc) {
  if (isNil(value) || value === '') {
    return null;
  }
  var date = null;
  if (/^[-]?\d+$/.test(value)) {
    date = moment.unix(value);
  } else if (isString(value) && !(value instanceof Date)) {
    date = moment(value, format || 'MM/DD/YYYY');
  } else {
    date = moment(value);
  }
  if (isUtc) {
    return date.utc();
  }
  return date;
};
export default momentizeValue;