import { chain, get } from 'lodash';

function getPermittedNetworks(scopes, networkId) {
  return chain(scopes)
    .filter((scope) => get(scope, 'network.id', '') === networkId)
    .map('permitted_network')
    .value();
}

export default getPermittedNetworks;
