import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ChartPie } from 'common/charts';
import { fetchMilitaryReports } from 'actions/Report/Contact/Military';
import _ from 'lodash';
import sortByTitleNoResponseLast from '../../utils/sortByTitleNoResponseLast';

export default class MilitaryDischarge extends Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.state = {
      discharge: [],
      title: '',
    };
  }

  componentDidMount() {
    this.fetchData(this.props.filters);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.filters, nextProps.filters)) {
      this.fetchData(nextProps.filters);
    }
  }

  fetchData(filters) {
    const { scope, id } = this.props;
    fetchMilitaryReports(scope, id, 'by-military-discharge', filters)
      .then((response) => {
        this.setState({
          discharge: sortByTitleNoResponseLast(_.get(response, 'data.values', [])),
          title: _.get(response, 'data.title', ''),
        });
        this.props.loaded('discharge');
      });
  }

  render() {
    if (_.isEmpty(this.state.discharge)) {
      return null;
    }
    return (
      <ChartPie
        data={this.state.discharge}
        title={this.state.title}
        x={(d) => d.title}
        y={(d) => d.total}
        style={{ height: '350px' }}
        valueFormat="d"
        twoColsLegend={false}
      />
    );
  }
}

MilitaryDischarge.propTypes = {
  filters: PropTypes.shape({
    end_date: PropTypes.string,
    network_ids: PropTypes.array,
    start_date: PropTypes.string,
  }),
  loaded: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
};
