import { get } from 'lodash';

const isOONCaseUser = (user, providerId) => {
  if (!user || !providerId) { return false; }
  const userProviders = get(user, 'groups', []);
  const provider = userProviders.find((g) => g.group.id === providerId) || [];
  const userRoles = get(provider, 'roles', []);

  const userHasRoles = userRoles.find((r) => (
    r.role_type === 'primary' && (
      (r.role_key === 'org_admin') ||
      (r.role_key === 'referrals_user') ||
      (r.role_key === 'referrals_admin') ||
      (r.role_key === 'oversight')
    )
  ));
  return Boolean(userHasRoles);
};

export default isOONCaseUser;
