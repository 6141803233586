import { join, concat, slice } from 'lodash';
var maskValue = function maskValue(value, format) {
  if (!value) {
    return '';
  }
  var reg = /\W/g;
  var newValue = value.replace(reg, '');
  var res = reg.exec(format);
  while (res !== null) {
    if (value.length > res.index) {
      newValue = join(concat(slice(newValue, 0, res.index), res, slice(newValue, res.index)), '');
    }
    res = reg.exec(format);
  }
  return newValue;
};
export default maskValue;