import React, { useState } from 'react';
import moment from 'moment';
import {
  Badge,
  Icon,
  Button,
} from '@unite-us/ui';
import { Filter } from '@unite-us/client-utils';
import {
  ColumnHeader,
  SortableColumnHeader,
  DataCell,
  DataRow,
  HeaderRow,
  Table,
  TableBody,
} from 'src/common/tables/V2';
import {
  useFindFeeScheduleByName,
} from 'src/components/Backoffice/api/hooks/v1/feeScheduleHooks';
import { browserHistory } from 'common/utils/browserHistory';
import ScrollToElement from 'src/common/utils/Form/ScrollToElement';
import { Spinner } from 'common/spinners';
import Pagination from 'src/common/Pagination/Pagination';
import { theme } from 'src/../tailwind.config';
import EmptyTable from 'src/common/tables/EmptyTable/EmptyTable';
import SearchBar from 'src/common/SearchBar/SearchBar';
import { DEFAULT_PAGE_SIZE } from 'src/components/Backoffice/backofficeUtils';
import { useFeeScheduleStatusContext } from './components/FeeScheduleStatusContextProvider';
import './stylesheets/FeeSchedules.scss';

const backgroundColor = (feeSchedule) => {
  if (moment(new Date()).startOf('day').isAfter(moment(feeSchedule.ends_at))) {
    return theme.extend.colors['medium-yellow'];
  }

  switch (feeSchedule.state) {
    case 'active':
      return theme.extend.colors.green;
    case 'inactive':
      return theme.extend.colors.red;
    case 'archived':
      return theme.extend.colors['medium-fill-blue'];
    default:
      return theme.extend.colors['medium-yellow'];
  }
};

const addFeeSchedule = () => {
  browserHistory.push({ pathname: '/backoffice/fee_schedules/new' });
};

const viewFeeSchedule = (feeSchedule) => () => {
  browserHistory.push({ pathname: `/backoffice/fee_schedules/${feeSchedule.id}` });
};

export const FeeSchedules = () => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const { feeScheduleStatus, setFeeScheduleStatus } = useFeeScheduleStatusContext();
  const [search, setSearch] = useState('');
  const [{ sortBy, sortDirection }, setSort] = useState({ sortBy: 'ends_at', sortDirection: 'asc' });
  const { data: feeSchedulesResponse, isFetching } = useFindFeeScheduleByName(
    search,
    feeScheduleStatus,
    {
      page: {
        size: pageSize,
        number: pageNumber,
      },
      sort: sortDirection === 'asc' ? sortBy : `-${sortBy}`,
    },
  );
  const { data: feeSchedules, paging } = feeSchedulesResponse.data;

  const ScrollToElementEvent = () => {
    ScrollToElement(document.getElementById('TopOfPayers'));
  };

  const searchFeeScheduleByName = (data) => {
    setPageNumber(1);
    setSearch(data.search);
  };

  const statusFilterOptions = [
    { value: 'active', label: 'Active', initial: feeScheduleStatus === 'active' },
    { value: 'inactive', label: 'Inactive', initial: feeScheduleStatus === 'inactive' },
    { value: 'archived', label: 'Archived', initial: feeScheduleStatus === 'archived' },
  ];

  return (
    <>
      <div data-testid="fs-header" className="flex px-8 pt-12">
        <div className="w-9/12 flex flex-row relative">
          <h1 className="pt-1 pb-2 font-bold text-brand-blue">Fee Schedules</h1>
          <SearchBar
            onSubmit={searchFeeScheduleByName}
            dataTestId="fs-search-bar"
            inputClassName="md:w-2/5"
          />
        </div>
        <div className="w-3/12 flex justify-end">
          <Button
            id="add-form-button"
            iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="V2Add" />}
            className={
              'py-0 px-4 text-sm text-action-blue rounded border solid border-dark-fill-blue bg-white normal-case'
            }
            label="Add Fee Schedule"
            data-testid="add-fs-btn"
            onClick={() => addFeeSchedule()}
          />
        </div>
      </div>
      <div className="flex">
        <Filter
          className="ml-8 mt-4 mb-2"
          name="Status"
          pluralName="Status"
          hideSelectAll
          isSingleOptionSelect
          options={statusFilterOptions}
          onFiltersChange={(activeFilters) => {
            setFeeScheduleStatus(activeFilters.toString());
            setPageNumber(1);
          }}
          dataTestId="fs-status-filter"
        />
      </div>
      { isFetching ? <Spinner /> : (
        <>
          <div data-testid="fee-schedules-table-container" className="p-8 pt-4">
            <Table
              id="feeSchedulesTable"
              className="bg-white rounded-t-lg border border-solid border-light-border-blue"
            >
              <HeaderRow>
                <ColumnHeader className="w-6/12">
                  Name
                </ColumnHeader>
                <ColumnHeader className="w-1/12">
                  Status
                </ColumnHeader>
                <ColumnHeader className="w-1/6">
                  Start Date
                </ColumnHeader>
                <SortableColumnHeader
                  colName="ends_at"
                  className="w-1/6"
                  label="End Date"
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  setSort={setSort}
                >
                  End Date
                </SortableColumnHeader>
              </HeaderRow>
              <TableBody>
                {feeSchedules.map((feeSchedule, index) => (
                  <DataRow
                    className="cursor-pointer hover:bg-light-fill-blue hover:bg-opacity-50"
                    dataTestId={`fee-schedule-table-row-${index}`}
                    key={`fee-schedule-row-${feeSchedule.id}`}
                    onClick={viewFeeSchedule(feeSchedule)}
                  >
                    <DataCell className="w-6/12">
                      {feeSchedule.name}
                    </DataCell>
                    <DataCell className="w-1/12">
                      <div>
                        <Badge
                          text={moment(new Date()).startOf('day').isAfter(moment(feeSchedule.ends_at)) ?
                            'Expired' :
                            feeSchedule.state}
                          style={{ textTransform: 'capitalize' }}
                          color={backgroundColor(feeSchedule)}
                        />
                      </div>
                    </DataCell>
                    <DataCell className="w-1/6">
                      {(feeSchedule.starts_at && moment(feeSchedule.starts_at).utc().format('LL')) || 'N/A'}
                    </DataCell>
                    <DataCell className="w-1/6">
                      {feeSchedule.ends_at && moment(new Date()).startOf('day').isAfter(moment(feeSchedule.ends_at)) ?
                      (
                        <div style={{ color: 'rgb(239 68 68)' }}>{moment(feeSchedule.ends_at)
                          .utc().format('LL')} (Expired)
                        </div>
                      ) :
                      moment(feeSchedule.ends_at).utc().format('LL') || 'N/A'}
                    </DataCell>
                  </DataRow>
              ))}
              </TableBody>
            </Table>
            {
              (paging.total_count === 0) && (
                <EmptyTable
                  className={'p-6 border border-t-0 border-solid border-light-border-blue'}
                  message={'No Results Found.'}
                />
              )
            }
          </div>
          <div data-testid="fee-schedule-pagination-container" className="p-6">
            <Pagination
              pageNumber={pageNumber}
              pageSize={pageSize}
              totalItemCount={paging.total_count}
              totalPageCount={paging.total_pages}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
              scrollPaginationElement={ScrollToElementEvent}
            />
          </div>
        </>
      )}
    </>
  );
};

export default FeeSchedules;
