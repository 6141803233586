import PropTypes from 'prop-types';
import React from 'react';
import { Divider } from '@unite-us/ui';
import DetailOutcome from 'common/display/SingleItemDetails/DetailOutcome';
import _ from 'lodash';

function DetailOutcomeWrapper(props) {
  const { item } = props;

  if ((!item.outcome && _.isEmpty(_.get(item, 'case.outcome'))) ||
  ((item.outcome && _.isEmpty(_.get(item, 'outcome'))))) {
    return null;
  }

  return (
    <div className="detail-outcome-wrapper">
      <DetailOutcome item={item} />
      <Divider className="mb-4 mt-4" />
    </div>
  );
}

DetailOutcomeWrapper.propTypes = {
  item: PropTypes.object,
};

DetailOutcomeWrapper.defaultProps = {
  item: {},
};

export default DetailOutcomeWrapper;
