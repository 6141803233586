function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { compact, find, groupBy, reduce, without } from 'lodash';
var PHONE_TYPE_SORT_ORDER = ['phone', 'fax', 'other'];
var sortPhoneNumbers = function sortPhoneNumbers(phone_numbers) {
  var sortOrder = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : PHONE_TYPE_SORT_ORDER;
  var primaryPhone = find(phone_numbers, {
    is_primary: true
  });
  var groupedPhones = groupBy(phone_numbers, 'phone_type');
  var sortedPhones = reduce(sortOrder, function (result, phoneType) {
    if (groupedPhones[phoneType]) {
      result.push.apply(result, _toConsumableArray(groupedPhones[phoneType]));
      return result;
    }
    return result;
  }, []);
  var phoneNumbers = compact([primaryPhone].concat(_toConsumableArray(without(sortedPhones, primaryPhone))));
  return phoneNumbers;
};
export default sortPhoneNumbers;