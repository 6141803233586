import { CLOSE_REFERRAL_MODAL_DIALOG } from '../../constants';
import canClose, { canArchive } from './canClose';

function close({
  user,
  referral,
  groupId,
  openModal,
  isCC,
  closeInboundReferralsEnabled,
}) {
  const canCloseAction = canClose({
    user,
    referral,
    groupId,
    isCC,
    closeInboundReferralsEnabled,
  });
  const canArchiveAction = canArchive({ user, referral, groupId });

  if (!canCloseAction && canArchiveAction) {
    return {
      label: 'Close Referral',
      value: 'closeReferral',
      className: 'take-action-close-referral',
      action: () => openModal(CLOSE_REFERRAL_MODAL_DIALOG),
    };
  }

  if (canCloseAction) {
    return {
      label: 'Close',
      value: 'closeReferral',
      className: 'take-action-close-referral',
      icon: 'CrossCircleO',
      description: 'Document the outcome.',
      action: () => openModal(CLOSE_REFERRAL_MODAL_DIALOG),
    };
  }

  return null;
}

export default close;
