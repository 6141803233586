import React, { Component } from 'react';
import { isEmpty, noop } from 'lodash';
import PropTypes from 'prop-types';
import {
  BaseCard,
  BaseCardBody,
} from '@unite-us/ui';
import { TABLEAU_BASE_URL, TABLEAU_DASHBOARD_URL } from 'src/config/env/env.config';
import { notify } from 'actions/Notifier';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { hasTableauDashboard } from 'common/utils/FeatureFlags/flags';
import { fetchLatestInsight } from 'actions/Latest/Downloads/Insights/Api';
import { fetchTicket } from 'actions/Bi/Auth/Api';
import { fetchUserInfo } from 'actions/User/Insights/Api';
import { fetchViews } from 'actions/Views/Insights/Api';
import TableauErrorPage from './TableauErrorPage';
import { stripContentUrl } from '../utils';
import './Tableau.scss';

export class Tableau extends Component {
  constructor(props) {
    super(props);

    const { data } = props;

    this.state = {
      error: data.error,
      initialized: !isEmpty(data.ticket),
      ticket: data.ticket,
      userId: data.userId,
      views: data.views,
    };

    this.initializeTableau = this.initializeTableau.bind(this);
    this.setError = this.setError.bind(this);
    this.updateInsights = this.updateInsights.bind(this);
  }

  componentDidMount() {
    const { initialized } = this.state;

    if (initialized) {
      this.initializeViz();
    } else {
      this.initializeTableau();
    }
  }

  setError(error) {
    this.setState({
      error: true,
    }, () => {
      this.props.notify({
        status: 'error',
        message: error.message,
      });
    });
  }

  initializeTableau() {
    const promises = Promise.all([
      fetchTicket(),
      fetchUserInfo(),
    ]);

    return promises.then((response) => {
      const { ticket } = response[0].data;
      const {
        user_info: {
          api_token,
          site_id,
          user_id,
          workbooks,
        },
      } = response[1].data;

      const viewParams = {
        api_token,
        site_id,
        workbook_id: workbooks[0].id,
      };

      fetchViews(viewParams).then((res) => {
        const { views } = res.data.response;

        // Disable workbook due to performance issue per https://uniteus.atlassian.net/browse/UU3-29125
        // const workbookView = {
        //   id: workbooks[0].id,
        //   name: WORKBOOK_DROPDOWN_NAME,
        // };
        // Update view state when workbook is implemented via views: [...views, workbookView],

        this.setState({
          ticket,
          userId: user_id,
          views,
        }, this.initializeViz);
      }).catch((error) => {
        this.setError(error);
      });
    }).catch((error) => {
      this.setError(error);
    });
  }

  initializeViz() {
    const { views, ticket } = this.state;
    const { useTableauDashboard } = this.props;
    const strippedView = stripContentUrl(views[0].content_url);
    const placeholder = document.getElementById('tableau-viz');

    const url = useTableauDashboard ?
      `${TABLEAU_BASE_URL}/trusted/${ticket}/views/${TABLEAU_DASHBOARD_URL}:linktarget=_self&:embed=yes&:toolbar=top&:showShareOptions=false` : /* eslint-disable-line max-len */
      `${TABLEAU_BASE_URL}/trusted/${ticket}/views/${strippedView}&:showShareOptions=false`;

    const options = {
      width: '100%',
      height: '850px',
      hideTabs: false,
      toolbarPosition: 'top',
    };

    const tab = new window.tableau.Viz(placeholder, url, options);
    return tab;
  }

  updateInsights() {
    const { initialized, userId } = this.state;

    if (initialized) {
      const params = { id: userId };

      fetchLatestInsight(params).then((response) => {
        const { data } = response;
        this.props.updateInsights(data.response);
      });
    }
  }

  render() {
    const {
      error,
    } = this.state;

    return (
      <BaseCard className="tableau-card">
        <BaseCardBody>
          <div
            className={classNames({
              tableau: true,
              'tableau--error': error,
            })}
          >
            {
              error && <TableauErrorPage onClick={this.initializeTableau} />
            }
            <div id="tableau-viz" />
          </div>
        </BaseCardBody>
      </BaseCard>
    );
  }
}

Tableau.propTypes = {
  data: PropTypes.shape({
    apiToken: PropTypes.string,
    error: PropTypes.bool,
    siteId: PropTypes.string,
    ticket: PropTypes.string,
    userId: PropTypes.string,
    views: PropTypes.array,
    workbooks: PropTypes.array,
  }),
  notify: PropTypes.func.isRequired,
  updateInsights: PropTypes.func,
  useTableauDashboard: PropTypes.bool.isRequired,
};

Tableau.defaultProps = {
  data: {
    apiToken: '',
    error: false,
    siteId: '',
    ticket: '',
    userId: '',
    views: [],
    workbooks: [],
  },
  updateInsights: noop,
};

function mapStateToProps(state) {
  return {
    useTableauDashboard: hasTableauDashboard(state),
  };
}

export default connect(mapStateToProps, {
  notify,
})(Tableau);
