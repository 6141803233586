import React from 'react';
import { wget, truncate } from 'lodash';
import PropTypes from 'prop-types';
import { phones as phonesUtils } from '@unite-us/client-utils';
import { findPrimaryOrFirst } from 'src/common/utils/utils';

const DISPLAY_NAME = 'care-coordinatoro-info';

const CareCoordinatorName = ({ fullName }) => (
  <div className={`${DISPLAY_NAME}-name`}>
    <span>{fullName}</span>
  </div>
);

CareCoordinatorName.propTypes = {
  fullName: PropTypes.string.isRequired,
};

const CareCoordinatorDetails = ({
  careCoordinator,
}) => {
  const fullName = wget(careCoordinator, 'full_name', '');
  const email = wget(careCoordinator, 'email', '');
  const phoneNumber = findPrimaryOrFirst(wget(careCoordinator, 'phone_numbers', []));
  const formattedPhoneNumber = phonesUtils.formatPhoneNumber(wget(phoneNumber, 'phone_number'));
  const providerName = wget(careCoordinator, 'provider.name', '');
  return (
    <div className="care-coordinator__info">
      <span>{fullName}</span>
      <div className="italic care-coordinator__provider">
        <span>{providerName}</span>
      </div>
      <div className="care-coordinator__email">
        <a href={`mailto:${email}`} title={email}>
          {truncate(email, { length: 40 })}
        </a>
      </div>
      <div className="care-coordinator__phone">
        {
          phoneNumber && (
            <a href={phonesUtils.getTelLink(phoneNumber)}>
              {formattedPhoneNumber}
            </a>
          )
        }
      </div>
    </div>
  );
};

CareCoordinatorDetails.propTypes = {
  careCoordinator: PropTypes.object.isRequired,
};

const CareCoordinatorInfo = ({
  careCoordinator, showDetails,
}) => {
  const { full_name = '' } = careCoordinator;
  return showDetails ? (
    <CareCoordinatorDetails careCoordinator={careCoordinator} />
  ) : (
    <CareCoordinatorName fullName={full_name} />
  );
};

CareCoordinatorInfo.propTypes = {
  careCoordinator: PropTypes.object.isRequired,
  showDetails: PropTypes.bool,
};

CareCoordinatorInfo.defaultProps = {
  showDetails: false,
};

export default CareCoordinatorInfo;
