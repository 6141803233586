import React from 'react';
import PropTypes from 'prop-types';
import ContactAddress from 'common/display/Address/components/ContactAddress';
import _ from 'lodash';

import { Icon } from '@unite-us/ui';
import { addresses as addressesUtils } from '@unite-us/client-utils';
import ContactDisplay from './ContactDisplay';
import iconStyles from '../utils/iconStyles';

function ContactAddressDisplay(props) {
  const { addresses } = props;

  if (_.isEmpty(addresses)) {
    return null;
  }

  const display = <ContactAddress address={addressesUtils.findMainAddress(addresses)} />;
  const icon = <Icon icon="HousingShelter" style={iconStyles} />;

  return (
    <div className="address">
      <ContactDisplay
        className="contact-display--aligned-top"
        display={display}
        icon={icon}
      />
    </div>
  );
}

ContactAddressDisplay.propTypes = {
  addresses: PropTypes.array.isRequired,
};

ContactAddressDisplay.defaultProps = {
  addresses: [],
};

export default ContactAddressDisplay;
