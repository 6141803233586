import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ASSESSMENTS } from 'common/utils/EventTracker/utils/eventConstants';
import SecureLink from 'src/common/utils/SecureLink';
import AssessmentStatus from 'src/components/Facesheet/Assessments/components/AssessmentStatus';
import callOrLog from 'src/common/utils/callOrLog';
import { tracker } from '@unite-us/client-utils';
import { get } from 'lodash';
import './stylesheets/detail-view.scss';

export class DetailAssessments extends Component {
  constructor(props) {
    super(props);

    this.onAssessmentClick = this.onAssessmentClick.bind(this);
  }

  onAssessmentClick(assessment, status) {
    const { detailObj } = this.props;
    const eventName = status === 'success' ?
      ASSESSMENTS.clickedFromDetailView :
      ASSESSMENTS.clickedByUnauthorizedUser;

    callOrLog(() => window.eventTracker(eventName, {
      ...tracker.getDetailObj(detailObj), assessment: get(assessment, 'form', {}),
    }));
  }

  render() {
    const {
      assessments,
      detailObj,
    } = this.props;

    const assessmentList = assessments.map((assessment = {}, i) => (
      <li className="detail-info__relationship-file" key={i}>
        <SecureLink
          securable={assessment}
          to={assessment.url}
          onLinkClick={(event) => this.onAssessmentClick(assessment, event.status)}
          forceCanView={get(detailObj, '_meta._type') === 'assistancerequest'}
        >
          {assessment.form.name}
        </SecureLink>

        <span className="detail-info__assessment-status">
          (<AssessmentStatus assessment={assessment} />)
        </span>
      </li>
    ));

    return (
      <div className="detail-info__relationship">
        <h5 className="detail-info__icon-label">Assessments</h5>
        <ul className="detail-info__relationship-files list-unstyled">
          {assessmentList}
        </ul>
      </div>
    );
  }
}

DetailAssessments.propTypes = {
  assessments: PropTypes.arrayOf(
    PropTypes.shape({
      form: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  detailObj: PropTypes.object.isRequired,
};

DetailAssessments.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default DetailAssessments;
