import { primaryFirstSort } from 'common/form/Profile/utils';

function primaryFirstEmailSort(phones = []) {
  return primaryFirstSort({
    arr: phones,
    sortByProperty: 'email_address',
  });
}

export default primaryFirstEmailSort;
