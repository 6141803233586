import { createElement } from 'react';
import { PropTypes } from 'prop-types';

const ConditionalTooltip = ({
  children,
  condition,
  text,
  direction,
  size,
  tag,
  className,
  'data-testid': dataTestId,
}) => (
  condition ?
    createElement(
      tag,
      {
        ...{
          [`data-tooltip-${direction}`]: true,
          [(size === 'small' ? 'data-tooltip' : `data-tooltip-${size}`)]: text,
          'data-testid': dataTestId,
          ...className && { className },
        },
      },
      children,
    ) : children
);

ConditionalTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  condition: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['left', 'right', 'bottom', 'bottom-right']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  tag: PropTypes.string,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
};

ConditionalTooltip.defaultProps = {
  direction: 'right',
  size: 'small',
  tag: 'strong',
  className: '',
  'data-testid': undefined,
};

export default ConditionalTooltip;
