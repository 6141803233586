import Notifier from 'common/helpers/Notifier';

export const noScreeningFormMessage = `
        A screening form has not been added to your organization or network.
        For assistance, please contact Unite Us using the chat bubble in the lower right corner of the screen.`;

function dispatchNoScreeningFormWarning() {
  const status = 'error';

  const dismissable = true;

  Notifier.handleSystemNotification(status, noScreeningFormMessage, dismissable);
}

export default dispatchNoScreeningFormWarning;
