import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Icon } from '@unite-us/ui';
import Email from 'common/display/Email';
import {
  findPrimaryOrFirst,
} from 'src/common/utils/utils';
import ContactDisplay from './ContactDisplay';
import iconStyles from '../utils/iconStyles';

export default function ContactEmailDisplay(props) {
  const { emails, clickable, shortDisplay } = props;

  if (_.isEmpty(emails)) {
    return null;
  }

  const display = (
    <Email
      item={findPrimaryOrFirst(emails)}
      noLink={!clickable}
      shortDisplay={shortDisplay}
    />
  );

  const icon = <Icon icon="IconEnvelope" style={iconStyles} />;

  return (
    <div className="email">
      <ContactDisplay display={display} icon={icon} />
    </div>
  );
}

ContactEmailDisplay.propTypes = {
  emails: PropTypes.array.isRequired,
  clickable: PropTypes.bool.isRequired,
  shortDisplay: PropTypes.bool.isRequired,
};

ContactEmailDisplay.defaultProps = {
  emails: [],
  clickable: true,
  shortDisplay: true,
};
