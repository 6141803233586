import { apiV4Default } from 'src/api/config';
import {
  CREATE_INSURANCE_ENTRY_ERROR,
  CREATE_INSURANCE_ENTRY_STARTED,
  CREATE_INSURANCE_ENTRY_SUCCESS,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import { isHttpError } from 'common/utils/Error';
import { buildMedicalInsuranceParams } from '../utils';

function createInsuranceEntry({
  groupId,
  contactId,
  values,
  type = 'defaultValue',
  showNotification = true,
}) {
  return (dispatch) => {
    dispatch({ type: CREATE_INSURANCE_ENTRY_STARTED });

    const paymentsFormParams = buildMedicalInsuranceParams({ groupId, values, contactId });

    const request = apiV4Default.post('/insurances', paymentsFormParams);

    function onSuccess(payload) {
      dispatch({
        type: CREATE_INSURANCE_ENTRY_SUCCESS,
        contactId,
        payload,
      });

      if (showNotification) {
        Notifier.dispatch(
          payload.status,
          'Insurance Added',
        );
      }

      return payload;
    }

    function onError(error) {
      dispatch({ type: CREATE_INSURANCE_ENTRY_ERROR });
      if (isHttpError(error)) {
        Notifier.dispatch(
          'error',
          `Error adding insurance for client; ${type === 'new' ?
            'refer to the Client Profile.' : 'please try again.'
          }`,
        );
      } else {
        Notifier.handleErrors(error);
      }
    }

    return request.then(onSuccess, onError);
  };
}

export default createInsuranceEntry;
