import { find } from 'lodash';
import { DROPZONE_INPUT_ID } from '../constants';

function setDropzoneInputId({ dropzoneId, inputName }) {
  const dropzone = document.getElementById(dropzoneId);
  const dropzoneInputs = dropzone && dropzone.getElementsByTagNameNS('http://www.w3.org/1999/xhtml', 'input');
  const dropzoneInput = find(dropzoneInputs, { name: inputName });
  if (dropzoneInput) {
    dropzoneInput.id = DROPZONE_INPUT_ID;
  }
}

export default setDropzoneInputId;
