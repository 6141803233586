import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useInvoiceDisputeWorkflow } from 'src/common/utils/FeatureFlags/flags';
import { NoInvoicesFoundCard } from '../components';
import InvoiceWorkqueue from './InvoiceWorkqueue';

const UnderDisputeInvoices = ({ showInvoiceDisputeWorkflow, children }) => {
  const bulkActions = ['IconDownloadV2', 'IconArchive'];

  return (
    <InvoiceWorkqueue
      disputed={showInvoiceDisputeWorkflow}
      bulkActions={bulkActions}
      useUnderDisputeInvoiceStatus={!showInvoiceDisputeWorkflow ? 'under_dispute' : null}
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <NoInvoicesFoundCard isFiltered={isFiltered} status="disputed" resetFilters={resetFilters} />
      )}
      path="in-dispute/"
    >
      {children}
    </InvoiceWorkqueue>
  );
};

UnderDisputeInvoices.propTypes = {
  children: PropTypes.node,
  showInvoiceDisputeWorkflow: PropTypes.bool,
};

UnderDisputeInvoices.defaultProps = {
  children: undefined,
  showInvoiceDisputeWorkflow: false,
};

function mapStateToProps(state) {
  return {
    showInvoiceDisputeWorkflow: useInvoiceDisputeWorkflow(state),
  };
}

export default connect(mapStateToProps)(UnderDisputeInvoices);
