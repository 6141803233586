import { useFind, useFindAll } from 'src/api/APIHooks';
import { ROLE_TYPES } from 'src/api/core/Roles/fetchRoles';
import { defaultQueryConfig } from './apiHookOptions';

export const useAllRoles = () => {
  const findAllRoles = useFindAll('role', {
    queryConfig: defaultQueryConfig,
  });
  const filteredFindAllRoles = {
    ...findAllRoles,
    roles: findAllRoles?.data,
    programRoles: findAllRoles?.data?.filter(
      (role) => role.role_type === ROLE_TYPES.PROGRAM,
    ),
    orgRoles: findAllRoles?.data?.filter(
      (role) => role.role_type === ROLE_TYPES.SYSTEM,
    ),
    restrictedRoles: findAllRoles?.data?.filter(
      (role) => role.role_type === ROLE_TYPES.RESTRICTED,
    ),
    primaryRoles: findAllRoles?.data?.filter(
      (role) => role.role_type === ROLE_TYPES.PRIMARY,
    ),
    featureRoles: findAllRoles?.data?.filter(
      (role) => role.role_type === ROLE_TYPES.FEATURE,
    ),
  };
  return filteredFindAllRoles;
};

export const useRoles = ({ roleIds }) => useFind(
    'role',
    {
      ids: roleIds,
    },
    {
      queryConfig: defaultQueryConfig,
    },
  );
