import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { FETCH_CONTACT_REFERRALS } from 'actions';
import Notifier from 'common/helpers/Notifier';
import { coreApi } from 'src/api/config';
import { get } from 'lodash';

const REQUESTED_STATES = [
  'sent',
  'in_review',
  'recalled',
  'declined',
  'accepted',
  'auto_recalled',
  'pending_authorization',
  'denied_authorization',
];

const fetchContactReferrals = (groupId, contactId, options = { page: 1 }) => (
  async (dispatch) => {
    const payload = await coreApi.query(
      'referral',
      {
        'case.person': contactId,
        ...(groupId ? { sending_provider: groupId } : null),
        state: REQUESTED_STATES.join(','),
      },
      {
        include: 'case_summary',
        ...(options ? {
          page: {
            number: options.page,
            size: options.size || 50,
          },
        } : null),
      },
    );
    if (!payload || !isHttpSuccess(payload.status)) {
      Notifier.handleErrors(payload);
      return payload;
    }
    const referrals = get(payload, 'data.data', []);

    await Promise.all([
      referrals.map((r) => (coreApi.populateRelationship('case', 'case', r))),
      coreApi.populateRelationship('case_summary.service', 'service', referrals),
      coreApi.populateRelationship('sending_provider', 'provider', referrals),
      coreApi.populateRelationship('receiving_provider', 'provider', referrals),
    ]);

    referrals.forEach((r) => {
      /* eslint-disable no-param-reassign */
      r.service_type = r.case_summary.service;
      r.referred_by_group = r.sending_provider;
      r.referred_to_group = r.receiving_provider;
      r.status = r.state;
    });

    dispatch({
      type: FETCH_CONTACT_REFERRALS,
      payload,
      id: contactId,
    });

    return payload;
  }
);

export default fetchContactReferrals;
