import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@unite-us/ui';
import { capitalize } from 'lodash';
import stateBgColor from 'src/common/utils/stateBgColor';
import { useFindDirectory } from 'src/components/Backoffice/api/hooks/prdApi/DirectoryHooks';
import { PRD_FE_DOMAIN } from 'src/config/env/env.config';
import moment from 'moment';

const PrdSubHeader = ({ directory_id }) => {
  const { data: prd } = useFindDirectory(directory_id);

  const prdStatus = () => {
    if (prd.current_configuration && !prd.has_draft_changes) {
      return 'published';
    } if (!prd.current_configuration) {
      return 'unpublished';
    }
      return 'draft changes';
  };

  const formattedDate = () => {
    if (!prd.updated_at) return '';
    return moment.utc(prd.updated_at).format('MM/DD/YYYY hh:mm a');
  };

  const domainUrl = () => (
    `${prd.domain}.${PRD_FE_DOMAIN}`
  );
  return (
    <div className="bg-white border-b border-solid border-dark-border-blue mb-0">
      <div className="px-8 pt-4">
        <div className="PRDSummary">
          <div className="flex flex-row justify-between">
            <div className="flex bg-white justify-start items-center flex-nowrap">
              <h1 className="pr-4 font-bold text-2xl leading-8">{prd.customer_name}</h1>
              <Badge
                className="flex flex-row justify-center px-10 py-2 flex-nowrap items-center"
                text={capitalize(prdStatus())}
                color={stateBgColor(prdStatus())}
              />
            </div>
          </div>
          <div className="mt-1 w-full py-4">
            <p className="pr-3 font-bold inline-block">DOMAIN URL: </p>
            <p className="pr-4 inline-block">{domainUrl()}</p>
            <p className="pr-4 inline-block">|</p>
            <p className="pr-3 font-bold inline-block">CUSTOMER CODE: </p>
            <p className="pr-4 inline-block">{prd.customer_code}</p>
            <p className="pr-3 font-bold inline-block">LAST SAVE: </p>
            <p className="inline-block">{formattedDate()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

PrdSubHeader.propTypes = {
  directory_id: PropTypes.string.isRequired,
};

export default PrdSubHeader;
