import React from 'react';
import './EmptyCase.scss';

const EmptyCase = () => (
  <div className="empty-case__empty col-md-12">
    <div className="empty-case__notification">
      <p className="empty-case__notification-text">NO CASES YET</p>
    </div>
  </div>
);

export default EmptyCase;
