function returnNoServiceTypeInProgramMessage({ action }) {
  const lastPartMessage = 'referrals for service types that are no longer associated with this program.';

  const message = {
    accept: `You can't accept ${lastPartMessage}`,
    holdForReview: `You can't hold for review ${lastPartMessage}`,
  };

  return message[action];
}

export default returnNoServiceTypeInProgramMessage;
