import { SEARCH_CONTACT } from 'actions';
import moment from 'moment';
import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { coreApi } from 'src/api/config';

const getAddresses = async (list) => {
  try {
    const addressIds = [];
    const newList = list.map((person) => {
      if (person.addresses.length) {
        const personAddressesIds = person.addresses.map((a) => a.id);
        addressIds.push(personAddressesIds);
      }
      return person;
    });

    if (addressIds.length) {
      const addressesResponse = await coreApi.query('address', { ids: addressIds });
      addressesResponse.data.data.forEach((addressItem) => {
        newList.forEach((person) => {
          if (person.id === addressItem.person.id) {
            person.addresses.push(addressItem);
          }
        });
      });
    }
    return newList;
  } catch (error) {
    return [];
  }
};

const searchContact = (groupId, contact, showErrors = false) => (async (dispatch) => {
  const coreResponse = await coreApi.query('person', {
    first_name: contact.first_name,
    last_name: contact.last_name,
    date_of_birth: moment.unix(contact.date_of_birth).utc().format('YYYY-MM-DD'),
  });

  if (!isHttpSuccess(coreResponse.status)) {
    if (showErrors) {
      return Notifier.handleErrors(coreResponse.status);
    }
  }

  const listPersonMatches = coreResponse.data.data;
  let list = await getAddresses(listPersonMatches);
  list = list.map((item) => ({
    item: {
      result: {
        ...item,
        date_of_birth: item.date_of_birth,
      },
    },
  }));

  const payload = {
    ...coreResponse,
    data: {
      ...coreResponse.data,
      data: list,
    },
  };

  dispatch({ type: SEARCH_CONTACT, payload });
  return payload;
}
);

export default searchContact;
