import _ from 'lodash';
import { serviceTypes as serviceTypeUtils } from '@unite-us/client-utils';
import { coordinationCenterNetwork } from 'common/utils/Group';
import { flattenServiceTypes } from './index';

export function removeAutoAddedServiceTypes(usedServiceTypes, allServiceTypes) {
  const result = _.reduce(usedServiceTypes, (acc, st) => {
    const autoAdded = serviceTypeUtils.isAutoAdded(
      st,
      usedServiceTypes,
      allServiceTypes,
      'id',
      _.has(st, 'children') ? 0 : 1,
      true,
      true,
    );
    if (autoAdded) {
      return acc;
    }
    return [...acc, st];
  }, []);
  return result;
}

export function concatEntitiesServiceTypes(entities, globalServiceTypes = [], keepParent = false) {
  return _.flatten(_.reduce(entities, (acc, ent) => {
    let flatServiceTypes = flattenServiceTypes(_.get(ent, 'service_types', []), keepParent);
    if (keepParent) {
      flatServiceTypes = removeAutoAddedServiceTypes(flatServiceTypes, globalServiceTypes);
    }
    return [...acc, ...flatServiceTypes];
  }, []));
}

export function omitMissingServiceTypes(allServiceTypes, usedServiceTypes) {
  return _.reduce(allServiceTypes, (acc, st) => {
    if (_.has(st, 'children') && !_.isEmpty(st.children)) {
      const filteredChildren = _.filter(st.children, (child) => (
        _.find(usedServiceTypes, (u) => u.id === child.id) !== undefined
      ));
      const parent = _.assign({}, st, { children: _.orderBy(filteredChildren, 'name') });
      if (filteredChildren.length <= 0) {
        if (_.find(usedServiceTypes, (ust) => ust.id === st.id)) {
          return [...acc, parent];
        }
        return acc;
      }
      return [...acc, parent];
    }
    if (_.find(usedServiceTypes, (ust) => ust.id === st.id)) {
      return [...acc, st];
    }
    return acc;
  }, []);
}

export function addParentToOptions(serviceTypes, usedServiceTypes) {
  return _.reduce(serviceTypes, (acc, st) => {
    if (_.has(st, 'children') && !_.isEmpty(st.children)) {
      if (_.find(usedServiceTypes, (ust) => ust.id === st.id)) {
        const children = [st, ...st.children];
        return [...acc, {
          ...st,
          id: 'disabled',
          code: 'disabled',
          children,
          serviceTypeId: st.id,
        }];
      }
    }
    return [...acc, st];
  }, []);
}

export function getNetWorkServiceTypes(network) {
  const serviceTypes = _.get(network, 'service_types', []);
  const networkServiceTypes = _.reduce(_.orderBy(serviceTypes, 'name'), (acc, st) => {
    if (_.has(st, 'children') && !_.isEmpty(st.children)) {
      const children = _.orderBy(st.children, 'name');
      return [...acc, _.assign({}, st, { children })];
    }
    return [...acc, st];
  }, []);

  return networkServiceTypes;
}

export default function buildServiceTypes(state) {
  const globalServiceTypes = _.get(state, 'session.globals.service_types', []);
  // CORE-WORK - Need to resolve network.service_types
  const networks = _.get(state, 'user.networks', []);
  const group = _.find(
    _.get(state, 'user.groups'),
    (g) => _.get(g, 'group.id', '') === _.get(state, 'session.groupId', 'NA'),
  );
  const allPrograms = _.get(group, 'programs', []);

  const networksServiceTypes = concatEntitiesServiceTypes(networks);
  const filteredNetworksST = omitMissingServiceTypes(globalServiceTypes, networksServiceTypes);
  const filteredNetworkSTWithParent = addParentToOptions(filteredNetworksST, networksServiceTypes);

  const programsServiceTypes = concatEntitiesServiceTypes(allPrograms, globalServiceTypes);
  const filteredProgramsST = omitMissingServiceTypes(globalServiceTypes, programsServiceTypes);
  const filteredProgramsSTWithParent = addParentToOptions(filteredProgramsST, programsServiceTypes);

  const allNetworksAndProgramsST = _.concat(networksServiceTypes, programsServiceTypes);
  const filteredAllST = omitMissingServiceTypes(globalServiceTypes, allNetworksAndProgramsST);
  const filteredAllSTWithParent = addParentToOptions(filteredAllST, allNetworksAndProgramsST);

  let network = {};
  if (_.get(state, 'session.isCoordinationGroup', false)) {
    // CORE-WORK - Need to resolve this with Service Type Schema
    network = coordinationCenterNetwork(networks, _.get(group, 'group.id'));
  } else {
    network = _.find(networks, (n) => _.get(n, 'id', '') === _.get(group, 'group.networks[0].id', 'NA'));
  }

  const programs = _.reduce(allPrograms, (acc, program) => {
    const programServiceTypes = concatEntitiesServiceTypes([program], globalServiceTypes);
    const filteredProgramST = omitMissingServiceTypes(globalServiceTypes, programServiceTypes);
    const filteredProgramSTWithParent = addParentToOptions(filteredProgramST, programServiceTypes);
    return _.merge(acc, { [program.id]: filteredProgramSTWithParent });
  }, {});

  return {
    allNetworks: filteredNetworkSTWithParent,
    allPrograms: filteredProgramsSTWithParent,
    allNetworksAndPrograms: filteredAllSTWithParent,
    network: getNetWorkServiceTypes(network),
    programs,
  };
}
