import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Icon } from '@unite-us/ui';

function VoiceMailOk({ isVoicemailOK, contactMethods }) {
  const isPhoneSelected = _.includes(contactMethods, 'phone');

  return (
    <div className="voicemail-ok">
      {
        isPhoneSelected && (
          <div className="voicemail-check">
            {
              isVoicemailOK ? (
                <div className="flex font-bold">
                  <Icon icon="IconCheckCircle" color="#1CCA21" data-testid="icon-voicemail-ok" />
                  <div className="ml-1">Okay to leave a voicemail.</div>
                </div>
              ) : (
                <div className="flex font-bold">
                  <Icon icon="IconTimesCircle" color="#CF2A2A" data-testid="icon-voicemail-not-ok" />
                  <div className="ml-1">Not okay to leave a voicemail.</div>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
}

VoiceMailOk.propTypes = {
  contactMethods: PropTypes.array.isRequired,
  isVoicemailOK: PropTypes.bool.isRequired,
};

export default VoiceMailOk;
