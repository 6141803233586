import { each, isEmpty } from 'lodash';

/**
 * Utility function to read document uploaded files
 * { object } docParams
 // Example
    docParams = {
      documents: [file1, file2, file3]
    }
 */
function checkUploads({ documents }) {
  return new Promise((resolve, reject) => {
    const completedDocuments = [];
    if (isEmpty(documents)) {
      resolve({ documents });
    }

    each(documents, (doc) => {
      const reader = new FileReader();

      reader.onload = () => {
        completedDocuments.push({
          document_data_uri: reader.result,
          title: doc.name,
        });

        if (documents.length === completedDocuments.length) {
          resolve({ documents: completedDocuments });
        }
      };

      reader.onerror = () => {
        reject({
          status: 'File load failed',
        });
      };
      reader.readAsDataURL(doc);
    });
  });
}

export default checkUploads;
