import {
  isReferralsUser,
  isReferralsAdmin,
  isOversightAdmin,
  isOrgAdmin
} from 'src/components/User/utils';

export const isReferralsUserAndAbove = (user, groupId) => (
  isReferralsUser(user, groupId) ||
  isReferralsAdmin(user, groupId) ||
  isOversightAdmin(user, groupId) ||
  isOrgAdmin(user, groupId)
);

export default isReferralsUserAndAbove;
