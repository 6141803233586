import isServiceTypeInProgram from 'src/components/Referrals/ReferralDetail/utils/isServiceTypeInProgram';
import returnNoServiceTypeInProgramMessage from 'src/components/Referrals/ReferralDetail/utils/returnNoServiceTypeInProgramMessage';
import { canAccept } from '@unite-us/app-create-referral';
import { ACCEPT_MODAL_DIALOG } from '../../constants';

function accept({
  user,
  isCC,
  groupId,
  openModal,
  referral,
}) {
  if (!canAccept({
    isCC,
    user,
    referral,
    groupId,
  })) {
    return null;
  }

  return {
    label: 'Accept',
    value: 'accept',
    className: 'take-action-accept',
    icon: 'Referral',
    description: isServiceTypeInProgram(referral) ?
      'Accept the referral to start working with the client.' :
      returnNoServiceTypeInProgramMessage({ action: 'accept' }),
    action: () => openModal(ACCEPT_MODAL_DIALOG),
    disabled: !isServiceTypeInProgram(referral),
  };
}

export default accept;
