import { useFind } from 'src/api/APIHooks';
import { isEmpty } from 'lodash';

const useInvoiceDisputeReasonOptions = (feeScheduleIds = []) => {
  const { isFetching, data: response } = useFind(
    'invoice_dispute_reason',
    {
      fee_schedule: feeScheduleIds.join(),
    },
    {
      queryConfig: {
        enabled: !isEmpty(feeScheduleIds),
      },
    },
  );

  return isFetching ? [] : response.data.data;
};

export default useInvoiceDisputeReasonOptions;
