import Notifier from 'common/helpers/Notifier';
import updateUserCore from 'src/api/auth/User/updateUser';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { coreApi } from 'src/api/config';

const onError = (dispatch, error) => {
  Notifier.handleErrors(error);
  return false;
};

export default function updateCurrentUserEmail({
  userId,
  employeeId,
  email,
}) {
  return async (dispatch) => {
    try {
      const attributes = { email };
      const [updateUserRes, updateEmpRes] = await Promise.all([
        updateUserCore({ userId, attributes })(dispatch),
        coreApi.updateRecord('employee', employeeId, { email }),
      ]);
      if (!updateUserRes) { return onError(dispatch, updateUserRes); }
      if (!updateEmpRes || !isHttpSuccess(updateEmpRes.status)) {
        return onError(dispatch, updateEmpRes);
      }
      Notifier.dispatch(updateEmpRes.status, 'User Successfully Updated');
      return updateEmpRes;
    } catch (error) {
      return onError(dispatch, error);
    }
  };
}
