import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _, { isFunction, noop } from 'lodash';
import { CheckBoxField, InputField, SelectField } from '@unite-us/ui';
import { InfoPopover, LocationAddressField } from '@unite-us/client-utils';
import { validations } from '@unite-us/app-components';
import {
  AddressRemoveButton,
  AddressFieldConditionalDisplay,
} from './components';

import {
  afValidations,
  requireAddressType,
} from './utils';

import {
  DISPLAY_CITY_STATE,
  DISPLAY_FULL,
  DISPLAY_POSTAL_CODE,
  DISPLAY_STREET_CITY_STATE_POSTAL_CODE,
  DISPLAY_ADDRESS_TYPE,
  DISPLAY_MAILING_ADDRESS,
  PRIMARY_ADDRESS_TOOLTIP,
} from './constants';

import './AddressField.scss';

export class AddressField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRemove: false,
      location: {
        line_1: _.get(props, 'field.line_1.value'),
        city: _.get(props, 'field.city.value'),
        state: _.get(props, 'field.state.value'),
        postal_code: _.get(props, 'field.postal_code.value'),
      },
    };
    this.confirmRemove = this.confirmRemove.bind(this);
    this.toggleRemove = this.toggleRemove.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onPrimaryChange = this.onPrimaryChange.bind(this);
  }

  componentDidMount() {
    if (!requireAddressType(this.props.displayFieldSet) && !_.isUndefined(this.props.field.address_type)) {
      this.props.field.address_type.onChange('home');
    }
  }

  onPrimaryChange() {
    this.props.onPrimaryChange(this.props.field);
  }

  onChange(e, attribute) {
    const value = e.target ? e.target.value : e;
    this.setState({
      location: {
        ...this.state.location,
        [attribute]: value,
      },
    }, () => {
      if (isFunction(this.props.clearValidation)) {
        this.props.clearValidation();
      }
    });
  }

  toggleRemove() {
    this.setState({ showRemove: !this.state.showRemove });
  }

  confirmRemove() {
    this.props.remove();
    this.toggleRemove();
  }

  render() {
    const {
      addressPath,
      addressTypeahead,
      addressTypes,
      displayFieldSet,
      field,
      forceRequired,
      id,
      inClientCreation,
      index,
      inline,
      inputStyle,
      isValidAddress,
      label,
      labelStyle,
      multi,
      registerField,
      remove,
      requireAddressLine1,
      usStates,
    } = this.props;

    const groupLabelClass = () => classNames('group-label', {
      hidden: label === undefined,
      inline,
    });

    const style = {
      label: labelStyle,
      input: inputStyle,
    };

    const addressTypeaheadComponent = addressTypeahead ? (
      <LocationAddressField
        {...field}
        id={`${id}-address-typeahead`}
        label="Address"
        google={window.google}
        fieldNamePath={null}
        required={false}
      />
    ) : null;

    return (
      <div className="address-field">
        { inClientCreation && index !== 0 && (<div className="payments-insurance-client-divider" />) }

        <div className="address-field__title row">
          <div className="col-xs-12">
            <strong className={groupLabelClass()} style={labelStyle}>
              {label}
            </strong>
          </div>
        </div>
        {(isValidAddress === false) && (
          <div className="col-xs-9 address-field__error" tabIndex="0">
            <p>*Invalid Address. Please make sure you enter a valid address.</p>
          </div>
        )}
        <div className="row">
          <AddressFieldConditionalDisplay selectedFieldSet={displayFieldSet} validDisplayTypes={DISPLAY_ADDRESS_TYPE}>
            <div className="col-xs-12 col-sm-6">
              <SelectField
                searchEnabled={false}
                className="address-field-type"
                field={field.address_type}
                id={`${id}-type`}
                inline={inline}
                label="Address Type"
                labelKey="display_name"
                options={addressTypes}
                placeholder="Select type"
                ref={registerField}
                style={style}
                validations={{
                  func: (value, message, args, values) => {
                    const currentValues = _.get(values, addressPath, [])[index];
                    return afValidations.isRequiredAddressType({
                        forceRequired,
                        values: currentValues,
                        displayFieldSet,
                        value,
                      });
                  },
                }}
                valueKey="value"
              />
            </div>
          </AddressFieldConditionalDisplay>
          <div className="flex items-center justify-end col-xs-12 col-sm-6">
            <AddressFieldConditionalDisplay selectedFieldSet={displayFieldSet} validDisplayTypes={DISPLAY_FULL}>
              <div className="flex flex-1">
                <CheckBoxField
                  field={field.is_primary}
                  id={`${id}-primary`}
                  inline
                  label="Primary"
                  onChange={this.onPrimaryChange}
                  showHint={false}
                  showError={false}
                  className="w-auto"
                />
                <InfoPopover
                  id={id}
                  className="is_primary-info-icon"
                  placement="bottom-start"
                >
                  <div className="is_primary-info-icon__popover-content">
                    {PRIMARY_ADDRESS_TOOLTIP}
                  </div>
                </InfoPopover>
              </div>
            </AddressFieldConditionalDisplay>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            {addressTypeaheadComponent}
            <AddressFieldConditionalDisplay
              selectedFieldSet={displayFieldSet}
              validDisplayTypes={addressTypeahead ? [] : DISPLAY_STREET_CITY_STATE_POSTAL_CODE}
            >
              <InputField
                autoComplete="no"
                field={field.line_1}
                id={`${id}-line1`}
                inline={inline}
                label="Address Line 1"
                ref={registerField}
                style={style}
                validations={requireAddressLine1 ? validations.isRequired : _.noop}
                onChange={(e) => this.onChange(e, 'line_1')}
              />
            </AddressFieldConditionalDisplay>

            <AddressFieldConditionalDisplay
              selectedFieldSet={displayFieldSet}
              validDisplayTypes={DISPLAY_STREET_CITY_STATE_POSTAL_CODE}
            >
              <InputField
                autoComplete="no"
                field={field.line_2}
                id={`${id}-line2`}
                inline={inline}
                label="Address Line 2"
                style={style}
              />
            </AddressFieldConditionalDisplay>

            <AddressFieldConditionalDisplay
              selectedFieldSet={displayFieldSet}
              validDisplayTypes={addressTypeahead ? [] : DISPLAY_CITY_STATE}
            >
              <InputField
                autoComplete="no"
                field={field.city}
                id={`${id}-city`}
                inline={inline}
                label="City"
                placeholder="City"
                ref={registerField}
                style={style}
                validations={{
                  func: (value, message, args, values) => {
                    const currentValues = _.get(values, addressPath, [])[index];
                    return !currentValues &&
                      afValidations.isRequiredCity({ forceRequired, values: currentValues, value });
                  },
                }}
                onChange={(e) => this.onChange(e, 'city')}
              />
            </AddressFieldConditionalDisplay>
          </div>
        </div>

        <div className="row">
          <AddressFieldConditionalDisplay
            selectedFieldSet={displayFieldSet}
            validDisplayTypes={addressTypeahead ? [] : DISPLAY_CITY_STATE}
          >
            <div className="col-xs-12 col-sm-6">
              <SelectField
                className="address-field-state"
                field={field.state}
                hideLabel={inline}
                id={`${id}-state`}
                inline={inline}
                label="State"
                labelKey="display_name"
                options={usStates}
                placeholder="Select state"
                ref={registerField}
                style={style}
                validations={{
                  func: (value, message, args, values) => {
                    const currentValues = _.get(values, addressPath, [])[index];
                    return !currentValues &&
                      afValidations.isRequiredState({ forceRequired, values: currentValues, value });
                  },
                }}
                valueKey="value"
                onChange={(e) => this.onChange(e, 'state')}
              />
            </div>
          </AddressFieldConditionalDisplay>

          <AddressFieldConditionalDisplay
            selectedFieldSet={displayFieldSet}
            validDisplayTypes={addressTypeahead ? [] : DISPLAY_POSTAL_CODE}
          >
            <div className="col-xs-12 col-sm-6">
              <InputField
                autoComplete="no"
                field={field.postal_code}
                hideLabel={inline}
                id={`${id}-postal-code`}
                inline={inline}
                label="Zip Code"
                maxLength={5}
                placeholder="Zip Code"
                ref={registerField}
                style={style}
                validations={[
                  {
                    func: (value, message, args, values) => {
                      const currentValues = _.get(values, addressPath, [])[index];
                      return !currentValues &&
                        afValidations.isRequiredZipCode({ forceRequired, values: currentValues, value });
                    },
                  },
                  {
                    func: (value, message) => (
                      afValidations.zipCodeNumberLength({ value, message })
                    ),
                    message: 'Zip code must be 5 digits',
                  },
                ]}
                onChange={(e) => this.onChange(e, 'postal_code')}
              />
            </div>
          </AddressFieldConditionalDisplay>
        </div>

        <div className="row">
          <AddressFieldConditionalDisplay
            selectedFieldSet={displayFieldSet}
            validDisplayTypes={DISPLAY_MAILING_ADDRESS}
          >
            <div className="col-xs-6">
              <CheckBoxField
                field={field.is_mailing_address}
                id={`${id}-is-mailing`}
                label="Mailing address"
                style={{ marginTop: '6px' }}
              />
            </div>
          </AddressFieldConditionalDisplay>
        </div>

        <div className="row justify-end mx-0">
          {((index !== 0 && inClientCreation) || (!inClientCreation)) && (
            <div className="mb-4">
              <AddressRemoveButton
                confirmRemove={this.confirmRemove}
                displayText={'Delete Address'}
                field={field}
                iconSize={18}
                multi={multi}
                remove={remove}
                showRemove={this.state.showRemove}
                toggleRemove={this.toggleRemove}
                trashColor="#4571BA"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

// TODO: Fix these prop type warnings. Too scared to fix now. -BD

AddressField.propTypes = {
  addressPath: PropTypes.string,
  addressTypes: PropTypes.array.isRequired,
  displayFieldSet: PropTypes.string,
  field: PropTypes.object.isRequired,
  // eslint-disable-next-line react/require-default-props
  forceRequired: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  id: PropTypes.string,
  index: PropTypes.number.isRequired,
  inClientCreation: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  inline: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  inputStyle: PropTypes.object.isRequired,
  // eslint-disable-next-line react/require-default-props
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  // eslint-disable-next-line react/require-default-props
  labelStyle: PropTypes.object.isRequired,
  // eslint-disable-next-line react/require-default-props
  multi: PropTypes.bool.isRequired,
  requireAddressLine1: PropTypes.bool,
  registerField: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  remove: PropTypes.func,
  usStates: PropTypes.array.isRequired,
  // eslint-disable-next-line react/require-default-props
  isValidAddress: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  clearValidation: PropTypes.func,
  onPrimaryChange: PropTypes.func,
  addressTypeahead: PropTypes.bool,
};

AddressField.defaultProps = {
  addressPath: 'addresses',
  displayFieldSet: 'full',
  // eslint-disable-next-line react/default-props-match-prop-types
  forceRequired: false,
  // eslint-disable-next-line react/default-props-match-prop-types
  inline: true,
  inClientCreation: false,
  // eslint-disable-next-line react/default-props-match-prop-types
  inputStyle: {},
  // eslint-disable-next-line react/default-props-match-prop-types
  labelStyle: {},
  // eslint-disable-next-line react/default-props-match-prop-types
  multi: true,
  requireAddressLine1: false,
  onPrimaryChange: noop,
  addressTypeahead: false,
};

function mapStateToProps(state, ownProps) {
  if (!state.session) {
    return {
      addressTypes: _.get(ownProps, 'addressTypes', ownProps.enums.contact_data.address_types),
      usStates: ownProps.enums.geography.us_states,
    };
  }
  return {
    addressTypes: _.get(ownProps, 'addressTypes', state.session.enums.contact_data.address_types),
    usStates: state.session.enums.geography.us_states,
  };
}

export default connect(mapStateToProps)(AddressField);
