const ENTER_KEY_CODE = 13;
const SPACEBAR_KEY_CODE = 32;

const routeOnKeyDown = (callback) => (e) => {
  if (e.keyCode === ENTER_KEY_CODE || e.keyCode === SPACEBAR_KEY_CODE) {
    return callback();
  }
  return false;
};

export default routeOnKeyDown;
