import React from 'react';
import PropTypes from 'prop-types';
import { BackButton } from 'common/buttons';
import { useFindPayer } from 'src/components/Organization/api/hooks/v1/payerHooks';
import { useFindPlan } from 'src/components/Backoffice/api/hooks/v1/planHooks';
import { useFindFeeSchedule } from 'src/components/Backoffice/api/hooks/v1/feeScheduleHooks';
import { isEmpty } from 'lodash';
import Breadcrumbs from 'common/Breadcrumbs';
import { browserHistory } from 'common/utils/browserHistory';
import PlanSubHeader from './PlanSubHeader';
import PlanOverview from './PlanOverview';
import PlanEnrollmentsAttachments from './PlanEnrollmentsAttachments';

const Plan = ({ params }) => {
  const payerId = params.payer_id;
  const planId = params.plan_id;
  const feeScheduleId = params.fee_schedule_id;

  const { data: plan } = useFindPlan(planId);
  const planLabel = isEmpty(plan.name) ? 'Loading Program...' : plan.name;
  let crumbs;
  if (payerId) {
    const { data: payer } = useFindPayer(payerId);
    const payerLabel = isEmpty(payer.name) ? 'Loading...' : payer.name;
    crumbs = [
      { label: 'Payers', href: '/backoffice/payers' },
      { label: payerLabel, href: `/backoffice/payers/${payer.id}` },
      { label: planLabel },
    ];
  } else {
    const { data: feeSchedule } = useFindFeeSchedule(feeScheduleId);
    const feeScheduleLabel = isEmpty(feeSchedule.name) ?
      'Loading...' :
      feeSchedule.name;
    crumbs = [
      { label: 'Fee Schedules', href: '/backoffice/fee_schedules' },
      {
        label: feeScheduleLabel,
        href: `/backoffice/fee_schedules/${feeScheduleId}`,
      },
      {
        label: 'Plans',
        href: `/backoffice/fee_schedules/${feeScheduleId}/plans`,
      },
      { label: planLabel },
    ];
  }

  const childTabComponent = () => {
    const tabUrl = window.location.pathname.split('/').pop();
    switch (tabUrl) {
      case 'attachments':
        return <PlanEnrollmentsAttachments plan={plan} />;
      default:
        return <PlanOverview planId={planId} />;
    }
  };

  const BackToPlans = () => {
    const planPath = `/backoffice/payers/${payerId}`;
    const feeSchedulePath = `/backoffice/fee_schedules/${feeScheduleId}/plans`;
    browserHistory.push({
      pathname: feeScheduleId ? feeSchedulePath : planPath,
    });
  };

  return (
    <>
      <div className="detail-view">
        <div className="flex p-4 justify-start items-center bg-white border-b border-solid border-dark-border-blue">
          <div>
            <BackButton navigateTo={BackToPlans} />
          </div>
          <div className="px-5">
            <Breadcrumbs className="bread-crumbs" crumbs={crumbs} />
          </div>
        </div>
        <div className="bg-white border-b border-solid border-dark-border-blue mb-0">
          <PlanSubHeader
            planId={planId}
            payerId={payerId}
            feeScheduleId={feeScheduleId}
          />
        </div>
        {childTabComponent()}
      </div>
    </>
  );
};

Plan.propTypes = {
  params: PropTypes.object.isRequired,
};

export default Plan;
