import { primaryFirstSort } from 'common/form/Profile/utils';

function primaryFirstPhoneSort(phones = []) {
  return primaryFirstSort({
    arr: phones,
    sortByProperty: 'phone_type',
  });
}

export default primaryFirstPhoneSort;
