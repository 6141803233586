import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';

import { browserHistory } from 'common/utils/browserHistory';
import { UUModal, CheckBoxField, Button } from '@unite-us/ui';
import { saveKeyToSessionStorage, loadKeyFromSessionStorage } from 'common/utils/utils';

const DuplicatesModal = ({ contactId }) => {
  const [hideModal, setHideModal] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    const hideDuplicatesModal = loadKeyFromSessionStorage('hide-duplicates-modal');

    if (!hideDuplicatesModal) {
      modalRef.current.openModal();
    }
  }, []);

  const saveModalStatus = () => {
    if (hideModal) {
      saveKeyToSessionStorage('hide-duplicates-modal', true);
    }
  };

  const handleClose = () => {
    saveModalStatus();
    modalRef.current.closeModal();
  };

  const onReviewDuplicates = () => {
    saveModalStatus();

    browserHistory.push({
      pathname: `people/duplicates/${contactId}`,
      query: { back: `facesheet/${contactId}` },
    });
  };

  return (
    <UUModal
      ref={modalRef} title="Duplicate Records" size="normal"
      onRequestClose={handleClose}
      modal
      actions={(
        <div className="flex justify-end gap-6">
          <Button
            label="Close"
            secondary
            onClick={handleClose}
          />
          <Button
            label="Review Duplicate Records"
            primary
            onClick={onReviewDuplicates}
          />
        </div>
      )}
    >
      <div className="text-base">
        We have found duplicate records for this client.
        Review duplicate records to determine if they should be merged.
      </div>
      <div className="flex gap-4 mt-10 items-center border-b-1">
        <CheckBoxField
          id="hide-modal-checkbox"
          checked={hideModal}
          onChange={() => setHideModal(!hideModal)}
          label="Don't show this again"
          className="mb-0"
        />
      </div>
    </UUModal>
  );
};

DuplicatesModal.propTypes = {
  contactId: PropTypes.string.isRequired,
};

export default DuplicatesModal;
