import { useCallback, useState } from 'react';
import findValidInsurance from './utils/findValidInsurance';

const useInsuranceValidations = ({
  socialInsurances,
  setInferredPlanId,
  ignoreSocialCareExpiredDates,
  serviceAuthorization,
}) => {
  const [hasValidInsurance, setHasValidInsurance] = useState(false);
  const [isInsuranceExpired, setIsInsuranceExpired] = useState(false);
  const [hasSelectedDates, setHasSelectedDates] = useState(false);

  const validateInsuranceCoverage = useCallback((startDate, endDate) => {
    const { validInsurance, isExpired } = findValidInsurance(
      socialInsurances,
      startDate,
      endDate,
      ignoreSocialCareExpiredDates,
      serviceAuthorization,
    );

    setHasValidInsurance(!!validInsurance);
    setIsInsuranceExpired(isExpired);
    setInferredPlanId(validInsurance?.relationships?.plan?.data?.id);
    setHasSelectedDates(startDate && endDate);
  }, [socialInsurances, setInferredPlanId, ignoreSocialCareExpiredDates, serviceAuthorization]);

  return {
    hasSelectedDates,
    hasValidInsurance,
    isInsuranceExpired,
    validateInsuranceCoverage,
  };
};

export default useInsuranceValidations;
