import React, { useState } from 'react';
import { debounce, uniqBy } from 'lodash';
import addLabelsToEnums from 'common/utils/addLabelsToEnums';
import DEFAULT_ENUMS from 'src/reducers/constants/defaultEnums';
import { Field, useFormState } from 'react-final-form';
import { retrieveTypeAheadCounties, retrieveTypeAheadCities } from 'common/utils/Geographic/geographicalsTypeAhead';
import { SelectField } from '@unite-us/ui';
import { arrayOf } from 'prop-types';
import { geoEntityOption } from 'src/pages/backoffice/shapes';

const allStates = addLabelsToEnums(DEFAULT_ENUMS.geography.us_states);

const GeographicalRestrictions = ({ initialCities, initialCounties }) => {
  const { states, cities, counties } = useFormState()?.values;

  const getInitialStatesOptions = () => {
    const initialStateOptions = allStates.filter((state) => !cities?.find((val) => val.state === state.value));
    return initialStateOptions.filter((state) => !counties?.find((val) => val.state === state.value));
  };

  const [countiesOptions, setCountiesOptions] = useState(initialCounties ? [...initialCounties] : []);
  const [citiesOptions, setCitiesOptions] = useState(initialCities ? [...initialCities] : []);
  const [statesOptions, setStatesOptions] = useState(() => getInitialStatesOptions());

  const filterStateOptions = ({ citiesVal = cities, countiesVal = counties }) => {
    const newStateOptions = allStates.filter((state) => !citiesVal?.find((city) => city.state === state.value));
    setStatesOptions(newStateOptions.filter((state) => !countiesVal?.find((county) => county.state === state.value)));
  };

  const getCounties = async (value) => {
    if (value.length > 2) {
      let newCountiesOptions = await retrieveTypeAheadCounties(value, countiesOptions, true);
      newCountiesOptions = newCountiesOptions.filter((county) => !states?.includes(county.state));
      setCountiesOptions(uniqBy(newCountiesOptions, 'display_name'));
    }
  };

  const getCities = async (value) => {
    if (value.length > 2) {
      let newCitiesOpts = await retrieveTypeAheadCities(value, citiesOptions, true);
      newCitiesOpts = newCitiesOpts.filter((city) => !states?.includes(city.state));
      setCitiesOptions(uniqBy(newCitiesOpts, 'display_name'));
    }
  };

  const handleStateChange = (stateVals) => {
    setCitiesOptions(citiesOptions.filter((city) => !stateVals?.includes(city.state)));
    setCountiesOptions(countiesOptions.filter((county) => !stateVals?.includes(county.state)));
  };

  return (
    <>
      <Field name="states">
        {(params) => (
          <SelectField
            dataTestElement="resident_states_dropdown"
            label="Show resources in only these states:"
            placeholder="Select states..."
            id="states"
            multiple
            valueKey="value"
            labelClassName="font-semibold dark-grey text-sm normal-case mb-2"
            onChange={handleStateChange}
            {...params}
            options={statesOptions}
          />
        )}
      </Field>
      <Field name="counties">
        {(params) => (
          <SelectField
            label="Show resources in only these counties:"
            placeholder="Select counties..."
            id="counties"
            multiple
            loadingText="Searching...."
            loadOnMount
            loadOptions={debounce((value) => getCounties(value), 400)}
            valueKey="display_name"
            labelKey="display_name"
            labelClassName="font-semibold dark-grey text-sm normal-case mb-2"
            forceObjectValue
            {...params}
            onChange={(countiesVal) => filterStateOptions({ countiesVal })}
            options={countiesOptions}
          />
        )}
      </Field>
      <Field name="cities">
        {(params) => (
          <SelectField
            label="Show resources in only these cities:"
            placeholder="Select cities..."
            id="cities"
            multiple
            loadingText="Searching...."
            loadOnMount
            loadOptions={debounce((value) => getCities(value), 400)}
            valueKey="display_name"
            labelKey="display_name"
            labelClassName="font-semibold dark-grey text-sm normal-case mb-2"
            forceObjectValue
            {...params}
            onChange={(citiesVal) => filterStateOptions({ citiesVal })}
            options={citiesOptions}
          />
        )}
      </Field>
    </>
  );
};

GeographicalRestrictions.defaultProps = {
  initialCities: [],
  initialCounties: [],
};

GeographicalRestrictions.propTypes = {
  initialCities: arrayOf(geoEntityOption),
  initialCounties: arrayOf(geoEntityOption),
};

export default GeographicalRestrictions;
