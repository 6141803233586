const getPendoParams = (currentEmployee) => {
    if (!currentEmployee) {
      return {
        userEmail: null,
        userId: null,
        userName: null,
        userRole: null,
      };
    }

    const userId = currentEmployee?.user?.id;
    const userFirstName = currentEmployee?.first_name;
    const userLastName = currentEmployee?.last_name;
    const userEmail = currentEmployee?.email;
    const userRoles = currentEmployee?.roles.map((role) => role?.name).join(', ');
    const employeeId = currentEmployee?.id;
    const providerName = currentEmployee?.provider?.name;
    const providerId = currentEmployee?.provider?.id;
    const providerType = currentEmployee?.provider?.provider_type;
    const providerSensitive = currentEmployee?.provider?.sensitive;

    return {
      userId,
      userFirstName,
      userLastName,
      userEmail,
      userRoles,
      employeeId,
      providerName,
      providerId,
      providerType,
      providerSensitive,
    };
};

export default getPendoParams;
