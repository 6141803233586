import axios from 'axios';
import { EULA_AGREEMENT_ACCEPTED } from 'actions';
import Notifier from 'common/helpers/Notifier';
import { AUTH_URL } from 'src/config/env/env.config';

function onSuccess(response, dispatch) {
  dispatch({ type: EULA_AGREEMENT_ACCEPTED, payload: response });
  return response;
}

function onError(error) {
  Notifier.handleErrors(error);

  return error;
}

export default function acceptEulaAgreement() {
  const request = axios.post('/users/accept_eula', null, {
    baseURL: AUTH_URL,
  });

  return (dispatch) => request
    .then((data) => onSuccess(data, dispatch))
    .catch((error) => onError(error));
}
