import {
  FETCH_GROUP_CONTACT,
  UPDATE_GROUP_CONTACT,
  UPDATE_CONSENT_CONTACT,
  TOGGLE_GROUP_CONTACT_FLAG,
  SEARCH_GROUP_CONTACTS,
  CREATE_GROUP_CONTACT,
  ADD_PHONE_NUMBER_TO_CONTACT,
  ADD_PHONE_NUMBERS_TO_CONTACT,
  UPDATE_CONTACT_PHONE_NUMBER,
  DELETE_CONTACT_PHONE_NUMBER,
  ADD_EMAIL_ADDRESS_TO_CONTACT,
  UPDATE_CONTACT_EMAIL_ADDRESS,
  DELETE_CONTACT_EMAIL_ADDRESS,
  ADD_HOUSEHOLD_MEMBER_TO_CONTACT,
  UPDATE_CONTACT_HOUSEHOLD_MEMBER,
  DELETE_CONTACT_HOUSEHOLD_MEMBER,
  SEARCH_CONTACT,
  CONTACT_CONSENT_ACCEPTED,
  SET_CONTACTS_IS_FETCHING,
  UNSET_CONTACTS_IS_FETCHING,
  RESET_GROUP_CONTACTS_TO_DEFAULT_STATE,
  FETCH_REFERRAL_CONTACT,
  UPDATE_REFERRAL_CONTACT,
  SET_STATIC_HOUSEHOLD_COUNT,
  UPDATE_DYNAMIC_HOUSEHOLD_COUNT,
  SUBMIT_HOUSEHOLD_COUNT,
  GET_HOUSEHOLD_COUNT,
  SCREENING_CONTACTS_FETCHED,
} from 'actions';
import _ from 'lodash';

import { mergeBatchedContacts } from 'reducers/utils/Contact';
import {
  updateNestedStateByOneLevel,
  createNestedStateByOneLevel,
  deleteNestedStateByOneLevel,
} from 'common/utils/utils';

export const defaultState = {
  isAppending: false,
  isFetching: false,
  contacts: [],
  paging: {},
  myContacts: null,
};

function updateContactPhoneNumbers(state, action) {
  const { contactId, payload } = action;
  const phoneNumbers = payload.data.data;
  const phoneNumberIds = phoneNumbers.map((n) => n.id);
  const contact = state.contacts.find((c) => c.id === contactId);

  const updatedNumbers = contact.phone_numbers.map((num) => {
    if (phoneNumberIds.includes(num.id)) {
      return phoneNumbers.find((n) => n.id === num.id);
    }
    return num;
  });

  const updatedContacts = state.contacts.map((con) => {
    if (con.id === contactId) {
      return { ...con, phone_numbers: updatedNumbers };
    }
    return con;
  });

  return _.assign({}, state, { contacts: updatedContacts });
}

function addContactPhoneNumbers(state, action) {
  const { contactId, payload } = action;
  const phoneNumbers = payload.data.data;
  const contact = state.contacts.find((c) => c.id === contactId);

  const updatedPhoneNumbers = contact.phone_numbers.concat(phoneNumbers);
  const updatedContacts = state.contacts.map((con) => {
    if (con.id === contactId) {
      return { ...con, phone_numbers: updatedPhoneNumbers };
    }
    return con;
  });

  return _.assign({}, state, { contacts: updatedContacts });
}

function contactsCollectionReducer(state, action, collType, collAction) {
  const { contactId, itemId, payload } = action;

  switch (collAction) {
    case 'ADD_TO_CONTACT_COLLECTION': {
      return createNestedStateByOneLevel(state, 'contacts', contactId, payload.data.data, collType);
    }
    case 'UPDATE_CONTACT_COLLECTION': {
      return updateNestedStateByOneLevel(state, 'contacts', contactId, itemId, payload.data.data, collType);
    }
    case 'DELETE_FROM_CONTACT_COLLECTION': {
      return deleteNestedStateByOneLevel(state, 'contacts', contactId, itemId, collType);
    }
    default:
      return state;
  }
}

function contactsPhoneNumbersReducer(state, action) {
  switch (action.type) {
    case ADD_PHONE_NUMBER_TO_CONTACT:
      return contactsCollectionReducer(state, action, 'phone_numbers', 'ADD_TO_CONTACT_COLLECTION');
    case ADD_PHONE_NUMBERS_TO_CONTACT:
      return addContactPhoneNumbers(state, action);
    case UPDATE_CONTACT_PHONE_NUMBER:
      return updateContactPhoneNumbers(state, action);
    case DELETE_CONTACT_PHONE_NUMBER:
      return contactsCollectionReducer(state, action, 'phone_numbers', 'DELETE_FROM_CONTACT_COLLECTION');
    default:
      return state;
  }
}

function contactsEmailAddressesReducer(state, action) {
  switch (action.type) {
    case ADD_EMAIL_ADDRESS_TO_CONTACT:
      return contactsCollectionReducer(state, action, 'email_addresses', 'ADD_TO_CONTACT_COLLECTION');
    case UPDATE_CONTACT_EMAIL_ADDRESS:
      return contactsCollectionReducer(state, action, 'email_addresses', 'UPDATE_CONTACT_COLLECTION');
    case DELETE_CONTACT_EMAIL_ADDRESS:
      return contactsCollectionReducer(state, action, 'email_addresses', 'DELETE_FROM_CONTACT_COLLECTION');
    default:
      return state;
  }
}

function contactsHouseholdMembersReducer(state, action) {
  switch (action.type) {
    case ADD_HOUSEHOLD_MEMBER_TO_CONTACT:
      return contactsCollectionReducer(state, action, 'household', 'ADD_TO_CONTACT_COLLECTION');
    case UPDATE_CONTACT_HOUSEHOLD_MEMBER:
      return contactsCollectionReducer(state, action, 'household', 'UPDATE_CONTACT_COLLECTION');
    case DELETE_CONTACT_HOUSEHOLD_MEMBER:
      return contactsCollectionReducer(state, action, 'household', 'DELETE_FROM_CONTACT_COLLECTION');
    default:
      return state;
  }
}

function fetchSingleContact(payload, state) {
  const responseData = payload.data.data;
  const idMap = state.contacts.map((stateEl) => stateEl.id);
  let newItems = [...state.contacts, responseData];
  if (_.includes(idMap, responseData.id)) {
    newItems = state.contacts.reduce((acc, stateEl) => {
      if (stateEl.id === responseData.id) {
        return [...acc, responseData];
      }
      return [...acc, stateEl];
    }, []);
  }
  return _.assign({}, state, { contacts: newItems });
}

function updateSingleContact(payload, state) {
  const updatedContacts = state.contacts.reduce((acc, current) => {
    if (current.id === payload.data.data.id) {
      return [
        ...acc,
        payload.data.data,
      ];
    }
    return [
      ...acc,
      current,
    ];
  }, []);

  return _.assign({}, state, { contacts: updatedContacts });
}

function updateSingleConsentContact(payload, state) {
  const updatedContacts = state.contacts.reduce((acc, current) => {
    if (current.id === payload.data.data.contact.id) {
      return [
        ...acc,
        payload.data.data.contact,
      ];
    }
    return [
      ...acc,
      current,
    ];
  }, []);

  return { ...state, contacts: updatedContacts };
}

export default function contactsReducer(state = defaultState, action) {
  const { payload, contactId } = action;
  switch (action.type) {
    case SCREENING_CONTACTS_FETCHED:
      return {
        ...state,
        contacts: mergeBatchedContacts({ state, contacts: payload }),
      };

    case RESET_GROUP_CONTACTS_TO_DEFAULT_STATE:
      return defaultState;

    case SET_CONTACTS_IS_FETCHING:
      return _.assign({}, state, { isFetching: true });

    case UNSET_CONTACTS_IS_FETCHING:
      return _.assign({}, state, { isFetching: false });

    case FETCH_GROUP_CONTACT: {
      return fetchSingleContact(payload, state);
    }

    case UPDATE_GROUP_CONTACT: {
      return updateSingleContact(payload, state);
    }

    case UPDATE_CONSENT_CONTACT: {
      return updateSingleConsentContact(payload, state);
    }

    case SEARCH_GROUP_CONTACTS: {
      return _.assign({}, state, { contacts: payload.data.data.map((item) => item.item.result) });
    }

    case TOGGLE_GROUP_CONTACT_FLAG: {
      const newItems = state.contacts.reduce((acc, stateEl) => {
        if (stateEl.id === contactId) {
          return [...acc, _.assign({}, stateEl, { is_flagged: !stateEl.is_flagged })];
        }
        return [...acc, stateEl];
      }, []);
      return _.assign({}, state, { contacts: newItems });
    }

    case CONTACT_CONSENT_ACCEPTED: {
      const newItems = state.contacts.reduce((acc, stateEl) => {
        if (stateEl.id === contactId) {
          return [...acc, payload.contact];
        }
        return [...acc, stateEl];
      }, []);

      return _.assign({}, state, { contacts: newItems });
    }

    case CREATE_GROUP_CONTACT: {
      return _.assign({}, state, { contacts: [...state.contacts, action.payload.data.data] });
    }

    case SEARCH_CONTACT: {
      const contacts = action.payload.data.data.map((contact) => contact.item.result);
      const newIds = contacts.map((c) => c.id);
      const newState = state.contacts.reduce((acc, curr) => {
        if (_.includes(newIds, curr.id)) {
          return [
            ...acc,
            _.find(contacts, (c) => c.id === curr.id),
          ];
        }
        return [
          ...acc,
          curr,
        ];
      }, []);
      return _.assign({}, state, { contacts: newState });
    }

    case ADD_PHONE_NUMBER_TO_CONTACT:
      return contactsPhoneNumbersReducer(state, action);
    case ADD_PHONE_NUMBERS_TO_CONTACT:
      return contactsPhoneNumbersReducer(state, action);
    case UPDATE_CONTACT_PHONE_NUMBER:
      return contactsPhoneNumbersReducer(state, action);
    case DELETE_CONTACT_PHONE_NUMBER:
      return contactsPhoneNumbersReducer(state, action);
    case ADD_EMAIL_ADDRESS_TO_CONTACT:
      return contactsEmailAddressesReducer(state, action);
    case UPDATE_CONTACT_EMAIL_ADDRESS:
      return contactsEmailAddressesReducer(state, action);
    case DELETE_CONTACT_EMAIL_ADDRESS:
      return contactsEmailAddressesReducer(state, action);
    case ADD_HOUSEHOLD_MEMBER_TO_CONTACT:
      return contactsHouseholdMembersReducer(state, action);
    case UPDATE_CONTACT_HOUSEHOLD_MEMBER:
      return contactsHouseholdMembersReducer(state, action);
    case DELETE_CONTACT_HOUSEHOLD_MEMBER:
      return contactsHouseholdMembersReducer(state, action);
    case FETCH_REFERRAL_CONTACT: {
      return fetchSingleContact(payload, state);
    }
    case UPDATE_REFERRAL_CONTACT: {
      return updateSingleContact(payload, state);
    }
    case SUBMIT_HOUSEHOLD_COUNT:
    case GET_HOUSEHOLD_COUNT:
    case SET_STATIC_HOUSEHOLD_COUNT:
    case UPDATE_DYNAMIC_HOUSEHOLD_COUNT: {
      const newState = state.contacts.reduce((acc, curr) => {
        if (curr.id === action.contactId) {
          return [...acc, { ...curr, household_count: action.payload.data.data }];
        }
        return [...acc, curr];
      }, []);
      return { ...state, contacts: newState };
    }
    default:
      return state;
  }
}
