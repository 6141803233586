import { atLeastOneGroupValue } from 'src/components/Referrals/ReferralFormFields/OONGroupsSelector/utils';
import { get, isEmpty } from 'lodash';

function oonGroupValidations(allowEmpty, isOONCase) {
  return (value, message, args, formValues) => (
    !allowEmpty &&
      isOONCase &&
      isEmpty(value) &&
      !atLeastOneGroupValue(get(formValues, 'service_case.oonCase.selected', [])) ?
      'Required' :
      null
  );
}

export default oonGroupValidations;
