import PropTypes from 'prop-types';
import React, { Component } from 'react';
import HorizontalLinearStepper from 'src/components/Cases/components/HorizontalLinearStepper';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import { MILITARY_FORM_NAME } from 'common/form/MilitaryForm';
import { CASE_DETAILS_FORM } from 'src/components/Cases/constants';
import { clearProgramEnrollment } from 'actions/Program';
import {
  BaseCard,
  BaseCardBody,
} from '@unite-us/ui';
import { OverlaySpinner } from 'common/spinners';
import getStepIndex from 'src/components/Cases/utils/getStepIndex';
import _ from 'lodash';

export class NewCaseView extends Component {
  componentWillUnmount() {
    this.props.destroyForm(CASE_DETAILS_FORM);
    this.props.destroyForm(MILITARY_FORM_NAME);
    this.props.clearEnrollment();
    _.map(this.props.groupForms, (form) => {
      this.props.destroyForm(form.id);
    });
  }

  render() {
    const {
      assistanceRequestId,
      children,
      contactId,
      currentStep,
      submitting,
    } = this.props;

    return (
      <div className="container">
        <BaseCard className="card-container">
          <BaseCardBody>
            <OverlaySpinner text="Submitting Case..." show={submitting} />
            <HorizontalLinearStepper
              currentStep={currentStep}
              children={children}
              contactId={contactId}
              assistanceRequestId={assistanceRequestId}
            />
          </BaseCardBody>
        </BaseCard>
      </div>
    );
  }
}

NewCaseView.propTypes = {
  assistanceRequestId: PropTypes.string,
  children: PropTypes.element,
  clearEnrollment: PropTypes.func.isRequired,
  contactId: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  destroyForm: PropTypes.func.isRequired,
  groupForms: PropTypes.array,
  submitting: PropTypes.bool.isRequired,
};

NewCaseView.defaultProps = {
  groupForms: [],
  submitting: false,
};

function mapStateToProps(state, ownProps) {
  const { groupForms, serviceCase } = state;
  const groupId = _.get(state, 'session.groupId');
  const pathName = _.get(ownProps, 'location.pathname', '');
  const params = _.get(ownProps, 'params', {});

  return {
    assistanceRequestId: params.assistance_request_id,
    contactId: params.id,
    currentStep: getStepIndex(pathName),
    groupForms: groupForms[groupId],
    submitting: _.get(serviceCase, 'isCreating', false),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    destroyForm: (form) => dispatch(destroy(form)),
    clearEnrollment: () => dispatch(clearProgramEnrollment()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCaseView);
