import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ChartMultiBar } from 'src/common/charts';
import { fetchMilitaryReports } from 'actions/Report/Contact/Military';
import _ from 'lodash';
import { serviceBranchesPartitions, networkServiceBranchesPartitions } from '../../utils/partitions';
import { serviceBranchesColors } from '../../utils/colors';
import sortByTitleNoResponseLast from '../../utils/sortByTitleNoResponseLast';

export default class EraByBranch extends Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.state = {
      eraByBranch: [],
      title: '',
    };
  }

  componentDidMount() {
    this.fetchData(this.props.filters);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.filters, nextProps.filters)) {
      this.fetchData(nextProps.filters);
    }
  }

  fetchData(filters) {
    const { scope, id } = this.props;
    const partitions = scope === 'group' ? serviceBranchesPartitions() : networkServiceBranchesPartitions();
    fetchMilitaryReports(
      scope,
      id,
      'service-era/by-branch-of-service',
      { partitions, ...filters },
    )
      .then((response) => {
        const data = _.get(response, 'data', {});
        data.values = sortByTitleNoResponseLast(data.values);
        this.setState({
          eraByBranch: data,
          title: _.get(response, 'data.title', ''),
        });
        this.props.loaded('eraByBranch');
      });
  }

  render() {
    if (_.isEmpty(this.state.eraByBranch)) {
      return null;
    }
    return (
      <ChartMultiBar
        data={this.state.eraByBranch}
        x={(d) => d.title}
        y={(d) => d.total}
        title={this.state.title}
        margin={{ left: 60, top: 60, bottom: 150 }}
        showValues
        showYAxis
        showLegend
        stacked
        valueFormat="d"
        rotateLabels={-45}
        useInteractiveGuideline={false}
        maxKeyLength={40}
        colors={serviceBranchesColors}
        xLabel="Service Era"
        colWidths={{
          x: '270px',
        }}
      />
    );
  }
}

EraByBranch.propTypes = {
  filters: PropTypes.shape({
    end_date: PropTypes.string,
    network_ids: PropTypes.array,
    start_date: PropTypes.string,
  }),
  loaded: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
};
