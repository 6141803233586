import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { get } from 'lodash';
import { SelectField } from '@unite-us/ui';
import { validations } from '@unite-us/app-components';

const ProcedureCodeRow = (props) => {
  const {
    name,
    options,
    index,
    formProps,
  } = props;

  const selectedProcedureCode = options?.find((procedureCode) => {
    const procedureCodeFormProp = get(
      formProps.values,
      `${name}.procedure_code`,
    );

    const procedureCodeId = procedureCodeFormProp?.id ?? procedureCodeFormProp;
    return procedureCodeId === procedureCode.id;
  });

  const procedureCodeModifiers = selectedProcedureCode ? selectedProcedureCode.procedure_code_modifiers : [];

  const onProcedureCodeChange = () => {
    formProps.form.change(`${name}.procedure_code_modifier`, '');
  };

  const pcOptions = options?.map((pc) => ({
      label: pc?.description,
      value: pc?.id,
      disabled: pc?.disabled,
  }));

  const pcmOptions = procedureCodeModifiers?.map((pcm) => ({
    label: pcm?.description,
    value: pcm?.id,
  }));

  const hideProcedureCodeRow = options?.length === 1 && options[0].procedure_code_modifiers.length <= 1;

  return (
    <div className={'col-span-4 grid-cols-4 gap-x-6 flex flex-row items-center my-0 px-0'} key={index}>
      <Field
        name={`${name}.procedure_code`}
        validate={(val) => validations.isRequired(val)}
      >
        {(fieldProps) => (
          <SelectField
            id={`provided-service-procedure-code-${index}`}
            options={pcOptions}
            placeholder="Choose one..."
            label="Procedure Code"
            searchPlaceholderValue="Search by code or description"
            onChange={onProcedureCodeChange}
            autoSelectValue={options?.length === 1}
            className={hideProcedureCodeRow ? 'hidden' : 'my-1'}
            required
            {...fieldProps}
          />
        )}
      </Field>
      <Field
        name={`${name}.procedure_code_modifier`}
        validate={(val) => validations.isRequired(val)}
      >
        {(fieldProps) => (
          <SelectField
            id={`provided-service-procedure-code-modifier-${index}`}
            label="Procedure Code Modifier"
            searchPlaceholderValue="Search by description"
            autoSelectValue={pcmOptions?.length === 1}
            options={pcmOptions}
            placeholder="Choose one..."
            className={hideProcedureCodeRow ? 'hidden' : 'my-1'}
            required
            disabled={procedureCodeModifiers.length < 1}
            {...fieldProps}
          />
        )}
      </Field>
    </div>
  );
};

ProcedureCodeRow.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  formProps: PropTypes.object.isRequired,
};
export default ProcedureCodeRow;
