// https://stackoverflow.com/questions/572768/styling-an-input-type-file-button
// https://stackoverflow.com/questions/20549241/how-to-reset-input-type-file
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';
import classNames from 'classnames';
import _ from 'lodash';
import './UploadCSVButton.scss';

class UploadCSVButton extends Component {
  constructor(props) {
    super(props);
    this.onUpload = this.onUpload.bind(this);
    this.state = {
      disabled: false,
    };
  }

  onUpload(e) {
    const { id } = this.props;
    const { disabled } = this.state;
    const files = _.get(e, 'target.files');

    if (!_.isEmpty(files) && !disabled) {
      this.setState({ disabled: true }, () => {
        this.props.onUpload(files).then(() => {
          // Reset the input value so the onChange gets called again if the user
          // selects the same file to upload.
          document.getElementById(id).value = '';
          this.setState({ disabled: false });
        });
      });
    }
  }

  render() {
    const { id, multiple } = this.props;
    const { disabled } = this.state;

    const labelClasses = () => classNames({
      'upload-csv-button__label': true,
      disabled,
    });

    return (
      <div className="upload-csv-button">
        <label
          htmlFor={id}
          className={labelClasses()}
        >
          <input
            type="file"
            id={id}
            multiple={multiple}
            onChange={this.onUpload}
            accept=".csv"
          />
          <span className="upload-csv-button__button-label">
            Upload CSV
            <Icon
              className="upload-csv-button__label--icon"
              icon="IconCloudUpload"
            />
          </span>
        </label>
      </div>
    );
  }
}

UploadCSVButton.propTypes = {
  id: PropTypes.string,
  multiple: PropTypes.bool,
  onUpload: PropTypes.func.isRequired,
};

UploadCSVButton.defaultProps = {
  id: 'file-upload-input',
  multiple: false,
};

export default UploadCSVButton;
