import {
  FETCH_FEE_SCHEDULES_STARTED,
  FETCH_FEE_SCHEDULES_SUCCESS,
  FETCH_FEE_SCHEDULES_ERROR,
  CREATE_FEE_SCHEDULE_STARTED,
  CREATE_FEE_SCHEDULE_SUCCESS,
  CREATE_FEE_SCHEDULE_ERROR,
  UPDATE_FEE_SCHEDULE_STARTED,
  UPDATE_FEE_SCHEDULE_SUCCESS,
  UPDATE_FEE_SCHEDULE_ERROR,
} from 'actions';
import {
  STATUS_INITIAL,
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_ERROR,
} from 'common/Status/constants';
import arrayToObject from 'common/utils/arrayToObject';

export const defaultState = {
  status: STATUS_INITIAL,
  data: {},
};

export default function feeSchedulesReducer(state = defaultState, action) {
  const { payload, type } = action;

  switch (type) {
    case FETCH_FEE_SCHEDULES_STARTED:
      return { ...state, status: STATUS_PENDING };
    case FETCH_FEE_SCHEDULES_ERROR:
      return { ...state, status: STATUS_ERROR };
    case FETCH_FEE_SCHEDULES_SUCCESS: {
      return {
        ...state,
        data: arrayToObject(payload),
        status: STATUS_SUCCESS,
      };
    }
    case UPDATE_FEE_SCHEDULE_STARTED:
      return { ...state, status: STATUS_PENDING };
    case UPDATE_FEE_SCHEDULE_ERROR:
      return { ...state, status: STATUS_ERROR };
    case UPDATE_FEE_SCHEDULE_SUCCESS: {
      return {
        ...state,
        data: { ...state.data, [payload.id]: payload },
        status: STATUS_SUCCESS,
      };
    }
    case CREATE_FEE_SCHEDULE_STARTED:
      return { ...state, status: STATUS_PENDING };
    case CREATE_FEE_SCHEDULE_ERROR:
      return { ...state, status: STATUS_ERROR };
    case CREATE_FEE_SCHEDULE_SUCCESS: {
      return {
        ...state,
        data: { ...state.data, [payload.id]: payload },
        status: STATUS_SUCCESS,
      };
    }

    default:
      return state;
  }
}
