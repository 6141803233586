import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { findIndex, first } from 'lodash';
import AddDeleteButtons from 'src/common/form/AddDeleteButtons/AddDeleteButtons';
import { defaultPaymentsInsurance } from 'src/components/Insurance/utils/';
import PaymentsInsuranceFieldsContainer from 'src/components/Insurance/PaymentsInsuranceFieldsContainer';
import '../Intake/stylesheets/intake.scss';

class PaymentsInformation extends Component {
  static customHideRemoveButton() {
    return false;
  }

  constructor(props) {
    super(props);

    this.onAddPaymentsInsuranceFields = this.onAddPaymentsInsuranceFields.bind(this);
    this.onRemovePaymentsInsuranceFields = this.onRemovePaymentsInsuranceFields.bind(this);
  }

  onAddPaymentsInsuranceFields() {
    const newPaymentsFormId = defaultPaymentsInsurance();
    this.props.fields.insurance.addField(newPaymentsFormId);

    setTimeout(() => {
      const paymentsElements = document.getElementsByClassName('payments-insurance-fields');
      const newPaymentsElement = paymentsElements[this.props.fields.insurance.length - 1];
      const newPlanTypeSelectField = first(newPaymentsElement?.getElementsByClassName('choices'));
      return newPlanTypeSelectField?.focus();
    }, 200);
  }

  onRemovePaymentsInsuranceFields(idToRemove) {
    const index = findIndex(this.props.fields.insurance, { form_id: { initialValue: idToRemove } });
    this.props.fields.insurance.removeField(index);
  }

  render() {
    const {
      contactId,
      fields,
      intake,
      multi,
      registerField,
      untouch,
    } = this.props;
    const namespace = 'insurance-form-button';
    const classes = classnames(namespace, intake && `${namespace}--intake`);
    const footer = 'insurance-form-button-footer h-10';
    const footerClasses = classnames(footer, intake && `${footer}--intake`);

    return (
      <div className="payments-insurance__information">
        <div className="flex">
          <div className="w-full -mr-32">
            { fields.insurance.map((insurance, index) => (
              <div className="insurance-form-head" key={insurance.form_id.initialValue}>
                <PaymentsInsuranceFieldsContainer
                  contactId={contactId}
                  fields={insurance}
                  index={index}
                  registerField={registerField}
                  untouch={untouch}
                />
                { intake && (<div className="payments-insurance-divider" />) }
                {
                  index !== 0 && multi && (
                    <div className={classes}>
                      <AddDeleteButtons
                        displayText="Delete Insurance"
                        onRemoveClick={() => this.onRemovePaymentsInsuranceFields(
                          fields.insurance[index].form_id.initialValue,
                        )}
                      />
                    </div>
                  )
                }
                { !intake && (<div className="payments-insurance-client-divider" />) }
              </div>
            ))}
          </div>
          {
            multi && (
              <div className={footerClasses}>
                <AddDeleteButtons
                  displayText="Add Insurance"
                  onAddClick={this.onAddPaymentsInsuranceFields}
                />
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

PaymentsInformation.propTypes = {
  contactId: PropTypes.string,
  fields: PropTypes.object.isRequired,
  intake: PropTypes.bool,
  multi: PropTypes.bool,
  registerField: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
};

PaymentsInformation.defaultProps = {
  contactId: '',
  intake: false,
  multi: true,
};

PaymentsInformation.fields = [
  'insurance[].form_id',
  'insurance[].id',
  'insurance[].plan_type',
  'insurance[].plan',
  'insurance[].member_id',
  'insurance[].group_id',
  'insurance[].enrollment',
  'insurance[].expiration',
];

PaymentsInformation.navigation = [
  {
    id: 'insurance-information',
    name: 'Insurance Information',
    standard: true,
  },
];

export default PaymentsInformation;
