import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isOONGroup } from 'src/components/Groups/utils';
import { addNameToOONPrograms } from '../utils';
import GroupDetailsProgram from './GroupDetailsProgram';

const GroupDetailsPrograms = (props) => {
  const {
    group,
    programs,
    providerLocations,
    center,
  } = props;

  // A hack to remove a duplicate default programs

  const slimPrograms = _.filter(
    programs,
    (program) => (program.attributes ?
      (program.attributes.name !== 'Referred Out of Network') :
      (program.name !== 'Referred Out of Network')),
  );
  const programsWithNames = isOONGroup(group) ?
    addNameToOONPrograms({ group, programs: slimPrograms }) :
    _.sortBy(slimPrograms, 'attributes.name');

  const filterProgramLocations =
    (id) => _.filter(providerLocations, { relationships: { programs: { data: [{ id }] } } });

  const formattedPrograms = programsWithNames.map((program) => (
    <GroupDetailsProgram
      center={center}
      htmlId={`program-detail-${program.id}`}
      initiallyCollapsed
      isOONGroup={isOONGroup(group)}
      key={`program-${program.id}`}
      program={program}
      programLocations={filterProgramLocations(program.id)}
    />
  ));

  return (
    <div className="group-details-content__programs">
      <div className="group-details-content__programs-container">
        {!_.isEmpty(programs) && formattedPrograms}
      </div>
    </div>
  );
};

GroupDetailsPrograms.propTypes = {
  center: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  programs: PropTypes.array.isRequired,
  providerLocations: PropTypes.array.isRequired,
};

export default GroupDetailsPrograms;
