import _ from 'lodash';
import { ASYNC_DASHBOARD_FILTERS_KEYS } from 'src/components/Dashboard/constants';

function setAsyncSearch(filter, asyncSearch) {
  if (_.includes(ASYNC_DASHBOARD_FILTERS_KEYS, filter.key)) {
    return asyncSearch;
  }
  return undefined;
}

export default setAsyncSearch;
