import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from '@unite-us/ui';
import { validateReduxForm } from 'common/form';
import _ from 'lodash';
import { OverlaySpinner } from 'common/spinners';
import { ProfileEmailFields } from 'common/form/Profile';
import { EDIT_EMAIL_ADDRESSES_GROUP } from 'common/display/Profile/constants/form';
import { generateUUID } from 'common/utils/utils';
import callOrLog from 'common/utils/callOrLog';
import { FACESHEET } from 'common/utils/EventTracker/utils/eventConstants';
import { cleanEmailValues } from 'common/form/Profile/utils/cleanContactValues';
import { emailsInitialValues } from './utils';
import './stylesheets/editEmailAddresses.scss';

export class EditEmailAddressesGroup extends Component {
  static initializeIds(list) {
    return _.map(list, (email) => ({ ...email, id: generateUUID() }));
  }

  constructor(props) {
    super(props);

    this.state = {
      deletingEmail: false,
      newIds: [],
    };

    this.onCancel = this.onCancel.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.initializeForm = this.initializeForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.addField = this.addField.bind(this);
    this.removeField = this.removeField.bind(this);
    this.resetAndCloseModal = this.resetAndCloseModal.bind(this);
    this.onPrimaryChange = this.onPrimaryChange.bind(this);
    this.removeOrDelete = this.removeOrDelete.bind(this);
    this.removeUnsavedEmails = this.removeUnsavedEmails.bind(this);
  }

  componentDidMount() {
    this.initializeForm();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.modalIsOpen && !nextProps.modalIsOpen) {
      this.removeUnsavedEmails();
    }
  }

  onCancel() {
    this.resetAndCloseModal();
  }

  onDelete(emails = [], idToRemove) {
    const emailsArr = emails.map(cleanEmailValues);

    // if there are no changed emails, don't track the update
    if (!_.isEmpty(emailsArr)) {
      callOrLog(() => this.context.eventTracker(FACESHEET.emailUpdated));
    }

    return this.props.onSave(emailsArr)
      .then(() => {
        const updatedStateIds = _.filter(this.state.newIds, (item) => item.id !== idToRemove);
        const index = _.findIndex(this.props.fields.emails, (e) => e.id.value === idToRemove);
        this.props.fields.emails.removeField(index);
        this.setState({
          newIds: updatedStateIds,
          deletingEmail: false,
        });
      });
  }

  onSubmit({ emails }) {
    const emailsArr = emails.map(cleanEmailValues);

    // if there are no changed emails, don't track the update
    if (!_.isEmpty(emailsArr)) {
      callOrLog(() => this.context.eventTracker(FACESHEET.emailUpdated));
    }

    // we are going to return this even if it is empty, it will close the modal, and the prop function
    // will determine what to do with the empty array of emails
    return this.props.onSave(emailsArr)
      .then(this.resetAndCloseModal);
  }

  onPrimaryChange(email) {
    _.each(this.props.fields.emails, (field) => {
      if (field !== email) {
        field.is_primary.onChange(false);
      }
    });
    email.is_primary.onChange(true);
  }

  initializeForm() {
    const { initialEmails } = this.props;
    const result = emailsInitialValues(initialEmails);

    const fieldEmails = EditEmailAddressesGroup.initializeIds(result);

    this.props.initializeForm({
      emails: fieldEmails,
    });
  }

  addField() {
    const { emails } = this.props.fields;
    const id = generateUUID();
    emails.addField({ id });
    this.setState({
      newIds: [...this.state.newIds, id],
    }, () => window.dispatchEvent(new Event('resize')));
  }

  removeField(emails, idToRemove) {
    const index = _.findIndex(emails, (e) => e.id.value === idToRemove);
    emails.removeField(index);
    this.setState({
      newIds: _.filter(this.state.newIds, (id) => id !== idToRemove),
    });
  }

  removeOrDelete(idToRemove) {
    const { fields: { emails } } = this.props;

    if (_.includes(this.state.newIds, idToRemove)) {
      this.removeField(emails, idToRemove);
    } else {
      this.setState({ deletingEmail: true }, () => {
        const filteredEmails = _.filter(emails, (email) => email.id.value !== idToRemove &&
          !_.includes(this.state.newIds, email.id.value));
        const emailValues = _.map(filteredEmails, (email) => ({
          email_address: email.email_address.value,
          is_primary: email.is_primary.value,
        }));
        this.onDelete(emailValues, idToRemove);
      });
    }
  }

  removeUnsavedEmails() {
    const { emails } = this.props.fields;

    _.each(this.state.newIds, () => {
      emails.removeField();
    });

    this.setState({ newIds: [] });
  }

  resetAndCloseModal() {
    this.initializeForm();
    this.setState({ newIds: [] });
    this.props.closeModal();
  }

  render() {
    const {
      fields: { emails },
      handleSubmit,
      hideCommunicationPreferences,
      registerField,
      styles,
      submitting,
    } = this.props;

    const { deletingEmail } = this.state;

    const emailFormFields = emails.map((email, index) => (
      <div
        id={`email-${index}`}
        key={`${email.id.value}`}
        style={index !== emails.length - 1 ? styles.inlineEmailContainer : {}}
      >
        <ProfileEmailFields
          fields={email}
          hideCommunicationPreferences={hideCommunicationPreferences}
          onPrimaryChange={this.onPrimaryChange}
          registerField={registerField}
          onRemove={this.removeOrDelete}
        />
      </div>
    ));

    return (
      <form
        className="edit-email-addresses content-with-actions"
        onSubmit={handleSubmit(this.onSubmit)}
      >
        <OverlaySpinner text="Saving..." show={submitting || deletingEmail} />
        <div className="content-container">
          {emailFormFields}
        </div>
        <div className="actions">
          <div className="row">
            <div className="col-sm-6 text-left edit-email-addresses__add-email">
              <a
                id="add-email-link"
                role="button"
                onClick={this.addField}
              >
                + Add Email
              </a>
            </div>
            <div className="col-sm-6">
              <span className="action-item">
                <Button
                  id="edit-email-cancel-btn"
                  onClick={this.onCancel}
                  disabled={submitting}
                  label="Cancel"
                  secondary
                />
              </span>
              <span className="action-item">
                <Button
                  id="edit-email-save-btn"
                  onClick={handleSubmit(this.onSubmit)}
                  disabled={submitting}
                  label="Save"
                  primary
                />
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

EditEmailAddressesGroup.propTypes = {
  closeModal: PropTypes.func,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hideCommunicationPreferences: PropTypes.bool,
  modalIsOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  registerField: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    inlineEmailContainer: PropTypes.object,
  }),
  submitting: PropTypes.bool.isRequired,
  initialEmails: PropTypes.array.isRequired,
  initializeForm: PropTypes.func.isRequired,
};

EditEmailAddressesGroup.defaultProps = {
  closeModal: _.noop,
  hideCommunicationPreferences: false,
  onSave: _.noop,
  styles: {
    inlineEmailContainer: {
      borderBottom: '1px solid #EBECED',
      marginBottom: '20px',
      paddingBottom: '10px',
    },
  },
};

EditEmailAddressesGroup.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { emails } = ownProps;
  return {
    groupId: state.session.groupId,
    initialEmails: emails,
  };
}

const fields = [
  'emails[].email_address',
  'emails[].id',
  'emails[].is_primary',
];
export default validateReduxForm({
  form: EDIT_EMAIL_ADDRESSES_GROUP,
  fields,
}, mapStateToProps)(EditEmailAddressesGroup);
