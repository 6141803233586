import $scripts from 'scriptjs';
import { google } from '@unite-us/client-utils';

import { GOOGLE_MAPS_API_KEY } from 'src/config/env/env.config';

const { googleMapsScriptUrl } = google;

const requiredMapScript = {
  scripts: [googleMapsScriptUrl({ key: GOOGLE_MAPS_API_KEY })],
};

export const loadGoogleMapsAPI = () => {
  try {
    $scripts(requiredMapScript.scripts, () => {});
    return true;
  } catch (error) {
    console.error('requiredMapScript.loadGoogleMapsAPI error', error);
    return false;
  }
};

export default requiredMapScript;
