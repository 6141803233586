import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useFindPlanEnrollmentForms } from 'src/components/Backoffice/api/hooks/v1/formHooks';
import PlanAttachments from './PlanAttachments';
import PlanEnrollmentForms from './PlanEnrollmentForms';

const PlanEnrollmentsAttachments = ({ plan }) => {
  const { data: enrollmentForms } = useFindPlanEnrollmentForms(plan.id);
  const attachments = plan.metafields ?
    plan.metafields.filter((metafield) => !isEmpty(metafield) && metafield.type === 'file') :
    [];

  return (
    <>
      { plan.plan_type === 'social' && <PlanEnrollmentForms planId={plan.id} enrollmentForm={enrollmentForms[0]} /> }
      <PlanAttachments plan={plan} attachments={attachments} />
    </>
  );
};

PlanEnrollmentsAttachments.propTypes = {
  plan: PropTypes.object.isRequired,
};

export default PlanEnrollmentsAttachments;
