import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  ColumnHeader,
  SortableColumnHeader,
  DataCell,
  DataRow,
  HeaderRow,
  Table,
  TableBody,
} from 'src/common/tables/V2';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Button,
  Icon,
} from '@unite-us/ui';
import moment from 'moment';
import { Checkbox } from 'src/common/TailwindComponents';
import { intersection, get } from 'lodash';
import { useFindEmployeesByFeeSchedule } from 'src/components/Backoffice/api/hooks/v1/employeeHooks';
import { useFindProviders } from 'src/components/Backoffice/api/hooks/v1/providerHooks';
import { useSetCareManager, useFindFeeSchedule } from 'src/components/Backoffice/api/hooks/v1/feeScheduleHooks';
import { useInvalidateQueries } from 'src/api/APIHooks';
import { theme } from 'src/../tailwind.config';
import { Spinner } from 'common/spinners';
import Notifier from 'common/helpers/Notifier';
import EmptyTable from 'src/common/tables/EmptyTable/EmptyTable';
import Pagination from 'src/common/Pagination/Pagination';
import ScrollToElement from 'src/common/utils/Form/ScrollToElement';
import OrganizationSearchTable from 'src/components/Backoffice/searchTable/OrganizationSearchTable';
import DialogV2 from 'common/modal/DialogV2';
import { DEFAULT_PAGE_SIZE } from 'src/components/Backoffice/backofficeUtils';
import AddCareManagersTable from './AddCareManagersTable';

const GenerateCareManagersTable = ({
  careManagers,
  selectedCareManagers,
  setSelectedCareManagers,
  sortBy,
  sortDirection,
  setSort,
}) => {
  const careManagersIds = careManagers?.map((careManager) => careManager.id);
  const organizationIds = careManagers?.map((careManager) => careManager.provider.id).join(',') || '';
  const { data: organizations, isFetching } = useFindProviders(organizationIds);
  const orgIdToName = {};
  organizations.forEach((org) => {
    orgIdToName[org.id] = org.name;
  });

  const selectCareManagers = (...ids) => {
    const newSelected = new Set([...selectedCareManagers, ...ids]);
    setSelectedCareManagers([...newSelected]);
  };
  const deselectCareManagers = (...ids) => {
    const newSelected = selectedCareManagers.filter((id) => !ids.includes(id));
    setSelectedCareManagers(newSelected);
  };
  const onCheckColumn = () => {
    if (
      selectedCareManagers.length !== 0 &&
      intersection(selectedCareManagers, careManagersIds).length ===
      careManagers.length
    ) {
      deselectCareManagers(...careManagersIds);
    } else {
      selectCareManagers(...careManagersIds);
    }
  };
  const toggleCareManagerSelected = (careManagerId) => {
    if (selectedCareManagers.includes(careManagerId)) {
      deselectCareManagers(careManagerId);
    } else {
      selectCareManagers(careManagerId);
    }
  };

  if (isFetching) return <Spinner />;

  return (
    <Table id="careManagersTable" className="bg-white rounded-t-lg border border-solid border-light-border-blue">
      <HeaderRow>
        <ColumnHeader className="w-6">
          <Checkbox
            ariaLabel="Select all invoices on page for export"
            checked={
              intersection(selectedCareManagers, careManagersIds).length ===
              careManagersIds.length
            }
            id="invoices-table-header-row-checkbox"
            onChange={onCheckColumn}
          />
        </ColumnHeader>
        <SortableColumnHeader
          colName="last_name"
          className="w-4/12"
          label="User Name"
          sortBy={sortBy}
          sortDirection={sortDirection}
          setSort={setSort}
        >
          User Name
        </SortableColumnHeader>
        <ColumnHeader className="w-3/12">
          Organization
        </ColumnHeader>
        <ColumnHeader className="w-2/12">
          User Email
        </ColumnHeader>
        <ColumnHeader className="w-3/12">
          User ID
        </ColumnHeader>
      </HeaderRow>
      <TableBody>
        {careManagers?.map((careManager, index) => (
          <DataRow
            className="cursor-pointer hover:bg-light-fill-blue hover:bg-opacity-50"
            dataTestId={`care-manager-table-row-${index}`}
            key={`care-manager-table-row-${careManager.id}`}
          >
            <DataCell className="w-6">
              <Checkbox
                ariaLabel={`Select care manager ${careManager.full_name} for removal`}
                checked={selectedCareManagers.includes(careManager.id)}
                id={`invoices-table-data-row-${careManager.id}`}
                onClick={(e) => e.stopPropagation()}
                onChange={() => toggleCareManagerSelected(careManager.id)}
              />
            </DataCell>
            <DataCell className="w-4/12">
              { `${careManager.last_name }, ${ careManager.first_name}` }
            </DataCell>
            <DataCell className="w-3/12">
              { orgIdToName[careManager.provider.id] }
            </DataCell>
            <DataCell className="w-2/12">
              { careManager.email }
            </DataCell>
            <DataCell className="w-3/12">
              { careManager.id }
            </DataCell>
          </DataRow>
        ))}
      </TableBody>
    </Table>
  );
};

const CareManagersTable = ({ feeScheduleId }) => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedCareManagers, setSelectedCareManagers] = useState([]);
  const [{ sortBy, sortDirection }, setSort] = useState({ sortBy: 'last_name', sortDirection: 'asc' });
  const [selectedOrganizationId, setSelectedOrganizationId] = useState('');
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
  const [showAddUserTable, setShowAddUserTable] = useState(false);
  const { data: feeSchedule } = useFindFeeSchedule(feeScheduleId);

  const AddCareManagerModal = useRef(null);

  const { data: careManagerResponse, isFetching } = useFindEmployeesByFeeSchedule(
    feeScheduleId,
    {
      page: {
        size: pageSize,
        number: pageNumber,
      },
      sort: sortDirection === 'asc' ? sortBy : `-${sortBy}`,
    },
  );
  const { data: careManagers, paging: careManagersPaging } = careManagerResponse.data;

  const resetAddCareManagerModal = () => {
    setSelectedOrganizationId('');
    setSelectedEmployeeIds([]);
    setShowAddUserTable(false);
  };

  const openModal = () => {
    resetAddCareManagerModal();
    AddCareManagerModal.current.openDialog();
  };

  const closeModal = () => {
    resetAddCareManagerModal();
    AddCareManagerModal.current.closeDialog();
  };

  const invalidateQueries = useInvalidateQueries();
  const { setRelationship: setCareManager } = useSetCareManager(
    {
      onSuccess: () => {
        closeModal();
        invalidateQueries('fee_schedule');
        invalidateQueries('employee');
      },
      onError: (error) => {
        const errorList = get(error, 'response.data.errors', []);
        const stringNotification = errorList.map((e) => e.title).join(', ');
        Notifier.dispatch(400, `Failed to set care manager: ${stringNotification}`);
      },
    },
  );

  const confirmationHandler = async (addUser, fsId, employeesIds) => {
    if (addUser) {
      await setCareManager(fsId, employeesIds);
      closeModal();
    } else {
      setShowAddUserTable(true);
    }
  };

  const ScrollToElementEvent = () => {
    ScrollToElement(document.getElementById('TopOfForms'));
  };

  if (isFetching) return <Spinner />;

  return (
    <div className="fee-schedule-base-card">
      <BaseCard className="p-6" noBorder>
        <BaseCardHeader title="Care Managers" noBorder className="px-4 font-bold">
          <Button
            id="add-users-button"
            iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="IconPlusCircle" />}
            className="flex"
            label="Add Users"
            data-testid="add-users-button"
            onClick={openModal}
            disabled={moment.utc().isAfter(moment(feeSchedule.ends_at).utc())}
          />
          <Button
            id="remove-users-button"
            iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="IconMinusCircle" />}
            className="flex"
            label="Remove Users"
            data-testid="remove-users-button"
            disabled
          />
        </BaseCardHeader>
        <BaseCardBody withPadding>
          { (careManagers?.length > 0) ? (
            <GenerateCareManagersTable
              careManagers={careManagers}
              selectedCareManagers={selectedCareManagers}
              setSelectedCareManagers={setSelectedCareManagers}
              sortBy={sortBy}
              sortDirection={sortDirection}
              setSort={setSort}
            />
          ) : (
            <EmptyTable
              className={'p-6 border border-solid border-light-border-blue'}
              message={'Click "Add Users" to add Care Managers'}
              dataTestId={'care-managers-empty-table'}
            />
          )}
          <div className="mt-6 mb-6">
            <Pagination
              pageNumber={pageNumber}
              pageSize={pageSize}
              totalItemCount={careManagersPaging.total_count}
              totalPageCount={careManagersPaging.total_pages}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
              scrollPaginationElement={ScrollToElementEvent}
            />
          </div>
        </BaseCardBody>
      </BaseCard>
      <DialogV2
        cancelHandler={closeModal}
        confirmationHandler={() => confirmationHandler(showAddUserTable, feeScheduleId, selectedEmployeeIds)}
        confirmLabel={showAddUserTable ? 'Add Users' : 'Next'}
        id={'add-care-manager-modal'}
        ref={AddCareManagerModal}
        title={'Add New Care Managers'}
        confirmationBtnDisabled={
          showAddUserTable ? selectedEmployeeIds.length === 0 : selectedOrganizationId.length === 0
        }
        width="6xl"
        confirmButtonTestId="add-care-manager-modal-confirm-button"
        dialogDescriptionClass="flex flex-col h-screen overflow-hidden bg-light-border-grey"
        scrollable={false}
        dialogWrapperPadding="p-12"
      >
        { showAddUserTable ? (
          <AddCareManagersTable
            selectedEmployeeIds={selectedEmployeeIds}
            setSelectedEmployeeIds={setSelectedEmployeeIds}
            organizationId={selectedOrganizationId}
            feeScheduleId={feeScheduleId}
          />
        ) : (
          <OrganizationSearchTable
            selectedOrganizationId={selectedOrganizationId}
            setSelectedOrganizationId={setSelectedOrganizationId}
          />
        )}
      </DialogV2>
    </div>
  );
};

GenerateCareManagersTable.propTypes = {
  careManagers: PropTypes.array.isRequired,
  selectedCareManagers: PropTypes.array.isRequired,
  setSelectedCareManagers: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  setSort: PropTypes.func.isRequired,
};

CareManagersTable.propTypes = {
  feeScheduleId: PropTypes.string.isRequired,
};

export default CareManagersTable;
