import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, InputField, Dialog } from '@unite-us/ui';
import { validateReduxForm } from 'common/form';
import { validations } from '@unite-us/app-components';
import { getDocTitle } from '../utils';

export class RenameDocumentDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const dialogJustOpened = !this.props.dialogOpen && (this.props.dialogOpen !== nextProps.dialogOpen);
    if (dialogJustOpened) {
      nextProps.fields.title.onChange(getDocTitle(nextProps.doc));
    }
  }

  onSubmit({ title }) {
    const { doc } = this.props;
    return Promise.resolve(this.props.onRenameDocument({
      ...doc,
      title,
    })).then(() => {
      this.props.resetForm();
      this.closeDialog('confirm');
    });
  }

  onCancel() {
    this.closeDialog('cancel');
  }

  closeDialog(result) {
    this.dialog.closeDialog(result);
  }

  render() {
    const {
      fields,
      handleSubmit,
    } = this.props;

    return (
      <Dialog
        className="rename-document-dialog"
        title="Rename Document"
        ref={(el) => { this.dialog = el; }}
        size="small"
        actions={(
          <div className="rename-document-dialog__actions">
            <Button
              className="rename-document-dialog__actions--cancel"
              label="Cancel"
              onClick={this.onCancel}
            />
            <Button
              className="rename-document-dialog__actions--save"
              label="Save"
              onClick={handleSubmit(this.onSubmit)}
              primary
              style={{ marginLeft: '12px' }}
            />
          </div>
        )}
      >
        <div className="rename-document-dialog__contents">
          <form
            className="rename-document-dialog__form"
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <InputField
              id="rename-document-title-field"
              label="File Name"
              field={fields.title}
              validations={validations.isRequired}
            />
          </form>
        </div>
      </Dialog>
    );
  }
}

RenameDocumentDialog.propTypes = {
  dialogOpen: PropTypes.bool,
  doc: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string, // for newly uploaded docs
    title: PropTypes.string, // for existing docs
  }).isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onRenameDocument: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
};

RenameDocumentDialog.defaultProps = {
  dialogOpen: false,
};

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {
      title: getDocTitle(ownProps.doc),
    },
  };
}

const fields = ['title'];
export default validateReduxForm({
  form: 'rename-document-dialog-form',
  fields,
}, mapStateToProps, null, null, { withRef: true })(RenameDocumentDialog);
