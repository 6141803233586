import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  tracker,
} from '@unite-us/client-utils';
import featureFlag from 'common/utils/FeatureFlag/FeatureFlag';
import IndexDetailView from 'src/components/Dashboard/components/IndexDetailView';
import SentReferralsIndex from 'src/components/Dashboard/components/Referrals/SentReferrals/SentReferralsIndex';
import { fetchDashboardReferrals } from 'actions/Referral/Group';
import CloseReferralModalDialog from
'src/components/Referrals/ReferralStatus/components/CloseReferralModalDialog';
import ConsentDialog from
'src/components/InformedConsent/components/ConsentDialog';
import { closeConsentDialog, openConsentDialog } from 'src/components/InformedConsent/utils';
import DetailView from 'src/components/Dashboard/components/DetailView';
import CaseDetailsView from 'src/components/Cases/components/Detail/CaseDetailsView';
import ReferralDetail from 'src/components/Referrals/ReferralDetail/index';
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';
import { fetchNetworkReferralOutcomes } from 'actions/Outcome/ServiceType/Network';
import {
  careCoordinatorFilterDefault,
  noneAssignedOption,
} from 'src/components/Dashboard/utils/filterDefaults';
import { getDataOfPage, isDataValid } from 'src/components/Dashboard/utils/dataHelpers';
import callOrLog from 'src/common/utils/callOrLog';
import { goToReferral } from 'src/components/Referrals/utils/routing';
import { goToCaseAsSentReferral } from 'src/components/Cases/utils/routing';
import { removeOptionalParamsFromPath } from 'src/common/utils/Navigation';
import { getReferralColumnConfig } from 'src/components/Dashboard/utils/columnConfigs/referrals';
import getOutcomesFromResponse from 'src/components/Dashboard/utils/getOutcomesFromResponse';
import { getGroupUsersOptions, getSentByOptions } from 'src/components/Groups/Users/utils';
import { getReasonsOptions } from 'src/components/Groups/utils';
import {
  filterVisibility,
  getReferralSubRouteFromStatus,
  getStatus,
  getTarget,
  isAcceptedClosedRoute,
  isAcceptedOpenOrClosed,
  sortStatuses,
} from 'src/components/Dashboard/components/Referrals/SentReferrals/utils';
import { REFERRALS_CLOSE_REFERRALS } from 'common/utils/FeatureFlag/utils/constants';
import {
  hideMoreFiltersOption,
  hasNewReasonFilter,
  crtb1290SortReferralsByOldest,
} from 'src/common/utils/FeatureFlags/flags';
import {
  CLOSE_REFERRAL_MODAL_DIALOG,
} from 'src/components/Referrals/ReferralStatus/constants';
import _ from 'lodash';
import {
  careCoordinatorsFilter,
  consentStatusFilter,
  serviceTypesFilter,
} from 'src/components/Dashboard/utils/filter';
import { DASHBOARD_EVENTS, DASHBOARD_VIEWS } from 'common/utils/EventTracker/utils/eventConstants';
import appendReferralIdToLocation from 'src/components/Dashboard/utils/appendReferralIdToLocation';
import { isCoordinationCenter } from 'common/utils/Group';
import { isSuperNetwork } from 'src/components/Network/utils';
import subNetworksFilter from 'src/components/Dashboard/utils/subNetworksFilter';
import labelCustomization from 'src/common/utils/Customization/labels';
import defaultLabels from 'src/constants/labels';

const SENT_STATUSES = [
  'sent',
  'in_review',
  'accepted',
  'declined',
  'recalled',
  'auto_recalled',
  'pending_authorization',
  'denied_authorization',
];

export class SentReferrals extends Component {
  constructor(props) {
    super(props);
    this.closeConsentDialog = this.closeConsentDialog.bind(this);
    this.closeReferral = this.closeReferral.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.onFiltersChange = this.onFiltersChange.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.requestOrUploadConsent = this.requestOrUploadConsent.bind(this);
    this.shouldFetch = this.shouldFetch.bind(this);
    this.fetch = this.fetch.bind(this);
    this.fetchGroupsUsers = this.fetchGroupsUsers.bind(this);
    this.fetchSentByUsers = this.fetchSentByUsers.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onToggle = this.onToggle.bind(this);

    this.state = {
      activeUsersInGroup: [],
      contact: {},
      outcomes: [],
      outcomesIsFetching: false,
      page: 1,
      referralId: '',
      sidx: 'updated_at',
      sord: 'desc',
      filters: {
        service_types: _.get(props, 'filters.service_types', []),
        status: getStatus(props),
        care_coordinator_users: _.get(
          props,
          'filters.care_coordinator_users',
          careCoordinatorFilterDefault(props.userId, props.isCaseManager && props.isCC),
        ),
        consent_status: _.get(props, 'filters.consent_status', []),
        sent_by: _.get(
          props,
          'filters.sent_by',
          [props.currentEmployee.id],
        ),
        resolved: _.get(props, 'filters.resolved', null),
        networks: _.get(props, 'filters,networks', []),
        reasons: _.get(props, 'filters.reasons', []),
      },
      employees: [],
      open: false,
    };
  }

  componentDidMount() {
    this.fetch();
    this.props.fetchProvidersUserCore({
      providers: this.props.groupId,
    }).then((response) => {
      if (response) {
        const { employees: activeUsersInGroup } = response;
        this.setState({ activeUsersInGroup, employees: response.employees });
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { route } = this.props;

    if (_.get(this.props, 'referrals.currentPage', 1) !== _.get(nextProps, 'referrals.currentPage', 1)) {
      this.setState({ page: nextProps.referrals.currentPage });
    }

    if (!this.props.refetch && nextProps.refetch) {
      this.fetch();
    }

    if (route.status !== nextProps.route.status ||
      (
        isAcceptedOpenOrClosed(route.status) &&
        isAcceptedClosedRoute(route) !== isAcceptedClosedRoute(nextProps.route)
      )
    ) {
      this.onFiltersChange('status', getStatus(nextProps));
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.fetch();
    }
  }

  onClose() {
    this.setState({ open: false });
  }

  onToggle() {
    const { open } = this.state;
    this.setState({ open: !open });
  }

  onCellClick(row) {
    const { referrals, route = {} } = this.props;
    const { page } = this.state;
    const subRoute = removeOptionalParamsFromPath(_.get(route, 'path'));

    const pagedData = getDataOfPage(referrals, page, this.props.sortAscending);
    const referral = pagedData.data[row];

    callOrLog(() => this.context.eventTracker(DASHBOARD_EVENTS.sentReferralsRow, {
      view: DASHBOARD_VIEWS.sentReferrals,
    }, { referral }));

    if (isAcceptedOpenOrClosed(route.status)) {
      goToCaseAsSentReferral({
        caseId: _.get(referral.case, 'id'),
        contactId: _.get(referral, 'contact.id'),
        pathname: `/dashboard/referrals/sent/${subRoute}`,
      });
    } else {
      goToReferral({
        ...referral,
        status: 'sent',
        subRoute,
      });
    }
  }

  onFiltersChange(key, activeFilters, callbacks) {
    const { route } = this.props;
    let newFilters = activeFilters;
    if (_.isEqual(key, 'care_coordinator_users')) {
      newFilters = careCoordinatorsFilter(activeFilters, callbacks);
    }
    this.setState({
      page: 1,
      filters: _.assign({}, this.state.filters, { [key]: newFilters }),
    }, () => {
      this.fetch();
      callOrLog(() => {
        const filters = tracker.buildDashboardFilterPayload(this.state, this.props);
        this.context.eventTracker(route.eventConstant, filters);
      });
    });
  }

  onNextClick() {
    const page = this.state.page + 1;

    this.shouldFetch(page);
  }

  onPrevClick() {
    const page = this.state.page - 1;

    this.shouldFetch(page);
  }

  closeConsentDialog() {
    closeConsentDialog(this.consentDialog);
  }

  shouldFetch(page) {
    if (isDataValid(this.props.referrals, page)) {
      this.setState({ page });
    } else {
      this.fetch(page);
    }
  }

  fetch(page = 1) {
    const { route, target } = this.props;
    const allStatuses = SENT_STATUSES.join(',');
    const formattedFilters = _.reduce(this.state.filters, (acc, f, key) => (
      f === 'all' ? acc : _.merge(acc, { [key]: f })
    ), {});
    formattedFilters.sent_by = _.uniq(
      _.map(formattedFilters.sent_by, (f) => f.split('-employee')[0]),
    );
    const status = formattedFilters.status || getStatus(this.props);
    const openOrClosed = isAcceptedOpenOrClosed(status);

    if (this.props.isCaseManager && _.isEmpty(formattedFilters.referral_sender_users)) {
      formattedFilters.referral_sender_users = openOrClosed ? null : ['all'];
    }

    const params = {
      target,
      sidx: this.state.sidx,
      sord: this.state.sord,
      filters: formattedFilters,
      page,
      allStatuses,
    };
    this.props.fetchDashboardReferrals(
      this.props.groupId,
      getReferralSubRouteFromStatus(status),
      openOrClosed ? {
        ...params,
        closed: isAcceptedClosedRoute(route),
        reset: true,
      } :
        params,
      this.state.filters.status !== 'all' ? this.state.filters.status : allStatuses,
      null,
    );
  }

  requestOrUploadConsent(data) {
    const contact = _.wget(data, 'contact', {});
    this.setState({ contact }, () => {
      openConsentDialog(this.consentDialog);
    });
  }

  closeReferral(data) {
    const referralId = _.wget(data, 'id');
    const networkId = _.wget(data, 'referred_by_network.id');
    const serviceTypeId = _.wget(data, 'service_type.id');

    this[CLOSE_REFERRAL_MODAL_DIALOG].openDialog();
    this.setState({ outcomesIsFetching: true }, () => (
      this.props.fetchNetworkReferralOutcomes(networkId, serviceTypeId).then((response) => this.setState({
        referralId,
        outcomes: getOutcomesFromResponse(response, serviceTypeId),
        outcomesIsFetching: false,
      }))
    ));
  }

  fetchGroupsUsers(search = '') {
    const { groupId } = this.props;
    return this.props.fetchProvidersUserCore({
      providers: groupId,
      options: { text: search },
    }).then((response) => {
      if (response) {
        const { employees: activeUsersInGroup } = response;
        if (search === '') {
          return _.concat(
            [noneAssignedOption(this.state)],
            getGroupUsersOptions(activeUsersInGroup, this.state),
          );
        }

        return getGroupUsersOptions(activeUsersInGroup, this.state);
      }

      return [];
    });
  }

  fetchSentByUsers(search = '') {
    const { groupId, currentEmployee } = this.props;
    return this.props.fetchProvidersUserCore({
      providers: groupId,
      options: {
        licensed: false,
        text: search,
      },
    })
      .then((response) => {
        if (response) {
          return getSentByOptions(
            response.employees,
            this.state,
            currentEmployee,
          );
        }

        return [];
      });
  }

  render() {
    const {
      currentEmployee,
      baseLocation,
      flagEnabled,
      isCC,
      isFetching,
      params,
      referrals,
      route,
      route: {
        status: routeStatus,
      },
      serviceTypes,
      currentGroup,
      groupId,
      target,
      showMoreFilters,
      reasons,
      hasNewReasonFilterFlag,
      labels,
    } = this.props;
    const {
      activeUsersInGroup,
      contact,
      open,
      outcomes,
      outcomesIsFetching,
      referralId,
      employees,
    } = this.state;

    const location = appendReferralIdToLocation(baseLocation, params);

    const pendingConsentActions = _.compact([
      {
        label: 'Request Or Upload Consent',
        action: this.requestOrUploadConsent,
      },
      flagEnabled(REFERRALS_CLOSE_REFERRALS, 'outbound-referrals') ? {
        label: 'Close Referral',
        action: this.closeReferral,
      } : null,
    ]);
    const groupIsSuperNetwork = isSuperNetwork(currentGroup.networks, groupId);
    const statuses = _.filter(this.props.statuses, (status) => status.value !== 'draft');
    const partialPath = removeOptionalParamsFromPath(_.get(route, 'path'));
    const columnConfig = getReferralColumnConfig(partialPath, statuses, pendingConsentActions, groupIsSuperNetwork);
    let filters = filterVisibility(_.compact([
      serviceTypesFilter(serviceTypes, this.state.filters.service_types),
      {
        key: 'care_coordinator_users',
        name: labels.CareCoordinator,
        pluralName: `${labels.CareCoordinator}s`,
        options: _.concat(
          [noneAssignedOption(this.state.filters.care_coordinator_users)],
          getGroupUsersOptions(activeUsersInGroup, this.state),
        ),
      },
      {
        key: 'sent_by',
        name: 'Sent By',
        pluralName: 'Sent By',
        options: getSentByOptions(employees, this.state, currentEmployee),
        asyncSearch: this.fetchSentByUsers,
        secondary: !showMoreFilters && target === 'sentReferralsAll',
      },
      ['pending', 'all'].includes(routeStatus) ?
        consentStatusFilter(this.state.filters.consent_status) : null,
      {
        key: 'status',
        name: 'Status',
        single: true,
        options: sortStatuses(statuses),
        value: this.state.filters.status,
      },
      {
        key: 'resolved',
        name: 'Resolution',
        single: true,
        options: [
          { label: 'Resolution (All)', value: null },
          { label: 'Resolved', value: 'true' },
          { label: 'Unresolved', value: 'false' },
        ],
        value: this.state.filters.resolved,
      },
      subNetworksFilter(currentGroup, this.state.filters.networks, true),
    ]), partialPath);

    if (hasNewReasonFilterFlag) {
      filters = _.concat(filters, filterVisibility(_.compact([
          {
            key: 'reasons',
            name: 'Reason',
            pluralName: 'Reasons',
            options: getReasonsOptions(reasons, this.state),
          },
        ]), partialPath));
    }

    const innerContentView = !isAcceptedOpenOrClosed(route.status) ? (
      <ReferralDetail
        subjectType="referral"
        key={params.id}
        location={location}
        params={params}
        type="sentReferrals"
        showContactColumn
      />
    ) : (
      <CaseDetailsView
        params={params}
        type="allCases"
        showContactColumn
        subjectType="case"
      />
    );

    const sentReferralDetailView = (
      <DetailView
        innerContentView={innerContentView}
      />
    );
    const pagedData = getDataOfPage(referrals, this.state.page, this.props.sortAscending);

    return (
      <div className="sent-referrals">
        <IndexDetailView
          columnConfig={columnConfig}
          params={this.props.params}
          DetailView={sentReferralDetailView}
          groupId={groupId}
          IndexView={SentReferralsIndex}
          data={pagedData.data}
          paging={pagedData.paging}
          isFetching={isFetching}
          onNextClick={this.onNextClick}
          onPrevClick={this.onPrevClick}
          onCellClick={this.onCellClick}
          initialSort={{ [this.state.sidx]: this.state.sord }}
          isCC={isCC}
          isSuperNetwork={groupIsSuperNetwork}
          onFiltersChange={this.onFiltersChange}
          filters={_.sortBy(filters, 'name')}
          statuses={statuses}
          route={route}
          fetchGroupsUsers={this.fetchGroupsUsers}
          onClose={this.onClose}
          onToggle={this.onToggle}
          open={open}
        />

        <ConsentDialog
          contact={contact}
          onNavigateAway={this.closeConsentDialog}
          ref={(el) => { this.consentDialog = el; }}
        />

        <CloseReferralModalDialog
          ref={(el) => { this[CLOSE_REFERRAL_MODAL_DIALOG] = el; }}
          referralId={referralId}
          outcomes={outcomes}
          outcomesIsFetching={outcomesIsFetching}
        />
      </div>
    );
  }
}

SentReferrals.propTypes = {
  location: PropTypes.object,
  currentEmployee: PropTypes.object.isRequired,
  baseLocation: PropTypes.string.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  fetchNetworkReferralOutcomes: PropTypes.func.isRequired,
  fetchDashboardReferrals: PropTypes.func.isRequired,
  flagEnabled: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isCC: PropTypes.bool.isRequired,
  isCaseManager: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  referrals: PropTypes.shape({
    data: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
  }).isRequired,
  refetch: PropTypes.bool.isRequired,
  route: PropTypes.shape({
    eventConstant: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  serviceTypes: PropTypes.array.isRequired,
  reasons: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  target: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  currentGroup: PropTypes.object.isRequired,
  showMoreFilters: PropTypes.bool,
  hasNewReasonFilterFlag: PropTypes.bool,
  labels: PropTypes.object,
  sortAscending: PropTypes.bool,
};

SentReferrals.defaultProps = {
  hasNewReasonFilterFlag: false,
  labels: defaultLabels,
  sortAscending: false,
};

function mapStateToProps(state, ownProps) {
  const groupId = _.get(state, 'session.groupId');
  const user = _.wget(state, 'user', {});
  const userGroups = _.get(state, 'groups.data', []);
  const target = getTarget(ownProps.route);
  const currentGroup = _.find(userGroups, { id: groupId }) || {};

  return {
    currentEmployee: _.wget(state, 'globalState.currentEmployee', {}),
    baseLocation: _.wget(ownProps, 'location.pathname', ''),
    groupId,
    currentGroup,
    // CORE-WORK - Need to resolve user.groups migration work
    isCC: isCoordinationCenter(_.get(currentGroup, 'networks', []), groupId),
    isFetching: _.get(state, `dashboard.${target}.isFetching`, false),
    referrals: _.get(state, `dashboard.${target}`, {}),
    refetch: _.get(state, 'dashboard.refetch'),
    serviceTypes: _.wget(state, 'serviceTypes.allNetworks', []),
    reasons: _.wget(state, 'session.enums.referrals.hold', []),
    statuses: _.reject(
      _.wget(state, 'session.enums.referrals.dashboard_statuses', []),
      (status) => _.includes(['recalled_or_auto_recalled', 'pending_consent', 'declined_consent'], status.value),
    ),
    target,
    token: _.get(state, 'session.token', ''),
    user,
    userId: _.wget(state, 'user.id', null),
    showMoreFilters: hideMoreFiltersOption(state),
    hasNewReasonFilterFlag: hasNewReasonFilter(state),
    labels: labelCustomization(state),
    sortAscending: crtb1290SortReferralsByOldest(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({
      fetchNetworkReferralOutcomes,
      fetchDashboardReferrals,
      fetchProvidersUserCore,
    }, dispatch),
    dispatch,
  };
}

SentReferrals.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default featureFlag(connect(mapStateToProps, mapDispatchToProps)(SentReferrals));
