import { get } from 'lodash';
import {
  FETCH_SEARCH_INDEX_RESULTS,
  SET_SEARCH_FETCHING,
  SET_SEARCH_INDEX_FETCHING,
  UNSET_SEARCH_FETCHING,
  UNSET_SEARCH_INDEX_FETCHING,
} from 'actions';

export const defaultState = {
  cancel: null,
  isFetching: false,
  isIndexFetching: false,
};

function cancelPreviousRequestIfExists(state) {
  const cancel = get(state, 'cancel', null);
  if (cancel !== null) {
    cancel();
  }
}

export default function searchReducer(state = defaultState, action) {
  const searchResults = get(action, 'payload.data.data', {});

  switch (action.type) {
    case FETCH_SEARCH_INDEX_RESULTS: {
      return {
        ...state,
        searchResults,
      };
    }

    case SET_SEARCH_INDEX_FETCHING: {
      cancelPreviousRequestIfExists(state);
      return {
        ...state,
        isIndexFetching: true,
        cancel: action.cancel,
      };
    }

    case UNSET_SEARCH_INDEX_FETCHING:
      return {
        ...state,
        cancel: null,
        isIndexFetching: false,
      };

    case SET_SEARCH_FETCHING: {
      cancelPreviousRequestIfExists(state);
      return {
        ...state,
        isFetching: true,
        cancel: action.cancel,
      };
    }

    case UNSET_SEARCH_FETCHING:
      return {
        ...state,
        cancel: null,
        isFetching: false,
      };

    default:
      return state;
  }
}
