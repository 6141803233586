import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Icon,
  InputField,
} from '@unite-us/ui';

const FilterSearch = (props) => {
  const { filters, label } = props;

  return (
    <div className="filter-search browse-filters-search">
      <div className="search-form form-group filter-search">
        <div>
          <InputField
            id="browse-search-input"
            className="form-control input browse-search"
            onChange={(event) => props.onFiltersChange(event.target.value, 'text')}
            value={get(filters, 'text', '')}
            hideHint
            hideError
            hideLabel
            label={label}
          />
        </div>

        <div className="search-icon">
          <Icon icon="IconSearch" />
        </div>
      </div>
    </div>
  );
};

FilterSearch.propTypes = {
  filters: PropTypes.shape({
    text: PropTypes.string,
  }).isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

FilterSearch.defaultProps = {
  filters: {
    text: '',
  },
  label: '',
};

export default FilterSearch;
