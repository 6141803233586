// Library Imports
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableHeaderColumn,
} from '@unite-us/ui';

// Util Imports
import { tableWidth } from 'common/helpers/Tables';
import { showFacesheetCasesTableDescription } from 'common/utils/FeatureFlags/flags';

// Component Imports
import EmptyCase from './EmptyCase';
import CaseTableRow from './CaseTableRow';

const CasesTable = (props) => {
  const {
    cases,
    groupId,
    showDescription,
  } = props;

  if (_.isEmpty(cases)) {
    return <EmptyCase />;
  }

  return (
    <Table noBorder id="casesTable">
      <TableHeader>
        <TableRow>
          <TableHeaderColumn style={{ width: tableWidth('icon') }} />
          <TableHeaderColumn
            style={{ width: tableWidth('small') }}
          >
            Date Opened
          </TableHeaderColumn>

          <TableHeaderColumn
            style={{ width: tableWidth('small') }}
          >
            Status
          </TableHeaderColumn>

          { showDescription && (
            <TableHeaderColumn style={{ width: tableWidth('medium') }}>
              Description
            </TableHeaderColumn>
          )}

          <TableHeaderColumn
            style={{ width: tableWidth('small') }}
          >
            Service Type
          </TableHeaderColumn>

          <TableHeaderColumn
            style={{ width: tableWidth('small') }}
          >
            Primary Worker
          </TableHeaderColumn>

          <TableHeaderColumn
            style={{ width: tableWidth('medium') }}
          >
            Organization
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>

      <TableBody>
        {cases.map((serviceCase, index) => {
          const serviceType = serviceCase.service;
          const primaryWorker = serviceCase.primary_worker;
          const serviceCaseGroup = serviceCase.provider;

          return (
            <CaseTableRow
              serviceCase={serviceCase}
              serviceType={serviceType}
              primaryWorker={primaryWorker}
              serviceCaseGroup={serviceCaseGroup}
              groupId={groupId}
              index={index}
              key={index}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

CasesTable.propTypes = {
  cases: PropTypes.array,
  groupId: PropTypes.string.isRequired,
  showDescription: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    showDescription: showFacesheetCasesTableDescription(state),
  };
}

export default connect(mapStateToProps)(CasesTable);
