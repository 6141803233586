import { theme } from 'src/../tailwind.config';
import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Icon } from '@unite-us/ui';
import { capitalize, isEmpty } from 'lodash';
import { browserHistory } from 'common/utils/browserHistory';
import stateBgColor from 'src/common/utils/stateBgColor';
import { useFindPlan } from 'src/components/Backoffice/api/hooks/v1/planHooks';

const PlanSummary = ({ planId, feeScheduleId }) => {
  const { data: plan } = useFindPlan(planId);
  const payerId = plan?.payer?.id;
  const planName = plan?.name;
  const state = isEmpty(plan?.state) ? 'Loading...' : plan.state;

  const EditPlan = () => {
    const feeSchedulePath = `/backoffice/fee_schedules/${feeScheduleId}/plans/${planId}/edit`;
    const payerPath = `/backoffice/payers/${payerId}/plans/${planId}/edit`;

    browserHistory.push({
      pathname: feeScheduleId ? feeSchedulePath : payerPath,
    });
  };

  return (
    <div className="PlanSummary">
      <div className="flex flex-row justify-between">
        <div className="flex bg-white justify-start items-center flex-nowrap">
          <h1 className="pr-4 font-bold text-2xl leading-8">{planName}</h1>
          <Badge
            className="flex flex-row justify-center px-10 py-2 flex-nowrap items-center"
            text={capitalize(state)}
            color={stateBgColor(state)}
          />
        </div>
        <div className="flex flex-nowrap">
          <Button
            id="edit-plan-button"
            className="mx-2"
            iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="V2Edit" />}
            label="Edit"
            onClick={EditPlan}
          />
        </div>
      </div>
    </div>
  );
};

PlanSummary.propTypes = {
  planId: PropTypes.string.isRequired,
  feeScheduleId: PropTypes.string,
};

PlanSummary.defaultProps = {
  feeScheduleId: '',
};

export default PlanSummary;
