const SHOW_ALL_FIELDS = 'full';
const SHOW_CITY_AND_STATE = 'city_state';
const SHOW_CITY_STATE_POSTAL_CODE = 'city_state_postal_code';
const SHOW_STREET_CITY_STATE_POSTAL_CODE = 'street_city_state_postal_code';
const SHOW_POSTAL_CODE = 'postal_code';
const SHOW_MAILING_ADDRESS = 'mailing_address';
const PRIMARY_ADDRESS_TOOLTIP =
  'Select this check box to make this the default address when searching for nearby programs. ' +
  'You can always update which address you’re using while searching.';

const DISPLAY_FULL = [
  SHOW_ALL_FIELDS,
];

const DISPLAY_CITY_STATE = [
  SHOW_CITY_AND_STATE,
  SHOW_CITY_STATE_POSTAL_CODE,
  SHOW_ALL_FIELDS,
  SHOW_STREET_CITY_STATE_POSTAL_CODE,
  SHOW_MAILING_ADDRESS,
];

const DISPLAY_POSTAL_CODE = [
  SHOW_CITY_STATE_POSTAL_CODE,
  SHOW_ALL_FIELDS,
  SHOW_POSTAL_CODE,
  SHOW_STREET_CITY_STATE_POSTAL_CODE,
  SHOW_MAILING_ADDRESS,
];

const DISPLAY_STREET_CITY_STATE_POSTAL_CODE = [
  SHOW_ALL_FIELDS,
  SHOW_STREET_CITY_STATE_POSTAL_CODE,
  SHOW_MAILING_ADDRESS,
];

const DISPLAY_ADDRESS_TYPE = [
  SHOW_MAILING_ADDRESS,
  SHOW_ALL_FIELDS,
];

const DISPLAY_MAILING_ADDRESS = [
  SHOW_MAILING_ADDRESS,
];

export {
  DISPLAY_FULL,
  DISPLAY_CITY_STATE,
  DISPLAY_POSTAL_CODE,
  DISPLAY_STREET_CITY_STATE_POSTAL_CODE,
  DISPLAY_ADDRESS_TYPE,
  DISPLAY_MAILING_ADDRESS,
  SHOW_ALL_FIELDS,
  SHOW_CITY_AND_STATE,
  SHOW_CITY_STATE_POSTAL_CODE,
  SHOW_POSTAL_CODE,
  SHOW_STREET_CITY_STATE_POSTAL_CODE,
  SHOW_MAILING_ADDRESS,
  PRIMARY_ADDRESS_TOOLTIP,
};
