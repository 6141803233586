import React from 'react';
import PropTypes from 'prop-types';
import FeeScheduleProgramDetail from './FeeScheduleProgramDetail';
import FeeScheduleProgramElegibility from './FeeScheduleProgramElegibility';

const FeeScheduleProgramOverview = ({ feeScheduleProgram }) => (
  <>
    <FeeScheduleProgramDetail feeScheduleProgram={feeScheduleProgram} />
    <FeeScheduleProgramElegibility feeScheduleProgram={feeScheduleProgram} />
  </>
);

FeeScheduleProgramOverview.propTypes = {
  feeScheduleProgram: PropTypes.object.isRequired,
};

export default FeeScheduleProgramOverview;
