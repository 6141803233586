import axios from 'axios';
import _ from 'lodash';
import Notifier from 'common/helpers/Notifier';
import { FETCH_IP_POSITION } from 'actions';
import { IP_INFO_KEY } from 'src/config/env/env.config';

export default function fetchIpPosition() {
  const headers = { Authorization: `Bearer ${IP_INFO_KEY}` };
  const request = axios.get('https://ipinfo.io/json', { headers });

  return (dispatch) => {
    function onSuccess(response) {
      const location = _.split(_.get(response, 'data.loc'), ',');
      const ipLocation = {
        lat: Number(_.first(location)),
        long: Number(_.last(location)),
      };

      dispatch({
        type: FETCH_IP_POSITION,
        payload: ipLocation,
      });

      return response;
    }

    function onError(error) {
      Notifier.handleErrors(error);
    }

    return request.then(onSuccess, onError);
  };
}
