import axios, { CancelToken } from 'axios';
import {
  DASHBOARD_FETCH_SCREENINGS,
  SET_DASHBOARD_FETCHING,
  SCREENING_CONTACTS_FETCHED,
} from 'actions';
import _ from 'lodash';
import Notifier from 'common/helpers/Notifier';
import { unsetDashboardFetching } from 'actions/Dashboard';
import {
  formatScreeningPayload,
  getContactsFromScreenings,
} from 'src/components/Dashboard/utils/actions';

const defaultOptions = {
  filters: {},
  page: 1,
  sidx: 'last_name',
  sord: 'asc',
};

function createParams(options) {
  return _.merge(
    {
      page: options.page,
      sidx: options.sidx,
      sord: options.sord,
    },
    _.reduce(options.filters, (acc, f, key) => (
      _.merge(acc, { [`q[${key}]`]: f })
    ), {}),
  );
}

function fetchDashboardScreenings({ groupId, options = defaultOptions } = {}) {
  return (dispatch) => {
    let cancel;

    const request = axios.get(`/groups/${groupId}/screenings`, {
      params: createParams(options),
      cancelToken: new CancelToken((c) => { cancel = c; }),
    });

    function onSuccess(payload) {
      const contacts = getContactsFromScreenings(_.get(payload, 'data.data', []));
      const adjustedPayload = formatScreeningPayload(payload);

      if (!_.isEmpty(contacts)) {
        dispatch({
          type: SCREENING_CONTACTS_FETCHED,
          payload: contacts,
        });
      }

      dispatch({
        type: DASHBOARD_FETCH_SCREENINGS,
        payload: adjustedPayload,
        target: 'screeningsAll',
        filters: options.filters,
      });

      return adjustedPayload;
    }

    function onError(error) {
      Notifier.handleErrors(error);

      return error;
    }

    dispatch({
      type: SET_DASHBOARD_FETCHING,
      target: 'screeningsAll',
      filters: options.filters,
      cancel,
    });

    return request.then(onSuccess, onError)
      .then((response) => (
        dispatch(unsetDashboardFetching(response, 'screeningsAll'))
      ));
  };
}

export default fetchDashboardScreenings;
