import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import {
  intersection,
  get,
  isEmpty,
  omitBy,
} from 'lodash';
import {
  SelectField,
  BaseCard,
  BaseCardHeader,
  BaseCardBody,
  InputField,
  FormLegend,
} from '@unite-us/ui';
import {
  DraftEditorField,
  validations,
} from '@unite-us/client-utils';
import { EMPTY_DRAFT_FIELD } from 'src/components/Group/Programs/constants';
import { ORG_STATES } from '../../constants';
import {
  PERMISSION_CATEGORIES,
} from '../../utils/getProgramPermissions';
import ServiceTypesInfo from './ServiceTypesInfo';

// FIXED service types should never be shown, but their values
// should be maintained through updates.
const FIXED_SERVICE_TYPES = [
  PERMISSION_CATEGORIES.UU_PAYMENTS,
];

const ProgramInfoForm = ({
  acceptingReferrals,
  activeServiceTypes,
  initialValues,
  providerState,
}) => {
  const hasFeeScheduleProgram = !isEmpty(
    get(initialValues, 'fee_schedule_program', null),
  );

  const filteredServiceTypes = useMemo(() => (
    omitBy(activeServiceTypes, (st) => (
      intersection(FIXED_SERVICE_TYPES, [st.code, ...st.children.map(((child) => child.code))]).length > 0
    ))
  ), [activeServiceTypes]);

  const serviceTypesOptions = Object.values(filteredServiceTypes).reduce(
    (acc, serviceType) => [
      ...acc,
      {
        id: serviceType.id,
        name: `${serviceType.name}${serviceType.sensitive ? '*' : ''}`,
        disabled:
          providerState !== ORG_STATES.draft &&
          serviceType.sensitive &&
          serviceType.children.length === 0,
        children: serviceType.children.reduce(
          (childAcc, child) => [
            ...childAcc,
            {
              id: child.id,
              name: `${child.name}${(serviceType.sensitive || child.sensitive) ? '*' : ''}`,
              disabled:
                providerState !== ORG_STATES.draft && (serviceType.sensitive || child.sensitive),
              parent: serviceType.id,
            },
          ],
          [],
        ),
      },
    ],
    [],
  );

  if (providerState === ORG_STATES.draft) {
    serviceTypesOptions.sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <BaseCard>
      <BaseCardHeader title="Program Info" />
      <BaseCardBody className="px-8 pt-4 pb-10">
        <FormLegend />
        <Field
          name="name"
          data-test-element="name"
          validate={(value) => validations.isRequired(value)}
        >
          {(params) => (
            <InputField
              autoFocus
              disabled={hasFeeScheduleProgram}
              id="program-name"
              label="Program Name"
              required
              {...params}
            />
          )}
        </Field>
        <Field
          validate={(value) => validations.isRequired(
            value === EMPTY_DRAFT_FIELD ? null : value,
          )}
          name="description"
        >
          {(params) => (
            <>
              <DraftEditorField
                dataTestId="program-description"
                id="program-description"
                label="Program Description"
                readOnly={hasFeeScheduleProgram}
                value={initialValues.description}
                required
                {...params}
              />
            </>
          )}
        </Field>
        <Field name="services">
          {(params) => (
            <SelectField
              id="service-types"
              label="Service Types Offered"
              multiple
              options={serviceTypesOptions}
              forceObjectValue
              shouldSort={false}
              disabled={hasFeeScheduleProgram}
              labelKey="name"
              valueKey="id"
              postLabelContent={ServiceTypesInfo({
                orgIsDraft: providerState === ORG_STATES.draft,
              })}
              required={acceptingReferrals ? 'required' : ''}
              {...params}
            />
          )}
        </Field>
      </BaseCardBody>
    </BaseCard>
  );
};

ProgramInfoForm.propTypes = {
  acceptingReferrals: PropTypes.bool,
  activeServiceTypes: PropTypes.object,
  currentServices: PropTypes.array,
  initialValues: PropTypes.shape({
    description: PropTypes.string,
    fee_schedule_program: PropTypes.object,
  }),
  providerState: PropTypes.string.isRequired,
};

ProgramInfoForm.defaultProps = {
  acceptingReferrals: false,
  activeServiceTypes: {},
  currentServices: [],
  initialValues: {},
};

export default ProgramInfoForm;
