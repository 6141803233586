import { isEmpty, get } from 'lodash';
import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import fetchGroupContact from 'src/actions/Contact/Group/fetchGroupContact';
import { coreApi } from 'src/api/config';

const updateGroupContact =
  (groupId, contactId, contact, options = {}) => async (dispatch) => {
    const providerResponse = await coreApi.findRecord('provider', groupId);
    const provider = get(providerResponse, 'data.data', null);
    const isCC = get(provider, 'provider_type') === 'coordination_center';

    if (!isCC || options.forceClientRelationship) {
      const existingRelationship = await coreApi.query('client_relationship', {
        person: contactId,
        provider: groupId,
      });
      const relationship = existingRelationship.data.data;
      if (isEmpty(relationship)) {
        await coreApi.createRecord('client_relationship', {
          person: contactId,
          provider: groupId,
        });
      }
    }

    if (!isEmpty(contact)) {
      const response = await coreApi.updateRecord('person', contactId, {
        ...contact,
      });

      if (!isHttpSuccess(response.status)) {
        Notifier.handleErrors(response);
        return response;
      }

      if (options.showNotification) {
        Notifier.dispatch(
          response.status,
          options.showNotification === true ?
            'Client Successfully Updated' :
            options.showNotification,
        );
      }
    }

    return dispatch(fetchGroupContact(groupId, contactId));
  };

export default updateGroupContact;
