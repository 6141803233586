import { get } from 'lodash';

const isMostRecentInvoice = (invoices = [], currentInvoice = {}) => {
  const lastInvoice = invoices[invoices.length - 1];

  // sets button on original invoice
  if (invoices.length === 1) {
    return true;
  }
  // sets button on subsequent invoices
  if (get(lastInvoice, 'id', {}) === currentInvoice.id) {
    return true;
  }
  return false;
};

export default isMostRecentInvoice;
