export const createAnswerIdSet = (questions) => {
  const answerSet = new Set();
  questions.forEach((question) => {
    if (question.choices) {
      question.choices.forEach((answer) => {
        answerSet.add(answer.id ?? answer.value);
      });
    }
  });
  return answerSet;
};

export const getUpdatedFormNeedConfigurations = (formNeedConfigurations, deletedAnswerIdSet) => {
  const updatedFormNeedConfigurations = [];
  formNeedConfigurations.forEach((formNeedConfig) => {
    const originalLength = formNeedConfig.input_option_scores.length;
    const updatedFormNeedConfig = {
      ...formNeedConfig,
      input_option_scores: formNeedConfig.input_option_scores.filter((inputOption) => (
         !deletedAnswerIdSet.has(inputOption.input_option_id)
      )),
    };
    if (updatedFormNeedConfig.input_option_scores.length !== originalLength) {
      updatedFormNeedConfigurations.push(updatedFormNeedConfig);
    }
  });
  return updatedFormNeedConfigurations;
};
