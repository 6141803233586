import { coreApi } from 'src/api/config';
import {
  FETCH_GROUP_SCREENING,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import { get, isEmpty } from 'lodash';
import { selectContact } from 'actions/Contact';

const fetchGroupScreening = ({ screeningId }) => (
  async (dispatch) => {
    try {
      const payload = await coreApi.findRecord('form_submission', screeningId);

      const formSubmission = get(payload, 'data.data', {});
      const identifiedNeeds = get(formSubmission, 'identified_needs', []);
      const serviceIds = identifiedNeeds.map((n) => n.id);
      const servicesQuery = !isEmpty(serviceIds) ? await coreApi.query('services', { id: serviceIds.join(',') }) : {};
      const services = get(servicesQuery, 'data.data', []);

      formSubmission.identified_needs = services;
      formSubmission.needs = [];
      services.forEach((service) => {
        if (identifiedNeeds.map((n) => n.id).includes(service.id)) {
          formSubmission.needs.push({ service_type: service });
        }
      });

      selectContact(formSubmission.context.id)(dispatch);
      dispatch({
        type: FETCH_GROUP_SCREENING,
        payload,
        id: screeningId,
      });

      return payload;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  }
);

export default fetchGroupScreening;
