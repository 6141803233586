import _ from 'lodash';

function getSentByOptions(
  employees = [],
  state = {},
  currentEmployee = {},
) {
  return _.uniqBy(_.map(_.concat(employees, currentEmployee), (employee) => (
    {
      label: employee.full_name,
      value: `${employee.id}-employee`,
      initial: _.includes(_.map(
        state.filters.sent_by,
        (f) => f.split('-employee')[0],
      ), employee.id),
    }
  )), 'value');
}

export default getSentByOptions;
