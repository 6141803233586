import { SEARCH_CONTACT } from 'actions';
import moment from 'moment';
import Notifier from 'common/helpers/Notifier';
import { cl1018PreferredLanguagesFields } from 'common/utils/FeatureFlags/flags';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { isHttp500Error } from 'common/utils/Error';
import { coreApi } from 'src/api/config';
import { isString, concat, lowerCase, get } from 'lodash';

export const PHONE_TYPES = ['home', 'work', 'mobile', 'fax', 'unknown'];

const buildContactsWithoutUURecords = (list) => {
  try {
    return list.map((person) => {
      const dob = person?.date_of_birth;
      return {
        ...person,
        full_name: `${person?.first_name} ${person?.last_name}`,
        date_of_birth: isString(dob) ? Math.floor(new Date(dob).getTime() / 1000) : dob,
        phone_numbers: get(person, 'phone_numbers', []).map((phone) => ({
          ...phone,
          phone_type: PHONE_TYPES.includes(lowerCase(phone?.phone_type)) ? phone.phone_type : 'unknown',
        })),
        external: true,
      };
    });
  } catch (error) {
    return [];
  }
};

const getRelatedEntities = async (list, preferredLanguagesEnabled) => {
  try {
    return await Promise.all(list.map(async (person) => {
      const recordLanguagesResponse = preferredLanguagesEnabled ? await coreApi.query(
        'record_languages',
        { record_id: person.id, record_type: 'Person' },
      ) : { data: { data: [] } };

      if (person.addresses.length) {
        const addressResponse = await coreApi.query('address', { person: person.id });
        return {
          ...person,
          addresses: addressResponse.data.data,
          preferred_languages: recordLanguagesResponse.data.data,
          external: false,
        };
      }
      return {
        ...person,
        preferred_languages: recordLanguagesResponse.data.data,
        external: false,
      };
    }));
  } catch (error) {
    return [];
  }
};

export default function searchContact(groupId, contact, showErrors = false) {
  return async (dispatch, getState) => {
    let listPersonMatches = [];
    let coreResponse = await coreApi.query('empi_person', {
      first_name: contact.first_name,
      last_name: contact.last_name,
      date_of_birth: moment.unix(contact.date_of_birth).utc().format('YYYY-MM-DD'),
    });

    if (isHttp500Error(coreResponse.status)) {
      coreResponse = await coreApi.query('person', {
        last_name: contact.last_name,
        date_of_birth: moment.unix(contact.date_of_birth).utc().format('YYYY-MM-DD'),
        'client_relationships.provider': groupId,
      });
      listPersonMatches = coreResponse.data.data;
    } else if (!isHttpSuccess(coreResponse.status) && showErrors) {
      return Notifier.handleErrors(coreResponse.status);
    } else {
      await coreApi.populateRelationship('person', 'person', coreResponse.data.data);
      listPersonMatches = coreResponse.data.data
        .filter((empiPerson) => empiPerson.person)
        .map((empiPerson) => empiPerson.person);
    }

    const contactsWithoutUURecords = coreResponse.data.data
      .filter((empiPerson) => !empiPerson.person);

    const state = getState();
    const preferredLanguagesEnabled = cl1018PreferredLanguagesFields(state);
    let list = concat(
      await getRelatedEntities(listPersonMatches, preferredLanguagesEnabled),
      buildContactsWithoutUURecords(contactsWithoutUURecords),
    );

    list = list.map((item) => ({
      item: {
        result: {
          ...item,
          date_of_birth: item.date_of_birth,
        },
      },
    }));

    const payload = {
      ...coreResponse,
      data: {
        ...coreResponse.data,
        data: list,
      },
    };

    dispatch({ type: SEARCH_CONTACT, payload });
    return payload;
  };
}
