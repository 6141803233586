// UI Copy Text
export const CONTACT_INFORMATION_FORM = 'contact-information';
export const CONTACT_SEARCH_FORM = 'contact';
export const COPY_CREATE_CLIENT = 'Create Client';
export const COPY_UPDATE_CLIENT = 'Update Client';
export const COPY_CREATE_CLIENT_SEARCH_SUB_HEADER =
  'If your client has an existing record, we can use it to expedite the creation process.';
export const COPY_MATCHING_RECORDS = 'We Found Matching Records';
export const COPY_MATCHING_RECORDS_SUB_HEADER = 'The information you provided matched our existing records.';
export const NOT_FOUND_HEADER_COPY = 'We did not find your client in our records.';
export const REGULAR_HEADER_COPY = 'Please fill out your client\'s basic contact information below.';

// Basic Route Paths
export const ADD_INFORMATION_PATH = 'new/add-contact-information';
export const CONFIRM_PATH = 'new/confirm';
export const SEARCH_PATH = 'new/search';

// Screenings Route Paths
export const NEW_SCREENING_PATH = '/screenings/new/screening';

// Referral Route Paths
export const NEW_REFERRAL_PATH = '/referrals/new/add-service-types';
export const NEW_REFERRAL_REVIEW_PATH = '/referrals/new/final-review';
export const NEW_REFERRAL_SUPPORTING_PATH = '/referrals/new/add-supporting-details';

// Org Route Paths
export const DRAFT_ORG_SETTINGS = 'settings';
export const DRAFT_ORG_USER_SETTINGS = 'settings/users';
