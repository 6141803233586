import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { SelectField } from '@unite-us/ui';

class ScreeningFormSelect extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = {
      form: null,
    };
  }

  componentDidMount() {
    if (get(this.props, 'forms.length') === 1) {
      this.onChange(this.props.forms[0]);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (get(this.props, 'forms.length') !== 1 && get(nextProps, 'forms.length') === 1) {
      this.onChange(nextProps.forms[0]);
    }
  }

  onChange(value) {
    this.setState({ form: value });
    this.props.onFormSelect(value);
  }

  render() {
    const {
      forms,
    } = this.props;

    if (get(forms, 'length') === 1) {
      return (
        <div className="screening-form-name mb-one">
          <h1>
            {forms[0].name}
          </h1>
        </div>
      );
    }

    return (
      <div className="screening-form-select">
        <SelectField
          id="screening-form-select"
          label="Screening Form"
          value={this.state.form}
          options={forms}
          valueKey="id"
          labelKey="name"
          onChange={this.onChange}
        />
      </div>
    );
  }
}

ScreeningFormSelect.propTypes = {
  forms: PropTypes.array,
  onFormSelect: PropTypes.func.isRequired,
};

export default ScreeningFormSelect;
