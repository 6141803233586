import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { CommunicationAdditionalInfo } from 'common/display/Profile';

function Email(props) {
  const {
    item: email,
    noLink,
    shortDisplay,
  } = props;

  if (_.isEmpty(email)) {
    return null;
  }

  if (noLink) {
    return (
      <div className="email">
        <p>{email.email_address}</p>
      </div>
    );
  }

  const additionalInfo = (
    <CommunicationAdditionalInfo
      communicationTypes={email.acceptable_communication_types}
      isPrimary={email.is_primary}
    />
  );
  return (
    <div className="email">
      {!shortDisplay && additionalInfo}
      <p>
        <a href={`mailto:${email.email_address}`} title={email.email_address}>
          {_.truncate(email.email_address, { length: 40 })}
        </a>
      </p>
    </div>
  );
}

Email.propTypes = {
  item: PropTypes.object,
  shortDisplay: PropTypes.bool,
  noLink: PropTypes.bool,
};

export default Email;
