import { find, get } from 'lodash';

function getGroupData({ fetchedGroupData, selectedGroupId, slimGroups }) {
  if (!selectedGroupId) {
    return {};
  }

  if (selectedGroupId !== get(fetchedGroupData, 'id')) {
    return find(slimGroups, { id: selectedGroupId }) || {};
  }

  return fetchedGroupData;
}

export default getGroupData;
