import _ from 'lodash';
import {
  ADD_USER_TO_GROUPS_PROGRAM,
  FETCH_PROFILE,
  FETCH_USER_FOR_SESSION_FAILURE,
  FETCH_USER_FOR_SESSION_INITIATED,
  FETCH_USER_FOR_SESSION_SUCCESS,
  UPDATE_AVATAR,
  UPDATE_GROUPS_USER,
  UPDATE_USER,
  USER_LOAD_FROM_SESSIONSTORAGE,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from 'actions';
import { SESSION_USER_KEY } from 'src/common/constants';
import { saveCurrentUserToSessionStorage } from 'common/utils/utils';

const defaultState = {};

function isUpdatingCurrentUser(user, currentUserId) {
  return _.get(user, 'id') === currentUserId;
}

export default function userReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_PROFILE:
      return action.payload.status === 200 ?
        action.payload.data.data.user :
        state;

    case USER_LOGIN_SUCCESS:
      return action.payload.status === 201 ?
        { ...state, ...action.payload.data.data.user } :
        state;

    case FETCH_USER_FOR_SESSION_INITIATED:
      return { ...state, fetchingUser: true };

    case FETCH_USER_FOR_SESSION_SUCCESS: {
      const newState = {
        ...state,
        ...action.payload.data.data,
        fetchingUser: false,
        fetchUserRetries: 0,
      };
      saveCurrentUserToSessionStorage(newState);

      return newState;
    }

    case FETCH_USER_FOR_SESSION_FAILURE: {
      const newState = {
        ...state,
        fetchingUser: false,
        fetchUserRetries: action.retries,
      };

      saveCurrentUserToSessionStorage(newState);
      return newState;
    }

    case ADD_USER_TO_GROUPS_PROGRAM:
    case UPDATE_GROUPS_USER: {
      const user = _.get(action, 'payload.data.data.user', {});

      if (isUpdatingCurrentUser(user, state.id)) {
        const newState = { ...state, ...user };
        saveCurrentUserToSessionStorage(newState);

        return newState;
      }

      return state;
    }

    case UPDATE_USER:
    case UPDATE_AVATAR: {
      const newState = { ...state, ...action.payload.data.data };
      saveCurrentUserToSessionStorage(newState);
      return newState;
    }

    case USER_LOGOUT:
      sessionStorage.removeItem(SESSION_USER_KEY);
      return {};

    case USER_LOAD_FROM_SESSIONSTORAGE:
      return _.assign({}, state, action.payload.user);

    default:
      return state;
  }
}
