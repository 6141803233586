import { addresses } from '@unite-us/client-utils';
import { formatNumber } from 'humanize-plus';

export const appendDistanceToName = (programOrProviderLocations, name, originCoordinates) => {
  const getDistance = (destinationLatLng) => addresses.getDistanceInMiles(originCoordinates, destinationLatLng, {
    format: 'float',
  });
  const distances = programOrProviderLocations
    .map((address) => {
      if (address.coordinates) {
        return getDistance([address.coordinates.lat, address.coordinates.lon]);
      }
      if (address.latitude && address.longitude) {
        return getDistance([address.latitude, address.longitude]);
      }
      return getDistance([undefined, undefined]);
    })
    .filter((distance) => distance !== '');
  const distance = Math.min(...distances);
  const miles = distance !== Infinity ? formatNumber(distance, 2) : 'no distance';
  const newName = miles !== 'no distance' ? `${name} (${miles} mi)` : `${name}`;

  return newName;
};

function getProgramAndProviderOptions(
  programsAndProviders = [],
  selectedIds = [],
  originCoordinates,
  canPaginateNetworkGroups,
) {
  return programsAndProviders.map((programOrProvider) => {
    let programOrProviderName = '';
    if (canPaginateNetworkGroups) {
      if (programOrProvider.locations) {
        programOrProviderName = appendDistanceToName(
          programOrProvider.locations,
          `${programOrProvider.name} | ${programOrProvider.provider_name}`,
          originCoordinates,
        );
      } else if (programOrProvider.addresses) {
        programOrProviderName = appendDistanceToName(
          programOrProvider.addresses,
          `Unspecified program | ${programOrProvider.name}`,
          originCoordinates,
        );
      }
    }

    return {
      disabled: selectedIds.includes(programOrProvider.id),
      displayName: programOrProviderName,
      id: programOrProvider.id,
      provider_id: programOrProvider.provider_id,
      name: programOrProvider.name,
    };
  });
}

export default getProgramAndProviderOptions;
