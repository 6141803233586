import { get } from 'lodash';

const getGroupCenter = (group) => {
  // Not ideal here to destructure latitude and longitude
  // Ideally we need to convert the v3 data to v4 data format as part of consolidation in the groupsReducer
  // Temporary fix since we don't know the implication of changing it at the reducer level
  const {
    lat_lng,
    latitude,
    longitude,
  } = get(group, 'addresses[0]', []);

  const lat = lat_lng ? lat_lng[0] : latitude;
  const lng = lat_lng ? lat_lng[1] : longitude;

  if (!lat && !lng) {
    return undefined;
  }

  return {
    lat,
    lng,
  };
};

export default getGroupCenter;
