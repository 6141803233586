import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@unite-us/ui';
import _ from 'lodash';
import { createOptions, makeCustomOption } from './utils';

class SingleSelectWithCustomValue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customOption: {},
    };

    this.onChange = this.onChange.bind(this);
    this.fetcher = this.fetcher.bind(this);
    this.resetOptions = this.resetOptions.bind(this);
  }

  componentDidMount() {
    this.resetOptions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.field.active !== prevProps.field.active) {
      this.props.touch(this.props.field.name);
    }
  }

  onChange(selected) {
    this.props.onChange(selected);
  }

  resetOptions() {
    if (this.props.resetOptions) {
      this.selectField.onSearch({ detail: { value: '' } });
    }
  }

  fetcher(search) {
    const {
      canPaginateNetworkGroups,
      disableCustomValue,
      debouncedSearchNetworkGroups,
      groupsOptionType,
      options,
      labelKey,
      valueKey,
    } = this.props;

    // create the custom option that user inputs in field.
    const customOption = disableCustomValue ? {} : makeCustomOption({
      labelKey,
      options,
      search,
      valueKey,
    });

    // need to set state so that when we build options on line 74, we include the custom option.
    this.setState({ customOption });

    // if the feature flag is ON, then we need to do a debounced search including the input text.
    if (canPaginateNetworkGroups && _.isFunction(debouncedSearchNetworkGroups)) {
      debouncedSearchNetworkGroups(search, groupsOptionType);
    }
    // we create options including the custom option on every letter entered in the input field.
    // returning the newPromise will trigger setting options in SelectField.
    return new Promise((resolve) => {
      resolve({
        options: createOptions({
          labelKey,
          options,
          search,
          valueKey,
        }, disableCustomValue),
      });
    });
  }

  render() {
    const { options, field, shouldSearchOnChange } = this.props;
    const { customOption } = this.state;
    /*
      * On every render, if a custom option exists in state (from line 51)
        we need to build options with that custom option.

      * Options from props will change when the debouncedSearchNetworkGroups
        function returns new options. If this is the case, then SelectField will recognize
        those new options and update them accordingly.
    */

    return (
      <SelectField
        {..._.uuOmit(this.props, ['options'])}
        className="single-select-with-custom-value"
        options={_.compact([customOption, ...options])}
        field={{
          ...field,
          onBlur: this.resetOptions,
        }}
        loadOptions={this.fetcher}
        onChange={this.onChange}
        ref={(n) => { this.selectField = n; }}
        shouldSort={false}
        shouldSearchOnChange={shouldSearchOnChange}
      />
    );
  }
}

SingleSelectWithCustomValue.propTypes = {
  canPaginateNetworkGroups: PropTypes.bool.isRequired,
  disableCustomValue: PropTypes.bool,
  debouncedSearchNetworkGroups: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  groupsOptionType: PropTypes.string,
  labelKey: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  resetOptions: PropTypes.bool,
  shouldSearchOnChange: PropTypes.bool,
  touch: PropTypes.func,
  valueKey: PropTypes.string,
};

SingleSelectWithCustomValue.defaultProps = {
  disableCustomValue: false,
  labelKey: 'label',
  onChange: _.noop,
  options: [],
  valueKey: 'value',
  groupsOptionType: '',
  resetOptions: true,
  shouldSearchOnChange: true,
  touch: _.noop,
};

export default SingleSelectWithCustomValue;
