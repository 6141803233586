import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Card = ({
  borderColor,
  children,
  className,
  'data-testid': dataTestId,
}) => (
  <div
    className={cx(
      'border border-solid rounded overflow-hidden',
      borderColor,
      className,
    )}
    data-testid={dataTestId}
  >
    {children}
  </div>
);

Card.propTypes = {
  borderColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
};

Card.defaultProps = {
  borderColor: 'border-dark-border-blue',
  className: '',
  'data-testid': undefined,
};

export default Card;
