// Library Imports
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';

// Relative and absolute imports
import ReferralProgramForm from 'src/components/Referrals/ReferralPrograms/ReferralProgramForm';
import otherProgramsExist from '../utils/otherProgramsExist';
import RejectModalForm from './RejectModalForm';
import RejectConfirmation from './RejectConfirmation';

export function RejectDisplay(props) {
  const {
    referralPrograms,
    userPrograms,
    referral,
    groupId,
    rejectClicked,
    forwardClicked,
  } = props;

  const confirmationClass = () => classNames({
    'referral-reject-display__confirmation': true,
    'fade-in': otherProgramsExist(referralPrograms, userPrograms) && !rejectClicked && !forwardClicked,
    hide: !otherProgramsExist(referralPrograms, userPrograms) || rejectClicked || forwardClicked,
  });

  const programFormClass = () => classNames({
    'referral-reject-display__program-form': true,
    'fade-in': forwardClicked,
    hide: !forwardClicked,
  });

  const rejectFormClass = () => classNames({
    'referral-reject-display__reject-modal-form': true,
    'fade-in': rejectClicked || !otherProgramsExist(referralPrograms, userPrograms),
    hide: (otherProgramsExist(referralPrograms, userPrograms) && !rejectClicked),
  });

  return (
    <div className="referral-reject-display">
      <div className={confirmationClass()}>
        <RejectConfirmation
          onRejectClicked={props.onRejectClicked}
          onForwardClicked={props.onForwardClicked}
          onCancelClick={props.closeDialog}
        />
      </div>

      <div className={programFormClass()}>
        <ReferralProgramForm
          referral={referral}
          groupId={groupId}
          closeModal={props.closeDialog}
        />
      </div>

      <div className={rejectFormClass()}>
        <RejectModalForm
          referral={referral}
          closeDialog={props.closeDialog}
        />
      </div>
    </div>
  );
}

RejectDisplay.propTypes = {
  referral: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  userPrograms: PropTypes.array,
  referralPrograms: PropTypes.array,
  closeDialog: PropTypes.func,
  onRejectClicked: PropTypes.func,
  onForwardClicked: PropTypes.func,
  rejectClicked: PropTypes.bool.isRequired,
  forwardClicked: PropTypes.bool.isRequired,
};

RejectDisplay.defaultProps = {
  referralPrograms: [],
  userPrograms: [],
  closeDialog: _.noop,
  onRejectClicked: _.noop,
  onForwardClicked: _.noop,
};

function mapStateToProps(state, ownProps) {
  const referralId = ownProps.referral.id;
  const userGroup = _.find(state.user.groups, (g) => _.isEqual(g.group.id, ownProps.groupId)) ||
    {};

  return {
    referralPrograms: _.get(state, `referralPrograms[${referralId}]`, {})
      .programs,
    userPrograms: userGroup.programs,
  };
}

export default connect(mapStateToProps)(RejectDisplay);
