import { get, isEmpty } from 'lodash';

function checkCityStateFieldValues(values) {
  return (
    !isEmpty(get(values, 'address_type')) &&
      isEmpty(get(values, 'state')) &&
      isEmpty(get(values, 'city'))
  );
}

export default checkCityStateFieldValues;
