import _ from 'lodash';

const OMITTED_KEYS = [
  'created_at',
  'updated_at',
  '_meta',
];

const UNDEFINED_VALUES = [
  null,
  undefined,
  '',
  'prefer_not_to_disclose',
  'undisclosed',
];

function hasChangableValue(value) {
  return UNDEFINED_VALUES.includes(value);
}

function hasAcceptableValue(value) {
  return !UNDEFINED_VALUES.includes(value);
}

function createMergedMilitaryObject(contactMilitary, requestorMilitary) {
  const cMilitary = _.uuOmit(contactMilitary, OMITTED_KEYS);
  const rMilitary = _.uuOmit(requestorMilitary, OMITTED_KEYS);
  const mergedMilitary = {};

  if (_.isNil(requestorMilitary) || _.isEqual(cMilitary, rMilitary)) {
    return null;
  }

  if (_.isEmpty(cMilitary)) {
    _(rMilitary).keys().each((key) => {
      if (hasAcceptableValue(rMilitary[key])) {
        mergedMilitary[key] = rMilitary[key];
      }
    });
  } else {
    _(cMilitary).keys().each((key) => {
      if (hasChangableValue(cMilitary[key]) && hasAcceptableValue(rMilitary[key])) {
        mergedMilitary[key] = rMilitary[key];
      }
    });
  }

  return mergedMilitary;
}

export default createMergedMilitaryObject;
