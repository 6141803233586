import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { phones as phonesUtils } from '@unite-us/client-utils';
import CommunicationAdditionalInfo from 'common/display/Profile/CommunicationAdditionalInfo';
import _ from 'lodash';

class PhoneNumberDisplay extends Component {
  formattedPhoneLink(phoneNumber) {
    const {
      hidePrimaryDisplay,
      primaryDisplayInline,
      shortDisplay,
      typeDisplayInline,
      noLinkStyles,
      detailedExtension,
    } = this.props;

    if (_.isEmpty(phoneNumber)) {
      return null;
    }

    let phoneNumberLength = _.get(phoneNumber, 'phone_number.length', 0);
    if (phoneNumber.extension) {
      phoneNumberLength += _.get(phoneNumber, 'extension.length', 0);
    }
    const phoneNumberWithNoAreaCode = phoneNumberLength === 7;

    const typeDisplay = phoneNumber.phone_type;
    const additionalInfo = (
      <CommunicationAdditionalInfo
        communicationTypes={phoneNumber.acceptable_communication_types}
        isPrimary={!hidePrimaryDisplay && phoneNumber.is_primary}
      />
    );
    const headerDisplay = <span className="subhead"> {typeDisplay} {additionalInfo}</span>;
    const fullPhoneNumber = phoneNumber.extension ?
      _.get(phoneNumber, 'phone_number') + _.get(phoneNumber, 'extension') :
      _.get(phoneNumber, 'phone_number');
    const formattedPhoneNumber = phonesUtils.formatPhoneNumber(fullPhoneNumber, detailedExtension);
    const doNotDisplayLink = this.props.noLink || phoneNumberWithNoAreaCode;

    if (doNotDisplayLink) {
      return (
        <div className="phone-number-display" style={noLinkStyles}>
          <span
            {...(detailedExtension && { 'data-test-element': 'client_phone_number' })}
          >
            {formattedPhoneNumber}
          </span>
          {
            typeDisplay && typeDisplayInline && headerDisplay
          }
          {
            primaryDisplayInline && !typeDisplayInline && headerDisplay
          }
        </div>
      );
    }

    return (
      <div className="phone-number-display">
        {!shortDisplay && <div>{headerDisplay}</div>}
        <p>
          {
            <a
              href={phonesUtils.getTelLink(phoneNumber, detailedExtension)}
              {...(detailedExtension && { 'data-test-element': 'client_phone_number' })}
            >
              {formattedPhoneNumber}
            </a>
          }
        </p>
      </div>
    );
  }

  render() {
    const { item: phoneNumber } = this.props;

    if (_.isEmpty(phoneNumber)) {
      return null;
    }
    return this.formattedPhoneLink(phoneNumber);
  }
}

PhoneNumberDisplay.propTypes = {
  item: PropTypes.object.isRequired,
  shortDisplay: PropTypes.bool,
  hidePrimaryDisplay: PropTypes.bool,
  noLink: PropTypes.bool,
  noLinkStyles: PropTypes.object,
  primaryDisplayInline: PropTypes.bool,
  typeDisplayInline: PropTypes.bool,
  detailedExtension: PropTypes.bool,
};

PhoneNumberDisplay.defaultProps = {
  shortDisplay: false,
  hidePrimaryDisplay: false,
  noLink: false,
  primaryDisplayInline: false,
  typeDisplayInline: false,
  noLinkStyles: {
    marginTop: '3px',
  },
  detailedExtension: false,
};

export default PhoneNumberDisplay;
