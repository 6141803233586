import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isEmpty, values } from 'lodash';
import { SelectField } from '@unite-us/ui';
import { fetchNetworkFeeSchedules } from 'src/actions/FeeSchedules';
import { getFilterOptions } from 'src/components/Browse/utils/filters';

export const FeeScheduleFilter = (props) => {
  const {
    contact,
    employee,
    feeScheduleOptions,
    filters: { feeScheduleIds },
    networkId,
    onFiltersChange,
  } = props;

  const employeeId = employee.id;
  const contactId = contact.id;
  useEffect(() => {
    props.fetchNetworkFeeSchedules({ networkId, employeeId, contactId });
  }, [networkId, employeeId, contactId]);

  const applyFilters = useCallback((value) => {
    const filterValue = value === '' ? undefined : [value];
    onFiltersChange(filterValue, 'feeScheduleIds');
  }, [onFiltersChange]);

  return !isEmpty(feeScheduleOptions) && (
    <div className="fee-schedule-section">
      <SelectField
        field={{
          onChange: applyFilters,
          value: feeScheduleIds && feeScheduleIds[0],
        }}
        id="fee-schedule-filter"
        label="Contracted Program"
        options={feeScheduleOptions}
        shouldSort={false}
      />
    </div>
  );
};

FeeScheduleFilter.propTypes = {
  contact: PropTypes.object,
  employee: PropTypes.object.isRequired,
  feeScheduleOptions: PropTypes.array.isRequired,
  fetchNetworkFeeSchedules: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    feeScheduleIds: PropTypes.array,
  }).isRequired,
  networkId: PropTypes.string.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
};

FeeScheduleFilter.defaultProps = {
  contact: {},
};

const mapStateToProps = (state, ownProps) => {
  const feeSchedules = values(state.feeSchedules.data).map(({ name, id }) => ({
    display_name: name,
    value: id,
  }));
  const feeScheduleOptions = getFilterOptions(feeSchedules, get(ownProps, 'filters.feeScheduleIds', []));
  const feeScheduleStatus = get(state, 'feeSchedules.status');
  return {
    feeScheduleOptions,
    feeScheduleStatus,
  };
};

export default connect(mapStateToProps, { fetchNetworkFeeSchedules })(FeeScheduleFilter);
