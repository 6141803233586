import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchGroup } from 'actions/Group';
import { fetchNetworkReferralOutcomes } from 'actions/Outcome/ServiceType/Network';
import {
  getProviderReferralScopes,
} from 'src/components/Groups/utils/referralScope';
import _ from 'lodash';
import { getReferredToNetwork } from 'src/components/Referrals/utils';
import ReferralActions from './ReferralActions';

function showStatus(status) {
  switch (status) {
    case 'pending':
      return 'NO ACTION TAKEN';
    case 'in_review':
      return 'IN REVIEW';
    case 'accepted':
      return 'ACCEPTED';
    case 'declined':
      return 'REJECTED';
    case 'recalled':
      return 'RECALLED';
    case 'draft':
      return 'DRAFT';
    default:
      return 'PENDING';
  }
}

export class Options extends Component {
  constructor(props) {
    super(props);

    this.state = {
      referralScopes: [],
    };
  }

  componentDidMount() {
    const {
      currentNetworkId, groupId, referral, serviceTypeId,
    } = this.props;
    const networkId = getReferredToNetwork(referral).id || currentNetworkId;
    this.getProviderReferralScopes(groupId);

    this.props.fetchNetworkReferralOutcomes(networkId, serviceTypeId, referral);
    if (_.isEmpty(this.state.referralScopes)) {
      this.props.fetchGroup(groupId);
    }
  }

  getProviderReferralScopes(providerId) {
    const promise = this.props.getProviderReferralScopes(providerId);
    Promise.resolve(promise).then((response) => (
      this.setState({ referralScopes: response })));
  }

  render() {
    const {
      disabled,
      groupId,
      isCoordinationGroup,
      isSendable,
      networkGroups,
      outcomes,
      outcomesIsFetching,
      pathName,
      referral,
    } = this.props;

    if (_.isEmpty(referral) || _.isEmpty(this.state.referralScopes)) {
      return null;
    }

    return (
      <ReferralActions
        disabled={disabled}
        falsifyActionBool={this.props.falsifyActionBool}
        groupId={groupId}
        isCC={isCoordinationGroup}
        isSendable={isSendable}
        networkGroups={networkGroups}
        outcomes={outcomes}
        outcomesIsFetching={outcomesIsFetching}
        pathName={pathName}
        referral={referral}
        status={showStatus(_.get(referral, 'status'))}
      />
    );
  }
}

Options.propTypes = {
  currentNetworkId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  falsifyActionBool: PropTypes.func.isRequired,
  fetchGroup: PropTypes.func.isRequired,
  fetchNetworkReferralOutcomes: PropTypes.func.isRequired,
  getProviderReferralScopes: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isCoordinationGroup: PropTypes.bool.isRequired,
  isSendable: PropTypes.bool.isRequired,
  networkGroups: PropTypes.array.isRequired,
  outcomes: PropTypes.array.isRequired,
  outcomesIsFetching: PropTypes.bool,
  pathName: PropTypes.string,
  referral: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
  serviceTypeId: PropTypes.string.isRequired,
};

Options.defaultProps = {
  disabled: false,
  outcomesIsFetching: false,
};

function mapStateToProps(state, ownProps) {
  const { referral } = ownProps;

  const groupId = _.get(state, 'session.groupId');
  const isCoordinationGroup = _.get(state, 'session.isCoordinationGroup', false);
  const networkGroups = _.get(state, 'networkGroups.data');
  const serviceTypeId = _.get(referral, 'service_type.id');
  const outcomesIsFetching = _.get(state, 'outcomes.isFetchingNetworkOutcomes', false);
  const referralNetworkOutcomes = _.get(state, 'outcomes.networkOutcomes', []);
  const referralServiceTypeOutcomes = _.find(referralNetworkOutcomes, {
    service_type_id: serviceTypeId,
  });

  return {
    currentNetworkId: _.get(state, 'session.networkId'),
    networkGroups,
    isCoordinationGroup,
    groupId,
    outcomes: _.get(referralServiceTypeOutcomes, 'outcomes', []),
    outcomesIsFetching,
    serviceTypeId,
  };
}

export default connect(mapStateToProps, {
  fetchGroup,
  fetchNetworkReferralOutcomes,
  getProviderReferralScopes,
})(Options);
