import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RenameDocumentDialog from './RenameDocumentDialog';

class RenameDocument extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      renameDialogOpen: false,
    };

    this.openRenameDialog = this.openRenameDialog.bind(this);
  }

  openRenameDialog() {
    this.setState({ renameDialogOpen: true }, () => {
      this.renameDocumentDialog.form.getWrappedInstance().dialog.openDialog()
        .then(() => this.setState({ renameDialogOpen: false }));
    });
  }

  render() {
    const { canRename, doc, id } = this.props;
    const { renameDialogOpen } = this.state;

    return (
      <div className="rename-document">
        {
          canRename && (
            <a
              id={`${id}-rename-link`}
              className="icon-delete"
              onClick={this.openRenameDialog}
            >
              Rename
            </a>
          )
        }
        <RenameDocumentDialog
          doc={doc}
          ref={(el) => { this.renameDocumentDialog = el; }}
          onRenameDocument={this.props.onRenameDocument}
          dialogOpen={renameDialogOpen}
        />
      </div>
    );
  }
}

RenameDocument.propTypes = {
  canRename: PropTypes.bool,
  doc: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onRenameDocument: PropTypes.func.isRequired,
};

RenameDocument.defaultProps = {
  canRename: false,
};

export default RenameDocument;
