import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { INSURANCE_STATUSES } from 'src/components/Insurance/constants';
import { capitalize } from 'lodash';
import { Icon } from '@unite-us/ui';
import ToolTip from 'common/ToolTip';

const badgeProps = {
  [INSURANCE_STATUSES.enrolled]: {
    label: 'Enrolled',
    icon: 'IconCheckCircle',
    iconColor: 'green',
  },
  [INSURANCE_STATUSES.not_enrolled]: {
    label: 'Not enrolled',
    messageRespectingExpiration: `The social care coverage for the member
      receiving services for this case has ended. Your organization cannot
      submit contracted service notes or invoices for services provided after
      the social care coverage end date.`,
    messageIgnoringExpiration: `The social care coverage for the member
      receiving services for this case has ended. Excluding select scenarios,
      your organization should not submit contracted service notes or invoices
      for services provided after the social care coverage end date.`,
    icon: 'V2Warning',
    iconColor: 'red',
  },
  [INSURANCE_STATUSES.pending_disenrollment]: {
    label: 'Pending disenrollment',
    messageRespectingExpiration: `A request has been sent to disenroll the
      client from this social care coverage plan. If the disenrollment request
      for this client is approved, your organization will not be able to submit
      contracted service notes or invoices for services provided after the
      social care coverage end date.`,
    messageIgnoringExpiration: `A request has been sent to disenroll the
      client from this social care coverage plan. If the disenrollment request
      for this client is approved, your organization should not submit
      contracted service notes or invoices for services provided after the
      social care coverage end date, excluding select scenarios.`,
    icon: 'IconInfoCircle',
    iconColor: 'action-blue-color',
  },
  [INSURANCE_STATUSES.rejected]: {
    label: 'Rejected',
  },
  [INSURANCE_STATUSES.pending_enrollment]: {
    label: 'Pending enrollment',
  },
};

const validStatuses = Object.keys(badgeProps);

const InsuranceStatusIndicator = ({
  ignoreExpiration,
  showInsuranceServiceDateWarning,
  showTooltip,
  status,
}) => {
  if (!validStatuses.includes(status)) return 'Not Available';
  const {
    label,
    icon,
    iconColor,
    messageRespectingExpiration,
    messageIgnoringExpiration,
  } = badgeProps[status];
  const message = ignoreExpiration ? messageIgnoringExpiration : messageRespectingExpiration;

  const Indicator = () => (
    <>
      <div className="flex align-middle">
        {icon && (
          <Icon
            color={iconColor}
            icon={icon}
          />
        )}
        <span className={cx(icon && 'ml-1')}>{capitalize(label)}</span>
      </div>
      {showInsuranceServiceDateWarning && (
        <p className="mt-1">
          The client&apos;s social care coverage ended prior to or during the selected service delivery date(s).
          Please verify the service date(s) before submitting.
        </p>
      )}
    </>
  );

  return (
    icon && message && showTooltip ? (
      <ToolTip
        tooltipElementAs="span"
        enableHover
        buttonComponentContent={Indicator}
        buttonClassName="mr-0.5 focus:outline-none"
        panelTextContent={message}
        panelClassName="bg-white border border-gray-400 border-solid rounded
  shadow-md text-dark-grey leading-4
    w-80 px-5 py-3"
        placement="bottom-start"
      />
    ) : (
      <Indicator />
    )
  );
};

InsuranceStatusIndicator.propTypes = {
  ignoreExpiration: PropTypes.bool.isRequired,
  showInsuranceServiceDateWarning: PropTypes.bool,
  showTooltip: PropTypes.bool.isRequired,
  status: PropTypes.oneOf(validStatuses).isRequired,
};

InsuranceStatusIndicator.defaultProps = {
  showInsuranceServiceDateWarning: false,
};

export default InsuranceStatusIndicator;
