import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isFunction } from 'lodash';
import SearchForm from './SearchForm';
import {
  decodeContactData,
  goToConfirmStep,
  goToAddInformationStep,
} from '../utils';
import {
  COPY_CREATE_CLIENT,
  COPY_CREATE_CLIENT_SEARCH_SUB_HEADER,
} from '../constants';

export default class ContactSearch extends Component {
  constructor(props) {
    super(props);
    this.onSearchComplete = this.onSearchComplete.bind(this);
    this.state = {
      contact: decodeContactData(props.location.query.contact),
    };
  }

  onSearchComplete(contacts, formData) {
    const {
      context,
      activeStep,
    } = this.props;

    if (isFunction(activeStep.onSearchComplete)) {
      return activeStep.onSearchComplete({
        contacts,
        formData,
      });
    }

    if (!isEmpty(contacts)) {
      return goToConfirmStep({ context, contactData: { ...this.state.contact, ...formData } });
    }
    const addInfoOptions = { context, contactData: { ...this.state.contact, ...formData } };
    return goToAddInformationStep(addInfoOptions);
  }

  render() {
    const { contact: { first_name, last_name, date_of_birth } = {} } = this.state;

    return (
      <div className="contact-search">
        <SearchForm
          header={COPY_CREATE_CLIENT}
          subHeader={COPY_CREATE_CLIENT_SEARCH_SUB_HEADER}
          onComplete={this.onSearchComplete}
          initialValues={{
            first_name,
            last_name,
            date_of_birth,
          }}
        />
      </div>
    );
  }
}

ContactSearch.propTypes = {
  activeStep: PropTypes.object,
  context: PropTypes.string,
  location: PropTypes.shape({
    query: PropTypes.shape({
      contact: PropTypes.string,
    }),
  }),
};

ContactSearch.defaultProps = {
  activeStep: {},
  context: null,
  location: {
    query: {
      contact: '',
    },
  },
};
