import { isEmpty, isObject, uuCompactArrayOrObject } from 'lodash';

/* eslint-disable no-param-reassign */

export function delKeys(obj) {
  if (typeof obj === 'object') {
    obj = uuCompactArrayOrObject(obj);

    Object.keys(obj).forEach((k) => {
      obj[k] = delKeys(obj[k]);

      if (isObject(obj[k]) && isEmpty(obj[k])) {
        delete obj[k];
      }
    });
  }

  return obj;
}
