import React from 'react';
import PropTypes from 'prop-types';
import { SelectField, RadioField, Card } from '@unite-us/ui';
import { Field, useForm, useField } from 'react-final-form';
import statesEnums from 'src/reducers/constants/serviceAreasStates';
import DivReset from 'src/components/Organization/components/DivLineHeightReset';
import { SERVICE_AREA_TYPE_STATE } from 'components/Groups/constants';
import addLabelsToEnums from 'common/utils/addLabelsToEnums';
import './ServiceAreasForm.scss';

const stateOptions = addLabelsToEnums(statesEnums);

const ServiceAreasForm = ({ values, orgAdminDeactivatedPrograms }) => {
  const form = useForm();

  const serviceAreasNationalValue = () => form.getFieldState('service_areas_national')?.value;

  const serviceAreaStateValues = () => form.getFieldState('service_areas_states')?.value;

  const serviceAreasNationalField = useField('service_areas_national');

  const serviceAreasStatesField = useField('service_areas_states');

  const delivery_options = values?.delivery_options || {};
  const requireServiceArea =
    !delivery_options.in_office &&
    Object.keys(delivery_options).some((option) => option !== 'in_office' && delivery_options[option]) &&
    !(
      values?.service_areas_national?.selected ||
      values?.service_areas_states?.some((s) => s.selected) ||
      values?.locations?.some((s) => s.selected)
    );
  const serviceAreaTitleColor = requireServiceArea ? 'text-red' : 'title';

  const onChangeServiceAreaNational = (v) => {
    if (v.target.value === 'true') {
      serviceAreasStatesField.input.onChange(
        serviceAreaStateValues().map((sASV) => ({
          ...sASV,
          selected: false,
          submitChange: sASV.selected ?
            !sASV.submitChange :
            sASV.submitChange,
        })),
      );
    }

    serviceAreasNationalField.input.onChange({
      ...serviceAreasNationalValue(),
      selected: v.target.value === 'true',
      submitChange: !serviceAreasNationalValue()?.submitChange,
    });
  };

  const onChangeServiceAreaStates = (v, params) => {
    const currentStates = serviceAreaStateValues().reduce(
      (prev, sASV) => {
        const newPrev = { ...prev };
        newPrev[sASV.state_abbreviation] = sASV;
        return newPrev;
      },
      {},
    );

    const addValues = v.map((abbr) => {
      if (currentStates[abbr]) {
        return {
          ...currentStates[abbr],
          selected: true,
          submitChange:
            currentStates[abbr].selected !== true ?
              !currentStates[abbr].submitChange :
              currentStates[abbr].submitChange,
        };
      }

      return {
        state_abbreviation: abbr,
        service_area_type: SERVICE_AREA_TYPE_STATE,
        selected: true,
        submitChange: true,
      };
    });

    Object.keys(currentStates)
      .filter((abbr) => !v.includes(abbr))
      .forEach((abbr) => {
        addValues.push({
          ...currentStates[abbr],
          selected: false,
          submitChange:
            currentStates[abbr].selected !== false ?
              !currentStates[abbr].submitChange :
              currentStates[abbr].submitChange,
        });
      });

    params.input.onChange(addValues);
  };

  return (
    <Card className="mt-6 rounded border-dark-border-blue">
      <DivReset>
        <div className="p-4 border-b border-solid border-gray-400">
          <h2 className="pb-2">Service Areas</h2>
          <span className="text-dark-grey">
            {orgAdminDeactivatedPrograms ? 'Service areas are geographic areas where this program provides services.' :
              'Service Areas are the geographic areas where this program provides services.'}
          </span>
        </div>
      </DivReset>
      <DivReset className="px-8 py-10 body">
        <h4 className={`mt-2 ${serviceAreaTitleColor}`}>SELECT SERVICE AREAS</h4>
        <p className="mt-2">
          {orgAdminDeactivatedPrograms ? 'If your program serves clients across the entire country or state, like a ' +
            'call center or helpline, select the areas served by this program. This selection ensures that the ' +
            'program appears when users search for services in that area.' :
            'Please select the areas served by this program. This can include ' +
            'specific states or the whole country. ' +
            'Selecting a service area ensures that the program shows up when users ' +
            'search for services in that area.'}
        </p>
        {
          requireServiceArea &&
          <p className="text-red mt-2">Please select at least one service area or location for this program</p>
        }
        <h4 className="mt-4 title">NATIONAL</h4>
        <p className="mt-2">
          {orgAdminDeactivatedPrograms ? 'Select Yes if this program provides services to the entire country.' :
            "Select 'Yes' if this program provides services to the entire country."}
        </p>
        <Field
          name="service_areas_national"
          data-test-element="service_areas_national"
          format={(v) => v?.selected}
        >
          {(params) => (
            <RadioField
              id="service_areas_national"
              name="service_areas_national"
              className="national-selector"
              options={[
                {
                  label: 'Yes',
                  value: true,
                  submitChange: false,
                },
                {
                  label: 'No',
                  value: false,
                  submitChange: false,
                },
              ]}
              {...{
                ...params,
                input: {
                  ...params.input,
                  onChange: (v) => (onChangeServiceAreaNational(v)),
                },
              }}
            />
          )}
        </Field>
        <h4 className="title">STATES</h4>
        <p className="mt-2">
          {orgAdminDeactivatedPrograms ? 'Select states where this program provides services to the entire state.' :
            'Select states where this program provides services to the entire ' +
            'state, including all counties and cities.'}
        </p>
        <Field
          name="service_areas_states"
          className="states-selector"
          data-test-element="service_areas_states"
          format={(v) => {
            if (v && v.length) {
              return v
                .filter((sa) => sa.selected)
                .map((sa) => sa.state_abbreviation);
            }
            return v;
          }}
        >
          {(params) => (
            <SelectField
              multiple
              placeholder="Select..."
              id="service_areas_states"
              options={stateOptions}
              disabled={serviceAreasNationalValue()?.selected}
              {...{
                ...params,
                input: {
                  ...params.input,
                  onChange: (v) => onChangeServiceAreaStates(v, params),
                },
              }}
            />
          )}
        </Field>
      </DivReset>
    </Card>
  );
};

ServiceAreasForm.propTypes = {
  values: PropTypes.shape({
    delivery_options: PropTypes.array.isRequired,
    locations: PropTypes.array,
    service_areas_national: PropTypes.array,
    service_areas_states: PropTypes.array,
  }),
  orgAdminDeactivatedPrograms: PropTypes.bool,
};

ServiceAreasForm.defaultProps = {
  values: {
    delivery_options: [],
  },
  orgAdminDeactivatedPrograms: false,
};

export default ServiceAreasForm;
