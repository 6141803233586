import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '@unite-us/ui';

const FormInteractionsButtons = ({
  primaryLabel,
  cancelActionHandler,
  conditionToDisableButton,
  conditionToDisableSecondaryButton,
  interaction,
  secondaryLabel,
  secondaryActionHandler,
  submitting,
  primaryActionHandler,
}) => (
  secondaryLabel ? (
    <div className="flex justify-between">
      <Button
        id={`${interaction}-cancel-btn`}
        className="cancel-button mr-5 p-0 border-0 text-action-blue whitespace-no-wrap"
        onClick={cancelActionHandler}
        disabled={submitting}
        label="Cancel"
      />
      <div className="flex flex-nowrap space-x-4">
        <Button
          className="whitespace-no-wrap"
          disabled={conditionToDisableSecondaryButton || submitting}
          id={`${interaction}-post-note-btn-secondary`}
          onClick={secondaryActionHandler}
          label={secondaryLabel}
          data-testid="add-note-btn-secondary"
        />
        <Button
          className="whitespace-no-wrap"
          disabled={conditionToDisableButton || submitting}
          id={`${interaction}-post-note-btn`}
          onClick={primaryActionHandler}
          label={primaryLabel}
          primary
          data-testid="add-note-btn"
        />
      </div>
    </div>
  ) : (
    <div className="flex justify-between">
      <Button
        id={`${interaction}-cancel-btn`}
        onClick={cancelActionHandler}
        disabled={submitting}
        label="Cancel"
        secondary
      />
      <Button
        className="whitespace-no-wrap"
        disabled={conditionToDisableButton || submitting}
        id={`${interaction}-post-note-btn`}
        onClick={primaryActionHandler}
        label={primaryLabel}
        primary
        data-testid="add-note-btn"
      />
    </div>
  )
);

FormInteractionsButtons.propTypes = {
  primaryLabel: PropTypes.string,
  cancelActionHandler: PropTypes.func.isRequired,
  interaction: PropTypes.node.isRequired,
  secondaryLabel: PropTypes.string,
  primaryActionHandler: PropTypes.func.isRequired,
  secondaryActionHandler: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  conditionToDisableButton: PropTypes.bool,
  conditionToDisableSecondaryButton: PropTypes.bool,
};

FormInteractionsButtons.defaultProps = {
  primaryLabel: 'Post Note',
  secondaryLabel: '',
  secondaryActionHandler: noop,
  conditionToDisableButton: false,
  conditionToDisableSecondaryButton: false,
};

export default FormInteractionsButtons;
