import React, { useContext } from 'react';
import PropTypes from 'prop-types';
// component to connect
import { AppScreenings } from '@unite-us/app-screenings';

// utils
import { isCaseManager } from 'src/components/User/utils';
import { CORE_API } from 'src/config/env/env.config';
import { TrackerContext } from '@unite-us/client-utils';
import Notifier from 'common/helpers/Notifier';
import { connect } from 'react-redux';
import { browserHistory } from 'common/utils/browserHistory';
import * as apiHooks from 'src/api/APIHooks';
import { useFindAllPlan } from 'src/components/Backoffice/api/hooks/v1/planHooks';
import fetchInsurances from 'src/api/core/Insurances/fetchInsurances';
import {
  MethodsOfContact,
  VoiceMailOk,
} from 'src/components/Facesheet/Profile/ContactRequirements/components';
import StatusSelect from 'src/components/StatusSelect';
import {
  generateClientData,
  getDobData,
  formatAddress,
  formatHouseholdCount,
  filterMilitaryInfo,
  formatDemographicInfo,
} from 'src/components/ClientSummary/utils';
import findPrimaryOrFirst from 'src/components/Contacts/utils/findPrimaryOrFirst';
import { dollarAmount } from 'common/display/Money/DollarAmount';
import {
  scrn7ScreeningsCompletion,
  crtb1121HideFieldInForm,
  uup459SupersetPhase2,
} from 'common/utils/FeatureFlags/flags';
import { BackButton } from 'common/buttons';

import { coreApi, getAuthToken } from 'src/api/config';

import { get } from 'lodash';
import { ASSESSMENTS } from 'common/utils/EventTracker/utils/eventConstants';
import { RISK_LEVELS } from 'src/components/Screenings/constants';
import showRiskDisplayOption from 'src/components/Screenings/Detail/utils/showRiskDisplayOption';
import callOrLog from 'src/common/utils/callOrLog';

const getBasename = ({ pathname, route, basename = '' }) => {
  const path = pathname.split(route).at(0);
  return `${basename}${path}${route}`;
};

const Screenings = ({
  location,
  currentEmployee,
  enums,
  providerId,
  groupId,
  isCaseManagerUser,
  crtb1121HideFieldInFormEnabled,
  scrn7ScreeningsCompletionEnabled,
}) => {
  const basename = getBasename({
    basename: location.basename,
    pathname: location.pathname,
    route: 'screenings/v2',
  });

  const trackEvent = useContext(TrackerContext);

  // TODO: This could be needed to get
  // state from AppScreenings. Note that
  // SupersetSearch, Referrals and ResourceList
  // all use useAppCreateReferralContext()
  // const { state: formSubmissionId } = useAppCreateReferralContext();

  return (
    <AppScreenings
      source="web_app"
      basename={basename}
      appSettings={{
        env: {
          getAuthToken,
          coreUrl: CORE_API,
          employeeId: currentEmployee?.id,
          providerId: currentEmployee?.provider.id,
        },
        flags: {
          crtb1121HideFieldInFormEnabled,
          scrn7ScreeningsCompletionEnabled,
          uup459SupersetPhase2,
        },
        basename,
      }}
      adapters={{
        coreApi,
      }}
      hooks={{ ...apiHooks, useFindAllPlan }}
      appState={{
        enums,
        groupId,
        isCaseManagerUser,
        providerId,
        currentEmployee,
      }}
      utils={{
        fetchInsurances,
        generateClientData,
        getDobData,
        formatAddress,
        formatHouseholdCount,
        filterMilitaryInfo,
        formatDemographicInfo,
        findPrimaryOrFirst,
        dollarAmount,
        showRiskDisplayOption,
        callOrLog,
      }}
      callbacks={{
        notify: {
          error: (message) => Notifier.dispatch('error', message),
          success: (message) => Notifier.dispatch('success', message),
          warn: (message) => Notifier.dispatch('warning', message),
        },
        trackEvent,
        onSubmitSubmission: (formSubmissionId) => {
          browserHistory.replace(`/screenings/all/${formSubmissionId}`);
        },
        goToFacesheet: (contactId) => {
          browserHistory.push(`/facesheet/${contactId}`);
          // we have to change this for the current url
        },
        goBack: () => {
          browserHistory.goBack();
        },
        replace: (path) => {
          browserHistory.replace(path);
        },
        pushInHistory: (url) => {
          browserHistory.push(url);
        },
      }}
      constants={{ RISK_LEVELS, ASSESSMENTS }}
      components={{
        MethodsOfContact,
        VoiceMailOk,
        StatusSelect,
        BackButton,
      }}
    />
  );
};

function mapStateToProps(state) {
  const enums = get(state, 'session.enums');
  const groupId = get(state, 'session.groupId');
  const isCaseManagerUser = isCaseManager(get(state, 'user'), groupId);
  const providerId = state.session.groupId;
  const currentEmployee = state.globalState.currentEmployee;
  const crtb1121HideFieldInFormEnabled = crtb1121HideFieldInForm(state);
  const scrn7ScreeningsCompletionEnabled = scrn7ScreeningsCompletion(state);

  return {
    isCaseManager: isCaseManagerUser,
    enums,
    groupId,
    providerId,
    currentEmployee,
    crtb1121HideFieldInFormEnabled,
    scrn7ScreeningsCompletionEnabled,
  };
}

Screenings.propTypes = {
  location: PropTypes.string.isRequired,
  currentEmployee: PropTypes.shape({
    id: PropTypes.string.isRequired,
    provider: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  providerId: PropTypes.string.isRequired,
  isCaseManagerUser: PropTypes.bool.isRequired,
  crtb1121HideFieldInFormEnabled: PropTypes.bool.isRequired,
  scrn7ScreeningsCompletionEnabled: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired,
  enums: PropTypes.object.isRequired,
};

Screenings.defaultProps = {};

export default connect(mapStateToProps)(Screenings);
