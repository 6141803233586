import {
  CASE_STATUS_OPEN,
  CASE_STATUS_CLOSED,
} from 'src/components/Cases/constants';
import { get, isEmpty, wget } from 'lodash';
import isCaseOpen from 'src/components/Cases/utils/isCaseOpen';

function getCaseStatus(theCase = {}) {
  if (isEmpty(theCase)) {
    return 'all';
  }

  return !isCaseOpen(theCase) ?
    CASE_STATUS_CLOSED :
    CASE_STATUS_OPEN;
}

function shouldGoToIndex(caseId, contactId) {
  return isEmpty(caseId) || isEmpty(contactId);
}

function caseRoute(theCase, groupId = '') {
  const contactId = wget(theCase, 'person.id');
  const pathname = wget(theCase, 'pathname', '');
  const caseId = get(theCase, 'id');
  const caseState = get(theCase, 'state');
  const caseStatus = getCaseStatus(theCase);
  if (!isEmpty(pathname)) {
    return `${pathname}/${caseId}/contact/${contactId}`;
  }

  if (caseState === 'draft') {
    return `/dashboard/referrals/sent/draft/${caseId}/contact/${contactId}`;
  }

  if (caseState === 'off_platform') {
    return `/dashboard/oon-cases/${caseStatus}/${caseId}/contact/${contactId}`;
  }

  if (isEmpty(pathname) && !isEmpty(groupId)) {
    const caseProvider = wget(theCase, 'provider.id');
    if (caseState === 'managed' && caseProvider === groupId) {
      return `/dashboard/cases/${caseStatus}/${caseId}/contact/${contactId}`;
    }
    if (caseState === 'managed' && caseProvider !== groupId) {
      return `/dashboard/external-cases/${caseStatus}/${caseId}/contact/${contactId}`;
    }
  }

  return shouldGoToIndex(caseId, contactId) ?
    `/dashboard/cases/${caseStatus}` :
    `/dashboard/cases/${caseStatus}/${caseId}/contact/${contactId}`;
}

export default caseRoute;
