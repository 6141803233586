import React from 'react';
import './loadingDots.scss';

export default function LoadingDots() {
  return (
    <div className="loadingDots">
      <div id="dot1" className="dot">.</div>
      <div id="dot2" className="dot">.</div>
      <div id="dot3" className="dot">.</div>
    </div>
  );
}
