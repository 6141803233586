import { every } from 'lodash';
import { isReferralsUserAndAbove } from 'src/components/User/utils';

export default function canRecall({ user, referral, groupId }) {
  const {
    state: referralState,
    case: {
      outcome: caseOutcome,
      state: caseState,
    },
  } = referral;

  if (caseState === 'draft') {
    return null;
  }

  const checks = [
    isReferralsUserAndAbove(user, groupId),
    ['sent', 'pending_authorization'].includes(referralState),
    referral.sending_provider.id === groupId,
    caseOutcome === null,
  ];

  return every(checks);
}
