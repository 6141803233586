import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'common/utils/browserHistory';
import ContactDetailReviewFromReferral from
  'common/display/ContactStepper/components/ContactDetailReviewFromReferral';
import ContactDetailReviewFromAssistanceRequest from
  'common/display/ContactStepper/components/ContactDetailReviewFromAssistanceRequest';
import { destroyForm } from 'actions/Form';
import { CREATE_REFERRAL_FORM } from 'src/components/Referrals/constants';
import _ from 'lodash';

export class ReviewContactDetails extends Component {
  constructor(props) {
    super(props);
    this.goToNextStep = this.goToNextStep.bind(this);
  }

  goToNextStep() {
    const { assistanceRequestId } = this.props;
    const prePath = assistanceRequestId ?
      `/assistance-requests/${assistanceRequestId}` :
      '';
    this.props.destroyForm(CREATE_REFERRAL_FORM);
    // @todo This is kinda hacky, but setting the timeout gives a buffer
    // so the form doesn't disappear and reappear when landing on the new page.
    // There may be a better way to do this.
    setTimeout(
      () => browserHistory.push(`${prePath}/referrals/new/add-service-types`),
      100,
    );
  }

  render() {
    const { assistanceRequestId } = this.props;
    const props = {
      header: 'Create Referral',
      mainHeader: 'Review Contact Information',
      subHeader: 'Please ensure that the contact information we have is accurate.',
      onComplete: this.goToNextStep,
    };

    return _.isEmpty(assistanceRequestId) ? (
      <ContactDetailReviewFromReferral {...props} />
    ) : (
      <ContactDetailReviewFromAssistanceRequest
        {...props}
        assistanceRequestId={assistanceRequestId}
      />
    );
  }
}

ReviewContactDetails.propTypes = {
  assistanceRequestId: PropTypes.string,
  destroyForm: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const assistanceRequestId = _.wget(ownProps, 'params.assistance_request_id');
  return {
    assistanceRequestId,
  };
}

export default connect(mapStateToProps, {
  destroyForm,
})(ReviewContactDetails);
