import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import { Icon } from '@unite-us/ui';

const Drawer = ({
  open, setOpen, children,
}) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="fixed inset-0 z-drawer" onClose={setOpen}>
      <div className="absolute inset-0">
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div
          className="absolute inset-y-0 mt-15 pl-10 right-0 max-w-full flex overflow-y-auto overflow-x-hidden"
        >
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="relative w-screen max-w-lg">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 left-0 lg:-ml-10 mt-2 flex">
                  <button
                    type="button"
                    className="h-8 w-8 mr-2 rounded-2xl bg-white hover:bg-light-fill-blue focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <Icon
                      className="h-3 w-3 stroke-current stroke-1 fill-current text-action-blue"
                      icon="IconCross"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="min-h-full bg-white">
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);

Drawer.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default Drawer;
