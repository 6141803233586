import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import Header from 'common/display/ContactStepper/components/Header';
import ContactDetails from 'src/components/Referrals/NewReferralStepper/components/ContactDetails';
import DetailUnorderedList from 'common/display/SingleItemDetails/DetailUnorderedList';
import ReferralServicesReview from 'src/components/Referrals/components/ReferralServices/ReferralServicesReview';
import { destroyAllDropzones } from 'common/form/FileUpload/actions/FileUpload';
import { enforcedConsentRoute } from 'common/utils/Navigation';
import { saveDraftReferral, submitReferral } from 'actions/Referral/Contact/Group';
import { createServiceCase, createServiceCaseFromAR } from 'actions/Case/Contact/Group';
import addNotification from 'common/utils/Notifications/actions/AddNotification';
import { patchAssessmentFromReferral } from 'actions/Assessment/Contact/Referral/Group';
import { fetchGroup } from 'actions/Group';
import { fetchGroupsPrograms } from 'actions/Program/Group';
import { newReferralFormFieldsAsServices } from 'src/components/Referrals/utils/form';
import { DOMAIN_CONSTANTS } from 'src/common/constants';
import { CREATE_REFERRAL_FORM } from 'src/components/Referrals/constants';
import { validateReduxForm } from 'common/form';
import { Button, Dialog } from '@unite-us/ui';
import _ from 'lodash';
import { OverlaySpinner } from 'common/spinners';
import callOrLog from 'src/common/utils/callOrLog';
import { REFERRAL } from 'common/utils/EventTracker/utils/eventConstants';
import { expandAllReferralReviewSections, hasCasesOONReferralUnlicensedOrgs, programBasedSearchSelector } from 'common/utils/FeatureFlags/flags';
import {
  getOONGroupNameFromField,
  validateOONGroupFields,
} from 'src/components/Referrals/ReferralFormFields/OONGroupsSelector/utils';
import {
  structureOONProviders,
} from 'src/components/Referrals/ReferralGroupsPrograms/utils';
import { getReferredOONProgram } from 'src/components/Groups/Programs/utils';
import { patchAssessment as patchCaseAssessment } from 'actions/Assessment/Case/Group';
import 'src/components/Referrals/stylesheets/referrals.scss';
import ConsentReminder from 'src/components/ConsentReminder';

export class NewReferralReviewStep extends Component {
  constructor(props) {
    super(props);

    this.onDeleteReferral = this.onDeleteReferral.bind(this);
    this.onEditReferral = this.onEditReferral.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onModalCancel = this.onModalCancel.bind(this);
    this.onModalConfirmation = this.onModalConfirmation.bind(this);
    this.onSaveDraft = this.onSaveDraft.bind(this);
    this.initiateEventTracking = this.initiateEventTracking.bind(this);
    this.patchReferralAssessments = this.patchReferralAssessments.bind(this);
    this.patchCaseAssessments = this.patchCaseAssessments.bind(this);
    this.responsesForReferral = this.responsesForReferral.bind(this);
    this.submitOONCase = this.submitOONCase.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { contact, currentGroup } = this.props;

    if (!contact || !currentGroup) {
      this.onEditReferral();
    }
  }

  componentDidMount() {
    this.props.fetchGroup(this.props.groupId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.isEmpty(nextProps.fields.services)) {
      browserHistory.push('/referrals/new/add-service-types');
    }
  }

  onDeleteReferral(evt, index) {
    evt.stopPropagation();
    this.deleteModal.openDialog().then((response) => {
      if (response === 'delete') {
        const { services } = this.props.fields;
        services.removeField(index);
      }
    });
  }

  onEditReferral() {
    const { requestId, locationSrc = '' } = this.props;
    const prefix = !_.isEmpty(requestId) ?
      `/assistance-requests/${requestId}` :
      '';

    // Pass the location src through to the created route
    browserHistory.push(`${prefix}/referrals/new/add-service-types?onEdit=true${locationSrc}`);
  }

  onModalCancel() {
    this.deleteModal.closeDialog();
  }

  onModalConfirmation() {
    this.deleteModal.closeDialog('delete');
  }

  onSaveDraft(values) {
    const {
      groupId,
      requestId,
      selectedContactId,
      screeningId,
      currentEmployee,
    } = this.props;

    const newdraftPromise = this.props.submitReferral(
      groupId,
      selectedContactId,
      values.services,
      {
        assistance_request_id: requestId, screening_id: screeningId, draft: true, requester_id: currentEmployee.id,
      },
    );
    return Promise.resolve(newdraftPromise).then((response) => {
      if (!_.isError(response)) {
        const referrals = _.map(response, (r) => _.get(r, 'data.data', []));

        this.submitAssessments(referrals, []);

        callOrLog(() => this.context.eventTracker(REFERRAL.saveDraft, {
          referral_ids: _.map(referrals, 'id'),
        }));

        browserHistory.push('/dashboard/referrals/sent/draft');
        this.props.resetForm();
        this.props.destroyForm(CREATE_REFERRAL_FORM);
        this.props.destroyAllDropzones();
      } else {
        this.props.addNotification({
          payload: {
            status: 'warning',
            statusText: 'There was a problem saving draft referral.',
          },
        });
      }
    });
  }

  onSubmit(values) {
    const {
      contact,
      groupId,
      requestId,
      selectedContactId,
      screeningId,
      currentEmployee,
    } = this.props;

    const referralValues = _.reject(values.services, 'isOONCase');
    const oonCaseValues = _.filter(values.services, 'isOONCase');

    const promises = [];

    if (!_.isEmpty(referralValues)) {
      const newReferralPromise = this.props.submitReferral(
        groupId,
        selectedContactId,
        referralValues,
        { assistance_request_id: requestId, screening_id: screeningId, requester_id: currentEmployee.id },
      );
      promises.push(newReferralPromise);
    }

    if (!_.isEmpty(oonCaseValues)) {
      _.map(oonCaseValues, (OONCase) => {
        promises.push(this.submitOONCase(OONCase));
      });
    }

    return Promise.all(promises).then((responses) => {
      const referralsResponse = !_.isEmpty(referralValues) ? responses[0] : {};
      if (_.isError(referralsResponse)) {
        return;
      }

      let casesResponses = {};
      if (!_.isEmpty(oonCaseValues)) {
        casesResponses = !_.isEmpty(referralValues) ? _.tail(responses) : responses;
      }

      const createdReferrals = _.map(referralsResponse, (r) => _.get(r, 'data.data', []));
      const createdCases = _.map(casesResponses, (response) => _.get(response, 'data.data', {}));

      this.submitAssessments(createdReferrals, createdCases);

      if (!_.isEmpty(createdReferrals)) {
        enforcedConsentRoute({
          allowSkipConsent: false,
          contact,
          itemType: DOMAIN_CONSTANTS.REFERRAL,
          to: '/dashboard/referrals/sent/all',
        });
        this.initiateEventTracking(createdReferrals);
        this.props.resetForm();
        this.props.destroyForm(CREATE_REFERRAL_FORM);
        this.props.destroyAllDropzones();
      } else {
        this.props.addNotification({
          payload: {
            status: 'success',
            statusText: 'Supporting Assessments Successfully Saved',
          },
        });
        this.props.destroyAllDropzones();
        browserHistory.push('/dashboard/oon-cases/open');
      }
    });
  }

  submitOONCase(values) {
    const {
      casesOONReferralUnlicensedOrgs,
      groupId,
      requestId,
      selectedContactId,
      screeningId,
    } = this.props;

    let selectedGroups = validateOONGroupFields(_.wget(values, 'oonCase.selected', []));
    const customGroups = _.wget(values, 'oonCase.custom', []);

    if (!_.isEmpty(customGroups)) {
      const formattedCustomGroups = _.map(customGroups, (custom) => ({
        group: {
          displayName: custom?.group,
          id: custom?.group,
          isCustom: true,
        },
      }));

      selectedGroups = selectedGroups.concat(formattedCustomGroups);
    }

    const { uploadedDocuments, attachableDocuments } = values;
    const filteredDocuments = _.filter(attachableDocuments, (doc) => doc.attached === true);
    const attachedDocuments = _.map(filteredDocuments, (ad) => ({
      title: _.wget(ad, 'document.title'),
      document_id: _.wget(ad, 'document.id'),
    }));
    const isOONCase = _.wget(values, 'isOONCase');

    return this.props.fetchGroupsPrograms(groupId, { includeDefault: true }).then((payload) => {
      const programs = _.wget(payload, 'data.data', []);
      const referredOONProgram = getReferredOONProgram(programs);
      let service_case = {
        description: values.notes || null,
        primary_worker_id: _.wget(values, 'oonCase.primary_worker.id') || null,
      };

      if (casesOONReferralUnlicensedOrgs) {
        service_case = {
          ...service_case,
          out_of_network_providers: structureOONProviders(selectedGroups),
        };
      }

      const params = {
        network_id: _.get(values, 'referred_by_network.id') || null,
        program: {
          entry_date: _.wget(values, 'oonCase.program_entry') || null,
          program_id: referredOONProgram.id || null, // @todo do we need this value?
          program_name: _.get(referredOONProgram, 'attributes.name', null),
          referred_to: selectedGroups.map(getOONGroupNameFromField).join(', '),
        },
        service_case,
        service_type_id: _.wget(values, 'service_type.id') || null,
      };

      return requestId ? this.props.createServiceCaseFromAR({
        assistanceRequestId: requestId,
        attachedDocuments,
        contactId: selectedContactId,
        dropzoneDocuments: uploadedDocuments,
        groupId,
        isOONCase,
        params,
      }) : this.props.createServiceCase({
        screening_id: screeningId,
        assistance_request_id: requestId,
        attachedDocuments,
        contactId: selectedContactId,
        dropzoneDocuments: uploadedDocuments,
        groupId,
        isOONCase,
        params,
      });
    });
  }

  patchReferralAssessments(referral, responses) {
    const { groupId, currentEmployee } = this.props;

    return Promise.all(_.map(responses, (assessment) => {
      this.props.patchAssessmentFromReferral({
        groupId,
        caseId: referral.case.id,
        id: assessment.id,
        values: assessment.data,
        submitter: currentEmployee,
      });
    }));
  }

  patchCaseAssessments(caseId, assessmentData) {
    const {
      groupId,
      currentEmployee,
    } = this.props;

    return Promise.all(_.map(assessmentData, (assessment) => (
      this.props.patchCaseAssessment({
        groupId,
        caseId,
        id: assessment.id,
        values: assessment.data,
        submitter: currentEmployee,
      })
    )));
  }

  submitAssessments(referrals, cases) {
    const referralsToSubmit = [];
    referrals.forEach((referral) => {
      const referralsToSubmitCaseIds = referralsToSubmit.map((r) => _.get(r, 'case.id'));
      if (!referralsToSubmitCaseIds.includes(_.get(referral, 'case.id'))) {
        referralsToSubmit.push(referral);
      }
    });

    const referralAssessments = referralsToSubmit.map((referral) => (
      this.patchReferralAssessments(referral, this.responsesForReferral(referral))
    ));
    const casesAssessments = cases.map((oonCase) => (
      this.patchCaseAssessments(oonCase.id, this.responsesForCase(oonCase))
    ));

    const promises = _.compact([
      ...referralAssessments,
      ...casesAssessments,
    ]);
    return Promise.all(promises).catch(() => (
      this.props.addNotification({
        payload: {
          status: 'warning',
          statusText: 'There was a problem saving the assessment with your referral.',
        },
      })
    ));
  }

  // only assessments that include the referral service type
  // are submitted with the referral context
  responsesForReferral(referral) {
    const { assessmentForms, assessmentResponses } = this.props;

    const formIdsForReferral = _.reduce(assessmentForms, (acc, assessmentForm) => {
      const serviceTypeId = _.get(referral, 'case.service_type.id', '');
      const formServiceTypeIds = [...assessmentForm.service_type_ids];

      if (_.includes(formServiceTypeIds, serviceTypeId)) {
        acc.push(assessmentForm.id);
      }

      return acc;
    }, []);

    return _.filter(assessmentResponses, (response) => (
      _.includes(formIdsForReferral, response.id)
    ));
  }

  // only assessments that include the referral service type
  // are submitted with the referral context
  responsesForCase(oonCase) {
    const { assessmentForms, assessmentResponses } = this.props;

    const formIdsForCase = _.reduce(assessmentForms, (acc, assessmentForm) => {
      const serviceTypeId = _.get(oonCase, 'service_type.id', '');
      const formServiceTypeIds = [...assessmentForm.service_type_ids];

      if (_.includes(formServiceTypeIds, serviceTypeId)) {
        acc.push(assessmentForm.id);
      }

      return acc;
    }, []);

    return _.filter(assessmentResponses, (response) => (
      _.includes(formIdsForCase, response.id)
    ));
  }

  initiateEventTracking(referrals) {
    const { requestId } = this.props;
    const referralIds = _.map(referrals, (referral) => referral.id);

    if (requestId) {
      return callOrLog(() => this.context.eventTracker(REFERRAL.createFromAr, {
        referral_ids: referralIds,
        assistance_request_id: requestId,
      }));
    }

    return callOrLog(() => this.context.eventTracker(REFERRAL.create, {
      referral_ids: referralIds,
    }));
  }

  render() {
    const {
      contact,
      expandAllReferralSections,
      fields,
      globalServiceTypes,
      handleSubmit,
      isReferralSaving,
      networks,
      referralScopes,
      returnedAssessment,
      submitting,
      isProgramBasedSearch,
    } = this.props;

    const hasOONCases = fields.services
      .some((s) => s.isOONCase.checked);

    const toolTip = hasOONCases && {
      'data-tooltip-left': true,
      'data-tooltip-large': 'Out of Network Cases cannot be saved as drafts',
    };

    return (
      <div className="review-referral-view">
        <OverlaySpinner show={submitting || isReferralSaving} text="Saving..." />

        <form
          className="referral-review"
          onSubmit={handleSubmit(this.onSubmit)}
        >
          <Header
            header="Create Referral"
            subHeader="You’re almost there! Please review your referrals for accuracy and submit them below."
          />

          <ContactDetails contact={contact} />

          {
            !_.isEmpty(returnedAssessment) && (
              <div className="review-referral-view__assessments">
                <DetailUnorderedList
                  list={returnedAssessment}
                  title="Assessments"
                />
              </div>
            )
          }

          <ReferralServicesReview
            expandAllReferralSections={expandAllReferralSections}
            fields={fields}
            globalServiceTypes={globalServiceTypes}
            networks={networks}
            onEdit={this.onEditReferral}
            onDelete={this.onDeleteReferral}
            referralScopes={referralScopes}
            isProgramBasedSearch={isProgramBasedSearch}
          />

          <ConsentReminder />

          <footer className="referral-footer row">
            <div className="col-md-12">
              <div style={{ float: 'right' }}>
                <span {...toolTip}>
                  <Button
                    id="save-draft-btn"
                    disabled={hasOONCases || submitting}
                    onClick={handleSubmit(this.onSaveDraft)}
                    label="Save Draft"
                    secondary
                  />
                </span>

                <Button
                  id="submit-referral-btn"
                  className="submit-referral-btn ml-one px-12"
                  onClick={handleSubmit(this.onSubmit)}
                  label="Confirm and Submit"
                  disabled={submitting}
                  primary
                />
              </div>
            </div>
          </footer>
        </form>

        <Dialog
          id="delete-referral-modal"
          ref={(el) => { this.deleteModal = el; }}
          title="Delete"
          size="mini"
          actions={(
            <div>
              <Button
                id="cancel-delete-referral-btn"
                label="Cancel"
                onClick={this.onModalCancel}
                primary
              />
              <Button
                id="delete-referral-btn"
                onClick={this.onModalConfirmation}
                label="Delete"
                primary
                style={{ marginLeft: '10px' }}
              />
            </div>
          )}
        >
          <p>Are you sure you want to delete this referral?</p>
        </Dialog>
      </div>
    );
  }
}

NewReferralReviewStep.propTypes = {
  addNotification: PropTypes.func.isRequired,
  assessmentForms: PropTypes.array.isRequired,
  casesOONReferralUnlicensedOrgs: PropTypes.bool.isRequired,
  contact: PropTypes.object,
  createServiceCase: PropTypes.func.isRequired,
  createServiceCaseFromAR: PropTypes.func.isRequired,
  currentGroup: PropTypes.object.isRequired,
  currentEmployee: PropTypes.object.isRequired,
  destroyForm: PropTypes.func.isRequired,
  destroyAllDropzones: PropTypes.func.isRequired,
  expandAllReferralSections: PropTypes.bool.isRequired,
  fetchGroup: PropTypes.func.isRequired,
  fetchGroupsPrograms: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  globalServiceTypes: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  isReferralSaving: PropTypes.bool.isRequired,
  isProgramBasedSearch: PropTypes.bool.isRequired,
  networks: PropTypes.array.isRequired,
  referralScopes: PropTypes.array.isRequired,
  requestId: PropTypes.string.isRequired,
  resetForm: PropTypes.func.isRequired,
  returnedAssessment: PropTypes.array.isRequired,
  assessmentResponses: PropTypes.array.isRequired,
  patchAssessmentFromReferral: PropTypes.func.isRequired,
  patchCaseAssessment: PropTypes.func.isRequired,
  saveDraftReferral: PropTypes.func.isRequired,
  selectedContactId: PropTypes.string.isRequired,
  submitReferral: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  locationSrc: PropTypes.string,
  screeningId: PropTypes.string,
};

NewReferralReviewStep.defaultProps = {
  isReferralSaving: false,
  locationSrc: undefined,
  screeningId: undefined,
};

NewReferralReviewStep.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const {
    contacts: { contacts = [] },
    searchedContacts,
    session: { groupId },
    user: { groups = [], networks = [] },
    groupForms,
  } = state;

  // We need to check the location query for a src param and pass it along
  // this is used to determine the stepper config (nehii users for now)
  const srcQueryString = _.get(ownProps, 'location.query.src');
  const locationSrc = srcQueryString ? `&src=${srcQueryString}` : undefined;

  const selectedContactId = _.get(state, 'selectedContact', '');
  const allContacts = [...contacts, ...searchedContacts];
  const contact = _.find(allContacts, { id: selectedContactId });

  const requestId = _.get(ownProps, 'params.assistance_request_id', '');
  const globalServiceTypes = _.get(state, 'session.globals.service_types', []);
  const currentGroup = groups.find((g) => _.get(g, 'group.id') === groupId);
  const isReferralSaving = _.get(state, 'contactReferrals.isSaving', false);

  const group = _.find(_.get(state, 'groups.data'), { id: groupId }) || {};
  const referralScopes = _.get(group, 'referral_scopes', []);

  const assessmentForms = groupForms[groupId] || [];
  const returnedAssessment = groupForms.returnedAssessment || [];
  const assessmentResponses = groupForms.responses || [];

  const expandAllReferralSections = expandAllReferralReviewSections(state);
  const casesOONReferralUnlicensedOrgs = hasCasesOONReferralUnlicensedOrgs(state);
  const screeningId = _.get(ownProps, 'params.screening_id');

  const currentEmployee = _.get(state, 'globalState.currentEmployee');

  return {
    assessmentForms,
    casesOONReferralUnlicensedOrgs,
    contact,
    currentGroup,
    expandAllReferralSections,
    globalServiceTypes,
    groupId,
    isReferralSaving,
    networks,
    referralScopes,
    returnedAssessment,
    assessmentResponses,
    requestId,
    selectedContactId,
    locationSrc,
    screeningId,
    currentEmployee,
    isProgramBasedSearch: programBasedSearchSelector(state),
  };
}

const fields = newReferralFormFieldsAsServices();
export default validateReduxForm({
  form: CREATE_REFERRAL_FORM,
  fields,
  destroyOnUnmount: false,
}, mapStateToProps, {
  addNotification,
  createServiceCase,
  createServiceCaseFromAR,
  fetchGroup,
  fetchGroupsPrograms,
  submitReferral,
  saveDraftReferral,
  destroyAllDropzones,
  patchAssessmentFromReferral,
  patchCaseAssessment,
})(NewReferralReviewStep);
