const payerInitialFormValues = (payer) => ({
  payer: {
    ...payer,
    form_id: payer.id,
    external_id: payer.external_id,
    payer_wq_interactive: payer.payer_wq_interactive ? 'true' : 'false',
  },
});

export default payerInitialFormValues;
