import { SELECT_CONTACT } from 'actions';

export default function selectContact(id) {
  return (dispatch) => {
    dispatch({
      type: SELECT_CONTACT,
      id,
    });
  };
}
