import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { get, isEmpty } from 'lodash';
import ContactDetailColumn from 'src/components/ContactDetailColumn';
import { ContactDetailColumnItem } from 'src/components/ContactDetailColumn/components';
import { Divider } from '@unite-us/ui';
import { isReferredWithinNetwork } from 'src/components/Referrals/utils';

function ContactReferralSummary(props) {
  const {
    contact,
    serviceType,
    description,
    contextAction,
    referral,
    sender,
  } = props;

  const hideSender = contextAction === 'create';
  const hideDescription = isEmpty(description);

  const sentTimestamp = get(props, 'referral.created_at');
  const referralSent = sentTimestamp ? moment.unix(sentTimestamp).format('MMMM Do YYYY') : '';

  return (
    <div className="contact-referral-summary">
      <ContactDetailColumn
        contact={contact}
        id="referral-contact"
      />
      <Divider />

      <div className="referral-detail-column">
        <h1>Referral</h1>
        <ContactDetailColumnItem id="referral-column-date-sent" title="Date Sent" hide={isEmpty(referralSent)}>
          {referralSent}
        </ContactDetailColumnItem>
        <ContactDetailColumnItem id="referral-column-sender" title="Sender" hide={hideSender}>
          {sender}
        </ContactDetailColumnItem>
        <ContactDetailColumnItem id="referral-column-service-type" title="Service Type">
          {get(serviceType, 'name', '')}
        </ContactDetailColumnItem>
        {
          !isReferredWithinNetwork(referral) ? (
            <ContactDetailColumnItem id="referral-column-sending-network" title="Sending Network">
              {get(referral, 'referred_by_network.name', '')}
            </ContactDetailColumnItem>
          ) : null
        }
        <ContactDetailColumnItem id="referral-column-description" title="Description" hide={hideDescription}>
          {description}
        </ContactDetailColumnItem>
      </div>
    </div>
  );
}

ContactReferralSummary.propTypes = {
  contact: PropTypes.object,
  contextAction: PropTypes.oneOf(['create', 'send']).isRequired,
  description: PropTypes.string,
  referral: PropTypes.shape({
    created_at: PropTypes.number,
    referred_by_network: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  sender: PropTypes.string,
  serviceType: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default ContactReferralSummary;
