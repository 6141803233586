import { apiDefault } from 'src/api/config';

export default function fetchCoreLocations(groupId) {
  const request = apiDefault.get(`/locations?filter[provider]=${groupId}`);

  function onSuccess(payload) {
    return payload.data.data;
  }

  function onError(error) {
    return error;
  }

  return request.then(onSuccess, onError);
}
