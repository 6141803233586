import _ from 'lodash';
import { coordinationCenterNetwork } from 'common/utils/Group';
import { isSuperNetwork } from 'src/components/Network/utils';

export default function subNetworksFilter(currentGroup, initialFilters, secondary = false) {
  if (currentGroup && isSuperNetwork(currentGroup.networks, currentGroup.id)) {
    const network = coordinationCenterNetwork(
      currentGroup.networks,
      currentGroup.id,
    );

    const referralScope = _.find(
      currentGroup.referral_scopes,
      (scope) => _.get(scope, 'network.id', null) === network.id,
    );

    if (referralScope && !_.isEmpty(referralScope.network.sub_networks)) {
      return {
        key: 'networks',
        name: 'Sub Network',
        pluralName: 'Sub Networks',
        options: _.reduce(
          _.get(referralScope, 'network.sub_networks', []),
          (acc, subNetwork) => [
            ...acc,
            {
              label: subNetwork.name,
              value: subNetwork.id,
              initial: _.includes(initialFilters, subNetwork.id),
            },
          ],
          [
            {
              label: network.name,
              value: network.id,
              initial: _.includes(initialFilters, network.id),
            },
          ],
        ),
        secondary,
      };
    }
  }

  return null;
}
