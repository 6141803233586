import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';

const DEFAULT_UNAUTHORIZED_MESSAGE_TEXT = 'You do not have authorization to view.';

const UnauthorizedMessage = ({ clickedDataObj }) => {
  const objMessages = get(clickedDataObj, 'permissions.can_view.message', []);
  const messages = isEmpty(objMessages) ?
    [DEFAULT_UNAUTHORIZED_MESSAGE_TEXT] :
    objMessages;

  return (
    <div className="unauthorized-message">
      {
        messages.map((message, i) => (
          <p key={i} className="no-margin">
            {message}
          </p>
        ))
      }
    </div>
  );
};

UnauthorizedMessage.propTypes = {
  clickedDataObj: PropTypes.shape({
    permissions: PropTypes.shape({
      can_view: PropTypes.shape({
        message: PropTypes.array,
      }),
    }),
  }),
};

UnauthorizedMessage.defaultProps = {
  clickedDataObj: {},
};

export default UnauthorizedMessage;
