import {
  FETCH_NOTIFICATION_PREFERENCES,
  UPDATE_NOTIFICATION_PREFERENCES_SUCCESS,
} from 'actions';
import { assign } from 'lodash';

export const defaultState = {};

export default function (state = defaultState, action) {
  switch (action.type) {
    case FETCH_NOTIFICATION_PREFERENCES: {
      if (action.status === 'success') {
        return action.payload.data.data;
      }

      return state;
    }

    case UPDATE_NOTIFICATION_PREFERENCES_SUCCESS: {
      return assign({}, state, action.payload.data.data);
    }

    default:
      return state;
  }
}
