import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { BaseCard, BaseCardBody, BaseCardHeader } from '@unite-us/ui';
import moment from 'moment';

const FeeScheduleScreeningDetail = (props) => (
  <div>
    <BaseCard className="p-8" noBorder>
      <BaseCardHeader title="Fee Schedule Screening Details" noBorder className="px-4 font-bold" />
      <BaseCardBody className="px-8 bg-white">
        {
          !isEmpty(props.feeScheduleScreening) &&
          (
          <div className="grid grid-cols-4">
            <div className="col-start-1 pl-0 pb-2 font-bold">
              Name:
            </div>
            <div>
              { props.feeScheduleScreening.name }
            </div>
            <div className="col-start-1 pl-0 pb-2 font-bold">
              Start Date:
            </div>
            <div className="capitalize">
              { moment(props.feeScheduleScreening.starts_at).utc().format('MM/DD/YYYY')}
            </div>
            <div className="col-start-1 pl-0 pb-2 font-bold">
              End Date:
            </div>
            <div className="capitalize">
              { moment(props.feeScheduleScreening.ends_at).utc().format('MM/DD/YYYY') }
            </div>
            <div className="col-start-1 pl-0 pb-2 font-bold">
              Unit Rate (Cents):
            </div>
            <div className="capitalize">
              { props.feeScheduleScreening.unit_rate }
            </div>
            <div className="col-start-1 pl-0 pb-2 font-bold">
              State:
            </div>
            <div className="capitalize">
              { props.feeScheduleScreening.state }
            </div>
            <div className="col-start-1 pl-0 pb-2 font-bold">
              Code Type:
            </div>
            <div className="capitalize">
              { props.feeScheduleScreening.code_type }
            </div>
            <div className="col-start-1 pl-0 pb-2 font-bold">
              Code:
            </div>
            <div className="capitalize">
              { props.feeScheduleScreening.code }
            </div>
          </div>
          )
        }
      </BaseCardBody>
    </BaseCard>
  </div>
);

FeeScheduleScreeningDetail.propTypes = {
  feeScheduleScreening: PropTypes.object.isRequired,
};

export default FeeScheduleScreeningDetail;
