import { apiV1 } from 'src/api/config';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { get } from 'lodash';

export default function createFileUpload({ recordType, recordId, file }) {
  return async () => {
    const response = await apiV1.uploadFile(recordType, recordId, file);

    if (!response || !isHttpSuccess(response.status)) return response;

    return get(response, 'data.data.id', null);
  };
}
