import { checkVersionCommon } from 'actions/SystemVersion';

const VERSION_CHECK_INTERVAL_IN_SECONDS = 120;

const checkVersionByInterval = (dispatch) => {
  const check = checkVersionCommon.bind(this, dispatch);

  window.setInterval(check, VERSION_CHECK_INTERVAL_IN_SECONDS * 1000);
};

export default checkVersionByInterval;
