export const genderColors = [
  '#E5DC54',
  '#7ED1A3',
  '#FCC53A',
  '#BCD64D',
  '#E53935',
];

export const serviceBranchesColors = [
  '#E5DC54',
  '#7ED1A3',
  '#FCC53A',
  '#47B04B',
  '#778B9F',
  '#E53935',
  '#4A657F',
];

export const resolutionColors = [
  '#74B57D',
  '#4A657F',
];
