import { FETCH_SERVICE_TYPES_FLATTENED } from 'actions';
import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { apiV1 } from 'src/api/config';
import { includePathwaysServices } from 'common/utils/FeatureFlags/flags';

const onError = (error) => {
  Notifier.handleErrors(error);
  return error;
};

const onSuccess = (dispatch, response) => {
  const services = response.data.data;
  const childServices = services.filter((st) => Object.prototype.hasOwnProperty.call(st, 'parent'));

  const flattenedServices = services.sort((d) => d.name).reduce((map, parent) => {
    if (!(Object.prototype.hasOwnProperty.call(parent, 'parent'))) {
      // eslint-disable-next-line no-param-reassign
      map[parent.id] = {
        ...parent,
        children: childServices.filter((st) => st.parent.id === parent.id),
      };
    }
    return map;
  }, {});

  dispatch({
    type: FETCH_SERVICE_TYPES_FLATTENED,
    payload: flattenedServices,
  });
};

const fetchServices = () => (
  async (dispatch, getState) => {
    try {
      const state = getState();
      const includePathways = includePathwaysServices(state) || false;

      const response = await apiV1.query(
        'service',
        { taxonomy: 'uniteus', include_pathways: includePathways },
        { page: { number: 1, size: 250 } },
      );

      if (!response || !isHttpSuccess(response.status)) {
        return onError(response);
      }
      return onSuccess(dispatch, response);
    } catch (error) {
      return onError(error);
    }
  }
);

export default fetchServices;
