import { every } from 'lodash';

import { isReferralsUserAndAbove } from 'src/components/User/utils';

const referralHasSensitiveErrors = (caseReferrals, referral) => {
  const referralCount = caseReferrals && caseReferrals.length;
  const sensitiveReceivingProviders = caseReferrals.filter((r) => r.receiving_provider.sensitive);
  const sensitiveServiceType = referral.service?.sensitive;
  const sensitiveSendingProvider = referral.referred_by_group?.sensitive;
  return referralCount > 1 && (
    sensitiveServiceType ||
    sensitiveSendingProvider ||
    sensitiveReceivingProviders.length
  );
};

export default function canSendDraft({
  caseReferrals,
  providerId,
  referral,
  user,
}) {
  const {
    case: {
      outcome: caseOutcome,
      state: caseState,
    },
  } = referral;

  const checks = [
    isReferralsUserAndAbove(user, providerId),
    !referralHasSensitiveErrors(caseReferrals, referral),
    ['draft'].includes(caseState),
    caseOutcome === null, // user can close a case with state Draft (thus setting an outcome on it)
  ];

  return every(checks);
}
