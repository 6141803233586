import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import TabbedSelectField from '../../../../common/form/TabbedSelectField/index';
import { getGroupOptions } from '../utils';

class ReferralGroupTabbedSelect extends Component {
  constructor(props) {
    super(props);

    this.onGroupSelect = this.onGroupSelect.bind(this);
  }

  onGroupSelect(selected) {
    const { index } = this.props;
    this.props.onGroupSelect(selected, index);
  }

  render() {
    const {
      canPaginateNetworkGroups,
      groupField,
      originCoordinates,
      paginateSearchNetworkGroups,
      selectedGroupIds,
      suggestedGroups,
      nationalStateSuggestedGroups,
      query,
    } = this.props;

    const handleSearch = _.debounce(paginateSearchNetworkGroups, 1500);

    const options = getGroupOptions({
      canPaginateNetworkGroups,
      groups: suggestedGroups?.map((x, i) => ({ position: i + 1, ...x })),
      selectedGroupIds,
      originCoordinates,
    });

    const nationalStateOptions = getGroupOptions({
      canPaginateNetworkGroups: false,
      groups: nationalStateSuggestedGroups?.map((x, i) => ({ position: i + 1, ...x })),
      selectedGroupIds,
      originCoordinates,
    });

    const origData = [
      {
        label: 'Nearby Organizations',
        id: 'nearby-organizations',
        options,
      },
      {
        label: 'Also serves this area',
        id: 'national-state-organizations',
        options: nationalStateOptions,
      },
    ];

    const groupFieldValue = groupField?.value?.id ? {
      ...nationalStateOptions.find((v) => v.id === groupField?.value?.id),
      ...options.find((v) => v.id === groupField?.value?.id),
    } : {};

    return (
      <TabbedSelectField
        name="in-network-selector"
        placeholder="Choose an organization"
        searchPlaceholder="Search for an organization"
        data={origData}
        query={query}
        open={query}
        searchDescription={'Displaying top 50 results. Type to search for more.'}
        className=""
        required
        clearable
        forceOpen={false}
        truncateOptions
        value={groupFieldValue}
        onChange={(val) => { groupField.onChange(val); this.onGroupSelect(val); }}
        searchEnabled
        onSearch={(e) => handleSearch(e.target.value)}
      />
    );
  }
}

ReferralGroupTabbedSelect.propTypes = {
  canPaginateNetworkGroups: PropTypes.bool.isRequired,
  groupField: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  originCoordinates: PropTypes.array.isRequired,
  onGroupSelect: PropTypes.func.isRequired,
  selectedGroupIds: PropTypes.array,
  suggestedGroups: PropTypes.array.isRequired,
  paginateSearchNetworkGroups: PropTypes.func,
  nationalStateSuggestedGroups: PropTypes.array,
  query: PropTypes.string,
};

ReferralGroupTabbedSelect.defaultProps = {
  paginateSearchNetworkGroups () { },
  nationalStateSuggestedGroups: [],
  query: '',
};

export default ReferralGroupTabbedSelect;
