import {
  useFindRecordBase,
  useQueryBase,
  useFindAllBase,
  useCreateRecordBase,
  useUpdateRecordBase,
  useDeleteRecordBase,
  usePopulateBase,
  useSetRelationshipBase,
  useUpdateRecordMultipartBase,
  useDeleteRelationshipBase,
} from '@unite-us/json-api-resources';
import { isPlainObject } from 'lodash';
import { useMemo } from 'react';
import { useQuery as useQueryRQ, useMutation, useQueryClient } from 'react-query';
import { getAdapter } from './config';

/*
// Documentation
// eslint-disable-next-line no-unused-vars
const options = {
  // React-Query mutation options
  // Only for useCreateRecord, useUpdateRecord, useDeleteRecord
  // eg: onError, onSuccess... etc
  // https://react-query.tanstack.com/reference/useMutation
  mutationConfig: {},

  // React-Query query options
  // Only for useQuery, useFindRecord, useFindAll, usePopulate,
  // eg: select, onError, onSuccess ... etc
  // https://react-query.tanstack.com/reference/useQuery
  queryConfig: {},
  api: 'coreApi', // coreApi or apiV1 or apiV4
  jsonApiParams: {
    include: '',
    groupId: '',
    page: {},
    sort: '',
    context: '',
    httpConfig: {},
  },
};

// eslint-disable-next-line no-unused-vars
const overrideOptions = {
  mutationConfig: {},
  jsonApiParams: {},
};

*/

const defaultApi = 'apiV1';

export const useFindRecord = (modelName, id, params = {}) => {
  const { api = defaultApi, queryConfig = {}, ...rest } = params;
  return useFindRecordBase(
    modelName,
    id,
    {
      ...rest, api: getAdapter(api), useQuery: useQueryRQ, queryConfig: { enabled: true, ...queryConfig },
    },
  );
};

export const useFind = (modelName, query, params = {}) => {
  const { api = defaultApi, queryConfig = {}, ...rest } = params;
  return useQueryBase(
    modelName,
    query,
    {
      ...rest, api: getAdapter(api), useQuery: useQueryRQ, queryConfig: { enabled: true, ...queryConfig },
    },
  );
};

export const useFindAll = (modelName, params = {}) => {
  const { api = defaultApi, queryConfig = {}, ...rest } = params;
  return useFindAllBase(
    modelName,
    {
      ...rest, api: getAdapter(api), useQuery: useQueryRQ, queryConfig: { enabled: true, ...queryConfig },
    },
  );
};

export const useCreateRecord = (modelName, options = {}) => {
  const callback = useCreateRecordBase({
    useMutation,
    mutationConfig: options.mutationConfig,
  });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.createRecord = (data, overrideOptions = {}) => {
    const api = getAdapter(options.api ?? defaultApi);
    const newData = {
      model: modelName,
      data,
      params: { ...overrideOptions.jsonApiParams, api },
    };

    return mutateAsync(newData, overrideOptions.mutationConfig);
  };

  return callback;
};

export const useUpdateMultipartRecord = (modelName, options = {}) => {
  const callback = useUpdateRecordMultipartBase({ useMutation, mutationConfig: options.mutationConfig });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.updateRecord = (id, data, overrideOptions = {}) => {
    const api = getAdapter(options.api ?? defaultApi);
    const newData = {
      model: modelName,
      id,
      data,
      params: { ...overrideOptions.jsonApiParams, api },
    };
    return mutateAsync(newData, overrideOptions.mutationConfig);
  };
  return callback;
};

export const useUpdateRecord = (modelName, options = {}) => {
  const callback = useUpdateRecordBase({ useMutation, mutationConfig: options.mutationConfig });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.updateRecord = (id, data, overrideOptions = {}) => {
    const api = getAdapter(options.api ?? defaultApi);
    const newData = {
      model: modelName,
      id,
      data,
      params: { ...overrideOptions.jsonApiParams, api },
    };

    return mutateAsync(newData, overrideOptions.mutationConfig);
  };

  return callback;
};

export const useDeleteRecord = (modelName, options = {}) => {
  const callback = useDeleteRecordBase({ useMutation, mutationConfig: options.mutationConfig });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.deleteRecord = (id, overrideOptions) => {
    const api = getAdapter(options.api ?? defaultApi);
    const newData = {
      model: modelName,
      id,
      params: { ...overrideOptions.jsonApiParams, api },
    };

    return mutateAsync(newData, overrideOptions.mutationConfig);
  };

  return callback;
};

export const usePopulate = (relationshipName, modelName, data, params = {}) => {
  const { api = defaultApi, queryConfig = {}, ...rest } = params;

  return usePopulateBase(
    relationshipName,
    modelName,
    data,
    {
      ...rest,
      api: getAdapter(api),
      useQuery: useQueryRQ,
      queryConfig: { enabled: true, ...queryConfig },
    },
  );
};

export const usePopulateMemo = (response, queriesDependencies, useIsLoading = false) => {
  const isFetching = useIsLoading ?
    queriesDependencies.some((q) => q.isLoading) : queriesDependencies.some((q) => q.isFetching);
  useMemo(() => {
    if (Array.isArray(response?.data?.data)) {
      response.data.data = [...response.data.data];
    } else if (isPlainObject(response?.data?.data)) {
      response.data.data = { ...response.data.data };
    }
  }, [isFetching]);

  return isFetching;
};

export const useInvalidateQueries = () => {
  const queryClient = useQueryClient();

  return (modelName) => queryClient.invalidateQueries(modelName);
};

export const useSetRelationship = (
  modelName,
  relationshipName,
  options = {},
) => {
  const callback = useSetRelationshipBase({
    useMutation,
    mutationConfig: options.mutationConfig,
  });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.setRelationship = (modelId, relationshipIds, overrideOptions = {}) => {
    const api = getAdapter(options.api ?? defaultApi);
    const newData = {
      modelName,
      id: modelId,
      relationshipModel: relationshipName,
      value: relationshipIds,
      params: { ...overrideOptions.jsonApiParams, api },
    };

    return mutateAsync(newData, overrideOptions.mutationConfig);
  };

  return callback;
};

export const useDeleteRelationship = (
  modelName,
  relationshipName,
  options = {},
) => {
  const callback = useDeleteRelationshipBase({
    useMutation,
    mutationConfig: options.mutationConfig,
  });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.deleteRelationship = (modelId, relationshipIds, overrideOptions = {}) => {
    const api = getAdapter(options.api ?? defaultApi);
    const newData = {
      modelName,
      id: modelId,
      relationshipModel: relationshipName,
      value: relationshipIds,
      params: { ...overrideOptions.jsonApiParams, api },
    };

    return mutateAsync(newData, overrideOptions.mutationConfig);
  };

  return callback;
};
