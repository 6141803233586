// Library Imports
import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, Badge, Dialog } from '@unite-us/ui';
import { connect } from 'react-redux';

// Util Imports
import { editable, openable } from 'common/utils/Permission/Permission';
import { isCaseClosed } from 'src/components/Cases/utils';
import { goToOpenCaseViaOpenCasesIndex } from 'src/components/Cases/utils/routing';

// Action Imports
import { reopenCase } from 'actions/Case/Contact/Group';

// Component Imports
import CaseCloseWrapper from 'src/components/Cases/components/Detail/CaseCloseWrapper';
import isHttpError from 'src/common/utils/Error/isHttpError';
import ToolTip from 'src/common/ToolTip';

export class ServiceCaseActions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
    };
    this.closeDialog = this.closeDialog.bind(this);
    this.openModal = this.openModal.bind(this);
    this.reopenCase = this.reopenCase.bind(this);
  }

  closeDialog() {
    return this.reopenCaseDialog.closeDialog();
  }

  openModal() {
    return this.reopenCaseDialog.openDialog();
  }

  reopenCase() {
    const { contact, serviceCase } = this.props;
    const contactId = contact.id;

    this.setState({ submitting: true });

    return this.props.reopenCase(serviceCase)
      .then((payload) => {
        this.setState({ submitting: false }, () => {
          if (!isHttpError(payload)) {
            goToOpenCaseViaOpenCasesIndex({ serviceCase, contactId });
          }
        });
      });
  }

  render() {
    const {
      contact,
      currentProviderId,
      isCoordinationGroup,
      serviceAuthorization,
      serviceCase,
    } = this.props;

    const caseClosed = isCaseClosed(serviceCase);
    const userCanOpen = openable(serviceCase);
    const programHasCap = _.get(serviceAuthorization, 'fee_schedule_program.has_cap', false);

    if (caseClosed && programHasCap && userCanOpen) {
      return (
        <ToolTip
          enableHover
          hoverDelay={0}
          buttonComponentContent={() => (
            <Button
              id="reopen-btn"
              onClick={this.openModal}
              label="Reopen Case"
              disabled
              primary
              ref={(caseActionButton) => { this.caseActionButton = caseActionButton; }}
              data-testid={'reopen-case-btn-disabled'}
            />
          )}
          panelClassName="border bg-white border-solid border-gray-400
          rounded p-4 text-gray-600 tracking-wide leading-5 shadow-md normal-case w-80 py-2.5 px-5"
          buttonClassName="px-2 focus:outline-none"
          panelTextContent="Cases for programs with service authorization limits (caps) cannot be reopened.
          If you would like to provide additional services to the client, a new authorization
          request must be submitted and approved."
        />
      );
    }
    if (caseClosed && !programHasCap && userCanOpen) {
      return (
        <div className="reopen-action">
          <Button
            id="reopen-btn"
            onClick={this.openModal}
            label="Reopen Case"
            disabled={this.state.submitting}
            primary
            ref={(caseActionButton) => { this.caseActionButton = caseActionButton; }}
            data-testid={'reopen-case-btn'}
          />
          <div className="reopen-case-dialog">
            <Dialog
              id="reopen-closed-case--modal"
              title="Are you sure you want to reopen this case?"
              size="large"
              modal
              ref={(el) => { this.reopenCaseDialog = el; }}
            >
              <div>
                <div className="content-with-actions">
                  <div className="content-container inactive-referral-dialog__content-container">
                    <h2>
                      If you would like to document additional services provided to
                      address this client&apos;s need, please click Cancel and open a new case.
                      <p>&nbsp;</p>
                      If you would like to correct information about the outcome of
                      the existing case, please click Reopen to continue.
                    </h2>
                  </div>
                  <div className="max-w-full mb-3 actions">
                    <span className="action-item">
                      <Button
                        id="close-case-cancel-btn"
                        label="Cancel"
                        onClick={this.closeDialog}
                      />
                    </span>
                    <span className="action-item">
                      <Button
                        id="reopen-btn"
                        onClick={this.reopenCase}
                        disabled={this.state.submitting}
                        label="Reopen"
                        primary
                      />
                    </span>
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      );
    }
    if (caseClosed) {
      return (
        <Badge
          text="CASE CLOSED"
          color="#C5C8CC"
          shape="square"
          style={{ color: 'white' }}
        />
      );
    }

    return !caseClosed && editable(serviceCase, '', currentProviderId, isCoordinationGroup) && (
      <CaseCloseWrapper
        serviceCase={serviceCase}
        contact={contact}
        programHasCap={programHasCap}
      />
    );
  }
}

ServiceCaseActions.propTypes = {
  contact: PropTypes.object.isRequired,
  currentProviderId: PropTypes.string.isRequired,
  isCoordinationGroup: PropTypes.bool.isRequired,
  reopenCase: PropTypes.func.isRequired,
  serviceAuthorization: PropTypes.object.isRequired,
  serviceCase: PropTypes.object.isRequired,
};

export default connect(null, { reopenCase })(ServiceCaseActions);
