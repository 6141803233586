import { compact, map, range } from 'lodash';
import { formatTimeOfDay } from '.';

function timeOfDayOptions({
  before,
  increment = 15,
  midnightEndsDay = false,
} = {}) {
  const dayIncrements = Math.floor((24 * 60) / increment);

  return compact([
    !midnightEndsDay && { value: '0', label: formatTimeOfDay(0), disabled: before > 0 },
    ...map(range(dayIncrements), (t) => {
      if (t === 0 || t === 1440) {
        return null;
      }
      const time = t * increment;
      return {
        label: formatTimeOfDay(time),
        value: time.toString(),
        disabled: before ? time <= before : false,
      };
    }),
    midnightEndsDay && { value: '1439', label: formatTimeOfDay(0), disabled: false },
  ]);
}

export default timeOfDayOptions;
