import { isEmpty, get } from 'lodash';
import {
  useCreateRecord,
  useFind,
  useFindRecord,
  useInvalidateQueries,
  useUpdateRecord,
} from 'src/api/APIHooks';
import Notifier from 'common/helpers/Notifier';
import { defaultQueryConfig, defaultMutationConfig } from '../apiHookOptions';

export const useFindFeeScheduleScreening = (id) => useFindRecord('fee_schedule_screening', id, {
    queryConfig: {
      ...defaultQueryConfig,
      placeholderData: undefined,
    },
});

export const useFindBillingConditions = (fee_schedule_screening_id) => {
  const filters = {
    fee_schedule_item: fee_schedule_screening_id,
  };
  const { data: response } = useFind('billing_condition', filters, {
    queryConfig: {
      placeholderData: undefined,
    },
  });

  const data = get(response, 'data.data', []);
  return { data };
};

export const useUpdateFeeScheduleScreening = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useUpdateRecord('fee_schedule_screening', {
    mutationConfig: {
      onSuccess: () => {
        Notifier.dispatch(201, 'Fee Schedule Screening updated successfully');
        invalidateQueries('provider');
        invalidateQueries('fee_schedule_screening');
      },
      onError: () => {
        Notifier.dispatch('error', 'Failed to update Fee Schedule Screening');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useCreateFeeScheduleScreening = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useCreateRecord('fee_schedule_screening', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('fee_schedule_screening');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useCreateBillingCondition = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useCreateRecord('billing_condition', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('billing_condition');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};
export const useFindProvidersByFeeScheduleScreening = (id, params = {}) => useFind(
  'provider',
  { fee_schedule_screenings: id },
  {
    queryConfig: {
      placeholderData: undefined,
    },
    ...params,
  },
);

export const useFindProvidersByName = (providerName, params = {}) => useFind(
  'provider',
  { name: providerName, provider_type: 'provider' },
  {
    queryConfig: {
      enabled: !isEmpty(providerName),
      placeholderData: undefined,
      ...params?.queryConfig,
    },
    ...params,
  },
);
