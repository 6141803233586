import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { browserHistory } from 'src/common/utils/browserHistory';
import { useFindRecord, useUpdateRecord, useInvalidateQueries } from 'src/api/APIHooks';
import { Spinner } from 'common/spinners';
import { useUniteUsServices } from 'src/components/Backoffice/api/hooks/v1/serviceHooks';
import {
  useFindFormNeedConfigurations,
  useCreateFormNeedConfiguration,
  useUpdateFormNeedConfiguration,
  useDeleteFormNeedConfiguration,
} from 'src/components/Backoffice/api/hooks/v1/formNeedConfigurationHooks';
import '@unite-us/surveyjs/src/components/Creator/surveyjs-creator-styles.scss';
import { createAnswerIdSet, getUpdatedFormNeedConfigurations } from './formEditDataTransformers';
import { updateFormNeedConfigurations } from './updateFormNeedConfigurations';
import { extractQuestionsRecursive } from '../extractQuestionsRecursive';
import { useEditLogicComponent } from '../components/useEditLogicComponent';

const SurveyJSCreator = React.lazy(() => import('@unite-us/surveyjs/dist/components/Creator/SurveyJSCreator'));

const FormEdit = ({
  params,
}) => {
  const startTab = params.start_tab ?? '';
  const { data: services } = useUniteUsServices();
  const { data: formNeedConfigurations } = useFindFormNeedConfigurations(params.id);
  const { createRecord: createConfig } = useCreateFormNeedConfiguration();
  const { updateRecord: updateConfig } = useUpdateFormNeedConfiguration();
  const { deleteRecord: deleteConfig } = useDeleteFormNeedConfiguration();
  const invalidateQueries = useInvalidateQueries();
  const { updateRecord } = useUpdateRecord('form', {
    api: 'coreApi',
  });

  const { data: formResponse, isFetching } = useFindRecord('form', params.id, {
    api: 'coreApi',
    queryConfig: {
      refetchOnWindowFocus: false,
    },
  });

  const formData = formResponse.data.data;
  if (isFetching) return <Spinner />;
  const [EditLogicComponent] = useEditLogicComponent(
    formResponse,
    services,
    params.id,
    formData.name,
    formNeedConfigurations,
    createConfig,
    updateConfig,
    deleteConfig,
    invalidateQueries,
  );

  const originalQuestions = [];
  extractQuestionsRecursive(
    get(formData.configuration, 'pages[0]', []),
    originalQuestions,
  ); // populates originalQuestions array
  const originalAnswerIdSet = createAnswerIdSet(originalQuestions);

  const edit = async (json, userTab = '') => {
    const questions = [];
    extractQuestionsRecursive(get(json, 'pages[0]', []), questions); // populates question array
    const answerIdSet = createAnswerIdSet(questions);

    const hasAtLeast1Question = questions.some((f) => f.type !== 'panel' && f.type !== 'html');

    if (isEmpty(json.title)) {
      Notifier.dispatch(400, 'The title is required');
      return;
    }
    if (!hasAtLeast1Question) {
      Notifier.dispatch(400, 'At least 1 question is required');
      return;
    }

    const deletedAnswerIdSet = new Set([...originalAnswerIdSet].filter((answerId) => !answerIdSet.has(answerId)));
    const updatedFormNeedConfigurations = getUpdatedFormNeedConfigurations(formNeedConfigurations, deletedAnswerIdSet);
    let isConfigUpdateSuccess = true;
    if (updatedFormNeedConfigurations.length > 0) {
      const results = await updateFormNeedConfigurations(updatedFormNeedConfigurations, updateConfig, deleteConfig);
      isConfigUpdateSuccess = results.filter((result) => isHttpSuccess(result.value)).length === results.length;
    }

    const result = await updateRecord(params.id, {
      name: json.title,
      description: json.description ?? null,
      configuration: { pages: json.pages },
    });
    if (isHttpSuccess(result.status) && isConfigUpdateSuccess) {
      Notifier.dispatch(200, 'Form updated');
    } else {
      Notifier.dispatch(400, 'Failed to update form');
    }
    invalidateQueries('form');
    if (userTab) {
      browserHistory.push(`/backoffice/forms/${params.id}/edit/${userTab}`);
    } else {
      browserHistory.push(`/backoffice/forms/${params.id}/edit`);
    }
  };

  const jsonEdit = {
    title: formData.name,
    description: formData.description,
    ...formData.configuration,
  };

  return (
    <div className="bg-white">
      <h1 className="p-4 font-bold">Edit Form: {formData.name}</h1>
      <Suspense fallback={<Spinner />}>
        <SurveyJSCreator
          onSave={edit}
          json={jsonEdit}
          editLogicComponent={EditLogicComponent}
          startTab={startTab}
        />
      </Suspense>
    </div>
  );
};

FormEdit.propTypes = {
  params: PropTypes.object.isRequired,
};

FormEdit.defaultProps = {
};

export default FormEdit;
