import { browserHistory } from 'common/utils/browserHistory';
import {
  generateUUID,
} from 'common/utils/utils';
import {
  NEW_REFERRAL_PATH,
  NEW_REFERRAL_REVIEW_PATH,
} from '../constants';
import getScreeningIdFromPathname from '../utils/getScreeningIdFromPathname';

const screeningToreferralStepperConfig = {
  steps: [
    {
      id: generateUUID(),
      title: 'Add Referral',
      onStepClick: () => {
        const location = browserHistory.getCurrentLocation();
        const screening = getScreeningIdFromPathname(location.pathname);
        browserHistory.push(
          `/screenings/${screening.screeningId}/need/${screening.needId}${NEW_REFERRAL_PATH}`,
        );
      },
      isStepDisabled: () => false,
      isStepCompleted: ({
        activeStepIndex = -1,
      }) => activeStepIndex > 0,
      routeRegex: /^\/?screenings\/(.*)\/need\/(.*)\/referrals\/new\/(add-service-types|add-supporting-details)$/,
    },
    {
      id: generateUUID(),
      title: 'Review Referral',
      onStepClick: () => {
        const location = browserHistory.getCurrentLocation();
        const screening = getScreeningIdFromPathname(location.pathname);
        browserHistory.push(
          `/screenings/${screening.screeningId}/need/${screening.needId}${NEW_REFERRAL_REVIEW_PATH}`,
        );
      },
      isStepDisabled: ({
        activeStepIndex = -1,
      }) => activeStepIndex < 1,
      isStepCompleted: () => false,
      routeRegex: /^\/?screenings\/(.*)\/need\/(.*)\/referrals\/new\/(final-review)$/,
    },
  ],
};

export default screeningToreferralStepperConfig;
