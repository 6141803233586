import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { Icon } from '@unite-us/ui';
import { serviceTypesFilter } from 'src/components/Dashboard/utils/filter';
import FilterSummary from '../components/FilterSummary';
import FiltersDrawer from '../components/FiltersDrawer';
import ShoppingCart from '../components/ShoppingCart';
import FilterSearch from '../components/FilterSearch';
import FilterToggle from '../components/FilterToggle';
import SortBySelect from '../components/SortBySelect';
import './ReferralCreateBrowseFilters.scss';

class ReferralCreateBrowseFilters extends Component {
  constructor(props) {
    super(props);

    this.removeGroup = this.removeGroup.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  handleClickOutside(event) {
    const isTargetToggleBtn = event.target.classList.contains('filter-drawer-toggle');

    if (this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      !isTargetToggleBtn &&
      this.props.filtersDrawerOpen) {
      this.props.toggleFiltersDrawer();
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  removeGroup(group) {
    if (_.isFunction(this.props.removeGroup)) {
      this.props.removeGroup(group);
    }
  }

  render() {
    const {
      addGroups,
      contact,
      currentUserGroup,
      existingSelectedGroups,
      filtersDrawerOpen,
      groupsOptionType,
      networkId,
      onSortByChange,
      resultsCount,
      selectedGroups,
      serviceType,
      serviceTypes,
      toggleBrowse,
      toggleFiltersDrawer,
      serviceAreaSupportForOrgsFlag,
    } = this.props;

    const serviceTypesClass = () => classNames({
      'referral-create-browse-filters__filter-fields--service-types': true,
      'read-only': serviceType,
    });

    const serviceOptions = serviceTypesFilter(serviceTypes, _.get(this.props, 'filters.serviceTypes', [])).options;

    return (
      <div className="referral-create-browse-filters browse-filters">
        <div className="browse-filters__main">
          <div className="referral-create-browse-filters__filter-fields">
            <FilterSearch
              filters={this.props.filters}
              onFiltersChange={this.props.onFiltersChange}
              label="referral search"
            />

            <div className="referral-create-browse-filters__filter-fields--sort-by">
              <SortBySelect onChange={onSortByChange} />
            </div>

            <div className={serviceTypesClass()}>
              <div className="selected-service-type">

                { !serviceAreaSupportForOrgsFlag && (
                <h4>Service Type: <span className="service">{serviceType.name}</span></h4>
                )}

                { serviceAreaSupportForOrgsFlag && (
                <div className="flex items-center cursor-pointer" onClick={toggleFiltersDrawer}>
                  <Icon icon="IconFilter" color="#4f70b0" /> <span className="pl-1">More Filters</span>
                </div>
                )}
              </div>
            </div>
          </div>

          <div className="referral-create-browse-filters__shopping-cart">
            <ShoppingCart
              removeItem={this.removeGroup}
              selectedGroups={selectedGroups}
              addGroups={addGroups}
              toggleBrowse={toggleBrowse}
              serviceType={serviceType}
              existingSelectedGroups={existingSelectedGroups}
              groupsOptionType={groupsOptionType}
            />
          </div>
        </div>

        <div className="browse-filters__secondary">
          <div className="browse-filters__secondary-summary">
            <FilterSummary
              filters={this.props.filters}
              resultsCount={resultsCount}
              selectedServiceType={serviceType}
              serviceOptions={serviceOptions}
              toggleFiltersDrawer={toggleFiltersDrawer}
              message={serviceAreaSupportForOrgsFlag && 'Use the filters to adjust your search.'}
            />
          </div>

          { !serviceAreaSupportForOrgsFlag && (
          <div className="browse-filters__secondary-filters-btn text-right">
            <FilterToggle onClick={toggleFiltersDrawer} />
          </div>
          )}

        </div>

        <FiltersDrawer
          clearNetworkBrowseFilters={this.props.clearNetworkBrowseFilters}
          closeFiltersDrawer={toggleFiltersDrawer}
          contact={contact}
          currentUserGroup={currentUserGroup}
          filters={this.props.filters}
          groupsOptionType={groupsOptionType}
          inputRef={this.setWrapperRef}
          networkId={networkId}
          onFiltersChange={this.props.onFiltersChange}
          open={filtersDrawerOpen}
          setGeoFilters={this.props.setGeoFilters}
        />
      </div>
    );
  }
}

ReferralCreateBrowseFilters.propTypes = {
  addGroups: PropTypes.func,
  clearNetworkBrowseFilters: PropTypes.func.isRequired,
  contact: PropTypes.object,
  currentUserGroup: PropTypes.object.isRequired,
  existingSelectedGroups: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  resultsCount: PropTypes.number.isRequired,
  groupsOptionType: PropTypes.string,
  networkId: PropTypes.string.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  onSortByChange: PropTypes.func.isRequired,
  removeGroup: PropTypes.func,
  selectedGroups: PropTypes.array.isRequired,
  serviceType: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  serviceTypes: PropTypes.array.isRequired,
  setGeoFilters: PropTypes.func.isRequired,
  toggleBrowse: PropTypes.func.isRequired,
  toggleFiltersDrawer: PropTypes.func.isRequired,
  filtersDrawerOpen: PropTypes.bool.isRequired,
  serviceAreaSupportForOrgsFlag: PropTypes.bool,
};

ReferralCreateBrowseFilters.defaultProps = {
  existingSelectedGroups: false,
  groupsOptionType: '',
  serviceAreaSupportForOrgsFlag: false,
};

ReferralCreateBrowseFilters.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default ReferralCreateBrowseFilters;
