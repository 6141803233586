import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'common/utils/browserHistory';
import { get } from 'lodash';
import { UULogoLoader } from '@unite-us/client-utils';
import { fetchSupport } from 'common/utils/Session/actions';
import { getEulaAgreementStatus } from 'actions/Agreement';
import { loginWithSession } from 'actions/Login';
import { LogoutAuth } from 'common/form';
import './OAuthLogin.scss';

export class OAuthLogin extends Component {
  componentDidMount() {
    const { token } = this.props;

    const authInfo = {
      auth: { token },
      support: {},
    };

    this.props.loginWithSession(authInfo)
      .then(() => {
        browserHistory.push('/');
      });
  }

  render() {
    return (
      <div className="oauth-login">
        <div className="oauth-login__success">
          <UULogoLoader
            height={100}
          />
          {
            <LogoutAuth />
          }
        </div>
      </div>
    );
  }
}

OAuthLogin.propTypes = {
  loginWithSession: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
  const sessionState = get(ownProps, 'location.query.state', '');
  const token = get(ownProps, 'location.query.access_token', '');

  return {
    sessionState,
    token,
  };
}

export default connect(mapStateToProps, {
  fetchSupport,
  getEulaAgreementStatus,
  loginWithSession,
})(OAuthLogin);
