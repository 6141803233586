import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ProviderCardV2 } from '@unite-us/client-utils';
import { get, isEmpty, noop } from 'lodash';
import classNames from 'classnames';
import { SERVICE_AREA_TYPE_NATIONAL, SERVICE_AREA_TYPE_STATE } from 'components/Groups/constants';
import { flattenServiceTypes } from 'common/utils/ServiceTypes';
import './GroupListItem.scss';

function tooltipText(serve, serviceAreaServed, stateDisplayName) {
  if (serve) {
    switch (serviceAreaServed) {
      case SERVICE_AREA_TYPE_NATIONAL:
        return 'This organization serves all of the United States';
      case SERVICE_AREA_TYPE_STATE:
        return `This organization serves the state of ${stateDisplayName}`;
      default:
        return null;
    }
  }
  return null;
}

class GroupListItem extends Component {
  constructor(props) {
    super(props);

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.toggleGroupDetail = this.toggleGroupDetail.bind(this);
    this.selectGroup = this.selectGroup.bind(this);
  }

  onMouseEnter() {
    const { group } = this.props;
    this.props.toggleProviderHover(true, group.id);
  }

  onMouseLeave() {
    const { group } = this.props;
    this.props.toggleProviderHover(false, group.id);
  }

  toggleGroupDetail() {
    this.props.toggleGroupDetail(this.props.group);
  }

  selectGroup() {
    this.props.selectGroup(this.props.group);
  }

  render() {
    const {
      center,
      group,
      id,
      selected,
      selectedGroupFromMarker,
      selectedServices,
      selectGroup,
      serviceType,
      serve,
      stateDisplayName,
      serviceAreaServed,
    } = this.props;

    const listItemClass = () => classNames({
      'group-list-item': true,
      'marker-selected': (get(group, 'id') === selectedGroupFromMarker),
    });

    const originLatLng = !isEmpty(center) ? [
      get(center, 'lat'),
      get(center, 'lng'),
    ] : [];

    const provider = {
      ...group,
      hours: group.hours || group.hours_of_operation,
    };

    return (
      <div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <ProviderCardV2
          className={listItemClass()}
          id={id}
          isSelected={selected}
          onAddProvider={selectGroup}
          onRemoveProvider={selectGroup}
          onDetailClick={this.toggleGroupDetail}
          originLatLng={originLatLng}
          provider={provider}
          selectedServiceType={
            (!isEmpty(serviceType) && serviceType) ||
            (selectedServices.length > 0 && selectedServices) ||
            flattenServiceTypes(group.service_types)
          }
          serve={serve}
          tooltipText={tooltipText(serve, serviceAreaServed, stateDisplayName)}
        />
      </div>
    );
  }
}

GroupListItem.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    service_types: PropTypes.array.isRequired,
    hours_of_operation: PropTypes.array,
    hours: PropTypes.array,
    service_area_served: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  selectedGroupFromMarker: PropTypes.string,
  selectedServices: PropTypes.array,
  selectGroup: PropTypes.func,
  serviceType: PropTypes.object,
  toggleGroupDetail: PropTypes.func,
  toggleProviderHover: PropTypes.func.isRequired,
  serve: PropTypes.bool,
  stateDisplayName: PropTypes.string,
  serviceAreaServed: PropTypes.string,
};

GroupListItem.defaultProps = {
  center: {},
  selected: false,
  selectedGroupFromMarker: '',
  selectedServices: [],
  serviceAreaServed: '',
  selectGroup: noop,
  serviceType: {},
  toggleGroupDetail: noop,
  serve: false,
  stateDisplayName: '',
};

export default GroupListItem;
