import {
  FETCH_SERVICE_CASES,
  UNSET_FETCH_SERVICE_CASES,
  SET_FETCH_SERVICE_CASES,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import { coreApi } from 'src/api/config';
import { includePathwaysServices, pays5604AuthorizationForInternalCases as pays5604Flag } from 'common/utils/FeatureFlags/flags';
import _ from 'lodash';

export default function fetchServiceCases(groupId, contactId, options = { page: 1 }) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_FETCH_SERVICE_CASES });
      const state = getState();
      const includePathways = includePathwaysServices(state) || false;
      const response = await coreApi.query('case', {
        person: contactId,
        state: pays5604Flag(state) ? 'managed,off_platform,pending_authorization' : 'managed,off_platform',
        include_pathways: includePathways,
      }, {
        page: {
          number: options.page,
          size: options.size || 50,
        },
      });
      const cases = response.data.data;

      await Promise.all([
        coreApi.populateRelationship('service', 'service', cases, {
          customQuery: (modelName, ids) => coreApi.query(modelName, {
              ids,
              include_pathways: includePathways,
          }).then((res) => ({ data: { data: _.get(res, 'data.data', []) } })),
        }),
        coreApi.populateRelationship('provider', 'provider', cases),
        coreApi.populateRelationship('primary_worker', 'employee', cases),
        coreApi.populateRelationship('program', 'program', cases, {
          customQuery: (modelName, ids) => {
            const maxChunkSize = 50;

            if (ids.length <= maxChunkSize) {
              return coreApi.query(modelName, {
                ids,
                include_pathways: includePathways,
              }, { page: { number: 1, size: ids.length } });
            }

            const chunks = _.chunk(ids, maxChunkSize);
            const promises = chunks.map((chunk) => coreApi.query(modelName, {
              ids: chunk,
              include_pathways: includePathways,
            }, { page: { number: 1, size: chunk.length } }));

            return Promise.all(promises).then((responses) => ({
              data: {
                data: _.compact(responses.flatMap((res) => _.get(res, 'data.data', []))),
              },
            }));
          },
        }),
      ]);

      dispatch({
        type: FETCH_SERVICE_CASES,
        contactId,
        payload: response,
      });

      dispatch({ type: UNSET_FETCH_SERVICE_CASES });
      return response;
    } catch (error) {
      dispatch({ type: UNSET_FETCH_SERVICE_CASES });
      Notifier.handleErrors(error);
      return error;
    }
  };
}
