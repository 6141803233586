import React from 'react';
import { PropTypes } from 'prop-types';
import formatDate from 'common/utils/Dates/formatDate';
import EnrollmentStatusIndicator from '../EnrollmentStatusIndicator';

const EnrollmentDetailsCardHeader = ({ enrollmentRequest, label }) => (
  <>
    <div className="flex pb-4">
      <span className="flex w-4/5 pt-2 text-2xl font-extrabold text-text-blue">
        {label + enrollmentRequest.short_id}
      </span>
    </div>
    <div className="flex pb-2 items-center">
      <div className="flex text-sm w-4/5">
        {`Last updated: ${formatDate(enrollmentRequest.updated_at)}`}
        <div className="pl-2">
          <EnrollmentStatusIndicator status={enrollmentRequest.enrollment_status} />
        </div>
      </div>
    </div>
  </>
);

EnrollmentDetailsCardHeader.propTypes = {
  enrollmentRequest: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

export default EnrollmentDetailsCardHeader;
