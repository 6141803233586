import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  LegendRowPct,
  LegendRowValue,
  LegendShowDetail,
} from './components';

import './Legend.scss';

class Legend extends Component {
  constructor(props) {
    super(props);

    this.onLegendClick = this.onLegendClick.bind(this);
    props.chart.dispatch.on('stateChange', (e) => this.setState({ disabled: e.disabled }));

    this.state = {
      disabled: _.map(props.data, () => false),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.setState({ disabled: _.map(nextProps.data, () => false) });
    }
  }

  onLegendClick(index) {
    const disabled = _.map(this.props.data, (d, i) => {
      if (i === index) {
        return !this.state.disabled[i];
      }
      return this.state.disabled[i] || false;
    });
    this.props.chart.dispatch.changeState({ disabled });
    this.props.chart.dispatch.stateChange({ disabled });
    this.props.chart.update();
  }

  render() {
    const {
      chart,
      data,
      detailText,
      detailWidth,
      onDetailClick,
      showPercent,
      showTotal,
      title,
    } = this.props;

    if (!chart || _.isEmpty(data)) {
      return null;
    }

    const colors = chart.color();
    const x = chart.x();
    const y = chart.y();
    const total = _.reduce(data, (acc, d, i) => {
      if (_.nth(this.state.disabled, i)) {
        return acc;
      }
      return acc + y(d);
    }, 0);

    return (
      <div className="legend">
        <table className="legend__table">
          <tbody>
            {
              title && (
                <tr className="legend-title-row">
                  <td className="legend-row-title" colSpan="4">
                    <strong>{title}</strong>
                  </td>
                </tr>
              )
            }
            {
              data.map((d, index) => (
                <tr
                  className="legend-row"
                  key={`serie-${index}`}
                  onClick={() => this.onLegendClick(index)}
                  role="button"
                >
                  <td className="legend-row-title">
                    <span
                      className="legend-bullet"
                      style={{
                        borderColor: colors[index],
                        backgroundColor: this.state.disabled[index] ? 'transparent' : colors[index],
                      }}
                    />
                    {x(d)}
                  </td>
                  <LegendRowValue
                    disabled={_.isNaN(y(d)) || this.state.disabled[index]}
                    showValue
                    value={y(d)}
                  />
                  <LegendRowPct
                    disabled={_.isNaN(y(d)) || this.state.disabled[index]}
                    showValue={showPercent}
                    total={total}
                    value={y(d)}
                  />
                  <LegendShowDetail
                    data={d}
                    detailText={detailText}
                    detailWidth={detailWidth}
                    disabled={_.isNaN(y(d)) || this.state.disabled[index]}
                    hasChildren={_.get(d, 'has_children', false)}
                    index={index}
                    onDetailClick={onDetailClick}
                  />
                </tr>
              ))
            }
            {
              showTotal ? (
                <tr>
                  <td className="legend-row-title">
                    <strong>Total</strong>
                  </td>
                  <td className="legend-row-value">
                    <strong>{total}</strong>
                  </td>
                  <td className="legend-row-pct" />
                  <td className="legend-show-detail" />
                </tr>
              ) : null
            }
          </tbody>
        </table>
      </div>
    );
  }
}

Legend.propTypes = {
  chart: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  detailText: PropTypes.string.isRequired,
  detailWidth: PropTypes.string.isRequired,
  onDetailClick: PropTypes.func,
  showPercent: PropTypes.bool.isRequired,
  showTotal: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

Legend.defaultProps = {
  detailText: 'See Detail',
  detailWidth: '90px',
};

export default Legend;
