import axios from 'axios';
import {
  SET_REFERRAL_IS_SAVING,
  SUBMIT_REFERRAL,
  UNSET_REFERRAL_IS_SAVING,
} from 'actions';
import { referralParams } from 'src/components/Referrals/utils/form';
import Notifier from 'common/helpers/Notifier';
import { setDashboardRefetch } from 'actions/Dashboard';

export default function saveDraftReferral(groupId, contactId, services, options, refetch = false) {
  const url = `/groups/${groupId}/contacts/${contactId}/referrals/draft`;

  return (dispatch) => {
    function onSuccess(payload, resolve) {
      dispatch({ type: SUBMIT_REFERRAL, payload });
      Notifier.dispatch(payload.status, 'Referral Successfully Created');
      if (refetch) {
        dispatch(setDashboardRefetch());
      }

      dispatch({ type: UNSET_REFERRAL_IS_SAVING });
      resolve(payload);
      return payload;
    }

    function onError(error, reject) {
      Notifier.handleErrors(error);
      dispatch({ type: UNSET_REFERRAL_IS_SAVING });
      reject(error);
      return error;
    }

    dispatch({ type: SET_REFERRAL_IS_SAVING });

    // Wrap the async actions in a promise for use with validateReduxForm's
    // handleSubmit function and submitting prop.
    return new Promise((res, rej) => {
      referralParams(services, options).then((params) => (
        axios.post(url, params)
          .then((response) => onSuccess(response, res))
          .catch((error) => onError(error, rej))
      ));
    });
  };
}
