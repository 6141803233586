import baseColumnStyles from './baseColumnStyles';

function widthStyles(colWidth) {
  return colWidth ? {
    width: colWidth,
  } : {};
}

function alignmentStyles(alignment) {
  return alignment ? {
    textAlign: alignment,
  } : {};
}

function getColumnStyles(columnLabel = {}) {
  return {
    ...baseColumnStyles(columnLabel.type),
    ...widthStyles(columnLabel.columnWidth),
    ...alignmentStyles(columnLabel.align),
  };
}

export default getColumnStyles;
