import { compact } from 'lodash';

export default function pluckSelectedFieldIds(selectedFields, groupKey = '') {
  const pluckedFields = selectedFields.map((field) => {
    const getField = field && field[groupKey];
      if (getField) {
        return getField.value === field[groupKey].initialValue ? field[groupKey].initialValue : field[groupKey].value;
      }

    return undefined;
  });
  return compact(pluckedFields);
}
