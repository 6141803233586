import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InfoPopover } from '@unite-us/client-utils';
import { MESSAGE_FIELD_INFO_POPOVER_TEXT } from '../constants';
import '../stylesheets/messageFieldLabel.scss';

const labelClasses = (required) => classNames(
  'message-field-label__label',
  { 'message-field-label__label--required': required },
);

const MessageFieldLabel = ({ popoverId, required }) => (
  <div className="message-field-label">
    <label
      className={labelClasses(required)}
      htmlFor={popoverId}
    >
      Message
    </label>
    <InfoPopover className="message-field-label__info-icon" id={popoverId}>
      <p className="message-field-label__popover-content">
        {MESSAGE_FIELD_INFO_POPOVER_TEXT}
      </p>
    </InfoPopover>
  </div>
);

MessageFieldLabel.propTypes = {
  popoverId: PropTypes.string,
  required: PropTypes.bool.isRequired,
};

MessageFieldLabel.defaultProps = {
  popoverId: 'message-field-label-popover',
};

export default MessageFieldLabel;
