import React from 'react';
import { capitalize } from 'lodash';
import { PropTypes } from 'prop-types';
import { CardDetail, CardSubHeader } from 'common/Card';
import formatDate from 'common/utils/Dates/formatDate';
import getTimeDifference from 'common/utils/Dates/getTimeDifference';
import { ENROLLMENT_STATUSES } from '../../constants';

const RequestDetailsSection = ({ enrollmentRequest, isDisenroll }) => (
  <>
    <CardSubHeader>Request Details</CardSubHeader>
    <CardDetail label="Request Type">{capitalize(enrollmentRequest.request_type)}</CardDetail>
    <CardDetail label="Date of Request">{formatDate(enrollmentRequest.created_at)}</CardDetail>
    {enrollmentRequest.enrollment_status === ENROLLMENT_STATUSES.open &&
      <CardDetail label="Time in Queue">{getTimeDifference(enrollmentRequest.created_at)}</CardDetail>}
    <CardDetail label="Submitted By">
      {enrollmentRequest.requester?.full_name}
      <br />
      ({enrollmentRequest.requester?.provider?.name})
    </CardDetail>
    <CardDetail label="Request ID#">{enrollmentRequest.short_id}</CardDetail>
    {isDisenroll && (
      <>
        <CardDetail label="Disenrollment Reason">
          {enrollmentRequest.enrollment_disenroll_reason?.display_name}
        </CardDetail>
        <CardDetail label="Note">{enrollmentRequest.disenroll_note}</CardDetail>
      </>
    )}
  </>
);

RequestDetailsSection.propTypes = {
  enrollmentRequest: PropTypes.object.isRequired,
  isDisenroll: PropTypes.bool.isRequired,
};

export default RequestDetailsSection;
