import { compact } from 'lodash';

const getSelectedGroups = (fields, groupsOptionType) => {
  const { service_case, oonCase, selected } = fields;

  const oonCaseSelected = service_case ? service_case.oonCase.selected : oonCase.selected;
  const selectedGroups = groupsOptionType === 'in-network' ? selected : oonCaseSelected;

  return compact(selectedGroups.map((provider) => provider.group.value));
};

export default getSelectedGroups;
