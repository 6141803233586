import { includes } from 'lodash';
import { getExtension } from '.';

const VALID_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif'];

function getDataURI(file) {
  return new Promise((resolve, reject) => {
    if (!includes(VALID_EXTENSIONS, getExtension(file))) {
      resolve('');
    } else {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;
        canvas.getContext('2d').drawImage(image, 0, 0);
        resolve(canvas.toDataURL('image/png'));
      };
      image.onerror = () => reject('Unable to load the image');
      image.src = file.preview;
    }
  });
}

export default getDataURI;
