import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'common/utils/browserHistory';
import {
  useCreatePlan,
} from 'src/components/Backoffice/api/hooks/v1/planHooks';
import PlanForm from './_plan_id/PlanForm';
import { constructPlanFormPayload } from '../utils';

const CreatePlan = ({ params }) => {
  const payerId = params.id;
  const { createRecord: createPlan } = useCreatePlan();

  const onSubmit = async (plan) => {
    const payload = constructPlanFormPayload(plan, payerId);
    const response = await createPlan(payload);
    if (response) {
      browserHistory.push({ pathname: `/backoffice/payers/${payerId}` });
    }
  };

  return (
    <PlanForm onSubmit={onSubmit} payerId={payerId} />
  );
};

CreatePlan.propTypes = {
  params: PropTypes.object.isRequired,
};

export default CreatePlan;
