import { get } from 'lodash';

function newAddressFromFields(fields) {
  return {
    address_type: get(fields, 'address_type.value'),
    city: get(fields, 'city.value'),
    id: get(fields, 'id.value'),
    is_mailing_address: get(fields, 'is_mailing_address.value', false),
    line_1: get(fields, 'line_1.value'),
    line_2: get(fields, 'line_2.value'),
    postal_code: get(fields, 'postal_code.value'),
    state: get(fields, 'state.value'),
    country: 'USA',
    is_primary: get(fields, 'is_primary.value'),
  };
}

export default newAddressFromFields;
