import { REMOVE_CASE_DOCUMENT } from 'actions';
import Notifier from 'common/helpers/Notifier';
import removeDocumentsFromResource from
  'common/form/DocumentUploader/actions/removeDocumentsFromResource';

function removeCaseDocument({
  caseId, contactId, documentId, groupId,
}) {
  return (dispatch) => {
    function onSuccess() {
      dispatch({
        type: REMOVE_CASE_DOCUMENT,
        caseId,
        contactId,
        documentId,
      });

      return documentId;
    }

    function onError(error) {
      Notifier.handleErrors(error);
      return error;
    }
    return removeDocumentsFromResource({
      contactId,
      documentId,
      groupId,
      resourceId: caseId,
      resourceType: 'cases',
    }).then(onSuccess, onError);
  };
}

export default removeCaseDocument;
