import Notifier from 'common/helpers/Notifier';
import _ from 'lodash';
import { coreApi } from 'src/api/config';

const addOONReferral = (caseObj, receiving_provider) => {
  const referralResponse = coreApi.createRecord('referral', {
    state: 'off_platform',
    case: caseObj.id,
    sending_provider: caseObj.provider.id,
    receiving_provider: receiving_provider.provider_id,
    receiving_network: caseObj.network.id,
    sending_network: caseObj.network.id,
  });

  return referralResponse;
};

const addNote = (caseObj, currentEmployee, oonProvider) => {
  const noteResponse = coreApi.createRecord('note', {
    subjectType: 'case',
    subject: caseObj.id,
    employee: currentEmployee.id,
    visibility: 'public',
    category: 'external_provider',
    text: `Referred off platform to ${oonProvider.name}`,
  });

  return noteResponse;
};

export default function updateOONCase(contactId, currentEmployee, groupId, serviceCase, oonProviders) {
  return async () => {
    try {
      const currentUnlicensedRecipientsIds = serviceCase.referrals
        .filter((r) => !_.isEmpty(r.receiving_provider.id) && r.state === 'off_platform')
        .map((r) => ({
          provider_id: r.receiving_provider.id,
        }));
      const currentCustomRecipientsNames = serviceCase.referrals
        .filter((n) => !_.isEmpty(n.category) && n.category === 'external_provider')
        .map((n) => ({
          name: n.receiving_provider.name,
        }));

      const selectedUnlicensedRecipientsIds = oonProviders.service_case.out_of_network_providers
        .filter((r) => !_.isEmpty(r.provider_id))
        .map((r) => ({
          provider_id: r.provider_id,
        }));

      const selectedCustomRecipientsNames = oonProviders.service_case.out_of_network_providers
        .filter((n) => n.provider_type === 'CUSTOM')
        .map((n) => ({
          name: n.custom_name,
        }));

      const promisesList = [];
      _.map(currentUnlicensedRecipientsIds, (r) => {
        const referralToDelete = !selectedUnlicensedRecipientsIds
          .some((recipient) => (recipient.provider_id === r.provider_id));
        if (referralToDelete) {
          const FilteredReferral =
              _.filter(serviceCase.referrals, (referral) => referral.receiving_provider.id === r.provider_id);
          promisesList.push(coreApi.deleteRecord('referral', FilteredReferral[0].id));
        }
      });
      _.map(currentCustomRecipientsNames, (r) => {
        const noteToDelete = !selectedCustomRecipientsNames
          .some((recipient) => (recipient.name === r.name));
        if (noteToDelete) {
          const FilteredNote =
              _.filter(serviceCase.referrals, (referral) => referral.receiving_provider.name === r.name);
          promisesList.push(coreApi.deleteRecord('note', FilteredNote[0].id));
        }
      });
      _.map(selectedUnlicensedRecipientsIds, (r) => {
        const referralToCreate = !currentUnlicensedRecipientsIds
          .some((recipient) => (recipient.provider_id === r.provider_id));
        if (referralToCreate) {
          promisesList.push(addOONReferral(serviceCase, r));
        }
      });
      _.map(selectedCustomRecipientsNames, (n) => {
        const noteToCreate = !currentCustomRecipientsNames
          .some((r) => (r.name === n.name));
        if (noteToCreate) {
          promisesList.push(addNote(serviceCase, currentEmployee, n));
        }
      });

      let payload = [];
      if (promisesList.length) {
        payload = await Promise.all(promisesList);
      }

      Notifier.dispatch(201, 'Case Successfully Updated');
      return payload;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}
