import _ from 'lodash';
import { addElement } from '../addElements';
import { updateElement } from '../updateElements';
import removeElement from '../removeElement';
import { addCase } from '../addCases';
import {
  isCurrentSection,
} from './index';

const handleReceivedReferral = (state, element, urlData, currentSection) => {
  if (_.isEmpty(state)) {
    return undefined;
  }
  if (_.isNil(state[currentSection])) {
    return state;
  }

  const modifier = _.get(urlData, 'modifier', '');
  const toUpdateId = _.get(urlData, 'uuid', '');

  switch (modifier) {
    case 'hold': {
      if (element.recalled || element.rejection) {
        return state;
      }

      const postRemoveState = removeElement(state, toUpdateId, currentSection);
      return isCurrentSection(currentSection, 'referralsInReview') ?
        addElement(postRemoveState, element, 'referralsInReview') :
        postRemoveState;
    }

    case 'reject':
      return removeElement(state, toUpdateId, currentSection);

    case 'accept': {
      const postRemoveState = removeElement(state, toUpdateId, currentSection);
      const serviceCase = _.get(element, 'service_case', false);

      return serviceCase ?
        addCase(postRemoveState, serviceCase, currentSection) :
        postRemoveState;
    }

    case 'recall':
      return removeElement(state, toUpdateId, currentSection);

    case 'interactions':
    default:
      return updateElement(state, toUpdateId, element, currentSection);
  }
};

export { handleReceivedReferral };
