import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import Search from 'common/display/ContactStepper/components/Search';
import { connect } from 'react-redux';
import featureFlag from 'src/common/utils/FeatureFlag/FeatureFlag';
import notAuthorizedNotification from 'src/common/utils/Notifications/actions/NotAuthorizedNotification';
import { destroyForm } from 'actions/Form';
import {
  REFERRALS_CREATE_REFERRALS,
} from 'common/utils/FeatureFlag/utils/constants';
import { CREATE_REFERRAL_FORM } from 'src/components/Referrals/constants';
import { CONTACT_SEARCH_FORM } from 'common/display/ContactStepper/constants';

class SearchContactStep extends Component {
  static onComplete() {
    browserHistory.push('/referrals/new/confirm');
  }

  UNSAFE_componentWillMount() {
    this.props.destroyForm(CREATE_REFERRAL_FORM);
    this.props.destroyForm(CONTACT_SEARCH_FORM);
    if (!this.props.flagEnabled(REFERRALS_CREATE_REFERRALS)) {
      browserHistory.push('/');
      this.props.notAuthorizedNotification();
    }
  }

  render() {
    return (
      <Search
        className="new-referrals-search"
        header="Create Referral"
        subHeader="If your client has an existing record, we can use it to expedite the referral process."
        onComplete={SearchContactStep.onComplete}
      />
    );
  }
}

SearchContactStep.propTypes = {
  destroyForm: PropTypes.func.isRequired,
  flagEnabled: PropTypes.func.isRequired,
  notAuthorizedNotification: PropTypes.func,
};

export default connect(null, {
  notAuthorizedNotification,
  destroyForm,
})(featureFlag(SearchContactStep));
