import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'common/spinners';
import _ from 'lodash';
import { fetchContactDocuments } from 'actions/Document/Contact/Group';
import { fetchGroupForms, fetchGroup } from 'actions/Group';
import {
  searchNetworkGroupsByReferralScopes,
  searchNetworkBrowseGroupsForCases as searchNetworkBrowseGroups,
} from 'actions/Group/Network';
import {
  organizationPaginationLimit,
  paginateNetworkGroups,
  hasPaymentsUserAccess,
  includePathwaysServices,
  hint716SearchNetworkHubSupportPremiumSelector,
  pays5604AuthorizationForInternalCases,
} from 'src/common/utils/FeatureFlags/flags';
import { clearProgramEnrollment } from 'actions/Program';
import { fetchGroupsPrograms } from 'actions/Program/Group';
import fetchUser from 'src/actions/User/User/Provider/fetchUser';
import { fetchGroupsUsersFromProgram } from 'actions/User/Program/Group';
import { checkProgramEnrollment } from 'actions/Program/Contact/Group';
import findCurrentGroup from 'common/utils/findCurrentGroup';
import { hasGroupLicense, hasNetworkLicense } from 'common/utils/User/userGroup';
import { getReferredOONProgram } from 'src/components/Groups/Programs/utils';
import AddCaseDetailsForm from 'src/components/Cases/components/AddCaseDetailsForm';
import { CASE_DETAILS_FORM } from 'src/components/Cases/constants';
import { shouldInitializeCaseDetailsForm } from 'src/components/Cases/utils';
import { getGroup } from 'common/utils/stateHelpers';
import { filterUserLicensedNetworks } from 'src/components/Network/utils';
import { fetchGroupContact } from 'src/actions/Contact/Group';
import { splitInAndOONGroups } from 'src/components/Groups/utils';
import { removeGroupsByIds } from 'src/components/Referrals/utils/scopes';
import { removeAllGroupFields } from 'src/components/Referrals/ReferralGroupsPrograms/utils';
import { requiredMapScript } from 'src/components/Browse/Map/utils';
import $scripts from 'scriptjs';
import { getOurCoordinates, getClientAddress, getClientCenter } from 'src/components/Browse/utils/address';
import { getEmployee } from 'src/components/Employee/employeeGetters';
import { isReferralsAdminAndAbove } from 'src/components/User/utils/isReferralsAdminAndAbove';

export class AddCaseDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      fetchingOONGroups: false,
      onServiceTypeChangeFetchEmpty: false,
      originCoordinates: [],
      oonGroups: [],
      browseMapGroups: [],
      isFetchingCoordinates: true,
    };

    this.buildParams = this.buildParams.bind(this);
    this.searchNetworkGroups = this.searchNetworkGroups.bind(this);
    this.formatGroupResponseData = this.formatGroupResponseData.bind(this);
    this.getCenterCoordinates = this.getCenterCoordinates.bind(this);
    this.paginateSearchNetworkGroups = this.paginateSearchNetworkGroups.bind(this);
    this.removeSelectedBrowseGroup = this.removeSelectedBrowseGroup.bind(this);
    this.removeSelfAndSender = this.removeSelfAndSender.bind(this);
    this.returnOONGroups = this.returnOONGroups.bind(this);
    this.searchOONGroups = this.searchOONGroups.bind(this);
    this.setBrowseMapGroups = this.setBrowseMapGroups.bind(this);
  }

  componentDidMount() {
    const {
      contactId,
      currentEmployee,
      groupId,
      shouldInitializeForm,
      usePaymentsUserRole,
      includePathways,
      pays5604AuthorizationForInternalCasesFlag,
      user,
    } = this.props;

    const isReferralAdminUserAndAbove = isReferralsAdminAndAbove(user, groupId);

    let pays5604AuthorizationForInternalCasesOptions = {};

    if (pays5604AuthorizationForInternalCasesFlag) {
      pays5604AuthorizationForInternalCasesOptions = {
        referablePerson: contactId,
        referableEmployee: currentEmployee.id,
        excludeAuthorizationRequired: !isReferralAdminUserAndAbove,
      };
    }

    const promises = shouldInitializeForm ? [
      this.props.fetchGroup(groupId),
      this.props.fetchGroupContact(groupId, contactId),
      this.props.fetchContactDocuments({ groupId, contactId }),
      this.props.fetchUser({ employeeId: currentEmployee.id }),
      this.props.fetchGroupsPrograms(
        groupId,
        {
          includeDefault: true,
          active: 'true',
          contactId: usePaymentsUserRole || includePathways ? contactId : undefined,
          excludeAuthorizationRequired: true,
          ...pays5604AuthorizationForInternalCasesOptions,
        },
      ),
    ] : [];
    Promise.all(promises).then(() => {
      this.getCenterCoordinates(); // Start fetching coordinates as soon as the component mounts.
      this.setState({ loading: false });
    });
  }

  /*
    * Here we fetch coordinates to be used when fetching network group on line::181
    * The coordinates to be used follow this hierarchy:
      * client -> employee -> group -> geo -> ip
    * Store these coordinates on state as "originCoordinates"
  */
  getCenterCoordinates() {
    const {
      contact = {},
      employee,
      currentUserGroup,
      geoCoordinates,
      ipCoordinates,
    } = this.props;

    const scriptsEmpty = () => _.isEmpty(requiredMapScript);

    // If the scripts are empty, we still want to set state with coordinates.
    if (scriptsEmpty()) {
      const clientCenter = getClientCenter(contact);
      const ourCoordinates = getOurCoordinates({
        employee,
        currentUserGroup,
        geoCoordinates,
        ipCoordinates,
      });
      const center = clientCenter || ourCoordinates || {};

      if (center) {
        this.setState({
          originCoordinates: _.isEmpty(center) ? [] : [center.lat, center.lng],
          isFetchingCoordinates: false,
        });
      }
      return;
    }

    // fetch client coordinates from Google.
    // if no client coordiantes exist, we set state with "our Coordinates".
    $scripts(requiredMapScript.scripts, () => {
      this.setState({ ...this.state, scriptsLoaded: true }, async () => {
        // try getting a valid contact address if there is a contact in props
        const clientAddress = await (!_.isEmpty(contact.addresses) && getClientAddress(contact));

        // get the coordinates of the current user, current group;
        const ourCoordinates = getOurCoordinates({
          employee,
          currentUserGroup,
          geoCoordinates,
          ipCoordinates,
        });

        // check to see if there is a valid contact address that has latitude and longitude
        const shouldUseContactAddress = !_.isEmpty(contact.addresses) &&
          !_.isError(clientAddress) &&
          clientAddress !== 'ZERO_RESULTS';

        const clientCoordinates = shouldUseContactAddress ?
          _.get(clientAddress, 'latLng', undefined) :
          undefined;

        const center = clientCoordinates || ourCoordinates || {};

        this.setState({
          originCoordinates: _.isEmpty(center) ? [] : [center.lat, center.lng],
          isFetchingCoordinates: false,
        });
      });
    });
  }

  setBrowseMapGroups(oonGroups, browseMapGroups) {
    this.setState({ oonGroups, browseMapGroups });
  }

  removeSelectedBrowseGroup(fields) {
    const { browseMapGroups } = this.state;
    /*
      When the user removes a selected browse map group, we want to make sure it doesnt
      render in along with the closest 50 groups in dropdowns.
    */
    if (browseMapGroups.length) {
      const selectedOONGroups = fields.service_case.oonCase.selected;

      // Filtering the browseMapGroups by those that are in the selected fields so if a group is deselected,
      // it is also removed from the list of dropdown groups.
      const filteredBrowseMapGroups = browseMapGroups.filter((bmg) => (
        _.some(selectedOONGroups, { group: { value: { id: bmg.id } } })
      ));

      this.setState({
        oonGroups: [
          ...this.state.oonGroups,
          ...filteredBrowseMapGroups,
        ],
        browseMapGroups: filteredBrowseMapGroups,
      });
    }
  }

  buildParams({ fields, text }) {
    const {
      currentUserGroup,
      groupId,
      serviceTypes,
      usePaymentsUserRole,
      hint716SearchNetworkHubSupportPremium,
    } = this.props;
    const { originCoordinates } = this.state;
    const permitted_networks = fields.network.value.id;
    const serviceType = _.get(fields, 'service_case.service_type.value', {});

    const reqParams = {
      q: {
        'locations][radius][latitude': originCoordinates[0],
        'locations][radius][longitude': originCoordinates[1],
        'locations][radius][distance': 2500,
        licensed: false,
        networks: permitted_networks,
        'programs.services': serviceType.id,
        ...(text && { query: text }),
        ...(usePaymentsUserRole && { include_payments: true }),
      },
      page: 1,
      size: 50,
      sort: 'distance',
    };

    return {
      browseMapView: false,
      currentUserGroup,
      groupId,
      networkId: fields.network.value.id,
      reqParams,
      serviceTypes,
      hint716SearchNetworkHubSupportPremium,
    };
  }

  searchNetworkGroups(search, fields) {
    const { browseMapGroups } = this.state;
    const params = this.buildParams({ fields, text: search });

    // if it's the intial search (on service type change paginatesearchNetwrokGroups))
    // else debounced.
    const canSearch = _.get(fields, 'service_case.service_type.value.id', '') &&
      _.get(fields, 'network.value.id', '');

    if (canSearch) {
      this.props.searchNetworkBrowseGroups(params)
        .then((networkGroups) => {
          if (Array.isArray(networkGroups)) {
            /* filteredGroups
              * Only include groups that includes the search text in its name.
            */
            const filteredGroups = _.filter(networkGroups, ({ name }) => (
              _.includes(_.toLower(name), _.toLower(search))
            ));

            /* browseMapGroupsToAdd
              * If we search for specific groups, only show those groups.
              * Otherwise, onBlur, append the selected browse map groups to the dropdown list.
            */
            const browseMapGroupsToAdd = search.length ? [] : browseMapGroups;

            const allGroups = [...filteredGroups, ...browseMapGroupsToAdd];

            if (filteredGroups.length) {
              this.setState({ oonGroups: allGroups });
            }
          }
        });
    }
  }

  // This is the initial request for 50 closest groups.
  // It will be called when the user selects a service type.
  paginateSearchNetworkGroups(fields) { // paginateNetworkGroups on
    this.setState({ fetchingOONGroups: true }, () => {
      const params = this.buildParams({ fields });

      this.props.searchNetworkBrowseGroups(params)
        .then((oonGroups) => {
          if (Array.isArray(oonGroups)) {
            removeAllGroupFields(fields.service_case.oonCase.selected);

            const allGroups = this.removeSelfAndSender(oonGroups);

            this.setState({
              onServiceTypeChangeFetchEmpty: !allGroups.length,
              oonGroups: allGroups,
              fetchingOONGroups: false,
            });
          }
        });
    });
  }

  removeSelfAndSender(groups) {
    const { groupId } = this.props;
    return removeGroupsByIds(groups, [groupId, groupId]);
  }

  formatGroupResponseData(response) {
    return splitInAndOONGroups(this.removeSelfAndSender(_.get(response, 'data.data', [])));
  }

  searchOONGroups(fields) { // paginateNetworkGroups off
    removeAllGroupFields(fields.service_case.oonGroups);

    this.returnOONGroups(fields).then((data) => {
      const oonGroups = this.formatGroupResponseData(data).outOfNetwork;
      _.each(oonGroups, (group) => {
        fields.service_case.oonGroups.addField({ group });
      });

      removeAllGroupFields(fields.service_case.oonCase.selected);
      this.setState({ fetchingOONGroups: false });
    });
  }

  returnOONGroups(fields) {
    const {
      groupId,
      pageLimit,
    } = this.props;

    const referredByNetworkId = fields.network.value.id;
    const referredToNetworkId = fields.network.value.id;
    const service_type_ids = [fields.service_case.service_type.value.id];
    const params = {
      referredByNetworkId,
      referredToNetworkId,
      groupId,
      options: {
        per: pageLimit,
        q: {
          service_type_ids,
          permitted_networks: [referredByNetworkId],
        },
      },
    };

    return new Promise((resolve) => {
      this.setState({ fetchingOONGroups: true }, () => {
        this.props.searchNetworkGroupsByReferralScopes(params).then((response) => {
          resolve(response);
        });
      });
    });
  }

  render() {
    const { assistanceRequest, shouldInitializeForm } = this.props;
    const {
      fetchingOONGroups,
      isFetchingCoordinates,
      loading,
      originCoordinates,
      onServiceTypeChangeFetchEmpty,
    } = this.state;

    if ((shouldInitializeForm && loading) || isFetchingCoordinates) {
      return <Spinner />;
    }
    return (
      <AddCaseDetailsForm
        {...this.props}
        assistanceRequest={assistanceRequest}
        debouncedSearchNetworkGroups={this.searchNetworkGroups}
        fetchingOONGroups={fetchingOONGroups}
        oonGroups={this.props.groups}
        originCoordinates={originCoordinates}
        paginateSearchNetworkGroups={this.paginateSearchNetworkGroups}
        removeSelectedBrowseGroup={this.removeSelectedBrowseGroup}
        onServiceTypeChangeFetchEmpty={onServiceTypeChangeFetchEmpty}
        searchOONGroups={this.searchOONGroups}
        setBrowseMapGroups={this.setBrowseMapGroups}
      />
    );
  }
}

AddCaseDetails.propTypes = {
  assistanceRequest: PropTypes.object,
  caseProgramEnrollment: PropTypes.object.isRequired,
  checkProgramEnrollment: PropTypes.func.isRequired,
  clearProgramEnrollment: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired,
  contactId: PropTypes.string.isRequired,
  currentEmployee: PropTypes.object.isRequired,
  currentUserGroup: PropTypes.object.isRequired,
  employee: PropTypes.object.isRequired,
  fetchContactDocuments: PropTypes.func.isRequired,
  fetchGroup: PropTypes.func.isRequired,
  fetchGroupContact: PropTypes.func.isRequired,
  fetchGroupForms: PropTypes.func.isRequired,
  fetchGroupsPrograms: PropTypes.func.isRequired,
  fetchGroupsUsersFromProgram: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  geoCoordinates: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  groupForms: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  groupsPrograms: PropTypes.object.isRequired,
  groups: PropTypes.array,
  groupsUsers: PropTypes.object.isRequired,
  hasGroupLicense: PropTypes.bool.isRequired,
  hasNetworkLicense: PropTypes.bool.isRequired,
  ipCoordinates: PropTypes.object.isRequired,
  isCheckingProgramEnrollment: PropTypes.bool.isRequired,
  isCoordinationGroup: PropTypes.bool.isRequired,
  isLoadingOptions: PropTypes.bool.isRequired,
  networks: PropTypes.array.isRequired,
  pageLimit: PropTypes.number.isRequired,
  params: PropTypes.object.isRequired,
  oonProgram: PropTypes.object,
  programmingOptionsArray: PropTypes.array.isRequired,
  programsServiceTypes: PropTypes.object.isRequired,
  searchNetworkBrowseGroups: PropTypes.func.isRequired,
  searchNetworkGroupsByReferralScopes: PropTypes.func.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  shouldInitializeForm: PropTypes.bool.isRequired,
  usePaymentsUserRole: PropTypes.bool.isRequired,
  includePathways: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    networks: PropTypes.array.isRequired,
  }).isRequired,
  hint716SearchNetworkHubSupportPremium: PropTypes.bool.isRequired,
  pays5604AuthorizationForInternalCasesFlag: PropTypes.bool.isRequired,
};

AddCaseDetails.defaultProps = {
  assistanceRequest: {},
  oonProgram: {},
  groups: [],
};

export function mapStateToProps(state, ownProps) {
  const {
    assistanceRequests: { requests },
    caseProgramEnrollment,
    contacts,
    contactDocuments,
    form,
    groupForms,
    groupsUsers,
    groupsPrograms,
    session: {
      position: {
        geoCoordinates,
        ipCoordinates,
      },
      groupId,
    },
    user,
  } = state;

  const contactId = _.get(ownProps, 'params.id', {});

  const activePrograms = groupsPrograms.data.filter((program) => (
    program.attributes.is_active
  ));

  const activeGroupsPrograms = { ...groupsPrograms, data: activePrograms };

  const currentEmployee = state.globalState.currentEmployee || {};
  const currentUserGroup = getGroup(state, groupId) || {};
  const userGroupNetworks = _.get(currentUserGroup, 'networks', []);
  const networks = filterUserLicensedNetworks(user, userGroupNetworks);
  const isCoordinationGroup = _.get(state, 'session.isCoordinationGroup', false);
  const oonProgram = getReferredOONProgram(activeGroupsPrograms.data);
  const defaultProgram = isCoordinationGroup ? oonProgram : {};
  const allContacts = _.get(contacts, 'contacts', []);
  const serviceTypes = _.get(state, 'session.globals.service_types', []);
  const contact = _.find(allContacts, { id: contactId }) || {};
  const groups = _.get(state, 'browse.groups', []);
  const sessionState = _.get(state, 'session', {});
  const group = findCurrentGroup(user, sessionState);
  const assistanceRequest = _.find(requests, { id: _.get(ownProps, 'params.assistance_request_id', '') });
  const employee = getEmployee({ state });

  const { data: programmingOptions } = activeGroupsPrograms;
  const programsServiceTypes = _.get(state, 'serviceTypes.programs', []);
  const canPaginateNetworkGroups = paginateNetworkGroups(state);

  return {
    currentEmployee,
    assistanceRequest,
    canPaginateNetworkGroups,
    caseProgramEnrollment: state.caseProgramEnrollment.data,
    contact,
    contactDocuments,
    contactId,
    currentUserGroup,
    defaultProgram,
    employee,
    geoCoordinates,
    group: group.group,
    groupId,
    groupForms: _.get(groupForms, groupId, []),
    groupsPrograms: activeGroupsPrograms,
    groups,
    groupsUsers,
    hasGroupLicense: hasGroupLicense(group),
    hasNetworkLicense: hasNetworkLicense(group),
    isCheckingProgramEnrollment: caseProgramEnrollment.isFetching,
    isCoordinationGroup,
    isLoadingOptions: _.isEmpty(groupsUsers.data) || _.isEmpty(groupsPrograms.data),
    ipCoordinates,
    networks,
    oonProgram,
    programmingOptionsArray: programmingOptions,
    // programsServiceTypes: _.get(state, 'serviceTypes.caseManagement', {}),
    // TODO: Migrations, We need a V4 endpoint that supports serviceTypes.caseManagement
    programsServiceTypes,
    serviceTypes,
    shouldInitializeForm: shouldInitializeCaseDetailsForm(form[CASE_DETAILS_FORM]),
    pageLimit: organizationPaginationLimit(state),
    usePaymentsUserRole: hasPaymentsUserAccess(state),
    includePathways: includePathwaysServices(state),
    user,
    hint716SearchNetworkHubSupportPremium: hint716SearchNetworkHubSupportPremiumSelector(state),
    pays5604AuthorizationForInternalCasesFlag: pays5604AuthorizationForInternalCases(state),
  };
}

export default connect(mapStateToProps, {
  checkProgramEnrollment,
  clearProgramEnrollment,
  fetchGroup,
  fetchGroupContact,
  fetchContactDocuments,
  fetchGroupForms,
  fetchGroupsPrograms,
  fetchGroupsUsersFromProgram,
  fetchUser,
  searchNetworkBrowseGroups,
  searchNetworkGroupsByReferralScopes,
})(AddCaseDetails);
