import {
  get,
  isEmpty,
  head,
  tail,
  assign,
  reduce,
  eq,
  map,
} from 'lodash';
import { updateElement } from './updateElements';

function updateNestedContact(state, contact, target) {
  return assign({}, state, {
    [target]: {
      ...state[target],
      data: map(state[target].data, (page) => {
        const pageData = get(page, 'data', []);
        return assign({}, page, {
          ...page,
          data: reduce(pageData, (acc, curr) => {
            if (eq(get(curr, 'contact.id', false), get(contact, 'id'))) {
              return [
                ...acc,
                assign({}, curr, {
                  ...curr,
                  contact,
                }),
              ];
            }

            return [
              ...acc,
              curr,
            ];
          }, []),
        });
      }),
    },
  });
}

function updateContact(state, element, urlData, currentSection) {
  const toUpdateId = get(urlData, 'uuid', '');
  const modifier = get(urlData, 'modifier', '');

  switch (modifier) {
    case 'care-coordinators':
      return updateNestedContact(state, element, currentSection);
    default:
      return currentSection === 'clients' ? updateElement(state, toUpdateId, element, 'clients') : state;
  }
}

export default function updateContacts(state, payload = [], urlData, currentSection) {
  if (isEmpty(payload)) { return state; }

  return updateContacts(
    updateContact(state, head(payload), urlData, currentSection),
    tail(payload),
    urlData,
    currentSection,
  );
}
