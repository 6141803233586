import _, { get } from 'lodash';
import Notifier from 'common/helpers/Notifier';
import {
  SEARCH_NETWORK_BROWSE_GROUPS,
  SET_NETWORK_BROWSE_GROUPS_FETCHING,
  UNSET_NETWORK_BROWSE_GROUPS_FETCHING,
} from 'actions';
import { getStateData } from 'src/components/Browse/Map/utils/geoCode';
import { serviceTypes as serviceTypeUtils } from '@unite-us/client-utils';
import { coreApi } from 'src/api/config';
import { addAdditionalProviderAttributes } from 'src/actions/Group/Network/searchProviderUtils';
import { includePathwaysServices } from 'common/utils/FeatureFlags/flags';
import fetchProviderBrowseResultsFromSearch from './fetchProviderBrowseResultsFromSearch';
import fetchProgramBrowseResultsFromSearch from './fetchProgramBrowseResultsFromSearch';

const unsetFetching = (dispatch) => {
  dispatch({
    type: UNSET_NETWORK_BROWSE_GROUPS_FETCHING,
  });
};

const onError = (error, dispatch) => {
  Notifier.handleErrors(error);
  unsetFetching(dispatch);
  return error;
};

const isLicensed = (networkScopes) => {
  if (!networkScopes || !networkScopes.length || networkScopes.length === 2) { return undefined; }
  if (networkScopes[0] === 'in-network') { return true; }
  return false;
};

const getLocationsFilter = (params) => ({
  radius: {
    distance: params.q.coordinates ? parseFloat(params.q.distance) || 25000 : null,
    longitude: get(params, 'q.coordinates.lon'),
    latitude: get(params, 'q.coordinates.lat'),
  },
});

const getPageFilter = (params) => ({
  page: {
    number: params.page,
    size: params.per || 10,
  },
  sort: params.q.force_sort_by_name || params.q.filterByServiceArea ? 'name' : 'distance',
});

// used on My Network page
export const searchMyNetworkBrowse = ({
  filters,
  groupId,
  networkId,
  reqParams,
  serviceTypes,
  type,
  serviceAreaSupportForOrgsFlag,
  hint716SearchNetworkHubSupportPremium,
}) => (
  async (dispatch) => {
    const locationsFilter = getLocationsFilter(reqParams);
    const page = getPageFilter(reqParams, serviceAreaSupportForOrgsFlag);

    const {
      accessibility_options,
      caters_to,
      fee_schedule_ids,
      include_pathways,
      include_payments,
      languages,
      referable,
      service_type_ids: services,
    } = reqParams.q;
    const licensed = isLicensed(filters.networkScopes);

    const programsFilter = {
      'programs.languages': (languages && languages.length ? languages.join(',') : null),
      'programs.services': (services && services.length ? services.join(',') : null),
      'programs.population_specializations': (caters_to && caters_to.length ? caters_to.join(',') : null),
      'programs.accessibility_options': (
        accessibility_options && accessibility_options.length ? accessibility_options.join(',') : null
      ),
      'programs.fee_schedule_program.fee_schedule': (
        fee_schedule_ids && fee_schedule_ids.length ? fee_schedule_ids.join(',') : null
      ),
    };

    const permitted_networks = get(reqParams, 'q.permitted_networks', []);

    const params = {
      networks: permitted_networks.join(',') || networkId,
      query: reqParams.q.text,
      licensed,
      state: 'active',
      ...(Object.keys(locationsFilter).length ? { locations: locationsFilter } : null),
      ...(Object.keys(programsFilter).length ? programsFilter : null),
      ...(referable && { referable }),
    };

    let networkGroups;
    if (!type || type === 'location') {
      dispatch({ type: SET_NETWORK_BROWSE_GROUPS_FETCHING });
      // HINT-8: Fetch My Network Program Locations
      const payload = await fetchProviderBrowseResultsFromSearch(
        {
          ...params,
          ...(include_pathways && { include_pathways: true }),
          ...(include_payments && { include_payments: true }),
          ...(hint716SearchNetworkHubSupportPremium && { type: 'all' }),
        },
        page,
        hint716SearchNetworkHubSupportPremium ? { 'filter[provider.type]': 'all' } : {},
      );
      networkGroups = payload.data.data;

      const composedPayload = {
        ...payload,
        data: {
          ...payload.data,
          data: serviceTypeUtils.composeGroupsList(networkGroups, serviceTypes),
        },
      };

      addAdditionalProviderAttributes(composedPayload.data.data);
      dispatch({
        type: SEARCH_NETWORK_BROWSE_GROUPS,
        payload: composedPayload,
        networkId,
        groupId,
        filters,
      });
      dispatch({ type: UNSET_NETWORK_BROWSE_GROUPS_FETCHING });
    }

    if (!type || type === 'serviceArea') {
      dispatch({ type: SET_NETWORK_BROWSE_GROUPS_FETCHING, list: 'serviceArea' });
      const { stateAbbreviation, stateDisplayName } = await getStateData({
        lat: get(locationsFilter, 'radius.latitude', '0'),
        lng: get(locationsFilter, 'radius.longitude', '0'),
      });
      delete params.locations;
      delete page.sort;
      params['programs.service_areas'] = { region: { state: stateAbbreviation } };
      // HINT-8: Fetch My Network Program Service Areas
      const payloadServiceAreas = await fetchProviderBrowseResultsFromSearch(
        {
          ...params,
          ...(include_pathways && { include_pathways: true }),
          ...(include_payments && { include_payments: true }),
          ...(hint716SearchNetworkHubSupportPremium && { type: 'all' }),
        },
        page,
        hint716SearchNetworkHubSupportPremium ? { 'filter[provider.type]': 'all' } : {},
      );

      const dataServiceAreas = payloadServiceAreas.data.data;

      const composedPayloadServiceAreas = {
        ...payloadServiceAreas,
        data: {
          ...payloadServiceAreas.data,
          data: serviceTypeUtils.composeGroupsList(dataServiceAreas, serviceTypes),
        },
      };
      addAdditionalProviderAttributes(composedPayloadServiceAreas.data.data);
      dispatch({
        type: SEARCH_NETWORK_BROWSE_GROUPS,
        payload: composedPayloadServiceAreas,
        networkId,
        groupId,
        filters,
        list: 'serviceArea',
        stateDisplayName,
      });
      dispatch({ type: UNSET_NETWORK_BROWSE_GROUPS_FETCHING, list: 'serviceArea' });
    }

    return networkGroups;
  }
);

// used on Create Referral page
const searchNetworkBrowseGroups = ({
    browseMapView,
    filters,
    groupId,
    networkId,
    reqParams,
    serviceTypes,
    isOON,
    type,
    serviceAreaSupportForOrgsFlag,
    isProgramBasedSearch,
    browseMapProgramIds,
    hint716SearchNetworkHubSupportPremium,
}) => (
  async (dispatch, getState) => {
    try {
      const locationsFilter = getLocationsFilter(reqParams);
      const page = getPageFilter(reqParams, serviceAreaSupportForOrgsFlag);
      const reduxState = getState();
      const includePathways = includePathwaysServices(reduxState);

      const {
        service_type_ids: services,
        languages,
        caters_to,
        accessibility_options,
        filterByServiceArea,
        include_pathways,
        include_payments,
        state,
        text,
        fee_schedule_ids,
        sensitive,
        feeScheduleProgramId,
      } = reqParams.q;

      let fetchedState;
      if (filterByServiceArea) {
        fetchedState = (await getStateData({
          lat: reqParams.q.coordinates.lat, lng: reqParams.q.coordinates.lon,
        })).stateAbbreviation || state;
      }

      const licensed = !isOON;

      const referable = licensed ? {
        ...(reqParams.q.referable ? reqParams.q.referable : {}),
        provider: groupId,
        network: networkId,
        recipient_network: reqParams.q.permitted_networks.join(','), // it should only be one network id
      } : {};
      const networksFilter = licensed ? null : networkId;

      if (!browseMapView) {
        dispatch({ type: SET_NETWORK_BROWSE_GROUPS_FETCHING });
        const params = {
          networks: networksFilter,
          licensed,
          ...(typeof sensitive === 'boolean' ? { sensitive } : null),
          ...(referable ? { referable } : null),
          ...(Object.keys(locationsFilter).length && !filterByServiceArea ? { locations: locationsFilter } : null),
          ...(Object.keys(locationsFilter).length ? { locations: locationsFilter } : null),
          ...(fee_schedule_ids ? { 'programs.fee_schedule_program.fee_schedule': fee_schedule_ids.join(',') } : null),
          ...(isOON ? {} : { 'programs.receiving_referrals': true }),
          ...(services ? { 'programs.services': services.join(',') } : null),
          state: 'active',
          ...(filterByServiceArea ?
            {
              'programs.service_areas':
              {
                region:
                {
                  ...(fetchedState ? { state: fetchedState } : null),
                },
              },
            } : null),
          ...(text ? { query: text } : null),
          ...(
            feeScheduleProgramId ?
              {
                'programs.fee_schedule_program': feeScheduleProgramId,
              } : null
          ),
          ...(include_pathways && { include_pathways: true }),
          ...(include_payments && { include_payments: true }),
        };

        let payload;
        if (isProgramBasedSearch) {
          payload = await fetchProgramBrowseResultsFromSearch({
            ...params,
            ...(hint716SearchNetworkHubSupportPremium && { 'provider.type': 'all' }),
          }, page, browseMapProgramIds);
        } else {
          payload = await fetchProviderBrowseResultsFromSearch(
            {
              ...params,
              ...(hint716SearchNetworkHubSupportPremium && { type: 'all' }),
            },
            page,
            hint716SearchNetworkHubSupportPremium ? { 'filter[provider.type]': 'all' } : {},
          );
        }
        const networkGroups = payload.data.data;

        if (!isProgramBasedSearch) {
          await coreApi.populateRelationship('programs', 'program', networkGroups, {
            customQuery: (modelName, ids) => {
              const maxChunkSize = 50;

              if (ids.length <= maxChunkSize) {
                return coreApi.query(modelName, {
                  ids,
                  include_pathways: includePathways,
                }, { page: { number: 1, size: ids.length } });
              }

              const chunks = _.chunk(ids, maxChunkSize);
              const promises = chunks.map((chunk) => coreApi.query(modelName, {
                ids: chunk,
                include_pathways: includePathways,
              }, { page: { number: 1, size: chunk.length } }));

              return Promise.all(promises).then((responses) => ({
                data: {
                  data: _.compact(responses.flatMap((res) => _.get(res, 'data.data', []))),
                },
              }));
            },
          });
        }

        dispatch({ type: UNSET_NETWORK_BROWSE_GROUPS_FETCHING, list: filterByServiceArea && 'serviceArea' });

        addAdditionalProviderAttributes(networkGroups);
        return networkGroups;
      }

      const programsFilter = {
        ...(include_pathways && { include_pathways: true }),
        ...(include_payments && { include_payments: true }),
        ...(isOON ? {} : { 'programs.receiving_referrals': true }),
        ...(languages && languages.length ? { 'programs.languages': languages.join(',') } : null),
        ...(services && services.length ? { 'programs.services': services.join(',') } : null),
        ...(caters_to && caters_to.length ? { 'programs.population_specializations': caters_to.join(',') } : null),
        ...(typeof sensitive === 'boolean' ? { sensitive } : null),
        ...(
          accessibility_options && accessibility_options.length ?
            {
              'programs.accessibility_options': accessibility_options.join(','),
            } : null
        ),
        ...(
          fee_schedule_ids && fee_schedule_ids.length ?
            {
              'programs.fee_schedule_program.fee_schedule': fee_schedule_ids.join(','),
            } : null
        ),
        ...(
          feeScheduleProgramId ?
            {
              'programs.fee_schedule_program': feeScheduleProgramId,
            } : null
        ),
      };

      const params = {
        networks: networksFilter,
        query: reqParams.q.text,
        licensed,
        ...(typeof sensitive === 'boolean' ? { sensitive } : null),
        ...(Object.keys(locationsFilter).length ? { locations: locationsFilter } : null),
        ...(Object.keys(programsFilter).length ? programsFilter : null),
        ...(referable ? { referable } : null),
        state: 'active',
      };

      let networkGroups;
      if (!type || type === 'location') {
        dispatch({ type: SET_NETWORK_BROWSE_GROUPS_FETCHING });
        // HINT-8: Fetch Referral Program Locations
        const payload = await fetchProviderBrowseResultsFromSearch(
          {
            ...params,
            includePathways,
            ...(hint716SearchNetworkHubSupportPremium && { type: 'all' }),
          },
          page,
          hint716SearchNetworkHubSupportPremium ? { 'filter[provider.type]': 'all' } : {},
        );
        networkGroups = payload.data.data;
        const composedPayload = {
          ...payload,
          data: {
            ...payload.data,
            data: serviceTypeUtils.composeGroupsList(networkGroups, serviceTypes),
          },
        };
        addAdditionalProviderAttributes(composedPayload.data.data);
        dispatch({
          type: SEARCH_NETWORK_BROWSE_GROUPS,
          payload: composedPayload,
          networkId,
          groupId,
          filters,
          list: 'location',
        });

        dispatch({ type: UNSET_NETWORK_BROWSE_GROUPS_FETCHING });
      }

      if (!type || type === 'serviceArea') {
        dispatch({ type: SET_NETWORK_BROWSE_GROUPS_FETCHING, list: 'serviceArea' });
        const { stateAbbreviation, stateDisplayName } = await getStateData({
          lat: get(locationsFilter, 'radius.latitude', '0'),
          lng: get(locationsFilter, 'radius.longitude', '0'),
        });
        delete params.locations;
        delete page.sort;
        params['programs.service_areas'] = { region: { state: stateAbbreviation } };
        // HINT-8: Fetch Referral Program Service Areas
        const payloadServiceAreas = await fetchProviderBrowseResultsFromSearch(
          {
            ...params,
            includePathways,
            ...(hint716SearchNetworkHubSupportPremium && { type: 'all' }),
          },
          page,
          hint716SearchNetworkHubSupportPremium ? { 'filter[provider.type]': 'all' } : {},
        );
        const dataServiceAreas = payloadServiceAreas.data.data;
        const composedPayloadServiceAreas = {
          ...payloadServiceAreas,
          data: {
            ...payloadServiceAreas.data,
            data: serviceTypeUtils.composeGroupsList(dataServiceAreas, serviceTypes),
          },
        };
        addAdditionalProviderAttributes(composedPayloadServiceAreas.data.data);
        dispatch({
          type: SEARCH_NETWORK_BROWSE_GROUPS,
          payload: composedPayloadServiceAreas,
          networkId,
          groupId,
          filters,
          list: 'serviceArea',
          stateDisplayName,
        });
        dispatch({ type: UNSET_NETWORK_BROWSE_GROUPS_FETCHING, list: 'serviceArea' });
      }

      return networkGroups;
    } catch (error) {
      return onError(error, dispatch);
    }
  }
);

export default searchNetworkBrowseGroups;
