import React from 'react';
import Select from 'react-select';
import './DropdownMenu.scss';

export default function DropdownMenu(props) {
  return (
    <div className="dropdown-menu">
      <Select
        controlShouldRenderValue={false}
        isSearchable={false}
        isClearable={false}
        components={{ DropdownIndicator: null }}
        classNamePrefix="dropdown-menu-element"
        {...props}
      />
    </div>
  );
}
