import {
  FETCH_GROUP_OUTCOMES,
  FETCH_NETWORK_OUTCOMES,
  SET_GROUP_OUTCOMES_FETCHING,
  SET_NETWORK_OUTCOMES_FETCHING,
} from 'actions';

export const defaultState = {
  isFetchingGroupOutcomes: false,
  isFetchingNetworkOutcomes: false,
};

export default function outcomesReducer(state = defaultState, action) {
  const { isFetching = false, payload } = action;

  switch (action.type) {
    case SET_NETWORK_OUTCOMES_FETCHING:
      return { ...state, isFetchingNetworkOutcomes: isFetching };

    case SET_GROUP_OUTCOMES_FETCHING:
      return { ...state, isFetchingGroupOutcomes: isFetching };

    case FETCH_GROUP_OUTCOMES:
      return {
        ...state,
        groupOutcomes: payload.data.data,
        isFetchingGroupOutcomes: isFetching,
      };

    case FETCH_NETWORK_OUTCOMES:
      return {
        ...state,
        networkOutcomes: payload.data.data,
        isFetchingNetworkOutcomes: isFetching,
      };

    default:
      return state;
  }
}
