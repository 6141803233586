import { FETCH_CONTACT_NOTIFICATION_PREFERENCES, UPDATE_CONTACT_NOTIFICATION_PREFERENCES_SUCCESS } from 'actions';

export const defaultState = {};

export default function (state = defaultState, action) {
  switch (action.type) {
    case FETCH_CONTACT_NOTIFICATION_PREFERENCES: {
      if (action.status === 'success') {
        return action.payload.data.data;
      }

      return state;
    }

    case UPDATE_CONTACT_NOTIFICATION_PREFERENCES_SUCCESS: {
      return { ...state, ...action.payload.data.data };
    }

    default:
      return state;
  }
}
