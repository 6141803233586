import Notifier from 'common/helpers/Notifier';
import {
  FETCH_AUTH_USERS_STARTED,
  FETCH_AUTH_USERS_SUCCESS,
  FETCH_AUTH_USERS_ERROR,
} from 'actions';
import { authApi } from 'src/api/config';


const onSuccess = (dispatch, payload) => {
  dispatch({
    type: FETCH_AUTH_USERS_SUCCESS,
    payload,
  });
};

const onError = (dispatch, error) => {
  dispatch({ type: FETCH_AUTH_USERS_ERROR });
  Notifier.handleErrors(error);
};

export default function fetchAuthUsers(ids) {
  return async (dispatch) => {
    try {
      if (!ids || !ids.length) { return []; }
      dispatch({ type: FETCH_AUTH_USERS_STARTED });
      const payload = await authApi.query('user', { ids: ids.map((i) => i.id) });
      return onSuccess(dispatch, payload);
    } catch (error) {
      return onError(dispatch, error);
    }
  };
}
