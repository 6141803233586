import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';

function AddDeleteButtons(props) {
  const {
    displayText, iconSize, onAddClick, onRemoveClick, onRemoveBlur, onAddBlur,
  } = props;
  return (
    <div>
      { onRemoveClick === undefined ? (
        <div className="ui-add-remove-buttons">
          <button
            className="button-flex"
            onClick={onAddClick}
            type="button"
            onBlur={onAddBlur}
          >
            <Icon icon="IconPlusCircle" size={iconSize} />
            <div className="payments-insurance-card-label">{displayText}</div>
          </button>
        </div>
      ) : (
        <div className={'ui-add-remove-buttons'}>
          <div className={'ui-add-remove-buttons__remove mr-quarter'}>
            <button
              className="button-flex"
              onClick={onRemoveClick}
              type="button"
              onBlur={onRemoveBlur}
            >
              <Icon icon="IconTrash" size={iconSize} />
              <div className="payments-insurance-card-label payments-insurance-card-label--remove">{displayText}</div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

AddDeleteButtons.propTypes = {
  displayText: PropTypes.string,
  iconSize: PropTypes.number,
  onAddClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  onAddBlur: PropTypes.func,
  onRemoveBlur: PropTypes.func,
};

AddDeleteButtons.defaultProps = {
  displayText: '',
  iconSize: 18,
  onAddClick: undefined,
  onRemoveClick: undefined,
  onAddBlur: undefined,
  onRemoveBlur: undefined,
};

export default AddDeleteButtons;
