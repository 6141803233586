import _ from 'lodash';

const DEFAULT_COUNTRY = 'United States of America';

export function areAddressFieldsEmpty(address) {
  return (
    (
      _.isEqual(address.address_type.value, '') ||
      _.isEqual(address.address_type.value, null) ||
      _.isEqual(address.address_type.value, [])
    ) &&
    (_.isEqual(address.line_1.value, '') || _.isEqual(address.line_1.value, null)) &&
    (_.isEqual(address.line_2.value, '') || _.isEqual(address.line_2.value, null)) &&
    (_.isEqual(address.city.value, '') || _.isEqual(address.city.value, null)) &&
    (_.isEqual(address.state.value, '') || _.isEqual(address.state.value, null)) &&
    (_.isEqual(address.postal_code.value, '') || _.isEqual(address.postal_code.value, null)) &&
    (!address.is_mailing_address || !address.is_mailing_address.value)
  );
}

export function massageAddressFields(values, addresses) {
  const addressFormFields = values.addresses;
  const result = _.reduce(addresses, (acc, curr, index) => {
    if (areAddressFieldsEmpty(curr)) {
      return [...acc];
    }
    return [...acc, { ...addressFormFields[index], country: DEFAULT_COUNTRY }];
  }, []);

  return { ...values, addresses: result };
}

export function massageIntakeAddressFields(values, addresses) {
  const addressFormFields = values.addresses;

  const result = _.reduce(addresses, (acc, curr, index) => {
    if (areAddressFieldsEmpty(curr)) {
      return [...acc];
    }
    return [...acc, { ...addressFormFields[index], country: DEFAULT_COUNTRY }];
  }, []);

  return result;
}
