import { NETWORK_CASE, ORG_CASE } from 'src/components/Cases/constants';

export default function caseTypeOptions(orgName, networkLicense) {
  if (networkLicense) {
    return [
      { type: ORG_CASE, name: `${orgName} Case` },
      { type: NETWORK_CASE, name: 'Network Case' },
    ];
  }
  return [{ type: ORG_CASE, name: `${orgName} Case` }];
}
