import axios from 'axios';
import Notifier from 'common/helpers/Notifier';
import getCoreError from 'src/api/utils/getCoreError';
import { AUTH_URL } from 'src/config/env/env.config';
import { isHttpSuccess } from 'src/api/utils/httpStatus';

const onSuccess = (response) => ({
  response,
  user: response.data.data,
});

const onError = (dispatch, error) => {
  Notifier.handleErrors(getCoreError(error));
  return error;
};

const makeRequestData = (attributes) => ({
  data: {
    attributes: {
      email: attributes.email,
    },
  },
});

export default function updateUser({
  userId,
  attributes,
}) {
  return async (dispatch) => {
    try {
      const requestData = makeRequestData(attributes);
      const response = await axios.put(`/v1/users/${userId}`, requestData, { baseURL: AUTH_URL });
      if (!response || !isHttpSuccess(response.status)) {
        return onError(dispatch, response);
      }
      return onSuccess(response);
    } catch (error) {
      return onError(dispatch, error);
    }
  };
}
