import Notifier from 'common/helpers/Notifier';
import { get } from 'lodash';
import { coreApi } from 'src/api/config';

const shareGroupsDetails = ({
  groupIds = [],
  messageType,
  to,
  language,
}) => async (_, getState) => {
  try {
    const currentEmployee = get(getState(), 'globalState.currentEmployee');
    const response = await coreApi.createRecord('communication', {
      medium: messageType,
      category: 'provider_share',
      to,
      sender: currentEmployee.id,
      details: {
        provider_ids: groupIds,
        language: language || 'en',
      },
    });

    Notifier.dispatch(response.status, 'Message successfully sent');

    return response;
  } catch (error) {
    Notifier.handleErrors(error);
    return error;
  }
};

export default shareGroupsDetails;
