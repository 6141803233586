import { fetchCoreServices } from 'src/actions/Group/Network';
import _ from 'lodash';

const isSon = (service) => service.relationships && service.relationships.parent !== undefined;

const isParent = (service, options) => options.find((e) => e.id === service.id);

const extractParents = (response) => response
  .filter(({ relationships }) => relationships.parent !== undefined)
  .map(({ relationships }) => relationships.parent.data.id);

const extractEmptyRelationships = (response) => response
  .filter(({ relationships }) => Object.keys(relationships).length === 0)
  .map(({ id }) => id);

const findMissingParents = (parents, parentsWithoutRelationships) => parents
  .filter((parent) => !parentsWithoutRelationships.includes(parent));

const mapToOptions = (parentsWithoutRelationships) => parentsWithoutRelationships.map((parent) => ({
  id: _.get(parent, 'id'),
  name: _.get(parent, 'attributes.name'),
  children: [],
}));

const createChildObject = (service, isSensitive) => ({
    id: _.get(service, 'id'),
    name: _.get(service, 'attributes.name'),
    disabled: isSensitive !== _.get(service, 'attributes.sensitive'),
});

async function getServiceTypeOptionList(servicesIdsList, referral) {
  const response = await fetchCoreServices(servicesIdsList);
  const isSensitive = referral.service_type.sensitive;

  const parents = extractParents(response);
  const parentsWithoutRelationships = extractEmptyRelationships(response);

  const missingParents = findMissingParents(parents, parentsWithoutRelationships);
  parentsWithoutRelationships.push(...missingParents);

  const options = await fetchCoreServices(parentsWithoutRelationships).then(mapToOptions);

  response.forEach((service) => {
      if (isSon(service)) {
          const parentId = service.relationships.parent.data.id;
          const item = options.find((e) => e.id === parentId);
          if (item) {
              item.children.push(createChildObject(service, isSensitive));
          }
      }
  });

  response.forEach((service) => {
      if (isParent(service, options)) {
          const parentId = service.id;
          const item = options.find((e) => e.id === parentId);
          if (item.children.length === 0) {
              item.children.push(createChildObject(service, isSensitive));
          }
      }
  });

  return options;
}

export default getServiceTypeOptionList;
