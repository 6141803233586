import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { browserHistory } from 'common/utils/browserHistory';
import {
  useFindFeeScheduleProgram,
  useUpdateFeeScheduleProgram,
} from 'src/components/Backoffice/api/hooks/v1/feeScheduleProgramHooks';
import {
  constructFeeScheduleProgramInitialFormValues,
  constructFeeScheduleProgramFormPayload,
} from 'src/pages/fee-schedules/_id/utils/index';
import { useUniteUsServices } from 'src/components/Backoffice/api/hooks/v1/serviceHooks';
import getServiceTypesWithParents from '../../utils/getServiceTypesWithParents';
import FeeScheduleProgramForm from '../components/FeeScheduleProgramForm';

const EditFeeScheduleProgram = ({ params }) => {
  const feeScheduleId = params.id;
  const feeScheduleProgramId = params.fee_schedule_program_id;
  const { updateRecord: updateFeeScheduleProgram } = useUpdateFeeScheduleProgram();
  const { status, data } = useFindFeeScheduleProgram(feeScheduleProgramId);
  const { data: parentServices } = useUniteUsServices();
  const initialValues = constructFeeScheduleProgramInitialFormValues({ ...data });
  const showPending = status === 'loading' && isEmpty(initialValues);

  const onSubmit = async (feeScheduleProgram) => {
    const payload = constructFeeScheduleProgramFormPayload(feeScheduleProgram, feeScheduleId);
    const servicesWithParents = getServiceTypesWithParents(feeScheduleProgram.services, Object.values(parentServices));
    payload.services = servicesWithParents;
    const response = await updateFeeScheduleProgram(feeScheduleProgramId, payload);
    if (response) {
      browserHistory.push({
        pathname: `/backoffice/fee_schedules/${feeScheduleId}/fee_schedule_programs/${feeScheduleProgramId}`,
      });
    }
  };

  return (
    <FeeScheduleProgramForm
      initialValues={initialValues} onSubmit={onSubmit} feeScheduleId={feeScheduleId}
      feeScheduleProgramId={feeScheduleProgramId} showPending={showPending}
    />
  );
};

EditFeeScheduleProgram.propTypes = {
  params: PropTypes.object.isRequired,
};

export default EditFeeScheduleProgram;
