import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { inputProps } from '@unite-us/client-utils';
import { validations } from '@unite-us/app-components';
import { InputField } from '@unite-us/ui';
import { validateReduxForm } from 'common/form';
import AddressField from 'common/form/AddressField/AddressField';
import { ProfilePhoneFields } from 'common/form/Profile';
import { OverlaySpinner } from 'common/spinners';
import { SHOW_MAILING_ADDRESS } from 'common/form/AddressField/constants';
import { updateCareTeamMember, createCareTeamMember } from '../../api';
import CareTeamFooter from '../components/CareTeamFooter';
import { generateOverlayText, sanitizeData } from '../utils';

export class CareTeamForm extends React.Component {
  /**
   * on + Add address click - will check to see if user addresses still exist.  Used to toggle display/hide
   * @params {object} member - careteam member information
   * @return {bool}
   */
  static addressClicked(member) {
    return member.addresses === undefined || member.addresses.length === 0;
  }

  constructor(props) {
    super(props);
    this.addAddress = this.addAddress.bind(this);
    this.addPhoneNumber = this.addPhoneNumber.bind(this);
    this.fieldsDirty = this.fieldsDirty.bind(this);
    this.handleFinalClose = this.handleFinalClose.bind(this);
    this.handleInitialClose = this.handleInitialClose.bind(this);
    this.onPrimaryChange = this.onPrimaryChange.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.removeAddress = this.removeAddress.bind(this);
    this.removePhoneNumber = this.removePhoneNumber.bind(this);

    this.state = {
      addressLength: 0,
      formCancelPressed: false,
      phoneNumberLength: 0,
      submitting: false,
    };
  }

  /**
   * On CWM - If member has an address and/or phone number will set State on mount
   * @return void
   */
  UNSAFE_componentWillMount() {
    const { member = {} } = this.props.initData;

    if (member.addresses) {
      this.setState({ addressLength: member.addresses.length });
    }

    if (member.phone_numbers) {
      this.setState({ phoneNumberLength: member.phone_numbers.length });
    }

    this.props.initializeForm(this.props.initData);
  }

  /**
   * On cwr - After initial mount, function will handle member initialization
   * @param { object } nextProps - incoming props
   */
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps.initData !== this.props.initData) {
  //     const { member } = nextProps.initData;
  //     this.setAddressAndPhoneState(member);
  //     this.props.initializeForm(nextProps.initData);
  //   }
  // }

  /**
   * Called when user clicks cancel and changes state to trigger confirmation footer
   * @return void
   */
  handleInitialClose() {
    if (this.fieldsDirty()) {
      this.setState({ formCancelPressed: !this.state.formCancelPressed });
    } else {
      this.removeAddressAndPhoneNumber();
      this.props.closeModal();
    }
  }

  /**
   * Called when user clicks YES or NO on the confirmation footer
   * @return void
   */
  handleFinalClose(status) {
    const { confirmClose } = status;

    if (confirmClose) {
      this.setState({ formCancelPressed: false });
      this.removeAddressAndPhoneNumber();
      this.props.closeModal();
    } else {
      this.setState({ formCancelPressed: false });
    }
  }

  onSubmitHandler(formData) {
    const related_person = sanitizeData(formData.member);
    const { editMode, invalidateQueries, personalRelationship } = this.props;
    this.setState({ submitting: true });
    const pr = { ...personalRelationship };
    pr.related_person = related_person;
    pr.relationship = related_person.relation_to_contact;
    const action = editMode ? updateCareTeamMember : createCareTeamMember;
    action(pr).then(() => {
      invalidateQueries('personal_relationship');
      invalidateQueries('person');
      this.props.closeModal();
      this.setState({ formCancelPressed: false, submitting: false });
    });
  }

  onPrimaryChange(phone) {
    this.props.fields.member.phone_numbers.forEach((field) => {
      if (field !== phone) {
        field.is_primary.onChange(false);
      }
    });
    phone.is_primary.onChange(true);
  }

  /**
   * Called by componentWillReceieveProps
   * If member has an address and/or phone number will set State OR set back to zero
   * @param { object } member - current care team member
   * @return void
   */
  setAddressAndPhoneState(member) {
    if (member.addresses) {
      this.setState({ addressLength: member.addresses.length });
    } else {
      this.setState({ addressLength: 0 });
    }

    if (member.phone_numbers) {
      this.setState({ phoneNumberLength: member.phone_numbers.length });
    } else {
      this.setState({ phoneNumberLength: 0 });
    }
  }

  removeAddress(index) {
    this.props.fields.member.addresses.removeField(index);
  }

  removePhoneNumber(index) {
    this.props.fields.member.phone_numbers.removeField(index);
  }

  /**
   * Removes ALL phone number and address fields
   * @return void
   */
  removeAddressAndPhoneNumber() {
    const {
      fields: { member },
    } = this.props;

    if (member.phone_numbers) {
      for (let i = 0; i < member.phone_numbers.length; i++) {
        this.removePhoneNumber(0);
      }
    }

    if (member.addresses) {
      this.removeAddress(0);
    }
  }

  addPhoneNumber() {
    this.props.fields.member.phone_numbers.addField({
      country_code: '1',
    });
    setTimeout(() => window.dispatchEvent(new Event('resize')), 10);
    const { member } = this.props;
    this.setAddressAndPhoneState(member.initData);
  }

  addAddress() {
    this.props.fields.member.addresses.addField();
    setTimeout(() => window.dispatchEvent(new Event('resize')), 10);
    const { member } = this.props;
    this.setAddressAndPhoneState(member.initData);
  }

  /**
   * Confirms if form is dirty based on inputs AND field addition/removal
   * @return { boolean }
   */
  fieldsDirty() {
    const {
      fields: { member },
    } = this.props;
    return this.props.dirty ||
      member.addresses.length !== this.state.addressLength ||
      member.phone_numbers.length !== this.state.phoneNumberLength;
  }

  careTeamFooterProps() {
    const { editMode } = this.props;
    const { formCancelPressed, submitting } = this.state;

    return {
      editMode,
      fieldsDirty: this.fieldsDirty,
      formCancelPressed,
      handleFinalClose: this.handleFinalClose,
      handleInitialClose: this.handleInitialClose,
      submitting,
    };
  }

  render() {
    const {
      editMode,
      fields: { member = {} },
      handleSubmit,
      registerField,
    } = this.props;

    const { submitting } = this.state;

    const submitText = generateOverlayText(editMode);

    return (
      <form onSubmit={handleSubmit(this.onSubmitHandler)} className="care-team-modal-form content-with-actions">
        <div className="content-container">
          <OverlaySpinner text={submitText} show={submitting} />
          <input
            id="member-id"
            label="id"
            type="hidden"
            {...inputProps(get(member, 'id'))}
          />

          <div className="row">
            <div className="col-sm-12 care-team-modal-form__first-name">
              <InputField
                field={member.first_name}
                id="first-name"
                inline={false}
                label="First Name"
                ref={registerField}
                required
                validations={validations.isRequired}
              />
            </div>

            <div className="col-sm-12 care-team-modal-form__last-name">
              <InputField
                field={member.last_name}
                id="last-name"
                inline={false}
                label="Last Name"
                ref={registerField}
                required
                validations={validations.isRequired}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 care-team-modal-form__relation">
              <InputField
                field={member.relation_to_contact}
                id="relation"
                inline={false}
                label="Relation To Contact"
                ref={registerField}
                required
                validations={validations.isRequired}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 care-team-modal-form__email">
              <InputField
                field={get(member, 'base_email_address.email_address', {})}
                id="email-address"
                inline={false}
                label="Email"
                ref={registerField}
                type="email"
                validations={validations.isEmail}
              />
            </div>

            <div className="col-sm-12 care-team-modal-form__numbers">
              {get(member, 'phone_numbers', []).map(
                (phoneNumber, index) => (
                  <div
                    id={`care-team-phone-${index}`}
                    key={index}
                  >
                    <ProfilePhoneFields
                      fields={phoneNumber}
                      hideCommunicationPreferences
                      key={index}
                      onPrimaryChange={this.onPrimaryChange}
                      onRemove={() => this.removePhoneNumber(index)}
                      registerField={registerField}
                    />
                  </div>
                ),
              )}

              <div className="form-group">
                <button
                  onClick={this.addPhoneNumber}
                  type="button"
                  className="text-action-blue"
                >
                  {get(member, 'phone_numbers', []).length > 0 ? '+ Add another phone' : '+ Add Phone'}
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <input
              id="address-id"
              label="id"
              type="hidden"
              {...inputProps(get(member, 'addresses.id'))}
            />

            <div className="col-sm-12 care-team-modal-form__addresses">
              {get(member, 'addresses', []).map(
                (address, index) => (
                  <div
                    id={`address-${index}`}
                    key={index}
                  >
                    <AddressField
                      addressPath="member.addresses"
                      field={address}
                      id={`address-${index}`}
                      index={index}
                      inline={false}
                      registerField={registerField}
                      remove={() => this.removeAddress(index)}
                      displayFieldSet={SHOW_MAILING_ADDRESS}
                    />
                  </div>
                ),
              )}

              <div className={CareTeamForm.addressClicked(member) ? 'form-group' : 'form-group--disabled'}>
                <button
                  onClick={this.addAddress}
                  type="button"
                  className="text-action-blue"
                >
                  + Add Address
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="care-team-modal__footer actions">
          <CareTeamFooter {...this.careTeamFooterProps()} />
        </div>
      </form>
    );
  }
}

CareTeamForm.propTypes = {
  closeModal: PropTypes.func,
  invalidateQueries: PropTypes.func,
  currentMember: PropTypes.object.isRequired,
  dirty: PropTypes.bool.isRequired,
  editMode: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initData: PropTypes.object.isRequired,
  initializeForm: PropTypes.func.isRequired,
  member: PropTypes.object,
  registerField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  personalRelationship: PropTypes.object,
};

CareTeamForm.defaultProps = {
  closeModal: () => { },
  invalidateQueries: () => { },
  editMode: false,
  personalRelationship: {},
};

function mapStateToProps(state) {
  return {
    currentMember: state.form.careTeamMember,
  };
}

const fields = [
  'member.addresses[].address_type',
  'member.addresses[].city',
  'member.addresses[].country',
  'member.addresses[].id',
  'member.addresses[].is_mailing_address',
  'member.addresses[].line_1',
  'member.addresses[].line_2',
  'member.addresses[].postal_code',
  'member.addresses[].state',
  'member.base_email_address.email_address',
  'member.base_email_address.id',
  'member.email_addresses[].email_address',
  'member.first_name',
  'member.id',
  'member.last_name',
  'member.phone_numbers[].country_code',
  'member.phone_numbers[].id',
  'member.phone_numbers[].is_primary',
  'member.phone_numbers[].phone_number',
  'member.phone_numbers[].phone_type',
  'member.relation_to_contact',
  'member.personal_relationship',
];

export default validateReduxForm(
  {
    form: 'careTeamMember',
    fields,
  },
  mapStateToProps,
)(CareTeamForm);
