import { useFind } from 'src/api/APIHooks';

export default (model, id) => (
  useFind(
    'version',
    {
      item: id,
      'item.type': model,
    },
    {
      queryConfig: {
        enabled: !!id,
      },
    },
  )
);
