import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, flow } from 'lodash';
import { connect } from 'react-redux';
import { browserHistory } from 'src/common/utils/browserHistory';
import FeatureFlagContainer from 'src/common/utils/FeatureFlags/FeatureFlagContainer';
import { hasPayerEnrollmentsAccess } from 'src/common/utils/FeatureFlags/flags';
import { CurrentProviderContextProvider, useIsPayer } from 'common/contexts/CurrentProviderContext';
import { StatusSelectBar } from './components/StatusSelectBar';

const ContainerWithCurrentProviderContext = ({ children, showEnrollments }) => {
  const isPayer = useIsPayer();
  useEffect(() => {
    if (!isPayer || !showEnrollments) {
      browserHistory.push('/');
    }
  }, [showEnrollments, isPayer, browserHistory]);
  return showEnrollments && isPayer && (
    <div className="flex antialiased min-h-full -mx-container-padding">
      <StatusSelectBar />
      <div className="mb-20 break-normal flex-grow">
        {children}
      </div>
    </div>
  );
};

ContainerWithCurrentProviderContext.propTypes = {
  children: PropTypes.node.isRequired,
  showEnrollments: PropTypes.bool.isRequired,
};

const EnrollmentsContainer = ({
  children,
  providerId,
  showEnrollments,
}) => (
  <CurrentProviderContextProvider providerId={providerId}>
    <ContainerWithCurrentProviderContext showEnrollments={showEnrollments}>
      {children}
    </ContainerWithCurrentProviderContext>
  </CurrentProviderContextProvider>
);

EnrollmentsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  providerId: PropTypes.string,
  showEnrollments: PropTypes.bool,
};

EnrollmentsContainer.defaultProps = {
  providerId: '',
  showEnrollments: false,
};

function mapStateToProps(state) {
  return {
    providerId: get(state, 'session.groupId', ''),
    showEnrollments: hasPayerEnrollmentsAccess(state),
  };
}

export default flow(
  FeatureFlagContainer,
  connect(mapStateToProps),
)(EnrollmentsContainer);
