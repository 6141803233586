import Notifier from 'common/helpers/Notifier';
import { ADD_NOTE_INTERACTION } from 'actions';
import { coreApi } from 'src/api/config';
import { get } from 'lodash';
import { getMessageEnabled } from 'src/common/form/Interactions/Message/utils';

const getMedium = (emails, phones, value) => {
  if (phones.find((p) => p.phone_number === value)) {
    return 'sms';
  }
  if (emails.find((e) => e.email_address === value)) {
    return 'email';
  }
  return null;
};

function createContactCommunication({
  contact,
  message,
  to,
}) {
  return async (dispatch, getState) => {
    try {
      const emails = getMessageEnabled(contact.email_addresses);
      const phones = getMessageEnabled(contact.phone_numbers);
      const medium = getMedium(emails, phones, to);

      const message_subject = 'Message';
      const currentEmployee = get(getState(), 'globalState.currentEmployee');

      await coreApi.createRecord('communication', {
        medium,
        category: 'direct_message',
        to,
        person: contact.id,
        sender: currentEmployee.id,
        details: {
          message_subject,
          message_body: message,
        },
      });

      dispatch({ type: ADD_NOTE_INTERACTION });

      Notifier.dispatch(200, 'Message Sent');
      return true;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}

export default createContactCommunication;
