import { find, get } from 'lodash';

export function isDefaultProgramSelected(groupsPrograms, fields, defaultProgram) {
  let defaultProgramSelected;

  if (!fields.program.value.id) {
    defaultProgramSelected = get(defaultProgram, 'attributes.default', false);
  } else {
    const selectedProgram = find(
      get(groupsPrograms, 'data', []),
      (program) => program.id === get(fields, 'program.value.id'),
    );

    defaultProgramSelected = get(selectedProgram, 'attributes.default', false);
  }

  return defaultProgramSelected;
}
