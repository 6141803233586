import _ from 'lodash';

function getMilitaryEnumDisplayName(value, enums, key) {
  return _.get(
    _.find(_.wget(enums, `contact_data.military.${key}`), { value }),
    'display_name',
  );
}

export default getMilitaryEnumDisplayName;
