import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { TextField } from '@unite-us/ui';
import { validations } from '@unite-us/app-components';
import { MessageFieldLabel } from '.';
import { MESSAGE_CHAR_LIMIT, MESSAGE_FIELD_HINT } from '../constants';
import '../stylesheets/messageField.scss';

const MessageField = (props) => (
  <div className="message-field">
    <MessageFieldLabel
      popoverId={`${props.id}-popover`}
      required={props.required}
    />
    <TextField
      className="message-field__label"
      ref={props.registerField}
      {...props}
    />
  </div>
);

MessageField.propTypes = {
  field: PropTypes.object.isRequired,
  hideLabel: PropTypes.bool,
  hint: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  registerField: PropTypes.func,
  required: PropTypes.bool,
  validations: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func,
  ]),
};

MessageField.defaultProps = {
  label: 'Message',
  hideLabel: true,
  hint: MESSAGE_FIELD_HINT,
  placeholder: 'Enter your message here...',
  registerField: noop,
  required: true,
  validations: [
    { func: validations.isRequired },
    { func: validations.isShorterThan, args: MESSAGE_CHAR_LIMIT },
  ],
};

export default MessageField;
