import _ from 'lodash';

function pickColors(colors, number) {
  let palette = colors;
  if (number > colors.length) {
    for (let i = 0; i < Math.floor(number / colors.length); i++) {
      palette = _.concat(palette, colors);
    }
  }
  if (number <= 1) {
    return [palette[0]];
  }
  let repartition = 1;
  if (number < palette.length) {
    repartition = palette.length / (number - 1);
  }
  const result = _.times(number, (i) => {
    if (i === number - 1) {
      return _.last(palette);
    }
    return palette[Math.floor(i * repartition)];
  });
  return result;
}

export default pickColors;
