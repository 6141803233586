import PropTypes from 'prop-types';
import React from 'react';
import { epochToDate } from 'common/utils/utils';
import Address from 'common/display/Address';
import PhoneNumber from 'common/display/PhoneNumberDisplay';
import SimpleList from 'common/display/SimpleList';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Button,
} from '@unite-us/ui';

const AssistanceRequestContactCard = ({
  contact,
  onSelectContact,
  }) => {
  const selectClientMatch = () => {
    const personId = contact.external ? null : contact.id;
    onSelectContact(personId, contact.person || contact);
  };

  return (
    <li className="my-3 mx-0 self-start w-1/3 grow-0 shrink-0 basis-1/3 float-none">
      <BaseCard>
        <BaseCardHeader title={contact.full_name} />
        <BaseCardBody withPadding>
          <dl>
            <dt>Date of Birth</dt>
            <dd>{epochToDate(contact.date_of_birth)}</dd>
          </dl>
          <dl>
            <dt>Phone</dt>
            <dd>
              <SimpleList list={contact.phone_numbers} ListElement={PhoneNumber} />
            </dd>
          </dl>
          <dl>
            <dt>Address</dt>
            <dd>
              <SimpleList list={contact.addresses} ListElement={Address} shortDisplay />
            </dd>
          </dl>
          <Button
            id={`${contact.id}-use-btn`}
            onClick={selectClientMatch}
            label="Use This Record"
            primary
            style={{ display: 'block', margin: '0 auto' }}
          />
        </BaseCardBody>
      </BaseCard>
    </li>
  );
};

AssistanceRequestContactCard.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.string.isRequired,
    full_name: PropTypes.string,
    date_of_birth: PropTypes.number,
    phone_numbers: PropTypes.array,
    addresses: PropTypes.array,
    person: PropTypes.shape({
      id: PropTypes.string,
    }),
    external: PropTypes.bool,
  }).isRequired,
  onSelectContact: PropTypes.func.isRequired,
};

export default AssistanceRequestContactCard;
