import { RETURNED_ASSESSMENT } from '..';

function returnedAssessment(name) {
  return {
    type: RETURNED_ASSESSMENT,
    payload: { name },
  };
}

export default returnedAssessment;
