import React from 'react';

export default function ServerReconnect() {
  return (
    <div id="serverReconnect">
      <div className="message">
        <div className="strong-text">Reconnected to server &nbsp;&#58;&#41;</div>
      </div>
    </div>
  );
}
