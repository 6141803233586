import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { Combobox } from '@headlessui/react';
import { Icon } from '@unite-us/ui';
import ReduxFormControlWrapper from './ReduxFormControlWrapper';

export const SearchableField = ({
  className,
  clearable,
  label,
  listBoxUpOptionsClassName,
  onChange,
  options,
  placeholder,
  required,
  truncateOptions,
  hideLabel,
  onInputChange,
  searchQuery,
}) => {
  const selectedOption = options.find((option) => option.value === searchQuery) || null;
  const onClearClick = (event) => {
    event.stopPropagation();
    onChange('');
  };

  return (
    <Combobox value={selectedOption} onChange={onChange}>
      {({ open }) => (
        <div className={classNames(className, 'antialiased')}>
          <Combobox.Label className={classNames(
            'block mb-1 leading-snug',
            'font-extrabold font-medium-font normal-case',
            'text-13px text-text-blue',
            hideLabel && 'hidden',
            required && 'ui-form-field__label--required',
          )}
          >
            {label}
          </Combobox.Label>
          <div className="relative">
            <div className={classNames(
              'relative box-border w-full bg-white cursor-default text-left',
              'pl-3 py-2',
              'focus:outline-none border border-solid border-dark-border-blue rounded-md shadow-sm',
              'border-dark-border-blue hover:border-action-blue focus:border-action-blue focus:shadow-input-ring',
              open && 'border-action-blue shadow-input-ring',
              clearable && selectedOption ? 'pr-12' : 'pr-10',
            )}
            >
              <div className="absolute inset-y-0 flex items-center">
                <Icon
                  aria-hidden
                  className="text-gray"
                  icon="IconSearch"
                  size={16}
                />
              </div>
              <Combobox.Input
                className="h-5 w-full pl-6 leading-5 focus:outline-none"
                displayValue={(option) => (option ? option.label : searchQuery)}
                onChange={(e) => onInputChange(e.target.value)}
                placeholder={placeholder}
                value={searchQuery}
              />
              {
                  clearable && !isEmpty(searchQuery) && (
                  <span
                    className="absolute opacity-50 inset-y-0 flex right-4 items-center"
                  >
                    <Icon
                      className="text-gray"
                      icon="IconCross"
                      size={10}
                      onClick={onClearClick}
                      ariaLabel={`Clear ${label}`}
                    />
                  </span>
                )
              }
            </div>
            <Combobox.Options
              className={classNames(
                'absolute z-dropdown box-content pl-0 py-1 z-10 mt-1 w-full max-h-80 overflow-auto',
                'bg-white shadow-lg rounded-md text-base focus:outline-none',
                listBoxUpOptionsClassName,
              )}
            >
              {options.map((option) => (
                <Combobox.Option
                  className={({ active }) => classNames(
                    'relative cursor-pointer py-2 pl-3 pr-9 select-none',
                    active && 'bg-action-blue',
                  )}
                  key={option.value}
                  value={option}
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          'block leading-4',
                          truncateOptions && 'truncate',
                          active ? 'text-white' : 'text-text-blue',
                          selected ? 'font-semibold mr-8' : 'font-normal',
                        )}
                      >
                        {option.label}
                      </span>
                      {selected && (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                          <Icon
                            aria-hidden
                            className={classNames(
                              'h-5 w-5 fill-current',
                              active ? 'text-white' : 'text-action-blue',
                            )}
                            icon="IconCheck"
                          />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </div>
        </div>
      )}
    </Combobox>
  );
};

SearchableField.propTypes = {
  className: PropTypes.string,
  clearable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.array,
        PropTypes.number,
      ]).isRequired,
    }),
  ),
  label: PropTypes.node,
  listBoxUpOptionsClassName: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  searchQuery: PropTypes.string,
  truncateOptions: PropTypes.bool,
  hideLabel: PropTypes.bool,
};

SearchableField.defaultProps = {
  className: '',
  clearable: false,
  label: '',
  listBoxUpOptionsClassName: '',
  options: [],
  placeholder: '',
  required: false,
  searchQuery: '',
  truncateOptions: true,
  hideLabel: false,
};

export default ReduxFormControlWrapper(SearchableField);
