import getAddressString from './getAddressString';

const getGoogleAddressDetails = async (address) => {
  const geoCoder = new window.google.maps.Geocoder();

  const addressString = getAddressString(address);

  return new Promise((resolve, reject) => {
    geoCoder.geocode({ address: addressString }, (results, status) => {
      if (status === 'OK') {
        const {
          formatted_address,
          geometry: {
            location: { lat, lng },
          },
          place_id,
        } = results[0];
        const formattedResult = {
          latLng: {
            lat: lat(),
            lng: lng(),
          },
          address: formatted_address,
          id: place_id,
        };

        resolve(formattedResult);
      } else {
        reject(status);
      }
    });
  });
};

export default getGoogleAddressDetails;
