export function tableWidth(input) {
  switch (input) {
    case 'icon':
      return '1%';
    case 'small':
      return '12%';
    case 'medium':
      return '15%';
    case 'large':
      return '18%';
    case 'quarter':
      return '25%';
    case 'half':
      return '50%';
    default:
      return '15%';
  }
}
