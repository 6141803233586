import React from 'react';
import PropTypes from 'prop-types';
import {
  useFindFeeScheduleProgram,
} from 'src/components/Backoffice/api/hooks/v1/feeScheduleProgramHooks';
import { BackButton } from 'common/buttons';
import {
  useFindFeeSchedule,
} from 'src/components/Backoffice/api/hooks/v1/feeScheduleHooks';
import { isEmpty } from 'lodash';
import Breadcrumbs from 'common/Breadcrumbs';
import { browserHistory } from 'common/utils/browserHistory';
import FeeScheduleProgramSubHeader from './FeeScheduleProgramSubHeader';
import FeeScheduleProgramOverview from './FeeScheduleProgramOverview';
import FeeScheduleProgramCboTable from './FeeScheduleProgramCboTable';
import FeeScheduleProgramCustomField from './FeeScheduleProgramCustomField';

const FeeScheduleProgram = ({ params }) => {
  const feeScheduleId = params.id;
  const feeScheduleProgramId = params.fee_schedule_program_id;
  const { data: feeSchedule } = useFindFeeSchedule(feeScheduleId);
  const { data: feeScheduleProgram } = useFindFeeScheduleProgram(feeScheduleProgramId);

  const feeScheduleLabel = isEmpty(feeSchedule.name) ? 'Loading...' : feeSchedule.name;
  const feeScheduleProgramLabel = isEmpty(feeScheduleProgram.name) ? 'Loading Program...' :
    feeScheduleProgram.name;

  const crumbs = [
    { label: 'Fee Schedules', href: '/backoffice/fee_schedules' },
    { label: feeScheduleLabel, href: `/backoffice/fee_schedules/${feeSchedule.id}` },
    { label: feeScheduleProgramLabel },
  ];

  const BackToFeeSchedulePrograms = () => {
    browserHistory.push({
      pathname: `/backoffice/fee_schedules/${feeScheduleId}`,
    });
  };

  const childTabComponent = () => {
    const tabUrl = window.location.pathname.split('/').pop();
    switch (tabUrl) {
      case 'cbo-table':
        return <FeeScheduleProgramCboTable feeScheduleProgramId={feeScheduleProgramId} />;
      case 'custom-fields':
        return <FeeScheduleProgramCustomField feeScheduleProgram={feeScheduleProgram} />;
      default:
        return <FeeScheduleProgramOverview feeScheduleProgram={feeScheduleProgram} />;
    }
  };

  return (
    <>
      <div className="detail-view">
        <div className="flex p-4 justify-start items-center bg-white border-b border-solid border-dark-border-blue">
          <div>
            <BackButton navigateTo={BackToFeeSchedulePrograms} />
          </div>
          <div className="px-5">
            <Breadcrumbs className="bread-crumbs" crumbs={crumbs} />
          </div>
        </div>
        <div className="bg-white border-b border-solid border-dark-border-blue mb-0">
          <FeeScheduleProgramSubHeader feeScheduleProgram={feeScheduleProgram} feeSchedule={feeSchedule} />
        </div>
        {
          childTabComponent()
        }
      </div>
    </>
  );
};

FeeScheduleProgram.propTypes = {
  params: PropTypes.object.isRequired,
};

export default FeeScheduleProgram;
