import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * There is a global reset of the div elements that setting line-height.
 * Use this component to reset the global reset to 1.25rem (currently 20px).
 */

const DivLineHeightReset = ({ children, className, ...props }) => (
  <div className={classNames('leading-5', className)} {...props}>
    {children}
  </div>
);

DivLineHeightReset.defaultProps = {
  className: '',
  // We have at least one instance of dangerouslySetInnerHTML.
  children: null,
};

DivLineHeightReset.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  className: PropTypes.string,
};

export default DivLineHeightReset;
