import {
  REFERRAL_STATUS_CLOSED,
  REFERRAL_STATUS_DECLINED,
  REFERRAL_STATUS_DRAFT,
  REFERRAL_STATUS_FORWARDED,
  REFERRAL_STATUS_IN_REVIEW,
  REFERRAL_STATUS_PENDING,
  REFERRAL_STATUS_PENDING_CONSENT,
  REFERRAL_STATUS_PROVIDER_TO_PROVIDER,
  REFERRAL_STATUS_RECALLED,
} from 'src/components/Referrals/constants';
import { isReferralSent, isProviderToProviderReferral } from 'src/components/Referrals/utils';
import { appendPath } from 'common/utils/Navigation';

function appendSentSubRoute(subRoute, id) {
  return appendPath(subRoute || 'all', id);
}

function referralRoute(referral = {}, groupId = '', isCC = false) {
  const { id = '', status, subRoute = '' } = referral;

  if (isProviderToProviderReferral({ groupId, isCC, referral })) {
    return `/dashboard/all-network/referrals/${id}`;
  }
  if (subRoute === 'closedReferrals') {
    return `/dashboard/referrals/sent/closed/${id}`;
  }
  if (subRoute === 'closedInboundReferrals') {
    return `/dashboard/new/closed/${id}`;
  }
  if (isReferralSent(referral, groupId) && subRoute !== '') {
    return `/dashboard/referrals/sent/${appendSentSubRoute(subRoute, id)}`;
  }

  switch (status) {
    case REFERRAL_STATUS_CLOSED:
      return `/dashboard/referrals/closed/${id}`;

    case REFERRAL_STATUS_DECLINED:
      return `/dashboard/referrals/sent/rejected/${id}`;

    case REFERRAL_STATUS_DRAFT:
      return `/dashboard/referrals/sent/drafts/${id}`;

    case REFERRAL_STATUS_IN_REVIEW:
      return `/dashboard/new/in-review/${id}`;

    case REFERRAL_STATUS_RECALLED:
      return `/dashboard/referrals/sent/recalled/${id}`;

    case REFERRAL_STATUS_PENDING_CONSENT:
      return `/dashboard/sent/pending-consent/${id}`;

    case REFERRAL_STATUS_PROVIDER_TO_PROVIDER:
      return `/dashboard/all-network/referrals/${id}`;

    case REFERRAL_STATUS_PENDING:
      if (subRoute === '' && id && referral.referred_by_group.id !== groupId) {
        return `/dashboard/new/referrals/${id}`;
      }
      if (subRoute === '' && id && referral.referred_by_group.id === groupId) {
        return `/dashboard/referrals/sent/all/${id}`;
      }
      return `/dashboard/referrals/sent/${appendSentSubRoute(subRoute, id)}`;

    case REFERRAL_STATUS_FORWARDED:
      return `/dashboard/referrals/sent/all/${id}`;

    default:
      return `/dashboard/new/referrals/${id}`;
  }
}

export default referralRoute;
