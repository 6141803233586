import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'common/utils/browserHistory';
import {
  tracker,
} from '@unite-us/client-utils';
import IndexDetailView from 'src/components/Dashboard/components/IndexDetailView';
import AllClientsIndex from 'src/components/Dashboard/components/Clients/AllClientsIndex';
import { fetchDashboardContacts } from 'actions/Contact/Group';
import _ from 'lodash';
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';
import { careCoordinatorFilterDefault, noneAssignedOption } from 'src/components/Dashboard/utils/filterDefaults';
import { getDataOfPage, isDataValid } from 'src/components/Dashboard/utils/dataHelpers';
import callOrLog from 'src/common/utils/callOrLog';
import { DASHBOARD_VIEWS, DASHBOARD_EVENTS } from 'common/utils/EventTracker/utils/eventConstants';
import { isSuperNetwork } from 'src/components/Network/utils';
import subNetworksFilter from 'src/components/Dashboard/utils/subNetworksFilter';
import { getGroupUsersOptions } from 'src/components/Groups/Users/utils';
import { careCoordinatorsFilter, clientsRequiringActionFilter } from 'src/components/Dashboard/utils/filter';
import labelCustomization from 'src/common/utils/Customization/labels';
import defaultLabels from 'src/constants/labels';

export class AllClients extends Component {
  constructor(props) {
    super(props);
    this.onCellClick = this.onCellClick.bind(this);
    this.onFiltersChange = this.onFiltersChange.bind(this);
    this.onLetterClick = this.onLetterClick.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.shouldFetch = this.shouldFetch.bind(this);
    this.fetch = this.fetch.bind(this);
    this.fetchGroupsUsers = this.fetchGroupsUsers.bind(this);
    this.debouncedFetchDashboardContacts = _.debounce(this.debouncedFetchDashboardContacts.bind(this));
    this.state = {
      activeUsersInGroup: [],
      page: 1,
      sidx: 'last_name,first_name',
      sord: 'last_name',
      filters: {
        care_coordinator_users: _.get(
          props,
          'filters.care_coordinator_users',
          careCoordinatorFilterDefault(props.userId, props.isCaseManager),
        ),
        flagged: _.get(props, 'filters.flagged', 'none'),
        last_name_starts_with_letter: null,
        networks: _.get(props, 'filters.networks', []),
      },
    };
  }

  componentDidMount() {
    this.fetch();
    this.props.fetchProvidersUserCore({
      providers: this.props.groupId,
    })
      .then((response) => {
        if (response) {
          const { employees: activeUsersInGroup } = response;
          this.setState({ activeUsersInGroup });
        }
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.get(this.props, 'clients.currentPage', 1) !== _.get(nextProps, 'clients.currentPage', 1)) {
      this.setState({ page: nextProps.clients.currentPage });
    }

    if (!this.props.refetch && nextProps.refetch) {
      this.fetch();
    }
  }

  onCellClick(row) {
    const pagedData = getDataOfPage(this.props.clients, this.state.page);
    const client = pagedData.data[row];

    callOrLog(() => this.context.eventTracker(DASHBOARD_EVENTS.clientsRow, {
      view: DASHBOARD_VIEWS.clients,
      contact_id: _.get(client, 'id'),
    }));

    browserHistory.push(`/facesheet/${client.id}`);
  }

  onFiltersChange(key, activeFilters, callbacks) {
    let newFilters = activeFilters;
    if (key === 'care_coordinator_users') {
      newFilters = careCoordinatorsFilter(activeFilters, callbacks);
    }

    this.setState({
      page: 1,
      filters: { ...this.state.filters, [key]: newFilters },
    }, () => {
      callOrLog(() => {
        const filters = tracker.buildDashboardFilterPayload(this.state, this.props);
        this.context.eventTracker(DASHBOARD_EVENTS.clientsFilter, filters);
      });
      this.fetch();
    });
  }

  onLetterClick(letter) {
    const newFilters = _.isEqual(letter, 'ALL') ?
      _.uuOmit(this.state.filters, 'last_name_starts_with_letter') :
      { ...this.state.filters, last_name_starts_with_letter: letter };

    this.setState({
      filters: newFilters,
    }, () => {
      callOrLog(() => this.context.eventTracker(DASHBOARD_EVENTS.letterFilter, {
        view: DASHBOARD_VIEWS.clients,
        filters: newFilters,
      }));

      this.fetch();
    });
  }

  onNextClick() {
    const page = this.state.page + 1;

    this.shouldFetch(page);
  }

  onPrevClick() {
    const page = this.state.page - 1;

    this.shouldFetch(page);
  }

  shouldFetch(page) {
    if (isDataValid(this.props.clients, page)) {
      this.setState({ page });
    } else {
      this.fetch(page);
    }
  }

  fetch(page = 1) {
    this.debouncedFetchDashboardContacts(page);
  }

  debouncedFetchDashboardContacts(page) {
    const { groupId, networkId, isCC } = this.props;
    this.props.fetchDashboardContacts(
      groupId,
      {
        target: 'clients',
        sidx: this.state.sidx,
        sord: this.state.sord,
        filters: this.state.filters,
        page,
      },
      networkId,
      isCC,
    );
  }

  fetchGroupsUsers(search = '') {
    const { groupId } = this.props;
    return this.props.fetchProvidersUserCore({
      providers: groupId,
      options: {
        text: search,
        licensed: false,
      },
    })
      .then((response) => {
        if (response) {
          const { employees: activeUsersInGroup } = response;
          if (search === '') {
            return _.concat(
              [noneAssignedOption(this.state)],
              getGroupUsersOptions(activeUsersInGroup, this.state),
            );
          }

          return getGroupUsersOptions(activeUsersInGroup, this.state);
        }

        return [];
      });
  }

  render() {
    const {
      clients,
      isFetching,
      isCC,
      params,
      currentGroup,
      groupId,
      labels,
    } = this.props;
    const { activeUsersInGroup } = this.state;

    const filters = _.compact([
      clientsRequiringActionFilter(this.state.filters.flagged),
      {
        key: 'care_coordinator_users',
        name: labels.CareCoordinator,
        pluralName: `${labels.CareCoordinator}s`,
        options: _.concat(
          [noneAssignedOption(this.state)],
          getGroupUsersOptions(activeUsersInGroup, this.state),
        ),
      },
      subNetworksFilter(currentGroup, this.state.filters.networks),
    ]);

    const clientsDetailView = (<div>detail</div>);
    const pagedData = getDataOfPage(clients, this.state.page);

    return (
      <IndexDetailView
        params={params}
        DetailView={clientsDetailView}
        IndexView={AllClientsIndex}
        data={pagedData.data}
        paging={pagedData.paging}
        isFetching={isFetching}
        onNextClick={this.onNextClick}
        onPrevClick={this.onPrevClick}
        onCellClick={this.onCellClick}
        initialSort={{ [this.state.sidx]: this.state.sord }}
        isCC={isCC}
        isSuperNetwork={isSuperNetwork(currentGroup.networks, groupId)}
        onFiltersChange={this.onFiltersChange}
        filters={filters}
        onLetterClick={this.onLetterClick}
        fetchGroupsUsers={this.fetchGroupsUsers}
      />
    );
  }
}

AllClients.propTypes = {
  params: PropTypes.object.isRequired,
  fetchDashboardContacts: PropTypes.func.isRequired,
  clients: PropTypes.shape({
    data: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  networkId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isCC: PropTypes.bool.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  isCaseManager: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  refetch: PropTypes.bool.isRequired,
  currentGroup: PropTypes.object.isRequired,
  labels: PropTypes.object,
};

AllClients.defaultProps = {
  labels: defaultLabels,
};

function mapStateToProps(state) {
  const groupId = _.get(state, 'session.groupId');
  const networkId = _.get(state, 'session.networkId');
  const userGroups = _.get(state, 'groups.data', []);
  const currentGroup = _.find(userGroups, { id: groupId }) || {};

  return {
    groupId,
    networkId,
    currentGroup,
    clients: _.get(state, 'dashboard.clients', {}),
    filters: _.get(state, 'dashboard.clients.filters', {}),
    isFetching: _.get(state, 'dashboard.clients.isFetching', false),
    // CORE-WORK - Need to resolve user.groups migration work
    isCC: _.get(state, 'session.isCoordinationGroup', false),
    userId: _.wget(state, 'user.id', null),
    refetch: _.get(state, 'dashboard.refetch'),
    token: _.get(state, 'session.token', ''),
    labels: labelCustomization(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({
      fetchDashboardContacts,
      fetchProvidersUserCore,
    }, dispatch),
    dispatch,
  };
}

AllClients.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllClients);
