import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Listbox } from '@headlessui/react';
import { Icon } from '@unite-us/ui';
import ReduxFormControlWrapper from './ReduxFormControlWrapper';

export const SelectField = ({
  className,
  clearable,
  forceOpen,
  label,
  listBoxUpOptionsClassName,
  onChange,
  options,
  placeholder,
  required,
  truncateOptions,
  value,
  hideLabel,
}) => {
  const onClearClick = (event) => {
    event.stopPropagation();
    onChange('');
  };
  const selectedOption = options.find((option) => option.value === value) || null;

  return (
    <Listbox value={selectedOption} onChange={onChange}>
      {({ open }) => (
        <div className={classNames(className, 'antialiased')}>
          <Listbox.Label className={classNames(
            'block mb-1 leading-snug',
            'font-extrabold font-medium-font normal-case',
            'text-13px text-text-blue',
            hideLabel && 'hidden',
            required && 'ui-form-field__label--required', // red asterisk after content
          )}
          >
            {label}
          </Listbox.Label>
          <div className="relative">
            <Listbox.Button
              className={classNames(
                'cursor-pointer relative w-full bg-white cursor-default text-left',
                'pl-3 py-2',
                'focus:outline-none border border-solid border-dark-border-blue rounded-md shadow-sm',
                'border-dark-border-blue hover:border-action-blue focus:border-action-blue focus:shadow-input-ring',
                open && 'border-action-blue shadow-input-ring',
                clearable && selectedOption ? 'pr-12' : 'pr-10',
              )}
            >
              <span className="block h-5 leading-5 truncate">
                {(selectedOption && selectedOption.label)}
                {!selectedOption && <span aria-hidden className="text-gray-600">{placeholder}</span>}
              </span>
              {
                clearable && selectedOption && (
                  <span
                    className="absolute opacity-50 inset-y-0 right-4 flex items-center pr-5"
                  >
                    <Icon
                      className="text-gray"
                      icon="IconCross"
                      size={10}
                      onClick={onClearClick}
                      ariaLabel={`Clear ${label}`}
                    />
                  </span>
                )
              }
              <span className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                <Icon
                  aria-hidden
                  className="text-gray"
                  icon="IconChevronDown"
                  size={12}
                />
              </span>
            </Listbox.Button>
            {(open || forceOpen) && (
              <Listbox.Options
                className={classNames(
                  'absolute z-dropdown box-content pl-0 py-1 z-10 mt-1 w-full max-h-80 overflow-auto',
                  'bg-white shadow-lg rounded-md text-base focus:outline-none',
                  listBoxUpOptionsClassName,
                )}
                static
              >
                {options.map((option) => (
                  <Listbox.Option
                    className={({ active }) => classNames(
                      'relative cursor-pointer py-2 pl-3 pr-9 select-none',
                      active ? 'bg-action-blue' : 'text-gray-900',
                    )}
                    key={option.label}
                    value={option}
                  >
                    {({ active, selected }) => (
                      <>
                        <span
                          className={classNames(
                            'block leading-4',
                            truncateOptions && 'truncate',
                            active && 'text-white',
                            selected ? 'font-semibold mr-8' : 'font-normal',
                          )}
                        >
                          {option.label}
                        </span>
                        {selected && (
                          <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                            <Icon
                              aria-hidden
                              className={classNames(
                                'h-5 w-5 fill-current',
                                active ? 'text-white' : 'text-action-blue',
                              )}
                              icon="IconCheck"
                            />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            )}
          </div>
        </div>
      )}
    </Listbox>
  );
};

SelectField.propTypes = {
  className: PropTypes.string,
  clearable: PropTypes.bool,
  forceOpen: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.array,
        PropTypes.number,
      ]).isRequired,
    }),
  ),
  label: PropTypes.node,
  listBoxUpOptionsClassName: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
  ]).isRequired,
  truncateOptions: PropTypes.bool,
  hideLabel: PropTypes.bool,
};

SelectField.defaultProps = {
  className: '',
  clearable: false,
  forceOpen: false,
  options: [],
  label: '',
  listBoxUpOptionsClassName: '',
  placeholder: '',
  required: false,
  truncateOptions: true,
  hideLabel: false,
};

export default ReduxFormControlWrapper(SelectField);
