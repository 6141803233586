// Library Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Serializer } from '@unite-us/client-utils';
import { dates } from '@unite-us/app-components';
import { Spinner } from 'common/spinners';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from '@unite-us/ui';

// Util Imports
import convertToDisplayName from 'common/utils/Enums/convertToDisplayName';
import callOrLog from 'src/common/utils/callOrLog';
import { REPORTS } from 'common/utils/EventTracker/utils/eventConstants';

// Stylesheet Imports
import 'common/stylesheets/tableView.scss';

function findExportValue(exp, key) {
  return _.get(exp, key, '');
}

function displayDateRange(exp) {
  const startDate = dates.formatDate(findExportValue(exp, 'details.start_date'));
  const endDate = dates.formatDate(findExportValue(exp, 'details.end_date'));

  return startDate ? `${startDate} to ${endDate}` : `All Time, ending ${endDate}`;
}

class ExportsTable extends Component {
  constructor(props) {
    super(props);

    this.trackDownloadClick = this.trackDownloadClick.bind(this);
  }

  trackDownloadClick(exp) {
    const slimExport = Serializer.build({ export: exp });

    callOrLog(() => this.context.eventTracker(REPORTS.downloadedExport, slimExport));
  }

  render() {
    const {
      exports, exportEnums, loading, styles, refetch,
    } = this.props;

    if (loading && !refetch) { return <Spinner text="Loading Exports..." />; }

    return (
      <Table
        className="exports-table"
        noBorder
        secondaryDataList={[1, 2, 4, 5]}
      >
        <TableHeader>
          <TableRow>
            <TableHeaderColumn style={styles.typeWidth}>Export Type</TableHeaderColumn>
            <TableHeaderColumn style={styles.dateRangeWidth}>Date Range</TableHeaderColumn>
            <TableHeaderColumn className="table-view__dynamic-label">Requested By</TableHeaderColumn>
            <TableHeaderColumn className="table-view__dynamic-label">Date Requested</TableHeaderColumn>
            <TableHeaderColumn className="table-view__dynamic-label">Status</TableHeaderColumn>
            <TableHeaderColumn style={styles.downloadWidth}>Download</TableHeaderColumn>
          </TableRow>
        </TableHeader>

        <TableBody
          displayRowCheckbox={false}
          showRowHover
        >
          {
            exports.map((exp, index) => (
              <TableRow
                className="exports-table__row"
                key={`export-row-${index}`}
                selectable={false}
              >
                <TableRowColumn style={styles.typeWidth}>
                  {convertToDisplayName(exportEnums, 'types', findExportValue(exp, 'export_type'))}
                </TableRowColumn>
                <TableRowColumn style={styles.dateRangeWidth}>{displayDateRange(exp)}</TableRowColumn>
                <TableRowColumn className="table-view__dynamic-value">
                  {_.get(exp, 'requester.full_name', '')}
                </TableRowColumn>
                <TableRowColumn className="table-view__dynamic-value">
                  {dates.formatDate(findExportValue(exp, 'created_at'))}
                </TableRowColumn>
                <TableRowColumn>
                  {convertToDisplayName(exportEnums, 'statuses', findExportValue(exp, 'state'))}
                </TableRowColumn>
                {!_.isEmpty(exp.documents) ? (
                  <TableRowColumn style={styles.downloadWidth}>
                    <a
                      download
                      href={exp.documents[0].pdf_url}
                      role="button"
                      target="_blank"
                      rel="noreferrer noopener"
                      onClick={() => { this.trackDownloadClick(exp); }}
                    >
                      {
                        exp.documents[0].pdf_url && 'Download'
                      }
                    </a>
                  </TableRowColumn>
                ) : null}
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    );
  }
}

ExportsTable.propTypes = {
  exports: PropTypes.array.isRequired,
  exportEnums: PropTypes.shape({
    types: PropTypes.array.isRequired,
    time_fields: PropTypes.array.isRequired,
    statuses: PropTypes.array.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  refetch: PropTypes.bool.isRequired,
  styles: PropTypes.object.isRequired,
};

ExportsTable.defaultProps = {
  exportEnums: {},
  styles: {
    dateRangeWidth: {},
    downloadWidth: { width: '75px' },
    timeFieldWidth: { width: '121px' },
    typeWidth: { width: '170px' },
  },
};

ExportsTable.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default ExportsTable;
