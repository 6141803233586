import React from 'react';
import PropTypes from 'prop-types';

const DisplayLine = ({
  title, value, className,
}) => (
  <div className={`${className} display-line`}>
    <h5 className="display-line-title">{title}</h5>
    <p className="display-line-value">{value}</p>
  </div>
);

DisplayLine.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

DisplayLine.defaultProps = {
  className: '',
  title: '',
  value: '',
};

export default DisplayLine;
