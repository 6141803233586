import {
  reduce,
  isUndefined,
  flatten,
  map,
  includes,
} from 'lodash';

export default function findCurrentElementLocation(state, idToFind) {
  return reduce(state, (result, curr, key) => {
    if (result.found) { return result; }
    if (isUndefined(curr.data)) { return result; }

    const sectionIds = flatten(map(curr.data, (page) => map(page.data, (el) => el.id)));
    if (includes(sectionIds, idToFind)) {
      return {
        found: true,
        location: key,
      };
    }

    return result;
  }, { found: false, location: '' });
}
