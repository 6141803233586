import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { IconNavigationLink, LeftNavigationBar } from 'src/common/Navigation';
import routesToNavConfig from 'pages/backoffice/routesToNavConfig';
import hasBackOfficeAccess from 'src/common/utils/BackOffice/backOffice';
import { FeeScheduleStatusContextProvider } from 'src/pages/fee-schedules/components/FeeScheduleStatusContextProvider';

function BackOffice(props) {
  const {
    showBackOffice,
    children,
    route,
  } = props;

  const path = route.path;
  const routes = routesToNavConfig({ route, path });
  const isNavigatingFeeSchedules = window.location.href.includes('fee_schedules');
  const fspId = window.location.href.match(/fee_schedules\/([^/]+)/)?.[1];

  return (
    showBackOffice &&
       (
       <div className="-mx-container-padding min-h-full flex">
         <LeftNavigationBar>
           {routes.map((r) => <IconNavigationLink key={r.label} {...r} />)}
         </LeftNavigationBar>
         <div className="break-normal flex-grow">
           <FeeScheduleStatusContextProvider isNavigatingFeeSchedules={isNavigatingFeeSchedules} fspId={fspId}>
             {children}
           </FeeScheduleStatusContextProvider>
         </div>
       </div>
       )
  );
}

BackOffice.propTypes = {
  children: PropTypes.node.isRequired,
  route: PropTypes.object.isRequired,
  showBackOffice: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    showBackOffice: hasBackOfficeAccess(state),
  };
}

export default connect(mapStateToProps)(BackOffice);
