import { get } from 'lodash';

export default function getScreeningIdFromPathname(pathname = '') {
  const regex = /^\/?screenings\/(.*)\/need\/(.*)\/(referrals)\/(.*)$/;
  const screeningId = get(pathname.match(regex), '[1]');
  const needId = get(pathname.match(regex), '[2]');

  return {
    screeningId,
    needId,
  };
}
