import {
  FETCH_ROLES_STARTED,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_ERROR,
} from 'actions';
import {
  STATUS_INITIAL,
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_ERROR,
} from 'common/Status/constants';

export const defaultState = {
  data: {
    group: [],
    program: [],
  },
  status: STATUS_INITIAL,
};

export default function roleReducer(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case FETCH_ROLES_STARTED:
      return { ...state, status: STATUS_PENDING };
    case FETCH_ROLES_ERROR:
      return { ...state, status: STATUS_ERROR };
    case FETCH_ROLES_SUCCESS: {
      const groupRoles = payload.data.data.filter((role) => (
        role.attributes.role_type === 'system'
      ));
      const programRoles = payload.data.data.filter((role) => (
        role.attributes.role_type === 'program'
      ));

      return {
        ...state,
        data: {
          group: groupRoles,
          program: programRoles,
        },
        status: STATUS_SUCCESS,
      };
    }
    default:
      return state;
  }
}
