const validate1Year = (start_at, end_at) => {
  if (start_at && (start_at !== '') && end_at && (end_at !== '')) {
    const millisBetweenDates = new Date(end_at) - new Date(start_at);
    const daysBetweenDates = millisBetweenDates / (86400 * 1000);
    if (daysBetweenDates > 365) {
      return 'Maximum duration is 1 year';
    }
  }
  return null;
};

export default validate1Year;
