import React from 'react';
import { get } from 'lodash';
import { Link } from 'react-router';
import { phones as phonesUtils } from '@unite-us/client-utils';
import { useFind, usePopulate } from 'api/APIHooks';
import { Card, CardHeader } from 'common/Card';
import { LineItem } from 'common/display/LineItem';
import { useFeatureFlag } from 'common/hooks';
import { useCurrentPayerId, useCurrentProviderId } from 'common/contexts/CurrentProviderContext';
import InsuranceStatusIndicator from 'components/Referrals/ReferralDetail/components/InsuranceStatusIndicator';
import PropTypes from 'prop-types';
import { epochToDate } from 'common/utils/utils';
import formatDate from 'common/utils/Dates/formatDate';
import findPrimaryNumber from 'common/form/Phone/utils/findPrimaryNumber';
import useInsuranceExternalMemberID from '../../enrollments/hooks/useInsuranceExternalMemberID';

const ClientDetailsCard = ({ person, serviceAuthorization }) => {
  const pays4502ShowSCC = useFeatureFlag('PAYS_4502_show_scc');
  const isPersonLoaded = !!person?.first_name;

  const personId = get(serviceAuthorization, 'person.id', '');
  const providerId = useCurrentProviderId();
  const { data: clientRelationshipData } = useFind(
    'client_relationship',
    {
      person: personId,
      provider: providerId,
    },
    {
      queryConfig: {
        enabled: !!personId && !!providerId,
        placeholderData: undefined,
      },
    },
  );
  const clientRelationships = get(clientRelationshipData, 'data.data', []);
  const hasClientRelationship = clientRelationships.length > 0;

  const primaryPhone = findPrimaryNumber(get(serviceAuthorization, 'person.phone_numbers', []));

  const currentPayerId = useCurrentPayerId();
  const {
    isLoading: insLoading,
    isFetching: insFetching,
    externalMemberID,
  } = useInsuranceExternalMemberID(person?.id, currentPayerId);

  const isInsuranceLoaded = !insLoading && !insFetching;
  const { isSuccess: isSocialCareCoverageLoaded } = usePopulate(
    'insurance',
    'insurance',
    serviceAuthorization,
    { queryConfig: { placeholderData: undefined, enabled: pays4502ShowSCC } },
  );
  const { isSuccess: isSocialCareCoveragePlanLoaded } = usePopulate(
    'insurance.plan',
    'plan',
    serviceAuthorization,
    { queryConfig: { placeholderData: undefined, enabled: pays4502ShowSCC } },
  );
  const enrollmentStatus = serviceAuthorization.insurance?.insurance_status;
  usePopulate(
    'fee_schedule_program',
    'fee_schedule_program',
    serviceAuthorization,
    { queryConfig: { placeholderData: undefined, enabled: pays4502ShowSCC } },
  );
  const { isSuccess: isFeeScheduleLoaded } = usePopulate(
    'fee_schedule_program.fee_schedule',
    'fee_schedule',
    serviceAuthorization,
    { queryConfig: { placeholderData: undefined, enabled: pays4502ShowSCC } },
  );
  const ignoreExpiration = !!serviceAuthorization?.fee_schedule_program?.fee_schedule?.ignore_social_care_expired_dates;

  return (
    <Card className="bg-white">
      <CardHeader
        className="pt-2 pl-2 -pb-2 space-y-2"
        title="Client"
      />
      <dl className="m-0 p-6 grid grid-cols-4 gap-x-8 gap-y-2">
        <LineItem
          field="Name"
          valueClassName="col-span-3 ml-2"
          isLoaded={isPersonLoaded}
          dataTestElement="client-name"
        >
          {hasClientRelationship ? (
            <Link to={`/facesheet/${get(person, 'id', '')}`}>
              {get(serviceAuthorization, 'person.first_name')} {get(serviceAuthorization, 'person.last_name')}
            </Link>
          ) : (
            <>
              {get(serviceAuthorization, 'person.first_name')} {get(serviceAuthorization, 'person.last_name')}
            </>
          )}
        </LineItem>
        <LineItem
          field="ID#"
          valueClassName="col-span-3 ml-2"
          isLoaded={isInsuranceLoaded}
          dataTestElement="client-id"
        >
          {externalMemberID || 'No Client ID'}
        </LineItem>
        <LineItem
          field="Date of Birth"
          valueClassName="col-span-3 ml-2"
          isLoaded={isPersonLoaded}
          dataTestElement="client-dob"
        >
          {epochToDate(get(serviceAuthorization, 'person.date_of_birth'))}
        </LineItem>
        <LineItem
          field="Phone"
          valueClassName="col-span-3 ml-2"
          isLoaded={isPersonLoaded}
          dataTestElement="client-phone"
        >
          {primaryPhone && phonesUtils.formatPhoneNumber(primaryPhone.phone_number)}
        </LineItem>
        {pays4502ShowSCC && (
          <>
            <LineItem
              field="Social Care Coverage Plan"
              valueClassName="col-span-3 ml-2"
              isLoaded={isSocialCareCoveragePlanLoaded}
              dataTestElement="client-insurance-plan-name"
            >
              <div className="leading-5">
                {serviceAuthorization.insurance?.plan?.name}
                <br />
                {formatDate(serviceAuthorization.insurance?.enrolled_at)}
                {serviceAuthorization.insurance?.expired_at ?
                  ` - ${formatDate(serviceAuthorization.insurance?.expired_at)}` :
                  ' - present'}
              </div>
            </LineItem>
            <LineItem
              field="Social Care Coverage Status"
              valueClassName="col-span-3 ml-2"
              isLoaded={isSocialCareCoverageLoaded && isFeeScheduleLoaded}
              dataTestElement="client-insurance-status"
            >
              {() => (
                <InsuranceStatusIndicator
                  ignoreExpiration={ignoreExpiration}
                  showTooltip={false}
                  status={enrollmentStatus}
                />
              )}
            </LineItem>
          </>
        )}
      </dl>
    </Card>
  );
};

ClientDetailsCard.propTypes = {
  person: PropTypes.object.isRequired,
  serviceAuthorization: PropTypes.object.isRequired,
};

export default ClientDetailsCard;
