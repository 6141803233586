import { hasRecall } from 'src/components/Referrals/ReferralStatus/utils';
import { isClosed } from 'src/components/Referrals/ReferralStatus/utils/status';

function returnFilteredStatus(referral = {}) {
  if (hasRecall(referral) && !isClosed(referral)) {
    return 'recalled';
  }

  return referral.status;
}

export default returnFilteredStatus;
