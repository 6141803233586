import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Button, Dialog } from '@unite-us/ui';
import { browserHistory } from 'common/utils/browserHistory';
import { archiveReferral } from 'src/actions/Referral/Group/closeReferral';
import './InactiveReferralDialog.scss';

const InactiveReferralDialog = ({
  actionRedirectPath,
  referralId,
  resetActionValue,
  setDialogCallback,
}) => {
  const inputEl = useRef(null);
  const openDialog = () => {
    inputEl.current.openDialog()
      .then(resetActionValue);
  };

  const closeDialog = () => {
    inputEl.current.closeDialog();
  };
  const archive = async () => {
    const response = await archiveReferral(referralId);
    closeDialog();
    browserHistory.push(actionRedirectPath);
  };

  useEffect(() => { setDialogCallback(openDialog, closeDialog); }, []);

  return (
    <div className="inactive-referral-dialog">
      <Dialog
        id="inactive-referral-modal"
        data-testid="inactive-referral-modal"
        ref={inputEl}
        title="Close Referral"
        size="large"
        modal
      >
        <div>
          <div className="content-with-actions">
            <div className="content-container inactive-referral-dialog__content-container">
              <h2>
                There are still active referrals in the referral group so you cannot enter a resolution and outcome at this time.
                <br /><br />
                Closing this referral will make it inactive.
              </h2>
            </div>
            <div className="actions">
              <span className="action-item">
                <Button
                  id="close-case-cancel-btn"
                  label="Cancel"
                  onClick={closeDialog}
                />
              </span>
              <span className="action-item">
                <Button
                  id="close-case-btn"
                  label="Close Referral"
                  primary
                  onClick={archive}
                />
              </span>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

InactiveReferralDialog.propTypes = {
  resetActionValue: PropTypes.func.isRequired,
  setDialogCallback: PropTypes.func.isRequired,
  referralId: PropTypes.string.isRequired,
  actionRedirectPath: PropTypes.string.isRequired,
};

export default InactiveReferralDialog;
