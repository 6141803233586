import { isUndefined } from 'lodash';

function isServiceTypeInProgram(referral) {
  const serviceId = referral?.service_type?.id;
  const programServiceIds = referral?.receiving_program?.provided_service_type_ids?.map((service) => service.id);

  if (isUndefined(serviceId) || isUndefined(programServiceIds)) return true;

  return programServiceIds.includes(serviceId);
}

export default isServiceTypeInProgram;
