import { SET_ASSESSMENTS_RESPONSES } from '..';

function setAssessmentsResponses(responses = [], contactId = '') {
  return {
    type: SET_ASSESSMENTS_RESPONSES,
    payload: {
      responses,
      responsesContactId: contactId,
    },
  };
}

export default setAssessmentsResponses;
