import { get } from 'lodash';

function noGroupsSelected(selectedGroups = []) {
  if (selectedGroups.length === 0) {
    return true;
  }

  return selectedGroups.length === 1 &&
    !get(selectedGroups, '[0].group.value') &&
    !get(selectedGroups, '[0].program.value.id');
}

export default noGroupsSelected;
