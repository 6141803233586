import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  TableHeaderColumn,
} from '@unite-us/ui';
import { TableHeaderColumnSortableIcon } from './index';

function rollDirection(dir) {
  switch (dir) {
    case 'asc':
      return 'desc';
    case 'desc':
      return '';
    default:
      return 'asc';
  }
}

class TableHeaderColumnSortable extends Component {
  constructor(props) {
    super(props);

    this.onSortColumn = this.onSortColumn.bind(this);

    this.state = {
      direction: props.initialSortDirection || '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.sortDirection !== nextProps.sortDirection) {
      this.setState({ direction: nextProps.sortDirection });
    }
  }

  onSortColumn() {
    const newDir = rollDirection(this.state.direction);
    this.setState({
      direction: newDir,
    });
    this.props.onSortColumn(this.props.sortColumn, newDir);
  }

  render() {
    const {
      children,
      className,
      style,
      tooltip,
      tooltipStyle,
    } = this.props;
    return (
      <TableHeaderColumn
        className={classNames(['table-header-column-sortable', className])}
        style={style}
        tooltip={tooltip}
        tooltipStyle={tooltipStyle}
      >
        <a
          className="table-header-column-sortable__link"
          onClick={this.onSortColumn}
          role="link"
        >
          {children} <TableHeaderColumnSortableIcon sortDirection={this.state.direction} />
        </a>
      </TableHeaderColumn>
    );
  }
}

TableHeaderColumnSortable.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  initialSortDirection: PropTypes.string,
  onSortColumn: PropTypes.func.isRequired,
  sortColumn: PropTypes.string.isRequired,
  sortDirection: PropTypes.string,
  style: PropTypes.object,
  tooltip: PropTypes.string,
  tooltipStyle: PropTypes.object,
};

export default TableHeaderColumnSortable;
