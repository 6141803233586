import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { browserHistory } from 'common/utils/browserHistory';
import {
  useFindDirectoryConfiguration,
  useUpdateDirectoryConfiguration,
} from 'src/components/Backoffice/api/hooks/prdApi/DirectoryConfigurationHooks';
import constructDirectoryConfigurationPayload from 'src/pages/backoffice/prds/utils/constructDirectoryConfigurationPayload';
import constructDirectoryConfigurationInitialFormValues from 'src/pages/backoffice/prds/utils/constructDirectoryConfigurationInitialFormValues';
import { useFindDirectory } from 'src/components/Backoffice/api/hooks/prdApi/DirectoryHooks';
import { Spinner } from 'common/spinners';
import PrdDetailForm from './components/PrdDetailForm';

const EditDirectoryConfiguration = ({ params }) => {
  const prdId = params.id;
  const directoryConfigurationId = params.directory_configuration_id;
  const { updateRecord: updateDirectoryConfiguration } = useUpdateDirectoryConfiguration();
  const { data: prd, isFetching } = useFindDirectory(prdId);
  const { status, data } = useFindDirectoryConfiguration(directoryConfigurationId);
  const initialValues = constructDirectoryConfigurationInitialFormValues({ ...data });
  const contactData = JSON.parse(data?.contact || '{}');
  const showPending = status === 'loading' && isEmpty(data);

  const onSubmit = async (directoryConfiguration) => {
    const payload = constructDirectoryConfigurationPayload(directoryConfiguration, initialValues?.logo, contactData);
    const response = await updateDirectoryConfiguration(directoryConfigurationId, payload);
    if (response) {
      browserHistory.push({
        pathname: `/backoffice/prds/${prdId}`,
      });
    }
  };
  return (
    <>
      {isFetching || isEmpty(initialValues) ? <Spinner /> : (
        <PrdDetailForm
          initialValues={initialValues} onSubmit={onSubmit} prdId={prdId}
          customerName={prd.customer_name} directoryConfigurationId={directoryConfigurationId} showPending={showPending}
        />
      )}
    </>
  );
};

EditDirectoryConfiguration.propTypes = {
  params: PropTypes.object.isRequired,
};

export default EditDirectoryConfiguration;
