import { browserHistory } from 'common/utils/browserHistory';
import referralRoute from './referralRoute';

function goToReferral(referral, query = {}, groupId = '') {
  const pathname = referralRoute(referral, groupId);
  browserHistory.push({
    pathname,
    query,
  });
}

export default goToReferral;
