import { get } from 'lodash';
import { feeSchedulePrograms as fspUtils } from '@unite-us/client-utils';
import { useFind, useFindRecord, usePopulate } from 'api/APIHooks';
import { PENDING_AND_APPROVED_STATUSES } from 'pages/invoices/constants';
import formatShortDate from 'common/utils/Dates/formatShortDate';

function useInvoiceSpend(serviceAuthorizationId) {
  const serviceAuthorizationIdExists = Boolean(serviceAuthorizationId);

  const {
    data: serviceAuthorizationData,
    isFetching: isAuthFetching,
    isSuccess: isAuthLoaded,
  } = useFindRecord('service_authorization', serviceAuthorizationId, {
    queryConfig: {
      enabled: serviceAuthorizationIdExists,
    },
  });

  const serviceAuthorization = get(serviceAuthorizationData, 'data.data', {});

  const {
    isFetching: isFSPFetching,
    isSuccess: isFSPLoaded,
  } = usePopulate('fee_schedule_program', 'fee_schedule_program', serviceAuthorization, {
    queryConfig: {
      enabled: serviceAuthorizationIdExists && isAuthLoaded && !isAuthFetching,
    },
  });
  const shouldFetchInvoicedSpend = serviceAuthorizationIdExists && isAuthLoaded && isFSPLoaded &&
    !(isAuthFetching || isFSPFetching);

  const {
    data: invoicedSpendData,
    isFetching: isSpendFetching,
    isSuccess: isSpendLoaded,
  } = useFind(
    'invoiced_spend',
    {
      fee_schedule_program: serviceAuthorization.fee_schedule_program?.id,
      person: serviceAuthorization.person?.id,
      service_authorization: serviceAuthorization.id,
      state: PENDING_AND_APPROVED_STATUSES.join(','),
    },
    {
      queryConfig: {
        enabled: shouldFetchInvoicedSpend,
        refetchOnMount: 'always',
      },
    },
  );

  if (shouldFetchInvoicedSpend && !isSpendFetching && isSpendLoaded) {
    const totalInvoicedSpend = get(invoicedSpendData, 'data.meta.total_spend', 0);
    const originalAuthorizedAmount = serviceAuthorization.approved_unit_amount ||
    serviceAuthorization.approved_cents;

    const paymentApproach = get(serviceAuthorization, 'fee_schedule_program.payment_type');

    const unit = get(serviceAuthorization, 'fee_schedule_program.unit', '');
    const isCostBased = fspUtils.isCostPaymentType(paymentApproach);
    const remainingAuthorizedAmount = originalAuthorizedAmount - totalInvoicedSpend;
    const startDate = formatShortDate(serviceAuthorization.approved_starts_at);
    const endDate = formatShortDate(serviceAuthorization.approved_ends_at);
    const authorizedServiceDeliveryDates = startDate === endDate ?
      startDate :
      `${startDate} - ${endDate}`;

    return {
      paymentApproach,
      unit,
      isCostBased,
      remainingAuthorizedAmount,
      authorizedServiceDeliveryDates,
      approvedStartsAt: serviceAuthorization.approved_starts_at,
      approvedEndsAt: serviceAuthorization.approved_ends_at,
      isFetching: false,
    };
  }
  if (!serviceAuthorizationIdExists) {
    return {};
  }
  return { isFetching: true };
}

export default useInvoiceSpend;
