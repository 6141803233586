import PropTypes from 'prop-types';
import React from 'react';
import {
  Menu,
  MenuItem,
} from '@unite-us/ui';
import Humanize from 'humanize-plus';
import { isEmpty, noop } from 'lodash';
import '../stylesheets/networkSelectMenuItem.scss';
import {
  getSharedNetworks,
  routeOnKeyDown,
} from '../utils';

function formatLabel(networkName = '') {
  return Humanize.capitalizeAll(networkName);
}

const NetworkSelectMenuItem = ({
  active,
  disabled,
  group,
  linkToNetwork,
  user,
  onNetworkLinkClick,
  label,
}) => {
  const networks = getSharedNetworks({ user, group });
  const hasSingleNetwork = networks.length === 1;

  if (isEmpty(networks)) {
    return null;
  }

  return (
    <div className="network-select-menu-item">
      <Menu
        className="networks-menu-item-btn"
        position="bottom-start"
        keyHandler={
          routeOnKeyDown(() => onNetworkLinkClick(hasSingleNetwork ? networks[0].id : null))
        }
        anchorElement={(
          <button
            type="button"
            id="nav-network"
            onClick={() => onNetworkLinkClick(hasSingleNetwork ? networks[0].id : null)}
            onKeyDown={
              routeOnKeyDown(() => onNetworkLinkClick(hasSingleNetwork ? networks[0].id : null))
            }
            className={active ? 'left-nav-link--active left-nav-link' : 'left-nav-link'}
          >
            {label}
          </button>
        )}
      >
        {
          !hasSingleNetwork ? networks.map(({ name, id }, index) => (
            <MenuItem
              className={`network-menu-${index}`}
              key={id}
              primaryNode={formatLabel(name)}
              onClick={() => linkToNetwork(id)}
              onKeyDown={routeOnKeyDown(() => linkToNetwork(id))}
              disabled={disabled}
            />
          )) : []
        }
      </Menu>
    </div>
  );
};

NetworkSelectMenuItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  group: PropTypes.object.isRequired,
  linkToNetwork: PropTypes.func,
  user: PropTypes.shape({
    networks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    ),
  }).isRequired,
  onNetworkLinkClick: PropTypes.func,
  label: PropTypes.string,
};

NetworkSelectMenuItem.defaultProps = {
  active: true,
  disabled: false,
  onNetworkLinkClick: noop(),
  linkToNetwork: noop(),
  label: '',
};

export default NetworkSelectMenuItem;
