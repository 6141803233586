import PropTypes from 'prop-types';
import React from 'react';

const MultiLineRowDisplay = ({ children }) => {
  const childrenWithNoHover = React.Children.map(
    children,
    (child) => React.cloneElement(child, {
      noHover: true,
    }),
  );
  return (
    <div
      className="multi-line-row-display common-display-profile common-display-profile--multi-line editable-panel"
    >
      {childrenWithNoHover}
    </div>
  );
};

MultiLineRowDisplay.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MultiLineRowDisplay;
