import { CONSENT_OPTIONS } from 'src/components/InformedConsent/constants';
import { includes } from 'lodash';

const uploadConsentTypes = [
  CONSENT_OPTIONS.AUDIO,
  CONSENT_OPTIONS.DOCUMENT,
];

function isUploadConsentType(consentType) {
  return includes(uploadConsentTypes, consentType);
}

export default isUploadConsentType;
