import React from 'react';
import { PropTypes } from 'prop-types';
import { Icon } from '@unite-us/ui';
import cx from 'classnames';

const ExpandableButton = (
  {
    isOpen, onClick, dataTestId, className, children,
  },
) => (
  <button
    type="button"
    onClick={onClick}
    className={cx('flex items-center focus:outline-none z-0', className)}
    data-testid={dataTestId}
  >
    <span className="text-action-blue font-bold cursor-pointer">
      {children || (isOpen ? 'Hide' : 'Show')}
    </span>
    <Icon
      aria-hidden
      className={cx(
        'transition-transform duration-500 ease-in-out ml-2 cursor-pointer',
        isOpen && 'transform rotate-180',
      )}
      icon="IconChevronDown"
      size={12}
    />
  </button>
);

ExpandableButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  dataTestId: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

ExpandableButton.defaultProps = {
  className: '',
  children: null,
};

export default ExpandableButton;
