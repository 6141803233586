import axios from 'axios';
import Notifier from 'common/helpers/Notifier';
import { get } from 'lodash';
import { FETCH_SUPPORT } from 'actions';
import submitLogoutForm from 'src/common/utils/Auth/submitLogoutForm';

export default function fetchSupport({ impersonation = false } = {}) {
  const request = axios.get('/session/support');

  return (dispatch) => {
    function onSuccess(payload) {
      dispatch({ type: FETCH_SUPPORT, payload, impersonation });
      return payload;
    }

    function onError(error) {
      if (get(error, 'response.status') === 403) {
        submitLogoutForm();
        return error;
      }
      Notifier.handleErrors(error);
      return error;
    }

    return request.then(onSuccess, onError);
  };
}
