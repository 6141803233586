import { useEffect, useRef, useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import { useFind } from 'src/api/APIHooks';

const useFeeScheduleScreeningOptions = (
  { feeScheduleIds, feeScheduleScreeningName } =
    { feeScheduleIds: [], feeScheduleScreeningName: { value: '', label: '' } },
) => {
  const [name, setName] = useState(null);

  const debounceSearch = useRef(debounce(setName, 300)).current;

  useEffect(() => {
    debounceSearch(feeScheduleScreeningName?.label);

    return () => {
      debounceSearch.cancel();
    };
  }, [feeScheduleScreeningName]);

  const { data } = useFind(
    'fee_schedule_screening',
    { fee_schedule: feeScheduleIds.join(), name },
    {
      queryConfig: {
        enabled: !isEmpty(feeScheduleIds) || !isEmpty(name),
        placeholderData: undefined,
      },
    },
  );

  return data?.data?.data?.map(({ name: screeningName, id }) => ({ label: screeningName, value: id })) ?? [];
};

export default useFeeScheduleScreeningOptions;
