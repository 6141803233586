import React from 'react';
import { PropTypes } from 'prop-types';
import { SelectField } from '@unite-us/ui';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import moment from 'moment';
import { timeOfDayOptions } from 'src/common/form/HoursOfOperationFields/utils';
import ButtonAddField from './ButtonAddField';
import ButtonRemoveField from './ButtonRemoveField';
import {
  DAYS_OF_THE_WEEK,
  TIME_FORMAT_SERVER,
  TIME_FORMAT_DISPLAY,
} from '../constants';
import normalizeHours from '../utils/normalizeHours';

const timeSlotsOptions = timeOfDayOptions().map(({ label }) => ({
  value: moment(label, TIME_FORMAT_SERVER).format(TIME_FORMAT_DISPLAY),
  label,
}));

const daysOptions = DAYS_OF_THE_WEEK.map((day) => ({
  value: day.toLowerCase(),
  label: day,
}));

const validateValues = (newValue, orgValues) => {
  if (!newValue || !orgValues?.hours?.length) {
    return undefined;
  }

  const daysWithOverlappingHours = normalizeHours.hoursOverlap(orgValues.hours);
  return daysWithOverlappingHours.find((day) => newValue.includes(day)) ?
    "Review your selections. Hours shouldn't overlap." :
    undefined;
};

const HoursOfOperationsEdit = ({ name, push }) => (
  <div className="relative">
    <ButtonAddField
      data-test-element="add_hours"
      isPositioned
      label="Add Hours"
      onClick={() => push('hours', {})}
    />
    <FieldArray name={name}>
      {({ fields }) => (fields.length ? fields.map((fieldName, index) => (
        <div key={`org-fieldset-${fieldName}`} data-testid="hours_set_row" className="flex">
          <div className="w-4/12 mr-2" data-test-element={`hours_day_${index}`}>
            <Field name={`${fieldName}.day`} validate={validateValues}>
              {(params) => (
                <SelectField
                  id={`org-hours-day-${index}`}
                  multiple
                  placeholder="Select..."
                  label="Hours of Operation"
                  hideLabel={!!index}
                  options={daysOptions}
                  shouldSort={false}
                  {...params}
                />
              )}
            </Field>
          </div>
          <div className="w-2/12 mr-3 self-end" data-test-element={`hours_opens_${index}`}>
            <Field name={`${fieldName}.opens`}>
              {(params) => (
                <SelectField
                  id={`org-hours-starts-${index}`}
                  options={timeSlotsOptions}
                  shouldSort={false}
                  label="Opens"
                  hideLabel
                  {...params}
                />
              )}
            </Field>
          </div>
          <div className="w-1/5 self-end" data-test-element={`hours_closes_${index}`}>
            <Field name={`${fieldName}.closes`}>
              {(params) => (
                <SelectField
                  id={`org-hours-ends-${index}`}
                  label="Closes"
                  options={timeSlotsOptions}
                  shouldSort={false}
                  hideLabel
                  {...params}
                />
              )}
            </Field>
          </div>
          <ButtonRemoveField
            onClick={() => fields.remove(index)}
            testId={`hours_set_trash_${index}`}
          />
        </div>
      )) : <div className="h-8" />)}
    </FieldArray>
  </div>
);

HoursOfOperationsEdit.propTypes = {
  name: PropTypes.string.isRequired,
  // Push method comes from the React Final Form library
  // For more information refer to react-final-form-arrays
  // documentation.
  push: PropTypes.func.isRequired,
};

export default HoursOfOperationsEdit;
