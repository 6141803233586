import { addresses } from '@unite-us/client-utils';
import { formatNumber } from 'humanize-plus';

function sortGroupsByDistance(groups, origin) {
  const getDistance = (destinationLatLng) => addresses.getDistanceInMiles(origin, destinationLatLng, {
    format: 'float',
  });
  const mapClosestAddressDistance = (group) => {
    const { addresses: groupAddresses = [] } = group;
    const distances = groupAddresses
      .map((address) => getDistance(address.lat_lng))
      .filter((distance) => distance !== '');
    const distance = Math.min(...distances);
    const miles = distance !== Infinity ? formatNumber(distance, 2) : 'no distance';
    const newName = miles !== 'no distance' ? `${group.name} (${miles} mi)` : `${group.name}`;
    return {
      ...group,
      name: newName,
      distance,
    };
  };
  const sortByDistanceAsc = (a, b) => a.distance - b.distance;
  const groupsWithDistance = groups.map(mapClosestAddressDistance);
  const groupsWithDistanceSorted = groupsWithDistance.sort(sortByDistanceAsc);
  return groupsWithDistanceSorted;
}

export default sortGroupsByDistance;
