import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import pluckSelectedFieldIds from 'src/common/form/utils/pluckSelectedFieldIds';
import ProcedureCodeRow from './ProcedureCodeRow';

const ProcedureCodeSelectField = (props) => {
  const { procedureCodesWithModifiers, formProps } = props;

  const rows = formProps.values.provided_service?.procedure_code_rows || [];
  const selectedProcedureCodes = pluckSelectedFieldIds(rows, 'procedure_code');

  const availableProcedureCodes =
    procedureCodesWithModifiers?.map((procedureCode) => (
      { ...procedureCode, disabled: selectedProcedureCodes.includes(procedureCode.id) }
    ));

  const addField = () => {
    formProps.form.mutators.push('provided_service.procedure_code_rows', { procedure_code: {} });
  };

  useEffect(() => {
    if (rows.length === 0) {
      addField();
    }
  }, [rows]);

  return (
    <>
      <div className="procedure-code-row-container grid grid-cols-5 gap-x-6 lg:gap-y-2 mb-3">
        <FieldArray name="provided_service.procedure_code_rows">
          {({ fields }) => (
            fields.map((name, index) => (
              <ProcedureCodeRow
                name={name}
                index={index}
                options={availableProcedureCodes}
                formProps={formProps}
              />
            ))
          )}
        </FieldArray>
      </div>
    </>
  );
};

ProcedureCodeSelectField.propTypes = {
  procedureCodesWithModifiers: PropTypes.array.isRequired,
  formProps: PropTypes.object.isRequired,
};

export default ProcedureCodeSelectField;
