import { mapKeys } from 'src/components/Group/Programs/Edit/utils';
import { uuOmit } from 'lodash';

const cleanEmailValues = (email = {}) => ({
  ...email,
  acceptable_communication_types: mapKeys(email.acceptable_communication_types),
  is_primary: email.is_primary || false,
});

const cleanPhoneValues = (phone = {}) => ({
  ...phone,
  acceptable_communication_types: mapKeys(phone.acceptable_communication_types),
  is_primary: phone.is_primary || false,
  phone_number: phone.phone_number.replace(/\D/g, ''),
});

const stripNewIds = (arr = [], newIds = []) => (
  arr.reduce((acc, curr) => (
    newIds.includes(curr.id) ?
      [...acc, uuOmit(curr, ['id'])] :
      [...acc, curr]
  ), [])
);

export {
  cleanEmailValues,
  cleanPhoneValues,
  stripNewIds,
};
