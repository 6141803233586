import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@unite-us/ui';
import classNames from 'classnames';
import './AddButton.scss';

const AddButton = ({ className, ...props }) => (
  <Button
    className={classNames('add-button', className)}
    {...props}
  />
);

AddButton.propTypes = {
  className: PropTypes.string,
  iconRight: PropTypes.node,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
};

AddButton.defaultProps = {
  className: '',
  iconRight: <Icon icon="IconPlusCircle" />,
  label: 'Add',
};

export default AddButton;
