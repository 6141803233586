import { getPreferredProp } from '@unite-us/ui';
function isAddressValid() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    state = _ref.state,
    city = _ref.city,
    required = _ref.required;
  var stateValue = getPreferredProp(state, 'value');
  var cityValue = getPreferredProp(city, 'value');
  var isEmpty = !stateValue;
  var hasEnoughInfo = !!cityValue;
  return hasEnoughInfo || isEmpty && !required;
}
export default isAddressValid;