import { RETRIEVE_STORED_SEARCHED_CONTACTS } from 'actions';

export default function retrieveSearchedContacts(contacts) {
  return (dispatch) => {
    dispatch({
      type: RETRIEVE_STORED_SEARCHED_CONTACTS,
      contacts,
    });
  };
}
