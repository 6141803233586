import { reduce, some } from 'lodash';
import { collectServiceGroupErrors } from '.';

const hasAnyErrors = (errors) => some(errors, (error) => error.length > 0);

const validateInNetworkGroupFields = ({
  allowEmptyGroups = false,
  networks = [],
  services = [],
}) => (
  new Promise((resolve, reject) => {
    const errors = reduce(services, (acc, service = {}, index) => ({
      ...acc,
      [index]: collectServiceGroupErrors({ allowEmptyGroups, networks, service }),
    }), {});
    if (hasAnyErrors(errors)) {
      reject(errors);
    }
    resolve();
  })
);

export default validateInNetworkGroupFields;
