import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  InputField,
} from '@unite-us/ui';
import callOrLog from 'src/common/utils/callOrLog';
import { MY_NETWORKS } from 'common/utils/EventTracker/utils/eventConstants';
import { getPermittedOutsideNetworks } from 'src/components/Referrals/utils/scopes';
import _ from 'lodash';
import '../stylesheets/networkActionsBar.scss';
import {
  NetworkActionsBarFilters,
  NetworkActionsBarPagination,
} from './components';
import {
  addRequiredProps,
  getPermittedNetworkOptions,
  getSubNetworkOptions,
} from './utils';

class NetworkActionsBar extends Component {
  constructor(props) {
    super(props);

    this.onNetworkFilterChange = this.onNetworkFilterChange.bind(this);
    this.onNetworkScopeFilterChange = this.onNetworkScopeFilterChange.bind(this);
    this.onServiceTypeFilterChange = this.onServiceTypeFilterChange.bind(this);
  }

  onServiceTypeFilterChange(activeFilters) {
    callOrLog(() => this.context.eventTracker(MY_NETWORKS.selectServiceType));
    this.props.onServiceTypesFilterChange(activeFilters);
  }

  onNetworkFilterChange(activeFilters) {
    callOrLog(() => this.context.eventTracker(MY_NETWORKS.selectNetwork));
    this.props.onNetworkFilterChange(activeFilters);
  }

  onNetworkScopeFilterChange(activeFilters) {
    callOrLog(() => this.context.eventTracker(MY_NETWORKS.selectNetworkScope));
    this.props.onNetworkScopeFilterChange(activeFilters);
  }

  renderFiltersAndPagination() {
    const {
      disableServiceTypeFilter,
      isFetching,
      isSuperNetwork,
      network,
      networkId,
      paging,
      referralScopes,
      serviceTypeOptions,
    } = this.props;

    const networkOptions = isSuperNetwork ?
      getSubNetworkOptions(network) :
      getPermittedNetworkOptions(referralScopes, networkId);

    const showNetworkFilter = isSuperNetwork ||
      !_.isEmpty(getPermittedOutsideNetworks(referralScopes, networkId));

    return (
      <div className="network-actions-bar__dropdown-secondary">
        <div className="network-actions-bar__dropdown-secondary__filters">
          <NetworkActionsBarFilters
            hideNetworkFilter={!showNetworkFilter}
            hideServiceTypeFilter={disableServiceTypeFilter}
            networkOptions={networkOptions}
            onNetworkFilterChange={this.onNetworkFilterChange}
            onNetworkScopeFilterChange={this.onNetworkScopeFilterChange}
            onServiceTypeFilterChange={this.onServiceTypeFilterChange}
            serviceTypeOptions={serviceTypeOptions}
          />
        </div>
        <NetworkActionsBarPagination
          isFetching={isFetching}
          onNextClick={this.props.onNextClick}
          onPrevClick={this.props.onPrevClick}
          paging={paging}
        />
      </div>
    );
  }

  render() {
    const {
      filterText,
    } = this.props;

    return (
      <div className="network-actions-bar">
        <div className="network-actions-bar__top">
          <div className="network-actions-bar__top--name-filter">
            <InputField
              clearable
              field={addRequiredProps(filterText)}
              hideLabel
              id="search-text"
              label="search"
              placeholder="Search by Name..."
            />
          </div>
          {
            this.renderFiltersAndPagination()
          }
        </div>
      </div>
    );
  }
}

NetworkActionsBar.propTypes = {
  disableServiceTypeFilter: PropTypes.bool,
  filterText: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  isSuperNetwork: PropTypes.bool,
  network: PropTypes.object.isRequired,
  networkId: PropTypes.string.isRequired,
  onNetworkFilterChange: PropTypes.func.isRequired,
  onNetworkScopeFilterChange: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  onServiceTypesFilterChange: PropTypes.func.isRequired,
  paging: PropTypes.object,
  referralScopes: PropTypes.arrayOf(
    PropTypes.shape({
      network: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      permitted_network: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
  serviceTypeOptions: PropTypes.array,
};

NetworkActionsBar.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

NetworkActionsBar.defaultProps = {
  disableServiceTypeFilter: false,
  isFetching: false,
  isSuperNetwork: false,
  paging: {},
  serviceTypeOptions: [],
};

export default NetworkActionsBar;
