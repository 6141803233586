function getProgramOptions({
  groups = [],
  selectedProgramIds = [],
}) {
  return groups.map((group) => {
    const {
      programs,
      name,
      position,
    } = group;

    return programs.reduce((programOptions, currentProgram) => {
      if (currentProgram.name === undefined) {
        return programOptions;
      }

      const groupName = currentProgram.distance ?
        `${name} (${currentProgram.distance})` :
        name;

      return [
        ...programOptions,
        {
          id: currentProgram.id,
          attributes: {
            ...currentProgram,
          },
          relationships: {
            provider: { data: group },
            fee_schedule_program: { data: currentProgram.fee_schedule_program },
            services: { data: currentProgram.services },
          },
          disabled: selectedProgramIds.includes(currentProgram.id),
          displayName: `${currentProgram.name} | ${groupName}`,
          position,
        },
      ];
    }, []);
  }).flat();
}

export default getProgramOptions;
