import {
  get,
  isEmpty,
  head,
  tail,
} from 'lodash';
import { addElement } from './addElements';
import { updateElement } from './updateElements';
import removeElement from './removeElement';

function updateAssistanceRequest(state, element, urlData, currentSection) {
  const toUpdateId = get(urlData, 'uuid', '');
  const modifier = get(urlData, 'modifier', '');

  switch (modifier) {
    case 'close': {
      const postRemoveState = removeElement(state, toUpdateId, 'assistanceRequests');
      return currentSection === 'closedAssistanceRequests' ?
        addElement(postRemoveState, element, 'closedAssistanceRequests') :
        postRemoveState;
    }

    default:
      return currentSection === 'assistanceRequests' ?
        updateElement(state, toUpdateId, element, 'assistanceRequests') :
        state;
  }
}

export default function updateAssistanceRequests(state, payload = [], urlData, currentSection) {
  if (isEmpty(payload)) { return state; }

  return updateAssistanceRequests(
    updateAssistanceRequest(state, head(payload), urlData, currentSection),
    tail(payload),
    urlData,
    currentSection,
  );
}
