import React, { useContext, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { TrackerContext } from '@unite-us/client-utils';
import Icon from '@unite-us/ui/dist/Display/Icon';
import { INVOICE } from 'common/utils/EventTracker/utils/eventConstants';
import callOrLog from 'src/common/utils/callOrLog';
import { dollarAmount } from 'src/common/display/Money/DollarAmount';
import DialogV2 from 'src/common/modal/DialogV2';
import { isEmpty, get, uniq } from 'lodash';
import { Link } from 'react-router';
import getRemainingAuthorizedAmountPerInvoice from 'actions/Contact/Group/getRemainingAuthorizedAmountPerInvoice';
import { useFind } from 'src/api/APIHooks';
import { useBulkUpdateInvoiceStatus } from '../hooks/useUpdateInvoice';

const BulkActionsForm = ({
  close,
  invoices,
  setRef,
  onSuccess,
  networkId,
  deselectInvoices,
  getRemainingAuthorizedAmountPerInvoice: getAvailableFundsPerInvoice,
  isOpen,
  invoicedSpends,
  loadingInvoicedSpends,
}) => {
  const [mouseHoverRow, setMouseHoverRow] = useState([]);
  const totalBulkInvoicesAmount = !isEmpty(invoices) ?
    dollarAmount(invoices.reduce((acc, invoice) => acc + invoice?.total_amount_invoiced, 0), true) : 0;

  const bulkStatus = !isEmpty(invoices) ?
    invoices[0].invoice_status :
    'No Invoices Selected';
  const readableBulkActionName =
    bulkStatus === 'accepted_by_payer' ? 'paid' : 'accepted';
  const modalTitle = bulkStatus === 'accepted_by_payer' ? 'Pay' : 'Accept';
  const selectBulkUpdateStatus =
    bulkStatus === 'accepted_by_payer' ? 'paid' : 'accepted_by_payer';

  const invoiceIds = !isEmpty(invoices) ?
    invoices.map((invoice) => invoice?.id) :
    [];

  const bulkAcceptInvoices = useBulkUpdateInvoiceStatus(
    invoiceIds,
    selectBulkUpdateStatus,
  );

  const eventTracker = useContext(TrackerContext);
  const submitBulkActionInvoices = async () => {
    await bulkAcceptInvoices();
    onSuccess();
    callOrLog(() => eventTracker(INVOICE.payerClickedBulkActions.accepted, {
      current_network: networkId,
    }));
  };

  useEffect(() => {
    if (invoices) {
      const setMouseEventRows = invoices.map(() => false);
      setMouseHoverRow(setMouseEventRows);
    }
  }, [invoices]);

  useEffect(() => {
    if (isOpen && bulkStatus === 'transmitted_to_payer') {
      const invoicesWithAuthorization = invoices.filter((invoice) => !!invoice.service_authorization);
      getAvailableFundsPerInvoice(invoicesWithAuthorization);
    }
  }, [invoices, isOpen, bulkStatus]);

  const setMouseHoverState = (idx) => {
    const newRows = mouseHoverRow.map((row, i) => {
      if (i === idx) {
        return !row;
      }
      return row;
    });
    setMouseHoverRow(newRows);
  };

  const remainingAuthorizedAmounts = { ...invoicedSpends };

  if (isOpen && bulkStatus === 'transmitted_to_payer') {
    invoices.forEach((invoice) => {
      remainingAuthorizedAmounts[
        invoice.service_authorization?.id
      ] -= invoice.provided_service_unit_amount;
    });
  }

  const fspIds = uniq(invoices.map((invoice) => invoice.fee_schedule_program_id));
  const { isSuccess: isFSPsLoaded, data } = useFind(
    'fee_schedule_programs',
    { id: fspIds.join() },
    { queryConfig: { placeholderData: undefined } },
  );
  const fsps = get(data, 'data.data', []);
  const invoiceFsps = Object.fromEntries(invoices.map((invoice) => [
    invoice.id, fsps.find((fsp) => invoice.fee_schedule_program_id === fsp.id),
  ]));

  const hasInvoicesAboveRemainingAuthorizedAmount = Object.values(remainingAuthorizedAmounts)
    .some((amount) => amount < 0);

  const canAcceptAllInvoices =
    invoices.every((invoice) => remainingAuthorizedAmounts[invoice.service_authorization?.id] > 0 ||
      invoiceFsps[invoice.id]?.can_invoice_above_remaining_authorized_amount);

  const disableConfirmationButton = invoices.length === 0 ||
    (hasInvoicesAboveRemainingAuthorizedAmount && !canAcceptAllInvoices) ||
    loadingInvoicedSpends || !isFSPsLoaded;

  return (
    <DialogV2
      ref={setRef}
      title={`${modalTitle} selected invoices`}
      confirmLabel={modalTitle}
      confirmationHandler={submitBulkActionInvoices}
      cancelHandler={close}
      width="3xl"
      showIconWithConfirmLabel
      iconName="IconCheckCircle"
      confirmationBtnDisabled={disableConfirmationButton}
    >
      <div className="flex flex-col text-left mb-2">
        <div className="text-sm pb-4 space-y-6" />
        <p
          className="ui-form-field__label text-text-blue font-medium-font text-24px pt-4 font-extrabold px-5"
        >
          You are about to mark the following invoices as
          <b> {readableBulkActionName} </b>for a total of{' '}
          <b>{totalBulkInvoicesAmount}</b>. Would you like to continue?
        </p>
      </div>
      <div className="my-6">
        {!isEmpty(invoices) &&
          invoices.map((invoice, index) => (
            <div
              onMouseEnter={() => setMouseHoverState(index)}
              onMouseLeave={() => setMouseHoverState(index)}
              id={`bulk-actions-form-row-${index + 1}`}
              key={`bulk-action-for-${invoice.id}`}
            >
              {remainingAuthorizedAmounts[invoice.service_authorization?.id] < 0 && (
                <Icon
                  className="absolute left-2 fill-current text-red mr-1"
                  icon="IconExclamationCircle"
                  size={16}
                />
              )}
              <div className="grid grid-cols-6 pl-5 mt-3">
                <Link
                  to={`/invoices/${invoice.id}`}
                  className="item-center underline"
                >
                  {invoice.short_id}
                </Link>
                <div className="flex justify-start mr-3">
                  <p className="truncate">
                    {invoice.fee_schedule_program_name}
                  </p>
                </div>
                <div className="flex justify-start">
                  <p className="truncate">{invoice.provider_name}</p>
                </div>
                <div className="flex justify-start inline-block pl-0">
                  <p className="truncate">{invoice.client_name}</p>
                </div>
                <div className="flex justify-end inline-block pl-0">
                  <b>{dollarAmount(invoice.total_amount_invoiced, true)}</b>
                </div>
                <div
                  className="flex justify-center"
                >
                  <Icon
                    icon="IconCross"
                    size={10}
                    onClick={() => deselectInvoices(invoice.id)}
                    className="flex flex-col grow inline-block"
                  />
                </div>
              </div>
            </div>
          ))}
        {hasInvoicesAboveRemainingAuthorizedAmount && (
          <p className="text-red text-13px pl-5 mt-5">
            {canAcceptAllInvoices ?
              'The remaining authorized amount is less than the invoice amount for one or more invoices.' :
              'One or more of the invoices cannot be accepted because the remaining authorized amount is ' +
              'less than the invoice amount.'}
          </p>
        )}
      </div>
    </DialogV2>
  );
};
BulkActionsForm.propTypes = {
  close: PropTypes.func.isRequired,
  invoices: PropTypes.array.isRequired,
  networkId: PropTypes.string.isRequired,
  setRef: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  deselectInvoices: PropTypes.func.isRequired,
  getRemainingAuthorizedAmountPerInvoice: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  invoicedSpends: PropTypes.object.isRequired,
  loadingInvoicedSpends: PropTypes.bool.isRequired,
};

BulkActionsForm.defaultProps = {
  onSuccess: () => {},
};

BulkActionsForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const networkId = state.networks.networkId;
  return {
    networkId,
    invoicedSpends: get(state, 'invoicedSpend.invoicedSpends'),
    loadingInvoicedSpends: get(state, 'invoicedSpend.isFetching'),
  };
}

export { BulkActionsForm };
export default connect(
mapStateToProps,
  { getRemainingAuthorizedAmountPerInvoice },
)(
  BulkActionsForm,
);
