// returns an employee payload from userForm submit formValues for useUpdateEmployee and useCreateEmployee
import { isEmpty } from 'lodash';

export const getEmployeePayload = (providerId, userId, formValues) => {
  const request = {
    provider: providerId,
    first_name: formValues.first_name,
    last_name: formValues.last_name,
    email: formValues.email,
    work_title: formValues.work_title,
    user: userId,
    state: formValues.user_status.id,
    roles: [
      ...(formValues.user_primary_role ? [formValues.user_primary_role.id] : []),
      ...(formValues.user_feature_roles ?
        formValues.user_feature_roles.map((role) => role.id) :
        []),
    ],
    programs: [
      ...(formValues.user_programs ?
        formValues.user_programs.map((userProgram) => userProgram.id) :
        []),
    ],
  };

  const phone_numbers = formValues.phone_numbers.filter((phoneNumber) => !isEmpty(phoneNumber) &&
    !isEmpty(phoneNumber.phone_number));

  const addresses = [{
    line_1: formValues.line_1,
    line_2: formValues.line_2,
    city: formValues.city,
    county: formValues.county,
    state: formValues.state,
    postal_code: formValues.postal_code,
    country: formValues.country,
    latitude: formValues.latitude,
    longitude: formValues.longitude,
  }];

  if (phone_numbers.length) {
    request.phone_numbers = phone_numbers;
  }
  // Will send an address payload if any fields are present. Must be validated upstream.
  if (Object.values(addresses[0]).some((val) => Boolean(val))) {
    request.addresses = addresses;
  }

  return request;
};
