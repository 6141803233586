import {
  HAS_IN_AND_OON_GROUPS_MESSAGE,
  NO_IN_HAS_OON_GROUPS_MESSAGE,
  NO_IN_OR_OON_GROUPS_MESSAGE,
  NO_OON_GROUPS_MESSAGE,
  HAS_IN_AND_OON_PROGRAMS_MESSAGE,
} from '../constants';

function oonGroupHeaderMessage({ inNetworkGroupsEmpty, oonGroupsEmpty, isProgramBasedSearch }) {
  if (oonGroupsEmpty) {
    return inNetworkGroupsEmpty ?
      NO_IN_OR_OON_GROUPS_MESSAGE :
      NO_OON_GROUPS_MESSAGE;
  }
  if (isProgramBasedSearch) {
    return HAS_IN_AND_OON_PROGRAMS_MESSAGE;
  }
  return inNetworkGroupsEmpty ?
    NO_IN_HAS_OON_GROUPS_MESSAGE :
    HAS_IN_AND_OON_GROUPS_MESSAGE;
}

export default oonGroupHeaderMessage;
