import { FETCH_ASSESSMENT_FROM_REFERRAL } from 'actions';
import Notifier from 'common/helpers/Notifier';
import { coreApi } from 'src/api/config';

export default function fetchAssessmentFromReferral(groupId, referralId, formId) {
  return async (dispatch) => {
    try {
      const payload = await coreApi.findRecord('form', formId);
      dispatch({
        type: FETCH_ASSESSMENT_FROM_REFERRAL,
        payload,
      });
      return payload;
    } catch (error) {
      console.log(error);
      Notifier.handleErrors(error);
      return error;
    }
  };
}
