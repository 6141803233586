import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
} from '@unite-us/ui';
import { BackButton } from 'common/buttons';
import MilitaryInformation from 'src/components/Facesheet/Profile/MilitaryInformation';
import { fetchGroupContact, updateGroupContact } from 'actions/Contact/Group';
import 'src/components/Facesheet/Profile/stylesheets/profile.scss';
import { cleanMilitaryFields } from 'src/components/MilitaryInformation/utils';
import { get } from 'lodash';

import '../stylesheets/military-information.scss';

class ContactMilitaryInformation extends Component {
  constructor(props) {
    super(props);
    this.updateMilitaryInformation = this.updateMilitaryInformation.bind(this);
  }

  componentDidMount() {
    const {
      groupId,
      params: {
        id: contactId,
      },
    } = this.props;
    this.props.fetchGroupContact(groupId, contactId);
  }

  updateMilitaryInformation(data) {
    const {
      params: {
        id: contactId,
      },
    } = this.props;
    const cleanedData = cleanMilitaryFields(data);
    const contact = this.props.contacts.find((c) => c.id === contactId);
    this.props.updateGroupContact(this.props.groupId, contact.id, { military: cleanedData });
  }

  render() {
    const {
      enums,
      groupId,
      params: {
        id: contactId,
      },
    } = this.props;

    const contact = this.props.contacts.find((c) => c.id === contactId);

    if (!contact) { return null; }

    return (
      <section className="container contact-military-information" data-role="military-information">
        <BackButton />
        <BaseCard>
          <BaseCardHeader title={`${get(contact, 'full_name', '')}'s Military Information`} />
          <BaseCardBody>
            <MilitaryInformation
              ref={(el) => { this.militaryInformation = el; }}
              contact={contact}
              enums={enums}
              groupId={groupId}
              hideHeader
              updateMilitaryInformation={this.updateMilitaryInformation}
            />
          </BaseCardBody>
        </BaseCard>
      </section>
    );
  }
}

ContactMilitaryInformation.propTypes = {
  contacts: PropTypes.array.isRequired,
  enums: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  fetchGroupContact: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  updateGroupContact: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    contacts,
    session: {
      enums,
      groupId,
    },
  } = state;

  return {
    contacts: contacts.contacts,
    enums,
    groupId,
  };
}

export default connect(mapStateToProps, {
  fetchGroupContact,
  updateGroupContact,
})(ContactMilitaryInformation);
