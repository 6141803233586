import Notifier from 'common/helpers/Notifier';
import { ADD_ADDRESS_FIELD_TO_CONTACT } from 'actions';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import fetchGroupContact from 'src/actions/Contact/Group/fetchGroupContact';
import { coreApi } from 'src/api/config';
import { getGeocodedAddress } from 'src/components/Browse/Map/utils/geoCode';

const createContactAddress = (groupId, contactId, address) => (
  async (dispatch) => {
    try {
      const geocodedAddress = await getGeocodedAddress({ ...address, person: contactId });
      const response = await coreApi.createRecord('address', geocodedAddress);

      if (!response || !isHttpSuccess(response.status)) {
        Notifier.handleErrors(response);
        return response;
      }

      dispatch({
        type: ADD_ADDRESS_FIELD_TO_CONTACT,
        payload: response,
        contactId,
      });
      dispatch(fetchGroupContact(groupId, contactId));
      Notifier.dispatch(response.status, 'Address Successfully Added');
      return response;
    } catch (error) {
      Notifier.handleErrors(error);
      return false;
    }
  }
);

export default createContactAddress;
