import PropTypes from 'prop-types';
import React from 'react';
import { SelectField } from '@unite-us/ui';
import { validations } from '@unite-us/app-components';
import { caseTypeOptions } from 'src/components/Cases/utils';

const CaseTypeField = ({
  group,
  hasGroupLicense,
  hasNetworkLicense,
  registerField,
  ...props
}) => (
  <SelectField
    disabled={hasGroupLicense && !hasNetworkLicense}
    options={caseTypeOptions(group.name, hasNetworkLicense)}
    ref={registerField}
    {...props}
  />
);

CaseTypeField.propTypes = {
  autoSelectValue: PropTypes.bool,
  field: PropTypes.object.isRequired,
  group: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  hasGroupLicense: PropTypes.bool.isRequired,
  hasNetworkLicense: PropTypes.bool.isRequired,
  id: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  required: PropTypes.bool,
  searchEnabled: PropTypes.bool,
  untouch: PropTypes.func.isRequired,
  validations: PropTypes.func,
};

CaseTypeField.defaultProps = {
  autoSelectValue: true,
  id: 'case-type',
  inline: false,
  label: 'Case Type',
  labelKey: 'name',
  searchEnabled: false,
  validations: validations.isRequired,
  valueKey: 'type',
  required: true,
};

export default CaseTypeField;
