import {
  SET_PROFILE_FORMS_FETCHING,
  UNSET_PROFILE_FORMS_FETCHING,
  FETCH_PROFILE_FORMS,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import { coreApi } from 'src/api/config';

export default function fetchProfileForms(groupId) {
  return (dispatch) => {
    dispatch({ type: SET_PROFILE_FORMS_FETCHING });

    return coreApi.query('form_usages', {
      usage_type: 'profile',
      provider: groupId,
      'form_utilizer.state': 'active',
    })
      .then((payload) => {
        dispatch({
          type: FETCH_PROFILE_FORMS,
          profileForms: payload.data.data,
        });
        dispatch({ type: UNSET_PROFILE_FORMS_FETCHING });
        return payload;
      })
      .catch((error) => {
        Notifier.handleErrors(error);
        dispatch({ type: UNSET_PROFILE_FORMS_FETCHING });
      });
  };
}
