import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import fetchUserFromSessionId from 'common/utils/Session/actions/FetchUserFromSessionId';
import { isLoggedIn } from 'common/utils/utils';

export class ServerDisconnect extends React.Component {
  constructor(props) {
    super(props);
    this.handleTryAgainClick = this.handleTryAgainClick.bind(this);
  }

  handleTryAgainClick(e) {
    e.preventDefault();

    if (isLoggedIn()) {
      this.props.fetchUserFromSessionId({ retries: 1 });
    }
  }

  render() {
    return (
      <div id="serverDisconnect">
        <div className="message strong-text">
          <div className="strong-text">
            We can&#39;t reach the server &#58;&#40;&nbsp;&nbsp;
          </div>
          <div className="text">
            Please check your internet connection and then
          </div>
          <a
            className="strong-text"
            href=""
            role="button"
            onClick={(e) => this.handleTryAgainClick(e)}
          >
            TRY AGAIN
          </a>
        </div>
      </div>
    );
  }
}

ServerDisconnect.propTypes = {
  fetchUserFromSessionId: PropTypes.func,
};

export default connect(null, { fetchUserFromSessionId })(ServerDisconnect);
