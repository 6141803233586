import { remove, assign, uuOmit } from 'lodash';
import isReallyEmpty from './isReallyEmpty';

const sanitizeData = (data) => {
  const DEFAULT_COUNTRY = 'USA';
  let sanitized = assign({}, data);
  sanitized.email_addresses.unshift(sanitized.base_email_address);
  remove(sanitized.email_addresses, (obj) => isReallyEmpty(obj));
  sanitized = uuOmit(sanitized, ['base_email_address']);
  sanitized.addresses.forEach((value) => {
    const address = value;
    address.country = DEFAULT_COUNTRY;
  });
  // Remove all but digit characters in phone number
  sanitized.phone_numbers = data.phone_numbers?.map((number) => (
    {
      ...number,
      phone_number: number.phone_number.replace(/\D/g, ''),
    }
    ));
  return sanitized;
};

export default sanitizeData;
