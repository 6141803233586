import Notifier from 'common/helpers/Notifier';
import { apiV4Default } from 'src/api/config';
import {
  FETCH_PLAN_INFORMATION_STARTED,
  FETCH_PLAN_INFORMATION_SUCCESS,
  FETCH_PLAN_INFORMATION_ERROR,
} from 'src/actions/index';

export default function fetchInsurancePlanInformation(planId, contactId) {
  return (dispatch) => {
    const request = apiV4Default.get(`/plans/${planId}`);
    dispatch({ type: FETCH_PLAN_INFORMATION_STARTED });

    function onSuccess(payload) {
      dispatch({
        type: FETCH_PLAN_INFORMATION_SUCCESS,
        contactId,
        planId,
        payload,
      });
      return payload;
    }

    function onError(error) {
      dispatch({ type: FETCH_PLAN_INFORMATION_ERROR });
      Notifier.dispatch('error', 'Insurance plan information cannot be retrieved');
      return error;
    }

    return request.then(onSuccess, onError);
  };
}
