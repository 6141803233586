import React from 'react';
import PropTypes from 'prop-types';
import { Filter, filterServiceTypeOptions } from '@unite-us/client-utils';

const FilterServiceType = (props) => {
  const { options, onFiltersChange } = props;

  return (
    <div className="browse-filters-service-types">
      <Filter
        hideSelectAll
        name="Service Type"
        filterKey="serviceTypes"
        onFiltersChange={onFiltersChange}
        options={options}
        filterSearchOptions={filterServiceTypeOptions}
      />
    </div>
  );
};

FilterServiceType.propTypes = {
  options: PropTypes.array.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
};

export default FilterServiceType;
