import { UPDATE_USER } from 'actions';
import axios from 'axios';
import Notifier from 'common/helpers/Notifier';

const onSuccess = (dispatch) => (
  (payload) => {
    const email = payload.data.data.email;
    dispatch({
      type: UPDATE_USER,
      // Just pass the email to the reducer.
      payload: { data: { data: { email } } },
    });
    return email;
  }
);

const onError = () => (
  (error) => {
    Notifier.dispatch(
      'error',
      'An error occurred while getting the user\'s email address.',
    );
    return error;
  }
);

const fetchUserEmail = () => (
  (dispatch) => axios
    .get('/users/me')
    .then(onSuccess(dispatch), onError(dispatch))
);

export default fetchUserEmail;
