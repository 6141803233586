import PropTypes from 'prop-types';
import React from 'react';
import FileUpload from 'common/form/FileUpload';
import ConsentDropzoneMessage from './ConsentDropzoneMessage';

const ConsentFileUpload = ({ document, type }) => (
  <FileUpload
    id="consent-file-upload"
    name="Consent"
    file={document}
    className="dropzone"
  >
    <ConsentDropzoneMessage type={type} />
  </FileUpload>
);

ConsentFileUpload.propTypes = {
  document: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

ConsentFileUpload.defaultProps = {
  document: {
    onChange: () => (true),
  },
};

export default ConsentFileUpload;
