import Notifier from 'common/helpers/Notifier';
import {
  UPDATE_GROUP_CONTACT,
} from 'actions';
import { coreApi } from 'src/api/config';

export default function assignCareCoordinator(clientRelationshipId, careCoordinator, isCC, contactId, groupId, msg) {
  return async (dispatch) => {
    try {
      let response;
      if (clientRelationshipId) {
        response = await coreApi.updateRecord('client_relationship', clientRelationshipId, {
          care_coordinator: careCoordinator === 'none' ? null : careCoordinator,
        });
      } else if (isCC) {
       response = await coreApi.createRecord('client_relationship', {
          person: contactId,
          provider: groupId,
          care_coordinator: careCoordinator === 'none' ? null : careCoordinator,
        });
      }

      dispatch({
        type: UPDATE_GROUP_CONTACT,
        payload: response,
      });

      Notifier.dispatch(response.status, msg);

      return response;
    } catch (error) {
      return Notifier.handleErrors(error);
    }
  };
}
