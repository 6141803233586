import { uploadAndAttachDocumentsToReferral } from '.';

function attachReferralDocuments({
  groupId,
  referral = {},
  attachedDocuments,
  uploadedDocuments,
}) {
  return () => (
    uploadAndAttachDocumentsToReferral({
      groupId,
      referralId: referral.id,
      caseId: referral.case.id,
      attachedDocuments,
      uploadedDocuments,
    })
  );
}

export default attachReferralDocuments;
