import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@unite-us/ui';
// todo -- we eventually want to be able to store images in the repo instead of using s3
// there's currently an issue where deployed images return a 502 in devqa, though
// https://uniteus.slack.com/archives/C01SA95BLHG/p1693946690486859
// import BrowseMapPreview from './BrowseMapPreview.png'; // then set img src={BrowseMapPreview}

function AddProgramsSection({
  toggleBrowse,
  isFetchingNetworkGroups,
}) {
  return (
    <div className="w-full add-programs-section">
      <hr className="mb-5" />
      <header>
        <h2 className="mb-3 font-bold">Add Programs</h2>
        <p className="mb-5">Add programs using Advanced Search or Quick Add.</p>
      </header>
      <div className="flex justify-between mb-5">
        <div>
          <h4 className="font-bold mb-1">Advanced Search</h4>
          <p className="mb-4">Use Advanced Search to:</p>
          <ul className="mb-5 list-disc">
            <li>Find programs based on <b>your client&apos;s eligibility</b></li>
            <li>See the most <b>relevant</b> programs at the top of the list</li>
            <li>Add programs to your <b>favorites</b></li>
            <li>Review <b>detailed</b> program information</li>
            <li>Locate programs on a <b>map</b></li>
          </ul>
          <Button
            disabled={isFetchingNetworkGroups}
            onClick={() => toggleBrowse('in-network')}
            ariaHasPopUp="dialog"
            label="Open Advanced Search"
            primary
          />
        </div>
        <div>
          <img
            alt="Screenshot of new Browse Map window"
            src="https://s3.amazonaws.com/uniteus-io-assets/app-client/browse-map-preview.png"
            className="max-w-xs border-solid border border-black"
          />
        </div>
      </div>
      <div>
        {/* Universal text replacing IN + OON dropdown headers */}
        <h4 className="font-bold mb-2">Review Added Programs or Use Quick Add</h4>
        <p>
          Review programs you&apos;ve added or use the drop-down list, sorted by distance, to quickly add more programs.
        </p>
      </div>
    </div>
  );
}

AddProgramsSection.propTypes = {
  toggleBrowse: PropTypes.func.isRequired,
  isFetchingNetworkGroups: PropTypes.bool,
};

AddProgramsSection.defaultProps = {
  isFetchingNetworkGroups: false,
};

export default AddProgramsSection;
