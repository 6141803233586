import React from 'react';
import NoRequestsExistLogo from 'src/common/TailwindComponents/NoRequestsExistLogo';

const NoEnrollmentsExist = () => (
  <>
    <NoRequestsExistLogo />
    <h4 className="font-heavy-font text-base text-dark-grey">
      You don&apos;t have any enrollment requests yet.
    </h4>
    <p className="tracking-tight max-w-xs text-dark-grey">
      Requests for enrollment and disenrollment are added to this list whenever a
      community-based organization requests social care coverage for a client.
    </p>
  </>
);

export default NoEnrollmentsExist;
