import React from 'react';
import { get } from 'lodash';
import { Card, CardHeader } from 'common/Card';
import { LineItem } from 'common/display/LineItem';
import { usePopulate } from 'api/APIHooks';
import { useFeatureFlag } from 'common/hooks';
import PropTypes from 'prop-types';
import ToolTip from 'src/common/ToolTip';
import { epochToDate } from 'common/utils/utils';
import CaseStatus from './CaseStatus';

const ProviderList = ({ referrals }) => (
  <ul className="list-unstyled">
    {referrals.map((r) => (
      <li key={r.id}>{r.receiving_provider.name}</li>
    ))}
  </ul>
);

ProviderList.propTypes = {
  referrals: PropTypes.array.isRequired,
};

const ReferralsToolTip = ({ referrals }) => (
  <ToolTip
    enableHover
    buttonComponentContent={() => (
      <span>&hellip; + {referrals.length - 3} more</span>
    )}
    panelClassName="border bg-white border-solid border-gray-400
  rounded p-4 shadow-md normal-case"
    buttonClassName="px-2 focus:outline-none"
    panelComponentContent={
      () => <ProviderList referrals={referrals.slice(3)} />
    }
  />
);

ReferralsToolTip.propTypes = {
  referrals: PropTypes.array.isRequired,
};

const ReferralDetailsCard = ({ referrals, serviceAuthorization }) => {
  const refsLoaded = referrals.length > 0;
  const kase = get(serviceAuthorization, 'case', {});
  const isCaseLoaded = !!kase?.description;
  const hasPays3581Flag = useFeatureFlag('pays-3581-accepted-service-authorization-updates');
  const authorizationAcceptedOrDeferred =
    ['approved', 'deferred'].includes(serviceAuthorization.state) && kase.state !== 'pending_authorization';
  const referralAccepted = kase.state === 'managed';
  const caseOpenedDate = epochToDate(kase.opened_date);
  const caseClosedDate = epochToDate(kase.closed_date);
  usePopulate('provider', 'provider', kase, { queryConfig: { placeholderData: undefined } });
  const receivingProviderName = kase.provider.name;

  return (
    <Card className="bg-white" data-testid="referral-details-card">
      <CardHeader
        className="pt-2 pl-2 space-y-2"
        title="Referral"
      />
      <dl className="m-0 p-6 grid grid-cols-4 gap-y-2 gap-x-8">
        {hasPays3581Flag && authorizationAcceptedOrDeferred && (
          <LineItem
            field="Case Status"
            valueClassName="col-span-3 ml-2"
            isLoaded={refsLoaded}
            dataTestElement="referral-status"
          >
            <CaseStatus
              state={kase.state}
              openedDate={caseOpenedDate}
              closedDate={caseClosedDate}
              showStatusDot={false}
            />
          </LineItem>
        )}
        {hasPays3581Flag && referralAccepted && (
          <LineItem
            field="Accepted by"
            valueClassName="col-span-3 ml-2"
            isLoaded={refsLoaded}
            dataTestElement="referral-accepted-by"
          >
            {receivingProviderName}
          </LineItem>
        )}
        <LineItem
          field="Recipient Network"
          valueClassName="col-span-3 ml-2"
          isLoaded={refsLoaded}
          dataTestElement="referral-recipient-network"
        >
          {get(referrals, '[0].receiving_network.name')}
        </LineItem>
        <LineItem
          field="Sent to"
          valueClassName="col-span-3 ml-2"
          isLoaded={refsLoaded}
          dataTestElement="referral-recipient-organization"
        >
          {(hasPays3581Flag && authorizationAcceptedOrDeferred) ? (
            referrals.slice(0, 3).map((r) => (r.receiving_provider.name)).join(', ')
          ) : (
            <ProviderList referrals={referrals.slice(0, 3)} />
          )}
          {referrals.length > 3 && (
            <ReferralsToolTip referrals={referrals} />
          )}
        </LineItem>
        <LineItem
          field="Description"
          valueClassName="col-span-3 ml-2"
          isLoaded={isCaseLoaded}
          dataTestElement="referral-description"
        >
          {get(serviceAuthorization, 'case.description')}
        </LineItem>
      </dl>
    </Card>
  );
};

ReferralDetailsCard.propTypes = {
  referrals: PropTypes.array.isRequired,
  serviceAuthorization: PropTypes.object.isRequired,
};

export default ReferralDetailsCard;
