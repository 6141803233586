import { useContext } from 'react';
import { camelCase, get } from 'lodash';
import { FeatureFlagContext } from '../utils/FeatureFlags/FeatureFlagContainer';

const useFeatureFlag = (flagName) => {
  const flags = useContext(FeatureFlagContext);
  return get(flags, camelCase(flagName));
};

export default useFeatureFlag;
