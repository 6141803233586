import React from 'react';

export const caseStatuses = {
  draft: () => <span><span className="bg-yellow inline-block w-3 h-3 rounded-full mr-2" />Draft</span>,
  open: () => <span><span className="bg-dark-green inline-block w-3 h-3 rounded-full mr-2" />Open</span>,
  closed: () => <span><span className="bg-blue-700 inline-block w-3 h-3 rounded-full mr-2" />Closed</span>,
  off_platform: () => (
    <span><span className="bg-blue-700 inline-block w-3 h-3 rounded-full mr-2" />Out of Network</span>
  ),
};
