import qs from 'qs';

function removeEmptyQuery(filter) {
  const newFilter = {};
  if (filter) {
    Object.keys(filter).forEach((key) => {
      if (!filter[key] || filter[key] === '') { return; }
      newFilter[key] = filter[key];
    });
  }

  return newFilter;
}

export default function objectToQueryString(obj) {
  const { filter, page } = obj;
  const filters = removeEmptyQuery(filter);
  const pageFilters = removeEmptyQuery(page);
  const string = qs.stringify({
    ...obj,
    ...(filters ? { filter: filters } : {}),
    ...(pageFilters ? { page: pageFilters } : {}),
  });
  return string;
}
