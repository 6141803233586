import axios from 'axios';
import Notifier from 'common/helpers/Notifier';
import {
  FETCH_GROUP_FORMS,
  CLEAN_RETURNED_ASSESSMENTS,
  FETCH_REFERRAL_ASSESSMENTS,
  SET_GROUP_FORMS_FETCHING,
  UNSET_GROUP_FORMS_FETCHING,
} from 'actions';
import { dispatchNoScreeningFormWarning } from 'actions/Notifier';
import _ from 'lodash';

function fetchGroupForms(
  groupId,
  use_type = 'intake',
  service_type_ids = [],
  network_ids = [],
  programId,
  options = {},
) {
  const request = axios.get(
    `/groups/${groupId}/forms/usage`,
    {
      params: {
        use_type,
        network_ids,
        service_type_ids,
        program_id: programId,
        ...(options.contactId && { contact_id: options.contactId }),
      },
    },
  );

  return (dispatch) => {
    function onSuccess(payload) {
      dispatch({ type: FETCH_GROUP_FORMS, groupId, payload });
      dispatch({ type: CLEAN_RETURNED_ASSESSMENTS, groupId });
      dispatch({ type: UNSET_GROUP_FORMS_FETCHING });

      if (_.get(options, 'referralId', false)) {
        dispatch({
          type: FETCH_REFERRAL_ASSESSMENTS,
          id: options.referralId,
          payload: payload.data.data,
        });
      }

      if (use_type === 'screening' && _.isEmpty(_.get(payload, 'data.data'))) {
        dispatchNoScreeningFormWarning(dispatch);
      }

      return payload;
    }

    function onError(error) {
      dispatch({ type: UNSET_GROUP_FORMS_FETCHING });
      Notifier.handleErrors(error);

      return error;
    }

    dispatch({ type: SET_GROUP_FORMS_FETCHING });

    return request.then(onSuccess, onError);
  };
}

export default fetchGroupForms;
