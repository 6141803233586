import React from 'react';
import { PropTypes } from 'prop-types';
import { Spinner } from 'common/spinners';
import { useFindRecord, usePopulate, usePopulateMemo } from 'src/api/APIHooks';
import { ENROLLMENT_STATUSES } from '../../constants';
import { useSccRequiredInsurancePlan } from '../../hooks';
import ActionBar from './ActionBar';
import EnrollmentDetailsCard from './EnrollmentDetailsCard';
import RejectionDetailsCard from './RejectionDetailsCard';

const EnrollmentDetailPage = ({ params }) => {
  const {
    isLoading,
    data: response,
  } = useFindRecord('enrollment_request', params.id, {
    queryConfig: { placeholderData: undefined },
  });

  const enrollmentRequest = response?.data?.data ?? {};
  const plQuery = usePopulate('plan', 'plan', enrollmentRequest, {
    queryConfig: { placeholderData: undefined },
  });
  const enQuery = usePopulate(
    'enrollment_disenroll_reason',
    'enrollment_disenroll_reason',
    enrollmentRequest,
    { queryConfig: { placeholderData: undefined } },
  );
  const peQuery = usePopulate('person', 'person', enrollmentRequest, {
    queryConfig: { placeholderData: undefined },
  });
  const inQuery = usePopulate('insurance', 'insurance', enrollmentRequest, {
    queryConfig: { placeholderData: undefined },
  });
  const emQuery = usePopulate('requester', 'employee', enrollmentRequest, {
    queryConfig: { placeholderData: undefined },
  });
  const prQuery = usePopulate(
    'provider',
    'provider',
    enrollmentRequest.requester,
    { queryConfig: { placeholderData: undefined } },
  );
  const isMemoLoading = usePopulateMemo(response?.data, [plQuery, enQuery, peQuery, inQuery, emQuery, prQuery]);
  const { isLoading: isPrimaryInsuranceLoading, primaryInsurance } = useSccRequiredInsurancePlan(
    enrollmentRequest?.insurance?.primary_health_insurance?.id,
    enrollmentRequest?.person?.id,
    enrollmentRequest?.plan?.required_health_plans,
  );
  usePopulate('plan', 'plan', primaryInsurance, {
    queryConfig: {
      enabled: !isPrimaryInsuranceLoading,
      placeholderData: undefined,
    },
  });

  const isDataLoading = isLoading || isMemoLoading || isPrimaryInsuranceLoading;

  return (
    <>
      {isDataLoading ? (<Spinner />) : (
        <>
          <ActionBar enrollmentRequest={enrollmentRequest} />
          <div className="pt-4 flex-1 px-3 py-2 space-y-6">
            {enrollmentRequest?.enrollment_status === ENROLLMENT_STATUSES.rejected && (
              <RejectionDetailsCard enrollmentRequest={enrollmentRequest} />
            )}
            <EnrollmentDetailsCard
              enrollmentRequest={enrollmentRequest}
              primaryInsurance={primaryInsurance}
            />
          </div>
        </>
      )}
    </>
  );
};

EnrollmentDetailPage.propTypes = {
  params: PropTypes.object.isRequired,
};

export default EnrollmentDetailPage;
