export const CONSENT_COMPLETED = 'consent-completed';
export const CONSENT_STATUS_REJECTED = 'declined';

export const CONSENT_OPTIONS = {
  AUDIO: 'audio',
  DOCUMENT: 'document',
  EMAIL_ADDRESS: 'email_address',
  ON_SCREEN: 'on_screen',
  ON_SCREEN_ATTESTATION: 'user_attestation',
  PHONE_NUMBER: 'phone_number',
  SELECTED_CONSENT_TYPE: 'selectedConsentType',
  LANGUAGE: 'language',
};

export const DIGITAL_CONSENT_TYPES = [
  CONSENT_OPTIONS.PHONE_NUMBER,
  CONSENT_OPTIONS.EMAIL_ADDRESS,
  CONSENT_OPTIONS.ON_SCREEN,
];

export const CONSENT_TYPE_PLATFORM_SERVICES = 'platform-services';
export const CONSENT_TYPE_LINK_AGREEMENT = 'link-agreement';

export const CONSENT_FORM_HEADER_MESSAGE = `If you will be servicing this
  client or sending referrals on their behalf, you will need to obtain their consent
  to do so.  You may request consent from the client via email or SMS, or you
  may upload a signed consent document below`;

export const CONSENT_LINK_FORM_HEADER_MESSAGE = `You must obtain an agreement to
 link this family member’s records on your client’s face sheet. You may request
 linkage via email or SMS, or you may upload a signed agreement document below.`;

export const CHOOSE_PREFERRED_LANGUAGE_MESSAGE = 'Please choose your client\'s preferred language';
