export const REFERRAL_DOCUMENT_UPLOAD = 'referral-document-upload';
export const DRAFT_REFERRAL_EDIT_FORM = 'draftReferralEdit';
export const CREATE_REFERRAL_FORM = 'create-referral-form';
export const CLOSE_REFERRAL_FORM = 'closeReferralForm';
export const CLOSE_REFERRAL_FIELDS = [
  'outcome_id',
  'resolved',
  'note',
];
export const HOLD_REFERRAL_FORM = 'holdReferralForm';
export const RECALL_REFERRAL_FORM = 'recallReferralForm';

export const REFERRAL_STATUS_ACCEPTED = 'accepted';
export const REFERRAL_STATUS_AUTO_RECALLED = 'auto_recalled';
export const REFERRAL_STATUS_CLOSED = 'closed';
export const REFERRAL_STATUS_DECLINED = 'declined';
export const REFERRAL_STATUS_DRAFT = 'draft';
export const REFERRAL_STATUS_FORWARDED = 'forwarded';
export const REFERRAL_STATUS_IN_REVIEW = 'in_review';
export const REFERRAL_STATUS_PENDING = 'sent';
export const REFERRAL_STATUS_PENDING_CONSENT = 'pending_consent';
export const REFERRAL_STATUS_PROVIDER_TO_PROVIDER = 'provider_to_provider';
export const REFERRAL_STATUS_RECALLED = 'recalled';

export const REFERRED_OUT_OF_NETWORK = 'Referred Out of Network';

export const AUTO_RECALLABLE_REFERRAL_TOOLTIP_TEXT =
  'This referral was sent to a group of recipients.\nThe first recipient to accept it, keeps it.';
export const INTERNETWORK_REFERRAL_TOOLTIP_TEXT = 'This referral originated from another network.';

export const PROVIDER_TO_PROVIDER_REFERRAL_BASE_ROUTE = 'open-channel';
export const SENT_REFERRAL_BASE_ROUTE = 'sent';
