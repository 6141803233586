import _ from 'lodash';

export default function getStepIndex(path) {
  if (_.isEmpty(path)) {
    return 0;
  }

  const viewFromPath = path.replace(/\/(\S+)\//g, '');

  switch (viewFromPath) {
    case 'add-case-details':
      return 0;

    case 'add-case-assessments':
      return 1;

    case 'review':
      return 2;

    default:
      return 0;
  }
}
