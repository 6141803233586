import { coreApi } from 'src/api/config';
import { SUBMIT_REFERRAL } from 'actions';
import Notifier from 'common/helpers/Notifier';
import { setDashboardRefetch } from 'actions/Dashboard';
import coreErrorHandler from 'components/ServiceAuthorization/coreErrorHandler';
import { get } from 'lodash';

const updateReferralState = (referral) => {
  const referralResponse = coreApi.updateRecord('referral', referral.id, {
    state: 'sent',
    receiving_program: get(referral, 'receiving_program.id', null),
  });

  return referralResponse;
};

const updateAuthorizationState = (serviceAuthorization) => {
  const authorizationResponse = coreApi.updateRecord('service_authorization', serviceAuthorization.id, {
    state: 'requested',
  });

  return authorizationResponse;
};

const createReferralFromDraft = (serviceCase, refetch = false) => (
  async (dispatch) => {
    try {
      const promisesList = [];
      let payload = [];
      let payloadObj = {};

      if (serviceCase.service_authorization) {
        promisesList.push(updateAuthorizationState(serviceCase.service_authorization));
      } else {
        serviceCase.referrals.map((referral) => promisesList.push(updateReferralState(referral)));
      }

      const sentReferrals = await Promise.all(promisesList);
      payload = sentReferrals.map((s) => (
        { ...s.data.data, contact: serviceCase.person, status: 'sent' }
      ));
      payloadObj = {
        data: {
          data: [
            ...payload,
          ],
        },
      };

      dispatch({
        type: SUBMIT_REFERRAL,
        payload: payloadObj,
        contactId: serviceCase.person.id,
      });
      Notifier.dispatch(200, `Referral${payload.length > 1 ? 's' : ''} Successfully Sent Out`);

      if (refetch) {
        dispatch(setDashboardRefetch());
      }
      return payload;
    } catch (error) {
      coreErrorHandler(error, serviceCase?.service_authorization?.insurance?.plan?.name);
      return error;
    }
  }
);

export default createReferralFromDraft;
