import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchBarResults from './SearchBarResults';

class SearchBarOverlay extends Component {
  constructor(props) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  handleClickOutside(event) {
    const isSearchOverlay = event.target.classList.contains('search-overlay');

    if (isSearchOverlay) {
      this.context.closeSearchResults();
    }
  }

  render() {
    const {
      showResultsDropdown,
    } = this.props;

    return (
      <div className="search-overlay">
        {
          showResultsDropdown &&
            <SearchBarResults />
        }
      </div>
    );
  }
}

SearchBarOverlay.propTypes = {
  showResultsDropdown: PropTypes.bool.isRequired,
};

SearchBarOverlay.contextTypes = {
  closeSearchResults: PropTypes.func.isRequired,
};

export default SearchBarOverlay;
