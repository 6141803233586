import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'common/utils/browserHistory';
import _ from 'lodash';
import { tracker } from '@unite-us/client-utils';
import ClosedIndex from 'src/components/Dashboard/components/AssistanceRequests/ClosedIndex';
import DetailView from 'src/components/Dashboard/components/DetailView';
import IndexDetailView from 'src/components/Dashboard/components/IndexDetailView';
import { fetchDashboardAssistanceRequests } from 'actions/AssistanceRequest/Group';
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';
import { getDataOfPage, isDataValid } from 'src/components/Dashboard/utils/dataHelpers';
import AssistanceRequestDetail from 'src/components/AssistanceRequests/components/AssistanceRequestDetail';
import callOrLog from 'src/common/utils/callOrLog';
import { careCoordinatorsFilter } from 'src/components/Dashboard/utils/filter';
import { noneAssignedOption } from 'src/components/Dashboard/utils/filterDefaults';
import { parentsOnlyServiceTypesFilter } from 'src/components/Dashboard/utils/filter/serviceTypesFilter';
import { DASHBOARD_VIEWS, DASHBOARD_EVENTS } from 'common/utils/EventTracker/utils/eventConstants';
import { getGroupUsersOptions } from 'src/components/Groups/Users/utils';
import labelCustomization from 'src/common/utils/Customization/labels';
import defaultLabels from 'src/constants/labels';
import { crtb1290SortReferralsByOldest } from 'src/common/utils/FeatureFlags/flags';

export class Closed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeUsersInGroup: [],
      page: 1,
      sidx: 'created_at',
      sord: 'desc',
      filters: {
        service_types: _.get(props, 'filters.service_types', []),
        resolved: _.get(props, 'filters.resolved', 'all'),
      },
    };

    this.fetch = this.fetch.bind(this);
    this.fetchGroupsUsers = this.fetchGroupsUsers.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.onFiltersChange = this.onFiltersChange.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
  }

  componentDidMount() {
    this.fetch();
    this.props.fetchProvidersUserCore({
      providers: this.props.groupId,
    }).then((response) => {
      if (response) {
        const { employees: activeUsersInGroup } = response;
        this.setState({ activeUsersInGroup });
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.get(this.props, 'assistanceRequests.currentPage', 1) !==
      _.get(nextProps, 'assistanceRequests.currentPage', 1)) {
      this.setState({ page: nextProps.assistanceRequests.currentPage });
    }

    if (!this.props.refetch && nextProps.refetch) {
      this.fetch();
    }
  }

  onCellClick(row) {
    const pagedData = getDataOfPage(this.props.assistanceRequests, this.state.page, this.props.sortAscending);
    const assistanceRequest = pagedData.data[row];

    callOrLog(() => this.context.eventTracker(DASHBOARD_EVENTS.closedAssistanceRequestsRow, {
      view: DASHBOARD_VIEWS.closedAssistanceRequests,
      resolved: _.get(assistanceRequest, 'closing.resolved'),
    }, { assistanceRequest }));

    browserHistory.push(`/dashboard/assistance-requests/closed/${assistanceRequest.id}`);
  }

  onFiltersChange(key, activeFilters, callbacks) {
    let newFilters = activeFilters;
    if (_.isEqual(key, 'care_coordinator_users')) {
      newFilters = careCoordinatorsFilter(activeFilters, callbacks);
    }
    this.setState({
      page: 1,
      filters: { ...this.state.filters, [key]: newFilters },
    }, () => {
      callOrLog(() => {
        this.context.eventTracker(
          DASHBOARD_EVENTS.closedAssistanceRequestsFilter,
          tracker.buildDashboardFilterPayload(this.state, this.props),
        );
      });

      this.fetch();
    });
  }

  onNextClick() {
    const page = this.state.page + 1;

    this.shouldFetch(page);
  }

  onPrevClick() {
    const page = this.state.page - 1;

    this.shouldFetch(page);
  }

  fetch(page = 1) {
    const { sidx, sord, filters } = this.state;

    this.props.fetchDashboardAssistanceRequests(
      this.props.groupId,
      {
        target: 'closedAssistanceRequests',
        sidx,
        sord,
        page,
        filters: {
          ...filters,
          statuses: ['closed'],
        },
      },
    );
  }

  shouldFetch(page) {
    if (isDataValid(this.props.assistanceRequests, page)) {
      this.setState({ page });
    } else {
      this.fetch(page);
    }
  }

  fetchGroupsUsers(search = '') {
    const { groupId } = this.props;
    return this.props.fetchProvidersUserCore({
      providers: groupId,
      options: {
        text: search,
        licensed: false,
      },
    }).then((response) => {
      if (response) {
        const { employees: activeUsersInGroup } = response;
        if (search === '') {
          return _.concat(
            [noneAssignedOption(this.state)],
            getGroupUsersOptions(activeUsersInGroup, this.state),
          );
        }

        return getGroupUsersOptions(activeUsersInGroup, this.state);
      }

      return [];
    });
  }

  render() {
    const {
      activeUsersInGroup,
      page,
      sidx,
      sord,
    } = this.state;
    const {
      assistanceRequests,
      isFetching,
      params,
      serviceTypes,
      labels,
    } = this.props;

    let filters = [
      parentsOnlyServiceTypesFilter(serviceTypes, this.state.filters.service_types),
      {
        key: 'resolved',
        name: 'Resolution',
        single: true,
        options: [
          { label: 'Resolution (All)', value: 'all' },
          { label: 'Resolved', value: 'true' },
          { label: 'Unresolved', value: 'false' },
        ],
        value: this.state.filters.resolved,
      },
    ];
    filters = _.concat(filters, [
      {
        key: 'care_coordinator_users',
        name: labels.CareCoordinator,
        pluralName: `${labels.CareCoordinator}s`,
        options: _.concat(
          [noneAssignedOption(this.state)],
          getGroupUsersOptions(activeUsersInGroup, this.state),
        ),
      }]);

    const newParams = { ...params, assistance_request_id: params.id };
    const pagedData = getDataOfPage(assistanceRequests, page, this.props.sortAscending);

    const detailView = (
      <DetailView
        innerContentView={
          <AssistanceRequestDetail params={newParams} />
        }
        hideMessageCenterButton
      />
    );

    return (
      <IndexDetailView
        data={pagedData.data}
        DetailView={detailView}
        IndexView={ClosedIndex}
        isFetching={isFetching}
        onCellClick={this.onCellClick}
        onNextClick={this.onNextClick}
        onPrevClick={this.onPrevClick}
        paging={pagedData.paging}
        params={params}
        filters={_.sortBy(filters, 'name')}
        initialSort={{ [sidx]: sord }}
        onFiltersChange={this.onFiltersChange}
        fetchGroupsUsers={this.fetchGroupsUsers}
      />
    );
  }
}

Closed.propTypes = {
  assistanceRequests: PropTypes.shape({
    data: PropTypes.array,
    currentPage: PropTypes.number,
  }).isRequired,
  fetchDashboardAssistanceRequests: PropTypes.func.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  refetch: PropTypes.bool.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  labels: PropTypes.object,
  sortAscending: PropTypes.bool,
};

Closed.defaultProps = {
  labels: defaultLabels,
  sortAscending: false,
};

Closed.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const assistanceRequests = _.get(state, 'dashboard.closedAssistanceRequests', {});
  const userId = _.get(state, 'user.id');
  const groupId = _.get(state, 'session.groupId');
  const isFetching = _.get(state, 'dashboard.closedAssistanceRequests.isFetching', false);
  const refetch = _.get(state, 'dashboard.refetch');
  const token = _.get(state, 'session.token', '');
  const isCC = _.wget(state, 'session.isCoordinationGroup', false);
  const serviceTypes = _.wget(state, isCC ? 'serviceTypes.network' : 'serviceTypes.allPrograms', []);

  return {
    assistanceRequests,
    userId,
    groupId,
    isFetching,
    refetch,
    token,
    serviceTypes,
    labels: labelCustomization(state),
    sortAscending: crtb1290SortReferralsByOldest(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ fetchDashboardAssistanceRequests, fetchProvidersUserCore }, dispatch),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Closed);
