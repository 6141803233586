export const getProgramServices = ({ allServices, programServiceIds }) => {
  // create a flat array of program services
  const flatProgramServices = programServiceIds.reduce(
    (acc, programServiceId) => {
      const fullService = allServices.find(
        (service) => (service?.id === programServiceId?.id),
      );
      if (fullService) { acc.push(fullService); }
      return acc;
    },
    [],
  );
  // reduce services to parent child hierarchy
  const servicesWithChildren = flatProgramServices.reduce((acc, fpService, index, array) => {
    if (fpService && !fpService.parent) {
      acc.push({ ...fpService, children: array.filter((fps) => fps?.parent?.id === fpService?.id) });
    }
    return acc;
  }, []);

  return servicesWithChildren;
};
