import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@unite-us/ui';
import { validateReduxForm } from 'common/form';
import _ from 'lodash';
import { OverlaySpinner } from 'common/spinners';
import { EDIT_PHONE_NUMBERS_GROUP } from 'common/display/Profile/constants/form';
import { ProfilePhoneFields } from 'common/form/Profile';
import { FACESHEET } from 'common/utils/EventTracker/utils/eventConstants';
import callOrLog from 'src/common/utils/callOrLog';
import { cleanPhoneValues } from 'common/form/Profile/utils/cleanContactValues';
import { generateUUID } from 'common/utils/utils';

import { phonesInitialValues } from './utils';
import './stylesheets/editPhoneNumbers.scss';

export class EditPhoneNumbersGroup extends Component {
  static initializeIds(list) {
    return _.map(list, (phone) => ({ ...phone, id: generateUUID() }));
  }

  constructor(props) {
    super(props);

    this.state = {
      newIds: [],
      deleting: false,
    };

    this.addField = this.addField.bind(this);
    this.initializeForm = this.initializeForm.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onPrimaryChange = this.onPrimaryChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.removeField = this.removeField.bind(this);
    this.removeOrDelete = this.removeOrDelete.bind(this);
    this.removeUnsavedPhones = this.removeUnsavedPhones.bind(this);
    this.resetAndCloseModal = this.resetAndCloseModal.bind(this);
  }

  componentDidMount() {
    this.initializeForm();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.modalIsOpen && !nextProps.modalIsOpen) {
      this.removeUnsavedPhones();
    }
  }

  onCancel() {
    this.removeUnsavedPhones();
    this.props.closeModal();
  }

  onSubmit({ phones }) {
    const phonesArr = phones.map(cleanPhoneValues);

    // if there are no changed phones, don't track the update
    if (!_.isEmpty(phonesArr)) {
      callOrLog(() => this.context.eventTracker(FACESHEET.phoneUpdated));
    }

    // we are going to return this even if it is empty, it will close the modal, and the prop function
    // will determine what to do with the empty array of numbers
    return this.props.onSave(phonesArr)
      .then(() => {
        this.resetAndCloseModal();
      });
  }

  onDelete(phones, idToRemove) {
    const phonesArr = phones.map(cleanPhoneValues);
    // if there are no changed phones, don't track the update
    if (!_.isEmpty(phonesArr)) {
      callOrLog(() => this.context.eventTracker(FACESHEET.phoneUpdated));
    }

    return this.props.onSave(phonesArr)
      .then(() => {
        const updatedStateIds = _.filter(this.state.newIds, (item) => item.id !== idToRemove);
        const index = _.findIndex(this.props.fields.phones, (e) => e.id.value === idToRemove);
        this.props.fields.phones.removeField(index);
        this.setState({
          newIds: updatedStateIds,
          deleting: false,
        });
      });
  }

  onPrimaryChange(phone) {
    _.each(this.props.fields.phones, (field) => {
      if (field !== phone) {
        field.is_primary.onChange(false);
      }
    });
    phone.is_primary.onChange(true);
  }

  initializeForm() {
    const { numbers } = this.props;
    const result = phonesInitialValues(numbers);

    const fieldPhones = EditPhoneNumbersGroup.initializeIds(result);

    this.props.initializeForm({
      phones: fieldPhones,
    });
  }

  addField() {
    const { phones } = this.props.fields;
    const id = generateUUID();
    phones.addField({ id });

    this.setState({
      newIds: [...this.state.newIds, id],
    });
  }

  removeField(phones, idToRemove) {
    const index = _.findIndex(phones, (e) => e.id.value === idToRemove);
    phones.removeField(index);
    this.setState({
      newIds: _.filter(this.state.newIds, (id) => id !== idToRemove),
    });
  }

  removeOrDelete(idToRemove) {
    const { fields: { phones } } = this.props;

    if (_.includes(this.state.newIds, idToRemove)) {
      this.removeField(phones, idToRemove);
    } else {
      this.setState({ deleting: true }, () => {
        const filteredPhones = _.filter(phones, (phone) => phone.id.value !== idToRemove &&
          !_.includes(this.state.newIds, phone.id.value));
        const phoneValues = _.map(filteredPhones, (phone) => ({
          country_code: phone.country_code.value,
          is_primary: phone.is_primary.value,
          phone_number: phone.phone_number.value,
          phone_type: phone.phone_type.value,
        }));
        this.onDelete(phoneValues, idToRemove);
      });
    }
  }

  removeUnsavedPhones() {
    const { phones } = this.props.fields;

    _.each(this.state.newIds, () => {
      phones.removeField();
    });

    this.setState({ newIds: [] });
  }

  resetAndCloseModal() {
    this.initializeForm();
    this.setState({ newIds: [] });
    this.props.closeModal();
  }

  render() {
    const {
      fields: { phones },
      handleSubmit,
      hideCommunicationPreferences,
      phoneFor,
      phoneTypes,
      registerField,
      styles,
      submitting,
    } = this.props;

    const { deleting } = this.state;

    const phoneForms = phones.map((phone, index) => (
      <div
        id={`inline-phone-${index}`}
        key={`${_.get(phone, 'id.value')}`}
        style={index !== phones.length - 1 ? styles.inlinePhoneContainer : {}}
      >
        <ProfilePhoneFields
          fields={phone}
          hideCommunicationPreferences={hideCommunicationPreferences}
          inline
          indexKey={index}
          onPrimaryChange={this.onPrimaryChange}
          onRemove={this.removeOrDelete}
          phoneFor={phoneFor}
          phoneTypes={phoneTypes}
          registerField={registerField}
        />
      </div>
    ));

    return (
      <form
        className="edit-phone-numbers content-with-actions"
        onSubmit={handleSubmit(this.onSubmit)}
      >
        <OverlaySpinner text="Saving..." show={submitting || deleting} />
        <div className="content-container">
          {phoneForms}
        </div>
        <div className="actions">
          <div className="row">
            <div className="col-sm-6 text-left edit-phone-numbers__add-phone">
              <a
                id="add-phone-link"
                role="button"
                onClick={this.addField}
              >
                + Add Phone Number
              </a>
            </div>
            <div className="col-sm-6">
              <span className="action-item">
                <Button
                  id="edit-phone-number-cancel-btn"
                  onClick={this.onCancel}
                  disabled={submitting}
                  label="Cancel"
                />
              </span>
              <span className="action-item">
                <Button
                  id="edit-phone-number-save-btn"
                  onClick={handleSubmit(this.onSubmit)}
                  disabled={submitting}
                  label="Save"
                  primary
                />
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

EditPhoneNumbersGroup.propTypes = {
  closeModal: PropTypes.func,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hideCommunicationPreferences: PropTypes.bool,
  initializeForm: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  numbers: PropTypes.array.isRequired,
  onSave: PropTypes.func,
  phoneFor: PropTypes.oneOf(['contact', 'group']),
  phoneTypes: PropTypes.array.isRequired,
  registerField: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    inlinePhoneContainer: PropTypes.object,
  }),
  submitting: PropTypes.bool.isRequired,
};

EditPhoneNumbersGroup.defaultProps = {
  closeModal: _.noop,
  hideCommunicationPreferences: false,
  onSave: _.noop,
  phoneFor: 'contact',
  styles: {
    inlinePhoneContainer: {
      borderBottom: '1px solid #EBECED',
      marginBottom: '20px',
      paddingBottom: '10px',
    },
  },
};

EditPhoneNumbersGroup.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { numbers } = ownProps;

  return {
    groupId: state.session.groupId,
    numbers,
  };
}

const fields = [
  'phones[].id',
  'phones[].country_code',
  'phones[].is_primary',
  'phones[].phone_number',
  'phones[].phone_type',
  'phones[].extension',
];
export default validateReduxForm(
  {
    form: EDIT_PHONE_NUMBERS_GROUP,
    fields,
  },
  mapStateToProps,
)(EditPhoneNumbersGroup);
