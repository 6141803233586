import {
  INIT_FILE_UPLOAD,
  DESTROY_FILE_UPLOAD,
  FILE_UPLOAD_ADD,
  CLEAR_FILE_UPLOAD,
  DESTROY_ALL_FILES_UPLOADS,
} from 'actions';
import _ from 'lodash';

const defaultState = {};

function initDropzone(state, config) {
  if (!_.isUndefined(state[config.name])) {
    return state;
  }
  const dzState = {};
  dzState[config.name] = {
    multiple: config.multiple,
    files: [],
  };
  return dzState;
}

function destroyDropzone(state, name) {
  if (_.isUndefined(state[name])) {
    return state;
  }
  return _.uuOmit(state, name);
}

function addFile(state, name, file, multipleDocForm, docIndex) {
  if (_.isUndefined(state[name])) {
    throw new Error('FileUpload has to be initialized.');
  }

  const currentFile = file;

  const dzState = { ...state };
  if (dzState[name].multiple) {
    dzState[name].files.push(currentFile);
  } else if (multipleDocForm) {
    currentFile.docIndex = docIndex;
    dzState[name].files.push(currentFile);
  } else {
    dzState[name].files[0] = currentFile;
  }
  return dzState;
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case INIT_FILE_UPLOAD:
      return { ...state, ...initDropzone(state, action.payload) };
    case DESTROY_FILE_UPLOAD:
      return { ...destroyDropzone(state, action.payload) };
    case DESTROY_ALL_FILES_UPLOADS:
      return defaultState;
    case FILE_UPLOAD_ADD:
      return { ...state, ...addFile(state, action.name, action.payload, action.multipleDocForm, action.docIndex) };
    case CLEAR_FILE_UPLOAD:
      return { ...state, [action.name]: { ...state[action.name], files: [] } };
    default:
      return state;
  }
}
