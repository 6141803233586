import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Tabs, Tab } from '@unite-us/app-components';
import { browserHistory } from 'common/utils/browserHistory';
import FeeScheduleScreeningSummary from './FeeScheduleScreeningSummary';

const FSS_TABS = ['overview', 'cbo-table', 'programs'];

const urlMappings = (feeScheduleId, feeScheduleScreeningId) => {
  const baseUrl = `/backoffice/fee_schedules/${feeScheduleId}/fee-schedule-screenings/${feeScheduleScreeningId}`;

  return {
    overview: `${baseUrl}`,
    programs: `${baseUrl}/programs`,
    'cbo-table': `${baseUrl}/cbo-table`,
  };
};

const FeeScheduleScreeningSubHeader = ({ feeSchedule, feeScheduleScreening }) => {
  const initialLocation = () => {
    const path = window.location.pathname.split('/').pop();
    return FSS_TABS.includes(path) ? path : 'overview';
  };
  const [location, setLocation] = useState(initialLocation);
  const handleTabChange = (value) => {
    setLocation(value);
    browserHistory.push(urlMappings(feeSchedule.id, feeScheduleScreening.id)[value]);
  };

  if (!feeScheduleScreening) return ('FeeScheduleScreening not found');
  return (
    <div className="px-6 pt-5">
      <FeeScheduleScreeningSummary feeScheduleId={feeSchedule.id} feeScheduleScreening={feeScheduleScreening} />
      <Tabs
        className="mt-5"
        onChange={handleTabChange}
        value={location}
      >
        <Tab label="Overview" value="overview" onChange={noop} />
        <Tab label="CBOs" value="cbo-table" onChange={noop} />
        <Tab label="Programs" value="programs" onChange={noop} />
      </Tabs>
    </div>
  );
};

FeeScheduleScreeningSubHeader.propTypes = {
  feeSchedule: PropTypes.object.isRequired,
  feeScheduleScreening: PropTypes.object.isRequired,
};

export default FeeScheduleScreeningSubHeader;
