import { apiDefault } from 'src/api/config';

export default function fetchCoreFeeScheduleProgram(feeScheduleProgramId) {
  const request = apiDefault.get(`/fee_schedule_programs/${feeScheduleProgramId}`);

  function onSuccess(payload) {
    return payload.data.data;
  }

  function onError(error) {
    return error;
  }

  return request.then(onSuccess, onError);
}
