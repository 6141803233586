import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import d3 from 'd3';
import nv from 'nvd3';
import {
  BaseCard,
  BaseCardBody,
} from '@unite-us/ui';

import {
  ChartTitle,
  DataTable,
} from 'common/charts';
import { formatData, pickColors, theme } from '../utils';

class ChartHorizontalMultiBar extends Component {
  constructor(props) {
    super(props);

    this.buildHorizontalBarChart = this.buildHorizontalBarChart.bind(this);
    this.compileChart = this.compileChart.bind(this);
    this.onViewToggle = this.onViewToggle.bind(this);
    this.refreshChart = this.refreshChart.bind(this);

    this.state = {
      chart: null,
      chartData: null,
      id: _.uniqueId('horizontal-chart-'),
      showTable: props.defaultShowTable,
    };
  }

  componentDidMount() {
    this.buildHorizontalBarChart();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.chartData && nextProps.data !== this.props.data) {
      const data = formatData(nextProps.data, undefined, undefined, nextProps.colors);
      this.state.chart.color(pickColors(theme.colors, data.length));
      this.state.chartData.datum(data);
      this.refreshChart();
    }

    if (this.state.chartData && nextProps.style !== this.props.style) {
      this.refreshChart();
    }
  }

  onViewToggle() {
    this.setState({ showTable: !this.state.showTable }, () => {
      this.refreshChart();
    });
  }

  compileChart() {
    const {
      props,
    } = this;
    const data = formatData(props.data, undefined, undefined, props.colors);
    const chart = nv.models.multiBarHorizontalChart()
      .x(props.x)
      .y(props.y)
      .color(pickColors(theme.colors, data.length))
      .noData(theme.noData)
      .margin(_.merge(theme.horizontalBar.margin, props.margin))
      .valueFormat(d3.format(props.valueFormat))
      .duration(theme.animation)
      .controlLabels(props.controlLabels)
      .groupSpacing(props.groupSpacing)
      .showControls(props.showControls)
      .showLegend(props.showLegend)
      .showValues(props.showValues)
      .showYAxis(props.showYAxis)
      .showXAxis(props.showXAxis)
      .stacked(props.stacked);
    chart.tooltip.enabled(props.enableTooltip);
    chart.tooltip.contentGenerator(props.tooltipGenerator);

    chart.yAxis.tickPadding(theme.axis.tickPadding).tickFormat(d3.format('d'));
    chart.xAxis.tickPadding(theme.axis.tickPadding);

    chart.legend.maxKeyLength(props.maxKeyLength);

    chart.multibar.dispatch.on('elementClick', props.onElementClick);
    chart.multibar.dispatch.on('elementDblClick', props.onElementDoubleClick);

    const chartData = d3.select(`#${this.state.id} svg`)
      .datum(data);

    chartData.call(chart);

    nv.utils.windowResize(chart.update);

    return { chart, chartData };
  }

  buildHorizontalBarChart() {
    nv.addGraph(this.compileChart, (result) => this.setState(result));
  }

  refreshChart() {
    if (this.state.chartData && this.state.chart) {
      this.state.chartData.call(this.state.chart);
    }
  }

  render() {
    const {
      colWidths,
      data,
      enableTableView,
      showControls,
      showLegend,
      showYAxis,
      style,
      subTitle,
      title,
      x,
      xLabel,
      y,
      yLabel,
    } = this.props;

    const charClass = () => classNames({
      'chart-horizontal': true,
      'hide-controls': !showControls,
      'hide-legend': !showLegend,
      'hide-y-axis': !showYAxis,
      hidden: this.state.showTable,
    });

    return (
      <BaseCard className="chart">
        <ChartTitle
          enableTableView={enableTableView}
          onViewToggle={this.onViewToggle}
          showTable={this.state.showTable}
          subTitle={subTitle}
          title={title}
        />
        <BaseCardBody>
          <div
            className={charClass()}
            id={this.state.id}
            style={style}
          >
            <svg />
          </div>
          {
            this.state.showTable && (
              <DataTable
                colWidths={colWidths}
                data={data}
                showPercent={false}
                x={x}
                xKey="title"
                xLabel={xLabel}
                y={y}
                yKey="total"
                yLabel={yLabel}
              />
            )
          }
        </BaseCardBody>
      </BaseCard>
    );
  }
}

ChartHorizontalMultiBar.propTypes = {
  colors: PropTypes.array,
  colWidths: PropTypes.object,
  controlLabels: PropTypes.object.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  defaultShowTable: PropTypes.bool.isRequired,
  enableTableView: PropTypes.bool.isRequired,
  enableTooltip: PropTypes.bool.isRequired,
  groupSpacing: PropTypes.number.isRequired,
  margin: PropTypes.object.isRequired,
  maxKeyLength: PropTypes.number.isRequired,
  onElementClick: PropTypes.func,
  onElementDoubleClick: PropTypes.func,
  showControls: PropTypes.bool.isRequired,
  showLegend: PropTypes.bool.isRequired,
  showValues: PropTypes.bool.isRequired,
  showXAxis: PropTypes.bool.isRequired,
  showYAxis: PropTypes.bool.isRequired,
  stacked: PropTypes.bool.isRequired,
  style: PropTypes.object.isRequired,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  tooltipGenerator: PropTypes.func.isRequired,
  valueFormat: PropTypes.string.isRequired,
  x: PropTypes.func.isRequired,
  xLabel: PropTypes.string,
  y: PropTypes.func.isRequired,
  yLabel: PropTypes.string,
};

ChartHorizontalMultiBar.defaultProps = {
  controlLabels: theme.horizontalBar.controlLabels,
  defaultShowTable: false,
  enableTableView: true,
  enableTooltip: theme.tooltip.enabled,
  groupSpacing: theme.horizontalBar.groupSpacing,
  margin: {},
  maxKeyLength: theme.legend.maxKeyLength,
  showControls: theme.horizontalBar.showControls,
  showLegend: theme.horizontalBar.showLegend,
  showValues: theme.bar.showValues,
  showXAxis: theme.bar.showXAxis,
  showYAxis: theme.bar.showYAxis,
  stacked: theme.horizontalBar.stacked,
  style: {
    height: '400px',
  },
  tooltipGenerator: theme.tooltip.contentGenerator,
  valueFormat: theme.valueFormat,
  x: (d) => d.label,
  xLabel: 'Name',
  y: (d) => d.value,
  yLabel: 'Total',
};

export default ChartHorizontalMultiBar;
