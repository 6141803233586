import PropTypes from 'prop-types';
import React from 'react';
import AddContactInformationFromNav from 'common/display/ContactStepper/components/AddContactInformationFromNav';
import AddContactInformationFromAssistanceRequest from
  'common/display/ContactStepper/components/AddContactInformationFromAssistanceRequest';
import AddContactInformationFromReferral from
  'common/display/ContactStepper/components/AddContactInformationFromReferral';
import AddContactInformationFromScreening from
  'common/display/ContactStepper/components/AddContactInformationFromScreening';
import { wget } from 'lodash';

export default function AddContactInformation(props) {
  const { params, location, route } = props;
  const originFrom = wget(route, 'from');

  switch (originFrom) {
    case 'intake-ar':
      return (
        <AddContactInformationFromAssistanceRequest
          header="Create Intake"
          location={location}
          assistanceRequestId={params.assistance_request_id}
          from={originFrom}
        />
      );
    case 'case-ar':
      return (
        <AddContactInformationFromAssistanceRequest
          header="Create Client"
          location={location}
          assistanceRequestId={params.assistance_request_id}
          from={originFrom}
        />
      );
    case 'referral':
      return (
        <AddContactInformationFromReferral
          header="Create Referral"
          location={location}
        />
      );
    case 'screening':
      return (
        <AddContactInformationFromScreening
          header="Create Screening"
          location={location}
        />
      );
    case 'referral-ar':
      return (
        <AddContactInformationFromAssistanceRequest
          assistanceRequestId={params.assistance_request_id}
          header="Create Referral"
          location={location}
          from={originFrom}
        />
      );
    default:
      return (
        <AddContactInformationFromNav
          header="Create Client"
          location={location}
        />
      );
  }
}

AddContactInformation.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  route: PropTypes.shape({
    from: PropTypes.string.isRequired,
  }).isRequired,
};
