import React, { Suspense } from 'react';
import { get, isEmpty } from 'lodash';
import { Spinner } from 'common/spinners';
import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { useCreateRecord, useInvalidateQueries } from 'src/api/APIHooks';
import { browserHistory } from 'src/common/utils/browserHistory';
import '@unite-us/surveyjs/src/components/Creator/surveyjs-creator-styles.scss';
import { extractQuestionsRecursive } from './extractQuestionsRecursive';
import { useEditLogicComponent } from './components/useEditLogicComponent';

const SurveyJSCreator = React.lazy(() => import('@unite-us/surveyjs/dist/components/Creator/SurveyJSCreator'));

const FormNew = () => {
  const invalidateQueries = useInvalidateQueries();
  const { createRecord } = useCreateRecord('form', {
    api: 'coreApi',
    mutationConfig: {
      onError: (error) => {
        const errorList = get(error, 'response.data.errors', []);
        const stringNotification = errorList.map((e) => e.title).join(', ');
        Notifier.dispatch(400, `Failed to create form: ${stringNotification}`);
      },
    },
  });

  const [EditLogicComponent] = useEditLogicComponent({}, {});

  const save = async (json, userTab = '') => {
    const questions = [];
    extractQuestionsRecursive(get(json, 'pages[0]', []), questions);

    const hasAtLeast1Question = questions.some((f) => f.type !== 'panel' && f.type !== 'html');

    if (isEmpty(json.title)) {
      Notifier.dispatch(400, 'The title is required');
      return;
    }
    if (!hasAtLeast1Question) {
      Notifier.dispatch(400, 'At least 1 question is required');
      return;
    }

    const result = await createRecord({
      name: json.title,
      state: 'published',
      description: json.description,
      configuration: { pages: json.pages },
    });
    if (isHttpSuccess(result.status)) {
      Notifier.dispatch(200, 'Form created');
    } else {
      Notifier.dispatch(400, 'Failed to create form');
    }
    invalidateQueries('form');
    const formId = result.data.data.id;
    if (userTab) {
      browserHistory.push(`/backoffice/forms/${formId}/edit/${userTab}`);
    } else {
      browserHistory.push(`/backoffice/forms/${formId}/edit`);
    }
  };

  return (
    <>
      <h1 className="p-4">Create New Form</h1>
      <Suspense fallback={<Spinner />}>
        <SurveyJSCreator editLogicComponent={EditLogicComponent} onSave={save} />
      </Suspense>
    </>
  );
};

FormNew.propTypes = {
};

FormNew.defaultProps = {
};

export default FormNew;
