import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'common/utils/browserHistory';
import { Tabs, Tab } from '@unite-us/ui';
import FeeScheduleSummary from './FeeScheduleSummary';

const AVAILABLE_TABS = [
  'fee-schedule-programs',
  'plans',
  'fee-schedule-screenings',
  'invoice-rejection-reasons',
  'authorization-rejection-reasons',
  'care-managers',
];

const urlMappings = (feeScheduleId) => {
  const baseUrl = `/backoffice/fee_schedules/${feeScheduleId}`;

  return {
    'fee-schedule-programs': baseUrl,
    plans: `${baseUrl}/plans`,
    'fee-schedule-screenings': `${baseUrl}/fee-schedule-screenings`,
    'invoice-rejection-reasons': `${baseUrl}/invoice-rejection-reasons`,
    'authorization-rejection-reasons': `${baseUrl}/authorization-rejection-reasons`,
    'care-managers': `${baseUrl}/care-managers`,
  };
};

const FeeScheduleSubHeader = ({ feeSchedule }) => {
  const initialLocation = () => {
    const path = window.location.pathname.split('/').pop();
    return AVAILABLE_TABS.includes(path) ? path : 'fee-schedule-programs';
  };
  const [location, setLocation] = useState(initialLocation);
  const handleTabChange = (value) => {
    setLocation(value);
    browserHistory.push(urlMappings(feeSchedule.id)[value]);
  };

  return (
    <div className="px-6 pt-5 fee-schedule-program-sub-header-items">
      <FeeScheduleSummary feeSchedule={feeSchedule} />
      <Tabs
        id="fee-schedule-tabs"
        className="fee-schedule-tabs mt-5"
        onChange={handleTabChange}
        value={location}
      >
        <Tab
          id="fee-schedule-fee-schedule-programs-tab"
          dataTestId="fs-programs-tab"
          label="Programs"
          value="fee-schedule-programs"
        />
        <Tab
          id="fee-schedule-plans-tab"
          dataTestId="fs-plans-tab"
          label="Plans"
          value="plans"
        />
        <Tab
          id="fee-schedule-screenings-tab"
          dataTestId="fs-screenings-tab"
          label="Screenings"
          value="fee-schedule-screenings"
        />
        <Tab
          id="fee-schedule-invoice-rejection-reasons-tab"
          dataTestId="fs-invoice-rejection-reasons-tab"
          label="Invoice Rejection Reasons"
          value="invoice-rejection-reasons"
        />
        <Tab
          id="fee-schedule-authorization-rejection-reasons-tab"
          dataTestId="fs-authorization-rejection-reasons-tab"
          label="Authorization Rejection Reasons"
          value="authorization-rejection-reasons"
        />
        <Tab
          id="fee-schedule-care-managers-tab"
          dataTestId="fs-care-managers-tab"
          label="Care Managers"
          value="care-managers"
        />
      </Tabs>
    </div>
  );
};

FeeScheduleSubHeader.propTypes = {
  feeSchedule: PropTypes.object.isRequired,
};

export default FeeScheduleSubHeader;
