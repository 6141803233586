import React from 'react';
import { Icon } from '@unite-us/ui';
import { PropTypes } from 'prop-types';
import cx from 'classnames';

const CardHeader = ({
  borderColor,
  iconColor,
  title,
  subtitle,
  titleIcon,
  className,
}) => (
  <div className={className}>
    <div className="flex">
      {
        titleIcon && (
          <Icon
            className="bg-white h-18 ml-3 mt-1.5 -mr-2"
            color={iconColor}
            icon={titleIcon}
          />
        )
      }
      <div className="pt-1 pl-4 text-text-blue text-base font-extrabold">{title}</div>
    </div>
    { subtitle &&
      (<div className="pt-1 pl-4 text-text-blue text-sm">{subtitle}</div>)}
    <hr className={cx('pt-2 -mx-4', borderColor)} />
  </div>
);

CardHeader.propTypes = {
  borderColor: PropTypes.string,
  iconColor: PropTypes.string,
  titleIcon: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  className: PropTypes.string,
};

CardHeader.defaultProps = {
  borderColor: 'border-dark-border-blue',
  iconColor: '#CF2B29',
  titleIcon: null,
  className: '',
  subtitle: null,
};

export default CardHeader;
