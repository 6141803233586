import PropTypes from 'prop-types';
import React from 'react';
import { CityState } from '@unite-us/client-utils';
import _ from 'lodash';
import County from './components/County';
import './stylesheets/address.scss';

const Address = ({ inlineCounty, item, shortDisplay }) => {
  const address = item;

  if (_.isEmpty(address)) {
    return null;
  }

  const additionalInfo = address.is_primary ? '(primary)' : '';
  const subheadContent = shortDisplay ?
    null :
    [address.address_type, additionalInfo].join(' ');

  return (
    <div className="address">
      <p className="subhead">{subheadContent}</p>
      <p className="address__content">
        {address.line_1}<br />
        {address.line_2 ? <span>{address.line_2}<br /></span> : null}
        <CityState address={address} />{address.postal_code}
        {inlineCounty && <County address={address} inline />}
      </p>
      {!inlineCounty && <County address={address} />}
    </div>
  );
};

Address.propTypes = {
  inlineCounty: PropTypes.bool,
  item: PropTypes.shape({
    address_type: PropTypes.string,
    line_1: PropTypes.string,
    line_2: PropTypes.string,
    postal_code: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
  shortDisplay: PropTypes.bool,
};

Address.defaultProps = {
  inlineCounty: false,
  shortDisplay: false,
};

export default Address;
