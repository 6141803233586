import { clearSession } from 'common/utils/utils';
import { remove as removeCookie } from 'es-cookie';
import { COOKIE_SESSION_KEY } from 'src/common/constants';

const submitLogoutForm = () => {
  const form = document.getElementById('logout-auth-form');

  clearSession();
  removeCookie(COOKIE_SESSION_KEY);

  if (form) {
    form.submit();
  }
};

export default submitLogoutForm;
