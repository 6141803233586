// THESE UTILS ARE DEPRECATED. USE APP-COMPONENT UTILS INSTEAD
import convertTimeToMilitaryFormat from "./convertTimeToMilitaryFormat";
import convertToMinutes from "./convertToMinutes";
import formatDate from "./formatDate";
import formatLocalDateTime from "./formatLocalDateTime";
import formatTableDate from "./formatTableDate";
import getAge from "./getAge";
export default {
  convertTimeToMilitaryFormat: convertTimeToMilitaryFormat,
  convertToMinutes: convertToMinutes,
  formatDate: formatDate,
  formatLocalDateTime: formatLocalDateTime,
  formatTableDate: formatTableDate,
  getAge: getAge
};