import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { validateReduxForm } from 'common/form';
import { useInvalidateQueries } from 'src/api/APIHooks';
import { validations } from '@unite-us/app-components';
import {
  Button,
  TextField,
} from '@unite-us/ui';
import { OverlaySpinner } from 'common/spinners';
import { browserHistory } from 'common/utils/browserHistory';
import { ReferralReasonField } from 'src/components/Referrals/ReferralFormFields';
import {
  createReasonOptions,
} from 'src/components/Referrals/ReferralFormFields/ReferralReasonField/utils';
import {
  RECALL_REFERRAL_FORM,
} from 'src/components/Referrals/constants';
import { recallReferral } from 'actions/Referral/Group';
import callOrLog from 'src/common/utils/callOrLog';
import { REFERRAL } from 'common/utils/EventTracker/utils/eventConstants';
import { validateNoteText } from 'src/components/Referrals/utils/form';

const RecallModalForm = (props) => {
  const invalidateQueries = useInvalidateQueries();
  const refreshData = () => {
    invalidateQueries('referral');
    invalidateQueries('note');
    invalidateQueries('case');
  };

  const composeValidators = (...validators) => (
    (value) => validators.reduce((error, validator) => error || validator(value), undefined)
  );

  const onSubmit = (values) => props.recallReferral(
      props.groupId,
      props.referral.id,
      values,
      true,
    ).then((response) => {
      refreshData();
      const referral = _.get(response, 'data.data', {});
      callOrLog(() => window.eventTracker(REFERRAL.recall, {
        reason: values.reason,
      }, { referral }));
      return browserHistory.push('/dashboard/referrals/sent/recalled');
    })
    .catch(() => refreshData());

  const closeDialog = () => {
    props.resetForm();
    props.closeDialog();
  };

  const {
    fields: { note, reason },
    handleSubmit,
    recallOptions,
    registerField,
  } = props;

  return (
    <form className="content-with-actions">
      <div className="content-container">
        <OverlaySpinner show={props.submitting} text="Recalling Referral..." />
        <ReferralReasonField
          className="recall-referral-reason-select"
          field={reason}
          id="reasonInput"
          options={createReasonOptions(recallOptions)}
          registerField={registerField}
        />
        <TextField
          field={note}
          id="noteInput"
          label="Note"
          ref={registerField}
          validations={composeValidators(validations.isRequired, validateNoteText)}
          inline={false}
          required
        />
      </div>
      <div className="actions">
        <span className="action-item">
          <Button
            id="recall-referral-cancel-btn"
            onClick={closeDialog}
            label="Cancel"
          />
        </span>
        <span className="action-item">
          <Button
            id="recall-referral-recall-btn"
            onClick={handleSubmit(onSubmit)}
            primary
            label="Recall"
            disabled={props.submitting || props.invalid}
          />
        </span>
      </div>
    </form>
  );
};

RecallModalForm.propTypes = {
  referral: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired,
  recallReferral: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  recallOptions: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    groupId: state.session.groupId,
    programs: state.groupsPrograms.data,
    employees: state.groupsUsers.data,
    recallOptions: _.wget(state, 'session.enums.referrals.recall', []),
  };
}

const fields = [
  'reason',
  'note',
];
export default validateReduxForm(
  {
    form: RECALL_REFERRAL_FORM,
    fields,
  },
  mapStateToProps,
  { recallReferral },
)(RecallModalForm);
