import { get, isEmpty } from 'lodash';
import {
  SET_DASHBOARD_FETCHING,
  DASHBOARD_FETCH_ASSISTANCE_REQUESTS,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import { unsetDashboardFetching } from 'actions/Dashboard';
import { coreApi } from 'src/api/config';

const defaultOptions = {
  target: 'assistanceRequests',
  sidx: 'created_at',
  sord: 'asc',
  filters: {},
  page: 1,
};

const onError = (error) => {
  Notifier.handleErrors(error);
  return error;
};

const fetchDashboardAssistanceRequests = (groupId, options = defaultOptions) => (
  async (dispatch) => {
    try {
      let params = {};

      // https://uniteus.slack.com/archives/C01A7M28KKP/p1627497605174100?thread_ts=1627493914.166800&cid=C01A7M28KKP
      // https://uniteus.atlassian.net/browse/CORE-1227
      if (options.target === 'assistanceRequests') { // pending
        params = {
          state: 'assistance_requested',
          has_outcome: false,
        };
      }
      if (options.target === 'closedAssistanceRequests') { // closed
        params = {
          state: 'assistance_requested',
          has_outcome: true,
          'outcome.resolved': options.filters.resolved === 'all' ?
            null : options.filters.resolved,
        };
      }
      if (options.target === 'processedAssistanceRequests') { // processed
        params = {
          state: 'accepted,referred,managed,deferred,off_platform',
        };
      }

      dispatch({
        type: SET_DASHBOARD_FETCHING,
        target: options.target,
        filters: options.filters,
      });

      const service = get(options, 'filters.service_types', []);
      const careCoordinatorUsers = get(options, 'filters.care_coordinator_users', []);
      const careCoordinators = careCoordinatorUsers.filter((c) => c !== 'none');
      const noneAssigned = careCoordinatorUsers.includes('none');

      const payload = await coreApi.query('cases', {
        ...params,
        assistance_request: true,
        originating_provider: groupId,
        ...(service.length ? { service: service.join(',') } : null),
        ...(!isEmpty(careCoordinators) ?
          { 'person.client_relationships.care_coordinator': careCoordinators.join(',') } : null),
        ...(noneAssigned ? {
          'person.client_relationships.has_care_coordinator': false,
          'person.client_relationships.provider': groupId,
        } : null),
      }, {
        page: {
          number: options.page,
          size: options.size || 50,
        },
        sort: options.sord,
      });
      dispatch(unsetDashboardFetching(payload, options.target));

      const cases = payload.data.data;
      await Promise.all([
        coreApi.populateRelationship('service', 'service', cases),
        coreApi.populateRelationship('provider', 'provider', cases),
        coreApi.populateRelationship('person', 'person', cases),
      ]);

      const mapped = cases.map((c) => ({
        ...c,
        group: c.provider,
        requestor: c.person,
        service_type: c.service,
      }));
      payload.data.data = mapped;

      dispatch({
        type: DASHBOARD_FETCH_ASSISTANCE_REQUESTS,
        payload,
        target: options.target,
        filters: options.filters,
      });

      return payload;
    } catch (error) {
      return onError(error);
    }
  }
);

export default fetchDashboardAssistanceRequests;
