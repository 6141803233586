import PropTypes from 'prop-types';
import React from 'react';
import { CheckBoxField } from '@unite-us/ui';
import { get, isEmpty, noop } from 'lodash';
import classNames from 'classnames';
import { noneSelected } from '../utils';

const SelectServiceCases = ({
  hidden,
  registerField,
  serviceCases,
  submitFailed,
}) => {
  const invalid = noneSelected(serviceCases);

  const caseCheckBoxes = serviceCases.map((aCase = {}, index) => (
    <div
      className="col-sm-12 selected-service-case"
      key={index}
    >
      <CheckBoxField
        id={`case-${index}-checkbox`}
        field={aCase.selected}
        hidden={hidden}
        label={get(aCase, 'label.value', '')}
        ref={registerField}
        validations={{ func: () => (invalid ? ' ' : null) }}
        showError={false}
        showHint={false}
      />
    </div>
  ));

  const invalidMessage = invalid && submitFailed && (
    <div className="select-service-cases__error-message">
      {'Select at least one case'}
    </div>
  );

  const rowClasses = classNames({
    'select-service-cases': true,
    row: true,
    hidden: isEmpty(serviceCases) || hidden,
  });

  return (
    <div className={rowClasses}>
      <div className="col-sm-12">
        <h5 className="label-header">
          Add Note to Cases {invalidMessage}
        </h5>
      </div>
      {caseCheckBoxes}
    </div>
  );
};

SelectServiceCases.propTypes = {
  hidden: PropTypes.bool,
  registerField: PropTypes.func,
  serviceCases: PropTypes.array.isRequired,
  submitFailed: PropTypes.bool.isRequired,
};

SelectServiceCases.defaultProps = {
  hidden: false,
  registerField: noop,
};

export default SelectServiceCases;
