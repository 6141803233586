import { REMOVE_REFERRAL_DOCUMENT } from 'actions';
import Notifier from 'common/helpers/Notifier';
import removeDocumentsFromResource from
  'common/form/DocumentUploader/actions/removeDocumentsFromResource';

function removeReferralDocument({ documentId, groupId, caseId }) {
  return (dispatch) => {
    function onSuccess() {
      dispatch({
        type: REMOVE_REFERRAL_DOCUMENT,
        documentId,
      });

      return documentId;
    }

    function onError(error) {
      Notifier.handleErrors(error);
      return error;
    }
    return removeDocumentsFromResource({
      documentId,
      groupId,
      resourceId: caseId,
      resourceType: 'referrals',
    }).then(onSuccess, onError);
  };
}

export default removeReferralDocument;
