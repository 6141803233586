import { useCallback, useState } from 'react';

const useServiceAuthorizationPagination = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const resetPagination = useCallback(() => {
    setPageNumber(1);
  }, [setPageNumber]);
  return {
    pageNumber,
    pageSize,
    setPageNumber,
    setPageSize,
    resetPagination,
  };
};

export default useServiceAuthorizationPagination;
