import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';
import ToolTip from '../../ToolTip/ToolTip';

const BulkActionButton = ({
  action,
  open,
  icon,
  disableOpenModal,
}) => (
  <ToolTip
    buttonClassName="focus:outline-none"
    buttonComponentContent={() => (
      <div className={`Bulk ${action} mx-4`}>
        <span className="text-lg flex flex-row">
          <button
            onClick={open}
            type="button"
            disabled={disableOpenModal}
            className={`${disableOpenModal ? 'fill-current opacity-25' : 'fill-current'}`}
            aria-label={`Bulk-Action-${action}-button`}
          >
            <Icon
              icon={icon}
              size={25}
              className={`${disableOpenModal ? 'fill-current opacity-25' : 'fill-current'}`}
            />
          </button>
        </span>
      </div>
    )}
    panelComponentContent={() => (
      <p className="bg-medium-gray text-white font-bold mb-2 w-50 p-4 border-solid opacity-30 rounded">
        {action}
      </p>
    )}
    enableHover
    hoverDelay={0}
    placement="bottom"
    offsetOptions={{ offset: [0, 5] }}
    flipOptions={{ fallbackPlacements: [] }}
  />
);

BulkActionButton.propTypes = {
  open: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  disableOpenModal: PropTypes.bool.isRequired,
};

export default BulkActionButton;
