// @TODO - Alex Can this component be deleted?
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog } from '@unite-us/ui';
import callOrLog from 'src/common/utils/callOrLog';
import { SERVICE_CASE } from 'common/utils/EventTracker/utils/eventConstants';
import { OONGroupDetails } from '.';

class ShowAndPrintOONGroupDialog extends Component {
  constructor(props) {
    super(props);

    this.onCancel = this.onCancel.bind(this);
    this.onPrint = this.onPrint.bind(this);
  }

  onCancel() {
    this.dialog.closeDialog();
  }

  onPrint() {
    const { contact } = this.props;

    callOrLog(() => (
      this.context.eventTracker(SERVICE_CASE.OONGroupPrintButtonClicked, null, { contact })
    ));

    window.print();
    this.dialog.closeDialog();
  }

  render() {
    const { oonGroups } = this.props;

    const groupColumns = oonGroups.map((group = {}, i) => (
      <div className="col-xs-4" key={`${group.id}${i}`}>
        <OONGroupDetails oonGroup={group} />
      </div>
    ));

    return (
      <Dialog
        id="show-and-print-oon-group-dialog"
        className="show-and-print-oon-group-dialog"
        ref={(c) => { this.dialog = c; }}
        size="large"
        title="Out Of Network Organizations"
        actions={(
          <div className="show-and-print-oon-group-dialog__actions">
            <Button
              id="show-and-print-oon-group-dialog-cancel-btn"
              className="show-and-print-oon-group-dialog__actions--cancel mr-one"
              label="Cancel"
              onClick={this.onCancel}
            />
            <Button
              id="show-and-print-oon-group-dialog-print-btn"
              className="show-and-print-oon-group-dialog__actions--print"
              onClick={this.onPrint}
              label="Print"
              primary
            />
          </div>
        )}
      >
        <div className="row">
          {groupColumns}
        </div>
      </Dialog>
    );
  }
}

ShowAndPrintOONGroupDialog.propTypes = {
  oonGroups: PropTypes.array,
  contact: PropTypes.object.isRequired,
};

ShowAndPrintOONGroupDialog.defaultProps = {
  oonGroups: [],
};

ShowAndPrintOONGroupDialog.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default ShowAndPrintOONGroupDialog;
