import React from 'react';
import PropTypes from 'prop-types';
import SearchPrograms from '../Browse/SearchPrograms';

function ReferralsSearchPrograms({ toggleBrowse, ...props }) {
  return (
    <div className="fixed inset-0 z-modal bg-black bg-opacity-75 overflow-hidden">
      <div className="absolute right-0 h-full" style={{ width: 'calc(100% - 66px)' }}>
        <header className="px-6 bg-text-blue flex items-center h-60 justify-between">
          <h2 className="text-white">Advanced Search</h2>
          <button
            className="py-3 px-16 border-black border-solid rounded bg-action-blue text-white text-lg font-black"
            type="button"
            onClick={toggleBrowse}
          >
            Close
          </button>
        </header>
        <SearchPrograms
          crtb1127AuthPaymentProgramsInReferrals={false}
          {...props}
        />
      </div>
    </div>
  );
}

ReferralsSearchPrograms.propTypes = {
  toggleBrowse: PropTypes.func.isRequired,
};

export default ReferralsSearchPrograms;
