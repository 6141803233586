import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { truncate, find } from 'lodash';
import { phones as phonesUtils } from '@unite-us/client-utils';
import { relationships } from 'src/reducers/constants/defaultEnums';
import separatePhoneNumberFromExtension from 'common/form/Profile/utils/separatePhoneNumberFromExtension';
import './RelationshipListItem.scss';

const DISPLAY_NAME = 'relationship-list-item';

const getRelationshipValue = (relationship, maintainRelationship = false) => {
  if (maintainRelationship) {
    return relationship;
  }
  return find(relationships, { value: relationship })?.display_name || 'Family Member';
};
const generateName = (fullName, linkedFamilyMemberId) => {
  if (linkedFamilyMemberId) {
    return (
      <Link className="font-black text-action-darker-blue" to={`/facesheet/${linkedFamilyMemberId}`}>
        {fullName}
      </Link>
    );
  }
  return fullName;
};
const handleOnKeyDown = (e, onClick, personalRelationshipId) => {
  if (e.key === 'Enter') {
    onClick(personalRelationshipId);
  }
};
const RelationshipListItem = ({
  personalRelationshipId,
  fullName,
  email,
  phoneNumber,
  relationship,
  onClick,
  children,
  maintainRelationship,
  linkedFamilyMemberId,
}) => (
  <div
    className={classNames(
      DISPLAY_NAME,
      `border border-solid
      hover:cursor-pointer hover:bg-medium-fill-blue
      border-dark-border-blue mb-2 px-2 py-3 bg-white rounded overflow-hidden`,
    )}
    onClick={() => onClick(personalRelationshipId)}
    onKeyDown={(e) => handleOnKeyDown(e, onClick, personalRelationshipId)}
    role="button"
    tabIndex="0"
  >
    <div className="flex items-center justify-between px-3 mb-1">
      <p
        className={classNames(
          `${DISPLAY_NAME}__name`,
          'font-black text-sm text-text-blue capitalize',
        )}
      >
        {generateName(fullName, linkedFamilyMemberId)}
      </p>
      <div className={classNames(`${DISPLAY_NAME}__actions`, 'flex')}>
        {children}
      </div>
    </div>
    <div className={classNames(`${DISPLAY_NAME}__relationship`, 'flex items-start px-3')}>
      <p
        className={classNames(`${DISPLAY_NAME}-rel-type`, 'text-dark-grey')}
      >
        {getRelationshipValue(relationship, maintainRelationship)}
      </p>
    </div>
    {email && (
      <div className={classNames(`${DISPLAY_NAME}__email`, 'px-3')}>
        <a href={`mailto:${email}`} title={email} className="text-[13px]">
          {truncate(email, { length: 40 })}
        </a>
      </div>
    )}
    {phoneNumber && (
      <div className={classNames(`${DISPLAY_NAME}__phone`, 'px-3')}>
        <a href={phonesUtils.getTelLink(separatePhoneNumberFromExtension(phoneNumber), true)} className="text-[13px]">
          {phonesUtils.formatPhoneNumber(phoneNumber.phone_number)}
        </a>
      </div>
    )}
  </div>
);

RelationshipListItem.propTypes = {
  personalRelationshipId: PropTypes.string.isRequired,
  fullName: PropTypes.string,
  relationship: PropTypes.string,
  maintainRelationship: PropTypes.bool,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  linkedFamilyMemberId: PropTypes.string,
};

RelationshipListItem.defaultProps = {
  fullName: '',
  relationship: '',
  maintainRelationship: false,
  email: '',
  phoneNumber: '',
  linkedFamilyMemberId: '',
};

export default RelationshipListItem;
