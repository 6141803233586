import { find, get } from 'lodash';
import { includesServiceType } from 'common/utils/ServiceTypes';

export default function isValidServiceType({
  fields, groupProgram, serviceType, user,
}) {
  let serviceTypes = get(groupProgram, 'service_types', []);

  if (get(groupProgram, 'is_default')) {
    // CORE-WORK - Need to resolve network.service_types
    const selectedNetwork = find(user.networks, { id: fields.network.value.id }) || {};
    serviceTypes = selectedNetwork.service_types || [];
  }

  return includesServiceType(serviceType, serviceTypes);
}
