import {
  FETCH_ACCOUNT_ROLES_ERROR,
  FETCH_ACCOUNT_ROLES_SUCCESS,
  FETCH_ACCOUNT_ROLES_STARTED,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { apiV1 } from 'src/api/config';

const onError = (dispatch, error) => {
  dispatch({ type: FETCH_ACCOUNT_ROLES_ERROR });

  Notifier.handleErrors(error);
  return error;
};

const onSuccess = (dispatch, accountRoles) => {
  dispatch({
    type: FETCH_ACCOUNT_ROLES_SUCCESS,
    payload: accountRoles,
  });

  return accountRoles;
};

const fetchAccountRoles = (userId) => (
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_ACCOUNT_ROLES_STARTED });

      const response = await apiV1.query('account_role', { account: userId });

      if (!response || !isHttpSuccess(response.status)) {
        return onError(dispatch, response);
      }

      return onSuccess(dispatch, response);
    } catch (error) {
      return onError(dispatch, error);
    }
  }
);

export default fetchAccountRoles;
