import { get } from 'lodash';

/*
  [
    { id: 1, name: 'service', code: 'c', parent: { id: 2 } },
    { id: 2, name: 'service 2', code: 'c2' },
    { id: 3, name: 'other', code: 'other'}
  ]
  to:

  [
    { id: 2, name: 'service 2', code: 'c2', children: [{ id: 1, name: 'service', code: 'c', parent: { id: 2 } }] },
    { id: 3, name: 'other', code: 'other'},
  ]
*/

const mapServiceResponse = (response) => (
  response.data.data
    .map((service, index, array) => {
      if (!service.parent) {
        return {
          ...service,
          children: array.filter((children) => get(children, 'parent.id') === service.id),
        };
      }
      return service;
    })
    .filter((service) => !service.parent)
);

export default mapServiceResponse;
