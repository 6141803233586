import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, find } from 'lodash';
import {
  Button,
  InputField,
  SelectField,
  RadioField,
  Icon,
} from '@unite-us/ui';
import { validations } from '@unite-us/app-components';
import {
  createPayer as creatingPayer,
  fetchPayers as fetchingPayers,
  updatePayer as updatingPayer,
} from 'actions/Payers';
import { validateReduxForm } from 'common/form';
import { Spinner } from 'common/spinners';
import {
  STATUS_INITIAL,
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_ERROR,
} from 'common/Status/constants';
import { browserHistory } from 'common/utils/browserHistory';
import { payerInitialFormValues } from 'pages/backoffice/payers/_id/utils';
import { BOOL_OPTIONS } from '../../constants';

const namespace = 'payer-form';
const PAYER_FORM_ID = 'payerForm';
const STATE_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'Archived', value: 'archived' },
];

export const PayerForm = ({
  createPayer,
  destroyForm,
  fetchPayers,
  fields,
  handleSubmit,
  payerId,
  registerField,
  status,
  updatePayer,
}) => {
  const isEditing = !isEmpty(payerId);
  const onSubmit = useCallback(async (formValues) => {
    const submitFn = isEditing ? updatePayer : createPayer;
    submitFn(formValues.payer).then((result) => {
      if (result) {
        destroyForm();
        if (isEditing) {
          browserHistory.push({ pathname: `/backoffice/payers/${payerId}` });
        } else {
          browserHistory.push({ pathname: '/backoffice/payers' });
        }
      }
    });
  }, [createPayer, destroyForm, isEditing, updatePayer]);
  const onCancel = useCallback(() => {
    destroyForm();
    const path = isEditing ? `/backoffice/payers/${payerId}` : '/backoffice/payers';
    browserHistory.push({ pathname: path });
  });

  const backToPayers = () => {
    browserHistory.push({ pathname: '/backoffice/payers' });
  };
  const viewPayer = (id) => () => {
    browserHistory.push({ pathname: `/backoffice/payers/${id}` });
  };
  const title = isEditing ? `Edit ${fields.payer.name.initialValue}` : 'Add Payer';
  const shouldLoad = isEditing && isEmpty(fields.payer.id.initialValue);
  const isLoaded = status === STATUS_SUCCESS || status === STATUS_ERROR;
  const isPending = status === STATUS_PENDING;
  const showSpinner = (shouldLoad && !isLoaded) || isPending;
  const showForm = isLoaded || !isEditing;
  useEffect(() => {
    if (shouldLoad && !isLoaded && !isPending) {
      fetchPayers();
    }
  }, [fetchPayers]);

  return (
    <div>
      {showSpinner && <Spinner />}
      {showForm && (
        <div className="flex flex-col p-6">
          <div className="flex content-start py-2">
            <div className="p-2 text-base">
              <Icon
                color="#525F73"
                icon="HousingShelter"
              />
            </div>
            <div className="p-2 text-base">/</div>
            <div
              className="p-2 text-base hover:text-medium-gray text-action-blue"
              id="back-to-payers-button"
              role="button"
              onClick={backToPayers}
              onKeyDown={backToPayers}
              tabIndex={0}
            >Payers
            </div>
            {isEditing && (
              <>
                <div className="p-2 text-base">/</div>
                <div
                  className="p-2 text-base hover:text-medium-gray text-action-blue"
                  id="back-to-view-payer-button"
                  role="button"
                  onClick={viewPayer(payerId)}
                  onKeyDown={viewPayer(payerId)}
                  tabIndex={0}
                >{fields.payer.name.initialValue}
                </div>
                <div className="p-2 text-base">/</div>
                <div className="p-2 text-base">edit</div>
              </>
            )}
          </div>
          <div className="w-1/2">
            {title}
          </div>
          <hr className="p-2 divide-y divide-brand-blue" />
          <div className="pt-4 w-1/2">
            <InputField
              field={fields.payer.name}
              id={`${namespace}__name-field`}
              label="Name"
              required
              ref={registerField}
              validations={validations.isRequired}
            />
          </div>

          <div className="pt-4 w-1/2">
            <InputField
              className="w-1/2"
              field={fields.payer.external_id}
              id={`${namespace}__external-id-field`}
              label="External ID"
              required
              ref={registerField}
              validations={validations.isRequired}
            />
          </div>

          <div className="pt-4 w-1/2">
            <RadioField
              id={`${namespace}__payer-wq-interactive-field`}
              label="Payer WQ Interactive"
              field={fields.payer.payer_wq_interactive}
              options={BOOL_OPTIONS}
              className="w-auto"
            />
          </div>

          {isEditing && (
            <SelectField
              className="pt-4 w-1/2"
              field={fields.payer.state}
              label="State"
              labelKey="label"
              id={`${namespace}__state-field`}
              options={STATE_OPTIONS}
              value={fields.state}
              valueKey="value"
              ref={registerField}
            />
          )}

          <div className="w-1/2 flex">
            <Button
              className="mr-4"
              label={isEditing ? 'Update' : 'Create'}
              data-testid="save-payer-btn"
              onClick={handleSubmit(onSubmit)}
              primary
            />
            <Button
              label="Cancel"
              onClick={onCancel}
              data-testid="cancel-payer-btn"
            />
          </div>

        </div>
      )}
    </div>
  );
};

PayerForm.propTypes = {
  createPayer: PropTypes.func.isRequired,
  destroyForm: PropTypes.func.isRequired,
  fetchPayers: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  payerId: PropTypes.string.isRequired,
  registerField: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  updatePayer: PropTypes.func.isRequired,
};

const fields = [
  'payer.form_id',
  'payer.id',
  'payer.name',
  'payer.external_id',
  'payer.payer_wq_interactive',
  'payer.state',
];

const mapStateToProps = (state, ownProps) => {
  const payerId = get(ownProps, 'params.id', '');
  const payers = get(state, 'payers.payers', []);
  const payer = find(payers, ['id', payerId]);
  const status = get(state, 'payers.status', STATUS_INITIAL);
  return {
    payerId,
    initialValues: payer ? payerInitialFormValues(payer) : {},
    status,
  };
};

export default validateReduxForm({
  form: PAYER_FORM_ID,
  fields,
}, mapStateToProps, {
  createPayer: creatingPayer,
  fetchPayers: fetchingPayers,
  updatePayer: updatingPayer,
})(PayerForm);
