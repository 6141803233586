import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconButton from 'common/buttons/IconButton';

const DISPLAY_NAME = 'relationship-card-header';

const getDisplayName = (title) => {
  if (title === 'Care Team') {
    return 'add-care-team-member-icon-button';
  }
    return 'add-family-member-icon-button';
};

const RelationshipHeader = ({
  title,
  onAddClick,
  addButtonLabel,
}) => (
  <header
    className={classNames(
      DISPLAY_NAME,
      'flex justify-between items-center mb-2',
    )}
  >
    <h3
      className={classNames(
        `${DISPLAY_NAME}__title`,
      )}
    >
      {title}
    </h3>
    <IconButton
      className={`${DISPLAY_NAME}__add-btn`}
      id={getDisplayName(title)}
      labelText={addButtonLabel}
      icon="IconPlusCircle"
      onClick={onAddClick}
    />
  </header>
);

RelationshipHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onAddClick: PropTypes.func.isRequired,
  addButtonLabel: PropTypes.string,
};

RelationshipHeader.defaultProps = {
  addButtonLabel: '',
};

export default RelationshipHeader;
