import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isClientMergingEnabled as isClientMergingEnabledFF } from 'common/utils/FeatureFlags/flags';
import { useAuthorizeClientMerging } from 'common/hooks';
import { browserHistory } from 'common/utils/browserHistory';
import { BackButton } from 'common/buttons';
import { useFind } from 'src/api/APIHooks';
import { Icon, Button, Pager } from '@unite-us/ui';
import { isOrgAdmin } from 'src/components/User/utils';
import { DEFAULT_PAGE_SIZE } from 'src/components/Backoffice/backofficeUtils';
import PersonToMerge from './components/PersonToMerge';

function MergeSelect(props) {
  const {
    location,
    params,
    isClientMergingEnabled,
    userIsAdmin,
  } = props;

  const { query: { back } } = location;

  const [selectedPeople, setSelectedPeople] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const selectedSSN = useMemo(() => {
    const personWithSSN = selectedPeople.find((selectedPerson) => selectedPerson.ssn);
    return personWithSSN ? personWithSSN.ssn : null;
  }, [selectedPeople]);

  const { data, isFetching } = useFind(`people/${params.person_id}/duplicates`, {}, {
    page: {
      size: DEFAULT_PAGE_SIZE,
      number: pageNumber,
    },
    api: 'coreApi',
    queryConfig: {
      enabled: isClientMergingEnabled,
      placeholderData: undefined,
    },
  });

  const duplicatedClients = data?.data?.data;
  const paging = data?.data?.paging || { total_count: 0, current_page: 0 };

  useEffect(() => {
    if (duplicatedClients && duplicatedClients?.length < 2) {
      browserHistory.push('/');
      return;
    }
  }, [duplicatedClients]);

  useAuthorizeClientMerging(isClientMergingEnabled, userIsAdmin);

  const handleSelect = (person) => {
    const isPersonSelected = selectedPeople.some((selectedPerson) => selectedPerson.id === person.id);

    if (isPersonSelected) {
      setSelectedPeople(selectedPeople.filter((selectedPerson) => selectedPerson.id !== person.id));
    } else if (selectedPeople.length < 2) {
      setSelectedPeople([...selectedPeople, person]);
    } else {
      setSelectedPeople([selectedPeople[1], person]);
    }
  };

  const onBack = () => {
    if (back) {
      browserHistory.push(back);
    } else {
      browserHistory.push('/people/duplicates');
    }
  };

  const onNextClick = () => {
    browserHistory.push({
      pathname: '/people/merge',
      query: { clients: selectedPeople.map((selectedClient) => selectedClient.id) },
    });
  };

  const onNextPage = () => {
    setPageNumber(paging?.current_page + 1);
  };

  const onPrevPage = () => {
    setPageNumber(paging?.current_page - 1);
  };

  return (
    <div className="flex justify-center py-12">
      <div className="flex flex-col items-center w-full max-w-5xl space-y-4">
        <BackButton className="self-start" navigateTo={onBack} />
        <div className="w-full">
          <div className="flex items-center justify-between px-8 py-4 bg-white border border-solid rounded-t-md
          border-filter-border-color"
          >
            <div>
              <h2 className="text-xl font-semibold text-brand-blue">
                Select records to merge
              </h2>
              <p className="mt-1 text-sm text-brand-blue">
                To merge clients, select two records. Then, click Next to decide which profile information to keep.
              </p>
            </div>
            <Pager
              paging={paging}
              disabled={isFetching}
              onNextClick={onNextPage}
              onPrevClick={onPrevPage}
            />
          </div>
          <ul
            className="p-8 space-y-4 bg-white border-b
              border-l border-r border-solid rounded-b-md
              border-filter-border-color"
          >
            {duplicatedClients?.map((person) => (
              <PersonToMerge
                key={person.id}
                person={person}
                isSelected={selectedPeople.some((selectedPerson) => selectedPerson.id === person.id)}
                handleSelect={handleSelect}
                disabled={selectedSSN && person.ssn && selectedSSN !== person.ssn}
              />
            ))}
          </ul>
        </div>
        <div className="w-full flex gap-2">
          <Icon icon="IconInfoCircle" size={15} />
          <span>
            Don&apos;t see a duplicate on this list? Only duplicate records that you can merge appear on this list.
          </span>
        </div>
        <div className="w-full flex justify-end">
          <Button
            primary label="Next"
            disabled={selectedPeople.length !== 2}
            onClick={onNextClick}
            className="w-40"
          />
        </div>
      </div>
    </div>
  );
}

MergeSelect.propTypes = {
  isClientMergingEnabled: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const { session } = state;

  return {
    isClientMergingEnabled: isClientMergingEnabledFF(state),
    userIsAdmin: isOrgAdmin(state.user, session.groupId),
  };
}

export default connect(mapStateToProps)(MergeSelect);
