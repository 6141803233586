import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import pluralize from 'pluralize';
import { get } from 'lodash';
import { useFind } from 'api/APIHooks';
import { getCapInformation } from 'components/ServiceAuthorization/utils';
import dollarsToCents from 'common/utils/Money/dollarsToCents';
import formatShortDate from 'common/utils/Dates/formatShortDate';
import formatSpend from 'common/utils/Money/formatSpend';
import { LineItem } from 'common/display/LineItem';

const filterByState = (items) => {
  const approvedItems = items.filter((item) => item.state === 'approved');
  const requestedItems = items.filter((item) => item.state === 'requested');
  return { approvedItems, requestedItems };
};

function AuthorizationSpendSection({
  feeScheduleProgram,
  person,
  isUnitBased,
  requestedStartsAt,
  requestedEndsAt,
  authorizedAmount,
  setIsAmountExceeded,
}) {
  const authorizedSpendFilters = {
    fee_schedule_program: feeScheduleProgram.id,
    person: person.id,
    requested_starts_at: requestedStartsAt.toISOString(),
    requested_ends_at: requestedEndsAt.toISOString(),
  };

  const authorizedSpendsQuery = useFind(
    'authorized_spend',
    authorizedSpendFilters,
    {
      queryConfig: {
        enabled:
        requestedEndsAt &&
        requestedStartsAt &&
        requestedEndsAt.isSameOrAfter(requestedStartsAt) &&
        Boolean(feeScheduleProgram.id),
        placeholderData: undefined,
      },
    },
  );
  const { data: authorizedSpendsData, isFetching } = authorizedSpendsQuery;
  const authorizedSpends = get(authorizedSpendsData, 'data.data', []);
  const { approvedItems, requestedItems } = filterByState(authorizedSpends);
  const totalApprovedSpend = get(authorizedSpendsData, 'data.meta.total_approved_spend', 0);
  const totalRequestedSpend = get(authorizedSpendsData, 'data.meta.total_requested_spend', 0);
  const availableCap = get(authorizedSpendsData, 'data.meta.available_cap', 0);

  const capInfo = getCapInformation(
    feeScheduleProgram,
    get(authorizedSpendsData, 'data.meta'),
  );

  const parsedAuthorizedAmount = isUnitBased ? parseInt(authorizedAmount, 10) : dollarsToCents(authorizedAmount);

  const isAmountExceeded = capInfo.hasCap && !!parsedAuthorizedAmount &&
    (parsedAuthorizedAmount > (availableCap));

  useEffect(() => {
    setIsAmountExceeded(capInfo.hasCap && isAmountExceeded);
    return () => {
      setIsAmountExceeded(false);
    };
  }, [isAmountExceeded]);

  return (
    <>
      <hr className="my-5 border-dark-fill-blue border-solid" />
      <h4 className="leading-4">
        CLIENT SERVICE UTILIZATION
      </h4>
      <p className="mt-2 mb-3">
        The following requests have service delivery dates that overlap with this cap period:
        <b> {
          capInfo.hasCap ?
            capInfo.formattedCapPeriod : `${formatShortDate(requestedStartsAt)} - ${formatShortDate(requestedEndsAt)}`
        }
        </b>
      </p>
      <dl className="grid grid-cols-2 gap-2 mt-0">
        { capInfo.hasCap && (
          <LineItem isLoaded={!isFetching} field="Available Amount">
            {capInfo.formattedAvailableAmount}
          </LineItem>
        )}
        <LineItem isLoaded={!isFetching} field="Accepted Requests">
          {formatSpend(totalApprovedSpend, feeScheduleProgram.unit, !isUnitBased)}
          {` (${approvedItems.length} ${pluralize('request', approvedItems.length)})`}
        </LineItem>
        <LineItem isLoaded={!isFetching} field="Open Requests">
          {formatSpend(totalRequestedSpend, feeScheduleProgram.unit, !isUnitBased)}
          {` (${requestedItems.length} ${pluralize('request', requestedItems.length)})`}
        </LineItem>
        { capInfo.hasCap && (
          <>
            <LineItem isLoaded={!isFetching} field="Cap">
              {capInfo.formattedCap}
            </LineItem>
            <LineItem isLoaded={!isFetching} field="Cap Period">
              {capInfo.formattedCapPeriod}
            </LineItem>
          </>
        )}
      </dl>
    </>
  );
}

export default AuthorizationSpendSection;

AuthorizationSpendSection.defaultProps = {
  authorizedAmount: null,
};

AuthorizationSpendSection.propTypes = {
  feeScheduleProgram: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  isUnitBased: PropTypes.bool.isRequired,
  requestedStartsAt: PropTypes.object.isRequired,
  requestedEndsAt: PropTypes.object.isRequired,
  authorizedAmount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  setIsAmountExceeded: PropTypes.func.isRequired,
};
