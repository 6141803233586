/* eslint-disable */
import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Notifier from 'common/helpers/Notifier';
import { browserHistory } from 'common/utils/browserHistory';
import {
  BaseCard,
} from '@unite-us/app-components';
import {
  useInvalidateQueries,
} from 'src/api/APIHooks';
import {
  EL_ASSESSMENT_QUERY_KEYS,
  useElAssessment,
} from 'src/pages/facesheet/_id/eligibility/hooks/useEligibilityAssessments';
import {
  updateAssessment,
  useElAssessmentTemplateId,
} from './hooks/eligibilityApi';

const BUTTON_STYLE_A = 'h-12 px-16 py-3 border border-solid border-medium-border-blue rounded text-action-darker-blue';

const EligibilityConsent = ({
  personId,
  setLoading,
  screenId,
  employeeId,
  nextStep,
  answerConsent, // to deprecate
}) => {
  const invalidateQueries = useInvalidateQueries();
  const templateId = useElAssessmentTemplateId();
  const { data: assessment } = useElAssessment(
    screenId,
    { enabled: Boolean(screenId) }
  );

  const invalidateCommonQueries = () => {
    invalidateQueries(EL_ASSESSMENT_QUERY_KEYS.ASSESSMENTS);
    invalidateQueries(EL_ASSESSMENT_QUERY_KEYS.ASSESSMENT);
  };

  const answer = async (value) => {
    try {
      setLoading(true);

      // user declined Eligibility (Assessment) Consent
      if (value === false) {
        const response = await updateAssessment({
          personId,
          employeeId,
          status: 'declined',
          related_screen_id: get(assessment, 'data.screen.related_screen_id', null),
          outreach_status: 'success',
          templateId,
          assessmentId: screenId,
        });
        
        invalidateCommonQueries();
        setLoading(false);
        if (get(response, 'data.screen.id')) {
          Notifier.dispatch(200, 'Eligibility Assessment Declined');
          browserHistory.push(`/facesheet/${personId}/eligibility/view/${screenId}`);
          return;
        }
      }
      const isConsentAccepted = get(assessment, 'data.screen.consent') === 'accepted';
      if (!isConsentAccepted) {
        await updateAssessment({
          personId,
          employeeId,
          status: 'draft',
          // answers,
          related_screen_id: get(assessment, 'data.screen.related_screen_id', null),
          outreach_status: 'success',
          templateId,
          consent: 'accepted',
          assessmentId: screenId,
        });
        invalidateCommonQueries();
      }

      setLoading(false);
      browserHistory.push(`/facesheet/${personId}/eligibility/new/${screenId}/assessment`);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="form-submissions">
      <BaseCard className="bg-white">
        <div
          className="border border-solid border-medium-border-blue border-l-0 border-r-0 border-t-0"
        >
          <div className="pl-4 py-6">
            <h2 className="text-xl font-bold">Agreement to Eligibility Assessment</h2>
          </div>
        </div>

        <div className="bg-light-fill-grey px-6 pt-4 pl-4">
          <p className="text-md">
            {
              'I would like to see, based on your medical history including information Medicaid ' +
              'collects, if the client qualifies for any services that could be helpful and which ' +
              'would be free to the client. Is this okay with the client? Do they have any questions?'
            }
          </p>
          <p className="text-md mt-5">
            {
              'Please confirm that the information provided during this assessment is accurate ' +
              'to the best of the client\'s knowledge.'
            }
          </p>
          <div className="mt-24 pb-6">
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => answer(false)}
                className={BUTTON_STYLE_A}
              >
                <span className="inline-block align-middle">Client Declines</span>
              </button>

              <button
                type="button"
                onClick={() => answer(true)}
                className="h-12 px-16 py-3 bg-action-blue rounded text-white"
              >
                <span className="inline-block align-middle text-white">Client Accepts</span>
              </button>
            </div>
          </div>
        </div>
      </BaseCard>
    </div>
  );
};

EligibilityConsent.propTypes = {
  setLoading: PropTypes.func.isRequired,
  answerConsent: PropTypes.func.isRequired,
  // employeeId: PropTypes.string.isRequired,
  personId: PropTypes.string.isRequired,
};

export default EligibilityConsent;
