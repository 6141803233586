import React from 'react';
import PropTypes from 'prop-types';
import { Filter } from '@unite-us/client-utils';

const FilterNetwork = (props) => {
  const { options } = props;

  return (
    <div className="browse-filters-networks">
      <Filter
        hideSelectAll
        isSingleOptionSelect
        name="Network"
        filterKey="networks"
        onFiltersChange={props.onFiltersChange}
        options={options}
      />
    </div>
  );
};

FilterNetwork.propTypes = {
  options: PropTypes.array.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
};

export default FilterNetwork;
