import { APPLICATION_LOAD_FROM_SESSIONSTORAGE } from 'actions';

export default function applicationLoadFromSessionStorage(apiTokenFromSessionStorage) {
  return (dispatch) => {
    if (apiTokenFromSessionStorage) {
      const payload = { session: JSON.parse(apiTokenFromSessionStorage) };

      dispatch({ type: APPLICATION_LOAD_FROM_SESSIONSTORAGE, payload });
    }
  };
}
