// DEPRECATED FUNCTION, DO NOT USE. USE APP-COMPONENTS maskValidation INSTEAD.
function maskValidation(value, message, args) {
  if (!args) {
    return null;
  }
  var reg = new RegExp(args);
  if (value && !reg.test(value)) {
    return message || "Should match the format /".concat(args, "/");
  }
  return null;
}
export default maskValidation;