import PropTypes from 'prop-types';
import React from 'react';
import { CONSENT_OPTIONS } from '../../constants';

const ConsentDropzoneMessage = ({ type }) => {
  switch (type) {
    case CONSENT_OPTIONS.DOCUMENT:
      return <p>Drop signed consent form to attach or <a>browse</a></p>;
    case CONSENT_OPTIONS.AUDIO:
      return <p>Drop audio consent file to attach or <a>browse</a></p>;
    default:
      return <p>Drop file to attach or <a>browse</a></p>;
  }
};

ConsentDropzoneMessage.propTypes = {
  type: PropTypes.string,
};

export default ConsentDropzoneMessage;
