import { find, get } from 'lodash';

const getDashboardScreeningsFromState = (state) => {
  const contacts = get(state, 'contacts.contacts', []);
  const screenings = get(state, 'dashboard.screeningsAll', {});

  return {
    ...screenings,
    data: get(screenings, 'data', []).map((topLevelData) => ({
      ...topLevelData,
      data: topLevelData.data.map((screeningObj) => ({
        ...screeningObj,
        contact: find(contacts, { id: screeningObj.contactId }),
      })),
    })),
  };
};

export default getDashboardScreeningsFromState;
