/**
 * Convert array of objects to object
 * @param { array } data - an array of objects
 * @return { object } - keys will be ids and values will be object
 */
const arrayToObject = (data) => {
  const newData = {};

  if (!data) {
    return {};
  }

  data.forEach((obj) => {
    newData[`${obj.id}`] = obj;
  });

  return newData;
};

export default arrayToObject;
