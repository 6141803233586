import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
} from '@unite-us/ui';
import _ from 'lodash';
import callOrLog from 'src/common/utils/callOrLog';
import { SEARCH } from 'common/utils/EventTracker/utils/eventConstants';
import ContactCard from './ContactCard';
import Header from './Header';

export class SearchConfirmation extends Component {
  constructor(props) {
    super(props);

    this.onClientNotFound = this.onClientNotFound.bind(this);
  }

  componentDidMount() {
    const { contacts } = this.props;

    if (_.isEmpty(contacts)) {
      this.props.onNotFound();
    }
  }

  onClientNotFound() {
    const [contact] = this.props.contacts;

    // this is usually called when a user ignores the existing records and proceeds to create new client
    callOrLog(() => this.context.eventTracker(SEARCH.ignoredRecords, {
      contacts: this.props.contacts.length,
    }));

    this.props.onNotFound(contact);
  }

  render() {
    const {
      contacts,
      className,
      header,
      mainHeader,
      subHeader,
      assistanceRequest,
      submitting,
      searchContext,
    } = this.props;

    return (
      <div className={className}>
        <div className="referral-header">
          <Header
            header={header}
            mainHeader={mainHeader}
            subHeader={subHeader}
          />
        </div>

        <ul className="contact-list list-unstyled">
          {
            contacts.map((contact) => (
              <ContactCard
                key={contact.id}
                id={`contact-card-${contact.id}`}
                contact={contact}
                onContactConfirmation={this.props.onContactConfirmation}
                assistanceRequest={assistanceRequest}
                searchContext={searchContext}
              />
            ))
          }
        </ul>

        <hr />

        <div className="row">
          <div className="client-not-found col-md-12">
            <Button
              id="create-new-client"
              onClick={this.onClientNotFound}
              label="Create New Client"
              primary
              disabled={submitting}
            />
          </div>
        </div>
      </div>
    );
  }
}

SearchConfirmation.propTypes = {
  header: PropTypes.string.isRequired,
  className: PropTypes.string,
  subHeader: PropTypes.string.isRequired,
  mainHeader: PropTypes.string,
  contacts: PropTypes.array.isRequired,
  onNotFound: PropTypes.func.isRequired,
  onContactConfirmation: PropTypes.func,
  assistanceRequest: PropTypes.shape({
    id: PropTypes.string,
  }),
  submitting: PropTypes.bool.isRequired,
  searchContext: PropTypes.string.isRequired,
};

SearchConfirmation.defaultProps = {
  className: '',
  mainHeader: '',
  onContactConfirmation: () => {},
  assistanceRequest: null,
};

SearchConfirmation.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    contacts: _.wget(state, 'searchedContacts', []),
  };
}

export default connect(mapStateToProps)(SearchConfirmation);
