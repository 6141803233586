import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  BaseCard,
  BaseCardHeader,
  BaseCardBody,
} from '@unite-us/ui';
import { BackButton } from 'common/buttons';
import MilitaryInformation from 'src/components/Facesheet/Profile/MilitaryInformation';
import {
  fetchReferralContact,
  updateReferralContact,
} from 'actions/Contact/Referral/Group';
import _ from 'lodash';
import 'src/components/Facesheet/Profile/stylesheets/profile.scss';
import 'src/components/MilitaryInformation/stylesheets/military-information.scss';

class ReferralMilitaryInformation extends Component {
  constructor(props) {
    super(props);
    this.updateMilitaryInformation = this.updateMilitaryInformation.bind(this);
  }

  componentDidMount() {
    const { groupId, referralId } = this.props;
    this.props.fetchReferralContact(groupId, referralId);
  }

  updateMilitaryInformation(data) {
    const { groupId, referralId } = this.props;
    this.props.updateReferralContact(groupId, referralId, { military: data });
  }

  render() {
    const {
      contact,
      enums,
      groupId,
      isFetching,
    } = this.props;

    return (
      <section className="container" data-role="military-information">
        <BackButton />
        <BaseCard className="referral-military-information">
          <BaseCardHeader title="Military Information" />
          <BaseCardBody>
            <MilitaryInformation
              contact={contact}
              enums={enums}
              groupId={groupId}
              hideHeader
              loading={isFetching}
              updateMilitaryInformation={this.updateMilitaryInformation}
            />
          </BaseCardBody>
        </BaseCard>
      </section>
    );
  }
}

ReferralMilitaryInformation.propTypes = {
  contact: PropTypes.object.isRequired,
  enums: PropTypes.object.isRequired,
  fetchReferralContact: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  referralId: PropTypes.string.isRequired,
  updateReferralContact: PropTypes.func.isRequired,
};

ReferralMilitaryInformation.defaultProps = {
  contact: {},
  isFetching: false,
};

function mapStateToProps(state, ownProps) {
  const { contacts: contactsObj, session } = state;
  const contacts = _.get(contactsObj, 'contacts', []);
  const isFetching = contactsObj.isFetching;
  const params = _.get(ownProps, 'params', {});
  const referralId = _.get(ownProps, 'params.id');
  const contact = _.find(contacts, { id: params.contact_id }) || {};

  return {
    contact,
    contacts,
    enums: _.get(session, 'enums', {}),
    groupId: _.get(session, 'groupId'),
    isFetching,
    referralId,
  };
}

export default connect(mapStateToProps, {
  fetchReferralContact,
  updateReferralContact,
})(ReferralMilitaryInformation);
