import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'common/Card';
import { ALL_AUTHORIZATION_PAGE_STATUSES } from 'common/Status/authorizationConstants';
import NoRequestsFoundLogo from 'common/TailwindComponents/NoRequestsFoundLogo';
import NoFilteredServiceAuthorizationsFound from './NoFilteredServiceAuthorizationsFound';
import { NoServiceAuthorizationsExist } from './NoServiceAuthorizationsExist';

const AUTHORIZATION_STATUSES_TO_NOTHING_FOUND_TEXT = {
  requested: 'open',
  approved: 'accepted',
  denied: 'rejected',
};

const NoServiceAuthorizationsContent = ({ isFiltered, resetFilters, status }) => {
  if (isFiltered) return <NoFilteredServiceAuthorizationsFound resetFilters={resetFilters} />;
  if (status === ALL_AUTHORIZATION_PAGE_STATUSES) return <NoServiceAuthorizationsExist />;
  return (
    <>
      <NoRequestsFoundLogo />
      <h4 className="font-heavy-font text-base text-grey">Nothing to see here!</h4>
      <p className="max-w-xs text-grey">
        You don&apos;t have any {AUTHORIZATION_STATUSES_TO_NOTHING_FOUND_TEXT[status]} authorization requests to review.
      </p>
    </>
  );
};

export const NoServiceAuthorizationsFoundCard = (props) => (
  <Card className="bg-white flex flex-col justify-center items-center text-center pt-40 pb-48 space-y-4">
    <NoServiceAuthorizationsContent {...props} />
  </Card>
);

NoServiceAuthorizationsContent.propTypes = {
  isFiltered: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

NoServiceAuthorizationsFoundCard.propTypes = {
  isFiltered: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};
