export function panelPaddingStyles() {
  return {
    padding: '20px 0px 25px 0px',
  };
}

export function panelCardHeaderStyles() {
  return {
    padding: '4px 0px 4px 0px',
  };
}

export function panelPostHeaderStyles() {
  return {
    padding: '25px 0px 25px 0px',
  };
}

export function emptyPanelPadding() {
  return {
    padding: '0',
  };
}
