import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { browserHistory } from 'common/utils/browserHistory';
import { createGroupsInvitation } from 'actions/Group';

const fields = ['email', 'first_name', 'last_name', 'send_immediately'];

class GroupsInvitationsNew extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(formData) {
    return new Promise((resolve) => {
      this.props.createGroupsInvitation(this.props.groupId, formData).then(() => resolve());
    })
      .then(() => browserHistory.push('admin/users'));
  }

  render() {
    const {
      fields: {
        email, first_name, last_name, send_immediately,
      }, handleSubmit,
    } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            required="required"
            className="form-control"
            placeholder="Enter an email address..."
            {...email}
          />
        </div>
        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            required="required"
            className="form-control"
            placeholder="Enter first name..."
            {...first_name}
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            required="required"
            className="form-control"
            placeholder="Enter last name..."
            {...last_name}
          />
        </div>
        <div className="checkbox">
          <label htmlFor="sendImmediatelyInput">
            <input
              type="checkbox"
              {...send_immediately}
            /> Send Invitation Email Immeditately
          </label>
        </div>
        <button
          className="btn btn-primary"
          type="submit"
        >
          Submit
        </button>
      </form>
    );
  }
}

GroupsInvitationsNew.propTypes = {
  groupId: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  createGroupsInvitation: PropTypes.func.isRequired,
  location: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  return {
    groupId: state.session.groupId,
    initialValues: {
      send_immediately: true,
      email: ownProps.location.query.email,
      first_name: ownProps.location.query.first_name,
      last_name: ownProps.location.query.last_name,
    },
  };
}

export default reduxForm({
  form: 'newInvitation',
  fields,
}, mapStateToProps, { createGroupsInvitation })(GroupsInvitationsNew);
