export const SCREENING_STATUSES = {
  notStarted: 'not_started',
  inProgress: 'in_progress',
  completed: 'completed',
  exempted: 'exempted',
};

export const RISK_LEVELS = {
  highRisk: 'high_risk',
};
