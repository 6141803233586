import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AddButton, RemoveButton } from 'common/buttons';
import { noop } from 'lodash';

class BrowseActions extends Component {
  constructor(props) {
    super(props);

    this.toggleSelectGroup = this.toggleSelectGroup.bind(this);
  }

  toggleSelectGroup(e) {
    e.stopPropagation();
    this.props.selectGroup(this.props.group);
  }

  render() {
    const {
      id,
      selected,
    } = this.props;

    return (
      <div className="browse-actions">
        {
          selected ? (
            <RemoveButton
              id={`${id}-select-btn`}
              onClick={this.toggleSelectGroup}
            />
          ) : (
            <AddButton
              id={`${id}-select-btn`}
              onClick={this.toggleSelectGroup}
            />
          )
        }
      </div>
    );
  }
}

BrowseActions.propTypes = {
  group: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  selectGroup: PropTypes.func,
};

BrowseActions.defaultProps = {
  selected: false,
  selectGroup: noop,
};

export default BrowseActions;
