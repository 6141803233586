import { BaseCard, BaseCardBody } from '@unite-us/ui';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import React from 'react';
import parse from 'html-react-parser';
import './FeeScheduleProgramElegibility.scss';

const FeeScheduleProgramElegibility = (props) => {
  const description = props?.feeScheduleProgram?.description;
  const eligibility = props?.feeScheduleProgram?.eligibility;
  const billing_limits = props?.feeScheduleProgram?.billing_limits;

  return (
    <BaseCard className="fee-schedule-program-elegibility px-8 pb-8" noBorder>
      <BaseCardBody withPadding>
        {
          !isEmpty(props.feeScheduleProgram) ? (
            <div className="row">
              <div className="col-md-6 list-divider">
                <h2 className="detail-info__icon-label p-4 font-bold">Description</h2>
                <p className="px-4" data-testid="fs-prog-description">{description ? parse(description) : ''}</p>
                <h2 className="detail-info__icon-label p-4 font-bold">Eligibility</h2>
                <p className="px-4" data-testid="fs-prog-eligibility">{eligibility ? parse(eligibility) : ''}</p>
                <h2 className="detail-info__icon-label p-4 font-bold">Billing Limits</h2>
                <p className="px-4" data-testid="fs-prog-billing-limits">
                  {billing_limits ? parse(billing_limits) : ''}
                </p>
              </div>
            </div>
          ) : null
        }
      </BaseCardBody>
    </BaseCard>
  );
};

FeeScheduleProgramElegibility.propTypes = {
  feeScheduleProgram: PropTypes.object.isRequired,
};

export default FeeScheduleProgramElegibility;
