const getIntercomCompanies = (groups) => {
  const companies = [];
  groups.forEach((groupObj) => {
    const { group } = groupObj;
    const providerExists = companies.find(
      (company) => company.company_id === group.id && company.name === group.name,
    );
    if (!providerExists) {
      companies.push({
        company_id: group.id,
        name: group.name,
      });
    }
    group.networks.forEach((network) => {
      const networkExists = companies.find(
        (company) => company.company_id === network.id && company.name === network.name,
      );
      if (!networkExists) {
        companies.push({
          company_id: network.id,
          name: network.name,
        });
      }
    });
  });
  return companies;
};

export default getIntercomCompanies;
