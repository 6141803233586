import PropTypes from 'prop-types';
import React from 'react';
import { colors } from '@unite-us/client-utils';
import { Badge } from '@unite-us/ui';
import DetailActionWrapper from 'common/display/SingleItemDetails/DetailActionWrapper';
import Options from 'src/components/Referrals/ReferralStatus/components/Options';
import _ from 'lodash';

const ReferralDetailActionWrapper = ({
  disableActions,
  falsifyActionBool,
  isSendable,
  location,
  referral,
  showActions,
  styles,
}) => {
  if (!_.isEmpty(_.get(referral, 'case.outcome'))) {
    return (
      <DetailActionWrapper
        buttonComp={(
          <Badge
            text="REFERRAL CLOSED"
            color={colors.palettes.LIGHT_GREY}
            shape="square"
            style={styles.badgeStyles.textStyle}
          />
        )}
      />
    );
  }

  return showActions ? (
    <DetailActionWrapper
      buttonComp={(
        <Options
          disabled={disableActions}
          pathName={location.baseLocation}
          referral={referral}
          falsifyActionBool={falsifyActionBool}
          isSendable={isSendable}
        />
      )}
    />
  ) : null;
};

ReferralDetailActionWrapper.propTypes = {
  disableActions: PropTypes.bool,
  isSendable: PropTypes.bool.isRequired,
  showActions: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    baseLocation: PropTypes.string,
  }).isRequired,
  referral: PropTypes.object.isRequired,
  falsifyActionBool: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired,
};

ReferralDetailActionWrapper.defaultProps = {
  disableActions: false,
  styles: {
    badgeStyles: {
      textStyle: {
        color: 'white',
      },
    },
  },
};

export default ReferralDetailActionWrapper;
