import { useFindRecord } from 'api/APIHooks';

const usePayerWQInteractiveView = (payerId) => {
  const { isLoading, data } = useFindRecord(
    'payer',
    payerId,
    {
      queryConfig: {
        enabled: !!payerId,
        placeholderData: undefined,
      },
    },
  );

  const payerWQInteractiveView = data?.data?.data?.payer_wq_interactive;

  return { isLoading, payerWQInteractiveView };
};

export default usePayerWQInteractiveView;
