import { coreApi } from 'src/api/config';
import { isEmpty } from 'lodash';

function uploadAndAttachDocumentsToReferral({
  // attach_to_contact = false,
  // resourceIds = [], // IDs in addition to the referralId.
  caseId,
  attachedDocuments,
  uploadedDocuments,
}) {
  const attachDocumentsToResource = () => (
    coreApi.setRelationship(
      'case',
      caseId,
      { relationshipModel: 'file_upload', value: attachedDocuments.map((a) => a.id) },
    )
  );

  const uploadDocumentsToResource = () => (
    uploadedDocuments.map((d) => coreApi.uploadFile('case', caseId, d))
  );

  const uploadPromises = !isEmpty(uploadedDocuments) && uploadDocumentsToResource();

  return Promise.all([
    !isEmpty(attachedDocuments) && attachDocumentsToResource(),
    ...(uploadPromises || []),
  ]);
}

export default uploadAndAttachDocumentsToReferral;
