import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  ENTER_KEY_CODE,
  SPACEBAR_KEY_CODE,
} from 'src/components/Navigation/constants';

class LeftNavLink extends Component {
  constructor() {
    super();
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onKeyDown({ keyCode }) {
    const {
      onLeftNavLinkClick,
      path,
      eventType,
    } = this.props;

    if (keyCode === ENTER_KEY_CODE || keyCode === SPACEBAR_KEY_CODE) {
      return onLeftNavLinkClick(path, eventType);
    }
    return false;
  }

  render() {
    const {
      active,
      children,
      eventType,
      id,
      onLeftNavLinkClick,
      path,
    } = this.props;

    return (
      <li
        tabIndex={0}
        id={id}
        onKeyDown={this.onKeyDown}
        onClick={() => onLeftNavLinkClick(path, eventType)}
        className={active ? 'left-nav-link--active left-nav-link' : 'left-nav-link'}
      >
        {children}
      </li>
    );
  }
}

LeftNavLink.defaultProps = {
  onLeftNavLinkClick: _.noop(),
};

LeftNavLink.propTypes = {
  id: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.node,
  eventType: PropTypes.string,
  onLeftNavLinkClick: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};

export default LeftNavLink;
