import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'common/spinners';
import { useFindLicensedProvidersByName } from 'src/components/Organization/api/hooks/v1/providerHooks';
import { DEFAULT_PAGE_SIZE } from 'src/components/Backoffice/backofficeUtils';
import DynamicSearchTable from './DynamicSearchTable';

const OrganizationSearchTable = ({ selectedOrganizationId, setSelectedOrganizationId }) => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const [providerName, setProviderName] = useState('');

  const { data: providersResponse, isFetching } = useFindLicensedProvidersByName(
    providerName,
    {
      page: {
        size: pageSize,
        number: pageNumber,
      },
    },
  );
  const { data: providers, paging = { total_count: 0, total_pages: 0 } } = providersResponse.data;

  useEffect(() => {
    setPageNumber(1);
  }, [providerName]);

  const generateDataForTable = () => {
    const data = [];
    providers.forEach((provider) => {
      data.push({
        id: provider.id,
        data: [provider.name, provider.id],
      });
    });
    return data;
  };

  if (isFetching) return <Spinner />;

  return (
    <>
      <DynamicSearchTable
        searchResults={generateDataForTable()}
        rowHeaders={['Name', 'Organization ID']}
        selectedRowIds={selectedOrganizationId}
        setSelectedRowIds={setSelectedOrganizationId}
        setSearch={setProviderName}
        emptyTableMessage={'No Results Found. Enter Organization to view users.'}
        searchBarPlaceHolder={'Enter the Organization Name...'}
        searchTitle={'Organization Name'}
        dataTestId={'organization-search'}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        paging={paging}
      />
    </>
  );
};

OrganizationSearchTable.propTypes = {
  selectedOrganizationId: PropTypes.string.isRequired,
  setSelectedOrganizationId: PropTypes.func.isRequired,
};

export default OrganizationSearchTable;
