import { compact, find, isEmpty, map, orderBy } from 'lodash';
import { PRIMARY_DISPLAY } from '../constants';

function communicationTypeDisplayName(contactCommunicationTypesEnums, value) {
  const displayName = (find(contactCommunicationTypesEnums, { value }) || {}).display_name;
  return displayName && displayName.toLowerCase();
}

function getAdditionalInfoDisplay({
  communicationTypes = [],
  enums = [],
  isPrimary = false,
}) {
  if (!isPrimary && isEmpty(communicationTypes)) {
    return '';
  }
  const items = compact([
    isPrimary && PRIMARY_DISPLAY,
    ...orderBy(map(communicationTypes, (type) => communicationTypeDisplayName(enums, type))),
  ]);
  return `(${items.join(', ')})`;
}

export default getAdditionalInfoDisplay;
