import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { useUpdateRecord, useInvalidateQueries } from 'src/api/APIHooks';
import Notifier from 'common/helpers/Notifier';
import { every } from 'lodash';

export const useBulkUpdate = (model, ids, params, successMessage) => {
  const invalidateQueries = useInvalidateQueries();
  const { updateRecord } = useUpdateRecord(model, {
    mutationConfig: {
      onError: () => {
        Notifier.dispatch('error', 'Something went wrong...');
      },
     },
  });
  return async () => {
    const rowsUpdated = await Promise.all(ids.map((id) => updateRecord(id, params)));
    const httpSuccess = every(rowsUpdated.map((row) => isHttpSuccess(row.status)));
    if (httpSuccess) {
       Notifier.dispatch(
       rowsUpdated[0].status,
        successMessage,
      );
    } else {
      Notifier.handleErrors('There was an error. Some or all rows were not properly updated');
    }
    invalidateQueries(`${model}s`);
  };
};
