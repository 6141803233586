import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';
import '../stylesheets/headerClickToExpand.scss';

function linkClasses(isExpanded) {
  return classNames({
    'header-click-to-expand': true,
    minimized: !isExpanded,
    expanded: isExpanded,
  });
}

const HeaderClickToExpand = ({
  children,
  isExpanded,
  onHeaderClick,
}) => (
  <div
    className={linkClasses(isExpanded)}
    role="button"
    onClick={!isExpanded && onHeaderClick}
  >
    {children}
  </div>
);

HeaderClickToExpand.propTypes = {
  children: PropTypes.node.isRequired,
  isExpanded: PropTypes.bool,
  onHeaderClick: PropTypes.func,
};

HeaderClickToExpand.defaultProps = {
  isExpanded: false,
  onHeaderClick: noop,
};

export default HeaderClickToExpand;
