import { get, isError } from 'lodash';

function isHttpError(possibleError, status = '') {
  if (isError(possibleError)) {
    return !status || get(possibleError, 'response.status') === status;
  }
  return false;
}

export default isHttpError;
