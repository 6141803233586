import _ from 'lodash';

export function updateElement(state, toUpdateId, element, target) {
  return _.assign({}, state, {
    [target]: {
      ...state[target],
      data: _.map(state[target].data, (page) => {
        const pageData = _.get(page, 'data', []);
        if (_.some(pageData, { id: toUpdateId })) {
          return _.assign({}, page, {
            ...page,
            data: _.reduce(pageData, (acc, curr) => {
              if (curr.id === toUpdateId) {
                return [
                  ...acc,
                  element,
                ];
              }

              return [
                ...acc,
                curr,
              ];
            }, []),
          });
        }

        return page;
      }),
    },
  });
}

export default function updateElements(state, payload = [], urlData, target, currentSection) {
  if (target !== currentSection) { return state; }
  if (!_.has(state, target)) { return state; }
  if (_.isEmpty(payload)) { return state; }

  const toUpdateId = _.get(urlData, 'uuid', '');

  return updateElements(
    updateElement(state, toUpdateId, _.head(payload), target),
    _.tail(payload),
    target,
    currentSection,
  );
}
