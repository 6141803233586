import React from 'react';
import PropTypes from 'prop-types';
import InvoiceWorkqueue from './InvoiceWorkqueue';
import { DRAFT_STATUSES } from '../constants';
import { NoInvoicesFoundCard } from '../components';

const DraftInvoices = ({ children }) => {
  const invoiceStatus = DRAFT_STATUSES;
  const bulkActions = ['IconDownloadV2', 'IconArchive'];

  return (
    <InvoiceWorkqueue
      invoiceStatus={invoiceStatus}
      bulkActions={bulkActions}
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <NoInvoicesFoundCard isFiltered={isFiltered} status="draft" resetFilters={resetFilters} />
      )}
      showStatusFilter
      showInvoiceAging
      statusOptionsConstant="DRAFT_STATUS_OPTIONS"
      path="draft/"
    >
      {children}
    </InvoiceWorkqueue>
  );
};

DraftInvoices.propTypes = {
  children: PropTypes.node,
};

DraftInvoices.defaultProps = {
  children: undefined,
};
export default DraftInvoices;
