import PropTypes from 'prop-types';
import React from 'react';
import { DynamicTable } from 'common/tables';
import _ from 'lodash';
import { Spinner } from 'common/spinners';
import FilterBar from '../FilterBar';
import DashboardRowIcon from '../DashboardRowIcon';

function getColumns() {
  return [
    {
      label: 'Client Name',
      sortColumn: 'requestor.full_name',
    },
    {
      label: 'Service Type',
      sortColumn: 'service_type.name',
    },
    {
      label: 'Last Updated',
      sortColumn: 'updated_at',
      type: 'date',
    },
  ];
}

const ProcessedIndex = (props) => {
  const {
    paging,
    data,
    onNextClick,
    onPrevClick,
    isFetching,
    onCellClick,
    initialSort,
    fetchGroupsUsers,
    filters,
    onFiltersChange,
  } = props;

  return (
    <div>
      <FilterBar
        filters={filters}
        onFiltersChange={onFiltersChange}
        paging={paging}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
        isFetching={isFetching && !_.isEmpty(data)}
        asyncSearch={fetchGroupsUsers}
      />
      <div className="dashboard-inner-content">
        {
          (isFetching && _.isEmpty(data)) ?
            <Spinner /> : (
              <DynamicTable
                id="processed-assistance-requests-table"
                dataSet={data}
                onCellClick={onCellClick}
                initialSort={initialSort}
                columnLabels={getColumns()}
                emptyMessage="There are no assistance requests."
                rowIcon={DashboardRowIcon}
              />
            )
        }
      </div>
    </div>
  );
};

ProcessedIndex.propTypes = {
  data: PropTypes.array.isRequired,
  filters: PropTypes.array,
  initialSort: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  onCellClick: PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  fetchGroupsUsers: PropTypes.func.isRequired,
  paging: PropTypes.shape({
    current_page: PropTypes.number,
    next_page: PropTypes.number,
    prev_page: PropTypes.number,
  }).isRequired,
};

export default ProcessedIndex;
