import Notifier from 'common/helpers/Notifier';
import { coreApi } from 'src/api/config';

export default function fetchCommunicationConsent(contactId, providerId) {
  return async () => {
    try {
      const response = await coreApi.query('communication', {
        category: 'consent_request',
        person: contactId,
        'sender.provider': providerId,
      });

      return response;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}
