import { coreApi } from 'src/api/config';
import _ from 'lodash';
import { FETCH_EXPORTS } from 'actions';
import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { convertCoreDocuments } from 'src/api/utils/fileUploadUtils';
import { assistanceRequestsExportsAvailable } from 'common/utils/FeatureFlags/flags';

const defaultOptions = {
  sidx: 'created_at',
  sord: 'desc',
  filters: {},
  export_types: [],
  page: 1,
  per: 10,
};

function onError(error) {
  Notifier.handleErrors(error);
  return error;
}

function onSuccess(dispatch, response, options) {
  dispatch({
    type: FETCH_EXPORTS,
    payload: response,
    filters: options.filters,
  });

  return response;
}

const fetchExports = (groupId, options = defaultOptions) => async (dispatch, getState) => {
  const state = getState();
  const isAssistanceRequestsExportsAvailable = assistanceRequestsExportsAvailable(state);

  const allExportTypes = [
    'assessments',
    'screenings',
    'cases',
    'clients',
    'referrals',
    'users',
    'notes',
  ];

  if (isAssistanceRequestsExportsAvailable) {
    allExportTypes.push('assistance_requests', 'assistance_requests_supplemental_responses');
  }

  try {
    const exportTypes = !_.isEmpty(options.filters) ?
      options.filters.export_types.join(',') : allExportTypes.join(',');
    const request = await coreApi.query('export', {
        'requester.provider': groupId,
        export_type: exportTypes,
    }, {
        page: {
          ...options.page,
        },
    });

    const exportsResponse = request.data.data;
    const fileUploadPromises = [];
    await coreApi.populateRelationship('requester', 'employee', exportsResponse);

    for (let i = 0; i < exportsResponse.length; i++) {
      const e = exportsResponse[i];
      // eslint-disable-next-line no-continue
      if (_.isEmpty(e.file_uploads)) { continue; }

      const promise = coreApi.query('file_upload', {
        record: e.id,
        'record.type': 'export',
      });
      promise.then((documentsResponse) => {
        if (isHttpSuccess(documentsResponse.status) && e.state === 'completed') {
          let documents = documentsResponse.data.data;
          documents = convertCoreDocuments(documents);
          e.documents = documents;
        }
      });
      fileUploadPromises.push(promise);
    }

    await Promise.all(fileUploadPromises);

    const exportsPayload = {
      ...request,
      data: {
        ...request.data,
        data: exportsResponse,
      },
    };

    onSuccess(dispatch, exportsPayload, options);
    return exportsPayload;
  } catch (error) {
    return onError(error);
  }
};

export default fetchExports;
