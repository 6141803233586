import {
  map, find, get, isObject, mapValues, filter, isEmpty,
} from 'lodash';
import { flattenServiceTypes } from 'common/utils/ServiceTypes';
import { getOONGroupNameFromField } from 'src/components/Referrals/ReferralFormFields/OONGroupsSelector/utils';
import { getAttachedDocumentsFromField } from './form';

const keepParent = true;

function getReferralDetailsFromForm({
  fields, serviceTypes, networks, isProgramBasedSearch,
}) {
  const flatServiceTypes = flattenServiceTypes(serviceTypes, keepParent);
  const serviceTypeValue = get(fields, 'service_type.value', '');
  const serviceTypeId = isObject(serviceTypeValue) ? get(serviceTypeValue, 'id', '') : serviceTypeValue;
  const referredByNetworkId = get(fields, 'referred_by_network.value.id');
  const referredToNetworkId = get(fields, 'referred_to_network.value.id') ||
    get(fields, 'referred_by_network.value.id');

  const isOONCase = get(fields, 'isOONCase.checked', false);
  let groupProgramFields = [...get(fields, 'selected', [])];
  let customProgramFields = [];
  if (isOONCase) {
    groupProgramFields = [...get(fields, 'oonCase.selected', [])];
    customProgramFields = [...get(fields, 'oonCase.custom', [])];
  }
  const filteredFields = filter(groupProgramFields.concat(customProgramFields), (field) => !isEmpty(field.group.value));
  const groups = map(filteredFields, (groupProgram = {}) => {
    const group = groupProgram?.group;
    const program = groupProgram?.program;
    const programName = get(program, 'value.attributes.name', null);
    const groupName = getOONGroupNameFromField({ group });

    if (programName) {
      return isProgramBasedSearch ? {
        name: `${programName} | ${groupName}`,
      } : {
        name: `${groupName} - ${programName}`,
      };
    }

    return {
      name: `${groupName}`,
    };
  });

  const hasAuthorizationRequest = get(fields, 'authorizationRequest.amount_requested.value');

  const authorizationRequest = hasAuthorizationRequest ? {
    ...mapValues(fields.authorizationRequest, (value) => value.value),
      fee_schedule_program_id: fields.selected[0]
        .program.value.relationships.fee_schedule_program.data.id,
  } : {};

  return {
    attachedDocuments: getAttachedDocumentsFromField(get(fields, 'attachableDocuments', [])),
    documents: get(fields, 'uploadedDocuments.value', []),
    groups,
    notes: get(fields, 'notes.value', ''),
    referredByNetwork: find(networks, { id: referredByNetworkId }) || {},
    referredToNetwork: find(networks, { id: referredToNetworkId }) || {},
    serviceType: find(flatServiceTypes, { id: serviceTypeId }) || serviceTypeValue || {},
    isOONCase,
    authorizationRequest,
  };
}

export default getReferralDetailsFromForm;
