import { get, isNil } from 'lodash';

/**
 * Only return programs that are receiving referrals and have the correct service type
 * @param {array} programs - The suggested Group's programs.
 * @param {object} serviceType - The selected service type in referral creation
 * @param {boolean} usePaymentsUserRole - whether or not the user has the payments user role
*/

const filterProgramsReceivingReferralsWithST = (programs, serviceType, usePaymentsUserRole) => {
  const serviceTypeId = isNil(serviceType) ? '' : get(serviceType, 'id', '');
  const groupPrograms = isNil(programs) ? [] : programs;

  const filteredPrograms = groupPrograms
  .filter(Boolean)
  .filter(({
    provided_service_type_ids = [],
    receiving_referrals,
    fee_schedule_program,
  }) => (
    receiving_referrals &&
    provided_service_type_ids.includes(serviceTypeId) &&
    (usePaymentsUserRole || isNil(fee_schedule_program))
  ));

  return filteredPrograms || [];
};

export default filterProgramsReceivingReferralsWithST;
