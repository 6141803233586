import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'common/utils/browserHistory';
import { validateReduxForm } from 'common/form';
import {
  Button,
  BaseCard,
  BaseCardBody,
  CheckBoxField,
} from '@unite-us/ui';
import { UULogoWithText } from '@unite-us/client-utils';
import { acceptEulaAgreement } from 'actions/Agreement';
import Eula from 'src/components/SystemAgreements/EulaAgreement/components/Eula';
import callOrLog from 'src/common/utils/callOrLog';
import { LOGIN } from 'common/utils/EventTracker/utils/eventConstants';
import { isSuccess } from 'common/utils/Notifications/utils';
import { get } from 'lodash';

const fields = [
  'eulaAccepted',
];

export class EulaAgreement extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    return this.props.acceptEulaAgreement()
      .then((response) => {
        if (isSuccess(get(response, 'status'))) {
          callOrLog(() => this.context.eventTracker(LOGIN.acceptEulaAgreement));
          if (!this.props.noRedirect) {
            browserHistory.push('/user/settings');
          }
        }
      });
  }

  render() {
    const {
      fields: {
        eulaAccepted,
      },
      handleSubmit,
      submitting,
    } = this.props;

    const allTermsAccepted = eulaAccepted.value;
    const checkBoxLabelText = 'I hereby acknowledge that I have read, understand, and agree to the End User License Agreement above.'; /* eslint-disable-line max-len */

    return (
      <div className="eula-agreement container">
        <div className="eula-agreement__logo">
          <UULogoWithText />
        </div>

        <div className="eula-agreement__content">
          <BaseCard>
            <BaseCardBody withPadding>
              <Eula />
              <form
                className="eula-confirmation"
                onSubmit={handleSubmit(this.onSubmit)}
              >
                <CheckBoxField
                  field={eulaAccepted}
                  id="eulaAccepted"
                  label={checkBoxLabelText}
                  showError={false}
                  showHint={false}
                />

                <Button
                  disabled={!allTermsAccepted || submitting}
                  id="eula-agree-btn"
                  label="I Agree"
                  onClick={handleSubmit(this.onSubmit)}
                  primary
                />
              </form>
            </BaseCardBody>
          </BaseCard>
        </div>
      </div>
    );
  }
}

EulaAgreement.propTypes = {
  acceptEulaAgreement: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  noRedirect: PropTypes.bool,
};

EulaAgreement.defaultProps = {
  noRedirect: false,
};

EulaAgreement.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default validateReduxForm(
  {
    form: 'eula-confirmation',
    fields,
    destroyOnUnmount: true,
  },
  null,
  { acceptEulaAgreement },
)(EulaAgreement);
