import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getDeterminantNetworkId, networkIdExists } from 'src/components/Referrals/utils/form';
import { ServiceTypeSelectField } from '@unite-us/client-utils';
import { shouldRefetchServiceTypes } from './utils';
import './ReferralServiceTypeSelect.scss';

class ReferralServiceTypeSelect extends Component {
  componentDidMount() {
    const networkId = getDeterminantNetworkId(this.props.fields);

    if (!_.isEmpty(networkId)) {
      this.props.updateServiceTypes();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Reactive to hide or show group options
    // Need to refactor to have the group field be responsible for this
    if (!networkIdExists(nextProps.fields) && nextProps.canSearch) {
      this.props.setCanSearch(false);
    }

    if (shouldRefetchServiceTypes(this.props, nextProps)) {
      this.props.updateServiceTypes();
    }
  }

  render() {
    const {
      className, fields, id, ...props
    } = this.props;

    const selectFieldClass = () => classNames({
      'referral-service-type-select': true,
      [className]: !!className,
    });

    // @todo I don't think having multiple ids is useful here.  We're not
    // passing an id to any instances of this component anyhow.  Going to check
    // with QA and see if the "service-type" id is used in testing.
    const selectFieldId = () => classNames({
      'service-type': true,
      [id]: !!id,
    });

    return (
      <ServiceTypeSelectField
        className={selectFieldClass()}
        field={fields.service_type}
        id={selectFieldId()}
        {...props}
      />
    );
  }
}

ReferralServiceTypeSelect.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  registerField: PropTypes.func.isRequired,
  setCanSearch: PropTypes.func.isRequired,
  updateServiceTypes: PropTypes.func.isRequired,
  validations: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  canSearch: PropTypes.bool.isRequired,
  touch: PropTypes.func.isRequired,
};

ReferralServiceTypeSelect.defaultProps = {
  className: '',
  forceObjectValue: true,
  id: '',
  options: [],
  placeholder: 'Choose one...',
  required: true,
  searchChoices: false,
  searchPlaceholderValue: 'Search for the service your client seeks',
};

export default ReferralServiceTypeSelect;
