import { ROUTE_BASEPATH } from 'src/config/env/env.config';
import { ORG_STATES } from 'src/components/Organization/constants';

export const containsOrgStateParam = (searchParams, state) => {
    if (searchParams) {
        const params = new URLSearchParams(searchParams);
        return params.get('state') === state;
    }
    return false;
};

export const updateOrgStateParam = (searchParams, state) => {
    const params = new URLSearchParams(searchParams || '');
    params.set('state', state);
    return `?${params.toString()}`;
};

export const mapOrgStateToParam = (state) => {
    if (state === ORG_STATES.active || state === ORG_STATES.draft) {
        return state;
    }
    if (state === ORG_STATES.draftSubmitted ||
        state === ORG_STATES.draftInReview ||
        state === ORG_STATES.draftNeedsAction ||
        state === ORG_STATES.draftPreLaunchConfigured) {
        return 'readonly';
    }
    return null;
};

export const getOrgPathname = (pathname) => {
    if (!pathname) return '/';
    // Remove the basepath to ensure the basepath is not appended twice, e.g. devqa branches
    return ROUTE_BASEPATH === '/' ? pathname : pathname.replace(ROUTE_BASEPATH, '');
};

export const isOrgInDraft = (state) => {
    if (state === ORG_STATES.draft ||
        state === ORG_STATES.draftSubmitted ||
        state === ORG_STATES.draftInReview ||
        state === ORG_STATES.draftNeedsAction ||
        state === ORG_STATES.draftPreLaunchConfigured) {
        return true;
    }
    return false;
};
