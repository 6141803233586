import Notifier from 'common/helpers/Notifier';

export const flattenDataAndThrowErrors = (response) => {
  if (response.name === 'Error') { throw response; }
  return response?.data?.data;
};

export const flattenResponseAndThrowErrors = (response) => {
  if (response.name === 'Error') { throw response; }
  return response?.data;
};

export const notifySomethingWentWrong = () => {
  Notifier.dispatch('error', 'Something went wrong...');
};

export const defaultQueryConfig = {
  select: flattenDataAndThrowErrors,
  onError: notifySomethingWentWrong,
  // 1 hour stale time
  staleTime: 1000 * 60 * 60,
  refetchOnWindowFocus: false,
};

export const defaultPagingQueryConfig = {
  select: flattenResponseAndThrowErrors,
  onError: notifySomethingWentWrong,
  // 1 hour stale time
  staleTime: 1000 * 60 * 60,
  refetchOnWindowFocus: false,
};

export const defaultMutationConfig = {
  select: flattenDataAndThrowErrors,
  onError: notifySomethingWentWrong,
};
