import React from 'react';
import PropTypes from 'prop-types';
import { PaginationInformation, PageSelection, PageSizeSelector } from 'src/common/Pagination';

const ServiceAuthorizationPagination = ({
  pageNumber,
  pageSize,
  setPageNumber,
  setPageSize,
  totalItemCount,
  totalPageCount,
}) => (
  <nav aria-label="Table navigation" className="flex justify-between items-end text-14px space-x-20">
    <PaginationInformation
      className="font-regular-font-roman text-text-blue"
      pageNumber={pageNumber}
      pageSize={pageSize}
      totalItemCount={totalItemCount}
    />
    {totalItemCount ? (
      <>
        <PageSelection
          className="flex-grow max-w-3xl"
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          totalPageCount={totalPageCount}
        />
        <PageSizeSelector setPageNumber={setPageNumber} pageSize={pageSize} setPageSize={setPageSize} />
      </>
    ) : null}
  </nav>
);

ServiceAuthorizationPagination.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  totalItemCount: PropTypes.number.isRequired,
  totalPageCount: PropTypes.number.isRequired,
};

export default ServiceAuthorizationPagination;
