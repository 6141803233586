import React from 'react';
import { PropTypes } from 'prop-types';

const UserSubheader = ({ isOrgActive, showDraftStepper }) => (

  <div className={`px-1 ${showDraftStepper ? 'pt-5 pb-5' : 'pt-2'}`}>
    {
      isOrgActive ?
        (
          <p>
            Add users and invite them to use the Unite Us Platform.
            Each user needs a separate account with a unique email address.
          </p>
        ) :
        (
          <p>
            {`${showDraftStepper ? 'Next' : 'First'}, review your user account and attach yourself to a program.
            Then, add additional users to your organization so they can use the Unite Us Platform.
            Each user needs a separate account with a unique email address.
            After your organization is live on the Unite Us Platform, you can invite users to log in.`}
          </p>
        )
    }
    <p className={`${showDraftStepper ? 'pb-5 pt-5' : 'pb-2 pt-4'}`}>
      Refer to the
      <a
        target="_blank"
        rel="noreferrer"
        href="http://support.uniteus.io/en/articles/2965065-add-and-edit-users-at-your-organization"
      >
        &nbsp;Add and Edit Users at Your Organization&nbsp;
      </a>
      article for more information.
    </p>
  </div>
);

UserSubheader.propTypes = {
  isOrgActive: PropTypes.bool.isRequired,
  showDraftStepper: PropTypes.bool.isRequired,
};

export default UserSubheader;
