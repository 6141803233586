import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  TextField,
} from '@unite-us/ui';
import { InfoPanel } from '@unite-us/client-utils';
import { validations } from '@unite-us/app-components';
import { CFR_PART_2_CASE_MESSAGE, PHI_INFO_MESSAGE } from 'common/messageConstants';
import { browserHistory } from 'common/utils/browserHistory';
import {
  caseDetailFields,
  caseTypeOptions,
  clearFields,
  isDefaultProgramSelected,
  isValidServiceType,
  networkOptions,
} from 'src/components/Cases/utils';
import { fetchContactDocuments } from 'actions/Document/Contact/Group';
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';
import { fetchGroupsUsersFromProgram } from 'actions/User/Program/Group';
import { fetchGroupsPrograms } from 'actions/Program/Group';
import ClientEnrolledNotification from 'src/components/Referrals/ReferralStatus/components/ClientEnrolledNotification';
import AuthorizationRequestForm from 'src/components/Referrals/ReferralFormFields/EditReferralDetails/AuthorizationRequestForm';
import DateDiv from 'src/components/Referrals/ReferralStatus/components/DateDiv';
import DocumentUploader from 'common/form/DocumentUploader';
import { Spinner } from 'common/spinners';
import { validateReduxForm } from 'common/form';
import _ from 'lodash';
import today from 'src/common/utils/today';
import { CONTACT_CASE_FILE_UPLOAD_KEY, CASE_DETAILS_FORM, ORG_CASE } from 'src/components/Cases/constants';
import { fetchCoreGroupForms } from 'actions/Group';
import CaseOONGroupsSelector from 'src/components/Cases/CaseOONGroupsSelector';
import { fetchGroupContact } from 'src/actions/Contact/Group';
import {
  invalidServiceTypeValidation,
  serviceTypeRequiredValidation,
} from 'common/utils/ServiceTypes/validations';
import { paginateNetworkGroups, pays5604AuthorizationForInternalCases } from 'src/common/utils/FeatureFlags/flags';
import CaseNetworkField from './CaseNetworkField';
import CaseProgramField from './CaseProgramField';
import CaseTypeField from './CaseTypeField';
import CaseServiceTypeField from './CaseServiceTypeField';
import CaseOONBrowse from './CaseOONBrowse';
import CasePrimaryWorkerField from './CasePrimaryWorkerField';
import './AddCaseDetailsForm.scss';

export class AddCaseDetailsForm extends Component {
  constructor(props) {
    super(props);

    const selectedProgramId = props.fields.program.value.id;
    const groupProgram = _.find(
      _.get(props.groupsPrograms, 'data', []),
      (program) => program.id === selectedProgramId,
    );
    const feeScheduleProgramId = groupProgram?.relationships.fee_schedule_program?.data?.id;

    this.state = {
      defaultProgramSelected: isDefaultProgramSelected(props.groupsPrograms, props.fields, props.defaultProgram),
      invalidServiceType: null,
      selectedProgramId: '',
      showBrowse: false,
      feeScheduleProgramId: feeScheduleProgramId || '',
      disableNextButton: false,
    };

    this.fetchGroupsUsers = this.fetchGroupsUsers.bind(this);
    this.debouncedFetchGroupsUsers = _.debounce(this.fetchGroupsUsers, 400);
    this.onCaseTypeChange = this.onCaseTypeChange.bind(this);
    this.onCreateCaseDetails = this.onCreateCaseDetails.bind(this);
    this.cancel = this.cancel.bind(this);
    this.getInitialValues = this.getInitialValues.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onProgramChange = this.onProgramChange.bind(this);
    this.onServiceTypeChange = this.onServiceTypeChange.bind(this);
    this.onNetworkChange = this.onNetworkChange.bind(this);
    this.checkGroupFormsAndNavigate = this.checkGroupFormsAndNavigate.bind(this);
    this.triggerBrowse = this.triggerBrowse.bind(this);
    this.setDisableNextButton = this.setDisableNextButton.bind(this);
  }

  componentDidMount() {
    const {
      initializeForm,
      shouldInitializeForm,
    } = this.props;

    if (shouldInitializeForm) {
      initializeForm(this.getInitialValues());
    }
  }

  onCreateCaseDetails() {
    const assistanceRequestId = _.get(this.props, 'assistanceRequest.id');
    const prefix = assistanceRequestId ? `/assistance-requests/${assistanceRequestId}` : '';
    this.checkGroupFormsAndNavigate(prefix);
  }

  onCaseTypeChange() {
    const {
      fields, group, hasNetworkLicense, untouch,
    } = this.props;

    const caseTypeOptionsLength = caseTypeOptions(group.name, hasNetworkLicense).length;

    if (caseTypeOptionsLength > 1) {
      clearFields({
        fields,
        untouch,
        keys: ['network', 'primary_worker', 'program', 'service_type'],
      });
    }
  }

  onNetworkChange() {
    const {
      fields,
      networks,
      untouch,
    } = this.props;

    if (networkOptions(networks).length > 1 && fields.service_case.service_type.value) {
      clearFields({
        fields,
        untouch,
        keys: ['service_type'],
      });
    }
  }

  onProgramChange(selectedProgram) {
    const {
      assistanceRequest,
      fields,
      groupId,
      groupsPrograms,
      untouch,
      user,
      shouldInitializeForm,
    } = this.props;

    clearFields({
      fields,
      untouch,
      keys: ['primary_worker', 'service_type'],
    });

    const selectedProgramId = selectedProgram.id;
    this.setState({ selectedProgramId });
    const groupProgram = _.find(_.get(groupsPrograms, 'data', []), (program) => program.id === selectedProgramId);

    const feeScheduleProgramId = groupProgram?.relationships.fee_schedule_program?.data?.id;
    this.setState({ feeScheduleProgramId });

    // Update defaultProgramSelected State only when Program is changed.
    // onProgramChange called by this.initialValues() - Refactor Opportunity
    if (!shouldInitializeForm) {
      this.setState({
        defaultProgramSelected: _.get(groupProgram, 'attributes.default', false),
      });
    }

    if (selectedProgram) {
      const { oonProgram } = this.props;
      const oonProgramId = _.get(oonProgram, 'id', '');
      const arServiceType = _.get(assistanceRequest, 'service_type');
      if (!_.isEmpty(arServiceType)) {
        if (isValidServiceType({
          fields,
          groupProgram,
          serviceType: arServiceType,
          user,
        })) {
          fields.service_case.service_type.onChange(arServiceType);
          this.onServiceTypeChange(arServiceType);
        } else {
          this.setState({ invalidServiceType: arServiceType }, () => {
            fields.service_case.service_type.onBlur('');
          });
        }
      }

      // fetch primary workers
      if (selectedProgramId === oonProgramId) {
        this.props.fetchProvidersUserCore({ providers: groupId, options: { size: 50, licensed: false } });
      } else {
        this.props.fetchGroupsUsersFromProgram(groupId, selectedProgramId);
      }
    }
  }

  onServiceTypeChange(serviceType) {
    const {
      canPaginateNetworkGroups,
      fields,
      groupId,
      paginateSearchNetworkGroups,
      searchOONGroups,
    } = this.props;

    if (this.state.currentServiceTypeId !== serviceType.id) {
      this.props.fetchCoreGroupForms(groupId, [serviceType.id]);
    }

    if (this.state.defaultProgramSelected) {
      if (canPaginateNetworkGroups) {
        paginateSearchNetworkGroups(fields);
      } else {
        searchOONGroups(fields);
      }
    }
  }

  getInitialValues() {
    const {
      assistanceRequest,
      contactId,
      contactDocuments,
      defaultProgram,
      hasGroupLicense,
      hasNetworkLicense,
      networks,
    } = this.props;

    if (defaultProgram.id) {
      this.onProgramChange(defaultProgram);
    }

    return _.uuCompactArrayOrObject({
      case_type: hasGroupLicense && !hasNetworkLicense ? ORG_CASE : {},
      network: networks.length === 1 && _.get(networks, '[0].id'),
      program_entry: today(),
      program: defaultProgram,
      service_case: _.uuCompactArrayOrObject({
        attachableDocuments: !_.isEmpty(contactDocuments) && _.get(contactDocuments, [contactId, 'data'], [])
          .map((doc) => ({
            attached: false,
            document: doc,
          })),
        description: _.get(assistanceRequest, 'description', ''),
        oonCase: {},
      }),
    });
  }

  setDisableNextButton(isDisabled) {
    this.setState({
      disableNextButton: isDisabled,
    });
  }

  checkGroupFormsAndNavigate(prefix) {
    const { groupForms, contactId } = this.props;

    return _.isEmpty(groupForms) ?
      browserHistory.push(`${prefix}/contacts/${contactId}/cases/new/review`) :
      browserHistory.push(`${prefix}/contacts/${contactId}/cases/new/add-case-assessments`);
  }

  resetForm() {
    this.props.resetForm();
  }

  cancel() {
    const { contactId } = this.props;
    const assistanceRequestId = _.get(this.props, 'assistanceRequest.id');
    if (assistanceRequestId) {
      browserHistory.push(`/dashboard/new/assistance-requests/${assistanceRequestId}`);
    } else {
      browserHistory.push(`/facesheet/${contactId}/cases`);
    }
  }

  triggerBrowse() {
    this.setState({
      showBrowse: !this.state.showBrowse,
    });
  }

  fetchGroupsUsers(search) {
    const { groupId, oonProgram } = this.props;
    const oonProgramId = _.get(oonProgram, 'id', '');
    const { selectedProgramId } = this.state;
    if (selectedProgramId === oonProgramId) {
      this.props.fetchProvidersUserCore({
        providers: groupId,
        options: {
          text: search,
          page: 1,
          size: 50,
          licensed: false,
        },
      });
    } else {
      this.props.fetchGroupsUsersFromProgram(groupId, selectedProgramId, { text: search, per: 50, page: 1 });
    }
  }

  render() {
    const {
      authorizationForInternalCasesFlag,
      caseProgramEnrollment,
      canPaginateNetworkGroups,
      contact,
      currentUserGroup,
      debouncedSearchNetworkGroups,
      fetchingOONGroups,
      fields,
      group,
      groupId,
      groupIsSensitive,
      groupsUsers,
      handleSubmit,
      hasGroupLicense,
      hasNetworkLicense,
      isCheckingProgramEnrollment,
      isCoordinationGroup,
      networks,
      originCoordinates,
      programmingOptionsArray,
      programsServiceTypes,
      registerField,
      removeSelectedBrowseGroup,
      onServiceTypeChangeFetchEmpty,
      setBrowseMapGroups,
      styles,
      touch,
      untouch,
      user,
      oonGroups,
    } = this.props;

    const { invalidServiceType } = this.state;

    const disableOnEmptyCaseType = this.props.hasGroupLicense && _.isEmpty(fields.case_type.value);

    const noEmptySpaces = (value) => (value && value.trim() === '') && 'Required';

    const composeValidators = (...validators) => (
      (value) => validators.reduce((error, validator) => error || validator(value), undefined)
    );

    // When paginateNetworkGroups feature flag is OFF, we store oonGroups on ReduxForm.
    // (see line::260 of AddCaseDetails for when we store groups on ReduxForm)
    // This is when we would use oonGroupsFields.
    const oonGroupsFields = fields.service_case.oonGroups.map((org) => org.group.value);
    const selectedOONGroups = fields.service_case.oonCase.selected
      .map((selected) => selected.group.value)
      .filter((selectedValue) => selectedValue.id);

    return (
      <div>
        <form className="add-case-details">
          <div className="add-case-details__reset mr-two">
            <Button
              id="clear-all-btn"
              onClick={this.resetForm}
              style={{ minWidth: '25px' }}
              label="Clear all"
              iconRight={<Icon icon="IconTrash" style={styles.iconTrash} />}
            />
          </div>

          <div className="add-case-details__content">
            {
              hasGroupLicense && (
                <CaseTypeField
                  field={fields.case_type}
                  group={group}
                  hasGroupLicense={hasGroupLicense}
                  hasNetworkLicense={hasNetworkLicense}
                  onChange={this.onCaseTypeChange}
                  registerField={registerField}
                  untouch={untouch}
                />
              )
            }

            <CaseNetworkField
              caseType={fields.case_type.value.type}
              disabled={disableOnEmptyCaseType}
              field={fields.network}
              networks={networks}
              onChange={this.onNetworkChange}
              registerField={registerField}
            />

            <div className="add-case-details__program">
              <CaseProgramField
                disabled={disableOnEmptyCaseType}
                field={fields.program}
                fields={fields}
                groupId={groupId}
                hasGroupLicense={hasGroupLicense}
                isCoordinationGroup={isCoordinationGroup}
                inline={false}
                placeholder="Choose one..."
                programmingOptionsArray={programmingOptionsArray}
                registerField={registerField}
                onChange={this.onProgramChange}
                required
                user={user}
                validations={validations.isRequired}
              />

              <DateDiv
                id="program-entry"
                iconColor={styles.iconTrash.svg.color}
                programEnrollment={caseProgramEnrollment}
                checkingEnrollment={isCheckingProgramEnrollment}
                entryDate={fields.program_entry}
                label={'Enrolled'}
                registerField={registerField}
              />
            </div>

            <div className="add-case-details__program_text">
              <ClientEnrolledNotification
                checkingEnrollment={isCheckingProgramEnrollment}
                programEnrollment={caseProgramEnrollment}
              />
            </div>

            <div className="mb-5">
              <CaseServiceTypeField
                defaultProgramSelected={this.state.defaultProgramSelected}
                field={fields.service_case.service_type}
                fields={fields}
                programsServiceTypes={programsServiceTypes}
                onChange={this.onServiceTypeChange}
                registerField={registerField}
                touch={touch}
                user={user}
                validations={[
                  invalidServiceTypeValidation(invalidServiceType),
                  serviceTypeRequiredValidation(invalidServiceType),
                ]}
              />
            </div>

            {
              fetchingOONGroups ? <Spinner scale={0.7} /> :
                (
                  <CaseOONGroupsSelector
                    contact={contact}
                    // fields are needed in the debounced call to build params (see line::236 of AddCaseDetails)
                    debouncedSearchNetworkGroups={(search) => debouncedSearchNetworkGroups(search, fields)}
                    fields={fields}
                    hide={
                      fetchingOONGroups ||
                      !this.state.defaultProgramSelected ||
                      !fields.service_case.service_type.value.id ||
                      !fields.network.value
                    }
                    isOONCase={this.state.defaultProgramSelected}
                    oonGroups={canPaginateNetworkGroups ?
                      _.uniqBy([...oonGroups, ...selectedOONGroups], 'id') :
                      oonGroupsFields}
                    originCoordinates={originCoordinates}
                    network={fields.network.value}
                    registerField={registerField}
                    removeSelectedBrowseGroup={removeSelectedBrowseGroup}
                    selectedFields={_.get(fields, 'service_case.oonCase.selected', [])}
                    onServiceTypeChangeFetchEmpty={onServiceTypeChangeFetchEmpty}
                    toggleBrowse={this.triggerBrowse}
                  />
                )
            }

            <CasePrimaryWorkerField
              disabled={!fields.program.value}
              fetchGroupsUsers={this.debouncedFetchGroupsUsers}
              field={fields.service_case.primary_worker_id}
              groupsUsers={groupsUsers}
              registerField={registerField}
            />

            { authorizationForInternalCasesFlag && (
              <AuthorizationRequestForm
                contact={contact}
                registerField={registerField}
                fields={fields}
                feeScheduleProgramId={this.state.feeScheduleProgramId}
                setDisableNextButton={this.setDisableNextButton}
              />
            )}
            <TextField
              ref={registerField}
              field={fields.service_case.description}
              id="description"
              label={this.state.defaultProgramSelected ? 'Notes' : 'Case Description'}
              afterLabelContent={(
                <InfoPanel
                  className="add-case-details-form__info-panel"
                  message={
                    groupIsSensitive ?
                      [PHI_INFO_MESSAGE, CFR_PART_2_CASE_MESSAGE].join(' ') :
                      PHI_INFO_MESSAGE
                  }
                />
              )}
              inline={false}
              validations={composeValidators(validations.isRequired, noEmptySpaces)}
              required
            />

            <div className="upload-case-documents">
              <h5 className="upload-case-documents__label">Attach Documents</h5>
              <DocumentUploader
                dropzoneName={CONTACT_CASE_FILE_UPLOAD_KEY}
                fields={fields.service_case}
                formOnly
                registerField={registerField}
              />
            </div>
          </div>

          <footer className="add-case-details__footer">
            <Button
              id="add-case-details-cancel-btn"
              label="Cancel"
              onClick={this.cancel}
              style={{ marginRight: '10px' }}
            />

            <Button
              disabled={this.state.disableNextButton}
              id="add-case-details-next-btn"
              onClick={handleSubmit(this.onCreateCaseDetails)}
              label="Next"
              primary
            />
          </footer>
        </form>
        {
          this.state.showBrowse && (
            <CaseOONBrowse
              contact={contact}
              currentUserGroup={currentUserGroup}
              description={fields.service_case.description.value}
              fields={fields}
              oonGroups={canPaginateNetworkGroups ? oonGroups : oonGroupsFields}
              sender={_.get(group, 'name', '')}
              serviceType={_.get(fields.service_case, 'service_type.value')}
              setBrowseMapGroups={setBrowseMapGroups}
              toggleBrowse={this.triggerBrowse}
            />
          )
        }
      </div>
    );
  }
}

AddCaseDetailsForm.propTypes = {
  assistanceRequest: PropTypes.object,
  caseProgramEnrollment: PropTypes.object,
  canPaginateNetworkGroups: PropTypes.bool.isRequired,
  authorizationForInternalCasesFlag: PropTypes.bool.isRequired,
  contact: PropTypes.object,
  contactDocuments: PropTypes.object.isRequired,
  contactId: PropTypes.string.isRequired,
  currentUserGroup: PropTypes.object,
  debouncedSearchNetworkGroups: PropTypes.func.isRequired,
  defaultProgram: PropTypes.object.isRequired,
  fetchCoreGroupForms: PropTypes.func.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  fetchGroupsUsersFromProgram: PropTypes.func.isRequired,
  fetchingOONGroups: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  groupForms: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  groupIsSensitive: PropTypes.bool.isRequired,
  groupsPrograms: PropTypes.object.isRequired,
  groupsUsers: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hasGroupLicense: PropTypes.bool.isRequired,
  hasNetworkLicense: PropTypes.bool.isRequired,
  initializeForm: PropTypes.func.isRequired,
  isCheckingProgramEnrollment: PropTypes.bool,
  isCoordinationGroup: PropTypes.bool.isRequired,
  networks: PropTypes.array,
  onServiceTypeChangeFetchEmpty: PropTypes.bool.isRequired,
  oonProgram: PropTypes.object.isRequired,
  oonGroups: PropTypes.array,
  originCoordinates: PropTypes.array.isRequired,
  paginateSearchNetworkGroups: PropTypes.func.isRequired,
  programmingOptionsArray: PropTypes.array.isRequired,
  programsServiceTypes: PropTypes.object,
  removeSelectedBrowseGroup: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  searchOONGroups: PropTypes.func.isRequired,
  setBrowseMapGroups: PropTypes.func.isRequired,
  shouldInitializeForm: PropTypes.bool.isRequired,
  styles: PropTypes.object,

  touch: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  user: PropTypes.shape({
    networks: PropTypes.array.isRequired,
  }).isRequired,
};

AddCaseDetailsForm.defaultProps = {
  assistanceRequest: {},
  currentUserGroup: {},
  isCheckingProgramEnrollment: false,
  networks: [],
  styles: {
    iconTrash: {
      svg: {
        color: '#c5c8cc',
        fill: '#c5c8cc',
        width: '25px',
        height: '25px',
      },
    },
  },
  oonGroups: [],
  caseProgramEnrollment: {},
  programsServiceTypes: {},
  contact: {},
};

AddCaseDetailsForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authorizationForInternalCasesFlag: pays5604AuthorizationForInternalCases(state),
  canPaginateNetworkGroups: paginateNetworkGroups(state), // Feature flag
  groupIsSensitive: _.get(state, 'session.currentProvider.attributes.sensitive', false),
});

export default validateReduxForm({
  form: CASE_DETAILS_FORM,
  fields: caseDetailFields,
  destroyOnUnmount: false,
}, mapStateToProps, {
  fetchContactDocuments,
  fetchGroupContact,
  fetchCoreGroupForms,
  fetchGroupsPrograms,
  fetchProvidersUserCore,
  fetchGroupsUsersFromProgram,
})(AddCaseDetailsForm);
