import PropTypes from 'prop-types';
import React from 'react';
import { get, isEmpty } from 'lodash';
import {
  UUBaseCard,
  UUBaseCardBody,
  UUBaseCardHeader,
  Divider,
  Icon,
} from '@unite-us/ui';
import { InfoPanel } from '@unite-us/client-utils';
import DetailOutcomeWrapper from 'common/display/SingleItemDetails/DetailOutcomeWrapper';
import DocumentUploader from 'common/form/DocumentUploader';
import ConsentReminder from 'src/components/ConsentReminder';
import ReasonNote from 'src/components/Referrals/ReferralDetail/components/ReasonNote';
import {
  dividerStatusStyles,
  isDescriptionEditable,
} from 'common/display/SingleItemDetails/utils/common';
import { getReferralIconAndTooltip } from 'src/components/Referrals/utils';
import ReferralSummaryWrapper from 'src/components/Referrals/components/ReferralDetailSummaryWrapper';
import DetailSummary from 'common/display/SingleItemDetails/dashboard/DetailSummary';
import DetailStatusText from 'common/display/SingleItemDetails/DetailStatusText';
import { returnFilteredStatus } from 'src/components/Referrals/ReferralDetail/utils';
import ReferralDetailActionWrapper from 'src/components/Referrals/ReferralDetail/components/ReferralDetailActionWrapper';
import ReferralBasicInformation from 'src/components/Referrals/ReferralDetail/components/ReferralBasicInformation';
import { CFR_PART_2_REFERRAL_MESSAGE, PHI_INFO_MESSAGE } from 'common/messageConstants';
import { referralStatuses } from 'src/components/ReferralHistory/utils/referralStatusConstants';
import ReferralServiceAuthorizationInformation from './ReferralServiceAuthorizationInformation';
import './ReferralDetailTop.scss';

const StatusBadge = ({ referralState, isClosedByCoordinationCenter }) => {
  const getStatus = () => {
    if (isClosedByCoordinationCenter) {
      return referralStatuses.reviewed({ showText: false });
    }
    return referralState && referralStatuses[referralState] && referralStatuses[referralState]({ showText: false });
  };
  return getStatus();
};

const ReferralDetailTop = ({
  groupId,
  isCCUser,
  referral,
  title,
  serviceCase,
  forwardedReferral,
  buttonClass,
  showDeleteModal,
  isSensitive,
  description,
  isReferralFetching,
  referralDocuments,
  contactId,
  editableReferral,
  attachDocuments,
  removeDocument,
  renameDocument,
  canViewContact,
  isDraft,
  isReferralsAdminUser,
  type,
  showActions,
  suggestedGroups,
  goToNotes,
  falsifyActionBool,
  isSendable,
  location,
  isClosedByCoordinationCenter,
}) => {
  const referralState = get(referral, 'state', null);
  const { status } = referral;
  const isSendingProvider = referral?.referred_by_group?.id === groupId;
  const serviceAuthorization = get(referral, 'case.service_authorization', {});

  return (
    <UUBaseCard>
      <UUBaseCardHeader noBorder title={title()}>
        {['declined', 'in_review', 'recalled'].includes(referralState) ? (
          <ReasonNote
            providerId={get(serviceCase, 'provider.id')}
            referral={referral}
          />
        ) : null}
        <div className="detail-info__status-text">
          <StatusBadge
            referralState={referralState}
            isClosedByCoordinationCenter={isClosedByCoordinationCenter}
          />
          <DetailStatusText
            enumsKey="referrals"
            detailObj={referral}
            groupId={groupId}
            isCoordinationGroup={isCCUser}
            isReferralForwarded={forwardedReferral}
            {...getReferralIconAndTooltip({ referral })}
            isClosedByCoordinationCenter={isClosedByCoordinationCenter}
          />

          <button
            type="button"
            name="delete draft referral"
            className={buttonClass()}
            onClick={showDeleteModal}
          >
            <Icon icon="IconTrash" size={21} />
          </button>
        </div>
      </UUBaseCardHeader>
      <Divider style={dividerStatusStyles(returnFilteredStatus(referral), isClosedByCoordinationCenter)} />
      <UUBaseCardBody withPadding>
        <DetailOutcomeWrapper status={status} item={referral} />
        <ReferralBasicInformation
          referral={referral}
          groupId={groupId}
          isCC={isCCUser}
        />
        <Divider className="detail-info__horizontal-divider mb-4 mt-4" />
        <ReferralSummaryWrapper
          contact={referral.contact}
          groupId={groupId}
          referral={referral}
        >
          <DetailSummary
            className={`detail-summary ${isSensitive ? 'detail-summary--sensitive' : ''}`}
            editable={isDescriptionEditable(referral, status, groupId, isCCUser)}
            description={description}
            item={referral}
            descriptionTitle="Referral Description"
            descriptionEditSubtitle={(
              <InfoPanel
                className="detail-summary__info-panel"
                message={
                  isSensitive ?
                    [PHI_INFO_MESSAGE, CFR_PART_2_REFERRAL_MESSAGE].join(' ') :
                    PHI_INFO_MESSAGE
                }
              />
            )}
            descriptionSubtitle={isSensitive ? (
              <InfoPanel
                className="detail-summary__info-panel"
                message={`Reminder: ${CFR_PART_2_REFERRAL_MESSAGE}`}
              />
            ) : undefined}
            status={status}
          />
        </ReferralSummaryWrapper>

        {!isEmpty(serviceAuthorization) && (
          <>
            <Divider className="detail-info__horizontal-divider mb-4 mt-4" />
            <ReferralServiceAuthorizationInformation
              serviceAuthorization={serviceAuthorization}
              showRequestedValues={isSendingProvider}
            />
          </>
        )}

        {
          !isSensitive ? (
            <>
              <Divider className="detail-info__horizontal-divider mb-4 mt-4" />
              <div className="row">
                <div className="col-md-6 list-divider">
                  <h5 className="detail-info__icon-label">Documents</h5>
                  {
                    !isReferralFetching && (
                      <DocumentUploader
                        attachedDocuments={referralDocuments}
                        contactId={contactId}
                        editable={editableReferral}
                        onAttachDocuments={attachDocuments}
                        onRemoveDocument={removeDocument}
                        onRenameDocument={renameDocument}
                        canViewContact={canViewContact}
                      />
                    )
                  }
                </div>
              </div>
              <Divider className="detail-info__horizontal-divider mb-4 mt-4" />
              <div>
                {isDraft ? (<ConsentReminder />) : null}
                <ReferralDetailActionWrapper
                  disableActions={!isReferralsAdminUser && type === 'newReferrals'}
                  showActions={showActions}
                  location={location}
                  suggestedGroups={suggestedGroups}
                  referral={referral}
                  goToNotes={goToNotes}
                  falsifyActionBool={falsifyActionBool}
                  isSendable={isSendable}
                />
              </div>
            </>
        ) : null
      }
      </UUBaseCardBody>
    </UUBaseCard>
  );
};

ReferralDetailTop.propTypes = {
  groupId: PropTypes.string.isRequired,
  isCCUser: PropTypes.bool.isRequired,
  title: PropTypes.func.isRequired,
  serviceCase: PropTypes.object.isRequired,
  forwardedReferral: PropTypes.bool.isRequired,
  buttonClass: PropTypes.func.isRequired,
  showDeleteModal: PropTypes.func.isRequired,
  isSensitive: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  isReferralFetching: PropTypes.bool.isRequired,
  contactId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  referral: PropTypes.shape({
    status: PropTypes.string,
    case: PropTypes.object,
    description: PropTypes.string,
    id: PropTypes.string,
    service_type: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    receiving_provider: PropTypes.shape({
      id: PropTypes.string,
    }),
    referred_by_network: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    referred_to_network: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    contact: PropTypes.shape({
      full_name: PropTypes.string,
      id: PropTypes.string.isRequired,
      permissions: PropTypes.object,
    }),
    permissions: PropTypes.object,
    service_authorization: PropTypes.object,
    referred_by_group: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  referralDocuments: PropTypes.array.isRequired,
  editableReferral: PropTypes.bool.isRequired,
  canViewContact: PropTypes.bool.isRequired,
  isReferralsAdminUser: PropTypes.bool.isRequired,
  isDraft: PropTypes.bool.isRequired,
  isSendable: PropTypes.bool.isRequired,
  attachDocuments: PropTypes.func.isRequired,
  removeDocument: PropTypes.func.isRequired,
  renameDocument: PropTypes.func.isRequired,
  goToNotes: PropTypes.func.isRequired,
  falsifyActionBool: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  showActions: PropTypes.bool.isRequired,
  suggestedGroups: PropTypes.array.isRequired,
  isClosedByCoordinationCenter: PropTypes.bool.isRequired,
};

export default ReferralDetailTop;
