import React from 'react';

const LocationFormSubheader = () => (
  <p className="pb-4">
    Add locations to your organization using the
    <a
      href="http://support.uniteus.io/en/articles/5808325-update-your-organization-s-profile-information"
      target="_blank"
      rel="noreferrer"
    >
      &nbsp;Update Your Organization’s Profile Information&nbsp;
    </a>
    article.
  </p>
);

export default LocationFormSubheader;
