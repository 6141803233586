import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BaseCard,
  BaseCardBody,
} from '@unite-us/ui';
import _ from 'lodash';
import d3 from 'd3';
import nv from 'nvd3';

import ChartTitle from '../ChartTitle/ChartTitle';
import Legend from '../Legend/Legend';
import { pickColors, theme } from '../utils';

import './ChartPie.scss';

class ChartPie extends Component {
  constructor(props) {
    super(props);

    this.buildChart = this.buildChart.bind(this);
    this.compileChart = this.compileChart.bind(this);
    this.refreshChart = this.refreshChart.bind(this);

    this.state = {
      id: _.uniqueId('pie-chart-'),
      chart: null,
      chartData: null,
    };
  }

  componentDidMount() {
    this.buildChart();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      if (this.state.chart) {
        this.state.chart.title(nextProps.innerTitle);
      }

      this.state.chartData.datum(nextProps.data);

      if (this.props.data.length !== nextProps.data.length) {
        this.state.chart.color(pickColors(nextProps.colors, nextProps.data.length));
      }

      this.refreshChart();
    }

    if (nextProps.donut !== this.props.donut) {
      this.state.chart.donut(nextProps.donut);
      this.refreshChart();
    }
  }

  compileChart() {
    const {
      props,
    } = this;

    const chart = nv.models.pieChart()
      .x(props.x)
      .y(props.y)
      .color(pickColors(props.colors, props.data.length))
      .showTooltipPercent(props.showTooltipPercent)
      .donut(props.donut)
      .donutRatio(props.donutRatio)
      .showLegend(false)
      .legendPosition(props.legendPosition)
      .labelsOutside(theme.pie.labelsOutside)
      .valueFormat(d3.format(props.valueFormat))
      .duration(theme.animation)
      .growOnHover(theme.pie.growOnHover)
      .padAngle(props.padAngle)
      .showLabels(props.showLabels)
      .title(props.innerTitle)
      .margin(_.merge(theme.pie.margin, props.margin));

    chart.tooltip.enabled(props.enableTooltip);

    chart.pie.dispatch.on('elementClick', props.onElementClick);
    chart.pie.dispatch.on('elementDblClick', props.onElementDoubleClick);

    if (_.isFunction(props.startAngle) && _.isFunction(props.endAngle)) {
      chart.startAngle(props.startAngle)
        .endAngle(props.endAngle);
    }

    const chartData = d3.select(`#${this.state.id} svg`)
      .datum(props.data);

    chartData.call(chart);

    if (!_.isEmpty(props.innerTitle) && props.donut) {
      const slice = chartData.selectAll('text.nv-pie-title').filter((d, i) => i === 0);
      slice.style('fill', props.titleColor);
    }

    nv.utils.windowResize(chart.update);

    return { chart, chartData };
  }

  buildChart() {
    nv.addGraph(this.compileChart, (result) => this.setState(result));
  }

  refreshChart() {
    this.state.chartData.call(this.state.chart);
  }

  render() {
    const {
      children,
      data,
      detailText,
      detailWidth,
      onBackButtonClick,
      onElementClick,
      pieColGridWidth,
      showBackButton,
      showLegend,
      showTooltipPercent,
      style,
      subTitle,
      title,
      titleStyle,
      twoColsLegend,
    } = this.props;
    const legend = showLegend ? (
      <div className={`col-sm-${12 - pieColGridWidth}`} style={style}>
        {
          this.state.chart && (
            <Legend
              chart={this.state.chart}
              data={data}
              detailText={detailText}
              detailWidth={detailWidth}
              onDetailClick={onElementClick}
              showPercent={data && data.length > 1 && showTooltipPercent}
              showTotal={data && data.length > 1}
              title={title}
              twoColsLegend={twoColsLegend}
            />
          )
        }
      </div>
    ) : null;

    return (
      <BaseCard className="chart">
        <ChartTitle
          onBackButtonClick={onBackButtonClick}
          showBackButton={showBackButton}
          subTitle={subTitle}
          title={title}
          titleStyle={titleStyle}
        />
        <BaseCardBody withPadding>
          <div
            className="chart-pie row"
            id={this.state.id}
          >
            <div className={`col-sm-${pieColGridWidth}`} style={style}>
              <svg />
            </div>
            {legend}
          </div>
          {children || null}
        </BaseCardBody>
      </BaseCard>
    );
  }
}

ChartPie.propTypes = {
  children: PropTypes.node,
  colors: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  donut: PropTypes.bool.isRequired,
  donutRatio: PropTypes.number.isRequired,
  enableTooltip: PropTypes.bool.isRequired,
  endAngle: PropTypes.func,
  innerTitle: PropTypes.string.isRequired,
  legendPosition: PropTypes.string.isRequired,
  margin: PropTypes.object.isRequired,
  onElementClick: PropTypes.func,
  onElementDoubleClick: PropTypes.func,
  padAngle: PropTypes.number.isRequired,
  pieColGridWidth: PropTypes.number.isRequired,
  showLabels: PropTypes.bool.isRequired,
  showLegend: PropTypes.bool.isRequired,
  showTooltipPercent: PropTypes.bool.isRequired,
  startAngle: PropTypes.func,
  style: PropTypes.object.isRequired,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  titleStyle: PropTypes.object.isRequired,
  twoColsLegend: PropTypes.bool.isRequired,
  valueFormat: PropTypes.string.isRequired,
  x: PropTypes.func.isRequired,
  y: PropTypes.func.isRequired,
  detailWidth: PropTypes.string,
  detailText: PropTypes.string,
  showBackButton: PropTypes.bool.isRequired,
  onBackButtonClick: PropTypes.func,
};

ChartPie.defaultProps = {
  colors: theme.colors,
  donut: theme.pie.donut,
  donutRatio: theme.pie.donutRatio,
  enableTooltip: theme.tooltip.enabled,
  innerTitle: '',
  legendPosition: theme.legend.position,
  margin: {},
  padAngle: theme.pie.padAngle,
  pieColGridWidth: 6,
  showLabels: theme.pie.showLabels,
  showLegend: true,
  showTooltipPercent: theme.tooltip.showTooltipPercent,
  style: {
    height: '400px',
  },
  titleColor: theme.colors[0],
  titleStyle: {},
  twoColsLegend: true,
  valueFormat: theme.valueFormat,
  x: (d) => d.label,
  y: (d) => d.value,
  showBackButton: false,
};

export default ChartPie;
