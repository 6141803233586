import React, { useState, useContext } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import AssessmentDetailsView from 'src/components/Contacts/components/AssessmentDetailsView';
import { useFindRecord } from 'src/api/APIHooks';
import PropTypes from 'prop-types';
import { CurrentEmployeeContext } from 'src/common/contexts/CurrentEmployeeContext';

export default function ServiceAuthorizationAssessmentDetails(props) {
  const { params } = props;
  const {
    submission_id: submissionId,
    person_id: personId,
    assessment_id: assessmentId,
  } = params;

  const { data: formResponse } = useFindRecord('form', assessmentId, { queryConfig: { placeholderData: undefined } });
  const assessment = formResponse?.data.data ?? {};
  const [editMode, setEditMode] = useState(false);

  const currentEmployee = useContext(CurrentEmployeeContext);

  const onCancel = () => {
    browserHistory.goBack();
  };

  const onEditClick = () => {
    setEditMode(true);
  };

  return (
    <div className="contact-assessment-details">
      {
        <AssessmentDetailsView
          assessment={assessment}
          submissionId={submissionId}
          editMode={editMode}
          showEditButton
          onEditClick={onEditClick}
          afterSubmit={onCancel}
          onCancel={onCancel}
          contextType="person"
          context={personId}
          currentEmployee={currentEmployee}
        />
      }
    </div>
  );
}

ServiceAuthorizationAssessmentDetails.propTypes = {
  params: PropTypes.shape({
    submission_id: PropTypes.string.isRequired,
    assessment_id: PropTypes.string.isRequired,
    person_id: PropTypes.string.isRequired,
  }).isRequired,
};
